import ActionUtility from "../../../utilities/ActionUtility";
import DashboardEffect from "./DashboardEffect";

export default class DashboardAction {
  //dashboard requests
  static REQUEST_DASHBOARD = "DashboardAction.REQUEST_DASHBOARD";
  static REQUEST_DASHBOARD_FINISHED =
    "DashboardAction.REQUEST_DASHBOARD_FINISHED";

  //METHODS

  //TicketType get method
  static requestDashboard(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DashboardAction.REQUEST_DASHBOARD,
        DashboardEffect.requestDashboard,
        params
      );
    };
  }
}
