import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import FeedBackGetModel from "./models/FeedBackGetModel";

// FeedbackEffect
export default class FeedbackEffect {
  // calling an API according to the Feedback model
  static async requestFeedback(params) {
    const endpoint = environment.api.feedback;
    return EffectUtility.getToModel(FeedBackGetModel, endpoint, params);
  }

  // Get method for the Feedback filter
  static async requestFeedbackFilter(params) {
    const endpoint = environment.api.feedback;
    return EffectUtility.getToModel(FeedBackGetModel, endpoint, params);
  }
}
