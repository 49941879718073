import ChannelAnalyticsFilterReducer from "./channelAnalytics/ChannelAnalyticsFilterReducer";
import ChannelAnalyticsReducer from "./channelAnalytics/ChannelAnalyticsReducer";
import ChannelAnalyticsAccountFilterReduser from "./channelAnalyticsAccount/ChannelAnalyticsAccountFilterReduser";
import ChannelAnalyticsAccountReducer from "./channelAnalyticsAccount/ChannelAnalyticsAccountReducer";
import McnFeedsFilterReduser from "./mcnFeeds/McnFeedsFilterReduser";
import McnFeedsReduser from "./mcnFeeds/McnFeedsReduser";
import McnMonitoringFilterReduser from "./mcnMonitoring/McnMonitoringFilterReduser";
import McnMonitoringReduser from "./mcnMonitoring/McnMonitoringReduser";
import YoutubeVideosShadowReducer from "./youtubeVideosShadow/YoutubeVideosShadowReducer";
import ChannelMonitoringYoutubeVideosReducer from "./youtubeVideosWebhook/ChannelMonitoringYoutubeVideosReducer";
import YoutubeVideosWebhookFilterReducer from "./youtubeVideosWebhook/YoutubeVideosWebhookFilterReducer";
import YoutubeVideosWebhookReducer from "./youtubeVideosWebhook/YoutubeVideosWebhookReducer";

export const youtubeMcnRootReducer = {
  // Mcn Monitoring
  mcnMonitoring: new McnMonitoringReduser().reducer,
  mcnMonitoringFilter: new McnMonitoringFilterReduser().reducer,

  //MCN Feeds
  mcnFeeds: new McnFeedsReduser().reducer,
  mcnFeedsFilter: new McnFeedsFilterReduser().reducer,
  //ChannelAnalytics
  channelAnalytics: new ChannelAnalyticsReducer().reducer,
  channelAnalyticsFilter: new ChannelAnalyticsFilterReducer().reducer,

  //ChannelAnalytics Account
  channelAnalyticsAccount: new ChannelAnalyticsAccountReducer().reducer,
  channelAnalyticsAccountFilter: new ChannelAnalyticsAccountFilterReduser()
    .reducer,
  youtubeVideosWebhook: new YoutubeVideosWebhookReducer().reducer,
  youtubeVideosWebhookFilter: new YoutubeVideosWebhookFilterReducer().reducer,
  channelMonitoringYoutubeVideos: new ChannelMonitoringYoutubeVideosReducer()
    .reducer,

  youtubeVideosShadow: new YoutubeVideosShadowReducer().reducer,
};
