import BaseReducer from "../../../../utilities/BaseReducer";
import NetRevenueByMonthAction from "./NetRevenueByMonthAction";

export default class NetRevenueByMonthFilterReducer extends BaseReducer {
  initialState = {
    netRevenueByMonthFilter: [],
  };
  [NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      netRevenueByMonthFilter: action.payload,
    };
  }
}
