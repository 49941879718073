import environment from "environment";
import QuoraAccountGetModel from "./models/QuoraAccountGetModel";
import QuoraAccountPutModel from "./models/QuoraAccountPutModel";
import QuoraAccountPostModel from "./models/QuoraAccountPostModel";
import QuoraAccountDeleteModel from "./models/QuoraAccountDeleteModel";
import EffectUtility from "../../../utilities/EffectUtility";

export default class QuoraAccountEffect {
  // Get method for the quora account account
  static async requestQuoraAccount(params) {
    const endpoint = environment.api.quoraAccount;
    return EffectUtility.getToModel(QuoraAccountGetModel, endpoint, params);
  }

  // Get method for the quora account account filter
  static async requestQuoraAccountFilter(params) {
    const endpoint = environment.api.quoraAccount;
    return EffectUtility.getToModel(QuoraAccountGetModel, endpoint, params);
  }

  // put method for the quora account account filter
  static async requestPutQuoraAccount(data, id) {
    const endpoint = environment.api.quoraAccount + "/" + id;
    return EffectUtility.putToModel(QuoraAccountPutModel, endpoint, data);
  }

  // post method for the quora account account filter
  static async requestPostQuoraAccount(data) {
    const endpoint = environment.api.quoraAccount + "/add";

    return EffectUtility.postToModel(QuoraAccountPostModel, endpoint, data);
  }

  static async requestDeleteQuoraAccount(id) {
    const endpoint = environment.api.quoraAccount + "/" + id;

    return EffectUtility.deleteToModel(QuoraAccountDeleteModel, endpoint);
  }
}
