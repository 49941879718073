import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import LinkedInAccountDeleteModel from "./models/LinkedInAccountDeleteModel";
import LinkedInAccountModel from "./models/LinkedInAccountGetModel";
import LinkedInAccountPostModel from "./models/LinkedInAccountPostModel";
import LinkedInAccountPutModel from "./models/LinkedInAccountPutModel";

export default class LinkedInAccountEffect {
  // calling an API according to the LinkedIn Account model

  // Get method for the LinkedIn account
  static async requestLinkedInAccount(params) {
    const endpoint = environment.api.linkedInAccount;
    return EffectUtility.getToModel(LinkedInAccountModel, endpoint, params);
  }

  // Get method for the LinkedIn account filter
  static async requestLinkedInAccountFilter(params) {
    const endpoint = environment.api.linkedInAccount;
    return EffectUtility.getToModel(LinkedInAccountModel, endpoint, params);
  }

  // put method for the LinkedIn account filter
  static async requestPutLinkedInAccount(data, id) {
    const endpoint = environment.api.linkedInAccount + "/" + id;
    return EffectUtility.putToModel(LinkedInAccountPutModel, endpoint, data);
  }

  // post method for the LinkedIn account filter
  static async requestPostLinkedInAccount(data) {
    const endpoint = environment.api.linkedInAccount + "/add";

    return EffectUtility.postToModel(LinkedInAccountPostModel, endpoint, data);
  }

  static async requestDeleteLinkedInAccount(id) {
    const endpoint = environment.api.linkedInAccount + "/" + id;

    return EffectUtility.deleteToModel(LinkedInAccountDeleteModel, endpoint);
  }
}
