import ActionUtility from "../../../../utilities/ActionUtility";
import MailLogsEffect from "./MailLogsEffect";

export default class MailLogsAction {
  // MailLogsAction action with an function declaration
  static REQUEST_MAIL_LOGS = "MailLogsAction.REQUEST_MAIL_LOGS";
  static REQUEST_MAIL_LOGS_FINISHED =
    "MailLogsAction.REQUEST_MAIL_LOGS_FINISHED";

  // METHODS
  static requestMailLogs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MailLogsAction.REQUEST_MAIL_LOGS,
        MailLogsEffect.requestMailLogs,
        params
      );
    };
  }

  // static requestSessionLogFilter(params) {
  //   return async (dispatch, getState) => {
  //     await ActionUtility.createThunkEffect(
  //       dispatch,
  //       SessionLogAction.REQUEST_SESSION_LOG_FILTER,
  //       SessionLogEffect.requestSessionLogFilter,
  //       params
  //     );
  //   };
  // }
}
