import { BaseModel } from "sjs-base-model";

// PageRoleGetModel Model
export default class PagePermissionGetModel extends BaseModel {
  // response getting from the api mentioned end point
  allowed_pages = [];
  default_dashboard = "";
  constructor(data1) {
    super();

    this.update(data1);
  }
}
