import BatchLinksFilterReducer from "./batchLinks/BatchLinkFilterReducer";
import BatchLinkStatusByIdReducer from "./batchLinks/BatchLinkStatusReducer";
import BatchLinksReducer from "./batchLinks/BatchLinksReducer";
import ArchivedLinkFilterReducer from "./linkCheckin/ArchivedLinkFilterReducer";
import ArchivedLinkReducer from "./linkCheckin/ArchivedLinkReducer";
import LinkCheckPlatformReducer from "./linkCheckin/LinkCheckPlatformReducer";
import LinkCheckingFilterReducer from "./linkCheckin/LinkCheckingFilterReducer";
import LinkCheckingReducer from "./linkCheckin/LinkCheckingReducer";
import CustomiseProxyReducer from "./proxy/CustomiseProxyReducer";
import ProxyAccountInfoReducer from "./proxy/ProxyAccountInfoReducer";
import ProxyCountriesReducer from "./proxy/ProxyCountriesReducer";
import LinkStatusReduser from "./linkStatus/LinkStatusReduser";
import LinkStatusFilterReduser from "./linkStatus/LinkStatusFilterReduser";
import LinkTransferSiteDataFilterReduser from "./linkTransferSitedata/LinkTransferSiteDataFilterReduser";
import LinkTransferSiteDataReduser from "./linkTransferSitedata/LinkTransferSiteDataReduser";
import CmsUploadLinksReducer from "./cmsUploadLinks/CmsUploadLinksReducer";
import CmsUploadLinksFilterReducer from "./cmsUploadLinks/CmsUploadLinksFilterReducer";
import CmsLinkStatusLogReducer from "./cmsLinkStatusLog/CmsLinkStatusLogReducer";
import LinkCheckPlatformFilterReducer from "./linkCheckin/LinkCheckPlatformFilterReducer";
import CmsProcessingStatusReducer from "./cmsUploadLinks/CmsProcessingStatusReducer";

export const linkCheckingRootReducer = {
  linkChecking: new LinkCheckingReducer().reducer,
  linkCheckingFilter: new LinkCheckingFilterReducer().reducer,
  linkCheckPlatforms: new LinkCheckPlatformReducer().reducer,
  linkCheckPlatformsFilter: new LinkCheckPlatformFilterReducer().reducer,

  // archived links
  archivedLinks: new ArchivedLinkReducer().reducer,
  archivedLinksFilter: new ArchivedLinkFilterReducer().reducer,

  // batchLinks
  batchLinks: new BatchLinksReducer().reducer,
  batchLinksFilter: new BatchLinksFilterReducer().reducer,
  batchLinksStatusByIdReport: new BatchLinkStatusByIdReducer().reducer,

  // Proxy Countries
  proxyCountries: new ProxyCountriesReducer().reducer,

  customiseProxy: new CustomiseProxyReducer().reducer,
  proxyAccountInfo: new ProxyAccountInfoReducer().reducer,
  linkStatus: new LinkStatusReduser().reducer,
  linkStatusFilter: new LinkStatusFilterReduser().reducer,
  LinkTransferSiteData: new LinkTransferSiteDataReduser().reducer,
  LinkTransferSiteDataFilter: new LinkTransferSiteDataFilterReduser().reducer,

  //cms links
  CmsUploadLinks: new CmsUploadLinksReducer().reducer,
  CmsUploadLinksFilter: new CmsUploadLinksFilterReducer().reducer,

  CmsLinkStatusLog: new CmsLinkStatusLogReducer().reducer,
  cmsProcessingStatus: new CmsProcessingStatusReducer().reducer,
};
