import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import PinterestAccountDeleteModel from "./model/PinterestAccountDeleteModel";
import PinterestAccountModel from "./model/PinterestAccountModel";
import PinterestAccountPutModel from "./model/PinterestAccountPutModel";

export default class PintersetAccontEffect {
  // Get method for the Pinterest account
  static async requestPinterestAccount(params) {
    const endpoint = environment.api.pinterestAccount;
    return EffectUtility.getToModel(PinterestAccountModel, endpoint, params);
  }

  // put method for the Pinterest account filter
  static async requestPutPinterestAccount(data, id) {
    const endpoint = environment.api.pinterestAccount + "/" + id;
    return EffectUtility.putToModel(PinterestAccountPutModel, endpoint, data);
  }

  static async requestDeletePinterestAccount(id) {
    const endpoint = environment.api.pinterestAccount + "/" + id;

    return EffectUtility.deleteToModel(PinterestAccountDeleteModel, endpoint);
  }
}
