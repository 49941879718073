import ActionUtility from "../../../../utilities/ActionUtility";
import LanguageEffect from "./LanguageEffect";

export default class LanguageAction {
  // LanguageAction action with an function declaration
  static REQUEST_LANGUAGE = "LanguageAction.REQUEST_LANGUAGE";
  static REQUEST_LANGUAGE_FINISHED = "LanguageAction.REQUEST_LANGUAGE_FINISHED";

  static REQUEST_LANGUAGE_FILTER = "LanguageAction.REQUEST_LANGUAGE_FILTER";
  static REQUEST_LANGUAGE_FILTER_FINISHED =
    "LanguageAction.REQUEST_LANGUAGE_FILTER_FINISHED";

  // METHODS
  // Language GET function
  static requestLanguage(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LanguageAction.REQUEST_LANGUAGE,
        LanguageEffect.requestLanguage,
        params
      );
    };
  }

  static requestLanguageFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LanguageAction.REQUEST_LANGUAGE_FILTER,
        LanguageEffect.requestLanguageFilter,
        params
      );
    };
  }
}
