import environment from "environment";
import ChatBotPostModel from "./models/ChatBotPostModel";
import ChatBotGetModel from "./models/ChatBotGetModel";
import EffectUtility from "../../utilities/EffectUtility";

export default class ChatBotEffect {
  static async requestGetChat(params, callback) {
    const endpoint = environment.api.chat;
    let response = EffectUtility.getToModel(ChatBotGetModel, endpoint, params);
    return response;
  }

  static async requestPostChat(data, params, callback) {
    const endpoint = environment.api.chat;
    let response = EffectUtility.postToModel(
      ChatBotPostModel,
      endpoint,
      data,
      params
    );
    return response;
  }
}
