import ActionUtility from "../../../utilities/ActionUtility";
import YoutubeEffect from "./YoutubeEffect";

export default class YoutubeAction {
  // Youtube feeds get requests
  static REQUEST_YOUTUBE = "YoutubeAction.REQUEST_YOUTUBE";
  static REQUEST_YOUTUBE_FINISHED = "YoutubeAction.REQUEST_YOUTUBE_FINISHED";

  //Youtube feeds by ID get requests
  static REQUEST_YOUTUBE_FEEDS_BY_ID =
    "YoutubeAction.REQUEST_YOUTUBE_FEEDS_BY_ID";
  static REQUEST_YOUTUBE_FEEDS_BY_ID_FINISHED =
    "YoutubeAction.REQUEST_YOUTUBE_FEEDS_BY_ID_FINISHED";

  // Youtube feeds filter get requests
  static REQUEST_YOUTUBE_FILTER = "YoutubeAction.REQUEST_YOUTUBE_FILTER";
  static REQUEST_YOUTUBE_FILTER_FINISHED =
    "YoutubeAction.REQUEST_YOUTUBE_FILTER_FINISHED";

  //youtube post reply
  static REQUEST_POST_REPLY = "YoutubeAction.REQUEST_POST_REPLY";
  static REQUEST_POST_REPLY_FINISHED =
    "YoutubeAction.REQUEST_POST_REPLY_FINISHED";

  static REQUEST_PUT_YOUTUBE_FEEDS = "YoutubeAction.REQUEST_PUT_YOUTUBE_FEEDS";
  static REQUEST_PUT_YOUTUBE_FEEDS_FINISHED =
    "YoutubeAction.REQUEST_PUT_YOUTUBE_FEEDS_FINISHED";

  static REQUEST_YOUTUBE_CLEAR = "YoutubeAction.REQUEST_YOUTUBE_CLEAR";
  static REQUEST_YOUTUBE_CLEAR_FINISHED =
    "YoutubeAction.REQUEST_YOUTUBE_CLEAR_FINISHED";

  // Action type for data post in youtube platform
  static REQUEST_YOUTUBE_DATA_POST = "YoutubeAction.REQUEST_YOUTUBE_DATA_POST";
  static REQUEST_YOUTUBE_DATA_POST_FINISHED =
    "YoutubeAction.REQUEST_YOUTUBE_DATA_POST_FINISHED";

  // METHODS

  // Youtube GET method
  static requestYoutube(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAction.REQUEST_YOUTUBE,
        YoutubeEffect.requestYoutube,
        params,
        callBackFunction
      );
    };
  }

  static async requestYoutubeClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAction.REQUEST_YOUTUBE_CLEAR,
        YoutubeEffect.requestYoutubeClear
      );
    };
  }

  //Youtube Feeds get by id method
  static requestYoutubeFeedById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAction.REQUEST_YOUTUBE_FEEDS_BY_ID,
        YoutubeEffect.requestYoutubeFeedById,
        id
      );
    };
  }

  //Youtube feeds get request for filter dropdown
  static requestYoutubeFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAction.REQUEST_YOUTUBE_FILTER,
        YoutubeEffect.requestYoutubeFilter,
        params
      );
    };
  }

  //Youtube reply post method
  static requestYoutubePostReply(id, params, data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAction.REQUEST_POST_REPLY,
        YoutubeEffect.requestYoutubeReplyPost,
        id,
        params,
        data,
        callback
      );
    };
  }

  static requestPutYoutubeFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAction.REQUEST_PUT_YOUTUBE_FEEDS,
        YoutubeEffect.requestPutYoutubeFeeds,
        data,
        id
      );
    };
  }

  static requestYoutubeDataPost(params, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAction.REQUEST_YOUTUBE_DATA_POST,
        YoutubeEffect.requestYoutubeDataPost,
        params,
        data
      );
    };
  }
}
