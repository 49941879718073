import BaseReducer from "../../../../utilities/BaseReducer";
import FingerPrintAssetAction from "./FingerPrintAssetAction";

export default class FingerPrintAssetReducer extends BaseReducer {
  initialState = {
    fingerPrintAsset: [],
  };
  [FingerPrintAssetAction.REQUEST_GET_ASSET_FINISHED](state, action) {
    return {
      ...state,
      fingerPrintAsset: action.payload,
    };
  }
}
