import BaseReducer from "../../utilities/BaseReducer";
import PageTaggedPostsAction from "./PageTaggedPostsAction";

export default class PageTaggedPostReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    pageTaggedPost: [],
  };

  //operation Filter
  [PageTaggedPostsAction.REQUEST_PAGE_TAGGED_POST_FINISHED](state, action) {
    return {
      ...state,
      pageTaggedPost: action.payload,
    };
  }
}
