import ActionUtility from "../../../../utilities/ActionUtility";
import AppStoreEffect from "./AppStoreEffect";

export default class AppStoreAction {
  // appstore action with an function declaration
  static REQUEST_APPSTORE = "AppStoreAction.REQUEST_APPSTORE";
  static REQUEST_APPSTORE_FINISHED = "AppStoreAction.REQUEST_APPSTORE_FINISHED";

  // appstore action with an function declaration
  static REQUEST_APPSTORE_FILTER = "AppStoreAction.REQUEST_APPSTORE_FILTER";
  static REQUEST_APPSTORE_FILTER_FINISHED =
    "AppStoreAction.REQUEST_APPSTORE_FILTER_FINISHED";

  static REQUEST_PUT_APPSTORE_FEEDS =
    "AppStoreAction.REQUEST_PUT_APPSTORE_FEEDS";
  static REQUEST_PUT_APPSTORE_FEEDS_FINISHED =
    "AppStoreAction.REQUEST_PUT_APPSTORE_FEEDS_FINISHED";

  static REQUEST_APPSTORE_CLEAR = "AppStoreAction.REQUEST_APPSTORE_CLEAR";
  static REQUEST_APPSTORE_CLEAR_FINISHED =
    "AppStoreAction.REQUEST_APPSTORE_CLEAR_FINISHED";

  // METHODS
  // AppStore GET function
  static requestAppStore(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAction.REQUEST_APPSTORE,
        AppStoreEffect.requestAppStore,
        params,
        callBackFunction
      );
    };
  }

  static requestAppStoreClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAction.REQUEST_APPSTORE_CLEAR,
        AppStoreEffect.requestAppStoreClear
      );
    };
  }

  static requestAppStoreFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAction.REQUEST_APPSTORE_FILTER,
        AppStoreEffect.requestAppStoreFilter,
        params
      );
    };
  }

  static requestPutAppStoreFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAction.REQUEST_PUT_APPSTORE_FEEDS,
        AppStoreEffect.requestPutAppStoreFeeds,
        data,
        id
      );
    };
  }
}
