import BaseReducer from "../../../utilities/BaseReducer";
import UserAction from "./UserAction";

export default class UserReducer extends BaseReducer {
  //initial state of user
  initialState = {
    userfilter: [],
  };

  //user request action finish
  [UserAction.REQUEST_USER_FILTER_FINISHED](state, action) {
    return {
      ...state,
      userfilter: action.payload,
    };
  }
}
