import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import OpportunityModel from "./model/OpportunityModel";
import OpportunityPostModel from "./model/OpportunityPostModel";
import OpportunityPutModel from "./model/OpportunityPutModel";

// OpportunityEffect
export default class OpportunityEffect {
  // calling an API according to the Opportunity model
  static async requesOpportunity(params) {
    const endpoint = environment.api.opportunity;
    return EffectUtility.getToModel(OpportunityModel, endpoint, params);
  }

  static async requesOpportunityClear() {
    return {};
  }

  static async requesOpportunityFilterClear() {
    return {};
  }

  // Get method for the Opportunity filter
  static async requestOpportunityFilter(params) {
    const endpoint = environment.api.opportunity;
    return EffectUtility.getToModel(OpportunityModel, endpoint, params);
  }

  // put method for the Opportunity
  static async requestPutOpportunity(data, id) {
    const endpoint = environment.api.opportunity + "/" + id;
    return EffectUtility.putToModel(OpportunityPutModel, endpoint, data);
  }

  // post method for the Opportunity
  static async requestPostOpportunity(data) {
    const endpoint = environment.api.opportunity + "/add";
    return EffectUtility.postToModel(OpportunityPostModel, endpoint, data);
  }
  static async requestDeleteOpportunity(id) {
    const endpoint = environment.api.opportunity + "/" + id;
    return EffectUtility.deleteToModel(OpportunityPostModel, endpoint);
  }
}
