import { Component } from "react";
import ActionUtility from "../../../../utilities/ActionUtility";
import ReplyEffect from "./ReplyEffect";

export default class ReplyActions extends Component {
  //TwitterDirectmessage requests
  static REQUEST_REPLY = "ReplyActions.REQUEST_REPLY";
  static REQUEST_REPLY_FINISHED = "ReplyActions.REQUEST_REPLY_FINISHED";

  //TwitterDirectmessage post
  static REQUEST_POST_REPLY = "ReplyActions.REQUEST_POST_REPLY";
  static REQUEST_POST_REPLY_FINISHED =
    "ReplyActions.REQUEST_POST_REPLY_FINISHED";

  //METHODS

  //TwitterDirectmessage get method
  static requestReply(id, params) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReplyActions.REQUEST_REPLY,
        ReplyEffect.requestReply,
        id,
        params
      );
    };
  }

  //TwitterDirectmessage post method
  static requestPostReply(id, params, data, callback, resolve, setLoading) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReplyActions.REQUEST_POST_REPLY,
        ReplyEffect.requestPostReply,
        id,
        params,
        data,
        callback,
        resolve,
        setLoading
      );
    };
  }
}
