import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Grid from "@mui/material/Grid";
import { Box, Button, IconButton } from "@mui/material";
import ReportLogoPostForm from "./ReportLogoPostForm";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ReportsLogoAction from "../../../stores/reportsLogo/ReportsLogoAction";
import OrganizationAction from "../../../common/stores/organization/OrganizationAction";
import RenderMedia from "../../../components/renderMedia/RenderMedia";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import MuiButton from "../../../components/muiButton/MuiButton";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReportsLogoAction.REQUEST_REPORTS_LOGO,
    ReportsLogoAction.REQUEST_REPORTS_LOGO_FILTER,
    OrganizationAction.REQUEST_ORGANIZATION_FILTER,
  ]),
  reportsLogo: state.reportsLogo.reportsLogo || [],
  reportsLogoFilter: state.reportsLogoFilter.reportsLogoFilter || [],
  pagination: state.reportsLogoFilter.reportsLogoFilter || [],
  organizationFilter: state.organizationFilter.organizationFilter || {},
});

function ReportsLogo(props) {
  const { isRequesting } = props;
  const [page_limit] = useState(15);
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [logoName, setLogoName] = useState(null);

  function fetchLogo() {
    const params = {
      order_by: `[["logo_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(ReportsLogoAction.requestReportsLogo(params));
  }

  function fetchReportsLogoFilter(params) {
    props.dispatch(ReportsLogoAction.requestReportsLogoFilter(params));
  }

  function fetchOrganizationFilter() {
    const param = {
      order_by: `[["organization_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(OrganizationAction.requestOrganizationFilter(param));
  }

  useEffect(() => {
    const param = {
      page_limit: page_limit,
      order_by: `[["modified_on", "desc"]]`,
    };

    fetchLogo();

    if (
      props?.reportsLogoFilter === undefined ||
      props?.reportsLogoFilter.length === 0
    ) {
      fetchReportsLogoFilter(param);
    }

    if (
      props?.organizationFilter === undefined ||
      props?.organizationFilter.length === 0
    ) {
      fetchOrganizationFilter();
    }
  }, []);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 100,
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "logo_name",
      headerName: "Logo Name",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "organization_name",
      headerName: "Organization",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "logo_image_url",
      headerName: "Image/Logo",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <RenderMedia image_url={params.value} mediaType="img" width={150} />
        );
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      resizable: false,
      width: 160,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  let organization_filter_array = [];
  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.map((item) => {
      return organization_filter_array.push({
        organization_id: item._id,
        organization_name: item._source.organization_name,
      });
    });
  }

  // const returnOrgName = (organization_id_from_rows) => {
  //   let filtered_org_name =
  //     organization_filter_array.length > 0 &&
  //     organization_filter_array.filter((val) => {
  //       return val.organization_id === organization_id_from_rows
  //         ? organization_id_from_rows
  //         : "";
  //     });

  //   return filtered_org_name[0]
  //     ? filtered_org_name[0].organization_name
  //     : "None";
  // };

  // Logo name dropdown list

  let logo_list = [];

  if (props.reportsLogo.hits) {
    props.reportsLogo.hits.hits &&
      props.reportsLogo.hits.hits.length > 0 &&
      props.reportsLogo.hits.hits.map((item) => {
        return logo_list.push({
          logo_id: item._id,
          logo_name: item._source.logo_name,
        });
      });
  }

  // datagrid rows
  const rows = [];
  if (
    props.reportsLogoFilter.hits &&
    props.reportsLogoFilter.hits.hits.length !== 0
  ) {
    props.reportsLogoFilter.hits.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        logo_id: item._id,
        logo_name: item._source.logo_name,
        organization_id: item._source.organization_id,
        organization_name: item._source.organization_id.organization_name,
        logo_image_url: item._source.logo_image_url,
        created_by: item._source.created_by
          ? item._source.created_by.user_name
          : null,
      });
    });
  }

  // Logo Dropdown Handle change
  const LogoNameHandle = (event, logoName) => {
    if (logoName !== null) {
      setLogoName(logoName);
    } else {
      setLogoName(null);
    }
  };

  const applyFilter = () => {
    const filter = [];

    if (logoName !== null) {
      filter.push(
        `["logo_name.keyword","must","","match","${logoName.logo_name}"]`
      );
    }

    if (logoName !== null) {
      const params = {
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      fetchReportsLogoFilter(params);
    } else {
      alert("Please add filters");
    }
  };

  const ClearState = () => {
    // Clear the state
    if (logoName !== null) {
      setLogoName(null);
    }
    setClearLoader(true);
    setFilterClicked(false);

    const params = {
      order_by: '[["modified_on", "desc"]]',
      page_limit: page_limit,
    };
    FilterClicked && fetchReportsLogoFilter(params);
  };

  const handleChange = (event, value) => {
    // page_number=1&page_limit=10
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (logoName !== null) {
      filter.push(
        `["logo_name.keyword","must","","match","${logoName.logo_name}"]`
      );
    }

    if (FilterClicked) {
      if (logoName !== null) {
        filter_params = {
          order_by: '[["modified_on", "desc"]]',
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      filter_params = {
        order_by: '[["modified_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchReportsLogoFilter(filter_params);
    }
  };

  return (
    <React.Fragment>
      {/* Filter */}

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={4}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <AutoCompleteComponent
            list={logo_list}
            value={logoName}
            textFieldProps={{
              placeholder: "Logo Name",
            }}
            getOptionLabel={(option) => option.logo_name}
            handleChange={LogoNameHandle}
            size="small"
            width="100%"
            disableClearable={true}
          />
        </Grid>
        <Grid item lg={6} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  loading={filterLoader && isRequesting}
                  name={"Filter"}
                  onClick={applyFilter}
                  width="100%"
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  loading={clearLoader && isRequesting}
                  name={"Clear"}
                  onClick={ClearState}
                  width="100%"
                />
              </Grid>
            </Grid>
            <Grid>
              {/* post button component*/}
              <PostPlusButton
                open={open}
                openCloseModal={openCloseModal}
                editMode={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ReportLogoPostForm
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        logoEditData={editRowsModel}
        edit={edit}
      />

      {/* data grid */}

      <Grid item lg={12} xl={12} md={12} sm={12} xs={12} pr={1}>
        {navigator.onLine ? (
          <LoadingIndicator isActive={props.isRequesting}>
            <Box>
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={120}
                pagelength={props.pagination.total_page_no}
                page={props.pagination.current_page_no}
                handleChange={handleChange}
              />
            </Box>
          </LoadingIndicator>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Grid>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(ReportsLogo);
