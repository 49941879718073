import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import WebformsGetModal from "./modals/WebformsGetModal";
import WebformsPostModal from "./modals/WebformsPostModal";

export default class WebformsEffect {
  static async requestWebforms(params, callback) {
    const endpoint = environment.api.webformsData;
    let response = EffectUtility.getToModel(WebformsGetModal, endpoint, params);
    return response;
  }

  static async requestWebformsCsvData(params, callback) {
    const endpoint = environment.api.webforms_csv_data;
    let response = EffectUtility.getToModel(WebformsGetModal, endpoint, params);
    return response;
  }

  static async requestWebformsCsvData(params, callback) {
    const endpoint = environment.api.webforms_csv_data;
    let response = EffectUtility.getToModel(WebformsGetModal, endpoint, params);

    response.then((resp) => {
      if (resp && callback) {
        callback(resp);
      }
    });
    return response;
  }

  static async requestWebformsFilter(params) {
    const endpoint = environment.api.webformsData;
    return EffectUtility.getToModel(WebformsGetModal, endpoint, params);
  }

  static async requestPostWebforms(data, callBackFunction) {
    const endpoint = environment.api.webformsData;
    let response = EffectUtility.postToModel(WebformsPostModal, endpoint, data);
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPutWebforms(data, id, callBackFunction) {
    const endpoint = environment.api.webformsData + `/${id}`;
    let response = EffectUtility.putToModel(WebformsPostModal, endpoint, data);
    return response;
  }

  static async requestPostCSVData(data, params, callBack) {
    const endpoint = environment.api.webforms_csv_data;
    let response = EffectUtility.postToModel(
      WebformsPostModal,
      endpoint,
      data,
      params
    );
    if (callBack)
      response.then((res) => {
        callBack(res);
      });

    return response;
  }
}
