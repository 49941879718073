import BaseReducer from "../../../utilities/BaseReducer";
import FaqAction from "./FaqAction";

export default class FaqModulesReducer extends BaseReducer {
  initialState = {
    faqModules: [],
  };
  [FaqAction.REQUEST_GET_FAQ_MODULES_FINISHED](state, action) {
    return {
      ...state,
      faqModules: action.payload,
    };
  }
}
