import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import SiteDataGetModel from "./model/SiteDataGetModel";
import SitedataPutModel from "./model/SitedataPutModel";
import SiteDataPostModel from "./model/SiteDataPostModel";

//LinkTransferSiteDataEffect
export default class LinkTransferSiteDataEffect {
  // calling an API according to theLinkChecking model
  static async requestLinkTransferSiteData(params) {
    const endpoint = environment.api.LinkTransferSiteData;
    return EffectUtility.getToModel(SiteDataGetModel, endpoint, params);
  }

  // Get method for theLinkChecking filter
  static async requestLinkTransferSiteDataFilter(params, callBack) {
    const endpoint = environment.api.LinkTransferSiteData;
    let response = EffectUtility.getToModel(SiteDataGetModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // put method for theLinkChecking
  static async requestPutLinkTransferSiteData(data, id) {
    const endpoint = environment.api.LinkTransferSiteData + "/" + id;
    return EffectUtility.putToModel(SitedataPutModel, endpoint, data);
  }

  // post method for theLinkChecking
  static async requestPostLinkTransferSiteData(data, params, callBack) {
    const endpoint = environment.api.LinkTransferSiteData;
    let response = EffectUtility.postToModel(
      SiteDataPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }
}
