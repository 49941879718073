import { BaseModel } from "sjs-base-model";
import { bool } from "yup";

export default class InfluencerDeviceMapGetModel extends BaseModel {
  _id = "";
  _index = "";
  _primary_term = 0;
  _seq_no = 0;
  _source = {};
  _version = 0;
  found = bool;

  constructor(data) {
    super();

    this.update(data);
  }
}
