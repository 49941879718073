import {
  Box,
  Grid,
  IconButton,
  ListItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import TicketOverviewTab from "./TicketOverviewTab";
import { connect } from "react-redux";
import TicketCustomerProfileTab from "./TicketCustomerProfileTab";
import CustomerPersonalDetailsTab from "./CustomerPersonalDetailsTab";
import TicketTimeLineTab from "./TicketTimeLineTab";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import { theme } from "../../../../../views/App";
import AISuggestionTab from "./AISuggestionTab";
import BardAISuggestionTab from "./BardAISuggestionTab";
import CustomTIcektsTab from "./CustomTIcektsTab";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [TicketsAction.REQUEST_TICKETS_BY_ID]),
  ticket: state.ticket.ticket || {},
  // tickets: state.tickets.tickets || {},
});

const TicketDetailsTabs = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabDetailsBoxRef = useRef(null);
  const [TabDetailBoxHeight, setTabDetailBoxHeight] = useState(0);

  useEffect(() => {
    const detailBoxHeight =
      TabDetailsBoxRef.current && TabDetailsBoxRef.current.offsetHeight;
    setTabDetailBoxHeight(detailBoxHeight);
  });

  useEffect(() => {}, [props.ticketData, props.related_tickets]);

  const {
    openAIsuggestionTab,
    setOpenAIsuggestionTab,
    aiSuggestionTab,
    setAiSuggestionTab,
  } = props;

  useEffect(() => {
    console.log("Tab_selected", aiSuggestionTab);

    if (openAIsuggestionTab) {
      if (
        JSON.parse(sessionStorage.getItem("ai_suggestion_feed_id"))?.tab ===
        "open_ai"
      )
        setValue(4);
      else setValue(5);
    }
  }, [openAIsuggestionTab, aiSuggestionTab]);

  return (
    <Box width={"100%"} height="100%" ref={TabDetailsBoxRef}>
      <Grid
        item
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          borderBottom: `0.5px solid ${theme.palette.background.border}`,
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          alignItems="center"
          justifyContent={"flex-start"}
          //   className="bg-success"
        >
          <Box sx={{ width: "100%", height: "40px" }}>
            <Box sx={{ overflowX: "auto", maxHeight: "40px" }}>
              <Tabs
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                aria-label="scrollable auto tabs"
                style={{
                  minWidth: "100%",
                  minHeight: 40,
                }}
                // className="bg-warning"
                sx={{
                  "& .MuiTabs-indicatorSpan": {
                    maxWidth: 20,
                    width: "80%",
                    backgroundColor: "#2a5e6c",
                  },
                  minWidth: "100%",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#85bac6", // This changes the background color of the selected tab scroller
                  },
                  "& .MuiTabs-flexContainer": {
                    position: "relative",
                    height: 40,
                    margin: 0, // remove margin from Tabs container
                    padding: 0, // remove padding from Tabs container
                    // height: 40,
                    // position: "relative",
                    // justifyContent: "flex-start",
                    // "&::before": {
                    //   content: '""',
                    //   position: "absolute",
                    //   bottom: "-5px",
                    //   left: 0,
                    //   width: "calc(100% + 10px)",
                    //   height: "5px",
                    //   backgroundColor: "#2196f3",
                    //   borderRadius: "0 0 0 10px",
                    //   zIndex: 0,
                    // },
                  },
                  "& .MuiTab-root": {
                    // zIndex: 0,
                    color: `${theme.palette.ticketListView.iconColor}`,
                    border: `1px solid transparent`,
                    backgroundColor: "transparent",
                    padding: 0,
                    margin: 0,
                    height: 40,

                    // backgroundColor: "#003a4c",
                    // height: "1vh",
                    "&.Mui-selected": {
                      // zIndex: 2,
                      //   backgroundColor: "#003a4c",
                      color: `#2a5e6c`,
                    },
                    "& .MuiTouchRipple-root": {
                      padding: 0,
                      margin: 0,
                    },
                  },
                }}
              >
                <Tab
                  label="Overview"
                  style={{
                    textTransform: "none",
                    minHeight: 0,
                  }}
                  //   {...a11yProps(0)}
                />
                <Tab
                  style={{
                    textTransform: "none",
                    minHeight: 0,
                  }}
                  label="User Profile"

                  //   {...a11yProps(1)}
                />
                <Tab
                  style={{
                    textTransform: "none",
                    minHeight: 0,
                  }}
                  label="User Details"

                  //   {...a11yProps(2)}
                />
                <Tab
                  style={{
                    textTransform: "none",
                    minHeight: 0,
                  }}
                  label="Ticket Log"
                />

                <Tab
                  style={{
                    textTransform: "none",
                    minHeight: 0,
                    display:
                      JSON.parse(
                        sessionStorage.getItem("ai_suggestion_feed_id")
                      )?.tab === "open_ai" && openAIsuggestionTab
                        ? null
                        : "none",
                  }}
                  label="Open AI Suggestion"
                />
                <Tab
                  style={{
                    textTransform: "none",
                    minHeight: 0,
                    display:
                      JSON.parse(
                        sessionStorage.getItem("ai_suggestion_feed_id")
                      )?.tab === "bard" && openAIsuggestionTab
                        ? null
                        : "none",
                  }}
                  label="Google AI Suggestion"
                />

                <Tab
                  label="Custom Fields"
                  style={{
                    textTransform: "none",
                    minHeight: 0,
                  }}
                  //   {...a11yProps(0)}
                />
              </Tabs>
            </Box>
          </Box>
        </Grid>
        {/* <Grid
          item
          xl={1}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          textAlign="right"
          alignItems={"center"}
          justifyContent="flex-end"
        >
          {props.showFeedDetails && (
            <IconButton
              onClick={() => {
                props.setShowDetailsBox(false);
              }}
            >
              <HighlightOffIcon style={{ width: "16px" }} />
            </IconButton>
          )}
        </Grid> */}
      </Grid>

      <Box height={`${TabDetailBoxHeight - 40}px`} width={"100%"}>
        {value === 0 && (
          <TicketOverviewTab
            ticketData={props.ticketData}
            filters={props.filters}
            pageNumber={props.pageNumber}
            customerData={props.customerData}
            statusList={props.statusList}
            fetchFunction={props.fetchFunction}
            filterprioritylist={props.filterprioritylist}
            userlist={props.userlist}
            onClose={props.onClose}
            ticket_count_filters={props.ticket_count_filters}
            page_limit={props.page_limit}
            page_length={props.page_length}
            related_tickets_ids={props.related_tickets_ids}
            filterTagList={props.filterTagList}
            setSelectedRows={props.setSelectedRows}
          />
        )}
        {value === 1 && (
          <TicketCustomerProfileTab
            customerData={props.customerData}
            ticketData={props.ticketData}
            filters={props.filters}
          />
        )}
        {value === 2 && (
          <CustomerPersonalDetailsTab
            // customerData={props.customerData}
            ticketData={props.ticketData}
            filters={props.filters}
            onClose={props.onClose}
            ticket_count_filters={props.ticket_count_filters}
            page_limit={props.page_limit}
            page_length={props.page_length}
            pageNumber={props.pageNumber}
          />
        )}
        {value === 3 && <TicketTimeLineTab ticketData={props.ticketData} />}
        {value === 4 && (
          <AISuggestionTab
            setOpenAIsuggestionTab={setOpenAIsuggestionTab}
            openAIsuggestionTab={openAIsuggestionTab}
            setAiSuggestionTab={setAiSuggestionTab}
            closeTab={() => {
              setValue(0);
            }}
          />
        )}
        {value === 5 && (
          <BardAISuggestionTab
            setOpenAIsuggestionTab={setOpenAIsuggestionTab}
            openAIsuggestionTab={openAIsuggestionTab}
            setAiSuggestionTab={setAiSuggestionTab}
            closeTab={() => {
              setValue(0);
            }}
          />
        )}

        {value === 6 && (
          <CustomTIcektsTab
            ticketData={props.ticketData}
            filters={props.filters}
            pageNumber={props.pageNumber}
            customerData={props.customerData}
            statusList={props.statusList}
            fetchFunction={props.fetchFunction}
            filterprioritylist={props.filterprioritylist}
            userlist={props.userlist}
            onClose={props.onClose}
            ticket_count_filters={props.ticket_count_filters}
            page_limit={props.page_limit}
            page_length={props.page_length}
            related_tickets_ids={props.related_tickets_ids}
            filterTagList={props.filterTagList}
            setSelectedRows={props.setSelectedRows}
          />
        )}
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(TicketDetailsTabs);
