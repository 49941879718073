import BaseReducer from "../../../../utilities/BaseReducer";
import WebsweepxReportsAction from "./WebsweepxReportsAction";

export default class WebsweepxTopSitesReduser extends BaseReducer {
  initialState = {
    topSitestobeAutomated: [],
  };
  [WebsweepxReportsAction.REQUEST_GET_TOPSITESTOBEAUTOMATED_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      topSitestobeAutomated: action.payload,
    };
  }
}
