import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import "./cssFiles/TatOverview.css";
import { endDate, startDate } from "./dateFunctions/DateFunctions";
import { format } from "date-fns";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const TatOverview = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [today, setToday] = useState(new Date());

  let responseData = [];

  let current_month = new Date();

  let current_month_end_date = endDate(current_month);

  //  before_previous_month
  let before_twelvth_month = new Date();
  before_twelvth_month.setMonth(before_twelvth_month.getMonth() - 11);

  let before_twelvth_month_start_date = startDate(before_twelvth_month);

  async function fetchData(from, to) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["tat_ticket"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;
    try {
      const response = await axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const data = await response;

      responseData.push(data);

      setData([...responseData]);
    } catch (error) {}
  }

  const returnMonth = (month) => {
    let dateObj = new Date(month);
    let options = { year: "numeric", month: "short" };
    let formattedDate = dateObj.toLocaleDateString("default", options);
    return formattedDate;
  };

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      fetchData(before_twelvth_month_start_date, current_month_end_date);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);

        fetchData(before_twelvth_month_start_date, current_month_end_date);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 11,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);

        if (props.openDateFilter) {
          fetchData(new Date(firstDateString), new Date(endDateString));
        } else {
          fetchData(before_twelvth_month_start_date, current_month_end_date);
        }

        setLoading(false);
      })();

      props.setFilterClicked(false);
    }
  }, [props.openDateFilter, props.filterClicked]);

  useEffect(() => {
    if (props.DownloadClicked) {
      let exportData = [];

      data[0].ticket_report.tat_ticket?.map((item) => {
        exportData.push({
          Months: returnMonth(item.key),
          Resolved: item.count ? prefixZero_for_singleDigit(item.count) : "00",
          TAT: item.tat_value ? HoursAndMinutes(item.tat_value) : "00:00:00",
        });
      });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, data]);

  return (
    <LoadingIndicator
      isActive={loading}
      style={{
        height: "100%",
      }}
    >
      {!loading ? (
        data && (
          <Grid
            container
            item
            lg={12}
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            {data &&
              data[0] &&
              data[0].ticket_report &&
              data[0].ticket_report.tat_ticket
                .slice(0, 4)
                .map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      justifyContent={"center"}
                      alignItems="center"
                      xl={2.9}
                      lg={2.9}
                      md={2.9}
                      xs={2.9}
                      sm={2.9}
                      // textAlign="center"
                      position="relative"
                      sx={{
                        borderRadius: "4px",
                        // border: `solid 1px #e5e2e2`,
                        height: 127,
                        backgroundColor:
                          index % 2 !== 0 ? "#f8fafa" : "#f2f6f7",
                        opacity: index % 2 !== 0 ? 0.9 : 1,
                      }}
                    >
                      <div>
                        <small
                          style={{
                            fontSize: 14,
                          }}
                        >
                          <p
                            style={{
                              color: "#386b78",
                            }}
                            className="font-weight-bold "
                          >
                            {returnMonth(item.key)}
                          </p>

                          <span>{`Resolved  ${prefixZero_for_singleDigit(
                            item.count
                          )}`}</span>
                          <br />
                          <span>{`TAT  ${HoursAndMinutes(
                            item.tat_value
                          )}`}</span>
                        </small>
                      </div>
                    </Grid>
                  );
                })}

            {data &&
              data[0] &&
              data[0].ticket_report &&
              data[0].ticket_report.tat_ticket
                .slice(4, 8)
                .map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      justifyContent={"center"}
                      alignItems="center"
                      xl={2.9}
                      lg={2.9}
                      md={2.9}
                      xs={2.9}
                      sm={2.9}
                      // textAlign="center"
                      position="relative"
                      sx={{
                        borderRadius: "4px",
                        // border: `solid 1px #e5e2e2`,
                        height: 127,
                        backgroundColor:
                          index % 2 === 0 ? "#f8fafa" : "#f2f6f7",
                        opacity: index % 2 === 0 ? 0.9 : 1,
                      }}
                    >
                      <div>
                        <small
                          style={{
                            fontSize: 14,
                          }}
                        >
                          <p
                            style={{
                              color: "#386b78",
                            }}
                            className="font-weight-bold "
                          >
                            {returnMonth(item.key)}
                          </p>

                          <span>{`Resolved  ${prefixZero_for_singleDigit(
                            item.count
                          )}`}</span>
                          <br />
                          <span>{`TAT  ${HoursAndMinutes(
                            item.tat_value
                          )}`}</span>
                        </small>
                      </div>
                    </Grid>
                  );
                })}
            {data &&
              data[0] &&
              data[0].ticket_report &&
              data[0].ticket_report.tat_ticket
                .slice(8, 12)
                .map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      justifyContent={"center"}
                      alignItems="center"
                      xl={2.9}
                      lg={2.9}
                      md={2.9}
                      xs={2.9}
                      sm={2.9}
                      // textAlign="center"
                      position="relative"
                      sx={{
                        borderRadius: "4px",
                        // border: `solid 1px #e5e2e2`,
                        height: 127,
                        backgroundColor:
                          index % 2 !== 0 ? "#f8fafa" : "#f2f6f7",
                        opacity: index % 2 !== 0 ? 0.9 : 1,
                      }}
                    >
                      <div>
                        <small
                          style={{
                            fontSize: 14,
                          }}
                        >
                          <p
                            style={{
                              color: "#386b78",
                            }}
                            className="font-weight-bold "
                          >
                            {returnMonth(item.key)}
                          </p>

                          <span>{`Resolved  ${prefixZero_for_singleDigit(
                            item.count
                          )}`}</span>
                          <br />
                          <span>{`TAT  ${HoursAndMinutes(
                            item.tat_value
                          )}`}</span>
                        </small>
                      </div>
                    </Grid>
                  );
                })}
          </Grid>
        )
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
};
export default TatOverview;
