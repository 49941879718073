import BaseReducer from "../../../../utilities/BaseReducer";
import LeadAction from "./LeadAction";

// LeadReduser
export default class LeadReducer extends BaseReducer {
  initialState = {
    lead: [],
  };
  [LeadAction.REQUEST_LEAD_FINISHED](state, action) {
    return {
      ...state,
      lead: action.payload,
    };
  }

  [LeadAction.REQUEST_LEAD_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      lead: action.payload,
    };
  }
}
