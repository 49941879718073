import React, { forwardRef } from "react";
import { LocalizationProvider, LicenseInfo } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import "./dateRangePicker.css";
import { theme } from "../../views/App";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import MuiTextField from "../muiTextField/MuiTextField";
import dayjs from "dayjs";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const DateRangeStyled = forwardRef(
  ({
    value,
    onChange,
    onKeyDown,
    open,
    maxDate,
    disableFuture,
    disablePast,
  }) => {
    // Define a custom theme for the picker

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateRangePicker"]}>
          <DateRangePicker
            value={value && [dayjs(value[0]), dayjs(value[1])]}
            onChange={onChange}
            open={open}
            disableFuture={disableFuture}
            disablePast={disablePast}
            format="DD/MM/YYYY"
            slots={{
              textField: MuiTextField,
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
                size: "small",
                label: "",
                style: {
                  outline: `1px ${theme.palette.borderColor.textField}`,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "8px",
                },
              },
            }}
          />
        </DemoContainer>
        {/* <DateRangePicker
          // inputFormat="YYYY-MM-DD"
          value={value}
          open={open}
          onChange={onChange}
          maxDate={maxDate && maxDate}
          PopperProps={{
            sx: {
              "& .MuiPaper-root": {
                "& .MuiPickersArrowSwitcher-root": {
                  backgroundColor: "#d0dfe3",
                  padding: 0,
                },
              },
              "& .MuiDateRangePickerViewDesktop-root": {
                backgroundColor: "#f3f9fb",
                border: "1px solid rgba(0, 0, 0, 0)",
                fontSize: 10,
                color: "#39717c",
              },
            },
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField
                {...startProps}
                label=""
                size="small"
                sx={{
                  ".MuiOutlinedInput-root": {
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "8px",
                  },
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                // onKeyDown={(event) => {
                //   if (event.key === "Backspace") {
                //     // event.preventDefault();
                //   }
                //   if (onKeyDown) {
                //     onKeyDown(event);
                //   }
                // }}
              />
              <Box sx={{ mx: 0.5 }}> to </Box>
              <TextField
                {...endProps}
                label=""
                size="small"
                sx={{
                  ".MuiOutlinedInput-root": {
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "8px",
                  },
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                // onKeyDown={(event) => {
                //   if (event.key === "Backspace") {
                //     // event.preventDefault();
                //   }
                //   if (onKeyDown) {
                //     onKeyDown(event);
                //   }
                // }}
              />
            </React.Fragment>
          )}
        /> */}
      </LocalizationProvider>
    );
  }
);

export default DateRangeStyled;
