import ActionUtility from "../../../../utilities/ActionUtility";
import ReleasesShadowTableEffect from "./ReleasesShadowTableEffect";

export default class ReleasesShadowTableAction {
  // ReleasesShadowTableAction action with an function declaration
  static REQUEST_RELEASES_SHADOW_TABLE =
    "ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE";
  static REQUEST_RELEASES_SHADOW_TABLE_FINISHED =
    "ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_FINISHED";

  static REQUEST_RELEASES_SHADOW_TABLE_CLEAR =
    "LabelAction.REQUEST_RELEASES_SHADOW_TABLE_CLEAR";
  static REQUEST_RELEASES_SHADOW_TABLE_CLEAR_FINISHED =
    "LabelAction.REQUEST_RELEASES_SHADOW_TABLE_CLEAR_FINISHED";

  static REQUEST_RELEASES_SHADOW_TABLE_BY_ID =
    "ReleasesAction.REQUEST_RELEASES_SHADOW_TABLE_BY_ID";
  static REQUEST_RELEASES_SHADOW_TABLE_BY_ID_FINISHED =
    "ReleasesAction.REQUEST_RELEASES_SHADOW_TABLE_BY_ID_FINISHED";

  // METHODS
  // ReleasesShadowTableAction GET function
  static requestReleasesShadowTable(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE,
        ReleasesShadowTableEffect.requestReleasesShadowTable,
        params
      );
    };
  }

  static requestReleasesShadowTableClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_CLEAR,
        ReleasesShadowTableEffect.requestReleasesShadowTableClear
      );
    };
  }

  static requestReleasesShadowTableById(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_BY_ID,
        ReleasesShadowTableEffect.requestReleasesShadowTableById,
        params
      );
    };
  }
}
