import ScrapeLinksFilterReduser from "./scrapeLinks/ScrapeLinksFilterReduser";
import ScrapeLinksReduser from "./scrapeLinks/ScrapeLinksReduser";
import WebsiteLinksFilterReduser from "./websitelinksGridView/WebsiteLinksFilterReduser";
import WebsiteLinksReduser from "./websitelinksGridView/WebsiteLinksReduser";

export const siteLinkDiscoveryRootReducer = {
  websiteLinks: new WebsiteLinksReduser().reducer,
  websiteLinksFilter: new WebsiteLinksFilterReduser().reducer,

  // scrapeLinks
  scrapeLinks: new ScrapeLinksReduser().reducer,
  scrapeLinksFilter: new ScrapeLinksFilterReduser().reducer,
};
