import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import LeadSourceModel from "./model/LeadSourceModel";
import LeadSourcePostModel from "./model/LeadSourcePostModel";
import LeadSourcePutModel from "./model/LeadSourcePutModel";

// LeadSourceEffect
export default class LeadSourceEffect {
  // calling an API according to the LeadSource model
  static async requesLeadSource(params) {
    const endpoint = environment.api.lead_source;
    return EffectUtility.getToModel(LeadSourceModel, endpoint, params);
  }

  static async requesLeadSourceClear() {
    return {};
  }

  static async requesLeadSourceFilterClear() {
    return {};
  }

  // Get method for the LeadSource filter
  static async requestLeadSourceFilter(params) {
    const endpoint = environment.api.lead_source;
    return EffectUtility.getToModel(LeadSourceModel, endpoint, params);
  }

  // put method for the LeadSource
  static async requestPutLeadSource(data, id) {
    const endpoint = environment.api.lead_source + "/" + id;
    return EffectUtility.putToModel(LeadSourcePutModel, endpoint, data);
  }

  // post method for the LeadSource
  static async requestPostLeadSource(data) {
    const endpoint = environment.api.lead_source + "/add";
    return EffectUtility.postToModel(LeadSourcePostModel, endpoint, data);
  }
  static async requestDeleteLeadSource(id) {
    const endpoint = environment.api.lead_source + "/" + id;
    return EffectUtility.deleteToModel(LeadSourcePostModel, endpoint);
  }
}
