import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import OrganizationModel from "./model/OrganizationModel";
import OrganizationPostModel from "./OrganizationPostModel";
import OrganizationPutModel from "./OrganizationPutModel";

// OrganizationEffect
export default class OrganizationEffect {
  // calling an API according to the Organization model
  // Get method for the
  static async requestOrganization(params) {
    const endpoint = environment.api.organization;
    return EffectUtility.getToModel(OrganizationModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestOrganizationFilter(params) {
    const endpoint = environment.api.organizationFilter;
    return EffectUtility.getToModel(OrganizationModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutOrganization(data, id) {
    const endpoint = environment.api.organization + "/" + id;
    return EffectUtility.putToModel(OrganizationPutModel, endpoint, data);
  }

  // post method for the Organization
  static async requestPostOrganization(data) {
    const endpoint = environment.api.organization + "/add";

    return EffectUtility.postToModel(OrganizationPostModel, endpoint, data);
  }
}
