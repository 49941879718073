import { BaseModel } from "sjs-base-model";

export default class MentionsCustomerAndAccountNames extends BaseModel {
  account_names = [];
  customer_names = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
