import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import AiSuggestionPostModel from "./models/AiSuggestionPostModel";
import BardAiSuggestionPostModel from "./models/BardAiSuggestionPostModel";

// AiSuggestionEffect
export default class AiSuggestionEffect {
  static async requestAiSuggestion(data, params, callback) {
    const endpoint = environment.api.aiSuggestion;

    let response = EffectUtility.postToModel(
      AiSuggestionPostModel,
      endpoint,
      data,
      params,
      callback
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestBardAiSuggestion(data, params, callback) {
    const endpoint = environment.api.bardAiSuggestion;

    let response = EffectUtility.postToModel(
      BardAiSuggestionPostModel,
      endpoint,
      data,
      params,
      callback
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }
}
