import React, { useEffect, useState } from "react";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";

function TicketClouserTatBreach(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  async function fetchData(dateRange) {
    const cancelToken = axios.CancelToken.source();

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["reportOn_tat_breach"]${
      props.DateFilter ? `&from=${dateRange[0]}&to=${dateRange[1]}` : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        return setData(response.data);
      })
      .catch((err) => {
        datadogLogs.logger.error(err.message, err);
        throw new Error(err);
      });
  }

  let tableList = [];

  data &&
    data.ticket_report &&
    data.ticket_report.reportOn_tat_breach.map((item) => {
      tableList.push({
        name: item.key,
        count: item.count,
      });
    });

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        // padding: "20px",
        // border: "1px solid grey",
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} />
        <Typography
          style={{
            fontSize: theme.reports.headerSize,
            padding: theme.reports.headerPadding,
          }}
        >
          {firstLetterOrange(props.report && props.report)}
        </Typography>
        <hr className="p-0 m-0 " />
        <Box style={{ width: "100%", padding: "12px" }}>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              borderRadius: theme.table.borderRadius,
            }}
          >
            {tableList.length > 0 && (
              <table
                style={{
                  width: "100%",
                  border: `1px solid ${theme.table.border}`,
                  textAlign: "left",
                }}
                border="2"
              >
                <tbody style={{ width: "100%" }}>
                  <tr
                    style={{
                      border: `1px solid ${theme.table.border}`,
                      color: `${theme.palette.text.primary}`,
                      backgroundColor: `${theme.palette.background.primary}`,
                    }}
                  >
                    <th className="pl-5">Name</th>
                    <th className="pl-5">Count</th>
                  </tr>

                  {tableList &&
                    tableList.map((value, index) => (
                      <tr
                        style={{
                          backgroundColor:
                            index % 2 === 0
                              ? `${theme.table.rowColor}`
                              : "white",
                          color: `${theme.table.rowText}`,
                          border: `1px solid ${theme.table.border}`,
                        }}
                      >
                        <td className="pl-5">{value.name}</td>
                        <td className="pl-5">{value.count}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </Box>

        {window.location.pathname === "/ticketStatsReport" && (
          <Description
            description={props.description}
            callBack={props.callBack}
            report={props.report}
            index={0}
          />
        )}
      </LoadingIndicator>
    </Box>
  );
}

export default TicketClouserTatBreach;
