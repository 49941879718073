import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import CloudSegmentDataModel from "./models/CloudSegmentDataModel";

// segment criteria Effect
export default class CloudSegmentCriteriaEffect {
  // Get method for the
  static async requestCloudMailSegment(params) {
    const endpoint = environment.api.segmentCriteria;
    return EffectUtility.getToModel(CloudSegmentDataModel, endpoint, params);
  }
}
