import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, Checkbox, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useLocation } from "react-router";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import OrganizationAction from "../../stores/organization/OrganizationAction";
import UserRoleMappingAction from "../../stores/userRoleMapping/UserRoleMappingAction";
import UserAction from "../../../ormTicketing/stores/user/UserAction";
import ProjectAction from "../../../ormTicketing/stores/project/projectAction";
import ManageRoleAction from "../../components/manageRole/ManageRoleAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { FullName } from "../../../components/ReusedFunctions";
import PostUserRoleMapping from "./PostUserRoleMapping";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    OrganizationAction.REQUEST_ORGANIZATION_FILTER,
    UserRoleMappingAction.REQUEST_POST_USER_ROLE_MAPPING,
    UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING,
    UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING_FILTER,
    UserAction.REQUEST_USER_FILTER,
    ProjectAction.REQUEST_PROJECT_FILTER,
    ManageRoleAction.REQUEST_ROLE_FILTER,
  ]),
  pagination: state.userRoleMapping.userRoleMapping || {},
  organizationFilter: state.organizationFilter.organizationFilter || {},
  userRoleMapping: state.userRoleMapping.userRoleMapping || [],
  userRoleMappingFilter:
    state.userRoleMappingFilter.userRoleMappingFilter || [],
  userfilter: state.userfilter.userfilter || [],
  projectfilter: state.projectfilter.projectfilter || [],
  roleFilter: state.roleFilter.roleFilter || [],
});

function UserRoleMappingList(props) {
  const { isRequesting } = props;

  const [user_name, setUser_name] = useState(null);
  let { state } = useLocation();

  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [roleName, SetRoleName] = useState([]);
  const [FilterClicked, setFilterClicked] = useState(false);

  const [open, setOpen] = useState(
    state ? state.from === "/addRole" && true : false
  );

  function fetchOrganizationFilter() {
    const param = {
      order_by: '[["modified_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(OrganizationAction.requestOrganizationFilter(param));
  }

  function fetchUserRoleMapping(params) {
    props.dispatch(UserRoleMappingAction.requestUserRoleMapping(params));
  }

  function fetchUserRoleMappingFilter() {
    const params = {
      order_by: '[["modified_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(UserRoleMappingAction.requestUserRoleMappingFilter(params));
  }

  function fetchUserFilter() {
    const param = {
      order_by: '[["modified_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  function fetchProjectFilter() {
    const params = {
      page_limit: "none",
    };
    props.dispatch(ProjectAction.requestProjectFilter(params));
  }

  function fetchRoleFilter() {
    const filter = [];

    filter.push(`[["is_template","must","","term","false"]]`);

    const params = {
      page_limit: "none",
      filters: `${filter}`,
    };
    props.dispatch(ManageRoleAction.requestRoleFilter(params));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    const param = {
      order_by: '[["modified_on", "desc"]]',
      page_limit: `${page_limit}`,
    };

    // if (
    //   props?.organizationFilter === undefined ||
    //   props?.organizationFilter.length === 0
    // ) {
    fetchOrganizationFilter();
    // }

    // if (
    //   props?.userRoleMappingFilter === undefined ||
    //   props?.userRoleMappingFilter.length === 0
    // ) {
    fetchUserRoleMappingFilter();
    // }

    // if (props?.userfilter === undefined || props?.userfilter.length === 0) {
    fetchUserFilter();
    // }

    // if (
    //   props?.projectfilter === undefined ||
    //   props?.projectfilter.length === 0
    // ) {
    fetchProjectFilter();
    // }

    // if (props?.roleFilter === undefined || props?.roleFilter.length === 0) {
    fetchRoleFilter();
    // }

    const params = {
      order_by: '[["modified_on", "desc"]]',
      page_limit: `${page_limit}`,
    };
    fetchUserRoleMapping(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter function for user_name
  const applyFilter = () => {
    const filter = [];
    if (user_name && user_name.user_name !== null) {
      let user_id = userId_function(user_name && user_name.user_name);
      filter.push(`["user_id.keyword","must","","match","${user_id}"]`);
    }

    if (roleName && roleName.length !== 0) {
      filter.push(
        `["roles","must","nested","",["role_name.keyword","must","","terms",${JSON.stringify(
          roleName
        )}]]`
      );
    }

    if (
      (user_name && user_name.user_name !== null) ||
      (roleName && roleName.length !== 0)
    ) {
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      const params = {
        filters: `[${filter}]`,
        order_by: '[["created_on", "desc"]]',
        page_limit: `${page_limit}`,
      };

      // props.dispatch(UserRoleMappingAction.requestUserRoleMapping(params));
      fetchUserRoleMapping(params);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    if (
      (user_name && user_name.user_name !== null) ||
      (roleName && roleName.length !== 0)
    ) {
      setClearLoader(true);
      SetRoleName([]);
      setUser_name(null);
      setFilterClicked(false);
      !FilterClicked && setClearLoader(false);
    }

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
    };

    FilterClicked && fetchUserRoleMapping(params);

    // props.dispatch(UserRoleMappingAction.requestUserRoleMapping(params));
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (user_name && user_name.user_name !== null) {
      let user_id = userId_function(user_name && user_name.user_name);
      filter.push(`["user_id.keyword","must","","match","${user_id}"]`);
    }

    if (roleName && roleName.length !== 0) {
      filter.push(
        `["roles","must","nested","",["role_name.keyword","must","","terms",${JSON.stringify(
          roleName
        )}]]`
      );
    }

    if (FilterClicked) {
      if (
        (user_name && user_name.user_name) ||
        (roleName && roleName.length !== 0)
      ) {
        filter_params = {
          order_by: '[["created_on", "desc"]]',
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchUserRoleMapping(filter_params);
    }

    // props.dispatch(UserRoleMappingAction.requestUserRoleMapping(filter_params));
  };

  let organization_array = [];
  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.map((item) => {
      return organization_array.push({
        organization_id: item._id,
        organization_name: item._source.organization_name,
      });
    });
  }

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "userRole_unique_id",
      headerName: "ID",
      width: 200,
      hide: true,
    },

    {
      field: "user_name",
      headerName: "User Name",
      width: 250,
      flex: 3,
    },

    {
      field: "user_object",
      headerName: "User_obj",
      width: 250,
      flex: 3,
      hide: true,
    },

    {
      field: "mapped_organization_name",
      headerName: "Organization",
      width: 250,
      flex: 3,
      resizable: false,
    },
    {
      field: "mapped_organization",
      headerName: "organization_array",
      width: 250,
      resizable: false,
      hide: true,
    },

    {
      field: "roles_names",
      headerName: "Role - Project",
      width: 250,
      flex: 5,
      resizable: false,
    },

    {
      field: "roles",
      headerName: "Roles_array",
      width: 250,
      flex: 5,
      resizable: false,
      hide: true,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 2,
      resizable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      resizable: false,
      width: 160,
      flex: 2,
      align: "center",

      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEditRowsModel(params.row);
            setEdit(true);

            setTimeout(() => {
              setOpen(true);
            }, 500);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  let postUserList = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((val) => {
      return postUserList.push({
        user_id: val.id,
        user_name: val.username,
        org_id: val.attributes.organization_id
          ? val.attributes.organization_id[0]
          : "",
        fullName: FullName(val),
      });
    });
  }

  let userlist = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((val) => {
      return userlist.push({
        user_id: val.id,
        user_name: val.username,
        org_id: val.attributes.organization_id
          ? val.attributes.organization_id[0]
          : "",
        fullName: FullName(val),
      });
    });
  }

  function username_function(id) {
    let user_name = userlist.filter((val) => {
      return val.user_id === id ? id : "";
    });
    return user_name[0] ? user_name[0].user_name : "";
  }

  function userFullName(id) {
    let user_name = userlist.filter((val) => {
      return val.user_id === id ? id : "";
    });
    return user_name[0] ? user_name[0].fullName : "";
  }

  function userId_function(name) {
    let user_name = userlist.filter((val) => {
      return val.user_name === name ? name : "";
    });
    return user_name[0] ? user_name[0].user_id : "";
  }

  function userObj_function(id) {
    let user_name = userlist.filter((val) => {
      return val.user_id === id ? id : "";
    });
    return user_name[0] ? user_name[0] : "";
  }

  // function project_name_function(id) {
  //   if (id === "*") {
  //     return "all";
  //   } else {
  //     let p_name = project_array.filter((val) => {
  //       return val.project_id === id;
  //     });
  //     return p_name[0] ? p_name[0].project_name : "";
  //   }
  // }

  function orgname_function(id) {
    if (id === "*") {
      return "for all users";
    } else {
      let o_name = organization_array.filter((val) => {
        return val.organization_id === id ? id : "";
      });
      return o_name[0] ? o_name[0].organization_name : "";
    }
  }

  let project_array = [];

  if (props.projectfilter.hits) {
    props.projectfilter.hits.hits.map((item) => {
      return project_array.push({
        project_id: item._id,
        project_name: item._source.project_name,
        org_id: item._source.organization_id,
      });
    });
  }

  const rows = [];
  if (props.userRoleMapping.hits) {
    props.userRoleMapping.hits.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        userRole_unique_id: item._id,
        mapped_organization_name: orgname_function(
          item._source.organization_id ? item._source.organization_id : ""
        ),
        // mapped_organization: item._source.organization,
        roles: item._source.roles ? item._source.roles : [],
        roles_names: item._source.roles
          ? item._source.roles.map((item) => {
              return `${item.role_name} - ${item.project_id.project_name}   `;
            })
          : null,
        user_name: username_function(
          item._source.user_id ? item._source.user_id : ""
        ),
        user_object: userObj_function(
          item._source.user_id ? item._source.user_id : ""
        ),
        created_by: item._source.created_by.user_name,
      });
    });
  }

  let roleList = [];

  if (props.roleFilter.hits) {
    props.roleFilter.hits.hits.forEach((val) => {
      roleList.push({
        role_id: val._id,
        role_name: val._source.role_name,
        project_id: val._source.project_id.project_id,
        organization_id: val._source.organization_id?.organization_id,
      });
    });
  }

  roleList = [...new Set(roleList)];

  // user_name call back
  const onClintHandleChange = (event, value) => {
    setUser_name(value);
  };

  const roleNamesHandleChange = (event, value) => {
    if (value.length != 0) {
      SetRoleName(value);
    } else {
      SetRoleName([]);
    }
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  let UserList_names = [];
  if (props.userfilter && props.userfilter.data) {
    props.userfilter.data.map((item) => {
      return UserList_names.push({
        user_name: item.username,
        fullName: userFullName(item.id),
      });
    });
  }

  UserList_names.sort((a, b) => a?.fullName.localeCompare(b));
  UserList_names = [...new Set(UserList_names)];

  let role_names = [];
  if (props.roleFilter.hits) {
    props.roleFilter.hits.hits.map((item) => {
      role_names.push(item._source.role_name);
    });
  }

  let role_names_filter_List = [];
  role_names_filter_List = [...new Set(role_names)];

  role_names_filter_List.sort((a, b) => a.localeCompare(b));
  role_names_filter_List = [...new Set(role_names_filter_List)];

  // project_array.push({ project_id: "*", project_name: "All" });

  //  datagrid rows

  return (
    <React.Fragment>
      {/* Filter */}
      <Box
        className="scrollable"
        style={{
          height: "100%",
          maxHeight: `${window.innerHeight}px`,
          overflow: "auto",
          position: "relative",
        }}
      >
        <PostUserRoleMapping
          open={open}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          projectEditData={editRowsModel}
          edit={edit}
          project_array={project_array}
          postUserList={postUserList}
          roleList={roleList}
          setEdit={setEdit}
        />
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          display="flex"
          justifyContent="space-between"
          my={1}
        >
          <Grid item lg={6} md={7} sm={7} container p={1}>
            <Grid item lg={4.5} md={5} sm={5} p={1} mt={0}>
              <AutoCompleteComponent
                value={user_name}
                textFieldProps={{
                  placeholder: "User",
                }}
                isOptionEqualToValue={(option, value) =>
                  option.fullName === value.fullName
                }
                list={UserList_names}
                getOptionLabel={(option) => {
                  return option.user_name !== ""
                    ? `${option.fullName} (${option.user_name})`
                    : "";
                }}
                handleChange={onClintHandleChange}
                size="small"
                width="100%"
              />
            </Grid>
            <Grid
              item
              lg={4.6}
              md={5}
              sm={5}
              p={1}
              mb={6}
              style={{ position: "relative" }}
              alignItems="flex-start"
              justifyContent="center"
            >
              <Grid
                item
                xl={12}
                style={{ position: "absolute", width: "95%", zIndex: 999 }}
              >
                <AutoCompleteComponent
                  value={roleName}
                  textFieldProps={{
                    placeholder: roleName.length > 0 ? null : "Roles",
                  }}
                  limitTags={1}
                  disableCloseOnSelect={true}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  list={role_names_filter_List}
                  handleChange={roleNamesHandleChange}
                  size="small"
                  multiple
                  width="100%"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={7} sm={7} container p={1}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={1}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                lg={8}
                sm={8}
                md={8}
                display={"flex"}
                justifyContent={"end"}
                mt={1}
                mr={2}
                sx={{ gap: 2 }}
              >
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    loading={filterLoader && isRequesting}
                    name={"Filter"}
                    width="100%"
                    onClick={applyFilter}
                  />
                </Grid>
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    width="100%"
                    loading={clearLoader && isRequesting}
                    name={"Clear"}
                    onClick={ClearState}
                  />
                </Grid>
              </Grid>
              <Grid>
                {/* post button component*/}
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* project post modal screen */}

        {/* ***************************************************************  user filter */}

        {/* data grid */}

        <Grid item lg={8} md={12} sm={12} xs={12} pr={1}>
          {/* loading icon */}
          <LoadingIndicator isActive={isRequesting}>
            {!isRequesting && props.userRoleMapping.hits ? (
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={60}
                height="75vh"
                pagelength={props.pagination.total_page_no}
                page={props.pagination.current_page_no}
                handleChange={handleChange}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      userRole_unique_id: false,
                      user_object: false,
                      mapped_organization: false,
                      roles: false,
                    },
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "75vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Grid>

        {/* Pagination */}
        {/* <Grid
        display="flex"
        justifyContent="center"
        container
        item
        lg={10}
        md={12}
        sm={12}
        // className="pagination_section"
      >
        {" "}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {props.userRoleMapping.hits
            ? props.userRoleMapping.hits.length !== 0 && (
                <PaginationUI
                  pagelength={props.pagination.total_page_no}
                  page={props.pagination.current_page_no}
                  handleChange={handleChange}
                />
              )
            : null}
        </Grid>
      </Grid> */}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(UserRoleMappingList);
