import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeDataExtractionAction from "./YoutubeDataExtractionAction";

export default class DataExtractionProjectsReducer extends BaseReducer {
  initialState = {
    dataExtractionProjects: [],
  };

  [YoutubeDataExtractionAction.REQUEST_DATAEXTRACTION_PROJECTS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      dataExtractionProjects: action.payload,
    };
  }
}
