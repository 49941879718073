import BaseReducer from "../../../../utilities/BaseReducer";
import QuestionsAction from "./QuestionsAction";

export default class QuestionReducer extends BaseReducer {
  initialState = {
    questions: [],
  };

  [QuestionsAction.REQUEST_QUESTIONS_FINISHED](state, action) {
    return {
      ...state,
      questions: action.payload,
    };
  }
}
