import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import YoutubeOriginalModel from "./model/YoutubeOriginalModel";
// import YoutubeOriginalPutModel from "./model/YoutubeOriginalPutModel";

// StatusEffect
export default class YoutubeOriginalEffect {
  // calling an API according to the status model
  // Get method for YoutubeTrackClaims
  static async requestYoutubeTrackClaims(params) {
    const endpoint = environment.api.youtubeTrackClaims;
    let response = EffectUtility.getToModel(
      YoutubeOriginalModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestYoutubeTrackClaimsClear() {
    return {};
  }

  static async requestYoutubeAFPReports(params) {
    const endpoint = environment.api.youtubeAFPReports;
    let response = EffectUtility.getToModel(
      YoutubeOriginalModel,
      endpoint,
      params
    );
    return response;
  }

  // static async requestYoutubeChannelRevenueClear() {
  //   return {};
  // }

  // static async requestYoutubeChannelRevenue(params) {
  //   const endpoint = environment.api.youtubeChannelRevenue;
  //   return EffectUtility.getToModel(
  //     YoutubeChannelRevenueModel,
  //     endpoint,
  //     params
  //   );
  // }
}
