import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { Button, createFilterOptions, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import MailTiggerListAction from "../../stores/triggerLIst/MailTiggerListAction";
import TriggerPost from "./TriggerPost";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    MailTiggerListAction.REQUEST_TRIGGER_LIST,
    MailTiggerListAction.REQUEST_TRIGGERLIST_FILTER,
  ]),
  trigger_list: state.trigger_list.trigger_list.hits || [],
  trigger_list_filter: state.trigger_list_filter.trigger_list_filter || {},
  pagination: state.trigger_list.trigger_list || {},
});

function MailTriggerLIst(props) {
  const { isRequesting } = props;
  const [triggerName, settriggerName] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchTriggerList() {
    const param = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
    };
    props.dispatch(MailTiggerListAction.requestTriggerList(param));
  }

  function fetchTriggerListFilter() {
    const param = {
      order_by: `[["trigger_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(MailTiggerListAction.requestTriggerListFilter(param));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    fetchTriggerList();

    // if (
    //   props?.trigger_list_filter === undefined ||
    //   props?.trigger_list_filter.length === 0
    // ) {
    fetchTriggerListFilter();
    // }
  }, []);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (triggerName !== null) {
      filter.push(
        `["trigger_name.keyword","must","","match","${triggerName}"]`
      );
    }

    if (triggerName !== null && FilterClicked) {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(MailTiggerListAction.requestTriggerList(filter_params));
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "trigger_unique_id",
      headerName: "trigger ID",
      width: 200,
      hide: true,
    },

    {
      field: "trigger_id",
      headerName: "trigger ID",
      width: 200,
      hide: true,
    },

    {
      field: "trigger_name",
      headerName: "Trigger Name",
      width: 250,
      flex: 4,
    },

    {
      field: "service_name",
      headerName: "Service Name",
      width: 250,
      flex: 5,
      resizable: false,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
      resizable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      resizable: false,
      width: 160,
      align: "center",

      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEditRowsModel(params.row);
            setEdit(true);
            setOpen(true);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const rows = [];
  if (props.trigger_list.hits) {
    props.trigger_list.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        trigger_unique_id: item._id,
        trigger_name: item._source.trigger_name,
        created_by: item._source.created_by.user_name,
        service_name: item._source.service_name,
        trigger_id: item._source.trigger_id,
      });
    });
  }

  const triggerName_handlechange = (event, value) => {
    if (value != null) {
      settriggerName(value);
    } else {
      settriggerName(null);
    }
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // Filter function for Client
  const applyFilter = () => {
    const filter = [];
    if (triggerName !== null) {
      filter.push(
        `["trigger_name.keyword","must","","match","${triggerName}"]`
      );
    }

    if (triggerName !== null) {
      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      props.dispatch(MailTiggerListAction.requestTriggerList(params));
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    if (triggerName !== null) {
      setClearLoader(true);
      settriggerName(null);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: 15,
    };
    FilterClicked &&
      props.dispatch(MailTiggerListAction.requestTriggerList(params));
  };

  let triggerNameList = [];
  if (props.trigger_list_filter.hits) {
    props.trigger_list_filter.hits.hits.map((item) => {
      return triggerNameList.push(item._source.trigger_name);
    });
  }

  triggerNameList.sort((a, b) => a.localeCompare(b));
  triggerNameList = [...new Set(triggerNameList)];

  let platform_array = [];

  //  datagrid rows

  return (
    <React.Fragment>
      {/* Filter */}

      <TriggerPost
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        projectEditData={editRowsModel}
        platform_array={platform_array.length > 0 ? platform_array : null}
        edit={edit}
      />

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={2}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <AutoCompleteComponent
            value={triggerName}
            textFieldProps={{
              placeholder: "Trigger Name",
            }}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: (option) => option,
            })}
            list={triggerNameList}
            handleChange={triggerName_handlechange}
            size="small"
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Filter"}
                  width="100%"
                  onClick={applyFilter}
                  loading={filterLoader && isRequesting}
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Clear"}
                  width="100%"
                  onClick={ClearState}
                  loading={clearLoader && isRequesting}
                />
              </Grid>
            </Grid>
            <Grid>
              {/* post button component*/}
              <PostPlusButton
                open={open}
                openCloseModal={openCloseModal}
                editMode={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* ***************************************************************  user filter */}

      {/* data grid */}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* loading icon */}
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting && (
            <DataGridFunction
              columns={columns}
              rows={rows}
              rowHeight={60}
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    trigger_unique_id: false,
                    trigger_id: false,
                  },
                },
              }}
            />
          )}
        </LoadingIndicator>
      </Grid>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(MailTriggerLIst);
