import { BaseModel } from "sjs-base-model";
import PrioritySourceModel from "./PrioritySourceModel";

export default class PriorityHitsDataModel extends BaseModel {
  //priority list hits data model
  _id = "";
  _index = "";
  _score = "";
  _source = PrioritySourceModel;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
