import BaseReducer from "../../../../utilities/BaseReducer";
import WebformsAction from "./WebformsAction";

export default class WebformsFilterReduser extends BaseReducer {
  initialState = {
    webformsFilterData: [],
  };
  [WebformsAction.REQUEST_WEBFORMS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      webformsFilterData: action.payload,
    };
  }
}
