import BaseReducer from "../../utilities/BaseReducer";
import CustomTicketFieldsAction from "./CustomTicketFieldsAction";

// category reducer function
export default class CustomTicketFields extends BaseReducer {
  initialState = {
    customticketfields_filter: [],
  };

  [CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      customticketfields_filter: action.payload,
    };
  }
}
