import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import NvProjectGetModal from "./modals/NvProjectGetModal";
import NvProjectPostModal from "./modals/NvProjectPostModal";
import PropertywiseContentUrlModel from "./modals/PropertywiseContentUrlModel";

export default class NvProjectEffect {
  static async requestNvProject(params, callback) {
    const endpoint = environment.api.websweepNvProject;
    let response = EffectUtility.getToModel(
      NvProjectGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetExecteScript_propertyWise(data, callBackFunction) {
    const endpoint = environment.api.executeScriptsPropertywise;
    let response = EffectUtility.postToModel(
      NvProjectPostModal,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestNvProjectFilter(params) {
    const endpoint = environment.api.websweepNvProject;
    return EffectUtility.getToModel(NvProjectGetModal, endpoint, params);
  }

  static async requestPostNvProject(data, callBackFunction) {
    const endpoint = environment.api.websweepNvProject;
    let response = EffectUtility.postToModel(
      NvProjectPostModal,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }
  static async requestPostgoogleSearchExtractor(data, callBackFunction) {
    const endpoint = environment.api.googleSearchExtractor;
    let response = EffectUtility.postToModel(
      NvProjectPostModal,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPutNvCategory(data, id, callBackFunction) {
    const endpoint = environment.api.websweepNvProject + `/${id}`;
    let response = EffectUtility.putToModel(NvProjectPostModal, endpoint, data);
    return response;
  }

  static async requestSyncCMSProperties(id) {
    const endpoint = environment.api.websweepxCMSPropertiesMapping + `/${id}`;
    let response = EffectUtility.putToModel(NvProjectPostModal, endpoint);
    return response;
  }

  static async requestPropertyWiseContentUrls(params, callback) {
    const endpoint = environment.api.websweepxPropertyWiseContentUrls;
    let response = EffectUtility.getToModel(
      PropertywiseContentUrlModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.status === "success" && callback) {
        callback(resp);
      }
    });
    return response;
  }

  static async requestPostExecuteContentUrlWise(data) {
    const endpoint = environment.api.websweepxExecuteContentUrlWise;
    let response = EffectUtility.postToModel(
      NvProjectPostModal,
      endpoint,
      data
    );

    return response;
  }
}
