import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencerDeviceMappingAction from "./InfluencerDeviceMappingAction";

export default class InfluencerDeviceResetReducer extends BaseReducer {
  initialState = {
    influencerDeviceMapping: [],
  };

  [InfluencerDeviceMappingAction.REQUEST_INFLUENCER_DEVICE_MAPPING_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencerDeviceMapping: action.payload,
    };
  }
}
