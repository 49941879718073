import BaseReducer from "../../../utilities/BaseReducer";
import ResponseMessageAction from "./ResponseMessageAction";

// reducer function
export default class ResponseMessageReducer extends BaseReducer {
  initialState = {
    responseMessage: [],
  };

  [ResponseMessageAction.REQUEST_RESPONSE_MESSAGE_FINISHED](state, action) {
    return {
      ...state,
      responseMessage: action.payload,
    };
  }
}
