import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { endDate, monthNames, startDate } from "../dateFunctions/DateFunctions";
import { format } from "date-fns";
import { Bubble } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Logo from "../logoContainer/Logo";
import Description from "../descriptionContainer/Description";
import { Box, Typography } from "@mui/material";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoRadialBarChart } from "../../../../components/nivoCharts/NivoRadialBarChart";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import { firstLetterOrange } from "../../../../components/ticketPartials";
import { prefixZero_for_singleDigit } from "../../../../components/ticketPartials";
const HourlylatformResolvedChart = (props) => {
  Chart.register(CategoryScale);

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  let firstDay = new Date(
    props.filterValue && new Date(props.filterValue).getFullYear(),
    props.filterValue && new Date(props.filterValue).getMonth(),
    1
  );

  let lastDay = new Date(
    props.filterValue && new Date(props.filterValue).getFullYear(),
    props.filterValue && new Date(props.filterValue).getMonth() + 1,
    0
  );

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter).then((e) => {
        setData(e);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter).then((e) => {
          setData(e);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter).then((e) => {
          setData(e);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["resolution_time_split_wise"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;
    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });
  }

  function returnPlatformName(platformID) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformID)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  let colors = ["#fc8d72", "#22bcba", "#cae5e1", "#b8c7c0"];

  let rows = [];

  if (
    data &&
    data.ticket_report &&
    data.ticket_report.resolution_time_split_wise
  ) {
    data.ticket_report.resolution_time_split_wise.forEach((item, index) => {
      if (
        item &&
        Object.keys(item)[0] &&
        returnPlatformName(Object.keys(item)[0]) &&
        returnPlatformName(Object.keys(item)[0])[0]
      ) {
        const row = {
          id: index + 1,
          platform:
            returnPlatformName(Object.keys(item)[0]) &&
            returnPlatformName(Object.keys(item)[0])[0],
          resolvedIn8Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "Resolved_in_8_Hours")
            .map((item) => item.count)[0],
          resolvedIn8to12Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "Resolved_in_8-12_Hours")
            .map((item) => item.count)[0],
          resolvedIn12to24Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "Resolved_in_12-24_Hours")
            .map((item) => item.count)[0],
          resolvedMoreThan24Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "More_Than_24_Hours")
            .map((item) => item.count)[0],
          totalCount: item[Object.keys(item)[0]]
            .filter((value) => value.key === "total_count")
            .map((item) => item.count)[0],
          total_Resolved_in_8_Hours:
            data &&
            data.ticket_report &&
            data.ticket_report.resolution_time_split_wise.slice(
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length - 1,
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length
            )[0].total[0].Resolved_in_8_Hours,
          total_Resolved_in_8_12_Hours:
            data &&
            data.ticket_report &&
            data.ticket_report.resolution_time_split_wise.slice(
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length - 1,
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length
            )[0].total[0]["Resolved_in_8-12_Hours"],
          total_Resolved_in_12_24_Hours:
            data &&
            data.ticket_report &&
            data.ticket_report.resolution_time_split_wise.slice(
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length - 1,
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length
            )[0].total[0]["Resolved_in_12-24_Hours"],
          total_Resolved_After_24_Hours:
            data &&
            data.ticket_report &&
            data.ticket_report.resolution_time_split_wise.slice(
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length - 1,
              data &&
                data.ticket_report &&
                data.ticket_report.resolution_time_split_wise.length
            )[0].total[0]["More_Than_24_Hours"],
        };

        rows.push(row);
      }
    });
  }

  rows &&
    rows.sort((a, b) => {
      const platformA = a.platform.toLowerCase();
      const platformB = b.platform.toLowerCase();

      if (platformA < platformB) {
        return -1;
      }

      if (platformA > platformB) {
        return 1;
      }

      return 0;
    });

  const reportsColors = {
    positive: "#468c9d",
    neutral: "#92c4d0",
    negative: "#fc8d72",
    total: "#2a5e6c",
    color1: "#E2E2E2",
    color2: "#CAE5E1",
    color3: "#FABBAC",
    color4: "#B4D5DD",
    color5: "#468C9D",
    color6: "#A4D9D1",
    color7: "#42808E",
    headerSize: "18px",
    gridBorder: "#e5e2e2",
  };

  function getColorForDataPoint(value) {
    const colorKeys = Object.keys(reportsColors).filter(
      (key) => key !== "headerSize" && key !== "gridBorder"
    );
    const colorIndex = value % colorKeys.length; // Get the index for alternating colors
    const color = reportsColors[colorKeys[colorIndex]];
    return color;
  }

  const chartData = [
    {
      id: "Resolved In 8 Hrs",
      data: rows
        .map((item) => ({
          x: item.platform,
          y: item.resolvedIn8Hours,
        }))
        ?.sort((a, b) => a.y - b.y),
    },
    {
      id: "Resolved In 8-12 Hrs",
      data: rows
        .map((item) => ({
          x: item.platform,
          y: item.resolvedIn8to12Hours,
        }))
        ?.sort((a, b) => a.y - b.y),
    },
    {
      id: "Resolved In 12-24 Hrs",
      data: rows
        .map((item) => ({
          x: item.platform,
          y: item.resolvedIn12to24Hours,
        }))
        ?.sort((a, b) => a.y - b.y),
    },
    {
      id: "Resolved After 24 Hrs",
      data: rows
        .map((item) => ({
          x: item.platform,
          y: item.resolvedMoreThan24Hours,
        }))
        ?.sort((a, b) => a.y - b.y),
    },
    {
      id: "Total Resolved",
      data: rows
        .map((item) => ({
          x: item.platform,
          y: item.totalCount,
        }))
        ?.sort((a, b) => a.y - b.y),
    },
  ];

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData?.push({
          Platforms: item?.platform,
          ["In 8 Hrs"]: prefixZero_for_singleDigit(item?.resolvedIn8Hours),
          ["8-12 Hrs"]: prefixZero_for_singleDigit(item.resolvedIn8to12Hours),
          ["12-24 Hrs"]: prefixZero_for_singleDigit(item.resolvedIn12to24Hours),
          ["After 24 Hrs"]: prefixZero_for_singleDigit(
            item.resolvedMoreThan24Hours
          ),
          ["Total"]: prefixZero_for_singleDigit(item.totalCount),
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rows]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        rows && rows.length !== 0 ? (
          <NivoRadialBarChart
            chartData={chartData}
            colors={[
              `${theme.platform.color1}`,
              `${theme.platform.color2}`,
              `${theme.platform.color3}`,
              `${theme.platform.color4}`,
              `${theme.platform.color5}`,
              `${theme.platform.color6}`,
              `${theme.platform.color7}`,
              `${theme.platform.color8}`,
              `${theme.platform.color9}`,
              `${theme.platform.color10}`,
              `${theme.platform.color11}`,
              `${theme.platform.color12}`,
              `${theme.platform.color13}`,
              `${theme.platform.color14}`,
              `${theme.platform.color15}`,
              `${theme.platform.color16}`,
              `${theme.platform.color17}`,
              `${theme.platform.color18}`,
              `${theme.platform.color19}`,
              `${theme.platform.color20}`,
            ]}
            margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
          />
        ) : (
          <NoDataFound />
        )
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
};

export default HourlylatformResolvedChart;
