import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { endDate, monthNames, startDate } from "../dateFunctions/DateFunctions";
import { format } from "date-fns";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Logo from "../logoContainer/Logo";
import Description from "../descriptionContainer/Description";
import { Box, Typography } from "@mui/material";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoLineChart } from "../../../../components/nivoCharts/NivoLineChart";
import { NivoRadarChart } from "../../../../components/nivoCharts/NivoRadarChart";
import { firstLetterOrange } from "../../../../components/ticketPartials";

const PlatformWiseOverallSentimentChart = (props) => {
  Chart.register(CategoryScale);

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);

  // this month
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);
  let before_previous_month_start_date = startDate(before_previous_month);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData(
        before_previous_month_start_date,
        current_month_end_date
      ).then((e) => {
        setReportData(e);
      });

      setLoading(false);
    })();
  }, []);

  async function fetchData(from, to) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${
    //     environment.api.ticketReports
    //   }?report_type=["count"]&reports=["platform"]&from=${format(
    //     from,
    //     "yyyy-MM-dd"
    //   )}&to=${format(to, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(async (response) => {
    //     return response;
    //   })
    //   .catch((err) => {});
  }

  let monthList = [];
  reportData &&
    reportData.ticket_report &&
    reportData.ticket_report.platform &&
    reportData.ticket_report.platform.length > 0 &&
    reportData.ticket_report.platform[0].monthwise?.buckets.length > 0 &&
    reportData.ticket_report.platform[0].monthwise?.buckets.map((month) => {
      monthList.push(month.key_as_string);
    });

  function platformName(platformId) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformId)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  let platforms = [];
  let negative_count = [];
  let positive_count = [];
  let neutral_count = [];

  reportData &&
    reportData.ticket_report &&
    reportData.ticket_report.platform &&
    reportData.ticket_report.platform.length > 0 &&
    reportData.ticket_report.platform
      .slice(0, reportData.ticket_report.platform.length - 6)
      .map((platformData) => {
        platforms.push(platformName(platformData.platform));
        negative_count.push(platformData.negative_count);
        positive_count.push(platformData.positive_counts);
        neutral_count.push(platformData.neutral_counts);
      });

  let MaxValue =
    Math.max(...negative_count) +
    Math.max(...positive_count) +
    Math.max(...neutral_count);

  function getDigit(value) {
    let digit = 1;

    for (let i = 0; value >= 10; i++) {
      value /= 10;
      digit *= 10;
    }

    return digit;
  }

  const digit = getDigit(MaxValue);
  let num = parseInt(MaxValue / digit);

  let updatedDigit = num * digit;

  let stepSize = updatedDigit / 10;

  const remainder = MaxValue % stepSize;

  const difference = stepSize - remainder;

  MaxValue += difference;

  const state = {
    labels: platforms,
    datasets: [
      {
        label: "Positive(+ve)",
        backgroundColor: `${theme.reports.positive}`,
        borderWidth: 1.5,
        borderColor: `${theme.reports.positive}`,
        data: positive_count,
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 4,
      },
      {
        label: "Negative(-ve)",
        backgroundColor: `${theme.reports.negative}`,
        data: negative_count,
        borderWidth: 1.5,
        borderColor: `${theme.reports.negative}`,
        lineTension: 0.3,
        pointStyle: "triangle",
        pointRadius: 5,
      },
      {
        label: "Neutral(∅)",
        backgroundColor: `${theme.reports.neutral}`,
        data: neutral_count,
        borderWidth: 1.5,
        borderColor: `${theme.reports.neutral}`,
        lineTension: 0.3,
        pointStyle: "rect",
        pointRadius: 6,
        pointStyle: "rect",
      },
    ],
  };

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_name;
        })
    );
  }

  const noDataFoundBool =
    (!loading && reportData?.status === "Failed") ||
    (!loading &&
      reportData &&
      reportData.ticket_report &&
      reportData.ticket_report.platform.length !== 0 &&
      Object.keys(reportData.ticket_report.platform[0]).length === 0);

  const summaryBoxRef = useRef(null);
  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `50px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 50
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const [renderKey, setRenderKey] = useState(0);

  // const chartData = [
  //   {
  //     id: "Positive(+ve)",
  //     color: "hsl(179, 70%, 50%)",
  //     data: platforms.map((platform, index) => ({
  //       x: platform,
  //       y: positive_count[index],
  //     })),
  //   },
  //   {
  //     id: "Negative(-ve)",
  //     data: platforms.map((platform, index) => ({
  //       x: platform,
  //       y: negative_count[index],
  //     })),
  //   },
  //   {
  //     id: "Neutral(∅)",
  //     data: platforms.map((platform, index) => ({
  //       x: platform,
  //       y: neutral_count[index],
  //     })),
  //   },
  // ];

  let chartData = [];

  platforms?.map((item, index) => {
    chartData.push({
      platform: item[0],
      "Positive(+ve)": positive_count[index],
      "Negative(-ve)": negative_count[index],
      "Neutral(∅)": neutral_count[index],
    });
  });

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} />
        <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box>
            <Typography
              style={{
                fontSize: theme.reports.headerSize,
                padding: theme.reports.headerPadding,
              }}
            >
              {firstLetterOrange(props.report && props.report)}
            </Typography>

            <hr className="p-0 m-0 " />
          </Box>

          <Box
            className="scrollable"
            sx={{
              padding: "12px",
              overflowY: "auto",
              height: "100%",
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  width: "3px",
                  height: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#b0d2da",
                  borderRadius: "3px",
                  "&:hover": {
                    backgroundColor: "#85bac6",
                  },
                },
              },
            }}
          >
            {!loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "12px",
                }}
              >
                {!loading && !noDataFoundBool ? (
                  <Box height="100%">
                    {/* <Line
                      datasetIdKey="id"
                      data={state}
                      options={{
                        animation: false,
                        responsive: true,
                        // maintainAspectRatio: false,
                        plugins: {
                          tooltip: {
                            usePointStyle: true,
                          },
                          legend: {
                            display: true,
                            position: "bottom",
                            labels: {
                              usePointStyle: true,
                            },
                          },
                        },

                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                            title: {
                              display: true,
                              // text: "Month",
                              color: `${theme.palette.primary.main}`,

                              font: {
                                size: `${theme.typography.fontSize}`,
                              },
                            },
                          },
                          y: {
                            title: {
                              display: true,
                              text: "Sentiment Count",
                              color: `${theme.palette.primary.main}`,

                              font: {
                                size: `${theme.typography.fontSize}`,
                              },
                            },
                            labelString: "Sentiment Count",
                            grid: {
                              display: true,
                            },
                          },
                        },
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    /> */}

                    {/* <NivoLineChart
                      chartData={chartData}
                      axisLeftLegend={"Sentiment"}
                      enableArea={true}
                      translateX={50}
                      translateY={50}
                      enableGridX={false}
                      anchor={"top"}
                      direction="row"
                      axisBottomLegend={"Platforms"}
                      enableGridY={false}
                      areaBaselineValue={Math.min(
                        ...chartData[0].data.map((entry) => entry.y)
                      )}
                      margin={{ top: 10, right: 10, bottom: 60, left: 50 }}
                    /> */}

                    {chartData && chartData.length !== 0 && (
                      <NivoRadarChart
                        chartData={chartData}
                        indexBy={"platform"}
                        colors={[
                          `${theme.reports.positive}`,
                          `${theme.reports.negative}`,
                          `${theme.reports.neutral}`,
                        ]}
                        keys={["Positive(+ve)", "Negative(-ve)", "Neutral(∅)"]}
                        anchor={"bottom"}
                        direction={"row"}
                        margin={{ top: 50, right: 80, bottom: 80, left: 80 }}
                        translateY={-50}
                        itemsSpacing={30}
                      />
                    )}
                  </Box>
                ) : (
                  !loading && <NoDataFound />
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </Box>
        </Box>
      </LoadingIndicator>
    </Box>
  );
};

export default PlatformWiseOverallSentimentChart;
