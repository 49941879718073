import CampaignMappingReducer from "./campaignMapping/CampaignMappingReducer";
import ActivityTypeFilterReducer from "./influencerActivityType/ActivityTypeFilterReducer";
import ActivityTypeReducer from "./influencerActivityType/ActivityTypeReducer";
import InfluencerDashboardReducer from "./influencerDashboard/InfluencerDashboardReducer";
import InfluencerDeviceResetReducer from "./influencerDeviceMapping/InfluencerDeviceMappingReducer";
import InfluencerPlatformFilterReducer from "./influencerPlatformAccounts/InfluencerPlatformFilterReducer";
import InfluencerPlatformReducer from "./influencerPlatformAccounts/InfluencerPlatformReducer";
import InfluencerUserFilterReducer from "./influencerUser/InfluencerUserFilterReducer";
import InfluencerUserReducer from "./influencerUser/InfluencerUserReducer";
import InfluencersActivityReportsReducer from "./influencersActivity/InfluencerActivityReportsReducer";
import InfluencersActivityFilterReduser from "./influencersActivity/InfluencersActivityFilterReduser";
import InfluencersActivityReduser from "./influencersActivity/InfluencersActivityReduser";
import InfluencersCampaignByIdReduser from "./influencersCampaigns/InfluencersCampaignByIdReduser";
import InfluencersCampaignReduser from "./influencersCampaigns/InfluencersCampaignReduser";
import influencersCampaignsFilterReduser from "./influencersCampaigns/influencersCampaignsFilterReduser";
import InfluencersLanguageReduser from "./influencerLanguage/InfluencersLanguageReduser";
import PlatformTaskSchedulingReduser from "./platformScheduler/PlatformTaskSchedulingReduser";
import PlatformSchedulerReduser from "./platformScheduler/PlatformSchedulerReduser";

export const influencerRootReducer = {
  // influencersCampaign
  influencersCampaign: new InfluencersCampaignReduser().reducer,
  influencersCampaignFilter: new influencersCampaignsFilterReduser().reducer,
  InfluencersCampaignById: new InfluencersCampaignByIdReduser().reducer,

  // campaign mapping
  campaignMapping: new CampaignMappingReducer().reducer,

  //influencersActivity
  influencersActivity: new InfluencersActivityReduser().reducer,
  influencersActivityFilter: new InfluencersActivityFilterReduser().reducer,
  influencersActivityReports: new InfluencersActivityReportsReducer().reducer,

  // influencer Dashboard
  influencerDashboard: new InfluencerDashboardReducer().reducer,

  // influencer user filter
  influencerUser: new InfluencerUserReducer().reducer,
  influencerUserFilter: new InfluencerUserFilterReducer().reducer,

  // influencer platform accounts
  influencerPlatformAccounts: new InfluencerPlatformReducer().reducer,
  influencerPlatformAccountsFilter: new InfluencerPlatformFilterReducer()
    .reducer,

  // influencer activity type
  activityType: new ActivityTypeReducer().reducer,
  activityTypeFilter: new ActivityTypeFilterReducer().reducer,

  influencerDeviceMapping: new InfluencerDeviceResetReducer().reducer,

  // language
  influencersLanguage: new InfluencersLanguageReduser().reducer,

  //scheduling
  scheduling: new PlatformSchedulerReduser().reducer,
  influencerPlatformScheduling: new PlatformTaskSchedulingReduser().reducer,
};
