import React, { useEffect, useRef, useState, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import { NivoBarChart } from "../../../../components/nivoCharts/NivoBarChart";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import Description from "../descriptionContainer/Description";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

function ResponseOverviewChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [activitiesData, setActivitiesData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(null);
    setActivitiesData(null);
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      setData(null);
      setActivitiesData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      setData(null);
      setActivitiesData(null);
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }
      `;
    await axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      setData(response);
    });

    let API2 = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["activities"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    await axiosRequest(API2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      setActivitiesData(response);
    });
  }
  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  function ReturnActivityName(platformId, activityId) {
    return (
      props.activity_list &&
      props.activity_list.length > 0 &&
      props.activity_list.filter(
        (item) => item.platform.platform_id === platformId
      ).length > 0 &&
      props.activity_list
        .filter((item) => item.platform.platform_id === platformId)
        .filter((item) => item.activity_id === activityId).length > 0 &&
      props.activity_list
        .filter((item) => item.platform.platform_id === platformId)
        .filter((item) => item.activity_id === activityId)[0].activity_name
    );
  }

  let totalResponse = 0;
  data &&
    data.ticket_report &&
    data.ticket_report.platform
      .slice(
        data && data.ticket_report && data.ticket_report.platform.length - 6,
        data && data.ticket_report && data.ticket_report.platform.length - 5
      )
      .map((item, index) => {
        return (totalResponse = totalResponse + item.overall_total);
      });

  let NoDataFoundBool =
    (activitiesData && activitiesData?.status === "Failed") ||
    (activitiesData &&
      activitiesData.ticket_report &&
      activitiesData.ticket_report.activities.every(
        (item) => Object.values(item)[0].length === 0
      ));

  let [chartData, setChartData] = useState(null);
  useEffect(() => {
    if (activitiesData?.ticket_report?.activities) {
      let data_array = activitiesData?.ticket_report?.activities;

      let result = data_array.map((item) => {
        const platformNumber = Object.keys(item)[0];
        const platformData = item[platformNumber];

        const platformObject = {
          platform: platformName(platformNumber)[0],
        };

        platformData.forEach((data) => {
          const activityKey = ReturnActivityName(platformNumber, data.key);
          platformObject[activityKey] = prefixZero_for_singleDigit(data.count);
        });

        if (platformObject?.platform) {
          return platformObject;
        }
      });

      const modifiedData = result
        ?.filter((item) => item)
        .map((item) => {
          const keys = Object.keys(item);

          // Sort keys (excluding "platform") based on their values
          const sortedKeys = keys
            .filter((key) => key !== "platform")
            .sort((a, b) => item[a] - item[b]);

          // Create a new object with sorted keys
          const sortedItem = {
            platform: item.platform,
          };

          item.platform &&
            sortedKeys.forEach((key) => {
              sortedItem[key] = item[key];
            });

          return sortedItem;
        });

      modifiedData.filter((item) => item.platform !== undefined);

      setChartData(modifiedData);
    }
  }, [activitiesData]);

  let legendOffset,
    maxYAxisLength = 0;
  if (chartData?.length > 0) {
    const validChartData = chartData.filter((item) => item.platform);
    maxYAxisLength =
      validChartData &&
      Math.max(
        ...validChartData?.map((item) => {
          return String(item.platform?.length);
        })
      );
    if (maxYAxisLength > 30) legendOffset = -120 - maxYAxisLength * 3;
    else if (maxYAxisLength > 20) legendOffset = -120 - maxYAxisLength * 2;
    else if (maxYAxisLength > 10) legendOffset = -120 - maxYAxisLength;
    else if (maxYAxisLength > 0) legendOffset = -120;
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const allActivities = activitiesData?.ticket_report?.activities.reduce(
        (acc, curr) => {
          const platformKeys = Object.keys(curr);
          platformKeys.forEach((platformKey) => {
            curr[platformKey].forEach(({ key }) => {
              const activityName = ReturnActivityName(platformKey, key);
              if (!acc.includes(activityName)) {
                acc.push(activityName);
              }
            });
          });
          return acc;
        },
        []
      );

      const exportData = data?.ticket_report?.platform
        ?.slice(0, data?.ticket_report?.platform?.length - 6)
        .map(({ platform, doc_count, monthwise }) => {
          // Find the activities for the current platform
          const activitiesObj = activitiesData?.ticket_report?.activities.find(
            (activity) => activity[platform]
          );

          // If activities are found, transform them into an object with key-value pairs
          const activities = activitiesObj
            ? activitiesObj[platform].reduce((acc, { key, count }) => {
                // Use the ReturnActivityName function to generate a new key name
                const activityName = ReturnActivityName(platform, key);
                acc[activityName] = prefixZero_for_singleDigit(count);
                return acc;
              }, {})
            : {};

          // Make sure all activity names are present in the object with default "00" values
          allActivities.forEach((activity) => {
            if (!activities.hasOwnProperty(activity)) {
              activities[activity] = "00";
            }
          });

          // Calculate the total
          const total = Object.values(activities).reduce(
            (sum, count) => sum + parseInt(count, 10),
            0
          );

          // Return the transformed object with platform as the key and total at the end
          return {
            platform: platformName(platform)[0],
            ...activities,
            total: prefixZero_for_singleDigit(total),
          };
        });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, activitiesData, data]);

  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const chartDataCopy = [...chartData]
      .reverse()
      ?.filter((item) => Object.keys(item).length > 1); // Create a copy of the chartData array and reverse it
    const yAxisData = chartDataCopy.map((item) => item.platform);
    const series = [];

    const keys = Array.from(
      new Set(
        chartDataCopy
          .flatMap((item) => Object.keys(item))
          .filter((key) => key !== "platform")
      )
    );

    const colorValues = Object.values(theme.platform);

    keys.forEach((key, index) => {
      const seriesData = chartDataCopy.map((item) => ({
        name: item.platform,
        value: item[key] ? parseInt(item[key]) : 0,
      }));

      series.push({
        name: key,
        type: "bar",
        stack: "total",
        label: {
          show: props.detailedReport ? true : false,
          position: "insideRight", // Position label inside the bar
          formatter: (params) => {
            let val = params.value;
            let formattedvalue = formatValue(val);
            return val === 0 ? "" : formattedvalue;
          },
          color: theme.palette.primary.dark,
        },
        data: seriesData,
        itemStyle: {
          color: colorValues[index % colorValues.length],
        },
      });
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              let val = item.value;
              let formattedvalue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName}: ${formattedvalue}<br/>`;
            }
          });
          return tooltipContent;
        },
      },
      legend: {
        icon: "circle",
        type: "scroll",
        data: shouldShowLegend ? keys : [],
      },
      labelLayout: {
        hideOverlap: true,
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",
        left: "6%",
        right: "3%",
        bottom: "5%",
        containLabel: true,
      },

      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
          start: 0, // Initial start position of the zoomed area
          end: 100, // Initial end position of the zoomed area
          //  preventDefaultMouseMove: false,
        },
      ],
      yAxis: [
        {
          type: "category",
          name:
            props.detailedReport || props.ChartContainerWidth?.current > 600
              ? "Platforms"
              : "",

          //   name: "Platforms",
          nameLocation: "middle",
          nameTextStyle: {
            color: theme.palette.primary.main,
            // fontWeight: "bold",
            // fontFamily: "BookAntiqua",
            verticalAlign: "left",
            lineHeight: 0,
          },

          axisTick: {
            show: true, // Hide the axis ticks completely
          },
          axisLabel: {
            show: props.detailedReport ? true : false,
            padding: [0, 30, 0, 0],
            hideOverlap: true,
          },
          splitLine: {
            show: false, // Hide the split lines completely
          },
          data: yAxisData, // Provide an empty array to ensure no default labels are displayed
        },
      ],

      xAxis: {
        type: "value",
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Count"
            : "",
        axisLabel: {
          hideOverlap: true,
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
        },
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
      },
      series: series,
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading &&
          !NoDataFoundBool &&
          chartData &&
          chartData?.length !== 0 ? (
            <Box
              style={{
                width: "100%",
                paddingY: "12px",
                paddingRight: "12px",
                height: "100%",
              }}
            >
              <ReactEcharts
                onEvents={{
                  click: () => null,
                }}
                option={getOption()}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default ResponseOverviewChart;
