import BaseReducer from "../../../utilities/BaseReducer";
import ThreadsAccountAction from "./ThreadsAccountAction";

//Threads Add account Reducer
export default class ThreadsAccountReuser extends BaseReducer {
  initialState = {
    threadsAddAccount: [],
  };

  [ThreadsAccountAction.REQUEST_THREADS_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      threadsAddAccount: action.payload,
    };
  }
}
