import LastSyncDateEffect from "./lastSyncdateEffect";

import ActionUtility from "../../utilities/ActionUtility";

// last sync date action
export default class LastSyncDateAction {
  static LAST_SYNC_DATE_REQUEST = "LastSyncDateAction.LAST_SYNC_DATE_REQUEST";
  static LAST_SYNC_DATE_REQUEST_FINISHED =
    "LastSyncDateAction.LAST_SYNC_DATE_REQUEST_FINISHED";

  //METHODS

  // last sync date
  static LastSyncDateRequest(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LastSyncDateAction.LAST_SYNC_DATE_REQUEST,
        LastSyncDateEffect.requestLastSyncDateEffect,
        params
      );
    };
  }
}
