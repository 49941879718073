import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import MailLogsGetModel from "./model/MailLogsGetModel";

// MailLogsEffect
export default class MailLogsEffect {
  // calling an API according to the SessionLog model
  static async requestMailLogs(params) {
    const endpoint = environment.api.mailLogs;
    return EffectUtility.getToModel(MailLogsGetModel, endpoint, params);
  }

  // Get method for the MailLogs filter
  // static async requestMailLogsFilter(params) {
  //   const endpoint = environment.api.sessionLog;
  //   return EffectUtility.getToModel(SessionLogGetModel, endpoint, params);
  // }
}
