import BaseReducer from "../../utilities/BaseReducer";
import AmbitionBoxFeedsAction from "./AmbitionBoxFeedsAction";

export default class AmbitionBoxFeedsFilterReduser extends BaseReducer {
  //initial state of AmbitionBoxFeedsFilterReduser
  initialState = {
    ambitionBoxFeedsFilter: [],
  };
  //AmbitionBoxFeedsFilterReduser request action finish
  [AmbitionBoxFeedsAction.REQUEST_AMBITION_FEED_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ambitionBoxFeedsFilter: action.payload,
    };
  }
}
