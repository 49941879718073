import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import ResolvedTicketModel from "./models/ResolvedTicketModel";

export default class ResolvedTicketEffect {
  //get ticket resolved request end point
  static async requestResolvedTicket(params) {
    const endpoint = environment.api.ticketResolved;

    return EffectUtility.getToModel(ResolvedTicketModel, endpoint, params);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
