import BaseReducer from "../../../utilities/BaseReducer";
import YoutubeAction from "./YoutubeAction";

export default class YoutubeReplyPostReducer extends BaseReducer {
  initialState = {
    youtubeReply: [],
  };

  [YoutubeAction.REQUEST_POST_REPLY_FINISHED](state, action) {
    return {
      ...state,
      youtubeReply: action.payload,
    };
  }
}
