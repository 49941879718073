import { BaseModel } from "sjs-base-model";

// AssignmentRuleGetModel
export default class AutomationFieldsModel extends BaseModel {
  feed = {};
  settings = {};
  ticket = {};
  user = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
