import {
  Box,
  createFilterOptions,
  Grid,
  ListItem,
  Chip,
  Checkbox,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import AutoCompleteMultiSelect from "../../../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { theme } from "../../../../../views/App";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TicketHistoryAction from "../../../../stores/ticketHistory/TicketHistoryAction";

//map state to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    // TicketsAction.REQUEST_RELATED_TICKETS,
  ]),
  related_tickets: state.related_tickets.related_tickets || {},
  twitter: state.twitter.twitter.hits || {},
  twitterDirectMeassage:
    state.twitterDirectMeassage.twitterDirectMeassage.hits || [],
});

const TicketOverviewTab = (props) => {
  const [loading, setLoading] = useState(false);

  function AssignedToFullName(id) {
    return (
      props.userlist &&
      props.userlist
        .filter((item) => item.user_id === id)
        .map((value) => value.fullname)
    );
  }
  let priority_list = [];

  const returnCustomerNamefromState = (data) => {
    let customer_name = "";

    if (
      data &&
      data.twitter &&
      data.twitter.hits &&
      data.twitter.hits.length !== 0
    ) {
      customer_name = data.twitter.hits[data.twitter.hits.length - 1]._source
        ? `${
            data.twitter.hits[data.twitter.hits.length - 1]._source
              .customer_name
          }`
        : "";

      customer_name =
        customer_name +
        `(@${
          data.twitter.hits[data.twitter.hits.length - 1]._source
            .customer_handel
            ? data.twitter.hits[data.twitter.hits.length - 1]._source
                .customer_handel
            : null
        })`;
    }

    if (customer_name === "") {
      if (
        data &&
        data.twitterDirectMeassage &&
        data.twitterDirectMeassage.hits &&
        data.twitterDirectMeassage.hits.length !== 0
      ) {
        let filtered_array = [];
        filtered_array = data.twitterDirectMeassage.hits.filter((item) => {
          return item._source.flag === "received";
        });

        customer_name =
          filtered_array &&
          filtered_array.length &&
          filtered_array[0]._source &&
          filtered_array[0]._source.sender_name &&
          filtered_array[0]._source.sender_name;

        let full_name;

        let customer_name_handle =
          filtered_array &&
          filtered_array.length &&
          filtered_array[0]._source &&
          filtered_array[0]._source.customer_handle
            ? `(@${
                filtered_array &&
                filtered_array.length !== 0 &&
                filtered_array[0]._source &&
                filtered_array[0]._source.customer_handle
              })`
            : "";

        full_name = customer_name + customer_name_handle;

        customer_name = full_name;
      }
    }

    return customer_name ? customer_name : "";
  };

  props.filterprioritylist &&
    props.filterprioritylist.length !== 0 &&
    props.filterprioritylist.map((item) => {
      priority_list.push({
        priority_id: item.priority_id,
        priority_name: item.ticket_priority_name,
        color: item.color,
      });
    });

  let related_tickets = [];
  related_tickets =
    props.ticketData._source && props.ticketData._source.related_tickets;

  const fetchRelatedTickets = (ticket_id) => {
    props.dispatch(TicketsAction.requestTicketsById(ticket_id));

    fetchTicketHistory(ticket_id);
  };

  function fetchTicketHistory(ticket_id) {
    let params = {
      filters: `[["ticket_id.keyword", "must", "", "match", ${JSON.stringify(
        ticket_id
      )}]]`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(TicketHistoryAction.requestTicketHistory(params));
  }

  const initial = {
    status:
      props.ticketData._source && props.ticketData._source.status
        ? props.ticketData._source.status
        : "",
    priority: {
      priority_id:
        props.ticketData._source &&
        props.ticketData._source.priority.priority_id
          ? props.ticketData._source.priority.priority_id
          : "",
      priority_name:
        props.ticketData._source &&
        props.ticketData._source.priority.priority_name
          ? props.ticketData._source.priority.priority_name
          : "",
    },
    assigned_to: {
      user_name:
        props.ticketData._source &&
        props.ticketData._source.assigned_to.user_name
          ? props.ticketData._source.assigned_to.user_name
          : "",
      user_id:
        props.ticketData._source &&
        props.ticketData._source.assigned_to.user_id,
      fullname: AssignedToFullName(
        props.ticketData._source && props.ticketData._source.assigned_to.user_id
      ),
    },
    related_tickets:
      props.related_tickets_ids &&
      props.related_tickets_ids.includes(props.ticketData._id)
        ? props.ticketData._id
        : "",
    tags:
      props.ticketData._source && props.ticketData._source.tags
        ? props.ticketData._source.tags
        : "",
  };

  let sentiment_value_option = ["Positive", "Negetive", "Neutral"];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [tags, setTags] = useState([]);

  const tagsHandleChange = (event, newValue) => {
    if (newValue.length === 0) {
      setTags([]);
      return;
    }

    const selectedOption = newValue[newValue.length - 1];
    const isPresent = tags.some((obj) => obj === selectedOption);
    let updatedValue = [];
    if (isPresent) {
      updatedValue = tags.filter((obj) => obj !== selectedOption);
    } else {
      updatedValue = [...tags, selectedOption];
    }
    setTags(updatedValue);
  };

  useEffect(() => {
    if (props?.ticketData?._source?.tags) {
      const tagNames = props.ticketData._source.tags.map((tag) => ({
        tag_id: tag.tag_id,
        tag_name: tag.tag_name,
      }));
      setTags(tagNames);
    }
  }, [props?.ticketData?._source?.tags]);

  const handleDeleteTags = (tag) => {
    const updatedTags = tags.filter((obj) => obj.tag_id !== tag.tag_id);
    setTags(updatedTags);
  };

  return (
    <Box
      className="scrollable"
      sx={{ paddingY: 1, paddingX: 2, height: "100%" }}
    >
      <span className="p-0 m-0 font-weight-bold">Ticket Overview</span>

      <Box width={"100%"}>
        <Formik
          initialValues={initial}
          enableReinitialize
          onSubmit={(values) => {
            setLoading(true);
            const post_obj = {};
            Object.assign(
              post_obj,
              values.assigned_to &&
                values.assigned_to.user_id &&
                props.ticketData._source &&
                !props.ticketData._source.assigned_to.user_id && {
                  assigned_to: {
                    user_id: values.assigned_to.user_id,
                    user_name: values.assigned_to.user_name,
                  },
                },

              values.assigned_to &&
                values.assigned_to.user_id &&
                props.ticketData._source &&
                props.ticketData._source.assigned_to.user_id &&
                props.ticketData._source.assigned_to.user_id !==
                  values.assigned_to.user_id && {
                  assigned_to: {
                    user_id: values.assigned_to.user_id,
                    user_name: values.assigned_to.user_name,
                  },
                },

              // values.status &&
              //   values.status.status_id &&
              //   props.ticketData._source &&
              //   !props.ticketData._source.status && {
              //     status: values.status.status_id,
              //   },

              values.status &&
                values.status.status_id &&
                props.ticketData._source &&
                props.ticketData._source.status &&
                props.ticketData._source.status.status_id !==
                  values.status.status_id && {
                  status: values.status.status_id,
                },

              values.priority &&
                values.priority.priority_id &&
                props.ticketData._source &&
                !props.ticketData._source.priority.priority_id && {
                  priority: {
                    priority_id: values.priority.priority_id,
                    priority_name: values.priority.priority_name,
                  },
                },
              values.priority &&
                values.priority.priority_id &&
                props.ticketData._source &&
                props.ticketData._source.priority.priority_id &&
                props.ticketData._source.priority.priority_id !==
                  values.priority.priority_id && {
                  priority: {
                    priority_id: values.priority.priority_id,
                    priority_name: values.priority.priority_name,
                  },
                },
              tags && {
                tags: tags.map((tag) => ({
                  tag_id: tag.tag_id,
                  tag_name: tag.tag_name,
                })),
              }
            );
            // ticket PUT dispatch action

            props
              .dispatch(
                TicketsAction.requestPutTicket(
                  JSON.stringify(post_obj),
                  props.ticketData._id
                )
              )
              .then(() => {
                setLoading(false);
                // props.dispatch(
                //   TicketsAction.requestTicketsById(props.ticketData._id)
                // );
                props.onClose();

                localStorage.getItem("selected_tickets_for_bulk_update") &&
                  localStorage.removeItem("selected_tickets_for_bulk_update");
                props.setSelectedRows([]);

                if (
                  props.ticketData._source.status.status_id ===
                  values.status.status_id
                ) {
                  props.fetchFunction(true);
                } else {
                  props.fetchFunction(false);
                }

                // let params = {
                //   page_number:
                //     props.page_length === 1
                //       ? props.pageNumber - 1
                //       : props.pageNumber,
                //   filters: `[${props.filters}]`,
                //   order_by: `[["created_on", "desc"]]`,
                //   page_limit: `${props.page_limit}`,
                // };
                // props.dispatch(TicketsAction.requestTickets(params));

                // let ticket_count_params = {
                //   filters: `[${props.ticket_count_filters}]`,
                // };
                // props.dispatch(
                //   TicketCountAction.requestTicketCount(ticket_count_params)
                // );
              });
          }}
          validateOnBlur={true}
          validateOnChange
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            submitForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // justifyContent="space-around"
                justifyContent="space-between"
                alignItems="center"
                // className="bg-warning"
              >
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  alignItems={"flex-end"}
                  mt={2}
                  mb={1}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className="p-0 m-0" style={{ fontSize: 12 }}>
                      Ticket Status
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={9}
                    lg={9}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    // className="bg-warning"
                    mt={-2}
                  >
                    <Field
                      name="status"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.status ? values.status : null}
                      component={AutoCompleteMultiSelect}
                      disableClearable={true}
                      options={props.statusList ? props.statusList : []}
                      // disabled={true}
                      // onChange={(_, value) => {
                      //   setFieldValue(`client`, value);
                      //   setFieldValue(`platform`, []);
                      //   setOrg_id(value.organization_id);
                      // }}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.status_name === value.status_name
                      }
                      getOptionLabel={(option) => option.status_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Status",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  alignItems={"flex-end"}
                  my={1}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className="p-0 m-0 " style={{ fontSize: 12 }}>
                      Ticket Priority
                    </span>
                  </Grid>

                  <Grid
                    item
                    xl={9}
                    lg={9}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    mt={-2}

                    // className="bg-warning"
                  >
                    <Field
                      name="priority"
                      component={AutoCompleteMultiSelect}
                      disableClearable={true}
                      size="small"
                      label="priority"
                      options={priority_list.length !== 0 ? priority_list : []}
                      value={values.priority ? values.priority : null}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.priority_name === value.priority_name
                      }
                      //setting custom label to custom value
                      getOptionLabel={(option) => option.priority_name}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <Box
                              mr={1}
                              width={10}
                              height={10}
                              borderRadius="50%"
                            >
                              {returnColor(option.color)}
                            </Box>
                            {option.priority_name}
                          </Box>
                        );
                      }}
                      textFieldProps={{
                        fullWidth: true,
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Select Priority",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  alignItems={"flex-end"}
                  my={1}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className="p-0 m-0" style={{ fontSize: 12 }}>
                      Agent
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={9}
                    lg={9}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    mt={-2}
                    // className="bg-warning"
                  >
                    <Field
                      name="assigned_to"
                      component={AutoCompleteMultiSelect}
                      disableClearable={true}
                      label="assigned_to"
                      size="small"
                      options={props.userlist ? props.userlist : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        {
                          return option.fullname === value.fullname;
                        }
                      }
                      //setting custom label to custom value
                      getOptionLabel={(option) => {
                        return option.user_name !== ""
                          ? `${option.fullname} (${option.user_name})`
                          : "";
                      }}
                      value={values.assigned_to ? values.assigned_to : null}
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.fullname,
                      })}
                      textFieldProps={{
                        fullWidth: true,
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Assign to",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  alignItems={"flex-end"}
                  my={1}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className="p-0 m-0" style={{ fontSize: 12 }}>
                      Tags
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={9}
                    lg={9}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AutoCompleteComponent
                      width="100%"
                      value={tags}
                      name="tags"
                      textFieldProps={{
                        placeholder:
                          tags && tags.length !== 0 ? null : "Tag Name",
                      }}
                      list={props.filterTagList ? props.filterTagList : []}
                      handleChange={tagsHandleChange}
                      multiple
                      disableCloseOnSelect={true}
                      getOptionLabel={(option) =>
                        option.tag_name ? option.tag_name : ""
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.tag_name}
                            variant="outlined"
                            size="small"
                            sx={{
                              my: 0.5,
                              mx: 0.5,
                            }}
                            onDelete={() => handleDeleteTags(option)}
                          />
                        ))
                      }
                      renderOption={(props, option) => {
                        const isPresent = tags.some(
                          (obj) => obj.tag_id === option.tag_id
                        );

                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={isPresent}
                            />
                            {option.tag_name}
                          </li>
                        );
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  alignItems={"flex-end"}
                  my={2}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className="p-0 m-0" style={{ fontSize: 12 }}>
                      Related Tickets
                    </span>
                    <Grid
                      item
                      xl={9}
                      lg={9}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      mt={-2}
                      // className="bg-warning"
                    >
                      <Field
                        name="related_tickets"
                        component={AutoCompleteMultiSelect}
                        disableClearable={true}
                        label="related_tickets"
                        size="small"
                        options={
                          props.related_tickets_ids
                            ? props.related_tickets_ids
                            : []
                        }
                        value={
                          values.related_tickets ? values.related_tickets : null
                        }
                        onChange={(_, value) => {
                          fetchRelatedTickets(value);
                          setFieldValue("related_tickets", value);
                        }}
                        //setting custom label to custom value
                        // getOptionLabel={(option) => option.fullname}
                        // filterOptions={createFilterOptions({
                        //   matchFrom: "start",
                        //   stringify: (option) => option.fullname,
                        // })}
                        textFieldProps={{
                          fullWidth: true,
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Related Tickets",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="flex-start"
                  alignItems={"flex-end"}
                  my={2}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span
                      className="p-0 m-0 font-weight-bold"
                      style={{ fontSize: 12 }}
                    >
                      Sentiment Value
                    </span>
                  </Grid>
                  <Grid
                    item
                    xl={9}
                    lg={9}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    // className="bg-warning"
                    mt={-2}
                  >
                    <Field
                      name="sentiment_value"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={
                        values.sentiment_value ? values.sentiment_value : null
                      }
                      component={AutoCompleteMultiSelect}
                      options={sentiment_value_option}
                      // disabled={true}
                      // onChange={(_, value) => {
                      //   setFieldValue(`client`, value);
                      //   setFieldValue(`platform`, []);
                      //   setOrg_id(value.organization_id);
                      // }}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option === value
                      }
                      getOptionLabel={(option) => option}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Sentiment Value",
                      }}
                    />
                  </Grid>
                </Grid> */}
                <Grid
                  item
                  container
                  xl={3.5}
                  lg={3.5}
                  md={6}
                  sm={6}
                  xs={6}
                  // justifyContent="center"
                  // alignItems="center"
                  // className="bg-warning"
                >
                  {/* <Grid
                    item
                    xl={5.5}
                    lg={5.5}
                    md={5.5}
                    sm={5.5}
                    xs={5.5}
                    textAlign="center"
                    sx={{
                      border: "solid 1px #9dc0c9;",
                      cursor: "pointer",
                      backgroundColor: "#39717c",
                      borderRadius: "4px",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                      }}
                      onClick={submitForm}
                    >
                      Update
                    </span>
                  </Grid> */}
                  <MuiButton
                    variant="contained"
                    width={"35%"}
                    height={"25px"}
                    type="submit"
                    name={"Update"}
                    loading={loading}
                    noTextOnLoading={true}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>

      <Box width={"100%"} my={2}>
        <Grid
          item
          container
          xl={11.5}
          lg={11}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid
            item
            xl={5.8}
            lg={10}
            md={12}
            sm={12}
            xs={12}
            // className="bg-success"
            my={1}
          >
            <span className="p-0 m-0 font-weight-bold">Total Mentions</span>
            <ListItem className="p-0 m-0">
              <FontAwesomeIcon
                //   className="bg-warning"
                icon={faComments}
                style={{
                  height: 10,
                  marginRight: 2,
                  color: `${theme.palette.appbar.icons}`,
                }}
              ></FontAwesomeIcon>

              <div
                className="p-0 m-0"
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                {props.ticketData._source &&
                props.ticketData._source.user_total_mentions &&
                props.ticketData._source.user_total_mentions
                  ? props.ticketData._source.user_total_mentions
                  : 0}
              </div>
            </ListItem>
          </Grid>

          <Grid item xl={5.8} lg={10} md={12} sm={12} xs={12} my={1}>
            <span className="p-0 m-0 font-weight-bold">Ticket Created On</span>

            <div
              className="p-0 m-0"
              style={{
                color: "#a0bfc9",
                fontSize: 12,
              }}
            >
              {(function createdDate() {
                if (Object.keys(props.ticketData).length) {
                  let created =
                    props.ticketData._source &&
                    props.ticketData._source.created_on;
                  let dateConversion = new Date(created);
                  dateConversion =
                    dateConversion.toDateString() +
                    " " +
                    dateConversion.toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });

                  return dateConversion;
                } else {
                  return null;
                }
              })()}
            </div>
          </Grid>

          <Grid
            item
            xl={5.8}
            lg={10}
            md={12}
            sm={12}
            xs={12}
            // className="bg-info"
            my={1}
          >
            <span className="p-0 m-0 font-weight-bold">Ticket Updated On</span>

            <div
              className="p-0 m-0"
              style={{
                color: "#a0bfc9",
                fontSize: 12,
              }}
            >
              {" "}
              {(function createdDate() {
                if (Object.keys(props.ticketData).length) {
                  let created =
                    props.ticketData._source &&
                    props.ticketData._source.modified_on;
                  let dateConversion = new Date(created);
                  dateConversion =
                    dateConversion.toDateString() +
                    " " +
                    dateConversion.toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });

                  return dateConversion;
                } else {
                  return null;
                }
              })()}
            </div>
          </Grid>

          <Grid
            item
            xl={5.8}
            lg={10}
            md={12}
            sm={12}
            xs={12}
            // className="bg-success"
            my={1}
          >
            <span className="p-0 m-0 font-weight-bold">Brand</span>&nbsp;
            <ListItem className="p-0 m-0">
              <EmojiEventsIcon
                style={{
                  width: "10px",
                  marginRight: 2,
                  color: `${theme.palette.appbar.icons}`,
                  padding: 0,
                  margin: 0,
                }}
              />

              <div
                className="p-0 m-0"
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                {props.ticketData._source &&
                  props.ticketData._source.account_name &&
                  props.ticketData._source.account_name.account_name}
              </div>
            </ListItem>
          </Grid>

          {props.ticketData._source &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.customer_name &&
            props.ticketData._source.customer_id.customer_name.length !== 0 && (
              <Grid
                item
                xl={8}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                // className="bg-success"
                my={0.5}
              >
                <span className="p-0 m-0 font-weight-bold">Profile</span>&nbsp;
                <ListItem className="p-0 m-0">
                  <EmojiEventsIcon
                    style={{
                      width: "10px",
                      marginRight: 2,
                      color: `${theme.palette.appbar.icons}`,
                      padding: 0,
                      margin: 0,
                    }}
                  />

                  <p
                    className="p-0 m-0"
                    style={{
                      color: "#a0bfc9",
                      fontSize: 12,
                    }}
                  >
                    {props.ticketData._source &&
                    props.ticketData._source.platform.platform_name ===
                      "Twitter"
                      ? returnCustomerNamefromState(props)
                      : props.ticketData._source &&
                        props.ticketData._source.customer_id &&
                        props.ticketData._source.customer_id.customer_name}
                  </p>
                </ListItem>
              </Grid>
            )}
        </Grid>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(TicketOverviewTab);
const returnColor = (color) => {
  return (
    <Box
      width={10}
      height={10}
      borderRadius="50%"
      sx={{
        backgroundColor: `${color}`,
      }}
    ></Box>
  );
};
