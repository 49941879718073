import React, { useState } from "react";
import "./TagPost.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field, getIn } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import TagAction from "../../stores/tag/TagAction";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../components/muiButton/MuiButton";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  tag_name: string()
    .required("Enter your Tag name")
    .trim()
    .min(1, "Tag name should be of minimum 1 characters length")
    .required("Tag name is required"),
});

const TagPost = (props) => {
  let projectProps = props.projectProps;

  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      tag_name: props.projectEditData.tag_name,
    };
  } else {
    initial = {
      tag_name: "",
    };
  }
  // Formik

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          // parameter for showing list based on the modified_by
          setLoading(true);
          const params = {
            order_by: `[["order_number","asc"]]`,
            page_limit: 15,
          };
          const param = {
            order_by: `[["tag_name.keyword", "asc"]]`,
            page_limit: "none",
          };
          if (props.edit) {
            props
              .dispatch(
                TagAction.requestPutTag(
                  JSON.stringify(values),
                  props.projectEditData.id
                )
              )
              .then(() => {
                props.dispatch(TagAction.requestTag(params));
                props.dispatch(TagAction.requestTagFilter(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            props.dispatch(TagAction.requestPostTag(values)).then(() => {
              const params = {
                order_by: `[["order_number","asc"]]`,
                page_limit: 15,
              };
              const param = {
                order_by: `[["tag_name.keyword", "asc"]]`,
                page_limit: "none",
              };
              props.dispatch(TagAction.requestTag(params));
              props.dispatch(TagAction.requestTagFilter(param));
              props.onClose();
              setLoading(false);
            });
          }

          // formikHelpers.resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "90%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">T</span>icket
                            &nbsp;
                            <span className="span_first_letter">T</span>ag
                            &nbsp;
                            <span className="span_first_letter">N</span>ame
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">T</span>icket
                            &nbsp;
                            <span className="span_first_letter">T</span>ag
                            &nbsp;
                            <span className="span_first_letter">N</span>ame
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Tag Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      autoFocus
                      variant="outlined"
                      placeholder="Tag Name"
                      id="tag_name"
                      size="small"
                      name="tag_name"
                      error={
                        Boolean(errors.tag_name) && Boolean(touched.tag_name)
                      }
                      helperText={Boolean(touched.tag_name) && errors.tag_name}
                      className="col-10"
                    />
                  </Grid>
                </Grid>
                {/* </Box> */}
                {/* <Box sx={{ width: "250px", margin: "auto", marginLeft: "140px" }}> */}
              </Grid>
              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Add Tag Name"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(TagPost);
