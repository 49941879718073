import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import EffectUtility from "../../../utilities/EffectUtility";
import HttpUtility from "../../../utilities/HttpUtility";
import RolePermissionDeleteModel from "./models/RolePermissionDeleteModel";
import RolePermissionModel from "./models/RolePermissionModel";
import RolePermissionPostModel from "./models/RolePermissionPostModel";
import RolePermissionPutModel from "./models/RolePermissionPutModel";

export default class RolePermissionsEffect {
  // get roles permissions request endpoint
  static async requestRolePermission(params) {
    const endpoint = environment.api.rolePermission;

    return EffectUtility.getToModel(RolePermissionModel, endpoint, params);
  }

  //rolePermission post endpoint
  static async requestRolePermissionPost(data) {
    const endpoint = environment.api.RolePermission;

    return EffectUtility.postToModel(RolePermissionPostModel, endpoint, data);
  }

  //rolePermission put endpoint
  static async requestRolePermissionPut(data, id) {
    const endpoint = environment.api.RolePermission + "/" + id;

    return EffectUtility.putToModel(RolePermissionPutModel, endpoint, data);
  }

  //delete RolePermission request endpoint
  static async requestRolePermissionDelete(id) {
    const endpoint = environment.api.RolePermission + "/" + id;
    return EffectUtility.deleteToModel(RolePermissionDeleteModel, endpoint, id);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
