import { Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { theme } from "../../../../../views/App";
import MuiSnackbarAlert from "../../../../../components/muiSnackbarAlert/MuiSnackbarAlert";

const ReactTimer = (props) => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  //   const returnDeadLineTime = (created_on, targetTime) => {
  //     console.log(
  //       "ticket_closure_max_duration===>",
  //       created_on,
  //       targetTime && targetTime
  //     );
  //       return targetTime
  //   };

  // The state for our timer
  const [timer, setTimer] = useState(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);

    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setTimer("00:00:00");
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    // setTimer("00:00:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    // if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    });

    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date(props.first_ticket_assigned_date_time);

    let durationArray = props.targetTime;
    durationArray = durationArray && durationArray.split(":");

    let hoursToAdd = Number(durationArray && durationArray[0]) || 0;
    let minutesToAdd = Number(durationArray && durationArray[1]) || 0;
    let secondsToAdd = Number(durationArray && durationArray[2]) || 0;

    deadline.setHours(deadline.getHours() + hoursToAdd);
    deadline.setMinutes(deadline.getMinutes() + minutesToAdd);
    deadline.setSeconds(deadline.getSeconds() + secondsToAdd);

    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  function getTimeInSeconds(timeString) {
    const [hours, minutes, seconds] = timeString && timeString.split(":");

    return (
      parseInt(hours) * 3600 +
      parseInt(minutes) * 60 +
      parseInt(seconds ? seconds : "00")
    );
  }

  // useEffect(() => {
  //   if (
  //     timer &&
  //     props.alertTime &&
  //     getTimeInSeconds(timer) === getTimeInSeconds(`00:${props.alertTime}`)
  //   ) {
  //     console.log("logcheck", true);
  //     let timerFUnction = () => {
  //       setAlertPopUp(true);
  //       setAlertMessage(`Ticket with ID ${props.item._id} is going to Breach`);
  //       // console.log("logcheck", "2");
  //     };
  //     let intervalID = setInterval(timerFUnction(), 1000);

  //     setTimeout(() => {
  //       clearInterval(intervalID);
  //     }, 500);
  //   } else {
  //     console.log("logcheck", false);
  //   }
  //   console.log(
  //     "logcheck",
  //     timer && timer,
  //     props.alertTime && props.alertTime,
  //     timer && getTimeInSeconds(timer),
  //     props.alertTime && getTimeInSeconds(`00:${props.alertTime}`)
  //   );
  // });

  // const [alertPopUp, setAlertPopUp] = useState(false);
  // const [alertMessage, setAlertMessage] = useState(null);

  return (
    <Typography sx={{ display: "flex" }}>
      <TimerOutlinedIcon
        style={{
          width: "16px",
          marginRight: 2,
          color:
            (props.finalStatuses &&
              props.finalStatuses.length &&
              props.finalStatuses.includes(
                props.item._source.status && props.item._source.status.status_id
              )) ||
            timer === "00:00:00"
              ? "#E64B25"
              : `${theme.palette.ticketListView.iconColor}`,
        }}
      />

      {/* <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={alertMessage}
      /> */}

      {timer && (
        <span
          style={{
            marginTop: 1,
            color:
              timer === "00:00:00"
                ? "#E64B25"
                : `${theme.palette.primary.main}`,
          }}
        >
          {props.finalStatuses &&
          props.finalStatuses.length &&
          props.finalStatuses.includes(
            props.item._source.status && props.item._source.status.status_id
          ) ? (
            <span
              style={{
                marginTop: 1,
                color: "#E64B25",
              }}
            >
              {"00:00:00"}
            </span>
          ) : (
            timer
          )}
        </span>
      )}
    </Typography>
  );
};

export default ReactTimer;
