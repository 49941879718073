import TopArtistAction from "./TopArtistAction";
import BaseReducer from "../../../../utilities/BaseReducer";

// TrackReduser
export default class TopArtistReducer extends BaseReducer {
  initialState = {
    topArtist: [],
  };
  [TopArtistAction.REQUEST_TOPARTIST_FINISHED](state, action) {
    return {
      ...state,
      topArtist: action.payload,
    };
  }
}
