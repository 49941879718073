import BaseReducer from "../../../utilities/BaseReducer";
import TelegramAccountAction from "./TelegramAccountAction";

// TelegramAccountReducer
export default class TelegramAccountReducer extends BaseReducer {
  initialState = {
    telegramAccount: [],
  };
  [TelegramAccountAction.REQUEST_TELEGRAM_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      telegramAccount: action.payload,
    };
  }
}
