import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import SurveyDataModel from "./model/SurveyDataModel";

// SurveyDataEffect
export default class SurveyDataEffect {
  // calling an API according to the Organization model
  // Get method for the
  static async requestSurveyData(params) {
    const endpoint = environment.api.surveyData;
    return EffectUtility.getToModel(SurveyDataModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestSurveyDataFilter(params) {
    const endpoint = environment.api.surveyDataFilter;
    return EffectUtility.getToModel(SurveyDataModel, endpoint, params);
  }

  //   // put method for the Organization
  //   static async requestPutSurveyData(data, id) {
  //     const endpoint = environment.api.organization + "/" + id;
  //     return EffectUtility.putToModel(OrganizationPutModel, endpoint, data);
  //   }

  //   // post method for the Organization
  //   static async requestPostSurveyData(data) {
  //     const endpoint = environment.api.organization + "/add";

  //     return EffectUtility.postToModel(OrganizationPostModel, endpoint, data);
  //   }
}
