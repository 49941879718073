import BaseReducer from "../../../../utilities/BaseReducer";
import WebsweepxReportsAction from "./WebsweepxReportsAction";

export default class WebsweepxReportsCMSWSXHostWiseReduser extends BaseReducer {
  initialState = {
    websweepXReportCMSvsWSXHostWise: [],
  };
  [WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSXHOSTWISE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepXReportCMSvsWSXHostWise: action.payload,
    };
  }
}
