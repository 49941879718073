import { BaseModel } from "sjs-base-model";
import ScrapeLinksHitsModel from "./ScrapeLinksHitsModel";
import ScrapeLinksShardsModel from "./ScrapeLinksShardsModel";

// ScrapeLinksModel
export default class ScrapeLinksModel extends BaseModel {
    // response getting from the api mentioned end point
    _shards = { _shards: ScrapeLinksShardsModel };
    current_page_no = 0;
    hits = { hits: ScrapeLinksHitsModel };
    total_page_no = 0;
    timed_out = false;
    took = 0;
    constructor(data1) {
        super();

        this.update(data1);
    }
}
