import BaseReducer from "../../../../utilities/BaseReducer";
import PinterestAccountAction from "./PinterestAccountAction";

export default class PinterestAccountReduser extends BaseReducer {
  initialState = {
    pinterestAccount: [],
  };
  [PinterestAccountAction.REQUEST_PINTEREST_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      pinterestAccount: action.payload,
    };
  }
}
