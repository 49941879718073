import { BaseModel } from "sjs-base-model";

// TrackPostModel
export default class TrackDeleteModel extends BaseModel {
  status = "";
  _id = "";
  _index = "";
  _primary_term = 0;
  _seq_no = 0;
  _shards = {};
  forced_refresh = false;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
