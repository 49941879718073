import BaseReducer from "../../utilities/BaseReducer";
import CustomerDataAction from "./CustomerDataAction";

export default class CustomerDataReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    customerData: [],
  };

  //operation Filter
  [CustomerDataAction.REQUEST_CUSTOMER_DATA_FINISHED](state, action) {
    return {
      ...state,
      customerData: action.payload,
    };
  }
}
