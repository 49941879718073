import BaseReducer from "../../../utilities/BaseReducer";
import PlatformAction from "./PlatformAction";

export default class PlatformFilterReducer extends BaseReducer {
  //initial state of ticketType
  initialState = {
    platformfilter: [],
  };

  //tickettype request action finish
  [PlatformAction.REQUEST_PLATFORM_FILTER_FINISHED](state, action) {
    return {
      ...state,
      platformfilter: action.payload,
    };
  }
}
