import BaseReducer from "../../../../utilities/BaseReducer";
import RevenueClaimAction from "./RevenueClaimAction";

export default class ClientwiseRevenueClaimReducer extends BaseReducer {
  initialState = {
    clientwiseRevenueClaim: [],
  };
  [RevenueClaimAction.REQUEST_CLIENTWISE_REVENUE_CLAIM_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      clientwiseRevenueClaim: action.payload,
    };
  }
}
