import { useEffect, useState } from "react";
import RenderMedia from "../../../../../components/renderMedia/RenderMedia";

const VideoComponent = ({ url }) => {
  const [videoLoaded, setVideoLoaded] = useState(true); // Initially set to true to render the video tag

  const handleVideoError = () => {
    setVideoLoaded(false); // Set to false when the video fails to load
  };

  return (
    <div>
      {videoLoaded && (
        <video controls width="400" height="300" onError={handleVideoError}>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const ImageComponent = ({ url, height = 200, alt = "Image" }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  return (
    <div>
      {imageLoaded && (
        <img src={url} height={height} alt={alt} onError={handleImageError} />
      )}
    </div>
  );
};

const AudioComponent = ({ url }) => {
  const [audioLoaded, setAudioLoaded] = useState(true);

  const handleAudioError = () => {
    setAudioLoaded(false);
  };

  return (
    <div>
      {audioLoaded && (
        <audio controls onError={handleAudioError}>
          <source src={url} type="audio/mp3" />
        </audio>
      )}
    </div>
  );
};

function MediaChecker({ url }) {
  let [extension, setExtension] = useState(null);
  let [mediaType, setMediaType] = useState(null);

  const imageFormats = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "webp",
    "svg",
    "tiff",
  ];

  const videoFormats = [
    "mp4",
    "avi",
    "mkv",
    "mov",
    "wmv",
    "flv",
    "webm",
    "m4v",
  ];

  useEffect(() => {
    const pathArray = url.split("/");
    const lastSegment = pathArray[pathArray.length - 1];
    const filenameArray = lastSegment.split(".");
    if (filenameArray.length > 1) {
      if (
        videoFormats &&
        imageFormats &&
        filenameArray[filenameArray.length - 1]
      ) {
        videoFormats.includes(filenameArray[filenameArray.length - 1]) &&
          setMediaType("video");
        imageFormats.includes(filenameArray[filenameArray.length - 1]) &&
          setMediaType("img");
      }
    } else {
      setExtension(null); // No file extension found
    }
  }, []);

  return (
    <div className="my-2">
      {/* <RenderMedia image_url={url} mediaType={`${mediaType}`} height="100%" />
       */}

      <VideoComponent url={url} />
      <ImageComponent url={url} />
      {/* {message_type === "story_mention" && <AudioComponent url={url} />} */}
    </div>
  );
}

export default MediaChecker;
