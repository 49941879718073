import { BaseModel } from "sjs-base-model";

export default class SessionLogReportGetModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = {};
  aggregations = {};
  hits = {};
  timed_out = false;
  took = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
