import { BaseModel } from "sjs-base-model";
import McnMonitoringHitsModel from "./McnMonitoringHitsModel";
import McnMonitoringShardsModel from "./McnMonitoringShardsModel";

// McnMonitoringModel
export default class McnMonitoringModel extends BaseModel {

  // response getting from the api mentioned end point
  _shards = { _shards: McnMonitoringShardsModel };
  current_page_no = 0;
  hits = { hits: McnMonitoringHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
