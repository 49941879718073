import { BaseModel } from "sjs-base-model";

// EmailsGetModel
export default class EmailsGetModel extends BaseModel {
  // response getting from the api mentioned end point
  message = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
