import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Logo from "../logoContainer/Logo";
import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";
import { Box, Typography } from "@mui/material";
import { CategoryScale } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoPieChart } from "../../../../components/nivoCharts/NivoPieChart";
import { firstLetterOrange } from "../../../../components/ticketPartials";

function TicketClosureFrtBreachChart(props) {
  Chart.register(CategoryScale);

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  async function fetchData(dateRange) {
    const cancelToken = axios.CancelToken.source();

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["reportOn_frt_breach"]${
      props.DateFilter ? `&from=${dateRange[0]}&to=${dateRange[1]}` : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        return setData(response);
      })
      .catch((err) => {
        datadogLogs.logger.error(err.message, err);
        throw new Error(err);
      });
  }

  let tableList = [];

  data &&
    data.ticket_report &&
    data.ticket_report.reportOn_frt_breach?.map((item) => {
      tableList.push({
        name: item.key.replace(/_/g, " "),
        count: item.count,
        percentage: item.percentage,
      });
    });

  let countData = [];
  let nameLabels = [];

  let percentlables = [];
  let truepercent = [];

  nameLabels = tableList.map((item) => {
    return item.name;
  });

  countData = tableList.map((item) => {
    return item.count;
  });

  percentlables = tableList.map((item) => {
    return item.count;
  });

  truepercent = tableList.map((item) => {
    return item.percentage;
  });

  const summaryBoxRef = useRef(null);
  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `50px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 50
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const [renderKey, setRenderKey] = useState(0);

  // Transforming the data
  const chartData = nameLabels?.map((nameLabel, index) => {
    return {
      id: nameLabel,
      label: nameLabel,
      value: countData[index],
    };
  });

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} />
        <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontSize: theme.reports.headerSize,
                padding: theme.reports.headerPadding,
              }}
            >
              {firstLetterOrange(props.report && props.report)}
            </Typography>

            <hr className="p-0 m-0 " />
          </Box>

          <Box
            className="scrollable"
            sx={{
              padding: "12px",
              overflowY: "auto",
              height: "100%",
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  width: "3px",
                  height: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#b0d2da",
                  borderRadius: "3px",
                  "&:hover": {
                    backgroundColor: "#85bac6",
                  },
                },
              },
            }}
          >
            <NivoPieChart
              chartData={chartData}
              anchor="bottom"
              direction="row"
              translateX={0}
              translatey={0}
              itemHeight={-50}
              colors={[
                `${theme.reports.positive}`,
                `${theme.reports.negative}`,
              ]}
            />
          </Box>
        </Box>
      </LoadingIndicator>
    </Box>
  );
}

export default TicketClosureFrtBreachChart;
