import BaseReducer from "../../../utilities/BaseReducer";
import AppStoreAddAccountAction from "./AppStoreAddAccountAction";

//Appstore Account Filter reducer
export default class AppstoreAccountFilterReducer extends BaseReducer {
  initialState = {
    appstoreAccountFilter: [],
  };

  [AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      appstoreAccountFilter: action.payload,
    };
  }
}
