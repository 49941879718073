import BaseReducer from "../../../utilities/BaseReducer";
import StatusAction from "./StatusAction";

export default class DashboardStatusFilterReduser extends BaseReducer {
  //initial state of StatusFilterReduser
  initialState = {
    dashboardStatusFilter: [],
  };
  //DashboardStatusFilterReduser request action finish
  [StatusAction.REQUEST_DASHBOARD_STATUS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      dashboardStatusFilter: action.payload,
    };
  }
}
