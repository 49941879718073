import BaseReducer from "../../utilities/BaseReducer";
import CampaignMonitoringAction from "./CampaignMonitoringAction";

// CampainListReduser
export default class CampainListReduser extends BaseReducer {
  initialState = {
    campaignList: [],
  };
  [CampaignMonitoringAction.REQUEST_CAMPAIN_MONITORING_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      campaignList: action.payload,
    };
  }
}
