import BaseReducer from "../../../../utilities/BaseReducer";
import ClientRevenueSharePolicyAction from "./ClientRevenueSharePolicyAction";

export default class ClientRevenueSharePolicyReduser extends BaseReducer {
  initialState = {
    clientRevenueSharePolicy: [],
  };
  [ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      clientRevenueSharePolicy: action.payload,
    };
  }

  [ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      clientRevenueSharePolicy: action.payload,
    };
  }
}
