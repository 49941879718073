import { BaseModel } from "sjs-base-model";
import QuoraFeedsDataHitsModel from "./QuoraFeedsDataHitsModel";

//QuoraFeedsHitsModel
export default class QuoraFeedsHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ QuoraFeedsDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
