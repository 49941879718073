import { BaseModel } from "sjs-base-model";
import TelegramAccountDataHitsModel from "./TelegramAccountDataHitsModel";

//TelegramAcountHitsModel
export default class TelegramAcountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ TelegramAccountDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
