import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import YTRevenueReportGetModel from "./models/YTRevenueReportGetModel";
import YTRevenueReportPutModel from "./models/YTRevenueReportPutModel";
import YTRevenueReportPostModel from "./models/YTRevenueReportPostModel";

export default class YTRevenueReportEffect {
  static async requestYTRevenueReport(params) {
    const endpoint = environment.api.ytRevenueReport;

    return EffectUtility.getToModel(YTRevenueReportGetModel, endpoint, params);
  }

  static async requestYTRevenueReportClear() {
    return {};
  }

  static async requesYTRevenueReportFilterClear() {
    return {};
  }

  static async requestYTRevenueReportExport(params, callBackfunction) {
    const endpoint = environment.api.ytRevenueReport;

    let response = EffectUtility.getToModel(
      YTRevenueReportGetModel,
      endpoint,
      params
    );

    if (response) {
      response.then((resp) => {
        callBackfunction(resp);
      });
    }
    return response;
  }

  static async requestYTRevenueReportFilter(params) {
    const endpoint = environment.api.ytRevenueReport;
    return EffectUtility.getToModel(YTRevenueReportGetModel, endpoint, params);
  }

  static async requestPutYTRevenueReport(data, id) {
    const endpoint = environment.api.ytRevenueReport + "/" + id;
    return EffectUtility.putToModel(YTRevenueReportPutModel, endpoint, data);
  }

  static async requestPostYTRevenueReport(data, callBack) {
    const endpoint = environment.api.ytRevenueReportFileUploader;
    let response = EffectUtility.postToModel(
      YTRevenueReportPostModel,
      endpoint,
      data
    );

    if (response && callBack) {
      response.then((resp) => {
        callBack(resp);
      });
    }

    return response;
  }
}
