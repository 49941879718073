import ActionUtility from "../../../../utilities/ActionUtility";
import TrackRevenueEffect from "./TrackRevenueEffect";

export default class TrackRevenueAction {
  static REQUEST_TRACK_REVENUE = "TrackRevenueAction.REQUEST_TRACK_REVENUE";
  static REQUEST_TRACK_REVENUE_FINISHED =
    "TrackRevenueAction.REQUEST_TRACK_REVENUE_FINISHED";

  static REQUEST_TRACK_REVENUE_CLEAR =
    "TrackRevenueAction.REQUEST_TRACK_REVENUE_CLEAR";
  static REQUEST_TRACK_REVENUE_CLEAR_FINISHED =
    "TrackRevenueAction.REQUEST_TRACK_REVENUE_CLEAR_FINISHED";

  static REQUEST_TRACKWISE_REVENUE_SUMMARY =
    "TrackRevenueAction.REQUEST_TRACKWISE_REVENUE_SUMMARY";
  static REQUEST_TRACKWISE_REVENUE_SUMMARY_FINISHED =
    "TrackRevenueAction.REQUEST_TRACKWISE_REVENUE_SUMMARY_FINISHED";

  // METHODS
  static requestTrackRevenue(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackRevenueAction.REQUEST_TRACK_REVENUE,
        TrackRevenueEffect.requestTrackRevenue,
        params
      );
    };
  }

  static requestTrackRevenueClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackRevenueAction.REQUEST_TRACK_REVENUE_CLEAR,
        TrackRevenueEffect.requestTrackRevenueClear
      );
    };
  }

  static requestTrackwiseRevenueSummary(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackRevenueAction.REQUEST_TRACKWISE_REVENUE_SUMMARY,
        TrackRevenueEffect.requestTrackwiseRevenueSummary,
        params
      );
    };
  }
}
