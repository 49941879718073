import BaseReducer from "../../../utilities/BaseReducer";
import KeywordConfigAction from "./KeywordConfigAction";

export default class KeywordConfigReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    keywordConfig: [],
  };

  //operation Filter
  [KeywordConfigAction.REQUEST_KEYWORD_CONFIG_FINISHED](state, action) {
    return {
      ...state,
      keywordConfig: action.payload,
    };
  }
}
