import { BaseModel } from "sjs-base-model";
import ResolvedTicketCountBucketsModel from "./ResolvedTicketCountBucketsModel";

export default class ResolvedTicketCountModel extends BaseModel {
  //Ticket resolved count data model

  buckets = [ResolvedTicketCountBucketsModel];
  doc_count_error_upper_bound = 0;
  sum_other_doc_count = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
