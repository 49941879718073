import BaseReducer from "../../utilities/BaseReducer";
import GoogleLoginAction from "./GoogleLoginAction";

// Google Login reducer function
export default class GoogleLoginReduser extends BaseReducer {
  initialState = {
    googleLogin: [],
  };

  [GoogleLoginAction.REQUEST_REQUEST_GOOGLE_LOGIN_FINISHED](state, action) {
    return {
      ...state,
      googleLogin: action.payload,
    };
  }
}
