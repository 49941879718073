import ActionUtility from "../../../../utilities/ActionUtility";

import DeviceResetEffect from "./DeviceResetEffect";

export default class DeviceResetAction {
  // get device mapping requests
  static REQUEST_DEVICE_MAPPING = "DeviceResetAction.REQUEST_DEVICE_MAPPING";
  static REQUEST_DEVICE_MAPPING_FINISHED =
    "DeviceResetAction.REQUEST_DEVICE_MAPPING_FINISHED";

  // delete device mapping requests
  static REQUEST_DELETE_DEVICE_MAPPING =
    "DeviceResetAction.REQUEST_DELETE_DEVICE_MAPPING";
  static REQUEST_DELETE_DEVICE_MAPPING_FINISHED =
    "DeviceResetAction.REQUEST_DELETE_DEVICE_MAPPING_FINISHED";

  //METHODS

  //get method for device mapping
  static requestDeviceMapping(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DeviceResetAction.REQUEST_DEVICE_MAPPING,
        DeviceResetEffect.requestDeviceMapping,
        params
      );
    };
  }

  //delete method for device reset
  static requestDeviceMapDelete(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DeviceResetAction.REQUEST_DELETE_DEVICE_MAPPING,
        DeviceResetEffect.requestDeviceMapDelete,
        id
      );
    };
  }
}
