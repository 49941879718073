import ActionUtility from "../../../../utilities/ActionUtility";
import TaskEffect from "./TaskEffect";

export default class TaskAction {
  // TaskAction action with an function declaration
  static REQUEST_TASK = "TaskAction.REQUEST_TASK";
  static REQUEST_TASK_FINISHED = "TaskAction.REQUEST_TASK_FINISHED";

  static REQUEST_TASK_FILTER = "TaskAction.REQUEST_TASK_FILTER";
  static REQUEST_TASK_FILTER_FINISHED =
    "TaskAction.REQUEST_TASK_FILTER_FINISHED";

  static REQUEST_PUT_TASK = "TaskAction.REQUEST_PUT_TASK";
  static REQUEST_PUT_TASK_FINISHED = "TaskAction.REQUEST_PUT_TASK_FINISHED";

  static REQUEST_POST_TASK = "TaskAction.REQUEST_POST_TASK";
  static REQUEST_POST_TASK_FINISHED = "TaskAction.REQUEST_POST_TASK_FINISHED";

  static REQUEST_TASK_CLEAR = "TaskAction.REQUEST_TASK_CLEAR";
  static REQUEST_TASK_CLEAR_FINISHED = "TaskAction.REQUEST_TASK_CLEAR_FINISHED";

  static REQUEST_TASK_FILTER_CLEAR = "TaskAction.REQUEST_TASK_FILTER_CLEAR";
  static REQUEST_TASK_FILTER_CLEAR_FINISHED =
    "TaskAction.REQUEST_TASK_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_TASK = "TaskAction.REQUEST_DELETE_TASK";
  static REQUEST_DELETE_TASK_FINISHED =
    "TaskAction.REQUEST_DELETE_TASK_FINISHED";

  // METHODS
  // Task GET function
  static requesTask(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TaskAction.REQUEST_TASK,
        TaskEffect.requesTask,
        params
      );
    };
  }

  static requesTaskClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TaskAction.REQUEST_TASK_CLEAR,
        TaskEffect.requesTaskClear
      );
    };
  }

  static requesTaskFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TaskAction.REQUEST_TASK_FILTER_CLEAR,
        TaskEffect.requesTaskFilterClear
      );
    };
  }

  static requestTaskFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TaskAction.REQUEST_TASK_FILTER,
        TaskEffect.requestTaskFilter,
        params
      );
    };
  }
  static requestPutTask(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TaskAction.REQUEST_PUT_TASK,
        TaskEffect.requestPutTask,
        data,
        id
      );
    };
  }

  static requestPostTask(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TaskAction.REQUEST_POST_TASK,
        TaskEffect.requestPostTask,
        data
      );
    };
  }
  static requestDeleteTask(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TaskAction.REQUEST_DELETE_TASK,
        TaskEffect.requestDeleteTask,
        id
      );
    };
  }
}
