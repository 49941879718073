import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import ReactEcharts from "echarts-for-react";

function PlatformwiseTicketsByCategoryChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter).then((res) => {
        setPlatformwiseTickets(res);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform_wise_ticket_by_category"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  let columns = [];

  columns.push(
    { field: "id", headerName: "Sl", resizable: false, width: 50, hide: true },
    {
      field: "Category",
      headerName: "Category",
      resizable: true,
      width: 200,
      hide: false,
    }
  );

  if (props.platform_list?.length) {
    props.platform_list?.map((col) => {
      columns.push({
        field: col?.platform_id,
        headerName: col?.platform_alias,
        resizable: true,
        width: 150,
        hide: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          const platforms = params?.row?.platforms || {}; // Safely get platforms
          const value =
            prefixZero_for_singleDigit(platforms[col.platform_id]) || "00"; // Get value or default to 0

          return (
            <Typography>
              {value} {/* Render the value */}
            </Typography>
          );
        },
      });
    });
  }

  let updatedRows = [];

  platformwiseTickets?.ticket_report?.platform_wise_ticket_by_category[0]?.children?.map(
    (item, index) => {
      updatedRows?.push({
        id: index + 1,
        Category: `${item?.value} (${item?.count})`,
        platforms: item?.platforms,
      });
    }
  );

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  const NoDataFoundBool = false;

  const handleClick = (params) => {
    // console.log(params);
  };

  function platformName(val) {
    return (
      props.platform_list &&
      props.platform_list
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  let barchartData = [];
  const categoryMap = {};

  updatedRows?.forEach((row) => {
    // Loop over the platforms object
    for (const [platformId, value] of Object.entries(row.platforms)) {
      const category = row.Category; // Get the category from the row
      const platformNameValue = platformName(platformId)[0]; // Get the platform name

      // Initialize the category if it doesn't exist
      if (!categoryMap[category]) {
        categoryMap[category] = {
          Category: category, // Store the category name
          platforms: {}, // Create an object to store platform values
        };
      }

      // Add the platform value to the corresponding category
      categoryMap[category].platforms[platformNameValue] =
        prefixZero_for_singleDigit(value);
    }
  });

  // Convert the categoryMap to the desired array format
  for (const category in categoryMap) {
    const platformData = {
      Category: categoryMap[category].Category, // Add category name
      ...categoryMap[category].platforms, // Spread the platforms object
    };
    barchartData.push(platformData); // Push the grouped data to barchartData
  }

  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const yAxisData = barchartData.reverse().map((item) => item.Category);

    const series = [];
    const keys = [];

    barchartData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (
          key !== "Category" &&
          key !== "total_tickets" &&
          key !== "id" &&
          !keys.includes(key)
        ) {
          keys.push(key);
        }
      });
    });

    keys.forEach((key, index) => {
      const seriesData = barchartData.map((item) => ({
        name: item.Category,
        value: item[key] ? parseInt(item[key]) : 0,
      }));

      series.push({
        name: key,
        type: "bar",
        stack: "total",
        label: {
          show: true,
          position: "insideRight",
          formatter: (params) => {
            let val = params.value;
            let formattedvalue = formatValue(val);
            return val === 0 ? "" : formattedvalue;
          },
          color: theme.palette.primary.dark,
        },
        emphasis: {
          focus: "series",
        },

        data: seriesData,
        itemStyle: {
          color: theme.platform[`color${index + 1}`], // Use theme colors dynamically
        },
      });
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              let val = item.value;
              let formattedvalue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName}: ${formattedvalue}<br/>`;
            }
          });
          return tooltipContent;
        },
      },
      legend: {
        icon: "circle",
        type: "scroll",

        data: shouldShowLegend ? keys : [],
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",

        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      labelLayout: {
        hideOverlap: true,
      },
      yAxis: {
        type: "category",
        data: yAxisData,
        axisLabel: {
          show: props.detailedReport ? true : false,
          padding: [0, 30, 0, 0],
          hideOverlap: true,
        },

        axisTick: {
          show: true,
        },

        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Category"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 0,
        },
      },

      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],

      xAxis: {
        type: "value",
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          formatter: (value) => formatValue(value),
          hideOverlap: true,
        },
      },
      series: series,
    };
  };
  useEffect(() => {
    if (props.DownloadClicked) {
      const allKeys = new Set();
      barchartData.forEach((item) => {
        Object.keys(item).forEach((key) => allKeys.add(key));
      });

      // Convert the Set to an array
      const uniqueKeys = Array.from(allKeys);

      // Step 2: Create exportData with all keys
      const exportData = barchartData.map((item) => {
        // Create a new object for each item
        const exportEntry = { Category: item.Category }; // Start with the Category

        // Assign values to the exportEntry for each unique key
        uniqueKeys.forEach((key) => {
          exportEntry[key] = item[key] || "00"; // Use item's value or 0 if key is absent
        });

        return exportEntry; // Return the new entry
      });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, barchartData]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading && !NoDataFoundBool ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption()}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: handleClick,
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default PlatformwiseTicketsByCategoryChart;
