import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  ListItem,
  Typography,
  SvgIcon,
  Collapse,
  FormControlLabel,
  Checkbox,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { alpha, styled } from "@mui/material/styles";
import { useSpring, animated } from "@react-spring/web";
import PropTypes from "prop-types";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import MuiTextField from "../../../../../components/muiTextField/MuiTextField";
import LoadingIndicator from "../../../../../components/loading-indicator/LoadingIndicator";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import NoDataFound from "../../../../../components/nodatafound/NoDataFound";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import TextArea from "antd/lib/input/TextArea";

// mapstate to props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const CategoryUpdateDialog = (props) => {
  // customticketfield_api
  // function fetchCustomticketfields() {
  //   const filter = [];

  //   filter.push(`["field_type.keyword","must","","match","category"]`);

  //   let paramater = {
  //     filters: `[${filter}]`,
  //   };
  //   props.dispatch(
  //     CustomTicketFieldsAction.requestCustomTicketFields(paramater)
  //   );
  // }

  // // customticketfield_api
  // function fetchCustomticketfieldsFilter() {
  //   const paramater = {
  //     page_limit: "none",
  //   };

  //   props.dispatch(
  //     CustomTicketFieldsAction.requestCustomTicketFieldsFilter(paramater)
  //   );
  // }

  let [submitLoading, setsubmitLoading] = useState(false);

  useEffect(() => {
    // fetchCustomticketfields();
    // fetchCustomticketfieldsFilter();
  }, []);

  let initial = {};

  //function for defining type of initial values for custom fields
  function setCustomFieldValueType(data) {
    if (data === "text") {
      return [{ value: "", id: "" }];
    } else if (data === "select") {
      return [];
    } else if (data === "date") {
      return [{ value: "", id: "" }];
    } else if (data === "multiselect") {
      return [];
    } else if (data === "radio") {
      return [{ value: "", id: "" }];
    } else if (data === "toggle") {
      return [{ value: "", id: "" }];
    } else if (data === "category") {
      return [];
    } else {
      return [];
    }
  }

  if (
    props.customticketfields.hits &&
    props.customticketfields.hits.hits.length !== 0
  ) {
    initial = {
      tree_value: props.customticketfields.hits.hits?.filter(
        (item) => item._source.field_type === "category"
      )[0]._source.field_values,
      checked:
        props.custom_value.length !== 0
          ? props.custom_value
              .filter((item) => {
                return item.field_type === "category";
              })
              .map((val) => {
                return val.field_values.length > 0
                  ? val.field_values[0]
                  : { id: "", value: "" };
              })[0]
          : { id: "", value: "" },
      bool: true,
    };
  }

  let customFieldInitialValues;

  if (
    props.ticketData &&
    props.ticketData._source?.custom_value?.length === 0
  ) {
    let custom_value = [];
    if (props.customticketfields.hits) {
      props.customticketfields.hits.hits.map((item) => {
        return custom_value.push({
          field_id: item._id,
          field_name: item._source.field_name,
          field_type: item._source.field_type,
          field_values: setCustomFieldValueType(item._source.field_type),
        });
      });
    }
    customFieldInitialValues = custom_value;
  }
  // initial values if (ticket custom_value = [{},{}]) < (project custom field = [{},{},{}])
  else if (
    props.ticketData &&
    props.ticketData._source?.custom_value?.length <
      (props.customticketfields.hits &&
        props.customticketfields.hits.hits.length)
  ) {
    let custom_value = [...props.ticketData._source?.custom_value];

    if (props.customticketfields.hits) {
      for (let i = 0; i < props.customticketfields.hits.hits.length; i++) {
        let isFound = false;

        for (let j = 0; j < custom_value.length; j++) {
          if (
            props.customticketfields.hits.hits[i]._id ===
            custom_value[j].field_id
          ) {
            isFound = true;
            break;
          }
        }
        if (isFound === false) {
          custom_value.push({
            field_id: props.customticketfields.hits.hits[i]._id,
            field_name:
              props.customticketfields.hits.hits[i]._source.field_name,
            field_type:
              props.customticketfields.hits.hits[i]._source.field_type,
            field_values: setCustomFieldValueType(
              props.customticketfields.hits.hits[i]._source.field_type
            ),
          });
        }
      }
      customFieldInitialValues = custom_value;
    }
  } else if (props.ticketData?._source) {
    let custom_value = [...props.ticketData._source?.custom_value];

    customFieldInitialValues = custom_value;
  }
  Object.assign(initial, {
    custom_value: customFieldInitialValues,
  });

  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }

  function CloseSquare(props) {
    return (
      <SvgIcon
        className="close"
        fontSize="inherit"
        style={{ width: 14, height: 14 }}
        {...props}
      >
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }

  const StyledTreeItem = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
  ))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      marginTop: 5,
      marginBottom: 5,
    },
  }));

  function TransitionComponent(props) {
    const style = useSpring({
      from: {
        opacity: 0,
        transform: "translate3d(20px,0,0)",
      },
      to: {
        opacity: props.in ? 1 : 0,
        transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      },
    });

    return (
      <animated.div style={style}>
        <Collapse {...props} />
      </animated.div>
    );
  }

  TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
  };

  function findIndexesById(nodes, id, indexes = []) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      indexes.push(i);
      if (node.id === id) {
        return indexes;
      } else if (node.sub_value && node.sub_value.length > 0) {
        const foundIndexes = findIndexesById(node.sub_value, id, [...indexes]);
        if (foundIndexes) {
          return foundIndexes;
        }
      }
      indexes.pop();
    }
    return null;
  }

  const renderTree = (nodes, index, setFieldValue, values, handleChange) => {
    const categoryUpdateMethod = (value, nodes, setFieldValue, values) => {
      value &&
        setFieldValue("checked", {
          id: nodes.id,
          value: nodes.value,
        });

      let custom_value_updated = [];

      values.custom_value.map((item) => {
        return item.field_type === "category"
          ? custom_value_updated.push({
              ...item,
              field_values: [{ id: nodes.id, value: nodes.value }],
            })
          : custom_value_updated.push(item);
      });

      setFieldValue("custom_value", custom_value_updated);
    };

    return (
      <StyledTreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <Grid
            // className="bg-warning"
            item
            container
            justifyContent={"flex-start"}
            alignItems="center"
          >
            <Grid item xl={9} lg={9} md={9} sm={9}>
              <Field
                as={TextArea}
                variant="outlined"
                readOnly={true}
                placeholder={`Enter Name`}
                size="small"
                name={`tree_value${findIndexesById(values.tree_value, nodes.id)
                  .map((i, ind) => {
                    return ind !==
                      findIndexesById(values.tree_value, nodes.id).length - 1
                      ? `.${i}.sub_value`
                      : `.${i}.value`;
                  })
                  .join("")}`}
                className={`${"textAreaFiled col-10"}`}
                width={"fit-content"}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      values.checked &&
                      values.checked.id === nodes.id &&
                      nodes.id
                    }
                  />
                }
                // label="Enable/Disable"
                name="checked"
                // value={values.checked}
                onChange={(e, value) => {
                  categoryUpdateMethod(value, nodes, setFieldValue, values);
                  setFieldValue("bool", false);
                }}
                // label={nodes.id}
              />
            </Grid>
          </Grid>
        }
        collapseIcon={<MinusSquare />}
        // expandIcon={<PlusSquare />}
        endIcon={<CloseSquare />}
      >
        <FieldArray
          name={`tree_value${findIndexesById(values.tree_value, nodes.id)
            .map((i) => `.${i}.sub_value`)
            .join("")}`}
        >
          {({ insert, remove, push }) => (
            <Grid container>
              {Array.isArray(nodes.sub_value) && (
                <>
                  {nodes.sub_value.length > 0 &&
                    nodes.sub_value.map((node, index) => {
                      return (
                        <Grid sx={{ display: "flex", width: "100%" }}>
                          <Grid sm={1} sx={{ marginRight: 1 }}>
                            <IconButton
                              onClick={() => {
                                remove(
                                  findIndexesById(nodes.sub_value, node.id)[0]
                                );
                              }}
                            >
                              {/* <DisabledByDefaultOutlinedIcon /> */}
                            </IconButton>
                          </Grid>
                          <Grid sm={11}>
                            {renderTree(
                              node,
                              index,
                              setFieldValue,
                              values,
                              handleChange
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  {
                    // <IconButton
                    //   onClick={() => {
                    //     push({
                    //       id: `${nodes.id}-${nodes.sub_value.length + 1}`,
                    //       value: "",
                    //       sub_value: [],
                    //     });
                    //   }}
                    // >
                    //   {/* <PlusSquare /> */}
                    // </IconButton>
                  }
                </>
              )}
            </Grid>
          )}
        </FieldArray>
      </StyledTreeItem>
    );
  };

  function ReturnNodeIdsForOpen(nodes, id, ids = []) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      ids.push(nodes[i].id);

      if (node.id === id) {
        return ids;
      } else if (node.sub_value && node.sub_value.length > 0) {
        const foundIndexes = ReturnNodeIdsForOpen(node.sub_value, id, [...ids]);
        if (foundIndexes) {
          return foundIndexes;
        }
      }
      ids.pop();
    }
    return null;
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="lg"
      TransitionComponent={Zoom}
      transitionDuration={350}
      sx={{
        backgroundColor: "transparent",
        "& .MuiDialog-paper": {
          borderRadius: "8px",
          boxShadow: "8px 8px 8px rgba(0, 0, 0, 0.4)",
        },
      }}
      //   className="bg-warning"
    >
      <DialogContent
        className=" p-0 m-0"
        sx={{
          border: "2px solid #c0bfbf",
        }}
      >
        <DialogContentText>
          <Box
            width="100%"
            // className="bg-success"
            sx={{
              height: "fit-content",
            }}
          >
            <Box
              // className="bg-warning"
              sx={{
                borderBottom: "1px solid #c0bfbf",
              }}
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                // className="bg-warning"
              >
                <Grid item px={1} sx={{ display: "flex" }}>
                  <Typography>
                    {/* <FontAwesomeIcon
                      icon={faFilter}
                      style={{
                        color: `${theme.palette.ticketListView.iconColor}`,
                      }}
                    ></FontAwesomeIcon> */}
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>C</span>
                    <span>ategory</span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>L</span>
                    <span>ist</span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>V</span>
                    <span>iew</span>
                  </Typography>
                </Grid>
                <Grid item px={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{
                        // color: `${theme.palette.ticketListView.iconColor}`,
                        fontSize: "20px",
                      }}
                    ></FontAwesomeIcon>
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            {props.customticketfields.hits &&
            props.customticketfields.hits.hits.length !== 0 &&
            props.customticketfields.hits.hits.filter(
              (item) => item._source.field_type === "category"
            )?.length > 0 &&
            props.customticketfields.hits.hits.filter(
              (item) => item._source.field_type === "category"
            )[0]._source.field_values.length > 0 ? (
              <Box width="100%" my={2}>
                <Box
                  width="100%"
                  //   className="bg-warning"
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Grid
                      item
                      container
                      xl={8}
                      lg={8}
                      md={8}
                      sm={8}
                      xs={8}
                      alignItems="center"
                      justifyContent={"center"}
                      //   className="bg-success"
                      flexDirection="column"
                    >
                      <Box
                        //   className="bg-warning"
                        width={"100%"}
                      >
                        <LoadingIndicator isActive={props.isRequesting}>
                          <Box width="100%">
                            <Formik
                              initialValues={initial}
                              validate={(values) => {
                                var errors = {};
                                if (!values.checked.id) {
                                  errors.checked = "Select Any Category ";
                                }
                                return errors;
                              }}
                              onSubmit={(values, actions) => {
                                setsubmitLoading(true);
                                props
                                  .dispatch(
                                    TicketsAction.requestPutTicket(
                                      JSON.stringify({
                                        custom_value: values.bool
                                          ? props.custom_value
                                          : values.custom_value,
                                      }),
                                      props.ticketData._id
                                    )
                                  )
                                  .then(() => {
                                    if (props.from === "ticketlist") {
                                      props.fetchFunction();
                                    }
                                    if (props.from === "ticketdetails") {
                                      props.dispatch(
                                        TicketsAction.requestTicketsById(
                                          props.ticketData._id
                                        )
                                      );
                                    }
                                    setsubmitLoading(false);

                                    props.onClose();
                                  });
                              }}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                                submitForm,
                                handleSubmit,
                                handleChange,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                  <FieldArray name="tree_value">
                                    {({ insert, remove, push }) => (
                                      <Grid container width="100%">
                                        {values.tree_value &&
                                          values.tree_value.length > 0 &&
                                          values.tree_value.map(
                                            (item, index) => (
                                              <Grid
                                                container
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Grid item sm={1}>
                                                  <ListItem
                                                    sx={{ cursor: "pointer" }}
                                                    title="Delete"
                                                    onClick={() => {
                                                      remove(index);
                                                    }}
                                                  >
                                                    {/* <DisabledByDefaultOutlinedIcon /> */}
                                                  </ListItem>
                                                </Grid>
                                                <Grid item sm={11}>
                                                  <TreeView
                                                    aria-label="customized"
                                                    defaultExpanded={
                                                      ReturnNodeIdsForOpen(
                                                        values.tree_value,
                                                        values.checked &&
                                                          values.checked.id
                                                      ) != null
                                                        ? ReturnNodeIdsForOpen(
                                                            values.tree_value,
                                                            values.checked.id
                                                          )
                                                        : [
                                                            values.tree_value[0]
                                                              .id,
                                                          ]
                                                    }
                                                    defaultCollapseIcon={
                                                      <MinusSquare />
                                                    }
                                                    defaultExpandIcon={
                                                      <PlusSquare />
                                                    }
                                                    defaultEndIcon={
                                                      <CloseSquare />
                                                    }
                                                    sx={{
                                                      height: "auto",
                                                      maxHeight: 500,
                                                      flexGrow: 1,
                                                      overflow: "auto",
                                                    }}
                                                  >
                                                    {renderTree(
                                                      item,
                                                      index,
                                                      setFieldValue,
                                                      values,
                                                      handleChange
                                                    )}
                                                  </TreeView>
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        {/* <ListItem
                                        sx={{ cursor: "pointer" }}
                                        title="Add"
                                        onClick={() => {
                                          push({
                                            id: `${
                                              values.tree_value.length + 1
                                            }`,
                                            value: "",
                                            sub_value: [],
                                          });
                                        }}
                                      >
                                        <AddBoxOutlinedIcon />
                                      </ListItem> */}
                                      </Grid>
                                    )}
                                  </FieldArray>

                                  <Grid
                                    item
                                    container
                                    // className="bg-warning"
                                    alignItems={"center"}
                                    justifyContent="center"
                                  >
                                    <ErrorMessage name="checked" />
                                  </Grid>

                                  <Grid
                                    item
                                    container
                                    // className="bg-warning"
                                    alignItems={"center"}
                                    justifyContent="center"
                                  >
                                    <MuiButton
                                      loading={submitLoading}
                                      type={"submit"}
                                      width="fit-content"
                                      title={"Submit"}
                                      name={"Submit"}
                                    />
                                  </Grid>
                                </Form>
                              )}
                            </Formik>
                          </Box>
                        </LoadingIndicator>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Box>
                <div className="nodataFoundContainer">
                  <p className="nodataFoundText">
                    To create categories, navigate to the custom field and
                    select ticket 'category'
                  </p>
                </div>
              </Box>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps)(CategoryUpdateDialog);
