import BaseReducer from "../../../../utilities/BaseReducer";
import OpportunityAction from "./OpportunityAction";

// OpportunityReduser
export default class OpportunityReducer extends BaseReducer {
  initialState = {
    Opportunity: [],
  };
  [OpportunityAction.REQUEST_OPPORTUNITY_FINISHED](state, action) {
    return {
      ...state,
      Opportunity: action.payload,
    };
  }

  [OpportunityAction.REQUEST_OPPORTUNITY_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Opportunity: action.payload,
    };
  }
}
