import React, { useEffect, useState } from "react";
import environment from "environment";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Description from "./descriptionContainer/Description";
import Logo from "./logoContainer/Logo";
import { Box } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";

const InstagramUserActivity = (props) => {
  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData().then((e) => {
        setData(e);
      });

      setLoading(false);
    })();
  }, []);

  async function fetchData() {
    let API = `${environment.api.reportsOnInstagram}?report_type=["count"]&reports=["instagram_activities_based_on_date_interval"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${environment.api.reportsOnInstagram}?report_type=["count"]&reports=["instagram_activities_based_on_date_interval"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     return response;
    //   });
  }

  let LabelList = [];
  let Comments = [];
  let Comment_Mentions = [];
  let Captions_Mention = [];

  data &&
    data.ticket_report &&
    data.ticket_report.instagram_activities_based_on_date_interval &&
    data.ticket_report.instagram_activities_based_on_date_interval.map(
      (value) => {
        LabelList.push(Object.keys(value)[0]);
        value &&
          value[Object.keys(value)].map((e) => {
            e["Comments"] >= 0 && Comments.push(e["Comments"]);
            e["Comment Mentions"] >= 0 &&
              Comment_Mentions.push(e["Comment Mentions"]);
            e["Captions Mention"] >= 0 &&
              Captions_Mention.push(e["Captions Mention"]);
          });
      }
    );

  const state = {
    labels: LabelList,
    datasets: [
      {
        label: "Comments",
        backgroundColor: `${theme.reports.positive}`,
        borderWidth: 1.5,
        borderColor: `${theme.reports.positive}`,
        data: Comments,
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
      {
        label: "Comment Mentions",
        backgroundColor: `${theme.reports.negative}`,
        data: Comment_Mentions,
        borderWidth: 1.5,
        borderColor: `${theme.reports.negative}`,
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
      {
        label: "Captions Mention",
        backgroundColor: `${theme.reports.neutral}`,
        data: Captions_Mention,
        borderWidth: 1.5,
        borderColor: `${theme.reports.neutral}`,
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
    ],
  };

  function TotalCount(item) {
    return item.length > 0 && item.reduce((prev, next) => prev + next);
  }

  return (
    <div
      style={{
        width: "100%",
        // padding: "15px",
        // border: "1px solid grey",
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} />
        <p
          style={{ fontSize: theme.reports.headerSize }}
          className="reportHeader"
        >
          {firstLetterOrange(props.report && props.report)}
        </p>

        <hr className="p-0 m-0 " />

        <br />

        <Box
          sx={{
            width: "100%",
            paddingX: 5,
          }}
        >
          <div
            style={{
              border: "1px solid grey",
              display: "flex",
              padding: "10px",
              fontSize: "20px",
              gap: "10px",
              height: "60px",
            }}
          >
            <div
              style={{
                width: "30%",
                height: "40px",
                color: `${theme.reports.positive}`,
              }}
            >
              <span>Comments</span>
              &nbsp;&nbsp;
              <span>{TotalCount(Comments)}</span>
            </div>

            <div
              style={{
                color: `${theme.reports.negative}`,
                width: "30%",
                height: "40px",
              }}
            >
              <span>Comment Mentions</span>&nbsp;&nbsp;
              <span>{TotalCount(Comment_Mentions)}</span>
            </div>
            <div
              style={{
                color: `${theme.reports.neutral}`,
                width: "30%",
                height: "40px",
              }}
            >
              <span>Caption Mentions</span>&nbsp;&nbsp;
              <span>{TotalCount(Captions_Mention)}</span>
            </div>
          </div>
          <br />
          <br />

          <div>
            <Line
              datasetIdKey="id"
              data={state}
              options={{
                animation: false,
                responsive: true,

                plugins: {
                  tooltip: {
                    usePointStyle: true,
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                      usePointStyle: true,
                    },
                  },
                },

                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Date",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "@ Comments, Comment Mentions & Caption Mentions",
                    },
                    // labelString: "Mention Count",
                    grid: {
                      display: true,
                    },
                  },
                },
              }}
            />
          </div>
        </Box>

        {window.location.pathname === "/ticketStatsReport" && (
          <Description
            description={props.description}
            callBack={props.callBack}
            report={props.report}
            index={0}
          />
        )}
      </LoadingIndicator>
    </div>
  );
};

export default InstagramUserActivity;
