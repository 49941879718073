import BaseReducer from "../../../../utilities/BaseReducer";
import SurveyDataAction from "./SurveyDataAction";

// SurveyDataRduser
export default class SurveyDataRduser extends BaseReducer {
  initialState = {
    surveyData: [],
  };
  [SurveyDataAction.REQUEST_SURVEY_DATA_FINISHED](state, action) {
    return {
      ...state,
      surveyData: action.payload,
    };
  }
}
