import BaseReducer from "../../../../utilities/BaseReducer";
import LeadSourceAction from "./LeadSourceAction";

// LeadSourceReduser
export default class LeadSourceReducer extends BaseReducer {
  initialState = {
    LeadSource: [],
  };
  [LeadSourceAction.REQUEST_LEADSOURCE_FINISHED](state, action) {
    return {
      ...state,
      LeadSource: action.payload,
    };
  }

  [LeadSourceAction.REQUEST_LEADSOURCE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      LeadSource: action.payload,
    };
  }
}
