import BaseReducer from "../../../../utilities/BaseReducer";
import AssetCollectionAction from "./AssetCollectionAction";

// MigrateTracksReuser
export default class AssetCollectionReducer extends BaseReducer {
  initialState = {
    collection: [],
  };
  [AssetCollectionAction.REQUEST_GET_COLLECTION_FINISHED](state, action) {
    return {
      ...state,
      collection: action.payload,
    };
  }
}
