import { Component } from "react";
import ActionUtility from "../../utilities/ActionUtility";
import CustomTicketFieldEffect from "./CustomTicketFieldsEffect";

export class CustomTicketFieldsAction extends Component {
  //customfields requests
  static REQUEST_CUSTOMTICKETFIELDS =
    "CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS";
  static REQUEST_CUSTOMTICKETFIELDS_FINISHED =
    "CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FINISHED";

  //customfields list (for the Filter dropdown)
  static REQUEST_CUSTOMTICKETFIELDS_FILTER =
    "CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER";
  static REQUEST_CUSTOMTICKETFIELDS_FILTER_FINISHED =
    "CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER_FINISHED";

  //customfields post
  static REQUEST_POST_CUSTOMTICKETFIELDS =
    "CustomTicketFieldsAction.REQUEST_POST_CUSTOMTICKETFIELDS";
  static REQUEST_POST_CUSTOMTICKETFIELDS_FINISHED =
    "CustomTicketFieldsAction.REQUEST_POST_CUSTOMTICKETFIELDS_FINISHED";

  //customfields put
  static REQUEST_PUT_CUSTOMTICKETFIELDS =
    "CustomTicketFieldsAction.REQUEST_PUT_CUSTOMTICKETFIELDS";
  static REQUEST_PUT_CUSTOMTICKETFIELDS_FINISHED =
    "CustomTicketFieldsAction.REQUEST_PUT_CUSTOMTICKETFIELDS_FINISHED";

  //customfields put Bulk
  static REQUEST_PUT_CUSTOMTICKETFIELDS_BULKUPDATE =
    "CustomTicketFieldsAction.REQUEST_PUT_CUSTOMTICKETFIELDS_BULKUPDATE";
  static REQUEST_PUT_CUSTOMTICKETFIELDS_BULKUPDATE_FINISHED =
    "CustomTicketFieldsAction.REQUEST_PUT_CUSTOMTICKETFIELDS_BULKUPDATE_FINISHED";

  //METHODS

  //customfields get method
  static requestCustomTicketFields(params) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS,
        CustomTicketFieldEffect.requestCustomTicketFields,
        params
      );
    };
  }

  // filter
  static requestCustomTicketFieldsFilter(params) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER,
        CustomTicketFieldEffect.requestCustomTicketFieldsFilter,
        params
      );
    };
  }

  //customticketfields post method
  static requestPostCustomTicketFields(data) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomTicketFieldsAction.REQUEST_POST_CUSTOMTICKETFIELDS,
        CustomTicketFieldEffect.requestPostCustomTicketFields,
        data
      );
    };
  }

  //customfields put method
  static requestPutCustomTicketFields(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      ActionUtility.createThunkEffect(
        dispatch,
        CustomTicketFieldsAction.REQUEST_PUT_CUSTOMTICKETFIELDS,
        CustomTicketFieldEffect.requestPutCustomTicketFields,
        data,
        id,
        callBackFunction
      );
    };
  }

  //customfields list get  method for filter dropdown
  static requestFilterCustomTicketFields(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER,
        CustomTicketFieldEffect.REQUEST_CUSTOMTICKETFIELDS_FILTER_FINISHED,
        params
      );
    };
  }

  // customfields Bulk put function
  static requestPutCustomefieldsBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomTicketFieldsAction.REQUEST_PUT_CUSTOMTICKETFIELDS_BULKUPDATE,
        CustomTicketFieldEffect.requestPutCustomefieldsBulkUpdate,
        data
      );
    };
  }
}

export default CustomTicketFieldsAction;
