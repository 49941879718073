import ActionUtility from "../../../../utilities/ActionUtility";
import CmsUploadLinksEffect from "./CmsUploadLinksEffect";

export default class CmsUploadLinksAction {
  static REQUEST_CMS_UPLOAD_LINKS =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS";
  static REQUEST_CMS_UPLOAD_LINKS_FINISHED =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FINISHED";

  static REQUEST_CMS_UPLOAD_LINKS_FILTER =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER";
  static REQUEST_CMS_UPLOAD_LINKS_FILTER_FINISHED =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER_FINISHED";

  static REQUEST_CMS_UPLOAD_LINKS_CLEAR =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_CLEAR";
  static REQUEST_CMS_UPLOAD_LINKS_CLEAR_FINISHED =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_CLEAR_FINISHED";

  static REQUEST_CMS_UPLOAD_LINKS_FILTER_CLEAR =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER_CLEAR";
  static REQUEST_CMS_UPLOAD_LINKS_FILTER_CLEAR_FINISHED =
    "CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER_CLEAR_FINISHED";

  static REQUEST_CMS_LINKS_VALIDATOR =
    "CmsUploadLinksAction.REQUEST_CMS_LINKS_VALIDATOR";
  static REQUEST_CMS_LINKS_VALIDATOR_FINISHED =
    "CmsUploadLinksAction.REQUEST_CMS_LINKS_VALIDATOR_FINISHED";

  static REQUEST_CMS_PROCESSING_STATUS =
    "CmsUploadLinksAction.REQUEST_CMS_PROCESSING_STATUS";
  static REQUEST_CMS_PROCESSING_STATUS_FINISHED =
    "CmsUploadLinksAction.REQUEST_CMS_PROCESSING_STATUS_FINISHED";

  // METHODS
  // CMS_UPLOAD_LINKS GET function
  static requestCmsUploadLinks(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS,
        CmsUploadLinksEffect.requestCmsUploadLinks,
        params
      );
    };
  }

  static requestCmsUploadLinksClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_CLEAR,
        CmsUploadLinksEffect.requestCmsUploadLinksClear
      );
    };
  }

  static requestCmsUploadLinksFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER_CLEAR,
        CmsUploadLinksEffect.requestCmsUploadLinksFilterClear
      );
    };
  }

  static requestCmsUploadLinksFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER,
        CmsUploadLinksEffect.requestCmsUploadLinksFilter,
        params
      );
    };
  }

  static requestCmsLinksValidator(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CmsUploadLinksAction.REQUEST_CMS_LINKS_VALIDATOR,
        CmsUploadLinksEffect.requestCmsLinksValidator,
        params
      );
    };
  }

  static requestCmsProcessingStatus(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CmsUploadLinksAction.REQUEST_CMS_PROCESSING_STATUS,
        CmsUploadLinksEffect.requestCmsProcessingStatus,
        params
      );
    };
  }
}
