import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import YoutubeAccountDeleteModel from "./model/YoutubeAccountDeleteModel";
import YoutubeAccountModel from "./model/YoutubeAccountModel";
import YoutubeAccountPutModel from "./model/YoutubeAccountPutModel";

// YouTube account Effect
export default class YoutubeAccountEffect {
  // calling an API according to the GoogleAccount model
  // Get method for YouTube account
  static async requestYoutubeAccount(params) {
    const endpoint = environment.api.youtubeAccount;
    return EffectUtility.getToModel(YoutubeAccountModel, endpoint, params);
  }

  // Get method for YouTube account filter
  static async requestYoutubeAccountFilter(params) {
    const endpoint = environment.api.youtubeAccountFilter;
    return EffectUtility.getToModel(YoutubeAccountModel, endpoint, params);
  }

  // put method for YouTube account filter
  static async requestPutYoutubeAccount(data, id) {
    const endpoint = environment.api.youtubeAccount + "/" + id;
    return EffectUtility.putToModel(YoutubeAccountPutModel, endpoint, data);
  }

  // delete method for YouTube account filter
  static async requestDeleteYoutubeAccount(id) {
    const endpoint = environment.api.youtubeAccount + "/" + id;
    return EffectUtility.deleteToModel(YoutubeAccountDeleteModel, endpoint);
  }
}
