import BaseReducer from "../../../../utilities/BaseReducer";
import SurveyReportAction from "./SurveyReportAction";

// reducer function
export default class SurveyReportReducer extends BaseReducer {
  initialState = {
    surveyReport: [],
  };

  [SurveyReportAction.REQUEST_SURVEY_REPORT_FINISHED](state, action) {
    return {
      ...state,
      surveyReport: action.payload,
    };
  }
}
