import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import InfluencerDeviceMapGetModel from "./models/InfluencerDeviceMappingGetModel";
import DeviceResetDeleteModel from "./models/InfluencerDevicerResetModel";

export default class InfluencerDeviceResetEffect {
  //get request endpoin for mobile device
  static async requestInfluencerDeviceMapping(params) {
    const endpoint = environment.api.influencerDeviceMapping;
    return EffectUtility.getToModel(
      InfluencerDeviceMapGetModel,
      endpoint,
      params
    );
  }

  //delete request endpoint for mobile device
  static async requestInfluencerDeviceMapDelete(id) {
    const endpoint = environment.api.influencerDeviceMapping + "/" + id;
    return EffectUtility.deleteToModel(DeviceResetDeleteModel, endpoint);
  }
}
