import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import FPplatformGetModel from "./models/FPplatformGetModel";

export default class FPplatformEffect {
  static async requestGetFPplatforms(params, callback) {
    const endpoint = environment.api.fingerPrintPlatforms;
    let response = EffectUtility.getToModel(
      FPplatformGetModel,
      endpoint,
      params
    );
    return response;
  }
}
