import ActionUtility from "../../../../utilities/ActionUtility";
import CampaignMappingEffect from "./CampaignMappingEffect";

export default class CampaignMappingAction {
  static REQUEST_CAMPAIGN_MAPPING =
    "CampaignMappingAction.REQUEST_CAMPAIGN_MAPPING";
  static REQUEST_CAMPAIGN_MAPPING_FINISHED =
    "CampaignMappingAction.REQUEST_CAMPAIGN_MAPPING_FINISHED";

  static REQUEST_CAMPAIGN_MAPPING_FILTER =
    "CampaignMappingAction.REQUEST_CAMPAIGN_MAPPING_FILTER";
  static REQUEST_CAMPAIGN_MAPPING_FILTER_FINISHED =
    "CampaignMappingAction.REQUEST_CAMPAIGN_MAPPING_FILTER_FINISHED";

  static REQUEST_POST_CAMPAIGN_MAPPING =
    "CampaignMappingAction.REQUEST_POST_CAMPAIGN_MAPPING";
  static REQUEST_POST_CAMPAIGN_MAPPING_FINISHED =
    "CampaignMappingAction.REQUEST_POST_CAMPAIGN_MAPPING_FINISHED";

  static REQUEST_PUT_CAMPAIGN_MAPPING =
    "ProjectAction.REQUEST_PUT_CAMPAIGN_MAPPING";
  static REQUEST_PUT_CAMPAIGN_MAPPING_FINISHED =
    "CampaignMappingAction.REQUEST_PUT_CAMPAIGN_MAPPING_FINISHED";

  static REQUEST_DELETE_CAMPAIGN_MAPPING =
    "ProjectAction.REQUEST_DELETE_CAMPAIGN_MAPPING";
  static REQUEST_DELETE_CAMPAIGN_MAPPING_FINISHED =
    "CampaignMappingAction.REQUEST_DELETE_CAMPAIGN_MAPPING_FINISHED";

  // METHODS

  static requestCampaignMapping(params, campaigncallback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMappingAction.REQUEST_CAMPAIGN_MAPPING,
        CampaignMappingEffect.requestCampaignMapping,
        params,
        campaigncallback
      );
    };
  }

  static requestCampaignMappingFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMappingAction.REQUEST_CAMPAIGN_MAPPING_FILTER,
        CampaignMappingEffect.requestCampaignMappingFilter,
        params
      );
    };
  }

  static requestPostCampaignMapping(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMappingAction.REQUEST_POST_CAMPAIGN_MAPPING,
        CampaignMappingEffect.requestPostCampaignMapping,
        data
      );
    };
  }

  // CLIENT put function
  static requestPutCampaignMapping(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMappingAction.REQUEST_PUT_CAMPAIGN_MAPPING,
        CampaignMappingEffect.requestPutCampaignMapping,
        data,
        id
      );
    };
  }

  static requestDeleteCampaignMapping(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMappingAction.REQUEST_DELETE_CAMPAIGN_MAPPING,
        CampaignMappingEffect.requestDeleteCampaignMapping,
        id
      );
    };
  }
}
