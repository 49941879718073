import BaseReducer from "../../../../utilities/BaseReducer";
import ClientAction from "./ClientAction";

// CLIENT by id Reducer
export default class ClientByIdReducer extends BaseReducer {
  initialState = {
    clientById: [],
  };
  [ClientAction.REQUEST_CLIENT_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      clientById: action.payload,
    };
  }
}
