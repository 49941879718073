import ActionUtility from "../../../../utilities/ActionUtility";
import DsrActivityShadowEffect from "./DsrActivityShadowEffect";

export default class DsrActivityShadowAction {
  // DsrActivityShadowAction action with an function declaration
  static REQUEST_GET_DSR_ACTIVITY_SHADOW =
    "DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_SHADOW";
  static REQUEST_GET_DSR_ACTIVITY_SHADOW_FINISHED =
    "DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_SHADOW_FINISHED";

  // METHODS
  static requestGetDsrActivityShadow(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityShadowAction.REQUEST_GET_DSR_ACTIVITY_SHADOW,
        DsrActivityShadowEffect.requestGetDsrActivityShadow,
        params,
        callback
      );
    };
  }
}
