import BaseReducer from "../../../utilities/BaseReducer";
import OrmReportLogoAction from "./OrmReportLogoAction";

export default class OrmReportLogoReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    ormReportLogo: [],
  };

  [OrmReportLogoAction.REQUEST_ORM_REPORT_LOGO_FINISHED](state, action) {
    return {
      ...state,
      ormReportLogo: action.payload,
    };
  }
}
