import BaseReducer from "../../../utilities/BaseReducer";
import RedditFeedAction from "./RedditFeedAction";

export default class RedditCommentsReduser extends BaseReducer {
  initialState = {
    redditComments: [],
  };

  [RedditFeedAction.REQUEST_REDDIT_COMMENTS_FINISHED](state, action) {
    return {
      ...state,
      redditComments: action.payload,
    };
  }
}
