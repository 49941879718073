import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import ApstoreDeleteModel from "./model/ApstoreDeleteModel";
import ThreadsAccountGetModel from "./model/ThreadsAccountGetModel";
import ThreadsPostModel from "./model/ThreadsPostModel";
import ThreadsPutModel from "./model/ThreadsPutModel";

//Threads Add account effect

export default class ThreadsAccountEffect {
  //get method of appstore account
  static async requestThreadsAccount(params) {
    const endpoint = environment.api.threadsAddAccount;

    return EffectUtility.getToModel(ThreadsAccountGetModel, endpoint, params);
  }

  //get method for appstore account filter dropdown
  static async requestThreadsAccountFilter(params) {
    const endpoint = environment.api.threadsAddAccountFilter;

    return EffectUtility.getToModel(ThreadsAccountGetModel, endpoint, params);
  }

  //post method of appstore account
  static async requestPostThreadsAccount(data) {
    const endpoint = environment.api.threadsAddAccount + "/add";

    return EffectUtility.postToModel(ThreadsPostModel, endpoint, data);
  }

  //put method of appstore account
  static async requestPutThreadsAccount(data, id) {
    const endpoint = environment.api.threadsAddAccount + "/" + id;

    return EffectUtility.putToModel(ThreadsPutModel, endpoint, data);
  }

  //DELETE method of appstore account
  static async requestDeleteThreadsAccount(id) {
    const endpoint = environment.api.threadsAddAccount + "/" + id;

    return EffectUtility.deleteToModel(ApstoreDeleteModel, endpoint);
  }
}
