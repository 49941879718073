import { BaseModel } from "sjs-base-model";

//Appstore Account put data model
export default class AppstoreAccountPutModel extends BaseModel {
  data = {};
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
