import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeOriginalAction from "./YoutubeOriginalAction";

// StatusReduser
export default class YoutubeTrackClaimsReduser extends BaseReducer {
  initialState = {
    youtubeTrackClaims: [],
  };
  [YoutubeOriginalAction.REQUEST_YOUTUBETRACKCLAIMS_FINISHED](state, action) {
    return {
      ...state,
      youtubeTrackClaims: action.payload,
    };
  }

  [YoutubeOriginalAction.REQUEST_YOUTUBETRACKCLAIMS_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeTrackClaims: action.payload,
    };
  }
}
