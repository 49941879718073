import environment from "environment";
import FaqGetModel from "./models/FaqGetModel";
import FaqModulesGetModel from "./models/FaqModulesGetModel";
import FaqTopicsGetModel from "./models/FaqTopicsGetModel";
import EffectUtility from "../../../utilities/EffectUtility";
import FaqPostModel from "./models/FaqPostModel";

export default class FaqEffect {
  static async requestGetFaq(params, callback) {
    const endpoint = environment.api.faq;
    let response = EffectUtility.getToModel(FaqGetModel, endpoint, params);
    return response;
  }

  static async requestPostFaq(data) {
    const endpoint = environment.api.faq + "/add";
    return EffectUtility.postToModel(FaqPostModel, endpoint, data);
  }

  static async requestPutFaq(data, id) {
    const endpoint = environment.api.faq + "/" + id;
    return EffectUtility.putToModel(FaqPostModel, endpoint, data);
  }

  static async requestGetFaqModules(params, callback) {
    const endpoint = environment.api.faqModules;
    let response = EffectUtility.getToModel(
      FaqModulesGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetFaqTopics(params, module, callback) {
    const endpoint = environment.api.faqTopics + (module ? `/${module}` : "");

    let response = EffectUtility.getToModel(
      FaqTopicsGetModel,
      endpoint,
      params
    );
    return response;
  }
}
