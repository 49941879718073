import BaseReducer from "../../../../utilities/BaseReducer";
import ClientAction from "./ClientAction";

// StatusReduser
export default class ClientReduser extends BaseReducer {
  initialState = {
    clientA3: [],
  };
  [ClientAction.REQUEST_CLIENT_FINISHED](state, action) {
    return {
      ...state,
      clientA3: action.payload,
    };
  }

  [ClientAction.REQUEST_CLIENT_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      clientA3: action.payload,
    };
  }
}
