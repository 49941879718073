import BaseReducer from "../../../utilities/BaseReducer";
import RolesAction from "./RolesAction";

export default class RolesReducer extends BaseReducer {
  initialState = {
    roles: [],
  };

  [RolesAction.REQUEST_ROLES_FINISHED](state, action) {
    return {
      ...state,
      roles: action.payload,
    };
  }
}
