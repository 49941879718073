import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TerritoriesModel from "./model/TerritoriesModel";

// TerritoriesEffect
export default class TerritoriesEffect {
  // calling an API according to the TerritoriesEffect model
  static async requestTerritories(params, releasePostCallBack) {
    const endpoint = environment.api.territories;
    let response = EffectUtility.getToModel(TerritoriesModel, endpoint, params);

    response &&
      releasePostCallBack &&
      response.then((resp) => {
        releasePostCallBack(resp);
      });
    return response;
  }

  // Get method for the territories filter
  static async requestTerritoriesFilter(params) {
    const endpoint = environment.api.territories;
    return EffectUtility.getToModel(TerritoriesModel, endpoint, params);
  }
}
