import { BaseModel } from "sjs-base-model";
import WordCloudSourceModel from "./WordCloudSourceModel";

export default class WordCloudDataHitsModel extends BaseModel {
  _id = "";
  _index = "";
  _score = 0.0;
  _source = [WordCloudSourceModel];

  constructor(data) {
    super();

    this.update(data);
  }
}
