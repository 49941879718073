import { BaseModel } from "sjs-base-model";
import FacebookShardsModel from "./FacebookShardsModel";
import FacebookHitsModel from "./FacebookHitsModel";

// get model for project // twitter api fetch model
export default class FacebookModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = {};
  hits = {};
  total_page_no = 0;
  current_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
