import BaseReducer from "../../../utilities/BaseReducer";
import QuoraFeedsAction from "./QuoraFeedsAction";

// QuoraFeedsReduser  function
export default class QuoraFeedsReduser extends BaseReducer {
  initialState = {
    quoraFeed: [],
  };
  [QuoraFeedsAction.REQUEST_QUORA_FEED_FINISHED](state, action) {
    return {
      ...state,
      quoraFeed: action.payload,
    };
  }

  [QuoraFeedsAction.REQUEST_QUORA_FEED_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      quoraFeed: action.payload,
    };
  }
}
