import BaseReducer from "../../../../utilities/BaseReducer";
import LinkTransferSiteDataAction from "./LinkTransferSiteDataAction";

export default class LinkTransferSiteDataFilterReduser extends BaseReducer {
  initialState = {
    LinkTransferSiteDataFilter: [],
  };
  [LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      LinkTransferSiteDataFilter: action.payload,
    };
  }

  //   [LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER_CLEAR_FINISHED](
  //     state,
  //     action
  //   ) {
  //     return {
  //       ...state,
  //       LinkTransferSiteDataFilter: action.payload,
  //     };
  //   }
}
