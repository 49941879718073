import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import PageTaggedPostGetModel from "./models/PageTaggedPostGetModel";

export default class PageTaggedPostsEffect {
  static async requestPageTaggedPosts(params) {
    const endpoint = environment.api.pageTaggedPost;
    return EffectUtility.getToModel(PageTaggedPostGetModel, endpoint, params);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
