import BaseReducer from "../../../utilities/BaseReducer";
import GoogleAccountAction from "./GoogleAccountAction";
export default class GoogleAccountFilterReducer extends BaseReducer {
  //initial state of GoogleAccount
  initialState = {
    googleAccountFilter: [],
  };
  //GoogleAccount request action finish
  [GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      googleAccountFilter: action.payload,
    };
  }
}
