import ActionUtility from "../../../../utilities/ActionUtility";
import McnFeedsEffect from "./McnFeedsEffect";
export default class McnFeedsAction {
  // McnFeedsAction action with an function declaration
  static REQUEST_MCN_FEEDS = "McnFeedsAction.REQUEST_MCN_FEEDS";
  static REQUEST_MCN_FEEDS_FINISHED =
    "McnFeedsAction.REQUEST_MCN_FEEDS_FINISHED";

  static REQUEST_MCN_FEEDS_FILTER = "McnFeedsAction.REQUEST_MCN_FEEDS_FILTER";
  static REQUEST_MCN_FEEDS_FILTER_FINISHED =
    "McnFeedsAction.REQUEST_MCN_FEEDS_FILTER_FINISHED";

  // METHODS
  // instagram GET function
  static requestMcnFeeds(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        McnFeedsAction.REQUEST_MCN_FEEDS,
        McnFeedsEffect.requestMcnFeeds,
        params
      );
    };
  }

  static requestMcnFeedsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        McnFeedsAction.REQUEST_MCN_FEEDS_FILTER,
        McnFeedsEffect.requestMcnFeedsFilter,
        params
      );
    };
  }
}
