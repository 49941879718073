import ActionUtility from "../../../utilities/ActionUtility";
import WhatsappAccountEffect from "./WhatsappAccountEffect";

export default class WhatsappAccountAction {
  // WhatsappAccountAction action with an function declaration
  static REQUEST_WHATSAPP_ACCOUNT =
    "WhatsappAccountAction.REQUEST_WHATSAPP_ACCOUNT";
  static REQUEST_WHATSAPP_ACCOUNT_FINISHED =
    "WhatsappAccountAction.REQUEST_WHATSAPP_ACCOUNT_FINISHED";

  static REQUEST_PUT_WHATSAPP_ACCOUNT =
    "WhatsappAccountAction.REQUEST_PUT_WHATSAPP_ACCOUNT";
  static REQUEST_PUT_WHATSAPP_ACCOUNT_FINISHED =
    "WhatsappAccountAction.REQUEST_PUT_WHATSAPP_ACCOUNT_FINISHED";

  static REQUEST_POST_WHATSAPP_ACCOUNT =
    "WhatsappAccountAction.REQUEST_POST_WHATSAPP_ACCOUNT";
  static REQUEST_POST_WHATSAPP_ACCOUNT_FINISHED =
    "WhatsappAccountAction.REQUEST_POST_WHATSAPP_ACCOUNT_FINISHED";

  static REQUEST_DELETE_WHATSAPP_ACCOUNT =
    "WhatsappAccountAction.REQUEST_DELETE_WHATSAPP_ACCOUNT";
  static REQUEST_DELETE_WHATSAPP_ACCOUNT_FINISHED =
    "WhatsappAccountAction.REQUEST_DELETE_WHATSAPP_ACCOUNT_FINISHED";

  // METHODS
  // whatsapp GET function
  static requestWhatsappAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsappAccountAction.REQUEST_WHATSAPP_ACCOUNT,
        WhatsappAccountEffect.requestWhatsappAccount,
        params
      );
    };
  }

  static requestPutWhatsappAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsappAccountAction.REQUEST_PUT_WHATSAPP_ACCOUNT,
        WhatsappAccountEffect.requestPutWhatsappAccount,
        data,
        id
      );
    };
  }

  // whatsapp post function
  static requestPostWhatsappAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsappAccountAction.REQUEST_POST_WHATSAPP_ACCOUNT,
        WhatsappAccountEffect.requestPostWhatsappAccount,
        data
      );
    };
  }

  // whatsapp delete account
  static requestDeleteWhatsappAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsappAccountAction.REQUEST_DELETE_WHATSAPP_ACCOUNT,
        WhatsappAccountEffect.requestDeleteWhatsappAccount,
        id
      );
    };
  }
}
