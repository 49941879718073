import { BaseModel } from "sjs-base-model";

// Segment Data Model
export default class CloudSegmentDataModel extends BaseModel {
  // response getting from the api mentioned end point

  id = "";
  mailing_list_id = "";
  name = "";
  description = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
