import BaseReducer from "../../../../utilities/BaseReducer";
import LinkStatusAction from "./LinkStatusAction";

export default class LinkStatusFilterReduser extends BaseReducer {
  initialState = {
    linkStatusFilter: [],
  };
  [LinkStatusAction.REQUEST_LINK_STATUS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      linkStatusFilter: action.payload,
    };
  }
}
