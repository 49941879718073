import BaseReducer from "../../../../utilities/BaseReducer";
import LinkCheckingAction from "./LinkCheckingAction";

export default class LinkCheckPlatformFilterReducer extends BaseReducer {
  initialState = {
    linkCheckPlatformsFilter: [],
  };
  [LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORM_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      linkCheckPlatformsFilter: action.payload,
    };
  }
}
