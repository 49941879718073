import ActionUtility from "../../../../utilities/ActionUtility";
import ScrapeLinksEffect from "./ScrapeLinksEffect";

export default class ScrapeLinksAction {
  // ScrapeLinksAction action with an function declaration
  static REQUEST_SCRAPELINKS = "ScrapeLinksAction.REQUEST_SCRAPELINKS";
  static REQUEST_SCRAPELINKS_FINISHED =
    "ScrapeLinksAction.REQUEST_SCRAPELINKS_FINISHED";

  static REQUEST_SCRAPELINKS_FILTER =
    "ScrapeLinksAction.REQUEST_SCRAPELINKS_FILTER";
  static REQUEST_SCRAPELINKS_FILTER_FINISHED =
    "ScrapeLinksAction.REQUEST_SCRAPELINKS_FILTER_FINISHED";

  // METHODS
  // ScrapeLinksAction GET function
  static requestScrapeLinks(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ScrapeLinksAction.REQUEST_SCRAPELINKS,
        ScrapeLinksEffect.requestScrapeLinks,
        params
      );
    };
  }

  static requestScrapeLinksFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ScrapeLinksAction.REQUEST_SCRAPELINKS_FILTER,
        ScrapeLinksEffect.requestScrapeLinksFilter,
        params
      );
    };
  }
}
