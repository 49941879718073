import ActionUtility from "../../../../utilities/ActionUtility";
import RevenueClaimEffect from "./RevenueClaimEffect";

export default class RevenueClaimAction {
  static REQUEST_REVENUE_CLAIM = "RevenueClaimAction.REQUEST_REVENUE_CLAIM";
  static REQUEST_REVENUE_CLAIM_FINISHED =
    "RevenueClaimAction.REQUEST_REVENUE_CLAIM_FINISHED";

  static REQUEST_CLIENT_REVENUE_BALANCE =
    "RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE";
  static REQUEST_CLIENT_REVENUE_BALANCE_FINISHED =
    "RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE_FINISHED";

  static REQUEST_GENERATE_REVENUE_CLAIM =
    "RevenueClaimAction.REQUEST_GENERATE_REVENUE_CLAIM";
  static REQUEST_GENERATE_REVENUE_CLAIM_FINISHED =
    "RevenueClaimAction.REQUEST_GENERATE_REVENUE_CLAIM_FINISHED";

  static REQUEST_CLIENTWISE_REVENUE_CLAIM =
    "RevenueClaimAction.REQUEST_CLIENTWISE_REVENUE_CLAIM";
  static REQUEST_CLIENTWISE_REVENUE_CLAIM_FINISHED =
    "RevenueClaimAction.REQUEST_CLIENTWISE_REVENUE_CLAIM_FINISHED";

  static REQUEST_REVENUE_ADVANCE = "RevenueClaimAction.REQUEST_REVENUE_ADVANCE";
  static REQUEST_REVENUE_ADVANCE_FINISHED =
    "RevenueClaimAction.REQUEST_REVENUE_ADVANCE_FINISHED";

  static REQUEST_GENERATE_CLIENT_INVOICE =
    "RevenueClaimAction.REQUEST_GENERATE_CLIENT_INVOICE";
  static REQUEST_GENERATE_CLIENT_INVOICE_FINISHED =
    "RevenueClaimAction.REQUEST_GENERATE_CLIENT_INVOICE_FINISHED";

  // METHODS
  static requestRevenueClaim(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_REVENUE_CLAIM,
        RevenueClaimEffect.requestRevenueClaim,
        params
      );
    };
  }

  static requestClientRevenueBalance(clientId) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE,
        RevenueClaimEffect.requestClientRevenueBalance,
        clientId
      );
    };
  }

  static requestGenerateRevenueClaim(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_GENERATE_REVENUE_CLAIM,
        RevenueClaimEffect.requestGenerateRevenueClaim,
        data
      );
    };
  }

  static requestClientwiseRevenueClaim(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_CLIENTWISE_REVENUE_CLAIM,
        RevenueClaimEffect.requestClientwiseRevenueClaim,
        params
      );
    };
  }

  // Advance Payment Request
  static requestAdvancePayment(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_REVENUE_ADVANCE,
        RevenueClaimEffect.requestAdvancePayment,
        params
      );
    };
  }

  static requestGenerateClientInvoice(data, id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_GENERATE_CLIENT_INVOICE,
        RevenueClaimEffect.requestGenerateClientInvoice,
        data,
        id,
        params,
        callBack
      );
    };
  }
}
