import ActionUtility from "../../../../utilities/ActionUtility";
import LinkTransferSiteDataEffect from "./LinkTransferSiteDataEffect";

export default class LinkTransferSiteDataAction {
  static REQUEST_LINK_TRANSFER_SITE_DATA =
    "LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA";
  static REQUEST_LINK_TRANSFER_SITE_DATA_FINISHED =
    "LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA_FINISHED";

  static REQUEST_POST_LINK_TRANSFER_SITE_DATA =
    "LinkTransferSiteDataAction.REQUEST_POST_LINK_TRANSFER_SITE_DATA";
  static REQUEST_POST_LINK_TRANSFER_SITE_DATA_FINISHED =
    "LinkTransferSiteDataAction.REQUEST_POST_LINK_TRANSFER_SITE_DATA_FINISHED";

  static REQUEST_PUT_LINK_TRANSFER_SITE_DATA =
    "LinkTransferSiteDataAction.REQUEST_PUT_LINK_TRANSFER_SITE_DATA";
  static REQUEST_PUT_LINK_TRANSFER_SITE_DATA_FINISHED =
    "LinkTransferSiteDataAction.REQUEST_PUT_LINK_TRANSFER_SITE_DATA_FINISHED";

  static REQUEST_LINK_TRANSFER_SITE_DATA_FILTER =
    "LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA_FILTER";
  static REQUEST_LINK_TRANSFER_SITE_DATA_FILTER_FINISHED =
    "LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA_FILTER_FINISHED";

  static requestLinkTransferSiteData(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA,
        LinkTransferSiteDataEffect.requestLinkTransferSiteData,
        params
      );
    };
  }

  static requestLinkTransferSiteDataFilter(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA_FILTER,
        LinkTransferSiteDataEffect.requestLinkTransferSiteDataFilter,
        params,
        callBack
      );
    };
  }

  static requestPostLinkTransferSiteData(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkTransferSiteDataAction.REQUEST_POST_LINK_TRANSFER_SITE_DATA,
        LinkTransferSiteDataEffect.requestPostLinkTransferSiteData,
        data
      );
    };
  }

  static requestPutLinkTransferSiteData(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkTransferSiteDataAction.REQUEST_PUT_LINK_TRANSFER_SITE_DATA,
        LinkTransferSiteDataEffect.requestPutLinkTransferSiteData,
        data,
        id
      );
    };
  }
}
