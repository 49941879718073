import ActionUtility from "../../../utilities/ActionUtility";
import QuoraFeedEffect from "./QuoraFeedEffect";

export default class QuoraFeedsAction {
  // QuoraFeedsAction  with an function declaration
  static REQUEST_QUORA_FEED = "QuoraFeedsAction.REQUEST_QUORA_FEED";
  static REQUEST_QUORA_FEED_FINISHED =
    "QuoraFeedsAction.REQUEST_QUORA_FEED_FINISHED";

  static REQUEST_QUORA_FEED_FILTER =
    "QuoraFeedsAction.REQUEST_QUORA_FEED_FILTER";
  static REQUEST_QUORA_FEED_FILTER_FINISHED =
    "QuoraFeedsAction.REQUEST_QUORA_FEED_FILTER_FINISHED";

  static REQUEST_PUT_QUORA_FEED = "QuoraFeedsAction.REQUEST_PUT_QUORA_FEED";
  static REQUEST_PUT_QUORA_FEED_FINISHED =
    "QuoraFeedsAction.REQUEST_PUT_QUORA_FEED_FINISHED";

  static REQUEST_POST_QUORA_FEED = "QuoraFeedsAction.REQUEST_POST_QUORA_FEED";
  static REQUEST_POST_QUORA_FEED_FINISHED =
    "QuoraFeedsAction.REQUEST_POST_QUORA_FEED_FINISHED";

  static REQUEST_QUORA_FEED_CLEAR = "QuoraFeedsAction.REQUEST_QUORA_FEED_CLEAR";
  static REQUEST_QUORA_FEED_CLEAR_FINISHED =
    "QuoraFeedsAction.REQUEST_QUORA_FEED_CLEAR_FINISHED";

  // METHODS
  // Quora Feeds GET function
  static requestQuoraFeed(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraFeedsAction.REQUEST_QUORA_FEED,
        QuoraFeedEffect.requestQuoraFeed,
        params,
        callBackFunction
      );
    };
  }

  static requestQuoraFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraFeedsAction.REQUEST_QUORA_FEED_CLEAR,
        QuoraFeedEffect.requestQuoraFeedClear
      );
    };
  }

  static requestQuoraFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraFeedsAction.REQUEST_QUORA_FEED_FILTER,
        QuoraFeedEffect.requestQuoraFeedFilter,
        params
      );
    };
  }
  static requestPutQuoraFeed(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraFeedsAction.REQUEST_PUT_QUORA_FEED,
        QuoraFeedEffect.requestPutQuoraFeed,
        data,
        id
      );
    };
  }

  // Quora Feeds post function
  static requestPostQuoraFeed(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraFeedsAction.REQUEST_POST_QUORA_FEED,
        QuoraFeedEffect.requestPostQuoraFeed,
        data
      );
    };
  }
}
