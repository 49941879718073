import BaseReducer from "../../../utilities/BaseReducer";
import AutoresponseAction from "./AutoresponseAction";

export default class AutoResponsefilterReducer extends BaseReducer {
  initialState = {
    autoResponseFilter: [],
  };

  [AutoresponseAction.REQUEST_AUTORESPONSE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      autoResponseFilter: action.payload,
    };
  }
}
