import ActionUtility from "../../../utilities/ActionUtility";
import UserLastWorkingProjectEffect from "./UserLastWorkingProjectEffect";

export default class UserLastWorkingProjectAction {
  //tickets requests
  //   static REQUEST_TICKETS = "TicketsAction.REQUEST_TICKETS";
  //   static REQUEST_TICKETS_FINISHED = "TicketsAction.REQUEST_TICKETS_FINISHED";

  //tickets requests
  static REQUEST_LAST_WORKING_PROJECT_BY_ID =
    "UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID";
  static REQUEST_LAST_WORKING_PROJECT_BY_ID_FINISHED =
    "UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID_FINISHED";

  //tickets post
  static REQUEST_POST_LAST_WORKING_PROJECT =
    "UserLastWorkingProjectAction.REQUEST_POST_LAST_WORKING_PROJECT";
  static REQUEST_POST_LAST_WORKING_PROJECT_FINISHED =
    "UserLastWorkingProjectAction.REQUEST_POST_LAST_WORKING_PROJECT_FINISHED";

  //METHODS

  //Ticket list get method
  //   static requestTickets(params) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         UserLastWorkingProjectAction.REQUEST_TICKETS,
  //         TicketsEffect.requestTickets,
  //         params
  //       );
  //     };
  //   }

  //Ticket list get method by id
  static requestLastWorkingProjectById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
        UserLastWorkingProjectEffect.requestLastWorkingProjectById,
        id
      );
    };
  }

  //Ticket POST method
  static requestPostLastWorkingProject(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserLastWorkingProjectAction.REQUEST_POST_LAST_WORKING_PROJECT,
        UserLastWorkingProjectEffect.requestPostLastWorkingProject,
        data,
        callBackFunction
      );
    };
  }
}
