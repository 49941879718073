import { BaseModel } from "sjs-base-model";

export default class TicketCountModel extends BaseModel {
  //Dashboard data model

  data = [];
  unassigned = 0;
  Total_tickets = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
