import BaseReducer from "../../../../utilities/BaseReducer";
import SitesExecutionLogsAction from "./SitesExecutionLogsAction";

export default class SitesExecutionLogReduser extends BaseReducer {
  initialState = {
    websweepXSitesExecutionLogs: [],
  };
  [SitesExecutionLogsAction.REQUEST_GET_SITES_EXECUTION_LOGS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepXSitesExecutionLogs: action.payload,
    };
  }
}
