import { BaseModel } from "sjs-base-model";

export default class WsxStatsReportModel extends BaseModel {
  // status = "";
  last_run = "";
  properties_mapped = "";
  total_host = "";
  total_host_links = {};

  constructor(data) {
    super();
    this.update(data);
  }
}
