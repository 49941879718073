import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ContactModel from "./model/ContactModel";
import ContactPostModel from "./model/ContactPostModel";
import ContactPutModel from "./model/ContactPutModel";

// ContactEffect
export default class ContactEffect {
  // calling an API according to the Contact model
  static async requesContact(params) {
    const endpoint = environment.api.contact;
    return EffectUtility.getToModel(ContactModel, endpoint, params);
  }

  static async requesContactClear() {
    return {};
  }

  static async requesContactFilterClear() {
    return {};
  }

  // Get method for the Contact filter
  static async requestContactFilter(params) {
    const endpoint = environment.api.contact;
    return EffectUtility.getToModel(ContactModel, endpoint, params);
  }

  // put method for the Contact
  static async requestPutContact(data, id) {
    const endpoint = environment.api.contact + "/" + id;
    return EffectUtility.putToModel(ContactPutModel, endpoint, data);
  }

  // post method for the Contact
  static async requestPostContact(data) {
    const endpoint = environment.api.contact + "/add";
    return EffectUtility.postToModel(ContactPostModel, endpoint, data);
  }
  static async requestDeleteContact(id) {
    const endpoint = environment.api.contact + "/" + id;
    return EffectUtility.deleteToModel(ContactPostModel, endpoint);
  }
}
