import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import RedditAccountAction from "../../stores/ticketWorkedOn/redditAccount/RedditAccountAction";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};
const validationSchema = yup.object({
  account_name: yup
    .string("Enter your Account name")
    .trim()
    .min(1, "Account name should be of minimum 1 characters length")
    .required("Account name is required"),
});

const AddRedditAccount = (props) => {
  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method
  let initial = {};

  if (props.redditAccountData) {
    initial = {
      account_name: props.redditAccountData.account_name,
      keywords: [],
    };
  } else {
    initial = {
      account_name: "",
      keywords: [],
    };
  }

  useEffect(() => {
    if (props.redditAccountData && props.redditAccountData.keywords) {
      props.redditAccountData.keywords.map((item) => {
        theArray.push(item);
      });
    } else {
      const InitialArray = [];
      setTheArray(InitialArray);
    }
  }, [props.open]);

  const [fieldtypeValues, setFieldtypeValues] = useState("");
  const [theArray, setTheArray] = useState([]);

  const addEntryClick = () => {
    if (fieldtypeValues > 1 || fieldtypeValues.trim()) {
      setTheArray([...theArray, fieldtypeValues]);
    }

    setFieldtypeValues("");
  };

  const handleChangeFor = (event) => {
    if (
      event.target.value.length > 1 ||
      event.target.value !== null ||
      event.target.value.trim()
    ) {
      setFieldtypeValues(event.target.value);
    }
  };

  const deleteEntry = (index) => {
    const updatedArray = [...theArray];
    updatedArray.splice(index, 1);
    setTheArray(updatedArray);
  };

  const [isFormSumbitted, SetIsFormSubmitted] = useState(false);

  function Required() {
    return (
      <>
        <span>Atleast one keyword is required</span>{" "}
        <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        validate={(values) => {
          var errors = {};
          if (!theArray.length) {
            errors.showerror = "Atleast one keyword is required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          setLoading(true);
          let obj = {};

          Object.assign(
            obj,
            values.account_name && { account_name: values.account_name },
            theArray.length > 0 && { keywords: theArray }
          );

          if (props.redditAccountData) {
            props
              .dispatch(
                RedditAccountAction.requestPutRedditAccount(
                  JSON.stringify(obj),
                  props.redditAccountData._id
                )
              )
              .then(() => {
                setLoading(false);

                let params = {
                  page_limit: "none",
                };
                let account_params = {
                  page_limit: "none",
                  order_by: '[["created_on", "desc"]]',
                };
                props.onClose();
                props.setAdd_account("");
                props.dispatch(
                  PlatformAccountsAction.requestPlatformAccount(account_params)
                );
                props.dispatch(
                  PlatformAccountsAction.requestPlatformAccountFilter(params)
                );
              });
          } else {
            props
              .dispatch(
                RedditAccountAction.requestPostRedditAccount(
                  JSON.stringify(obj)
                )
              )
              .then(() => {
                setLoading(false);

                let params = {
                  page_limit: "none",
                };
                let account_params = {
                  page_limit: "none",
                  order_by: '[["created_on", "desc"]]',
                };
                props.onClose();
                props.setAdd_account("");
                props.dispatch(
                  PlatformAccountsAction.requestPlatformAccount(account_params)
                );
                props.dispatch(
                  PlatformAccountsAction.requestPlatformAccountFilter(params)
                );
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          formik,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-11">
                      <h3 className="dailogtext">
                        <b>
                          {props.redditAccountData ? (
                            <>
                              <span className="span_first_letter">E</span>
                              dit &nbsp;
                            </>
                          ) : (
                            <>
                              <span className="span_first_letter">A</span>
                              dd &nbsp;
                            </>
                          )}
                          <span className="span_first_letter">R</span>eddit
                          &nbsp;
                          <span className="span_first_letter">A</span>ccount
                          &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div className="col-1 closebtn">
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                          props.setAdd_account("");
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="account_name"
                      size="small"
                      name="account_name"
                      placeholder="Account Name"
                      error={
                        Boolean(errors.account_name) &&
                        Boolean(touched.account_name)
                      }
                      helperText={
                        Boolean(touched.account_name) && errors.account_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Keywords * :
                  </Grid>

                  <Grid item lg={5} md={5} sm={5} mt={1}>
                    {theArray &&
                      theArray.map((entry, index) => (
                        <Grid item xs={12} key={index} mt={1}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Chip
                              label={entry}
                              sx={{ marginRight: "10px" }}
                              onDelete={() => {
                                deleteEntry(index);
                              }}
                              deleteIcon={<CancelIcon />}
                            />
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Add Values * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} sx={{ display: "flex" }}>
                    <Field
                      // text feild from material Ui
                      // disabled={props.edit === true ? true : false}
                      as={MuiTextField}
                      width="200px"
                      variant="outlined"
                      id="keywords"
                      name="keywords"
                      placeholder="Add keywords"
                      onChange={handleChangeFor}
                      size="small"
                      value={fieldtypeValues}
                      // error={
                      //   Boolean(errors.keywords) && Boolean(touched.keywords)
                      // }
                      // helperText={Boolean(touched.keywords) && errors.keywords}
                    />
                    <MuiButton
                      size="small"
                      name={"Add"}
                      onClick={addEntryClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <Grid item container justifyContent={"center"} alignItems="center">
              <Grid item>
                {errors.showerror && isFormSumbitted && <Required />}
              </Grid>
            </Grid>

            <DialogActions className="m-1 mb-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={
                        props.redditAccountData ? "Edit Account" : "Add Account"
                      }
                      type="submit"
                      onClick={() => {
                        SetIsFormSubmitted(true);
                      }}
                      loading={loading}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Edit Project "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={() => {
                        if (props.redditAccountData) {
                          setTheArray([]);

                          const newArray = [
                            ...props.redditAccountData.keywords,
                          ];
                          setTheArray(newArray);
                        } else {
                          setTheArray([]);
                        }
                        setFieldValue("account_name", "");
                        setFieldtypeValues("");
                        SetIsFormSubmitted(false);
                      }}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        SetIsFormSubmitted(false);
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AddRedditAccount);
