import React from "react";

function Logo(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ justifySelf: "flex-start" }}>
        {props.leftLogo && (
          <img className="reportLogo" src={props.leftLogo} alt="" />
        )}
      </div>

      <div style={{ justifySelf: "flex-end" }}>
        {props.rightLogo && (
          <img className="reportLogo" src={props.rightLogo} alt="" />
        )}
      </div>
    </div>
  );
}

export default Logo;
