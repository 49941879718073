import BaseReducer from "../../../../utilities/BaseReducer";
import DsrActivityGroupAction from "./DsrActivityGroupAction";

export default class DsrActivityGroupFilterReducer extends BaseReducer {
  initialState = {
    dsrActivityGroupFilter: [],
  };
  [DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      dsrActivityGroupFilter: action.payload,
    };
  }
}
