import BaseReducer from "../../../../utilities/BaseReducer";
import CmsUploadLinksAction from "./CmsUploadLinksAction";

export default class CmsProcessingStatusReducer extends BaseReducer {
  initialState = {
    cmsProcessingStatus: [],
  };
  [CmsUploadLinksAction.REQUEST_CMS_PROCESSING_STATUS_FINISHED](state, action) {
    return {
      ...state,
      cmsProcessingStatus: action.payload,
    };
  }
}
