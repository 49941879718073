import { BaseModel } from "sjs-base-model";

// put model
export default class AppStatusPutModel extends BaseModel {
  status = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
