import BaseReducer from "../../../../utilities/BaseReducer";
import TerritoryAction from "./TerritoryAction";

export default class TerritoryFilterReducer extends BaseReducer {
  //initial state of TerritoryFilterReduser
  initialState = {
    Territory_filter: [],
  };
  //TerritoryFilterReduser request action finish
  [TerritoryAction.REQUEST_TERRITORY_FILTER_FINISHED](state, action) {
    return {
      ...state,
      Territory_filter: action.payload,
    };
  }

  [TerritoryAction.REQUEST_TERRITORY_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Territory_filter: action.payload,
    };
  }
}
