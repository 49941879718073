import { BaseModel } from "sjs-base-model";
import InstagramFeedsHitsDataModel from "./InstagramFeedsHitsDataModel";

export default class InstagramFeedsHitsModel extends BaseModel {
  hits = [InstagramFeedsHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
