import BaseReducer from "../../../../utilities/BaseReducer";
import LinkCheckingAction from "./LinkCheckingAction";

export default class LinkCheckingReducer extends BaseReducer {
  initialState = {
    linkChecking: [],
  };
  [LinkCheckingAction.REQUEST_LINK_CHECKING_FINISHED](state, action) {
    return {
      ...state,
      linkChecking: action.payload,
    };
  }

  [LinkCheckingAction.REQUEST_LINK_CHECKING_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      linkChecking: action.payload,
    };
  }
}
