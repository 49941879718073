import { BaseModel } from "sjs-base-model";

export default class ActivityDataGroupwiseModel extends BaseModel {
  activity_data_groupwise = [];

  constructor(data) {
    super();
    this.update(data);
  }
}
