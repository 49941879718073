import BaseReducer from "../../../utilities/BaseReducer";
import AiChatAction from "./AiChatAction";

export default class AiChatReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    aiChatFeed: [],
  };

  //operation Filter
  [AiChatAction.REQUEST_AICHAT_FEED_FINISHED](state, action) {
    return {
      ...state,
      aiChatFeed: action.payload,
    };
  }
}
