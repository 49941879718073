import BaseReducer from "../../../utilities/BaseReducer";
import TicketsAction from "./TicketsAction";

export default class TicketReportsReduserWithPagination extends BaseReducer {
  //initial state of ticket list
  initialState = {
    ticketsReportsWithPagination: [],
  };

  //ticket request action finish
  [TicketsAction.REQUEST_TICKETS_FOR_REPORTS_WITH_PAGINATION_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ticketsReportsWithPagination: action.payload,
    };
  }
}
