import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import UserAction from "../../ormTicketing/stores/user/UserAction";
import MuiButton from "../muiButton/MuiButton";
import MuiTextField from "../muiTextField/MuiTextField";
import CloseIcon from "@mui/icons-material/Close";
import environment from "environment";
import jwtDecode from "jwt-decode";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const UpdateMyProfileImg = (props) => {
  let initial = {};

  initial = {
    user_profile_url: "",
    boolean: false,
  };

  const [loading, setLoading] = useState(false);

  const handlFileUpload = (value, setFieldValue, event) => {
    setFieldValue("boolean", true);
    let formData = new FormData();
    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    fetch(`${environment.api.baseApi}/file_upload/add`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_tocken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        setFieldValue("user_profile_url", response.url);
        setFieldValue("boolean", false);
      });
  };

  const clearFile = (setFieldValue) => {
    document.querySelector("input[type='file']").value = "";
    setFieldValue("user_profile_url", "");
  };

  // handleClear function
  const handleClear = () => {
    document.querySelector("input[type='file']").value = "";
    setLoading(false);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      {/* Scope of Formik starts here */}
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let obj = { user_profile_url: values.user_profile_url };
          props
            .dispatch(UserAction.requestPutUserProfileImg_upload(obj))
            .then(() => {
              setLoading(false);
              props.onClose();
              const auth = jwtDecode(localStorage.getItem("Auth"));

              let id = auth.sub;
              props.dispatch(UserAction.requestUserById(id));
            });
        }}
        validateOnBlur={true}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          // Form Tag from Formik
          <Form>
            {/* Content of the dialog Box  */}
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* text Content inside DialogBox  */}
              <DialogContentText sx={{ width: "100%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10 ">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;U
                          </span>
                          pload &nbsp;
                          <span className="span_first_letter">P</span>
                          rofile &nbsp;
                          <span className="span_first_letter">I</span>
                          mage &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>
              {/* Form Filed inside Dialog Box  */}
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // className="bg-warning"
                alignItems={"center"}
                justifyContent="center"
              >
                <Grid
                  item
                  //   className="bg-success"
                  xl={10}
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  alignItems="center"
                  textAlign={"center"}
                >
                  <input
                    disabled={values.boolean}
                    type="file"
                    accept="image/*"
                    // name="attachments"
                    // onChange={(e) => {
                    //   setFieldValue(
                    //     "attachments",
                    //     e.target.files[0]
                    //   );
                    // }}
                    onChange={(e) => {
                      handlFileUpload(e.target.files[0], setFieldValue, e);
                    }}
                  />
                </Grid>
                {values.user_profile_url && (
                  <Grid
                    item
                    // className="bg-info"
                    xl={2}
                  >
                    <IconButton className="m-0 p-0" title="Remove">
                      <CloseIcon
                        style={{
                          width: "13px",
                        }}
                        onClick={() => {
                          clearFile(setFieldValue);
                        }}
                      />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            {/* Actions performed from form action buttons  */}
            <DialogActions className="m-1 my-4">
              {/* Calling ButtonComponent to perform form actions  */}
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    name={"Submit"}
                    type="submit"
                    disabled={values.boolean}
                    loading={loading}
                    size="large"
                    width="100%"
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Reset"}
                    type="reset"
                    size="large"
                    width="100%"
                    onClick={handleClear}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(UpdateMyProfileImg);
