import { BaseModel } from "sjs-base-model";
import WhatsAppFeedDataModel from "./WhatsAppFeedDataModel";

// Whatsapp Feed data hits Model function
export default class WhatsAppDataHitsModel extends BaseModel {
  
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [WhatsAppFeedDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
