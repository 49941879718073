import BaseReducer from "../../../../utilities/BaseReducer";
import FPplatformAction from "./FPplatformAction";

export default class FPplatformReducer extends BaseReducer {
  initialState = {
    FPplatforms: [],
  };
  [FPplatformAction.REQUEST_GET_FP_PLATFORMS_FINISHED](state, action) {
    return {
      ...state,
      FPplatforms: action.payload,
    };
  }
}
