import { Box, Select, MenuItem, OutlinedInput } from "@mui/material";
import { useTheme } from "@mui/system";
import jwtDecode from "jwt-decode";
import React from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import UserAction from "../../ormTicketing/stores/user/UserAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [UserAction.REQUEST_USER_BY_ID]),
  userById: state.userById.userById || [],
});

function StatusDropDown(props, width) {
  const [online, setOnline] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const { isRequesting } = props;

  // *****************************************************************

  const onlineHandleChange = (event, value) => {
    setOnline(value);
    setFetching(true);

    let obj = {
      user_working_status: value,
    };

    props.dispatch(UserAction.requestPutUserWorkingStatus(obj)).then(() => {
      const auth = jwtDecode(localStorage.getItem("Auth"));
      fetch(auth.sub);
      setFetching(false);
    });
  };

  // fetch function
  function fetch(id) {
    props.dispatch(UserAction.requestUserById(id));
  }

  React.useEffect((event) => {
    const auth = jwtDecode(localStorage.getItem("Auth"));
    fetch(auth.sub);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const theme = useTheme();

  theme.components = {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          outline: "none",
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.palette.buttonComponent.borderRadius.small,
        },
      },
    },
  };

  const menuProps = {
    PaperProps: {
      style: {
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: 2,
      },
    },
  };

  let statusValue =
    props.userById.data &&
    props.userById.data.attributes &&
    props.userById.data.attributes.user_working_status &&
    props.userById.data.attributes.user_working_status[0];

  return (
    <Box sx={{ width: "100%", alignSelf: "center" }}>
      <Select
        value={statusValue ? statusValue : null}
        size="small"
        style={{
          height: "25px",
          width: "125px",
          borderRadius: theme.palette.buttonComponent.borderRadius.small,
        }}
        placeholder="Select Status"
        onChange={(e, v) => {
          onlineHandleChange(e, e.target.value);
        }}
        inputProps={{
          "aria-label": "Without label",
        }}
        input={
          <OutlinedInput
            fullWidth
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              outline: "none",
              backgroundColor: theme.palette.background.paper,
              border: "none",
              boxShadow: "inset 0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            }}
            size={"small"}
            // endAdornment={
            //   <InputAdornment position="end" sx={{ marginRight: 4 }}>
            //     {fetching ? (
            //       <CircularProgress color="inherit" size={15} />
            //     ) : null}
            //   </InputAdornment>
            // }
          />
        }
        MenuProps={menuProps}
      >
        <MenuItem value={"Online"}>
          {" "}
          <span
            className="fa fa-circle"
            style={{
              fontSize: "10px",
              color: "#2ef234",
            }}
          ></span>
          &nbsp;&nbsp; Online
        </MenuItem>
        <MenuItem value={"Offline"}>
          <span
            className="fa fa-circle"
            style={{
              fontSize: "10px",
              color: theme.palette.secondary.main,
            }}
          ></span>
          &nbsp;&nbsp; Offline
        </MenuItem>
      </Select>
    </Box>
  );
}

export default connect(mapStateToProps)(StatusDropDown);
