import BaseReducer from "../../../utilities/BaseReducer";
import ProjectAction from "./projectAction";

// reducer function
export default class ProjectReducer extends BaseReducer {
  initialState = {
    project: [],
  };

  [ProjectAction.REQUEST_PROJECT_FINISHED](state, action) {
    return {
      ...state,
      project: action.payload,
    };
  }
}
