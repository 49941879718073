import ActionUtility from "../../../../utilities/ActionUtility";
import ChannelAnalyticsAccountEffect from "./ChannelAnalyticsAccountEffect";

export default class ChannelAnalyticsAccountAction {
  //get request for channel analytics account
  static REQUEST_CHANNEL_ANALYTICS_ACCOUNT =
    "ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT";
  static REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FINISHED =
    "ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FINISHED";

  //Post requests
  static REQUEST_CHANNEL_ANALYTICS_ACCOUNT_POST =
    "ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_POST";
  static REQUEST_CHANNEL_ANALYTICS_ACCOUNT_POST_FINISHED =
    "ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_POST_FINISHED";

  static REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FILTER =
    "ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FILTER";
  static REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FILTER_FINISHED =
    "ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_CHANNEL_ANALYTICS_ACCOUNT =
    "ChannelAnalyticsAccountAction.REQUEST_PUT_CHANNEL_ANALYTICS_ACCOUNT";
  static REQUEST_PUT_CHANNEL_ANALYTICS_ACCOUNT_FINISHED =
    "ChannelAnalyticsAccountAction.REQUEST_PUT_CHANNEL_ANALYTICS_ACCOUNT_FINISHED";

  //METHODS
  static requestChannelAnalyticsAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT,
        ChannelAnalyticsAccountEffect.requestChannelAnalyticsAccount,
        params
      );
    };
  }

  //post method
  static requestChannelAnalyticsAccountPost(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_POST,
        ChannelAnalyticsAccountEffect.requestChannelAnalyticsAccountPost,
        data
      );
    };
  }

  static requestChannelAnalyticsAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FILTER,
        ChannelAnalyticsAccountEffect.requestChannelAnalyticsAccountFilter,
        params
      );
    };
  }
  static requestChannelAnalyticsAccountPut(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChannelAnalyticsAccountAction.REQUEST_PUT_CHANNEL_ANALYTICS_ACCOUNT,
        ChannelAnalyticsAccountEffect.requestChannelAnalyticsAccountPut,
        data,
        id
      );
    };
  }
}
