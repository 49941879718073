import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import CustomerDataGetModel from "./models/CustomerDataGetModel";
import CustomerDataPostModel from "./models/CustomerDataPostModel";
import CustomerDataPutModel from "./models/CustomerDataPutModel";
import CustomerDataDeleteModel from "./models/CustomerDataDeleteModel";
import CustomerDataByIdModel from "./models/CustomerDataByIdModel";

export default class CustomerDataEffect {
  static async requestCustomerData(params) {
    const endpoint = environment.api.customerData;
    return EffectUtility.getToModel(CustomerDataGetModel, endpoint, params);
  }

  static async requestCustomerDataById(id) {
    const endpoint = environment.api.customerData + "/" + id;

    return EffectUtility.getToModel(CustomerDataByIdModel, endpoint);
  }

  static async requestPostCustomerData(data, callBackFunction) {
    const endpoint = environment.api.customerData + "/add";
    let response = EffectUtility.postToModel(
      CustomerDataPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp.id);
      }
    });
    return response;
  }

  static async requestPutCustomerData(data, id) {
    const endpoint = environment.api.customerData + "/" + id;
    return EffectUtility.putToModel(CustomerDataPutModel, endpoint, data);
  }

  static async requestDeleteCustomerData(id) {
    const endpoint = environment.api.customerData + "/" + id;

    return EffectUtility.deleteToModel(CustomerDataDeleteModel, endpoint);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
