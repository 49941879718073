import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import DIYReportTemplateModel from "./models/DIYReportTemplateModel";
import DIYReportTemplatePutDataModel from "./models/DIYReportTemplatePutDataModel";
import DIYReportTemplatePostDataModel from "./models/DIYReportTemplatePostDataModel";
import DIYReportsByIdModel from "./models/DIYReportsByIdModel";
import DIYReportDeleteModel from "./models/DIYReportDeleteModel";

export default class DIYReportTemplateEffect {
  // get reportTemplate requestDIY endpoint
  static async requestDIYReportTemplate(params, callBack) {
    const endpoint = environment.api.diyReportTemplate;

    let response = EffectUtility.getToModel(
      DIYReportTemplateModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits.hits && callBack) {
        callBack(resp);
      }
    });

    return response;
  }

  // get reportTemplate requestDIY endpoint
  static async requestDIYReportTemplateFilter(params) {
    const endpoint = environment.api.diyReportTemplate;
    let response = EffectUtility.getToModel(
      DIYReportTemplateModel,
      endpoint,
      params
    );

    return response;
  }

  //get reportTemplate post api
  static async requestDIYPostReportTemplate(data) {
    const endpoint = environment.api.diyReportTemplate + "/add";
    return EffectUtility.postToModel(
      DIYReportTemplatePostDataModel,
      endpoint,
      data
    );
  }

  //get reportTemplate put api
  static async requestDIYPutReportTemplate(data, id) {
    const endpoint = environment.api.diyReportTemplate + "/" + id;

    return EffectUtility.putToModel(
      DIYReportTemplatePutDataModel,
      endpoint,
      data
    );
  }

  static async requestDIYDeleteReportTemplate(id) {
    const endpoint = environment.api.diyReportTemplate + "/" + id;
    let response = EffectUtility.deleteToModel(DIYReportDeleteModel, endpoint);

    return response;
  }

  // static async requestDIYUpdateOrgReportTemplate(data, id, params) {
  //   const endpoint = environment.api.UpdateOrgReportTemplate;

  //   return EffectUtility.putToModel(
  //     DIYReportTemplatePutDataModel,
  //     endpoint,
  //     data,
  //     params
  //   );
  // }

  //get reports requestDIY by id end point
  static async requestDIYReportsById(id) {
    const endpoint = environment.api.diyReportTemplate + "/" + id;

    return EffectUtility.getToModel(DIYReportsByIdModel, endpoint);
  }

  //get error end point
  static async requestDIYError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
