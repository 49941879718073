import BaseReducer from "../../../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

export default class ReleasewiseRevenueReduser extends BaseReducer {
  initialState = {
    releasewiseRevenue: [],
  };
  [A3ReportsAction.REQUEST_RELEASEWISE_REVENUE_FINISHED](state, action) {
    return {
      ...state,
      releasewiseRevenue: action.payload,
    };
  }
}
