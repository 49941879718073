import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import AutomationFlowGetModal from "./modals/AutomationFlowGetModal";

export default class AutomationFlowEffect {
  static async requestGetAutomationFlow(params, callback) {
    const endpoint = environment.api.noCodeAPi + "/automation_flow";
    let response = EffectUtility.getToModel(
      AutomationFlowGetModal,
      endpoint,
      params
    );
    return response;
  }
}
