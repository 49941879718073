import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import OrmNotificationGetModel from "./models/OrmNotificationsGetModel";
import OrmNotificationGetByIdModel from "./models/OrmNotificationsByIdModel";
import OrmNotificationsBulkUpdateModel from "./models/OrmnotificationBulkUpdateModel";
import OrmNotificationsPutModel from "./models/OrmNotificationsPutModel";

export default class ORMnotificationEffect {
  static async requestOrmNotifications(params) {
    const endpoint = environment.api.ormNotificationsData;
    return EffectUtility.getToModel(OrmNotificationGetModel, endpoint, params);
  }

  static async requestOrmNotificationsFilter(params, callback) {
    const endpoint = environment.api.ormNotificationsData;
    let response = EffectUtility.getToModel(
      OrmNotificationGetModel,
      endpoint,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestOrmNotificationsById(id, params) {
    const endpoint = environment.api.ormNotificationsData + "/" + id;
    return EffectUtility.getToModel(
      OrmNotificationGetByIdModel,
      endpoint,
      params
    );
  }

  static async requestPutOrmNotificationsBulkUpdate(data) {
    const endpoint = environment.api.ormNotificationsDataBulkUpdate;
    return EffectUtility.putToModel(
      OrmNotificationsBulkUpdateModel,
      endpoint,
      data
    );
  }

  // static async requestPostOrmNotifications(data) {
  //   const endpoint = environment.api.ormNotificationsData;
  //   return EffectUtility.postToModel(, endpoint, data);
  // }

  static async requestPutOrmNotifications(data, id) {
    const endpoint = environment.api.ormNotificationsData + "/" + id;
    return EffectUtility.putToModel(
      OrmNotificationsPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  // static async requestDeleteOrmNotifications(id) {
  //   const endpoint = environment.api.ormNotificationsData + "/" + id;

  //   return EffectUtility.deleteToModel(, endpoint);
  // }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
