import {
  Box,
  createFilterOptions,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import SwitchComponent from "../../../components/switchComponent/SwitchComponent";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ManageRoleAction from "../../components/manageRole/ManageRoleAction";
import ManageUserPermissionPost from "../../components/manageUserPermissionPost/ManageUserPermissionPost";
import OperationAction from "../../stores/operations/OperationAction";
import OrmResourceAction from "../../stores/ormResources/OrmResourceAction";
import RolePermissionAction from "../../stores/rolePermissions/RolePermissionsAction";

// mapState to props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    RolePermissionAction.REQUEST_ROLEPERMISSIONS,
    ManageRoleAction.REQUEST_ROLE_FILTER,
    OperationAction.REQUEST_OPERATIONS_FILTER,
    ManageRoleAction.REQUEST_ROLE_TEMPLATE_FILTER,
    OrmResourceAction.REQUEST_ORM_RESOURCE,
  ]),
  rolePermission: state.rolePermission.rolePermission || [],
  roleFilter: state.roleFilter.roleFilter,
  operations: state.operations.operations || {},
  pagination: state.rolePermission.rolePermission || [],
  roleTemplate: state.roleTemplate.roleTemplate || {},
  OrmResource: state.OrmResource.OrmResource || [],
});

function ManageUserPermission(props) {
  const { isRequesting } = props;

  const [open, setOpen] = useState(false);
  const [filterRole, setFilterRole] = useState(null);
  const [filterResource, setFilterResource] = useState(null);
  let [filterResourceDomain, setfilterResourceDomain] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchRolePermission(params) {
    props.dispatch(RolePermissionAction.requestRolePermission(params));
  }

  function fetchRole() {
    const filter = [];

    filter.push(`[["is_template","must","","term","false"]]`);

    let param = {
      page_limit: "none",
      filters: `${filter}`,
    };

    props.dispatch(ManageRoleAction.requestRoleFilter(param));
  }

  function fetchOperations(params) {
    props.dispatch(OperationAction.requestOperationFilter(params));
  }

  function fetchRoleTemplateTrue() {
    const filter = [];
    let params = {};

    filter.push(`[["is_template","must","","term","true"]]`);

    params = {
      filters: `${filter}`,
      order_by: '[["modified_on", "desc"]]',
      page_limit: `none`,
    };
    props.dispatch(ManageRoleAction.requestRoleTemplateTrueFilter(params));
  }

  function fetchOrmResource() {
    props.dispatch(OrmResourceAction.requestOrmResource());
  }

  const [page_limit] = useState(15);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    let param = { page_limit: "none" };

    // if (props?.operations === undefined || props?.operations.length === 0) {
    fetchOperations();
    // }

    // if (props?.roleFilter === undefined || props?.roleFilter.length === 0) {
    fetchRole();
    // }

    // if (props?.roleTemplate === undefined || props?.roleTemplate.length === 0) {
    fetchRoleTemplateTrue();
    // }

    // if (props?.OrmResource === undefined || props?.OrmResource.length === 0) {
    fetchOrmResource();
    // }

    const filter = [];
    let params;
    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }
    if (filterRole !== null) {
      filter.push(`["role_id.keyword","must","","terms",["${filterRole.id}"]]`);
    }
    if (filterResource !== null || filterRole !== null) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    } else {
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    }
    fetchRolePermission(params);
  }, []);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(true);
  }, []);

  // Resources drop down list initialize

  const [resourceKey, setResourceKey] = useState(null);

  let resourceKeys = [];

  let resourceList = [];

  if (props.OrmResource.resources) {
    props.OrmResource.resources
      .filter((item) => Object.keys(item)[0] === resourceKey)
      .map((item) => {
        Object.values(item)[0].map((val) => {
          resourceList.push({ name: val.resource_name, id: val.resource_id });
        });
      });
  }

  // Role drop down list initialize
  let roleList = [];

  if (props.roleFilter.hits) {
    props.roleFilter.hits.hits.forEach((val) => {
      roleList.push({ id: val._id, name: val._source.role_name });
    });
  }

  if (props.roleTemplate.hits) {
    props.roleTemplate.hits.hits
      .filter((val) => val._source.role_name === "super_admin")
      .map((item) => {
        return roleList.push({ id: item._id, name: item._source.role_name });
      });
  }

  // Permission drop down list initialize
  let operationsList = [];
  if (props.operations.hits) {
    props.operations.hits.hits.forEach((val) => {
      operationsList.push({
        operation_id: val._id,
        operation_name: val._source.operation_name,
        enabled: false,
      });
    });
  }

  // Datagrid rows list Assigning
  const rows = [];

  if (props.rolePermission.hits && props.rolePermission.hits.length !== 0) {
    props.rolePermission.hits.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        role_id: item._source.role_id,
        role_name: item._source.role_id.role_name,
        resource_id: item._source.resource_id,
        resource_name: item._source.resource_id.resource_name,
        operation: item,
      });
    });
  }

  // if (props.rolePermissions) {
  //   let id = 1;
  //   for (const role in props.rolePermissions.role_permission) {
  //     for (const resource in props.rolePermissions.role_permission[role]) {
  //       resources.push({
  //         id: id++,
  //         role_id: role,
  //         role_name:
  //           props.rolePermissions.role_permission[role][resource][0].role_name,
  //         resource_id: resource,
  //         resource_name:
  //           props.rolePermissions.role_permission[role][resource][0]
  //             .resource_name,
  //         permissions: props.rolePermissions.role_permission[role][resource],
  //       });
  //     }
  //   }
  // }

  props.OrmResource &&
    props?.OrmResource?.resources?.map((item) => {
      resourceKeys.push(Object.keys(item)[0]);
    });

  resourceKeys.sort((a, b) => a.localeCompare(b));
  resourceKeys = [...new Set(resourceKeys)];

  const returnEnabledValue = (values, val) => {
    let filtered_array = values.operation.filter((item) => {
      return item.operation_id === val.operation_id;
    });

    if (filtered_array.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // resource value handling function
  const resourceHandleChange = (e, resource) => {
    setFilterResource(resource);
  };

  // resource value handling function
  const resourceDomainHandleChange = (e, resource) => {
    setfilterResourceDomain(resource);
    setResourceKey(resource);
  };

  // role value handling function
  const roleHandleChange = (e, role) => {
    setFilterRole(role);
  };

  // Switch value handling function
  const SwitchHandleChange = (data) => {
    if (data) {
      let postData = {
        role_id: data.roleId,
        resource_id: data.resourceId,

        operation: [
          {
            enabled: data.operation.enabled,
            operation_id: data.operation.operation_id,
            operation_name: data.operation.operation_name,
          },
        ],
      };

      props
        .dispatch(
          RolePermissionAction.requestRolePermissionPut(
            JSON.stringify(postData),
            data.id
          )
        )
        .then(() => {
          const filter = [];

          let params;

          if (filterResource !== null) {
            filter.push(
              `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
            );
          }

          if (filterRole !== null) {
            filter.push(
              `["role_id.keyword","must","","terms",["${filterRole.id}"]]`
            );
          }

          if (filterResource !== null || filterRole !== null) {
            params = {
              filters: `[${filter}]`,
              page_limit: `${page_limit}`,
            };
          } else {
            params = {
              page_limit: `${page_limit}`,
            };
          }

          fetchRolePermission(params);
        });
    }
    // else {
    //   props
    //     .dispatch(RolePermissionAction.requestRolePermissionDelete(e.target.id))
    //     .then(() => {
    //       const filter = [];

    //       let params;

    //       if (filterResource !== null) {
    //         filter.push(
    //           `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
    //         );
    //       }

    //       if (filterRole !== null) {
    //         filter.push(
    //           `["role_id.keyword","must","","terms",["${filterRole.id}"]]`
    //         );
    //       }

    //       if (filterResource !== null || filterRole !== null) {
    //         params = {
    //           filters: `[${filter}]`,
    //           page_limit: `${page_limit}`,
    //         };
    //       } else {
    //         params = {
    //           page_limit: `${page_limit}`,
    //         };
    //       }

    //       fetchRolePermission(params);
    //     });
    // }
  };

  // FILTER FUNCTION
  const ApplyFilter = () => {
    const filter = [];

    let params;

    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }
    if (filterRole !== null) {
      filter.push(
        `["role_id.keyword","must","","terms",${JSON.stringify(
          filterRole &&
            filterRole.options.length !== 0 &&
            filterRole.options.map((item) => {
              return item.id;
            })
        )}]`
      );
    }

    if (
      (filterResource !== null && filterResourceDomain !== null) ||
      (filterRole !== null && filterResourceDomain === null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
      fetchRolePermission(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);

      // params = {
      //   page_number: 0,
      //   page_limit: `${page_limit}`,
      //   order_by: `[["created_on","asc"]]`,
      // };
      // fetchRolePermission(params);
    }
  };

  const handleChange = (event, value) => {
    const filter = [];

    let params;

    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }

    if (filterRole !== null) {
      filter.push(
        `["role_id.keyword","must","","terms",["${
          filterRole &&
          filterRole.options.length !== 0 &&
          filterRole.options.map((item) => {
            return item.id;
          })
        }"]]`
      );
    }

    if (FilterClicked) {
      if (filterResource !== null || filterRole !== null) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: `[["created_on","asc"]]`,
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchRolePermission(params);
    }
  };

  //CLEAR FUNCTION
  const ClearFunction = () => {
    if (
      filterResource !== null ||
      filterRole !== null ||
      filterResourceDomain !== null
    ) {
      setFilterResource(null);
      setfilterResourceDomain(null);
      setResourceKey(null);
      setFilterRole(null);
      setClearLoader(true);
      setFilterClicked(false);
      !FilterClicked && setClearLoader(false);
    }

    let params = {
      page_limit: `${page_limit}`,
      order_by: `[["created_on","asc"]]`,
    };
    FilterClicked && fetchRolePermission(params);
  };

  //Handle Callback function for refreshing data
  const handleData = useCallback(async () => {
    const filter = [];

    let params;

    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }

    if (filterRole !== null) {
      filter.push(`["role_id.keyword","must","","terms",["${filterRole.id}"]]`);
    }

    if (filterResource !== null || filterRole !== null) {
      params = {
        filters: `[${filter}]`,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    } else {
      params = {
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    }

    fetchRolePermission(params);
  }, [filterResource, filterRole]);

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 150,
      flex: 2,
    },
    {
      field: "role_id",
      headerName: "Role",
      width: 150,
      flex: 2,
      hide: true,
    },
    {
      field: "resource_name",
      headerName: "Resource",
      width: 250,
      flex: 3,
    },
    {
      field: "resource_id",
      headerName: "Resource Id",
      width: 250,
      flex: 3,
      hide: true,
    },
    {
      field: "operations",
      headerName: "Operation",
      width: 400,
      flex: 6,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return (
          <Grid
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent="center"
            alignSelf={"center"}
            width={400}
          >
            {params.row.operation._source.operation.map((item) => (
              <Grid
                display={"center"}
                justifyContent="center"
                alignSelf={"center"}
                p={1.5}
              >
                <SwitchComponent
                  id={item.operation_id ? item.operation_id : ""}
                  label={item.operation_name ? item.operation_name : ""}
                  labelPlacement="start"
                  checked={item.enabled === true ? true : false}
                  onClick={() => {
                    handleCHange(
                      params.row.operation._id,
                      item.operation_id,
                      item.operation_name,
                      item.enabled,
                      params.row.operation._source.operation
                    );
                  }}
                />
              </Grid>
            ))}
          </Grid>
        );
      },
    },
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Delete",
    //   width: 160,
    //   flex: 2,
    //   cellClassName: "actions",
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<Delete />}
    //       label="Delete"
    //       sx={{
    //         color: theme.palette.primary.main,
    //       }}
    //       onClick={deleteUser(params.id)}
    //     />,
    //   ],
    // },
  ];

  const handleCHange = (id, op_id, op_name, enabledValue, op_list) => {
    let an_object = op_list.filter((val) => {
      return val.operation_id === op_id;
    });

    let new_obj = {
      operation_id: op_id,
      operation_name: op_name,
      enabled: enabledValue === true ? false : true,
    };
    let index_of_clicked_switch = op_list.indexOf(
      an_object[0] ? an_object[0] : {}
    );

    op_list = [...op_list];
    op_list.splice(index_of_clicked_switch, 1, new_obj);

    let values = {
      operation: [...op_list],
    };

    props
      .dispatch(
        RolePermissionAction.requestRolePermissionPut(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        const filter = [];
        let params;
        if (filterResource !== null) {
          filter.push(
            `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
          );
        }
        if (filterRole !== null) {
          filter.push(
            `["role_id.keyword","must","","terms",${JSON.stringify(
              filterRole &&
                filterRole.options.length !== 0 &&
                filterRole.options.map((item) => {
                  return item.id;
                })
            )}]`
          );
        }
        if (filterResource !== null || filterRole !== null) {
          params = {
            filters: `[${filter}]`,
            page_number: props.pagination.current_page_no,
            page_limit: `${page_limit}`,
            order_by: `[["created_on","asc"]]`,
          };
        } else {
          params = {
            page_number: props.pagination.current_page_no,
            page_limit: `${page_limit}`,
            order_by: `[["created_on","asc"]]`,
          };
        }
        fetchRolePermission(params);
      });
  };

  const groupedRoles = roleList.reduce((groups, role) => {
    const name = role.name;
    const existingGroup = groups.find((group) => group.name === name);
    if (existingGroup) {
      existingGroup.options.push(role);
    } else {
      groups.push({ name, options: [role] });
    }
    return groups;
  }, []);

  return (
    <React.Fragment>
      <Box>
        {/* <h5>Manage User-Role Permissions</h5> */}
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            container
            lg={8}
            md={8}
            sm={8}
            display="flex"
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={8} md={8} sm={8} sx={{ gap: 2, display: "flex" }}>
              <Grid item lg={6} md={6} sm={6} mt={1}>
                <AutoCompleteComponent
                  value={filterRole}
                  getOptionLabel={(group) => group.name}
                  groupBy={(group) => group.name}
                  size="small"
                  list={groupedRoles}
                  width="100%"
                  handleChange={roleHandleChange}
                  textFieldProps={{
                    placeholder: "Select Role",
                  }}
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option.name,
                  })}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} mt={1}>
                <AutoCompleteComponent
                  value={filterResourceDomain}
                  getOptionLabel={(option) => option}
                  size="small"
                  width="100%"
                  list={resourceKeys}
                  handleChange={resourceDomainHandleChange}
                  textFieldProps={{
                    placeholder: "Select Resource",
                  }}
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option,
                  })}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} mt={1}>
                <AutoCompleteComponent
                  value={filterResource}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  width="100%"
                  list={resourceList}
                  handleChange={resourceHandleChange}
                  textFieldProps={{
                    placeholder: "Select Resource Type",
                  }}
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option.name,
                  })}
                />
              </Grid>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              container
              display="flex"
              justifyContent="end"
            >
              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                container
                mt={1}
                sx={{ gap: "10px" }}
              >
                <Grid lg={5} md={5} sm={5}>
                  <MuiButton
                    loading={filterLoader && isRequesting}
                    name="Filter"
                    width="100%"
                    onClick={ApplyFilter}
                  />
                </Grid>
                <Grid lg={5} md={5} sm={5}>
                  <MuiButton
                    loading={clearLoader && isRequesting}
                    name="Clear"
                    width="100%"
                    onClick={ClearFunction}
                  />
                </Grid>
              </Grid>
              <Grid>
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  // editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* PERMISSION ASSIGNING FORM - POST MODEL SCREEN */}
        <ManageUserPermissionPost
          open={open}
          roleList={roleList}
          resourceList={resourceList}
          OrmResource={props.OrmResource && props.OrmResource.resources}
          operationsList={operationsList}
          onClose={() => {
            setOpen(false);
          }}
          callback={() => handleData()}
        />
        <Grid>
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <LoadingIndicator isActive={isRequesting}>
                  {!isRequesting && props.rolePermission.hits ? (
                    <DataGridFunction
                      rows={rows}
                      columns={columns}
                      rowHeight={50}
                      getRowHeight={() => "auto"}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            role_id: false,
                            resource_id: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingBgImage />
                    </Box>
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
            {/* <Grid
              // className="bg-warning"
              display="flex"
              justifyContent="center"
              container
              item
              lg={8}
              md={12}
              sm={12}
              // className="pagination_section"
            >
              <Grid item lg={6} md={12} sm={12} xs={12}>
                {props.rolePermission.hits
                  ? props.rolePermission.hits.hits.length !== 0 && (
                      <PaginationUI
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  : null}
              </Grid>
            </Grid> */}
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(ManageUserPermission);
