import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import NetRevenueByMonthGetModel from "./model/NetRevenueReportByMonthGetModel";

// NetRevenueByMonthEffect
export default class NetRevenueByMonthEffect {
  // calling an API according to the SessionLog model
  static async requestNetRevenueByMonth(params) {
    const endpoint = environment.api.netRevenueByMonth;
    return EffectUtility.getToModel(
      NetRevenueByMonthGetModel,
      endpoint,
      params
    );
  }
  static async requestNetRevenueByMonthFilter(params) {
    const endpoint = environment.api.netRevenueByMonth;
    return EffectUtility.getToModel(
      NetRevenueByMonthGetModel,
      endpoint,
      params
    );
  }

  static async requestNetRevenueByMonthFilterClear() {
    return {};
  }
}
