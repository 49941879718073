import { BaseModel } from "sjs-base-model";

export default class YoutubeChannelMonitorDeleteModel extends BaseModel {
  _id = "";
  _index = "";
  _primary_term = 0;
  _seq_no = 0;
  _shards = {};
  _version = 0;
  forced_refresh = false;
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
