import BaseReducer from "../../../../utilities/BaseReducer";
import ProxyAction from "./ProxyAction";

export default class ProxyCountriesReducer extends BaseReducer {
  initialState = {
    proxyCountries: [],
  };
  [ProxyAction.REQUEST_PROXY_COUNTRIES_FINISHED](state, action) {
    return {
      ...state,
      proxyCountries: action.payload,
    };
  }
}
