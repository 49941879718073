import BaseReducer from "../../../utilities/BaseReducer";
import PlatformAccountsAction from "./PlatformAccountsAction";

export default class PlatformAccountFilterReducer extends BaseReducer {
  initialState = {
    platformAccountFilter: [],
  };

  [PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      platformAccountFilter: action.payload,
    };
  }
}
