import environment from "environment";
import TopLabelModel from "./model/TopLabelModel";
import TopLabelPutModel from "./model/TopLabelPutModel";
import TopLabelPostModel from "./model/TopLabelPostModel";
import EffectUtility from "../../../../utilities/EffectUtility";

// TopLabelEffect
export default class TopLabelEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestTopLabel(params, callBack) {
    const endpoint = environment.api.topLabel;
    let response = EffectUtility.getToModel(TopLabelModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // Get method for the topLabel filter
  static async requestTopLabelFilter(params) {
    const endpoint = environment.api.topLabel;
    return EffectUtility.getToModel(TopLabelModel, endpoint, params);
  }

  // put method for the topLabel
  static async requestPutTopLabel(data, id) {
    const endpoint = environment.api.topLabel + "/" + id;
    return EffectUtility.putToModel(TopLabelPutModel, endpoint, data);
  }

  // post method for the topLabel
  static async requestPostTopLabel(data) {
    const endpoint = environment.api.topLabel + "/add";
    return EffectUtility.postToModel(TopLabelPostModel, endpoint, data);
  }

  static async requestDeleteTopLabel(id) {
    const endpoint = environment.api.topLabel + "/" + id;
    return EffectUtility.deleteToModel(TopLabelPostModel, endpoint);
  }
}
