import BaseReducer from "../../../../utilities/BaseReducer";
import AssetsAction from "./AssetsAction";

// AssetsReduser
export default class AssetsReduser extends BaseReducer {
  initialState = {
    assets: [],
  };
  [AssetsAction.REQUEST_ASSETS_FINISHED](state, action) {
    return {
      ...state,
      assets: action.payload,
    };
  }
}
