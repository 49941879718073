import React, { useState } from "react";
import {
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field, getIn } from "formik";
import { connect } from "react-redux";
import MuiButton from "../../../components/muiButton/MuiButton";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import UserMailMappingAction from "../../stores/userMailMapping/UserMailMappingAction";
// mapstateto props function
import * as yup from "yup";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const UserMailMappingPost = (props) => {
  let projectProps = props.projectProps;

  const getEmailObject = (emai_id) => {
    let user_id_object =
      props.userList.length !== 0 &&
      props.userList.filter((val) => {
        return val.user_email === emai_id;
      });

    return user_id_object.length !== 0 ? user_id_object[0] : "";
  };

  const validationSchema = yup.object({
    trigger_id: yup
      .object()
      .shape({})
      .required("Trigger Name is Required")
      .nullable(),
    email_id: yup
      .object()
      .shape({})
      .required("Email ID is Required")
      .nullable(),
  });

  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      trigger_id: props.projectEditData.trigger_id_obj,
      email_id: props.projectEditData.email_id
        ? getEmailObject(props.projectEditData.email_id)
        : "",
    };
  } else {
    initial = {
      trigger_id: "",
      email_id: "",
    };
  }
  // Formik

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          // parameter for showing list based on the modified_by
          const params = {
            page_limit: 15,
          };
          const param = {
            page_limit: "none",
          };
          if (props.edit) {
            props
              .dispatch(
                UserMailMappingAction.requestPutUserMailMapping(
                  JSON.stringify(values),
                  props.projectEditData.id
                )
              )
              .then(() => {
                props.dispatch(
                  UserMailMappingAction.requestUserMailMapping(params)
                );
                props.dispatch(
                  UserMailMappingAction.requestUserMailMappingFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          } else {
            let post_obj = {};

            Object.assign(
              post_obj,
              values.email_id && { email_id: values.email_id.user_email },
              values.trigger_id && { trigger_id: values.trigger_id.trigger_id },
              { subscribed: true }
            );
            props
              .dispatch(
                UserMailMappingAction.requestPostUserMailMapping(post_obj)
              )
              .then(() => {
                const params = {
                  page_limit: 15,
                };
                const param = {
                  page_limit: "none",
                };
                props.dispatch(
                  UserMailMappingAction.requestUserMailMapping(params)
                );
                props.dispatch(
                  UserMailMappingAction.requestUserMailMappingFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;U
                            </span>
                            ser &nbsp;
                            <span className="span_first_letter">M</span>
                            ail&nbsp;
                            <span className="span_first_letter">M</span>apping
                            &nbsp;
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">U</span>
                            ser&nbsp;
                            <span className="span_first_letter">M</span>ail
                            &nbsp;
                            <span className="span_first_letter">M</span>apping
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <Grid item lg={3} md={3} sm={3} ml={10}>
                    Mail ID * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      name="email_id"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.email_id ? values.email_id : null}
                      component={AutoCompleteMultiSelect}
                      // disabled={props.edit || values.category}
                      options={props.userList ? props.userList : []}
                      disabled={
                        props.userList && props.userList.length === 1
                          ? true
                          : false
                      }
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.user_email,
                      })}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.user_email === value.user_email
                      }
                      getOptionLabel={(option) => option.user_email}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Mail ID",
                        autoFocus: true,
                      }}
                      error={
                        Boolean(errors.email_id) && Boolean(touched.email_id)
                      }
                      helperText={Boolean(touched.email_id) && errors.email_id}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <Grid item lg={3} md={3} sm={3} ml={10}>
                    Trigger Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      name="trigger_id"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.trigger_id ? values.trigger_id : null}
                      component={AutoCompleteMultiSelect}
                      // disabled={props.edit || values.category}
                      options={
                        props.triggerNameList ? props.triggerNameList : []
                      }
                      disabled={
                        props.triggerNameList &&
                        props.triggerNameList.length === 1
                          ? true
                          : false
                      }
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.trigger_name,
                      })}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.trigger_name === value.trigger_name
                      }
                      getOptionLabel={(option) => option.trigger_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Trigger Name",
                      }}
                      error={
                        Boolean(errors.trigger_id) &&
                        Boolean(touched.trigger_id)
                      }
                      helperText={
                        Boolean(touched.trigger_id) && errors.trigger_id
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Map User"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Submit"
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(UserMailMappingPost);
