import BaseReducer from "../../../../utilities/BaseReducer";
import BatchAction from "./BatchAction";

export default class BatchFilterReduser extends BaseReducer {
  //initial state of BatchFilterReduser
  initialState = {
    batchA3Filter: [],
  };
  //BatchFilterReduser request action finish
  [BatchAction.REQUEST_BATCH_FILTER_FINISHED](state, action) {
    return {
      ...state,
      batchA3Filter: action.payload,
    };
  }
}
