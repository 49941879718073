import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import DsrActvityShadowGetModel from "./models/DsrActivityShadowGetModel";

export default class DsrActivityShadowEffect {
  static async requestGetDsrActivityShadow(params, callback) {
    const endpoint = environment.api.dsrActivityShadow;
    let response = EffectUtility.getToModel(
      DsrActvityShadowGetModel,
      endpoint,
      params
    );
    return response;
  }
}
