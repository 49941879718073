import { BaseModel } from "sjs-base-model";

//instagram delete Model
export default class InstagramAccountDeleteModel extends BaseModel {
  result = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
