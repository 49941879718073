import BaseReducer from "../../../utilities/BaseReducer";
import TicketsAction from "./TicketsAction";

export default class TicketByIdReducer extends BaseReducer {
  //initial state of ticket
  initialState = {
    ticket: [],
  };

  // ticket filter
  [TicketsAction.REQUEST_TICKETS_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      ticket: action.payload,
    };
  }

  [TicketsAction.REQUEST_TICKET_BY_ID_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      ticket: action.payload,
    };
  }
}
