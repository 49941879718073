import { Box, Avatar } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import UserAction from "../../../ormTicketing/stores/user/UserAction";
import jwtDecode from "jwt-decode";
import { useTheme } from "@mui/material";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [UserAction.REQUEST_USER_BY_ID]),
  userById: state.userById.userById || [],
});

function AvatarIcon(props) {
  const theme = useTheme();

  let FirstLetter = props.userById.data ? props.userById.data.firstName : "";

  return (
    <Box>
      <Avatar
        alt="Remy Sharp"
        sx={{
          cursor: "pointer",
          backgroundColor: "white",
          objectFit: "cover",
          border: `0.1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
        }}
        src={`${
          props.userById.data &&
          props.userById.data.attributes &&
          props.userById.data.attributes.user_profile_url &&
          props.userById.data.attributes.user_profile_url[0]
        }`}
      >
        {props.userById.data ? <h2>{FirstLetter.slice(0, 1)}</h2> : ""}
      </Avatar>
    </Box>
  );
}

export default connect(mapStateToProps)(AvatarIcon);
