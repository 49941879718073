import React, { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";

import { connect } from "react-redux";
import WhatsappAccountAction from "../../stores/whatsappAccount/WhatsappAccountAction";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};
const validationSchema = yup.object({
  account_name: yup
    .string("Enter your Account name")
    .trim()
    .min(1, "Account name should be of minimum 1 characters length")
    .required("Account name is required"),

  whatsapp_number: yup
    .string("enter a proper phone number")
    .min(10, "enter a valid 10 digit number")
    .max(10, "invalid number")
    .trim()
    .required("Whatsapp Number is required"),

  token: yup
    .string("Enter your Token")
    .trim()
    .min(1, "Token should be of minimum 1 characters length")
    .required("Token number is required"),

  waba_id: yup
    .string("Enter your Waba ID")
    .trim()
    .min(1, "Waba ID should be of minimum 1 characters length")
    .required("Waba ID is required"),

  phone_number_id: yup
    .string("Enter your Phone Number ID")
    .trim()
    .min(1, "Phone Number ID should be of minimum 1 characters length")
    .required("Phone Number ID is required"),

  // project: yup
  //   .object()
  //   .shape({})
  //   .required("Project Name is Required")
  //   .nullable(),
});

const AddWhatsappAccount = (props) => {
  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method
  let initial = {};
  initial = {
    account_name: "",
    whatsapp_number: "",
    // project: "",
    token: "",
    waba_id: "",
    phone_number_id: "",
  };

  const [loading, setLoading] = useState(false);

  const handlePhoneKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);
          props
            .dispatch(
              WhatsappAccountAction.requestPostWhatsappAccount(
                JSON.stringify(values)
              )
            )
            .then(() => {
              setLoading(false);

              let params = {
                page_limit: "none",
              };
              let account_params = {
                page_limit: "none",
                order_by: '[["created_on", "desc"]]',
              };
              props.onClose();
              props.setAdd_account("");
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccount(account_params)
              );
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccountFilter(params)
              );
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-11">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">A</span>
                          dd &nbsp;
                          <span className="span_first_letter">W</span>hatsapp
                          &nbsp;
                          <span className="span_first_letter">A</span>ccount
                          &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div
                      className="col-1 closebtn"
                      onClick={() => {
                        props.onClose();
                        props.setAdd_account("");
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="account_name"
                      size="small"
                      name="account_name"
                      placeholder="Account Name"
                      error={
                        Boolean(errors.account_name) &&
                        Boolean(touched.account_name)
                      }
                      helperText={
                        Boolean(touched.account_name) && errors.account_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Whatsapp Number :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      type="text"
                      id="whatsapp_number"
                      size="small"
                      name="whatsapp_number"
                      placeholder="Whatsapp Number"
                      error={
                        Boolean(errors.whatsapp_number) &&
                        Boolean(touched.whatsapp_number)
                      }
                      helperText={
                        Boolean(touched.whatsapp_number) &&
                        errors.whatsapp_number
                      }
                      handleKeyPress={handlePhoneKeyPress}
                    />
                  </Grid>
                </Grid>
                {/* 
                <Grid item lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Project :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="project"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.project ? values.project : null}
                      component={AutoCompleteMultiSelect}
                      options={props.project_array ? props.project_array : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.project_name === value.project_name
                      }
                      getOptionLabel={(option) => option.project_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Project",
                      }}
                    />
                  </Grid>
                </Grid> */}

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Token :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="token"
                      size="small"
                      name="token"
                      placeholder="Token"
                      error={Boolean(errors.token) && Boolean(touched.token)}
                      helperText={Boolean(touched.token) && errors.token}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Waba ID :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="waba_id"
                      size="small"
                      name="waba_id"
                      placeholder="Waba ID"
                      error={
                        Boolean(errors.waba_id) && Boolean(touched.waba_id)
                      }
                      helperText={Boolean(touched.waba_id) && errors.waba_id}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Phone Number ID :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="phone_number_id"
                      size="small"
                      name="phone_number_id"
                      placeholder="phone_number_id"
                      error={
                        Boolean(errors.phone_number_id) &&
                        Boolean(touched.phone_number_id)
                      }
                      helperText={
                        Boolean(touched.phone_number_id) &&
                        errors.phone_number_id
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Account "}
                      type="submit"
                      loading={loading}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Edit Project "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AddWhatsappAccount);
