import BaseReducer from "../../../../utilities/BaseReducer";
import LinkCheckingAction from "./LinkCheckingAction";

export default class LinkCheckPlatformReducer extends BaseReducer {
  initialState = {
    linkCheckPlatforms: [],
  };
  [LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORMS_FINISHED](state, action) {
    return {
      ...state,
      linkCheckPlatforms: action.payload,
    };
  }
}
