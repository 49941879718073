import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import GoogleLoginModel from "./GoogleLoginModel";
//Google Login effect
export default class GoogleLoginEffect {
  // calling an API according to the ProjectModel
  static async requestGoogleLogin(params) {
    const endpoint = environment.api.googleLogin;

    return EffectUtility.getToModel(GoogleLoginModel, endpoint, params);
  }
}
