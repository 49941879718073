import TagAction from "./TagAction";
import BaseReducer from "../../../utilities/BaseReducer";

// reducer function
export default class TagReducer extends BaseReducer {
  initialState = {
    tag: [],
  };

  [TagAction.REQUEST_TAG_FINISHED](state, action) {
    return {
      ...state,
      tag: action.payload,
    };
  }
}
