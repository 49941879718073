import ActionUtility from "../../../../utilities/ActionUtility";
import TrustpilotAccountEffect from "./TrustpilotAccountEffect";

export default class TrustpilotAccountAction {
  // TrustpilotAccountAction action with an function declaration
  static REQUEST_TRUSTPILOT_ACCOUNT =
    "TrustpilotAccountAction.REQUEST_TRUSTPILOT_ACCOUNT";
  static REQUEST_TRUSTPILOT_ACCOUNT_FINISHED =
    "TrustpilotAccountAction.REQUEST_TRUSTPILOT_ACCOUNT_FINISHED";

  static REQUEST_TRUSTPILOT_ACCOUNT_FILTER =
    "TrustpilotAccountAction.REQUEST_TRUSTPILOT_ACCOUNT_FILTER";
  static REQUEST_TRUSTPILOT_ACCOUNT_FILTER_FINISHED =
    "TrustpilotAccountAction.REQUEST_TRUSTPILOT_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_TRUSTPILOT_ACCOUNT =
    "TrustpilotAccountAction.REQUEST_PUT_TRUSTPILOT_ACCOUNT";
  static REQUEST_PUT_TRUSTPILOT_ACCOUNT_FINISHED =
    "TrustpilotAccountAction.REQUEST_PUT_TRUSTPILOT_ACCOUNT_FINISHED";

  static REQUEST_DELETE_TRUSTPILOT_ACCOUNT =
    "TrustpilotAccountAction.REQUEST_DELETE_TRUSTPILOT_ACCOUNT";
  static REQUEST_DELETE_TRUSTPILOT_ACCOUNT_FINISHED =
    "TrustpilotAccountAction.REQUEST_DELETE_TRUSTPILOT_ACCOUNT_FINISHED";

  static REQUEST_POST_TRUSTPILOT_ACCOUNT =
    "TrustpilotAccountAction.REQUEST_POST_TRUSTPILOT_ACCOUNT";
  static REQUEST_POST_TRUSTPILOT_ACCOUNT_FINISHED =
    "TrustpilotAccountAction.REQUEST_POST_TRUSTPILOT_ACCOUNT_FINISHED";

  // METHODS
  // Trustpilot GET function
  static requestTrustpilotAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotAccountAction.REQUEST_TRUSTPILOT_ACCOUNT,
        TrustpilotAccountEffect.requestTrustpilotAccount,
        params
      );
    };
  }

  // Trustpilot post function
  static requestPostTrustpilotAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotAccountAction.REQUEST_POST_TRUSTPILOT_ACCOUNT,
        TrustpilotAccountEffect.requestPostTrustpilotAccount,
        data
      );
    };
  }

  static requestTrustpilotAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotAccountAction.REQUEST_TRUSTPILOT_ACCOUNT_FILTER,
        TrustpilotAccountEffect.requestTrustpilotAccountFilter,
        params
      );
    };
  }
  static requestPutTrustpilotAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotAccountAction.REQUEST_PUT_TRUSTPILOT_ACCOUNT,
        TrustpilotAccountEffect.requestPutTrustpilotAccount,
        data,
        id
      );
    };
  }

  static requestDeleteTrustpilotAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotAccountAction.REQUEST_DELETE_TRUSTPILOT_ACCOUNT,
        TrustpilotAccountEffect.requestDeleteTrustpilotAccount,
        id
      );
    };
  }
}
