import { BaseModel } from "sjs-base-model";

// NvProjectPostModal
export default class NvProjectPostModal extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  status = "";
  message = "";
  data = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
