import ActionUtility from "../../../../utilities/ActionUtility";
import YoutubeVideosShadowEffect from "./YoutubeVideosShadowEffect";

export default class YoutubeVideosShadowAction {
  static REQUEST_YOUTUBE_VIDEOS_SHADOW =
    "YoutubeVideosShadowAction.REQUEST_YOUTUBE_VIDEOS_SHADOW";
  static REQUEST_YOUTUBE_VIDEOS_SHADOW_FINISHED =
    "YoutubeVideosShadowAction.REQUEST_YOUTUBE_VIDEOS_SHADOW_FINISHED";

  //Methods

  static requestYoutubeVideosWebhook(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosShadowAction.REQUEST_YOUTUBE_VIDEOS_SHADOW,
        YoutubeVideosShadowEffect.requestYoutubeVideosWebhook,
        params,
        callBackFunction
      );
    };
  }
}
