import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import YoutubeOfficialModel from "./model/YoutubeOfficialModel";
import YoutubeOfficialPutModel from "./model/YoutubeOfficialPutModel";
import YoutubeChannelRevenueModel from "./model/YoutubeChannelRevenueModel";
import YoutubeContentDownloadModel from "./model/YoutubeContentDownloadModel";

// StatusEffect
export default class YoutubeOfficialEffect {
  // calling an API according to the status model
  // Get method for the
  static async requestYoutubeOfficial(params) {
    const endpoint = environment.api.youtubeOfficial;
    let response = EffectUtility.getToModel(
      YoutubeOfficialModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestYoutubeOfficialClear() {
    return {};
  }
  static async requestYoutubeChannelRevenueClear() {
    return {};
  }

  static async requestYoutubeChannelRevenue(params) {
    const endpoint = environment.api.youtubeChannelRevenue;
    return EffectUtility.getToModel(
      YoutubeChannelRevenueModel,
      endpoint,
      params
    );
  }

  // put method for the YoutubeOfficial
  static async requestPutYoutubeOfficial(data, id) {
    const endpoint = environment.api.youtubeOfficial + "/" + id;
    return EffectUtility.putToModel(YoutubeOfficialPutModel, endpoint, data);
  }

  // For downloading youtube content
  static async requestYoutubeContentDownload(id, callBack) {
    const endpoint = environment.api.youtubeContentDownload + "/" + id;
    let response = EffectUtility.getToModel(
      YoutubeContentDownloadModel,
      endpoint
    );
    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }
}
