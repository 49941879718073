import { BaseModel } from "sjs-base-model";

export default class WebsweepxTopSitesGetModal extends BaseModel {
  // status = "";
  top_sites_to_be_automated = [];

  constructor(data) {
    super();
    this.update(data);
  }
}
