import { BaseModel } from "sjs-base-model";

export default class TicketSettingsHitsDataModel extends BaseModel {
  //ticketType hits data model
  _id = "";
  _index = "";
  _score = "";
  _source = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
