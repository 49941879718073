import BaseReducer from "../../../../utilities/BaseReducer";
import AssetCollectionAction from "./AssetCollectionAction";

// CopiesReducer
export default class CopiesReducer extends BaseReducer {
  initialState = {
    copies: [],
  };
  [AssetCollectionAction.REQUEST_GET_COPIES_FINISHED](state, action) {
    return {
      ...state,
      copies: action.payload,
    };
  }
}
