import BaseReducer from "../../../../utilities/BaseReducer";
import ChannelAnalyticsAccountAction from "./ChannelAnalyticsAccountAction";

export default class ChannelAnalyticsAccountFilterReduser extends BaseReducer {
  initialState = {
    channelAnalyticsAccountFilter: [],
  };

  [ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      channelAnalyticsAccountFilter: action.payload,
    };
  }
}
