import BaseReducer from "../../../utilities/BaseReducer";
import InstagramFeedsAction from "./InstagramFeedsAction";

export default class ThreadsFeedReduser extends BaseReducer {
  initialState = {
    threadFeeds: [],
  };

  [InstagramFeedsAction.REQUEST_THREADS_FINISHED](state, action) {
    return {
      ...state,
      threadFeeds: action.payload,
    };
  }
}
