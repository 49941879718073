import {
  Grid,
  Box,
  Tab,
  Button,
  Checkbox,
  IconButton,
  FormControlLabel,
  createFilterOptions,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Form, Formik, Field, FieldArray, ErrorMessage, getIn } from "formik";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import PriorityAction from "../../stores/priority/PriorityAction";
import StatusAction from "../../stores/status/StatusAction";
import UserAction from "../../stores/user/UserAction";
import AssignmentRuleAction from "../../stores/assignmentRule/AssignmentRuleAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import RolesAction from "../../../common/stores/roles/RolesAction";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    PriorityAction.REQUEST_PRIORITY_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    RolesAction.REQUEST_ROLES,
    UserAction.REQUEST_USER,
  ]),
  user: state.user.user || [],
  priority: state.filterpriority.filterpriority.hits || [],
  status: state.statusFilter.statusFilter.hits || [],
  roles: state.roles.roles || [],
});

const AssignmentPost = (props) => {
  const optionList = ["All Of These", "Any Of These"];

  const { editData, platformList } = props;

  const [tabLeftValue, setLeftTabValue] = React.useState("1");
  const [tabRightValue, setTabRightValue] = React.useState("1");
  const [numAll, setNumAll] = React.useState(1);
  const [numAny, setNumAny] = React.useState(1);
  const [booleanVal, setBooleanVal] = React.useState(false);
  const [openTab, setOpenTab] = React.useState([]);

  // filter priority dispatch function
  function fetchFilterPriority(params) {
    props.dispatch(PriorityAction.filterRequestPriority(params));
  }

  function fetchStatusFilter(param) {
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  // roles
  function fetch_role(params) {
    props.dispatch(RolesAction.requestRole(params));
  }

  function fetch_user(params) {
    props.dispatch(UserAction.requestUser(params));
  }

  let project = JSON.parse(localStorage.getItem("user_last_working_project"));

  useEffect(() => {
    // priority api
    const priority_param = {
      page_limit: "none",
      order_by: `[["ticket_priority_name.keyword", "asc"]]`,
      // filters: `[["project.project_id.keyword","must","","match",${JSON.stringify(
      //   project.project_id
      // )}]]`,
    };

    fetchFilterPriority(priority_param);

    // status api
    const status_param = {
      page_limit: "none",
      order_by: `[["status_name.keyword", "asc"]]`,
      // filters: `[["project.project_id.keyword","must","","match",${JSON.stringify(
      //   project.project_id
      // )}]]`,
    };

    fetchStatusFilter(status_param);

    const platform_param = {
      page_limit: "none",
    };

    const role_param = {
      page_limit: "none",
      filters: `[["project_id.keyword","must","","terms",["*",${JSON.stringify(
        project.project_id
      )}]]]`,
    };

    fetch_role(role_param);
    fetch_user(platform_param);
  }, []);

  useEffect(() => {
    let tabList = [];

    if (props.edit) {
      props.editData.conditions &&
        props.editData.conditions.allofthese.length > 0 &&
        tabList.push("All Of These");
      props.editData.conditions &&
        props.editData.conditions.anyofthese.length > 0 &&
        tabList.push("Any Of These");

      setOpenTab(tabList);

      if (props.editData.actions) {
        props.editData.actions.assign_to_role
          ? setBooleanVal(true)
          : setBooleanVal(false);
      }
    } else {
      setOpenTab([]);
    }
  }, [props.edit && props.open]);

  const tabLeftHandleChange = (event, newValue) => {
    setLeftTabValue(newValue);
  };

  useEffect(() => {
    if (openTab[0] === "Any Of These") {
      setLeftTabValue("2");
    }
  }, [openTab]);

  const tabRightHandleChange = (event, newValue) => {
    setTabRightValue(newValue);
  };

  const conditionHandleChange = (event, value) => {
    value.map((item) => {
      item === "Any Of These" ? setLeftTabValue("2") : setLeftTabValue("1");
      item === "All Of These" ? setLeftTabValue("1") : setLeftTabValue("2");
    });

    setOpenTab(value);
    value === ["All Of These"] ? setNumAll(1) : setNumAll(0);
    value === ["Any Of These"] ? setNumAny(1) : setNumAny(0);
  };

  let AgentList = [];

  if (props.user.data) {
    props.user.data.map((item) => {
      return AgentList.push({
        agent_id: item.id,
        agent_name: item.username,
      });
    });
  }

  let statusList = [];
  statusList = ["priority", "status", "platform"];

  let OperandList = ["Equal", "Not Equal"];

  let RoleList = [];

  if (props.roles.hits) {
    props.roles.hits.hits.map((item) => {
      return RoleList.push({
        role_id: item._id,
        role_name: item._source.role_name,
      });
    });
  }

  let priority_list = [];

  props.priority &&
    props.priority.hits &&
    props.priority.hits.map((val) => {
      priority_list.push({
        id: val._id,
        name: val._source.ticket_priority_name,
      });
    });

  let status_list = [];

  props.status &&
    props.status.hits &&
    props.status.hits.map((val) => {
      status_list.push({
        id: val._id,
        name: val._source.status_name,
      });
    });

  let agentFilterList = ["Online", "Offline", "all"];

  let alloftheseList = [];

  editData &&
    editData.conditions &&
    editData.conditions.allofthese &&
    editData.conditions.allofthese.map((item) => {
      alloftheseList.push({
        key: item.key,
        operand: item.operand === "==" ? "Equal" : "Not Equal",
        value: item.value.map((val) => {
          return {
            id: val.id,
            name: val.ticket_priority_name
              ? val.ticket_priority_name
              : val.status_name
              ? val.status_name
              : val.platform_name
              ? val.platform_name
              : "",
          };
        }),
      });
    });

  let anyoftheseList = [];

  editData &&
    editData.conditions &&
    editData.conditions.anyofthese &&
    editData.conditions.anyofthese.map((item) => {
      anyoftheseList.push({
        key: item.key,
        operand: item.operand === "==" ? "Equal" : "Not Equal",
        value: item.value.map((val) => {
          return {
            id: val.id,
            name: val.ticket_priority_name
              ? val.ticket_priority_name
              : val.status_name
              ? val.status_name
              : val.platform_name
              ? val.platform_name
              : "",
          };
        }),
      });
    });

  function AgentIdList(item) {
    let item_list = [];
    if (props.user.data) {
      props.user.data
        .filter((val) => item.includes(val.id))
        .map((item) => {
          return item_list.push({
            agent_id: item.id,
            agent_name: item.username,
          });
        });
    }

    return item_list && item_list;
  }

  function RoleIdList(item) {
    let role_list = [];

    if (props.roles.hits) {
      props.roles.hits.hits
        .filter((val) => item.includes(val._id))
        .map((item) => {
          return role_list.push({
            role_id: item._id,
            role_name: item._source.role_name,
          });
        });
    }

    return role_list && role_list;
  }

  // initial values

  let initialValues = {};

  if (props.edit) {
    initialValues = {
      rulename: editData.rulename,
      allofthese: alloftheseList,
      anyofthese: anyoftheseList,
      agent_filter: editData.actions.agent_filter,
      assign_to_role: editData.actions.assign_to_role,
      agent_id: AgentIdList(editData.actions.agent_id),
      role_id: RoleIdList(editData.actions.role_id),
      negetive_priority: editData.negetive_priority,
      neutral_priority: editData.neutral_priority,
      positive_priority: editData.positive_priority,
    };
  } else {
    initialValues = {
      rulename: "",
      allofthese: [
        {
          key: "",
          operand: "",
          value: [],
        },
      ],
      anyofthese: [
        {
          key: "",
          operand: "",
          value: [],
        },
      ],
      agent_filter: "",
      assign_to_role: false,
      agent_id: AgentList && AgentList.length === 1 ? AgentList : [],
      role_id: RoleList && RoleList.length === 1 ? RoleList : [],
      negetive_priority:
        priority_list && priority_list.length === 1 ? priority_list[0] : "",
      neutral_priority:
        priority_list && priority_list.length === 1 ? priority_list[0] : "",
      positive_priority:
        priority_list && priority_list.length === 1 ? priority_list[0] : "",
    };
  }

  function Required() {
    return (
      <>
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  const [loading, setLoading] = useState(false);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          let errors = {};

          openTab.includes("All Of These") &&
            values.allofthese.map((item, index) => {
              if (!item.key) {
                errors[`allofthese.${index}.key`] = <Required />;
              }

              if (!item.operand) {
                errors[`allofthese.${index}.operand`] = <Required />;
              }

              if (!item.value.length > 0) {
                errors[`allofthese.${index}.value`] = <Required />;
              }
            });

          openTab.includes("Any Of These") &&
            values.anyofthese.map((item, index) => {
              if (!item.key) {
                errors[`anyofthese.${index}.key`] = <Required />;
              }

              if (!item.operand) {
                errors[`anyofthese.${index}.operand`] = <Required />;
              }

              if (!item.value.length > 0) {
                errors[`anyofthese.${index}.value`] = <Required />;
              }
            });

          if (!values.rulename) {
            errors.rulename = <Required />;
          }

          if (!values.agent_filter) {
            errors.agent_filter = <Required />;
          }
          if (!booleanVal && !values.agent_id.length > 0) {
            errors.agent_id = <Required />;
          }
          if (!values.role_id.length > 0 && booleanVal) {
            errors.role_id = <Required />;
          }

          if (!values.negetive_priority) {
            errors.negetive_priority = <Required />;
          }

          if (!values.neutral_priority) {
            errors.neutral_priority = <Required />;
          }

          if (!values.positive_priority) {
            errors.positive_priority = <Required />;
          }

          return errors;
        }}
        onSubmit={async (values) => {
          setLoading(true);
          // await new Promise((r) => setTimeout(r, 500));
          // alert(JSON.stringify(values, null, 2));

          let alloftheseList = [];

          values.allofthese.map((item) => {
            return alloftheseList.push({
              key: item.key,
              operand: item.operand === "Equal" ? "==" : "!=",
              value:
                item.key === "priority"
                  ? item.value.map((val) => {
                      return val.id;
                    })
                  : item.key === "status"
                  ? item.value.map((val) => {
                      return val.id;
                    })
                  : item.key === "platform"
                  ? item.value.map((val) => {
                      return val.id;
                    })
                  : [],
            });
          });

          let anyoftheseList = [];

          values.anyofthese.map((item) => {
            return anyoftheseList.push({
              key: item.key,
              operand: item.operand === "Equal" ? "==" : "!=",
              value:
                item.key === "priority"
                  ? item.value.map((val) => {
                      return val.id;
                    })
                  : item.key === "status"
                  ? item.value.map((val) => {
                      return val.id;
                    })
                  : item.key === "platform"
                  ? item.value.map((val) => {
                      return val.id;
                    })
                  : [],
            });
          });

          let agentIdList = [];

          values.agent_id &&
            values.agent_id.map((item) => {
              agentIdList.push(item.agent_id);
            });

          let roleIdList = [];

          values.role_id &&
            values.role_id.map((item) => {
              roleIdList.push(item.role_id);
            });

          let conditions = {};
          let actions = {};

          Object.assign(
            conditions,
            openTab.includes("All Of These") && { allofthese: alloftheseList },
            openTab.includes("Any Of These") && { anyofthese: anyoftheseList }
            // values.agent_filter && { age }
          );

          Object.assign(
            actions,
            { agent_filter: values.agent_filter },
            { assign_to_role: values.assign_to_role },
            booleanVal && { role_id: roleIdList },
            { agent_id: agentIdList }
          );

          let set_priority = {};

          Object.assign(
            set_priority,
            values.negetive_priority && {
              negative: values.negetive_priority.id,
            },
            values.neutral_priority && {
              neutral: values.neutral_priority.id,
            },
            values.positive_priority && {
              positive: values.positive_priority.id,
            }
            // values.agent_filter && { age }
          );

          let postObject = {
            rulename: values.rulename,
            conditions: conditions,
            actions: actions,
            set_priority: set_priority,
          };

          let page_limit = 15;

          const params = {
            order_by: `[["order_number","asc"]]`,
            page_limit: `${page_limit}`,
          };

          const param = {
            order_by: `[["rulename.keyword", "asc"]]`,
            page_limit: "none",
          };

          let id = editData && editData.id;

          if (!props.edit) {
            props
              .dispatch(
                AssignmentRuleAction.requestPostAssignmentRule(postObject)
              )
              .then(() => {
                props.dispatch(
                  AssignmentRuleAction.requestAssignmentRule(params)
                );

                props.dispatch(
                  AssignmentRuleAction.requestAssignmentRuleFilter(param)
                );

                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(
                AssignmentRuleAction.requestPutAssignmentRule(postObject, id)
              )
              .then(() => {
                props.dispatch(
                  AssignmentRuleAction.requestAssignmentRule(params)
                );

                props.dispatch(
                  AssignmentRuleAction.requestAssignmentRuleFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
        }) => (
          <Form>
            <Grid sx={{ p: 2 }}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={
                    {
                      // backgroundColor: "#d3e2e6",
                    }
                  }
                >
                  <IconButton
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <ArrowCircleLeftIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid
                  container
                  item
                  xl={11}
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  alignItems="center"
                  sx={
                    {
                      // backgroundColor: "#d3e2e6",
                    }
                  }
                >
                  <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
                    <h6>New Ticket Assignment Condition</h6>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                mt={1}
                justifyContent="flex-end"
              >
                <Grid
                  display="flex"
                  spacing={2}
                  gap={2}
                  sx={{ marginRight: 2 }}
                >
                  <Grid item lg={3} md={3} sm={3} minWidth={150}>
                    <MuiButton
                      name={"Save"}
                      loading={loading}
                      size="small"
                      width="100%"
                      type={"submit"}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} minWidth={150}>
                    {props.edit ? (
                      <MuiButton
                        name={"Reset"}
                        type={"reset"}
                        size="small"
                        width="100%"
                        // onClick={() => {
                        //   props.onClose();
                        // }}
                      />
                    ) : (
                      <MuiButton
                        name={"Reset"}
                        type={"reset"}
                        size="small"
                        width="100%"
                        // onClick={() => {
                        //   props.onClose();
                        // }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container lg={6} md={6} sm={12}>
                <Grid
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ alignItems: "center" }}
                >
                  <Grid item lg={2} md={4} sm={5}>
                    <h6>Rule Name * :</h6>
                  </Grid>
                  <Grid item lg={10} md={8} sm={7}>
                    <Field
                      variant="outlined"
                      id="rulename"
                      as={MuiTextField}
                      autoFocus
                      size="small"
                      name="rulename"
                      placeholder="Rule Name"
                      error={Boolean(
                        getIn(touched, "rulename") && getIn(errors, "rulename")
                      )}
                      helperText={
                        getIn(touched, "rulename") && getIn(errors, "rulename")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container lg={6} md={6} sm={12} mt={1}>
                <Grid
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ alignItems: "center" }}
                >
                  <Grid item lg={2} md={4} sm={5}>
                    <h6>Condition </h6>
                  </Grid>

                  <Grid item lg={10} md={8} sm={7}>
                    <AutoCompleteComponent
                      value={openTab}
                      handleChange={conditionHandleChange}
                      list={optionList}
                      textFieldProps={{
                        placeholder:
                          openTab && openTab.length === 0
                            ? "Select Option"
                            : null,
                      }}
                      size="small"
                      width="100%"
                      multiple={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                display="flex"
                mt={1}
                justifyContent={"space-between"}
                sx={{ paddingBottom: 10 }}
              >
                <Grid
                  container
                  item
                  lg={7.5}
                  md={12}
                  sm={12}
                  scroll
                  sx={{
                    // backgroundColor: "#fff",
                    padding: 2,
                    borderRadius: 5,
                    border: "solid 0.5px #d3e2e6",
                    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <TabContext value={tabLeftValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "#d4edf5" }}>
                        <TabList
                          onChange={tabLeftHandleChange}
                          aria-label="lab API tabs example"
                        >
                          {openTab && openTab.includes("All Of These") && (
                            <Tab
                              sx={{ border: "none" }}
                              label="All Of These"
                              value="1"
                            />
                          )}
                          {openTab && openTab.includes("Any Of These") && (
                            <Tab
                              sx={{ border: "none" }}
                              label="Any Of These"
                              value="2"
                            />
                          )}
                        </TabList>
                      </Box>
                      {openTab && openTab.includes("All Of These") && (
                        <TabPanel
                          value="1"
                          sx={{
                            // backgroundColor: "white",
                            padding: "5px",
                            height: "auto",
                            width: "100%",
                          }}
                        >
                          <FieldArray name="allofthese">
                            {({ insert, remove, push }) => (
                              <Grid
                                container
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                className="d-flex justify-content-center"
                                // sx={{
                                //   height: "100px",
                                // }}
                              >
                                {values.allofthese &&
                                  values.allofthese.length > 0 &&
                                  values.allofthese.map((allofthese, index) => (
                                    <Grid
                                      container
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      justifyContent="space-between"
                                      alignItems="center"
                                      mt={1}
                                      key={index}
                                    >
                                      <Grid
                                        item
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        container
                                      >
                                        <Grid
                                          item
                                          lg={3.5}
                                          md={3.5}
                                          sm={3.5}
                                          mt={3}
                                        >
                                          Source * :
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={8}>
                                          <Field
                                            name={`allofthese.${index}.key`}
                                            size="small"
                                            disableClearable={true}
                                            // Calling AutoCompleteMultiSelect Component
                                            value={
                                              values.allofthese[index].key
                                                ? values.allofthese[index].key
                                                : null
                                            }
                                            component={AutoCompleteMultiSelect}
                                            options={
                                              statusList ? statusList : []
                                            }
                                            onChange={(_, value) => {
                                              setFieldValue(
                                                `allofthese.${index}.key`,
                                                value
                                              );
                                              setFieldValue(
                                                `allofthese.${index}.value`,
                                                []
                                              );
                                            }}
                                            getOptionLabel={(option) => option}
                                            textFieldProps={{
                                              margin: "normal",
                                              variant: "outlined",
                                              placeholder: "Source",
                                            }}
                                          />
                                          {errors[
                                            `allofthese.${index}.key`
                                          ] && (
                                            <span
                                              style={{ position: "absolute" }}
                                            >
                                              {
                                                errors[
                                                  `allofthese.${index}.key`
                                                ]
                                              }
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        container
                                      >
                                        <Grid
                                          item
                                          lg={3.5}
                                          md={3.5}
                                          sm={3.5}
                                          mt={3.5}
                                        >
                                          Operand * :
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={8}>
                                          <Field
                                            name={`allofthese.${index}.operand`}
                                            size="small"
                                            disableClearable={true}
                                            // Calling AutoCompleteMultiSelect Component
                                            // value={
                                            //   values.roles[index].role
                                            //     ? values.roles[index].role
                                            //     : null
                                            // }
                                            component={AutoCompleteMultiSelect}
                                            onChange={(_, value) => {
                                              setFieldValue(
                                                `allofthese.${index}.operand`,
                                                value
                                              );
                                            }}
                                            options={OperandList}
                                            getOptionLabel={(option) => option}
                                            textFieldProps={{
                                              margin: "normal",
                                              variant: "outlined",
                                              placeholder: "Operand",
                                            }}
                                          />
                                          {errors[
                                            `allofthese.${index}.operand`
                                          ] && (
                                            <span
                                              style={{ position: "absolute" }}
                                            >
                                              {
                                                errors[
                                                  `allofthese.${index}.operand`
                                                ]
                                              }
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        md={4}
                                        sm={4}
                                        container
                                      >
                                        <Grid item lg={3} md={3} sm={3} mt={3}>
                                          Values * :
                                        </Grid>

                                        <Grid item lg={8} md={8} sm={8}>
                                          <Field
                                            limitTags={1}
                                            name={`allofthese.${index}.value`}
                                            size="small"
                                            // disableClearable={true}
                                            multiple
                                            value={
                                              values.allofthese[index].value
                                                ? values.allofthese[index].value
                                                : []
                                            }
                                            disableCloseOnSelect={true}
                                            component={AutoCompleteMultiSelect}
                                            options={
                                              values.allofthese[index].key ===
                                              "priority"
                                                ? priority_list
                                                : values.allofthese[index]
                                                    .key === "status"
                                                ? status_list
                                                : values.allofthese[index]
                                                    .key === "platform"
                                                ? platformList
                                                : values.allofthese[index]
                                                    .key === ""
                                                ? []
                                                : []
                                            }
                                            renderOption={(
                                              props,
                                              option,
                                              { selected }
                                            ) => (
                                              <li {...props}>
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{
                                                    marginRight: 8,
                                                  }}
                                                  checked={selected}
                                                />
                                                {option.name}
                                              </li>
                                            )}
                                            isOptionEqualToValue={(
                                              option,
                                              value
                                            ) =>
                                              //setting value property to custom value
                                              values.allofthese[index].key ===
                                              "status"
                                                ? option.id === value.id
                                                : option.name === value.name
                                            }
                                            getOptionLabel={(option) =>
                                              option.name ? option.name : option
                                            }
                                            textFieldProps={{
                                              margin: "normal",
                                              variant: "outlined",
                                              // placeholder: "Values",
                                              // zIndex: 999,
                                              placeholder:
                                                values.allofthese[index]
                                                  .value &&
                                                !values.allofthese[index].value
                                                  .length
                                                  ? "Value"
                                                  : null,
                                            }}
                                          />
                                          {errors[
                                            `allofthese.${index}.value`
                                          ] && (
                                            <span
                                              style={{ position: "absolute" }}
                                            >
                                              {
                                                errors[
                                                  `allofthese.${index}.value`
                                                ]
                                              }
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={1}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        container
                                        mt={1}
                                        className="d-flex justify-content-end"
                                      >
                                        <MuiButton
                                          name={<DeleteIcon />}
                                          onClick={() => remove(index)}
                                        />
                                      </Grid>
                                    </Grid>
                                  ))}
                                <Grid
                                  container
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  justifyContent="flex-end"
                                  mt={1}
                                >
                                  <MuiButton
                                    onClick={() =>
                                      push({
                                        key: "",
                                        operand: "",
                                        value: [],
                                      })
                                    }
                                    name={<AddIcon />}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                        </TabPanel>
                      )}

                      {openTab && openTab.includes("Any Of These") && (
                        <TabPanel
                          value="2"
                          sx={{
                            padding: "5px",
                            // backgroundColor: "white",
                            height: "auto",
                            width: "100%",
                          }}
                        >
                          <FieldArray name="anyofthese">
                            {({ insert, remove, push }) => (
                              <Grid
                                container
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                // px={1}
                                className="d-flex justify-content-center"
                              >
                                {values.anyofthese &&
                                  values.anyofthese.length > 0 &&
                                  values.anyofthese.map((role, index) => (
                                    <Grid
                                      container
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      justifyContent="space-between"
                                      alignItems="center"
                                      mt={1}
                                      key={index}
                                    >
                                      <Grid
                                        item
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        container
                                      >
                                        <Grid
                                          item
                                          lg={3.5}
                                          md={3.5}
                                          sm={3.5}
                                          mt={3}
                                        >
                                          Source * :
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={8}>
                                          <Field
                                            name={`anyofthese.${index}.key`}
                                            size="small"
                                            disableClearable={true}
                                            // Calling AutoCompleteMultiSelect Component
                                            value={
                                              values.anyofthese[index].key
                                                ? values.anyofthese[index].key
                                                : null
                                            }
                                            component={AutoCompleteMultiSelect}
                                            options={
                                              statusList ? statusList : []
                                            }
                                            onChange={(_, value) => {
                                              setFieldValue(
                                                `anyofthese.${index}.key`,
                                                value
                                              );
                                              setFieldValue(
                                                `anyofthese.${index}.value`,
                                                []
                                              );
                                            }}
                                            getOptionLabel={(option) => option}
                                            textFieldProps={{
                                              margin: "normal",
                                              variant: "outlined",
                                              placeholder: "Source",
                                            }}
                                          />
                                          {errors[
                                            `anyofthese.${index}.key`
                                          ] && (
                                            <span
                                              style={{ position: "absolute" }}
                                            >
                                              {
                                                errors[
                                                  `anyofthese.${index}.key`
                                                ]
                                              }
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        container
                                      >
                                        <Grid
                                          item
                                          lg={3.5}
                                          md={3.5}
                                          sm={3.5}
                                          mt={3.5}
                                        >
                                          Operand * :
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={8}>
                                          <Field
                                            name={`anyofthese.${index}.operand`}
                                            size="small"
                                            disableClearable={true}
                                            // Calling AutoCompleteMultiSelect Component
                                            // value={
                                            //   values.roles[index].role
                                            //     ? values.roles[index].role
                                            //     : null
                                            // }
                                            component={AutoCompleteMultiSelect}
                                            onChange={(_, value) => {
                                              setFieldValue(
                                                `anyofthese.${index}.operand`,
                                                value
                                              );
                                            }}
                                            options={OperandList}
                                            getOptionLabel={(option) => option}
                                            textFieldProps={{
                                              margin: "normal",
                                              variant: "outlined",
                                              placeholder: "Operand",
                                            }}
                                          />
                                          {errors[
                                            `anyofthese.${index}.operand`
                                          ] && (
                                            <span
                                              style={{ position: "absolute" }}
                                            >
                                              {
                                                errors[
                                                  `anyofthese.${index}.operand`
                                                ]
                                              }
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        md={4}
                                        sm={4}
                                        container
                                      >
                                        <Grid item lg={3} md={3} sm={3} mt={3}>
                                          Values * :
                                        </Grid>
                                        <Grid
                                          item
                                          lg={8}
                                          md={8}
                                          sm={8}
                                          style={
                                            {
                                              // position: "absolute",
                                              // width: "95%",
                                              // zIndex: 999,
                                            }
                                          }
                                        >
                                          <Field
                                            limitTags={1}
                                            name={`anyofthese.${index}.value`}
                                            size="small"
                                            // disableClearable={true}
                                            multiple
                                            value={
                                              values.anyofthese[index].value
                                                ? values.anyofthese[index].value
                                                : []
                                            }
                                            disableCloseOnSelect={true}
                                            component={AutoCompleteMultiSelect}
                                            options={
                                              values.anyofthese[index].key ===
                                              "priority"
                                                ? priority_list
                                                : values.anyofthese[index]
                                                    .key === "status"
                                                ? status_list
                                                : values.anyofthese[index]
                                                    .key === "platform"
                                                ? platformList
                                                : values.anyofthese[index]
                                                    .key === ""
                                                ? []
                                                : []
                                            }
                                            renderOption={(
                                              props,
                                              option,
                                              { selected }
                                            ) => (
                                              <li {...props}>
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{
                                                    marginRight: 8,
                                                  }}
                                                  checked={selected}
                                                />
                                                {option.name}
                                              </li>
                                            )}
                                            isOptionEqualToValue={(
                                              option,
                                              value
                                            ) =>
                                              //setting value property to custom value
                                              values.anyofthese[index].key ===
                                              "status"
                                                ? option.id === value.id
                                                : option.name === value.name
                                            }
                                            getOptionLabel={(option) =>
                                              option.name ? option.name : option
                                            }
                                            textFieldProps={{
                                              margin: "normal",
                                              variant: "outlined",
                                              // placeholder: "Value",
                                              placeholder:
                                                values.anyofthese[index]
                                                  .value &&
                                                !values.anyofthese[index].value
                                                  .length
                                                  ? "Value"
                                                  : null,
                                            }}
                                          />
                                          {errors[
                                            `anyofthese.${index}.value`
                                          ] && (
                                            <span
                                              style={{ position: "absolute" }}
                                            >
                                              {
                                                errors[
                                                  `anyofthese.${index}.value`
                                                ]
                                              }
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={1}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        container
                                        mt={1}
                                        className="d-flex justify-content-end"
                                      >
                                        <MuiButton
                                          name={<DeleteIcon />}
                                          onClick={() => remove(index)}
                                        />
                                      </Grid>
                                    </Grid>
                                  ))}
                                <Grid
                                  container
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  justifyContent="flex-end"
                                  mt={1}
                                >
                                  <MuiButton
                                    onClick={() =>
                                      push({
                                        key: "",
                                        operand: "",
                                        value: [],
                                      })
                                    }
                                    name={<AddIcon />}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </FieldArray>
                        </TabPanel>
                      )}
                    </TabContext>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  lg={4.2}
                  md={12}
                  sm={12}
                  sx={{
                    backgroundColor: "#fff",
                    padding: 2,
                    borderRadius: 5,
                    border: "solid 0.5px #d3e2e6",
                    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                    height: 550,
                    paddingBottom: 10,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <TabContext value={tabRightValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "#d4edf5" }}>
                        <TabList
                          onChange={tabRightHandleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            sx={{ border: "none" }}
                            label="Actions"
                            value="1"
                          />

                          {/* <Tab
                            sx={{ border: "none" }}
                            label="Priorities"
                            value="2"
                          /> */}
                        </TabList>
                      </Box>

                      <TabPanel
                        value="1"
                        sx={{
                          // backgroundColor: "white",
                          padding: "5px",
                          height: "auto",
                          width: "100%",
                        }}
                        className="p-0 m-0"
                      >
                        <Box
                          sx={{
                            height: 450,
                            overflow: "auto",
                            overscrollBehaviorY: "scroll",
                          }}
                          className="scrollable"
                        >
                          <Grid container px={1}>
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>User Status *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`agent_filter`}
                                  size="small"
                                  limitTags={1}
                                  disableClearable={true}
                                  // Calling AutoCompleteMultiSelect Component
                                  // value={
                                  //   values.roles[index].role
                                  //     ? values.roles[index].role
                                  //     : null
                                  // }

                                  component={AutoCompleteMultiSelect}
                                  options={agentFilterList}
                                  getOptionLabel={(option) => option}
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "User Status",
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"flex-start"}
                            >
                              <Grid item lg={4} md={5} sm={5} mt={1}>
                                <span>Assigned to Role</span>
                              </Grid>

                              <Grid item lg={1} md={2} sm={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.assign_to_role}
                                      onClick={(event) => {
                                        if (event.target.checked) {
                                          setBooleanVal(true);
                                        } else {
                                          setBooleanVal(false);
                                        }
                                      }}
                                      onChange={handleChange}
                                    />
                                  }
                                  // label="Has Parent Field"
                                  name="assign_to_role"
                                  value={values.assign_to_role}
                                />
                              </Grid>
                            </Grid>

                            {booleanVal && (
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={2} md={2} sm={2} mt={1}>
                                  <span>Roles {booleanVal ? `*` : null}</span>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  sx={{ position: "relative" }}
                                >
                                  <Field
                                    limitTags={1}
                                    name="role_id"
                                    sx={{
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }}
                                    component={AutoCompleteMultiSelect}
                                    multiple
                                    value={values.role_id ? values.role_id : []}
                                    variant="outlined"
                                    options={RoleList}
                                    disabled={
                                      RoleList && RoleList.length === 1
                                        ? true
                                        : false
                                    }
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{
                                            marginRight: 8,
                                          }}
                                          checked={selected}
                                        />
                                        {option.role_name}
                                      </li>
                                    )}
                                    textFieldProps={{
                                      placeholder: "Roles",
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.role_id === value.role_id
                                    }
                                    // inputRef={inputRef1}
                                    // sx={{ width: "240px" }}
                                    getOptionLabel={(option) =>
                                      option.role_name
                                    }
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )}

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={3}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={2} sm={2} mt={1}>
                                <span>User {!booleanVal ? `*` : null}</span>
                              </Grid>
                              <Grid
                                item
                                lg={8}
                                md={8}
                                sm={8}
                                style={{ position: "relative" }}
                              >
                                <Field
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }}
                                  name="agent_id"
                                  component={AutoCompleteMultiSelect}
                                  limitTags={1}
                                  multiple
                                  value={values.agent_id ? values.agent_id : []}
                                  variant="outlined"
                                  options={AgentList}
                                  disabled={
                                    AgentList && AgentList.length === 1
                                      ? true
                                      : false
                                  }
                                  textFieldProps={{
                                    placeholder: "User",
                                  }}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{
                                          marginRight: 8,
                                        }}
                                        checked={selected}
                                      />
                                      {option.agent_name}
                                    </li>
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.agent_id === value.agent_id
                                  }
                                  filterOptions={createFilterOptions({
                                    matchFrom: "start",
                                    stringify: (option) => option.agent_name,
                                  })}
                                  getOptionLabel={(option) => option.agent_name}
                                  size="small"
                                />
                                <br />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={2}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>Negative *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={"negetive_priority"}
                                  size="small"
                                  value={
                                    values.negetive_priority
                                      ? values.negetive_priority
                                      : null
                                  }
                                  disabled={
                                    priority_list && priority_list.length === 1
                                      ? true
                                      : false
                                  }
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  options={priority_list}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.name === value.name
                                  }
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Negative Priority",
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>Neutral *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={"neutral_priority"}
                                  size="small"
                                  value={
                                    values.neutral_priority
                                      ? values.neutral_priority
                                      : null
                                  }
                                  disabled={
                                    priority_list && priority_list.length === 1
                                      ? true
                                      : false
                                  }
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  options={priority_list}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.name === value.name
                                  }
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Neutral Priority",
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>Positive *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={"positive_priority"}
                                  size="small"
                                  value={
                                    values.positive_priority
                                      ? values.positive_priority
                                      : null
                                  }
                                  disabled={
                                    priority_list && priority_list.length === 1
                                      ? true
                                      : false
                                  }
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  options={priority_list}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.name === value.name
                                  }
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Positive Priority",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </TabPanel>

                      {/* <TabPanel
                        value="2"
                        sx={{
                          // backgroundColor: "white",
                          padding: "5px",
                          height: "auto",
                          width: "100%",
                        }}
                      >
                        <Grid container></Grid>
                      </TabPanel> */}
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default connect(mapStateToProps)(AssignmentPost);
