import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Typography } from "@mui/material";
import { Pie, PolarArea } from "react-chartjs-2";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoPieChart } from "../../../../components/nivoCharts/NivoPieChart";
import { firstLetterOrange } from "../../../../components/ticketPartials";

function OverallSentimentMentionChart3months(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);
  const [sentimentData, setSentimentData] = useState(null);

  function getLast3monthStartAndThismonthEndDate() {
    let date = new Date();
    const last3monthStartDate = new Date(
      date.getFullYear(),
      date.getMonth() - 2,
      2
    );
    const last3monthstartDatestring = last3monthStartDate
      .toISOString()
      .slice(0, 10);

    const currentMonthEnddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    );
    const currentMonthendDatestring = currentMonthEnddate
      .toISOString()
      .slice(0, 10);

    return {
      last3monthStartDate: last3monthstartDatestring,
      currentMonthEnddate: currentMonthendDatestring,
    };
  }

  let { last3monthStartDate, currentMonthEnddate } =
    getLast3monthStartAndThismonthEndDate();

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchSentimentData().then((res) => {
        setSentimentData(res);
      });
      setLoading(false);
    })();
  }, []);

  async function fetchSentimentData() {
    let API = `${environment.api.sentimentalAnalysisReports}?report_type=["count"]&reports=["overall_sentiment_mentions"]&from=${last3monthStartDate}&to=${currentMonthEnddate}`;

    try {
      const res = await axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  let labels = ["Positive(+ve)", "Negative(-ve)", "Neutral(∅)"];
  const countData = [];

  sentimentData &&
    sentimentData.sentiment_analysis_report &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions
      .overall_sentiment_mentions &&
    countData.push(
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.total_positive,
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.total_negative,
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.total_neutral
    );

  let truepercent = [];

  sentimentData &&
    sentimentData.sentiment_analysis_report &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions
      .overall_sentiment_mentions &&
    truepercent.push(
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.last_three_months_positive_percentage,
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.last_three_months_negative_percentage,
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.last_three_months_neutral_percentage
    );
  let NoDataFoundBool =
    !loading &&
    sentimentData &&
    sentimentData.sentiment_analysis_report &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions
      .overall_sentiment_mentions &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions
      .overall_sentiment_mentions.last_three_months_negative_percentage +
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.last_three_months_neutral_percentage +
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.last_three_months_positive_percentage ===
      0;

  const state = {
    labels: labels,
    datasets: [
      {
        type: "polarArea",
        data: countData && countData,
        borderWidth: 2,
        // backgroundColor: [
        //   `${theme.reports.positive}`,
        //   `${theme.reports.negative}`,
        //   `${theme.reports.neutral}`,
        // ],
        backgroundColor: [
          "rgb(70, 140, 157 , 0.5)", // Transparent positive color
          "rgb(252, 141, 114 , 0.6)", // Transparent negative color
          "rgb(146, 196, 208 , 0.2)", // Transparent neutral color
        ],
      },
    ],
  };

  let colors = [
    `${theme.reports.positive}`,
    `${theme.reports.negative}`,
    `${theme.reports.neutral}`,
  ];

  const chartData = labels.map((label, index) => ({
    id: label,
    label: label,
    value: countData[index],
  }));

  const options = {
    responsive: true,
    animation: false,

    plugins: {
      title: {
        display: true,
      },

      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
          padding: 20,
          align: "end",
          generateLabels: function (chart) {
            const { data } = chart;

            if (data.labels.length) {
              return data.labels.map((label, i) => {
                return {
                  text: `${label}: ${data.datasets[0].data[i]}(${truepercent[i]}%)`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  index: i,
                };
              });
            } else {
              return [];
            }
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const countLabel = countData[context.dataIndex] || 0;
            return `${label}: ${countLabel}(${
              truepercent[context.dataIndex]
            }%)`;
          },
        },
      },

      datalabels: {
        align: "end",
        anchor: "end",
        display: true,
        color: "black",
        formatter: (value) => {
          return value;
        },
      },
    },
    r: {
      suggestedMin: 0,
    },

    // radius: "100%",
  };

  const summaryBoxRef = useRef(null);
  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `50px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 50
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const [renderKey, setRenderKey] = useState(0);

  return (
    <Box
      width={"100%"}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontSize: theme.reports.headerSize,
                padding: theme.reports.headerPadding,
              }}
            >
              {firstLetterOrange(props.report && props.report)}
            </Typography>

            <hr className="p-0 m-0 " />
          </Box>

          <Box
            className="scrollable"
            sx={{
              padding: "12px",
              overflowY: "auto",
              height: "100%",
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  width: "3px",
                  height: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#b0d2da",
                  borderRadius: "3px",
                  "&:hover": {
                    backgroundColor: "#85bac6",
                  },
                },
              },
            }}
          >
            {!loading ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {!loading && !NoDataFoundBool ? (
                  <Box
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {/* <PolarArea
                      style={{
                        alignItems: "center",
                      }}
                      data={state}
                      options={options}
                    /> */}
                    <NivoPieChart
                      chartData={chartData}
                      anchor="bottom"
                      direction="row"
                      translateX={0}
                      translatey={0}
                      itemHeight={-50}
                      enableArcLinkLabels={false}
                      colors={[
                        `${theme.reports.positive}`,
                        `${theme.reports.negative}`,
                        `${theme.reports.neutral}`,
                      ]}
                    />
                  </Box>
                ) : (
                  !loading && <NoDataFound />
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </Box>
        </Box>
      </LoadingIndicator>
    </Box>
  );
}

export default OverallSentimentMentionChart3months;
