import BaseReducer from "../../../../utilities/BaseReducer";
import MailLogsAction from "./MailLogsAction";

// SessionLogReuser
export default class MailLogsReducer extends BaseReducer {
  initialState = {
    mailLogs: [],
  };
  [MailLogsAction.REQUEST_MAIL_LOGS_FINISHED](state, action) {
    return {
      ...state,
      mailLogs: action.payload,
    };
  }
}
