import { BaseModel } from "sjs-base-model";

// Inhstagram put model
export default class InstagramAccountPutModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point
  // message = "";
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
