import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import StatusModel from "../status/model/StatusModel";
import StatusPostModel from "../status/model/StatusPostModel";

// InrRatesEffect
export default class InrRatesEffect {
  // calling an API according to the inrrates model
  // Get method for the
  static async requestInrRates(params) {
    const endpoint = environment.api.inrRates;
    return EffectUtility.getToModel(StatusModel, endpoint, params);
  }

  // Get method for the inrrates filter
  static async requestInrRatesFilter(params) {
    const endpoint = environment.api.inrRates;
    return EffectUtility.getToModel(StatusModel, endpoint, params);
  }

  // post method for the inrrates
  static async requestPostInrRates(data) {
    const endpoint = environment.api.inrRates;
    return EffectUtility.postToModel(StatusPostModel, endpoint, data);
  }
}
