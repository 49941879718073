import BaseReducer from "../../../utilities/BaseReducer";
import PlatformAccountsAction from "./PlatformAccountsAction";

//Platform Account Reducer
export default class PlatformAccountReducer extends BaseReducer {
  initialState = {
    platformAccount: [],
  };

  [PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      platformAccount: action.payload,
    };
  }
}
