import BaseReducer from "../../../../utilities/BaseReducer";
import ContactAction from "./ContactAction";

// ContactReduser
export default class ContactReducer extends BaseReducer {
  initialState = {
    Contact: [],
  };
  [ContactAction.REQUEST_CONTACT_FINISHED](state, action) {
    return {
      ...state,
      Contact: action.payload,
    };
  }

  [ContactAction.REQUEST_CONTACT_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Contact: action.payload,
    };
  }
}
