import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

import { Form, Formik, Field } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import OrganizationAction from "../../stores/organization/OrganizationAction";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../components/muiButton/MuiButton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const OrganizationPost = (props) => {
  let initial = {};
  if (props.edit) {
    initial = {
      organization_name: props.projectEditData.organisation_name,
      platform: props.projectEditData.platform || [],
    };
  } else {
    initial = {
      organization_name: "",
      platform:
        props.platform_array && props.platform_array.length === 1
          ? props.platform_array
          : [],
    };
  }

  const validationSchema = yup.object({
    organization_name: yup
      .string("Enter Organisation name")
      .trim()
      .min(1, "Organisation name should be of minimum 1 characters length")
      .required("Organisation name is required"),

    project_id: yup.string(),
  });
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};
          if (!values.platform.length) {
            errors.platform = "Platform Name is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          const params = {
            order_by: '[["created_on", "desc"]]',
            page_limit: 15,
          };
          const param = {
            order_by: `[["organization_name.keyword", "asc"]]`,
            page_limit: "none",
          };
          if (props.edit) {
            props
              .dispatch(
                OrganizationAction.requestPutOrganization(
                  JSON.stringify(values),
                  props.projectEditData.organization_unique_id
                )
              )
              .then(() => {
                props.dispatch(OrganizationAction.requestOrganization(params));
                props.dispatch(
                  OrganizationAction.requestOrganizationFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(OrganizationAction.requestPostOrganization(values))
              .then(() => {
                const params = {
                  order_by: '[["created_on", "desc"]]',
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["organization_name.keyword", "asc"]]`,
                  page_limit: "none",
                };
                props.dispatch(OrganizationAction.requestOrganization(params));
                props.dispatch(
                  OrganizationAction.requestOrganizationFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">O</span>
                            rganization &nbsp;
                            <span className="span_first_letter">D</span>etails
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">O</span>
                            rganization &nbsp;
                            <span className="span_first_letter">D</span>etails
                          </b>
                        )}
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div
                        className="col-2 closebtn"
                        onClick={() => {
                          props.onClose();
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Organization Name * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      autoFocus
                      variant="outlined"
                      placeholder="Organization Name"
                      id="organization_name"
                      size="small"
                      name="organization_name"
                      disabled={props.edit ? true : false}
                      error={
                        Boolean(errors.organization_name) &&
                        Boolean(touched.organization_name)
                      }
                      helperText={
                        Boolean(touched.organization_name) &&
                        errors.organization_name
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} mt={3}>
                    Platform * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      name="platform"
                      size="small"
                      multiple
                      // Calling AutoCompleteMultiSelect Component
                      value={values.platform ? values.platform : null}
                      component={AutoCompleteMultiSelect}
                      // multiple
                      disableCloseOnSelect={true}
                      disabled={
                        props.platform_array &&
                        props.platform_array.length === 1
                          ? true
                          : false
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.platform_alias}
                        </li>
                      )}
                      options={props.platform_array ? props.platform_array : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.platform_alias === value.platform_alias
                      }
                      getOptionLabel={(option) => option.platform_alias}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder:
                          !values.platform.length > 0 ? "Platform" : null,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Add Organization Name"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      // onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(OrganizationPost);
