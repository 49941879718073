import ActionUtility from "../../../utilities/ActionUtility";
import GmbQuestionsFeedEffect from "./GmbQuestionsFeedEffect";

export default class GmbQuestionFeedsAction {
  // GmbQuestionFeedsAction  with an function declaration
  static REQUEST_GMB_QUESTION_FEED =
    "GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED";
  static REQUEST_GMB_QUESTION_FEED_FINISHED =
    "GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_FINISHED";

  static REQUEST_GMB_QUESTION_FEED_FILTER =
    "GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_FILTER";
  static REQUEST_GMB_QUESTION_FEED_FILTER_FINISHED =
    "GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_FILTER_FINISHED";

  static REQUEST_PUT_GMB_QUESTION =
    "GmbQuestionFeedsAction.REQUEST_PUT_GMB_QUESTION";
  static REQUEST_PUT_GMB_QUESTION_FINISHED =
    "GmbQuestionFeedsAction.REQUEST_PUT_GMB_QUESTION_FINISHED";

  static REQUEST_GMB_QUESTION_FEED_CLEAR =
    "GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_CLEAR";
  static REQUEST_GMB_QUESTION_FEED_CLEAR_FINISHED =
    "GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_CLEAR_FINISHED";

  // METHODS
  // GMB Question Feeds GET function
  static requestGmbQuestionFeed(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED,
        GmbQuestionsFeedEffect.requestGmbQuestionFeed,
        params,
        callBackFunction
      );
    };
  }

  static requestGmbQuestionFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_CLEAR,
        GmbQuestionsFeedEffect.requestGmbQuestionFeedClear
      );
    };
  }

  static requestGmbQuestionFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_FILTER,
        GmbQuestionsFeedEffect.requestGmbQuestionFeedFilter,
        params
      );
    };
  }

  static requestPutGMBQuestions(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbQuestionFeedsAction.REQUEST_PUT_GMB_QUESTION,
        GmbQuestionsFeedEffect.requestPutGMBQuestions,
        data,
        id
      );
    };
  }
}
