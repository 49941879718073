import BaseReducer from "../../../../utilities/BaseReducer";
import ClientAction from "./ClientAction";

export default class ClientFilterReduser extends BaseReducer {
  //initial state of ClientFilterReduser
  initialState = {
    clientFilterA3: [],
  };
  //ClientFilterReduser request action finish
  [ClientAction.REQUEST_CLIENT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      clientFilterA3: action.payload,
    };
  }

  [ClientAction.REQUEST_CLIENT_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      clientFilterA3: action.payload,
    };
  }
}
