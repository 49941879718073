import BaseReducer from "../../../../utilities/BaseReducer";
import WhiteListAction from "./WhiteListAction";

// WhitelistReducer
export default class WhiteListReducer extends BaseReducer {
  initialState = {
    whitelist: [],
  };
  [WhiteListAction.REQUEST_GET_WHITELIST_FINISHED](state, action) {
    return {
      ...state,
      whitelist: action.payload,
    };
  }
}
