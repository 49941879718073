import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  ListItem,
  Typography,
  SvgIcon,
  Collapse,
  FormControlLabel,
  Checkbox,
  FormControl,
  OutlinedInput,
  MenuItem,
  Chip,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../../../../components/loading-indicator/LoadingIndicator";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import NoDataFound from "../../../../../components/nodatafound/NoDataFound";
import { TreeView } from "@mui/x-tree-view/TreeView";
import EmailFeedsAction from "../../../../stores/emailFeeds/EmailFeedsAction";
import MuiTextField from "../../../../../components/muiTextField/MuiTextField";
import TextArea from "antd/lib/input/TextArea";
import CollectionsIcon from "@mui/icons-material/Collections";
import { theme } from "../../../../../views/App";
import environment from "environment";
import ReplyMessages from "../../../../components/replyMessages/ReplyMessages";
import EmailAccountAction from "../../../../stores/emailAccount/EmailAccountAction";
import * as yup from "yup";

// mapstate to props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    EmailAccountAction.REQUEST_EMAIL_ACCOUNT,
  ]),
  emailAccount: state.emailAccount.emailAccount.hits || [],
});

const SendMentionsEmail = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const fetchEmailAccounts = () => {
    const params = {
      page_limit: "none",
    };
    props.dispatch(EmailAccountAction.requestEmailAccount(params));
  };

  useEffect(() => {
    fetchEmailAccounts();
  }, []);

  const [openMessages, setOpenMessages] = useState(false);
  let initialMessage = {};

  if (openMessages && message) {
    initialMessage = {
      message: message.content,
    };
  } else {
    initialMessage = {
      from: "",
      message: "",
      subject: "",
      to: "",
      comment_image: "",
    };
  }

  const ReplyValidation = yup.object({
    message: yup
      .string("Please Enter a valid Reply")
      .min(1, "Please Enter a valid Reply")
      .required("Please Enter a valid Reply!"),
  });

  let email_user_list = [];
  if (props.emailAccount && props.emailAccount.hits) {
    props.emailAccount.hits.length !== 0 &&
      props.emailAccount.hits.map((value) => {
        email_user_list.push({
          account_id: value._id,
          user_name: value._source.username,
        });
      });
  }

  email_user_list = [...new Set(email_user_list)];

  const cancelFunction = (index, setFieldValue, values, resetForm) => {
    resetForm();
    props.setSendEmailOpen(false);
  };

  const handlFileUpload = (value, setFieldValue, event) => {
    setFieldValue("boolean", true);

    // event.preventDefault();
    let formData = new FormData();
    formData.append("file", value);

    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    fetch(`${environment.api.baseApi}/file_upload/add`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_tocken,
        // Accept: "application/json",
        // "Content-Type": "application/json",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        setFieldValue("comment_image", response.url);
        setFieldValue("boolean", false);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <DialogContent
        className=" p-0 m-0"
        sx={{
          border: "2px solid #c0bfbf",
        }}
      >
        <DialogContentText>
          <Box
            width="100%"
            sx={{
              height: "fit-content",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #c0bfbf",
              }}
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                // className="bg-warning"
              >
                <Grid item px={1} sx={{ display: "flex" }}>
                  <Typography>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>S</span>
                    <span>end</span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>E</span>
                    <span>mail</span>
                  </Typography>
                </Grid>
                <Grid item px={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{
                        fontSize: "20px",
                      }}
                    ></FontAwesomeIcon>
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            <Box px={2}>
              <Box width={"100%"}>
                <Box width={"100%"}>
                  <Box width={"100%"}>
                    <Formik
                      initialValues={initialMessage}
                      enableReinitialize
                      validationSchema={ReplyValidation}
                      onSubmit={(values, formikHelpers) => {
                        setLoading(true);
                        let post_obj = {
                          body: values.message,
                          subject: values.subject,
                          to: values.to,
                        };

                        function callbackFn(item) {
                          alert(item);
                          props.setSendEmailOpen(false);
                        }

                        let Id =
                          email_user_list &&
                          email_user_list
                            .filter((item) => item.user_name === values.from)
                            .map((item) => {
                              return item.account_id;
                            });

                        props
                          .dispatch(
                            EmailFeedsAction.requestPostSendEmail(
                              Id && Id[0],
                              JSON.stringify(post_obj),
                              callbackFn
                            )
                          )
                          .then(() => {
                            setLoading(false);
                          });
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        submitForm,
                        resetForm,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <DialogContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                            className="p-3 m-0"
                          >
                            <Box width="100%">
                              <Box width={"100%"}>
                                <Grid
                                  item
                                  container
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  justifyContent="space-between"
                                  alignItems={"center"}
                                  // className="bg-warning"
                                >
                                  <Grid
                                    item
                                    xl={12}
                                    container
                                    lg={12}
                                    md={5.8}
                                    sm={5.8}
                                    xs={5.8}
                                    justifyContent="flex-start"
                                    alignItems={"flex-start"}
                                  >
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      justifyContent="center"
                                      alignItems="center"
                                      mt={-1}
                                      // className="bg-warning"
                                    >
                                      <Field
                                        name="from"
                                        as={FormControl}
                                        fullWidth
                                        size="small"
                                      >
                                        <Select
                                          value={values.from}
                                          MenuProps={{
                                            PaperProps: {
                                              style: {
                                                border: `1px solid #707070`,
                                                borderRadius: 2,
                                                marginTop: 2,
                                              },
                                            },
                                          }}
                                          displayEmpty
                                          placeholder="From"
                                          renderValue={(selected) => {
                                            if (!values.from) {
                                              return (
                                                <span
                                                  style={{
                                                    color: "#b8b6b6",
                                                  }}
                                                >
                                                  From
                                                </span>
                                              );
                                            }
                                            return selected;
                                          }}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "from",
                                              e.target.value
                                            )
                                          }
                                          input={
                                            <OutlinedInput
                                              sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": {
                                                  top: 0,
                                                },
                                                outline: "none",
                                                borderRadius: "5px",
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    borderWidth: "1px",
                                                  },
                                              }}
                                            />
                                          }
                                        >
                                          {email_user_list &&
                                          email_user_list.length > 0 ? (
                                            email_user_list.map((item, ind) => (
                                              <MenuItem
                                                value={item.user_name}
                                                key={ind}
                                              >
                                                <Checkbox
                                                  size="small"
                                                  checked={
                                                    values &&
                                                    values.from.indexOf(
                                                      item.user_name
                                                    ) > -1
                                                  }
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setFieldValue("from", [
                                                        item.user_name,
                                                      ]);
                                                    } else {
                                                      setFieldValue("from", []);
                                                    }
                                                  }}
                                                />
                                                {item.user_name}
                                              </MenuItem>
                                            ))
                                          ) : (
                                            <MenuItem disabled>
                                              <span>No options</span>
                                            </MenuItem>
                                          )}
                                        </Select>

                                        <ErrorMessage name="from" />
                                      </Field>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={12}
                                    container
                                    lg={12}
                                    md={5.8}
                                    sm={5.8}
                                    xs={5.8}
                                    justifyContent="center"
                                    alignItems={"center"}
                                    my={0.5}
                                  >
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      justifyContent="center"
                                      alignItems="center"
                                      // className="bg-warning"
                                    >
                                      <Field
                                        variant="outlined"
                                        as={MuiTextField}
                                        id="subject"
                                        size="small"
                                        name="subject"
                                        placeholder="Subject"
                                        error={
                                          Boolean(errors.subject) &&
                                          Boolean(touched.subject)
                                        }
                                        helperText={
                                          Boolean(touched.subject) &&
                                          errors.subject
                                        }
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={12}
                                    container
                                    lg={12}
                                    md={5.8}
                                    sm={5.8}
                                    xs={5.8}
                                    justifyContent="center"
                                    alignItems={"center"}
                                    my={0.5}
                                  >
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Field
                                        variant="outlined"
                                        as={MuiTextField}
                                        id="to"
                                        size="small"
                                        name="to"
                                        placeholder="To"
                                        error={
                                          Boolean(errors.to) &&
                                          Boolean(touched.to)
                                        }
                                        helperText={
                                          Boolean(touched.to) && errors.to
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box width="100%">
                                <Grid
                                  item
                                  xl={10}
                                  lg={10}
                                  md={8}
                                  sm={8}
                                  container
                                  justifyContent={"flex-start"}
                                  alignItems="center"
                                  // className="bg-warning"
                                >
                                  <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={8}
                                    container
                                    justifyContent={"flex-start"}
                                    alignItems="center"
                                  >
                                    {openMessages && (
                                      <ReplyMessages
                                        onSetMessage={(value) => {
                                          if (value != null) {
                                            setMessage(value);
                                          } else {
                                            setMessage(null);
                                          }
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>

                              <Grid
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                justifyContent="center"
                                alignItems="center"
                                // className='bg-warning'
                              >
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={10}
                                  sm={10}
                                  xs={10}
                                  // className='bg-info'
                                  textAlign="center"
                                >
                                  <Field
                                    name="message"
                                    as={TextArea}
                                    placeholder="Write reply..."
                                    className="textAreaFiled"
                                    style={{
                                      width: "99%",
                                      height: 100,
                                      borderRadius: "4px",
                                      padding: 1,
                                    }}
                                  />
                                  <Box textAlign="left" px={2}>
                                    <ErrorMessage
                                      style={{ textAlign: "left" }}
                                      name="message"
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>

                            <Box width={"100%"}>
                              <Grid
                                item
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                justifyContent="space-between"
                                alignItems={"center"}
                              >
                                <Grid item>
                                  {/* <ListItem className="m-0 p-0">
                                    <CollectionsIcon
                                      style={{
                                        width: "16px",
                                        color: `${theme.palette.ticketListView.iconColor}`,
                                      }}
                                    />

                                    <p
                                      className="m-0 p-0"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Attach Media
                                    </p>
                                  </ListItem>
                                  <input
                                    disabled={values.boolean}
                                    type="file"
                                    id="attachments"
                                    onChange={(e) => {
                                      handlFileUpload(
                                        e.target.files[0],
                                        setFieldValue,
                                        e
                                      );
                                    }}
                                  />

                                  {values.comment_image && (
                                    <Chip
                                      label={
                                        values.comment_image &&
                                        values.comment_image.slice(-20)
                                      }
                                      onDelete={() => {
                                        setFieldValue("comment_image", null);
                                        document.querySelector(
                                          "input[type='file']"
                                        ).value = "";
                                      }}
                                      variant="outlined"
                                      size="small"
                                    />
                                  )} */}
                                </Grid>
                              </Grid>
                            </Box>

                            <Box width={"100%"} mt={2}>
                              <Grid
                                item
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                justifyContent="flex-end"
                                alignItems={"center"}
                              >
                                <Grid
                                  item
                                  container
                                  xl={3}
                                  lg={4}
                                  md={5.5}
                                  sm={5.5}
                                  xs={5.5}
                                  justifyContent="space-between"
                                  textAlign="center"
                                >
                                  <Grid
                                    item
                                    xl={5}
                                    lg={5}
                                    md={5}
                                    sm={5}
                                    xs={5}
                                    textAlign="center"
                                    sx={{
                                      border: "solid 1px #9dc0c9;",
                                      cursor: "pointer",
                                      backgroundColor: "white",
                                      borderRadius: "4px",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#39717c",
                                      }}
                                      onClick={() => {
                                        props.values
                                          ? cancelFunction(
                                              props.index,
                                              props.setFieldValue,
                                              props.values,
                                              resetForm
                                            )
                                          : props.setSendEmailOpen(false);
                                      }}
                                    >
                                      Cancel
                                    </span>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={5}
                                    lg={5}
                                    md={5}
                                    sm={5}
                                    xs={5}
                                    // textAlign="center"
                                    // sx={{
                                    //   border: "solid 1px #9dc0c9;",
                                    //   cursor: "pointer",
                                    //   backgroundColor: "#39717c",
                                    //   borderRadius: "4px",
                                    // }}
                                  >
                                    <MuiButton
                                      variant="contained"
                                      minWidth="100%"
                                      height={"25px"}
                                      name={"Send"}
                                      loading={loading}
                                      noTextOnLoading={true}
                                      onClick={() => {
                                        submitForm();
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </DialogContent>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps)(SendMentionsEmail);
