import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import { format } from "date-fns";
import { connect } from "react-redux";
// Pagination component
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
// import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@mui/icons-material/Star";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import SearchIcon from "@mui/icons-material/Search";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import AppStoreAction from "../../../stores/linkedInFeed/appstore/AppStoreAction";
import { theme } from "../../../../views/App";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import PaginationUI from "../../../../components/pagination/Pagination";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const useStyles = makeStyles({
//   avatar: {
//     width: 45,
//     height: 45,
//   },
// });

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    AppStoreAction.REQUEST_APPSTORE,
    AppStoreAction.REQUEST_APPSTORE_FILTER,
  ]),
  appstore: state.appstore.appstore.hits || [],
  pagination: state.appstore.appstore || {},
  appstorefilter: state.appstorefilter.appstorefilter.hits || [],
});

function AppStoreListView(props) {
  const [value, setValue] = React.useState("1");

  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { isRequesting } = props;

  //****************************************** setState
  const [page_limit] = useState(10);
  const [appName, setAppName] = useState("");
  const [customerName, setCustomerName] = React.useState(null);

  const [replyState, setReplyState] = useState("");
  const [rating, setRating] = useState([]);

  const [star] = useState(["1", "2", "3", "4", "5"]);
  const [date, setDate] = useState([null, null]);
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // ************************************************* Dispatch functions
  function fetchAppstore(params) {
    props.dispatch(AppStoreAction.requestAppStore(params));
  }

  function fetchAppStoreFilter(param) {
    props.dispatch(AppStoreAction.requestAppStoreFilter(param));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  // //*************************************************  UseEffect function
  useEffect(() => {
    const param = { page_limit: "none" };

    fetchAppStoreFilter(param);

    const filter = [];
    let params = {};

    // search
    if (searchQuery !== "") {
      filter.push(`["review","must","","wildcard","*${searchQuery}*"]`);
    }

    if (appName !== "") {
      filter.push(`["app_name.keyword","must","","match","${appName}"]`);
    }
    if (rating.length !== 0) {
      filter.push(`["rating.keyword","must","","terms",[${rating}]]`);
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      appName !== "" ||
      searchQuery !== "" ||
      rating.length !== 0 ||
      customerName !== null ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    }

    fetchAppstore(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // ****************************************************** applyFilter Function

  const applyFilter = () => {
    let params = {};
    const filter = [];

    if (appName !== "") {
      filter.push(`["app_name.keyword","must","","match","${appName}"]`);
    }

    if (searchQuery !== "") {
      filter.push(`["review","must","","wildcard","*${searchQuery}*"]`);
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (rating.length !== 0) {
      filter.push(`["rating","must","","terms",[${rating}]]`);
    }
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      appName !== "" ||
      rating.length !== 0 ||
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== null
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        // page_number: 0,
        page_limit: `${page_limit}`,
      };
      alert("Please add filters");
    }
    fetchAppstore(params);
  };

  // // ************************************************************ HandleChange
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (searchQuery !== "") {
      filter.push(`["review.keyword","must","","wildcard","*${searchQuery}*"]`);
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (appName !== "") {
      filter.push(`["app_name.keyword","must","","match","${appName}"]`);
    }
    if (rating.length !== 0) {
      filter.push(`["rating","must","","terms",[${rating}]]`);
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (FilterClicked) {
      if (
        appName !== "" ||
        searchQuery !== "" ||
        customerName !== null ||
        rating.length !== 0 ||
        (date[0] !== null && date[1] !== null) ||
        sentimental_val !== null
      ) {
        params = {
          filters: `[${filter}]`,
          order_by: `[["created_on","desc"]]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchAppstore(params);
    }
  };

  // // ************************************** appNameHandleChange
  const appNameHandleChange = (event, value) => {
    if (value != null) {
      setAppName(value);
    } else {
      setAppName("");
    }
  };

  // ***************************************  Rating HandleChange
  const ratingHandleChange = (event, value) => {
    if (value != null) {
      setRating(value);
    } else {
      setRating("");
    }
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val(null);
    }
  };

  // *************************************AppName Filter List
  let filterlist = [];

  if (props.appstorefilter.hits) {
    props.appstorefilter.hits.forEach((value) => {
      filterlist.push(value._source.app_name);
    });
  }

  const uniqueList = [...new Set(filterlist)];

  //*******************************  clearState Functions ******************
  const ClearState = () => {
    setFilterClicked(false);
    if (
      appName !== "" ||
      rating.length !== 0 ||
      searchQuery !== "" ||
      replyState !== "" ||
      customerName !== null ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== null
    ) {
      setAppName("");
      setRating([]);
      setSearchQuery("");
      setReplyState("");
      setSentimental_val(null);
      setDate([null, null]);
      setClearLoader(true);
      setCustomerName(null);

      const params = {
        order_by: `[["created_on","desc"]]`,
        // page_number: 0,
        page_limit: `${page_limit}`,
      };
      FilterClicked && fetchAppstore(params);
    } else {
    }
  };

  function returnStars(stars) {
    let i = stars;
    let displayStars = [];
    if (stars === i) {
      for (let k = 1; k <= i; k++) {
        displayStars.push(<StarIcon />);
      }
    }
    return displayStars;
  }

  let maxDate = new Date();

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number"
    ) {
      if (item._source.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item._source.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item._source.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }

    return sentiment;
  };

  //Customer names list assigning
  let customerList = [];

  if (props.appstorefilter.hits) {
    props.appstorefilter.hits.forEach((val) => {
      customerList.push(val._source.customer_name);
    });
  }
  const uniqueCustomerList = [...new Set(customerList)];

  const CustomerHandleChange = (e, val) => {
    setCustomerName(val);
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}
      <Grid container>
        {/*Playstore filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
            // className="bg-warning"
          >
            <Grid
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              alignItems={"flex-start"}
              spacing={1}
              // className="bg-success"
            >
              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>App Name</p>
                </Grid>
                <AutoCompleteComponent
                  value={appName}
                  handleChange={appNameHandleChange}
                  list={uniqueList}
                  textFieldProps={{
                    placeholder: "App Name",
                  }}
                  getOptionLabel={(option) => (option ? option : "")}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Customer Name</p>
                </Grid>
                <AutoCompleteComponent
                  value={customerName}
                  list={uniqueCustomerList}
                  textFieldProps={{
                    placeholder: "Customer Name",
                  }}
                  handleChange={CustomerHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={2}>
                  <span>Search Content</span>
                </Grid>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      borderRadius:
                        theme.palette.buttonComponent.borderRadius.medium,
                      outline: `1px ${theme.palette.borderColor.textField}`,
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.typography.padding,
                      height: "35px",
                      width: "100%",
                    }}
                    id="search_bar"
                    autoComplete={"off"}
                    value={searchQuery}
                    placeholder={"Search by Content"}
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment>
                        <IconButton onClick={applyFilter}>
                          <SearchIcon
                            sx={{
                              "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Ratings</p>
                </Grid>

                <Grid style={{ position: "relative" }}>
                  <Grid
                    item
                    xl={12}
                    style={{
                      position: "absolute",
                      width: "95%",
                      zIndex: 999,
                    }}
                  >
                    <AutoCompleteComponent
                      value={rating}
                      handleChange={ratingHandleChange}
                      list={star}
                      textFieldProps={{
                        placeholder: rating.length > 0 ? null : "Ratings",
                      }}
                      size="small"
                      width="100%"
                      multiple={true}
                      limitTags={1}
                      disableCloseOnSelect={true}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xxl={1.5} lg={2} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>

              <Grid item xl={1.5} lg={2} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentimental Value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid
                item
                xl={2}
                lg={2}
                md={12}
                sm={12}
                mt={0}
                // className="bg-warning"
              >
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={3}
                >
                  <Grid
                    item
                    // xl={3.5} lg={6} md={3} sm={5}
                  >
                    <MuiButton
                      width="100%"
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid
                    item
                    // xl={3.5} lg={6} md={3} sm={5}
                  >
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <h5 className="pl-3 pr-3 pt-2"></h5> */}

        {/* <Divider sx={{ borderColor: theme.palette.background.button }} /> */}
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "8px", marginBottom: "8px" }} />
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            {/* <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Reviews</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" /> */}
          </Grid>
          <LoadingIndicator isActive={isRequesting}>
            {props.appstore.hits ? (
              <Box>
                {props.appstore.hits.length !== 0 ? (
                  props.appstore.hits.map((val, key) => (
                    <Box
                      sx={{
                        padding: 0,
                        margin: "1.2%",
                      }}
                    >
                      <List
                        key={key}
                        sx={{
                          backgroundColor: theme.palette.background.paper,

                          border: "solid 0.5px #d3e2e6",
                          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",

                          // borderRadius:
                          //   theme.palette.buttonComponent.borderRadius.large,
                          borderRadius: theme.borderRadius,
                          display: "flex",
                          flexDirection: "row",
                        }}
                        component="nav"
                        variant="fullWidth"
                      >
                        <List>
                          <ListItem className="p-0 m-0 ml-3">
                            <h6 className="p-0 m-0 mr-3">
                              {val._source.customer_name}{" "}
                            </h6>
                            <p className="p-0 m-0" style={{ fontSize: "12px" }}>
                              {" "}
                              {(function createdDate() {
                                let created = val._source.created_on;
                                let dateConversion = new Date(created);
                                dateConversion =
                                  dateConversion.toDateString() +
                                  ", " +
                                  dateConversion.toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  });

                                return dateConversion;
                              })()}{" "}
                            </p>
                          </ListItem>

                          <ListItem className="p-0 m-0 ml-3">
                            <ListItemText>
                              <h5
                                className="p-0 m-0"
                                style={{ fontWeight: "400" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "550",
                                    fontSize: 16,
                                  }}
                                >
                                  Ratings:
                                </span>{" "}
                                &nbsp;
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: 12,
                                    alignSelf: "center",
                                  }}
                                >
                                  {/* {val._source.rating}  */}
                                  {returnStars(val._source.rating)}
                                </span>
                              </h5>
                            </ListItemText>
                          </ListItem>

                          <ListItem className="p-0 m-0 ml-3">
                            <ListItemText>
                              <h5
                                className="p-0 m-0"
                                style={{ fontWeight: "400" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "550",
                                    fontSize: 16,
                                  }}
                                >
                                  Review:
                                </span>{" "}
                                &nbsp;
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: 15.5,
                                  }}
                                >
                                  {val._source.review}
                                </span>
                              </h5>
                            </ListItemText>
                          </ListItem>

                          <ListItem className="p-0 m-0 ml-3">
                            <ListItemText>
                              <h5
                                className="p-0 m-0"
                                style={{ fontWeight: "400" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "550",
                                    fontSize: 16,
                                  }}
                                >
                                  App name:
                                </span>{" "}
                                &nbsp;
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: 15.5,
                                  }}
                                >
                                  {val._source.app_name}
                                </span>
                              </h5>
                            </ListItemText>
                          </ListItem>

                          {val._source.sentiment_value !== null ? (
                            <ListItem className="p-0 m-0 ml-3">
                              <ListItemText>
                                <b
                                  style={{
                                    fontSize:
                                      theme.palette.buttonComponent.fontSize
                                        .extraLarge,
                                    // lineHeight: "22px",
                                  }}
                                >
                                  Sentiment value :
                                </b>
                                &nbsp;
                                {val._source.sentiment_value &&
                                typeof val._source.sentiment_value ===
                                  "number" &&
                                val._source.sentiment_value > 0 ? (
                                  <IconButton
                                    className="p-0 m-0"
                                    title={returnSentimentname(val)}
                                  >
                                    <SentimentSatisfiedAltRoundedIcon
                                      titleAccess="Positive"
                                      style={{
                                        width: "16px",
                                        color: `green`,
                                      }}
                                    />
                                  </IconButton>
                                ) : val._source.sentiment_value < 0 ? (
                                  <IconButton
                                    className="p-0 m-0"
                                    title={returnSentimentname(val)}
                                  >
                                    <SentimentDissatisfiedRoundedIcon
                                      titleAccess="Negative"
                                      style={{
                                        width: "16px",
                                        color: `${theme.palette.sentimentIconColor.negative}`,
                                      }}
                                    />
                                  </IconButton>
                                ) : val._source.sentiment_value !== null ? (
                                  <IconButton
                                    className="p-0 m-0"
                                    title={returnSentimentname(val)}
                                  >
                                    <SentimentNeutralRoundedIcon
                                      titleAccess="Neutral"
                                      style={{
                                        width: "16px",
                                        color: `${theme.palette.sentimentIconColor.neutral}`,
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </ListItemText>
                            </ListItem>
                          ) : null}
                        </List>

                        {val._source.replied === true ? (
                          <List className="p-0 m-0 ml-3">
                            <ListItem>
                              <Button
                                variant="outlined"
                                // style={{ cursor: "none" }}
                              >
                                Replied
                              </Button>
                            </ListItem>
                          </List>
                        ) : null}
                      </List>
                    </Box>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </Box>
            ) : null}
          </LoadingIndicator>
        </Box>
      </Grid>
      {props.appstore.hits
        ? props.appstore.hits.length !== 0 && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(AppStoreListView);
