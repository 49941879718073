import ActionUtility from "../../../../utilities/ActionUtility";
import TerritoryEffect from "./TerritoryEffect";

export default class TerritoryAction {
  // TerritoryAction action with an function declaration
  static REQUEST_TERRITORY = "TerritoryAction.REQUEST_TERRITORY";
  static REQUEST_TERRITORY_FINISHED =
    "TerritoryAction.REQUEST_TERRITORY_FINISHED";

  static REQUEST_TERRITORY_FILTER = "TerritoryAction.REQUEST_TERRITORY_FILTER";
  static REQUEST_TERRITORY_FILTER_FINISHED =
    "TerritoryAction.REQUEST_TERRITORY_FILTER_FINISHED";

  static REQUEST_PUT_TERRITORY = "TerritoryAction.REQUEST_PUT_TERRITORY";
  static REQUEST_PUT_TERRITORY_FINISHED =
    "TerritoryAction.REQUEST_PUT_TERRITORY_FINISHED";

  static REQUEST_POST_TERRITORY = "TerritoryAction.REQUEST_POST_TERRITORY";
  static REQUEST_POST_TERRITORY_FINISHED =
    "TerritoryAction.REQUEST_POST_TERRITORY_FINISHED";

  static REQUEST_TERRITORY_CLEAR = "TerritoryAction.REQUEST_TERRITORY_CLEAR";
  static REQUEST_TERRITORY_CLEAR_FINISHED =
    "TerritoryAction.REQUEST_TERRITORY_CLEAR_FINISHED";

  static REQUEST_TERRITORY_FILTER_CLEAR =
    "TerritoryAction.REQUEST_TERRITORY_FILTER_CLEAR";
  static REQUEST_TERRITORY_FILTER_CLEAR_FINISHED =
    "TerritoryAction.REQUEST_TERRITORY_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_TERRITORY = "TerritoryAction.REQUEST_DELETE_TERRITORY";
  static REQUEST_DELETE_TERRITORY_FINISHED =
    "TerritoryAction.REQUEST_DELETE_TERRITORY_FINISHED";

  // METHODS
  // Territory GET function
  static requesTerritory(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoryAction.REQUEST_TERRITORY,
        TerritoryEffect.requesTerritory,
        params
      );
    };
  }

  static requesTerritoryClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoryAction.REQUEST_TERRITORY_CLEAR,
        TerritoryEffect.requesTerritoryClear
      );
    };
  }

  static requesTerritoryFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoryAction.REQUEST_TERRITORY_FILTER_CLEAR,
        TerritoryEffect.requesTerritoryFilterClear
      );
    };
  }

  static requestTerritoryFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoryAction.REQUEST_TERRITORY_FILTER,
        TerritoryEffect.requestTerritoryFilter,
        params
      );
    };
  }
  static requestPutTerritory(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoryAction.REQUEST_PUT_TERRITORY,
        TerritoryEffect.requestPutTerritory,
        data,
        id
      );
    };
  }

  static requestPostTerritory(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoryAction.REQUEST_POST_TERRITORY,
        TerritoryEffect.requestPostTerritory,
        data
      );
    };
  }
  static requestDeleteTerritory(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoryAction.REQUEST_DELETE_TERRITORY,
        TerritoryEffect.requestDeleteTerritory,
        id
      );
    };
  }
}
