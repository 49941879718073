import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";
export default class ResponseMessageSourceModel extends BaseModel {
  /*
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  content = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = {};
  modified_on = "";
  project_id = "";
  response_category = {
    response_category_id: "",
    response_category_name: "",
  };
  title = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
