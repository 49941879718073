import { BaseModel } from "sjs-base-model";
import PlayStoreHitsDataModel from "./PlayStoreHitsDataModel";

// get model for project
export default class PlayStoreHitsModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  hits = [{ PlayStoreHitsDataModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
