import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TopAlbumModel from "./model/TopAlbumModel";

// TopAlbumEffect
export default class TopAlbumEffect {
  // calling an API according to the plan model
  static async requestTopAlbum(params) {
    const endpoint = environment.api.top_albums_report;
    return EffectUtility.getToModel(TopAlbumModel, endpoint, params);
  }

  // Get method for the plan filter
  static async requestTopAlbumFilter(params) {
    const endpoint = environment.api.top_albums_report;
    return EffectUtility.getToModel(TopAlbumModel, endpoint, params);
  }
}
