import BaseReducer from "../../../utilities/BaseReducer";
import AutoresponseAction from "./AutoresponseAction";

export default class AutomationFieldsReducer extends BaseReducer {
  initialState = {
    automationFields: [],
  };

  [AutoresponseAction.REQUEST_AUTOMATION_FIELDS_FINISHED](state, action) {
    return {
      ...state,
      automationFields: action.payload,
    };
  }
}
