import ActionUtility from "../../../utilities/ActionUtility";
import ClientEffect from "./ClientEffect";

export default class ClientAction {
  // CLIENT action with an function declaration
  static REQUEST_CLIENT = "ClientAction.REQUEST_CLIENT";
  static REQUEST_CLIENT_FINISHED = "ClientAction.REQUEST_CLIENT_FINISHED";

  // CLIENT action with an function declaration
  static REQUEST_CLIENT_FILTER = "ClientAction.REQUEST_CLIENT_FILTER";
  static REQUEST_CLIENT_FILTER_FINISHED =
    "ClientAction.REQUEST_CLIENT_FILTER_FINISHED";

  //   CLIENT post
  static REQUEST_POST_CLIENT = "ClientAction.REQUEST_POST_CLIENT";
  static REQUEST_POST_CLIENT_FINISHED =
    "ClientAction.REQUEST_POST_CLIENT_FINISHED";

  // CLIENT put
  static REQUEST_PUT_CLIENT = "ProjectAction.REQUEST_PUT_CLIENT";
  static REQUEST_PUT_CLIENT_FINISHED =
    "ClientAction.REQUEST_PUT_CLIENT_FINISHED";

  // METHODS
  // CLIENT GET function
  static requestClient(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_CLIENT,
        ClientEffect.requestClient,
        params
      );
    };
  }

  // CLIENT filter
  static requestClientFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_CLIENT_FILTER,
        ClientEffect.requestClientFilter,
        params
      );
    };
  }

  //   static requestProjectFinished() {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         ProjectAction.REQUEST_CLIENT_FINISHED,
  //         ProjectEffect.requestProjectFinished
  //       );
  //     };
  //   }

  // CLIENT post function
  static requestPostClient(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_POST_CLIENT,
        ClientEffect.requestPostClient,
        data,
        callBackFunction
      );
    };
  }

  // CLIENT put function
  static requestPutClient(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_PUT_CLIENT,
        ClientEffect.requestPutClient,
        data,
        id
      );
    };
  }
}
