import BaseReducer from "../../utilities/BaseReducer";
import ChatBotAction from "./ChatBotAction";

export default class ChatBotReducer extends BaseReducer {
  initialState = {
    chat: [],
  };
  [ChatBotAction.REQUEST_GET_CHAT_FINISHED](state, action) {
    return {
      ...state,
      chat: action.payload,
    };
  }
}
