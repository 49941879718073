import ActionUtility from "../../../utilities/ActionUtility";
import PinterestFeedsEffect from "./PinterestFeedsEffect";

export default class PinterestFeedsAction {
  //Pinterest feeds requests
  static REQUEST_PINTEREST_FEEDS =
    "PinterestFeedsAction.REQUEST_PINTEREST_FEEDS";
  static REQUEST_PINTEREST_FEEDS_FINISHED =
    "PinterestFeedsAction.REQUEST_PINTEREST_FEEDS_FINISHED";

  // Pinterest Filter
  static REQUEST_PINTEREST_FILTER =
    "PinterestFeedsAction.REQUEST_PINTEREST_FILTER";
  static REQUEST_PINTEREST_FILTER_FINISHED =
    "PinterestFeedsAction.REQUEST_PINTEREST_FILTER_FINISHED";

  static REQUEST_PUT_PINTEREST_FEEDS =
    "PinterestFeedsAction.REQUEST_PUT_PINTEREST_FEEDS";
  static REQUEST_PUT_PINTEREST_FEEDS_FINISHED =
    "PinterestFeedsAction.REQUEST_PUT_PINTEREST_FEEDS_FINISHED";

  static REQUEST_PINTEREST_FEEDS_CLEAR =
    "PinterestFeedsAction.REQUEST_PINTEREST_FEEDS_CLEAR";
  static REQUEST_PINTEREST_FEEDS_CLEAR_FINISHED =
    "PinterestFeedsAction.REQUEST_PINTEREST_FEEDS_CLEAR_FINISHED";

  static REQUEST_PINTEREST_POST = "PinterestFeedsAction.REQUEST_PINTEREST_POST";
  static REQUEST_PINTEREST_POST_FINISHED =
    "PinterestFeedsAction.REQUEST_PINTEREST_POST_FINISHED";

  //METHODS

  //Pinterest get method
  static requestPinterestFeeds(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestFeedsAction.REQUEST_PINTEREST_FEEDS,
        PinterestFeedsEffect.requestPinterestFeeds,
        params,
        callBackFunction
      );
    };
  }

  static requestPinterestFeedsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestFeedsAction.REQUEST_PINTEREST_FEEDS_CLEAR,
        PinterestFeedsEffect.requestPinterestFeedsClear
      );
    };
  }

  static requestPutPinterestFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestFeedsAction.REQUEST_PUT_PINTEREST_FEEDS,
        PinterestFeedsEffect.requestPutPinterestFeeds,
        data,
        id
      );
    };
  }

  static requestPinterestFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestFeedsAction.REQUEST_PINTEREST_FILTER,
        PinterestFeedsEffect.requestPinterestFilter,
        params
      );
    };
  }

  static requestPinterestPost(queryParams, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestFeedsAction.REQUEST_PINTEREST_POST,
        PinterestFeedsEffect.requestPinterestPost,
        queryParams,
        data
      );
    };
  }
}
