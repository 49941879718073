import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import DsrActivityPostModel from "./models/DsrActivityPostModel";
import DSRActvityGetModel from "./models/DSRActivityGetModel";

export default class DsrActivityEffect {
  static async requestGetDsrActivity(params, callback) {
    const endpoint = environment.api.dsrActivity;
    let response = EffectUtility.getToModel(
      DSRActvityGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetDsrKanbanActivity(params, callback) {
    const endpoint = environment.api.dsrActivity;
    let response = EffectUtility.getToModel(
      DSRActvityGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetTodayDsrActivity(params, callback) {
    const endpoint = environment.api.dsrActivity;
    let response = EffectUtility.getToModel(
      DSRActvityGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostDsrActivity(data, params, callback) {
    const endpoint = environment.api.dsrActivity + "/add";
    let response = EffectUtility.postToModel(
      DsrActivityPostModel,
      endpoint,
      data,
      params
    );
    return response;
  }

  static async requestPutDsrActivity(data, id, callback) {
    const endpoint = environment.api.dsrActivity + "/" + id;
    let response = EffectUtility.putToModel(
      DsrActivityPostModel,
      endpoint,
      data
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  static async requestGetRecentDsrActivity(params, callback) {
    const endpoint = environment.api.dsrActivity;
    let response = EffectUtility.getToModel(
      DSRActvityGetModel,
      endpoint,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  static async requestPushNotification(data) {
    const endpoint = environment.api.dsrNotification;
    return EffectUtility.postToModel(DsrActivityPostModel, endpoint, data);
  }
}
