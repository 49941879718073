import StatusEffect from "../../../../ormTicketing/stores/status/StatusEffect";
import BaseReducer from "../../../../utilities/BaseReducer";
import SurveyUserMapAction from "./SurveyUserMapAction";

export default class SurveyUserMapReducer extends BaseReducer {
  initialState = {
    surveyUserMap: [],
  };

  [SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_FINISHED](stat, action) {
    return {
      ...StatusEffect,
      surveyUserMap: action.payload,
    };
  }
}
