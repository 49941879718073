import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import GoogleAccountDeleteModel from "./model/GoogleAccountDeleteModel";
import GoogleAccountModel from "./model/GoogleAccountModel";
import GoogleAccountPutModel from "./model/GoogleAccountPutModel";

// GoogleAccountEffect
export default class GoogleAccountEffect {
  // calling an API according to the GoogleAccount model
  // Get method for google account
  static async requestGoogleAccount(params) {
    const endpoint = environment.api.googleAccount;
    return EffectUtility.getToModel(GoogleAccountModel, endpoint, params);
  }

  // Get method for google account filter
  static async requestGoogleAccountFilter(params) {
    const endpoint = environment.api.googleAccountFilter;
    return EffectUtility.getToModel(GoogleAccountModel, endpoint, params);
  }

  // put method for google account filter
  static async requestPutGoogleAccount(data, id) {
    const endpoint = environment.api.googleAccount + "/" + id;
    return EffectUtility.putToModel(GoogleAccountPutModel, endpoint, data);
  }

  // delete method for google account filter
  static async requestDeleteGoogleAccount(id) {
    const endpoint = environment.api.googleAccount + "/" + id;
    return EffectUtility.deleteToModel(GoogleAccountDeleteModel, endpoint);
  }
}
