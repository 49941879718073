import BaseReducer from "../../../../utilities/BaseReducer";
import LeadSourceAction from "./LeadSourceAction";

export default class LeadSourceFilterReducer extends BaseReducer {
  //initial state of LeadSourceFilterReduser
  initialState = {
    LeadSource_filter: [],
  };
  //LeadSourceFilterReduser request action finish
  [LeadSourceAction.REQUEST_LEADSOURCE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      LeadSource_filter: action.payload,
    };
  }

  [LeadSourceAction.REQUEST_LEADSOURCE_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      LeadSource_filter: action.payload,
    };
  }
}
