import ActionUtility from "../../../../utilities/ActionUtility";
import KeywordDiscoverPatternEffect from "./KeywordDiscoverPatternEffect";

export default class KeywordDiscoverPatternAction {
  static REQUEST_KEYWORD_CONFIG =
    "KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG";
  static REQUEST_KEYWORD_CONFIG_FINISHED =
    "KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG_FINISHED";

  static REQUEST_KEYWORD_CONFIG_FILTER =
    "KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG_FILTER";
  static REQUEST_KEYWORD_CONFIG_FILTER_FINISHED =
    "KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG_FILTER_FINISHED";

  static REQUEST_POST_KEYWORD_CONFIG =
    "KeywordDiscoverPatternAction.REQUEST_POST_KEYWORD_CONFIG";
  static REQUEST_POST_KEYWORD_CONFIG_FINISHED =
    "KeywordDiscoverPatternAction.REQUEST_POST_KEYWORD_CONFIG_FINISHED";

  static REQUEST_PUT_KEYWORD_CONFIG =
    "KeywordDiscoverPatternAction.REQUEST_PUT_KEYWORD_CONFIG";
  static REQUEST_PUT_KEYWORD_CONFIG_FINISHED =
    "KeywordDiscoverPatternAction.REQUEST_PUT_KEYWORD_CONFIG_FINISHED";

  static REQUEST_DELETE_KEYWORD_CONFIG =
    "KeywordDiscoverPatternAction.REQUEST_DELETE_KEYWORD_CONFIG";
  static REQUEST_DELETE_KEYWORD_CONFIG_FINISHED =
    "KeywordDiscoverPatternAction.REQUEST_DELETE_KEYWORD_CONFIG_FINISHED";

  //METHODS

  static requestKeywordConfiguration(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG,
        KeywordDiscoverPatternEffect.requestKeywordConfiguration,
        params
      );
    };
  }

  static requestKeywordConfigurationFilter(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG_FILTER,
        KeywordDiscoverPatternEffect.requestKeywordConfigurationFilter,
        params,
        callback
      );
    };
  }

  static requestPostKeywordConfig(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordDiscoverPatternAction.REQUEST_POST_KEYWORD_CONFIG,
        KeywordDiscoverPatternEffect.requestPostKeywordConfig,
        data
      );
    };
  }

  static requestPutKeywordConfig(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordDiscoverPatternAction.REQUEST_PUT_KEYWORD_CONFIG,
        KeywordDiscoverPatternEffect.requestPutKeywordConfig,
        data,
        id
      );
    };
  }

  static requestDeleteKeywordConfig(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordDiscoverPatternAction.REQUEST_DELETE_KEYWORD_CONFIG,
        KeywordDiscoverPatternEffect.requestDeleteKeywordConfig,
        id
      );
    };
  }
}
