import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";
import ResolvedTicketCountModel from "./ResolvedTicketCountModel";

export default class ResolvedTicketModel extends BaseModel {
  //Resolved Ticket model

  agent = boolean;
  data = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
