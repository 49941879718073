import BaseReducer from "../../../utilities/BaseReducer";
import UserLastWorkingProjectAction from "./UserLastWorkingProjectAction";

export default class LastProjectByIdReducer extends BaseReducer {
  //initial state of ticket
  initialState = {
    userLastWorkingProjectById: {},
  };

  // ticket filter
  [UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      userLastWorkingProjectById: action.payload,
    };
  }
}
