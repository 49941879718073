import BaseReducer from "../../../utilities/BaseReducer";
import EmailAccountAction from "./EmailAccountAction";

export default class EmailAccountFilterReduser extends BaseReducer {
  //initial state of InstagramAccount
  initialState = {
    emailAccountFilter: [],
  };
  //InstagramAccount request action finish
  [EmailAccountAction.REQUEST_EMAIL_ACCOUNT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      emailAccountFilter: action.payload,
    };
  }
}
