import BaseReducer from "../../../../utilities/BaseReducer";
import RevenueTransactionAction from "./RevenueTransactionAction";

export default class RevenueTransactionReducer extends BaseReducer {
  initialState = {
    revenueTransaction: [],
  };
  [RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      revenueTransaction: action.payload,
    };
  }

  [RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      revenueTransaction: action.payload,
    };
  }
}
