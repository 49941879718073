import BaseReducer from "../../../../utilities/BaseReducer";
import ActivityInsightAction from "./ActivityInsightAction";

export default class ActvityInsightReducer extends BaseReducer {
  initialState = {
    activityInsight: [],
  };
  [ActivityInsightAction.REQUEST_ACTIVITY_INSIGHT_FINISHED](state, action) {
    return {
      ...state,
      activityInsight: action.payload,
    };
  }
}
