import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import EmailFeedsModel from "./model/EmailFeedsModel";
import EmailFeedsPostModel from "./model/EmailFeedsPostModel";
import EmailFeedsPutModel from "./model/EmailFeedsPutModel";

// EmailFeedsEffect fetch model
export default class EmailFeedsEffect {
  // calling an API according to the EmailFeeds model
  static async requestEmailFeeds(params) {
    const endpoint = environment.api.emailFeeds;
    return EffectUtility.getToModel(EmailFeedsModel, endpoint, params);
  }

  static async requestEmailFeedsClear() {
    return {};
  }

  static async requestEmailFeedsFilter(params) {
    const endpoint = environment.api.emailFeedsFilter;
    return EffectUtility.getToModel(EmailFeedsModel, endpoint, params);
  }

  static async requestEmailFeedsPost(id, data, callback) {
    const endpoint = environment.api.emailReply + "/" + id;
    let response = EffectUtility.postToModel(
      EmailFeedsPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
      }
    });

    return response;
  }

  static async requestEmailFeedsPut(data, id) {
    const endpoint = environment.api.emailFeeds + "/" + id;
    return EffectUtility.putToModel(EmailFeedsPutModel, endpoint, data);
  }

  static async requestPostSendEmail(id, data, callback) {
    const endpoint = environment.api.postSendEmail + "/" + id;

    let response = EffectUtility.postToModel(
      EmailFeedsPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp && callback) {
        callback(resp.message);
      }
    });

    return response;
  }
}
