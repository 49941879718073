import BaseReducer from "../../../utilities/BaseReducer";
import TicketsAction from "./TicketsAction";

export default class TicketsReportReduser extends BaseReducer {
  //initial state of ticket list
  initialState = {
    ticketsReport: [],
  };

  //ticket request action finish
  [TicketsAction.REQUEST_TICKETS_FOR_REPORTS_FINISHED](state, action) {
    return {
      ...state,
      ticketsReport: action.payload,
    };
  }
}
