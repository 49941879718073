import { BaseModel } from "sjs-base-model";

export default class FPTotalCopiesCountGetModel extends BaseModel {
  total_copies_count = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
