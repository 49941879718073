import { BaseModel } from "sjs-base-model";

import TagHitsModel from "./TagHitsModel";
import TagShardsModel from "./TagShardsModel";

export default class TagModel extends BaseModel {
  //Tag list data model
  _shards = { _shards: TagShardsModel };
  current_page_no = 0;
  hits = { hits: TagHitsModel };
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
