import ActionUtility from "../../../utilities/ActionUtility";
import ConsumerEffect from "./ConsumerEffect";

// consumer api fetch model
export default class ConsumerAction {
  // project action with an function declaration
  static REQUEST_CONSUMER = "ConsumerAction.REQUEST_CONSUMER";
  static REQUEST_CONSUMER_FINISHED = "ConsumerAction.REQUEST_CONSUMER_FINISHED";

  // consumer filter
  static REQUEST_CONSUMER_FILTER = "ConsumerAction.REQUEST_CONSUMER_FILTER";
  static REQUEST_CONSUMER_FILTER_FINISHED =
    "ConsumerAction.REQUEST_CONSUMER_FILTER_FINISHED";

  // consumer
  static REQUEST_PUT_CONSUMER = "ConsumerAction.REQUEST_PUT_CONSUMER";
  static REQUEST_PUT_CONSUMER_FINISHED =
    "ConsumerAction.REQUEST_PUT_CONSUMER_FINISHED";

  // METHODS
  // Project GET function
  static requestConsumer(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAction.REQUEST_CONSUMER,
        ConsumerEffect.requestConsumer,
        params,
        callBackFunction
      );
    };
  }

  static requestConsumerFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAction.REQUEST_CONSUMER_FILTER,
        ConsumerEffect.requestConsumerFilter,
        params
      );
    };
  }

  static requestPutConsumer(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAction.REQUEST_PUT_CONSUMER,
        ConsumerEffect.requestPutConsumer,
        data,
        id
      );
    };
  }
}
