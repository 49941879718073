import BaseReducer from "../../../utilities/BaseReducer";
import YoutubeAction from "./YoutubeAction";

export default class YoutubeFeedFilterReducer extends BaseReducer {
  initialState = {
    youtubeFeedFilter: [],
  };

  [YoutubeAction.REQUEST_YOUTUBE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      youtubeFeedFilter: action.payload,
    };
  }
}
