import BaseReducer from "../../../../utilities/BaseReducer";
import ScrapeLinksAction from "./ScrapeLinksAction";

export default class ScrapeLinksFilterReduser extends BaseReducer {
  //initial state of ScrapeLinksFilterReduser
  initialState = {
    scrapeLinksFilter: [],
  };
  //ScrapeLinksFilterReduser request action finish
  [ScrapeLinksAction.REQUEST_SCRAPELINKS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      scrapeLinksFilter: action.payload,
    };
  }
}
