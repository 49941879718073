import BaseReducer from "../../../../utilities/BaseReducer";
import AutomationFlowAction from "./AutomationFlowAction";

export default class AutomationFlowReduser extends BaseReducer {
  initialState = {
    aotomationFlow: [],
  };
  [AutomationFlowAction.REQUEST_GET_AUTOMATION_FLOW_FINISHED](state, action) {
    return {
      ...state,
      aotomationFlow: action.payload,
    };
  }
}
