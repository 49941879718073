import BaseReducer from "../../utilities/BaseReducer";
import OrmNotificationAction from "./ORMNotificationAction";

export default class OrmNotificationsByIdReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    ormNotificationsById: [],
  };

  //operation Filter
  [OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ormNotificationsById: action.payload,
    };
  }
}
