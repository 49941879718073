import BaseReducer from "../../../../utilities/BaseReducer";
import DsrActivityDashboardAction from "./DsrDashboardAction";

export default class DsrActivityDashboardReducer extends BaseReducer {
  initialState = {
    dsrActivityData: [],
    dsrActiviyGroupwise: [],
    dsrDashboardSummary: [],
  };
  [DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_DATA_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      dsrActivityData: action.payload,
    };
  }

  [DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_GROUPWISE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      dsrActiviyGroupwise: action.payload,
    };
  }

  [DsrActivityDashboardAction.REQUEST_DSR_DASHBOARD_SUMMARY_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      dsrDashboardSummary: action.payload,
    };
  }
}
