import environment from "environment";
import TopStoreModel from "./model/TopStoreModel";
import TopStorePutModel from "./model/TopStorePutModel";
import TopStorePostModel from "./model/TopStorePostModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import AudiostreamsModel from "./model/AudiostreamsModel";

// TopStoreEffect
export default class TopStoreEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestTopStore(params, callBack) {
    const endpoint = environment.api.topStore;
    let response = EffectUtility.getToModel(TopStoreModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // Get method for the track filter
  static async requestTopStoreFilter(params) {
    const endpoint = environment.api.track;
    return EffectUtility.getToModel(TopStoreModel, endpoint, params);
  }

  static async requestAudioStreams(params, callBack) {
    const endpoint = environment.api.audioStreams;
    let response = EffectUtility.getToModel(
      AudiostreamsModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestTopRetailer(params, callBack) {
    const endpoint = environment.api.topStore;
    let response = EffectUtility.getToModel(TopStoreModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // put method for the track
  static async requestPutTopStore(data, id) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.putToModel(TopStorePutModel, endpoint, data);
  }

  // post method for the track
  static async requestPostTopStore(data) {
    const endpoint = environment.api.track + "/add";
    return EffectUtility.postToModel(TopStorePostModel, endpoint, data);
  }

  static async requestDeleteTopStore(id) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.deleteToModel(TopStorePostModel, endpoint);
  }
}
