import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

// get model for project
export default class ProjectByIdModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _id = "";
  _index = "";
  _primary_term = 0;
  _seq_no = 0;
  _source = {};
  _version = 0;
  found = boolean;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
