import { BaseModel } from "sjs-base-model";

// category data hits Model
export default class CustomTicketFieldsModel extends BaseModel {
  // response getting from the api
  id = "";
  status = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
