import ActionUtility from "../../../utilities/ActionUtility";
import TrustpilotFeedsEffect from "./TrustpilotFeedsEffect";

export default class TrustpilotFeedsAction {
  //Trustpilot feeds requests
  static REQUEST_TRUSTPILOT_FEEDS =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS";
  static REQUEST_TRUSTPILOT_FEEDS_FINISHED =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS_FINISHED";

  // Trustpilot Filter
  static REQUEST_TRUSTPILOT_FILTER =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FILTER";
  static REQUEST_TRUSTPILOT_FILTER_FINISHED =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FILTER_FINISHED";

  static REQUEST_PUT_TRUSTPILOT_FEEDS =
    "TrustpilotFeedsAction.REQUEST_PUT_TRUSTPILOT_FEEDS";
  static REQUEST_PUT_TRUSTPILOT_FEEDS_FINISHED =
    "TrustpilotFeedsAction.REQUEST_PUT_TRUSTPILOT_FEEDS_FINISHED";

  static REQUEST_TRUSTPILOT_FEEDS_CLEAR =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS_CLEAR";
  static REQUEST_TRUSTPILOT_FEEDS_CLEAR_FINISHED =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS_CLEAR_FINISHED";

  static REQUEST_TRUSTPILOT_POST =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_POST";
  static REQUEST_TRUSTPILOT_POST_FINISHED =
    "TrustpilotFeedsAction.REQUEST_TRUSTPILOT_POST_FINISHED";

  //METHODS

  //Trustpilot get method
  static requestTrustpilotFeeds(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS,
        TrustpilotFeedsEffect.requestTrustpilotFeeds,
        params,
        callBackFunction
      );
    };
  }

  static requestTrustpilotFeedsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS_CLEAR,
        TrustpilotFeedsEffect.requestTrustpilotFeedsClear
      );
    };
  }

  static requestPutTrustpilotFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotFeedsAction.REQUEST_PUT_TRUSTPILOT_FEEDS,
        TrustpilotFeedsEffect.requestPutTrustpilotFeeds,
        data,
        id
      );
    };
  }

  static requestTrustpilotFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FILTER,
        TrustpilotFeedsEffect.requestTrustpilotFilter,
        params
      );
    };
  }

  static requestTrustpilotPost(queryParams, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrustpilotFeedsAction.REQUEST_TRUSTPILOT_POST,
        TrustpilotFeedsEffect.requestTrustpilotPost,
        queryParams,
        data
      );
    };
  }
}
