import BaseReducer from "../../../utilities/BaseReducer";
import CategoryAction from "./CategoryAction";

export default class FilterCategoryReducer extends BaseReducer {
  //initial state of category list
  initialState = {
    categoryfilter: [],
  };

  // ticket filter
  [CategoryAction.REQUEST_CATEGORY_FILTER_FINISHED](state, action) {
    return {
      ...state,
      categoryfilter: action.payload,
    };
  }
}
