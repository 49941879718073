import ActionUtility from "../../../utilities/ActionUtility";
import LinkedInAccountEffect from "./LinkedInAccountEffect";

export default class LinkedInAccountAction {
  // LinkedInAccountAction with an function declaration
  static REQUEST_LINKED_IN_ACCOUNT =
    "LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT";
  static REQUEST_LINKED_IN_ACCOUNT_FINISHED =
    "LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT_FINISHED";

  static REQUEST_LINKED_IN_ACCOUNT_FILTER =
    "LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT_FILTER";
  static REQUEST_LINKED_IN_ACCOUNT_FILTER_FINISHED =
    "LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_LINKED_IN_ACCOUNT =
    "LinkedInAccountAction.REQUEST_PUT_LINKED_IN_ACCOUNT";
  static REQUEST_PUT_LINKED_IN_ACCOUNT_FINISHED =
    "LinkedInAccountAction.REQUEST_PUT_LINKED_IN_ACCOUNT_FINISHED";

  static REQUEST_POST_LINKED_IN_ACCOUNT =
    "LinkedInAccountAction.REQUEST_POST_LINKED_IN_ACCOUNT";
  static REQUEST_POST_LINKED_IN_ACCOUNT_FINISHED =
    "LinkedInAccountAction.REQUEST_POST_LINKED_IN_ACCOUNT_FINISHED";

  static REQUEST_DELETE_LINKED_IN_ACCOUNT =
    "LinkedInAccountAction.REQUEST_DELETE_LINKED_IN_ACCOUNT";
  static REQUEST_DELETE_LINKED_IN_ACCOUNT_FINISHED =
    "LinkedInAccountAction.REQUEST_DELETE_LINKED_IN_ACCOUNT_FINISHED";

  // METHODS
  // Linked Get function
  static requestLinkedInAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT,
        LinkedInAccountEffect.requestLinkedInAccount,
        params
      );
    };
  }

  static requestLinkedInAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT_FILTER,
        LinkedInAccountEffect.requestLinkedInAccountFilter,
        params
      );
    };
  }
  static requestPutLinkedInAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInAccountAction.REQUEST_PUT_LINKED_IN_ACCOUNT,
        LinkedInAccountEffect.requestPutLinkedInAccount,
        data,
        id
      );
    };
  }

  // linked post function
  static requestPostLinkedInAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInAccountAction.REQUEST_POST_LINKED_IN_ACCOUNT,
        LinkedInAccountEffect.requestPostLinkedInAccount,
        data
      );
    };
  }

  static requestDeleteLinkedInAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInAccountAction.REQUEST_DELETE_LINKED_IN_ACCOUNT,
        LinkedInAccountEffect.requestDeleteLinkedInAccount,
        data
      );
    };
  }
}
