import { BaseModel } from "sjs-base-model";

export default class InvoiceClaimDetailsModel extends BaseModel {
  a3labels_details = {};
  client_details = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
