import ActionUtility from "../../../../utilities/ActionUtility";
import A3NotificationEffect from "./A3NotificationEffect";

export default class A3NotificationAction {
  static REQUEST_A3_NOTIFICATIONS =
    "A3NotificationAction.REQUEST_A3_NOTIFICATIONS";
  static REQUEST_A3_NOTIFICATIONS_FINISHED =
    "A3NotificationAction.REQUEST_A3_NOTIFICATIONS_FINISHED";

  static REQUEST_FILTER_NOTIFICATIONS =
    "A3NotificationAction.REQUEST_FILTER_NOTIFICATIONS";
  static REQUEST_FILTER_NOTIFICATIONS_FINISHED =
    "A3NotificationAction.REQUEST_FILTER_NOTIFICATIONS_FINISHED";

  static REQUEST_POST_A3_NOTIFICATIONS =
    "A3NotificationAction.REQUEST_POST_A3_NOTIFICATIONS";
  static REQUEST_POST_A3_NOTIFICATIONS_FINISHED =
    "A3NotificationAction.REQUEST_POST_A3_NOTIFICATIONS_FINISHED";

  static REQUEST_PUT_A3_NOTIFICATIONS =
    "A3NotificationAction.REQUEST_PUT_A3_NOTIFICATIONS";
  static REQUEST_PUT_A3_NOTIFICATIONS_FINISHED =
    "A3NotificationAction.REQUEST_PUT_A3_NOTIFICATIONS_FINISHED";

  static REQUEST_PUT_A3_NOTIFICATIONS_BULK_UPDATE =
    "A3NotificationAction.REQUEST_PUT_A3_NOTIFICATIONS_BULK_UPDATE";
  static REQUEST_PUT_A3_NOTIFICATIONS_BULK_UPDATE_FINISHED =
    "A3NotificationAction.REQUEST_PUT_A3_NOTIFICATIONS_BULK_UPDATE_FINISHED";

  static REQUEST_DELETE_A3_NOTIFICATIONS =
    "A3NotificationAction.REQUEST_DELETE_A3_NOTIFICATIONS";
  static REQUEST_DELETE_A3_NOTIFICATIONS_FINISHED =
    "A3NotificationAction.REQUEST_DELETE_A3_NOTIFICATIONS_FINISHED";

  //METHODS
  static requestA3Notifications(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3NotificationAction.REQUEST_A3_NOTIFICATIONS,
        A3NotificationEffect.requestA3Notifications,
        params
      );
    };
  }

  static requestPutA3NotificationsBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3NotificationAction.REQUEST_PUT_A3_NOTIFICATIONS_BULK_UPDATE,
        A3NotificationEffect.requestPutA3NotificationsBulkUpdate,
        data
      );
    };
  }

  static requestA3FilterNotifications(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
        A3NotificationEffect.requestA3FilterNotifications,
        params,
        callback
      );
    };
  }

  //   static requestPostA3Notifications(data) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         A3NotificationAction.REQUEST_POST_A3_NOTIFICATIONS,
  //         A3NotificationEffect.requestPostA3Notifications,
  //         data
  //       );
  //     };
  //   }

  static requestPutA3Notifications(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3NotificationAction.REQUEST_PUT_A3_NOTIFICATIONS,
        A3NotificationEffect.requestPutA3Notifications,
        data,
        id
      );
    };
  }

  //   static requestDeleteA3Notifications(id) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         A3NotificationAction.REQUEST_DELETE_A3_NOTIFICATIONS,
  //         A3NotificationEffect.requestDeleteA3Notifications,
  //         id
  //       );
  //     };
  //   }
}
