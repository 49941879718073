import { BaseModel } from "sjs-base-model";
import GmbReviewFeedsDataHitsModel from "./GmbReviewFeedsDataHitsModel";

//GmbReviewFeedsHitsModel
export default class GmbReviewFeedsHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ GmbReviewFeedsDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
