import { BaseModel } from "sjs-base-model";
import ResolvedTicketCountBucketsDataModel from "./ResolvedTicketCountBucketsDataModel";

export default class ResolvedTicketCountBucketsModel extends BaseModel {
  //Resolved ticket count buckets model
  doc_count = 0;
  key = "";
  ticket_between = {
    buckets: [ResolvedTicketCountBucketsDataModel],
  };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
