import ActionUtility from "../../../utilities/ActionUtility";
import CloudMialOrgEffect from "./CloudMailOrgEffect";

export default class CloudMailOrgAction {
  static REQUEST_CLOUD_MAIL_ORG = "CloudMailOrgAction.REQUEST_CLOUD_MAIL_ORG";
  static REQUEST_CLOUD_MAIL_ORG_FINISHED =
    "CloudMailOrgAction.REQUEST_CLOUD_MAIL_ORG_FINISHED";

  static requestCloudMailOrg(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CloudMailOrgAction.REQUEST_CLOUD_MAIL_ORG,
        CloudMialOrgEffect.requestCloudMailOrg,
        params
      );
    };
  }
}
