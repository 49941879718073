import { BaseModel } from "sjs-base-model";
import CannedMessageDataHitsModel from "./CannedMessageDataHitsModel";
import CannedMessageShardsModel from "./CannedMessageShardsModel";

// CannedMessageModel
export default class CannedMessageModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: CannedMessageShardsModel };
  current_page_no = 0;
  hits = { hits: CannedMessageDataHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
