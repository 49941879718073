import { BaseModel } from "sjs-base-model";
import ConsumerHitsModel from "./ConsumerHitsModel";
import ConsumerShardsModel from "./ConsumerShardsModel";

export default class ConsumerModel extends BaseModel {
  _shards = { _shards: ConsumerShardsModel };
  hits = { hits: ConsumerHitsModel };
  total_page_no = 0;
  current_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
