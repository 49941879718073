import { Component } from "react";
import ActionUtility from "../../../utilities/ActionUtility";
import InstagramDirectMessageEffect from "./InstagramDirectMessageEffect";

export default class InstagramDirectMessageAction extends Component {
  //InstagramDirectMessage requests
  static REQUEST_INSTAGRAM_DIRECT_MESSAGE =
    "InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE";
  static REQUEST_INSTAGRAM_DIRECT_MESSAGE_FINISHED =
    "InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE_FINISHED";

  //InstagramDirectMessage post
  static REQUEST_POST_INSTAGRAM_DIRECT_MESSAGE =
    "InstagramDirectMessageAction.REQUEST_POST_INSTAGRAM_DIRECT_MESSAGE";
  static REQUEST_POST_INSTAGRAM_DIRECT_MESSAGE_FINISHED =
    "InstagramDirectMessageAction.REQUEST_POST_INSTAGRAM_DIRECT_MESSAGE_FINISHED";

  static REQUEST_INSTAGRAM_DIRECT_MESSAGE_CLEAR =
    "InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE_CLEAR";
  static REQUEST_INSTAGRAM_DIRECT_MESSAGE_CLEAR_FINISHED =
    "InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE_CLEAR_FINISHED";

  //METHODS

  //InstagramDirectMessage get method
  static requestInstagramDirectMessage(params) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE,
        InstagramDirectMessageEffect.requestInstagramDirectMessage,
        params
      );
    };
  }

  //InstagramDirectMessage post method
  static requestInstagramDirectMessagePost(data, id, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramDirectMessageAction.REQUEST_POST_INSTAGRAM_DIRECT_MESSAGE,
        InstagramDirectMessageEffect.requestInstagramDirectMessagePost,
        data,
        id,
        callback
      );
    };
  }

  static requestInstagramDMClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE_CLEAR,
        InstagramDirectMessageEffect.requestInstagramDMClear
      );
    };
  }
}
