import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import MuiStaticTimePicker from "../../../components/dateTimePicker/MuiStaticTimePIcker";
import MuiTimePicker from "../../../components/dateTimePicker/MuiTimePicker";
import MuiButton from "../../../components/muiButton/MuiButton";
import SwitchButton from "../../../components/switchComponent/SwitchButton";
import TicketSettingsAction from "../../stores/ticketSettings/TicketSettingsAction";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const AddBusinessHours = (props) => {
  const { business_hours_list } = props;

  let initialValues = {};

  if (business_hours_list && business_hours_list.length !== 0) {
    initialValues = {
      business_hours: business_hours_list,
    };
  } else {
    initialValues = {
      business_hours: [
        {
          is_holiday: false,
          from: "",
          to: "",
          day: "Sunday",
        },

        {
          is_holiday: false,
          from: "",
          to: "",
          day: "Monday",
        },
        {
          is_holiday: false,
          from: "",
          to: "",
          day: "Tuesday",
        },
        {
          is_holiday: false,
          from: "",
          to: "",
          day: "Wednesday",
        },
        {
          is_holiday: false,
          from: "",
          to: "",
          day: "Thursday",
        },
        {
          is_holiday: false,
          from: "",
          to: "",
          day: "Friday",
        },
        {
          is_holiday: false,
          from: "",
          to: "",
          day: "Saturday",
        },
      ],
    };
  }

  function TimeCalculator(value) {
    if (typeof value.$H === "number") {
      return `${value.$H < 10 ? `0${value.$H}` : value.$H}:${
        value.$m < 10 ? `0${value.$m}` : value.$m
      }`;
    } else {
      return value && value.split("T")[1] === "undefined"
        ? "00:00"
        : value.split("T")[1];
    }
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));

          let business_hours = [];

          values.business_hours.map((item, index) => {
            business_hours.push({
              day: index + 1,
              is_holiday: item.is_holiday,
              from:
                item.from !== "00:00"
                  ? item.from !== ""
                    ? TimeCalculator(item.from)
                    : "00:00"
                  : "00:00",
              to:
                item.to !== "00:00"
                  ? item.to !== ""
                    ? TimeCalculator(item.to)
                    : "00:00"
                  : "00:00",
            });
          });

          let obj = {
            business_hours,
          };

          if (props.PutId) {
            props
              .dispatch(
                TicketSettingsAction.requestPutTicketSettings(
                  JSON.stringify(obj),
                  props.PutId
                )
              )
              .then(() => {
                props.dispatch(TicketSettingsAction.requestTicketSettings());
              });
          } else {
            props
              .dispatch(TicketSettingsAction.requestPostTicketSettings(obj))
              .then(() => {
                props.dispatch(TicketSettingsAction.requestTicketSettings());
              });
          }
        }}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="business_hours">
              {({ insert, remove, push }) => (
                <div style={{ padding: "20px" }}>
                  <div className="row">
                    <div className="col">Days</div>
                    <div className="col">Holiday</div>
                    <div className="col">From</div>
                    <div className="col">To</div>
                  </div>
                  <br />
                  {values.business_hours.length > 0 &&
                    values.business_hours.map((item, index) => (
                      <div className="row" key={index}>
                        <div className="col" style={{ marginTop: "5px" }}>
                          <label htmlFor={`business_hours.${index}.day`}>
                            {item.day}
                          </label>
                        </div>
                        <div className="col" style={{ marginTop: "5px" }}>
                          <Field
                            type="checkbox"
                            name={`business_hours.${index}.is_holiday`}
                            component={SwitchButton}
                            checked={item.is_holiday ? item.is_holiday : null}
                          />
                          <ErrorMessage
                            name={`business_hours.${index}.is_holiday`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                        <div className="col" style={{ marginTop: "5px" }}>
                          <Field
                            value={values.business_hours[index].from}
                            name={`business_hours.${index}.from`}
                            component={MuiTimePicker}
                            // views={["hours", "minutes"]}
                            format={"HH:mm"}
                            size="small"
                          />
                          <ErrorMessage
                            name={`business_hours.${index}.from`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                        <div className="col" style={{ marginTop: "5px" }}>
                          <Field
                            value={values.business_hours[index].to}
                            name={`business_hours.${index}.to`}
                            component={MuiTimePicker}
                            // views={["hours", "minutes"]}
                            format={"HH:mm"}
                            size="small"
                          />
                          <ErrorMessage
                            name={`business_hours.${index}.to`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </FieldArray>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <MuiButton name={"Submit"} type="submit">
                Invite
              </MuiButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect(mapDispatchToProps)(AddBusinessHours);
