import BaseReducer from "../../../../utilities/BaseReducer";
import TopLyricistAction from "./TopLyricistAction";

export default class TopLyricistFilterReducer extends BaseReducer {
  //initial state of TopLyricistFilterReduser
  initialState = {
    top_lyricists_report_filter: [],
  };
  //TopLyricistFilterReduser request action finish
  [TopLyricistAction.REQUEST_TOP_LYRICIST_FILTER_FINISHED](state, action) {
    return {
      ...state,
      top_lyricists_report_filter: action.payload,
    };
  }
}
