import { BaseModel } from "sjs-base-model";
import TicketTypeHitsDataModel from "./TicketTypeHitsDataModel";

export default class TicketTypeHitsModel extends BaseModel {
  //ticketType hits model
  hits = [TicketTypeHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
