import ActionUtility from "../../../utilities/ActionUtility";
import MailTriggerListEffect from "./MailTriggerListEffect";

export default class MailTiggerListAction {
  // MailTiggerListAction action with an function declaration
  static REQUEST_TRIGGER_LIST = "MailTiggerListAction.REQUEST_TRIGGER_LIST";
  static REQUEST_TRIGGER_LIST_FINISHED =
    "MailTiggerListAction.REQUEST_TRIGGER_LIST_FINISHED";

  static REQUEST_TRIGGERLIST_FILTER =
    "MailTiggerListAction.REQUEST_TRIGGERLIST_FILTER";
  static REQUEST_TRIGGERLIST_FILTER_FINISHED =
    "MailTiggerListAction.REQUEST_TRIGGERLIST_FILTER_FINISHED";

  static REQUEST_PUT_TRIGGERLIST =
    "MailTiggerListAction.REQUEST_PUT_TRIGGERLIST";
  static REQUEST_PUT_TRIGGERLIST_FINISHED =
    "MailTiggerListAction.REQUEST_PUT_TRIGGERLIST_FINISHED";

  static REQUEST_POST_TRIGGERLIST =
    "MailTiggerListAction.REQUEST_POST_TRIGGERLIST";
  static REQUEST_POST_TRIGGERLIST_FINISHED =
    "MailTiggerListAction.REQUEST_POST_TRIGGERLIST_FINISHED";

  // METHODS
  // triggerlist GET function
  static requestTriggerList(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MailTiggerListAction.REQUEST_TRIGGER_LIST,
        MailTriggerListEffect.requestTriggerList,
        params
      );
    };
  }

  static requestTriggerListFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MailTiggerListAction.REQUEST_TRIGGERLIST_FILTER,
        MailTriggerListEffect.requestTriggerListFilter,
        params
      );
    };
  }
  static requestPutTriggerList(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MailTiggerListAction.REQUEST_PUT_TRIGGERLIST,
        MailTriggerListEffect.requestPutTriggerList,
        data,
        id
      );
    };
  }

  // triggerlistfunction
  static requestPostTriggerList(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MailTiggerListAction.REQUEST_POST_TRIGGERLIST,
        MailTriggerListEffect.requestPostTriggerList,
        data
      );
    };
  }
}
