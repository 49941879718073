import BaseReducer from "../../../utilities/BaseReducer";
import WhatsAppFeedAction from "./WhatsAppFeedAction";

export default class WhatsAppFeedFilterReduser extends BaseReducer {
  //initial state of WhatsAppFeed
  initialState = {
    whatsAppFeedFilter: [],
  };
  //WhatsAppFeed request action finish
  [WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_FILTER_FINISHED](state, action) {
    return {
      ...state,
      whatsAppFeedFilter: action.payload,
    };
  }
}
