import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import PaginationUI from "../../../components/pagination/Pagination";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
//import { NivoBarChart } from "../../../components/nivoCharts/NivoBarChart";
import ReactEcharts from "echarts-for-react";
function BridgeUsersAvgTATChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  async function fetchData(dateRange) {
    let API = `${
      environment.api.agentReports
    }?report_type=["count"]&reports=["agent_based_on_platform_wise_tat"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });
  }
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter).then((e) => setData(e));
      setLoading(false);
    })();
  }, [props.DateFilter]);
  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter).then((e) => setData(e));
        setLoading(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter).then((e) => setData(e));
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  let PlatformNameList = [];
  data &&
    data.agent_report &&
    data.agent_report.agent_based_on_platform_wise_tat &&
    data.agent_report.agent_based_on_platform_wise_tat.length &&
    data.agent_report.agent_based_on_platform_wise_tat.map((item) => {
      item &&
        item[Object.keys(item)].map((val) => {
          PlatformNameList.push(Object.keys(val)[0]);
        });
    });

  PlatformNameList = new Set([...PlatformNameList]);

  PlatformNameList = [...new Set(PlatformNameList)];

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  let updatedRows = [];

  data &&
    data.agent_report &&
    data.agent_report.agent_based_on_platform_wise_tat.length &&
    data.agent_report.agent_based_on_platform_wise_tat.map((item, index) => {
      if (Object.keys(item)[0] !== "Unassigned") {
        const rows = {
          id: index + 1,
          agent_name: Object.keys(item)[0],
        };

        PlatformNameList.map((key) => {
          if (key) {
            const filteredPlatform = Object.values(item)[0].find(
              (val) => Object.keys(val)[0] === key
            );

            if (
              filteredPlatform &&
              platformName(Object.keys(filteredPlatform)[0])
            ) {
              rows[platformName(Object.keys(filteredPlatform)[0])] = {
                count:
                  Object.values(filteredPlatform)[0] &&
                  Object.values(filteredPlatform)[0].count,
                value:
                  Object.values(filteredPlatform)[0] &&
                  HoursAndMinutes(Object.values(filteredPlatform)[0].value),
              };
            } else {
              rows[platformName(key)[0]] = {
                count: 0,
                value: "00:00:00",
              };
            }
          }
        });

        updatedRows.push(rows);
      }
    });

  let columns = [];

  columns = [
    {
      field: "agent_name",
      headerName: "Bridge User",
      width: 150,
      resizable: true,
    },
  ];

  PlatformNameList &&
    PlatformNameList.map((item) => {
      if (item && platformName(item)[0]) {
        columns.push({
          field: platformName(item)[0],
          headerName: (
            <div
              style={{
                lineHeight: "23px",
                minWidth: "150px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {platformName(item)[0]}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <span>{"Count"}</span>
                <span>{"TAT"}</span>
              </div>
            </div>
          ),

          resizable: true,
          width: 180,

          renderCell: (params) => (
            <div
              style={{
                lineHeight: "23px",
                minWidth: "150px",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <span>
                {params.row[platformName(item)[0]] &&
                  params.row[platformName(item)[0]].count}
              </span>
              <span>
                {params.row[platformName(item)[0]] &&
                  params.row[platformName(item)[0]].value}
              </span>
            </div>
          ),
        });
      }
    });

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  const totalRows = updatedRows && updatedRows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const finalRows = updatedRows && updatedRows.slice(startIndex, endIndex);

  let NoDataFoundBool =
    data &&
    data.agent_report &&
    data.agent_report.agent_based_on_platform_wise_tat.length === 0;

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };
  const chartData = updatedRows?.map((agent) => ({
    id: agent.agent_name,
    data: Object.entries(agent)
      .filter(([key, value]) => key !== "id" && typeof value === "object")
      .map(([platform, { count }]) => ({ x: platform, y: count })),
  }));

  let barchartData = [];

  const uniquePlatformNames = new Set();

  updatedRows?.forEach((agent) => {
    Object.keys(agent)
      .filter(
        (key) =>
          key !== "id" && key !== "agent_name" && typeof agent[key] === "object"
      )
      .forEach((platform) => {
        uniquePlatformNames.add(platform);
      });
  });

  const result = Array.from(uniquePlatformNames);

  barchartData = updatedRows.map((agent) => {
    const agentResult = {
      agent: agent.agent_name,
    };

    Object.entries(agent)
      .filter(
        ([key, value]) =>
          key !== "id" && key !== "agent_name" && typeof value === "object"
      )
      .forEach(([platform, { count, value }]) => {
        agentResult[platform] = count;
      });

    return agentResult;
  });

  let legendOffset,
    maxYAxisLength = 0;
  if (chartData?.length > 0) {
    maxYAxisLength =
      chartData &&
      Math.max(
        ...chartData?.map((item) => {
          return String(item.id?.length);
        })
      );
    if (maxYAxisLength >= 10)
      legendOffset = -90 - maxYAxisLength * (maxYAxisLength / 10);
    else if (maxYAxisLength > 0) legendOffset = -80;
  }

  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const yAxisData = barchartData.reverse().map((item) => item.agent);
    const series = [];
    const keys = [];
    barchartData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (
          key !== "agent" &&
          key !== "total_tickets" &&
          key !== "id" &&
          !keys.includes(key)
        ) {
          keys.push(key);
        }
      });
    });

    keys.forEach((key, index) => {
      const seriesData = barchartData.map((item) => ({
        name: item.agent,
        value: item[key] ? parseInt(item[key]) : 0,
      }));

      series.push({
        name: key,
        type: "bar",
        stack: "total",
        label: {
          show: true,
          position: "insideRight",
          formatter: (params) => {
            let val = params.value;
            let formattedvalue = formatValue(val);
            return val === 0 ? "" : formattedvalue;
          },
          color: theme.palette.primary.dark,
        },
        emphasis: {
          focus: "series",
        },
        data: seriesData,
        itemStyle: {
          color: theme.platform[`color${index + 1}`], // Use theme colors dynamically
        },
      });
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              let val = item.value;
              let formattedvalue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName}: ${formattedvalue}<br/>`;
            }
          });
          return tooltipContent;
        },
      },

      legend: {
        icon: "circle",
        type: "scroll",
        data: shouldShowLegend ? keys : [],
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",

        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      labelLayout: {
        hideOverlap: true,
      },
      yAxis: {
        type: "category",
        // name: "Bridge User",
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Bridge User"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 0,
        },
        data: yAxisData,
        axisLabel: {
          show: props.detailedReport ? true : false,
          padding: [0, 30, 0, 0],
          hideOverlap: true,
        },
        axisTick: {
          show: true, // Hide the axis ticks completely
        },
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      xAxis: {
        type: "value",
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          formatter: (value) => formatValue(value),
          hideOverlap: true,
        },
      },
      series: series,
    };
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      chartData?.forEach((item) => {
        // Create an object for each item with the "Bridge User" key
        const exportItem = {
          ["Bridge User"]: item.id,
        };

        // Iterate through the data array to add x and y as key-value pairs
        item.data.forEach((dataPoint) => {
          exportItem[dataPoint.x] = prefixZero_for_singleDigit(dataPoint.y); // Set x as key and y as value
        });

        // Push the completed exportItem to exportData
        exportData.push(exportItem);
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, chartData]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading && !NoDataFoundBool ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption()}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default BridgeUsersAvgTATChart;
