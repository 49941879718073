import DeviceResetAction from "./DeviceResetAction";
import BaseReducer from "../../../../utilities/BaseReducer";

export default class surveyDeviceResetReducer extends BaseReducer {
  initialState = {
    surveyMappedDevice: [],
  };

  [DeviceResetAction.REQUEST_DEVICE_MAPPING_FINISHED](state, action) {
    return {
      ...state,
      surveyMappedDevice: action.payload,
    };
  }
}
