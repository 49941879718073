import ActionUtility from "../../../utilities/ActionUtility";
import OrmReportLogoEffect from "./OrmReportLogoEffect";

export default class OrmReportLogoAction {
  static REQUEST_ORM_REPORT_LOGO =
    "OrmReportLogoAction.REQUEST_ORM_REPORT_LOGO";
  static REQUEST_ORM_REPORT_LOGO_FINISHED =
    "OrmReportLogoAction.REQUEST_ORM_REPORT_LOGO_FINISHED";

  //METHODS

  static requestOrmReportLogo(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmReportLogoAction.REQUEST_ORM_REPORT_LOGO,
        OrmReportLogoEffect.requestOrmReportLogo,
        params
      );
    };
  }
}
