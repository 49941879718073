import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";
import * as echarts from "echarts";
import { tooltip } from "leaflet";
function BasicAreaChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getOption = {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["Week1", "Week2", "Week3", "Week4"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [820, 932, 1330, 1320],
        type: "line",
        itemStyle: {
          color: "#468C9D",
        },
        areaStyle: {
          color: "#B4D5DD",
        },
      },
    ],
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default BasicAreaChart;
