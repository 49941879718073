import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

//TicketType source data model
export default class TicketTypeSourceModel extends BaseModel {
  id = v4();
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = "";
  modified_on = "";
  ticket_type_name = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
