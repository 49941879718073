import ActionUtility from "../../../utilities/ActionUtility";
import OrganizationEffect from "./OrganizationEffect";

export default class OrganizationAction {
  // OrganizationAction action with an function declaration
  static REQUEST_ORGANIZATION = "OrganizationAction.REQUEST_ORGANIZATION";
  static REQUEST_ORGANIZATION_FINISHED =
    "OrganizationAction.REQUEST_ORGANIZATION_FINISHED";

  static REQUEST_ORGANIZATION_FILTER =
    "OrganizationAction.REQUEST_ORGANIZATION_FILTER";
  static REQUEST_ORGANIZATION_FILTER_FINISHED =
    "OrganizationAction.REQUEST_ORGANIZATION_FILTER_FINISHED";

  static REQUEST_PUT_ORGANIZATION =
    "OrganizationAction.REQUEST_PUT_ORGANIZATION";
  static REQUEST_PUT_ORGANIZATION_FINISHED =
    "OrganizationAction.REQUEST_PUT_ORGANIZATION_FINISHED";

  static REQUEST_POST_ORGANIZATION =
    "OrganizationAction.REQUEST_POST_ORGANIZATION";
  static REQUEST_POST_ORGANIZATION_FINISHED =
    "OrganizationAction.REQUEST_POST_ORGANIZATION_FINISHED";

  // METHODS
  // Organization GET function
  static requestOrganization(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrganizationAction.REQUEST_ORGANIZATION,
        OrganizationEffect.requestOrganization,
        params
      );
    };
  }

  static requestOrganizationFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrganizationAction.REQUEST_ORGANIZATION_FILTER,
        OrganizationEffect.requestOrganizationFilter,
        params
      );
    };
  }
  static requestPutOrganization(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrganizationAction.REQUEST_PUT_ORGANIZATION,
        OrganizationEffect.requestPutOrganization,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostOrganization(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrganizationAction.REQUEST_POST_ORGANIZATION,
        OrganizationEffect.requestPostOrganization,
        data
      );
    };
  }
}
