import { BaseModel } from "sjs-base-model";

export default class RolePermissionPutModel extends BaseModel {
  // data = {};
  // id = "";
  status = "";

  constructor(data1) {
    super();
    this.update(data1);
  }
}
