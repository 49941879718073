import BaseReducer from "../../../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Releases Reducer
export default class ReleaseShadowReduser extends BaseReducer {
  initialState = {
    releaseshadoow: [],
  };
  [ReleasesAction.REQUEST_RELEASES_SHADOW_FINISHED](state, action) {
    return {
      ...state,
      releaseshadoow: action.payload,
    };
  }

  //   [ReleasesAction.REQUEST_RELEASE_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       releaseshadoow: action.payload,
  //     };
  //   }
}
