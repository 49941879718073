import BaseReducer from "../../../utilities/BaseReducer";
import TwitterAction from "./TwitterAction";

export default class TwitterFilterReducer extends BaseReducer {
  //initial state of user
  initialState = {
    twitterfilter: [],
  };

  //user request action finish
  [TwitterAction.REQUEST_TWITTER_FILTER_FINISHED](state, action) {
    return {
      ...state,
      twitterfilter: action.payload,
    };
  }
}
