/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import { format } from "date-fns";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import environment from "environment";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import FacebookAction from "../../../stores/facebook/FacebookAction";
import TicketsAction from "../../../stores/tickets/TicketsAction";
import FaceBookAccountAction from "../../../stores/faceBookAccount/FaceBookAccountAction";
import { theme } from "../../../../views/App";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import PaginationUI from "../../../../components/pagination/Pagination";
import { axiosRequest } from "../../../../utilities/FetchRequest";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    FacebookAction.REQUEST_FACEBOOK,
    // FacebookAction.REQUEST_FACEBOOK_FILTER,
    FacebookAction.REQUEST_FACEBOOK_WEBHOOK,
    FacebookAction.REQUEST_FACEBOOK_COMMENT_DELETE,
    FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT,
    TicketsAction.REQUEST_TICKETS_FILTER,
  ]),
  facebookAccount: state.facebookAccount.facebookAccount || [],
  // facebook: state.facebook.facebook.hits || [],
  // facebookFilter: state.facebookFilter.facebookFilter || [],
  pagination: state.facebookWebhook.facebookWebhook || {},
  ticketfilter: state.ticketfilter.ticketfilter || [],
  facebookWebhook: state.facebookWebhook.facebookWebhook.hits || [],
});

function FacebookFeedMain(props) {
  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  const [show, setShow] = useState(false);
  const [date, setDate] = useState([null, null]);
  const [showMoreData, setShowMoreData] = useState({});
  const [activity, setActivity] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [value] = React.useState("1");
  const [page_limit] = useState(5);
  const Ratings = ["1.0", "2.0", "3.0", "4.0", "5.0"];
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  const ActivitiesList = ["Comment", "Reply", "DM", "Review"];

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  const activityHandleChange = (event, value) => {
    if (value != null) {
      setActivity(value);
    } else {
      setActivity(null);
    }
  };

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName(null);
    }
  };

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val(null);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  //  HANDLE FILTER
  const handleFilter = () => {
    const filter = [];

    if (
      accountName !== null ||
      activity !== null ||
      searchQuery !== "" ||
      sentimental_val !== null ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== null
    ) {
      let params = {};

      Object.assign(
        params,
        accountName && { account_name: accountName },
        searchQuery && { search_content: `*${searchQuery}*` },
        date[0] && date[1] && { from_date: `${format(date[0], "yyyy-MM-dd")}` },
        date[0] && date[1] && { to_date: `${format(date[1], "yyyy-MM-dd")}` },
        sentimental_val && { sentiment_value: sentimental_val.toLowerCase() }
      );

      fetchFB(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
    }
  };

  //  HANDLE FILTER
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (activity !== null) {
      filter.push(`["activities.keyword", "must", "", "match", "${activity}"]`);
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(
          `["sentiment_value","must","lte","range","0"],["sentiment_value","must","gte","range","0"]`
        );
      }
    }

    if (FilterClicked) {
      if (
        accountName !== null ||
        activity !== null ||
        searchQuery !== "" ||
        (date[0] !== null && date[1] !== null) ||
        sentimental_val !== null
      ) {
        const params = {
          filters: `[${filter}]`,
          page_limit: `${page_limit}`,
          page_number: `${value}` - 1,
          order_by: '[["created_on", "desc"]]',
        };
        fetchFB(params);
      }
    } else {
      params = {
        page_limit: `${page_limit}`,
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
      };

      if (value - 1 !== props.pagination.current_page_no) {
        fetchFB(params);
      }
    }
  };

  const ClearState = () => {
    setFilterClicked(false);

    if (
      accountName !== null ||
      activity !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== null
    ) {
      setAccountName(null);
      setActivity(null);
      setDate([null, null]);
      setSearchQuery("");

      setSentimental_val(null);
      setClearLoader(true);

      setShow(false);

      const params = {
        // page_number: `${value}` - 1,
        // order_by: '[["created_on", "desc"]]',
        // page_limit: `${page_limit}`,
      };
      FilterClicked && fetchFB(params);
    }
  };

  function fetchFB(params) {
    // props.dispatch(FacebookAction.requestFacebook(params));
    props.dispatch(FacebookAction.requestFacebookWebhook(params));
  }

  function fetchFacebookFilter() {
    props.dispatch(FacebookAction.requestFacebookFilter());
  }

  function fetchFacebookAccount() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(FaceBookAccountAction.requestFaceBookAccount());
  }

  function fetchTicketsFilter() {
    let param = {
      filters: `[["platform.keyword","must","","match","Facebook"],["activities.keyword","must","","match","Comments"]]`,
      page_limit: "none",
    };
    props.dispatch(TicketsAction.requestTicketsFilter(param));
  }

  useEffect(() => {
    // fetchFacebookFilter();
    fetchFacebookAccount();
    fetchTicketsFilter();
    const filter = [];
    let params = {};

    if (activity !== null) {
      filter.push(`["activities.keyword", "must", "", "match", "${activity}"]`);
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      accountName !== null ||
      activity !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      const params = {
        // filters: `[${filter}]`,
        // page_limit: `${page_limit}`,
        // order_by: '[["created_on", "desc"]]',
      };
      fetchFB(params);
    } else {
      params = {
        // page_limit: `${page_limit}`,
        // order_by: '[["created_on", "desc"]]',
      };
      fetchFB(params);
    }
  }, []);

  const list = [];

  if (props.facebookAccount.hits) {
    props.facebookAccount.hits.hits &&
      props.facebookAccount.hits.hits.length > 0 &&
      props.facebookAccount.hits.hits.map((value) => {
        list.push(value._source.account_name);
      });
  }

  const uniqueList = [...new Set(list)];
  let maxDate = new Date();

  const returnSentimentname = (item) => {
    let sentiment;
    if (item.sentiment_value && typeof item.sentiment_value === "number") {
      if (item.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }
    return sentiment;
  };

  const openTicketFunction = (ticket_id) => {
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
    set_circuler_pogress_id(ticket_id);

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);

    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  return (
    <Box>
      <Grid
        container
        mt={2}
        sx={{
          backgroundColor: "#f3f9fb",
          height: "auto",
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
        }}
      >
        <Grid
          container
          sx={{
            margin: 0,
            height: "auto",
            padding: 0,
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Account Name</span>
              </Grid>
              <AutoCompleteComponent
                value={accountName}
                list={uniqueList}
                handleChange={accountNameHandleChange}
                textFieldProps={{
                  placeholder: "Select Option",
                }}
                size="small"
                width="100%"
              />
            </Grid>

            <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Search Content</span>
              </Grid>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius:
                      theme.palette.buttonComponent.borderRadius.medium,
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    padding: theme.typography.padding,
                    height: "35px",
                    width: "100%",
                  }}
                  id="search_bar"
                  autoComplete={"off"}
                  value={searchQuery}
                  placeholder={"Search by Content"}
                  onInput={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  size="small"
                  startAdornment={
                    <InputAdornment>
                      <IconButton onClick={handleFilter}>
                        <SearchIcon
                          sx={{
                            "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            {/* <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
              <Grid item lg={3} md={6} sm={6} mt={1}>
                <span>Activities</span>
              </Grid>
              <AutoCompleteComponent
                list={ActivitiesList}
                value={activity}
                textFieldProps={{
                  placeholder: "Select Option",
                }}
                handleChange={activityHandleChange}
                size="small"
                width="100%"
              />
            </Grid> */}

            <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Date Filter</span>
                <Grid item mt={1}>
                  <DatePicker
                    value={date}
                    onChange={dateValueHandleChange}
                    maxDate={maxDate}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Sentimental Value</span>
              </Grid>
              <AutoCompleteComponent
                // ***************************************************Customer name filter
                value={sentimental_val}
                list={sentimental_val_list}
                textFieldProps={{
                  placeholder: "Sentimental Value",
                }}
                handleChange={sentimentalvalHandleChange}
                size="small"
                width="100%"
              />
            </Grid>

            <Grid item xl={2} lg={3} md={12} sm={12} mt={0}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={2.5}
              >
                <Grid item xl={3.5} lg={6} md={3} sm={5}>
                  <MuiButton
                    loading={filterLoader && isRequesting}
                    name={"Apply"}
                    width="100%"
                    onClick={handleFilter}
                  />
                </Grid>
                <Grid item xl={3.5} lg={6} md={3} sm={5}>
                  <MuiButton
                    loading={clearLoader && isRequesting}
                    name={"Clear"}
                    width="100%"
                    onClick={ClearState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <hr style={{ marginTop: 18 }} />

      {/* <LoadingIndicator isActive={isRequesting}>
        {props.facebook.hits ? ( */}
      <Box width="100%">
        <Grid
          lg={12}
          md={12}
          sm={12}
          container
          sx={{
            backgroundColor: "#f3f9fb",
            padding: 2,
            borderRadius: theme.borderRadius,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            height: "100%",
          }}
        >
          {/* {props.facebook.hits.length !== 0 ? ( */}
          <Box width="100%">
            {/* <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="flex-start"
              alignItems="center"
              mt={0}
            >
              <Grid
                item
                // xl={3.5}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                textAlign="left"
              >
                <h5>Feeds</h5>
              </Grid>
            </Grid>
            <hr className="dotted" style={{ marginTop: 0 }} /> */}

            {/* {console.log("fffffff", props.facebookWebhook)} */}

            {/* ********************************************************************************** Left List */}
            {props.facebookWebhook ? (
              <LoadingIndicator isActive={isRequesting}>
                {Array.isArray(props.facebookWebhook) &&
                props.facebookWebhook.length !== 0 ? (
                  <Grid
                    container
                    lg={12}
                    md={12}
                    sm={12}
                    display="flex"
                    mt={1}
                    justifyContent={"space-between"}
                    sx={{ paddingBottom: 10 }}
                  >
                    <Grid lg={5.8} md={12} sm={12}>
                      <Box
                        sx={{
                          overflow: "auto",
                          overscrollBehaviorY: "contain",
                        }}
                      >
                        {props.facebookWebhook &&
                          props.facebookWebhook.map((post, key) => {
                            return post._source.comments
                              ? post._source.comments &&
                                  post._source.comments.length > 0 &&
                                  post._source.comments.map((item) => (
                                    <Grid lg={12} md={12} sm={12}>
                                      <List>
                                        <Grid
                                          item
                                          container
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          sx={{
                                            backgroundColor: "#fff",
                                            height: "100%",
                                            padding: 2,
                                            borderRadius: theme.borderRadius,
                                            border: "solid 0.5px #d3e2e6",
                                            boxShadow:
                                              "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                            cursor:
                                              item && item.ticket_id
                                                ? "pointer"
                                                : null,
                                          }}
                                          // className="bg-warning"
                                          onClick={() => {
                                            localStorage.setItem(
                                              "activeTab",
                                              0
                                            );

                                            item &&
                                              item.ticket_id &&
                                              openTicketFunction(
                                                item.ticket_id
                                              );
                                          }}
                                        >
                                          <Grid
                                            item
                                            container
                                            lg={12}
                                            md={12}
                                            sm={12}
                                          >
                                            <Grid
                                              item
                                              lg={1.5}
                                              md={1.5}
                                              sm={2}
                                              p={1}
                                            >
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    sx={{
                                                      backgroundColor:
                                                        "skyblue",
                                                    }}
                                                    src={
                                                      item.user_profile_pic &&
                                                      item.user_profile_pic
                                                    }
                                                  >
                                                    {item.commented_user_name &&
                                                      item.commented_user_name.charAt(
                                                        0
                                                      )}
                                                  </Avatar>
                                                </ListItemAvatar>
                                              </ListItem>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={9.5}
                                              md={9.5}
                                              sm={9}
                                              container
                                            >
                                              <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                container
                                              >
                                                <Grid
                                                  item
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  mt={1}
                                                >
                                                  <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                  >
                                                    <ListItem>
                                                      <h6>
                                                        {" "}
                                                        {
                                                          item.commented_user_name
                                                        }{" "}
                                                      </h6>
                                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      <p>
                                                        {(function createdDate() {
                                                          let created =
                                                            item.comment_created_at;
                                                          let dateConversion =
                                                            new Date(created);
                                                          dateConversion =
                                                            dateConversion.toDateString() +
                                                            ", " +
                                                            dateConversion.toLocaleTimeString(
                                                              "en-US",
                                                              {
                                                                hour: "numeric",
                                                                minute:
                                                                  "numeric",
                                                                hour12: true,
                                                              }
                                                            );

                                                          return dateConversion;
                                                        })()}{" "}
                                                      </p>
                                                    </ListItem>
                                                  </Grid>
                                                </Grid>

                                                <Grid
                                                  item
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  mt={1}
                                                >
                                                  <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                  >
                                                    <ListItem>
                                                      <b>Sentiment value : </b>
                                                      &nbsp;
                                                      {post._source
                                                        .sentiment_value &&
                                                      post._source
                                                        .sentiment_value > 0 ? (
                                                        <IconButton
                                                          className="p-0 m-0"
                                                          title={returnSentimentname(
                                                            post._source
                                                          )}
                                                        >
                                                          <SentimentSatisfiedAltRoundedIcon
                                                            titleAccess="Positive"
                                                            style={{
                                                              width: "16px",
                                                              color: `green`,
                                                            }}
                                                          />
                                                        </IconButton>
                                                      ) : post._source
                                                          .sentiment_value <
                                                        0 ? (
                                                        <IconButton
                                                          className="p-0 m-0"
                                                          title={returnSentimentname(
                                                            post._source
                                                          )}
                                                        >
                                                          <SentimentDissatisfiedRoundedIcon
                                                            titleAccess="Negative"
                                                            style={{
                                                              width: "16px",
                                                              color: `${theme.palette.sentimentIconColor.negative}`,
                                                            }}
                                                          />
                                                        </IconButton>
                                                      ) : post._source
                                                          .sentiment_value !==
                                                        null ? (
                                                        <IconButton
                                                          className="p-0 m-0"
                                                          title={returnSentimentname(
                                                            post._source
                                                          )}
                                                        >
                                                          <SentimentNeutralRoundedIcon
                                                            titleAccess="Neutral"
                                                            style={{
                                                              width: "16px",
                                                              color: `${theme.palette.sentimentIconColor.neutral}`,
                                                            }}
                                                          />
                                                        </IconButton>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </ListItem>
                                                  </Grid>
                                                </Grid>

                                                <Grid
                                                  item
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <ListItem>
                                                    <Grid
                                                      item
                                                      lg={12}
                                                      md={12}
                                                      sm={12}
                                                    >
                                                      <Typography>
                                                        <b>Content :</b>
                                                        &nbsp;
                                                        {item.comment_content
                                                          .length >= 400 ? (
                                                          <div>
                                                            {item.comment_content.slice(
                                                              0,
                                                              380
                                                            )}
                                                            ... &nbsp;
                                                            <button
                                                              onClick={(
                                                                event
                                                              ) => {
                                                                event.stopPropagation();

                                                                setShowMoreData(
                                                                  item
                                                                );
                                                                setShow(true);
                                                                window.scrollTo(
                                                                  {
                                                                    top: 0,
                                                                    behavior:
                                                                      "smooth",
                                                                  }
                                                                );
                                                              }}
                                                              style={{
                                                                background:
                                                                  "none",
                                                                border: "none",
                                                              }}
                                                            >
                                                              <b>Show More</b>
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          item.comment_content.slice(
                                                            0,
                                                            500
                                                          )
                                                        )}
                                                      </Typography>
                                                    </Grid>
                                                  </ListItem>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              container
                                              lg={1}
                                              md={1}
                                              sm={1}
                                              p={1}
                                            >
                                              <Grid
                                                item
                                                lg={12}
                                                xl={12}
                                                md={12}
                                                sm={12}
                                              >
                                                {props.facebookAccount &&
                                                  props.facebookAccount.hits &&
                                                  props.facebookAccount.hits.hits.map(
                                                    (val) =>
                                                      val._id ===
                                                      post._source
                                                        .account_id ? (
                                                        <DeleteIcon
                                                          fontSize="25px"
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            let ticketId =
                                                              props.ticketfilter &&
                                                              props.ticketfilter
                                                                .hits &&
                                                              props.ticketfilter.hits.hits.filter(
                                                                (i) =>
                                                                  i._source
                                                                    .feed_id ===
                                                                  item._id
                                                              )[0]._id;

                                                            let data = {
                                                              ticket_id:
                                                                ticketId,
                                                            };

                                                            props
                                                              .dispatch(
                                                                FacebookAction.requestFacebookCommentDelete(
                                                                  item._id,
                                                                  JSON.stringify(
                                                                    data
                                                                  )
                                                                )
                                                              )
                                                              .then(() => {
                                                                fetchFB();
                                                              });
                                                          }}
                                                        />
                                                      ) : null
                                                  )}
                                              </Grid>
                                              {item.feed_link && (
                                                <Grid
                                                  item
                                                  lg={12}
                                                  xl={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  <Typography
                                                    component={"a"}
                                                    href={
                                                      item.feed_link
                                                        ? item.feed_link
                                                        : ""
                                                    }
                                                    target="_blank"
                                                    title="Feed Link"
                                                  >
                                                    <OpenInNewIcon
                                                      fontSize="25px"
                                                      sx={{ cursor: "pointer" }}
                                                    />
                                                  </Typography>
                                                </Grid>
                                              )}
                                              {/* {circuler_progress_id ===
                                                item.ticket_id &&
                                                open_ticket && (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={15}
                                                    // className="bg-light"
                                                  />
                                                )} */}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </List>
                                    </Grid>
                                  ))
                              : post.inner_hits &&
                                  post.inner_hits.comments.hits &&
                                  post.inner_hits.comments.hits.hits.map(
                                    (value) => (
                                      <Grid lg={12} md={12} sm={12}>
                                        <List>
                                          <Grid
                                            item
                                            container
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            sx={{
                                              backgroundColor: "#fff",
                                              height: "100%",
                                              padding: 2,
                                              borderRadius: theme.borderRadius,
                                              border: "solid 0.5px #d3e2e6",
                                              boxShadow:
                                                "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                              cursor:
                                                value && value._source.ticket_id
                                                  ? "pointer"
                                                  : null,
                                            }}
                                            // className="bg-warning"
                                            onClick={() => {
                                              localStorage.setItem(
                                                "activeTab",
                                                0
                                              );

                                              value &&
                                                value._source.ticket_id &&
                                                openTicketFunction(
                                                  value._source.ticket_id
                                                );
                                            }}
                                          >
                                            <Grid
                                              item
                                              container
                                              lg={12}
                                              md={12}
                                              sm={12}
                                            >
                                              <Grid
                                                item
                                                lg={1.5}
                                                md={1.5}
                                                sm={2}
                                                p={1}
                                              >
                                                <ListItem>
                                                  <ListItemAvatar>
                                                    <Avatar
                                                      sx={{
                                                        backgroundColor:
                                                          "skyblue",
                                                      }}
                                                      src={
                                                        value._source
                                                          .user_profile_pic &&
                                                        value._source
                                                          .user_profile_pic
                                                      }
                                                    >
                                                      {value._source
                                                        .commented_user_name &&
                                                        value._source.commented_user_name.charAt(
                                                          0
                                                        )}
                                                    </Avatar>
                                                  </ListItemAvatar>
                                                </ListItem>
                                              </Grid>
                                              <Grid
                                                item
                                                lg={9.5}
                                                md={9.5}
                                                sm={9}
                                                container
                                              >
                                                <Grid
                                                  item
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  container
                                                >
                                                  <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    mt={1}
                                                  >
                                                    <Grid
                                                      item
                                                      lg={12}
                                                      md={12}
                                                      sm={12}
                                                    >
                                                      <ListItem>
                                                        <h6>
                                                          {" "}
                                                          {
                                                            value._source
                                                              .commented_user_name
                                                          }{" "}
                                                        </h6>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <p>
                                                          {(function createdDate() {
                                                            let created =
                                                              value._source
                                                                .comment_created_at;
                                                            let dateConversion =
                                                              new Date(created);
                                                            dateConversion =
                                                              dateConversion.toDateString() +
                                                              ", " +
                                                              dateConversion.toLocaleTimeString(
                                                                "en-US",
                                                                {
                                                                  hour: "numeric",
                                                                  minute:
                                                                    "numeric",
                                                                  hour12: true,
                                                                }
                                                              );

                                                            return dateConversion;
                                                          })()}{" "}
                                                        </p>
                                                      </ListItem>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    mt={1}
                                                  >
                                                    <Grid
                                                      item
                                                      lg={12}
                                                      md={12}
                                                      sm={12}
                                                    >
                                                      <ListItem>
                                                        <b>Sentiment value :</b>
                                                        &nbsp;
                                                        {value._source
                                                          .sentiment_value &&
                                                        value._source
                                                          .sentiment_value >
                                                          0 ? (
                                                          <IconButton
                                                            className="p-0 m-0"
                                                            title={returnSentimentname(
                                                              value._source
                                                            )}
                                                          >
                                                            <SentimentSatisfiedAltRoundedIcon
                                                              titleAccess="Positive"
                                                              style={{
                                                                width: "16px",
                                                                color: `green`,
                                                              }}
                                                            />
                                                          </IconButton>
                                                        ) : value._source
                                                            .sentiment_value <
                                                          0 ? (
                                                          <IconButton
                                                            className="p-0 m-0"
                                                            title={returnSentimentname(
                                                              value._source
                                                            )}
                                                          >
                                                            <SentimentDissatisfiedRoundedIcon
                                                              titleAccess="Negative"
                                                              style={{
                                                                width: "16px",
                                                                color: `${theme.palette.sentimentIconColor.negative}`,
                                                              }}
                                                            />
                                                          </IconButton>
                                                        ) : value._source
                                                            .sentiment_value !==
                                                          null ? (
                                                          <IconButton
                                                            className="p-0 m-0"
                                                            title={returnSentimentname(
                                                              value._source
                                                            )}
                                                          >
                                                            <SentimentNeutralRoundedIcon
                                                              titleAccess="Neutral"
                                                              style={{
                                                                width: "16px",
                                                                color: `${theme.palette.sentimentIconColor.neutral}`,
                                                              }}
                                                            />
                                                          </IconButton>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </ListItem>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                  >
                                                    <ListItem>
                                                      <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                      >
                                                        <Typography>
                                                          <b>Content :</b>
                                                          &nbsp;
                                                          {value._source
                                                            .comment_content
                                                            .length >= 400 ? (
                                                            <div>
                                                              {value._source.comment_content.slice(
                                                                0,
                                                                380
                                                              )}
                                                              ... &nbsp;
                                                              <button
                                                                onClick={(
                                                                  event
                                                                ) => {
                                                                  event.stopPropagation();
                                                                  setShowMoreData(
                                                                    value._source
                                                                  );
                                                                  setShow(true);
                                                                  window.scrollTo(
                                                                    {
                                                                      top: 0,
                                                                      behavior:
                                                                        "smooth",
                                                                    }
                                                                  );
                                                                }}
                                                                style={{
                                                                  background:
                                                                    "none",
                                                                  border:
                                                                    "none",
                                                                }}
                                                              >
                                                                <b>Show More</b>
                                                              </button>
                                                            </div>
                                                          ) : (
                                                            value._source.comment_content.slice(
                                                              0,
                                                              500
                                                            )
                                                          )}
                                                        </Typography>
                                                      </Grid>
                                                    </ListItem>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                lg={1}
                                                md={1}
                                                sm={1}
                                                p={1}
                                              >
                                                <Grid
                                                  item
                                                  lg={12}
                                                  xl={12}
                                                  md={12}
                                                  sm={12}
                                                >
                                                  {/* {props.facebookAccount &&
                                                  props.facebookAccount.hits &&
                                                  props.facebookAccount.hits.hits.map(
                                                    (val) =>
                                                      val._id ===
                                                      post._source
                                                        .account_id ? (
                                                        <DeleteIcon
                                                          fontSize="25px"
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            let ticketId =
                                                              props.ticketfilter &&
                                                              props.ticketfilter
                                                                .hits &&
                                                              props.ticketfilter.hits.hits.filter(
                                                                (i) =>
                                                                  i._source
                                                                    .feed_id ===
                                                                  item._id
                                                              )[0]._id;

                                                            let data = {
                                                              ticket_id:
                                                                ticketId,
                                                            };

                                                            props
                                                              .dispatch(
                                                                FacebookAction.requestFacebookCommentDelete(
                                                                  item._id,
                                                                  JSON.stringify(
                                                                    data
                                                                  )
                                                                )
                                                              )
                                                              .then(() => {
                                                                fetchFB();
                                                              });
                                                          }}
                                                        />
                                                      ) : null
                                                  )} */}
                                                </Grid>
                                                {value._source.feed_link && (
                                                  <Grid
                                                    item
                                                    lg={12}
                                                    xl={12}
                                                    md={12}
                                                    sm={12}
                                                  >
                                                    <Typography
                                                      component={"a"}
                                                      href={
                                                        value._source.feed_link
                                                          ? value._source
                                                              .feed_link
                                                          : ""
                                                      }
                                                      target="_blank"
                                                      title="Feed Link"
                                                    >
                                                      <OpenInNewIcon
                                                        fontSize="25px"
                                                        sx={{
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    </Typography>
                                                  </Grid>
                                                )}
                                                {/* {circuler_progress_id ===
                                                item.ticket_id &&
                                                open_ticket && (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={15}
                                                    // className="bg-light"
                                                  />
                                                )} */}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </List>
                                      </Grid>
                                    )
                                  );
                          })}
                      </Box>
                    </Grid>

                    <Grid lg={0.1} md={0} sm={0} mr={1}>
                      <Divider orientation="vertical" />
                    </Grid>

                    <Grid
                      lg={0}
                      md={12}
                      sm={12}
                      mr={1}
                      sx={{ display: { lg: "none" } }}
                    >
                      <Divider />
                    </Grid>

                    {/* ********************************************************************************** Right List */}
                    <Grid lg={5.8} md={12} sm={12} sx={{ height: "100%" }}>
                      {show ? <ShowMore data={showMoreData} /> : null}
                    </Grid>
                  </Grid>
                ) : (
                  <NoDataFound />
                )}
              </LoadingIndicator>
            ) : null}
          </Box>
          {/* ) : (
            <NoDataFound />
          )} */}
        </Grid>
      </Box>
      {/* ) : null}
      </LoadingIndicator> */}
      {props.facebookWebhook.hits
        ? props.facebookWebhook.hits.length !== 0 && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChangePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(FacebookFeedMain);

const ShowMore = (props) => {
  return (
    <List
      sx={{
        width: "100%",
      }}
      component="nav"
    >
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        sx={{
          backgroundColor: "#fff",
          height: "auto",
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
        }}
      >
        <Grid container lg={12} md={12} sm={12}>
          <Grid item lg={1.5} md={1.5} sm={1.5} p={1} alignSelf="center">
            <ListItem>
              <ListItemAvatar>
                <Avatar></Avatar>
              </ListItemAvatar>
            </ListItem>
          </Grid>
          <Grid item lg={10.5} md={10.5} sm={10.5} container>
            <Grid item lg={12} md={12} sm={12} container>
              <Grid item lg={12} md={12} sm={12} mt={1} container>
                <Grid item lg={12} md={12} sm={12}>
                  <ListItem>
                    <h6>{props.data ? props.data.commented_user_name : ""}</h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p>
                      {(function createdDate() {
                        let created = props.data.comment_created_at;
                        let dateConversion = new Date(created);
                        dateConversion =
                          dateConversion.toDateString() +
                          ", " +
                          dateConversion.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          });

                        return dateConversion;
                      })()}{" "}
                    </p>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <ListItem>
                  <Grid item lg={12} md={12} sm={12}>
                    <Typography>
                      <b>Content :</b>
                      &nbsp;
                      {props.data ? props.data.comment_content : ""}
                    </Typography>
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item lg={7} md={12} sm={12} mt={1} display="flex">
                {/* <Grid item lg={3} md={4} sm={5} mt={1}>
                  <ListItem>
                    <FavoriteIcon />
                    <span>Like</span>&nbsp;&nbsp;
                    <span>{0}</span>
                  </ListItem>
                </Grid> */}
                <Grid item lg={3} md={4} sm={5} mt={1}>
                  <ListItem>
                    <TextsmsIcon />
                    <span>Replies</span>&nbsp;&nbsp;
                    <span>{props.data ? props.data.replies.length : ""}</span>
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </List>
  );
};
