import BaseReducer from "../../../../utilities/BaseReducer";
import QuestionsAction from "./QuestionsAction";

export default class QuestionFilterReducer extends BaseReducer {
  initialState = {
    questionsFilter: [],
  };

  [QuestionsAction.REQUEST_QUESTIONS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      questionsFilter: action.payload,
    };
  }
}
