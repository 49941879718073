import BaseReducer from "../../../utilities/BaseReducer";
import ResponseMessageAction from "./ResponseMessageAction";

// reducer function
export default class ResponseMessageFilterReducer extends BaseReducer {
  initialState = {
    responseMessageFilter: [],
  };

  [ResponseMessageAction.REQUEST_RESPONSE_MESSAGE_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      responseMessageFilter: action.payload,
    };
  }
}
