import BaseReducer from "../../../utilities/BaseReducer";
import ProjectAction from "./projectAction";

// reducer function
export default class ProjectFilterReducer extends BaseReducer {
  initialState = {
    projectfilter: [],
  };

  [ProjectAction.REQUEST_PROJECT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      projectfilter: action.payload,
    };
  }
}
