import BaseReducer from "../../../../utilities/BaseReducer";
import RevenueClaimAction from "./RevenueClaimAction";

export default class ClientRevenueBalanceReducer extends BaseReducer {
  initialState = {
    clientRevenueBalance: [],
  };
  [RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE_FINISHED](state, action) {
    return {
      ...state,
      clientRevenueBalance: action.payload,
    };
  }
}
