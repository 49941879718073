import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Description from "./descriptionContainer/Description";
import {
  Box,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const Platformwisetop10categories = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (dateRange, platform, sentiment) => {
    const advanced_filters = {
      //   platforms: ["4", "9"],
      //   sentiments_values: [
      //     "positive_sentiment",
      //     "negative_sentiment",
      //     "neutral_sentiment",
      //   ],
      top_category_value: 10,
    };

    if (platform?.length) {
      advanced_filters["platforms"] = platform?.map(
        (item) => item?.platform_id
      );
    }

    if (sentiment?.length) {
      advanced_filters["sentiments_values"] = sentiment?.map(
        (item) => item?.sentiment
      );
    }

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform_wise_top_categories"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }&advanced_filters=${JSON.stringify(advanced_filters)}`;

    try {
      const response = await axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      const result = await fetchData(props.DateFilter);
      setData(result);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        const result = await fetchData(props.DateFilter);
        setData(result);
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (
      props.filterClicked &&
      ((props.openDateFilter &&
        props.openDateFilter[0] &&
        props.openDateFilter[1]) ||
        props.platform_selected?.length ||
        props.sentiment_selected?.length)
    ) {
      (async function CallApi() {
        setLoading(true);

        const result = await fetchData(
          props.openDateFilter &&
            props.openDateFilter[0] &&
            props.openDateFilter[1]
            ? props.openDateFilter
            : null,
          props.platform_selected,
          props.sentiment_selected
        );
        setData(result);
        setLoading(false);
        props.setFilterClicked(false);
      })();
    } else {
      if (props.filterClicked) {
        alert("Please Select Proper Filters");
        props.setFilterClicked(false);
      }
    }
  }, [
    props.openDateFilter,
    props.filterClicked,
    props.platform_selected,
    props.sentiment_selected,
  ]);

  function returnPlatformName(platformID) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformID)
        .map((item) => item.platform_alias)
    );
  }

  const rows = [];

  if (
    data &&
    data.ticket_report &&
    data.ticket_report.platform_wise_top_categories
  ) {
    data.ticket_report.platform_wise_top_categories.forEach((item, index) => {
      const platformName = returnPlatformName(item?.platform)?.[0];
      if (item && platformName) {
        const row = {
          id: index + 1, // Ensure a unique id for each row
          platform: platformName,
          total: item?.platform_doc_count,
        };
        rows.push(row);
      }
    });
  }

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  const columns = [
    {
      field: "platform",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <p
          className={
            params.row.platform === "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }
        >
          {params.row.platform}
        </p>
      ),
    },
  ];

  const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))(({ theme, minHeight }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: "5px",
      minWidth: "350px",
      minHeight: minHeight,
      // boxShadow: "8px 10px 10px rgba(0, 0, 0, 0.4)",
      padding: "15px",
    },
    // [`& .${tooltipClasses.arrow}`]: {
    //   color: theme.palette.primary.main,
    // },
  }));

  // Dynamically add top1 to top10 columns
  for (let i = 1; i <= 10; i++) {
    columns.push({
      field: `top${i}`,
      headerName: `${i}`,
      resizable: true,
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <CustomToolTip title={params?.row[`top${i}`]}>
            <Typography
              sx={{
                cursor: "pointer",
              }}
            >
              {params?.row[`top${i}`]}
            </Typography>
          </CustomToolTip>
        );
      },
    });
  }

  columns.push({
    field: `total`,
    headerName: `Total`,
    resizable: true,
    flex: 1,
    minWidth: 100,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <Typography className={"font-weight-bold p-0 m-0"}>
          {params?.formattedValue}
        </Typography>
      );
    },
  });

  const rowEntries = [];
  data?.ticket_report?.platform_wise_top_categories?.forEach((item, index) => {
    const row = {
      id: index + 1, // Ensure each row has a unique id
      platform:
        item?.platform === "total"
          ? "Total"
          : returnPlatformName(item?.platform)?.[0] || "Unknown Platform",
      total: item.platform_doc_count
        ? prefixZero_for_singleDigit(item.platform_doc_count)
        : "00",
    };

    // Add top 10 category values to the row
    const topCategories = item.categories?.[0]?.top_level_category || [];
    topCategories.slice(0, 10).forEach((category, i) => {
      row[
        `top${i + 1}`
      ] = `${category.category_key} (${category.category_doc_count})`;
    });

    // Fill the remaining slots with '--' if fewer than 10 categories are present
    for (let i = topCategories.length; i < 10; i++) {
      row[`top${i + 1}`] = "--";
    }

    rowEntries.push(row);
  });

  useEffect(() => {
    if (props.DownloadClicked) {
      const newArray = rowEntries.map(({ id, total, ...rest }) => ({
        ...rest,
        total,
      }));

      props.setexcelData(newArray);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rowEntries]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading && props.dataGridHeight ? (
        <div
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: theme.table.borderRadius,
          }}
        >
          {props.dataGridHeight.current && (
            <DataGridFunction
              rows={rowEntries}
              columns={columns}
              rowBorder={false}
              height={props.dataGridHeight.current}
              rowHeight={50}
              headerHeight={80}
              onCellClick={onCellClick}
              rowColor={true}
              headerColor={true}
              getRowId={(row) => row.id} // Ensure custom row ID assignment
            />
          )}
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
};

export default Platformwisetop10categories;
