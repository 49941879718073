import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import EmailAccountDeleteModel from "./model/EmailAccountDeleteModel";
import EmailAccountModel from "./model/EmailAccountModel";
import EmailAccountPostModel from "./model/EmailAccountPostModel";
import EmailAccountPutModel from "./model/EmailAccountPutModel";

// EmailAccountEffect
export default class EmailAccountEffect {
  // calling an API according to the Email Account model
  // Get method for the Email account
  static async requestEmailAccount(params) {
    const endpoint = environment.api.emailAccount;
    return EffectUtility.getToModel(EmailAccountModel, endpoint, params);
  }

  // Get method for the Email account filter
  static async requestEmailAccountFilter(params) {
    const endpoint = environment.api.emailAccountFilter;
    return EffectUtility.getToModel(EmailAccountModel, endpoint, params);
  }

  // put method for the Email account filter
  static async requestPutEmailAccount(data, id) {
    const endpoint = environment.api.emailAccount + "/" + id;
    return EffectUtility.putToModel(EmailAccountPutModel, endpoint, data);
  }

  // post method for the Email account filter
  static async requestPostEmailAccount(data) {
    const endpoint = environment.api.emailAccount + "/add";

    return EffectUtility.postToModel(EmailAccountPostModel, endpoint, data);
  }

  // DELETE API
  static async requestDeleteEmailAccount(id) {
    const endpoint = environment.api.emailAccount + "/" + id;

    return EffectUtility.deleteToModel(EmailAccountDeleteModel, endpoint);
  }
}
