import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import {
  BorderContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../../components/componentLayout/ComponentLayout";
import CircularLoadingIndicator from "../../../../../components/loading-indicator/CircularLoadingIndicator";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { returnSentimentColor } from "../../../../../components/ticketPartials";
import MuiAutocomplete from "../../../../../components/muiSelectField/MuiAutocomplete";

function SentimentFilterPopper(props) {
  let { isRequesting } = props;

  const [sentiment_value, set_sentiment_value] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters"))
        .sentiment_value;
    } else {
      return null;
    }
  });

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    if (filters) {
      set_sentiment_value(filters.sentiment_value && filters.sentiment_value);
    }
  }, [props.open]);

  // Sentimentvalue to callback function
  const sentiment_value_handleChange = (event, value) => {
    set_sentiment_value(value);
  };

  let [sentimentError, setsentimentError] = useState(false);

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    if (!sentiment_value) {
      setsentimentError(true);

      setTimeout(() => {
        setsentimentError(false);
      }, 1000);
    } else {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

      if (filters) {
        filters.sentiment_value = sentiment_value;
        filters.pageNumber = `0`;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            sentiment_value,
          })
        );
      }

      props.saveFilter(filters);
      props.fetchTicketCount();
    }
  }

  function handleClearFilters() {
    if (sentiment_value !== null) {
      set_sentiment_value(null);

      props.clearFilter("sentiment");

      localStorage.removeItem("mentionsFilters");
    }
  }

  return (
    <Box>
      <BorderContainer>
        <ClickAwayListener onClickAway={props.handleClose}>
          <div
            autoFocusItem={props.popopen}
            id="composition-menu"
            aria-labelledby="composition-button"
          >
            <Grid
              container
              // className="bg-warning"
              sx={{
                height: 250,
              }}
            >
              <Typography
                component={"p"}
                className="dashboard-title"
                sx={{ fontSize: 18 }}
              >
                {"Revised Sentiment".split(" ").map((item) => (
                  <span className="dashboard-title">{item}</span>
                ))}
              </Typography>

              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xl={3}
                  sx={{
                    animation: sentimentError && "bounce 0.5s linear",
                    "@keyframes bounce": {
                      "0%, 20%, 50%, 80%, 100%": {
                        transform: "translateY(0)",
                      },
                      "40%": {
                        transform: "translateY(-30px)",
                      },
                      "60%": {
                        transform: "translateY(-15px)",
                      },
                    },
                  }}
                >
                  <AutoCompleteComponent
                    value={sentiment_value}
                    list={props.sentiment_value_list}
                    size="small"
                    textFieldProps={{
                      placeholder: "Sentiment Value",
                    }}
                    renderOption={(props, option, { selected }) => (
                      <RadioGroup>
                        <FormControlLabel
                          {...props}
                          control={<Radio checked={selected} size="small" />}
                          label={option}
                        />
                      </RadioGroup>
                    )}
                    disableClearable={true}
                    getOptionLabel={(option) => (option ? option : "")}
                    handleChange={sentiment_value_handleChange}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginY: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item>
                  <MuiButton
                    variant="outlined"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Reset"}
                    loading={false}
                    backgroundColor={"white"}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleClearFilters();
                    }}
                  />
                </Grid>
                <Grid item>
                  <MuiButton
                    variant="contained"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Apply"}
                    loading={false}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleSaveFilters();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      </BorderContainer>
    </Box>
  );
}

export default SentimentFilterPopper;
