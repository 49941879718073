import ActionUtility from "../../../../utilities/ActionUtility";
import BatchLinksEffect from "./BatchLinksEffect";

export default class BatchLinksAction {
  // BatchLinksAction action with an function declaration
  static REQUEST_BATCH_LINKS = "BatchLinksAction.REQUEST_BATCH_LINKS";
  static REQUEST_BATCH_LINKS_FINISHED =
    "BatchLinksAction.REQUEST_BATCH_LINKS_FINISHED";

  static REQUEST_BATCH_LINKS_FILTER =
    "BatchLinksAction.REQUEST_BATCH_LINKS_FILTER";
  static REQUEST_BATCH_LINKS_FILTER_FINISHED =
    "BatchLinksAction.REQUEST_BATCH_LINKS_FILTER_FINISHED";

  static REQUEST_PUT_BATCH_LINKS = "BatchLinksAction.REQUEST_PUT_BATCH_LINKS";
  static REQUEST_PUT_BATCH_LINKS_FINISHED =
    "BatchLinksAction.REQUEST_PUT_BATCH_LINKS_FINISHED";

  static REQUEST_POST_BATCH_LINKS = "BatchLinksAction.REQUEST_POST_BATCH_LINKS";
  static REQUEST_POST_BATCH_LINKS_FINISHED =
    "BatchLinksAction.REQUEST_POST_BATCH_LINKS_FINISHED";

  static REQUEST_BATCH_LINKS_CLEAR =
    "BatchLinksAction.REQUEST_BATCH_LINKS_CLEAR";
  static REQUEST_BATCH_LINKS_CLEAR_FINISHED =
    "BatchLinksAction.REQUEST_BATCH_LINKS_CLEAR_FINISHED";

  static REQUEST_BATCH_LINKS_FILTER_CLEAR =
    "BatchLinksAction.REQUEST_BATCH_LINKS_FILTER_CLEAR";
  static REQUEST_BATCH_LINKS_FILTER_CLEAR_FINISHED =
    "BatchLinksAction.REQUEST_BATCH_LINKS_FILTER_CLEAR_FINISHED";

  static REQUEST_POST_UPLOAD_LINKS =
    "BatchLinksAction.REQUEST_POST_UPLOAD_LINKS";
  static REQUEST_POST_UPLOAD_LINKS_FINISHED =
    "BatchLinksAction.REQUEST_POST_UPLOAD_LINKS_FINISHED";

  static REQUEST_BATCH_LINKS_STATUS_REPORT_BY_ID =
    "BatchLinksAction.REQUEST_BATCH_LINKS_STATUS_REPORT_BY_ID";
  static REQUEST_BATCH_LINKS_STATUS_REPORT_BY_ID_FINISHED =
    "BatchLinksAction.REQUEST_BATCH_LINKS_STATUS_REPORT_BY_ID_FINISHED";

  // METHODS
  // BATCH_LINKS GET function
  static requestBatchLinks(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_BATCH_LINKS,
        BatchLinksEffect.requestBatchLinks,
        params
      );
    };
  }

  static requestBatchLinksClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_BATCH_LINKS_CLEAR,
        BatchLinksEffect.requestBatchLinksClear
      );
    };
  }

  static requestBatchLinksFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_BATCH_LINKS_FILTER_CLEAR,
        BatchLinksEffect.requestBatchLinksFilterClear
      );
    };
  }

  static requestBatchLinksFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_BATCH_LINKS_FILTER,
        BatchLinksEffect.requestBatchLinksFilter,
        params
      );
    };
  }
  static requestPutBatchLinks(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_PUT_BATCH_LINKS,
        BatchLinksEffect.requestPutBatchLinks,
        data,
        id
      );
    };
  }

  static requestPostBatchLinks(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_POST_BATCH_LINKS,
        BatchLinksEffect.requestPostBatchLinks,
        data,
        params,
        callBack
      );
    };
  }

  static requestPostUploadLinks(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_POST_UPLOAD_LINKS,
        BatchLinksEffect.requestPostUploadLinks,
        data,
        params,
        callBack
      );
    };
  }

  static requestBatchLinksStatusReportById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchLinksAction.REQUEST_BATCH_LINKS_STATUS_REPORT_BY_ID,
        BatchLinksEffect.requestBatchLinksStatusReportById,
        id
      );
    };
  }
}
