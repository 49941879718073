import { BaseModel } from "sjs-base-model";
import FacebookDmSourceModel from "./FacebookDmSourceModel";

export default class FacebookDmPostModel extends BaseModel {
  data = { FacebookDmSourceModel };
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
