import { BaseModel } from "sjs-base-model";

// category data hits Model
export default class CustomTicketFieldsSourceModel extends BaseModel {
  // response getting from the api

  created_by = "";
  created_on = "";
  field_alias = "";
  field_name = "";
  field_type = "";
  field_values = [];
  has_parent_field = Boolean;
  modified_by = null;
  modified_on = "";
  parent_field = { parent_field_id: "", parent_field_name: "" };
  project = { project_id: " ", project_name: " " };

  constructor(data) {
    super();

    this.update(data);
  }
}
