import BaseReducer from "../../../../utilities/BaseReducer";
import OpportunityAction from "./OpportunityAction";

export default class OpportunityFilterReducer extends BaseReducer {
  //initial state of OpportunityFilterReduser
  initialState = {
    Opportunity_filter: [],
  };
  //OpportunityFilterReduser request action finish
  [OpportunityAction.REQUEST_OPPORTUNITY_FILTER_FINISHED](state, action) {
    return {
      ...state,
      Opportunity_filter: action.payload,
    };
  }

  [OpportunityAction.REQUEST_OPPORTUNITY_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Opportunity_filter: action.payload,
    };
  }
}
