/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Divider,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { format } from "date-fns";
import Avatar from "@mui/material/Avatar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { connect } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import environment from "environment";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import InstagramFeedsAction from "../../../stores/instagramFeeds/InstagramFeedsAction";
import InstagramAccountAction from "../../../stores/InstagramAccount/InstagramAccountAction";
import { axiosRequest } from "../../../../utilities/FetchRequest";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS,
    InstagramFeedsAction.REQUEST_INSTAGRAM_FILTER,
    InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT,
  ]),
  instagramFeeds: state.instagramFeeds.instagramFeeds || [],
  instagramFilter: state.instagramFilter.instagramFilter || [],
  instagramAccount: state.instagramAccount.instagramAccount || [],
  pagination: state.instagramFeeds.instagramFeeds || [],
});

function InstagramFeedsMain(props) {
  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number" &&
      item._source.sentiment_value > 0
    ) {
      sentiment = "Positive";
    } else if (item._source.sentiment_value < 0) {
      sentiment = "Negative";
    } else if (item._source.sentiment_value !== null) {
      sentiment = "Neutral";
    }

    return sentiment;
  };

  const [show, setShow] = useState(false);
  const [showMoreData, setShowMoreData] = useState({});
  const [date, setDate] = useState([null, null]);
  const [activity, setActivity] = useState("");
  const [accountName, setAccountName] = useState("");
  const [value] = React.useState("1");
  const [page_limit] = useState(10);
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [customerName, setCustomerName] = React.useState(null);

  const ActivitiesList = [
    "All",
    "Comments",
    "Direct Message",
    "Comment Mentions",
    "Captions Mention",
  ];

  function fetchInsta(params) {
    props.dispatch(InstagramFeedsAction.requestInstagramFeeds(params));
  }

  function fetch_instagram_filter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(InstagramFeedsAction.requestInstagramFilter(params));
  }

  function fetch_instagram_Account_filter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(InstagramAccountAction.requestInstagramAccount(params));
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = (panel) => {
    setExpanded((prevExpanded) => (prevExpanded === panel ? false : panel));
  };

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetch_instagram_filter();
    fetch_instagram_Account_filter();

    const filter = [];
    let params = {};

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (activity !== "" && activity !== "All") {
      filter.push(`["activities.keyword", "must", "", "match", "${activity}"]`);
    } else if (activity !== "" && activity === "All") {
    }

    if (accountName !== "") {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"],`);
      }
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      accountName !== "" ||
      activity !== "" ||
      sentimental_val !== null ||
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      const params = {
        filters: `[${filter}]`,
        page_limit: `${page_limit}`,
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
      };
      fetchInsta(params);
    } else {
      params = {
        page_limit: `${page_limit}`,
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
      };
      fetchInsta(params);
    }
  }, []);

  const activityHandleChange = (event, value) => {
    if (value != null) {
      setActivity(value);
    } else {
      setActivity("");
    }
  };

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName("");
    }
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val("");
    }
  };

  //  HANDLE FILTER
  const handleFilter = () => {
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (activity !== "" && activity !== "All") {
      filter.push(`["activities.keyword", "must", "", "match", "${activity}"]`);
    } else if (activity !== "" && activity === "All") {
    }

    if (accountName !== "") {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"],`);
      }
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      accountName !== "" ||
      activity !== "" ||
      sentimental_val !== null ||
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      const params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
        page_limit: `${page_limit}`,
      };
      fetchInsta(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
    }
  };

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  //  HANDLE FILTER
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    let params = {};
    if (activity !== "" && activity !== "All") {
      filter.push(`["activities.keyword", "must", "", "match", "${activity}"]`);
    } else if (activity !== "" && activity === "All") {
    }
    if (accountName !== "") {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"],`);
      }
    }

    if (FilterClicked) {
      if (
        accountName !== "" ||
        activity !== "" ||
        searchQuery !== "" ||
        sentimental_val !== null ||
        customerName !== null ||
        (date[0] !== null && date[1] !== null)
      ) {
        const params = {
          filters: `[${filter}]`,
          page_limit: `${page_limit}`,
          page_number: `${value}` - 1,
          order_by: '[["created_on", "desc"]]',
        };
        fetchInsta(params);
      }
    } else {
      params = {
        page_limit: `${page_limit}`,
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
      };
      if (value - 1 !== props.pagination.current_page_no) {
        fetchInsta(params);
      }
    }
  };

  const ClearState = () => {
    setFilterClicked(false);
    if (
      accountName !== "" ||
      activity !== "" ||
      sentimental_val !== null ||
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      setAccountName("");
      setActivity("");
      setDate([null, null]);
      setSearchQuery("");
      setSentimental_val(null);
      setCustomerName(null);
      setClearLoader(true);

      setShow(false);
      const params = {
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
        page_limit: `${page_limit}`,
      };
      FilterClicked && fetchInsta(params);
    }
  };

  // ********************************************** useEffect

  const list = [];

  if (props.instagramAccount.hits) {
    props.instagramAccount.hits.hits.map((value) => {
      list.push(value._source.account_name);
    });
  }

  const CustomerHandleChange = (e, val) => {
    setCustomerName(val);
  };

  //Customer names list assigning
  let customerList = [];

  if (props.instagramFilter.hits) {
    props.instagramFilter.hits.hits.forEach((val) => {
      if (val._source.customer_name) {
        customerList.push(val._source.customer_name);
      }
    });
  }
  const uniqueCustomerList = [...new Set(customerList)];

  const uniqueList = [...new Set(list)];

  let maxDate = new Date();

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);

    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        mt={2}
        sx={{
          backgroundColor: "#f3f9fb",
          height: "auto",
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
        }}
      >
        <Grid
          container
          sx={{
            margin: 0,
            height: "auto",
            padding: 0,
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xl={1.6} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Account Name</span>
              </Grid>
              <AutoCompleteComponent
                value={accountName}
                list={uniqueList}
                handleChange={accountNameHandleChange}
                textFieldProps={{
                  placeholder: "Select Account",
                }}
                size="small"
                width="100%"
              />
            </Grid>

            <Grid item xl={1.6} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Search Content</span>
              </Grid>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius:
                      theme.palette.buttonComponent.borderRadius.medium,
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    padding: theme.typography.padding,
                    height: "35px",
                    width: "100%",
                  }}
                  id="search_bar"
                  autoComplete={"off"}
                  value={searchQuery}
                  placeholder={"Search by Content"}
                  onInput={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  size="small"
                  startAdornment={
                    <InputAdornment>
                      <IconButton onClick={handleFilter}>
                        <SearchIcon
                          sx={{
                            "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xl={1.6} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Customer Name</span>
              </Grid>
              <AutoCompleteComponent
                value={customerName}
                list={uniqueCustomerList}
                textFieldProps={{
                  placeholder: "Customer Name",
                }}
                handleChange={CustomerHandleChange}
                size="small"
                width="100%"
              />
            </Grid>

            <Grid item xl={1.6} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Activities</span>
              </Grid>
              <AutoCompleteComponent
                list={ActivitiesList}
                value={activity}
                textFieldProps={{
                  placeholder: "Select Option",
                }}
                handleChange={activityHandleChange}
                size="small"
                width="100%"
              />
            </Grid>

            <Grid item xl={1.6} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Date Filter</span>
              </Grid>

              <DatePicker
                value={date}
                onChange={dateValueHandleChange}
                maxDate={maxDate}
              />
            </Grid>
            <Grid item xl={1.6} lg={3} md={6} sm={12} mt={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                <span>Sentiment value</span>
              </Grid>
              <AutoCompleteComponent
                // ***************************************************Customer name filter
                value={sentimental_val}
                list={sentimental_val_list}
                textFieldProps={{
                  placeholder: "Sentimental Value",
                }}
                handleChange={sentimentalvalHandleChange}
                size="small"
                width="100%"
              />
            </Grid>

            <Grid item xl={2} lg={3} md={12} sm={12} mt={0}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={2.5}
              >
                <Grid item xl={3.5} lg={6} md={3} sm={5}>
                  <MuiButton
                    width="100%"
                    loading={filterLoader && isRequesting}
                    name={"Apply"}
                    onClick={handleFilter}
                  />
                </Grid>
                <Grid item xl={3.5} lg={6} md={3} sm={5}>
                  <MuiButton
                    width="100%"
                    loading={clearLoader && isRequesting}
                    name={"Clear"}
                    onClick={ClearState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <hr style={{ marginTop: 18 }} />

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
          // className="bg-primary"
        >
          {/* <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Reviews</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid> */}

          {props.instagramFeeds.hits ? (
            <LoadingIndicator isActive={isRequesting}>
              {props.instagramFeeds.hits.hits.length !== 0 ? (
                props.instagramFeeds.hits.hits.map(
                  (item, index) =>
                    !isRequesting && (
                      <Box
                        sx={{
                          padding: 0,
                          margin: "1.2%",
                          marginTop: 2,
                          marginBottom: 2,
                          borderRadius: theme.borderRadius,
                        }}
                        // className="bg-warning"
                      >
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          // onChange={handleChangeExpand(`panel${index}`)}
                          style={{
                            backgroundColor: "#fff",
                            height: "auto",
                            padding: 2,
                            borderRadius: theme.borderRadius,
                            border: "solid 0.5px #d3e2e6",
                            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                            cursor:
                              item && item._source.ticket_id
                                ? "pointer"
                                : "default",
                          }}
                          // className="bg-warning"
                        >
                          <AccordionSummary
                            style={{
                              cursor:
                                item && item._source.ticket_id
                                  ? "pointer"
                                  : "default",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleChangeExpand(`panel${index}`)
                                }
                              />
                            }
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                            onClick={() => {
                              localStorage.setItem("activeTab", 0);

                              item._source &&
                                item._source.ticket_id &&
                                openTicketFunction(item._source.ticket_id);
                            }}
                            // className="bg-success"
                          >
                            <List
                              sx={{
                                width: "100%",
                              }}
                              // component="nav"
                            >
                              <Grid
                                container
                                // className="bg-warning"
                              >
                                <Grid
                                  container
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  // className="bg-warning"
                                >
                                  <Grid item lg={12} md={12} sm={12} container>
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      container
                                    >
                                      <Grid
                                        item
                                        xl={12}
                                        lg={6}
                                        md={12}
                                        sm={12}
                                        // className="bg-warning"
                                      >
                                        <ListItem>
                                          <Grid
                                            container
                                            lg={12}
                                            xl={12}
                                            md={12}
                                            sm={12}
                                          >
                                            <Grid
                                              lg={10}
                                              xl={10}
                                              md={10}
                                              sm={10}
                                              container
                                              item
                                              alignItems={"flex-start"}
                                              justifyContent="flex-start"
                                            >
                                              <Grid
                                                item
                                                xl={1}
                                                lg={1}
                                                md={1}
                                                sm={1}
                                                xs={1}
                                              >
                                                <ListItemAvatar>
                                                  <Avatar></Avatar>
                                                </ListItemAvatar>
                                              </Grid>
                                              <Grid
                                                item
                                                container
                                                xl={10}
                                                lg={10}
                                                md={10}
                                                sm={10}
                                                xs={10}
                                              >
                                                <Grid
                                                  xl={12}
                                                  md={12}
                                                  sm={12}
                                                  lg={12}
                                                >
                                                  <span className="font-weight-bold">
                                                    {item._source.customer_name}{" "}
                                                  </span>{" "}
                                                  <span className="mx-2">
                                                    {" "}
                                                    {(function createdDate() {
                                                      let created =
                                                        item._source.created_on;
                                                      let dateConversion =
                                                        new Date(created);
                                                      dateConversion =
                                                        dateConversion.toDateString() +
                                                        ", " +
                                                        dateConversion.toLocaleTimeString(
                                                          "en-US",
                                                          {
                                                            hour: "numeric",
                                                            minute: "numeric",
                                                            hour12: true,
                                                          }
                                                        );

                                                      return dateConversion;
                                                    })()}
                                                  </span>
                                                </Grid>

                                                <Grid
                                                  item
                                                  container
                                                  xl={12}
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  alignItems="center"
                                                  justifyContent={"flex-start"}
                                                  mt={1}
                                                  spacing={1}
                                                >
                                                  {item._source
                                                    .sentiment_value !==
                                                    null && (
                                                    <Grid
                                                      item
                                                      // lg={2}
                                                      // md={12}
                                                      // sm={12}
                                                      textAlign="left"
                                                    >
                                                      <ListItem className="p-0 m-0">
                                                        <h6
                                                          align="left"
                                                          className="p-0 m-0"
                                                        >
                                                          Sentiment Value :
                                                        </h6>
                                                        &nbsp;
                                                        {item._source
                                                          .sentiment_value !==
                                                          null &&
                                                        item._source
                                                          .sentiment_value >
                                                          0 ? (
                                                          <IconButton
                                                            className="p-0 m-0"
                                                            title={returnSentimentname(
                                                              item
                                                            )}
                                                          >
                                                            <SentimentSatisfiedAltRoundedIcon
                                                              titleAccess="Positive"
                                                              style={{
                                                                width: "16px",
                                                                color: `green`,
                                                              }}
                                                            />
                                                          </IconButton>
                                                        ) : item._source
                                                            .sentiment_value <
                                                          0 ? (
                                                          <IconButton
                                                            className="p-0 m-0"
                                                            title={returnSentimentname(
                                                              item
                                                            )}
                                                          >
                                                            <SentimentDissatisfiedRoundedIcon
                                                              titleAccess="Negative"
                                                              style={{
                                                                width: "16px",
                                                                color: `${theme.palette.sentimentIconColor.negative}`,
                                                              }}
                                                            />
                                                          </IconButton>
                                                        ) : item._source
                                                            .sentiment_value !==
                                                          null ? (
                                                          <IconButton
                                                            className="p-0 m-0"
                                                            title={returnSentimentname(
                                                              item
                                                            )}
                                                          >
                                                            <SentimentNeutralRoundedIcon
                                                              titleAccess="Neutral"
                                                              style={{
                                                                width: "16px",
                                                                color: `${theme.palette.sentimentIconColor.neutral}`,
                                                              }}
                                                            />
                                                          </IconButton>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </ListItem>
                                                    </Grid>
                                                  )}

                                                  <Grid
                                                    item
                                                    // lg={2}
                                                    // md={12}
                                                    // sm={12}
                                                    textAlign="left"
                                                  >
                                                    <ListItem className="p-0 m-0">
                                                      <h6
                                                        align="left"
                                                        className="p-0 m-0"
                                                      >
                                                        Account Name :
                                                      </h6>
                                                      &nbsp;
                                                      {
                                                        item._source
                                                          .account_name
                                                      }
                                                    </ListItem>
                                                  </Grid>

                                                  {(item._source.activities ===
                                                    "Comment Mentions" ||
                                                    item._source.activities ===
                                                      "Comments") && (
                                                    <Grid
                                                      item
                                                      lg={12}
                                                      md={12}
                                                      sm={12}
                                                    >
                                                      <ListItem className="p-0 m-0">
                                                        <h6 className="p-0 m-0">
                                                          Comment Content :
                                                        </h6>
                                                        &nbsp;
                                                        {item._source
                                                          .comment_content ? (
                                                          item._source
                                                            .comment_content
                                                            .length >= 80 ? (
                                                            <div>
                                                              {item._source.comment_content.slice(
                                                                0,
                                                                75
                                                              )}
                                                              ... &nbsp;
                                                            </div>
                                                          ) : (
                                                            item._source
                                                              .comment_content
                                                          )
                                                        ) : null}
                                                      </ListItem>
                                                    </Grid>
                                                  )}

                                                  {item._source.activities ===
                                                    "Captions Mention" && (
                                                    <Grid
                                                      item
                                                      lg={12}
                                                      md={12}
                                                      sm={12}
                                                    >
                                                      <ListItem className="p-0 m-0">
                                                        <h6 className="p-0 m-0">
                                                          Caption Content :
                                                        </h6>
                                                        &nbsp;
                                                        {item._source
                                                          .parent_post_caption ? (
                                                          item._source
                                                            .parent_post_caption
                                                            .length >= 80 ? (
                                                            <div>
                                                              {item._source.parent_post_caption.slice(
                                                                0,
                                                                75
                                                              )}
                                                              ... &nbsp;
                                                            </div>
                                                          ) : (
                                                            item._source
                                                              .parent_post_caption
                                                          )
                                                        ) : null}
                                                      </ListItem>
                                                    </Grid>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>

                                          <Grid
                                            item
                                            container
                                            lg={1}
                                            md={2}
                                            sm={2}
                                            alignItems={"flex-start"}
                                            justifyContent="space-between"
                                          >
                                            {item._source.feed_link && (
                                              <Grid item>
                                                <Typography
                                                  component={"a"}
                                                  href={
                                                    item._source.feed_link
                                                      ? item._source.feed_link
                                                      : ""
                                                  }
                                                  target="_blank"
                                                  title="Feed Link"
                                                >
                                                  <OpenInNewIcon
                                                    fontSize="25px"
                                                    sx={{ cursor: "pointer" }}
                                                  />
                                                </Typography>
                                              </Grid>
                                            )}

                                            {circuler_progress_id ===
                                              item._source.ticket_id &&
                                              open_ticket && (
                                                <CircularProgress
                                                  color="inherit"
                                                  size={15}
                                                  // className="bg-light"
                                                />
                                              )}
                                          </Grid>
                                        </ListItem>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </List>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box
                              sx={{
                                padding: 1,
                                margin: "1.2%",
                                marginTop: 0,
                                marginBottom: "0.5%",
                                border: "solid 0.5px #d3e2e6",
                                borderRadius: theme.borderRadius,
                                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                              }}
                            >
                              <Grid container>
                                <Grid item pr={5}>
                                  <ListItem>
                                    <FavoriteIcon />
                                    <span>Like</span>&nbsp;&nbsp;
                                    <span>
                                      {item._source.parent_post_like_count}
                                    </span>
                                  </ListItem>
                                </Grid>
                                <Grid item pr={5}>
                                  <ListItem>
                                    <TextsmsIcon />
                                    <span>Reply</span>&nbsp;&nbsp;
                                    {item._source.replies_for_comment
                                      ? item._source.replies_for_comment.length
                                      : 0}
                                  </ListItem>
                                </Grid>
                              </Grid>

                              <Grid item m={2}>
                                <Typography className="text-justify">
                                  <span className="font-weight-bold">
                                    Detailed Content
                                  </span>{" "}
                                  :{" "}
                                  {item._source.comment_content ||
                                    item._source.parent_post_caption}
                                </Typography>
                              </Grid>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                )
              ) : (
                <NoDataFound />
              )}
            </LoadingIndicator>
          ) : (
            <NoDataFound />
          )}
        </Box>
      </Grid>
      {props.instagramFeeds.hits
        ? props.instagramFeeds.hits.hits.length !== 0 &&
          !isRequesting && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChangePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(InstagramFeedsMain);

const ShowMore = (props) => {
  return (
    <List
      sx={{
        width: "100%",
      }}
      component="nav"
    >
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        sx={{
          backgroundColor: "#fff",
          height: "auto",
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
        }}
      >
        <Grid container lg={12} md={12} sm={12}>
          <Grid item lg={1.5} md={1.5} sm={1.5} p={1} alignSelf="center">
            <ListItem>
              <ListItemAvatar>
                <Avatar></Avatar>
              </ListItemAvatar>
            </ListItem>
          </Grid>
          <Grid item lg={10.5} md={10.5} sm={10.5} container>
            <Grid item lg={12} md={12} sm={12} container>
              <Grid item lg={12} md={12} sm={12} mt={1} container>
                <Grid item lg={12} md={12} sm={12}>
                  <ListItem>
                    <h6 className="p-0 m-0">
                      {props.data ? props.data._source.account_name : ""}
                    </h6>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p>
                      {(function createdDate() {
                        let created = props.data._source.comment_created_time;
                        let dateConversion = new Date(created);
                        dateConversion =
                          dateConversion.toDateString() +
                          ", " +
                          dateConversion.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          });

                        return dateConversion;
                      })()}
                    </p>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <ListItem>
                  <Grid item lg={12} md={12} sm={12}>
                    <Typography>
                      <b>Content :</b>
                      &nbsp;
                      {props.data ? props.data._source.comment_content : ""}
                    </Typography>
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item lg={7} md={12} sm={12} mt={1} display="flex">
                <Grid item lg={3} md={4} sm={5} mt={1}>
                  <ListItem>
                    <FavoriteIcon />
                    <span>Like</span>&nbsp;&nbsp;
                    <span>
                      {props.data
                        ? props.data._source.parent_post_like_count
                        : 0}
                    </span>
                  </ListItem>
                </Grid>
                <Grid item lg={3} md={4} sm={5} mt={1}>
                  <ListItem>
                    <TextsmsIcon />
                    <span>Reply</span>&nbsp;&nbsp;
                    {props.data
                      ? props.data._source.replies_for_comment.length
                      : 0}
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </List>
  );
};
