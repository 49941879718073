import BaseReducer from "../../../../utilities/BaseReducer";
import TopLyricistAction from "./TopLyricistAction";

// TopLyricistReduser
export default class TopLyricistReducer extends BaseReducer {
  initialState = {
    top_lyricists_report: [],
  };
  [TopLyricistAction.REQUEST_TOP_LYRICIST_FINISHED](state, action) {
    return {
      ...state,
      top_lyricists_report: action.payload,
    };
  }
}
