import { BaseModel } from "sjs-base-model";
import AmbitionBoxAccountDataHitsModel from "./AmbitionBoxAccountDataHitsModel";
//AmbitionBoxAccountHitsModel
export default class AmbitionBoxAccountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ AmbitionBoxAccountDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
