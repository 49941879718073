import { BaseModel } from "sjs-base-model";
import InfluencersDataModel from "./InfluencersDataModel";

// InfluencersDataHitsModel
export default class InfluencersDataHitsModel extends BaseModel {
    // response getting from the api

    _id = "";
    _index = "";
    _score = 0.0;
    _source = [InfluencersDataModel];
    constructor(data) {
        super();
        this.update(data);
    }
}
