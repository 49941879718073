import { BaseModel } from "sjs-base-model";

export default class AssignmentRulePutBulkUpdate extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  data = [];
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
