import BaseReducer from "../../../utilities/BaseReducer";
import RedditFeedAction from "./RedditFeedAction";

export default class RedditFeedReducer extends BaseReducer {
  initialState = {
    redditFeeds: [],
  };

  [RedditFeedAction.REQUEST_REDDIT_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      redditFeeds: action.payload,
    };
  }

  [RedditFeedAction.REQUEST_REDDIT_FEEDS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      redditFeeds: action.payload,
    };
  }
}
