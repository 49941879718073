import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import CustomerModel from "./model/CustomerModel";
import CustomerPostModel from "./model/CustomerPostModel";
import CustomerPutModel from "./model/CustomerPutModel";

// CustomerEffect
export default class CustomerEffect {
  // calling an API according to the Customer model
  static async requesCustomer(params) {
    const endpoint = environment.api.customer;
    return EffectUtility.getToModel(CustomerModel, endpoint, params);
  }

  static async requesCustomerClear() {
    return {};
  }

  static async requesCustomerFilterClear() {
    return {};
  }

  // Get method for the Customer filter
  static async requestCustomerFilter(params) {
    const endpoint = environment.api.customer;
    return EffectUtility.getToModel(CustomerModel, endpoint, params);
  }

  // put method for the Customer
  static async requestPutCustomer(data, id) {
    const endpoint = environment.api.customer + "/" + id;
    return EffectUtility.putToModel(CustomerPutModel, endpoint, data);
  }

  // post method for the Customer
  static async requestPostCustomer(data) {
    const endpoint = environment.api.customer + "/add";
    return EffectUtility.postToModel(CustomerPostModel, endpoint, data);
  }

  static async requestDeleteCustomer(id) {
    const endpoint = environment.api.customer + "/" + id;
    return EffectUtility.deleteToModel(CustomerPostModel, endpoint);
  }
}
