import React, { useEffect, useState } from "react";
import environment from "environment";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { Box } from "@mui/material";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";

function TicketCategoryDetailedReport(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [categoryData, setCategoryData] = useState(null);
  const [customfieldCategory, setCustomFieldCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchCategoryData().then((res) => {
        setCategoryData(res);
      });

      await fetchCustomFiledCategory().then((res) => {
        setCustomFieldCategory(res);
      });
      setLoading(false);
    })();
  }, []);

  async function fetchCategoryData() {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["category_monthwise"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${environment.api.ticketReports}?report_type=["count"]&reports=["category_monthwise"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(async (response) => {
    //     return response;
    //   })
    //   .catch((err) => {});
  }

  async function fetchCustomFiledCategory() {
    let API = `${environment.api.baseApi}/custom_ticket_fields?filters=[["field_type","must","","match","category"]]`;
    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then(async (response) => {
        return response;
      })
      .catch((err) => {});
  }

  let categoriesList = [];
  categoriesList =
    customfieldCategory &&
    customfieldCategory.hits &&
    customfieldCategory.hits.hits &&
    customfieldCategory.hits.hits.length > 0 &&
    customfieldCategory.hits.hits[0]._source.field_values;

  let categoryValuesList = [];

  function AppendCategoryNames(valuesArray) {
    valuesArray &&
      valuesArray.length > 0 &&
      valuesArray.map((item) => {
        categoryValuesList.push(item);
        if (item.sub_value.length > 0) {
          AppendCategoryNames(item.sub_value);
        }
      });
  }

  categoriesList &&
    categoriesList.length > 0 &&
    AppendCategoryNames(categoriesList);
  // console.log(
  //   "CAATEGORY",
  //   categoryData,
  //   "Customecategory",
  //   categoriesList,
  //   "FINAL",
  //   categoryValuesList
  // );

  return (
    <div>
      {categoryData &&
        categoryData.ticket_report &&
        categoryData.ticket_report.category_monthwise && (
          <LoadingIndicator isActive={loading}>
            <p
              style={{ fontSize: theme.reports.headerSize }}
              className="reportHeader"
            >
              {firstLetterOrange(props.report && props.report)}
            </p>

            <hr className="p-0 m-0 " />

            <br />

            <Box
              sx={{
                width: "100%",
                paddingX: 5,
              }}
            >
              <div
                className="scrollable"
                style={{
                  width: "100%",
                  overflowX: "auto",
                  overflowY: "auto",
                  maxHeight: "800px",
                }}
              >
                {Object.keys(categoryData.ticket_report.category_monthwise)
                  .length > 0 && (
                  <table
                    style={{
                      width: "100%",
                      border: `1px solid ${theme.table.border}`,
                      textAlign: "left",
                    }}
                    border="2"
                  >
                    <tbody style={{ width: "100%" }}>
                      <tr
                        style={{
                          border: `1px solid ${theme.table.border}`,
                          color: `${theme.palette.text.primary}`,
                          backgroundColor: `${theme.palette.background.primary}`,
                        }}
                      >
                        <th
                          className="pl-3 pr-3"
                          style={{ textAlign: "center" }}
                          rowSpan={"2"}
                        >
                          Ticket Category
                        </th>
                        <th
                          className="pl-3 pr-3"
                          style={{ textAlign: "center" }}
                          colspan={`${
                            Object.keys(
                              categoryData.ticket_report.category_monthwise
                            ).length
                          }`}
                        >
                          Month
                        </th>
                        <th
                          className="pl-3 pr-3"
                          style={{ textAlign: "center" }}
                          rowSpan={"2"}
                        >
                          Total
                        </th>{" "}
                        <th
                          className="pl-3 pr-3"
                          style={{ textAlign: "center" }}
                          rowSpan={"2"}
                        >
                          Ticket Category Break Up %
                        </th>
                      </tr>
                      <tr
                        style={{
                          border: `1px solid ${theme.table.border}`,
                          color: `${theme.palette.text.primary}`,
                          backgroundColor: `${theme.palette.background.primary}`,
                        }}
                      >
                        {Object.keys(
                          categoryData.ticket_report.category_monthwise
                        ).map((item) => (
                          <th className="pl-3 pr-3">
                            {item}
                            <br />
                          </th>
                        ))}
                      </tr>
                      {/* {Object.values(
                      categoryData.ticket_report.category_monthwise
                    ).map((monthReport, index) => (
                      <tr
                        style={{
                          backgroundColor:
                            index % 2 === 0
                              ? `${theme.table.rowColor}`
                              : "white",
                          color: `${theme.table.rowText}`,
                          border: `1px solid ${theme.table.border}`,
                        }}
                      >
                        {console.log("VALUE", monthReport, customfieldCategory)}
                      </tr>
                    ))} */}
                      {categoryValuesList &&
                        categoryValuesList.length > 0 &&
                        categoryValuesList.map((item, index) => (
                          <tr
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? `${theme.table.rowColor}`
                                  : "white",
                              color: `${theme.table.rowText}`,
                              border: `1px solid ${theme.table.border}`,
                            }}
                          >
                            <td className="pl-3">{item.value}</td>

                            {/* {console.log(
                            "ITEM_",
                            Object.values(
                              categoryData.ticket_report.category_monthwise
                            )
                          )} */}

                            {/* {
monthReport && monthReport.length > 0 ? (
 <td className="pl-3">
{Object.values(monthReport[0]).filter((obj) =>
Object.values(obj).includes(item.id)
).length > 0 &&
Object.values(monthReport[0]).filter((obj) =>
Object.values(obj).includes(item.id)
)[0].count}
</td>
) : (
 <td className="pl-3">0</td>
)
} */}

                            {Object.values(
                              categoryData.ticket_report.category_monthwise
                            ).map((monthReport, index) => {
                              {
                                monthReport && monthReport.length > 0 ? (
                                  <td className="pl-3">0</td>
                                ) : (
                                  <td className="pl-3">0</td>
                                );
                              }

                              return <td className="pl-3">0</td>;
                            })}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Box>
          </LoadingIndicator>
        )}
    </div>
  );
}

export default TicketCategoryDetailedReport;
