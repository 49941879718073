import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import InfluencerDashboardModel from "./model/InfluencerDashboardModel";
import InfluencerDashboardPostModel from "./model/InfluencerDashboardPostModel";
import InfluencerDashboardPutModel from "./model/InfluencerDashboardPutModel";

// InfluencerDashboardEffect
export default class InfluencerDashboardEffect {
  // calling an API according to the Influencers model
  // Get method for the
  static async requestInfluencerDashboard(params) {
    const endpoint = environment.api.influencerDashboard;
    return EffectUtility.getToModel(InfluencerDashboardModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestInfluencerDashboardFilter(params) {
    const endpoint = environment.api.influencerDashboardFilter;
    return EffectUtility.getToModel(InfluencerDashboardModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutInfluencerDashboard(data, id) {
    const endpoint = environment.api.influencerDashboard + "/" + id;
    return EffectUtility.putToModel(
      InfluencerDashboardPutModel,
      endpoint,
      data
    );
  }

  // post method for the Organization
  static async requestPostInfluencerDashboard(data) {
    const endpoint = environment.api.influencerDashboard + "/add";

    return EffectUtility.postToModel(
      InfluencerDashboardPostModel,
      endpoint,
      data
    );
  }
}
