import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import WebsweepxReportsGetModal from "./models/WebsweepxReportsGetModal";
import WebsweepxReportCMSvsWSXGetModel from "./models/WebsweepxReportCMSvsWSXGetModel";
import WebsweepxTopSitesGetModal from "./models/WebsweepxTopSitesGetModal";
import WsxStatsReportModel from "./models/WsxStatsReportModel";
import WebsweepxCrawledPagesGetModal from "./models/WebsweepxCrawledPagesGetModal";
import WebsweepxReportCMSvsWSXHSSGetModel from "./models/WebsweepxReportCMSvsWSXHSSGetModel";
import WebsweepxCMSvsWSXTelegramGetModal from "./models/WebsweepxCMSvsWSXTelegramGetModal";
import TelegramChannelWiseLinksModel from "./models/TelegramChannelWiseLinksModel";
export default class WebsweepxReportsEffect {
  static async requestWebsweepxReports(params, callback) {
    const endpoint = environment.api.websweepXReports;

    let response = EffectUtility.getToModel(
      WebsweepxReportsGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetTelegramChannelWiseLinks(params, callback) {
    const endpoint = environment.api.telegramChannelwiseLinks;

    let response = EffectUtility.getToModel(
      TelegramChannelWiseLinksModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestWebsweepxReportCMSvsWSX(params, callback) {
    const endpoint = environment.api.websweepXReportCMSvsWSX;
    let response = EffectUtility.getToModel(
      WebsweepxReportCMSvsWSXGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestWebsweepxReportCMSvsWSXProperties(params, callback) {
    const endpoint = environment.api.websweepXReportCMSvsWSXProperties;
    let response = EffectUtility.getToModel(
      WebsweepxReportCMSvsWSXGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestWebsweepxReportCMSvsWSXHostWise(params, callback) {
    const endpoint = environment.api.websweepXReportCMSvsWSXHostWise;
    let response = EffectUtility.getToModel(
      WebsweepxReportCMSvsWSXGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestWebsweepxReportCMSvsWSXHSSWise(params) {
    const endpoint = environment.api.websweepXReportCMSvsWSXHSSWise;
    let response = EffectUtility.getToModel(
      WebsweepxReportCMSvsWSXHSSGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestTopSitesToBeAutomated(params, callback) {
    const endpoint = environment.api.topSitestobeAutomated;
    let response = EffectUtility.getToModel(
      WebsweepxTopSitesGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestWsxStatsReport(params, callback) {
    const endpoint = environment.api.wsxStatsReportData;
    let response = EffectUtility.getToModel(
      WsxStatsReportModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestCrawledPagesReport(params) {
    const endpoint = environment.api.websweepxCrawledPagesReport;
    let response = EffectUtility.getToModel(
      WebsweepxCrawledPagesGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestCMSvsWSXTelegramReport(params, callback) {
    const endpoint = environment.api.websweepxCMSvsWSXTelegramReport;
    let response = EffectUtility.getToModel(
      WebsweepxCMSvsWSXTelegramGetModal,
      endpoint,
      params
    );
    return response;
  }
}
