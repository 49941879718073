import BaseReducer from "../../../utilities/BaseReducer";
import EmailFeedsAction from "./EmailFeedsAction";

// twitter reducer function
export default class EmailFeedsReduser extends BaseReducer {
  initialState = {
    emailFeeds: [],
  };

  [EmailFeedsAction.REQUEST_EMAIL_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      emailFeeds: action.payload,
    };
  }

  [EmailFeedsAction.REQUEST_EMAIL_FEEDS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      emailFeeds: action.payload,
    };
  }
}
