import ActionUtility from "../../../../utilities/ActionUtility";
import FingerPrintAssetEffect from "./FingerPrintAssetEffect";

export default class FingerPrintAssetAction {
  // FingerPrintAssetAction action with an function declaration
  static REQUEST_POST_ASSET = "FingerPrintAssetAction.REQUEST_POST_ASSET";
  static REQUEST_POST_ASSET_FINISHED =
    "FingerPrintAssetAction.REQUEST_POST_ASSET_FINISHED";

  static REQUEST_PUT_ASSET = "FingerPrintAssetAction.REQUEST_PUT_ASSET";
  static REQUEST_PUT_ASSET_FINISHED =
    "FingerPrintAssetAction.REQUEST_PUT_ASSET_FINISHED";

  static REQUEST_GET_ASSET = "FingerPrintAssetAction.REQUEST_GET_ASSET";
  static REQUEST_GET_ASSET_FINISHED =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_FINISHED";

  static REQUEST_GET_ASSET_FILTER =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_FILTER";
  static REQUEST_GET_ASSET_FILTER_FINISHED =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_FILTER_FINISHED";

  static REQUEST_GET_ASSET_BY_ID =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_BY_ID";
  static REQUEST_GET_ASSET_BY_ID_FINISHED =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_BY_ID_FINISHED";

  static REQUEST_GET_ASSET_BY_ID_CLEAR =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_BY_ID_CLEAR";
  static REQUEST_GET_ASSET_BY_ID_CLEAR_FINISHED =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_BY_ID_CLEAR_FINISHED";

  static REQUEST_PUT_ASSET_TRACKING_STATUS =
    "FingerPrintAssetAction.REQUEST_PUT_ASSET_TRACKING_STATUS";
  static REQUEST_PUT_ASSET_TRACKING_STATUS_FINISHED =
    "FingerPrintAssetAction.REQUEST_PUT_ASSET_TRACKING_STATUS_FINISHED";

  static REQUEST_GET_ASSET_SYNCED_COPIES =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_SYNCED_COPIES";
  static REQUEST_GET_ASSET_SYNCED_COPIES_FINISHED =
    "FingerPrintAssetAction.REQUEST_GET_ASSET_SYNCED_COPIES_FINISHED";

  // METHODS
  static requestGetAssets(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_GET_ASSET,
        FingerPrintAssetEffect.requestGetAssets,
        params,
        callback
      );
    };
  }

  static requestPutAssets(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_PUT_ASSET,
        FingerPrintAssetEffect.requestPutAssets,
        data,
        id
      );
    };
  }

  static requestGetAssetSyncedCopies(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_GET_ASSET_SYNCED_COPIES,
        FingerPrintAssetEffect.requestGetAssetSyncedCopies,
        id,
        params
      );
    };
  }

  static requestGetAssetsFilter(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_GET_ASSET_FILTER,
        FingerPrintAssetEffect.requestGetAssetsFilter,
        params,
        callback
      );
    };
  }

  static requestGetAssetById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_GET_ASSET_BY_ID,
        FingerPrintAssetEffect.requestGetAssetById,
        id,
        params
      );
    };
  }

  static requestGetAssetByIdClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_GET_ASSET_BY_ID_CLEAR,
        FingerPrintAssetEffect.requestGetAssetByIdClear
      );
    };
  }

  static requestPostAsset(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_POST_ASSET,
        FingerPrintAssetEffect.requestPostAsset,
        data,
        params,
        callback
      );
    };
  }

  static requestPutAssetTrackingStatus(data, id, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintAssetAction.REQUEST_PUT_ASSET_TRACKING_STATUS,
        FingerPrintAssetEffect.requestPutAssetTrackingStatus,
        data,
        id,
        params,
        callback
      );
    };
  }
}
