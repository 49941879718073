import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";
function StaticPlatformSentimentChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getOption = {
    legend: {
      show: false,
    },
    tooltip: {},

    dataset: {
      source: [
        ["product", "Positive", "Negative", "Neutral"],
        ["Facebook", 43.3, 85.8, 93.7],
        ["Twitter", 83.1, 73.4, 55.1],
        ["Linkedin", 86.4, 65.2, 82.5],
        ["YouTube", 72.4, 53.9, 39.1],
      ],
    },
    xAxis: { type: "category" },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
      {
        type: "bar",
        name: "Positive",
        itemStyle: {
          color: "#468C9D", // Green color for Positive
        },
      },
      {
        type: "bar",
        name: "Negative",
        itemStyle: {
          color: "#F26D4E", // Red color for Negative
        },
      },
      {
        type: "bar",
        name: "Neutral",
        itemStyle: {
          color: "#B4D5DD", // Yellow color for Neutral
        },
      },
    ],
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default StaticPlatformSentimentChart;
