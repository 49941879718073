import PlayStoreAction from "./PlayStoreAction";
import BaseReducer from "../../../utilities/BaseReducer";

// reducer function
export default class PlayStoreReducer extends BaseReducer {
  initialState = {
    playstore: [],
  };

  [PlayStoreAction.REQUEST_PLAYSTORE_FINISHED](state, action) {
    return {
      ...state,
      playstore: action.payload,
    };
  }

  [PlayStoreAction.REQUEST_PLAYSTORE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      playstore: action.payload,
    };
  }
}
