import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import SitesExecutionLogsGetModal from "./modals/SitesExecutionLogsGetModal";

export default class SitesExecutionLogsEffect {
  static async requestSitesExecutionLogs(params, callback) {
    const endpoint = environment.api.websweepXSitesExecutionLogs;
    let response = EffectUtility.getToModel(
      SitesExecutionLogsGetModal,
      endpoint,
      params
    );
    return response;
  }
}
