import environment from "environment";
import RevenueClaimGetModel from "./models/RevenueClaimGetModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import ClientRevenueBalanceGetModel from "./models/ClientRevenueBalanceGetModel";
import GenerateRevenueClaimPostModel from "./models/GenerateRevenueClaimPostModel";
import AdvanceRevenuePaymentPostModel from "./models/AdvanceRevenuePaymentPostModel";

export default class RevenueClaimEffect {
  static async requestRevenueClaim(params) {
    const endpoint = environment.api.revenueClaim;

    return EffectUtility.getToModel(RevenueClaimGetModel, endpoint, params);
  }

  static async requestClientRevenueBalance(clientId) {
    const endpoint = environment.api.clientRevenueBalance + "/" + clientId;

    return EffectUtility.getToModel(ClientRevenueBalanceGetModel, endpoint);
  }

  static async requestGenerateRevenueClaim(data) {
    const endpoint = environment.api.generateRevenueClaim;
    return EffectUtility.postToModel(
      GenerateRevenueClaimPostModel,
      endpoint,
      data
    );
  }

  static async requestClientwiseRevenueClaim(params) {
    const endpoint = environment.api.revenueClaim;

    return EffectUtility.getToModel(RevenueClaimGetModel, endpoint, params);
  }

  static async requestGenerateClientInvoice(data, id, params, callBack) {
    const endpoint = environment.api.generateClientInvoice + "/" + id;
    let response = EffectUtility.postToModel(
      GenerateRevenueClaimPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // Advance Payment Request
  static requestAdvancePayment(params) {
    const endpoint = environment.api.advancePaymentRequest;
    return EffectUtility.postToModel(
      AdvanceRevenuePaymentPostModel,
      endpoint,
      params
    );
  }
}
