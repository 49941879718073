import ActionUtility from "../../utilities/ActionUtility";
import ResourceEffect from "./ResourceEffect";

export default class ResourceAction {
  // resource filter
  static REQUEST_RESOURCE_FILTER = "ResourceAction.REQUEST_RESOURCE_FILTER";
  static REQUEST_RESOURCE_FILTER_FINISHED =
    "ResourceAction.REQUEST_RESOURCE_FILTER_FINISHED";

  //METHODS

  //Resource filter list get method
  static requestResourceFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResourceAction.REQUEST_RESOURCE_FILTER,
        ResourceEffect.requestResourceFilter,
        params
      );
    };
  }
}
