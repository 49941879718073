import BaseReducer from "../../../../utilities/BaseReducer";
import RevenueReportErrorsAction from "./RevenueReportErrorsAction";

// RevenueReportErrorsReuser
export default class RevenueReportErrorsReducer extends BaseReducer {
  initialState = {
    revenueReportErrors: [],
  };
  [RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      revenueReportErrors: action.payload,
    };
  }
}
