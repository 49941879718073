import ActionUtility from "../../../../utilities/ActionUtility";
import LeadSourceEffect from "./LeadSourceEffect";

export default class LeadSourceAction {
  // LeadSourceAction action with an function declaration
  static REQUEST_LEADSOURCE = "LeadSourceAction.REQUEST_LEADSOURCE";
  static REQUEST_LEADSOURCE_FINISHED =
    "LeadSourceAction.REQUEST_LEADSOURCE_FINISHED";

  static REQUEST_LEADSOURCE_FILTER =
    "LeadSourceAction.REQUEST_LEADSOURCE_FILTER";
  static REQUEST_LEADSOURCE_FILTER_FINISHED =
    "LeadSourceAction.REQUEST_LEADSOURCE_FILTER_FINISHED";

  static REQUEST_PUT_LEADSOURCE = "LeadSourceAction.REQUEST_PUT_LEADSOURCE";
  static REQUEST_PUT_LEADSOURCE_FINISHED =
    "LeadSourceAction.REQUEST_PUT_LEADSOURCE_FINISHED";

  static REQUEST_POST_LEADSOURCE = "LeadSourceAction.REQUEST_POST_LEADSOURCE";
  static REQUEST_POST_LEADSOURCE_FINISHED =
    "LeadSourceAction.REQUEST_POST_LEADSOURCE_FINISHED";

  static REQUEST_LEADSOURCE_CLEAR = "LeadSourceAction.REQUEST_LEADSOURCE_CLEAR";
  static REQUEST_LEADSOURCE_CLEAR_FINISHED =
    "LeadSourceAction.REQUEST_LEADSOURCE_CLEAR_FINISHED";

  static REQUEST_LEADSOURCE_FILTER_CLEAR =
    "LeadSourceAction.REQUEST_LEADSOURCE_FILTER_CLEAR";
  static REQUEST_LEADSOURCE_FILTER_CLEAR_FINISHED =
    "LeadSourceAction.REQUEST_LEADSOURCE_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_LEADSOURCE =
    "LeadSourceAction.REQUEST_DELETE_LEADSOURCE";
  static REQUEST_DELETE_LEADSOURCE_FINISHED =
    "LeadSourceAction.REQUEST_DELETE_LEADSOURCE_FINISHED";

  // METHODS
  // LeadSource GET function
  static requesLeadSource(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadSourceAction.REQUEST_LEADSOURCE,
        LeadSourceEffect.requesLeadSource,
        params
      );
    };
  }

  static requesLeadSourceClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadSourceAction.REQUEST_LEADSOURCE_CLEAR,
        LeadSourceEffect.requesLeadSourceClear
      );
    };
  }

  static requesLeadSourceFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadSourceAction.REQUEST_LEADSOURCE_FILTER_CLEAR,
        LeadSourceEffect.requesLeadSourceFilterClear
      );
    };
  }

  static requestLeadSourceFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadSourceAction.REQUEST_LEADSOURCE_FILTER,
        LeadSourceEffect.requestLeadSourceFilter,
        params
      );
    };
  }
  static requestPutLeadSource(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadSourceAction.REQUEST_PUT_LEADSOURCE,
        LeadSourceEffect.requestPutLeadSource,
        data,
        id
      );
    };
  }

  static requestPostLeadSource(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadSourceAction.REQUEST_POST_LEAD,
        LeadSourceEffect.requestPostLeadSource,
        data
      );
    };
  }
  static requestDeleteLeadSource(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadSourceAction.REQUEST_DELETE_LEAD,
        LeadSourceEffect.requestDeleteLeadSource,
        id
      );
    };
  }
}
