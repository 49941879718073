import ActionUtility from "../../../utilities/ActionUtility";
import AppStatusEffect from "./AppStatusEffect";

export default class AppStatusAction {
  // App Status action with an function declaration
  static REQUEST_APP_STATUS = "AppStatusAction.REQUEST_APP_STATUS";
  static REQUEST_APP_STATUS_FINISHED =
    "AppStatusAction.REQUEST_APP_STATUS_FINISHED";

  // App status put
  static REQUEST_PUT_APP_STATUS = "AppStatusAction.REQUEST_PUT_APP_STATUS";
  static REQUEST_PUT_APP_STATUS_FINISHED =
    "AppStatusAction.REQUEST_PUT_APP_STATUS_FINISHED";

  // METHODS
  // App Status GET function
  static requestAppStatus(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStatusAction.REQUEST_APP_STATUS,
        AppStatusEffect.requestAppStatus,
        params
      );
    };
  }

  // App Status put function
  static requestPutAppStatus(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStatusAction.REQUEST_PUT_APP_STATUS,
        AppStatusEffect.requestPutAppStatus,
        data,
        id
      );
    };
  }
}
