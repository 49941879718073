import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import RevenueReportErrorsGetModel from "./models/RevenueReportErrorsGetModel";

// RevenueReportErrorsEffect
export default class RevenueReportErrorsEffect {
  // calling an API according to the RevenueReportErrors model
  static async requestRevenueReportErrors(params) {
    const endpoint = environment.api.revenueReportErrors;
    return EffectUtility.getToModel(
      RevenueReportErrorsGetModel,
      endpoint,
      params
    );
  }

  // Get method for the RevenueReportErrors filter
  static async requestRevenueReportErrorsFilter(params) {
    const endpoint = environment.api.revenueReportErrors;
    return EffectUtility.getToModel(
      RevenueReportErrorsGetModel,
      endpoint,
      params
    );
  }
}
