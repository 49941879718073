import { BaseModel } from "sjs-base-model";
import ResponseMessageShardsModel from "./ResponseMessageShardsModel";
import ResponseMessageHitsModel from "./ResponseMessageHitsModel";

// get model for project
export default class ResponseMessageModel extends BaseModel {
  /*
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = { _shards: ResponseMessageShardsModel };
  current_page_no = 0;
  hits = { hits: ResponseMessageHitsModel };
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
