import ActionUtility from "../../../utilities/ActionUtility";
import IndeedAccountEffect from "./IndeedAccountEffect";

export default class IndeedAccountAction {
  // IndeedAccountAction action with an function declaration
  static REQUEST_INDEED_ACCOUNT = "IndeedAccountAction.REQUEST_INDEED_ACCOUNT";
  static REQUEST_INDEED_ACCOUNT_FINISHED =
    "IndeedAccountAction.REQUEST_INDEED_ACCOUNT_FINISHED";

  static REQUEST_INDEED_ACCOUNT_FILTER =
    "IndeedAccountAction.REQUEST_INDEED_ACCOUNT_FILTER";
  static REQUEST_INDEED_ACCOUNT_FILTER_FINIISHED =
    "IndeedAccountAction.REQUEST_INDEED_ACCOUNT_FILTER_FINIISHED";

  static REQUEST_PUT_INDEED_ACCOUNT =
    "IndeedAccountAction.REQUEST_PUT_INDEED_ACCOUNT";
  static REQUEST_PUT_INDEED_ACCOUNT_FINISHED =
    "IndeedAccountAction.REQUEST_PUT_INDEED_ACCOUNT_FINISHED";

  static REQUEST_DELETE_INDEED_ACCOUNT =
    "IndeedAccountAction.REQUEST_DELETE_INDEED_ACCOUNT";
  static REQUEST_DELETE_INDEED_ACCOUNT_FINISHED =
    "IndeedAccountAction.REQUEST_DELETE_INDEED_ACCOUNT_FINISHED";

  static REQUEST_POST_INDEED_ACCOUNT =
    "IndeedAccountAction.REQUEST_POST_INDEED_ACCOUNT";
  static REQUEST_POST_INDEED_ACCOUNT_FINISHED =
    "IndeedAccountAction.REQUEST_POST_INDEED_ACCOUNT_FINISHED";

  // METHODS
  // Indeed GET function
  static requestIndeedAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedAccountAction.REQUEST_INDEED_ACCOUNT,
        IndeedAccountEffect.requestIndeedAccount,
        params
      );
    };
  }

  // Indeed post function
  static requestPostIndeedAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedAccountAction.REQUEST_POST_INDEED_ACCOUNT,
        IndeedAccountEffect.requestPostIndeedAccount,
        data
      );
    };
  }

  static requestIndeedAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedAccountAction.REQUEST_INDEED_ACCOUNT_FILTER,
        IndeedAccountEffect.requestIndeedAccountFilter,
        params
      );
    };
  }
  static requestPutIndeedAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedAccountAction.REQUEST_PUT_INDEED_ACCOUNT,
        IndeedAccountEffect.requestPutIndeedAccount,
        data,
        id
      );
    };
  }

  static requestDeleteIndeedAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedAccountAction.REQUEST_DELETE_INDEED_ACCOUNT,
        IndeedAccountEffect.requestDeleteIndeedAccount,
        id
      );
    };
  }
}
