import environment from "environment";
import AiSettingsGetModel from "./models/AiSettingsGetModel";
import AiSettingsPutModel from "./models/AiSettingsPutModel";
import AiSettingsPostModel from "./models/AiSettingsPostModel";
import EffectUtility from "../../../utilities/EffectUtility";

export default class AiSettingsEffect {
  static async requestAiSettings(params) {
    const endpoint = environment.api.aiSettings;
    return EffectUtility.getToModel(AiSettingsGetModel, endpoint, params);
  }

  static async requestPutAiSettings(data, id) {
    const endpoint = environment.api.aiSettings + "/" + id;
    return EffectUtility.putToModel(AiSettingsPutModel, endpoint, data);
  }

  static async requestPostAiSettings(data, params, callBack) {
    const endpoint = environment.api.aiSettings;
    let response = EffectUtility.postToModel(
      AiSettingsPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestDeleteAiSettings(id) {
    const endpoint = environment.api.aiSettings + "/" + id;
    return EffectUtility.deleteToModel(AiSettingsPostModel, endpoint);
  }
}
