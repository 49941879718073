import { BaseModel } from "sjs-base-model";
import EmailAccountHitsModel from "./EmailAccountHitsModel";
import EmailAccountShardsModel from "./EmailAccountShardsModel";

// EmailAccountModel
export default class EmailAccountModel extends BaseModel {

  // response getting from the api mentioned end point
  _shards = { _shards: EmailAccountShardsModel };
  current_page_no = 0;
  hits = { hits: EmailAccountHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
