import BaseReducer from "../../../utilities/BaseReducer";
import FacebookAction from "./FacebookAction";

// twitter reducer function
export default class FacebookReducer extends BaseReducer {
  initialState = {
    facebook: [],
  };

  [FacebookAction.REQUEST_FACEBOOK_FINISHED](state, action) {
    return {
      ...state,
      facebook: action.payload,
    };
  }

  [FacebookAction.REQUEST_FACEBOOK_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      facebook: action.payload,
    };
  }
}
