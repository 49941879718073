import ActionUtility from "../../../utilities/ActionUtility";
import LinkedInFeedEffect from "./LinkedInFeedEffect";

export default class LinkedInFeedAction {
  static REQUEST_LINKEDIN_FEED = "LinkedInFeedAction.REQUEST_LINKEDIN_FEED";
  static REQUEST_LINKEDIN_FEED_FINISHED =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_FINISHED";

  static REQUEST_LINKEDIN_FEED_FILTER =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_FILTER";
  static REQUEST_LINKEDIN_FEED_FILTER_FINISHED =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_FILTER_FINISHED";

  static REQUEST_LINKEDIN_FEED_REPLY_POST =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_REPLY_POST";
  static REQUEST_LINKEDIN_FEED_REPLY_POST_FINISHED =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_REPLY_POST_FINISHED";

  static REQUEST_LINKEDIN_FEED_POST_MENTION_REPLY =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_POST_MENTION_REPLY";
  static REQUEST_LINKEDIN_FEED_POST_MENTION_REPLY_FINISHED =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_POST_MENTION_REPLY_FINISHED";

  static REQUEST_PUT_LINKEDIN_FEEDS =
    "LinkedInFeedAction.REQUEST_PUT_LINKEDIN_FEEDS";
  static REQUEST_PUT_LINKEDIN_FEEDS_FINISHED =
    "LinkedInFeedAction.REQUEST_PUT_LINKEDIN_FEEDS_FINISHED";

  static REQUEST_LINKEDIN_FEED_CLEAR =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_CLEAR";
  static REQUEST_LINKEDIN_FEED_CLEAR_FINISHED =
    "LinkedInFeedAction.REQUEST_LINKEDIN_FEED_CLEAR_FINISHED";

  // METHODS
  // GMB Feeds GET function
  static requestLinkedInFeed(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInFeedAction.REQUEST_LINKEDIN_FEED,
        LinkedInFeedEffect.requestLinkedInFeed,
        params,
        callBackFunction
      );
    };
  }

  static async requestLinkedInFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInFeedAction.REQUEST_LINKEDIN_FEED_CLEAR,
        LinkedInFeedEffect.requestLinkedInFeedClear
      );
    };
  }

  static requestLinkedInFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInFeedAction.REQUEST_LINKEDIN_FEED_FILTER,
        LinkedInFeedEffect.requestLinkedInFeedFilter,
        params
      );
    };
  }

  // REview reply post method
  static requestLinkedInReplyPost(id, data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInFeedAction.REQUEST_LINKEDIN_FEED_REPLY_POST,
        LinkedInFeedEffect.requestLinkedInReplyPost,
        id,
        data,
        callback
      );
    };
  }

  static requestLinkedInPostMentionReply(id, data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInFeedAction.REQUEST_LINKEDIN_FEED_REPLY_POST,
        LinkedInFeedEffect.requestLinkedInPostMentionReply,
        id,
        data,
        callback
      );
    };
  }

  static requestPutLinkedInFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkedInFeedAction.REQUEST_PUT_LINKEDIN_FEEDS,
        LinkedInFeedEffect.requestPutLinkedInFeeds,
        data,
        id
      );
    };
  }
}
