import ActionUtility from "../../../utilities/ActionUtility";
import YoutubeAccountEffect from "./YoutubeAccountEffect";
export default class YoutubeAccountAction {
  // GoogleAccountAction action with an function declaration
  static REQUEST_YOUTUBE_ACCOUNT =
    "YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT";
  static REQUEST_YOUTUBE_ACCOUNT_FINISHED =
    "YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FINISHED";

  static REQUEST_YOUTUBE_ACCOUNT_FILTER =
    "YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FILTER";
  static REQUEST_YOUTUBE_ACCOUNT_FILTER_FINISHED =
    "YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_YOUTUBE_ACCOUNT =
    "YoutubeAccountAction.REQUEST_PUT_YOUTUBE_ACCOUNT";
  static REQUEST_PUT_YOUTUBE_ACCOUNT_FINISHED =
    "YoutubeAccountAction.REQUEST_PUT_YOUTUBE_ACCOUNT_FINISHED";

  static REQUEST_DELETE_YOUTUBE_ACCOUNT =
    "YoutubeAccountAction.REQUEST_DELETE_YOUTUBE_ACCOUNT";
  static REQUEST_DELETE_YOUTUBE_ACCOUNT_FINISHED =
    "YoutubeAccountAction.REQUEST_DELETE_YOUTUBE_ACCOUNT_FINISHED";

  // METHODS
  // aDDaCCOUNT GET function
  static requestYoutubeAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT,
        YoutubeAccountEffect.requestYoutubeAccount,
        params
      );
    };
  }

  static requestYoutubeAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FILTER,
        YoutubeAccountEffect.requestYoutubeAccountFilter,
        params
      );
    };
  }
  static requestPutYoutubeAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAccountAction.REQUEST_PUT_YOUTUBE_ACCOUNT,
        YoutubeAccountEffect.requestPutYoutubeAccount,
        data,
        id
      );
    };
  }

  static requestDeleteYoutubeAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeAccountAction.REQUEST_DELETE_YOUTUBE_ACCOUNT,
        YoutubeAccountEffect.requestDeleteYoutubeAccount,

        id
      );
    };
  }
}
