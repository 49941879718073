import { BaseModel } from "sjs-base-model";
import AppStoreShardsModel from "./AppStoreShardsModel";
import AppStoreHitsModel from "./AppStoreHitsModel";

// get model for project
export default class AppStoreModel extends BaseModel {
  _shards = { _shards: AppStoreShardsModel };
  hits = { hits: AppStoreHitsModel };
  total_page_no = 0;
  current_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
