import ActionUtility from "../../../../utilities/ActionUtility";
import WhitelistedSitesEffect from "./WhitelistedSitesEffect";

export default class WhitelistedSitesACtion {
  static REQUEST_GET_WHITELISTED_SITES =
    "WhitelistedSitesACtion.REQUEST_GET_WHITELISTED_SITES";
  static REQUEST_GET_WHITELISTED_SITES_FINISHED =
    "WhitelistedSitesACtion.REQUEST_GET_WHITELISTED_SITES_FINISHED";

  static REQUEST_GET_WHITELISTED_SITES_FILTER =
    "WhitelistedSitesACtion.REQUEST_GET_WHITELISTED_SITES_FILTER";
  static REQUEST_GET_WHITELISTED_SITES_FILTER_FINISHED =
    "WhitelistedSitesACtion.REQUEST_GET_WHITELISTED_SITES_FILTER_FINISHED";

  static REQUEST_POST_WHITELISTED_SITES =
    "WhitelistedSitesACtion.REQUEST_POST_WHITELISTED_SITES";
  static REQUEST_POST_WHITELISTED_SITES_FINISHED =
    "WhitelistedSitesACtion.REQUEST_POST_WHITELISTED_SITES_FINISHED";

  static REQUEST_PUT_WHITELISTED_SITES =
    "WhitelistedSitesACtion.REQUEST_PUT_WHITELISTED_SITES";
  static REQUEST_PUT_WHITELISTED_SITES_FINISHED =
    "WhitelistedSitesACtion.REQUEST_PUT_WHITELISTED_SITES_FINISHED";

  // METHODS
  static requestWhitelistedSites(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhitelistedSitesACtion.REQUEST_GET_WHITELISTED_SITES,
        WhitelistedSitesEffect.requestWhitelistedSites,
        params,
        callback
      );
    };
  }

  static requestWhitelistedSitesFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhitelistedSitesACtion.REQUEST_GET_WHITELISTED_SITES_FILTER,
        WhitelistedSitesEffect.requestWhitelistedSitesFilter,
        params
      );
    };
  }

  static requestPostWhitelistedSites(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhitelistedSitesACtion.REQUEST_POST_WHITELISTED_SITES,
        WhitelistedSitesEffect.requestPostWhitelistedSites,
        data,
        callBackFunction
      );
    };
  }

  static requestPutWhitelistedSites(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhitelistedSitesACtion.REQUEST_PUT_WHITELISTED_SITES,
        WhitelistedSitesEffect.requestPutWhitelistedSites,
        data,
        id
      );
    };
  }
}
