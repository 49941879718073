import ActionUtility from "../../../utilities/ActionUtility";
import TelegramEffect from "./TelegramEffect";

export default class TelegramAction {
  //Telegram feeds get requests
  static REQUEST_TELEGRAM_FEEDS = "TelegramAction.REQUEST_TELEGRAM_FEEDS";
  static REQUEST_TELEGRAM_FEEDS_FINISHED =
    "TelegramAction.REQUEST_TELEGRAM_FEEDS_FINISHED";

  //Telegram feeds get requests for filter
  static REQUEST_TELEGRAM_FEEDS_FILTER =
    "TelegramAction.REQUEST_TELEGRAM_FEEDS_FILTER";
  static REQUEST_TELEGRAM_FEEDS_FILTER_FINISHED =
    "TelegramAction.REQUEST_TELEGRAM_FEEDS_FILTER_FINISHED";

  // Telegram Post Method

  static REQUEST_TELEGRAM_POST = "TelegramAction.REQUEST_TELEGRAM_POST";
  static REQUEST_TELEGRAM_POST_FINISHED =
    "TelegramAction.REQUEST_TELEGRAM_POST_FINISHED";

  static REQUEST_TELEGRAM_PUT = "TelegramAction.REQUEST_TELEGRAM_PUT";
  static REQUEST_TELEGRAM_PUT_FINISHED =
    "TelegramAction.REQUEST_TELEGRAM_PUT_FINISHED";

  static REQUEST_TELEGRAM_FEEDS_CLEAR =
    "TelegramAction.REQUEST_TELEGRAM_FEEDS_CLEAR";
  static REQUEST_TELEGRAM_FEEDS_CLEAR_FINISHED =
    "TelegramAction.REQUEST_TELEGRAM_FEEDS_CLEAR_FINISHED";

  //METHODS of Telegram Feeds

  //telegramfeeds get method
  static requestTelegramFeeds(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAction.REQUEST_TELEGRAM_FEEDS,
        TelegramEffect.requestTelegramFeeds,
        params
      );
    };
  }

  static requestTelegramFeedsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAction.REQUEST_TELEGRAM_FEEDS_CLEAR,
        TelegramEffect.requestTelegramFeedsClear
      );
    };
  }

  //telegram feeds filter get method
  static requestTelegramFeedsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAction.REQUEST_TELEGRAM_FEEDS_FILTER,
        TelegramEffect.requestTelegramFeedsFilter,
        params
      );
    };
  }

  static requestTelegramPost(data, id, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAction.REQUEST_TELEGRAM_POST,
        TelegramEffect.requestTelegramPost,
        data,
        id,
        callback
      );
    };
  }

  static requestTelegramPut(data, id, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramAction.REQUEST_TELEGRAM_PUT,
        TelegramEffect.requestTelegramPut,
        data,
        id,
        callback
      );
    };
  }

  // telegram post method
}
