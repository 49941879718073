import { Box, Grid, ListItem } from "@mui/material";
import React, { useState } from "react";
import MuiButton from "../../../components/muiButton/MuiButton";
import PlatformPostDialog from "../ticketsListView/postDataDialogs/PlatformPostDialog";
import { theme } from "../../../views/App";

const PlatformPost = () => {
  const [postDialogOpen, setPostDialogOpen] = useState(true);

  const buttonStyle = {
    width: "200px",
    height: "200px",
    border: `1px dashed${theme.palette.text.primary}`, // Adjust color as needed
    backgroundColor: "transparent",
    cursor: "pointer",
    transition: "background-color 0.3s, border-color 0.3s",
    color: theme.palette.text.primary,
    fontWeight: "",
  };

  const hoverStyle = {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box width={"100%"} height={"100%"}>
      {postDialogOpen && (
        <PlatformPostDialog
          open={postDialogOpen}
          onClose={() => {
            setPostDialogOpen(false);
          }}
        />
      )}

      <Box width={"100%"} height={"100%"}>
        <Grid
          item
          container
          xs={12}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
          //   className="bg-warning"
        >
          <button
            style={isHovered ? { ...buttonStyle, ...hoverStyle } : buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
            onClick={() => {
              setPostDialogOpen(true);
            }}
          >
            Platform Post
          </button>
        </Grid>
      </Box>
    </Box>
  );
};

export default PlatformPost;
