import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import AmbitionBoxAccountAction from "../../stores/ambitionBoxAccount/AmbitionBoxAccountAction";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};
const validationSchema = yup.object({
  account_name: yup
    .string("Enter your Account name")
    .trim()
    .min(1, "Account name should be of minimum 1 characters length")
    .required("Account name is required"),

  page_url: yup
    .string("Enter page url")
    .trim()
    .min(1, "page url should be of minimum 1 characters length")
    .required("Page Url is required"),
});

const AddAmbitionBoxAccount = (props) => {
  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method
  let initial = {};
  initial = {
    account_name: "",
    page_url: "",
  };

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          const params = {
            page_limit: "none",
          };
          setLoading(true);
          props
            .dispatch(
              AmbitionBoxAccountAction.requestPostAmbitionBoxAccount(
                JSON.stringify(values)
              )
            )
            .then(() => {
              setLoading(false);

              let params = {
                page_limit: "none",
              };
              let account_params = {
                page_limit: "none",
                order_by: '[["created_on", "desc"]]',
              };
              props.onClose();
              props.setAdd_account("");
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccount(account_params)
              );
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccountFilter(params)
              );
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext pl-5">
                        <b>
                          <span className="span_first_letter">A</span>
                          dd &nbsp;
                          <span className="span_first_letter">A</span>mbition
                          &nbsp;
                          <span className="span_first_letter">A</span>ccount
                          &nbsp;
                        </b>
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div
                        className="col-2 closebtn"
                        onClick={() => {
                          props.onClose();
                          props.setAdd_account("");
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              <Grid container>
                <Grid
                  item
                  xl={10}
                  lg={12}
                  md={12}
                  sm={12}
                  my={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                  ml={8}
                  //   className="bg-warning"
                >
                  <Grid
                    item
                    xl={3}
                    lg={2}
                    md={2}
                    sm={2}
                    mt={1}
                    // className="bg-success"
                  >
                    Account Name :
                  </Grid>
                  <Grid
                    item
                    xl={9}
                    lg={10}
                    md={5}
                    sm={5}
                    mt={0.5}
                    // className="bg-info"
                  >
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="account_name"
                      size="small"
                      name="account_name"
                      placeholder="Account Name"
                      error={
                        Boolean(errors.account_name) &&
                        Boolean(touched.account_name)
                      }
                      helperText={
                        Boolean(touched.account_name) && errors.account_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={10}
                  lg={12}
                  md={12}
                  sm={12}
                  my={1}
                  ml={8}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xl={3} lg={3} md={3} sm={3} mt={1}>
                    Page Url :
                  </Grid>
                  <Grid item xl={9} lg={10} md={5} sm={5} mt={0.5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="host"
                      size="small"
                      name="page_url"
                      placeholder="Page Url"
                      error={
                        Boolean(errors.page_url) && Boolean(touched.page_url)
                      }
                      helperText={Boolean(touched.page_url) && errors.page_url}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Account "}
                      type="submit"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      name={"Edit Project "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AddAmbitionBoxAccount);
