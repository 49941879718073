import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import OrmResourceModel from "./models/OrmResourceModel";

export default class OrmResourceEffect {
  // calling an API according to the ClientModel
  static async requestOrmResource(params) {
    const endpoint = environment.api.ormResource;

    return EffectUtility.getToModel(OrmResourceModel, endpoint, params);
  }
}
