import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// Google Account  data model
export default class EmailAccountDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  account_name = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  email = "";
  enabled = Boolean;
  host = "";
  is_ssl = "";
  last_sync_date = "";
  modified_by = {
    user_id: "",
    user_name: "",
  };
  modified_on = "";
  password = "";
  platform = "";
  port = "";
  project = {
    project_id: "",
    project_name: "",
  };
  username= "";

  constructor(data) {
    super();

    this.update(data);
  }
}
