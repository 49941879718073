import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import {
  BorderContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../../components/componentLayout/ComponentLayout";
import CircularLoadingIndicator from "../../../../../components/loading-indicator/CircularLoadingIndicator";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "../../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiAutocomplete from "../../../../../components/muiSelectField/MuiAutocomplete";
import DateRangeStyled from "../../../../../components/dateRangePickerComponent/DateRangePickerStyled";

function DateDurationPopper(props) {
  // const [open, setOpen] = React.useState(false);
  // const statePopperRef = React.useRef(null);
  // const prevOpen = React.useRef(open);
  let maxDate = new Date();

  const [createdDatelistSelected, setCreatedDatelistSelected] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters"))
        .createdDatelistSelected;
    } else {
      return null;
    }
  });

  const [createdDate, setCreatedDate] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return [
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[0]
          ? new Date(
              JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[1]
          ? new Date(
              JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[1]
            )
          : null,
      ];
    } else {
      return [null, null];
    }
  });

  const [modifiedDatelistSelected, setModifiedDatelistSelected] = useState(
    () => {
      if (localStorage.getItem("mentionsFilters")) {
        return JSON.parse(localStorage.getItem("mentionsFilters"))
          .modifiedDatelistSelected;
      } else {
        return null;
      }
    }
  );

  const [modifiedDate, setModifiedDate] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return [
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate[0]
          ? new Date(
              JSON.parse(
                localStorage.getItem("mentionsFilters")
              ).modifiedDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate[1]
          ? new Date(
              JSON.parse(
                localStorage.getItem("mentionsFilters")
              ).modifiedDate[1]
            )
          : null,
      ];
    } else {
      return [null, null];
    }
  });

  useEffect(() => {
    if (props.popopen) {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        setCreatedDatelistSelected(
          filters.createdDatelistSelected && filters.createdDatelistSelected
        );
        setCreatedDate(filters.createdDate && filters.createdDate);

        setModifiedDatelistSelected(
          filters.modifiedDatelistSelected && filters.modifiedDatelistSelected
        );
        setModifiedDate(filters.modifiedDate && filters.modifiedDate);
      }
    }
  }, [props.popopen]);

  // return focus to the button when we transitioned from !open -> open
  // useEffect(() => {
  //   if (props.prevOpen.current === true && props.popopen === false) {
  //     props.statePopperRef.focus();
  //   }

  //   props.prevOpen.current = props.popopen;
  // }, [props.popopen]);

  // created date  filter setstate function
  const createdDateHandle = (event, createdDate) => {
    if (createdDate !== null) {
      setCreatedDatelistSelected(createdDate);
    } else {
      setCreatedDatelistSelected(null);
      setCreatedDate([null, null]);
    }
  };

  const createdDateValueHandleChange = (e, v) => {
    setCreatedDate(e);
  };

  const modifiedOnDateHandle = (event, modifiedDate) => {
    setModifiedDatelistSelected(modifiedDate);
  };

  const modifiedOnValueHandleChange = (e, v) => {
    setModifiedDate(e);
  };

  let [createdError, setcreatedError] = useState(false);

  async function handleSaveFilters() {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

    if (
      createdDatelistSelected === "Custom" ||
      modifiedDatelistSelected === "Custom"
    ) {
      const isPastDate = (date) => {
        const now = new Date();
        return date <= now;
      };

      if (
        (createdDate[0] &&
          createdDate[0].$d &&
          isPastDate(createdDate[0].$d) &&
          createdDate[1] &&
          createdDate[1].$d &&
          isPastDate(createdDate[1].$d)) ||
        (modifiedDate[0] &&
          modifiedDate[0].$d &&
          isPastDate(modifiedDate[0].$d) &&
          modifiedDate[1] &&
          modifiedDate[1].$d &&
          isPastDate(modifiedDate[1].$d))
      ) {
        if (filters) {
          filters.createdDatelistSelected = createdDatelistSelected;
          filters.createdDate = createdDate;
          filters.modifiedDatelistSelected = modifiedDatelistSelected;
          filters.modifiedDate = modifiedDate;
          filters.pageNumber = `0`;

          localStorage.setItem("mentionsFilters", JSON.stringify(filters));

          props.fetchTicketCount();
          props.saveFilter(filters);
        } else {
          localStorage.setItem(
            "mentionsFilters",
            JSON.stringify({
              createdDatelistSelected,
              createdDate,
              modifiedDate,
              modifiedDatelistSelected,
            })
          );
        }
      } else {
        setcreatedError(true);

        setTimeout(() => {
          setcreatedError(false);
        }, 1000);
      }
    } else {
      if (
        createdDatelistSelected === null &&
        modifiedDatelistSelected === null
      ) {
        setcreatedError(true);

        setTimeout(() => {
          setcreatedError(false);
        }, 1000);
      } else {
        if (filters) {
          filters.createdDatelistSelected = createdDatelistSelected;
          filters.createdDate = createdDate;
          filters.modifiedDatelistSelected = modifiedDatelistSelected;
          filters.modifiedDate = modifiedDate;
          filters.pageNumber = `0`;
          localStorage.setItem("mentionsFilters", JSON.stringify(filters));

          props.fetchTicketCount();
          props.saveFilter(filters);
        } else {
          localStorage.setItem(
            "mentionsFilters",
            JSON.stringify({
              createdDatelistSelected,
              createdDate,
              modifiedDate,
              modifiedDatelistSelected,
            })
          );
        }
        // props.fetchTicketCount();
        // props.saveFilter(filters);
      }
    }
  }

  function handleClearFilters() {
    if (
      createdDatelistSelected !== null ||
      (createdDate[0] !== null && createdDate[1] !== null) ||
      modifiedDatelistSelected !== null ||
      (modifiedDate[0] !== null && modifiedDate[1] !== null)
    ) {
      setCreatedDatelistSelected(null);
      setCreatedDate([null, null]);
      setModifiedDatelistSelected(null);
      setModifiedDate([null, null]);
      props.clearFilter("date");

      localStorage.removeItem("mentionsFilters");
    }
  }

  return (
    <BorderContainer>
      <ClickAwayListener onClickAway={props.handleClose}>
        <div
          autoFocusItem={props.popopen}
          id="composition-menu"
          aria-labelledby="composition-button"
        >
          <Grid
            container
            sx={{
              height: 250,
            }}
          >
            <Typography
              component={"p"}
              className="dashboard-title"
              sx={{ fontSize: 18 }}
            >
              {"Date Duration".split(" ").map((item) => (
                <span className="dashboard-title">{item}</span>
              ))}
            </Typography>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              sx={
                {
                  // marginTop: 2,
                }
              }
              display="flex"
              justifyContent={"space-evenly"}
            >
              <Box>
                {
                  <Grid
                    item
                    lg={12}
                    md={12}
                    laptop={12}
                    textAlign="left"
                    sx={{
                      animation: createdError && "bounce 0.5s linear",
                      "@keyframes bounce": {
                        "0%, 20%, 50%, 80%, 100%": {
                          transform: "translateY(0)",
                        },
                        "40%": {
                          transform: "translateY(-30px)",
                        },
                        "60%": {
                          transform: "translateY(-15px)",
                        },
                      },
                    }}
                  >
                    <Typography component={"p"}>Created On</Typography>
                    <AutoCompleteComponent
                      list={props.dateSelecte}
                      textFieldProps={{
                        placeholder: "Created Date",
                      }}
                      value={createdDatelistSelected}
                      handleChange={createdDateHandle}
                      size="small"
                    />
                  </Grid>
                }
                {createdDatelistSelected === "Custom" && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    laptop={12}
                    marginTop={2}
                    sx={{
                      animation: createdError && "bounce 0.5s linear",
                      "@keyframes bounce": {
                        "0%, 20%, 50%, 80%, 100%": {
                          transform: "translateY(0)",
                        },
                        "40%": {
                          transform: "translateY(-30px)",
                        },
                        "60%": {
                          transform: "translateY(-15px)",
                        },
                      },
                    }}
                  >
                    {/* <DatePicker
                    value={createdDate}
                    onChange={createdDateValueHandleChange}
                  /> */}

                    <DateRangeStyled
                      value={createdDate}
                      onChange={createdDateValueHandleChange}
                      disableFuture={true}
                    />
                  </Grid>
                )}
              </Box>
              <Box>
                <Grid
                  item
                  lg={12}
                  md={12}
                  laptop={12}
                  textAlign="left"
                  sx={{
                    animation: createdError && "bounce 0.5s linear",
                    "@keyframes bounce": {
                      "0%, 20%, 50%, 80%, 100%": {
                        transform: "translateY(0)",
                      },
                      "40%": {
                        transform: "translateY(-30px)",
                      },
                      "60%": {
                        transform: "translateY(-15px)",
                      },
                    },
                  }}
                >
                  <Typography component={"p"}>Modified On</Typography>
                  <AutoCompleteComponent
                    list={props.dateSelected}
                    textFieldProps={{
                      placeholder: "Modified On",
                    }}
                    value={modifiedDatelistSelected}
                    handleChange={modifiedOnDateHandle}
                    size="small"
                  />
                </Grid>
                {modifiedDatelistSelected === "Custom" && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    laptop={12}
                    marginTop={2}
                    sx={{
                      animation: createdError && "bounce 0.5s linear",
                      "@keyframes bounce": {
                        "0%, 20%, 50%, 80%, 100%": {
                          transform: "translateY(0)",
                        },
                        "40%": {
                          transform: "translateY(-30px)",
                        },
                        "60%": {
                          transform: "translateY(-15px)",
                        },
                      },
                    }}
                  >
                    <DateRangeStyled
                      value={modifiedDate}
                      onChange={modifiedOnValueHandleChange}
                      disableFuture={true}
                    />
                  </Grid>
                )}
              </Box>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              // className="bg-warning"
              sx={{
                marginY: 5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item>
                <MuiButton
                  variant="outlined"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Reset"}
                  loading={false}
                  backgroundColor={"white"}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleClearFilters();
                  }}
                />
              </Grid>
              <Grid item>
                <MuiButton
                  variant="contained"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Apply"}
                  loading={false}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleSaveFilters();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ClickAwayListener>
    </BorderContainer>
  );
}

export default DateDurationPopper;
