import React, { useState } from "react";
import "../projectPostForm/ProjectPost.css";
import {
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
} from "@mui/material";
import { Field, Form, Formik, useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckboxTree from "react-checkbox-tree";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import RolesAction from "../../stores/roles/RolesAction";
import ManageRoleAction from "../manageRole/ManageRoleAction";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import { ModelScreenContainer } from "../../../components/componentLayout/ComponentLayout";
import MuiButton from "../../../components/muiButton/MuiButton";
import { theme } from "../../../views/App";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

//validation schema
const validationSchema = yup.object({
  role_name: yup
    .string("Enter role name")
    .trim()
    .min(1, "Role Name should be of minimum 1 characters length")
    .required("Role name is required"),
});

function AddRolePost(props) {
  const [page_limit] = useState(15);
  function childrenPages(item) {
    let List = [];
    item.map((e) => {
      List.push({
        value: e.page_id,
        label: e.page_name,
      });
    });

    return List && List;
  }

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  // Filter the inputArray based on matching page_id in filteringArray
  let filteredArray = props?.ormPages
    ?.map((platform) => {
      const platformKey = Object.keys(platform)[0];
      const filteredPages = platform[platformKey].filter((page) =>
        page_permission.includes(page.page_id)
      );

      return { [platformKey]: filteredPages };
    })
    .filter((platform) => {
      const platformKey = Object.keys(platform)[0];
      return platform[platformKey].length > 0;
    });

  let nodesList = [];
  filteredArray &&
    filteredArray.map((item) => {
      nodesList.push({
        value: Object.keys(item)[0],
        label: capitalize(Object.keys(item)[0]),
        children: childrenPages(Object.values(item)[0]),
      });
    });

  let AllPagesList = [];
  nodesList &&
    nodesList.map((item) => {
      item.children.map((event) => {
        AllPagesList.push({
          page_id: event.value,
          page_name: event.label,
        });
      });
    });

  let initial = {};
  if (props.edit) {
    initial = {
      role_name: props.roleEditData.roles,
      page_mapping: props.roleEditData.page_mapping_array,
      role_level: "",
      project: "",
      organization: "",
      parent_role: props.roleEditData.parent_role,
      default_dashboard: props.roleEditData.default_dashboard,
    };
  } else {
    initial = {
      role_name: "",
      role_level: "",
      project:
        props.project_array && props.project_array.length === 1
          ? props.project_array[0]
          : "",
      organization:
        props.organization_array && props.organization_array.length === 1
          ? props.organization_array[0]
          : "",
      parent_role:
        props.templateRoleList && props.templateRoleList.length === 1
          ? props.templateRoleList[0]
          : "",
      default_dashboard:
        props.reports_array && props.reports_array.length === 1
          ? props.reports_array[0]
          : "",
    };
  }

  const role_level_list = ["organization", "project"];
  const [loading, setLoading] = useState(false);
  const [selectedPages, setSelectedPages] = useState(
    props.roleEditData ? props.roleEditData.page_mapping_array : []
  );

  async function PagesHandleChanege(setFieldValue, page) {
    let treeValues = [];

    treeValues = AllPagesList.filter((val) => page.includes(val.page_id));

    setSelectedPages(treeValues);
    setFieldValue("page_mapping", treeValues);
  }

  const [checked, setChecked] = useState(() => {
    if (props.roleEditData.page_mapping_array) {
      return props.roleEditData.page_mapping_array.map((item) => {
        return item.page_id;
      });
    } else {
      return [];
    }
  });

  // Function to extract all 'value' fields recursively
  function extractValues(array) {
    let values = [];
    array.forEach((item) => {
      values.push(item.value);
      if (item.children) {
        values = values.concat(extractValues(item.children));
      }
    });
    return values;
  }

  const [expanded, setExpanded] = useState(
    nodesList?.length > 10 ? [] : extractValues(nodesList)
  );

  function GridCellCheckboxRenderer(setFieldValue, formikValues) {
    return (
      <Grid
        xl={12}
        container
        lg={12}
        md={12}
        sm={6}
        xs={6}
        item
        alignItems={"flex-start"}
      >
        <CheckboxTree
          icons={{
            check: <CheckBoxIcon size="small" />,
            uncheck: <CheckBoxOutlineBlankIcon size="small" />,
            expandClose: <ChevronRightIcon size="small" />,
            expandOpen: <ExpandMoreIcon size="small" />,
            parentClose: null,
            parentOpen: null,
            expandAll: null,
            leaf: null,
            halfCheck: <CheckBoxTwoToneIcon size="small" />,
            collapseAll: null,
          }}
          iconsClass="fa5"
          nodes={nodesList}
          checked={checked}
          expanded={expanded}
          onCheck={(val, e) => {
            setChecked(val);
            PagesHandleChanege(setFieldValue, val);
          }}
          onExpand={(expanded) => setExpanded(expanded)}
        />
      </Grid>
    );
  }

  return (
    <Box>
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.role_name) {
            errors.role_name = "Role Name is Required";
          }

          if (!values.parent_role && !props.edit) {
            errors.parent_role = "Role Template is Required";
          }
          if (!values.role_level && !props.edit) {
            errors.role_level = "Role Level is Required";
          }
          if (values.role_level === "project" && !props.edit) {
            if (!values.project) {
              errors.project = "project is Required";
            }
          }
          if (values.role_level === "organization" && !props.edit) {
            if (!values.organization) {
              errors.organization = "organization is Required";
            }
          }
          if (props.edit) {
            if (!values.page_mapping.length) {
              errors.page_mapping = "Pages are Required";
            }
          }
          return errors;
        }}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          let obj = {};
          Object.assign(
            obj,
            values.role_name && { role_name: values.role_name },
            values.role_name && {
              role_level: values.role_level,
            },
            values.project.project_id && {
              project_id: values.project.project_id,
            },
            values.organization.organization_id && {
              organization_id: values.organization.organization_id,
            },
            values.parent_role && {
              parent_role: values.parent_role,
            },
            values.default_dashboard && {
              default_dashboard_id: values.default_dashboard.report_template_id,
            }
          );

          const filter = [];
          let params = {};

          filter.push(`["is_template","must","","term","false"]`);
          if (props.searchRoleName !== null) {
            filter.push(
              `["role_name.keyword","must","","term","${props.searchRoleName}"]`
            );
          }
          params = {
            filters: `[${filter}]`,
            order_by: '[["modified_on", "desc"]]',
            page_limit: 15,
          };

          const param = {
            order_by: '[["modified_on", "desc"]]',
            page_limit: "none",
          };

          if (props.edit) {
            let obj_edit = {};
            Object.assign(
              obj_edit,
              values.role_name && {
                role_name: values.role_name,
              },
              values.page_mapping && {
                page_mapping: values.page_mapping,
              },
              {
                default_dashboard_id: values.default_dashboard
                  ?.report_template_id
                  ? values.default_dashboard?.report_template_id
                  : "",
              }
            );

            props
              .dispatch(
                RolesAction.requestPutRole(
                  JSON.stringify(obj_edit),
                  props.roleEditData.role_id
                )
              )
              .then(() => {
                props.dispatch(RolesAction.requestRole(params));
                props.dispatch(ManageRoleAction.requestRoleFilter(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            props.dispatch(RolesAction.requestPostRole(obj)).then(() => {
              const param = {
                order_by: '[["modified_on", "desc"]]',
                page_limit: "none",
              };
              params = {
                filters: `[${filter}]`,
                page_number: 0,
                order_by: '[["modified_on", "desc"]]',
                page_limit: 15,
              };

              props.dispatch(RolesAction.requestRole(params));
              props.dispatch(ManageRoleAction.requestRoleFilter(param));
              props.onClose();
              setLoading(false);
              if (!props.roles_List.includes(obj.role_name))
                props.onNavigateUserRoleMapping();
            });
          }
          // formikHelpers.resetForm();
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <Form>
            <Grid item lg={12} md={12} sm={12} xs={12} textAlign="left">
              <IconButton
                onClick={() => {
                  props.onClose();
                }}
              >
                <ArrowCircleLeftIcon
                  style={{
                    height: "45px",
                    width: "45px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent={"center"}
              my={2}
            >
              <h3 className="dailogtext">
                {!props.edit ? (
                  <b>
                    <span className="span_first_letter">A</span>
                    dd &nbsp;
                    <span className="span_first_letter">R</span>
                    ole &nbsp;
                  </b>
                ) : (
                  <b>
                    <span className="span_first_letter">E</span>
                    dit &nbsp;
                    <span className="span_first_letter">R</span>
                    ole &nbsp;
                  </b>
                )}
              </h3>
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              sx={{
                height: 600,
                justifyContent: "space-evenly",
              }}
              // className="bg-warning"
            >
              <Grid
                container
                lg={6}
                md={8}
                sm={10}
                sx={{ height: "max-content" }}
              >
                <Grid item lg={12} md={12} sm={12} container mb={1}>
                  <Grid item lg={2.5} md={2.5} sm={2.5} mt={1}>
                    Role Name * :
                  </Grid>
                  <Grid item lg={9.5} md={9.5} sm={9.5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="role_name"
                      size="small"
                      name="role_name"
                      placeholder="Role Name"
                      error={
                        Boolean(errors.role_name) && Boolean(touched.role_name)
                      }
                      helperText={
                        Boolean(touched.role_name) && errors.role_name
                      }
                    />
                  </Grid>
                </Grid>

                {!props.edit && (
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={2.5} md={2.5} sm={2.5} mt={3}>
                      Role Level * :
                    </Grid>
                    <Grid item lg={9.5} md={9.5} sm={9.5}>
                      <Field
                        name="role_level"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component
                        value={values.role_level}
                        component={AutoCompleteMultiSelect}
                        // disabled={props.edit || values.category}
                        options={role_level_list ? role_level_list : []}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Role Level",
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {values.role_level === "project" && (
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={2.5} md={2.5} sm={2.5} mt={3}>
                      Project * :
                    </Grid>
                    <Grid item lg={9.5} md={9.5} sm={9.5}>
                      <Field
                        name="project"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component
                        value={values.project ? values.project : null}
                        component={AutoCompleteMultiSelect}
                        options={props.project_array ? props.project_array : []}
                        disabled={
                          props.project_array &&
                          props.project_array.length === 1
                            ? true
                            : false
                        }
                        isOptionEqualToValue={(option, value) =>
                          //setting value property to custom value
                          option.project_name === value.project_name
                        }
                        getOptionLabel={(option) => option.project_name}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Project",
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {values.role_level === "organization" && (
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={2.5} md={2.5} sm={2.5} mt={3}>
                      Organization * :
                    </Grid>
                    <Grid item lg={9.5} md={9.5} sm={9.5}>
                      <Field
                        name="organization"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component
                        value={values.organization ? values.organization : null}
                        component={AutoCompleteMultiSelect}
                        options={
                          props.organization_array
                            ? props.organization_array
                            : []
                        }
                        disabled={
                          props.organization_array &&
                          props.organization_array.length === 1
                            ? true
                            : false
                        }
                        isOptionEqualToValue={(option, value) =>
                          //setting value property to custom value
                          option.organization_name === value.organization_name
                        }
                        getOptionLabel={(option) => option.organization_name}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Organization",
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={2.5} md={2.5} sm={2.5} mt={3}>
                    Role Template * :
                  </Grid>
                  <Grid item lg={9.5} md={9.5} sm={9.5}>
                    <Field
                      name="parent_role"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.parent_role ? values.parent_role : null}
                      component={AutoCompleteMultiSelect}
                      options={
                        props.templateRoleList ? props.templateRoleList : []
                      }
                      disabled={
                        props.templateRoleList &&
                        props.templateRoleList.length === 1
                          ? true
                          : props.edit
                      }
                      getOptionLabel={(option) => {
                        let role_name = option;
                        // Split the string into an array of words using "_" as the separator
                        let words = role_name.split("_");

                        // Capitalize the first letter of each word and join them with a space
                        let capitalized = words
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ");

                        return capitalized;
                      }}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Role Template",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={2.5} md={2.5} sm={2.5} mt={3}>
                    Default Dashboard :
                  </Grid>
                  <Grid item lg={9.5} md={9.5} sm={9.5}>
                    <Field
                      name="default_dashboard"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={
                        values.default_dashboard
                          ? values.default_dashboard
                          : null
                      }
                      // disabled={
                      //   props.reports_array && props.reports_array.length === 1
                      //     ? true
                      //     : false
                      // }
                      component={AutoCompleteMultiSelect}
                      options={props.reports_array ? props.reports_array : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.report_template_name ===
                        value.report_template_name
                      }
                      getOptionLabel={(option) => option.report_template_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Default Dashboard",
                      }}
                    />
                  </Grid>
                </Grid>

                {props.edit && (
                  <Grid item lg={12} md={12} sm={12} container mb={1}>
                    <Grid item lg={2.5} md={2.5} sm={2.5} mt={3}>
                      Pages :
                    </Grid>

                    <Grid
                      item
                      lg={9.5}
                      md={9.5}
                      sm={9.5}
                      className="scrollable"
                      sx={{ height: 300, overflow: "auto" }}
                    >
                      <ModelScreenContainer>
                        {values.page_mapping.map((page) => (
                          <Chip
                            label={page.page_name}
                            onDelete={async () => {
                              const newPages = selectedPages.filter(
                                (p) => p.page_id !== page.page_id
                              );
                              setSelectedPages(newPages);
                              let array = newPages.map((item) => {
                                return item.page_id;
                              });
                              setChecked(array);
                              setFieldValue("page_mapping", newPages);
                            }}
                            deleteIcon={<DeleteIcon />}
                            variant="outlined"
                            className="m-1"
                          />
                        ))}
                      </ModelScreenContainer>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  my={2}
                  display="flex"
                  justifyContent={"center"}
                >
                  <Grid
                    item
                    lg={2}
                    md={2}
                    sm={2}
                    mr={2}
                    // minWidth={200}
                    // className="bg-warning"
                  >
                    {!props.edit ? (
                      <MuiButton
                        loading={loading}
                        name={"Add Role "}
                        width={"100%"}
                        type="submit"
                      />
                    ) : (
                      <MuiButton
                        loading={loading}
                        name={"Submit"}
                        width={"100%"}
                        type="submit"
                      />
                    )}
                  </Grid>

                  <Grid
                    item
                    lg={2}
                    md={2}
                    sm={2}
                    // minWidth={200}
                    // className="bg-success"
                  >
                    {!props.edit ? (
                      <MuiButton
                        width={"100%"}
                        name={"Reset"}
                        type="reset"
                        onClick={Formik.resetForm}
                      />
                    ) : (
                      <MuiButton
                        name={"Cancel"}
                        width={"100%"}
                        onClick={() => {
                          props.onClose();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>

                {props.edit && (
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    display="flex"
                    justifyContent={"space-around"}
                    // className="bg-info"
                    mt={2}
                  >
                    <ListItem className="p-0 m-0">
                      <ReportGmailerrorredIcon style={{ marginLeft: 2 }} />{" "}
                      <span className="font-weight-bold">
                        The permission changes made on the role will be
                        applicable after logout and re-login to the application
                        by the role users
                      </span>
                    </ListItem>
                  </Grid>
                )}
              </Grid>
              {props.edit && (
                <Grid
                  item
                  container
                  lg={3}
                  md={3}
                  sm={3}
                  className="scrollable "
                  sx={{
                    height: "inherit",
                    overflow: "auto",
                    overscrollBehaviorY: "scroll",
                    backgroundColor: "white",
                    padding: 2,
                    borderRadius: theme.borderRadius,
                    border: "solid 0.5px #d3e2e6",
                    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                  }}
                >
                  <Grid
                    item
                    xl={12}
                    container
                    lg={12}
                    md={12}
                    sm={6}
                    xs={6}
                    justifyContent="flex-start"
                    alignItems={"flex-start"}
                  >
                    <Grid width={"100%"}>
                      <h5 align="center">Page Permission</h5>
                      {GridCellCheckboxRenderer(setFieldValue, values)}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default connect(mapDispatchToProps)(AddRolePost);
