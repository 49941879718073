import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import EffectUtility from "../../utilities/EffectUtility";
import PagePermissionGetModel from "./model/PagePermissionGetModel";

// PagePermissionEffect
export default class PagePermissionEffect {
  // calling an API according to the GoogleAccount model
  // Get method for PagePermissionEffect
  static async requestPagePermission(params) {
    const endpoint = environment.api.pagePermission;

    const response = await EffectUtility.getToModel(
      PagePermissionGetModel,
      endpoint,
      params
    );

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    if (response) {
      const show_demo_reports =
        response.allowed_pages?.includes("show_demo_reports") || false;
      localStorage.setItem("show_demo_reports", show_demo_reports);
    }

    localStorage.setItem(
      "page_permission",
      JSON.stringify(response.allowed_pages)
    );

    localStorage.setItem(
      "default_dashboard",
      JSON.stringify(response.default_dashboard)
    );

    return response;
  }
}
