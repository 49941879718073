import BaseReducer from "../../../utilities/BaseReducer";
import TrustpilotFeedsAction from "./TrustpilotFeedsAction";

export default class TrustpilotFeedsFilterReduser extends BaseReducer {
  initialState = {
    trustPilotFilter: [],
  };

  [TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      trustPilotFilter: action.payload,
    };
  }
}
