import ActionUtility from "../../../utilities/ActionUtility";
import EmailAccountEffect from "./EmailAccountEffect";
export default class EmailAccountAction {
  // EmailAccountAction action with an function declaration
  static REQUEST_EMAIL_ACCOUNT = "EmailAccountAction.REQUEST_EMAIL_ACCOUNT";
  static REQUEST_EMAIL_ACCOUNT_FINISHED =
    "EmailAccountAction.REQUEST_EMAIL_ACCOUNT_FINISHED";

  static REQUEST_EMAIL_ACCOUNT_FILTER =
    "EmailAccountAction.REQUEST_EMAIL_ACCOUNT_FILTER";
  static REQUEST_EMAIL_ACCOUNT_FILTER_FINISHED =
    "EmailAccountAction.REQUEST_EMAIL_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_EMAIL_ACCOUNT =
    "EmailAccountAction.REQUEST_PUT_EMAIL_ACCOUNT";
  static REQUEST_PUT_EMAIL_ACCOUNT_FINISHED =
    "EmailAccountAction.REQUEST_PUT_EMAIL_ACCOUNT_FINISHED";

  static REQUEST_POST_EMAIL_ACCOUNT =
    "EmailAccountAction.REQUEST_POST_EMAIL_ACCOUNT";
  static REQUEST_POST_EMAIL_ACCOUNT_FINISHED =
    "EmailAccountAction.REQUEST_POST_EMAIL_ACCOUNT_FINISHED";

  static REQUEST_DELETE_EMAIL_ACCOUNT =
    "EmailAccountAction.REQUEST_DELETE_EMAIL_ACCOUNT";
  static REQUEST_DELETE_EMAIL_ACCOUNT_FINISHED =
    "EmailAccountAction.REQUEST_DELETE_EMAIL_ACCOUNT_FINISHED";

  // METHODS
  // instagram GET function
  static requestEmailAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailAccountAction.REQUEST_EMAIL_ACCOUNT,
        EmailAccountEffect.requestEmailAccount,
        params
      );
    };
  }

  static requestEmailAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailAccountAction.REQUEST_EMAIL_ACCOUNT_FILTER,
        EmailAccountEffect.requestEmailAccountFilter,
        params
      );
    };
  }
  static requestPutEmailAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailAccountAction.REQUEST_PUT_EMAIL_ACCOUNT,
        EmailAccountEffect.requestPutEmailAccount,
        data,
        id
      );
    };
  }

  // EMAIL post function
  static requestPostEmailAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailAccountAction.REQUEST_POST_EMAIL_ACCOUNT,
        EmailAccountEffect.requestPostEmailAccount,
        data
      );
    };
  }

  // EMAIL delete function
  static requestDeleteEmailAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailAccountAction.REQUEST_DELETE_EMAIL_ACCOUNT,
        EmailAccountEffect.requestDeleteEmailAccount,
        id
      );
    };
  }
}
