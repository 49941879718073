import ActionUtility from "../../../utilities/ActionUtility";
import WhatsAppFeedEffect from "./WhatsAppFeedEffect";

export default class WhatsAppFeedAction {
  // WhatsAppFeedAction action with an function declaration
  static REQUEST_WHATSAPP_FEED = "WhatsAppFeedAction.REQUEST_WHATSAPP_FEED";
  static REQUEST_WHATSAPP_FEED_FINISHED =
    "WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_FINISHED";

  static REQUEST_WHATSAPP_FEED_FILTER =
    "WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_FILTER";
  static REQUEST_WHATSAPP_FEED_FILTER_FINISHED =
    "WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_FILTER_FINISHED";

  static REQUEST_WHATSAPP_POST_MESSAGE =
    "WhatsAppFeedAction.REQUEST_WHATSAPP_POST_MESSAGE";
  static REQUEST_WHATSAPP_POST_MESSAGE_FINISHED =
    "WhatsAppFeedAction.REQUEST_WHATSAPP_POST_MESSAGE_FINISHED";

  static REQUEST_WHATSAPP_FEED_CLEAR =
    "WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_CLEAR";
  static REQUEST_WHATSAPP_FEED_CLEAR_FINISHED =
    "WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_CLEAR_FINISHED";

  static REQUEST_PUT_WHATSAPP_FEED =
    "WhatsAppFeedAction.REQUEST_PUT_WHATSAPP_FEED";
  static REQUEST_PUT_WHATSAPP_FEED_FINISHED =
    "WhatsAppFeedAction.REQUEST_PUT_WHATSAPP_FEED_FINISHED";

  //   static REQUEST_POST_WHATSAPP_FEED =
  //     "WhatsAppFeedAction.REQUEST_POST_WHATSAPP_FEED";
  //   static REQUEST_POST_WHATSAPP_FEED_FINISHED =
  //     "WhatsAppFeedAction.REQUEST_POST_WHATSAPP_FEED_FINISHED";

  // METHODS
  // WhatsApp GET function
  static requestWhatsAppFeed(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsAppFeedAction.REQUEST_WHATSAPP_FEED,
        WhatsAppFeedEffect.requestWhatsAppFeed,
        params
      );
    };
  }

  static requestWhatsAppFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_CLEAR,
        WhatsAppFeedEffect.requestWhatsAppFeedClear
      );
    };
  }

  static requestWhatsAppFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_FILTER,
        WhatsAppFeedEffect.requestWhatsAppFeedFilter,
        params
      );
    };
  }
  static requestPutWhatsAppFeed(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsAppFeedAction.REQUEST_PUT_WHATSAPP_FEED,
        WhatsAppFeedEffect.requestPutWhatsAppFeed,
        data,
        id
      );
    };
  }

  // WhatsApp post function
  //   static requestPostWhatsAppFeed(data) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         WhatsAppFeedAction.REQUEST_POST_WHATSAPP_FEED,
  //         WhatsAppFeedEffect.requestPostWhatsAppFeed,
  //         data
  //       );
  //     };
  //   }

  //Whatsapp post message function
  static requestWhatsappPostMessage(data, id, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhatsAppFeedAction.REQUEST_WHATSAPP_POST_MESSAGE,
        WhatsAppFeedEffect.requestWhatsappPostMessage,
        data,
        id,
        callback
      );
    };
  }
}
