import BaseReducer from "../../../utilities/BaseReducer";
import GlassdoorAccountAction from "./GlassdoorAccountAction";

export default class GlassdoorAccountFilterReduser extends BaseReducer {
  //initial state of GlassdoorAccount
  initialState = {
    glassdoorAccountFilter: [],
  };
  //GlassdoorAccount request action finish
  [GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      glassdoorAccountFilter: action.payload,
    };
  }
}
