import BaseReducer from "../../../../utilities/BaseReducer";
import ReportUploadAction from "./ReportUploadAction";

export default class CsvUploadSummaryByIDReducer extends BaseReducer {
  initialState = {
    csvUploadSummaryByID: [],
  };
  [ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      csvUploadSummaryByID: action.payload,
    };
  }
}
