import BaseReducer from "../../../../utilities/BaseReducer";
import TerritoriesAction from "./TerritoriesAction";

// TerritoriesReduser
export default class TerritoriesReduser extends BaseReducer {
  initialState = {
    territories: [],
  };
  [TerritoriesAction.REQUEST_TERRITORIES_FINISHED](state, action) {
    return {
      ...state,
      territories: action.payload,
    };
  }

  //   [LabelAction.REQUEST_LABELS_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       paln: action.payload,
  //     };
  //   }
}
