import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

export default class DataExtractionProjectsModel extends BaseModel {
  data = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
