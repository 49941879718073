import BaseReducer from "../../../../utilities/BaseReducer";
import ActivityTypeAction from "./ActivityTypeAction";

export default class ActivityTypeReducer extends BaseReducer {
  initialState = {
    activityType: [],
  };

  [ActivityTypeAction.REQUEST_ACTIVITY_TYPE_FINISHED](state, action) {
    return {
      ...state,
      activityType: action.payload,
    };
  }
}
