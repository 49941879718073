import BaseReducer from "../../../../utilities/BaseReducer";
import ChannelAnalyticsAccountAction from "./ChannelAnalyticsAccountAction";

export default class ChannelAnalyticsAccountReducer extends BaseReducer {
  initialState = {
    channelAnalyticsAccount: [],
  };

  [ChannelAnalyticsAccountAction.REQUEST_CHANNEL_ANALYTICS_ACCOUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      channelAnalyticsAccount: action.payload,
    };
  }
}
