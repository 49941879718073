import BaseReducer from "../../../../utilities/BaseReducer";
import GoogleSearchAction from "./GoogleSearchAction";

export default class GoogleSearchExportReduser extends BaseReducer {
  initialState = {
    websweepxGoogleSearchDiscoveredExport: [],
  };
  [GoogleSearchAction.REQUEST_GOOGLE_DISCOVERED_DATA_EXPORT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepxGoogleSearchDiscoveredExport: action.payload,
    };
  }
}
