import ActionUtility from "../../../utilities/ActionUtility";
import CloudSegmentCriteriaEffect from "./CloudSegmentCriteriaEffect";

export default class CloudSegmentCriteriaAction {
  static REQUEST_CLOUD_SEGMENT_CRITERIA =
    "CloudSegmentCriteriaAction.REQUEST_CLOUD_SEGMENT_CRITERIA";
  static REQUEST_CLOUD_SEGMENT_CRITERIA_FINISHED =
    "CloudSegmentCriteriaAction.REQUEST_CLOUD_SEGMENT_CRITERIA_FINISHED";

  static requestCloudMailSegment(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CloudSegmentCriteriaAction.REQUEST_CLOUD_SEGMENT_CRITERIA,
        CloudSegmentCriteriaEffect.requestCloudMailSegment,
        params
      );
    };
  }
}
