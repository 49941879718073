import environment from "environment";
import UserPostModel from "./models/UserPostModel";
import UserPutModel from "./models/UserPutModel";
import UserByIdModel from "./models/UserByIdModel";
import SurveyUserModel from "./models/SurveyUserModel";
import HttpErrorResponseModel from "../../../../models/HttpErrorResponseModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import AdminResetPasswordPutModel from "../../../../ormTicketing/stores/user/models/AdminResetPasswordPutModel";
import UserResetPasswordPutModel from "../../../../ormTicketing/stores/user/models/UserResetPasswordPutModel";
import SurveyUserDeviceMapGetModel from "./models/SuverUserDeviceMapModel";
import SurveyUserDeviceResetDeleteModel from "./models/SurveyUserDeviceResetModel";

export default class SurveyUserEffect {
  //get USER request end point
  static async requestSurveyUser(params) {
    const endpoint = environment.api.surveyUser;

    return EffectUtility.getToModel(SurveyUserModel, endpoint, params);
  }
  //   // Post api
  static async requestPostSurveyUser(data, callBack) {
    const endpoint = environment.api.surveyUser;

    let response = EffectUtility.postToModel(UserPostModel, endpoint, data);

    response.then((res) => {
      if (res.status === "success" && callBack) {
        callBack();
      }
    });
    return response;
  }

  //Put api
  static async requestPutSurveyUser(data, id) {
    const endpoint = environment.api.surveyUser + "/" + id;

    return EffectUtility.putToModel(UserPutModel, endpoint, data);
  }

  //Username Filter api
  static async requestFilterSurveyUserName() {
    const endpoint = environment.api.surveyUser;

    return EffectUtility.getToModel(SurveyUserModel, endpoint);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }

  //get USER request end point with page_limit none
  static async requestSurveyUserFilter() {
    const endpoint = environment.api.surveyUserFilter;

    return EffectUtility.getToModel(SurveyUserModel, endpoint);
  }

  // my profile single userbyId
  static async requestSurveyUserById(id) {
    const endpoint = environment.api.surveyUser + "/" + id;

    return EffectUtility.getToModel(UserByIdModel, endpoint);
  }

  static async requestAdminResetPasswordPut(data) {
    const endpoint = environment.api.surveyAdminResetPassword;
    return EffectUtility.putToModel(AdminResetPasswordPutModel, endpoint, data);
  }

  static async requestUserResetPassword(data) {
    const endpoint = environment.api.surveyUserResetPassword;
    return EffectUtility.putToModel(UserResetPasswordPutModel, endpoint, data);
  }

  static async requestSurveyUserDeviceMapping(params) {
    const endpoint = environment.api.surveyDeviceMapping;
    return EffectUtility.getToModel(
      SurveyUserDeviceMapGetModel,
      endpoint,
      params
    );
  }

  //delete request endpoint for mobile device
  static async requestSurveyUserDeviceMapDelete(id) {
    const endpoint = environment.api.surveyDeviceMapping + "/" + id;
    return EffectUtility.deleteToModel(
      SurveyUserDeviceResetDeleteModel,
      endpoint
    );
  }
}
