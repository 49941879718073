import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ActivityInsightGetModel from "./models/ActivityInsightGetModel";
import ActivityInsightReportGetModel from "./models/ActivityInsightReportGetModel";

export default class ActivityInsightEffect {
  static async requestActivityInsight(params) {
    const endpoint = environment.api.activityInsight;

    return EffectUtility.getToModel(ActivityInsightGetModel, endpoint, params);
  }

  static async requestActivityInsightReport(params) {
    const endpoint = environment.api.activityInsightReport;

    return EffectUtility.getToModel(
      ActivityInsightReportGetModel,
      endpoint,
      params
    );
  }
}
