import { BaseModel } from "sjs-base-model";

export default class TelegramChannelWiseLinksModel extends BaseModel {
  res = {};

  constructor(data) {
    super();
    this.update(data);
  }
}
