import ActionUtility from "../../../../utilities/ActionUtility";
import RevenueTransactionEffect from "./RevenueTransactionEffect";

export default class RevenueTransactionAction {
  static REQUEST_REVENUE_TRANSACTION =
    "RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION";
  static REQUEST_REVENUE_TRANSACTION_FINISHED =
    "RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION_FINISHED";

  static REQUEST_POST_REVENUE_TRANSACTION =
    "RevenueTransactionAction.REQUEST_POST_REVENUE_TRANSACTION";
  static REQUEST_POST_REVENUE_TRANSACTION_FINISHED =
    "RevenueTransactionAction.REQUEST_POST_REVENUE_TRANSACTION_FINISHED";

  static REQUEST_REVENUE_TRANSACTION_CLEAR =
    "RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION_CLEAR";
  static REQUEST_REVENUE_TRANSACTION_CLEAR_FINISHED =
    "RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION_CLEAR_FINISHED";

  // METHODS
  static requestRevenueTransaction(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION,
        RevenueTransactionEffect.requestRevenueTransaction,
        params
      );
    };
  }

  static requestRevenueTransactionClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueTransactionAction.REQUEST_REVENUE_TRANSACTION_CLEAR,
        RevenueTransactionEffect.requestRevenueTransactionClear
      );
    };
  }

  static requestPostRevenueTransaction(data) {
    console.log("Data", data);
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueTransactionAction.REQUEST_POST_REVENUE_TRANSACTION,
        RevenueTransactionEffect.requestPostRevenueTransaction,
        data
      );
    };
  }
}
