import BaseReducer from "../../../utilities/BaseReducer";
import TurnAroundTimeAction from "./TurnAroundTimeAction";

export default class TurnAroundTimeReducer extends BaseReducer {
  //initial state of Ticket turn around time
  initialState = {
    ticketAvgTat: [],
  };

  //Ticket turn around time request action finish
  [TurnAroundTimeAction.REQUEST_TURNAROUNDTIME_FINISHED](state, action) {
    return {
      ...state,
      ticketAvgTat: action.payload,
    };
  }
}
