import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";
import TicketSettingsHitsModel from "./TicketSettingsHitsModel";

export default class TicketSettingsModel extends BaseModel {
  //ticketType data model
  _shards = {};
  current_page_no = 0;
  hits = { TicketSettingsHitsModel };
  timed_out = boolean;
  took = 0;
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
