import { BaseModel } from "sjs-base-model";
import EmailFeedsShardsModel from "./EmailFeedsShardsModel";
import EmailFeedsHitsModel from "./EmailFeedsHitsModel";

// get model for EmailFeeds api fetch model
export default class EmailFeedsModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = { _shards: EmailFeedsShardsModel };
  hits = { hits: EmailFeedsHitsModel };
  total_page_no = 0;
  current_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
