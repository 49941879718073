import BaseReducer from "../../../utilities/BaseReducer";
import GlassdoorFeedsAction from "./GlassdoorFeedsAction";

export default class GlassdoorFeedsFilterReduser extends BaseReducer {
  //initial state of GlassdoorFeedsFilterReduser
  initialState = {
    glassdoorFeedsFilter: [],
  };
  //GlassdoorFeedsFilterReduser request action finish
  [GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_FILTER_FINISHED](state, action) {
    return {
      ...state,
      glassdoorFeedsFilter: action.payload,
    };
  }
}
