import React, { useState, useEffect, useRef } from "react";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import PaginationUI from "../../../components/pagination/Pagination";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

function BridgeUsersAvgTAT(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.agentReports
    }?report_type=["count"]&reports=["agent_based_on_platform_wise_tat"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });
  }
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter).then((e) => setData(e));
      setLoading(false);
    })();
  }, [props.DateFilter]);
  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter).then((e) => setData(e));
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter).then((e) => setData(e));
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  let PlatformNameList = [];
  data &&
    data.agent_report &&
    data.agent_report.agent_based_on_platform_wise_tat &&
    data.agent_report.agent_based_on_platform_wise_tat.length &&
    data.agent_report.agent_based_on_platform_wise_tat.map((item) => {
      item &&
        item[Object.keys(item)].map((val) => {
          PlatformNameList.push(Object.keys(val)[0]);
        });
    });

  PlatformNameList = new Set([...PlatformNameList]);

  PlatformNameList = [...new Set(PlatformNameList)];

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  let updatedRows = [];

  data &&
    data.agent_report &&
    data.agent_report.agent_based_on_platform_wise_tat.length &&
    data.agent_report.agent_based_on_platform_wise_tat.map((item, index) => {
      if (Object.keys(item)[0] !== "Unassigned") {
        const rows = {
          id: index + 1,
          agent_name: Object.keys(item)[0],
        };

        PlatformNameList.map((key) => {
          if (key) {
            const filteredPlatform = Object.values(item)[0].find(
              (val) => Object.keys(val)[0] === key
            );

            if (
              filteredPlatform &&
              platformName(Object.keys(filteredPlatform)[0])
            ) {
              rows[platformName(Object.keys(filteredPlatform)[0])] = {
                count:
                  Object.values(filteredPlatform)[0] &&
                  Object.values(filteredPlatform)[0].count,
                value:
                  Object.values(filteredPlatform)[0] &&
                  HoursAndMinutes(Object.values(filteredPlatform)[0].value),
              };
            } else {
              rows[platformName(key)[0]] = {
                count: 0,
                value: "00:00:00",
              };
            }
          }
        });

        updatedRows.push(rows);
      }
    });

  let columns = [];

  columns = [
    // { field: "id", headerName: "Sl No", resizable: false, width: 50 },
    {
      field: "agent_name",
      headerName: "Bridge User",
      width: 150,
      resizable: true,
    },
  ];

  PlatformNameList &&
    PlatformNameList.map((item) => {
      if (item && platformName(item)[0]) {
        columns.push({
          field: platformName(item)[0],
          headerName: (
            <div
              style={{
                lineHeight: "23px",
                minWidth: "150px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {platformName(item)[0]}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <span>{"Count"}</span>
                <span>{"TAT"}</span>
              </div>
            </div>
          ),

          resizable: true,
          width: 180,

          renderCell: (params) => (
            <div
              style={{
                lineHeight: "23px",
                minWidth: "150px",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <span>
                {params.row[platformName(item)[0]] &&
                  prefixZero_for_singleDigit(
                    params.row[platformName(item)[0]].count
                  )}
              </span>
              <span>
                {params.row[platformName(item)[0]] &&
                  params.row[platformName(item)[0]].value}
              </span>
            </div>
          ),
        });
      }
    });

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  const totalRows = updatedRows && updatedRows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const finalRows = updatedRows && updatedRows.slice(startIndex, endIndex);

  let NoDataFoundBool =
    data &&
    data.agent_report &&
    data.agent_report.agent_based_on_platform_wise_tat.length === 0;

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  // const summaryBoxRef = useRef(null);

  // let dataGridHeight = useRef(null);

  // useEffect(() => {
  //   const calculateHeights = () => {
  //     const outerBoxHeight = summaryBoxRef.current.clientHeight;

  //     if (
  //       summaryBoxRef.current &&
  //       summaryBoxRef.current.children[1] &&
  //       summaryBoxRef.current.children[0]
  //     ) {
  //       summaryBoxRef.current.children[0].style.height = 50;

  //       dataGridHeight.current = outerBoxHeight - 75;
  //     }
  //   };

  //   calculateHeights();

  //   // Use ResizeObserver to listen for changes in the size of summaryBoxRef
  //   const resizeObserver = new ResizeObserver(calculateHeights);
  //   resizeObserver.observe(summaryBoxRef.current);

  //   // Cleanup ResizeObserver on component unmount
  //   return () => {
  //     resizeObserver.disconnect();
  //   };
  // }, []); // Empty dependency array to ensure the effect runs only once on mount

  // const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.forEach((item) => {
        const dataObject = {
          ["Bridge User"]: item?.agent_name, // Add the "Bridge User" key with agent_name value
        };

        // Iterate over the keys in the item object
        Object.keys(item).forEach((key) => {
          if (key !== "id" && key !== "agent_name") {
            // Exclude "id" and "agent_name"
            const { count, value } = item[key]; // Destructure count and value
            dataObject[`${key} (Count | TAT)`] = `${prefixZero_for_singleDigit(
              count
            )}|${value}`; // Set key-value pair in the dataObject
          }
        });

        exportData.push(dataObject); // Add the dataObject to exportData array
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    // <Box
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //     // padding: "20px",
    //   }}
    // >
    <LoadingIndicator isActive={loading}>
      {/* <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} /> */}
      {/* <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        > */}
      {/* <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontSize: theme.reports.headerSize,
                padding: theme.reports.headerPadding,
              }}
            >
              {firstLetterOrange(props.report && props.report)}
            </Typography>
            <hr className="p-0 m-0 " />
          </Box> */}

      <Box>
        {!loading ? (
          <>
            {!loading && !NoDataFoundBool ? (
              <Box sx={{ padding: "12px" }}>
                <Grid container lg={12} md={12} sm={12} sx={{ height: 420 }}>
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      onCellClick={onCellClick}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                      headerHeight={80}
                      initialState={{
                        pinnedColumns: { left: ["agent_name"] },
                      }}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
      {/* </Box> */}
    </LoadingIndicator>
  ) : // </Box>
  null;
}
export default BridgeUsersAvgTAT;
