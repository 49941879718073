import BaseReducer from "../../utilities/BaseReducer";
import ReportTemplateAction from "./ReportTemplateAction";

export default class ReportTemplateReducerFilter extends BaseReducer {
  initialState = {
    reportTemplateFilter: [],
  };

  [ReportTemplateAction.REQUEST_REPORT_TEMPLATE_FILTER_FINSIHED](
    state,
    action
  ) {
    return {
      ...state,
      reportTemplateFilter: action.payload,
    };
  }
}
