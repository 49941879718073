import BaseReducer from "../../../../utilities/BaseReducer";
import DsrActivityShadowAction from "./DsrActivityShadowAction";

export default class DsrActivityShadowReducer extends BaseReducer {
  initialState = {
    dsrActivityShadow: [],
  };
  [DsrActivityShadowAction.REQUEST_GET_DSR_ACTIVITY_SHADOW_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      dsrActivityShadow: action.payload,
    };
  }
}
