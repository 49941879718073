import BaseReducer from "../../../../utilities/BaseReducer";
import TopTerritoriesAction from "./TopTerritoriesAction";

export default class TopTerritoryReduser extends BaseReducer {
  initialState = {
    top_territory: [],
  };
  [TopTerritoriesAction.REQUEST_TOP_TERRITORY_FINISHED](state, action) {
    return {
      ...state,
      top_territory: action.payload,
    };
  }
}
