import BaseReducer from "../../../../utilities/BaseReducer";
import RedditAccountAction from "./RedditAccountAction";

export default class RedditAccountReducer extends BaseReducer {
  initialState = {
    redditAccount: [],
  };
  [RedditAccountAction.REQUEST_REDDIT_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      redditAccount: action.payload,
    };
  }
}
