import environment from "environment";
import InfluencerFacebookPlatformPostModel from "./models/InfluencerFacebookPlatformPostModel";
import InfluencerInstagramPlatformPostModel from "./models/InfluencerInstagramPlatformPostmodel";
import InfluencerPlatformAccountsModel from "./models/InfluencerPlatformAccountsModel";
import InfluencerQuoraPlatformPostModel from "./models/InfluencerQuoraPlatformPostModel";
import InfluencerQuoraPlatformPutModel from "./models/InfluencerQuoraPlatformPutModel";
import InfluencerYoutubePlatformPostModel from "./models/InfluencerYoutubePlatformPostModel";
import EffectUtility from "../../../../utilities/EffectUtility";

export default class InfluencerPlatformEffect {
  static async requestInfluencerPlatforms(params) {
    const endpoint = environment.api.influencerPlatformAccounts;
    return EffectUtility.getToModel(
      InfluencerPlatformAccountsModel,
      endpoint,
      params
    );
  }

  static async requestInfluencerPlatformsFilter(params) {
    const endpoint = environment.api.influencerPlatformAccountsFilter;
    return EffectUtility.getToModel(
      InfluencerPlatformAccountsModel,
      endpoint,
      params
    );
  }

  static async requestPostInfluencerFacebookAccount(data) {
    const endpoint = environment.api.influencerFacebookPlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerFacebookPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPostInfluencerInstagramAccount(data) {
    const endpoint = environment.api.influencerInstagramPlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerInstagramPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPostInfluencerYoutubeAccount(data) {
    const endpoint = environment.api.influencerYoutubePlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerYoutubePlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerFacebookAccount(data, id) {
    const endpoint = environment.api.influencerFacebookPlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerFacebookPlatformPostModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  static async requestPutInfluencerInstagramAccount(data, id) {
    const endpoint = environment.api.influencerInstagramPlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerInstagramPlatformPostModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  static async requestPutInfluencerYoutubeAccount(data, id) {
    const endpoint = environment.api.influencerYoutubePlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerYoutubePlatformPostModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  static async requestPostInfluencerQuoraAccount(data) {
    const endpoint = environment.api.influencerQuoraPlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerQuoraAccount(data, id) {
    const endpoint = environment.api.influencerQuoraPlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  static async requestPostInfluencerPlaystoreAccount(data) {
    const endpoint = environment.api.influencerPlaystorePlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerPlaystoreAccount(data, id) {
    const endpoint = environment.api.influencerPlaystorePlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  //twitter platform

  static async requestPostInfluencerTwitterAccount(data) {
    const endpoint = environment.api.influencerTwitterPlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerTwitterAccount(data, id) {
    const endpoint = environment.api.influencerTwitterPlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  //whatsapp platform

  static async requestPostInfluencerWhatsappAccount(data) {
    const endpoint = environment.api.influencerWhatsappPlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerWhatsappAccount(data, id) {
    const endpoint = environment.api.influencerWhatsappPlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  //AmbitionBox platform

  static async requestPostInfluencerAmbitionBoxAccount(data) {
    const endpoint = environment.api.influencerAmbitionBoxPlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerAmbitionBoxAccount(data, id) {
    const endpoint = environment.api.influencerAmbitionBoxPlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  //AmbitionBox platform

  static async requestPostInfluencerAppstoreAccount(data) {
    const endpoint = environment.api.influencerAppstorePlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerAppstoreAccount(data, id) {
    const endpoint = environment.api.influencerAppstorePlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  // glassdoor platform

  static async requestPostInfluencerGlassdoorAccount(data) {
    const endpoint = environment.api.influencerGlassdoorPlatform + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerGlassdoorAccount(data, id) {
    const endpoint = environment.api.influencerGlassdoorPlatform + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  // email platfrom

  static async requestPostInfluencerEmailAaccount(data) {
    const endpoint = environment.api.influencerEmailAccount + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerEmailAaccount(data, id) {
    const endpoint = environment.api.influencerEmailAccount + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  // telegram account
  static async requestPostInfluencerTelegramAccount(data) {
    const endpoint = environment.api.influencerTelegramAccount + "/add";
    return EffectUtility.postToModel(
      InfluencerQuoraPlatformPostModel,
      endpoint,
      data
    );
  }

  static async requestPutInfluencerTelegramAccount(data, id) {
    const endpoint = environment.api.influencerTelegramAccount + "/" + id;
    return EffectUtility.putToModel(
      InfluencerQuoraPlatformPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }
}
