import environment from "environment";
import TrustpilotAccountGetModel from "./model/TrustpilotAccountGetModel";
import TrustpilotAccountPostModel from "./model/TrustpilotAccountPostModel";
import TrustpilotAccountPutModel from "./model/TrustpilotAccountPutModel";
import TrustPilotAccountDeleteModel from "./model/TrustPilotAccountDeleteModel";
import EffectUtility from "../../../../utilities/EffectUtility";

// TrustpilotAccountEffect
export default class TrustpilotAccountEffect {
  // calling an API according to the GoogleAccount model
  // Get method for Trustpilot account
  static async requestTrustpilotAccount(params) {
    const endpoint = environment.api.trustpilotaccount;
    return EffectUtility.getToModel(
      TrustpilotAccountGetModel,
      endpoint,
      params
    );
  }

  static async requestPostTrustpilotAccount(data) {
    const endpoint = environment.api.trustpilotaccount + "/add";

    return EffectUtility.postToModel(
      TrustpilotAccountPostModel,
      endpoint,
      data
    );
  }

  // Get method for Trustpilot account filter
  static async requestTrustpilotAccountFilter(params) {
    const endpoint = environment.api.trustpilotaccount;
    return EffectUtility.getToModel(
      TrustpilotAccountGetModel,
      endpoint,
      params
    );
  }

  // put method for Trustpilot account filter
  static async requestPutTrustpilotAccount(data, id) {
    const endpoint = environment.api.trustpilotaccount + "/" + id;
    return EffectUtility.putToModel(TrustpilotAccountPutModel, endpoint, data);
  }

  // delete method for Trustpilot account filter
  static async requestDeleteTrustpilotAccount(id) {
    const endpoint = environment.api.trustpilotaccount + "/" + id;
    return EffectUtility.deleteToModel(TrustPilotAccountDeleteModel, endpoint);
  }
}
