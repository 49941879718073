import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
} from "@mui/material";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import ReportsLogoAction from "../../../stores/reportsLogo/ReportsLogoAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";

const mapDispatchToProps = (dispatch) => {
  return {};
};
function ReportLogoPostForm(props) {
  let initial = {};
  if (props.edit) {
    initial = {
      logo_name: props.logoEditData.logo_name,
      logo_image: props.logoEditData.logo_image,
      logo_image_url: props.logoEditData.logo_image_url,
      boolean: true,
    };
  } else {
    initial = {
      logo_name: "",
      logo_image: "",
      logo_image_url: "",
    };
  }

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    logo_name: yup
      .string("Enter Logo name")
      .trim()
      .min(1, "Logo name should be of minimum 1 characters length")
      .required("Logo name is required"),

    logo_image: yup
      .mixed()
      .required("Image is required")
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && value.type.startsWith("image/")
      ),
  });

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        // validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        validate={(values) => {
          var errors = {};
          if (!values.logo_name) {
            errors.logo_name = "Logo name is required";
          }

          if (!props.edit && !values.logo_image) {
            errors.logo_image = "Image url is required";
          }

          if (props.edit && !values.logo_image_url) {
            errors.logo_image = "Image url is required";
          }

          return errors;
        }}
        onSubmit={(values) => {
          setLoading(true);

          let formData = new FormData();

          values.logo_name && formData.append("logo_name", values.logo_name);
          values.logo_image && formData.append("logo_image", values.logo_image);

          // parameter for showing list based on the modified_by
          const params = {
            order_by: '[["modified_on", "desc"]]',
            page_limit: 15,
          };
          const param = {
            order_by: `[["logo_name.keyword", "asc"]]`,
            page_limit: "none",
          };

          if (props.edit) {
            props
              .dispatch(
                ReportsLogoAction.requestReportsLogoPut(
                  props.logoEditData.logo_id,
                  formData
                )
              )
              .then(() => {
                props.dispatch(
                  ReportsLogoAction.requestReportsLogoFilter(params)
                );
                props.dispatch(ReportsLogoAction.requestReportsLogo(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(ReportsLogoAction.requestReportsLogoPost(formData))
              .then(() => {
                props.dispatch(
                  ReportsLogoAction.requestReportsLogoFilter(params)
                );
                props.dispatch(ReportsLogoAction.requestReportsLogo(param));
                props.onClose();
                setLoading(false);
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">A</span>
                            dd &nbsp;
                            <span className="span_first_letter">L</span>ogo
                            &nbsp;
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">E</span>
                            dit &nbsp;
                            <span className="span_first_letter">L</span>ogo
                            &nbsp;
                          </b>
                        )}
                      </h3>
                    </div>
                    <div className="col-2 closebtn">
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item ml={2} lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Logo Name * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="logo_name"
                      size="small"
                      name="logo_name"
                      placeholder="Logo Name"
                      error={
                        Boolean(errors.logo_name) && Boolean(touched.logo_name)
                      }
                      helperText={
                        Boolean(touched.logo_name) && errors.logo_name
                      }
                    />
                  </Grid>
                </Grid>

                {!values.boolean && (
                  <Grid container mt={2}>
                    <Grid item ml={2} lg={12} md={12} sm={12} container>
                      <Grid item lg={3} md={3} sm={3} mt={1}>
                        Upload Logo * :
                      </Grid>
                      <Grid item lg={8} md={8} sm={8}>
                        <input
                          type="file"
                          accept="image/*"
                          name="logo_image"
                          onChange={(e) => {
                            setFieldValue("logo_image", e.target.files[0]);
                            setFieldValue("logo_image_url", "data");
                          }}
                        />
                        <Box px={1}>
                          <ErrorMessage name="logo_image" />
                        </Box>
                        {/* <Field
                        type="file"
                        name="logo_image"
                        variant="outlined"
                        as={MuiTextField}
                        size="small"
                        placeholder="Select image"
                        error={
                          Boolean(errors.logo_image) && Boolean(touched.logo_image)
                        }
                        helperText={
                          Boolean(touched.logo_image) && errors.logo_image
                        }
                      /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {values.boolean && (
                  <Grid container mt={2}>
                    <Grid item ml={2} lg={12} md={12} sm={12} container>
                      <Grid item lg={3} md={3} sm={3} mt={1}>
                        Selected Logo :
                      </Grid>
                      <Grid item lg={8} md={8} sm={8}>
                        <Chip
                          avatar={
                            <Avatar src={props.logoEditData.logo_image_url} />
                          }
                          onDelete={() => {
                            setFieldValue("boolean", false);
                            setFieldValue("logo_image_url", "");
                          }}
                          deleteIcon={
                            <IconButton
                              onClick={() => {
                                setFieldValue("boolean", false);
                                setFieldValue("logo_image_url", "");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Logo "}
                      type="submit"
                      loading={loading}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      name={"Submit"}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapDispatchToProps)(ReportLogoPostForm);
