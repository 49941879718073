import ActionUtility from "../../../utilities/ActionUtility";
import PriorityEffect from "./PriorityEffect";

export default class PriorityAction {
  //priority requests
  static REQUEST_PRIORITY = "PriorityAction.REQUEST_PRIORITY";
  static REQUEST_PRIORITY_FINISHED = "PriorityAction.REQUEST_PRIORITY_FINISHED";

  //priority post
  static REQUEST_POST_PRIORITY = "PriorityAction.REQUEST_POST_PRIORITY";
  static REQUEST_POST_PRIORITY_FINISHED =
    "PriorityAction.REQUEST_POST_PRIORITY";

  //priority put
  static REQUEST_PUT_PRIORITY = "PriorityAction.REQUEST_PUT_PRIORITY";
  static REQUEST_PUT_PRIORITY_FINISHED =
    "PriorityAction.REQUEST_PUT_PRIORITY_FINISHED";

  //priority list (type get method FOR FILTER)
  static REQUEST_PRIORITY_FILTER = "PriorityAction.REQUEST_PRIORITY_FILTER";
  static REQUEST_PRIORITY_FILTER_FINISHED =
    "PriorityAction.REQUEST_PRIORITY_FILTER_FINISHED";

  //Priority put Bulk
  static REQUEST_PUT_PRIORITY_BULKUPDATE =
    "PriorityAction.REQUEST_PUT_PRIORITY_BULKUPDATE";
  static REQUEST_PUT_PRIORITY_BULKUPDATE_FINISHED =
    "PriorityAction.REQUEST_PUT_PRIORITY_BULKUPDATE_FINISHED";

  // METHODS
  //priority list get method
  static requestPriority(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PriorityAction.REQUEST_PRIORITY,
        PriorityEffect.requestPriority,
        params
      );
    };
  }

  //TicketType POST method
  static requestPostPriority(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PriorityAction.REQUEST_POST_PRIORITY,
        PriorityEffect.requestPostPriority,
        data
      );
    };
  }

  // TicketType put function
  static requestPutPriority(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PriorityAction.REQUEST_PUT_PRIORITY,
        PriorityEffect.requestPutPriority,
        data,
        id
      );
    };
  }

  //priority list get method FOR FILTER
  static filterRequestPriority(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PriorityAction.REQUEST_PRIORITY_FILTER,
        PriorityEffect.filterRequestPriority,
        params
      );
    };
  }

  // Priority Bulk put function
  static requestPutPriorityBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PriorityAction.REQUEST_PUT_PRIORITY_BULKUPDATE,
        PriorityEffect.requestPutPriorityBulkUpdate,
        data
      );
    };
  }
}
