import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import ResponseCategoryModel from "./models/ResponseCategoryModel";
import ResponseCategoryPostModel from "./models/ResponseCategoryPostModel";
import ResponseCategoryPutModel from "./models/ResponseCategoryPutModel";

export default class ResponseCategoryEffect {
  // calling an API according to the categoryModel
  static async requestResponseCategory(params) {
    const endpoint = environment.api.responseCategory;

    return EffectUtility.getToModel(ResponseCategoryModel, endpoint, params);
  }

  // category filter with page_limit none
  static async requestResponseCategoryFilter(params) {
    const endpoint = environment.api.responseCategoryFilter;

    return EffectUtility.getToModel(ResponseCategoryModel, endpoint, params);
  }

  // Post API
  static async requestPostResponseCategory(data) {
    const endpoint = environment.api.responseCategory + "/add";

    return EffectUtility.postToModel(ResponseCategoryPostModel, endpoint, data);
  }

  // Put API
  static async requestPutResponseCategory(data, id) {
    const endpoint = environment.api.responseCategory + "/" + id;

    return EffectUtility.putToModel(ResponseCategoryPutModel, endpoint, data);
  }
}
