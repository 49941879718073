import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import { connect } from "react-redux";
import { theme } from "../../../../views/App";
import QuoraFeedsAction from "../../../stores/quoraFeeds/QuoraFeedsAction";
import MuiTextField from "../../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../../components/muiButton/MuiButton";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const PostUrl = (props) => {
  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method
  let initial = {};

  const [url_value, setUrl_value] = useState("");

  const [theArray, setTheArray] = useState([]);

  const url_value_handlechnage = (event) => {
    if (
      event.target.value.length > 1 ||
      event.target.value !== null ||
      event.target.value.trim()
    ) {
      setUrl_value(event.target.value);
    }
  };

  const addEntryClick = () => {
    if (url_value != "" && url_value.trim() != "") {
      setTheArray([...theArray, url_value]);
      setUrl_value("");
    } else if (url_value === "" && url_value.trim() === "") {
      alert("Provide proper URL");
    }
    setUrl_value("");
  };

  initial = {
    question_url: theArray,
  };

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        // validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);
          if (theArray.length > 0) {
            // parameter for showing list based on the modified_by
            const params = {
              order_by: '[["modified_on", "desc"]]',
              page_number: 0,
              page_limit: 15,
            };
            props
              .dispatch(
                QuoraFeedsAction.requestPostQuoraFeed(JSON.stringify(values))
              )
              .then(() => {
                const params = {
                  page_number: 0,
                  page_limit: 10,
                };
                props.dispatch(QuoraFeedsAction.requestQuoraFeed(params));
                // props.dispatch(ProjectAction.requestProjectFilter(params));
                props.onClose();
                setTheArray([]);
                setUrl_value("");
                setLoading(false);
              });
          } else {
            alert("Please add URL");
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%">
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext ml-5">
                        <b>
                          <span className="span_first_letter">A</span>
                          dd &nbsp;
                          <span className="span_first_letter">U</span>rl &nbsp;
                        </b>
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div className="col-2 closebtn">
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                          onClick={() => {
                            props.onClose();
                            setTheArray([]);
                            setUrl_value("");
                          }}
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              <Grid container>
                {theArray.length > 0 && (
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    px={2}
                    py={1}
                    // className="bg-warning"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      mt={1}
                      // className="bg-success"
                    >
                      List :
                    </Grid>

                    <Grid
                      item
                      xl={10.9}
                      lg={10.9}
                      md={10.9}
                      sm={10.9}
                      mt={1}
                      p={1}
                      // className="bg-info"
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        border: "solid 0.5px #d3e2e6",
                        borderRadius: theme.borderRadius,
                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                      }}
                    >
                      {theArray.map((entry, i) => (
                        <Typography>{`${i + 1}. ${entry}`},&nbsp;</Typography>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  px={2}
                  py={1}
                  // className="bg-warning"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    mt={1}
                    // className="bg-success"
                  >
                    Url * :
                  </Grid>
                  <Grid
                    item
                    xl={8.9}
                    lg={8.9}
                    md={8.9}
                    sm={8.9}
                    mt={0.2}
                    // className="bg-info"
                  >
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="url"
                      size="small"
                      name="url"
                      placeholder="Url"
                      value={url_value}
                      onChange={url_value_handlechnage}
                      // error={
                      //   Boolean(errors.url) &&
                      //   Boolean(touched.url)
                      // }
                      // helperText={
                      //   Boolean(touched.url) && errors.url
                      // }
                    />
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2}>
                    <MuiButton
                      width="100%"
                      size="small"
                      name={"Add"}
                      onClick={addEntryClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 mb-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    name={"Add Url"}
                    type="submit"
                    width="100%"
                    loading={loading}
                  />
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Reset"}
                    type="reset"
                    onClick={() => {
                      setTheArray([]);
                      setUrl_value("");
                    }}
                    width="100%"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(PostUrl);
