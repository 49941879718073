import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ReturnReport,
  firstLetterOrange,
  getItemsById,
  getStaticItemsById,
  returnPlatformDropdownLogos,
} from "../../../components/ticketPartials";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { theme } from "../../../views/App";
import CancelIcon from "@mui/icons-material/Cancel";
import DatePicker from "../../../components/dateRangePickerComponent/DateRangePicker";
import { format, isValid } from "date-fns";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DownloadIcon from "@mui/icons-material/Download";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiDatePicker from "../../../components/datePickerComponent/MuidatePicker";
import DateTimePickerComponent from "../../../components/dateTimePicker/DateTimePicker";
import ExcelJS from "exceljs";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
const XLSX = require("xlsx");

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function DetailedReportScreen(props) {
  const { detailReportOpen, setDetailReportId, setDetailReportOpen } = props;

  // Platforms list creation for reports

  let { platform_list, activity_list, statusList, userList, report } = props;

  let onKeyDown = (event) => {
    if (event.key === "Backspace") {
      event.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const [createdDate, setCreatedDate] = useState([null, null]);
  const [filterClicked, setFilterClicked] = useState(false);
  const [clearClicked, setclearClicked] = useState(false);

  const [DownloadClicked, setDownloadClicked] = useState(false);
  const [excelData, setexcelData] = useState(null);
  const [createdSingleDate, setCreatedSingleDate] = useState(null);

  const createdDateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d ? e[0].$d : null;
    let date2 = e[1] && e[1].$d ? e[1].$d : null;

    const isPastDate = (date) => {
      const now = new Date();
      return date <= now;
    };

    if (
      isValid(date1) &&
      isValid(date2) &&
      isPastDate(date1) &&
      isPastDate(date2)
    ) {
      setCreatedDate([
        isValid(date1) && format(date1, "yyyy-MM-dd"),
        isValid(date2) && format(date2, "yyyy-MM-dd"),
      ]);

      if (isValid(date1) && isValid(date2)) {
      } else {
      }
    }
  };

  const createdDateSingleValueHandleChange = (e, v) => {
    let date1 = e && e.$d ? e.$d : null;

    if (date1) {
      setCreatedSingleDate(format(date1, "yyyy-MM-dd"));
    }
  };

  let maxDate = new Date();

  const customOnClose = () => {
    props.onClose();
    setCreatedDate([null, null]);
    setFilterClicked(false);
    setplatform_selected([]);
    setsentiment_selected([]);
    setclearClicked(false);
    setCreatedSingleDate(null);
    set_type_value([]);
  };

  useEffect(() => {
    if (DownloadClicked) {
      if (excelData?.length > 0) {
        // Function to capitalize the first letter of each key
        const capitalizeKeys = (arr) =>
          arr.map((item) =>
            Object.fromEntries(
              Object.entries(item).map(([key, value]) => [
                key.charAt(0).toUpperCase() + key.slice(1),
                value,
              ])
            )
          );

        const capitalizedData = capitalizeKeys(excelData);

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Tickets");

        if (
          report === "sRPdGowBpodn5_f5j-MF" ||
          report === "sRPdGowBpodn5_f5j-MG"
        ) {
          const allColumns = [
            ...new Set(
              excelData
                .flatMap((item) => Object.keys(item))
                .filter((key) => key !== "id")
            ),
          ];

          const mergeRanges = {}; // To track merge ranges for each category level
          const processedData = excelData.map((item) => {
            const newItem = {};
            allColumns.forEach((key) => {
              newItem[key] = item[key] || ""; // Use item value or empty
            });
            return newItem;
          });

          // Merging logic for hierarchical data
          allColumns.forEach((col, colIndex) => {
            let startRow = null; // Start row for the current merge range
            let currentValue = null; // Current value being checked
            let count = 0; // Count of how many rows to merge

            for (
              let rowIndex = 0;
              rowIndex < processedData.length;
              rowIndex++
            ) {
              const cellValue = processedData[rowIndex][col];

              if (cellValue) {
                // Only process non-empty values
                if (cellValue === currentValue) {
                  count++; // Increment the count for consecutive duplicates
                } else {
                  // If we had a previous range, merge it
                  if (currentValue !== null && count > 1) {
                    mergeRanges[col] = mergeRanges[col] || [];
                    mergeRanges[col].push({
                      start: startRow + 2, // +2 for Excel row index (1-based)
                      end: startRow + count + 1,
                      colIndex: colIndex + 1,
                    });
                  }

                  // Reset for the new value
                  currentValue = cellValue;
                  startRow = rowIndex;
                  count = 1; // Reset count for new value
                }
              } else {
                // Reset for empty cell; merge if we had a previous value
                if (currentValue !== null && count > 1) {
                  mergeRanges[col] = mergeRanges[col] || [];
                  mergeRanges[col].push({
                    start: startRow + 2,
                    end: startRow + count + 1,
                    colIndex: colIndex + 1,
                  });
                }

                // Reset current value tracking
                currentValue = null;
                startRow = null; // Reset startRow for empty value
                count = 0; // Reset count for empty value
              }
            }

            // Check if there's a final range to merge after the loop
            if (currentValue !== null && count > 1) {
              mergeRanges[col] = mergeRanges[col] || [];
              mergeRanges[col].push({
                start: startRow + 2,
                end: startRow + count + 1,
                colIndex: colIndex + 1,
              });
            }
          });

          worksheet.addRow(allColumns);

          // Add data rows
          processedData.forEach((row) => {
            worksheet.addRow(allColumns.map((col) => row[col] || ""));
          });
          Object.keys(mergeRanges).forEach((col) => {
            mergeRanges[col].forEach(({ start, end, colIndex }) => {
              worksheet.mergeCells(start, colIndex, end, colIndex);
            });
          });

          // Set column widths based on content length
          // Set maximum column widths
          worksheet.columns.forEach((column) => {
            const maxLength = Math.max(
              ...allColumns.map((row) => (row ? row?.toString().length : 0))
            );
            column.width = Math.max(maxLength, 10);
          });
        } else {
          // Add column headers
          worksheet.columns = Object.keys(capitalizedData[0]).map((key) => ({
            header: key,
            key: key,
            width: Math.max(key.length, 10), // Set initial width
          }));

          // Add rows to the worksheet
          capitalizedData.forEach((data) => {
            worksheet.addRow(data);
          });

          // Set column widths based on content length
          worksheet.columns.forEach((column) => {
            const maxLength = Math.max(
              ...capitalizedData.map((row) =>
                row[column.key] ? row[column.key].toString().length : 0
              ),
              column.header.length // Include header length
            );
            column.width = Math.max(maxLength, 10); // Ensure a minimum width
          });
        }
        worksheet.eachRow((row, rowNumber) => {
          row.eachCell((cell) => {
            const cellValue = cell.value;
            const cellType =
              typeof cellValue === "string" &&
              /^-?\d+(\.\d+)?%?$/.test(cellValue)
                ? "numericString"
                : typeof cellValue;
            cell.alignment =
              rowNumber === 1
                ? { horizontal: "center", vertical: "middle" }
                : {
                    horizontal: cellType === "string" ? "left" : "right",
                    vertical: "middle",
                  };
          });
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: "application/octet-stream" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${
            props.open && getItemsById(report)[0]?.report_name
              ? getItemsById(report)[0]?.report_name
              : "Reports"
          }.xlsx`;
          link.click();
        });

        setDownloadClicked(false);
        setexcelData(null);
      }
    }
  }, [excelData, DownloadClicked]);

  const dateFilterEnabled = [
    "Sentiment Overview by Platform Chart",
    "Monthly Sentiment Trend",
    "Sentiment Overview by Platforms",
    "Daily Sentiment Trend",
    "Platform Interaction Overview",
    "Platform Interaction Overview Chart",
    "Hourly Platformwise Tickets Resolved",
    "Platformwise Ticket Status",
    "Platformwise Top 10 Categories",
    "Hourly Platformwise Tickets Resolved Chart",
    "Bridge User Ticket Status",
    "Bridge User Ticket Status Chart",
    "Tickets By Category Chart",
    "Sentiment By Tickets Category Chart",
    "Negative Tickets By Category Chart",
    "Bridge User Summary",
    "Word Cloud",
    "Breach Status",
    "Platforms Cumulative Avg FRT Overview",
    "Platforms Cumulative Avg TAT Overview",
    "Bridge User FRT Avg by Platform",
    "Bridge User FRT Avg by Platform Chart",
    "Bridge User TAT Avg by Platform",
    "Bridge User TAT Avg by Platform Chart",
    "Platformwise Tickets by Category",
    "Platformwise Tickets by Category Chart",
    "Negative Tickets Platformwise by Category Chart",
    "Negative Tickets Platformwise by Category",
    "FRT/TAT Averages by Category",
    "FRT/TAT Averages by Category Chart",
    "Sentiment by Tickets Category",
    "Tickets by Category",
    "Negative Tickets by Category",
    "Sentiment By Custom Fields",
    "Platformwise Tickets by Custom Fields",
    "Tickets by Custom Fields",
    "FRT/TAT Averages by Custom Fields",
    "Negative Tickets Platformwise by Custom Fields",
  ];
  const downloadEnabledReports = [
    "Sentiment Analysis (Last 12 Months)",
    "Sentiment Analysis (Last 12 Months) Chart",
    "Sentiment Overview by Platform Chart",
    "Monthly Sentiment Trend",
    "Sentiment Overview by Platforms",
    "Daily Sentiment Trend",
    "Sentiment Analysis (Last 3 Months)",
    "Platform Mentions vs Negative Mentions (Last 3 Months)",
    "Negative Tickets Platformwise by Category Chart",
    "Platform Mentions vs Negative Mentions (Last 3 Months) Chart",
    "Sentiment By Custom Fields",
    "Platform Interaction Overview",
    "Platform Interaction Overview Chart",
    "Platformwise Avg FRT (Last 3 Months)",
    "TAT Overview (Last 12 Months)",
    "Hourly Platformwise Tickets Resolved",
    "Platformwise Ticket Status",
    "Platformwise Top 10 Categories",
    "Hourly Platformwise Tickets Resolved Chart",
    "Bridge User Ticket Status",
    "Bridge User Ticket Status Chart",
    "Tickets By Category Chart",
    "Sentiment By Tickets Category Chart",
    "Negative Tickets By Category Chart",
    "Platformwise Tickets (Last 3 Months)",
    "Bridge User Summary",
    "Word Cloud",
    "Breach Status",
    "FRT Efficiency (Last 3 Months)",
    "Platforms Cumulative Avg FRT Overview",
    "Platforms Cumulative Avg TAT Overview",
    "Bridge User FRT Avg by Platform",
    "Bridge User FRT Avg by Platform Chart",
    "Bridge User TAT Avg by Platform",
    "Bridge User TAT Avg by Platform Chart",
    "Negative Mentions (Last 3 Months)",
    "Negative Mentions (Last 3 Months) Chart",
    "Platform Sentiment Summary (Last 3 Months)",
    "Platformwise Tickets by Category",
    "Platformwise Tickets by Category Chart",
    "Negative Tickets Platformwise by Category",
    "FRT/TAT Averages by Category",
    "FRT/TAT Averages by Category Chart",
    "Sentiment by Tickets Category",
    "Ticket Category (Last 3 Months)",
    "Tickets by Category",
    "Negative Tickets by Category",
    "Platformwise Tickets by Custom Fields",
    "Tickets by Custom Fields",
    "FRT/TAT Averages by Custom Fields",
    "Negative Tickets Platformwise by Custom Fields",
  ];

  const singleDateFilterEnabled = [
    "Ticket Category (Last 3 Months)",
    "Platformwise Avg FRT (Last 3 Months)",
    "Platform Mentions vs Negative Mentions (Last 3 Months)",
    "Platform Mentions vs Negative Mentions (Last 3 Months) Chart",
    "Platformwise Tickets (Last 3 Months)",
    "Negative Mentions (Last 3 Months)",
    "Negative Mentions (Last 3 Months) Chart",
    "Platform Sentiment Summary (Last 3 Months)",
    "Sentiment Analysis (Last 3 Months)",
    "TAT Overview (Last 12 Months)",
    "Sentiment Analysis (Last 12 Months)",
    "Sentiment Analysis (Last 12 Months) Chart",
  ];

  const custom_type_enabled = [
    "Platformwise Tickets by Custom Fields",
    "Negative Tickets Platformwise by Custom Fields",
    "Sentiment By Custom Fields",
    "Tickets by Custom Fields",
    "FRT/TAT Averages by Custom Fields",
  ];

  const dateFilterEnabel = dateFilterEnabled?.includes(
    getItemsById(report)[0]?.report_name
  );
  const downloadEnable = downloadEnabledReports?.includes(
    getItemsById(report)[0]?.report_name
  );

  const singleDateFilterEnabel = singleDateFilterEnabled?.includes(
    getItemsById(report)[0]?.report_name
  );

  const custom_type_enable = custom_type_enabled?.includes(
    getItemsById(report)[0]?.report_name
  );

  const [platform_selected, setplatform_selected] = useState([]);
  const [sentiment_selected, setsentiment_selected] = useState([]);

  const platformHandleChange = (event, platform) => {
    setplatform_selected(platform);
  };
  const sentimentHandleChange = (event, sentiment) => {
    setsentiment_selected(sentiment);
  };

  let uniqueList = [];
  let sentimentList = [
    {
      sentiment_alias: "Positive",
      sentiment: "positive_sentiment",
    },
    {
      sentiment_alias: "Negative",
      sentiment: "negative_sentiment",
    },
    {
      sentiment_alias: "Neutral",
      sentiment: "neutral_sentiment",
    },
  ];

  platform_list?.sort((a, b) =>
    a?.platform_alias.localeCompare(b.platform_alias)
  );
  uniqueList = [...new Set(platform_list)];

  const [type_value, set_type_value] = useState([]);

  const types = [
    { type_name: "Text", type_id: "text" },
    { type_name: "Select", type_id: "select" },
    { type_name: "Toggle", type_id: "toggle" },
    { type_name: "Radio", type_id: "radio" },
    { type_name: "Date", type_id: "date" },
  ];

  const type_HandleChange = (event, value) => {
    if (value != null) {
      set_type_value(value);
    } else {
      set_type_value([]);
    }
  };

  const boxRef = useRef(null); // Reference to the Box component
  const [boxHeight, setBoxHeight] = useState(750); // State to hold the current Box height

  // Update boxHeight whenever the Box's height changes
  useEffect(() => {
    const updateHeight = () => {
      if (boxRef.current) {
        setBoxHeight(boxRef.current.offsetHeight);
      }
    };

    // Initial height setting
    updateHeight();

    // Update height on resize
    window.addEventListener("resize", updateHeight);

    // Clean up event listener
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <Dialog open={props.open} onClose={customOnClose} fullWidth maxWidth="lg">
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="p-0 m-0"
      >
        {report !== "rBPdGowBpodn5_f5jeOH" &&
        report !== "rBPdGowBpodn5_f5jeOD" ? (
          <Grid
            item
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              width: "100%",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 1, // Ensures it stays above other content within the dialog
              padding: 2,
            }}
          >
            <Grid
              item
              // className="bg-success"
              xs={report === "rRPdGowBpodn5_f5jePW" ? 2.5 : 4.5}
            >
              <Typography
                style={{
                  fontSize: detailReportOpen ? "20px" : "18px",
                  padding: theme.reports.headerPadding,
                }}
              >
                {props.open &&
                firstLetterOrange(getItemsById(report)[0]?.report_name)
                  ? firstLetterOrange(getItemsById(report)[0]?.report_name)
                  : null}

                {props.open &&
                firstLetterOrange(getStaticItemsById(report)[0]?.report_name)
                  ? firstLetterOrange(
                      getStaticItemsById(report)[0]?.report_name
                    )
                  : null}
              </Typography>
            </Grid>
            <Grid
              xs={report === "rRPdGowBpodn5_f5jePW" ? 9.5 : 7.5}
              item
              container
              justifyContent={"flex-end"}
              alignItems={"center"}
              // className="bg-warning"
            >
              {dateFilterEnabel && (
                <Grid item xs={report === "rRPdGowBpodn5_f5jePW" ? 3.2 : 5}>
                  <DatePicker
                    value={createdDate ? createdDate : [null, null]}
                    onChange={createdDateValueHandleChange}
                    maxDate={maxDate}
                    onKeyDown={onKeyDown}
                    fieldheight={"35px"}
                    disableFuture
                  />
                </Grid>
              )}

              {custom_type_enable && (
                <Grid
                  item
                  xs={report === "rRPdGowBpodn5_f5jePW" ? 3.2 : 3}
                  ml={0.5}
                  mt={-4}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      zIndex: 999,
                    }}
                  >
                    <AutoCompleteComponent
                      // ***************************************************Customer name filter
                      multiple
                      value={type_value}
                      list={types}
                      disableCloseOnSelect={true}
                      getOptionLabel={(option) => option.type_name}
                      isOptionEqualToValue={(option, value) =>
                        option.type_name === value.type_name
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.type_name}
                        </li>
                      )}
                      limitTags={1}
                      textFieldProps={{
                        placeholder:
                          type_value && type_value.length ? null : "Type",
                        zIndex: 999,
                      }}
                      handleChange={type_HandleChange}
                      size="small"
                      width="100%"
                    />
                  </div>
                </Grid>
              )}

              {singleDateFilterEnabel && (
                <Grid item xs={report === "rRPdGowBpodn5_f5jePW" ? 3.2 : 4}>
                  <DateTimePickerComponent
                    value={createdSingleDate}
                    onChange={createdDateSingleValueHandleChange}
                    format="DD/MM/YYYY"
                    views={["year", "month"]}
                    label={`Date`}
                    // maxDate={new Date()}
                  />
                </Grid>
              )}

              {report === "rRPdGowBpodn5_f5jePW" ? (
                <Grid
                  item
                  xs={2.7}
                  ml={2}
                  style={{ position: "relative" }}
                  mt={-4}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      zIndex: 999,
                    }}
                  >
                    <AutoCompleteComponent
                      list={uniqueList}
                      getOptionLabel={(option) => option.platform_alias}
                      disablePortal
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          <Typography
                          // fontSize={8}
                          >
                            {option.platform_alias}
                          </Typography>
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.platform_alias === value.platform_alias
                      }
                      multiple
                      limitTags={2}
                      value={platform_selected}
                      handleChange={platformHandleChange}
                      textFieldProps={{
                        placeholder:
                          platform_selected?.length > 0 ? null : "Platforms",
                      }}
                      size="small"
                      width="100%"
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.platform_alias}
                            variant="outlined"
                            size="small"
                            className="mx-1 "
                            // onDelete={() => {
                            //   setFieldValue(
                            //     "owned_labels",
                            //     value.filter((item) => item.id !== option.id)
                            //   );
                            // }}
                          />
                        ))
                      }
                    />
                  </div>
                </Grid>
              ) : null}

              {report === "rRPdGowBpodn5_f5jePW" ? (
                <Grid
                  item
                  xs={2}
                  ml={2}
                  style={{ position: "relative" }}
                  mt={-4}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      zIndex: 999,
                    }}
                  >
                    <AutoCompleteComponent
                      list={sentimentList}
                      getOptionLabel={(option) => option.sentiment_alias}
                      disablePortal
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          <Typography
                          // fontSize={8}
                          >
                            {option.sentiment_alias}
                          </Typography>
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.sentiment_alias === value.sentiment_alias
                      }
                      multiple
                      limitTags={2}
                      value={sentiment_selected}
                      handleChange={sentimentHandleChange}
                      textFieldProps={{
                        placeholder:
                          sentiment_selected?.length > 0 ? null : "Sentiment",
                      }}
                      size="small"
                      width="100%"
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.sentiment_alias}
                            variant="outlined"
                            size="small"
                            className="mx-1 "
                            // onDelete={() => {
                            //   setFieldValue(
                            //     "owned_labels",
                            //     value.filter((item) => item.id !== option.id)
                            //   );
                            // }}
                          />
                        ))
                      }
                    />
                  </div>
                </Grid>
              ) : null}

              {(dateFilterEnabel || singleDateFilterEnabel) && (
                <Grid item ml={1}>
                  <Grid item container gap={1}>
                    <MuiButton
                      name="Submit"
                      onClick={() => {
                        setFilterClicked(true);
                      }}
                      disabled={
                        report === "rRPdGowBpodn5_f5jePW" ||
                        report === "sRPdGowBpodn5_f5j-MH" ||
                        report === "sRPdGowBpodn5_f5j-MI" ||
                        report === "1BPgGowBpodn5_f5weGH" ||
                        report === "sRPdGowBpodn5_f5j-Mz" ||
                        report === "sRPdGowBpodn5_f5j-My"
                          ? false
                          : (createdDate[0] && createdDate[1]) ||
                            createdSingleDate
                          ? false
                          : true
                      }
                    />
                    <MuiButton
                      name="Clear"
                      onClick={() => {
                        setCreatedDate([null, null]);
                        setclearClicked(true);
                        setplatform_selected([]);
                        setsentiment_selected([]);
                        setCreatedSingleDate(null);
                        set_type_value([]);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {downloadEnable && (
                <Grid item>
                  <IconButton
                    onClick={() => {
                      // excelDownloadFunction();
                      setDownloadClicked(true);
                    }}
                    title="Download"
                  >
                    <DownloadIcon
                      style={{}}
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  </IconButton>
                </Grid>
              )}

              <Grid item>
                <IconButton
                  onClick={() => {
                    // props.onClose();
                    customOnClose();
                  }}
                  title="Close"
                >
                  <CancelIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                    style={{}}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {(report === "rRPdGowBpodn5_f5jePW" ||
          report === "sRPdGowBpodn5_f5j-MH" ||
          report === "1BPgGowBpodn5_f5weGH" ||
          report === "sRPdGowBpodn5_f5j-MI" ||
          report === "sRPdGowBpodn5_f5j-Mz" ||
          report === "sRPdGowBpodn5_f5j-My") && <br />}

        <Box
          sx={{
            width: "100%",
            aspectRatio: "1/1", // Keeps the box square
            overflow: "auto", // Adds scroll if content overflows
          }}
          ref={boxRef}

          // className="bg-info"
        >
          {ReturnReport(
            { report_id: props.report },
            platform_list,
            () => {},
            activity_list,
            statusList,
            userList,
            true,
            createdDate[0] && createdDate[1]
              ? createdDate
              : createdSingleDate
              ? createdSingleDate
              : null,
            filterClicked,
            setFilterClicked,
            DownloadClicked,
            setDownloadClicked,
            setexcelData,
            platform_selected,
            sentiment_selected,
            clearClicked,
            setclearClicked,
            setDetailReportOpen,
            setDetailReportId,
            detailReportOpen,
            type_value,
            set_type_value,
            boxHeight
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DetailedReportScreen;
