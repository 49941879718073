import BaseReducer from "../../../../utilities/BaseReducer";
import WhitelistedSitesACtion from "./WhitelistedSitesACtion";

export default class WhitelistedSitesReduser extends BaseReducer {
  initialState = {
    websweepXWhitelistedSites: [],
  };
  [WhitelistedSitesACtion.REQUEST_GET_WHITELISTED_SITES_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepXWhitelistedSites: action.payload,
    };
  }
}
