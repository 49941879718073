import { BaseModel } from "sjs-base-model";
import AppStoreHitsDataModel from "./AppStoreHitsDataModel";

// get model for project
export default class AppStoreHitsModel extends BaseModel {
  hits = [{ AppStoreHitsDataModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
