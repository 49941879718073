import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import EffectUtility from "../../../utilities/EffectUtility";
import HttpUtility from "../../../utilities/HttpUtility";
import InstagramCommentPostModel from "./models/InstagramCommentPostModel";
import InstagramFeedsByIdModel from "./models/InstagramFeedsByIdModel";
import InstagramFeedsModel from "./models/InstagramFeedsModel";
import InstagramPutModel from "./models/InstagramPutModel";
import InstagramReplyPostModel from "./models/InstagramReplyPostModel";
import InstagramCommmentHideModel from "./models/InstagramCommentHideModel";
import InstagramPostModel from "./models/InstagramPostModel";

export default class InstagramFeedsEffect {
  //instagram feeds request
  static async requestInstagramFeeds(params, callBackFunction) {
    const endpoint = environment.api.instagram;

    let response = EffectUtility.getToModel(
      InstagramFeedsModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits && resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestThreads(params, callBackFunction) {
    const endpoint = environment.api.threadFeeds;

    let response = EffectUtility.getToModel(
      InstagramFeedsModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits && resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestInstagramFeedsClear() {
    return {};
  }

  //instagram feeds request by ID
  static async requestInstagramFeedsById(id) {
    const endpoint = environment.api.instagram + "/" + id;

    return EffectUtility.getToModel(InstagramFeedsByIdModel, endpoint);
  }

  //instagram feeds Filter
  static async requestInstagramFilter(params) {
    const endpoint = environment.api.instagramFilter;
    return EffectUtility.getToModel(InstagramFeedsModel, endpoint, params);
  }

  // put method for the Insta Feeds
  static async requestPutInstaFeeds(data, id) {
    const endpoint = environment.api.instagram + "/" + id;
    return EffectUtility.putToModel(InstagramPutModel, endpoint, data);
  }

  static async requestPutThreadsFeeds(data, id) {
    const endpoint = environment.api.threadFeeds + "/" + id;
    return EffectUtility.putToModel(InstagramPutModel, endpoint, data);
  }

  //instagram feeds reply
  static async requestInstagramReplyPost(id, data, callback) {
    const endpoint = environment.api.instagramReply + "/" + id;
    let response = EffectUtility.postToModel(
      InstagramReplyPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
      }
    });

    return response;
  }

  //instagram feeds reply
  static async requestThreadsReplyPost(id, data, callback) {
    const endpoint = environment.api.threadsReply + "/" + id;
    let response = EffectUtility.postToModel(
      InstagramReplyPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
      }
    });

    return response;
  }

  static async requestInstagramCommentPost(id, data, callback) {
    const endpoint = environment.api.instagramComment + "/" + id;
    let response = EffectUtility.postToModel(
      InstagramCommentPostModel,
      endpoint,
      data,
      callback
    );

    response.then((resp) => {
      if (resp && callback) {
        callback();
      }
    });

    return response;
  }

  static async requestInstagramCommentHide(id, data) {
    const endpoint = environment.api.instagramHideComment + "/" + id;
    return EffectUtility.postToModel(
      InstagramCommmentHideModel,
      endpoint,
      data
    );
  }

  static async requestInstagramCommentDelete(id) {
    const endpoint = environment.api.instagramDeleteComment + "/" + id;
    return EffectUtility.deleteToModel(InstagramCommmentHideModel, endpoint);
  }

  // Post API
  static async requestInstagramPost(queryParams, data) {
    const endpoint = environment.api.platformPost + "?" + queryParams;

    return EffectUtility.postToModel(InstagramPostModel, endpoint, data);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
