import ActionUtility from "../../utilities/ActionUtility";
import SetUserEffect from "./SetUserEffect";

export default class SetUserAction {
  //set User get requests
  static REQUEST_SETUSER = "SetUserAction.REQUEST_SETUSER";
  static REQUEST_SETUSER_FINISHED = "SetUserAction.REQUEST_SETUSER_FINISHED";

  //methodds

  //getMethod
  static requestSetUser(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SetUserAction.REQUEST_SETUSER,
        SetUserEffect.requestSetUser,
        params
      );
    };
  }
}
