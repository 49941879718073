import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import YoutubeFeedByIdModel from "./model/YoutubeFeedByIdModel";
import YoutubeModel from "./model/YoutubeModel";
import YoutubePutModel from "./model/YoutubePutModel";
import YoutubeReplyPostModel from "./model/YoutubeReplyPostModel";
import YoutubePostDataModel from "./model/YoutubePostDataModel";

export default class YoutubeEffect {
  //youtube feeds request
  static async requestYoutube(params, callBackFunction) {
    const endpoint = environment.api.youtubeFeed;
    let response = EffectUtility.getToModel(YoutubeModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestYoutubeClear() {
    return {};
  }

  //youtube request for filter dropdown
  static async requestYoutubeFilter(params) {
    const endpoint = environment.api.youtubeFeed;
    return EffectUtility.getToModel(YoutubeModel, endpoint, params);
  }

  //youtube feeds request by id
  static async requestYoutubeFeedById(id) {
    const endpoint = environment.api.youtubeFeed + "/" + id;
    return EffectUtility.getToModel(YoutubeFeedByIdModel, endpoint);
  }

  // put method for the Youtube Feeds
  static async requestPutYoutubeFeeds(data, id) {
    const endpoint = environment.api.youtubeFeed + "/" + id;
    return EffectUtility.putToModel(YoutubePutModel, endpoint, data);
  }

  //Youtube postReply request method
  static async requestYoutubeReplyPost(id, params, data, callback) {
    const endpoint = environment.api.youtubeReply + "/" + id;
    let response = EffectUtility.postToModel(
      YoutubeReplyPostModel,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp) {
        resp.status === "Success" && callback && callback(resp);
      }
    });

    return response;
  }

  static async requestYoutubeDataPost(params, data) {
    const endpoint = environment.api.platformPost + "?" + params;

    return EffectUtility.postToModel(YoutubePostDataModel, endpoint, data);
  }
}
