import BaseReducer from "../../../utilities/BaseReducer";
import OrmResourceAction from "./OrmResourceAction";

// reducer function
export default class OrmResourceReducer extends BaseReducer {
  initialState = {
    OrmResource: [],
  };

  [OrmResourceAction.REQUEST_ORM_RESOURCE_FINISHED](state, action) {
    return {
      ...state,
      OrmResource: action.payload,
    };
  }
}
