import ActionUtility from "../../../utilities/ActionUtility";
import TicketSettingsEffect from "./TicketSettingsEffect";

export default class TicketSettingsAction {
  //ticket settings requests
  static REQUEST_TICKET_SETTINGS =
    "TicketSettingsAction.REQUEST_TICKET_SETTINGS";
  static REQUEST_TICKET_SETTINGS_FINISHED =
    "TicketSettingsAction.REQUEST_TICKET_SETTINGS_FINISHED";

  //ticket settings post
  static REQUEST_POST_TICKET_SETTINGS =
    "TicketSettingsAction.REQUEST_POST_TICKET_SETTINGS";
  static REQUEST_POST_TICKET_SETTINGS_FINISHED =
    "TicketSettingsAction.REQUEST_POST_TICKET_SETTINGS_FINISHED";

  //ticket settings put
  static REQUEST_PUT_TICKET_SETTINGS =
    "TicketSettingsAction.REQUEST_PUT_TICKET_SETTINGS";
  static REQUEST_PUT_TICKET_SETTINGS_FINISHED =
    "TicketSettingsAction.REQUEST_PUT_TICKET_SETTINGS_FINISHED";

  //METHODS

  //Ticket settings get method
  static requestTicketSettings(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_TICKET_SETTINGS,
        TicketSettingsEffect.requestTicketSettings,
        params
      );
    };
  }

  //Ticket Settings POST method
  static requestPostTicketSettings(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_POST_TICKET_SETTINGS,
        TicketSettingsEffect.requestPostTicketSettings,
        data
      );
    };
  }

  // Ticket Settings put function
  static requestPutTicketSettings(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketSettingsAction.REQUEST_PUT_TICKET_SETTINGS,
        TicketSettingsEffect.requestPutTicketSettings,
        data,
        id
      );
    };
  }
}
