import axios from "axios";
import environment from "environment";

function typeOfTheResponse(data) {
  if (data instanceof FormData) {
    return "FormData";
  } else {
    try {
      const jsonData = JSON.parse(data);
      return "json";
    } catch (jsonError) {
      // Not JSON, check for other types like HTML or string
      if (/^<[\s\S]*>$/.test(data)) {
        return "html";
      } else {
        return "string";
      }
    }
  }
}

export async function axiosRequest(url, config, responseType = "json") {
  let { method, headers, body } = config;
  let token;
  if (localStorage.getItem("Auth") !== null) {
    token = JSON.parse(localStorage.getItem("Auth")).access_token;
  }
  try {
    let response;
    switch (method.toLowerCase()) {
      case "get":
        response = await axios.get(url, { headers }, responseType);
        break;

      case "post":
        response = await axios.post(url, body, { headers }, responseType);
        break;

      case "put":
        response = await axios.put(url, body, { headers }, responseType);
        break;

      case "delete":
        response = await axios.delete(url, { headers }, responseType);
        break;

      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }

    return response?.data;
  } catch (error) {
    let errorData;

    switch (typeOfTheResponse(error.config.data)) {
      case "json":
        errorData = JSON.parse(error.config.data);
        break;

      case "html":
        errorData = error.config.data;
        break;

      case "string":
        errorData = error.config.data;
        break;

      case "FormData":
        errorData = "FormData";
        break;

      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }

    // console.log(
    //   "axios_error",
    //   error,
    //   typeOfTheResponse(error.config.data),
    //   errorData
    // );

    // console.log("axios_error2", errorData);

    let post_log_data = {
      type: "custom_fetch",
      status: error.response?.status,
      url: error.config?.url,
      error: error.response?.data,
      payload_data: errorData,
      method: method,
      userId: localStorage.getItem("userId"),
    };

    // console.log("ERROR_LOG", post_log_data);
    if (error.response?.status != 401) {
      fetch(`${environment.api.baseApi}/api/logs/frontendLogs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(post_log_data),
      });
    }

    if (error?.response?.data) {
      return error?.response.data;
    }

    return error;
  }
}
