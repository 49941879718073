import { BaseModel } from "sjs-base-model";

export default class YoutubeDataExtractionPutModel extends BaseModel {
    status = "";

    constructor(data) {
        super();
    
        this.update(data);
      }
}