import { BaseModel } from "sjs-base-model";
import GmbQuestionsHitsModel from "./GmbQuestionsHitsModel";
import GmbQuestionsShardsModel from "./GmbQuestionsShardsModel";

// GmbQuestionsModel
export default class GmbQuestionsModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: GmbQuestionsShardsModel };
  current_page_no = 0;
  hits = { hits: GmbQuestionsHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
