import BaseReducer from "../../../../utilities/BaseReducer";
import BatchLinksAction from "./BatchLinksAction";

export default class BatchLinkStatusByIdReducer extends BaseReducer {
  initialState = {
    batchLinksStatusByIdReport: [],
  };
  [BatchLinksAction.REQUEST_BATCH_LINKS_STATUS_REPORT_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      batchLinksStatusByIdReport: action.payload,
    };
  }
}
