import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeVideosWebhookAction from "./YoutubeVideosWebhookAction";

export default class YoutubeVideosWebhookReducer extends BaseReducer {
  initialState = {
    youtubeVideosWebhook: [],
  };

  [YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeVideosWebhook: action.payload,
    };
  }
}
