import {
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation, useNavigate } from "react-router";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import RolesAction from "../../stores/roles/RolesAction";
import ManageRoleAction from "../../components/manageRole/ManageRoleAction";
import ProjectAction from "../../../ormTicketing/stores/project/projectAction";
import OrganizationAction from "../../stores/organization/OrganizationAction";
import AddRolePost from "../../components/addRolePost/AddRolePost";
import FrontEndResourceAction from "../../stores/frontEndResources/FrontEndResourceAction";
import OrmPagesAction from "../../stores/ormPages/OrmPagesAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import DIYReportTemplateAction from "../../../ormTicketing/stores/newReportTemplate/DIYReportTemplateAction";
import { faShield } from "@fortawesome/free-solid-svg-icons";

// mapState to props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    RolesAction.REQUEST_ROLES,
    ManageRoleAction.REQUEST_ROLE_FILTER,
    ProjectAction.REQUEST_PROJECT_FILTER,
    OrganizationAction.REQUEST_ORGANIZATION_FILTER,
    ManageRoleAction.REQUEST_ROLE_TEMPLATE_FILTER,
    FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_FILTER,
    OrmPagesAction.REQUEST_ORM_PAGES,
    DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE_FILTER,
  ]),
  roles: state.roles.roles || [],
  pagination: state.roles.roles || [],
  roleFilter: state.roleFilter.roleFilter || [],
  projectfilter: state.projectfilter.projectfilter || [],
  organizationFilter: state.organizationFilter.organizationFilter || {},
  roleTemplate: state.roleTemplate.roleTemplate || {},
  frontEndResourceFilter:
    state.frontEndResourceFilter.frontEndResourceFilter || {},
  ormPages: state.ormPages.ormPages || [],
  diyReportTemplateFilter:
    state.diyReportTemplateFilter.diyReportTemplateFilter || [],
});

function AddRole(props) {
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [searchRoleName, setSearchRoleName] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(true);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const { isRequesting } = props;

  function fetch_role(params) {
    props.dispatch(RolesAction.requestRole(params));
  }

  function fetch_role_filter(filter_params) {
    props.dispatch(ManageRoleAction.requestRoleFilter(filter_params));
  }

  function fetchFrontEndResourceFilterData(params) {
    props.dispatch(
      FrontEndResourceAction.requestFrontEndResourceFilter(params)
    );
  }

  function fetchReportTemplate() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(
      DIYReportTemplateAction.requestDIYReportTemplateFilter(params)
    );
  }

  function fetchRoleTemplateTrue() {
    const filter = [];
    let params = {};

    filter.push(`[["is_template","must","","term","true"]]`);
    params = {
      filters: `${filter}`,
      order_by: '[["_id","asc"]]',
      page_limit: `none`,
    };
    props.dispatch(ManageRoleAction.requestRoleTemplateTrueFilter(params));
  }

  function fetchProjectFilter() {
    const params = {
      page_limit: "none",
    };
    props.dispatch(ProjectAction.requestProjectFilter(params));
  }

  function fetchOrganizationFilter() {
    const params = {
      page_limit: "none",
    };
    props.dispatch(OrganizationAction.requestOrganizationFilter(params));
  }

  function fetchOrmResource() {
    props.dispatch(OrmPagesAction.requestOrmPages());
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    const param = {
      page_limit: "none",
    };
    const role_param = {
      page_limit: "none",
      order_by: `[["role_name.keyword", "asc"]]`,
    };

    // if (
    //   props?.diyReportTemplateFilter === undefined ||
    //   props?.diyReportTemplateFilter.length === 0
    // ) {
    fetchReportTemplate();
    // }

    // if (
    //   props?.frontEndResourceFilter === undefined ||
    //   Object.keys(props.frontEndResourceFilter).length === 0
    // ) {
    fetchFrontEndResourceFilterData(param);
    // }

    // if (
    //   props?.organizationFilter === undefined ||
    //   props?.organizationFilter.length === 0
    // ) {
    fetchOrganizationFilter();
    // }

    // if (props?.roleTemplate === undefined || props?.roleTemplate.length === 0) {
    fetchRoleTemplateTrue();
    // }

    // if (
    //   props?.projectfilter === undefined ||
    //   props?.projectfilter.length === 0
    // ) {
    fetchProjectFilter();
    // }

    // if (props?.roleFilter === undefined || props?.roleFilter.length === 0) {
    fetch_role_filter(role_param);
    // }

    // if (props?.ormPages === undefined || props?.ormPages.length === 0) {
    fetchOrmResource();
    // }

    const filter = [];
    let params = {};

    filter.push(`[["is_template","must","","term","false"]]`);

    params = {
      filters: `${filter}`,
      order_by: '[["modified_on", "desc"]]',
      page_limit: `${page_limit}`,
    };

    fetch_role(params);
  }, []);

  const rolehandleChange = (event, value) => {
    if (value !== null) {
      setSearchRoleName(value);
    } else {
      setSearchRoleName(null);
    }
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    if (searchRoleName !== null) {
      filter.push(`["role_name.keyword","must","","term","${searchRoleName}"]`);
    }

    filter.push(`["is_template","must","","term","false"]`);

    if (searchRoleName !== null && FilterClicked) {
      params = {
        order_by: '[["modified_on", "desc"]]',
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        filters: `[${filter}]`,
        order_by: '[["modified_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetch_role(params);
    }
  };

  const applyFilter = () => {
    let params = {};
    const filter = [];

    if (searchRoleName !== null) {
      filter.push(`["role_name.keyword","must","","term","${searchRoleName}"]`);
    }

    filter.push(`["is_template","must","","term","false"]`);

    if (searchRoleName !== null) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      setFilterClicked(true);
      fetch_role(params);
      setFilterLoader(true);
      setClearLoader(false);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    // Clear the state
    if (searchRoleName !== null) {
      setSearchRoleName(null);
      setClearLoader(true);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);
    const filter = [];

    filter.push(`[["is_template","must","","term","false"]]`);

    const params = {
      filters: `${filter}`,
      order_by: '[["modified_on", "desc"]]',
      page_limit: 15,
    };
    FilterClicked && fetch_role(params);
  };

  let rolesList_filter_general = [];

  let rolesList_filter = [];

  if (props.roleFilter.hits) {
    props.roleFilter.hits.hits.map((item) => {
      return rolesList_filter_general.push({
        role_name: item._source.role_name,
        is_template: item._source.is_template,
      });
    });
  }

  if (rolesList_filter_general.length >= 0) {
    let filtered_parent_role = rolesList_filter_general.filter((val) => {
      return val.is_template === false;
    });

    filtered_parent_role.length >= 0 &&
      filtered_parent_role.map((item) => {
        rolesList_filter.push(item.role_name);
      });
  }

  const parent_rolesList_filter = [];

  if (rolesList_filter_general.length >= 0) {
    let filtered_parent_role = rolesList_filter_general.filter((val) => {
      return val.is_template === true;
    });

    filtered_parent_role.length >= 0 &&
      filtered_parent_role.map((item) => {
        parent_rolesList_filter.push(item.role_name);
      });
  }

  let project_array = [];

  if (props.projectfilter.hits) {
    props.projectfilter.hits.hits.map((item) => {
      return project_array.push({
        project_id: item._id,
        project_name: item._source.project_name,
      });
    });
  }

  let organization_array = [];

  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.map((item) => {
      return organization_array.push({
        organization_id: item._id,
        organization_name: item._source.organization_name,
      });
    });
  }

  let templateRoles = [];
  if (props.roleTemplate.hits) {
    props.roleTemplate.hits.hits.map((item) => {
      return templateRoles.push(item._source.role_name);
    });
  }

  // page name dropdown list assign
  let page_name_list = [];

  if (props.frontEndResourceFilter.hits) {
    props.frontEndResourceFilter.hits.hits.forEach((item) => {
      page_name_list.push({
        page_name: item._source.page_name,
        page_id: item._id,
      });
    });
  }

  page_name_list = [...new Set(page_name_list)];

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "roles",
      headerName: "Role",
      flex: 2,
      width: 150,
    },

    {
      field: "role_level",
      headerName: "Role Level",
      flex: 2,
      width: 150,
    },

    {
      field: "project_name",
      headerName: "Project",
      flex: 2,
      width: 150,
    },

    {
      field: "organization_name",
      headerName: "Organization",
      flex: 2,
      width: 150,
    },

    {
      field: "page_mapping_names",
      headerName: "Mapped Pages",
      flex: 2,
      width: 250,
    },

    {
      field: "page_mapping_array",
      headerName: "Mapped Pages",
      flex: 2,
      width: 250,
      hide: true,
    },

    {
      field: "parent_role",
      headerName: "parent_role",
      flex: 2,
      width: 250,
      hide: true,
    },

    {
      field: "Default Dashboard",
      headerName: "default_dashboard",
      flex: 2,
      width: 250,
      hide: true,
    },

    {
      field: "role_id",
      headerName: "Role ID",
      flex: 2,
      width: 150,
      hide: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      flex: 1,
      width: 160,
      align: "center",

      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <div>
            {!params?.row?.disabled ? (
              <IconButton
                title="Edit"
                onClick={(event) => {
                  event.ignore = true;
                  setEdit(true);
                  setOpen(true);
                  setEditRowsModel(params.row);
                }}
                sx={{ border: "0" }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
                ></FontAwesomeIcon>
              </IconButton>
            ) : (
              <IconButton title="No Authorization" sx={{ border: "0" }}>
                <FontAwesomeIcon
                  icon={faShield}
                  style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
                ></FontAwesomeIcon>
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  let reports_array = [
    {
      report_template_id: "demo_static_report_id",
      report_template_name: "Demo_Static",
    },
  ];

  if (props.diyReportTemplateFilter.hits) {
    props.diyReportTemplateFilter.hits.hits.forEach((value) => {
      reports_array.push({
        report_template_id: value._id,
        report_template_name: value._source.report_template_name,
      });
    });
  }

  const returnDefaultDashboardItem = (ID) => {
    return reports_array?.filter((item) => {
      return item.report_template_id === ID;
    })[0]
      ? reports_array?.filter((item) => {
          return item.report_template_id === ID;
        })[0]
      : "";
  };

  const rows = [];

  if (props.roles.hits && props.roles.hits.hits.length != 0) {
    props.roles.hits.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        roles: item._source.role_name,
        disabled: item._source.disabled,
        parent_role: item._source.parent_role,
        role_level: item._source.role_level,
        project_name: item._source.project_id
          ? item._source.project_id.project_name
          : "",
        organization_name: item._source.organization_id
          ? item._source.organization_id.organization_name
          : "",
        role_id: item._id,
        page_mapping_names: item._source.page_mapping
          ? item._source.page_mapping.map((item) => {
              return item.page_name;
            })
          : null,
        page_mapping_array: item._source.page_mapping
          ? item._source.page_mapping
          : [],
        default_dashboard: item._source.default_dashboard_id
          ? returnDefaultDashboardItem(item._source.default_dashboard_id)
          : "",
      });
    });
  }

  const [userRole, setUserRole] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  rolesList_filter.sort((a, b) => a.localeCompare(b));
  rolesList_filter = [...new Set(rolesList_filter)];

  return (
    <React.Fragment>
      {/* modal screen */}
      {open && (
        <AddRolePost
          open={open}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          searchRoleName={searchRoleName}
          roleEditData={editRowsModel}
          edit={edit}
          ormPages={props.ormPages && props.ormPages.pages}
          project_array={project_array}
          organization_array={organization_array}
          rolesList_filter={parent_rolesList_filter}
          templateRoleList={templateRoles}
          roles_List={rolesList_filter}
          onNavigateUserRoleMapping={(value) => {
            setUserRole(true);
          }}
          pageList={page_name_list}
          reports_array={reports_array}
        />
      )}

      {!open && (
        <Box>
          {/* <h5>Add Role</h5> */}

          <Dialog open={userRole} fullWidth maxWidth="sm">
            <DialogContent>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <h5>Would you like to Map Role to the User ?</h5>
              </div>
            </DialogContent>

            <DialogActions>
              <Button
                size="small"
                onClick={() => {
                  setUserRole(false);
                }}
              >
                <ClearIcon />
              </Button>
              <Button
                size="small"
                onClick={() => {
                  navigate("/userRoleMapping", {
                    state: { from: location.pathname },
                  });
                }}
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </Dialog>

          <Grid
            container
            xl={12}
            lg={12}
            alignItems="center"
            justifyContent="space-between"
            // className="bg-warning"
          >
            <Grid
              container
              lg={12}
              xl={12}
              md={12}
              sm={12}
              justifyContent="space-between"
              pr={1}
              // className="bg-success"
            >
              <Grid item lg={2} md={5} sm={5} mt={1}>
                <AutoCompleteComponent
                  list={rolesList_filter}
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option,
                  })}
                  isOptionEqualToValue={(option, value) => option === value}
                  label="Role"
                  size="small"
                  handleChange={rolehandleChange}
                  textFieldProps={{
                    placeholder: "Role",
                  }}
                  value={searchRoleName}
                  width="100%"
                />
              </Grid>
              <Grid item lg={5} md={7} sm={7} container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={0}
                >
                  <Grid
                    lg={8}
                    sm={8}
                    md={8}
                    display={"flex"}
                    justifyContent={"end"}
                    mt={1}
                    mr={2}
                    sx={{ gap: 2 }}
                  >
                    <Grid lg={3} md={3} sm={3}>
                      <MuiButton
                        width="100%"
                        name={"Filter"}
                        onClick={applyFilter}
                        loading={filterLoader && isRequesting}
                      />
                    </Grid>
                    <Grid lg={3} md={3} sm={3}>
                      <MuiButton
                        width="100%"
                        name={"Clear"}
                        onClick={ClearState}
                        loading={clearLoader && isRequesting}
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                    {/* post button component*/}

                    <PostPlusButton
                      open={open}
                      openCloseModal={openCloseModal}
                      editMode={editMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {navigator.onLine ? (
            <Box>
              <Grid
                display="flex"
                justifyContent="center"
                container
                item
                lg={12}
                md={12}
                sm={12}
              >
                <Grid item lg={12} xl={12} md={12} sm={12} xs={12} pr={1}>
                  <LoadingIndicator isActive={isRequesting}>
                    {!isRequesting && props.roles.hits ? (
                      <DataGridFunction
                        rows={rows}
                        columns={columns}
                        rowHeight={65}
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              page_mapping_array: false,
                              parent_role: false,
                              Dashboard: false,
                              role_id: false,
                            },
                          },
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "80vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingBgImage />
                      </Box>
                    )}
                  </LoadingIndicator>
                </Grid>
              </Grid>
              {/* <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                {props.roles.hits
                  ? props.roles.hits.hits.length !== 0 && (
                      <PaginationUI
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  : null}
              </Grid>
            </Grid> */}
            </Box>
          ) : (
            alert("No Internet Connection") || (
              <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
            )
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(AddRole);
