import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import CmsLinksStatusLogGetModel from "./models/CmsLinkStatusLogModel";

export default class CmsLinkStatusLogEffect {
  static async requestCmsLinkStatusLog(params) {
    const endpoint = environment.api.cmsLinkStatusLog;
    return EffectUtility.getToModel(
      CmsLinksStatusLogGetModel,
      endpoint,
      params
    );
  }
}
