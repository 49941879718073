import { BaseModel } from "sjs-base-model";

// IngBannerListModel
export default class IngBannerListModel extends BaseModel {
  // response getting from the api mentioned end point
  data = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
