import BaseReducer from "../../../../utilities/BaseReducer";
import TaskAction from "./TaskAction";

// TaskReduser
export default class TaskReducer extends BaseReducer {
  initialState = {
    Task: [],
  };
  [TaskAction.REQUEST_TASK_FINISHED](state, action) {
    return {
      ...state,
      Task: action.payload,
    };
  }

  [TaskAction.REQUEST_TASK_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Task: action.payload,
    };
  }
}
