import ActionUtility from "../../../utilities/ActionUtility";
import ConsumerAccountEffect from "./ConsumerAccountEffect";

export default class ConsumerAccountAction {
  // ConsumerAccountAction with an function declaration
  static REQUEST_CONSUMER_ACCOUNT =
    "ConsumerAccountAction.REQUEST_CONSUMER_ACCOUNT";
  static REQUEST_CONSUMER_ACCOUNT_FINISHED =
    "ConsumerAccountAction.REQUEST_CONSUMER_ACCOUNT_FINISHED";

  static REQUEST_CONSUMER_ACCOUNT_FILTER =
    "ConsumerAccountAction.REQUEST_CONSUMER_ACCOUNT_FILTER";
  static REQUEST_CONSUMER_ACCOUNT_FILTER_FINISHED =
    "ConsumerAccountAction.REQUEST_CONSUMER_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_CONSUMER_ACCOUNT =
    "ConsumerAccountAction.REQUEST_PUT_CONSUMER_ACCOUNT";
  static REQUEST_PUT_CONSUMER_ACCOUNT_FINISHED =
    "ConsumerAccountAction.REQUEST_PUT_CONSUMER_ACCOUNT_FINISHED";

  static REQUEST_POST_CONSUMER_ACCOUNT =
    "ConsumerAccountAction.REQUEST_POST_CONSUMER_ACCOUNT";
  static REQUEST_POST_CONSUMER_ACCOUNT_FINISHED =
    "ConsumerAccountAction.REQUEST_POST_CONSUMER_ACCOUNT_FINISHED";

  static REQUEST_DELETE_CONSUMER_ACCOUNT =
    "ConsumerAccountAction.REQUEST_DELETE_CONSUMER_ACCOUNT";
  static REQUEST_DELETE_CONSUMER_ACCOUNT_FINISHED =
    "ConsumerAccountAction.REQUEST_DELETE_CONSUMER_ACCOUNT_FINISHED";

  // METHODS
  // Consumer Get function
  static requestConsumerAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAccountAction.REQUEST_CONSUMER_ACCOUNT,
        ConsumerAccountEffect.requestConsumerAccount,
        params
      );
    };
  }

  static requestConsumerAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAccountAction.REQUEST_CONSUMER_ACCOUNT_FILTER,
        ConsumerAccountEffect.requestConsumerAccountFilter,
        params
      );
    };
  }
  static requestPutConsumerAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAccountAction.REQUEST_PUT_CONSUMER_ACCOUNT,
        ConsumerAccountEffect.requestPutConsumerAccount,
        data,
        id
      );
    };
  }

  // Consumer post function
  static requestPostConsumerAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAccountAction.REQUEST_POST_CONSUMER_ACCOUNT,
        ConsumerAccountEffect.requestPostConsumerAccount,
        data
      );
    };
  }

  static requestDeleteConsumerAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ConsumerAccountAction.REQUEST_DELETE_CONSUMER_ACCOUNT,
        ConsumerAccountEffect.requestDeleteConsumerAccount,
        data
      );
    };
  }
}
