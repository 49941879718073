import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, ListItem, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { theme } from "../../../../../views/App";
import PlatformPostDialog from "../../postDataDialogs/PlatformPostDialog";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function MoreFunctionPopper(props) {
  const [open, setOpen] = React.useState(false);
  const morePopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const [postDialogOpen, setPostDialogOpen] = useState(false);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      morePopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (morePopperRef.current && morePopperRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={morePopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={props.value === 0 && open}
        anchorEl={morePopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 200,
                height: "auto",
                boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  style={{
                    height: "auto",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                  }}
                  className="scrollable"
                >
                  <Box
                    width="100%"
                    sx={{ textAlign: "left", padding: "10px" }}
                    // className="bg-warning"
                  >
                    <ListItem>
                      <MuiButton
                        name={"Bulk Update"}
                        onClick={() => {
                          props.selectionModel.length > 0
                            ? props.setOpenBulkAssignDialog(
                                !props.openBulkAssignDialog
                              )
                            : alert("Please Select Atleast one Ticket");
                        }}
                        size="small"
                        height="26px"
                      />
                    </ListItem>

                    {/* <ListItem>
                      <MuiButton
                        name={"Platform Post"}
                        onClick={() => {
                          setPostDialogOpen(true);
                        }}
                        size="small"
                        height="26px"
                      />
                    </ListItem> */}

                    {props.selectionModel.length !== 0 &&
                      props.selectionModel.every(
                        (item) =>
                          item._source &&
                          item._source.platform &&
                          item._source.platform.platform_name === "Playstore"
                      ) && (
                        <ListItem>
                          <MuiButton
                            name={"Bulk Reply - Playstore"}
                            onClick={() => {
                              props.selectionModel.every(
                                (item) =>
                                  item._source &&
                                  item._source.platform &&
                                  item._source.platform.platform_name ===
                                    "Playstore"
                              )
                                ? props.setOpenReplyDialog(
                                    !props.openReplyDialog
                                  )
                                : alert("Please Select Only Playstore tickets");
                            }}
                            size="small"
                            height="26px"
                          />
                        </ListItem>
                      )}

                    {props.selectionModel.length !== 0 &&
                      props.selectionModel.every(
                        (item) =>
                          item._source &&
                          item._source.platform &&
                          item._source.platform.platform_name === "GMB"
                      ) && (
                        <ListItem>
                          <MuiButton
                            name={"Bulk Reply - GMB"}
                            onClick={() => {
                              props.selectionModel.every(
                                (item) =>
                                  item._source &&
                                  item._source.platform &&
                                  item._source.platform.platform_name === "GMB"
                              )
                                ? props.setOpenReplyDialog(
                                    !props.openReplyDialog
                                  )
                                : alert("Please Select Only GMB tickets");
                            }}
                            size="small"
                            height="26px"
                          />
                        </ListItem>
                      )}
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {postDialogOpen && (
        <PlatformPostDialog
          open={postDialogOpen}
          onClose={() => {
            setPostDialogOpen(false);
          }}
        />
      )}
    </Grid>
  );
}

export default connect(mapDispatchToProps)(MoreFunctionPopper);
