import BaseReducer from "../../../utilities/BaseReducer";
import FaqAction from "./FaqAction";

export default class FaqReducer extends BaseReducer {
  initialState = {
    faq: [],
  };
  [FaqAction.REQUEST_GET_FAQ_FINISHED](state, action) {
    return {
      ...state,
      faq: action.payload,
    };
  }
}
