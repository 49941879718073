import BaseReducer from "../../../utilities/BaseReducer";
import TicketCountAction from "./TicketCountAction";

export default class DashboardTicketCountReduser extends BaseReducer {
  //initial state of ticket count
  initialState = {
    dashboardTicketCount: [],
  };

  //ticket count request action finish
  [TicketCountAction.REQUEST_DASHBOARD_TICKET_COUNT_FINISHED](state, action) {
    return {
      ...state,
      dashboardTicketCount: action.payload,
    };
  }
}
