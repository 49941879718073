import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
// Pagination component
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Tab,
  Typography,
} from "@mui/material";
import PostUrl from "./PostUrl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import QuoraFeedsAction from "../../../stores/quoraFeeds/QuoraFeedsAction";
import QuoraAccountAction from "../../../stores/quoraAccount/QuoraAccountAction";
import { theme } from "../../../../views/App";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import PaginationUI from "../../../../components/pagination/Pagination";
// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    QuoraFeedsAction.REQUEST_QUORA_FEED,
    QuoraFeedsAction.REQUEST_QUORA_FEED_FILTER,
    QuoraAccountAction.REQUEST_QUORA_ACCOUNT_FILTER,
  ]),
  quoraFeed: state.quoraFeed.quoraFeed || [],
  pagination: state.quoraFeed.quoraFeed || {},
  quoraFeedFilter: state.quoraFeedFilter.quoraFeedFilter || [],
  quoraAccount: state.quoraAccount.quoraAccount || [],
});

function QuoraListView(props) {
  // useState Functions
  const [date, setDate] = useState([null, null]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [page_limit] = useState(10);
  const [sentimentalValue, setSentimentalValue] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [accountName, setAccountName] = useState("");

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  // importing useStyes hook
  const { isRequesting } = props;

  //tab handle change
  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ************************************************* Dispatch functions

  function fetchQuoraFeeds() {
    let params = {
      page_limit: 10,
    };
    props.dispatch(QuoraFeedsAction.requestQuoraFeed(params));
  }

  function fetchQuoraFeedsFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(QuoraFeedsAction.requestQuoraFeedFilter(params));
  }

  function fetchQuoraAccount() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(QuoraAccountAction.requestQuoraAccount(params));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //   UseEffect function
  useEffect(() => {
    fetchQuoraFeeds();
    fetchQuoraFeedsFilter();
    fetchQuoraAccount();
  }, []);

  // applyFilter Function

  const applyFilter = () => {
    let params = {};
    const filter = [];

    if (sentimentalValue !== "") {
      if (sentimentalValue === "Negative") {
        filter.push(`["sentiment_value.keyword","must","","wildcard","*-*"]`);
      } else if (sentimentalValue === "Positive") {
        filter.push(
          `["sentiment_value.keyword","must_not","","wildcard","*-*"]`
        );
        filter.push(`["sentiment_value.keyword","must_not","","term","0.0"]`);
      } else if (sentimentalValue === "Neutral") {
        filter.push(`["sentiment_value.keyword","must","","term","0.0"]`);
      }
    }

    if (accountName !== "") {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      accountName !== "" ||
      sentimentalValue !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        created_on: "desc",
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      props.dispatch(QuoraFeedsAction.requestQuoraFeed(params));
    } else {
      alert("Please add filters");
      params = {
        created_on: "desc",
        page_limit: `${page_limit}`,
      };
      props.dispatch(QuoraFeedsAction.requestQuoraFeed(params));
    }
  };

  //  autocompleteHandleChange
  const autocompleteHandleChange = (event, value) => {
    if (value != null) {
      setSentimentalValue(value);
    } else {
      setSentimentalValue("");
    }
  };

  const list = [];

  if (props.quoraAccount.hits) {
    props.quoraAccount.hits.hits.map((value) => {
      list.push(value._source.account_name);
    });
  }

  // ************************************* Filter List
  // Integrating Costomer name to list
  // const list = [];
  // if (props.quoraFeedFilter.hits) {
  //   props.quoraFeedFilter.hits.hits.length >= 0 &&
  //     props.quoraFeedFilter.hits.hits.map((item) => {
  //       list.push(item._source.sentiment_value);
  //     });
  // }
  const uniqueList = ["Positive", "Negative", "Neutral"];

  // Clear method
  const ClearState = () => {
    setFilterClicked(false);

    if (
      accountName !== "" ||
      sentimentalValue !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      setSentimentalValue("");
      setAccountName("");
      setDate([null, null]);
      setClearLoader(true);

      if (navigator.onLine === true) {
        const params = {
          page_limit: `${page_limit}`,
        };
        FilterClicked &&
          props.dispatch(QuoraFeedsAction.requestQuoraFeed(params));
      } else {
        alert("No internet connection");
      }
    }
  };

  // pagination
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (accountName !== "") {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    if (sentimentalValue !== "") {
      if (sentimentalValue === "Negative") {
        filter.push(`["sentiment_value.keyword","must","","wildcard","*-*"]`);
      } else if (sentimentalValue === "Positive") {
        filter.push(
          `["sentiment_value.keyword","must_not","","wildcard","*-*"]`
        );
        filter.push(`["sentiment_value.keyword","must_not","","term","0.0"]`);
      } else if (sentimentalValue === "Neutral") {
        filter.push(`["sentiment_value.keyword","must","","term","0.0"]`);
      }
    }
    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (FilterClicked) {
      if (
        accountName !== "" ||
        sentimentalValue !== "" ||
        (date[0] !== null && date[1] !== null)
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(QuoraFeedsAction.requestQuoraFeed(params));
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded_2, setExpanded_2] = React.useState(false);

  const handleChangeExpand_2 = (panel) => (event, isExpanded) => {
    setExpanded_2(isExpanded ? panel : false);
  };

  let maxDate = new Date();

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName("");
    }
  };

  return (
    <Box mt={1}>
      <PostUrl open={open} onSubmit={() => {}} onClose={() => setOpen(false)} />
      {/* *******************************************************Filters */}
      <Grid container>
        {/*Twitter filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            // borderRadius: theme.palette.buttonComponent.borderRadius.large,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            mt={2}
            container
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item lg={2.5} md={4} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <span>Account Name</span>
                </Grid>
                <AutoCompleteComponent
                  value={accountName}
                  list={list}
                  handleChange={accountNameHandleChange}
                  textFieldProps={{
                    placeholder: "Select Account",
                  }}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item lg={2.5} md={4} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <span>Sentimental Value</span>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimentalValue}
                  list={uniqueList}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={autocompleteHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item lg={2.5} md={4} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <span>Date Filter</span>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>
              <Grid item xl={4} lg={3} md={12} sm={12} mt={0}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={2.5}
                >
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      width="100%"
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                  <Grid item xl={3.5} mt={-1} lg={6} md={3} sm={5}>
                    <PostPlusButton
                      open={open}
                      openCloseModal={openCloseModal}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <h5 className="pl-3 pr-3 pt-2"></h5> */}

        {/* <Divider sx={{ borderColor: theme.palette.background.button }} /> */}
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
      </Grid>
      {/* ****************************************************** Divider */}

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
          // className="bg-primary"
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Questions</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid>

          {props.quoraFeed.hits ? (
            <LoadingIndicator isActive={isRequesting}>
              {props.quoraFeed.hits.hits.length !== 0 ? (
                props.quoraFeed.hits.hits.map(
                  (item, index) =>
                    !isRequesting && (
                      <Box
                        sx={{
                          padding: 0,
                          margin: "1.2%",
                          marginTop: 0,
                          marginBottom: "0.5%",
                          borderRadius: theme.borderRadius,
                        }}
                        // className="bg-warning"
                      >
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChangeExpand(`panel${index}`)}
                          style={{
                            backgroundColor: "#fff",
                            height: "auto",
                            padding: 2,
                            borderRadius: theme.borderRadius,
                            border: "solid 0.5px #d3e2e6",
                            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                          >
                            <List
                              sx={{
                                width: "100%",
                              }}
                              // component="nav"
                            >
                              <Grid
                                container
                                // className="bg-warning"
                              >
                                <Grid
                                  container
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  // className="bg-warning"
                                >
                                  <Grid item lg={12} md={12} sm={12} container>
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      container
                                    >
                                      <Grid item lg={3} md={12} sm={12}>
                                        <ListItem>
                                          <Typography
                                            style={{
                                              fontSize: "18px",
                                            }}
                                          >
                                            Account Name :{" "}
                                            {item._source.account_name}
                                          </Typography>{" "}
                                        </ListItem>
                                      </Grid>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={6}
                                        md={12}
                                        sm={12}
                                        // className="bg-warning"
                                      >
                                        <ListItem>
                                          <Typography
                                            style={{
                                              marginRight: "20px",
                                              fontSize: "18px",
                                            }}
                                          >
                                            <a
                                              href={item._source.question_url}
                                              target="blank"
                                              style={{
                                                color:
                                                  theme.palette.primary.main,
                                              }}
                                              className="font-weight-bold"
                                            >
                                              {item._source.question}{" "}
                                            </a>
                                          </Typography>
                                          <Typography>
                                            {" "}
                                            {(function createdDate() {
                                              let created =
                                                item._source.created_on;
                                              let dateConversion = new Date(
                                                created
                                              );
                                              dateConversion =
                                                dateConversion.toDateString() +
                                                ", " +
                                                dateConversion.toLocaleTimeString(
                                                  "en-US",
                                                  {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                  }
                                                );

                                              return dateConversion;
                                            })()}
                                          </Typography>
                                        </ListItem>
                                      </Grid>
                                      <Grid item lg={3} md={12} sm={12}>
                                        <ListItem>
                                          <Typography
                                            style={{
                                              fontSize: "18px",
                                            }}
                                          >
                                            Sentiment Value : &nbsp;{" "}
                                            {item._source.sentiment_value}
                                          </Typography>
                                        </ListItem>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </List>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <span
                                className="font-weight-bold pl-4"
                                style={{
                                  fontSize: 22,
                                }}
                              >
                                Answer
                              </span>
                            </Typography>

                            {item._source.answer && (
                              <Box
                                sx={{
                                  padding: 1,
                                  margin: "1.2%",
                                  marginTop: 0,
                                  marginBottom: "0.5%",
                                  border: "solid 0.5px #d3e2e6",
                                  borderRadius: theme.borderRadius,
                                  boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                }}
                              >
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "70vw",
                                      }}
                                    >
                                      {item._source.answer}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography className="text-justify">
                                      <span className="font-weight-bold">
                                        Detailed Answer
                                      </span>{" "}
                                      : {item._source.answer}
                                      <br></br>
                                      <span className="font-weight-bold">
                                        Score
                                      </span>{" "}
                                      : {item._score}
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                )
              ) : (
                <NoDataFound />
              )}
            </LoadingIndicator>
          ) : null}
        </Box>
      </Grid>
      {props.quoraFeed.hits
        ? props.quoraFeed.hits.hits.length !== 0 &&
          !isRequesting && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChangePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(QuoraListView);
