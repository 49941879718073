import ActionUtility from "../../../../utilities/ActionUtility";
import DiscoveredDataEffect from "./DiscoveredDataEffect";

export default class DiscoveredDataAction {
  static REQUEST_GET_DISCOVERED_DATA =
    "DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA";
  static REQUEST_GET_DISCOVERED_DATA_FINISHED =
    "DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA_FINISHED";

  static REQUEST_PUT_DISCOVERED_DATA =
    "DiscoveredDataAction.REQUEST_PUT_DISCOVERED_DATA";
  static REQUEST_PUT_DISCOVERED_DATA_FINISHED =
    "DiscoveredDataAction.REQUEST_PUT_DISCOVERED_DATA_FINISHED";

  static REQUEST_GET_DISCOVERED_DATA_FILTER =
    "DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA_FILTER";
  static REQUEST_GET_DISCOVERED_DATA_FILTER_FINISHED =
    "DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA_FILTER_FINISHED";

  static REQUEST_CLEAR_DISCOVERED_DATA =
    "DiscoveredDataAction.REQUEST_CLEAR_DISCOVERED_DATA";
  static REQUEST_CLEAR_DISCOVERED_DATA_FINISHED =
    "DiscoveredDataAction.REQUEST_CLEAR_DISCOVERED_DATA_FINISHED";

  static REQUEST_POST_EXPORT_DISCOVERED_DATA =
    "DiscoveredDataAction.REQUEST_POST_EXPORT_DISCOVERED_DATA";
  static REQUEST_POST_EXPORT_DISCOVERED_DATA_FINISHED =
    "DiscoveredDataAction.REQUEST_POST_EXPORT_DISCOVERED_DATA_FINISHED";

  // METHODS
  static requestGetDiscoveredData(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA,
        DiscoveredDataEffect.requestGetDiscoveredData,
        params,
        callback
      );
    };
  }

  static requestPutDiscoveredData(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscoveredDataAction.REQUEST_PUT_DISCOVERED_DATA,
        DiscoveredDataEffect.requestPutDiscoveredData,
        data,
        id
      );
    };
  }

  static requestPostExportDiscoveredData(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscoveredDataAction.REQUEST_POST_EXPORT_DISCOVERED_DATA,
        DiscoveredDataEffect.requestPostExportDiscoveredData,
        params,
        callBackFunction
      );
    };
  }

  static requestGetDiscoveredDataFilter(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA_FILTER,
        DiscoveredDataEffect.requestGetDiscoveredDataFilter,
        params,
        callback
      );
    };
  }

  static requestClearDiscoveredData() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscoveredDataAction.REQUEST_CLEAR_DISCOVERED_DATA,
        DiscoveredDataEffect.requestClearDiscoveredData
      );
    };
  }
}
