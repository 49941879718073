import ActionUtility from "../../../../utilities/ActionUtility";
import TopArtistEffect from "./TopArtistEffect";

export default class TopArtistAction {
  // TopArtistAction action with an function declaration
  static REQUEST_TOPARTIST = "TopArtistAction.REQUEST_TOPARTIST";
  static REQUEST_TOPARTIST_FINISHED =
    "TopArtistAction.REQUEST_TOPARTIST_FINISHED";

  static REQUEST_TOPARTIST_FILTER = "TopArtistAction.REQUEST_TOPARTIST_FILTER";
  static REQUEST_TOPARTIST_FILTER_FINISHED =
    "TopArtistAction.REQUEST_TOPARTIST_FILTER_FINISHED";

  static REQUEST_PUT_TOPARTIST = "TopArtistAction.REQUEST_PUT_TOPARTIST";
  static REQUEST_PUT_TOPARTIST_FINISHED =
    "TopArtistAction.REQUEST_PUT_TOPARTIST_FINISHED";

  static REQUEST_POST_TOPARTIST = "TopArtistAction.REQUEST_POST_TOPARTIST";
  static REQUEST_POST_TOPARTIST_FINISHED =
    "TopArtistAction.REQUEST_POST_TOPARTIST_FINISHED";

  static REQUEST_DELETE_TOPARTIST = "TopArtistAction.REQUEST_DELETE_TOPARTIST";
  static REQUEST_DELETE_TOPARTIST_FINISHED =
    "TopArtistAction.REQUEST_DELETE_TOPARTIST_FINISHED";

  // METHODS
  // Organization GET function
  static requestTopArtist(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopArtistAction.REQUEST_TOPARTIST,
        TopArtistEffect.requestTopArtist,
        params,
        callBack
      );
    };
  }

  static requestTopArtistFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopArtistAction.REQUEST_TOPARTIST_FILTER,
        TopArtistEffect.requestTopArtistFilter,
        params
      );
    };
  }
  static requestPutTopArtist(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopArtistAction.REQUEST_PUT_TOPARTIST,
        TopArtistEffect.requestPutTopArtist,
        data,
        id
      );
    };
  }

  static requestPostTopArtist(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopArtistAction.REQUEST_POST_TOPARTIST,
        TopArtistEffect.requestPostTopArtist,
        data
      );
    };
  }

  static requestDeleteTopArtist(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopArtistAction.REQUEST_DELETE_TOPARTIST,
        TopArtistEffect.requestDeleteTopArtist,
        id
      );
    };
  }
}
