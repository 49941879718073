import { BaseModel } from "sjs-base-model";

export default class ProxyAccountInfoGetModel extends BaseModel {
  message = {};
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
