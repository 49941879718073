import BaseReducer from "../../../utilities/BaseReducer";
import AmbitionBoxAccountAction from "./AmbitionBoxAccountAction";

export default class AmbitionBoxAccountFilterReuser extends BaseReducer {
  //initial state of AmbitionBoxAccountFilterReuser
  initialState = {
    ambitionBoxAccountFilter: [],
  };
  //AmbitionBoxAccountFilterReuser request action finish
  [AmbitionBoxAccountAction.REQUEST_AMBITIONBOX_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ambitionBoxAccountFilter: action.payload,
    };
  }
}
