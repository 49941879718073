import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";
import PlatformHitsModel from "./PlatformHitsModel";

export default class PlatformModel extends BaseModel {
  //priority list data model
  _shards = {};
  hits = PlatformHitsModel;
  timed_out = boolean;
  took = 0;
  total_page_no = 0;
  current_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
