import {
  Avatar,
  Box,
  Grid,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { connect } from "react-redux";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import CustomerDataAction from "../../../../../stores/ticketCustomerData/CustomerDataAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import { theme } from "../../../../../views/App";
import {
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../../../components/ticketPartials";
// mapstateto props function fetching
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
  twitter: state.twitter.twitter.hits || {},
  twitterDirectMeassage:
    state.twitterDirectMeassage.twitterDirectMeassage.hits || [],
});

const TicketCustomerProfileTab = (props) => {
  const returnCustomerNamefromState = (data) => {
    let customer_name = "";

    if (
      data &&
      data.twitter &&
      data.twitter.hits &&
      data.twitter.hits.length !== 0
    ) {
      customer_name = data.twitter.hits[data.twitter.hits.length - 1]._source
        ? `${
            data.twitter.hits[data.twitter.hits.length - 1]._source
              .customer_name
          }`
        : "";

      customer_name =
        customer_name +
        `(@${
          data.twitter.hits[data.twitter.hits.length - 1]._source
            .customer_handel
            ? data.twitter.hits[data.twitter.hits.length - 1]._source
                .customer_handel
            : null
        })`;
    }

    if (customer_name === "") {
      if (
        data &&
        data.twitterDirectMeassage &&
        data.twitterDirectMeassage.hits &&
        data.twitterDirectMeassage.hits.length !== 0
      ) {
        let filtered_array = [];
        filtered_array = data.twitterDirectMeassage.hits.filter((item) => {
          return item._source.flag === "received";
        });

        customer_name =
          filtered_array &&
          filtered_array.length &&
          filtered_array[0]._source &&
          filtered_array[0]._source.sender_name &&
          filtered_array[0]._source.sender_name;

        let full_name;

        let customer_name_handle =
          filtered_array &&
          filtered_array.length &&
          filtered_array[0]._source &&
          filtered_array[0]._source.customer_handle
            ? `(@${
                filtered_array &&
                filtered_array.length !== 0 &&
                filtered_array[0]._source &&
                filtered_array[0]._source.customer_handle
              })`
            : "";

        full_name = customer_name + customer_name_handle;

        customer_name = full_name;
      }
    }

    return customer_name ? customer_name : "";
  };
  function returnCustomerName(customerID) {
    return props.customerData.hits &&
      props.customerData.hits.hits &&
      props.customerData.hits.hits.length > 0 &&
      props.customerData.hits.hits.filter((item) => item._id === customerID)[0]
      ? props.customerData.hits.hits.filter(
          (item) => item._id === customerID
        )[0]._source.customer_name
      : null;
  }

  function returnHandleName(customerID, platform) {
    return props.customerData.hits &&
      props.customerData.hits.hits &&
      props.customerData.hits.hits.length > 0 &&
      props.customerData.hits.hits.filter((item) => item._id === customerID)[0]
      ? props.customerData.hits.hits
          .filter((item) => item._id === customerID)[0]
          ._source.platform.filter((P_item) => {
            return P_item.platform_name === platform;
          })[0].account_id
      : null;
  }

  function returnSentimentUpliftScore(customerID) {
    return props.customerData.hits &&
      props.customerData.hits.hits &&
      props.customerData.hits.hits.length > 0 &&
      props.customerData.hits.hits.filter((item) => item._id === customerID)[0]
      ? props.customerData.hits.hits.filter(
          (item) => item._id === customerID
        )[0]._source.social_score
      : null;
  }

  function returnCustomerModifiedOnDate(customerID) {
    return props.customerData.hits &&
      props.customerData.hits.hits &&
      props.customerData.hits.hits.length > 0 &&
      props.customerData.hits.hits.filter((item) => item._id === customerID)[0]
      ? props.customerData.hits.hits.filter(
          (item) => item._id === customerID
        )[0]._source.modified_on
      : null;
  }

  function returnCustomerPhone(customerID) {
    return props.customerData.hits &&
      props.customerData.hits.hits &&
      props.customerData.hits.hits.length > 0 &&
      props.customerData.hits.hits.filter((item) => item._id === customerID)[0]
      ? props.customerData.hits.hits.filter(
          (item) => item._id === customerID
        )[0]._source.customer_phone
      : null;
  }

  function returnCustomerEmail(customerID) {
    return props.customerData.hits &&
      props.customerData.hits.hits &&
      props.customerData.hits.hits.length > 0 &&
      props.customerData.hits.hits.filter((item) => item._id === customerID)[0]
      ? props.customerData.hits.hits.filter(
          (item) => item._id === customerID
        )[0]._source.customer_email
      : null;
  }

  const hanldeInfluencerPut = (item) => {
    let customer_id = item.customer_id && item.customer_id.customer_id;

    let params = {
      // page_limit: `${page_limit}`,
      order_by: '[["modified_on", "desc"]]',
    };

    if (item.customer_id) {
      if (item.customer_id.is_influencer) {
        let data = { is_influencer: false };
        props
          .dispatch(
            CustomerDataAction.requestPutCustomerData(
              JSON.stringify(data),
              customer_id
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else {
        let data = { is_influencer: true };

        props
          .dispatch(
            CustomerDataAction.requestPutCustomerData(
              JSON.stringify(data),
              customer_id
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      }
    }
  };

  return (
    <Box
      sx={{
        padding: 1,
        height: "100%",
      }}
      className="scrollable"
    >
      {props.ticketData._source && (
        <div>
          <Box width={"100%"}>
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="flex-start"
              alignItems={"flex-start"}
              // className="bg-warning"
            >
              <Grid item xl={1} lg={2} md={2} sm={2} xs={1}>
                <Avatar
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                  src={
                    props.ticketData._source &&
                    props.ticketData._source.customer_id &&
                    props.ticketData._source.customer_id.customer_profile_url
                  }
                >
                  {/* {item._source.account_name.charAt(
                                              0
                                            )} */}
                </Avatar>
              </Grid>

              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                {/* <p className="p-0 m-0">
              <span className="font-weight-bold">
                {" "}
                {returnCustomerName(props.ticketData._source.customer_id)}
              </span>
              <span
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                ({" "}
                {`@${returnHandleName(
                  props.ticketData._source.customer_id,
                  props.ticketData._source.platform
                )}`}
                )
              </span>
            </p> */}

                <ListItem className="p-0 m-0">
                  <p className="font-weight-bold p-0 m-0">
                    {" "}
                    {props.ticketData._source &&
                    props.ticketData._source.platform.platform_name ===
                      "Twitter"
                      ? returnCustomerNamefromState(props)
                      : props.ticketData._source &&
                        props.ticketData._source.customer_id &&
                        props.ticketData._source.customer_id.customer_name}
                  </p>

                  <p
                    className="p-0 m-0"
                    style={{
                      color: "#a0bfc9",
                      fontSize: 12,
                    }}
                  >
                    {/* ({" "}
                    {`@${returnHandleName(
                      props.ticketData._source.customer_id,
                      props.ticketData._source.platform
                    )}`}
                    ) */}
                  </p>
                </ListItem>

                <Typography sx={{ display: "flex" }}>
                  <Typography>Influencer</Typography>
                  <Tooltip title="Mark as Influencer">
                    <ListItem
                      sx={{
                        height: 20,
                        backgroundColor: theme.palette.background.paper,
                        padding: 1,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        hanldeInfluencerPut(props.ticketData._source);
                      }}
                    >
                      {props.ticketData._source &&
                      props.ticketData._source.customer_id &&
                      props.ticketData._source.customer_id.is_influencer ? (
                        <>
                          <CheckCircleIcon
                            style={{
                              width: "16px",
                              marginRight: 2,
                              // color: `${theme.palette.ticketListView.iconColor}`,
                              color: "green",
                            }}
                          />
                          <Typography
                            fontSize={{
                              xl: "14px",
                              lg: "12px",
                              md: "11px",
                              laptop: "11px",
                            }}
                            display={{
                              xl: "block",
                              lg: "block",
                              md: "block",
                              sm: "block",
                              laptop: "none",
                            }}
                          >
                            <span
                              className="m-0 p-0"
                              style={{ color: "#86aeb6" }}
                            >
                              UnMark as Influencer
                            </span>
                          </Typography>
                        </>
                      ) : (
                        <>
                          <CheckCircleOutlineIcon
                            style={{
                              width: "16px",
                              marginRight: 2,
                              color: "red",
                              // color: `${theme.palette.ticketListView.iconColor}`,
                            }}
                          />
                          <Typography
                            fontSize={{
                              xl: "14px",
                              lg: "12px",
                              md: "11px",
                              laptop: "11px",
                            }}
                            display={{
                              xl: "block",
                              lg: "block",
                              md: "block",
                              sm: "block",
                              laptop: "none",
                            }}
                          >
                            <span
                              style={{ color: "#86aeb6" }}
                              className="m-0 p-0"
                            >
                              Mark as Influencer
                            </span>
                          </Typography>
                        </>
                      )}
                    </ListItem>
                  </Tooltip>
                </Typography>

                {/* {returnSentimentUpliftScore(
                  props.ticketData._source.customer_id
                ) !== null && (
                  <p className="pb-0 mb-0">
                    <span>Sentiment uplift score</span>&nbsp;:&nbsp;
                    <span
                      style={{
                        color: "#a0bfc9",
                        fontSize: 12,
                      }}
                    >
                      {returnSentimentUpliftScore(
                        props.ticketData._source.customer_id
                      )}
                    </span>
                  </p>
                )} */}

                {/* <p className="p-0 m-0">
                  <span
                    style={{
                      color: "#a0bfc9",
                      fontSize: 12,
                    }}
                  >
                    Last update on
                  </span>
                  &nbsp;:&nbsp;
                  <span
                    style={{
                      color: "#a0bfc9",
                      fontSize: 12,
                    }}
                  >
                    {(function createdDate() {
                      let created = returnCustomerModifiedOnDate(
                        props.ticketData._source.customer_id
                      );
                      let dateConversion = new Date(created);
                      dateConversion =
                        dateConversion.toDateString() +
                        " " +
                        dateConversion.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        });

                      return dateConversion;
                    })()}
                  </span>
                </p> */}
              </Grid>
            </Grid>
          </Box>

          <Box width={"100%"} mt={3} mb={2}>
            <hr className="p-0 m-0" />
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <p className="font-weight-bold">Profile</p>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                {/* <KeyboardArrowDownIcon style={{ width: 15 }} /> */}
              </Grid>
            </Grid>
            <hr className="p-0 m-0" />
          </Box>

          <Box width={"100%"} p={2}>
            <Box width={"100%"}>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-start"
                alignItems={"center"}
                // className="bg-warning"
              >
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  // className="bg-success"
                >
                  {returnPlatformDropdownLogos(
                    props.ticketData._source &&
                      props.ticketData._source.customer_id &&
                      props.ticketData._source.customer_id.platform &&
                      props.ticketData._source.customer_id.platform[0] &&
                      props.ticketData._source.customer_id.platform[0]
                        .platform_name
                  )}
                </Grid>
                <Grid
                  item
                  xl={10.5}
                  lg={10.5}
                  md={10.5}
                  sm={10.5}
                  xs={10.5}
                  // className="bg-info"
                >
                  <ListItem className="p-0 m-0">
                    <p className="font-weight-bold p-0 m-0">
                      {" "}
                      {/* {props.ticketData._source &&
                        props.ticketData._source.customer_id &&
                        props.ticketData._source.customer_id.customer_name} */}
                      {props.ticketData._source &&
                      props.ticketData._source.platform.platform_name ===
                        "Twitter"
                        ? returnCustomerNamefromState(props)
                        : props.ticketData._source &&
                          props.ticketData._source.customer_id &&
                          props.ticketData._source.customer_id.customer_name}
                    </p>

                    <p
                      className="p-0 m-0"
                      style={{
                        color: "#a0bfc9",
                        fontSize: 12,
                      }}
                    >
                      {/* ({" "}
                      {`@${returnHandleName(
                        props.ticketData._source.customer_id,
                        props.ticketData._source.platform
                      )}`}
                      ) */}
                    </p>
                  </ListItem>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* <Box width={"100%"} mt={2}>
            <hr className="p-0 m-0" />
            <p className="font-weight-bold">Traits</p>
          </Box> */}

          {/* <Box
            width={"100%"}
          >
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
              justifyContent={"center"}
            >
              <Grid item xl={12}>
                <MuiTextField size="small" placeholder="Add Traits" />
              </Grid>
            </Grid>
          </Box> */}

          {/* <Box width={"100%"} mt={3} mb={2}>
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Grid item xl={3} lg={3} md={2} sm={2} xs={2}>
                <p className="font-weight-bold">CRM Details</p>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <KeyboardArrowDownIcon style={{ width: 15 }} />
              </Grid>
            </Grid>
          </Box> */}

          {/* <Box width={"100%"}>
            <p className="p-0 m-0">
              <span
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                Name
              </span>
              &nbsp;:&nbsp;
              <span
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                {props.ticketData._source &&
                  props.ticketData._source.customer_id &&
                  props.ticketData._source.customer_id._customer_name}
              </span>
            </p>

            <p className="p-0 m-0">
              <span
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                EmailID
              </span>
              &nbsp;:&nbsp;
              <span
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                {props.ticketData._source &&
                  props.ticketData._source.customer_id &&
                  props.ticketData._source.customer_id.customer_email}
              </span>
            </p>

            <p className="p-0 m-0">
              <span
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                PhoneNumber
              </span>
              &nbsp;:&nbsp;
              <span
                style={{
                  color: "#a0bfc9",
                  fontSize: 12,
                }}
              >
                {props.ticketData._source &&
                  props.ticketData._source.customer_id &&
                  props.ticketData._source.customer_id.customer_phone}
              </span>
            </p>
          </Box> */}
        </div>
      )}
    </Box>
  );
};

export default connect(mapStateToProps)(TicketCustomerProfileTab);
