import ActionUtility from "../../utilities/ActionUtility";
import CampaignMonitoringEffect from "./CampaignMonitoringEffect";

export default class CampaignMonitoringAction {
  // CampaignMonitoringAction action with an function declaration
  static REQUEST_CAMPAIN_MONITORING =
    "CampaignMonitoringAction.REQUEST_CAMPAIN_MONITORING";
  static REQUEST_CAMPAIN_MONITORING_FINISHED =
    "CampaignMonitoringAction.REQUEST_CAMPAIN_MONITORING_FINISHED";

  static REQUEST_CAMPAIGN_MONITORING_FILTER =
    "CampaignMonitoringAction.REQUEST_CAMPAIGN_MONITORING_FILTER";
  static REQUEST_CAMPAIGN_MONITORING_FILTER_FINISHED =
    "CampaignMonitoringAction.REQUEST_CAMPAIGN_MONITORING_FILTER_FINISHED";

  static REQUEST_PUT_CAMPAIGN_MONITORING =
    "CampaignMonitoringAction.REQUEST_PUT_CAMPAIGN_MONITORING";
  static REQUEST_PUT_CAMPAIGN_MONITORING_FINISHED =
    "CampaignMonitoringAction.REQUEST_PUT_CAMPAIGN_MONITORING_FINISHED";

  static REQUEST_POST_CAMPAIGN_MONITORING =
    "CampaignMonitoringAction.REQUEST_POST_CAMPAIGN_MONITORING";
  static REQUEST_POST_CAMPAIGN_MONITORING_FINISHED =
    "CampaignMonitoringAction.REQUEST_POST_CAMPAIGN_MONITORING_FINISHED";

  static REQUEST_DELETE_CAMPAIGN_MONITORING =
    "AmbitionBoxAccountAction.REQUEST_DELETE_CAMPAIGN_MONITORING";
  static REQUEST_DELETE_CAMPAIGN_MONITORING_FINISHED =
    "AmbitionBoxAccountAction.REQUEST_DELETE_CAMPAIGN_MONITORING_FINISHED";

  // METHODS
  // CampainList GET function
  static requestCampainMonitoring(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMonitoringAction.REQUEST_CAMPAIN_MONITORING,
        CampaignMonitoringEffect.requestCampainMonitoring,
        params
      );
    };
  }

  static requestCampainMonitoringFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMonitoringAction.REQUEST_CAMPAIGN_MONITORING_FILTER,
        CampaignMonitoringEffect.requestCampainMonitoringFilter,
        params
      );
    };
  }
  static requestPutCampainMonitoring(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMonitoringAction.REQUEST_PUT_CAMPAIGN_MONITORING,
        CampaignMonitoringEffect.requestPutCampainMonitoring,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostCampainMonitoring(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMonitoringAction.REQUEST_POST_CAMPAIGN_MONITORING,
        CampaignMonitoringEffect.requestPostCampainMonitoring,
        data
      );
    };
  }

  static requestDeleteCampainMonitoring(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CampaignMonitoringAction.REQUEST_DELETE_CAMPAIGN_MONITORING,
        CampaignMonitoringEffect.requestDeleteAmbitionBoxAccount,
        data
      );
    };
  }
}
