import { BaseModel } from "sjs-base-model";
import ResponseCategorySourceModel from "./ResponseCategorySourceModel";

export default class ResponseCategoryDataHitsModel extends BaseModel {
  _id = "";
  _index = "";
  _score = 0.0;
  _source = [ResponseCategorySourceModel];

  constructor(data) {
    super();

    this.update(data);
  }
}
