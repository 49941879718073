import ActionUtility from "../../../../utilities/ActionUtility";
import SessionLogEffect from "./SessionLogEffect";

export default class SessionLogAction {
  // SessionLogAction action with an function declaration
  static REQUEST_SESSION_LOG = "SessionLogAction.REQUEST_SESSION_LOG";
  static REQUEST_SESSION_LOG_FINISHED =
    "SessionLogAction.REQUEST_SESSION_LOG_FINISHED";

  static REQUEST_SESSION_LOG_FILTER =
    "SessionLogAction.REQUEST_SESSION_LOG_FILTER";
  static REQUEST_SESSION_LOG_FILTER_FINISHED =
    "SessionLogAction.REQUEST_SESSION_LOG_FILTER_FINISHED";

  static REQUEST_SESSION_LOG_REPORT =
    "SessionLogAction.REQUEST_SESSION_LOG_REPORT";
  static REQUEST_SESSION_LOG_REPORT_FINISHED =
    "SessionLogAction.REQUEST_SESSION_LOG_REPORT_FINISHED";

  // METHODS
  static requestSessionLog(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SessionLogAction.REQUEST_SESSION_LOG,
        SessionLogEffect.requestSessionLog,
        params
      );
    };
  }

  static requestSessionLogFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SessionLogAction.REQUEST_SESSION_LOG_FILTER,
        SessionLogEffect.requestSessionLogFilter,
        params
      );
    };
  }

  static requestSessionLogReport(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SessionLogAction.REQUEST_SESSION_LOG_REPORT,
        SessionLogEffect.requestSessionLogReport,
        params
      );
    };
  }
}
