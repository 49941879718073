import BaseReducer from "../../../utilities/BaseReducer";
import AmbitionBoxAccountAction from "./AmbitionBoxAccountAction";

// AmbitionBoxAccountReduser  function
export default class AmbitionBoxAccountReduser extends BaseReducer {
  initialState = {
    ambitionBoxAccount: [],
  };
  [AmbitionBoxAccountAction.REQUEST_AMBITONBOX_ACCOUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ambitionBoxAccount: action.payload,
    };
  }
}
