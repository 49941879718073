import BaseReducer from "../../../utilities/BaseReducer";
import TicketSettingsAction from "./TicketSettingsAction";

export default class TicketSettingsReducer extends BaseReducer {
  //initial state of ticketType
  initialState = {
    ticketSettings: [],
  };

  //tickettype request action finish
  [TicketSettingsAction.REQUEST_TICKET_SETTINGS_FINISHED](state, action) {
    return {
      ...state,
      ticketSettings: action.payload,
    };
  }
}
