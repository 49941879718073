import { Component } from "react";
import ActionUtility from "../../../utilities/ActionUtility";
import UnRetweetEffect from "./UnRetweetEffect";

export default class UnRetweetAction extends Component {
  //Retweet get
  static REQUEST_UNRETWEET = "UnRetweetAction.REQUEST_UNRETWEET";
  static REQUEST_UNRETWEET_FINISHED =
    "UnRetweetAction.REQUEST_UNRETWEET_FINISHED";

  //   static REQUEST_ERROR =
  //   "RetweetAction.REQUEST_RETWEET";
  // static REQUEST_ERROR_FINISHED =
  //   "RetweetAction.REQUEST_RETWEET_FINISHED";

  //Retweet post method
  static requestUnRetweet(id, params) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UnRetweetAction.REQUEST_UNRETWEET,
        UnRetweetEffect.requestUnRetweet,
        id,
        params
      );
    };
  }

  // static requestError(){
  //   return async (dispatch, getstate) => {
  //     await ActionUtility.createThunkEffect(
  //       dispatch,
  //       UnRetweetAction.REQUEST_ERROR,
  //       RetweetEffect.requestError,
  //     );
  //   };
  // }
}
