import ActionUtility from "../../../utilities/ActionUtility";
import OrmResourceEffect from "./OrmResourceEffect";

export default class OrmResourceAction {
  // CLIENT action with an function declaration
  static REQUEST_ORM_RESOURCE = "OrmResourceAction.REQUEST_ORM_RESOURCE";
  static REQUEST_ORM_RESOURCE_FINISHED =
    "OrmResourceAction.REQUEST_ORM_RESOURCE_FINISHED";

  // METHODS
  // CLIENT GET function
  static requestOrmResource(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmResourceAction.REQUEST_ORM_RESOURCE,
        OrmResourceEffect.requestOrmResource,
        params
      );
    };
  }
}
