import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

import { Form, Formik, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import TicketsAction from "../../../ormTicketing/stores/tickets/TicketsAction";
import { theme } from "../../App";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const TicketDescEditModel = (props) => {
  let initial = {};
  initial = {
    ticket_name: props.EditData
      ? props.EditData._source && props.EditData._source.ticket_name
      : "",
    ticket_desc: props.EditData
      ? props.EditData._source && props.EditData._source.ticket_desc
      : "",
  };

  const validationSchema = yup.object({
    ticket_name: yup
      .string("Enter Ticket name")
      .trim()
      .min(1, "Ticket name should be of minimum 1 characters length")
      .required("Ticket name is required"),

    ticket_desc: yup
      .string("Enter Ticket Description")
      .trim()
      .min(1, "Ticket Description should be of minimum 1 characters length")
      .required("Ticket Description is required"),
  });

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          props
            .dispatch(
              TicketsAction.requestPutTicket(
                JSON.stringify(values),
                props.EditData._id
              )
            )
            .then(() => {
              props.dispatch(
                TicketsAction.requestTicketsById(props.EditData._id)
              );

              // props.fetchFunction();
              // props.dispatch(TicketsAction.requestTickets(params));
              setLoading(false);
              props.onClose();
            });

          // formikHelpers.resetForm();
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;E
                          </span>
                          dit &nbsp;
                          <span className="span_first_letter">T</span>
                          icket &nbsp;
                        </b>
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div className="col-2 closebtn">
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                          onClick={() => {
                            props.onClose();
                          }}
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Ticket Name * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Ticket Name"
                      id="ticket_name"
                      size="small"
                      name="ticket_name"
                    />
                    <Box px={2}>
                      <ErrorMessage name="ticket_name" />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} mt={3}>
                    Ticket Description * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9} mt={2}>
                    <Field
                      className="ticket_desc_test_area scrollable"
                      name="ticket_desc"
                      component="textarea"
                      value={values.ticket_desc}
                      onChange={handleChange}
                      // defaultValue={demoText.textarea}
                      type="text"
                      maxRows={5}
                      minRows={5}
                      style={{
                        padding: "10px",
                        height: 150,
                        boxSizing: "border-box",
                        color: `${theme.palette.text.primary}`,
                      }}
                      aria-label="maximum height"
                      placeholder="Enter Ticket Description"
                      label="ticket_desc"
                      error={
                        Boolean(errors.ticket_desc) &&
                        Boolean(touched.ticket_desc)
                      }
                      helperText={
                        Boolean(touched.ticket_desc) && errors.ticket_desc
                      }
                    />
                    <Box px={2}>
                      <ErrorMessage name="ticket_desc" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    type="submit"
                    name="Submit"
                    width="100%"
                    loading={loading}
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Cancel"}
                    width="100%"
                    onClick={() => {
                      props.onClose();
                      // Formik.resetForm;
                    }}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(TicketDescEditModel);
