import ActionUtility from "../../../../utilities/ActionUtility";

import SurveyUserMapEffect from "./SurveyUserMapEffect";

export default class SurveyUserMapAction {
  static REQUEST_SURVEY_USER_MAP =
    "SurveyUserMapAction.REQUEST_SURVEY_USER_MAP";
  static REQUEST_SURVEY_USER_MAP_FINISHED =
    "SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_FINISHED";

  static REQUEST_SURVEY_USER_MAP_POST =
    "SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_POST";
  static REQUEST_SURVEY_USER_MAP_POST_FINISHED =
    "SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_POST_FINISHED";

  static REQUEST_SURVEY_USER_MAP_DELETE =
    "SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_DELETE";
  static REQUEST_SURVEY_USER_MAP_DELETE_FINISHED =
    "SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_DELETE_FINISHED";

  static requestSurveyUserMap(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserMapAction.REQUEST_SURVEY_USER_MAP,
        SurveyUserMapEffect.requestSurveyUserMap,
        params
      );
    };
  }

  static requestSurveyUserMapPost(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_POST,
        SurveyUserMapEffect.requestSurveyUserMapPost,
        data
      );
    };
  }

  static requestDeleteSurveyUserMap(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserMapAction.REQUEST_SURVEY_USER_MAP_DELETE,
        SurveyUserMapEffect.requestDeleteSurveyUserMap,
        id
      );
    };
  }
}
