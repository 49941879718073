import { BaseModel } from "sjs-base-model";

export default class WhitelistedSitesGetModal extends BaseModel {
  _shards = {};
  current_page_no = 0;
  hits = {};
  timed_out = false;
  took = 0;
  total_page_no = 0;

  constructor(data) {
    super();
    this.update(data);
  }
}
