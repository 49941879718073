import ActionUtility from "../../../../utilities/ActionUtility";
import WebformsEffect from "./WebformsEffect";

export default class WebformsAction {
  static REQUEST_GET_WEBFORMS = "WebformsAction.REQUEST_GET_WEBFORMS";
  static REQUEST_GET_WEBFORMS_FINISHED =
    "WebformsAction.REQUEST_GET_WEBFORMS_FINISHED";

  static REQUEST_GET_WEBFORMS_CSV_DATA =
    "WebformsAction.REQUEST_GET_WEBFORMS_CSV_DATA";
  static REQUEST_GET_WEBFORMS_CSV_DATA_FINISHED =
    "WebformsAction.REQUEST_GET_WEBFORMS_CSV_DATA_FINISHED";

  static REQUEST_WEBFORMS_FILTER = "WebformsAction.REQUEST_WEBFORMS_FILTER";
  static REQUEST_WEBFORMS_FILTER_FINISHED =
    "WebformsAction.REQUEST_WEBFORMS_FILTER_FINISHED";

  static REQUEST_POST_WEBFORMS = "WebformsAction.REQUEST_POST_WEBFORMS";
  static REQUEST_POST_WEBFORMS_FINISHED =
    "WebformsAction.REQUEST_POST_WEBFORMS_FINISHED";

  static REQUEST_PUT_WEBFORMS = "WebformsAction.REQUEST_PUT_WEBFORMS";
  static REQUEST_PUT_WEBFORMS_FINISHED =
    "WebformsAction.REQUEST_PUT_WEBFORMS_FINISHED";

  static REQUEST_POST_CSV_DATA = "WebformsAction.REQUEST_POST_CSV_DATA";
  static REQUEST_POST_CSV_DATA_FINISHED =
    "WebformsAction.REQUEST_POST_CSV_DATA_FINISHED";

  // METHODS
  static requestWebforms(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebformsAction.REQUEST_GET_WEBFORMS,
        WebformsEffect.requestWebforms,
        params,
        callback
      );
    };
  }

  static requestWebformsCsvData(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebformsAction.REQUEST_GET_WEBFORMS_CSV_DATA,
        WebformsEffect.requestWebformsCsvData,
        params,
        callback
      );
    };
  }

  static requestWebformsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebformsAction.REQUEST_WEBFORMS_FILTER,
        WebformsEffect.requestWebformsFilter,
        params
      );
    };
  }

  static requestPostWebforms(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebformsAction.REQUEST_POST_WEBFORMS,
        WebformsEffect.requestPostWebforms,
        data,
        callBackFunction
      );
    };
  }

  static requestPutWebforms(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebformsAction.REQUEST_PUT_WEBFORMS,
        WebformsEffect.requestPutWebforms,
        data,
        id
      );
    };
  }

  static requestPostCSVData(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebformsAction.REQUEST_POST_CSV_DATA,
        WebformsEffect.requestPostCSVData,
        data,
        params,
        callBack
      );
    };
  }
}
