import ActionUtility from "../../../utilities/ActionUtility";
import UserPermissionEffect from "./UserPermissionEffect";
export default class UserPermissionAction {
  // userPermission action with an function declaration
  static REQUEST_USER_PERSMISSION =
    "UserPermissionAction.REQUEST_USER_PERSMISSION";
  static REQUEST_USER_PERSMISSION_FINISHED =
    "UserPermissionAction.REQUEST_USER_PERSMISSION_FINISHED";

  // user Permission post
  static REQUEST_POST_USER_PERSMISSION =
    "UserPermissionAction.REQUEST_POST_USER_PERSMISSION";
  static REQUEST_POST_USER_PERSMISSION_FINISHED =
    "UserPermissionAction.REQUEST_POST_USER_PERSMISSION_FINISHED";

  // category put
  //   static REQUEST_PUT_CATEGORY = "UserPermissionAction.REQUEST_PUT_CATEGORY";
  //   static REQUEST_PUT_CATEGORY_FINISHED =
  //     "UserPermissionAction.REQUEST_PUT_CATEGORY_FINISHED";

  // user Permission post
  static REQUEST_DELETE_USER_PERSMISSION =
    "UserPermissionAction.REQUEST_DELETE_USER_PERSMISSION";
  static REQUEST_DELETE_USER_PERSMISSION_FINISHED =
    "UserPermissionAction.REQUEST_DELETE_USER_PERSMISSION_FINISHED";

  // METHODS
  // Category GET function
  static requestUserPermission(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserPermissionAction.REQUEST_USER_PERSMISSION,
        UserPermissionEffect.requestUserPermission,
        params
      );
    };
  }

  //   static requestCategoryFilter(params) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         UserPermissionAction.REQUEST_USER_PERSMISSION_FILTER,
  //         CategoryEffect.requestCategoryFilter,
  //         params
  //       );
  //     };
  //   }

  // USER post function
  static requestPostUserPermission(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserPermissionAction.REQUEST_POST_USER_PERSMISSION,
        UserPermissionEffect.requestPostUserPermission,
        data
      );
    };
  }

  // category put function
  //   static requestPutCategory(data, id) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         UserPermissionAction.REQUEST_PUT_CATEGORY,
  //         CategoryEffect.requestPutCategory,
  //         data,
  //         id
  //       );
  //     };
  //   }

  static requestDeleteUserPermission(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserPermissionAction.REQUEST_DELETE_USER_PERSMISSION,
        UserPermissionEffect.requestDeleteUserPermission,
        id
      );
    };
  }
}
