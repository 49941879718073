import ActionUtility from "../../../../utilities/ActionUtility";
import LeadEffect from "./LeadEffect";

export default class LeadAction {
  // LeadAction action with an function declaration
  static REQUEST_LEAD = "LeadAction.REQUEST_LEAD";
  static REQUEST_LEAD_FINISHED = "LeadAction.REQUEST_LEAD_FINISHED";

  static REQUEST_LEAD_FILTER = "LeadAction.REQUEST_LEAD_FILTER";
  static REQUEST_LEAD_FILTER_FINISHED =
    "LeadAction.REQUEST_LEAD_FILTER_FINISHED";

  static REQUEST_PUT_LEAD = "LeadAction.REQUEST_PUT_LEAD";
  static REQUEST_PUT_LEAD_FINISHED = "LeadAction.REQUEST_PUT_LEAD_FINISHED";

  static REQUEST_POST_LEAD = "LeadAction.REQUEST_POST_LEAD";
  static REQUEST_POST_LEAD_FINISHED = "LeadAction.REQUEST_POST_LEAD_FINISHED";

  static REQUEST_LEAD_CLEAR = "LeadAction.REQUEST_LEAD_CLEAR";
  static REQUEST_LEAD_CLEAR_FINISHED = "LeadAction.REQUEST_LEAD_CLEAR_FINISHED";

  static REQUEST_LEAD_FILTER_CLEAR = "LeadAction.REQUEST_LEAD_FILTER_CLEAR";
  static REQUEST_LEAD_FILTER_CLEAR_FINISHED =
    "LeadAction.REQUEST_LEAD_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_LEAD = "LeadAction.REQUEST_DELETE_LEAD";
  static REQUEST_DELETE_LEAD_FINISHED =
    "LeadAction.REQUEST_DELETE_LEAD_FINISHED";

  // METHODS
  // Lead GET function
  static requesLead(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadAction.REQUEST_LEAD,
        LeadEffect.requesLead,
        params
      );
    };
  }

  static requesLeadClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadAction.REQUEST_LEAD_CLEAR,
        LeadEffect.requesLeadClear
      );
    };
  }

  static requesLeadFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadAction.REQUEST_LEAD_FILTER_CLEAR,
        LeadEffect.requesLeadFilterClear
      );
    };
  }

  static requestLeadFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadAction.REQUEST_LEAD_FILTER,
        LeadEffect.requestLeadFilter,
        params
      );
    };
  }
  static requestPutLead(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadAction.REQUEST_PUT_LEAD,
        LeadEffect.requestPutLead,
        data,
        id
      );
    };
  }

  static requestPostLead(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadAction.REQUEST_POST_LEAD,
        LeadEffect.requestPostLead,
        data
      );
    };
  }
  static requestDeleteLead(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LeadAction.REQUEST_DELETE_LEAD,
        LeadEffect.requestDeleteLead,
        id
      );
    };
  }
}
