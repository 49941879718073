import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  SvgIcon,
} from "@mui/material";
import { Formik, Form, Field, getIn, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";

import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../components/muiButton/MuiButton";
import React, { useEffect, useRef, useState } from "react";
import CustomTicketFieldsAction from "../../../stores/customticketfields/CustomTicketFieldsAction";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";

import { useSpring, animated } from "@react-spring/web";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { theme } from "../../../views/App";
import TextArea from "antd/lib/input/TextArea";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    marginTop: 5,
    marginBottom: 5,
  },
}));

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

// Validation Schema
const validationSchema = yup.object().shape({
  field_name: yup
    .string("Enter your custom ticket field name")
    .trim()
    .min(1, "Ticket Field Name should be of minimum 1 characters length")
    .required("Ticket Field Name is required"),

  // project: yup
  //   .object()
  //   .shape({
  //     project_name: yup
  //       .string()
  //       .transform((value, originalValue) =>
  //         originalValue.trim() === "" ? null : value
  //       )
  //       .nullable(),
  //   })
  //   .required("Project required"),

  // project: yup.object({}).required("required").nullable(),
  // project: yup
  //   .object()
  //   .shape({
  //     project_name: yup.string(),
  //     project_id: yup.string(),
  //   })
  //   .nullable()
  //   .required("required"),

  // project: yup
  //   .object({ project_name: yup.string() })
  //   .nullable()
  //   .required("required"),

  // project: yup
  //   .object({
  //     project_name: yup.string().required("aaa"),
  //     project_id: yup.string().required("aaa"),
  //   })
  //   .required("Required"),

  // project: yup.object().shape({
  //   project_name: yup.string().required("label is required"),
  //   project_id: yup.string().required("value is required"),
  // }),

  field_alias: yup
    .string("Enter your custom ticket field alias")
    .trim()
    .min(1, "Ticket Field Alias should be of minimum 1 characters length")
    .required("Ticket Field Alias is required"),

  field_type: yup.string().required("Ticket Field Type is required").nullable(),

  // parent_field_id:
  // .string("Enter your custom ticket parent field name")
  // .min(1, "Ticket Parent Field Name should be of minimum 1 characters length")
  // yup.required(),
});

const CustomTicketPost = (props) => {
  // project pros from project api fectched by category component
  let projectProps = props.project;

  const [booleanVal, setBooleanVal] = useState(false);

  // *************Custom ticket fields*****************
  const [fieldtypeValues, setFieldtypeValues] = useState("");
  const [selectProject, setSelectProject] = useState("");
  const [theArray, setTheArray] = useState([]);

  let itemNames = props.customticketfield_data.hits
    ? props.customticketfield_data.hits.filter((eachobj) => {
        return (
          eachobj._source.project.project_id ===
          props.user_last_working_project_id
        );
      })
    : "";

  let parentFieldList = [];
  const inputRef1 = useRef("");

  useEffect(() => {
    if (props.open && props.edit) {
      const InitialArray = [];
      if (props.projectEditData.field_values) {
        props.projectEditData.field_values.map((item) => {
          return InitialArray.push(item.value);
        });
      }
      setTheArray(InitialArray);
    }
  }, [props.open]);

  itemNames &&
    itemNames.map((item) => {
      parentFieldList.push({
        parent_field_id: item._id,
        parent_field_name: item._source.field_name,
      });
    });

  const handleChangeFor = (event) => {
    if (
      event.target.value.length > 1 ||
      event.target.value !== null ||
      event.target.value.trim()
    ) {
      setFieldtypeValues(event.target.value);
    }
  };

  const addEntryClick = () => {
    if (fieldtypeValues > 1 || fieldtypeValues.trim()) {
      setTheArray([...theArray, fieldtypeValues]);
    }

    setFieldtypeValues("");
  };

  function ReturnIDforNode(array) {
    let maxId = array.reduce((max, obj) => {
      let id = obj.id.split("-")[obj.id.split("-").length - 1];

      return Number(id) > max ? Number(id) : max;
    }, 0);

    const nextId = (maxId = maxId + 1);
    return `${nextId}`;
  }

  let obj = [];

  theArray.forEach((item, index) => {
    return obj.push({
      id: index,
      value: item,
    });
  });

  // *************Custom ticket fields*****************

  //Projectname list Array Creation
  //projectArray Creation

  // custom field type
  const fieldType = [
    "text",
    "select",
    "radio",
    "toggle",
    "date",
    "ticket category",
  ]; //["text", "select", "radio", "toggle", "date"]

  // Parent Field name
  // const parentFieldName = ["null"];

  //   "parent field name",
  //   custom_ticket_field.hits
  //     ? custom_ticket_field.hits.forEach((value) => {
  //         // if (value._source.parent_field_id === value._id) {
  //         parentFieldName.push({
  //           _id: value._id,
  //           // parent_field_id: value._source.parent_field_id,
  //           field_name: value._source.field_name,
  //         });
  //         // }
  //       })
  //     : null

  // Setting Initial Values
  let initial = {};
  if (props.edit) {
    initial = {
      field_name: props.projectEditData.custom_ticket_field_name,
      field_alias: props.projectEditData.field_alias,
      field_type: props.projectEditData.field_type,
      field_values: props.projectEditData.field_values,
      tree_value: props.projectEditData.field_values,
    };
  } else {
    // field_values array of object

    initial = {
      field_name: "",
      field_alias: "",
      field_type: "",
      has_parent_field: false,
      parent_field: "",
      field_values: obj,
      tree_value: [{ id: "1", value: "", sub_value: [] }],
    };
  }

  // Parent Field for the creating field
  // const [checked, setChecked] = useState(true);

  // const handleCheckboxChange = (e) => {
  //   setChecked(e.target.checked);
  // };
  // let errors;
  // Returning A DialogBox
  const onCloseFunction = () => {
    props.onClose();
    setBooleanVal(false);
  };

  const [loading, setLoading] = useState(false);

  const renderTree = (nodes, index, setFieldValue, values) => {
    function findIndexesById(nodes, id, indexes = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        indexes.push(i);
        if (node.id === id) {
          return indexes;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = findIndexesById(node.sub_value, id, [
            ...indexes,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        indexes.pop();
      }
      return null;
    }

    return (
      <div
      // className="bg-info"
      >
        <StyledTreeItem
          key={nodes.id}
          nodeId={nodes.id}
          label={
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent="flex-start"
              xl={12}
              lg={12}
              md={12}
              width={"100%"}
              // className="bg-success"
            >
              <Grid item xl={9} lg={9} md={9} sm={9}>
                <Field
                  as={TextArea}
                  variant="outlined"
                  placeholder={`Enter Name`}
                  className={`${"textAreaFiled col-10"}`}
                  size="small"
                  name={`tree_value${findIndexesById(
                    values.tree_value,
                    nodes.id
                  )
                    .map((i, ind) => {
                      return ind !==
                        findIndexesById(values.tree_value, nodes.id).length - 1
                        ? `.${i}.sub_value`
                        : `.${i}.value`;
                    })
                    .join("")}`}
                  // width={500}
                  // minWidth="150px"
                  validate={(value) => {
                    if (!value) {
                      return "Required";
                    }
                  }}
                />
              </Grid>

              <Grid item>
                <ErrorMessage
                  name={`tree_value${findIndexesById(
                    values.tree_value,
                    nodes.id
                  )
                    .map((i, ind) => {
                      return ind !==
                        findIndexesById(values.tree_value, nodes.id).length - 1
                        ? `.${i}.sub_value`
                        : `.${i}.value`;
                    })
                    .join("")}`}
                  component="div"
                  style={{
                    color: `${theme.palette.buttonComponent.background.error}`,
                    marginTop: 9,
                  }}
                />
              </Grid>
            </Grid>
          }
          collapseIcon={<MinusSquare />}
          expandIcon={<PlusSquare />}
          endIcon={<CloseSquare />}
        >
          <FieldArray
            name={`tree_value${findIndexesById(values.tree_value, nodes.id)
              .map((i) => `.${i}.sub_value`)
              .join("")}`}
          >
            {({ insert, remove, push }) => (
              <Grid container item>
                {Array.isArray(nodes.sub_value) && (
                  <>
                    {nodes.sub_value.length > 0 &&
                      nodes.sub_value.map((node, index) => {
                        return (
                          <Grid sx={{ display: "flex", width: "100%" }}>
                            <Grid sm={1} sx={{ marginRight: 1 }}>
                              <IconButton
                                onClick={() => {
                                  remove(
                                    findIndexesById(nodes.sub_value, node.id)[0]
                                  );
                                }}
                              >
                                <DisabledByDefaultOutlinedIcon />
                              </IconButton>
                            </Grid>
                            <Grid sm={11}>
                              {renderTree(node, index, setFieldValue, values)}
                            </Grid>
                          </Grid>
                        );
                      })}
                    <IconButton
                      onClick={() => {
                        push({
                          id: `${nodes.id}-${ReturnIDforNode(nodes.sub_value)}`,
                          value: "",
                          sub_value: [],
                        });
                      }}
                    >
                      <PlusSquare />
                    </IconButton>
                  </>
                )}
              </Grid>
            )}
          </FieldArray>
        </StyledTreeItem>
      </div>
    );
  };

  return (
    <Dialog open={props.open} onClose={onCloseFunction} fullWidth maxWidth="md">
      {/* Scope of Formik starts here */}
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.field_name) {
            errors.field_name = "Field Name is Required";
          }

          if (!values.field_alias) {
            errors.field_alias = "Field Alias is Required";
          }

          if (
            (values.field_type === "select" || values.field_type === "radio") &&
            !obj.length
          ) {
            errors.field_values = "Field values Required";
          }

          if (!props.edit) {
            if (!values.field_type) {
              errors.field_type = "Field Type is Required";
            }
          }

          if (!props.edit) {
            if (values.has_parent_field === true) {
              if (!values.parent_field) {
                errors.parent_field = "Parent Field id Required";
              }
            }
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          if (props.edit) {
            let objValues = {};

            Object.assign(
              objValues,
              { field_name: values.field_name },
              { field_alias: values.field_alias },
              values.field_type &&
                values.field_type !== "ticket category" &&
                obj.length > 0 && { field_values: obj },
              values.field_type &&
                values.field_type === "ticket category" && {
                  field_values: values.tree_value,
                }
            );

            const callBackFunction = () => {
              const params = {
                order_by: `[["order_number","asc"]]`,
                page_limit: 15,
              };
              const param = {
                order_by: `[["order_number","asc"]]`,
                page_limit: "none",
              };
              props.dispatch(
                CustomTicketFieldsAction.requestCustomTicketFields(params)
              );
              props.dispatch(
                CustomTicketFieldsAction.requestCustomTicketFieldsFilter(param)
              );
              props.onClose();
              setLoading(false);
            };

            props.dispatch(
              CustomTicketFieldsAction.requestPutCustomTicketFields(
                JSON.stringify(objValues),
                props.projectEditData.id,
                callBackFunction
              )
            );
          } else {
            // Post data
            let objValues = {};
            Object.assign(
              objValues,
              values.field_name && { field_name: values.field_name },
              values.field_alias && { field_alias: values.field_alias },
              values.field_type &&
                values.field_type === "ticket category" && {
                  field_type: "category",
                },
              values.field_type &&
                values.field_type !== "ticket category" && {
                  field_type: values.field_type,
                },
              values.field_type &&
                values.field_type &&
                values.field_type === "ticket category" && {
                  field_values: values.tree_value,
                },
              obj.length > 0 && { field_values: obj },
              {
                has_parent_field: values.has_parent_field,
              },
              values.parent_field && {
                parent_field: values.parent_field,
              }
            );

            props
              .dispatch(
                CustomTicketFieldsAction.requestPostCustomTicketFields(
                  objValues
                )
              )
              .then(() => {
                const params = {
                  order_by: `[["order_number","asc"]]`,
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["order_number","asc"]]`,
                  page_limit: "none",
                };
                props.dispatch(
                  CustomTicketFieldsAction.requestCustomTicketFields(params)
                );
                props.dispatch(
                  CustomTicketFieldsAction.requestCustomTicketFieldsFilter(
                    param
                  )
                );
                props.onClose();
                setLoading(false);
                setBooleanVal(false);
              });
          }

          setTheArray([]);
          // formikHelpers.resetForm();
        }}
        validateOnBlur={true}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          // Form Tag from Formik
          <Form>
            {/* Content of the dialog Box  */}
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* text Content inside DialogBox  */}
              <DialogContentText sx={{ width: "100%" }}>
                <Box sx={{ flexGrow: 1, width: "100%" }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingBottom: "20px", paddingTop: "10px" }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={10}>
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">C</span>ustom
                            &nbsp;
                            <span className="span_first_letter">T</span>
                            icket&nbsp;
                            <span className="span_first_letter">F</span>ield
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">C</span>ustom
                            &nbsp;
                            <span className="span_first_letter">T</span>
                            icket&nbsp;
                            <span className="span_first_letter">F</span>ield
                          </b>
                        )}
                      </h3>
                    </Grid>
                    <Grid item xs={2}>
                      <div
                        className="col-2 closebtn"
                        onClick={() => {
                          props.onClose();
                          setTheArray([]);
                          setBooleanVal(false);
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>
              {/*****************************Custom Ticket Fields****************************/}
              <Box sx={{ flexGrow: 1, width: "80%" }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ paddingBottom: "10px" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    Ticket Field Name * :
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      // text feild from material Ui
                      as={MuiTextField}
                      autoFocus
                      width="240px"
                      size="small"
                      fullWidth
                      variant="outlined"
                      id="field_name"
                      name="field_name"
                      placeholder="Ticket Field Name"
                      // value={formik.values.tag_name}
                      error={
                        Boolean(errors.field_name) &&
                        Boolean(touched.field_name)
                      }
                      helperText={
                        Boolean(touched.field_name) && errors.field_name
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ paddingBottom: "10px" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    Ticket Field Alias * :
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      // text feild from material Ui
                      as={MuiTextField}
                      width="240px"
                      variant="outlined"
                      size="small"
                      id="field_alias"
                      name="field_alias"
                      placeholder="Ticket Field Alias"
                      // value={formik.values.tag_name}
                      error={
                        Boolean(errors.field_alias) &&
                        Boolean(touched.field_alias)
                      }
                      helperText={
                        Boolean(touched.field_alias) && errors.field_alias
                      }
                    />
                  </Grid>
                </Grid>

                {!props.edit && (
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={4}>
                      Ticket Field Type * :
                    </Grid>
                    <Grid item xs={8}>
                      <Field
                        name="field_type"
                        disabled={props.edit === true ? true : false}
                        // Calling AutoCompleteMultiSelect Component
                        component={AutoCompleteMultiSelect}
                        variant="outlined"
                        id="field_type"
                        // size="large"
                        textFieldProps={{
                          placeholder: "Ticket Field Type",
                        }}
                        sx={{ width: "240px" }}
                        options={fieldType}
                        getOptionLabel={(option) => option}
                        size="small"
                        value={values.field_type}
                        error={
                          Boolean(errors.field_type) &&
                          Boolean(touched.field_type)
                        }
                        helperText={
                          Boolean(touched.field_type) &&
                          Boolean(errors.field_type)
                        }
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Dynamic fields */}
                <Grid container>
                  {values.field_type === "select" ||
                  values.field_type === "radio" ? (
                    <React.Fragment>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        mt={1}
                      >
                        <Grid item xs={4.3}>
                          Custom Field values * :
                        </Grid>
                        <Grid
                          item
                          mr={5.5}
                          xs={6.5}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {theArray.map((entry) => (
                            <Typography>{entry},</Typography>
                          ))}
                        </Grid>
                        {props.edit ? (
                          <Grid item xs={0.5} lg={0.5} md={0.5}>
                            <IconButton
                              onClick={() => {
                                setTheArray([]);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        // mt={1}
                      >
                        <Grid item xs={4}>
                          Add Values * :
                        </Grid>
                        <Grid item xs={8} display="flex" flexDirection="row">
                          <Field
                            // text feild from material Ui
                            // disabled={props.edit === true ? true : false}
                            as={MuiTextField}
                            width="200px"
                            variant="outlined"
                            id="field_values"
                            name="field_values"
                            placeholder="Custom field values"
                            onChange={handleChangeFor}
                            size="small"
                            value={fieldtypeValues}
                            error={
                              Boolean(errors.field_values) &&
                              Boolean(touched.field_values)
                            }
                            helperText={
                              Boolean(touched.field_values) &&
                              errors.field_values
                            }
                          />

                          <MuiButton
                            // disabled={props.edit === true ? true : false}
                            size="small"
                            name={"Add"}
                            onClick={addEntryClick}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ) : values.field_type === "ticket category" ? (
                    <Box width="100%">
                      <FieldArray name="tree_value">
                        {({ insert, remove, push }) => (
                          <Grid container width="100%">
                            {values.tree_value.length > 0 &&
                              values.tree_value.map((item, index) => (
                                <Grid
                                  container
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <Grid item sm={1}>
                                    <ListItem
                                      sx={{ cursor: "pointer" }}
                                      title="Delete"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <DisabledByDefaultOutlinedIcon />
                                    </ListItem>
                                  </Grid>
                                  <Grid item sm={11}>
                                    <TreeView
                                      aria-label="customized"
                                      defaultExpanded={["1"]}
                                      defaultCollapseIcon={<MinusSquare />}
                                      defaultExpandIcon={<PlusSquare />}
                                      defaultEndIcon={<CloseSquare />}
                                      sx={{
                                        height: "auto",
                                        maxHeight: 500,
                                        flexGrow: 1,
                                        overflow: "auto",
                                      }}
                                    >
                                      {renderTree(
                                        item,
                                        index,
                                        setFieldValue,
                                        values
                                      )}
                                    </TreeView>
                                  </Grid>
                                </Grid>
                              ))}
                            <ListItem
                              sx={{ cursor: "pointer" }}
                              title="Add"
                              onClick={() => {
                                push({
                                  id: ReturnIDforNode(values.tree_value),
                                  value: "",
                                  sub_value: [],
                                });
                              }}
                            >
                              <AddBoxOutlinedIcon />
                            </ListItem>
                          </Grid>
                        )}
                      </FieldArray>
                    </Box>
                  ) : null}
                </Grid>

                {!props.edit && (
                  <Grid item xl={12} textAlign={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.has_parent_field}
                          onClick={(event) => {
                            if (event.target.checked) {
                              setBooleanVal(true);
                            } else {
                              setBooleanVal(false);
                            }
                          }}
                          onChange={handleChange}
                        />
                      }
                      label="Has Parent Field"
                      name="has_parent_field"
                      value={values.has_parent_field}
                    />
                  </Grid>
                )}

                {booleanVal && (
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingBottom: "10px" }}
                    justifyContent="center"
                    alignItems="center"
                    mt={0.5}
                  >
                    <Grid item xs={4}>
                      Select Parent Field * :
                    </Grid>
                    <Grid item xs={8}>
                      <Field
                        name="parent_field"
                        // disabled={props.edit === true ? true : false}
                        // Calling AutoCompleteMultiSelect Component
                        component={AutoCompleteMultiSelect}
                        value={values.parent_field ? values.parent_field : null}
                        variant="outlined"
                        id="parent_field"
                        options={parentFieldList}
                        // size="large"
                        textFieldProps={{
                          placeholder: "Select Parent Field",
                        }}
                        inputRef={inputRef1}
                        sx={{ width: "240px" }}
                        getOptionLabel={(option) => option.parent_field_name}
                        size="small"
                        error={Boolean(
                          getIn(touched, "parent_field") &&
                            getIn(errors, "parent_field")
                        )}
                        helperText={Boolean(
                          getIn(touched, "parent_field") &&
                            getIn(errors, "parent_field")
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            </DialogContent>
            {/* Actions performed from form action buttons  */}
            <DialogActions className="m-1 my-4">
              {/* Calling ButtonComponent to perform form actions  */}
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Custom Field"}
                      type="submit"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      name={"Submit"}
                      type="submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      width="100%"
                      onClick={() => {
                        setTheArray([]);
                      }}
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        setTheArray([]);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(CustomTicketPost);
