import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { theme } from "../../../views/App";

const SelectReportDialog = (props) => {
  const [selectedReport, setSelectedReport] = useState(null);

  return (
    <Grid container>
      <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
        <DialogContent>
          <DialogContentText>
            <Grid
              item
              xs={8}
              display="flex"
              justifyContent={"space-around"}
              fullWidth
            >
              <Typography component={"h3"}>
                <b>
                  <span className="span_first_letter">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S
                  </span>
                  elect &nbsp;
                  <span className="span_first_letter">R</span>eport{" "}
                </b>
              </Typography>
              <div className="col-2 closebtn">
                <i
                  title="Close"
                  className="fa fa-times-circle-o fa-2x"
                  style={{ size: "15px" }}
                  aria-hidden="true"
                  onClick={() => {
                    props.onClose();
                  }}
                ></i>
              </div>
            </Grid>
            <Grid
              container
              sx={{
                height: "auto",
                maxHeight: 600,
                overflowY: "scroll",
                p: 3,
              }}
            >
              {Object.entries(props.reportsList).map(
                ([reportBased, reports]) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: theme.palette.background.default,
                        width: "100%",
                        height: "fit-content",
                        borderRadius: theme.borderRadius,
                        border: "solid 0.5px #d3e2e6",
                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                        marginBottom: 10,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{reportBased}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {reports.length &&
                          reports.map((report) => (
                            <Grid xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={JSON.stringify(report)}
                                    label={report.report_name}
                                    onChange={(e) => {
                                      setSelectedReport(e.target.value);

                                      const newReportsPage = props.pages.map(
                                        (val) => {
                                          let newReports = [...val.reports];
                                          newReports[props.index] = {
                                            report_name: e.target.value,
                                            filter:
                                              e.target.value ===
                                                "Monthly Wise Platform Review Counts" ||
                                              e.target.value ===
                                                "Resolution Time Split Wise" ||
                                              e.target.value ===
                                                "Hourly Platformwise Tickets Resolved"
                                                ? new Date()
                                                : "",
                                            description: [],
                                          };
                                          return val.page_number ===
                                            props.page.page_number
                                            ? {
                                                ...val,
                                                reports: newReports,
                                              }
                                            : val;
                                        }
                                      );

                                      props.setReportPages(newReportsPage);
                                      props.onClose();
                                    }}
                                    checked={
                                      props?.page?.reports[props.index]
                                        .report_name &&
                                      JSON.parse(
                                        props.page.reports[props.index]
                                          .report_name
                                      ).report_id === report?.report_id
                                    }
                                  />
                                }
                                label={report.report_name}
                              />
                            </Grid>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </Grid>
  );
};

export default SelectReportDialog;
