import { BaseModel } from "sjs-base-model";

export default class FacebookReplyPostModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  data = {};
  status = "";
  id = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
