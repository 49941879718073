import ActionUtility from "../../../utilities/ActionUtility";
import UserMailMappingEffect from "./UserMailMappingEffect";

export default class UserMailMappingAction {
  // UserMailMappingAction action with an function declaration
  static REQUEST_USERMAIL_MAPPING =
    "UserMailMappingAction.REQUEST_USERMAIL_MAPPING";
  static REQUEST_USERMAIL_MAPPING_FINISHED =
    "UserMailMappingAction.REQUEST_USERMAIL_MAPPING_FINISHED";

  static REQUEST_USERMAIL_MAPPING_FILTER =
    "UserMailMappingAction.REQUEST_USERMAIL_MAPPING_FILTER";
  static REQUEST_USERMAIL_MAPPING_FILTER_FINISHED =
    "UserMailMappingAction.REQUEST_USERMAIL_MAPPING_FILTER_FINISHED";

  static REQUEST_PUT_USERMAIL_MAPPING =
    "UserMailMappingAction.REQUEST_PUT_USERMAIL_MAPPING";
  static REQUEST_PUT_USERMAIL_MAPPING_FINISHED =
    "UserMailMappingAction.REQUEST_PUT_USERMAIL_MAPPING_FINISHED";

  static REQUEST_POST_USERMAIL_MAPPING =
    "UserMailMappingAction.REQUEST_POST_USERMAIL_MAPPING";
  static REQUEST_POST_USERMAIL_MAPPING_FINISHED =
    "UserMailMappingAction.REQUEST_POST_USERMAIL_MAPPING_FINISHED";

  // METHODS
  // usermail mapping GET function
  static requestUserMailMapping(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserMailMappingAction.REQUEST_USERMAIL_MAPPING,
        UserMailMappingEffect.requestUserMailMapping,
        params
      );
    };
  }

  static requestUserMailMappingFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserMailMappingAction.REQUEST_USERMAIL_MAPPING_FILTER,
        UserMailMappingEffect.requestUserMailMappingFilter,
        params
      );
    };
  }
  static requestPutUserMailMapping(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserMailMappingAction.REQUEST_POST_USERMAIL_MAPPING,
        UserMailMappingEffect.requestPutUserMailMapping,
        data,
        id
      );
    };
  }

  // triggerlistfunction
  static requestPostUserMailMapping(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserMailMappingAction.REQUEST_PUT_USERMAIL_MAPPING,
        UserMailMappingEffect.requestPostUserMailMapping,
        data
      );
    };
  }
}
