import ActionUtility from "../../../utilities/ActionUtility";
import BrandCompitatorEffect from "./BrandCompitatorEffect";

export default class BrandCompitatorAction {
  // BRAND_COMPITATOR action with an function declaration
  static REQUEST_BRAND_COMPITATOR =
    "BrandCompitatorAction.REQUEST_BRAND_COMPITATOR";
  static REQUEST_BRAND_COMPITATOR_FINISHED =
    "BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FINISHED";

  // BRAND_COMPITATOR action with an function declaration
  static REQUEST_BRAND_COMPITATOR_FILTER =
    "BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FILTER";
  static REQUEST_BRAND_COMPITATOR_FILTER_FINISHED =
    "BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FILTER_FINISHED";

  //   BRAND_COMPITATOR post
  static REQUEST_POST_BRAND_COMPITATOR =
    "BrandCompitatorAction.REQUEST_POST_BRAND_COMPITATOR";
  static REQUEST_POST_BRAND_COMPITATOR_FINISHED =
    "BrandCompitatorAction.REQUEST_POST_BRAND_COMPITATOR_FINISHED";

  // BRAND_COMPITATOR put
  static REQUEST_PUT_BRAND_COMPITATOR =
    "ProjectAction.REQUEST_PUT_BRAND_COMPITATOR";
  static REQUEST_PUT_BRAND_COMPITATOR_FINISHED =
    "BrandCompitatorAction.REQUEST_PUT_BRAND_COMPITATOR_FINISHED";

  // METHODS
  // BRAND_COMPITATOR GET function
  static requestBrandCompitator(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BrandCompitatorAction.REQUEST_BRAND_COMPITATOR,
        BrandCompitatorEffect.requestBrandCompitator,
        params
      );
    };
  }

  // BRAND_COMPITATOR filter
  static requestBrandCompitatorFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FILTER,
        BrandCompitatorEffect.requestBrandCompitatorFilter,
        params
      );
    };
  }

  //   static requestProjectFinished() {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         ProjectAction.REQUEST_BRAND_COMPITATOR_FINISHED,
  //         ProjectEffect.requestProjectFinished
  //       );
  //     };
  //   }

  // BRAND_COMPITATOR post function
  static requestPostBrandCompitator(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BrandCompitatorAction.REQUEST_POST_BRAND_COMPITATOR,
        BrandCompitatorEffect.requestPostBrandCompitator,
        data,
        callBackFunction
      );
    };
  }

  // BRAND_COMPITATOR put function
  static requestPutBrandCompitator(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BrandCompitatorAction.REQUEST_PUT_BRAND_COMPITATOR,
        BrandCompitatorEffect.requestPutBrandCompitator,
        data,
        id
      );
    };
  }
}
