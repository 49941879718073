import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import AssignmentRuleGetModel from "./models/AssignementRuleGetModel";
import AssignmentRulePostModel from "./models/AssignmentRulePostModel";
import AssignmentRulePutModel from "./models/AssignmentRulePutModel";
import AssignmentRulePutBulkUpdate from "./models/AssignmentRulePutBulkUpdate";

export default class AssignmentRuleEffect {
  static async requestAssignmentRule(params) {
    const endpoint = environment.api.assignmentRule;
    return EffectUtility.getToModel(AssignmentRuleGetModel, endpoint, params);
  }

  static async requestAssignmentRuleFilter(params) {
    const endpoint = environment.api.assignmentRuleFilter;
    return EffectUtility.getToModel(AssignmentRuleGetModel, endpoint, params);
  }

  static async requestPostAssignmentRule(data) {
    const endpoint = environment.api.assignmentRule;
    return EffectUtility.postToModel(AssignmentRulePostModel, endpoint, data);
  }

  static async requestPutAssignmentRule(data, id) {
    const endpoint = environment.api.assignmentRule + "/" + id;
    return EffectUtility.putToModel(AssignmentRulePutModel, endpoint, data);
  }

  // Put method for the Tag bulk update
  static async requestPutAssignmentRuleBulkUpdate(data) {
    const endpoint = environment.api.assignmentRule;
    return EffectUtility.putToModel(
      AssignmentRulePutBulkUpdate,
      endpoint,
      data
    );
  }

  //   static async requestDeleteKeywordConfig(id) {
  //     const endpoint = environment.api.keywordConfiguration + "/" + id;

  //     return EffectUtility.deleteToModel(KeywordConfigDeleteModel, endpoint);
  //   }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
