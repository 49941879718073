import BaseReducer from "../../../../utilities/BaseReducer";
import A3NotificationAction from "./A3NotificationAction";

export default class A3NotificationFilterReducer extends BaseReducer {
  //initialState of notifications filter
  initialState = {
    A3NotificationsFilter: [],
  };

  //notifications Filter
  [A3NotificationAction.REQUEST_FILTER_NOTIFICATIONS_FINISHED](state, action) {
    return {
      ...state,
      A3NotificationsFilter: action.payload,
    };
  }
}
