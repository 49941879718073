import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import LinkedInFeedsModel from "./models/LinkedFeedModel";
import LinkedInPostModel from "./models/LinkedInPostModel";
import LinkedInPutModel from "./models/LinkedInPutModel";

// LinkedInFeedEffect
export default class LinkedInFeedEffect {
  static async requestLinkedInFeed(params, callBackFunction) {
    const endpoint = environment.api.LinkedFeed;

    let response = EffectUtility.getToModel(
      LinkedInFeedsModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestLinkedInFeedClear() {
    return {};
  }

  // Get method for the LinkedIn filter
  static async requestLinkedInFeedFilter(params) {
    const endpoint = environment.api.LinkedFeed;
    return EffectUtility.getToModel(LinkedInFeedsModel, endpoint, params);
  }

  // put method for the Twitter Feeds
  static async requestPutLinkedInFeeds(data, id) {
    const endpoint = environment.api.LinkedFeed + "/" + id;
    return EffectUtility.putToModel(LinkedInPutModel, endpoint, data);
  }

  //  Comment reply Post API
  static async requestLinkedInReplyPost(id, data, callback) {
    const endpoint = environment.api.LinkedInReply + "/" + id;

    let response = EffectUtility.postToModel(LinkedInPostModel, endpoint, data);

    response.then((resp) => {
      if (resp) {
        resp.status === "Success" && callback && callback();
      }
    });

    return response;
  }

  //  Post mention reply API
  static async requestLinkedInPostMentionReply(id, data, callback) {
    const endpoint = environment.api.LinkedInPostMentionReply + "/" + id;

    let response = EffectUtility.postToModel(LinkedInPostModel, endpoint, data);

    response.then((resp) => {
      if (resp) {
        resp.status === "Success" && callback && callback(resp);
      }
    });

    return response;
  }
}
