import BaseReducer from "../../../../utilities/BaseReducer";
import BlogsAction from "./BlogsAction";

// GetConfiguredEmailReduser
export default class GetConfiguredEmailReduser extends BaseReducer {
  initialState = {
    get_configured_email_address: [],
  };
  [BlogsAction.REQUEST_CONFIGURED_EMAIL_ADDRESS_FINISHED](state, action) {
    return {
      ...state,
      get_configured_email_address: action.payload,
    };
  }
}
