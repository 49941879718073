import React, { useState } from "react";
import SelectProject from "../../components/selectProjectDialog/SelectProject";

function OpenFormProject() {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <SelectProject open={open} onSubmit={() => setOpen(true)} />
    </div>
  );
}

export default OpenFormProject;
