import ActionUtility from "../../../utilities/ActionUtility";
import GlassdoorFeedsEffect from "./GlassdoorFeedsEffect";

export default class GlassdoorFeedsAction {
  // GlassdoorFeedsAction  with an function declaration
  static REQUEST_GLASSDOOR_FEED = "GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED";
  static REQUEST_GLASSDOOR_FEED_FINISHED =
    "GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_FINISHED";

  static REQUEST_PUT_GLASSDOOR_FEED =
    "GlassdoorFeedsAction.REQUEST_PUT_GLASSDOOR_FEED";
  static REQUEST_PUT_GLASSDOOR_FEED_FINISHED =
    "GlassdoorFeedsAction.REQUEST_PUT_GLASSDOOR_FEED_FINISHED";

  static REQUEST_GLASSDOOR_FEED_FILTER =
    "GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_FILTER";
  static REQUEST_GLASSDOOR_FEED_FILTER_FINISHED =
    "GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_FILTER_FINISHED";

  static REQUEST_GLASSDOOR_FEED_CLEAR =
    "GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_CLEAR";
  static REQUEST_GLASSDOOR_FEED_CLEAR_FINISHED =
    "GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_CLEAR_FINISHED";

  // METHODS
  // Glassdoor Feeds GET function
  static requestGlassdoorFeed(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED,
        GlassdoorFeedsEffect.requestGlassdoorFeed,
        params
      );
    };
  }

  static requestPutGlassdoorFeed(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorFeedsAction.REQUEST_PUT_GLASSDOOR_FEED,
        GlassdoorFeedsEffect.requestPutGlassdoorFeed,
        data,
        id
      );
    };
  }

  static requestGlassdoorFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_CLEAR,
        GlassdoorFeedsEffect.requestGlassdoorFeedClear
      );
    };
  }

  static requestGlassdoorFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_FILTER,
        GlassdoorFeedsEffect.requestGlassdoorFeedFilter,
        params
      );
    };
  }
}
