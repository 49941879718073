import BaseReducer from "../../../utilities/BaseReducer";
import QuoraAccountAction from "./QuoraAccountAction";

export default class QuoraAccountReducer extends BaseReducer {
  initialState = {
    quoraAccount: [],
  };
  [QuoraAccountAction.REQUEST_QUORA_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      quoraAccount: action.payload,
    };
  }
}
