import { BaseModel } from "sjs-base-model";
import McnFeedsHitsModel from "./McnFeedsHitsModel";
import McnFeedsShardsModel from "./McnFeedsShardsModel";

// McnFeedsModel
export default class McnFeedsModel extends BaseModel {

  // response getting from the api mentioned end point
  _shards = { _shards: McnFeedsShardsModel };
  current_page_no = 0;
  hits = { hits: McnFeedsHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
