import BaseReducer from "../../../utilities/BaseReducer";
import LinkedInFeedAction from "./LinkedInFeedAction";

// GmbReviewFeedsReduser  function
export default class LinkedInFeedsFilterReduser extends BaseReducer {
  initialState = {
    linkedInFeedFilter: [],
  };
  [LinkedInFeedAction.REQUEST_LINKEDIN_FEED_FILTER_FINISHED](state, action) {
    return {
      ...state,
      linkedInFeedFilter: action.payload,
    };
  }
}
