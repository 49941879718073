import BaseReducer from "../../../utilities/BaseReducer";
import AllMentionsAction from "./AllMentionsAction";

export default class AllMentionsExportFilterReduser extends BaseReducer {
  //initial state of AllMentionsFilterReduser
  initialState = {
    allmentionsExportFilter: [],
  };
  //AllMentionsExportFilterReduser request action finish
  [AllMentionsAction.REQUEST_ALLMENTIONS_EXPORT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      allmentionsExportFilter: action.payload,
    };
  }
}
