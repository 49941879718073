import React, { useState } from "react";
import {
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { DialogBox } from "../../../components/componentLayout/ComponentLayout";
import TicketTypeAction from "../../../stores/ticketType/TicketTypeAction";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../components/muiButton/MuiButton";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

//validation schema
const validationSchema = yup.object({
  ticket_type_name: yup
    .string("Enter your ticket type name")
    .trim()
    .min(1, "Ticket type name should be of minimum 1 characters length")
    .required("Ticket Type is required"),
});

const TicketTypePost = (props) => {
  // using custom theme from theme provider
  const theme = useTheme();

  const [page_limit] = useState(15);

  // Ticket Type post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      ticket_type_name: props.ticketTypeEditData.ticket_type_name,
    };
  } else {
    initial = {
      ticket_type_name: "",
    };
  }

  const [loading, setLoading] = useState(false);

  //Formik
  const formik = useFormik({
    initialValues: initial,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // parameter for showing list based on the pagination
      setLoading(true);
      const params = {
        order_by: `[["order_number","asc"]]`,
        page_limit: 15,
      };
      const param = {
        order_by: `[["ticket_type_name.keyword", "asc"]]`,
        page_limit: "none",
      };

      if (props.edit) {
        props
          .dispatch(
            TicketTypeAction.requestPutTicketType(
              JSON.stringify(values),
              props.ticketTypeEditData.id
            )
          )
          .then(() => {
            props.dispatch(TicketTypeAction.requestTicketType(params));
            props.dispatch(TicketTypeAction.requestFilterTicketType(param));
            setLoading(false);
            props.onClose();
          });
      } else {
        props
          .dispatch(TicketTypeAction.requestPostTicketType(values))
          .then(() => {
            const params = {
              order_by: `[["order_number","asc"]]`,
              page_limit: 15,
            };
            const param = {
              order_by: `[["ticket_type_name.keyword", "asc"]]`,
              page_limit: "none",
            };

            props.dispatch(TicketTypeAction.requestTicketType(params));
            props.dispatch(TicketTypeAction.requestFilterTicketType(param));
            setLoading(false);
            props.onClose();
          });
      }

      formik.resetForm();
    },
  });

  const handleClose = () => {
    props.onClose();
    formik.resetForm();
  };

  return (
    <DialogBox>
      <Dialog
        open={props.open}
        onClose={() => {
          handleClose();
        }}
        maxWidth="sm"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DialogContentText sx={{ marginLeft: "15%", width: "80%" }}>
              <div className="text-center pb-2">
                <div className="row p-3">
                  <div className="col-10">
                    <h3 className="dailogtext">
                      {!props.edit ? (
                        <b>
                          <span className="span_first_letter">A</span>
                          dd &nbsp;
                          <span className="span_first_letter">T</span>icket
                          &nbsp;<span className="span_first_letter">T</span>
                          ype &nbsp;
                        </b>
                      ) : (
                        <b>
                          <span className="span_first_letter">E</span>
                          dit &nbsp;
                          <span className="span_first_letter">T</span>icket
                          &nbsp; <span className="span_first_letter">T</span>
                          ype &nbsp;
                        </b>
                      )}
                    </h3>
                  </div>
                  <div
                    className="col-2 closebtn"
                    onClick={() => {
                      props.onClose();
                      formik.resetForm();
                    }}
                  >
                    <i
                      className="fa fa-times-circle-o fa-2x"
                      title="Close"
                      style={{ size: "15px" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </DialogContentText>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} container>
                <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                  Ticket Type * :
                </Grid>
                <Grid item lg={5} md={5} sm={5}>
                  <MuiTextField
                    autoFocus
                    variant="outlined"
                    id="ticket_type_name"
                    size="small"
                    name="ticket_type_name"
                    placeholder="Ticket Type Name"
                    value={formik.values.ticket_type_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ticket_type_name &&
                      Boolean(formik.errors.ticket_type_name)
                    }
                    helperText={
                      formik.touched.ticket_type_name &&
                      formik.errors.ticket_type_name
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className="m-3 my-1">
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              display="flex"
              justifyContent="space-evenly"
            >
              <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                {!props.edit ? (
                  <MuiButton
                    name={"Add Ticket Type"}
                    type="submit"
                    loading={loading}
                    width="100%"
                  />
                ) : (
                  <MuiButton
                    loading={loading}
                    name={"Submit"}
                    type="submit"
                    width="100%"
                  />
                )}
              </Grid>

              <Grid item lg={5} md={5} sm={5} minWidth={200}>
                {!props.edit ? (
                  <MuiButton
                    name={"Reset"}
                    type="reset"
                    onClick={formik.resetForm}
                    width="100%"
                  />
                ) : (
                  <MuiButton
                    name={"Cancel"}
                    onClick={() => {
                      props.onClose();
                      formik.resetForm();
                    }}
                    width="100%"
                  />
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </DialogBox>
  );
};

export default connect(mapDispatchToProps)(TicketTypePost);
