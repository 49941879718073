import { BaseModel } from "sjs-base-model";

// CustomerPutModel
export default class CustomerPutModel extends BaseModel {
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
