import environment from "environment";
import A3ReportsLabelModel from "./model/A3ReportsLabelModel";
// import A3ReportsPutModel from "./model/A3ReportsPutModel";
// import A3ReportsPostModel from "./model/A3ReportsPostModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import A3ReportsArtistModel from "./model/A3ReportsArtistModel";
import A3ReportsReleaseModel from "./model/A3ReportsReleaseModel";
import A3ReportsStorewiseModel from "./model/A3ReportsStorewiseModel";
import A3ReportsMonthwiseModel from "./model/A3ReportsMonthwiseModel";
import A3ReportsCountrywiseModel from "./model/A3ReportsCountrywiseModel";
import A3LabelRevenueReportGetModel from "./model/A3LabelRevenueReportGetModel";
import TrackwiseRevenueGetModel from "./model/TrackwiseRevenueGetModel";
import ReleasewiseRevenueGetModel from "./model/ReleasewiseRevenueGetModel";
import ArtistwiseRevenueGetModel from "./model/ArtistwiseRevenueGetModel";
import ClientWiseRevenueModel from "./model/ClientWiseRevenueModel";

// A3ReportsEffect
export default class A3ReportsEffect {
  // calling an API according to the track model

  // Get method for the A3Reports Labels
  static async requestLabelReports(params, callBack) {
    const endpoint = environment.api.clientLabelReport;
    let response = EffectUtility.getToModel(
      A3ReportsLabelModel,
      endpoint,
      params
    );
    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  // Get method for the A3Reports Artist
  static async requestArtistReports(params) {
    const endpoint = environment.api.clientArtistReport;
    let response = EffectUtility.getToModel(
      A3ReportsArtistModel,
      endpoint,
      params
    );

    return response;
  }

  // Get method for the A3Reports Release
  static async requestReleaseReports(params) {
    const endpoint = environment.api.clientReleaseReport;
    return EffectUtility.getToModel(A3ReportsReleaseModel, endpoint, params);
  }

  static async requestStorewiseReports(params) {
    const endpoint = environment.api.storewiseRevenueReport;
    let response = EffectUtility.getToModel(
      A3ReportsStorewiseModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestMonthwiseReports(params) {
    const endpoint = environment.api.monthwiseRevenueReport;
    let response = EffectUtility.getToModel(
      A3ReportsMonthwiseModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestCountrywiseReports(params) {
    const endpoint = environment.api.countrywiseRevenueReport;
    let response = EffectUtility.getToModel(
      A3ReportsCountrywiseModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestLabelRevenueReport(params) {
    const endpoint = environment.api.labelRevenueReport;
    let response = EffectUtility.getToModel(
      A3LabelRevenueReportGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestClientwiseRevenue(params) {
    const endpoint = environment.api.clientwiserevnue;
    return EffectUtility.getToModel(ClientWiseRevenueModel, endpoint, params);
  }

  static async requestTrackwiseRevenue(params) {
    const endpoint = environment.api.trackwiseRevenue;
    return EffectUtility.getToModel(TrackwiseRevenueGetModel, endpoint, params);
  }

  static async requestReleasewiseRevenue(params) {
    const endpoint = environment.api.releasewiseRevenue;
    return EffectUtility.getToModel(
      ReleasewiseRevenueGetModel,
      endpoint,
      params
    );
  }

  static async requestArtistwiseRevenue(params) {
    const endpoint = environment.api.artistwiseRevenue;
    return EffectUtility.getToModel(
      ArtistwiseRevenueGetModel,
      endpoint,
      params
    );
  }
}
