import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ProxyCountriesGetModel from "./models/ProxyCountriesGetModel";
import ProxyPostModel from "./models/ProxyPostModel";
import CustomiseProxyGetModel from "./models/CustomiseProxyGetModel";
import ProxyAccountInfoGetModel from "./models/ProxyAccountInfoGetModel";
import ProxyBulkUpdateModel from "./models/ProxyBulkUpdateModel";
export default class ProxyEffect {
  // proxy countries
  static async requestProxyCountries(params) {
    const endpoint = environment.api.proxyCountries;
    return EffectUtility.getToModel(ProxyCountriesGetModel, endpoint, params);
  }

  static async requestPostProxyCountries(data) {
    const endpoint = environment.api.proxyCountries;
    return EffectUtility.postToModel(ProxyPostModel, endpoint, data);
  }

  static async requestCustomiseProxy(params) {
    const endpoint = environment.api.customiseProxy;
    return EffectUtility.getToModel(CustomiseProxyGetModel, endpoint, params);
  }

  static async requestPostCustomiseProxy(data) {
    const endpoint = environment.api.customiseProxy;
    return EffectUtility.postToModel(ProxyPostModel, endpoint, data);
  }

  static async requestProxyAccountInfo(params) {
    const endpoint = environment.api.proxyAccountInfo;
    return EffectUtility.getToModel(ProxyAccountInfoGetModel, endpoint, params);
  }

  static async requestProxyBulkUpdate(params) {
    const endpoint = environment.api.proxyBulkUpdate;
    return EffectUtility.postToModel(
      ProxyBulkUpdateModel,
      endpoint,
      null,
      params
    );
  }
}
