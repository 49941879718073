import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import WebsiteLinksModel from "./model/WebsiteLinksModel";
import WebsiteLinksPostModel from "./model/WebsiteLinksPostModel";
import WebsiteLinksPutModel from "./model/WebsiteLinksPutModel";

// WebsiteLinksEffect
export default class WebsiteLinksEffect {
  // calling an API according to the Links model
  // Get method for the
  static async requestWebsiteLinks(params) {
    const endpoint = environment.api.websiteLinks;
    return EffectUtility.getToModel(WebsiteLinksModel, endpoint, params);
  }

  // Get method for the Links filter
  static async requestWebsiteLinksFilter(params) {
    const endpoint = environment.api.websiteLinksFilter;
    return EffectUtility.getToModel(WebsiteLinksModel, endpoint, params);
  }

  // put method for the Links
  static async requestWebsistePutLinks(data, id) {
    const endpoint = environment.api.websiteLinks + "/" + id;
    return EffectUtility.putToModel(WebsiteLinksPutModel, endpoint, data);
  }

  // post method for the Links
  static async requestWebsitePostLinks(data) {
    const endpoint = environment.api.websiteLinks + "/add";

    return EffectUtility.postToModel(WebsiteLinksPostModel, endpoint, data);
  }
}
