import BaseReducer from "../../../utilities/BaseReducer";
import GoogleAccountAction from "./GoogleAccountAction";

// GoogleAccount reducer function
export default class GoogleAccountReduser extends BaseReducer {
  initialState = {
    googleAccount: [],
  };
  [GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      googleAccount: action.payload,
    };
  }
}
