import { BaseModel } from "sjs-base-model";
import TicketTypeSourceModel from "./TicketTypeSourceModel";

export default class TicketTypeHitsDataModel extends BaseModel {
  //ticketType hits data model
  _id = "";
  _index = "";
  _score = "";
  _source = { TicketTypeSourceModel };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
