import BaseReducer from "../../../../utilities/BaseReducer";
import AssetCollectionAction from "./AssetCollectionAction";

// AssetCollectionFilterReducer
export default class AssetCollectionFilterReducer extends BaseReducer {
  initialState = {
    collectionFilter: [],
  };
  [AssetCollectionAction.REQUEST_GET_COLLECTION_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      collectionFilter: action.payload,
    };
  }
}
