import ActionUtility from "../../../../utilities/ActionUtility";
import ActivityInsightEffect from "./ActivityInsightEffect";

export default class ActivityInsightAction {
  static REQUEST_ACTIVITY_INSIGHT = "ActivityInsight.REQUEST_ACTIVITY_INSIGHT";
  static REQUEST_ACTIVITY_INSIGHT_FINISHED =
    "ActivityInsight.REQUEST_ACTIVITY_INSIGHT_FINISHED";

  static REQUEST_ACTIVITY_INSIGHT_REPORT =
    "ActivityInsight.REQUEST_ACTIVITY_INSIGHT_REPORT";
  static REQUEST_ACTIVITY_INSIGHT_REPORT_FINISHED =
    "ActivityInsight.REQUEST_ACTIVITY_INSIGHT_REPORT_FINISHED";

  // METHODS
  static requestActivityInsight(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActivityInsightAction.REQUEST_ACTIVITY_INSIGHT,
        ActivityInsightEffect.requestActivityInsight,
        params
      );
    };
  }

  static requestActivityInsightReport(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActivityInsightAction.REQUEST_ACTIVITY_INSIGHT_REPORT,
        ActivityInsightEffect.requestActivityInsightReport,
        params
      );
    };
  }
}
