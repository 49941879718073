import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import SurveyDeleteModel from "./models/SurveyDeleteModel";
import SurveyModel from "./models/SurveyModel";
import SurveyPostDataModel from "./models/SurveyPostDataModel";
import SurveyPutModel from "./models/SurveyPutModel";

export default class SurveyEffect {
  // calling an API according to the SurveyModel
  static async requestSurvey(params) {
    const endpoint = environment.api.survey;
    return EffectUtility.getToModel(SurveyModel, endpoint, params);
  }

  // Project filter with page_limit none
  static async requestSurveyFilter(params) {
    const endpoint = environment.api.surveyFilter;
    return EffectUtility.getToModel(SurveyModel, endpoint, params);
  }

  // Post API
  static async requestPostSurvey(data) {
    const endpoint = environment.api.survey;

    return EffectUtility.postToModel(SurveyPostDataModel, endpoint, data);
  }

  // Put API
  static async requestPutSurvey(data, id) {
    const endpoint = environment.api.survey + "/" + id;

    return EffectUtility.putToModel(SurveyPutModel, endpoint, data);
  }

  // Delete API
  static async requestDeleteSurvey(id) {
    const endpoint = environment.api.survey + "/" + id;

    return EffectUtility.deleteToModel(SurveyDeleteModel, endpoint);
  }
}
