import BaseReducer from "../../../../utilities/BaseReducer";
import ResourceAction from "./ResourceAction";

// ResourceReduser
export default class ResourceReduser extends BaseReducer {
  initialState = {
    resource: [],
  };
  [ResourceAction.REQUEST_RESOURCE_FINISHED](state, action) {
    return {
      ...state,
      resource: action.payload,
    };
  }
}
