import { BaseModel } from "sjs-base-model";
import CustomFieldHitdataModel from "./CustomFieldHitdataModel";

// category data hits Model
export default class CustomTicketFieldsDataModel extends BaseModel {
  // response getting from the api

  hits = [CustomFieldHitdataModel];
  max_score = 0;
  total = {};

  constructor(data) {
    super();

    this.update(data);
  }
}
