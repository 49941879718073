import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { theme } from "../../../views/App";
import ReactEcharts from "echarts-for-react";

function FrtTatAveragesByCategoryChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter).then((res) => {
        setPlatformwiseTickets(res);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["reports_on_categories_wise_tat_frt"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  let columns = [];

  columns.push(
    { field: "id", headerName: "Sl", resizable: false, width: 50, hide: true },
    {
      field: "Category",
      headerName: "Category",
      resizable: true,
      hide: false,
      flex: 2,
    },

    {
      field: "frt_count",
      headerName: "Avg. FRT",
      resizable: true,
      hide: false,
      flex: 1,
    },

    {
      field: "tat_count",
      headerName: "Avg. TAT",
      resizable: true,
      hide: false,
      flex: 1,
    }
  );

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const totalSeconds = number * 3600; // Convert the decimal to total seconds
    const hours = Math.floor(totalSeconds / 3600); // Extract the whole number part (hours)
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Convert the remaining seconds to minutes
    const seconds = Math.floor(totalSeconds % 60); // Get the remaining seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  let updatedRows = [];

  platformwiseTickets?.ticket_report?.reports_on_categories_wise_tat_frt[0]?.children?.map(
    (item, index) => {
      updatedRows?.push({
        id: index + 1,
        Category: `${item?.value} (${item?.count})`,
        frt_count: item?.frt_count
          ? HoursAndMinutes(item.frt_count)
          : "00:00:00",
        tat_count: item?.tat_count
          ? HoursAndMinutes(item.tat_count)
          : "00:00:00",
      });
    }
  );

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item, index) => {
        exportData?.push({
          Category: item?.Category,
          ["Avg. FRT"]: item?.frt_count,
          ["Avg. TAT"]: item?.tat_count,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  const NoDataFoundBool = false;

  const handleClick = (params) => {};

  const getOption = () => {
    // Convert HH:MM:SS to seconds for chart use
    const convertToSeconds = (timeStr) => {
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    };

    // Prepare y-axis data from `updatedRows`
    const yAxisData = updatedRows.map((item) => item.Category);

    // Define series for `frt_count` and `tat_count` without stacking
    const series = [
      {
        name: "FRT Count",
        type: "bar",
        data: updatedRows.map((item) => ({
          name: item.Category,
          value: item.frt_count ? convertToSeconds(item.frt_count) : 0,
        })),
        label: {
          show: true,
          position: "right",
          color: theme.palette.primary.dark,
          formatter: (params) => {
            const totalSeconds = params.value; // assuming `params.value` is in seconds
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            // Format to HH:MM:SS
            return `${String(hours).padStart(2, "0")}:${String(
              minutes
            ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
          },
        },
        itemStyle: {
          color: theme.platform.color1, // Dynamic color for first series
        },
      },
      {
        name: "TAT Count",
        type: "bar",
        data: updatedRows.map((item) => ({
          name: item.Category,
          value: item.tat_count ? convertToSeconds(item.tat_count) : 0,
        })),
        label: {
          show: true,
          position: "right",
          formatter: (params) => {
            const totalSeconds = params.value; // assuming `params.value` is in seconds
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            // Format to HH:MM:SS
            return `${String(hours).padStart(2, "0")}:${String(
              minutes
            ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
          },
        },
        itemStyle: {
          color: theme.platform.color2, // Dynamic color for second series
        },
      },
    ];

    return {
      title: {
        left: "center",
        textStyle: {
          fontSize: 16,
          fontWeight: "bold",
          color: theme.palette.primary.main,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName}: ${
                updatedRows.find((row) => row.Category === item.axisValue)[
                  item.seriesName === "FRT Count" ? "frt_count" : "tat_count"
                ]
              }<br/>`;
            }
          });
          return tooltipContent;
        },
      },
      legend: {
        data: ["FRT Count", "TAT Count"],
        icon: "circle",
      },
      grid: {
        top: "10%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        name: "Duration (Seconds)",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          hideOverlap: true,
        },
      },
      yAxis: {
        type: "category",
        data: yAxisData,
        axisLabel: {
          show: true,
          padding: [0, 30, 0, 0],
          hideOverlap: true,
        },
        name: "Category",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 0,
        },
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      series: series,
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading && !NoDataFoundBool ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption()}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: handleClick,
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default FrtTatAveragesByCategoryChart;
