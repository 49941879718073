import BaseReducer from "../../../../utilities/BaseReducer";
import DsrActivityAction from "./DsrActivityAction";

export default class TodayDsrActivityReducer extends BaseReducer {
  initialState = {
    todayDsrActivity: [],
  };
  [DsrActivityAction.REQUEST_GET_TODAY_DSR_ACTIVITY_FINISHED](state, action) {
    return {
      ...state,
      todayDsrActivity: action.payload,
    };
  }
}
