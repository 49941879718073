import BaseReducer from "../../../../utilities/BaseReducer";
import DirectorAction from "./DirectorAction";

// DirectorReuser
export default class DirectorReducer extends BaseReducer {
  initialState = {
    director: [],
  };
  [DirectorAction.REQUEST_DIRECTOR_FINISHED](state, action) {
    return {
      ...state,
      director: action.payload,
    };
  }

  [DirectorAction.REQUEST_DIRECTOR_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      director: action.payload,
    };
  }
}
