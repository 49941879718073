import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// twitter api fetch model
export default class TwitterDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  content_of_the_tweet = "";
  created_by = "";
  created_on = "";
  created_time = "";
  customer_name = "";
  modified_on = "";
  parent_post = "";
  platform = 0;
  sentimental_value = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
