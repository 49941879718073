import ActionUtility from "../../../utilities/ActionUtility";
import TicketReportSEffect from "./TicketReportsEffect";

export default class TicketReportsAction {
  static REQUEST_TICKET_REPORTS = "TicketReportsAction.REQUEST_TICKET_REPORTS";
  static REQUEST_TICKET_REPORTS_FINISHED =
    "TicketReportsAction.REQUEST_TICKET_REPORTS_FINISHED";

  static REQUEST_AGENT_REPORTS = "TicketReportsAction.REQUEST_AGENT_REPORTS";
  static REQUEST_AGENT_REPORTS_FINISHED =
    "TicketReportsAction.REQUEST_AGENT_REPORTS_FINISHED";

  static REQUEST_SENTIMENTAL_ANALYSIS_REPORTS =
    "TicketReportsAction.REQUEST_SENTIMENTAL_ANALYSIS_REPORTS";
  static REQUEST_SENTIMENTAL_ANALYSIS_REPORTS_FINISHED =
    "TicketReportsAction.REQUEST_SENTIMENTAL_ANALYSIS_REPORTS_FINISHED";
  // GET method

  static requestTicketReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketReportsAction.REQUEST_TICKET_REPORTS,
        TicketReportSEffect.requestTicketREports,
        params
      );
    };
  }

  // get agent reports method
  static requestAgentReport(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketReportsAction.REQUEST_AGENT_REPORTS,
        TicketReportSEffect.requestAgentReport,
        params
      );
    };
  }

  // get Sentimental analysis reports method
  static requestSentimentalAnalysisReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketReportsAction.REQUEST_SENTIMENTAL_ANALYSIS_REPORTS,
        TicketReportSEffect.requestSentimentalAnalysisReports,
        params
      );
    };
  }
}
