import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import CKeditor from "../../../views/ticketStatsReports/CKeditor";

function Description(props) {
  const [open, setOpen] = React.useState(false);
  const descriptionRef1 = React.useRef(null);

  const handleToggle = () => {
    if (props.callBack != undefined) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event) => {
    if (
      descriptionRef1.current &&
      descriptionRef1.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      descriptionRef1.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [description, setDescription] = useState(
    props.description
      ? props.description.length > 0
        ? props.description
        : []
      : []
  );

  useEffect(() => {
    props.description &&
      props.description.length &&
      setDescription(props.description);
  }, [props.description]);

  return (
    <React.Fragment>
      <Typography
        component={"p"}
        ref={descriptionRef1}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ backgroundColor: "none", padding: 15 }}
        dangerouslySetInnerHTML={{
          __html:
            description && description[props.index]
              ? description[props.index]
              : "<p>Click here to edit</p>",
        }}
      ></Typography>
      <Popper
        open={open}
        anchorEl={descriptionRef1.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <CKeditor
                    callBack={props.callBack}
                    index={props.index}
                    report={props.report}
                    description={description}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default Description;
