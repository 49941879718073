import PlayStoreAction from "./PlayStoreAction";
import BaseReducer from "../../../utilities/BaseReducer";

// reducer function
export default class PlayStoreFilterReducer extends BaseReducer {
  initialState = {
    playstorefilter: [],
  };

  [PlayStoreAction.REQUEST_PLAYSTORE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      playstorefilter: action.payload,
    };
  }
}
