import { Link } from "react-router-dom";
import React from "react";
import ReleasesAction from "../../../a3label_org_intranet/a3labels/stores/releases/ReleasesAction";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import BlogsAction from "../../../a3label_org_intranet/a3labels/stores/blogs/BlogsAction";
import DiscoveredDataAction from "../../../aiplex_tools_frontend_modular/websweepX/stores/discoveredData/DiscoveredDataAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const MenuNavLink = (props) => {
  const handleLinkClick = (event) => {
    if (localStorage.getItem("changes_made_on_A3page")) {
      const confirmed = window.confirm(
        `Do you want to navigate to ${props.page} page without saving?`
      );

      localStorage.getItem("changes_made_on_A3page") &&
        localStorage.removeItem("changes_made_on_A3page");

      if (!confirmed) {
        event.preventDefault(); // Prevent the navigation if not confirmed
        localStorage.setItem("changes_made_on_A3page", true);
      }
    }

    if (props.page) {
      if (props.page === "Releases") {
        props.dispatch(ReleasesAction.requesReleaseClear());
      }

      if (props.page === "Blogs") {
        props.dispatch(BlogsAction.requestBlogsClear());
      }

    }


    if (props?.to === "/websweepx_discovered") {
      props.dispatch(DiscoveredDataAction.requestClearDiscoveredData());
    }
  };

  return (
    <Link
      exact="true"
      {...props}
      style={{ textDecoration: "none" }}
      onClick={handleLinkClick}
    />
  );
};

export default connect(mapStateToProps)(MenuNavLink);
