import { BaseModel } from "sjs-base-model";

// TwitterDirect message data Model
export default class TwitterDirectMessageDataModel extends BaseModel {
  // response getting from the api

  // created_timestamp = "";
  // id = "";
  // message_create = {
  //   message_data: {
  //     entities: { hashtags: [], symbols: [], urls: [], user_mentions: [] },
  //     text: "",
  //   },
  //   sender_id: "",
  //   source_app_id: "",
  //   target: {
  //     recipient_id: "",
  //   },
  // };

  // type = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
