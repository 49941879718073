import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import OperationGetModel from "./models/OperationGetModel";

export default class OperationEffect {
  //get Operation request endpoint for filter
  static async requestOperationFilter(params) {
    const endpoint = environment.api.operation;
    return EffectUtility.getToModel(OperationGetModel, endpoint, params);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
