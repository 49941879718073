import { BaseModel } from "sjs-base-model";

// get model for project
// twitter api fetch model
export default class FacebookShardsModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  failed = 0;
  skipped = 0;
  successful = 1;
  total = 1;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
