import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

//Tickets list source data model
export default class TicketsSourceModel extends BaseModel {
  Attachment_url = "";
  assigned_to = { user_id: "", user_name: "" };
  comment = [];
  content_type = "";
  created_by = "";
  created_on = "";
  custom_value = [];
  customer = { customer_email: "", customer_name: "" };
  first_response_time = "";
  is_child_ticket = boolean;
  modified_by = "";
  modified_on = "";
  Notes = [];
  parent_ticket_id = "";
  priority = { priority_id: "", priority_name: "" };
  project = { project_id: "", project_name: "" };
  related_tickets = [];
  resolved_time = "";
  souce_link = "";
  source = "";
  status = "";
  tags = [{ tag_id: "", tag_name: "" }];
  ticket_desc = "";
  ticket_name = "";
  ticket_type = { ticket_type_id: "", ticket_type_name: "" };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
