import ActionUtility from "../../utilities/ActionUtility";
import PagePermissionEffect from "./PagePermissionEffect";
export default class PagePermissionAction {
  // PagePermissionAction action with an function declaration
  static REQUEST_PAGE_PERMISSION =
    "PagePermissionAction.REQUEST_PAGE_PERMISSION";
  static REQUEST_PAGE_PERMISSION_FINISHED =
    "PagePermissionAction.REQUEST_PAGE_PERMISSION_FINISHED";

  // METHODS
  // PagePermissionAction GET function
  static requestPagePermission(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PagePermissionAction.REQUEST_PAGE_PERMISSION,
        PagePermissionEffect.requestPagePermission,
        params
      );
    };
  }
}
