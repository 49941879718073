import { BaseModel } from "sjs-base-model";
import GlassdoorAccountDataHitsModel from "./GlassdoorAccountDataHitsModel";
//GlassdoorAccountHitsModel
export default class GlassdoorAccountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ GlassdoorAccountDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
