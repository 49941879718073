import { BaseModel } from "sjs-base-model";

export default class FingerPrintAssetSyncModel extends BaseModel {
  copies_added = 0;
  message = "";
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
