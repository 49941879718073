import ActionUtility from "../../../utilities/ActionUtility";
import OrmPagesEffect from "./OrmPagesEffect";

export default class OrmPagesAction {
  // CLIENT action with an function declaration
  static REQUEST_ORM_PAGES = "OrmPagesAction.REQUEST_ORM_PAGES";
  static REQUEST_ORM_PAGES_FINISHED =
    "OrmPagesAction.REQUEST_ORM_PAGES_FINISHED";

  // METHODS
  // CLIENT GET function
  static requestOrmPages(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmPagesAction.REQUEST_ORM_PAGES,
        OrmPagesEffect.requestOrmPages,
        params
      );
    };
  }
}
