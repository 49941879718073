import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import SurveyUserMapDeleteModel from "./models/SurveyUserMapDeleteModel";
import SurveyUserMapGetModel from "./models/SurveyUserMapGetModel";
import SurveyUserMapPostModel from "./models/SurveyUserMapPostModel";

export default class SurveyUserMapEffect {
  static async requestSurveyUserMap(params) {
    const endpoint = environment.api.surveyUserMApping;
    return EffectUtility.getToModel(SurveyUserMapGetModel, endpoint, params);
  }

  static async requestSurveyUserMapPost(data) {
    const endpoint = environment.api.surveyUserMApping;
    return EffectUtility.postToModel(SurveyUserMapPostModel, endpoint, data);
  }

  static async requestDeleteSurveyUserMap(id) {
    const endpoint = environment.api.surveyUserMApping + "/" + id;

    return EffectUtility.deleteToModel(SurveyUserMapDeleteModel, endpoint);
  }
}
