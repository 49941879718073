import {
  Grid,
  IconButton,
  Typography,
  Box,
  Popper,
  Paper,
  Checkbox,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PauseIcon from "@mui/icons-material/Pause";
import { connect } from "react-redux";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import PlatformAction from "../../stores/platform/PlatformAction";
import KeywordConfigAction from "../../stores/keywordConfiguration/KeywordConfigAction";
import {
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../components/ticketPartials";
import KeywordConfigurationForm from "../../components/keywordConfigurationForm.js/KeywordConfigurationForm";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT,
    PlatformAction.REQUEST_PLATFORM,
    KeywordConfigAction.REQUEST_KEYWORD_CONFIG,
    KeywordConfigAction.REQUEST_KEYWORD_CONFIG_FILTER,
  ]),
  platformAccount: state.platformAccount.platformAccount || [],
  platform: state.platform.platform || [],
  keywordConfig: state.keywordConfig.keywordConfig || [],
  keywordConfigurationFilter:
    state.keywordConfigurationFilter.keywordConfigurationFilter || [],
  pagination: state.keywordConfig.keywordConfig || [],
});

function KeywordConfigurations(props) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const { isRequesting } = props;
  const [page_limit] = useState(15);
  const [keywordsOpenPopper, setKeywordOpenPopper] = React.useState(null);
  const [includeWords, setIncludeWords] = React.useState([]);
  const [excludeWords, setExcludeWords] = React.useState([]);
  const [configuration_name, set_configuration_name] = useState(null);
  const [platform_value, set_platform_value] = useState([]);
  const openPopper = Boolean(keywordsOpenPopper);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  const handleViewClick = (event, params) => {
    setKeywordOpenPopper(keywordsOpenPopper ? null : event.currentTarget);
    setIncludeWords(params.row.rule_group);
    setExcludeWords(params.row.exclude);
  };
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    let params = {
      page_limit: "none",
    };
    let keyword_config_params = {
      page_limit: `${page_limit}`,
      order_by: '[["created_on", "desc"]]',
    };

    // if (
    //   props?.keywordConfigurationFilter === undefined ||
    //   props?.keywordConfigurationFilter.length === 0
    // ) {
    fetchKeywordConfigFilter(params);
    // }

    // if (
    //   props?.platformAccount === undefined ||
    //   props?.platformAccount.length === 0
    // ) {
    fetchPlatformAccounts(params);
    // }

    fetchPlatform();
    fetchKeywordConfig(keyword_config_params);
  }, []);

  function fetchPlatformAccounts(params) {
    props.dispatch(PlatformAccountsAction.requestPlatformAccount(params));
  }

  function fetchPlatform() {
    let param = {
      //   level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function fetchKeywordConfig(params) {
    props.dispatch(KeywordConfigAction.requestKeywordConfiguration(params));
  }

  function fetchKeywordConfigFilter(params) {
    props.dispatch(
      KeywordConfigAction.requestKeywordConfigurationFilter(params)
    );
  }
  function pauseEdit(params) {
    if (!params.row.enabled) {
      let obj = {
        enabled: true,
      };
      props
        .dispatch(
          KeywordConfigAction.requestPutKeywordConfig(
            JSON.stringify(obj),
            params.row._id
          )
        )
        .then(() => {
          let keyword_config_params = {
            page_limit: `${page_limit}`,
            order_by: '[["modified_on", "desc"]]',
          };
          fetchKeywordConfig(keyword_config_params);
        });
    } else {
      let obj = {
        enabled: false,
      };
      props
        .dispatch(
          KeywordConfigAction.requestPutKeywordConfig(
            JSON.stringify(obj),
            params.row._id
          )
        )
        .then(() => {
          let keyword_config_params = {
            page_limit: `${page_limit}`,
            order_by: '[["modified_on", "desc"]]',
          };

          fetchKeywordConfig(keyword_config_params);
        });
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 100,
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
    },
    {
      field: "config_name",
      headerName: "Configuration Name",
      width: 200,
      flex: 2,
    },
    {
      field: "rule_group",
      headerName: "Keywords",
      width: 200,
      renderCell: (item) => item.value.map((i) => String(i.keywords)),
      hide: true,
    },
    {
      field: "account",
      headerName: "Channels",
      width: 250,
      flex: 2.5,
      renderCell: (params) => (
        <Box alignItems={"center"}>
          {params.value.map((item) => (
            <>
              {returnPlatformDropdownLogos(getPlatformName(item.platform_id))}
              &nbsp;
              {getAccountName(item.account_id)}
            </>
          ))}
        </Box>
      ),
    },
    {
      field: "created_on",
      headerName: "Created On",
      width: 150,
      flex: 1.5,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      flex: 1,
    },
    {
      field: "pause",
      type: "actions",
      headerName: "Pause",
      align: "center",

      width: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          title="Pause"
          sx={{ border: "0" }}
          onClick={(event) => {
            pauseEdit(params);
          }}
        >
          {params.row.enabled ? (
            <PauseIcon htmlColor="#2a5e6c" />
          ) : (
            <PlayArrowIcon htmlColor="#2a5e6c" />
          )}
        </IconButton>
      ),
    },

    {
      field: "edit",
      type: "actions",
      headerName: "Edit",
      align: "center",

      // resizable: false,
      flex: 1,
      width: 100,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
    {
      field: "view",
      type: "actions",
      headerName: "View",
      align: "center",

      width: 100,
      flex: 1,

      renderCell: (params) => (
        <Box>
          <IconButton
            title="View keywords"
            onClick={(e) => handleViewClick(e, params)}
            sx={{ border: "0" }}
          >
            {/* {keywordsOpenPopper ? (
              <VisibilityOffIcon fontSize="small" htmlColor="#2a5e6c" />
            ) : ( */}
            <VisibilityIcon fontSize="small" htmlColor="#2a5e6c" />
            {/* )} */}
          </IconButton>

          <Popper
            open={openPopper}
            anchorEl={keywordsOpenPopper}
            sx={{ width: "max-content" }}
          >
            <Paper sx={{ border: 0.5, p: 1.5, boxShadow: 3 }}>
              <Typography sx={{ fontWeight: "bold" }}>Include Words</Typography>
              {includeWords && includeWords.length ? (
                includeWords.map((item, index) => {
                  return (
                    <Typography>
                      {index + 1}. {item.keywords.toString()}
                    </Typography>
                  );
                })
              ) : (
                <span style={{ color: "gray" }}>No Keywords Found</span>
              )}
              <Typography sx={{ fontWeight: "bold" }}>Exclude Words</Typography>
              {excludeWords && excludeWords.length ? (
                excludeWords.map((item, index) => {
                  return (
                    <Typography>
                      {index + 1}. {item.keywords.toString()}
                    </Typography>
                  );
                })
              ) : (
                <span style={{ color: "gray" }}>No Keywords Found</span>
              )}
            </Paper>
          </Popper>
        </Box>
      ),
    },

    {
      field: "delete",
      type: "actions",
      headerName: "Delete",
      align: "center",
      width: 100,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          title="Delete"
          sx={{ border: "0" }}
          onClick={(event) => {
            props
              .dispatch(
                KeywordConfigAction.requestDeleteKeywordConfig(
                  params.row.rowData._id
                )
              )
              .then(() => {
                props.dispatch(
                  KeywordConfigAction.requestKeywordConfiguration({
                    page_limit: page_limit,
                  })
                );
              });
          }}
        >
          <DeleteIcon htmlColor="#2a5e6c" />
        </IconButton>
      ),
    },
  ];

  const rows = [];

  if (props.keywordConfig.hits) {
    props.keywordConfig.hits.hits.map((item, index) => {
      return rows.push({
        id: props.pagination.current_page_no * page_limit + ++index,
        _id: item._id,
        account: item._source.account,
        config_name: item._source.config_name,
        location: item._source.location,
        rule_group: item._source.rule_group,
        exclude: item._source.exclude && item._source.exclude,
        created_on: new Date(item._source.created_on).toLocaleString(),
        config_name: item._source.config_name,
        rowData: item,
        enabled: item._source.enabled,
        status: item._source.enabled ? "Enabled" : "Disabled",
      });
    });
  }

  const all_accounts = [];

  if (props.platformAccount.hits) {
    props.platformAccount.hits.hits &&
      props.platformAccount.hits.hits.map((item) => {
        all_accounts.push(item);
      });
  }

  let platforms = [];

  if (props.platform.hits) {
    props.platform.hits.hits &&
      props.platform.hits.hits.map((item) => {
        if (item._id === "1" || item._id === "9" || item._id === "2") {
          platforms.push({
            platform_id: item._id,
            platform_name: item._source.platform_name,
            platform_alias: item._source.alias,
          });
        }
      });
  }

  platforms.sort((a, b) => a?.platform_alias.localeCompare(b.platform_alias));
  platforms = [...new Set(platforms)];

  function getPlatformName(platformId) {
    return (
      platforms.length > 0 &&
      platforms.filter((item) => item.platform_id === platformId)[0]
        ?.platform_name
    );
  }

  function getAccountName(accountId) {
    all_accounts.filter((item) => item._id === accountId);

    return (
      all_accounts.filter((item) => item._id === accountId).length > 0 &&
      all_accounts.filter((item) => item._id === accountId)[0]._source
        .account_name
    );
  }

  let configuration_name_list = [];

  if (props.keywordConfigurationFilter.hits) {
    props.keywordConfigurationFilter.hits.hits &&
      props.keywordConfigurationFilter.hits.hits.length > 0 &&
      props.keywordConfigurationFilter.hits.hits.map((item) => {
        configuration_name_list.push(item._source.config_name);
      });
  }

  configuration_name_list = [...new Set(configuration_name_list)];

  configuration_name_list.sort((a, b) => a.localeCompare(b));
  configuration_name_list = [...new Set(configuration_name_list)];

  let platform_name_list = [];

  const configuration_name_list_handlechange = (event, value) => {
    if (value != null) {
      set_configuration_name(value);
    } else {
      set_configuration_name(null);
    }
  };
  const platform_HandleChange = (event, value) => {
    if (value != null) {
      set_platform_value(value);
    } else {
      set_platform_value(null);
    }
  };

  // Filter function for Client
  const applyFilter = () => {
    const filter = [];
    if (configuration_name !== null) {
      filter.push(
        `["config_name.keyword","must","","match","${configuration_name}"]`
      );
    }

    if (platform_value && platform_value.length !== 0) {
      let platform_id_array = [];
      platform_value &&
        platform_value.length > 0 &&
        platform_value.map((item) => {
          platform_id_array.push(item.platform_id);
        });
      filter.push(
        `["account","must","nested","",["platform_id.keyword","must","","terms",${JSON.stringify(
          platform_id_array
        )}]]`
      );
    }

    if (
      configuration_name !== null ||
      (platform_value && platform_value.length !== 0)
    ) {
      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      props.dispatch(KeywordConfigAction.requestKeywordConfiguration(params));
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (configuration_name !== null) {
      filter.push(
        `["config_name.keyword","must","","match","${configuration_name}"]`
      );
    }

    if (configuration_name !== null && FilterClicked) {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(
        KeywordConfigAction.requestKeywordConfiguration(filter_params)
      );
    }
  };

  const ClearState = () => {
    if (configuration_name !== null || platform_value) {
      setClearLoader(true);
      set_configuration_name(null);
      set_platform_value([]);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
    };
    FilterClicked &&
      props.dispatch(KeywordConfigAction.requestKeywordConfiguration(params));
  };

  return (
    <Box>
      <KeywordConfigurationForm
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        editData={editRowsModel}
        edit={edit}
        all_accounts={all_accounts}
        platforms={platforms}
      />

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={2}
        // my={2}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <AutoCompleteComponent
            value={configuration_name}
            textFieldProps={{
              placeholder: "Configuration Name",
            }}
            list={configuration_name_list}
            handleChange={configuration_name_list_handlechange}
            size="small"
            width="100%"
          />
        </Grid>

        <Grid item lg={2} md={5} sm={5} mt={1} style={{ position: "relative" }}>
          <Grid
            item
            xl={12}
            style={{
              position: "absolute",
              width: "95%",
              zIndex: 999,
            }}
          >
            <AutoCompleteComponent
              // ***************************************************Customer name filter
              multiple
              value={platform_value}
              list={platforms}
              disableCloseOnSelect={true}
              getOptionLabel={(option) => option.platform_name}
              isOptionEqualToValue={(option, value) =>
                option.platform_alias === value.platform_alias
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.platform_alias}
                </li>
              )}
              limitTags={1}
              textFieldProps={{
                placeholder:
                  platform_value && platform_value.length ? null : "Platforms",
                zIndex: 999,
              }}
              handleChange={platform_HandleChange}
              size="small"
              width="100%"
            />
          </Grid>
        </Grid>

        <Grid item lg={7.5} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Filter"}
                  width="100%"
                  onClick={applyFilter}
                  loading={filterLoader && isRequesting}
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Clear"}
                  width="100%"
                  onClick={ClearState}
                  loading={clearLoader && isRequesting}
                />
              </Grid>
            </Grid>
            <Grid>
              {/* post button component*/}
              <PostPlusButton
                open={open}
                openCloseModal={openCloseModal}
                editMode={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <LoadingIndicator isActive={isRequesting}>
          {props.keywordConfig.hits && !isRequesting ? (
            <DataGridFunction
              columns={columns}
              rows={rows}
              rowHeight={100}
              height="75vh"
              getRowId={(item) => item.id}
              // getRowHeight={() => "auto"}
              pagelength={props.pagination && props.pagination.total_page_no}
              page={props.pagination && props.pagination.current_page_no}
              handleChange={handleChange}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    rule_group: false,
                  },
                },
              }}
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingBgImage />
            </Box>
          )}
        </LoadingIndicator>
      </Grid>
    </Box>
  );
}

export default connect(mapStateToProps)(KeywordConfigurations);
