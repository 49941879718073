import BaseReducer from "../../../utilities/BaseReducer";
import CloudMailOrgAction from "./CloudMailOrgAction";

// OrganizationReduser
export default class CloudMailOrgReducer extends BaseReducer {
  initialState = {
    cloudMailOrg: [],
  };
  [CloudMailOrgAction.REQUEST_CLOUD_MAIL_ORG_FINISHED](state, action) {
    return {
      ...state,
      cloudMailOrg: action.payload,
    };
  }
}
