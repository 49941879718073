import DeviceResetAction from "./DeviceResetAction";
import BaseReducer from "../../utilities/BaseReducer";

export default class DeviceResetReducer extends BaseReducer {
  initialState = {
    influencerDeviceMapping: [],
  };

  [DeviceResetAction.REQUEST_DEVICE_MAPPING_FINISHED](state, action) {
    return {
      ...state,
      influencerDeviceMapping: action.payload,
    };
  }
}
