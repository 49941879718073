import ActionUtility from "../../../utilities/ActionUtility";
import AssignmentRuleEffect from "./AssignmentRuleEffect";

export default class AssignmentRuleAction {
  static REQUEST_ASSIGNMENT_RULE =
    "AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE";
  static REQUEST_ASSIGNMENT_RULE_FINISHED =
    "AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE_FINISHED";

  static REQUEST_ASSIGNMENT_RULE_FILTER =
    "AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE_FILTER";
  static REQUEST_ASSIGNMENT_RULE_FILTER_FINISHED =
    "AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE_FILTER_FINISHED";

  static REQUEST_POST_ASSIGNMENT_RULE =
    "AssignmentRuleAction.REQUEST_POST_ASSIGNMENT_RULE";
  static REQUEST_POST_ASSIGNMENT_RULE_FINISHED =
    "AssignmentRuleAction.REQUEST_POST_ASSIGNMENT_RULE_FINISHED";

  static REQUEST_PUT_ASSIGNMENT_RULE =
    "AssignmentRuleAction.REQUEST_PUT_ASSIGNMENT_RULE";
  static REQUEST_PUT_ASSIGNMENT_RULE_FINISHED =
    "AssignmentRuleAction.REQUEST_PUT_ASSIGNMENT_RULE_FINISHED";

  static REQUEST_PUT_ASSIGNMENT_RULE_BULKUPDATE =
    "AssignmentRuleAction.REQUEST_PUT_ASSIGNMENT_RULE_BULKUPDATE";
  static REQUEST_PUT_ASSIGNMENT_RULE_BULKUPDATE_FINISHED =
    "AssignmentRuleAction.REQUEST_PUT_ASSIGNMENT_RULE_BULKUPDATE_FINISHED";

  //   static REQUEST_DELETE_KEYWORD_CONFIG =
  //     "AssignmentRuleAction.REQUEST_DELETE_KEYWORD_CONFIG";
  //   static REQUEST_DELETE_KEYWORD_CONFIG_FINISHED =
  //     "AssignmentRuleAction.REQUEST_DELETE_KEYWORD_CONFIG_FINISHED";

  //METHODS
  static requestAssignmentRule(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE,
        AssignmentRuleEffect.requestAssignmentRule,
        params
      );
    };
  }

  static requestAssignmentRuleFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE_FILTER,
        AssignmentRuleEffect.requestAssignmentRuleFilter,
        params
      );
    };
  }

  static requestPostAssignmentRule(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssignmentRuleAction.REQUEST_POST_ASSIGNMENT_RULE,
        AssignmentRuleEffect.requestPostAssignmentRule,
        data
      );
    };
  }

  static requestPutAssignmentRule(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssignmentRuleAction.REQUEST_PUT_ASSIGNMENT_RULE,
        AssignmentRuleEffect.requestPutAssignmentRule,
        data,
        id
      );
    };
  }

  // Tag Bulk put function
  static requestPutAssignmentRuleBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssignmentRuleAction.REQUEST_PUT_ASSIGNMENT_RULE_BULKUPDATE,
        AssignmentRuleEffect.requestPutAssignmentRuleBulkUpdate,
        data
      );
    };
  }

  //   static requestDeleteKeywordConfig(id) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         AssignmentRuleAction.REQUEST_DELETE_KEYWORD_CONFIG,
  //         KeywordConfigEffect.requestDeleteKeywordConfig,
  //         id
  //       );
  //     };
  //   }
}
