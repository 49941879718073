import {
  Box,
  Collapse,
  Grid,
  IconButton,
  ListItem,
  SvgIcon,
} from "@mui/material";
import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import React, { useEffect } from "react";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { alpha, styled } from "@mui/material/styles";
import { useSpring, animated } from "@react-spring/web";
import PropTypes from "prop-types";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import CannedMessageAction from "../../stores/cannedMessage/CannedMessageAction";
import { theme } from "../../../views/App";
import MuiButton from "../../../components/muiButton/MuiButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    CannedMessageAction.REQUEST_CANNED_MESSAGE,
  ]),
  cannedMessage: state.cannedMessage.cannedMessage || [],
});
const CannedMessage = (props) => {
  let canned_response =
    props.cannedMessage.hits &&
    props.cannedMessage.hits.hits &&
    props.cannedMessage.hits.hits.length !== 0 &&
    props.cannedMessage.hits.hits[0]._source.canned_message;

  let all_ids_array = [];
  canned_response &&
    canned_response.length !== 0 &&
    canned_response.map((item) => {
      all_ids_array.push(item.id);
      item &&
        item.sub_value.map((item2) => {
          all_ids_array.push(item2.id);
          item2 &&
            item2.sub_value.map((item3) => {
              all_ids_array.push(item3.id);
              item3 &&
                item3.sub_value.map((itm4) => {
                  all_ids_array.push(itm4.id);
                });
            });
        });
    });

  const fetchCannedMessage = () => {
    let params = {
      page_limit: "none",
    };
    props.dispatch(CannedMessageAction.requestCannedMessage(params));
  };
  useEffect(() => {
    fetchCannedMessage();
  }, []);

  let initial = {};

  if (props.cannedMessage.hits && props.cannedMessage.hits.hits.length !== 0) {
    initial = {
      tree_value: props.cannedMessage.hits.hits[0]._source.canned_message,
    };
  } else
    initial = {
      tree_value: [{ id: "1", value: "", sub_value: [] }],
    };

  const StyledTreeItem = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
  ))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      marginTop: 5,
      marginBottom: 5,
    },
  }));

  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }

  function CloseSquare(props) {
    return (
      <SvgIcon
        className="close"
        fontSize="inherit"
        style={{ width: 14, height: 14 }}
        {...props}
      >
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }

  function TransitionComponent(props) {
    const style = useSpring({
      from: {
        opacity: 0,
        transform: "translate3d(20px,0,0)",
      },
      to: {
        opacity: props.in ? 1 : 0,
        transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      },
    });

    return (
      <animated.div style={style}>
        <Collapse {...props} />
      </animated.div>
    );
  }

  TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
  };

  function ReturnIDforNode(array) {
    let maxId = array.reduce((max, obj) => {
      let id = obj.id.split("-")[obj.id.split("-").length - 1];

      return Number(id) > max ? Number(id) : max;
    }, 0);

    const nextId = (maxId = maxId + 1);
    return `${nextId}`;
  }

  const renderTree = (nodes, index, setFieldValue, values) => {
    function findIndexesById(nodes, id, indexes = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        indexes.push(i);
        if (node.id === id) {
          return indexes;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = findIndexesById(node.sub_value, id, [
            ...indexes,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        indexes.pop();
      }
      return null;
    }

    let nodesLength = nodes.id.length;

    return (
      <StyledTreeItem
        key={nodes.id}
        nodeId={nodes.id}
        onClick={(event) => {
          event.stopPropagation();
        }}
        label={
          <Grid
            xl={6.5}
            lg={6.5}
            md={6.5}
            sm={4.5}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <FastField
              as={nodes.id.split("-").length === 4 ? TextArea : MuiTextField}
              // as={MuiTextField}
              className={`${
                nodes.id.split("-").length === 4 ? "textAreaFiled" : "col-10"
              }`}
              autoFocus
              variant="outlined"
              placeholder={
                nodes.id.split("-").length === 1
                  ? "Category"
                  : nodes.id.split("-").length === 2
                  ? "Sub-Category"
                  : nodes.id.split("-").length === 3
                  ? "Title"
                  : nodes.id.split("-").length === 4
                  ? "Response Message"
                  : "Enter Name"
              }
              size="small"
              name={`tree_value${findIndexesById(values.tree_value, nodes.id)
                .map((i, ind) => {
                  return ind !==
                    findIndexesById(values.tree_value, nodes.id).length - 1
                    ? `.${i}.sub_value`
                    : `.${i}.value`;
                })
                .join("")}`}
              // className="col-10"
              width={"fit-content"}
              validate={(value) => {
                if (!value) {
                  return "Required";
                }
              }}
            />

            <ErrorMessage
              name={`tree_value${findIndexesById(values.tree_value, nodes.id)
                .map((i, ind) => {
                  return ind !==
                    findIndexesById(values.tree_value, nodes.id).length - 1
                    ? `.${i}.sub_value`
                    : `.${i}.value`;
                })
                .join("")}`}
              component="div"
              style={{
                color: `${theme.palette.buttonComponent.background.error}`,
                marginTop: 9,
              }}
            />
          </Grid>
        }
        collapseIcon={<MinusSquare />}
        expandIcon={<PlusSquare />}
        endIcon={<CloseSquare />}
      >
        <FieldArray
          name={`tree_value${findIndexesById(values.tree_value, nodes.id)
            .map((i) => `.${i}.sub_value`)
            .join("")}`}
        >
          {({ insert, remove, push }) => (
            <Grid container>
              {Array.isArray(nodes.sub_value) && (
                <>
                  {nodes.sub_value.length > 0 &&
                    nodes.sub_value.map((node, index) => {
                      return (
                        <Grid sx={{ display: "flex", width: "100%" }}>
                          <Grid sm={1} sx={{ marginRight: 1 }}>
                            <IconButton
                              onClick={() => {
                                remove(
                                  findIndexesById(nodes.sub_value, node.id)[0]
                                );
                              }}
                            >
                              <DisabledByDefaultOutlinedIcon />
                            </IconButton>
                          </Grid>
                          <Grid sm={11}>
                            {renderTree(node, index, setFieldValue, values)}
                          </Grid>
                        </Grid>
                      );
                    })}

                  {nodesLength < 6 ? (
                    nodesLength === 5 && nodes.sub_value.length === 1 ? null : (
                      <IconButton
                        onClick={() => {
                          push({
                            id: `${nodes.id}-${ReturnIDforNode(
                              nodes.sub_value
                            )}`,
                            value: "",
                            sub_value: [],
                          });
                        }}
                      >
                        <PlusSquare />
                      </IconButton>
                    )
                  ) : null}
                </>
              )}
            </Grid>
          )}
        </FieldArray>
      </StyledTreeItem>
    );
  };

  return (
    <Box
      className="scrollable"
      style={{
        height: "100%",
        maxHeight: `${window.innerHeight}px`,
        overflow: "auto",
        position: "relative",
      }}
    >
      <Grid
        item
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid
          item
          container
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={8}
          alignItems="center"
          justifyContent={"center"}
          //   className="bg-success"
          flexDirection="column"
        >
          <Box my={3}>
            <h4>Canned Responses</h4>
          </Box>

          <Box
            //   className="bg-warning"
            width={"100%"}
          >
            <LoadingIndicator isActive={props.isRequesting}>
              {!props.isRequesting ? (
                <Box width="100%">
                  <Formik
                    initialValues={initial}
                    onSubmit={(values, actions) => {
                      //   props.setFieldValue(values.tree_value);
                      if (
                        props.cannedMessage.hits &&
                        props.cannedMessage.hits.hits.length !== 0
                      ) {
                        let put_obj = {
                          canned_message: values.tree_value,
                        };

                        let id =
                          props.cannedMessage.hits &&
                          props.cannedMessage.hits.hits.length !== 0
                            ? props.cannedMessage.hits.hits[0]._id
                            : "";
                        props
                          .dispatch(
                            CannedMessageAction.requestPutCannedMessage(
                              put_obj,
                              id
                            )
                          )
                          .then(() => {
                            fetchCannedMessage();
                          });
                      } else {
                        let post_obj = {
                          canned_message: values.tree_value,
                        };
                        props
                          .dispatch(
                            CannedMessageAction.requestPostCannedMessage(
                              post_obj
                            )
                          )
                          .then(() => {
                            fetchCannedMessage();
                          });
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      submitForm,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <FieldArray name="tree_value">
                          {({ insert, remove, push }) => (
                            <Grid container width="100%">
                              {values.tree_value.length > 0 &&
                                values.tree_value.map((item, index) => (
                                  <Grid
                                    container
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                    }}
                                  >
                                    <Grid item sm={1}>
                                      <ListItem
                                        sx={{ cursor: "pointer" }}
                                        title="Delete"
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        <DisabledByDefaultOutlinedIcon />
                                      </ListItem>
                                    </Grid>
                                    <Grid item sm={11}>
                                      <TreeView
                                        aria-label="customized"
                                        defaultExpanded={all_ids_array}
                                        // defaultCollapseIcon={<MinusSquare />}
                                        // defaultExpandIcon={<PlusSquare />}
                                        // defaultEndIcon={<CloseSquare />}
                                        sx={{
                                          height: "auto",
                                          // maxHeight: 500,
                                          flexGrow: 1,
                                          overflow: "auto",
                                        }}
                                      >
                                        {renderTree(
                                          item,
                                          index,
                                          setFieldValue,
                                          values
                                        )}
                                      </TreeView>
                                    </Grid>
                                  </Grid>
                                ))}
                              <ListItem
                                sx={{ cursor: "pointer" }}
                                title="Add"
                                onClick={() => {
                                  push({
                                    id: ReturnIDforNode(values.tree_value),
                                    value: "",
                                    sub_value: [],
                                  });
                                }}
                              >
                                <AddBoxOutlinedIcon />
                              </ListItem>
                            </Grid>
                          )}
                        </FieldArray>

                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          alignItems="center"
                          //   className="bg-warning"
                          justifyContent={"center"}
                        >
                          <Grid item>
                            <MuiButton
                              name={
                                props.cannedMessage.hits &&
                                props.cannedMessage.hits.hits.length !== 0
                                  ? "Submit"
                                  : "Add"
                              }
                              width={150}
                              onClick={() => {
                                submitForm();
                              }}
                            />
                          </Grid>{" "}
                          <Grid item>
                            <MuiButton
                              name={"Reset"}
                              width={150}
                              type="Reset"
                            />
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage />
                </Box>
              )}
            </LoadingIndicator>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(CannedMessage);
