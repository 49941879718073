import BaseReducer from "../../../utilities/BaseReducer";
import UserAction from "./UserAction";

export default class UserFilterReducer extends BaseReducer {
  //initial state of user
  initialState = {
    filterUser: [],
  };

  //user request filter userName
  [UserAction.REQUEST_USER_FILTERUSERNAME_FINISHED](state, action) {
    return {
      ...state,
      filterUser: action.payload,
    };
  }
}
