import BaseReducer from "../../../utilities/BaseReducer";
import GmbReviewAction from "./GmbReviewAction";

export default class GmbReviewFeedsFilterReduser extends BaseReducer {
  //initial state of GmbReviewFeedsFilterReduser
  initialState = {
    GMBReviewFeedsFilter: [],
  };
  //GmbReviewFeedsFilterReduser request action finish
  [GmbReviewAction.REQUEST_GMB_REVIEW_FEED_FILTER_FINISHED](state, action) {
    return {
      ...state,
      GMBReviewFeedsFilter: action.payload,
    };
  }
}
