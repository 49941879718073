import ActionUtility from "../../../../utilities/ActionUtility";
import UserProfileEffect from "./UserProfileEffect";

export default class UserProfileAction {
  static REQUEST_USERPROFILE = "UserProfileAction.REQUEST_USERPROFILE";
  static REQUEST_USERPROFILE_FINISHED =
    "UserProfileAction.REQUEST_USERPROFILE_FINISHED";

  static REQUEST_PUT_USERPROFILE = "UserProfileAction.REQUEST_PUT_USERPROFILE";
  static REQUEST_PUT_USERPROFILE_FINISHED =
    "UserProfileAction.REQUEST_PUT_USERPROFILE_FINISHED";

  // METHODS

  static requestUserprofile() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_USERPROFILE,
        UserProfileEffect.requestUserprofile
      );
    };
  }

  static requestPutUserprofile(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_USERPROFILE,
        UserProfileAction.requestPutUserprofile,
        data
      );
    };
  }
}
