import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import AppStoreAccountDeleteModel from "./models/AppStoreAccountDeleteModel";
import AppStoreAccountGetModel from "./models/AppStoreAccountGetModel";
import AppstoreAccountPutModel from "./models/AppstoreAccountPutModel";
import AppStoreAddAccountModel from "./models/AppStoreAddAccountModel";

//Appstore Add account effect

export default class AppStoreAddAccountEffect {
  //get method of appstore account
  static async requestAppStoreAccount(params) {
    const endpoint = environment.api.appStoreAddAccount;

    return EffectUtility.getToModel(AppStoreAccountGetModel, endpoint, params);
  }

  //get method for appstore account filter dropdown
  static async requestAppStoreAccountFilter(params) {
    const endpoint = environment.api.appstoreAccountFilter;

    return EffectUtility.getToModel(AppStoreAccountGetModel, endpoint, params);
  }

  //post method of appstore account
  static async requestPostAppstoreAccount(data) {
    const endpoint = environment.api.appStoreAddAccount + "/add";

    return EffectUtility.postToModel(AppStoreAddAccountModel, endpoint, data);
  }

  //put method of appstore account
  static async requestPutAppSstoreAccount(data, id) {
    const endpoint = environment.api.appStoreAddAccount + "/" + id;

    return EffectUtility.putToModel(AppstoreAccountPutModel, endpoint, data);
  }

  //DELETE method of appstore account
  static async requestDeleteAppstoreAccount(id) {
    const endpoint = environment.api.appStoreAddAccount + "/" + id;

    return EffectUtility.deleteToModel(AppStoreAccountDeleteModel, endpoint);
  }
}
