import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import RevenueTransactionGetModel from "./models/RevenueTransactionGetModel";
import RevenueTransactionPostModel from "./models/RevenueTransactionPostModel";

export default class RevenueTransactionEffect {
  static async requestRevenueTransaction(params) {
    const endpoint = environment.api.revenueTransaction;

    return EffectUtility.getToModel(
      RevenueTransactionGetModel,
      endpoint,
      params
    );
  }

  static async requestRevenueReportClear() {
    return {};
  }

  static async requestPostRevenueTransaction(data) {
    const endpoint = environment.api.revenueTransaction;
    console.log("endpoint", endpoint);
    return EffectUtility.postToModel(
      RevenueTransactionPostModel,
      endpoint,
      data
    );
  }
}
