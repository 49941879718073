import BaseReducer from "../../../utilities/BaseReducer";
import ConsumerAction from "./ConsumerAction";

export default class ConsumerFilterReducer extends BaseReducer {
  //initial state of user
  initialState = {
    consumer_filter: [],
  };

  //user request action finish
  [ConsumerAction.REQUEST_CONSUMER_FILTER_FINISHED](state, action) {
    return {
      ...state,
      consumer_filter: action.payload,
    };
  }
}
