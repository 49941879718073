import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// Google Account  data model
export default class McnFeedsDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  category= {
    category_id :"",
    category_name :""
  }
  channel_name = "";
  count = 0;
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  like_count = "";
  media_community= "";
  modified_by = {
    user_id: "",
    user_name: "",
  };
  modified_on = "";
  project = {
    project_id: "",
    project_name: "",
  };
  published= "";
  summary= "";
  video_thumbnail= "";
  video_title= "";
  video_url= "";
  views = "";
  yt_channelid= "";
  yt_videoid= "";

  constructor(data) {
    super();

    this.update(data);
  }
}
