import { BaseModel } from "sjs-base-model";

export default class YoutubeChannelSubscribeModel extends BaseModel {
  status = "";
  constructor(data1) {
    super();

    this.update(data1);
  }
}
