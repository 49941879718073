import BaseReducer from "../../../utilities/BaseReducer";
import TicketFirstResponseTimeAction from "./TicketFirstResponseTimeAction";

export default class TicketFirstResponseTimeReducer extends BaseReducer {
  //initial state of Ticket first response time
  initialState = {
    ticketFirstResponseTime: [],
  };

  //Ticket first response time request action finish
  [TicketFirstResponseTimeAction.REQUEST_TICKETFIRSTRESPONSETIME_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ticketFirstResponseTime: action.payload,
    };
  }
}
