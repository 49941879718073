import environment from "environment";

import EffectUtility from "../../../utilities/EffectUtility";
import TicketByUserLastWorkingProjectByIdModeldModel from "./model/UserLastWorkingProjectByIdModel";
import LastProjectPostModel from "./model/LastProjectPostModel";

export default class UserLastWorkingProjectEffect {
  //get ticket request end point
  //   static async requestTickets(params) {
  //     const endpoint = environment.api.tickets;

  //get ticket request bu id end point
  static async requestLastWorkingProjectById(id) {
    const endpoint = environment.api.userLastWorkingProject + "/" + id;

    return EffectUtility.getToModel(
      TicketByUserLastWorkingProjectByIdModeldModel,
      endpoint,
      id
    );
  }

  // Post api
  static async requestPostLastWorkingProject(data, callBackFunction) {
    const endpoint = environment.api.userLastWorkingProject;

    let response = EffectUtility.postToModel(
      LastProjectPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }
}
