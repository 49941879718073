import BaseReducer from "../../../utilities/BaseReducer";
import DIYReportTemplateAction from "./DIYReportTemplateAction";

export default class DIYReportTemplateByIdReduser extends BaseReducer {
  initialState = {
    diyReportById: [],
  };

  [DIYReportTemplateAction.REQUEST_DIY_REPORTS_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      diyReportById: action.payload,
    };
  }
}
