import { BaseModel } from "sjs-base-model";

export default class FPTotalAssetCountGetModel extends BaseModel {
  total_asset_count = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
