import BaseReducer from "../../../utilities/BaseReducer";
import TelegramAction from "./TelegramAction";

export default class TelegramFeedsFilterReducer extends BaseReducer {
  initialState = {
    telegramFeedsFilter: [],
  };

  [TelegramAction.REQUEST_TELEGRAM_FEEDS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      telegramFeedsFilter: action.payload,
    };
  }
}
