import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { connect } from "react-redux";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import { theme } from "../../../../../views/App";
import InstagramFeedsAction from "../../../../stores/instagramFeeds/InstagramFeedsAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import GmbQuestionFeedsAction from "../../../../stores/gmbQuestions/GmbQuestionFeedsAction";
import GmbReviewAction from "../../../../stores/gmbReviews/GmbReviewAction";
import FacebookAction from "../../../../stores/facebook/FacebookAction";
import LinkedInFeedAction from "../../../../stores/linkedInFeed/LinkedInFeedAction";
import YoutubeAction from "../../../../stores/youtube/YoutubeAction";
import AppStoreAction from "../../../../stores/linkedInFeed/appstore/AppStoreAction";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";
import IndeedFeedAction from "../../../../stores/indeed/IndeedFeedAction";
import RedditFeedAction from "../../../../stores/reddit/RedditFeedAction";
import ConsumerAction from "../../../../stores/consumerFeeds/ConsumerAction";
import WhatsAppFeedAction from "../../../../stores/whatsappFeed/WhatsAppFeedAction";
import TwitterDirectMessageAction from "../../../../stores/linkedIn_account/twitterDirectMessage/TwitterDirectMessageAction";
import TelegramAction from "../../../../stores/telegram/TelegramAction";
import DiscordFeedsAction from "../../../../stores/discord/DiscordFeedsAction";
import EmailFeedsAction from "../../../../stores/emailFeeds/EmailFeedsAction";
import PlayStoreAction from "../../../../stores/playstore/PlayStoreAction";
import QuoraFeedsAction from "../../../../stores/quoraFeeds/QuoraFeedsAction";
import { ShadowContainer } from "../../../../../components/componentLayout/ComponentLayout";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function SentimentValueUpdatePopper(props) {
  const [open, setOpen] = React.useState(false);
  const priorityPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      priorityPopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      priorityPopperRef.current &&
      priorityPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  let sentiment_value_option = ["Positive", "Negative", "Neutral"];

  let renderEmoji = (sentiment) => {
    return sentiment === "Positive" ? (
      <SentimentSatisfiedAltRoundedIcon
        className="p-0 m-0"
        titleAccess="Positive"
        style={{
          width: "16px",
          color: `green`,
        }}
      />
    ) : sentiment === "Negative" ? (
      <SentimentDissatisfiedRoundedIcon
        className="p-0 m-0"
        titleAccess="Negative"
        style={{
          width: "16px",
          color: `${theme.palette.sentimentIconColor.negative}`,
        }}
      />
    ) : sentiment === "Neutral" ? (
      <SentimentNeutralRoundedIcon
        className="p-0 m-0"
        titleAccess="Neutral"
        style={{
          width: "16px",
          color: `${theme.palette.sentimentIconColor.neutral}`,
        }}
      />
    ) : (
      ""
    );
  };

  let returnSentimentValueString = (sentiment_string) => {
    let sentiment_score = "";
    if (sentiment_string === "Positive") {
      sentiment_score = 0.1;
    } else if (sentiment_string === "Negative") {
      sentiment_score = -0.1;
    } else if (sentiment_string === "Neutral") {
      sentiment_score = 0.0;
    }

    return sentiment_score;
  };

  let sentimentUpdateMethod = (sentiment) => {
    if (props.from && props.from === "feeds") {
      if (props.ticketData._source.platform.platform_name === "Instagram") {
        props
          .dispatch(
            InstagramFeedsAction.requestPutInstaFeeds(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Twitter" &&
        props.feedData._index === "twitter"
      ) {
        props
          .dispatch(
            TwitterAction.requestPutTwitterFeeds(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Appstore"
      ) {
        props
          .dispatch(
            AppStoreAction.requestPutAppStoreFeeds(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Youtube"
      ) {
        props
          .dispatch(
            YoutubeAction.requestPutYoutubeFeeds(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "LinkedIn"
      ) {
        props
          .dispatch(
            LinkedInFeedAction.requestPutLinkedInFeeds(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Facebook"
      ) {
        props
          .dispatch(
            FacebookAction.requestPutFbFeeds(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
                comment_id: props.fb_comment_id && props.fb_comment_id,
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (props.ticketData._source.platform.platform_name === "GMB") {
        if (props.feedData._index === "gmb_review") {
          props
            .dispatch(
              GmbReviewAction.requestPutGMBReviews(
                JSON.stringify({
                  sentiment_value: returnSentimentValueString(sentiment),
                }),
                props.feedId
              )
            )
            .then(() => {
              props.dispatch(
                TicketsAction.requestTicketsById(props.ticketData._id)
              );
            });
        } else {
          props
            .dispatch(
              GmbQuestionFeedsAction.requestPutGMBQuestions(
                JSON.stringify({
                  sentiment_value: returnSentimentValueString(sentiment),
                }),
                props.feedId
              )
            )
            .then(() => {
              props.dispatch(
                TicketsAction.requestTicketsById(props.ticketData._id)
              );
            });
        }
      } else if (props.ticketData._source.platform.platform_name === "Indeed") {
        props
          .dispatch(
            IndeedFeedAction.requestPutIndeedFeed(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (props.ticketData._source.platform.platform_name === "Reddit") {
        props
          .dispatch(
            RedditFeedAction.requestPutRedditFeed(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name ===
        "Consumer Complaints"
      ) {
        props
          .dispatch(
            ConsumerAction.requestPutConsumer(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Whatsapp"
      ) {
        props
          .dispatch(
            WhatsAppFeedAction.requestPutWhatsAppFeed(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Twitter" &&
        props.feedData._index === "twitter_direct_messages"
      ) {
        props
          .dispatch(
            TwitterDirectMessageAction.requestPutTwitterDmFeed(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Telegram"
      ) {
        props
          .dispatch(
            TelegramAction.requestTelegramPut(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Discord"
      ) {
        props
          .dispatch(
            DiscordFeedsAction.requestPutDiscordFeeds(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (props.ticketData._source.platform.platform_name === "Email") {
        props
          .dispatch(
            EmailFeedsAction.requestEmailFeedsPut(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (
        props.ticketData._source.platform.platform_name === "Playstore"
      ) {
        props
          .dispatch(
            PlayStoreAction.requestPlayStorePut(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      } else if (props.ticketData._source.platform.platform_name === "Quora") {
        props
          .dispatch(
            QuoraFeedsAction.requestPutQuoraFeed(
              JSON.stringify({
                sentiment_value: returnSentimentValueString(sentiment),
              }),
              props.feedId
            )
          )
          .then(() => {
            props.dispatch(
              TicketsAction.requestTicketsById(props.ticketData._id)
            );
          });
      }
    } else {
      props
        .dispatch(
          TicketsAction.requestPutTicket(
            JSON.stringify({
              sentiment_value: returnSentimentValueString(sentiment),
            }),
            props.ticketId
          )
        )
        .then(() => {
          props.fetchFunction(sentiment);
        });
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={priorityPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={priorityPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <ShadowContainer borderRadius={5}>
              <Paper
                style={{
                  width: "auto",
                  minWidth: "fit-content",
                  height: "auto",
                  padding: 2,
                  //   boxShadow: theme.shadows,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    style={{
                      height: "fit-content",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                    className="scrollable"
                  >
                    {sentiment_value_option.length > 0 &&
                      sentiment_value_option.map((sentiment) => (
                        <ListItem
                          sx={{
                            cursor: "pointer",

                            "&:hover": {
                              backgroundColor: theme.palette.background.default,
                            },
                          }}
                          onClick={() => {
                            sentimentUpdateMethod(sentiment);
                          }}
                        >
                          <Grid
                            item
                            container
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            // className="bg-warning"
                            justifyContent={"flex-start"}
                            alignItems="center"
                            gap={1}
                          >
                            <Grid
                              item
                              //   className="bg-success"
                            >
                              {renderEmoji(sentiment)}
                            </Grid>
                            <Grid
                              item
                              //   className="bg-info"
                            >
                              {sentiment}
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                  </div>
                </ClickAwayListener>
              </Paper>
            </ShadowContainer>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(SentimentValueUpdatePopper);
