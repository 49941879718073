import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import AssetCollectionPostModel from "./model/AssetCollectionPostModel";
import AssetCollectionGetModel from "./model/AssetCollectionGetModel";

// AssetCollectionEffect
export default class AssetCollectionEffect {
  static async requestGetCollection(params, callback) {
    const endpoint = environment.api.collection;
    let response = EffectUtility.getToModel(
      AssetCollectionGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetCollectionFilter(params, callback) {
    const endpoint = environment.api.collection;
    let response = EffectUtility.getToModel(
      AssetCollectionGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPutCollection(data, id) {
    const endpoint = environment.api.collection + "/" + id;
    return EffectUtility.putToModel(AssetCollectionPostModel, endpoint, data);
  }

  static async requestPostCollection(params, callback) {
    const endpoint = environment.api.collection + "/add";
    let response = EffectUtility.postToModel(
      AssetCollectionPostModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostCopiesToDataExtraction(data, callback) {
    const endpoint = environment.api.DataExtractionAll;
    let response = EffectUtility.postToModel(
      AssetCollectionPostModel,
      endpoint,
      data
    );
    return response;
  }

  static async requestGetCopies(params) {
    const endpoint = environment.api.copies;
    let response = EffectUtility.getToModel(
      AssetCollectionGetModel,
      endpoint,
      params
    );
    return response;
  }
}
