import BaseReducer from "../../../utilities/BaseReducer";
import PlatformAccountsAction from "./PlatformAccountsAction";

//Platform Account Reducer
export default class TelegramPlatformAccountReduser extends BaseReducer {
  initialState = {
    telegramPlatformAccount: [],
  };

  [PlatformAccountsAction.REQUEST_TELEGRAM_PLATFORM_ACCOUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      telegramPlatformAccount: action.payload,
    };
  }
}
