import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import CmsUploadLinksGetModel from "./models/CmsUploadLinksGetModel";
import CmsLinksValidationModel from "./models/CmsLinkValidationModel";
import CmsProcessingStatusModel from "./models/CmsProcessingStatusModel";

export default class CmsUploadLinksEffect {
  // calling an API according to theCmsUploadLinks model
  static async requestCmsUploadLinks(params) {
    const endpoint = environment.api.cmsUploadLinks;
    return EffectUtility.getToModel(CmsUploadLinksGetModel, endpoint, params);
  }

  static async requestCmsUploadLinksClear() {
    return {};
  }

  static async requestCmsUploadLinksFilterClear() {
    return {};
  }

  // Get method for theCmsUploadLinks filter
  static async requestCmsUploadLinksFilter(params) {
    const endpoint = environment.api.cmsUploadLinks;
    return EffectUtility.getToModel(CmsUploadLinksGetModel, endpoint, params);
  }

  static async requestCmsLinksValidator(params) {
    const endpoint = environment.api.cmsLinkValidator;
    return EffectUtility.getToModel(CmsLinksValidationModel, endpoint, params);
  }

  static async requestCmsProcessingStatus(params) {
    const endpoint = environment.api.cmsProcessingStatus;
    return EffectUtility.getToModel(CmsProcessingStatusModel, endpoint, params);
  }
}
