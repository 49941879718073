import BaseReducer from "../../../../utilities/BaseReducer";
import WebsweepxReportsAction from "./WebsweepxReportsAction";

export default class WebsweepxReportsCMSWSXReduser extends BaseReducer {
  initialState = {
    websweepXReportCMSvsWSX: [],
  };
  [WebsweepxReportsAction.REQUEST_WEBSWEEPXREPORTCMSvsWSX_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepXReportCMSvsWSX: action.payload,
    };
  }
}
