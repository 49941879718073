import ActionUtility from "../../../../utilities/ActionUtility";
import NvProjectEffect from "./NvProjectEffect";

export default class NvProjectAction {
  static REQUEST_GET_NV_PROJECT = "NvProjectAction.REQUEST_GET_NV_PROJECT";
  static REQUEST_GET_NV_PROJECT_FINISHED =
    "NvProjectAction.REQUEST_GET_NV_PROJECT_FINISHED";

  static REQUEST_NV_PROJECT_FILTER =
    "NvProjectAction.REQUEST_NV_PROJECT_FILTER";
  static REQUEST_NV_PROJECT_FILTER_FINISHED =
    "NvProjectAction.REQUEST_NV_PROJECT_FILTER_FINISHED";

  static REQUEST_POST_NV_PROJECT = "NvProjectAction.REQUEST_POST_NV_PROJECT";
  static REQUEST_POST_NV_PROJECT_FINISHED =
    "NvProjectAction.REQUEST_POST_NV_PROJECT_FINISHED";

  static REQUEST_PUT_NV_PROJECT = "NvProjectAction.REQUEST_PUT_NV_PROJECT";
  static REQUEST_PUT_NV_PROJECT_FINISHED =
    "NvProjectAction.REQUEST_PUT_NV_PROJECT_FINISHED";

  static REQUEST_PUT_SYNC_CMS_PROPERTIES =
    "NvProjectAction.REQUEST_PUT_SYNC_CMS_PROPERTIES";
  static REQUEST_PUT_SYNC_CMS_PROPERTIES_FINISHED =
    "NvProjectAction.REQUEST_PUT_SYNC_CMS_PROPERTIES_FINISHED";

  static REQUEST_GET_EXECUTE_SCRIPT_PROPERTY_WISE =
    "NvProjectAction.REQUEST_GET_EXECUTE_SCRIPT_PROPERTY_WISE";
  static REQUEST_GET_EXECUTE_SCRIPT_PROPERTY_WISE_FINISHED =
    "NvProjectAction.REQUEST_GET_EXECUTE_SCRIPT_PROPERTY_WISE_FINISHED";

  static REQUEST_GET_PROPERTY_WISE_CONTENT_URLS =
    "NvProjectAction.REQUEST_GET_PROPERTY_WISE_CONTENT_URLS";
  static REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_FINISHED =
    "NvProjectAction.REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_FINISHED";

  static REQUEST_POST_EXECUTE_CONTENT_URLS_WISE =
    "NvProjectAction.REQUEST_POST_EXECUTE_CONTENT_URLS_WISE";
  static REQUEST_POST_EXECUTE_CONTENT_URLS_WISE_FINISHED =
    "NvProjectAction.REQUEST_POST_EXECUTE_CONTENT_URLS_WISE_FINISHED";

  static REQUEST_POST_GOOGLESEARCHEXTRACTOR =
    "NvProjectAction.REQUEST_POST_GOOGLESEARCHEXTRACTOR";
  static REQUEST_POST_GOOGLESEARCHEXTRACTOR_FINISHED =
    "NvProjectAction.REQUEST_POST_GOOGLESEARCHEXTRACTOR_FINISHED";

  // METHODS
  static requestNvProject(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_GET_NV_PROJECT,
        NvProjectEffect.requestNvProject,
        params,
        callback
      );
    };
  }

  static requestGetExecteScript_propertyWise(data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_GET_EXECUTE_SCRIPT_PROPERTY_WISE,
        NvProjectEffect.requestGetExecteScript_propertyWise,
        data,
        callback
      );
    };
  }

  static requestNvProjectFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_NV_PROJECT_FILTER,
        NvProjectEffect.requestNvProjectFilter,
        params
      );
    };
  }

  static requestPostNvProject(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_POST_NV_PROJECT,
        NvProjectEffect.requestPostNvProject,
        data,
        callBackFunction
      );
    };
  }
  static requestPostgoogleSearchExtractor(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_POST_GOOGLESEARCHEXTRACTOR,
        NvProjectEffect.requestPostgoogleSearchExtractor,
        data,
        callBackFunction
      );
    };
  }

  static requestPutNvCategory(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_PUT_NV_PROJECT,
        NvProjectEffect.requestPutNvCategory,
        data,
        id
      );
    };
  }

  static requestSyncCMSProperties(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_PUT_SYNC_CMS_PROPERTIES,
        NvProjectEffect.requestSyncCMSProperties,
        id
      );
    };
  }

  static requestPropertyWiseContentUrls(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_GET_PROPERTY_WISE_CONTENT_URLS,
        NvProjectEffect.requestPropertyWiseContentUrls,
        params,
        callback
      );
    };
  }

  static requestPostExecuteContentUrlWise(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NvProjectAction.REQUEST_POST_EXECUTE_CONTENT_URLS_WISE,
        NvProjectEffect.requestPostExecuteContentUrlWise,
        data
      );
    };
  }
}
