import ActionUtility from "../../../utilities/ActionUtility";
import AllMentionsEffect from "./AllMentionsEffect";

export default class AllMentionsAction {
  // AllMentionsAction action with an function declaration
  static REQUEST_ALLMENTIONS = "AllMentionsAction.REQUEST_ALLMENTIONS";
  static REQUEST_ALLMENTIONS_FINISHED =
    "AllMentionsAction.REQUEST_ALLMENTIONS_FINISHED";

  static REQUEST_ALLMENTIONS_FILTER =
    "AllMentionsAction.REQUEST_ALLMENTIONS_FILTER";
  static REQUEST_ALLMENTIONS_FILTER_FINISHED =
    "AllMentionsAction.REQUEST_ALLMENTIONS_FILTER_FINISHED";

  static REQUEST_ALLMENTIONS_EXPORT_FILTER =
    "AllMentionsAction.REQUEST_ALLMENTIONS_EXPORT_FILTER";
  static REQUEST_ALLMENTIONS_EXPORT_FILTER_FINISHED =
    "AllMentionsAction.REQUEST_ALLMENTIONS_EXPORT_FILTER_FINISHED";

  static REQUEST_MENTIONS_CUSTOMERS_AND_ACCOUNTNAMES =
    "AllMentionsAction.REQUEST_MENTIONS_CUSTOMERS_AND_ACCOUNTNAMES";
  static REQUEST_MENTIONS_CUSTOMERS_AND_ACCOUNTNAMES_FINISHED =
    "AllMentionsAction.REQUEST_MENTIONS_CUSTOMERS_AND_ACCOUNTNAMES_FINISHED";

  // METHODS
  // All Mentions GET function
  static requestAllmentions(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AllMentionsAction.REQUEST_ALLMENTIONS,
        AllMentionsEffect.requestAllmentions,
        params
      );
    };
  }

  static requestAllmentionsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AllMentionsAction.REQUEST_ALLMENTIONS_FILTER,
        AllMentionsEffect.requestAllmentionsFilter,
        params
      );
    };
  }

  static requestAllmentionsExportFilter(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AllMentionsAction.REQUEST_ALLMENTIONS_EXPORT_FILTER,
        AllMentionsEffect.requestAllmentionsExportFilter,
        params,
        callBack
      );
    };
  }

  static requestMentionsCustomerAndAccountNames(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AllMentionsAction.REQUEST_MENTIONS_CUSTOMERS_AND_ACCOUNTNAMES,
        AllMentionsEffect.requestMentionsCustomerAndAccountNames,
        params
      );
    };
  }
}
