import BaseReducer from "../../../utilities/BaseReducer";
import AiSuggestionAction from "./AiSuggestionAction";

export default class BardAiSuggestionReducer extends BaseReducer {
  initialState = {
    BardAiSuggestions: [],
  };
  [AiSuggestionAction.REQUEST_BARD_AI_SUGGESTION_FINISHED](state, action) {
    return {
      ...state,
      BardAiSuggestions: action.payload,
    };
  }
}
