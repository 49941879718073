import ActionUtility from "../../../utilities/ActionUtility";
import GmbReviewEffect from "./GmbReviewEffect";

export default class GmbReviewAction {
  // GmbReviewAction  with an function declaration
  static REQUEST_GMB_REVIEW_FEED = "GmbReviewAction.REQUEST_GMB_REVIEW_FEED";
  static REQUEST_GMB_REVIEW_FEED_FINISHED =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_FINISHED";

  static REQUEST_GMB_REVIEW_FEED_FILTER =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_FILTER";
  static REQUEST_GMB_REVIEW_FEED_FILTER_FINISHED =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_FILTER_FINISHED";

  static REQUEST_GMB_REVIEW_FEED_REPLY =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_REPLY";
  static REQUEST_GMB_REVIEW_FEED_REPLY_FINISHED =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_REPLY_FINISHED";

  static REQUEST_GMB_ANSWER_FEED_REPLY =
    "GmbReviewAction.REQUEST_GMB_ANSWER_FEED_REPLY";
  static REQUEST_GMB_ANSWER_FEED_REPLY_FINISHED =
    "GmbReviewAction.REQUEST_GMB_ANSWER_FEED_REPLY_FINISHED";

  static REQUEST_GMB_REVIEW_FEED_BULK_REPLY =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_BULK_REPLY";
  static REQUEST_GMB_REVIEW_FEED_BULK_REPLY_FINISHED =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_BULK_REPLY_FINISHED";

  static REQUEST_PUT_GMB_FEEDS = "GmbReviewAction.REQUEST_PUT_GMB_FEEDS";
  static REQUEST_PUT_GMB_FEEDS_FINISHED =
    "GmbReviewAction.REQUEST_PUT_GMB_FEEDS_FINISHED";

  static REQUEST_GMB_REVIEW_FEED_CLEAR =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_CLEAR";
  static REQUEST_GMB_REVIEW_FEED_CLEAR_FINISHED =
    "GmbReviewAction.REQUEST_GMB_REVIEW_FEED_CLEAR_FINISHED";

  static REQUEST_POST_GMB_REVIEW_FEED =
    "GmbReviewAction.REQUEST_POST_GMB_REVIEW_FEED";
  static REQUEST_POST_GMB_REVIEW_FEED_FINISHED =
    "GmbReviewAction.REQUEST_POST_GMB_REVIEW_FEED_FINISHED";

  // METHODS
  // GMB Feeds GET function
  static requestGmbReviewFeed(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbReviewAction.REQUEST_GMB_REVIEW_FEED,
        GmbReviewEffect.requestGmbReviewFeed,
        data,
        callBackFunction
      );
    };
  }

  static requestGmbReviewFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbReviewAction.REQUEST_GMB_REVIEW_FEED_CLEAR,
        GmbReviewEffect.requestGmbReviewFeedClear
      );
    };
  }

  static requestGmbReviewFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbReviewAction.REQUEST_GMB_REVIEW_FEED_FILTER,
        GmbReviewEffect.requestGmbReviewFeedFilter,
        params
      );
    };
  }

  //Gmb REview reply post method
  static requestGmbReviewReplyPost(id, data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbReviewAction.REQUEST_GMB_REVIEW_FEED_REPLY,
        GmbReviewEffect.requestGmbReviewReplyPost,
        id,
        data,
        callback
      );
    };
  }

  static requestGmbAnswerReplyPost(id, data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbReviewAction.REQUEST_GMB_ANSWER_FEED_REPLY,
        GmbReviewEffect.requestGmbAnswerReplyPost,
        id,
        data,
        callback
      );
    };
  }

  static requestGmbReviewBulkReplyPost(data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbReviewAction.REQUEST_GMB_REVIEW_FEED_BULK_REPLY,
        GmbReviewEffect.requestGmbReviewBulkReplyPost,
        data,
        callback
      );
    };
  }
  static requestPutGMBReviews(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GmbReviewAction.REQUEST_PUT_GMB_FEEDS,
        GmbReviewEffect.requestPutGMBReviews,
        data,
        id
      );
    };
  }
}
