import React, { useState } from "react";
import {
  Box,
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

import { Form, Formik, Field } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../components/muiButton/MuiButton";
import MailTiggerListAction from "../../stores/triggerLIst/MailTiggerListAction";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const TriggerPost = (props) => {
  let initial = {};
  if (props.edit) {
    initial = {
      trigger_id: props.projectEditData.trigger_id,
      trigger_name: props.projectEditData.trigger_name,
      service_name: props.projectEditData.service_name,
    };
  } else {
    initial = {
      trigger_id: "",
      trigger_name: "",
      service_name: "orm",
    };
  }

  const validationSchema = yup.object({
    trigger_name: yup
      .string("Enter Trigger name")
      .trim()
      .min(1, "Trigger name should be of minimum 1 characters length")
      .required("Trigger name is required"),

    service_name: yup
      .string("Enter Service name")
      .trim()
      .min(1, "Service name should be of minimum 1 characters length")
      .required("Service name is required"),
  });
  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};
          //   if (!values.platform.length) {
          //     errors.platform = "Platform Name is Required";
          //   }

          //   return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          const params = {
            order_by: '[["created_on", "desc"]]',
            page_limit: 15,
          };
          const param = {
            order_by: `[["trigger_name.keyword", "asc"]]`,
            page_limit: "none",
          };
          if (props.edit) {
            props
              .dispatch(
                MailTiggerListAction.requestPutTriggerList(
                  JSON.stringify(values),
                  props.projectEditData.trigger_unique_id
                )
              )
              .then(() => {
                props.dispatch(MailTiggerListAction.requestTriggerList(params));
                props.dispatch(
                  MailTiggerListAction.requestTriggerListFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(MailTiggerListAction.requestPostTriggerList(values))
              .then(() => {
                const params = {
                  order_by: '[["created_on", "desc"]]',
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["trigger_name.keyword", "asc"]]`,
                  page_limit: "none",
                };
                props.dispatch(MailTiggerListAction.requestTriggerList(params));
                props.dispatch(
                  MailTiggerListAction.requestTriggerListFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">T</span>
                            rigger &nbsp;
                            <span className="span_first_letter">N</span>ame
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">T</span>
                            rigger &nbsp;
                            <span className="span_first_letter">N</span>ame
                          </b>
                        )}
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div
                        className="col-2 closebtn"
                        onClick={() => {
                          props.onClose();
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Trigger Name * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Trigger Name"
                      id="trigger_name"
                      autoFocus
                      size="small"
                      name="trigger_name"
                      //   disabled={props.edit ? true : false}
                      error={
                        Boolean(errors.trigger_name) &&
                        Boolean(touched.trigger_name)
                      }
                      helperText={
                        Boolean(touched.trigger_name) && errors.trigger_name
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container my={1}>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Service Name * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Service Name"
                      id="service_name"
                      size="small"
                      name="service_name"
                      disabled={true}
                      error={
                        Boolean(errors.service_name) &&
                        Boolean(touched.service_name)
                      }
                      helperText={
                        Boolean(touched.service_name) && errors.service_name
                      }
                    />
                  </Grid>
                </Grid>

                {/* <Grid item lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} mt={3}>
                    Service * :
                  </Grid>
                  <Grid item lg={9} md={9} sm={9}>
                    <Field
                      name="Service"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.organization ? values.organization : null}
                      component={AutoCompleteMultiSelect}
                      // disabled={props.edit || values.category}
                      options={
                        props.organization_array ? props.organization_array : []
                      }
                      disabled={
                        props.organization_array &&
                        props.organization_array.length === 1
                          ? true
                          : false
                      }
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.organization_name,
                      })}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.organization_name === value.organization_name
                      }
                      getOptionLabel={(option) => option.organization_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Organization",
                      }}
                      error={
                        Boolean(errors.organization) &&
                        Boolean(touched.organization)
                      }
                      helperText={
                        Boolean(touched.organization) && errors.organization
                      }
                    />
                  </Grid>
                </Grid> */}
              </Grid>

              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Add Trigger Name"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      // onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(TriggerPost);
