import React from "react";
import "./CircleLoder.css";
import { theme } from "../../../../views/App";

const CircleLoader = ({ width, height }) => {
  return (
    <div
      className="custom-loader"
      style={{
        background: `conic-gradient(#0000 10%, ${theme.palette.primary.main})`,
        width: width ?? "50px",
        height: height ?? "50px",
      }}
    ></div>
  );
};

export default CircleLoader;
