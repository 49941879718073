import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../../../../views/App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import RestoreIcon from "@mui/icons-material/Restore";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import DateDurationPopper from "../allTickets/filterPoppers/DateDurationPopper";
import TicketStatePopper from "../allTickets/filterPoppers/TicketStatePopper";
import CategoryFilterPopper from "../allTickets/filterPoppers/CategoryFilterPopper";
import PlatformFilterPopper from "../allTickets/filterPoppers/PlatformFilterPopper";
import SentimentFilterPopper from "../allTickets/filterPoppers/SentimentFilterPopper";
import AgentProfilePopper from "../allTickets/filterPoppers/AgentProfilePopper";
import TicketCountAction from "../../../stores/ticketCount/TicketCountAction";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { format } from "date-fns";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { connect } from "react-redux";
import TagFilterPopper from "../allTickets/filterPoppers/TagFilterPopper";
import RatingsFilterPopper from "../allTickets/filterPoppers/RatingsFilterPopper";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TicketCountAction.REQUEST_TICKET_COUNT,
  ]),
  ticketCount: state.ticketCount.ticketCount || [],
});

const ListViewFilters = (props) => {
  const [showThirdRow, setShowThirdRow] = React.useState(false);
  let ticketCountFilterRef = useRef();

  // DATE value handle change
  const today = new Date();
  const ratingsEnabledPlatforms = ["GMB", "Playstore", "Appstore"];

  // ***************************************
  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);

  //Last 2 days filter
  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  // start from today's date last 7 days
  const last7Days = [...Array(7).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date;
  });

  // last 30 days
  let date2 = new Date();
  let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  // ***************************************
  // last 3 months
  let date3 = new Date();
  let last3Month = date3.setMonth(date3.getMonth() - 3);

  function fetchTicketCount() {
    ticketCountFilterRef.current = [];

    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

    if (filters) {
      // filters.priority &&
      //   ticketCountFilterRef.current.push(
      //     `["priority.priority_id.keyword","must","","terms",[${JSON.stringify(
      //       filters.priority.priority_id
      //     )}]]`
      //   );
      filters.platform &&
        ticketCountFilterRef.current.push(
          `["platform.keyword","must","","match","${filters.platform.platform_id}"]`
        );
      filters.activities &&
        filters.activities.length !== 0 &&
        ticketCountFilterRef.current.push(
          `["activities.keyword","must","","terms",${JSON.stringify(
            filters.activities.map((item) => {
              return item.activity_id;
            })
          )}]`
        );
      filters.assigned_To &&
        ticketCountFilterRef.current.push(
          `["assigned_to.user_name.keyword","must","","term","${filters.assigned_To.user_name}"]`
        );

      filters.unAssigned &&
        ticketCountFilterRef.current.push(
          // `["assigned_to","must_not","","exists",""]`
          `["assigned_to.user_name.keyword","must","","term","Unassigned"]`
        );

      if (filters.createdDatelistSelected) {
        filters.createdDatelistSelected === "Today" &&
          ticketCountFilterRef.current.push(
            `["created_on","must","lte","range","${format(
              new Date(),
              "yyyy-MM-dd"
            )}"],["created_on","must","gte","range","${format(
              today,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.createdDatelistSelected === "Previous Day" &&
          ticketCountFilterRef.current.push(
            `["created_on","must","lte","range","${format(
              previousday,
              "yyyy-MM-dd"
            )}"],["created_on","must","gte","range","${format(
              previousday,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.createdDatelistSelected === "Last 2 Days" &&
          ticketCountFilterRef.current.push(
            `["created_on","must","lte","range","${format(
              last2days[0],
              "yyyy-MM-dd"
            )}"],["created_on","must","gte","range","${format(
              last2days[1],
              "yyyy-MM-dd"
            )}"]`
          );

        filters.createdDatelistSelected === "Last 7 Days" &&
          ticketCountFilterRef.current.push(
            `["created_on","must","lte","range","${format(
              last7Days[0],
              "yyyy-MM-dd"
            )}"],["created_on","must","gte","range","${format(
              last7Days[6],
              "yyyy-MM-dd"
            )}"]`
          );

        filters.createdDatelistSelected === "Last 30 Days" &&
          ticketCountFilterRef.current.push(
            `["created_on","must","lte","range","${format(
              today,
              "yyyy-MM-dd"
            )}"],["created_on","must","gte","range","${format(
              last30Days,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.createdDatelistSelected === "Last 3 Months" &&
          ticketCountFilterRef.current.push(
            `["created_on","must","lte","range","${format(
              today,
              "yyyy-MM-dd"
            )}"],["created_on","must","gte","range","${format(
              last3Month,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.createdDatelistSelected === "Custom" &&
          filters.createdDate &&
          ticketCountFilterRef.current.push(
            `["created_on","must","lte","range","${format(
              new Date(filters.createdDate[1]),
              "yyyy-MM-dd"
            )}"],["created_on","must","gte","range","${format(
              new Date(filters.createdDate[0]),
              "yyyy-MM-dd"
            )}"]`
          );
      }

      if (filters.modifiedDatelistSelected) {
        filters.modifiedDatelistSelected === "Today" &&
          ticketCountFilterRef.current.push(
            `["modified_on","must","lte","range","${format(
              new Date(),
              "yyyy-MM-dd"
            )}"],["modified_on","must","gte","range","${format(
              today,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.modifiedDatelistSelected === "Previous Day" &&
          ticketCountFilterRef.current.push(
            `["modified_on","must","lte","range","${format(
              previousday,
              "yyyy-MM-dd"
            )}"],["modified_on","must","gte","range","${format(
              previousday,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.modifiedDatelistSelected === "Last 2 Days" &&
          ticketCountFilterRef.current.push(
            `["modified_on","must","lte","range","${format(
              last2days[0],
              "yyyy-MM-dd"
            )}"],["modified_on","must","gte","range","${format(
              last2days[1],
              "yyyy-MM-dd"
            )}"]`
          );

        filters.modifiedDatelistSelected === "Last 7 Days" &&
          ticketCountFilterRef.current.push(
            `["modified_on","must","lte","range","${format(
              last7Days[0],
              "yyyy-MM-dd"
            )}"],["modified_on","must","gte","range","${format(
              last7Days[6],
              "yyyy-MM-dd"
            )}"]`
          );

        filters.modifiedDatelistSelected === "Last 30 Days" &&
          ticketCountFilterRef.current.push(
            `["modified_on","must","lte","range","${format(
              today,
              "yyyy-MM-dd"
            )}"],["modified_on","must","gte","range","${format(
              last30Days,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.modifiedDatelistSelected === "Last 3 Months" &&
          ticketCountFilterRef.current.push(
            `["modified_on","must","lte","range","${format(
              today,
              "yyyy-MM-dd"
            )}"],["modified_on","must","gte","range","${format(
              last3Month,
              "yyyy-MM-dd"
            )}"]`
          );

        filters.modifiedDatelistSelected === "Custom" &&
          filters.modifiedDate &&
          ticketCountFilterRef.current.push(
            `["modified_on","must","lte","range","${format(
              new Date(filters.modifiedDate[1]),
              "yyyy-MM-dd"
            )}"],["modified_on","must","gte","range","${format(
              new Date(filters.modifiedDate[0]),
              "yyyy-MM-dd"
            )}"]`
          );
      }

      filters.category &&
        !!filters.category.length &&
        ticketCountFilterRef.current.push(
          `["category.category_name.keyword","must","","term","${filters.category}"]`
        );

      if (filters.searchTypeSelected) {
        filters.searchTypeSelected === "Ticket-ID" &&
          ticketCountFilterRef.current.push(
            `["_id","must","","terms",["${filters.searchQuery}"]]`
          );

        filters.searchTypeSelected === "Content" &&
          ticketCountFilterRef.current.push(
            `["ticket_desc.keyword","must","","wildcard","*${filters.searchQuery}*"]`
          );
      }
      if (filters.sentiment_value) {
        filters.sentiment_value === "Negative" &&
          ticketCountFilterRef.current.push(
            `["sentiment_value","must","lte","range","-0.01"]`
          );

        filters.sentiment_value === "Positive" &&
          ticketCountFilterRef.current.push(
            `["sentiment_value","must","gte","range","0.01"]`
          );

        filters.sentiment_value === "Neutral" &&
          ticketCountFilterRef.current.push(
            `["sentiment_value","must","","match","0"]`
          );
      }

      if (filters.tags && filters.tags.length > 0) {
        ticketCountFilterRef.current.push(
          `["tags","must","nested","",["tag_name.keyword","must","","terms",${JSON.stringify(
            filters.tags
          )}]]`
        );
      }
    }

    let params = {
      filters: `[${ticketCountFilterRef.current}]`,
    };

    ticketCountFilterRef.current &&
      props.dispatch(TicketCountAction.requestTicketCount(params));
  }

  // Tickets Count Data initialise
  let TicketCountData = [];

  if (
    props.ticketCount &&
    props.ticketCount.data &&
    props.ticketCount.data.length !== 0
  ) {
    TicketCountData = [...props.ticketCount.data];
  }

  function returnTicketStatusCount(statusId) {
    return TicketCountData.length > 0 &&
      TicketCountData.filter((item) => item.key === statusId).length > 0
      ? TicketCountData.filter((item) => item.key === statusId).map(
          (item) => item.count
        )
      : 0;
  }

  // Status List assign for count display

  let Initial_status = [];
  let AdditionalStatusList = [];
  let Final_status = [];

  let InitialStatusid = "";
  let FinalStatusId = "";

  if (props.statusList.length > 0) {
    let initial_status = props.statusList.filter((item) => {
      return item.initial_status === true;
    });

    initial_status.length > 0 && Initial_status.push(initial_status[0]);
    InitialStatusid = Initial_status.length > 0 && Initial_status[0].status_id;

    let final_status = props.statusList.filter((item) => {
      return item.final_status === true;
    });

    final_status.length > 0 && Final_status.push(final_status[0]);
    FinalStatusId = Final_status.length > 0 && Final_status[0].status_id;

    AdditionalStatusList = props.statusList.filter(
      (item) =>
        item.status_id !== FinalStatusId && item.status_id !== InitialStatusid
    );
  }

  let [open, setOpen] = useState(false);

  const handleOpenClose = (ind) => {
    setOpen(!open);
  };

  const [value, setValue] = React.useState(0);

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setDatePopOpen(true);
    }
    if (newValue === 1) {
      setStatePopOpen(true);
    }
    // if (newValue === 2) {
    //   setCategoryPopOpen(true);
    // }
    if (newValue === 3) {
      setCategoryPopOpen(true);
    }
    if (newValue === 4) {
      setAgentPopOpen(true);
    }
  };

  //date duration popper
  const [datePopOpen, setDatePopOpen] = React.useState(false);
  const datePopperRef = React.useRef(null);
  const datePrevOpen = React.useRef(datePopOpen);

  const handleDatePopper = () => {
    setDatePopOpen((datePrevOpen) => !datePrevOpen);
  };

  const dateHandleClose = (event) => {
    if (datePopperRef.current && datePopperRef.current.contains(event.target)) {
      return;
    }

    setDatePopOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    setShowThirdRow(false);
    if (datePrevOpen.current === true && open === false) {
      datePopperRef.current && datePopperRef.current.focus();
    }

    datePrevOpen.current = open;
  }, [open]);

  // ticket state popper
  const [statePopOpen, setStatePopOpen] = React.useState(false);
  const statePopperRef = React.useRef(null);
  const stateScrollRef = useRef(null);
  const statePrevOpen = React.useRef(statePopOpen);

  const handleStatePopper = () => {
    setStatePopOpen((statePrevOpen) => !statePrevOpen);
  };

  const stateHandleClose = (event) => {
    if (
      statePopperRef.current &&
      statePopperRef.current.contains(event.target)
    ) {
      return;
    }

    setStatePopOpen(false);
  };

  // Category popper

  const [categoryPopOpen, setCategoryPopOpen] = React.useState(false);
  const categoryPopperRef = React.useRef(null);
  const categoryScrollRef = useRef(null);
  const categoryPrevOpen = React.useRef(categoryPopOpen);

  const handleCategoryPopper = () => {
    setCategoryPopOpen((categoryPrevOpen) => !categoryPrevOpen);
  };

  const categoryHandleClose = (event) => {
    if (
      categoryPopperRef.current &&
      categoryPopperRef.current.contains(event.target)
    ) {
      return;
    }

    setCategoryPopOpen(false);
  };

  // AgentProfile popper

  const [agentPopOpen, setAgentPopOpen] = React.useState(false);
  const agentPopperRef = React.useRef(null);
  const agentPrevOpen = React.useRef(open);

  const handleAgentPopper = () => {
    setAgentPopOpen((agentPrevOpen) => !agentPrevOpen);
  };

  const agentHandleClose = (event) => {
    if (
      agentPopperRef.current &&
      agentPopperRef.current.contains(event.target)
    ) {
      return;
    }

    setAgentPopOpen(false);
  };

  // platform popper

  const [platformOpen, setPlatformOpen] = React.useState(false);
  const platformPopperRef = React.useRef(null);
  const platformScrollRef = useRef(null);
  const platformPrevOpen = React.useRef(open);

  const handlePlatformPopper = () => {
    setPlatformOpen((platformPrevOpen) => !platformPrevOpen);
  };

  const platformHandleClose = (event) => {
    if (
      platformPopperRef.current &&
      platformPopperRef.current.contains(event.target)
    ) {
      return;
    }

    setPlatformOpen(false);
  };

  // Sentiment popper

  const [sentimentPopOpen, setSentimentPopOpen] = React.useState(false);
  const sentimentPopperRef = React.useRef(null);
  const sentimentPrevOpen = React.useRef(open);

  const handleSentimentPopper = () => {
    setSentimentPopOpen((sentimentPrevOpen) => !sentimentPrevOpen);
  };

  const sentimentHandleClose = (event) => {
    if (
      sentimentPopperRef.current &&
      sentimentPopperRef.current.contains(event.target)
    ) {
      return;
    }

    setSentimentPopOpen(false);
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes scrollInFade {
            0% {
              opacity: 0;
              transform: translateY(50%); /* Start from bottom */
            }
            100% {
              opacity: 1;
              transform: translateY(0); /* Scroll to top */
            }
          }
          }`,
        }}
      />
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth
        maxWidth="lg"
        TransitionComponent={Zoom}
        transitionDuration={350}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            boxShadow: "8px 8px 8px rgba(0, 0, 0, 0.4)",
          },
        }}

        //   className="bg-warning"
      >
        <DialogContent
          className=" p-0 m-0"
          sx={{
            border: "2px solid #c0bfbf",
            // maxHeight: 400,
          }}
        >
          <DialogContentText
          //   className="bg-warning p-0 m-0"
          >
            <Box
              // className="bg-warning"
              sx={{
                borderBottom: "1px solid #c0bfbf",
              }}
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                // className="bg-warning"
              >
                <Grid item px={1} sx={{ display: "flex" }}>
                  <Typography>
                    <FontAwesomeIcon
                      icon={faFilter}
                      style={{
                        color: `${theme.palette.ticketListView.iconColor}`,
                      }}
                    ></FontAwesomeIcon>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>F</span>
                    <span>ilter</span>
                  </Typography>
                </Grid>
                <Grid item px={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                      setOpen(false);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{
                        // color: `${theme.palette.ticketListView.iconColor}`,
                        fontSize: "20px",
                      }}
                    ></FontAwesomeIcon>
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                {!open && (
                  <Grid
                    item
                    container
                    xl={9}
                    lg={9}
                    md={9}
                    sm={9}
                    xs={9}
                    alignItems="center"
                    justifyContent={"center"}
                    textAlign="center"
                    // className="bg-warning"
                    sx={{
                      borderRight: "6px solid #f3f9fb",
                    }}
                  >
                    {showThirdRow && (
                      <KeyboardArrowUpIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowThirdRow(false);
                        }}
                      />
                    )}
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent={"center"}
                      //pt={5}
                      //pb={10}
                    >
                      {!showThirdRow && (
                        <Grid
                          item
                          container
                          xl={10}
                          lg={10}
                          md={10}
                          sm={10}
                          xs={10}
                          justifyContent={"space-between"}
                          alignItems="flex-start"
                          sx={{
                            animation: "scrollInFade 0.3s ease-in-out",
                          }}
                        >
                          <Grid
                            item
                            container
                            // className="bg-success"
                            sx={{
                              borderRadius: "9px",
                              boxShadow:
                                "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                              border: "solid 0.5px #86aeb6",
                              height: 160,
                              width: 160,
                              cursor: "pointer",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                            }}
                            textAlign="center"
                            justifyContent={"center"}
                            alignItems="center"
                            // className="bg-warning"
                            onClick={() => {
                              handleOpenClose(0);
                              setValue(0);
                            }}
                          >
                            <Grid>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"

                                // className="bg-light"
                              >
                                <RestoreIcon
                                  style={{
                                    fontSize: "40px",
                                    color: "#92b9c4",
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                                px={0.5}
                                mt={0.5}
                                // className="bg-light"
                              >
                                <span
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "20px",
                                  }}
                                >
                                  Date Duration
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            // className="bg-info"
                            sx={{
                              borderRadius: "9px",
                              boxShadow:
                                "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                              border: "solid 0.5px #86aeb6",
                              cursor: "pointer",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                              height: 160,
                              width: 160,
                            }}
                            textAlign="center"
                            justifyContent={"center"}
                            alignItems="center"
                            onClick={() => {
                              handleOpenClose(1);
                              setValue(1);
                            }}
                          >
                            <Grid>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                                // className="bg-light"
                              >
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "35px",
                                    color: "#92b9c4",
                                  }}
                                  //   className="bg-warning"
                                  icon={faTicket}
                                ></FontAwesomeIcon>
                              </Grid>

                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                                px={0.5}
                                mt={0.5}
                                // className="bg-light"
                              >
                                <span
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "20px",
                                  }}
                                >
                                  Ticket Priority
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            // className="bg-primary"
                            sx={{
                              borderRadius: "9px",
                              boxShadow:
                                "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                              border: "solid 0.5px #86aeb6",
                              cursor: "pointer",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                              height: 160,
                              width: 160,
                            }}
                            textAlign="center"
                            justifyContent={"center"}
                            alignItems="center"
                            onClick={() => {
                              handleOpenClose(2);
                              setValue(2);
                            }}
                          >
                            <Grid>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                                // className="bg-light"
                              >
                                <CallSplitIcon
                                  style={{
                                    fontSize: "40px",
                                    color: "#92b9c4",
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                                px={0.5}
                                mt={0.5}
                                // className="bg-light"
                              >
                                <span
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "20px",
                                  }}
                                >
                                  Platforms
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        item
                        container
                        xl={10}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                        justifyContent={"space-between"}
                        alignItems="flex-start"
                        mt={!showThirdRow ? 10 : 0}
                        sx={{
                          animation: "scrollInFade 0.5s ease-in-out",
                        }}
                      >
                        <Grid
                          item
                          container
                          sx={{
                            borderRadius: "9px",
                            boxShadow:
                              "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                            border: "solid 0.5px #86aeb6",
                            cursor: "pointer",
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                            height: 160,
                            width: 160,
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            handleOpenClose(3);
                            setValue(3);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              // className="bg-light"
                            >
                              <FontAwesomeIcon
                                style={{ fontSize: "35px", color: "#92b9c4" }}
                                //   className="bg-warning"
                                icon={faFileLines}
                              ></FontAwesomeIcon>
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              px={0.5}
                              mt={0.5}
                              // className="bg-light"
                            >
                              <span
                                className="p-0 m-0"
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                Category
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          // className="bg-info"
                          sx={{
                            borderRadius: "9px",
                            boxShadow:
                              "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                            border: "solid 0.5px #86aeb6",
                            cursor: "pointer",
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                            height: 160,
                            width: 160,
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            handleOpenClose(4);
                            setValue(4);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              // className="bg-light"
                            >
                              <SupervisorAccountIcon
                                style={{ fontSize: "40px", color: "#92b9c4" }}
                              />
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              px={0.5}
                              mt={0.5}
                              // className="bg-light"
                            >
                              <span
                                className="p-0 m-0"
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                Agent Profile
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          // className="bg-primary"
                          sx={{
                            borderRadius: "9px",
                            boxShadow:
                              "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                            border: "solid 0.5px #86aeb6",
                            cursor: "pointer",
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                            height: 160,
                            width: 160,
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            handleOpenClose(5);
                            setValue(5);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              // className="bg-light"
                            >
                              <SentimentSatisfiedAltIcon
                                style={{ fontSize: "40px", color: "#92b9c4" }}
                              />
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              px={0.5}
                              mt={0.5}
                              // className="bg-light"
                            >
                              <span
                                className="p-0 m-0"
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                Revised Sentiment
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {showThirdRow && (
                        <Grid
                          item
                          container
                          xl={10}
                          lg={10}
                          md={10}
                          sm={10}
                          xs={10}
                          justifyContent={"space-between"}
                          alignItems="flex-start"
                          mt={10}
                        >
                          <Grid
                            item
                            container
                            sx={{
                              borderRadius: "9px",
                              boxShadow:
                                "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                              border: "solid 0.5px #86aeb6",
                              cursor: "pointer",
                              height: 160,
                              width: 160,
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                            }}
                            textAlign="center"
                            justifyContent={"center"}
                            alignItems="center"
                            onClick={() => {
                              handleOpenClose(6);
                              setValue(6);
                            }}
                          >
                            <Grid>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                              >
                                <LocalOfferIcon
                                  style={{
                                    fontSize: "40px",
                                    color: "#92b9c4",
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                                px={0.5}
                                mt={0.5}
                              >
                                <span
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "20px",
                                  }}
                                >
                                  Tags
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>

                          {localStorage.getItem("mentionsFilters") &&
                            JSON.parse(localStorage.getItem("mentionsFilters"))
                              .platform !== null && (
                              <Grid
                                item
                                container
                                sx={{
                                  borderRadius: "9px",
                                  boxShadow:
                                    "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                  border: "solid 0.5px #86aeb6",
                                  cursor: "pointer",
                                  height: 160,
                                  width: 160,
                                  "&:hover": {
                                    transform: "scale(1.05)",
                                  },
                                }}
                                textAlign="center"
                                justifyContent={"center"}
                                alignItems="center"
                                onClick={() => {
                                  handleOpenClose(7);
                                  setValue(7);
                                }}
                              >
                                <Grid>
                                  <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    textAlign="center"
                                  >
                                    <LocalOfferIcon
                                      style={{
                                        fontSize: "40px",
                                        color: "#92b9c4",
                                      }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    textAlign="center"
                                    px={0.5}
                                    mt={0.5}
                                  >
                                    <span
                                      className="p-0 m-0"
                                      style={{
                                        fontSize: "20px",
                                      }}
                                    >
                                      Ratings
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                        </Grid>
                      )}
                    </Grid>
                    {!showThirdRow && (
                      <KeyboardArrowDownIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowThirdRow(true);
                        }}
                      />
                    )}
                  </Grid>
                )}

                {open && (
                  <Grid
                    item
                    container
                    xl={9}
                    lg={9}
                    md={9}
                    sm={9}
                    xs={9}
                    alignItems="flex-start"
                    justifyContent={"center"}
                    textAlign="center"
                    // className="bg-success"
                    sx={{
                      borderRight: "6px solid #f3f9fb",
                    }}
                  >
                    <Box width={"100%"}>
                      <Box
                        width={"100%"}
                        // className="bg-success"
                        my={0.5}
                      >
                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          alignItems="center"
                          justifyContent={"space-between"}
                          // className="bg-success"
                          // gap={1}
                          px={0.5}
                        >
                          <Tabs
                            indicatorColor="transparent"
                            value={value}
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={tabHandleChange}
                            aria-label="scrollable auto tabs"
                            style={{
                              // minHeight: "30vh",
                              minWidth: "100%",
                            }}
                            // className="bg-warning"
                            sx={{
                              "& .MuiTabs-indicatorSpan": {
                                maxWidth: 20,
                                width: "80%",
                                backgroundColor: "#2a5e6c",
                              },
                              minWidth: "100%",

                              "& .MuiTabs-flexContainer": {
                                position: "relative",
                                // height: "1vh",
                                margin: 0, // remove margin from Tabs container
                                padding: 0, // remove padding from Tabs container
                                // height: 40,
                                // position: "relative",
                                // justifyContent: "flex-start",
                                // "&::before": {
                                //   content: '""',
                                //   position: "absolute",
                                //   bottom: "-5px",
                                //   left: 0,
                                //   width: "calc(100% + 10px)",
                                //   height: "5px",
                                //   backgroundColor: "#2196f3",
                                //   borderRadius: "0 0 0 10px",
                                //   zIndex: 0,
                                // },
                              },
                              "& .MuiTab-root": {
                                zIndex: 1,
                                color: "#2a5e6c",
                                border: `1px solid transparent`,
                                backgroundColor: "transparent",
                                // backgroundColor: "#003a4c",
                                // height: "1vh",
                                opacity: 0.3,
                                "&.Mui-selected": {
                                  zIndex: 2,
                                  //   backgroundColor: "#003a4c",
                                  color: `#2a5e6c`,
                                  opacity: 1,
                                },
                              },
                            }}
                          >
                            <Tab
                              label={
                                <Typography
                                  component={"div"}
                                  ref={datePopperRef}
                                  id="composition-button"
                                  aria-controls={
                                    open ? "composition-menu" : undefined
                                  }
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={handleDatePopper}
                                  style={{ backgroundColor: "none" }}
                                >
                                  <Grid
                                    item
                                    container
                                    // xl={1.8}
                                    // lg={2}
                                    // md={2}
                                    // sm={2}
                                    // xs={2}
                                    alignItems="center"
                                    justifyContent={"center"}
                                    className="bg-light"
                                    // onClick={() => {
                                    //   handleCardOpen(0);
                                    // }}
                                    sx={{
                                      width: "126px",
                                      height: "126px",
                                      boxShadow:
                                        "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                      border: "solid 0.5px #86aeb6",
                                      cursor: "pointer",
                                      // opacity: `${opacity_array[0]}`,
                                    }}
                                  >
                                    <Grid>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        // className="bg-light"
                                      >
                                        <RestoreIcon
                                          style={{
                                            fontSize: "40px",
                                            color: "#92b9c4",
                                          }}
                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        px={0.5}
                                        mt={0.5}
                                        // className="bg-light"
                                      >
                                        <span
                                          className="p-0 m-0"
                                          style={{
                                            fontSize: "20px",
                                          }}
                                        >
                                          Date Duration
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Typography>
                              }
                              style={{
                                textTransform: "none",
                                minHeight: 0,
                              }}
                              //   {...a11yProps(0)}
                            />
                            <Tab
                              style={{
                                textTransform: "none",
                                minHeight: 0,
                              }}
                              label={
                                <Typography
                                  component={"div"}
                                  ref={statePopperRef}
                                  id="composition-button"
                                  aria-controls={
                                    open ? "composition-menu" : undefined
                                  }
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={handleStatePopper}
                                  style={{ backgroundColor: "none" }}
                                >
                                  <Grid
                                    item
                                    container
                                    // xl={1.8}
                                    // lg={2}
                                    // md={2}
                                    // sm={2}
                                    // xs={2}
                                    alignItems="center"
                                    justifyContent={"center"}
                                    className="bg-light"
                                    sx={{
                                      width: "126px",
                                      height: "120px",
                                      boxShadow:
                                        "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                      border: "solid 0.5px #86aeb6",
                                      cursor: "pointer",
                                      // opacity: `${opacity_array[1]}`,
                                    }}
                                  >
                                    <Grid>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        // className="bg-light"
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "35px",
                                            color: "#92b9c4",
                                          }}
                                          //   className="bg-warning"
                                          icon={faTicket}
                                        ></FontAwesomeIcon>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        px={0.5}
                                        mt={0.5}
                                        // className="bg-light"
                                      >
                                        <span
                                          className="p-0 m-0"
                                          style={{
                                            fontSize: "20px",
                                          }}
                                        >
                                          Ticket Priority
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Typography>
                              }

                              //   {...a11yProps(1)}
                            />
                            <Tab
                              style={{
                                textTransform: "none",
                                minHeight: 0,
                              }}
                              label={
                                <Grid
                                  item
                                  container
                                  // xl={2}
                                  // lg={2}
                                  // md={2}
                                  // sm={2}
                                  // xs={2}
                                  alignItems="center"
                                  justifyContent={"center"}
                                  className="bg-light"
                                  sx={{
                                    width: "126px",
                                    height: "120px",
                                    boxShadow:
                                      "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                    border: "solid 0.5px #86aeb6",
                                    cursor: "pointer",
                                    // opacity: opacity_array[2],
                                  }}
                                >
                                  <Grid>
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      textAlign="center"
                                      // className="bg-light"
                                    >
                                      <CallSplitIcon
                                        style={{
                                          fontSize: "40px",
                                          color: "#92b9c4",
                                        }}
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      textAlign="center"
                                      px={0.5}
                                      mt={0.5}
                                      // className="bg-light"
                                    >
                                      <span
                                        className="p-0 m-0"
                                        style={{
                                          fontSize: "20px",
                                        }}
                                      >
                                        Platforms
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              }

                              //   {...a11yProps(1)}
                            />
                            <Tab
                              style={{
                                textTransform: "none",
                                minHeight: 0,
                              }}
                              label={
                                <Grid
                                  item
                                  container
                                  // xl={2}
                                  // lg={2}
                                  // md={2}
                                  // sm={2}
                                  // xs={2}
                                  alignItems="center"
                                  justifyContent={"center"}
                                  className="bg-light"
                                  sx={{
                                    width: "126px",
                                    height: "120px",
                                    boxShadow:
                                      "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                    border: "solid 0.5px #86aeb6",
                                    cursor: "pointer",
                                    // opacity: opacity_array[3],
                                  }}
                                >
                                  <Typography
                                    component={"div"}
                                    ref={categoryPopperRef}
                                    id="composition-button"
                                    aria-controls={
                                      categoryPopOpen
                                        ? "composition-menu"
                                        : undefined
                                    }
                                    aria-expanded={
                                      categoryPopOpen ? "true" : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={handleCategoryPopper}
                                    style={{ backgroundColor: "none" }}
                                  >
                                    <Grid>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        // className="bg-light"
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "35px",
                                            color: "#92b9c4",
                                          }}
                                          //   className="bg-warning"
                                          icon={faFileLines}
                                        ></FontAwesomeIcon>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        px={0.5}
                                        mt={0.5}
                                        // className="bg-light"
                                      >
                                        <span
                                          className="p-0 m-0"
                                          style={{
                                            fontSize: "20px",
                                          }}
                                        >
                                          Category
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                </Grid>
                              }

                              //   {...a11yProps(1)}
                            />
                            <Tab
                              style={{
                                textTransform: "none",
                                minHeight: 0,
                              }}
                              label={
                                <Typography
                                  component={"div"}
                                  ref={agentPopperRef}
                                  id="composition-button"
                                  aria-controls={
                                    open ? "composition-menu" : undefined
                                  }
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={handleAgentPopper}
                                  style={{ backgroundColor: "none" }}
                                >
                                  <Grid
                                    item
                                    container
                                    // xl={2}
                                    // lg={2}
                                    // md={2}
                                    // sm={2}
                                    // xs={2}
                                    alignItems="center"
                                    justifyContent={"center"}
                                    className="bg-light"
                                    sx={{
                                      width: "126px",
                                      height: "120px",
                                      boxShadow:
                                        "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                      border: "solid 0.5px #86aeb6",
                                      cursor: "pointer",
                                      // opacity: opacity_array[4],
                                    }}
                                  >
                                    <Grid>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        // className="bg-light"
                                      >
                                        <SupervisorAccountIcon
                                          style={{
                                            fontSize: "40px",
                                            color: "#92b9c4",
                                          }}
                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        textAlign="center"
                                        px={0.5}
                                        mt={0.5}
                                        // className="bg-light"
                                      >
                                        <span
                                          className="p-0 m-0"
                                          style={{
                                            fontSize: "20px",
                                          }}
                                        >
                                          Agent Profile
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Typography>
                              }

                              //   {...a11yProps(2)}
                            />
                            <Tab
                              style={{
                                textTransform: "none",
                                minHeight: 0,
                              }}
                              label={
                                <Grid
                                  item
                                  container
                                  // xl={2}
                                  // lg={2}
                                  // md={2}
                                  // sm={2}
                                  // xs={2}
                                  alignItems="center"
                                  justifyContent={"center"}
                                  className="bg-light"
                                  sx={{
                                    width: "126px",
                                    height: "120px",
                                    boxShadow:
                                      "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                    border: "solid 0.5px #86aeb6",
                                    cursor: "pointer",
                                    // opacity: opacity_array[5],
                                  }}
                                >
                                  <Grid>
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      textAlign="center"
                                      // className="bg-light"
                                    >
                                      <SentimentSatisfiedAltIcon
                                        style={{
                                          fontSize: "40px",
                                          color: "#92b9c4",
                                        }}
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      textAlign="center"
                                      px={0.5}
                                      mt={0.5}
                                      // className="bg-light"
                                    >
                                      <span
                                        className="p-0 m-0"
                                        style={{
                                          fontSize: "20px",
                                        }}
                                      >
                                        Revised Sentiment
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              }

                              //   {...a11yProps(2)}
                            />
                            <Tab
                              style={{
                                textTransform: "none",
                                minHeight: 0,
                              }}
                              label={
                                <Grid
                                  item
                                  container
                                  alignItems="center"
                                  justifyContent={"center"}
                                  className="bg-light"
                                  sx={{
                                    width: "126px",
                                    height: "120px",
                                    boxShadow:
                                      "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                    border: "solid 0.5px #86aeb6",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Grid>
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      textAlign="center"
                                    >
                                      <LocalOfferIcon
                                        style={{
                                          fontSize: "40px",
                                          color: "#92b9c4",
                                        }}
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      textAlign="center"
                                      px={0.5}
                                      mt={0.5}
                                    >
                                      <span
                                        className="p-0 m-0"
                                        style={{
                                          fontSize: "20px",
                                        }}
                                      >
                                        Tags
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              }
                            />

                            {localStorage.getItem("mentionsFilters") &&
                              JSON.parse(
                                localStorage.getItem("mentionsFilters")
                              ).platform !== null && (
                                <Tab
                                  style={{
                                    textTransform: "none",
                                    minHeight: 0,
                                  }}
                                  label={
                                    <Grid
                                      item
                                      container
                                      alignItems="center"
                                      justifyContent={"center"}
                                      className="bg-light"
                                      sx={{
                                        width: "126px",
                                        height: "120px",
                                        boxShadow:
                                          "0 3px 6px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
                                        border: "solid 0.5px #86aeb6",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Grid>
                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          textAlign="center"
                                        >
                                          <LocalOfferIcon
                                            style={{
                                              fontSize: "40px",
                                              color: "#92b9c4",
                                            }}
                                          />
                                        </Grid>

                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          textAlign="center"
                                          px={0.5}
                                          mt={0.5}
                                        >
                                          <span
                                            className="p-0 m-0"
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          >
                                            Ratings
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              )}
                          </Tabs>
                        </Grid>
                      </Box>
                      <Box width={"100%"} position={"relative"} px={1}>
                        {value === 0 && (
                          <DateDurationPopper
                            dateSelecte={props.dateSelecte}
                            dateSelected={props.dateSelected}
                            popopen={datePopOpen}
                            statePopperRef={datePopperRef.current}
                            prevOpen={datePrevOpen}
                            setOpen={setDatePopOpen}
                            handleClose={dateHandleClose}
                            onClose={props.onClose}
                            saveFilter={props.saveFilter}
                            clearFilter={props.clearFilter}
                            fetchTicketCount={fetchTicketCount}
                          />
                        )}

                        {value === 1 && (
                          <TicketStatePopper
                            open={props.open}
                            clearFilter={props.clearFilter}
                            popopen={statePopOpen}
                            statePopperRef={statePopperRef.current}
                            prevOpen={statePrevOpen}
                            setOpen={setStatePopOpen}
                            handleClose={stateHandleClose}
                            scrollRef={stateScrollRef}
                            priority_list={props.priority_list}
                            statusList={props.statusList}
                            onClose={props.onClose}
                            saveFilter={props.saveFilter}
                            fetchTicketCount={fetchTicketCount}
                          />
                        )}

                        {value === 2 && (
                          <PlatformFilterPopper
                            open={props.open}
                            popopen={platformOpen}
                            statePopperRef={platformPopperRef.current}
                            prevOpen={platformPrevOpen}
                            setOpen={setPlatformOpen}
                            handleClose={platformHandleClose}
                            scrollRef={platformScrollRef}
                            onClose={props.onClose}
                            saveFilter={props.saveFilter}
                            clearFilter={props.clearFilter}
                            filteredPlatformList={props.filteredPlatformList}
                            fetchTicketCount={fetchTicketCount}
                          />
                        )}

                        {value === 3 && (
                          <CategoryFilterPopper
                            open={props.open}
                            setFilterOpen={props.setFilterOpen}
                            popopen={categoryPopOpen}
                            statePopperRef={categoryPopperRef.current}
                            prevOpen={categoryPrevOpen}
                            setOpen={setCategoryPopOpen}
                            handleClose={categoryHandleClose}
                            scrollRef={categoryScrollRef}
                            onClose={props.onClose}
                            saveFilter={props.saveFilter}
                            clearFilter={props.clearFilter}
                            filterdCategoryList={props.filterdCategoryList}
                            fetchTicketCount={fetchTicketCount}
                            customticketfields={props.customticketfields}
                            customticketfields_filter={
                              props.customticketfields_filter
                            }
                          />
                        )}

                        {value === 4 && (
                          <AgentProfilePopper
                            open={props.open}
                            popopen={agentPopOpen}
                            statePopperRef={agentPopperRef.current}
                            prevOpen={agentPrevOpen}
                            setOpen={setAgentPopOpen}
                            handleClose={agentHandleClose}
                            userFilterdList={props.userFilterdList}
                            onClose={props.onClose}
                            saveFilter={props.saveFilter}
                            clearFilter={props.clearFilter}
                            fetchTicketCount={fetchTicketCount}
                            // scrollRef={categoryScrollRef}
                          />
                        )}

                        {value === 5 && (
                          <SentimentFilterPopper
                            open={props.open}
                            popopen={sentimentPopOpen}
                            statePopperRef={sentimentPopperRef.current}
                            prevOpen={sentimentPrevOpen}
                            setOpen={setSentimentPopOpen}
                            handleClose={sentimentHandleClose}
                            // scrollRef={platformScrollRef}
                            onClose={props.onClose}
                            saveFilter={props.saveFilter}
                            clearFilter={props.clearFilter}
                            sentiment_value_list={props.sentiment_value_list}
                            fetchTicketCount={fetchTicketCount}
                          />
                        )}

                        {value === 6 && (
                          <TagFilterPopper
                            open={props.open}
                            filterTagList={props.filterTagList}
                            onClose={props.onClose}
                            saveFilter={props.saveFilter}
                            clearFilter={props.clearFilter}
                            fetchTicketCount={fetchTicketCount}
                          />
                        )}

                        {value === 7 &&
                          localStorage.getItem("mentionsFilters") &&
                          JSON.parse(localStorage.getItem("mentionsFilters"))
                            .platform !== null &&
                          ratingsEnabledPlatforms?.includes(
                            JSON.parse(localStorage.getItem("mentionsFilters"))
                              .platform.platform_name
                          ) && (
                            <RatingsFilterPopper
                              open={props.open}
                              ratings_value_list={props.ratings_value_list}
                              popopen={platformOpen}
                              statePopperRef={platformPopperRef.current}
                              prevOpen={platformPrevOpen}
                              setOpen={setPlatformOpen}
                              handleClose={platformHandleClose}
                              scrollRef={platformScrollRef}
                              onClose={props.onClose}
                              saveFilter={props.saveFilter}
                              clearFilter={props.clearFilter}
                              filteredPlatformList={props.filteredPlatformList}
                              fetchTicketCount={fetchTicketCount}
                            />
                          )}
                      </Box>
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  // alignItems="center"
                  // justifyContent={"center"}
                  // className="bg-warning"
                  // textAlign="center"
                >
                  <Box width={"100%"} sx={{ paddingTop: "10px" }}>
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      mt={4.5}
                      // className="bg-warning"
                      justifyContent={"center"}
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        xl={4.5}
                        lg={4.5}
                        md={4.5}
                        sm={4.5}
                        xs={4.5}
                        // className="bg-success"
                        textAlign={"center"}
                        alignItems="center"
                        justifyContent={"center"}
                        // gap={2}
                      >
                        <Grid
                          item
                          container
                          ml={-2}
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          // alignItems="center"
                        >
                          <Grid
                            item
                            container
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            // className="bg-success"
                            alignItems={"center"}
                            justifyContent="center"
                            textAlign={"center"}
                          >
                            <Grid
                              item
                              container
                              sx={{
                                width: "70px",
                                height: "70px",
                                border: "solid 0.5px #86aeb6",
                                backgroundColor: "#f9fcfd",
                                borderRadius: "50%",
                                //   fontSize: "28px",
                                fontSize: "20px",
                              }}
                              textAlign="center"
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              {props.ticketCount.Total_tickets}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            //   className="bg-light"
                            textAlign={"center"}
                            container
                            alignItems={"center"}
                            justifyContent="center"
                            px={2}
                            py={0.5}
                            height={"50px"}
                          >
                            <p
                              className=" p-0 m-0"
                              style={{
                                //   fontSize: "16px",
                                fontSize: "14px",
                              }}
                            >
                              {"Total Tickets"}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xl={4.5}
                        lg={4.5}
                        md={4.5}
                        sm={4.5}
                        xs={4.5}
                        // className="bg-warning"
                        textAlign={"center"}
                        alignItems="center"
                        justifyContent={"center"}
                        // gap={2}
                      >
                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          ml={-2}
                          alignItems="center"
                        >
                          <Grid
                            item
                            container
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            //   className="bg-success"
                            alignItems={"center"}
                            justifyContent="center"
                            textAlign={"center"}
                          >
                            <Grid
                              item
                              container
                              sx={{
                                width: "70px",
                                height: "70px",
                                border: "solid 0.5px #86aeb6",
                                backgroundColor: "#f9fcfd",
                                borderRadius: "50%",
                                //   fontSize: "28px",
                                fontSize: "20px",
                              }}
                              textAlign="center"
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              {props.ticketCount.unassigned}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            //   className="bg-light"
                            textAlign={"center"}
                            container
                            alignItems={"center"}
                            justifyContent="center"
                            px={2}
                            py={0.5}
                            height={"50px"}
                          >
                            <p
                              className=" p-0 m-0"
                              style={{
                                //   fontSize: "16px",
                                fontSize: "14px",
                              }}
                            >
                              {"Unassigned Tickets"}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xl={4.5}
                        lg={4.5}
                        md={4.5}
                        sm={4.5}
                        xs={4.5}
                        // className="bg-success"
                        textAlign={"center"}
                        alignItems="center"
                        justifyContent={"center"}
                        // gap={2}
                      >
                        <Box
                          //   className="bg-light"
                          my={1}
                          width={"100%"}
                        >
                          <Grid
                            item
                            container
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            ml={-1}
                            alignItems="center"
                            // className="bg-warning"
                            // py={1}
                          >
                            <Grid
                              item
                              container
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              //   className="bg-success"
                              alignItems={"center"}
                              justifyContent="center"
                              textAlign={"center"}
                            >
                              <Grid
                                item
                                container
                                sx={{
                                  width: "70px",
                                  height: "70px",
                                  border: "solid 0.5px #86aeb6",
                                  backgroundColor: "#f9fcfd",
                                  borderRadius: "50%",
                                  //   fontSize: "28px",
                                  fontSize: "20px",
                                }}
                                textAlign="center"
                                alignItems={"center"}
                                justifyContent="center"
                              >
                                {Initial_status.length > 0 &&
                                  returnTicketStatusCount(
                                    Initial_status[0].status_id
                                  )}
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              //   className="bg-light"
                              textAlign={"center"}
                              container
                              alignItems={"center"}
                              justifyContent="center"
                              px={2}
                              py={0.5}
                            >
                              <p
                                className=" p-0 m-0"
                                style={{
                                  //   fontSize: "16px",
                                  fontSize: "14px",
                                }}
                              >
                                {Initial_status.length > 0 &&
                                  Initial_status[0].status_name}
                              </p>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        container
                        xl={4.5}
                        lg={4.5}
                        md={4.5}
                        sm={4.5}
                        xs={4.5}
                        // className="bg-warning"
                        textAlign={"center"}
                        alignItems="center"
                        justifyContent={"center"}
                        // gap={2}
                      >
                        <Box
                          //   className="bg-light"
                          my={1}
                          width={"100%"}
                        >
                          <Grid
                            item
                            container
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            ml={-1}
                            alignItems="center"
                            // className="bg-warning"
                            // py={1}
                          >
                            <Grid
                              item
                              container
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              //   className="bg-success"
                              alignItems={"center"}
                              justifyContent="center"
                              textAlign={"center"}
                            >
                              <Grid
                                item
                                container
                                sx={{
                                  width: "70px",
                                  height: "70px",
                                  border: "solid 0.5px #86aeb6",
                                  backgroundColor: "#f9fcfd",
                                  borderRadius: "50%",
                                  //   fontSize: "28px",
                                  fontSize: "20px",
                                }}
                                textAlign="center"
                                alignItems={"center"}
                                justifyContent="center"
                              >
                                {Final_status.length > 0 &&
                                  returnTicketStatusCount(
                                    Final_status[0] && Final_status[0].status_id
                                  )}
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              //   className="bg-light"
                              textAlign={"center"}
                              container
                              alignItems={"center"}
                              justifyContent="center"
                              px={2}
                              py={0.5}
                            >
                              <p
                                className=" p-0 m-0"
                                style={{
                                  //   fontSize: "16px",
                                  fontSize: "14px",
                                }}
                              >
                                {Final_status.length > 0 &&
                                  Final_status[0] &&
                                  Final_status[0].status_name}
                              </p>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Box
                        className="scrollable"
                        sx={{
                          height: 260,
                          overflowY: "scroll",
                          width: "100%",
                          scrollbarWidth: "none",
                          borderTop: "1px solid #2a5e6c",
                        }}
                      >
                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          // className="bg-warning"
                          justifyContent={"center"}
                          alignItems="center"
                        >
                          {AdditionalStatusList.length > 0 &&
                            AdditionalStatusList.map((item, index) => (
                              <Grid
                                item
                                container
                                xl={5}
                                lg={5}
                                md={5}
                                sm={5}
                                xs={5}
                                // className="bg-success"
                                textAlign={"center"}
                                alignItems="center"
                                justifyContent={"center"}
                                // gap={2}
                                key={index}
                              >
                                <Box
                                  //   className="bg-light"
                                  my={1}
                                  width={"100%"}
                                >
                                  <Grid
                                    item
                                    container
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    alignItems="center"
                                    // className="bg-warning"
                                    // py={1}
                                  >
                                    <Grid
                                      item
                                      container
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      //   className="bg-success"
                                      alignItems={"center"}
                                      justifyContent="center"
                                      textAlign={"center"}
                                    >
                                      <Grid
                                        item
                                        container
                                        sx={{
                                          width: "70px",
                                          height: "70px",
                                          border: "solid 0.5px #86aeb6",
                                          backgroundColor: "#f9fcfd",
                                          borderRadius: "50%",
                                          //   fontSize: "28px",
                                          fontSize: "20px",
                                        }}
                                        textAlign="center"
                                        alignItems={"center"}
                                        justifyContent="center"
                                      >
                                        {returnTicketStatusCount(
                                          item.status_id
                                        )}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      //   className="bg-light"
                                      textAlign={"center"}
                                      container
                                      alignItems={"center"}
                                      justifyContent="center"
                                      height={"50px"}
                                      px={2}
                                      py={0.5}
                                    >
                                      <p
                                        className=" p-0 m-0"
                                        style={{
                                          //   fontSize: "16px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {item.status_name}
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps)(ListViewFilters);
