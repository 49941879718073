import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Badge from "@mui/material/Badge";
import { connect } from "react-redux";
import {
  BorderContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../../components/componentLayout/ComponentLayout";
import CircularLoadingIndicator from "../../../../../components/loading-indicator/CircularLoadingIndicator";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangeStyled from "../../../../../components/dateRangePickerComponent/DateRangePickerStyled";
import MuiAutocomplete from "../../../../../components/muiSelectField/MuiAutocomplete";
import StyleIcon from "@mui/icons-material/Style";
import { theme } from "../../../../../views/App";
import CategoryFilterDialog from "./CategoryFilterDialog";
import { Form, Formik } from "formik";
import Chip from "@mui/material/Chip";

function CategoryFilterPopper(props) {
  let { isRequesting } = props;

  const [category, setCategory] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).category;
    } else {
      return [];
    }
  });

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    if (filters) {
      setCategory(filters.category && filters.category);
    }
  }, [props.open]);

  // category callback function
  const categoryListHandleChange = (event, category) => {
    setCategory(category);
  };
  const returnCustomValues = (sub_value_id) => {
    let category_values =
      props.customticketfields &&
      props.customticketfields.hits &&
      props.customticketfields.hits.hits[0]._source.field_values;

    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }

    let custom_field_vals = ReturnCategoryName(category_values, sub_value_id);

    return custom_field_vals;
  };

  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  let [categoryError, setcategoryError] = useState(false);

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    if (category && category.length !== 0) {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

      if (filters) {
        let newcategory = category.map((item) => item.id);
        filters.category = newcategory;
        filters.pageNumber = `0`;
        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            category,
          })
        );
      }

      props.saveFilter(filters);
      props.fetchTicketCount();
    } else {
      setcategoryError(true);

      setTimeout(() => {
        setcategoryError(false);
      }, 1000);
    }
  }

  function handleClearFilters(setFieldValue) {
    if (
      category &&
      category.length > 0
      // selected.length !== 0
    ) {
      setCategory([]);
      // setFieldValue("sub_value", { id: "", value: "" });
      setFieldValue("sub_value", []);
      props.clearFilter("category");

      localStorage.removeItem("mentionsFilters");
    }
  }

  const returntrueCategoryValues = (ticket) => {
    let bool_val = false;

    if (
      ticket &&
      ticket._source &&
      ticket._source.custom_value &&
      ticket._source.custom_value.length !== 0
    ) {
      ticket._source.custom_value
        .filter((val) => {
          return val.field_type === "category";
        })
        .map((item) => {
          if (item && item.field_values) {
            item.field_values[0].id !== ""
              ? (bool_val = true)
              : (bool_val = false);
          }
        });
    }

    return bool_val;
  };

  const [categoryUpdateOpen, setCategoryUpdateOpen] = useState(false);

  // category edit button open close model
  const openCloseModal = useCallback((open) => {
    setCategoryUpdateOpen(open);
  }, []);

  let initial = {
    sub_value:
      localStorage.getItem("mentionsFilters") &&
      JSON.parse(localStorage.getItem("mentionsFilters")).category &&
      JSON.parse(localStorage.getItem("mentionsFilters")).category.length > 0
        ? JSON.parse(localStorage.getItem("mentionsFilters")).category.map(
            (id, index) => ({
              id: id,
              value: returnCustomValuesAsString(id),
            })
          )
        : [],
  };

  return (
    <Box width="100%">
      <BorderContainer
      // borderRadius={5}
      >
        <ClickAwayListener onClickAway={props.handleClose}>
          <div
            autoFocusItem={props.popopen}
            id="composition-menu"
            aria-labelledby="composition-button"
          >
            <Grid
              item
              container
              sx={{
                height: 250,
              }}
              xl={12}
              // className="bg-success"
            >
              <Grid item xl={12} textAlign="left">
                <Typography
                  component={"p"}
                  className="dashboard-title"
                  sx={{ fontSize: 18 }}
                >
                  Category
                </Typography>
              </Grid>

              <Grid item xl={12}>
                <Formik initialValues={initial}>
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    submitForm,
                    handleSubmit,
                    handleChange,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        laptop={12}
                        columnGap={2}
                        rowGap={1}
                        sx={{
                          marginTop: 2,
                        }}
                        // className="bg-warning"
                      >
                        <Grid item xl={10} lg={10} md={10} sm={10}>
                          {values.sub_value &&
                            values.sub_value.id &&
                            returnCustomValues(values.sub_value.id) &&
                            returnCustomValuesAsString(values.sub_value.id)}
                          <Grid
                            item
                            p={0.5}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              animation: categoryError && "bounce 0.5s linear",
                              "@keyframes bounce": {
                                "0%, 20%, 50%, 80%, 100%": {
                                  transform: "translateY(0)",
                                },
                                "40%": {
                                  transform: "translateY(-30px)",
                                },
                                "60%": {
                                  transform: "translateY(-15px)",
                                },
                              },
                            }}
                          >
                            <ListItem className="py-0 m-0 px-1">
                              <StyleIcon
                                style={{
                                  width: "16px",
                                  marginRight: 2,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              />
                              <p
                                className="m-0 p-0"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  openCloseModal(!categoryUpdateOpen);
                                }}
                              >
                                Select Category
                              </p>
                            </ListItem>
                          </Grid>
                          <Grid>
                            {values.sub_value &&
                              values.sub_value.length > 0 &&
                              values.sub_value.map((item, index) => (
                                <Chip
                                  key={index}
                                  label={item.value}
                                  onDelete={async () => {
                                    let updatedSubValues = [
                                      ...values.sub_value,
                                    ];
                                    updatedSubValues.splice(index, 1);
                                    if (
                                      updatedSubValues == [] ||
                                      updatedSubValues.length === 0
                                    )
                                      updatedSubValues = [];

                                    setFieldValue(
                                      `sub_value`,
                                      updatedSubValues
                                    );
                                  }}
                                  variant="outlined"
                                  size="small"
                                />
                              ))}
                          </Grid>
                          <CategoryFilterDialog
                            open={categoryUpdateOpen}
                            setFieldValue={(value) => {
                              setFieldValue(`sub_value`, value);
                            }}
                            sub_value={values.sub_value}
                            onClose={() => {
                              setCategoryUpdateOpen(false);
                            }}
                            customticketfields={props.customticketfields}
                            customticketfields_filter={
                              props.customticketfields_filter
                            }
                          />
                          {setCategory(values.sub_value)}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        laptop={12}
                        columnGap={2}
                        rowGap={1}
                        sx={{
                          marginY: 5,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Grid item>
                          <MuiButton
                            variant="outlined"
                            width={"103px"}
                            height={"35px"}
                            backgroundColor={"white"}
                            type="submit"
                            name={"Reset"}
                            loading={false}
                            noTextOnLoading={true}
                            onClick={() => {
                              handleClearFilters(setFieldValue);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <MuiButton
                            variant="contained"
                            width={"103px"}
                            height={"35px"}
                            type="submit"
                            name={"Apply"}
                            loading={false}
                            noTextOnLoading={true}
                            onClick={() => {
                              handleSaveFilters();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      </BorderContainer>
    </Box>
  );
}

export default CategoryFilterPopper;
