import ActionUtility from "../../../utilities/ActionUtility";
import TicketsEffect from "./TicketsEffect";

export default class TicketsAction {
  //tickets requests
  static REQUEST_TICKETS = "TicketsAction.REQUEST_TICKETS";
  static REQUEST_TICKETS_FINISHED = "TicketsAction.REQUEST_TICKETS_FINISHED";

  //tickets related requests
  static REQUEST_RELATED_TICKETS = "TicketsAction.REQUEST_RELATED_TICKETS";
  static REQUEST_RELATED_TICKETS_FINISHED =
    "TicketsAction.REQUEST_RELATED_TICKETS_FINISHED";

  //tickets requests for Reports Page
  static REQUEST_TICKETS_FOR_REPORTS =
    "TicketsAction.REQUEST_TICKETS_FOR_REPORTS";
  static REQUEST_TICKETS_FOR_REPORTS_FINISHED =
    "TicketsAction.REQUEST_TICKETS_FOR_REPORTS_FINISHED";

  //tickets requests for Reports Page with pagination
  static REQUEST_TICKETS_FOR_REPORTS_WITH_PAGINATION =
    "TicketsAction.REQUEST_TICKETS_FOR_REPORTS_WITH_PAGINATION";
  static REQUEST_TICKETS_FOR_REPORTS_WITH_PAGINATION_FINISHED =
    "TicketsAction.REQUEST_TICKETS_FOR_REPORTS_WITH_PAGINATION_FINISHED";

  //tickets requests
  static REQUEST_TICKETS_BY_ID = "TicketsAction.REQUEST_TICKETS_BY_ID";
  static REQUEST_TICKETS_BY_ID_FINISHED =
    "TicketsAction.REQUEST_TICKETS_BY_ID_FINISHED";

  static REQUEST_TICKET_BY_ID_CLEAR =
    "TicketsAction.REQUEST_TICKET_BY_ID_CLEAR";
  static REQUEST_TICKET_BY_ID_CLEAR_FINISHED =
    "TicketsAction.REQUEST_TICKET_BY_ID_CLEAR_FINISHED";

  //tickets By Fields
  static REQUEST_TICKETS_BY_FIELDS = "TicketsAction.REQUEST_TICKETS_BY_FIELDS";
  static REQUEST_TICKETS_BY_FIELDS_FINISHED =
    "TicketsAction.REQUEST_TICKETS_BY_FIELDS_FINISHED";

  // ticket filter
  static REQUEST_TICKETS_FILTER = "TicketsAction.REQUEST_TICKETS_FILTER";
  static REQUEST_TICKETS_FILTER_FINISHED =
    "TicketsAction.REQUEST_TICKETS_FILTER_FINISHED";

  //tickets post
  static REQUEST_POST_TICKETS = "TicketsAction.REQUEST_POST_TICKETS";
  static REQUEST_POST_TICKETS_FINISHED =
    "TicketsAction.REQUEST_POST_TICKETS_FINISHED";

  //ticket PUT
  static REQUEST_PUT_TICKETS = "TicketsAction.REQUEST_PUT_TICKETS";
  static REQUEST_PUT_TICKETS_FINISHED =
    "TicketsAction.REQUEST_PUT_TICKETS_FINISHED";

  //ticket BULK PUT
  static REQUEST_BULK_PUT_TICKETS = "TicketsAction.REQUEST_BULK_PUT_TICKETS";
  static REQUEST_BULK_PUT_TICKETS_FINISHED =
    "TicketsAction.REQUEST_BULK_PUT_TICKETS_FINISHED";

  //METHODS

  //Ticket list get method
  static requestTickets(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS,
        TicketsEffect.requestTickets,
        params
      );
    };
  }

  //Related ticket list get method
  static requestRelatedTickets(params, fetchMassTicketHistory) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_RELATED_TICKETS,
        TicketsEffect.requestRelatedTickets,
        params,
        fetchMassTicketHistory
      );
    };
  }

  //Ticket list get method
  static requestTicketsForReports(params, Callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_FOR_REPORTS,
        TicketsEffect.requestTicketsForReports,
        params,
        Callback
      );
    };
  }

  //Ticket list get method for pagination of reports
  static requestTicketsForReportsWithPagination(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_FOR_REPORTS_WITH_PAGINATION,
        TicketsEffect.requestTicketsForReportsWithPagination,
        params
      );
    };
  }

  //Ticket list get method by id
  static requestTicketsById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_BY_ID,
        TicketsEffect.requestTicketsById,
        id
      );
    };
  }

  static requestTicketByIdClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKET_BY_ID_CLEAR,
        TicketsEffect.requestTicketByIdClear
      );
    };
  }

  //Ticket list get method by Fields
  static requestTicketsByFields(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_BY_FIELDS,
        TicketsEffect.requestTicketsByFields,
        params
      );
    };
  }

  //Ticket filter list get method
  static requestTicketsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_FILTER,
        TicketsEffect.requestTicketsFilter,
        params
      );
    };
  }

  //Ticket POST method
  static requestPostTicket(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_POST_TICKETS,
        TicketsEffect.requestPostTicket,
        data
      );
    };
  }

  //Ticket PUT method
  static requestPutTicket(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_PUT_TICKETS,
        TicketsEffect.requestPutTicket,
        data,
        id
      );
    };
  }

  //Ticket PUT method
  static requestBulkPutTicket(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_BULK_PUT_TICKETS,
        TicketsEffect.requestBulkPutTicket,
        data
      );
    };
  }
}
