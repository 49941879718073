import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import {
  firstLetterOrange,
  getItemsById,
  getStaticItemsById,
} from "./ticketPartials";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ErrorBoundary from "./ErrorBoundary";

const StaticReportContainer = ({
  summaryBoxRef,
  theme,
  report,
  children,
  noHeading,
  setDetailReportId,
  setDetailReportOpen,
}) => {
  return (
    <Grid
      lg={12}
      sx={{
        height: "100%",
      }}
    >
      <ErrorBoundary message="Error in loading report">
        <Box
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            ref={summaryBoxRef}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flex: "12",
            }}
          >
            {!noHeading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: theme.reports.headerSize,
                    padding: theme.reports.headerPadding,
                  }}
                >
                  {firstLetterOrange(
                    getStaticItemsById(report)[0]?.report_name
                  )}
                </Typography>
                <IconButton
                  onClick={() => {
                    setDetailReportOpen(true);
                    setDetailReportId(report);
                  }}
                  title="Maximize"
                >
                  <FullscreenIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                    fontSize="14px"
                  />
                </IconButton>
              </Box>
            )}
            <hr className="p-0 m-0" />
            <Box
              sx={{
                padding: "12px",
                height: "100%",
              }}
            >
              <ErrorBoundary message="Error in loading report">
                {children}
              </ErrorBoundary>
            </Box>
          </Box>
        </Box>
      </ErrorBoundary>
      <div className="pagebreak" />
    </Grid>
  );
};

export default StaticReportContainer;
