import BaseReducer from "../../../utilities/BaseReducer";
import UserPermissionAction from "./UserPermissionAction";

// UserPermissionReducer function
export default class UserPermissionReducer extends BaseReducer {
  initialState = {
    userPermission: [],
  };

  [UserPermissionAction.REQUEST_USER_PERSMISSION_FINISHED](state, action) {
    return {
      ...state,
      userPermission: action.payload,
    };
  }
}
