import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import CannedMessageAction from "../../stores/cannedMessage/CannedMessageAction";
import CannedMessageDialog from "../../views/ticketsListView/ticketDetails/components/CannedMessageDialog";

// mapState to props
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    CannedMessageAction.REQUEST_CANNED_MESSAGE,
  ]),

  cannedMessage: state.cannedMessage.cannedMessage || [],
});

const ReplyMessages = (props) => {
  const [category, setCategory] = useState(null);
  const [subcategory, setSubCategory] = useState(null);
  const [tittle, setTitle] = useState(null);
  const [message, setMessage] = useState(null);

  const [cannedMessageOpen, setCannedMessageOpen] = useState(false);

  const fetchCannedMessage = () => {
    let params = {
      page_limit: "none",
    };
    props.dispatch(CannedMessageAction.requestCannedMessage(params));
  };

  useEffect(() => {
    fetchCannedMessage();
    setCategory(null);
    setMessage(null);
  }, []);

  let categoryList = [];
  let subcategoryList = [];
  let titleFilterList = [];
  let messageList = [];

  if (props.cannedMessage.hits && props.cannedMessage.hits.hits.length !== 0) {
    props.cannedMessage.hits.hits[0] &&
      props.cannedMessage.hits.hits[0]._source.canned_message.length !== 0 &&
      props.cannedMessage.hits.hits[0]._source.canned_message.map((item) => {
        categoryList.push({
          response_category_id: item.id,
          response_category_name: item.value,
        });
        item.sub_value &&
          item.sub_value.length !== 0 &&
          item.sub_value.map((item_2) => {
            subcategoryList.push({
              response_category_id: item.id,
              response_subcategory_id: item_2.id,
              response_subcategory_name: item_2.value,
            });

            item_2.sub_value &&
              item_2.sub_value.length !== 0 &&
              item_2.sub_value.map((item_3) => {
                titleFilterList.push({
                  response_subcategory_id: item_2.id,
                  response_titleFilter_id: item_3.id,
                  response_titleFilter_name: item_3.value,
                });

                item_3.sub_value &&
                  item_3.sub_value.length !== 0 &&
                  item_3.sub_value.map((item_4) => {
                    messageList.push({
                      response_titleFilter_id: item_3.id,
                      response_messageList_id: item_4.id,
                      content: item_4.value,
                    });
                  });
              });
          });
      });
  }

  const categoryHandleChange = async (event, value) => {
    if (value != null) {
      setCategory(value);
      setSubCategory(null);
      setTitle(null);
      setMessage(null);
      await props.setFieldValue("message", "");
    } else {
      setCategory(null);
      setSubCategory(null);
      setTitle(null);
      setMessage(null);
    }
  };

  const subCategoryHandleChange = async (event, value) => {
    if (value != null) {
      setSubCategory(value);
      setTitle(null);
      setMessage(null);
      await props.setFieldValue("message", "");
    } else {
      setSubCategory(null);
      setTitle(null);
      setMessage(null);
    }
  };

  let filtered_subcategoryList = [];

  if (category && subcategoryList) {
    subcategoryList
      .filter(
        (item) => item.response_category_id === category.response_category_id
      )
      .map((item) => {
        return filtered_subcategoryList.push({
          response_category_id: item.response_category_id,
          response_subcategory_id: item.response_subcategory_id,
          response_subcategory_name: item.response_subcategory_name,
        });
      });
  }

  let filtered_titleFilterList = [];

  if (subcategory && titleFilterList) {
    titleFilterList
      .filter(
        (item) =>
          item.response_subcategory_id === subcategory.response_subcategory_id
      )
      .map((item) => {
        return filtered_titleFilterList.push({
          response_subcategory_id: item.response_subcategory_id,
          response_titleFilter_id: item.response_titleFilter_id,
          response_titleFilter_name: item.response_titleFilter_name,
        });
      });
  }

  const titleHandleChange = async (event, value) => {
    if (value != null) {
      setTitle(value);
      setMessage(null);

      let filtered_messageList = [];

      if (value && messageList) {
        messageList
          .filter(
            (item) =>
              item.response_titleFilter_id === value.response_titleFilter_id
          )
          .map((item) => {
            return filtered_messageList.push({
              response_titleFilter_id: item.response_titleFilter_id,
              response_messageList_id: item.response_messageList_id,
              content: item.content,
            });
          });
      }

      await props.onSetMessage(
        filtered_messageList &&
          filtered_messageList.length !== 0 &&
          filtered_messageList[0]
      );

      await (props.values.messages &&
        props.values.messages.length &&
        props.setFieldValue(`messages[${props.values.messages.length - 1}]`, [
          filtered_messageList &&
            filtered_messageList.length !== 0 &&
            filtered_messageList[0].content,
        ]));
    } else {
      setTitle(null);
      setMessage(null);
    }
  };

  return (
    <React.Fragment>
      <Grid
        lg={12}
        py={1}
        sm={12}
        md={12}
        item
        container
        alignItems={"center"}
        justifyContent="space-between"
        // gap={1}
      >
        <Grid item lg={3.8} sm={3.8} md={3.8}>
          <AutoCompleteComponent
            list={categoryList}
            label="category"
            size="small"
            getOptionLabel={(option) =>
              option.response_category_name ? option.response_category_name : ""
            }
            handleChange={categoryHandleChange}
            textFieldProps={{
              placeholder: "Category",
            }}
            value={category}
            width="100%"
          />
        </Grid>

        <Grid item lg={3.8} sm={3.8} md={3.8}>
          <AutoCompleteComponent
            list={filtered_subcategoryList}
            label=""
            size="small"
            getOptionLabel={(option) =>
              option.response_subcategory_name
                ? option.response_subcategory_name
                : ""
            }
            handleChange={subCategoryHandleChange}
            textFieldProps={{
              placeholder: "Sub Category",
            }}
            value={subcategory}
            width="100%"
          />
        </Grid>

        <Grid item lg={3.8} sm={3.8} md={3.8}>
          <AutoCompleteComponent
            list={filtered_titleFilterList}
            label=""
            size="small"
            getOptionLabel={(option) =>
              option.response_titleFilter_name
                ? option.response_titleFilter_name
                : ""
            }
            handleChange={titleHandleChange}
            textFieldProps={{
              placeholder: "Title",
            }}
            value={tittle}
            width="100%"
          />
        </Grid>

        <CannedMessageDialog
          open={cannedMessageOpen}
          onClose={() => {
            setCannedMessageOpen(false);
          }}
          message={message}
          setMessage={setMessage}
        />
      </Grid>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(ReplyMessages);
