import BaseReducer from "../../../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// TopFiveDraftReleasesReduser Reducer
export default class TopFiveDraftReleasesReduser extends BaseReducer {
  initialState = {
    top5Draft: [],
  };
  [ReleasesAction.REQUEST_TOP_5_DRAFT_RELEASES_FINISHED](state, action) {
    return {
      ...state,
      top5Draft: action.payload,
    };
  }
}
