import { BaseModel } from "sjs-base-model";
import WordCloudHitsModel from "./WordCloudHitsModel";
import WordCloudShardsModel from "./WordCloudShardsModel";

// get model for project
export default class WordCloudModel extends BaseModel {
  _shards = { _shards: WordCloudShardsModel };
  current_page_no = 0;
  hits = { hits: WordCloudHitsModel };
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
