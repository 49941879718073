import { BaseModel } from "sjs-base-model";
import TicketWorkedOnCountBucketsDataModel from "./TicketWorkedOnCountBucketsDataModel";

export default class TicketWorkedOnCountBucketsModel extends BaseModel {
  //ticket worked on count buckets model
  doc_count = 0;
  key = "";
  ticket_between = {
    buckets: [TicketWorkedOnCountBucketsDataModel],
  };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
