import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import errorImage from "../Images/pagenotfound.webp";

export default function PageNotFound() {
  return (
    <Box py={1}>
      <Grid
        item
        Container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        // className="bg-warning"
        justifyContent={"center"}
        alignItems="center"
      >
        <Grid
          item
          xl={9}
          lg={9}
          md={9}
          sm={9}
          xs={9}
          // className="bg-success"
          textAlign={"center"}
        >
          <img
            src={errorImage}
            alt="Error:404 Page not found. "
            loading="eager"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
