import { Component } from "react";
import ActionUtility from "../../../utilities/ActionUtility";
import PlayStoreReplyEffect from "./PlayStoreReplyEffect";

export default class PlayStoreReplyAction extends Component {
  //PlayStoreReply post
  static REQUEST_POST_PLAYSTORE_REPLY =
    "PlayStoreReplyAction.REQUEST_POST_PLAYSTORE_REPLY";
  static REQUEST_POST_PLAYSTORE_REPLY_FINISHED =
    "PlayStoreReplyAction.REQUEST_POST_PLAYSTORE_REPLY_FINISHED";

  static REQUEST_POST_PLAYSTORE_BULK_REPLY =
    "PlayStoreReplyAction.REQUEST_POST_PLAYSTORE_BULK_REPLY";
  static REQUEST_POST_PLAYSTORE_BULK_REPLY_FINISHED =
    "PlayStoreReplyAction.REQUEST_POST_PLAYSTORE_BULK_REPLY_FINISHED";

  //METHODS

  //PlayStoreReply post method
  static requestPostPlayStoreReply(data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlayStoreReplyAction.REQUEST_POST_PLAYSTORE_REPLY,
        PlayStoreReplyEffect.requestPostPlayStoreReply,
        data,
        callback
      );
    };
  }

  static requestPostPlayStoreBulkReply(data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlayStoreReplyAction.REQUEST_POST_PLAYSTORE_BULK_REPLY,
        PlayStoreReplyEffect.requestPostPlayStoreBulkReply,
        data,
        callback
      );
    };
  }
}
