import BaseReducer from "../../utilities/BaseReducer";
import OrmNotificationAction from "./ORMNotificationAction";

export default class OrmNotificationReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    ormNotifications: [],
  };

  //operation Filter
  [OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_FINISHED](state, action) {
    return {
      ...state,
      ormNotifications: action.payload,
    };
  }
}
