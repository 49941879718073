import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencerUserAction from "./InfluencerUserAction";

export default class InfluencerUserFilterReducer extends BaseReducer {
  //initial state of InfluencerUserFilterReducer
  initialState = {
    influencerUserFilter: [],
  };
  //InfluencerUserFilterReducer request action finish
  [InfluencerUserAction.REQUEST_INFLUENCER_USER_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencerUserFilter: action.payload,
    };
  }
}
