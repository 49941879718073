import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TwitterDirectMessageModel from "./models/TwitterDirectMessageModel";
import TwitterDirectMessagePostModel from "./models/TwitterDirectMessagePostModel";
import TwitterDmPutModel from "./models/TwitterDmPutModel";

//Category effect
export default class TwitterDirectMessageEffect {
  // calling an API according to the TwitterDirectmessage
  static async requestTwitterDirectMessage(params) {
    const endpoint = environment.api.twitterDM;

    return EffectUtility.getToModel(
      TwitterDirectMessageModel,
      endpoint,
      params
    );
  }

  // TwitterDirectmessage Post API
  static async requestTwitterDirectMessagePost(
    id,
    params,
    data,
    callBackFunction
  ) {
    const endpoint =
      environment.api.twitterDirectMessage +
      "/" +
      id +
      "?account_name=" +
      params.account_name.account_name +
      "&" +
      "message_id=" +
      params.message_id;

    let response = EffectUtility.postToModel(
      TwitterDirectMessagePostModel,
      endpoint,

      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestTwitterDmClear() {
    return {};
  }

  static async requestPutTwitterDmFeed(data, id) {
    const endpoint = environment.api.twitterDM + "/" + id;
    return EffectUtility.putToModel(TwitterDmPutModel, endpoint, data);
  }
}
