import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import UserProfileModel from "./model/UserProfileModel";
import UserProfilePutModel from "./model/UserProfilePutModel";

export default class UserProfileEffect {
  static async requestUserprofile() {
    const endpoint = environment.api.userprofile;
    return EffectUtility.getToModel(UserProfileModel, endpoint);
  }

  static async requestPutUserprofile(data, id) {
    const endpoint = environment.api.userprofile + "/" + id;
    return EffectUtility.putToModel(UserProfilePutModel, endpoint, data);
  }
}
