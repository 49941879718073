import { BaseModel } from "sjs-base-model";

// StatusPostModel
export default class StatusPostModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;
    // response getting from the api mentioned end point

    status = "";

    constructor(data1) {
        super();

        this.update(data1);
    }
}
