import BaseReducer from "../../../../utilities/BaseReducer";
import HssCategoryAction from "./HssCategoryAction";

export default class HssCategoryReduser extends BaseReducer {
  initialState = {
    websweepXhssCategory: [],
  };
  [HssCategoryAction.REQUEST_GET_HSS_CATEGORY_FINISHED](state, action) {
    return {
      ...state,
      websweepXhssCategory: action.payload,
    };
  }
}
