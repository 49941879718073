import { BaseModel } from "sjs-base-model";
import WhatsAppHitsModel from "./WhatsAppHitsModel";
import WhatsAppShardsModel from "./WhatsAppShardsModel";

// WhatsAppFeedModel
export default class WhatsAppFeedModel extends BaseModel {

  // response getting from the api mentioned end point
  _shards = { _shards: WhatsAppShardsModel };
  current_page_no = 0;
  hits = { hits: WhatsAppHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
