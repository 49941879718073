import {
  Box,
  Grid,
  IconButton,
  Popper,
  Typography,
  Paper,
} from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import CustomTicketFieldsAction from "../../../stores/customticketfields/CustomTicketFieldsAction";

import UserLastWorkingProjectAction from "../../stores/userLastWorkingProject/UserLastWorkingProjectAction";
import CustomTicketPost from "../../components/customTicketFieldPostForm/CustomTicketPost";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

// mapstate to props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS,
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER,
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
  ]),
  customticketfields: state.customticketfields.customticketfields.hits || {},
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
  pagination: state.customticketfields.customticketfields || {},
  customticketfield_filter:
    state.customticketfields_filter.customticketfields_filter.hits || {},
});

// Custom Ticket Fields Fucntion
function CustomTicketFields(props) {
  const { isRequesting } = props;

  // State declarations
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [fieldType, setfieldType] = useState("");
  const [customFieldName, setCustomFieldName] = useState("");
  const [field_type] = useState([
    "text",
    "select",
    "radio",
    "toggle",
    "date",
    "ticket category",
  ]); //["text", "select", "radio", "toggle", "date"]
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const customFieldArray = [];

  if (props.customticketfield_filter.hits) {
    props.customticketfield_filter.hits.map((val) => {
      customFieldArray.push({
        parent_field_id: val._id,
        parent_field_name: val._source.field_name,
      });
    });
  }
  // customticketfield_api
  function fetchCustomticketfields(paramater) {
    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFields(paramater)
    );
  }
  function fetchLastWorkingProject(id) {
    props.dispatch(
      UserLastWorkingProjectAction.requestLastWorkingProjectById(id)
    );
  }

  // customticketfield_api
  function fetchCustomticketfieldsFilter(paramater) {
    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFieldsFilter(paramater)
    );
  }
  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    let ID = localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : "";
    fetchLastWorkingProject(ID);
    let filter_params = {};
    const filter = [];

    if (customFieldName !== "") {
      filter.push(
        `["field_name.keyword","must","","match","${customFieldName}"]`
      );
    }
    if (fieldType !== "") {
      fieldType === "ticket category"
        ? filter.push(`["field_type.keyword","must","","match","category"]`)
        : filter.push(
            `["field_type.keyword","must","","match","${fieldType}"]`
          );
    }

    if (fieldType !== "" || customFieldName !== "") {
      filter_params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["order_number","asc"]]`,
      };
    }

    fetchCustomticketfields(filter_params);

    // filter api\

    const project = {
      page_limit: "none",
      order_by: `[["field_name.keyword", "asc"]]`,
    };

    // if (
    //   props?.customticketfield_filter === undefined ||
    //   Object.keys(props.customticketfield_filter).length === 0
    // ) {
    fetchCustomticketfieldsFilter(project);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [popperAnchor, setPopperAnchor] = React.useState(null);
  const [fieldValues, setFieldValues] = React.useState([]);

  const handleClick = (event, params) => {
    setPopperAnchor(popperAnchor ? null : event.currentTarget);
    setFieldValues(params.row.field_values);
  };

  const openPopper = Boolean(popperAnchor);

  // Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      hover: "none",
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      width: 50,
      hover: "none",
      hide: true,
    },
    {
      field: "custom_ticket_field_name",
      headerName: "Custom Field Name",
      width: 300,
      flex: 4,
      hover: "none",
    },

    {
      field: "field_alias",
      headerName: "Alias",
      width: 200,
      hover: "none",
      flex: 4,
    },
    {
      field: "field_type",
      headerName: "Field Type",
      width: 250,
      hover: "none",
      flex: 2,
    },
    {
      field: "field_values",
      headerName: "Field Values",
      width: "7%",
      align: "center",
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <Box>
          <IconButton
            title="View Field Values"
            onClick={(e) => handleClick(e, params)}
            sx={{ border: "0" }}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>

          <Popper
            open={openPopper}
            anchorEl={popperAnchor}
            sx={{ width: "7%" }}
          >
            <Paper sx={{ border: 0.5, p: 1.5, boxShadow: 3 }}>
              {fieldValues.length ? (
                fieldValues.map((item, index) => (
                  <Typography>{item.value}</Typography>
                ))
              ) : (
                <span style={{ color: "gray" }}>No Values Found</span>
              )}
            </Paper>
          </Popper>
        </Box>
      ),
    },
    {
      field: "has_parent_field",
      headerName: "Has Parent",
      width: 200,
      flex: 2,
    },
    {
      field: "parent_field_id",
      headerName: "Parent Ticket Field Name",
      width: 250,
      flex: 4,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      flex: 2,
      width: 160,
      align: "center",
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  //  datagrid rows
  const rows = [];
  if (props.customticketfields.hits) {
    props.customticketfields.hits.map((item, index) => {
      return rows.push({
        // id: item._id
        //   ? props.pagination.current_page_no * page_limit + ++index
        //   : "",
        sl_no: item._source.order_number,
        id: item._id,
        custom_ticket_field_name: item._source.field_name,
        field_alias: item._source.field_alias,
        field_type:
          item._source.field_type === "category"
            ? "ticket category"
            : item._source.field_type,
        has_parent_field: item._source.has_parent_field,
        parent_field_id: item._source.parent_field.parent_field_name,
        field_values: item._source.field_values,
      });
    });
  }

  // pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (customFieldName !== "") {
      filter.push(
        `["field_name.keyword","must","","match","${customFieldName}"]`
      );
    }
    if (fieldType !== "") {
      fieldType === "ticket category"
        ? filter.push(`["field_type.keyword","must","","match","category"]`)
        : filter.push(
            `["field_type.keyword","must","","match","${fieldType}"]`
          );
    }

    if (FilterClicked) {
      if (fieldType !== "" || customFieldName !== "") {
        filter_params = {
          order_by: `[["order_number","asc"]]`,
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      filter_params = {
        order_by: `[["order_number","asc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetchCustomticketfields(filter_params);
    }
  };

  // filter function
  const applyFilter = () => {
    let filter_params = {};
    const filter = [];

    if (customFieldName !== "") {
      filter.push(
        `["field_name.keyword","must","","match","${customFieldName}"]`
      );
    }
    if (fieldType !== "") {
      fieldType === "ticket category"
        ? filter.push(`["field_type.keyword","must","","match","category"]`)
        : filter.push(
            `["field_type.keyword","must","","match","${fieldType}"]`
          );
    }

    if (fieldType !== "" || customFieldName !== "") {
      filter_params = {
        order_by: `[["order_number","asc"]]`,
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      fetchCustomticketfields(filter_params);
    } else {
      alert("Please Add Filter");
      setClearLoader(false);
    }
  };

  // customfiledticket name list
  let customticketfieldName = [];
  if (props.customticketfield_filter.hits) {
    props.customticketfield_filter.hits.forEach((value) => {
      customticketfieldName.push(value._source.field_name);
    });
  }

  customticketfieldName.sort((a, b) => a.localeCompare(b));
  customticketfieldName = [...new Set(customticketfieldName)];

  const fieldType_HandleChange = (event, value) => {
    if (value != null) {
      setfieldType(value);
    } else {
      setfieldType("");
    }
  };

  const CustomticketFiledname_handleChange = (event, value) => {
    if (value != null) {
      setCustomFieldName(value);
    } else {
      setCustomFieldName("");
    }
  };

  // Clear states
  const ClearState = () => {
    if (customFieldName !== "" || fieldType !== "") {
      setClearLoader(true);
      setCustomFieldName("");
      setfieldType("");
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    // ******************************
    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetchCustomticketfields(params);
  };

  let user_last_working_project_id;

  if (props.lastWorkingProject && props.lastWorkingProject._source) {
    user_last_working_project_id =
      props.lastWorkingProject._source.project &&
      props.lastWorkingProject._source.project.project_id;
  }

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["field_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    data &&
      props
        .dispatch(
          CustomTicketFieldsAction.requestPutCustomefieldsBulkUpdate(bulkData)
        )
        .then(() => {
          props.dispatch(
            CustomTicketFieldsAction.requestCustomTicketFields(paramss)
          );
          props.dispatch(
            CustomTicketFieldsAction.requestCustomTicketFieldsFilter(param)
          );
        });
  };

  return (
    <React.Fragment>
      <Box>
        {/* modal screen */}

        <CustomTicketPost
          open={open}
          edit={edit}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          projectEditData={editRowsModel}
          customticketfield_data={props.customticketfield_filter}
          customFieldArray={customFieldArray}
          user_last_working_project_id={
            user_last_working_project_id !== undefined
              ? user_last_working_project_id
              : ""
          }
        />
        {/* <Dialog open={openEye}></Dialog> */}
        {/* FILTER CONTAINER */}
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="space-between"
          spacing={1}
          pr={4}
        >
          <Grid item lg={2} md={3} sm={3.5} mt={1}>
            <AutoCompleteComponent
              value={customFieldName}
              handleChange={CustomticketFiledname_handleChange}
              list={customticketfieldName}
              textFieldProps={{
                placeholder: "Custom Field Name",
              }}
              size="small"
              width="100%"
            />
          </Grid>{" "}
          <Grid item lg={2} md={3} sm={3.5} mt={1}>
            <AutoCompleteComponent
              value={fieldType}
              handleChange={fieldType_HandleChange}
              list={field_type}
              textFieldProps={{
                placeholder: "Field Type",
              }}
              size="small"
              width="100%"
            />{" "}
          </Grid>
          <Grid item lg={8} xl={8} md={6} sm={5}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={2}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                lg={8}
                sm={8}
                md={8}
                display={"flex"}
                justifyContent={"end"}
                mt={1}
                mr={2}
                sx={{ gap: 2 }}
              >
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    name={"Filter"}
                    width="100%"
                    onClick={applyFilter}
                    loading={filterLoader && isRequesting}
                  />
                </Grid>
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    name={"Clear"}
                    width="100%"
                    onClick={ClearState}
                    loading={clearLoader && isRequesting}
                  />
                </Grid>
              </Grid>

              <Grid>
                {/* post button component*/}
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {navigator.onLine ? (
          <Box>
            <Grid item lg={12} md={12} sm={12} xs={12} pr={1}>
              <LoadingIndicator isActive={isRequesting}>
                {/* datagrid component */}
                {props.customticketfields.hits && !isRequesting ? (
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={60}
                    rowReordering
                    onRowOrderChange={rowOrderHandleChange}
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "80vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingBgImage />
                  </Box>
                )}
              </LoadingIndicator>
            </Grid>
            {/* Pagination */}{" "}
            {/* <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
              className="pagination_section"
            >
              <Grid item lg={12} md={12} sm={12}>
                {props.customticketfields.hits
                  ? props.customticketfields.hits.length !== 0 && (
                      <PaginationUI
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  : null}
              </Grid>
            </Grid> */}
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(CustomTicketFields);
