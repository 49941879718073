import BaseReducer from "../../../utilities/BaseReducer";
import AiSuggestionAction from "./AiSuggestionAction";

export default class AiSuggestionReducer extends BaseReducer {
  initialState = {
    aiSuggestions: [],
  };
  [AiSuggestionAction.REQUEST_AI_SUGGESTION_FINISHED](state, action) {
    return {
      ...state,
      aiSuggestions: action.payload,
    };
  }
}
