import BaseReducer from "../../../../utilities/BaseReducer";
import UserProfileAction from "./UserProfileAction";

// Release by id Reducer
export default class UserProfileReducer extends BaseReducer {
  initialState = {
    userprofile: [],
  };
  [UserProfileAction.REQUEST_USERPROFILE_FINISHED](state, action) {
    return {
      ...state,
      userprofile: action.payload,
    };
  }
}
