import BaseReducer from "../../../../utilities/BaseReducer";
import LinkCheckingAction from "./LinkCheckingAction";

export default class ArchivedLinkFilterReducer extends BaseReducer {
  initialState = {
    archivedLinksFilter: [],
  };
  [LinkCheckingAction.REQUEST_ARCHIVED_LINKS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      archivedLinksFilter: action.payload,
    };
  }
}
