import BaseReducer from "../../../utilities/BaseReducer";
import MailTiggerListAction from "./MailTiggerListAction";

// MailTriggerListReduser
export default class MailTriggerListReduser extends BaseReducer {
  initialState = {
    trigger_list: [],
  };
  [MailTiggerListAction.REQUEST_TRIGGER_LIST_FINISHED](state, action) {
    return {
      ...state,
      trigger_list: action.payload,
    };
  }
}
