import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import InstagramAccountDeleteModel from "./model/InstagramAccountDeleteModel";
import InstagramAccountModel from "./model/InstagramAccountModel";
import InstagramAccountPutModel from "./model/InstagramAccountPutModel";

// InstagramAccountEffect
export default class InstagramAccountEffect {
  // calling an API according to theInstagram Account model
  // Get method for theInstagram account
  static async requestInstagramAccount(params) {
    const endpoint = environment.api.instagramAccount;
    return EffectUtility.getToModel(InstagramAccountModel, endpoint, params);
  }

  // Get method for theInstagram account filter
  static async requestInstagramAccountFilter(params) {
    const endpoint = environment.api.instagramAccountFilter;
    return EffectUtility.getToModel(InstagramAccountModel, endpoint, params);
  }

  // put method for theInstagram account filter
  static async requestPutInstagramAccount(data, id) {
    const endpoint = environment.api.instagramAccount + "/" + id;
    return EffectUtility.putToModel(InstagramAccountPutModel, endpoint, data);
  }

  // DELETE method for theInstagram account filter
  static async requestDeleteInstagramAccount(id) {
    const endpoint = environment.api.instagramAccount + "/" + id;
    return EffectUtility.deleteToModel(InstagramAccountDeleteModel, endpoint);
  }
}
