import BaseReducer from "../../../../utilities/BaseReducer";
import AppStoreAction from "./AppStoreAction";

// reducer function
export default class AppStoreReducer extends BaseReducer {
  initialState = {
    appstore: [],
  };

  [AppStoreAction.REQUEST_APPSTORE_FINISHED](state, action) {
    return {
      ...state,
      appstore: action.payload,
    };
  }

  [AppStoreAction.REQUEST_APPSTORE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      appstore: action.payload,
    };
  }
}
