import BaseReducer from "../../../../utilities/BaseReducer";
import CmsLinkStatusLogAction from "./CmsLinkStatusLogAction";

export default class CmsLinkStatusLogReducer extends BaseReducer {
  initialState = {
    CmsLinkStatusLog: [],
  };

  [CmsLinkStatusLogAction.REQUEST_CMS_LINKS_STATUS_LOG_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      CmsLinkStatusLog: action.payload,
    };
  }
}
