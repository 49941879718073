import BaseReducer from "../../../utilities/BaseReducer";
import GmbQuestionFeedsAction from "./GmbQuestionFeedsAction";

export default class GmbQuestionsFilterReduser extends BaseReducer {
  //initial state of GmbQuestionsFilterReduser
  initialState = {
    GMBQuestionFeedsFilter: [],
  };
  //GmbQuestionsFilterReduser request action finish
  [GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      GMBQuestionFeedsFilter: action.payload,
    };
  }
}
