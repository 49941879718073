import { BaseModel } from "sjs-base-model";

// Google Login Model
export default class GoogleLoginModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  constructor(data1) {
    super();

    this.update(data1);
  }
}
