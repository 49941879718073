import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { NivoTreeMapChart } from "../../../components/nivoCharts/NivoTreeMapChart";
import { firstLetterOrange } from "../../../components/ticketPartials";

function MultilevelTicketCategoryReport(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [categoryData, setCategoryData] = useState(null);
  const [customfieldCategory, setCustomFieldCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  function getLast3monthStartAndThismonthEndDate() {
    let date = new Date();
    const last3monthStartDate = new Date(
      date.getFullYear(),
      date.getMonth() - 2,
      2
    );
    const last3monthstartDatestring = last3monthStartDate
      .toISOString()
      .slice(0, 10);

    const currentMonthEnddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    );
    const currentMonthendDatestring = currentMonthEnddate
      .toISOString()
      .slice(0, 10);

    return {
      last3monthStartDate: last3monthstartDatestring,
      currentMonthEnddate: currentMonthendDatestring,
    };
  }

  let { last3monthStartDate, currentMonthEnddate } =
    getLast3monthStartAndThismonthEndDate();

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchCategoryData().then((res) => {
        setCategoryData(res);
      });

      await fetchCustomFiledCategory().then((res) => {
        setCustomFieldCategory(res);
      });
      setLoading(false);
    })();
  }, []);

  async function fetchCategoryData() {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["multilevel_category"]&from=${last3monthStartDate}&to=${currentMonthEnddate}`;
    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then(async (response) => {
        return response;
      })
      .catch((err) => {});
  }

  async function fetchCustomFiledCategory() {
    let API = `${environment.api.baseApi}/custom_ticket_fields?filters=[["field_type","must","","match","category"]]`;
    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then(async (response) => {
        return response;
      })
      .catch((err) => {});
  }

  let categoriesList = [];
  categoriesList =
    customfieldCategory &&
    customfieldCategory.hits &&
    customfieldCategory.hits.hits &&
    customfieldCategory.hits.hits.length > 0 &&
    customfieldCategory.hits.hits[0]._source.field_values;

  let categoryValuesList = [];

  function AppendCategoryNames(valuesArray) {
    valuesArray &&
      valuesArray.length > 0 &&
      valuesArray.map((item) => {
        categoryValuesList.push(item);
        if (item.sub_value.length > 0) {
          AppendCategoryNames(item.sub_value);
        }
      });
  }

  categoriesList &&
    categoriesList.length > 0 &&
    AppendCategoryNames(categoriesList);

  function convertDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const today = new Date(); // Get the current date

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastSecondFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // Set the date to the first day of the previous month

  const lastThirdFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    1
  ); // Set the date to the first day of the previous month

  let currentMonth = convertDate(thisMonth);
  let lastSecond = convertDate(lastSecondFirstDay);
  let lastThird = convertDate(lastThirdFirstDay);

  const summaryBoxRef = useRef(null);
  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;
      const firstBoxHeight = 0.1 * outerBoxHeight;
      const secondBoxHeight = 0.9 * outerBoxHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `50px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 50
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const [renderKey, setRenderKey] = useState(0);

  let chartData = categoryData?.ticket_report?.multilevel_category
    ? [...categoryData?.ticket_report?.multilevel_category]
    : [];

  function removeCountProperty(obj) {
    // If the object has children and it's an array with length greater than 0
    if (
      obj.children &&
      Array.isArray(obj.children) &&
      obj.children.length > 0
    ) {
      // Remove the 'count' property
      delete obj.count;
    }

    // If the object has a 'key' property, update it using the custom function
    if (obj.key) {
      const findValueById = (arr, targetId) => {
        for (const obj of arr) {
          if (obj.id === targetId) {
            return obj.value;
          }

          if (obj.sub_value.length > 0) {
            const result = findValueById(obj.sub_value, targetId);
            if (result) {
              return result;
            }
          }
        }

        return null;
      };
      obj.key = findValueById(
        customfieldCategory?.hits?.hits[0]?._source?.field_values,
        obj.key
      );
    }

    // Recursively process children
    if (obj.children && Array.isArray(obj.children)) {
      obj.children.forEach((child) => {
        removeCountProperty(child);
      });
    }
  }

  useEffect(() => {
    if (chartData && chartData[0] && customfieldCategory) {
      removeCountProperty(chartData[0]);
    }
  }, [chartData, chartData[0], customfieldCategory]);

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
      }}
      key={renderKey}
    >
      <LoadingIndicator isActive={loading}>
        <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontSize: theme.reports.headerSize,
                padding: "10px 20px",
              }}
            >
              {firstLetterOrange(props.report && props.report)}
            </Typography>

            <hr className="p-0 m-0 " />
          </Box>

          <Box
            className="scrollable"
            sx={{
              padding: "12px",
              overflowY: "scroll",
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  width: "3px",
                  height: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#b0d2da",
                  borderRadius: "3px",
                  "&:hover": {
                    backgroundColor: "#85bac6",
                  },
                },
              },
            }}
          >
            {categoryData &&
              categoryData.ticket_report &&
              categoryData.ticket_report.multilevel_category &&
              customfieldCategory &&
              customfieldCategory?.hits?.hits[0] && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <NivoTreeMapChart chartData={chartData[0]} />
                </Box>
              )}
          </Box>
        </Box>
      </LoadingIndicator>
    </Box>
  );
}

export default MultilevelTicketCategoryReport;
