import { BaseModel } from "sjs-base-model";

//Priority list source data model
export default class PrioritySourceModel extends BaseModel {
  color = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = "";
  modified_on = "";
  ticket_priority_name = "";
  order_number = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
