import BaseReducer from "../../../../utilities/BaseReducer";
import ContactAction from "./ContactAction";

export default class ContactFilterReducer extends BaseReducer {
  //initial state of ContactFilterReduser
  initialState = {
    Contact_filter: [],
  };
  //ContactFilterReduser request action finish
  [ContactAction.REQUEST_CONTACT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      Contact_filter: action.payload,
    };
  }

  [ContactAction.REQUEST_CONTACT_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Contact_filter: action.payload,
    };
  }
}
