import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { connect } from "react-redux";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import environment from "environment";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import YoutubeAction from "../../../stores/youtube/YoutubeAction";
import YoutubeAccountAction from "../../../stores/youtubeAccount/YoutubeAccountAction";
import { axiosRequest } from "../../../../utilities/FetchRequest";

//mapState to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    YoutubeAction.REQUEST_YOUTUBE,
    YoutubeAction.REQUEST_YOUTUBE_FILTER,
    YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FILTER,
  ]),
  youtubeFeed: state.youtubeFeed.youtubeFeed || [],
  youtubeFeedFilter: state.youtubeFeedFilter.youtubeFeedFilter || [],
  youtubeAccountFilter: state.youtubeAccountFilter.youtubeAccountFilter || [],
  pagination: state.youtubeFeed.youtubeFeed || [],
});

function YoutubeFeedsListView(props) {
  //State variables
  const [value, setValue] = React.useState("1");
  const [customerName, setCustomerName] = React.useState(null);
  const [page_limit] = React.useState(10);
  const [date, setDate] = React.useState([null, null]);
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [accountName, setAccountName] = useState(null);

  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };
  //tab handle change
  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  function fetchYoutubeFeed(params) {
    props.dispatch(YoutubeAction.requestYoutube(params));
  }

  function fetchYoutubeFeedFilter(params) {
    props.dispatch(YoutubeAction.requestYoutubeFilter(params));
  }

  function fetchYoutubeAccountFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(YoutubeAccountAction.requestYoutubeAccountFilter(params));
  }

  useEffect(() => {
    const param = { page_limit: "none" };
    fetchYoutubeFeedFilter(param);
    fetchYoutubeAccountFilter();

    const filter = [];
    let params = {};

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== ""
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,

        order_by: '[["created_on", "desc"]]',
      };
    } else {
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }

    fetchYoutubeFeed(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Customer names list assigning
  let customerList = [];

  if (props.youtubeFeedFilter.hits) {
    props.youtubeFeedFilter.hits.hits.forEach((val) => {
      customerList.push(val._source.customer_name);
    });
  }
  const uniqueCustomerList = [...new Set(customerList)];

  const CustomerHandleChange = (e, val) => {
    setCustomerName(val);
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val("");
    }
  };

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName(null);
    }
  };

  //Filter Function
  const ApplyFilter = () => {
    let params = {};
    const filter = [];

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      accountName !== null ||
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== ""
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,

        order_by: '[["created_on", "desc"]]',
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Select Filters");
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }

    fetchYoutubeFeed(params);
  };

  // Clear method
  const ClearState = () => {
    setFilterClicked(false);
    if (
      accountName !== null ||
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== ""
    ) {
      setCustomerName(null);
      setDate([null, null]);
      setSentimental_val("");
      setClearLoader(true);
      setSearchQuery("");
      setAccountName(null);

      if (navigator.onLine === true) {
        const params = {
          order_by: '[["created_on", "desc"]]',
          page_limit: `${page_limit}`,
        };
        FilterClicked && fetchYoutubeFeed(params);
      } else {
        alert("No internet connection");
      }
    }
  };

  //Pagination
  const handlePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }
    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (FilterClicked) {
      if (
        accountName !== null ||
        customerName !== null ||
        searchQuery !== "" ||
        (date[0] !== null && date[1] !== null) ||
        sentimental_val !== ""
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: '[["created_on", "desc"]]',
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchYoutubeFeed(params);
    }
  };

  const youtube_list = [];

  if (props.youtubeAccountFilter.hits) {
    props.youtubeAccountFilter.hits.hits.map((value) => {
      youtube_list.push(value._source.account_name);
    });
  }

  const youtube_uniqueList = [...new Set(youtube_list)];
  let maxDate = new Date();

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);

    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number"
    ) {
      if (item._source.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item._source.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item._source.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }

    return sentiment;
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}

      <Grid container>
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            mt={2}
            container
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={2}>
                  <span>Account Name</span>
                </Grid>
                <AutoCompleteComponent
                  value={accountName}
                  list={youtube_uniqueList}
                  handleChange={accountNameHandleChange}
                  textFieldProps={{
                    placeholder: "Select Account",
                  }}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={2}>
                  <span>Search Content</span>
                </Grid>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      borderRadius:
                        theme.palette.buttonComponent.borderRadius.medium,
                      outline: `1px ${theme.palette.borderColor.textField}`,
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.typography.padding,
                      height: "35px",
                      width: "100%",
                    }}
                    id="search_bar"
                    autoComplete={"off"}
                    value={searchQuery}
                    placeholder={"Search by Comment Content"}
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment>
                        <IconButton onClick={ApplyFilter}>
                          <SearchIcon
                            sx={{
                              "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Customer Name</p>
                </Grid>
                <AutoCompleteComponent
                  value={customerName}
                  list={uniqueCustomerList}
                  textFieldProps={{
                    placeholder: "Customer Name",
                  }}
                  handleChange={CustomerHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentimental Value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={2} lg={3} md={12} sm={12} mt={0}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={3.5}
                >
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={filterLoader && isRequesting}
                      name={"Filter"}
                      onClick={ApplyFilter}
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Comments</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid>
          <LoadingIndicator isActive={isRequesting}>
            {props.youtubeFeed.hits ? (
              <Box>
                {props.youtubeFeed.hits.hits.length !== 0 ? (
                  props.youtubeFeed.hits.hits.map(
                    (item, key) =>
                      !isRequesting && (
                        <Box
                          sx={{
                            padding: 0,
                            margin: "1.2%",
                            marginTop: 0,
                            marginBottom: "0.5%",
                          }}
                        >
                          <List
                            sx={{
                              width: "100%",
                              cursor:
                                item && item._source.ticket_id
                                  ? "pointer"
                                  : null,
                            }}
                            onClick={() => {
                              localStorage.setItem("activeTab", 0);

                              item._source &&
                                item._source.ticket_id &&
                                openTicketFunction(item._source.ticket_id);
                            }}
                          >
                            <Grid
                              container
                              sx={{
                                backgroundColor: "#fff",
                                height: "auto",
                                padding: 2,
                                borderRadius: theme.borderRadius,
                                border: "solid 0.5px #d3e2e6",
                                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                              }}
                            >
                              <Grid container lg={12} md={12} sm={12}>
                                <Grid
                                  item
                                  lg={1}
                                  md={2}
                                  sm={2}
                                  maxWidth={"80px !important"}
                                >
                                  <ListItem>
                                    {/* <ListItemAvatar>
                                      <Avatar></Avatar>
                                    </ListItemAvatar> */}
                                    <img
                                      src={
                                        item._source.commented_user_image_url
                                      }
                                      style={{
                                        height: 50,
                                        width: 50,
                                        borderRadius: 25,
                                      }}
                                      alt=""
                                    />
                                  </ListItem>
                                </Grid>
                                <Grid item lg={10} md={9} sm={9} container>
                                  <Grid item lg={12} md={12} sm={12} container>
                                    <Grid item lg={6} md={12} sm={12}>
                                      <ListItem>
                                        <h6 style={{ marginRight: "20px" }}>
                                          {item._source.customer_name}{" "}
                                        </h6>
                                        <p>
                                          {" "}
                                          {(function createdDate() {
                                            let created =
                                              item._source.created_on;
                                            let dateConversion = new Date(
                                              created
                                            );
                                            dateConversion =
                                              dateConversion.toDateString() +
                                              ", " +
                                              dateConversion.toLocaleTimeString(
                                                "en-US",
                                                {
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                                }
                                              );

                                            return dateConversion;
                                          })()}
                                        </p>
                                      </ListItem>
                                    </Grid>
                                    <Grid item lg={3} md={12} sm={12}>
                                      <ListItem>
                                        <h6>Activity :</h6>&nbsp;
                                        {item._source.activities}
                                      </ListItem>
                                    </Grid>
                                    <Grid item lg={3} md={12} sm={12}>
                                      <ListItem>
                                        <h6>Video Title :</h6>
                                        &nbsp;&nbsp;&nbsp;
                                        <Box
                                          title="click here to watch"
                                          sx={{
                                            cursor: "pointer",

                                            height: 20,
                                          }}
                                          name={item._source.video_title}
                                          onClick={() => {
                                            window.open(item._source.video_url);
                                          }}
                                        >
                                          <b className="videoLink">
                                            {item._source.video_title}
                                          </b>
                                        </Box>
                                      </ListItem>
                                    </Grid>
                                  </Grid>
                                  <Grid item lg={12} md={12} sm={12}>
                                    <ListItem>
                                      <Typography>
                                        <b
                                          style={{
                                            fontSize:
                                              theme.palette.buttonComponent
                                                .fontSize.extraLarge,
                                            lineHeight: "22px",
                                          }}
                                        >
                                          Comment Content :
                                        </b>
                                        &nbsp;
                                        {item._source.comment_content}
                                      </Typography>
                                    </ListItem>
                                  </Grid>
                                  {item._source.sentiment_value !== null ? (
                                    <Grid item lg={3} md={3} sm={3}>
                                      <ListItem>
                                        <Typography>
                                          <b
                                            style={{
                                              fontSize:
                                                theme.palette.buttonComponent
                                                  .fontSize.extraLarge,
                                              lineHeight: "22px",
                                            }}
                                          >
                                            Sentiment value :
                                          </b>
                                          &nbsp;
                                          {item._source.sentiment_value &&
                                          typeof item._source
                                            .sentiment_value === "number" &&
                                          item._source.sentiment_value > 0 ? (
                                            <IconButton
                                              className="p-0 m-0"
                                              title={returnSentimentname(item)}
                                            >
                                              <SentimentSatisfiedAltRoundedIcon
                                                titleAccess="Positive"
                                                style={{
                                                  width: "16px",
                                                  color: `green`,
                                                }}
                                              />
                                            </IconButton>
                                          ) : item._source.sentiment_value <
                                            0 ? (
                                            <IconButton
                                              className="p-0 m-0"
                                              title={returnSentimentname(item)}
                                            >
                                              <SentimentDissatisfiedRoundedIcon
                                                titleAccess="Negative"
                                                style={{
                                                  width: "16px",
                                                  color: `${theme.palette.sentimentIconColor.negative}`,
                                                }}
                                              />
                                            </IconButton>
                                          ) : item._source.sentiment_value !==
                                            null ? (
                                            <IconButton
                                              className="p-0 m-0"
                                              title={returnSentimentname(item)}
                                            >
                                              <SentimentNeutralRoundedIcon
                                                titleAccess="Neutral"
                                                style={{
                                                  width: "16px",
                                                  color: `${theme.palette.sentimentIconColor.neutral}`,
                                                }}
                                              />
                                            </IconButton>
                                          ) : (
                                            ""
                                          )}
                                        </Typography>
                                      </ListItem>
                                    </Grid>
                                  ) : null}

                                  <Grid item lg={3} md={3} sm={3}>
                                    <ListItem>
                                      <Typography>
                                        <b
                                          style={{
                                            fontSize:
                                              theme.palette.buttonComponent
                                                .fontSize.extraLarge,
                                            lineHeight: "22px",
                                          }}
                                        >
                                          Account Name :
                                        </b>
                                        &nbsp;
                                        {item._source.account_name}
                                      </Typography>
                                    </ListItem>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  container
                                  lg={1}
                                  md={2}
                                  sm={2}
                                  alignItems={"flex-start"}
                                  justifyContent="space-between"
                                >
                                  {item._source.video_url && (
                                    <Grid item lg={1} xl={1} md={1} sm={1}>
                                      <Typography
                                        component={"a"}
                                        href={
                                          item._source.video_url
                                            ? item._source.video_url
                                            : ""
                                        }
                                        target="_blank"
                                        title="Video link"
                                      >
                                        <OpenInNewIcon
                                          fontSize="25px"
                                          sx={{ cursor: "pointer" }}
                                        />
                                      </Typography>
                                    </Grid>
                                  )}

                                  {circuler_progress_id ===
                                    item._source.ticket_id &&
                                    open_ticket && (
                                      <CircularProgress
                                        color="inherit"
                                        size={15}
                                        // className="bg-light"
                                      />
                                    )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </List>
                        </Box>
                      )
                  )
                ) : (
                  <NoDataFound />
                )}
              </Box>
            ) : null}
          </LoadingIndicator>
        </Box>
      </Grid>
      {props.youtubeFeed.hits
        ? props.youtubeFeed.hits.hits.length !== 0 &&
          !isRequesting && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handlePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(YoutubeFeedsListView);
