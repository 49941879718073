import { BaseModel } from "sjs-base-model";

//Priority list source data model
export default class PlatformSourceModel extends BaseModel {
  created_by = "";
  created_on = "";
  modified_by = "";
  modified_on = "";
  other = {};
  platform_name = "";
  alias = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
