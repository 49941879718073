import BaseReducer from "../../../../utilities/BaseReducer";
import ProxyAction from "./ProxyAction";

export default class CustomiseProxyReducer extends BaseReducer {
  initialState = {
    customiseProxy: [],
  };
  [ProxyAction.REQUEST_CUSTOMISE_PROXY_FINISHED](state, action) {
    return {
      ...state,
      customiseProxy: action.payload,
    };
  }
}
