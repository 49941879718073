import BaseReducer from "../../../../utilities/BaseReducer";
import SurveyDataAction from "./SurveyDataAction";

export default class SurveyDataFilterReduser extends BaseReducer {
  //initial state of OrganizationFilterReduser
  initialState = {
    surveyDataFilter: [],
  };
  //OrganizationFilterReduser request action finish
  [SurveyDataAction.REQUEST_SURVEY_DATA_FILTER_FINISHED](state, action) {
    return {
      ...state,
      surveyDataFilter: action.payload,
    };
  }
}
