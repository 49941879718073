import BaseReducer from "../../../../utilities/BaseReducer";
import NvProjectAction from "./NvProjectAction";

export default class NvProjectFilterReduser extends BaseReducer {
  initialState = {
    websweepNvProjectFilter: [],
  };
  [NvProjectAction.REQUEST_NV_PROJECT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      websweepNvProjectFilter: action.payload,
    };
  }
}
