import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import HssDetailsGetModel from "./models/HssDetailsGetModel";
import HssPutModal from "./models/HssPutModal";
import HssPostModal from "./models/HssPostModal";
import HssDetailsExecuteScriptModel from "./models/HssDetailExecuteScriptModel";
import HssDetailsByIDGetModel from "./models/HssDetailsByIDGetModel";

export default class HssDetailsEffect {
  static async requestGetHssDetails(params, callBack) {
    const endpoint = environment.api.websweepXHssDetails;
    let response = EffectUtility.getToModel(
      HssDetailsGetModel,
      endpoint,
      params
    );
    if (callBack && response) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestGetHssDetailsByID(ID, callBack) {
    const endpoint = environment.api.websweepXHssDetails + "/" + ID;
    let response = EffectUtility.getToModel(HssDetailsByIDGetModel, endpoint);
    if (callBack && response) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestGetHssDetailsShadow(params, callback) {
    const endpoint = environment.api.websweepXHssDetailsShadow;
    let response = EffectUtility.getToModel(
      HssDetailsGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostHss(data, callBackFunction) {
    const endpoint = environment.api.websweepXHssDetails;

    let response = EffectUtility.postToModel(HssPostModal, endpoint, data);

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPutHss(data, id, callBackFunction) {
    const endpoint = environment.api.websweepXHssDetails + `/${id}`;

    let response = EffectUtility.putToModel(HssPutModal, endpoint, data);

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestGetHssDetailsExecuteScripts(params, callback) {
    const endpoint = environment.api.websweepXExecuteScripts;
    let response = EffectUtility.getToModel(
      HssDetailsExecuteScriptModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetHssDetailsExecuteAllScripts(params, callback) {
    const endpoint = environment.api.websweepXExecuteAllScripts;
    let response = EffectUtility.getToModel(
      HssDetailsExecuteScriptModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetHssDetailsPauseScripts(params, callback) {
    const endpoint = environment.api.websweepXPauseScripts;
    let response = EffectUtility.getToModel(
      HssDetailsExecuteScriptModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetHssDetailsResumneScripts(params, callback) {
    const endpoint = environment.api.websweepXResumeScripts;
    let response = EffectUtility.getToModel(
      HssDetailsExecuteScriptModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetHssDetailsStopScripts(params, callback) {
    const endpoint = environment.api.websweepXStopScripts;
    let response = EffectUtility.getToModel(
      HssDetailsExecuteScriptModel,
      endpoint,
      params
    );
    return response;
  }
}
