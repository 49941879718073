import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import ConsumerAccountAction from "../../stores/consumer_account/ConsumerAccountAction";
import MuiSnackbarAlert from "../../../components/muiSnackbarAlert/MuiSnackbarAlert";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};
const validationSchema = yup.object({
  account_name: yup
    .string("Enter your Account name")
    .trim()
    .min(1, "Account name should be of minimum 1 characters length")
    .required("Account name is required"),
});

const AddConsumerAccount = (props) => {
  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method
  let initial = {};
  const [noFiltersAlert, setNoFiltersAlert] = useState(false);

  const [fieldtypeValues, setFieldtypeValues] = useState("");
  const [theArray, setTheArray] = useState([]);
  const [loading, setLoading] = useState(false);

  if (props.consumerAccountData) {
    initial = {
      account_name: props.consumerAccountData.account_name,
      field_values: "",
    };
  } else {
    initial = {
      account_name: "",
      field_values: "",
    };
  }

  useEffect(() => {
    if (props.consumerAccountData && props.consumerAccountData.keywords) {
      props.consumerAccountData.keywords.map((item) => {
        theArray.push(item);
      });
    } else {
      const InitialArray = [];
      setTheArray(InitialArray);
    }
  }, [props.open]);

  const addEntryClick = () => {
    if (fieldtypeValues > 1 || fieldtypeValues.trim()) {
      setTheArray([...theArray, fieldtypeValues]);
    }

    setFieldtypeValues("");
  };

  const handleChangeFor = (event) => {
    if (
      event.target.value.length > 1 ||
      event.target.value !== null ||
      event.target.value.trim()
    ) {
      setFieldtypeValues(event.target.value);
    }
  };

  const deleteEntry = (index) => {
    const updatedArray = [...theArray];
    updatedArray.splice(index, 1);
    setTheArray(updatedArray);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        // validate={(values) => {
        //   var errors = {};
        //   if (!theArray.length) {
        //     errors.field_values = "Add Atleast one keyword";
        //   }
        //   return errors;
        // }}
        validateOnChange
        onSubmit={(values) => {
          if (theArray && theArray.length > 0) {
            setLoading(true);
            let obj = {};

            Object.assign(
              obj,
              values.account_name && { account_name: values.account_name },
              theArray.length > 0 && { keywords: theArray }
            );

            if (props.consumerAccountData) {
              props
                .dispatch(
                  ConsumerAccountAction.requestPutConsumerAccount(
                    JSON.stringify(obj),
                    props.consumerAccountData._id
                  )
                )
                .then(() => {
                  let params = {
                    page_limit: "none",
                  };
                  let account_params = {
                    page_limit: "none",
                    order_by: '[["created_on", "desc"]]',
                  };
                  setLoading(false);

                  props.onClose();
                  props.setAdd_account("");
                  props.dispatch(
                    PlatformAccountsAction.requestPlatformAccount(
                      account_params
                    )
                  );
                  props.dispatch(
                    PlatformAccountsAction.requestPlatformAccountFilter(params)
                  );
                });
            } else {
              props
                .dispatch(
                  ConsumerAccountAction.requestPostConsumerAccount(
                    JSON.stringify(obj)
                  )
                )
                .then(() => {
                  let params = {
                    page_limit: "none",
                  };
                  let account_params = {
                    page_limit: "none",
                    order_by: '[["created_on", "desc"]]',
                  };
                  setLoading(false);
                  props.onClose();
                  props.setAdd_account("");
                  props.dispatch(
                    PlatformAccountsAction.requestPlatformAccount(
                      account_params
                    )
                  );
                  props.dispatch(
                    PlatformAccountsAction.requestPlatformAccountFilter(params)
                  );
                });
            }
          } else {
            setNoFiltersAlert(true);
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          formik,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-11">
                      <h3 className="dailogtext">
                        <b>
                          {props.consumerAccountData ? (
                            <>
                              <span className="span_first_letter">E</span>
                              dit &nbsp;
                            </>
                          ) : (
                            <>
                              <span className="span_first_letter">A</span>
                              dd &nbsp;
                            </>
                          )}
                          <span className="span_first_letter">C</span>onsumer
                          &nbsp;
                          <span className="span_first_letter">C</span>omplaint
                          &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div
                      className="col-1 closebtn"
                      onClick={() => {
                        props.onClose();
                        props.setAdd_account("");
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="account_name"
                      size="small"
                      name="account_name"
                      placeholder="Account Name"
                      error={
                        Boolean(errors.account_name) &&
                        Boolean(touched.account_name)
                      }
                      helperText={
                        Boolean(touched.account_name) && errors.account_name
                      }
                    />
                  </Grid>
                </Grid>
                {theArray && theArray.length !== 0 && (
                  <Grid item lg={12} md={12} sm={12} my={1} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      Keywords * :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} mt={1}>
                      {theArray &&
                        theArray.map((entry, index) => (
                          <Grid item xs={12} key={index} mt={1}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Chip
                                label={entry}
                                sx={{ marginRight: "10px" }}
                                onDelete={() => {
                                  deleteEntry(index);
                                }}
                                deleteIcon={<CancelIcon />}
                              />
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Add Values * :
                  </Grid>

                  <MuiSnackbarAlert
                    open={noFiltersAlert}
                    onClose={() => setNoFiltersAlert(false)}
                    message={"Add Atleast one keyword"}
                  />

                  <Grid item lg={6} md={6} sm={6} sx={{ display: "flex" }}>
                    <Field
                      // text feild from material Ui
                      // disabled={props.edit === true ? true : false}
                      as={MuiTextField}
                      width="200px"
                      variant="outlined"
                      id="field_values"
                      name="field_values"
                      placeholder="Custom field values"
                      onChange={handleChangeFor}
                      size="small"
                      value={fieldtypeValues}
                      // error={
                      //   Boolean(errors.field_values) &&
                      //   Boolean(touched.field_values)
                      // }
                      // helperText={
                      //   Boolean(touched.field_values) && errors.field_values
                      // }
                    />
                    <MuiButton
                      size="small"
                      name={"Add"}
                      onClick={addEntryClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 mb-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={
                        props.consumerAccountData
                          ? "Edit Account"
                          : "Add Account"
                      }
                      loading={loading}
                      type="submit"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Edit Project "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={() => {
                        if (props.consumerAccountData) {
                          setTheArray([]);
                          const newArray = [
                            ...props.consumerAccountData.keywords,
                          ];
                          setTheArray(newArray);
                        } else {
                          setTheArray([]);
                        }
                        setFieldValue("account_name", "");
                        setFieldtypeValues("");
                      }}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AddConsumerAccount);
