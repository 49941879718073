import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencerDashboardAction from "./InfluencerDashboardAction";

// InfluencerDashboardReducer
export default class InfluencerDashboardReducer extends BaseReducer {
  initialState = {
    influencerDashboard: [],
  };
  [InfluencerDashboardAction.REQUEST_INFLUENCER_DASHBOARD_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencerDashboard: action.payload,
    };
  }
}
