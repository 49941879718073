import ActionUtility from "../../utilities/ActionUtility";
import GoogleLoginEffect from "./GoogleLoginEffect";
export default class GoogleLoginAction 
{
  // Gooogle action with an function declaration
  static REQUEST_GOOGLE_LOGIN = "GoogleLoginAction.REQUEST_GOOGLE_LOGIN";
  static REQUEST_REQUEST_GOOGLE_LOGIN_FINISHED = "GoogleLoginAction.REQUEST_REQUEST_GOOGLE_LOGIN_FINISHED";


  // METHODS
  // Google Login GET function
  static requestGoogleLogin(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleLoginAction.REQUEST_GOOGLE_LOGIN,
        GoogleLoginEffect.requestGoogleLogin,
        params
      );
    };
  }
}
