import BaseReducer from "../../../utilities/BaseReducer";
import TicketReportsAction from "./TicketReportsAction";

export default class SentimentalAnalysisReportReducer extends BaseReducer {
  initialState = {
    sentimentalAnalysisReport: {},
  };

  [TicketReportsAction.REQUEST_SENTIMENTAL_ANALYSIS_REPORTS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      sentimentalAnalysisReport: action.payload,
    };
  }
}
