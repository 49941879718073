import {
  Box,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import AiSuggestionAction from "../../../../stores/aiSuggestions/AiSuggestionAction";
import copy from "copy-to-clipboard";
import { theme } from "../../../../../views/App";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LoadingBgImage from "../../../../../components/loadingBgImage/LoadingBgImage";

// mapstateto props function fetching both project api and category api
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AiSuggestionAction.REQUEST_BARD_AI_SUGGESTION,
  ]),
  BardAiSuggestions: state.BardAiSuggestions.BardAiSuggestions || [],
});

const BardAISuggestionTab = (props) => {
  const [loading, setLoading] = useState(false);
  const { openAIsuggestionTab, setOpenAIsuggestionTab, setAiSuggestionTab } =
    props;

  const [suggestionData, setSuggestionData] = useState([]);
  const [regenerateLoading, setRegenerateLoading] = useState(false);

  function getObjectById(array, idToFind) {
    return array.find((obj) => obj.feedId === idToFind);
  }

  useEffect(() => {
    const feed_id = JSON.parse(
      sessionStorage.getItem("ai_suggestion_feed_id")
    )?.feed_id;

    const postData = {
      feed_id: feed_id,
    };

    let suggestionsArray = JSON.parse(
      sessionStorage.getItem("ticket_feed_bard_suggestions")
    );

    if (suggestionsArray && suggestionsArray.length) {
      const existingSuggestionInStorage = getObjectById(
        suggestionsArray,
        feed_id
      );

      if (existingSuggestionInStorage) {
        setSuggestionData(existingSuggestionInStorage?.aiSuggestions);
      } else {
        setLoading(true);
        FetchBardAiSuggestion(postData);
      }
    } else {
      setLoading(true);
      FetchBardAiSuggestion(postData);
    }
  }, [openAIsuggestionTab]);

  function FetchBardAiSuggestion(data) {
    const callback = (response) => {
      if (response.data) {
        setSuggestionData(response.data);
        const feed_id = JSON.parse(
          sessionStorage.getItem("ai_suggestion_feed_id")
        )?.feed_id;

        const feedAiSuggestions = {
          feedId: feed_id,
          aiSuggestions: response.data,
        };

        if (sessionStorage.getItem("ticket_feed_bard_suggestions")) {
          let suggestionsArray = JSON.parse(
            sessionStorage.getItem("ticket_feed_bard_suggestions")
          );

          if (suggestionsArray && suggestionsArray.length) {
            const existingSuggestionInStorage = getObjectById(
              suggestionsArray,
              feed_id
            );

            if (existingSuggestionInStorage) {
              const index = suggestionsArray.findIndex(
                (obj) => obj.feedId === feed_id
              );

              if (index !== -1) {
                suggestionsArray[index] = feedAiSuggestions;
              }
            } else {
              suggestionsArray.push(feedAiSuggestions);
            }
          }

          sessionStorage.setItem(
            "ticket_feed_bard_suggestions",
            JSON.stringify(suggestionsArray)
          );
        } else {
          let suggestionsArray = [];
          suggestionsArray.push(feedAiSuggestions);

          sessionStorage.setItem(
            "ticket_feed_bard_suggestions",
            JSON.stringify(suggestionsArray)
          );
        }
      }
    };

    props
      .dispatch(AiSuggestionAction.requestBardAiSuggestion(data, {}, callback))
      .then(() => {
        setLoading(false);
        setRegenerateLoading(false);
      });
  }

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  function regenerateAiSuggestions() {
    const feed_id = JSON.parse(
      sessionStorage.getItem("ai_suggestion_feed_id")
    )?.feed_id;

    const postData = {
      feed_id: feed_id,
    };

    FetchBardAiSuggestion(postData);
  }

  return (
    <Box
      sx={{
        padding: 3,
        height: "100%",
      }}
      className="scrollable"
    >
      <Box
        width={"100%"}
        sx={{
          height: "100%",
        }}
      >
        <Grid
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          // className="bg-warning"
          sx={{
            height: "100%",
          }}
        >
          {loading ? (
            <Box
              sx={{
                width: "100%",
                height: "inherit",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingBgImage width={150} />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
              }}
            >
              {suggestionData?.map((response) => {
                return (
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    my={0.5}
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      border: `1px solid ${theme.palette.primary.light}`,
                      backgroundColor: theme.palette.background.default,
                      borderRadius: 2,
                    }}
                  >
                    <Grid item sm={1}>
                      <Tooltip title="Copy Suggestion">
                        <IconButton
                          className="py-0 m-0 px-1"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            copyToClipboard(response?.text);
                          }}
                          sx={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          <FileCopyIcon
                            fontSize="small"
                            style={{
                              width: 13,
                              color: theme.palette.primary.main,
                            }}
                          />
                        </IconButton>
                        <Snackbar
                          message="Suggestion copied to clip-board"
                          sx={{ height: "70%" }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          autoHideDuration={500}
                          onClose={() => setCopied(false)}
                          open={copied}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item sm={11} sx={{ width: "100%" }}>
                      <Typography component={"div"} width={"100%"}>
                        {response?.text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          )}

          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Grid item>
              <MuiButton
                onClick={() => {
                  setRegenerateLoading(true);
                  regenerateAiSuggestions();
                }}
                name={"Regenerate"}
                width={150}
                loading={regenerateLoading}
              />
            </Grid>

            <Grid item>
              <MuiButton
                onClick={() => {
                  setOpenAIsuggestionTab(null);
                  setAiSuggestionTab(null);
                  props.closeTab();
                }}
                name={"Close"}
                width={150}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(BardAISuggestionTab);
