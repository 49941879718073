import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import CustomfieldsBulkUpdateModel from "./models/CustomfieldsBulkUpdateModel";
import CustomTicketFieldsPutModel from "./models/CustomTicketFieldPutModel";
import CustomTicketFieldsModel from "./models/CustomTicketFieldsModel";
import CustomTicketFieldsPostModel from "./models/customTicketFieldsPostModel";

//Category effect
export default class CustomTicketFieldEffect {
  // calling an API according to the ProjectModel
  static async requestCustomTicketFields(params) {
    const endpoint = environment.api.custom_ticket_field;

    return EffectUtility.getToModel(CustomTicketFieldsModel, endpoint, params);
  }

  // Custom_ticket_fields filter api
  static async requestCustomTicketFieldsFilter(params) {
    const endpoint = environment.api.custom_ticket_field_filter;

    return EffectUtility.getToModel(CustomTicketFieldsModel, endpoint, params);
  }

  // Post API
  static async requestPostCustomTicketFields(data) {
    const endpoint = environment.api.custom_ticket_field + "/add";

    return EffectUtility.postToModel(
      CustomTicketFieldsPostModel,
      endpoint,
      data
    );
  }

  // Put API
  static async requestPutCustomTicketFields(data, id, callBackFunction) {
    const endpoint = environment.api.custom_ticket_field + "/" + id;

    let response = EffectUtility.putToModel(
      CustomTicketFieldsPutModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction();
      }
    });
    return response;
  }

  // Put method for the Customfields bulk update
  static async requestPutCustomefieldsBulkUpdate(data) {
    const endpoint = environment.api.customfieldsBulkUpdate;
    return EffectUtility.putToModel(
      CustomfieldsBulkUpdateModel,
      endpoint,
      data
    );
  }

  // Delete API
  //   static async requestDeleteCategory(id) {
  //     const endpoint = environment.api.category + "/" + id;
}
