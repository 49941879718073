import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import RetweetGetModel from "./models/RetweetGetModel";

//Retweet effect
export default class RetweetEffect {
  // Retweet Post API
  static async requestRetweet(id, params) {
    const endpoint = environment.api.retweet + "/" + id;

    return EffectUtility.getToModel(RetweetGetModel, endpoint, params);
  }

  //get error end point
  // static async requestError() {
  //   const endpoint = environment.api.errorExample;
  //   const response = await HttpUtility.get(endpoint);
}
