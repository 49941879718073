import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  OutlinedInput,
  Tab,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { connect } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import environment from "environment";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import EmailFeedsAction from "../../../stores/emailFeeds/EmailFeedsAction";
import { axiosRequest } from "../../../../utilities/FetchRequest";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    EmailFeedsAction.REQUEST_EMAIL_FEEDS,
    EmailFeedsAction.REQUEST_EMAIL_FEEDS_FILTER,
  ]),
  emailFeeds: state.emailFeeds.emailFeeds || [],
  emailFeedsFilter: state.emailFeedsFilter.emailFeedsFilter || [],
  pagination: state.emailFeeds.emailFeeds || {},
});

function EmailListView(props) {
  const [value, setValue] = React.useState("1");
  const [customerName, setCustomerName] = useState(null);
  const [date, setDate] = useState([null, null]);
  const [page_limit] = useState(10);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  function fetchEmail(params) {
    props.dispatch(EmailFeedsAction.requestEmailFeeds(params));
  }

  function fetch_filter(params) {
    props.dispatch(EmailFeedsAction.requestEmailFeedsFilter(params));
  }

  const isPastDate = (date) => {
    const now = new Date();
    return date <= now;
  };

  // USE EFFECT
  useEffect(() => {
    const param = { page_limit: "none" };
    fetch_filter(param);
    const filter = [];
    let params = {};

    // search
    if (searchQuery !== "") {
      filter.push(
        `["subject.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }
    // created_time filter
    if (
      date[0] !== null &&
      isPastDate(date[0]) &&
      date[1] !== null &&
      isPastDate(date[1])
    ) {
      filter.push(
        `["date","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["date","must","gte","range","${format(date[0], "yyyy-MM-dd")}"]`
      );
    }

    if (
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null &&
        isPastDate(date[0]) &&
        date[1] !== null &&
        isPastDate(date[1]))
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    } else {
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }

    fetchEmail(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autocompleteHandleChange = (event, value) => {
    if (value != null) {
      setCustomerName(value);
    } else {
      setCustomerName(null);
    }
  };

  // APPLY FILTER
  const applyFilter = () => {
    let params = {};
    const filter = [];

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    // search
    if (searchQuery !== "") {
      filter.push(
        `["subject.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (
      date[0] !== null &&
      isPastDate(date[0]) &&
      date[1] !== null &&
      isPastDate(date[1])
    ) {
      filter.push(
        `["date","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["date","must","gte","range","${format(date[0], "yyyy-MM-dd")}"]`
      );
    }

    if (
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null &&
        isPastDate(date[0]) &&
        date[1] !== null &&
        isPastDate(date[1]))
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
      fetchEmail(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please add filters");
      // params = {
      //   order_by: '[["created_on", "desc"]]',
      //   page_limit: `${page_limit}`,
      // };
      // fetch(params);
    }
  };

  // Email List
  const list = [];

  if (props.emailFeedsFilter.hits) {
    props.emailFeedsFilter.hits.hits.map((value) => {
      list.push(value._source.from ? value._source.from : "");
    });
  }

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  const uniqueList = [...new Set(list)];

  // Clear Function
  const ClearState = () => {
    setFilterClicked(false);
    if (
      customerName !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      setCustomerName(null);
      setDate([null, null]);
      setClearLoader(true);
      setSearchQuery("");
      if (navigator.onLine === true) {
        const params = {
          order_by: '[["created_on", "desc"]]',
          page_limit: `${page_limit}`,
        };
        FilterClicked && fetchEmail(params);
      } else {
        alert("No internet connection");
      }
    }
  };

  // PAGINATION
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["subject.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    // created_time filter
    if (
      date[0] !== null &&
      isPastDate(date[0]) &&
      date[1] !== null &&
      isPastDate(date[1])
    ) {
      filter.push(
        `["date","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["date","must","gte","range","${format(date[0], "yyyy-MM-dd")}"]`
      );
    }

    if (FilterClicked) {
      if (
        customerName !== null ||
        searchQuery !== "" ||
        (date[0] !== null &&
          isPastDate(date[0]) &&
          date[1] !== null &&
          isPastDate(date[1]))
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: '[["created_on", "desc"]]',
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchEmail(params);
    }
  };
  let maxDate = new Date();

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);
    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}
      <Grid container>
        {/*Twitter filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            mt={2}
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
            // className="bg-warning"
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="space-between"
              alignItems={"flex-start"}
              // spacing={1}
              // className="bg-warning"
            >
              <Grid
                item
                xl={1.9}
                lg={2.9}
                md={2.9}
                sm={11}
                // mt={1}
                // className="bg-success"
              >
                <Grid item xl={12} lg={12} md={12} sm={12}>
                  <p>Customer Name</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={customerName}
                  list={uniqueList}
                  textFieldProps={{
                    placeholder: "Customer Name",
                  }}
                  handleChange={autocompleteHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={1.9} lg={2.9} md={2.9} sm={12}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={2}>
                  <span>Search by Subject</span>
                </Grid>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      borderRadius:
                        theme.palette.buttonComponent.borderRadius.medium,
                      outline: `1px ${theme.palette.borderColor.textField}`,
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.typography.padding,
                      height: "35px",
                      width: "100%",
                    }}
                    id="search_bar"
                    autoComplete={"off"}
                    value={searchQuery}
                    placeholder={"Search by Subject"}
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment>
                        <IconButton onClick={applyFilter}>
                          <SearchIcon
                            sx={{
                              "&.MuiSvgIcon-root": {
                                fontSize: "1.2rem",
                              },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={1.9} lg={2.9} md={2.9} sm={12}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  sx={{ width: "100%" }}
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>
              <Grid item xl={5.9} lg={2.9} md={2.9} sm={11}>
                <Grid item xl={12} lg={12} md={12} sm={12}>
                  {/* <p>Customer Name</p> */}
                </Grid>

                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  // spacing={2}
                  justifyContent="flex-end"
                  mt={4.2}
                >
                  <Grid item xl={2.5} lg={4} md={5.5} sm={5} mr={1}>
                    <MuiButton
                      name={"Apply"}
                      width="100%"
                      loading={filterLoader && isRequesting}
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid item xl={2.5} lg={4} md={5.5} sm={5}>
                    <MuiButton
                      name={"Clear"}
                      width="100%"
                      loading={clearLoader && isRequesting}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
      </Grid>
      {/* ****************************************************** Divider */}

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Email</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid>
          {props.emailFeeds.hits ? (
            <LoadingIndicator isActive={isRequesting}>
              {props.emailFeeds.hits.hits.length !== 0 ? (
                props.emailFeeds.hits.hits.map((val) => (
                  <Box
                    sx={{
                      padding: 0,
                      margin: "1.2%",
                      marginTop: 0,
                      marginBottom: "0.5%",
                    }}
                  >
                    <List
                      sx={{
                        width: "100%",
                        cursor: val && val._source.ticket_id ? "pointer" : null,
                      }}
                      onClick={() => {
                        localStorage.setItem("activeTab", 0);

                        val._source &&
                          val._source.ticket_id &&
                          openTicketFunction(val._source.ticket_id);
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          backgroundColor: "#fff",
                          height: "auto",
                          padding: 2,
                          borderRadius: theme.borderRadius,
                          border: "solid 0.5px #d3e2e6",
                          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                        }}
                      >
                        <Grid container lg={12} md={12} sm={12}>
                          <Grid
                            item
                            lg={1}
                            md={2}
                            sm={2}
                            alignSelf="center"
                            maxWidth={"80px !important"}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar></Avatar>
                              </ListItemAvatar>
                            </ListItem>
                          </Grid>
                          <Grid item lg={10} md={9} sm={9} container>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              direction={{
                                lg: "row",
                                xl: "row",
                                md: "row",
                                sm: "column-reverse",
                              }}
                            >
                              <Grid item lg={4} md={6} sm={5}>
                                <ListItem>
                                  <h6>From</h6>&nbsp;&nbsp;&nbsp;
                                  <p>{val._source.from}</p>
                                </ListItem>
                              </Grid>
                              <Grid item lg={3} md={6} sm={5.5}>
                                <ListItem>
                                  <p>
                                    {(function createdDate() {
                                      let created = val._source.date;
                                      let dateConversion = new Date(created);
                                      dateConversion =
                                        dateConversion.toDateString() +
                                        ", " +
                                        dateConversion.toLocaleTimeString(
                                          "en-US",
                                          {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          }
                                        );

                                      return dateConversion;
                                    })()}
                                  </p>
                                </ListItem>
                              </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12}>
                              <ListItem>
                                <h6>To</h6>&nbsp;&nbsp;&nbsp;
                                <p>{val._source.to ? val._source.to[0] : ""}</p>
                              </ListItem>
                            </Grid>

                            {/* <Grid item lg={12} md={12} sm={12}>
                              <ListItem>
                                <h6>Sentiment value</h6>&nbsp;&nbsp;&nbsp;
                                <p>
                                  {val._source.sentiment_value
                                    ? val._source.sentiment_value
                                    : ""}
                                </p>
                              </ListItem>
                            </Grid> */}

                            <Grid item lg={12} md={12} sm={12}>
                              <ListItem>
                                <Typography>
                                  <b
                                    style={{
                                      fontSize:
                                        theme.palette.buttonComponent.fontSize
                                          .extraLarge,
                                      lineHeight: "22px",
                                    }}
                                  >
                                    Subject
                                  </b>
                                  &nbsp;&nbsp;&nbsp;
                                  <span>{val._source.subject}</span>
                                </Typography>
                              </ListItem>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            lg={1}
                            md={2}
                            sm={2}
                            alignItems={"flex-start"}
                            justifyContent="space-between"
                          >
                            {val._source.feed_link && (
                              <Grid
                                item
                                // className="bg-info"
                                lg={1}
                                xl={1}
                                md={1}
                                sm={1}
                              >
                                <Typography
                                  component={"a"}
                                  href={
                                    val._source.feed_link
                                      ? val._source.feed_link
                                      : ""
                                  }
                                  target="_blank"
                                  title="Feed Link"
                                >
                                  {/* <OpenInNewIcon
                                    fontSize="25px"
                                    sx={{ cursor: "pointer" }}
                                  /> */}
                                </Typography>
                              </Grid>
                            )}

                            {circuler_progress_id === val._source.ticket_id &&
                              open_ticket && (
                                <CircularProgress
                                  color="inherit"
                                  size={15}
                                  // className="bg-light"
                                />
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </List>
                  </Box>
                ))
              ) : (
                <NoDataFound />
              )}
            </LoadingIndicator>
          ) : null}
        </Box>
      </Grid>
      {props.emailFeeds.hits
        ? props.emailFeeds.hits.hits.length !== 0 &&
          !isRequesting && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChangePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(EmailListView);
