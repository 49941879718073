import BaseReducer from "../../../../utilities/BaseReducer";
import InrRatesAction from "./InrRatesAction";

// InrRatesReduser
export default class InrRatesReduser extends BaseReducer {
  initialState = {
    inrRates: [],
  };
  [InrRatesAction.REQUEST_INR_RATES_FINISHED](state, action) {
    return {
      ...state,
      inrRates: action.payload,
    };
  }
}
