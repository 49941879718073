import ActionUtility from "../../../utilities/ActionUtility";
import CategoryEffect from "./CategoryEffect";
export default class CategoryAction {
  // Category action with an function declaration
  static REQUEST_CATEGORY = "CategoryAction.REQUEST_CATEGORY";
  static REQUEST_CATEGORY_FINISHED = "CategoryAction.REQUEST_CATEGORY_FINISHED";

  //   category post
  static REQUEST_POST_CATEGORY = "CategoryAction.REQUEST_POST_CATEGORY";
  static REQUEST_POST_CATEGORY_FINISHED =
    "CategoryAction.REQUEST_POST_CATEGORY_FINISHED";

  // category put
  static REQUEST_PUT_CATEGORY = "CategoryAction.REQUEST_PUT_CATEGORY";
  static REQUEST_PUT_CATEGORY_FINISHED =
    "CategoryAction.REQUEST_PUT_CATEGORY_FINISHED";

  // category delete
  static REQUEST_DELETE_CATEGORY = "CategoryAction.REQUEST_DELETE_CATEGORY";
  static REQUEST_DELETE_CATEGORY_FINISHED =
    "CategoryAction.REQUEST_DELETE_CATEGORY_FINISHED";

  //Category list (type get method FOR FILTER)
  static REQUEST_CATEGORY_FILTER = "CategoryAction.REQUEST_CATEGORY_FILTER";
  static REQUEST_CATEGORY_FILTER_FINISHED =
    "CategoryAction.REQUEST_CATEGORY_FILTER_FINISHED";

  //Category put Bulk

  static REQUEST_PUT_CATEGORY_BULKUPDATE =
    "CategoryAction.REQUEST_PUT_CATEGORY_BULKUPDATE";
  static REQUEST_PUT_CATEGORY_BULKUPDATE_FINISHED =
    "CategoryAction.REQUEST_PUT_CATEGORY_BULKUPDATE_FINISHED";

  // METHODS
  // Category GET function
  static requestCategory(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CategoryAction.REQUEST_CATEGORY,
        CategoryEffect.requestCategory,
        params
      );
    };
  }

  static requestCategoryFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CategoryAction.REQUEST_CATEGORY_FILTER,
        CategoryEffect.requestCategoryFilter,
        params
      );
    };
  }

  // static requestCategoryFinished() {
  //   return async (dispatch, getState) => {
  //     await ActionUtility.createThunkEffect(
  //       dispatch,
  //       CategoryAction.REQUEST_CATEGORY_FINISHED,
  //       CategoryEffect.requestCategoryFinished
  //     );
  //   };
  // }

  // category post function
  static requestPostCategory(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CategoryAction.REQUEST_POST_CATEGORY,
        CategoryEffect.requestPostCategory,
        data
      );
    };
  }

  // category put function
  static requestPutCategory(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CategoryAction.REQUEST_PUT_CATEGORY,
        CategoryEffect.requestPutCategory,
        data,
        id
      );
    };
  }

  // category delete function
  static requestDeleteCategory(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CategoryAction.REQUEST_DELETE_CATEGORY,
        CategoryEffect.requestDeleteCategory,
        id
      );
    };
  }

  // Category Bulk put function

  static requestPutCategoryBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CategoryAction.REQUEST_PUT_CATEGORY_BULKUPDATE,
        CategoryEffect.requestPutCategoryBulkUpdate,
        data
      );
    };
  }
}
