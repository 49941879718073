import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import CannedMessagePostModel from "./CannedMessagePostModel";
import CannedMessagePutModel from "./CannedMessagePutModel";
import CannedMessageModel from "./model/CannedMessageModel";

// CannedMessageEffect
export default class CannedMessageEffect {
  // calling an API according to the CannedMessage model
  // Get method for the
  static async requestCannedMessage(params) {
    const endpoint = environment.api.cannedMessage;
    return EffectUtility.getToModel(CannedMessageModel, endpoint, params);
  }

  // Get method for the Canned Message filter
  static async requestCannedMessageFilter(params) {
    const endpoint = environment.api.cannedMessage;
    return EffectUtility.getToModel(CannedMessageModel, endpoint, params);
  }

  // put method for the Canned Message
  static async requestPutCannedMessage(data, id) {
    const endpoint = environment.api.cannedMessage + "/" + id;
    return EffectUtility.putToModel(CannedMessagePutModel, endpoint, data);
  }

  // post method for the Canned Message
  static async requestPostOrganization(data) {
    const endpoint = environment.api.cannedMessage + "/add";

    return EffectUtility.postToModel(CannedMessagePostModel, endpoint, data);
  }
}
