import { BaseModel } from "sjs-base-model";
import TwitterDataHitsModel from "./TwitterDataHitsModel";

// get model for project
// twitter api fetch model
export default class TwitterHitsModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  hits = [{ TwitterDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
