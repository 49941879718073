import BaseReducer from "../../../utilities/BaseReducer";
import RedditFeedAction from "./RedditFeedAction";

export default class RedditPostReduser extends BaseReducer {
  initialState = {
    redditPosts: [],
  };

  [RedditFeedAction.REQUEST_REDDIT_POSTS_FINISHED](state, action) {
    return {
      ...state,
      redditPosts: action.payload,
    };
  }
}
