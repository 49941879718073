import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function FrtTatAveargesByCustomFields(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter).then((res) => {
        setPlatformwiseTickets(res);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    const type_value_filters = props.type_value?.length
      ? props.type_value.map((item) => `${item.type_id}`)
      : [];

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["reports_on_custom_value_wise_tat_frt"]&advanced_filters={"custom_value_type":${
      props.type_value?.length
        ? JSON.stringify(type_value_filters)
        : `["text","select","radio","toggle","date"]`
    }}${
      props.DateFilter || (props.filterClicked && props.openDateFilter)
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  let columns = [];

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const totalSeconds = number * 3600; // Convert the decimal to total seconds
    const hours = Math.floor(totalSeconds / 3600); // Extract the whole number part (hours)
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Convert the remaining seconds to minutes
    const seconds = Math.floor(totalSeconds % 60); // Get the remaining seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  columns.push(
    {
      field: "id",
      headerName: "Sl No",
      resizable: false,
      width: 100,
      hide: true,
    },
    {
      field: "field_name",
      headerName: "Custom Field Name",
      resizable: true,
      hide: false,
      flex: 6,
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name?.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.field_name}
          </Typography>
        );
      },
    },

    {
      field: "ticket_frt_response_time",
      headerName: "Avg. FRT",
      resizable: true,
      hide: false,
      flex: 3,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <span
            className={`${
              params?.row?.field_name.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.tat_frt?.ticket_frt_response_time
              ? HoursAndMinutes(params?.row?.tat_frt?.ticket_frt_response_time)
              : "00:00:00"}
          </span>
        );
      },
    },
    {
      field: "ticket_tat_response_time",
      headerName: "Avg. TAT",
      resizable: true,
      hide: false,
      flex: 3,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <span
            className={`${
              params?.row?.field_name.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.tat_frt?.ticket_tat_response_time
              ? HoursAndMinutes(params?.row?.tat_frt?.ticket_tat_response_time)
              : "00:00:00"}
          </span>
        );
      },
    }
  );

  let updatedRows = [];
  if (platformwiseTickets) {
    platformwiseTickets?.ticket_report?.reports_on_custom_value_wise_tat_frt?.forEach(
      (entyItem, index) => {
        // Loop through each value in the `values` array
        entyItem?.values?.forEach((valueItem) => {
          updatedRows?.push({
            id: updatedRows.length + 1, // Ensure unique IDs
            field_name: `${entyItem.key} - ${valueItem.key} (${valueItem.count})`,
            tat_frt: valueItem?.tat_frt,
          });
        });
      }
    );
  }

  const totalValue = {
    ticket_frt_response_time:
      platformwiseTickets?.ticket_report?.reports_on_custom_value_wise_tat_frt[
        platformwiseTickets?.ticket_report?.reports_on_custom_value_wise_tat_frt
          ?.length - 1
      ].total_frt,
    ticket_tat_response_time:
      platformwiseTickets?.ticket_report?.reports_on_custom_value_wise_tat_frt[
        platformwiseTickets?.ticket_report?.reports_on_custom_value_wise_tat_frt
          ?.length - 1
      ].total_tat,
  };
  const totalRow = {
    id: updatedRows.length + 1,
    field_name: "Total",
    tat_frt: totalValue,
  };
  platformwiseTickets?.ticket_report?.reports_on_custom_value_wise_tat_frt
    ?.length > 1 && updatedRows.push(totalRow);
  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item, index) => {
        const exportEntry = {
          ["Sl No"]: index + 1,
          ["Field Name"]: item.field_name,
          ["Avg. FRT"]: item.tat_frt?.ticket_frt_response_time
            ? HoursAndMinutes(item.tat_frt?.ticket_frt_response_time)
            : "00:00:00",
          ["Avg. TAT"]: item.tat_frt?.ticket_tat_response_time
            ? HoursAndMinutes(item.tat_frt?.ticket_tat_response_time)
            : "00:00:00",
        };

        exportData.push(exportEntry); // Add the entry to the exportData array
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {!loading &&
            updatedRows.length > 0 &&
            props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={50}
                      onCellClick={onCellClick}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default FrtTatAveargesByCustomFields;
