import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import HssCategoryGetModal from "./models/HssCategoryGetModel";
import HssCategoryPostModal from "./models/HssCategoryPostModel";

export default class HssCategoryEffect {
  static async requestHssCategory(params, callback) {
    const endpoint = environment.api.websweepXhssCategory;
    let response = EffectUtility.getToModel(
      HssCategoryGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestHssCategoryFilter(params) {
    const endpoint = environment.api.websweepXhssCategory;
    return EffectUtility.getToModel(HssCategoryGetModal, endpoint, params);
  }

  static async requestPostHssCategory(data, callBackFunction) {
    const endpoint = environment.api.websweepXhssCategory;

    let response = EffectUtility.postToModel(
      HssCategoryPostModal,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPutHSSCategory(data, id, callBackFunction) {
    const endpoint = environment.api.websweepXhssCategory + `/${id}`;
    let response = EffectUtility.putToModel(
      HssCategoryPostModal,
      endpoint,
      data
    );
    return response;
  }
}
