import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import AssignmentPost from "./AssignmentPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import AssignmentRuleAction from "../../stores/assignmentRule/AssignmentRuleAction";
import ProjectAction from "../../stores/project/projectAction";
import PriorityAction from "../../stores/priority/PriorityAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE,
    AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE_FILTER,
    ProjectAction.REQUEST_PROJECT,
    PriorityAction.REQUEST_PRIORITY_FILTER,
  ]),
  assignmentRule: state.assignmentRule.assignmentRule || [],
  assignmentRuleFilter: state.assignmentRuleFilter.assignmentRuleFilter || [],
  pagination: state.assignmentRule.assignmentRule || [],
  project: state.project.project || [],
  priority: state.filterpriority.filterpriority.hits || [],
});

function AssignmentRule(props) {
  const navigate = useNavigate();
  const { isRequesting } = props;

  const [page_limit] = useState(15);
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [edit, setEdit] = useState(false);
  const [ruleName, setRuleName] = useState(null);

  function fetchAssignmentRule(params) {
    props.dispatch(AssignmentRuleAction.requestAssignmentRule(params));
  }

  function AssignmentRuleFilter(params) {
    props.dispatch(AssignmentRuleAction.requestAssignmentRuleFilter(params));
  }

  function fetchProject(params) {
    props.dispatch(ProjectAction.requestProject(params));
  }

  // filter priority dispatch function
  function fetchFilterPriority() {
    const priority_param = {
      page_limit: "none",
      order_by: `[["ticket_priority_name.keyword", "asc"]]`,
    };

    props.dispatch(PriorityAction.filterRequestPriority(priority_param));
  }

  useEffect(() => {
    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["rulename.keyword", "asc"]]`,
      page_limit: "none",
    };

    fetchAssignmentRule(params);
    AssignmentRuleFilter(param);

    let project_id = JSON.parse(
      localStorage.getItem("user_last_working_project")
    );

    const project_param = {
      filters: `[["_id","must","","match","${
        project_id ? project_id.project_id : ""
      }"]]`,
    };

    fetchProject(project_param);
    fetchFilterPriority();
  }, []);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  let orgId =
    props.project.hits &&
    props.project.hits.hits.map((item) => {
      return item._source.organization_id.organization_id;
    });

  // const priorityList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleRuleNameChange = (e, val) => {
    setRuleName(val);
  };

  let platformList = [];

  if (props.project.hits) {
    props.project.hits.hits.map((item) => {
      item._source.platform.map((val) => {
        platformList.push({
          id: val.platform_id,
          name: val.platform_name,
        });
      });
    });
  }

  const handleFilter = () => {
    const filter = [];
    if (ruleName && ruleName.rule_name !== null) {
      filter.push(
        `["rulename.keyword","must","","match","${ruleName.rule_name}"]`
      );
    }

    if (ruleName !== null) {
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      const params = {
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
        filters: `[${filter}]`,
      };
      fetchAssignmentRule(params);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    if (ruleName !== null) {
      setRuleName(null);
      setFilterClicked(false);

      const params = {
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };

      fetchAssignmentRule(params);
    }
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (ruleName !== null) {
      filter.push(
        `["rulename.keyword","must","","match","${ruleName.rule_name}"]`
      );
    }

    if (FilterClicked) {
      if (ruleName !== null) {
        filter_params = {
          order_by: '[["order_number", "asc"]]',
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      filter_params = {
        order_by: '[["order_number", "asc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchAssignmentRule(filter_params);
    }
  };

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["rulename.keyword", "asc"]]`,
      page_limit: "none",
    };

    data &&
      props
        .dispatch(
          AssignmentRuleAction.requestPutAssignmentRuleBulkUpdate(bulkData)
        )
        .then(() => {
          props.dispatch(AssignmentRuleAction.requestAssignmentRule(paramss));
          props.dispatch(
            AssignmentRuleAction.requestAssignmentRuleFilter(param)
          );
        });
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const columns = [
    {
      field: "sl_no",
      headerName: "SL No",
      width: 150,
      flex: 1,
    },
    {
      field: "rulename",
      headerName: "Rule Name",
      width: 250,
      flex: 3,
    },
    {
      field: "negetive_priority",
      headerName: "Negetive Priority",
      width: 250,
      flex: 3,
      hide: true,
    },
    {
      field: "neutral_priority",
      headerName: "Neutral Priority",
      width: 250,
      flex: 3,
      hide: true,
    },
    {
      field: "positive_priority",
      headerName: "Positive Priority",
      width: 250,
      flex: 3,
      hide: true,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      align: "center",
      width: 160,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },

    // {
    //   field: "search",
    //   type: "actions",
    //   headerName: "Set_Priority",
    //   flex: 2,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <AutoCompleteComponent
    //       list={priorityList}
    //       textFieldProps={{
    //         placeholder: "Select Option",
    //       }}
    //       size="small"
    //     />
    //   ),
    // },
  ];

  const rows = [];

  let priority_list = [];

  props.priority &&
    props.priority.hits &&
    props.priority.hits.map((val) => {
      priority_list.push({
        id: val._id,
        name: val._source.ticket_priority_name,
      });
    });

  const returnPriorityObject = (priority_id) => {
    let filteredPriorityObject =
      priority_list &&
      priority_list.length !== 0 &&
      priority_list.filter((val) => {
        return val.id === priority_id;
      });

    return filteredPriorityObject && filteredPriorityObject.length !== 0
      ? filteredPriorityObject[0]
      : "";
  };

  if (props.assignmentRule.hits) {
    props.assignmentRule.hits.hits &&
      props.assignmentRule.hits.hits.length &&
      props.assignmentRule.hits.hits.map((item, index) => {
        return rows.push({
          sl_no: item._source.order_number,
          id: item._id,
          _index: item._index,
          _score: item._score,
          rulename: item._source.rulename,
          created_by: item._source.created_by.user_name,
          order_number: item._source.order_number,
          actions: item._source.actions,
          conditions: item._source.conditions,
          negetive_priority:
            item._source &&
            item._source.set_priority &&
            item._source.set_priority.negative
              ? returnPriorityObject(item._source.set_priority.negative)
              : "",
          neutral_priority:
            item._source &&
            item._source.set_priority &&
            item._source.set_priority.neutral
              ? returnPriorityObject(item._source.set_priority.neutral)
              : "",
          positive_priority:
            item._source &&
            item._source.set_priority &&
            item._source.set_priority.positive
              ? returnPriorityObject(item._source.set_priority.positive)
              : "",
        });
      });
  }

  let rule_name_list = [];
  if (props.assignmentRuleFilter.hits) {
    props.assignmentRuleFilter.hits.hits.length &&
      props.assignmentRuleFilter.hits.hits.map((item) => {
        rule_name_list.push({
          rule_id: item._id,
          rule_name: item._source.rulename,
        });
      });
  }

  let rule_name_filtered_list = [];
  rule_name_filtered_list = [...new Set(rule_name_list)];

  // const uniqueList = ["Active", "InActive", "All"];

  return (
    <React.Fragment>
      <Box
        className="scrollable"
        style={{
          height: "100%",
          maxHeight: `${window.innerHeight}px`,
          overflow: "auto",
          position: "relative",
        }}
      >
        {open && (
          <AssignmentPost
            open={open}
            onSubmit={() => {}}
            onClose={() => {
              setOpen(false);
            }}
            orgId={JSON.stringify(orgId[0])}
            editData={editRowsModel}
            edit={edit}
            setEdit={setEdit}
            platformList={platformList}
          />
        )}

        {!open && (
          <Box>
            <h5 style={{ marginTop: "5px" }}>Ticket Assignment Condition</h5>

            <Grid container>
              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-between"
                mt={1}
              >
                <Grid item lg={6} md={7} sm={7} container p={1}>
                  <Grid item lg={4.5} md={5} sm={5} p={1} mt={0}>
                    <AutoCompleteComponent
                      value={ruleName}
                      list={rule_name_filtered_list}
                      textFieldProps={{
                        placeholder: "Rule Name",
                      }}
                      getOptionLabel={(option) => option.rule_name}
                      handleChange={handleRuleNameChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>

                  {/* <Grid
                item
                lg={4.6}
                md={5}
                sm={5}
                p={1}
                mb={6}
                style={{ position: "relative" }}
                alignItems="flex-start"
                justifyContent="center"
              >
                <Grid
                  item
                  xl={12}
                  style={{ position: "absolute", width: "95%", zIndex: 999 }}
                >
                  <AutoCompleteComponent
                    textFieldProps={{
                      placeholder: "Rule Name",
                    }}
                    limitTags={1}
                    list={rule_name_filtered_list}
                    handleChange={handleFilter}
                    size="small"
                    multiple
                    width="100%"
                  />
                </Grid>
              </Grid> */}
                </Grid>
                <Grid item lg={6} md={6} sm={5}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    mt={0}
                  >
                    <Grid
                      lg={8}
                      sm={8}
                      md={8}
                      display={"flex"}
                      justifyContent={"end"}
                      mt={1}
                      mr={2}
                      sx={{ gap: 2 }}
                    >
                      <Grid lg={3} md={3} sm={3}>
                        <MuiButton
                          name={"Filter"}
                          width="100%"
                          onClick={handleFilter}
                          loading={filterLoader && isRequesting}
                        />
                      </Grid>
                      <Grid lg={3} md={3} sm={3}>
                        <MuiButton
                          width="100%"
                          loading={clearLoader && isRequesting}
                          name={"Clear"}
                          onClick={ClearState}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <PostPlusButton
                        open={open}
                        openCloseModal={openCloseModal}
                        editMode={editMode}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* data grid */}
            <Grid item lg={12} md={12} sm={12} xs={12} pr={1}>
              <LoadingIndicator isActive={isRequesting}>
                {props.assignmentRule.hits && !isRequesting ? (
                  <DataGridFunction
                    rows={rows}
                    columns={columns}
                    rowHeight={60}
                    height="70vh"
                    rowReordering
                    onRowOrderChange={rowOrderHandleChange}
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          negetive_priority: false,
                          neutral_priority: false,
                          positive_priority: false,
                        },
                      },
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingBgImage />
                  </Box>
                )}
              </LoadingIndicator>
            </Grid>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(AssignmentRule);
