import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fieldToTextField } from "formik-material-ui";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// date picker component with formik integration
export default function DatePickerComponent({
  disableClearable,
  textFieldProps,
  size,
  fieldValue,
  onChange,
  ...props
}) {
  const {
    form: { setTouched, setFieldValue, touched },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disableClearable={disableClearable}
        {...props}
        {...field}
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        InputAdornmentProps={{ margin: 100 }}
        onBlur={() => setTouched({ ...touched, [name]: true })}
        renderInput={(props) => (
          <TextField
            InputLabelProps={{ shrink: true }}
            {...props}
            {...textFieldProps}
            helperText={helperText}
            error={error}
            size={size}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              svg: { width: 50 },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
