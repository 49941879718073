import BaseReducer from "../../../../utilities/BaseReducer";
import ClaimRequestAction from "./ClaimRequestAction";

export default class InvoiceClaimDetailsReducer extends BaseReducer {
  initialState = {
    invoiceClaimDetails: [],
  };
  [ClaimRequestAction.REQUEST_REVENUE_INVOICE_CLAIM_DETAILS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      invoiceClaimDetails: action.payload,
    };
  }
}
