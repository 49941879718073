import ActionUtility from "../../../../utilities/ActionUtility";
import TopTrackEffect from "./TopTrackEffect";

export default class TopTrackAction {
  // TopTrackAction action with an function declaration
  static REQUEST_TOPTRACK = "TopTrackAction.REQUEST_TOPTRACK";
  static REQUEST_TOPTRACK_FINISHED = "TopTrackAction.REQUEST_TOPTRACK_FINISHED";

  static REQUEST_TOPTRACK_FILTER = "TopTrackAction.REQUEST_TOPTRACK_FILTER";
  static REQUEST_TOPTRACK_FILTER_FINISHED =
    "TopTrackAction.REQUEST_TOPTRACK_FILTER_FINISHED";

  static REQUEST_PUT_TOPTRACK = "TopTrackAction.REQUEST_PUT_TOPTRACK";
  static REQUEST_PUT_TOPTRACK_FINISHED =
    "TopTrackAction.REQUEST_PUT_TOPTRACK_FINISHED";

  static REQUEST_POST_TOPTRACK = "TopTrackAction.REQUEST_POST_TOPTRACK";
  static REQUEST_POST_TOPTRACK_FINISHED =
    "TopTrackAction.REQUEST_POST_TOPTRACK_FINISHED";

  static REQUEST_DELETE_TOPTRACK = "TopTrackAction.REQUEST_DELETE_TOPTRACK";
  static REQUEST_DELETE_TOPTRACK_FINISHED =
    "TopTrackAction.REQUEST_DELETE_TOPTRACK_FINISHED";

  // METHODS
  // Organization GET function
  static requestTopTrack(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTrackAction.REQUEST_TOPTRACK,
        TopTrackEffect.requestTopTrack,
        params,
        callBack
      );
    };
  }

  static requestTopTrackFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTrackAction.REQUEST_TOPTRACK_FILTER,
        TopTrackEffect.requestTopTrackFilter,
        params
      );
    };
  }
  static requestPutTopTrack(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTrackAction.REQUEST_PUT_TOPTRACK,
        TopTrackEffect.requestPutTopTrack,
        data,
        id
      );
    };
  }

  static requestPostTopTrack(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTrackAction.REQUEST_POST_TOPTRACK,
        TopTrackEffect.requestPostTopTrack,
        data
      );
    };
  }

  static requestDeleteTopTrack(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTrackAction.REQUEST_DELETE_TOPTRACK,
        TopTrackEffect.requestDeleteTopTrack,
        id
      );
    };
  }
}
