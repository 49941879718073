import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import Ping from "./components/Ping";
import SpeedTestComponent from "./components/SpeedTestComponent";
import ClientSpeedUsingNetworkInfo from "./components/ClientSpeedTest";
import BrowserSpeedTestComponent from "./components/BrowserSpeedTest";

export const internetCheckDomain = "http://localhost:3000";

function InternetSpeedTest() {
  return (
    <Box>
      <ClientSpeedUsingNetworkInfo />
      <Divider sx={{ marginBottom: 1 }} />
      <Grid container item sm={12} sx={{ marginTop: 2 }} columnSpacing={1}>
        <Grid item sm={6}>
          <Ping />
        </Grid>
        <Grid item sm={6}>
          <SpeedTestComponent />
        </Grid>
        {/* <Grid item sm={4}>
          <BrowserSpeedTestComponent />
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default InternetSpeedTest;
