import { BaseModel } from "sjs-base-model";

// CampainSchedulePostModel
export default class CampainSchedulePostModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  data = [];
  pagination = [1, 1, 1, 1];
  status = "";
  total_counts = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
