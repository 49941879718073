import ActionUtility from "../../../../utilities/ActionUtility";
import TopStoreEffect from "./TopStoreEffect";

export default class TopStoreAction {
  // TopStoreAction action with an function declaration
  static REQUEST_TOPSTORE = "TopStoreAction.REQUEST_TOPSTORE";
  static REQUEST_TOPSTORE_FINISHED = "TopStoreAction.REQUEST_TOPSTORE_FINISHED";

  static REQUEST_TOPSTORE_FILTER = "TopStoreAction.REQUEST_TOPSTORE_FILTER";
  static REQUEST_TOPSTORE_FILTER_FINISHED =
    "TopStoreAction.REQUEST_TOPSTORE_FILTER_FINISHED";

  static REQUEST_AUDIO_STREAMS = "TopStoreAction.REQUEST_AUDIO_STREAMS";
  static REQUEST_AUDIO_STREAMS_FINISHED =
    "TopStoreAction.REQUEST_AUDIO_STREAMS_FINISHED";

  static REQUEST_TOPRETAILER = "TopStoreAction.REQUEST_TOPRETAILER";
  static REQUEST_TOPRETAILER_FINISHED =
    "TopStoreAction.REQUEST_TOPRETAILER_FINISHED";

  static REQUEST_PUT_TOPSTORE = "TopStoreAction.REQUEST_PUT_TOPSTORE";
  static REQUEST_PUT_TOPSTORE_FINISHED =
    "TopStoreAction.REQUEST_PUT_TOPSTORE_FINISHED";

  static REQUEST_POST_TOPSTORE = "TopStoreAction.REQUEST_POST_TOPSTORE";
  static REQUEST_POST_TOPSTORE_FINISHED =
    "TopStoreAction.REQUEST_POST_TOPSTORE_FINISHED";

  static REQUEST_DELETE_TOPSTORE = "TopStoreAction.REQUEST_DELETE_TOPSTORE";
  static REQUEST_DELETE_TOPSTORE_FINISHED =
    "TopStoreAction.REQUEST_DELETE_TOPSTORE_FINISHED";

  // METHODS
  // Organization GET function
  static requestTopStore(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopStoreAction.REQUEST_TOPSTORE,
        TopStoreEffect.requestTopStore,
        params,
        callBack
      );
    };
  }

  static requestTopStoreFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopStoreAction.REQUEST_TOPSTORE_FILTER,
        TopStoreEffect.requestTopStoreFilter,
        params
      );
    };
  }

  static requestAudioStreams(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopStoreAction.REQUEST_AUDIO_STREAMS,
        TopStoreEffect.requestAudioStreams,
        params,
        callBack
      );
    };
  }
  static requestTopRetailer(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopStoreAction.REQUEST_TOPRETAILER,
        TopStoreEffect.requestTopRetailer,
        params,
        callBack
      );
    };
  }

  static requestPutTopStore(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopStoreAction.REQUEST_PUT_TOPSTORE,
        TopStoreEffect.requestPutTopStore,
        data,
        id
      );
    };
  }

  static requestPostTopStore(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopStoreAction.REQUEST_POST_TOPSTORE,
        TopStoreEffect.requestPostTopStore,
        data
      );
    };
  }

  static requestDeleteTopStore(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopStoreAction.REQUEST_DELETE_TOPSTORE,
        TopStoreEffect.requestDeleteTopStore,
        id
      );
    };
  }
}
