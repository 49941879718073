import BaseReducer from "../../../../utilities/BaseReducer";
import CmsUploadLinksAction from "./CmsUploadLinksAction";

export default class CmsUploadLinksReducer extends BaseReducer {
  initialState = {
    CmsUploadLinks: [],
  };
  [CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FINISHED](state, action) {
    return {
      ...state,
      CmsUploadLinks: action.payload,
    };
  }

  [CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      CmsUploadLinks: action.payload,
    };
  }
}
