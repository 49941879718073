import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeVideosWebhookAction from "./YoutubeVideosWebhookAction";

export default class ChannelMonitoringYoutubeVideosReducer extends BaseReducer {
  initialState = {
    channelMonitoringYoutubeVideos: [],
  };

  [YoutubeVideosWebhookAction.REQUEST_CHANNEL_MONITORING_YOUTUBE_VIDEOS_WEBHOOK_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      channelMonitoringYoutubeVideos: action.payload,
    };
  }
}
