import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencersActivityAction from "./InfluencersActivityAction";

export default class InfluencersActivityFilterReduser extends BaseReducer {
  //initial state of InfluencersActivityFilterReduser
  initialState = {
    influencersActivityFilter: [],
  };
  //InfluencersActivityFilterReduser request action finish
  [InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencersActivityFilter: action.payload,
    };
  }
}
