import BaseReducer from "../../../utilities/BaseReducer";
import ConsumerAccountAction from "./ConsumerAccountAction";

// CONSUMER AccountReduser  function
export default class ConsumerAccountReducer extends BaseReducer {
  initialState = {
    consumer_account: [],
  };
  [ConsumerAccountAction.REQUEST_CONSUMER_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      consumer_account: action.payload,
    };
  }
}
