import BaseReducer from "../../../../utilities/BaseReducer";
import TrackAction from "./TrackAction";

// TrackReduser
export default class TrackReduser extends BaseReducer {
  initialState = {
    track: [],
  };
  [TrackAction.REQUEST_TRACK_FINISHED](state, action) {
    return {
      ...state,
      track: action.payload,
    };
  }
}
