import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// LinksDataModel
export default class LinksDataModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;
    // response getting from the api
    id = v4();
    created_by = {
        user_id: "",
        user_name: "",
    };
    created_on = "";
    modified_by = {
        user_id: "",
        user_name: "",
    };
    modified_on = "";
    domain_url = "";
    enabled = Boolean;
    last_sync_date = "";
    project = {}
    website_name = ""

    constructor(data) {
        super();

        this.update(data);
    }
}
