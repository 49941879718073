import { BaseModel } from "sjs-base-model";
import ProjectShardsModel from "./ProjectShardsModel";
import ProjectHitsModel from "./ProjectHitsModel";

// get model for project
export default class ProjectModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  status = "";
  _shards = { _shards: ProjectShardsModel };
  current_page_no = 0;
  hits = { hits: [] };
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
