import ActionUtility from "../../utilities/ActionUtility";
import ChatBotEffect from "./ChatBotEffect";

export default class ChatBotAction {
  static REQUEST_POST_CHAT = "ChatBotAction.REQUEST_POST_CHAT";
  static REQUEST_POST_CHAT_FINISHED =
    "ChatBotAction.REQUEST_POST_CHAT_FINISHED";

  static REQUEST_GET_CHAT = "ChatBotAction.REQUEST_GET_CHAT";
  static REQUEST_GET_CHAT_FINISHED = "ChatBotAction.REQUEST_GET_CHAT_FINISHED";

  // METHODS
  static requestGetChat(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChatBotAction.REQUEST_GET_CHAT,
        ChatBotEffect.requestGetChat,
        params,
        callback
      );
    };
  }

  static requestPostChat(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChatBotAction.REQUEST_POST_CHAT,
        ChatBotEffect.requestPostChat,
        data,
        params,
        callback
      );
    };
  }
}
