import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeVideosShadowAction from "./YoutubeVideosShadowAction";

export default class YoutubeVideosShadowReducer extends BaseReducer {
  initialState = {
    youtubeVideosShadow: [],
  };

  [YoutubeVideosShadowAction.REQUEST_YOUTUBE_VIDEOS_SHADOW_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeVideosShadow: action.payload,
    };
  }
}
