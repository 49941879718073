import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import CloudMialListDataModel from "./models/CloudMialListDataModel";

// Cloud Mail List Effect
export default class CloudMialListEffect {
  // Get method for the
  static async requestCloudMailList(params) {
    const endpoint = environment.api.cloudMailList;
    return EffectUtility.getToModel(CloudMialListDataModel, endpoint, params);
  }
}
