import ActionUtility from "../../../utilities/ActionUtility";
import TicketHistoryEffect from "./TicketHistoryEffect";

export default class TicketHistoryAction {
  //tickettype requests
  static REQUEST_TICKET_HISTORY = "TicketHistoryAction.REQUEST_TICKET_HISTORY";
  static REQUEST_TICKET_HISTORY_FINISHED =
    "TicketHistoryAction.REQUEST_TICKET_HISTORY_FINISHED";

  static REQUEST_POST_TICKET_HISTORY =
    "TicketHistoryAction.REQUEST_POST_TICKET_HISTORY";
  static REQUEST_POST_TICKET_HISTORY_FINISHED =
    "TicketHistoryAction.REQUEST_POST_TICKET_HISTORY_FINISHED";

  static REQUEST_TICKET_HISTORY_CLEAR =
    "TicketHistoryAction.REQUEST_TICKET_HISTORY_CLEAR";
  static REQUEST_TICKET_HISTORY_CLEAR_FINISHED =
    "TicketHistoryAction.REQUEST_TICKET_HISTORY_CLEAR_FINISHED";

  static REQUEST_MASS_TICKET_HISTORY =
    "TicketHistoryAction.REQUEST_MASS_TICKET_HISTORY";
  static REQUEST_MASS_TICKET_HISTORY_FINISHED =
    "TicketHistoryAction.REQUEST_MASS_TICKET_HISTORY_FINISHED";

  //TicketType get method
  static requestTicketHistory(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketHistoryAction.REQUEST_TICKET_HISTORY,
        TicketHistoryEffect.requestTicketHistory,
        params
      );
    };
  }

  static requestMassTicketHistory(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketHistoryAction.REQUEST_MASS_TICKET_HISTORY,
        TicketHistoryEffect.requestMassTicketHistory,
        params
      );
    };
  }

  // Ticket history Post function
  static requestPostTicketHistory(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketHistoryAction.REQUEST_POST_TICKET_HISTORY,
        TicketHistoryEffect.requestPostTicketHistory,
        data
      );
    };
  }

  static requestTicketHistoryClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketHistoryAction.REQUEST_TICKET_HISTORY_CLEAR,
        TicketHistoryEffect.requestTicketHistoryClear
      );
    };
  }
}
