import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import ResourceModel from "./models/ResourceModel";

export default class ResourceEffect {
  //get resource request end point for filter
  static async requestResourceFilter(params) {
    const endpoint = environment.api.resourceFilter;
    return EffectUtility.getToModel(ResourceModel, endpoint, params);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
