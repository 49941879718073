import BaseReducer from "../../../../utilities/BaseReducer";
import CustomerAction from "./CustomerAction";

// CustomerReduser
export default class CustomerReducer extends BaseReducer {
  initialState = {
    Customer: [],
  };
  [CustomerAction.REQUEST_CUSTOMER_FINISHED](state, action) {
    return {
      ...state,
      Customer: action.payload,
    };
  }

  [CustomerAction.REQUEST_CUSTOMER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Customer: action.payload,
    };
  }
}
