import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import AmbitionBoxFeedsModel from "./model/AmbitionBoxFeedsModel";
import AmbitionBoxPutModel from "./model/AmbitionBoxPutModel";

// AmbitionBoxFeedsEffect
export default class AmbitionBoxFeedsEffect {
  // calling an API according to the Glassdoor Feeds model
  // Get method for the Ambition Box Feeds
  static async requestAmbitionBoxFeed(params) {
    const endpoint = environment.api.ambitionBoxFeeds;
    return EffectUtility.getToModel(AmbitionBoxFeedsModel, endpoint, params);
  }

  static async requestPutAmbitionBoxFeed(data, id) {
    const endpoint = environment.api.ambitionBoxFeeds + "/" + id;
    return EffectUtility.putToModel(AmbitionBoxPutModel, endpoint, data);
  }

  static async requestAmbitionBoxFeedClear() {
    return {};
  }

  // Get method for the Ambition Box Feeds filter
  static async requestAmbitionBoxFeedFilter(params) {
    const endpoint = environment.api.ambitionBoxFeedsFilter;
    return EffectUtility.getToModel(AmbitionBoxFeedsModel, endpoint, params);
  }
}
