import { BaseModel } from "sjs-base-model";

// InfluencersCampaignPutModel
export default class InfluencersCampaignPutModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;
    // response getting from the api mentioned end point
    // message = "";
    status = "";

    constructor(data) {
        super();
        this.update(data);
    }
}
