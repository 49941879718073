import ActionUtility from "../../../../utilities/ActionUtility";
import StreamPlatformEffect from "./StreamPlatformEffect";

export default class StreamPlatformAction {
  // StreamPlatformAction action with an function declaration
  static REQUEST_STREAM_PLATFORM =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM";
  static REQUEST_STREAM_PLATFORM_FINISHED =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM_FINISHED";

  static REQUEST_STREAM_PLATFORM_FILTER =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER";
  static REQUEST_STREAM_PLATFORM_FILTER_FINISHED =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER_FINISHED";

  static REQUEST_PUT_STREAM_PLATFORM =
    "StreamPlatformAction.REQUEST_PUT_STREAM_PLATFORM";
  static REQUEST_PUT_STREAM_PLATFORM_FINISHED =
    "StreamPlatformAction.REQUEST_PUT_STREAM_PLATFORM_FINISHED";

  static REQUEST_POST_STREAM_PLATFORM =
    "StreamPlatformAction.REQUEST_POST_STREAM_PLATFORM";
  static REQUEST_POST_STREAM_PLATFORM_FINISHED =
    "StreamPlatformAction.REQUEST_POST_STREAM_PLATFORM_FINISHED";

  static REQUEST_STREAM_PLATFORM_CLEAR =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM_CLEAR";
  static REQUEST_STREAM_PLATFORM_CLEAR_FINISHED =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM_CLEAR_FINISHED";

  static REQUEST_STREAM_PLATFORM_FILTER_CLEAR =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER_CLEAR";
  static REQUEST_STREAM_PLATFORM_FILTER_CLEAR_FINISHED =
    "StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER_CLEAR_FINISHED";

  // METHODS
  // platform GET function
  static requesPlatform(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StreamPlatformAction.REQUEST_STREAM_PLATFORM,
        StreamPlatformEffect.requesPlatform,
        params
      );
    };
  }

  static requesPlatformClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StreamPlatformAction.REQUEST_STREAM_PLATFORM_CLEAR,
        StreamPlatformEffect.requesPlatformClear
      );
    };
  }

  static requesPlatformFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER_CLEAR,
        StreamPlatformEffect.requesPlatformFilterClear
      );
    };
  }

  static requestPlatformFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER,
        StreamPlatformEffect.requestPlatformFilter,
        params
      );
    };
  }
  static requestPutPlatform(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StreamPlatformAction.REQUEST_PUT_STREAM_PLATFORM,
        StreamPlatformEffect.requestPutPlatform,
        data,
        id
      );
    };
  }

  static requestPostPlatform(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StreamPlatformAction.REQUEST_POST_STREAM_PLATFORM,
        StreamPlatformEffect.requestPostPlatform,
        data
      );
    };
  }
}
