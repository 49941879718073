import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";
export default class WordCloudSourceModel extends BaseModel {
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = {
    user_id: "",
    user_name: "",
  };
  modified_on = "";
  negative_words = [];
  positive_words = [];
  project = {
    project_id: "",
    project_name: "",
  };

  constructor(data) {
    super();

    this.update(data);
  }
}
