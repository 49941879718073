import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { BorderContainer } from "../../../../../components/componentLayout/ComponentLayout";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function RatingsFilterPopper(props) {
  let { isRequesting } = props;

  const [ratingValue, setRatingValue] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).ratings_Value;
    } else {
      return null;
    }
  });

  let [platformError, setplatformError] = useState(false);

  //platform callback function
  const sentiment_value_handleChange = (event, ratings) => {
    setRatingValue(ratings);
  };

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    if (!ratingValue) {
      setplatformError(true);

      setTimeout(() => {
        setplatformError(false);
      }, 1000);
    } else {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

      if (filters) {
        filters.ratings_Value = ratingValue;
        filters.pageNumber = `0`;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            ratingValue,
          })
        );
      }

      props.saveFilter(filters);
      props.fetchTicketCount();
    }
  }

  function handleClearFilters() {
    if (ratingValue !== null) {
      setRatingValue(null);
      props.clearFilter("ratings");

      localStorage.removeItem("mentionsFilters");
    }
  }

  return (
    <BorderContainer>
      <ClickAwayListener onClickAway={props.handleClose}>
        <div
          autoFocusItem={props.popopen}
          id="composition-menu"
          aria-labelledby="composition-button"
        >
          <Grid
            container
            // className="bg-warning"
            sx={{
              height: 250,
            }}
          >
            <Typography
              component={"p"}
              className="dashboard-title"
              sx={{ fontSize: 18 }}
            >
              Ratings
            </Typography>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              // className="bg-warning"
              sx={{ marginTop: 2, display: "flex", flexWrap: "wrap" }}
            >
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                sx={{
                  animation: platformError && "bounce 0.5s linear",
                  "@keyframes bounce": {
                    "0%, 20%, 50%, 80%, 100%": {
                      transform: "translateY(0)",
                    },
                    "40%": {
                      transform: "translateY(-30px)",
                    },
                    "60%": {
                      transform: "translateY(-15px)",
                    },
                  },
                }}
              >
                <AutoCompleteComponent
                  value={ratingValue}
                  list={props.ratings_value_list ?? []}
                  size="small"
                  textFieldProps={{
                    placeholder: "Ratings",
                  }}
                  disableClearable={true}
                  getOptionLabel={(option) => (option ? option : "")}
                  handleChange={sentiment_value_handleChange}
                />
              </Grid>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item>
                <MuiButton
                  variant="outlined"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Reset"}
                  loading={false}
                  backgroundColor={"white"}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleClearFilters();
                  }}
                />
              </Grid>
              <Grid item>
                <MuiButton
                  variant="contained"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Apply"}
                  loading={false}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleSaveFilters();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ClickAwayListener>
    </BorderContainer>
  );
}

export default RatingsFilterPopper;
