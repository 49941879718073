import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import React from "react";
import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import GoogleAccountAction from "../../stores/googleAccount/GoogleAccountAction";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../components/muiButton/MuiButton";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const PlaystoreAccountAdditionals = (props) => {
  let initial = {};

  if (
    props.PlaystoreAccountData &&
    props.PlaystoreAccountData.apps &&
    props.PlaystoreAccountData.apps.length !== 0
  ) {
    initial = {
      apps: props.PlaystoreAccountData.apps,
    };
  } else {
    initial = {
      apps: [{ app_name: "", package_name: "" }],
    };
  }
  function Required() {
    return (
      <>
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        // validateOnBlur={true}
        // validateOnChange
        validate={(values) => {
          let errors = {};

          values.apps.map((item, index) => {
            if (!item.app_name) {
              errors[`apps.${index}.app_name`] = <Required />;
            }

            if (!item.package_name) {
              errors[`apps.${index}.package_name`] = <Required />;
            }
          });

          return errors;
        }}
        onSubmit={(values) => {
          let params = {
            order_by: '[["created_on", "desc"]]',
            page_limit: "none",
          };

          props
            .dispatch(
              GoogleAccountAction.requestPutGoogleAccount(
                JSON.stringify(values),
                props.PlaystoreAccountData._id
              )
            )
            .then(() => {
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccount(params)
              );
              props.onClose();
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "95%" }}>
                <div className="text-center pb-2">
                  <div className="row">
                    <div className="col-11 ">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">P</span>
                          laystore &nbsp;
                          <span className="span_first_letter">A</span>ccount
                          &nbsp;
                          <span className="span_first_letter">A</span>dditionals
                          &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div
                      className="col-1 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <FieldArray name="apps">
                {({ push, remove }) => (
                  <Grid container spacing={2}>
                    {values.apps.map((role, index) => (
                      <Grid container item xs={12} spacing={2} key={index}>
                        <Grid item container xs={5}>
                          <Grid item xl={12}>
                            <Field
                              name={`apps.${index}.app_name`}
                              as={MuiTextField}
                              id={`apps.${index}.app_name`}
                              size="small"
                              placeholder="App name"
                              variant="outlined"
                            />
                          </Grid>

                          <Grid item xl={12} ml={1}>
                            {errors[`apps.${index}.app_name`] && (
                              <span style={{ position: "absolute" }}>
                                {errors[`apps.${index}.app_name`]}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item container xs={5}>
                          <Grid item xl={12}>
                            <Field
                              name={`apps.${index}.package_name`}
                              as={MuiTextField}
                              id={`apps.${index}.package_name`}
                              placeholder="Package name"
                              variant="outlined"
                              size="small"
                            />
                          </Grid>

                          <Grid item xl={12} ml={1}>
                            {errors[`apps.${index}.package_name`] && (
                              <span style={{ position: "absolute" }}>
                                {errors[`apps.${index}.package_name`]}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        {values.apps.length !== 1 && (
                          <Grid item xs={1}>
                            <MuiButton
                              name={<DeleteIcon />}
                              onClick={() => remove(index)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    ))}
                    {values.apps &&
                      values.apps[values.apps.length - 1] &&
                      values.apps[values.apps.length - 1].app_name &&
                      values.apps[values.apps.length - 1].package_name && (
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent="flex-end"
                          // className="bg-warning"
                        >
                          <Grid item xs={1}>
                            <MuiButton
                              name={<AddIcon />}
                              onClick={() =>
                                push({ app_name: "", package_name: "" })
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                )}
              </FieldArray>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    name={
                      props.PlaystoreAccountData &&
                      props.PlaystoreAccountData.apps &&
                      props.PlaystoreAccountData.apps.length !== 0
                        ? "Submit"
                        : "Add Account"
                    }
                    type="submit"
                    width="100%"
                  />
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Cancel"}
                    width="100%"
                    onClick={() => {
                      props.onClose();
                    }}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(PlaystoreAccountAdditionals);
