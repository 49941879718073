import { BaseModel } from "sjs-base-model";

export default class WebsweepxCMSvsWSXTelegramGetModal extends BaseModel {
  status = "";
  telegram_wsx_links_vs_cms_links = {};

  constructor(data) {
    super();
    this.update(data);
  }
}
