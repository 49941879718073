import environment from "environment";
import ChannelAnalyticsAccountModel from "./models/ChannelAnalyticsAccountModel";
import ChannelAnalyticsAccountPostModel from "./models/ChannelAnalyticsAccountPostModel";
import ChannelAnalyticsPutModel from "./models/ChannelAnalyticsPutModel";
import EffectUtility from "../../../../utilities/EffectUtility";

export default class ChannelAnalyticsAccountEffect {
  //get method
  static async requestChannelAnalyticsAccount(params) {
    const endpoint = environment.api.channelAnalyticsAccount;
    return EffectUtility.getToModel(
      ChannelAnalyticsAccountModel,
      endpoint,
      params
    );
  }

  //post method
  static async requestChannelAnalyticsAccountPost(data) {
    const endpoint = environment.api.channelAnalyticsAccount + "/add";
    return EffectUtility.postToModel(
      ChannelAnalyticsAccountPostModel,
      endpoint,
      data
    );
  }

  // Get method
  static async requestChannelAnalyticsAccountFilter(params) {
    const endpoint = environment.api.channelAnalyticsAccountFilter;
    return EffectUtility.getToModel(
      ChannelAnalyticsAccountModel,
      endpoint,
      params
    );
  }

  // put method
  static async requestChannelAnalyticsAccountPut(data, id) {
    const endpoint = environment.api.channelAnalyticsAccount + "/" + id;
    return EffectUtility.putToModel(ChannelAnalyticsPutModel, endpoint, data);
  }
}
