import { BaseModel } from "sjs-base-model";

export default class PlayStoreDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  created_by = {
    user_id: "",
    user_name: "",
  };

  constructor(data) {
    super();

    this.update(data);
  }
}
