import ActionUtility from "../../../../utilities/ActionUtility";
import YoutubeVideosWebhookEffect from "./YoutubeVideosWebhookEffect";

export default class YoutubeVideosWebhookAction {
  static REQUEST_YOUTUBE_VIDEOS_WEBHOOK =
    "YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK";
  static REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FINISHED";

  static REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FILTER =
    "YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FILTER";
  static REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FILTER_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FILTER_FINISHED";

  static REQUEST_POST_YOUTUBE_VIDEOS_WEBHOOK =
    "YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_VIDEOS_WEBHOOK";
  static REQUEST_POST_YOUTUBE_VIDEOS_WEBHOOK_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_VIDEOS_WEBHOOK_FINISHED";

  static REQUEST_CHANNEL_MONITORING_YOUTUBE_VIDEOS_WEBHOOK =
    "YoutubeVideosWebhookAction.REQUEST_CHANNEL_MONITORING_YOUTUBE_VIDEOS_WEBHOOK";
  static REQUEST_CHANNEL_MONITORING_YOUTUBE_VIDEOS_WEBHOOK_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_CHANNEL_MONITORING_YOUTUBE_VIDEOS_WEBHOOK_FINISHED";

  static REQUEST_POST_YOUTUBE_CHANNEL_WEBHOOK =
    "YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_CHANNEL_WEBHOOK";
  static REQUEST_POST_YOUTUBE_CHANNEL_WEBHOOK_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_CHANNEL_WEBHOOK_FINISHED";

  static REQUEST_SUBSCRIBE_CHANNEL_WEBHOOK =
    "YoutubeVideosWebhookAction.REQUEST_SUBSCRIBE_CHANNEL_WEBHOOK";
  static REQUEST_SUBSCRIBE_CHANNEL_WEBHOOK_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_SUBSCRIBE_CHANNEL_WEBHOOK_FINISHED";

  static REQUEST_DELETE_YOUTUBE_CHANNEL_WEBHOOK =
    "YoutubeVideosWebhookAction.REQUEST_DELETE_YOUTUBE_CHANNEL_WEBHOOK";
  static REQUEST_DELETE_YOUTUBE_CHANNEL_WEBHOOK_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_DELETE_YOUTUBE_CHANNEL_WEBHOOK_FINISHED";

  static REQUEST_POST_YOUTUBE_DATA =
    "YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_DATA";
  static REQUEST_POST_YOUTUBE_DATA_FINISHED =
    "YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_DATA_FINISHED";

  //Methods

  static requestYoutubeVideosWebhook(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK,
        YoutubeVideosWebhookEffect.requestYoutubeVideosWebhook,
        params,
        callBackFunction
      );
    };
  }

  static requestYoutubeVideosWebhookFilter(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FILTER,
        YoutubeVideosWebhookEffect.requestYoutubeVideosWebhookFilter,
        params,
        callBackFunction
      );
    };
  }

  static requestChannelMonitoringYoutubeVideosWebhook(
    params,
    callBackFunction
  ) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_CHANNEL_MONITORING_YOUTUBE_VIDEOS_WEBHOOK,
        YoutubeVideosWebhookEffect.requestChannelMonitoringYoutubeVideosWebhook,
        params,
        callBackFunction
      );
    };
  }

  //POST method
  static requestPostYoutubeVideosWebhook(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_VIDEOS_WEBHOOK,
        YoutubeVideosWebhookEffect.requestPostYoutubeVideosWebhook,
        data
      );
    };
  }

  static requestPostYoutubeChannelsWebhook(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_CHANNEL_WEBHOOK,
        YoutubeVideosWebhookEffect.requestPostYoutubeChannelsWebhook,
        data
      );
    };
  }

  static requestPostSubscribeChannels(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_SUBSCRIBE_CHANNEL_WEBHOOK,
        YoutubeVideosWebhookEffect.requestPostSubscribeChannels,
        data
      );
    };
  }

  static requestDeleteYoutubeChannelWebhook(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_DELETE_YOUTUBE_CHANNEL_WEBHOOK,
        YoutubeVideosWebhookEffect.requestDeleteYoutubeChannelWebhook,
        id
      );
    };
  }

  // to upload the CSV file to generate the screenshot of the links provided in the CSV
  static requestPostYoutubeData(data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeVideosWebhookAction.REQUEST_POST_YOUTUBE_DATA,
        YoutubeVideosWebhookEffect.requestPostYoutubeData,
        data,
        callback
      );
    };
  }
}
