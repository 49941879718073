import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";

function StaticPlatformWiseSentiment(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const pathSymbols = {
    Facebook:
      "path://M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-2.25c0-.928.134-1.312 1.447-1.312h2.553v-4.656c-.444-.061-1.964-.193-3.729-.193-3.693 0-6.271 2.253-6.271 6.393v2.968z",
    Twitter:
      "path://M20.375 1.625h-2.944l-6.435 7.529-6.396-7.529h-3.062c0.247 0.373 0.544 0.857 0.919 1.402l5.454 8.159-5.916 6.899c-0.362 0.416-0.744 0.797-1.048 1.136h2.944l6.792-7.929 6.831 7.929h3.062c-0.26-0.376-0.592-0.771-0.971-1.234l-5.54-6.822 5.892-8.134c0.346-0.478 0.647-0.935 0.938-1.437z",
    Linkedin:
      "path://M5.348 3.5c1.444 0 2.352-1.065 2.352-2.4 0-1.362-.938-2.4-2.352-2.4-1.414 0-2.352 1.038-2.352 2.4 0 1.335.908 2.4 2.304 2.4zm-.064 4.5h4.776v12h-4.776v-12zm7.656 0h4.608v1.608h.064c.648-1.224 2.04-2.52 4.2-2.52 4.488 0 5.328 3.036 5.328 6.984v7.932h-4.8v-7.08c0-1.692-.036-3.876-2.376-3.876-2.376 0-2.736 1.884-2.736 3.72v7.236h-4.8v-12z",
    Youtube:
      "path://M24 12.315c0-1.547-.56-2.905-1.487-3.833-.931-.931-2.289-1.487-3.836-1.487-2.163-.118-4.335-.15-6.499-.15-2.165 0-4.336.032-6.499.15-1.547 0-2.905.56-3.836 1.487-.931.931-1.487 2.289-1.487 3.836-.119 2.165-.15 4.335-.15 6.499 0 2.165.032 4.336.15 6.499.119 1.547.56 2.905 1.487 3.836.931.931 2.289 1.487 3.836 1.487 2.165.118 4.336.15 6.499.15 2.165 0 4.335-.032 6.499-.15 1.547 0 2.905-.56 3.836-1.487.931-.931 1.487-2.289 1.487-3.836.118-2.165.15-4.335.15-6.499 0-2.165-.032-4.336-.15-6.499zm-13.5 8.985v-7.8l6.3 3.9-6.3 3.9z",
    Instagram:
      "path://M12 2.163c3.204 0 3.584.012 4.849.07 1.366.062 2.633.33 3.608 1.306.975.975 1.243 2.242 1.305 3.608.058 1.265.069 1.645.069 4.849s-.012 3.584-.07 4.849c-.062 1.366-.33 2.633-1.305 3.608-.975.975-2.242 1.243-3.608 1.305-1.265.058-1.645.069-4.849.069s-3.584-.012-4.849-.07c-1.366-.062-2.633-.33-3.608-1.305-.975-.975-1.243-2.242-1.305-3.608-.058-1.265-.069-1.645-.069-4.849s.012-3.584.07-4.849c.062-1.366.33-2.633 1.305-3.608.975-.975 2.242-1.243 3.608-1.305 1.265-.058 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-1.576.07-3.035.348-4.272 1.585-1.237 1.237-1.515 2.696-1.585 4.272-.058 1.28-.072 1.688-.072 4.947s.014 3.667.072 4.947c.07 1.576.348 3.035 1.585 4.272 1.237 1.237 2.696 1.515 4.272 1.585 1.28.058 1.688.072 4.947.072s3.667-.014 4.947-.072c1.576-.07 3.035-.348 4.272-1.585 1.237-1.237 1.515-2.696 1.585-4.272.058-1.28.072-1.688.072-4.947s-.014-3.667-.072-4.947c-.07-1.576-.348-3.035-1.585-4.272-1.237-1.237-2.696-1.515-4.272-1.585-1.28-.058-1.688-.072-4.947-.072zm0 5.838c-3.401 0-6.162 2.761-6.162 6.162s2.761 6.162 6.162 6.162 6.162-2.761 6.162-6.162-2.761-6.162-6.162-6.162zm0 10.162c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm6.406-11.845c-.796 0-1.441-.646-1.441-1.441 0-.796.646-1.441 1.441-1.441.796 0 1.441.646 1.441 1.441 0 .796-.646 1.441-1.441 1.441z",
  };
  const getOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },

      formatter: function (params) {
        return params[0].name + ": " + params[0].value;
      },
    },

    xAxis: {
      data: ["Facebook", "Twitter", "Linkedin", "YouTube", "Instagram"],
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: {
        color: "#468C9D",
      },
    },
    yAxis: {
      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
    },
    color: ["#468C9D"],
    series: [
      {
        name: "hill",
        type: "pictorialBar",
        barCategoryGap: "-130%",
        symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
        itemStyle: {
          opacity: 0.5,
        },
        emphasis: {
          itemStyle: {
            opacity: 1,
          },
        },
        data: [123, 60, 25, 18, 12],
        z: 10,
      },
      {
        name: "glyph",
        type: "pictorialBar",
        barGap: "-100%",
        symbolPosition: "end",
        symbolSize: 50,
        symbolOffset: [0, "-120%"],
        data: [
          {
            value: 123,
            symbol: pathSymbols.Facebook,
            symbolSize: [20, 20],
          },
          {
            value: 60,
            symbol: pathSymbols.Twitter,
            symbolSize: [20, 20],
          },
          {
            value: 25,
            symbol: pathSymbols.Linkedin,
            symbolSize: [20, 20],
          },
          {
            value: 18,
            symbol: pathSymbols.Youtube,
            symbolSize: [20, 20],
          },
          {
            value: 12,
            symbol: pathSymbols.Instagram,
            symbolSize: [20, 20],
          },
        ],
      },
    ],
  };
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default StaticPlatformWiseSentiment;
