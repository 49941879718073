import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import AppStatusModel from "./models/AppStatusModel";
import AppStatusPutModel from "./models/AppStatusPutModel";

export default class AppStatusEffect {
  // calling an API according to the App Status
  static async requestAppStatus(params) {
    const endpoint = environment.api.appStatus;

    return EffectUtility.getToModel(AppStatusModel, endpoint, params);
  }

  // Put API
  static async requestPutAppStatus(data, id) {
    const endpoint = environment.api.appStatus + "/" + id;

    return EffectUtility.putToModel(AppStatusPutModel, endpoint, data);
  }
}
