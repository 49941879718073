import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import TwitterModel from "./models/TwitterModel";
import TwitterPutModel from "./models/TwitterPutModel";
import TwitterPostModel from "./models/TwitterPostModel";
import TweetDeleteModel from "./models/TweetDeleteModel";

// twitter api fetch model
export default class TwitterEffect {
  // calling an API according to the twitterModel
  static async requestTwitter(params, callBackFunction) {
    const endpoint = environment.api.twitter;

    let response = EffectUtility.getToModel(TwitterModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestTwitterFilter(params) {
    const endpoint = environment.api.twitterfilter;

    return EffectUtility.getToModel(TwitterModel, endpoint, params);
  }

  // Post API
  static async requstPostTwitter(params, data) {
    const endpoint = environment.api.platformPost + "?" + params;

    return EffectUtility.postToModel(TwitterPostModel, endpoint, data);
  }

  // put method for the Twitter Feeds
  static async requestPutTwitterFeeds(data, id) {
    const endpoint = environment.api.twitter + "/" + id;
    return EffectUtility.putToModel(TwitterPutModel, endpoint, data);
  }

  static async requestTweetDelete(id) {
    const endpoint = environment.api.tweetDelete + "/" + id;
    return EffectUtility.deleteToModel(TweetDeleteModel, endpoint);
  }

  static async requestTwitterFeedClear() {
    return {};
  }
}
