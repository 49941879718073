import environment from "environment";
import TopReleaseModel from "./model/TopReleaseModel";
import TopReleasePutModel from "./model/TopReleasePutModel";
import TopReleasePostModel from "./model/TopReleasePostModel";
import EffectUtility from "../../../../utilities/EffectUtility";

// TopReleaseEffect
export default class TopReleaseEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestTopRelease(params, callBack) {
    const endpoint = environment.api.topReleases;
    let response = EffectUtility.getToModel(TopReleaseModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // Get method for the track filter
  static async requestTopReleaseFilter(params) {
    const endpoint = environment.api.track;
    return EffectUtility.getToModel(TopReleaseModel, endpoint, params);
  }

  // put method for the track
  static async requestPutTopRelease(data, id) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.putToModel(TopReleasePutModel, endpoint, data);
  }

  // post method for the track
  static async requestPostTopRelease(data) {
    const endpoint = environment.api.track + "/add";
    return EffectUtility.postToModel(TopReleasePostModel, endpoint, data);
  }

  static async requestDeleteTopRelease(id) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.deleteToModel(TopReleasePostModel, endpoint);
  }
}
