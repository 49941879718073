import React, { useEffect, useRef, useState } from "react";
import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Zoom from "@mui/material/Zoom";
import Popper from "@mui/material/Popper";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  BackGroundContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../componentLayout/ComponentLayout";
import { makeStyles } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import OrmNotificationAction from "../../stores/ormNotifications/ORMNotificationAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import CircularLoadingIndicator from "../loading-indicator/CircularLoadingIndicator";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { theme } from "../../views/App";
import NotificationDialog from "../../ormTicketing/views/notifications/NotificationDialog";

const useStyles = makeStyles({
  gridContainer: {
    height: 250, // set the height of the grid container
    overflow: "auto", // add scrollbars when necessary
    "&::-webkit-scrollbar": {
      display: "none", // hide the scrollbar for webkit-based browsers (e.g. Chrome, Safari)
    },
    "-ms-overflow-style": "none", // hide the scrollbar for Internet Explorer and Edge
    "scrollbar-width": "none", // hide the scrollbar for Firefox and other browsers that support the new scrollbar style
  },
});

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS,
    OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_BY_ID,
  ]),
  ormNotificationsById: state.ormNotificationsById.ormNotificationsById || [],
});

function NotificationBox(props) {
  const [open, setOpen] = React.useState(false);
  const notificationPopperRef = React.useRef(null);
  const scrollRef = useRef(null);
  const prevOpen = React.useRef(open);

  const classes = useStyles();
  const navigate = useNavigate();

  let { isRequesting } = props;

  useEffect(() => {
    if (open) fetchOrmNotifications();
  }, [open]);

  function fetchOrmNotifications() {
    let Id = localStorage.getItem("userId");
    let params = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,

      // order_by: '[["created_on", "desc"]]',
    };
    props.dispatch(
      OrmNotificationAction.requestOrmNotificationsById(Id, params)
    );
  }

  function UpdateNotificationCount() {
    props.setNewNotificationsCount(props.count - 1);
  }

  let unreadNotificationList = [];
  props.ormNotificationsById &&
    props.ormNotificationsById.hits &&
    props.ormNotificationsById.hits.filter(
      (item) => item._source.notification_msg_read_status === false
    ).length &&
    (unreadNotificationList = props.ormNotificationsById.hits.filter(
      (item) => item._source.notification_msg_read_status === false
    ));

  const handleNotificationPopper = () => {
    setOpen((prevOpen) => !prevOpen);
    // setNotifictionsUnread(0);
    let bulkUpdate = [];
    unreadNotificationList.length &&
      unreadNotificationList.map((item) => {
        return bulkUpdate.push({
          _id: item._id,
          _index: item._index,
          _score: item._score,
          _source: {
            notification_msg_read_status: true,
          },
        });
      });
    // props.dispatch(
    //   OrmNotificationAction.requestPutOrmNotificationsBulkUpdate(
    //     JSON.stringify({ data: bulkUpdate })
    //   )
    // );
  };
  const [notificationsUnread, setNotifictionsUnread] = useState(0);

  useEffect(() => {
    setNotifictionsUnread(props.count);
    props.ormNotificationsById &&
      props.ormNotificationsById.hits &&
      props.ormNotificationsById.hits.filter(
        (item) => item._source.notification_msg_read_status === false
      ).length &&
      setNotifictionsUnread(
        props.ormNotificationsById.hits.filter(
          (item) => item._source.notification_msg_read_status === false
        ).length
      );
  }, [props.count]);

  const handleClose = (event) => {
    if (
      notificationPopperRef.current &&
      notificationPopperRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      notificationPopperRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const container = scrollRef.current;
    container?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function returnLocalTime(time) {
    let timeConversion = new Date(time);
    timeConversion =
      timeConversion.toDateString() +
      ", " +
      timeConversion.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

    return timeConversion;
  }

  const [notificationsData, setNotificationData] = useState({});
  const [openNotificationDialog, setOpenNotificationsDialog] = useState(false);

  return (
    <Grid container xs={6}>
      <NotificationDialog
        open={openNotificationDialog}
        onClose={() => {
          setOpenNotificationsDialog(false);
        }}
        notificationsData={notificationsData}
      />

      <Typography
        component={"div"}
        ref={notificationPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleNotificationPopper}
        style={{ backgroundColor: "none" }}
      >
        <Tooltip title="ORM Notifications" arrow placement="bottom">
          <Badge badgeContent={props.count} color="secondary">
            <NotificationsIcon />
          </Badge>
        </Tooltip>
      </Typography>

      <Popper
        open={open}
        anchorEl={notificationPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Zoom
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
            timeout={300}
          >
            <Paper style={{ width: 350 }}>
              {props.ormNotificationsById &&
              props.ormNotificationsById.hits &&
              props.ormNotificationsById.hits.length ? (
                <ShadowContainer>
                  {!isRequesting ? (
                    <ClickAwayListener onClickAway={handleClose}>
                      <div
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          justifyContent="flex-end"
                          sx={{
                            position: "sticky",
                            top: 0,
                            mb: 2,
                          }}
                        >
                          <Typography
                            component={"span"}
                            onClick={() => {
                              navigate("/notifications");
                              setOpen(false);
                            }}
                            sx={{
                              boxSizing: "border-box",
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              paddingX: "15px",
                              paddingY: "4px",
                              borderRadius: "4px",
                              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                              "&:hover": {
                                fontWeight: "bold",
                                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.6)",
                              },
                            }}
                          >
                            View All
                          </Typography>
                        </Grid>
                        {props?.ormNotificationsById?.hits?.filter((item) => {
                          return (
                            item?._source?.notification_msg_read_status ===
                            false
                          );
                        }).length !== 0 ? (
                          <Grid
                            container
                            xs={12}
                            gap={1}
                            className={classes.gridContainer}
                            ref={scrollRef}
                          >
                            {props.ormNotificationsById.hits
                              .filter((item) => {
                                return (
                                  item?._source
                                    ?.notification_msg_read_status === false
                                );
                              })
                              .map((notification, ind) => (
                                <Grid
                                  item
                                  key={ind}
                                  xs={12}
                                  onClick={() => {
                                    setNotificationData({
                                      id: notification._id,
                                      created_by:
                                        notification?._source.created_by,
                                      created_on:
                                        notification?._source.created_on,
                                      modified_by:
                                        notification?._source.modified_by,
                                      modified_on:
                                        notification?._source.modified_on,
                                      notification_image_url:
                                        notification?._source
                                          .notification_image_url,
                                      notification_msg:
                                        notification?._source.notification_msg,
                                      notification_msg_read_status:
                                        notification?._source
                                          .notification_msg_read_status,
                                      notification_sent:
                                        notification?._source.notification_sent,
                                      notification_title:
                                        notification?._source
                                          .notification_title,
                                      notification_type:
                                        notification?._source.notification_type,
                                    });
                                    setOpenNotificationsDialog(true);
                                    let post_data = {
                                      notification_msg_read_status: true,
                                    };
                                    notification?._source
                                      .notification_msg_read_status === false &&
                                      props
                                        .dispatch(
                                          OrmNotificationAction.requestPutOrmNotifications(
                                            JSON.stringify(post_data),
                                            notification._id
                                          )
                                        )
                                        .then(() => {
                                          UpdateNotificationCount();
                                        });
                                  }}
                                >
                                  <ModelScreenContainer
                                    sx={{
                                      backgroundColor:
                                        theme.palette.background.primary,
                                      borderColor: theme.palette.primary.main,
                                    }}
                                  >
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        Title:{" "}
                                      </span>
                                      {notification._source.notification_title}
                                    </p>
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        Message:{" "}
                                      </span>
                                      {notification._source.notification_msg}{" "}
                                    </p>
                                    <span style={{ fontWeight: "bold" }}>
                                      {returnLocalTime(
                                        notification._source.created_on
                                      )}
                                    </span>
                                  </ModelScreenContainer>
                                </Grid>
                              ))}
                          </Grid>
                        ) : (
                          <Typography
                            component={"span"}
                            sx={{ fontSize: "18px" }}
                          >
                            <hr
                              className="p-0 mb-4"
                              style={{
                                color: theme.palette.primary.main,
                                marginBottom: theme.spacing(2),
                              }}
                            />
                            No Notifications
                          </Typography>
                        )}
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <div style={{ marginTop: "50px" }}>
                      <CircularLoadingIndicator isActive={isRequesting} />
                    </div>
                  )}
                </ShadowContainer>
              ) : !isRequesting ? (
                <ClickAwayListener onClickAway={handleClose}>
                  <ShadowContainer sx={{ width: "100%" }}>
                    <Typography component={"span"}>No Notifications</Typography>
                  </ShadowContainer>
                </ClickAwayListener>
              ) : (
                <CircularLoadingIndicator isActive={isRequesting} />
              )}
            </Paper>
          </Zoom>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapStateToProps)(NotificationBox);
