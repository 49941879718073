import BaseReducer from "../../../../utilities/BaseReducer";
import A3NotificationAction from "./A3NotificationAction";

export default class A3NotificationReducer extends BaseReducer {
  //initialState of Notifications filter
  initialState = {
    A3Notifications: [],
  };

  //Notifications Filter
  [A3NotificationAction.REQUEST_A3_NOTIFICATIONS_FINISHED](state, action) {
    return {
      ...state,
      A3Notifications: action.payload,
    };
  }
}
