import BaseReducer from "../../../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

export default class ClientWiseRevenueReduser extends BaseReducer {
  initialState = {
    clientwiserevnue: [],
  };
  [A3ReportsAction.REQUEST_CLIENTWISE_REVENUE_FINISHED](state, action) {
    return {
      ...state,
      clientwiserevnue: action.payload,
    };
  }
}
