import BaseReducer from "../../../utilities/BaseReducer";
import RetweetAction from "./RetweetAction";

// TwitterDirectMessageReducer function
export default class RetweetReducer extends BaseReducer {
  initialState = {
    retweet: [],
  };

  [RetweetAction.REQUEST_RETWEET_FINISHED](state, action) {
    return {
      ...state,
      retweet: action.payload,
    };
  }
}
