import { BaseModel } from "sjs-base-model";

// get model for project
export default class OrmResourceModel extends BaseModel {
  resources = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
