import BaseReducer from "../../../../utilities/BaseReducer";
import TopComposerAction from "./TopComposerAction";

// TopComposerReduser
export default class TopComposerReducer extends BaseReducer {
  initialState = {
    top_composers_report: [],
  };
  [TopComposerAction.REQUEST_TOP_COMPOSER_FINISHED](state, action) {
    return {
      ...state,
      top_composers_report: action.payload,
    };
  }
}
