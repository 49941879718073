import React, { forwardRef } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTheme } from "@mui/material";
import MuiTextField from "../muiTextField/MuiTextField";

const DateTimePickerComponent = forwardRef(
  ({
    value,
    onChange,
    fieldheight,
    views,
    disableFuture,
    disablePast,
    minDate,
    format,
    width,
    viewRenderers,
    error,
    helperText,
    maxDate,
  }) => {
    const theme = useTheme();

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          components={["DateTimePicker", "DateTimePicker"]}
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          value={value && dayjs(value)}
          format={format ? format : "DD/MM/YYYY hh:mm:ss a"}
          onChange={onChange}
          views={views ? views : ["year", "day", "hours", "minutes", "seconds"]}
          slots={{
            textField: MuiTextField,
          }}
          viewRenderers={viewRenderers}
          slotProps={{
            textField: {
              size: "small",
              label: "",
              style: {
                width: width ? width : "100%",
                outline: `1px ${theme.palette.borderColor.textField}`,
                backgroundColor: theme.palette.background.paper,
                borderRadius: "8px",
                height: fieldheight ? fieldheight : "100%",
              },
              helperText: helperText ?? "",
              error: error ?? false,
            },
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default DateTimePickerComponent;
