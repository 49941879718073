import BaseReducer from "../../../../utilities/BaseReducer";
import WebsiteLinksAction from "./WebsiteLinksAction";

// WebsiteLinksReduser
export default class WebsiteLinksReduser extends BaseReducer {
  initialState = {
    websiteLinks: [],
  };
  [WebsiteLinksAction.REQUEST_WEBSITE_LINKS_FINISHED](state, action) {
    return {
      ...state,
      websiteLinks: action.payload,
    };
  }
}
