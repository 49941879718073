import React, { useState } from "react";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { WidthFull } from "@mui/icons-material";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
const StaticDialySentimentTrend = (props) => {
  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  let LabelList = [
    "Jul 4, 2024",
    "Jul 5, 2024",
    "Jul 6, 2024",
    "Jul 7, 2024",
    "Jul 8, 2024",
    "Jul 9, 2024",
    "Jul 10, 2024",
    "Jul 11, 2024",
    "Jul 12, 2024",
    "Jul 13, 2024",
    "Jul 14, 2024",
    "Jul 15, 2024",
    "Jul 16, 2024",
    "Jul 17, 2024",
    "Jul 18, 2024",
    "Jul 19, 2024",
    "Jul 20, 2024",
    "Jul 21, 2024",
    "Jul 22, 2024",
    "Jul 23, 2024",
    "Jul 24, 2024",
    "Jul 25, 2024",
    "Jul 26, 2024",
    "Jul 27, 2024",
    "Jul 28, 2024",
    "Jul 29, 2024",
    "Jul 30, 2024",
    "Jul 31, 2024",
    "Aug 1, 2024",
    "Aug 2, 2024",
    "Aug 3, 2024",
    "Aug 4, 2024",
    "Aug 5, 2024",
    "Aug 6, 2024",
    "Aug 7, 2024",
    "Aug 8, 2024",
    "Aug 9, 2024",
    "Aug 10, 2024",
  ];
  let Positive_Sentiment = [
    3, 8, 0, 0, 9, 1, 8, 8, 2, 0, 0, 2, 5, 4, 1, 0, 0, 0, 0, 11, 1, 4, 0, 0, 0,
    0, 2, 2, 1, 1, 0, 0, 2, 1, 0, 0, 0, 2,
  ];
  let Negative_Sentiment = [
    1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ];
  let Neutral_Sentiment = [
    4, 4, 1, 0, 2, 6, 3, 2, 1, 0, 0, 0, 4, 2, 0, 0, 0, 0, 0, 6, 2, 0, 0, 0, 0,
    1, 1, 7, 4, 0, 0, 0, 0, 0, 1, 8, 1, 0,
  ];
  let Total_Sentiment = [];

  function date_format(val) {
    return val;
  }

  function TotalCalculator(val) {
    return val.map((item) => item.count).reduce((prev, next) => prev + next);
  }

  let Total_list = [
    {
      date_wise: [
        [
          "10-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 2,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "09-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 1,
              key: "neutral",
            },
          ],
        ],
        [
          "08-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 8,
              key: "neutral",
            },
          ],
        ],
        [
          "07-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 1,
              key: "neutral",
            },
          ],
        ],
        [
          "06-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 1,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "05-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 2,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "04-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "03-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "02-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 1,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "01-08-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 1,
              key: "positive_sentiment",
            },
            {
              count: 4,
              key: "neutral",
            },
          ],
        ],
        [
          "31-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 2,
              key: "positive_sentiment",
            },
            {
              count: 7,
              key: "neutral",
            },
          ],
        ],
        [
          "30-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 2,
              key: "positive_sentiment",
            },
            {
              count: 1,
              key: "neutral",
            },
          ],
        ],
        [
          "29-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 1,
              key: "neutral",
            },
          ],
        ],
        [
          "28-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "27-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "26-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "25-07-2024",
          [
            {
              count: 1,
              key: "negative_sentiment",
            },
            {
              count: 4,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "24-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 1,
              key: "positive_sentiment",
            },
            {
              count: 2,
              key: "neutral",
            },
          ],
        ],
        [
          "23-07-2024",
          [
            {
              count: 2,
              key: "negative_sentiment",
            },
            {
              count: 11,
              key: "positive_sentiment",
            },
            {
              count: 6,
              key: "neutral",
            },
          ],
        ],
        [
          "22-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "21-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "20-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "19-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "18-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 1,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "17-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 4,
              key: "positive_sentiment",
            },
            {
              count: 2,
              key: "neutral",
            },
          ],
        ],
        [
          "16-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 5,
              key: "positive_sentiment",
            },
            {
              count: 4,
              key: "neutral",
            },
          ],
        ],
        [
          "15-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 2,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "14-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "13-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "12-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 2,
              key: "positive_sentiment",
            },
            {
              count: 1,
              key: "neutral",
            },
          ],
        ],
        [
          "11-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 8,
              key: "positive_sentiment",
            },
            {
              count: 2,
              key: "neutral",
            },
          ],
        ],
        [
          "10-07-2024",
          [
            {
              count: 1,
              key: "negative_sentiment",
            },
            {
              count: 8,
              key: "positive_sentiment",
            },
            {
              count: 3,
              key: "neutral",
            },
          ],
        ],
        [
          "09-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 1,
              key: "positive_sentiment",
            },
            {
              count: 6,
              key: "neutral",
            },
          ],
        ],
        [
          "08-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 9,
              key: "positive_sentiment",
            },
            {
              count: 2,
              key: "neutral",
            },
          ],
        ],
        [
          "07-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 0,
              key: "neutral",
            },
          ],
        ],
        [
          "06-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 0,
              key: "positive_sentiment",
            },
            {
              count: 1,
              key: "neutral",
            },
          ],
        ],
        [
          "05-07-2024",
          [
            {
              count: 0,
              key: "negative_sentiment",
            },
            {
              count: 8,
              key: "positive_sentiment",
            },
            {
              count: 4,
              key: "neutral",
            },
          ],
        ],
        [
          "04-07-2024",
          [
            {
              count: 1,
              key: "negative_sentiment",
            },
            {
              count: 3,
              key: "positive_sentiment",
            },
            {
              count: 4,
              key: "neutral",
            },
          ],
        ],
      ],
    },
    {
      total_mentions: 143,
    },
    {
      total_negative: 5,
    },
    {
      total_neutral: 60,
    },
    {
      total_positive: 78,
    },
  ];

  let date_wise = "";
  let total_mentions = "";
  let total_negative = "";
  let total_neutral = "";
  let total_positive = "";

  [date_wise, total_mentions, total_negative, total_neutral, total_positive] =
    Total_list;

  function returnMonthName(monthString) {
    const dateString = monthString;
    const [day, month, year] = dateString.split("-");
    const date = new Date(`${year}`, month - 1, day);
    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });

    return formattedDate;
  }

  const state = {
    labels: LabelList.reverse(),
    datasets: [
      {
        label: "Positive Sentiment",
        backgroundColor: `${theme.reports.positive}`,
        borderWidth: 1.5,
        borderColor: `${theme.reports.positive}`,
        data: Positive_Sentiment.reverse(),
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
      {
        label: "Negative Sentiment",
        backgroundColor: `${theme.reports.negative}`,
        data: Negative_Sentiment.reverse(),
        borderWidth: 1.5,
        borderColor: `${theme.reports.negative}`,
        lineTension: 0.3,
        pointStyle: "triangle",
        pointRadius: 6,
      },
      {
        label: "Neutral",
        backgroundColor: `${theme.reports.neutral}`,
        data: Neutral_Sentiment.reverse(),
        borderWidth: 1.5,
        borderColor: `${theme.reports.neutral}`,
        lineTension: 0.3,
        pointStyle: "rect",
        pointRadius: 6,
      },
    ],
  };

  let NoDataFoundBool =
    !loading && total_mentions && total_mentions.total_mentions === 0;

  const chartData = [
    {
      id: "Positive(+ve)",
      color: `${theme.reports.positive}`,
      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Positive_Sentiment[index],
      })),
    },
    {
      id: "Negative(-ve)",
      color: `#F26D4E`,

      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Negative_Sentiment[index],
      })),
    },
    {
      id: "Neutral(∅)",
      color: `${theme.reports.neutral}`,

      data: LabelList.map((Label, index) => ({
        x: new Date(Label).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        y: Neutral_Sentiment[index],
      })),
    },
  ];
  //600 width 330 height
  console.log("dsljk", props.ChartContainerHeight);
  const getOption = () => {
    const legendData = chartData.map((series) => series.id);

    let allYValues = [];
    chartData?.forEach((series) => {
      series.data.forEach((point) => {
        allYValues.push(point.y);
      });
    });

    const minValue = Math.min(...allYValues);
    const maxValue = Math.max(...allYValues);

    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    return {
      tooltip: {
        trigger: "axis",
        position: function (pt) {
          return [pt[0], "10%"];
        },
        confine: true,

        formatter: (params) => {
          let tooltipContent = params
            .map((param, index) => {
              let val = param.value;
              let formattedValue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${param.color};border-radius:50%;"></span>`;
              return `${colorSpan}${param.seriesName}: ${formattedValue}`;
            })
            .join("<br>");
          return tooltipContent;
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: chartData && chartData[0]?.data.map((point) => point.x),
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Date"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          hideOverlap: true,
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"],
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: -20,
        },
        nameGap: 30,
        min: minValue,
        max: maxValue,
        axisLabel: {
          padding: props.detailedReport ? [0, 50, 0, 0] : [0, 0, 0, 0],
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
          hideOverlap: true,
        },
      },
      legend: {
        show: true,
        data: shouldShowLegend ? legendData : [],
        top: 0,
      },

      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100,
        },
      ],
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "15%" : "10%",
        //top: "15%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      series:
        chartData &&
        chartData.map((series) => ({
          name: series.id,
          type: "line",
          symbol: "none",
          sampling: "lttb",
          itemStyle: {
            color: series.color,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: series.color,
              },
              {
                offset: 1,
                color: series.color,
              },
            ]),
          },
          data: series.data.map((point) => point.y),
        })),
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {!loading && !NoDataFoundBool ? (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: "12",
                  }}
                >
                  {props.detailedReport && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "5px",
                        fontSize: "14px",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                        }}
                      >
                        <span
                          style={{
                            color: `${theme.reports.total}`,
                            fontWeight: "bold",
                          }}
                        >
                          Total Tickets &nbsp;&nbsp;
                          {total_mentions && total_mentions.total_mentions}
                        </span>
                      </div>
                      <div
                        style={{
                          width: "20%",
                        }}
                      >
                        <span style={{ color: `${theme.reports.positive}` }}>
                          Positive&nbsp;&nbsp;
                          {total_positive && total_positive.total_positive}
                        </span>
                      </div>
                      <div
                        style={{
                          width: "20%",
                        }}
                      >
                        <span style={{ color: `${theme.reports.negative}` }}>
                          Negative &nbsp;&nbsp;
                          {total_negative && total_negative.total_negative}
                        </span>
                      </div>
                      <div style={{ width: "20%" }}>
                        <span style={{ color: `${theme.reports.neutral}` }}>
                          Neutral &nbsp;&nbsp;
                          {total_neutral && total_neutral.total_neutral}
                        </span>
                      </div>
                    </Box>
                  )}
                  <Box style={{ width: "100%", height: "100%" }}>
                    {chartData?.length && (
                      <ReactEcharts
                        option={getOption()}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        notMerge={true}
                        lazyUpdate={true}
                      />
                    )}

                    {/* <ChartsRe option={getOption()} /> */}
                  </Box>
                </Box>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
};

export default StaticDialySentimentTrend;
