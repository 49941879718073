import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import GlassdoorAccountDeleteModel from "./model/GlassdoorAccountDeleteModel";
import GlassdoorAccountModel from "./model/GlassdoorAccountModel";
import GlassdoorAccountPostModel from "./model/GlassdoorAccountPostModel";
import GlassdoorAccountPutModel from "./model/GlassdoorAccountPutModel";

// GlassdoorAccountEffect
export default class GlassdoorAccountEffect {
  // calling an API according to the Glassdoor Account model
  // Get method for the Glassdoor account
  static async requestGlassdoorAccount(params) {
    const endpoint = environment.api.glassdoorAccount;
    return EffectUtility.getToModel(GlassdoorAccountModel, endpoint, params);
  }

  // Get method for the Glassdoor account filter
  static async requestGlassdoorAccountFilter(params) {
    const endpoint = environment.api.glassdoorAccountFilter;
    return EffectUtility.getToModel(GlassdoorAccountModel, endpoint, params);
  }

  // put method for the Glassdoor account filter
  static async requestPutGlassdoorAccount(data, id) {
    const endpoint = environment.api.glassdoorAccount + "/" + id;
    return EffectUtility.putToModel(GlassdoorAccountPutModel, endpoint, data);
  }

  // post method for the Glassdoor account filter
  static async requestPostGlassdoorAccount(data) {
    const endpoint = environment.api.glassdoorAccount + "/add";

    return EffectUtility.postToModel(GlassdoorAccountPostModel, endpoint, data);
  }

  // delete method for the Glassdoor account filter
  static async requestDeleteGlassdoorAccount(id) {
    const endpoint = environment.api.glassdoorAccount + "/" + id;

    return EffectUtility.deleteToModel(GlassdoorAccountDeleteModel, endpoint);
  }
}
