import BaseReducer from "../../../../utilities/BaseReducer";
import McnMonitoringAction from "./McnMonitoringAction";

// McnMonitoringReduser  function
export default class McnMonitoringReduser extends BaseReducer {
  initialState = {
    mcnMonitoring: [],
  };
  [McnMonitoringAction.REQUEST_MCN_MONITORING_FINISHED](state, action) {
    return {
      ...state,
      mcnMonitoring: action.payload,
    };
  }
}
