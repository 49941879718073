import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// faceBook Account  data model
export default class IndeedAccountGetModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  _shards = {};
  current_page_no = 0;
  hits = {};
  timed_out = Boolean;
  took = 0;
  total_page_no = 0;

  constructor(data) {
    super();

    this.update(data);
  }
}
