import environment from "environment";
import TagModel from "./models/TagModel";
import TagPostModel from "./models/TagPostModel";
import TagPutModel from "./models/TagPutModel";
import TagDeleteModel from "./models/TagDeleteModel";
import TagBulkUpdatePutModel from "./models/TagBulkUpdatePutModel";
import EffectUtility from "../../../utilities/EffectUtility";

export default class TagEffect {
  // calling an API according to the TagModel
  static async requestTag(params) {
    const endpoint = environment.api.tag;
    return EffectUtility.getToModel(TagModel, endpoint, params);
  }

  static async requestTagFilter(params) {
    const endpoint = environment.api.tag;
    return EffectUtility.getToModel(TagModel, endpoint, params);
  }

  // Post API
  static async requestPostTag(data) {
    const endpoint = environment.api.tag + "/add";

    return EffectUtility.postToModel(TagPostModel, endpoint, data);
  }

  // Put API
  static async requestPutTag(data, id) {
    const endpoint = environment.api.tag + "/" + id;

    return EffectUtility.putToModel(TagPutModel, endpoint, data);
  }

  // Delete API
  static async requestDeleteTag(id) {
    const endpoint = environment.api.tag + "/" + id;

    return EffectUtility.deleteToModel(TagDeleteModel, endpoint);
  }

  // Put method for the Tag bulk update
  static async requestPutTagBulkUpdate(data, callBack) {
    const endpoint = environment.api.tagBulkUpdate;
    const response = EffectUtility.putToModel(
      TagBulkUpdatePutModel,
      endpoint,
      data
    );
    if (response && callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }
}
