import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import WhitelistGetModel from "./models/WhitelistGetModel";
import WhitelistPostModel from "./models/WhiteListPostModel";

export default class WhiteListEffect {
  static async requestGetWhitelist(params) {
    const endpoint = environment.api.whitelist;
    let response = EffectUtility.getToModel(
      WhitelistGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostWhitelist(params) {
    const endpoint = environment.api.whitelist;
    let response = EffectUtility.postToModel(
      WhitelistPostModel,
      endpoint,
      params
    );
    return response;
  }
}
