import { BaseModel } from "sjs-base-model";
import FaceBookAccountDataHitsModel from "./FaceBookAccountDataHitsModel";

//faceBook account HitsModel
export default class FaceBookAccountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ FaceBookAccountDataHitsModel}];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
