import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";
import * as echarts from "echarts";

function StaticSubcategoryTrend(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const yearCount = 6;
  const categoryCount = 30;
  const xAxisData = [];
  const customData = [];

  const dataList = [];
  const legendData = ["trend", "Sub1", "Sub2", "Sub3", "Sub4", "Sub5", "Sub6"];
  const encodeY = [];
  for (var i = 0; i < yearCount; i++) {
    dataList.push([]);
    encodeY.push(1 + i);
  }
  for (var i = 0; i < categoryCount; i++) {
    var val = Math.random() * 1000;
    xAxisData.push("category" + i);
    var customVal = [i];
    customData.push(customVal);
    for (var j = 0; j < dataList.length; j++) {
      var value =
        j === 0
          ? echarts.number.round(val, 2)
          : echarts.number.round(
              Math.max(0, dataList[j - 1][i] + (Math.random() - 0.5) * 200),
              2
            );
      dataList[j].push(value);
      customVal.push(value);
    }
  }
  const colorss1 = [
    "#6BA5B2",
    "#468C9D",
    "#92C4D0",
    "#B4D5DD",
    "#9DBFC4",
    "#D8EAEF",
  ];
  const getOption = {
    tooltip: {
      trigger: "axis",
    },

    legend: {
      //   top: "10%",
      icon: "circle",
      type: "scroll",
      data: legendData,
    },
    dataZoom: [
      {
        type: "inside",
        start: 50,
        end: 70,
      },
    ],
    xAxis: {
      data: xAxisData,
    },
    grid: {
      top: "15%",
    },
    yAxis: {},
    series: [
      {
        type: "custom",
        name: "trend",
        renderItem: function (params, api) {
          var xValue = api.value(0);
          var currentSeriesIndices = api.currentSeriesIndices();
          var barLayout = api.barLayout({
            barGap: "30%",
            barCategoryGap: "20%",
            count: currentSeriesIndices.length - 1,
          });
          var points = [];
          for (var i = 0; i < currentSeriesIndices.length; i++) {
            var seriesIndex = currentSeriesIndices[i];
            if (seriesIndex !== params.seriesIndex) {
              var point = api.coord([xValue, api.value(seriesIndex)]);
              point[0] += barLayout[i - 1].offsetCenter;
              point[1] -= 20;
              points.push(point);
            }
          }
          var style = api.style({
            stroke: api.visual("color"),
            fill: "none",
          });
          return {
            type: "polyline",
            shape: {
              points: points,
            },
            style: style,
          };
        },
        itemStyle: {
          borderWidth: 2,
          color: "#468C9D",
        },
        encode: {
          x: 0,
          y: encodeY,
        },
        data: customData,
        z: 100,
      },
      ...dataList.map(function (data, index) {
        return {
          type: "bar",
          animation: false,
          name: legendData[index + 1],
          itemStyle: {
            color: colorss1[index],
            opacity: 0.5,
          },
          data: data,
        };
      }),
    ],
  };
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default StaticSubcategoryTrend;
