import BaseReducer from "../../../../utilities/BaseReducer";
import BlogsAction from "./BlogsAction";

// GetClientEmailReduser
export default class GetClientEmailReduser extends BaseReducer {
  initialState = {
    get_client_email_address: [],
  };
  [BlogsAction.REQUEST_CLIENT_EMAIL_ADDRESS_FINISHED](state, action) {
    return {
      ...state,
      get_client_email_address: action.payload,
    };
  }
}
