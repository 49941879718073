import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import StatusModel from "../status/model/StatusModel";

// BatchEffect
export default class BatchEffect {
  // calling an API according to the status model
  // Get method for the
  static async requestBatch(params) {
    const endpoint = environment.api.batchA3;
    return EffectUtility.getToModel(StatusModel, endpoint, params);
  }

  // Get method for the status filter
  static async requestBatchFilter(params) {
    const endpoint = environment.api.batchA3;
    return EffectUtility.getToModel(StatusModel, endpoint, params);
  }
}
