import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ReportsLogoGetModel from "./models/ReportsLogoGetModel";
import ReportsLogoPostModel from "./models/ReportsLogoPostModel";
import ReportsLogoPutModel from "./models/ReportsLogoPutModel";

export default class ReportsLogoEffect {
  static async requestReportsLogo(params) {
    const endpoint = environment.api.reportLogo;
    return EffectUtility.getToModel(ReportsLogoGetModel, endpoint, params);
  }

  static async requestReportsLogoFilter(params) {
    const endpoint = environment.api.reportLogo;
    return EffectUtility.getToModel(ReportsLogoGetModel, endpoint, params);
  }

  static async requestReportsLogoPost(data) {
    const endpoint = environment.api.reportLogo + "/add";
    return EffectUtility.postToModel(ReportsLogoPostModel, endpoint, data);
  }

  static async requestReportsLogoPut(id, data) {
    const endpoint = environment.api.reportLogo + "/" + id;

    return EffectUtility.putToModel(ReportsLogoPutModel, endpoint, data);
  }
}
