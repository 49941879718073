import BaseReducer from "../../../../utilities/BaseReducer";
import promptConfigACtion from "./promptConfigACtion";

export default class PromptsReduser extends BaseReducer {
  initialState = {
    websweepXPrompts: [],
  };
  [promptConfigACtion.REQUEST_GET_PROMPT_FINISHED](state, action) {
    return {
      ...state,
      websweepXPrompts: action.payload,
    };
  }
}
