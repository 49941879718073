import BaseReducer from "../../../../utilities/BaseReducer";
import KeywordDiscoverPatternAction from "./KeywordDiscoverPatternAction";

export default class KeywordDiscoverPatterFIlterReduser extends BaseReducer {
  //initial state of KeywordConfigFilterReduser
  initialState = {
    keyword_discover_pattern_filter: [],
  };
  //KeywordDiscoverPatterFIlterReduser request action finish
  [KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      keyword_discover_pattern_filter: action.payload,
    };
  }
}
