import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { format } from "date-fns";
import { endDate, startDate } from "./dateFunctions/DateFunctions";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

const OverallSentimentMentions = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [today, setToday] = useState(new Date());

  let current_month = new Date();

  let current_month_end_date = endDate(current_month);

  //  before_twelvth_month
  let before_twelvth_month = new Date();
  before_twelvth_month.setMonth(before_twelvth_month.getMonth() - 11);

  let before_twelvth_month_start_date = startDate(before_twelvth_month);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData(
        before_twelvth_month_start_date,
        current_month_end_date
      ).then((e) => {
        let data = [];
        data.push(e);
        setData(data);
      });

      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);

        await fetchData(
          before_twelvth_month_start_date,
          current_month_end_date
        ).then((e) => {
          let data = [];
          data.push(e);
          setData(data);
        });

        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
        setToday(new Date());
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 11,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);
        await fetchData(
          new Date(firstDateString),
          new Date(endDateString)
        ).then((e) => {
          let data = [];
          data.push(e);
          setData(data);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  function returnNoOfDays(month, year) {
    return new Date(year, month, 0).getDate();
  }

  async function fetchData(from, to) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["overall_sentiment_value_across_platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });
  }

  const dateWiseData =
    data &&
    data[0] &&
    data[0].sentiment_analysis_report.overall_sentiment_value_across_platform
      .date_wise;

  const mappedData =
    dateWiseData &&
    dateWiseData.length !== 0 &&
    dateWiseData.map((date) => {
      return {
        date: date[0],
        values: date[1],
      };
    });

  function convertToDateMonthYear(dateStr) {
    const dateParts = dateStr.split("-");

    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]);

    const monthYearStr = new Date(year, month - 1).toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    });

    return monthYearStr;
  }

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let columns = [
    {
      field: "months",
      headerName: "Months",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      renderCell: (params) => (
        <p
          className={`${
            params.row.months == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row.months)}
        </p>
      ),
    },
    {
      field: "positive",
      headerName: "Positive",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.months == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row.positive)}
        </p>
      ),
    },

    {
      field: "negative",
      headerName: "Negative",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.months == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row.negative)}
        </p>
      ),
    },
    {
      field: "neutral",
      headerName: "Neutral",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.months == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row.neutral)}
        </p>
      ),
    },
    {
      field: "total",
      headerName: "Total",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      headerAlign: "center",
      align: "center",
      cellClassName: "boldRow",
    },
  ];
  let rows = [];

  if (mappedData && mappedData.length !== 0) {
    mappedData.map((item, index) => {
      rows.push({
        id: index + 1,
        months: convertToDateMonthYear(item.date),
        positive: item.values.filter((val) => {
          return val.key === "positive_sentiment";
        })[0].count,
        negative: item.values.filter((val) => {
          return val.key === "negative_sentiment";
        })[0].count,
        neutral: item.values.filter((val) => {
          return val.key === "neutral_sentiment";
        })[0].count,
        total: prefixZero_for_singleDigit(
          item.values.filter((val) => {
            return val.key === "total_count";
          })[0].count
        ),
      });
    });

    rows.push({
      id: mappedData.length + 1,
      months: "Total",
      positive: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_positive
      ),
      negative: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_negative
      ),
      neutral: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_neutral
      ),
      total: prefixZero_for_singleDigit(
        data &&
          data[0] &&
          data[0].sentiment_analysis_report
            .overall_sentiment_value_across_platform.total_mentions
      ),
    });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData?.push({
          Months: item?.months,
          Positive: prefixZero_for_singleDigit(item?.positive),
          Negative: prefixZero_for_singleDigit(item.negative),
          Neutral: prefixZero_for_singleDigit(item.neutral),
          Total: prefixZero_for_singleDigit(item.total),
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rows]);

  return (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {!loading && rows.length > 0 && props.dataGridHeight.current ? (
              <DataGridFunction
                rows={rows}
                columns={columns}
                onCellClick={onCellClick}
                height={props.dataGridHeight.current}
                rowHeight={50}
                rowBorder={false}
                rowColor={true}
                headerColor={true}
                headerHeight={80}
                initialState={{
                  pinnedColumns: { left: ["agent_name"] },
                }}
              />
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
};
export default OverallSentimentMentions;
