import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencerPlatformAction from "./InfluencerPlatformAction";

export default class InfluencerPlatformFilterReducer extends BaseReducer {
  initialState = {
    influencerPlatformAccountsFilter: [],
  };

  [InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencerPlatformAccountsFilter: action.payload,
    };
  }
}
