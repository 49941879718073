import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// CloudMailDataModel
export default class CloudMailDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  campaign_name = "";
  content_link = "";
  content_name = "";
  content_subject_line = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  error = "";
  from_name = "";
  mailing_list = "";
  modified_by = {
    user_id: "",
    user_name: "",
  };
  modified_on = "";
  organization_id = "";
  organization_name = "";
  schedule_status = "";
  scheduling_time = "";
  segment = "";
  slot = "";
  speed = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
