import { BaseModel } from "sjs-base-model";

// get model for surveyReportModel
export default class surveyReportModel extends BaseModel {
  data = [];

  constructor(data) {
    super();

    this.update(data);
  }
}
