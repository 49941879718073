import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import CloudMailModel from "./model/CloudMailModel";
import CloudMailPostModel from "./model/CloudMailPostModel";
import CloudMailPutModel from "./model/CloudMailPutModel";

// CloudMailEffect
export default class CloudMailEffect {
  // calling an API according to the Organization model
  // Get method for the
  static async requestCloudMail(params) {
    const endpoint = environment.api.cloudMail;
    return EffectUtility.getToModel(CloudMailModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestCloudMailFilter(params) {
    const endpoint = environment.api.cloudMailFilter;
    return EffectUtility.getToModel(CloudMailModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutCloudMail(data, id) {
    const endpoint = environment.api.cloudMail + "/" + id;
    return EffectUtility.putToModel(CloudMailPutModel, endpoint, data);
  }

  // post method for the Organization
  static async requestPostCloudMail(data) {
    const endpoint = environment.api.cloudMail + "/add";

    return EffectUtility.postToModel(CloudMailPostModel, endpoint, data);
  }
}
