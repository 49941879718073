import {
  Box,
  Grid,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
  Paper,
  rgbToHex,
  Popover,
  Slide,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../../../views/App";
// import "./dashboard.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { format, isValid } from "date-fns";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import DashboardAction from "../../stores/dashboard/DashboardAction";
import PriorityAction from "../../stores/priority/PriorityAction";
import StatusAction from "../../stores/status/StatusAction";
import UserAction from "../../stores/user/UserAction";
import TicketCountAction from "../../stores/ticketCount/TicketCountAction";
import TicketWorkedOnAction from "../../stores/ticketWorkedOn/TicketWorkedOnAction";
import ResolvedTicketAction from "../../stores/resolvedTicket/ResolvedTicketAction";
import TicketFirstResponseTimeAction from "../../stores/firstResponseTime/TicketFirstResponseTimeAction";
import TurnAroundTimeAction from "../../stores/turnAroundTime/TurnAroundTimeAction";
import { FullName } from "../../../components/ReusedFunctions";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import {
  prefixZero_for_singleDigit,
  returnAvailability,
  returnColor,
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../components/ticketPartials";
import MuiButton from "../../../components/muiButton/MuiButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

import DatePicker from "../../../components/dateRangePickerComponent/DateRangePicker";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    DashboardAction.REQUEST_DASHBOARD,
    PriorityAction.REQUEST_PRIORITY_FILTER,
    StatusAction.REQUEST_DASHBOARD_STATUS_FILTER,
    UserAction.REQUEST_USER_FILTER,
    TicketCountAction.REQUEST_DASHBOARD_TICKET_COUNT,
    TicketWorkedOnAction.REQUEST_TICKETWORKEDON,
    ResolvedTicketAction.REQUEST_RESOLVEDTICKET,
    TicketFirstResponseTimeAction.REQUEST_TICKETFIRSTRESPONSETIME,
  ]),
  dashboard: state.dashboard.dashboard || [],
  filterPriority: state.filterpriority.filterpriority || [],
  dashboardStatusFilter:
    state.dashboardStatusFilter.dashboardStatusFilter || [],
  userfilter: state.userfilter.userfilter || {},
  dashboardTicketCount: state.dashboardTicketCount.dashboardTicketCount || [],
  ticketWorkedOn: state.ticketWorkedOn.ticketWorkedOn || [],
  ticketResolved: state.ticketResolved.ticketResolved || [],
  ticketFirstResponseTime:
    state.ticketFirstResponseTime.ticketFirstResponseTime || [],
  ticketAvgTat: state.ticketAvgTat.ticketAvgTat || [],
});

function TicketSummaryChartView(props) {
  const navigate = useNavigate();
  const [priority, setPriority] = useState(
    localStorage.getItem("dashboard_filter_priority")
      ? JSON.parse(localStorage.getItem("dashboard_filter_priority"))
      : null
  );

  useEffect(() => {
    if (localStorage.getItem("dashboard_filter_priority")) {
      setPriority(
        JSON.parse(localStorage.getItem("dashboard_filter_priority"))
      );
    } else {
      setPriority(null);
    }
  }, [localStorage.getItem("dashboard_filter_priority")]);

  const [createdDate, setCreatedDate] = useState(null);

  const [assigned_to, setAssigned_to] = useState(
    localStorage.getItem("dashboard_filter_assigned_to")
      ? JSON.parse(localStorage.getItem("dashboard_filter_assigned_to"))
      : null
  );

  const [ticketsAllSources, setTicketAllSources] = useState(
    localStorage.getItem("dashboard_platform_filter")
      ? JSON.parse(localStorage.getItem("dashboard_platform_filter"))
      : null
  );

  useEffect(() => {
    if (localStorage.getItem("dashboard_filter_assigned_to")) {
      setAssigned_to(
        JSON.parse(localStorage.getItem("dashboard_filter_assigned_to"))
      );
    } else {
      setAssigned_to(null);
    }
  }, [localStorage.getItem("dashboard_filter_assigned_to")]);

  useEffect(() => {
    if (localStorage.getItem("dashboard_platform_filter")) {
      setTicketAllSources(
        JSON.parse(localStorage.getItem("dashboard_platform_filter"))
      );
    } else {
      setTicketAllSources(null);
    }
  }, [localStorage.getItem("dashboard_platform_filter")]);

  useEffect(() => {
    if (localStorage.getItem("dashboard_activities_filter")) {
      setMentionsActivity(
        JSON.parse(localStorage.getItem("dashboard_activities_filter"))
      );
    } else {
      setMentionsActivity(null);
    }
  }, [localStorage.getItem("dashboard_activities_filter")]);

  const [mentionsActivity, setMentionsActivity] = useState(
    localStorage.getItem("dashboard_activities_filter")
      ? JSON.parse(localStorage.getItem("dashboard_activities_filter"))
      : null
  );

  const [timeFilter, setTimeFilter] = useState(
    localStorage.getItem("dashboard_time_filter")
      ? localStorage.getItem("dashboard_time_filter")
      : null
  );

  // const [myTicketsTime, setMyTicketTime] = useState(
  //   localStorage.getItem("my_tickets_date_filter")
  //     ? JSON.parse(localStorage.getItem("my_tickets_date_filter"))
  //     : null
  // );

  let filters = useRef();

  //fetching priority list for priority dropdown
  function fetchFilterPriority() {
    const params = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    props.dispatch(PriorityAction.filterRequestPriority(params));
  }

  // ticket dispatch function for filter
  function fetchUserFilter() {
    const param = {
      page_limit: "none",
    };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  //fetch filterTicketsCount
  function fetchFilterTicketCount(params) {
    props.dispatch(DashboardAction.requestDashboard(params));
  }

  function fetchTicketCountData(params) {
    props.dispatch(TicketCountAction.requestDashboardTicketCount(params));
  }

  // ticket dispatch function for filter
  function fetchStatusFilter() {
    let param = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    props.dispatch(StatusAction.requestdashboardStatusFilter(param));
  }

  //fetching ticket WorkedOn values
  function fetchTicketworkedOn(params) {
    props.dispatch(TicketWorkedOnAction.requestTicketWorkedOn(params));
  }

  //fetching ticket resolved values
  function fetchResolvedTicket(params) {
    props.dispatch(ResolvedTicketAction.requestResolvedTicket(params));
  }

  //fetching ticket first response time
  function fetchTicketFirstResponseTime(params) {
    props.dispatch(
      TicketFirstResponseTimeAction.requestTicketFirstResponseTime(params)
    );
  }

  function fetchTicketTAT(params) {
    props.dispatch(TurnAroundTimeAction.requestTurnAroundTime(params));
  }

  const auth = localStorage.getItem("Auth");

  const authData = jwtDecode(auth);

  localStorage.setItem("userId", authData.sub);
  localStorage.setItem("userName", authData.preferred_username);

  let AssiggnedToObject = [];
  AssiggnedToObject.push({
    user_name: authData.preferred_username,
    full_name: authData.given_name + " " + authData.family_name,
  });

  const [actual_filter, set_actual_filter] = useState(
    props.DateFilter ? props.DateFilter : null
  );

  //useEffect function
  useEffect(() => {
    filters.current = [];

    fetchFilterPriority();
    fetchStatusFilter();
    fetchUserFilter();
    fetchDashboardData();
    // fetchTicketCountData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actual_filter]);

  useEffect(() => {
    set_actual_filter(props.DateFilter);
  }, [props.DateFilter]);

  useEffect(() => {
    set_actual_filter(createdDate);
  }, [createdDate]);

  //PRIORITY list assigning
  let prioritylist = [];
  let filteredPriorityList = [];

  if (props.filterPriority.hits) {
    props.filterPriority.hits.hits.forEach((value) => {
      prioritylist.push({
        ticket_priority_name: value._source.ticket_priority_name,
        color: value._source.color,
        priority_id: value._id,
      });
    });
    filteredPriorityList = [...new Set(prioritylist)];
  }

  let statusList = [];
  if (props.dashboardStatusFilter.hits) {
    props.dashboardStatusFilter.hits.hits.forEach((item) => {
      statusList.push({
        status_id: item._id,
        status_name: item._source.status_name,
      });
    });
  }

  const returnStatusName = (statusId) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusId;
      });

    return filtered_status.length > 0 ? filtered_status[0].status_name : null;
  };

  const returnStatusObject = (statusId) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusId;
      });

    return filtered_status.length > 0 && filtered_status[0];
  };

  let userlist = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((val) => {
      return userlist.push({
        user_id: val.id,
        user_name: val.username,
        // org_id: val.attributes.organization_id
        //   ? val.attributes.organization_id[0]
        //   : "",
        full_name: FullName(val),
        statusValue:
          val.attributes.user_working_status &&
          val.attributes.user_working_status[0],
      });
    });
  }

  userlist.sort((a, b) => a?.full_name.localeCompare(b.full_name));
  userlist = [...new Set(userlist)];

  // DATE value handle change
  const today = new Date();

  // ***************************************
  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);

  // start from today's date last 7 days
  const last7Days = [...Array(7).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date;
  });

  // last 30 days
  let date2 = new Date();
  let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  // ***************************************
  // last 3 months
  let date3 = new Date();
  let last3Month = date3.setMonth(date3.getMonth() - 3);

  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  //ACTIVITIES list assigning

  let MentionsActivitiesList = [];
  let filteredMentionsActivitiesList = [];

  const activitiesData = JSON.parse(localStorage.getItem("activities"));

  if (activitiesData && ticketsAllSources) {
    activitiesData.hits.hits.map((item) => {
      if (
        item._source.platform.platform_name === ticketsAllSources.platform_name
      ) {
        MentionsActivitiesList.push({
          activity_id: item._id,
          activity_name: item._source.activities,
        });
      }
    });
  }

  filteredMentionsActivitiesList = [...new Set(MentionsActivitiesList)];

  filteredMentionsActivitiesList.sort((a, b) =>
    a?.activity_name.localeCompare(b.activity_name)
  );
  filteredMentionsActivitiesList = [...new Set(filteredMentionsActivitiesList)];

  let uniqueList = [];

  props?.platformList.sort((a, b) =>
    a?.platform_alias.localeCompare(b.platform_alias)
  );
  uniqueList = [...new Set(props.platformList)];

  //Dashboard data fetch
  function fetchDashboardData() {
    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }
    if (mentionsActivity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${mentionsActivity.activity_id}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (actual_filter) {
      let dateRange = actual_filter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    if (
      priority !== null ||
      ticketsAllSources !== null ||
      mentionsActivity !== null ||
      assigned_to !== null ||
      actual_filter
    ) {
      let dashboard_main_filter = [];
      let workedupon_main_filter = [];
      let ticket_Resolved_main_filters = [];
      let ticket_first_response_time_main_filters = [];
      let ticket_avg_tat_main_filters = [];
      let ticket_count_main_filters = [];
      let agent_ticket_status_main_filters = [];

      let main_filter = [];

      if (filters.current.length !== 0) {
        dashboard_main_filter.push(filters.current);
        workedupon_main_filter.push(filters.current);
        ticket_Resolved_main_filters.push(filters.current);
        ticket_first_response_time_main_filters.push(filters.current);
        ticket_avg_tat_main_filters.push(filters.current);
        ticket_count_main_filters.push(filters.current);
        agent_ticket_status_main_filters.push(filters.current);
      }

      if (actual_filter !== null) {
        dashboard_main_filter.push(dashboard_filters);
        workedupon_main_filter.push(workedupon_filters);
        ticket_Resolved_main_filters.push(ticket_Resolved_filters);
        ticket_first_response_time_main_filters.push(
          ticket_first_response_time_filters
        );
        ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
        ticket_count_main_filters.push(ticket_count_filters);
        agent_ticket_status_main_filters.push(agent_ticket_status);
      }

      params = { filters: `[${filters.current}]` };
      param = {
        filters: `[${main_filter}]`,
      };

      dashboard_param = {
        filters: `[${dashboard_main_filter}]`,
      };

      worked_upon_param = {
        filters: `[${workedupon_main_filter}]`,
      };

      ticket_Resolved_param = {
        filters: `[${ticket_Resolved_main_filters}]`,
      };

      ticket_first_response_time_param = {
        filters: `[${ticket_first_response_time_main_filters}]`,
      };

      ticket_avg_tat_param = {
        filters: `[${ticket_avg_tat_main_filters}]`,
      };

      ticket_count_param = {
        filters: `[${ticket_count_main_filters}]`,
      };

      Object.assign(
        worked_upon_param,
        actual_filter && {
          from: actual_filter[0],
          to: actual_filter[1],
        }
      );

      Object.assign(
        dashboard_param,
        actual_filter && {
          from: actual_filter[0],
          to: actual_filter[1],
        }
      );

      Object.assign(
        ticket_first_response_time_param,
        actual_filter && {
          from: actual_filter[0],
          to: actual_filter[1],
        }
      );

      Object.assign(
        ticket_avg_tat_param,
        actual_filter && {
          from: actual_filter[0],
          to: actual_filter[1],
        }
      );

      Object.assign(
        ticket_Resolved_param,
        actual_filter && {
          from: actual_filter[0],
          to: actual_filter[1],
        }
      );
    }

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  }

  // priority callback function
  const priorityhandleChange = (event, priority) => {
    setPriority(priority);
    if (priority != null) {
      localStorage.setItem(
        "dashboard_filter_priority",
        JSON.stringify(priority)
      );
    } else {
      localStorage.removeItem("dashboard_filter_priority");
    }
    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }
    if (mentionsActivity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${mentionsActivity.activity_id}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (actual_filter) {
      let dateRange = actual_filter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${filters.current}]`,
    };

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (actual_filter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      dashboard_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  // assigned to  function
  const assignedTohandleChange = (event, assigned_to) => {
    setAssigned_to(assigned_to);
    if (assigned_to != null) {
      localStorage.setItem(
        "dashboard_filter_assigned_to",
        JSON.stringify(assigned_to)
      );
    } else {
      localStorage.removeItem("dashboard_filter_assigned_to");
    }
    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (mentionsActivity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${mentionsActivity.activity_id}"]]`
      );
    }
    if (actual_filter) {
      let dateRange = actual_filter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (actual_filter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${filters.current}]`,
    };

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      dashboard_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  //tickets allSources callbackFunction
  const platformHandleChange = (event, platform) => {
    setTicketAllSources(platform);
    setMentionsActivity(null);

    if (platform != null) {
      localStorage.setItem(
        "dashboard_platform_filter",
        JSON.stringify(platform)
      );
    } else {
      localStorage.removeItem("dashboard_platform_filter");
      localStorage.removeItem("dashboard_activities_filter");
    }

    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (platform !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${platform.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (actual_filter) {
      let dateRange = actual_filter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (actual_filter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${main_filter}]`,
    };

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      dashboard_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  // Mentions activity callBack function
  const activityHandleChange = (event, activity) => {
    setMentionsActivity(activity);
    if (activity != null) {
      localStorage.setItem(
        "dashboard_activities_filter",
        JSON.stringify(activity)
      );
    } else {
      localStorage.removeItem("dashboard_activities_filter");
    }

    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }
    if (activity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${activity.activity_id}"]]`
      );
    }
    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (actual_filter) {
      let dateRange = actual_filter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (actual_filter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${main_filter}]`,
    };

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      dashboard_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      actual_filter && {
        from: actual_filter[0],
        to: actual_filter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  // const theme = useTheme();

  const dashboardDataPerRow = 5;

  const [dashboardData, setdashboardDataPerRow] = useState(dashboardDataPerRow);

  const handleDashboardDataPerRow = () => {
    setdashboardDataPerRow(dashboardData + dashboardDataPerRow);
  };

  const handleDashboardDataShowLess = () => {
    setdashboardDataPerRow(dashboardData - dashboardDataPerRow);
  };

  let dasboard_modified_data = [];

  if (props.dashboardStatusFilter.hits && props.dashboardTicketCount.data) {
    let filtered_data = props.dashboardStatusFilter.hits.hits.forEach(
      (item) => {
        props.dashboardTicketCount.data.filter((val) => {
          if (val.key === item._id) {
            dasboard_modified_data.push(val);
          }
        });
      }
    );
  }
  dasboard_modified_data = dasboard_modified_data.map((item) => {
    // Find status name for the current item's key
    const status = statusList.find((status) => status.status_id === item.key);
    return {
      ...item,
      status_name: status ? status.status_name : "Unknown", // Add status_name or default to "Unknown"
    };
  });

  props.dashboardTicketCount &&
    props.dashboardTicketCount.data &&
    props.dashboardTicketCount.data
      .filter((val) => {
        return val.key === "direct_close";
      })
      .map((item) => {
        dasboard_modified_data.push({
          count: item?.count,
          status_name: "Ignored",
          priority: [],
        });
      });

  // Unassigned Object appent to Ticket Status list
  props.dashboardTicketCount &&
    props.dashboardTicketCount.unassigned &&
    dasboard_modified_data.push({
      count: props.dashboardTicketCount?.unassigned,
      status_name: "Unassigned",
      priority: [],
    });

  const returnTime = (time) => {
    // function getFullTimeStringFromSeconds(input_seconds) {
    //   const date = new Date(input_seconds * 1000); // Convert seconds to milliseconds
    //   const hours = date.getUTCHours().toString().padStart(2, "0");
    //   const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    //   const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    //   return `${hours}:${minutes}:${seconds}`;
    // }

    function getFullTimeStringFromHours(hours) {
      const totalHours = Math.floor(hours);
      const minutes = Math.floor((hours - totalHours) * 60);
      const seconds = Math.floor(((hours - totalHours) * 60 - minutes) * 60);
      const formattedTime = `${totalHours}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return formattedTime;
    }

    return (
      <p style={{ fontSize: "16px", margin: 0, padding: 0 }}>
        {getFullTimeStringFromHours(time)}
      </p>
    );
  };

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      setOpen(false);
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const returnOnholdTicketCount = (response) => {
    let over_all_count = 0;

    if (
      response &&
      response.data &&
      response.data.ticket_onhold_count &&
      response.data.ticket_onhold_count.buckets.length !== 0
    ) {
      response.data.ticket_onhold_count.buckets.map((val) => {
        over_all_count = over_all_count + val.doc_count;
      });
    }

    return prefixZero_for_singleDigit(over_all_count);
  };

  const returnstatuswithticketcounts = (response) => {
    return (
      <Grid>
        {response &&
          response.data &&
          response.data.ticket_onhold_count &&
          response.data.ticket_onhold_count.buckets.map((val) => {
            return (
              <p className="p-0 m-0">
                {returnStatusName(val.key)} :{" "}
                {prefixZero_for_singleDigit(val.doc_count)}
              </p>
            );
          })}
      </Grid>
    );
  };

  const [showFilters, setShowFilters] = useState(
    priority || assigned_to || ticketsAllSources || mentionsActivity
      ? true
      : false
  );

  const summaryBoxRef = useRef(null);

  // useEffect(() => {
  //   const calculateHeights = () => {
  //     const outerBoxHeight = summaryBoxRef.current.clientHeight;
  //     const firstBoxHeight = 0.1 * outerBoxHeight;
  //     const secondBoxHeight = 0.9 * outerBoxHeight;

  //     if (
  //       summaryBoxRef.current &&
  //       summaryBoxRef.current.children[1] &&
  //       summaryBoxRef.current.children[0]
  //     ) {
  //       summaryBoxRef.current.children[0].style.height = `50px`;
  //       summaryBoxRef.current.children[1].style.height = `${
  //         outerBoxHeight - 50
  //       }px`;
  //     }
  //   };

  //   calculateHeights();

  //   // Use ResizeObserver to listen for changes in the size of summaryBoxRef
  //   const resizeObserver = new ResizeObserver(calculateHeights);
  //   resizeObserver.observe(summaryBoxRef.current);

  //   // Cleanup ResizeObserver on component unmount
  //   return () => {
  //     resizeObserver.disconnect();
  //   };
  // }, []);
  const [renderKey, setRenderKey] = useState(0);

  const onHoldCount =
    props.ticketWorkedOn &&
    props.ticketWorkedOn.data &&
    props.ticketWorkedOn.data.ticket_onhold_count &&
    props.ticketWorkedOn.data.ticket_onhold_count.buckets &&
    props.ticketWorkedOn.data.ticket_onhold_count.buckets.length
      ? returnOnholdTicketCount(props.ticketWorkedOn)
      : 0;

  const resolvedCount =
    props?.ticketResolved &&
    props?.ticketResolved.data &&
    props?.ticketResolved.data.ticket_between &&
    props?.ticketResolved?.data?.ticket_between?.doc_count !== null
      ? prefixZero_for_singleDigit(
          props.ticketResolved.data.ticket_between.doc_count
        )
      : "00";

  const getOption2 = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: ["On Hold", "Resolved"],
      },
      yAxis: {
        type: "value",
      },
      confine: true,
      grid: {
        top: "15%",
        left: "5%",
        right: "5%",
        bottom: "10%",
        containLabel: true,
      },
      series: [
        {
          barWidth: "30%",
          data: [
            {
              value: onHoldCount,
              itemStyle: { color: theme.platform[`color3`] },
            },
            {
              value: resolvedCount,
              itemStyle: { color: theme.platform[`color7`] },
            },
          ],
          type: "bar",
        },
      ],
    };
  };

  const getOption1 = () => {
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 530 &&
        props.ChartContainerWidth.current > 530) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }

    const keys = [];
    dasboard_modified_data.forEach((item) => {
      keys.push(item.status_name);
    });

    const seriesData = dasboard_modified_data.map((item, index) => ({
      name: item.status_name,
      value: item.count,
      itemStyle: {
        color: theme.platform[`color${index + 1}`], // Use theme colors dynamically
      },
    }));

    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: shouldShowLegend,
        type: "scroll",
        orient: "horizontal",
        icon: "circle",
        left: "center",
        top: 0,
      },

      grid: {
        top: "15%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },

      dataZoom: [
        {
          type: "inside",
        },
      ],
      confine: true,
      series: [
        {
          type: "pie",
          radius: "70%",
          data: seriesData,
          label: {
            show: true,
            position: "outside",
            formatter: "{c}", // Show name and value
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          labelLine: {
            show: true,
            smooth: true,
            lineStyle: {
              type: "solid",
            },
          },
          emphasis: {
            focus: "series",
          },
          selectedMode: "series",
          padAngle: 0.7,
        },
      ],
    };
  };

  const createdDateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d ? e[0].$d : null;
    let date2 = e[1] && e[1].$d ? e[1].$d : null;

    const isPastDate = (date) => {
      const now = new Date();
      return date <= now;
    };

    if (
      isValid(date1) &&
      isValid(date2) &&
      isPastDate(date1) &&
      isPastDate(date2)
    ) {
      setCreatedDate([
        isValid(date1) && format(date1, "yyyy-MM-dd"),
        isValid(date2) && format(date2, "yyyy-MM-dd"),
      ]);
    }
  };

  const returnExportTime = (time) => {
    function getFullTimeStringFromHours(hours) {
      const totalHours = Math.floor(hours);
      const minutes = Math.floor((hours - totalHours) * 60);
      const seconds = Math.floor(((hours - totalHours) * 60 - minutes) * 60);
      const formattedTime = `${totalHours}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return formattedTime;
    }

    return getFullTimeStringFromHours(time);
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      dasboard_modified_data?.map((item) => {
        exportData.push({
          Status: item
            ? item.status_name
              ? item.status_name
              : returnStatusName(item?.key)
            : null,
          Count: item?.count,
        });
      });

      exportData.push("");

      exportData?.push({
        Status: "On Hold",
        ["Count"]:
          props.ticketWorkedOn &&
          props.ticketWorkedOn.data &&
          props.ticketWorkedOn.data.ticket_onhold_count &&
          props.ticketWorkedOn.data.ticket_onhold_count.buckets &&
          props.ticketWorkedOn.data.ticket_onhold_count.buckets.length
            ? returnstatuswithticketcounts(props.ticketWorkedOn)
            : "00",
      });

      exportData?.push({
        Status: "Resolved",
        ["Count"]:
          props?.ticketResolved &&
          props?.ticketResolved.data &&
          props?.ticketResolved.data.ticket_between &&
          props?.ticketResolved?.data?.ticket_between?.doc_count !== null
            ? prefixZero_for_singleDigit(
                props.ticketResolved.data.ticket_between.doc_count
              )
            : "00",
      });

      exportData?.push({
        Status: "First Response Time",
        ["Count"]: props.ticketFirstResponseTime.ticket_first_response_time
          ? props.ticketFirstResponseTime.ticket_first_response_time.value !==
            null
            ? returnExportTime(
                props.ticketFirstResponseTime.ticket_first_response_time.value
              )
            : "00:00"
          : "00:00",
      });

      exportData?.push({
        Status: "Turn Around Time",
        ["Count"]: props.ticketAvgTat.ticket_tat
          ? props.ticketAvgTat.ticket_tat.value !== null
            ? returnExportTime(props.ticketAvgTat.ticket_tat.value)
            : "00:00"
          : "00:00",
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // position: "relative",
      }}
      key={renderKey}
    >
      <LoadingIndicator
        isActive={props.isRequesting}
        style={{
          height: "100%",
        }}
      >
        <Box
          // ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box
            style={{
              // flex: "1",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              item
              xl={12}
              lg={12}
              laptop={12}
              md={12}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Grid
                item
                lg={2}
                laptop={2}
                md={12}
                sm={12}
                textAlign="left"
                alignSelf="center"
                pl={2}
              >
                <p style={{ fontSize: "20px", margin: 0, padding: 0 }}>
                  <span className="firstLetter">T</span>icket&nbsp;
                  <span className="firstLetter">S</span>ummary&nbsp;
                  <span className="firstLetter">C</span>hart
                </p>
              </Grid>

              {!showFilters && (
                <Grid
                  container
                  item
                  lg={!showFilters ? 10 : null}
                  sm={!showFilters ? 12 : null}
                  laptop={!showFilters ? 8 : null}
                  md={!showFilters ? 12 : null}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Grid item xl={0.5} lg={1} md={2} sm={3}>
                    <Box
                      sx={
                        {
                          // marginTop: "2px",
                          // padding: "4px",
                          // borderRadius: "4px",
                        }
                      }
                    >
                      <Tooltip title="Apply Filters" arrow placement="left">
                        <FilterAltIcon
                          onClick={() => {
                            setShowFilters(true);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Grid>

                  {props.detailReportOpen && (
                    <Grid item xl={0.5} lg={1} md={2} sm={3}>
                      <Box
                        sx={
                          {
                            // marginTop: "2px",
                            // padding: "4px",
                            // borderRadius: "4px",
                          }
                        }
                      >
                        <Tooltip title="Download" arrow placement="left">
                          <DownloadIcon
                            sx={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              // excelDownloadFunction();
                              props.setDownloadClicked(true);
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </Grid>
                  )}

                  <Grid item xl={0.5} lg={1} md={2} sm={3}>
                    <Box
                      sx={
                        {
                          // marginTop: "2px",
                          // padding: "4px",
                          // borderRadius: "4px",
                        }
                      }
                    >
                      {!props?.detailReportOpen ? (
                        <Tooltip title="Maximize" arrow placement="right">
                          <FullscreenIcon
                            sx={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.setDetailReportOpen(true);
                              props.setDetailReportId(props.report);
                            }}
                            fontSize="14px"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Close" arrow placement="right">
                          <CancelIcon
                            sx={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.setDetailReportOpen(false);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}

              {showFilters && (
                <Grid
                  container
                  item
                  lg={showFilters ? 10 : null}
                  sm={showFilters ? 12 : null}
                  laptop={showFilters ? 8 : null}
                  md={showFilters ? 12 : null}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Slide
                    direction="left"
                    in={showFilters}
                    mountOnEnter
                    unmountOnExit
                    style={{
                      width: "100%",
                    }}
                  >
                    <Paper>
                      <Grid
                        container
                        item
                        lg={12}
                        sm={12}
                        laptop={8}
                        md={12}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems={"center"}
                      >
                        <Grid
                          item
                          xl={0.5}
                          lg={1}
                          md={1}
                          sm={2}
                          p={1}
                          // className="bg-success"
                        >
                          <IconButton
                            onClick={() => {
                              setShowFilters(false);
                            }}
                            title="Close Filters"
                            style={{
                              minHeight: 0,
                              padding: 0,
                              color: `${theme.palette.ticketListView.headerIcon}`,
                            }}
                          >
                            <KeyboardDoubleArrowRight
                              style={{
                                width: "16px",
                                color: `${theme.palette.ticketListView.headerIcon}`,
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                          <Typography
                            title="Platform"
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <AutoCompleteComponent
                              list={uniqueList}
                              getOptionLabel={(option) =>
                                option.platform_alias
                                  ? option.platform_alias
                                  : ""
                              }
                              disablePortal
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  <Box width={20} ml={"-10px"} mr={"25px"}>
                                    {returnPlatformDropdownLogos(
                                      option.platform_name
                                    )}
                                  </Box>
                                  <span>{option.platform_alias}</span>
                                </Box>
                              )}
                              value={ticketsAllSources}
                              handleChange={platformHandleChange}
                              textFieldProps={{
                                placeholder: "Platforms",
                              }}
                              size="small"
                              width="100%"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                          <Typography
                            title="Activity"
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <AutoCompleteComponent
                              disablePortal
                              disabled={!ticketsAllSources}
                              value={mentionsActivity}
                              getOptionLabel={(option) =>
                                option.activity_name ? option.activity_name : ""
                              }
                              list={filteredMentionsActivitiesList}
                              handleChange={activityHandleChange}
                              textFieldProps={{
                                placeholder: "Activities",
                              }}
                              size="small"
                              width="100%"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                          <Typography
                            title="Priority"
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <AutoCompleteComponent
                              disablePortal
                              list={
                                filteredPriorityList ? filteredPriorityList : []
                              }
                              getOptionLabel={(option) =>
                                option.ticket_priority_name
                                  ? option.ticket_priority_name
                                  : ""
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  <Box
                                    mr={1}
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                  >
                                    {returnColor(option.color)}
                                  </Box>
                                  {option.ticket_priority_name}
                                </Box>
                              )}
                              value={priority}
                              handleChange={priorityhandleChange}
                              textFieldProps={{
                                placeholder: "Priority",
                              }}
                              size="small"
                              width="100%"
                            />
                          </Typography>
                        </Grid>
                        {props.ticketResolved &&
                          props.ticketResolved.agent === false && (
                            <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                              <Typography
                                title="Agent"
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <AutoCompleteComponent
                                  disablePortal
                                  list={userlist ? userlist : []}
                                  value={assigned_to}
                                  textFieldProps={{
                                    placeholder: "Assigned To",
                                  }}
                                  lastComponent
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.full_name === value.full_name
                                  }
                                  getOptionLabel={(option) =>
                                    option.full_name ? option.full_name : ""
                                  }
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      <Box
                                        mr={1}
                                        width={10}
                                        height={10}
                                        borderRadius="50%"
                                      >
                                        {returnAvailability(option.statusValue)}
                                      </Box>
                                      {option.full_name}
                                    </Box>
                                  )}
                                  handleChange={assignedTohandleChange}
                                  size="small"
                                  width="100%"
                                  height="50%"
                                />
                              </Typography>
                            </Grid>
                          )}
                        {
                          <Grid item xl={3} lg={3} md={3} laptop={5}>
                            <Typography
                              title="Date Range"
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <DatePicker
                                value={createdDate ? createdDate : [null, null]}
                                onChange={createdDateValueHandleChange}
                                maxDate={new Date()}
                                // onKeyDown={onKeyDown}
                                fieldheight={"35px"}
                                disableFuture
                              />
                            </Typography>
                          </Grid>
                        }

                        {props.detailReportOpen && (
                          <Grid item xl={0.5} lg={1} md={2} sm={3}>
                            <Box
                              sx={{
                                //display: "inline-block",
                                marginTop: "2px",
                                padding: "4px",
                                // border: `1px solid ${theme.palette.common.grey}`,
                                borderRadius: "4px",
                              }}
                            >
                              <Tooltip title="Download" arrow placement="left">
                                <DownloadIcon
                                  sx={{
                                    color: theme.palette.primary.main,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // excelDownloadFunction();
                                    props.setDownloadClicked(true);
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Grid>
                        )}

                        <Grid item xl={0.5} lg={1} md={2} sm={3}>
                          <Box
                            sx={{
                              //display: "inline-block",
                              marginTop: "2px",
                              padding: "4px",
                              // border: `1px solid ${theme.palette.common.grey}`,
                              borderRadius: "4px",
                            }}
                          >
                            {!props?.detailReportOpen ? (
                              <Tooltip title="Maximize" arrow placement="right">
                                <FullscreenIcon
                                  sx={{
                                    color: theme.palette.primary.main,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    props.setDetailReportOpen(true);
                                    props.setDetailReportId(props.report);
                                  }}
                                  fontSize="14px"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Close" arrow placement="right">
                                <CancelIcon
                                  sx={{
                                    color: theme.palette.primary.main,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    props.setDetailReportOpen(false);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Slide>
                </Grid>
              )}
            </Grid>
            <hr style={{ width: "100%", margin: 0 }} />
          </Box>

          {!props.isRequesting ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 1,
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // padding: 1,
                  boxSizing: "border-box",
                }}
                // className="bg-warning"
              >
                {!props.isRequesting ? (
                  <ReactEcharts
                    option={getOption1()}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                ) : (
                  <LoadingBgImage />
                )}
              </Box>
              <hr
                style={{
                  height: "100%",
                  margin: 0,
                  border: "0",
                  borderLeft: "1px solid rgba(0,0,0,.1)",
                }}
              />
              <Box
                sx={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  // padding: 1,
                  boxSizing: "border-box",
                }}
                // className="bg-info"
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <ReactEcharts
                    option={getOption2()}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                </Box>
                <hr
                  style={{
                    width: "100%",
                    border: "none",
                    borderTop: "1px solid rgba(0,0,0,.1)",
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    mt: 2,
                    paddingX: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Card
                        sx={{
                          width: "100%",
                          height: "100%",
                          bgcolor: "#e3f2fd", // Light blue color
                          border: "1px solid #90caf9", // Border color
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
                          borderRadius: "8px", // Rounded corners
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden", // Hide overflowing content
                          textOverflow: "ellipsis", // Text ellipsis
                        }}
                      >
                        <Box
                          sx={{
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            textOverflow: "hidden",
                            textOverflow: "ellipsis", // Text ellipsis
                            overflow: "hidden", // Hide overflowing text
                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis", // Add ellipsis for overflowing text
                              whiteSpace: "nowrap", // Prevent text wrapping
                              width: "100%", // Ensure it takes full width
                            }}
                          >
                            First Response Time
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 16,
                            }}
                          >
                            {props.ticketFirstResponseTime &&
                            props.ticketFirstResponseTime
                              .ticket_first_response_time
                              ? props.ticketFirstResponseTime
                                  .ticket_first_response_time.value !== null
                                ? returnTime(
                                    props.ticketFirstResponseTime
                                      .ticket_first_response_time.value
                                  )
                                : "00:00"
                              : "00:00"}
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>

                    <Grid item xs={6}>
                      <Card
                        sx={{
                          width: "100%",
                          height: "100%",
                          bgcolor: "#e3f2fd", // Light blue color
                          border: "1px solid #90caf9", // Border color
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
                          borderRadius: "8px", // Rounded corners
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden", // Hide overflowing content
                          textOverflow: "ellipsis", // Text ellipsis
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            textOverflow: "ellipsis", // Text ellipsis
                            overflow: "hidden", // Hide overflowing text
                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              mt: 0.5,
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis", // Add ellipsis for overflowing text
                              whiteSpace: "nowrap", // Prevent text wrapping
                              width: "100%", // Ensure it takes full width
                            }}
                          >
                            Turn Around Time
                          </Typography>
                          <Typography fontSize={16}>
                            {props.ticketAvgTat && props.ticketAvgTat.ticket_tat
                              ? props.ticketAvgTat.ticket_tat.value !== null
                                ? returnTime(
                                    props.ticketAvgTat.ticket_tat.value
                                  )
                                : "00:00"
                              : "00:00"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "30vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingBgImage />
            </Box>
          )}
        </Box>
      </LoadingIndicator>
    </Box>
  );
}

export default connect(mapStateToProps)(TicketSummaryChartView);
