import ActionUtility from "../../../../utilities/ActionUtility";
import AssetCollectionEffect from "./AssetCollectionEffect";

export default class AssetCollectionAction {
  // AssetCollectionAction action with an function declaration
  static REQUEST_POST_COLLECTION =
    "AssetCollectionAction.REQUEST_POST_COLLECTION";
  static REQUEST_POST_COLLECTION_FINISHED =
    "AssetCollectionAction.REQUEST_POST_COLLECTION_FINISHED";

  static REQUEST_PUT_COLLECTION =
    "AssetCollectionAction.REQUEST_PUT_COLLECTION";
  static REQUEST_PUT_COLLECTION_FINISHED =
    "AssetCollectionAction.REQUEST_PUT_COLLECTION_FINISHED";

  static REQUEST_GET_COLLECTION =
    "AssetCollectionAction.REQUEST_GET_COLLECTION";
  static REQUEST_GET_COLLECTION_FINISHED =
    "AssetCollectionAction.REQUEST_GET_COLLECTION_FINISHED";

  static REQUEST_GET_COLLECTION_FILTER =
    "AssetCollectionAction.REQUEST_GET_COLLECTION_FILTER";
  static REQUEST_GET_COLLECTION_FILTER_FINISHED =
    "AssetCollectionAction.REQUEST_GET_COLLECTION_FILTER_FINISHED";

  static REQUEST_GET_COPIES = "AssetCollectionAction.REQUEST_GET_COPIES";
  static REQUEST_GET_COPIES_FINISHED =
    "AssetCollectionAction.REQUEST_GET_COPIES_FINISHED";

  static REQUEST_POST_COPIES_TO_DATAEXTRACTION =
    "AssetCollectionAction.REQUEST_POST_COPIES_TO_DATAEXTRACTION";
  static REQUEST_POST_COPIES_TO_DATAEXTRACTION_FINISHED =
    "AssetCollectionAction.REQUEST_POST_COPIES_TO_DATAEXTRACTION_FINISHED";

  // METHODS
  // AssetCollectionAction GET function

  static requestGetCollection(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetCollectionAction.REQUEST_GET_COLLECTION,
        AssetCollectionEffect.requestGetCollection,
        params,
        callback
      );
    };
  }

  static requestGetCollectionFilter(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetCollectionAction.REQUEST_GET_COLLECTION_FILTER,
        AssetCollectionEffect.requestGetCollectionFilter,
        params,
        callback
      );
    };
  }

  //AssetCollectionAction POST
  static requestPostCollection(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetCollectionAction.REQUEST_POST_COLLECTION,
        AssetCollectionEffect.requestPostCollection,
        params,
        callback
      );
    };
  }

  static requestPostCopiesToDataExtraction(data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetCollectionAction.REQUEST_POST_COPIES_TO_DATAEXTRACTION,
        AssetCollectionEffect.requestPostCopiesToDataExtraction,
        data,
        callback
      );
    };
  }

  static requestPutCollection(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetCollectionAction.REQUEST_PUT_COLLECTION,
        AssetCollectionEffect.requestPutCollection,
        data,
        id
      );
    };
  }

  static requestGetCopies(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetCollectionAction.REQUEST_GET_COPIES,
        AssetCollectionEffect.requestGetCopies,
        params,
        callback
      );
    };
  }
}
