import { BaseModel } from "sjs-base-model";

//Youtube reply POST data model
export default class YoutubeReplyPostModel extends BaseModel {
  message = "";
  status = "";
  data = {};
  id = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
