import ActionUtility from "../../../../utilities/ActionUtility";
import ChannelAnalyticsEffect from "./ChannelAnalyticsEffect";

export default class ChannelAnalyticsAction {
  //get requests for Channel analytics
  static REQUEST_CHANNEL_ANALYTICS =
    "ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS";
  static REQUEST_CHANNEL_ANALYTICS_FINISHED =
    "ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS_FINISHED";

  //get request filtered with page limit none for export data
  static REQUEST_CHANNEL_ANALYTICS_FILTER =
    "ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS_FILTER";
  static REQUEST_CHANNEL_ANALYTICS_FILTER_FINISHED =
    "ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS_FILTER_FINISHED";

  //METHODS

  //GET method
  static requestChannelAnalytics(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS,
        ChannelAnalyticsEffect.requestChannelAnalytics,
        params
      );
    };
  }

  //Filtered get method
  static requestFilteredChannelAnalytics(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS_FILTER,
        ChannelAnalyticsEffect.requestFilteredChannelAnalytics,
        params
      );
    };
  }
}
