import ActionUtility from "../../../utilities/ActionUtility";
import PlatformAccountsEffect from "./PlatformAccountsEffect";

export default class PlatformAccountsAction {
  //pltform Account action get requests
  static REQUEST_PLATFORM_ACCOUNT =
    "PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT";
  static REQUEST_PLATFORM_ACCOUNT_FINISHED =
    "PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FINISHED";

  //pltform Account action get requests filter with page limit none
  static REQUEST_PLATFORM_ACCOUNT_FILTER =
    "PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FILTER";
  static REQUEST_PLATFORM_ACCOUNT_FILTER_FINISHED =
    "PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FILTER_FINISHED";

  //pltform Account action get requests
  static REQUEST_TELEGRAM_PLATFORM_ACCOUNT =
    "PlatformAccountsAction.REQUEST_TELEGRAM_PLATFORM_ACCOUNT";
  static REQUEST_TELEGRAM_PLATFORM_ACCOUNT_FINISHED =
    "PlatformAccountsAction.REQUEST_TELEGRAM_PLATFORM_ACCOUNT_FINISHED";

  //Methods
  //platform accounts get method
  static requestPlatformAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT,
        PlatformAccountsEffect.requestPlatformAccount,
        params
      );
    };
  }

  static requestTelegramPlatformAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformAccountsAction.REQUEST_TELEGRAM_PLATFORM_ACCOUNT,
        PlatformAccountsEffect.requestTelegramPlatformAccount,
        params
      );
    };
  }

  //get Method of platform accounts for filter
  static requestPlatformAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FILTER,
        PlatformAccountsEffect.requestPlatformAccountFilter,
        params
      );
    };
  }
}
