import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import environment from "environment";
import { useLocation } from "react-router-dom";

function RedditCallback(props) {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let query = JSON.parse(sessionStorage.getItem("redditcallback"));

    if (query) {
      const urlParams = new URLSearchParams(query?.searchQuery);
      const codeValue = urlParams.get("code");

      let token;
      if (localStorage.getItem("Auth") !== null) {
        token = JSON.parse(localStorage.getItem("Auth")).access_token;
      }

      const url = `${environment.api.baseApi}/request_reddit_access_token?code=${codeValue}`;

      axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          navigate("/addAccount");
        })
        .catch((error) => {
          alert(error.response.data.message);
          navigate("/addAccount");
        });
    }
  }, []);
}

export default RedditCallback;
