import styles from "./LoadingIndicator.module.scss";

import React from "react";
import classNames from "classnames";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// loading
function CircularLoadingIndicator(props) {
  const { children, isActive, className } = props;
  const cssClasses = classNames(className, {
    [styles.wrapper]: isActive,
  });

  return (
    <div className={cssClasses}>
      {isActive && (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      )}
      {children}
    </div>
  );
}

export default CircularLoadingIndicator;
