import BaseReducer from "../../../utilities/BaseReducer";
import EmailAccountAction from "./EmailAccountAction";

// EmailAccountReduser  function
export default class EmailAccountReduser extends BaseReducer {
  initialState = {
    emailAccount: [],
  };
  [EmailAccountAction.REQUEST_EMAIL_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      emailAccount: action.payload,
    };
  }
}
