import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import TicketCountModel from "./models/TicketCountModel";

export default class TicketCountEffect {
  //get ticket count request end point
  static async requestTicketCount(params) {
    const endpoint = environment.api.ticketCount;

    return EffectUtility.getToModel(TicketCountModel, endpoint, params);
  }

  static async requestDashboardTicketCount(params) {
    const endpoint = environment.api.ticketCount;

    return EffectUtility.getToModel(TicketCountModel, endpoint, params);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
