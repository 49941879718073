import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencersCampaignsAction from "./InfluencersCampaignsAction";

// InfluencersCampaignReduser
export default class InfluencersCampaignReduser extends BaseReducer {
  initialState = {
    influencersCampaign: [],
  };
  [InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencersCampaign: action.payload,
    };
  }
}
