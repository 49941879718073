import ActionUtility from "../../../../utilities/ActionUtility";
import MigrateTracksEffect from "./MigrateTracksEffect";

export default class MigrateTracksAction {
  // MigrateTracksAction action with an function declaration
  static REQUEST_POST_MIGRATESPOTIFY =
    "MigrateTracksAction.REQUEST_POST_MIGRATESPOTIFY";
  static REQUEST_POST_MIGRATESPOTIFY_FINISHED =
    "MigrateTracksAction.REQUEST_POST_MIGRATESPOTIFY_FINISHED";

  static REQUEST_POST_MIGRATEAPPLE =
    "MigrateTracksAction.REQUEST_POST_MIGRATEAPPLE";
  static REQUEST_POST_MIGRATEAPPLE_FINISHED =
    "MigrateTracksAction.REQUEST_POST_MIGRATEAPPLE_FINISHED";

  // METHODS
  // labels GET function
  static requestPostSpotifyMigrateTracks(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MigrateTracksAction.REQUEST_POST_MIGRATESPOTIFY,
        MigrateTracksEffect.requestPostSpotifyMigrateTracks,
        params,
        callback
      );
    };
  }

  static requestPostAppleMigrateTracks(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        MigrateTracksAction.REQUEST_POST_MIGRATEAPPLE,
        MigrateTracksEffect.requestPostAppleMigrateTracks,
        params,
        callback
      );
    };
  }
}
