import { BaseModel } from "sjs-base-model";
import EmailAccountDataModel from "./EmailAccountDataModel";

// Email Account data hits Model function
export default class EmailAccountDataHitsModel extends BaseModel {
  
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [EmailAccountDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
