import { BaseModel } from "sjs-base-model";
import TicketsHitsDataModel from "./TicketsHitsDataModel";

export default class TicketsHitsModel extends BaseModel {
  //ticket list hits model
  hits = [TicketsHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
