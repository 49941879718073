import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencersCampaignsAction from "./InfluencersCampaignsAction";

export default class influencersCampaignsFilterReduser extends BaseReducer {
  //initial state of OrganizationFilterReduser
  initialState = {
    influencersCampaignFilter: [],
  };
  //influencersCampaignsFilterReduser request action finish
  [InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencersCampaignFilter: action.payload,
    };
  }
}
