import BaseReducer from "../../../../utilities/BaseReducer";
import NvProjectAction from "./NvProjectAction";

export default class NvProjectReduser extends BaseReducer {
  initialState = {
    websweepNvProject: [],
  };
  [NvProjectAction.REQUEST_GET_NV_PROJECT_FINISHED](state, action) {
    return {
      ...state,
      websweepNvProject: action.payload,
    };
  }
}
