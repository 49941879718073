import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TwitterDashboardModel from "./TwitterDashboardModel";

//Category effect
export default class TwitterDashboardEffect {
  // calling an API according to the twitterDashboard Model
  static async requestTwitterDashboard(params) {
    const endpoint = environment.api.twitterDashboard;
    return EffectUtility.getToModel(TwitterDashboardModel, endpoint, params);
  }
}
