import environment from "./base";

/*
 * base.ts is the default environment for production.
 * You shouldn't have override anything.
 */
const baseApi = "https://operatorapi.a3labels.in";
const influencersAPI = "";
const surveyApi = "";
const dataExtractionApi = "";
const siteDiscovery = "";
const mcnApi = "";
const cloudMailApi = "";
const competitionalAnalyticsApi = "";
const openAiApi = "";
const socketApi = "clientnotifi.a3labels.in";
const schedulerapi = "";
const translateApi = "";
const telegramApi = "";
const a3labelApi = "https://workspaceapi.a3labels.in";
const a3TunesApi = "https://a3labels.in/quicklinks";
const crmApi = "";
const youtubeMonitorApi = "";
const fingerPrintApi = "";
const sendmailApi = "";
const linkCheckingApi = "";
const aiSuggestionsApi = "";
const dsrApi = "";
const influencerSchedulerApi = "";
const websweepxApi = "";
const a3mailserviceApi = "https://a3labelmailservice.clikbite.com";

const env = environment({
  baseApi,
  influencersAPI,
  surveyApi,
  dataExtractionApi,
  siteDiscovery,
  mcnApi,
  cloudMailApi,
  competitionalAnalyticsApi,
  openAiApi,
  socketApi,
  schedulerapi,
  translateApi,
  telegramApi,
  a3labelApi,
  crmApi,
  youtubeMonitorApi,
  fingerPrintApi,
  a3TunesApi,
  sendmailApi,
  linkCheckingApi,
  aiSuggestionsApi,
  dsrApi,
  influencerSchedulerApi,
  websweepxApi,
  a3mailserviceApi
});

const productionEnv = {
  ...env,
  // override anything that gets added from base.
};

export default productionEnv;
