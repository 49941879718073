import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
// Pagination component
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import environment from "environment";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import AmbitionBoxFeedsAction from "../../../../stores/ambitionBoxFeeds/AmbitionBoxFeedsAction";
import { axiosRequest } from "../../../../utilities/FetchRequest";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED,
    AmbitionBoxFeedsAction.REQUEST_AMBITION_FEED_FILTER,
  ]),
  ambitionBoxFeeds: state.ambitionBoxFeeds.ambitionBoxFeeds || [],
  pagination: state.ambitionBoxFeeds.ambitionBoxFeeds || {},
  ambitionBoxFeedsFilter:
    state.ambitionBoxFeedsFilter.ambitionBoxFeedsFilter || [],
});

function AmbitionBoxListView(props) {
  // useState Functions
  const [date, setDate] = useState([null, null]);
  const [value, setValue] = React.useState("1");
  const [rating_val, setRating_val] = useState([]);
  const [page_limit] = useState(10);
  const [accountName, setAccountName] = useState("");
  const Ratings = ["1.0", "2.0", "3.0", "4.0", "5.0"];
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // importing useStyes hook
  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  //tab handle change
  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ************************************************* Dispatch functions

  function fetchAmbitionBoxFeeds() {
    const params = {
      page_limit: page_limit,
      order_by: '[["posted_on", "desc"]]',
    };
    props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(params));
  }

  function fetchAmbitionBoxFeedsFilter() {
    const params = {
      page_limit: "none",
      order_by: '[["posted_on", "desc"]]',
    };
    props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeedFilter(params));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //   UseEffect function
  useEffect(() => {
    fetchAmbitionBoxFeeds();
    fetchAmbitionBoxFeedsFilter();
  }, []);

  // applyFilter Function

  const applyFilter = () => {
    let params = {};
    const filter = [];

    if (accountName !== "") {
      filter.push(
        `["account_name.keyword","must","","match","${accountName}"]`
      );
    }
    if (rating_val.length !== 0) {
      filter.push(`["rating.keyword","must","","terms",[${rating_val}]]`);
    }

    if (sentimental_val !== "") {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value.keyword","must","","wildcard","*-*"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(
          `["sentiment_value.keyword","must_not","","wildcard","*-*"]`
        );
        filter.push(`["sentiment_value.keyword","must_not","","term","0.0"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value.keyword","must","","term","0.0"]`);
      }
    }

    if (
      accountName !== "" ||
      rating_val.length !== 0 ||
      sentimental_val !== ""
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["posted_on", "desc"]]',
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(params));
    } else {
      alert("Please add filters");
      params = {
        order_by: '[["posted_on", "desc"]]',
        page_limit: `${page_limit}`,
      };
      props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(params));
    }
  };

  //  accountNameHandleChange
  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName("");
    }
  };
  const RatingHandlechange = (event, value) => {
    if (value != null) {
      setRating_val(value);
    } else {
      setRating_val([]);
    }
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val("");
    }
  };

  // ************************************* Filter List
  // Integrating Costomer name to list
  const list = [];
  if (props.ambitionBoxFeedsFilter.hits) {
    props.ambitionBoxFeedsFilter.hits.hits.length >= 0 &&
      props.ambitionBoxFeedsFilter.hits.hits.map((item) => {
        list.push(item._source.account_name);
      });
  }
  const uniqueList = [...new Set(list)];

  // Clear method
  const ClearState = () => {
    setFilterClicked(false);
    if (
      rating_val.length !== 0 ||
      accountName !== "" ||
      sentimental_val !== ""
    ) {
      setRating_val([]);
      setAccountName("");
      setSentimental_val("");
      setClearLoader(true);

      if (navigator.onLine === true) {
        const params = {
          order_by: '[["posted_on", "desc"]]',
          page_limit: `${page_limit}`,
        };
        FilterClicked &&
          props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(params));
      } else {
        alert("No internet connection");
      }
    }
  };

  // pagination
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (accountName !== "") {
      filter.push(
        `["account_name.keyword","must","","match","${accountName}"]`
      );
    }
    if (rating_val.length !== 0) {
      filter.push(`["rating.keyword","must","","terms",[${rating_val}]]`);
    }

    if (sentimental_val !== "") {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value.keyword","must","","wildcard","*-*"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(
          `["sentiment_value.keyword","must_not","","wildcard","*-*"]`
        );
        filter.push(`["sentiment_value.keyword","must_not","","term","0.0"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value.keyword","must","","term","0.0"]`);
      }
    }

    if (FilterClicked) {
      if (
        accountName !== "" ||
        rating_val.length !== 0 ||
        sentimental_val !== ""
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: '[["posted_on", "desc"]]',

          // posted_on: "desc",
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["posted_on", "desc"]]',
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(params));
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openTicketFunction = (ticket_id) => {
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
    set_circuler_pogress_id(ticket_id);

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);
    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number"
    ) {
      if (item._source.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item._source.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item._source.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }
    return sentiment;
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}
      <Grid container>
        {/*Twitter filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            // borderRadius: theme.palette.buttonComponent.borderRadius.large,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            mt={2}
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Account Name</p>
                </Grid>
                <AutoCompleteComponent
                  // ***********************************************************Rating Filter
                  value={accountName}
                  list={uniqueList}
                  textFieldProps={{
                    placeholder: "Account Name",
                  }}
                  handleChange={accountNameHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Ratings</p>
                </Grid>

                <Grid style={{ position: "relative" }}>
                  <Grid
                    item
                    xl={12}
                    style={{
                      position: "absolute",
                      width: "95%",
                      zIndex: 999,
                    }}
                  >
                    <AutoCompleteComponent
                      // ***************************************************Customer name filter
                      value={rating_val}
                      list={Ratings}
                      textFieldProps={{
                        placeholder: rating_val.length > 0 ? null : "Ratings",
                      }}
                      handleChange={RatingHandlechange}
                      size="small"
                      width="100%"
                      multiple={true}
                      limitTags={1}
                      disableCloseOnSelect={true}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentimental Value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={6} lg={3} md={12} sm={12} mt={0}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={2.5}
                >
                  <Grid item xl={3} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid item xl={3} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <h5 className="pl-3 pr-3 pt-2"></h5> */}

        {/* <Divider sx={{ borderColor: theme.palette.background.button }} /> */}
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
      </Grid>
      {/* ****************************************************** Divider */}

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
          // className="bg-primary"
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Reviews</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid>

          {props.ambitionBoxFeeds.hits ? (
            <LoadingIndicator isActive={isRequesting}>
              {props.ambitionBoxFeeds.hits.hits.length !== 0 ? (
                props.ambitionBoxFeeds.hits.hits.map(
                  (item, index) =>
                    !isRequesting && (
                      <Box
                        sx={{
                          padding: 0,
                          margin: "1.2%",
                          marginTop: 0,
                          marginBottom: "0.5%",
                          borderRadius: theme.borderRadius,
                        }}
                        // className="bg-warning"
                      >
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          // onChange={handleChangeExpand(`panel${index}`)}
                          style={{
                            backgroundColor: "#fff",
                            height: "auto",
                            padding: 2,
                            borderRadius: theme.borderRadius,
                            border: "solid 0.5px #d3e2e6",
                            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                            cursor:
                              item && item._source.ticket_id
                                ? "pointer"
                                : "default",
                          }}
                        >
                          <AccordionSummary
                            style={{
                              cursor:
                                item && item._source.ticket_id
                                  ? "pointer"
                                  : "default",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleChangeExpand(`panel${index}`)
                                }
                              />
                            }
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                          >
                            <List
                              sx={{
                                width: "100%",
                                cursor:
                                  item && item._source.ticket_id
                                    ? "pointer"
                                    : null,
                              }}
                              onClick={() => {
                                localStorage.setItem("activeTab", 0);

                                item._source &&
                                  item._source.ticket_id &&
                                  openTicketFunction(item._source.ticket_id);
                              }}
                              // component="nav"
                            >
                              <Grid
                                container
                                // className="bg-warning"
                              >
                                <Grid
                                  container
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  // className="bg-warning"
                                >
                                  <Grid item lg={11} md={9} sm={9} container>
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      container
                                    >
                                      <Grid
                                        item
                                        xl={12}
                                        lg={6}
                                        md={12}
                                        sm={12}
                                        // className="bg-warning"
                                      >
                                        <ListItem>
                                          <h6 style={{ marginRight: "20px" }}>
                                            {item._source.customer_name}{" "}
                                          </h6>
                                          <p>
                                            {" "}
                                            {(function createdDate() {
                                              let created =
                                                item._source.posted_on;
                                              let dateConversion = new Date(
                                                created
                                              );
                                              dateConversion =
                                                dateConversion.toDateString() +
                                                ", " +
                                                dateConversion.toLocaleTimeString(
                                                  "en-US",
                                                  {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                  }
                                                );

                                              return dateConversion;
                                            })()}
                                          </p>
                                        </ListItem>
                                      </Grid>
                                      <Grid item lg={12} md={12} sm={12}>
                                        <ListItem>
                                          <Typography>
                                            <b
                                              style={{
                                                fontSize:
                                                  theme.palette.buttonComponent
                                                    .fontSize.extraLarge,
                                                lineHeight: "22px",
                                              }}
                                            >
                                              Company name :
                                            </b>
                                            &nbsp;
                                            {item._source.account_name}
                                          </Typography>
                                        </ListItem>
                                      </Grid>
                                      <Grid item xl={4} lg={4} md={12} sm={12}>
                                        <ListItem>
                                          <h6>Designation :</h6>&nbsp;
                                          {item._source.designation}
                                        </ListItem>
                                      </Grid>
                                      <Grid item xl={4} lg={4} md={12} sm={12}>
                                        <ListItem>
                                          <h6>Rating :</h6>&nbsp;
                                          {item._source.rating}
                                        </ListItem>
                                      </Grid>
                                      <Grid item xl={4} lg={4} md={12} sm={12}>
                                        <ListItem>
                                          <h6>Sentiment Value :</h6>&nbsp;
                                          {item._source.sentiment_value &&
                                          typeof item._source
                                            .sentiment_value === "number" &&
                                          item._source.sentiment_value > 0 ? (
                                            <IconButton
                                              className="p-0 m-0"
                                              title={returnSentimentname(item)}
                                            >
                                              <SentimentSatisfiedAltRoundedIcon
                                                titleAccess="Positive"
                                                style={{
                                                  width: "16px",
                                                  color: `green`,
                                                }}
                                              />
                                            </IconButton>
                                          ) : item._source.sentiment_value <
                                            0 ? (
                                            <IconButton
                                              className="p-0 m-0"
                                              title={returnSentimentname(item)}
                                            >
                                              <SentimentDissatisfiedRoundedIcon
                                                titleAccess="Negative"
                                                style={{
                                                  width: "16px",
                                                  color: `${theme.palette.sentimentIconColor.negative}`,
                                                }}
                                              />
                                            </IconButton>
                                          ) : item._source.sentiment_value !==
                                            null ? (
                                            <IconButton
                                              className="p-0 m-0"
                                              title={returnSentimentname(item)}
                                            >
                                              <SentimentNeutralRoundedIcon
                                                titleAccess="Neutral"
                                                style={{
                                                  width: "16px",
                                                  color: `${theme.palette.sentimentIconColor.neutral}`,
                                                }}
                                              />
                                            </IconButton>
                                          ) : (
                                            ""
                                          )}
                                        </ListItem>
                                      </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12}>
                                      <ListItem>
                                        <Typography>
                                          <b
                                            style={{
                                              fontSize:
                                                theme.palette.buttonComponent
                                                  .fontSize.extraLarge,
                                              lineHeight: "22px",
                                            }}
                                          >
                                            Title :
                                          </b>
                                          &nbsp;
                                          {item._source.title}
                                        </Typography>
                                      </ListItem>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    container
                                    lg={1}
                                    md={2}
                                    sm={2}
                                    alignItems={"flex-start"}
                                    justifyContent="space-between"
                                  >
                                    {item._source.feed_link && (
                                      <Grid item lg={1} xl={1} md={1} sm={1}>
                                        <Typography
                                          component={"a"}
                                          href={
                                            item._source.feed_link
                                              ? item._source.feed_link
                                              : ""
                                          }
                                          target="_blank"
                                          title="Feed Link"
                                        >
                                          <OpenInNewIcon
                                            fontSize="25px"
                                            sx={{ cursor: "pointer" }}
                                          />
                                        </Typography>
                                      </Grid>
                                    )}

                                    {circuler_progress_id ===
                                      item._source.ticket_id &&
                                      open_ticket && (
                                        <CircularProgress
                                          color="inherit"
                                          size={15}
                                          // className="bg-light"
                                        />
                                      )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </List>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <Box
                                sx={{
                                  padding: 2,
                                  marginTop: 0,
                                  marginBottom: "0.5%",
                                  border: "solid 0.5px #d3e2e6",
                                  borderRadius: theme.borderRadius,
                                  boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                }}
                              >
                                <p className="p-0 m-0 mb-1">
                                  <span
                                    className="font-weight-bold"
                                    style={{ fontSize: 16 }}
                                  >
                                    {" "}
                                    pros :
                                  </span>{" "}
                                  {item._source.pros}
                                </p>
                                <p>
                                  <span
                                    className="font-weight-bold"
                                    style={{ fontSize: 16 }}
                                  >
                                    {" "}
                                    cons :
                                  </span>{" "}
                                  {item._source.cons}
                                </p>
                              </Box>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                )
              ) : (
                <NoDataFound />
              )}
            </LoadingIndicator>
          ) : null}
        </Box>
      </Grid>
      <Box position="relative">
        {props.ambitionBoxFeeds.hits
          ? props.ambitionBoxFeeds.hits.hits.length !== 0 &&
            !isRequesting && (
              <PaginationUI
                pagelength={props.pagination.total_page_no}
                page={props.pagination.current_page_no}
                handleChange={handleChangePagination}
              />
            )
          : null}
      </Box>
    </Box>
  );
}

export default connect(mapStateToProps)(AmbitionBoxListView);
