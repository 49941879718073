import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import AssetsModel from "./model/AssetsModel";
import AssetsPostModel from "./model/AssetsPostModel";
import AssetsBulkPutModel from "./model/AssetsBulkPutModel";
import AssetsPutModel from "./model/AssetsPutModel";
import AssetsDeleteModel from "./model/AssetsDeleteModel";

// AssetsEffect
export default class AssetsEffect {
  // calling an API according to the assets model
  // Get method for the
  static async requestAssets(params) {
    const endpoint = environment.api.assets;
    return EffectUtility.getToModel(AssetsModel, endpoint, params);
  }

  static async requestAssetsClear() {
    return {};
  }

  static async requestAssetsFilterClear() {
    return {};
  }

  // Get method for the assets filter
  static async requestAssetsFilter(params) {
    const endpoint = environment.api.assets;
    return EffectUtility.getToModel(AssetsModel, endpoint, params);
  }

  // put method for the assets
  static async requestPutAssets(data, id) {
    const endpoint = environment.api.assets + "/" + id;
    return EffectUtility.putToModel(AssetsPutModel, endpoint, data);
  }

  // post method for the assets
  static async requestPostAssets(data, callBack) {
    const endpoint = environment.api.assets + "/add";
    const response = EffectUtility.postToModel(AssetsPostModel, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // post method for the assets
  static async requestPutAssetsBulkUpdate(data) {
    const endpoint = environment.api.assetsUpdate;
    return EffectUtility.putToModel(AssetsBulkPutModel, endpoint, data);
  }

  static async requestAssetsDelete(id, callback) {
    const endpoint = environment.api.assets + "/" + id;
    let response = EffectUtility.deleteToModel(AssetsDeleteModel, endpoint);

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }
}
