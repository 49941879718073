import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// twitter api fetch model
export default class ConsumerDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  company_reply = v4();
  created_by = {};
  created_on = "";
  customer_name = "";
  description = "";
  location = "";
  modified_on = "";
  notified = "";
  posted_by = 0;
  project = {};
  sentiment_value = "";
  title = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
