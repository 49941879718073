import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import CampaignGetModel from "./model/CampaignGetModel";
import CampainSchduledeleteModel from "./model/CampainSchduledeleteModel";
import CampainSchedulePostModel from "./model/CampainSchedulePostModel";

// CampaignMonitoringEffect
export default class CampaignMonitoringEffect {
  // calling an API according to the CampaignMonitoring model
  // Get method for the
  static async requestCampainMonitoring(params) {
    const endpoint = environment.api.campaignList;
    return EffectUtility.getToModel(CampaignGetModel, endpoint, params);
  }

  // Get method for the CampaignMonitoring filter
  static async requestCampainMonitoringFilter(params) {
    const endpoint = environment.api.campaignList;
    return EffectUtility.getToModel(CampaignGetModel, endpoint, params);
  }

  // put method for the CampaignMonitoring
  static async requestPutCampainMonitoring(data, id) {
    const endpoint = environment.api.campaignSchedule + "/" + id;
    return EffectUtility.putToModel(CampainSchedulePostModel, endpoint, data);
  }

  // post method for the CampaignMonitoring
  static async requestPostCampainMonitoring(data) {
    const endpoint = environment.api.campaignSchedule;

    return EffectUtility.postToModel(CampainSchedulePostModel, endpoint, data);
  }

  static async requestDeleteAmbitionBoxAccount(id) {
    const endpoint = environment.api.campaignSchedule + "/" + id;

    return EffectUtility.deleteToModel(CampainSchduledeleteModel, endpoint);
  }
}
