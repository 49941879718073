import { BaseModel } from "sjs-base-model";

export default class SetUserGetModel extends BaseModel {
  // status = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
