import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import FingerPrintAssetGetModel from "./models/FingerPrintAssetGetModel";
import FingerPrintAssetPostModel from "./models/FingerPrintAssetPostModel";
import FingerPrintAssetByIdModel from "./models/FingerPrintGetAssetByIdModel";
import FingerPrintAssetTrackingPutModel from "./models/FingerPrintAssetTrackingPutModel";
import FingerPrintAssetSyncModel from "./models/FingerPrintAssetSyncModel";

export default class FingerPrintAssetEffect {
  static async requestGetAssets(params, callback) {
    const endpoint = environment.api.fingerPrintAssets;
    let response = EffectUtility.getToModel(
      FingerPrintAssetGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPutAssets(data, id) {
    const endpoint = environment.api.fingerPrintAssets + "/" + id;
    return EffectUtility.putToModel(FingerPrintAssetPostModel, endpoint, data);
  }

  static async requestGetAssetSyncedCopies(id, params, callBack) {
    const endpoint = environment.api.fingerPrintAssetSuncedCopies + "/" + id;
    let response = EffectUtility.getToModel(
      FingerPrintAssetSyncModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetAssetsFilter(params, callback) {
    const endpoint = environment.api.fingerPrintAssets;
    let response = EffectUtility.getToModel(
      FingerPrintAssetGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetAssetById(id, params, callBack) {
    const endpoint = environment.api.fingerPrintAssets + "/" + id;
    let response = EffectUtility.getToModel(
      FingerPrintAssetByIdModel,
      endpoint
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestGetAssetByIdClear() {
    return {};
  }

  static async requestPostAsset(data, params, callback) {
    const endpoint = environment.api.fingerPrintAsset + "/add";
    let response = EffectUtility.postToModel(
      FingerPrintAssetPostModel,
      endpoint,
      data,
      params
    );
    return response;
  }

  static async requestPutAssetTrackingStatus(data, id, params, callback) {
    const endpoint = environment.api.fingerPrintAssetTrackingStatus + "/" + id;
    let response = EffectUtility.putToModel(
      FingerPrintAssetTrackingPutModel,
      endpoint,
      data,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }
}
