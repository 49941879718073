import React, { useEffect, useState } from "react";
import environment from "environment";
import CategoryWiseSentimentsChart from "./charts/CategoryWiseSentimentsChart";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const CategoryWiseSentiments = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData();
      setLoading(false);
    })();
  }, []);

  async function fetchData(month) {
    let API = `${environment.api.sentimentalAnalysisReports}?report_type=["count"]&reports=["sentiment_analysis_report_custom_value"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${environment.api.sentimentalAnalysisReports}?report_type=["count"]&reports=["sentiment_analysis_report_custom_value"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setData(response);
    //   });
  }

  let chartLabels = [];
  let positiveData = [];
  let negativeData = [];
  let tableList = [];

  data &&
    data.sentiment_analysis_report &&
    data.sentiment_analysis_report.sentiment_analysis_report_custom_value &&
    data.sentiment_analysis_report.sentiment_analysis_report_custom_value
      .filter((item) => Object.keys(item)[0] === props.filterValue)
      .map((value, index) => {
        value[Object.keys(value)[0]].map((i) => {
          return chartLabels.push(Object.keys(i)[0]);
        });

        value[Object.keys(value)[0]].map((item) => {
          tableList.push({
            name: Object.keys(item)[0],
            positive_sentiment: item[Object.keys(item)]
              .filter((item) => item.key === "positive_sentiment")
              .map((v) => {
                return v.count;
              }),
            negative_sentiment: item[Object.keys(item)]
              .filter((item) => item.key === "negative_sentiment")
              .map((v) => {
                return v.count;
              }),
          });
        });

        value[Object.keys(value)[0]].map((i) => {
          i[Object.keys(i)]
            .filter((item) => item.key === "positive_sentiment")
            .map((value) => positiveData.push(value.count));
        });

        value[Object.keys(value)[0]].map((i) => {
          i[Object.keys(i)]
            .filter((item) => item.key === "negative_sentiment")
            .map((value) => negativeData.push(value.count));
        });
      });

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          sx={{
            width: "100%",
            paddingX: 5,
          }}
        >
          <div style={{ width: "100%", overflowX: "auto" }}>
            {tableList.length > 0 && (
              <table
                style={{
                  width: "100%",
                  border: `1px solid ${theme.table.border}`,
                  textAlign: "left",
                }}
                border="2"
              >
                <tbody style={{ width: "100%" }}>
                  <tr
                    style={{
                      border: `1px solid ${theme.table.border}`,
                      color: `${theme.palette.text.primary}`,
                      backgroundColor: `${theme.palette.background.primary}`,
                    }}
                  >
                    <th className="pl-5 pr-5">Category Name</th>
                    <th className="pl-5 pr-5">Negative Count</th>
                    <th className="pl-5 pr-5">Positive Count</th>
                  </tr>

                  {tableList &&
                    tableList.map((item, index) => (
                      <tr
                        style={{
                          backgroundColor:
                            index % 2 === 0
                              ? `${theme.table.rowColor}`
                              : "white",
                          color: `${theme.table.rowText}`,
                          border: `1px solid ${theme.table.border}`,
                        }}
                      >
                        <td className="pl-5">{item.name ? item.name : "-"}</td>
                        <td className="pl-5">
                          {item.negative_sentiment &&
                            item.negative_sentiment[0]}
                        </td>
                        <td className="pl-5">
                          {item.positive_sentiment &&
                            item.positive_sentiment[0]}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}

      {/* <div style={{ justifyContent: "center", display: "flex" }}>
        <div style={{ width: "60%" }}>
          {chartLabels.length > 0 && (
            <CategoryWiseSentimentsChart list={tableList} />
          )}
        </div>
      </div> */}
    </LoadingIndicator>
  );
};

export default CategoryWiseSentiments;
