import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import WhatsappAccountModel from "./model/WhatsappAccountModel";
import WhatsappAccountPostModel from "./model/WhatsappAccountPostModel";
import WhatsappAccountPutModel from "./model/WhatsappAccountPutModel";
import WhatsappDeleteModel from "./model/WhatsappDeleteModel";

// WhatsappAccountEffect
export default class WhatsappAccountEffect {
  // calling an API according to the Whatsapp account model
  // Get method for the Whatsapp account
  static async requestWhatsappAccount(params) {
    const endpoint = environment.api.whatsappAccount;
    return EffectUtility.getToModel(WhatsappAccountModel, endpoint, params);
  }

  // put method for the Whatsapp account
  static async requestPutWhatsappAccount(data, id) {
    const endpoint = environment.api.whatsappAccount + "/" + id;
    return EffectUtility.putToModel(WhatsappAccountPutModel, endpoint, data);
  }

  // post method for the Whatsapp account
  static async requestPostWhatsappAccount(data) {
    const endpoint = environment.api.whatsappAccount + "/add";

    return EffectUtility.postToModel(WhatsappAccountPostModel, endpoint, data);
  }

  // Delete API
  static async requestDeleteWhatsappAccount(id) {
    const endpoint = environment.api.whatsappAccount + "/" + id;
    return EffectUtility.deleteToModel(WhatsappDeleteModel, endpoint);
  }
}
