import BaseReducer from "../../../../utilities/BaseReducer";
import ReplyActions from "./ReplyActions";

export default class ReplyReducer extends BaseReducer {
  //initial state of ticket list
  initialState = {
    reply: [],
  };

  //ticket request action finish
  [ReplyActions.REQUEST_POST_REPLY_FINISHED](state, action) {
    return {
      ...state,
      reply: action.payload,
    };
  }
}
