import { BaseModel } from "sjs-base-model";
import FaceBookAcountDataModel from "./FaceBookAcountDataModel";

// YouTube Account data hits Model function
export default class FaceBookAccountDataHitsModel extends BaseModel {
  
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [FaceBookAcountDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
