import BaseReducer from "../../../../utilities/BaseReducer";
import LeadAction from "./LeadAction";

export default class LeadFilterReducer extends BaseReducer {
  //initial state of LeadFilterReduser
  initialState = {
    lead_filter: [],
  };
  //LeadFilterReduser request action finish
  [LeadAction.REQUEST_LEAD_FILTER_FINISHED](state, action) {
    return {
      ...state,
      lead_filter: action.payload,
    };
  }

  [LeadAction.REQUEST_LEAD_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      lead_filter: action.payload,
    };
  }
}
