import { BaseModel } from "sjs-base-model";

export default class WebsweepxReportCMSvsWSXHSSGetModel extends BaseModel {
  constructor(data) {
    super();
    this.update(data);
  }

  update(data) {
    Object.assign(this, data);
  }
}
