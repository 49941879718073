import ActionUtility from "../../../../utilities/ActionUtility";
import PintersetAccontEffect from "./PintersetAccontEffect";

export default class PinterestAccountAction {
  // PinterestAccountAction with an function declaration
  static REQUEST_PINTEREST_ACCOUNT =
    "PinterestAccountAction.REQUEST_PINTEREST_ACCOUNT";
  static REQUEST_PINTEREST_ACCOUNT_FINISHED =
    "PinterestAccountAction.REQUEST_PINTEREST_ACCOUNT_FINISHED";

  static REQUEST_PUT_PINTEREST_ACCOUNT =
    "PinterestAccountAction.REQUEST_PUT_PINTEREST_ACCOUNT";
  static REQUEST_PUT_PINTEREST_ACCOUNT_FINISHED =
    "PinterestAccountAction.REQUEST_PUT_PINTEREST_ACCOUNT_FINISHED";

  static REQUEST_DELETE_PINTEREST_ACCOUNT =
    "PinterestAccountAction.REQUEST_DELETE_PINTEREST_ACCOUNT";
  static REQUEST_DELETE_PINTEREST_ACCOUNT_FINISHED =
    "PinterestAccountAction.REQUEST_DELETE_PINTEREST_ACCOUNT_FINISHED";

  // METHODS
  // Pinterest Get function
  static requestPinterestAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestAccountAction.REQUEST_PINTEREST_ACCOUNT,
        PintersetAccontEffect.requestPinterestAccount,
        params
      );
    };
  }

  static requestPutPinterestAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestAccountAction.REQUEST_PUT_PINTEREST_ACCOUNT,
        PintersetAccontEffect.requestPutPinterestAccount,
        data,
        id
      );
    };
  }

  static requestDeletePinterestAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PinterestAccountAction.REQUEST_DELETE_PINTEREST_ACCOUNT,
        PintersetAccontEffect.requestDeletePinterestAccount,
        data
      );
    };
  }
}
