import BaseReducer from "../../../utilities/BaseReducer";
import AddAccountAction from "./AddAccountAction";

// category reducer function
export default class AddAccountReducer extends BaseReducer {
  initialState = {
    addAccount: [],
  };

  [AddAccountAction.REQUEST_ADD_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      addAccount: action.payload,
    };
  }
}
