import BaseReducer from "../../../../utilities/BaseReducer";
import LinkStatusAction from "../linkStatus/LinkStatusAction";

export default class LinkStatusReduser extends BaseReducer {
  initialState = {
    linkStatus: [],
  };
  [LinkStatusAction.REQUEST_LINK_STATUS_FINISHED](state, action) {
    return {
      ...state,
      linkStatus: action.payload,
    };
  }
}
