import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencersActivityAction from "./InfluencersActivityAction";

// InfluencersActivityReduser
export default class InfluencersActivityReportsReducer extends BaseReducer {
  initialState = {
    influencersActivityReports: [],
  };
  [InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_REPORTS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencersActivityReports: action.payload,
    };
  }
}
