import { BaseModel } from "sjs-base-model";

// AssignmentRuleGetModel
export default class AutomationOperationsModel extends BaseModel {
  array = [];
  integer = [];
  string = [];
  time = [];
  boolean = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
