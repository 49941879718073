import { ResponsivePie } from "@nivo/pie";
import { theme } from "../../views/App";
import { prefixZero_for_singleDigit } from "../ticketPartials";

export const NivoPieChart = (props) => {
  // Data Format for reference :
  // [
  //  {
  //   "id": "css",
  //   "label": "css",
  //   "value": 525,
  //  },
  // ]

  const customColors = props.colors
    ? props.colors
    : [
        `${theme.pieChart.color1}`,
        `${theme.pieChart.color2}`,
        `${theme.pieChart.color3}`,
        `${theme.pieChart.color4}`,
        `${theme.pieChart.color5}`,
        `${theme.pieChart.color6}`,
      ];

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";

    // Function to calculate the brightness of a color
    function calculateLuminance(hex) {
      const rgb = parseInt(hex.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return 0.299 * r + 0.587 * g + 0.114 * b;
    }

    do {
      color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    } while (calculateLuminance(color) < 50); // Adjust the brightness threshold

    return color;
  }

  return (
    <ResponsivePie
      data={props.chartData}
      margin={
        props.margin
          ? props.margin
          : { top: 40, right: 80, bottom: 40, left: 80 }
      }
      innerRadius={props.innerRadius !== undefined ? props.innerRadius : 0.5} // 0.5 to 1
      padAngle={4}
      cornerRadius={5}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={props.chartData?.map((item, index) =>
        index < customColors.length ? customColors[index] : getRandomColor()
      )}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLabels={props.enableArcLabels && props.enableArcLabels} // default:true
      enableArcLinkLabels={
        props.enableArcLinkLabels && props.enableArcLinkLabels
      } // default:true
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="black"
      arcLinkLabelsDiagonalLength={5}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      // defs={[
      //   {
      //     id: "dots",
      //     type: "patternDots",
      //     background: "inherit",
      //     color: "rgba(255, 255, 255, 0.3)",
      //     size: 4,
      //     padding: 1,
      //     stagger: true,
      //   },
      //   {
      //     id: "lines",
      //     type: "patternLines",
      //     background: "inherit",
      //     color: "rgba(255, 255, 255, 0.3)",
      //     rotation: -45,
      //     lineWidth: 6,
      //     spacing: 10,
      //   },
      // ]}
      // fill={[
      //   {
      //     match: {
      //       id: "ruby",
      //     },
      //     id: "dots",
      //   },
      //   {
      //     match: {
      //       id: "c",
      //     },
      //     id: "lines",
      //   },
      // ]}
      tooltip={
        props.tooltip
          ? props.tooltip
          : (response) => (
              <div
                style={{
                  padding: "4px 10px 4px 10px",
                  color: theme.palette.primary.main,
                  background: "white",
                  display: "flex",
                  alignItems: "center",
                  border: `1px solid ${theme.palette.common.grey}`,
                  borderRadius: "4px",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                }}
              >
                <div
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 8,
                    backgroundColor: response?.datum?.color,
                  }}
                ></div>
                {response?.datum.label}:&nbsp;
                <strong>
                  {prefixZero_for_singleDigit(response?.datum.value)}
                </strong>
              </div>
            )
      }
      defs={props.defs}
      fill={props.fill}
      legends={
        props.legendhide
          ? []
          : [
              {
                anchor: props.anchor ? props.anchor : "right",
                direction: props.direction ? props.direction : "column",
                justify: false,
                translateX: props.translateX ? props.translateX : 0,
                translateY: props.translateY ? props.translateY : 0,
                itemsSpacing: 30,
                itemWidth: 100,
                itemHeight: props.itemHeight ? props.itemHeight : 18,
                itemTextColor: "black",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]
      }
      motionConfig="gentle"
      animate={props.animate && props.animate} // default is true
      onClick={props.onClick}
    />
  );
};
