import ActionUtility from "../../../utilities/ActionUtility";
import ResponseMessageEffect from "./ResponseMessageEffect";

export default class ResponseMessageAction {
  // MESSAGE action with an function declaration
  static REQUEST_RESPONSE_MESSAGE =
    "ResponseMessageAction.REQUEST_RESPONSE_MESSAGE";
  static REQUEST_RESPONSE_MESSAGE_FINISHED =
    "ResponseMessageAction.REQUEST_RESPONSE_MESSAGE_FINISHED";

  // MESSAGE action with an function declaration
  static REQUEST_RESPONSE_MESSAGE_FILTER =
    "ResponseMessageAction.REQUEST_RESPONSE_MESSAGE_FILTER";
  static REQUEST_RESPONSE_MESSAGE_FILTER_FINISHED =
    "ResponseMessageAction.REQUEST_RESPONSE_MESSAGE_FILTER_FINISHED";

  //   MESSAGE post
  static REQUEST_POST_RESPONSE_MESSAGE =
    "ResponseMessageAction.REQUEST_POST_RESPONSE_MESSAGE";
  static REQUEST_POST_RESPONSE_MESSAGE_FINISHED =
    "ResponseMessageAction.REQUEST_POST_RESPONSE_MESSAGE_FINISHED";

  // MESSAGE put
  static REQUEST_PUT_RESPONSE_MESSAGE =
    "ProjectAction.REQUEST_PUT_RESPONSE_MESSAGE";
  static REQUEST_PUT_RESPONSE_MESSAGE_FINISHED =
    "ResponseMessageAction.REQUEST_PUT_RESPONSE_MESSAGE_FINISHED";

  // METHODS
  // MESSAGE GET function
  static requestResponseMessage(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseMessageAction.REQUEST_RESPONSE_MESSAGE,
        ResponseMessageEffect.requestResponseMessage,
        params
      );
    };
  }

  // MESSAGE filter
  static requestResponseMessageFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseMessageAction.REQUEST_RESPONSE_MESSAGE_FILTER,
        ResponseMessageEffect.requestResponseMessageFilter,
        params
      );
    };
  }

  // MESSAGE post function
  static requestPostResponseMessage(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseMessageAction.REQUEST_POST_RESPONSE_MESSAGE,
        ResponseMessageEffect.requestPostResponseMessage,
        data
      );
    };
  }

  // MESSAGE put function
  static requestPutResponseMessage(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseMessageAction.REQUEST_PUT_RESPONSE_MESSAGE,
        ResponseMessageEffect.requestPutResponseMessage,
        data,
        id
      );
    };
  }
}
