import ActionUtility from "../../../utilities/ActionUtility";
import FaceBookAccountEffect from "./FaceBookAccountEffect";
export default class FaceBookAccountAction {
  // FaceBookAccountAction action with an function declaration
  static REQUEST_FACEBOOK_ACCOUNT =
    "FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT";
  static REQUEST_FACEBOOK_ACCOUNT_FINISHED =
    "FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT_FINISHED";

  static REQUEST_FACEBOOK_ACCOUNT_FILTER =
    "FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT_FILTER";
  static REQUEST_FACEBOOK_ACCOUNT_FILTER_FINIISHED =
    "FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT_FILTER_FINIISHED";

  static REQUEST_PUT_FACEBOOK_ACCOUNT =
    "FaceBookAccountAction.REQUEST_PUT_FACEBOOK_ACCOUNT";
  static REQUEST_PUT_FACEBOOK_ACCOUNT_FINISHED =
    "FaceBookAccountAction.REQUEST_PUT_FACEBOOK_ACCOUNT_FINISHED";

  static REQUEST_DELETE_FACEBOOK_ACCOUNT =
    "FaceBookAccountAction.REQUEST_DELETE_FACEBOOK_ACCOUNT";
  static REQUEST_DELETE_FACEBOOK_ACCOUNT_FINISHED =
    "FaceBookAccountAction.REQUEST_DELETE_FACEBOOK_ACCOUNT_FINISHED";

  // METHODS
  // faceBook GET function
  static requestFaceBookAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT,
        FaceBookAccountEffect.requestFaceBookAccount,
        params
      );
    };
  }

  static requestFaceBookAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT_FILTER,
        FaceBookAccountEffect.requestFaceBookAccountFilter,
        params
      );
    };
  }
  static requestPutFaceBookAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaceBookAccountAction.REQUEST_PUT_FACEBOOK_ACCOUNT,
        FaceBookAccountEffect.requestPutFaceBookAccount,
        data,
        id
      );
    };
  }

  static requestDeleteFaceBookAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaceBookAccountAction.REQUEST_DELETE_FACEBOOK_ACCOUNT,
        FaceBookAccountEffect.requestDeleteFaceBookAccount,
        id
      );
    };
  }
}
