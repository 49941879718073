import BaseReducer from "../../../../utilities/BaseReducer";
import WebsweepxReportsAction from "./WebsweepxReportsAction";

export default class WebsweepxCrawledPagesReduser extends BaseReducer {
  initialState = {
    websweepxCrawledPagesReport: [],
  };
  [WebsweepxReportsAction.REQUEST_GET_CRAWLEDPAGESREPORT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepxCrawledPagesReport: action.payload,
    };
  }
}
