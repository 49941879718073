import BaseReducer from "../../../../utilities/BaseReducer";
import TaskAction from "./TaskAction";

export default class TaskFilterReducer extends BaseReducer {
  //initial state of TaskFilterReduser
  initialState = {
    Task_filter: [],
  };
  //TaskFilterReduser request action finish
  [TaskAction.REQUEST_TASK_FILTER_FINISHED](state, action) {
    return {
      ...state,
      Task_filter: action.payload,
    };
  }

  [TaskAction.REQUEST_TASK_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Task_filter: action.payload,
    };
  }
}
