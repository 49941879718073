import ActionUtility from "../../../utilities/ActionUtility";
import AiChatEffect from "./AiChatEffect";

export default class AiChatAction {
  static REQUEST_AICHAT_FEED = "AiChatAction.REQUEST_AICHAT_FEED";
  static REQUEST_AICHAT_FEED_FINISHED =
    "AiChatAction.REQUEST_AICHAT_FEED_FINISHED";

  static REQUEST_AICHAT_FEED_FILTER = "AiChatAction.REQUEST_AICHAT_FEED_FILTER";
  static REQUEST_AICHAT_FEED_FILTER_FINISHED =
    "AiChatAction.REQUEST_AICHAT_FEED_FILTER_FINISHED";

  static REQUEST_POST_AICHAT_FEED = "AiChatAction.REQUEST_POST_AICHAT_FEED";
  static REQUEST_POST_AICHAT_FEED_FINISHED =
    "AiChatAction.REQUEST_POST_AICHAT_FEED_FINISHED";

  static REQUEST_PUT_AICHAT_FEED = "AiChatAction.REQUEST_PUT_AICHAT_FEED";
  static REQUEST_PUT_AICHAT_FEED_FINISHED =
    "AiChatAction.REQUEST_PUT_AICHAT_FEED_FINISHED";

  static REQUEST_DELETE_AICHAT_FEED = "AiChatAction.REQUEST_DELETE_AICHAT_FEED";
  static REQUEST_DELETE_AICHAT_FEED_FINISHED =
    "AiChatAction.REQUEST_DELETE_AICHAT_FEED_FINISHED";

  //METHODS

  static requestAIchatFeed(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiChatAction.REQUEST_AICHAT_FEED,
        AiChatEffect.requestAIchatFeed,
        params
      );
    };
  }

  static requestAIchatFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiChatAction.REQUEST_AICHAT_FEED_FILTER,
        AiChatEffect.requestAIchatFeedFilter,
        params
      );
    };
  }

  static requestPostAIchat(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiChatAction.REQUEST_POST_AICHAT_FEED,
        AiChatEffect.requestPostAIchat,
        data
      );
    };
  }
}
