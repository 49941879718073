import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import PriorityAction from "../../stores/priority/PriorityAction";
import { DialogBox } from "../../../components/componentLayout/ComponentLayout";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

//validation schema
const validationSchema = yup.object({
  ticket_priority_name: yup
    .string("Enter your ticket priority name")
    .trim()
    .min(1, "Ticket Priority Name should be of minimum 1 characters length")
    .required("Ticket Priority name is required"),

  color: yup
    .string("Select color")
    .trim()
    .min(1, "Color should be of minimum 1 characters length")
    .required("Color is required"),
});

const TicketPriorityPost = (props) => {
  // Ticket Type post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      ticket_priority_name: props.priorityEditData.ticket_priority_name,
      color: props.priorityEditData.color,
    };
  } else {
    initial = {
      ticket_priority_name: "",
      color: "",
    };
  }

  const [loading, setLoading] = useState(false);

  //Formik
  const formik = useFormik({
    initialValues: initial,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // parameter for showing list based on the modified_by
      setLoading(true);
      const params = {
        order_by: `[["order_number","asc"]]`,
        page_limit: 15,
      };
      const param = {
        order_by: `[["ticket_priority_name.keyword", "asc"]]`,
        page_limit: "none",
      };
      if (props.edit) {
        let edit_obj = {
          ticket_priority_name: values.ticket_priority_name,
          color: values.color,
        };
        props
          .dispatch(
            PriorityAction.requestPutPriority(
              JSON.stringify(edit_obj),
              props.priorityEditData.id
            )
          )
          .then(() => {
            props.dispatch(PriorityAction.requestPriority(params));
            props.dispatch(PriorityAction.filterRequestPriority(param));
            props.onClose();
            setLoading(false);
          });
      } else {
        props.dispatch(PriorityAction.requestPostPriority(values)).then(() => {
          const params = {
            order_by: `[["order_number","asc"]]`,
            page_limit: 15,
          };
          const param = {
            order_by: `[["ticket_priority_name.keyword", "asc"]]`,
            page_limit: "none",
          };
          props.dispatch(PriorityAction.requestPriority(params));
          props.dispatch(PriorityAction.filterRequestPriority(param));
          props.onClose();
          setLoading(false);
        });
      }

      formik.resetForm();
    },
  });

  const handleClose = () => {
    props.onClose();
    formik.resetForm();
  };

  return (
    <DialogBox>
      <Dialog
        open={props.open}
        onClose={() => {
          handleClose();
        }}
        maxWidth="sm"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DialogContentText sx={{ width: "100%" }}>
              <div className="text-center pb-2">
                <div className="row p-3">
                  <div className="col-10">
                    <h3 className="dailogtext">
                      {!props.edit ? (
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                          </span>
                          dd &nbsp;
                          <span className="span_first_letter">T</span>icket
                          &nbsp;
                          <span className="span_first_letter">P</span>riority
                          &nbsp;
                          <span className="span_first_letter">N</span>ame
                        </b>
                      ) : (
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                          </span>
                          dit &nbsp;
                          <span className="span_first_letter">T</span>icket
                          &nbsp;
                          <span className="span_first_letter">P</span>riority
                          &nbsp;
                          <span className="span_first_letter">N</span>ame
                        </b>
                      )}
                    </h3>
                  </div>
                  <div
                    className="col-2 closebtn"
                    onClick={() => {
                      props.onClose();
                      formik.resetForm();
                    }}
                  >
                    <i
                      title="Close"
                      className="fa fa-times-circle-o fa-2x"
                      style={{ size: "15px" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </DialogContentText>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} container>
                <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                  Priority Name * :
                </Grid>
                <Grid item lg={5} md={5} sm={5}>
                  <MuiTextField
                    autoFocus
                    variant="outlined"
                    size="small"
                    id="ticket_priority_name"
                    name="ticket_priority_name"
                    placeholder="Priority Name"
                    value={formik.values.ticket_priority_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ticket_priority_name &&
                      Boolean(formik.errors.ticket_priority_name)
                    }
                    helperText={
                      formik.touched.ticket_priority_name &&
                      formik.errors.ticket_priority_name
                    }
                  />
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} sm={12} container mt={2}>
                <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                  Priority Indicator * :
                </Grid>
                <Grid item lg={5} md={5} sm={5}>
                  <MuiTextField
                    type="color"
                    variant="outlined"
                    size="small"
                    id="color"
                    name="color"
                    placeholder="ticket_priority_indicator"
                    value={formik.values.color}
                    onChange={formik.handleChange}
                    error={formik.touched.color && Boolean(formik.errors.color)}
                    helperText={formik.touched.color && formik.errors.color}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className="m-5 my-4">
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              display="flex"
              justifyContent="space-evenly"
            >
              <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                {!props.edit ? (
                  <MuiButton
                    name={"Add Priority"}
                    type="submit"
                    loading={loading}
                    width="100%"
                  />
                ) : (
                  <MuiButton
                    name={"Submit"}
                    type="submit"
                    loading={loading}
                    width="100%"
                  />
                )}
              </Grid>
              <Grid item lg={5} md={5} sm={5} minWidth={200}>
                {!props.edit ? (
                  <MuiButton
                    name={"Reset"}
                    type="reset"
                    onClick={formik.resetForm}
                    width="100%"
                  />
                ) : (
                  <MuiButton
                    name={"Cancel"}
                    width="100%"
                    onClick={() => {
                      props.onClose();
                      formik.resetForm();
                    }}
                  />
                )}{" "}
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </DialogBox>
  );
};

export default connect(mapDispatchToProps)(TicketPriorityPost);
