import React, { useState } from "react";
import {
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

import { Form, Formik, Field } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import ClientAction from "../../stores/client/ClientAction";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../components/muiButton/MuiButton";
// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const ClintPost = (props) => {
  let initial = {};
  if (props.edit) {
    initial = {
      client_name: props.projectEditData.client_name,
      organization: props.projectEditData.mapped_organization,
    };
  } else {
    initial = {
      client_name: "",
      organization:
        props.organization_array && props.organization_array.length === 1
          ? props.organization_array[0]
          : "",
    };
  }

  const validationSchema = yup.object({
    client_name: yup
      .string("Enter your Client name")
      .trim()
      .min(1, "Client name should be of minimum 1 characters length")
      .required("Client name is required"),

    organization: yup
      .object()
      .shape({})
      .required("Organization is Required")
      .nullable(),
  });

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          const params = {
            order_by: '[["created_on", "desc"]]',
            page_limit: 15,
          };
          const param = {
            order_by: `[["client_name.keyword", "asc"]]`,
            page_limit: "none",
          };
          if (props.edit) {
            props
              .dispatch(
                ClientAction.requestPutClient(
                  JSON.stringify(values),
                  props.projectEditData.project_unique_id
                )
              )
              .then(() => {
                props.dispatch(ClientAction.requestClient(params));
                props.dispatch(ClientAction.requestClientFilter(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            const callBackFunction = (resp) => {
              props.onClose(resp);
            };

            props
              .dispatch(
                ClientAction.requestPostClient(values, callBackFunction)
              )
              .then(() => {
                const params = {
                  order_by: '[["created_on", "desc"]]',
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["client_name.keyword", "asc"]]`,
                  page_limit: "none",
                };
                props.dispatch(ClientAction.requestClient(params));
                props.dispatch(ClientAction.requestClientFilter(param));
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "80%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">C</span>lient
                            &nbsp;
                            <span className="span_first_letter">D</span>etails
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">C</span>lient
                            &nbsp;
                            <span className="span_first_letter">D</span>etails
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Client Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Client Name"
                      id="client_name"
                      size="small"
                      name="client_name"
                      autoFocus
                      error={
                        Boolean(errors.client_name) &&
                        Boolean(touched.client_name)
                      }
                      helperText={
                        Boolean(touched.client_name) && errors.client_name
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Organization * :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="organization"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.organization ? values.organization : null}
                      component={AutoCompleteMultiSelect}
                      // disabled={props.edit || values.category}
                      options={
                        props.organization_array ? props.organization_array : []
                      }
                      disabled={
                        props.organization_array &&
                        props.organization_array.length === 1
                          ? true
                          : false
                      }
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.organization_name,
                      })}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.organization_name === value.organization_name
                      }
                      getOptionLabel={(option) => option.organization_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Organization",
                      }}
                      error={
                        Boolean(errors.organization) &&
                        Boolean(touched.organization)
                      }
                      helperText={
                        Boolean(touched.organization) && errors.organization
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Add Client Name"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(ClintPost);
