import LeadFilterReducer from "./lead/LeadFilterReducer";
import LeadReducer from "./lead/LeadReducer";
import TaskReducer from "./task/TaskReducer";
import TaskFilterReducer from "./task/TaskFilterReducer";
import CustomerReducer from "./customer/CustomerReducer";
import CustomerFilterReducer from "./customer/CustomerFilterReducer";
import OpportunityReducer from "./opportunity/OpportunityReducer";
import OpportunityFilterReducer from "./opportunity/OpportunityFilterReducer";
import ContactReducer from "./contact/ContactReducer";
import ContactFilterReducer from "./contact/ContactFilterReducer";
import LeadSourceReducer from "./leadsource/LeadSourceReducer";
import LeadSourceFilterReducer from "./leadsource/LeadSourceFilterReducer";
import TerritoryReducer from "./territory/TerritoryReducer";
import TerritoryFilterReducer from "./territory/TerritoryFilterReducer";

export const crmRootReducer = {
  //lead

  lead: new LeadReducer().reducer,
  lead_filter: new LeadFilterReducer().reducer,

  //Task

  Task: new TaskReducer().reducer,
  Task_filter: new TaskFilterReducer().reducer,

  //customer
  Customer: new CustomerReducer().reducer,
  Customer_filter: new CustomerFilterReducer().reducer,

  //Opportunity
  Opportunity: new OpportunityReducer().reducer,
  Opportunity_filter: new OpportunityFilterReducer().reducer,

  //Contact
  Contact: new ContactReducer().reducer,
  Contact_filter: new ContactFilterReducer().reducer,

  //LeadSource
  LeadSource: new LeadSourceReducer().reducer,
  LeadSource_filter: new LeadSourceFilterReducer().reducer,

  //Territory
  Territory: new TerritoryReducer().reducer,
  Territory_filter: new TerritoryFilterReducer().reducer,
};
