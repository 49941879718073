import { BaseModel } from "sjs-base-model";

// twitter api fetch model
export default class FacebookSourceModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_on = "";
  post_comment = [];
  post_content = "";
  post_created_at = "";
  post_id = "";
  user_email = "";
  user_id = "";
  user_name = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
