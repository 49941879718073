import axios, { AxiosError } from "axios";
import HttpErrorResponseModel from "../models/HttpErrorResponseModel";
import React from "react";
import axiosRetry from "axios-retry";
// import environment from "environment";

const RequestMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Delete: "DELETE",
  Options: "OPTIONS",
  Head: "HEAD",
  Patch: "PATCH",
};

class HttpUtility extends React.Component {
  static async get(endpoint, params, requestConfig) {
    const paramsConfig = params ? { params } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Get,
      },
      {
        ...paramsConfig,
        ...requestConfig,
      }
    );
  }

  static async post(endpoint, data, params) {
    const config = data ? { data } : undefined;
    const paramsConfig = params ? { params } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Post,
      },
      {
        ...config,
        ...paramsConfig,
      }
    );
  }

  static async put(endpoint, data, params) {
    const config = data ? { data } : undefined;
    const paramsConfig = params ? { params } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Put,
      },
      { ...config, ...paramsConfig }
    );
  }

  static async postWithId(endpoint, data) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Post,
      },
      config
    );
  }

  static async delete(endpoint, data) {
    const config = data ? { data } : undefined;

    return HttpUtility._request(
      {
        url: endpoint,
        method: RequestMethod.Delete,
      },
      config
    );
  }

  static async _request(restRequest, config) {
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000; // Retry delay in milliseconds
      },
      retryCondition: (error) => {
        // return error.response.status === 0; // Whenever axios error response status = 0, retry call

        return (
          error.config.method.toLowerCase() == "get" &&
          (axiosRetry.isNetworkError(error) ||
            axiosRetry.isRetryableError(error) ||
            (error.response && error.response.status === 0))
        );
      },
    });

    if (!Boolean(restRequest.url)) {
      console.error(
        `Received ${restRequest.url} which is invalid for a endpoint url`
      );
    }

    // const timeout = 10000;

    try {
      const controller = new AbortController();
      // const { signal } = controller;

      let token;
      if (localStorage.getItem("Auth") !== null) {
        token = JSON.parse(localStorage.getItem("Auth")).access_token;
      }
      const axiosRequestConfig = {
        ...config,
        method: restRequest.method,
        url: restRequest.url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          ...config?.headers,
        },
        // timeout: timeout,
        // cancelToken: signal.token,
      };

      const [axiosResponse] = await Promise.all([
        axios(axiosRequestConfig),
        HttpUtility._delay(),
      ]);

      // console.log("AXIOS_RESPONSE", axiosResponse);
      const { status, data, request } = axiosResponse;

      if (data.success === false) {
        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: data.errors.join(" - "),
            errors: data.errors,
            url: request ? request.responseURL : restRequest.url,
            raw: axiosResponse,
          },
          restRequest
        );
      }

      return {
        ...axiosResponse,
      };
    } catch (error) {
      // console.log("ERROR", error);

      if (error.code === "ECONNABORTED") {
        const { status, message, request, config, name, statusText, data } =
          error;

        // const errors = data.hasOwnProperty("errors")
        //   ? [statusText, ...data.errors]
        //   : [statusText];

        // console.warn("AXIOS_ERR", error, restRequest, errors);

        // console.log(
        //   "ERROR_RESPONSE_API",
        //   HttpUtility._fillInErrorWithDefaults(
        //     {
        //       status: 500,
        //       message: errors.filter(Boolean).join(" - "),
        //       errors,
        //       url: error.request.__apm_symbol__xhrURL,
        //       raw: error.response,
        //     },
        //     restRequest
        //   )
        // );

        // return HttpUtility._fillInErrorWithDefaults(
        //   {
        //     status: request.status,
        //     message: message && "Server request timed out, Please try again",
        //     errors: name,
        //     url: request.__apm_symbol__xhrURL,
        //     raw: config.data,
        //   },
        //   restRequest
        // );
      }

      // If there is a status 500 response from server, then the below block will return ERROR model to stop the loading indicator
      if (error.code === "ERR_NETWORK") {
        let errorBody = error;

        Object.assign(errorBody, {
          ...error,
          data: { message: errorBody.message, status: "failed" },
        });

        // console.log("ERROR_BODY", errorBody);

        return HttpUtility._fillInErrorWithDefaults(
          {
            status: error.request.status,
            message: error.message,
            errors: [],
            url: error.request.__apm_symbol__xhrURL,
            raw: error,
          },
          restRequest
        );
      }

      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const { status, statusText, data, request } = error.response;

        // console.log("check===>", request);

        if (
          status === 401
          // &&
          // request &&
          // request.responseURL &&
          // request.responseURL !==
          //   `${environment.api.postToIngrooves}/${localStorage.getItem("id")}`
        ) {
           if (data.error === "invalid_token") {
             localStorage.removeItem("Auth");
             // alert(data.error_description);
             window.location.reload();
           } else if (data.error === "invalid_grant") {
             alert(data.error_description);
           }
        }

        const errors = data.hasOwnProperty("errors")
          ? [statusText, ...data.errors]
          : [statusText];

        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: errors.filter(Boolean).join(" - "),
            errors,
            url: error.request.responseURL,
            raw: error.response,
          },
          restRequest
        );
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        const { status, statusText, responseURL } = error.request;

        return HttpUtility._fillInErrorWithDefaults(
          {
            status,
            message: statusText,
            errors: [statusText],
            url: responseURL,
            raw: error.request,
          },
          restRequest
        );
      }

      // Something happened in setting up the request that triggered an Error
      return HttpUtility._fillInErrorWithDefaults(
        {
          status: 0,
          message: error.message,
          errors: [error.message],
          url: restRequest.url,
          raw: error,
        },
        restRequest
      );
    }
  }

  static _fillInErrorWithDefaults(error, request) {
    const model = new HttpErrorResponseModel();

    model.status = error.status || 0;
    model.message = error.message || "Error requesting data";
    model.errors = error.errors.length
      ? error.errors
      : ["Error requesting data"];
    model.url = error.url || request.url;
    model.raw = error.raw;

    // Remove anything with undefined or empty strings.
    model.errors = model.errors.filter(Boolean);

    return model;
  }

  /**
   * We want to show the loading indicator to the user but sometimes the api
   * request finished too quickly. This makes sure there the loading indicator is
   * visual for at least a given time.
   *
   * @param duration
   * @returns {Promise<unknown>}
   * @private
   */
  static _delay(duration = 250) {
    return new Promise((resolve) => setTimeout(resolve, duration));
  }
}
export default HttpUtility;
