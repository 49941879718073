import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import TrustpilotFeedsModel from "./model/TrustpilotFeedsModel";
import TrustpilotPostModel from "./model/TrustpilotPostModel";
import TrustpilotPutModel from "./model/TrustpilotPutModel";

export default class TrustpilotFeedsEffect {
  //Pinterest feeds request
  static async requestTrustpilotFeeds(params, callBackFunction) {
    const endpoint = environment.api.trustPilot;
    let response = EffectUtility.getToModel(
      TrustpilotFeedsModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestTrustpilotFeedsClear() {
    return {};
  }

  //Pinterest feeds Filter
  static async requestTrustpilotFilter(params) {
    const endpoint = environment.api.trustPilot;
    return EffectUtility.getToModel(TrustpilotFeedsModel, endpoint, params);
  }

  // put method for the Pinterest Feeds
  static async requestPutTrustpilotFeeds(data, id) {
    const endpoint = environment.api.trustPilot + "/" + id;
    return EffectUtility.putToModel(TrustpilotPutModel, endpoint, data);
  }

  // Post API
  static async requestTrustpilotPost(queryParams, data) {
    const endpoint = environment.api.trustPilot + "?" + queryParams;

    return EffectUtility.postToModel(TrustpilotPostModel, endpoint, data);
  }
}
