import BaseReducer from "../../../utilities/BaseReducer";
import RolesAction from "./RolesAction";

export default class RolesFilterReducer extends BaseReducer {
  initialState = {
    rolesFilter: [],
  };

  [RolesAction.REQUEST_ROLE_FILTER_FINSIHED](state, action) {
    return {
      ...state,
      rolesFilter: action.payload,
    };
  }
}
