import ActionUtility from "../../../utilities/ActionUtility";
import TicketCountEffect from "./TicketcountEffect";

export default class TicketCountAction {
  //ticket count requests
  static REQUEST_TICKET_COUNT = "TicketCountAction.REQUEST_TICKET_COUNT";
  static REQUEST_TICKET_COUNT_FINISHED =
    "TicketCountAction.REQUEST_TICKET_COUNT_FINISHED";

  //ticket count requests
  static REQUEST_DASHBOARD_TICKET_COUNT =
    "TicketCountAction.REQUEST_DASHBOARD_TICKET_COUNT";
  static REQUEST_DASHBOARD_TICKET_COUNT_FINISHED =
    "TicketCountAction.REQUEST_DASHBOARD_TICKET_COUNT_FINISHED";

  //METHODS

  //Ticketcount get method
  static requestTicketCount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketCountAction.REQUEST_TICKET_COUNT,
        TicketCountEffect.requestTicketCount,
        params
      );
    };
  }

  //Ticketcount get method
  static requestDashboardTicketCount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketCountAction.REQUEST_DASHBOARD_TICKET_COUNT,
        TicketCountEffect.requestDashboardTicketCount,
        params
      );
    };
  }
}
