import { BaseModel } from "sjs-base-model";
import OragnizationDataHitsModel from "./OragnizationDataHitsModel";
import OrganizationShardsModel from "./OrganizationShardsModel";

// OrganizationModel
export default class OrganizationModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: OrganizationShardsModel };
  current_page_no = 0;
  hits = { hits: OragnizationDataHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
