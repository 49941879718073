import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import UserRoleMappingModel from "./model/UserRoleMappingModel";
import UserRoleMappingPostModel from "./model/UserRoleMappingPostModel";
import UserRoleMappingPutModel from "./model/UserRoleMappingPutModel";

// UserRoleMappingEffect
export default class UserRoleMappingEffect {
  // calling an API according to the Email Account model
  // Get method for the user role mapping
  static async requestUserRoleMapping(params) {
    const endpoint = environment.api.userRoleMapping;
    return EffectUtility.getToModel(UserRoleMappingModel, endpoint, params);
  }

  // Get method for the user role mapping filter
  static async requestUserRoleMappingFilter(params) {
    const endpoint = environment.api.userRoleMappingFilter;
    return EffectUtility.getToModel(UserRoleMappingModel, endpoint, params);
  }

  // put method for the user role mapping
  static async requestPutUserRoleMapping(data, id) {
    const endpoint = environment.api.userRoleMapping + "/" + id;
    return EffectUtility.putToModel(UserRoleMappingPutModel, endpoint, data);
  }

  // post method for the user role mapping
  static async requestPostUserRoleMapping(data) {
    const endpoint = environment.api.userRoleMapping;

    return EffectUtility.postToModel(UserRoleMappingPostModel, endpoint, data);
  }
}
