import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import PinterestFeedsModel from "./model/PinterestFeedsModel";
import PinterestPostModel from "./model/PinterestPostModel";
import PinterestPutModel from "./model/PinterestPutModel";

export default class PinterestFeedsEffect {
  //Pinterest feeds request
  static async requestPinterestFeeds(params, callBackFunction) {
    const endpoint = environment.api.pinterest;
    let response = EffectUtility.getToModel(
      PinterestFeedsModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestPinterestFeedsClear() {
    return {};
  }

  //Pinterest feeds Filter
  static async requestPinterestFilter(params) {
    const endpoint = environment.api.pinterest;
    return EffectUtility.getToModel(PinterestFeedsModel, endpoint, params);
  }

  // put method for the Pinterest Feeds
  static async requestPutPinterestFeeds(data, id) {
    const endpoint = environment.api.pinterest + "/" + id;
    return EffectUtility.putToModel(PinterestPutModel, endpoint, data);
  }

  // Post API
  static async requestPinterestPost(queryParams, data) {
    const endpoint = environment.api.pinterest + "?" + queryParams;

    return EffectUtility.postToModel(PinterestPostModel, endpoint, data);
  }
}
