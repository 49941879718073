import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// InfluencersDataModel
export default class InfluencersDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();

  constructor(data) {
    super();

    this.update(data);
  }
}
