import BaseReducer from "../../utilities/BaseReducer";
import TicketTypeAction from "./TicketTypeAction";

export default class TicketTypeFilterReducer extends BaseReducer {
  //initial state of ticketType
  initialState = {
    filterTicketType: [],
  };

  //tickettype request action finish
  [TicketTypeAction.REQUEST_TICKETTYPE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      filterTicketType: action.payload,
    };
  }
}
