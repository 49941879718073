import BaseReducer from "../../../utilities/BaseReducer";
import YoutubeAccountAction from "./YoutubeAccountAction";
export default class YoutubeAccountFilterReduser extends BaseReducer {
  //initial state of YoutubeAccount
  initialState = {
    youtubeAccountFilter: [],
  };
  //YoutubeAccountAction request action finish
  [YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeAccountFilter: action.payload,
    };
  }
}
