import ActionUtility from "../../../utilities/ActionUtility";
import CloudMialListEffect from "./CloudMialListEffect";

export default class CloudMailListAction {
  static REQUEST_CLOUD_MAIL_LIST =
    "CloudMailListAction.REQUEST_CLOUD_MAIL_LIST";
  static REQUEST_CLOUD_MAIL_LIST_FINISHED =
    "CloudMailListAction.REQUEST_CLOUD_MAIL_LIST_FINISHED";

  static requestCloudMailList(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CloudMailListAction.REQUEST_CLOUD_MAIL_LIST,
        CloudMialListEffect.requestCloudMailList,
        params
      );
    };
  }
}
