import { BaseModel } from "sjs-base-model";
import PlayStoreShardsModel from "./PlayStoreShardsModel";
import PlayStoreHitsModel from "./PlayStoreHitsModel";

// get model for project
export default class PlayStoreModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = { _shards: PlayStoreShardsModel };
  hits = { hits: PlayStoreHitsModel };
  total_page_no = 0;
  current_page_no = 0;
  timed_out = false;
  took = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
