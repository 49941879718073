import { BaseModel } from "sjs-base-model";

export default class ChannelAnalyticsAccountPostModel extends BaseModel {
  data = {};
  id = "";
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
