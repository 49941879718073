import BaseReducer from "../../../utilities/BaseReducer";
import AiChatAction from "./AiChatAction";

export default class AiChatFilterReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    aiChatFeedFilter: [],
  };

  //operation Filter
  [AiChatAction.REQUEST_AICHAT_FEED_FILTER_FINISHED](state, action) {
    return {
      ...state,
      aiChatFeedFilter: action.payload,
    };
  }
}
