import { Component } from "react";
import ActionUtility from "../../../utilities/ActionUtility";
import FacebookDmEffect from "./FacebookDmEffect";

export default class FacebookDmAction extends Component {
  //Facebook_md requests
  static REQUEST_FACEBOOK_DM = "Facebook_Dm_Action.REQUEST_FACEBOOK_DM";
  static REQUEST_FACEBOOK_DM_FINISHED =
    "Facebook_Dm_Action.REQUEST_FACEBOOK_DM_FINISHED";

  //Facebook_md post
  static REQUEST_FACEBOOK_DM_POST =
    "Facebook_Dm_Action.REQUEST_FACEBOOK_DM_POST";
  static REQUEST_FACEBOOK_DM_POST_FINISHED =
    "Facebook_Dm_Action.REQUEST_FACEBOOK_DM_POST_FINISHED";

  static REQUEST_FACEBOOK_DM_CLEAR =
    "FacebookDmAction.REQUEST_FACEBOOK_DM_CLEAR";
  static REQUEST_FACEBOOK_DM_CLEAR_FINISHED =
    "FacebookDmAction.REQUEST_FACEBOOK_DM_CLEAR_FINISHED";

  //METHODS

  //Facebook_md get method
  static requestFacebookMessage(params, Callbackfunc) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookDmAction.REQUEST_FACEBOOK_DM,
        FacebookDmEffect.requestFacebookMessage,
        params,
        Callbackfunc
      );
    };
  }

  //Facebook_md post method
  static requestFacebookMessagePost(data, id, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookDmAction.REQUEST_FACEBOOK_DM_POST,
        FacebookDmEffect.requestFacebookMessagePost,
        data,
        id,
        callback
      );
    };
  }

  // Facebook Dm state clear function
  static requestFacebookDmClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookDmAction.REQUEST_FACEBOOK_DM_CLEAR,
        FacebookDmEffect.requestFacebookDmClear
      );
    };
  }
}
