import React, { useState, useEffect, useRef } from "react";
import environment from "environment";

import { connect } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";

import ReactEcharts from "echarts-for-react";

function TicketsByCategoryChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter).then((res) => {
        setPlatformwiseTickets(res);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);
  function flattenCategories(item, level = 0, parentRow = {}) {
    const currentRow = { ...parentRow };
    // currentRow[`CategoryLevel${level + 1}`] = `${item.value} (${item.count})`;
    currentRow[`CategoryLevel${level + 1}`] = `${item.value} (${item.count})`;

    if (!item.children || item.children.length === 0) {
      // If no children, push the row to updatedRows
      updatedRows.push({ id: updatedRows.length + 1, ...currentRow });
    } else {
      // If there are children, continue to flatten the structure recursively
      item.children.forEach((child) => {
        flattenCategories(child, level + 1, currentRow);
      });
    }
  }
  const updatedRows = [];
  platformwiseTickets?.ticket_report?.multilevel_category[0]?.children?.forEach(
    (item) => {
      return flattenCategories(item);
    }
  );
  useEffect(() => {
    if (props.DownloadClicked) {
      function processDataForExport(data) {
        const allColumns = [
          ...new Set(data.flatMap((item) => Object.keys(item))),
        ]; // Collect all column names
        const lastSeenValues = {}; // Track last seen value for each column

        return data.map((item) => {
          const newItem = {};

          // Loop through each column name
          allColumns.forEach((key) => {
            if (key === "id") {
              newItem[key] = item[key]; // Always include 'id' if it exists
            } else {
              // If the value is the same as last seen, replace it with an empty string
              if (lastSeenValues[key] === item[key]) {
                newItem[key] = "";
              } else {
                // New or unique value: keep it and update last seen
                newItem[key] = item[key] || ""; // Fill missing columns with empty strings
                lastSeenValues[key] = item[key];
              }
            }
          });

          delete newItem.id;

          return newItem;
        });
      }

      props.setexcelData(processDataForExport(updatedRows));

      // props.setDownloadClicked(false); // Uncomment if you want to reset after download
    }
  }, [props.DownloadClicked, updatedRows]);
  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["multilevel_category"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    return {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        formatter: function (params) {
          return `${
            params.data.value ||
            (params.data.key === "category"
              ? "Tickets By Category"
              : params.data.key)
          }<br />Count: ${params.data.count || 0}`;
        },
      },
      series: [
        {
          type: "tree",
          data: platformwiseTickets.ticket_report.multilevel_category,
          top: "1%",
          left: "10%",
          bottom: "1%",

          symbolSize: 8,
          label: {
            position: "left",
            verticalAlign: "middle",
            align: "right",
            fontSize: 12,
            formatter: function (params) {
              return params.data.value || params.data.key;
            },
          },
          leaves: {
            label: {
              position: "right",
              verticalAlign: "middle",
              align: "left",
            },
          },
          emphasis: {
            focus: "descendant",
          },
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
          initialTreeDepth: -1,
        },
      ],
    };
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <>
            {!loading &&
            platformwiseTickets?.ticket_report?.multilevel_category?.length ? (
              <Box height="100%" width={"100%"}>
                <ReactEcharts
                  option={getOption()}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                />
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}
export default TicketsByCategoryChart;
