import BaseReducer from "../../../utilities/BaseReducer";
import EmailFeedsAction from "./EmailFeedsAction";

export default class EmailFeedFilterReduser extends BaseReducer {
  //initial state of user
  initialState = {
    emailFeedsFilter: [],
  };

  //user request action finish
  [EmailFeedsAction.REQUEST_EMAIL_FEEDS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      emailFeedsFilter: action.payload,
    };
  }
}
