import { BaseModel } from "sjs-base-model";

//Priority list source data model
export default class FacebookDmSourceModel extends BaseModel {
  conversation_id = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  flag = "";
  message_at = "";
  message_id = "";
  message_text = "";
  modified_on = "";
  receiver_id = "";
  receiver_name = "";
  sender_id = "";
  sender_name = "";
  user_email = "";
  user_id = "";
  user_name = "";
  ticket_id = "";
  account_name = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
