import ActionUtility from "../../../utilities/ActionUtility";
import TicketFirstResponseTimeEffect from "./TicketFirstResponseTimeEffect";

export default class TicketFirstResponseTimeAction {
  //Ticket FRT requests
  static REQUEST_TICKETFIRSTRESPONSETIME =
    "TicketFirstResponseTimeAction.REQUEST_TICKETFIRSTRESPONSETIME";
  static REQUEST_TICKETFIRSTRESPONSETIME_FINISHED =
    "TicketFirstResponseTimeAction.REQUEST_TICKETFIRSTRESPONSETIME_FINISHED";

  //METHODS

  //Ticket FRT get method
  static requestTicketFirstResponseTime(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketFirstResponseTimeAction.REQUEST_TICKETFIRSTRESPONSETIME,
        TicketFirstResponseTimeEffect.requestTicketFirstResponseTime,
        params
      );
    };
  }
}
