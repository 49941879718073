import {
  Grid,
  Box,
  Tab,
  Button,
  Checkbox,
  IconButton,
  FormControlLabel,
  createFilterOptions,
  Typography,
  Tooltip,
  Chip,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import { Form, Formik, Field, FieldArray, ErrorMessage, getIn } from "formik";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import PriorityAction from "../../stores/priority/PriorityAction";
import StatusAction from "../../stores/status/StatusAction";
import UserAction from "../../stores/user/UserAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import RolesAction from "../../../common/stores/roles/RolesAction";
import AutoresponseAction from "../../stores/autoResponse/AutoresponseAction";
import TextArea from "antd/lib/input/TextArea";
import CronGenerator from "react-cron-generator";
import SwitchButton from "../../../components/switchComponent/SwitchButton";
import MuiDatePicker from "../../../components/datePickerComponent/MuidatePicker";
import DateRangePickerFormik from "../../../components/dateRangePickerComponent/DateRangePickerFormik";
import { format } from "date-fns";
import CategoryIcon from "@mui/icons-material/Category";
import { theme } from "../../../views/App";
import CategoryFilterDialog from "../ticketsListView/allTickets/filterPoppers/CategoryFilterDialog";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MuiTimePicker from "../../../components/dateTimePicker/MuiTimePicker";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    PriorityAction.REQUEST_PRIORITY_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    RolesAction.REQUEST_ROLES,
    UserAction.REQUEST_USER,
  ]),
  user: state.user.user || [],
  status: state.statusFilter.statusFilter.hits || [],
  automationFields: state.automationFields.automationFields || [],
  automationOperations: state.automationOperations.automationOperations || [],
  customticketfields_filter:
    state.customticketfields_filter.customticketfields_filter || {},
});

const AutoResponsePost = (props) => {
  const optionList = ["All Of These", "Any Of These"];

  const {
    editData,
    platformList,
    priority_list = [],
    RoleList = [],
    AgentList = [],
    tagList = [],
  } = props;

  const [tabLeftValue, setLeftTabValue] = React.useState("1");
  const [tabRightValue, setTabRightValue] = React.useState("1");
  const [numAll, setNumAll] = React.useState(1);
  const [numAny, setNumAny] = React.useState(1);
  const [booleanVal, setBooleanVal] = React.useState(false);
  const [openTab, setOpenTab] = React.useState([]);

  const [categorySelectOpen, setCategorySelectOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);

  useEffect(() => {
    let tabList = [];

    if (props.edit) {
      props.editData.conditions &&
        props.editData.conditions.allofthese?.length > 0 &&
        tabList.push("All Of These");
      props.editData.conditions &&
        props.editData.conditions.anyofthese?.length > 0 &&
        tabList.push("Any Of These");

      setOpenTab(tabList);

      if (props.editData) {
        props.editData.ai_bot ? setBooleanVal(true) : setBooleanVal(false);
      }

      if (props.editData) {
        props.editData.enabled ? setBooleanVal(true) : setBooleanVal(false);
      }
    } else {
      setOpenTab([]);
    }
  }, [props.edit && props.open]);

  const tabLeftHandleChange = (event, newValue) => {
    setLeftTabValue(newValue);
  };

  useEffect(() => {
    if (openTab[0] === "Any Of These") {
      setLeftTabValue("2");
    }
  }, [openTab]);

  const tabRightHandleChange = (event, newValue) => {
    setTabRightValue(newValue);
  };
  const conditionHandleChange = (event, value) => {
    value.forEach((item) => {
      if (item === "Any Of These") {
        setLeftTabValue("2");
      } else if (item === "All Of These") {
        setLeftTabValue("1");
      }
    });

    setOpenTab(value);

    // Check if "All Of These" is present in the array
    value.includes("All Of These") ? setNumAll(1) : setNumAll(0);

    // Check if "Any Of These" is present in the array
    value.includes("Any Of These") ? setNumAny(1) : setNumAny(0);
  };

  function ReturnOperandList(operandType) {
    if (operandType === "str")
      return props?.automationOperations?.string
        ?.flat()
        ?.map((obj) => Object.values(obj))
        ?.flat();
    if (operandType === "list")
      return props?.automationOperations?.array
        ?.flat()
        ?.map((obj) => Object.values(obj))
        ?.flat();
    if (operandType === "int")
      return props?.automationOperations?.integer
        ?.flat()
        ?.map((obj) => Object.values(obj))
        ?.flat();
    if (operandType === "boolean")
      return props?.automationOperations?.boolean
        ?.flat()
        ?.map((obj) => Object.values(obj))
        ?.flat();
    if (operandType === "datetime")
      return props?.automationOperations?.time
        ?.flat()
        ?.map((obj) => Object.values(obj))
        ?.flat();
    return [];
  }

  let status_list = [];

  props.status &&
    props.status.hits &&
    props.status.hits.map((val) => {
      status_list.push({
        id: val._id,
        name: val._source.status_name,
      });
    });

  let sentiment_list = [
    {
      id: "negative",
      name: "Negative",
      value: -0.01,
    },
    {
      id: "positive",
      name: "Positive",
      value: 0.01,
    },
    {
      id: "neutral",
      name: "Neutral",
      value: 0,
    },
  ];

  let automationFieldsList = [];
  if (props?.automationFields) {
    Object.keys(props?.automationFields)?.length > 0 &&
      Object.entries(props?.automationFields).map(([key, value]) => {
        let fields = Object.entries(value);

        fields.map(([item, field_type]) => {
          automationFieldsList.push({
            value: `${key}.${item}`,
            type: field_type,
          });
        });
      });
  }

  //activities list Assigning
  const returnActivityList = (platforms) => {
    let activityList = [];
    const activitiesData = JSON.parse(localStorage.getItem("activities"));
    if (activitiesData !== null) {
      if (activitiesData.hits.hits) {
        activitiesData.hits.hits.map((item) => {
          // if (platforms !== null) {
          //   if (
          //     platforms?.includes(item._source.platform.platform_name) //.platform_name
          //   ) {
          activityList.push({
            id: item._id,
            name: item._source.activities,
          });
          // }
          // }
        });
      }
    }

    return activityList;
  };

  function ReturnValues(item) {
    if (
      [
        "feed.created_on",
        "feed.modified_on",
        "ticket.created_on",
        "ticket.modified_on",
        "ticket.first_response_time",
      ].some((type) => item.key?.includes(type))
    ) {
      return item.value.map((date) => (date ? new Date(date) : null));
    }

    if (
      [
        "ticket.sentiment_value",
        "feed.sentiment_value",
        "ticket.manual_sentiment_value",
      ].some((type) => item.key?.includes(type))
    ) {
      return item.value.map((val) => {
        let obj = { value: item.key };
        let id = val && Object.hasOwn(val, "id") ? val.id : val;

        return ReturnValuesList(obj)?.filter((list) => list.value === id)?.[0];
      });
    }

    if (
      ["feed.keywords", "ticket.keywords"].some((type) =>
        item.key?.includes(type)
      )
    ) {
      return [item.value.join(",")];
    }

    if (
      [
        "settings.business_hours",
        "ticket.unassigned",
        "user.user_working_status",
      ].some((type) => item.key?.includes(type))
    ) {
      return item.value;
    }

    if (
      [
        "feed.activities",
        "ticket.activities",
        "feed.platform",
        "ticket.platform",
        "ticket.priority",
        "ticket.status",
        "feed.created_by",
        "feed.modified_by",
        "ticket.modified_by",
        "ticket.created_by",
        "ticket.tags",
      ].some((type) => item.key?.includes(type))
    ) {
      return item.value.map((val) => {
        let obj = { value: item.key };
        let id = val && Object.hasOwn(val, "id") ? val.id : val;

        return ReturnValuesList(obj)?.filter((list) => list.id === id)?.[0];
      });
    }

    if (["ticket.category"].some((type) => item.key?.includes(type))) {
      return item.value?.map((cat_id) => returnCustomValuesforChip(cat_id));
    }

    function returnCustomValuesforChip(sub_value_id) {
      let category_values =
        props.customticketfields_filter &&
        props.customticketfields_filter.hits &&
        props.customticketfields_filter.hits.hits?.filter(
          (item) => item._source.field_type === "category"
        )?.[0]?._source?.field_values;

      function ReturnCategoryName(nodes, id, names = []) {
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];

          names.push(nodes[i].value);

          if (node.id === id) {
            return node;
          } else if (node.sub_value && node.sub_value.length > 0) {
            const foundIndexes = ReturnCategoryName(node.sub_value, id, [
              ...names,
            ]);
            if (foundIndexes) {
              return foundIndexes;
            }
          }
          names.pop();
        }
        return null;
      }

      let custom_field_vals =
        category_values &&
        category_values.length !== 0 &&
        ReturnCategoryName(category_values, sub_value_id);
      return custom_field_vals;
    }
  }

  let alloftheseList = [];

  editData &&
    editData.conditions &&
    editData.conditions.allofthese &&
    editData.conditions.allofthese.map((item) => {
      alloftheseList.push({
        key: automationFieldsList?.filter(
          (list) => list.value === item.key
        )?.[0],
        operand: item.operand,
        value: ReturnValues(item),
      });
    });

  let anyoftheseList = [];

  editData &&
    editData.conditions &&
    editData.conditions.anyofthese &&
    editData.conditions.anyofthese.map((item) => {
      anyoftheseList.push({
        key: automationFieldsList?.filter(
          (list) => list.value === item.key
        )?.[0],
        operand: item.operand,
        value: ReturnValues(item),
      });
    });

  function AgentIdList(item) {
    return AgentList.filter((val) => item?.includes(val.id));
  }

  function RoleIdList(item) {
    return RoleList?.filter((val) => item?.includes(val.role_id));
  }

  function ReturnPriorityObj(priority_id) {
    return priority_list?.filter((item) => item.id === priority_id)?.[0];
  }

  function ReturnValuesList(source_type) {
    let list = [];

    if (source_type?.value?.includes("activities")) list = returnActivityList();
    if (source_type?.value?.includes("platform")) list = platformList;
    if (source_type?.value?.includes("sentiment_value")) list = sentiment_list;
    if (source_type?.value?.includes("priority")) list = priority_list;
    if (source_type?.value?.includes("status")) list = status_list;
    if (source_type?.value?.includes("tags")) list = tagList;

    if (
      source_type?.value?.includes("created_by") ||
      source_type?.value?.includes("modified_by") ||
      source_type?.value?.includes("assigned")
    )
      list = AgentList;

    return list;
  }

  function ReturnValuesId(source_type, source_value, operand) {
    let list = [];

    if (
      [
        "feed.activities",
        "ticket.activities",
        "feed.platform",
        "ticket.platform",
        "ticket.priority",
        "ticket.status",
        "feed.created_by",
        "feed.modified_by",
        "ticket.modified_by",
        "ticket.created_by",
        "ticket.category",
        "ticket.tags",
      ].some((type) => source_type?.value?.includes(type))
    )
      list = source_value.map((item) => item.id);

    if (
      ["feed.keywords", "ticket.keywords"].some((type) =>
        source_type?.value?.includes(type)
      )
    )
      list = source_value.filter((item) => item !== "");
    if (
      [
        "settings.business_hours",
        "ticket.unassigned",
        "user.user_working_status",
      ].some((type) => source_type?.value?.includes(type))
    )
      list = [...source_value];

    //  NEED TO CHECK
    if (
      [
        "ticket.sentiment_value",
        "feed.sentiment_value",
        "ticket.manual_sentiment_value",
      ].some((type) => source_type?.value?.includes(type))
    )
      list = source_value.map((item) => item.value);
    if (
      [
        "feed.created_on",
        "feed.modified_on",
        "ticket.created_on",
        "ticket.modified_on",
        "ticket.first_response_time",
        "ticket.first_ticket_assigned_date_time",
      ].some((type) => source_type?.value?.includes(type))
    ) {
      if (operand === "between") {
        list = source_value?.map((item) => (item?.$d ? item?.$d : item));
      } else if (operand === "ago") {
        list = [source_value[0]?.$m ? source_value[0]?.$m : source_value[0]];
      } else {
        list = [source_value[0]?.$d ? source_value[0]?.$d : source_value[0]];
      }
    }

    return list;
  }

  // initial values
  let initialValues = {};

  if (props.edit) {
    initialValues = {
      rulename: editData.automation_name,
      delay: editData?.auto_response?.delay ?? null,
      allofthese: alloftheseList,
      anyofthese: anyoftheseList,
      ai_bot: editData?.auto_response.ai_bot,
      enabled: editData?.enabled,

      auto_response_enabled: editData?.actions?.includes("auto_response"),
      auto_response: {
        ai_bot: editData?.auto_response?.ai_bot,
        delay: editData?.auto_response?.delay,
        response_message: editData?.auto_response?.response_message,
        replay_type: editData?.auto_response?.replay_type,
      },

      notification_enabled: editData?.actions?.includes("notification"),
      notification: {
        notification_message: editData?.notification?.notification_message,
        assigned_to: editData?.notification?.assigned_to,
        entity: editData?.notification?.entity
          ? Object.keys(editData?.notification?.entity)?.[0]
          : null,
        entity_values: editData?.notification?.entity
          ? Object.keys(editData?.notification?.entity)?.[0] === "role"
            ? RoleIdList(Object.values(editData?.notification?.entity)?.[0])
            : Object.keys(editData?.notification?.entity)?.[0] === "agents"
            ? AgentIdList(Object.values(editData?.notification?.entity)?.[0])
            : Object.values(editData?.notification?.entity)?.[0]
          : [],
      },

      mail_trigger_enabled: editData?.actions?.includes("mail_trigger"),
      mail_trigger: editData?.mail_trigger,

      auto_assignment_enabled: editData?.actions?.includes("auto_assignment"),
      auto_assignment: {
        agent_filter: editData?.auto_assignment?.agent_filter,
        assign_to_role: editData?.auto_assignment?.assign_to_role,
        agent_id: AgentIdList(editData?.auto_assignment?.agent_id),
        role_id: RoleIdList(editData?.auto_assignment?.role_id),
        negetive_priority: ReturnPriorityObj(
          editData?.auto_assignment?.set_priority?.negative
        ),
        neutral_priority: ReturnPriorityObj(
          editData?.auto_assignment?.set_priority?.neutral
        ),
        positive_priority: ReturnPriorityObj(
          editData?.auto_assignment?.set_priority?.positive
        ),
      },

      response_message: editData?.auto_response?.response_message ?? [""],
      notification_message: editData?.notification?.notification_message ?? "",
      agents: editData?.notification?.entity?.agents ?? [],
      cron_job: editData?.cron_job,
    };
  } else {
    initialValues = {
      rulename: "",
      allofthese: [
        {
          key: "",
          operand: "",
          value: [],
        },
      ],
      anyofthese: [
        {
          key: "",
          operand: "",
          value: [],
        },
      ],
      enabled: true,
      agents: [],
      auto_response_enabled: true,
      notification_enabled: false,
      mail_trigger_enabled: false,
      auto_assignment_enabled: false,
      auto_response: {
        ai_bot: false,
        delay: "",
        response_message: [""],
        replay_type: "",
      },
      notification: {
        notification_message: "",
        assigned_to: false,
        entity: null,
        entity_values: [],
      },
      mail_trigger: {
        mail_sub: "",
        mail_body: "",
        recipient_email: "",
        cc: [""],
      },
      auto_assignment: {
        agent_filter: "",
        assign_to_role: false,
        agent_id: [],
        role_id: [],
        negetive_priority: "",
        neutral_priority: "",
        positive_priority: "",
      },
      cron_job: null,
    };
  }

  function Required() {
    return (
      <>
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  const [loading, setLoading] = useState(false);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          let errors = {};

          openTab.includes("All Of These") &&
            values.allofthese.map((item, index) => {
              if (!item.key) {
                errors[`allofthese.${index}.key`] = <Required />;
              }

              if (!item.operand) {
                errors[`allofthese.${index}.operand`] = <Required />;
              }

              if (!item.value?.length > 0) {
                errors[`allofthese.${index}.value`] = <Required />;
              }
            });

          openTab.includes("Any Of These") &&
            values.anyofthese.map((item, index) => {
              if (!item.key) {
                errors[`anyofthese.${index}.key`] = <Required />;
              }

              if (!item.operand) {
                errors[`anyofthese.${index}.operand`] = <Required />;
              }

              if (!item.value?.length > 0) {
                errors[`anyofthese.${index}.value`] = <Required />;
              }
            });

          if (!values.rulename) {
            errors.rulename = <Required />;
          }

          return errors;
        }}
        onSubmit={async (values) => {
          setLoading(true);

          let alloftheseList = [];

          values.allofthese.map((item) => {
            return alloftheseList.push({
              key: item.key?.value,
              operand: item.operand,
              value: ReturnValuesId(item.key, item.value, item.operand),
            });
          });

          let anyoftheseList = [];

          values.anyofthese.map((item) => {
            return anyoftheseList.push({
              key: item.key?.value,
              operand: item.operand,
              value: ReturnValuesId(item.key, item.value, item.operand),
            });
          });

          let conditions = {};

          const actions = [];

          if (values?.auto_response_enabled) {
            actions?.push("auto_response");
          }

          if (values?.notification_enabled) {
            actions?.push("notification");
          }

          if (values?.mail_trigger_enabled) {
            actions?.push("mail_trigger");
          }

          if (values?.auto_assignment_enabled) {
            actions?.push("auto_assignment");
          }

          Object.assign(
            conditions,
            openTab.includes("All Of These") && { allofthese: alloftheseList },
            openTab.includes("Any Of These") && { anyofthese: anyoftheseList }
          );

          const cronValue = values.cron_job ? values.cron_job : "";

          // Find the index of the first space
          const firstSpaceIndex = cronValue.indexOf(" ");

          // Find the index of the last space
          const lastSpaceIndex = cronValue.lastIndexOf(" ");

          // Remove the character before the first space
          const modifiedCronValue = cronValue.substring(
            firstSpaceIndex + 1,
            lastSpaceIndex
          );
          const cronExpression = modifiedCronValue.replace("?", "*");
          console.log("gfks", cronExpression);
          let postObject = {
            automation_name: values.rulename,
            conditions: conditions,
            actions: actions,
            cron_job: modifiedCronValue,
          };

          const auto_response = {
            ai_bot: values.auto_response?.ai_bot,
            delay: values?.auto_response?.delay
              ? Number(values?.auto_response.delay)
              : null,
            response_message: values?.auto_response.response_message,
            replay_type: values?.auto_response?.replay_type,
          };

          const notification = {
            notification_message: values?.notification?.notification_message,
            assigned_to: values?.notification?.assigned_to,
          };

          let LastWorkingProject = JSON.parse(
            localStorage.getItem("user_last_working_project")
          );

          Object.assign(
            notification,
            values.notification?.entity === "organization" && {
              entity: {
                organization: [LastWorkingProject?.organization_id],
              },
            },
            values.notification?.entity === "project" && {
              entity: {
                project: [LastWorkingProject?.project_id],
              },
            },
            values.notification?.entity === "role" && {
              entity: {
                role: values?.notification?.entity_values?.map(
                  (item) => item.role_id
                ),
              },
            },
            values.notification?.entity === "agents" && {
              entity: {
                agents: values?.notification?.entity_values?.map(
                  (item) => item.id
                ),
              },
            }
          );

          const auto_assignment = {
            agent_filter: values?.auto_assignment?.agent_filter,
            assign_to_role: values?.auto_assignment?.assign_to_role,
            agent_id: values?.auto_assignment?.agent_id?.map(
              (agent) => agent?.id
            ),
            role_id: values?.auto_assignment?.role_id?.map(
              (role) => role?.role_id
            ),
            set_priority: {
              negative: values?.auto_assignment?.negetive_priority?.id,
              neutral: values?.auto_assignment?.neutral_priority?.id,
              positive: values?.auto_assignment?.positive_priority?.id,
            },
          };

          Object.assign(
            postObject,
            { enabled: values.enabled },
            values?.auto_response_enabled && { auto_response: auto_response },
            values?.notification_enabled && { notification: notification },
            values?.mail_trigger_enabled && {
              mail_trigger: values.mail_trigger,
            },
            values?.auto_assignment_enabled && {
              auto_assignment: auto_assignment,
            }
          );

          let page_limit = 15;

          const params = {
            order_by: `[["order_number","asc"]]`,
            page_limit: `${page_limit}`,
          };

          const param = {
            order_by: `[["automation_name.keyword", "asc"]]`,
            page_limit: "none",
          };

          let id = editData && editData.id;

          if (!props.edit) {
            props
              .dispatch(AutoresponseAction.requestPostAutoresponse(postObject))
              .then(() => {
                props.dispatch(AutoresponseAction.requestAutorespons(params));

                props.dispatch(
                  AutoresponseAction.requestAutoresponsFilter(param)
                );

                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(
                AutoresponseAction.requestPutAutorespons(postObject, id)
              )
              .then(() => {
                props.dispatch(AutoresponseAction.requestAutorespons(params));

                props.dispatch(
                  AutoresponseAction.requestAutoresponsFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
        }) => (
          <Form>
            <Grid sx={{ p: 2 }}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <ArrowCircleLeftIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid
                  container
                  item
                  xl={11}
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  alignItems="center"
                  sx={
                    {
                      // backgroundColor: "#d3e2e6",
                    }
                  }
                >
                  <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
                    <h6>Automation</h6>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                mt={1}
                justifyContent="flex-end"
              >
                <Grid
                  display="flex"
                  spacing={2}
                  gap={2}
                  sx={{ marginRight: 2 }}
                >
                  <Grid item lg={3} md={3} sm={3} minWidth={150}>
                    <MuiButton
                      name={"Save"}
                      loading={loading}
                      size="small"
                      width="100%"
                      type={"submit"}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} minWidth={150}>
                    {props.edit ? (
                      <MuiButton
                        name={"Reset"}
                        type={"reset"}
                        size="small"
                        width="100%"
                        // onClick={() => {
                        //   props.onClose();
                        // }}
                      />
                    ) : (
                      <MuiButton
                        name={"Reset"}
                        type={"reset"}
                        size="small"
                        width="100%"
                        onClick={() => {
                          setOpenTab([]);
                          setTabRightValue("1");
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container lg={6} md={6} sm={12}>
                <Grid
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ alignItems: "center" }}
                >
                  <Grid item lg={2.5} md={4} sm={5}>
                    <h6>Automation Name * :</h6>
                  </Grid>
                  <Grid item lg={9.5} md={8} sm={7}>
                    <Field
                      variant="outlined"
                      id="rulename"
                      as={MuiTextField}
                      autoFocus
                      size="small"
                      name="rulename"
                      placeholder="Automation Name"
                      error={Boolean(
                        getIn(touched, "rulename") && getIn(errors, "rulename")
                      )}
                      helperText={
                        getIn(touched, "rulename") && getIn(errors, "rulename")
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container lg={12} md={12} sm={12} mt={1}>
                <Grid
                  container
                  lg={6}
                  md={6}
                  sm={6}
                  sx={{ alignItems: "center" }}
                >
                  <Grid item lg={2.5} md={4} sm={5}>
                    <h6>Condition </h6>
                  </Grid>

                  <Grid item lg={9.5} md={8} sm={7}>
                    <AutoCompleteComponent
                      value={openTab}
                      handleChange={conditionHandleChange}
                      list={optionList}
                      textFieldProps={{
                        placeholder:
                          openTab && openTab.length === 0
                            ? "Select Option"
                            : null,
                      }}
                      size="small"
                      width="100%"
                      multiple={true}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  lg={6}
                  md={6}
                  sm={6}
                  sx={{ alignItems: "center" }}
                >
                  <Grid item lg={2.5} md={4} sm={5}></Grid>

                  <Grid
                    item
                    lg={9.5}
                    md={8}
                    sm={7}
                    justifyContent={"flex-end"}
                    container
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.auto_response_enabled}
                          onChange={(event) => {
                            handleChange(event);
                            setTabRightValue("1");
                          }}
                        />
                      }
                      name="auto_response_enabled"
                      label="Auto Response"
                      value={values.auto_response_enabled}
                    />

                    <FormControlLabel
                      label="Notification"
                      control={
                        <Checkbox
                          checked={values.notification_enabled}
                          onChange={(event) => {
                            handleChange(event);
                            setTabRightValue("2");
                          }}
                        />
                      }
                      name="notification_enabled"
                      value={values.notification_enabled}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.mail_trigger_enabled}
                          onChange={(event) => {
                            handleChange(event);
                            setTabRightValue("3");
                          }}
                        />
                      }
                      name="mail_trigger_enabled"
                      label="Mail Trigger"
                      value={values.mail_trigger_enabled}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.auto_assignment_enabled}
                          onChange={(event) => {
                            handleChange(event);
                            setTabRightValue("4");
                          }}
                        />
                      }
                      name="auto_assignment_enabled"
                      label="Auto Assignment"
                      value={values.auto_assignment_enabled}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                lg={6}
                md={6}
                sm={6}
                mt={1}
                justifyContent={"flex-start"}
              >
                <Grid item lg={2.5} md={4} sm={5} mt={2}>
                  <span>Automation Enabled</span>
                </Grid>

                <Grid item lg={9.5} md={8} sm={7} justifyContent={"flex-start"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.enabled}
                        onChange={handleChange}
                      />
                    }
                    // label="Has Parent Field"
                    name="enabled"
                    value={values.enabled}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                display="flex"
                mt={1}
                justifyContent={"space-between"}
                sx={{ paddingBottom: 10 }}
              >
                <Grid
                  container
                  item
                  lg={4.2}
                  md={12}
                  sm={12}
                  sx={{
                    backgroundColor: "#fff",
                    padding: 2,
                    borderRadius: 5,
                    border: "solid 0.5px #d3e2e6",
                    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                    height: 550,
                    paddingBottom: 10,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <TabContext value={tabRightValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "#d4edf5" }}>
                        <TabList
                          onChange={tabRightHandleChange}
                          aria-label="lab API tabs example"
                        >
                          {values?.auto_response_enabled && (
                            <Tab
                              sx={{ border: "none" }}
                              label="Auto Response"
                              value="1"
                            />
                          )}

                          {values?.notification_enabled && (
                            <Tab
                              sx={{ border: "none" }}
                              label="Notification"
                              value="2"
                            />
                          )}

                          {values?.mail_trigger_enabled && (
                            <Tab
                              sx={{ border: "none" }}
                              label="Mail Trigger"
                              value="3"
                            />
                          )}

                          {values?.auto_assignment_enabled && (
                            <Tab
                              sx={{ border: "none" }}
                              label="Auto Assignment"
                              value="4"
                            />
                          )}
                        </TabList>
                      </Box>

                      {values?.auto_response_enabled && (
                        <TabPanel
                          value="1"
                          sx={{
                            // backgroundColor: "white",
                            padding: "5px",
                            height: "auto",
                            width: "100%",
                          }}
                          className="p-0 m-0"
                        >
                          <Box
                            sx={{
                              height: 450,
                              overflow: "auto",
                              overscrollBehaviorY: "scroll",
                            }}
                            className="scrollable"
                          >
                            <Grid container px={1}>
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"flex-start"}
                              >
                                <Grid item lg={4} md={5} sm={5} mt={1}>
                                  <span>AI Bot</span>
                                </Grid>

                                <Grid item lg={1} md={2} sm={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values?.auto_response?.ai_bot}
                                        onClick={(event) => {
                                          if (event.target.checked) {
                                            setBooleanVal(true);
                                          } else {
                                            setBooleanVal(false);
                                          }
                                        }}
                                        onChange={handleChange}
                                      />
                                    }
                                    name={`auto_response.ai_bot`}
                                    value={values?.auto_response?.ai_bot}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Grid item lg={2} md={4} sm={4}>
                                  <span>Delay *</span>
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Field
                                      name="auto_response.delay"
                                      render={({ field, form }) => {
                                        const minutes = Math.floor(
                                          field.value / 60
                                        );
                                        const seconds = field.value % 60;
                                        const displayValue = dayjs(
                                          `${String(minutes).padStart(
                                            2,
                                            "0"
                                          )}:${String(seconds).padStart(
                                            2,
                                            "0"
                                          )}`,
                                          "mm:ss"
                                        );

                                        return (
                                          <TimeField
                                            {...field}
                                            format="mm:ss"
                                            value={displayValue}
                                            onChange={(newValue) => {
                                              if (newValue) {
                                                const [minutes, seconds] =
                                                  newValue
                                                    .format("mm:ss")
                                                    .split(":")
                                                    .map(Number);
                                                const totalSeconds =
                                                  minutes * 60 + seconds;
                                                form.setFieldValue(
                                                  "auto_response.delay",
                                                  String(totalSeconds)
                                                );
                                              } else {
                                                form.setFieldValue(
                                                  "auto_response.delay",
                                                  ""
                                                );
                                              }
                                            }}
                                            placeholder="Delay"
                                            size="small"
                                            error={Boolean(
                                              getIn(touched, "delay") &&
                                                getIn(errors, "delay")
                                            )}
                                            helperText={
                                              getIn(touched, "delay") &&
                                              getIn(errors, "delay")
                                            }
                                          />
                                        );
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>{" "}
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Grid item lg={2} md={4} sm={4}>
                                  <span>Reply Type</span>
                                </Grid>

                                <Grid item lg={8} md={8} sm={8}>
                                  <Field
                                    name={`auto_response.replay_type`}
                                    size="small"
                                    value={
                                      values.auto_response?.replay_type
                                        ? values.auto_response.replay_type
                                        : null
                                    }
                                    disableClearable={true}
                                    component={AutoCompleteMultiSelect}
                                    options={["DM", "ticket", "feed"]}
                                    getOptionLabel={(option) =>
                                      option?.replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      )
                                    }
                                    textFieldProps={{
                                      margin: "normal",
                                      variant: "outlined",
                                      placeholder: "Choose Reply Type",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Grid item lg={2} md={4} sm={4}>
                                  <span>Response Message </span>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  // className="bg-warning"
                                >
                                  <Tooltip
                                    title={"Message"}
                                    arrow
                                    placement="right-end"
                                  >
                                    <FieldArray
                                      name={`auto_response.response_message`}
                                    >
                                      {({ insert, remove, push }) => (
                                        <Grid
                                          container
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          mt={1}
                                          className="d-flex justify-content-center"
                                        >
                                          {values?.auto_response
                                            ?.response_message?.length > 0 &&
                                            values?.auto_response?.response_message?.map(
                                              (_, index) => (
                                                <Grid
                                                  container
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  justifyContent="space-between"
                                                  alignItems="center"
                                                  key={index}
                                                >
                                                  <Grid
                                                    item
                                                    xl={9}
                                                    lg={9}
                                                    md={9}
                                                    sm={9}
                                                    container
                                                  >
                                                    <Field
                                                      as={TextArea}
                                                      className={`${"textAreaFiled col-12"}`}
                                                      fieldheight="30px"
                                                      variant="outlined"
                                                      placeholder={
                                                        "Enter Here..."
                                                      }
                                                      id={`response_message`}
                                                      size="small"
                                                      style={{
                                                        width: "100%",
                                                        height: 80,
                                                      }}
                                                      name={`auto_response.response_message.${index}`}
                                                      error={
                                                        errors &&
                                                        Boolean(
                                                          errors.hasOwnProperty(
                                                            "response_message"
                                                          )
                                                        )
                                                      }
                                                    />
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    xl={1}
                                                    lg={1}
                                                    md={1}
                                                    sm={1}
                                                    container
                                                    mt={1}
                                                    className="d-flex justify-content-end"
                                                  >
                                                    <MuiButton
                                                      name={<DeleteIcon />}
                                                      onClick={() =>
                                                        remove(index)
                                                      }
                                                    />
                                                  </Grid>
                                                </Grid>
                                              )
                                            )}

                                          <Grid
                                            container
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            justifyContent="flex-end"
                                            mt={1}
                                          >
                                            <MuiButton
                                              onClick={() => push("")}
                                              name={<AddIcon />}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </FieldArray>
                                  </Tooltip>
                                </Grid>
                              </Grid>{" "}
                            </Grid>
                          </Box>
                        </TabPanel>
                      )}

                      {values?.notification_enabled && (
                        <TabPanel
                          value="2"
                          sx={{
                            // backgroundColor: "white",
                            padding: "5px",
                            height: "auto",
                            width: "100%",
                          }}
                          className="p-0 m-0"
                        >
                          <Box
                            sx={{
                              height: 450,
                              overflow: "auto",
                              overscrollBehaviorY: "scroll",
                            }}
                            className="scrollable"
                          >
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item lg={2} md={4} sm={4}>
                                <span>Notification Message </span>
                              </Grid>
                              <Grid
                                item
                                lg={8}
                                md={8}
                                sm={8}
                                // className="bg-warning"
                              >
                                <Tooltip
                                  title={"Notification Message"}
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={TextArea}
                                    className={`${"textAreaFiled col-12"}`}
                                    fieldheight="30px"
                                    variant="outlined"
                                    placeholder={"Enter Here..."}
                                    id={`notification.notification_message`}
                                    size="small"
                                    name={`notification.notification_message`}
                                    error={
                                      errors &&
                                      Boolean(
                                        errors.hasOwnProperty(
                                          `notification.notification_message`
                                        )
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item lg={2} md={4} sm={4}>
                                <span>Assigned Tickets</span>
                              </Grid>

                              <Grid item lg={8} md={8} sm={8}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.notification?.assigned_to}
                                      onChange={handleChange}
                                    />
                                  }
                                  name={`notification.assigned_to`}
                                  label=""
                                  value={values?.notification?.assigned_to}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item lg={2} md={4} sm={4}>
                                <span>Entity</span>
                              </Grid>

                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`notification.entity`}
                                  size="small"
                                  value={
                                    values.notification.entity
                                      ? values.notification.entity
                                      : null
                                  }
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  onChange={(_, value) => {
                                    setFieldValue(`notification.entity`, value);
                                    setFieldValue(
                                      `notification.entity_values`,
                                      []
                                    );
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )
                                  }
                                  options={[
                                    "organization",
                                    "project",
                                    "role",
                                    "agents",
                                  ]}
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Choose Entity",
                                  }}
                                />
                              </Grid>
                            </Grid>

                            {values.notification?.entity === "role" ? (
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Grid item lg={2} md={4} sm={4}>
                                  <span>Roles</span>
                                </Grid>

                                <Grid item lg={8} md={8} sm={8}>
                                  <Field
                                    limitTags={1}
                                    name={`notification.entity_values`}
                                    sx={{
                                      width: "100%",
                                      zIndex: 999,
                                    }}
                                    component={AutoCompleteMultiSelect}
                                    multiple
                                    value={
                                      values?.notification.entity_values
                                        ? values.notification.entity_values
                                        : []
                                    }
                                    variant="outlined"
                                    options={RoleList}
                                    disabled={
                                      RoleList && RoleList.length === 1
                                        ? true
                                        : false
                                    }
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{
                                            marginRight: 8,
                                          }}
                                          checked={selected}
                                        />
                                        {option.role_name}
                                      </li>
                                    )}
                                    textFieldProps={{
                                      placeholder: "Roles",
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.role_id === value.role_id
                                    }
                                    // inputRef={inputRef1}
                                    // sx={{ width: "240px" }}
                                    getOptionLabel={(option) =>
                                      option.role_name
                                    }
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            ) : null}

                            {values.notification?.entity === "agents" ? (
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Grid item lg={2} md={4} sm={4}>
                                  <span>Agents</span>
                                </Grid>

                                <Grid item lg={8} md={8} sm={8}>
                                  <Field
                                    name={`notification.entity_values`}
                                    sx={{
                                      width: "100%",
                                      zIndex: 999,
                                    }}
                                    component={AutoCompleteMultiSelect}
                                    limitTags={1}
                                    multiple
                                    value={
                                      values?.notification.entity_values
                                        ? values.notification.entity_values
                                        : []
                                    }
                                    variant="outlined"
                                    options={AgentList}
                                    disabled={
                                      AgentList && AgentList.length === 1
                                        ? true
                                        : false
                                    }
                                    textFieldProps={{
                                      placeholder: "User",
                                    }}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{
                                            marginRight: 8,
                                          }}
                                          checked={selected}
                                        />
                                        {option.name}
                                      </li>
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    filterOptions={createFilterOptions({
                                      matchFrom: "start",
                                      stringify: (option) => option.name,
                                    })}
                                    getOptionLabel={(option) => option.name}
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            ) : null}
                          </Box>
                        </TabPanel>
                      )}

                      {values?.mail_trigger_enabled && (
                        <TabPanel
                          value="3"
                          sx={{
                            // backgroundColor: "white",
                            padding: "5px",
                            height: "auto",
                            width: "100%",
                          }}
                          className="p-0 m-0"
                        >
                          <Box
                            sx={{
                              height: 450,
                              overflow: "auto",
                              overscrollBehaviorY: "scroll",
                            }}
                            className="scrollable"
                          >
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Grid item lg={2} md={4} sm={4}>
                                <span>Recipient Mail</span>
                              </Grid>
                              <Grid
                                item
                                lg={8}
                                md={8}
                                sm={8}
                                // className="bg-warning"
                              >
                                <Tooltip
                                  title={"Recipient Email Address"}
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    variant="outlined"
                                    id="rulename"
                                    as={MuiTextField}
                                    autoFocus
                                    size="small"
                                    name={`mail_trigger.recipient_email`}
                                    placeholder="Recipient Mail"
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4}>
                                <span>Cc</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title={"Cc"}
                                  arrow
                                  placement="right-end"
                                >
                                  <FieldArray name={`mail_trigger.cc`}>
                                    {({ insert, remove, push }) => (
                                      <Grid
                                        container
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        rowGap={1}
                                        className="d-flex justify-content-center"
                                        // sx={{
                                        //   height: "100px",
                                        // }}
                                      >
                                        {values?.mail_trigger?.cc?.length > 0 &&
                                          values?.mail_trigger?.cc?.map(
                                            (mail, index) => (
                                              <Grid
                                                container
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                justifyContent="space-between"
                                                alignItems="center"
                                                key={index}
                                              >
                                                <Grid
                                                  item
                                                  xl={9}
                                                  lg={9}
                                                  md={9}
                                                  sm={9}
                                                  container
                                                >
                                                  <Field
                                                    variant="outlined"
                                                    id="rulename"
                                                    as={MuiTextField}
                                                    autoFocus
                                                    size="small"
                                                    name={`mail_trigger.cc[${index}]`}
                                                    placeholder="Enter mail address"
                                                  />
                                                </Grid>

                                                <Grid
                                                  item
                                                  xl={1}
                                                  lg={1}
                                                  md={1}
                                                  sm={1}
                                                  container
                                                  mt={1}
                                                  className="d-flex justify-content-end"
                                                >
                                                  <MuiButton
                                                    name={<DeleteIcon />}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  />
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        <Grid
                                          container
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          justifyContent="flex-end"
                                          mt={1}
                                        >
                                          <MuiButton
                                            onClick={() => push("")}
                                            name={<AddIcon />}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </FieldArray>
                                </Tooltip>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4}>
                                <span>Subject</span>
                              </Grid>
                              <Grid
                                item
                                lg={8}
                                md={8}
                                sm={8}
                                // className="bg-warning"
                              >
                                <Tooltip
                                  title={"Mail Subject"}
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={TextArea}
                                    className={`${"textAreaFiled col-12"}`}
                                    fieldheight="30px"
                                    variant="outlined"
                                    placeholder={"Enter Subject"}
                                    id={`mail_trigger_subject`}
                                    size="small"
                                    name={`mail_trigger.mail_sub`}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4}>
                                <span>Body</span>
                              </Grid>
                              <Grid
                                item
                                lg={8}
                                md={8}
                                sm={8}
                                // className="bg-warning"
                              >
                                <Tooltip
                                  title={"Mail Body"}
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={TextArea}
                                    className={`${"textAreaFiled col-12"}`}
                                    fieldheight="30px"
                                    variant="outlined"
                                    placeholder={"Enter Message"}
                                    id={`mail_trigger_body`}
                                    size="small"
                                    name={`mail_trigger.mail_body`}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </TabPanel>
                      )}

                      {values?.auto_assignment && (
                        <TabPanel
                          value="4"
                          sx={{
                            padding: "5px",
                            height: "auto",
                            width: "100%",
                          }}
                          className="p-0 m-0"
                        >
                          <Box
                            sx={{
                              height: 450,
                              overflow: "auto",
                              overscrollBehaviorY: "scroll",
                            }}
                            className="scrollable"
                          >
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>User Status *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`auto_assignment.agent_filter`}
                                  size="small"
                                  limitTags={1}
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  options={["Online", "Offline", "all"]}
                                  getOptionLabel={(option) => option}
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "User Status",
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"flex-start"}
                            >
                              <Grid item lg={4} md={5} sm={5} mt={1}>
                                <span>Assigned to Role</span>
                              </Grid>

                              <Grid item lg={1} md={2} sm={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values.auto_assignment.assign_to_role
                                      }
                                      onClick={(event) => {
                                        if (event.target.checked) {
                                          setBooleanVal(true);
                                        } else {
                                          setBooleanVal(false);
                                        }
                                      }}
                                      onChange={handleChange}
                                    />
                                  }
                                  // label="Has Parent Field"
                                  name={`auto_assignment.assign_to_role`}
                                  value={values.auto_assignment?.assign_to_role}
                                />
                              </Grid>
                            </Grid>

                            {booleanVal && (
                              <Grid
                                container
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                mt={1}
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={2} md={2} sm={2} mt={1}>
                                  <span>Roles {booleanVal ? `*` : null}</span>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  sx={{ position: "relative" }}
                                >
                                  <Field
                                    limitTags={1}
                                    name={`auto_assignment.role_id`}
                                    sx={{
                                      position: "absolute",
                                      width: "100%",
                                      zIndex: 999,
                                    }}
                                    component={AutoCompleteMultiSelect}
                                    multiple
                                    value={
                                      values?.auto_assignment.role_id
                                        ? values.auto_assignment.role_id
                                        : []
                                    }
                                    variant="outlined"
                                    options={RoleList}
                                    disabled={
                                      RoleList && RoleList.length === 1
                                        ? true
                                        : false
                                    }
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{
                                            marginRight: 8,
                                          }}
                                          checked={selected}
                                        />
                                        {option.role_name}
                                      </li>
                                    )}
                                    textFieldProps={{
                                      placeholder: "Roles",
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.role_id === value.role_id
                                    }
                                    // inputRef={inputRef1}
                                    // sx={{ width: "240px" }}
                                    getOptionLabel={(option) =>
                                      option.role_name
                                    }
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )}

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={3}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={2} sm={2} mt={1}>
                                <span>User {!booleanVal ? `*` : null}</span>
                              </Grid>
                              <Grid
                                item
                                lg={8}
                                md={8}
                                sm={8}
                                style={{ position: "relative" }}
                              >
                                <Field
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: 999,
                                  }}
                                  name={`auto_assignment.agent_id`}
                                  component={AutoCompleteMultiSelect}
                                  limitTags={1}
                                  multiple
                                  value={
                                    values.auto_assignment.agent_id
                                      ? values.auto_assignment.agent_id
                                      : []
                                  }
                                  variant="outlined"
                                  options={AgentList}
                                  disabled={
                                    AgentList && AgentList.length === 1
                                      ? true
                                      : false
                                  }
                                  textFieldProps={{
                                    placeholder: "User",
                                  }}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{
                                          marginRight: 8,
                                        }}
                                        checked={selected}
                                      />
                                      {option.name}
                                    </li>
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  filterOptions={createFilterOptions({
                                    matchFrom: "start",
                                    stringify: (option) => option.name,
                                  })}
                                  getOptionLabel={(option) => option.name}
                                  size="small"
                                />
                                <br />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={2}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>Negative *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`auto_assignment.negetive_priority`}
                                  size="small"
                                  value={
                                    values.auto_assignment.negetive_priority
                                      ? values.auto_assignment.negetive_priority
                                      : null
                                  }
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  options={priority_list}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.name === value.name
                                  }
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Negative Priority",
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>Neutral *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`auto_assignment.neutral_priority`}
                                  size="small"
                                  value={
                                    values.auto_assignment.neutral_priority
                                      ? values.auto_assignment.neutral_priority
                                      : null
                                  }
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  options={priority_list}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.name === value.name
                                  }
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Neutral Priority",
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              mt={1}
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={2} md={4} sm={4} mt={3}>
                                <span>Positive *</span>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`auto_assignment.positive_priority`}
                                  size="small"
                                  value={
                                    values.auto_assignment.positive_priority
                                      ? values.auto_assignment.positive_priority
                                      : null
                                  }
                                  disableClearable={true}
                                  component={AutoCompleteMultiSelect}
                                  options={priority_list}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.name === value.name
                                  }
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Positive Priority",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </TabPanel>
                      )}
                    </TabContext>
                  </Box>
                </Grid>

                <Grid
                  container
                  item
                  lg={7.5}
                  md={12}
                  sm={12}
                  scroll
                  sx={{
                    // backgroundColor: "#fff",
                    padding: 2,
                    borderRadius: 5,
                    border: "solid 0.5px #d3e2e6",
                    boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <TabContext value={tabLeftValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "#d4edf5" }}>
                        <TabList
                          onChange={tabLeftHandleChange}
                          aria-label="lab API tabs example"
                        >
                          {openTab && openTab.includes("All Of These") && (
                            <Tab
                              style={{
                                border: Object.keys(errors).some((key) =>
                                  key.startsWith("allofthese")
                                )
                                  ? `2px solid ${theme.palette.text.error}`
                                  : "2px solid transparent", // Transparent border for consistent layout
                                borderRadius: "4px", // Rounded corners for a cleaner look
                                padding: "4px", // Optional padding to enhance the appearance
                                transition: "border-color 0.3s ease", // Smooth transition for better UX
                              }}
                              label="All Of These"
                              value="1"
                            />
                          )}
                          {openTab && openTab.includes("Any Of These") && (
                            <Tab
                              style={{
                                border: Object.keys(errors).some((key) =>
                                  key.startsWith("anyofthese")
                                )
                                  ? `2px solid ${theme.palette.text.error}`
                                  : "2px solid transparent", // Transparent border for consistent layout
                                borderRadius: "4px", // Rounded corners for a cleaner look
                                padding: "4px", // Optional padding to enhance the appearance
                                transition: "border-color 0.3s ease", // Smooth transition for better UX
                              }}
                              label="Any Of These"
                              value="2"
                            />
                          )}
                        </TabList>
                      </Box>
                      {openTab?.map((item) => {
                        let tabPanel;
                        let formikValuesField;
                        if (item === "All Of These") {
                          tabPanel = "1";
                          formikValuesField = "allofthese";
                        }

                        if (item === "Any Of These") {
                          tabPanel = "2";
                          formikValuesField = "anyofthese";
                        }

                        return (
                          <TabPanel
                            value={tabPanel}
                            sx={{
                              // backgroundColor: "white",
                              padding: "5px",
                              height: "auto",
                              width: "100%",
                            }}
                          >
                            <FieldArray name={formikValuesField}>
                              {({ insert, remove, push }) => (
                                <Grid
                                  container
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  mt={1}
                                  className="d-flex justify-content-center"
                                >
                                  {values[formikValuesField] &&
                                    values[formikValuesField].length > 0 &&
                                    values[formikValuesField].map(
                                      (field, index) => {
                                        let autocompleteFields = [
                                          "activities",
                                          "platform",
                                          "status",
                                          "priority",
                                          "sentiment_value",
                                          "manual_sentiment_value",
                                          "tags",
                                          "created_by",
                                          "modified_by",
                                          "assigned",
                                        ];
                                        let dateFields = [
                                          "created_on",
                                          "modified_on",
                                          "first_response_time",
                                          "first_ticket_assigned_date_time",
                                        ];
                                        let textFields = ["keywords"];
                                        let toggleFields = [
                                          "business_hours",
                                          "unassigned",
                                          "user_working_status",
                                        ];
                                        let treeField = ["category"];

                                        function RenderField() {
                                          if (
                                            autocompleteFields.some(
                                              (keyword) => {
                                                const word =
                                                  values[formikValuesField][
                                                    index
                                                  ]?.key?.value?.split(".")[1];
                                                return word === keyword;
                                              }
                                            )
                                          ) {
                                            return (
                                              <Field
                                                limitTags={1}
                                                name={`${formikValuesField}.${index}.value`}
                                                size="small"
                                                // disableClearable={true}
                                                multiple
                                                value={
                                                  values[formikValuesField][
                                                    index
                                                  ].value
                                                    ? values[formikValuesField][
                                                        index
                                                      ].value
                                                    : []
                                                }
                                                disableCloseOnSelect={true}
                                                component={
                                                  AutoCompleteMultiSelect
                                                }
                                                options={ReturnValuesList(
                                                  values[formikValuesField][
                                                    index
                                                  ]?.key
                                                )}
                                                renderOption={(
                                                  props,
                                                  option,
                                                  { selected }
                                                ) => (
                                                  <li {...props}>
                                                    <Checkbox
                                                      icon={icon}
                                                      checkedIcon={checkedIcon}
                                                      style={{
                                                        marginRight: 8,
                                                      }}
                                                      checked={selected}
                                                    />
                                                    {option.name}
                                                  </li>
                                                )}
                                                isOptionEqualToValue={(
                                                  option,
                                                  value
                                                ) =>
                                                  //setting value property to custom value
                                                  values[formikValuesField][
                                                    index
                                                  ].key?.value?.includes(
                                                    "status"
                                                  )
                                                    ? option.id === value.id
                                                    : option?.name ===
                                                      value?.name
                                                }
                                                getOptionLabel={(option) =>
                                                  option?.name
                                                    ? option?.name
                                                    : option
                                                }
                                                textFieldProps={{
                                                  margin: "normal",
                                                  variant: "outlined",
                                                  // placeholder: "Values",
                                                  // zIndex: 999,
                                                  placeholder:
                                                    values[formikValuesField][
                                                      index
                                                    ].value &&
                                                    !values[formikValuesField][
                                                      index
                                                    ].value.length
                                                      ? "Value"
                                                      : null,
                                                }}
                                              />
                                            );
                                          } else if (
                                            dateFields.some((keyword) => {
                                              const word =
                                                values[formikValuesField][
                                                  index
                                                ]?.key?.value?.split(".")[1];
                                              return word === keyword;
                                            })
                                          ) {
                                            if (
                                              values[formikValuesField][index]
                                                ?.operand === "between"
                                            ) {
                                              return (
                                                <Field
                                                  component={
                                                    DateRangePickerFormik
                                                  }
                                                  size={"small"}
                                                  value={
                                                    values[formikValuesField][
                                                      index
                                                    ]?.value
                                                  }
                                                  name={`${formikValuesField}.${index}.value`}
                                                  label={false}
                                                  textFieldProps={{
                                                    fullWidth: true,
                                                    margin: "normal",
                                                    variant: "outlined",
                                                  }}
                                                />
                                              );
                                            } else if (
                                              values[formikValuesField][index]
                                                ?.operand === "ago"
                                            ) {
                                              return (
                                                <Field
                                                  value={values.eta}
                                                  name={`${formikValuesField}.${index}.value[0]`}
                                                  component={MuiTimePicker}
                                                  views={["minutes"]}
                                                  format={"mm"}
                                                  width="100%"
                                                  size="small"
                                                />
                                              );
                                            } else {
                                              return (
                                                <Field
                                                  component={MuiDatePicker}
                                                  size="small"
                                                  value={
                                                    values[formikValuesField][
                                                      index
                                                    ]?.value?.[0]
                                                  }
                                                  disablePast={false}
                                                  format="DD/MM/YYYY"
                                                  name={`${formikValuesField}.${index}.value[0]`}
                                                  textFieldProps={{
                                                    fullWidth: true,
                                                    margin: "normal",
                                                    variant: "outlined",
                                                  }}
                                                />
                                              );
                                            }
                                          } else if (
                                            textFields.some((keyword) => {
                                              const word =
                                                values[formikValuesField][
                                                  index
                                                ]?.key?.value?.split(".")[1];
                                              return word === keyword;
                                            })
                                          ) {
                                            return (
                                              <Field
                                                as={TextArea}
                                                variant="outlined"
                                                placeholder={
                                                  "Comma separated values"
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: 80,
                                                }}
                                                size="small"
                                                name={`${formikValuesField}.${index}.value`}
                                                onChange={(e) => {
                                                  const { value } = e.target;

                                                  setFieldValue(
                                                    `${formikValuesField}.${index}.value`,
                                                    value?.split(",")
                                                  );
                                                }}
                                              />
                                            );
                                          } else if (
                                            toggleFields.some((keyword) => {
                                              const word =
                                                values[formikValuesField][
                                                  index
                                                ]?.key?.value?.split(".")[1];
                                              return word === keyword;
                                            })
                                          ) {
                                            return (
                                              <Field
                                                type="checkbox"
                                                name={`${formikValuesField}.${index}.value[0]`}
                                                component={SwitchButton}
                                                checked={
                                                  values[formikValuesField][
                                                    index
                                                  ]?.value?.[0]
                                                }
                                              />
                                            );
                                          } else if (
                                            treeField.some((keyword) => {
                                              const word =
                                                values[formikValuesField][
                                                  index
                                                ]?.key?.value?.split(".")[1];
                                              return word === keyword;
                                            })
                                          ) {
                                            return (
                                              <Box sx={{ display: "flex" }}>
                                                <Box
                                                  sx={{
                                                    width: "100%",
                                                    flexWrap: "wrap",
                                                  }}
                                                >
                                                  {values[formikValuesField]?.[
                                                    index
                                                  ]?.value?.length > 0 &&
                                                    values[formikValuesField]?.[
                                                      index
                                                    ]?.value?.map(
                                                      (item, newIndex) => (
                                                        <Chip
                                                          key={index}
                                                          label={item?.value}
                                                          onDelete={async () => {
                                                            let updatedSubValues =
                                                              [
                                                                ...values[
                                                                  formikValuesField
                                                                ]?.[index]
                                                                  ?.value,
                                                              ];

                                                            updatedSubValues.splice(
                                                              newIndex,
                                                              1
                                                            );
                                                            if (
                                                              updatedSubValues ==
                                                                [] ||
                                                              updatedSubValues.length ===
                                                                0
                                                            )
                                                              updatedSubValues =
                                                                [];

                                                            setFieldValue(
                                                              `${formikValuesField}.${index}.value`,
                                                              updatedSubValues
                                                            );
                                                          }}
                                                          variant="outlined"
                                                          size="small"
                                                        />
                                                      )
                                                    )}
                                                </Box>
                                                <IconButton
                                                  onClick={() => {
                                                    setCategorySelectOpen(true);
                                                    setCategorySelected({
                                                      field: `${formikValuesField}.${index}.value`,
                                                      value:
                                                        values[
                                                          formikValuesField
                                                        ]?.[index]?.value,
                                                    });
                                                  }}
                                                >
                                                  <CategoryIcon
                                                    sx={{
                                                      color:
                                                        theme.palette.primary
                                                          .main,
                                                      fontSize: 10,
                                                    }}
                                                  />
                                                </IconButton>
                                              </Box>
                                            );
                                          } else {
                                            return null; // Default or unknown field type
                                          }
                                        }

                                        return (
                                          <Grid
                                            container
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt={1}
                                            key={index}
                                          >
                                            <Grid
                                              item
                                              xl={3}
                                              lg={3}
                                              md={3}
                                              sm={3}
                                              container
                                            >
                                              <Grid
                                                item
                                                lg={3.5}
                                                md={3.5}
                                                sm={3.5}
                                                mt={3}
                                              >
                                                Source * :
                                              </Grid>
                                              <Grid item lg={8} md={8} sm={8}>
                                                <Field
                                                  name={`${formikValuesField}.${index}.key`}
                                                  size="small"
                                                  disableClearable={true}
                                                  component={
                                                    AutoCompleteMultiSelect
                                                  }
                                                  options={automationFieldsList}
                                                  value={
                                                    values[formikValuesField][
                                                      index
                                                    ].key
                                                      ? values[
                                                          formikValuesField
                                                        ][index].key
                                                      : null
                                                  }
                                                  onChange={(_, value) => {
                                                    setFieldValue(
                                                      `${formikValuesField}.${index}.key`,
                                                      value
                                                    );

                                                    setFieldValue(
                                                      `${formikValuesField}.${index}.operand`,
                                                      ""
                                                    );
                                                    setFieldValue(
                                                      `${formikValuesField}.${index}.value`,

                                                      toggleFields.some(
                                                        (keyword) =>
                                                          value?.value?.includes(
                                                            keyword
                                                          )
                                                      )
                                                        ? [false]
                                                        : []
                                                    );
                                                  }}
                                                  getOptionLabel={
                                                    (option) =>
                                                      option.value &&
                                                      option.value
                                                        .replace(/\./g, " > ") // Replace '.' with ' > '
                                                        .replace(/_/g, " ") // Replace '_' with a space
                                                        .replace(
                                                          /\b\w/g,
                                                          (char) =>
                                                            char.toUpperCase()
                                                        ) // Capitalize each word
                                                  }
                                                  isOptionEqualToValue={(
                                                    option,
                                                    value
                                                  ) =>
                                                    option.value === value.value
                                                  }
                                                  textFieldProps={{
                                                    margin: "normal",
                                                    variant: "outlined",
                                                    placeholder: "Source",
                                                  }}
                                                />
                                                {errors[
                                                  `${formikValuesField}.${index}.key`
                                                ] && (
                                                  <span
                                                    style={{
                                                      position: "absolute",
                                                    }}
                                                  >
                                                    {
                                                      errors[
                                                        `${formikValuesField}.${index}.key`
                                                      ]
                                                    }
                                                  </span>
                                                )}
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xl={3}
                                              lg={3}
                                              md={3}
                                              sm={3}
                                              container
                                            >
                                              <Grid
                                                item
                                                lg={3.5}
                                                md={3.5}
                                                sm={3.5}
                                                mt={3.5}
                                              >
                                                Operand * :
                                              </Grid>
                                              <Grid item lg={8} md={8} sm={8}>
                                                <Field
                                                  name={`${formikValuesField}.${index}.operand`}
                                                  size="small"
                                                  disableClearable={true}
                                                  component={
                                                    AutoCompleteMultiSelect
                                                  }
                                                  onChange={(_, value) => {
                                                    setFieldValue(
                                                      `${formikValuesField}.${index}.operand`,
                                                      value
                                                    );
                                                  }}
                                                  options={ReturnOperandList(
                                                    values[formikValuesField][
                                                      index
                                                    ]?.key?.type
                                                  )}
                                                  getOptionLabel={(option) =>
                                                    option
                                                  }
                                                  textFieldProps={{
                                                    margin: "normal",
                                                    variant: "outlined",
                                                    placeholder: "Operand",
                                                  }}
                                                />
                                                {errors[
                                                  `${formikValuesField}.${index}.operand`
                                                ] && (
                                                  <span
                                                    style={{
                                                      position: "absolute",
                                                    }}
                                                  >
                                                    {
                                                      errors[
                                                        `${formikValuesField}.${index}.operand`
                                                      ]
                                                    }
                                                  </span>
                                                )}
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xl={4}
                                              lg={4}
                                              md={4}
                                              sm={4}
                                              container
                                            >
                                              {values[formikValuesField][index]
                                                ?.key ? (
                                                <Grid
                                                  item
                                                  lg={3}
                                                  md={3}
                                                  sm={3}
                                                  mt={3}
                                                >
                                                  Values * :
                                                </Grid>
                                              ) : null}

                                              <Grid item lg={8} md={8} sm={8}>
                                                {values[formikValuesField][
                                                  index
                                                ]?.key?.value
                                                  ? RenderField()
                                                  : null}
                                                {errors[
                                                  `${formikValuesField}.${index}.value`
                                                ] && (
                                                  <span
                                                    style={{
                                                      position: "absolute",
                                                    }}
                                                  >
                                                    {
                                                      errors[
                                                        `${formikValuesField}.${index}.value`
                                                      ]
                                                    }
                                                  </span>
                                                )}
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xl={1}
                                              lg={1}
                                              md={1}
                                              sm={1}
                                              container
                                              mt={1}
                                              className="d-flex justify-content-end"
                                            >
                                              <MuiButton
                                                name={<DeleteIcon />}
                                                onClick={() => remove(index)}
                                              />
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )}
                                  <Grid
                                    container
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    justifyContent="flex-end"
                                    mt={1}
                                  >
                                    <MuiButton
                                      onClick={() =>
                                        push({
                                          key: "",
                                          operand: "",
                                          value: [],
                                        })
                                      }
                                      name={<AddIcon />}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </FieldArray>
                          </TabPanel>
                        );
                      })}
                    </TabContext>
                  </Box>
                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  mt={1}
                  justifyContent={"flex-start"}
                >
                  <Grid item sm={12}>
                    <span>CRON</span>
                  </Grid>

                  <Grid item sm={12}>
                    <Field
                      component={CronGenerator}
                      name="cron_job"
                      values={values.cron_job}
                      onChange={(value) => setFieldValue("cron_job", value)}
                      // showResultText={true}
                      // showResultCron={true}
                      // value={values.cron}
                      // onBlur={handleBlur("cron_job")}
                      showTabs={false} // Hide the tabs for seconds and years
                      showQuartzCron={false} // Hide the Quartz cron expression
                      allowEmpty={false} // Don't allow empty cron value
                      cronProps={{
                        // Limit allowed fields to minutes, hours, day of the month, month, and day of the week
                        allowMinutes: false,
                        allowHours: false,
                        allowDaysOfMonth: false,
                        allowMonths: false,
                        allowDaysOfWeek: false,
                        allowYears: false, // Disable years
                      }}
                    />
                  </Grid>
                </Grid>

                <CategoryFilterDialog
                  open={categorySelectOpen}
                  onClose={() => {
                    setCategorySelectOpen(false);
                  }}
                  setFieldValue={(value) => {
                    setFieldValue(categorySelected?.field, value);
                  }}
                  sub_value={categorySelected?.value}
                  customticketfields={props.customticketfields_filter}
                  customticketfields_filter={props.customticketfields_filter}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default connect(mapStateToProps)(AutoResponsePost);
