import { theme } from "../../views/App";
import { ResponsiveRadar } from "@nivo/radar";

export const NivoRadarChart = (props) => {
  const customColors = props.colors
    ? props.colors
    : [
        `${theme.pieChart.color2}`,
        `${theme.pieChart.color1}`,
        `${theme.pieChart.color6}`,
        `${theme.pieChart.color4}`,
        `${theme.pieChart.color5}`,
        `${theme.pieChart.color3}`,
      ];

  return (
    <ResponsiveRadar
      data={props.chartData}
      keys={props.keys}
      indexBy={props.indexBy}
      valueFormat=">-.2f"
      margin={
        props.margin
          ? props.margin
          : { top: 70, right: 80, bottom: 40, left: 80 }
      }
      borderColor={{ from: "color" }}
      gridLabelOffset={36}
      dotSize={10}
      dotColor={{ theme: "background" }}
      dotBorderWidth={2}
      colors={customColors}
      blendMode="multiply"
      motionConfig="wobbly"
      legends={
        props.legendHide
          ? []
          : [
              {
                anchor: props.anchor ? props.anchor : "top-left",
                direction: props.direction ? props.direction : "column",
                translateX: props.translateX ? props.translateX : -50,
                translateY: props.translateY ? props.translateY : -40,
                itemWidth: 80,
                itemHeight: 20,
                itemsSpacing: props.itemsSpacing && props.itemsSpacing,
                itemTextColor: "#999",
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]
      }
      tooltip={props.tooltip}
    />
  );
};
