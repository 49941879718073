import BaseReducer from "../../../utilities/BaseReducer";
import OperationAction from "./OperationAction";

export default class OperationReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    operations: [],
  };

  //operation Filter
  [OperationAction.REQUEST_OPERATIONS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      operations: action.payload,
    };
  }
}
