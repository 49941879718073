import { BaseModel } from "sjs-base-model";

export default class ResolvedTicketCountBucketsDataModel extends BaseModel {
  //Resolved Ticket count Buckets data model
  doc_count = 0;
  from = 0;
  from_as_string = "";
  key = "";
  to = 0;
  to_as_string = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
