import BaseReducer from "../../../utilities/BaseReducer";
import TicketReportsAction from "./TicketReportsAction";

export default class AgentReportsReducer extends BaseReducer {
  initialState = {
    agentReports: {},
  };

  [TicketReportsAction.REQUEST_AGENT_REPORTS_FINISHED](state, action) {
    return {
      ...state,
      agentReports: action.payload,
    };
  }
}
