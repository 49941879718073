import ActionUtility from "../../../../utilities/ActionUtility";
import GoogleSearchEffect from "./GoogleSearchEffect";

export default class GoogleSearchAction {
  static REQUEST_GET_GOOGLE_CONFIG =
    "GoogleSearchAction.REQUEST_GET_GOOGLE_CONFIG";
  static REQUEST_GET_GOOGLE_CONFIG_FINISHED =
    "GoogleSearchAction.REQUEST_GET_GOOGLE_CONFIG_FINISHED";

  static REQUEST_POST_GOOGLE_CONFIG =
    "GoogleSearchAction.REQUEST_POST_GOOGLE_CONFIG";
  static REQUEST_POST_GOOGLE_CONFIG_FINISHED =
    "GoogleSearchAction.REQUEST_POST_GOOGLE_CONFIG_FINISHED";

  static REQUEST_PUT_GOOGLE_CONFIG =
    "GoogleSearchAction.REQUEST_PUT_GOOGLE_CONFIG";
  static REQUEST_PUT_GOOGLE_CONFIG_FINISHED =
    "GoogleSearchAction.REQUEST_PUT_GOOGLE_CONFIG_FINISHED";

  static REQUEST_GET_GOOGLE_SEARCH =
    "GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH";
  static REQUEST_GET_GOOGLE_SEARCH_FINISHED =
    "GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH_FINISHED";

  static REQUEST_POST_GOOGLE_SEARCH =
    "GoogleSearchAction.REQUEST_POST_GOOGLE_SEARCH";
  static REQUEST_POST_GOOGLE_SEARCH_FINISHED =
    "GoogleSearchAction.REQUEST_POST_GOOGLE_SEARCH_FINISHED";

  static REQUEST_PUT_GOOGLE_SEARCH =
    "GoogleSearchAction.REQUEST_PUT_GOOGLE_SEARCH";
  static REQUEST_PUT_GOOGLE_SEARCH_FINISHED =
    "GoogleSearchAction.REQUEST_PUT_GOOGLE_SEARCH_FINISHED";

  static REQUEST_SYNC_GOOGLE_SEARCH =
    "GoogleSearchAction.REQUEST_SYNC_GOOGLE_SEARCH";
  static REQUEST_SYNC_GOOGLE_SEARCH_FINISHED =
    "GoogleSearchAction.REQUEST_SYNC_GOOGLE_SEARCH_FINISHED";

  static REQUEST_GET_GOOGLE_SEARCH_DISCOVERED_DATA =
    "GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH_DISCOVERED_DATA";
  static REQUEST_GET_GOOGLE_SEARCH_DISCOVERED_DATA_FINISHED =
    "GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH_DISCOVERED_DATA_FINISHED";

  static REQUEST_PUT_GOOGLE_SEARCH_DISCOVERED_DATA =
    "GoogleSearchAction.REQUEST_PUT_GOOGLE_SEARCH_DISCOVERED_DATA";
  static REQUEST_PUT_GOOGLE_SEARCH_DISCOVERED_DATA_FINISHED =
    "GoogleSearchAction.REQUEST_PUT_GOOGLE_SEARCH_DISCOVERED_DATA_FINISHED";

  static REQUEST_GOOGLE_DISCOVERED_DATA_EXPORT =
    "GoogleSearchAction.REQUEST_GOOGLE_DISCOVERED_DATA_EXPORT";
  static REQUEST_GOOGLE_DISCOVERED_DATA_EXPORT_FINISHED =
    "GoogleSearchAction.REQUEST_GOOGLE_DISCOVERED_DATA_EXPORT_FINISHED";

  static REQUEST_GOOGLE_DISCOVERED_DATA_BULK_UPDATE =
    "GoogleSearchAction.REQUEST_GOOGLE_DISCOVERED_DATA_BULK_UPDATE";
  static REQUEST_GOOGLE_DISCOVERED_DATA_BULK_UPDATE_FINISHED =
    "GoogleSearchAction.REQUEST_GOOGLE_DISCOVERED_DATA_BULK_UPDATE_FINISHED";

  static requestGetGoogleConfig(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_GET_GOOGLE_CONFIG,
        GoogleSearchEffect.requestGetGoogleConfig,
        params
      );
    };
  }

  static requestPostGoogleConfig(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_POST_GOOGLE_CONFIG,
        GoogleSearchEffect.requestPostGoogleConfig,
        data
      );
    };
  }

  static requestPutGoogleConfig(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_PUT_GOOGLE_CONFIG,
        GoogleSearchEffect.requestPutGoogleConfig,
        data,
        id
      );
    };
  }

  static requestGetGoogleSearch(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH,
        GoogleSearchEffect.requestGetGoogleSearch,
        params
      );
    };
  }

  static requestPostGoogleSearch(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_POST_GOOGLE_SEARCH,
        GoogleSearchEffect.requestPostGoogleSearch,
        data
      );
    };
  }

  static requestPutGoogleSearch(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_PUT_GOOGLE_SEARCH,
        GoogleSearchEffect.requestPutGoogleSearch,
        data,
        id
      );
    };
  }

  static requestSyncGoogleSearch(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_SYNC_GOOGLE_SEARCH,
        GoogleSearchEffect.requestSyncGoogleSearch,
        params
      );
    };
  }

  static requestGoogleSearchDiscoveredData(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH_DISCOVERED_DATA,
        GoogleSearchEffect.requestGoogleSearchDiscoveredData,
        params
      );
    };
  }

  static requestPutGoogleSearchDiscoveredData(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_PUT_GOOGLE_SEARCH_DISCOVERED_DATA,
        GoogleSearchEffect.requestPutGoogleSearchDiscoveredData,
        data,
        id
      );
    };
  }

  static requestGoogleDiscoveredDataExport(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_GOOGLE_DISCOVERED_DATA_EXPORT,
        GoogleSearchEffect.requestGoogleDiscoveredDataExport,
        params,
        callBack
      );
    };
  }

  static requestGoogleDiscoveredDataBulkUpdate(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleSearchAction.REQUEST_GOOGLE_DISCOVERED_DATA_BULK_UPDATE,
        GoogleSearchEffect.requestGoogleDiscoveredDataBulkUpdate,
        params
      );
    };
  }
}
