import { BaseModel } from "sjs-base-model";

// LinkedInAccountPostModel
export default class LinkedInAccountPostModel extends BaseModel {
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
