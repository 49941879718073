import DsrActivityReducer from "./dsrActivity/DsrActivityReducer";
import DsrKanbanActivityReducer from "./dsrActivity/DsrKanbanActivityReducer";
import TodayDsrActivityReducer from "./dsrActivity/TodayDsrActivityReducer";
import DsrActivityGroupFilterReducer from "./dsrActivityGroup/DsrActivityGroupFilterReducer";
import DsrActivityGroupReducer from "./dsrActivityGroup/DsrActivityGroupReducer";
import DsrActivityShadowReducer from "./dsrActivityShadow/DsrActivityShadowReducer";
import DsrActivityDashboardReducer from "./dsrDashboard/DsrDashboardReducer";

export const dsrRootReducer = {
  dsrActivity: new DsrActivityReducer().reducer,
  todayDsrActivity: new TodayDsrActivityReducer().reducer,
  dsrActivityGroup: new DsrActivityGroupReducer().reducer,
  dsrActivityGroupFilter: new DsrActivityGroupFilterReducer().reducer,
  dsrActivityShadow: new DsrActivityShadowReducer().reducer,

  dsrActivityData: new DsrActivityDashboardReducer().reducer,
  dsrActiviyGroupwise: new DsrActivityDashboardReducer().reducer,
  dsrDashboardSummary: new DsrActivityDashboardReducer().reducer,
  dsrKanbanActivity: new DsrKanbanActivityReducer().reducer,
};
