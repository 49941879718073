import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Box, PaginationItem } from "@mui/material";
import { theme } from "../../views/App";

// Reuse Pagination components
export default function PaginationUI({ variant, ...props }) {
  return (
    <Stack spacing={2} sx={{ width: "max-content" }}>
      <Pagination
        shape="rounded"
        // color="primary"
        // defaultPage={1}
        count={props.pagelength}
        page={props.page + 1}
        onChange={props.handleChange}
        renderItem={(page) => (
          <PaginationItem
            {...page}
            disableRipple
            sx={{
              margin: 0,
              padding: 0,
              color: "#86aeb6",
              "&.Mui-selected": {
                borderBottom: "3px solid #e64b25",
                fontWeight: "bold",
                color: "#2a5e6c",
              },
            }}
          />
        )}
        // sx={{
        // position: "relative",
        // bottom: 10,
        // top: 10,
        // }}
      />
    </Stack>
  );
}
