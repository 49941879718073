import { BaseModel } from "sjs-base-model";
import EmailAccountDataHitsModel from "./EmailAccountDataHitsModel";

//EmailAccountHitsModel
export default class EmailAccountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ EmailAccountDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
