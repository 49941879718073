import environment from "environment";
import CategoryModel from "./models/CategoryModel";
import CategoryPostModel from "./models/CategoryPostModel";
import CategoryPutModel from "./models/CategoryPutModel.js";
import CategoryDeleteModel from "./models/CategoryDeleteModel";
import CategoryBulkUpdateModel from "./models/CategoryBulkUpdateModel";
import EffectUtility from "../../../utilities/EffectUtility";

//Category effect
export default class CategoryEffect {
  // calling an API according to the ProjectModel
  static async requestCategory(params) {
    const endpoint = environment.api.category;
    return EffectUtility.getToModel(CategoryModel, endpoint, params);
  }

  static async requestCategoryFilter(params) {
    const endpoint = environment.api.categoryfilter;
    return EffectUtility.getToModel(CategoryModel, endpoint, params);
  }

  // Post API
  static async requestPostCategory(data) {
    const endpoint = environment.api.category + "/add";
    return EffectUtility.postToModel(CategoryPostModel, endpoint, data);
  }

  // Put API
  static async requestPutCategory(data, id) {
    const endpoint = environment.api.category + "/" + id;
    return EffectUtility.putToModel(CategoryPutModel, endpoint, data);
  }

  // Delete API
  static async requestDeleteCategory(id) {
    const endpoint = environment.api.category + "/" + id;
    return EffectUtility.deleteToModel(CategoryDeleteModel, endpoint);
  }

  // Put method for the Ticket type bulk update
  static async requestPutCategoryBulkUpdate(data) {
    const endpoint = environment.api.categoryBulkUpdate;
    return EffectUtility.putToModel(CategoryBulkUpdateModel, endpoint, data);
  }
}
