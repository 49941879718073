import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function SentimentOnCustomValue(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter).then((res) => {
        setPlatformwiseTickets(res);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    const type_value_filters = props.type_value?.length
      ? props.type_value.map((item) => `${item.type_id}`)
      : [];

    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform_ticket_by_custom_value"]&advanced_filters={"custom_value_type":${
      props.type_value?.length
        ? JSON.stringify(type_value_filters)
        : `["text","select","radio","toggle","date"]`
    }}${
      props.DateFilter || (props.filterClicked && props.openDateFilter)
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  let columns = [];

  columns.push(
    {
      field: "id",
      headerName: "Sl No",
      resizable: false,
      width: 100,
      hide: true,
    },
    {
      field: "field_name",
      headerName: "Custom Field Name",
      resizable: true,
      hide: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name?.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.field_name}
          </Typography>
        );
      },
    }
  );
  if (props.platform_list?.length) {
    props.platform_list.map((col) => {
      columns.push({
        field: col.platform_id,
        headerName: col.platform_alias,
        resizable: true,
        width: 150,
        hide: false,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          const matchingPlatform = params?.row?.platforms.find(
            (platform) => platform.key === col.platform_id
          );

          // Get the count if a match is found, otherwise default to "00"
          const value = matchingPlatform
            ? prefixZero_for_singleDigit(matchingPlatform.count)
            : "00";

          return (
            <Typography
              className={`${
                params?.row?.field_name.startsWith("Total")
                  ? "font-weight-bold p-0 m-0"
                  : "font-weight-normal p-0 m-0"
              }`}
            >
              {value}
            </Typography>
          );
        },
      });
    });
  }

  let updatedRows = [];
  if (platformwiseTickets) {
    platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value?.forEach(
      (entyItem, index) => {
        // Loop through each value in the `values` array
        entyItem?.values?.forEach((valueItem) => {
          updatedRows?.push({
            id: updatedRows.length + 1, // Ensure unique IDs
            field_name: `${entyItem.key} - ${valueItem.key} (${valueItem.count})`,
            platforms: valueItem?.platforms,
          });
        });
      }
    );
  }

  const totalRow = {
    id: updatedRows.length + 1,
    field_name: "Total",
    platforms: {},
  };
  totalRow.platforms = props.platform_list.map((col) => {
    const platformId = col.platform_id;
    const totalValue =
      platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value?.[
        platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value
          ?.length - 1
      ]?.[platformId] !== undefined
        ? platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value?.[
            platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value
              ?.length - 1
          ]?.[platformId]
        : "00";

    return {
      key: platformId.toString(),
      count: prefixZero_for_singleDigit(totalValue),
    };
  });

  platformwiseTickets?.ticket_report?.platform_ticket_by_custom_value?.length >
    1 && updatedRows.push(totalRow);
  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      updatedRows?.map((item, index) => {
        const exportEntry = {
          // ["Sl No"]: index + 1,
          ["Field Name"]: item.field_name,
        };

        if (props.platform_list?.length) {
          props.platform_list.map((col) => {
            // Find the platform matching `col.platform_id` in `item.platforms`
            const matchingPlatform = item?.platforms?.find(
              (platform) => platform.key === col.platform_id
            );

            // Set the export entry for the current platform's alias
            exportEntry[col.platform_alias] = matchingPlatform
              ? prefixZero_for_singleDigit(matchingPlatform.count) // Format the count if found
              : "00"; // Default to "00" if no matching platform is found
          });
        }

        exportData.push(exportEntry); // Add the entry to the exportData array
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {!loading &&
            updatedRows.length > 0 &&
            props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={50}
                      onCellClick={onCellClick}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default SentimentOnCustomValue;
