import ActionUtility from "../../../utilities/ActionUtility";
import ResolvedTicketEffect from "./ResolvedTicketEffect";

export default class ResolvedTicketAction {
  //Ticket Resolved requests
  static REQUEST_RESOLVEDTICKET = "ResolvedTicketAction.REQUEST_RESOLVEDTICKET";
  static REQUEST_RESOLVEDTICKET_FINISHED =
    "ResolvedTicketAction.REQUEST_RESOLVEDTICKET_FINISHED";

  //METHODS

  //TicketResolved get method
  static requestResolvedTicket(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResolvedTicketAction.REQUEST_RESOLVEDTICKET,
        ResolvedTicketEffect.requestResolvedTicket,
        params
      );
    };
  }
}
