import ActionUtility from "../../../utilities/ActionUtility";
import AmbitionBoxAccountEffect from "./AmbitionBoxAccountEffect";

export default class AmbitionBoxAccountAction {
  // AmbitionBoxAccountAction with an function declaration
  static REQUEST_AMBITONBOX_ACCOUNT =
    "AmbitionBoxAccountAction.REQUEST_AMBITONBOX_ACCOUNT";
  static REQUEST_AMBITONBOX_ACCOUNT_FINISHED =
    "AmbitionBoxAccountAction.REQUEST_AMBITONBOX_ACCOUNT_FINISHED";

  static REQUEST_AMBITIONBOX_ACCOUNT_FILTER =
    "AmbitionBoxAccountAction.REQUEST_AMBITIONBOX_ACCOUNT_FILTER";
  static REQUEST_AMBITIONBOX_ACCOUNT_FILTER_FINISHED =
    "AmbitionBoxAccountAction.REQUEST_AMBITIONBOX_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_AMBITIONBOX_ACCOUNT =
    "AmbitionBoxAccountAction.REQUEST_PUT_AMBITIONBOX_ACCOUNT";
  static REQUEST_PUT_AMBITIONBOX_ACCOUNT_FINISHED =
    "AmbitionBoxAccountAction.REQUEST_PUT_AMBITIONBOX_ACCOUNT_FINISHED";

  static REQUEST_POST_AMBITIONBOX_ACCOUNT =
    "AmbitionBoxAccountAction.REQUEST_POST_AMBITIONBOX_ACCOUNT";
  static REQUEST_POST_AMBITIONBOX_ACCOUNT_FINISHED =
    "AmbitionBoxAccountAction.REQUEST_POST_AMBITIONBOX_ACCOUNT_FINISHED";

  static REQUEST_DELETE_AMBITIONBOX_ACCOUNT =
    "AmbitionBoxAccountAction.REQUEST_DELETE_AMBITIONBOX_ACCOUNT";
  static REQUEST_DELETE_AMBITIONBOX_ACCOUNT_FINISHED =
    "AmbitionBoxAccountAction.REQUEST_DELETE_AMBITIONBOX_ACCOUNT_FINISHED";

  // METHODS
  // AmbitonBox Get function
  static requestAmbitionBoxAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxAccountAction.REQUEST_AMBITONBOX_ACCOUNT,
        AmbitionBoxAccountEffect.requestAmbitionBoxAccount,
        params
      );
    };
  }

  static requestAmbitionBoxAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxAccountAction.REQUEST_AMBITIONBOX_ACCOUNT_FILTER,
        AmbitionBoxAccountEffect.requestAmbitionBoxAccountFilter,
        params
      );
    };
  }
  static requestPutAmbitionBoxAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxAccountAction.REQUEST_PUT_AMBITIONBOX_ACCOUNT,
        AmbitionBoxAccountEffect.requestPutAmbitionBoxAccount,
        data,
        id
      );
    };
  }

  // Glassdoor post function
  static requestPostAmbitionBoxAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxAccountAction.REQUEST_POST_AMBITIONBOX_ACCOUNT,
        AmbitionBoxAccountEffect.requestPostAmbitionBoxAccount,
        data
      );
    };
  }

  static requestDeleteAmbitionBoxAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxAccountAction.REQUEST_DELETE_AMBITIONBOX_ACCOUNT,
        AmbitionBoxAccountEffect.requestDeleteAmbitionBoxAccount,
        data
      );
    };
  }
}
