import BaseReducer from "../../../utilities/BaseReducer";
import ThreadsAccountAction from "./ThreadsAccountAction";

//Threads Account Filter reducer
export default class ThreadsAccountFilterReduser extends BaseReducer {
  initialState = {
    threadsAddAccountFilter: [],
  };

  [ThreadsAccountAction.REQUEST_THREADS_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      threadsAddAccountFilter: action.payload,
    };
  }
}
