import ActionUtility from "../../../../utilities/ActionUtility";
import ActorEffect from "./ActorEffect";

export default class ActorAction {
  // ActorAction action with an function declaration
  static REQUEST_ACTOR = "ActorAction.REQUEST_ACTOR";
  static REQUEST_ACTOR_FINISHED = "ActorAction.REQUEST_ACTOR_FINISHED";

  static REQUEST_ACTOR_FILTER = "ActorAction.REQUEST_ACTOR_FILTER";
  static REQUEST_ACTOR_FILTER_FINISHED =
    "ActorAction.REQUEST_ACTOR_FILTER_FINISHED";

  static REQUEST_PUT_ACTOR = "ActorAction.REQUEST_PUT_ACTOR";
  static REQUEST_PUT_ACTOR_FINISHED = "ActorAction.REQUEST_PUT_ACTOR_FINISHED";

  static REQUEST_POST_ACTOR = "ActorAction.REQUEST_POST_ACTOR";
  static REQUEST_POST_ACTOR_FINISHED =
    "ActorAction.REQUEST_POST_ACTOR_FINISHED";

  static REQUEST_ACTOR_CLEAR = "ActorAction.REQUEST_ACTOR_CLEAR";
  static REQUEST_ACTOR_CLEAR_FINISHED =
    "ActorAction.REQUEST_ACTOR_CLEAR_FINISHED";

  static REQUEST_ACTOR_FILTER_CLEAR = "ActorAction.REQUEST_ACTOR_FILTER_CLEAR";
  static REQUEST_ACTOR_FILTER_CLEAR_FINISHED =
    "ActorAction.REQUEST_ACTOR_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_ACTOR = "ActorAction.REQUEST_DELETE_ACTOR";
  static REQUEST_DELETE_ACTOR_FINISHED =
    "ActorAction.REQUEST_DELETE_ACTOR_FINISHED";

  // METHODS
  // ACTOR GET function
  static requestActor(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActorAction.REQUEST_ACTOR,
        ActorEffect.requestActor,
        params
      );
    };
  }

  static requestActorClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActorAction.REQUEST_ACTOR_CLEAR,
        ActorEffect.requestActorClear
      );
    };
  }

  static requestActorFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActorAction.REQUEST_ACTOR_FILTER_CLEAR,
        ActorEffect.requestActorFilterClear
      );
    };
  }

  static requestActorFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActorAction.REQUEST_ACTOR_FILTER,
        ActorEffect.requestActorFilter,
        params
      );
    };
  }
  static requestPutActor(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActorAction.REQUEST_PUT_ACTOR,
        ActorEffect.requestPutActor,
        data,
        id
      );
    };
  }

  static requestPostActor(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActorAction.REQUEST_POST_ACTOR,
        ActorEffect.requestPostActor,
        data,
        params,
        callBack
      );
    };
  }

  static requestDeleteActor(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActorAction.REQUEST_DELETE_ACTOR,
        ActorEffect.requestDeleteActor,
        id
      );
    };
  }
}
