import { BaseModel } from "sjs-base-model";

// CustomfieldsBulkUpdateModel
export default class CustomfieldsBulkUpdateModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;
    // response getting from the api mentioned end point

    data = [];
    status = "";

    constructor(data) {
        super();
        this.update(data);
    }
}
