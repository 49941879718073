import { Alert, AlertTitle, Slide, Snackbar } from "@mui/material";
import React from "react";
import { theme } from "../../views/App";

function MuiSnackbarAlert({
  open,
  onClose,
  message,
  severity = "warning",
  title,
  autoHideDuration,
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={autoHideDuration ?? null}
      onClose={onClose}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{
          width: "100%",
          borderRadius: "5px",
          // backgroundColor: theme.palette.background.error,
          fontSize: 16,
        }}
      >
        {title && (
          <AlertTitle sx={{ fontWeight: "bold", fontSize: 16 }}>
            {title ?? ""}
          </AlertTitle>
        )}
        {message ? message : ""}
      </Alert>
    </Snackbar>
  );
}

export default MuiSnackbarAlert;
