import BaseReducer from "../../utilities/BaseReducer";
import CustomTicketFieldsAction from "./CustomTicketFieldsAction";

// category reducer function
export default class CustomTicketFields extends BaseReducer {
  initialState = {
    customticketfields: [],
  };

  [CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      customticketfields: action.payload,
    };
  }
}
