import BaseReducer from "../../../../utilities/BaseReducer";
import ReleaseStatusCountAction from "./ReleaseStatusCountAction";

// TrackReduser
export default class ReleaseStatusCountReduser extends BaseReducer {
  initialState = {
    releaseStatusCount: [],
  };
  [ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      releaseStatusCount: action.payload,
    };
  }
}
