import {
  Avatar,
  Box,
  Checkbox,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Popper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TimerIcon from "@mui/icons-material/Timer";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import StyleIcon from "@mui/icons-material/Style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MoreIcon from "@mui/icons-material/More";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import copy from "copy-to-clipboard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import EmailComponent from "../components/EmailComponent";
import TranslatedContent from "../components/TranslateFunction";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { connect } from "react-redux";
import { theme } from "../../../../../views/App";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";
import {
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../../../components/ticketPartials";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import RenderMedia from "../../../../../components/renderMedia/RenderMedia";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
TimeAgo.addDefaultLocale(en);

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function TicketFeedRepliesContainer(props) {
  let showFeedDetails = props.showFeedDetails;
  let ticketData = props.ticketData;
  let feedData = props.feedData;
  let reply = props.reply;

  const [copied, setCopied] = useState(false);
  const timeAgo = new TimeAgo("en-US");

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const icon = (
    <CheckBoxOutlineBlankIcon
      className="p-0 m-0"
      fontSize="small"
      style={{
        color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      className="p-0 m-0"
      style={{
        // color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );

  const returntags = (tag_array) => {
    let tag_string = "";
    tag_array.length !== 0 &&
      tag_array.map((item) => {
        tag_string =
          tag_string !== ""
            ? tag_string + "/" + item.tag_name
            : tag_string + item.tag_name;
      });

    return tag_string.length > 20
      ? tag_string.substring(0, 20 - 2) + ".."
      : tag_string;
  };

  // edit icon opening
  const [popperAnchor, setPopperAnchor] = React.useState(null);
  const [popperAnchorTranslate, setPopperAnchorTranslate] =
    React.useState(null);

  const handleClick = (event) => {
    setPopperAnchor(popperAnchor ? null : event.currentTarget);
  };

  const handleClickTranslate = (event) => {
    setPopperAnchorTranslate(
      popperAnchorTranslate ? null : event.currentTarget
    );
  };

  const openPopper = Boolean(popperAnchor);
  const openTranlatePopper = Boolean(popperAnchorTranslate);

  const [showEmailBox, setShowEmailBox] = useState(false);

  const handleEmailButtonClick = () => {
    setShowEmailBox((prev) => !prev);
    // props.setEmailClicked((prev) => !prev);

    // values.feed[index].replyOpen === true &&
    //   setFieldValue(
    //     `feed[${index}].replyOpen`,
    //     !values.feed[index].replyOpen
    //   );
  };

  const [translateClicked, setTranslateClicked] = useState(false);

  const returnACtivitiesname = () => {
    let activity = "";
    if (ticketData._source.platform.platform_name === "Twitter") {
      if (feedData._source.tweet_type) {
        activity = feedData._source.tweet_type;
      } else {
        activity = "Direct Message";
      }
    } else if (ticketData._source.platform.platform_name === "Facebook") {
      if (props.feedData._index === "facebook_direct_messages") {
        activity = "Direct Message";
      } else {
        activity = ticketData._source.activities.activities_name;
      }
    } else if (ticketData._source.platform.platform_name === "Instagram") {
      if (feedData._source.activities) {
        activity = feedData._source.activities;
      } else {
        activity = "Direct Message";
      }
    } else if (ticketData._source.platform.platform_name === "GMB") {
      if (feedData._index === "gmb_review" && feedData._source.activities) {
        activity = feedData._source.activities;
      } else if (
        feedData._index === "gmb_questions_answers" &&
        feedData._source.activities
      ) {
        activity = feedData._source.activities;
      }
    } else if (ticketData._source.platform.platform_name === "Email") {
      activity = "Reply";
    } else {
      activity = ticketData._source.activities.activities_name;
    }
    return activity;
  };

  const returnCustomValues = (custom_fields) => {
    let category_values = props.category_custom_value;

    let filtered_category_vals = [];
    if (custom_fields && custom_fields.length !== 0) {
      filtered_category_vals = custom_fields.filter((item) => {
        return item.field_type === "category";
      });
    }

    let sub_value_id = [];

    if (filtered_category_vals && filtered_category_vals.length !== 0) {
      filtered_category_vals.map((item) => {
        sub_value_id.push(item.field_values[0].id);
      });
    }

    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals = ReturnCategoryName(
      category_values && category_values.length !== 0 ? category_values : [],
      sub_value_id && sub_value_id[0] && sub_value_id[0]
    );

    return custom_field_vals;
  };

  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  const returntrueCategoryValues = (ticket) => {
    let bool_val = false;

    if (
      ticket &&
      ticket._source &&
      ticket._source.custom_value &&
      ticket._source.custom_value.length !== 0
    ) {
      ticket._source.custom_value
        .filter((val) => {
          return val.field_type === "category";
        })
        .map((item) => {
          if (item && item.field_values) {
            item.field_values[0].id !== ""
              ? (bool_val = true)
              : (bool_val = false);
          }
        });
    }

    return bool_val;
  };

  function handleTwitterReplyDelete(id) {
    if (id) {
      props.dispatch(TwitterAction.requestTweetDelete(id)).then(() => {
        props.dispatch(TicketsAction.requestTicketsById(props.ticketData._id));
      });
    }
  }

  const ReplyTimeString = (platformName, reply) => {
    let timeString = null;

    const formatDate = (dateString) => {
      let date = new Date(dateString);
      return (
        date.toDateString() +
        " " +
        date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      );
    };

    switch (platformName) {
      case "Facebook":
      case "Twitter":
        timeString = reply?.reply_created_at
          ? formatDate(reply.reply_created_at)
          : reply?._source?.message_at
          ? formatDate(reply._source.message_at)
          : null;
        break;
      case "Whatsapp":
      case "Instagram":
        timeString = reply?._source?.message_sent_on
          ? formatDate(reply._source.message_sent_on)
          : reply?.replied_created_time
          ? formatDate(reply.replied_created_time)
          : null;
        break;
      case "Youtube":
      case "LinkedIn":
        timeString = reply?.created_on ? formatDate(reply.created_on) : null;
        break;
      case "Playstore":
        timeString = reply?.reply_created_at
          ? formatDate(reply.reply_created_at)
          : null;
        break;
      case "GMB":
        timeString = reply?.reply_time
          ? formatDate(reply.reply_time)
          : reply?.answer_created_at
          ? formatDate(reply?.answer_created_at)
          : null;
        break;
      case "Telegram":
        timeString = reply?._source?.created_on
          ? formatDate(reply._source.created_on)
          : null;
        break;
      case "Threads":
        timeString = reply?.replied_at ? formatDate(reply.replied_at) : null;
        break;
      case "Email":
        timeString = reply?.replied_time
          ? formatDate(reply.replied_time)
          : null;
        break;
      case "Reddit":
        timeString = reply?.replied_on ? formatDate(reply.replied_on) : null;
        break;
      default:
        timeString = null;
    }

    console.log("platformName", platformName, reply, timeString);

    return timeString;
  };

  return (
    <Box
      sx={{
        paddingX: 2,
        paddingY: 1,
      }}
      // className="bg-success"
    >
      <Grid
        item
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        alignItems="center"
        justifyContent={"flex-end"}
      >
        <Grid
          item
          xl={showFeedDetails ? 10 : 8}
          lg={9}
          md={9}
          sm={9}
          xs={9}
          alignItems="flex-end"
          justifyContent={"center"}
          textAlign="left"
          sx={{
            borderRadius: "10px",
            border: "solid 0.5px #92b9c4",
            backgroundColor: "#f3f9fb",
          }}
        >
          <Box width={"100%"}>
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="space-between"
              alignItems={"center"}
              py={1}
              px={2}
            >
              <Grid
                item
                container
                xl={7}
                lg={8}
                md={8}
                sm={8}
                xs={8}
                justifyContent="flex-start"
                alignItems={"center"}
                gap={2}
              >
                <Grid
                  item
                  // xl={3} lg={3} md={3} sm={3} xs={3}
                >
                  <ListItem
                    className="p-0 m-0"
                    style={{
                      color: `${theme.palette.text.primary}`,
                    }}
                  >
                    <Avatar
                      style={{
                        width:
                          props.ticketData._source.platform.platform_name ===
                          "Youtube"
                            ? null
                            : "20px",
                        height:
                          props.ticketData._source.platform.platform_name ===
                          "Youtube"
                            ? null
                            : "20px",
                        // backgroundColor:
                        //   props.ticketData._source.platform.platform_name ===
                        //     "Youtube" ||
                        //   props.ticketData._source.platform.platform_name ===
                        //     "Indeed" ||
                        //   props.ticketData._source.platform.platform_name ===
                        //     "Trust Pilot"
                        //     ? "white"
                        //     : `${theme.palette.primary.main}`,
                      }}
                    >
                      {returnPlatformDropdownLogos(
                        ticketData._source.platform.platform_name
                      )}
                    </Avatar>

                    {ticketData._source.activities &&
                      ticketData._source.activities.activities_name && (
                        <p
                          className="m-0 p-0 ml-1"
                          style={{
                            color: `${theme.palette.text.primary}`,
                            fontSize: "13px",
                          }}
                        >
                          Brand {returnACtivitiesname()}
                        </p>
                      )}
                  </ListItem>
                </Grid>

                {/* Replied Username Container */}
                <Grid
                  item
                  // xl={9} lg={9} md={9} sm={9} xs={3}
                >
                  <ListItem className="p-0 m-0">
                    <Avatar
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      src={""}
                    >
                      {ticketData._source?.account_name?.account_name?.charAt(
                        0
                      )}

                      {/* {ticketData._source.platform.platform_name ===
                        "Instagram" && reply.replied_username?.charAt(0)}

                      {ticketData._source.platform.platform_name ===
                        "Twitter" && !feedData._source.tweet_type
                        ? reply._source &&
                          reply._source.sender_name &&
                          reply._source.sender_name.charAt(0)
                        : reply &&
                          reply.agent_name &&
                          reply.agent_name.charAt(0)}

                      {ticketData._source.platform.platform_name ===
                        "Facebook" &&
                      ticketData._source.activities.activities_name ===
                        "Direct Message"
                        ? feedData._source &&
                          feedData._source.sender_name &&
                          feedData._source.sender_name.charAt(0)
                        : ""}

                      {ticketData._source.platform.platform_name === "GMB" &&
                      feedData._index === "gmb_review" &&
                      feedData._source.account_name
                        ? feedData._source.account_name.charAt(0)
                        : ""}

                      {ticketData._source.platform.platform_name === "GMB" &&
                      feedData._index === "gmb_questions_answers" &&
                      feedData._source.account_name
                        ? feedData._source.account_name.charAt(0)
                        : ""}

                      {ticketData._source.platform.platform_name ===
                        "LinkedIn" && reply.reply_username
                        ? reply.reply_username && reply.reply_username.charAt(0)
                        : ""}

                      {ticketData._source.platform.platform_name ===
                        "Telegram" && reply._source.account_name
                        ? reply._source.account_name.charAt(0)
                        : ""}

                      {ticketData._source.platform.platform_name ===
                        "Threads" && feedData._source.account_name
                        ? feedData._source.account_name.charAt(0)
                        : ""}
                      {ticketData._source.platform.platform_name ===
                        "Playstore" && reply.app_name
                        ? reply.app_name.charAt(0)
                        : ""} */}
                    </Avatar>
                    {ticketData._source?.account_name?.account_name ? (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                          textTransform: "capitalize",
                        }}
                      >
                        {ticketData._source?.account_name?.account_name}
                      </p>
                    ) : null}

                    {/* {ticketData._source.platform.platform_name ===
                      "Twitter" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {!feedData._source.tweet_type
                          ? reply._source &&
                            reply._source &&
                            reply._source.sender_name &&
                            reply._source.sender_name
                          : reply.agent_name}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name ===
                      "Whatsapp" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {reply._source &&
                          reply._source &&
                          reply._source.account_name}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name ===
                      "Instagram" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {reply.replied_username}
                      </p>
                    )}
                    {ticketData._source.platform.platform_name ===
                      "Youtube" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {reply.reply_username}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name ===
                      "Playstore" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {reply.app_name}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name ===
                      "LinkedIn" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {reply.reply_username}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name === "GMB" &&
                      feedData._index === "gmb_review" && (
                        <p
                          className="p-0 m-0 ml-1"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {feedData._source.account_name &&
                            feedData._source.account_name}
                        </p>
                      )}

                    {ticketData._source.platform.platform_name === "GMB" &&
                      feedData._index === "gmb_questions_answers" && (
                        <p
                          className="p-0 m-0 ml-1"
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {feedData._source.account_name &&
                            feedData._source.account_name}
                        </p>
                      )}

                    {ticketData._source.platform.platform_name ===
                      "Telegram" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {reply._source.account_name &&
                          reply._source.account_name}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name === "Email" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {feedData._source.account_name &&
                          feedData._source.account_name}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name ===
                      "Threads" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {feedData._source.account_name &&
                          feedData._source.account_name}
                      </p>
                    )}

                    {ticketData._source.platform.platform_name === "Reddit" && (
                      <p
                        className="p-0 m-0 ml-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {reply.replied_by && reply.replied_by}
                      </p>
                    )} */}
                  </ListItem>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xl={4.5}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                justifyContent="flex-end"
                alignItems={"center"}
                gap={1}
              >
                <Grid
                  item
                  px={1}
                  py={0}
                  // xl={10}
                  // lg={10}
                  // md={10}
                  // sm={10}
                  // xs={10}
                  sx={{
                    backgroundColor: "#d3e2e6",
                    border: `0.2px solid 
                    ${theme.palette.background.primary}`,
                    borderRadius: 2,
                    color: "#39717c",
                  }}
                  textAlign="center"
                >
                  <span
                    style={{
                      color: `${theme.palette.ticketListView.checkbox}`,
                      fontSize: "12px",
                    }}
                  >
                    {feedData._source.account_name
                      ? feedData._source.account_name
                      : props.ticketData._source &&
                        props.ticketData._source.account_name &&
                        props.ticketData._source.account_name.account_name}
                  </span>
                </Grid>

                <Grid
                  item
                  // xl={6} lg={6} md={6} sm={6} xs={6}
                >
                  <Tooltip
                    title={`Updated On : ${ReplyTimeString(
                      ticketData._source.platform.platform_name,
                      reply
                    )}`}
                    arrow
                    placement="left"
                    style={{
                      cursor: "default",
                    }}
                  >
                    <ListItem className="p-0 m-0">
                      <AccessTimeIcon
                        style={{
                          width: "13px",
                          marginRight: 2,
                          color: `${theme.palette.text.dark}`,
                        }}
                      />
                      <span
                        className="m-0 p-0"
                        style={{
                          fontSize: "12px",
                          color: `${theme.palette.text.dark}`,
                        }}
                      >
                        {ticketData._source.platform.platform_name ===
                        "Facebook"
                          ? reply && reply.reply_created_at
                            ? timeAgo.format(new Date(reply.reply_created_at))
                            : reply &&
                              reply._source &&
                              reply._source.message_at &&
                              reply._source.message_at &&
                              timeAgo.format(new Date(reply._source.message_at))
                          : null}
                        {ticketData._source.platform.platform_name === "Twitter"
                          ? reply && reply.reply_created_at
                            ? timeAgo.format(new Date(reply.reply_created_at))
                            : reply &&
                              reply._source &&
                              reply._source.message_at &&
                              reply._source.message_at &&
                              timeAgo.format(new Date(reply._source.message_at))
                          : null}
                        {ticketData._source.platform.platform_name ===
                        "Whatsapp"
                          ? reply &&
                            reply._source &&
                            reply._source.message_sent_on &&
                            timeAgo.format(
                              new Date(reply._source.message_sent_on)
                            )
                          : null}
                        {ticketData._source.platform.platform_name ===
                        "Instagram"
                          ? reply && reply.replied_created_time
                            ? timeAgo.format(
                                new Date(reply.replied_created_time)
                              )
                            : reply &&
                              reply._source &&
                              reply._source.message_sent_on &&
                              timeAgo.format(
                                new Date(reply._source.message_sent_on)
                              )
                          : null}
                        {/* {ticketData._source.platform.platform_name ===
                        "Instagram" &&
                        reply.replied_created_time &&
                        timeAgo.format(new Date(reply.replied_created_time))} */}
                        {ticketData._source.platform.platform_name ===
                          "Youtube" &&
                          reply.created_on &&
                          timeAgo.format(new Date(reply.created_on))}
                        {ticketData._source.platform.platform_name ===
                          "Playstore" &&
                          reply.reply_created_at &&
                          timeAgo.format(new Date(reply.reply_created_at))}
                        {ticketData._source.platform.platform_name ===
                          "LinkedIn" &&
                          reply.created_on &&
                          timeAgo.format(new Date(reply.created_on))}
                        {ticketData._source.platform.platform_name === "GMB" &&
                          feedData._index === "gmb_review" &&
                          reply.reply_time &&
                          timeAgo.format(new Date(reply.reply_time))}

                        {ticketData._source.platform.platform_name === "GMB" &&
                          feedData._index === "gmb_questions_answers" &&
                          reply.answer_created_at &&
                          timeAgo.format(new Date(reply.answer_created_at))}

                        {ticketData._source.platform.platform_name ===
                          "Telegram" &&
                          reply._source.created_on &&
                          timeAgo.format(new Date(reply._source.created_on))}
                        {ticketData._source.platform.platform_name ===
                          "Threads" &&
                          reply.replied_at &&
                          timeAgo.format(new Date(reply.replied_at))}

                        {ticketData._source.platform.platform_name ===
                          "Email" &&
                          reply.replied_time &&
                          timeAgo.format(new Date(reply.replied_time))}

                        {ticketData._source.platform.platform_name ===
                          "Reddit" &&
                          reply.replied_on &&
                          timeAgo.format(new Date(reply.replied_on))}
                      </span>
                    </ListItem>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>

            <hr className="p-0 m-0" />

            {(ticketData._source.platform.platform_name === "Twitter" ||
              ticketData._source.platform.platform_name === "Youtube") && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                {/* <span
                  className="m-0 py-1  text-left"
                  style={{
                    fontSize: "12px",
                    color: `${theme.palette.text.ticketContent}`,
                  }}
                >
                  {reply.reply_content.replace(/\\n/g, "\n")}
                </span> */}

                <span
                  className="m-0 py-1 text-left"
                  style={{
                    fontSize: "12px",
                    color: `${theme.palette.text.ticketContent}`,
                  }}
                >
                  {reply.reply_content && reply.reply_content.includes("\n")
                    ? reply.reply_content.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                    : reply.reply_content}
                </span>

                {reply._source && reply._source.message_text && (
                  <span
                    className="m-0 py-1  text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply._source.message_text}
                  </span>
                )}
              </Box>
            )}
            {ticketData._source.platform.platform_name === "Facebook" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                {/* reply._source.message_text */}
                {reply && reply.reply_content && (
                  <span
                    className="m-0 py-1  text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply.reply_content}
                  </span>
                )}

                {reply &&
                  reply.media &&
                  reply.media.media_type &&
                  reply.media.media_type === "photo" && (
                    <Box width="100%" sx={{ padding: "10px" }}>
                      <RenderMedia
                        image_url={reply.media.media_url}
                        mediaType="img"
                        height="100"
                      />
                    </Box>
                  )}

                {reply && reply._source && reply._source.message_text && (
                  <span
                    className="m-0 py-1  text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply._source.message_text}
                  </span>
                )}
              </Box>
            )}

            {ticketData._source.platform.platform_name === "Threads" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                {/* reply._source.message_text */}
                {reply && reply.reply_content && (
                  <span
                    className="m-0 py-1  text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply.reply_content}
                  </span>
                )}

                {reply &&
                  reply.media &&
                  reply.media[0].type &&
                  (reply.media[0].type === "svg" ||
                    reply.media[0].type === "jpg") && (
                    <Box width="100%" sx={{ padding: "10px" }}>
                      <RenderMedia
                        image_url={reply.media[0].url}
                        mediaType="img"
                        height="100"
                      />
                    </Box>
                  )}
              </Box>
            )}

            {ticketData._source.platform.platform_name === "Reddit" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                {/* reply._source.message_text */}
                {reply && reply.reply_content && (
                  <span
                    className="m-0 py-1  text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply.reply_content}
                  </span>
                )}
              </Box>
            )}

            {ticketData._source.platform.platform_name === "Instagram" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                <span
                  className="m-0 py-1 text-left"
                  style={{
                    fontSize: "12px",
                    color: `${theme.palette.text.ticketContent}`,
                  }}
                >
                  {feedData &&
                  feedData._source &&
                  feedData._source.activities &&
                  feedData._source.activities === "Direct Message"
                    ? reply && reply._source && reply._source.message_content
                    : reply.replied_content}
                </span>
              </Box>
            )}

            {ticketData._source.platform.platform_name === "Playstore" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                <span
                  className="m-0 py-1 text-left"
                  style={{
                    fontSize: "12px",
                    color: `${theme.palette.text.ticketContent}`,
                  }}
                >
                  {reply.reply_content}
                </span>
              </Box>
            )}

            {ticketData._source.platform.platform_name === "GMB" &&
              feedData._index === "gmb_review" && (
                <Box
                  px={2}
                  sx={{
                    overflowWrap: "anywhere",
                  }}
                >
                  <span
                    className="m-0 py-1 text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply.reply_content}
                  </span>
                </Box>
              )}

            {ticketData._source.platform.platform_name === "GMB" &&
              feedData._index === "gmb_questions_answers" && (
                <Box
                  px={2}
                  sx={{
                    overflowWrap: "anywhere",
                  }}
                >
                  <span
                    className="m-0 py-1 text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply.answer}
                  </span>
                </Box>
              )}

            {ticketData._source.platform.platform_name === "Whatsapp" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                {reply && reply._source && reply._source.message_content && (
                  <span
                    className="m-0 py-1  text-left"
                    style={{
                      fontSize: "12px",
                      color: `${theme.palette.text.ticketContent}`,
                    }}
                  >
                    {reply._source.message_content}
                  </span>
                )}
              </Box>
            )}

            {ticketData._source.platform.platform_name === "LinkedIn" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                <span
                  className="m-0 py-1  text-left"
                  style={{
                    fontSize: "12px",
                    color: `${theme.palette.text.ticketContent}`,
                  }}
                >
                  {reply.reply_content}
                </span>
              </Box>
            )}

            {ticketData._source.platform.platform_name === "Telegram" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                <span
                  className="m-0 py-1 text-left"
                  style={{
                    fontSize: "12px",
                    color: `${theme.palette.text.ticketContent}`,
                  }}
                >
                  {reply._source.message_content &&
                    reply._source.message_content}
                </span>
              </Box>
            )}

            {ticketData._source.platform.platform_name === "Email" && (
              <Box
                px={2}
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                <span
                  className="m-0 py-1 text-left"
                  style={{
                    fontSize: "12px",
                    color: `${theme.palette.text.ticketContent}`,
                  }}
                >
                  {reply.body && reply.body}
                </span>
              </Box>
            )}

            <Box px={2}>
              <Typography
                fontSize={{
                  xl: "12px",
                  lg: "12px",
                  md: "11px",
                  laptop: "11px",
                }}
              >
                {translateClicked && (
                  <Grid
                    sx={{
                      backgroundColor: `${theme.palette.background.light}`,
                      border: `1px solid ${theme.palette.background.light}`,
                      borderRadius: "3px",
                      padding: "5px",
                      marginY: "5px",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography component={"p"}>
                        Translated to English
                      </Typography>
                      <Typography
                        component={"p"}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTranslateClicked(!translateClicked);
                        }}
                      >
                        <HighlightOffOutlinedIcon
                          titleAccess="Close"
                          sx={{ width: "12px" }}
                        />
                      </Typography>
                    </Grid>

                    <TranslatedContent
                      content={
                        ticketData._source.platform.platform_name ===
                          "Twitter" ||
                        ticketData._source.platform.platform_name === "Youtube"
                          ? reply.reply_content
                            ? reply.reply_content
                            : reply._source &&
                              reply._source.message_text &&
                              reply._source.message_text
                          : ticketData._source.platform.platform_name ===
                            "Facebook"
                          ? reply && reply.reply_content
                            ? reply.reply_content
                            : reply._source &&
                              reply._source.message_text &&
                              reply._source.message_text
                          : ticketData._source.platform.platform_name ===
                            "Instagram"
                          ? reply && reply.replied_content
                            ? reply.replied_content
                            : reply._source &&
                              reply._source.message_content &&
                              reply._source.message_content
                          : ticketData._source.platform.platform_name ===
                            "Playstore"
                          ? reply.reply_content
                          : ticketData._source.platform.platform_name ===
                              "GMB" && feedData._index === "gmb_review"
                          ? reply.reply_content
                          : ticketData._source.platform.platform_name ===
                              "GMB" &&
                            feedData._index === "gmb_questions_answers"
                          ? reply.answer
                          : ticketData._source.platform.platform_name ===
                            "LinkedIn"
                          ? reply.reply_content
                          : ticketData._source.platform.platform_name ===
                            "Telegram"
                          ? reply._source.message_content
                          : ticketData._source.platform.platform_name ===
                            "Whatsapp"
                          ? reply._source.message_content
                          : ticketData._source.platform.platform_name ===
                            "Email"
                          ? reply.body
                          : null
                      }
                    />
                  </Grid>
                )}
              </Typography>
            </Box>

            {/* <hr className="p-0 m-0" /> */}

            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="space-between"
              alignItems={"center"}
              style={{
                backgroundColor: "#f3f9fb",
                borderTop: `0.5px solid 
                    ${theme.palette.background.primary}`,
                borderBottom: `0.5px solid 
                    ${theme.palette.background.primary}`,
              }}
              px={2}

              // className={"bg-warning"}
            >
              <Grid
                item
                container
                xl={10}
                lg={10}
                md={10}
                sm={10}
                xs={10}
                justifyContent="flex-start"
                alignItems={"center"}
                my={0.5}
                // className="bg-success"
              >
                <Grid
                  item
                  // container
                  // xl={1}
                  // lg={0.8}
                  // md={0.5}
                  // xs={0.5}
                  alignItems="center"
                  justifyContent={"flex-start"}
                  textAlign="left"
                  sx={{
                    borderRight: `1px solid ${theme.palette.background.primary}`,
                  }}
                  pr={1}
                  // className="bg-light"
                >
                  <Grid item>
                    <ListItem
                      className="p-0 m-0"
                      style={{
                        padding: 0,
                        margin: 0,
                        color: `${theme.palette.ticketListView.iconColor}`,
                      }}
                    >
                      <Checkbox
                        className="p-0 m-0"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        // checked={selected}
                        sx={
                          {
                            // width: 3,
                            // height: 3,
                          }
                        }
                      />
                    </ListItem>
                  </Grid>
                </Grid>

                <Grid
                  item
                  // container
                  // xl={1}
                  // lg={1.5}
                  // md={0.5}
                  // xs={0.5}
                  alignItems="center"
                  justifyContent={"center"}
                  textAlign="center"
                  // className="bg-info"
                  sx={{
                    borderRight: `1px solid ${theme.palette.background.primary}`,
                  }}
                  px={1}
                >
                  {props.reply &&
                    props.reply._source &&
                    props.reply._source.ticket_id && (
                      <Grid item>
                        <IconButton
                          className="p-0 m-0"
                          title="Ticket Id"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            props.reply &&
                              props.reply._source &&
                              props.reply._source.ticket_id &&
                              copyToClipboard(props.reply._source.ticket_id);
                          }}
                          sx={{
                            // width: 6,
                            // height: 6,
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          {/* <FileCopyIcon
                            fontSize="small"
                            style={{
                              // marginRight: -5,
                              width: "13px",
                            }}
                          /> */}

                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{
                              color: `${theme.palette.ticketListView.iconColor}`,
                              width: "13px",
                            }}
                          ></FontAwesomeIcon>
                        </IconButton>
                        <Snackbar
                          message="Ticket Id copied to clipboard"
                          sx={{ height: "70%" }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          autoHideDuration={500}
                          onClose={() => setCopied(false)}
                          open={copied}
                        />
                      </Grid>
                    )}
                </Grid>

                {/* {props.ticketData._source.custom_value.length !== 0 &&
                returntrueCategoryValues(props.ticketData) ? (
                  <Grid
                    item
                    container
                    xl={3}
                    lg={1.5}
                    md={0.5}
                    xs={0.5}
                    alignItems="center"
                    justifyContent={"center"}
                    textAlign="center"
                    sx={{
                      borderRight: `1px solid ${theme.palette.background.primary}`,
                    }}
                  >
                    <ListItem className="py-0 m-0 px-1">
                      <StyleIcon
                        style={{
                          width: "16px",
                          marginRight: 2,
                          color: `${theme.palette.ticketListView.iconColor}`,
                          color: `${theme.palette.ticketListView.iconColor}`,
                        }}
                      />
                      <p
                        className="m-0 p-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.openCloseModal(!props.categoryUpdateOpen);
                        }}
                      >
                        {returnCustomValues(
                          props.ticketData._source.custom_value
                        ) &&
                          returnCustomValuesAsString(
                            props.ticketData._source.custom_value
                          )}
                      </p>
                    </ListItem>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    xl={3}
                    lg={1.5}
                    md={0.5}
                    xs={0.5}
                    alignItems="center"
                    justifyContent={"center"}
                    textAlign="center"
                    sx={{
                      borderRight: `1px solid ${theme.palette.background.primary}`,
                    }}
                  >
                    <ListItem className="py-0 m-0 px-1">
                      <StyleIcon
                        style={{
                          width: "16px",
                          marginRight: 2,
                          color: `${theme.palette.ticketListView.iconColor}`,
                          color: `${theme.palette.ticketListView.iconColor}`,
                        }}
                      />

                      <p
                        className="m-0 p-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.openCloseModal(!props.categoryUpdateOpen);
                        }}
                      >
                        Select Category
                      </p>
                    </ListItem>
                  </Grid>
                )} */}

                {/* {props.ticketData._source.tags.length !== 0 && (
                  <Grid
                    item
                    container
                    xl={3}
                    lg={1.5}
                    md={0.5}
                    xs={0.5}
                    alignItems="center"
                    justifyContent={"center"}
                    textAlign="center"
                    sx={{
                      borderRight: `1px solid ${theme.palette.background.primary}`,
                    }}
                  >
                    <Grid item>
                      <ListItem
                        style={{ padding: 0, margin: 0 }}
                        className="p-0 m-0"
                      >
                        <StyleIcon
                          style={{
                            width: "13px",
                            marginRight: 2,
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <p className="m-0 p-0">
                          {returntags(props.ticketData._source.tags)}
                        </p>
                      </ListItem>
                    </Grid>
                  </Grid>
                )} */}
              </Grid>

              {/* <Grid
                item
                container
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                justifyContent="flex-end"
                alignItems={"center"}
              >
                <IconButton
                  className="p-0 m-0"
                  title=""
                  onClick={() => {
                    props.openCloseModal(!props.categoryUpdateOpen);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{
                      color: `${theme.palette.ticketListView.iconColor}`,
                      width: "13px",
                    }}
                  ></FontAwesomeIcon>
                </IconButton>

                <Popper
                  sx={{ width: 200, zIndex: 100, position: "relative" }}
                  open={openPopper}
                  anchorEl={popperAnchor}
                  placement={"right-start"}
                >
                  <Paper sx={{ p: 1.2, boxShadow: theme.shadows }}>
                    <Typography>UI NOT FROZEN</Typography>
                  </Paper>
                </Popper>
              </Grid> */}
            </Grid>

            <Box width={"100%"}>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="flex-end"
                alignItems={"center"}
                px={1.5}
              >
                <Grid
                  item
                  container
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  gap={1}
                  justifyContent="flex-end"
                  // justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Grid item>
                    <IconButton
                      className="p-0 m-0"
                      title="Send Mail"
                      onClick={() => {
                        // props.setFieldValue(
                        //   `feed[${index}].emailOpen`,
                        //   !props.values.feed[index].emailOpen
                        // );
                        handleEmailButtonClick();
                      }}
                    >
                      <MailOutlineIcon
                        style={{
                          width: "13px",
                          // marginRight: 2,
                          color: `${theme.palette.ticketListView.iconColor}`,
                        }}
                      />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    <IconButton
                      className="p-0 m-0"
                      title="Translate"
                      onClick={(e) => {
                        setTranslateClicked(!translateClicked);
                      }}
                    >
                      <GTranslateIcon
                        style={{
                          width: "12px",
                          // marginRight: 2,
                          color: translateClicked
                            ? theme.palette.primary.main
                            : `${theme.palette.ticketListView.iconColor}`,
                        }}
                      />
                    </IconButton>

                    {/* <Popper
                      sx={{ width: 200, zIndex: 100, position: "relative" }}
                      open={openTranlatePopper}
                      anchorEl={popperAnchorTranslate}
                      placement={"right-start"}
                    >
                      <Paper sx={{ p: 1.2, boxShadow: theme.shadows }}>
                        <Typography>UI NOT FROZEN</Typography>
                      </Paper>
                    </Popper> */}
                  </Grid>

                  {ticketData._source.platform.platform_name === "Twitter" &&
                    returnACtivitiesname() === "Tweet" && (
                      <Grid item>
                        <IconButton
                          className="p-0 m-0"
                          title={
                            reply.reply_deleted === false
                              ? "Delete Reply"
                              : "Reply Deleted"
                          }
                          sx={{
                            opacity: reply.reply_deleted ? 0.5 : 1,
                            pointerEvents: reply.reply_deleted && "none",
                          }}
                          onClick={() => {
                            // props.setFieldValue(
                            //   `feed[${index}].emailOpen`,
                            //   !props.values.feed[index].emailOpen
                            // );
                            // handleEmailButtonClick();
                          }}
                        >
                          {reply && reply.reply_deleted === false ? (
                            <DeleteIcon
                              style={{
                                width: "13px",
                                // marginRight: 2,
                                color: `${theme.palette.ticketListView.iconColor}`,
                              }}
                              onClick={() => {
                                handleTwitterReplyDelete(reply.reply_tweet_id);
                              }}
                            />
                          ) : (
                            <DeleteForeverSharpIcon
                              style={{
                                width: "13px",
                                // marginRight: 2,
                                color: `${theme.palette.ticketListView.iconColor}`,
                              }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Box>

            {showEmailBox && (
              <EmailComponent
                showEmailBox={showEmailBox}
                setEmailClicked={props.setEmailClicked}
                setShowEmailBox={setShowEmailBox}
                ticketData={props.ticketData}
                // feedData={
                //   props.emailFeeds.hits &&
                //   props.emailFeeds.hits[index] &&
                //   props.emailFeeds.hits[index]._source
                //     ? props.emailFeeds.hits[index]._source
                //     : null
                // }
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default connect(mapDispatchToProps)(TicketFeedRepliesContainer);
