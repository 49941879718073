import BaseReducer from "../../../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// This is used as a duplicate for Release by id Reducer
// For exisiting tracks
export default class ExistingTracksReducer extends BaseReducer {
  initialState = {
    releaseById2: [],
  };
  [ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS_FINISHED](state, action) {
    return {
      ...state,
      releaseById2: action.payload,
    };
  }

  // [ReleasesAction.REQUEST_RELEASE_BY_ID_CLEAR_FINISHED](state, action) {
  //   return {
  //     ...state,
  //     releaseById: action.payload,
  //   };
  // }
}
