import styles from "./LoadingIndicator.module.scss";

import React from "react";
import classNames from "classnames";
import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// loading
function LoadingIndicator(props) {
  const { children, isActive, className } = props;
  const cssClasses = classNames(className, {
    [styles.wrapper]: isActive,
  });

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 500);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <div className={cssClasses} style={{ width: "100%", height: "100%" }}>
      {isActive && (
        <>
          {/* <div className={styles.loaderContainer}>
            <LoadingButton
              loading={isActive}
              // loadingIndicator="Loading…"
              // active={true}
              // inverted={true}
              size="large"
            />


          </div> */}
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </>
      )}
      {children}
    </div>
  );
}

export default LoadingIndicator;
