import ActionUtility from "../../utilities/ActionUtility";
import TicketTypeEffect from "./TicketTypeEffect";

export default class TicketTypeAction {
  //tickettype requests
  static REQUEST_TICKETTYPE = "TicketTypeAction.REQUEST_TICKETTYPE";
  static REQUEST_TICKETTYPE_FINISHED =
    "TicketTypeAction.REQUEST_TICKETTYPE_FINISHED";

  //ticketType post
  static REQUEST_POST_TICKETTYPE = "TicketTypeAction.REQUEST_POST_TICKETTYPE";
  static REQUEST_POST_TICKETTYPE_FINISHED =
    "TicketTypeAction.REQUEST_POST_TICKETTYPE";

  //ticketType put
  static REQUEST_PUT_TICKETTYPE = "TicketTypeAction.REQUEST_PUT_TICKETTYPE";
  static REQUEST_PUT_TICKETTYPE_FINISHED =
    "TicketTypeAction.REQUEST_PUT_TICKETTYPE_FINISHED";

  //TicketType list (for Filter drop down)
  static REQUEST_TICKETTYPE_FILTER =
    "TicketTypeAction.REQUEST_TICKETTYPE_FILTER";
  static REQUEST_TICKETTYPE_FILTER_FINISHED =
    "TicketTypeAction.REQUEST_TICKETTYPE_FILTER_FINISHED";

  //ticketType put Bulk

  static REQUEST_PUT_TICKET_TYPE_BULKUPDATE =
    "TicketTypeAction.REQUEST_PUT_TICKET_TYPE_BULKUPDATE";
  static REQUEST_PUT_TICKET_TYPE_BULKUPDATE_FINISHED =
    "TicketTypeAction.REQUEST_PUT_TICKET_TYPE_BULKUPDATE_FINISHED";

  //METHODS

  //TicketType get method
  static requestTicketType(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketTypeAction.REQUEST_TICKETTYPE,
        TicketTypeEffect.requestTicketType,
        params
      );
    };
  }

  //TicketType POST method
  static requestPostTicketType(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketTypeAction.REQUEST_POST_TICKETTYPE,
        TicketTypeEffect.requestPostTicketType,
        data
      );
    };
  }

  // TicketType put function
  static requestPutTicketType(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketTypeAction.REQUEST_PUT_TICKETTYPE,
        TicketTypeEffect.requestPutTicketType,
        data,
        id
      );
    };
  }

  // TicketType list get  method for filter dropdown
  static requestFilterTicketType(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketTypeAction.REQUEST_TICKETTYPE_FILTER,
        TicketTypeEffect.requestFilterTicketType,
        params
      );
    };
  }

  // TicketType Bulk put function

  static requestPutTicketTypeBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketTypeAction.REQUEST_PUT_TICKET_TYPE_BULKUPDATE,
        TicketTypeEffect.requestPutTicketTypeBulkUpdate,
        data
      );
    };
  }
}
