import BaseReducer from "../../../../utilities/BaseReducer";
import ActivityTypeAction from "./ActivityTypeAction";

export default class ActivityTypeFilterReducer extends BaseReducer {
  initialState = {
    activityTypeFilter: [],
  };

  [ActivityTypeAction.REQUEST_ACTIVITY_TYPE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      activityTypeFilter: action.payload,
    };
  }
}
