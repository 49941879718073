import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// InstagramDmDataModel
export default class InstagramDmDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  account_id = "";
  account_pic_url = "";
  account_username = "";
  activities = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  customer_id = "";
  customer_pic_url = "";
  customer_username = "";
  feed_id = "";
  flag = "";
  message_content = "";
  message_recived_on = "";
  message_type = "";
  modified_on = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
