import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { connect } from "react-redux";
import { BorderContainer } from "../../../../../components/componentLayout/ComponentLayout";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TwitterAction.REQUEST_TWITTER,
    TwitterAction.REQUEST_TWITTER_FILTER,
  ]),
});

function CustomerNamePopper(props) {
  let { isRequesting } = props;

  const [page_limit] = useState(10);
  const [customerName, setCustomerName] = useState(() => {
    if (localStorage.getItem("allMentionsParameters")) {
      return JSON.parse(localStorage.getItem("allMentionsParameters"))
        .customerName;
    } else {
      return null;
    }
  });

  // const [sentiment_value, set_sentiment_value] = useState(() => {
  //   if (localStorage.getItem("mentionsFilters")) {
  //     return JSON.parse(localStorage.getItem("mentionsFilters"))
  //       .sentiment_value;
  //   } else {
  //     return null;
  //   }
  // });

  useEffect(() => {
    // let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    // if (filters) {
    //   set_sentiment_value(filters.sentiment_value && filters.sentiment_value);
    // }
    let filters = JSON.parse(localStorage.getItem("allMentionsParameters"));
    if (filters) {
      setCustomerName(filters.customerName && filters.customerName);
    }
  }, [props.open]);

  // Sentimentvalue to callback function
  const customerNameHandleChange = (event, value) => {
    setCustomerName(value);
  };

  //****************** dispatch functions   *******************
  function fetchTwitter(params) {
    props.dispatch(TwitterAction.requestTwitter(params));
  }

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    let filters = JSON.parse(localStorage.getItem("allMentionsParameters"));
    filters.customerName = customerName;
    localStorage.setItem("allMentionsParameters", JSON.stringify(filters));

    // props.saveFilter();
    props.fetchTicketCount();

    let object_new = {};

    Object.assign(
      object_new,
      props.filterContents
        ? {
            ...props.filterContents,
            customerName: customerName,
          }
        : {
            customerName: customerName,
          }
    );

    props.setFilterContents(
      props.filterContents
        ? {
            ...props.filterContents,
            customerName: customerName,
          }
        : {
            customerName: customerName,
          }
    );

    console.log("filterclicked", object_new);
    props.applyFilter(object_new);
  }

  function handleClearFilters() {
    if (customerName !== null) {
      setCustomerName(null);
    }
    let filters = JSON.parse(localStorage.getItem("allMentionsParameters"));
    filters.customerName = null;
    localStorage.setItem("allMentionsParameters", JSON.stringify(filters));
    const params = {
      order_by: '[["created", "desc"]]',
      page_limit: `${page_limit}`,
    };
  }

  return (
    <Box>
      <BorderContainer>
        <ClickAwayListener onClickAway={props.handleClose}>
          <div
            autoFocusItem={props.popopen}
            id="composition-menu"
            aria-labelledby="composition-button"
          >
            <Grid
              container
              // className="bg-warning"
              sx={{
                height: 250,
              }}
            >
              <Typography
                component={"p"}
                className="dashboard-title"
                sx={{ fontSize: 18 }}
              >
                {"Customer Name".split(" ").map((item) => (
                  <span className="dashboard-title">{item}</span>
                ))}
              </Typography>

              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid item xl={3}>
                  <AutoCompleteComponent
                    value={customerName}
                    list={props.customerName}
                    size="small"
                    textFieldProps={{
                      placeholder: "Customer Name",
                    }}
                    disableClearable={true}
                    getOptionLabel={(option) => (option ? option : "")}
                    handleChange={customerNameHandleChange}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginY: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item>
                  <MuiButton
                    variant="outlined"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Reset"}
                    loading={false}
                    backgroundColor={"white"}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleClearFilters();
                    }}
                  />
                </Grid>
                <Grid item>
                  <MuiButton
                    variant="contained"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Apply"}
                    loading={false}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleSaveFilters();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      </BorderContainer>
    </Box>
  );
}

export default connect(mapStateToProps)(CustomerNamePopper);
