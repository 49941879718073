import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Badge from "@mui/material/Badge";
import { connect } from "react-redux";
import {
  BorderContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../../components/componentLayout/ComponentLayout";
import CircularLoadingIndicator from "../../../../../components/loading-indicator/CircularLoadingIndicator";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiAutocomplete from "../../../../../components/muiSelectField/MuiAutocomplete";
import { theme } from "../../../../../views/App";
function AgentProfilePopper(props) {
  const [assigned_To, setAssigned_To] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).assigned_To;
    } else {
      return null;
    }
  });

  const [unAssigned, SetUnAssigned] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).unAssigned;
    } else {
      return false;
    }
  });

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    if (filters) {
      setAssigned_To(filters.assigned_To && filters.assigned_To);
    }
  }, [props.open]);

  // Assigned to callback function
  const assignedTohandleChange = (event, value) => {
    if (value !== null) {
      setAssigned_To(value);
      SetUnAssigned(false);
    } else {
      setAssigned_To(null);
    }
  };

  let [AssignError, setAssignError] = useState(false);

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    if (unAssigned === false && assigned_To === null) {
      setAssignError(true);

      setTimeout(() => {
        setAssignError(false);
      }, 1000);
    } else {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

      if (filters) {
        filters.assigned_To = assigned_To;
        filters.unAssigned = unAssigned;
        filters.pageNumber = `0`;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            assigned_To,
            unAssigned,
          })
        );
      }

      props.saveFilter(filters);
      props.fetchTicketCount();
    }
  }

  function handleClearFilters() {
    if ((assigned_To && assigned_To.user_name !== null) || unAssigned) {
      setAssigned_To(null);
      SetUnAssigned(false);
      props.clearFilter("agent");

      localStorage.removeItem("mentionsFilters");
    }
  }

  let uniqueList = [];

  props.userFilterdList.sort((a, b) => a?.fullname.localeCompare(b.fullname));
  uniqueList = [...new Set(props.userFilterdList)];

  return (
    <Box>
      <BorderContainer>
        <ClickAwayListener onClickAway={props.handleClose}>
          <div
            autoFocusItem={props.popopen}
            id="composition-menu"
            aria-labelledby="composition-button"
          >
            <Grid
              item
              container
              // className="bg-warning"
              sx={{
                height: 250,
              }}
            >
              <Typography
                component={"p"}
                className="dashboard-title"
                sx={{ fontSize: 18 }}
              >
                {"Agent Profile".split(" ").map((item) => (
                  <span className="dashboard-title">{item}</span>
                ))}
              </Typography>

              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                // className="bg-success"
                justifyContent={"flex-start"}
                alignItems="center"
                sx={{
                  animation: AssignError && "bounce 0.5s linear",
                  "@keyframes bounce": {
                    "0%, 20%, 50%, 80%, 100%": {
                      transform: "translateY(0)",
                    },
                    "40%": {
                      transform: "translateY(-30px)",
                    },
                    "60%": {
                      transform: "translateY(-15px)",
                    },
                  },
                }}
              >
                <Grid item xl={4} lg={4} md={4}>
                  <AutoCompleteComponent
                    value={assigned_To}
                    size="small"
                    list={uniqueList}
                    textFieldProps={{
                      placeholder: "Assigned To",
                    }}
                    width={"100%"}
                    renderOption={(props, option, { selected }) => (
                      <RadioGroup>
                        <FormControlLabel
                          {...props}
                          control={<Radio checked={selected} size="small" />}
                          label={option.fullname}
                        />
                      </RadioGroup>
                    )}
                    disableClearable={true}
                    getOptionLabel={(option) =>
                      option.fullname ? option.fullname : ""
                    }
                    handleChange={assignedTohandleChange}
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  // className="bg-info"
                >
                  <FormControlLabel
                    sx={{
                      color: theme.palette.primary.main,
                      padding: 0,
                      margin: 0,
                    }}
                    control={
                      <Checkbox
                        checked={unAssigned}
                        onChange={(e, val) => {
                          if (val) {
                            SetUnAssigned(val);
                            setAssigned_To(null);
                          } else {
                            SetUnAssigned(val);
                          }
                        }}
                        sx={{ padding: 0, marginRight: 1 }}
                      />
                    }
                    label={"Un Assigned Tickets"}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginY: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item>
                  <MuiButton
                    variant="outlined"
                    width={"103px"}
                    height={"35px"}
                    backgroundColor={"white"}
                    type="submit"
                    name={"Reset"}
                    loading={false}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleClearFilters();
                    }}
                  />
                </Grid>
                <Grid item>
                  <MuiButton
                    variant="contained"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Apply"}
                    loading={false}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleSaveFilters();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      </BorderContainer>
    </Box>
  );
}

export default AgentProfilePopper;
