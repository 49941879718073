import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  ListItem,
  ListItemAvatar,
  Paper,
  Popover,
  Popper,
  Slide,
  Snackbar,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import copy from "copy-to-clipboard";
import Chip from "@mui/material/Chip";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReplyIcon from "@mui/icons-material/Reply";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import StarIcon from "@mui/icons-material/Star";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import FolderIcon from "@mui/icons-material/Folder";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import StyleIcon from "@mui/icons-material/Style";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonIcon from "@mui/icons-material/Person";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TicketDetails from "../ticketDetails/TicketDetails";
import { makeStyles } from "@material-ui/core";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useLocation } from "react-router";
import { format } from "date-fns";
import DateSelectionPopper from "../ticketDetails/components/DateSelectionPopper";
import DirectClosePopper from "../ticketDetails/components/DirectClosePopper";
import ReactTimer from "../ticketDetails/components/ReactTimer";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import ListViewFilters from "../filters/ListViewFilters";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PriorityUpdatePopper from "./components/PriorityUpdatePopper";
import SentimentValueUpdatePopper from "./components/SentimentValueUpdatePopper";
import CategoryUpdateDialog from "../ticketDetails/components/CategoryUpdateDialog";
import TranslatedContent from "../ticketDetails/components/TranslateFunction";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import TicketsAction from "../../../stores/tickets/TicketsAction";
import PriorityAction from "../../../stores/priority/PriorityAction";
import PlatformAction from "../../../stores/platform/PlatformAction";
import ProjectAction from "../../../stores/project/projectAction";
import TicketCountAction from "../../../stores/ticketCount/TicketCountAction";
import TicketSettingsAction from "../../../stores/ticketSettings/TicketSettingsAction";
import CategoryAction from "../../../stores/category/CategoryAction";
import CustomTicketFieldsAction from "../../../../stores/customticketfields/CustomTicketFieldsAction";
import StatusAction from "../../../stores/status/StatusAction";
import { theme } from "../../../../views/App";
import TwitterAction from "../../../stores/twitterFeeds/TwitterAction";
import FacebookAction from "../../../stores/facebook/FacebookAction";
import InstagramFeedsAction from "../../../stores/instagramFeeds/InstagramFeedsAction";
import AppStoreAction from "../../../stores/linkedInFeed/appstore/AppStoreAction";
import YoutubeAction from "../../../stores/youtube/YoutubeAction";
import LinkedInFeedAction from "../../../stores/linkedInFeed/LinkedInFeedAction";
import GmbQuestionFeedsAction from "../../../stores/gmbQuestions/GmbQuestionFeedsAction";
import IndeedFeedAction from "../../../stores/indeed/IndeedFeedAction";
import RedditFeedAction from "../../../stores/reddit/RedditFeedAction";
import QuoraFeedsAction from "../../../stores/quoraFeeds/QuoraFeedsAction";
import TrustpilotFeedsAction from "../../../stores/trustpilot/TrustpilotFeedsAction";
import PinterestFeedsAction from "../../../stores/pinterest/PinterestFeedsAction";
import PlayStoreAction from "../../../stores/playstore/PlayStoreAction";
import GmbReviewAction from "../../../stores/gmbReviews/GmbReviewAction";

import TwitterDirectMessageAction from "../../../stores/linkedIn_account/twitterDirectMessage/TwitterDirectMessageAction";
import InstagramDirectMessageAction from "../../../stores/instagramDirectMessage/InstagramDirectMessageAction";
import FacebookDmAction from "../../../stores/facebook_dm/FacebookDmAction";
import AmbitionBoxFeedsAction from "../../../../stores/ambitionBoxFeeds/AmbitionBoxFeedsAction";
import GlassdoorFeedsAction from "../../../stores/glassdoorFeeds/GlassdoorFeedsAction";
import EmailFeedsAction from "../../../stores/emailFeeds/EmailFeedsAction";
import TelegramAction from "../../../stores/telegram/TelegramAction";
import WhatsAppFeedAction from "../../../stores/whatsappFeed/WhatsAppFeedAction";
import CustomerDataAction from "../../../../stores/ticketCustomerData/CustomerDataAction";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import {
  formatName,
  returnLogo,
  returnPlatformDropdownLogos,
  returnSmallLogo,
  returnSmallPlatformLogos,
} from "../../../../components/ticketPartials";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import PaginationUI from "../../../../components/pagination/Pagination";
import BulkAssign from "../../../../views/ticketList/BulkAssign";
import TagAction from "../../../stores/tag/TagAction";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TagsUpdatePopper from "./components/TagsUpdatePopper";
import TagUpdateDailog from "../ticketDetails/components/TagUpdateDailog";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import UserPermissionAction from "../../../../common/stores/userPermission/UserPermissionAction";
import ReplyComponent from "../ticketDetails/components/ReplyComponent";
import RenderMedia from "../../../../components/renderMedia/RenderMedia";

TimeAgo.addDefaultLocale(en);

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TicketsAction.REQUEST_TICKETS,
    TicketsAction.REQUEST_TICKETS_FILTER,
    PriorityAction.REQUEST_PRIORITY_FILTER,
    PlatformAction.REQUEST_PLATFORM,
    UserPermissionAction.REQUEST_USER_PERSMISSION,
    ProjectAction.REQUEST_PROJECT_BY_ID,
    StatusAction.REQUEST_STATUS_FILTER,
    TicketCountAction.REQUEST_TICKET_COUNT,
    TagAction.REQUEST_TAG_FILTER,
    TicketSettingsAction.REQUEST_TICKET_SETTINGS,
    CategoryAction.REQUEST_CATEGORY_FILTER,
    // CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS,
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER,
  ]),
  tickets: state.tickets.tickets || {},
  platform: state.platform.platform.hits || {},
  pagination: state.tickets || {},
  filterpriority: state.filterpriority.filterpriority.hits || [],
  userPermission: state.userPermission.userPermission || {},
  statusFilter: state.statusFilter.statusFilter || [],
  ticketfilter: state.ticketfilter.ticketfilter || [],
  projectById: state.projectById.projectById || [],
  ticketCount: state.ticketCount.ticketCount || [],
  tagfilter: state.tagfilter.tagfilter || [],
  ticketSettings: state.ticketSettings.ticketSettings.hits || {},
  categoryfilter: state.categoryfilter.categoryfilter || {},
  customticketfields: state.customticketfields.customticketfields || {},
  customticketfields_filter:
    state.customticketfields_filter.customticketfields_filter.hits || {},
  userById: state.userById.userById || [],
});
const AllTickets = (props) => {
  const [selected, setSelected] = useState([]);
  const { isRequesting } = props;

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      // right: -3,
      top: 0,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "1px",
      fontSize: "9px",
    },
  }));
  function fetchCustomticketfields() {
    const filter = [];

    filter.push(`["field_type.keyword","must","","match","category"]`);

    let paramater = {
      filters: `[${filter}]`,
    };
    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFields(paramater)
    );
  }
  // customticketfield_api
  function fetchCustomticketfieldsFilter() {
    const paramater = {
      page_limit: "none",
    };

    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFieldsFilter(paramater)
    );
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleReplyClose = () => {
    props.setOpenReplyDialog(false);
  };

  const handleReplyErrorsClose = () => {
    props.setOpenReplyErrorsDialog(false);
    SetReplyLogs(null);
  };

  const [replyLogs, SetReplyLogs] = useState(null);

  const returnCustomValues = (custom_fields) => {
    let category_values =
      props.customticketfields &&
      props.customticketfields.hits &&
      props.customticketfields.hits.hits?.filter(
        (item) => item._source.field_type === "category"
      )?.[0]._source.field_values;

    let filtered_category_vals = [];
    if (custom_fields && custom_fields.length !== 0) {
      filtered_category_vals = custom_fields.filter((item) => {
        return item.field_type === "category";
      });
    }

    let sub_value_id = [];

    if (filtered_category_vals && filtered_category_vals.length !== 0) {
      filtered_category_vals.map((item) => {
        sub_value_id.push(item.field_values[0]?.id);
      });
    }
    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals =
      category_values &&
      category_values.length !== 0 &&
      ReturnCategoryName(category_values, sub_value_id[0]);

    return custom_field_vals;
  };

  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  let returnCustomValuesforChipAsString = (data) => {
    let values = [];

    values = returnCustomValuesforChip(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  const returntrueCategoryValues = (ticket) => {
    let bool_val = false;

    if (
      ticket &&
      ticket._source &&
      ticket._source.custom_value &&
      ticket._source.custom_value.length !== 0
    ) {
      ticket._source.custom_value
        .filter((val) => {
          return val.field_type === "category";
        })
        .map((item) => {
          if (item && item.field_values) {
            item?.field_values?.length && item?.field_values[0]?.id !== ""
              ? (bool_val = true)
              : (bool_val = false);
          }
        });
    }

    return bool_val;
  };

  const tagsStringValues = (ticket) => {
    let tagText = " Select Tags ";
    if (
      ticket &&
      ticket._source &&
      ticket._source.tags &&
      ticket._source.tags.length !== 0
    )
      tagText = ticket._source.tags.map((tag) => tag.tag_name).join("/");
    return tagText;
  };

  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  let { state } = useLocation();
  let location = useLocation();

  const StatusbarRef = useRef(null);
  const [statusbarHeight, setStatusBarHeight] = useState(0);

  const paginationRef = useRef(null);
  const [paginationHeight, setPaginationHeight] = useState(0);

  const filtersBoxRef = useRef(null);
  const [FiltersHeight, setFiltersHeight] = useState(0);

  const TicketsBoxRef = useRef(null);
  const [TicketBoxHeight, setTicketBoxHeight] = useState(0);

  useEffect(
    () => {
      const topHeaderHeight = StatusbarRef.current.offsetHeight;
      setStatusBarHeight(topHeaderHeight);

      const paginationHeight = paginationRef.current.offsetHeight;
      setPaginationHeight(paginationHeight);

      const filtersHeight = filtersBoxRef.current.offsetHeight;
      setFiltersHeight(filtersHeight);

      const ticketBoxHeight = TicketsBoxRef.current.offsetHeight;
      setTicketBoxHeight(ticketBoxHeight);
    }
    // [
    //   // StatusbarRef, paginationRef, filtersBoxRef, TicketsBoxRef
    // ]
  );

  const [dateSelecte] = useState([
    "Last 2 Days",
    "Today",
    "Previous Day",
    "Last 7 Days",
    "Last 30 Days",
    "Custom",
  ]);

  const [dateSelected] = useState([
    "Last 2 Days",
    "Today",
    "Previous Day",
    "Last 7 Days",
    "Last 30 Days",
    "Custom",
  ]);

  const useStyles = makeStyles(() => ({
    popover: {
      boxShadow: `${theme.customer_popover_shadows}`,
    },
  }));

  const classes = useStyles();

  const [replyClicked, setReplyClicked] = useState(false);
  const [emailClicked, setEmailClicked] = useState(false);

  const [pageNumber, setPageNumber] = useState(() => {
    if (state && state.action === "directed") {
      return 0;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).pageNumber;
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (props.searchQuery === "") {
      setSearchQuery(null);
      setTicketIds([]);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = null;
        filters.searchTypeSelected = null;
        filters.pageNumber = `0`;
        filters.ticket_ids = [];

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      }
    }
  }, [props.searchQuery]);

  const [searchQuery, setSearchQuery] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).searchQuery;
    } else {
      return null;
    }
  });

  const [ticket_ids, setTicketIds] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).ticket_ids;
    } else {
      return [];
    }
  });

  const [searchTypeSelected, setSearchTypeSelected] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters"))
        .searchTypeSelected;
    } else {
      return null;
    }
  });
  const [sentiment_value, set_sentiment_value] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters"))
        .sentiment_value;
    } else {
      return null;
    }
  });

  const [ratings_Value, set_ratings_Value] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).ratings_Value;
    } else {
      return null;
    }
  });

  const [platform, setPlatform] = useState(() => {
    if (
      (state && state.action === "directed") ||
      (state && state.action === "direct_close")
    ) {
      return state.platform;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).platform;
    } else {
      return null;
    }
  });

  const [priority, setPriority] = useState(() => {
    if (
      (state && state.action === "directed") ||
      (state && state.action === "direct_close")
    ) {
      return state.priority;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).priority;
    } else {
      return null;
    }
  });

  const [activities, setActivities] = useState(() => {
    if (
      (state && state.action === "directed") ||
      (state && state.action === "direct_close")
    ) {
      return state.activity;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).activities;
    } else {
      return [];
    }
  });

  // const [selectStatus, setSelectStatus] = useState(() => {
  //   if (localStorage.getItem("mentionsFilters")) {
  //     return JSON.parse(localStorage.getItem("mentionsFilters")).selectStatus;
  //   } else {
  //     return [];
  //   }
  // });
  const [createdDatelistSelected, setCreatedDatelistSelected] = useState(() => {
    if (
      (state && state.action === "directed") ||
      (state && state.action === "direct_close")
    ) {
      return state.createdDatelistSelected;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters"))
        .createdDatelistSelected;
    } else {
      return "Last 2 Days";
    }
  });

  const [modifiedDatelistSelected, setModifiedDatelistSelected] = useState(
    () => {
      if (
        localStorage.getItem("mentionsFilters") &&
        localStorage.getItem("mentionsFilters").modifiedDatelistSelected
      ) {
        return JSON.parse(localStorage.getItem("mentionsFilters"))
          .modifiedDatelistSelected;
      } else {
        return null;
      }
    }
  );

  const [createdDate, setCreatedDate] = useState(() => {
    if (state && state.action === "directed") {
      return [
        state.createdDate[0] ? new Date(state.createdDate[0]) : null,
        state.createdDate[1] ? new Date(state.createdDate[1]) : null,
      ];
    } else if (localStorage.getItem("mentionsFilters")) {
      return [
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[0]
          ? new Date(
              JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[1]
          ? new Date(
              JSON.parse(localStorage.getItem("mentionsFilters")).createdDate[1]
            )
          : null,
      ];
    } else {
      return [null, null];
    }
  });

  const [modifiedDate, setModifiedDate] = useState(() => {
    if (
      localStorage.getItem("mentionsFilters") &&
      localStorage.getItem("mentionsFilters").modifiedDate
    ) {
      return [
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate[0]
          ? new Date(
              JSON.parse(
                localStorage.getItem("mentionsFilters")
              ).modifiedDate[0]
            )
          : null,
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate &&
        JSON.parse(localStorage.getItem("mentionsFilters")).modifiedDate[1]
          ? new Date(
              JSON.parse(
                localStorage.getItem("mentionsFilters")
              ).modifiedDate[1]
            )
          : null,
      ];
    } else {
      return [null, null];
    }
  });

  const [tags, setTags] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).tags;
    } else {
      return [];
    }
  });

  const [category, setCategory] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).category;
    } else {
      return [];
    }
  });
  const [assigned_To, setAssigned_To] = useState(() => {
    if (state && state.action === "directed") {
      return state.assigned_to;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).assigned_To;
    } else {
      return null;
    }
  });

  const [unAssigned, SetUnAssigned] = useState(() => {
    if (state && state.action === "directed") {
      return state.unAssigned;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).unAssigned;
    } else {
      return false;
    }
  });

  let statusList = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits.forEach((item) => {
      statusList.push({
        status_id: item._id,
        status_name: item._source.status_name,
        initial_status: item._source.initial_status,
        final_status: item._source.final_status,
      });
    });
  }

  let finalStatuses = [];
  if (statusList.length > 0) {
    finalStatuses = statusList
      .filter((item) => item.final_status === true)
      .map((item) => item.status_id);
  }

  // initialStatus id assigning
  let i_status_id = "";

  statusList.length > 0 &&
    statusList.map((item) => {
      return item.initial_status == true && (i_status_id = item.status_id);
    });

  const [selected_status, set_selected_status] = useState(() => {
    if (state && state.action === "directed") {
      return state.status.status_id;
    } else if (state && state.action === "direct_close") {
      return state.directClose;
    } else if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters"))
        .selected_status;
    } else {
      return !isRequesting && i_status_id;
    }
  });

  // When the initial status is found it will update Selected status value.
  useEffect(() => {
    set_selected_status(i_status_id);
  }, [i_status_id]);

  const icon = (
    <CheckBoxOutlineBlankIcon
      fontSize="small"
      color={`${theme.palette.ticketListView.checkbox}`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: 15,
        height: 15,
        backgroundColor: "white",
      }}
    />
  );
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      color={`${theme.palette.ticketListView.checkbox}`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: 15,
        height: 15,
      }}
    />
  );
  const timeAgo = new TimeAgo("en-US");

  // usestates
  const [page_limit] = useState(6);
  let filters = useRef();
  let ticketCountFilterRef = useRef();

  // fetch All function
  function fetchAllTickets(params) {
    props.dispatch(TicketsAction.requestTickets(params));
  }

  function fetchTagFilter(param) {
    props.dispatch(TagAction.requestTagFilter(param));
  }

  // ticket settings api
  function fetchTicketSettings(params) {
    props.dispatch(TicketSettingsAction.requestTicketSettings(params));
  }

  function fetchPlatform() {
    let param = {
      //   level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };

    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function extractUrl(description) {
    const urlPattern = /\| URL \| (https?:\/\/[^\s]+)/;
    const match = description.match(urlPattern);
    return match ? match[1] : null;
  }
  // open feed link fn
  function OpenNewWindow(item) {
    // feed link for twitter platform
    if (item?._source?.souce_link) {
      window.open(item?._source?.souce_link);
    } else {
      alert("No Link Found");
    }
  }

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toDateString() +
      " " +
      date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };

  function FullName(value) {
    return value._source.user?.firstname + " " + value._source.user?.lastname;
  }

  // ticket dispatch function for filter
  function fetchUserFilter() {
    let filter = [];

    let project = localStorage.getItem("user_last_working_project")
      ? JSON.parse(localStorage.getItem("user_last_working_project")).project_id
      : "";

    let org_id = localStorage.getItem("user_last_working_project")
      ? JSON.parse(localStorage.getItem("user_last_working_project"))
          .organization_id &&
        JSON.parse(localStorage.getItem("user_last_working_project"))
          .organization_id
      : "";

    filter.push(
      `["resource_id.keyword","must","","terms",["${project}" , "*"]],["organization_id.keyword","must","","terms",["${org_id}" , "*"]]`
    );

    const param = {
      page_limit: "none",
      filters: `[${filter}]`,
    };
    props.dispatch(UserPermissionAction.requestUserPermission(param));
  }

  // priority dispatch function
  function fetchFilterPriority() {
    const param = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    props.dispatch(PriorityAction.filterRequestPriority(param));
  }

  function fetchCategoryNameFilter() {
    const param = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };

    props.dispatch(CategoryAction.requestCategoryFilter(param));
  }

  //fetch filterTicketsCount
  function fetchTicketCount(params) {
    props.dispatch(TicketCountAction.requestTicketCount(params));
  }

  // ticket dispatch function for filter
  function fetchStatusFilter() {
    let param = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  //project list
  function fetchProjectById(id) {
    props.dispatch(ProjectAction.requestProjectById(id));
  }

  const latest_feed_created_on_platforms = [
    "Playstore",
    "Facebook",
    "Instagram",
    "GMB",
    "LinkedIn",
    "YouTube",
  ];

  // DATE value handle change
  const today = new Date();

  // ***************************************
  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);

  //Last 2 days filter
  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  // start from today's date last 7 days
  const last7Days = [...Array(7).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date;
  });

  // last 30 days
  let date2 = new Date();
  let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  // ***************************************
  // last 3 months
  let date3 = new Date();
  let last3Month = date3.setMonth(date3.getMonth() - 3);
  let custom_names = [];

  useEffect(() => {
    let params = {
      filters: `[${ticketCountFilterRef.current}]`,
    };

    Object.assign(
      params,
      searchTypeSelected === "Author" &&
        searchQuery !== null && { customer_name: `*${searchQuery}*` }
    );

    ticketCountFilterRef.current && fetchTicketCount(params);
  }, [ticketCountFilterRef.current]);

  // FILTER FUNCTIONS
  function PriorityFilter(value) {
    return `["priority.priority_id.keyword","must","","terms",[${JSON.stringify(
      value.priority_id
    )}]]`;
  }

  function StatusFilter(value) {
    return `["status.keyword","must","","terms",${JSON.stringify(value)}]`;
  }

  function TicketIDFilter(value) {
    return `["_id","must","","terms",["${value}"]]`;
  }

  function TicketContentFilter(value) {
    return `["_id","must","","terms",${JSON.stringify(value)}]`;
  }

  function TagsFilter(value) {
    return `["tags","must","nested","",["tag_name.keyword","must","","terms",${JSON.stringify(
      value
    )}]]`;
  }

  function CategoryFilter(value) {
    return `["custom_value","must","nested","",["field_values.id.keyword","must","","terms",${JSON.stringify(
      value
    )}]],["custom_value","must","nested","",["field_type.keyword","must","","match","category"]]`;
  }

  function AssignedToFilter(value) {
    return `["assigned_to.user_name.keyword","must","","term","${value}"]`;
  }

  function UnAssignedToFilter(value) {
    if (value) {
      // return `["assigned_to","must_not","","exists",""]`;
      return `["assigned_to.user_name.keyword","must","","term","Unassigned"]`;
    }
  }

  function sentimentFilter(value) {
    if (value === "Negative") {
      return `["sentiment_value","must","lte","range","-0.01"]`;
    } else if (value === "Positive") {
      return `["sentiment_value","must","gte","range","0.01"]`;
    } else if (value === "Neutral") {
      return `["sentiment_value","must","","match","0"]`;
    }
  }

  function ratingsFilter(rating_val, platform) {
    function singleDigitToWords(num) {
      const numberWords = {
        1: "ONE",
        2: "TWO",
        3: "THREE",
        4: "FOUR",
        5: "FIVE",
      };

      return numberWords[num] || "number out of range";
    }

    if (platform === "GMB") {
      return `["ticket_desc.keyword","must","","wildcard","*/ratings/${singleDigitToWords(
        rating_val
      )}*"]`;
    }
    // else if (platform === "Ambition Box") {
    //   return `["ticket_desc.keyword","must","","wildcard","*/ratings/${rating_val}*"]`;
    // }
    else if (platform === "Appstore") {
      return `["ticket_desc.keyword","must","","wildcard","*/ratings/${rating_val}*"]`;
    } else if (platform === "Playstore") {
      return `["ticket_desc.keyword","must","","wildcard","*/ratings/${rating_val}*"]`;
    }
  }

  function PlatformFilter(value) {
    return `["platform.keyword","must","","match","${value}"]`;
  }

  function ActivitiesFilter(value) {
    return `["activities.keyword","must","","terms",${JSON.stringify(value)}]`;
  }

  function TodayFilter() {
    return `["created_on","must","lte","range","${format(
      new Date(),
      "yyyy-MM-dd"
    )}"],["created_on","must","gte","range","${format(today, "yyyy-MM-dd")}"]`;
  }

  function ModifiedTodayFilter() {
    return `["modified_on","must","lte","range","${format(
      new Date(),
      "yyyy-MM-dd"
    )}"],["modified_on","must","gte","range","${format(today, "yyyy-MM-dd")}"]`;
  }

  function PreviousFilter() {
    return `["created_on","must","lte","range","${format(
      previousday,
      "yyyy-MM-dd"
    )}"],["created_on","must","gte","range","${format(
      previousday,
      "yyyy-MM-dd"
    )}"]`;
  }

  function ModifiedPreviousFilter() {
    return `["modified_on","must","lte","range","${format(
      previousday,
      "yyyy-MM-dd"
    )}"],["modified_on","must","gte","range","${format(
      previousday,
      "yyyy-MM-dd"
    )}"]`;
  }

  function Last2DaysFilter() {
    return `["created_on","must","lte","range","${format(
      last2days[0],
      "yyyy-MM-dd"
    )}"],["created_on","must","gte","range","${format(
      last2days[1],
      "yyyy-MM-dd"
    )}"]`;
  }

  function ModifiedOnLast2DaysFilter() {
    return `["modified_on","must","lte","range","${format(
      last2days[0],
      "yyyy-MM-dd"
    )}"],["modified_on","must","gte","range","${format(
      last2days[1],
      "yyyy-MM-dd"
    )}"]`;
  }

  function Last7DaysFilter() {
    return `["created_on","must","lte","range","${format(
      last7Days[0],
      "yyyy-MM-dd"
    )}"],["created_on","must","gte","range","${format(
      last7Days[6],
      "yyyy-MM-dd"
    )}"]`;
  }

  function ModifiedLast7DaysFilter() {
    return `["modified_on","must","lte","range","${format(
      last7Days[0],
      "yyyy-MM-dd"
    )}"],["modified_on","must","gte","range","${format(
      last7Days[6],
      "yyyy-MM-dd"
    )}"]`;
  }

  function Last30DaysFilter() {
    return `["created_on","must","lte","range","${format(
      today,
      "yyyy-MM-dd"
    )}"],["created_on","must","gte","range","${format(
      last30Days,
      "yyyy-MM-dd"
    )}"]`;
  }

  function ModifiedLast30DaysFilter() {
    return `["modified_on","must","lte","range","${format(
      today,
      "yyyy-MM-dd"
    )}"],["modified_on","must","gte","range","${format(
      last30Days,
      "yyyy-MM-dd"
    )}"]`;
  }

  function Last3MonthsFilter() {
    return `["created_on","must","lte","range","${format(
      today,
      "yyyy-MM-dd"
    )}"],["created_on","must","gte","range","${format(
      last3Month,
      "yyyy-MM-dd"
    )}"]`;
  }

  function ModifiedLast3MonthsFilter() {
    return `["modified_on","must","lte","range","${format(
      today,
      "yyyy-MM-dd"
    )}"],["modified_on","must","gte","range","${format(
      last3Month,
      "yyyy-MM-dd"
    )}"]`;
  }

  function CustomDateFilter() {
    return `["created_on","must","lte","range","${format(
      createdDate[1],
      "yyyy-MM-dd"
    )}"],["created_on","must","gte","range","${format(
      createdDate[0],
      "yyyy-MM-dd"
    )}"]`;
  }

  function ModifiedCustomDateFilter() {
    return `["modified_on","must","lte","range","${format(
      modifiedDate[1],
      "yyyy-MM-dd"
    )}"],["modified_on","must","gte","range","${format(
      modifiedDate[0],
      "yyyy-MM-dd"
    )}"]`;
  }

  // Initial useEffect call
  useEffect(() => {
    let workingProject = JSON.parse(
      localStorage.getItem("user_last_working_project")
    );

    // if (
    //   props?.userPermission === undefined ||
    //   props?.userPermission.length === 0
    // ) {
    fetchUserFilter();
    // }

    // if (
    //   props?.filterpriority === undefined ||
    //   props?.filterpriority.length === 0
    // ) {
    fetchFilterPriority();
    // }

    // if (
    //   props?.statusFilter === undefined ||
    //   Object.keys(props.statusFilter).length === 0
    // ) {
    fetchStatusFilter();
    // }

    fetchProjectById(workingProject?.project_id);

    // if (
    //   props?.platform === undefined ||
    //   Object.keys(props.platform).length === 0
    // ) {
    fetchPlatform();
    // }

    // if (
    //   props?.categoryfilter === undefined ||
    //   Object.keys(props.categoryfilter).length === 0
    // ) {
    fetchCategoryNameFilter();
    // }

    // if (
    //   props?.customticketfields_filter === undefined ||
    //   Object.keys(props.customticketfields_filter).length === 0
    // ) {
    fetchCustomticketfieldsFilter();
    // }

    const param = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };

    // if (props?.tagfilter === undefined || props.tagfilter.length === 0) {
    fetchTagFilter(param);
    // }

    fetchTicketSettings();
    fetchCustomticketfields();

    localStorage.setItem(
      "mentionsFilters",
      JSON.stringify({
        searchQuery,
        createdDatelistSelected,
        modifiedDatelistSelected,
        createdDate,
        modifiedDate,
        priority,
        platform,
        activities,
        // selectStatus,
        selected_status,
        assigned_To,
        unAssigned,
        sentiment_value,
        ratings_Value,
        tags,
        category,
        selected,
        searchTypeSelected,
        pageNumber: pageNumber,
      })
    );
  }, []);

  // Below UseEffect will trigger when local storage get updated and sets the state variable to new value
  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    if (filters) {
      filters.ticket_ids && setTicketIds(filters.ticket_ids);
      filters.searchQuery && setSearchQuery(filters.searchQuery);
      filters.searchTypeSelected &&
        setSearchTypeSelected(filters.searchTypeSelected);
      filters.priority && setPriority(filters.priority);
      filters.sentiment_value && set_sentiment_value(filters.sentiment_value);
      filters.ratings_Value && set_ratings_Value(filters.ratings_Value);
      filters.platform && setPlatform(filters.platform);
      filters.activities && setActivities(filters.activities);
      filters.selected_status && set_selected_status(filters.selected_status);
      filters.createdDatelistSelected &&
        setCreatedDatelistSelected(filters.createdDatelistSelected);
      filters.pageNumber && setPageNumber(filters.pageNumber);
      filters.modifiedDatelistSelected &&
        setModifiedDatelistSelected(filters.modifiedDatelistSelected);

      // setDate(filters.date);
      filters.tags && setTags(filters.tags);
      filters.category && setCategory(filters.category);
      filters.assigned_To && setAssigned_To(filters.assigned_To);
      filters.unAssigned && SetUnAssigned(filters.unAssigned);

      props.setSearchChanges(false);

      // setpagination_clicked(false);
    }
  }, [localStorage.getItem("mentionsFilters"), props.searchChanges]);

  // When the state values get updated the fetch function will call ticket api with updated filters

  // console.log("LOCAL_STATES", {
  //   searchQuery,
  //   createdDatelistSelected,
  //   modifiedDatelistSelected,
  //   createdDate,
  //   modifiedDate,
  //   priority,
  //   platform,
  //   activities,
  //   selectStatus,
  //   assigned_To,
  //   sentiment_value,
  //   tags,
  //   category,
  //   searchTypeSelected,
  //   selected_status,
  // });

  // Fetch Function for calling ticket api with Filters
  async function fetchFunction(page_number) {
    filters.current = [];
    ticketCountFilterRef.current = [];

    // priority
    if (priority !== null) {
      filters.current.push(PriorityFilter(priority));
      // ticketCountFilterRef.current &&
      //   ticketCountFilterRef.current.push(PriorityFilter(priority));
    }

    // search
    if (searchQuery !== null) {
      if (searchTypeSelected === "Ticket-ID") {
        filters.current.push(TicketIDFilter(searchQuery));
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(TicketIDFilter(searchQuery));
      } else if (searchTypeSelected === "Content" && ticket_ids) {
        filters.current.push(TicketContentFilter(ticket_ids));
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(TicketContentFilter(ticket_ids));
      }
    }

    // tags;
    if (tags && tags.length !== 0) {
      filters.current.push(TagsFilter(tags));
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(TagsFilter(tags));
    }

    // assigned user
    if (assigned_To && assigned_To.user_name !== null) {
      filters.current.push(AssignedToFilter(assigned_To.user_name));
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(
          AssignedToFilter(assigned_To.user_name)
        );
    }

    if (selected_status) {
      let status_array = [];
      status_array.push(selected_status);

      if (status_array?.length !== 0) {
        if (status_array[0] === "direct_close") {
          ticketCountFilterRef.current.push(
            `["direct_close","must","","match","true"]`
          );
        }
      }
    }

    // assigned user
    if (unAssigned && unAssigned !== null) {
      filters.current.push(UnAssignedToFilter(unAssigned));
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(UnAssignedToFilter(unAssigned));
    }

    // sentiment
    if (sentiment_value !== null) {
      filters.current.push(sentimentFilter(sentiment_value));
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(sentimentFilter(sentiment_value));
    }

    // sentiment
    if (ratings_Value !== null && platform) {
      filters.current.push(
        ratingsFilter(ratings_Value, platform?.platform_name)
      );
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(
          ratingsFilter(ratings_Value, platform?.platform_name)
        );
    }

    // platform
    if (platform && platform.platform_name !== null) {
      filters.current.push(PlatformFilter(platform.platform_id));
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(PlatformFilter(platform.platform_id));
    }

    // category
    if (category && category.length !== 0) {
      filters.current.push(CategoryFilter(category));
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(CategoryFilter(category));
    }

    // activities
    if (activities && activities.length !== 0) {
      let activities_id_array = activities.map((item) => {
        return item.activity_id;
      });

      filters.current.push(ActivitiesFilter(activities_id_array));
      ticketCountFilterRef.current &&
        ticketCountFilterRef.current.push(
          ActivitiesFilter(activities_id_array)
        );
    }

    // date range filter based on the created date
    if (createdDatelistSelected !== null) {
      if (createdDatelistSelected === "Today") {
        filters.current.push(TodayFilter());
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(TodayFilter());
      } else if (createdDatelistSelected === "Previous Day") {
        filters.current.push(PreviousFilter());
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(PreviousFilter());
      } else if (createdDatelistSelected === "Last 2 Days") {
        filters.current.push(Last2DaysFilter());
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(Last2DaysFilter());
      } else if (createdDatelistSelected === "Last 7 Days") {
        filters.current.push(Last7DaysFilter());
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(Last7DaysFilter());
      } else if (createdDatelistSelected === "Last 30 Days") {
        filters.current.push(Last30DaysFilter());
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(Last30DaysFilter());
      } else if (createdDatelistSelected === "Last 3 Months") {
        filters.current.push(Last3MonthsFilter());
        ticketCountFilterRef.current &&
          ticketCountFilterRef.current.push(Last3MonthsFilter());
      } else if (createdDatelistSelected === "Custom") {
        if (createdDate[0] !== null && createdDate[1] !== null) {
          filters.current.push(CustomDateFilter());
          ticketCountFilterRef.current &&
            ticketCountFilterRef.current.push(CustomDateFilter());
        }
      }
    }
    // date range filter based on the modified date
    if (modifiedDatelistSelected !== null) {
      if (modifiedDatelistSelected === "Today") {
        filters.current.push(ModifiedTodayFilter());
        ticketCountFilterRef.current.push(ModifiedTodayFilter());
      } else if (modifiedDatelistSelected === "Previous Day") {
        filters.current.push(ModifiedPreviousFilter());
        ticketCountFilterRef.current.push(ModifiedPreviousFilter());
      } else if (modifiedDatelistSelected === "Last 2 Days") {
        filters.current.push(ModifiedOnLast2DaysFilter());
        ticketCountFilterRef.current.push(ModifiedOnLast2DaysFilter());
      } else if (modifiedDatelistSelected === "Last 7 Days") {
        filters.current.push(ModifiedLast7DaysFilter());
        ticketCountFilterRef.current.push(ModifiedLast7DaysFilter());
      } else if (modifiedDatelistSelected === "Last 30 Days") {
        filters.current.push(ModifiedLast30DaysFilter());
        ticketCountFilterRef.current.push(ModifiedLast30DaysFilter());
      } else if (modifiedDatelistSelected === "Last 3 Months") {
        filters.current.push(ModifiedLast3MonthsFilter());
        ticketCountFilterRef.current.push(ModifiedLast3MonthsFilter());
      } else if (modifiedDatelistSelected === "Custom") {
        if (modifiedDate[0] !== null && modifiedDate[1] !== null) {
          filters.current.push(ModifiedCustomDateFilter());
          ticketCountFilterRef.current.push(ModifiedCustomDateFilter());
        }
      }
    }

    // STATUS ARRAY FILTERS
    if (selected_status) {
      let status_array = [];
      status_array.push(selected_status);

      if (status_array?.length !== 0) {
        if (status_array[0] === "direct_close") {
          filters.current.push(`["direct_close","must","","match","true"]`);
        } else {
          filters.current.push(
            `["status.keyword","must","","terms",${JSON.stringify(
              status_array
            )}]`
          );
        }
      }
    }

    let params = {};

    if (
      (ticket_ids && ticket_ids.length !== 0) ||
      searchQuery !== null ||
      (category && category.length !== 0) ||
      priority !== null ||
      sentiment_value !== null ||
      ratings_Value !== null ||
      assigned_To !== null ||
      unAssigned !== null ||
      createdDatelistSelected !== null ||
      modifiedDatelistSelected !== null ||
      platform !== null ||
      (activities && activities.length !== 0) ||
      (tags && tags.length !== 0) ||
      searchTypeSelected !== null ||
      selected_status !== null
    ) {
      // filter parameter
      params = {
        filters: `[${filters.current}]`,
        page_number: page_number ? page_number : 0,

        page_limit: `${page_limit}`,
        order_by: `[["${props.sort_by}", "${props.sort_order}"]]`,
      };
      Object.assign(
        params,
        searchTypeSelected === "Author" &&
          searchQuery !== null && { customer_name: `*${searchQuery}*` }
      );
    } else {
      params = {
        page_number: page_number ? page_number : 0,
        page_limit: `${page_limit}`,
        order_by: `[["${props.sort_by}", "${props.sort_order}"]]`,
      };
    }

    await ClearReduxTicketFeedStates(openedTicketPlatform);

    fetchAllTickets(params);

    let ticketCountparams = {
      filters: `[${ticketCountFilterRef.current}]`,
    };

    Object.assign(
      ticketCountparams,
      searchTypeSelected === "Author" &&
        searchQuery !== null && { customer_name: `*${searchQuery}*` }
    );

    ticketCountFilterRef.current && fetchTicketCount(ticketCountparams);

    localStorage.setItem(
      "mentionsFilters",
      JSON.stringify({
        searchQuery,
        createdDatelistSelected,
        modifiedDatelistSelected,
        createdDate,
        modifiedDate,
        priority,
        platform,
        activities,
        selected_status,
        assigned_To,
        unAssigned,
        sentiment_value,
        ratings_Value,
        tags,
        category,
        selected,
        // selected_status,

        ticket_ids,
        searchTypeSelected,
        pageNumber: page_number,
      })
    );
  }

  useEffect(() => {
    if (selected_status) {
      if (
        selected_status !== null ||
        priority !== null ||
        searchQuery !== null ||
        (createdDate[0] !== null && createdDate[1] !== null) ||
        (modifiedDate[0] !== null && modifiedDate[1] !== null) ||
        (platform && platform.platform_name !== null) ||
        sentiment_value !== null ||
        ratings_Value !== null ||
        (activities && activities.length !== 0) ||
        createdDatelistSelected !== null ||
        modifiedDatelistSelected !== null ||
        (tags && tags.length !== 0) ||
        assigned_To !== null ||
        (unAssigned !== null && unAssigned)
      ) {
        fetchFunction(pageNumber);
      }
    }
  }, [
    pageNumber,
    selected_status,
    searchQuery,
    priority,
    sentiment_value,
    ratings_Value,
    platform,
    activities,
    createdDatelistSelected,
    modifiedDatelistSelected,
    createdDate,
    modifiedDate,
    tags,
    category,
    assigned_To,
    unAssigned,
    props.sort_by,
    props.sort_order,
  ]);

  //platform list assigning
  let filteredPlatformList = [];

  let platformList = [];

  if (props.platform.hits) {
    props.platform.hits.map((item) => {
      platformList.push({
        platform_logo: item._source.platform_name,
        platform_name: item._source.platform_name,
        platform_id: item._id,
        platform_alias: item._source.alias,
      });
    });
  }

  filteredPlatformList = [...new Set(platformList)];

  // SENTIMENTAL list assigning
  const sentiment_value_list = ["Positive", "Negative", "Neutral"];
  const ratings_value_list = ["1", "2", "3", "4", "5"];

  // TAG list assigning
  const tagList = [];
  let filterTagList = [];

  if (props.tagfilter.hits) {
    props.tagfilter.hits.hits.map((item) => {
      tagList.push(item._source.tag_name);
    });
  }
  filterTagList = [...new Set(tagList)];

  // CATEGORY list assigning
  const category_List = [];

  if (props.categoryfilter.hits) {
    props.categoryfilter.hits.hits.map((item) => {
      if (item._source) {
        if (item._source.category_name !== undefined) {
          category_List.push(item._source.category_name);
        }
      }
    });
  }
  let filterdCategoryList = [];
  filterdCategoryList = [...new Set(category_List)];

  let userlist = [];

  if (props.userPermission.hits) {
    props.userPermission.hits.hits.map((val) => {
      return userlist.push({
        user_id: val._source.user_id,
        user_name: val._source.user?.username,
        org_id: val._source.organization_id?.organization_id,
        fullname: FullName(val),
      });
    });
  }
  //assigned_to agent list assigning
  let userFilterdList = [];
  if (userlist) {
    let orgId;
    if (props.projectById.found) {
      orgId = props.projectById._source.organization_id.organization_id;
    }

    userlist
      .filter((item) => item.org_id === orgId)
      .map((value) => {
        return userFilterdList.push({
          user_name: value.user_name,
          full_name: value.fullname,
          user_id: value.user_id,
        });
      });
  }

  const returnAssigneTofullname = (userID, user_name) => {
    let filtered_user =
      userlist.length > 0 &&
      userlist.filter((val) => {
        return val.user_id === userID;
      });
    return filtered_user.length > 0
      ? filtered_user[0].fullname.length > 20
        ? filtered_user[0].fullname.substring(0, 20 - 3) + "..."
        : filtered_user[0].fullname
      : user_name;
  };

  const returnTwittername = (names, handleNeeded) => {
    let twittername = "";
    twittername = names.includes(" ") ? names.split(" ") : null;

    return names.includes(" ")
      ? twittername &&
          twittername.length !== 0 &&
          `${twittername.slice(1).join(" ")} ${
            handleNeeded ? `(@${twittername[0]})` : ""
          }
          
          `
      : names;
  };

  // priority list
  let filterprioritylist = [];

  if (props.filterpriority.hits) {
    props.filterpriority.hits.forEach((value) => {
      filterprioritylist.push({
        ticket_priority_name: value._source.ticket_priority_name,
        color: value._source.color,
        priority_id: value._id,
      });
    });
  }

  const tagListmain = [];
  let filterTagListMain = [];

  if (props.tagfilter.hits) {
    props.tagfilter.hits.hits.map((item) => {
      tagListmain.push({ tag_name: item._source.tag_name, tag_id: item._id });
    });
  }
  filterTagListMain = [...new Set(tagListmain)];

  const renderPriorityColor = (p_name) => {
    let p_list = filterprioritylist.filter((val) => {
      return val.ticket_priority_name === p_name;
    });

    return `${p_list.length > 0 ? p_list[0].color : null}`;
  };

  // const returntags = (tag_array) => {
  //   let tag_string = "";
  //   tag_array.length !== 0 &&
  //     tag_array.map((item) => {
  //       tag_string =
  //         tag_string !== ""
  //           ? tag_string + "/" + item.tag_name
  //           : tag_string + item.tag_name;
  //     });

  //   return tag_string.length > 20
  //     ? tag_string.substring(0, 20 - 2) + ".."
  //     : tag_string;
  // };

  const [isLastPage, setIsLastPage] = useState(false);
  let lastPageTickets = "";

  lastPageTickets =
    props.tickets.hits &&
    props.tickets.hits.hits &&
    props.tickets.hits.hits.length;

  // pagination
  const handleChangePagination = (event, value) => {
    if (value === props.pagination.tickets.total_page_no) {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // setpagination_clicked(true);

    setPageNumber(value - 1);
    fetchFunction(value - 1);
  };

  let priority_top_3_index = [];

  if (filterprioritylist.length !== 0) {
    let new_array = filterprioritylist.slice(0, 3);
    new_array.length > 0 &&
      new_array.map((item) => {
        priority_top_3_index.push({
          priority_id: item.priority_id,
          ticket_priority_name: item.ticket_priority_name,
          color: item.color,
        });
      });
  }

  function returnStatusName(statusId) {
    return statusId === "direct_close"
      ? "Ignored"
      : statusList.length > 0 &&
          statusList.filter((item) => item.status_id === statusId).length > 0 &&
          statusList.filter((item) => item.status_id === statusId)[0]
            .status_name;
  }

  // // Final Status id assigning
  // let FinalStatusId = "";

  // statusList.length > 0 &&
  //   statusList.map((item) => {
  //     return item.final_status == true && (FinalStatusId = item.status_id);
  //   });

  let initial_status_array = [];
  let statusTop4List = [];
  let ExtraStatusList = [];

  let TicketCountData = [];

  // Initial status array assigning
  let Initial_status = statusList.filter((item) => {
    return item.status_id === i_status_id;
  });

  initial_status_array.push(Initial_status[0]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [number, setNumber] = useState(4);

  useEffect(() => {
    if (screenWidth < 1500 && screenWidth > 1300) {
      setNumber(3);
    } else if (screenWidth <= 1300 && screenWidth > 1200) {
      setNumber(2);
    } else if (screenWidth <= 1200) {
      setNumber(0);
    } else {
      setNumber(4);
    }
  }, [screenWidth]);

  // top 4 status list to show on right side of ticket page
  statusTop4List = statusList
    .filter((item) => item.status_id !== i_status_id)
    .slice(0, number);

  // additional status list to show on more dropdown of ticket page
  ExtraStatusList = statusList
    .filter((item) => item.status_id !== i_status_id)
    .slice(number, statusList.length);

  if (true) {
    ExtraStatusList.push({
      status_id: "direct_close",
      status_name: "Ignored",
      initial_status: false,
      final_status: false,
    });
  }

  if (
    props.ticketCount &&
    props.ticketCount.data &&
    props.ticketCount.data.length !== 0
  ) {
    TicketCountData = [...props.ticketCount.data];
  }

  function returnTicketStatusCount(statusId) {
    return TicketCountData.length > 0 &&
      TicketCountData.filter((item) => item.key === statusId).length > 0
      ? TicketCountData.filter((item) => item.key === statusId).map(
          (item) => item.count
        )
      : 0;
  }

  function returnTicketPriorityCount(priorityName) {
    return TicketCountData.length > 0 &&
      TicketCountData.filter((item) => item.key === selected_status).length > 0
      ? TicketCountData.filter(
          (item) => item.key === selected_status
        )[0].priority.filter((item) => item.key === priorityName).length > 0
        ? TicketCountData.filter((item) => item.key === selected_status)[0]
            .priority.filter((item) => item.key === priorityName)
            .map((item) => item.count)
        : 0
      : 0;
  }

  const handlePriority = (event, newPriority) => {
    if (newPriority) {
      setPriority(newPriority);
      setPageNumber(0);
    }
  };

  const handleStatus = async (event, newStatus) => {
    if (newStatus) {
      set_selected_status(newStatus);
      setPageNumber(0);
    }
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const [openPopper, setOpenPopper] = useState(false);
  const morePopperRef = React.useRef(null);
  const prevOpen = React.useRef(openPopper);

  const handleCloseMore = (event) => {
    if (morePopperRef.current && morePopperRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
  };

  const handleClick = (event) => {
    setOpenPopper((prevOpen) => !prevOpen);
  };
  useEffect(() => {
    if (prevOpen.current === true && openPopper === false) {
      morePopperRef.current.focus();
    }
    prevOpen.current = openPopper;
  }, [openPopper]);

  const handleChangeDropDowns = (event) => {
    const value = event.target.value;

    if (selected.indexOf(value) !== -1) {
      // if value already present
      const newSelected = selected.filter((s) => s !== value);
      setSelected(newSelected);
    } else {
      // if value not present
      setSelected([...selected, value]);
    }
  };

  const [showBox, setShowBox] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [CategoryUpdateData, setCategoryUpdateData] = useState(null);
  const [tagUpdateData, setTagUpdateData] = useState(null);

  const [openedTicketPlatform, setOpenTicketPlatform] = useState(null);

  const handleButtonClick = async (item) => {
    setOpenTicketPlatform(item._source?.platform?.platform_name);
    if (item._id !== ticketData._id) {
      await ClearReduxTicketFeedStates(openedTicketPlatform);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowBox(true);
    setTicketData(item);
  };

  async function ClearReduxTicketFeedStates(previousOpenedPlatform) {
    if (previousOpenedPlatform === "Ambition Box") {
      await props.dispatch(
        AmbitionBoxFeedsAction.requestAmbitionBoxFeedClear()
      );
    }
    if (previousOpenedPlatform === "Appstore") {
      await props.dispatch(AppStoreAction.requestAppStoreClear());
    }
    if (previousOpenedPlatform === "Consumer Complaints") {
    }
    if (previousOpenedPlatform === "Discord") {
    }
    if (previousOpenedPlatform === "Email") {
      await props.dispatch(EmailFeedsAction.requestEmailFeedsClear());
    }
    if (previousOpenedPlatform === "Facebook") {
      await props.dispatch(FacebookAction.requestFacebookClear());

      await props.dispatch(FacebookDmAction.requestFacebookDmClear());
    }
    if (previousOpenedPlatform === "GMB") {
      await props.dispatch(
        GmbQuestionFeedsAction.requestGmbQuestionFeedClear()
      );

      await props.dispatch(GmbReviewAction.requestGmbReviewFeedClear());
    }
    if (previousOpenedPlatform === "Glassdoor") {
      await props.dispatch(GlassdoorFeedsAction.requestGlassdoorFeedClear());
    }
    if (previousOpenedPlatform === "Indeed") {
      await props.dispatch(IndeedFeedAction.requestIndeedFeedClear());
    }
    if (previousOpenedPlatform === "Instagram") {
      await props.dispatch(
        InstagramDirectMessageAction.requestInstagramDMClear()
      );

      await props.dispatch(InstagramFeedsAction.requestInstagramFeedsClear());
    }

    if (previousOpenedPlatform === "Pinterest") {
      await props.dispatch(PinterestFeedsAction.requestPinterestFeedsClear());
    }
    if (previousOpenedPlatform === "Playstore") {
      await props.dispatch(PlayStoreAction.requestPlayStoreClear());
    }
    if (previousOpenedPlatform === "Quora") {
      await props.dispatch(QuoraFeedsAction.requestQuoraFeedClear());
    }

    if (previousOpenedPlatform === "Reddit") {
      await props.dispatch(RedditFeedAction.requestRedditFeedClear());
      await props.dispatch(RedditFeedAction.requestRedditCommentsClear());
      await props.dispatch(RedditFeedAction.requestRedditPostsClear());
    }
    if (previousOpenedPlatform === "Telegram") {
      await props.dispatch(TelegramAction.requestTelegramFeedsClear());
    }
    if (previousOpenedPlatform === "Threads") {
    }

    if (previousOpenedPlatform === "Trust Pilot") {
      await props.dispatch(TrustpilotFeedsAction.requestTrustpilotFeedsClear());
    }

    if (previousOpenedPlatform === "Twitter") {
      await props.dispatch(TwitterDirectMessageAction.requestTwitterDmClear());
      await props.dispatch(TwitterAction.requestTwitterFeedClear());
    }

    if (previousOpenedPlatform === "Whatsapp") {
      await props.dispatch(WhatsAppFeedAction.requestWhatsAppFeedClear());
    }
    if (previousOpenedPlatform === "Threads") {
    }

    await props.dispatch(TicketsAction.requestTicketByIdClear());
  }

  useEffect(() => {
    setTicketData(
      props.tickets &&
        props.tickets.hits &&
        props.tickets.hits.hits.length !== 0 &&
        props.tickets.hits.hits[0]
    );
  }, [props.tickets]);

  // useEffect(() => {
  //   !showBox && props.dispatch(TicketsAction.requestTicketsById());
  // }, [showBox]);

  const [costomer_popover_anchorEl, set_costomer_popover_anchorEl] =
    React.useState(null);

  const handlePopoverOpen = (event) => {
    set_costomer_popover_anchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    set_costomer_popover_anchorEl(null);
  };

  const costomer_popover_open = Boolean(costomer_popover_anchorEl);

  const [customer_id, set_customer_id] = useState(null);
  const [customer_platform, set_customer_platform] = useState(null);

  const handleMouseHoverStateChange = (customer_id, platform) => {
    set_customer_id(customer_id);
    set_customer_platform(platform);
  };

  const hanldeInfluencerPut = (item) => {
    let customer_id =
      item._source.customer_id && item._source.customer_id.customer_id;

    let params = {
      filters: `[${filters.current}]`,
      page_number: pageNumber,
      page_limit: `${page_limit}`,
      order_by: '[["modified_on", "desc"]]',
    };

    if (item._source.customer_id) {
      if (item._source.customer_id.is_influencer) {
        let data = { is_influencer: false };

        props
          .dispatch(
            CustomerDataAction.requestPutCustomerData(
              JSON.stringify(data),
              customer_id
            )
          )
          .then(() => {
            props.dispatch(TicketsAction.requestTickets(params));
          });
      } else {
        let data = { is_influencer: true };

        props
          .dispatch(
            CustomerDataAction.requestPutCustomerData(
              JSON.stringify(data),
              customer_id
            )
          )
          .then(() => {
            props.dispatch(TicketsAction.requestTickets(params));
          });
      }
    }
  };

  // ticket_closure_max_duration
  let ticket_closure_max_duration =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.ticket_closure_max_duration &&
    props.ticketSettings.hits[0]._source.ticket_closure_max_duration;

  let flr_time =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source.flr_time &&
    props.ticketSettings.hits[0]._source.flr_time;

  let flr_breach_alert_notification_window_time =
    props.ticketSettings.hits &&
    props.ticketSettings.hits[0] &&
    props.ticketSettings.hits[0]._source
      .flr_breach_alert_notification_window_time &&
    props.ticketSettings.hits[0]._source
      .flr_breach_alert_notification_window_time;

  const [categoryUpdateOpen, setCategoryUpdateOpen] = useState(false);
  const [tagUpdateOpen, setTagUpdateOpen] = useState(false);

  // category edit button open close model
  const openCloseModal = useCallback((open) => {
    setCategoryUpdateOpen(open);
  }, []);

  // tag edit button open close model
  const tagOpenCloseModal = useCallback((open) => {
    setTagUpdateOpen(open);
  }, []);

  const [translatedDescription, setTranslatedDescription] = useState([]);

  const returnCustomValuesforChip = (sub_value_id) => {
    let category_values =
      props.customticketfields &&
      props.customticketfields.hits &&
      props.customticketfields.hits.hits[0]._source.field_values;

    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals =
      category_values &&
      category_values.length !== 0 &&
      ReturnCategoryName(category_values, sub_value_id);
    return custom_field_vals;
  };

  const [selected_tickets, set_Selected_tickets] = useState(() => {
    if (localStorage.getItem("selected_tickets_for_bulk_update")) {
      return JSON.parse(
        localStorage.getItem("selected_tickets_for_bulk_update")
      );
    } else {
      return [];
    }
  });

  useEffect(() => {
    set_Selected_tickets(
      localStorage.getItem("selected_tickets_for_bulk_update")
        ? JSON.parse(localStorage.getItem("selected_tickets_for_bulk_update"))
        : []
    );
  }, [localStorage.getItem("selected_tickets_for_bulk_update")]);

  const returnCheckBoxValue = (item) => {
    let statuscheck =
      selected_tickets && selected_tickets.length > 0
        ? selected_tickets.some((obj) => obj._id === item._id)
        : false;

    return statuscheck;
  };

  function returnStars(rating) {
    let i;
    switch (rating) {
      case "ONE":
        i = 1;
        break;
      case "TWO":
        i = 2;
        break;
      case "THREE":
        i = 3;
        break;
      case "FOUR":
        i = 4;
        break;
      case "FIVE":
        i = 5;
        break;
    }

    let displayStars = [];

    for (let k = 1; k <= i; k++) {
      displayStars.push(<StarIcon sx={{ color: "gold" }} />);
    }

    return displayStars;
  }

  const returnStarsIcon = (stars) => {
    let i = stars;
    let displayStars = [];
    if (stars === i) {
      for (let k = 1; k <= i; k++) {
        displayStars.push(<StarIcon sx={{ color: "gold" }} />);
      }
    }
    return displayStars;
  };

  let visibleReplyBtn = (check_platform) => {
    let Boolean = true;

    if (
      check_platform?._source?.platform?.platform_name === "Appstore" ||
      check_platform?._source?.platform?.platform_name ===
        "Consumer Complaints" ||
      check_platform?._source?.platform?.platform_name === "Indeed" ||
      check_platform?._source?.platform?.platform_name === "Ambition Box" ||
      // check_platform?._source?.platform?.platform_name === "GMB" ||
      check_platform?._source?.platform?.platform_name === "Quora"
    ) {
      Boolean = false;
    }

    if (check_platform?._source?.platform?.platform_name === "Reddit") {
      Boolean = false;
    }

    if (check_platform?._source?.platform?.platform_name === "Instagram") {
      if (
        check_platform?._source?.activities?.activities_id === "25" ||
        check_platform?._source?.activities?.activities_id === "13" ||
        check_platform?._source?.activities?.activities_id === "14"
      ) {
        Boolean = false;
      }
    }

    return Boolean;
  };

  const [open, setOpen] = useState(false);

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  let [checkboxSelectedTickets, setCheckboxSelectedTickets] = useState(0);

  return (
    <Box height={"100%"} ref={TicketsBoxRef}>
      <ListViewFilters
        open={props.open}
        ratings_value_list={ratings_value_list}
        onClose={props.onClose}
        customticketfields={props.customticketfields}
        customticketfields_filter={props.customticketfields_filter}
        clearFilter={(value) => {
          if (value === "priority") {
            setPriority(null);
            // set_selected_status("");
          }
          if (value === "date") {
            setCreatedDatelistSelected(null);
            setCreatedDate([null, null]);
            setModifiedDatelistSelected(null);
            setModifiedDate([null, null]);
          }
          if (value === "platform") {
            setPlatform(null);
            setActivities([]);
          }
          if (value === "category") {
            setCategory([]);
          }
          if (value === "agent") {
            setAssigned_To(null);
            SetUnAssigned(false);
          }
          if (value === "sentiment") {
            set_sentiment_value(null);
          }
          if (value === "ratings") {
            set_ratings_Value(null);
          }
          // s
          // setSearchQuery(null)
          setTags([]);
          // setSearchTypeSelected(null);
        }}
        saveFilter={(filters) => {
          !filters.searchQuery && setSearchQuery(null);
          !filters.createdDatelistSelected && setCreatedDatelistSelected(null);
          !filters.createdDate && setCreatedDate([null, null]);
          !filters.modifiedDatelistSelected &&
            setModifiedDatelistSelected(null);
          !filters.modifiedDate && setModifiedDate([null, null]);
          !filters.priority && setPriority(null);
          !filters.platform && setPlatform(null);
          !filters.activities && setActivities([]);
          !filters.selected_status && set_selected_status([]);
          !filters.assigned_To && setAssigned_To(null);
          !filters.unAssigned && SetUnAssigned(false);
          !filters.sentiment_value && set_sentiment_value(null);
          !filters.ratings_Value && set_ratings_Value(null);
          !filters.tags && setTags([]);
          !filters.category && setCategory([]);
          !filters.searchTypeSelected && setSearchTypeSelected(null);
        }}
        priority_list={filterprioritylist}
        statusList={statusList}
        sentiment_value_list={sentiment_value_list}
        filteredPlatformList={filteredPlatformList}
        filterdCategoryList={filterdCategoryList}
        dateSelecte={dateSelecte}
        dateSelected={dateSelected}
        filterTagList={filterTagList}
        userFilterdList={userlist}
        fetchFunction={fetchFunction}
        setFilterOpen={props.setFilterOpen}
      />

      <Box sx={{ width: 500 }}>
        <Snackbar
          autoHideDuration={1500}
          // open={open}
          TransitionComponent={Slide}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {`${checkboxSelectedTickets} ticket(s) has been selected`}
          </Alert>
        </Snackbar>
      </Box>
      <Box ref={StatusbarRef}>
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{
            backgroundColor: `${theme.palette.background.primary}`,
            // height: "40px",
            padding: 0,
            margin: 0,
          }}
        >
          <Grid
            item
            container
            xl={7}
            lg={8.5}
            md={11}
            sm={6}
            xs={6}
            // gap={1}
            justifyContent={"flex-start"}
            alignItems="center"
          >
            <Grid item xl={12} lg={12} md={12} sm={2} xs={2} display={"flex"}>
              {initial_status_array[0] && (
                <ToggleButtonGroup
                  exclusive
                  value={selected_status}
                  onChange={handleStatus}
                  aria-label="status"
                  style={{
                    border: "none",
                  }}
                  sx={{
                    "&.MuiToggleButtonGroup-root": {
                      padding: "0px",
                      margin: "0px",
                    },
                  }}
                >
                  <ToggleButton
                    value={
                      initial_status_array[0]
                        ? initial_status_array[0].status_id
                        : null
                    }
                    disabled={isRequesting}
                    aria-label="status"
                    style={{
                      border: "none",
                      padding: 0,
                      margin: 0,
                      textTransform: "none",
                    }}
                    sx={{
                      "&.MuiToggleButton-root": {
                        padding: "0px",
                        margin: "0px",
                      },
                      "&.Mui-selected": {
                        fontWeight: "bold",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItem>
                      <p
                        className="p-0 m-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {initial_status_array[0]
                          ? initial_status_array[0].status_name
                          : null}
                      </p>
                      <Typography
                        component="div"
                        sx={{
                          height: 18,
                          width: "fit-content",
                          borderRadius: 6,
                          backgroundColor: "white",
                          border: "1px solid #86aeb6",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          marginLeft: 1,
                          paddingX: "5px",
                          paddingY: 1,
                          letterSpacing: 1,
                        }}
                      >
                        {`${returnTicketStatusCount(
                          initial_status_array[0].status_id
                        )}`}
                      </Typography>
                    </ListItem>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              <ToggleButtonGroup
                value={priority}
                exclusive
                onChange={handlePriority}
                aria-label="priority"
                sx={{
                  "&.MuiToggleButtonGroup-root": {
                    // height: "40px",
                  },
                }}
              >
                {priority_top_3_index.map((item) => (
                  <ToggleButton
                    value={item ? item : null}
                    disabled={isRequesting}
                    aria-label="laptop"
                    style={{
                      border: "none",
                      padding: 0,
                      margin: 0,
                      textTransform: "none",
                    }}
                    sx={{
                      "&.MuiToggleButton-root": {
                        // height: "10px",
                      },
                      "&.Mui-selected": {
                        fontWeight: "bold",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ListItem>
                      <Box
                        // mr={1}
                        width={10}
                        height={10}
                        borderRadius="50%"
                        sx={{
                          backgroundColor: renderPriorityColor(
                            item.ticket_priority_name
                          ),
                        }}
                      ></Box>
                      <Grid
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        title={
                          item
                            ? `${
                                item.ticket_priority_name
                              }  (${returnTicketPriorityCount(
                                item.ticket_priority_name
                              )})`
                            : null
                        }
                      >
                        <span style={{ display: "flex" }}>
                          <p className="p-0 m-0 ml-1">
                            {item
                              ? item.ticket_priority_name.length > 10
                                ? item.ticket_priority_name.substring(
                                    0,
                                    10 - 3
                                  ) + "..."
                                : item.ticket_priority_name
                              : null}
                          </p>
                          <p className="p-0 m-0 ml-1">
                            {item &&
                              `(${returnTicketPriorityCount(
                                item.ticket_priority_name
                              )})`}
                          </p>
                        </span>
                      </Grid>
                    </ListItem>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <DateSelectionPopper
                dateSelecte={dateSelecte}
                date={createdDate}
                setDate={setCreatedDate}
                setPageNumber={setPageNumber}
                createdDatelistSelected={createdDatelistSelected}
                setCreatedDatelistSelected={setCreatedDatelistSelected}
                setModifiedDate={setModifiedDate}
                setModifiedDatelistSelected={setModifiedDatelistSelected}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            xl={5}
            lg={3.5}
            md={1}
            sm={6}
            xs={6}
            spacing={2}
            display={"flex"}
            justifyContent={"flex-end"}
            // alignItems="center"
          >
            <Grid
              item
              xl={1}
              lg={1}
              md={4}
              sm={4}
              xs={2}
              gap={1}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Stack direction="row" spacing={4}>
                <ToggleButtonGroup
                  exclusive
                  value={selected_status}
                  onChange={handleStatus}
                  aria-label="status"
                  style={{
                    border: "none",
                  }}
                  sx={{
                    "&.MuiToggleButtonGroup-root": {
                      // height: "40px",
                    },
                  }}
                >
                  {statusTop4List.map((item) => (
                    <ToggleButton
                      disabled={isRequesting}
                      value={item ? item.status_id : null}
                      aria-label="laptop"
                      style={{
                        border: "none",
                        padding: 0,
                        margin: 0,
                        textTransform: "none",
                      }}
                      sx={{
                        "&.MuiToggleButton-root": {
                          // height: "10px",
                        },

                        "&.Mui-selected": {
                          fontWeight: "bold",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        item
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "100px",
                        }}
                        title={
                          item
                            ? `${item.status_name}  (${returnTicketStatusCount(
                                item.status_id
                              )})`
                            : null
                        }
                      >
                        <span style={{ display: "flex" }}>
                          <p className="p-0 m-0">
                            {item
                              ? item.status_name.length > 12
                                ? item.status_name.substring(0, 12 - 3) + "..."
                                : item.status_name
                              : null}
                          </p>
                          <p className="p-0 m-0 ml-1">
                            {`(${returnTicketStatusCount(item.status_id)})`}
                          </p>
                        </span>
                      </Grid>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
              {ExtraStatusList.length > 0 && (
                <>
                  <Box
                    sx={{ display: "flex", cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    <Typography className="p-0 m-0">More</Typography>
                    <Typography
                      component={"div"}
                      ref={morePopperRef}
                      id="composition-button"
                      aria-controls={
                        openPopper ? "composition-menu" : undefined
                      }
                      aria-expanded={openPopper ? "true" : undefined}
                      aria-haspopup="true"
                      style={{ backgroundColor: "none" }}
                    >
                      {/* <Typography className="p-0 m-0">More</Typography> */}
                      {!openPopper ? (
                        <KeyboardArrowDownIcon
                          sx={{
                            fontSize: "16px",
                            color: theme.palette.primary.main,
                          }}
                        />
                      ) : (
                        <KeyboardArrowUpIcon
                          sx={{
                            fontSize: "16px",
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </Typography>
                  </Box>
                  <Popper
                    sx={{
                      width: 200,
                      zIndex: 1,
                      position: "relative",
                      boxShadow: theme.shadows,
                    }}
                    open={openPopper}
                    anchorEl={morePopperRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper sx={{ p: 1.2 }} elevation={4}>
                          <ClickAwayListener onClickAway={handleCloseMore}>
                            <div
                              autoFocusItem={openPopper}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                            >
                              {ExtraStatusList.length > 0 &&
                                ExtraStatusList.map((item, index) => (
                                  <>
                                    <Box
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                      }}
                                    >
                                      <ToggleButtonGroup
                                        exclusive
                                        value={selected_status}
                                        onChange={handleStatus}
                                        aria-label="status"
                                        style={{
                                          border: "none",
                                        }}
                                        sx={{
                                          "&.MuiToggleButtonGroup-root": {
                                            // height: "40px",
                                          },
                                        }}
                                      >
                                        <ToggleButton
                                          value={item.status_id}
                                          disabled={isRequesting}
                                          aria-label="status"
                                          style={{
                                            border: "none",
                                            padding: 0,
                                            margin: 0,
                                            textTransform: "none",
                                          }}
                                          sx={{
                                            "&.MuiToggleButton-root": {
                                              // height: "10px",
                                            },

                                            "&.Mui-selected": {
                                              fontWeight: "bold",
                                              backgroundColor: "transparent",
                                            },
                                          }}
                                        >
                                          <ListItem>
                                            <p className="p-0 m-0">
                                              {item.status_name}
                                            </p>
                                            <p className="p-0 m-0 ml-1">
                                              {`(${returnTicketStatusCount(
                                                item.status_id
                                              )})`}
                                            </p>
                                          </ListItem>
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </Box>
                                  </>
                                ))}
                            </div>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        height="auto"
        position="relative"
        // className="bg-primary"
        ref={filtersBoxRef}
        sx={{
          borderBottomLeftRadius: !showBox ? 6 : 0,
          borderBottomRightRadius: !showBox ? 6 : 0,
        }}
      >
        {(searchQuery !== null ||
          priority !== null ||
          sentiment_value !== null ||
          ratings_Value !== null ||
          (assigned_To && assigned_To.user_name !== null) ||
          createdDatelistSelected !== null ||
          modifiedDatelistSelected !== null ||
          platform !== null ||
          (activities && activities.length !== 0) ||
          category !== null ||
          (unAssigned !== null && unAssigned) ||
          selected_status !== null) && (
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignItems={"center"}
            justifyContent="flex-start"
            sx={{
              backgroundColor: `white`,
              height: "100%",
              padding: 0,
              margin: 0,
              // borderBottom: "1px solid gray",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              paddingY: "5px",
              boxShadow: `${theme.customer_popover_shadows}`,
              borderBottomLeftRadius: !showBox ? 6 : 0,
              borderBottomRightRadius: !showBox ? 6 : 0,
            }}
          >
            {selected_status !== null && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Status :{" "}
                  <Chip
                    label={returnStatusName(selected_status)}
                    onDelete={() => {
                      set_selected_status(i_status_id);
                      setPageNumber(0);
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
            {category && category.length > 0 && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Category :{" "}
                  {category.map((item) => (
                    <Chip
                      label={
                        item &&
                        returnCustomValuesforChip(item) &&
                        returnCustomValuesforChipAsString(item)
                      }
                      onDelete={() => {
                        let filters = JSON.parse(
                          localStorage.getItem("mentionsFilters")
                        );

                        const index = filters.category.findIndex(
                          (value) => value === item
                        );
                        let newCategory = [
                          ...filters.category.slice(0, index),
                          ...filters.category.slice(index + 1),
                        ];

                        filters.category = newCategory;
                        filters.pageNumber = `0`;
                        setPageNumber(0);

                        localStorage.setItem(
                          "mentionsFilters",
                          JSON.stringify(filters)
                        );
                        setCategory(newCategory);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  ))}
                </Grid>
              </Grid>
            )}

            {platform && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Platform :{" "}
                  <Chip
                    label={platform && platform.platform_name}
                    onDelete={() => {
                      let filters = JSON.parse(
                        localStorage.getItem("mentionsFilters")
                      );
                      filters.platform = null;
                      filters.ratings_Value = null;
                      filters.activities = [];
                      filters.pageNumber = `0`;

                      setPlatform(null);
                      set_ratings_Value(null);
                      setActivities([]);
                      setPageNumber(0);
                      localStorage.setItem(
                        "mentionsFilters",
                        JSON.stringify(filters)
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
            {activities && activities.length !== 0 && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Activities :{" "}
                  {activities &&
                    activities.length !== 0 &&
                    activities.map((activity) => (
                      <Chip
                        style={{
                          marginRight: 2,
                        }}
                        label={activity && activity.activity_name}
                        onDelete={async () => {
                          let filters = JSON.parse(
                            localStorage.getItem("mentionsFilters")
                          );
                          let updatedActivities = activities.filter(
                            (obj) => obj.activity_id !== activity.activity_id
                          );

                          filters.activities = updatedActivities;
                          filters.pageNumber = `0`;

                          setActivities(updatedActivities);
                          setPageNumber(0);

                          localStorage.setItem(
                            "mentionsFilters",
                            JSON.stringify(filters)
                          );
                        }}
                        variant="outlined"
                        size="small"
                      />
                    ))}
                </Grid>
              </Grid>
            )}
            {searchQuery && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Search :{" "}
                  <Chip
                    label={searchQuery}
                    onDelete={async () => {
                      let filters = JSON.parse(
                        localStorage.getItem("mentionsFilters")
                      );
                      filters.searchQuery = null;
                      filters.searchTypeSelected = null;
                      filters.pageNumber = `0`;

                      setSearchTypeSelected(null);
                      setSearchQuery(null);
                      setTicketIds([]);
                      setPageNumber(0);

                      props.setSearchQuery("");
                      localStorage.setItem(
                        "mentionsFilters",
                        JSON.stringify(filters)
                      );
                    }}
                    sx={{
                      width: "auto",
                      maxWidth: "70vw",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
            {createdDatelistSelected && (
              <Grid sx={{ paddingLeft: 1 }}>
                {createdDatelistSelected &&
                createdDatelistSelected !== "Custom" ? (
                  <Grid>
                    Created Date :{" "}
                    <Chip
                      label={createdDatelistSelected}
                      onDelete={async () => {
                        let filters = JSON.parse(
                          localStorage.getItem("mentionsFilters")
                        );
                        filters.createdDatelistSelected = null;
                        filters.pageNumber = `0`;

                        setCreatedDatelistSelected(null);
                        setPageNumber(0);

                        localStorage.setItem(
                          "mentionsFilters",
                          JSON.stringify(filters)
                        );
                        // setpagination_clicked(false);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                ) : (
                  createdDate &&
                  createdDate[0] && (
                    <Grid>
                      Created Date :{" "}
                      <Chip
                        label={`${new Date(createdDate[0]).toLocaleDateString(
                          "en-US"
                        )} - ${new Date(createdDate[1]).toLocaleDateString(
                          "en-US"
                        )}`}
                        onDelete={async () => {
                          let filters = JSON.parse(
                            localStorage.getItem("mentionsFilters")
                          );
                          filters.createdDate = [null, null];
                          filters.pageNumber = `0`;
                          setPageNumber(0);
                          setCreatedDatelistSelected(null);
                          setCreatedDate([null, null]);
                          localStorage.setItem(
                            "mentionsFilters",
                            JSON.stringify(filters)
                          );
                        }}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  )
                )}
              </Grid>
            )}

            {modifiedDatelistSelected && (
              <Grid sx={{ paddingLeft: 1 }}>
                {modifiedDatelistSelected &&
                modifiedDatelistSelected !== "Custom" ? (
                  <Grid>
                    Modified Date :{" "}
                    <Chip
                      label={modifiedDatelistSelected}
                      onDelete={async () => {
                        let filters = JSON.parse(
                          localStorage.getItem("mentionsFilters")
                        );
                        filters.modifiedDatelistSelected = null;
                        filters.pageNumber = `0`;

                        setModifiedDatelistSelected(null);
                        setPageNumber(0);

                        localStorage.setItem(
                          "mentionsFilters",
                          JSON.stringify(filters)
                        );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                ) : (
                  modifiedDate &&
                  modifiedDate[0] && (
                    <Grid>
                      Modified Date :{" "}
                      <Chip
                        label={`${new Date(modifiedDate[0]).toLocaleDateString(
                          "en-US"
                        )} - ${new Date(modifiedDate[1]).toLocaleDateString(
                          "en-US"
                        )}`}
                        onDelete={async () => {
                          let filters = JSON.parse(
                            localStorage.getItem("mentionsFilters")
                          );
                          filters.modifiedDate = [null, null];
                          filters.pageNumber = `0`;
                          setPageNumber(0);

                          setModifiedDatelistSelected(null);
                          setModifiedDate([null, null]);
                          localStorage.setItem(
                            "mentionsFilters",
                            JSON.stringify(filters)
                          );
                        }}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  )
                )}
              </Grid>
            )}

            {priority && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Priority :{" "}
                  <Chip
                    label={priority.ticket_priority_name}
                    onDelete={async () => {
                      let filters = JSON.parse(
                        localStorage.getItem("mentionsFilters")
                      );
                      filters.priority = null;
                      filters.pageNumber = `0`;

                      setPriority(null);
                      setPageNumber(0);

                      localStorage.setItem(
                        "mentionsFilters",
                        JSON.stringify(filters)
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
            {assigned_To && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Assigned :{" "}
                  <Chip
                    label={assigned_To.user_name}
                    onDelete={async () => {
                      let filters = JSON.parse(
                        localStorage.getItem("mentionsFilters")
                      );
                      filters.assigned_To = null;
                      filters.pageNumber = `0`;

                      setAssigned_To(null);
                      setPageNumber(0);

                      localStorage.setItem(
                        "mentionsFilters",
                        JSON.stringify(filters)
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
            {unAssigned && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  <Chip
                    label={unAssigned && "Un Assigned"}
                    onDelete={async () => {
                      let filters = JSON.parse(
                        localStorage.getItem("mentionsFilters")
                      );
                      filters.unAssigned = false;
                      filters.pageNumber = `0`;

                      SetUnAssigned(null);
                      setPageNumber(0);

                      localStorage.setItem(
                        "mentionsFilters",
                        JSON.stringify(filters)
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}
            {sentiment_value && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Sentiment Value :{" "}
                  <Chip
                    label={sentiment_value}
                    onDelete={async () => {
                      let filters = JSON.parse(
                        localStorage.getItem("mentionsFilters")
                      );
                      filters.sentiment_value = null;
                      filters.pageNumber = `0`;

                      set_sentiment_value(null);
                      setPageNumber(0);

                      localStorage.setItem(
                        "mentionsFilters",
                        JSON.stringify(filters)
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}

            {ratings_Value && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Ratings :{" "}
                  <Chip
                    label={ratings_Value}
                    onDelete={async () => {
                      let filters = JSON.parse(
                        localStorage.getItem("mentionsFilters")
                      );
                      filters.ratings_Value = null;
                      filters.pageNumber = `0`;

                      set_ratings_Value(null);
                      setPageNumber(0);

                      localStorage.setItem(
                        "mentionsFilters",
                        JSON.stringify(filters)
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            )}

            {tags && tags.length > 0 && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Tags :{" "}
                  {tags &&
                    tags.length !== 0 &&
                    tags.map((tag) => (
                      <Chip
                        label={tag}
                        onDelete={async () => {
                          let filters = JSON.parse(
                            localStorage.getItem("mentionsFilters")
                          );
                          let updatedTags = tags.filter((obj) => obj !== tag);

                          filters.tags = updatedTags;
                          filters.pageNumber = `0`;

                          setTags(updatedTags);
                          setPageNumber(0);

                          localStorage.setItem(
                            "mentionsFilters",
                            JSON.stringify(filters)
                          );
                        }}
                        variant="outlined"
                        size="small"
                      />
                    ))}
                </Grid>
              </Grid>
            )}

            {props.selectionModel && props.selectionModel.length > 0 && (
              <Grid sx={{ paddingLeft: 1 }}>
                <Grid>
                  Selected Tickets :{" "}
                  {props.selectionModel && (
                    <Chip
                      label={props.selectionModel.length}
                      onDelete={async () => {
                        props.setSelectionModel([]);
                        set_Selected_tickets([]);
                        setPageNumber(0);
                        fetchFunction(0);
                        localStorage.getItem(
                          "selected_tickets_for_bulk_update"
                        ) &&
                          localStorage.removeItem(
                            "selected_tickets_for_bulk_update"
                          );
                      }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>

      <Box position="relative">
        <Box>
          <LoadingIndicator isActive={isRequesting}>
            {!isRequesting ? (
              <>
                <Grid
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  alignItems={"flex-start"}
                  justifyContent="center"
                  // className="scrollable"
                >
                  <Grid
                    item
                    xl={showBox ? 3 : 12}
                    lg={showBox ? 3 : 12}
                    md={showBox ? 3 : 12}
                    sm={showBox ? 3 : 12}
                    xs={showBox ? 3 : 12}
                  >
                    {!isRequesting && props.tickets.hits ? (
                      <Box>
                        {showBox && (
                          <Box
                            width="100%"
                            sx={{
                              height: "40px",
                              // marginTop: 2,
                              borderTop: `0.5px solid ${theme.palette.background.border}`,
                              borderBottom: `0.5px solid ${theme.palette.background.border}`,
                            }}
                          >
                            <Grid
                              item
                              container
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              alignItems="center"
                              justifyContent={"space-between"}
                              className="m-0 p-0"
                              sx={{
                                backgroundColor: "white",
                                borderBottom: `0.5px solid ${theme.palette.background.border}`,
                                height: "inherit",
                              }}
                            >
                              <Grid
                                item
                                alignItems="center"
                                justifyContent={"center"}
                                px={2.5}
                              >
                                <ListItem
                                  className="m-0 p-0"
                                  onClick={() => {
                                    setShowBox(false);
                                    setReplyClicked(false);
                                    setEmailClicked(false);
                                    fetchFunction(pageNumber);
                                    props.dispatch(
                                      TicketsAction.requestTicketByIdClear()
                                    );
                                  }}
                                >
                                  <ArrowCircleLeftOutlinedIcon
                                    style={{
                                      marginRight: 3,
                                      cursor: "pointer",
                                    }}
                                  />
                                  <p
                                    className="m-0 p-0"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Back to Ticket List
                                  </p>
                                </ListItem>
                              </Grid>
                            </Grid>
                          </Box>
                        )}

                        <Box
                          className="scrollable"
                          sx={{
                            height: `${
                              TicketBoxHeight -
                              statusbarHeight -
                              FiltersHeight -
                              paginationHeight -
                              (showBox ? 40 : 0)
                            }px`,
                          }}
                        >
                          {props.tickets.hits.hits.length !== 0 ? (
                            props.tickets.hits.hits.map(
                              (item, key) =>
                                item._source && (
                                  <Box width={"100%"}>
                                    {!showBox && (
                                      <Box
                                        sx={{
                                          // boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                          border: `solid 0.5px ${theme.palette.background.primary}`,
                                          borderTopRightRadius: 8,
                                          borderTopLeftRadius: 8,
                                          backgroundColor: `${theme.palette.background.paper}`,
                                        }}
                                        mb={0.5}
                                      >
                                        <Grid
                                          item
                                          container
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          alignItems="center"
                                          justifyContent={"space-between"}
                                        >
                                          <Grid
                                            item
                                            container
                                            xl={6.5}
                                            lg={6.5}
                                            md={6.5}
                                            sm={6.5}
                                            xs={6.5}
                                            alignItems="center"
                                            justifyContent={"flex-start"}
                                            sx={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {item._source.platform !== null && (
                                              <Box
                                                sx={{ width: "150px" }}
                                                alignItems="center"
                                                justifyContent={"center"}
                                                textAlign="center"
                                              >
                                                <ListItem
                                                  style={{
                                                    color: `${theme.palette.text.primary}`,
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                    paddingLeft: "6px",
                                                  }}
                                                >
                                                  {returnPlatformDropdownLogos(
                                                    item._source.platform &&
                                                      item._source.platform
                                                        .platform_name
                                                      ? item._source.platform
                                                          .platform_name
                                                      : item._source.platform
                                                  )}
                                                  <p className="m-0 p-0 ml-1">
                                                    {item._source.activities !==
                                                    0
                                                      ? item._source
                                                          .activities &&
                                                        item._source.activities
                                                          .activities_name
                                                      : null}
                                                  </p>
                                                </ListItem>
                                              </Box>
                                            )}
                                            <Box
                                              sx={{
                                                width: "150px",
                                              }}
                                            >
                                              {item._source.customer_id &&
                                                item._source.customer_id
                                                  .customer_name && (
                                                  <Box
                                                    item
                                                    // xl={showBox ? 4 : 4}
                                                    alignItems="center"
                                                    justifyContent={"center"}
                                                    textAlign="center"
                                                    onMouseEnter={() => {
                                                      handleMouseHoverStateChange(
                                                        item._source
                                                          .customer_id,
                                                        item._source.platform
                                                      );
                                                    }}
                                                    sx={{
                                                      whiteSpace: "nowrap",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      width: "150px",
                                                    }}
                                                  >
                                                    <ListItem>
                                                      <Avatar
                                                        style={{
                                                          width: "24px",
                                                          height: "24px",
                                                        }}
                                                        src={
                                                          item._source &&
                                                          item._source
                                                            .customer_id &&
                                                          item._source
                                                            .customer_id
                                                            .customer_profile_url
                                                        }
                                                      >
                                                        {item._source &&
                                                          item._source
                                                            .customer_id &&
                                                          item._source
                                                            .customer_id
                                                            .customer_name &&
                                                          item._source.customer_id.customer_name.charAt(
                                                            0
                                                          )}
                                                      </Avatar>

                                                      <div>
                                                        <Typography
                                                          aria-owns={
                                                            costomer_popover_open
                                                              ? "mouse-over-popover"
                                                              : undefined
                                                          }
                                                          aria-haspopup="true"
                                                          onMouseEnter={
                                                            handlePopoverOpen
                                                          }
                                                          onMouseLeave={
                                                            handlePopoverClose
                                                          }
                                                          onWheel={
                                                            handlePopoverClose
                                                          }
                                                        >
                                                          <p className="p-0 m-0 ml-1 font-weight-bold">
                                                            {/* {item._source
                                                          .customer_id &&
                                                          item._source
                                                            .customer_id
                                                            .customer_name} */}

                                                            {item._source
                                                              .platform
                                                              .platform_name ===
                                                            "Twitter"
                                                              ? item._source
                                                                  .customer_id
                                                                  .customer_name &&
                                                                returnTwittername(
                                                                  item._source
                                                                    .customer_id
                                                                    .customer_name,
                                                                  false
                                                                )
                                                              : item._source
                                                                  .customer_id &&
                                                                item._source
                                                                  .customer_id
                                                                  .customer_name}
                                                          </p>
                                                        </Typography>
                                                        {customer_id && (
                                                          <Popover
                                                            classes={{
                                                              paper:
                                                                classes.popover,
                                                            }}
                                                            id="mouse-over-popover"
                                                            sx={{
                                                              pointerEvents:
                                                                "none",
                                                              marginTop: 1,
                                                              boxShadow: `${theme.shadows}`,
                                                            }}
                                                            open={
                                                              costomer_popover_open
                                                            }
                                                            anchorEl={
                                                              costomer_popover_anchorEl
                                                            }
                                                            anchorOrigin={{
                                                              vertical:
                                                                "bottom",
                                                              horizontal:
                                                                "left",
                                                            }}
                                                            transformOrigin={{
                                                              vertical: "top",
                                                              horizontal:
                                                                "left",
                                                            }}
                                                            onClose={
                                                              handlePopoverClose
                                                            }
                                                            disableRestoreFocus
                                                          >
                                                            <Typography
                                                              sx={{
                                                                p: 0.5,
                                                                boxShadow: `${theme.shadows}`,
                                                              }}
                                                            >
                                                              <Box
                                                                width={"auto"}
                                                                minWidth={160}
                                                                // maxWidth="auto"
                                                              >
                                                                <Grid
                                                                  item
                                                                  container
                                                                  xl={12}
                                                                  lg={12}
                                                                  md={12}
                                                                  sm={12}
                                                                  xs={12}
                                                                  justifyContent="flex-start"
                                                                  alignItems={
                                                                    "center"
                                                                  }
                                                                >
                                                                  <Grid
                                                                    item
                                                                    xl={2}
                                                                    lg={2}
                                                                    md={2}
                                                                    sm={2}
                                                                    xs={2}
                                                                  >
                                                                    <Avatar
                                                                      style={{
                                                                        width:
                                                                          "24px",
                                                                        height:
                                                                          "24px",
                                                                      }}
                                                                      src={
                                                                        customer_id.customer_profile_url &&
                                                                        customer_id.customer_profile_url
                                                                      }
                                                                    >
                                                                      {customer_id.customer_name &&
                                                                        customer_id.customer_name.charAt(
                                                                          0
                                                                        ) &&
                                                                        customer_id.customer_name.charAt(
                                                                          0
                                                                        )}
                                                                    </Avatar>
                                                                  </Grid>

                                                                  <Grid
                                                                    item
                                                                    xl={9}
                                                                    lg={9}
                                                                    md={9}
                                                                    sm={9}
                                                                    xs={9}
                                                                  >
                                                                    {customer_id
                                                                      .platform[0]
                                                                      .platform_name ===
                                                                    "Twitter" ? (
                                                                      <>
                                                                        <p
                                                                          className="p-0 m-0 ml-1"
                                                                          style={{
                                                                            fontSize:
                                                                              "12px",
                                                                          }}
                                                                        >
                                                                          {returnTwittername(
                                                                            customer_id.customer_name,
                                                                            true
                                                                          )}
                                                                        </p>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        <p
                                                                          className="p-0 m-0 ml-1"
                                                                          style={{
                                                                            fontSize:
                                                                              "12px",
                                                                          }}
                                                                        >
                                                                          {customer_id.customer_name &&
                                                                            customer_id.customer_name}
                                                                        </p>
                                                                        <p
                                                                          className="p-0 m-0 ml-1"
                                                                          style={{
                                                                            fontSize:
                                                                              "12px",
                                                                          }}
                                                                        >
                                                                          @
                                                                          {customer_id.customer_name &&
                                                                            customer_id.customer_name}
                                                                        </p>
                                                                      </>
                                                                    )}
                                                                  </Grid>
                                                                </Grid>

                                                                <hr className="p-0 m-0" />

                                                                {customer_id.customer_location &&
                                                                  customer_id
                                                                    .customer_location
                                                                    .length !==
                                                                    0 && (
                                                                    <ListItem className="p-0 m-0">
                                                                      <LocationOnIcon
                                                                        style={{
                                                                          width:
                                                                            "12px",
                                                                          marginRight: 2,
                                                                          color: `${theme.palette.ticketListView.iconColor}`,
                                                                        }}
                                                                      />
                                                                      <p
                                                                        className="p-0 m-0 ml-1"
                                                                        style={{
                                                                          fontSize:
                                                                            "11px",
                                                                        }}
                                                                      >
                                                                        {customer_id.customer_location &&
                                                                          customer_id.customer_location}
                                                                      </p>
                                                                    </ListItem>
                                                                  )}

                                                                {customer_id.customer_location &&
                                                                  customer_id
                                                                    .customer_location
                                                                    .length !==
                                                                    0 && (
                                                                    <hr className="p-0 m-0" />
                                                                  )}

                                                                {/* {returnSentimentUpliftScore(
                                                                customer_id
                                                              ) &&
                                                                returnSentimentUpliftScore(
                                                                  customer_id
                                                                ) !== null && (
                                                                  <Grid
                                                                    item
                                                                    container
                                                                    xl={12}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                    justifyContent="flex-start"
                                                                    alignItems={
                                                                      "center"
                                                                    }
                                                                    px={0.5}
                                                                  >
                                                                    <Grid
                                                                      item
                                                                      xl={8}
                                                                      lg={8}
                                                                      md={8}
                                                                      sm={8}
                                                                      xs={8}
                                                                      textAlign="left"
                                                                    >
                                                                      <p
                                                                        className="p-0 m-0"
                                                                        style={{
                                                                          fontSize:
                                                                            "11px",
                                                                        }}
                                                                      >
                                                                        sentiment
                                                                        uplift
                                                                        score
                                                                      </p>
                                                                    </Grid>

                                                                    <Grid
                                                                      item
                                                                      xl={4}
                                                                      lg={4}
                                                                      md={4}
                                                                      sm={4}
                                                                      xs={4}
                                                                      textAlign="right"
                                                                    >
                                                                      <p
                                                                        className="p-0 m-0"
                                                                        style={{
                                                                          fontSize:
                                                                            "11px",
                                                                        }}
                                                                      >
                                                                        {returnSentimentUpliftScore(
                                                                          customer_id
                                                                        )}
                                                                      </p>
                                                                    </Grid>
                                                                  </Grid>
                                                                )} */}
                                                              </Box>
                                                            </Typography>
                                                          </Popover>
                                                        )}
                                                      </div>
                                                    </ListItem>
                                                  </Box>
                                                )}
                                            </Box>
                                            <Box
                                              item
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="center"
                                            >
                                              <ListItem>
                                                <AccountBoxIcon
                                                  style={{
                                                    width: "16px",
                                                    color: `${theme.palette.text.primary}`,
                                                  }}
                                                />
                                                <Typography
                                                  sx={{
                                                    "&:hover": {
                                                      transform: "scale(1.05)",
                                                    },
                                                  }}
                                                >
                                                  <Tooltip title="Open Detail">
                                                    <p
                                                      className="m-0 p-0"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        handleButtonClick(item);
                                                        setReplyClicked(false);
                                                        setEmailClicked(false);
                                                      }}
                                                    >
                                                      Open Detail
                                                      {/* {showBox ? "Open details" : "Open details"} */}
                                                    </p>
                                                  </Tooltip>
                                                </Typography>
                                              </ListItem>
                                            </Box>
                                          </Grid>

                                          <Grid
                                            item
                                            container
                                            xl={5.5}
                                            lg={5.5}
                                            md={5.5}
                                            sm={5.5}
                                            xs={5.5}
                                            alignItems="center"
                                            justifyContent={"flex-end"}
                                            sx={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <Grid
                                              item
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="left"
                                              // className="bg-info"
                                              sx={{ width: 130 }}
                                            >
                                              {item._source.assigned_to
                                                .user_id &&
                                                item._source.assigned_to
                                                  .user_name.length !== 0 && (
                                                  <ListItem>
                                                    <PersonIcon
                                                      style={{
                                                        width: "16px",
                                                        // marginRight: 2,
                                                        color: `${theme.palette.ticketListView.iconColor}`,
                                                      }}
                                                    />
                                                    <p className="m-0 p-0">
                                                      {item._source.assigned_to
                                                        .user_id
                                                        ? returnAssigneTofullname(
                                                            item._source
                                                              .assigned_to
                                                              .user_id,
                                                            item._source
                                                              .assigned_to
                                                              .user_name
                                                          ) &&
                                                          returnAssigneTofullname(
                                                            item._source
                                                              .assigned_to
                                                              .user_id,
                                                            item._source
                                                              .assigned_to
                                                              .user_name
                                                          ).length > 15
                                                          ? returnAssigneTofullname(
                                                              item._source
                                                                .assigned_to
                                                                .user_id,
                                                              item._source
                                                                .assigned_to
                                                                .user_name
                                                            ).substring(
                                                              0,
                                                              15 - 3
                                                            ) + "..."
                                                          : returnAssigneTofullname(
                                                              item._source
                                                                .assigned_to
                                                                .user_id,
                                                              item._source
                                                                .assigned_to
                                                                .user_name
                                                            )
                                                        : item?._source
                                                            ?.assigned_to
                                                            ?.user_name ?? ""}
                                                    </p>
                                                  </ListItem>
                                                )}
                                            </Grid>
                                            {finalStatuses &&
                                              finalStatuses.length > 0 &&
                                              finalStatuses.includes(
                                                item &&
                                                  item._source &&
                                                  item._source.status.status_id
                                              ) === false && (
                                                <Grid
                                                  item
                                                  // xl={3}
                                                  // lg={4}
                                                  // md={4}
                                                  // sm={4}
                                                  // xs={4}
                                                  sx={{ minWidth: 70 }}
                                                  alignItems="center"
                                                  justifyContent={"center"}
                                                  textAlign="right"
                                                >
                                                  <ListItem>
                                                    {!isRequesting && (
                                                      <ReactTimer
                                                        finalStatuses={
                                                          finalStatuses
                                                        }
                                                        first_ticket_assigned_date_time={
                                                          item._source
                                                            .first_ticket_assigned_date_time
                                                        }
                                                        item={item}
                                                        created={
                                                          item._source
                                                            .created_on
                                                        }
                                                        targetTime={flr_time}
                                                        alertTime={
                                                          flr_breach_alert_notification_window_time
                                                        }
                                                      />
                                                    )}
                                                  </ListItem>
                                                </Grid>
                                              )}
                                            <Grid
                                              item
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="right"
                                              sx={{ width: 110 }}
                                            >
                                              <Tooltip
                                                title={`Mentioned On : ${
                                                  item._source
                                                    .latest_feed_created_on
                                                    ? getFormattedDate(
                                                        item._source
                                                          .latest_feed_created_on
                                                      )
                                                    : item._source.created_on &&
                                                      getFormattedDate(
                                                        item._source.created_on
                                                      )
                                                }`}
                                                arrow
                                                placement="left"
                                                style={{
                                                  cursor: "default",
                                                }}
                                              >
                                                <ListItem>
                                                  <AccessTimeIcon
                                                    style={{
                                                      width: "16px",
                                                      marginRight: 2,
                                                      color: `${theme.palette.ticketListView.iconColor}`,
                                                    }}
                                                  />
                                                  {item._source
                                                    .latest_feed_created_on ? (
                                                    <p className="m-0 p-0">
                                                      {timeAgo.format(
                                                        new Date(
                                                          item._source.latest_feed_created_on
                                                        )
                                                      ).length > 12
                                                        ? timeAgo
                                                            .format(
                                                              new Date(
                                                                item._source.latest_feed_created_on
                                                              )
                                                            )
                                                            .substring(
                                                              0,
                                                              12 - 2
                                                            )
                                                        : timeAgo.format(
                                                            new Date(
                                                              item._source.latest_feed_created_on
                                                            )
                                                          )}
                                                    </p>
                                                  ) : (
                                                    <p className="m-0 p-0">
                                                      {timeAgo.format(
                                                        new Date(
                                                          item._source.created_on
                                                        )
                                                      ).length > 12
                                                        ? timeAgo
                                                            .format(
                                                              new Date(
                                                                item._source.created_on
                                                              )
                                                            )
                                                            .substring(
                                                              0,
                                                              12 - 2
                                                            )
                                                        : timeAgo.format(
                                                            new Date(
                                                              item._source.created_on
                                                            )
                                                          )}
                                                    </p>
                                                  )}
                                                </ListItem>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <hr className="p-0 m-0" />

                                        {/* <Divider /> */}
                                        {item._source.ticket_desc &&
                                          item._source.ticket_desc.length !==
                                            0 && (
                                            <Grid
                                              item
                                              container
                                              xl={12}
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              alignItems="center"
                                              justifyContent={"space-between"}
                                              sx={{
                                                cursor: "pointer",
                                                paddingX: 2,
                                                paddingY: 1,
                                                display: "block",
                                              }}
                                            >
                                              {item?._source?.platform ? (
                                                <Typography
                                                  component={"span"}
                                                  className="two-line-paragraph"
                                                  sx={{
                                                    color: "#484848",
                                                  }}
                                                  onClick={() => {
                                                    handleButtonClick(item);
                                                  }}
                                                >
                                                  {item._source.platform
                                                    ?.platform_name === "GMB" ||
                                                  item._source.platform
                                                    .platform_name ===
                                                    "Glassdoor" ? (
                                                    <span>
                                                      {item._source.ticket_desc.split(
                                                        "/ratings/"
                                                      )[1] && (
                                                        <>
                                                          Rating :{" "}
                                                          {returnStars(
                                                            item._source.ticket_desc.split(
                                                              "/ratings/"
                                                            )[1]
                                                          )}
                                                          <br />
                                                        </>
                                                      )}
                                                      {
                                                        item._source.ticket_desc.split(
                                                          "/ratings/"
                                                        )[0]
                                                      }
                                                    </span>
                                                  ) : item._source.platform
                                                      ?.platform_name ===
                                                      "Playstore" ||
                                                    item._source.platform
                                                      .platform_name ===
                                                      "Indeed" ||
                                                    item._source.platform
                                                      .platform_name ===
                                                      "Appstore" ? (
                                                    <span>
                                                      {item._source.ticket_desc.split(
                                                        "/ratings/"
                                                      )[1] && (
                                                        <>
                                                          Rating :{" "}
                                                          {returnStarsIcon(
                                                            item._source.ticket_desc.split(
                                                              "/ratings/"
                                                            )[1]
                                                          )}
                                                          <br />
                                                        </>
                                                      )}
                                                      {
                                                        item._source.ticket_desc.split(
                                                          "/ratings/"
                                                        )[0]
                                                      }
                                                    </span>
                                                  ) : item._source.ticket_desc.includes(
                                                      "| URL |"
                                                    ) ? (
                                                    <Box width="100%">
                                                      {/* <RenderMedia
                                                      image_url={extractUrl(
                                                        item._source.ticket_desc
                                                      )}
                                                      mediaType="img"
                                                      height="100%"
                                                    /> */}
                                                      <Chip
                                                        label="Media Attached"
                                                        sx={{
                                                          "& .MuiChip-label": {
                                                            fontWeight: "bold",
                                                          },
                                                        }}
                                                      />
                                                    </Box>
                                                  ) : (
                                                    item._source.ticket_desc
                                                      .replace(/&lt;/g, "<")
                                                      .replace(/&gt;/g, ">")
                                                      .replace(/&amp;/g, "&")
                                                  )}
                                                </Typography>
                                              ) : null}
                                              {translatedDescription.includes(
                                                item._id
                                              ) && (
                                                <Grid
                                                  item
                                                  sx={{
                                                    backgroundColor: `${theme.palette.background.light}`,
                                                    border: `1px solid ${theme.palette.background.light}`,
                                                    borderRadius: "3px",
                                                    padding: "5px",
                                                    marginY: "5px",
                                                  }}
                                                >
                                                  <Grid
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <Typography component={"p"}>
                                                      Translated to English
                                                    </Typography>
                                                    <Typography
                                                      component={"p"}
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "flex-end",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        let translatedIds = [
                                                          ...translatedDescription,
                                                        ];

                                                        if (
                                                          translatedIds.includes(
                                                            item._id
                                                          )
                                                        ) {
                                                          translatedIds.splice(
                                                            translatedIds.indexOf(
                                                              item._id
                                                            ),
                                                            1
                                                          );
                                                        } else {
                                                          translatedIds.push(
                                                            item._id
                                                          );
                                                        }
                                                        setTranslatedDescription(
                                                          [
                                                            ...new Set(
                                                              translatedIds
                                                            ),
                                                          ]
                                                        );
                                                      }}
                                                    >
                                                      <HighlightOffOutlinedIcon
                                                        titleAccess="Close"
                                                        sx={{ width: "12px" }}
                                                      />
                                                    </Typography>
                                                  </Grid>

                                                  <TranslatedContent
                                                    content={
                                                      item._source.ticket_desc.split(
                                                        "/ratings/"
                                                      )[0]
                                                    }
                                                  />
                                                </Grid>
                                              )}
                                            </Grid>
                                          )}
                                        {/* <Divider /> */}
                                        <hr className="p-0 m-0" />
                                        <Grid
                                          item
                                          container
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          xs={12}
                                          alignItems="center"
                                          justifyContent={"space-between"}
                                          sx={{
                                            backgroundColor: `${theme.palette.background.default}`,
                                            paddingX: 1,
                                            border: `${theme.palette.background.primary}`,
                                          }}
                                        >
                                          <Grid
                                            item
                                            container
                                            xl={6}
                                            lg={5}
                                            laptop={8}
                                            md={4}
                                            sm={6}
                                            xs={6}
                                            my={0.5}
                                          >
                                            <Grid
                                              item
                                              py={0.5}
                                              px={1}
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="center"
                                              sx={{
                                                borderRight: `1px solid ${theme.palette.background.primary}`,
                                              }}
                                            >
                                              {/* <ListItem className="py-0 m-0 px-1 pt-1"> */}
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                sx={{
                                                  margin: 0,
                                                  padding: 0,
                                                  color: `${theme.palette.ticketListView.checkbox}`,
                                                }}
                                                defaultChecked={returnCheckBoxValue(
                                                  item
                                                )}
                                                onChange={(e) => {
                                                  let selectedTickets = [
                                                    ...props.selectionModel,
                                                  ];
                                                  let index =
                                                    selectedTickets.findIndex(
                                                      (obj) =>
                                                        obj._id === item._id
                                                    );

                                                  if (
                                                    e.target.checked === true
                                                  ) {
                                                    if (index === -1) {
                                                      selectedTickets.push(
                                                        item
                                                      );
                                                    }
                                                  }

                                                  if (
                                                    e.target.checked === false
                                                  ) {
                                                    if (index !== -1) {
                                                      selectedTickets.splice(
                                                        index,
                                                        1
                                                      );
                                                    }
                                                  }

                                                  props.setSelectionModel([
                                                    ...new Set(selectedTickets),
                                                  ]);

                                                  localStorage.setItem(
                                                    "selected_tickets_for_bulk_update",
                                                    JSON.stringify([
                                                      ...new Set(
                                                        selectedTickets
                                                      ),
                                                    ])
                                                  );
                                                  handleClickSnack();
                                                  setCheckboxSelectedTickets(
                                                    selectedTickets.length
                                                  );
                                                }}
                                              />
                                              {/* </ListItem> */}
                                            </Grid>
                                            <Grid
                                              item
                                              py={0.5}
                                              px={1}
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="center"
                                              sx={{
                                                borderRight: `1px solid ${theme.palette.background.primary}`,
                                              }}
                                            >
                                              <Tooltip title="Ticket Id">
                                                {/* <ListItem
                                              className="py-0 m-0 px-1"
                                              sx={{
                                                color: `${theme.palette.ticketListView.iconColor}`,
                                              }}
                                            > */}
                                                <IconButton
                                                  className="py-0 m-0 px-1"
                                                  // title="Ticket Id"
                                                  color="primary"
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    copyToClipboard(item._id);
                                                  }}
                                                  sx={{
                                                    color: `${theme.palette.ticketListView.iconColor}`,
                                                  }}
                                                >
                                                  <FileCopyIcon
                                                    fontSize="small"
                                                    style={{
                                                      width: 13,
                                                    }}
                                                  />
                                                </IconButton>
                                                <Snackbar
                                                  message="Ticket Id copied to clipboard"
                                                  sx={{ height: "70%" }}
                                                  anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                  }}
                                                  autoHideDuration={500}
                                                  onClose={() =>
                                                    setCopied(false)
                                                  }
                                                  open={copied}
                                                />
                                                {/* </ListItem> */}
                                              </Tooltip>
                                            </Grid>
                                            {/* {item._source.user_total_mentions ? ( */}
                                            <Grid
                                              item
                                              py={0.5}
                                              px={1}
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="center"
                                              sx={{
                                                borderRight: `1px solid ${theme.palette.background.primary}`,
                                              }}
                                            >
                                              <ListItem
                                                // className="py-0 m-0 px-1"
                                                className="p-0 m-0"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faComments}
                                                  style={{
                                                    width: 13,
                                                    // height: 10,
                                                    marginRight: 2,
                                                    color: `${theme.palette.ticketListView.iconColor}`,
                                                  }}
                                                ></FontAwesomeIcon>
                                                <p className="m-0 p-0">
                                                  {item._source
                                                    .user_total_mentions &&
                                                  item._source
                                                    .user_total_mentions
                                                    ? item._source
                                                        .user_total_mentions
                                                    : 0}
                                                </p>
                                              </ListItem>
                                            </Grid>
                                            {/* ) : null} */}
                                            {item._source.status &&
                                              item._source.status
                                                .status_name && (
                                                <Grid
                                                  item
                                                  py={0.5}
                                                  px={1}
                                                  alignItems="center"
                                                  justifyContent={"center"}
                                                  textAlign="center"
                                                  sx={{
                                                    borderRight: `1px solid ${theme.palette.background.primary}`,
                                                  }}
                                                >
                                                  <ListItem
                                                    // className="py-0 m-0 px-1"
                                                    className="p-0 m-0"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faFolderOpen}
                                                      style={{
                                                        width: 13,
                                                        marginRight: 2,
                                                        color: `${theme.palette.ticketListView.iconColor}`,
                                                      }}
                                                    ></FontAwesomeIcon>
                                                    <p className="m-0 p-0">
                                                      {item._source.status
                                                        .status_name.length > 12
                                                        ? item._source.status.status_name.substring(
                                                            0,
                                                            12 - 2
                                                          ) + ".."
                                                        : item._source.status
                                                            .status_name}
                                                    </p>
                                                  </ListItem>
                                                </Grid>
                                              )}
                                            {item._source.priority &&
                                              item._source.priority
                                                .priority_name && (
                                                <Grid
                                                  item
                                                  py={0.5}
                                                  px={1}
                                                  alignItems="center"
                                                  justifyContent={"center"}
                                                  textAlign="center"
                                                  sx={{
                                                    borderRight: `1px solid ${theme.palette.background.primary}`,
                                                  }}
                                                >
                                                  <PriorityUpdatePopper
                                                    priorityList={
                                                      filterprioritylist
                                                    }
                                                    renderPriorityColor={
                                                      renderPriorityColor
                                                    }
                                                    ticketId={item._id}
                                                    fetchFunction={() => {
                                                      fetchFunction(pageNumber);
                                                    }}
                                                  >
                                                    <ListItem
                                                      // className="py-0 m-0 px-1"

                                                      className="p-0 m-0 "
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <Box
                                                        mr={1}
                                                        width={10}
                                                        height={10}
                                                        borderRadius="50%"
                                                        sx={{
                                                          backgroundColor:
                                                            renderPriorityColor(
                                                              item._source
                                                                .priority
                                                                .priority_name
                                                            ),
                                                        }}
                                                      ></Box>
                                                      <p
                                                        className="m-0 p-0"
                                                        data-testid="individualPriority"
                                                      >
                                                        {
                                                          item._source.priority
                                                            .priority_name
                                                        }
                                                      </p>
                                                    </ListItem>
                                                  </PriorityUpdatePopper>
                                                </Grid>
                                              )}
                                            {/* {item._source.category &&
                                          item._source.category
                                            .category_name && (
                                            <Grid
                                              item
                                              p={0.5}
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="center"
                                              sx={{
                                                borderRight: `1px solid ${theme.palette.background.primary}`,
                                              }}
                                            >
                                              <ListItem className="py-0 m-0 px-1">
                                                <AlternateEmailIcon
                                                  style={{
                                                    width: "16px",
                                                    marginRight: 2,
                                                    color: `${theme.palette.ticketListView.iconColor}`,
                                                  }}
                                                />
                                                <p className="m-0 p-0">
                                                  {item._source.category &&
                                                  item._source.category
                                                    .category_name.length > 15
                                                    ? item._source.category.category_name.substring(
                                                        0,
                                                        15 - 3
                                                      ) + "..."
                                                    : item._source.category
                                                        .category_name}
                                                </p>
                                              </ListItem>
                                            </Grid>
                                          )} */}
                                            {typeof item._source
                                              .sentiment_value === "number" && (
                                              <Grid
                                                item
                                                py={0.5}
                                                px={1}
                                                sx={{
                                                  borderRight: `1px solid ${theme.palette.background.primary}`,
                                                }}
                                                data-testid="individualSentiment"
                                              >
                                                <SentimentValueUpdatePopper
                                                  priorityList={
                                                    filterprioritylist
                                                  }
                                                  renderPriorityColor={
                                                    renderPriorityColor
                                                  }
                                                  ticketId={item._id}
                                                  fetchFunction={() => {
                                                    fetchFunction(pageNumber);
                                                  }}
                                                >
                                                  {item._source
                                                    .sentiment_value &&
                                                  item._source.sentiment_value >
                                                    0 ? (
                                                    <SentimentSatisfiedAltRoundedIcon
                                                      titleAccess="Positive"
                                                      style={{
                                                        width: "16px",
                                                        color: `green`,
                                                      }}
                                                    />
                                                  ) : item._source
                                                      .sentiment_value < 0 ? (
                                                    <SentimentDissatisfiedRoundedIcon
                                                      titleAccess="Negative"
                                                      style={{
                                                        width: "16px",
                                                        color: `${theme.palette.sentimentIconColor.negative}`,
                                                      }}
                                                    />
                                                  ) : item._source
                                                      .sentiment_value !==
                                                    null ? (
                                                    <SentimentNeutralRoundedIcon
                                                      titleAccess="Neutral"
                                                      style={{
                                                        width: "16px",
                                                        color: `${theme.palette.sentimentIconColor.neutral}`,
                                                      }}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </SentimentValueUpdatePopper>
                                              </Grid>
                                            )}
                                            {/* {item._source.tags.length !== 0 && (
                                          <Grid
                                            item
                                            p={0.5}
                                            alignItems="center"
                                            justifyContent={"center"}
                                            textAlign="center"
                                            sx={{
                                              borderRight: `1px solid ${theme.palette.background.primary}`,
                                            }}
                                          >
                                            <ListItem className="py-0 m-0 px-1">
                                              <StyleIcon
                                                style={{
                                                  width: "16px",
                                                  marginRight: 2,
                                                  color: `${theme.palette.ticketListView.iconColor}`,
                                                  color: `${theme.palette.ticketListView.iconColor}`,
                                                }}
                                              />
                                              <p className="m-0 p-0">
                                                {returntags(item._source.tags)}
                                              </p>
                                            </ListItem>
                                          </Grid>
                                        )} */}
                                            {item._source.custom_value.length >=
                                              0 && (
                                              <div>
                                                {returntrueCategoryValues(
                                                  item
                                                ) ? (
                                                  <Grid
                                                    item
                                                    py={0.5}
                                                    px={1}
                                                    alignItems="center"
                                                    justifyContent={"center"}
                                                    textAlign="center"
                                                    sx={{
                                                      borderRight: `1px solid ${theme.palette.background.primary}`,
                                                    }}
                                                    title={
                                                      returnCustomValues(
                                                        item._source
                                                          .custom_value
                                                      ) &&
                                                      returnCustomValuesAsString(
                                                        item._source
                                                          .custom_value
                                                      )
                                                    }
                                                  >
                                                    <ListItem
                                                      // className="py-0 m-0 px-1
                                                      // "
                                                      className="p-0 m-0 "
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <StyleIcon
                                                        style={{
                                                          width: 13,
                                                          marginRight: 2,
                                                          color: `${theme.palette.ticketListView.iconColor}`,
                                                        }}
                                                      />
                                                      <p
                                                        className="m-0 p-0"
                                                        data-testid="individualCategory"
                                                        style={{
                                                          whiteSpace: "nowrap",
                                                          overflow: "hidden",
                                                          textOverflow:
                                                            "ellipsis",
                                                          width: "100px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setCategoryUpdateData(
                                                            item
                                                          );

                                                          openCloseModal(
                                                            !categoryUpdateOpen
                                                          );
                                                        }}
                                                      >
                                                        {returnCustomValues(
                                                          item._source
                                                            .custom_value
                                                        ) &&
                                                          returnCustomValuesAsString(
                                                            item._source
                                                              .custom_value
                                                          )}
                                                      </p>
                                                    </ListItem>
                                                  </Grid>
                                                ) : (
                                                  <Grid
                                                    item
                                                    py={0.5}
                                                    px={1}
                                                    alignItems="center"
                                                    justifyContent={"center"}
                                                    textAlign="center"
                                                    sx={{
                                                      borderRight: `1px solid ${theme.palette.background.primary}`,
                                                    }}
                                                  >
                                                    <ListItem
                                                      // className="py-0 m-0 px-1"

                                                      className="p-0 m-0"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      <StyleIcon
                                                        style={{
                                                          width: 13,
                                                          marginRight: 2,
                                                          color: `${theme.palette.ticketListView.iconColor}`,
                                                          color: `${theme.palette.ticketListView.iconColor}`,
                                                        }}
                                                      />
                                                      <p
                                                        className="m-0 p-0"
                                                        data-testid="individualCategory"
                                                        style={{
                                                          whiteSpace: "nowrap",
                                                          overflow: "hidden",
                                                          textOverflow:
                                                            "ellipsis",
                                                          width: "100px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setCategoryUpdateData(
                                                            item
                                                          );
                                                          openCloseModal(
                                                            !categoryUpdateOpen
                                                          );
                                                        }}
                                                      >
                                                        Select Category
                                                      </p>
                                                    </ListItem>
                                                  </Grid>
                                                )}
                                              </div>
                                            )}

                                            <Grid
                                              item
                                              py={0.5}
                                              px={1}
                                              alignItems="center"
                                              justifyContent={"center"}
                                              textAlign="center"
                                              sx={{
                                                borderRight: `1px solid ${theme.palette.background.primary}`,
                                                maxWidth: "120px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                              title={
                                                item._source &&
                                                item._source.tags &&
                                                item._source.tags.length !== 0
                                                  ? tagsStringValues(item)
                                                  : null
                                              }
                                            >
                                              <ListItem
                                                // className="py-0 m-0 px-1"
                                                className="p-0 m-0"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setTagUpdateData(item);

                                                  tagOpenCloseModal(
                                                    !tagUpdateOpen
                                                  );
                                                }}
                                              >
                                                <LocalOfferIcon
                                                  style={{
                                                    width: 13,
                                                    color: `${theme.palette.ticketListView.iconColor}`,
                                                    marginRight: 2,
                                                  }}
                                                />

                                                <p
                                                  className="m-0 p-0"
                                                  data-testid="individualTag"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    // width: "100px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {tagsStringValues(item)
                                                    ? tagsStringValues(item)
                                                        .length > 15
                                                      ? tagsStringValues(
                                                          item
                                                        ).substring(0, 15 - 3) +
                                                        "..."
                                                      : tagsStringValues(item)
                                                    : tagsStringValues(item)}
                                                </p>
                                              </ListItem>
                                            </Grid>
                                          </Grid>

                                          <Grid
                                            item
                                            container
                                            xl={6}
                                            lg={7}
                                            laptop={4}
                                            md={5.5}
                                            sm={6}
                                            xs={6}
                                            my={0.5}
                                            gap={1}
                                            display="flex"
                                            justifyContent={"flex-end"}
                                          >
                                            {finalStatuses &&
                                              finalStatuses.length > 0 &&
                                              finalStatuses.includes(
                                                item &&
                                                  item._source &&
                                                  item._source.status.status_id
                                              ) === false && (
                                                <Grid
                                                  item
                                                  sx={{
                                                    backgroundColor:
                                                      theme.palette.background
                                                        .paper,
                                                    border: `1px solid ${theme.palette.background.primary}`,
                                                    borderRadius: "5px",
                                                  }}
                                                  paddingX={1}
                                                >
                                                  <DirectClosePopper
                                                    statusList={statusList}
                                                    finalStatuses={
                                                      finalStatuses
                                                    }
                                                    ticketID={item._id}
                                                    fetchFunction={(item) => {
                                                      if (
                                                        item &&
                                                        isLastPage &&
                                                        lastPageTickets === 1
                                                      ) {
                                                        if (
                                                          pageNumber === 1 ||
                                                          pageNumber === 0 ||
                                                          pageNumber === "0"
                                                        ) {
                                                          setPageNumber(0);
                                                          fetchFunction(0);
                                                        } else {
                                                          fetchFunction(
                                                            pageNumber - 1
                                                          );
                                                        }
                                                      } else {
                                                        fetchFunction(
                                                          pageNumber
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title={
                                                        isSmallScreen
                                                          ? "Ignore"
                                                          : null
                                                      }
                                                    >
                                                      <ListItem
                                                        className="p-0 m-0"
                                                        sx={{
                                                          height: 25,
                                                          display: "flex",
                                                          textAlign: "center",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <HighlightOffIcon
                                                          style={{
                                                            width: "16px",
                                                            marginRight: 2,
                                                            color: `${theme.palette.ticketListView.iconColor}`,
                                                          }}
                                                        />
                                                        <Typography
                                                          fontSize={{
                                                            xl: "14px",
                                                            lg: "12px",
                                                            md: "11px",
                                                            laptop: "11px",
                                                          }}
                                                          display={{
                                                            xl: "block",
                                                            lg: "block",
                                                            md: "none",
                                                            sm: "none",
                                                            laptop: "none",
                                                          }}
                                                        >
                                                          <p className="m-0 p-0">
                                                            Ignore
                                                          </p>
                                                        </Typography>
                                                      </ListItem>
                                                    </Tooltip>
                                                  </DirectClosePopper>
                                                </Grid>
                                              )}

                                            {finalStatuses &&
                                              finalStatuses.length > 0 &&
                                              finalStatuses.includes(
                                                item &&
                                                  item._source &&
                                                  item._source.status.status_id
                                              ) === false && (
                                                <Grid
                                                  item
                                                  sx={{
                                                    backgroundColor:
                                                      theme.palette.background
                                                        .paper,
                                                    border: `1px solid ${theme.palette.background.primary}`,
                                                    borderRadius: "5px",
                                                  }}
                                                  paddingX={1}
                                                >
                                                  <Tooltip
                                                    title={
                                                      isSmallScreen
                                                        ? "Reply"
                                                        : null
                                                    }
                                                  >
                                                    <ListItem
                                                      onClick={() => {
                                                        handleButtonClick(item);
                                                        setReplyClicked(true);
                                                      }}
                                                      className="p-0 m-0"
                                                      sx={{
                                                        height: 25,
                                                        display: "flex",
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <ReplyIcon
                                                        style={{
                                                          width: "16px",
                                                          marginRight: 2,
                                                          color: `${theme.palette.ticketListView.iconColor}`,
                                                        }}
                                                      />
                                                      <Typography
                                                        fontSize={{
                                                          xl: "14px",
                                                          lg: "12px",
                                                          md: "11px",
                                                          laptop: "11px",
                                                        }}
                                                        display={{
                                                          xl: "block",
                                                          lg: "block",
                                                          md: "none",
                                                          sm: "none",
                                                          laptop: "none",
                                                        }}
                                                      >
                                                        <p className="m-0 p-0">
                                                          Reply
                                                        </p>
                                                      </Typography>
                                                    </ListItem>
                                                  </Tooltip>
                                                </Grid>
                                              )}

                                            {finalStatuses &&
                                              finalStatuses.length > 0 &&
                                              finalStatuses.includes(
                                                item &&
                                                  item._source &&
                                                  item._source.status.status_id
                                              ) === false && (
                                                <Grid
                                                  item
                                                  sx={{
                                                    backgroundColor:
                                                      theme.palette.background
                                                        .paper,
                                                    border: `1px solid ${theme.palette.background.primary}`,
                                                    borderRadius: "5px",
                                                  }}
                                                  paddingX={1}
                                                >
                                                  <Tooltip
                                                    title={
                                                      isSmallScreen
                                                        ? "Send Email"
                                                        : null
                                                    }
                                                  >
                                                    <ListItem
                                                      onClick={() => {
                                                        handleButtonClick(item);
                                                        setEmailClicked(true);
                                                      }}
                                                      className="p-0 m-0"
                                                      sx={{
                                                        height: 25,
                                                        display: "flex",
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <EmailOutlinedIcon
                                                        style={{
                                                          width: "16px",
                                                          marginRight: 2,
                                                          color: `${theme.palette.ticketListView.iconColor}`,
                                                        }}
                                                      />
                                                      <Typography
                                                        fontSize={{
                                                          xl: "14px",
                                                          lg: "12px",
                                                          md: "11px",
                                                          laptop: "11px",
                                                        }}
                                                        display={{
                                                          xl: "block",
                                                          lg: "block",
                                                          md: "none",
                                                          sm: "none",
                                                          laptop: "none",
                                                        }}
                                                      >
                                                        <p className="m-0 p-0">
                                                          Send Email
                                                        </p>
                                                      </Typography>
                                                    </ListItem>
                                                  </Tooltip>
                                                </Grid>
                                              )}

                                            {((item._source.platform !== null &&
                                              item._source.platform
                                                .platform_name === "Twitter" &&
                                              item._source.activities &&
                                              item._source.activities
                                                .activities_name !==
                                                "Direct Message") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Facebook" &&
                                                item._source.activities &&
                                                item._source.activities
                                                  .activities_name !==
                                                  "Direct Message") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Instagram" &&
                                                item._source.activities &&
                                                item._source.activities
                                                  .activities_name !==
                                                  "Direct Message") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Youtube") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Appstore") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "LinkedIn") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name === "GMB") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Indeed") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Reddit") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name === "Quora") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Trust Pilot") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Pinterest") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Playstore") ||
                                              (item._source.platform &&
                                                item._source.platform
                                                  .platform_name ===
                                                  "Threads")) && (
                                              <Grid
                                                item
                                                sx={{
                                                  backgroundColor:
                                                    theme.palette.background
                                                      .paper,
                                                  border: `1px solid ${theme.palette.background.primary}`,
                                                  borderRadius: "5px",
                                                }}
                                                paddingX={1}
                                              >
                                                <Tooltip
                                                  title={
                                                    isSmallScreen
                                                      ? "Open Link"
                                                      : null
                                                  }
                                                >
                                                  <ListItem
                                                    className="p-0 m-0"
                                                    sx={{
                                                      height: 25,
                                                      display: "flex",
                                                      textAlign: "center",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      OpenNewWindow(item);
                                                    }}
                                                  >
                                                    <OpenInNewIcon
                                                      style={{
                                                        width: "16px",
                                                        marginRight: 2,
                                                        color: `${theme.palette.ticketListView.iconColor}`,
                                                      }}
                                                    />
                                                    <Typography
                                                      fontSize={{
                                                        xl: "14px",
                                                        lg: "12px",
                                                        md: "11px",
                                                        laptop: "11px",
                                                      }}
                                                      display={{
                                                        xl: "block",
                                                        lg: "block",
                                                        md: "none",
                                                        sm: "none",
                                                        laptop: "none",
                                                      }}
                                                    >
                                                      <p className="m-0 p-0">
                                                        Open Link
                                                      </p>
                                                    </Typography>
                                                  </ListItem>
                                                </Tooltip>
                                              </Grid>
                                            )}

                                            {item._source &&
                                              item._source.customer_id &&
                                              item._source.customer_id !==
                                                null && (
                                                <Grid
                                                  item
                                                  sx={{
                                                    backgroundColor:
                                                      theme.palette.background
                                                        .paper,
                                                    border: `1px solid ${theme.palette.background.primary}`,
                                                    borderRadius: "5px",
                                                  }}
                                                  paddingX={1}
                                                >
                                                  <Tooltip
                                                    title={
                                                      isSmallScreen
                                                        ? item._source
                                                            .customer_id &&
                                                          item._source
                                                            .customer_id
                                                            .is_influencer
                                                          ? "UnMark as Influencer"
                                                          : "Mark as Influencer"
                                                        : null
                                                    }
                                                  >
                                                    <ListItem
                                                      className="p-0 m-0"
                                                      sx={{
                                                        height: 25,
                                                        display: "flex",
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        hanldeInfluencerPut(
                                                          item
                                                        );
                                                      }}
                                                    >
                                                      {item._source
                                                        .customer_id &&
                                                      item._source.customer_id
                                                        .is_influencer ? (
                                                        <>
                                                          <CancelIcon
                                                            style={{
                                                              width: "16px",
                                                              marginRight: 2,
                                                              color: `${theme.palette.ticketListView.iconColor}`,
                                                            }}
                                                          />
                                                          <Typography
                                                            fontSize={{
                                                              xl: "14px",
                                                              lg: "12px",
                                                              md: "11px",
                                                              laptop: "11px",
                                                            }}
                                                            display={{
                                                              xl: "block",
                                                              lg: "block",
                                                              md: "none",
                                                              sm: "none",
                                                              laptop: "none",
                                                            }}
                                                          >
                                                            <p className="m-0 p-0">
                                                              UnMark as
                                                              Influencer
                                                            </p>
                                                          </Typography>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <CheckCircleOutlineIcon
                                                            style={{
                                                              width: "16px",
                                                              marginRight: 2,
                                                              color: "red",
                                                              color: `${theme.palette.ticketListView.iconColor}`,
                                                            }}
                                                          />
                                                          <Typography
                                                            fontSize={{
                                                              xl: "14px",
                                                              lg: "12px",
                                                              md: "11px",
                                                              laptop: "11px",
                                                            }}
                                                            display={{
                                                              xl: "block",
                                                              lg: "block",
                                                              md: "block",
                                                              sm: "none",
                                                              laptop: "none",
                                                            }}
                                                          >
                                                            <p className="m-0 p-0">
                                                              Mark as Influencer
                                                            </p>
                                                          </Typography>
                                                        </>
                                                      )}
                                                    </ListItem>
                                                  </Tooltip>
                                                </Grid>
                                              )}

                                            <Grid item paddingX={1}>
                                              <Tooltip title="Translate">
                                                <ListItem
                                                  className="p-0 m-0"
                                                  sx={{
                                                    height: 25,
                                                    display: "flex",
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    let translatedIds = [
                                                      ...translatedDescription,
                                                    ];

                                                    if (
                                                      translatedIds.includes(
                                                        item._id
                                                      )
                                                    ) {
                                                      translatedIds.splice(
                                                        translatedIds.indexOf(
                                                          item._id
                                                        ),
                                                        1
                                                      );
                                                    } else {
                                                      translatedIds.push(
                                                        item._id
                                                      );
                                                    }
                                                    setTranslatedDescription([
                                                      ...new Set(translatedIds),
                                                    ]);
                                                  }}
                                                >
                                                  <GTranslateIcon
                                                    style={{
                                                      width: "16px",
                                                      color:
                                                        translatedDescription.includes(
                                                          item._id
                                                        )
                                                          ? `${theme.palette.primary.main}`
                                                          : `${theme.palette.ticketListView.iconColor}`,
                                                    }}
                                                  />
                                                </ListItem>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    )}

                                    {showBox && (
                                      <div>
                                        <Box
                                          sx={{
                                            // border: `solid 0.5px ${theme.palette.background.paper}`,
                                            borderTopRightRadius: !showBox && 8,
                                            borderTopLeftRadius: !showBox && 8,
                                            backgroundColor:
                                              ticketData &&
                                              ticketData._id === item._id
                                                ? `${theme.palette.background.default}`
                                                : `${theme.palette.background.paper}`,
                                            "&:hover": {
                                              backgroundColor: `${theme.palette.background.default}`,
                                              border: `solid 0.5px ${theme.palette.background.paper}`,
                                            },

                                            cursor: "pointer",
                                          }}
                                          pt={2}
                                          px={1}
                                          onClick={() => {
                                            handleButtonClick(item);
                                            setReplyClicked(false);
                                            setEmailClicked(false);
                                          }}
                                        >
                                          <Grid
                                            item
                                            container
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            alignItems="flex-start"
                                            justifyContent={"space-between"}
                                            mb={0.5}
                                          >
                                            <Grid
                                              item
                                              container
                                              xl={8}
                                              lg={8}
                                              md={9}
                                              sm={9}
                                              xs={9}
                                              justifyContent={"flex-start"}
                                              alignItems="flex-start"
                                            >
                                              <Grid
                                                item
                                                xl={2}
                                                justifyContent="center"
                                                textAlign={"center"}
                                                display={"flex"}
                                              >
                                                <Grid item>
                                                  {item._source.platform
                                                    ?.platform_name !==
                                                  "Youtube" ? (
                                                    <StyledBadge
                                                      badgeContent={returnSmallPlatformLogos(
                                                        item._source.platform &&
                                                          item._source.platform
                                                            .platform_name
                                                          ? item._source
                                                              .platform
                                                              .platform_name
                                                          : item._source
                                                              .platform
                                                      )}
                                                      color={
                                                        item._source.platform &&
                                                        item._source.platform
                                                          .platform_name !==
                                                          "Youtube"
                                                          ? "primary"
                                                          : "default"
                                                      }
                                                    >
                                                      <Avatar
                                                        style={{
                                                          width: "24px",
                                                          height: "24px",
                                                          border: `1px solid ${theme.palette.background.border}
                                                    `,
                                                        }}
                                                        src={
                                                          item._source &&
                                                          item._source
                                                            .customer_id &&
                                                          item._source
                                                            .customer_id
                                                            .customer_profile_url
                                                        }
                                                      >
                                                        {item._source &&
                                                          item._source
                                                            .customer_id &&
                                                          item._source
                                                            .customer_id
                                                            .customer_name &&
                                                          item._source.customer_id.customer_name.charAt(
                                                            0
                                                          )}
                                                      </Avatar>
                                                    </StyledBadge>
                                                  ) : (
                                                    <Avatar
                                                      style={{
                                                        width: "24px",
                                                        height: "24px",
                                                        alignSelf: "center",
                                                        border: `1px solid ${theme.palette.background.border}
                                                    `,
                                                      }}
                                                      src={
                                                        item._source &&
                                                        item._source
                                                          .customer_id &&
                                                        item._source.customer_id
                                                          .customer_profile_url
                                                      }
                                                    >
                                                      {item._source &&
                                                        item._source
                                                          .customer_id &&
                                                        item._source.customer_id
                                                          .customer_name &&
                                                        item._source.customer_id.customer_name.charAt(
                                                          0
                                                        )}
                                                    </Avatar>
                                                  )}
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                item
                                                xl={8.5}
                                                textAlign={"left"}
                                                style={{
                                                  color: `${theme.palette.text.gray}`,
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    width: "100px",
                                                  }}
                                                >
                                                  <p
                                                    className="p-0 m-0 ml-1"
                                                    style={{
                                                      fontSize: "13.5px",
                                                    }}
                                                  >
                                                    {item._source.platform
                                                      ?.platform_name ===
                                                    "Twitter"
                                                      ? item._source.customer_id
                                                          .customer_name &&
                                                        returnTwittername(
                                                          item._source
                                                            .customer_id
                                                            .customer_name
                                                        )
                                                      : item._source
                                                          .customer_id &&
                                                        item._source.customer_id
                                                          .customer_name}
                                                  </p>

                                                  <Box
                                                    style={{
                                                      fontSize: "11px",
                                                      marginTop: -6,
                                                      color: `${theme.palette.text.dark}`,
                                                    }}
                                                    className="ml-1"
                                                  >
                                                    {item._source.activities
                                                      ? item._source.activities
                                                          .activities_id
                                                        ? item._source
                                                            .activities
                                                            .activities_name
                                                        : item._source
                                                            .activities
                                                      : item._source.activities}
                                                  </Box>
                                                </Box>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              // xl={3}
                                              // lg={4}
                                              // md={3}
                                              // sm={3}
                                              // xs={3}
                                              justifyContent={"flex-end"}
                                              alignItems="center"
                                              // className="bg-info"
                                              textAlign={"right"}
                                              // container
                                              pr={2}
                                            >
                                              <Tooltip
                                                title={`Mentioned On : ${
                                                  item._source
                                                    .latest_feed_created_on
                                                    ? getFormattedDate(
                                                        item._source
                                                          .latest_feed_created_on
                                                      )
                                                    : getFormattedDate(
                                                        item._source.created_on
                                                      )
                                                }`}
                                                arrow
                                                placement="left"
                                                style={{
                                                  cursor: "default",
                                                }}
                                              >
                                                <ListItem
                                                  className={"p-0 m-0"}
                                                  sx={{
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <AccessTimeIcon
                                                    style={{
                                                      width: "13px",
                                                      marginRight: 2,
                                                      color: `${theme.palette.text.dark}`,
                                                    }}
                                                  />
                                                  {item._source
                                                    .latest_feed_created_on ? (
                                                    <span
                                                      className="m-0 p-0"
                                                      style={{
                                                        color: `${theme.palette.text.dark}`,
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {timeAgo.format(
                                                        new Date(
                                                          item._source.latest_feed_created_on
                                                        )
                                                      ).length > 12
                                                        ? timeAgo
                                                            .format(
                                                              new Date(
                                                                item._source.latest_feed_created_on
                                                              )
                                                            )
                                                            .substring(
                                                              0,
                                                              12 - 2
                                                            )
                                                        : timeAgo.format(
                                                            new Date(
                                                              item._source.latest_feed_created_on
                                                            )
                                                          )}
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className="m-0 p-0"
                                                      style={{
                                                        color: `${theme.palette.text.dark}`,
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {timeAgo.format(
                                                        new Date(
                                                          item._source.created_on
                                                        )
                                                      ).length > 12
                                                        ? timeAgo
                                                            .format(
                                                              new Date(
                                                                item._source.created_on
                                                              )
                                                            )
                                                            .substring(
                                                              0,
                                                              12 - 2
                                                            )
                                                        : timeAgo.format(
                                                            new Date(
                                                              item._source.created_on
                                                            )
                                                          )}
                                                    </span>
                                                  )}
                                                </ListItem>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>

                                          {item._source.ticket_desc &&
                                            item._source.ticket_desc.length !==
                                              0 && (
                                              <Box>
                                                <Grid
                                                  item
                                                  container
                                                  xl={12}
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  justifyContent={"flex-start"}
                                                  alignItems="flex-start"
                                                >
                                                  <Grid
                                                    item
                                                    container
                                                    xl={1.4}
                                                    lg={1.4}
                                                    md={1.3}
                                                    sm={1.8}
                                                    xs={1.8}
                                                    justifyContent="center"
                                                    textAlign={"center"}
                                                  >
                                                    <Avatar
                                                      style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        border: `1px solid ${theme.palette.background.border}`,
                                                      }}
                                                      src={""}
                                                    >
                                                      {item?._source?.account_name?.account_name?.charAt(
                                                        0
                                                      )}
                                                    </Avatar>
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    textAlign={"left"}
                                                    xl={10.2}
                                                    lg={10.2}
                                                    md={10.2}
                                                    sm={10.2}
                                                    xs={10.2}
                                                  >
                                                    <Typography
                                                      component={"span"}
                                                      className="two-line-paragraph"
                                                      sx={{
                                                        color: "#484848",
                                                        fontSize: "13px",
                                                      }}
                                                    >
                                                      {item._source.platform
                                                        ?.platform_name ===
                                                        "GMB" ||
                                                      item._source.platform
                                                        ?.platform_name ===
                                                        "Glassdoor" ? (
                                                        <span>
                                                          {item._source.ticket_desc.split(
                                                            "/ratings/"
                                                          )[1] && (
                                                            <>
                                                              Rating :{" "}
                                                              {returnStars(
                                                                item._source.ticket_desc.split(
                                                                  "/ratings/"
                                                                )[1]
                                                              )}
                                                              <br />
                                                            </>
                                                          )}
                                                          {item._source.ticket_desc.split(
                                                            "/ratings/"
                                                          )[0].length > 110
                                                            ? item._source.ticket_desc
                                                                .split(
                                                                  "/ratings/"
                                                                )[0]
                                                                .substring(
                                                                  0,
                                                                  110 - 3
                                                                )
                                                            : item._source.ticket_desc.split(
                                                                "/ratings/"
                                                              )[0]}
                                                        </span>
                                                      ) : item._source.platform
                                                          ?.platform_name ===
                                                          "Playstore" ||
                                                        item._source.platform
                                                          ?.platform_name ===
                                                          "Indeed" ||
                                                        item._source.platform
                                                          ?.platform_name ===
                                                          "Appstore" ? (
                                                        <span>
                                                          {item._source.ticket_desc.split(
                                                            "/ratings/"
                                                          )[1] && (
                                                            <>
                                                              Rating :{" "}
                                                              {returnStarsIcon(
                                                                item._source.ticket_desc.split(
                                                                  "/ratings/"
                                                                )[1]
                                                              )}
                                                              <br />
                                                            </>
                                                          )}
                                                          {item._source.ticket_desc.split(
                                                            "/ratings/"
                                                          )[0].length > 110
                                                            ? item._source.ticket_desc
                                                                .split(
                                                                  "/ratings/"
                                                                )[0]
                                                                .substring(
                                                                  0,
                                                                  110 - 3
                                                                )
                                                            : item._source.ticket_desc.split(
                                                                "/ratings/"
                                                              )[0]}
                                                        </span>
                                                      ) : item._source.ticket_desc.includes(
                                                          "| URL |"
                                                        ) ? (
                                                        <Box
                                                          width="100%"
                                                          hight="100%"
                                                        >
                                                          {/* <RenderMedia
                                                            image_url={extractUrl(
                                                              item._source
                                                                .ticket_desc
                                                            )}
                                                            mediaType="img"
                                                            height="100%"
                                                          /> */}
                                                          <Chip
                                                            label="Media Attached"
                                                            sx={{
                                                              "& .MuiChip-label":
                                                                {
                                                                  fontWeight:
                                                                    "bold",
                                                                },
                                                            }}
                                                          />
                                                        </Box>
                                                      ) : item._source
                                                          .ticket_desc.length >
                                                        110 ? (
                                                        item._source.ticket_desc
                                                          .replace(/&lt;/g, "<")
                                                          .replace(/&gt;/g, ">")
                                                          .replace(
                                                            /&amp;/g,
                                                            "&"
                                                          )
                                                          .substring(
                                                            0,
                                                            110 - 3
                                                          ) + "..."
                                                      ) : (
                                                        item._source.ticket_desc
                                                      )}
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            )}

                                          <Box width="100%">
                                            <Grid
                                              item
                                              container
                                              xl={12}
                                              lg={12}
                                              md={12}
                                              xs={12}
                                              alignItems="center"
                                              justifyContent={"space-between"}
                                            >
                                              <Grid
                                                item
                                                container
                                                xl={showBox ? 12 : 6}
                                                lg={showBox ? 12 : 6}
                                                md={showBox ? 12 : 6}
                                                xs={showBox ? 12 : 6}
                                                alignItems="center"
                                                justifyContent={"flex-start"}
                                                my={0.5}
                                              >
                                                <Grid
                                                  item
                                                  xl={1.1}
                                                  lg={1.1}
                                                  md={1.1}
                                                  sm={1.5}
                                                  xs={1.5}
                                                  // className="bg-info"
                                                ></Grid>

                                                <Grid
                                                  item
                                                  alignItems="center"
                                                  justifyContent={"center"}
                                                  textAlign="center"
                                                  mr={1}
                                                >
                                                  <ListItem
                                                    sx={{
                                                      color: `${theme.palette.ticketListView.iconColor}`,
                                                    }}
                                                    className="m-0 p-0"
                                                  >
                                                    <IconButton
                                                      title="Ticket Id"
                                                      color="primary"
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        copyToClipboard(
                                                          item._id
                                                        );
                                                      }}
                                                      sx={{
                                                        color: `${theme.palette.ticketListView.iconColor}`,
                                                      }}
                                                    >
                                                      <FileCopyIcon
                                                        fontSize="small"
                                                        style={{
                                                          marginRight: -5,
                                                          width: "13px",
                                                        }}
                                                      />
                                                    </IconButton>
                                                    <p
                                                      className="m-0 p-0"
                                                      style={{
                                                        fontSize: "13px",
                                                      }}
                                                    >
                                                      Ticket ID
                                                    </p>
                                                    <Snackbar
                                                      message="Ticket Id copied to clipboard"
                                                      sx={{ height: "70%" }}
                                                      anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                      }}
                                                      autoHideDuration={500}
                                                      onClose={() =>
                                                        setCopied(false)
                                                      }
                                                      open={copied}
                                                    />{" "}
                                                  </ListItem>
                                                </Grid>

                                                {item._source.status &&
                                                  item._source.status
                                                    .status_name && (
                                                    <Grid
                                                      item
                                                      mx={1}
                                                      alignItems="center"
                                                      justifyContent={"center"}
                                                      textAlign="center"
                                                      sx={{
                                                        borderRight: !showBox
                                                          ? `1px solid ${theme.palette.background.primary}`
                                                          : `1px solid transparent`,
                                                      }}
                                                    >
                                                      <ListItem
                                                        style={{
                                                          padding: 0,
                                                          margin: 0,
                                                        }}
                                                      >
                                                        <FolderIcon
                                                          style={{
                                                            width: "13px",
                                                            marginRight: 2,
                                                            color: `${theme.palette.ticketListView.iconColor}`,
                                                          }}
                                                        />
                                                        <p
                                                          className="m-0 p-0"
                                                          style={{
                                                            fontSize: "13px",
                                                          }}
                                                        >
                                                          {item._source.status
                                                            .status_name
                                                            .length > 12
                                                            ? item._source.status.status_name.substring(
                                                                0,
                                                                12 - 2
                                                              ) + ".."
                                                            : item._source
                                                                .status
                                                                .status_name}
                                                        </p>
                                                      </ListItem>
                                                    </Grid>
                                                  )}

                                                {item._source.priority &&
                                                  item._source.priority
                                                    .priority_name && (
                                                    <Grid
                                                      item
                                                      alignItems="center"
                                                      justifyContent={"center"}
                                                      textAlign="center"
                                                      sx={{
                                                        borderRight: !showBox
                                                          ? `1px solid ${theme.palette.background.primary}`
                                                          : `1px solid transparent`,
                                                      }}
                                                      ml={1}
                                                    >
                                                      <ListItem
                                                        style={{
                                                          padding: 0,
                                                          margin: 0,
                                                        }}
                                                      >
                                                        <Box
                                                          mr={0.5}
                                                          width={8}
                                                          height={8}
                                                          borderRadius="50%"
                                                          sx={{
                                                            backgroundColor:
                                                              renderPriorityColor(
                                                                item._source
                                                                  .priority
                                                                  .priority_name
                                                              ),
                                                          }}
                                                        ></Box>
                                                        <p
                                                          className="m-0 p-0"
                                                          style={{
                                                            fontSize: "13px",
                                                          }}
                                                        >
                                                          {
                                                            item._source
                                                              .priority
                                                              .priority_name
                                                          }
                                                        </p>
                                                      </ListItem>
                                                    </Grid>
                                                  )}
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        </Box>
                                        <hr
                                          className="p-0 m-0"
                                          style={{
                                            border: "none",
                                            borderTop: `0.1px solid ${theme.palette.background.border}`,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Box>
                                )
                            )
                          ) : (
                            <NoDataFound />
                          )}
                        </Box>
                      </Box>
                    ) : null}
                  </Grid>

                  {showBox && (
                    <Grid
                      item
                      xl={showBox ? 9 : null}
                      lg={showBox ? 9 : null}
                      md={showBox ? 9 : null}
                      sm={showBox ? 9 : null}
                      laptop={showBox ? 9 : null}
                      xs={showBox ? 9 : null}
                      sx={{
                        height: `${
                          TicketBoxHeight -
                          statusbarHeight -
                          FiltersHeight -
                          paginationHeight
                        }px`,
                      }}
                    >
                      <Slide
                        direction="left"
                        in={showBox}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Paper style={{ height: "100%" }}>
                          {/* <ErrorBoundary
                        message="Oops! Something went wrong. Our team has been notified and we are working to fix the problem. Please try again later.
"
                      > */}

                          <TicketDetails
                            onClose={() => {
                              setShowBox(false);
                            }}
                            setSelectedRows={props.setSelectionModel}
                            onCloseCategoryDialog={() => {
                              setCategoryUpdateOpen(false);
                            }}
                            filterTagList={filterTagListMain}
                            openCloseModal={openCloseModal}
                            categoryUpdateOpen={categoryUpdateOpen}
                            pageNumber={pageNumber}
                            ticketData={ticketData && ticketData}
                            // customerData={props.customerData}
                            filters={filters.current}
                            ticket_count_filters={ticketCountFilterRef.current}
                            statusList={statusList && statusList}
                            finalStatuses={finalStatuses}
                            filterprioritylist={
                              filterprioritylist && filterprioritylist
                            }
                            userlist={userlist && userlist}
                            setShowDetailsBox={setShowBox}
                            replyClicked={replyClicked}
                            setReplyClicked={setReplyClicked}
                            emailClicked={emailClicked}
                            setEmailClicked={setEmailClicked}
                            fetchFunction={(item) => {
                              console.log(
                                "TICKET_UPDATE_FETCH_FUNCTION_CALLING",
                                item,
                                isLastPage,
                                lastPageTickets,
                                pageNumber
                              );
                              if (
                                !item &&
                                isLastPage &&
                                lastPageTickets === 1
                              ) {
                                if (pageNumber === 1) {
                                  setPageNumber(0);
                                  fetchFunction(0);
                                } else {
                                  fetchFunction(pageNumber - 1);
                                }
                              } else {
                                fetchFunction(pageNumber);
                              }
                            }}
                            page_limit={page_limit}
                            page_length={
                              props.tickets.hits &&
                              props.tickets.hits.hits &&
                              props.tickets.hits.hits.length
                            }
                            customticketfields={props.customticketfields}
                            customticketfields_filter={
                              props.customticketfields_filter
                            }
                          />
                          {/* </ErrorBoundary> */}
                        </Paper>
                      </Slide>
                    </Grid>
                  )}
                </Grid>

                <CategoryUpdateDialog
                  open={categoryUpdateOpen}
                  onClose={() => {
                    setCategoryUpdateOpen(false);
                  }}
                  ticketData={CategoryUpdateData && CategoryUpdateData}
                  custom_value={
                    CategoryUpdateData &&
                    CategoryUpdateData._source &&
                    CategoryUpdateData._source.custom_value &&
                    CategoryUpdateData._source.custom_value.length !== 0
                      ? CategoryUpdateData._source.custom_value
                      : []
                  }
                  customticketfields={props.customticketfields}
                  customticketfields_filter={props.customticketfields_filter}
                  fetchFunction={() => {
                    fetchFunction(pageNumber);
                  }}
                  from="ticketlist"
                />

                <TagUpdateDailog
                  filterTagList={filterTagListMain}
                  open={tagUpdateOpen}
                  onClose={() => {
                    setTagUpdateOpen(false);
                  }}
                  ticketData={tagUpdateData && tagUpdateData}
                  fetchFunction={() => {
                    fetchFunction(pageNumber);
                  }}
                  from="ticketlist"
                />
              </>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "50vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Box>
      </Box>

      <BulkAssign
        open={props.openBulkAssignDialog}
        onClose={() => {
          props.setOpenBulkAssignDialog(false);
        }}
        userlist={userlist && userlist}
        statusList={statusList}
        selectedRows={
          props.selectionModel.length > 0 ? props.selectionModel : []
        }
        setSelectedRows={props.setSelectionModel}
        allTicket={
          props.tickets.hits && props.tickets.hits.hits.length > 0
            ? props.tickets.hits.hits
            : []
        }
        fetchFunction={(item) => {
          setPageNumber(0);
          fetchFunction(0);
        }}
      />

      <Dialog
        maxWidth="md"
        fullWidth
        open={props.openReplyErrorsDialog}
        // TransitionComponent={Transition}
        onClose={handleReplyErrorsClose}
        TransitionComponent={Zoom}
        transitionDuration={350}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            boxShadow: "8px 8px 8px rgba(0, 0, 0, 0.4)",
          },
        }}

        // className="bg-success"
      >
        <>
          <Typography
            my={1}
            sx={{
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "bold",
            }}
          >
            {formatName("Reply Logs")}
          </Typography>

          <Box mx={2}>
            {replyLogs && replyLogs?.res?.length !== 0 ? (
              replyLogs?.res?.map((reply) => {
                return (
                  <Box
                    sx={{
                      p: 2,
                      m: 1,
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      transition: "background-color 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>
                      Message: {reply?.message ?? "N/A"}
                    </Box>
                    <Box>Status: {reply?.status ?? "N/A"}</Box>
                    <Box>Ticket ID: {reply?.ticket_id ?? "N/A"}</Box>
                  </Box>
                );
              })
            ) : (
              <NoDataFound />
            )}
          </Box>
        </>
      </Dialog>

      <Dialog
        maxWidth="md"
        fullWidth
        open={props.openReplyDialog}
        onClose={handleReplyClose}
        TransitionComponent={Zoom}
        transitionDuration={350}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            boxShadow: "8px 8px 8px rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        <ReplyComponent
          selectedRows={
            props.selectionModel.length > 0 ? props.selectionModel : []
          }
          setSelectedRows={props.setSelectionModel}
          set_Selected_tickets={set_Selected_tickets}
          SetReplyLogs={SetReplyLogs}
          setOpenReplyErrorsDialog={props.setOpenReplyErrorsDialog}
          statusList={statusList ? statusList : []}
          fetchFunction={fetchFunction}
          setShowReplyBox={props.setOpenReplyDialog}
          filters={filters.current}
          pageNumber={pageNumber}
          page_limit={page_limit}
          page_length={
            props.tickets.hits &&
            props.tickets.hits.hits &&
            props.tickets.hits.hits.length
          }
          ticket_count_filters={ticketCountFilterRef.current}
        />
      </Dialog>

      <Box
        // height={returnHeightOfPagination()}
        position="fixed"
        width="100%"
        bottom={0}
        right={0}
        ref={paginationRef}
        // className="bg-info"
      >
        {props.tickets.hits
          ? props.tickets.hits.hits.length !== 0 && (
              <Grid
                item
                container
                justifyContent={"flex-end"}
                alignItems="center"
                sx={{
                  marginRight: 20,
                  paddingY: "10px",
                  position: "relative",
                  bottom: 0,
                  right: 0,
                }}
              >
                <PaginationUI
                  pagelength={props.pagination.tickets.total_page_no}
                  page={props.pagination.tickets.current_page_no}
                  handleChange={handleChangePagination}
                />
              </Grid>
            )
          : null}
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(AllTickets);
