import { BaseModel } from "sjs-base-model";

// post model
export default class AssignmentRulePostModel extends BaseModel {
  /*
   * assignment rule-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
