import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import GMBReplyPostModel from "./models/GMBReplyPostModel";
import GmbReviewFeedsModel from "./models/GmbReviewFeedsModel";
import GMBReviewPutModel from "./models/GMBReviewPutModel";
import PlaystoreBulkReplyPostModel from "../playStoreReply/PlaystoreBulkReplyPostModel";

// GmbReviewEffect
export default class GmbReviewEffect {
  // calling an API according to the Quora Feeds model
  // Get method for the GMB Feeds
  // static async requestGmbReviewFeed(params, callBackFunction) {
  //   const endpoint = environment.api.GMBReviewFeeds;
  //   let response = EffectUtility.getToModel(
  //     GmbReviewFeedsModel,
  //     endpoint,
  //     params
  //   );

  //   response.then((resp) => {
  //     if (resp.hits.hits && callBackFunction) {
  //       callBackFunction(resp);
  //     }
  //   });

  //   return response;
  // }

  // Post api
  static async requestGmbReviewFeed(data, callBackFunction) {
    const endpoint = environment.api.GMBReviewFeeds;
    let response = EffectUtility.postToModel(
      GmbReviewFeedsModel,
      endpoint,
      JSON.stringify(data)
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestGmbReviewFeedClear() {
    return {};
  }

  // Get method for the GMB Feeds filter
  static async requestGmbReviewFeedFilter(params) {
    const endpoint = environment.api.GMBReviewFeedsFilter;
    return EffectUtility.getToModel(GmbReviewFeedsModel, endpoint, params);
  }

  // Gmb Post API
  static async requestGmbReviewReplyPost(id, data, callback) {
    const endpoint = environment.api.GMBReviewReply + "/" + id;

    let response = EffectUtility.postToModel(GMBReplyPostModel, endpoint, data);

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
        resp.status === "fail" && alert(resp.message);
      }
    });

    return response;
  }
  static async requestGmbAnswerReplyPost(id, data, callback) {
    const endpoint = environment.api.GMBAnswerReply + "/" + id;

    let response = EffectUtility.postToModel(GMBReplyPostModel, endpoint, data);

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
        resp.status === "fail" && alert(resp.message);
      }
    });

    return response;
  }

  static async requestGmbReviewBulkReplyPost(data, callback) {
    const endpoint = environment.api.GMBReviewBulkReply;

    let response = EffectUtility.postToModel(
      PlaystoreBulkReplyPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp && callback) {
        callback(resp);
      }
    });

    return response;
  }

  // put method for the Gmb Review Feeds
  static async requestPutGMBReviews(data, id) {
    const endpoint = environment.api.GMBReviewFeeds + "/" + id;
    return EffectUtility.putToModel(GMBReviewPutModel, endpoint, data);
  }
}
