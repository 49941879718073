import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ClientBulkPutModel from "./model/ClientBulkPutModel";
import ClientModel from "./model/ClientModel";
import ClientPostModel from "./model/ClientPostModel";
import ClientPutModel from "./model/ClientPutModel";
import AdminResetPasswordPutModel from "./model/AdminResetPasswordPutModel";
import ClientByIdModel from "./model/ClientByIdModel";
import A3ClientDeviceResetModel from "./model/A3ClientDeviceResetModel";
import ClaimPostModal from "./model/ClaimPostModal";

// StatusEffect
export default class ClientEffect {
  // calling an API according to the status model
  // Get method for the
  static async requestClient(params) {
    const endpoint = environment.api.clientA3;
    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  static async requestClientClear() {
    return {};
  }

  static async requestClientFilterClear() {
    return {};
  }

  // Get method for the client filter
  static async requestClientFilter(params) {
    const endpoint = environment.api.clientA3;
    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  static async requestSubClientFilter(params) {
    const endpoint = environment.api.clientA3;
    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  // Get method for the client filter
  static async requestClientById(id) {
    const endpoint = environment.api.clientA3 + "/" + id;
    return EffectUtility.getToModel(ClientByIdModel, endpoint);
  }

  // put method for the client
  static async requestPutClient(data, id) {
    const endpoint = environment.api.clientA3 + "/" + id;
    return EffectUtility.putToModel(ClientPutModel, endpoint, data);
  }

  // post method for the client
  static async requestPostClient(data, callBack) {
    const endpoint = environment.api.clientA3 + "/add";
    let response = EffectUtility.postToModel(ClientPostModel, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestPostClaimRevenueAll(data, callBack) {
    const endpoint = environment.api.revenueClaimAll;
    let response = EffectUtility.postToModel(ClaimPostModal, endpoint, data);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // post method for the client
  static async requestPutClientBulkUpdate(data) {
    const endpoint = environment.api.clientUpdate;
    return EffectUtility.putToModel(ClientBulkPutModel, endpoint, data);
  }

  static async requestAdminResetPassword(data) {
    const endpoint = environment.api.a3AdminResetPassword;
    return EffectUtility.putToModel(AdminResetPasswordPutModel, endpoint, data);
  }

  static async requestDeviceReset(id) {
    const endpoint = environment.api.A3ClientResetDevice + "/" + id;
    return EffectUtility.deleteToModel(A3ClientDeviceResetModel, endpoint, id);
  }
}
