import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// Mcn   data model
export default class McnMonitoringDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  category = {
    category_id :"",
    category_name :""
  }
  channel_id= "";
  channel_name= "";
  competitor_name = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  enabled = Boolean;
  modified_by = {
    user_id: "",
    user_name: "",
  };
  modified_on = "";
  project = {
    project_id: "",
    project_name: "",
  };

  constructor(data) {
    super();

    this.update(data);
  }
}
