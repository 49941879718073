import BaseReducer from "../../../utilities/BaseReducer";
import LinkedInAccountAction from "./LinkedInAccountAction";

export default class LinkedInAccountReducer extends BaseReducer {
  initialState = {
    linkedInAccount: [],
  };
  [LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      linkedInAccount: action.payload,
    };
  }
}
