import React, { useRef } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ClickAwayListener } from "@mui/material";

const CustomPopper = ({
  ref,
  anchorEl,
  open,
  onClose,

  renderContent,
}) => {
  const popperRef = useRef(null);

  const handleClickAway = (event) => {
    if (popperRef.current && !popperRef.current.contains(event.target)) {
      onClose();
    }
  };
  return (
    <Popper
      ref={popperRef}
      placement="bottom-start"
      disablePortal
      open={open}
      anchorEl={anchorEl}
      transition
      sx={{
        zIndex: 10,
        position: "relative",
      }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              className="scrollable"
              sx={{
                p: 1,
                zIndex: 10,
                boxShadow: "0px 1px 4px 0px grey",
                maxHeight: "350px",
                overflowY: "auto",
                position: "relative",
                minWidth: 100,
              }}
            >
              {renderContent}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

export default CustomPopper;
