import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import LanguageModel from "./model/LanguageModel";

// LanguageEffect
export default class LanguageEffect {
  // calling an API according to the LanguageEffect model
  static async requestLanguage(params) {
    const endpoint = environment.api.language;
    return EffectUtility.getToModel(LanguageModel, endpoint, params);
  }

  // Get method for the Language filter
  static async requestLanguageFilter(params) {
    const endpoint = environment.api.language;
    return EffectUtility.getToModel(LanguageModel, endpoint, params);
  }
}
