import { BaseModel } from "sjs-base-model";

export default class AiSuggestionPostModel extends BaseModel {
  // response getting from the api mentioned end point
  responses = [];
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
