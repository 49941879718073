import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../../../views/App";
import { connect } from "react-redux";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import YesOrNoDailog from "../../../../../components/yesOrNoDailog/YesOrNoDailog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import jwtDecode from "jwt-decode";
import TextArea from "antd/lib/input/TextArea";
import MuiButton from "../../../../../components/muiButton/MuiButton";

// mapstateto props function fetching
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TicketsAction.REQUEST_PUT_TICKETS]),
});

const NotesUI = (props) => {
  const { item, index, Notes, ticketData } = props;
  const formatDate = (dateString) => {
    let date = new Date(dateString);
    return (
      date.toDateString() +
      " " +
      date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const notesPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const [loading, setLoading] = useState(false);
  const [currentNotesValue, setCurrentNotesVlaue] = useState("");
  const [deleteNotesIndex, setDeleteNotesIndex] = useState(null);

  let initialNotes = {
    notes: currentNotesValue,
  };

  const handleNotesPopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const deleteNotesFunction = (deleteIndex) => {
    const updatedNotes = Notes.filter((note, index) => index !== deleteIndex);

    //      Notes: updatedNotes.map((note) => ({
    //     notes_content: note.notes_content,
    //   })),

    let object = {
      Notes: updatedNotes,
    };
    const data = object;

    props
      .dispatch(TicketsAction.requestPutTicket(data, ticketData._id))
      .then(() => {
        props.dispatch(TicketsAction.requestTicketsById(ticketData._id));
        setConfirmDialog(false);
      });
  };

  const handleClose = (event) => {
    if (
      notesPopperRef.current &&
      notesPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  function Required() {
    return (
      <>
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  return (
    <Box
      p={1}
      m={1}
      sx={{
        border: "solid 0.5px #92b9c4",
        borderRadius: 1,
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        "&:hover": {
          transform: !open && "scale(1.02)",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <span style={{ overflowWrap: "anywhere" }}>
                {/* <span style={{ fontWeight: "bold", overflowWrap: "anywhere" }}>
                  {index + 1}
                </span> */}
                {item.user_name}
              </span>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4.5} sx={{ textAlign: "right" }}>
          {formatDate(item.notes_created_on)}
        </Grid>

        <Grid item xs={0.5} sx={{ textAlign: "right" }}>
          <Typography
            onClick={handleNotesPopper}
            component={"div"}
            ref={notesPopperRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            style={{
              backgroundColor: "none",
              // border: "1px solid #e5e2e2",
              borderRadius: 4,
            }}
          >
            <IconButton
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentNotesVlaue(item.notes_content);
                setDeleteNotesIndex(index);
              }}
            >
              <FontAwesomeIcon
                icon={faPen}
                style={{
                  height: "13px",
                  width: "13px",
                  color: `${theme.palette.ticketListView.iconColor}`,
                }}
                title="Edit"
              ></FontAwesomeIcon>
            </IconButton>
          </Typography>
        </Grid>

        <Grid item xs={0.5} sx={{ textAlign: "right" }}>
          <IconButton
            sx={{
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                height: "13px",
                width: "13px",
                color: `${theme.palette.ticketListView.iconColor}`,
              }}
              onClick={() => {
                setConfirmDialog(true);
              }}
              title="Delete"
            ></FontAwesomeIcon>
          </IconButton>
        </Grid>

        <Popper
          open={open}
          anchorEl={notesPopperRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 100 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper
                style={{
                  boxShadow: theme.shadows,
                  width: 500,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <Grid lg={12} md={12} sm={12} sx={{ p: 3 }}>
                      <Formik
                        initialValues={initialNotes}
                        enableReinitialize
                        validate={(values) => {
                          var errors = {};

                          if (!values.notes) {
                            errors.notes = <Required />;
                          }

                          return errors;
                        }}
                        onSubmit={(values, formikHelpers) => {
                          setLoading(true);

                          const updatedNotes = Notes.filter(
                            (note, index) => index !== deleteNotesIndex
                          );

                          let object = {
                            Notes: [
                              ...updatedNotes,
                              {
                                notes_content: values.notes,
                                // notes_created_on: new Date(),
                              },
                            ],
                          };

                          const data = object;

                          props
                            .dispatch(
                              TicketsAction.requestPutTicket(
                                data,
                                ticketData._id
                              )
                            )
                            .then(() => {
                              props.dispatch(
                                TicketsAction.requestTicketsById(ticketData._id)
                              );

                              setLoading(false);
                              setOpen(false);
                            });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          submitForm,
                          resetForm,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Grid lg={12} md={12} sm={12}>
                              <Field
                                name="notes"
                                as={TextArea}
                                // defaultValue={demoText.textarea}
                                placeholder="Enter Notes...."
                                className="textAreaFiled"
                                style={{
                                  width: "100%",
                                  height: 100,
                                }}
                              />
                              <ErrorMessage name="notes" />
                            </Grid>
                            <Grid
                              lg={12}
                              md={12}
                              sm={12}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Grid
                                lg={6}
                                md={6}
                                sm={6}
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <MuiButton
                                  name={"Cancel"}
                                  // type="reset"
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                ></MuiButton>
                                <MuiButton
                                  name={"Submit"}
                                  type="submit"
                                  loading={loading}
                                  noTextOnLoading={true}
                                ></MuiButton>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>

      <YesOrNoDailog
        open={Boolean(confirmDialog)}
        content={"Are you sure you want to delete ?"}
        loading={props.isRequesting}
        cancel={() => {
          setConfirmDialog(false);
        }}
        submit={() => {
          deleteNotesFunction(index);
        }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box width="100%">
            <span style={{ overflowWrap: "anywhere" }}>
              {item.notes_content}
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(NotesUI);
