import BaseReducer from "../../../utilities/BaseReducer";
import AssignmentRuleAction from "./AssignmentRuleAction";

export default class AssignmentRuleFilterReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    assignmentRuleFilter: [],
  };

  //operation Filter
  [AssignmentRuleAction.REQUEST_ASSIGNMENT_RULE_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      assignmentRuleFilter: action.payload,
    };
  }
}
