import BaseReducer from "../../../utilities/BaseReducer";
import FaceBookAccountAction from "./FaceBookAccountAction";

// FaceBookAccountFilterReduser function
export default class FaceBookAccountFilterReduser extends BaseReducer {
  //initial state of YoutubeAccount
  initialState = {
    facebookAccountFilter: [],
  };
  //YoutubeAccountAction request action finish
  [FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT_FILTER_FINIISHED](
    state,
    action
  ) {
    return {
      ...state,
      facebookAccountFilter: action.payload,
    };
  }
}
