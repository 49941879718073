import ActionUtility from "../../../../utilities/ActionUtility";
import CustomerEffect from "./CustomerEffect";

export default class CustomerAction {
  // CustomerAction action with an function declaration
  static REQUEST_CUSTOMER = "CustomerAction.REQUEST_CUSTOMER";
  static REQUEST_CUSTOMER_FINISHED = "CustomerAction.REQUEST_CUSTOMER_FINISHED";

  static REQUEST_CUSTOMER_FILTER = "CustomerAction.REQUEST_CUSTOMER_FILTER";
  static REQUEST_CUSTOMER_FILTER_FINISHED =
    "CustomerAction.REQUEST_CUSTOMER_FILTER_FINISHED";

  static REQUEST_PUT_CUSTOMER = "CustomerAction.REQUEST_PUT_CUSTOMER";
  static REQUEST_PUT_CUSTOMER_FINISHED =
    "CustomerAction.REQUEST_PUT_CUSTOMER_FINISHED";

  static REQUEST_POST_CUSTOMER = "CustomerAction.REQUEST_POST_CUSTOMER";
  static REQUEST_POST_CUSTOMER_FINISHED =
    "CustomerAction.REQUEST_POST_CUSTOMER_FINISHED";

  static REQUEST_CUSTOMER_CLEAR = "CustomerAction.REQUEST_CUSTOMER_CLEAR";
  static REQUEST_CUSTOMER_CLEAR_FINISHED =
    "CustomerAction.REQUEST_CUSTOMER_CLEAR_FINISHED";

  static REQUEST_CUSTOMER_FILTER_CLEAR =
    "CustomerAction.REQUEST_CUSTOMER_FILTER_CLEAR";
  static REQUEST_CUSTOMER_FILTER_CLEAR_FINISHED =
    "CustomerAction.REQUEST_CUSTOMER_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_CUSTOMER = "CustomerAction.REQUEST_DELETE_CUSTOMER";
  static REQUEST_DELETE_CUSTOMER_FINISHED =
    "CustomerAction.REQUEST_DELETE_CUSTOMER_FINISHED";

  // METHODS
  // Customer GET function
  static requesCustomer(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerAction.REQUEST_CUSTOMER,
        CustomerEffect.requesCustomer,
        params
      );
    };
  }

  static requesCustomerClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerAction.REQUEST_CUSTOMER_CLEAR,
        CustomerEffect.requesCustomerClear
      );
    };
  }

  static requesCustomerFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerAction.REQUEST_CUSTOMER_FILTER_CLEAR,
        CustomerEffect.requesCustomerFilterClear
      );
    };
  }

  static requestCustomerFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerAction.REQUEST_CUSTOMER_FILTER,
        CustomerEffect.requestCustomerFilter,
        params
      );
    };
  }
  static requestPutCustomer(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerAction.REQUEST_PUT_CUSTOMER,
        CustomerEffect.requestPutCustomer,
        data,
        id
      );
    };
  }

  static requestPostCustomer(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerAction.REQUEST_POST_CUSTOMER,
        CustomerEffect.requestPostCustomer,
        data
      );
    };
  }
  static requestDeleteCustomer(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerAction.REQUEST_DELETE_CUSTOMER,
        CustomerEffect.requestDeleteCustomer,
        id
      );
    };
  }
}
