import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import PlayStoreReplyPostModel from "./PlayStoreReplyPostModel";
import PlaystoreBulkReplyPostModel from "./PlaystoreBulkReplyPostModel";

//Category effect
export default class PlayStoreReplyEffect {
  // TwitterDirectmessage Post API
  static async requestPostPlayStoreReply(data, callback) {
    const endpoint = environment.api.playStoreReply;

    let response = EffectUtility.postToModel(
      PlayStoreReplyPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp && callback) {
        callback(resp);
      }
    });

    return response;
  }
  static async requestPostPlayStoreBulkReply(data, callback) {
    const endpoint = environment.api.playStoreBulkReply;

    let response = EffectUtility.postToModel(
      PlaystoreBulkReplyPostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp && callback) {
        callback(resp);
      }
    });

    return response;
  }
}
