import BaseReducer from "../../../../utilities/BaseReducer";
import ChannelAnalyticsAction from "./ChannelAnalyticsAction";

export default class ChannelAnalyticsReducer extends BaseReducer {
  initialState = {
    channelAnalytics: [],
  };

  [ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS_FINISHED](state, action) {
    return {
      ...state,
      channelAnalytics: action.payload,
    };
  }
}
