import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import AllMentionsGetModel from "./model/AllMentionsGetModel";
import MentionsCustomerAndAccountNames from "./model/MentionsCustomerAndAccountNames";

// AllMentionsEffect
export default class AllMentionsEffect {
  // calling an API according to the allmentions model
  // Get method for the
  static async requestAllmentions(params) {
    const endpoint = environment.api.allMentions;
    return EffectUtility.getToModel(AllMentionsGetModel, endpoint, params);
  }

  // Get method for the allmentions filter
  static async requestAllmentionsFilter(params) {
    const endpoint = environment.api.allMentions;
    return EffectUtility.getToModel(AllMentionsGetModel, endpoint, params);
  }

  static async requestAllmentionsExportFilter(params, callBack) {
    const endpoint = environment.api.allMentions;
    const Response = EffectUtility.getToModel(
      AllMentionsGetModel,
      endpoint,
      params
    );

    Response.then((response) => {
      if (response && callBack) {
        callBack(response);
      }
    });

    return Response;
  }

  static async requestMentionsCustomerAndAccountNames(params) {
    const endpoint = environment.api.ormCustomerAndAccountNames;
    return EffectUtility.getToModel(
      MentionsCustomerAndAccountNames,
      endpoint,
      params
    );
  }
}
