import BaseReducer from "../../../../utilities/BaseReducer";
import DiscoveredDataAction from "./DiscoveredDataAction";

export default class DiscoveredDataReducer extends BaseReducer {
  initialState = {
    websweepxDiscoveredData: [],
  };
  [DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA_FINISHED](state, action) {
    return {
      ...state,
      websweepxDiscoveredData: action.payload,
    };
  }

  [DiscoveredDataAction.REQUEST_CLEAR_DISCOVERED_DATA_FINISHED](state, action) {
    return {
      ...state,
      websweepxDiscoveredData: action.payload,
    };
  }
}
