import BaseReducer from "../../../utilities/BaseReducer";
import DiscordFeedsAction from "./DiscordFeedsAction";

export default class DiscordFeedReduser extends BaseReducer {
  initialState = {
    discordFeeds: [],
  };

  [DiscordFeedsAction.REQUEST_DISCORD_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      discordFeeds: action.payload,
    };
  }

  [DiscordFeedsAction.REQUEST_DISCORD_FEEDS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      discordFeeds: action.payload,
    };
  }
}
