import { BaseModel } from "sjs-base-model";

//youtube delete Model
export default class YoutubeAccountDeleteModel extends BaseModel {
  result = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
