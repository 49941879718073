import BaseReducer from "../../../utilities/BaseReducer";
import GmbQuestionFeedsAction from "./GmbQuestionFeedsAction";

// GmbQuestionsReduser  function
export default class GmbQuestionsReduser extends BaseReducer {
  initialState = {
    GMBQuestionFeeds: [],
  };
  [GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_FINISHED](state, action) {
    return {
      ...state,
      GMBQuestionFeeds: action.payload,
    };
  }

  [GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      GMBQuestionFeeds: action.payload,
    };
  }
}
