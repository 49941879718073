import { BaseModel } from "sjs-base-model";
import CategoryDataHitsModel from "./CategoryDataHitsModel";

//Category hits model
export default class CategoryHitsModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  hits = [{ CategoryDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
