import ActionUtility from "../../../utilities/ActionUtility";
import RedditFeedEffect from "./RedditFeedEffect";

export default class RedditFeedAction {
  static REQUEST_REDDIT_FEEDS = "RedditFeedAction.REQUEST_REDDIT_FEEDS";
  static REQUEST_REDDIT_FEEDS_FINISHED =
    "RedditFeedAction.REQUEST_REDDIT_FEEDS_FINISHED";

  static REQUEST_REDDIT_POSTS = "RedditFeedAction.REQUEST_REDDIT_POSTS";
  static REQUEST_REDDIT_POSTS_FINISHED =
    "RedditFeedAction.REQUEST_REDDIT_POSTS_FINISHED";

  static REQUEST_REDDIT_COMMENTS = "RedditFeedAction.REQUEST_REDDIT_COMMENTS";
  static REQUEST_REDDIT_COMMENTS_FINISHED =
    "RedditFeedAction.REQUEST_REDDIT_COMMENTS_FINISHED";

  static REQUEST_REDDIT_POSTS_CLEAR =
    "RedditFeedAction.REQUEST_REDDIT_POSTS_CLEAR";
  static REQUEST_REDDIT_POSTS_CLEAR_FINISHED =
    "RedditFeedAction.REQUEST_REDDIT_POSTS_FINISHED";

  static REQUEST_REDDIT_COMMENTS_CLEAR =
    "RedditFeedAction.REQUEST_REDDIT_COMMENTS_CLEAR";
  static REQUEST_REDDIT_COMMENTS_CLEAR_FINISHED =
    "RedditFeedAction.REQUEST_REDDIT_COMMENTS_CLEAR_FINISHED";

  static REQUEST_REDDIT_FEEDS_CLEAR =
    "RedditFeedAction.REQUEST_REDDIT_FEEDS_CLEAR";
  static REQUEST_REDDIT_FEEDS_CLEAR_FINISHED =
    "RedditFeedAction.REQUEST_REDDIT_FEEDS_CLEAR_FINISHED";

  static REQUEST_PUT_REDDIT_FEEDS = "RedditFeedAction.REQUEST_PUT_REDDIT_FEEDS";
  static REQUEST_PUT_REDDIT_FEEDS_FINISHED =
    "RedditFeedAction.REQUEST_PUT_REDDIT_FEEDS_FINISHED";

  // METHODS
  static requestRedditFeed(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditFeedAction.REQUEST_REDDIT_FEEDS,
        RedditFeedEffect.requestRedditFeed,
        params,
        callBackFunction
      );
    };
  }

  static requestRedditPosts(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditFeedAction.REQUEST_REDDIT_POSTS,
        RedditFeedEffect.requestRedditPosts,
        params,
        callBackFunction
      );
    };
  }

  static requestRedditPostsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditFeedAction.REQUEST_REDDIT_POSTS_CLEAR,
        RedditFeedEffect.requestRedditPostsClear
      );
    };
  }

  static requestRedditComments(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditFeedAction.REQUEST_REDDIT_COMMENTS,
        RedditFeedEffect.requestRedditComments,
        params,
        callBackFunction
      );
    };
  }
  static requestRedditCommentsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditFeedAction.REQUEST_REDDIT_COMMENTS_CLEAR,
        RedditFeedEffect.requestRedditCommentsClear
      );
    };
  }

  static requestRedditFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditFeedAction.REQUEST_REDDIT_FEEDS_CLEAR,
        RedditFeedEffect.requestRedditFeedClear
      );
    };
  }

  static requestPutRedditFeed(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditFeedAction.REQUEST_PUT_REDDIT_FEEDS,
        RedditFeedEffect.requestPutRedditFeed,
        data,
        id
      );
    };
  }
}
