import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import MailTriggerListGetModel from "./model/MailTriggerListGetModel";
import MailTriggerListPostModel from "./model/MailTriggerListPostModel";
import MailTriggerListPutModel from "./model/MailTriggerListPutModel";

// MailTriggerListEffect
export default class MailTriggerListEffect {
  // calling an API according to the Organization model
  // Get method for the
  static async requestTriggerList(params) {
    const endpoint = environment.api.triggerList;
    return EffectUtility.getToModel(MailTriggerListGetModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestTriggerListFilter(params) {
    const endpoint = environment.api.triggerList;
    return EffectUtility.getToModel(MailTriggerListGetModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutTriggerList(data, id) {
    const endpoint = environment.api.triggerList + "/" + id;
    return EffectUtility.putToModel(MailTriggerListPostModel, endpoint, data);
  }

  // post method for the Organization
  static async requestPostTriggerList(data) {
    const endpoint = environment.api.triggerList + "/add";

    return EffectUtility.postToModel(MailTriggerListPutModel, endpoint, data);
  }
}
