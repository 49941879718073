import BaseReducer from "../../../utilities/BaseReducer";
import ResponseCategoryAction from "./ResponseCategoryAction";

// reducer function
export default class ResponseCategoryReducer extends BaseReducer {
  initialState = {
    responseCategory: [],
  };

  [ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY_FINISHED](state, action) {
    return {
      ...state,
      responseCategory: action.payload,
    };
  }
}
