import { BaseModel } from "sjs-base-model";
import CustomTicketFieldsSourceModel from "./CustomTicketFieldsSourceModel";

export default class TicketsHitsDataModel extends BaseModel {
  //ticket list hits data model
  _id = "";
  _index = "";
  _score = "";
  _source = { CustomTicketFieldsSourceModel };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
