import { BaseModel } from "sjs-base-model";
import SurveyHitsModel from "./SurveyHitsModel";
import SurveyShardsModel from "./SurveyShardsModel";

// get model for SurveyModel
export default class SurveyModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = { _shards: SurveyShardsModel };
  current_page_no = 0;
  hits = { hits: SurveyHitsModel };
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
