import BaseReducer from "../../../utilities/BaseReducer";
import AppStoreAddAccountAction from "./AppStoreAddAccountAction";

//APpstore Add account Reducer
export default class AppStoreAddAccountReducer extends BaseReducer {
  initialState = {
    appStoreAccount: [],
  };

  [AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      appStoreAccount: action.payload,
    };
  }
}
