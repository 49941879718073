import BaseReducer from "../../../utilities/BaseReducer";
import AiSettingsAction from "./AiSettingsAction";

// AiSettingsReuser
export default class AiSettingsReducer extends BaseReducer {
  initialState = {
    aiSettings: [],
  };
  [AiSettingsAction.REQUEST_AI_SETTINGS_FINISHED](state, action) {
    return {
      ...state,
      aiSettings: action.payload,
    };
  }
}
