import BaseReducer from "../../utilities/BaseReducer";
import ReportTemplateAction from "./ReportTemplateAction";

export default class ReportTemplateByIdReduser extends BaseReducer {
  //initial state of ticket
  initialState = {
    reportById: [],
  };

  // ticket filter
  [ReportTemplateAction.REQUEST_REPORTS_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      reportById: action.payload,
    };
  }
}
