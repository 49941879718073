import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import CampaignMappingDeleteModel from "./models/CampaignMappingDeleteModel";
import CampaignMappingModel from "./models/CampaignMappingModel";
import CampaignMappingPostModel from "./models/CampaignMappingPostModel";
import CampaignMappingPutModel from "./models/CampaignMappingPutModel";

export default class CampaignMappingEffect {
  // calling an API according to the Model
  static async requestCampaignMapping(params, campaigncallback) {
    const endpoint = environment.api.campaignMapping;

    let Response = EffectUtility.getToModel(
      CampaignMappingModel,
      endpoint,
      params
    );

    Response.then((resp) => {
      resp && campaigncallback && campaigncallback(resp);
    });

    return Response;
  }

  //  filter with page_limit none
  static async requestCampaignMappingFilter(params) {
    const endpoint = environment.api.campaignMappingFilter;

    return EffectUtility.getToModel(CampaignMappingModel, endpoint, params);
  }

  // Post API
  static async requestPostCampaignMapping(data) {
    const endpoint = environment.api.campaignMapping;
    return EffectUtility.postToModel(CampaignMappingPostModel, endpoint, data);
  }

  // Put API
  static async requestPutCampaignMapping(data, id) {
    const endpoint = environment.api.campaignMapping + "/" + id;

    return EffectUtility.putToModel(CampaignMappingPutModel, endpoint, data);
  }

  static async requestDeleteCampaignMapping(id) {
    const endpoint = environment.api.campaignMapping + "/" + id;

    return EffectUtility.deleteToModel(CampaignMappingDeleteModel, endpoint);
  }
}
