import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import MailTriggerListGetModel from "../triggerLIst/model/MailTriggerListGetModel";
import MailTriggerListPostModel from "../triggerLIst/model/MailTriggerListPostModel";
import MailTriggerListPutModel from "../triggerLIst/model/MailTriggerListPutModel";

// UserMailMappingEffect
export default class UserMailMappingEffect {
  // calling an API according to the Organization model
  // Get method for the
  static async requestUserMailMapping(params) {
    const endpoint = environment.api.userMailMapping;
    return EffectUtility.getToModel(MailTriggerListGetModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestUserMailMappingFilter(params) {
    const endpoint = environment.api.userMailMapping;
    return EffectUtility.getToModel(MailTriggerListGetModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutUserMailMapping(data, id) {
    const endpoint = environment.api.updateUserMailMapping + "/" + id;
    return EffectUtility.putToModel(MailTriggerListPostModel, endpoint, data);
  }

  // post method for the Organization
  static async requestPostUserMailMapping(data) {
    const endpoint = environment.api.userMailMapping + "/add";

    return EffectUtility.postToModel(MailTriggerListPutModel, endpoint, data);
  }
}
