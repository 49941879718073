import ActionUtility from "../../../../utilities/ActionUtility";
import InfluencerDeviceResetEffect from "./InfluencerDeviceMappingEffect";

export default class InfluencerDeviceMappingAction {
  // get device mapping requests
  static REQUEST_INFLUENCER_DEVICE_MAPPING =
    "InfluencerDeviceMappingAction.REQUEST_INFLUENCER_DEVICE_MAPPING";
  static REQUEST_INFLUENCER_DEVICE_MAPPING_FINISHED =
    "InfluencerDeviceMappingAction.REQUEST_INFLUENCER_DEVICE_MAPPING_FINISHED";

  // delete device mapping requests
  static REQUEST_INFLUENCER_DELETE_DEVICE_MAPPING =
    "InfluencerDeviceMappingAction.REQUEST_INFLUENCER_DELETE_DEVICE_MAPPING";
  static REQUEST_INFLUENCER_DELETE_DEVICE_MAPPING_FINISHED =
    "InfluencerDeviceMappingAction.REQUEST_INFLUENCER_DELETE_DEVICE_MAPPING_FINISHED";

  //METHODS

  //get method for device mapping
  static requestInfluencerDeviceMapping(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerDeviceMappingAction.REQUEST_INFLUENCER_DEVICE_MAPPING,
        InfluencerDeviceResetEffect.requestInfluencerDeviceMapping,
        params
      );
    };
  }

  //delete method for device reset
  static requestInfluencerDeviceMapDelete(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerDeviceMappingAction.REQUEST_INFLUENCER_DELETE_DEVICE_MAPPING,
        InfluencerDeviceResetEffect.requestInfluencerDeviceMapDelete,
        id
      );
    };
  }
}
