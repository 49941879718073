import { BaseModel } from "sjs-base-model";
import PlatformHitsDataModel from "./PlatformHitsDataModel";

export default class PlatformHitsModel extends BaseModel {
  //priority list hits model
  hits = [PlatformHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
