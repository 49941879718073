import ActionUtility from "../../../utilities/ActionUtility";
import GlassdoorAccountEffect from "./GlassdoorAccountEffect";

export default class GlassdoorAccountAction {
  // GlassdoorAccountAction action with an function declaration
  static REQUEST_GLASSDOOR_ACCOUNT =
    "GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT";
  static REQUEST_GLASSDOOR_ACCOUNT_FINISHED =
    "GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT_FINISHED";

  static REQUEST_GLASSDOOR_ACCOUNT_FILTER =
    "GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT_FILTER";
  static REQUEST_GLASSDOOR_ACCOUNT_FILTER_FINISHED =
    "GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_GLASSDOOR_ACCOUNT =
    "GlassdoorAccountAction.REQUEST_PUT_GLASSDOOR_ACCOUNT";
  static REQUEST_PUT_GLASSDOOR_ACCOUNT_FINISHED =
    "GlassdoorAccountAction.REQUEST_PUT_GLASSDOOR_ACCOUNT_FINISHED";

  static REQUEST_POST_GLASSDOOR_ACCOUNT =
    "GlassdoorAccountAction.REQUEST_POST_GLASSDOOR_ACCOUNT";
  static REQUEST_POST_GLASSDOOR_ACCOUNT_FINISHED =
    "GlassdoorAccountAction.REQUEST_POST_GLASSDOOR_ACCOUNT_FINISHED";

  static REQUEST_DELETE_GLASSDOOR_ACCOUNT =
    "GlassdoorAccountAction.REQUEST_DELETE_GLASSDOOR_ACCOUNT";
  static REQUEST_DELETE_GLASSDOOR_ACCOUNT_FINISHED =
    "GlassdoorAccountAction.REQUEST_DELETE_GLASSDOOR_ACCOUNT_FINISHED";

  // METHODS
  // Glassdoor Get function
  static requestGlassdoorAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT,
        GlassdoorAccountEffect.requestGlassdoorAccount,
        params
      );
    };
  }

  static requestGlassdoorAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT_FILTER,
        GlassdoorAccountEffect.requestGlassdoorAccountFilter,
        params
      );
    };
  }
  static requestPutGlassdoorAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorAccountAction.REQUEST_PUT_GLASSDOOR_ACCOUNT,
        GlassdoorAccountEffect.requestPutGlassdoorAccount,
        data,
        id
      );
    };
  }

  // Glassdoor post function
  static requestPostGlassdoorAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorAccountAction.REQUEST_POST_GLASSDOOR_ACCOUNT,
        GlassdoorAccountEffect.requestPostGlassdoorAccount,
        data
      );
    };
  }

  // Glassdoor delete function
  static requestDeleteGlassdoorAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GlassdoorAccountAction.REQUEST_DELETE_GLASSDOOR_ACCOUNT,
        GlassdoorAccountEffect.requestDeleteGlassdoorAccount,
        id
      );
    };
  }
}
