import { BaseModel } from "sjs-base-model";
export default class BrandSourceModel extends BaseModel {
  /*
   * brand-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  account = {
    account_id: "",
    account_name: "",
  };
  brand_competitor_account_name = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  enabled = "";
  last_sync_date = "";
  modified_by = {
    user_id: "",
    user_name: "",
  };
  modified_on = "";
  platform = "";
  project = {
    project_id: "",
    project_name: "",
  };

  constructor(data) {
    super();

    this.update(data);
  }
}
