import BaseReducer from "../../../../utilities/BaseReducer";
import UserResourceAction from "./UserResourceAction";

// UserResourceReducer
export default class UserResourceReducer extends BaseReducer {
  initialState = {
    userResource: [],
  };
  [UserResourceAction.REQUEST_USER_RESOURCE_FINISHED](state, action) {
    return {
      ...state,
      userResource: action.payload,
    };
  }
}
