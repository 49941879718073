import BaseReducer from "../../../../utilities/BaseReducer";
import LinkTransferSiteDataAction from "./LinkTransferSiteDataAction";

export default class LinkTransferSiteDataReduser extends BaseReducer {
  initialState = {
    LinkTransferSiteData: [],
  };
  [LinkTransferSiteDataAction.REQUEST_LINK_TRANSFER_SITE_DATA_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      LinkTransferSiteData: action.payload,
    };
  }

  //   [LinkCheckingAction.REQUEST_LINK_CHECKING_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       LinkTransferSiteData: action.payload,
  //     };
  //   }
}
