import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ShadowContainer } from "../../../components/componentLayout/ComponentLayout";

function ClientSpeedUsingNetworkInfo() {
  const [connectionType, setConnectionType] = useState(null);
  const [downlinkSpeed, setDownlinkSpeed] = useState(null);

  useEffect(() => {
    if (navigator.connection) {
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;

      setConnectionType(connection.effectiveType);
      setDownlinkSpeed(connection.downlink); // Speed in Mbps
    }
  }, []);

  return (
    <Box>
      <Typography
        component={"div"}
        sx={{ fontSize: 22, fontWeight: "bold", textAlign: "center" }}
      >
        System Speed
      </Typography>
      {connectionType && (
        <Box
          sx={{ padding: 1, display: "flex", justifyContent: "space-evenly" }}
        >
          <ShadowContainer>
            <div>Connection Type</div>
            <Typography>{connectionType}</Typography>
          </ShadowContainer>
          <ShadowContainer>
            <div>Downlink Speed</div>
            <Typography>{downlinkSpeed} Mbps</Typography>
          </ShadowContainer>
        </Box>
      )}
    </Box>
  );
}

export default ClientSpeedUsingNetworkInfo;
