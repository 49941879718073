import { Box, Grid, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Formik, Field, getIn } from "formik";
import { theme } from "../../../views/App";
import MuiButton from "../../../components/muiButton/MuiButton";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import AiSettingsAction from "../../stores/aiSettings/AiSettingsAction";
import { connect } from "react-redux";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [AiSettingsAction.REQUEST_AI_SETTINGS]),
  aiSettings: state.aiSettings.aiSettings || {},
});

function AiSettings(props) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAiSettings();
  }, []);

  function fetchAiSettings() {
    props.dispatch(AiSettingsAction.requestAiSettings());
  }

  const aiSettingsData = props?.aiSettings?._source;

  const initial = {
    model: "text-davinci-003",
    temperature: aiSettingsData?.temperature || 0,
    max_tokens: aiSettingsData?.max_tokens || 0,
    top_p: aiSettingsData?.top_p || 1,
    frequency_penalty: aiSettingsData?.frequency_penalty || 0,
    presence_penalty: aiSettingsData?.presence_penalty || 0,
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initial}
        validate={(values) => {}}
        onSubmit={(values) => {
          setLoading(true);
          props
            .dispatch(AiSettingsAction.requestPostAiSettings(values))
            .then(() => {
              setLoading(false);
              fetchAiSettings();
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          resetForm,
          values,
        }) => (
          <Form>
            <Grid
              container
              sm={10}
              md={8}
              lg={6}
              sx={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.background.paper,
                padding: 10,
                borderRadius: 5,
                boxShadow: theme.shadows,
              }}
            >
              <Grid
                container
                item
                sm={12}
                md={10}
                lg={8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid item sm={12} md={12} lg={12}>
                  <Typography
                    component={"h1"}
                    sx={{
                      fontSize: "28px",
                      fontWeight: "bold",
                    }}
                  >
                    AI Settings
                  </Typography>
                </Grid>
              </Grid>
              {props.isRequesting ? (
                <Box sx={{ padding: 20 }}>
                  <LoadingBgImage width={80} />
                </Box>
              ) : (
                <>
                  <Grid
                    container
                    item
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <Typography component={"div"}>Temperature</Typography>
                      <Typography component={"div"}>
                        {values.temperature}
                      </Typography>
                    </Grid>

                    <Grid item sm={12} md={12} lg={12}>
                      <Slider
                        value={values.temperature}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        max={2}
                        step={0.01}
                        onChange={(e, v) => setFieldValue("temperature", v)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <Typography component={"div"}>Maximum Length</Typography>
                      <Typography component={"div"}>
                        {values.max_tokens}
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <Slider
                        value={values.max_tokens}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        max={4096}
                        onChange={(e, v) => setFieldValue("max_tokens", v)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <Typography component={"div"}>Top P</Typography>
                      <Typography component={"div"}>{values.top_p}</Typography>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <Slider
                        value={values.top_p}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        max={1}
                        step={0.01}
                        onChange={(e, v) => setFieldValue("top_p", v)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <Typography component={"div"}>
                        Frequency penalty
                      </Typography>
                      <Typography component={"div"}>
                        {values.frequency_penalty}
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <Slider
                        value={values.frequency_penalty}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        max={2}
                        step={0.01}
                        onChange={(e, v) =>
                          setFieldValue("frequency_penalty", v)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <Typography component={"div"}>
                        Presence penalty
                      </Typography>
                      <Typography component={"div"}>
                        {values.presence_penalty}
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <Slider
                        value={values.presence_penalty}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        max={2}
                        step={0.01}
                        onChange={(e, v) =>
                          setFieldValue("presence_penalty", v)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 5,
                    }}
                    spacing={1}
                  >
                    <Grid item sm={5} md={5} lg={5}>
                      <MuiButton
                        name={"Save"}
                        type={"submit"}
                        width={"100%"}
                        loading={loading}
                      />
                    </Grid>

                    <Grid item sm={5} md={5} lg={5}>
                      <MuiButton
                        name={"Reset"}
                        width={"100%"}
                        onClick={() => resetForm()}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default connect(mapStateToProps)(AiSettings);
