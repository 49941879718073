import environment from "environment";
import PlatformAccountsModel from "./models/PlatformAccountsModel";
import EffectUtility from "../../../utilities/EffectUtility";

//Platform Accounts
export default class PlatformAccountsEffect {
  static async requestPlatformAccount(params) {
    const endpoint = environment.api.platformAccount;
    return EffectUtility.getToModel(PlatformAccountsModel, endpoint, params);
  }
  static async requestTelegramPlatformAccount(params) {
    const endpoint = environment.api.telegramPlatformAccount;
    return EffectUtility.getToModel(PlatformAccountsModel, endpoint, params);
  }

  static async requestPlatformAccountFilter(params) {
    const endpoint = environment.api.platformAccountFilter;
    return EffectUtility.getToModel(PlatformAccountsModel, endpoint, params);
  }
}
