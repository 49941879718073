import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import GenreBulkPutModel from "./model/GenreBulkPutModel";
import GenreModel from "./model/GenreModel";
import GenrePostModel from "./model/GenrePostModel";
import GenrePutModel from "./model/GenrePutModel";

// StatusEffect
export default class GenreEffect {
  // calling an API according to the status model
  // Get method for the
  static async requestGenre(params) {
    const endpoint = environment.api.genre;
    return EffectUtility.getToModel(GenreModel, endpoint, params);
  }

  static async requestGenreClear() {
    return {};
  }

  static async requestGenreFilterClear() {
    return {};
  }

  // Get method for the Genre filter
  static async requestGenreFilter(params) {
    const endpoint = environment.api.genre;
    return EffectUtility.getToModel(GenreModel, endpoint, params);
  }

  // put method for the Genre
  static async requestPutGenre(data, id) {
    const endpoint = environment.api.genre + "/" + id;
    return EffectUtility.putToModel(GenrePutModel, endpoint, data);
  }

  // post method for the Genre
  static async requestPostGenre(data) {
    const endpoint = environment.api.genre + "/add";
    return EffectUtility.postToModel(GenrePostModel, endpoint, data);
  }

  // post method for the Genre
  static async requestPutGenreBulkUpdate(data) {
    const endpoint = environment.api.genreUpdate;
    return EffectUtility.putToModel(GenreBulkPutModel, endpoint, data);
  }
}
