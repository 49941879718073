import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";
export default class ProjectDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = "";
  modified_on = "";
  project_id = 0;
  project_name = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
