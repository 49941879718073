import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import { format } from "date-fns";
// import "./AppStoreStyles.css";
import { connect } from "react-redux";
// Pagination component
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
// import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@mui/icons-material/Star";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import environment from "environment";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import DiscordFeedsAction from "../../../stores/discord/DiscordFeedsAction";
import DiscordAccountAction from "../../../stores/discordAccount/DiscordAccountAction";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const useStyles = makeStyles({
//   avatar: {
//     width: 45,
//     height: 45,
//   },
// });

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    DiscordAccountAction.REQUEST_DISCORD_ACCOUNT,
    DiscordFeedsAction.REQUEST_DISCORD_FEEDS,
    DiscordFeedsAction.REQUEST_DISCORD_FILTER,
  ]),
  discordAccount: state.discordAccount.discordAccount || [],
  discordFeeds: state.discordFeeds.discordFeeds.hits || [],
  pagination: state.discordFeeds.discordFeeds || {},
  discordFilter: state.discordFilter.discordFilter.hits || [],
});

function DiscordFeeds(props) {
  const { isRequesting } = props;

  //****************************************** setState
  const [page_limit] = useState(10);
  const [customerName, setCustomerName] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [date, setDate] = useState([null, null]);
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // ************************************************* Dispatch functions

  function fetchDiscord(params) {
    props.dispatch(DiscordFeedsAction.requestDiscordFeeds(params));
  }

  function fetchDiscordFilter(param) {
    props.dispatch(DiscordFeedsAction.requestDiscordFilter(param));
  }

  function fetchDiscordAccount() {
    let param = {
      page_limit: "none",
    };
    props.dispatch(DiscordAccountAction.requestDiscordAccount(param));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  // //*************************************************  UseEffect function
  useEffect(() => {
    let param_filter = [];

    param_filter.push(`["flag","must","","match","received"]`);

    const param = {
      page_limit: "none",
      filters: `[${param_filter}]`,
    };

    fetchDiscordFilter(param);
    fetchDiscordFilter(param);

    fetchDiscordAccount();

    const filter = [];
    let params = {};

    filter.push(`["flag","must","","match","received"]`);

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_number","must","","match","${accountName.account_id}"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      accountName !== null ||
      customerName !== null ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        filters: `[${param_filter}]`,
      };
    }

    fetchDiscord(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // ****************************************************** applyFilter Function

  const applyFilter = () => {
    let params = {};
    const filter = [];
    filter.push(`["flag","must","","match","received"]`);

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_number","must","","match","${accountName.account_id}"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    // if (sentimental_val !== null) {
    //   if (sentimental_val === "Negative") {
    //     filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
    //   } else if (sentimental_val === "Positive") {
    //     filter.push(`["sentiment_value","must","gte","range","0.01"]`);
    //   } else if (sentimental_val === "Neutral") {
    //     filter.push(`["sentiment_value","must","","match","0"]`);
    //   }
    // }
    let param_filter = [];

    param_filter.push(`["flag","must","","match","received"]`);

    if (
      customerName !== null ||
      accountName !== null ||
      (date[0] !== null && date[1] !== null)
      //     ||
      //   sentimental_val !== null
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        // page_number: 0,
        page_limit: `${page_limit}`,
        filters: `[${param_filter}]`,
      };
      alert("Please add filters");
    }
    fetchDiscord(params);
  };

  // // ************************************************************ HandleChange
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];
    filter.push(`["flag","must","","match","received"]`);

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_number","must","","match","${accountName.account_id}"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    // if (sentimental_val !== null) {
    //   if (sentimental_val === "Negative") {
    //     filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
    //   } else if (sentimental_val === "Positive") {
    //     filter.push(`["sentiment_value","must","gte","range","0.01"]`);
    //   } else if (sentimental_val === "Neutral") {
    //     filter.push(`["sentiment_value","must","","match","0"]`);
    //   }
    // }
    let param_filter = [];

    param_filter.push(`["flag","must","","match","received"]`);

    if (FilterClicked) {
      if (
        customerName !== null ||
        accountName !== null ||
        (date[0] !== null && date[1] !== null)
        //   ||
        // sentimental_val !== null
      ) {
        params = {
          filters: `[${filter}]`,
          order_by: `[["created_on","desc"]]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        filters: `[${param_filter}]`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchDiscord(params);
    }
  };

  // // ************************************** CustomerHandleChange
  const customerNameHandleChange = (event, value) => {
    if (value != null) {
      setCustomerName(value);
    } else {
      setCustomerName(null);
    }
  };

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName(null);
    }
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val(null);
    }
  };

  // *************************************Customer Filter List
  let filterlist = [];

  if (props.discordFilter.hits) {
    props.discordFilter.hits.forEach((value) => {
      filterlist.push(value._source.customer_name);
    });
  }

  const uniqueList = [...new Set(filterlist)];

  let accountNameList = [];

  if (props.discordAccount.hits) {
    props.discordAccount.hits.hits &&
      props.discordAccount.hits.hits.length !== 0 &&
      props.discordAccount.hits.hits.forEach((value) => {
        accountNameList.push({
          account_name: value._source.channel_name,
          account_id: value._id,
        });
      });
  }

  accountNameList = [...new Set(accountNameList)];

  //*******************************  clearState Functions ******************
  const ClearState = () => {
    setFilterClicked(false);
    if (
      customerName !== null ||
      accountName !== null ||
      (date[0] !== null && date[1] !== null)
      //     ||
      //   sentimental_val !== null
    ) {
      setCustomerName(null);
      //   setSentimental_val(null);
      setDate([null, null]);
      setClearLoader(true);
      setAccountName(null);

      let param_filter = [];

      param_filter.push(`["flag","must","","match","received"]`);

      const params = {
        order_by: `[["created_on","desc"]]`,
        // page_number: 0,
        page_limit: `${page_limit}`,
        filters: `[${param_filter}]`,
      };
      FilterClicked && fetchDiscord(params);
    } else {
    }
  };

  let maxDate = new Date();

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number"
    ) {
      if (item._source.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item._source.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item._source.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }

    return sentiment;
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}
      <Grid container>
        {/*Playstore filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
            // className="bg-warning"
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              alignItems={"flex-start"}
              spacing={1}
              // className="bg-success"
            >
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Account Name</p>
                </Grid>
                <AutoCompleteComponent
                  value={accountName}
                  handleChange={accountNameHandleChange}
                  list={accountNameList}
                  textFieldProps={{
                    placeholder: "Account Name",
                  }}
                  getOptionLabel={(option) =>
                    option.account_name ? option.account_name : ""
                  }
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Customer Name</p>
                </Grid>
                <AutoCompleteComponent
                  value={customerName}
                  handleChange={customerNameHandleChange}
                  list={uniqueList}
                  textFieldProps={{
                    placeholder: "Customer Name",
                  }}
                  getOptionLabel={(option) => (option ? option : "")}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>

              {/* <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentimental Value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid> */}

              <Grid item xl={6} lg={3} md={12} sm={12} mt={0}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={3}
                >
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <h5 className="pl-3 pr-3 pt-2"></h5> */}

        {/* <Divider sx={{ borderColor: theme.palette.background.button }} /> */}
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "8px", marginBottom: "8px" }} />
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          ></Grid>
          <LoadingIndicator isActive={isRequesting}>
            {props.discordFeeds.hits ? (
              <Box>
                {props.discordFeeds.hits.length !== 0 ? (
                  props.discordFeeds.hits.map((val, key) => (
                    <Box
                      sx={{
                        padding: 0,
                        margin: "1.2%",
                      }}
                    >
                      <List
                        key={key}
                        sx={{
                          backgroundColor: theme.palette.background.paper,

                          border: "solid 0.5px #d3e2e6",
                          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",

                          // borderRadius:
                          //   theme.palette.buttonComponent.borderRadius.large,
                          borderRadius: theme.borderRadius,
                          display: "flex",
                          flexDirection: "row",
                        }}
                        component="nav"
                        variant="fullWidth"
                      >
                        <List>
                          <ListItem className="p-0 m-0 ml-3">
                            <h6 className="p-0 m-0 mr-3">
                              {val._source.customer_name}{" "}
                            </h6>
                            <p className="p-0 m-0" style={{ fontSize: "12px" }}>
                              {" "}
                              {(function createdDate() {
                                let created = val._source.message_recieved_on;
                                let dateConversion = new Date(created);
                                dateConversion =
                                  dateConversion.toDateString() +
                                  ", " +
                                  dateConversion.toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  });

                                return dateConversion;
                              })()}{" "}
                            </p>
                          </ListItem>

                          {/* {val._source.media_type ? (
                            val._source.media_type === "Photo" ? (
                              <Box px={2} py={1}>
                                <RenderMedia
                                  image_url={val._source.media_url}
                                  mediaType="img"
                                  height="100"
                                />
                              </Box>
                            ) : val._source.media_type === "Document" ? (
                              <Box px={2}>
                                <IconButton
                                  onClick={() => {
                                    // window.location.href = item._source.media_url;
                                    const a = document.createElement("a");
                                    a.href = val._source.media_url;
                                    a.target = "_blank";
                                    a.download = true;
                                    a.click();
                                  }}
                                >
                                  Document : <FileDownloadIcon />
                                </IconButton>
                              </Box>
                            ) : val._source.media_type === "Contact" ? (
                              <Box px={2}>
                                <IconButton
                                  onClick={() => {
                                    // window.location.href = item._source.media_url;
                                    const a = document.createElement("a");
                                    a.href = val._source.media_url;
                                    a.target = "_blank";
                                    a.download = true;
                                    a.click();
                                  }}
                                >
                                  Contact : <FileDownloadIcon />
                                </IconButton>
                              </Box>
                            ) : val._source.media_type === "Geo" ? (
                              <Box px={2}></Box>
                            ) : null
                          ) : null} */}

                          {val._source.message_content &&
                            val._source.message_content.length !== 0 && (
                              <ListItem className="p-0 m-0 ml-3">
                                <ListItemText>
                                  <h5
                                    className="p-0 m-0"
                                    style={{ fontWeight: "400" }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "550",
                                        fontSize: 16,
                                      }}
                                    >
                                      Message content:
                                    </span>{" "}
                                    &nbsp;
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        fontSize: 15.5,
                                      }}
                                    >
                                      {val._source.message_content}
                                    </span>
                                  </h5>
                                </ListItemText>
                              </ListItem>
                            )}

                          <ListItem className="p-0 m-0 ml-3">
                            <ListItemText>
                              <h5
                                className="p-0 m-0"
                                style={{ fontWeight: "400" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "550",
                                    fontSize: 16,
                                  }}
                                >
                                  Account name:
                                </span>{" "}
                                &nbsp;
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: 15.5,
                                  }}
                                >
                                  {val._source.account_name}
                                </span>
                              </h5>
                            </ListItemText>
                          </ListItem>

                          {val._source.sentiment_value &&
                          val._source.sentiment_value !== null ? (
                            <ListItem className="p-0 m-0 ml-3">
                              <ListItemText>
                                <b
                                  style={{
                                    fontSize:
                                      theme.palette.buttonComponent.fontSize
                                        .extraLarge,
                                    // lineHeight: "22px",
                                  }}
                                >
                                  Sentiment value :
                                </b>
                                &nbsp;
                                {val._source.sentiment_value &&
                                typeof val._source.sentiment_value ===
                                  "number" &&
                                val._source.sentiment_value > 0 ? (
                                  <IconButton
                                    className="p-0 m-0"
                                    title={returnSentimentname(val)}
                                  >
                                    <SentimentSatisfiedAltRoundedIcon
                                      titleAccess="Positive"
                                      style={{
                                        width: "16px",
                                        color: `green`,
                                      }}
                                    />
                                  </IconButton>
                                ) : val._source.sentiment_value < 0 ? (
                                  <IconButton
                                    className="p-0 m-0"
                                    title={returnSentimentname(val)}
                                  >
                                    <SentimentDissatisfiedRoundedIcon
                                      titleAccess="Negative"
                                      style={{
                                        width: "16px",
                                        color: `${theme.palette.sentimentIconColor.negative}`,
                                      }}
                                    />
                                  </IconButton>
                                ) : val._source.sentiment_value !== null ? (
                                  <IconButton
                                    className="p-0 m-0"
                                    title={returnSentimentname(val)}
                                  >
                                    <SentimentNeutralRoundedIcon
                                      titleAccess="Neutral"
                                      style={{
                                        width: "16px",
                                        color: `${theme.palette.sentimentIconColor.neutral}`,
                                      }}
                                    />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </ListItemText>
                            </ListItem>
                          ) : null}
                        </List>
                      </List>
                    </Box>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </Box>
            ) : null}
          </LoadingIndicator>
        </Box>
      </Grid>
      {props.discordFeeds.hits
        ? props.discordFeeds.hits.length !== 0 && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(DiscordFeeds);
