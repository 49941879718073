import environment from "environment";
import RolesPostDataModel from "./models/RolesPostDataModel";
import RolesPutDataModel from "./models/RolesPutDataModel";
import RoleSModel from "./models/RolesModel";
import EffectUtility from "../../../utilities/EffectUtility";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import ManageRoleModel from "../../components/manageRole/models/ManageRoleModel";

export default class RolesEffect {
  // get roles request endpoint
  static async requestRole(params) {
    const endpoint = environment.api.roles;
    return EffectUtility.getToModel(ManageRoleModel, endpoint, params);
  }
  //get roles post api
  static async requestPostRole(data) {
    const endpoint = environment.api.roles;
    return EffectUtility.postToModel(RolesPostDataModel, endpoint, data);
  }

  //get roles put api
  static async requestPutRole(data, id) {
    const endpoint = environment.api.roles + "/" + id;

    return EffectUtility.putToModel(RolesPutDataModel, endpoint, data);
  }
  // Roles filter with page_limit none
  static async requestRoleFilter(params) {
    const endpoint = environment.api.rolesfilter;
    return EffectUtility.getToModel(RoleSModel, endpoint, params);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
