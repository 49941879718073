import ActionUtility from "../../../utilities/ActionUtility";
import TurnAroundTimeEffect from "./TurnAroundTimeEffect";

export default class TurnAroundTimeAction {
  //Ticket TAT requests
  static REQUEST_TURNAROUNDTIME = "TurnAroundTimeAction.REQUEST_TURNAROUNDTIME";
  static REQUEST_TURNAROUNDTIME_FINISHED =
    "TurnAroundTimeAction.REQUEST_TURNAROUNDTIME_FINISHED";

  //METHODS

  //Ticket TAT get method
  static requestTurnAroundTime(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TurnAroundTimeAction.REQUEST_TURNAROUNDTIME,
        TurnAroundTimeEffect.requestTurnAroundTime,
        params
      );
    };
  }
}
