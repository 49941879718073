import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import RevenueClaimRequestGetModel from "./models/RevenueClaimRequestGetModel";
import RevenueClaimRequestByIdModel from "./models/RevenueClaimRequestByIdModel";
import RevenueClaimRequestPutModel from "./models/RevenueClaimRequestPutModel";
import InvoiceClaimDetailsModel from "./models/InvoiceClaimDetailsModel";

export default class ClaimRequestEffect {
  static async requestRevenueClaimRequest(params) {
    const endpoint = environment.api.revenueClaimRequest;

    return EffectUtility.getToModel(
      RevenueClaimRequestGetModel,
      endpoint,
      params
    );
  }

  static async requestRevenueClaimRequestById(id, params, callBack) {
    const endpoint = environment.api.revenueClaimRequest + "/" + id;
    let response = EffectUtility.getToModel(
      RevenueClaimRequestByIdModel,
      endpoint
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestRevenueClaimRequestByIdClear() {
    return {};
  }

  static async requestRevenueClaimRequestPut(data, id, params, callback) {
    const endpoint = environment.api.updateRevenueClaimRequest + "/" + id;
    let response = EffectUtility.putToModel(
      RevenueClaimRequestPutModel,
      endpoint,
      data,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  static async requestInvoiceClaimDetails(clientId) {
    const endpoint = environment.api.getInvoiceClaimDetails + "/" + clientId;

    return EffectUtility.getToModel(InvoiceClaimDetailsModel, endpoint);
  }
}
