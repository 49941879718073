import BaseReducer from "../../../utilities/BaseReducer";
import TwitterAction from "./TwitterAction";

// twitter reducer function
export default class TwitterReducer extends BaseReducer {
  initialState = {
    twitter: [],
  };

  [TwitterAction.REQUEST_TWITTER_FINISHED](state, action) {
    return {
      ...state,
      twitter: action.payload,
    };
  }

  [TwitterAction.REQUEST_TWITTER_FEEDS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      twitter: action.payload,
    };
  }
}
