import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TerritoryModel from "./model/TerritoryModel";
import TerritoryPostModel from "./model/TerritoryPostModel";
import TerritoryPutModel from "./model/TerritoryPutModel";

// TerritoryEffect
export default class TerritoryEffect {
  // calling an API according to the Territory model
  static async requesTerritory(params) {
    const endpoint = environment.api.territory;
    return EffectUtility.getToModel(TerritoryModel, endpoint, params);
  }

  static async requesTerritoryClear() {
    return {};
  }

  static async requesTerritoryFilterClear() {
    return {};
  }

  // Get method for the Territory filter
  static async requestTerritoryFilter(params) {
    const endpoint = environment.api.territory;
    return EffectUtility.getToModel(TerritoryModel, endpoint, params);
  }

  // put method for the Territory
  static async requestPutTerritory(data, id) {
    const endpoint = environment.api.territory + "/" + id;
    return EffectUtility.putToModel(TerritoryPutModel, endpoint, data);
  }

  // post method for the Territory
  static async requestPostTerritory(data) {
    const endpoint = environment.api.territory + "/add";
    return EffectUtility.postToModel(TerritoryPostModel, endpoint, data);
  }
  static async requestDeleteTerritory(id) {
    const endpoint = environment.api.territory + "/" + id;
    return EffectUtility.deleteToModel(TerritoryPostModel, endpoint);
  }
}
