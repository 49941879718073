import { BaseModel } from "sjs-base-model";

// put model
export default class EmailFeedsPostModel extends BaseModel {
  status = "";
  message = "";
  data = {};
  id = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
