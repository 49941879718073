import BaseReducer from "../../../../utilities/BaseReducer";
import PropertywiseUrlsAction from "./PropertywiseUrlsAction";

export default class PropertywiseUrlsReducer extends BaseReducer {
  initialState = {
    propertywise_content_urls_history: [],
  };
  [PropertywiseUrlsAction.REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_HISTORY_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      propertywise_content_urls_history: action.payload,
    };
  }
}
