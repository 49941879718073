import { BaseModel } from "sjs-base-model";

//twitterAccount delete Model
export default class KeywordConfigDeleteModel extends BaseModel {
  _id = "";
  _index = "";
  _primary_term = "";
  _seq_no = "";
  _shards = {};
  _version = "";
  result = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
