import BaseReducer from "../../utilities/BaseReducer";
import ReportTemplateAction from "./ReportTemplateAction";

export default class ReportTemplateReducer extends BaseReducer {
  initialState = {
    reportTemplate: [],
  };

  [ReportTemplateAction.REQUEST_REPORT_TEMPLATE_FINISHED](state, action) {
    return {
      ...state,
      reportTemplate: action.payload,
    };
  }
}
