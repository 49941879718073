import { BaseModel } from "sjs-base-model";
import InstagramAccountDataHitsModel from "./InstagramAccountDataHitsModel";

//InstagramAccountHitsModel
export default class InstagramAccountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ InstagramAccountDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
