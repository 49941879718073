import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import FPTotalAssetCountGetModel from "./models/FPTotalAssetCountGetModel";
import FPTotalCollectionCountGetModel from "./models/FPTotalCollectionCountGetModel";
import FPTotalCopiesCountGetModel from "./models/FPTotalCopiesCountGetModel";
import FPAssetwiseCopiesCountGetModel from "./models/FPAssetwiseCopiesGetModel";
import FPViewsPerAssetGetModel from "./models/FPViewsPerAssetGetModel";
import FPActiveAssetsGetModel from "./models/FPActiveAssetsGetModel";

export default class FingerPrintReportsEffect {
  static async requestGetTotalAssetCount(params, callback) {
    const endpoint = environment.api.FPtotalAssetCount;
    let response = EffectUtility.getToModel(
      FPTotalAssetCountGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetTotalCollectionCount(params, callback) {
    const endpoint = environment.api.FPtotalCollectionsCount;
    let response = EffectUtility.getToModel(
      FPTotalCollectionCountGetModel,
      endpoint,
      params
    );
    return response;
  }
  static async requestGetTotalCopiesCount(params, callback) {
    const endpoint = environment.api.FPtotalCopiesCount;
    let response = EffectUtility.getToModel(
      FPTotalCopiesCountGetModel,
      endpoint,
      params
    );
    return response;
  }
  static async requestGetTotalCopiesAssetwiseCount(params, callback) {
    const endpoint = environment.api.FPassetWiseCopies;
    let response = EffectUtility.getToModel(
      FPAssetwiseCopiesCountGetModel,
      endpoint,
      params
    );
    return response;
  }
  static async requestGetViewsPerAssetCount(params, callback) {
    const endpoint = environment.api.FPviewsPerAsset;
    let response = EffectUtility.getToModel(
      FPViewsPerAssetGetModel,
      endpoint,
      params
    );
    return response;
  }
  static async requestGetActiveAssetCount(params, callback) {
    const endpoint = environment.api.FPactiveAssets;
    let response = EffectUtility.getToModel(
      FPActiveAssetsGetModel,
      endpoint,
      params
    );
    return response;
  }
}
