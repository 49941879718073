import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// GlassDoorFeedsDataModel
export default class AmbitionBoxFeedsDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  account_name = "";
  activities = "";
  cons = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  customer_name = "";
  designation = "";
  feed_id = "";
  modified_on = "";
  platform = "";
  posted_on = "";
  project = {
    project_id: "",
    project_name: "",
  };
  pros = "";
  rating = "";
  sentiment_value = "";
  title = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
