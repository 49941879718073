import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";
export default class AppStoreDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  created_by = "";
  created_on = "";
  created_time = "";
  customer_name = "";
  modified_on = "";
  parent_review = "";
  platform = "";
  rating = "";
  review = "";
  sentimental_value = "";
  title = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
