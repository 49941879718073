import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import RevenueReportGetModel from "./models/RevenueReportGetModel";
import RevenueReportPutModel from "./models/RevenueReportPutModel";
import RevenueReportPostModel from "./models/RevenueReportPostModel";

export default class RevenueReportEffect {
  static async requestRevenueReport(params) {
    const endpoint = environment.api.revenueReport;

    return EffectUtility.getToModel(RevenueReportGetModel, endpoint, params);
  }

  static async requestRevenueReportClear() {
    return {};
  }

  static async requesRevenueReportFilterClear() {
    return {};
  }

  static async requestRevenueReportExport(params, callBackfunction) {
    const endpoint = environment.api.revenueReport;

    let response = EffectUtility.getToModel(
      RevenueReportGetModel,
      endpoint,
      params
    );

    if (response) {
      response.then((resp) => {
        callBackfunction(resp);
      });
    }
    return response;
  }

  static async requestRevenueReportFilter(params) {
    const endpoint = environment.api.revenueReport;
    return EffectUtility.getToModel(RevenueReportGetModel, endpoint, params);
  }

  static async requestPutRevenueReport(data, id) {
    const endpoint = environment.api.revenueReport + "/" + id;
    return EffectUtility.putToModel(RevenueReportPutModel, endpoint, data);
  }

  static async requestPostRevenueReport(data, callBack) {
    const endpoint = environment.api.revenueReportFileUploader;
    let response = EffectUtility.postToModel(
      RevenueReportPostModel,
      endpoint,
      data
    );

    if (response && callBack) {
      response.then((resp) => {
        callBack(resp);
      });
    }

    return response;
  }
}
