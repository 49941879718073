import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import ResponseMessageModel from "./models/ResponseMessageModel";
import ResponseMessagePostModel from "./models/ResponseMessagePostModel";
import ResponseMessagePutModel from "./models/ResponseMessagePutModel";

export default class ResponseMessageEffect {
  // calling an API according to the categoryModel
  static async requestResponseMessage(params) {
    const endpoint = environment.api.responseMessage;

    return EffectUtility.getToModel(ResponseMessageModel, endpoint, params);
  }

  // category filter with page_limit none
  static async requestResponseMessageFilter(params) {
    const endpoint = environment.api.responseMessageFilter;

    return EffectUtility.getToModel(ResponseMessageModel, endpoint, params);
  }

  // Post API
  static async requestPostResponseMessage(data) {
    const endpoint = environment.api.responseMessage + "/add";

    return EffectUtility.postToModel(ResponseMessagePostModel, endpoint, data);
  }

  // Put API
  static async requestPutResponseMessage(data, id) {
    const endpoint = environment.api.responseMessage + "/" + id;

    return EffectUtility.putToModel(ResponseMessagePutModel, endpoint, data);
  }
}
