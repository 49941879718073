import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Form, Formik, Field, ErrorMessage, FastField } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import AddAccountAction from "../../../stores/addAccount/AddAccountAction";
import InstagramAccountAction from "../../../stores/InstagramAccount/InstagramAccountAction";
import FaceBookAccountAction from "../../../stores/faceBookAccount/FaceBookAccountAction";
import YoutubeAccountAction from "../../../stores/youtubeAccount/YoutubeAccountAction";
import YoutubeAction from "../../../stores/youtube/YoutubeAction";
import FacebookAction from "../../../stores/facebook/FacebookAction";
import TwitterAction from "../../../stores/twitterFeeds/TwitterAction";
import InstagramFeedsAction from "../../../stores/instagramFeeds/InstagramFeedsAction";
import MuiTextField from "../../../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../../components/muiButton/MuiButton";
import { theme } from "../../../../views/App.jsx";
import DateTimePickerComponent from "../../../../components/dateTimePicker/DateTimePicker.js";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { returnPlatformDropdownLogos } from "../../../../components/ticketPartials.js";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { format } from "date-fns";
import PlatformSchedulerAction from "../../../stores/platformScheduler/PlatformSchedulerAction.js";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AddAccountAction.REQUEST_ADD_ACCOUNT_FILTER,
    InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT_FILTER,
    FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT,
    YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FILTER,
  ]),
  addAccountFilter: state.addAccountFilter.addAccountFilter.hits || [],
  instagramAccountFilter:
    state.instagramAccountFilter.instagramAccountFilter.hits || [],
  facebookAccount: state.facebookAccount.facebookAccount.hits || [],
  youtubeAccountFilter:
    state.youtubeAccountFilter.youtubeAccountFilter.hits || [],
});

function PlatformPostDialog(props) {
  let initial = {
    project_id: "",
    task_id: "orm_schedule_post",
    start_time: "",
    end_time: "",
    group: "",
    title: "",
    caption: "",
    platforms: [],
    media: null,
    accounts: [],
    privacy_status: "",
  };

  const platforms_list = ["Youtube", "Facebook", "Twitter", "Instagram"];
  let privacy_status_list = ["public", "private", "unlisted"];

  const [loading, setLoading] = useState(false);

  const handleDelete = (setFieldValue, name) => {
    document.querySelector("input[type='file']").value = "";
    setFieldValue(name, "");
  };

  useEffect(() => {
    fetchTwitterAccount();
    fetchInstagramAccount();
    fetchFacebookAccount();
    fetchYoutubeAccount();
  }, []);

  function fetchTwitterAccount() {
    let param = {
      page_limit: "none",
    };
    props.dispatch(AddAccountAction.requestAddAccountFilter(param));
  }

  function fetchInstagramAccount() {
    let param = {
      page_limit: "none",
    };
    props.dispatch(InstagramAccountAction.requestInstagramAccountFilter(param));
  }

  function fetchFacebookAccount() {
    let param = {
      page_limit: "none",
    };
    props.dispatch(FaceBookAccountAction.requestFaceBookAccount(param));
  }

  function fetchYoutubeAccount() {
    let param = {
      page_limit: "none",
    };

    props.dispatch(YoutubeAccountAction.requestYoutubeAccountFilter(param));
  }

  let accountList = [];

  if (props.addAccountFilter.hits) {
    props.addAccountFilter.hits.map((value) => {
      accountList.push({
        account_id: value._id,
        account_name: value._source.account_name,
        platform: value._source.platform,
      });
    });
  }

  if (props.instagramAccountFilter.hits) {
    props.instagramAccountFilter.hits.map((value) => {
      accountList.push({
        account_id: value._id,
        account_name: value._source.account_name,
        platform: value._source.platform,
      });
    });
  }

  if (props.facebookAccount.hits) {
    props.facebookAccount.hits.map((value) => {
      accountList.push({
        account_id: value._id,
        account_name: value._source.account_name,
        platform: value._source.platform,
      });
    });
  }

  if (props.youtubeAccountFilter.hits) {
    !!props.youtubeAccountFilter.hits.length &&
      props.youtubeAccountFilter.hits.map((value) => {
        accountList.push({
          account_id: value._id,
          account_name: value._source.account_name,
          platform: value._source.platform,
        });
      });
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        validateOnBlur={true}
        validate={(values) => {
          var errors = {};

          if (!values.start_time) {
            errors.start_time = "Start time Required";
          }

          if (!values.end_time) {
            errors.end_time = "End time Required";
          }

          if (!values.group) {
            errors.group = "Group Required";
          }

          if (!values.caption) {
            errors.caption = "Caption Required";
          }

          if (!values.accounts.length) {
            errors.accounts = "Select atleast one Account";
          }

          if (!values.media) {
            errors.media = "Media is required";
          }

          return errors;
        }}
        validateOnChange
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let userLastWorkingProject = JSON.parse(
            localStorage.getItem("user_last_working_project")
          )?.project_id;

          let platformObj = {};
          let accountObj = {};

          values.accounts.map((account) =>
            Object.assign(platformObj, {
              [account.platform]: { account_id: account.account_id },
            })
          );

          values.accounts.map((account) =>
            Object.assign(accountObj, {
              account_id: account.account_id,
            })
          );

          let formData = new FormData();

          formData.append("project_id", userLastWorkingProject);
          formData.append("task_id", values.task_id);
          formData.append(
            "start_time",
            format(new Date(values.start_time), "yyyy-MM-dd HH:mm:ss")
          );
          formData.append(
            "end_time",
            format(new Date(values.end_time), "yyyy-MM-dd HH:mm:ss")
          );
          formData.append("group", values.group);
          formData.append("caption", values.caption);
          formData.append("media", values.media);
          formData.append("platforms", JSON.stringify(platformObj));

          if (values.platforms.includes("Youtube")) {
            formData.append("title", values.title);
            formData.append("account_id", accountObj?.account_id);
            formData.append("privacy_status", values.privacy_status);
          }

          props
            .dispatch(
              PlatformSchedulerAction.requestPlatformPostSchedule(formData)
            )
            .then(() => {
              props.onClose();
              setLoading(false);
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          resetForm,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "80%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P
                          </span>
                          ost &nbsp; &nbsp;
                          <span className="span_first_letter">D</span>ata
                        </b>
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Box>
                {platforms_list.map((platform) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.platforms.includes(platform)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            // Add platform to the array if checked
                            setFieldValue("platforms", [
                              ...values.platforms,
                              platform,
                            ]);
                          } else {
                            // Remove platform from the array if unchecked
                            setFieldValue(
                              "platforms",
                              values.platforms.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={platform}
                    key={platform} // adding a key for better rendering
                  />
                ))}
              </Box>
              {console.log("VAlues", { values, errors })}

              <Grid container rowSpacing={2}>
                <Grid item ml={2} lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Start time * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <DateTimePickerComponent
                      minDate={dayjs().startOf("day")}
                      views={["year", "day", "hours", "minutes"]}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={values.start_time ? values.start_time : null}
                      onChange={(value) => {
                        setFieldValue("start_time", value);
                      }}
                      format={"DD/MM/YYYY hh:mm a"}
                      error={Boolean(errors.start_time) && touched.start_time}
                      helperText={
                        Boolean(touched.start_time) && errors.start_time
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item ml={2} lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    End time * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <DateTimePickerComponent
                      minDate={dayjs().startOf("day")}
                      views={["year", "day", "hours", "minutes"]}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={values.end_time ? values.end_time : null}
                      onChange={(value) => {
                        setFieldValue("end_time", value);
                      }}
                      format={"DD/MM/YYYY hh:mm a"}
                      error={Boolean(errors.end_time) && touched.end_time}
                      helperText={Boolean(touched.end_time) && errors.end_time}
                    />
                  </Grid>
                </Grid>

                {values.platforms.includes("Youtube") && (
                  <Grid item ml={2} lg={12} md={12} sm={12} container>
                    <Grid item lg={3} md={3} sm={3} mt={1}>
                      Title :
                    </Grid>
                    <Grid item lg={8} md={8} sm={8}>
                      <Field
                        variant="outlined"
                        as={MuiTextField}
                        id="title"
                        size="small"
                        name="title"
                        placeholder="Title"
                        maxLength={100}
                        // error={Boolean(errors.title) && Boolean(touched.title)}
                        // helperText={Boolean(touched.title) && errors.title}
                      />
                      <Box textAlign="left" px={2}>
                        {100 - values.title?.length} characters left
                      </Box>
                    </Grid>
                  </Grid>
                )}

                <Grid item ml={2} lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Group * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="group"
                      size="small"
                      name="group"
                      placeholder="Group"
                      error={Boolean(errors.group) && Boolean(touched.group)}
                      helperText={Boolean(touched.group) && errors.group}
                    />
                  </Grid>
                </Grid>

                <Grid item ml={2} lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Description * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <FastField
                      className={
                        touched.caption && errors.caption
                          ? "ticket_desc_test_area_error scrollable"
                          : "ticket_desc_test_area scrollable"
                      }
                      name="caption"
                      component="textarea"
                      value={values.caption}
                      onChange={handleChange}
                      type="text"
                      maxRows={5}
                      minRows={5}
                      maxLength={5000}
                      style={{
                        height: 200,
                        padding: "10px",
                        boxSizing: "border-box",
                        color: `${theme.palette.text.primary}`,
                      }}
                      aria-label="maximum height"
                      placeholder="Enter Description here..."
                      label="description"
                      error={
                        Boolean(errors.caption) && Boolean(touched.caption)
                      }
                      helperText={Boolean(touched.caption) && errors.caption}
                    />

                    <Box textAlign="left" px={2}>
                      {5000 - values.caption?.length} characters left
                    </Box>

                    <Box px={1}>
                      <ErrorMessage name="description" />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item ml={2} lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Media * :
                  </Grid>
                  <Grid item lg={7.5} md={7.5} sm={7.5}>
                    <input
                      type="file"
                      name="media"
                      accept="video/*, image/*"
                      onChange={(e) => {
                        setFieldValue("media", e.target.files[0]);
                      }}
                    />
                    <Box px={1}>
                      <ErrorMessage name="media" />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}></Grid>
                  {values.media && (
                    <Grid item>
                      <Chip
                        label={values.media.name}
                        onDelete={() => {
                          handleDelete(setFieldValue, "media");
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} sm={12} container>
                <Grid ml={2} item mt={3} lg={3} md={3} sm={3}>
                  Account * :
                </Grid>
                <Grid item lg={7.5} md={7.5} sm={7.5}>
                  <Field
                    name="accounts"
                    size="small"
                    multiple
                    // Calling AutoCompleteMultiSelect Component
                    value={values.accounts ? values.accounts : null}
                    component={AutoCompleteMultiSelect}
                    disableCloseOnSelect={true}
                    onChange={(e, selectedAccounts) => {
                      // Iterate through the new selected accounts
                      const updatedAccounts = selectedAccounts.reduce(
                        (acc, currentAccount) => {
                          const existingAccountIndex = acc.findIndex(
                            (item) => item.platform === currentAccount.platform
                          );

                          if (existingAccountIndex !== -1) {
                            // If the platform already exists, replace the account
                            acc[existingAccountIndex] = currentAccount;
                          } else {
                            // Add new account if platform is not found
                            acc.push(currentAccount);
                          }

                          return acc;
                        },
                        [...values.accounts]
                      ); // Start with the existing accounts

                      // Update the accounts field in Formik
                      setFieldValue("accounts", updatedAccounts);
                    }}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {returnPlatformDropdownLogos(option.platform)}
                          {option.account_name}
                        </li>
                      );
                    }}
                    options={
                      accountList
                        ? accountList?.filter((item) =>
                            values.platforms.includes(item.platform)
                          )
                        : []
                    }
                    isOptionEqualToValue={(option, value) =>
                      //setting value property to custom value
                      option.account_id === value.account_id
                    }
                    getOptionLabel={(option) => option.account_name}
                    textFieldProps={{
                      margin: "normal",
                      variant: "outlined",
                      placeholder:
                        !values.accounts.length > 0 ? "Choose Account" : null,
                    }}
                    error={Boolean(errors.accounts) && touched.accounts}
                    helperText={Boolean(touched.accounts) && errors.accounts}
                  />
                  {!values.platforms.length && (
                    <Typography
                      component={"span"}
                      sx={{ color: theme.palette.secondary.main, fontSize: 12 }}
                    >
                      Select Plaform to get Account list
                    </Typography>
                  )}
                </Grid>
              </Grid>

              {values.platforms.includes("Youtube") && (
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid ml={2} item mt={3} lg={3} md={3} sm={3}>
                    Visibility :
                  </Grid>
                  <Grid item lg={7.5} md={7.5} sm={7.5}>
                    <Field
                      name="privacy_status"
                      size="small"
                      component={AutoCompleteMultiSelect}
                      options={privacy_status_list ? privacy_status_list : []}
                      getOptionLabel={(str) =>
                        str?.charAt(0).toUpperCase() + str?.slice(1)
                      }
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Choose who can see your video",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Post"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      width="100%"
                      onClick={() => {
                        resetForm();
                        document.querySelector("input[type='file']").value = "";
                      }}
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapStateToProps)(PlatformPostDialog);
