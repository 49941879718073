import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import EffectUtility from "../../utilities/EffectUtility";
import HttpUtility from "../../utilities/HttpUtility";
import SetUserGetModel from "./models/SetUserGetModel";

export default class SetUserEffect {
  //setUser get method
  static async requestSetUser(params) {
    const endpoint = environment.api.setuser;

    return EffectUtility.getToModel(SetUserGetModel, endpoint, params);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
