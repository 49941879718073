import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TopComposerModel from "./model/TopComposerModel";

// TopComposerEffect
export default class TopComposerEffect {
  // calling an API according to the plan model
  static async requestTopComposer(params) {
    const endpoint = environment.api.top_composers_report;
    return EffectUtility.getToModel(TopComposerModel, endpoint, params);
  }

  // Get method for the plan filter
  static async requestTopComposerFilter(params) {
    const endpoint = environment.api.top_composers_report;
    return EffectUtility.getToModel(TopComposerModel, endpoint, params);
  }
}
