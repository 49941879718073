import BaseReducer from "../../../../utilities/BaseReducer";
import DiscoveredDataAction from "./DiscoveredDataAction";

export default class DiscoveredDataFilterReducer extends BaseReducer {
  initialState = {
    websweepxDiscoveredDataFilter: [],
  };
  [DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepxDiscoveredDataFilter: action.payload,
    };
  }

  [DiscoveredDataAction.REQUEST_GET_DISCOVERED_DATA_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepxDiscoveredDataFilter: action.payload,
    };
  }
}
