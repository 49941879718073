import BaseReducer from "../../../utilities/BaseReducer";
import DashboardAction from "./DashboardAction";

export default class DashboardReducer extends BaseReducer {
  //initial state of dashboard
  initialState = {
    dashboard: [],
  };

  //dashboard request action finish
  [DashboardAction.REQUEST_DASHBOARD_FINISHED](state, action) {
    return {
      ...state,
      dashboard: action.payload,
    };
  }
}
