import { BaseModel } from "sjs-base-model";
import UserDataModel from "./UserDataModel";

export default class SurveyUserModel extends BaseModel {
  //USER model
  data = [UserDataModel];
  // status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
