import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";

import AppStoreModel from "./models/AppStoreModel";
import AppStorePutModel from "./models/AppStorePutModel";

export default class AppStoreEffect {
  // calling an API according to the ProjectModel
  static async requestAppStore(params, callBackFunction) {
    const endpoint = environment.api.appstore;

    let response = EffectUtility.getToModel(AppStoreModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestAppStoreClear() {
    return {};
  }

  // calling an API according to the ProjectModel
  static async requestAppStoreFilter(params) {
    const endpoint = environment.api.appstorefilter;
    return EffectUtility.getToModel(AppStoreModel, endpoint, params);
  }

  // put method for the AppStore Feeds
  static async requestPutAppStoreFeeds(data, id) {
    const endpoint = environment.api.appstore + "/" + id;
    return EffectUtility.putToModel(AppStorePutModel, endpoint, data);
  }
}
