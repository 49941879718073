import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import AddAccountDeleteModel from "./models/AddAccountDeleteModel";
import AddAccountModel from "./models/AddAccountModel";
import AddAccountPutModel from "./models/AddAccountPutModel";

//Category effect
export default class AddAccountEffect {
  // calling an API according to the ProjectModel
  static async requestAddAccount(params) {
    const endpoint = environment.api.addAccount;
    return EffectUtility.getToModel(AddAccountModel, endpoint, params);
  }

  static async requestAddAccountFilter(params) {
    const endpoint = environment.api.addAccountFilter;
    return EffectUtility.getToModel(AddAccountModel, endpoint, params);
  }

  // Put API
  static async requestPutAddAccount(data, id) {
    const endpoint = environment.api.addAccount + "/" + id;

    return EffectUtility.putToModel(AddAccountPutModel, endpoint, data);
  }
  // DELETE API
  static async requestDeleteAddAccount(id) {
    const endpoint = environment.api.addAccount + "/" + id;

    return EffectUtility.deleteToModel(AddAccountDeleteModel, endpoint);
  }
}
