import BaseReducer from "../../../utilities/BaseReducer";
import WhatsAppFeedAction from "./WhatsAppFeedAction";

// WhatsAppFeedReduser  function
export default class WhatsAppFeedReduser extends BaseReducer {
  initialState = {
    whatsAppFeed: [],
  };
  [WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_FINISHED](state, action) {
    return {
      ...state,
      whatsAppFeed: action.payload,
    };
  }

  [WhatsAppFeedAction.REQUEST_WHATSAPP_FEED_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      whatsAppFeed: action.payload,
    };
  }
}
