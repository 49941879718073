import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import GoogleSearchGetModal from "./modals/GoogleSearchGetModal";
import GoogleSearchPostModal from "./modals/GoogleSearchPostModal";

export default class GoogleSearchEffect {
  static async requestGetGoogleConfig(params) {
    const endpoint = environment.api.websweepxGoogleSearchConfig;
    let response = EffectUtility.getToModel(
      GoogleSearchGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostGoogleConfig(data) {
    const endpoint = environment.api.websweepxGoogleSearchConfig;
    let response = EffectUtility.postToModel(
      GoogleSearchPostModal,
      endpoint,
      data
    );
    return response;
  }

  static async requestPutGoogleConfig(data, id) {
    const endpoint = environment.api.websweepxGoogleSearchConfig + `/${id}`;
    let response = EffectUtility.putToModel(
      GoogleSearchPostModal,
      endpoint,
      data
    );
    return response;
  }

  static async requestGetGoogleSearch(params) {
    const endpoint = environment.api.websweepxGoogleSearchMain;
    let response = EffectUtility.getToModel(
      GoogleSearchGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostGoogleSearch(data) {
    const endpoint = environment.api.websweepxGoogleSearchMain;
    let response = EffectUtility.postToModel(
      GoogleSearchPostModal,
      endpoint,
      data
    );
    return response;
  }

  static async requestPutGoogleSearch(data, id) {
    const endpoint = environment.api.websweepxGoogleSearchMain + `/${id}`;
    let response = EffectUtility.putToModel(
      GoogleSearchPostModal,
      endpoint,
      data
    );
    return response;
  }

  static async requestSyncGoogleSearch(params) {
    const endpoint = environment.api.websweepxGoogleSearchSync;
    let response = EffectUtility.getToModel(
      GoogleSearchPostModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestGoogleSearchDiscoveredData(params) {
    const endpoint = environment.api.websweepxGoogleSearchDiscovered;
    let response = EffectUtility.getToModel(
      GoogleSearchGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestPutGoogleSearchDiscoveredData(data, id) {
    const endpoint = environment.api.websweepxGoogleSearchDiscovered + `/${id}`;
    let response = EffectUtility.putToModel(
      GoogleSearchPostModal,
      endpoint,
      data
    );
    return response;
  }

  static async requestGoogleDiscoveredDataExport(params, callBack) {
    const endpoint = environment.api.websweepxGoogleSearchDiscovered;
    let response = EffectUtility.getToModel(
      GoogleSearchGetModal,
      endpoint,
      params
    );

    if (callBack)
      response.then((res) => {
        callBack(res);
      });

    return response;
  }

  static async requestGoogleDiscoveredDataBulkUpdate(params) {
    const endpoint = environment.api.websweepxGoogleSearchBulkUpdate;
    let response = EffectUtility.putToModel(
      GoogleSearchGetModal,
      endpoint,
      params
    );
    return response;
  }
}
