import BaseReducer from "../../../utilities/BaseReducer";
import OrganizationAction from "./OrganizationAction";

// OrganizationReduser
export default class OrganizationReduser extends BaseReducer {
  initialState = {
    organization: [],
  };
  [OrganizationAction.REQUEST_ORGANIZATION_FINISHED](state, action) {
    return {
      ...state,
      organization: action.payload,
    };
  }
}
