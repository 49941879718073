import ActionUtility from "../../../../utilities/ActionUtility";
import FingerPrintReportsEffect from "./FingerPrintReportsEffect";

export default class FingerPrintReportsAction {
  static REQUEST_TOTAL_ASSET_COUNT =
    "FingerPrintReportsAction.REQUEST_TOTAL_ASSET_COUNT";
  static REQUEST_TOTAL_ASSET_COUNT_FINISHED =
    "FingerPrintReportsAction.REQUEST_TOTAL_ASSET_COUNT_FINISHED";

  static REQUEST_TOTAL_COLLECTION_COUNT =
    "FingerPrintReportsAction.REQUEST_TOTAL_COLLECTION_COUNT";
  static REQUEST_TOTAL_COLLECTION_COUNT_FINISHED =
    "FingerPrintReportsAction.REQUEST_TOTAL_COLLECTION_COUNT_FINISHED";

  static REQUEST_TOTAL_COPIES_COUNT =
    "FingerPrintReportsAction.REQUEST_TOTAL_COPIES_COUNT";
  static REQUEST_TOTAL_COPIES_COUNT_FINISHED =
    "FingerPrintReportsAction.REQUEST_TOTAL_COPIES_COUNT_FINISHED";

  static REQUEST_COPIES_ASSETWISE_COUNT =
    "FingerPrintReportsAction.REQUEST_COPIES_ASSETWISE_COUNT";
  static REQUEST_COPIES_ASSETWISE_COUNT_FINISHED =
    "FingerPrintReportsAction.REQUEST_COPIES_ASSETWISE_COUNT_FINISHED";

  static REQUEST_VIEWS_PER_ASSET_COUNT =
    "FingerPrintReportsAction.REQUEST_VIEWS_PER_ASSET_COUNT";
  static REQUEST_VIEWS_PER_ASSET_COUNT_FINISHED =
    "FingerPrintReportsAction.REQUEST_VIEWS_PER_ASSET_COUNT_FINISHED";

  static REQUEST_ACTIVE_ASSET_COUNT =
    "FingerPrintReportsAction.REQUEST_ACTIVE_ASSET_COUNT";
  static REQUEST_ACTIVE_ASSET_COUNT_FINISHED =
    "FingerPrintReportsAction.REQUEST_ACTIVE_ASSET_COUNT_FINISHED";

  // METHODS
  static requestGetTotalAssetCount(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintReportsAction.REQUEST_TOTAL_ASSET_COUNT,
        FingerPrintReportsEffect.requestGetTotalAssetCount,
        params,
        callback
      );
    };
  }

  static requestGetTotalCollectionCount(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintReportsAction.REQUEST_TOTAL_COLLECTION_COUNT,
        FingerPrintReportsEffect.requestGetTotalCollectionCount,
        params,
        callback
      );
    };
  }
  static requestGetTotalCopiesCount(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintReportsAction.REQUEST_TOTAL_COPIES_COUNT,
        FingerPrintReportsEffect.requestGetTotalCopiesCount,
        params,
        callback
      );
    };
  }
  static requestGetTotalCopiesAssetwiseCount(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintReportsAction.REQUEST_COPIES_ASSETWISE_COUNT,
        FingerPrintReportsEffect.requestGetTotalCopiesAssetwiseCount,
        params,
        callback
      );
    };
  }
  static requestGetViewsPerAssetCount(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintReportsAction.REQUEST_VIEWS_PER_ASSET_COUNT,
        FingerPrintReportsEffect.requestGetViewsPerAssetCount,
        params,
        callback
      );
    };
  }

  static requestGetActiveAssetCount(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintReportsAction.REQUEST_ACTIVE_ASSET_COUNT,
        FingerPrintReportsEffect.requestGetActiveAssetCount,
        params,
        callback
      );
    };
  }
}
