import React, { useEffect, useState } from "react";
import environment from "environment";
import LineChartComponent from "../charts/LineChartComponent";
import Logo from "../logoContainer/Logo";
import Description from "../descriptionContainer/Description";
import { Box } from "@mui/material";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../../components/ticketPartials";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";

const ShareOfVoiceChart = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, []);

  async function fetchData(month) {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["customeValue"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      setData(response);
      return response;
    });

    // return fetch(
    //   `${environment.api.ticketReports}?report_type=["count"]&reports=["customeValue"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setData(response);
    //   });
  }

  let categoryList = [];

  data &&
    data.ticket_report &&
    data.ticket_report.customeValue &&
    data.ticket_report.customeValue
      .filter((item) => Object.keys(item)[0] === props.filterValue)
      .map((value) => {
        Object.entries(value)[0][1].map((item) => {
          categoryList.push({
            name: item.key,
            count: item.count,
            percentage: Number(item.percentage.toFixed(2)),
          });
        });
      });

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        categoryList.length > 0 && <LineChartComponent list={categoryList} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
};

export default ShareOfVoiceChart;
