import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../components/muiButton/MuiButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { theme } from "../App";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import PaginationUI from "../../components/pagination/Pagination";
import DatePicker from "../../components/dateRangePickerComponent/DateRangePicker";
import { format } from "date-fns";
import GMBAccountAction from "../../ormTicketing/stores/gmb_Account/GMBAccountAction";
import GmbQuestionFeedsAction from "../../ormTicketing/stores/gmbQuestions/GmbQuestionFeedsAction";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED,
    GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED_FILTER,
    GMBAccountAction.REQUEST_GMB_ACCOUNT,
  ]),
  GMBQuestionFeeds: state.GMBQuestionFeeds.GMBQuestionFeeds || [],
  pagination: state.GMBQuestionFeeds.GMBQuestionFeeds || {},
  GMBQuestionFeedsFilter:
    state.GMBQuestionFeedsFilter.GMBQuestionFeedsFilter || [],
  gmbAccount: state.gmbAccount.gmbAccount.hits || [],
});

function GMBQuestions(props) {
  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  const [page_limit, set_page_limit] = useState(15);
  const [value, setValue] = React.useState("1");
  const [date, setDate] = useState([null, null]);
  const [question_author_name, set_question_author_name] = useState(null);

  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [accountName, setAccountName] = useState(null);

  const AccountList = ["All", "Account1", "Account2"];
  const DaysList = ["Today", "Previous Day", "Last 30 Days"];

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  const handleFilter = () => {
    let params = {};
    const filter = [];

    if (question_author_name !== null) {
      filter.push(
        `["question_author_name.keyword","must","","match","${question_author_name}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["question_created_at","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["question_created_at","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      (date[0] !== null && date[1] !== null) ||
      accountName !== null ||
      question_author_name !== null
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        created_on: "desc",
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeed(params));
    } else {
      alert("Please add filters");
    }
  };

  const [tabTwoValue, setTabTwoValue] = React.useState("1");

  const tabTwoHandleChange = (event, newValue) => {
    setTabTwoValue(newValue);
  };

  function fetchGmbQuestionFeeds() {
    let params = {
      page_limit: `${page_limit}`,
    };
    props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeed(params));
  }

  function fetchGmbQuestionFeedsFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeedFilter(params));
  }

  //   UseEffect function
  useEffect(() => {
    fetchGmbQuestionFeeds();
    fetchGmbQuestionFeedsFilter();
  }, []);

  // pagination
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (question_author_name !== null) {
      filter.push(
        `["question_author_name.keyword","must","","terms",${question_author_name}]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["question_created_at","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["question_created_at","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (FilterClicked) {
      if (
        (date[0] !== null && date[1] !== null) ||
        accountName !== null ||
        question_author_name !== null
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeed(params));
    }
  };

  // Clear method
  const ClearState = () => {
    setFilterClicked(false);

    if (
      (date[0] !== null && date[1] !== null) ||
      accountName !== null ||
      question_author_name !== null
    ) {
      setDate([null, null]);
      set_question_author_name(null);
      setClearLoader(true);
      setAccountName(null);

      if (navigator.onLine === true) {
        const params = {
          page_limit: `${page_limit}`,
        };
        FilterClicked &&
          props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeed(params));
      } else {
        alert("No internet connection");
      }
    }
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded_2, setExpanded_2] = React.useState(false);

  const handleChangeExpand_2 = (panel) => (event, isExpanded) => {
    setExpanded_2(isExpanded ? panel : false);
  };

  let question_author_name_list = [];

  if (props.GMBQuestionFeedsFilter && props.GMBQuestionFeedsFilter.hits) {
    props.GMBQuestionFeedsFilter.hits.hits.map((item) => {
      return question_author_name_list.push(item._source.question_author_name);
    });
  }

  const question_author_name_HandleChange = (event, value) => {
    if (value != null) {
      set_question_author_name(value);
    } else {
      set_question_author_name(null);
    }
  };

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName(null);
    }
  };

  // ************************************* Filter List
  // Integrating Costomer name to list
  const Gmb_question_list = [];

  if (props.gmbAccount.hits) {
    props.gmbAccount.hits.length !== 0 &&
      props.gmbAccount.hits.map((value) => {
        Gmb_question_list.push(value._source.account_name);
      });
  }

  const gmb_questions_uniqueList = [...new Set(Gmb_question_list)];

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  let maxDate = new Date();

  return (
    <Box>
      <Grid
        container
        mt={2}
        sx={{
          backgroundColor: "#f3f9fb",
          height: "auto",
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.16)",
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Account Name</span>
            </Grid>
            <AutoCompleteComponent
              value={accountName}
              list={Gmb_question_list}
              handleChange={accountNameHandleChange}
              textFieldProps={{
                placeholder: "Select Account",
              }}
              size="small"
              width="100%"
            />
          </Grid>

          <Grid item lg={2} md={4} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Customer Name</span>
            </Grid>
            <AutoCompleteComponent
              list={question_author_name_list}
              value={question_author_name}
              textFieldProps={{
                placeholder: "Customer Name",
              }}
              handleChange={question_author_name_HandleChange}
              size="small"
              width="100%"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Date Filter</span>
            </Grid>
            <DatePicker
              value={date}
              onChange={dateValueHandleChange}
              maxDate={maxDate}
            />
          </Grid>
          {/* <Grid item lg={2} md={4} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Answered During</span>
            </Grid>
            <AutoCompleteComponent
              list={DaysList}
              textFieldProps={{
                placeholder: "Select Option",
              }}
              size="small"
              width="100%"
            />
          </Grid> */}
          <Grid item lg={6} md={3} sm={12} mt={0}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={2}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid display={"flex"} justifyContent={"center"} mt={5}>
                <Grid>
                  <MuiButton
                    name={"Apply"}
                    onClick={handleFilter}
                    loading={filterLoader && isRequesting}
                  />
                </Grid>
                <Grid>
                  <MuiButton
                    name={"Clear"}
                    loading={clearLoader && isRequesting}
                    onClick={ClearState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr />
      {/* **********************************************************   Tabs  */}
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
          // className="bg-primary"
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Question & Answers</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid>

          {props.GMBQuestionFeeds && props.GMBQuestionFeeds.hits ? (
            <LoadingIndicator isActive={isRequesting}>
              {props.GMBQuestionFeeds.hits.hits.length !== 0 ? (
                props.GMBQuestionFeeds.hits.hits.map((item, index) => (
                  <Box
                    sx={{
                      padding: 0,
                      margin: "1.2%",
                      marginTop: 0,
                      marginBottom: "0.5%",
                      borderRadius: theme.borderRadius,
                    }}
                    // className="bg-warning"
                  >
                    <Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleChangeExpand(`panel${index}`)}
                      style={{
                        backgroundColor: "#fff",
                        height: "auto",
                        padding: 2,
                        borderRadius: theme.borderRadius,
                        border: "solid 0.5px #d3e2e6",
                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={
                        //   item._source.answers.length > 0 ? (
                        //     <ExpandMoreIcon />
                        //   ) : null
                        // }
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                      >
                        <List
                          sx={{
                            width: "100%",
                          }}
                          component="nav"
                        >
                          <Grid
                            container
                            // sx={{
                            //   backgroundColor: "#fff",
                            //   height: "auto",
                            //   padding: 2,
                            //   borderRadius: theme.borderRadius,
                            //   border: "solid 0.5px #d3e2e6",
                            //   boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                            // }}
                          >
                            <Grid
                              container
                              lg={12}
                              md={12}
                              sm={12}
                              alignItems="flex-start"
                              justifyContent={"center"}
                            >
                              <Grid
                                item
                                lg={1}
                                md={2}
                                sm={2}
                                maxWidth={"80px !important"}
                              >
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar
                                      sx={{
                                        backgroundColor: "skyblue",
                                      }}
                                      src={
                                        item._source.question_author_profile_pic
                                      }
                                    >
                                      {item._source.question_author_name.charAt(
                                        0
                                      )}
                                    </Avatar>
                                  </ListItemAvatar>
                                </ListItem>
                              </Grid>
                              <Grid
                                item
                                lg={11}
                                md={10}
                                sm={10}
                                container
                                alignItems={"center"}
                                // className="bg-warning"
                              >
                                <Grid item lg={12} md={12} sm={12} container>
                                  <Grid item lg={4} md={12} sm={12} mt={1}>
                                    <ListItem>
                                      <h6>Question Author name :&nbsp;</h6>
                                      {item._source.question_author_name}
                                    </ListItem>
                                  </Grid>
                                  <Grid item lg={4} md={12} sm={12} mt={1}>
                                    <ListItem>
                                      <h6>Question created at :</h6>
                                      {(function createdDate() {
                                        let created =
                                          item._source.question_created_at;
                                        let dateConversion = new Date(created);
                                        dateConversion =
                                          dateConversion.toDateString() +
                                          ", " +
                                          dateConversion.toLocaleTimeString(
                                            "en-US",
                                            {
                                              hour: "numeric",
                                              minute: "numeric",
                                              hour12: true,
                                            }
                                          );

                                        return dateConversion;
                                      })()}{" "}
                                    </ListItem>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={4}
                                    md={12}
                                    sm={12}
                                    display={"flex"}
                                  >
                                    <ListItem>
                                      <h6>Total answers :</h6>
                                      {item._source.total_answer_count}
                                    </ListItem>
                                    <ListItem>
                                      <h6>Total votes :</h6>
                                      {item._source.vote_count}
                                    </ListItem>
                                  </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} mt={1}>
                                  <ListItem>
                                    <Grid container xl={12}>
                                      <Grid item xl={0.8}>
                                        <h6>Question : </h6>
                                      </Grid>{" "}
                                      <Grid xl={11.2}>
                                        {item._source.question}
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} mt={1}>
                                  <ListItem>
                                    <Grid container xl={12}>
                                      <Grid item>
                                        <h6>Account Name : </h6>
                                      </Grid>{" "}
                                      <Grid item>
                                        {item._source.account_name}
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </List>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <span
                            className="font-weight-bold pl-4"
                            style={{
                              fontSize: 22,
                            }}
                          >
                            Answers
                          </span>
                        </Typography>
                        {item._source.answers.length >= 0
                          ? item._source.answers.map((ans, index_2) => (
                              <Box
                                sx={{
                                  padding: 1,
                                  margin: "1.2%",
                                  marginTop: 0,
                                  marginBottom: "0.5%",
                                  border: "solid 0.5px #d3e2e6",
                                  borderRadius: theme.borderRadius,
                                  boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                }}
                              >
                                <Accordion
                                  expanded={expanded_2 === `panel${index_2}`}
                                  onChange={handleChangeExpand_2(
                                    `panel${index_2}`
                                  )}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index_2}bh-content`}
                                    id={`panel${index_2}bh-header`}
                                  >
                                    <Grid container>
                                      <Grid
                                        container
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        alignItems="flex-start"
                                        justifyContent={"center"}
                                      >
                                        <Grid
                                          item
                                          lg={1}
                                          md={2}
                                          sm={2}
                                          maxWidth={"80px !important"}
                                        >
                                          <ListItem>
                                            <ListItemAvatar>
                                              <Avatar
                                                sx={{
                                                  backgroundColor: "skyblue",
                                                }}
                                                src={
                                                  ans.answer_author_profile_pic
                                                }
                                              >
                                                {ans.answer_author_name.charAt(
                                                  0
                                                )}
                                              </Avatar>
                                            </ListItemAvatar>
                                          </ListItem>
                                        </Grid>
                                        <Grid
                                          item
                                          lg={11}
                                          md={10}
                                          sm={10}
                                          container
                                          alignItems={"center"}
                                          // className="bg-warning"
                                        >
                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            container
                                          >
                                            <Grid
                                              item
                                              lg={4}
                                              md={12}
                                              sm={12}
                                              mt={1}
                                            >
                                              <ListItem>
                                                <h6>
                                                  Answer author name :&nbsp;
                                                </h6>
                                                {ans.answer_author_name}
                                              </ListItem>
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              md={12}
                                              sm={12}
                                              mt={1}
                                            >
                                              <ListItem>
                                                <h6>Answer Created at :</h6>
                                                {(function createdDate() {
                                                  let created =
                                                    ans.answer_created_at;
                                                  let dateConversion = new Date(
                                                    created
                                                  );
                                                  dateConversion =
                                                    dateConversion.toDateString() +
                                                    ", " +
                                                    dateConversion.toLocaleTimeString(
                                                      "en-US",
                                                      {
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                        hour12: true,
                                                      }
                                                    );

                                                  return dateConversion;
                                                })()}{" "}
                                              </ListItem>
                                            </Grid>
                                            {/* <Grid
                                              item
                                              lg={4}
                                              md={12}
                                              sm={12}
                                              display={"flex"}
                                            >
                                              <ListItem>
                                                <h6>Rating :</h6>
                                                {returnStars(
                                                    item._source.review_rating
                                                  )}
                                              </ListItem>
                                              <ListItem>
                                                <h6>Project :</h6>
                                                {
                                                  item._source.project[0]
                                                    .project_name
                                                }
                                              </ListItem>
                                            </Grid> */}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ListItem>
                                      <Grid container xl={12}>
                                        <Grid item xl={0.8}>
                                          <h6>Review : </h6>
                                        </Grid>{" "}
                                        <Grid xl={11.2}>{ans.answer}</Grid>
                                      </Grid>
                                    </ListItem>
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            ))
                          : null}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))
              ) : (
                <NoDataFound />
              )}
            </LoadingIndicator>
          ) : null}
        </Box>

        {props.GMBQuestionFeeds && props.GMBQuestionFeeds.hits
          ? props.GMBQuestionFeeds.hits.hits.length !== 0 &&
            !isRequesting && (
              <PaginationUI
                pagelength={props.pagination.total_page_no}
                page={props.pagination.current_page_no}
                handleChange={handleChangePagination}
              />
            )
          : null}
      </Grid>
    </Box>
  );
}

export default connect(mapStateToProps)(GMBQuestions);
