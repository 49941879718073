import BaseReducer from "../../../utilities/BaseReducer";
import BrandCompitatorAction from "./BrandCompitatorAction";

// reducer function
export default class BrandCompitatorReducer extends BaseReducer {
  initialState = {
    brandCompitator: [],
  };

  [BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FINISHED](state, action) {
    return {
      ...state,
      brandCompitator: action.payload,
    };
  }
}
