import ActionUtility from "../../../../utilities/ActionUtility";

import SurveyReportEffect from "./SurveyReportEffect";

export default class SurveyReportAction {
  // Survey action with an function declaration
  static REQUEST_SURVEY_REPORT = "SurveyReportAction.REQUEST_SURVEY_REPORT";
  static REQUEST_SURVEY_REPORT_FINISHED =
    "SurveyReportAction.REQUEST_SURVEY_REPORT_FINISHED";

  // METHODS
  // Survey report GET function
  static requestSurveyReportById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyReportAction.REQUEST_SURVEY_REPORT,
        SurveyReportEffect.requestSurveyReportById,
        id,
        params
      );
    };
  }
}
