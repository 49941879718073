import { BaseModel } from "sjs-base-model";
import SurveyDataDhitsModel from "./SurveyDataDhitsModel";
import SurveyDataShardsModel from "./SurveyDataShardsModel";

// SurveyDataModel
export default class SurveyDataModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: SurveyDataShardsModel };
  current_page_no = 0;
  hits = { hits: SurveyDataDhitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
