import { Box, Dialog, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";
import { connect } from "react-redux";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import CancelIcon from "@mui/icons-material/Cancel";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const A3NotificationDialog = (props) => {
  const notification = props?.notificationsData;

  const handleClose = () => {
    props.onClose();
  };

  function returnLocalTime(time) {
    let timeConversion = new Date(time);
    timeConversion =
      timeConversion.toDateString() +
      ", " +
      timeConversion.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

    return timeConversion;
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      fullWidth
      TransitionComponent={Slide}
      transitionDuration={350}
    >
      <Box>
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          px={3}
          py={1}
          sx={{
            borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
          }}
        >
          <Grid item pt={1} sx={{ color: theme.palette.primary.main }}>
            <h1
              style={{
                fontSize: "24px",
              }}
            >
              {formatName("Notification")}
            </h1>
          </Grid>
          <Grid item sx={{ color: theme.palette.text.gray }}>
            <IconButton
              onClick={props.onClose}
              sx={{
                transition: "transform 0.3s ease-in-out",

                "&:hover": {
                  color: theme.palette.secondary.main,
                  transform: "scale(1.15)",
                },
              }}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />
        <Box p={2}>
          <Grid container lg={12} md={12} sm={12} spacing={2}>
            <Grid item lg={12} md={12} sm={12}>
              <Typography component={"div"}>
                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: "bold",
                    marginRight: "15px",
                  }}
                >
                  Title :{" "}
                </Typography>
                {notification?.notification_title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Typography component={"div"}>
                <Typography
                  component={"span"}
                  sx={{ fontWeight: "bold", marginRight: "15px" }}
                >
                  Message :{" "}
                </Typography>
                {notification?.notification_msg}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Typography component={"div"}>
                <Typography
                  component={"span"}
                  sx={{ fontWeight: "bold", marginRight: "15px" }}
                >
                  Created By :{" "}
                </Typography>
                {notification?.created_by?.user_name}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Typography
                component={"span"}
                sx={{ fontWeight: "bold", marginRight: "15px" }}
              >
                Created On :{" "}
              </Typography>
              {notification?.created_on
                ? returnLocalTime(notification.created_on)
                : ""}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};
export default connect(mapStateToProps)(A3NotificationDialog);
