import React, { useEffect, useRef, useState } from "react";
import "./cssFiles/NegativeMentionsDispositionWise.css";
import environment from "environment";
import { endDate, monthNames, startDate } from "./dateFunctions/DateFunctions";
import { format } from "date-fns";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Description from "./descriptionContainer/Description";
import Logo from "./logoContainer/Logo";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

function NegativeMentions(props) {
  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);

  const [today, setToday] = useState(new Date());

  const [current_month_data, set_current_month_data] = useState(null);

  // this month
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);

  let before_previous_month_start_date = startDate(before_previous_month);

  function convertDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastSecondFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // Set the date to the first day of the previous month

  const lastThirdFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    1
  ); // Set the date to the first day of the previous month

  let currentMonth = convertDate(thisMonth);
  let lastSecond = convertDate(lastSecondFirstDay);
  let lastThird = convertDate(lastThirdFirstDay);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      // fetching platform data
      await fetchData(
        before_previous_month_start_date,
        current_month_end_date
      ).then((e) => {
        set_current_month_data(e);
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);

        // fetching platform data
        await fetchData(
          new Date(firstDateString),
          new Date(endDateString)
        ).then((e) => {
          set_current_month_data(e);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        setToday(new Date());

        // fetching platform data
        await fetchData(
          before_previous_month_start_date,
          current_month_end_date
        ).then((e) => {
          set_current_month_data(e);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  async function fetchData(from, to) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${
    //     environment.api.ticketReports
    //   }?report_type=["count"]&reports=["platform"]&from=${format(
    //     from,
    //     "yyyy-MM-dd"
    //   )}&to=${format(to, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(async (response) => {
    //     return response;
    //   })
    //   .catch((err) => {});
  }

  let barChartList = [];

  const result = [];

  // barChartList &&
  //   barChartList.forEach((el) => {
  //     let [key, value] = Object.entries(el)[0];
  //     for (let el of result)
  //       if (key in el) {
  //         el[key].push(value);
  //         return;
  //       }

  //     result.push({ [key]: [value] });
  //   });

  let Total_of_before_previous_month = [];

  // result &&
  //   result.map((value) => {
  //     value &&
  //       value[Object.keys(value)].map((item) => {
  //         item &&
  //           item.map((value) => {
  //             if (value.before_previous_month_count) {
  //               Total_of_before_previous_month.push(
  //                 value.before_previous_month_count
  //               );
  //             }
  //           });
  //       });
  //   });

  let Total_of_previous_month = [];

  function TotalCount(item) {
    return item.length > 0 && item.reduce((prev, next) => prev + next);
  }

  let countData = [];
  let nameLabels = [];

  let MaxValue = Math.max(...countData);

  let AvgOfMax = MaxValue / 10;
  MaxValue = MaxValue + AvgOfMax;

  AvgOfMax = Math.round(AvgOfMax);

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  const returnBeforePreviousMothNegetiveTotal = (platform) => {
    let total = 0;

    platform &&
      platform.length !== 0 &&
      platform.map((item) => {
        item.monthwise.buckets &&
          item.monthwise.buckets[0].postive_and_negative_sentiment.buckets.map(
            (val) => {
              if (val.key === "negative_sentiment") {
                total = total + val.doc_count;
              }
            }
          );
      });

    return total;
  };

  const returnPreviousMothNegetiveTotal = (platform) => {
    let total = 0;

    platform &&
      platform.length !== 0 &&
      platform.map((item) => {
        item.monthwise.buckets &&
          item.monthwise.buckets[1].postive_and_negative_sentiment.buckets.map(
            (val) => {
              if (val.key === "negative_sentiment") {
                total = total + val.doc_count;
              }
            }
          );
      });

    return total;
  };

  const returnCurrentMothNegetiveTotal = (platform) => {
    let total = 0;

    platform &&
      platform.length !== 0 &&
      platform.map((item) => {
        item.monthwise &&
          item.monthwise.buckets &&
          item.monthwise.buckets[2].postive_and_negative_sentiment.buckets.map(
            (val) => {
              if (val.key === "negative_sentiment") {
                total = total + val.doc_count;
              }
            }
          );
      });

    return total;
  };

  const returnTotal = (platform) => {
    let total = 0;

    platform &&
      platform.length !== 0 &&
      platform.map((item) => {
        if (item) {
          total = total + item.negative_count;
        }
      });

    return total;
  };

  const roundToThreeDec = (decimal) => {
    const number = decimal;
    const roundedNumber = number.toFixed(3);
    return roundedNumber;
  };

  let NoDataFoundBool =
    (!loading && current_month_data?.status === "Failed") ||
    (!loading &&
      current_month_data &&
      current_month_data.ticket_report &&
      current_month_data.ticket_report.platform &&
      current_month_data.ticket_report.platform.slice(
        current_month_data &&
          current_month_data.ticket_report &&
          current_month_data.ticket_report.platform &&
          current_month_data.ticket_report.platform.length - 2,
        current_month_data &&
          current_month_data.ticket_report &&
          current_month_data.ticket_report.platform &&
          current_month_data.ticket_report.platform.length - 1
      )[0].total_negative === 0);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      minWidth: 150,
    },
    {
      field: new Date(lastThird).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      headerName: new Date(lastThird).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: new Date(lastSecond).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      headerName: new Date(lastSecond).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      headerName: new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total",
      headerName: `Total`,
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
      cellClassName: "boldRow",
    },
    {
      field: "avg",
      headerName: `${new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })} Avg%`,
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
  ];
  let rows = [];

  function returnMonthName(monthString) {
    let dateObj = new Date(monthString);
    let options = { year: "numeric", month: "short" };
    let formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  if (current_month_data?.ticket_report?.platform) {
    current_month_data?.ticket_report.platform.map((item, index) => {
      if (item.platform) {
        const lastThirdKeyN = `${returnMonthName(lastThird)}`;

        const lastThirdCountN =
          item.monthwise.buckets[0].postive_and_negative_sentiment.buckets[0]
            .doc_count;

        const lastSecondKeyN = `${returnMonthName(lastSecond)}`; // Construct the dynamic key
        const lastSecondCountN =
          item.monthwise.buckets[1].postive_and_negative_sentiment.buckets[0]
            .doc_count;

        const currentMonthKeyN = `${returnMonthName(currentMonth)}`; // Construct the dynamic key
        const currentMonthCountN =
          item.monthwise.buckets[2]?.postive_and_negative_sentiment?.buckets[0]
            ?.doc_count ?? 0;

        const row = {
          id: index + 1,
          platforms: platformName(item.platform),
          total: prefixZero_for_singleDigit(item.negative_count),
          avg: `${item.negative_percentage}%`,
        };

        row[lastThirdKeyN] = prefixZero_for_singleDigit(lastThirdCountN);
        row[lastSecondKeyN] = prefixZero_for_singleDigit(lastSecondCountN);
        row[currentMonthKeyN] = prefixZero_for_singleDigit(currentMonthCountN);

        rows.push(row);
      } else {
        const lastThirdKeyAN = `${returnMonthName(lastThird)}`; // Construct the dynamic key
        const lastThirdCountAN =
          current_month_data?.ticket_report.platform.filter((single) => {
            return single.key === lastThird;
          })[0] &&
          Object.values(
            current_month_data?.ticket_report.platform.filter((single) => {
              return single.key === lastThird;
            })[0]
          )[1];

        const lastSecondKeyAN = `${returnMonthName(lastSecond)}`; // Construct the dynamic key
        const lastSecondCountAN =
          current_month_data?.ticket_report.platform.filter((single) => {
            return single.key === lastSecond;
          })[0] &&
          Object.values(
            current_month_data?.ticket_report.platform.filter((single) => {
              return single.key === lastSecond;
            })[0]
          )[1];

        const currentMonthKeyAN = `${returnMonthName(currentMonth)}`; // Construct the dynamic key
        const currentMonthCountAN =
          current_month_data?.ticket_report.platform.filter((single) => {
            return single.key === currentMonth;
          })[0] &&
          Object.values(
            current_month_data?.ticket_report.platform.filter((single) => {
              return single.key === currentMonth;
            })[0]
          )[1];

        let total_count =
          current_month_data?.ticket_report.platform[
            current_month_data?.ticket_report.platform.length - 2
          ];

        const row = {
          id: index + 1,
          platforms: Object.keys(item).includes("overall_total")
            ? "Total"
            : platformName(item.platform),
          total: prefixZero_for_singleDigit(total_count.total_negative),
          avg: `${
            current_month_data?.ticket_report.platform[
              current_month_data?.ticket_report.platform.length - 1
            ].total_percentage
          }%`,
        };

        row[lastThirdKeyAN] = prefixZero_for_singleDigit(lastThirdCountAN);
        row[lastSecondKeyAN] = prefixZero_for_singleDigit(lastSecondCountAN);
        row[currentMonthKeyAN] =
          prefixZero_for_singleDigit(currentMonthCountAN);

        rows.push(row);
      }
    });
  }

  const temp_rows = [...rows.splice(0, rows.length - 5)];

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      temp_rows?.forEach((item) => {
        exportData?.push({
          platforms: Array.isArray(item?.platforms)
            ? item?.platforms[0]
            : item?.platforms,
          [`${new Date(lastThird).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}`]:
            item[
              `${new Date(lastThird).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
              })}`
            ],

          [`${new Date(lastSecond).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}`]:
            item[
              `${new Date(lastSecond).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
              })}`
            ],

          [`${new Date(currentMonth).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}`]:
            item[
              `${new Date(currentMonth).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
              })}`
            ],

          Total: item?.total,

          [`${new Date(currentMonth).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })} Avg%`]: `${item["avg"]}`,
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, temp_rows]);

  return (
    <LoadingIndicator isActive={loading}>
      <Box>
        {!loading ? (
          <Box style={{ width: "100%", padding: "12px" }}>
            {!loading && !NoDataFoundBool ? (
              <div
                style={{
                  width: "100%",
                  overflowX: "auto",
                  borderRadius: theme.table.borderRadius,
                }}
              >
                {props.dataGridHeight?.current && rows && (
                  <DataGridFunction
                    rows={temp_rows}
                    columns={columns}
                    rowBorder={false}
                    height={props.dataGridHeight.current}
                    rowHeight={50}
                    headerHeight={80}
                    onCellClick={onCellClick}
                    rowColor={true}
                    headerColor={true}
                    getRowClassName={(params) =>
                      (params.row.platforms == "Total" ? "boldRow" : "") +
                      (params.id % 2 === 0 ? " oddRow" : "")
                    }
                  />
                )}
              </div>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}

export default NegativeMentions;
