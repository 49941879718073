import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ResourceModel from "./model/ResourceModel";
import ResourcePutModel from "./model/ResourcePutModel";
import ResourcePostModel from "./model/ResourcePostModel";

// resourceEffect
export default class resourceEffect {
  // calling an API according to the resource model
  // Get method for the
  static async requestResource(params) {
    const endpoint = environment.api.resource;
    return EffectUtility.getToModel(ResourceModel, endpoint, params);
  }

  // Get method for the resource filter
  static async requestResourceFilter(params) {
    const endpoint = environment.api.resource;
    return EffectUtility.getToModel(ResourceModel, endpoint, params);
  }

  // put method for the resource
  static async requestPutResource(data, id) {
    const endpoint = environment.api.resource + "/" + id;
    return EffectUtility.putToModel(ResourcePutModel, endpoint, data);
  }

  // post method for the resource
  static async requestPostResource(data) {
    const endpoint = environment.api.resource + "/add";
    return EffectUtility.postToModel(ResourcePostModel, endpoint, data);
  }
}
