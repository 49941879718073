import { BaseModel } from "sjs-base-model";
import TwitterDirectMessageDataModel from "./TwitterDirectMessageDataModel";

export default class TwitterDirectMessagePostmodel extends BaseModel {
  data = { TwitterDirectMessageDataModel };
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
