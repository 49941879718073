import ActionUtility from "../../../utilities/ActionUtility";
import FaqEffect from "./FaqEffect";

export default class FaqAction {
  static REQUEST_GET_FAQ = "FaqAction.REQUEST_GET_FAQ";
  static REQUEST_GET_FAQ_FINISHED = "FaqAction.REQUEST_GET_FAQ_FINISHED";

  static REQUEST_POST_FAQ = "FaqAction.REQUEST_POST_FAQ";
  static REQUEST_POST_FAQ_FINISHED = "FaqAction.REQUEST_POST_FAQ_FINISHED";

  static REQUEST_PUT_FAQ = "FaqAction.REQUEST_PUT_FAQ";
  static REQUEST_PUT_FAQ_FINISHED = "FaqAction.REQUEST_PUT_FAQ_FINISHED";

  static REQUEST_GET_FAQ_MODULES = "FaqAction.REQUEST_GET_FAQ_MODULES";
  static REQUEST_GET_FAQ_MODULES_FINISHED =
    "FaqAction.REQUEST_GET_FAQ_MODULES_FINISHED";

  static REQUEST_GET_FAQ_TOPICS = "FaqAction.REQUEST_GET_FAQ_TOPICS";
  static REQUEST_GET_FAQ_TOPICS_FINISHED =
    "FaqAction.REQUEST_GET_FAQ_TOPICS_FINISHED";

  // METHODS
  static requestGetFaq(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaqAction.REQUEST_GET_FAQ,
        FaqEffect.requestGetFaq,
        params,
        callback
      );
    };
  }

  static requestPostFaq(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaqAction.REQUEST_POST_FAQ,
        FaqEffect.requestPostFaq,
        data
      );
    };
  }

  static requestPutFaq(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaqAction.REQUEST_PUT_FAQ,
        FaqEffect.requestPutFaq,
        data,
        id
      );
    };
  }

  static requestGetFaqModules(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaqAction.REQUEST_GET_FAQ_MODULES,
        FaqEffect.requestGetFaqModules,
        params,
        callback
      );
    };
  }

  static requestGetFaqTopics(params, module, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FaqAction.REQUEST_GET_FAQ_TOPICS,
        FaqEffect.requestGetFaqTopics,
        params,
        module,
        callback
      );
    };
  }
}
