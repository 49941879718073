import BaseReducer from "../../../../utilities/BaseReducer";
import HssDetailsAction from "./HssDetailsAction";

export default class HssDetailsByIDReducer extends BaseReducer {
  initialState = {
    HSSPropertyByID: [],
  };
  [HssDetailsAction.REQUEST_GET_HSS_DETAILS_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      HSSPropertyByID: action.payload,
    };
  }
}
