import { BaseModel } from "sjs-base-model";
import ResponseMessageDataHitsModel from "./ResponseMessageDataHitsModel";

// get model for project
export default class ResponseMessageHitsModel extends BaseModel {
  /*
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  hits = [{ ResponseMessageDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
