import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import ManageRoleModel from "./models/ManageRoleModel";

export default class ManageRoleEffect {
  //get role request endpoint for filter
  static async requestRoleFilter(params) {
    const endpoint = environment.api.rolesfilter;
    return EffectUtility.getToModel(ManageRoleModel, endpoint, params);
  }

  static async requestRoleTemplateTrueFilter(params) {
    const endpoint = environment.api.rolesfilter;
    return EffectUtility.getToModel(ManageRoleModel, endpoint, params);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
