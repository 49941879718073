import ActionUtility from "../../../utilities/ActionUtility";
import AiSuggestionEffect from "./AiSuggestionEffect";

export default class AiSuggestionAction {
  // AiSuggestionAction action with an function declaration
  static REQUEST_AI_SUGGESTION = "AiSuggestionAction.REQUEST_AI_SUGGESTION";
  static REQUEST_AI_SUGGESTION_FINISHED =
    "AiSuggestionAction.REQUEST_AI_SUGGESTION_FINISHED";

  static REQUEST_BARD_AI_SUGGESTION =
    "AiSuggestionAction.REQUEST_BARD_AI_SUGGESTION";
  static REQUEST_BARD_AI_SUGGESTION_FINISHED =
    "AiSuggestionAction.REQUEST_BARD_AI_SUGGESTION_FINISHED";

  // METHODS
  static requestAiSuggestion(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiSuggestionAction.REQUEST_AI_SUGGESTION,
        AiSuggestionEffect.requestAiSuggestion,
        data,
        params,
        callback
      );
    };
  }

  static requestBardAiSuggestion(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiSuggestionAction.REQUEST_BARD_AI_SUGGESTION,
        AiSuggestionEffect.requestBardAiSuggestion,
        data,
        params,
        callback
      );
    };
  }
}
