import BaseReducer from "../../../../utilities/BaseReducer";
import LinkCheckingAction from "./LinkCheckingAction";

export default class LinkCheckingFilterReducer extends BaseReducer {
  initialState = {
    linkCheckingFilter: [],
  };
  [LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER_FINISHED](state, action) {
    return {
      ...state,
      linkCheckingFilter: action.payload,
    };
  }

  [LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      linkCheckingFilter: action.payload,
    };
  }
}
