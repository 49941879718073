import { BaseModel } from "sjs-base-model";

export default class SiteDataPostModel extends BaseModel {
  status = "";
  message = "";
  error = [];
  total_error = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
