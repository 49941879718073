import environment from "environment";
import AmbitionBoxPutModel from "../../../stores/ambitionBoxFeeds/model/AmbitionBoxPutModel";
import EffectUtility from "../../../utilities/EffectUtility";
import GlassdoorFeedModel from "./model/GlassdoorFeedModel";

// GlassdoorFeedsEffect
export default class GlassdoorFeedsEffect {
  // calling an API according to the Glassdoor Feeds model
  // Get method for the Glassdoor Feeds
  static async requestGlassdoorFeed(params) {
    const endpoint = environment.api.glassdoorFeeds;
    return EffectUtility.getToModel(GlassdoorFeedModel, endpoint, params);
  }

  static async requestGlassdoorFeedClear() {
    return {};
  }

  static async requestPutGlassdoorFeed(data, id) {
    const endpoint = environment.api.glassdoorFeeds + "/" + id;
    return EffectUtility.putToModel(AmbitionBoxPutModel, endpoint, data);
  }

  // Get method for the Glassdoor Feeds filter
  static async requestGlassdoorFeedFilter(params) {
    const endpoint = environment.api.glassdoorFeedsFilter;
    return EffectUtility.getToModel(GlassdoorFeedModel, endpoint, params);
  }
}
