import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
// DataGridFunction Component
// postPlus button component
import { connect } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TagAction from "../../stores/tag/TagAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import TagPost from "../../components/tagform/TagPost";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

// mapstatetoprops function

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TagAction.REQUEST_TAG,
    TagAction.REQUEST_TAG_FILTER,
  ]),
  tag: state.tag.tag.hits || [],
  pagination: state.tag.tag || [],
  tagfilter: state.tagfilter.tagfilter || [],
});

function Tag(props) {
  const { isRequesting } = props;
  //******************************************************** SetState
  const [searchTag, setSearchTag] = useState(null);
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  // ***************************************************** Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "tag_name",
      headerName: "Tag Name",
      width: 250,
      flex: 4,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 4,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      align: "center",
      width: 100,
      flex: 1,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
            title="Edit"
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  // *********************************************  fetch methods

  function fetch(params) {
    props.dispatch(TagAction.requestTag(params));
  }

  function fetchTagFilter(param) {
    props.dispatch(TagAction.requestTagFilter(param));
  }

  // **************************************************  UseEffect
  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["tag_name.keyword", "asc"]]`,
    };

    // if (props?.tagfilter === undefined || props?.tagfilter.length === 0) {
    fetchTagFilter(param);
    // }

    // projectFetch(param);

    const filter = [];
    let params = {};

    if (searchTag !== null) {
      filter.push(`["tag_name.keyword","must","","term","${searchTag}"]`);
    }

    if (searchTag !== null) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["order_number","asc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: 0,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    fetch(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //********************************************************* pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    if (searchTag !== null) {
      filter.push(`["tag_name.keyword","must","","term","${searchTag}"]`);
    }

    if (searchTag !== null && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetch(params);
    }
  };

  // *******************************************************  handle Filter
  const handleFilter = () => {
    const filter = [];

    if (searchTag !== null) {
      filter.push(`["tag_name.keyword","must","","term","${searchTag}"]`);
    }

    if (searchTag !== null) {
      const params = {
        filters: `[${filter}]`,
        order_by: `[["order_number","asc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetch(params);
      setFilterClicked(true);
      setFilterLoader(true);
      setClearLoader(false);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];

  if (props.tag.hits) {
    props.tag.hits.map((item, index) => {
      return rows.push({
        // sl_no: item._id
        //   ? props.pagination.current_page_no * page_limit + ++index
        //   : "",
        sl_no: item._source.order_number,
        id: item._id,
        tag_name: item._source.tag_name,
        created_by: item._source.created_by.user_name,
      });
    });
  }

  // ****************************************** tag name handle change

  const tagListHandleChange = (event, searchTag) => {
    if (searchTag != null) {
      setSearchTag(searchTag);
    } else {
      setSearchTag(null);
    }
  };

  // ********************************************* Tag Filter List
  let taglist = [];
  if (props.tagfilter.hits) {
    props.tagfilter.hits.hits.forEach((value) => {
      taglist.push(value._source.tag_name);
    });
  }

  let uniqueList = [...new Set(taglist)];

  uniqueList.sort((a, b) => a.localeCompare(b));
  uniqueList = [...new Set(uniqueList)];

  //***************************************** */ project nama list

  // ************************************************* clear function

  const ClearState = () => {
    // Clear the state

    if (searchTag !== null) {
      setClearLoader(true);
      setSearchTag(null);
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);

    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetch(params);
  };

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["tag_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    const callBack = () => {
      props.dispatch(TagAction.requestTag(paramss));
      props.dispatch(TagAction.requestTagFilter(param));
    };

    data &&
      props.dispatch(TagAction.requestPutTagBulkUpdate(bulkData, callBack));
  };

  return (
    <React.Fragment>
      {/* Tag post modal screen */}
      <Box>
        <TagPost
          open={open}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          projectEditData={editRowsModel}
          edit={edit}
        />

        {/*************************************************** Tag Filter */}

        <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.5} mt={1}>
              <AutoCompleteComponent
                value={searchTag}
                list={uniqueList}
                textFieldProps={{
                  placeholder: "Tag Name",
                }}
                handleChange={tagListHandleChange}
                size="small"
                width="100%"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={5}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  sm={8}
                  md={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      name={"Filter"}
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Clear"}
                      width="100%"
                      onClick={ClearState}
                      loading={clearLoader && isRequesting}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  {/* post button component*/}
                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              <Grid item lg={8} md={12} sm={12} xs={12}>
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {props.tag.hits && !isRequesting ? (
                    <DataGridFunction
                      columns={columns}
                      rows={rows}
                      rowHeight={60}
                      rowReordering
                      onRowOrderChange={rowOrderHandleChange}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingBgImage />
                    </Box>
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>

            {/* Pagination */}
            {/* <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={10}
              md={12}
              sm={12}
              className="pagination_section"
            >
              <Grid item lg={8} md={12} sm={12}>
                {props.tag.hits
                  ? props.tag.hits.length !== 0 && (
                      <PaginationUI
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  : null}
              </Grid>
            </Grid> */}
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(Tag);
