import ActionUtility from "../../../../utilities/ActionUtility";
import ReleasesEffect from "./ReleasesEffect";

export default class ReleasesAction {
  static REQUEST_RELEASES = "ReleasesAction.REQUEST_RELEASES";
  static REQUEST_RELEASES_FINISHED = "ReleasesAction.REQUEST_RELEASES_FINISHED";

  static REQUEST_CHANGE_REQUEST_RELEASES =
    "ReleasesAction.REQUEST_CHANGE_REQUEST_RELEASES";
  static REQUEST_CHANGE_REQUEST_RELEASES_FINISHED =
    "ReleasesAction.REQUEST_CHANGE_REQUEST_RELEASES_FINISHED";

  static REQUEST_TOP_5_DELIVERED_RELEASES =
    "ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES";
  static REQUEST_TOP_5_DELIVERED_RELEASES_FINISHED =
    "ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES_FINISHED";

  static REQUEST_TOP_5_DRAFT_RELEASES =
    "ReleasesAction.REQUEST_TOP_5_DRAFT_RELEASES";
  static REQUEST_TOP_5_DRAFT_RELEASES_FINISHED =
    "ReleasesAction.REQUEST_TOP_5_DRAFT_RELEASES_FINISHED";

  static REQUEST_RELEASES_SHADOW = "ReleasesAction.REQUEST_RELEASES_SHADOW";
  static REQUEST_RELEASES_SHADOW_FINISHED =
    "ReleasesAction.REQUEST_RELEASES_SHADOW_FINISHED";

  static REQUEST_RELEASE_FILTER = "ReleasesAction.REQUEST_RELEASE_FILTER";
  static REQUEST_RELEASE_FILTER_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_FILTER_FINISHED";

  static REQUEST_DRAFT_RELEASE_FILTER =
    "ReleasesAction.REQUEST_DRAFT_RELEASE_FILTER";
  static REQUEST_DRAFT_RELEASE_FILTER_FINISHED =
    "ReleasesAction.REQUEST_DRAFT_RELEASE_FILTER_FINISHED";

  static REQUEST_PUT_RELEASE = "ReleasesAction.REQUEST_PUT_RELEASE";
  static REQUEST_PUT_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_PUT_RELEASE_FINISHED";

  static REQUEST_PUT_DRAFT_RELEASE = "ReleasesAction.REQUEST_PUT_DRAFT_RELEASE";
  static REQUEST_PUT_DRAFT_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_PUT_DRAFT_RELEASE_FINISHED";

  static REQUEST_POST_RELEASE = "ReleasesAction.REQUEST_POST_RELEASE";
  static REQUEST_POST_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_POST_RELEASE_FINISHED";

  static REQUEST_RELEASE_CLEAR = "ReleasesAction.REQUEST_RELEASE_CLEAR";
  static REQUEST_RELEASE_CLEAR_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_CLEAR_FINISHED";

  static REQUEST_RELEASE_FILTER_CLEAR =
    "ReleasesAction.REQUEST_RELEASE_FILTER_CLEAR";
  static REQUEST_RELEASE_FILTER_CLEAR_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_FILTER_CLEAR_FINISHED";

  static REQUEST_RELEASE_BY_ID = "ReleasesAction.REQUEST_RELEASE_BY_ID";
  static REQUEST_RELEASE_BY_ID_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_BY_ID_FINISHED";

  static REQUEST_CHANGE_REQUESTS_RELEASE_BY_ID =
    "ReleasesAction.REQUEST_CHANGE_REQUESTS_RELEASE_BY_ID";
  static REQUEST_CHANGE_REQUESTS_RELEASE_BY_ID_FINISHED =
    "ReleasesAction.REQUEST_CHANGE_REQUESTS_RELEASE_BY_ID_FINISHED";

  static REQUEST_PUT_CHANGE_REQUEST_RELEASE =
    "ReleasesAction.REQUEST_PUT_CHANGE_REQUEST_RELEASE";
  static REQUEST_PUT_CHANGE_REQUEST_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_PUT_CHANGE_REQUEST_RELEASE_FINISHED";

  static REQUEST_FINAL_RELEASE_STATE =
    "ReleasesAction.REQUEST_FINAL_RELEASE_STATE";
  static REQUEST_FINAL_RELEASE_STATE_FINISHED =
    "ReleasesAction.REQUEST_FINAL_RELEASE_STATE_FINISHED";

  static REQUEST_DRAFT_RELEASE_BY_ID =
    "ReleasesAction.REQUEST_DRAFT_RELEASE_BY_ID";
  static REQUEST_DRAFT_RELEASE_BY_ID_FINISHED =
    "ReleasesAction.REQUEST_DRAFT_RELEASE_BY_ID_FINISHED";

  static REQUEST_RELEASE_BY_ID_CLEAR =
    "ReleasesAction.REQUEST_RELEASE_BY_ID_CLEAR";
  static REQUEST_RELEASE_BY_ID_CLEAR_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_BY_ID_CLEAR_FINISHED";

  static REQUEST_DRAFT_RELEASES = "ReleasesAction.REQUEST_DRAFT_RELEASES";
  static REQUEST_DRAFT_RELEASES_FINISHED =
    "ReleasesAction.REQUEST_DRAFT_RELEASES_FINISHED";

  static REQUEST_DRAFT_RELEASE_BY_ID_CLEAR =
    "ReleasesAction.REQUEST_DRAFT_RELEASE_BY_ID_CLEAR";
  static REQUEST_DRAFT_RELEASE_BY_ID_CLEAR_FINISHED =
    "ReleasesAction.REQUEST_DRAFT_RELEASE_BY_ID_CLEAR_FINISHED";

  // To post the release into the ingrooves
  static REQUEST_POST_TO_INGROOVES = "ReleasesAction.REQUEST_POST_TO_INGROOVES";
  static REQUEST_POST_TO_INGROOVES_FINISHED =
    "ReleasesAction.REQUEST_POST_TO_INGROOVES_FINISHED";

  static REQUEST_POST_TO_INGROOVES_INITIATE_TOKEN =
    "ReleasesAction.REQUEST_POST_TO_INGROOVES_INITIATE_TOKEN";
  static REQUEST_POST_TO_INGROOVES_INITIATE_TOKEN_FINISHED =
    "ReleasesAction.REQUEST_POST_TO_INGROOVES_INITIATE_TOKEN_FINISHED";

  static REQUEST_POST_FINAL_RELEASE =
    "ReleasesAction.REQUEST_POST_FINAL_RELEASE";
  static REQUEST_POST_FINAL_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_POST_FINAL_RELEASE_FINISHED";

  static REQUEST_WITHDRAW_RELEASE_FROM_REVIEW =
    "ReleasesAction.REQUEST_WITHDRAW_RELEASE_FROM_REVIEW";
  static REQUEST_WITHDRAW_RELEASE_FROM_REVIEW_FINISHED =
    "ReleasesAction.REQUEST_WITHDRAW_RELEASE_FROM_REVIEW_FINISHED";

  static REJECT_REVIEW = "ReleasesAction.REJECT_REVIEW";
  static REJECT_REVIEW_FINISHED = "ReleasesAction.REJECT_REVIEW_FINISHED";

  static REQUEST_DELETE_DRAFT_RELEASE =
    "ReleasesAction.REQUEST_DELETE_DRAFT_RELEASE";
  static REQUEST_DELETE_DRAFT_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_DELETE_DRAFT_RELEASE_FINISHED";

  static REQUEST_POST_DSP_ID = "ReleasesAction.REQUEST_POST_DSP_ID";
  static REQUEST_POST_DSP_ID_FINISHED =
    "ReleasesAction.REQUEST_POST_DSP_ID_FINISHED";

  static REQUEST_RELEASE_EXISTINGTRACKS =
    "ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS";
  static REQUEST_RELEASE_EXISTINGTRACKS_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS_FINISHED";

  static REQUEST_UPDATE_TO_DELIVERED =
    "ReleasesAction.REQUEST_UPDATE_TO_DELIVERED";
  static REQUEST_UPDATE_TO_DELIVERED_FINISHED =
    "ReleasesAction.REQUEST_UPDATE_TO_DELIVERED_FINISHED";

  static REQUEST_UPDATE_TO_INGEST = "ReleasesAction.REQUEST_UPDATE_TO_INGEST";
  static REQUEST_UPDATE_TO_INGEST_FINISHED =
    "ReleasesAction.REQUEST_UPDATE_TO_INGEST_FINISHED";

  // METHODS
  static requesReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASES,
        ReleasesEffect.requesReleases,
        params
      );
    };
  }

  static requestChangeRequestReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_CHANGE_REQUEST_RELEASES,
        ReleasesEffect.requestChangeRequestReleases,
        params
      );
    };
  }

  static requesTop5DeliveredReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES,
        ReleasesEffect.requesTop5DeliveredReleases,
        params
      );
    };
  }

  static requesTop5DraftReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_TOP_5_DRAFT_RELEASES,
        ReleasesEffect.requesTop5DraftReleases,
        params
      );
    };
  }

  static requesDraftReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_DRAFT_RELEASES,
        ReleasesEffect.requesDraftReleases,
        params
      );
    };
  }

  static requesReleasesShadow(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASES_SHADOW,
        ReleasesEffect.requesReleasesShadow,
        params
      );
    };
  }

  static requestDeletedraftRelease(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_DELETE_DRAFT_RELEASE,
        ReleasesEffect.requestDeletedraftRelease,
        id
      );
    };
  }

  static requesReleaseClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_CLEAR,
        ReleasesEffect.requesReleaseClear
      );
    };
  }

  static requesReleaseFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_FILTER_CLEAR,
        ReleasesEffect.requesReleaseFilterClear
      );
    };
  }

  static requestReleaseFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_FILTER,
        ReleasesEffect.requestReleaseFilter,
        params
      );
    };
  }

  static requestDraftReleaseFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_DRAFT_RELEASE_FILTER,
        ReleasesEffect.requestDraftReleaseFilter,
        params
      );
    };
  }
  static requestPutRelease(data, id, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_PUT_RELEASE,
        ReleasesEffect.requestPutRelease,
        data,
        id,
        params,
        callback
      );
    };
  }

  // DRAFT RELEASES UPDATE
  static requestPutDraftRelease(data, id, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_PUT_DRAFT_RELEASE,
        ReleasesEffect.requestPutDraftRelease,
        data,
        id,
        callBack
      );
    };
  }

  static requestPutChangeRequestRelease(data, id, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_PUT_CHANGE_REQUEST_RELEASE,
        ReleasesEffect.requestPutChangeRequestRelease,
        data,
        id,
        callBack
      );
    };
  }

  static requestPostRelease(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_POST_RELEASE,
        ReleasesEffect.requestPostRelease,
        data,
        callBack
      );
    };
  }

  static requestPostToInitiateToken(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_POST_TO_INGROOVES_INITIATE_TOKEN,
        ReleasesEffect.requestPostToInitiateToken,
        data,
        callBack
      );
    };
  }

  static requestReleaseById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_BY_ID,
        ReleasesEffect.requestReleaseById,
        id,
        params,
        callBack
      );
    };
  }
  static requestChangeRequestsReleaseById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_CHANGE_REQUESTS_RELEASE_BY_ID,
        ReleasesEffect.requestChangeRequestsReleaseById,
        id,
        params,
        callBack
      );
    };
  }

  static requestFinalReleaseState(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_FINAL_RELEASE_STATE,
        ReleasesEffect.requestFinalReleaseState,
        id
      );
    };
  }

  static requestDraftReleaseById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_DRAFT_RELEASE_BY_ID,
        ReleasesEffect.requestDraftReleaseById,
        id,
        params,
        callBack
      );
    };
  }

  static requestDraftReleaseByIdClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_DRAFT_RELEASE_BY_ID_CLEAR,
        ReleasesEffect.requestDraftReleaseByIdClear
      );
    };
  }

  static requestpostToDsp(id, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_POST_TO_INGROOVES,
        ReleasesEffect.requestpostToDsp,
        id,
        callBack
      );
    };
  }

  static requestPostFinalRelease(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_POST_FINAL_RELEASE,
        ReleasesEffect.requestPostFinalRelease,
        data,
        id,
        params
      );
    };
  }

  static RequestWithdrawReleaseFromReview(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_WITHDRAW_RELEASE_FROM_REVIEW,
        ReleasesEffect.RequestWithdrawReleaseFromReview,
        id,
        params
      );
    };
  }

  //Review Again
  static rejectRelease(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REJECT_REVIEW,
        ReleasesEffect.rejectRelease,
        data,
        id
      );
    };
  }

  // Map DSP ID
  static requestPostDSPID(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_POST_DSP_ID,
        ReleasesEffect.requestPostDSPID,
        params,
        callBack
      );
    };
  }

  // Calling Release By ID method
  static requestReleaseExistingTrack(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS,
        ReleasesEffect.requestReleaseExistingTrack,
        id,
        params,
        callBack
      );
    };
  }

  // UPdate release to delivered status
  static requestUpdateToDelivered(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_UPDATE_TO_DELIVERED,
        ReleasesEffect.requestUpdateToDelivered,
        id
      );
    };
  }

  static requestUpdateToIngest(id, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_UPDATE_TO_INGEST,
        ReleasesEffect.requestUpdateToIngest,
        id,
        data
      );
    };
  }
}
