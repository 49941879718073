import BaseReducer from "../../../utilities/BaseReducer";
import ClientAction from "./ClientAction";

// reducer function
export default class ClientFilterReducer extends BaseReducer {
  initialState = {
    clientFilter: [],
  };

  [ClientAction.REQUEST_CLIENT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      clientFilter: action.payload,
    };
  }
}
