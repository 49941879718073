import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import "./cssFiles/OverAllMentionsPaltformBased.css";
import { endDate, monthNames, startDate } from "./dateFunctions/DateFunctions";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

function TotalMentions(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [today, setToday] = useState(new Date());

  // this month
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);

  let before_previous_month_start_date = startDate(before_previous_month);

  function platformName(platformId) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformId)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let rows = [];

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);

      await fetchData(
        before_previous_month_start_date,
        current_month_end_date
      ).then((e) => {
        setReportData(e);
      });

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setToday(new Date());

        setLoading(true);

        await fetchData(
          before_previous_month_start_date,
          current_month_end_date
        ).then((e) => {
          setReportData(e);
        });

        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if ((props.openDateFilter, props.filterClicked)) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);

        await fetchData(
          new Date(firstDateString),
          new Date(endDateString)
        ).then((e) => {
          setReportData(e);
        });

        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(from, to) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });
  }

  let monthList = [];
  reportData &&
    reportData.ticket_report &&
    reportData.ticket_report.platform &&
    reportData.ticket_report.platform.length > 0 &&
    reportData.ticket_report.platform[0].monthwise?.buckets.length > 0 &&
    reportData.ticket_report.platform[0].monthwise?.buckets.map((month) => {
      monthList.push(month.key_as_string);
    });

  function returnMonthName(monthString) {
    let dateObj = new Date(monthString);
    let options = { year: "numeric", month: "short" };
    let formattedDate = dateObj.toLocaleDateString("default", options);
    return formattedDate;
  }

  function convertDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastSecondFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // Set the date to the first day of the previous month

  const lastThirdFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    1
  ); // Set the date to the first day of the previous month

  let currentMonth = convertDate(thisMonth);
  let lastSecond = convertDate(lastSecondFirstDay);
  let lastThird = convertDate(lastThirdFirstDay);

  // const formattedDate =

  let FirstMonth = {};
  let secondMonth = {};
  let thirdMonth = {};
  let TotalMonth = {};
  let TotalNegative = {};
  let TotalPercentage = {};

  reportData &&
    reportData.ticket_report &&
    reportData.ticket_report.platform &&
    reportData.ticket_report.platform.map((item) => {
      if (Object.values(item).includes(currentMonth)) {
        FirstMonth = item;
      }
      if (Object.values(item).includes(lastSecond)) {
        secondMonth = item;
      }
      if (Object.values(item).includes(lastThird)) {
        thirdMonth = item;
      }

      if (Object.keys(item).includes(currentMonth, lastSecond, lastThird)) {
        TotalMonth = item;
      }

      if (Object.keys(item).includes("total_negative")) {
        TotalNegative = item;
      }

      if (Object.keys(item).includes("total_percentage")) {
        TotalPercentage = item;
      }
    });

  function TotalData(TotalValue, date) {
    const filteredEntry = Object.entries(TotalValue).find(
      ([key]) => key === date
    );

    return filteredEntry && filteredEntry[1];
  }

  const noDataFoundBoll =
    (!loading && reportData && reportData?.status === "Failed") ||
    (!loading &&
      reportData &&
      reportData.ticket_report &&
      reportData.ticket_report.platform.length !== 0 &&
      Object.keys(reportData.ticket_report.platform[0]).length === 0);

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      minWidth: 150,
      flex: 2,
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {params.row.platforms}
        </p>
      ),
    },
  ];

  monthList.length > 0 &&
    monthList.map((month) => {
      return columns.push({
        field: returnMonthName(month),
        headerName: (
          <div
            style={{
              lineHeight: "23px",
              width: "100%",
              fontSize: "14px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {returnMonthName(month)}
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "14px",

                justifyContent: "space-around",
              }}
            >
              Total | (-ve)
            </div>
          </div>
        ),
        resizable: true,
        align: "center",
        headerAlign: "center",
        // flex: 2,
        minWidth: 150,
        flex: 2,

        renderCell: (params) => {
          return params.field === `${returnMonthName(lastThird)}` ? (
            params.row.platforms !== "Total" ? (
              <p className=" p-0 m-0">
                <span>
                  {prefixZero_for_singleDigit(
                    params.row[`${returnMonthName(lastThird)}Pos`]
                  )}
                </span>{" "}
                | (
                <span>
                  {prefixZero_for_singleDigit(
                    params.row[`${returnMonthName(lastThird)}Neg`]
                  )}
                </span>
                )
              </p>
            ) : (
              <p className=" p-0 m-0">
                <span className="font-weight-bold p-0 m-0">
                  {prefixZero_for_singleDigit(
                    params.row[`${returnMonthName(lastThird)}Pos`]
                  )}
                </span>{" "}
                | (
                <span className="font-weight-bold p-0 m-0">
                  {prefixZero_for_singleDigit(
                    params.row[`${returnMonthName(lastThird)}Neg`]
                  )}
                </span>
                )
              </p>
            )
          ) : params.field === `${returnMonthName(lastSecond)}` ? (
            <p
              className={`${
                params.row.platforms == "Total"
                  ? "font-weight-bold p-0 m-0"
                  : "font-weight-normal p-0 m-0"
              }`}
            >
              <span>
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(lastSecond)}Pos`]
                )}
              </span>{" "}
              | (
              <span>
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(lastSecond)}Neg`]
                )}
              </span>
              )
            </p>
          ) : (
            <p
              className={`${
                params.row.platforms == "Total"
                  ? "font-weight-bold p-0 m-0"
                  : "font-weight-normal p-0 m-0"
              }`}
            >
              <span>
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(currentMonth)}Pos`]
                )}
              </span>{" "}
              | (
              <span>
                {prefixZero_for_singleDigit(
                  params.row[`${returnMonthName(currentMonth)}Neg`]
                )}
              </span>
              )
            </p>
          );
        },
      });
    });

  columns.push({
    field: "cumulative",
    headerName: (
      <div
        style={{
          lineHeight: "23px",
          width: "100%",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Cumulative
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",

            justifyContent: "space-around",
          }}
        >
          Total | (-ve)
        </div>
      </div>
    ),
    align: "center",
    headerAlign: "center",
    resizable: true,
    minWidth: 150,
    flex: 2,
    renderCell: (params) => (
      <p className={"font-weight-bold p-0 m-0"}>
        <span>{prefixZero_for_singleDigit(params.row.cumulativeTotal)}</span> |
        (
        <span>{prefixZero_for_singleDigit(params.row.cumulativeNegative)}</span>
        )
      </p>
    ),
  });

  currentMonth &&
    columns.push({
      field: `${new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })}1`,

      headerName: (
        <div
          style={{
            lineHeight: "23px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
            }}
          >
            {new Date(currentMonth).toLocaleDateString("en-US", {
              month: "short",
              year: "numeric",
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              fontSize: "14px",
            }}
          >
            Avg Negative %
          </div>
        </div>
      ),

      resizable: true,
      minWidth: 150,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          <span>{params.row.negative_percentage}</span>%
        </p>
      ),
    });

  if (reportData?.ticket_report?.platform) {
    reportData?.ticket_report.platform.map((item, index) => {
      if (item.platform) {
        const lastThirdKeyN = `${returnMonthName(lastThird)}Neg`; // Construct the dynamic key
        const lastThirdCountN =
          item.monthwise.buckets[0]?.postive_and_negative_sentiment.buckets[0]
            ?.doc_count;

        const lastSecondKeyN = `${returnMonthName(lastSecond)}Neg`; // Construct the dynamic key
        const lastSecondCountN =
          item.monthwise.buckets[1]?.postive_and_negative_sentiment.buckets[0]
            ?.doc_count;

        const currentMonthKeyN = `${returnMonthName(currentMonth)}Neg`; // Construct the dynamic key
        const currentMonthCountN =
          item.monthwise.buckets[2]?.postive_and_negative_sentiment?.buckets[0]
            ?.doc_count ?? 0;

        const lastThirdKeyP = `${returnMonthName(lastThird)}Pos`; // Construct the dynamic key

        const lastThirdCountP = item?.monthwise.buckets[0]?.doc_count;

        const lastSecondKeyP = `${returnMonthName(lastSecond)}Pos`; // Construct the dynamic key
        const lastSecondCountP = item?.monthwise.buckets[1]?.doc_count;

        const currentMonthKeyP = `${returnMonthName(currentMonth)}Pos`; // Construct the dynamic key
        const currentMonthCountP = item?.monthwise.buckets[2]?.doc_count ?? 0;

        const row = {
          id: index + 1,
          platforms: Object.keys(item).includes("overall_total")
            ? "Total"
            : platformName(item.platform),
          cumulativeTotal: item.doc_count,
          cumulativeNegative: item.negative_count,
          negative_percentage: item.negative_percentage,
        };

        row[lastThirdKeyN] = lastThirdCountN;
        row[lastSecondKeyN] = lastSecondCountN;
        row[currentMonthKeyN] = currentMonthCountN;
        row[lastThirdKeyP] = lastThirdCountP;
        row[lastSecondKeyP] = lastSecondCountP;
        row[currentMonthKeyP] = currentMonthCountP;

        rows.push(row);
      } else {
        const lastThirdKeyAT = `${returnMonthName(lastThird)}Pos`; // Construct the dynamic key
        // Safely access reportData and perform the filtering
        const platformData = reportData?.ticket_report?.platform?.filter(
          (single) => {
            return single && Object.keys(single).includes("overall_total");
          }
        );

        // Check if platformData exists and has at least one item before accessing
        const lastThirdCountAT =
          platformData?.length > 0 ? Object.values(platformData[0])[0] : null;

        const lastSecondKeyAT = `${returnMonthName(lastSecond)}Pos`; // Construct the dynamic key
        const lastSecondCountAT =
          reportData?.ticket_report.platform.filter((single) => {
            return Object.keys(single).includes("overall_total");
          })[0] &&
          Object.values(
            reportData?.ticket_report.platform.filter((single) => {
              return Object.keys(single).includes("overall_total");
            })[0]
          )[1];

        const currentMonthKeyAT = `${returnMonthName(currentMonth)}Pos`; // Construct the dynamic key
        const currentMonthCountAT =
          reportData?.ticket_report.platform.filter((single) => {
            return Object.keys(single).includes("overall_total");
          })[0] &&
          Object.values(
            reportData?.ticket_report.platform.filter((single) => {
              return Object.keys(single).includes("overall_total");
            })[0]
          )[2];

        const lastThirdKeyAN = `${returnMonthName(lastThird)}Neg`; // Construct the dynamic key
        const lastThirdCountAN = reportData?.ticket_report?.platform?.filter(
          (single) => single.key === lastThird
        )[0]
          ? reportData.ticket_report.platform.filter(
              (single) => single.key === lastThird
            )[0] &&
            Object.values(
              reportData.ticket_report.platform.filter(
                (single) => single.key === lastThird
              )[0]
            )[1]
          : 0; // Fallback to 0 if no matching object is found

        const lastSecondKeyAN = `${returnMonthName(lastSecond)}Neg`; // Construct the dynamic key
        const lastSecondCountAN = reportData?.ticket_report?.platform?.filter(
          (single) => single.key === lastSecond
        )[0]
          ? Object.values(
              reportData.ticket_report.platform.filter(
                (single) => single.key === lastSecond
              )[0]
            )[1]
          : 0; // Fallback to 0 if no matching object is found

        const currentMonthKeyAN = `${returnMonthName(currentMonth)}Neg`; // Construct the dynamic key
        const currentMonthCountAN = reportData?.ticket_report?.platform?.filter(
          (single) => single.key === currentMonth
        )[0]
          ? Object.values(
              reportData.ticket_report.platform.filter(
                (single) => single.key === currentMonth
              )[0]
            )[1]
          : 0; // Fallback to 0 if no matching object is found

        let total_count =
          reportData?.ticket_report.platform[
            reportData?.ticket_report.platform.length - 2
          ];

        const row = {
          id: index + 1,
          platforms: Object.keys(item).includes("overall_total")
            ? "Total"
            : platformName(item.platform),
          cumulativeTotal: item.overall_total,
          cumulativeNegative: total_count.total_negative,
          negative_percentage:
            reportData?.ticket_report.platform[
              reportData?.ticket_report.platform.length - 1
            ].total_percentage,
        };

        row[lastThirdKeyAT] = lastThirdCountAT;
        row[lastThirdKeyAN] = lastThirdCountAN;
        row[lastSecondKeyAT] = lastSecondCountAT;
        row[lastSecondKeyAN] = lastSecondCountAN;
        row[currentMonthKeyAT] = currentMonthCountAT;
        row[currentMonthKeyAN] = currentMonthCountAN;

        rows.push(row);
      }
    });
  }

  const pinnedRows = {
    bottom: [rows[rows.length - 1]],
  };

  const temp_rows = [...rows.splice(0, rows.length - 5)];

  useEffect(() => {
    if (props.DownloadClicked) {
      const returnMonth = (input) => {
        let response;

        if (input == 0) {
          response = lastThird;
        } else if (input == 1) {
          response = lastSecond;
        } else if (input == 2) {
          response = currentMonth;
        }
        return response;
      };
      const exportData = [];

      temp_rows?.forEach((item) => {
        const dataObject = {
          platforms: Array.isArray(item.platforms)
            ? item.platforms[0]
            : item.platforms, // Include the platforms key
        };

        // Add keys dynamically using the monthList array
        monthList.forEach((month, index) => {
          dataObject[
            `${returnMonthName(month)} Total | (-ve)`
          ] = `${prefixZero_for_singleDigit(
            item[`${returnMonthName(returnMonth(index))}Pos`]
          )} |${prefixZero_for_singleDigit(
            item[`${returnMonthName(returnMonth(index))}Neg`]
          )}`; // Use the platform value, or null if unavailable
        });

        dataObject["Cumulative Total | (-ve)"] = `${prefixZero_for_singleDigit(
          item.cumulativeTotal
        )} | ${prefixZero_for_singleDigit(item.cumulativeNegative)}`;

        dataObject[
          `${returnMonthName(monthList[monthList?.length - 1])} Avg Negative %`
        ] = `${item.negative_percentage}%`;

        exportData.push(dataObject);
      });

      props.setexcelData(exportData);
    }
  }, [temp_rows, props.DownloadClicked]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading && props.dataGridHeight ? (
        <div
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: theme.table.borderRadius,
          }}
        >
          {!noDataFoundBoll && !loading ? (
            <div
              style={{
                width: "100%",
                textAlign: "left",
                border: `1px solid ${theme.table.border}`,
              }}
              border="2"
            >
              {reportData &&
              reportData.ticket_report &&
              reportData.ticket_report.platform &&
              reportData.ticket_report.platform.length > 0 ? (
                <Box>
                  {props.dataGridHeight?.current && rows && (
                    <DataGridFunction
                      rows={temp_rows}
                      columns={columns}
                      rowBorder={false}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={80}
                      onCellClick={onCellClick}
                      rowColor={true}
                      headerColor={true}
                      pinnedRows={pinnedRows}
                    />
                  )}
                </Box>
              ) : (
                <NoDataFound />
              )}
            </div>
          ) : (
            !loading && <NoDataFound />
          )}
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default TotalMentions;
