import BaseReducer from "../../../../utilities/BaseReducer";
import DsrActivityGroupAction from "./DsrActivityGroupAction";

export default class DsrActivityGroupReducer extends BaseReducer {
  initialState = {
    dsrActivityGroup: [],
  };
  [DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      dsrActivityGroup: action.payload,
    };
  }
}
