import React from "react";
import Box from "@mui/material/Box";
import { Switch } from "@mui/material";
import { fieldToTextField } from "formik-material-ui";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";

//SWITCH/ TOGGLE BUTTON

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 22,
  height: 12,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(9.5px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "green" : "#2a5e6c",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 9,
    height: 8,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#797676" : "gray",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function SwitchButton({ size, onChange, fieldValue, ...props }) {
  const {
    form: { setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ mx: 3, mt: 1.5 }}
            {...props}
            onChange={(value, item) => {
              setFieldValue(name, item);
            }}
          />
        }
        // name = {name}
      />
    </FormGroup>
    // <Box sx={{ display: "flex", alignItems: "center" }}>
    //   <Switch
    //     {...props}
    //     onChange={(value, item) => {
    //       setFieldValue(name, item);
    //     }}
    //   />
    // </Box>
  );
}
