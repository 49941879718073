import ActionUtility from "../../../../utilities/ActionUtility";
import OpportunityEffect from "./OpportunityEffect";

export default class OpportunityAction {
  // OpportunityAction action with an function declaration
  static REQUEST_OPPORTUNITY = "OpportunityAction.REQUEST_OPPORTUNITY";
  static REQUEST_OPPORTUNITY_FINISHED =
    "OpportunityAction.REQUEST_OPPORTUNITY_FINISHED";

  static REQUEST_OPPORTUNITY_FILTER =
    "OpportunityAction.REQUEST_OPPORTUNITY_FILTER";
  static REQUEST_OPPORTUNITY_FILTER_FINISHED =
    "OpportunityAction.REQUEST_OPPORTUNITY_FILTER_FINISHED";

  static REQUEST_PUT_OPPORTUNITY = "OpportunityAction.REQUEST_PUT_OPPORTUNITY";
  static REQUEST_PUT_OPPORTUNITY_FINISHED =
    "OpportunityAction.REQUEST_PUT_OPPORTUNITY_FINISHED";

  static REQUEST_POST_OPPORTUNITY =
    "OpportunityAction.REQUEST_POST_OPPORTUNITY";
  static REQUEST_POST_OPPORTUNITY_FINISHED =
    "OpportunityAction.REQUEST_POST_OPPORTUNITY_FINISHED";

  static REQUEST_OPPORTUNITY_CLEAR =
    "OpportunityAction.REQUEST_OPPORTUNITY_CLEAR";
  static REQUEST_OPPORTUNITY_CLEAR_FINISHED =
    "OpportunityAction.REQUEST_OPPORTUNITY_CLEAR_FINISHED";

  static REQUEST_OPPORTUNITY_FILTER_CLEAR =
    "OpportunityAction.REQUEST_OPPORTUNITY_FILTER_CLEAR";
  static REQUEST_OPPORTUNITY_FILTER_CLEAR_FINISHED =
    "OpportunityAction.REQUEST_OPPORTUNITY_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_OPPORTUNITY =
    "OpportunityAction.REQUEST_DELETE_OPPORTUNITY";
  static REQUEST_DELETE_OPPORTUNITY_FINISHED =
    "OpportunityAction.REQUEST_DELETE_OPPORTUNITY_FINISHED";

  // METHODS
  // Opportunity GET function
  static requesOpportunity(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OpportunityAction.REQUEST_OPPORTUNITY,
        OpportunityEffect.requesOpportunity,
        params
      );
    };
  }

  static requesOpportunityClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OpportunityAction.REQUEST_OPPORTUNITY_CLEAR,
        OpportunityEffect.requesOpportunityClear
      );
    };
  }

  static requesOpportunityFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OpportunityAction.REQUEST_OPPORTUNITY_FILTER_CLEAR,
        OpportunityEffect.requesOpportunityFilterClear
      );
    };
  }

  static requestOpportunityFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OpportunityAction.REQUEST_OPPORTUNITY_FILTER,
        OpportunityEffect.requestOpportunityFilter,
        params
      );
    };
  }
  static requestPutOpportunity(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OpportunityAction.REQUEST_PUT_OPPORTUNITY,
        OpportunityEffect.requestPutOpportunity,
        data,
        id
      );
    };
  }

  static requestPostOpportunity(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OpportunityAction.REQUEST_POST_OPPORTUNITY,
        OpportunityEffect.requestPostOpportunity,
        data
      );
    };
  }

  static requestDeleteOpportunity(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OpportunityAction.REQUEST_DELETE_OPPORTUNITY,
        OpportunityEffect.requestDeleteOpportunity,
        id
      );
    };
  }
}
