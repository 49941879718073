import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeDataExtractionAction from "./YoutubeDataExtractionAction";

export default class YoutubeDataExtractionReducer extends BaseReducer {
  initialState = {
    youtubeDataExtraction: [],
  };

  [YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeDataExtraction: action.payload,
    };
  }

  [YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeDataExtraction: action.payload,
    };
  }
}
