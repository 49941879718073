import { BaseModel } from "sjs-base-model";
import UserRoleMappingDataHitsModel from "./UserRoleMappingDataHitsModel";

//UserRoleMappingHitsModel
export default class UserRoleMappingHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ UserRoleMappingDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
