import ActionUtility from "../../../../utilities/ActionUtility";
import SurveyDataEffect from "./SurveyDataEffect";

export default class SurveyDataAction {
  // SurveyDataAction action with an function declaration
  static REQUEST_SURVEY_DATA = "SurveyDataAction.REQUEST_SURVEY_DATA";
  static REQUEST_SURVEY_DATA_FINISHED =
    "SurveyDataAction.REQUEST_SURVEY_DATA_FINISHED";

  static REQUEST_SURVEY_DATA_FILTER =
    "SurveyDataAction.REQUEST_SURVEY_DATA_FILTER";
  static REQUEST_SURVEY_DATA_FILTER_FINISHED =
    "SurveyDataAction.REQUEST_SURVEY_DATA_FILTER_FINISHED";

  static REQUEST_PUT_SURVEY_DATA = "SurveyDataAction.REQUEST_PUT_SURVEY_DATA";
  static REQUEST_PUT_SURVEY_DATA_FINISHED =
    "SurveyDataAction.REQUEST_PUT_SURVEY_DATA_FINISHED";

  static REQUEST_POST_SURVEY_DATA = "SurveyDataAction.REQUEST_POST_SURVEY_DATA";
  static REQUEST_POST_SURVEY_DATA_FINISHED =
    "SurveyDataAction.REQUEST_POST_SURVEY_DATA_FINISHED";

  // METHODS
  // Organization GET function
  static requestSurveyData(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyDataAction.REQUEST_SURVEY_DATA,
        SurveyDataEffect.requestSurveyData,
        params
      );
    };
  }

  static requestSurveyDataFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyDataAction.REQUEST_SURVEY_DATA_FILTER,
        SurveyDataEffect.requestSurveyDataFilter,
        params
      );
    };
  }
  static requestPutSurveyData(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyDataAction.REQUEST_PUT_SURVEY_DATA,
        SurveyDataEffect.requestPutSurveyData,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostSurveyData(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyDataAction.REQUEST_POST_SURVEY_DATA,
        SurveyDataEffect.requestPostSurveyData,
        data
      );
    };
  }
}
