import { BaseModel } from "sjs-base-model";
import InstagramDmDataHitsModel from "./InstagramDmDataHitsModel";

//InstagramDmHitsModel
export default class InstagramDmHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ InstagramDmDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
