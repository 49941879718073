import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faShield,
  faTicket,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../views/App";

const PrivacyPolicy = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
      <DialogContent className="p-0 m-0">
        <DialogContentText>
          <Box
            // className="bg-warning"
            sx={{
              borderBottom: "1px solid #c0bfbf",
            }}
          >
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              justifyContent="space-between"
              alignItems={"center"}
              //   className="bg-warning"
            >
              <Grid item px={1} sx={{ display: "flex" }}>
                <Typography>
                  <FontAwesomeIcon
                    icon={faShield}
                    style={{
                      color: `${theme.palette.ticketListView.iconColor}`,
                    }}
                  ></FontAwesomeIcon>
                  &nbsp;&nbsp;
                  <span style={{ color: "#f79f8a" }}>P</span>
                  <span>rivacy</span>
                  &nbsp;&nbsp;
                  <span style={{ color: "#f79f8a" }}>P</span>
                  <span>olicy</span>
                </Typography>
              </Grid>
              <Grid item px={1}>
                <IconButton
                  onClick={() => {
                    props.onClose();
                    // setPrivacyPolicyOpen(false);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{
                      // color: `${theme.palette.ticketListView.iconColor}`,
                      fontSize: "20px",
                    }}
                  ></FontAwesomeIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>

        <Box p={5}>
          <Typography>UnderDevelopment...!</Typography>
          <Typography>Please contact Administrator.</Typography>
        </Box>
        {/* <DialogActions></DialogActions> */}
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyPolicy;
