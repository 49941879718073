import ActionUtility from "../../../../utilities/ActionUtility";
import DsrActivityEffect from "./DsrActivityEffect";

export default class DsrActivityAction {
  // DsrActivityAction action with an function declaration
  static REQUEST_POST_DSR_ACTIVITY =
    "DsrActivityAction.REQUEST_POST_DSR_ACTIVITY";
  static REQUEST_POST_DSR_ACTIVITY_FINISHED =
    "DsrActivityAction.REQUEST_POST_DSR_ACTIVITY_FINISHED";

  static REQUEST_GET_DSR_ACTIVITY =
    "DsrActivityAction.REQUEST_GET_DSR_ACTIVITY";
  static REQUEST_GET_DSR_ACTIVITY_FINISHED =
    "DsrActivityAction.REQUEST_GET_DSR_ACTIVITY_FINISHED";

  static REQUEST_GET_DSR_KANBAN_ACTIVITY =
    "DsrActivityAction.REQUEST_GET_DSR_KANBAN_ACTIVITY";
  static REQUEST_GET_DSR_KANBAN_ACTIVITY_FINISHED =
    "DsrActivityAction.REQUEST_GET_DSR_KANBAN_ACTIVITY_FINISHED";

  static REQUEST_GET_TODAY_DSR_ACTIVITY =
    "DsrActivityAction.REQUEST_GET_TODAY_DSR_ACTIVITY";
  static REQUEST_GET_TODAY_DSR_ACTIVITY_FINISHED =
    "DsrActivityAction.REQUEST_GET_TODAY_DSR_ACTIVITY_FINISHED";

  static REQUEST_PUT_DSR_ACTIVITY =
    "DsrActivityAction.REQUEST_PUT_DSR_ACTIVITY";
  static REQUEST_PUT_DSR_ACTIVITY_FINISHED =
    "DsrActivityAction.REQUEST_PUT_DSR_ACTIVITY_FINISHED";

  static REQUEST_GET_RECENT_DSR_ACTIVITY =
    "DsrActivityAction.REQUEST_GET_RECENT_DSR_ACTIVITY";
  static REQUEST_GET_RECENT_DSR_ACTIVITY_FINISHED =
    "DsrActivityAction.REQUEST_GET_RECENT_DSR_ACTIVITY_FINISHED";

  static REQUEST_PUSH_NOTIFICATION =
    "DsrActivityAction.REQUEST_PUSH_NOTIFICATION";
  static REQUEST_PUSH_NOTIFICATION_FINISHED =
    "DsrActivityAction.REQUEST_PUSH_NOTIFICATION_FINISHED";

  // METHODS
  static requestGetDsrActivity(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityAction.REQUEST_GET_DSR_ACTIVITY,
        DsrActivityEffect.requestGetDsrActivity,
        params,
        callback
      );
    };
  }

  static requestGetDsrKanbanActivity(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityAction.REQUEST_GET_DSR_KANBAN_ACTIVITY,
        DsrActivityEffect.requestGetDsrKanbanActivity,
        params,
        callback
      );
    };
  }

  static requestGetTodayDsrActivity(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityAction.REQUEST_GET_TODAY_DSR_ACTIVITY,
        DsrActivityEffect.requestGetTodayDsrActivity,
        params,
        callback
      );
    };
  }

  static requestPostDsrActivity(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityAction.REQUEST_POST_DSR_ACTIVITY,
        DsrActivityEffect.requestPostDsrActivity,
        data,
        params,
        callback
      );
    };
  }

  static requestPutDsrActivity(data, id, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityAction.REQUEST_PUT_DSR_ACTIVITY,
        DsrActivityEffect.requestPutDsrActivity,
        data,
        id,
        callBack
      );
    };
  }

  static requestGetRecentDsrActivity(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityAction.REQUEST_GET_RECENT_DSR_ACTIVITY,
        DsrActivityEffect.requestGetRecentDsrActivity,
        params,
        callback
      );
    };
  }

  static requestPushNotification(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityAction.REQUEST_PUSH_NOTIFICATION,
        DsrActivityEffect.requestPushNotification,
        data
      );
    };
  }
}
