import ActionUtility from "../../../../utilities/ActionUtility";
import WebsiteLinksEffect from "./WebsiteLinksEffect";

export default class WebsiteLinksAction {
  // WebsiteLinksAction with an function declaration
  static REQUEST_WEBSITE_LINKS = "WebsiteLinksAction.REQUEST_WEBSITE_LINKS";
  static REQUEST_WEBSITE_LINKS_FINISHED =
    "WebsiteLinksAction.REQUEST_WEBSITE_LINKS_FINISHED";

  static REQUEST_WEBSITE_LINKS_FILTER =
    "WebsiteLinksAction.REQUEST_WEBSITE_LINKS_FILTER";
  static REQUEST_WEBSITE_LINKS_FILTER_FINISHED =
    "WebsiteLinksAction.REQUEST_WEBSITE_LINKS_FILTER_FINISHED";

  static REQUEST_PUT_WEBSITE_LINKS =
    "WebsiteLinksAction.REQUEST_PUT_WEBSITE_LINKS";
  static REQUEST_PUT_WEBSITE_LINKS_FINISHED =
    "WebsiteLinksAction.REQUEST_PUT_WEBSITE_LINKS_FINISHED";

  static REQUEST_POST_WEBSITE_LINKS =
    "WebsiteLinksAction.REQUEST_POST_WEBSITE_LINKS";
  static REQUEST_POST_WEBSITE_LINKS_FINISHED =
    "WebsiteLinksAction.REQUEST_POST_WEBSITE_LINKS_FINISHED";

  // METHODS
  // requestWebsiteLinks GET function
  static requestWebsiteLinks(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsiteLinksAction.REQUEST_WEBSITE_LINKS,
        WebsiteLinksEffect.requestWebsiteLinks,
        params
      );
    };
  }

  static requestWebsiteLinksFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsiteLinksAction.REQUEST_WEBSITE_LINKS_FILTER,
        WebsiteLinksEffect.requestWebsiteLinksFilter,
        params
      );
    };
  }
  static requestWebsistePutLinks(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsiteLinksAction.REQUEST_PUT_WEBSITE_LINKS,
        WebsiteLinksEffect.requestWebsistePutLinks,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestWebsitePostLinks(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WebsiteLinksAction.REQUEST_POST_WEBSITE_LINKS,
        WebsiteLinksEffect.requestWebsitePostLinks,
        data
      );
    };
  }
}
