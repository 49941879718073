import ActionUtility from "../../../../utilities/ActionUtility";
import SurveyEffect from "./SurveyEffect";

export default class SurveyAction {
  // Survey action with an function declaration
  static REQUEST_SURVEY = "SurveyAction.REQUEST_SURVEY";
  static REQUEST_SURVEY_FINISHED = "SurveyAction.REQUEST_SURVEY_FINISHED";

  // Survey action with an function declaration
  static REQUEST_SURVEY_FILTER = "SurveyAction.REQUEST_SURVEY_FILTER";
  static REQUEST_SURVEY_FILTER_FINISHED =
    "SurveyAction.REQUEST_SURVEY_FILTER_FINISHED";

  //   Survey post
  static REQUEST_POST_SURVEY = "SurveyAction.REQUEST_POST_SURVEY";
  static REQUEST_POST_SURVEY_FINISHED =
    "SurveyAction.REQUEST_POST_SURVEY_FINISHED";

  // Survey put
  static REQUEST_PUT_SURVEY = "SurveyAction.REQUEST_PUT_SURVEY";
  static REQUEST_PUT_SURVEY_FINISHED =
    "SurveyAction.REQUEST_PUT_SURVEY_FINISHED";

  // Survey delete
  static REQUEST_DELETE_SURVEY = "SurveyAction.REQUEST_DELETE_SURVEY";
  static REQUEST_DELETE_SURVEY_FINISHED =
    "SurveyAction.REQUEST_DELETE_SURVEY_FINISHED";

  // METHODS
  // Survey GET function
  static requestSurvey(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyAction.REQUEST_SURVEY,
        SurveyEffect.requestSurvey,
        params
      );
    };
  }

  // Survey filter
  static requestSurveyFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyAction.REQUEST_SURVEY_FILTER,
        SurveyEffect.requestSurveyFilter,
        params
      );
    };
  }

  //   static requestProjectFinished() {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         SurveyAction.REQUEST_PROJECT_FINISHED,
  //         SurveyEffect.requestProjectFinished
  //       );
  //     };
  //   }

  // Survey post function
  static requestPostSurvey(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyAction.REQUEST_POST_SURVEY,
        SurveyEffect.requestPostSurvey,
        data
      );
    };
  }

  // Survey put function
  static requestPutSurvey(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyAction.REQUEST_PUT_SURVEY,
        SurveyEffect.requestPutSurvey,
        data,
        id
      );
    };
  }

  // Survey delete function
  static requestDeleteSurvey(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyAction.REQUEST_DELETE_SURVEY,
        SurveyEffect.requestDeleteSurvey,
        id
      );
    };
  }
}
