import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ClientBulkPutModel from "../client/model/ClientBulkPutModel";
import ClientPostModel from "../client/model/ClientPostModel";
import BlogsModel from "./model/BlogsModel";
import EmailsGetModel from "./model/EmailsGetModel";

// BlogsEffect
export default class BlogsEffect {
  // calling an API according to the plan model
  static async requestBlogs(params) {
    const endpoint = environment.api.blogs;
    return EffectUtility.getToModel(BlogsModel, endpoint, params);
  }

  static async requestClientEmailAddress(params) {
    const endpoint = environment.api.get_client_email_address;
    return EffectUtility.getToModel(EmailsGetModel, endpoint, params);
  }

  static async requestConfiguredEmailAddress(params) {
    const endpoint = environment.api.get_configured_email_address;
    return EffectUtility.getToModel(EmailsGetModel, endpoint, params);
  }

  // Get method for the plan filter
  static async requestBlogsFilter(params) {
    const endpoint = environment.api.blogs;
    return EffectUtility.getToModel(BlogsModel, endpoint, params);
  }

  static async requestBlogsClear() {
    return {};
  }

  static async requestPostBlogs(data) {
    const endpoint = environment.api.blogs + "/add";
    return EffectUtility.postToModel(ClientPostModel, endpoint, data);
  }

  static async requestPostMusicLabelsBlogsMail(data, params, id) {
    const endpoint = environment.api.musiclabels_blog_mail + "/" + id;
    return EffectUtility.postToModel(ClientPostModel, endpoint, data, params);
  }

  static async requestPutBlogs(data, id) {
    const endpoint = environment.api.blogs + "/" + id;
    return EffectUtility.putToModel(ClientBulkPutModel, endpoint, data);
  }
}
