import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";
import TicketWorkedOnCountModel from "./TicketWorkedOnCountModel";

export default class TicketWorkedOnModel extends BaseModel {
  //Ticket Worked On model

  agent = boolean;
  data = {};
  constructor(data1) {
    super();

    this.update(data1);
  }
}
