import BaseReducer from "../../../utilities/BaseReducer";
import ResolvedTicketAction from "./ResolvedTicketAction";

export default class ResolvedTicketReducer extends BaseReducer {
  //initial state of Ticket resolved.
  initialState = {
    ticketResolved: [],
  };

  //Ticket resolved request action finish
  [ResolvedTicketAction.REQUEST_RESOLVEDTICKET_FINISHED](state, action) {
    return {
      ...state,
      ticketResolved: action.payload,
    };
  }
}
