import { BaseModel } from "sjs-base-model";

//Tickets list source data model
export default class LastProjectSourceModel extends BaseModel {
    created_by = {
        user_id :"",
        user_name:""
    }
    created_on = "";
    modified_by = {}
    modified_on = "";
    project = {
        project_id :"",
        project_name:""
    }
    user_id = "" ;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
