import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";

//map state to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, []),
});

const TagUpdateDailog = (props) => {
  const initial = {
    tags:
      props?.ticketData?._source && props.ticketData._source?.tags
        ? props.ticketData._source.tags
        : "",
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const tagsHandleChange = (event, newValue) => {
    if (newValue.length === 0) {
      setTags([]);
      return;
    }
    const selectedOption = newValue[newValue.length - 1];
    const isPresent = tags.some((obj) => obj === selectedOption);
    let updatedValue = [];
    if (isPresent) {
      updatedValue = tags.filter((obj) => obj !== selectedOption);
    } else {
      updatedValue = [...tags, selectedOption];
    }
    setTags(updatedValue);
  };

  useEffect(() => {
    if (props?.ticketData?._source?.tags) {
      const tagNames = props.ticketData._source.tags.map((tag) => ({
        tag_id: tag.tag_id,
        tag_name: tag.tag_name,
      }));
      setTags(tagNames);
    }
  }, [props?.ticketData?._source?.tags]);

  const handleDeleteTags = (tag) => {
    const updatedTags = tags.filter((obj) => obj.tag_id !== tag.tag_id);
    setTags(updatedTags);
  };

  const onCloseFunction = () => {
    props.onClose();
    // setTags([]);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        onCloseFunction();
      }}
      fullWidth
      maxWidth="sm"
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <DialogContent
        className=" p-0 m-0"
        sx={
          {
            // border: "2px solid #c0bfbf",
          }
        }
      >
        <DialogContentText>
          <Box
            width="100%"
            // className="bg-success"
            sx={{
              height: "fit-content",
            }}
          >
            <Box
            // className="bg-warning"
            // sx={{
            //   borderBottom: "1px solid #c0bfbf",
            // }}
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                // className="bg-warning"
              >
                <Grid item px={1} sx={{ display: "flex" }}>
                  <Typography>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>U</span>
                    <span>pdate</span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>T</span>
                    <span>ags</span>
                    &nbsp;&nbsp;
                  </Typography>
                </Grid>
                <Grid item px={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                      //   setTags([]);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{
                        // color: `${theme.palette.ticketListView.iconColor}`,
                        fontSize: "20px",
                      }}
                    ></FontAwesomeIcon>
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            <Box width={"80%"} margin="auto" padding={5} height={"auto"}>
              <Formik
                initialValues={initial}
                enableReinitialize
                onSubmit={(values) => {
                  setLoading(true);
                  const post_obj = {};
                  Object.assign(
                    post_obj,

                    tags && {
                      tags: tags.map((tag) => ({
                        tag_id: tag.tag_id,
                        tag_name: tag.tag_name,
                      })),
                    }
                  );
                  // ticket PUT dispatch action
                  props
                    .dispatch(
                      TicketsAction.requestPutTicket(
                        JSON.stringify(post_obj),
                        props.ticketData._id
                      )
                    )
                    .then(() => {
                      setLoading(false);

                      props.onClose();
                      //   if (
                      //     props.ticketData._source.status.status_id ===
                      //     values.status.status_id
                      //   ) {
                      props.fetchFunction(true);
                      //   } else {
                      //     props.fetchFunction(false);
                      //   }
                    });
                }}
                validateOnBlur={true}
                validateOnChange
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  submitForm,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      // justifyContent="space-around"
                      justifyContent="space-between"
                      alignItems="center"
                      // className="bg-warning"
                    >
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        justifyContent="flex-start"
                        alignItems={"flex-end"}
                        mb={2}
                      >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          {/* <span
                            className="p-0 m-0 font-weight-bold"
                            style={{ fontSize: 12 }}
                          >
                            Tags
                          </span> */}
                        </Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <AutoCompleteComponent
                            width="100%"
                            value={tags}
                            name="tags"
                            textFieldProps={{
                              placeholder:
                                tags && tags.length !== 0 ? null : "Tag Name",
                            }}
                            list={
                              props.filterTagList ? props.filterTagList : []
                            }
                            handleChange={tagsHandleChange}
                            multiple
                            disableCloseOnSelect={true}
                            getOptionLabel={(option) =>
                              option.tag_name ? option.tag_name : ""
                            }
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  label={option.tag_name}
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    my: 0.5,
                                    mx: 0.5,
                                  }}
                                  onDelete={() => handleDeleteTags(option)}
                                />
                              ))
                            }
                            renderOption={(props, option) => {
                              const isPresent = tags.some(
                                (obj) => obj.tag_id === option.tag_id
                              );

                              return (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={isPresent}
                                  />
                                  {option.tag_name}
                                </li>
                              );
                            }}
                            size="small"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        justifyContent="center"
                        alignItems={"center"}
                        // className="bg-warning"
                      >
                        <MuiButton
                          variant="contained"
                          type="submit"
                          name={"Update"}
                          loading={loading}
                          noTextOnLoading={true}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps)(TagUpdateDailog);
