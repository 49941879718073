import React, { useEffect, useState } from "react";
import { Box, IconButton, Grid } from "@mui/material";
import { connect } from "react-redux";
import { Form, Formik, Field, FieldArray, ErrorMessage, getIn } from "formik";
import * as yup from "yup";
import MuiButton from "../../../components/muiButton/MuiButton";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
// import { Cron } from "react-js-cron";
// import "react-js-cron/dist/styles.css";
// import "antd/dist/antd.css";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ShadowContainer } from "../../../components/componentLayout/ComponentLayout";
import DateRangePickerFormik from "../../../components/dateRangePickerComponent/DateRangePickerFormik";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import PlatformSchedulerAction from "../../stores/platformScheduler/PlatformSchedulerAction";
import { format } from "date-fns";
import CronGenerator from "react-cron-generator";

const mapDispatchToProps = (dispatch) => {
  return {};
};

function SchedulerPostForm(props) {
  let initial = {};

  if (props.edit) {
    initial = {
      group: props.editData._source.group,
      platform: "",
      platform_account: "",
      job_type: "",
      cron: "",
      date: [null, null],
      project_id: localStorage.getItem("user_last_working_project")
        ? JSON.parse(localStorage.getItem("user_last_working_project"))
            .project_id
        : "",
      orm_platform_scheduling_id: props.editData._id,
    };
  } else {
    initial = {
      group: "",
      platform:
        props.platforms && props.platforms.length === 1
          ? props.platforms[0]
          : "",
      platform_account: "",
      job_type: "",
      cron: "",
      date: [null, null],
      project_id: localStorage.getItem("user_last_working_project")
        ? JSON.parse(localStorage.getItem("user_last_working_project"))
            .project_id
        : "",
    };
  }

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("30 5 * * 1,6");

  const schema = yup.object().shape({
    cron: yup
      .string()
      .test("valid-cron", "Invalid cron expression", (value) => {
        if (!value) {
          return true; // If the value is empty, we consider it valid.
        }

        try {
          // cronstrue.toString(value); // This will throw an error if the expression is invalid.
          return true;
        } catch (error) {
          return false;
        }
      }),
  });

  const validationSchema = yup.object().shape({
    cron: yup.string().required("Cron value is required"),
    // Add more fields and their validation rules as needed
  });

  return (
    <Grid
      container
      xs={12}
      md={12}
      lg={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item lg={6} md={6} sm={6} xs={6} textAlign="left">
        <IconButton
          onClick={() => {
            props.onClose();
          }}
        >
          <ArrowCircleLeftIcon
            style={{
              height: "45px",
              width: "45px",
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Grid>
      <Box width="80%" mb={3}>
        <Grid container xl={12} justifyContent="center" alignItems="center">
          <Grid item xl={11} textAlign="center">
            <h3 className="dailogtext">
              {!props.edit ? (
                <b>
                  <span className="span_first_letter">&nbsp;&nbsp;S</span>
                  chedule&nbsp;{" "}
                  <span className="span_first_letter">a &nbsp;</span>
                  <span className="span_first_letter">T</span>
                  ask
                </b>
              ) : (
                <b>
                  <span className="span_first_letter">&nbsp;&nbsp;E</span>
                  dit &nbsp;
                  <span className="span_first_letter">S</span>
                  cheduling &nbsp;
                  <span className="span_first_letter">T</span>
                  ask
                </b>
              )}
            </h3>
          </Grid>
        </Grid>
      </Box>
      <Grid container xs={12} lg={12}>
        <Formik
          initialValues={initial}
          validationSchema={validationSchema}
          validate={(values) => {
            var errors = {};
            if (!values.group) {
              errors.group = "Group name is required";
            }

            if (!values.platform) {
              errors.platform = "Platform is required";
            }

            if (!values.platform_account) {
              errors.platform_account = "Platform account is required";
            }

            if (!values.job_type) {
              errors.job_type = "Job type is required";
            }

            if (!values.date[0] || !values.date[1]) {
              errors.date = "Date Range is required";
            }

            if (
              values.cron &&
              values.cron
                .substring(
                  values.cron.indexOf(" ") + 1,
                  values.cron.lastIndexOf(" ")
                )
                .split(" ")
                .includes("")
            ) {
              errors.cron = "Cron value is not valid";
            }
            return errors;
          }}
          onSubmit={(values, formikHelpers) => {
            let date_format = [];

            if (values.date[0]) {
              date_format.push(values.date[0].$d);
            }

            if (values.date[1]) {
              date_format.push(values.date[1].$d);
            }

            let params = {};

            const cronValue = values.cron ? values.cron : "";

            // Find the index of the first space
            const firstSpaceIndex = cronValue.indexOf(" ");

            // Find the index of the last space
            const lastSpaceIndex = cronValue.lastIndexOf(" ");

            // Remove the character before the first space
            const modifiedCronValue = cronValue.substring(
              firstSpaceIndex + 1,
              lastSpaceIndex
            );

            Object.assign(
              params,
              values.platform && {
                task_id: values.platform.platform_name,
              },
              values.cron && {
                cron: modifiedCronValue,
              },
              values.platform_account && {
                account_id: values.platform_account.account_id,
              },
              values.job_type.job_type && {
                job_type:
                  values.job_type.job_type === "Direct Message"
                    ? "Direct_Message"
                    : values.job_type.job_type,
              },
              values.date[0] && {
                start_time: date_format[0]
                  ? format(date_format[0], "yyyy-MM-dd HH:mm:ss")
                  : values.date[0],
              },
              values.date[1] && {
                end_time: date_format[1]
                  ? format(date_format[1], "yyyy-MM-dd HH:mm:ss")
                  : values.date[1],
              },
              values.group && {
                group: values.group,
              },
              values.project_id && {
                project_id: values.project_id,
              },
              values.orm_platform_scheduling_id && {
                orm_platform_scheduling_id: values.orm_platform_scheduling_id,
              }
            );

            props
              .dispatch(
                PlatformSchedulerAction.requestPlatformTaskScheduling(params)
              )
              .then(() => {
                let param = {
                  page_limit: props.page_limit,
                  order_by: '[["created_on", "desc"]]',
                };
                props.dispatch(
                  PlatformSchedulerAction.requestPlatformScheduling(param)
                );
                props.onClose();
              });
          }}
        >
          {({
            errors,
            isValid,
            helperText,
            touched,
            dirty,
            handleChange,
            values,
            resetForm,
            setFieldValue,
            handleBlur,
          }) => (
            <Grid xs={12} lg={12}>
              <Form>
                <Grid container xs={12} lg={12}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    mt={1}
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      container
                      lg={4}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item lg={3} md={3} sm={3} mt={1}>
                        Group * :
                      </Grid>
                      <Grid item lg={9} md={9} sm={9}>
                        <Field
                          as={MuiTextField}
                          autoFocus
                          disabled={props.edit ? true : false}
                          variant="outlined"
                          placeholder="Group"
                          id="group"
                          size="small"
                          name="group"
                          error={
                            Boolean(errors.group) && Boolean(touched.group)
                          }
                          helperText={Boolean(touched.group) && errors.group}
                          className="col-10"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    mt={1}
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // maxHeight: 500,
                      // overflowY: "scroll",
                    }}
                  >
                    <Grid container lg={10} md={12} sm={12} mt={1}>
                      <Grid
                        container
                        lg={12}
                        md={12}
                        sm={12}
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                        // key={index}
                        border="1px solid lightGrey"
                        bgcolor={"white"}
                        borderRadius={5}
                        p={1}
                      >
                        <Grid
                          item
                          xl={11}
                          lg={11}
                          md={12}
                          sm={12}
                          container
                          // sx={{ backgroundColor: "yellow" }}
                        >
                          <Grid item xl={4} lg={12} md={12} sm={12} container>
                            <Grid item lg={3.5} md={3.5} sm={3.5} mt={3}>
                              Platform * :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Field
                                name={`platform`}
                                size="small"
                                disableClearable={true}
                                // Calling AutoCompleteMultiSelect Component
                                component={AutoCompleteMultiSelect}
                                value={values.platform ? values.platform : null}
                                getOptionLabel={(option) =>
                                  option.platform_alias
                                    ? option.platform_alias
                                    : ""
                                }
                                disabled={
                                  props.platforms &&
                                  props.platforms.length === 1
                                    ? true
                                    : false
                                }
                                options={props.platforms ? props.platforms : []}
                                onChange={(_, value) => {
                                  setFieldValue(`platform`, value);
                                  setFieldValue(`platform_account`, "");
                                  setFieldValue(`job_type`, "");
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  //setting value property to custom value
                                  option.platform_alias === value.platform_alias
                                }
                                textFieldProps={{
                                  margin: "normal",
                                  variant: "outlined",
                                  placeholder: "Platform",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={5} container>
                            <Grid item lg={3} md={3} sm={3} mt={3}>
                              Account * :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Field
                                name={`platform_account`}
                                size="small"
                                disableClearable={true}
                                // Calling AutoCompleteMultiSelect Component
                                value={
                                  values.platform_account
                                    ? values.platform_account
                                    : null
                                }
                                component={AutoCompleteMultiSelect}
                                options={
                                  props.all_accounts
                                    ? props.all_accounts.filter(
                                        (item) =>
                                          item.platform ===
                                          values.platform.platform_name
                                      )
                                    : []
                                }
                                getOptionLabel={(option) =>
                                  option.account_name ? option.account_name : ""
                                }
                                isOptionEqualToValue={(value, option) =>
                                  option.account_id === value.account_id
                                }
                                textFieldProps={{
                                  margin: "normal",
                                  variant: "outlined",
                                  placeholder: "Account",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xl={4} lg={4} md={5} sm={5} container>
                            <Grid item lg={3.5} md={3.5} sm={3.5} mt={3}>
                              Job Type * :
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Field
                                name={`job_type`}
                                size="small"
                                disableClearable={true}
                                // Calling AutoCompleteMultiSelect Component
                                component={AutoCompleteMultiSelect}
                                value={values.job_type ? values.job_type : null}
                                getOptionLabel={(option) =>
                                  option.job_type ? option.job_type : ""
                                }
                                isOptionEqualToValue={(value, option) =>
                                  option.job_type_id === value.job_type_id
                                }
                                options={
                                  values.platform !== ""
                                    ? props.jobTypeList
                                      ? props.jobTypeList.filter(
                                          (item) =>
                                            item.platform ===
                                            values.platform.platform_name
                                        )
                                      : []
                                    : []
                                }
                                textFieldProps={{
                                  margin: "normal",
                                  variant: "outlined",
                                  placeholder: "Job Type",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            lg={12}
                            justifyContent="flex-start"
                          >
                            <Grid item lg={6} md={12} sm={12} mt={1} container>
                              <Grid item lg={3} md={3} sm={3} mt={1}>
                                Date Range * :
                              </Grid>
                              <Grid item lg={9} md={9} sm={9}>
                                <Field
                                  component={DateRangePickerFormik}
                                  size={"small"}
                                  value={
                                    values.date ? values.date : [null, null]
                                  }
                                  name={`date`}
                                  label={false}
                                  textFieldProps={{
                                    fullWidth: true,
                                    margin: "normal",
                                    variant: "outlined",
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid item lg={6} md={12} sm={12} mt={1} container>
                              <Grid item lg={3} md={3} sm={3} mt={1}>
                                Cron * :
                              </Grid>
                              <Grid item lg={9} md={9} sm={9}>
                                {/* <Cron
                                  value={values.cron}
                                  setValue={(val) => {
                                    setFieldValue(`cron`, val);
                                  }}
                                /> */}

                                <Field
                                  component={CronGenerator}
                                  name="cron"
                                  onChange={(value) =>
                                    setFieldValue("cron", value)
                                  }
                                  // value={values.cron}
                                  onBlur={handleBlur("cron")}
                                  showTabs={false} // Hide the tabs for seconds and years
                                  showQuartzCron={false} // Hide the Quartz cron expression
                                  allowEmpty={false} // Don't allow empty cron value
                                  cronProps={{
                                    // Limit allowed fields to minutes, hours, day of the month, month, and day of the week
                                    allowMinutes: false,
                                    allowHours: false,
                                    allowDaysOfMonth: false,
                                    allowMonths: false,
                                    allowDaysOfWeek: false,
                                    allowYears: false, // Disable years
                                  }}
                                />

                                {touched.cron && errors.cron && (
                                  <ErrorMessage name="cron" component="div" />
                                )}

                                {/* <CronGenerator
                                  value={values.cron}
                                  onChange={(value) =>
                                    setFieldValue("cron", value)
                                  }
                                  onBlur={handleBlur("cron")}
                                  showTabs={false} // Hide the tabs for seconds and years
                                  showQuartzCron={false} // Hide the Quartz cron expression
                                  allowEmpty={false} // Don't allow empty cron value
                                  cronProps={{
                                    // Limit allowed fields to minutes, hours, day of the month, month, and day of the week
                                    allowMinutes: false,
                                    allowHours: false,
                                    allowDaysOfMonth: false,
                                    allowMonths: false,
                                    allowDaysOfWeek: false,
                                    allowYears: false, // Disable years
                                  }}
                                />
                                {touched.cron && errors.cron && (
                                  <div>{errors.cron}</div>
                                )} */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Grid item lg={2} md={5} sm={5} mr={2} minWidth={150}>
                    {!props.edit ? (
                      <MuiButton
                        type="submit"
                        name="Add Configuraton"
                        width="100%"
                        loading={loading}
                      />
                    ) : (
                      <MuiButton
                        type="submit"
                        name="Submit"
                        width="100%"
                        loading={loading}
                      />
                    )}
                  </Grid>
                  <Grid item lg={2} md={5} sm={5} minWidth={150}>
                    {!props.edit ? (
                      <MuiButton
                        type="reset"
                        name="Reset"
                        // onClick={() => handleReset(resetForm)}
                        width="100%"
                      />
                    ) : (
                      <MuiButton
                        name={"Cancel"}
                        width="100%"
                        onClick={() => {
                          props.onClose();
                          // resetForm();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(SchedulerPostForm);
