import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// SurveyDataDModel
export default class SurveyDataDModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = {
    user_id: "",
    user_name: "",
  };
  modified_on = "";
  customer_email_id = "";
  customer_mobile_number = "";
  customer_name = "";
  survey = {
    survey_id: "",
    survey_name: "",
  };
  survey_data = [];

  constructor(data) {
    super();

    this.update(data);
  }
}
