import BaseReducer from "../../../utilities/BaseReducer";
import YoutubeAction from "./YoutubeAction";

export default class YoutubeFeedReducer extends BaseReducer {
  initialState = {
    youtubeFeed: [],
  };

  [YoutubeAction.REQUEST_YOUTUBE_FINISHED](state, action) {
    return {
      ...state,
      youtubeFeed: action.payload,
    };
  }

  [YoutubeAction.REQUEST_YOUTUBE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      youtubeFeed: action.payload,
    };
  }
}
