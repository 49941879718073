import { BaseModel } from "sjs-base-model";
import TicketsSourceModel from "./TicketsSourceModel";
import { boolean } from "yup";

export default class TicketByIdModel extends BaseModel {
  //ticket list hits model
  _id = "";
  _index = "";
  _primary_term = "";
  _seq_no = 0;
  _source = {};
  _version = 0;
  found = boolean;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
