import BaseReducer from "../../../../utilities/BaseReducer";
import ReportUploadAction from "./ReportUploadAction";

export default class CsvUploadSummaryReducer extends BaseReducer {
  initialState = {
    csvUploadSummary: [],
  };
  [ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY_FINISHED](state, action) {
    return {
      ...state,
      csvUploadSummary: action.payload,
    };
  }
}
