import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import RedditFeedModel from "./models/RedditFeedModel";
import RedditPutModel from "./models/RedditFeedPutModel";

export default class RedditFeedEffect {
  static async requestRedditFeed(params, callBackFunction) {
    const endpoint = environment.api.reddit;

    let response = EffectUtility.getToModel(RedditFeedModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestRedditPosts(params, callBackFunction) {
    const endpoint = environment.api.redditPosts;

    let response = EffectUtility.getToModel(RedditFeedModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestRedditComments(params, callBackFunction) {
    const endpoint = environment.api.redditComments;

    let response = EffectUtility.getToModel(RedditFeedModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestRedditFeedClear() {
    return {};
  }

  static async requestRedditPostsClear() {
    return {};
  }

  static async requestRedditCommentsClear() {
    return {};
  }

  static async requestPutRedditFeed(data, id) {
    const endpoint = environment.api.reddit + "/" + id;
    return EffectUtility.putToModel(RedditPutModel, endpoint, data);
  }
}
