import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import environment from "environment";

function LinkedInCallback(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    // let token;
    // if (localStorage.getItem("Auth") !== null) {
    //   token = JSON.parse(localStorage.getItem("Auth")).access_token;
    // }

    // let url = `${environment.api.baseApi}/linkedin_auth?code=${searchParams.get(
    //   "code"
    // )}`;

    // axios
    //   .get(url, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //     },
    //   })
    //   .then((res) => {
    //     navigate("/addAccount");
    //   })
    //   .catch((res) => {
    //     alert(res && res.response.data.message);
    //     navigate("/addAccount");
    //   });

    let query = JSON.parse(sessionStorage.getItem("linkedIncallback"));

    if (query) {
      const urlParams = new URLSearchParams(query?.searchQuery);

      // Extract values by parameter names
      const stateValue = urlParams.get("state");
      const codeValue = urlParams.get("code");

      let token;
      if (localStorage.getItem("Auth") !== null) {
        token = JSON.parse(localStorage.getItem("Auth")).access_token;
      }

      let url = `${environment.api.baseApi}/linkedin_auth?code=${codeValue}`;

      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          navigate("/addAccount");
        })
        .catch((res) => {
          alert(res && res.response.data.message);
          navigate("/addAccount");
        });
    }
  }, []);
}
export default LinkedInCallback;
