import BaseReducer from "../../../utilities/BaseReducer";
import QuoraFeedsAction from "./QuoraFeedsAction";

export default class QuoraFeedsFilterReduser extends BaseReducer {
  //initial state of QuoraFeedsFilterReduser
  initialState = {
    quoraFeedFilter: [],
  };
  //QuoraFeedsFilterReduser request action finish
  [QuoraFeedsAction.REQUEST_QUORA_FEED_FILTER_FINISHED](state, action) {
    return {
      ...state,
      quoraFeedFilter: action.payload,
    };
  }
}
