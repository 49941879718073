import ActionUtility from "../../../utilities/ActionUtility";
import EmailFeedsEffect from "./EmailFeedsEffect";

// email api fetch model
export default class EmailFeedsAction {
  // EmailFeedsAction  with an function declaration
  static REQUEST_EMAIL_FEEDS = "EmailFeedsAction.REQUEST_EMAIL_FEEDS";
  static REQUEST_EMAIL_FEEDS_FINISHED =
    "EmailFeedsAction.REQUEST_EMAIL_FEEDS_FINISHED";

  // Email filter
  static REQUEST_EMAIL_FEEDS_FILTER =
    "EmailFeedsAction.REQUEST_EMAIL_FEEDS_FILTER";
  static REQUEST_EMAIL_FEEDS_FILTER_FINISHED =
    "EmailFeedsAction.REQUEST_EMAIL_FEEDS_FILTER_FINISHED";

  // Email Post
  static REQUEST_EMAIL_FEEDS_POST = "EmailFeedsAction.REQUEST_EMAIL_FEEDS_POST";
  static REQUEST_EMAIL_FEEDS_POST_FINISHED =
    "EmailFeedsAction.REQUEST_EMAIL_FEEDS_POST_FINISHED";

  // Post Email Email
  static REQUEST_POST_SEND_EMAIL = "EmailFeedsAction.REQUEST_POST_SEND_EMAIL";
  static REQUEST_POST_SEND_EMAIL_FINISHED =
    "EmailFeedsAction.REQUEST_POST_SEND_EMAIL_FINISHED";

  static REQUEST_EMAIL_FEEDS_CLEAR =
    "EmailFeedsAction.REQUEST_EMAIL_FEEDS_CLEAR";
  static REQUEST_EMAIL_FEEDS_CLEAR_FINISHED =
    "EmailFeedsAction.REQUEST_EMAIL_FEEDS_CLEAR_FINISHED";

  static REQUEST_EMAIL_FEEDS_PUT = "EmailFeedsAction.REQUEST_EMAIL_FEEDS_PUT";
  static REQUEST_EMAIL_FEEDS_PUT_FINISHED =
    "EmailFeedsAction.REQUEST_EMAIL_FEEDS_PUT_FINISHED";

  // METHODS
  // Email GET function
  static requestEmailFeeds(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailFeedsAction.REQUEST_EMAIL_FEEDS,
        EmailFeedsEffect.requestEmailFeeds,
        params
      );
    };
  }

  static requestEmailFeedsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailFeedsAction.REQUEST_EMAIL_FEEDS_CLEAR,
        EmailFeedsEffect.requestEmailFeedsClear
      );
    };
  }

  static requestEmailFeedsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailFeedsAction.REQUEST_EMAIL_FEEDS_FILTER,
        EmailFeedsEffect.requestEmailFeedsFilter,
        params
      );
    };
  }

  static requestEmailFeedsPost(id, data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailFeedsAction.REQUEST_EMAIL_FEEDS_POST,
        EmailFeedsEffect.requestEmailFeedsPost,
        id,
        data,
        callback
      );
    };
  }

  static requestEmailFeedsPut(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailFeedsAction.REQUEST_EMAIL_FEEDS_PUT,
        EmailFeedsEffect.requestEmailFeedsPut,
        data,
        id
      );
    };
  }

  static requestPostSendEmail(id, data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EmailFeedsAction.REQUEST_POST_SEND_EMAIL,
        EmailFeedsEffect.requestPostSendEmail,
        id,
        data,
        callback
      );
    };
  }
}
