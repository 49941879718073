import { Component } from "react";
import ActionUtility from "../../../../utilities/ActionUtility";
import TwitterDirectMessageEffect from "./TwitterDirectMessageEffect";

export default class TwitterDirectMessageAction extends Component {
  //TwitterDirectmessage requests
  static REQUEST_TWITTERDIRECTMESSAGE =
    "TwitterDirectMessageAction.REQUEST_TWITTERDIRECTMESSAGE";
  static REQUEST_TWITTERDIRECTMESSAGE_FINISHED =
    "TwitterDirectMessageAction.REQUEST_TWITTERDIRECTMESSAGE_FINISHED";

  //TwitterDirectmessage post
  static REQUEST_POST_TWITTERDIRECTMESSAGE =
    "TwitterDirectMessageAction.REQUEST_POST_TWITTERDIRECTMESSAGE";
  static REQUEST_POST_TWITTERDIRECTMESSAGE_FINISHED =
    "TwitterDirectMessageAction.REQUEST_POST_TWITTERDIRECTMESSAGE_FINISHED";

  static REQUEST_TWITTER_DM_CLEAR =
    "TwitterDirectMessageAction.REQUEST_TWITTER_DM_CLEAR";
  static REQUEST_TWITTER_DM_CLEAR_FINISHED =
    "TwitterDirectMessageAction.REQUEST_TWITTER_DM_CLEAR_FINISHED";

  static REQUEST_TWITTER_DM_PUT =
    "TwitterDirectMessageAction.REQUEST_TWITTER_DM_PUT";
  static REQUEST_TWITTER_DM_PUT_FINISHED =
    "TwitterDirectMessageAction.REQUEST_TWITTER_DM_PUT_FINISHED";

  //METHODS

  //TwitterDirectmessage get method
  static requestTwitterDirectMessage(params) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterDirectMessageAction.REQUEST_TWITTERDIRECTMESSAGE,
        TwitterDirectMessageEffect.requestTwitterDirectMessage,
        params
      );
    };
  }

  //TwitterDirectmessage post method
  static requestTwitterDirectMessagePost(id, params, data, callback) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterDirectMessageAction.REQUEST_POST_TWITTERDIRECTMESSAGE,
        TwitterDirectMessageEffect.requestTwitterDirectMessagePost,
        id,
        params,
        data,
        callback
      );
    };
  }

  // Clear Twitter DM
  static requestTwitterDmClear() {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterDirectMessageAction.REQUEST_TWITTER_DM_CLEAR,
        TwitterDirectMessageEffect.requestTwitterDmClear
      );
    };
  }

  static requestPutTwitterDmFeed(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterDirectMessageAction.REQUEST_TWITTER_DM_PUT,
        TwitterDirectMessageEffect.requestPutTwitterDmFeed,
        data,
        id
      );
    };
  }
}
