import React, { useEffect, useState } from "react";
import "./cssFiles/MonthlyWisePlatformReviewCounts.css";
import environment from "environment";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";

function MonthlyWisePlatformReviewCounts(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  let lastDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter);
      setLoading(false);
    })();
  }, [props.DateFilter]);

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  async function fetchData(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]&from=${
      props.DateFilter ? dateRange[0] : format(firstDay, "yyyy-MM-dd")
    }&to=${props.DateFilter ? dateRange[1] : format(lastDay, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${
    //     environment.api.ticketReports
    //   }?report_type=["count"]&reports=["platform"]&from=${
    //     props.DateFilter ? dateRange[0] : format(firstDay, "yyyy-MM-dd")
    //   }&to=${props.DateFilter ? dateRange[1] : format(lastDay, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setData(response);
    //   });
  }

  return (
    <div
      style={{
        width: "100%",
        // padding: "10px",
        //  border: "1px solid grey"
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} />

        <p
          style={{
            fontSize: theme.reports.headerSize,
            padding: theme.reports.headerPadding,
          }}
          className="reportHeader"
        >
          {firstLetterOrange(props.report && props.report)}
        </p>

        <hr className="p-0 m-0 " />

        <br />
        {window.location.pathname === "/ticketStatsReport" && (
          <Description
            description={props.description}
            callBack={props.callBack}
            report={props.report}
            index={0}
          />
        )}
        <Box
          className="platformReviewscount"
          style={{ width: "100%" }}
          sx={{
            paddingX: 5,
          }}
        >
          {data &&
            data.ticket_report.platform.map((item, index) => (
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <span style={{ fontSize: "13px" }} id="firstLetter">
                    {platformName(item.key)}
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>{item.count}</span>
                  {/* {data.ticket_report.platform.length - 1 !== index && (
                  <div id="verticalBorder"></div>
                )} */}
                  <div className="verticalBorder"></div>
                </div>
              </div>
            ))}
        </Box>
      </LoadingIndicator>
    </div>
  );
}

export default MonthlyWisePlatformReviewCounts;
