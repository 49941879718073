import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import OrmReportLogoGetModel from "./models/OrmReportLogoGetModel";

export default class OrmReportLogoEffect {
  static async requestOrmReportLogo(params) {
    const endpoint = environment.api.ormReportLogo;
    return EffectUtility.getToModel(OrmReportLogoGetModel, endpoint, params);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
