import { BaseModel } from "sjs-base-model";

export default class HssDetailsByIDGetModel extends BaseModel {
  _id = "";
  _index = "";
  _primary_term = 0;
  _seq_no = 0;
  _source = {};
  _version = 0;
  found = false;

  constructor(data) {
    super();
    this.update(data);
  }
}
