import ActionUtility from "../../../../utilities/ActionUtility";
import McnMonitoringEffect from "./McnMonitoringEffect";
export default class McnMonitoringAction {
  // McnMonitoringAction action with an function declaration
  static REQUEST_MCN_MONITORING = "McnMonitoringAction.REQUEST_MCN_MONITORING";
  static REQUEST_MCN_MONITORING_FINISHED =
    "McnMonitoringAction.REQUEST_MCN_MONITORING_FINISHED";

  static REQUEST_MCN_MONITORING_FILTER =
    "McnMonitoringAction.REQUEST_MCN_MONITORING_FILTER";
  static REQUEST_MCN_MONITORING_FILTER_FINISHED =
    "McnMonitoringAction.REQUEST_MCN_MONITORING_FILTER_FINISHED";

  static REQUEST_PUT_MCN_MONITORING =
    "McnMonitoringAction.REQUEST_PUT_MCN_MONITORING";
  static REQUEST_PUT_MCN_MONITORING_FINISHED =
    "McnMonitoringAction.REQUEST_PUT_MCN_MONITORING_FINISHED";

  static REQUEST_POST_MCN_MONITORING =
    "McnMonitoringAction.REQUEST_POST_MCN_MONITORING";
  static REQUEST_POST_MCN_MONITORING_FINISHED =
    "McnMonitoringAction.REQUEST_POST_MCN_MONITORING_FINISHED";

  // METHODS
  // Mcn GET function
  static requestMcnMonitoring(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        McnMonitoringAction.REQUEST_MCN_MONITORING,
        McnMonitoringEffect.requestMcnMonitoring,
        params
      );
    };
  }

  static requestMcnMonitoringFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        McnMonitoringAction.REQUEST_MCN_MONITORING_FILTER,
        McnMonitoringEffect.requestMcnMonitoringFilter,
        params
      );
    };
  }
  static requestPutMcnMonitoring(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        McnMonitoringAction.REQUEST_PUT_MCN_MONITORING,
        McnMonitoringEffect.requestPutMcnMonitoring,
        data,
        id
      );
    };
  }

  // Mcn Post function
  static requestPostMcnMonitoring(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        McnMonitoringAction.REQUEST_POST_MCN_MONITORING,
        McnMonitoringEffect.requestPostMcnMonitoring,
        data
      );
    };
  }
}
