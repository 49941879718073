import ActionUtility from "../../../../utilities/ActionUtility";
import InfluencersActivityEffect from "./InfluencersActivityEffect";

export default class InfluencersActivityAction {
  // InfluencersActivityAction action with an function declaration
  static REQUEST_INFLUENCERS_ACTIVITY =
    "InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY";
  static REQUEST_INFLUENCERS_ACTIVITY_FINISHED =
    "InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_FINISHED";

  static REQUEST_INFLUENCERS_ACTIVITY_FILTER =
    "InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_FILTER";
  static REQUEST_INFLUENCERS_ACTIVITY_FILTER_FINISHED =
    "InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_FILTER_FINISHED";

  static REQUEST_INFLUENCERS_ACTIVITY_REPORTS =
    "InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_REPORTS";
  static REQUEST_INFLUENCERS_ACTIVITY_REPORTS_FINISHED =
    "InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_REPORTS_FINISHED";

  static REQUEST_PUT_INFLUENCERS_ACTIVITY =
    "InfluencersActivityAction.REQUEST_PUT_INFLUENCERS_ACTIVITY";
  static REQUEST_PUT_INFLUENCERS_ACTIVITY_FINISHED =
    "InfluencersActivityAction.REQUEST_PUT_INFLUENCERS_ACTIVITY_FINISHED";

  static REQUEST_POST_INFLUENCERS_ACTIVITY =
    "InfluencersActivityAction.REQUEST_POST_INFLUENCERS_ACTIVITY";
  static REQUEST_POST_INFLUENCERS_ACTIVITY_FINISHED =
    "InfluencersActivityAction.REQUEST_POST_INFLUENCERS_ACTIVITY_FINISHED";

  // METHODS
  // InfluencersActivity GET function
  static requestInfluencersActivity(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY,
        InfluencersActivityEffect.requestInfluencersActivity,
        params
      );
    };
  }

  static requestInfluencersActivityFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_FILTER,
        InfluencersActivityEffect.requestInfluencersActivityFilter,
        params
      );
    };
  }

  static requestInfluencersActivityReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_REPORTS,
        InfluencersActivityEffect.requestInfluencersActivityReports,
        params
      );
    };
  }
  static requestPutInfluencersActivity(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersActivityAction.REQUEST_PUT_INFLUENCERS_ACTIVITY,
        InfluencersActivityEffect.requestPutInfluencersActivity,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostInfluencersActivity(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersActivityAction.REQUEST_POST_INFLUENCERS_ACTIVITY,
        InfluencersActivityEffect.requestPostInfluencersActivity,
        data
      );
    };
  }
}
