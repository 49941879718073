import ActionUtility from "../../../utilities/ActionUtility";
import GMBAccountEffect from "./GMBAccountEffect";

export default class GMBAccountAction {
  // GMBAccountAction with an function declaration
  static REQUEST_GMB_ACCOUNT = "GMBAccountAction.REQUEST_GMB_ACCOUNT";
  static REQUEST_GMB_ACCOUNT_FINISHED =
    "GMBAccountAction.REQUEST_GMB_ACCOUNT_FINISHED";

  static REQUEST_GMB_ACCOUNT_FILTER =
    "GMBAccountAction.REQUEST_GMB_ACCOUNT_FILTER";
  static REQUEST_GMB_ACCOUNT_FILTER_FINISHED =
    "GMBAccountAction.REQUEST_GMB_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_GMB_ACCOUNT = "GMBAccountAction.REQUEST_PUT_GMB_ACCOUNT";
  static REQUEST_PUT_GMB_ACCOUNT_FINISHED =
    "GMBAccountAction.REQUEST_PUT_GMB_ACCOUNT_FINISHED";

  static REQUEST_POST_GMB_ACCOUNT = "GMBAccountAction.REQUEST_POST_GMB_ACCOUNT";
  static REQUEST_POST_GMB_ACCOUNT_FINISHED =
    "GMBAccountAction.REQUEST_POST_GMB_ACCOUNT_FINISHED";

  static REQUEST_DELETE_GMB_ACCOUNT =
    "GMBAccountAction.REQUEST_DELETE_GMB_ACCOUNT";
  static REQUEST_DELETE_GMB_ACCOUNT_FINISHED =
    "GMBAccountAction.REQUEST_DELETE_GMB_ACCOUNT_FINISHED";

  // METHODS
  // GMB Get function
  static requestGMBAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GMBAccountAction.REQUEST_GMB_ACCOUNT,
        GMBAccountEffect.requestGMBAccount,
        params
      );
    };
  }

  static requestGMBAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GMBAccountAction.REQUEST_GMB_ACCOUNT_FILTER,
        GMBAccountEffect.requestGMBAccountFilter,
        params
      );
    };
  }
  static requestPutGMBAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GMBAccountAction.REQUEST_PUT_GMB_ACCOUNT,
        GMBAccountEffect.requestPutGMBAccount,
        data,
        id
      );
    };
  }

  // GMB post function
  static requestPostGMBAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GMBAccountAction.REQUEST_POST_GMB_ACCOUNT,
        GMBAccountEffect.requestPostGMBAccount,
        data
      );
    };
  }

  static requestDeleteGMBAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GMBAccountAction.REQUEST_DELETE_GMB_ACCOUNT,
        GMBAccountEffect.requestDeleteGMBAccount,
        data
      );
    };
  }
}
