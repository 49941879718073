import BaseReducer from "../../../../utilities/BaseReducer";
import TopTerritoriesAction from "./TopTerritoriesAction";

export default class TopTerritoriesFilterReducer extends BaseReducer {
  //initial state of TopTerritoriesFilterReducer
  initialState = {
    top_territories_report_filter: [],
  };
  //TopTerritoriesFilterReducer request action finish
  [TopTerritoriesAction.REQUEST_TOP_TERRITORIES_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      top_territories_report_filter: action.payload,
    };
  }
}
