import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import TurnAroundTimeModel from "./models/TurnAroundTimeModel";

export default class TurnAroundTimeEffect {
  //get ticket first response time request end point
  static async requestTurnAroundTime(params) {
    const endpoint = environment.api.ticketAvgTat;

    return EffectUtility.getToModel(TurnAroundTimeModel, endpoint, params);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
