import BaseReducer from "../../../utilities/BaseReducer";
import UserRoleMappingAction from "./UserRoleMappingAction";

// UserRoleMappingReduser  function
export default class UserRoleMappingReduser extends BaseReducer {
  initialState = {
    userRoleMapping: [],
  };
  [UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING_FINISHED](state, action) {
    return {
      ...state,
      userRoleMapping: action.payload,
    };
  }
}
