import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeDataExtractionAction from "./YoutubeDataExtractionAction";

export default class YoutubeDataExtractionFilterReducer extends BaseReducer {
  initialState = {
    youtubeDataExtractionFilter: [],
  };

  [YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeDataExtractionFilter: action.payload,
    };
  }

  [YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeDataExtractionFilter: action.payload,
    };
  }
}
