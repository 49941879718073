import React, { useEffect, useState } from "react";

import {
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import environment from "environment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import { format } from "date-fns";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import ConsumerAction from "../../../stores/consumerFeeds/ConsumerAction";
import { axiosRequest } from "../../../../utilities/FetchRequest";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  avatar: {
    // margin: 10,
    width: 45,
    height: 45,
  },
});

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [ConsumerAction.REQUEST_CONSUMER]),
  consumer_feed: state.consumer_feed.consumer_feed.hits || {},
  pagination: state.consumer_feed.consumer_feed || {},
});

function ConsumerComplaintFeeds(props) {
  const [page_limit] = useState(10);
  const { isRequesting } = props;

  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [date, setDate] = useState([null, null]);

  const [expanded, setExpanded] = useState(false);

  let maxDate = new Date();

  const classes = useStyles();

  function fetchConsumerFeeds(params) {
    props.dispatch(ConsumerAction.requestConsumer(params));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    const filter = [];
    let params = {};

    // search
    if (searchQuery !== "") {
      filter.push(
        `["description.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== null
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    }

    fetchConsumerFeeds(params);
  }, []);

  const applyFilter = () => {
    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["description.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== null
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      fetchConsumerFeeds(params);
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
      alert("Please add filters");
    }
  };

  const ClearState = () => {
    setFilterClicked(false);
    if (
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== ""
    ) {
      setSearchQuery("");
      setDate([null, null]);
      setSentimental_val(null);
      setClearLoader(true);

      const params = {
        page_limit: `${page_limit}`,
        order_by: `[["created_on","desc"]]`,
      };
      FilterClicked && fetchConsumerFeeds(params);
    }
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];
    // search
    if (searchQuery !== "") {
      filter.push(
        `["description.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== null
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        order_by: `[["created_on","desc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchConsumerFeeds(params);
    }
  };

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);
    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val(null);
    }
  };

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number"
    ) {
      if (item._source.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item._source.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item._source.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }

    return sentiment;
  };

  const handleChangeExpand = (panel) => {
    setExpanded((prevExpanded) => (prevExpanded === panel ? false : panel));
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}
      <Grid container>
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            // borderRadius: theme.palette.buttonComponent.borderRadius.large,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            mt={2}
            container
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
          >
            <Grid
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              // alignItems={"center"}
              spacing={1}
            >
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={2}>
                  <span>Search Content</span>
                </Grid>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      borderRadius:
                        theme.palette.buttonComponent.borderRadius.medium,
                      outline: `1px ${theme.palette.borderColor.textField}`,
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.typography.padding,
                      height: "35px",
                      width: "100%",
                    }}
                    id="search_bar"
                    autoComplete={"off"}
                    value={searchQuery}
                    placeholder={"Search by Content"}
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment>
                        <IconButton onClick={applyFilter}>
                          <SearchIcon
                            sx={{
                              "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentimental Value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={2} lg={3} md={12} sm={12} mt={0}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={3}
                >
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "8px", marginBottom: "8px" }} />
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.borderRadius,
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
        >
          <LoadingIndicator isActive={isRequesting}>
            {props.consumer_feed.hits ? (
              <Box width="100%">
                {props.consumer_feed.hits.length !== 0 ? (
                  props.consumer_feed.hits.map((val, index) => (
                    <Box
                      sx={{
                        padding: 0,
                        margin: "1.2%",
                      }}
                    >
                      <Accordion
                        expanded={expanded === `panel${index}`}
                        // onChange={handleChangeExpand(`panel${index}`)}
                        style={{
                          backgroundColor: "#fff",
                          height: "auto",
                          padding: 2,
                          borderRadius: theme.borderRadius,
                          border: "solid 0.5px #d3e2e6",
                          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                          cursor: "default",
                        }}
                        // className="bg-warning"
                      >
                        <AccordionSummary
                          style={{
                            cursor: "default",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleChangeExpand(`panel${index}`)
                              }
                            />
                          }
                          aria-controls={`panel${index}bh-content`}
                          id={`panel${index}bh-header`}
                        >
                          <List
                            sx={{
                              width: "100%",
                              borderRadius: theme.borderRadius,
                            }}
                          >
                            <Grid container>
                              <Grid
                                container
                                lg={12}
                                md={12}
                                sm={12}
                                alignItems="flex-start"
                              >
                                <Grid
                                  item
                                  lg={1}
                                  md={1}
                                  sm={1}
                                  alignSelf="center"
                                  maxWidth={"80px !important"}
                                >
                                  <ListItem>
                                    <ListItemAvatar>
                                      <Avatar
                                        alt="image"
                                        // src={val._source.customer_image}
                                        className={classes.avatar}
                                      />
                                    </ListItemAvatar>
                                  </ListItem>
                                </Grid>
                                <Grid item lg={10} md={9} sm={9} container>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    container
                                    direction={{
                                      lg: "row",
                                      xl: "row",
                                      md: "row",
                                      sm: "column-reverse",
                                    }}
                                  >
                                    <Grid item lg={3} md={6} sm={5.5}>
                                      <ListItem className="p-0 m-0 ml-3">
                                        {(function createdDate() {
                                          let created = val._source.created_on;
                                          let dateConversion = new Date(
                                            created
                                          );
                                          dateConversion =
                                            dateConversion.toDateString() +
                                            ", " +
                                            dateConversion.toLocaleTimeString(
                                              "en-US",
                                              {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              }
                                            );

                                          return dateConversion;
                                        })()}
                                      </ListItem>
                                    </Grid>
                                  </Grid>

                                  <Grid item lg={12} md={12} sm={12}>
                                    <ListItem className="p-0 m-0 ml-3">
                                      <ListItemText>
                                        <span
                                          style={{
                                            fontWeight: "550",
                                          }}
                                        >
                                          Title :
                                        </span>{" "}
                                        {val._source.title}
                                      </ListItemText>
                                    </ListItem>
                                  </Grid>

                                  <Grid item lg={12} md={12} sm={12}>
                                    <ListItem className="p-0 m-0 ml-3">
                                      <ListItemText>
                                        <span
                                          style={{
                                            fontWeight: "550",
                                          }}
                                        >
                                          Description :{" "}
                                        </span>
                                        {val._source.description.slice(0, 150)}
                                        ... &nbsp;
                                      </ListItemText>
                                    </ListItem>
                                  </Grid>

                                  <Grid item lg={12} md={12} sm={12}>
                                    <ListItem className="p-0 m-0 ml-3">
                                      <ListItemText>
                                        <span
                                          style={{
                                            fontWeight: "550",
                                          }}
                                        >
                                          Sentiment :{" "}
                                        </span>
                                        {val._source.sentiment_value &&
                                        typeof val._source.sentiment_value ===
                                          "number" &&
                                        val._source.sentiment_value > 0 ? (
                                          <IconButton
                                            className="p-0 m-0"
                                            title={returnSentimentname(val)}
                                          >
                                            <SentimentSatisfiedAltRoundedIcon
                                              titleAccess="Positive"
                                              style={{
                                                width: "16px",
                                                color: `green`,
                                              }}
                                            />
                                          </IconButton>
                                        ) : val._source.sentiment_value < 0 ? (
                                          <IconButton
                                            className="p-0 m-0"
                                            title={returnSentimentname(val)}
                                          >
                                            <SentimentDissatisfiedRoundedIcon
                                              titleAccess="Negative"
                                              style={{
                                                width: "16px",
                                                color: `${theme.palette.sentimentIconColor.negative}`,
                                              }}
                                            />
                                          </IconButton>
                                        ) : val._source.sentiment_value !==
                                          null ? (
                                          <IconButton
                                            className="p-0 m-0"
                                            title={returnSentimentname(val)}
                                          >
                                            <SentimentNeutralRoundedIcon
                                              titleAccess="Neutral"
                                              style={{
                                                width: "16px",
                                                color: `${theme.palette.sentimentIconColor.neutral}`,
                                              }}
                                            />
                                          </IconButton>
                                        ) : (
                                          ""
                                        )}
                                      </ListItemText>
                                    </ListItem>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  container
                                  lg={1}
                                  md={1}
                                  sm={1}
                                  alignItems={"center"}
                                  justifyContent="space-between"
                                >
                                  {val._source.ticket_id && (
                                    <Typography title="Ticket">
                                      <FontAwesomeIcon
                                        fontSize={"16px"}
                                        icon={faTicket}
                                        cursor={"pointer"}
                                        onClick={() => {
                                          localStorage.setItem("activeTab", 0);

                                          val._source.ticket_id &&
                                            openTicketFunction(
                                              val._source.ticket_id
                                            );
                                        }}
                                      ></FontAwesomeIcon>
                                    </Typography>
                                  )}

                                  {circuler_progress_id ===
                                    val._source.ticket_id &&
                                    open_ticket && (
                                      <CircularProgress
                                        color="inherit"
                                        size={15}
                                      />
                                    )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </List>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              padding: 1,
                              margin: "1.2%",
                              marginTop: 0,
                              marginBottom: "0.5%",
                              border: "solid 0.5px #d3e2e6",
                              borderRadius: theme.borderRadius,
                              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                            }}
                          >
                            <Grid container></Grid>

                            <Grid item m={2}>
                              <Typography className="text-justify">
                                <span className="font-weight-bold">
                                  Detailed Description
                                </span>{" "}
                                : {val._source.description}
                              </Typography>
                            </Grid>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </Box>
            ) : null}
          </LoadingIndicator>
        </Box>
      </Grid>
      {props.consumer_feed.hits
        ? props.consumer_feed.hits.length !== 0 && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(ConsumerComplaintFeeds);
