import environment from "environment";
import React, { useEffect, useState } from "react";

async function TranslateFunction(content) {
  try {
    const response = await fetch(
      `${
        environment.api.translateApi
      }/google_translate?text=${encodeURIComponent(content)}`
    );
    const data = await response.json();
    if (data["Translated-Content"]) {
      return data["Translated-Content"];
    } else {
      return content;
    }
  } catch (error) {
    throw new Error("Translation failed");
  }
}

export default function TranslatedContent(props) {
  const [translatedContent, setTranslatedContent] = useState("");

  async function translateContent() {
    const content = props.content;
    const translated = await TranslateFunction(content);
    setTranslatedContent(translated);
  }

  useEffect(() => {
    translateContent();
  }, [props]);
  return (
    <div style={{ overflowWrap: "anywhere" }}>
      {translatedContent && translatedContent.includes("\n")
        ? translatedContent.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))
        : translatedContent}
    </div>
  );
}
