import BaseReducer from "../../../utilities/BaseReducer";
import PlayStoreReplyAction from "./PlayStoreReplyAction";

export default class PlayStoreReplyReducer extends BaseReducer {
  //initial state of ticket list
  initialState = {
    playStoreReply: [],
  };

  //ticket request action finish
  [PlayStoreReplyAction.REQUEST_POST_PLAYSTORE_REPLY_FINISHED](state, action) {
    return {
      ...state,
      playStoreReply: action.payload,
    };
  }
}
