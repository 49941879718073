import { BaseModel } from "sjs-base-model";

export default class AiChatFeedPostModel extends BaseModel {
  choices = [];

  constructor(data) {
    super();

    this.update(data);
  }
}
