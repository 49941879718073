import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Badge from "@mui/material/Badge";
import { connect } from "react-redux";
import {
  BorderContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../../components/componentLayout/ComponentLayout";
import CircularLoadingIndicator from "../../../../../components/loading-indicator/CircularLoadingIndicator";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { returnColor } from "../../../../../components/ticketPartials";
import MuiAutocomplete from "../../../../../components/muiSelectField/MuiAutocomplete";
import { theme } from "../../../../../views/App";

function TicketStatePopper(props) {
  let { isRequesting } = props;

  const [priority, setPriority] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).priority;
    } else {
      return null;
    }
  });

  let [priorityError, setPriorityError] = useState(false);

  const [selectStatus, setSelectStatus] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).selectStatus;
    } else {
      return [];
    }
  });

  // priority callback function
  const priorityhandleChange = (event, priority) => {
    setPriority(priority);
  };

  // status callback function
  const statushandleChange = (event, status) => {
    // if (!status) {
    //   setAssigned_To(null);
    // }
    setSelectStatus(status);
  };

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    if (filters) {
      setPriority(filters.priority && filters.priority);
      setSelectStatus(filters.selectStatus && filters.selectStatus);
    }
  }, [props.open]);

  //******************SAVE FILTERS FUNCTION************************
  async function handleSaveFilters() {
    if (!priority) {
      setPriorityError(true);

      setTimeout(() => {
        setPriorityError(false);
      }, 1000);
    } else {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.priority = priority;
        filters.selectStatus = selectStatus;
        filters.pageNumber = `0`;
        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            priority,
            selectStatus,
          })
        );
      }
      props.saveFilter(filters);
      props.fetchTicketCount();
      props.onClose();
    }
  }

  function handleClearFilters() {
    if (
      priority !== null ||
      selectStatus !== null
      // selected.length !== 0
    ) {
      setPriority(null);
      setSelectStatus([]);
      props.clearFilter("priority");

      localStorage.removeItem("mentionsFilters");
    }
  }

  return (
    <Box>
      <BorderContainer>
        <ClickAwayListener onClickAway={props.handleClose}>
          <div
            autoFocusItem={props.popopen}
            id="composition-menu"
            aria-labelledby="composition-button"
          >
            <Grid
              container
              // className="bg-warning"
              // className="bg-warning"
              sx={{
                height: 250,
              }}
            >
              <Typography
                component={"p"}
                className="dashboard-title"
                sx={{ fontSize: 18 }}
              >
                Tickets Priority
              </Typography>

              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xl={3}
                  sx={{
                    animation: priorityError && "bounce 0.5s linear",
                    "@keyframes bounce": {
                      "0%, 20%, 50%, 80%, 100%": {
                        transform: "translateY(0)",
                      },
                      "40%": {
                        transform: "translateY(-30px)",
                      },
                      "60%": {
                        transform: "translateY(-15px)",
                      },
                    },
                  }}
                >
                  <AutoCompleteComponent
                    width="100%"
                    value={priority}
                    list={props.priority_list ? props.priority_list : []}
                    textFieldProps={{
                      placeholder: "Priority",
                    }}
                    renderOption={(props, option, { selected }) => (
                      <RadioGroup>
                        <FormControlLabel
                          {...props}
                          control={<Radio checked={selected} size="small" />}
                          label={option.ticket_priority_name}
                        />
                      </RadioGroup>
                    )}
                    size="small"
                    disableClearable={true}
                    getOptionLabel={(option) =>
                      option.ticket_priority_name
                        ? option.ticket_priority_name
                        : ""
                    }
                    handleChange={priorityhandleChange}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                laptop={12}
                columnGap={2}
                rowGap={1}
                sx={{
                  marginY: 5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item>
                  <MuiButton
                    variant="outlined"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Reset"}
                    loading={false}
                    backgroundColor={"white"}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleClearFilters();
                    }}
                  />
                </Grid>
                <Grid item>
                  <MuiButton
                    variant="contained"
                    width={"103px"}
                    height={"35px"}
                    type="submit"
                    name={"Apply"}
                    loading={false}
                    noTextOnLoading={true}
                    onClick={() => {
                      handleSaveFilters();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      </BorderContainer>
    </Box>
  );
}

export default TicketStatePopper;
