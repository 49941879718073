import BaseReducer from "../../../utilities/BaseReducer";
import PlatformAction from "./PlatformAction";

export default class PlatformReducer extends BaseReducer {
  //initial state of ticketType
  initialState = {
    platform: [],
  };

  //tickettype request action finish
  [PlatformAction.REQUEST_PLATFORM_FINISHED](state, action) {
    return {
      ...state,
      platform: action.payload,
    };
  }
}
