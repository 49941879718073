import React, { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { LocalizationProvider, LicenseInfo } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import "./dateRangePicker.css";
import { fieldToTextField } from "formik-material-ui";
import MuiTextField from "../muiTextField/MuiTextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const DateRangePickerFormik = forwardRef(
  ({ value, onChange, fieldValue, textFieldProps, size, ...props }) => {
    const theme = useTheme();
    const {
      form: { setTouched, setFieldValue, touched },
    } = props;
    const { error, helperText, ...field } = fieldToTextField(props);
    const { name } = field;

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateRangePicker"]}>
          <DateRangePicker
            {...props}
            {...field}
            value={
              value && [
                value[0] ? dayjs(value[0]) : null,
                value[1] ? dayjs(value[1]) : null,
              ]
            }
            onChange={(value) => {
              setFieldValue(name, value);
            }}
            onBlur={() => setTouched({ ...touched, [name]: true })}
            format="DD/MM/YYYY"
            slots={{
              textField: MuiTextField,
            }}
            slotProps={{
              textField: {
                focused: true,
                fullWidth: true,
                variant: "outlined",
                size: "small",
                label: "",
                style: {
                  outline: `1px ${theme.palette.borderColor.textField}`,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "8px",
                },
              },
            }}
          />
        </DemoContainer>
        {/* <DateRangePicker
          inputFormat="YYYY-MM-DD"
          {...props}
          {...field}
          value={value}
          onChange={(value) => {
            setFieldValue(name, value);
          }}
          onBlur={() => setTouched({ ...touched, [name]: true })}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField
                {...startProps}
                helperText={helperText}
                error={error}
                label=""
                size="small"
                sx={{
                  ".MuiOutlinedInput-root": {
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "8px",
                  },
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              />
              <Box sx={{ mx: 0.5 }}> to </Box>
              <TextField
                {...endProps}
                helperText={helperText}
                error={error}
                label=""
                size="small"
                sx={{
                  ".MuiOutlinedInput-root": {
                    outline: `1px ${theme.palette.borderColor.textField}`,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "8px",
                  },
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              />
            </React.Fragment>
          )}
        /> */}
      </LocalizationProvider>
    );
  }
);

export default DateRangePickerFormik;
