import React, { useEffect, useRef, useState } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import { Box } from "@mui/system";
import { ShadowContainer } from "../../components/componentLayout/ComponentLayout";
import {
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ChatBotAction from "../../stores/chatBot/ChatBotAction";
import { connect } from "react-redux";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ChatBotAction.REQUEST_GET_CHAT,
    ChatBotAction.REQUEST_POST_CHAT,
  ]),
  chat: state.chat.chat || [],
});

function ChatBot(props) {
  const { isRequesting } = props;
  const messageBoxRef = useRef(null);
  const sendButtonRef = useRef(null);

  const [openChatBot, setOpenChatBot] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [Load, setLoad] = React.useState(false);

  const [question, setQuestion] = useState("");

  function fetchChatMessages(params) {
    props.dispatch(ChatBotAction.requestGetChat(params));
  }

  useEffect(() => {
    let params = {
      order_by: `[["created_on","desc"]]`,
      page_limit: "none",
    };
    if (openChatBot === true) {
      fetchChatMessages(params);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [openChatBot]);

  useEffect(() => {
    const container = messageBoxRef.current;
    container?.scrollTo({
      top: container.scrollHeight,
      behavior: "smooth",
    });
  }, [Load]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenChatBot((prev) => !prev);
    setPlacement("top-end");
  };

  function returnQuestionBox(question) {
    return (
      <Box
        sx={{
          textAlign: "left",
          width: "75%",
          alignSelf: "flex-start",
          padding: 1,
          backgroundColor: theme.palette.background.light,
          borderRadius: 2,
          whiteSpace: "pre-line",
        }}
      >
        {question}
      </Box>
    );
  }

  function returnAnswerBox(answer) {
    return (
      <Box
        sx={{
          textAlign: "left",
          width: "75%",
          alignSelf: "end",
          padding: 1,
          backgroundColor: theme.palette.background.primary,
          borderRadius: 2,
          marginTop: 1,
          whiteSpace: "pre-line",
        }}
      >
        {answer}
      </Box>
    );
  }

  return (
    <Box sx={{ position: "fixed", right: 30, bottom: 30 }}>
      {/* <ShadowContainer> */}
      <Typography
        component={"div"}
        onClick={handleClick}
        sx={{ width: "100%", height: "100%" }}
      >
        <IconButton
          title={openChatBot ? "Close" : "Chat Bot"}
          sx={{
            backgroundColor: theme.palette.primary.main,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
              transform: "scale(1.1)",
            },
          }}
        >
          {openChatBot ? (
            <CancelRoundedIcon
              sx={{
                color: "#ffffff",
                cursor: "pointer",
              }}
            />
          ) : (
            <ChatIcon sx={{ color: "#ffffff", cursor: "pointer" }} />
          )}
        </IconButton>
      </Typography>
      {/* </ShadowContainer> */}

      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        open={openChatBot}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ boxShadow: theme.shadows }}>
              <Grid
                container
                sx={{
                  height: "600px",
                  width: "600px",
                }}
              >
                <Box
                  sx={{
                    width: "-webkit-fill-available",
                    height: "inherit",
                    padding: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflowY: "scroll",
                      height: "calc(100% - 50px)",
                    }}
                    ref={messageBoxRef}
                    className="scrollable"
                  >
                    {props.chat?.hits?.hits.length &&
                      props.chat?.hits?.hits.map((item) => (
                        <Grid
                          item
                          sm={12}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginY: 2,
                          }}
                        >
                          {returnQuestionBox(item._source.question)}
                          {returnAnswerBox(item._source.answer)}
                        </Grid>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "fixed",
                      bottom: 10,
                      width: "97%",
                      htight: "50px",
                    }}
                  >
                    <MuiTextField
                      placeholder="Ask something..."
                      size="small"
                      width="98%"
                      onChange={(e) => {
                        setQuestion(e.target.value);
                      }}
                      value={question}
                      onKeyPress={(event) => {
                        const { key } = event;
                        if (key === "Enter") {
                          sendButtonRef.current &&
                            sendButtonRef.current.click();
                        }
                      }}
                    />

                    <IconButton
                      sx={{ marginRight: 2 }}
                      onClick={() => {
                        let postBody = {
                          query: question,
                        };

                        setLoad(true);

                        props
                          .dispatch(
                            ChatBotAction.requestPostChat(
                              JSON.stringify(postBody)
                            )
                          )
                          .then(() => {
                            setQuestion("");

                            let params = {
                              order_by: `[["created_on","desc"]]`,
                              page_limit: "none",
                            };
                            fetchChatMessages(params);
                            setLoad(false);
                          })
                          .catch((e) => {
                            setLoad(false);
                          });
                      }}
                      ref={sendButtonRef}
                    >
                      {Load ? (
                        <CircularProgress
                          isActive={Load}
                          style={{ width: 30, height: 30 }}
                          sx={{
                            color: theme.palette.primary.main,
                          }}
                        />
                      ) : (
                        <SendRoundedIcon
                          sx={{ color: theme.palette.primary.main }}
                          style={{ width: 30, height: 30 }}
                        />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

export default connect(mapStateToProps)(ChatBot);
