import ActionUtility from "../../../utilities/ActionUtility";
import ThreadsAccountEffect from "./ThreadsAccountEffect";

export default class ThreadsAccountAction {
  //threads Account get request
  static REQUEST_THREADS_ACCOUNT =
    "ThreadsAccountAction.REQUEST_THREADS_ACCOUNT";
  static REQUEST_THREADS_ACCOUNT_FINISHED =
    "ThreadsAccountAction.REQUEST_THREADS_ACCOUNT_FINISHED";

  //Add threads account post request
  static REQUEST_POST_THREADS_ACCOUNT =
    "ThreadsAccountAction.REQUEST_POST_THREADS_ACCOUNT";
  static REQUEST_POST_THREADS_ACCOUNT_FINISHED =
    "ThreadsAccountAction.REQUEST_POST_THREADS_ACCOUNT_FINISHED";

  //threads account put requests
  static REQUEST_PUT_THREADS_ACCOUNT =
    "ThreadsAccountAction.REQUEST_PUT_THREADS_ACCOUNT";
  static REQUEST_PUT_THREADS_ACCOUNT_FINISHED =
    "ThreadsAccountAction.REQUEST_PUT_THREADS_ACCOUNT_FINISHED";

  //threads account filters
  static REQUEST_THREADS_ACCOUNT_FILTER =
    "ThreadsAccountAction.REQUEST_THREADS_ACCOUNT_FILTER";
  static REQUEST_THREADS_ACCOUNT_FILTER_FINISHED =
    "ThreadsAccountAction.REQUEST_THREADS_ACCOUNT_FILTER_FINISHED";

  static REQUEST_DELETE_THREADS_ACCOUNT =
    "ThreadsAccountAction.REQUEST_DELETE_THREADS_ACCOUNT";
  static REQUEST_DELETE_THREADS_ACCOUNT_FINISHED =
    "ThreadsAccountAction.REQUEST_DELETE_THREADS_ACCOUNT_FINISHED";

  //METHODS

  //Threads Account get method
  static requestThreadsAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ThreadsAccountAction.REQUEST_THREADS_ACCOUNT,
        ThreadsAccountEffect.requestThreadsAccount,
        params
      );
    };
  }

  //threads Account get for filter dropdown
  static requestThreadsAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ThreadsAccountAction.REQUEST_THREADS_ACCOUNT_FILTER,
        ThreadsAccountEffect.requestThreadsAccountFilter,
        params
      );
    };
  }

  //threads Account Post method
  static requestPostThreadsAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ThreadsAccountAction.REQUEST_POST_THREADS_ACCOUNT,
        ThreadsAccountEffect.requestPostThreadsAccount,
        data
      );
    };
  }

  //threads Account Put method
  static requestPutThreadsAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ThreadsAccountAction.REQUEST_PUT_THREADS_ACCOUNT,
        ThreadsAccountEffect.requestPutThreadsAccount,
        data,
        id
      );
    };
  }

  //threads Account delete method
  static requestDeleteThreadsAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ThreadsAccountAction.REQUEST_DELETE_THREADS_ACCOUNT,
        ThreadsAccountEffect.requestDeleteThreadsAccount,
        id
      );
    };
  }
}
