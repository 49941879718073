import { BaseModel } from "sjs-base-model";
import ScrapeLinksDataModel from "./ScrapeLinksDataModel";

// ScrapeLinksHitsModel
export default class ScrapeLinksHitsModel extends BaseModel {
    // response getting from the api

    _id = "";
    _index = "";
    _score = 0.0;
    _source = [ScrapeLinksDataModel];
    constructor(data) {
        super();
        this.update(data);
    }
}
