import ActionUtility from "../../../../utilities/ActionUtility";
import ProxyEffect from "./ProxyEffect";

export default class ProxyAction {
  static REQUEST_PROXY_COUNTRIES = "ProxyAction.REQUEST_PROXY_COUNTRIES";
  static REQUEST_PROXY_COUNTRIES_FINISHED =
    "ProxyAction.REQUEST_PROXY_COUNTRIES_FINISHED";

  static REQUEST_POST_PROXY_COUNTRIES =
    "ProxyAction.REQUEST_POST_PROXY_COUNTRIES";
  static REQUEST_POST_PROXY_COUNTRIES_FINISHED =
    "ProxyAction.REQUEST_POST_PROXY_COUNTRIES_FINISHED";

  static REQUEST_CUSTOMISE_PROXY = "ProxyAction.REQUEST_CUSTOMISE_PROXY";
  static REQUEST_CUSTOMISE_PROXY_FINISHED =
    "ProxyAction.REQUEST_CUSTOMISE_PROXY_FINISHED";

  static REQUEST_POST_CUSTOMISE_PROXY =
    "ProxyAction.REQUEST_POST_CUSTOMISE_PROXY";
  static REQUEST_POST_CUSTOMISE_PROXY_FINISHED =
    "ProxyAction.REQUEST_POST_CUSTOMISE_PROXY_FINISHED";

  static REQUEST_PROXY_ACCOUNT_INFO = "ProxyAction.REQUEST_PROXY_ACCOUNT_INFO";
  static REQUEST_PROXY_ACCOUNT_INFO_FINISHED =
    "ProxyAction.REQUEST_PROXY_ACCOUNT_INFO_FINISHED";

  static REQUEST_PROXY_BULK_UPDATE = "ProxyAction.REQUEST_PROXY_BULK_UPDATE";
  static REQUEST_PROXY_BULK_UPDATE_FINISHED =
    "ProxyAction.REQUEST_PROXY_BULK_UPDATE_FINISHED";

  static requestProxyCountries(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProxyAction.REQUEST_PROXY_COUNTRIES,
        ProxyEffect.requestProxyCountries,
        params
      );
    };
  }

  static requestPostProxyCountries(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProxyAction.REQUEST_POST_PROXY_COUNTRIES,
        // ProxyEffect.requestPostProxyCountries,
        data
      );
    };
  }

  static requestCustomiseProxy(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProxyAction.REQUEST_CUSTOMISE_PROXY,
        ProxyEffect.requestCustomiseProxy,
        params
      );
    };
  }

  static requestPostCustomiseProxy(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProxyAction.REQUEST_POST_CUSTOMISE_PROXY,
        ProxyEffect.requestPostCustomiseProxy,
        data
      );
    };
  }

  static requestProxyAccountInfo(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProxyAction.REQUEST_PROXY_ACCOUNT_INFO,
        ProxyEffect.requestProxyAccountInfo,
        params
      );
    };
  }

  static requestProxyBulkUpdate(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProxyAction.REQUEST_PROXY_BULK_UPDATE,
        ProxyEffect.requestProxyBulkUpdate,
        params
      );
    };
  }
}
