import BaseReducer from "../../../utilities/BaseReducer";
import PriorityAction from "./PriorityAction";

export default class PriorityReducer extends BaseReducer {
  //initial state of priority list
  initialState = {
    priority: [],
  };

  //priority request action finish
  [PriorityAction.REQUEST_PRIORITY_FINISHED](state, action) {
    return {
      ...state,
      priority: action.payload,
    };
  }
}
