import React, { useEffect, useState, useCallback } from "react";
/* eslint-disable react-hooks/exhaustive-deps */

import Grid from "@mui/material/Grid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button, createFilterOptions, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ProjectAction from "../../../ormTicketing/stores/project/projectAction";
import ClientAction from "../../stores/client/ClientAction";
import OrganizationAction from "../../stores/organization/OrganizationAction";
import StatusAction from "../../../ormTicketing/stores/status/StatusAction";
import PlatformAction from "../../../ormTicketing/stores/platform/PlatformAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import ProjectListPost from "../../components/projectPostForm/ProjectPost";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import AddUserPermission from "../../components/adduser/AddUserPermission";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import ReportTemplateAction from "../../../stores/reportTemplate/ReportTemplateAction";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ProjectAction.REQUEST_PROJECT,
    ProjectAction.REQUEST_PROJECT_FILTER,
    ClientAction.REQUEST_CLIENT,
    OrganizationAction.REQUEST_ORGANIZATION_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    PlatformAction.REQUEST_PLATFORM,
    ReportTemplateAction.REQUEST_UPDATE_ORG_REPORT_TEMPLATE,
  ]),
  project: state.project.project || [],
  client: state.client.client || [],
  pagination: state.project.project || {},
  projectfilter: state.projectfilter.projectfilter || {},
  organizationFilter: state.organizationFilter.organizationFilter || {},
  statusFilter: state.statusFilter.statusFilter || [],
  platform: state.platform.platform || [],
  userById: state.userById.userById || [],
});

function ProjectList(props) {
  const { isRequesting } = props;
  // SetState
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [searchProjectName, setSearchProjectName] = useState(null);
  const [selectValue] = useState(null);
  const [openUser, setOpenUser] = useState(false);
  const [openRender, setopenRender] = useState(false);
  const [, setShowElement] = React.useState(true);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  const onFilterProjectName = (event, searchProjectName) => {
    if (searchProjectName !== null) {
      setSearchProjectName(searchProjectName);
    } else {
      setSearchProjectName(null);
    }
  };

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const updateReportsFunction = async (params_in) => {
    let organization = params_in?.row?.organization_id?.organization_id;

    let params = {
      organization_id: organization,
    };

    props
      .dispatch(
        ReportTemplateAction.requestUpdateOrgReportTemplate(null, null, params)
      )
      .then(() => {});
  };

  // **************************************************Columns
  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "project_unique_id",
      headerName: "Project ID",
      width: 200,
      hide: true,
    },

    {
      field: "project_name",
      headerName: "Project Name",
      width: 250,
      flex: 3,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
      resizable: false,
    },

    {
      field: "client_name",
      headerName: "Client",
      width: 250,
      flex: 2,
      resizable: false,
      hide: true,
    },

    {
      field: "client_name_org_name",
      headerName: "Client - Organization",
      width: 250,
      flex: 2,
      resizable: false,
    },
    {
      field: "client_id",
      headerName: "Client_id",
      width: 250,
      flex: 4,
      resizable: false,
      hide: true,
    },

    {
      field: "client_organization_id",
      headerName: "client_organization_id",
      width: 250,
      flex: 4,
      resizable: false,
      hide: true,
    },

    {
      field: "organization_id",
      headerName: "organization_id",
      width: 250,
      flex: 4,
      resizable: false,
      hide: true,
    },
    // {
    // field: "platform_name",
    // headerName: "Platform",
    // width: 250,
    // flex: 4,
    // resizable: false,
    // },
    {
      field: "platform",
      headerName: "Platform",
      width: 250,
      flex: 4,
      resizable: false,
      hide: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      resizable: false,
      width: 160,
      flex: 2,
      cellClassName: "actions",
      align: "center",

      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
    {
      field: "permission",
      type: "actions",
      headerName: "Permission",
      width: 160,
      flex: 2,
      align: "center",
      renderCell: (params) => (
        <IconButton
          title="Add User"
          onClick={(event) => {
            event.ignore = true;
            setOpenUser(true);
            setopenRender(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <PersonAddIcon
            className="add-user-btn"
            name="Add User"
            sx={{ color: "#2a5e6c" }}
          ></PersonAddIcon>
        </IconButton>
      ),
    },
    {
      field: "sync_reports",
      type: "actions",
      headerName: "Sync Reports",
      width: 160,
      align: "center",
      flex: 2,
      hide:
        props?.userById?.data?.attributes?.organization_id[0] === "*"
          ? false
          : true,
      renderCell: (params) => (
        <IconButton
          title="Sync Reports"
          onClick={(event) => {
            event.ignore = true;

            updateReportsFunction(params);
          }}
          sx={{ border: "0" }}
        >
          <CloudSyncOutlinedIcon
            sx={{
              color: "#2a5e6c",
            }}
          />
        </IconButton>
      ),
    },
  ];

  function fetch(params) {
    props.dispatch(ProjectAction.requestProject(params));
  }

  function fetchOrgPlatforms() {
    let param = {
      level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function fetchProjectFilter() {
    const params = {
      order_by: `[["project_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(ProjectAction.requestProjectFilter(params));
  }

  function fetchClients(params) {
    props.dispatch(ClientAction.requestClient(params));
  }

  function fetchOrganizationFilter() {
    const param = {
      order_by: `[["organization_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(OrganizationAction.requestOrganizationFilter(param));
  }

  function fetchStatusFilter() {
    const param = {
      page_limit: "none",
      order_by: `[["status_name.keyword", "asc"]]`,
    };
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  // Project list
  const getProjectList = (val) => {
    let filter_params = {};
    const filter = [];

    if (searchProjectName !== null) {
      filter.push(
        `["project_name.keyword","must","","match","${searchProjectName}"]`
      );
    }

    if (searchProjectName !== null) {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    }

    fetch(filter_params);
  };

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  // UseEffect function
  useEffect(() => {
    const params = { page_limit: `${page_limit}` };
    const param = {
      page_limit: "none",
      order_by: `[["client_name.keyword", "asc"]]`,
    };

    getProjectList(params);

    // if (props?.client === undefined || props?.client.length === 0) {
    fetchClients(param);
    // }

    // if (
    //   props?.organizationFilter === undefined ||
    //   props?.organizationFilter.length === 0
    // ) {
    fetchOrganizationFilter();
    // }

    // if (props?.platform === undefined || props?.platform.length === 0) {
    fetchOrgPlatforms();
    // }

    // if (
    //   props?.projectfilter === undefined ||
    //   props?.projectfilter.length === 0
    // ) {
    fetchProjectFilter();
    // }

    // if (
    //   props?.statusFilter === undefined ||
    //   Object.keys(props.statusFilter).length === 0
    // ) {
    fetchStatusFilter();
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // pagination
  const handleChange = (event, value) => {
    // page_number=1&page_limit=10
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (searchProjectName !== null) {
      filter.push(
        `["project_name.keyword","must","","match","${searchProjectName}"]`
      );
    }

    if (FilterClicked) {
      if (searchProjectName !== null || selectValue !== null) {
        filter_params = {
          order_by: '[["created_on", "desc"]]',
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetch(filter_params);
    }
  };

  // Filter function for project
  const applyFilter = () => {
    const filter = [];

    if (searchProjectName !== null) {
      filter.push(
        `["project_name.keyword","must","","match","${searchProjectName}"]`
      );
    }

    if (searchProjectName !== null || selectValue !== null) {
      const params = {
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      fetch(params);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    // Clear the state
    if (searchProjectName !== null) {
      setClearLoader(true);
      setSearchProjectName(null);
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: 15,
    };
    FilterClicked && fetch(params);
    setTimeout(function () {
      setShowElement(false);
    }, 3000);
  };

  let organization_filter_array = [];
  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.map((item) => {
      return organization_filter_array.push({
        organization_id: item._id,
        organization_name: item._source.organization_name,
      });
    });
  }

  let platform_array = [];

  if (props.platform.hits) {
    props.platform.hits.hits.map((item) => {
      return platform_array.push({
        platform_id: item._id,
        platform_name: item._source.platform_name,
        platform_alias: item._source.alias,
      });
    });
  }

  // datagrid rows
  const rows = [];
  if (props.project.hits && props.project.hits.hits.length !== 0) {
    props.project.hits.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        project_unique_id: item._id,
        project_name: item._source.project_name,
        created_by: item._source.created_by
          ? item._source.created_by.user_name
          : null,
        client_name: item._source.client
          ? item._source.client.client_name
          : null,
        client_name_org_name: item._source.client
          ? `${item._source.client.client_name} - ${item._source.organization_id.organization_name}`
          : null,
        client_id: item._source.client ? item._source.client.client_id : null,
        client_organization_id: item._source.client
          ? item._source.client.organization
          : null,
        platform: item._source.platform ? item._source.platform : [],
        organization_id:
          item._source && item._source.organization_id
            ? item._source.organization_id
            : "",
      });
    });
  }
  let project_nameList_filter = [];
  if (props.projectfilter.hits) {
    props.projectfilter.hits.hits.map((item) => {
      return project_nameList_filter.push(item._source.project_name);
    });
  }

  let uniqueList = [...new Set(project_nameList_filter)];

  uniqueList.sort((a, b) => a.localeCompare(b));
  uniqueList = [...new Set(uniqueList)];

  let client_array = [];

  if (props.client.hits) {
    props.client.hits.hits.map((item) => {
      return client_array.push({
        client_id: item._id,
        client_name: item._source.client_name,
        organization_id: item._source.organization
          ? item._source.organization.organization_id
          : "",
      });
    });
  }

  let organization_array = [];
  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.map((item) => {
      return organization_array.push({
        organization_id: item._id,
        mapped_platforms: item._source.platform,
      });
    });
  }

  let statuslist = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits.forEach((value) => {
      statuslist.push(value._source.status_name);
    });
  }

  let status_list = [...new Set(statuslist)];

  return (
    <React.Fragment>
      {/* Filter */}

      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes rotateAnimation {
            0% {
              transform: rotate(0deg);  
            }
            100% {
              transform: rotate(-360deg);
            }
          }
    `,
        }}
      />

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={4}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <AutoCompleteComponent
            value={searchProjectName}
            list={uniqueList}
            textFieldProps={{
              placeholder: "Project Name",
            }}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: (option) => option,
            })}
            handleChange={onFilterProjectName}
            size="small"
            width="100%"
            disableClearable={true}
          />
        </Grid>
        <Grid item lg={6} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  loading={filterLoader && isRequesting}
                  name={"Filter"}
                  onClick={applyFilter}
                  width="100%"
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  loading={clearLoader && isRequesting}
                  name={"Clear"}
                  onClick={ClearState}
                  width="100%"
                />
              </Grid>
            </Grid>
            <Grid>
              {/* post button component*/}
              <PostPlusButton
                open={open}
                openCloseModal={openCloseModal}
                editMode={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* project post modal screen */}
      <ProjectListPost
        open={open}
        platform_array={platform_array && platform_array}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        projectEditData={editRowsModel}
        edit={edit}
        client_array={client_array.length > 0 ? client_array : []}
        organization_array={
          organization_array.length > 0 ? organization_array : []
        }
        status_list={status_list}
      />
      {/* *************************************************************** user filter */}
      {!props.isRequesting && (
        <AddUserPermission
          open={openUser}
          onClose={() => setOpenUser(false)}
          projectProps={editRowsModel}
          render={openRender}
          onCloseRender={() => setopenRender(false)}
        />
      )}

      {/* data grid */}

      <Grid item lg={12} xl={12} md={12} sm={12} xs={12} pr={1}>
        {/* loading icon */}

        {/* datagrid Component */}
        {navigator.onLine ? (
          <LoadingIndicator isActive={props.isRequesting}>
            <Box>
              {!isRequesting ? (
                <DataGridFunction
                  columns={columns}
                  rows={rows}
                  rowHeight={60}
                  pagelength={props.pagination.total_page_no}
                  page={props.pagination.current_page_no}
                  handleChange={handleChange}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        project_unique_id: false,
                        client_name: false,
                        client_id: false,
                        client_organization_id: false,
                        organization_id: false,
                        platform: false,
                        sync_reports:
                          props?.userById?.data?.attributes
                            ?.organization_id[0] === "*"
                            ? true
                            : false,
                      },
                    },
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage />
                </Box>
              )}
            </Box>
          </LoadingIndicator>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Grid>

      {/* Pagination */}
      {/* <Grid
display="flex"
justifyContent="center"
container
item
lg={12}
md={12}
sm={12}
>
{" "}
<Grid item lg={8} md={12} sm={12} xs={12}>
{props.project.hits
? props.project.hits.length !== 0 && (
<PaginationUI
pagelength={props.pagination.total_page_no}
page={props.pagination.current_page_no}
handleChange={handleChange}
/>
)
: null}
</Grid>
</Grid> */}
    </React.Fragment>
  );
}

// connect the datagrid function to mapstate function
export default connect(mapStateToProps)(ProjectList);
