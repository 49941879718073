import BaseReducer from "../../../utilities/BaseReducer";
import InstagramDirectMessageAction from "./InstagramDirectMessageAction";

// InstagramDirectMessageReduser function
export default class InstagramDirectMessageReduser extends BaseReducer {
  initialState = {
    instagramDirectMessage: [],
  };

  [InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      instagramDirectMessage: action.payload,
    };
  }

  [InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      instagramDirectMessage: action.payload,
    };
  }
}
