import { BaseModel } from "sjs-base-model";

// YoutubeOfficialPutModel
export default class YoutubeOfficialPutModel extends BaseModel {
  /*
   * YoutubeOfficial-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point
  data = {};
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
