import BaseReducer from "../../../../utilities/BaseReducer";
import TopLabelAction from "./TopLabelAction";

// TrackReduser
export default class TopLabelReducer extends BaseReducer {
  initialState = {
    topLabel: [],
  };
  [TopLabelAction.REQUEST_TOPLABEL_FINISHED](state, action) {
    return {
      ...state,
      topLabel: action.payload,
    };
  }
}
