import { Component } from "react";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import ActionUtility from "../../utilities/ActionUtility";
import SubSourceModel from "./models/SubSourceModel";
import SubSourceEffect from "./SubSourceEffect";

export default class SubSourceAction extends Component {
  //Subsource get
  static REQUEST_SUBSOURCE = "SubSourceAction.REQUEST_SUBSOURCE";
  static REQUEST_SUBSOURCE_FINISHED =
    "SubSourceAction.REQUEST_SUBSOURCE_FINISHED";

  //Retweet post method
  static requestSubSource(params) {
    return async (dispatch, getstate) => {
      if (localStorage.getItem("activities") !== null) {
        let data = localStorage.getItem("activities");
        const model = new SubSourceModel(JSON.parse(data));
        const isError = model instanceof HttpErrorResponseModel;
        dispatch(
          ActionUtility.createAction(
            `${SubSourceAction.REQUEST_SUBSOURCE}_FINISHED`,
            model,
            isError
          )
        );
        return model;
      } else {
        await ActionUtility.createThunkEffect(
          dispatch,
          SubSourceAction.REQUEST_SUBSOURCE,
          SubSourceEffect.requestSubSource,
          params
        );
      }
    };
  }
}
