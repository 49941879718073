import ActionUtility from "../../../utilities/ActionUtility";
import AiSettingsEffect from "./AiSettingsEffect";

export default class AiSettingsAction {
  static REQUEST_AI_SETTINGS = "AiSettingsAction.REQUEST_AI_SETTINGS";
  static REQUEST_AI_SETTINGS_FINISHED =
    "AiSettingsAction.REQUEST_AI_SETTINGS_FINISHED";

  static REQUEST_PUT_AI_SETTINGS = "AiSettingsAction.REQUEST_PUT_AI_SETTINGS";
  static REQUEST_PUT_AI_SETTINGS_FINISHED =
    "AiSettingsAction.REQUEST_PUT_AI_SETTINGS_FINISHED";

  static REQUEST_POST_AI_SETTINGS = "AiSettingsAction.REQUEST_POST_AI_SETTINGS";
  static REQUEST_POST_AI_SETTINGS_FINISHED =
    "AiSettingsAction.REQUEST_POST_AI_SETTINGS_FINISHED";

  static REQUEST_DELETE_AI_SETTINGS =
    "AiSettingsAction.REQUEST_DELETE_AI_SETTINGS";
  static REQUEST_DELETE_AI_SETTINGS_FINISHED =
    "AiSettingsAction.REQUEST_DELETE_AI_SETTINGS_FINISHED";

  // METHODS
  // AI_SETTINGS GET function
  static requestAiSettings(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiSettingsAction.REQUEST_AI_SETTINGS,
        AiSettingsEffect.requestAiSettings,
        params
      );
    };
  }

  static requestPutAiSettings(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiSettingsAction.REQUEST_PUT_AI_SETTINGS,
        AiSettingsEffect.requestPutAiSettings,
        data,
        id
      );
    };
  }

  static requestPostAiSettings(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiSettingsAction.REQUEST_POST_AI_SETTINGS,
        AiSettingsEffect.requestPostAiSettings,
        data,
        params,
        callBack
      );
    };
  }

  static requestDeleteAiSettings(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AiSettingsAction.REQUEST_DELETE_AI_SETTINGS,
        AiSettingsEffect.requestDeleteAiSettings,
        id
      );
    };
  }
}
