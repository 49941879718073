import BaseReducer from "../../../utilities/BaseReducer";
import AutoresponseAction from "./AutoresponseAction";

export default class AutoresponseReducer extends BaseReducer {
  initialState = {
    autoResponse: [],
  };

  [AutoresponseAction.REQUEST_AUTORESPONSE_FINISHED](state, action) {
    return {
      ...state,
      autoResponse: action.payload,
    };
  }
}
