import React, { useState } from "react";

const switchStyles = {
  switch: {
    position: "relative",
    display: "inline-block",
    width: 45,
    // height: 20,
  },
  input: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  slider: {
    position: "absolute",
    cursor: "pointer",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
    transition: "0.4s",
    borderRadius: "34px",
    transition: "background-color .4s",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "calc(100% + 2px)",
    // height: "calc(100% + 0px)",
  },
  textAligningLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "4px",
    color: "white",
  },
  textAligningRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "4px",
    color: "#86aeb6",
  },
  sliderBefore: {
    position: "absolute",
    height: 16,
    width: 16,
    left: 2,
    bottom: 2,
    transition: "0.4s",
    borderRadius: "50%",
  },
  checkedSlider: {
    backgroundColor: "#86aeb6",
  },
  checkedSliderBefore: {
    backgroundColor: "white",
    transform: "translateX(26px)",
  },
  label: {
    fontSize: "11px",
    fontWeight: "bold",
  },
};

function ManualSwitchButton(props) {
  const [checked, setChecked] = useState(props.checked);
  const handleChange = (e) => {
    props.onChange(e);
    setChecked(!checked);
  };

  return (
    <label
      style={{ ...switchStyles.switch, height: checked ? 20 : 21 }}
      //   style={switchStyles.switch}
      className="switch"
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={switchStyles.input}
      />
      <span
        style={{
          ...switchStyles.slider,
          ...(checked && switchStyles.checkedSlider),
          ...(checked
            ? switchStyles.textAligningLeft
            : switchStyles.textAligningRight),
          border: !checked && "1px solid #86aeb6",
        }}
      >
        <span
          style={{
            ...switchStyles.sliderBefore,
            ...(checked && switchStyles.checkedSliderBefore),
            backgroundColor: checked ? "white" : "#86aeb6",
          }}
        ></span>
        <span style={switchStyles.label}>{checked ? "ON" : "OFF"}</span>
      </span>
    </label>
  );
}
export default ManualSwitchButton;
