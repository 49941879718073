import BaseReducer from "../../../utilities/BaseReducer";
import InstagramAccountAction from "./InstagramAccountAction";

export default class InstagramAccountFilterReduser extends BaseReducer {
  //initial state of InstagramAccount
  initialState = {
    instagramAccountFilter: [],
  };
  //InstagramAccount request action finish
  [InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      instagramAccountFilter: action.payload,
    };
  }
}
