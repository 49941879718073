import ActionUtility from "../../../utilities/ActionUtility";
import ProjectEffect from "./ProjectEffect";

export default class ProjectAction {
  // project action with an function declaration
  static REQUEST_PROJECT = "ProjectAction.REQUEST_PROJECT";
  static REQUEST_PROJECT_FINISHED = "ProjectAction.REQUEST_PROJECT_FINISHED";

  // project action with an function declaration
  static REQUEST_PROJECT_FILTER = "ProjectAction.REQUEST_PROJECT_FILTER";
  static REQUEST_PROJECT_FILTER_FINISHED =
    "ProjectAction.REQUEST_PROJECT_FILTER_FINISHED";

  // project by ID
  static REQUEST_PROJECT_BY_ID = "ProjectAction.REQUEST_PROJECT_BY_ID";
  static REQUEST_PROJECT_BY_ID_FINISHED =
    "ProjectAction.REQUEST_PROJECT_BY_ID_FINISHED";
  //   project post
  static REQUEST_POST_PROJECT = "ProjectAction.REQUEST_POST_PROJECT";
  static REQUEST_POST_PROJECT_FINISHED =
    "ProjectAction.REQUEST_POST_PROJECT_FINISHED";

  // project put
  static REQUEST_PUT_PROJECT = "ProjectAction.REQUEST_PUT_PROJECT";
  static REQUEST_PUT_PROJECT_FINISHED =
    "ProjectAction.REQUEST_PUT_PROJECT_FINISHED";

  // project delete
  static REQUEST_DELETE_PROJECT = "ProjectAction.REQUEST_DELETE_PROJECT";
  static REQUEST_DELETE_PROJECT_FINISHED =
    "ProjectAction.REQUEST_DELETE_PROJECT_FINISHED";

  // METHODS
  // Project GET function
  static requestProject(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.REQUEST_PROJECT,
        ProjectEffect.requestProject,
        params
      );
    };
  }

  // project filter
  static requestProjectFilter(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.REQUEST_PROJECT_FILTER,
        ProjectEffect.requestProjectFilter,
        params,
        callBackFunction
      );
    };
  }

  static requestProjectById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.REQUEST_PROJECT_BY_ID,
        ProjectEffect.requestProjectById,
        id
      );
    };
  }

  // Project post function
  static requestPostProject(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.REQUEST_POST_PROJECT,
        ProjectEffect.requestPostProject,
        data,
        callBackFunction
      );
    };
  }

  // Project put function
  static requestPutProject(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.REQUEST_PUT_PROJECT,
        ProjectEffect.requestPutProject,
        data,
        id
      );
    };
  }

  // Project delete function
  static requestDeleteProject(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProjectAction.REQUEST_DELETE_PROJECT,
        ProjectEffect.requestDeleteProject,
        id
      );
    };
  }
}
