import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import IndeedPutModel from "../indeed/models/IndeedPutModel";
import WhatsAppFeedModel from "./model/WhatsAppFeedModel";
import WhatsAppPostMessageModel from "./model/WhatsAppPostMessageModel";

// WhatsAppFeedEffect function
export default class WhatsAppFeedEffect {
  // calling an API according to the WhatsApp feed model
  // Get method for the WhatsApp feed
  static async requestWhatsAppFeed(params) {
    const endpoint = environment.api.whatsAppFeed;
    return EffectUtility.getToModel(WhatsAppFeedModel, endpoint, params);
  }

  static async requestWhatsAppFeedClear() {
    return {};
  }

  // Get method for the WhatsApp feed  filter
  static async requestWhatsAppFeedFilter(params) {
    const endpoint = environment.api.whatsAppFeedFilter;
    return EffectUtility.getToModel(WhatsAppFeedModel, endpoint, params);
  }

  // put method for the WhatsApp feed  filter

  static async requestPutWhatsAppFeed(data, id) {
    const endpoint = environment.api.whatsAppFeed + "/" + id;
    return EffectUtility.putToModel(IndeedPutModel, endpoint, data);
  }

  // post method for the WhatsApp feed filter
  //   static async requestPostWhatsAppFeed(data) {
  //     const endpoint = environment.api.emailAccount + "/add";

  //   }

  //whatsapp post message method

  static async requestWhatsappPostMessage(data, id, callback) {
    const endpoint = environment.api.whatsappPostMessage + "/" + id;

    let response = EffectUtility.postToModel(
      WhatsAppPostMessageModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
      }
    });

    return response;
  }
}
