import { BaseModel } from "sjs-base-model";

// CloudMailList Data Model
export default class CloudMialListDataModel extends BaseModel {
  // response getting from the api mentioned end point

  mail_list = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
