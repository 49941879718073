import BaseReducer from "../../utilities/BaseReducer";
import ReportsLogoAction from "./ReportsLogoAction";

export default class ReportsLogoReducer extends BaseReducer {
  initialState = {
    reportsLogo: [],
  };
  [ReportsLogoAction.REQUEST_REPORTS_LOGO_FINISHED](state, action) {
    return {
      ...state,
      reportsLogo: action.payload,
    };
  }
}
