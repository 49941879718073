import BaseReducer from "../../../utilities/BaseReducer";
import FacebookAction from "./FacebookAction";

export default class FacebookInnerFilterReducer extends BaseReducer {
  initialState = {
    fbInnerFilter: [],
  };

  [FacebookAction.REQUEST_FACEBOOK_INNER_FILTER_FINISHED](state, action) {
    return {
      ...state,
      fbInnerFilter: action.payload,
    };
  }

  [FacebookAction.REQUEST_FACEBOOK_INNER_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      fbInnerFilter: action.payload,
    };
  }
}
