import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ChannelAnalyticsGetModel from "./models/ChannelAnalyticsGetModel";

export default class ChannelAnalyticsEffect {
  static async requestChannelAnalytics(params) {
    const endpoint = environment.api.channelAnalytics;
    return EffectUtility.getToModel(ChannelAnalyticsGetModel, endpoint, params);
  }

  static async requestFilteredChannelAnalytics(params) {
    const endpoint = environment.api.channelAnalytics;
    return EffectUtility.getToModel(ChannelAnalyticsGetModel, endpoint, params);
  }
}
