import { BaseModel } from "sjs-base-model";

export default class FPTotalCollectionCountGetModel extends BaseModel {
  total_collection_count = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
