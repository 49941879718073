import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { theme } from "../../../../views/App";

const LineChartComponent = (props) => {
  Chart.register(CategoryScale);

  const { list, type } = props;

  let countData = [];
  let nameLabels = [];
  let percentageData = [];
  countData = list.map((item) => {
    return item.count;
  });
  percentageData = list.map((item) => {
    return item.percentage;
  });

  let Count_MAX = Math.max(...countData);
  let Percentage_MAX = Math.max(...percentageData);

  let MaxValue = Count_MAX + Percentage_MAX;

  let AvgOfMax = MaxValue / 10;
  MaxValue = MaxValue + AvgOfMax;

  AvgOfMax = Math.round(AvgOfMax);

  nameLabels = list.map((item) => {
    return item.name;
  });

  const state = {
    labels: nameLabels,
    datasets: [
      {
        type: "line",
        label: "Count",
        lineTension: 0.5,
        backgroundColor: `${theme.reports.positive}`,
        data: countData,
        borderWidth: 2,
        borderRadius: 5,
      },
      {
        type: "line",
        label: "Percentage",
        lineTension: 0.5,
        backgroundColor: `${theme.reports.negative}`,
        data: percentageData,
        borderWidth: 2,
        borderRadius: 5,
      },
    ],
  };

  return (
    <div style={{ width: "100%", padding: "50px" }}>
      <Line
        datasetIdKey="id"
        data={state}
        options={{
          animation: false,
          responsive: true,
          plugins: {
            tooltip: {
              usePointStyle: true,
            },
            legend: {
              display: true,
              position: "right",
              labels: {
                usePointStyle: true,
                padding: 20,
              },
            },
            datalabels: {
              callback: (value) => value, // Add the percentage after the value
              align: "end",
              anchor: "end",
              color: "black",
              clip: true,
              formatter: (value) => {
                return value;
              },
            },
          },

          scales: {
            x: {
              grid: {
                display: false,
              },
              title: {
                display: true,
                text: "Custom Values",
                color: `${theme.palette.primary.main}`,

                font: {
                  size: `${theme.typography.fontSize}`,
                },
              },
              ticks: {
                color: "black",
                font: {
                  size: 13,
                },
              },
            },
            y: {
              max: MaxValue,
              min: 0,
              title: {
                display: true,
                text: "Count",
                color: `${theme.palette.primary.main}`,

                font: {
                  size: `${theme.typography.fontSize}`,
                },
              },
              ticks: {
                stepSize: AvgOfMax,
                color: "black",
                font: {
                  size: 13,
                },
                // callback: function (value, index, values) {
                //   return value;
                // },
              },

              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChartComponent;
