import ActionUtility from "../../../../utilities/ActionUtility";
import TerritoriesEffect from "./TerritoriesEffect";

export default class TerritoriesAction {
  // TerritoriesAction action with an function declaration
  static REQUEST_TERRITORIES = "TerritoriesAction.REQUEST_TERRITORIES";
  static REQUEST_TERRITORIES_FINISHED =
    "TerritoriesAction.REQUEST_TERRITORIES_FINISHED";

  static REQUEST_TERRITORIES_FILTER =
    "TerritoriesAction.REQUEST_TERRITORIES_FILTER";
  static REQUEST_TERRITORIES_FILTER_FINISHED =
    "TerritoriesAction.REQUEST_TERRITORIES_FILTER_FINISHED";

  // METHODS
  // Territories GET function
  static requestTerritories(params, releasePostCallBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoriesAction.REQUEST_TERRITORIES,
        TerritoriesEffect.requestTerritories,
        params,
        releasePostCallBack
      );
    };
  }

  static requestTerritoriesFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TerritoriesAction.REQUEST_TERRITORIES_FILTER,
        TerritoriesEffect.requestTerritoriesFilter,
        params
      );
    };
  }
}
