import ActionUtility from "../../../utilities/ActionUtility";
import InstagramAccountEffect from "./InstagramAccountEffect";
export default class InstagramAccountAction {
  // InstagramAccountAction action with an function declaration
  static REQUEST_INSTAGRAM_ACCOUNT =
    "InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT";
  static REQUEST_INSTAGRAM_ACCOUNT_FINISHED =
    "InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT_FINISHED";

  static REQUEST_INSTAGRAM_ACCOUNT_FILTER =
    "InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT_FILTER";
  static REQUEST_INSTAGRAM_ACCOUNT_FILTER_FINISHED =
    "InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_INSTAGRAM_ACCOUNT =
    "InstagramAccountAction.REQUEST_PUT_INSTAGRAM_ACCOUNT";
  static REQUEST_PUT_INSTAGRAM_ACCOUNT_FINISHED =
    "InstagramAccountAction.REQUEST_PUT_INSTAGRAM_ACCOUNT_FINISHED";

  static REQUEST_DELETE_INSTAGRAM_ACCOUNT =
    "InstagramAccountAction.REQUEST_DELETE_INSTAGRAM_ACCOUNT";
  static REQUEST_DELETE_INSTAGRAM_ACCOUNT_FINISHED =
    "InstagramAccountAction.REQUEST_DELETE_INSTAGRAM_ACCOUNT_FINISHED";

  // METHODS
  // instagram GET function
  static requestInstagramAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT,
        InstagramAccountEffect.requestInstagramAccount,
        params
      );
    };
  }

  static requestInstagramAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT_FILTER,
        InstagramAccountEffect.requestInstagramAccountFilter,
        params
      );
    };
  }
  static requestPutInstagramAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramAccountAction.REQUEST_PUT_INSTAGRAM_ACCOUNT,
        InstagramAccountEffect.requestPutInstagramAccount,
        data,
        id
      );
    };
  }

  static requestDeleteInstagramAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramAccountAction.REQUEST_DELETE_INSTAGRAM_ACCOUNT,
        InstagramAccountEffect.requestDeleteInstagramAccount,

        id
      );
    };
  }
}
