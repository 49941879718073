import BaseReducer from "../../../../utilities/BaseReducer";
import WebformsAction from "./WebformsAction";

export default class WebformsReduser extends BaseReducer {
  initialState = {
    webformsData: [],
  };
  [WebformsAction.REQUEST_GET_WEBFORMS_FINISHED](state, action) {
    return {
      ...state,
      webformsData: action.payload,
    };
  }
}
