import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import LinkStatusGetModel from "./model/LinkStatusGetModel";

export default class LinkStatusEffect {
  // proxy countries
  static async requestLinkStatus(params) {
    const endpoint = environment.api.linkStatus;
    return EffectUtility.getToModel(LinkStatusGetModel, endpoint, params);
  }

  static async requestLinkStatusFilter(params) {
    const endpoint = environment.api.linkStatus;
    return EffectUtility.getToModel(LinkStatusGetModel, endpoint, params);
  }
}
