import ActionUtility from "../../../../utilities/ActionUtility";
import DsrActivityDashboardEffect from "./DsrDashboardEffect";

export default class DsrActivityDashboardAction {
  // DsrActivityDashboardAction action with an function declaration
  static REQUEST_DSR_ACTIVITY_DATA =
    "DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_DATA";
  static REQUEST_DSR_ACTIVITY_DATA_FINISHED =
    "DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_DATA_FINISHED";

  static REQUEST_DSR_ACTIVITY_GROUPWISE =
    "DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_GROUPWISE";
  static REQUEST_DSR_ACTIVITY_GROUPWISE_FINISHED =
    "DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_GROUPWISE_FINISHED";

  static REQUEST_DSR_DASHBOARD_SUMMARY =
    "DsrActivityDashboardAction.REQUEST_DSR_DASHBOARD_SUMMARY";
  static REQUEST_DSR_DASHBOARD_SUMMARY_FINISHED =
    "DsrActivityDashboardAction.REQUEST_DSR_DASHBOARD_SUMMARY_FINISHED";

  // METHODS
  static requestDsrActivityData(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_DATA,
        DsrActivityDashboardEffect.requestDsrActivityData,
        params
      );
    };
  }

  static requestDsrActivityGroupwise(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityDashboardAction.REQUEST_DSR_ACTIVITY_GROUPWISE,
        DsrActivityDashboardEffect.requestDsrActivityGroupwise,
        params
      );
    };
  }

  static requestDsrDashboardSummary(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityDashboardAction.REQUEST_DSR_DASHBOARD_SUMMARY,
        DsrActivityDashboardEffect.requestDsrDashboardSummary,
        params
      );
    };
  }
}
