import BaseReducer from "../../../../utilities/BaseReducer";
import FeedBackAction from "./FeedBackAction";

// FeedbackReuser
export default class FeedbackReducer extends BaseReducer {
  initialState = {
    feedback: [],
  };
  [FeedBackAction.REQUEST_FEED_BACK_FINISHED](state, action) {
    return {
      ...state,
      feedback: action.payload,
    };
  }
}
