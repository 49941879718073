import BaseReducer from "../../../../utilities/BaseReducer";
import LinkCheckingAction from "./LinkCheckingAction";

export default class ArchivedLinkReducer extends BaseReducer {
  initialState = {
    archivedLinks: [],
  };
  [LinkCheckingAction.REQUEST_ARCHIVED_LINKS_FINISHED](state, action) {
    return {
      ...state,
      archivedLinks: action.payload,
    };
  }
}
