import BaseReducer from "../../../../utilities/BaseReducer";
import ChannelAnalyticsAction from "./ChannelAnalyticsAction";

export default class ChannelAnalyticsFilterReducer extends BaseReducer {
  initialState = {
    channelAnalyticsFilter: [],
  };

  [ChannelAnalyticsAction.REQUEST_CHANNEL_ANALYTICS_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      channelAnalyticsFilter: action.payload,
    };
  }
}
