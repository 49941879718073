import ActionUtility from "../../../utilities/ActionUtility";
import DIYReportTemplateEffect from "./DIYReportTemplateEffect";

export default class DIYReportTemplateAction {
  //DIY_REPORT_TEMPLATEs requestDIYs
  static REQUEST_DIY_REPORT_TEMPLATE =
    "DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE";
  static REQUEST_DIY_REPORT_TEMPLATE_FINISHED =
    "DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE_FINISHED";

  //DIY_REPORT_TEMPLATEs post
  static REQUEST_POST_DIY_REPORT_TEMPLATE =
    "DIYReportTemplateAction.REQUEST_POST_DIY_REPORT_TEMPLATE";
  static REQUEST_POST_DIY_REPORT_TEMPLATE_FINISHED =
    "DIYReportTemplateAction.REQUEST_POST_DIY_REPORT_TEMPLATE_FINISHED";

  //DIY_REPORT_TEMPLATEs put
  static REQUEST_PUT_DIY_REPORT_TEMPLATE =
    "DIYReportTemplateAction.REQUEST_PUT_DIY_REPORT_TEMPLATE";
  static REQUEST_PUT_DIY_REPORT_TEMPLATE_FINISHED =
    "DIYReportTemplateAction.REQUEST_PUT_DIY_REPORT_TEMPLATE_FINISHED";

  static REQUEST_DELETE_DIY_REPORT_TEMPLATE =
    "DIYReportTemplateAction.REQUEST_DELETE_DIY_REPORT_TEMPLATE";
  static REQUEST_DELETE_DIY_REPORT_TEMPLATE_FINISHED =
    "DIYReportTemplateAction.REQUEST_DELETE_DIY_REPORT_TEMPLATE_FINISHED";

  static REQUEST_UPDATE_ORG_DIY_REPORT_TEMPLATE =
    "DIYReportTemplateAction.REQUEST_UPDATE_ORG_DIY_REPORT_TEMPLATE";
  static REQUEST_UPDATE_ORG_DIY_REPORT_TEMPLATE_FINISHED =
    "DIYReportTemplateAction.REQUEST_UPDATE_ORG_DIY_REPORT_TEMPLATE_FINISHED";

  //DIY_REPORT_TEMPLATEs filter
  static REQUEST_DIY_REPORT_TEMPLATE_FILTER =
    "DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE_FILTER";
  static REQUEST_DIY_REPORT_TEMPLATE_FILTER_FINISHED =
    "DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE_FILTER_FINISHED";

  static REQUEST_DIY_REPORTS_BY_ID =
    "DIYReportTemplateAction.REQUEST_DIY_REPORTS_BY_ID";
  static REQUEST_DIY_REPORTS_BY_ID_FINISHED =
    "DIYReportTemplateAction.REQUEST_DIY_REPORTS_BY_ID_FINISHED";

  //METHODS

  //DIY_REPORT_TEMPLATE list get method
  static requestDIYReportTemplate(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE,
        DIYReportTemplateEffect.requestDIYReportTemplate,
        params,
        callBack
      );
    };
  }

  //DIY_REPORT_TEMPLATE list get method
  static requestDIYReportTemplateFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE_FILTER,
        DIYReportTemplateEffect.requestDIYReportTemplateFilter,
        params
      );
    };
  }

  //DIY_REPORT_TEMPLATE list post method
  static requestDIYPostReportTemplate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DIYReportTemplateAction.REQUEST_POST_DIY_REPORT_TEMPLATE,
        DIYReportTemplateEffect.requestDIYPostReportTemplate,
        data
      );
    };
  }

  //DIY_REPORT_TEMPLATE list put method
  static requestDIYPutReportTemplate(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DIYReportTemplateAction.REQUEST_PUT_DIY_REPORT_TEMPLATE,
        DIYReportTemplateEffect.requestDIYPutReportTemplate,
        data,
        id
      );
    };
  }

  static requestDIYDeleteReportTemplate(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DIYReportTemplateAction.REQUEST_DELETE_DIY_REPORT_TEMPLATE,
        DIYReportTemplateEffect.requestDIYDeleteReportTemplate,
        id
      );
    };
  }

  // static requestDIYUpdateOrgReportTemplate(data, id, params) {
  //   return async (dispatch, getState) => {
  //     await ActionUtility.createThunkEffect(
  //       dispatch,
  //       DIYReportTemplateAction.REQUEST_UPDATE_ORG_DIY_REPORT_TEMPLATE,
  //       DIYReportTemplateEffect.requestDIYUpdateOrgReportTemplate,
  //       data,
  //       id,
  //       params
  //     );
  //   };
  // }

  //Report list get method by id
  static requestDIYReportsById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DIYReportTemplateAction.REQUEST_DIY_REPORTS_BY_ID,
        DIYReportTemplateEffect.requestDIYReportsById,
        id
      );
    };
  }
}
