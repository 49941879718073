import { BaseModel } from "sjs-base-model";
import FacebookDmHitsDataModel from "./FacebookDmHitsDataModel";

export default class FacebookDmHitsModels extends BaseModel {
  //priority list hits model
  hits = [FacebookDmHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
