import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { format } from "date-fns";
import { connect } from "react-redux";
// Pagination component
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SearchIcon from "@mui/icons-material/Search";
import environment from "environment";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import PlayStoreAction from "../../../stores/playstore/PlayStoreAction";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../../utilities/FetchRequest";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  avatar: {
    // margin: 10,
    width: 45,
    height: 45,
  },
});

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    PlayStoreAction.REQUEST_PLAYSTORE,
    // PlayStoreAction.REQUEST_PLAYSTORE_FILTER,
  ]),
  playstore: state.playstore.playstore.hits || [],
  pagination: state.playstore.playstore || {},
  playstorefilter: state.playstorefilter.playstorefilter.hits || [],
});

function PlayStoreListView(props) {
  const [value, setValue] = React.useState("1");

  const classes = useStyles();

  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  //****************************************** setState
  const [page_limit] = useState(10);
  const [appName, setAppName] = useState(null);
  const [customerName, setCustomerName] = React.useState(null);

  const [replyState, setReplyState] = useState("");
  const [rating, setRating] = useState([]);
  const [star] = useState(["1", "2", "3", "4", "5"]);
  const [date, setDate] = useState([null, null]);
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // ************************************************* Dispatch functions
  function fetchPlayStore(params) {
    props.dispatch(PlayStoreAction.requestPlayStore(params));
  }

  function fetchPlayStoreFilter(param) {
    props.dispatch(PlayStoreAction.requestPlayStoreFilter(param));
  }

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  // //*************************************************  UseEffect function
  useEffect(() => {
    const param = { page_limit: "none" };

    fetchPlayStoreFilter(param);

    const filter = [];
    let params = {};

    // search
    if (searchQuery !== "") {
      filter.push(
        `["review_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (appName !== null) {
      filter.push(`["app_name.keyword","must","","match","${appName}"]`);
    }
    if (rating.length !== 0) {
      filter.push(`["ratings.keyword","must","","terms",[${rating}]]`);
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      appName !== null ||
      rating.length !== 0 ||
      searchQuery !== "" ||
      customerName !== null ||
      sentimental_val !== null ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["review_created_at","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        order_by: `[["review_created_at","desc"]]`,
        // page_number: 0,
        page_limit: `${page_limit}`,
      };
    }

    fetchPlayStore(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // ****************************************************** applyFilter Function

  const applyFilter = () => {
    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["review_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (appName !== null) {
      filter.push(`["app_name.keyword","must","","match","${appName}"]`);
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (rating.length !== 0) {
      filter.push(`["ratings.keyword","must","","terms",[${rating}]]`);
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      appName !== null ||
      rating.length !== 0 ||
      (date[0] !== null && date[1] !== null) ||
      searchQuery !== "" ||
      customerName !== null ||
      sentimental_val !== ""
    ) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["review_created_at","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please add filters");
    }
    fetchPlayStore(params);
  };

  // // ************************************************************ HandleChange
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["review_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (appName !== null) {
      filter.push(`["app_name.keyword","must","","match","${appName}"]`);
    }
    if (rating.length !== 0) {
      filter.push(`["ratings.keyword","must","","terms",[${rating}]]`);
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (FilterClicked) {
      if (
        appName !== null ||
        customerName !== null ||
        rating.length !== 0 ||
        (date[0] !== null && date[1] !== null) ||
        searchQuery !== "" ||
        sentimental_val !== ""
      ) {
        params = {
          filters: `[${filter}]`,
          order_by: `[["review_created_at","desc"]]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
        };
      }
    } else {
      params = {
        order_by: `[["review_created_at","desc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchPlayStore(params);
    }
  };

  // // ************************************** appNameHandleChange
  const appNameHandleChange = (event, value) => {
    if (value != null) {
      setAppName(value);
    } else {
      setAppName(null);
    }
  };

  // ***************************************  Rating HandleChange
  const ratingHandleChange = (event, value) => {
    if (value != null) {
      setRating(value);
    } else {
      setRating("");
    }
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val("");
    }
  };

  // *************************************AppName Filter List
  let filterlist = [];

  if (props.playstorefilter.hits) {
    props.playstorefilter.hits.forEach((value) => {
      filterlist.push(value._source.app_name && value._source.app_name);
    });
  }

  const uniqueList = [...new Set(filterlist)];

  //*******************************  clearState Functions ******************
  const ClearState = () => {
    setFilterClicked(false);
    if (
      appName !== null ||
      rating.length !== 0 ||
      replyState !== "" ||
      searchQuery !== "" ||
      customerName !== null ||
      (date[0] !== null && date[1] !== null) ||
      sentimental_val !== ""
    ) {
      setAppName(null);
      setRating([]);
      setReplyState("");
      setSearchQuery("");
      setDate([null, null]);
      setSentimental_val("");
      setClearLoader(true);
      setCustomerName(null);

      const params = {
        order_by: `[["review_created_at","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      FilterClicked && fetchPlayStore(params);
    }
  };

  let maxDate = new Date();

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);
    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;
    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number"
    ) {
      if (item._source.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item._source.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item._source.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }

    return sentiment;
  };

  //Customer names list assigning
  let customerList = [];

  if (props.playstorefilter.hits) {
    props.playstorefilter.hits.forEach((val) => {
      customerList.push(val._source.customer_name);
    });
  }
  const uniqueCustomerList = [...new Set(customerList)];

  const CustomerHandleChange = (e, val) => {
    setCustomerName(val);
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}
      <Grid container>
        {/*Playstore filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            // borderRadius: theme.palette.buttonComponent.borderRadius.large,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            mt={2}
            container
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
          >
            <Grid
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              // alignItems={"center"}
              spacing={1}
            >
              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>App Name</p>
                </Grid>
                <AutoCompleteComponent
                  value={appName}
                  handleChange={appNameHandleChange}
                  list={uniqueList}
                  textFieldProps={{
                    placeholder: "App Name",
                  }}
                  getOptionLabel={(option) => (option ? option : "")}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Customer Name</p>
                </Grid>
                <AutoCompleteComponent
                  value={customerName}
                  list={uniqueCustomerList}
                  textFieldProps={{
                    placeholder: "Customer Name",
                  }}
                  handleChange={CustomerHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={2}>
                  <span>Search Content</span>
                </Grid>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      borderRadius:
                        theme.palette.buttonComponent.borderRadius.medium,
                      outline: `1px ${theme.palette.borderColor.textField}`,
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.typography.padding,
                      height: "35px",
                      width: "100%",
                    }}
                    id="search_bar"
                    autoComplete={"off"}
                    value={searchQuery}
                    placeholder={"Search by Content"}
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment>
                        <IconButton onClick={applyFilter}>
                          <SearchIcon
                            sx={{
                              "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={1.5} lg={1.5} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Ratings</p>
                </Grid>
                <Grid style={{ position: "relative" }}>
                  <Grid
                    item
                    xl={12}
                    style={{
                      position: "absolute",
                      width: "95%",
                      zIndex: 999,
                    }}
                  >
                    <AutoCompleteComponent
                      value={rating}
                      handleChange={ratingHandleChange}
                      list={star}
                      textFieldProps={{
                        placeholder: rating.length > 0 ? null : "Ratings",
                      }}
                      size="small"
                      width="100%"
                      multiple={true}
                      limitTags={1}
                      disableCloseOnSelect={true}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={1.5} lg={2} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>

              <Grid item xl={1.5} lg={2} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentimental Value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={3} lg={2} md={12} sm={12} mt={0}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={3}
                >
                  <Grid
                    item
                    // xl={3.5} lg={6} md={3} sm={5}
                  >
                    <MuiButton
                      width="100%"
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid
                    item
                    // xl={3.5} lg={6} md={3} sm={5}
                  >
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <h5 className="pl-3 pr-3 pt-2"></h5> */}

        {/* <Divider sx={{ borderColor: theme.palette.background.button }} /> */}
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "8px", marginBottom: "8px" }} />
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,
          // borderRadius: theme.palette.buttonComponent.borderRadius.large,
          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
        >
          {/* <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Reviews</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid> */}
          <LoadingIndicator isActive={isRequesting}>
            {props.playstore.hits ? (
              <Box width="100%">
                {props.playstore.hits.length !== 0 ? (
                  props.playstore.hits.map((val, key) => (
                    <Box
                      sx={{
                        padding: 0,
                        margin: "1.2%",
                        marginTop: 0,
                        marginBottom: "0.5%",
                      }}
                    >
                      <List
                        sx={{
                          cursor:
                            val && val._source.ticket_id ? "pointer" : null,
                          borderRadius: theme.borderRadius,
                        }}
                        onClick={() => {
                          localStorage.setItem("activeTab", 0);

                          val._source &&
                            val._source.ticket_id &&
                            openTicketFunction(val._source.ticket_id);
                        }}
                        // className="bg-warning"
                      >
                        <Grid
                          container
                          sx={{
                            backgroundColor: "#fff",
                            height: "auto",
                            padding: 2,
                            borderRadius: theme.borderRadius,
                            border: "solid 0.5px #d3e2e6",
                            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                          }}
                        >
                          <Grid
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            alignItems="flex-start"
                          >
                            <Grid
                              item
                              lg={1}
                              md={2}
                              sm={2}
                              alignSelf="center"
                              maxWidth={"80px !important"}
                            >
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar
                                    alt="image"
                                    src={val._source.customer_image}
                                    className={classes.avatar}
                                  />
                                </ListItemAvatar>
                              </ListItem>
                            </Grid>
                            <Grid item lg={10} md={9} sm={9} container>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                direction={{
                                  lg: "row",
                                  xl: "row",
                                  md: "row",
                                  sm: "column-reverse",
                                }}
                              >
                                <Grid item lg={4} md={6} sm={5}>
                                  <ListItem>
                                    <p>{val._source.customer_name}</p>
                                  </ListItem>
                                </Grid>
                                <Grid item lg={3} md={6} sm={5.5}>
                                  <ListItem>
                                    <p>
                                      {(function createdDate() {
                                        let created = val._source.created_on;
                                        let dateConversion = new Date(created);
                                        dateConversion =
                                          dateConversion.toDateString() +
                                          ", " +
                                          dateConversion.toLocaleTimeString(
                                            "en-US",
                                            {
                                              hour: "numeric",
                                              minute: "numeric",
                                              hour12: true,
                                            }
                                          );

                                        return dateConversion;
                                      })()}
                                    </p>
                                  </ListItem>
                                </Grid>
                              </Grid>

                              <Grid item lg={12} md={12} sm={12}>
                                <ListItem>
                                  <ListItemText>
                                    Rating : {val._source.ratings} Stars
                                  </ListItemText>
                                </ListItem>
                              </Grid>

                              {/* <Grid item lg={12} md={12} sm={12}>
                              <ListItem>
                                <h6>Sentiment value</h6>&nbsp;&nbsp;&nbsp;
                                <p>
                                  {val._source.sentiment_value
                                    ? val._source.sentiment_value
                                    : ""}
                                </p>
                              </ListItem>
                            </Grid> */}

                              <Grid item lg={12} md={12} sm={12}>
                                <ListItem>
                                  <ListItemText>
                                    Review : {val._source.review_content}
                                  </ListItemText>
                                </ListItem>
                              </Grid>

                              <Grid item lg={12} md={12} sm={12}>
                                {val._source.sentiment_value !== null ? (
                                  <ListItem className="p-0 m-0 ml-3">
                                    <ListItemText>
                                      <b
                                        style={{
                                          fontSize:
                                            theme.palette.buttonComponent
                                              .fontSize.extraLarge,
                                          // lineHeight: "22px",
                                        }}
                                      >
                                        Sentiment value :
                                      </b>
                                      &nbsp;
                                      {val._source.sentiment_value &&
                                      typeof val._source.sentiment_value ===
                                        "number" &&
                                      val._source.sentiment_value > 0 ? (
                                        <IconButton
                                          className="p-0 m-0"
                                          title={returnSentimentname(val)}
                                        >
                                          <SentimentSatisfiedAltRoundedIcon
                                            titleAccess="Positive"
                                            style={{
                                              width: "16px",
                                              color: `green`,
                                            }}
                                          />
                                        </IconButton>
                                      ) : val._source.sentiment_value < 0 ? (
                                        <IconButton
                                          className="p-0 m-0"
                                          title={returnSentimentname(val)}
                                        >
                                          <SentimentDissatisfiedRoundedIcon
                                            titleAccess="Negative"
                                            style={{
                                              width: "16px",
                                              color: `${theme.palette.sentimentIconColor.negative}`,
                                            }}
                                          />
                                        </IconButton>
                                      ) : val._source.sentiment_value !==
                                        null ? (
                                        <IconButton
                                          className="p-0 m-0"
                                          title={returnSentimentname(val)}
                                        >
                                          <SentimentNeutralRoundedIcon
                                            titleAccess="Neutral"
                                            style={{
                                              width: "16px",
                                              color: `${theme.palette.sentimentIconColor.neutral}`,
                                            }}
                                          />
                                        </IconButton>
                                      ) : (
                                        ""
                                      )}
                                    </ListItemText>
                                  </ListItem>
                                ) : null}
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              container
                              lg={1}
                              md={2}
                              sm={2}
                              alignItems={"flex-start"}
                              justifyContent="space-between"
                            >
                              {val._source.feed_link && (
                                <Grid
                                  item
                                  // className="bg-info"
                                  lg={1}
                                  xl={1}
                                  md={1}
                                  sm={1}
                                >
                                  <Typography
                                    component={"a"}
                                    href={
                                      val._source.feed_link
                                        ? val._source.feed_link
                                        : ""
                                    }
                                    target="_blank"
                                    title="Feed Link"
                                  >
                                    {/* <OpenInNewIcon
                                    fontSize="25px"
                                    sx={{ cursor: "pointer" }}
                                  /> */}
                                  </Typography>
                                </Grid>
                              )}

                              {circuler_progress_id === val._source.ticket_id &&
                                open_ticket && (
                                  <CircularProgress
                                    color="inherit"
                                    size={15}
                                    // className="bg-light"
                                  />
                                )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                          {circuler_progress_id === val._source.ticket_id &&
                            open_ticket && (
                              <CircularProgress
                                color="inherit"
                                size={15}
                                // className="bg-light"
                              />
                            )}
                        </Grid>

                        {val._source.replied === true ? (
                          <List style={{ marginLeft: "auto" }}>
                            <ListItem>
                              <Button
                                variant="outlined"
                                // style={{ cursor: "none" }}
                              >
                                Replied
                              </Button>
                            </ListItem>
                          </List>
                        ) : null}
                      </List>
                    </Box>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </Box>
            ) : null}
          </LoadingIndicator>
        </Box>
      </Grid>
      {props.playstore.hits
        ? props.playstore.hits.length !== 0 && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(PlayStoreListView);
