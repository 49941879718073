import BaseReducer from "../../utilities/BaseReducer";
import LastSyncDateAction from "./lastSyncdateAction";

// last sync date reducer
export default class LastSyncDateReducer extends BaseReducer {
  initialState = {
    lastSyncDate: {},
  };

  [LastSyncDateAction.LAST_SYNC_DATE_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      lastSyncDate: action.payload,
    };
  }
}
