import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { update } from "lodash";

function TicketsByCategory(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [platformwiseTickets, setPlatformwiseTickets] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformwiseTicketsByCategory(props.DateFilter).then((res) => {
        setPlatformwiseTickets(res);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.DateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchPlatformwiseTicketsByCategory(props.openDateFilter).then(
          (res) => {
            setPlatformwiseTickets(res);
          }
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchPlatformwiseTicketsByCategory(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["multilevel_category"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  // Function to get the maximum depth of the nested structure
  function getMaxDepth(item) {
    if (!item.children || item.children?.length === 0) {
      return 1;
    }
    return 1 + Math.max(...item.children.map(getMaxDepth));
  }

  // Get the maximum depth of the nested structure
  const maxDepth =
    platformwiseTickets?.ticket_report?.multilevel_category[0] &&
    getMaxDepth(platformwiseTickets?.ticket_report?.multilevel_category[0]);

  // Create columns for each level dynamically

  const slotColumnCommonFields = {
    sortable: false,
    filterable: false,
    pinnable: false,
    hideable: false,
    cellClassName: (params) => params.value,
  };

  let columns = [
    {
      field: "id",
      headerName: "Sl",
      resizable: false,
      width: 50,
      hide: true,
      ...slotColumnCommonFields,
    },
  ];

  for (let i = 0; i < maxDepth; i++) {
    columns.push({
      field: `CategoryLevel${i + 1}`,
      headerName: `Category Level ${i + 1}`,
      resizable: true,
      width: 200,
      hide: false,
      ...slotColumnCommonFields,
      renderCell: (params) => {
        let backgroundColor = "transparent";

        return (
          <div
            className={`${
              params?.row?.id === updatedRows.length
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
            style={{
              backgroundColor,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {params.row[`CategoryLevel${i + 1}`]}
          </div>
        );
      },
    });
  }

  // Function to flatten the nested structure and create rows
  function flattenCategories(item, level = 0, parentRow = {}) {
    const currentRow = { ...parentRow };
    // currentRow[`CategoryLevel${level + 1}`] = `${item.value} (${item.count})`;
    currentRow[`CategoryLevel${level + 1}`] = item.value
      ? `${item.value} (${item.count})`
      : ` (${item.count})`;

    if (!item.children || item.children.length === 0) {
      // If no children, push the row to updatedRows
      updatedRows.push({ id: updatedRows.length + 1, ...currentRow });
    } else {
      // If there are children, continue to flatten the structure recursively
      item.children.forEach((child) => {
        flattenCategories(child, level + 1, currentRow);
      });
    }
  }

  // Flatten the nested structure starting from the root categories
  const updatedRows = [];
  platformwiseTickets?.ticket_report?.multilevel_category[0]?.children?.forEach(
    (item) => {
      return flattenCategories(item);
    }
  );

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };
  if (
    updatedRows?.length > 0 &&
    platformwiseTickets?.ticket_report?.multilevel_category?.[2]
  ) {
    const newRow = { id: updatedRows.length + 1 }; // Initialize a single object with the `id`

    Object.entries(
      platformwiseTickets.ticket_report.multilevel_category[2]
    ).forEach(([key, value], index) => {
      if (index > 0) {
        if (index === 1) newRow[`CategoryLevel${index}`] = `Total (${value})`;
        else {
          newRow[`CategoryLevel${index}`] = `${value}`;
        }
      }
    });

    updatedRows.push(newRow);
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      props.setexcelData(updatedRows);
    }
  }, [props.DownloadClicked, updatedRows]);

  const rootStyles = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "transparent",
  };

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {!loading &&
            updatedRows.length > 0 &&
            props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {props.dataGridHeight.current && (
                    <Box sx={rootStyles}>
                      <DataGridFunction
                        rows={updatedRows}
                        columns={columns}
                        height={props.dataGridHeight.current}
                        rowHeight={50}
                        headerHeight={50}
                        onCellClick={onCellClick}
                        rowBorder={false}
                        rowBorderCustom={true}
                        rowColor={true}
                        headerColor={true}
                        unstable_rowSpanning={true}
                        getRowClassName={(params) => ""}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              id: false,
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default TicketsByCategory;
