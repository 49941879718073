import BaseReducer from "../../../../utilities/BaseReducer";
import GoogleSearchAction from "./GoogleSearchAction";

export default class GoogleDiscoveredDataGetReduser extends BaseReducer {
  initialState = {
    websweepxGoogleSearchDiscovered: [],
  };
  [GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH_DISCOVERED_DATA_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepxGoogleSearchDiscovered: action.payload,
    };
  }
}
