import BaseReducer from "../../../../utilities/BaseReducer";
import TelegramChannelAction from "./TelegramChannelAction";

export default class TelegramChannelGetReduser extends BaseReducer {
  initialState = {
    websweepxTelegramChannelMonitoring: [],
  };
  [TelegramChannelAction.REQUEST_GET_TELEGRAM_CHANNEL_FINISHED](state, action) {
    return {
      ...state,
      websweepxTelegramChannelMonitoring: action.payload,
    };
  }
}
