import ArtistFilterReduser from "./artist/ArtistFilterReduser";
import ArtistReduser from "./artist/ArtistReduser";
import AssetsFilterReduser from "./assets/AssetsFilterReduser";
import AssetsReduser from "./assets/AssetsReduser";
import ClientFilterReduser from "./client/ClientFilterReduser";
import ClientReduser from "./client/ClientReduser";
import GenreFilterReduser from "./genre/GenreFilterReduser";
import GenreReduser from "./genre/GenreReduser";
import LabelFilterReduser from "./label/LabelFilterReduser";
import LabelReducer from "./label/LabelReducer";
import LanguageReduser from "./language/LanguageReduser";
import PlanFilterReduser from "./plan/PlanFilterReduser";
import PlanReduser from "./plan/PlanReduser";
import ReleaseByIdReducer from "./releases/ReleaseByIdReducer";
import ReleasesFilterReducer from "./releases/ReleasesFilterReducer";
import ReleasesReducer from "./releases/ReleasesReducer";
import ResourceReduser from "./resource/ResourceReduser";
import StatusFilterReduser from "./status/StatusFilterReduser";
import StatusReduser from "./status/StatusReduser";
import StreamPlatformFilterReduser from "./streamPlatform/StreamPlatformFilterReduser";
import StreamPlatformReduser from "./streamPlatform/StreamPlatformReduser";
import SubGenreFilterReducer from "./subgenre/SubGenreFilterReducer";
import SubGenreReducer from "./subgenre/SubGenreReducer";
import TerritoriesReduser from "./territories/TerritoriesReduser";
import TimeZoneReduser from "./timezone/TimeZoneReducer";
import TrackFilterReduser from "./track/TrackFilterReduser";
import TrackReduser from "./track/TrackReduser";
import UserResourceFilterReducer from "./userResource/UserResourceFilterReducer";
import UserResourceReducer from "./userResource/UserResourceReducer";
import UserProfileReducer from "./userprofile/UserProfileReducer";
import ReleaseShadowReduser from "./releases/ReleaseShadowReduser";
import TopReleaseReduser from "./topReleaseReport/TopReleaseReduser";
import TopStoreReduser from "./topStoresReport/TopStoreReduser";
import ReleasesShadowTableReduser from "./releasesShadowTable/ReleasesShadowTableReduser";
import ReleasesShadowTableIdReduser from "./releasesShadowTable/ReleasesShadowTableIdReduser";
import DraftReleaseReduser from "./releases/DraftReleaseReduser";
import DraftReleaseByIdReducer from "./releases/DraftReleaseByIdReducer";
import DraftReleaseFilterReducer from "./releases/DraftReleaseFilterReducer";
import ValidationByIdReducer from "./validation/ValidationByIdReducer";
import ClientByIdReducer from "./client/ClientByIdReducer";
import ClientRevenueSharePolicyReduser from "./clientRevenueSharingPolicy/ClientRevenueSharePolicyReducer";
import TopTrackReduser from "./topTrackReport/TopTrackReduser";
import TopTrackFilterReduser from "./topTrackReport/TopTrackFilterReduser";
import TopArtistReducer from "./topArtistReport/TopArtistReducer";
import TopArtistFilterReducer from "./topArtistReport/TopArtistFilterReducer";
import TopLabelReducer from "./topLabelReport/TopLabelReducer";
import TopLabelFilterReducer from "./topLabelReport/TopLabelFilterReducer";
import TopTerritoriesReducer from "./TopTerritories/TopTerritoriesReducer";
import TopTerritoriesFilterReducer from "./TopTerritories/TopTerritoriesFilterReducer";
import TopLanguagesReduser from "./topLanguages/TopLanguagesReduser";
import TopLanguagesFilterReduser from "./topLanguages/TopLanguagesFilterReduser";
import TopComposerReducer from "./topComposer/TopComposerReducer";
import TopComposerFilterReducer from "./topComposer/TopComposerFilterReducer";
import TopAlbumReducer from "./topAlbum/TopAlbumReducer";
import TopAlbumFilterReducer from "./topAlbum/TopAlbumFilterReducer";
import TopLyricistReducer from "./topLyricist/TopLyricistReducer";
import TopLyricistFilterReducer from "./topLyricist/TopLyricistFilterReducer";
import ActorReducer from "./actor/ActorReducer";
import ActorFilterReducer from "./actor/ActorFilterReducer";
import ActressReducer from "./actress/ActressReducer";
import ActressFilterReducer from "./actress/ActressFilterReducer";
import DirectorReducer from "./director/DirectorReducer";
import DirectorFilterReducer from "./director/DirectorFilterReducer";
import RevenueReportReducer from "./revenueReport/RevenueReportReducer";
import RevenueReportFilterReducer from "./revenueReport/RevenueReportFilterReducer";
import TrackRevenueReducer from "./trackRevenue/TrackRevenueReducer";
import IngBannerListReduser from "./label/IngBannerListReduser";
import TrackByIdReduser from "./track/TrackByIdReduser";
import RevenueTransactionReducer from "./revenueTransaction/RevenueTransactionReducer";
import ReleaseStatusCountReduser from "./releaseStatusCountReport/ReleaseStatusCountReduser";
import BlogsReducer from "./blogs/BlogsReducer";
import BlogsFilterReducer from "./blogs/BlogsFilterReducer";
import TopFiveDeliveredReleasesReduser from "./releases/TopFiveDeliveredReleasesReduser";
import YoutubeOfficialReduser from "./youtubeOfficial/YoutubeOfficialReduser";
import YoutubeChannelRevenueReduser from "./youtubeOfficial/YoutubeChannelRevenueReduser";
import YoutubeContentDownloadReduser from "./youtubeOfficial/YoutubeContentDownloadReduser";
import MigrateSpotifyTracksReducer from "./migrateTracks/MigrateTracksReducer";
import A3ReportsLabelReducer from "./reportsA3/A3ReportsLabelReducer";
import A3ReportsArtistReducer from "./reportsA3/A3ReportsArtistReducer";
import A3ReportsReleaseReducer from "./reportsA3/A3ReportsReleaseReducer";
import YoutubeTrackClaimsReduser from "./youtubeOriginal/YoutubeTrackClaimsReduser";
import YoutubeAFPReportsReduser from "./youtubeOriginal/YoutubeAFPReportsReduser";
import A3ReportsStorewiseReducer from "./reportsA3/A3ReportsStorewiseReducer";
import A3ReportsMonthwiseReducer from "./reportsA3/A3ReportsMonthwiseReducer";
import ExistingTracksReducer from "./releases/ExistingTracksReducer";
import ExistingTrackReduser from "./track/ExistingTrackReduser";
import TopFiveDraftReleasesReduser from "./releases/TopFiveDraftReleasesReduser";
import FeedbackReducer from "./feedback/FeedBackReducer";
import SessionLogReducer from "./sessionLogs/SessionLogReducer";
import SessionLogReportReducer from "./sessionLogs/SessionLogReportReducer";
import A3NotificationReducer from "./notificationsA3/A3NotificationReducer";
import A3NotificationFilterReducer from "./notificationsA3/A3NotificationFilterReducer";
import A3ReportsCountrywiseReducer from "./reportsA3/A3ReportsCountrywiseReducer";
import MailLogsReducer from "./mailLogs/MailLogsReducer";
import SubClientFilterReducer from "./client/SubClientFilterReducer";
import A3LabelRevenueReportReducer from "./reportsA3/A3LabelRevenueReportReducer";
import TrackwiseRevenueReduser from "./reportsA3/TrackwiseRevenueReduser";
import ReleasewiseRevenueReduser from "./reportsA3/ReleasewiseRevenueReduser";
import ArtistwiseRevenueReduser from "./reportsA3/ArtistwiseRevenueReduser";
import NetRevenueByMonthReducer from "./netRevenueReportByMonth/NetRevenueByMonthReducer";
import NetRevenueByMonthFilterReducer from "./netRevenueReportByMonth/NetRevenueByMonthFilterReducer";
import YTRevenueReportFilterReducer from "./ytRevenueReport/YTRevenueReportFilterReducer";
import YTRevenueReportReducer from "./ytRevenueReport/YTRevenueReportReducer";
import YTRevenueReportExportReducer from "./ytRevenueReport/YTRevenueReportExportReducer";
import RevenueReportExportReducer from "./revenueReport/RevenueReportExportReducer";
import TopTerritoryReduser from "./TopTerritories/TopTerritoryReduser";
import TopRetailerReduser from "./topStoresReport/TopRetailerReduser";
import AudioStreamsReduser from "./topStoresReport/AudioStreamsReduser";
import TrackwiseRevenueSummaryReducer from "./trackRevenue/TrackwiseRevenueSummaryReducer";
import RevenueClaimReducer from "./revenueClaim/RevenueClaimReducer";
import ClientRevenueBalanceReducer from "./revenueClaim/ClientRevenueBalanceReducer";
import ClaimRequestReducer from "./revenueClaimRequest/ClaimRequestReducer";
import ClaimRequestByIdReducer from "./revenueClaimRequest/ClaimRequestByIdReducer";
import InvoiceClaimDetailsReducer from "./revenueClaimRequest/InvoiceClaimDetailsReducer";
import RevenueReportErrorsReducer from "./revenueReportErrors/RevenueReportErrorsReducer";
import ClientwiseRevenueClaimReducer from "./revenueClaim/ClientwiseRevenueClaimReducer";
import ActvityInsightReducer from "./activityInsight/ActivityInsightReducer";
import ActvityInsightReportReducer from "./activityInsight/ActivityInsightReportReducer";
import CsvUploadSummaryReducer from "./csvReportUpload/CsvUploadSummaryReducer";
import TrackStatusCountReduser from "./track/TrackStatusCountReduser";
import ClientWiseRevenueReduser from "./reportsA3/ClientWiseRevenueReduser";
import InrRatesFilterReduser from "./inrRates/InrRatesFilterReduser";
import InrRatesReduser from "./inrRates/InrRatesReduser";
import CsvUploadSummaryByIDReducer from "./csvReportUpload/CsvUploadSummaryByIDReducer";
import BatchFilterReduser from "./batch/BatchFilterReduser";
import BatchReduser from "./batch/BatchReduser";
import ChangeRequestReleasesReducer from "./releases/ChangeRequestReleasesReducer";
import ChangeRequestReleaseByIdReducer from "./releases/ChangeRequestReleaseByIdReducer";
import ChangeRequestTrackReduser from "./track/ChangeRequestTrackReduser";
import GetClientEmailReduser from "./blogs/GetClientEmailReduser";
import GetConfiguredEmailReduser from "./blogs/GetConfiguredEmailReduser";

export const a3RootReducer = {
  //A3 Labels
  statusA3: new StatusReduser().reducer,
  statusFilterA3: new StatusFilterReduser().reducer,

  batchA3Filter: new BatchFilterReduser().reducer,
  batchA3: new BatchReduser().reducer,

  label: new LabelReducer().reducer,
  labelFilter: new LabelFilterReduser().reducer,
  ing_banner_list: new IngBannerListReduser().reducer,

  assets: new AssetsReduser().reducer,
  assetsFilter: new AssetsFilterReduser().reducer,

  artist: new ArtistReduser().reducer,
  artistFilter: new ArtistFilterReduser().reducer,

  track: new TrackReduser().reducer,
  trackFilter: new TrackFilterReduser().reducer,
  trackById: new TrackByIdReduser().reducer,
  existingTracks: new ExistingTrackReduser().reducer,
  changeRequestTrack: new ChangeRequestTrackReduser().reducer,

  genre: new GenreReduser().reducer,
  genreFilter: new GenreFilterReduser().reducer,

  inrRates: new InrRatesReduser().reducer,
  inrRatesFilter: new InrRatesFilterReduser().reducer,

  stream_platform: new StreamPlatformReduser().reducer,
  stream_platform_filter: new StreamPlatformFilterReduser().reducer,

  subgenre: new SubGenreReducer().reducer,
  subgenreFilter: new SubGenreFilterReducer().reducer,

  clientA3: new ClientReduser().reducer,
  clientFilterA3: new ClientFilterReduser().reducer,
  clientById: new ClientByIdReducer().reducer,
  subclientFilterA3: new SubClientFilterReducer().reducer,

  release: new ReleasesReducer().reducer,
  changeRequestRelease: new ChangeRequestReleasesReducer().reducer,

  top5Delivered: new TopFiveDeliveredReleasesReduser().reducer,
  top5Draft: new TopFiveDraftReleasesReduser().reducer,
  releaseFilter: new ReleasesFilterReducer().reducer,
  releaseById: new ReleaseByIdReducer().reducer,
  releaseById2: new ExistingTracksReducer().reducer,
  releaseStatusCount: new ReleaseStatusCountReduser().reducer,
  trackStatusCount: new TrackStatusCountReduser().reducer,

  //userprofile
  userprofile: new UserProfileReducer().reducer,

  //plan
  plan: new PlanReduser().reducer,
  planFilter: new PlanFilterReduser().reducer,

  //territories
  territories: new TerritoriesReduser().reducer,

  //timezone
  timezone: new TimeZoneReduser().reducer,

  //language
  language: new LanguageReduser().reducer,

  userResource: new UserResourceReducer().reducer,
  userResourceFilter: new UserResourceFilterReducer().reducer,

  releaseshadoow: new ReleaseShadowReduser().reducer,
  draftrelease: new DraftReleaseReduser().reducer,
  draftReleaseById: new DraftReleaseByIdReducer().reducer,
  draftreleaseFilter: new DraftReleaseFilterReducer().reducer,
  changeRequestReleaseById: new ChangeRequestReleaseByIdReducer().reducer,

  //Top Track reports
  topTrack: new TopTrackReduser().reducer,
  topTrackFilter: new TopTrackFilterReduser().reducer,

  //Top artist reports
  topArtist: new TopArtistReducer().reducer,
  topArtistFilter: new TopArtistFilterReducer().reducer,

  //actor
  actor: new ActorReducer().reducer,
  actorFilter: new ActorFilterReducer().reducer,

  //actress
  actress: new ActressReducer().reducer,
  actressFilter: new ActressFilterReducer().reducer,

  //director
  director: new DirectorReducer().reducer,
  directorFilter: new DirectorFilterReducer().reducer,

  //Top label reports
  topLabel: new TopLabelReducer().reducer,
  topLabelFilter: new TopLabelFilterReducer().reducer,

  //Top territory reports
  top_territories_report: new TopTerritoriesReducer().reducer,
  top_territories_report_filter: new TopTerritoriesFilterReducer().reducer,
  top_territory: new TopTerritoryReduser().reducer,

  //Top language reports
  top_languages_report: new TopLanguagesReduser().reducer,
  top_territories_report_filter: new TopLanguagesFilterReduser().reducer,

  //Top composor reports
  top_composers_report: new TopComposerReducer().reducer,
  top_composer_report_filter: new TopComposerFilterReducer().reducer,

  //Top lyricist reports
  top_lyricists_report: new TopLyricistReducer().reducer,
  top_lyricists_report_filter: new TopLyricistFilterReducer().reducer,

  //Top album reports
  top_albums_report: new TopAlbumReducer().reducer,
  top_albums_report_filter: new TopAlbumFilterReducer().reducer,

  //Top Releases reports
  topRelease: new TopReleaseReduser().reducer,

  //Top Stores reports
  topStore: new TopStoreReduser().reducer,
  topRetailer: new TopRetailerReduser().reducer,
  audioStreams: new AudioStreamsReduser().reducer,

  resource: new ResourceReduser().reducer,

  //releasesShadowTable
  releasesShadowTable: new ReleasesShadowTableReduser().reducer,
  releasesShadowTableById: new ReleasesShadowTableIdReduser().reducer,

  // validation of release by id
  validateReleaseById: new ValidationByIdReducer().reducer,

  clientRevenueSharePolicy: new ClientRevenueSharePolicyReduser().reducer,
  revenueReport: new RevenueReportReducer().reducer,
  revenueReportFilter: new RevenueReportFilterReducer().reducer,
  revenueTransaction: new RevenueTransactionReducer().reducer,
  trackRevenue: new TrackRevenueReducer().reducer,

  // blogs
  blogs: new BlogsReducer().reducer,
  get_client_email_address: new GetClientEmailReduser().reducer,
  get_configured_email_address: new GetConfiguredEmailReduser().reducer,
  blogsFilter: new BlogsFilterReducer().reducer,

  //Youtube Official / CMS
  youtubeOfficial: new YoutubeOfficialReduser().reducer,
  youtubeChannelRevenue: new YoutubeChannelRevenueReduser().reducer,
  youtubeContentDownload: new YoutubeContentDownloadReduser().reducer,

  //Migrate Tracks
  spotifyMigrateTrack: new MigrateSpotifyTracksReducer().reducer,

  //ClientWise Reports
  clientLabelReport: new A3ReportsLabelReducer().reducer,
  clientArtistReport: new A3ReportsArtistReducer().reducer,
  clientReleaseReport: new A3ReportsReleaseReducer().reducer,

  //YoutubeOriginals
  youtubeTrackClaims: new YoutubeTrackClaimsReduser().reducer,
  youtubeAFPReports: new YoutubeAFPReportsReduser().reducer,

  //Revenue Reports
  storewiseRevenueReport: new A3ReportsStorewiseReducer().reducer,
  monthwiseRevenueReport: new A3ReportsMonthwiseReducer().reducer,
  countrywiseRevenueReport: new A3ReportsCountrywiseReducer().reducer,
  labelRevenueReport: new A3LabelRevenueReportReducer().reducer,
  trackwiseRevenue: new TrackwiseRevenueReduser().reducer,
  clientwiserevnue: new ClientWiseRevenueReduser().reducer,
  releasewiseRevenue: new ReleasewiseRevenueReduser().reducer,
  artistwiseRevenue: new ArtistwiseRevenueReduser().reducer,
  trackwiseRevenueSummary: new TrackwiseRevenueSummaryReducer().reducer,

  feedback: new FeedbackReducer().reducer,
  activityInsight: new ActvityInsightReducer().reducer,
  activityInsightReport: new ActvityInsightReportReducer().reducer,

  //Logs
  sessionLog: new SessionLogReducer().reducer,
  sessionLogReport: new SessionLogReportReducer().reducer,
  mailLogs: new MailLogsReducer().reducer,

  //A3Notifications
  A3Notifications: new A3NotificationReducer().reducer,
  A3NotificationsFilter: new A3NotificationFilterReducer().reducer,

  netRevenueByMonth: new NetRevenueByMonthReducer().reducer,
  netRevenueByMonthFilter: new NetRevenueByMonthFilterReducer().reducer,

  // Youtube Revenue report
  ytRevenueReport: new YTRevenueReportReducer().reducer,
  ytRevenueReportFilter: new YTRevenueReportFilterReducer().reducer,
  ytRevenueReportExport: new YTRevenueReportExportReducer().reducer,
  revenueReportExport: new RevenueReportExportReducer().reducer,

  revenueClaim: new RevenueClaimReducer().reducer,
  clientwiseRevenueClaim: new ClientwiseRevenueClaimReducer().reducer,
  clientRevenueBalance: new ClientRevenueBalanceReducer().reducer,
  revenueClaimRequest: new ClaimRequestReducer().reducer,
  claimRequestById: new ClaimRequestByIdReducer().reducer,
  invoiceClaimDetails: new InvoiceClaimDetailsReducer().reducer,
  revenueReportErrors: new RevenueReportErrorsReducer().reducer,
  csvUploadSummary: new CsvUploadSummaryReducer().reducer,
  csvUploadSummaryByID: new CsvUploadSummaryByIDReducer().reducer,
};
