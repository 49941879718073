import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MuiButton from "../../../components/muiButton/MuiButton";
import RolePermissionAction from "../../stores/rolePermissions/RolePermissionsAction";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function ManageUserPermissionPost(props) {
  // Validation Schema
  const validationSchema = yup.object({
    role: yup.object().shape({}).required("Role is Required").nullable(),

    resource: yup
      .object()
      .shape({})
      .required("Resource type is Required")
      .nullable(),
  });

  let resourceKeys = [];

  props.OrmResource &&
    props.OrmResource.map((item) => {
      resourceKeys.push(Object.keys(item)[0]);
    });

  const returnEnabledValue = (values, val) => {
    let filtered_array = values.operation.filter((item) => {
      return item.operation_id === val.operation_id;
    });

    if (filtered_array.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [resourceKey, setResourceKey] = useState(null);

  useEffect(() => {
    if (resourceKeys && resourceKeys.length === 1) {
      setResourceKey(resourceKeys[0]);
    } else {
      setResourceKey(null);
    }
  }, [props]);

  let ResourceList = [];

  props.OrmResource &&
    props.OrmResource.filter(
      (item) => Object.keys(item)[0] === resourceKey
    ).map((item) => {
      ResourceList.push(Object.values(item)[0]);
    });

  const [loading, setLoading] = useState(false);

  // INITIAL VALUES ASSIGNING for POST form
  let initial = {
    resource_key:
      resourceKeys && resourceKeys.length === 1 ? resourceKeys[0] : "",
    role:
      props.roleList && props.roleList.length === 1 ? props.roleList[0] : "",
    resource: "",
    operation: [],
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      {/* Scope of Formik starts here */}
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};
          if (!values.operation.length) {
            errors.operation = "Operation are required";
          }

          if (!values.resource_key) {
            errors.resource_key = "Resource Key is required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let postData = {
            role_id: values.role.id,
            resource_id: String(values.resource.resource_id),
            operation: props.operationsList.map((val) => {
              return {
                operation_id: val.operation_id,
                operation_name: val.operation_name,
                enabled: returnEnabledValue(values, val),
              };
            }),
          };

          const params = {
            page_limit: 15,
          };

          props
            .dispatch(
              RolePermissionAction.requestRolePermissionPost(
                JSON.stringify(postData)
              )
            )
            .then(
              () => props.callback(),
              props.onClose(),

              props.dispatch(
                RolePermissionAction.requestRolePermission(params)
              ),
              setLoading(false)
            );
          // formikHelpers.resetForm();
        }}
        validateOnBlur={true}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          // Form Tag from Formik
          <Form>
            {/* Content of the dialog Box  */}
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* text Content inside DialogBox  */}
              <DialogContentText sx={{ width: "100%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10 ">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                          </span>
                          dd &nbsp;
                          <span className="span_first_letter">N</span>ew &nbsp;
                          <span className="span_first_letter">P</span>ermission
                        </b>
                      </h3>
                    </div>
                    <div className="col-2 closebtn">
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>
              {/* Form Filed inside Dialog Box  */}

              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} alignSelf="center">
                    Select Resource * :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="resource_key"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={resourceKey}
                      disabled={
                        resourceKeys && resourceKeys.length === 1 ? true : false
                      }
                      component={AutoCompleteMultiSelect}
                      options={resourceKeys}
                      onChange={(_, value) => {
                        setFieldValue(`resource_key`, value);
                        setFieldValue(`resource`, "");
                        setResourceKey(value);
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Select Resource",
                        autoFocus: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} alignSelf="center">
                    Resource Type * :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="resource"
                      size="small"
                      // value={values.resource ? values.resource : null}
                      //   // Calling AutoCompleteMultiSelect Component
                      component={AutoCompleteMultiSelect}
                      options={
                        ResourceList && ResourceList[0] ? ResourceList[0] : []
                      }
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.resource_id === value.resource_id
                      }
                      getOptionLabel={(option) =>
                        option && option.resource_name
                      }
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Resource Type",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} alignSelf="center">
                    Role * :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="role"
                      size="small"
                      value={values.role ? values.role : null}
                      // Calling AutoCompleteMultiSelect Component
                      component={AutoCompleteMultiSelect}
                      disabled={
                        props.roleList && props.roleList.length === 1
                          ? true
                          : false
                      }
                      options={props.roleList ? props.roleList : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.id === value.id
                      }
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.name,
                      })}
                      getOptionLabel={(option) => option.name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Role",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} alignSelf="center">
                    Permission * :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="operation"
                      size="small"
                      multiple
                      value={values.operation ? values.operation : null}
                      component={AutoCompleteMultiSelect}
                      disableCloseOnSelect={true}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.operation_name}
                        </li>
                      )}
                      options={props.operationsList ? props.operationsList : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.operation_name === value.operation_name
                      }
                      getOptionLabel={(option) => option.operation_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder:
                          !values.operation.length > 0 ? "Permissions" : null,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    name={"Add Permissions"}
                    type="submit"
                    size="large"
                    width="100%"
                    loading={loading}
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Reset"}
                    type="reset"
                    size="large"
                    width="100%"
                    onClick={() => {
                      setResourceKey(null);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapDispatchToProps)(ManageUserPermissionPost);
