import BaseReducer from "../../../../utilities/BaseReducer";
import ClientAction from "./ClientAction";

export default class SubClientFilterReducer extends BaseReducer {
  initialState = {
    subclientFilterA3: [],
  };

  [ClientAction.REQUEST_SUBCLIENT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      subclientFilterA3: action.payload,
    };
  }
}
