import BaseReducer from "../../../utilities/BaseReducer";
import ProjectAction from "./projectAction";

// reducer function
export default class ProjectByIdReducer extends BaseReducer {
  initialState = {
    projectById: [],
  };

  [ProjectAction.REQUEST_PROJECT_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      projectById: action.payload,
    };
  }
}
