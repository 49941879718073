import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import TrustpilotAccountAction from "../../stores/ticketWorkedOn/trustPilotAccount/TrustpilotAccountAction";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};
const validationSchema = yup.object({
  account_name: yup
    .string("Enter your Account name")
    .trim()
    .min(1, "Account name should be of minimum 1 characters length")
    .required("Account name is required"),

  page_url: yup
    .string("Enter Page Url")
    .trim()
    .min(1, "Page Url should be of minimum 1 characters length")
    .required("Page Url is required"),
});

const AddTrustpilotAccount = (props) => {
  let initial = {};
  initial = {
    account_name: "",
    page_url: "",
  };

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);
          let obj = {};

          Object.assign(
            obj,
            values.account_name && { account_name: values.account_name },
            values.page_url && { page_url: values.page_url }
          );

          props
            .dispatch(
              TrustpilotAccountAction.requestPostTrustpilotAccount(
                JSON.stringify(obj)
              )
            )
            .then(() => {
              setLoading(false);

              let params = {
                page_limit: "none",
              };
              let account_params = {
                page_limit: "none",
                order_by: '[["created_on", "desc"]]',
              };
              props.onClose();
              props.setAdd_account("");
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccount(account_params)
              );
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccountFilter(params)
              );
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          formik,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "5%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-11">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">A</span>
                          dd &nbsp;
                          <span className="span_first_letter">T</span>rust Pilot
                          &nbsp;
                          <span className="span_first_letter">A</span>ccount
                          &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div
                      className="col-1 closebtn"
                      onClick={() => {
                        props.onClose();
                        props.setAdd_account("");
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="account_name"
                      size="small"
                      name="account_name"
                      placeholder="Account Name"
                      error={
                        Boolean(errors.account_name) &&
                        Boolean(touched.account_name)
                      }
                      helperText={
                        Boolean(touched.account_name) && errors.account_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Page URL :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="page_url"
                      size="small"
                      name="page_url"
                      placeholder="Page URL"
                      error={
                        Boolean(errors.page_url) && Boolean(touched.page_url)
                      }
                      helperText={Boolean(touched.page_url) && errors.page_url}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Account "}
                      loading={loading}
                      type="submit"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Edit account "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton name={"Reset"} type="reset" width="100%" />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AddTrustpilotAccount);
