import ActionUtility from "../../../../utilities/ActionUtility";
import LinkStatusEffect from "./LinkStatusEffect";

export default class LinkStatusAction {
  static REQUEST_LINK_STATUS = "LinkStatusAction.REQUEST_LINK_STATUS";
  static REQUEST_LINK_STATUS_FINISHED =
    "LinkStatusAction.REQUEST_LINK_STATUS_FINISHED";

  static REQUEST_LINK_STATUS_FILTER =
    "LinkStatusAction.REQUEST_LINK_STATUS_FILTER";
  static REQUEST_LINK_STATUS_FILTER_FINISHED =
    "LinkStatusAction.REQUEST_LINK_STATUS_FILTER_FINISHED";

  static requestLinkStatus(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkStatusAction.requestLinkStatus,
        LinkStatusEffect.requestLinkStatus,
        params
      );
    };
  }

  static requestLinkStatusFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkStatusAction.REQUEST_LINK_STATUS_FILTER,
        LinkStatusEffect.requestLinkStatusFilter,
        params
      );
    };
  }
}
