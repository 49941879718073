import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import KeywordConfigGetModel from "./models/KeywordConfigGetModel";
import KeywordConfigPostModel from "./models/KeywordConfigPostModel";
import KeywordConfigDeleteModel from "./models/KeywordConfigDeleteModel";

export default class KeywordConfigEffect {
  static async requestKeywordConfiguration(params) {
    const endpoint = environment.api.keywordConfiguration;
    return EffectUtility.getToModel(KeywordConfigGetModel, endpoint, params);
  }

  static async requestKeywordConfigurationFilter(params) {
    const endpoint = environment.api.keywordConfigurationFilter;
    return EffectUtility.getToModel(KeywordConfigGetModel, endpoint, params);
  }

  static async requestPostKeywordConfig(data) {
    const endpoint = environment.api.keywordConfiguration;
    return EffectUtility.postToModel(KeywordConfigPostModel, endpoint, data);
  }

  static async requestPutKeywordConfig(data, id) {
    const endpoint = environment.api.keywordConfiguration + "/" + id;
    return EffectUtility.putToModel(
      KeywordConfigPostModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  static async requestDeleteKeywordConfig(id) {
    const endpoint = environment.api.keywordConfiguration + "/" + id;

    return EffectUtility.deleteToModel(KeywordConfigDeleteModel, endpoint);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
