import BaseReducer from "../../../../utilities/BaseReducer";
import AppStoreAction from "./AppStoreAction";

// reducer function
export default class AppStoreFilterReducer extends BaseReducer {
  initialState = {
    appstorefilter: [],
  };

  [AppStoreAction.REQUEST_APPSTORE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      appstorefilter: action.payload,
    };
  }
}
