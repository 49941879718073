import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import AmbitionBoxAccountModel from "./model/AmbitionBoxAccountModel";
import AmbitionBoxAccountPostModel from "./model/AmbitionBoxAccountPostModel";
import AmbitionBoxAccountPutModel from "./model/AmbitionBoxAccountPutModel";
import AmbitionBoxDeleteModel from "./model/AmbitionBoxDeleteModel";

// AmbitionBoxAccountEffect
export default class AmbitionBoxAccountEffect {
  // calling an API according to the AmbitionBox Account model
  // Get method for the AmbitionBox account
  static async requestAmbitionBoxAccount(params) {
    const endpoint = environment.api.ambitionBoxAccount;
    return EffectUtility.getToModel(AmbitionBoxAccountModel, endpoint, params);
  }

  // Get method for the AmbitionBox account filter
  static async requestAmbitionBoxAccountFilter(params) {
    const endpoint = environment.api.ambitionBoxACcountFilter;
    return EffectUtility.getToModel(AmbitionBoxAccountModel, endpoint, params);
  }

  // put method for the AmbitionBox account filter
  static async requestPutAmbitionBoxAccount(data, id) {
    const endpoint = environment.api.ambitionBoxAccount + "/" + id;
    return EffectUtility.putToModel(AmbitionBoxAccountPutModel, endpoint, data);
  }

  // post method for the AmbitionBox account filter
  static async requestPostAmbitionBoxAccount(data) {
    const endpoint = environment.api.ambitionBoxAccount + "/add";

    return EffectUtility.postToModel(
      AmbitionBoxAccountPostModel,
      endpoint,
      data
    );
  }

  static async requestDeleteAmbitionBoxAccount(id) {
    const endpoint = environment.api.ambitionBoxAccount + "/" + id;

    return EffectUtility.deleteToModel(AmbitionBoxDeleteModel, endpoint);
  }
}
