import ActionUtility from "../../../utilities/ActionUtility";
import UserRoleMappingEffect from "./UserRoleMappingEffect";

export default class UserRoleMappingAction {
  // UserRoleMappingAction action with an function declaration
  static REQUEST_USER_ROLE_MAPPING =
    "UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING";
  static REQUEST_USER_ROLE_MAPPING_FINISHED =
    "UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING_FINISHED";

  static REQUEST_USER_ROLE_MAPPING_FILTER =
    "UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING_FILTER";
  static REQUEST_USER_ROLE_MAPPING_FILTER_FINISHED =
    "UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING_FILTER_FINISHED";

  static REQUEST_PUT_USER_ROLE_MAPPING =
    "UserRoleMappingAction.REQUEST_PUT_USER_ROLE_MAPPING";
  static REQUEST_PUT_USER_ROLE_MAPPING_FINISHED =
    "UserRoleMappingAction.REQUEST_PUT_USER_ROLE_MAPPING_FINISHED";

  static REQUEST_POST_USER_ROLE_MAPPING =
    "UserRoleMappingAction.REQUEST_POST_USER_ROLE_MAPPING";
  static REQUEST_POST_USER_ROLE_MAPPING_FINISHED =
    "UserRoleMappingAction.REQUEST_POST_USER_ROLE_MAPPING_FINISHED";

  // METHODS
  // instagram GET function
  static requestUserRoleMapping(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING,
        UserRoleMappingEffect.requestUserRoleMapping,
        params
      );
    };
  }

  static requestUserRoleMappingFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING_FILTER,
        UserRoleMappingEffect.requestUserRoleMappingFilter,
        params
      );
    };
  }
  static requestPutUserRoleMapping(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserRoleMappingAction.REQUEST_PUT_USER_ROLE_MAPPING,
        UserRoleMappingEffect.requestPutUserRoleMapping,
        data,
        id
      );
    };
  }

  // EMAIL post function
  static requestPostUserRoleMapping(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserRoleMappingAction.REQUEST_POST_USER_ROLE_MAPPING,
        UserRoleMappingEffect.requestPostUserRoleMapping,
        data
      );
    };
  }
}
