import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import DsrActvityGroupGetModel from "./models/DsrActivityGroupGetModel";
import DsrActivityGroupPostModel from "./models/DsrActivityGroupPostModel";

export default class DsrActivityGroupEffect {
  static async requestGetDsrActivityGroup(params, callback) {
    const endpoint = environment.api.dsrActivityGroup;
    let response = EffectUtility.getToModel(
      DsrActvityGroupGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestGetDsrActivityGroupFilter(params, callback) {
    const endpoint = environment.api.dsrActivityGroup;
    let response = EffectUtility.getToModel(
      DsrActvityGroupGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostDsrActivityGroup(data, params, callback) {
    const endpoint = environment.api.dsrActivityGroup + "/add";
    let response = EffectUtility.postToModel(
      DsrActivityGroupPostModel,
      endpoint,
      data,
      params
    );
    return response;
  }

  static async requestPutDsrActivityGroup(data, id) {
    const endpoint = environment.api.dsrActivityGroup + "/" + id;
    return EffectUtility.putToModel(DsrActivityGroupPostModel, endpoint, data);
  }
}
