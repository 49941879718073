import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fieldToTextField } from "formik-material-ui";
import { Box, Paper } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MuiTextField from "../muiTextField/MuiTextField";
import { theme } from "../../views/App";

function MuiTimePicker({
  disableClearable,
  textFieldProps,
  size,
  width,
  fieldValue,
  onChange,
  format,
  views,
  value,
  ...props
}) {
  const {
    form: { setTouched, setFieldValue, touched },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="default">
      <Box sx={{ width: !width ? 200 : width }}>
        <TimePicker
          value={value ? dayjs(value) : null}
          onChange={(value) => setFieldValue(name, value)}
          slots={{
            textField: MuiTextField,
          }}
          format={format ? format : "HH:mm"}
          views={views}
          slotProps={{
            actionBar: { actions: [] },

            textField: {
              fullWidth: true,
              variant: "outlined",
              size: "small",
              label: "",
              style: {
                outline: `1px ${theme.palette.borderColor.textField}`,
                backgroundColor: theme.palette.background.paper,
                borderRadius: "8px",
              },
            },
          }}
          ampm={false} // Remove AM/PM indicators
          // adapter={AdapterDateFns}
        />
      </Box>
    </LocalizationProvider>
  );
}

export default MuiTimePicker;
