import { BaseModel } from "sjs-base-model";
import TicketSettingsHitsDataModel from "./TicketSettingsHitsDataModel";

export default class TicketSettingsHitsModel extends BaseModel {
  //ticketType hits model
  hits = [TicketSettingsHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();
    this.update(data1);
  }
}
