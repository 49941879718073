import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  createFilterOptions,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useNavigate } from "react-router";
import { Field, Form, Formik, useFormik, useFormikContext } from "formik";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import TerritoriesAction from "../../stores/territories/TerritoriesAction";
import { theme } from "../../../../views/App";
import MuiButton from "../../../../components/muiButton/MuiButton";
import MuiTextField from "../../../../components/muiTextField/MuiTextField";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import ClientAction from "../../stores/client/ClientAction";
import AutoCompleteMultiSelect from "../../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { CSVLink } from "react-csv";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [ClientAction.REQUEST_CLIENT_FILTER]),
  clientFilter: state.clientFilter.clientFilter || [],
});

const NewReleaseDialog = (props) => {
  console.log("dialogProp==>", props);
  const navigate = useNavigate();

  let initial = {
    title: "",
    client_id: "",
    is_new_release: false,
  };
  const [loading, setLoading] = useState(false);

  const csvLink = useRef();
  const [exportData, setExportData] = useState([]);

  const [releaseType, setReleaseType] = useState("audio");

  const handleClick = (event) => {
    setReleaseType(event.target.value);
  };

  const handleExport = () => {
    let Export = [];

    Export.push({
      release_title: "",
      song_title: "",
      type: "",
      primary_artist: "",
      composer: "",
      genre: "",
      sub_genre: "",
      label_name: "",
      meta_language: "",
      format: "",
      plan: "",
      original_release_date: "",
      production_year: "",
      copyright_year: "",
      phonographic_copyright_year: "",
      copyright_to: "",
      published_by: "",
      p_catalouge_number: "",
      lyric_language: "",
      track_language: "",
      preview: "",
      publisher: "",
      main_release_date: "",
      content_type: "",
      track_type: "",
      ISRC: "",
      instrumental: "",
      author: "",
      lyrics: "",
    });

    setExportData(Export);

    setTimeout(() => {
      csvLink.current.link.click();
    }, 2000);
  };

  //Formik
  //   const formik = useFormik({
  //     initialValues: initial,
  //     enableReinitialize: true,
  //     // validationSchema: validationSchema,
  //     onSubmit: (values) => {
  //       // parameter for showing list based on the pagination
  //       setLoading(true);
  //       const params = {
  //         order_by: `[["order_number","asc"]]`,
  //         page_limit: 15,
  //       };
  //       //   const param = {
  //       //     order_by: `[["ticket_type_name.keyword", "asc"]]`,
  //       //     page_limit: "none",
  //       //   };

  //       //   formik.resetForm();
  //     },
  //   });

  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["modified_on","desc"]]`,
    };

    props.open && fetchClientFilter(param);
  }, [props.open]);

  let client_name_array = [];

  if (props.clientFilter.hits) {
    props.clientFilter.hits.hits.length !== 0 &&
      props.clientFilter.hits.hits.map((item) => {
        item._source.client_name &&
          client_name_array.push({
            client_id: item._id,
            client_name: item._source.client_name,
          });
      });
  }

  const handleClose = () => {
    props.onClose();
    // Formik.resetForm();
  };
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="sm"
    >
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.title) {
            errors.title = "Title is Required";
          }

          if (!values.client_id) {
            errors.client_id = "Client name is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let callBack = (res) => {
            props
              .dispatch(ReleasesAction.requestDraftReleaseByIdClear())
              .then(() => {
                localStorage.getItem("id") && localStorage.removeItem("id");
                localStorage.setItem("draft_releaseid", res.id);
                navigate(`/A3releaseInformation(draft)`);
              });
          };

          const releasePostCallBack = (territories) => {
            let Africa = [];
            let Antartica = [];
            let Australia = [];
            let Europe = [];
            let Northamerica = [];
            let southamerica = [];
            let Asia = [];

            if (territories && territories.territory_data) {
              Africa = territories.territory_data.AF;
              Antartica = territories.territory_data.AN;
              Australia = territories.territory_data.OC;
              Europe = territories.territory_data.EU;
              Northamerica = territories.territory_data.NA;
              southamerica = territories.territory_data.SA;
              Asia = territories.territory_data.AS;
            }

            let AsiaIdArray = [];

            Asia &&
              Asia.map((item) => {
                AsiaIdArray.push(item.country_code);
              });

            let AfricaIdArray = [];

            Africa &&
              Africa.map((item) => {
                AfricaIdArray.push(item.country_code);
              });

            let AntarticaIdArray = [];

            Antartica &&
              Antartica.map((item) => {
                AntarticaIdArray.push(item.country_code);
              });

            let AustraliaIdArray = [];

            Australia &&
              Australia.map((item) => {
                AustraliaIdArray.push(item.country_code);
              });

            let EuropeIdArray = [];

            Europe &&
              Europe.map((item) => {
                EuropeIdArray.push(item.country_code);
              });

            let NorthamericaIdArray = [];

            Northamerica &&
              Northamerica.map((item) => {
                NorthamericaIdArray.push(item.country_code);
              });

            let southamericaIdArray = [];

            southamerica &&
              southamerica.map((item) => {
                southamericaIdArray.push(item.country_code);
              });

            let allCountriesArray = [
              ...AfricaIdArray,
              ...AntarticaIdArray,
              ...AustraliaIdArray,
              ...EuropeIdArray,
              ...NorthamericaIdArray,
              ...southamericaIdArray,
              ...AsiaIdArray,
            ];

            let postObj = {
              title: values.title,
              type_of_release: releaseType,
              ...(releaseType === "video" && { format: "single" }),
              territory:
                allCountriesArray && allCountriesArray.length !== 0
                  ? allCountriesArray
                  : [],
              client_id: values.client_id.client_id,

              is_new_release: values.is_new_release,
            };

            let current_page = "?current_page=releases";

            props
              .dispatch(
                ReleasesAction.requestPostRelease(
                  JSON.stringify(postObj),
                  callBack,
                  current_page
                )
              )
              .then((res) => {
                let params = {
                  page_limit: 15,
                  order_by: '[["created_on", "desc"]]',
                  current_page: "releases",
                };
                // props.dispatch(ReleasesAction.requesReleases(params));
                props.onClose();
                setLoading(false);
              });
          };

          let params = {
            page_limit: "none",
          };

          props.dispatch(
            TerritoriesAction.requestTerritories(params, releasePostCallBack)
          );
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <Box>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                //   className="bg-warning"
                px={3}
                py={1}
                sx={{
                  borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
                }}
              >
                <Grid item sx={{ color: theme.palette.text.gray }}>
                  {/* <Typography></Typography> */}
                </Grid>
                <Grid item sx={{ color: theme.palette.text.gray }}>
                  New Release
                </Grid>
              </Grid>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
              <LoadingIndicator isActive={props.isRequesting}>
                <>
                  <Box p={5}>
                    <h1
                      style={{
                        fontSize: theme.typography.h1.fontSize,
                      }}
                    >
                      {formatName("What is the Title of Your New Release ?")}
                    </h1>
                    <Grid item lg={12} md={12} sm={12} container pt={5}>
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Fill in your release title-song/album"
                        id="title"
                        size="small"
                        name="title"
                        error={Boolean(errors.title) && Boolean(touched.title)}
                        helperText={Boolean(touched.title) && errors.title}
                        // className="col-10"
                      />
                      {/* <CheckboxField
                    label="Please select only if this release is an album and Contains atleast one track already uploaded earlier"
                    name="is_new_release"
                    value={values.is_new_release}
                    onChange={handleChange}
                  /> */}
                    </Grid>

                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      // container
                      // className="bg-warning"
                    >
                      <Field
                        name="client_id"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component
                        value={values.client_id ? values.client_id : null}
                        component={AutoCompleteMultiSelect}
                        // disabled={props.edit || values.category}
                        options={client_name_array ? client_name_array : []}
                        // disabled={
                        //   props.client_name_array &&
                        //   props.client_name_array.length === 1
                        //     ? true
                        //     : false
                        // }
                        width="100%"
                        filterOptions={createFilterOptions({
                          matchFrom: "start",
                          stringify: (option) => option.client_name,
                        })}
                        isOptionEqualToValue={(option, value) =>
                          //setting value property to custom value
                          option.client_name === value.client_name
                        }
                        getOptionLabel={(option) => option.client_name}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Client",
                        }}
                        error={
                          Boolean(errors.client_id) &&
                          Boolean(touched.client_id)
                        }
                        helperText={
                          Boolean(touched.client_id) && errors.client_id
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        pt: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <h1
                        style={{
                          fontSize: `16px`,
                        }}
                      >
                        {formatName("Release Type ")}:
                      </h1>
                      <RadioGroup
                        row
                        aria-label="music-type"
                        name="music-type"
                        value={releaseType ? releaseType : "audio"}
                        onChange={handleClick}
                      >
                        <FormControlLabel
                          value="audio"
                          control={<Radio />}
                          label="Audio"
                        />
                        <FormControlLabel
                          value="video"
                          control={<Radio />}
                          label="Video"
                        />
                      </RadioGroup>
                    </Grid>
                  </Box>

                  <DialogActions>
                    <Grid
                      container
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      justifyContent="space-between"
                      alignItems={"center"}
                      // className="bg-warning"
                      mx={4}
                    >
                      <Grid item lg={2} md={2} sm={2}>
                        <Grid item xs={4}>
                          {/* <MuiButton
                            name={"Template"}
                            // loading={loading}
                            width="100%"
                            cancel
                            onClick={() => {
                              handleExport();
                            }}
                          /> */}
                          <IconButton
                            onClick={() => {
                              handleExport();
                            }}
                            title={"Download Bulkupload template file"}
                          >
                            <DownloadForOfflineIcon
                              style={{
                                fontSize: "20px",
                                color: theme.palette.primary.main,
                              }}
                            />
                          </IconButton>

                          <CSVLink
                            data={exportData}
                            filename="BulkUploadTemplate.csv"
                            className="hidden"
                            ref={csvLink}
                            target="_blank"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        container
                        alignItems={"center"}
                        justifyContent="flex-end"
                        gap={2}
                        // className="bg-info"
                      >
                        <Grid item xs={4}>
                          <MuiButton
                            loading={loading}
                            name={"Create"}
                            type="submit"
                            width="100%"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MuiButton
                            name={"Cancel"}
                            // loading={loading}
                            width="100%"
                            cancel
                            onClick={() => {
                              props.onClose();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </>
              </LoadingIndicator>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default connect(mapStateToProps)(NewReleaseDialog);

const CheckboxField = ({ label, name, value, onChange }) => {
  return (
    <Grid item lg={12} md={12} sm={12} container mt={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            style={{ marginTop: "-18px" }}
            onChange={onChange}
          />
        }
        label={label}
        name={name}
        value={value}
        // label={<Typography style={{ marginTop: "17px" }}>{label}</Typography>}
      />
    </Grid>
  );
};
