import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field, getIn } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import ReportTemplateAction from "../../../stores/reportTemplate/ReportTemplateAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  template_name: string()
    .required("Template Name is Required")
    .trim()
    .min(1, "Template name should be of minimum 1 characters length")
    .required("Template name is required"),
});

const reportTemplatePost = (props) => {
  let initial = {};

  initial = {
    template_name: "",
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          let object = {};
          let template = [];

          props.selectedReports.map((item) => {
            template.push({
              // template_name: item.report,
              // filter: item.filterValue,
              // description: item.description ? item.description : [],
              // left_top_logo: item.left_top_logo ? item.left_top_logo : "",
              // right_top_logo: item.right_top_logo ? item.right_top_logo : "",
              layout: item.page_layout,
              left_top_logo: item.left_top_logo ? item.left_top_logo : "",
              right_top_logo: item.right_top_logo ? item.right_top_logo : "",
              reports: item.reports?.map((item) => {
                return {
                  description: item.description,
                  filter: item.filter,
                  report_id: JSON.parse(item.report_name)?.report_id,
                };
              }),
            });
          });

          object = {
            report_template_name: values.template_name,
            template: template,
          };

          console.log("template", template);

          props
            .dispatch(ReportTemplateAction.requestPostReportTemplate(object))
            .then(() => {
              props.dispatch(ReportTemplateAction.requestReportTemplate());
              props.onClose();
            });
          formikHelpers.resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "90%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                          </span>
                          dd &nbsp;
                          <span className="span_first_letter">T</span>emplate
                          &nbsp;
                          <span className="span_first_letter">N</span>ame
                        </b>
                      </h3>
                    </div>
                    <div className="col-2 closebtn">
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Template Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      autoFocus
                      variant="outlined"
                      placeholder="Template Name"
                      id="template_name"
                      size="small"
                      name="template_name"
                      error={
                        Boolean(errors.template_name) &&
                        Boolean(touched.template_name)
                      }
                      helperText={
                        Boolean(touched.template_name) && errors.template_name
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
                {/* </Box> */}
                {/* <Box sx={{ width: "250px", margin: "auto", marginLeft: "140px" }}> */}
              </Grid>
              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    type="submit"
                    name="Add Template Name"
                    width="100%"
                    // loading={loading}
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Cancel"}
                    width="100%"
                    onClick={() => {
                      props.onClose();
                      //   Formik.resetForm();
                    }}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(reportTemplatePost);
