import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Checkbox,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  ListItem,
  Paper,
  Popper,
  Slide,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import TimerIcon from "@mui/icons-material/Timer";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import StyleIcon from "@mui/icons-material/Style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import EmailIcon from "@mui/icons-material/Email";
import ReplyIcon from "@mui/icons-material/Reply";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReplyComponent from "../components/ReplyComponent";
import EmailComponent from "../components/EmailComponent";
import TicketFeedRepliesContainer from "./TicketFeedRepliesContainer";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import StarIcon from "@mui/icons-material/Star";
import TicketPlatformMessagesContainer from "./TicketPlatformMessagesContainer";
import * as DOMPurify from "dompurify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectClosePopper from "../components/DirectClosePopper";
import copy from "copy-to-clipboard";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@material-ui/core";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router";
import ThreeDotsPopper from "../components/ThreeDotsPopper";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CategoryUpdateDialog from "../components/CategoryUpdateDialog";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import SentimentValueUpdatePopper from "../../allTickets/components/SentimentValueUpdatePopper";
import TranslatedContent from "../components/TranslateFunction";
import environment from "environment";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";
import RetweetAction from "../../../../stores/retweet/RetweetAction";
import UnRetweetAction from "../../../../stores/unRetweet/UnRetweetAction";
import InstagramFeedsAction from "../../../../stores/instagramFeeds/InstagramFeedsAction";
import GmbQuestionFeedsAction from "../../../../stores/gmbQuestions/GmbQuestionFeedsAction";
import LinkedInFeedAction from "../../../../stores/linkedInFeed/LinkedInFeedAction";
import YoutubeAction from "../../../../stores/youtube/YoutubeAction";
import GmbReviewAction from "../../../../stores/gmbReviews/GmbReviewAction";
import PlayStoreAction from "../../../../stores/playstore/PlayStoreAction";
import TelegramAction from "../../../../stores/telegram/TelegramAction";
import GlassdoorFeedsAction from "../../../../stores/glassdoorFeeds/GlassdoorFeedsAction";
import EmailFeedsAction from "../../../../stores/emailFeeds/EmailFeedsAction";
import AmbitionBoxFeedsAction from "../../../../../stores/ambitionBoxFeeds/AmbitionBoxFeedsAction";
import FacebookDmAction from "../../../../stores/facebook_dm/FacebookDmAction";
import InstagramDirectMessageAction from "../../../../stores/instagramDirectMessage/InstagramDirectMessageAction";
import QuoraFeedsAction from "../../../../stores/quoraFeeds/QuoraFeedsAction";
import TwitterDirectMessageAction from "../../../../stores/linkedIn_account/twitterDirectMessage/TwitterDirectMessageAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import FacebookAction from "../../../../stores/facebook/FacebookAction";
import WhatsAppFeedAction from "../../../../stores/whatsappFeed/WhatsAppFeedAction";
import AppStoreAction from "../../../../stores/linkedInFeed/appstore/AppStoreAction";
import IndeedFeedAction from "../../../../stores/indeed/IndeedFeedAction";
import RedditFeedAction from "../../../../stores/reddit/RedditFeedAction";
import ConsumerAction from "../../../../stores/consumerFeeds/ConsumerAction";
import DiscordFeedsAction from "../../../../stores/discord/DiscordFeedsAction";
import TrustpilotFeedsAction from "../../../../stores/trustpilot/TrustpilotFeedsAction";
import PinterestFeedsAction from "../../../../stores/pinterest/PinterestFeedsAction";
import {
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../../../components/ticketPartials";
import { theme } from "../../../../../views/App";
import RenderMedia from "../../../../../components/renderMedia/RenderMedia";
import LoadingIndicator from "../../../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../../../components/nodatafound/NoDataFound";
import Details from "../../../../../views/manualTicketDetails/ticketDetailTabs/Details";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import LoadingBgImage from "../../../../../components/loadingBgImage/LoadingBgImage";
import NotesUI from "./NotesUI";
import ReturnDeletedStatement from "./ReturnDeletedStatement";

TimeAgo.addDefaultLocale(en);
// mapstateto props function fetching
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TwitterAction.REQUEST_TWITTER,
    RetweetAction.REQUEST_RETWEET,
    UnRetweetAction.REQUEST_UNRETWEET,
    InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS,
    GmbQuestionFeedsAction.REQUEST_GMB_QUESTION_FEED,
    LinkedInFeedAction.REQUEST_LINKEDIN_FEED,
    YoutubeAction.REQUEST_YOUTUBE,
    GmbReviewAction.REQUEST_GMB_REVIEW_FEED,
    PlayStoreAction.REQUEST_PLAYSTORE,
    TelegramAction.REQUEST_TELEGRAM_FEEDS,
    GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED,
    EmailFeedsAction.REQUEST_EMAIL_FEEDS,
    AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED,
    FacebookDmAction.REQUEST_FACEBOOK_DM,
    InstagramDirectMessageAction.REQUEST_INSTAGRAM_DIRECT_MESSAGE,
    QuoraFeedsAction.REQUEST_QUORA_FEED,
    TwitterDirectMessageAction.REQUEST_TWITTERDIRECTMESSAGE,
    TicketsAction.REQUEST_TICKETS_BY_ID,
    FacebookAction.REQUEST_FACEBOOK,
    WhatsAppFeedAction.REQUEST_WHATSAPP_FEED,
    AppStoreAction.REQUEST_APPSTORE,
    IndeedFeedAction.REQUEST_INDEED_FEEDS,
    RedditFeedAction.REQUEST_REDDIT_COMMENTS,
    RedditFeedAction.REQUEST_REDDIT_POSTS,
    ConsumerAction.REQUEST_CONSUMER,
    DiscordFeedsAction.REQUEST_DISCORD_FEEDS,
    TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS,
    PinterestFeedsAction.REQUEST_PINTEREST_FEEDS,
    // TicketsAction.REQUEST_PUT_TICKETS,
  ]),
  twitter: state.twitter.twitter.hits || {},
  threadFeeds: state.threadFeeds.threadFeeds.hits || {},
  retweet: state.retweet.retweet || [],
  unretweet: state.unretweet.unretweet || [],
  instagramFeeds: state.instagramFeeds.instagramFeeds.hits || {},
  facebook: state.facebook.facebook.hits || {},
  GMBQuestionFeeds: state.GMBQuestionFeeds.GMBQuestionFeeds.hits || [],
  linkedInFeeds: state.linkedInFeeds.linkedInFeeds.hits || [],
  youtubeFeed: state.youtubeFeed.youtubeFeed.hits || [],
  GMBReviewFeeds: state.GMBReviewFeeds.GMBReviewFeeds.hits || [],
  playstore: state.playstore.playstore.hits || [],
  telegramFeeds: state.telegramFeeds.telegramFeeds.hits || [],
  glassdoorFeeds: state.glassdoorFeeds.glassdoorFeeds.hits || {},
  emailFeeds: state.emailFeeds.emailFeeds.hits || {},
  ambitionBoxFeeds: state.ambitionBoxFeeds.ambitionBoxFeeds.hits || {},
  facebook_dm: state.facebook_dm.facebook_dm.hits || [],
  instagramDirectMessage:
    state.instagramDirectMessage.instagramDirectMessage.hits || [],
  twitterDirectMeassage:
    state.twitterDirectMeassage.twitterDirectMeassage.hits || [],
  quoraFeed: state.quoraFeed.quoraFeed.hits || {},
  whatsAppFeed: state.whatsAppFeed.whatsAppFeed.hits || [],
  appStore: state.appstore.appstore.hits || {},
  indeedFeeds: state.indeedFeeds.indeedFeeds.hits || {},
  trustPilotFeeds: state.trustPilotFeeds.trustPilotFeeds.hits || {},
  pinterestFeeds: state.pinterestFeeds.pinterestFeeds.hits || {},
  redditFeeds: state.redditFeeds.redditFeeds.hits || {},
  redditPosts: state.redditPosts.redditPosts.hits || {},
  redditComments: state.redditComments.redditComments.hits || {},
  consumer_feed: state.consumer_feed.consumer_feed.hits || {},
  discordFeeds: state.discordFeeds.discordFeeds.hits || {},
});
const TicketFeedComponent = (props) => {
  let initial_val_for_TicketPlatformMessagesContainer = {};

  const {
    openAIsuggestionTab,
    setOpenAIsuggestionTab,
    aiSuggestionTab,
    setAiSuggestionTab,
  } = props;

  let ActivityName = "";
  ActivityName =
    props &&
    props.ticketData._source &&
    props.ticketData._source.activities &&
    props.ticketData._source.activities.activities_name;

  !props.isRequesting &&
    Object.assign(
      initial_val_for_TicketPlatformMessagesContainer,

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Instagram" &&
        props.instagramDirectMessage.hits &&
        props.instagramDirectMessage.hits.length && {
          feed: props.instagramDirectMessage.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen:
                props.replyClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              emailOpen:
                props.emailClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Twitter" &&
        props.twitterDirectMeassage.hits &&
        props.twitterDirectMeassage.hits.length && {
          feed: props.twitterDirectMeassage.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen:
                props.replyClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              emailOpen:
                props.emailClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Facebook" &&
        props.facebook_dm.hits &&
        props.facebook_dm.hits.length && {
          feed: props.facebook_dm.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen:
                props.replyClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              emailOpen:
                props.emailClicked &&
                index === lastReceivedIndex &&
                ActivityName === "Direct Message",
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Telegram" &&
        props.telegramFeeds.hits &&
        props.telegramFeeds.hits.length && {
          feed: props.telegramFeeds.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen: props.replyClicked && index === lastReceivedIndex,
              emailOpen: props.emailClicked && index === lastReceivedIndex,
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Whatsapp" &&
        props.whatsAppFeed.hits &&
        props.whatsAppFeed.hits.length && {
          feed: props.whatsAppFeed.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "received") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen: props.replyClicked && index === lastReceivedIndex,
              emailOpen: props.emailClicked && index === lastReceivedIndex,
              translateClicked: false,
            };
          }),
        },

      props &&
        props.ticketData._source &&
        props.ticketData._source.platform &&
        props.ticketData._source.platform.platform_name === "Discord" &&
        props.discordFeeds.hits &&
        props.discordFeeds.hits.length && {
          feed: props.discordFeeds.hits.map((item, index, array) => {
            let lastReceivedIndex = -1;

            array.forEach((item, i) => {
              if (item._source.flag === "recieved") {
                lastReceivedIndex = i;
              }
            });

            return {
              replyOpen: props.replyClicked && index === lastReceivedIndex,
              emailOpen: props.emailClicked && index === lastReceivedIndex,
              translateClicked: false,
            };
          }),
        }
    );

  const [showBoxReplyBox, setShowReplyBox] = useState(
    props.replyClicked ? true : false
  );

  const [showEmailBox, setShowEmailBox] = useState(
    props.emailClicked ? true : false
  );

  const timeAgo = new TimeAgo("en-US");

  const handleReplyButtonClick = (index, setFieldValue, values) => {
    // setShowReplyBox((prev) => !prev);
    // props.setReplyClicked((prev) => !prev);

    // showEmailBox === true && setShowEmailBox(false);
    values.feed[index].emailOpen === true &&
      setFieldValue(`feed[${index}].emailOpen`, !values.feed[index].emailOpen);
  };

  const handleEmailButtonClick = (index, setFieldValue, values) => {
    // setShowEmailBox((prev) => !prev);
    // props.setEmailClicked((prev) => !prev);
    // showBoxReplyBox === true && setShowReplyBox(false);

    values.feed[index].replyOpen === true &&
      setFieldValue(`feed[${index}].replyOpen`, !values.feed[index].replyOpen);
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (copyText) => {
    copy(copyText);
    setCopied(true);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const icon = (
    <CheckBoxOutlineBlankIcon
      className="p-0 m-0"
      fontSize="small"
      style={{
        color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );
  const checkedIcon = (
    <CheckBoxIcon
      fontSize="small"
      className="p-0 m-0"
      style={{
        // color: `${theme.palette.ticketListView.iconColor}`,
        width: "13px",
      }}
    />
  );

  const { isRequesting } = props;

  // fetch function for Instagram Platform
  function fetchInstaFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(InstagramFeedsAction.requestInstagramFeeds(params));
  }

  // fetch fn for instagram dm
  function fetchClosedTicketInstaDm() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}],["is_deleted","must_not","","match","true"]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(
      InstagramDirectMessageAction.requestInstagramDirectMessage(params)
    );
  }

  function fetchOpenTicketInstaDm() {
    let filter = [];

    filter.push(
      `["customer_username.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0] &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"],["account_id.keyword","must","","match","${
        props.ticketData._source &&
        props.ticketData._source.account_name &&
        props.ticketData._source.account_name.account_id
      }"],["project.project_id.keyword","must","","match","${
        props.ticketData._source.project.project_id
      }"],["is_deleted","must_not","","match","true"]`
    );

    const params = {
      filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };

    props.dispatch(
      InstagramDirectMessageAction.requestInstagramDirectMessage(params)
    );
  }

  // gmd feeds fetch function
  function fetchQuestionsFeed() {
    let filter = [];
    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(GmbQuestionFeedsAction.requestGmbQuestionFeed(params));
  }

  // fetch function for playstore platform
  function fetchAppStore() {
    let filter = [];

    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(AppStoreAction.requestAppStore(param));
  }

  // fetch function for linkedIn Platform

  function fetchOpenLinkedInFeed() {
    let filter = [];

    filter.push(
      `[["customer_id.keyword","must","","term",${JSON.stringify(
        props.ticketData._source.customer_id
          ? props.ticketData._source.customer_id?.platform[0]?.account_id
          : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(LinkedInFeedAction.requestLinkedInFeed(params));
  }

  function fetchClosedLinkedInFeed() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(LinkedInFeedAction.requestLinkedInFeed(params));
  }

  //Youtube feed fetch Function
  function fetchYoutubeFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(YoutubeAction.requestYoutube(params));
  }

  // fetch fn for gmb reviews
  function fetchReviewsFeed() {
    let filter = [];
    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(GmbReviewAction.requestGmbReviewFeed(params));
  }
  // fetch function for playstore platform
  function fetchPlayStore() {
    let filter = [];
    filter.push(
      `[["reviewId.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["review_created_at", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(PlayStoreAction.requestPlayStore(param));
  }

  // telegram feed fetch function
  function fetchTelegramMessage() {
    let filter = [];

    filter.push(
      `[["feed_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };

    props.dispatch(TelegramAction.requestTelegramFeeds(params));
  }
  //fetch function for Glassdoor platform
  function fetchGlassDoorReview() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(GlassdoorFeedsAction.requestGlassdoorFeed(param));
  }

  // fetch fn of ambition  box
  function fetchAmbitionBoxReview() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
    };
    props.dispatch(AmbitionBoxFeedsAction.requestAmbitionBoxFeed(param));
  }

  function fetchClosedEmailFeeds() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };
    props.dispatch(EmailFeedsAction.requestEmailFeeds(param));
  }

  function fetchOpenEmailFeeds() {
    let filter = [];

    filter.push(
      `[["customer_name.keyword","must","","match",${JSON.stringify(
        props.ticketData._source.customer_id &&
          props.ticketData._source.customer_id.customer_name
          ? props.ticketData._source.customer_id.customer_name
          : null
      )}]]`
    );

    let param = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };
    props.dispatch(EmailFeedsAction.requestEmailFeeds(param));
  }

  //fetch function for Email platform
  function fetchEmailFeeds() {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedEmailFeeds()
      : fetchOpenEmailFeeds();
  }

  function returnStars(rating) {
    let i;
    switch (rating) {
      case "ONE":
        i = 1;
        break;
      case "TWO":
        i = 2;
        break;
      case "THREE":
        i = 3;
        break;
      case "FOUR":
        i = 4;
        break;
      case "FIVE":
        i = 5;
        break;
      case "1.0":
        i = 1;
        break;
      case "2.0":
        i = 2;
        break;
      case "3.0":
        i = 3;
        break;
      case "4.0":
        i = 4;
        break;
      case "5.0":
        i = 5;
        break;
    }

    let displayStars = [];

    for (let k = 1; k <= i; k++) {
      displayStars.push(<StarIcon sx={{ color: "gold" }} />);
    }

    return displayStars;
  }

  const returnStarsIcon = (stars) => {
    let i = stars;
    let displayStars = [];
    if (stars === i) {
      for (let k = 1; k <= i; k++) {
        displayStars.push(<StarIcon sx={{ color: "gold" }} />);
      }
    }
    return displayStars;
  };

  //fetch fn for Quora Platform
  function fetchQuorafeed() {
    let filter = [];
    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      page_limit: "none",
    };
    props.dispatch(QuoraFeedsAction.requestQuoraFeed(param));
  }

  // fetch function for Twitter Platform
  function fetchTwitter() {
    let filter = [];

    filter.push(
      `[["tweet_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(TwitterAction.requestTwitter(param));
  }

  // fetch fn for twitter direct mesage
  function fetchClosedTicketTwitterDm() {
    let filter = [];
    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      order_by: `[["created_on","asc"]]`,
      page_limit: "none",
    };
    props.dispatch(
      TwitterDirectMessageAction.requestTwitterDirectMessage(params)
    );
  }

  const fetchTwitterPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedTicketTwitterDm()
      : fetchOpenTicketTwitterDm();
    fetchTwitter();
  };

  const fetchThreads = () => {
    let filter = [];

    filter.push(
      `[["thread_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );
    let param = {
      filters: `${filter}`,
      order_by: '[["created_on", "asc"]]',
      page_limit: "none",
    };
    props.dispatch(InstagramFeedsAction.requestThreads(param));
  };

  function fetchOpenTicketTwitterDm() {
    let filter = [];

    filter.push(
      `["feed_id.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0] &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"],["account_name.keyword","must","","match","${
        props.ticketData._source.account_name &&
        props.ticketData._source.account_name.account_name
      }"],["project.project_id.keyword","must","","match","${
        props.ticketData._source.project.project_id
      }"]`
    );

    const params = {
      filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","asc"]]`,
    };

    props.dispatch(
      TwitterDirectMessageAction.requestTwitterDirectMessage(params)
    );
  }

  function Callbackfunc(resp) {
    let filter = [];

    filter.push(
      `[["conversation_id.keyword","must","","match",${JSON.stringify(
        resp &&
          resp.hits &&
          resp.hits.hits &&
          resp.hits.hits[0] &&
          resp.hits.hits[0]._source.conversation_id
          ? resp.hits.hits[0]._source.conversation_id
          : ""
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(FacebookDmAction.requestFacebookMessage(params));
  }

  function fetchFbDm_message() {
    // let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    // fetch(
    //   `${
    //     environment.api.facebook_dm
    //   }?filters=[["message_id.keyword","must","","terms",${JSON.stringify(
    //     props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
    //   )}]]&page_limit=none`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     Callbackfunc(response);
    //   });

    let filter = [];

    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(
      FacebookDmAction.requestFacebookMessage(params, Callbackfunc)
    );
  }

  // fetch function for Facebook Platform
  function fetchFacebook() {
    let filter = [];
    filter.push(
      `[["comment_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    let param = {
      // comments: props.ticketData._source && props.ticketData._source.feed_id,
      filters: `${filter}`,
      page_limit: "none",
    };
    props.dispatch(FacebookAction.requestFacebook(param));
  }

  const fetchClosedTicketDm = () => {
    let filter = [];

    filter.push(
      `[["message_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(FacebookDmAction.requestFacebookMessage(params));
  };

  const fetchFacebookPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedTicketDm()
      : fetchFbDm_message();
    // fetchFbDm_message();
    fetchFacebook();
  };

  const fetchInstagramPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedTicketInstaDm()
      : fetchOpenTicketInstaDm();

    fetchInstaFeeds();
  };

  const fetchLinkedINPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedLinkedInFeed()
      : fetchOpenLinkedInFeed();
  };

  // fetch fn for whatsapp
  function fetchOpenWhatsAppFeed() {
    let filter = [];

    filter.push(
      `[["customer_number.keyword","must","","match","${
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.platform &&
        props.ticketData._source.customer_id.platform[0].account_id
      }"]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(WhatsAppFeedAction.requestWhatsAppFeed(params));
  }

  function fetchClosedWhatsAppFeed() {
    let filter = [];

    filter.push(
      `[["feed_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
      order_by: '[["created_on", "asc"]]',
    };
    props.dispatch(WhatsAppFeedAction.requestWhatsAppFeed(params));
  }

  const fetchWhatsAppPlatform = () => {
    props.finalStatuses &&
    props.finalStatuses.length > 0 &&
    props.finalStatuses.includes(
      props.ticketData &&
        props.ticketData._source &&
        props.ticketData._source.status &&
        props.ticketData._source.status.status_id
    )
      ? fetchClosedWhatsAppFeed()
      : fetchOpenWhatsAppFeed();
  };

  function GMBplatformFeed() {
    fetchQuestionsFeed();
    fetchReviewsFeed();
  }

  function fetchIndeed() {
    let filter = [];

    filter.push(
      `[["review_id.keyword","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(IndeedFeedAction.requestIndeedFeed(params));
  }

  function fetchTrustpilotPlatform() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(TrustpilotFeedsAction.requestTrustpilotFeeds(params));
  }

  function fetchPinterestPlatform() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(PinterestFeedsAction.requestPinterestFeeds(params));
  }

  function fetchRedditFeed() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };
    if (props.ticketData._source.activities.activities_name === "Comments") {
      props.dispatch(RedditFeedAction.requestRedditComments(params));
    } else if (
      props.ticketData._source.activities.activities_name === "Posts"
    ) {
      props.dispatch(RedditFeedAction.requestRedditPosts(params));
    }
  }

  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  function fetchConsumerFeeds() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(ConsumerAction.requestConsumer(params));
  }

  function fetchDiscordFeeds() {
    let filter = [];

    filter.push(
      `[["_id","must","","terms",${JSON.stringify(
        props.ticketData._source.feed_id ? props.ticketData._source.feed_id : []
      )}]]`
    );

    const params = {
      filters: `${filter}`,
      page_limit: "none",
    };

    props.dispatch(DiscordFeedsAction.requestDiscordFeeds(params));
  }

  let allowed_platforms = ["LinkedIn", "Playstore", "GMB"];

  useEffect(() => {
    if (
      props.ticketData &&
      props.ticketData._source &&
      props.ticketData._source.platform
    ) {
      props.ticketData._source.platform.platform_name === "Twitter" &&
        fetchTwitterPlatform();

      props.ticketData._source.platform.platform_name === "Threads" &&
        fetchThreads();

      props.ticketData._source.platform.platform_name === "Facebook" &&
        fetchFacebookPlatform();

      props.ticketData._source.platform.platform_name === "Instagram" &&
        fetchInstagramPlatform();

      props.ticketData._source.platform.platform_name === "Whatsapp" &&
        fetchWhatsAppPlatform();

      props.ticketData._source.platform.platform_name === "GMB" &&
        GMBplatformFeed();

      props.ticketData._source.platform.platform_name === "LinkedIn" &&
        fetchLinkedINPlatform();
      props.ticketData._source.platform.platform_name === "Youtube" &&
        fetchYoutubeFeeds();
      props.ticketData._source.platform.platform_name === "Ambition Box" &&
        fetchAmbitionBoxReview();

      props.ticketData._source.platform.platform_name === "Glassdoor" &&
        fetchGlassDoorReview();

      props.ticketData._source.platform.platform_name === "Email" &&
        fetchEmailFeeds();

      props.ticketData._source.platform.platform_name === "Telegram" &&
        fetchTelegramMessage();

      props.ticketData._source.platform.platform_name === "Playstore" &&
        fetchPlayStore();

      props.ticketData._source.platform.platform_name === "Quora" &&
        fetchQuorafeed();

      props.ticketData._source.platform.platform_name === "Appstore" &&
        fetchAppStore();

      props.ticketData._source.platform.platform_name === "Indeed" &&
        fetchIndeed();

      props.ticketData._source.platform.platform_name === "Trust Pilot" &&
        fetchTrustpilotPlatform();

      props.ticketData._source.platform.platform_name === "Pinterest" &&
        fetchPinterestPlatform();

      props.ticketData._source.platform.platform_name === "Reddit" &&
        fetchRedditFeed();

      props.ticketData._source.platform.platform_name ===
        "Consumer Complaints" && fetchConsumerFeeds();

      props.ticketData._source.platform.platform_name === "Discord" &&
        fetchDiscordFeeds();
    }
  }, [props.ticketData]);

  const returntags = (tag_array) => {
    let tag_string = "";
    tag_array.length !== 0 &&
      tag_array.map((item) => {
        tag_string =
          tag_string !== ""
            ? tag_string + "/" + item.tag_name
            : tag_string + item.tag_name;
      });

    return tag_string.length > 10
      ? tag_string.substring(0, 10 - 2) + ".."
      : tag_string;
  };

  // TWITTER RELATED ACTIVITIES
  function fetchRetweet(id, params) {
    props.dispatch(RetweetAction.requestRetweet(id, params)).then(() => {
      fetchTwitter();
    });
  }

  function fetchUnRetweet(id, params) {
    props.dispatch(UnRetweetAction.requestUnRetweet(id, params)).then(() => {
      fetchTwitter();
    });
  }

  const renderingHtml = (rawHtml) => {
    const clean = DOMPurify.sanitize(rawHtml);
    return <div dangerouslySetInnerHTML={{ __html: clean }} />;
  };

  function fullName_function(id) {
    let user_name = props.userlist.filter((val) => {
      return val.user_id === id ? id : "";
    });
    return user_name[0] ? user_name[0].fullname : "";
  }

  const returnCustomValues = (custom_fields) => {
    let category_values = props.category_custom_value;

    let filtered_category_vals = [];
    if (custom_fields && custom_fields.length !== 0) {
      filtered_category_vals = custom_fields.filter((item) => {
        return item.field_type === "category";
      });
    }

    let sub_value_id = [];

    if (filtered_category_vals && filtered_category_vals.length !== 0) {
      filtered_category_vals.map((item) => {
        sub_value_id.push(item.field_values[0]?.id);
      });
    }
    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals = ReturnCategoryName(
      category_values && category_values.length !== 0 && category_values,
      sub_value_id && sub_value_id[0] && sub_value_id[0]
    );

    return custom_field_vals;
  };

  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  const returntrueCategoryValues = (ticket) => {
    let bool_val = false;

    if (
      ticket &&
      ticket._source &&
      ticket._source.custom_value &&
      ticket._source.custom_value.length !== 0
    ) {
      ticket._source.custom_value
        .filter((val) => {
          return val.field_type === "category";
        })
        .map((item) => {
          if (item && item.field_values) {
            item?.field_values?.length && item?.field_values[0]?.id !== ""
              ? (bool_val = true)
              : (bool_val = false);
          }
        });
    }

    return bool_val;
  };

  const returnDirectMessageDate = () => {
    let directMessageData;
    if (
      props.ticketData &&
      props.ticketData._source &&
      props.ticketData._source.platform.platform_name === "Twitter"
    ) {
      if (
        props.twitterDirectMeassage &&
        props.twitterDirectMeassage.hits &&
        props.twitterDirectMeassage.hits.length
      ) {
        directMessageData = props.twitterDirectMeassage.hits;
      }
    } else {
      directMessageData = null;
    }
    return directMessageData;
  };

  const returnVisibleReplyBtn = (item, index) => {
    let boolean = true;

    if (
      props.ticketData._source &&
      props.ticketData._source.platform.platform_name == "Instagram"
    ) {
      if (
        item._source.activities === "Tagged Posts" ||
        item._source.activities === "Captions Mention" ||
        item._source.activities === "Comment Mentions"
      ) {
        boolean = false;
      }
    }

    if (
      props.ticketData._source &&
      props.ticketData._source.platform.platform_name == "GMB"
    ) {
      if (item._index === "gmb_questions_answers") {
        // boolean = false;
      }
    }

    if (
      props?.ticketData?._source?.platform?.platform_name == "Appstore" ||
      props?.ticketData?._source?.platform?.platform_name ==
        "Consumer Complaints" ||
      props?.ticketData?._source?.platform?.platform_name == "Indeed" ||
      props?.ticketData?._source?.platform?.platform_name == "Ambition Box" ||
      props?.ticketData?._source?.platform?.platform_name == "Quora"
    ) {
      boolean = false;
    }

    if (
      props?.ticketData?._source?.platform?.platform_name == "Instagram" &&
      item._source.is_deleted
    ) {
      boolean = false;
    }

    if (props?.ticketData?._source?.platform?.platform_name == "Reddit") {
      boolean = false;
    }

    if (
      props?.ticketData?._source?.platform?.platform_name == "Playstore" &&
      props?.playstore?.hits?.length > 1
    ) {
      if (index !== undefined && index !== props.playstore.hits.length - 1) {
        boolean = false;
      }
    }

    if (
      props?.ticketData?._source?.platform?.platform_name == "Facebook" &&
      props?.facebook?.hits?.length > 1
    ) {
      if (index !== undefined && item?._source?.is_deleted) {
        boolean = false;
      }
    }

    if (
      props?.ticketData?._source?.platform?.platform_name == "GMB" &&
      props?.GMBReviewFeeds?.hits?.length > 1
    ) {
      if (
        index !== undefined &&
        index !== props.GMBReviewFeeds.hits.length - 1
      ) {
        boolean = false;
      }
    }

    if (item?._source?.feed_deleted === true) {
      boolean = false;
    }

    return boolean;
  };

  const replyTimeString = (props, index, item) => {
    const getFormattedDate = (dateString) => {
      const date = new Date(dateString);
      return (
        date.toDateString() +
        " " +
        date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      );
    };

    const platformName = props.ticketData._source.platform.platform_name;
    let timeString = "";

    switch (platformName) {
      case "Facebook":
        timeString = getFormattedDate(
          props.facebook.hits[index]._source.comment_created_at
        );
        break;
      case "Twitter":
        timeString = getFormattedDate(
          props.twitter.hits[index]._source.created
        );
        break;
      case "Threads":
        timeString = getFormattedDate(
          props.threadFeeds.hits[index]._source.thread_created_datetime
        );
        break;
      case "Instagram":
        if (props.instagramFeeds.hits) {
          const commentCreatedTime =
            props.instagramFeeds.hits[index]._source.comment_created_time;
          const parentPostCreatedTime =
            props.instagramFeeds.hits[index]._source.parent_post_created_time;
          timeString = getFormattedDate(
            commentCreatedTime
              ? commentCreatedTime
              : parentPostCreatedTime
              ? parentPostCreatedTime
              : ""
          );
        }
        break;
      case "Youtube":
        const youtubeSource = props.youtubeFeed.hits[index]._source;
        timeString = getFormattedDate(
          youtubeSource.activities === "Comments"
            ? youtubeSource.comment_created_time
            : youtubeSource.activities === "Videos"
            ? youtubeSource.video_published_date
            : null
        );
        break;
      case "GMB":
        if (item._index === "gmb_questions_answers") {
          timeString = getFormattedDate(
            props.GMBQuestionFeeds.hits[index]._source.question_created_at
          );
        } else if (item._index === "gmb_review") {
          timeString = getFormattedDate(
            props.GMBReviewFeeds.hits[index]._source.created_on
          );
        }
        break;
      case "LinkedIn":
        timeString = getFormattedDate(
          props.linkedInFeeds.hits[index]._source.created_at
        );
        break;
      case "Ambition Box":
        timeString = getFormattedDate(
          props.ambitionBoxFeeds.hits[index]._source.posted_on
        );
        break;
      case "Playstore":
        timeString = getFormattedDate(
          props.playstore.hits[index]._source.review_created_at
        );
        break;
      case "Glassdoor":
        timeString = getFormattedDate(
          props.glassdoorFeeds.hits[index]._source.date
        );
        break;
      case "Email":
        timeString = getFormattedDate(
          props.emailFeeds.hits[index]._source.date
        );
        break;
      case "Quora":
        timeString = getFormattedDate(
          props.quoraFeed.hits[index]._source.created_on
        );
        break;
      case "Appstore":
        timeString = getFormattedDate(
          props.appStore.hits[index]._source.created_on
        );
        break;
      case "Indeed":
        timeString = getFormattedDate(
          props.indeedFeeds.hits[index]._source.created_on
        );
        break;
      case "Trust Pilot":
        timeString = getFormattedDate(
          props.trustPilotFeeds.hits[index]._source.created_at
        );
        break;
      case "Pinterest":
        timeString = getFormattedDate(
          props.pinterestFeeds.hits[index]._source.commented_on
        );
        break;
      case "Reddit":
        if (props.ticketData._source.activities.activities_name === "Posts") {
          timeString = getFormattedDate(
            props.redditPosts.hits[index]._source.posted_on
          );
        } else if (
          props.ticketData._source.activities.activities_name === "Comments"
        ) {
          timeString = getFormattedDate(
            props.redditComments.hits[index]._source.commented_on
          );
        }
        break;
      case "Consumer Complaints":
        timeString = getFormattedDate(
          props.consumer_feed.hits[index]._source.created_on
        );
        break;
      default:
        break;
    }

    return timeString;
  };

  const returnTrueNewTicket = (
    platform,
    twitter,
    instagramFeeds,
    facebook,
    GMB,
    youtubeFeed,
    playstore,
    appStore,
    glassdoorFeeds,
    emailFeeds,
    consumer_feed,
    linkedInFeeds,
    indeedFeeds,
    redditFeeds,
    threadFeeds,
    ambtionBoxFeeds
  ) => {
    let bool_val = false;

    if (platform === "Twitter" && props.ticketData._id === twitter.ticket_id) {
      bool_val = true;
    } else if (
      platform === "Instagram" &&
      props.ticketData._id === instagramFeeds.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Facebook" &&
      props.ticketData._id === facebook.ticket_id
    ) {
      bool_val = true;
    } else if (platform === "GMB" && props.ticketData._id === GMB.ticket_id) {
      bool_val = true;
    } else if (
      platform === "Youtube" &&
      props.ticketData._id === youtubeFeed.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Playstore" &&
      props.ticketData._id === playstore.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Appstore" &&
      props.ticketData._id === appStore.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Glassdoor" &&
      props.ticketData._id === glassdoorFeeds.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Email" &&
      props.ticketData._id === emailFeeds.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Consumer Complaints" &&
      props.ticketData._id === consumer_feed.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "LinkedIn" &&
      props.ticketData._id === linkedInFeeds.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Indeed" &&
      props.ticketData._id === indeedFeeds.ticket_id
    ) {
      bool_val = true;
    }
    // else if (
    //   platform === "Reddit" &&
    //   props.ticketData._id === redditFeeds.ticket_id
    // ) {
    //   bool_val = true;
    // }
    else if (
      platform === "Threads" &&
      props.ticketData._id === threadFeeds.ticket_id
    ) {
      bool_val = true;
    } else if (
      platform === "Ambition Box" &&
      props.ticketData._id === ambtionBoxFeeds.ticket_id
    ) {
      bool_val = true;
    }

    return bool_val;
  };

  const returnLogStatement = (ticket_id) => {
    let filtered_tickets =
      props.mass_ticket_history &&
      props.mass_ticket_history.filter((item) => {
        return item._source.ticket_id === ticket_id;
      });

    filtered_tickets =
      filtered_tickets &&
      filtered_tickets.filter((val) => {
        return val._source.final_status === true;
      });

    let closed_user =
      filtered_tickets[0] &&
      filtered_tickets[0]._source.description.split("By")[1];

    // return `ticket closed by ${closed_user ? closed_user : null}`;

    return (
      <>
        {
          <Grid item container xl={12} m={0.5}>
            <Box
              width={"100%"}
              sx={{
                // paddingX: 2,
                paddingY: 0,
              }}
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                alignItems="center"
                justifyContent={"flex-end"}
                // className="bg-success"
              >
                <Grid
                  item
                  textAlign={"right"}
                  // xl={10}
                  // lg={10}
                  // md={10}
                  // sm={10}
                  // className="bg-info"
                  sx={{
                    backgroundColor: "white",
                    border: "solid 0.5px #92b9c4",
                    fontFamily: "BookAntiqua-Italic",
                    borderRadius: 1,
                  }}
                  px={2}
                >
                  <span
                    style={{
                      color: `${theme.palette.primary.main}`,
                    }}
                  >
                    <i
                      style={{
                        opacity: 1,
                      }}
                    >
                      {`${closed_user ? closed_user : null}`}
                    </i>
                    &nbsp;
                    <i
                      style={{
                        opacity: 0.8,
                      }}
                    >{`has closed the ticket ID :`}</i>
                    &nbsp;
                    <i>
                      {filtered_tickets[0] &&
                        filtered_tickets[0]._source.ticket_id}
                    </i>
                    &nbsp;
                    <i
                      style={{
                        opacity: 0.8,
                      }}
                    >
                      at
                    </i>
                    &nbsp;
                    <i
                      style={{
                        opacity: 1,
                        textDecoration: "underline",
                      }}
                    >
                      {(function createdDate() {
                        let created =
                          filtered_tickets[0] &&
                          filtered_tickets[0]._source.created_on;
                        let dateConversion = new Date(created);
                        dateConversion =
                          dateConversion.toDateString() +
                          ", " +
                          dateConversion.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          });

                        return dateConversion;
                      })()}
                    </i>
                  </span>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        }
      </>
    );
  };

  let [DividerId, setDividerId] = useState(null);

  function ReturnFeeds(index, setFieldValue, values, item, setScrollToIndex) {
    if (props.replyClicked && values.feed[index].replyOpen) {
      setScrollToIndex(index);
    }
    setDividerId(null);

    return (
      <Box width={"100%"}>
        <Box>
          <Box
            sx={{
              paddingX: 2,
              paddingTop: 2,
              paddingBottom: 3,
            }}
            // className="bg-warning"
          >
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
              justifyContent={"flex-start"}
              // className="bg-success"
            >
              <Grid
                item
                xl={props.showFeedDetails ? 10 : 8}
                lg={10}
                md={9}
                sm={9}
                xs={9}
                alignItems="flex-start"
                justifyContent={"center"}
                textAlign="left"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  border: "solid 0.5px #92b9c4",
                }}
                // className="bg-warning"
              >
                <Box width={"100%"}>
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="space-between"
                    alignItems={"center"}
                    // className="bg-warning"
                    py={1}
                    px={2}
                    sx={
                      {
                        // borderTopLeftRadius: "10px",
                      }
                    }
                  >
                    <Grid
                      item
                      container
                      xl={7.5}
                      lg={8}
                      md={8}
                      sm={8}
                      xs={8}
                      justifyContent="flex-start"
                      alignItems={"center"}
                      // className="bg-success"
                      gap={2}
                    >
                      <Grid
                        item
                        // xl={3} lg={3} md={3} sm={3} xs={3}
                      >
                        <ListItem className="p-0 m-0">
                          <Avatar
                            style={{
                              width:
                                props.ticketData._source.platform
                                  .platform_name === "Youtube"
                                  ? null
                                  : "20px",
                              height:
                                props.ticketData._source.platform
                                  .platform_name === "Youtube"
                                  ? null
                                  : "20px",
                              // backgroundColor:
                              //   props.ticketData._source.platform
                              //     .platform_name === "Youtube" ||
                              //   props.ticketData._source.platform
                              //     .platform_name === "Indeed" ||
                              //   props.ticketData._source.platform
                              //     .platform_name === "Trust Pilot"
                              //     ? "white"
                              //     : `${theme.palette.primary.main}`,
                            }}
                          >
                            {returnPlatformDropdownLogos(
                              props.ticketData._source.platform.platform_name
                            )}
                          </Avatar>

                          <p
                            className="m-0 p-0 ml-1"
                            style={{
                              color: `${theme.palette.text.primary}`,
                              fontSize: "13px",
                            }}
                          >
                            {props.ticketData._source.platform &&
                            props.ticketData._source.platform.platform_name ===
                              "Twitter"
                              ? props.twitter.hits[index]._source.tweet_type
                              : props.ticketData._source.platform
                                  .platform_name === "Instagram"
                              ? props.instagramFeeds.hits[index]._source
                                  .activities
                              : props.ticketData._source.platform
                                  .platform_name === "Facebook"
                              ? props.facebook.hits[index]._source &&
                                props.facebook.hits[index]._source.activities &&
                                props.facebook.hits[index]._source.activities
                              : props.ticketData._source.platform
                                  .platform_name === "GMB"
                              ? item._source.activities
                              : props.ticketData._source.platform
                                  .platform_name === "LinkedIn"
                              ? props.linkedInFeeds.hits &&
                                props.linkedInFeeds.hits[index]._source
                                  .activities
                              : props.ticketData._source.platform
                                  .platform_name === "Indeed"
                              ? props.indeedFeeds.hits[index]._source.activities
                              : props.ticketData._source.platform
                                  .platform_name === "Reddit" &&
                                props.ticketData._source.activities
                                  .activities_name === "Comments"
                              ? props.redditComments.hits[index]._source
                                  .activities
                              : props.ticketData._source.platform
                                  .platform_name === "Reddit" &&
                                props.ticketData._source.activities
                                  .activities_name === "Posts"
                              ? props.redditPosts.hits[index]._source.activities
                              : props.ticketData._source.platform
                                  .platform_name === "Quora"
                              ? props.quoraFeed.hits[index]._source &&
                                props.quoraFeed.hits[index]._source.activities
                              : props.ticketData._source.activities &&
                                props.ticketData._source.activities
                                  .activities_name
                              ? props.ticketData._source.activities
                                  .activities_name
                              : props.ticketData._source.activities}
                          </p>
                        </ListItem>
                      </Grid>

                      {/* Customer Name and Profile box */}
                      <Grid
                        item
                        // xl={9} lg={9} md={9} sm={9} xs={3}
                      >
                        <ListItem className="p-0 m-0">
                          <Avatar
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            src={
                              props.ticketData._source.platform
                                .platform_name === "Facebook"
                                ? props.ticketData._source.customer_id !==
                                    null &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url
                                : props.ticketData._source.platform &&
                                  props.ticketData._source.platform
                                    .platform_name === "GMB" &&
                                  item._index === "gmb_questions_answers"
                                ? props.GMBQuestionFeeds.hits &&
                                  props.GMBQuestionFeeds.hits[index]._source
                                    .question_author_profile_pic
                                : props.ticketData._source.platform
                                    .platform_name === "Youtube"
                                ? props.youtubeFeed.hits &&
                                  props.youtubeFeed.hits[index]._source
                                    .activities === "Comments"
                                  ? props.youtubeFeed.hits[index]._source
                                      .commented_user_image_url
                                  : null
                                : props.ticketData._source.platform
                                    .platform_name === "GMB" &&
                                  item._index === "gmb_review"
                                ? props.GMBReviewFeeds.hits &&
                                  props.GMBReviewFeeds.hits[index]._source
                                    .reviewer_photo
                                : props.ticketData._source.platform
                                    .platform_name === "Playstore"
                                ? props.playstore.hits &&
                                  props.playstore.hits[index]._source
                                    .customer_image
                                : props.ticketData._source.platform
                                    .platform_name === "Appstore"
                                ? props.appStore.hits &&
                                  props.appStore.hits[index]._source
                                    .customer_image
                                : props.ticketData._source.platform &&
                                  (props.ticketData._source.platform
                                    .platform_name === "Twitter" ||
                                    props.ticketData._source.platform
                                      .platform_name === "Facebook" ||
                                    props.ticketData._source.platform
                                      .platform_name === "Instagram") &&
                                  props.ticketData._source.customer_id
                                ? props.ticketData._source.customer_id !==
                                    null &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url
                                : props.ticketData._source.platform &&
                                  (props.ticketData._source.platform
                                    .platform_name === "LinkedIn" ||
                                    props.ticketData._source.platform
                                      .platform_name === "Ambition Box") &&
                                  props.ticketData._source.customer_id
                                ? props.ticketData._source.customer_id !==
                                    null &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url
                                : props.ticketData._source.platform
                                    .platform_name === "Trust Pilot" &&
                                  props.ticketData._source.customer_id
                                ? props.ticketData._source.customer_id !==
                                    null &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url &&
                                  props.ticketData._source.customer_id
                                    .customer_profile_url
                                : null
                            }
                          >
                            {props.ticketData._source.platform.platform_name ===
                            "Facebook"
                              ? props.facebook.hits &&
                                props.facebook.hits[index]._source
                                  .commented_user_name
                                ? props.facebook.hits[
                                    index
                                  ]._source.commented_user_name.charAt(0)
                                : props.facebook.hits[index]._source
                                    .customer_name
                                ? props.facebook.hits[
                                    index
                                  ]._source.customer_name.charAt(0)
                                : null
                              : props.ticketData._source.platform
                                  .platform_name === "Twitter"
                              ? props.twitter.hits &&
                                props.twitter.hits[
                                  index
                                ]._source.customer_name.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Instagram"
                              ? props.instagramFeeds.hits[index]._source
                                  .comment_username &&
                                props.instagramFeeds.hits &&
                                props.instagramFeeds.hits[
                                  index
                                ]._source.comment_username.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "GMB"
                              ? item._index === "gmb_questions_answers"
                                ? props.GMBQuestionFeeds.hits &&
                                  props.GMBQuestionFeeds.hits[
                                    index
                                  ]._source.question_author_name.charAt(0)
                                : null
                              : props.ticketData._source.platform
                                  .platform_name === "LinkedIn" ||
                                props.ticketData._source.platform
                                  .platform_name === "Ambition Box"
                              ? props.ticketData._source.customer_id &&
                                props.ticketData._source.customer_id
                                  .customer_name &&
                                props.ticketData._source.customer_id.customer_name.charAt(
                                  0
                                )
                              : props.ticketData._source.platform
                                  .platform_name === "Youtube"
                              ? props.youtubeFeed.hits &&
                                props.youtubeFeed.hits[index]._source
                                  .activities === "Comments"
                                ? props.youtubeFeed.hits[index]._source
                                    .commented_username &&
                                  props.youtubeFeed.hits[
                                    index
                                  ]._source.commented_username.charAt(0)
                                : props.youtubeFeed.hits[index]._source
                                    .activities === "Videos"
                                ? props.youtubeFeed.hits[
                                    index
                                  ]._source.customer_name.charAt(0)
                                : null
                              : props.ticketData._source.platform
                                  .platform_name === "Playstore"
                              ? props.playstore.hits &&
                                props.playstore.hits[
                                  index
                                ]._source.customer_name.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Email"
                              ? props.emailFeeds.hits &&
                                props.emailFeeds.hits[
                                  index
                                ]._source.customer_name.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Consumer Complaints"
                              ? props.consumer_feed.hits &&
                                props.consumer_feed.hits[
                                  index
                                ]._source.customer_name.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Trust Pilot"
                              ? props.trustPilotFeeds.hits &&
                                props.trustPilotFeeds.hits[
                                  index
                                ]._source.customer_name.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Pinterest"
                              ? props.pinterestFeeds.hits &&
                                props.pinterestFeeds.hits[
                                  index
                                ]._source.commented_username.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Indeed"
                              ? props.indeedFeeds.hits &&
                                props.indeedFeeds.hits[
                                  index
                                ]._source.customer_name.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Reddit" &&
                                props.ticketData._source.activities
                                  .activities_name === "Comments"
                              ? props.redditComments.hits &&
                                props.redditComments.hits[
                                  index
                                ]._source.commented_by.charAt(0)
                              : props.ticketData._source.platform
                                  .platform_name === "Reddit" &&
                                props.ticketData._source.activities
                                  .activities_name === "Posts"
                              ? props.redditPosts.hits &&
                                props.redditPosts.hits[
                                  index
                                ]._source.posted_by.charAt(0)
                              : null}
                          </Avatar>
                          {/* FACEBOOK */}
                          {props.ticketData._source.platform.platform_name ===
                            "Facebook" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.facebook.hits &&
                                props.facebook.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {/* TWITTER */}
                          {props.ticketData._source.platform.platform_name ===
                            "Twitter" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.twitter.hits &&
                                props.twitter.hits[index]._source.customer_name}
                              {`(@${
                                props.twitter.hits &&
                                props.twitter.hits[index]._source
                                  .customer_handel
                              })`}
                            </p>
                          )}

                          {/* INSTAGRAM */}
                          {(props.ticketData._source.platform.platform_name ===
                            "Instagram" ||
                            props.ticketData._source.platform.platform_name ===
                              "Ambition Box") && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.ticketData &&
                                props.ticketData._source &&
                                props.ticketData._source.customer_id &&
                                props.ticketData._source.customer_id
                                  .customer_name}
                            </p>
                          )}

                          {/* GMB Question Answers*/}
                          {props.ticketData._source.platform.platform_name ===
                            "GMB" &&
                            item._index === "gmb_questions_answers" && (
                              <p
                                className="p-0 m-0 ml-1"
                                style={{
                                  fontSize: "13px",
                                }}
                              >
                                {props.GMBQuestionFeeds.hits &&
                                  props.GMBQuestionFeeds.hits[index]._source
                                    .question_author_name}
                                {/* {`(@${props.GMBQuestionFeeds.hits[index]._source.account_name})`} */}
                              </p>
                            )}

                          {/* GMB Reviews*/}
                          {props.ticketData._source.platform.platform_name ===
                            "GMB" &&
                            item._index === "gmb_review" && (
                              <p
                                className="p-0 m-0 ml-1"
                                style={{
                                  fontSize: "13px",
                                }}
                              >
                                {props.GMBReviewFeeds.hits &&
                                  props.GMBReviewFeeds.hits[index]._source
                                    .reviewer_name}

                                {/* {`(@${props.GMBReviewFeeds.hits[index]._source.account_name})`} */}
                              </p>
                            )}

                          {/* LINKEDIN */}
                          {props.ticketData._source.platform.platform_name ===
                            "LinkedIn" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.ticketData._source.customer_id &&
                                props.ticketData._source.customer_id
                                  .customer_name}
                              {`(@${
                                props.ticketData._source.customer_id &&
                                props.ticketData._source.customer_id
                                  .customer_name
                              })`}
                            </p>
                          )}

                          {/* YOUTUBE COMMENTS */}
                          {props.ticketData._source.platform.platform_name ===
                            "Youtube" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.youtubeFeed.hits[index]._source
                                .activities === "Comments"
                                ? props.youtubeFeed.hits[index]._source
                                    .commented_username
                                : props.youtubeFeed.hits[index]._source
                                    .activities === "Videos"
                                ? props.youtubeFeed.hits[index]._source
                                    .customer_name
                                : null}
                            </p>
                          )}

                          {/* PLAYSTORE */}
                          {props.ticketData._source.platform.platform_name ===
                            "Playstore" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.playstore.hits &&
                                props.playstore.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {/* APPSTORE */}
                          {props.ticketData._source.platform.platform_name ===
                            "Appstore" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.appStore.hits &&
                                props.appStore.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {/* Email */}
                          {props.ticketData._source.platform.platform_name ===
                            "Email" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.emailFeeds.hits &&
                                props.emailFeeds.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {props.ticketData._source.platform.platform_name ===
                            "Consumer Complaints" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.consumer_feed.hits &&
                                props.consumer_feed.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {/* Quora */}
                          {props.ticketData._source.platform.platform_name ===
                            "Quora" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.quoraFeed.hits &&
                                props.quoraFeed.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {props.ticketData._source.platform.platform_name ===
                            "Trust Pilot" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.trustPilotFeeds.hits &&
                                props.trustPilotFeeds.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {props.ticketData._source.platform.platform_name ===
                            "Pinterest" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.pinterestFeeds.hits &&
                                props.pinterestFeeds.hits[index]._source
                                  .commented_username}
                            </p>
                          )}

                          {props.ticketData._source.platform.platform_name ===
                            "Indeed" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.indeedFeeds.hits &&
                                props.indeedFeeds.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {props.ticketData._source.platform.platform_name ===
                            "Threads" && (
                            <p
                              className="p-0 m-0 ml-1"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.threadFeeds.hits &&
                                props.threadFeeds.hits[index]._source
                                  .customer_name}
                            </p>
                          )}

                          {props.ticketData._source.platform.platform_name ===
                            "Reddit" &&
                            props.ticketData._source.activities
                              .activities_name === "Comments" && (
                              <p
                                className="p-0 m-0 ml-1"
                                style={{
                                  fontSize: "13px",
                                }}
                              >
                                {props.redditComments.hits &&
                                  props.redditComments.hits[index]._source
                                    .commented_by}
                              </p>
                            )}

                          {props.ticketData._source.platform.platform_name ===
                            "Reddit" &&
                            props.ticketData._source.activities
                              .activities_name === "Posts" && (
                              <p
                                className="p-0 m-0 ml-1"
                                style={{
                                  fontSize: "13px",
                                }}
                              >
                                {props.redditPosts.hits &&
                                  props.redditPosts.hits[index]._source
                                    .posted_by}
                              </p>
                            )}
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xl={4.5}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      justifyContent="flex-end"
                      alignItems={"center"}
                      // className="bg-info"
                      gap={1}
                      // className="bg-warning"
                    >
                      <Grid
                        px={1}
                        py={0}
                        item
                        // xl={10}
                        // lg={10}
                        // md={10}
                        // sm={10}
                        // xs={10}
                        sx={{
                          backgroundColor: "#fafafa",
                          border: `0.2px solid 
                    ${theme.palette.background.primary}`,
                          borderRadius: 2,
                          color: "#39717c",
                        }}
                        textAlign="center"
                      >
                        <span
                          style={{
                            color: `${theme.palette.ticketListView.checkbox}`,
                            fontSize: "12px",
                          }}
                        >
                          {/* FACEBOOK */}
                          {props.ticketData._source.platform.platform_name ===
                            "Facebook" &&
                            props.ticketData._source.account_name &&
                            props.ticketData._source.account_name
                              .account_name &&
                            props.ticketData._source.account_name.account_name}

                          {/* TWITTER */}
                          {props.ticketData._source.platform.platform_name ===
                            "Twitter" &&
                            props.twitter.hits &&
                            props.twitter.hits[index]._source.account_name}

                          {/* THREADS */}
                          {props.ticketData._source.platform.platform_name ===
                            "Threads" &&
                            props.threadFeeds.hits &&
                            props.threadFeeds.hits[index]._source.account_name}

                          {/* INSTAGRAM */}
                          {props.ticketData._source.platform.platform_name ===
                            "Instagram" &&
                            props.instagramFeeds.hits &&
                            props.instagramFeeds.hits[index]._source
                              .account_name}

                          {/* GMB Question Answers*/}
                          {props.ticketData._source.platform.platform_name ===
                            "GMB" &&
                            item._index === "gmb_questions_answers" &&
                            props.GMBQuestionFeeds.hits &&
                            props.GMBQuestionFeeds.hits[index]._source
                              .account_name}

                          {/* GMB Question Answers*/}
                          {props.ticketData._source.platform.platform_name ===
                            "GMB" &&
                            item._index === "gmb_review" &&
                            props.GMBReviewFeeds.hits &&
                            props.GMBReviewFeeds.hits[index]._source
                              .account_name}

                          {/* LinkedIn */}
                          {props.ticketData._source.platform.platform_name ===
                            "LinkedIn" &&
                            props.linkedInFeeds.hits &&
                            props.linkedInFeeds.hits[index]._source
                              .account_name}

                          {/* LinkedIn */}
                          {props.ticketData._source.platform.platform_name ===
                            "Ambition Box" &&
                            props.ambitionBoxFeeds.hits &&
                            props.ambitionBoxFeeds.hits[index]._source
                              .account_name}

                          {/* YOUTUBE */}
                          {props.ticketData._source.platform.platform_name ===
                            "Youtube" &&
                            props.youtubeFeed.hits &&
                            props.youtubeFeed.hits[index]._source.account_name}

                          {/* Playstore */}
                          {props.ticketData._source.platform.platform_name ===
                            "Playstore" &&
                            props.playstore.hits &&
                            props.playstore.hits[index]._source.app_name}

                          {/* Appstore */}
                          {props.ticketData._source.platform.platform_name ===
                            "Appstore" &&
                            props.appStore.hits &&
                            props.appStore.hits[index]._source.app_name}

                          {/* Glassdoor */}
                          {props.ticketData._source.platform.platform_name ===
                            "Glassdoor" &&
                            props.glassdoorFeeds.hits &&
                            props.glassdoorFeeds.hits[index]._source
                              .account_name}

                          {/* Email */}
                          {props.ticketData._source.platform.platform_name ===
                            "Email" &&
                            props.emailFeeds.hits &&
                            props.emailFeeds.hits[index]._source.account_name}

                          {props.ticketData._source.platform.platform_name ===
                            "Consumer Complaints" &&
                            props.consumer_feed.hits &&
                            props.consumer_feed.hits[index]._source
                              .account_name}

                          {/* Indeed */}
                          {props.ticketData._source.platform.platform_name ===
                            "Indeed" &&
                            props.indeedFeeds.hits &&
                            props.indeedFeeds.hits[index]._source.account_name}

                          {/* Quora */}
                          {props.ticketData._source.platform.platform_name ===
                            "Quora" &&
                            props.quoraFeed.hits &&
                            props.quoraFeed.hits[index]._source.account_name}

                          {/* Trust Pilot */}
                          {props.ticketData._source.platform.platform_name ===
                            "Trust Pilot" &&
                            props.ticketData._source.account_name.account_name}

                          {/* Pinterest */}
                          {props.ticketData._source.platform.platform_name ===
                            "Pinterest" &&
                            props.pinterestFeeds.hits &&
                            props.pinterestFeeds.hits[index]._source
                              .account_name}

                          {/* Reddit */}
                          {props.ticketData._source.platform.platform_name ===
                            "Reddit" &&
                            props.ticketData._source.activities
                              .activities_name === "Comments" &&
                            props.redditComments.hits &&
                            props.redditComments.hits[index]._source
                              .account_name}

                          {props.ticketData._source.platform.platform_name ===
                            "Reddit" &&
                            props.ticketData._source.activities
                              .activities_name === "Posts" &&
                            props.redditPosts.hits &&
                            props.redditPosts.hits[index]._source.account_name}
                        </span>
                      </Grid>

                      <Grid
                        item
                        // xl={6} lg={6} md={6} sm={6} xs={6}
                      >
                        <Tooltip
                          title={`Mentioned On : ${replyTimeString(
                            props,
                            index,
                            item
                          )}`}
                          arrow
                          placement="left"
                          style={{
                            cursor: "default",
                          }}
                        >
                          <ListItem className="p-0 m-0">
                            <AccessTimeIcon
                              style={{
                                width: "13px",
                                marginRight: 2,
                                color: `${theme.palette.text.dark}`,
                              }}
                            />
                            <span
                              className="m-0 p-0"
                              style={{
                                fontSize: "12px",
                                color: `${theme.palette.text.dark}`,
                              }}
                            >
                              {props.ticketData._source.platform
                                .platform_name === "Facebook" &&
                                timeAgo.format(
                                  new Date(
                                    props.facebook.hits[
                                      index
                                    ]._source.comment_created_at
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Twitter" &&
                                timeAgo.format(
                                  new Date(
                                    props.twitter.hits[index]._source.created
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Threads" &&
                                timeAgo.format(
                                  new Date(
                                    props.threadFeeds.hits[
                                      index
                                    ]._source.thread_created_datetime
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Instagram" &&
                                props.instagramFeeds.hits &&
                                timeAgo.format(
                                  new Date(
                                    props.instagramFeeds.hits[index]._source
                                      .comment_created_time
                                      ? props.instagramFeeds.hits[index]._source
                                          .comment_created_time
                                      : props.instagramFeeds.hits[index]._source
                                          .parent_post_created_time
                                      ? props.instagramFeeds.hits[index]._source
                                          .parent_post_created_time
                                      : ""
                                  )
                                )}

                              {/* YOUTUBE */}
                              {props.ticketData._source.platform
                                .platform_name === "Youtube" &&
                                timeAgo.format(
                                  new Date(
                                    props.youtubeFeed.hits[index]._source
                                      .activities === "Comments"
                                      ? props.youtubeFeed.hits[index]._source
                                          .comment_created_time
                                      : props.youtubeFeed.hits[index]._source
                                          .activities === "Videos"
                                      ? props.youtubeFeed.hits[index]._source
                                          .video_published_date
                                      : null
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "GMB" &&
                                item._index === "gmb_questions_answers" &&
                                timeAgo.format(
                                  new Date(
                                    props.GMBQuestionFeeds.hits[
                                      index
                                    ]._source.question_created_at
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "GMB" &&
                                item._index === "gmb_review" &&
                                timeAgo.format(
                                  new Date(
                                    props.GMBReviewFeeds.hits[
                                      index
                                    ]._source.created_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "LinkedIn" &&
                                timeAgo.format(
                                  new Date(
                                    props.linkedInFeeds.hits[
                                      index
                                    ]._source.created_at
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Ambition Box" &&
                                timeAgo.format(
                                  new Date(
                                    props.ambitionBoxFeeds.hits[
                                      index
                                    ]._source.posted_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Playstore" &&
                                timeAgo.format(
                                  new Date(
                                    props.playstore.hits[
                                      index
                                    ]._source.review_created_at
                                  )
                                )}

                              {props.ticketData._source.platform &&
                                props.ticketData._source.platform
                                  .platform_name === "Glassdoor" &&
                                timeAgo.format(
                                  new Date(
                                    props.glassdoorFeeds.hits[
                                      index
                                    ]._source.date
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Email" &&
                                timeAgo.format(
                                  new Date(
                                    props.emailFeeds.hits[index]._source.date
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Quora" &&
                                timeAgo.format(
                                  new Date(
                                    props.quoraFeed.hits[
                                      index
                                    ]._source.created_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Appstore" &&
                                timeAgo.format(
                                  new Date(
                                    props.appStore.hits[
                                      index
                                    ]._source.created_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Indeed" &&
                                timeAgo.format(
                                  new Date(
                                    props.indeedFeeds.hits[
                                      index
                                    ]._source.created_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Trust Pilot" &&
                                timeAgo.format(
                                  new Date(
                                    props.trustPilotFeeds.hits[
                                      index
                                    ]._source.created_at
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Pinterest" &&
                                timeAgo.format(
                                  new Date(
                                    props.pinterestFeeds.hits[
                                      index
                                    ]._source.commented_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Reddit" &&
                                props.ticketData._source.activities
                                  .activities_name === "Posts" &&
                                timeAgo.format(
                                  new Date(
                                    props.redditPosts.hits[
                                      index
                                    ]._source.posted_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Reddit" &&
                                props.ticketData._source.activities
                                  .activities_name === "Comments" &&
                                timeAgo.format(
                                  new Date(
                                    props.redditComments.hits[
                                      index
                                    ]._source.commented_on
                                  )
                                )}

                              {props.ticketData._source.platform
                                .platform_name === "Consumer Complaints" &&
                                timeAgo.format(
                                  new Date(
                                    props.consumer_feed.hits[
                                      index
                                    ]._source.created_on
                                  )
                                )}
                            </span>
                          </ListItem>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>

                  <hr className="p-0 m-0" />

                  <Box py={0.5}>
                    {/* Facebook Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Facebook" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            {props.facebook.hits[index]._source.comment_content}
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Pinterest Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Pinterest" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            {
                              props.pinterestFeeds.hits[index]._source
                                .comment_content
                            }
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {props.ticketData._source.platform.platform_name ===
                      "Threads" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            {
                              props.threadFeeds.hits[index]._source
                                .thread_content
                            }
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Twitter Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Twitter" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        {props.twitter.hits[index]._source.media.length !== 0 &&
                          props.twitter.hits[index]._source.media.map(
                            (param) => (
                              <Box width="100%">
                                {param.type === "photo" ? (
                                  <Box width="100%" sx={{ padding: "10px" }}>
                                    <RenderMedia
                                      image_url={param.url}
                                      mediaType="img"
                                      height="100"
                                    />
                                  </Box>
                                ) : (
                                  <Box width="100%" sx={{ padding: "10px" }}>
                                    <RenderMedia
                                      image_url={param.url}
                                      mediaType="video"
                                      height="100"
                                    />
                                  </Box>
                                )}
                              </Box>
                            )
                          )}
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            {
                              props.twitter.hits[index]._source
                                .content_of_the_tweet
                            }
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Instagram Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Instagram" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            {/* {props.instagramFeeds.hits[index]._source
                          .comment_content
                          ? props.instagramFeeds.hits[index]._source
                              .comment_content
                          : props.instagramFeeds.hits[index]._source
                              .parent_post_caption
                          ? props.instagramFeeds.hits[index]._source
                              .parent_post_caption
                          : ""} */}

                            {props.instagramFeeds.hits[index]._source
                              .activities === "Captions Mention" ||
                            props.instagramFeeds.hits[index]._source
                              .activities === "Tagged Posts" ? (
                              <Box
                              // className="bg-warning"
                              >
                                <Grid
                                  item
                                  container
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    textAlign="left"
                                  >
                                    {
                                      props.instagramFeeds.hits[index]._source
                                        .parent_post_mediya_type ===
                                        "IMAGE" && (
                                        <Box
                                          width="100%"
                                          sx={{ padding: "10px" }}
                                        >
                                          <RenderMedia
                                            image_url={
                                              props.instagramFeeds.hits[index]
                                                ._source.parent_post_media_url
                                            }
                                            mediaType="img"
                                            height="100"
                                          />
                                        </Box>
                                      )
                                      // : (
                                      //   <Box width="100%" sx={{ padding: "10px" }}>
                                      //     <RenderMedia
                                      //       image_url={
                                      //         props.instagramFeeds.hits[index]._source
                                      //           .parent_post_media_url
                                      //       }
                                      //       mediaType="video"
                                      //       height="100"
                                      //     />
                                      //   </Box>
                                      //     )
                                    }

                                    {props.instagramFeeds.hits[index]._source
                                      .parent_post_mediya_type === "VIDEO" && (
                                      <Box
                                        width="100%"
                                        sx={{ padding: "10px" }}
                                      >
                                        <RenderMedia
                                          image_url={
                                            props.instagramFeeds.hits[index]
                                              ._source.parent_post_media_url
                                          }
                                          mediaType="video"
                                          height="100"
                                        />
                                      </Box>
                                    )}
                                  </Grid>

                                  <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                  >
                                    {
                                      props.instagramFeeds.hits[index]._source
                                        .parent_post_caption
                                    }
                                  </Grid>
                                </Grid>
                              </Box>
                            ) : (
                              props.instagramFeeds.hits[index]._source
                                .comment_content
                            )}
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* GMB Question feeds */}
                    {props.ticketData._source.platform.platform_name ===
                      "GMB" &&
                      item._index === "gmb_questions_answers" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          <Typography
                            fontSize={{
                              xl: "12px",
                              lg: "12px",
                              md: "11px",
                              laptop: "11px",
                            }}
                          >
                            <span
                              className="m-0 py-1  text-left"
                              style={{
                                fontSize: "12px",
                                color: `${theme.palette.text.ticketContent}`,
                              }}
                            >
                              {
                                props.GMBQuestionFeeds.hits[index]._source
                                  .question
                              }
                            </span>
                          </Typography>
                        </Box>
                      )}

                    {/* GMB Review feeds */}
                    {props.ticketData._source.platform.platform_name ===
                      "GMB" &&
                      item._index === "gmb_review" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          <Typography
                            fontSize={{
                              xl: "12px",
                              lg: "12px",
                              md: "11px",
                              laptop: "11px",
                            }}
                          >
                            <span
                              className="m-0 py-1  text-left"
                              style={{
                                fontSize: "12px",
                                color: `${theme.palette.text.ticketContent}`,
                              }}
                            >
                              {props.GMBReviewFeeds.hits &&
                                props.GMBReviewFeeds.hits[index]._source
                                  .review_comment}
                            </span>
                          </Typography>
                        </Box>
                      )}

                    {props.ticketData._source.platform.platform_name ===
                      "GMB" &&
                      item._index === "gmb_review" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            Rating : {"  "}
                            {returnStars(
                              props.GMBReviewFeeds.hits &&
                                props.GMBReviewFeeds.hits[index]._source
                                  .review_rating
                            )}
                          </span>
                        </Box>
                      )}

                    {/* Linked Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "LinkedIn" &&
                      props.linkedInFeeds.hits[index]._source.activities &&
                      props.linkedInFeeds.hits[index]._source.activities ===
                        "Comment" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          {props.linkedInFeeds.hits &&
                          props.linkedInFeeds.hits[index]._source
                            .comment_media_type !== "NONE" ? (
                            props.linkedInFeeds.hits &&
                            props.linkedInFeeds.hits[index]._source
                              .comment_media_type === "IMAGE" ? (
                              <Box width="100%" sx={{ padding: "10px" }}>
                                <RenderMedia
                                  image_url={
                                    props.linkedInFeeds.hits &&
                                    props.linkedInFeeds.hits[index]._source
                                      .comment_media_url
                                  }
                                  mediaType="img"
                                  height="100"
                                />
                              </Box>
                            ) : (
                              props.linkedInFeeds.hits &&
                              props.linkedInFeeds.hits[index]._source
                                .comment_media_type === "video" && (
                                <Box width="100%" sx={{ padding: "10px" }}>
                                  <RenderMedia
                                    image_url={
                                      props.linkedInFeeds.hits &&
                                      props.linkedInFeeds.hits[index]._source
                                        .comment_media_url
                                    }
                                    mediaType="video"
                                    height="100"
                                  />
                                </Box>
                              )
                            )
                          ) : (
                            <Typography
                              fontSize={{
                                xl: "12px",
                                lg: "12px",
                                md: "11px",
                                laptop: "11px",
                              }}
                            >
                              <span
                                className="m-0 py-1  text-left"
                                style={{
                                  fontSize: "12px",
                                  color: `${theme.palette.text.ticketContent}`,
                                }}
                              >
                                {props.linkedInFeeds.hits &&
                                  props.linkedInFeeds.hits[index]._source
                                    .comment_content}
                              </span>
                            </Typography>
                          )}
                        </Box>
                      )}

                    {props.ticketData._source.platform.platform_name ===
                      "LinkedIn" &&
                      props.linkedInFeeds.hits[index]._source.activities &&
                      props.linkedInFeeds.hits[index]._source.activities ===
                        "Post Mention" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          {props.linkedInFeeds.hits &&
                            props.linkedInFeeds.hits[index]._source
                              .post_media_type !== "NONE" &&
                            (props.linkedInFeeds.hits &&
                            props.linkedInFeeds.hits[index]._source
                              .post_media_type === "IMAGE" ? (
                              <Box width="100%" sx={{ padding: "10px" }}>
                                <RenderMedia
                                  image_url={
                                    props.linkedInFeeds.hits &&
                                    props.linkedInFeeds.hits[index]._source
                                      .post_media_url
                                  }
                                  mediaType="img"
                                  height="100"
                                />
                              </Box>
                            ) : (
                              props.linkedInFeeds.hits &&
                              props.linkedInFeeds.hits[index]._source
                                .post_media_type === "video" && (
                                <Box width="100%" sx={{ padding: "10px" }}>
                                  <RenderMedia
                                    image_url={
                                      props.linkedInFeeds.hits &&
                                      props.linkedInFeeds.hits[index]._source
                                        .post_media_url
                                    }
                                    mediaType="video"
                                    height="100"
                                  />
                                </Box>
                              )
                            ))}
                          <Typography
                            fontSize={{
                              xl: "12px",
                              lg: "12px",
                              md: "11px",
                              laptop: "11px",
                            }}
                          >
                            <span
                              className="m-0 py-1  text-left"
                              style={{
                                fontSize: "12px",
                                color: `${theme.palette.text.ticketContent}`,
                              }}
                            >
                              {props.linkedInFeeds.hits &&
                                props.linkedInFeeds.hits[index]._source
                                  .post_content}
                            </span>
                          </Typography>
                        </Box>
                      )}
                    {/* YOUTUBE Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Youtube" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        {props.youtubeFeed.hits &&
                        props.youtubeFeed.hits[index]._source.activities ===
                          "Videos" ? (
                          <Box width="100%" sx={{ padding: "10px" }}>
                            <RenderMedia
                              image_url={
                                props.youtubeFeed.hits &&
                                props.youtubeFeed.hits[index]._source
                                  .video_thumbnail
                              }
                              mediaType="img"
                              height="100"
                            />
                          </Box>
                        ) : // <Box width="100%" sx={{ padding: "10px" }}>
                        //   <RenderMedia
                        //     image_url={
                        //       props.youtubeFeed.hits &&
                        //       props.youtubeFeed.hits[index]._source.video_url
                        //     }
                        //     mediaType="video"
                        //     height="100"
                        //   />
                        // </Box>
                        null}

                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            {props.youtubeFeed.hits &&
                            props.youtubeFeed.hits[index]._source.activities ===
                              "Comments"
                              ? props.youtubeFeed.hits[index]._source
                                  .comment_content
                              : props.youtubeFeed.hits[index]._source
                                  .activities === "Videos"
                              ? props.youtubeFeed.hits[index]._source
                                  .video_title
                              : null}
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Playstore Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Playstore" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            {props.playstore.hits &&
                              props.playstore.hits[index]._source
                                .review_content}
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Playstore Platform Rating Stars Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Playstore" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            Rating :{" "}
                            {returnStarsIcon(
                              props.playstore.hits &&
                                props.playstore.hits[index]._source.ratings
                            )}
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Appstore Platform Activity Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Appstore" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1 text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            <strong>Heading : </strong>
                            {props.appStore.hits &&
                              props.appStore.hits[index]._source.review_heading}
                            <br />
                            {/* <hr></hr> */}
                            <strong>Review : </strong>
                            {props.appStore.hits &&
                              props.appStore.hits[index]._source.review}
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Appstore Platform Rating Stars Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Appstore" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <span
                            className="m-0 py-1  text-left"
                            style={{
                              fontSize: "12px",
                              color: `${theme.palette.text.ticketContent}`,
                            }}
                          >
                            <strong>Rating : </strong>
                            {returnStarsIcon(
                              props.appStore.hits &&
                                props.appStore.hits[index]._source.rating
                            )}
                          </span>
                        </Typography>
                      </Box>
                    )}

                    {/* Glassdoor Platform Rating Stars Information box */}
                    {props.ticketData._source.platform &&
                      props.ticketData._source.platform.platform_name ===
                        "Glassdoor" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          <p className="m-0 py-1  text-left">
                            Job Title :{" "}
                            {props.glassdoorFeeds.hits &&
                              props.glassdoorFeeds.hits[index]._source.jobTitle}
                          </p>
                          <p className="m-0 py-1  text-left">
                            Rating :{" "}
                            {returnStars(
                              props.glassdoorFeeds.hits &&
                                props.glassdoorFeeds.hits[index]._source
                                  .overallRating
                            )}
                          </p>

                          <p className="m-0 py-1  text-left">
                            Review :{" "}
                            {props.glassdoorFeeds.hits &&
                              props.glassdoorFeeds.hits[index]._source.reviews}
                          </p>

                          <Box
                            sx={{
                              padding: 2,
                              margin: 2,
                              border: "solid 0.5px #d3e2e6",
                              borderRadius: theme.borderRadius,
                              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                              backgroundColor: "#ffffff",
                            }}
                            mb={0.5}
                          >
                            <p className="p-0 m-0 mb-1">
                              <span className="font-weight-bold text-justify">
                                {" "}
                                pros :
                              </span>{" "}
                              {props.glassdoorFeeds.hits &&
                                props.glassdoorFeeds.hits[index]._source.pros}
                            </p>
                            <p>
                              <span className="font-weight-bold text-justify">
                                {" "}
                                cons :
                              </span>{" "}
                              {props.glassdoorFeeds.hits &&
                                props.glassdoorFeeds.hits[index]._source.cons}
                            </p>
                          </Box>
                        </Box>
                      )}

                    {/* Glassdoor Platform Rating Stars Information box */}
                    {props.ticketData._source.platform &&
                      props.ticketData._source.platform.platform_name ===
                        "Ambition Box" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          <p className="m-0 py-1  text-left">
                            Designation :{" "}
                            {props.ambitionBoxFeeds.hits &&
                              props.ambitionBoxFeeds.hits[index]._source
                                .designation}
                          </p>
                          <p className="m-0 py-1  text-left">
                            Rating :{" "}
                            {returnStars(
                              props.ambitionBoxFeeds.hits &&
                                props.ambitionBoxFeeds.hits[index]._source
                                  .rating
                            )}
                          </p>

                          <p className="m-0 py-1  text-left">
                            title :{" "}
                            {props.ambitionBoxFeeds.hits &&
                              props.ambitionBoxFeeds.hits[index]._source.title}
                          </p>

                          <Box
                            sx={{
                              padding: 2,
                              margin: 2,
                              border: "solid 0.5px #d3e2e6",
                              borderRadius: theme.borderRadius,
                              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                              backgroundColor: "#ffffff",
                            }}
                            mb={0.5}
                          >
                            <p className="p-0 m-0 mb-1">
                              <span className="font-weight-bold text-justify">
                                {" "}
                                pros :
                              </span>{" "}
                              {props.ambitionBoxFeeds.hits &&
                                props.ambitionBoxFeeds.hits[index]._source
                                  .likes}
                            </p>
                            <p>
                              <span className="font-weight-bold text-justify">
                                {" "}
                                cons :
                              </span>{" "}
                              {props.ambitionBoxFeeds.hits &&
                                props.ambitionBoxFeeds.hits[index]._source
                                  .dislikes}
                            </p>
                          </Box>
                        </Box>
                      )}

                    {/* Email Platform Rating Stars Information box */}
                    {props.ticketData._source.platform.platform_name ===
                      "Email" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Grid
                          container
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            backgroundColor: theme.palette.background.default,
                            // height: 550,
                            position: "relative",
                            // overflow: "auto",
                            // overscrollBehaviorY: "contain",
                          }}
                          // className="bg-warning"
                        >
                          {!isRequesting && (
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              height={"fit-content"}
                              p={2}
                              mx={2}
                              my={2}
                              sx={{
                                border: "solid 0.5px white",
                                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                borderRadius:
                                  theme.palette.buttonComponent.borderRadius
                                    .large,
                                backgroundColor: "white",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                }}
                              >
                                <Grid
                                  container
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  // className="bg-warning"
                                >
                                  <Grid
                                    item
                                    xl={1.5}
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                    textAlign="left"
                                    // className="bg-success"
                                  >
                                    <span>From</span>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={10}
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    textAlign="left"
                                    // className="bg-info"
                                  >
                                    <span>
                                      :&nbsp;
                                      {props.emailFeeds.hits &&
                                        props.emailFeeds.hits[index]._source
                                          .from}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                }}
                              >
                                <Grid
                                  container
                                  xl={12}
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  // className="bg-warning"
                                >
                                  <Grid
                                    item
                                    xl={1.5}
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                    textAlign="left"
                                  >
                                    <span>To</span>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={10}
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    textAlign="left"
                                  >
                                    <span>
                                      :&nbsp;
                                      {props.emailFeeds.hits &&
                                      props.emailFeeds.hits[index]._source.to
                                        ? props.emailFeeds.hits &&
                                          props.emailFeeds.hits[
                                            index
                                          ]._source.to.map((item) => {
                                            return item;
                                          })
                                        : ""}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Typography>

                              <Typography
                                sx={{
                                  fontSize: "16px",
                                }}
                              >
                                <Grid
                                  container
                                  xl={12}
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                  // className="bg-warning"
                                >
                                  <Grid
                                    item
                                    xl={1.5}
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                    textAlign="left"
                                  >
                                    <span>Subject</span>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={10}
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    textAlign="left"
                                  >
                                    <span>
                                      :&nbsp;
                                      {props.emailFeeds.hits &&
                                        props.emailFeeds.hits[index]._source
                                          .subject}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Typography>

                              {props.emailFeeds.hits &&
                                props.emailFeeds.hits[index]._source.cc.length >
                                  0 && (
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      xl={12}
                                      justifyContent="flex-start"
                                      alignItems="flex-start"

                                      // className="bg-warning"
                                    >
                                      <Grid
                                        item
                                        xl={1}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        xs={1}
                                        textAlign="left"
                                      >
                                        <span>cc</span>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={10}
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={10}
                                        textAlign="left"
                                      >
                                        <span>
                                          :&nbsp;
                                          {props.emailFeeds.hits &&
                                          props.emailFeeds.hits[index]._source
                                            .cc
                                            ? props.emailFeeds.hits &&
                                              props.emailFeeds.hits[
                                                index
                                              ]._source.cc.map((cc) => {
                                                return `${cc}, `;
                                              })
                                            : ""}
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                )}

                              {props.emailFeeds.hits &&
                                props.emailFeeds.hits[index]._source.bcc
                                  .length > 0 && (
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      xl={12}
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      // className="bg-warning"
                                    >
                                      <Grid
                                        item
                                        xl={1.5}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        xs={1}
                                        textAlign="left"
                                      >
                                        <span>bcc</span>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={10}
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={10}
                                        textAlign="left"
                                      >
                                        <span>
                                          :&nbsp;
                                          {props.emailFeeds.hits &&
                                          props.emailFeeds.hits[index]._source
                                            .bcc
                                            ? props.emailFeeds.hits &&
                                              props.emailFeeds.hits[
                                                index
                                              ]._source.bcc.map((bcc) => {
                                                return `${bcc}, `;
                                              })
                                            : ""}
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                )}

                              {props.emailFeeds.hits &&
                                props.emailFeeds.hits[index]._source.replay_to
                                  .length > 0 && (
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      xl={12}
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      // className="bg-warning"
                                    >
                                      <Grid
                                        item
                                        xl={1.5}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        xs={1}
                                        textAlign="left"
                                      >
                                        <span>Reply to</span>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={10}
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={10}
                                        textAlign="left"
                                      >
                                        <span>
                                          :{" "}
                                          {props.emailFeeds.hits &&
                                          props.emailFeeds.hits[index]._source
                                            .replay_to
                                            ? props.emailFeeds.hits &&
                                              props.emailFeeds.hits[
                                                index
                                              ]._source.replay_to.map(
                                                (replay_to) => {
                                                  return `${replay_to}, `;
                                                }
                                              )
                                            : ""}
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                )}

                              {props.emailFeeds.hits &&
                                props.emailFeeds.hits[index]._source.attachments
                                  .length > 0 && (
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      xl={12}
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      // className="bg-warning"
                                    >
                                      <Grid
                                        item
                                        xl={1.5}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        xs={1}
                                        textAlign="left"
                                      >
                                        <span>Attachments</span>
                                      </Grid>

                                      <Grid
                                        item
                                        xl={10}
                                        lg={10}
                                        md={10}
                                        sm={10}
                                        xs={10}
                                        textAlign="left"
                                      >
                                        <span>
                                          :{" "}
                                          {props.emailFeeds.hits &&
                                          props.emailFeeds.hits[index]._source
                                            .attachments
                                            ? props.emailFeeds.hits[
                                                index
                                              ]._source.attachments.map(
                                                (attachments) => {
                                                  return `${attachments.filename}, `;
                                                }
                                              )
                                            : ""}
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                )}

                              <Typography
                                sx={{
                                  fontSize: "16px",
                                }}
                              >
                                <Grid
                                  item
                                  container
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  // className="bg-warning"
                                >
                                  <Grid
                                    item
                                    xl={2}
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                    textAlign="left"
                                  >
                                    <span>Received on</span>
                                  </Grid>

                                  <Grid
                                    item
                                    xl={10}
                                    lg={10}
                                    md={10}
                                    sm={10}
                                    xs={10}
                                    textAlign="left"
                                  >
                                    <span>
                                      {" "}
                                      :{" "}
                                      {(function createdDate() {
                                        let created =
                                          props.emailFeeds.hits &&
                                          props.emailFeeds.hits[index]._source
                                            .date;
                                        let dateConversion = new Date(created);
                                        dateConversion =
                                          dateConversion.toDateString() +
                                          ", " +
                                          dateConversion.toLocaleTimeString(
                                            "en-US",
                                            {
                                              hour: "numeric",
                                              minute: "numeric",
                                              hour12: true,
                                            }
                                          );

                                        return dateConversion;
                                      })()}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Typography>

                              <hr></hr>

                              {renderingHtml(
                                props.emailFeeds.hits &&
                                  props.emailFeeds.hits[index]._source.body_html
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    )}

                    {props.ticketData._source.platform.platform_name ===
                      "Quora" && (
                      <Box px={2} py={1}>
                        {props.quoraFeed.hits ? (
                          props.quoraFeed.hits.map((item, i) => (
                            <LoadingIndicator isActive={isRequesting}>
                              {!isRequesting && (
                                <Box>
                                  <Box>
                                    <p className="font-weight-bold">
                                      {item._source.question}
                                    </p>
                                  </Box>

                                  <p>
                                    <Typography className="text-justify">
                                      {item._source.answer}
                                    </Typography>
                                  </p>
                                </Box>
                              )}
                            </LoadingIndicator>
                          ))
                        ) : (
                          <NoDataFound />
                        )}
                      </Box>
                    )}

                    {props.ticketData._source.platform.platform_name ===
                      "Indeed" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "14px",
                            lg: "14px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          {props.indeedFeeds.hits &&
                            props.indeedFeeds.hits[index]._source
                              .author_details && (
                              <>
                                <p className="p-0 m-0 mb-1">
                                  <span className="font-weight-bold text-justify">
                                    {" "}
                                    Author Designation :
                                  </span>{" "}
                                  {props.indeedFeeds.hits &&
                                    props.indeedFeeds.hits[index]._source
                                      .author_details.designation}
                                </p>
                                <p className="p-0 m-0 mb-1">
                                  <span className="font-weight-bold text-justify">
                                    {" "}
                                    Author Location :
                                  </span>{" "}
                                  {props.indeedFeeds.hits &&
                                    props.indeedFeeds.hits[index]._source
                                      .author_details.location}
                                </p>
                                <p className="p-0 m-0 mb-1">
                                  <span className="font-weight-bold text-justify">
                                    {" "}
                                    Posted on :
                                  </span>{" "}
                                  {(function createdDate() {
                                    let created =
                                      props.indeedFeeds.hits[index]._source
                                        .author_details.posted_on;
                                    let dateConversion = new Date(created);
                                    dateConversion =
                                      dateConversion.toDateString() +
                                      " " +
                                      dateConversion.toLocaleTimeString(
                                        "en-US",
                                        {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      );

                                    return dateConversion;
                                  })()}
                                </p>
                              </>
                            )}
                          {props.indeedFeeds.hits &&
                            props.indeedFeeds.hits[index]._source
                              .rating_title && (
                              <p className="p-0 m-0 mb-1">
                                <span className="font-weight-bold text-justify">
                                  {" "}
                                  Title :
                                </span>{" "}
                                {props.indeedFeeds.hits &&
                                  props.indeedFeeds.hits[index]._source
                                    .rating_title}
                              </p>
                            )}

                          {props.indeedFeeds.hits &&
                            props.indeedFeeds.hits[index]._source
                              .rating_description && (
                              <p className="p-0 m-0 mb-1">
                                <span className="font-weight-bold text-justify">
                                  {" "}
                                  Description :
                                </span>{" "}
                                {props.indeedFeeds.hits &&
                                  props.indeedFeeds.hits[index]._source
                                    .rating_description}
                              </p>
                            )}

                          {props.indeedFeeds.hits &&
                            props.indeedFeeds.hits[index]._source
                              .rating_pros && (
                              <p className="p-0 m-0 mb-1">
                                <span className="font-weight-bold text-justify">
                                  {" "}
                                  pros :
                                </span>{" "}
                                {props.indeedFeeds.hits &&
                                  props.indeedFeeds.hits[index]._source
                                    .rating_pros}
                              </p>
                            )}
                          {props.indeedFeeds.hits[index]._source
                            .rating_cons && (
                            <p>
                              <span className="font-weight-bold text-justify">
                                {" "}
                                cons :
                              </span>{" "}
                              {props.indeedFeeds.hits &&
                                props.indeedFeeds.hits[index]._source
                                  .rating_cons}
                            </p>
                          )}
                        </Typography>
                      </Box>
                    )}

                    {props.ticketData._source.platform.platform_name ===
                      "Trust Pilot" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "14px",
                            lg: "14px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          {props.trustPilotFeeds.hits &&
                            props.trustPilotFeeds.hits[index]._source && (
                              <>
                                <p className="p-0 m-0 mb-1">
                                  <span className="font-weight-bold text-justify">
                                    {" "}
                                    Review title :
                                  </span>{" "}
                                  {props.trustPilotFeeds.hits &&
                                    props.trustPilotFeeds.hits[index]._source
                                      .review_title}
                                </p>
                                <p className="p-0 m-0 mb-1">
                                  <span className="font-weight-bold text-justify">
                                    {" "}
                                    Review description:
                                  </span>{" "}
                                  {props.trustPilotFeeds.hits &&
                                    props.trustPilotFeeds.hits[index]._source
                                      .description}
                                </p>
                              </>
                            )}

                          {props.trustPilotFeeds.hits &&
                            props.trustPilotFeeds.hits[index]._source
                              .rating && (
                              <p className="p-0 m-0 mb-1">
                                <span className="font-weight-bold text-justify">
                                  {" "}
                                  Rating :
                                </span>{" "}
                                {returnStarsIcon(
                                  props.trustPilotFeeds.hits &&
                                    props.trustPilotFeeds.hits[index]._source
                                      .rating
                                )}
                              </p>
                            )}
                        </Typography>
                      </Box>
                    )}

                    {props.ticketData._source.platform.platform_name ===
                      "Indeed" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "12px",
                            lg: "12px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          <p className="p-0 m-0 mb-1">
                            <span className="font-weight-bold text-justify">
                              {" "}
                              Rating :
                            </span>{" "}
                            {returnStarsIcon(
                              props.indeedFeeds.hits &&
                                props.indeedFeeds.hits[index]._source.rating
                            )}
                          </p>
                        </Typography>
                      </Box>
                    )}

                    {props.ticketData._source.platform.platform_name ===
                      "Reddit" &&
                      props.ticketData._source.activities.activities_name ===
                        "Comments" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          <Typography
                            fontSize={{
                              xl: "14px",
                              lg: "14px",
                              md: "11px",
                              laptop: "11px",
                            }}
                          >
                            {props.redditComments.hits &&
                              props.redditComments.hits[index]._source
                                .comment_content && (
                                <p className="p-0 m-0 mb-1">
                                  {/* <span className="font-weight-bold text-justify">
                                    {" "}
                                    Comments :
                                  </span>{" "} */}
                                  {props.redditComments.hits &&
                                    props.redditComments.hits[index]._source
                                      .comment_content}
                                </p>
                              )}
                          </Typography>
                        </Box>
                      )}

                    {props.ticketData._source.platform.platform_name ===
                      "Reddit" &&
                      props.ticketData._source.activities.activities_name ===
                        "Posts" && (
                        <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                          <Typography
                            fontSize={{
                              xl: "14px",
                              lg: "14px",
                              md: "11px",
                              laptop: "11px",
                            }}
                          >
                            {props.redditPosts.hits &&
                              props.redditPosts.hits[index]._source
                                .post_title && (
                                <p className="p-0 m-0 mb-1">
                                  <span className="font-weight-bold text-justify">
                                    {" "}
                                    Post Title :
                                  </span>{" "}
                                  {props.redditPosts.hits &&
                                    props.redditPosts.hits[index]._source
                                      .post_title}
                                </p>
                              )}

                            {props.redditPosts.hits &&
                              props.redditPosts.hits[index]._source
                                .post_content && (
                                <p className="p-0 m-0 mb-1">
                                  <span className="font-weight-bold text-justify">
                                    {" "}
                                    Post Content :
                                  </span>{" "}
                                  {props.redditPosts.hits &&
                                    props.redditPosts.hits[index]._source
                                      .post_content}
                                </p>
                              )}
                          </Typography>
                        </Box>
                      )}

                    {props.ticketData._source.platform.platform_name ===
                      "Consumer Complaints" && (
                      <Box px={2} sx={{ overflowWrap: "anywhere" }}>
                        <Typography
                          fontSize={{
                            xl: "14px",
                            lg: "14px",
                            md: "11px",
                            laptop: "11px",
                          }}
                        >
                          {props.consumer_feed.hits &&
                            props.consumer_feed.hits[index]._source.title && (
                              <p className="p-0 m-0 mb-1">
                                <span className="font-weight-bold text-justify">
                                  {" "}
                                  Title :
                                </span>{" "}
                                {props.consumer_feed.hits &&
                                  props.consumer_feed.hits[index]._source.title}
                              </p>
                            )}

                          {props.consumer_feed.hits &&
                            props.consumer_feed.hits[index]._source
                              .description && (
                              <p className="p-0 m-0 mb-1">
                                <span className="font-weight-bold text-justify">
                                  {" "}
                                  Description :
                                </span>{" "}
                                {props.consumer_feed.hits &&
                                  props.consumer_feed.hits[index]._source
                                    .description}
                              </p>
                            )}

                          {props.consumer_feed.hits &&
                            props.consumer_feed.hits[index]._source
                              .posted_on && (
                              <p className="p-0 m-0 mb-1">
                                <span className="font-weight-bold text-justify">
                                  Posted on :
                                </span>
                                {props.consumer_feed.hits &&
                                  props.consumer_feed.hits[index]._source
                                    .posted_on}
                              </p>
                            )}
                        </Typography>
                      </Box>
                    )}

                    <Box px={2}>
                      <Typography
                        fontSize={{
                          xl: "12px",
                          lg: "12px",
                          md: "11px",
                          laptop: "11px",
                        }}
                      >
                        {values.feed[index] &&
                          values.feed[index].translateClicked &&
                          values.feed[index].translateClicked && (
                            <Grid
                              sx={{
                                backgroundColor: `${theme.palette.background.light}`,
                                border: `1px solid ${theme.palette.background.light}`,
                                borderRadius: "3px",
                                padding: "5px",
                                marginY: "5px",
                              }}
                            >
                              <Grid
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography component={"p"}>
                                  Translated to English
                                </Typography>
                                <Typography
                                  component={"p"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      `feed[${index}].translateClicked`,
                                      !values.feed[index].translateClicked
                                    );
                                  }}
                                >
                                  <HighlightOffOutlinedIcon
                                    titleAccess="Close"
                                    sx={{ width: "12px" }}
                                  />
                                </Typography>
                              </Grid>
                              <TranslatedContent
                                content={
                                  props.ticketData._source.platform
                                    .platform_name === "Twitter"
                                    ? `${props.twitter.hits[index]._source.content_of_the_tweet}`
                                    : props.ticketData._source.platform
                                        .platform_name === "Facebook"
                                    ? props.facebook.hits[index]._source
                                        .comment_content
                                    : props.ticketData._source.platform
                                        .platform_name === "Instagram"
                                    ? props.instagramFeeds.hits[index]._source
                                        .activities === "Captions Mention" ||
                                      props.instagramFeeds.hits[index]._source
                                        .activities === "Tagged Posts"
                                      ? props.instagramFeeds.hits[index]._source
                                          .parent_post_caption
                                      : props.instagramFeeds.hits[index]._source
                                          .comment_content
                                    : props.ticketData._source.platform
                                        .platform_name === "GMB"
                                    ? item._index === "gmb_questions_answers"
                                      ? props.GMBQuestionFeeds.hits[index]
                                          ._source.question
                                      : item._index === "gmb_review" &&
                                        props.GMBReviewFeeds.hits &&
                                        props.GMBReviewFeeds.hits[index]._source
                                          .review_comment
                                    : props.ticketData._source.platform
                                        .platform_name === "LinkedIn"
                                    ? props.linkedInFeeds.hits &&
                                      props.linkedInFeeds.hits[index]._source
                                        .comment_content
                                    : props.ticketData._source.platform
                                        .platform_name === "Youtube"
                                    ? props.youtubeFeed.hits &&
                                      props.youtubeFeed.hits[index]._source
                                        .activities === "Comments"
                                      ? props.youtubeFeed.hits[index]._source
                                          .comment_content
                                      : props.youtubeFeed.hits[index]._source
                                          .activities === "Videos"
                                      ? props.youtubeFeed.hits[index]._source
                                          .video_title
                                      : null
                                    : props.ticketData._source.platform
                                        .platform_name === "Playstore"
                                    ? props.playstore.hits &&
                                      props.playstore.hits[index]._source
                                        .review_content
                                    : props.ticketData._source.platform
                                        .platform_name === "Glassdoor"
                                    ? props.glassdoorFeeds.hits &&
                                      props.glassdoorFeeds.hits[index]._source
                                        .reviews
                                    : props.ticketData._source.platform
                                        .platform_name === "Appstore"
                                    ? props.appStore.hits &&
                                      `${props.appStore.hits[index]._source.review_heading}\n${props.appStore.hits[index]._source.review}`
                                    : props.ticketData._source.platform
                                        .platform_name === "Indeed"
                                    ? props.indeedFeeds.hits &&
                                      `${props.indeedFeeds.hits[index]._source.rating_title}\n${props.indeedFeeds.hits[index]._source.rating_description}`
                                    : //                 :
                                    //                 props.ticketData._source.platform
                                    //     .platform_name === "Reddit"
                                    // ? props.redditFeeds.hits &&
                                    //   `${props.redditFeeds.hits[index]._source.title}\n${props.redditFeeds.hits[index]._source.description}`
                                    props.ticketData._source.platform
                                        .platform_name === "Consumer Complaints"
                                    ? props.consumer_feed.hits &&
                                      `${props.consumer_feed.hits[index]._source.title}\n${props.consumer_feed.hits[index]._source.description}`
                                    : props.ticketData._source.platform
                                        .platform_name === "Quora"
                                    ? props.quoraFeed.hits &&
                                      props.quoraFeed.hits[index]._source.answer
                                    : props.ticketData._source.platform
                                        .platform_name === "Trust Pilot"
                                    ? props.trustPilotFeeds.hits &&
                                      `${props.trustPilotFeeds.hits[index]._source.review_title}\n${props.trustPilotFeeds.hits[index]._source.description}`
                                    : props.ticketData._source.platform
                                        .platform_name === "Pinterest"
                                    ? props.pinterestFeeds.hits &&
                                      props.pinterestFeeds.hits[index]._source
                                        .comment_content
                                    : null
                                }
                              />
                            </Grid>
                          )}
                      </Typography>
                    </Box>
                  </Box>

                  {/* <hr className="p-0 m-0" /> */}
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="space-between"
                    alignItems={"center"}
                    style={{
                      backgroundColor: "#f3f9fb",
                      borderTop: `0.5px solid 
                    ${theme.palette.background.primary}`,
                      borderBottom: `0.5px solid 
                    ${theme.palette.background.primary}`,
                    }}
                    px={2}

                    // className={"bg-warning"}
                  >
                    <Grid
                      item
                      container
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      justifyContent="flex-start"
                      alignItems={"center"}
                      my={0.5}
                      // className="bg-success"
                    >
                      <Grid
                        item
                        // container
                        // xl={1}
                        // lg={0.8}
                        // md={0.5}
                        // xs={0.5}
                        alignItems="center"
                        justifyContent={"flex-start"}
                        textAlign="left"
                        sx={{
                          borderRight: `1px solid ${theme.palette.background.primary}`,
                        }}
                        pr={1}
                        // className="bg-light"
                      >
                        <Grid item>
                          <ListItem
                            className="p-0 m-0"
                            style={{
                              padding: 0,
                              margin: 0,
                              color: `${theme.palette.ticketListView.iconColor}`,
                            }}
                          >
                            <Checkbox
                              className="p-0 m-0"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              // checked={selected}
                              sx={
                                {
                                  // width: 3,
                                  // height: 3,
                                }
                              }
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                      {item && item._source && item._source.ticket_id && (
                        <Grid
                          item
                          // container
                          // xl={1}
                          // lg={1.5}
                          // md={0.5}
                          // xs={0.5}
                          alignItems="center"
                          justifyContent={"center"}
                          textAlign="center"
                          // className="bg-info"
                          sx={{
                            borderRight: `1px solid ${theme.palette.background.primary}`,
                          }}
                          px={1}
                        >
                          <Grid item>
                            <IconButton
                              className="p-0 m-0"
                              title="Ticket Id"
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                item &&
                                  item._source &&
                                  item._source.ticket_id &&
                                  copyToClipboard(item._source.ticket_id);
                              }}
                              sx={{
                                // width: 6,
                                // height: 6,
                                color: `${theme.palette.ticketListView.iconColor}`,
                              }}
                            >
                              {/* <FileCopyIcon
                                fontSize="small"
                                style={{
                                  // marginRight: -5,
                                  width: "13px",
                                }}
                              /> */}

                              <FontAwesomeIcon
                                icon={faCopy}
                                style={{
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                  width: "13px",
                                }}
                              ></FontAwesomeIcon>
                            </IconButton>
                            <Snackbar
                              message="Ticket Id copied to clipboard"
                              sx={{ height: "70%" }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              autoHideDuration={500}
                              onClose={() => setCopied(false)}
                              open={copied}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        item
                        // container
                        // xl={1}
                        // lg={1.5}
                        // md={0.5}
                        // xs={0.5}
                        alignItems="center"
                        justifyContent={"center"}
                        textAlign="center"
                        // className="bg-info"
                        sx={{
                          borderRight: `1px solid ${theme.palette.background.primary}`,
                        }}
                        px={1}
                      >
                        <Grid item>
                          {/* {props.ticketData._source.sentiment_value !== null && ( */}
                          <Grid item>
                            <SentimentValueUpdatePopper
                              ticketData={props.ticketData}
                              feedData={item}
                              from="feeds"
                              fb_comment_id={
                                props.ticketData._source.platform
                                  .platform_name === "Facebook" &&
                                props.facebook.hits &&
                                props.facebook.hits[index]._source.comment_id
                              }
                              feedId={
                                props.ticketData._source.platform
                                  .platform_name === "Instagram"
                                  ? props.instagramFeeds.hits &&
                                    props.instagramFeeds.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Twitter"
                                  ? props.twitter.hits &&
                                    props.twitter.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Appstore"
                                  ? props.appStore.hits &&
                                    props.appStore.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Youtube"
                                  ? props.youtubeFeed.hits &&
                                    props.youtubeFeed.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "LinkedIn"
                                  ? props.linkedInFeeds.hits &&
                                    props.linkedInFeeds.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Facebook"
                                  ? props.facebook.hits &&
                                    props.facebook.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "GMB"
                                  ? item._index === "gmb_review"
                                    ? props.GMBReviewFeeds.hits &&
                                      props.GMBReviewFeeds.hits[index]._id
                                    : props.GMBQuestionFeeds.hits &&
                                      props.GMBQuestionFeeds.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Indeed"
                                  ? props.indeedFeeds.hits &&
                                    props.indeedFeeds.hits[index]._id
                                  : //               :
                                  //               props.ticketData._source.platform
                                  //     .platform_name === "Reddit"
                                  // ? props.redditFeeds.hits &&
                                  //   props.redditFeeds.hits[index]._id
                                  props.ticketData._source.platform
                                      .platform_name === "Consumer Complaints"
                                  ? props.consumer_feed.hits &&
                                    props.consumer_feed.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Email"
                                  ? props.emailFeeds.hits &&
                                    props.emailFeeds.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Playstore"
                                  ? props.playstore.hits &&
                                    props.playstore.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Quora"
                                  ? props.quoraFeed.hits &&
                                    props.quoraFeed.hits[index]._id
                                  : props.ticketData._source.platform
                                      .platform_name === "Trust Pilot"
                                  ? props.trustPilotFeeds.hits &&
                                    props.trustPilotFeeds.hits[index]._id
                                  : null
                              }
                              fetchFunction={(sentiment) => {
                                let localstorage_sentiment =
                                  localStorage.getItem("mentionsFilters") &&
                                  JSON.parse(
                                    localStorage.getItem("mentionsFilters")
                                  ).sentiment_value;

                                if (localstorage_sentiment) {
                                  if (localstorage_sentiment !== sentiment) {
                                    props.fetchFunction();
                                  } else {
                                    props.dispatch(
                                      TicketsAction.requestTicketsById(
                                        props.ticketData._id
                                      )
                                    );
                                  }
                                } else {
                                  props.dispatch(
                                    TicketsAction.requestTicketsById(
                                      props.ticketData._id
                                    )
                                  );
                                }
                              }}
                              filters={props.filters}
                              pageNumber={props.pageNumber}
                              page_limit={props.page_limit}
                            >
                              {props.ticketData._source.platform
                                .platform_name === "Instagram" ? (
                                props.instagramFeeds.hits &&
                                props.instagramFeeds.hits[index]._source
                                  .sentiment_value &&
                                props.instagramFeeds.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.instagramFeeds.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.instagramFeeds.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Twitter" ? (
                                props.twitter.hits &&
                                props.twitter.hits[index]._source
                                  .sentiment_value &&
                                props.twitter.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.twitter.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.twitter.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Youtube" ? (
                                props.youtubeFeed.hits &&
                                props.youtubeFeed.hits[index]._source
                                  .sentiment_value &&
                                props.youtubeFeed.hits &&
                                props.youtubeFeed.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.youtubeFeed.hits &&
                                  props.youtubeFeed.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.youtubeFeed.hits &&
                                  props.youtubeFeed.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Appstore" ? (
                                props.appStore.hits &&
                                props.appStore.hits[index]._source
                                  .sentiment_value &&
                                props.appStore.hits &&
                                props.appStore.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.appStore.hits &&
                                  props.appStore.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.appStore.hits &&
                                  props.appStore.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "LinkedIn" ? (
                                props.linkedInFeeds.hits &&
                                props.linkedInFeeds.hits[index]._source
                                  .sentiment_value &&
                                props.linkedInFeeds.hits &&
                                props.linkedInFeeds.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.linkedInFeeds.hits &&
                                  props.linkedInFeeds.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.linkedInFeeds.hits &&
                                  props.linkedInFeeds.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Facebook" ? (
                                props.facebook.hits &&
                                props.facebook.hits[index]._source
                                  .sentiment_value &&
                                props.facebook.hits &&
                                props.facebook.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.facebook.hits &&
                                  props.facebook.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.facebook.hits &&
                                  props.facebook.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "GMB" &&
                                item._index === "gmb_review" ? (
                                props.GMBReviewFeeds.hits &&
                                props.GMBReviewFeeds.hits[index]._source
                                  .sentiment_value &&
                                props.GMBReviewFeeds.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.GMBReviewFeeds.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.GMBReviewFeeds.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "GMB" &&
                                item._index === "gmb_questions_answers" ? (
                                props.GMBQuestionFeeds.hits &&
                                props.GMBQuestionFeeds.hits[index]._source
                                  .sentiment_value &&
                                props.GMBQuestionFeeds.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.GMBQuestionFeeds.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.GMBQuestionFeeds.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Indeed" ? (
                                props.indeedFeeds.hits &&
                                props.indeedFeeds.hits[index]._source
                                  .sentiment_value &&
                                props.indeedFeeds.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.indeedFeeds.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.indeedFeeds.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : //                 props.ticketData._source.platform
                              //   .platform_name === "Reddit" ? (
                              // props.redditFeeds.hits &&
                              // props.redditFeeds.hits[index]._source
                              //   .sentiment_value &&
                              // props.redditFeeds.hits[index]._source
                              //   .sentiment_value > 0 ? (
                              //   <SentimentSatisfiedAltRoundedIcon
                              //     titleAccess="Positive"
                              //     style={{
                              //       width: "16px",
                              //       color: `green`,
                              //     }}
                              //   />
                              // ) : props.redditFeeds.hits[index]._source
                              //     .sentiment_value < 0 ? (
                              //   <SentimentDissatisfiedRoundedIcon
                              //     titleAccess="Negative"
                              //     style={{
                              //       width: "16px",
                              //       color: `${theme.palette.sentimentIconColor.negative}`,
                              //     }}
                              //   />
                              // ) : props.redditFeeds.hits[index]._source
                              //     .sentiment_value !== null ? (
                              //   <SentimentNeutralRoundedIcon
                              //     titleAccess="Neutral"
                              //     style={{
                              //       width: "16px",
                              //       color: `${theme.palette.sentimentIconColor.neutral}`,
                              //     }}
                              //   />
                              // ) : (
                              //   ""
                              // )
                              //                 ) :
                              props.ticketData._source.platform
                                  .platform_name === "Consumer Complaints" ? (
                                props.consumer_feed.hits &&
                                props.consumer_feed.hits[index]._source
                                  .sentiment_value &&
                                props.consumer_feed.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.consumer_feed.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.consumer_feed.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Email" ? (
                                props.emailFeeds.hits &&
                                props.emailFeeds.hits[index]._source
                                  .sentiment_value &&
                                props.emailFeeds.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.emailFeeds.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.emailFeeds.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Playstore" ? (
                                props.playstore.hits &&
                                props.playstore.hits[index]._source
                                  .sentiment_value &&
                                props.playstore.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.playstore.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.playstore.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Quora" ? (
                                props.quoraFeed.hits &&
                                props.quoraFeed.hits[index]._source
                                  .sentiment_value &&
                                props.quoraFeed.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.quoraFeed.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.quoraFeed.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : props.ticketData._source.platform
                                  .platform_name === "Trust Pilot" ? (
                                props.trustPilotFeeds.hits &&
                                props.trustPilotFeeds.hits[index]._source
                                  .sentiment_value &&
                                props.trustPilotFeeds.hits[index]._source
                                  .sentiment_value > 0 ? (
                                  <SentimentSatisfiedAltRoundedIcon
                                    titleAccess="Positive"
                                    style={{
                                      width: "16px",
                                      color: `green`,
                                    }}
                                  />
                                ) : props.trustPilotFeeds.hits[index]._source
                                    .sentiment_value < 0 ? (
                                  <SentimentDissatisfiedRoundedIcon
                                    titleAccess="Negative"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.negative}`,
                                    }}
                                  />
                                ) : props.trustPilotFeeds.hits[index]._source
                                    .sentiment_value !== null ? (
                                  <SentimentNeutralRoundedIcon
                                    titleAccess="Neutral"
                                    style={{
                                      width: "16px",
                                      color: `${theme.palette.sentimentIconColor.neutral}`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )
                              ) : null}
                            </SentimentValueUpdatePopper>
                          </Grid>
                          {/* )} */}
                        </Grid>
                      </Grid>

                      {returnTrueNewTicket(
                        props.ticketData._source.platform.platform_name,
                        props.ticketData._source.platform.platform_name ===
                          "Twitter"
                          ? props.twitter &&
                              props.twitter.hits[index] &&
                              props.twitter.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Instagram"
                          ? props.instagramFeeds &&
                              props.instagramFeeds.hits &&
                              props.instagramFeeds.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Facebook"
                          ? props.facebook.hits &&
                              props.facebook.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "GMB"
                          ? item && item._index === "gmb_questions_answers"
                            ? props.GMBQuestionFeeds &&
                              props.GMBQuestionFeeds.hits &&
                              props.GMBQuestionFeeds.hits[index]._source
                            : item._index === "gmb_review"
                            ? props.GMBReviewFeeds &&
                              props.GMBReviewFeeds.hits &&
                              props.GMBReviewFeeds.hits[index]._source
                            : null
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Youtube"
                          ? props.youtubeFeed.hits &&
                              props.youtubeFeed.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Playstore"
                          ? props.playstore.hits &&
                              props.playstore.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Appstore"
                          ? props.appStore.hits &&
                              props.appStore.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Glassdoor"
                          ? props.glassdoorFeeds.hits &&
                              props.glassdoorFeeds.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Email"
                          ? props.emailFeeds.hits &&
                              props.emailFeeds.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Consumer Complaints"
                          ? props.consumer_feed.hits &&
                              props.consumer_feed.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "LinkedIn"
                          ? props.linkedInFeeds.hits &&
                              props.linkedInFeeds.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Indeed"
                          ? props.indeedFeeds.hits &&
                              props.indeedFeeds.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Reddit"
                          ? props.redditFeeds.hits &&
                              props.redditFeeds.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Threads"
                          ? props.twitter &&
                              props.threadFeeds.hits[index] &&
                              props.threadFeeds.hits[index]._source
                          : null,
                        props.ticketData._source.platform.platform_name ===
                          "Ambition Box"
                          ? props.twitter &&
                              props.ambitionBoxFeeds.hits[index] &&
                              props.ambitionBoxFeeds.hits[index]._source
                          : null
                      ) ? (
                        props.ticketData &&
                        props.ticketData._source &&
                        props.ticketData._source.custom_value.length !== 0 &&
                        returntrueCategoryValues(props.ticketData) ? (
                          <Grid
                            item
                            container
                            xl={3}
                            lg={1.5}
                            md={0.5}
                            xs={0.5}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              borderRight: `1px solid ${theme.palette.background.primary}`,
                            }}
                            title={
                              returnCustomValues(
                                props.ticketData._source.custom_value
                              ) &&
                              returnCustomValuesAsString(
                                props.ticketData._source.custom_value
                              )
                            }
                          >
                            <ListItem className="py-0 m-0 px-1">
                              <StyleIcon
                                style={{
                                  width: "16px",
                                  marginRight: 2,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              />
                              <p
                                className="m-0 p-0"
                                data-testid="ticketFeedCategory"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100px",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  props.openCloseModal(
                                    !props.categoryUpdateOpen
                                  );
                                }}
                              >
                                {returnCustomValues(
                                  props.ticketData._source.custom_value
                                ) &&
                                  returnCustomValuesAsString(
                                    props.ticketData._source.custom_value
                                  )}
                              </p>
                            </ListItem>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            container
                            xl={3}
                            lg={1.5}
                            md={0.5}
                            xs={0.5}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              borderRight: `1px solid ${theme.palette.background.primary}`,
                            }}
                          >
                            <ListItem className="py-0 m-0 px-1">
                              <StyleIcon
                                style={{
                                  width: "16px",
                                  marginRight: 2,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              />

                              <p
                                className="m-0 p-0"
                                data-testid="ticketFeedCategory"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100px",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  props.openCloseModal(
                                    !props.categoryUpdateOpen
                                  );
                                }}
                              >
                                Select Category
                              </p>
                            </ListItem>
                          </Grid>
                        )
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="space-between"
                    alignItems={"center"}
                    my={1}
                    px={2}
                    display="flex"
                    // className="bg-warning"
                  >
                    <Grid
                      item
                      container
                      xl={5}
                      lg={7}
                      md={7}
                      sm={7}
                      xs={7}
                      laptop={5}
                      justifyContent="flex-start"
                      alignItems={"center"}
                      display="flex"
                      // className="bg-success"
                    >
                      {allowed_platforms?.includes(
                        props?.ticketData._source.platform.platform_name
                      ) &&
                        !returnVisibleReplyBtn(item) && (
                          <Grid
                            item
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            display={{
                              xl: "block",
                              lg: "block",
                              md: "block",
                              sm: "block",
                              laptop: "block",
                            }}
                          >
                            <Typography fontSize={{ xl: 15, md: 10 }}>
                              <IconButton
                                className="p-0 m-0"
                                title={
                                  item._source.is_deleted
                                    ? `This feed has been Deleted from ${item._source.platform}`
                                    : `User has deleted this '${item?._source?.activities}' from the console.`
                                }
                              >
                                <ReportGmailerrorredIcon
                                  style={{
                                    width: "13px",
                                    // marginRight: 2,
                                    color: `${theme.palette.text.error}`,
                                  }}
                                />
                              </IconButton>
                              {/* <span className="font-weight-bold">
                                Message Will be Disappears after 7 Days
                              </span> */}
                            </Typography>
                          </Grid>
                        )}

                      <Grid
                        item
                        alignItems="center"
                        justifyContent={"center"}
                        textAlign="center"
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                          // border: `1px solid ${theme.palette.background.primary}`,
                          borderRadius: "5px",
                        }}
                        display={{
                          xl: "block",
                          lg: "block",
                          md: "none",
                          sm: "none",
                          laptop: "none",
                        }}

                        // paddingX={1}
                      >
                        <ReturnDeletedStatement
                          componentProps={props}
                          feed_item={item}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xl={7}
                      lg={5}
                      laptop={7}
                      md={5}
                      sm={5}
                      xs={5}
                      justifyContent="flex-end"
                      alignItems={"center"}
                      gap={1}
                      display="flex"
                      // className="bg-info"
                    >
                      {!allowed_platforms?.includes(
                        props?.ticketData._source.platform.platform_name
                      ) &&
                        !returnVisibleReplyBtn(item) && (
                          <Grid
                            item
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                          >
                            <Typography fontSize={{ xl: 15, md: 10 }}>
                              <IconButton
                                className="p-0 m-0"
                                title={
                                  item._source.is_deleted
                                    ? `This feed has been Deleted from ${item._source.platform}`
                                    : "There is no reply action for this activity"
                                }
                              >
                                <ReportGmailerrorredIcon
                                  style={{
                                    width: "13px",
                                    // marginRight: 2,
                                    color: `${theme.palette.text.error}`,
                                  }}
                                />
                              </IconButton>
                              {/* <span className="font-weight-bold">
                                Message Will be Disappears after 7 Days
                              </span> */}
                            </Typography>
                          </Grid>
                        )}

                      {/* Ignore button Container */}
                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) === false && (
                          <Grid
                            item
                            // xl={props.showFeedDetails ? 2.2 : 1.5}
                            // lg={2}
                            // md={2}
                            // sm={2}
                            // xs={2}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              // "&:hover": {
                              //   transform: "scale(1.05)",
                              // },
                              backgroundColor: theme.palette.background.paper,
                              border: `1px solid ${theme.palette.background.primary}`,
                              borderRadius: "5px",
                            }}
                            paddingX={1}

                            // className="bg-warning"
                          >
                            <Tooltip title={isSmallScreen ? "Ignore" : null}>
                              <DirectClosePopper
                                finalStatuses={props.finalStatuses}
                                statusList={props.statusList}
                                ticketID={props.ticketData._id}
                                fetchFunction={() => {
                                  props.fetchFunction();
                                }}
                              >
                                <ListItem
                                  className="p-0 m-0"
                                  sx={{
                                    height: 20,
                                    display: "flex",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <HighlightOffIcon
                                    style={{
                                      width: "13px",
                                      marginRight: 2,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                  <Typography
                                    fontSize={{
                                      xl: "13px",
                                      lg: "12px",
                                      md: "11px",
                                      laptop: "11px",
                                    }}
                                    display={{
                                      xl: "block",
                                      lg: "none",
                                      md: "none",
                                      sm: "none",
                                      laptop: "none",
                                    }}
                                  >
                                    <p className="m-0 p-0">Ignore</p>
                                  </Typography>
                                </ListItem>
                              </DirectClosePopper>
                            </Tooltip>
                          </Grid>
                        )}

                      {/* Reply Button Container */}
                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) === false &&
                        returnVisibleReplyBtn(item, index) && (
                          <Grid
                            item
                            // xl={props.showFeedDetails ? 2.2 : 1.5}
                            // lg={2}
                            // md={2}
                            // sm={2}
                            // xs={2}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              // "&:hover": {
                              //   transform: "scale(1.05)",
                              // },
                              backgroundColor: theme.palette.background.paper,
                              border: `1px solid ${theme.palette.background.primary}`,
                              borderRadius: "5px",
                            }}
                            paddingX={1}
                            data-index={index}
                          >
                            {/* <Tooltip title="Ignore"> */}
                            <ListItem
                              className="p-0 m-0"
                              sx={{
                                height: 20,
                                display: "flex",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setFieldValue(
                                  `feed[${index}].replyOpen`,
                                  !values.feed[index].replyOpen
                                );

                                handleReplyButtonClick(
                                  index,
                                  setFieldValue,
                                  values
                                );
                              }}
                            >
                              <ReplyIcon
                                style={{
                                  width: "13px",
                                  marginRight: 2,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              />
                              <Typography
                                fontSize={{
                                  xl: "13px",
                                  lg: "12px",
                                  md: "11px",
                                  laptop: "11px",
                                }}
                                display={{
                                  xl: "block",
                                  lg: "none",
                                  md: "none",
                                  sm: "none",
                                  laptop: "none",
                                }}
                              >
                                <p className="m-0 p-0">Reply</p>
                              </Typography>
                            </ListItem>
                            {/* </Tooltip> */}
                          </Grid>
                        )}

                      {/* Send Email Button Container */}
                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) === false && (
                          <Grid
                            item
                            // xl={props.showFeedDetails ? 2.2 : 1.5}
                            // lg={2}
                            // md={2}
                            // sm={2}
                            // xs={2}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              // "&:hover": {
                              //   transform: "scale(1.05)",
                              // },
                              backgroundColor: theme.palette.background.paper,
                              border: `1px solid ${theme.palette.background.primary}`,
                              borderRadius: "5px",
                            }}
                            paddingX={1}
                          >
                            <ListItem
                              className="p-0 m-0"
                              sx={{
                                height: 20,
                                display: "flex",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setFieldValue(
                                  `feed[${index}].emailOpen`,
                                  !values.feed[index].emailOpen
                                );
                                handleEmailButtonClick(
                                  index,
                                  setFieldValue,
                                  values
                                );
                              }}
                            >
                              <EmailOutlinedIcon
                                style={{
                                  width: "13px",
                                  marginRight: 2,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              />
                              <Typography
                                fontSize={{
                                  xl: "13px",
                                  lg: "12px",
                                  md: "11px",
                                  laptop: "11px",
                                }}
                                display={{
                                  xl: "block",
                                  lg: "none",
                                  md: "none",
                                  sm: "none",
                                  laptop: "none",
                                }}
                              >
                                <p className="m-0 p-0">Send Email</p>
                              </Typography>
                            </ListItem>
                          </Grid>
                        )}

                      {/* Open Link Button Container */}
                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) &&
                        (props.ticketData._source.platform.platform_name ===
                          "Twitter" ||
                          props.ticketData._source.platform.platform_name ===
                            "Facebook" ||
                          props.ticketData._source.platform.platform_name ===
                            "Instagram" ||
                          props.ticketData._source.platform.platform_name ===
                            "Youtube" ||
                          props.ticketData._source.platform.platform_name ===
                            "LinkedIn" ||
                          props.ticketData._source.platform.platform_name ===
                            "GMB" ||
                          props.ticketData._source.platform.platform_name ===
                            "Appstore" ||
                          props.ticketData._source.platform.platform_name ===
                            "Indeed" ||
                          props.ticketData._source.platform.platform_name ===
                            "Reddit" ||
                          props.ticketData._source.platform.platform_name ===
                            "Consumer Complaints" ||
                          props.ticketData._source.platform.platform_name ===
                            "Quora" ||
                          props.ticketData._source.platform.platform_name ===
                            "Trust Pilot" ||
                          props.ticketData._source.platform.platform_name ===
                            "Pinterest" ||
                          props.ticketData._source.platform.platform_name ===
                            "Playstore") && (
                          <Grid
                            item
                            // xl={props.showFeedDetails ? 2.2 : 1.5}
                            // lg={2}
                            // md={2}
                            // sm={2}
                            // xs={2}
                            // className="bg-warning"
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            // sx={{
                            //   // "&:hover": {
                            //   //   transform: "scale(1.05)",
                            //   // },
                            //   backgroundColor: theme.palette.background.paper,
                            //   border: `1px solid ${theme.palette.background.primary}`,
                            //   borderRadius: "5px",
                            // }}
                            // paddingX={1}
                          >
                            <Typography
                              component={"a"}
                              href={
                                props.ticketData._source.platform
                                  .platform_name === "Twitter"
                                  ? props.twitter.hits[index]._source.feed_link
                                    ? props.twitter.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Facebook"
                                  ? props.facebook.hits[index]._source.feed_link
                                    ? props.facebook.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Instagram"
                                  ? props.instagramFeeds.hits[index]._source
                                      .feed_link
                                    ? props.instagramFeeds.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Youtube"
                                  ? props.youtubeFeed.hits[index]._source
                                      .video_url
                                    ? props.youtubeFeed.hits[index]._source
                                        .video_url
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "LinkedIn"
                                  ? props.linkedInFeeds.hits[index]._source
                                      .feed_link
                                    ? props.linkedInFeeds.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Appstore"
                                  ? props.appStore.hits[index]._source.app_url
                                    ? props.appStore.hits[index]._source.app_url
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "GMB"
                                  ? item._index === "gmb_review"
                                    ? props.GMBReviewFeeds.hits[index]._source
                                        .gmb_profile_link
                                      ? props.GMBReviewFeeds.hits[index]._source
                                          .gmb_profile_link
                                      : ""
                                    : props.GMBQuestionFeeds.hits[index]._source
                                        .gmb_profile_link
                                    ? props.GMBQuestionFeeds.hits[index]._source
                                        .gmb_profile_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Indeed"
                                  ? props.indeedFeeds.hits[index]._source.url
                                  : //               :
                                  //               props.ticketData._source.platform
                                  //     .platform_name === "Reddit"
                                  // ? props.redditFeeds.hits[index]._source.link
                                  // : props.ticketData._source.platform
                                  //     .platform_name === "Consumer Complaints"
                                  // ? props.consumer_feed.hits[index]._source.link
                                  props.ticketData._source.platform
                                      .platform_name === "Reddit" &&
                                    props.ticketData._source.activities
                                      .activities_name === "Posts"
                                  ? props.redditPosts.hits[index]._source.link
                                  : props.ticketData._source.platform
                                      .platform_name === "Reddit" &&
                                    props.ticketData._source.activities
                                      .activities_name === "Comments"
                                  ? props.redditComments.hits[index]._source
                                      .link
                                  : props.ticketData._source.platform
                                      .platform_name === "Quora"
                                  ? props.quoraFeed.hits[index]._source
                                      .question_url
                                  : props.ticketData._source.platform
                                      .platform_name === "Trust Pilot"
                                  ? props.trustPilotFeeds.hits[index]._source
                                      .feed_link
                                  : props.ticketData._source.platform
                                      .platform_name === "Pinterest"
                                  ? props.pinterestFeeds.hits[index]._source
                                      .feed_link
                                  : props.ticketData._source.platform
                                      .platform_name === "Playstore"
                                  ? props.playstore.hits[index]._source
                                      .feed_link
                                  : ""
                              }
                              target="_blank"
                              title="Feed Link"
                            >
                              <IconButton className="p-0 m-0">
                                <OpenInNewIcon
                                  style={{
                                    width: "13px",
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                              </IconButton>
                            </Typography>
                          </Grid>
                        )}

                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) === false &&
                        (props.ticketData._source.platform.platform_name ===
                          "Twitter" ||
                          props.ticketData._source.platform.platform_name ===
                            "Facebook" ||
                          props.ticketData._source.platform.platform_name ===
                            "Instagram" ||
                          props.ticketData._source.platform.platform_name ===
                            "Youtube" ||
                          props.ticketData._source.platform.platform_name ===
                            "LinkedIn" ||
                          props.ticketData._source.platform.platform_name ===
                            "GMB" ||
                          props.ticketData._source.platform.platform_name ===
                            "Appstore" ||
                          props.ticketData._source.platform.platform_name ===
                            "Indeed" ||
                          props.ticketData._source.platform.platform_name ===
                            "Reddit" ||
                          // props.ticketData._source.platform.platform_name ===
                          //   "Consumer Complaints" ||
                          props.ticketData._source.platform.platform_name ===
                            "Quora" ||
                          props.ticketData._source.platform.platform_name ===
                            "Trust Pilot" ||
                          props.ticketData._source.platform.platform_name ===
                            "Pinterest" ||
                          props.ticketData._source.platform.platform_name ===
                            "Playstore" ||
                          props.ticketData._source.platform.platform_name ===
                            "Threads") && (
                          <Grid
                            item
                            // xl={props.showFeedDetails ? 2.2 : 1.5}
                            // lg={2}
                            // md={2}
                            // sm={2}
                            // xs={2}
                            // className="bg-warning"
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            sx={{
                              // "&:hover": {
                              //   transform: "scale(1.05)",
                              // },
                              backgroundColor: theme.palette.background.paper,
                              border: `1px solid ${theme.palette.background.primary}`,
                              borderRadius: "5px",
                            }}
                            paddingX={1}
                          >
                            <Typography
                              component={"a"}
                              href={
                                props.ticketData._source.platform
                                  .platform_name === "Twitter"
                                  ? props.twitter.hits[index]._source.feed_link
                                    ? props.twitter.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Facebook"
                                  ? props.facebook.hits[index]._source.feed_link
                                    ? props.facebook.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Instagram"
                                  ? props.instagramFeeds.hits[index]._source
                                      .feed_link
                                    ? props.instagramFeeds.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Youtube"
                                  ? props.youtubeFeed.hits[index]._source
                                      .video_url
                                    ? props.youtubeFeed.hits[index]._source
                                        .video_url
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "LinkedIn"
                                  ? props.linkedInFeeds.hits[index]._source
                                      .feed_link
                                    ? props.linkedInFeeds.hits[index]._source
                                        .feed_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Appstore"
                                  ? props.appStore.hits[index]._source.app_url
                                    ? props.appStore.hits[index]._source.app_url
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "GMB"
                                  ? item._index === "gmb_review"
                                    ? props.GMBReviewFeeds.hits[index]._source
                                        .gmb_profile_link
                                      ? props.GMBReviewFeeds.hits[index]._source
                                          .gmb_profile_link
                                      : ""
                                    : props.GMBQuestionFeeds.hits[index]._source
                                        .gmb_profile_link
                                    ? props.GMBQuestionFeeds.hits[index]._source
                                        .gmb_profile_link
                                    : ""
                                  : props.ticketData._source.platform
                                      .platform_name === "Indeed"
                                  ? props.indeedFeeds.hits[index]._source.url
                                  : //               props.ticketData._source.platform
                                  //     .platform_name === "Reddit"
                                  // ? props.redditFeeds.hits[index]._source.link
                                  //                 :
                                  props.ticketData._source.platform
                                      .platform_name === "Reddit" &&
                                    props.ticketData._source.activities
                                      .activities_name === "Posts"
                                  ? props.redditPosts.hits[index]._source.link
                                  : props.ticketData._source.platform
                                      .platform_name === "Reddit" &&
                                    props.ticketData._source.activities
                                      .activities_name === "Comments"
                                  ? props.redditComments.hits[index]._source
                                      .link
                                  : props.ticketData._source.platform
                                      .platform_name === "Consumer Complaints"
                                  ? props.consumer_feed.hits[index]._source.link
                                  : props.ticketData._source.platform
                                      .platform_name === "Quora"
                                  ? props.quoraFeed.hits[index]._source
                                      .question_url
                                  : props.ticketData._source.platform
                                      .platform_name === "Trust Pilot"
                                  ? props.trustPilotFeeds.hits[index]._source
                                      .feed_link
                                  : props.ticketData._source.platform
                                      .platform_name === "Pinterest"
                                  ? props.pinterestFeeds.hits[index]._source
                                      .feed_link
                                  : props.ticketData._source.platform
                                      .platform_name === "Playstore"
                                  ? props.playstore.hits[index]._source
                                      .feed_link
                                  : props.ticketData._source.platform
                                      .platform_name === "Threads"
                                  ? props.threadFeeds.hits[index]._source
                                      .feed_link
                                  : ""
                              }
                              target="_blank"
                              // title="Feed Link"
                            >
                              <ListItem
                                className="p-0 m-0"
                                sx={{
                                  height: 20,
                                  display: "flex",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <OpenInNewIcon
                                  style={{
                                    width: "13px",
                                    marginRight: 2,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                                <Typography
                                  fontSize={{
                                    xl: "13px",
                                    lg: "12px",
                                    md: "11px",
                                    laptop: "11px",
                                  }}
                                  display={{
                                    xl: "block",
                                    lg: "none",
                                    md: "none",
                                    sm: "none",
                                    laptop: "none",
                                  }}
                                >
                                  <p className="m-0 p-0">Open Link</p>
                                </Typography>
                              </ListItem>
                            </Typography>
                          </Grid>
                        )}

                      {/* working */}

                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) === false && (
                          <Grid
                            item
                            // xl={1}
                            // lg={1}
                            // md={1}
                            // sm={1}
                            // xs={1}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            // className="bg-success"
                          >
                            <ThreeDotsPopper
                              comment_id={
                                item && item._source && item._source.comment_id
                              }
                              is_hidden={
                                props.ticketData._source.platform
                                  .platform_name === "Instagram"
                                  ? item._source &&
                                    item._source.comment_is_hidden &&
                                    item._source.comment_is_hidden
                                  : props.ticketData._source.platform
                                      .platform_name === "Facebook"
                                  ? item &&
                                    item._source &&
                                    item._source.is_hidden
                                  : false
                              }
                              is_deleted={
                                props.ticketData._source.platform
                                  .platform_name === "Instagram"
                                  ? item._source &&
                                    item._source.is_deleted &&
                                    item._source.is_deleted
                                  : props.ticketData._source.platform
                                      .platform_name === "Facebook"
                                  ? item._source &&
                                    item._source.is_deleted &&
                                    item._source.is_deleted
                                  : false
                              }
                              ticketData={props.ticketData}
                              feedData={
                                props.ticketData._source.platform
                                  .platform_name === "Instagram"
                                  ? props.instagramFeeds.hits[index]
                                  : null
                              }
                              feed={item}
                              retweeted={
                                item && item._source && item._source.retweeted
                              }
                              tweet_id={
                                item && item._source && item._source.tweet_id
                              }
                              setTranslate={() =>
                                setFieldValue(
                                  `feed[${index}].translateClicked`,
                                  !values.feed[index].translateClicked
                                )
                              }
                              translated={
                                values.feed[index] &&
                                values.feed[index].translateClicked
                                  ? values.feed[index].translateClicked
                                  : false
                              }
                              openAIsuggestionTab={openAIsuggestionTab}
                              setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                              aiSuggestionTab={aiSuggestionTab}
                              setAiSuggestionTab={setAiSuggestionTab}
                            >
                              <ListItem
                                className="m-0 p-0"
                                sx={{
                                  height: 15,
                                  backgroundColor:
                                    theme.palette.background.paper,
                                  // padding: 1,
                                  cursor: "pointer",
                                  // border: `0.5px solid #d3e2e6`,
                                  marginY: 1,
                                  // "&:hover": {
                                  //   transform: "scale(1.05)",
                                  // },
                                }}
                              >
                                <MoreVertIcon
                                  style={{
                                    width: "16px",
                                    color: `${theme.palette.text.primary}`,
                                  }}
                                />
                              </ListItem>
                            </ThreeDotsPopper>
                          </Grid>
                        )}

                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) && (
                          <Grid
                            item
                            // xl={1}
                            // lg={1}
                            // md={1}
                            // sm={1}
                            // xs={1}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                            // className="bg-success"
                          >
                            <ListItem
                              className="m-0 p-0"
                              sx={{
                                height: 15,
                                backgroundColor: theme.palette.background.paper,
                                // padding: 1,
                                cursor: "pointer",
                                // border: `0.5px solid #d3e2e6`,
                                marginY: 1,
                                // "&:hover": {
                                //   transform: "scale(1.05)",
                                // },
                              }}
                              onClick={() => {
                                setFieldValue(
                                  `feed[${index}].emailOpen`,
                                  !values.feed[index].emailOpen
                                );
                                handleEmailButtonClick(
                                  index,
                                  setFieldValue,
                                  values
                                );
                              }}
                            >
                              <IconButton className="p-0 m-0" title="Send Mail">
                                <EmailOutlinedIcon
                                  style={{
                                    width: "13px",
                                    marginRight: 2,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                              </IconButton>
                            </ListItem>
                          </Grid>
                        )}

                      {props.finalStatuses &&
                        props.finalStatuses.length > 0 &&
                        props.finalStatuses.includes(
                          props.ticketData &&
                            props.ticketData._source &&
                            props.ticketData._source.status.status_id
                        ) && (
                          <Grid
                            item
                            // xl={1}
                            // lg={1}
                            // md={1}
                            // sm={1}
                            // xs={1}
                            alignItems="center"
                            justifyContent={"center"}
                            textAlign="center"
                          >
                            <ListItem
                              className="m-0 p-0"
                              sx={{
                                height: 15,
                                backgroundColor: theme.palette.background.paper,
                                // padding: 1,
                                cursor: "pointer",
                                // border: `0.5px solid #d3e2e6`,
                                marginY: 1,
                                // "&:hover": {
                                //   transform: "scale(1.05)",
                                // },
                              }}
                              onClick={() => {
                                setFieldValue(
                                  `feed[${index}].translateClicked`,
                                  !values.feed[index].translateClicked
                                );
                              }}
                            >
                              <IconButton className="p-0 m-0" title="Translate">
                                <GTranslateIcon
                                  style={{
                                    width: "13px",
                                    marginRight: 2,
                                    color: values.feed[index].translateClicked
                                      ? theme.palette.primary.main
                                      : `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                />
                              </IconButton>
                            </ListItem>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </Box>

                {values.feed[index] && values.feed[index].replyOpen && (
                  <Box data-reply-open={values.feed[index].replyOpen}>
                    <ReplyComponent
                      directMessageDate={returnDirectMessageDate()}
                      index={index}
                      fetchFunction={props.fetchFunction}
                      setFieldValue={setFieldValue}
                      values={values}
                      setReplyClicked={props.setReplyClicked}
                      showBoxReplyBox={values.feed[index].replyOpen}
                      setShowReplyBox={setShowReplyBox}
                      setShowEmailBox={setShowEmailBox}
                      ticketData={props.ticketData}
                      filters={props.filters}
                      pageNumber={props.pageNumber}
                      page_limit={props.page_limit}
                      page_length={props.page_length}
                      ticket_count_filters={props.ticket_count_filters}
                      statusList={props.statusList ? props.statusList : []}
                      feedData={
                        props.ticketData._source.platform.platform_name ===
                        "Playstore"
                          ? props.playstore.hits[index] &&
                            props.playstore.hits[index]._source
                            ? props.playstore.hits[index]._source
                            : null
                          : props.ticketData._source.platform.platform_name ===
                            "Instagram"
                          ? props.instagramFeeds.hits[index] &&
                            props.instagramFeeds.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "Email"
                          ? props.emailFeeds.hits[index] &&
                            props.emailFeeds.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "Appstore"
                          ? props.appStore.hits[index] &&
                            props.appStore.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "Facebook"
                          ? props.facebook.hits[index] &&
                            props.facebook.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "Youtube"
                          ? props.youtubeFeed.hits[index] &&
                            props.youtubeFeed.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "Twitter"
                          ? props.twitter.hits[index] &&
                            props.twitter.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "LinkedIn"
                          ? props.linkedInFeeds.hits[index] &&
                            props.linkedInFeeds.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                              "GMB" && item._index === "gmb_review"
                          ? props.GMBReviewFeeds.hits[index] &&
                            props.GMBReviewFeeds.hits[index]
                          : item._index === "gmb_questions_answers"
                          ? props.GMBQuestionFeeds.hits[index] &&
                            props.GMBQuestionFeeds.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "Indeed"
                          ? props.indeedFeeds[index] && props.indeedFeeds[index]
                          : //                   props.ticketData._source.platform.platform_name ===
                          //   "Reddit"
                          // ? props.redditFeeds.hits[index]
                          //                     :
                          props.ticketData._source.platform.platform_name ===
                            "Consumer Complaints"
                          ? props.consumer_feed.hits[index]
                          : props.ticketData._source.platform.platform_name ===
                            "Threads"
                          ? props.threadFeeds.hits[index]
                          : null
                      }
                    />
                  </Box>
                )}

                {values.feed[index] && values.feed[index].emailOpen && (
                  <EmailComponent
                    index={index}
                    setFieldValue={setFieldValue}
                    values={values}
                    showEmailBox={values.feed[index].emailOpen}
                    setEmailClicked={props.setEmailClicked}
                    setShowReplyBox={setShowReplyBox}
                    setShowEmailBox={setShowEmailBox}
                    ticketData={props.ticketData}
                    feedData={
                      props.emailFeeds.hits &&
                      props.emailFeeds.hits[index] &&
                      props.emailFeeds.hits[index]._source
                        ? props.emailFeeds.hits[index]._source
                        : null
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          {/* Replies container for different platforms  */}
          <Grid>
            {/* FACEBOOK REPLIES */}

            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Facebook" &&
              props.facebook.hits &&
              props.facebook.hits.length !== 0 &&
              props.facebook.hits[index]._source &&
              props.facebook.hits[index]._source.replies &&
              props.facebook.hits[index]._source.replies.length !== 0 &&
              props.facebook.hits[index]._source.replies.map((value) => (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  ticketData={props.ticketData}
                  feedData={props.facebook.hits[index]}
                  reply={value}
                  openCloseModal={props.openCloseModal}
                  category_custom_value={props.category_custom_value}
                />
              ))}

            {/* INSTAGRAM REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Instagram" &&
              props.instagramFeeds.hits &&
              props.instagramFeeds.hits.length !== 0 &&
              props.instagramFeeds.hits[index]._source &&
              props.instagramFeeds.hits[index]._source.replies_for_comment &&
              props.instagramFeeds.hits[index]._source.replies_for_comment
                .length !== 0 &&
              props.instagramFeeds.hits[index]._source.replies_for_comment.map(
                (value) => (
                  <TicketFeedRepliesContainer
                    showFeedDetails={props.showFeedDetails}
                    ticketData={props.ticketData}
                    feedData={props.instagramFeeds.hits[index]}
                    reply={value}
                    openCloseModal={props.openCloseModal}
                    category_custom_value={props.category_custom_value}
                  />
                )
              )}

            {/* THREADS REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Threads" &&
              props.threadFeeds.hits &&
              props.threadFeeds.hits.length !== 0 &&
              props.threadFeeds.hits[index]._source &&
              props.threadFeeds.hits[index]._source.agent_replies &&
              props.threadFeeds.hits[index]._source.agent_replies.length !==
                0 &&
              props.threadFeeds.hits[index]._source.agent_replies.map(
                (value) => (
                  <TicketFeedRepliesContainer
                    showFeedDetails={props.showFeedDetails}
                    ticketData={props.ticketData}
                    feedData={props.threadFeeds.hits[index]}
                    reply={value}
                    openCloseModal={props.openCloseModal}
                    category_custom_value={props.category_custom_value}
                  />
                )
              )}

            {/* Reddit Comments REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Reddit" &&
              props.ticketData._source.activities.activities_name ===
                "Comments" &&
              props.redditComments.hits &&
              props.redditComments.hits.length !== 0 &&
              props.redditComments.hits[index]._source &&
              props.redditComments.hits[index]._source.replies &&
              props.redditComments.hits[index]._source.replies.length !== 0 &&
              props.redditComments.hits[index]._source.replies.map((value) => (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  ticketData={props.ticketData}
                  feedData={props.redditComments.hits[index]}
                  reply={value}
                  openCloseModal={props.openCloseModal}
                  category_custom_value={props.category_custom_value}
                />
              ))}

            {/* APPSTORE REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Appstore" &&
              props.appStore.hits &&
              props.appStore.hits.length !== 0 &&
              props.appStore.hits[index]._source &&
              props.appStore.hits[index]._source.reply_content && (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  category_custom_value={props.category_custom_value}
                  openCloseModal={props.openCloseModal}
                  ticketData={props.ticketData}
                  feedData={props.appStore.hits[index]}
                  reply={
                    props.appStore.hits[index] &&
                    props.appStore.hits[index]._source
                  }
                />
              )}

            {/* YOUTUBE REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Youtube" &&
              props.youtubeFeed.hits &&
              props.youtubeFeed.hits.length !== 0 &&
              props.youtubeFeed.hits[index]._source &&
              props.youtubeFeed.hits[index]._source.replies &&
              props.youtubeFeed.hits[index]._source.replies.length !== 0 &&
              props.youtubeFeed.hits[index]._source.replies.map((value) => (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  ticketData={props.ticketData}
                  feedData={props.youtubeFeed.hits[index]}
                  reply={value}
                  openCloseModal={props.openCloseModal}
                  category_custom_value={props.category_custom_value}
                />
              ))}

            {/* GMB REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "GMB" &&
              item._index === "gmb_review" &&
              props.GMBReviewFeeds.hits &&
              props.GMBReviewFeeds.hits.length !== 0 &&
              props.GMBReviewFeeds.hits[index]._source &&
              props.GMBReviewFeeds.hits[index]._source.review_replay && (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  ticketData={props.ticketData}
                  openCloseModal={props.openCloseModal}
                  feedData={props.GMBReviewFeeds.hits[index]}
                  category_custom_value={props.category_custom_value}
                  reply={{
                    reply_time:
                      props.GMBReviewFeeds.hits[index]._source.review_replay_on,
                    reply_content:
                      props.GMBReviewFeeds.hits[index]._source.review_replay,
                  }}
                />
              )}

            {/* GMB ANSWER REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "GMB" &&
              item._index === "gmb_questions_answers" &&
              props.GMBQuestionFeeds.hits &&
              props.GMBQuestionFeeds.hits.length !== 0 &&
              props.GMBQuestionFeeds.hits[index]._source &&
              props.GMBQuestionFeeds.hits[index]._source.answers?.map(
                (answer) => {
                  return (
                    <TicketFeedRepliesContainer
                      showFeedDetails={props.showFeedDetails}
                      ticketData={props.ticketData}
                      openCloseModal={props.openCloseModal}
                      feedData={props.GMBQuestionFeeds.hits[index]}
                      category_custom_value={props.category_custom_value}
                      reply={answer}
                    />
                  );
                }
              )}

            {/* PLAYSTORE REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Playstore" &&
              props.playstore.hits &&
              props.playstore.hits.length !== 0 &&
              props.playstore.hits[index]._source &&
              props.playstore.hits[index]._source.reply_content && (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  category_custom_value={props.category_custom_value}
                  openCloseModal={props.openCloseModal}
                  ticketData={props.ticketData}
                  feedData={props.playstore.hits[index]}
                  reply={
                    props.playstore.hits[index] &&
                    props.playstore.hits[index]._source
                  }
                />
              )}

            {/* TWITTER REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Twitter" &&
              props.twitter.hits &&
              props.twitter.hits.length !== 0 &&
              props.twitter.hits[index]._source &&
              props.twitter.hits[index]._source.agent_replies &&
              props.twitter.hits[index]._source.agent_replies.map((value) => (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  openCloseModal={props.openCloseModal}
                  ticketData={props.ticketData}
                  feedData={props.twitter.hits[index]}
                  reply={value}
                  category_custom_value={props.category_custom_value}
                />
              ))}

            {/* LINKEDIN REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "LinkedIn" &&
              props.linkedInFeeds.hits &&
              props.linkedInFeeds.hits.length !== 0 &&
              props.linkedInFeeds.hits[index]._source &&
              props.linkedInFeeds.hits[index]._source.replies &&
              props.linkedInFeeds.hits[index]._source.replies.length !== 0 &&
              props.linkedInFeeds.hits[index]._source.replies.map((value) => (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  openCloseModal={props.openCloseModal}
                  ticketData={props.ticketData}
                  feedData={props.linkedInFeeds.hits[index]}
                  reply={value}
                  category_custom_value={props.category_custom_value}
                />
              ))}

            {/* EMAIL REPLIES */}
            {!isRequesting &&
              props.ticketData._source.platform.platform_name === "Email" &&
              props.emailFeeds.hits &&
              props.emailFeeds.hits.length !== 0 &&
              props.emailFeeds.hits[index]._source &&
              props.emailFeeds.hits[index]._source.replies &&
              props.emailFeeds.hits[index]._source.replies.length !== 0 &&
              props.emailFeeds.hits[index]._source.replies.map((value) => (
                <TicketFeedRepliesContainer
                  showFeedDetails={props.showFeedDetails}
                  openCloseModal={props.openCloseModal}
                  ticketData={props.ticketData}
                  feedData={props.emailFeeds.hits[index]}
                  reply={value}
                  category_custom_value={props.category_custom_value}
                />
              ))}
          </Grid>
        </Box>
        {/* {item && item._source && item._source.is_deleted && (
          <Grid item container xl={12}>
            <Box
              width={"100%"}
              sx={{
                paddingX: 2,
                paddingY: 0,
              }}
              // className="bg-warning"
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                alignItems="center"
                justifyContent={"flex-end"}
                // className="bg-success"
              >
                <Grid
                  item
                  textAlign={"right"}
                  // xl={10}
                  // lg={10}
                  // md={10}
                  // sm={10}
                  // className="bg-info"
                  sx={{
                    backgroundColor: "white",
                    border: "solid 0.5px #92b9c4",
                    fontFamily: "BookAntiqua-Italic",
                  }}
                  px={2}
                >
                  <span
                    style={{
                      color: `${theme.palette.primary.main}`,
                    }}
                  >
                    <span
                      style={{
                        opacity: 0.8,
                      }}
                    >{`Above Comment with ID `}</span>
                    <i
                      style={{
                        opacity: 1,
                      }}
                    >
                      {item._id}
                    </i>
                    &nbsp;
                    <span
                      style={{
                        opacity: 0.8,
                      }}
                    >{`has been deleted from `}</span>
                    &nbsp;
                    <i>
                      {props.ticketData &&
                        props.ticketData._source &&
                        props.ticketData._source.platform &&
                        props.ticketData._source.platform.platform_name}
                    </i>
                    &nbsp;
                    <span
                      style={{
                        opacity: 0.8,
                      }}
                    >{`By`}</span>
                    &nbsp;
                    <i>
                      {props.comment_deleted &&
                        props.comment_deleted[0] &&
                        props.comment_deleted[0]._source.description.split(
                          "By"
                        )[1]}
                    </i>
                  </span>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )} */}

        {DividerId && DividerId !== item._source.ticket_id ? (
          <>
            <Divider textAlign="right">{returnLogStatement(DividerId)}</Divider>
          </>
        ) : null}
        {item._source.ticket_id ? setDividerId(item._source.ticket_id) : null}
      </Box>
    );
  }

  const boxRef = useRef(null);
  const [scrollToIndex, setScrollToIndex] = useState(null);

  useEffect(() => {
    if (props.replyClicked === true || props.emailClicked === true) {
      if (
        props &&
        props.ticketData._source &&
        props.ticketData._source.activities &&
        props.ticketData._source.activities.activities_name === "Direct Message"
      ) {
        boxRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      if (props?.ticketData?._source?.platform?.platform_name === "Whatsapp") {
        boxRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    } else {
      if (boxRef.current && scrollToIndex !== null) {
        const container = boxRef.current.querySelector(
          `[data-index="${scrollToIndex}"]`
        );
        if (container) {
          container.scrollIntoView({ behavior: "smooth" });
          setScrollToIndex(null); // Reset scrollToIndex after scrolling
        }
      }
    }
    if (props.replyClicked === false && props.emailClicked === false) {
      boxRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [isRequesting, scrollToIndex]);

  return (
    <Box
      width={"100%"}
      sx={{
        paddingY: 0,
        paddingLeft: 1,
        height: "100%",
        position: "relative",
      }}
    >
      <LoadingIndicator isActive={isRequesting}>
        {isRequesting && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              // display: "flex",
              justifyContent: "center",
              display: isRequesting ? "block" : "none",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <LoadingBgImage />
            </Grid>
          </div>
        )}
        <Box
          sx={{ height: "100%", overflowY: "auto" }}
          ref={boxRef}
          className="scrollable"
        >
          {!isRequesting &&
            props.ticketData._source &&
            props.ticketData._source.platform && (
              <>
                {props.ticketData._source.platform.platform_name ===
                  "Facebook" &&
                  props.facebook.hits &&
                  props.facebook.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.facebook.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.comment_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.comment_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.facebook.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Twitter" &&
                  props.twitter &&
                  props.twitter.hits &&
                  props.twitter.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.twitter.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.content_of_the_tweet ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.content_of_the_tweet ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.twitter.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Threads" &&
                  props.threadFeeds &&
                  props.threadFeeds.hits &&
                  props.threadFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.threadFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.thread_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.thread_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.threadFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Instagram" &&
                  props.instagramFeeds.hits &&
                  props.instagramFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.instagramFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.instagramFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name === "GMB" && (
                  <>
                    {props.GMBQuestionFeeds.hits &&
                      props.GMBQuestionFeeds.hits.length > 0 && (
                        <Formik
                          initialValues={{
                            feed: props.GMBQuestionFeeds.hits.map((item) => {
                              return {
                                replyOpen:
                                  props.replyClicked &&
                                  item._source.question ===
                                    props.ticketData._source.ticket_desc
                                    ? true
                                    : false,
                                emailOpen:
                                  props.emailClicked &&
                                  item._source.question ===
                                    props.ticketData._source.ticket_desc
                                    ? true
                                    : false,
                                translateClicked: false,
                              };
                            }),
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              {props.GMBQuestionFeeds.hits.map((item, index) =>
                                ReturnFeeds(
                                  index,
                                  setFieldValue,
                                  values,
                                  item,
                                  setScrollToIndex
                                )
                              )}
                            </Form>
                          )}
                        </Formik>
                      )}
                    {props.GMBReviewFeeds.hits &&
                      props.GMBReviewFeeds.hits.length > 0 && (
                        <Formik
                          initialValues={{
                            feed: props.GMBReviewFeeds.hits.map((item) => {
                              return {
                                replyOpen:
                                  props.replyClicked &&
                                  item._source.review_comment ===
                                    props.ticketData._source.ticket_desc.split(
                                      "/ratings/"
                                    )[0]
                                    ? true
                                    : false,
                                emailOpen:
                                  props.emailClicked &&
                                  item._source.review_comment ===
                                    props.ticketData._source.ticket_desc.split(
                                      "/ratings/"
                                    )[0]
                                    ? true
                                    : false,
                                translateClicked: false,
                              };
                            }),
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              {props.GMBReviewFeeds.hits.map((item, index) =>
                                ReturnFeeds(
                                  index,
                                  setFieldValue,
                                  values,
                                  item,
                                  setScrollToIndex
                                )
                              )}
                            </Form>
                          )}
                        </Formik>
                      )}
                  </>
                )}
                {props.ticketData._source.platform.platform_name ===
                  "LinkedIn" &&
                  props.linkedInFeeds.hits &&
                  props.linkedInFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.linkedInFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.feed_id ===
                                props.ticketData._source.ticket_name
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.linkedInFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Youtube" &&
                  props.youtubeFeed.hits &&
                  props.youtubeFeed.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.youtubeFeed.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              (item._source.video_title ===
                                props.ticketData._source.ticket_desc ||
                                item._source.comment_content ===
                                  props.ticketData._source.ticket_desc)
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              (item._source.video_title ===
                                props.ticketData._source.ticket_desc ||
                                item._source.comment_content ===
                                  props.ticketData._source.ticket_desc)
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.youtubeFeed.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Playstore" &&
                  props.playstore.hits &&
                  props.playstore.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.playstore.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.review_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.review_content ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.playstore.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Appstore" &&
                  props.appStore.hits &&
                  props.appStore.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.appStore.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.review_heading ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.review_heading ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.appStore.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Ambition Box" &&
                  props.ambitionBoxFeeds.hits &&
                  props.ambitionBoxFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: Array(props.ambitionBoxFeeds.hits.length).fill({
                          replyOpen: false,
                          emailOpen: false,
                          translateClicked: false,
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.ambitionBoxFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name ===
                  "Glassdoor" &&
                  props.glassdoorFeeds.hits &&
                  props.glassdoorFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.glassdoorFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.reviews ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.reviews ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.glassdoorFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name === "Email" &&
                  props.emailFeeds.hits &&
                  props.emailFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.emailFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.subject ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.subject ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.emailFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                {props.ticketData._source.platform.platform_name === "Quora" &&
                  props.quoraFeed.hits &&
                  props.quoraFeed.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.quoraFeed.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.answer ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.answer ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.quoraFeed.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name === "Indeed" &&
                  props.indeedFeeds.hits &&
                  props.indeedFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.indeedFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc.split(
                                  "/ratings/"
                                )[0]
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.indeedFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Trust Pilot" &&
                  props.trustPilotFeeds.hits &&
                  props.trustPilotFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.trustPilotFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.trustPilotFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Pinterest" &&
                  props.pinterestFeeds.hits &&
                  props.pinterestFeeds.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.pinterestFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.rating_description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? // .split(
                                  //   "/ratings/"
                                  // )[0]
                                  true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.pinterestFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {/* {props.ticketData._source.platform.platform_name === "Reddit" &&
                  props.redditPosts.hits &&
                  props.redditPosts.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.redditFeeds.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.redditFeeds.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )} */}

                {props.ticketData._source.platform.platform_name === "Reddit" &&
                  props.ticketData._source.activities.activities_name ===
                    "Posts" &&
                  props.redditPosts.hits &&
                  props.redditPosts.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.redditPosts.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.redditPosts.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name === "Reddit" &&
                  props.ticketData._source.activities.activities_name ===
                    "Comments" &&
                  props.redditComments.hits &&
                  props.redditComments.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.redditComments.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.redditComments.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_name ===
                  "Consumer Complaints" &&
                  props.consumer_feed.hits &&
                  props.consumer_feed.hits.length > 0 && (
                    <Formik
                      initialValues={{
                        feed: props.consumer_feed.hits.map((item) => {
                          return {
                            replyOpen:
                              props.replyClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            emailOpen:
                              props.emailClicked &&
                              item._source.description ===
                                props.ticketData._source.ticket_desc
                                ? true
                                : false,
                            translateClicked: false,
                          };
                        }),
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          {props.consumer_feed.hits.map((item, index) =>
                            ReturnFeeds(
                              index,
                              setFieldValue,
                              values,
                              item,
                              setScrollToIndex
                            )
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}

                {props.ticketData._source.platform.platform_id === "14" && (
                  <Box
                    sx={{
                      paddingX: 2,
                      paddingTop: 2,
                      paddingBottom: 3,
                    }}
                  >
                    <Details
                      ticketData={props.ticketData && props.ticketData}
                      filters={props.filters}
                      fetchFunction={props.fetchFunction}
                    />
                  </Box>
                )}
              </>
            )}

          {!isRequesting &&
            props.ticketData._source &&
            props.ticketData._source.platform &&
            ((props.ticketData._source &&
              props.ticketData._source.platform &&
              props.ticketData._source.platform.platform_name === "Telegram" &&
              props.telegramFeeds.hits &&
              props.telegramFeeds.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name ===
                  "Facebook" &&
                props.facebook_dm.hits &&
                props.facebook_dm.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name ===
                  "Instagram" &&
                props.instagramDirectMessage.hits &&
                props.instagramDirectMessage.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name ===
                  "Whatsapp" &&
                props.whatsAppFeed.hits &&
                props.whatsAppFeed.hits.length) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name === "Twitter" &&
                props.twitterDirectMeassage.hits &&
                props.twitterDirectMeassage.hits.length > 0) ||
              (props.ticketData._source &&
                props.ticketData._source.platform.platform_name &&
                props.ticketData._source.platform.platform_name === "Discord" &&
                props.discordFeeds.hits &&
                props.discordFeeds.hits.length)) && (
              <Formik
                initialValues={initial_val_for_TicketPlatformMessagesContainer}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <TicketPlatformMessagesContainer
                      // index={index}
                      mass_ticket_history={props.mass_ticket_history}
                      finalStatuses={props.finalStatuses}
                      category_custom_value={props.category_custom_value}
                      openCloseModal={props.openCloseModal}
                      tagopenCloseModal={props.tagopenCloseModal}
                      setFieldValue={setFieldValue}
                      values={values}
                      replyClicked={props.replyClicked}
                      // ref={containerRef}
                      filters={props.filters}
                      setScrollToIndex={setScrollToIndex}
                      // item={item}
                      ticketData={props.ticketData}
                      fetchFunction={props.fetchFunction}
                      statusList={props.statusList}
                      customerData={props.customerData}
                      showFeedDetails={props.showFeedDetails}
                      ticket_count_filters={props.ticket_count_filters}
                      pageNumber={props.pageNumber}
                      page_limit={props.page_limit}
                      page_length={props.page_length}
                      messages={
                        props.ticketData._source.platform.platform_name ===
                        "Telegram"
                          ? props.telegramFeeds.hits
                          : props.ticketData._source.platform.platform_name ===
                            "Facebook"
                          ? props.facebook_dm.hits &&
                            props.facebook_dm.hits.length &&
                            props.facebook_dm.hits
                          : props.ticketData._source.platform.platform_name ===
                            "Instagram"
                          ? props.instagramDirectMessage.hits
                          : props.ticketData._source.platform.platform_name ===
                              "Twitter" &&
                            props.twitterDirectMeassage.hits &&
                            props.twitterDirectMeassage.hits.length
                          ? props.twitterDirectMeassage.hits
                          : props.ticketData._source.platform.platform_name ===
                              "Whatsapp" &&
                            props.whatsAppFeed.hits &&
                            props.whatsAppFeed.hits.length
                          ? props.whatsAppFeed.hits
                          : props.ticketData._source.platform.platform_name ===
                            "Discord"
                          ? props.discordFeeds.hits &&
                            props.discordFeeds.hits.length &&
                            props.discordFeeds.hits
                          : []
                      }
                      timeAgo={timeAgo}
                      openAIsuggestionTab={openAIsuggestionTab}
                      setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                      aiSuggestionTab={aiSuggestionTab}
                      setAiSuggestionTab={setAiSuggestionTab}
                    />
                  </Form>
                )}
              </Formik>
            )}

          {!isRequesting &&
            props.ticketData &&
            props.ticketData._source &&
            props.ticketData._source.Notes.length !== 0 && (
              <Grid>
                <Box
                  sx={{
                    paddingX: 2,
                    paddingY: 1,
                  }}
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justifyContent={"flex-end"}
                  >
                    <Grid
                      item
                      xl={9}
                      lg={9}
                      md={9}
                      sm={9}
                      xs={9}
                      alignItems="flex-end"
                      justifyContent={"center"}
                      textAlign="left"
                      sx={{
                        borderRadius: "10px",
                        border: "solid 0.5px transparent",
                        backgroundColor: "white",
                        color: `${theme.palette.text.primary}`,
                      }}
                    >
                      <Box
                        sx={{
                          // border: "1px solid gray",
                          p: 1,
                          borderRadius: "5px",
                          // backgroundColor: "yellow",
                        }}
                      >
                        <span>Notes :</span>
                        {!isRequesting &&
                          props.ticketData._source &&
                          props.ticketData._source.Notes &&
                          props.ticketData._source.Notes.map(
                            (item, index, array) => (
                              <NotesUI
                                item={item}
                                index={index}
                                Notes={array}
                                ticketData={props.ticketData}
                              />
                            )
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}

          {!isRequesting &&
            props.finalStatuses &&
            props.finalStatuses.length > 0 &&
            props.finalStatuses.includes(
              props.ticketData &&
                props.ticketData._source &&
                props.ticketData._source.status.status_id
            ) && (
              <Grid item container xl={12} mt={0.5}>
                <Box
                  width={"100%"}
                  sx={{
                    paddingX: 2,
                    paddingY: 0,
                  }}
                  // className="bg-warning"
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    // className="bg-success"
                  >
                    <Grid
                      item
                      textAlign={"right"}
                      // xl={10}
                      // lg={10}
                      // md={10}
                      // sm={10}
                      // className="bg-info"
                      sx={{
                        backgroundColor: "white",
                        border: "solid 0.5px #92b9c4",
                        fontFamily: "BookAntiqua-Italic",
                        borderRadius: 1,
                      }}
                      px={2}
                    >
                      <span
                        style={{
                          color: `${theme.palette.primary.main}`,
                        }}
                      >
                        <span>
                          {props.ticket_closed &&
                            props.ticket_closed[0] &&
                            props.ticket_closed[0]._source.description.split(
                              "By"
                            )[1]}
                        </span>
                        &nbsp;
                        <span>{`has closed the ticket ID :`}</span>
                        &nbsp;
                        <span>
                          {props.ticketData &&
                            props.ticketData._id &&
                            props.ticketData._id}
                        </span>
                        &nbsp;
                        <span>at</span>
                        &nbsp;
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {(function createdDate() {
                            let created =
                              props.ticket_closed &&
                              props.ticket_closed[0] &&
                              props.ticket_closed[0]._source.created_on;
                            let dateConversion = new Date(created);
                            dateConversion =
                              dateConversion.toDateString() +
                              ", " +
                              dateConversion.toLocaleTimeString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              });

                            return dateConversion;
                          })()}
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
        </Box>
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(TicketFeedComponent);
