import React, { useEffect, useState } from "react";
import "./ProjectPost.css";

import {
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { connect } from "react-redux";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ProjectAction from "../../../ormTicketing/stores/project/projectAction";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../components/muiButton/MuiButton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// mapstateto props function

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const validationSchema = yup.object({
  project_name: yup
    .string("Enter your project name")
    .trim()
    .min(1, "Project name should be of minimum 1 characters length")
    .required("Project name is required"),

  project_id: yup.string(),
  client: yup.object().shape({}).required("Client Name is Required").nullable(),
  // platform: yup
  // .object()
  // .shape({})
  // .required("Platforms are is Required")
  // .nullable(),
});

const ProjectListPost = (props) => {
  const [org_id, setOrg_id] = useState("");

  // useEffect(() => {
  //   if (props.edit) {
  //     setOrg_id(org_id && org_id.organization_id);
  //   } else {
  //     setOrg_id("");
  //   }
  // }, []);

  let platform_array = [];
  let filtered_organization = [];
  filtered_organization =
    props.organization_array &&
    props.organization_array.length > 0 &&
    props.organization_array.filter((val) => {
      return val.organization_id === org_id;
    });

  filtered_organization &&
    filtered_organization.length > 0 &&
    filtered_organization.map((item) => {
      item.mapped_platforms &&
        item.mapped_platforms.length > 0 &&
        platform_array.push(item.mapped_platforms);
    });

  if (props.organizationArray) {
    props.organizationArray.map((item) => {
      item.mapped_platforms &&
        item.mapped_platforms.length > 0 &&
        platform_array.push(item.mapped_platforms);
    });
  }

  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      project_name: props.projectEditData.project_name,
      client: {
        client_id: props.projectEditData.client_id,
        client_name: props.projectEditData.client_name,
        organization_id: props.projectEditData.organization_id,
      },
      platform: props.projectEditData.platform || [],
      initial_status_name: props.projectEditData.initial_status_name,
      final_status_name: props.projectEditData.final_status_name,
    };
  } else {
    initial = {
      project_name: "",
      client:
        props.client_array && props.client_array.length === 1
          ? props.client_array[0]
          : "",
      platform:
        props.platform_array && props.platform_array.length === 1
          ? props.platform_array
          : [],
      initial_status_name: "",
      final_status_name: "",
    };
  }

  const [loading, setLoading] = useState(false);

  const onCloseFunction = () => {
    props.onClose();
    setOrg_id("");
    platform_array = [];
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        onCloseFunction();
      }}
      fullWidth
      maxWidth="sm"
    >
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};
          if (!values.platform.length) {
            errors.platform = "Platform Name is Required";
          }

          if (!props.edit && !values.initial_status_name) {
            errors.initial_status_name = "Initial Status is Required";
          }

          if (!props.edit && !values.final_status_name) {
            errors.final_status_name = "Final Status is Required";
          }

          return errors;
        }}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);

          // parameter for showing list based on the modified_by
          const params = {
            order_by: '[["created_on", "desc"]]',
            page_limit: 15,
          };
          const param = {
            order_by: `[["project_name.keyword", "asc"]]`,
            page_limit: "none",
          };

          if (props.edit) {
            const edit_obj = {
              project_name: values.project_name,
              client: {
                client_id: values.client.client_id,
                client_name: values.client.client_name,
              },
              platform: values.platform,
            };

            props
              .dispatch(
                ProjectAction.requestPutProject(
                  JSON.stringify(edit_obj),
                  props.projectEditData.project_unique_id
                )
              )
              .then(() => {
                props.dispatch(ProjectAction.requestProject(params));
                props.dispatch(ProjectAction.requestProjectFilter(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            const callBackFunction = (resp) => {
              props.onClose(resp);
            };
            props
              .dispatch(
                ProjectAction.requestPostProject(
                  JSON.stringify(values),
                  callBackFunction
                )
              )
              .then(() => {
                const params = {
                  order_by: '[["created_on", "desc"]]',
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["project_name.keyword", "asc"]]`,
                  page_limit: "none",
                };

                props.dispatch(ProjectAction.requestProject(params));
                props.dispatch(ProjectAction.requestProjectFilter(param));
                setLoading(false);
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
          resetForm,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">A</span>
                            dd &nbsp;
                            <span className="span_first_letter">P</span>roject
                            &nbsp;
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">E</span>
                            dit &nbsp;
                            <span className="span_first_letter">P</span>roject
                            &nbsp;
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                        setOrg_id("");
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item ml={2} lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} mt={1}>
                    Project Name * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="project_name"
                      size="small"
                      name="project_name"
                      placeholder="Project Name"
                      error={
                        Boolean(errors.project_name) &&
                        Boolean(touched.project_name)
                      }
                      helperText={
                        Boolean(touched.project_name) && errors.project_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item ml={2} lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} mt={3}>
                    Client * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <Field
                      name="client"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.client ? values.client : null}
                      component={AutoCompleteMultiSelect}
                      options={props.client_array && props.client_array}
                      onChange={(_, value) => {
                        setFieldValue(`client`, value);
                        // setFieldValue(`platform`, []);
                        setOrg_id(value.organization_id);
                      }}
                      disabled={
                        props.client_array && props.client_array.length === 1
                          ? true
                          : false
                      }
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        stringify: (option) => option.client_name,
                      })}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.client_name === value.client_name
                      }
                      getOptionLabel={(option) => option.client_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Clients",
                      }}
                    />
                  </Grid>
                  {values.client
                    ? setOrg_id(
                        props.edit
                          ? values.client.organization_id.organization_id
                          : values.client.organization_id
                      )
                    : null}
                </Grid>
                <Grid item ml={2} lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} mt={3}>
                    Platform * :
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <Field
                      name="platform"
                      size="small"
                      multiple
                      // Calling AutoCompleteMultiSelect Component
                      value={values.platform ? values.platform : null}
                      component={AutoCompleteMultiSelect}
                      // multiple
                      disableCloseOnSelect={true}
                      disabled={
                        props.platform_array &&
                        props.platform_array.length === 1
                          ? true
                          : false
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.platform_alias}
                        </li>
                      )}
                      options={
                        props.platform_array
                          ? props.platform_array
                          : platform_array && platform_array[0]
                          ? platform_array[0]
                          : []
                      }
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.platform_alias === value.platform_alias
                      }
                      getOptionLabel={(option) => option.platform_alias}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder:
                          !values.platform.length > 0 ? "Platform" : null,
                      }}
                    />
                  </Grid>
                </Grid>

                {!props.edit && (
                  <Grid item ml={2} lg={12} md={12} sm={12} container my={2}>
                    <Grid item lg={3} md={3} sm={3}>
                      Initial Status * :
                    </Grid>
                    <Grid item lg={8} md={8} sm={8}>
                      <Field
                        name="initial_status_name"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component

                        placeholder="Initial Status"
                        as={MuiTextField}
                        error={
                          Boolean(errors.initial_status_name) &&
                          Boolean(touched.initial_status_name)
                        }
                        helperText={
                          Boolean(touched.initial_status_name) &&
                          errors.initial_status_name
                        }
                        // options={props.status_list ? props.status_list : []}
                        // onChange={(_, value) => {
                        //   setFieldValue(`initial_status_name`, value);
                        // }}
                        // isOptionEqualToValue={(option, value) =>
                        //   //setting value property to custom value
                        //   option.client_name === value.client_name
                        // }
                        // getOptionLabel={(option) => option}
                        // textFieldProps={{
                        //   margin: "normal",
                        //   variant: "outlined",
                        //   placeholder: "Initial Status",
                        // }}
                      />
                    </Grid>
                  </Grid>
                )}

                {!props.edit && (
                  <Grid item ml={2} lg={12} md={12} sm={12} container mt={1}>
                    <Grid item lg={3} md={3} sm={3}>
                      Final Status * :
                    </Grid>
                    <Grid item lg={8} md={8} sm={8}>
                      <Field
                        name="final_status_name"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component

                        as={MuiTextField}
                        placeholder="Final Status"
                        error={
                          Boolean(errors.final_status_name) &&
                          Boolean(touched.final_status_name)
                        }
                        helperText={
                          Boolean(touched.final_status_name) &&
                          errors.final_status_name
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Project "}
                      type="submit"
                      loading={loading}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      name={"Submit"}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapStateToProps)(ProjectListPost);
