import { BaseModel } from "sjs-base-model";

// InfluencersLangugeGetModel
export default class InfluencersLangugeGetModel extends BaseModel {
  // response getting from the api mentioned end point

  list_of_language_data = {};
  constructor(data1) {
    super();

    this.update(data1);
  }
}
