import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import HttpErrorResponseModel from "../../../../models/HttpErrorResponseModel";
import YoutubeVideosShadowGetModel from "./models/YoutubeVideosShadowGetModels";

export default class YoutubeVideosShadowEffect {
  static async requestYoutubeVideosWebhook(params, callBackFunction) {
    const endpoint = environment.api.ytVideosShadow;

    let response = EffectUtility.getToModel(
      YoutubeVideosShadowGetModel,
      endpoint,
      params
    );
    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
