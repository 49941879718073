import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
//import { NivoBarChart } from "../../../components/nivoCharts/NivoBarChart";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";
function StaticDialyMentionsChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  let date = [
    "1968/10/4",
    "1968/10/5",
    "1968/10/6",
    "1968/10/7",
    "1968/10/8",
    "1968/10/9",
    "1968/10/10",
    "1968/10/11",
    "1968/10/12",
    "1968/10/13",
    "1968/10/14",
    "1968/10/15",
    "1968/10/16",
    "1968/10/17",
    "1968/10/18",
    "1968/10/19",
    "1968/10/20",
    "1968/10/21",
    "1968/10/22",
    "1968/10/23",
    "1968/10/24",
    "1968/10/25",
    "1968/10/26",
    "1968/10/27",
    "1968/10/28",
    "1968/10/29",
    "1968/10/30",
    "1968/10/31",
    "1968/11/1",
    "1968/11/2",
    "1968/11/3",
    "1968/11/4",
    "1968/11/5",
    "1968/11/6",
    "1968/11/7",
    "1968/11/8",
    "1968/11/9",
    "1968/11/10",
    "1968/11/11",
    "1968/11/12",
    "1968/11/13",
    "1968/11/14",
    "1968/11/15",
    "1968/11/16",
    "1968/11/17",
    "1968/11/18",
    "1968/11/19",
    "1968/11/20",
    "1968/11/21",
    "1968/11/22",
    "1968/11/23",
    "1968/11/24",
    "1968/11/25",
    "1968/11/26",
    "1968/11/27",
    "1968/11/28",
    "1968/11/29",
    "1968/11/30",
    "1968/12/1",
    "1968/12/2",
    "1968/12/3",
    "1968/12/4",
    "1968/12/5",
    "1968/12/6",
    "1968/12/7",
    "1968/12/8",
    "1968/12/9",
    "1968/12/10",
    "1968/12/11",
    "1968/12/12",
    "1968/12/13",
    "1968/12/14",
    "1968/12/15",
    "1968/12/16",
    "1968/12/17",
    "1968/12/18",
    "1968/12/19",
    "1968/12/20",
    "1968/12/21",
    "1968/12/22",
    "1968/12/23",
    "1968/12/24",
    "1968/12/25",
    "1968/12/26",
    "1968/12/27",
    "1968/12/28",
    "1968/12/29",
    "1968/12/30",
    "1968/12/31",
    "1969/1/1",
    "1969/1/2",
    "1969/1/3",
    "1969/1/4",
    "1969/1/5",
    "1969/1/6",
    "1969/1/7",
    "1969/1/8",
    "1969/1/9",
    "1969/1/10",
    "1969/1/11",
    "1969/1/12",
    "1969/1/13",
    "1969/1/14",
    "1969/1/15",
    "1969/1/16",
    "1969/1/17",
    "1969/1/18",
    "1969/1/19",
    "1969/1/20",
    "1969/1/21",
    "1969/1/22",
    "1969/1/23",
    "1969/1/24",
    "1969/1/25",
    "1969/1/26",
    "1969/1/27",
    "1969/1/28",
    "1969/1/29",
    "1969/1/30",
    "1969/1/31",
    "1969/2/1",
    "1969/2/2",
    "1969/2/3",
    "1969/2/4",
    "1969/2/5",
    "1969/2/6",
    "1969/2/7",
    "1969/2/8",
    "1969/2/9",
    "1969/2/10",
    "1969/2/11",
    "1969/2/12",
    "1969/2/13",
    "1969/2/14",
    "1969/2/15",
    "1969/2/16",
    "1969/2/17",
    "1969/2/18",
    "1969/2/19",
    "1969/2/20",
    "1969/2/21",
    "1969/2/22",
    "1969/2/23",
    "1969/2/24",
    "1969/2/25",
    "1969/2/26",
    "1969/2/27",
    "1969/2/28",
    "1969/3/1",
    "1969/3/2",
    "1969/3/3",
    "1969/3/4",
    "1969/3/5",
    "1969/3/6",
    "1969/3/7",
    "1969/3/8",
    "1969/3/9",
    "1969/3/10",
    "1969/3/11",
    "1969/3/12",
    "1969/3/13",
    "1969/3/14",
    "1969/3/15",
    "1969/3/16",
    "1969/3/17",
    "1969/3/18",
    "1969/3/19",
    "1969/3/20",
    "1969/3/21",
    "1969/3/22",
    "1969/3/23",
    "1969/3/24",
    "1969/3/25",
    "1969/3/26",
    "1969/3/27",
    "1969/3/28",
    "1969/3/29",
    "1969/3/30",
    "1969/3/31",
    "1969/4/1",
    "1969/4/2",
    "1969/4/3",
    "1969/4/4",
    "1969/4/5",
    "1969/4/6",
    "1969/4/7",
    "1969/4/8",
    "1969/4/9",
    "1969/4/10",
    "1969/4/11",
    "1969/4/12",
    "1969/4/13",
    "1969/4/14",
    "1969/4/15",
    "1969/4/16",
    "1969/4/17",
    "1969/4/18",
    "1969/4/19",
    "1969/4/20",
    "1969/4/21",
    "1969/4/22",
    "1969/4/23",
    "1969/4/24",
    "1969/4/25",
    "1969/4/26",
    "1969/4/27",
    "1969/4/28",
    "1969/4/29",
    "1969/4/30",
    "1969/5/1",
    "1969/5/2",
    "1969/5/3",
    "1969/5/4",
    "1969/5/5",
    "1969/5/6",
    "1969/5/7",
    "1969/5/8",
    "1969/5/9",
    "1969/5/10",
    "1969/5/11",
    "1969/5/12",
    "1969/5/13",
    "1969/5/14",
    "1969/5/15",
    "1969/5/16",
    "1969/5/17",
    "1969/5/18",
    "1969/5/19",
    "1969/5/20",
    "1969/5/21",
    "1969/5/22",
    "1969/5/23",
    "1969/5/24",
    "1969/5/25",
    "1969/5/26",
    "1969/5/27",
    "1969/5/28",
    "1969/5/29",
    "1969/5/30",
    "1969/5/31",
    "1969/6/1",
    "1969/6/2",
    "1969/6/3",
    "1969/6/4",
    "1969/6/5",
    "1969/6/6",
    "1969/6/7",
    "1969/6/8",
    "1969/6/9",
    "1969/6/10",
    "1969/6/11",
    "1969/6/12",
    "1969/6/13",
    "1969/6/14",
    "1969/6/15",
    "1969/6/16",
    "1969/6/17",
    "1969/6/18",
    "1969/6/19",
    "1969/6/20",
    "1969/6/21",
    "1969/6/22",
    "1969/6/23",
    "1969/6/24",
    "1969/6/25",
    "1969/6/26",
    "1969/6/27",
    "1969/6/28",
    "1969/6/29",
    "1969/6/30",
    "1969/7/1",
    "1969/7/2",
    "1969/7/3",
    "1969/7/4",
    "1969/7/5",
    "1969/7/6",
    "1969/7/7",
    "1969/7/8",
    "1969/7/9",
    "1969/7/10",
    "1969/7/11",
    "1969/7/12",
    "1969/7/13",
    "1969/7/14",
    "1969/7/15",
    "1969/7/16",
    "1969/7/17",
    "1969/7/18",
    "1969/7/19",
    "1969/7/20",
    "1969/7/21",
    "1969/7/22",
    "1969/7/23",
    "1969/7/24",
    "1969/7/25",
    "1969/7/26",
    "1969/7/27",
    "1969/7/28",
    "1969/7/29",
    "1969/7/30",
    "1969/7/31",
    "1969/8/1",
    "1969/8/2",
    "1969/8/3",
    "1969/8/4",
    "1969/8/5",
    "1969/8/6",
    "1969/8/7",
    "1969/8/8",
    "1969/8/9",
    "1969/8/10",
    "1969/8/11",
    "1969/8/12",
    "1969/8/13",
    "1969/8/14",
    "1969/8/15",
    "1969/8/16",
    "1969/8/17",
    "1969/8/18",
    "1969/8/19",
    "1969/8/20",
    "1969/8/21",
    "1969/8/22",
    "1969/8/23",
    "1969/8/24",
    "1969/8/25",
    "1969/8/26",
    "1969/8/27",
    "1969/8/28",
    "1969/8/29",
    "1969/8/30",
    "1969/8/31",
    "1969/9/1",
    "1969/9/2",
    "1969/9/3",
    "1969/9/4",
    "1969/9/5",
    "1969/9/6",
    "1969/9/7",
    "1969/9/8",
    "1969/9/9",
    "1969/9/10",
    "1969/9/11",
    "1969/9/12",
    "1969/9/13",
    "1969/9/14",
    "1969/9/15",
    "1969/9/16",
    "1969/9/17",
    "1969/9/18",
    "1969/9/19",
    "1969/9/20",
    "1969/9/21",
    "1969/9/22",
    "1969/9/23",
    "1969/9/24",
    "1969/9/25",
    "1969/9/26",
    "1969/9/27",
    "1969/9/28",
    "1969/9/29",
    "1969/9/30",
    "1969/10/1",
    "1969/10/2",
    "1969/10/3",
    "1969/10/4",
    "1969/10/5",
    "1969/10/6",
    "1969/10/7",
    "1969/10/8",
    "1969/10/9",
    "1969/10/10",
    "1969/10/11",
    "1969/10/12",
    "1969/10/13",
    "1969/10/14",
    "1969/10/15",
    "1969/10/16",
    "1969/10/17",
    "1969/10/18",
    "1969/10/19",
    "1969/10/20",
    "1969/10/21",
    "1969/10/22",
    "1969/10/23",
    "1969/10/24",
    "1969/10/25",
    "1969/10/26",
    "1969/10/27",
    "1969/10/28",
    "1969/10/29",
    "1969/10/30",
    "1969/10/31",
    "1969/11/1",
    "1969/11/2",
    "1969/11/3",
    "1969/11/4",
    "1969/11/5",
    "1969/11/6",
    "1969/11/7",
    "1969/11/8",
    "1969/11/9",
    "1969/11/10",
    "1969/11/11",
    "1969/11/12",
    "1969/11/13",
    "1969/11/14",
    "1969/11/15",
    "1969/11/16",
    "1969/11/17",
    "1969/11/18",
    "1969/11/19",
    "1969/11/20",
    "1969/11/21",
    "1969/11/22",
    "1969/11/23",
    "1969/11/24",
    "1969/11/25",
    "1969/11/26",
    "1969/11/27",
    "1969/11/28",
    "1969/11/29",
    "1969/11/30",
    "1969/12/1",
    "1969/12/2",
    "1969/12/3",
    "1969/12/4",
    "1969/12/5",
    "1969/12/6",
    "1969/12/7",
    "1969/12/8",
    "1969/12/9",
    "1969/12/10",
    "1969/12/11",
    "1969/12/12",
    "1969/12/13",
    "1969/12/14",
    "1969/12/15",
    "1969/12/16",
    "1969/12/17",
    "1969/12/18",
    "1969/12/19",
    "1969/12/20",
    "1969/12/21",
    "1969/12/22",
    "1969/12/23",
    "1969/12/24",
    "1969/12/25",
    "1969/12/26",
    "1969/12/27",
    "1969/12/28",
    "1969/12/29",
    "1969/12/30",
    "1969/12/31",
    "1970/1/1",
    "1970/1/2",
    "1970/1/3",
    "1970/1/4",
    "1970/1/5",
    "1970/1/6",
    "1970/1/7",
    "1970/1/8",
    "1970/1/9",
    "1970/1/10",
    "1970/1/11",
    "1970/1/12",
    "1970/1/13",
    "1970/1/14",
    "1970/1/15",
    "1970/1/16",
    "1970/1/17",
    "1970/1/18",
    "1970/1/19",
    "1970/1/20",
    "1970/1/21",
    "1970/1/22",
    "1970/1/23",
    "1970/1/24",
    "1970/1/25",
    "1970/1/26",
    "1970/1/27",
    "1970/1/28",
    "1970/1/29",
    "1970/1/30",
    "1970/1/31",
    "1970/2/1",
    "1970/2/2",
    "1970/2/3",
    "1970/2/4",
    "1970/2/5",
    "1970/2/6",
    "1970/2/7",
    "1970/2/8",
    "1970/2/9",
    "1970/2/10",
    "1970/2/11",
    "1970/2/12",
    "1970/2/13",
    "1970/2/14",
    "1970/2/15",
    "1970/2/16",
    "1970/2/17",
    "1970/2/18",
    "1970/2/19",
    "1970/2/20",
    "1970/2/21",
    "1970/2/22",
    "1970/2/23",
    "1970/2/24",
    "1970/2/25",
    "1970/2/26",
    "1970/2/27",
    "1970/2/28",
    "1970/3/1",
    "1970/3/2",
    "1970/3/3",
    "1970/3/4",
    "1970/3/5",
    "1970/3/6",
    "1970/3/7",
    "1970/3/8",
    "1970/3/9",
    "1970/3/10",
    "1970/3/11",
    "1970/3/12",
    "1970/3/13",
    "1970/3/14",
    "1970/3/15",
    "1970/3/16",
    "1970/3/17",
    "1970/3/18",
    "1970/3/19",
    "1970/3/20",
    "1970/3/21",
    "1970/3/22",
    "1970/3/23",
    "1970/3/24",
    "1970/3/25",
    "1970/3/26",
    "1970/3/27",
    "1970/3/28",
    "1970/3/29",
    "1970/3/30",
    "1970/3/31",
    "1970/4/1",
    "1970/4/2",
    "1970/4/3",
    "1970/4/4",
    "1970/4/5",
    "1970/4/6",
    "1970/4/7",
    "1970/4/8",
    "1970/4/9",
    "1970/4/10",
    "1970/4/11",
    "1970/4/12",
    "1970/4/13",
    "1970/4/14",
    "1970/4/15",
    "1970/4/16",
    "1970/4/17",
    "1970/4/18",
    "1970/4/19",
    "1970/4/20",
    "1970/4/21",
    "1970/4/22",
    "1970/4/23",
    "1970/4/24",
    "1970/4/25",
    "1970/4/26",
    "1970/4/27",
    "1970/4/28",
    "1970/4/29",
    "1970/4/30",
    "1970/5/1",
    "1970/5/2",
    "1970/5/3",
    "1970/5/4",
    "1970/5/5",
    "1970/5/6",
    "1970/5/7",
    "1970/5/8",
    "1970/5/9",
    "1970/5/10",
    "1970/5/11",
    "1970/5/12",
    "1970/5/13",
    "1970/5/14",
    "1970/5/15",
    "1970/5/16",
    "1970/5/17",
    "1970/5/18",
    "1970/5/19",
    "1970/5/20",
    "1970/5/21",
    "1970/5/22",
    "1970/5/23",
    "1970/5/24",
    "1970/5/25",
    "1970/5/26",
    "1970/5/27",
    "1970/5/28",
    "1970/5/29",
    "1970/5/30",
    "1970/5/31",
    "1970/6/1",
    "1970/6/2",
    "1970/6/3",
    "1970/6/4",
    "1970/6/5",
    "1970/6/6",
    "1970/6/7",
    "1970/6/8",
    "1970/6/9",
    "1970/6/10",
    "1970/6/11",
    "1970/6/12",
    "1970/6/13",
    "1970/6/14",
    "1970/6/15",
    "1970/6/16",
    "1970/6/17",
    "1970/6/18",
    "1970/6/19",
    "1970/6/20",
    "1970/6/21",
    "1970/6/22",
    "1970/6/23",
    "1970/6/24",
    "1970/6/25",
    "1970/6/26",
    "1970/6/27",
    "1970/6/28",
    "1970/6/29",
    "1970/6/30",
    "1970/7/1",
    "1970/7/2",
    "1970/7/3",
    "1970/7/4",
    "1970/7/5",
    "1970/7/6",
    "1970/7/7",
    "1970/7/8",
    "1970/7/9",
    "1970/7/10",
    "1970/7/11",
    "1970/7/12",
    "1970/7/13",
    "1970/7/14",
    "1970/7/15",
    "1970/7/16",
    "1970/7/17",
    "1970/7/18",
    "1970/7/19",
    "1970/7/20",
    "1970/7/21",
    "1970/7/22",
    "1970/7/23",
    "1970/7/24",
    "1970/7/25",
    "1970/7/26",
    "1970/7/27",
    "1970/7/28",
    "1970/7/29",
    "1970/7/30",
    "1970/7/31",
    "1970/8/1",
    "1970/8/2",
    "1970/8/3",
    "1970/8/4",
    "1970/8/5",
    "1970/8/6",
    "1970/8/7",
    "1970/8/8",
    "1970/8/9",
    "1970/8/10",
    "1970/8/11",
    "1970/8/12",
    "1970/8/13",
    "1970/8/14",
    "1970/8/15",
    "1970/8/16",
    "1970/8/17",
    "1970/8/18",
    "1970/8/19",
    "1970/8/20",
    "1970/8/21",
    "1970/8/22",
    "1970/8/23",
    "1970/8/24",
    "1970/8/25",
    "1970/8/26",
    "1970/8/27",
    "1970/8/28",
    "1970/8/29",
    "1970/8/30",
    "1970/8/31",
    "1970/9/1",
    "1970/9/2",
    "1970/9/3",
    "1970/9/4",
    "1970/9/5",
    "1970/9/6",
    "1970/9/7",
    "1970/9/8",
    "1970/9/9",
    "1970/9/10",
    "1970/9/11",
    "1970/9/12",
    "1970/9/13",
    "1970/9/14",
    "1970/9/15",
    "1970/9/16",
    "1970/9/17",
    "1970/9/18",
    "1970/9/19",
    "1970/9/20",
    "1970/9/21",
    "1970/9/22",
    "1970/9/23",
    "1970/9/24",
    "1970/9/25",
    "1970/9/26",
    "1970/9/27",
    "1970/9/28",
    "1970/9/29",
    "1970/9/30",
    "1970/10/1",
    "1970/10/2",
    "1970/10/3",
    "1970/10/4",
    "1970/10/5",
    "1970/10/6",
    "1970/10/7",
    "1970/10/8",
    "1970/10/9",
    "1970/10/10",
    "1970/10/11",
    "1970/10/12",
    "1970/10/13",
    "1970/10/14",
    "1970/10/15",
    "1970/10/16",
    "1970/10/17",
    "1970/10/18",
    "1970/10/19",
    "1970/10/20",
    "1970/10/21",
    "1970/10/22",
    "1970/10/23",
    "1970/10/24",
    "1970/10/25",
    "1970/10/26",
    "1970/10/27",
    "1970/10/28",
    "1970/10/29",
    "1970/10/30",
    "1970/10/31",
    "1970/11/1",
    "1970/11/2",
    "1970/11/3",
    "1970/11/4",
    "1970/11/5",
    "1970/11/6",
    "1970/11/7",
    "1970/11/8",
    "1970/11/9",
    "1970/11/10",
    "1970/11/11",
    "1970/11/12",
    "1970/11/13",
    "1970/11/14",
    "1970/11/15",
    "1970/11/16",
    "1970/11/17",
    "1970/11/18",
    "1970/11/19",
    "1970/11/20",
    "1970/11/21",
    "1970/11/22",
    "1970/11/23",
    "1970/11/24",
    "1970/11/25",
    "1970/11/26",
    "1970/11/27",
    "1970/11/28",
    "1970/11/29",
    "1970/11/30",
    "1970/12/1",
    "1970/12/2",
    "1970/12/3",
    "1970/12/4",
    "1970/12/5",
    "1970/12/6",
    "1970/12/7",
    "1970/12/8",
    "1970/12/9",
    "1970/12/10",
    "1970/12/11",
    "1970/12/12",
    "1970/12/13",
    "1970/12/14",
    "1970/12/15",
    "1970/12/16",
    "1970/12/17",
    "1970/12/18",
    "1970/12/19",
    "1970/12/20",
    "1970/12/21",
    "1970/12/22",
    "1970/12/23",
    "1970/12/24",
    "1970/12/25",
    "1970/12/26",
    "1970/12/27",
    "1970/12/28",
    "1970/12/29",
    "1970/12/30",
    "1970/12/31",
    "1971/1/1",
    "1971/1/2",
    "1971/1/3",
    "1971/1/4",
    "1971/1/5",
    "1971/1/6",
    "1971/1/7",
    "1971/1/8",
    "1971/1/9",
    "1971/1/10",
    "1971/1/11",
    "1971/1/12",
    "1971/1/13",
    "1971/1/14",
    "1971/1/15",
    "1971/1/16",
    "1971/1/17",
    "1971/1/18",
    "1971/1/19",
    "1971/1/20",
    "1971/1/21",
    "1971/1/22",
    "1971/1/23",
    "1971/1/24",
    "1971/1/25",
    "1971/1/26",
    "1971/1/27",
    "1971/1/28",
    "1971/1/29",
    "1971/1/30",
    "1971/1/31",
    "1971/2/1",
    "1971/2/2",
    "1971/2/3",
    "1971/2/4",
    "1971/2/5",
    "1971/2/6",
    "1971/2/7",
    "1971/2/8",
    "1971/2/9",
    "1971/2/10",
    "1971/2/11",
    "1971/2/12",
    "1971/2/13",
    "1971/2/14",
    "1971/2/15",
    "1971/2/16",
    "1971/2/17",
    "1971/2/18",
    "1971/2/19",
    "1971/2/20",
    "1971/2/21",
    "1971/2/22",
    "1971/2/23",
    "1971/2/24",
    "1971/2/25",
    "1971/2/26",
    "1971/2/27",
    "1971/2/28",
    "1971/3/1",
    "1971/3/2",
    "1971/3/3",
    "1971/3/4",
    "1971/3/5",
    "1971/3/6",
    "1971/3/7",
    "1971/3/8",
    "1971/3/9",
    "1971/3/10",
    "1971/3/11",
    "1971/3/12",
    "1971/3/13",
    "1971/3/14",
    "1971/3/15",
    "1971/3/16",
    "1971/3/17",
    "1971/3/18",
    "1971/3/19",
    "1971/3/20",
    "1971/3/21",
    "1971/3/22",
    "1971/3/23",
    "1971/3/24",
    "1971/3/25",
    "1971/3/26",
    "1971/3/27",
    "1971/3/28",
    "1971/3/29",
    "1971/3/30",
    "1971/3/31",
    "1971/4/1",
    "1971/4/2",
    "1971/4/3",
    "1971/4/4",
    "1971/4/5",
    "1971/4/6",
    "1971/4/7",
    "1971/4/8",
    "1971/4/9",
    "1971/4/10",
    "1971/4/11",
    "1971/4/12",
    "1971/4/13",
    "1971/4/14",
    "1971/4/15",
    "1971/4/16",
    "1971/4/17",
    "1971/4/18",
    "1971/4/19",
    "1971/4/20",
    "1971/4/21",
    "1971/4/22",
    "1971/4/23",
    "1971/4/24",
    "1971/4/25",
    "1971/4/26",
    "1971/4/27",
    "1971/4/28",
    "1971/4/29",
    "1971/4/30",
    "1971/5/1",
    "1971/5/2",
    "1971/5/3",
    "1971/5/4",
    "1971/5/5",
    "1971/5/6",
    "1971/5/7",
    "1971/5/8",
    "1971/5/9",
    "1971/5/10",
    "1971/5/11",
    "1971/5/12",
    "1971/5/13",
    "1971/5/14",
    "1971/5/15",
    "1971/5/16",
    "1971/5/17",
    "1971/5/18",
    "1971/5/19",
    "1971/5/20",
    "1971/5/21",
    "1971/5/22",
    "1971/5/23",
    "1971/5/24",
    "1971/5/25",
    "1971/5/26",
    "1971/5/27",
    "1971/5/28",
    "1971/5/29",
    "1971/5/30",
    "1971/5/31",
    "1971/6/1",
    "1971/6/2",
    "1971/6/3",
    "1971/6/4",
    "1971/6/5",
    "1971/6/6",
    "1971/6/7",
    "1971/6/8",
    "1971/6/9",
    "1971/6/10",
    "1971/6/11",
    "1971/6/12",
    "1971/6/13",
    "1971/6/14",
    "1971/6/15",
    "1971/6/16",
    "1971/6/17",
    "1971/6/18",
    "1971/6/19",
    "1971/6/20",
    "1971/6/21",
    "1971/6/22",
    "1971/6/23",
    "1971/6/24",
    "1971/6/25",
    "1971/6/26",
    "1971/6/27",
    "1971/6/28",
    "1971/6/29",
    "1971/6/30",
    "1971/7/1",
    "1971/7/2",
    "1971/7/3",
    "1971/7/4",
    "1971/7/5",
    "1971/7/6",
    "1971/7/7",
    "1971/7/8",
    "1971/7/9",
    "1971/7/10",
    "1971/7/11",
    "1971/7/12",
    "1971/7/13",
    "1971/7/14",
    "1971/7/15",
    "1971/7/16",
    "1971/7/17",
    "1971/7/18",
    "1971/7/19",
    "1971/7/20",
    "1971/7/21",
    "1971/7/22",
    "1971/7/23",
    "1971/7/24",
    "1971/7/25",
    "1971/7/26",
    "1971/7/27",
    "1971/7/28",
    "1971/7/29",
    "1971/7/30",
    "1971/7/31",
    "1971/8/1",
    "1971/8/2",
    "1971/8/3",
    "1971/8/4",
    "1971/8/5",
    "1971/8/6",
    "1971/8/7",
    "1971/8/8",
    "1971/8/9",
    "1971/8/10",
    "1971/8/11",
    "1971/8/12",
    "1971/8/13",
    "1971/8/14",
    "1971/8/15",
    "1971/8/16",
    "1971/8/17",
    "1971/8/18",
    "1971/8/19",
    "1971/8/20",
    "1971/8/21",
    "1971/8/22",
    "1971/8/23",
    "1971/8/24",
    "1971/8/25",
    "1971/8/26",
    "1971/8/27",
    "1971/8/28",
    "1971/8/29",
    "1971/8/30",
    "1971/8/31",
    "1971/9/1",
    "1971/9/2",
    "1971/9/3",
    "1971/9/4",
    "1971/9/5",
    "1971/9/6",
    "1971/9/7",
    "1971/9/8",
    "1971/9/9",
    "1971/9/10",
    "1971/9/11",
    "1971/9/12",
    "1971/9/13",
    "1971/9/14",
    "1971/9/15",
    "1971/9/16",
    "1971/9/17",
    "1971/9/18",
    "1971/9/19",
    "1971/9/20",
    "1971/9/21",
    "1971/9/22",
    "1971/9/23",
    "1971/9/24",
    "1971/9/25",
    "1971/9/26",
    "1971/9/27",
    "1971/9/28",
    "1971/9/29",
    "1971/9/30",
    "1971/10/1",
    "1971/10/2",
    "1971/10/3",
    "1971/10/4",
    "1971/10/5",
    "1971/10/6",
    "1971/10/7",
    "1971/10/8",
    "1971/10/9",
    "1971/10/10",
    "1971/10/11",
    "1971/10/12",
    "1971/10/13",
    "1971/10/14",
    "1971/10/15",
    "1971/10/16",
    "1971/10/17",
    "1971/10/18",
    "1971/10/19",
    "1971/10/20",
    "1971/10/21",
    "1971/10/22",
    "1971/10/23",
    "1971/10/24",
    "1971/10/25",
    "1971/10/26",
    "1971/10/27",
    "1971/10/28",
    "1971/10/29",
    "1971/10/30",
    "1971/10/31",
    "1971/11/1",
    "1971/11/2",
    "1971/11/3",
    "1971/11/4",
    "1971/11/5",
    "1971/11/6",
    "1971/11/7",
    "1971/11/8",
    "1971/11/9",
    "1971/11/10",
    "1971/11/11",
    "1971/11/12",
    "1971/11/13",
    "1971/11/14",
    "1971/11/15",
    "1971/11/16",
    "1971/11/17",
    "1971/11/18",
    "1971/11/19",
    "1971/11/20",
    "1971/11/21",
    "1971/11/22",
    "1971/11/23",
    "1971/11/24",
    "1971/11/25",
    "1971/11/26",
    "1971/11/27",
    "1971/11/28",
    "1971/11/29",
    "1971/11/30",
    "1971/12/1",
    "1971/12/2",
    "1971/12/3",
    "1971/12/4",
    "1971/12/5",
    "1971/12/6",
    "1971/12/7",
    "1971/12/8",
    "1971/12/9",
    "1971/12/10",
    "1971/12/11",
    "1971/12/12",
    "1971/12/13",
    "1971/12/14",
    "1971/12/15",
    "1971/12/16",
    "1971/12/17",
    "1971/12/18",
    "1971/12/19",
    "1971/12/20",
    "1971/12/21",
    "1971/12/22",
    "1971/12/23",
    "1971/12/24",
    "1971/12/25",
    "1971/12/26",
    "1971/12/27",
    "1971/12/28",
    "1971/12/29",
    "1971/12/30",
    "1971/12/31",
    "1972/1/1",
    "1972/1/2",
    "1972/1/3",
    "1972/1/4",
    "1972/1/5",
    "1972/1/6",
    "1972/1/7",
    "1972/1/8",
    "1972/1/9",
    "1972/1/10",
    "1972/1/11",
    "1972/1/12",
    "1972/1/13",
    "1972/1/14",
    "1972/1/15",
    "1972/1/16",
    "1972/1/17",
    "1972/1/18",
    "1972/1/19",
    "1972/1/20",
    "1972/1/21",
    "1972/1/22",
    "1972/1/23",
    "1972/1/24",
    "1972/1/25",
    "1972/1/26",
    "1972/1/27",
    "1972/1/28",
    "1972/1/29",
    "1972/1/30",
    "1972/1/31",
    "1972/2/1",
    "1972/2/2",
    "1972/2/3",
    "1972/2/4",
    "1972/2/5",
    "1972/2/6",
    "1972/2/7",
    "1972/2/8",
    "1972/2/9",
    "1972/2/10",
    "1972/2/11",
    "1972/2/12",
    "1972/2/13",
    "1972/2/14",
    "1972/2/15",
    "1972/2/16",
    "1972/2/17",
    "1972/2/18",
    "1972/2/19",
    "1972/2/20",
    "1972/2/21",
    "1972/2/22",
    "1972/2/23",
    "1972/2/24",
    "1972/2/25",
    "1972/2/26",
    "1972/2/27",
    "1972/2/28",
    "1972/2/29",
    "1972/3/1",
    "1972/3/2",
    "1972/3/3",
    "1972/3/4",
    "1972/3/5",
    "1972/3/6",
    "1972/3/7",
    "1972/3/8",
    "1972/3/9",
    "1972/3/10",
    "1972/3/11",
    "1972/3/12",
    "1972/3/13",
    "1972/3/14",
    "1972/3/15",
    "1972/3/16",
    "1972/3/17",
    "1972/3/18",
    "1972/3/19",
    "1972/3/20",
    "1972/3/21",
    "1972/3/22",
    "1972/3/23",
    "1972/3/24",
    "1972/3/25",
    "1972/3/26",
    "1972/3/27",
    "1972/3/28",
    "1972/3/29",
    "1972/3/30",
    "1972/3/31",
    "1972/4/1",
    "1972/4/2",
    "1972/4/3",
    "1972/4/4",
    "1972/4/5",
    "1972/4/6",
    "1972/4/7",
    "1972/4/8",
    "1972/4/9",
    "1972/4/10",
    "1972/4/11",
    "1972/4/12",
    "1972/4/13",
    "1972/4/14",
    "1972/4/15",
    "1972/4/16",
    "1972/4/17",
    "1972/4/18",
    "1972/4/19",
    "1972/4/20",
    "1972/4/21",
    "1972/4/22",
    "1972/4/23",
    "1972/4/24",
    "1972/4/25",
    "1972/4/26",
    "1972/4/27",
    "1972/4/28",
    "1972/4/29",
    "1972/4/30",
    "1972/5/1",
    "1972/5/2",
    "1972/5/3",
    "1972/5/4",
    "1972/5/5",
    "1972/5/6",
    "1972/5/7",
    "1972/5/8",
    "1972/5/9",
    "1972/5/10",
    "1972/5/11",
    "1972/5/12",
    "1972/5/13",
    "1972/5/14",
    "1972/5/15",
    "1972/5/16",
    "1972/5/17",
    "1972/5/18",
    "1972/5/19",
    "1972/5/20",
    "1972/5/21",
    "1972/5/22",
    "1972/5/23",
    "1972/5/24",
    "1972/5/25",
    "1972/5/26",
    "1972/5/27",
    "1972/5/28",
    "1972/5/29",
    "1972/5/30",
    "1972/5/31",
    "1972/6/1",
    "1972/6/2",
    "1972/6/3",
    "1972/6/4",
    "1972/6/5",
    "1972/6/6",
    "1972/6/7",
    "1972/6/8",
    "1972/6/9",
    "1972/6/10",
    "1972/6/11",
    "1972/6/12",
    "1972/6/13",
    "1972/6/14",
    "1972/6/15",
    "1972/6/16",
    "1972/6/17",
    "1972/6/18",
    "1972/6/19",
    "1972/6/20",
    "1972/6/21",
    "1972/6/22",
    "1972/6/23",
    "1972/6/24",
    "1972/6/25",
    "1972/6/26",
    "1972/6/27",
    "1972/6/28",
    "1972/6/29",
    "1972/6/30",
    "1972/7/1",
    "1972/7/2",
    "1972/7/3",
    "1972/7/4",
    "1972/7/5",
    "1972/7/6",
    "1972/7/7",
    "1972/7/8",
    "1972/7/9",
    "1972/7/10",
    "1972/7/11",
    "1972/7/12",
    "1972/7/13",
    "1972/7/14",
    "1972/7/15",
    "1972/7/16",
    "1972/7/17",
    "1972/7/18",
    "1972/7/19",
    "1972/7/20",
    "1972/7/21",
    "1972/7/22",
    "1972/7/23",
    "1972/7/24",
    "1972/7/25",
    "1972/7/26",
    "1972/7/27",
    "1972/7/28",
    "1972/7/29",
    "1972/7/30",
    "1972/7/31",
    "1972/8/1",
    "1972/8/2",
    "1972/8/3",
    "1972/8/4",
    "1972/8/5",
    "1972/8/6",
    "1972/8/7",
    "1972/8/8",
    "1972/8/9",
    "1972/8/10",
    "1972/8/11",
    "1972/8/12",
    "1972/8/13",
    "1972/8/14",
    "1972/8/15",
    "1972/8/16",
    "1972/8/17",
    "1972/8/18",
    "1972/8/19",
    "1972/8/20",
    "1972/8/21",
    "1972/8/22",
    "1972/8/23",
    "1972/8/24",
    "1972/8/25",
    "1972/8/26",
    "1972/8/27",
    "1972/8/28",
    "1972/8/29",
    "1972/8/30",
    "1972/8/31",
    "1972/9/1",
    "1972/9/2",
    "1972/9/3",
    "1972/9/4",
    "1972/9/5",
    "1972/9/6",
    "1972/9/7",
    "1972/9/8",
    "1972/9/9",
    "1972/9/10",
    "1972/9/11",
    "1972/9/12",
    "1972/9/13",
    "1972/9/14",
    "1972/9/15",
    "1972/9/16",
    "1972/9/17",
    "1972/9/18",
    "1972/9/19",
    "1972/9/20",
    "1972/9/21",
    "1972/9/22",
    "1972/9/23",
    "1972/9/24",
    "1972/9/25",
    "1972/9/26",
    "1972/9/27",
    "1972/9/28",
    "1972/9/29",
    "1972/9/30",
    "1972/10/1",
    "1972/10/2",
    "1972/10/3",
    "1972/10/4",
    "1972/10/5",
    "1972/10/6",
    "1972/10/7",
    "1972/10/8",
    "1972/10/9",
    "1972/10/10",
    "1972/10/11",
    "1972/10/12",
    "1972/10/13",
    "1972/10/14",
    "1972/10/15",
    "1972/10/16",
    "1972/10/17",
    "1972/10/18",
    "1972/10/19",
    "1972/10/20",
    "1972/10/21",
    "1972/10/22",
    "1972/10/23",
    "1972/10/24",
    "1972/10/25",
    "1972/10/26",
    "1972/10/27",
    "1972/10/28",
    "1972/10/29",
    "1972/10/30",
    "1972/10/31",
    "1972/11/1",
    "1972/11/2",
    "1972/11/3",
    "1972/11/4",
    "1972/11/5",
    "1972/11/6",
    "1972/11/7",
    "1972/11/8",
    "1972/11/9",
    "1972/11/10",
    "1972/11/11",
    "1972/11/12",
    "1972/11/13",
    "1972/11/14",
    "1972/11/15",
    "1972/11/16",
    "1972/11/17",
    "1972/11/18",
    "1972/11/19",
    "1972/11/20",
    "1972/11/21",
    "1972/11/22",
    "1972/11/23",
    "1972/11/24",
    "1972/11/25",
    "1972/11/26",
    "1972/11/27",
    "1972/11/28",
    "1972/11/29",
    "1972/11/30",
    "1972/12/1",
    "1972/12/2",
    "1972/12/3",
    "1972/12/4",
    "1972/12/5",
    "1972/12/6",
    "1972/12/7",
    "1972/12/8",
    "1972/12/9",
    "1972/12/10",
    "1972/12/11",
    "1972/12/12",
    "1972/12/13",
    "1972/12/14",
    "1972/12/15",
    "1972/12/16",
    "1972/12/17",
    "1972/12/18",
    "1972/12/19",
    "1972/12/20",
    "1972/12/21",
    "1972/12/22",
    "1972/12/23",
    "1972/12/24",
    "1972/12/25",
    "1972/12/26",
    "1972/12/27",
    "1972/12/28",
    "1972/12/29",
    "1972/12/30",
    "1972/12/31",
    "1973/1/1",
    "1973/1/2",
    "1973/1/3",
    "1973/1/4",
    "1973/1/5",
    "1973/1/6",
    "1973/1/7",
    "1973/1/8",
    "1973/1/9",
    "1973/1/10",
    "1973/1/11",
    "1973/1/12",
    "1973/1/13",
    "1973/1/14",
    "1973/1/15",
    "1973/1/16",
    "1973/1/17",
    "1973/1/18",
    "1973/1/19",
    "1973/1/20",
    "1973/1/21",
    "1973/1/22",
    "1973/1/23",
    "1973/1/24",
    "1973/1/25",
    "1973/1/26",
    "1973/1/27",
    "1973/1/28",
    "1973/1/29",
    "1973/1/30",
    "1973/1/31",
    "1973/2/1",
    "1973/2/2",
    "1973/2/3",
    "1973/2/4",
    "1973/2/5",
    "1973/2/6",
    "1973/2/7",
    "1973/2/8",
    "1973/2/9",
    "1973/2/10",
    "1973/2/11",
    "1973/2/12",
    "1973/2/13",
    "1973/2/14",
    "1973/2/15",
    "1973/2/16",
    "1973/2/17",
    "1973/2/18",
    "1973/2/19",
    "1973/2/20",
    "1973/2/21",
    "1973/2/22",
    "1973/2/23",
    "1973/2/24",
    "1973/2/25",
    "1973/2/26",
    "1973/2/27",
    "1973/2/28",
    "1973/3/1",
    "1973/3/2",
    "1973/3/3",
    "1973/3/4",
    "1973/3/5",
    "1973/3/6",
    "1973/3/7",
    "1973/3/8",
    "1973/3/9",
    "1973/3/10",
    "1973/3/11",
    "1973/3/12",
    "1973/3/13",
    "1973/3/14",
    "1973/3/15",
    "1973/3/16",
    "1973/3/17",
    "1973/3/18",
    "1973/3/19",
    "1973/3/20",
    "1973/3/21",
    "1973/3/22",
    "1973/3/23",
    "1973/3/24",
    "1973/3/25",
    "1973/3/26",
    "1973/3/27",
    "1973/3/28",
    "1973/3/29",
    "1973/3/30",
    "1973/3/31",
    "1973/4/1",
    "1973/4/2",
    "1973/4/3",
    "1973/4/4",
    "1973/4/5",
    "1973/4/6",
    "1973/4/7",
    "1973/4/8",
    "1973/4/9",
    "1973/4/10",
    "1973/4/11",
    "1973/4/12",
    "1973/4/13",
    "1973/4/14",
    "1973/4/15",
    "1973/4/16",
    "1973/4/17",
    "1973/4/18",
    "1973/4/19",
    "1973/4/20",
    "1973/4/21",
    "1973/4/22",
    "1973/4/23",
    "1973/4/24",
    "1973/4/25",
    "1973/4/26",
    "1973/4/27",
    "1973/4/28",
    "1973/4/29",
    "1973/4/30",
    "1973/5/1",
    "1973/5/2",
    "1973/5/3",
    "1973/5/4",
    "1973/5/5",
    "1973/5/6",
    "1973/5/7",
    "1973/5/8",
    "1973/5/9",
    "1973/5/10",
    "1973/5/11",
    "1973/5/12",
    "1973/5/13",
    "1973/5/14",
    "1973/5/15",
    "1973/5/16",
    "1973/5/17",
    "1973/5/18",
    "1973/5/19",
    "1973/5/20",
    "1973/5/21",
    "1973/5/22",
    "1973/5/23",
    "1973/5/24",
    "1973/5/25",
    "1973/5/26",
    "1973/5/27",
    "1973/5/28",
    "1973/5/29",
    "1973/5/30",
    "1973/5/31",
    "1973/6/1",
    "1973/6/2",
    "1973/6/3",
    "1973/6/4",
    "1973/6/5",
    "1973/6/6",
    "1973/6/7",
    "1973/6/8",
    "1973/6/9",
    "1973/6/10",
    "1973/6/11",
    "1973/6/12",
    "1973/6/13",
    "1973/6/14",
    "1973/6/15",
    "1973/6/16",
    "1973/6/17",
    "1973/6/18",
    "1973/6/19",
    "1973/6/20",
    "1973/6/21",
    "1973/6/22",
    "1973/6/23",
    "1973/6/24",
    "1973/6/25",
    "1973/6/26",
    "1973/6/27",
    "1973/6/28",
    "1973/6/29",
    "1973/6/30",
    "1973/7/1",
    "1973/7/2",
    "1973/7/3",
    "1973/7/4",
    "1973/7/5",
    "1973/7/6",
    "1973/7/7",
    "1973/7/8",
    "1973/7/9",
    "1973/7/10",
    "1973/7/11",
    "1973/7/12",
    "1973/7/13",
    "1973/7/14",
    "1973/7/15",
    "1973/7/16",
    "1973/7/17",
    "1973/7/18",
    "1973/7/19",
    "1973/7/20",
    "1973/7/21",
    "1973/7/22",
    "1973/7/23",
    "1973/7/24",
    "1973/7/25",
    "1973/7/26",
    "1973/7/27",
    "1973/7/28",
    "1973/7/29",
    "1973/7/30",
    "1973/7/31",
    "1973/8/1",
    "1973/8/2",
    "1973/8/3",
    "1973/8/4",
    "1973/8/5",
    "1973/8/6",
    "1973/8/7",
    "1973/8/8",
    "1973/8/9",
    "1973/8/10",
    "1973/8/11",
    "1973/8/12",
    "1973/8/13",
    "1973/8/14",
    "1973/8/15",
    "1973/8/16",
    "1973/8/17",
    "1973/8/18",
    "1973/8/19",
    "1973/8/20",
    "1973/8/21",
    "1973/8/22",
    "1973/8/23",
    "1973/8/24",
    "1973/8/25",
    "1973/8/26",
    "1973/8/27",
    "1973/8/28",
    "1973/8/29",
    "1973/8/30",
    "1973/8/31",
    "1973/9/1",
    "1973/9/2",
    "1973/9/3",
    "1973/9/4",
    "1973/9/5",
    "1973/9/6",
    "1973/9/7",
    "1973/9/8",
    "1973/9/9",
    "1973/9/10",
    "1973/9/11",
    "1973/9/12",
    "1973/9/13",
    "1973/9/14",
    "1973/9/15",
    "1973/9/16",
    "1973/9/17",
    "1973/9/18",
    "1973/9/19",
    "1973/9/20",
    "1973/9/21",
    "1973/9/22",
    "1973/9/23",
    "1973/9/24",
    "1973/9/25",
    "1973/9/26",
    "1973/9/27",
    "1973/9/28",
    "1973/9/29",
    "1973/9/30",
    "1973/10/1",
    "1973/10/2",
    "1973/10/3",
    "1973/10/4",
    "1973/10/5",
    "1973/10/6",
    "1973/10/7",
    "1973/10/8",
    "1973/10/9",
    "1973/10/10",
    "1973/10/11",
    "1973/10/12",
    "1973/10/13",
    "1973/10/14",
    "1973/10/15",
    "1973/10/16",
    "1973/10/17",
    "1973/10/18",
    "1973/10/19",
    "1973/10/20",
    "1973/10/21",
    "1973/10/22",
    "1973/10/23",
    "1973/10/24",
    "1973/10/25",
    "1973/10/26",
    "1973/10/27",
    "1973/10/28",
    "1973/10/29",
    "1973/10/30",
    "1973/10/31",
    "1973/11/1",
    "1973/11/2",
    "1973/11/3",
    "1973/11/4",
    "1973/11/5",
    "1973/11/6",
    "1973/11/7",
    "1973/11/8",
    "1973/11/9",
    "1973/11/10",
    "1973/11/11",
    "1973/11/12",
    "1973/11/13",
    "1973/11/14",
    "1973/11/15",
    "1973/11/16",
    "1973/11/17",
    "1973/11/18",
    "1973/11/19",
    "1973/11/20",
    "1973/11/21",
    "1973/11/22",
    "1973/11/23",
    "1973/11/24",
    "1973/11/25",
    "1973/11/26",
    "1973/11/27",
    "1973/11/28",
    "1973/11/29",
    "1973/11/30",
    "1973/12/1",
    "1973/12/2",
    "1973/12/3",
    "1973/12/4",
    "1973/12/5",
    "1973/12/6",
    "1973/12/7",
    "1973/12/8",
    "1973/12/9",
    "1973/12/10",
    "1973/12/11",
    "1973/12/12",
    "1973/12/13",
    "1973/12/14",
    "1973/12/15",
    "1973/12/16",
    "1973/12/17",
    "1973/12/18",
    "1973/12/19",
    "1973/12/20",
    "1973/12/21",
    "1973/12/22",
    "1973/12/23",
    "1973/12/24",
    "1973/12/25",
    "1973/12/26",
    "1973/12/27",
    "1973/12/28",
    "1973/12/29",
    "1973/12/30",
    "1973/12/31",
    "1974/1/1",
    "1974/1/2",
    "1974/1/3",
    "1974/1/4",
    "1974/1/5",
    "1974/1/6",
    "1974/1/7",
    "1974/1/8",
    "1974/1/9",
    "1974/1/10",
    "1974/1/11",
    "1974/1/12",
    "1974/1/13",
    "1974/1/14",
    "1974/1/15",
    "1974/1/16",
    "1974/1/17",
    "1974/1/18",
    "1974/1/19",
    "1974/1/20",
    "1974/1/21",
    "1974/1/22",
    "1974/1/23",
    "1974/1/24",
    "1974/1/25",
    "1974/1/26",
    "1974/1/27",
    "1974/1/28",
    "1974/1/29",
    "1974/1/30",
    "1974/1/31",
    "1974/2/1",
    "1974/2/2",
    "1974/2/3",
    "1974/2/4",
    "1974/2/5",
    "1974/2/6",
    "1974/2/7",
    "1974/2/8",
    "1974/2/9",
    "1974/2/10",
    "1974/2/11",
    "1974/2/12",
    "1974/2/13",
    "1974/2/14",
    "1974/2/15",
    "1974/2/16",
    "1974/2/17",
    "1974/2/18",
    "1974/2/19",
    "1974/2/20",
    "1974/2/21",
    "1974/2/22",
    "1974/2/23",
    "1974/2/24",
    "1974/2/25",
    "1974/2/26",
    "1974/2/27",
    "1974/2/28",
    "1974/3/1",
    "1974/3/2",
    "1974/3/3",
    "1974/3/4",
    "1974/3/5",
    "1974/3/6",
    "1974/3/7",
    "1974/3/8",
    "1974/3/9",
    "1974/3/10",
    "1974/3/11",
    "1974/3/12",
    "1974/3/13",
    "1974/3/14",
    "1974/3/15",
    "1974/3/16",
    "1974/3/17",
    "1974/3/18",
    "1974/3/19",
    "1974/3/20",
    "1974/3/21",
    "1974/3/22",
    "1974/3/23",
    "1974/3/24",
    "1974/3/25",
    "1974/3/26",
    "1974/3/27",
    "1974/3/28",
    "1974/3/29",
    "1974/3/30",
    "1974/3/31",
    "1974/4/1",
    "1974/4/2",
    "1974/4/3",
    "1974/4/4",
    "1974/4/5",
    "1974/4/6",
    "1974/4/7",
    "1974/4/8",
    "1974/4/9",
    "1974/4/10",
    "1974/4/11",
    "1974/4/12",
    "1974/4/13",
    "1974/4/14",
    "1974/4/15",
    "1974/4/16",
    "1974/4/17",
    "1974/4/18",
    "1974/4/19",
    "1974/4/20",
    "1974/4/21",
    "1974/4/22",
    "1974/4/23",
    "1974/4/24",
    "1974/4/25",
    "1974/4/26",
    "1974/4/27",
    "1974/4/28",
    "1974/4/29",
    "1974/4/30",
    "1974/5/1",
    "1974/5/2",
    "1974/5/3",
    "1974/5/4",
    "1974/5/5",
    "1974/5/6",
    "1974/5/7",
    "1974/5/8",
    "1974/5/9",
    "1974/5/10",
    "1974/5/11",
    "1974/5/12",
    "1974/5/13",
    "1974/5/14",
    "1974/5/15",
    "1974/5/16",
    "1974/5/17",
    "1974/5/18",
    "1974/5/19",
    "1974/5/20",
    "1974/5/21",
    "1974/5/22",
    "1974/5/23",
    "1974/5/24",
    "1974/5/25",
    "1974/5/26",
    "1974/5/27",
    "1974/5/28",
    "1974/5/29",
    "1974/5/30",
    "1974/5/31",
    "1974/6/1",
    "1974/6/2",
    "1974/6/3",
    "1974/6/4",
    "1974/6/5",
    "1974/6/6",
    "1974/6/7",
    "1974/6/8",
    "1974/6/9",
    "1974/6/10",
    "1974/6/11",
    "1974/6/12",
    "1974/6/13",
    "1974/6/14",
    "1974/6/15",
    "1974/6/16",
    "1974/6/17",
    "1974/6/18",
    "1974/6/19",
    "1974/6/20",
    "1974/6/21",
    "1974/6/22",
    "1974/6/23",
    "1974/6/24",
    "1974/6/25",
    "1974/6/26",
    "1974/6/27",
    "1974/6/28",
    "1974/6/29",
    "1974/6/30",
    "1974/7/1",
    "1974/7/2",
    "1974/7/3",
    "1974/7/4",
    "1974/7/5",
    "1974/7/6",
    "1974/7/7",
    "1974/7/8",
    "1974/7/9",
    "1974/7/10",
    "1974/7/11",
    "1974/7/12",
    "1974/7/13",
    "1974/7/14",
    "1974/7/15",
    "1974/7/16",
    "1974/7/17",
    "1974/7/18",
    "1974/7/19",
    "1974/7/20",
    "1974/7/21",
    "1974/7/22",
    "1974/7/23",
    "1974/7/24",
    "1974/7/25",
    "1974/7/26",
    "1974/7/27",
    "1974/7/28",
    "1974/7/29",
    "1974/7/30",
    "1974/7/31",
    "1974/8/1",
    "1974/8/2",
    "1974/8/3",
    "1974/8/4",
    "1974/8/5",
    "1974/8/6",
    "1974/8/7",
    "1974/8/8",
    "1974/8/9",
    "1974/8/10",
    "1974/8/11",
    "1974/8/12",
    "1974/8/13",
    "1974/8/14",
    "1974/8/15",
    "1974/8/16",
    "1974/8/17",
    "1974/8/18",
    "1974/8/19",
    "1974/8/20",
    "1974/8/21",
    "1974/8/22",
    "1974/8/23",
    "1974/8/24",
    "1974/8/25",
    "1974/8/26",
    "1974/8/27",
    "1974/8/28",
    "1974/8/29",
    "1974/8/30",
    "1974/8/31",
    "1974/9/1",
    "1974/9/2",
    "1974/9/3",
    "1974/9/4",
    "1974/9/5",
    "1974/9/6",
    "1974/9/7",
    "1974/9/8",
    "1974/9/9",
    "1974/9/10",
    "1974/9/11",
    "1974/9/12",
    "1974/9/13",
    "1974/9/14",
    "1974/9/15",
    "1974/9/16",
    "1974/9/17",
    "1974/9/18",
    "1974/9/19",
    "1974/9/20",
    "1974/9/21",
    "1974/9/22",
    "1974/9/23",
    "1974/9/24",
    "1974/9/25",
    "1974/9/26",
    "1974/9/27",
    "1974/9/28",
    "1974/9/29",
    "1974/9/30",
    "1974/10/1",
    "1974/10/2",
    "1974/10/3",
    "1974/10/4",
    "1974/10/5",
    "1974/10/6",
    "1974/10/7",
    "1974/10/8",
    "1974/10/9",
    "1974/10/10",
    "1974/10/11",
    "1974/10/12",
    "1974/10/13",
    "1974/10/14",
    "1974/10/15",
    "1974/10/16",
    "1974/10/17",
    "1974/10/18",
    "1974/10/19",
    "1974/10/20",
    "1974/10/21",
    "1974/10/22",
    "1974/10/23",
    "1974/10/24",
    "1974/10/25",
    "1974/10/26",
    "1974/10/27",
    "1974/10/28",
    "1974/10/29",
    "1974/10/30",
    "1974/10/31",
    "1974/11/1",
    "1974/11/2",
    "1974/11/3",
    "1974/11/4",
    "1974/11/5",
    "1974/11/6",
    "1974/11/7",
    "1974/11/8",
    "1974/11/9",
    "1974/11/10",
    "1974/11/11",
    "1974/11/12",
    "1974/11/13",
    "1974/11/14",
    "1974/11/15",
    "1974/11/16",
    "1974/11/17",
    "1974/11/18",
    "1974/11/19",
    "1974/11/20",
    "1974/11/21",
    "1974/11/22",
    "1974/11/23",
    "1974/11/24",
    "1974/11/25",
    "1974/11/26",
    "1974/11/27",
    "1974/11/28",
    "1974/11/29",
    "1974/11/30",
    "1974/12/1",
    "1974/12/2",
    "1974/12/3",
    "1974/12/4",
    "1974/12/5",
    "1974/12/6",
    "1974/12/7",
    "1974/12/8",
    "1974/12/9",
    "1974/12/10",
    "1974/12/11",
    "1974/12/12",
    "1974/12/13",
    "1974/12/14",
    "1974/12/15",
    "1974/12/16",
    "1974/12/17",
    "1974/12/18",
    "1974/12/19",
    "1974/12/20",
    "1974/12/21",
    "1974/12/22",
    "1974/12/23",
    "1974/12/24",
    "1974/12/25",
    "1974/12/26",
    "1974/12/27",
    "1974/12/28",
    "1974/12/29",
    "1974/12/30",
    "1974/12/31",
    "1975/1/1",
    "1975/1/2",
    "1975/1/3",
    "1975/1/4",
    "1975/1/5",
    "1975/1/6",
    "1975/1/7",
    "1975/1/8",
    "1975/1/9",
    "1975/1/10",
    "1975/1/11",
    "1975/1/12",
    "1975/1/13",
    "1975/1/14",
    "1975/1/15",
    "1975/1/16",
    "1975/1/17",
    "1975/1/18",
    "1975/1/19",
    "1975/1/20",
    "1975/1/21",
    "1975/1/22",
    "1975/1/23",
    "1975/1/24",
    "1975/1/25",
    "1975/1/26",
    "1975/1/27",
    "1975/1/28",
    "1975/1/29",
    "1975/1/30",
    "1975/1/31",
    "1975/2/1",
    "1975/2/2",
    "1975/2/3",
    "1975/2/4",
    "1975/2/5",
    "1975/2/6",
    "1975/2/7",
    "1975/2/8",
    "1975/2/9",
    "1975/2/10",
    "1975/2/11",
    "1975/2/12",
    "1975/2/13",
    "1975/2/14",
    "1975/2/15",
    "1975/2/16",
    "1975/2/17",
    "1975/2/18",
    "1975/2/19",
    "1975/2/20",
    "1975/2/21",
    "1975/2/22",
    "1975/2/23",
    "1975/2/24",
    "1975/2/25",
    "1975/2/26",
    "1975/2/27",
    "1975/2/28",
    "1975/3/1",
    "1975/3/2",
    "1975/3/3",
    "1975/3/4",
    "1975/3/5",
    "1975/3/6",
    "1975/3/7",
    "1975/3/8",
    "1975/3/9",
    "1975/3/10",
    "1975/3/11",
    "1975/3/12",
    "1975/3/13",
    "1975/3/14",
    "1975/3/15",
    "1975/3/16",
    "1975/3/17",
    "1975/3/18",
    "1975/3/19",
    "1975/3/20",
    "1975/3/21",
    "1975/3/22",
    "1975/3/23",
    "1975/3/24",
    "1975/3/25",
    "1975/3/26",
    "1975/3/27",
    "1975/3/28",
    "1975/3/29",
    "1975/3/30",
    "1975/3/31",
    "1975/4/1",
    "1975/4/2",
    "1975/4/3",
    "1975/4/4",
    "1975/4/5",
    "1975/4/6",
    "1975/4/7",
    "1975/4/8",
    "1975/4/9",
    "1975/4/10",
    "1975/4/11",
    "1975/4/12",
    "1975/4/13",
    "1975/4/14",
    "1975/4/15",
    "1975/4/16",
    "1975/4/17",
    "1975/4/18",
    "1975/4/19",
    "1975/4/20",
    "1975/4/21",
    "1975/4/22",
    "1975/4/23",
    "1975/4/24",
    "1975/4/25",
    "1975/4/26",
    "1975/4/27",
    "1975/4/28",
    "1975/4/29",
    "1975/4/30",
    "1975/5/1",
    "1975/5/2",
    "1975/5/3",
    "1975/5/4",
    "1975/5/5",
    "1975/5/6",
    "1975/5/7",
    "1975/5/8",
    "1975/5/9",
    "1975/5/10",
    "1975/5/11",
    "1975/5/12",
    "1975/5/13",
    "1975/5/14",
    "1975/5/15",
    "1975/5/16",
    "1975/5/17",
    "1975/5/18",
    "1975/5/19",
    "1975/5/20",
    "1975/5/21",
    "1975/5/22",
    "1975/5/23",
    "1975/5/24",
    "1975/5/25",
    "1975/5/26",
    "1975/5/27",
    "1975/5/28",
    "1975/5/29",
    "1975/5/30",
    "1975/5/31",
    "1975/6/1",
    "1975/6/2",
    "1975/6/3",
    "1975/6/4",
    "1975/6/5",
    "1975/6/6",
    "1975/6/7",
    "1975/6/8",
    "1975/6/9",
    "1975/6/10",
    "1975/6/11",
    "1975/6/12",
    "1975/6/13",
    "1975/6/14",
    "1975/6/15",
    "1975/6/16",
    "1975/6/17",
    "1975/6/18",
    "1975/6/19",
    "1975/6/20",
    "1975/6/21",
    "1975/6/22",
    "1975/6/23",
    "1975/6/24",
    "1975/6/25",
    "1975/6/26",
    "1975/6/27",
    "1975/6/28",
    "1975/6/29",
    "1975/6/30",
    "1975/7/1",
    "1975/7/2",
    "1975/7/3",
    "1975/7/4",
    "1975/7/5",
    "1975/7/6",
    "1975/7/7",
    "1975/7/8",
    "1975/7/9",
    "1975/7/10",
    "1975/7/11",
    "1975/7/12",
    "1975/7/13",
    "1975/7/14",
    "1975/7/15",
    "1975/7/16",
    "1975/7/17",
    "1975/7/18",
    "1975/7/19",
    "1975/7/20",
    "1975/7/21",
    "1975/7/22",
    "1975/7/23",
    "1975/7/24",
    "1975/7/25",
    "1975/7/26",
    "1975/7/27",
    "1975/7/28",
    "1975/7/29",
    "1975/7/30",
    "1975/7/31",
    "1975/8/1",
    "1975/8/2",
    "1975/8/3",
    "1975/8/4",
    "1975/8/5",
    "1975/8/6",
    "1975/8/7",
    "1975/8/8",
    "1975/8/9",
    "1975/8/10",
    "1975/8/11",
    "1975/8/12",
    "1975/8/13",
    "1975/8/14",
    "1975/8/15",
    "1975/8/16",
    "1975/8/17",
    "1975/8/18",
    "1975/8/19",
    "1975/8/20",
    "1975/8/21",
    "1975/8/22",
    "1975/8/23",
    "1975/8/24",
    "1975/8/25",
    "1975/8/26",
    "1975/8/27",
    "1975/8/28",
    "1975/8/29",
    "1975/8/30",
    "1975/8/31",
    "1975/9/1",
    "1975/9/2",
    "1975/9/3",
    "1975/9/4",
    "1975/9/5",
    "1975/9/6",
    "1975/9/7",
    "1975/9/8",
    "1975/9/9",
    "1975/9/10",
    "1975/9/11",
    "1975/9/12",
    "1975/9/13",
    "1975/9/14",
    "1975/9/15",
    "1975/9/16",
    "1975/9/17",
    "1975/9/18",
    "1975/9/19",
    "1975/9/20",
    "1975/9/21",
    "1975/9/22",
    "1975/9/23",
    "1975/9/24",
    "1975/9/25",
    "1975/9/26",
    "1975/9/27",
    "1975/9/28",
    "1975/9/29",
    "1975/9/30",
    "1975/10/1",
    "1975/10/2",
    "1975/10/3",
    "1975/10/4",
    "1975/10/5",
    "1975/10/6",
    "1975/10/7",
    "1975/10/8",
    "1975/10/9",
    "1975/10/10",
    "1975/10/11",
    "1975/10/12",
    "1975/10/13",
    "1975/10/14",
    "1975/10/15",
    "1975/10/16",
    "1975/10/17",
    "1975/10/18",
    "1975/10/19",
    "1975/10/20",
    "1975/10/21",
    "1975/10/22",
    "1975/10/23",
    "1975/10/24",
    "1975/10/25",
    "1975/10/26",
    "1975/10/27",
    "1975/10/28",
    "1975/10/29",
    "1975/10/30",
    "1975/10/31",
    "1975/11/1",
    "1975/11/2",
    "1975/11/3",
    "1975/11/4",
    "1975/11/5",
    "1975/11/6",
    "1975/11/7",
    "1975/11/8",
    "1975/11/9",
    "1975/11/10",
    "1975/11/11",
    "1975/11/12",
    "1975/11/13",
    "1975/11/14",
    "1975/11/15",
    "1975/11/16",
    "1975/11/17",
    "1975/11/18",
    "1975/11/19",
    "1975/11/20",
    "1975/11/21",
    "1975/11/22",
    "1975/11/23",
    "1975/11/24",
    "1975/11/25",
    "1975/11/26",
    "1975/11/27",
    "1975/11/28",
    "1975/11/29",
    "1975/11/30",
    "1975/12/1",
    "1975/12/2",
    "1975/12/3",
    "1975/12/4",
    "1975/12/5",
    "1975/12/6",
    "1975/12/7",
    "1975/12/8",
    "1975/12/9",
    "1975/12/10",
    "1975/12/11",
    "1975/12/12",
    "1975/12/13",
    "1975/12/14",
    "1975/12/15",
    "1975/12/16",
    "1975/12/17",
    "1975/12/18",
    "1975/12/19",
    "1975/12/20",
    "1975/12/21",
    "1975/12/22",
    "1975/12/23",
    "1975/12/24",
    "1975/12/25",
    "1975/12/26",
    "1975/12/27",
    "1975/12/28",
    "1975/12/29",
    "1975/12/30",
    "1975/12/31",
    "1976/1/1",
    "1976/1/2",
    "1976/1/3",
    "1976/1/4",
    "1976/1/5",
    "1976/1/6",
    "1976/1/7",
    "1976/1/8",
    "1976/1/9",
    "1976/1/10",
    "1976/1/11",
    "1976/1/12",
    "1976/1/13",
    "1976/1/14",
    "1976/1/15",
    "1976/1/16",
    "1976/1/17",
    "1976/1/18",
    "1976/1/19",
    "1976/1/20",
    "1976/1/21",
    "1976/1/22",
    "1976/1/23",
    "1976/1/24",
    "1976/1/25",
    "1976/1/26",
    "1976/1/27",
    "1976/1/28",
    "1976/1/29",
    "1976/1/30",
    "1976/1/31",
    "1976/2/1",
    "1976/2/2",
    "1976/2/3",
    "1976/2/4",
    "1976/2/5",
    "1976/2/6",
    "1976/2/7",
    "1976/2/8",
    "1976/2/9",
    "1976/2/10",
    "1976/2/11",
    "1976/2/12",
    "1976/2/13",
    "1976/2/14",
    "1976/2/15",
    "1976/2/16",
    "1976/2/17",
    "1976/2/18",
    "1976/2/19",
    "1976/2/20",
    "1976/2/21",
    "1976/2/22",
    "1976/2/23",
    "1976/2/24",
    "1976/2/25",
    "1976/2/26",
    "1976/2/27",
    "1976/2/28",
    "1976/2/29",
    "1976/3/1",
    "1976/3/2",
    "1976/3/3",
    "1976/3/4",
    "1976/3/5",
    "1976/3/6",
    "1976/3/7",
    "1976/3/8",
    "1976/3/9",
    "1976/3/10",
    "1976/3/11",
    "1976/3/12",
    "1976/3/13",
    "1976/3/14",
    "1976/3/15",
    "1976/3/16",
    "1976/3/17",
    "1976/3/18",
    "1976/3/19",
    "1976/3/20",
    "1976/3/21",
    "1976/3/22",
    "1976/3/23",
    "1976/3/24",
    "1976/3/25",
    "1976/3/26",
    "1976/3/27",
    "1976/3/28",
    "1976/3/29",
    "1976/3/30",
    "1976/3/31",
    "1976/4/1",
    "1976/4/2",
    "1976/4/3",
    "1976/4/4",
    "1976/4/5",
    "1976/4/6",
    "1976/4/7",
    "1976/4/8",
    "1976/4/9",
    "1976/4/10",
    "1976/4/11",
    "1976/4/12",
    "1976/4/13",
    "1976/4/14",
    "1976/4/15",
    "1976/4/16",
    "1976/4/17",
    "1976/4/18",
    "1976/4/19",
    "1976/4/20",
    "1976/4/21",
    "1976/4/22",
    "1976/4/23",
    "1976/4/24",
    "1976/4/25",
    "1976/4/26",
    "1976/4/27",
    "1976/4/28",
    "1976/4/29",
    "1976/4/30",
    "1976/5/1",
    "1976/5/2",
    "1976/5/3",
    "1976/5/4",
    "1976/5/5",
    "1976/5/6",
    "1976/5/7",
    "1976/5/8",
    "1976/5/9",
    "1976/5/10",
    "1976/5/11",
    "1976/5/12",
    "1976/5/13",
    "1976/5/14",
    "1976/5/15",
    "1976/5/16",
    "1976/5/17",
    "1976/5/18",
    "1976/5/19",
    "1976/5/20",
    "1976/5/21",
    "1976/5/22",
    "1976/5/23",
    "1976/5/24",
    "1976/5/25",
    "1976/5/26",
    "1976/5/27",
    "1976/5/28",
    "1976/5/29",
    "1976/5/30",
    "1976/5/31",
    "1976/6/1",
    "1976/6/2",
    "1976/6/3",
    "1976/6/4",
    "1976/6/5",
    "1976/6/6",
    "1976/6/7",
    "1976/6/8",
    "1976/6/9",
    "1976/6/10",
    "1976/6/11",
    "1976/6/12",
    "1976/6/13",
    "1976/6/14",
    "1976/6/15",
    "1976/6/16",
    "1976/6/17",
    "1976/6/18",
    "1976/6/19",
    "1976/6/20",
    "1976/6/21",
    "1976/6/22",
    "1976/6/23",
    "1976/6/24",
    "1976/6/25",
    "1976/6/26",
    "1976/6/27",
    "1976/6/28",
    "1976/6/29",
    "1976/6/30",
    "1976/7/1",
    "1976/7/2",
    "1976/7/3",
    "1976/7/4",
    "1976/7/5",
    "1976/7/6",
    "1976/7/7",
    "1976/7/8",
    "1976/7/9",
    "1976/7/10",
    "1976/7/11",
    "1976/7/12",
    "1976/7/13",
    "1976/7/14",
    "1976/7/15",
    "1976/7/16",
    "1976/7/17",
    "1976/7/18",
    "1976/7/19",
    "1976/7/20",
    "1976/7/21",
    "1976/7/22",
    "1976/7/23",
    "1976/7/24",
    "1976/7/25",
    "1976/7/26",
    "1976/7/27",
    "1976/7/28",
    "1976/7/29",
    "1976/7/30",
    "1976/7/31",
    "1976/8/1",
    "1976/8/2",
    "1976/8/3",
    "1976/8/4",
    "1976/8/5",
    "1976/8/6",
    "1976/8/7",
    "1976/8/8",
    "1976/8/9",
    "1976/8/10",
    "1976/8/11",
    "1976/8/12",
    "1976/8/13",
    "1976/8/14",
    "1976/8/15",
    "1976/8/16",
    "1976/8/17",
    "1976/8/18",
    "1976/8/19",
    "1976/8/20",
    "1976/8/21",
    "1976/8/22",
    "1976/8/23",
    "1976/8/24",
    "1976/8/25",
    "1976/8/26",
    "1976/8/27",
    "1976/8/28",
    "1976/8/29",
    "1976/8/30",
    "1976/8/31",
    "1976/9/1",
    "1976/9/2",
    "1976/9/3",
    "1976/9/4",
    "1976/9/5",
    "1976/9/6",
    "1976/9/7",
    "1976/9/8",
    "1976/9/9",
    "1976/9/10",
    "1976/9/11",
    "1976/9/12",
    "1976/9/13",
    "1976/9/14",
    "1976/9/15",
    "1976/9/16",
    "1976/9/17",
    "1976/9/18",
    "1976/9/19",
    "1976/9/20",
    "1976/9/21",
    "1976/9/22",
    "1976/9/23",
    "1976/9/24",
    "1976/9/25",
    "1976/9/26",
    "1976/9/27",
    "1976/9/28",
    "1976/9/29",
    "1976/9/30",
    "1976/10/1",
    "1976/10/2",
    "1976/10/3",
    "1976/10/4",
    "1976/10/5",
    "1976/10/6",
    "1976/10/7",
    "1976/10/8",
    "1976/10/9",
    "1976/10/10",
    "1976/10/11",
    "1976/10/12",
    "1976/10/13",
    "1976/10/14",
    "1976/10/15",
    "1976/10/16",
    "1976/10/17",
    "1976/10/18",
    "1976/10/19",
    "1976/10/20",
    "1976/10/21",
    "1976/10/22",
    "1976/10/23",
    "1976/10/24",
    "1976/10/25",
    "1976/10/26",
    "1976/10/27",
    "1976/10/28",
    "1976/10/29",
    "1976/10/30",
    "1976/10/31",
    "1976/11/1",
    "1976/11/2",
    "1976/11/3",
    "1976/11/4",
    "1976/11/5",
    "1976/11/6",
    "1976/11/7",
    "1976/11/8",
    "1976/11/9",
    "1976/11/10",
    "1976/11/11",
    "1976/11/12",
    "1976/11/13",
    "1976/11/14",
    "1976/11/15",
    "1976/11/16",
    "1976/11/17",
    "1976/11/18",
    "1976/11/19",
    "1976/11/20",
    "1976/11/21",
    "1976/11/22",
    "1976/11/23",
    "1976/11/24",
    "1976/11/25",
    "1976/11/26",
    "1976/11/27",
    "1976/11/28",
    "1976/11/29",
    "1976/11/30",
    "1976/12/1",
    "1976/12/2",
    "1976/12/3",
    "1976/12/4",
    "1976/12/5",
    "1976/12/6",
    "1976/12/7",
    "1976/12/8",
    "1976/12/9",
    "1976/12/10",
    "1976/12/11",
    "1976/12/12",
    "1976/12/13",
    "1976/12/14",
    "1976/12/15",
    "1976/12/16",
    "1976/12/17",
    "1976/12/18",
    "1976/12/19",
    "1976/12/20",
    "1976/12/21",
    "1976/12/22",
    "1976/12/23",
    "1976/12/24",
    "1976/12/25",
    "1976/12/26",
    "1976/12/27",
    "1976/12/28",
    "1976/12/29",
    "1976/12/30",
    "1976/12/31",
    "1977/1/1",
    "1977/1/2",
    "1977/1/3",
    "1977/1/4",
    "1977/1/5",
    "1977/1/6",
    "1977/1/7",
    "1977/1/8",
    "1977/1/9",
    "1977/1/10",
    "1977/1/11",
    "1977/1/12",
    "1977/1/13",
    "1977/1/14",
    "1977/1/15",
    "1977/1/16",
    "1977/1/17",
    "1977/1/18",
    "1977/1/19",
    "1977/1/20",
    "1977/1/21",
    "1977/1/22",
    "1977/1/23",
    "1977/1/24",
    "1977/1/25",
    "1977/1/26",
    "1977/1/27",
    "1977/1/28",
    "1977/1/29",
    "1977/1/30",
    "1977/1/31",
    "1977/2/1",
    "1977/2/2",
    "1977/2/3",
    "1977/2/4",
    "1977/2/5",
    "1977/2/6",
    "1977/2/7",
    "1977/2/8",
    "1977/2/9",
    "1977/2/10",
    "1977/2/11",
    "1977/2/12",
    "1977/2/13",
    "1977/2/14",
    "1977/2/15",
    "1977/2/16",
    "1977/2/17",
    "1977/2/18",
    "1977/2/19",
    "1977/2/20",
    "1977/2/21",
    "1977/2/22",
    "1977/2/23",
    "1977/2/24",
    "1977/2/25",
    "1977/2/26",
    "1977/2/27",
    "1977/2/28",
    "1977/3/1",
    "1977/3/2",
    "1977/3/3",
    "1977/3/4",
    "1977/3/5",
    "1977/3/6",
    "1977/3/7",
    "1977/3/8",
    "1977/3/9",
    "1977/3/10",
    "1977/3/11",
    "1977/3/12",
    "1977/3/13",
    "1977/3/14",
    "1977/3/15",
    "1977/3/16",
    "1977/3/17",
    "1977/3/18",
    "1977/3/19",
    "1977/3/20",
    "1977/3/21",
    "1977/3/22",
    "1977/3/23",
    "1977/3/24",
    "1977/3/25",
    "1977/3/26",
    "1977/3/27",
    "1977/3/28",
    "1977/3/29",
    "1977/3/30",
    "1977/3/31",
    "1977/4/1",
    "1977/4/2",
    "1977/4/3",
    "1977/4/4",
    "1977/4/5",
    "1977/4/6",
    "1977/4/7",
    "1977/4/8",
    "1977/4/9",
    "1977/4/10",
    "1977/4/11",
    "1977/4/12",
    "1977/4/13",
    "1977/4/14",
    "1977/4/15",
    "1977/4/16",
    "1977/4/17",
    "1977/4/18",
    "1977/4/19",
    "1977/4/20",
    "1977/4/21",
    "1977/4/22",
    "1977/4/23",
    "1977/4/24",
    "1977/4/25",
    "1977/4/26",
    "1977/4/27",
    "1977/4/28",
    "1977/4/29",
    "1977/4/30",
    "1977/5/1",
    "1977/5/2",
    "1977/5/3",
    "1977/5/4",
    "1977/5/5",
    "1977/5/6",
    "1977/5/7",
    "1977/5/8",
    "1977/5/9",
    "1977/5/10",
    "1977/5/11",
    "1977/5/12",
    "1977/5/13",
    "1977/5/14",
    "1977/5/15",
    "1977/5/16",
    "1977/5/17",
    "1977/5/18",
    "1977/5/19",
    "1977/5/20",
    "1977/5/21",
    "1977/5/22",
    "1977/5/23",
    "1977/5/24",
    "1977/5/25",
    "1977/5/26",
    "1977/5/27",
    "1977/5/28",
    "1977/5/29",
    "1977/5/30",
    "1977/5/31",
    "1977/6/1",
    "1977/6/2",
    "1977/6/3",
    "1977/6/4",
    "1977/6/5",
    "1977/6/6",
    "1977/6/7",
    "1977/6/8",
    "1977/6/9",
    "1977/6/10",
    "1977/6/11",
    "1977/6/12",
    "1977/6/13",
    "1977/6/14",
    "1977/6/15",
    "1977/6/16",
    "1977/6/17",
    "1977/6/18",
    "1977/6/19",
    "1977/6/20",
    "1977/6/21",
    "1977/6/22",
    "1977/6/23",
    "1977/6/24",
    "1977/6/25",
    "1977/6/26",
    "1977/6/27",
    "1977/6/28",
    "1977/6/29",
    "1977/6/30",
    "1977/7/1",
    "1977/7/2",
    "1977/7/3",
    "1977/7/4",
    "1977/7/5",
    "1977/7/6",
    "1977/7/7",
    "1977/7/8",
    "1977/7/9",
    "1977/7/10",
    "1977/7/11",
    "1977/7/12",
    "1977/7/13",
    "1977/7/14",
    "1977/7/15",
    "1977/7/16",
    "1977/7/17",
    "1977/7/18",
    "1977/7/19",
    "1977/7/20",
    "1977/7/21",
    "1977/7/22",
    "1977/7/23",
    "1977/7/24",
    "1977/7/25",
    "1977/7/26",
    "1977/7/27",
    "1977/7/28",
    "1977/7/29",
    "1977/7/30",
    "1977/7/31",
    "1977/8/1",
    "1977/8/2",
    "1977/8/3",
    "1977/8/4",
    "1977/8/5",
    "1977/8/6",
    "1977/8/7",
    "1977/8/8",
    "1977/8/9",
    "1977/8/10",
    "1977/8/11",
    "1977/8/12",
    "1977/8/13",
    "1977/8/14",
    "1977/8/15",
    "1977/8/16",
    "1977/8/17",
    "1977/8/18",
    "1977/8/19",
    "1977/8/20",
    "1977/8/21",
    "1977/8/22",
    "1977/8/23",
    "1977/8/24",
    "1977/8/25",
    "1977/8/26",
    "1977/8/27",
    "1977/8/28",
    "1977/8/29",
    "1977/8/30",
    "1977/8/31",
    "1977/9/1",
    "1977/9/2",
    "1977/9/3",
    "1977/9/4",
    "1977/9/5",
    "1977/9/6",
    "1977/9/7",
    "1977/9/8",
    "1977/9/9",
    "1977/9/10",
    "1977/9/11",
    "1977/9/12",
    "1977/9/13",
    "1977/9/14",
    "1977/9/15",
    "1977/9/16",
    "1977/9/17",
    "1977/9/18",
    "1977/9/19",
    "1977/9/20",
    "1977/9/21",
    "1977/9/22",
    "1977/9/23",
    "1977/9/24",
    "1977/9/25",
    "1977/9/26",
    "1977/9/27",
    "1977/9/28",
    "1977/9/29",
    "1977/9/30",
    "1977/10/1",
    "1977/10/2",
    "1977/10/3",
    "1977/10/4",
    "1977/10/5",
    "1977/10/6",
    "1977/10/7",
    "1977/10/8",
    "1977/10/9",
    "1977/10/10",
    "1977/10/11",
    "1977/10/12",
    "1977/10/13",
    "1977/10/14",
    "1977/10/15",
    "1977/10/16",
    "1977/10/17",
    "1977/10/18",
    "1977/10/19",
    "1977/10/20",
    "1977/10/21",
    "1977/10/22",
    "1977/10/23",
    "1977/10/24",
    "1977/10/25",
    "1977/10/26",
    "1977/10/27",
    "1977/10/28",
    "1977/10/29",
    "1977/10/30",
    "1977/10/31",
    "1977/11/1",
    "1977/11/2",
    "1977/11/3",
    "1977/11/4",
    "1977/11/5",
    "1977/11/6",
    "1977/11/7",
    "1977/11/8",
    "1977/11/9",
    "1977/11/10",
    "1977/11/11",
    "1977/11/12",
    "1977/11/13",
    "1977/11/14",
    "1977/11/15",
    "1977/11/16",
    "1977/11/17",
    "1977/11/18",
    "1977/11/19",
    "1977/11/20",
    "1977/11/21",
    "1977/11/22",
    "1977/11/23",
    "1977/11/24",
    "1977/11/25",
    "1977/11/26",
    "1977/11/27",
    "1977/11/28",
    "1977/11/29",
    "1977/11/30",
    "1977/12/1",
    "1977/12/2",
    "1977/12/3",
    "1977/12/4",
    "1977/12/5",
    "1977/12/6",
    "1977/12/7",
    "1977/12/8",
    "1977/12/9",
    "1977/12/10",
    "1977/12/11",
    "1977/12/12",
    "1977/12/13",
    "1977/12/14",
    "1977/12/15",
    "1977/12/16",
    "1977/12/17",
    "1977/12/18",
    "1977/12/19",
    "1977/12/20",
    "1977/12/21",
    "1977/12/22",
    "1977/12/23",
    "1977/12/24",
    "1977/12/25",
    "1977/12/26",
    "1977/12/27",
    "1977/12/28",
    "1977/12/29",
    "1977/12/30",
    "1977/12/31",
    "1978/1/1",
    "1978/1/2",
    "1978/1/3",
    "1978/1/4",
    "1978/1/5",
    "1978/1/6",
    "1978/1/7",
    "1978/1/8",
    "1978/1/9",
    "1978/1/10",
    "1978/1/11",
    "1978/1/12",
    "1978/1/13",
    "1978/1/14",
    "1978/1/15",
    "1978/1/16",
    "1978/1/17",
    "1978/1/18",
    "1978/1/19",
    "1978/1/20",
    "1978/1/21",
    "1978/1/22",
    "1978/1/23",
    "1978/1/24",
    "1978/1/25",
    "1978/1/26",
    "1978/1/27",
    "1978/1/28",
    "1978/1/29",
    "1978/1/30",
    "1978/1/31",
    "1978/2/1",
    "1978/2/2",
    "1978/2/3",
    "1978/2/4",
    "1978/2/5",
    "1978/2/6",
    "1978/2/7",
    "1978/2/8",
    "1978/2/9",
    "1978/2/10",
    "1978/2/11",
    "1978/2/12",
    "1978/2/13",
    "1978/2/14",
    "1978/2/15",
    "1978/2/16",
    "1978/2/17",
    "1978/2/18",
    "1978/2/19",
    "1978/2/20",
    "1978/2/21",
    "1978/2/22",
    "1978/2/23",
    "1978/2/24",
    "1978/2/25",
    "1978/2/26",
    "1978/2/27",
    "1978/2/28",
    "1978/3/1",
    "1978/3/2",
    "1978/3/3",
    "1978/3/4",
    "1978/3/5",
    "1978/3/6",
    "1978/3/7",
    "1978/3/8",
    "1978/3/9",
    "1978/3/10",
    "1978/3/11",
    "1978/3/12",
    "1978/3/13",
    "1978/3/14",
    "1978/3/15",
    "1978/3/16",
    "1978/3/17",
    "1978/3/18",
    "1978/3/19",
    "1978/3/20",
    "1978/3/21",
    "1978/3/22",
    "1978/3/23",
    "1978/3/24",
    "1978/3/25",
    "1978/3/26",
    "1978/3/27",
    "1978/3/28",
    "1978/3/29",
    "1978/3/30",
    "1978/3/31",
    "1978/4/1",
    "1978/4/2",
    "1978/4/3",
    "1978/4/4",
    "1978/4/5",
    "1978/4/6",
    "1978/4/7",
    "1978/4/8",
    "1978/4/9",
    "1978/4/10",
    "1978/4/11",
    "1978/4/12",
    "1978/4/13",
    "1978/4/14",
    "1978/4/15",
    "1978/4/16",
    "1978/4/17",
    "1978/4/18",
    "1978/4/19",
    "1978/4/20",
    "1978/4/21",
    "1978/4/22",
    "1978/4/23",
    "1978/4/24",
    "1978/4/25",
    "1978/4/26",
    "1978/4/27",
    "1978/4/28",
    "1978/4/29",
    "1978/4/30",
    "1978/5/1",
    "1978/5/2",
    "1978/5/3",
    "1978/5/4",
    "1978/5/5",
    "1978/5/6",
    "1978/5/7",
    "1978/5/8",
    "1978/5/9",
    "1978/5/10",
    "1978/5/11",
    "1978/5/12",
    "1978/5/13",
    "1978/5/14",
    "1978/5/15",
    "1978/5/16",
    "1978/5/17",
    "1978/5/18",
    "1978/5/19",
    "1978/5/20",
    "1978/5/21",
    "1978/5/22",
    "1978/5/23",
    "1978/5/24",
    "1978/5/25",
    "1978/5/26",
    "1978/5/27",
    "1978/5/28",
    "1978/5/29",
    "1978/5/30",
    "1978/5/31",
    "1978/6/1",
    "1978/6/2",
    "1978/6/3",
    "1978/6/4",
    "1978/6/5",
    "1978/6/6",
    "1978/6/7",
    "1978/6/8",
    "1978/6/9",
    "1978/6/10",
    "1978/6/11",
    "1978/6/12",
    "1978/6/13",
    "1978/6/14",
    "1978/6/15",
    "1978/6/16",
    "1978/6/17",
    "1978/6/18",
    "1978/6/19",
    "1978/6/20",
    "1978/6/21",
    "1978/6/22",
    "1978/6/23",
    "1978/6/24",
    "1978/6/25",
    "1978/6/26",
    "1978/6/27",
    "1978/6/28",
    "1978/6/29",
    "1978/6/30",
    "1978/7/1",
    "1978/7/2",
    "1978/7/3",
    "1978/7/4",
    "1978/7/5",
    "1978/7/6",
    "1978/7/7",
    "1978/7/8",
    "1978/7/9",
    "1978/7/10",
    "1978/7/11",
    "1978/7/12",
    "1978/7/13",
    "1978/7/14",
    "1978/7/15",
    "1978/7/16",
    "1978/7/17",
    "1978/7/18",
    "1978/7/19",
    "1978/7/20",
    "1978/7/21",
    "1978/7/22",
    "1978/7/23",
    "1978/7/24",
    "1978/7/25",
    "1978/7/26",
    "1978/7/27",
    "1978/7/28",
    "1978/7/29",
    "1978/7/30",
    "1978/7/31",
    "1978/8/1",
    "1978/8/2",
    "1978/8/3",
    "1978/8/4",
    "1978/8/5",
    "1978/8/6",
    "1978/8/7",
    "1978/8/8",
    "1978/8/9",
    "1978/8/10",
    "1978/8/11",
    "1978/8/12",
    "1978/8/13",
    "1978/8/14",
    "1978/8/15",
    "1978/8/16",
    "1978/8/17",
    "1978/8/18",
    "1978/8/19",
    "1978/8/20",
    "1978/8/21",
    "1978/8/22",
    "1978/8/23",
    "1978/8/24",
    "1978/8/25",
    "1978/8/26",
    "1978/8/27",
    "1978/8/28",
    "1978/8/29",
    "1978/8/30",
    "1978/8/31",
    "1978/9/1",
    "1978/9/2",
    "1978/9/3",
    "1978/9/4",
    "1978/9/5",
    "1978/9/6",
    "1978/9/7",
    "1978/9/8",
    "1978/9/9",
    "1978/9/10",
    "1978/9/11",
    "1978/9/12",
    "1978/9/13",
    "1978/9/14",
    "1978/9/15",
    "1978/9/16",
    "1978/9/17",
    "1978/9/18",
    "1978/9/19",
    "1978/9/20",
    "1978/9/21",
    "1978/9/22",
    "1978/9/23",
    "1978/9/24",
    "1978/9/25",
    "1978/9/26",
    "1978/9/27",
    "1978/9/28",
    "1978/9/29",
    "1978/9/30",
    "1978/10/1",
    "1978/10/2",
    "1978/10/3",
    "1978/10/4",
    "1978/10/5",
    "1978/10/6",
    "1978/10/7",
    "1978/10/8",
    "1978/10/9",
    "1978/10/10",
    "1978/10/11",
    "1978/10/12",
    "1978/10/13",
    "1978/10/14",
    "1978/10/15",
    "1978/10/16",
    "1978/10/17",
    "1978/10/18",
    "1978/10/19",
    "1978/10/20",
    "1978/10/21",
    "1978/10/22",
    "1978/10/23",
    "1978/10/24",
    "1978/10/25",
    "1978/10/26",
    "1978/10/27",
    "1978/10/28",
    "1978/10/29",
    "1978/10/30",
    "1978/10/31",
    "1978/11/1",
    "1978/11/2",
    "1978/11/3",
    "1978/11/4",
    "1978/11/5",
    "1978/11/6",
    "1978/11/7",
    "1978/11/8",
    "1978/11/9",
    "1978/11/10",
    "1978/11/11",
    "1978/11/12",
    "1978/11/13",
    "1978/11/14",
    "1978/11/15",
    "1978/11/16",
    "1978/11/17",
    "1978/11/18",
    "1978/11/19",
    "1978/11/20",
    "1978/11/21",
    "1978/11/22",
    "1978/11/23",
    "1978/11/24",
    "1978/11/25",
    "1978/11/26",
    "1978/11/27",
    "1978/11/28",
    "1978/11/29",
    "1978/11/30",
    "1978/12/1",
    "1978/12/2",
    "1978/12/3",
    "1978/12/4",
    "1978/12/5",
    "1978/12/6",
    "1978/12/7",
    "1978/12/8",
    "1978/12/9",
    "1978/12/10",
    "1978/12/11",
    "1978/12/12",
    "1978/12/13",
    "1978/12/14",
    "1978/12/15",
    "1978/12/16",
    "1978/12/17",
    "1978/12/18",
    "1978/12/19",
    "1978/12/20",
    "1978/12/21",
    "1978/12/22",
    "1978/12/23",
    "1978/12/24",
    "1978/12/25",
    "1978/12/26",
    "1978/12/27",
    "1978/12/28",
    "1978/12/29",
    "1978/12/30",
    "1978/12/31",
    "1979/1/1",
    "1979/1/2",
    "1979/1/3",
    "1979/1/4",
    "1979/1/5",
    "1979/1/6",
    "1979/1/7",
    "1979/1/8",
    "1979/1/9",
    "1979/1/10",
    "1979/1/11",
    "1979/1/12",
    "1979/1/13",
    "1979/1/14",
    "1979/1/15",
    "1979/1/16",
    "1979/1/17",
    "1979/1/18",
    "1979/1/19",
    "1979/1/20",
    "1979/1/21",
    "1979/1/22",
    "1979/1/23",
    "1979/1/24",
    "1979/1/25",
    "1979/1/26",
    "1979/1/27",
    "1979/1/28",
    "1979/1/29",
    "1979/1/30",
    "1979/1/31",
    "1979/2/1",
    "1979/2/2",
    "1979/2/3",
    "1979/2/4",
    "1979/2/5",
    "1979/2/6",
    "1979/2/7",
    "1979/2/8",
    "1979/2/9",
    "1979/2/10",
    "1979/2/11",
    "1979/2/12",
    "1979/2/13",
    "1979/2/14",
    "1979/2/15",
    "1979/2/16",
    "1979/2/17",
    "1979/2/18",
    "1979/2/19",
    "1979/2/20",
    "1979/2/21",
    "1979/2/22",
    "1979/2/23",
    "1979/2/24",
    "1979/2/25",
    "1979/2/26",
    "1979/2/27",
    "1979/2/28",
    "1979/3/1",
    "1979/3/2",
    "1979/3/3",
    "1979/3/4",
    "1979/3/5",
    "1979/3/6",
    "1979/3/7",
    "1979/3/8",
    "1979/3/9",
    "1979/3/10",
    "1979/3/11",
    "1979/3/12",
    "1979/3/13",
    "1979/3/14",
    "1979/3/15",
    "1979/3/16",
    "1979/3/17",
    "1979/3/18",
    "1979/3/19",
    "1979/3/20",
    "1979/3/21",
    "1979/3/22",
    "1979/3/23",
    "1979/3/24",
    "1979/3/25",
    "1979/3/26",
    "1979/3/27",
    "1979/3/28",
    "1979/3/29",
    "1979/3/30",
    "1979/3/31",
    "1979/4/1",
    "1979/4/2",
    "1979/4/3",
    "1979/4/4",
    "1979/4/5",
    "1979/4/6",
    "1979/4/7",
    "1979/4/8",
    "1979/4/9",
    "1979/4/10",
    "1979/4/11",
    "1979/4/12",
    "1979/4/13",
    "1979/4/14",
    "1979/4/15",
    "1979/4/16",
    "1979/4/17",
    "1979/4/18",
    "1979/4/19",
    "1979/4/20",
    "1979/4/21",
    "1979/4/22",
    "1979/4/23",
    "1979/4/24",
    "1979/4/25",
    "1979/4/26",
    "1979/4/27",
    "1979/4/28",
    "1979/4/29",
    "1979/4/30",
    "1979/5/1",
    "1979/5/2",
    "1979/5/3",
    "1979/5/4",
    "1979/5/5",
    "1979/5/6",
    "1979/5/7",
    "1979/5/8",
    "1979/5/9",
    "1979/5/10",
    "1979/5/11",
    "1979/5/12",
    "1979/5/13",
    "1979/5/14",
    "1979/5/15",
    "1979/5/16",
    "1979/5/17",
    "1979/5/18",
    "1979/5/19",
    "1979/5/20",
    "1979/5/21",
    "1979/5/22",
    "1979/5/23",
    "1979/5/24",
    "1979/5/25",
    "1979/5/26",
    "1979/5/27",
    "1979/5/28",
    "1979/5/29",
    "1979/5/30",
    "1979/5/31",
    "1979/6/1",
    "1979/6/2",
    "1979/6/3",
    "1979/6/4",
    "1979/6/5",
    "1979/6/6",
    "1979/6/7",
    "1979/6/8",
    "1979/6/9",
    "1979/6/10",
    "1979/6/11",
    "1979/6/12",
    "1979/6/13",
    "1979/6/14",
    "1979/6/15",
    "1979/6/16",
    "1979/6/17",
    "1979/6/18",
    "1979/6/19",
    "1979/6/20",
    "1979/6/21",
    "1979/6/22",
    "1979/6/23",
    "1979/6/24",
    "1979/6/25",
    "1979/6/26",
    "1979/6/27",
    "1979/6/28",
    "1979/6/29",
    "1979/6/30",
    "1979/7/1",
    "1979/7/2",
    "1979/7/3",
    "1979/7/4",
    "1979/7/5",
    "1979/7/6",
    "1979/7/7",
    "1979/7/8",
    "1979/7/9",
    "1979/7/10",
    "1979/7/11",
    "1979/7/12",
    "1979/7/13",
    "1979/7/14",
    "1979/7/15",
    "1979/7/16",
    "1979/7/17",
    "1979/7/18",
    "1979/7/19",
    "1979/7/20",
    "1979/7/21",
    "1979/7/22",
    "1979/7/23",
    "1979/7/24",
    "1979/7/25",
    "1979/7/26",
    "1979/7/27",
    "1979/7/28",
    "1979/7/29",
    "1979/7/30",
    "1979/7/31",
    "1979/8/1",
    "1979/8/2",
    "1979/8/3",
    "1979/8/4",
    "1979/8/5",
    "1979/8/6",
    "1979/8/7",
    "1979/8/8",
    "1979/8/9",
    "1979/8/10",
    "1979/8/11",
    "1979/8/12",
    "1979/8/13",
    "1979/8/14",
    "1979/8/15",
    "1979/8/16",
    "1979/8/17",
    "1979/8/18",
    "1979/8/19",
    "1979/8/20",
    "1979/8/21",
    "1979/8/22",
    "1979/8/23",
    "1979/8/24",
    "1979/8/25",
    "1979/8/26",
    "1979/8/27",
    "1979/8/28",
    "1979/8/29",
    "1979/8/30",
    "1979/8/31",
    "1979/9/1",
    "1979/9/2",
    "1979/9/3",
    "1979/9/4",
    "1979/9/5",
    "1979/9/6",
    "1979/9/7",
    "1979/9/8",
    "1979/9/9",
    "1979/9/10",
    "1979/9/11",
    "1979/9/12",
    "1979/9/13",
    "1979/9/14",
    "1979/9/15",
    "1979/9/16",
    "1979/9/17",
    "1979/9/18",
    "1979/9/19",
    "1979/9/20",
    "1979/9/21",
    "1979/9/22",
    "1979/9/23",
    "1979/9/24",
    "1979/9/25",
    "1979/9/26",
    "1979/9/27",
    "1979/9/28",
    "1979/9/29",
    "1979/9/30",
    "1979/10/1",
    "1979/10/2",
    "1979/10/3",
    "1979/10/4",
    "1979/10/5",
    "1979/10/6",
    "1979/10/7",
    "1979/10/8",
    "1979/10/9",
    "1979/10/10",
    "1979/10/11",
    "1979/10/12",
    "1979/10/13",
    "1979/10/14",
    "1979/10/15",
    "1979/10/16",
    "1979/10/17",
    "1979/10/18",
    "1979/10/19",
    "1979/10/20",
    "1979/10/21",
    "1979/10/22",
    "1979/10/23",
    "1979/10/24",
    "1979/10/25",
    "1979/10/26",
    "1979/10/27",
    "1979/10/28",
    "1979/10/29",
    "1979/10/30",
    "1979/10/31",
    "1979/11/1",
    "1979/11/2",
    "1979/11/3",
    "1979/11/4",
    "1979/11/5",
    "1979/11/6",
    "1979/11/7",
    "1979/11/8",
    "1979/11/9",
    "1979/11/10",
    "1979/11/11",
    "1979/11/12",
    "1979/11/13",
    "1979/11/14",
    "1979/11/15",
    "1979/11/16",
    "1979/11/17",
    "1979/11/18",
    "1979/11/19",
    "1979/11/20",
    "1979/11/21",
    "1979/11/22",
    "1979/11/23",
    "1979/11/24",
    "1979/11/25",
    "1979/11/26",
    "1979/11/27",
    "1979/11/28",
    "1979/11/29",
    "1979/11/30",
    "1979/12/1",
    "1979/12/2",
    "1979/12/3",
    "1979/12/4",
    "1979/12/5",
    "1979/12/6",
    "1979/12/7",
    "1979/12/8",
    "1979/12/9",
    "1979/12/10",
    "1979/12/11",
    "1979/12/12",
    "1979/12/13",
    "1979/12/14",
    "1979/12/15",
    "1979/12/16",
    "1979/12/17",
    "1979/12/18",
    "1979/12/19",
    "1979/12/20",
    "1979/12/21",
    "1979/12/22",
    "1979/12/23",
    "1979/12/24",
    "1979/12/25",
    "1979/12/26",
    "1979/12/27",
    "1979/12/28",
    "1979/12/29",
    "1979/12/30",
    "1979/12/31",
    "1980/1/1",
    "1980/1/2",
    "1980/1/3",
    "1980/1/4",
    "1980/1/5",
    "1980/1/6",
    "1980/1/7",
    "1980/1/8",
    "1980/1/9",
    "1980/1/10",
    "1980/1/11",
    "1980/1/12",
    "1980/1/13",
    "1980/1/14",
    "1980/1/15",
    "1980/1/16",
    "1980/1/17",
    "1980/1/18",
    "1980/1/19",
    "1980/1/20",
    "1980/1/21",
    "1980/1/22",
    "1980/1/23",
    "1980/1/24",
    "1980/1/25",
    "1980/1/26",
    "1980/1/27",
    "1980/1/28",
    "1980/1/29",
    "1980/1/30",
    "1980/1/31",
    "1980/2/1",
    "1980/2/2",
    "1980/2/3",
    "1980/2/4",
    "1980/2/5",
    "1980/2/6",
    "1980/2/7",
    "1980/2/8",
    "1980/2/9",
    "1980/2/10",
    "1980/2/11",
    "1980/2/12",
    "1980/2/13",
    "1980/2/14",
    "1980/2/15",
    "1980/2/16",
    "1980/2/17",
    "1980/2/18",
    "1980/2/19",
    "1980/2/20",
    "1980/2/21",
    "1980/2/22",
    "1980/2/23",
    "1980/2/24",
    "1980/2/25",
    "1980/2/26",
    "1980/2/27",
    "1980/2/28",
    "1980/2/29",
    "1980/3/1",
    "1980/3/2",
    "1980/3/3",
    "1980/3/4",
    "1980/3/5",
    "1980/3/6",
    "1980/3/7",
    "1980/3/8",
    "1980/3/9",
    "1980/3/10",
    "1980/3/11",
    "1980/3/12",
    "1980/3/13",
    "1980/3/14",
    "1980/3/15",
    "1980/3/16",
    "1980/3/17",
    "1980/3/18",
    "1980/3/19",
    "1980/3/20",
    "1980/3/21",
    "1980/3/22",
    "1980/3/23",
    "1980/3/24",
    "1980/3/25",
    "1980/3/26",
    "1980/3/27",
    "1980/3/28",
    "1980/3/29",
    "1980/3/30",
    "1980/3/31",
    "1980/4/1",
    "1980/4/2",
    "1980/4/3",
    "1980/4/4",
    "1980/4/5",
    "1980/4/6",
    "1980/4/7",
    "1980/4/8",
    "1980/4/9",
    "1980/4/10",
    "1980/4/11",
    "1980/4/12",
    "1980/4/13",
    "1980/4/14",
    "1980/4/15",
    "1980/4/16",
    "1980/4/17",
    "1980/4/18",
    "1980/4/19",
    "1980/4/20",
    "1980/4/21",
    "1980/4/22",
    "1980/4/23",
    "1980/4/24",
    "1980/4/25",
    "1980/4/26",
    "1980/4/27",
    "1980/4/28",
    "1980/4/29",
    "1980/4/30",
    "1980/5/1",
    "1980/5/2",
    "1980/5/3",
    "1980/5/4",
    "1980/5/5",
    "1980/5/6",
    "1980/5/7",
    "1980/5/8",
    "1980/5/9",
    "1980/5/10",
    "1980/5/11",
    "1980/5/12",
    "1980/5/13",
    "1980/5/14",
    "1980/5/15",
    "1980/5/16",
    "1980/5/17",
    "1980/5/18",
    "1980/5/19",
    "1980/5/20",
    "1980/5/21",
    "1980/5/22",
    "1980/5/23",
    "1980/5/24",
    "1980/5/25",
    "1980/5/26",
    "1980/5/27",
    "1980/5/28",
    "1980/5/29",
    "1980/5/30",
    "1980/5/31",
    "1980/6/1",
    "1980/6/2",
    "1980/6/3",
    "1980/6/4",
    "1980/6/5",
    "1980/6/6",
    "1980/6/7",
    "1980/6/8",
    "1980/6/9",
    "1980/6/10",
    "1980/6/11",
    "1980/6/12",
    "1980/6/13",
    "1980/6/14",
    "1980/6/15",
    "1980/6/16",
    "1980/6/17",
    "1980/6/18",
    "1980/6/19",
    "1980/6/20",
    "1980/6/21",
    "1980/6/22",
    "1980/6/23",
    "1980/6/24",
    "1980/6/25",
    "1980/6/26",
    "1980/6/27",
    "1980/6/28",
    "1980/6/29",
    "1980/6/30",
    "1980/7/1",
    "1980/7/2",
    "1980/7/3",
    "1980/7/4",
    "1980/7/5",
    "1980/7/6",
    "1980/7/7",
    "1980/7/8",
    "1980/7/9",
    "1980/7/10",
    "1980/7/11",
    "1980/7/12",
    "1980/7/13",
    "1980/7/14",
    "1980/7/15",
    "1980/7/16",
    "1980/7/17",
    "1980/7/18",
    "1980/7/19",
    "1980/7/20",
    "1980/7/21",
    "1980/7/22",
    "1980/7/23",
    "1980/7/24",
    "1980/7/25",
    "1980/7/26",
    "1980/7/27",
    "1980/7/28",
    "1980/7/29",
    "1980/7/30",
    "1980/7/31",
    "1980/8/1",
    "1980/8/2",
    "1980/8/3",
    "1980/8/4",
    "1980/8/5",
    "1980/8/6",
    "1980/8/7",
    "1980/8/8",
    "1980/8/9",
    "1980/8/10",
    "1980/8/11",
    "1980/8/12",
    "1980/8/13",
    "1980/8/14",
    "1980/8/15",
    "1980/8/16",
    "1980/8/17",
    "1980/8/18",
    "1980/8/19",
    "1980/8/20",
    "1980/8/21",
    "1980/8/22",
    "1980/8/23",
    "1980/8/24",
    "1980/8/25",
    "1980/8/26",
    "1980/8/27",
    "1980/8/28",
    "1980/8/29",
    "1980/8/30",
    "1980/8/31",
    "1980/9/1",
    "1980/9/2",
    "1980/9/3",
    "1980/9/4",
    "1980/9/5",
    "1980/9/6",
    "1980/9/7",
    "1980/9/8",
    "1980/9/9",
    "1980/9/10",
    "1980/9/11",
    "1980/9/12",
    "1980/9/13",
    "1980/9/14",
    "1980/9/15",
    "1980/9/16",
    "1980/9/17",
    "1980/9/18",
    "1980/9/19",
    "1980/9/20",
    "1980/9/21",
    "1980/9/22",
    "1980/9/23",
    "1980/9/24",
    "1980/9/25",
    "1980/9/26",
    "1980/9/27",
    "1980/9/28",
    "1980/9/29",
    "1980/9/30",
    "1980/10/1",
    "1980/10/2",
    "1980/10/3",
    "1980/10/4",
    "1980/10/5",
    "1980/10/6",
    "1980/10/7",
    "1980/10/8",
    "1980/10/9",
    "1980/10/10",
    "1980/10/11",
    "1980/10/12",
    "1980/10/13",
    "1980/10/14",
    "1980/10/15",
    "1980/10/16",
    "1980/10/17",
    "1980/10/18",
    "1980/10/19",
    "1980/10/20",
    "1980/10/21",
    "1980/10/22",
    "1980/10/23",
    "1980/10/24",
    "1980/10/25",
    "1980/10/26",
    "1980/10/27",
    "1980/10/28",
    "1980/10/29",
    "1980/10/30",
    "1980/10/31",
    "1980/11/1",
    "1980/11/2",
    "1980/11/3",
    "1980/11/4",
    "1980/11/5",
    "1980/11/6",
    "1980/11/7",
    "1980/11/8",
    "1980/11/9",
    "1980/11/10",
    "1980/11/11",
    "1980/11/12",
    "1980/11/13",
    "1980/11/14",
    "1980/11/15",
    "1980/11/16",
    "1980/11/17",
    "1980/11/18",
    "1980/11/19",
    "1980/11/20",
    "1980/11/21",
    "1980/11/22",
    "1980/11/23",
    "1980/11/24",
    "1980/11/25",
    "1980/11/26",
    "1980/11/27",
    "1980/11/28",
    "1980/11/29",
    "1980/11/30",
    "1980/12/1",
    "1980/12/2",
    "1980/12/3",
    "1980/12/4",
    "1980/12/5",
    "1980/12/6",
    "1980/12/7",
    "1980/12/8",
    "1980/12/9",
    "1980/12/10",
    "1980/12/11",
    "1980/12/12",
    "1980/12/13",
    "1980/12/14",
    "1980/12/15",
    "1980/12/16",
    "1980/12/17",
    "1980/12/18",
    "1980/12/19",
    "1980/12/20",
    "1980/12/21",
    "1980/12/22",
    "1980/12/23",
    "1980/12/24",
    "1980/12/25",
    "1980/12/26",
    "1980/12/27",
    "1980/12/28",
    "1980/12/29",
    "1980/12/30",
    "1980/12/31",
    "1981/1/1",
    "1981/1/2",
    "1981/1/3",
    "1981/1/4",
    "1981/1/5",
    "1981/1/6",
    "1981/1/7",
    "1981/1/8",
    "1981/1/9",
    "1981/1/10",
    "1981/1/11",
    "1981/1/12",
    "1981/1/13",
    "1981/1/14",
    "1981/1/15",
    "1981/1/16",
    "1981/1/17",
    "1981/1/18",
    "1981/1/19",
    "1981/1/20",
    "1981/1/21",
    "1981/1/22",
    "1981/1/23",
    "1981/1/24",
    "1981/1/25",
    "1981/1/26",
    "1981/1/27",
    "1981/1/28",
    "1981/1/29",
    "1981/1/30",
    "1981/1/31",
    "1981/2/1",
    "1981/2/2",
    "1981/2/3",
    "1981/2/4",
    "1981/2/5",
    "1981/2/6",
    "1981/2/7",
    "1981/2/8",
    "1981/2/9",
    "1981/2/10",
    "1981/2/11",
    "1981/2/12",
    "1981/2/13",
    "1981/2/14",
    "1981/2/15",
    "1981/2/16",
    "1981/2/17",
    "1981/2/18",
    "1981/2/19",
    "1981/2/20",
    "1981/2/21",
    "1981/2/22",
    "1981/2/23",
    "1981/2/24",
    "1981/2/25",
    "1981/2/26",
    "1981/2/27",
    "1981/2/28",
    "1981/3/1",
    "1981/3/2",
    "1981/3/3",
    "1981/3/4",
    "1981/3/5",
    "1981/3/6",
    "1981/3/7",
    "1981/3/8",
    "1981/3/9",
    "1981/3/10",
    "1981/3/11",
    "1981/3/12",
    "1981/3/13",
    "1981/3/14",
    "1981/3/15",
    "1981/3/16",
    "1981/3/17",
    "1981/3/18",
    "1981/3/19",
    "1981/3/20",
    "1981/3/21",
    "1981/3/22",
    "1981/3/23",
    "1981/3/24",
    "1981/3/25",
    "1981/3/26",
    "1981/3/27",
    "1981/3/28",
    "1981/3/29",
    "1981/3/30",
    "1981/3/31",
    "1981/4/1",
    "1981/4/2",
    "1981/4/3",
    "1981/4/4",
    "1981/4/5",
    "1981/4/6",
    "1981/4/7",
    "1981/4/8",
    "1981/4/9",
    "1981/4/10",
    "1981/4/11",
    "1981/4/12",
    "1981/4/13",
    "1981/4/14",
    "1981/4/15",
    "1981/4/16",
    "1981/4/17",
    "1981/4/18",
    "1981/4/19",
    "1981/4/20",
    "1981/4/21",
    "1981/4/22",
    "1981/4/23",
    "1981/4/24",
    "1981/4/25",
    "1981/4/26",
    "1981/4/27",
    "1981/4/28",
    "1981/4/29",
    "1981/4/30",
    "1981/5/1",
    "1981/5/2",
    "1981/5/3",
    "1981/5/4",
    "1981/5/5",
    "1981/5/6",
    "1981/5/7",
    "1981/5/8",
    "1981/5/9",
    "1981/5/10",
    "1981/5/11",
    "1981/5/12",
    "1981/5/13",
    "1981/5/14",
    "1981/5/15",
    "1981/5/16",
    "1981/5/17",
    "1981/5/18",
    "1981/5/19",
    "1981/5/20",
    "1981/5/21",
    "1981/5/22",
    "1981/5/23",
    "1981/5/24",
    "1981/5/25",
    "1981/5/26",
    "1981/5/27",
    "1981/5/28",
    "1981/5/29",
    "1981/5/30",
    "1981/5/31",
    "1981/6/1",
    "1981/6/2",
    "1981/6/3",
    "1981/6/4",
    "1981/6/5",
    "1981/6/6",
    "1981/6/7",
    "1981/6/8",
    "1981/6/9",
    "1981/6/10",
    "1981/6/11",
    "1981/6/12",
    "1981/6/13",
    "1981/6/14",
    "1981/6/15",
    "1981/6/16",
    "1981/6/17",
    "1981/6/18",
    "1981/6/19",
    "1981/6/20",
    "1981/6/21",
    "1981/6/22",
    "1981/6/23",
    "1981/6/24",
    "1981/6/25",
    "1981/6/26",
    "1981/6/27",
    "1981/6/28",
    "1981/6/29",
    "1981/6/30",
    "1981/7/1",
    "1981/7/2",
    "1981/7/3",
    "1981/7/4",
    "1981/7/5",
    "1981/7/6",
    "1981/7/7",
    "1981/7/8",
    "1981/7/9",
    "1981/7/10",
    "1981/7/11",
    "1981/7/12",
    "1981/7/13",
    "1981/7/14",
    "1981/7/15",
    "1981/7/16",
    "1981/7/17",
    "1981/7/18",
    "1981/7/19",
    "1981/7/20",
    "1981/7/21",
    "1981/7/22",
    "1981/7/23",
    "1981/7/24",
    "1981/7/25",
    "1981/7/26",
    "1981/7/27",
    "1981/7/28",
    "1981/7/29",
    "1981/7/30",
    "1981/7/31",
    "1981/8/1",
    "1981/8/2",
    "1981/8/3",
    "1981/8/4",
    "1981/8/5",
    "1981/8/6",
    "1981/8/7",
    "1981/8/8",
    "1981/8/9",
    "1981/8/10",
    "1981/8/11",
    "1981/8/12",
    "1981/8/13",
    "1981/8/14",
    "1981/8/15",
    "1981/8/16",
    "1981/8/17",
    "1981/8/18",
    "1981/8/19",
    "1981/8/20",
    "1981/8/21",
    "1981/8/22",
    "1981/8/23",
    "1981/8/24",
    "1981/8/25",
    "1981/8/26",
    "1981/8/27",
    "1981/8/28",
    "1981/8/29",
    "1981/8/30",
    "1981/8/31",
    "1981/9/1",
    "1981/9/2",
    "1981/9/3",
    "1981/9/4",
    "1981/9/5",
    "1981/9/6",
    "1981/9/7",
    "1981/9/8",
    "1981/9/9",
    "1981/9/10",
    "1981/9/11",
    "1981/9/12",
    "1981/9/13",
    "1981/9/14",
    "1981/9/15",
    "1981/9/16",
    "1981/9/17",
    "1981/9/18",
    "1981/9/19",
    "1981/9/20",
    "1981/9/21",
    "1981/9/22",
    "1981/9/23",
    "1981/9/24",
    "1981/9/25",
    "1981/9/26",
    "1981/9/27",
    "1981/9/28",
    "1981/9/29",
    "1981/9/30",
    "1981/10/1",
    "1981/10/2",
    "1981/10/3",
    "1981/10/4",
    "1981/10/5",
    "1981/10/6",
    "1981/10/7",
    "1981/10/8",
    "1981/10/9",
    "1981/10/10",
    "1981/10/11",
    "1981/10/12",
    "1981/10/13",
    "1981/10/14",
    "1981/10/15",
    "1981/10/16",
    "1981/10/17",
    "1981/10/18",
    "1981/10/19",
    "1981/10/20",
    "1981/10/21",
    "1981/10/22",
    "1981/10/23",
    "1981/10/24",
    "1981/10/25",
    "1981/10/26",
    "1981/10/27",
    "1981/10/28",
    "1981/10/29",
    "1981/10/30",
    "1981/10/31",
    "1981/11/1",
    "1981/11/2",
    "1981/11/3",
    "1981/11/4",
    "1981/11/5",
    "1981/11/6",
    "1981/11/7",
    "1981/11/8",
    "1981/11/9",
    "1981/11/10",
    "1981/11/11",
    "1981/11/12",
    "1981/11/13",
    "1981/11/14",
    "1981/11/15",
    "1981/11/16",
    "1981/11/17",
    "1981/11/18",
    "1981/11/19",
    "1981/11/20",
    "1981/11/21",
    "1981/11/22",
    "1981/11/23",
    "1981/11/24",
    "1981/11/25",
    "1981/11/26",
    "1981/11/27",
    "1981/11/28",
    "1981/11/29",
    "1981/11/30",
    "1981/12/1",
    "1981/12/2",
    "1981/12/3",
    "1981/12/4",
    "1981/12/5",
    "1981/12/6",
    "1981/12/7",
    "1981/12/8",
    "1981/12/9",
    "1981/12/10",
    "1981/12/11",
    "1981/12/12",
    "1981/12/13",
    "1981/12/14",
    "1981/12/15",
    "1981/12/16",
    "1981/12/17",
    "1981/12/18",
    "1981/12/19",
    "1981/12/20",
    "1981/12/21",
    "1981/12/22",
    "1981/12/23",
    "1981/12/24",
    "1981/12/25",
    "1981/12/26",
    "1981/12/27",
    "1981/12/28",
    "1981/12/29",
    "1981/12/30",
    "1981/12/31",
    "1982/1/1",
    "1982/1/2",
    "1982/1/3",
    "1982/1/4",
    "1982/1/5",
    "1982/1/6",
    "1982/1/7",
    "1982/1/8",
    "1982/1/9",
    "1982/1/10",
    "1982/1/11",
    "1982/1/12",
    "1982/1/13",
    "1982/1/14",
    "1982/1/15",
    "1982/1/16",
    "1982/1/17",
    "1982/1/18",
    "1982/1/19",
    "1982/1/20",
    "1982/1/21",
    "1982/1/22",
    "1982/1/23",
    "1982/1/24",
    "1982/1/25",
    "1982/1/26",
    "1982/1/27",
    "1982/1/28",
    "1982/1/29",
    "1982/1/30",
    "1982/1/31",
    "1982/2/1",
    "1982/2/2",
    "1982/2/3",
    "1982/2/4",
    "1982/2/5",
    "1982/2/6",
    "1982/2/7",
    "1982/2/8",
    "1982/2/9",
    "1982/2/10",
    "1982/2/11",
    "1982/2/12",
    "1982/2/13",
    "1982/2/14",
    "1982/2/15",
    "1982/2/16",
    "1982/2/17",
    "1982/2/18",
    "1982/2/19",
    "1982/2/20",
    "1982/2/21",
    "1982/2/22",
    "1982/2/23",
    "1982/2/24",
    "1982/2/25",
    "1982/2/26",
    "1982/2/27",
    "1982/2/28",
    "1982/3/1",
    "1982/3/2",
    "1982/3/3",
    "1982/3/4",
    "1982/3/5",
    "1982/3/6",
    "1982/3/7",
    "1982/3/8",
    "1982/3/9",
    "1982/3/10",
    "1982/3/11",
    "1982/3/12",
    "1982/3/13",
    "1982/3/14",
    "1982/3/15",
    "1982/3/16",
    "1982/3/17",
    "1982/3/18",
    "1982/3/19",
    "1982/3/20",
    "1982/3/21",
    "1982/3/22",
    "1982/3/23",
    "1982/3/24",
    "1982/3/25",
    "1982/3/26",
    "1982/3/27",
    "1982/3/28",
    "1982/3/29",
    "1982/3/30",
    "1982/3/31",
    "1982/4/1",
    "1982/4/2",
    "1982/4/3",
    "1982/4/4",
    "1982/4/5",
    "1982/4/6",
    "1982/4/7",
    "1982/4/8",
    "1982/4/9",
    "1982/4/10",
    "1982/4/11",
    "1982/4/12",
    "1982/4/13",
    "1982/4/14",
    "1982/4/15",
    "1982/4/16",
    "1982/4/17",
    "1982/4/18",
    "1982/4/19",
    "1982/4/20",
    "1982/4/21",
    "1982/4/22",
    "1982/4/23",
    "1982/4/24",
    "1982/4/25",
    "1982/4/26",
    "1982/4/27",
    "1982/4/28",
    "1982/4/29",
    "1982/4/30",
    "1982/5/1",
    "1982/5/2",
    "1982/5/3",
    "1982/5/4",
    "1982/5/5",
    "1982/5/6",
    "1982/5/7",
    "1982/5/8",
    "1982/5/9",
    "1982/5/10",
    "1982/5/11",
    "1982/5/12",
    "1982/5/13",
    "1982/5/14",
    "1982/5/15",
    "1982/5/16",
    "1982/5/17",
    "1982/5/18",
    "1982/5/19",
    "1982/5/20",
    "1982/5/21",
    "1982/5/22",
    "1982/5/23",
    "1982/5/24",
    "1982/5/25",
    "1982/5/26",
    "1982/5/27",
    "1982/5/28",
    "1982/5/29",
    "1982/5/30",
    "1982/5/31",
    "1982/6/1",
    "1982/6/2",
    "1982/6/3",
    "1982/6/4",
    "1982/6/5",
    "1982/6/6",
    "1982/6/7",
    "1982/6/8",
    "1982/6/9",
    "1982/6/10",
    "1982/6/11",
    "1982/6/12",
    "1982/6/13",
    "1982/6/14",
    "1982/6/15",
    "1982/6/16",
    "1982/6/17",
    "1982/6/18",
    "1982/6/19",
    "1982/6/20",
    "1982/6/21",
    "1982/6/22",
    "1982/6/23",
    "1982/6/24",
    "1982/6/25",
    "1982/6/26",
    "1982/6/27",
    "1982/6/28",
    "1982/6/29",
    "1982/6/30",
    "1982/7/1",
    "1982/7/2",
    "1982/7/3",
    "1982/7/4",
    "1982/7/5",
    "1982/7/6",
    "1982/7/7",
    "1982/7/8",
    "1982/7/9",
    "1982/7/10",
    "1982/7/11",
    "1982/7/12",
    "1982/7/13",
    "1982/7/14",
    "1982/7/15",
    "1982/7/16",
    "1982/7/17",
    "1982/7/18",
    "1982/7/19",
    "1982/7/20",
    "1982/7/21",
    "1982/7/22",
    "1982/7/23",
    "1982/7/24",
    "1982/7/25",
    "1982/7/26",
    "1982/7/27",
    "1982/7/28",
    "1982/7/29",
    "1982/7/30",
    "1982/7/31",
    "1982/8/1",
    "1982/8/2",
    "1982/8/3",
    "1982/8/4",
    "1982/8/5",
    "1982/8/6",
    "1982/8/7",
    "1982/8/8",
    "1982/8/9",
    "1982/8/10",
    "1982/8/11",
    "1982/8/12",
    "1982/8/13",
    "1982/8/14",
    "1982/8/15",
    "1982/8/16",
    "1982/8/17",
    "1982/8/18",
    "1982/8/19",
    "1982/8/20",
    "1982/8/21",
    "1982/8/22",
    "1982/8/23",
    "1982/8/24",
    "1982/8/25",
    "1982/8/26",
    "1982/8/27",
    "1982/8/28",
    "1982/8/29",
    "1982/8/30",
    "1982/8/31",
    "1982/9/1",
    "1982/9/2",
    "1982/9/3",
    "1982/9/4",
    "1982/9/5",
    "1982/9/6",
    "1982/9/7",
    "1982/9/8",
    "1982/9/9",
    "1982/9/10",
    "1982/9/11",
    "1982/9/12",
    "1982/9/13",
    "1982/9/14",
    "1982/9/15",
    "1982/9/16",
    "1982/9/17",
    "1982/9/18",
    "1982/9/19",
    "1982/9/20",
    "1982/9/21",
    "1982/9/22",
    "1982/9/23",
    "1982/9/24",
    "1982/9/25",
    "1982/9/26",
    "1982/9/27",
    "1982/9/28",
    "1982/9/29",
    "1982/9/30",
    "1982/10/1",
    "1982/10/2",
    "1982/10/3",
    "1982/10/4",
    "1982/10/5",
    "1982/10/6",
    "1982/10/7",
    "1982/10/8",
    "1982/10/9",
    "1982/10/10",
    "1982/10/11",
    "1982/10/12",
    "1982/10/13",
    "1982/10/14",
    "1982/10/15",
    "1982/10/16",
    "1982/10/17",
    "1982/10/18",
    "1982/10/19",
    "1982/10/20",
    "1982/10/21",
    "1982/10/22",
    "1982/10/23",
    "1982/10/24",
    "1982/10/25",
    "1982/10/26",
    "1982/10/27",
    "1982/10/28",
    "1982/10/29",
    "1982/10/30",
    "1982/10/31",
    "1982/11/1",
    "1982/11/2",
    "1982/11/3",
    "1982/11/4",
    "1982/11/5",
    "1982/11/6",
    "1982/11/7",
    "1982/11/8",
    "1982/11/9",
    "1982/11/10",
    "1982/11/11",
    "1982/11/12",
    "1982/11/13",
    "1982/11/14",
    "1982/11/15",
    "1982/11/16",
    "1982/11/17",
    "1982/11/18",
    "1982/11/19",
    "1982/11/20",
    "1982/11/21",
    "1982/11/22",
    "1982/11/23",
    "1982/11/24",
    "1982/11/25",
    "1982/11/26",
    "1982/11/27",
    "1982/11/28",
    "1982/11/29",
    "1982/11/30",
    "1982/12/1",
    "1982/12/2",
    "1982/12/3",
    "1982/12/4",
    "1982/12/5",
    "1982/12/6",
    "1982/12/7",
    "1982/12/8",
    "1982/12/9",
    "1982/12/10",
    "1982/12/11",
    "1982/12/12",
    "1982/12/13",
    "1982/12/14",
    "1982/12/15",
    "1982/12/16",
    "1982/12/17",
    "1982/12/18",
    "1982/12/19",
    "1982/12/20",
    "1982/12/21",
    "1982/12/22",
    "1982/12/23",
    "1982/12/24",
    "1982/12/25",
    "1982/12/26",
    "1982/12/27",
    "1982/12/28",
    "1982/12/29",
    "1982/12/30",
    "1982/12/31",
    "1983/1/1",
    "1983/1/2",
    "1983/1/3",
    "1983/1/4",
    "1983/1/5",
    "1983/1/6",
    "1983/1/7",
    "1983/1/8",
    "1983/1/9",
    "1983/1/10",
    "1983/1/11",
    "1983/1/12",
    "1983/1/13",
    "1983/1/14",
    "1983/1/15",
    "1983/1/16",
    "1983/1/17",
    "1983/1/18",
    "1983/1/19",
    "1983/1/20",
    "1983/1/21",
    "1983/1/22",
    "1983/1/23",
    "1983/1/24",
    "1983/1/25",
    "1983/1/26",
    "1983/1/27",
    "1983/1/28",
    "1983/1/29",
    "1983/1/30",
    "1983/1/31",
    "1983/2/1",
    "1983/2/2",
    "1983/2/3",
    "1983/2/4",
    "1983/2/5",
    "1983/2/6",
    "1983/2/7",
    "1983/2/8",
    "1983/2/9",
    "1983/2/10",
    "1983/2/11",
    "1983/2/12",
    "1983/2/13",
    "1983/2/14",
    "1983/2/15",
    "1983/2/16",
    "1983/2/17",
    "1983/2/18",
    "1983/2/19",
    "1983/2/20",
    "1983/2/21",
    "1983/2/22",
    "1983/2/23",
    "1983/2/24",
    "1983/2/25",
    "1983/2/26",
    "1983/2/27",
    "1983/2/28",
    "1983/3/1",
    "1983/3/2",
    "1983/3/3",
    "1983/3/4",
    "1983/3/5",
    "1983/3/6",
    "1983/3/7",
    "1983/3/8",
    "1983/3/9",
    "1983/3/10",
    "1983/3/11",
    "1983/3/12",
    "1983/3/13",
    "1983/3/14",
    "1983/3/15",
    "1983/3/16",
    "1983/3/17",
    "1983/3/18",
    "1983/3/19",
    "1983/3/20",
    "1983/3/21",
    "1983/3/22",
    "1983/3/23",
    "1983/3/24",
    "1983/3/25",
    "1983/3/26",
    "1983/3/27",
    "1983/3/28",
    "1983/3/29",
    "1983/3/30",
    "1983/3/31",
    "1983/4/1",
    "1983/4/2",
    "1983/4/3",
    "1983/4/4",
    "1983/4/5",
    "1983/4/6",
    "1983/4/7",
    "1983/4/8",
    "1983/4/9",
    "1983/4/10",
    "1983/4/11",
    "1983/4/12",
    "1983/4/13",
    "1983/4/14",
    "1983/4/15",
    "1983/4/16",
    "1983/4/17",
    "1983/4/18",
    "1983/4/19",
    "1983/4/20",
    "1983/4/21",
    "1983/4/22",
    "1983/4/23",
    "1983/4/24",
    "1983/4/25",
    "1983/4/26",
    "1983/4/27",
    "1983/4/28",
    "1983/4/29",
    "1983/4/30",
    "1983/5/1",
    "1983/5/2",
    "1983/5/3",
    "1983/5/4",
    "1983/5/5",
    "1983/5/6",
    "1983/5/7",
    "1983/5/8",
    "1983/5/9",
    "1983/5/10",
    "1983/5/11",
    "1983/5/12",
    "1983/5/13",
    "1983/5/14",
    "1983/5/15",
    "1983/5/16",
    "1983/5/17",
    "1983/5/18",
    "1983/5/19",
    "1983/5/20",
    "1983/5/21",
    "1983/5/22",
    "1983/5/23",
    "1983/5/24",
    "1983/5/25",
    "1983/5/26",
    "1983/5/27",
    "1983/5/28",
    "1983/5/29",
    "1983/5/30",
    "1983/5/31",
    "1983/6/1",
    "1983/6/2",
    "1983/6/3",
    "1983/6/4",
    "1983/6/5",
    "1983/6/6",
    "1983/6/7",
    "1983/6/8",
    "1983/6/9",
    "1983/6/10",
    "1983/6/11",
    "1983/6/12",
    "1983/6/13",
    "1983/6/14",
    "1983/6/15",
    "1983/6/16",
    "1983/6/17",
    "1983/6/18",
    "1983/6/19",
    "1983/6/20",
    "1983/6/21",
    "1983/6/22",
    "1983/6/23",
    "1983/6/24",
    "1983/6/25",
    "1983/6/26",
    "1983/6/27",
    "1983/6/28",
    "1983/6/29",
    "1983/6/30",
    "1983/7/1",
    "1983/7/2",
    "1983/7/3",
    "1983/7/4",
    "1983/7/5",
    "1983/7/6",
    "1983/7/7",
    "1983/7/8",
    "1983/7/9",
    "1983/7/10",
    "1983/7/11",
    "1983/7/12",
    "1983/7/13",
    "1983/7/14",
    "1983/7/15",
    "1983/7/16",
    "1983/7/17",
    "1983/7/18",
    "1983/7/19",
    "1983/7/20",
    "1983/7/21",
    "1983/7/22",
    "1983/7/23",
    "1983/7/24",
    "1983/7/25",
    "1983/7/26",
    "1983/7/27",
    "1983/7/28",
    "1983/7/29",
    "1983/7/30",
    "1983/7/31",
    "1983/8/1",
    "1983/8/2",
    "1983/8/3",
    "1983/8/4",
    "1983/8/5",
    "1983/8/6",
    "1983/8/7",
    "1983/8/8",
    "1983/8/9",
    "1983/8/10",
    "1983/8/11",
    "1983/8/12",
    "1983/8/13",
    "1983/8/14",
    "1983/8/15",
    "1983/8/16",
    "1983/8/17",
    "1983/8/18",
    "1983/8/19",
    "1983/8/20",
    "1983/8/21",
    "1983/8/22",
    "1983/8/23",
    "1983/8/24",
    "1983/8/25",
    "1983/8/26",
    "1983/8/27",
    "1983/8/28",
    "1983/8/29",
    "1983/8/30",
    "1983/8/31",
    "1983/9/1",
    "1983/9/2",
    "1983/9/3",
    "1983/9/4",
    "1983/9/5",
    "1983/9/6",
    "1983/9/7",
    "1983/9/8",
    "1983/9/9",
    "1983/9/10",
    "1983/9/11",
    "1983/9/12",
    "1983/9/13",
    "1983/9/14",
    "1983/9/15",
    "1983/9/16",
    "1983/9/17",
    "1983/9/18",
    "1983/9/19",
    "1983/9/20",
    "1983/9/21",
    "1983/9/22",
    "1983/9/23",
    "1983/9/24",
    "1983/9/25",
    "1983/9/26",
    "1983/9/27",
    "1983/9/28",
    "1983/9/29",
    "1983/9/30",
    "1983/10/1",
    "1983/10/2",
    "1983/10/3",
    "1983/10/4",
    "1983/10/5",
    "1983/10/6",
    "1983/10/7",
    "1983/10/8",
    "1983/10/9",
    "1983/10/10",
    "1983/10/11",
    "1983/10/12",
    "1983/10/13",
    "1983/10/14",
    "1983/10/15",
    "1983/10/16",
    "1983/10/17",
    "1983/10/18",
    "1983/10/19",
    "1983/10/20",
    "1983/10/21",
    "1983/10/22",
    "1983/10/23",
    "1983/10/24",
    "1983/10/25",
    "1983/10/26",
    "1983/10/27",
    "1983/10/28",
    "1983/10/29",
    "1983/10/30",
    "1983/10/31",
    "1983/11/1",
    "1983/11/2",
    "1983/11/3",
    "1983/11/4",
    "1983/11/5",
    "1983/11/6",
    "1983/11/7",
    "1983/11/8",
    "1983/11/9",
    "1983/11/10",
    "1983/11/11",
    "1983/11/12",
    "1983/11/13",
    "1983/11/14",
    "1983/11/15",
    "1983/11/16",
    "1983/11/17",
    "1983/11/18",
    "1983/11/19",
    "1983/11/20",
    "1983/11/21",
    "1983/11/22",
    "1983/11/23",
    "1983/11/24",
    "1983/11/25",
    "1983/11/26",
    "1983/11/27",
    "1983/11/28",
    "1983/11/29",
    "1983/11/30",
    "1983/12/1",
    "1983/12/2",
    "1983/12/3",
    "1983/12/4",
    "1983/12/5",
    "1983/12/6",
    "1983/12/7",
    "1983/12/8",
    "1983/12/9",
    "1983/12/10",
    "1983/12/11",
    "1983/12/12",
    "1983/12/13",
    "1983/12/14",
    "1983/12/15",
    "1983/12/16",
    "1983/12/17",
    "1983/12/18",
    "1983/12/19",
    "1983/12/20",
    "1983/12/21",
    "1983/12/22",
    "1983/12/23",
    "1983/12/24",
    "1983/12/25",
    "1983/12/26",
    "1983/12/27",
    "1983/12/28",
    "1983/12/29",
    "1983/12/30",
    "1983/12/31",
    "1984/1/1",
    "1984/1/2",
    "1984/1/3",
    "1984/1/4",
    "1984/1/5",
    "1984/1/6",
    "1984/1/7",
    "1984/1/8",
    "1984/1/9",
    "1984/1/10",
    "1984/1/11",
    "1984/1/12",
    "1984/1/13",
    "1984/1/14",
    "1984/1/15",
    "1984/1/16",
    "1984/1/17",
    "1984/1/18",
    "1984/1/19",
    "1984/1/20",
    "1984/1/21",
    "1984/1/22",
    "1984/1/23",
    "1984/1/24",
    "1984/1/25",
    "1984/1/26",
    "1984/1/27",
    "1984/1/28",
    "1984/1/29",
    "1984/1/30",
    "1984/1/31",
    "1984/2/1",
    "1984/2/2",
    "1984/2/3",
    "1984/2/4",
    "1984/2/5",
    "1984/2/6",
    "1984/2/7",
    "1984/2/8",
    "1984/2/9",
    "1984/2/10",
    "1984/2/11",
    "1984/2/12",
    "1984/2/13",
    "1984/2/14",
    "1984/2/15",
    "1984/2/16",
    "1984/2/17",
    "1984/2/18",
    "1984/2/19",
    "1984/2/20",
    "1984/2/21",
    "1984/2/22",
    "1984/2/23",
    "1984/2/24",
    "1984/2/25",
    "1984/2/26",
    "1984/2/27",
    "1984/2/28",
    "1984/2/29",
    "1984/3/1",
    "1984/3/2",
    "1984/3/3",
    "1984/3/4",
    "1984/3/5",
    "1984/3/6",
    "1984/3/7",
    "1984/3/8",
    "1984/3/9",
    "1984/3/10",
    "1984/3/11",
    "1984/3/12",
    "1984/3/13",
    "1984/3/14",
    "1984/3/15",
    "1984/3/16",
    "1984/3/17",
    "1984/3/18",
    "1984/3/19",
    "1984/3/20",
    "1984/3/21",
    "1984/3/22",
    "1984/3/23",
    "1984/3/24",
    "1984/3/25",
    "1984/3/26",
    "1984/3/27",
    "1984/3/28",
    "1984/3/29",
    "1984/3/30",
    "1984/3/31",
    "1984/4/1",
    "1984/4/2",
    "1984/4/3",
    "1984/4/4",
    "1984/4/5",
    "1984/4/6",
    "1984/4/7",
    "1984/4/8",
    "1984/4/9",
    "1984/4/10",
    "1984/4/11",
    "1984/4/12",
    "1984/4/13",
    "1984/4/14",
    "1984/4/15",
    "1984/4/16",
    "1984/4/17",
    "1984/4/18",
    "1984/4/19",
    "1984/4/20",
    "1984/4/21",
    "1984/4/22",
    "1984/4/23",
    "1984/4/24",
    "1984/4/25",
    "1984/4/26",
    "1984/4/27",
    "1984/4/28",
    "1984/4/29",
    "1984/4/30",
    "1984/5/1",
    "1984/5/2",
    "1984/5/3",
    "1984/5/4",
    "1984/5/5",
    "1984/5/6",
    "1984/5/7",
    "1984/5/8",
    "1984/5/9",
    "1984/5/10",
    "1984/5/11",
    "1984/5/12",
    "1984/5/13",
    "1984/5/14",
    "1984/5/15",
    "1984/5/16",
    "1984/5/17",
    "1984/5/18",
    "1984/5/19",
    "1984/5/20",
    "1984/5/21",
    "1984/5/22",
    "1984/5/23",
    "1984/5/24",
    "1984/5/25",
    "1984/5/26",
    "1984/5/27",
    "1984/5/28",
    "1984/5/29",
    "1984/5/30",
    "1984/5/31",
    "1984/6/1",
    "1984/6/2",
    "1984/6/3",
    "1984/6/4",
    "1984/6/5",
    "1984/6/6",
    "1984/6/7",
    "1984/6/8",
    "1984/6/9",
    "1984/6/10",
    "1984/6/11",
    "1984/6/12",
    "1984/6/13",
    "1984/6/14",
    "1984/6/15",
    "1984/6/16",
    "1984/6/17",
    "1984/6/18",
    "1984/6/19",
    "1984/6/20",
    "1984/6/21",
    "1984/6/22",
    "1984/6/23",
    "1984/6/24",
    "1984/6/25",
    "1984/6/26",
    "1984/6/27",
    "1984/6/28",
    "1984/6/29",
    "1984/6/30",
    "1984/7/1",
    "1984/7/2",
    "1984/7/3",
    "1984/7/4",
    "1984/7/5",
    "1984/7/6",
    "1984/7/7",
    "1984/7/8",
    "1984/7/9",
    "1984/7/10",
    "1984/7/11",
    "1984/7/12",
    "1984/7/13",
    "1984/7/14",
    "1984/7/15",
    "1984/7/16",
    "1984/7/17",
    "1984/7/18",
    "1984/7/19",
    "1984/7/20",
    "1984/7/21",
    "1984/7/22",
    "1984/7/23",
    "1984/7/24",
    "1984/7/25",
    "1984/7/26",
    "1984/7/27",
    "1984/7/28",
    "1984/7/29",
    "1984/7/30",
    "1984/7/31",
    "1984/8/1",
    "1984/8/2",
    "1984/8/3",
    "1984/8/4",
    "1984/8/5",
    "1984/8/6",
    "1984/8/7",
    "1984/8/8",
    "1984/8/9",
    "1984/8/10",
    "1984/8/11",
    "1984/8/12",
    "1984/8/13",
    "1984/8/14",
    "1984/8/15",
    "1984/8/16",
    "1984/8/17",
    "1984/8/18",
    "1984/8/19",
    "1984/8/20",
    "1984/8/21",
    "1984/8/22",
    "1984/8/23",
    "1984/8/24",
    "1984/8/25",
    "1984/8/26",
    "1984/8/27",
    "1984/8/28",
    "1984/8/29",
    "1984/8/30",
    "1984/8/31",
    "1984/9/1",
    "1984/9/2",
    "1984/9/3",
    "1984/9/4",
    "1984/9/5",
    "1984/9/6",
    "1984/9/7",
    "1984/9/8",
    "1984/9/9",
    "1984/9/10",
    "1984/9/11",
    "1984/9/12",
    "1984/9/13",
    "1984/9/14",
    "1984/9/15",
    "1984/9/16",
    "1984/9/17",
    "1984/9/18",
    "1984/9/19",
    "1984/9/20",
    "1984/9/21",
    "1984/9/22",
    "1984/9/23",
    "1984/9/24",
    "1984/9/25",
    "1984/9/26",
    "1984/9/27",
    "1984/9/28",
    "1984/9/29",
    "1984/9/30",
    "1984/10/1",
    "1984/10/2",
    "1984/10/3",
    "1984/10/4",
    "1984/10/5",
    "1984/10/6",
    "1984/10/7",
    "1984/10/8",
    "1984/10/9",
    "1984/10/10",
    "1984/10/11",
    "1984/10/12",
    "1984/10/13",
    "1984/10/14",
    "1984/10/15",
    "1984/10/16",
    "1984/10/17",
    "1984/10/18",
    "1984/10/19",
    "1984/10/20",
    "1984/10/21",
    "1984/10/22",
    "1984/10/23",
    "1984/10/24",
    "1984/10/25",
    "1984/10/26",
    "1984/10/27",
    "1984/10/28",
    "1984/10/29",
    "1984/10/30",
    "1984/10/31",
    "1984/11/1",
    "1984/11/2",
    "1984/11/3",
    "1984/11/4",
    "1984/11/5",
    "1984/11/6",
    "1984/11/7",
    "1984/11/8",
    "1984/11/9",
    "1984/11/10",
    "1984/11/11",
    "1984/11/12",
    "1984/11/13",
    "1984/11/14",
    "1984/11/15",
    "1984/11/16",
    "1984/11/17",
    "1984/11/18",
    "1984/11/19",
    "1984/11/20",
    "1984/11/21",
    "1984/11/22",
    "1984/11/23",
    "1984/11/24",
    "1984/11/25",
    "1984/11/26",
    "1984/11/27",
    "1984/11/28",
    "1984/11/29",
    "1984/11/30",
    "1984/12/1",
    "1984/12/2",
    "1984/12/3",
    "1984/12/4",
    "1984/12/5",
    "1984/12/6",
    "1984/12/7",
    "1984/12/8",
    "1984/12/9",
    "1984/12/10",
    "1984/12/11",
    "1984/12/12",
    "1984/12/13",
    "1984/12/14",
    "1984/12/15",
    "1984/12/16",
    "1984/12/17",
    "1984/12/18",
    "1984/12/19",
    "1984/12/20",
    "1984/12/21",
    "1984/12/22",
    "1984/12/23",
    "1984/12/24",
    "1984/12/25",
    "1984/12/26",
    "1984/12/27",
    "1984/12/28",
    "1984/12/29",
    "1984/12/30",
    "1984/12/31",
    "1985/1/1",
    "1985/1/2",
    "1985/1/3",
    "1985/1/4",
    "1985/1/5",
    "1985/1/6",
    "1985/1/7",
    "1985/1/8",
    "1985/1/9",
    "1985/1/10",
    "1985/1/11",
    "1985/1/12",
    "1985/1/13",
    "1985/1/14",
    "1985/1/15",
    "1985/1/16",
    "1985/1/17",
    "1985/1/18",
    "1985/1/19",
    "1985/1/20",
    "1985/1/21",
    "1985/1/22",
    "1985/1/23",
    "1985/1/24",
    "1985/1/25",
    "1985/1/26",
    "1985/1/27",
    "1985/1/28",
    "1985/1/29",
    "1985/1/30",
    "1985/1/31",
    "1985/2/1",
    "1985/2/2",
    "1985/2/3",
    "1985/2/4",
    "1985/2/5",
    "1985/2/6",
    "1985/2/7",
    "1985/2/8",
    "1985/2/9",
    "1985/2/10",
    "1985/2/11",
    "1985/2/12",
    "1985/2/13",
    "1985/2/14",
    "1985/2/15",
    "1985/2/16",
    "1985/2/17",
    "1985/2/18",
    "1985/2/19",
    "1985/2/20",
    "1985/2/21",
    "1985/2/22",
    "1985/2/23",
    "1985/2/24",
    "1985/2/25",
    "1985/2/26",
    "1985/2/27",
    "1985/2/28",
    "1985/3/1",
    "1985/3/2",
    "1985/3/3",
    "1985/3/4",
    "1985/3/5",
    "1985/3/6",
    "1985/3/7",
    "1985/3/8",
    "1985/3/9",
    "1985/3/10",
    "1985/3/11",
    "1985/3/12",
    "1985/3/13",
    "1985/3/14",
    "1985/3/15",
    "1985/3/16",
    "1985/3/17",
    "1985/3/18",
    "1985/3/19",
    "1985/3/20",
    "1985/3/21",
    "1985/3/22",
    "1985/3/23",
    "1985/3/24",
    "1985/3/25",
    "1985/3/26",
    "1985/3/27",
    "1985/3/28",
    "1985/3/29",
    "1985/3/30",
    "1985/3/31",
    "1985/4/1",
    "1985/4/2",
    "1985/4/3",
    "1985/4/4",
    "1985/4/5",
    "1985/4/6",
    "1985/4/7",
    "1985/4/8",
    "1985/4/9",
    "1985/4/10",
    "1985/4/11",
    "1985/4/12",
    "1985/4/13",
    "1985/4/14",
    "1985/4/15",
    "1985/4/16",
    "1985/4/17",
    "1985/4/18",
    "1985/4/19",
    "1985/4/20",
    "1985/4/21",
    "1985/4/22",
    "1985/4/23",
    "1985/4/24",
    "1985/4/25",
    "1985/4/26",
    "1985/4/27",
    "1985/4/28",
    "1985/4/29",
    "1985/4/30",
    "1985/5/1",
    "1985/5/2",
    "1985/5/3",
    "1985/5/4",
    "1985/5/5",
    "1985/5/6",
    "1985/5/7",
    "1985/5/8",
    "1985/5/9",
    "1985/5/10",
    "1985/5/11",
    "1985/5/12",
    "1985/5/13",
    "1985/5/14",
    "1985/5/15",
    "1985/5/16",
    "1985/5/17",
    "1985/5/18",
    "1985/5/19",
    "1985/5/20",
    "1985/5/21",
    "1985/5/22",
    "1985/5/23",
    "1985/5/24",
    "1985/5/25",
    "1985/5/26",
    "1985/5/27",
    "1985/5/28",
    "1985/5/29",
    "1985/5/30",
    "1985/5/31",
    "1985/6/1",
    "1985/6/2",
    "1985/6/3",
    "1985/6/4",
    "1985/6/5",
    "1985/6/6",
    "1985/6/7",
    "1985/6/8",
    "1985/6/9",
    "1985/6/10",
    "1985/6/11",
    "1985/6/12",
    "1985/6/13",
    "1985/6/14",
    "1985/6/15",
    "1985/6/16",
    "1985/6/17",
    "1985/6/18",
    "1985/6/19",
    "1985/6/20",
    "1985/6/21",
    "1985/6/22",
    "1985/6/23",
    "1985/6/24",
    "1985/6/25",
    "1985/6/26",
    "1985/6/27",
    "1985/6/28",
    "1985/6/29",
    "1985/6/30",
    "1985/7/1",
    "1985/7/2",
    "1985/7/3",
    "1985/7/4",
    "1985/7/5",
    "1985/7/6",
    "1985/7/7",
    "1985/7/8",
    "1985/7/9",
    "1985/7/10",
    "1985/7/11",
    "1985/7/12",
    "1985/7/13",
    "1985/7/14",
    "1985/7/15",
    "1985/7/16",
    "1985/7/17",
    "1985/7/18",
    "1985/7/19",
    "1985/7/20",
    "1985/7/21",
    "1985/7/22",
    "1985/7/23",
    "1985/7/24",
    "1985/7/25",
    "1985/7/26",
    "1985/7/27",
    "1985/7/28",
    "1985/7/29",
    "1985/7/30",
    "1985/7/31",
    "1985/8/1",
    "1985/8/2",
    "1985/8/3",
    "1985/8/4",
    "1985/8/5",
    "1985/8/6",
    "1985/8/7",
    "1985/8/8",
    "1985/8/9",
    "1985/8/10",
    "1985/8/11",
    "1985/8/12",
    "1985/8/13",
    "1985/8/14",
    "1985/8/15",
    "1985/8/16",
    "1985/8/17",
    "1985/8/18",
    "1985/8/19",
    "1985/8/20",
    "1985/8/21",
    "1985/8/22",
    "1985/8/23",
    "1985/8/24",
    "1985/8/25",
    "1985/8/26",
    "1985/8/27",
    "1985/8/28",
    "1985/8/29",
    "1985/8/30",
    "1985/8/31",
    "1985/9/1",
    "1985/9/2",
    "1985/9/3",
    "1985/9/4",
    "1985/9/5",
    "1985/9/6",
    "1985/9/7",
    "1985/9/8",
    "1985/9/9",
    "1985/9/10",
    "1985/9/11",
    "1985/9/12",
    "1985/9/13",
    "1985/9/14",
    "1985/9/15",
    "1985/9/16",
    "1985/9/17",
    "1985/9/18",
    "1985/9/19",
    "1985/9/20",
    "1985/9/21",
    "1985/9/22",
    "1985/9/23",
    "1985/9/24",
    "1985/9/25",
    "1985/9/26",
    "1985/9/27",
    "1985/9/28",
    "1985/9/29",
    "1985/9/30",
    "1985/10/1",
    "1985/10/2",
    "1985/10/3",
    "1985/10/4",
    "1985/10/5",
    "1985/10/6",
    "1985/10/7",
    "1985/10/8",
    "1985/10/9",
    "1985/10/10",
    "1985/10/11",
    "1985/10/12",
    "1985/10/13",
    "1985/10/14",
    "1985/10/15",
    "1985/10/16",
    "1985/10/17",
    "1985/10/18",
    "1985/10/19",
    "1985/10/20",
    "1985/10/21",
    "1985/10/22",
    "1985/10/23",
    "1985/10/24",
    "1985/10/25",
    "1985/10/26",
    "1985/10/27",
    "1985/10/28",
    "1985/10/29",
    "1985/10/30",
    "1985/10/31",
    "1985/11/1",
    "1985/11/2",
    "1985/11/3",
    "1985/11/4",
    "1985/11/5",
    "1985/11/6",
    "1985/11/7",
    "1985/11/8",
    "1985/11/9",
    "1985/11/10",
    "1985/11/11",
    "1985/11/12",
    "1985/11/13",
    "1985/11/14",
    "1985/11/15",
    "1985/11/16",
    "1985/11/17",
    "1985/11/18",
    "1985/11/19",
    "1985/11/20",
    "1985/11/21",
    "1985/11/22",
    "1985/11/23",
    "1985/11/24",
    "1985/11/25",
    "1985/11/26",
    "1985/11/27",
    "1985/11/28",
    "1985/11/29",
    "1985/11/30",
    "1985/12/1",
    "1985/12/2",
    "1985/12/3",
    "1985/12/4",
    "1985/12/5",
    "1985/12/6",
    "1985/12/7",
    "1985/12/8",
    "1985/12/9",
    "1985/12/10",
    "1985/12/11",
    "1985/12/12",
    "1985/12/13",
    "1985/12/14",
    "1985/12/15",
    "1985/12/16",
    "1985/12/17",
    "1985/12/18",
    "1985/12/19",
    "1985/12/20",
    "1985/12/21",
    "1985/12/22",
    "1985/12/23",
    "1985/12/24",
    "1985/12/25",
    "1985/12/26",
    "1985/12/27",
    "1985/12/28",
    "1985/12/29",
    "1985/12/30",
    "1985/12/31",
    "1986/1/1",
    "1986/1/2",
    "1986/1/3",
    "1986/1/4",
    "1986/1/5",
    "1986/1/6",
    "1986/1/7",
    "1986/1/8",
    "1986/1/9",
    "1986/1/10",
    "1986/1/11",
    "1986/1/12",
    "1986/1/13",
    "1986/1/14",
    "1986/1/15",
    "1986/1/16",
    "1986/1/17",
    "1986/1/18",
    "1986/1/19",
    "1986/1/20",
    "1986/1/21",
    "1986/1/22",
    "1986/1/23",
    "1986/1/24",
    "1986/1/25",
    "1986/1/26",
    "1986/1/27",
    "1986/1/28",
    "1986/1/29",
    "1986/1/30",
    "1986/1/31",
    "1986/2/1",
    "1986/2/2",
    "1986/2/3",
    "1986/2/4",
    "1986/2/5",
    "1986/2/6",
    "1986/2/7",
    "1986/2/8",
    "1986/2/9",
    "1986/2/10",
    "1986/2/11",
    "1986/2/12",
    "1986/2/13",
    "1986/2/14",
    "1986/2/15",
    "1986/2/16",
    "1986/2/17",
    "1986/2/18",
    "1986/2/19",
    "1986/2/20",
    "1986/2/21",
    "1986/2/22",
    "1986/2/23",
    "1986/2/24",
    "1986/2/25",
    "1986/2/26",
    "1986/2/27",
    "1986/2/28",
    "1986/3/1",
    "1986/3/2",
    "1986/3/3",
    "1986/3/4",
    "1986/3/5",
    "1986/3/6",
    "1986/3/7",
    "1986/3/8",
    "1986/3/9",
    "1986/3/10",
    "1986/3/11",
    "1986/3/12",
    "1986/3/13",
    "1986/3/14",
    "1986/3/15",
    "1986/3/16",
    "1986/3/17",
    "1986/3/18",
    "1986/3/19",
    "1986/3/20",
    "1986/3/21",
    "1986/3/22",
    "1986/3/23",
    "1986/3/24",
    "1986/3/25",
    "1986/3/26",
    "1986/3/27",
    "1986/3/28",
    "1986/3/29",
    "1986/3/30",
    "1986/3/31",
    "1986/4/1",
    "1986/4/2",
    "1986/4/3",
    "1986/4/4",
    "1986/4/5",
    "1986/4/6",
    "1986/4/7",
    "1986/4/8",
    "1986/4/9",
    "1986/4/10",
    "1986/4/11",
    "1986/4/12",
    "1986/4/13",
    "1986/4/14",
    "1986/4/15",
    "1986/4/16",
    "1986/4/17",
    "1986/4/18",
    "1986/4/19",
    "1986/4/20",
    "1986/4/21",
    "1986/4/22",
    "1986/4/23",
    "1986/4/24",
    "1986/4/25",
    "1986/4/26",
    "1986/4/27",
    "1986/4/28",
    "1986/4/29",
    "1986/4/30",
    "1986/5/1",
    "1986/5/2",
    "1986/5/3",
    "1986/5/4",
    "1986/5/5",
    "1986/5/6",
    "1986/5/7",
    "1986/5/8",
    "1986/5/9",
    "1986/5/10",
    "1986/5/11",
    "1986/5/12",
    "1986/5/13",
    "1986/5/14",
    "1986/5/15",
    "1986/5/16",
    "1986/5/17",
    "1986/5/18",
    "1986/5/19",
    "1986/5/20",
    "1986/5/21",
    "1986/5/22",
    "1986/5/23",
    "1986/5/24",
    "1986/5/25",
    "1986/5/26",
    "1986/5/27",
    "1986/5/28",
    "1986/5/29",
    "1986/5/30",
    "1986/5/31",
    "1986/6/1",
    "1986/6/2",
    "1986/6/3",
    "1986/6/4",
    "1986/6/5",
    "1986/6/6",
    "1986/6/7",
    "1986/6/8",
    "1986/6/9",
    "1986/6/10",
    "1986/6/11",
    "1986/6/12",
    "1986/6/13",
    "1986/6/14",
    "1986/6/15",
    "1986/6/16",
    "1986/6/17",
    "1986/6/18",
    "1986/6/19",
    "1986/6/20",
    "1986/6/21",
    "1986/6/22",
    "1986/6/23",
    "1986/6/24",
    "1986/6/25",
    "1986/6/26",
    "1986/6/27",
    "1986/6/28",
    "1986/6/29",
    "1986/6/30",
    "1986/7/1",
    "1986/7/2",
    "1986/7/3",
    "1986/7/4",
    "1986/7/5",
    "1986/7/6",
    "1986/7/7",
    "1986/7/8",
    "1986/7/9",
    "1986/7/10",
    "1986/7/11",
    "1986/7/12",
    "1986/7/13",
    "1986/7/14",
    "1986/7/15",
    "1986/7/16",
    "1986/7/17",
    "1986/7/18",
    "1986/7/19",
    "1986/7/20",
    "1986/7/21",
    "1986/7/22",
    "1986/7/23",
    "1986/7/24",
    "1986/7/25",
    "1986/7/26",
    "1986/7/27",
    "1986/7/28",
    "1986/7/29",
    "1986/7/30",
    "1986/7/31",
    "1986/8/1",
    "1986/8/2",
    "1986/8/3",
    "1986/8/4",
    "1986/8/5",
    "1986/8/6",
    "1986/8/7",
    "1986/8/8",
    "1986/8/9",
    "1986/8/10",
    "1986/8/11",
    "1986/8/12",
    "1986/8/13",
    "1986/8/14",
    "1986/8/15",
    "1986/8/16",
    "1986/8/17",
    "1986/8/18",
    "1986/8/19",
    "1986/8/20",
    "1986/8/21",
    "1986/8/22",
    "1986/8/23",
    "1986/8/24",
    "1986/8/25",
    "1986/8/26",
    "1986/8/27",
    "1986/8/28",
    "1986/8/29",
    "1986/8/30",
    "1986/8/31",
    "1986/9/1",
    "1986/9/2",
    "1986/9/3",
    "1986/9/4",
    "1986/9/5",
    "1986/9/6",
    "1986/9/7",
    "1986/9/8",
    "1986/9/9",
    "1986/9/10",
    "1986/9/11",
    "1986/9/12",
    "1986/9/13",
    "1986/9/14",
    "1986/9/15",
    "1986/9/16",
    "1986/9/17",
    "1986/9/18",
    "1986/9/19",
    "1986/9/20",
    "1986/9/21",
    "1986/9/22",
    "1986/9/23",
    "1986/9/24",
    "1986/9/25",
    "1986/9/26",
    "1986/9/27",
    "1986/9/28",
    "1986/9/29",
    "1986/9/30",
    "1986/10/1",
    "1986/10/2",
    "1986/10/3",
    "1986/10/4",
    "1986/10/5",
    "1986/10/6",
    "1986/10/7",
    "1986/10/8",
    "1986/10/9",
    "1986/10/10",
    "1986/10/11",
    "1986/10/12",
    "1986/10/13",
    "1986/10/14",
    "1986/10/15",
    "1986/10/16",
    "1986/10/17",
    "1986/10/18",
    "1986/10/19",
    "1986/10/20",
    "1986/10/21",
    "1986/10/22",
    "1986/10/23",
    "1986/10/24",
    "1986/10/25",
    "1986/10/26",
    "1986/10/27",
    "1986/10/28",
    "1986/10/29",
    "1986/10/30",
    "1986/10/31",
    "1986/11/1",
    "1986/11/2",
    "1986/11/3",
    "1986/11/4",
    "1986/11/5",
    "1986/11/6",
    "1986/11/7",
    "1986/11/8",
    "1986/11/9",
    "1986/11/10",
    "1986/11/11",
    "1986/11/12",
    "1986/11/13",
    "1986/11/14",
    "1986/11/15",
    "1986/11/16",
    "1986/11/17",
    "1986/11/18",
    "1986/11/19",
    "1986/11/20",
    "1986/11/21",
    "1986/11/22",
    "1986/11/23",
    "1986/11/24",
    "1986/11/25",
    "1986/11/26",
    "1986/11/27",
    "1986/11/28",
    "1986/11/29",
    "1986/11/30",
    "1986/12/1",
    "1986/12/2",
    "1986/12/3",
    "1986/12/4",
    "1986/12/5",
    "1986/12/6",
    "1986/12/7",
    "1986/12/8",
    "1986/12/9",
    "1986/12/10",
    "1986/12/11",
    "1986/12/12",
    "1986/12/13",
    "1986/12/14",
    "1986/12/15",
    "1986/12/16",
    "1986/12/17",
    "1986/12/18",
    "1986/12/19",
    "1986/12/20",
    "1986/12/21",
    "1986/12/22",
    "1986/12/23",
    "1986/12/24",
    "1986/12/25",
    "1986/12/26",
    "1986/12/27",
    "1986/12/28",
    "1986/12/29",
    "1986/12/30",
    "1986/12/31",
    "1987/1/1",
    "1987/1/2",
    "1987/1/3",
    "1987/1/4",
    "1987/1/5",
    "1987/1/6",
    "1987/1/7",
    "1987/1/8",
    "1987/1/9",
    "1987/1/10",
    "1987/1/11",
    "1987/1/12",
    "1987/1/13",
    "1987/1/14",
    "1987/1/15",
    "1987/1/16",
    "1987/1/17",
    "1987/1/18",
    "1987/1/19",
    "1987/1/20",
    "1987/1/21",
    "1987/1/22",
    "1987/1/23",
    "1987/1/24",
    "1987/1/25",
    "1987/1/26",
    "1987/1/27",
    "1987/1/28",
    "1987/1/29",
    "1987/1/30",
    "1987/1/31",
    "1987/2/1",
    "1987/2/2",
    "1987/2/3",
    "1987/2/4",
    "1987/2/5",
    "1987/2/6",
    "1987/2/7",
    "1987/2/8",
    "1987/2/9",
    "1987/2/10",
    "1987/2/11",
    "1987/2/12",
    "1987/2/13",
    "1987/2/14",
    "1987/2/15",
    "1987/2/16",
    "1987/2/17",
    "1987/2/18",
    "1987/2/19",
    "1987/2/20",
    "1987/2/21",
    "1987/2/22",
    "1987/2/23",
    "1987/2/24",
    "1987/2/25",
    "1987/2/26",
    "1987/2/27",
    "1987/2/28",
    "1987/3/1",
    "1987/3/2",
    "1987/3/3",
    "1987/3/4",
    "1987/3/5",
    "1987/3/6",
    "1987/3/7",
    "1987/3/8",
    "1987/3/9",
    "1987/3/10",
    "1987/3/11",
    "1987/3/12",
    "1987/3/13",
    "1987/3/14",
    "1987/3/15",
    "1987/3/16",
    "1987/3/17",
    "1987/3/18",
    "1987/3/19",
    "1987/3/20",
    "1987/3/21",
    "1987/3/22",
    "1987/3/23",
    "1987/3/24",
    "1987/3/25",
    "1987/3/26",
    "1987/3/27",
    "1987/3/28",
    "1987/3/29",
    "1987/3/30",
    "1987/3/31",
    "1987/4/1",
    "1987/4/2",
    "1987/4/3",
    "1987/4/4",
    "1987/4/5",
    "1987/4/6",
    "1987/4/7",
    "1987/4/8",
    "1987/4/9",
    "1987/4/10",
    "1987/4/11",
    "1987/4/12",
    "1987/4/13",
    "1987/4/14",
    "1987/4/15",
    "1987/4/16",
    "1987/4/17",
    "1987/4/18",
    "1987/4/19",
    "1987/4/20",
    "1987/4/21",
    "1987/4/22",
    "1987/4/23",
    "1987/4/24",
    "1987/4/25",
    "1987/4/26",
    "1987/4/27",
    "1987/4/28",
    "1987/4/29",
    "1987/4/30",
    "1987/5/1",
    "1987/5/2",
    "1987/5/3",
    "1987/5/4",
    "1987/5/5",
    "1987/5/6",
    "1987/5/7",
    "1987/5/8",
    "1987/5/9",
    "1987/5/10",
    "1987/5/11",
    "1987/5/12",
    "1987/5/13",
    "1987/5/14",
    "1987/5/15",
    "1987/5/16",
    "1987/5/17",
    "1987/5/18",
    "1987/5/19",
    "1987/5/20",
    "1987/5/21",
    "1987/5/22",
    "1987/5/23",
    "1987/5/24",
    "1987/5/25",
    "1987/5/26",
    "1987/5/27",
    "1987/5/28",
    "1987/5/29",
    "1987/5/30",
    "1987/5/31",
    "1987/6/1",
    "1987/6/2",
    "1987/6/3",
    "1987/6/4",
    "1987/6/5",
    "1987/6/6",
    "1987/6/7",
    "1987/6/8",
    "1987/6/9",
    "1987/6/10",
    "1987/6/11",
    "1987/6/12",
    "1987/6/13",
    "1987/6/14",
    "1987/6/15",
    "1987/6/16",
    "1987/6/17",
    "1987/6/18",
    "1987/6/19",
    "1987/6/20",
    "1987/6/21",
    "1987/6/22",
    "1987/6/23",
    "1987/6/24",
    "1987/6/25",
    "1987/6/26",
    "1987/6/27",
    "1987/6/28",
    "1987/6/29",
    "1987/6/30",
    "1987/7/1",
    "1987/7/2",
    "1987/7/3",
    "1987/7/4",
    "1987/7/5",
    "1987/7/6",
    "1987/7/7",
    "1987/7/8",
    "1987/7/9",
    "1987/7/10",
    "1987/7/11",
    "1987/7/12",
    "1987/7/13",
    "1987/7/14",
    "1987/7/15",
    "1987/7/16",
    "1987/7/17",
    "1987/7/18",
    "1987/7/19",
    "1987/7/20",
    "1987/7/21",
    "1987/7/22",
    "1987/7/23",
    "1987/7/24",
    "1987/7/25",
    "1987/7/26",
    "1987/7/27",
    "1987/7/28",
    "1987/7/29",
    "1987/7/30",
    "1987/7/31",
    "1987/8/1",
    "1987/8/2",
    "1987/8/3",
    "1987/8/4",
    "1987/8/5",
    "1987/8/6",
    "1987/8/7",
    "1987/8/8",
    "1987/8/9",
    "1987/8/10",
    "1987/8/11",
    "1987/8/12",
    "1987/8/13",
    "1987/8/14",
    "1987/8/15",
    "1987/8/16",
    "1987/8/17",
    "1987/8/18",
    "1987/8/19",
    "1987/8/20",
    "1987/8/21",
    "1987/8/22",
    "1987/8/23",
    "1987/8/24",
    "1987/8/25",
    "1987/8/26",
    "1987/8/27",
    "1987/8/28",
    "1987/8/29",
    "1987/8/30",
    "1987/8/31",
    "1987/9/1",
    "1987/9/2",
    "1987/9/3",
    "1987/9/4",
    "1987/9/5",
    "1987/9/6",
    "1987/9/7",
    "1987/9/8",
    "1987/9/9",
    "1987/9/10",
    "1987/9/11",
    "1987/9/12",
    "1987/9/13",
    "1987/9/14",
    "1987/9/15",
    "1987/9/16",
    "1987/9/17",
    "1987/9/18",
    "1987/9/19",
    "1987/9/20",
    "1987/9/21",
    "1987/9/22",
    "1987/9/23",
    "1987/9/24",
    "1987/9/25",
    "1987/9/26",
    "1987/9/27",
    "1987/9/28",
    "1987/9/29",
    "1987/9/30",
    "1987/10/1",
    "1987/10/2",
    "1987/10/3",
    "1987/10/4",
    "1987/10/5",
    "1987/10/6",
    "1987/10/7",
    "1987/10/8",
    "1987/10/9",
    "1987/10/10",
    "1987/10/11",
    "1987/10/12",
    "1987/10/13",
    "1987/10/14",
    "1987/10/15",
    "1987/10/16",
    "1987/10/17",
    "1987/10/18",
    "1987/10/19",
    "1987/10/20",
    "1987/10/21",
    "1987/10/22",
    "1987/10/23",
    "1987/10/24",
    "1987/10/25",
    "1987/10/26",
    "1987/10/27",
    "1987/10/28",
    "1987/10/29",
    "1987/10/30",
    "1987/10/31",
    "1987/11/1",
    "1987/11/2",
    "1987/11/3",
    "1987/11/4",
    "1987/11/5",
    "1987/11/6",
    "1987/11/7",
    "1987/11/8",
    "1987/11/9",
    "1987/11/10",
    "1987/11/11",
    "1987/11/12",
    "1987/11/13",
    "1987/11/14",
    "1987/11/15",
    "1987/11/16",
    "1987/11/17",
    "1987/11/18",
    "1987/11/19",
    "1987/11/20",
    "1987/11/21",
    "1987/11/22",
    "1987/11/23",
    "1987/11/24",
    "1987/11/25",
    "1987/11/26",
    "1987/11/27",
    "1987/11/28",
    "1987/11/29",
    "1987/11/30",
    "1987/12/1",
    "1987/12/2",
    "1987/12/3",
    "1987/12/4",
    "1987/12/5",
    "1987/12/6",
    "1987/12/7",
    "1987/12/8",
    "1987/12/9",
    "1987/12/10",
    "1987/12/11",
    "1987/12/12",
    "1987/12/13",
    "1987/12/14",
    "1987/12/15",
    "1987/12/16",
    "1987/12/17",
    "1987/12/18",
    "1987/12/19",
    "1987/12/20",
    "1987/12/21",
    "1987/12/22",
    "1987/12/23",
    "1987/12/24",
    "1987/12/25",
    "1987/12/26",
    "1987/12/27",
    "1987/12/28",
    "1987/12/29",
    "1987/12/30",
    "1987/12/31",
    "1988/1/1",
    "1988/1/2",
    "1988/1/3",
    "1988/1/4",
    "1988/1/5",
    "1988/1/6",
    "1988/1/7",
    "1988/1/8",
    "1988/1/9",
    "1988/1/10",
    "1988/1/11",
    "1988/1/12",
    "1988/1/13",
    "1988/1/14",
    "1988/1/15",
    "1988/1/16",
    "1988/1/17",
    "1988/1/18",
    "1988/1/19",
    "1988/1/20",
    "1988/1/21",
    "1988/1/22",
    "1988/1/23",
    "1988/1/24",
    "1988/1/25",
    "1988/1/26",
    "1988/1/27",
    "1988/1/28",
    "1988/1/29",
    "1988/1/30",
    "1988/1/31",
    "1988/2/1",
    "1988/2/2",
    "1988/2/3",
    "1988/2/4",
    "1988/2/5",
    "1988/2/6",
    "1988/2/7",
    "1988/2/8",
    "1988/2/9",
    "1988/2/10",
    "1988/2/11",
    "1988/2/12",
    "1988/2/13",
    "1988/2/14",
    "1988/2/15",
    "1988/2/16",
    "1988/2/17",
    "1988/2/18",
    "1988/2/19",
    "1988/2/20",
    "1988/2/21",
    "1988/2/22",
    "1988/2/23",
    "1988/2/24",
    "1988/2/25",
    "1988/2/26",
    "1988/2/27",
    "1988/2/28",
    "1988/2/29",
    "1988/3/1",
    "1988/3/2",
    "1988/3/3",
    "1988/3/4",
    "1988/3/5",
    "1988/3/6",
    "1988/3/7",
    "1988/3/8",
    "1988/3/9",
    "1988/3/10",
    "1988/3/11",
    "1988/3/12",
    "1988/3/13",
    "1988/3/14",
    "1988/3/15",
    "1988/3/16",
    "1988/3/17",
    "1988/3/18",
    "1988/3/19",
    "1988/3/20",
    "1988/3/21",
    "1988/3/22",
    "1988/3/23",
    "1988/3/24",
    "1988/3/25",
    "1988/3/26",
    "1988/3/27",
    "1988/3/28",
    "1988/3/29",
    "1988/3/30",
    "1988/3/31",
    "1988/4/1",
    "1988/4/2",
    "1988/4/3",
    "1988/4/4",
    "1988/4/5",
    "1988/4/6",
    "1988/4/7",
    "1988/4/8",
    "1988/4/9",
    "1988/4/10",
    "1988/4/11",
    "1988/4/12",
    "1988/4/13",
    "1988/4/14",
    "1988/4/15",
    "1988/4/16",
    "1988/4/17",
    "1988/4/18",
    "1988/4/19",
    "1988/4/20",
    "1988/4/21",
    "1988/4/22",
    "1988/4/23",
    "1988/4/24",
    "1988/4/25",
    "1988/4/26",
    "1988/4/27",
    "1988/4/28",
    "1988/4/29",
    "1988/4/30",
    "1988/5/1",
    "1988/5/2",
    "1988/5/3",
    "1988/5/4",
    "1988/5/5",
    "1988/5/6",
    "1988/5/7",
    "1988/5/8",
    "1988/5/9",
    "1988/5/10",
    "1988/5/11",
    "1988/5/12",
    "1988/5/13",
    "1988/5/14",
    "1988/5/15",
    "1988/5/16",
    "1988/5/17",
    "1988/5/18",
    "1988/5/19",
    "1988/5/20",
    "1988/5/21",
    "1988/5/22",
    "1988/5/23",
    "1988/5/24",
    "1988/5/25",
    "1988/5/26",
    "1988/5/27",
    "1988/5/28",
    "1988/5/29",
    "1988/5/30",
    "1988/5/31",
    "1988/6/1",
    "1988/6/2",
    "1988/6/3",
    "1988/6/4",
    "1988/6/5",
    "1988/6/6",
    "1988/6/7",
    "1988/6/8",
    "1988/6/9",
    "1988/6/10",
    "1988/6/11",
    "1988/6/12",
    "1988/6/13",
    "1988/6/14",
    "1988/6/15",
    "1988/6/16",
    "1988/6/17",
    "1988/6/18",
    "1988/6/19",
    "1988/6/20",
    "1988/6/21",
    "1988/6/22",
    "1988/6/23",
    "1988/6/24",
    "1988/6/25",
    "1988/6/26",
    "1988/6/27",
    "1988/6/28",
    "1988/6/29",
    "1988/6/30",
    "1988/7/1",
    "1988/7/2",
    "1988/7/3",
    "1988/7/4",
    "1988/7/5",
    "1988/7/6",
    "1988/7/7",
    "1988/7/8",
    "1988/7/9",
    "1988/7/10",
    "1988/7/11",
    "1988/7/12",
    "1988/7/13",
    "1988/7/14",
    "1988/7/15",
    "1988/7/16",
    "1988/7/17",
    "1988/7/18",
    "1988/7/19",
    "1988/7/20",
    "1988/7/21",
    "1988/7/22",
    "1988/7/23",
    "1988/7/24",
    "1988/7/25",
    "1988/7/26",
    "1988/7/27",
    "1988/7/28",
    "1988/7/29",
    "1988/7/30",
    "1988/7/31",
    "1988/8/1",
    "1988/8/2",
    "1988/8/3",
    "1988/8/4",
    "1988/8/5",
    "1988/8/6",
    "1988/8/7",
    "1988/8/8",
    "1988/8/9",
    "1988/8/10",
    "1988/8/11",
    "1988/8/12",
    "1988/8/13",
    "1988/8/14",
    "1988/8/15",
    "1988/8/16",
    "1988/8/17",
    "1988/8/18",
    "1988/8/19",
    "1988/8/20",
    "1988/8/21",
    "1988/8/22",
    "1988/8/23",
    "1988/8/24",
    "1988/8/25",
    "1988/8/26",
    "1988/8/27",
    "1988/8/28",
    "1988/8/29",
    "1988/8/30",
    "1988/8/31",
    "1988/9/1",
    "1988/9/2",
    "1988/9/3",
    "1988/9/4",
    "1988/9/5",
    "1988/9/6",
    "1988/9/7",
    "1988/9/8",
    "1988/9/9",
    "1988/9/10",
    "1988/9/11",
    "1988/9/12",
    "1988/9/13",
    "1988/9/14",
    "1988/9/15",
    "1988/9/16",
    "1988/9/17",
    "1988/9/18",
    "1988/9/19",
    "1988/9/20",
    "1988/9/21",
    "1988/9/22",
    "1988/9/23",
    "1988/9/24",
    "1988/9/25",
    "1988/9/26",
    "1988/9/27",
    "1988/9/28",
    "1988/9/29",
    "1988/9/30",
    "1988/10/1",
    "1988/10/2",
    "1988/10/3",
    "1988/10/4",
    "1988/10/5",
    "1988/10/6",
    "1988/10/7",
    "1988/10/8",
    "1988/10/9",
    "1988/10/10",
    "1988/10/11",
    "1988/10/12",
    "1988/10/13",
    "1988/10/14",
    "1988/10/15",
    "1988/10/16",
    "1988/10/17",
    "1988/10/18",
    "1988/10/19",
    "1988/10/20",
    "1988/10/21",
    "1988/10/22",
    "1988/10/23",
    "1988/10/24",
    "1988/10/25",
    "1988/10/26",
    "1988/10/27",
    "1988/10/28",
    "1988/10/29",
    "1988/10/30",
    "1988/10/31",
    "1988/11/1",
    "1988/11/2",
    "1988/11/3",
    "1988/11/4",
    "1988/11/5",
    "1988/11/6",
    "1988/11/7",
    "1988/11/8",
    "1988/11/9",
    "1988/11/10",
    "1988/11/11",
    "1988/11/12",
    "1988/11/13",
    "1988/11/14",
    "1988/11/15",
    "1988/11/16",
    "1988/11/17",
    "1988/11/18",
    "1988/11/19",
    "1988/11/20",
    "1988/11/21",
    "1988/11/22",
    "1988/11/23",
    "1988/11/24",
    "1988/11/25",
    "1988/11/26",
    "1988/11/27",
    "1988/11/28",
    "1988/11/29",
    "1988/11/30",
    "1988/12/1",
    "1988/12/2",
    "1988/12/3",
    "1988/12/4",
    "1988/12/5",
    "1988/12/6",
    "1988/12/7",
    "1988/12/8",
    "1988/12/9",
    "1988/12/10",
    "1988/12/11",
    "1988/12/12",
    "1988/12/13",
    "1988/12/14",
    "1988/12/15",
    "1988/12/16",
    "1988/12/17",
    "1988/12/18",
    "1988/12/19",
    "1988/12/20",
    "1988/12/21",
    "1988/12/22",
    "1988/12/23",
    "1988/12/24",
    "1988/12/25",
    "1988/12/26",
    "1988/12/27",
    "1988/12/28",
    "1988/12/29",
    "1988/12/30",
    "1988/12/31",
    "1989/1/1",
    "1989/1/2",
    "1989/1/3",
    "1989/1/4",
    "1989/1/5",
    "1989/1/6",
    "1989/1/7",
    "1989/1/8",
    "1989/1/9",
    "1989/1/10",
    "1989/1/11",
    "1989/1/12",
    "1989/1/13",
    "1989/1/14",
    "1989/1/15",
    "1989/1/16",
    "1989/1/17",
    "1989/1/18",
    "1989/1/19",
    "1989/1/20",
    "1989/1/21",
    "1989/1/22",
    "1989/1/23",
    "1989/1/24",
    "1989/1/25",
    "1989/1/26",
    "1989/1/27",
    "1989/1/28",
    "1989/1/29",
    "1989/1/30",
    "1989/1/31",
    "1989/2/1",
    "1989/2/2",
    "1989/2/3",
    "1989/2/4",
    "1989/2/5",
    "1989/2/6",
    "1989/2/7",
    "1989/2/8",
    "1989/2/9",
    "1989/2/10",
    "1989/2/11",
    "1989/2/12",
    "1989/2/13",
    "1989/2/14",
    "1989/2/15",
    "1989/2/16",
    "1989/2/17",
    "1989/2/18",
    "1989/2/19",
    "1989/2/20",
    "1989/2/21",
    "1989/2/22",
    "1989/2/23",
    "1989/2/24",
    "1989/2/25",
    "1989/2/26",
    "1989/2/27",
    "1989/2/28",
    "1989/3/1",
    "1989/3/2",
    "1989/3/3",
    "1989/3/4",
    "1989/3/5",
    "1989/3/6",
    "1989/3/7",
    "1989/3/8",
    "1989/3/9",
    "1989/3/10",
    "1989/3/11",
    "1989/3/12",
    "1989/3/13",
    "1989/3/14",
    "1989/3/15",
    "1989/3/16",
    "1989/3/17",
    "1989/3/18",
    "1989/3/19",
    "1989/3/20",
    "1989/3/21",
    "1989/3/22",
    "1989/3/23",
    "1989/3/24",
    "1989/3/25",
    "1989/3/26",
    "1989/3/27",
    "1989/3/28",
    "1989/3/29",
    "1989/3/30",
    "1989/3/31",
    "1989/4/1",
    "1989/4/2",
    "1989/4/3",
    "1989/4/4",
    "1989/4/5",
    "1989/4/6",
    "1989/4/7",
    "1989/4/8",
    "1989/4/9",
    "1989/4/10",
    "1989/4/11",
    "1989/4/12",
    "1989/4/13",
    "1989/4/14",
    "1989/4/15",
    "1989/4/16",
    "1989/4/17",
    "1989/4/18",
    "1989/4/19",
    "1989/4/20",
    "1989/4/21",
    "1989/4/22",
    "1989/4/23",
    "1989/4/24",
    "1989/4/25",
    "1989/4/26",
    "1989/4/27",
    "1989/4/28",
    "1989/4/29",
    "1989/4/30",
    "1989/5/1",
    "1989/5/2",
    "1989/5/3",
    "1989/5/4",
    "1989/5/5",
    "1989/5/6",
    "1989/5/7",
    "1989/5/8",
    "1989/5/9",
    "1989/5/10",
    "1989/5/11",
    "1989/5/12",
    "1989/5/13",
    "1989/5/14",
    "1989/5/15",
    "1989/5/16",
    "1989/5/17",
    "1989/5/18",
    "1989/5/19",
    "1989/5/20",
    "1989/5/21",
    "1989/5/22",
    "1989/5/23",
    "1989/5/24",
    "1989/5/25",
    "1989/5/26",
    "1989/5/27",
    "1989/5/28",
    "1989/5/29",
    "1989/5/30",
    "1989/5/31",
    "1989/6/1",
    "1989/6/2",
    "1989/6/3",
    "1989/6/4",
    "1989/6/5",
    "1989/6/6",
    "1989/6/7",
    "1989/6/8",
    "1989/6/9",
    "1989/6/10",
    "1989/6/11",
    "1989/6/12",
    "1989/6/13",
    "1989/6/14",
    "1989/6/15",
    "1989/6/16",
    "1989/6/17",
    "1989/6/18",
    "1989/6/19",
    "1989/6/20",
    "1989/6/21",
    "1989/6/22",
    "1989/6/23",
    "1989/6/24",
    "1989/6/25",
    "1989/6/26",
    "1989/6/27",
    "1989/6/28",
    "1989/6/29",
    "1989/6/30",
    "1989/7/1",
    "1989/7/2",
    "1989/7/3",
    "1989/7/4",
    "1989/7/5",
    "1989/7/6",
    "1989/7/7",
    "1989/7/8",
    "1989/7/9",
    "1989/7/10",
    "1989/7/11",
    "1989/7/12",
    "1989/7/13",
    "1989/7/14",
    "1989/7/15",
    "1989/7/16",
    "1989/7/17",
    "1989/7/18",
    "1989/7/19",
    "1989/7/20",
    "1989/7/21",
    "1989/7/22",
    "1989/7/23",
    "1989/7/24",
    "1989/7/25",
    "1989/7/26",
    "1989/7/27",
    "1989/7/28",
    "1989/7/29",
    "1989/7/30",
    "1989/7/31",
    "1989/8/1",
    "1989/8/2",
    "1989/8/3",
    "1989/8/4",
    "1989/8/5",
    "1989/8/6",
    "1989/8/7",
    "1989/8/8",
    "1989/8/9",
    "1989/8/10",
    "1989/8/11",
    "1989/8/12",
    "1989/8/13",
    "1989/8/14",
    "1989/8/15",
    "1989/8/16",
    "1989/8/17",
    "1989/8/18",
    "1989/8/19",
    "1989/8/20",
    "1989/8/21",
    "1989/8/22",
    "1989/8/23",
    "1989/8/24",
    "1989/8/25",
    "1989/8/26",
    "1989/8/27",
    "1989/8/28",
    "1989/8/29",
    "1989/8/30",
    "1989/8/31",
    "1989/9/1",
    "1989/9/2",
    "1989/9/3",
    "1989/9/4",
    "1989/9/5",
    "1989/9/6",
    "1989/9/7",
    "1989/9/8",
    "1989/9/9",
    "1989/9/10",
    "1989/9/11",
    "1989/9/12",
    "1989/9/13",
    "1989/9/14",
    "1989/9/15",
    "1989/9/16",
    "1989/9/17",
    "1989/9/18",
    "1989/9/19",
    "1989/9/20",
    "1989/9/21",
    "1989/9/22",
    "1989/9/23",
    "1989/9/24",
    "1989/9/25",
    "1989/9/26",
    "1989/9/27",
    "1989/9/28",
    "1989/9/29",
    "1989/9/30",
    "1989/10/1",
    "1989/10/2",
    "1989/10/3",
    "1989/10/4",
    "1989/10/5",
    "1989/10/6",
    "1989/10/7",
    "1989/10/8",
    "1989/10/9",
    "1989/10/10",
    "1989/10/11",
    "1989/10/12",
    "1989/10/13",
    "1989/10/14",
    "1989/10/15",
    "1989/10/16",
    "1989/10/17",
    "1989/10/18",
    "1989/10/19",
    "1989/10/20",
    "1989/10/21",
    "1989/10/22",
    "1989/10/23",
    "1989/10/24",
    "1989/10/25",
    "1989/10/26",
    "1989/10/27",
    "1989/10/28",
    "1989/10/29",
    "1989/10/30",
    "1989/10/31",
    "1989/11/1",
    "1989/11/2",
    "1989/11/3",
    "1989/11/4",
    "1989/11/5",
    "1989/11/6",
    "1989/11/7",
    "1989/11/8",
    "1989/11/9",
    "1989/11/10",
    "1989/11/11",
    "1989/11/12",
    "1989/11/13",
    "1989/11/14",
    "1989/11/15",
    "1989/11/16",
    "1989/11/17",
    "1989/11/18",
    "1989/11/19",
    "1989/11/20",
    "1989/11/21",
    "1989/11/22",
    "1989/11/23",
    "1989/11/24",
    "1989/11/25",
    "1989/11/26",
    "1989/11/27",
    "1989/11/28",
    "1989/11/29",
    "1989/11/30",
    "1989/12/1",
    "1989/12/2",
    "1989/12/3",
    "1989/12/4",
    "1989/12/5",
    "1989/12/6",
    "1989/12/7",
    "1989/12/8",
    "1989/12/9",
    "1989/12/10",
    "1989/12/11",
    "1989/12/12",
    "1989/12/13",
    "1989/12/14",
    "1989/12/15",
    "1989/12/16",
    "1989/12/17",
    "1989/12/18",
    "1989/12/19",
    "1989/12/20",
    "1989/12/21",
    "1989/12/22",
    "1989/12/23",
    "1989/12/24",
    "1989/12/25",
    "1989/12/26",
    "1989/12/27",
    "1989/12/28",
    "1989/12/29",
    "1989/12/30",
    "1989/12/31",
    "1990/1/1",
    "1990/1/2",
    "1990/1/3",
    "1990/1/4",
    "1990/1/5",
    "1990/1/6",
    "1990/1/7",
    "1990/1/8",
    "1990/1/9",
    "1990/1/10",
    "1990/1/11",
    "1990/1/12",
    "1990/1/13",
    "1990/1/14",
    "1990/1/15",
    "1990/1/16",
    "1990/1/17",
    "1990/1/18",
    "1990/1/19",
    "1990/1/20",
    "1990/1/21",
    "1990/1/22",
    "1990/1/23",
    "1990/1/24",
    "1990/1/25",
    "1990/1/26",
    "1990/1/27",
    "1990/1/28",
    "1990/1/29",
    "1990/1/30",
    "1990/1/31",
    "1990/2/1",
    "1990/2/2",
    "1990/2/3",
    "1990/2/4",
    "1990/2/5",
    "1990/2/6",
    "1990/2/7",
    "1990/2/8",
    "1990/2/9",
    "1990/2/10",
    "1990/2/11",
    "1990/2/12",
    "1990/2/13",
    "1990/2/14",
    "1990/2/15",
    "1990/2/16",
    "1990/2/17",
    "1990/2/18",
    "1990/2/19",
    "1990/2/20",
    "1990/2/21",
    "1990/2/22",
    "1990/2/23",
    "1990/2/24",
    "1990/2/25",
    "1990/2/26",
    "1990/2/27",
    "1990/2/28",
    "1990/3/1",
    "1990/3/2",
    "1990/3/3",
    "1990/3/4",
    "1990/3/5",
    "1990/3/6",
    "1990/3/7",
    "1990/3/8",
    "1990/3/9",
    "1990/3/10",
    "1990/3/11",
    "1990/3/12",
    "1990/3/13",
    "1990/3/14",
    "1990/3/15",
    "1990/3/16",
    "1990/3/17",
    "1990/3/18",
    "1990/3/19",
    "1990/3/20",
    "1990/3/21",
    "1990/3/22",
    "1990/3/23",
    "1990/3/24",
    "1990/3/25",
    "1990/3/26",
    "1990/3/27",
    "1990/3/28",
    "1990/3/29",
    "1990/3/30",
    "1990/3/31",
    "1990/4/1",
    "1990/4/2",
    "1990/4/3",
    "1990/4/4",
    "1990/4/5",
    "1990/4/6",
    "1990/4/7",
    "1990/4/8",
    "1990/4/9",
    "1990/4/10",
    "1990/4/11",
    "1990/4/12",
    "1990/4/13",
    "1990/4/14",
    "1990/4/15",
    "1990/4/16",
    "1990/4/17",
    "1990/4/18",
    "1990/4/19",
    "1990/4/20",
    "1990/4/21",
    "1990/4/22",
    "1990/4/23",
    "1990/4/24",
    "1990/4/25",
    "1990/4/26",
    "1990/4/27",
    "1990/4/28",
    "1990/4/29",
    "1990/4/30",
    "1990/5/1",
    "1990/5/2",
    "1990/5/3",
    "1990/5/4",
    "1990/5/5",
    "1990/5/6",
    "1990/5/7",
    "1990/5/8",
    "1990/5/9",
    "1990/5/10",
    "1990/5/11",
    "1990/5/12",
    "1990/5/13",
    "1990/5/14",
    "1990/5/15",
    "1990/5/16",
    "1990/5/17",
    "1990/5/18",
    "1990/5/19",
    "1990/5/20",
    "1990/5/21",
    "1990/5/22",
    "1990/5/23",
    "1990/5/24",
    "1990/5/25",
    "1990/5/26",
    "1990/5/27",
    "1990/5/28",
    "1990/5/29",
    "1990/5/30",
    "1990/5/31",
    "1990/6/1",
    "1990/6/2",
    "1990/6/3",
    "1990/6/4",
    "1990/6/5",
    "1990/6/6",
    "1990/6/7",
    "1990/6/8",
    "1990/6/9",
    "1990/6/10",
    "1990/6/11",
    "1990/6/12",
    "1990/6/13",
    "1990/6/14",
    "1990/6/15",
    "1990/6/16",
    "1990/6/17",
    "1990/6/18",
    "1990/6/19",
    "1990/6/20",
    "1990/6/21",
    "1990/6/22",
    "1990/6/23",
    "1990/6/24",
    "1990/6/25",
    "1990/6/26",
    "1990/6/27",
    "1990/6/28",
    "1990/6/29",
    "1990/6/30",
    "1990/7/1",
    "1990/7/2",
    "1990/7/3",
    "1990/7/4",
    "1990/7/5",
    "1990/7/6",
    "1990/7/7",
    "1990/7/8",
    "1990/7/9",
    "1990/7/10",
    "1990/7/11",
    "1990/7/12",
    "1990/7/13",
    "1990/7/14",
    "1990/7/15",
    "1990/7/16",
    "1990/7/17",
    "1990/7/18",
    "1990/7/19",
    "1990/7/20",
    "1990/7/21",
    "1990/7/22",
    "1990/7/23",
    "1990/7/24",
    "1990/7/25",
    "1990/7/26",
    "1990/7/27",
    "1990/7/28",
    "1990/7/29",
    "1990/7/30",
    "1990/7/31",
    "1990/8/1",
    "1990/8/2",
    "1990/8/3",
    "1990/8/4",
    "1990/8/5",
    "1990/8/6",
    "1990/8/7",
    "1990/8/8",
    "1990/8/9",
    "1990/8/10",
    "1990/8/11",
    "1990/8/12",
    "1990/8/13",
    "1990/8/14",
    "1990/8/15",
    "1990/8/16",
    "1990/8/17",
    "1990/8/18",
    "1990/8/19",
    "1990/8/20",
    "1990/8/21",
    "1990/8/22",
    "1990/8/23",
    "1990/8/24",
    "1990/8/25",
    "1990/8/26",
    "1990/8/27",
    "1990/8/28",
    "1990/8/29",
    "1990/8/30",
    "1990/8/31",
    "1990/9/1",
    "1990/9/2",
    "1990/9/3",
    "1990/9/4",
    "1990/9/5",
    "1990/9/6",
    "1990/9/7",
    "1990/9/8",
    "1990/9/9",
    "1990/9/10",
    "1990/9/11",
    "1990/9/12",
    "1990/9/13",
    "1990/9/14",
    "1990/9/15",
    "1990/9/16",
    "1990/9/17",
    "1990/9/18",
    "1990/9/19",
    "1990/9/20",
    "1990/9/21",
    "1990/9/22",
    "1990/9/23",
    "1990/9/24",
    "1990/9/25",
    "1990/9/26",
    "1990/9/27",
    "1990/9/28",
    "1990/9/29",
    "1990/9/30",
    "1990/10/1",
    "1990/10/2",
    "1990/10/3",
    "1990/10/4",
    "1990/10/5",
    "1990/10/6",
    "1990/10/7",
    "1990/10/8",
    "1990/10/9",
    "1990/10/10",
    "1990/10/11",
    "1990/10/12",
    "1990/10/13",
    "1990/10/14",
    "1990/10/15",
    "1990/10/16",
    "1990/10/17",
    "1990/10/18",
    "1990/10/19",
    "1990/10/20",
    "1990/10/21",
    "1990/10/22",
    "1990/10/23",
    "1990/10/24",
    "1990/10/25",
    "1990/10/26",
    "1990/10/27",
    "1990/10/28",
    "1990/10/29",
    "1990/10/30",
    "1990/10/31",
    "1990/11/1",
    "1990/11/2",
    "1990/11/3",
    "1990/11/4",
    "1990/11/5",
    "1990/11/6",
    "1990/11/7",
    "1990/11/8",
    "1990/11/9",
    "1990/11/10",
    "1990/11/11",
    "1990/11/12",
    "1990/11/13",
    "1990/11/14",
    "1990/11/15",
    "1990/11/16",
    "1990/11/17",
    "1990/11/18",
    "1990/11/19",
    "1990/11/20",
    "1990/11/21",
    "1990/11/22",
    "1990/11/23",
    "1990/11/24",
    "1990/11/25",
    "1990/11/26",
    "1990/11/27",
    "1990/11/28",
    "1990/11/29",
    "1990/11/30",
    "1990/12/1",
    "1990/12/2",
    "1990/12/3",
    "1990/12/4",
    "1990/12/5",
    "1990/12/6",
    "1990/12/7",
    "1990/12/8",
    "1990/12/9",
    "1990/12/10",
    "1990/12/11",
    "1990/12/12",
    "1990/12/13",
    "1990/12/14",
    "1990/12/15",
    "1990/12/16",
    "1990/12/17",
    "1990/12/18",
    "1990/12/19",
    "1990/12/20",
    "1990/12/21",
    "1990/12/22",
    "1990/12/23",
    "1990/12/24",
    "1990/12/25",
    "1990/12/26",
    "1990/12/27",
    "1990/12/28",
    "1990/12/29",
    "1990/12/30",
    "1990/12/31",
    "1991/1/1",
    "1991/1/2",
    "1991/1/3",
    "1991/1/4",
    "1991/1/5",
    "1991/1/6",
    "1991/1/7",
    "1991/1/8",
    "1991/1/9",
    "1991/1/10",
    "1991/1/11",
    "1991/1/12",
    "1991/1/13",
    "1991/1/14",
    "1991/1/15",
    "1991/1/16",
    "1991/1/17",
    "1991/1/18",
    "1991/1/19",
    "1991/1/20",
    "1991/1/21",
    "1991/1/22",
    "1991/1/23",
    "1991/1/24",
    "1991/1/25",
    "1991/1/26",
    "1991/1/27",
    "1991/1/28",
    "1991/1/29",
    "1991/1/30",
    "1991/1/31",
    "1991/2/1",
    "1991/2/2",
    "1991/2/3",
    "1991/2/4",
    "1991/2/5",
    "1991/2/6",
    "1991/2/7",
    "1991/2/8",
    "1991/2/9",
    "1991/2/10",
    "1991/2/11",
    "1991/2/12",
    "1991/2/13",
    "1991/2/14",
    "1991/2/15",
    "1991/2/16",
    "1991/2/17",
    "1991/2/18",
    "1991/2/19",
    "1991/2/20",
    "1991/2/21",
    "1991/2/22",
    "1991/2/23",
    "1991/2/24",
    "1991/2/25",
    "1991/2/26",
    "1991/2/27",
    "1991/2/28",
    "1991/3/1",
    "1991/3/2",
    "1991/3/3",
    "1991/3/4",
    "1991/3/5",
    "1991/3/6",
    "1991/3/7",
    "1991/3/8",
    "1991/3/9",
    "1991/3/10",
    "1991/3/11",
    "1991/3/12",
    "1991/3/13",
    "1991/3/14",
    "1991/3/15",
    "1991/3/16",
    "1991/3/17",
    "1991/3/18",
    "1991/3/19",
    "1991/3/20",
    "1991/3/21",
    "1991/3/22",
    "1991/3/23",
    "1991/3/24",
    "1991/3/25",
    "1991/3/26",
    "1991/3/27",
    "1991/3/28",
    "1991/3/29",
    "1991/3/30",
    "1991/3/31",
    "1991/4/1",
    "1991/4/2",
    "1991/4/3",
    "1991/4/4",
    "1991/4/5",
    "1991/4/6",
    "1991/4/7",
    "1991/4/8",
    "1991/4/9",
    "1991/4/10",
    "1991/4/11",
    "1991/4/12",
    "1991/4/13",
    "1991/4/14",
    "1991/4/15",
    "1991/4/16",
    "1991/4/17",
    "1991/4/18",
    "1991/4/19",
    "1991/4/20",
    "1991/4/21",
    "1991/4/22",
    "1991/4/23",
    "1991/4/24",
    "1991/4/25",
    "1991/4/26",
    "1991/4/27",
    "1991/4/28",
    "1991/4/29",
    "1991/4/30",
    "1991/5/1",
    "1991/5/2",
    "1991/5/3",
    "1991/5/4",
    "1991/5/5",
    "1991/5/6",
    "1991/5/7",
    "1991/5/8",
    "1991/5/9",
    "1991/5/10",
    "1991/5/11",
    "1991/5/12",
    "1991/5/13",
    "1991/5/14",
    "1991/5/15",
    "1991/5/16",
    "1991/5/17",
    "1991/5/18",
    "1991/5/19",
    "1991/5/20",
    "1991/5/21",
    "1991/5/22",
    "1991/5/23",
    "1991/5/24",
    "1991/5/25",
    "1991/5/26",
    "1991/5/27",
    "1991/5/28",
    "1991/5/29",
    "1991/5/30",
    "1991/5/31",
    "1991/6/1",
    "1991/6/2",
    "1991/6/3",
    "1991/6/4",
    "1991/6/5",
    "1991/6/6",
    "1991/6/7",
    "1991/6/8",
    "1991/6/9",
    "1991/6/10",
    "1991/6/11",
    "1991/6/12",
    "1991/6/13",
    "1991/6/14",
    "1991/6/15",
    "1991/6/16",
    "1991/6/17",
    "1991/6/18",
    "1991/6/19",
    "1991/6/20",
    "1991/6/21",
    "1991/6/22",
    "1991/6/23",
    "1991/6/24",
    "1991/6/25",
    "1991/6/26",
    "1991/6/27",
    "1991/6/28",
    "1991/6/29",
    "1991/6/30",
    "1991/7/1",
    "1991/7/2",
    "1991/7/3",
    "1991/7/4",
    "1991/7/5",
    "1991/7/6",
    "1991/7/7",
    "1991/7/8",
    "1991/7/9",
    "1991/7/10",
    "1991/7/11",
    "1991/7/12",
    "1991/7/13",
    "1991/7/14",
    "1991/7/15",
    "1991/7/16",
    "1991/7/17",
    "1991/7/18",
    "1991/7/19",
    "1991/7/20",
    "1991/7/21",
    "1991/7/22",
    "1991/7/23",
    "1991/7/24",
    "1991/7/25",
    "1991/7/26",
    "1991/7/27",
    "1991/7/28",
    "1991/7/29",
    "1991/7/30",
    "1991/7/31",
    "1991/8/1",
    "1991/8/2",
    "1991/8/3",
    "1991/8/4",
    "1991/8/5",
    "1991/8/6",
    "1991/8/7",
    "1991/8/8",
    "1991/8/9",
    "1991/8/10",
    "1991/8/11",
    "1991/8/12",
    "1991/8/13",
    "1991/8/14",
    "1991/8/15",
    "1991/8/16",
    "1991/8/17",
    "1991/8/18",
    "1991/8/19",
    "1991/8/20",
    "1991/8/21",
    "1991/8/22",
    "1991/8/23",
    "1991/8/24",
    "1991/8/25",
    "1991/8/26",
    "1991/8/27",
    "1991/8/28",
    "1991/8/29",
    "1991/8/30",
    "1991/8/31",
    "1991/9/1",
    "1991/9/2",
    "1991/9/3",
    "1991/9/4",
    "1991/9/5",
    "1991/9/6",
    "1991/9/7",
    "1991/9/8",
    "1991/9/9",
    "1991/9/10",
    "1991/9/11",
    "1991/9/12",
    "1991/9/13",
    "1991/9/14",
    "1991/9/15",
    "1991/9/16",
    "1991/9/17",
    "1991/9/18",
    "1991/9/19",
    "1991/9/20",
    "1991/9/21",
    "1991/9/22",
    "1991/9/23",
    "1991/9/24",
    "1991/9/25",
    "1991/9/26",
    "1991/9/27",
    "1991/9/28",
    "1991/9/29",
    "1991/9/30",
    "1991/10/1",
    "1991/10/2",
    "1991/10/3",
    "1991/10/4",
    "1991/10/5",
    "1991/10/6",
    "1991/10/7",
    "1991/10/8",
    "1991/10/9",
    "1991/10/10",
    "1991/10/11",
    "1991/10/12",
    "1991/10/13",
    "1991/10/14",
    "1991/10/15",
    "1991/10/16",
    "1991/10/17",
    "1991/10/18",
    "1991/10/19",
    "1991/10/20",
    "1991/10/21",
    "1991/10/22",
    "1991/10/23",
    "1991/10/24",
    "1991/10/25",
    "1991/10/26",
    "1991/10/27",
    "1991/10/28",
    "1991/10/29",
    "1991/10/30",
    "1991/10/31",
    "1991/11/1",
    "1991/11/2",
    "1991/11/3",
    "1991/11/4",
    "1991/11/5",
    "1991/11/6",
    "1991/11/7",
    "1991/11/8",
    "1991/11/9",
    "1991/11/10",
    "1991/11/11",
    "1991/11/12",
    "1991/11/13",
    "1991/11/14",
    "1991/11/15",
    "1991/11/16",
    "1991/11/17",
    "1991/11/18",
    "1991/11/19",
    "1991/11/20",
    "1991/11/21",
    "1991/11/22",
    "1991/11/23",
    "1991/11/24",
    "1991/11/25",
    "1991/11/26",
    "1991/11/27",
    "1991/11/28",
    "1991/11/29",
    "1991/11/30",
    "1991/12/1",
    "1991/12/2",
    "1991/12/3",
    "1991/12/4",
    "1991/12/5",
    "1991/12/6",
    "1991/12/7",
    "1991/12/8",
    "1991/12/9",
    "1991/12/10",
    "1991/12/11",
    "1991/12/12",
    "1991/12/13",
    "1991/12/14",
    "1991/12/15",
    "1991/12/16",
    "1991/12/17",
    "1991/12/18",
    "1991/12/19",
    "1991/12/20",
    "1991/12/21",
    "1991/12/22",
    "1991/12/23",
    "1991/12/24",
    "1991/12/25",
    "1991/12/26",
    "1991/12/27",
    "1991/12/28",
    "1991/12/29",
    "1991/12/30",
    "1991/12/31",
    "1992/1/1",
    "1992/1/2",
    "1992/1/3",
    "1992/1/4",
    "1992/1/5",
    "1992/1/6",
    "1992/1/7",
    "1992/1/8",
    "1992/1/9",
    "1992/1/10",
    "1992/1/11",
    "1992/1/12",
    "1992/1/13",
    "1992/1/14",
    "1992/1/15",
    "1992/1/16",
    "1992/1/17",
    "1992/1/18",
    "1992/1/19",
    "1992/1/20",
    "1992/1/21",
    "1992/1/22",
    "1992/1/23",
    "1992/1/24",
    "1992/1/25",
    "1992/1/26",
    "1992/1/27",
    "1992/1/28",
    "1992/1/29",
    "1992/1/30",
    "1992/1/31",
    "1992/2/1",
    "1992/2/2",
    "1992/2/3",
    "1992/2/4",
    "1992/2/5",
    "1992/2/6",
    "1992/2/7",
    "1992/2/8",
    "1992/2/9",
    "1992/2/10",
    "1992/2/11",
    "1992/2/12",
    "1992/2/13",
    "1992/2/14",
    "1992/2/15",
    "1992/2/16",
    "1992/2/17",
    "1992/2/18",
    "1992/2/19",
    "1992/2/20",
    "1992/2/21",
    "1992/2/22",
    "1992/2/23",
    "1992/2/24",
    "1992/2/25",
    "1992/2/26",
    "1992/2/27",
    "1992/2/28",
    "1992/2/29",
    "1992/3/1",
    "1992/3/2",
    "1992/3/3",
    "1992/3/4",
    "1992/3/5",
    "1992/3/6",
    "1992/3/7",
    "1992/3/8",
    "1992/3/9",
    "1992/3/10",
    "1992/3/11",
    "1992/3/12",
    "1992/3/13",
    "1992/3/14",
    "1992/3/15",
    "1992/3/16",
    "1992/3/17",
    "1992/3/18",
    "1992/3/19",
    "1992/3/20",
    "1992/3/21",
    "1992/3/22",
    "1992/3/23",
    "1992/3/24",
    "1992/3/25",
    "1992/3/26",
    "1992/3/27",
    "1992/3/28",
    "1992/3/29",
    "1992/3/30",
    "1992/3/31",
    "1992/4/1",
    "1992/4/2",
    "1992/4/3",
    "1992/4/4",
    "1992/4/5",
    "1992/4/6",
    "1992/4/7",
    "1992/4/8",
    "1992/4/9",
    "1992/4/10",
    "1992/4/11",
    "1992/4/12",
    "1992/4/13",
    "1992/4/14",
    "1992/4/15",
    "1992/4/16",
    "1992/4/17",
    "1992/4/18",
    "1992/4/19",
    "1992/4/20",
    "1992/4/21",
    "1992/4/22",
    "1992/4/23",
    "1992/4/24",
    "1992/4/25",
    "1992/4/26",
    "1992/4/27",
    "1992/4/28",
    "1992/4/29",
    "1992/4/30",
    "1992/5/1",
    "1992/5/2",
    "1992/5/3",
    "1992/5/4",
    "1992/5/5",
    "1992/5/6",
    "1992/5/7",
    "1992/5/8",
    "1992/5/9",
    "1992/5/10",
    "1992/5/11",
    "1992/5/12",
    "1992/5/13",
    "1992/5/14",
    "1992/5/15",
    "1992/5/16",
    "1992/5/17",
    "1992/5/18",
    "1992/5/19",
    "1992/5/20",
    "1992/5/21",
    "1992/5/22",
    "1992/5/23",
    "1992/5/24",
    "1992/5/25",
    "1992/5/26",
    "1992/5/27",
    "1992/5/28",
    "1992/5/29",
    "1992/5/30",
    "1992/5/31",
    "1992/6/1",
    "1992/6/2",
    "1992/6/3",
    "1992/6/4",
    "1992/6/5",
    "1992/6/6",
    "1992/6/7",
    "1992/6/8",
    "1992/6/9",
    "1992/6/10",
    "1992/6/11",
    "1992/6/12",
    "1992/6/13",
    "1992/6/14",
    "1992/6/15",
    "1992/6/16",
    "1992/6/17",
    "1992/6/18",
    "1992/6/19",
    "1992/6/20",
    "1992/6/21",
    "1992/6/22",
    "1992/6/23",
    "1992/6/24",
    "1992/6/25",
    "1992/6/26",
    "1992/6/27",
    "1992/6/28",
    "1992/6/29",
    "1992/6/30",
    "1992/7/1",
    "1992/7/2",
    "1992/7/3",
    "1992/7/4",
    "1992/7/5",
    "1992/7/6",
    "1992/7/7",
    "1992/7/8",
    "1992/7/9",
    "1992/7/10",
    "1992/7/11",
    "1992/7/12",
    "1992/7/13",
    "1992/7/14",
    "1992/7/15",
    "1992/7/16",
    "1992/7/17",
    "1992/7/18",
    "1992/7/19",
    "1992/7/20",
    "1992/7/21",
    "1992/7/22",
    "1992/7/23",
    "1992/7/24",
    "1992/7/25",
    "1992/7/26",
    "1992/7/27",
    "1992/7/28",
    "1992/7/29",
    "1992/7/30",
    "1992/7/31",
    "1992/8/1",
    "1992/8/2",
    "1992/8/3",
    "1992/8/4",
    "1992/8/5",
    "1992/8/6",
    "1992/8/7",
    "1992/8/8",
    "1992/8/9",
    "1992/8/10",
    "1992/8/11",
    "1992/8/12",
    "1992/8/13",
    "1992/8/14",
    "1992/8/15",
    "1992/8/16",
    "1992/8/17",
    "1992/8/18",
    "1992/8/19",
    "1992/8/20",
    "1992/8/21",
    "1992/8/22",
    "1992/8/23",
    "1992/8/24",
    "1992/8/25",
    "1992/8/26",
    "1992/8/27",
    "1992/8/28",
    "1992/8/29",
    "1992/8/30",
    "1992/8/31",
    "1992/9/1",
    "1992/9/2",
    "1992/9/3",
    "1992/9/4",
    "1992/9/5",
    "1992/9/6",
    "1992/9/7",
    "1992/9/8",
    "1992/9/9",
    "1992/9/10",
    "1992/9/11",
    "1992/9/12",
    "1992/9/13",
    "1992/9/14",
    "1992/9/15",
    "1992/9/16",
    "1992/9/17",
    "1992/9/18",
    "1992/9/19",
    "1992/9/20",
    "1992/9/21",
    "1992/9/22",
    "1992/9/23",
    "1992/9/24",
    "1992/9/25",
    "1992/9/26",
    "1992/9/27",
    "1992/9/28",
    "1992/9/29",
    "1992/9/30",
    "1992/10/1",
    "1992/10/2",
    "1992/10/3",
    "1992/10/4",
    "1992/10/5",
    "1992/10/6",
    "1992/10/7",
    "1992/10/8",
    "1992/10/9",
    "1992/10/10",
    "1992/10/11",
    "1992/10/12",
    "1992/10/13",
    "1992/10/14",
    "1992/10/15",
    "1992/10/16",
    "1992/10/17",
    "1992/10/18",
    "1992/10/19",
    "1992/10/20",
    "1992/10/21",
    "1992/10/22",
    "1992/10/23",
    "1992/10/24",
    "1992/10/25",
    "1992/10/26",
    "1992/10/27",
    "1992/10/28",
    "1992/10/29",
    "1992/10/30",
    "1992/10/31",
    "1992/11/1",
    "1992/11/2",
    "1992/11/3",
    "1992/11/4",
    "1992/11/5",
    "1992/11/6",
    "1992/11/7",
    "1992/11/8",
    "1992/11/9",
    "1992/11/10",
    "1992/11/11",
    "1992/11/12",
    "1992/11/13",
    "1992/11/14",
    "1992/11/15",
    "1992/11/16",
    "1992/11/17",
    "1992/11/18",
    "1992/11/19",
    "1992/11/20",
    "1992/11/21",
    "1992/11/22",
    "1992/11/23",
    "1992/11/24",
    "1992/11/25",
    "1992/11/26",
    "1992/11/27",
    "1992/11/28",
    "1992/11/29",
    "1992/11/30",
    "1992/12/1",
    "1992/12/2",
    "1992/12/3",
    "1992/12/4",
    "1992/12/5",
    "1992/12/6",
    "1992/12/7",
    "1992/12/8",
    "1992/12/9",
    "1992/12/10",
    "1992/12/11",
    "1992/12/12",
    "1992/12/13",
    "1992/12/14",
    "1992/12/15",
    "1992/12/16",
    "1992/12/17",
    "1992/12/18",
    "1992/12/19",
    "1992/12/20",
    "1992/12/21",
    "1992/12/22",
    "1992/12/23",
    "1992/12/24",
    "1992/12/25",
    "1992/12/26",
    "1992/12/27",
    "1992/12/28",
    "1992/12/29",
    "1992/12/30",
    "1992/12/31",
    "1993/1/1",
    "1993/1/2",
    "1993/1/3",
    "1993/1/4",
    "1993/1/5",
    "1993/1/6",
    "1993/1/7",
    "1993/1/8",
    "1993/1/9",
    "1993/1/10",
    "1993/1/11",
    "1993/1/12",
    "1993/1/13",
    "1993/1/14",
    "1993/1/15",
    "1993/1/16",
    "1993/1/17",
    "1993/1/18",
    "1993/1/19",
    "1993/1/20",
    "1993/1/21",
    "1993/1/22",
    "1993/1/23",
    "1993/1/24",
    "1993/1/25",
    "1993/1/26",
    "1993/1/27",
    "1993/1/28",
    "1993/1/29",
    "1993/1/30",
    "1993/1/31",
    "1993/2/1",
    "1993/2/2",
    "1993/2/3",
    "1993/2/4",
    "1993/2/5",
    "1993/2/6",
    "1993/2/7",
    "1993/2/8",
    "1993/2/9",
    "1993/2/10",
    "1993/2/11",
    "1993/2/12",
    "1993/2/13",
    "1993/2/14",
    "1993/2/15",
    "1993/2/16",
    "1993/2/17",
    "1993/2/18",
    "1993/2/19",
    "1993/2/20",
    "1993/2/21",
    "1993/2/22",
    "1993/2/23",
    "1993/2/24",
    "1993/2/25",
    "1993/2/26",
    "1993/2/27",
    "1993/2/28",
    "1993/3/1",
    "1993/3/2",
    "1993/3/3",
    "1993/3/4",
    "1993/3/5",
    "1993/3/6",
    "1993/3/7",
    "1993/3/8",
    "1993/3/9",
    "1993/3/10",
    "1993/3/11",
    "1993/3/12",
    "1993/3/13",
    "1993/3/14",
    "1993/3/15",
    "1993/3/16",
    "1993/3/17",
    "1993/3/18",
    "1993/3/19",
    "1993/3/20",
    "1993/3/21",
    "1993/3/22",
    "1993/3/23",
    "1993/3/24",
    "1993/3/25",
    "1993/3/26",
    "1993/3/27",
    "1993/3/28",
    "1993/3/29",
    "1993/3/30",
    "1993/3/31",
    "1993/4/1",
    "1993/4/2",
    "1993/4/3",
    "1993/4/4",
    "1993/4/5",
    "1993/4/6",
    "1993/4/7",
    "1993/4/8",
    "1993/4/9",
    "1993/4/10",
    "1993/4/11",
    "1993/4/12",
    "1993/4/13",
    "1993/4/14",
    "1993/4/15",
    "1993/4/16",
    "1993/4/17",
    "1993/4/18",
    "1993/4/19",
    "1993/4/20",
    "1993/4/21",
    "1993/4/22",
    "1993/4/23",
    "1993/4/24",
    "1993/4/25",
    "1993/4/26",
    "1993/4/27",
    "1993/4/28",
    "1993/4/29",
    "1993/4/30",
    "1993/5/1",
    "1993/5/2",
    "1993/5/3",
    "1993/5/4",
    "1993/5/5",
    "1993/5/6",
    "1993/5/7",
    "1993/5/8",
    "1993/5/9",
    "1993/5/10",
    "1993/5/11",
    "1993/5/12",
    "1993/5/13",
    "1993/5/14",
    "1993/5/15",
    "1993/5/16",
    "1993/5/17",
    "1993/5/18",
    "1993/5/19",
    "1993/5/20",
    "1993/5/21",
    "1993/5/22",
    "1993/5/23",
    "1993/5/24",
    "1993/5/25",
    "1993/5/26",
    "1993/5/27",
    "1993/5/28",
    "1993/5/29",
    "1993/5/30",
    "1993/5/31",
    "1993/6/1",
    "1993/6/2",
    "1993/6/3",
    "1993/6/4",
    "1993/6/5",
    "1993/6/6",
    "1993/6/7",
    "1993/6/8",
    "1993/6/9",
    "1993/6/10",
    "1993/6/11",
    "1993/6/12",
    "1993/6/13",
    "1993/6/14",
    "1993/6/15",
    "1993/6/16",
    "1993/6/17",
    "1993/6/18",
    "1993/6/19",
    "1993/6/20",
    "1993/6/21",
    "1993/6/22",
    "1993/6/23",
    "1993/6/24",
    "1993/6/25",
    "1993/6/26",
    "1993/6/27",
    "1993/6/28",
    "1993/6/29",
    "1993/6/30",
    "1993/7/1",
    "1993/7/2",
    "1993/7/3",
    "1993/7/4",
    "1993/7/5",
    "1993/7/6",
    "1993/7/7",
    "1993/7/8",
    "1993/7/9",
    "1993/7/10",
    "1993/7/11",
    "1993/7/12",
    "1993/7/13",
    "1993/7/14",
    "1993/7/15",
    "1993/7/16",
    "1993/7/17",
    "1993/7/18",
    "1993/7/19",
    "1993/7/20",
    "1993/7/21",
    "1993/7/22",
    "1993/7/23",
    "1993/7/24",
    "1993/7/25",
    "1993/7/26",
    "1993/7/27",
    "1993/7/28",
    "1993/7/29",
    "1993/7/30",
    "1993/7/31",
    "1993/8/1",
    "1993/8/2",
    "1993/8/3",
    "1993/8/4",
    "1993/8/5",
    "1993/8/6",
    "1993/8/7",
    "1993/8/8",
    "1993/8/9",
    "1993/8/10",
    "1993/8/11",
    "1993/8/12",
    "1993/8/13",
    "1993/8/14",
    "1993/8/15",
    "1993/8/16",
    "1993/8/17",
    "1993/8/18",
    "1993/8/19",
    "1993/8/20",
    "1993/8/21",
    "1993/8/22",
    "1993/8/23",
    "1993/8/24",
    "1993/8/25",
    "1993/8/26",
    "1993/8/27",
    "1993/8/28",
    "1993/8/29",
    "1993/8/30",
    "1993/8/31",
    "1993/9/1",
    "1993/9/2",
    "1993/9/3",
    "1993/9/4",
    "1993/9/5",
    "1993/9/6",
    "1993/9/7",
    "1993/9/8",
    "1993/9/9",
    "1993/9/10",
    "1993/9/11",
    "1993/9/12",
    "1993/9/13",
    "1993/9/14",
    "1993/9/15",
    "1993/9/16",
    "1993/9/17",
    "1993/9/18",
    "1993/9/19",
    "1993/9/20",
    "1993/9/21",
    "1993/9/22",
    "1993/9/23",
    "1993/9/24",
    "1993/9/25",
    "1993/9/26",
    "1993/9/27",
    "1993/9/28",
    "1993/9/29",
    "1993/9/30",
    "1993/10/1",
    "1993/10/2",
    "1993/10/3",
    "1993/10/4",
    "1993/10/5",
    "1993/10/6",
    "1993/10/7",
    "1993/10/8",
    "1993/10/9",
    "1993/10/10",
    "1993/10/11",
    "1993/10/12",
    "1993/10/13",
    "1993/10/14",
    "1993/10/15",
    "1993/10/16",
    "1993/10/17",
    "1993/10/18",
    "1993/10/19",
    "1993/10/20",
    "1993/10/21",
    "1993/10/22",
    "1993/10/23",
    "1993/10/24",
    "1993/10/25",
    "1993/10/26",
    "1993/10/27",
    "1993/10/28",
    "1993/10/29",
    "1993/10/30",
    "1993/10/31",
    "1993/11/1",
    "1993/11/2",
    "1993/11/3",
    "1993/11/4",
    "1993/11/5",
    "1993/11/6",
    "1993/11/7",
    "1993/11/8",
    "1993/11/9",
    "1993/11/10",
    "1993/11/11",
    "1993/11/12",
    "1993/11/13",
    "1993/11/14",
    "1993/11/15",
    "1993/11/16",
    "1993/11/17",
    "1993/11/18",
    "1993/11/19",
    "1993/11/20",
    "1993/11/21",
    "1993/11/22",
    "1993/11/23",
    "1993/11/24",
    "1993/11/25",
    "1993/11/26",
    "1993/11/27",
    "1993/11/28",
    "1993/11/29",
    "1993/11/30",
    "1993/12/1",
    "1993/12/2",
    "1993/12/3",
    "1993/12/4",
    "1993/12/5",
    "1993/12/6",
    "1993/12/7",
    "1993/12/8",
    "1993/12/9",
    "1993/12/10",
    "1993/12/11",
    "1993/12/12",
    "1993/12/13",
    "1993/12/14",
    "1993/12/15",
    "1993/12/16",
    "1993/12/17",
    "1993/12/18",
    "1993/12/19",
    "1993/12/20",
    "1993/12/21",
    "1993/12/22",
    "1993/12/23",
    "1993/12/24",
    "1993/12/25",
    "1993/12/26",
    "1993/12/27",
    "1993/12/28",
    "1993/12/29",
    "1993/12/30",
    "1993/12/31",
    "1994/1/1",
    "1994/1/2",
    "1994/1/3",
    "1994/1/4",
    "1994/1/5",
    "1994/1/6",
    "1994/1/7",
    "1994/1/8",
    "1994/1/9",
    "1994/1/10",
    "1994/1/11",
    "1994/1/12",
    "1994/1/13",
    "1994/1/14",
    "1994/1/15",
    "1994/1/16",
    "1994/1/17",
    "1994/1/18",
    "1994/1/19",
    "1994/1/20",
    "1994/1/21",
    "1994/1/22",
    "1994/1/23",
    "1994/1/24",
    "1994/1/25",
    "1994/1/26",
    "1994/1/27",
    "1994/1/28",
    "1994/1/29",
    "1994/1/30",
    "1994/1/31",
    "1994/2/1",
    "1994/2/2",
    "1994/2/3",
    "1994/2/4",
    "1994/2/5",
    "1994/2/6",
    "1994/2/7",
    "1994/2/8",
    "1994/2/9",
    "1994/2/10",
    "1994/2/11",
    "1994/2/12",
    "1994/2/13",
    "1994/2/14",
    "1994/2/15",
    "1994/2/16",
    "1994/2/17",
    "1994/2/18",
    "1994/2/19",
    "1994/2/20",
    "1994/2/21",
    "1994/2/22",
    "1994/2/23",
    "1994/2/24",
    "1994/2/25",
    "1994/2/26",
    "1994/2/27",
    "1994/2/28",
    "1994/3/1",
    "1994/3/2",
    "1994/3/3",
    "1994/3/4",
    "1994/3/5",
    "1994/3/6",
    "1994/3/7",
    "1994/3/8",
    "1994/3/9",
    "1994/3/10",
    "1994/3/11",
    "1994/3/12",
    "1994/3/13",
    "1994/3/14",
    "1994/3/15",
    "1994/3/16",
    "1994/3/17",
    "1994/3/18",
    "1994/3/19",
    "1994/3/20",
    "1994/3/21",
    "1994/3/22",
    "1994/3/23",
    "1994/3/24",
    "1994/3/25",
    "1994/3/26",
    "1994/3/27",
    "1994/3/28",
    "1994/3/29",
    "1994/3/30",
    "1994/3/31",
    "1994/4/1",
    "1994/4/2",
    "1994/4/3",
    "1994/4/4",
    "1994/4/5",
    "1994/4/6",
    "1994/4/7",
    "1994/4/8",
    "1994/4/9",
    "1994/4/10",
    "1994/4/11",
    "1994/4/12",
    "1994/4/13",
    "1994/4/14",
    "1994/4/15",
    "1994/4/16",
    "1994/4/17",
    "1994/4/18",
    "1994/4/19",
    "1994/4/20",
    "1994/4/21",
    "1994/4/22",
    "1994/4/23",
    "1994/4/24",
    "1994/4/25",
    "1994/4/26",
    "1994/4/27",
    "1994/4/28",
    "1994/4/29",
    "1994/4/30",
    "1994/5/1",
    "1994/5/2",
    "1994/5/3",
    "1994/5/4",
    "1994/5/5",
    "1994/5/6",
    "1994/5/7",
    "1994/5/8",
    "1994/5/9",
    "1994/5/10",
    "1994/5/11",
    "1994/5/12",
    "1994/5/13",
    "1994/5/14",
    "1994/5/15",
    "1994/5/16",
    "1994/5/17",
    "1994/5/18",
    "1994/5/19",
    "1994/5/20",
    "1994/5/21",
    "1994/5/22",
    "1994/5/23",
    "1994/5/24",
    "1994/5/25",
    "1994/5/26",
    "1994/5/27",
    "1994/5/28",
    "1994/5/29",
    "1994/5/30",
    "1994/5/31",
    "1994/6/1",
    "1994/6/2",
    "1994/6/3",
    "1994/6/4",
    "1994/6/5",
    "1994/6/6",
    "1994/6/7",
    "1994/6/8",
    "1994/6/9",
    "1994/6/10",
    "1994/6/11",
    "1994/6/12",
    "1994/6/13",
    "1994/6/14",
    "1994/6/15",
    "1994/6/16",
    "1994/6/17",
    "1994/6/18",
    "1994/6/19",
    "1994/6/20",
    "1994/6/21",
    "1994/6/22",
    "1994/6/23",
    "1994/6/24",
    "1994/6/25",
    "1994/6/26",
    "1994/6/27",
    "1994/6/28",
    "1994/6/29",
    "1994/6/30",
    "1994/7/1",
    "1994/7/2",
    "1994/7/3",
    "1994/7/4",
    "1994/7/5",
    "1994/7/6",
    "1994/7/7",
    "1994/7/8",
    "1994/7/9",
    "1994/7/10",
    "1994/7/11",
    "1994/7/12",
    "1994/7/13",
    "1994/7/14",
    "1994/7/15",
    "1994/7/16",
    "1994/7/17",
    "1994/7/18",
    "1994/7/19",
    "1994/7/20",
    "1994/7/21",
    "1994/7/22",
    "1994/7/23",
    "1994/7/24",
    "1994/7/25",
    "1994/7/26",
    "1994/7/27",
    "1994/7/28",
    "1994/7/29",
    "1994/7/30",
    "1994/7/31",
    "1994/8/1",
    "1994/8/2",
    "1994/8/3",
    "1994/8/4",
    "1994/8/5",
    "1994/8/6",
    "1994/8/7",
    "1994/8/8",
    "1994/8/9",
    "1994/8/10",
    "1994/8/11",
    "1994/8/12",
    "1994/8/13",
    "1994/8/14",
    "1994/8/15",
    "1994/8/16",
    "1994/8/17",
    "1994/8/18",
    "1994/8/19",
    "1994/8/20",
    "1994/8/21",
    "1994/8/22",
    "1994/8/23",
    "1994/8/24",
    "1994/8/25",
    "1994/8/26",
    "1994/8/27",
    "1994/8/28",
    "1994/8/29",
    "1994/8/30",
    "1994/8/31",
    "1994/9/1",
    "1994/9/2",
    "1994/9/3",
    "1994/9/4",
    "1994/9/5",
    "1994/9/6",
    "1994/9/7",
    "1994/9/8",
    "1994/9/9",
    "1994/9/10",
    "1994/9/11",
    "1994/9/12",
    "1994/9/13",
    "1994/9/14",
    "1994/9/15",
    "1994/9/16",
    "1994/9/17",
    "1994/9/18",
    "1994/9/19",
    "1994/9/20",
    "1994/9/21",
    "1994/9/22",
    "1994/9/23",
    "1994/9/24",
    "1994/9/25",
    "1994/9/26",
    "1994/9/27",
    "1994/9/28",
    "1994/9/29",
    "1994/9/30",
    "1994/10/1",
    "1994/10/2",
    "1994/10/3",
    "1994/10/4",
    "1994/10/5",
    "1994/10/6",
    "1994/10/7",
    "1994/10/8",
    "1994/10/9",
    "1994/10/10",
    "1994/10/11",
    "1994/10/12",
    "1994/10/13",
    "1994/10/14",
    "1994/10/15",
    "1994/10/16",
    "1994/10/17",
    "1994/10/18",
    "1994/10/19",
    "1994/10/20",
    "1994/10/21",
    "1994/10/22",
    "1994/10/23",
    "1994/10/24",
    "1994/10/25",
    "1994/10/26",
    "1994/10/27",
    "1994/10/28",
    "1994/10/29",
    "1994/10/30",
    "1994/10/31",
    "1994/11/1",
    "1994/11/2",
    "1994/11/3",
    "1994/11/4",
    "1994/11/5",
    "1994/11/6",
    "1994/11/7",
    "1994/11/8",
    "1994/11/9",
    "1994/11/10",
    "1994/11/11",
    "1994/11/12",
    "1994/11/13",
    "1994/11/14",
    "1994/11/15",
    "1994/11/16",
    "1994/11/17",
    "1994/11/18",
    "1994/11/19",
    "1994/11/20",
    "1994/11/21",
    "1994/11/22",
    "1994/11/23",
    "1994/11/24",
    "1994/11/25",
    "1994/11/26",
    "1994/11/27",
    "1994/11/28",
    "1994/11/29",
    "1994/11/30",
    "1994/12/1",
    "1994/12/2",
    "1994/12/3",
    "1994/12/4",
    "1994/12/5",
    "1994/12/6",
    "1994/12/7",
    "1994/12/8",
    "1994/12/9",
    "1994/12/10",
    "1994/12/11",
    "1994/12/12",
    "1994/12/13",
    "1994/12/14",
    "1994/12/15",
    "1994/12/16",
    "1994/12/17",
    "1994/12/18",
    "1994/12/19",
    "1994/12/20",
    "1994/12/21",
    "1994/12/22",
    "1994/12/23",
    "1994/12/24",
    "1994/12/25",
    "1994/12/26",
    "1994/12/27",
    "1994/12/28",
    "1994/12/29",
    "1994/12/30",
    "1994/12/31",
    "1995/1/1",
    "1995/1/2",
    "1995/1/3",
    "1995/1/4",
    "1995/1/5",
    "1995/1/6",
    "1995/1/7",
    "1995/1/8",
    "1995/1/9",
    "1995/1/10",
    "1995/1/11",
    "1995/1/12",
    "1995/1/13",
    "1995/1/14",
    "1995/1/15",
    "1995/1/16",
    "1995/1/17",
    "1995/1/18",
    "1995/1/19",
    "1995/1/20",
    "1995/1/21",
    "1995/1/22",
    "1995/1/23",
    "1995/1/24",
    "1995/1/25",
    "1995/1/26",
    "1995/1/27",
    "1995/1/28",
    "1995/1/29",
    "1995/1/30",
    "1995/1/31",
    "1995/2/1",
    "1995/2/2",
    "1995/2/3",
    "1995/2/4",
    "1995/2/5",
    "1995/2/6",
    "1995/2/7",
    "1995/2/8",
    "1995/2/9",
    "1995/2/10",
    "1995/2/11",
    "1995/2/12",
    "1995/2/13",
    "1995/2/14",
    "1995/2/15",
    "1995/2/16",
    "1995/2/17",
    "1995/2/18",
    "1995/2/19",
    "1995/2/20",
    "1995/2/21",
    "1995/2/22",
    "1995/2/23",
    "1995/2/24",
    "1995/2/25",
    "1995/2/26",
    "1995/2/27",
    "1995/2/28",
    "1995/3/1",
    "1995/3/2",
    "1995/3/3",
    "1995/3/4",
    "1995/3/5",
    "1995/3/6",
    "1995/3/7",
    "1995/3/8",
    "1995/3/9",
    "1995/3/10",
    "1995/3/11",
    "1995/3/12",
    "1995/3/13",
    "1995/3/14",
    "1995/3/15",
    "1995/3/16",
    "1995/3/17",
    "1995/3/18",
    "1995/3/19",
    "1995/3/20",
    "1995/3/21",
    "1995/3/22",
    "1995/3/23",
    "1995/3/24",
    "1995/3/25",
    "1995/3/26",
    "1995/3/27",
    "1995/3/28",
    "1995/3/29",
    "1995/3/30",
    "1995/3/31",
    "1995/4/1",
    "1995/4/2",
    "1995/4/3",
    "1995/4/4",
    "1995/4/5",
    "1995/4/6",
    "1995/4/7",
    "1995/4/8",
    "1995/4/9",
    "1995/4/10",
    "1995/4/11",
    "1995/4/12",
    "1995/4/13",
    "1995/4/14",
    "1995/4/15",
    "1995/4/16",
    "1995/4/17",
    "1995/4/18",
    "1995/4/19",
    "1995/4/20",
    "1995/4/21",
    "1995/4/22",
    "1995/4/23",
    "1995/4/24",
    "1995/4/25",
    "1995/4/26",
    "1995/4/27",
    "1995/4/28",
    "1995/4/29",
    "1995/4/30",
    "1995/5/1",
    "1995/5/2",
    "1995/5/3",
    "1995/5/4",
    "1995/5/5",
    "1995/5/6",
    "1995/5/7",
    "1995/5/8",
    "1995/5/9",
    "1995/5/10",
    "1995/5/11",
    "1995/5/12",
    "1995/5/13",
    "1995/5/14",
    "1995/5/15",
    "1995/5/16",
    "1995/5/17",
    "1995/5/18",
    "1995/5/19",
    "1995/5/20",
    "1995/5/21",
    "1995/5/22",
    "1995/5/23",
    "1995/5/24",
    "1995/5/25",
    "1995/5/26",
    "1995/5/27",
    "1995/5/28",
    "1995/5/29",
    "1995/5/30",
    "1995/5/31",
    "1995/6/1",
    "1995/6/2",
    "1995/6/3",
    "1995/6/4",
    "1995/6/5",
    "1995/6/6",
    "1995/6/7",
    "1995/6/8",
    "1995/6/9",
    "1995/6/10",
    "1995/6/11",
    "1995/6/12",
    "1995/6/13",
    "1995/6/14",
    "1995/6/15",
    "1995/6/16",
    "1995/6/17",
    "1995/6/18",
    "1995/6/19",
    "1995/6/20",
    "1995/6/21",
    "1995/6/22",
    "1995/6/23",
    "1995/6/24",
    "1995/6/25",
    "1995/6/26",
    "1995/6/27",
    "1995/6/28",
    "1995/6/29",
    "1995/6/30",
    "1995/7/1",
    "1995/7/2",
    "1995/7/3",
    "1995/7/4",
    "1995/7/5",
    "1995/7/6",
    "1995/7/7",
    "1995/7/8",
    "1995/7/9",
    "1995/7/10",
    "1995/7/11",
    "1995/7/12",
    "1995/7/13",
    "1995/7/14",
    "1995/7/15",
    "1995/7/16",
    "1995/7/17",
    "1995/7/18",
    "1995/7/19",
    "1995/7/20",
    "1995/7/21",
    "1995/7/22",
    "1995/7/23",
    "1995/7/24",
    "1995/7/25",
    "1995/7/26",
    "1995/7/27",
    "1995/7/28",
    "1995/7/29",
    "1995/7/30",
    "1995/7/31",
    "1995/8/1",
    "1995/8/2",
    "1995/8/3",
    "1995/8/4",
    "1995/8/5",
    "1995/8/6",
    "1995/8/7",
    "1995/8/8",
    "1995/8/9",
    "1995/8/10",
    "1995/8/11",
    "1995/8/12",
    "1995/8/13",
    "1995/8/14",
    "1995/8/15",
    "1995/8/16",
    "1995/8/17",
    "1995/8/18",
    "1995/8/19",
    "1995/8/20",
    "1995/8/21",
    "1995/8/22",
    "1995/8/23",
    "1995/8/24",
    "1995/8/25",
    "1995/8/26",
    "1995/8/27",
    "1995/8/28",
    "1995/8/29",
    "1995/8/30",
    "1995/8/31",
    "1995/9/1",
    "1995/9/2",
    "1995/9/3",
    "1995/9/4",
    "1995/9/5",
    "1995/9/6",
    "1995/9/7",
    "1995/9/8",
    "1995/9/9",
    "1995/9/10",
    "1995/9/11",
    "1995/9/12",
    "1995/9/13",
    "1995/9/14",
    "1995/9/15",
    "1995/9/16",
    "1995/9/17",
    "1995/9/18",
    "1995/9/19",
    "1995/9/20",
    "1995/9/21",
    "1995/9/22",
    "1995/9/23",
    "1995/9/24",
    "1995/9/25",
    "1995/9/26",
    "1995/9/27",
    "1995/9/28",
    "1995/9/29",
    "1995/9/30",
    "1995/10/1",
    "1995/10/2",
    "1995/10/3",
    "1995/10/4",
    "1995/10/5",
    "1995/10/6",
    "1995/10/7",
    "1995/10/8",
    "1995/10/9",
    "1995/10/10",
    "1995/10/11",
    "1995/10/12",
    "1995/10/13",
    "1995/10/14",
    "1995/10/15",
    "1995/10/16",
    "1995/10/17",
    "1995/10/18",
    "1995/10/19",
    "1995/10/20",
    "1995/10/21",
    "1995/10/22",
    "1995/10/23",
    "1995/10/24",
    "1995/10/25",
    "1995/10/26",
    "1995/10/27",
    "1995/10/28",
    "1995/10/29",
    "1995/10/30",
    "1995/10/31",
    "1995/11/1",
    "1995/11/2",
    "1995/11/3",
    "1995/11/4",
    "1995/11/5",
    "1995/11/6",
    "1995/11/7",
    "1995/11/8",
    "1995/11/9",
    "1995/11/10",
    "1995/11/11",
    "1995/11/12",
    "1995/11/13",
    "1995/11/14",
    "1995/11/15",
    "1995/11/16",
    "1995/11/17",
    "1995/11/18",
    "1995/11/19",
    "1995/11/20",
    "1995/11/21",
    "1995/11/22",
    "1995/11/23",
    "1995/11/24",
    "1995/11/25",
    "1995/11/26",
    "1995/11/27",
    "1995/11/28",
    "1995/11/29",
    "1995/11/30",
    "1995/12/1",
    "1995/12/2",
    "1995/12/3",
    "1995/12/4",
    "1995/12/5",
    "1995/12/6",
    "1995/12/7",
    "1995/12/8",
    "1995/12/9",
    "1995/12/10",
    "1995/12/11",
    "1995/12/12",
    "1995/12/13",
    "1995/12/14",
    "1995/12/15",
    "1995/12/16",
    "1995/12/17",
    "1995/12/18",
    "1995/12/19",
    "1995/12/20",
    "1995/12/21",
    "1995/12/22",
    "1995/12/23",
    "1995/12/24",
    "1995/12/25",
    "1995/12/26",
    "1995/12/27",
    "1995/12/28",
    "1995/12/29",
    "1995/12/30",
    "1995/12/31",
    "1996/1/1",
    "1996/1/2",
    "1996/1/3",
    "1996/1/4",
    "1996/1/5",
    "1996/1/6",
    "1996/1/7",
    "1996/1/8",
    "1996/1/9",
    "1996/1/10",
    "1996/1/11",
    "1996/1/12",
    "1996/1/13",
    "1996/1/14",
    "1996/1/15",
    "1996/1/16",
    "1996/1/17",
    "1996/1/18",
    "1996/1/19",
    "1996/1/20",
    "1996/1/21",
    "1996/1/22",
    "1996/1/23",
    "1996/1/24",
    "1996/1/25",
    "1996/1/26",
    "1996/1/27",
    "1996/1/28",
    "1996/1/29",
    "1996/1/30",
    "1996/1/31",
    "1996/2/1",
    "1996/2/2",
    "1996/2/3",
    "1996/2/4",
    "1996/2/5",
    "1996/2/6",
    "1996/2/7",
    "1996/2/8",
    "1996/2/9",
    "1996/2/10",
    "1996/2/11",
    "1996/2/12",
    "1996/2/13",
    "1996/2/14",
    "1996/2/15",
    "1996/2/16",
    "1996/2/17",
    "1996/2/18",
    "1996/2/19",
    "1996/2/20",
    "1996/2/21",
    "1996/2/22",
    "1996/2/23",
    "1996/2/24",
    "1996/2/25",
    "1996/2/26",
    "1996/2/27",
    "1996/2/28",
    "1996/2/29",
    "1996/3/1",
    "1996/3/2",
    "1996/3/3",
    "1996/3/4",
    "1996/3/5",
    "1996/3/6",
    "1996/3/7",
    "1996/3/8",
    "1996/3/9",
    "1996/3/10",
    "1996/3/11",
    "1996/3/12",
    "1996/3/13",
    "1996/3/14",
    "1996/3/15",
    "1996/3/16",
    "1996/3/17",
    "1996/3/18",
    "1996/3/19",
    "1996/3/20",
    "1996/3/21",
    "1996/3/22",
    "1996/3/23",
    "1996/3/24",
    "1996/3/25",
    "1996/3/26",
    "1996/3/27",
    "1996/3/28",
    "1996/3/29",
    "1996/3/30",
    "1996/3/31",
    "1996/4/1",
    "1996/4/2",
    "1996/4/3",
    "1996/4/4",
    "1996/4/5",
    "1996/4/6",
    "1996/4/7",
    "1996/4/8",
    "1996/4/9",
    "1996/4/10",
    "1996/4/11",
    "1996/4/12",
    "1996/4/13",
    "1996/4/14",
    "1996/4/15",
    "1996/4/16",
    "1996/4/17",
    "1996/4/18",
    "1996/4/19",
    "1996/4/20",
    "1996/4/21",
    "1996/4/22",
    "1996/4/23",
    "1996/4/24",
    "1996/4/25",
    "1996/4/26",
    "1996/4/27",
    "1996/4/28",
    "1996/4/29",
    "1996/4/30",
    "1996/5/1",
    "1996/5/2",
    "1996/5/3",
    "1996/5/4",
    "1996/5/5",
    "1996/5/6",
    "1996/5/7",
    "1996/5/8",
    "1996/5/9",
    "1996/5/10",
    "1996/5/11",
    "1996/5/12",
    "1996/5/13",
    "1996/5/14",
    "1996/5/15",
    "1996/5/16",
    "1996/5/17",
    "1996/5/18",
    "1996/5/19",
    "1996/5/20",
    "1996/5/21",
    "1996/5/22",
    "1996/5/23",
    "1996/5/24",
    "1996/5/25",
    "1996/5/26",
    "1996/5/27",
    "1996/5/28",
    "1996/5/29",
    "1996/5/30",
    "1996/5/31",
    "1996/6/1",
    "1996/6/2",
    "1996/6/3",
    "1996/6/4",
    "1996/6/5",
    "1996/6/6",
    "1996/6/7",
    "1996/6/8",
    "1996/6/9",
    "1996/6/10",
    "1996/6/11",
    "1996/6/12",
    "1996/6/13",
    "1996/6/14",
    "1996/6/15",
    "1996/6/16",
    "1996/6/17",
    "1996/6/18",
    "1996/6/19",
    "1996/6/20",
    "1996/6/21",
    "1996/6/22",
    "1996/6/23",
    "1996/6/24",
    "1996/6/25",
    "1996/6/26",
    "1996/6/27",
    "1996/6/28",
    "1996/6/29",
    "1996/6/30",
    "1996/7/1",
    "1996/7/2",
    "1996/7/3",
    "1996/7/4",
    "1996/7/5",
    "1996/7/6",
    "1996/7/7",
    "1996/7/8",
    "1996/7/9",
    "1996/7/10",
    "1996/7/11",
    "1996/7/12",
    "1996/7/13",
    "1996/7/14",
    "1996/7/15",
    "1996/7/16",
    "1996/7/17",
    "1996/7/18",
    "1996/7/19",
    "1996/7/20",
    "1996/7/21",
    "1996/7/22",
    "1996/7/23",
    "1996/7/24",
    "1996/7/25",
    "1996/7/26",
    "1996/7/27",
    "1996/7/28",
    "1996/7/29",
    "1996/7/30",
    "1996/7/31",
    "1996/8/1",
    "1996/8/2",
    "1996/8/3",
    "1996/8/4",
    "1996/8/5",
    "1996/8/6",
    "1996/8/7",
    "1996/8/8",
    "1996/8/9",
    "1996/8/10",
    "1996/8/11",
    "1996/8/12",
    "1996/8/13",
    "1996/8/14",
    "1996/8/15",
    "1996/8/16",
    "1996/8/17",
    "1996/8/18",
    "1996/8/19",
    "1996/8/20",
    "1996/8/21",
    "1996/8/22",
    "1996/8/23",
    "1996/8/24",
    "1996/8/25",
    "1996/8/26",
    "1996/8/27",
    "1996/8/28",
    "1996/8/29",
    "1996/8/30",
    "1996/8/31",
    "1996/9/1",
    "1996/9/2",
    "1996/9/3",
    "1996/9/4",
    "1996/9/5",
    "1996/9/6",
    "1996/9/7",
    "1996/9/8",
    "1996/9/9",
    "1996/9/10",
    "1996/9/11",
    "1996/9/12",
    "1996/9/13",
    "1996/9/14",
    "1996/9/15",
    "1996/9/16",
    "1996/9/17",
    "1996/9/18",
    "1996/9/19",
    "1996/9/20",
    "1996/9/21",
    "1996/9/22",
    "1996/9/23",
    "1996/9/24",
    "1996/9/25",
    "1996/9/26",
    "1996/9/27",
    "1996/9/28",
    "1996/9/29",
    "1996/9/30",
    "1996/10/1",
    "1996/10/2",
    "1996/10/3",
    "1996/10/4",
    "1996/10/5",
    "1996/10/6",
    "1996/10/7",
    "1996/10/8",
    "1996/10/9",
    "1996/10/10",
    "1996/10/11",
    "1996/10/12",
    "1996/10/13",
    "1996/10/14",
    "1996/10/15",
    "1996/10/16",
    "1996/10/17",
    "1996/10/18",
    "1996/10/19",
    "1996/10/20",
    "1996/10/21",
    "1996/10/22",
    "1996/10/23",
    "1996/10/24",
    "1996/10/25",
    "1996/10/26",
    "1996/10/27",
    "1996/10/28",
    "1996/10/29",
    "1996/10/30",
    "1996/10/31",
    "1996/11/1",
    "1996/11/2",
    "1996/11/3",
    "1996/11/4",
    "1996/11/5",
    "1996/11/6",
    "1996/11/7",
    "1996/11/8",
    "1996/11/9",
    "1996/11/10",
    "1996/11/11",
    "1996/11/12",
    "1996/11/13",
    "1996/11/14",
    "1996/11/15",
    "1996/11/16",
    "1996/11/17",
    "1996/11/18",
    "1996/11/19",
    "1996/11/20",
    "1996/11/21",
    "1996/11/22",
    "1996/11/23",
    "1996/11/24",
    "1996/11/25",
    "1996/11/26",
    "1996/11/27",
    "1996/11/28",
    "1996/11/29",
    "1996/11/30",
    "1996/12/1",
    "1996/12/2",
    "1996/12/3",
    "1996/12/4",
    "1996/12/5",
    "1996/12/6",
    "1996/12/7",
    "1996/12/8",
    "1996/12/9",
    "1996/12/10",
    "1996/12/11",
    "1996/12/12",
    "1996/12/13",
    "1996/12/14",
    "1996/12/15",
    "1996/12/16",
    "1996/12/17",
    "1996/12/18",
    "1996/12/19",
    "1996/12/20",
    "1996/12/21",
    "1996/12/22",
    "1996/12/23",
    "1996/12/24",
    "1996/12/25",
    "1996/12/26",
    "1996/12/27",
    "1996/12/28",
    "1996/12/29",
    "1996/12/30",
    "1996/12/31",
    "1997/1/1",
    "1997/1/2",
    "1997/1/3",
    "1997/1/4",
    "1997/1/5",
    "1997/1/6",
    "1997/1/7",
    "1997/1/8",
    "1997/1/9",
    "1997/1/10",
    "1997/1/11",
    "1997/1/12",
    "1997/1/13",
    "1997/1/14",
    "1997/1/15",
    "1997/1/16",
    "1997/1/17",
    "1997/1/18",
    "1997/1/19",
    "1997/1/20",
    "1997/1/21",
    "1997/1/22",
    "1997/1/23",
    "1997/1/24",
    "1997/1/25",
    "1997/1/26",
    "1997/1/27",
    "1997/1/28",
    "1997/1/29",
    "1997/1/30",
    "1997/1/31",
    "1997/2/1",
    "1997/2/2",
    "1997/2/3",
    "1997/2/4",
    "1997/2/5",
    "1997/2/6",
    "1997/2/7",
    "1997/2/8",
    "1997/2/9",
    "1997/2/10",
    "1997/2/11",
    "1997/2/12",
    "1997/2/13",
    "1997/2/14",
    "1997/2/15",
    "1997/2/16",
    "1997/2/17",
    "1997/2/18",
    "1997/2/19",
    "1997/2/20",
    "1997/2/21",
    "1997/2/22",
    "1997/2/23",
    "1997/2/24",
    "1997/2/25",
    "1997/2/26",
    "1997/2/27",
    "1997/2/28",
    "1997/3/1",
    "1997/3/2",
    "1997/3/3",
    "1997/3/4",
    "1997/3/5",
    "1997/3/6",
    "1997/3/7",
    "1997/3/8",
    "1997/3/9",
    "1997/3/10",
    "1997/3/11",
    "1997/3/12",
    "1997/3/13",
    "1997/3/14",
    "1997/3/15",
    "1997/3/16",
    "1997/3/17",
    "1997/3/18",
    "1997/3/19",
    "1997/3/20",
    "1997/3/21",
    "1997/3/22",
    "1997/3/23",
    "1997/3/24",
    "1997/3/25",
    "1997/3/26",
    "1997/3/27",
    "1997/3/28",
    "1997/3/29",
    "1997/3/30",
    "1997/3/31",
    "1997/4/1",
    "1997/4/2",
    "1997/4/3",
    "1997/4/4",
    "1997/4/5",
    "1997/4/6",
    "1997/4/7",
    "1997/4/8",
    "1997/4/9",
    "1997/4/10",
    "1997/4/11",
    "1997/4/12",
    "1997/4/13",
    "1997/4/14",
    "1997/4/15",
    "1997/4/16",
    "1997/4/17",
    "1997/4/18",
    "1997/4/19",
    "1997/4/20",
    "1997/4/21",
    "1997/4/22",
    "1997/4/23",
    "1997/4/24",
    "1997/4/25",
    "1997/4/26",
    "1997/4/27",
    "1997/4/28",
    "1997/4/29",
    "1997/4/30",
    "1997/5/1",
    "1997/5/2",
    "1997/5/3",
    "1997/5/4",
    "1997/5/5",
    "1997/5/6",
    "1997/5/7",
    "1997/5/8",
    "1997/5/9",
    "1997/5/10",
    "1997/5/11",
    "1997/5/12",
    "1997/5/13",
    "1997/5/14",
    "1997/5/15",
    "1997/5/16",
    "1997/5/17",
    "1997/5/18",
    "1997/5/19",
    "1997/5/20",
    "1997/5/21",
    "1997/5/22",
    "1997/5/23",
    "1997/5/24",
    "1997/5/25",
    "1997/5/26",
    "1997/5/27",
    "1997/5/28",
    "1997/5/29",
    "1997/5/30",
    "1997/5/31",
    "1997/6/1",
    "1997/6/2",
    "1997/6/3",
    "1997/6/4",
    "1997/6/5",
    "1997/6/6",
    "1997/6/7",
    "1997/6/8",
    "1997/6/9",
    "1997/6/10",
    "1997/6/11",
    "1997/6/12",
    "1997/6/13",
    "1997/6/14",
    "1997/6/15",
    "1997/6/16",
    "1997/6/17",
    "1997/6/18",
    "1997/6/19",
    "1997/6/20",
    "1997/6/21",
    "1997/6/22",
    "1997/6/23",
    "1997/6/24",
    "1997/6/25",
    "1997/6/26",
    "1997/6/27",
    "1997/6/28",
    "1997/6/29",
    "1997/6/30",
    "1997/7/1",
    "1997/7/2",
    "1997/7/3",
    "1997/7/4",
    "1997/7/5",
    "1997/7/6",
    "1997/7/7",
    "1997/7/8",
    "1997/7/9",
    "1997/7/10",
    "1997/7/11",
    "1997/7/12",
    "1997/7/13",
    "1997/7/14",
    "1997/7/15",
    "1997/7/16",
    "1997/7/17",
    "1997/7/18",
    "1997/7/19",
    "1997/7/20",
    "1997/7/21",
    "1997/7/22",
    "1997/7/23",
    "1997/7/24",
    "1997/7/25",
    "1997/7/26",
    "1997/7/27",
    "1997/7/28",
    "1997/7/29",
    "1997/7/30",
    "1997/7/31",
    "1997/8/1",
    "1997/8/2",
    "1997/8/3",
    "1997/8/4",
    "1997/8/5",
    "1997/8/6",
    "1997/8/7",
    "1997/8/8",
    "1997/8/9",
    "1997/8/10",
    "1997/8/11",
    "1997/8/12",
    "1997/8/13",
    "1997/8/14",
    "1997/8/15",
    "1997/8/16",
    "1997/8/17",
    "1997/8/18",
    "1997/8/19",
    "1997/8/20",
    "1997/8/21",
    "1997/8/22",
    "1997/8/23",
    "1997/8/24",
    "1997/8/25",
    "1997/8/26",
    "1997/8/27",
    "1997/8/28",
    "1997/8/29",
    "1997/8/30",
    "1997/8/31",
    "1997/9/1",
    "1997/9/2",
    "1997/9/3",
    "1997/9/4",
    "1997/9/5",
    "1997/9/6",
    "1997/9/7",
    "1997/9/8",
    "1997/9/9",
    "1997/9/10",
    "1997/9/11",
    "1997/9/12",
    "1997/9/13",
    "1997/9/14",
    "1997/9/15",
    "1997/9/16",
    "1997/9/17",
    "1997/9/18",
    "1997/9/19",
    "1997/9/20",
    "1997/9/21",
    "1997/9/22",
    "1997/9/23",
    "1997/9/24",
    "1997/9/25",
    "1997/9/26",
    "1997/9/27",
    "1997/9/28",
    "1997/9/29",
    "1997/9/30",
    "1997/10/1",
    "1997/10/2",
    "1997/10/3",
    "1997/10/4",
    "1997/10/5",
    "1997/10/6",
    "1997/10/7",
    "1997/10/8",
    "1997/10/9",
    "1997/10/10",
    "1997/10/11",
    "1997/10/12",
    "1997/10/13",
    "1997/10/14",
    "1997/10/15",
    "1997/10/16",
    "1997/10/17",
    "1997/10/18",
    "1997/10/19",
    "1997/10/20",
    "1997/10/21",
    "1997/10/22",
    "1997/10/23",
    "1997/10/24",
    "1997/10/25",
    "1997/10/26",
    "1997/10/27",
    "1997/10/28",
    "1997/10/29",
    "1997/10/30",
    "1997/10/31",
    "1997/11/1",
    "1997/11/2",
    "1997/11/3",
    "1997/11/4",
    "1997/11/5",
    "1997/11/6",
    "1997/11/7",
    "1997/11/8",
    "1997/11/9",
    "1997/11/10",
    "1997/11/11",
    "1997/11/12",
    "1997/11/13",
    "1997/11/14",
    "1997/11/15",
    "1997/11/16",
    "1997/11/17",
    "1997/11/18",
    "1997/11/19",
    "1997/11/20",
    "1997/11/21",
    "1997/11/22",
    "1997/11/23",
    "1997/11/24",
    "1997/11/25",
    "1997/11/26",
    "1997/11/27",
    "1997/11/28",
    "1997/11/29",
    "1997/11/30",
    "1997/12/1",
    "1997/12/2",
    "1997/12/3",
    "1997/12/4",
    "1997/12/5",
    "1997/12/6",
    "1997/12/7",
    "1997/12/8",
    "1997/12/9",
    "1997/12/10",
    "1997/12/11",
    "1997/12/12",
    "1997/12/13",
    "1997/12/14",
    "1997/12/15",
    "1997/12/16",
    "1997/12/17",
    "1997/12/18",
    "1997/12/19",
    "1997/12/20",
    "1997/12/21",
    "1997/12/22",
    "1997/12/23",
    "1997/12/24",
    "1997/12/25",
    "1997/12/26",
    "1997/12/27",
    "1997/12/28",
    "1997/12/29",
    "1997/12/30",
    "1997/12/31",
    "1998/1/1",
    "1998/1/2",
    "1998/1/3",
    "1998/1/4",
    "1998/1/5",
    "1998/1/6",
    "1998/1/7",
    "1998/1/8",
    "1998/1/9",
    "1998/1/10",
    "1998/1/11",
    "1998/1/12",
    "1998/1/13",
    "1998/1/14",
    "1998/1/15",
    "1998/1/16",
    "1998/1/17",
    "1998/1/18",
    "1998/1/19",
    "1998/1/20",
    "1998/1/21",
    "1998/1/22",
    "1998/1/23",
    "1998/1/24",
    "1998/1/25",
    "1998/1/26",
    "1998/1/27",
    "1998/1/28",
    "1998/1/29",
    "1998/1/30",
    "1998/1/31",
    "1998/2/1",
    "1998/2/2",
    "1998/2/3",
    "1998/2/4",
    "1998/2/5",
    "1998/2/6",
    "1998/2/7",
    "1998/2/8",
    "1998/2/9",
    "1998/2/10",
    "1998/2/11",
    "1998/2/12",
    "1998/2/13",
    "1998/2/14",
    "1998/2/15",
    "1998/2/16",
    "1998/2/17",
    "1998/2/18",
    "1998/2/19",
    "1998/2/20",
    "1998/2/21",
    "1998/2/22",
    "1998/2/23",
    "1998/2/24",
    "1998/2/25",
    "1998/2/26",
    "1998/2/27",
    "1998/2/28",
    "1998/3/1",
    "1998/3/2",
    "1998/3/3",
    "1998/3/4",
    "1998/3/5",
    "1998/3/6",
    "1998/3/7",
    "1998/3/8",
    "1998/3/9",
    "1998/3/10",
    "1998/3/11",
    "1998/3/12",
    "1998/3/13",
    "1998/3/14",
    "1998/3/15",
    "1998/3/16",
    "1998/3/17",
    "1998/3/18",
    "1998/3/19",
    "1998/3/20",
    "1998/3/21",
    "1998/3/22",
    "1998/3/23",
    "1998/3/24",
    "1998/3/25",
    "1998/3/26",
    "1998/3/27",
    "1998/3/28",
    "1998/3/29",
    "1998/3/30",
    "1998/3/31",
    "1998/4/1",
    "1998/4/2",
    "1998/4/3",
    "1998/4/4",
    "1998/4/5",
    "1998/4/6",
    "1998/4/7",
    "1998/4/8",
    "1998/4/9",
    "1998/4/10",
    "1998/4/11",
    "1998/4/12",
    "1998/4/13",
    "1998/4/14",
    "1998/4/15",
    "1998/4/16",
    "1998/4/17",
    "1998/4/18",
    "1998/4/19",
    "1998/4/20",
    "1998/4/21",
    "1998/4/22",
    "1998/4/23",
    "1998/4/24",
    "1998/4/25",
    "1998/4/26",
    "1998/4/27",
    "1998/4/28",
    "1998/4/29",
    "1998/4/30",
    "1998/5/1",
    "1998/5/2",
    "1998/5/3",
    "1998/5/4",
    "1998/5/5",
    "1998/5/6",
    "1998/5/7",
    "1998/5/8",
    "1998/5/9",
    "1998/5/10",
    "1998/5/11",
    "1998/5/12",
    "1998/5/13",
    "1998/5/14",
    "1998/5/15",
    "1998/5/16",
    "1998/5/17",
    "1998/5/18",
    "1998/5/19",
    "1998/5/20",
    "1998/5/21",
    "1998/5/22",
    "1998/5/23",
    "1998/5/24",
    "1998/5/25",
    "1998/5/26",
    "1998/5/27",
    "1998/5/28",
    "1998/5/29",
    "1998/5/30",
    "1998/5/31",
    "1998/6/1",
    "1998/6/2",
    "1998/6/3",
    "1998/6/4",
    "1998/6/5",
    "1998/6/6",
    "1998/6/7",
    "1998/6/8",
    "1998/6/9",
    "1998/6/10",
    "1998/6/11",
    "1998/6/12",
    "1998/6/13",
    "1998/6/14",
    "1998/6/15",
    "1998/6/16",
    "1998/6/17",
    "1998/6/18",
    "1998/6/19",
    "1998/6/20",
    "1998/6/21",
    "1998/6/22",
    "1998/6/23",
    "1998/6/24",
    "1998/6/25",
    "1998/6/26",
    "1998/6/27",
    "1998/6/28",
    "1998/6/29",
    "1998/6/30",
    "1998/7/1",
    "1998/7/2",
    "1998/7/3",
    "1998/7/4",
    "1998/7/5",
    "1998/7/6",
    "1998/7/7",
    "1998/7/8",
    "1998/7/9",
    "1998/7/10",
    "1998/7/11",
    "1998/7/12",
    "1998/7/13",
    "1998/7/14",
    "1998/7/15",
    "1998/7/16",
    "1998/7/17",
    "1998/7/18",
    "1998/7/19",
    "1998/7/20",
    "1998/7/21",
    "1998/7/22",
    "1998/7/23",
    "1998/7/24",
    "1998/7/25",
    "1998/7/26",
    "1998/7/27",
    "1998/7/28",
    "1998/7/29",
    "1998/7/30",
    "1998/7/31",
    "1998/8/1",
    "1998/8/2",
    "1998/8/3",
    "1998/8/4",
    "1998/8/5",
    "1998/8/6",
    "1998/8/7",
    "1998/8/8",
    "1998/8/9",
    "1998/8/10",
    "1998/8/11",
    "1998/8/12",
    "1998/8/13",
    "1998/8/14",
    "1998/8/15",
    "1998/8/16",
    "1998/8/17",
    "1998/8/18",
    "1998/8/19",
    "1998/8/20",
    "1998/8/21",
    "1998/8/22",
    "1998/8/23",
    "1998/8/24",
    "1998/8/25",
    "1998/8/26",
    "1998/8/27",
    "1998/8/28",
    "1998/8/29",
    "1998/8/30",
    "1998/8/31",
    "1998/9/1",
    "1998/9/2",
    "1998/9/3",
    "1998/9/4",
    "1998/9/5",
    "1998/9/6",
    "1998/9/7",
    "1998/9/8",
    "1998/9/9",
    "1998/9/10",
    "1998/9/11",
    "1998/9/12",
    "1998/9/13",
    "1998/9/14",
    "1998/9/15",
    "1998/9/16",
    "1998/9/17",
    "1998/9/18",
    "1998/9/19",
    "1998/9/20",
    "1998/9/21",
    "1998/9/22",
    "1998/9/23",
    "1998/9/24",
    "1998/9/25",
    "1998/9/26",
    "1998/9/27",
    "1998/9/28",
    "1998/9/29",
    "1998/9/30",
    "1998/10/1",
    "1998/10/2",
    "1998/10/3",
    "1998/10/4",
    "1998/10/5",
    "1998/10/6",
    "1998/10/7",
    "1998/10/8",
    "1998/10/9",
    "1998/10/10",
    "1998/10/11",
    "1998/10/12",
    "1998/10/13",
    "1998/10/14",
    "1998/10/15",
    "1998/10/16",
    "1998/10/17",
    "1998/10/18",
    "1998/10/19",
    "1998/10/20",
    "1998/10/21",
    "1998/10/22",
    "1998/10/23",
    "1998/10/24",
    "1998/10/25",
    "1998/10/26",
    "1998/10/27",
    "1998/10/28",
    "1998/10/29",
    "1998/10/30",
    "1998/10/31",
    "1998/11/1",
    "1998/11/2",
    "1998/11/3",
    "1998/11/4",
    "1998/11/5",
    "1998/11/6",
    "1998/11/7",
    "1998/11/8",
    "1998/11/9",
    "1998/11/10",
    "1998/11/11",
    "1998/11/12",
    "1998/11/13",
    "1998/11/14",
    "1998/11/15",
    "1998/11/16",
    "1998/11/17",
    "1998/11/18",
    "1998/11/19",
    "1998/11/20",
    "1998/11/21",
    "1998/11/22",
    "1998/11/23",
    "1998/11/24",
    "1998/11/25",
    "1998/11/26",
    "1998/11/27",
    "1998/11/28",
    "1998/11/29",
    "1998/11/30",
    "1998/12/1",
    "1998/12/2",
    "1998/12/3",
    "1998/12/4",
    "1998/12/5",
    "1998/12/6",
    "1998/12/7",
    "1998/12/8",
    "1998/12/9",
    "1998/12/10",
    "1998/12/11",
    "1998/12/12",
    "1998/12/13",
    "1998/12/14",
    "1998/12/15",
    "1998/12/16",
    "1998/12/17",
    "1998/12/18",
    "1998/12/19",
    "1998/12/20",
    "1998/12/21",
    "1998/12/22",
    "1998/12/23",
    "1998/12/24",
    "1998/12/25",
    "1998/12/26",
    "1998/12/27",
    "1998/12/28",
    "1998/12/29",
    "1998/12/30",
    "1998/12/31",
    "1999/1/1",
    "1999/1/2",
    "1999/1/3",
    "1999/1/4",
    "1999/1/5",
    "1999/1/6",
    "1999/1/7",
    "1999/1/8",
    "1999/1/9",
    "1999/1/10",
    "1999/1/11",
    "1999/1/12",
    "1999/1/13",
    "1999/1/14",
    "1999/1/15",
    "1999/1/16",
    "1999/1/17",
    "1999/1/18",
    "1999/1/19",
    "1999/1/20",
    "1999/1/21",
    "1999/1/22",
    "1999/1/23",
    "1999/1/24",
    "1999/1/25",
    "1999/1/26",
    "1999/1/27",
    "1999/1/28",
    "1999/1/29",
    "1999/1/30",
    "1999/1/31",
    "1999/2/1",
    "1999/2/2",
    "1999/2/3",
    "1999/2/4",
    "1999/2/5",
    "1999/2/6",
    "1999/2/7",
    "1999/2/8",
    "1999/2/9",
    "1999/2/10",
    "1999/2/11",
    "1999/2/12",
    "1999/2/13",
    "1999/2/14",
    "1999/2/15",
    "1999/2/16",
    "1999/2/17",
    "1999/2/18",
    "1999/2/19",
    "1999/2/20",
    "1999/2/21",
    "1999/2/22",
    "1999/2/23",
    "1999/2/24",
    "1999/2/25",
    "1999/2/26",
    "1999/2/27",
    "1999/2/28",
    "1999/3/1",
    "1999/3/2",
    "1999/3/3",
    "1999/3/4",
    "1999/3/5",
    "1999/3/6",
    "1999/3/7",
    "1999/3/8",
    "1999/3/9",
    "1999/3/10",
    "1999/3/11",
    "1999/3/12",
    "1999/3/13",
    "1999/3/14",
    "1999/3/15",
    "1999/3/16",
    "1999/3/17",
    "1999/3/18",
    "1999/3/19",
    "1999/3/20",
    "1999/3/21",
    "1999/3/22",
    "1999/3/23",
    "1999/3/24",
    "1999/3/25",
    "1999/3/26",
    "1999/3/27",
    "1999/3/28",
    "1999/3/29",
    "1999/3/30",
    "1999/3/31",
    "1999/4/1",
    "1999/4/2",
    "1999/4/3",
    "1999/4/4",
    "1999/4/5",
    "1999/4/6",
    "1999/4/7",
    "1999/4/8",
    "1999/4/9",
    "1999/4/10",
    "1999/4/11",
    "1999/4/12",
    "1999/4/13",
    "1999/4/14",
    "1999/4/15",
    "1999/4/16",
    "1999/4/17",
    "1999/4/18",
    "1999/4/19",
    "1999/4/20",
    "1999/4/21",
    "1999/4/22",
    "1999/4/23",
    "1999/4/24",
    "1999/4/25",
    "1999/4/26",
    "1999/4/27",
    "1999/4/28",
    "1999/4/29",
    "1999/4/30",
    "1999/5/1",
    "1999/5/2",
    "1999/5/3",
    "1999/5/4",
    "1999/5/5",
    "1999/5/6",
    "1999/5/7",
    "1999/5/8",
    "1999/5/9",
    "1999/5/10",
    "1999/5/11",
    "1999/5/12",
    "1999/5/13",
    "1999/5/14",
    "1999/5/15",
    "1999/5/16",
    "1999/5/17",
    "1999/5/18",
    "1999/5/19",
    "1999/5/20",
    "1999/5/21",
    "1999/5/22",
    "1999/5/23",
    "1999/5/24",
    "1999/5/25",
    "1999/5/26",
    "1999/5/27",
    "1999/5/28",
    "1999/5/29",
    "1999/5/30",
    "1999/5/31",
    "1999/6/1",
    "1999/6/2",
    "1999/6/3",
    "1999/6/4",
    "1999/6/5",
    "1999/6/6",
    "1999/6/7",
    "1999/6/8",
    "1999/6/9",
    "1999/6/10",
    "1999/6/11",
    "1999/6/12",
    "1999/6/13",
    "1999/6/14",
    "1999/6/15",
    "1999/6/16",
    "1999/6/17",
    "1999/6/18",
    "1999/6/19",
    "1999/6/20",
    "1999/6/21",
    "1999/6/22",
    "1999/6/23",
    "1999/6/24",
    "1999/6/25",
    "1999/6/26",
    "1999/6/27",
    "1999/6/28",
    "1999/6/29",
    "1999/6/30",
    "1999/7/1",
    "1999/7/2",
    "1999/7/3",
    "1999/7/4",
    "1999/7/5",
    "1999/7/6",
    "1999/7/7",
    "1999/7/8",
    "1999/7/9",
    "1999/7/10",
    "1999/7/11",
    "1999/7/12",
    "1999/7/13",
    "1999/7/14",
    "1999/7/15",
    "1999/7/16",
    "1999/7/17",
    "1999/7/18",
    "1999/7/19",
    "1999/7/20",
    "1999/7/21",
    "1999/7/22",
    "1999/7/23",
    "1999/7/24",
    "1999/7/25",
    "1999/7/26",
    "1999/7/27",
    "1999/7/28",
    "1999/7/29",
    "1999/7/30",
    "1999/7/31",
    "1999/8/1",
    "1999/8/2",
    "1999/8/3",
    "1999/8/4",
    "1999/8/5",
    "1999/8/6",
    "1999/8/7",
    "1999/8/8",
    "1999/8/9",
    "1999/8/10",
    "1999/8/11",
    "1999/8/12",
    "1999/8/13",
    "1999/8/14",
    "1999/8/15",
    "1999/8/16",
    "1999/8/17",
    "1999/8/18",
    "1999/8/19",
    "1999/8/20",
    "1999/8/21",
    "1999/8/22",
    "1999/8/23",
    "1999/8/24",
    "1999/8/25",
    "1999/8/26",
    "1999/8/27",
    "1999/8/28",
    "1999/8/29",
    "1999/8/30",
    "1999/8/31",
    "1999/9/1",
    "1999/9/2",
    "1999/9/3",
    "1999/9/4",
    "1999/9/5",
    "1999/9/6",
    "1999/9/7",
    "1999/9/8",
    "1999/9/9",
    "1999/9/10",
    "1999/9/11",
    "1999/9/12",
    "1999/9/13",
    "1999/9/14",
    "1999/9/15",
    "1999/9/16",
    "1999/9/17",
    "1999/9/18",
    "1999/9/19",
    "1999/9/20",
    "1999/9/21",
    "1999/9/22",
    "1999/9/23",
    "1999/9/24",
    "1999/9/25",
    "1999/9/26",
    "1999/9/27",
    "1999/9/28",
    "1999/9/29",
    "1999/9/30",
    "1999/10/1",
    "1999/10/2",
    "1999/10/3",
    "1999/10/4",
    "1999/10/5",
    "1999/10/6",
    "1999/10/7",
    "1999/10/8",
    "1999/10/9",
    "1999/10/10",
    "1999/10/11",
    "1999/10/12",
    "1999/10/13",
    "1999/10/14",
    "1999/10/15",
    "1999/10/16",
    "1999/10/17",
    "1999/10/18",
    "1999/10/19",
    "1999/10/20",
    "1999/10/21",
    "1999/10/22",
    "1999/10/23",
    "1999/10/24",
    "1999/10/25",
    "1999/10/26",
    "1999/10/27",
    "1999/10/28",
    "1999/10/29",
    "1999/10/30",
    "1999/10/31",
    "1999/11/1",
    "1999/11/2",
    "1999/11/3",
    "1999/11/4",
    "1999/11/5",
    "1999/11/6",
    "1999/11/7",
    "1999/11/8",
    "1999/11/9",
    "1999/11/10",
    "1999/11/11",
    "1999/11/12",
    "1999/11/13",
    "1999/11/14",
    "1999/11/15",
    "1999/11/16",
    "1999/11/17",
    "1999/11/18",
    "1999/11/19",
    "1999/11/20",
    "1999/11/21",
    "1999/11/22",
    "1999/11/23",
    "1999/11/24",
    "1999/11/25",
    "1999/11/26",
    "1999/11/27",
    "1999/11/28",
    "1999/11/29",
    "1999/11/30",
    "1999/12/1",
    "1999/12/2",
    "1999/12/3",
    "1999/12/4",
    "1999/12/5",
    "1999/12/6",
    "1999/12/7",
    "1999/12/8",
    "1999/12/9",
    "1999/12/10",
    "1999/12/11",
    "1999/12/12",
    "1999/12/13",
    "1999/12/14",
    "1999/12/15",
    "1999/12/16",
    "1999/12/17",
    "1999/12/18",
    "1999/12/19",
    "1999/12/20",
    "1999/12/21",
    "1999/12/22",
    "1999/12/23",
    "1999/12/24",
    "1999/12/25",
    "1999/12/26",
    "1999/12/27",
    "1999/12/28",
    "1999/12/29",
    "1999/12/30",
    "1999/12/31",
    "2000/1/1",
    "2000/1/2",
    "2000/1/3",
    "2000/1/4",
    "2000/1/5",
    "2000/1/6",
    "2000/1/7",
    "2000/1/8",
    "2000/1/9",
    "2000/1/10",
    "2000/1/11",
    "2000/1/12",
    "2000/1/13",
    "2000/1/14",
    "2000/1/15",
    "2000/1/16",
    "2000/1/17",
    "2000/1/18",
    "2000/1/19",
    "2000/1/20",
    "2000/1/21",
    "2000/1/22",
    "2000/1/23",
    "2000/1/24",
    "2000/1/25",
    "2000/1/26",
    "2000/1/27",
    "2000/1/28",
    "2000/1/29",
    "2000/1/30",
    "2000/1/31",
    "2000/2/1",
    "2000/2/2",
    "2000/2/3",
    "2000/2/4",
    "2000/2/5",
    "2000/2/6",
    "2000/2/7",
    "2000/2/8",
    "2000/2/9",
    "2000/2/10",
    "2000/2/11",
    "2000/2/12",
    "2000/2/13",
    "2000/2/14",
    "2000/2/15",
    "2000/2/16",
    "2000/2/17",
    "2000/2/18",
    "2000/2/19",
    "2000/2/20",
    "2000/2/21",
    "2000/2/22",
    "2000/2/23",
    "2000/2/24",
    "2000/2/25",
    "2000/2/26",
    "2000/2/27",
    "2000/2/28",
    "2000/2/29",
    "2000/3/1",
    "2000/3/2",
    "2000/3/3",
    "2000/3/4",
    "2000/3/5",
    "2000/3/6",
    "2000/3/7",
    "2000/3/8",
    "2000/3/9",
    "2000/3/10",
    "2000/3/11",
    "2000/3/12",
    "2000/3/13",
    "2000/3/14",
    "2000/3/15",
    "2000/3/16",
    "2000/3/17",
    "2000/3/18",
    "2000/3/19",
    "2000/3/20",
    "2000/3/21",
    "2000/3/22",
    "2000/3/23",
    "2000/3/24",
    "2000/3/25",
    "2000/3/26",
    "2000/3/27",
    "2000/3/28",
    "2000/3/29",
    "2000/3/30",
    "2000/3/31",
    "2000/4/1",
    "2000/4/2",
    "2000/4/3",
    "2000/4/4",
    "2000/4/5",
    "2000/4/6",
    "2000/4/7",
    "2000/4/8",
    "2000/4/9",
    "2000/4/10",
    "2000/4/11",
    "2000/4/12",
    "2000/4/13",
    "2000/4/14",
    "2000/4/15",
    "2000/4/16",
    "2000/4/17",
    "2000/4/18",
    "2000/4/19",
    "2000/4/20",
    "2000/4/21",
    "2000/4/22",
    "2000/4/23",
    "2000/4/24",
    "2000/4/25",
    "2000/4/26",
    "2000/4/27",
    "2000/4/28",
    "2000/4/29",
    "2000/4/30",
    "2000/5/1",
    "2000/5/2",
    "2000/5/3",
    "2000/5/4",
    "2000/5/5",
    "2000/5/6",
    "2000/5/7",
    "2000/5/8",
    "2000/5/9",
    "2000/5/10",
    "2000/5/11",
    "2000/5/12",
    "2000/5/13",
    "2000/5/14",
    "2000/5/15",
    "2000/5/16",
    "2000/5/17",
    "2000/5/18",
    "2000/5/19",
    "2000/5/20",
    "2000/5/21",
    "2000/5/22",
    "2000/5/23",
    "2000/5/24",
    "2000/5/25",
    "2000/5/26",
    "2000/5/27",
    "2000/5/28",
    "2000/5/29",
    "2000/5/30",
    "2000/5/31",
    "2000/6/1",
    "2000/6/2",
    "2000/6/3",
    "2000/6/4",
    "2000/6/5",
    "2000/6/6",
    "2000/6/7",
    "2000/6/8",
    "2000/6/9",
    "2000/6/10",
    "2000/6/11",
    "2000/6/12",
    "2000/6/13",
    "2000/6/14",
    "2000/6/15",
    "2000/6/16",
    "2000/6/17",
    "2000/6/18",
    "2000/6/19",
    "2000/6/20",
    "2000/6/21",
    "2000/6/22",
    "2000/6/23",
    "2000/6/24",
    "2000/6/25",
    "2000/6/26",
    "2000/6/27",
    "2000/6/28",
    "2000/6/29",
    "2000/6/30",
    "2000/7/1",
    "2000/7/2",
    "2000/7/3",
    "2000/7/4",
    "2000/7/5",
    "2000/7/6",
    "2000/7/7",
    "2000/7/8",
    "2000/7/9",
    "2000/7/10",
    "2000/7/11",
    "2000/7/12",
    "2000/7/13",
    "2000/7/14",
    "2000/7/15",
    "2000/7/16",
    "2000/7/17",
    "2000/7/18",
    "2000/7/19",
    "2000/7/20",
    "2000/7/21",
    "2000/7/22",
    "2000/7/23",
    "2000/7/24",
    "2000/7/25",
    "2000/7/26",
    "2000/7/27",
    "2000/7/28",
    "2000/7/29",
    "2000/7/30",
    "2000/7/31",
    "2000/8/1",
    "2000/8/2",
    "2000/8/3",
    "2000/8/4",
    "2000/8/5",
    "2000/8/6",
    "2000/8/7",
    "2000/8/8",
    "2000/8/9",
    "2000/8/10",
    "2000/8/11",
    "2000/8/12",
    "2000/8/13",
    "2000/8/14",
    "2000/8/15",
    "2000/8/16",
    "2000/8/17",
    "2000/8/18",
    "2000/8/19",
    "2000/8/20",
    "2000/8/21",
    "2000/8/22",
    "2000/8/23",
    "2000/8/24",
    "2000/8/25",
    "2000/8/26",
    "2000/8/27",
    "2000/8/28",
    "2000/8/29",
    "2000/8/30",
    "2000/8/31",
    "2000/9/1",
    "2000/9/2",
    "2000/9/3",
    "2000/9/4",
    "2000/9/5",
    "2000/9/6",
    "2000/9/7",
    "2000/9/8",
    "2000/9/9",
    "2000/9/10",
    "2000/9/11",
    "2000/9/12",
    "2000/9/13",
    "2000/9/14",
    "2000/9/15",
    "2000/9/16",
    "2000/9/17",
    "2000/9/18",
    "2000/9/19",
    "2000/9/20",
    "2000/9/21",
    "2000/9/22",
    "2000/9/23",
    "2000/9/24",
    "2000/9/25",
    "2000/9/26",
    "2000/9/27",
    "2000/9/28",
    "2000/9/29",
    "2000/9/30",
    "2000/10/1",
    "2000/10/2",
    "2000/10/3",
    "2000/10/4",
    "2000/10/5",
    "2000/10/6",
    "2000/10/7",
    "2000/10/8",
    "2000/10/9",
    "2000/10/10",
    "2000/10/11",
    "2000/10/12",
    "2000/10/13",
    "2000/10/14",
    "2000/10/15",
    "2000/10/16",
    "2000/10/17",
    "2000/10/18",
    "2000/10/19",
    "2000/10/20",
    "2000/10/21",
    "2000/10/22",
    "2000/10/23",
    "2000/10/24",
    "2000/10/25",
    "2000/10/26",
    "2000/10/27",
    "2000/10/28",
    "2000/10/29",
    "2000/10/30",
    "2000/10/31",
    "2000/11/1",
    "2000/11/2",
    "2000/11/3",
    "2000/11/4",
    "2000/11/5",
    "2000/11/6",
    "2000/11/7",
    "2000/11/8",
    "2000/11/9",
    "2000/11/10",
    "2000/11/11",
    "2000/11/12",
    "2000/11/13",
    "2000/11/14",
    "2000/11/15",
    "2000/11/16",
    "2000/11/17",
    "2000/11/18",
    "2000/11/19",
    "2000/11/20",
    "2000/11/21",
    "2000/11/22",
    "2000/11/23",
    "2000/11/24",
    "2000/11/25",
    "2000/11/26",
    "2000/11/27",
    "2000/11/28",
    "2000/11/29",
    "2000/11/30",
    "2000/12/1",
    "2000/12/2",
    "2000/12/3",
    "2000/12/4",
    "2000/12/5",
    "2000/12/6",
    "2000/12/7",
    "2000/12/8",
    "2000/12/9",
    "2000/12/10",
    "2000/12/11",
    "2000/12/12",
    "2000/12/13",
    "2000/12/14",
    "2000/12/15",
    "2000/12/16",
    "2000/12/17",
    "2000/12/18",
    "2000/12/19",
    "2000/12/20",
    "2000/12/21",
    "2000/12/22",
    "2000/12/23",
    "2000/12/24",
    "2000/12/25",
    "2000/12/26",
    "2000/12/27",
    "2000/12/28",
    "2000/12/29",
    "2000/12/30",
    "2000/12/31",
    "2001/1/1",
    "2001/1/2",
    "2001/1/3",
    "2001/1/4",
    "2001/1/5",
    "2001/1/6",
    "2001/1/7",
    "2001/1/8",
    "2001/1/9",
    "2001/1/10",
    "2001/1/11",
    "2001/1/12",
    "2001/1/13",
    "2001/1/14",
    "2001/1/15",
    "2001/1/16",
    "2001/1/17",
    "2001/1/18",
    "2001/1/19",
    "2001/1/20",
    "2001/1/21",
    "2001/1/22",
    "2001/1/23",
    "2001/1/24",
    "2001/1/25",
    "2001/1/26",
    "2001/1/27",
    "2001/1/28",
    "2001/1/29",
    "2001/1/30",
    "2001/1/31",
    "2001/2/1",
    "2001/2/2",
    "2001/2/3",
    "2001/2/4",
    "2001/2/5",
    "2001/2/6",
    "2001/2/7",
    "2001/2/8",
    "2001/2/9",
    "2001/2/10",
    "2001/2/11",
    "2001/2/12",
    "2001/2/13",
    "2001/2/14",
    "2001/2/15",
    "2001/2/16",
    "2001/2/17",
    "2001/2/18",
    "2001/2/19",
    "2001/2/20",
    "2001/2/21",
    "2001/2/22",
    "2001/2/23",
    "2001/2/24",
    "2001/2/25",
    "2001/2/26",
    "2001/2/27",
    "2001/2/28",
    "2001/3/1",
    "2001/3/2",
    "2001/3/3",
    "2001/3/4",
    "2001/3/5",
    "2001/3/6",
    "2001/3/7",
    "2001/3/8",
    "2001/3/9",
    "2001/3/10",
    "2001/3/11",
    "2001/3/12",
    "2001/3/13",
    "2001/3/14",
    "2001/3/15",
    "2001/3/16",
    "2001/3/17",
    "2001/3/18",
    "2001/3/19",
    "2001/3/20",
    "2001/3/21",
    "2001/3/22",
    "2001/3/23",
    "2001/3/24",
    "2001/3/25",
    "2001/3/26",
    "2001/3/27",
    "2001/3/28",
    "2001/3/29",
    "2001/3/30",
    "2001/3/31",
    "2001/4/1",
    "2001/4/2",
    "2001/4/3",
    "2001/4/4",
    "2001/4/5",
    "2001/4/6",
    "2001/4/7",
    "2001/4/8",
    "2001/4/9",
    "2001/4/10",
    "2001/4/11",
    "2001/4/12",
    "2001/4/13",
    "2001/4/14",
    "2001/4/15",
    "2001/4/16",
    "2001/4/17",
    "2001/4/18",
    "2001/4/19",
    "2001/4/20",
    "2001/4/21",
    "2001/4/22",
    "2001/4/23",
    "2001/4/24",
    "2001/4/25",
    "2001/4/26",
    "2001/4/27",
    "2001/4/28",
    "2001/4/29",
    "2001/4/30",
    "2001/5/1",
    "2001/5/2",
    "2001/5/3",
    "2001/5/4",
    "2001/5/5",
    "2001/5/6",
    "2001/5/7",
    "2001/5/8",
    "2001/5/9",
    "2001/5/10",
    "2001/5/11",
    "2001/5/12",
    "2001/5/13",
    "2001/5/14",
    "2001/5/15",
    "2001/5/16",
    "2001/5/17",
    "2001/5/18",
    "2001/5/19",
    "2001/5/20",
    "2001/5/21",
    "2001/5/22",
    "2001/5/23",
    "2001/5/24",
    "2001/5/25",
    "2001/5/26",
    "2001/5/27",
    "2001/5/28",
    "2001/5/29",
    "2001/5/30",
    "2001/5/31",
    "2001/6/1",
    "2001/6/2",
    "2001/6/3",
    "2001/6/4",
    "2001/6/5",
    "2001/6/6",
    "2001/6/7",
    "2001/6/8",
    "2001/6/9",
    "2001/6/10",
    "2001/6/11",
    "2001/6/12",
    "2001/6/13",
    "2001/6/14",
    "2001/6/15",
    "2001/6/16",
    "2001/6/17",
    "2001/6/18",
    "2001/6/19",
    "2001/6/20",
    "2001/6/21",
    "2001/6/22",
    "2001/6/23",
    "2001/6/24",
    "2001/6/25",
    "2001/6/26",
    "2001/6/27",
    "2001/6/28",
    "2001/6/29",
    "2001/6/30",
    "2001/7/1",
    "2001/7/2",
    "2001/7/3",
    "2001/7/4",
    "2001/7/5",
    "2001/7/6",
    "2001/7/7",
    "2001/7/8",
    "2001/7/9",
    "2001/7/10",
    "2001/7/11",
    "2001/7/12",
    "2001/7/13",
    "2001/7/14",
    "2001/7/15",
    "2001/7/16",
    "2001/7/17",
    "2001/7/18",
    "2001/7/19",
    "2001/7/20",
    "2001/7/21",
    "2001/7/22",
    "2001/7/23",
    "2001/7/24",
    "2001/7/25",
    "2001/7/26",
    "2001/7/27",
    "2001/7/28",
    "2001/7/29",
    "2001/7/30",
    "2001/7/31",
    "2001/8/1",
    "2001/8/2",
    "2001/8/3",
    "2001/8/4",
    "2001/8/5",
    "2001/8/6",
    "2001/8/7",
    "2001/8/8",
    "2001/8/9",
    "2001/8/10",
    "2001/8/11",
    "2001/8/12",
    "2001/8/13",
    "2001/8/14",
    "2001/8/15",
    "2001/8/16",
    "2001/8/17",
    "2001/8/18",
    "2001/8/19",
    "2001/8/20",
    "2001/8/21",
    "2001/8/22",
    "2001/8/23",
    "2001/8/24",
    "2001/8/25",
    "2001/8/26",
    "2001/8/27",
    "2001/8/28",
    "2001/8/29",
    "2001/8/30",
    "2001/8/31",
    "2001/9/1",
    "2001/9/2",
    "2001/9/3",
    "2001/9/4",
    "2001/9/5",
    "2001/9/6",
    "2001/9/7",
    "2001/9/8",
    "2001/9/9",
    "2001/9/10",
    "2001/9/11",
    "2001/9/12",
    "2001/9/13",
    "2001/9/14",
    "2001/9/15",
    "2001/9/16",
    "2001/9/17",
    "2001/9/18",
    "2001/9/19",
    "2001/9/20",
    "2001/9/21",
    "2001/9/22",
    "2001/9/23",
    "2001/9/24",
    "2001/9/25",
    "2001/9/26",
    "2001/9/27",
    "2001/9/28",
    "2001/9/29",
    "2001/9/30",
    "2001/10/1",
    "2001/10/2",
    "2001/10/3",
    "2001/10/4",
    "2001/10/5",
    "2001/10/6",
    "2001/10/7",
    "2001/10/8",
    "2001/10/9",
    "2001/10/10",
    "2001/10/11",
    "2001/10/12",
    "2001/10/13",
    "2001/10/14",
    "2001/10/15",
    "2001/10/16",
    "2001/10/17",
    "2001/10/18",
    "2001/10/19",
    "2001/10/20",
    "2001/10/21",
    "2001/10/22",
    "2001/10/23",
    "2001/10/24",
    "2001/10/25",
    "2001/10/26",
    "2001/10/27",
    "2001/10/28",
    "2001/10/29",
    "2001/10/30",
    "2001/10/31",
    "2001/11/1",
    "2001/11/2",
    "2001/11/3",
    "2001/11/4",
    "2001/11/5",
    "2001/11/6",
    "2001/11/7",
    "2001/11/8",
    "2001/11/9",
    "2001/11/10",
    "2001/11/11",
    "2001/11/12",
    "2001/11/13",
    "2001/11/14",
    "2001/11/15",
    "2001/11/16",
    "2001/11/17",
    "2001/11/18",
    "2001/11/19",
    "2001/11/20",
    "2001/11/21",
    "2001/11/22",
    "2001/11/23",
    "2001/11/24",
    "2001/11/25",
    "2001/11/26",
    "2001/11/27",
    "2001/11/28",
    "2001/11/29",
    "2001/11/30",
    "2001/12/1",
    "2001/12/2",
    "2001/12/3",
    "2001/12/4",
    "2001/12/5",
    "2001/12/6",
    "2001/12/7",
    "2001/12/8",
    "2001/12/9",
    "2001/12/10",
    "2001/12/11",
    "2001/12/12",
    "2001/12/13",
    "2001/12/14",
    "2001/12/15",
    "2001/12/16",
    "2001/12/17",
    "2001/12/18",
    "2001/12/19",
    "2001/12/20",
    "2001/12/21",
    "2001/12/22",
    "2001/12/23",
    "2001/12/24",
    "2001/12/25",
    "2001/12/26",
    "2001/12/27",
    "2001/12/28",
    "2001/12/29",
    "2001/12/30",
    "2001/12/31",
    "2002/1/1",
    "2002/1/2",
    "2002/1/3",
    "2002/1/4",
    "2002/1/5",
    "2002/1/6",
    "2002/1/7",
    "2002/1/8",
    "2002/1/9",
    "2002/1/10",
    "2002/1/11",
    "2002/1/12",
    "2002/1/13",
    "2002/1/14",
    "2002/1/15",
    "2002/1/16",
    "2002/1/17",
    "2002/1/18",
    "2002/1/19",
    "2002/1/20",
    "2002/1/21",
    "2002/1/22",
    "2002/1/23",
    "2002/1/24",
    "2002/1/25",
    "2002/1/26",
    "2002/1/27",
    "2002/1/28",
    "2002/1/29",
    "2002/1/30",
    "2002/1/31",
    "2002/2/1",
    "2002/2/2",
    "2002/2/3",
    "2002/2/4",
    "2002/2/5",
    "2002/2/6",
    "2002/2/7",
    "2002/2/8",
    "2002/2/9",
    "2002/2/10",
    "2002/2/11",
    "2002/2/12",
    "2002/2/13",
    "2002/2/14",
    "2002/2/15",
    "2002/2/16",
    "2002/2/17",
    "2002/2/18",
    "2002/2/19",
    "2002/2/20",
    "2002/2/21",
    "2002/2/22",
    "2002/2/23",
    "2002/2/24",
    "2002/2/25",
    "2002/2/26",
    "2002/2/27",
    "2002/2/28",
    "2002/3/1",
    "2002/3/2",
    "2002/3/3",
    "2002/3/4",
    "2002/3/5",
    "2002/3/6",
    "2002/3/7",
    "2002/3/8",
    "2002/3/9",
    "2002/3/10",
    "2002/3/11",
    "2002/3/12",
    "2002/3/13",
    "2002/3/14",
    "2002/3/15",
    "2002/3/16",
    "2002/3/17",
    "2002/3/18",
    "2002/3/19",
    "2002/3/20",
    "2002/3/21",
    "2002/3/22",
    "2002/3/23",
    "2002/3/24",
    "2002/3/25",
    "2002/3/26",
    "2002/3/27",
    "2002/3/28",
    "2002/3/29",
    "2002/3/30",
    "2002/3/31",
    "2002/4/1",
    "2002/4/2",
    "2002/4/3",
    "2002/4/4",
    "2002/4/5",
    "2002/4/6",
    "2002/4/7",
    "2002/4/8",
    "2002/4/9",
    "2002/4/10",
    "2002/4/11",
    "2002/4/12",
    "2002/4/13",
    "2002/4/14",
    "2002/4/15",
    "2002/4/16",
    "2002/4/17",
    "2002/4/18",
    "2002/4/19",
    "2002/4/20",
    "2002/4/21",
    "2002/4/22",
    "2002/4/23",
    "2002/4/24",
    "2002/4/25",
    "2002/4/26",
    "2002/4/27",
    "2002/4/28",
    "2002/4/29",
    "2002/4/30",
    "2002/5/1",
    "2002/5/2",
    "2002/5/3",
    "2002/5/4",
    "2002/5/5",
    "2002/5/6",
    "2002/5/7",
    "2002/5/8",
    "2002/5/9",
    "2002/5/10",
    "2002/5/11",
    "2002/5/12",
    "2002/5/13",
    "2002/5/14",
    "2002/5/15",
    "2002/5/16",
    "2002/5/17",
    "2002/5/18",
    "2002/5/19",
    "2002/5/20",
    "2002/5/21",
    "2002/5/22",
    "2002/5/23",
    "2002/5/24",
    "2002/5/25",
    "2002/5/26",
    "2002/5/27",
    "2002/5/28",
    "2002/5/29",
    "2002/5/30",
    "2002/5/31",
    "2002/6/1",
    "2002/6/2",
    "2002/6/3",
    "2002/6/4",
    "2002/6/5",
    "2002/6/6",
    "2002/6/7",
    "2002/6/8",
    "2002/6/9",
    "2002/6/10",
    "2002/6/11",
    "2002/6/12",
    "2002/6/13",
    "2002/6/14",
    "2002/6/15",
    "2002/6/16",
    "2002/6/17",
    "2002/6/18",
    "2002/6/19",
    "2002/6/20",
    "2002/6/21",
    "2002/6/22",
    "2002/6/23",
    "2002/6/24",
    "2002/6/25",
    "2002/6/26",
    "2002/6/27",
    "2002/6/28",
    "2002/6/29",
    "2002/6/30",
    "2002/7/1",
    "2002/7/2",
    "2002/7/3",
    "2002/7/4",
    "2002/7/5",
    "2002/7/6",
    "2002/7/7",
    "2002/7/8",
    "2002/7/9",
    "2002/7/10",
    "2002/7/11",
    "2002/7/12",
    "2002/7/13",
    "2002/7/14",
    "2002/7/15",
    "2002/7/16",
    "2002/7/17",
    "2002/7/18",
    "2002/7/19",
    "2002/7/20",
    "2002/7/21",
    "2002/7/22",
    "2002/7/23",
    "2002/7/24",
    "2002/7/25",
    "2002/7/26",
    "2002/7/27",
    "2002/7/28",
    "2002/7/29",
    "2002/7/30",
    "2002/7/31",
    "2002/8/1",
    "2002/8/2",
    "2002/8/3",
    "2002/8/4",
    "2002/8/5",
    "2002/8/6",
    "2002/8/7",
    "2002/8/8",
    "2002/8/9",
    "2002/8/10",
    "2002/8/11",
    "2002/8/12",
    "2002/8/13",
    "2002/8/14",
    "2002/8/15",
    "2002/8/16",
    "2002/8/17",
    "2002/8/18",
    "2002/8/19",
    "2002/8/20",
    "2002/8/21",
    "2002/8/22",
    "2002/8/23",
    "2002/8/24",
    "2002/8/25",
    "2002/8/26",
    "2002/8/27",
    "2002/8/28",
    "2002/8/29",
    "2002/8/30",
    "2002/8/31",
    "2002/9/1",
    "2002/9/2",
    "2002/9/3",
    "2002/9/4",
    "2002/9/5",
    "2002/9/6",
    "2002/9/7",
    "2002/9/8",
    "2002/9/9",
    "2002/9/10",
    "2002/9/11",
    "2002/9/12",
    "2002/9/13",
    "2002/9/14",
    "2002/9/15",
    "2002/9/16",
    "2002/9/17",
    "2002/9/18",
    "2002/9/19",
    "2002/9/20",
    "2002/9/21",
    "2002/9/22",
    "2002/9/23",
    "2002/9/24",
    "2002/9/25",
    "2002/9/26",
    "2002/9/27",
    "2002/9/28",
    "2002/9/29",
    "2002/9/30",
    "2002/10/1",
    "2002/10/2",
    "2002/10/3",
    "2002/10/4",
    "2002/10/5",
    "2002/10/6",
    "2002/10/7",
    "2002/10/8",
    "2002/10/9",
    "2002/10/10",
    "2002/10/11",
    "2002/10/12",
    "2002/10/13",
    "2002/10/14",
    "2002/10/15",
    "2002/10/16",
    "2002/10/17",
    "2002/10/18",
    "2002/10/19",
    "2002/10/20",
    "2002/10/21",
    "2002/10/22",
    "2002/10/23",
    "2002/10/24",
    "2002/10/25",
    "2002/10/26",
    "2002/10/27",
    "2002/10/28",
    "2002/10/29",
    "2002/10/30",
    "2002/10/31",
    "2002/11/1",
    "2002/11/2",
    "2002/11/3",
    "2002/11/4",
    "2002/11/5",
    "2002/11/6",
    "2002/11/7",
    "2002/11/8",
    "2002/11/9",
    "2002/11/10",
    "2002/11/11",
    "2002/11/12",
    "2002/11/13",
    "2002/11/14",
    "2002/11/15",
    "2002/11/16",
    "2002/11/17",
    "2002/11/18",
    "2002/11/19",
    "2002/11/20",
    "2002/11/21",
    "2002/11/22",
    "2002/11/23",
    "2002/11/24",
    "2002/11/25",
    "2002/11/26",
    "2002/11/27",
    "2002/11/28",
    "2002/11/29",
    "2002/11/30",
    "2002/12/1",
    "2002/12/2",
    "2002/12/3",
    "2002/12/4",
    "2002/12/5",
    "2002/12/6",
    "2002/12/7",
    "2002/12/8",
    "2002/12/9",
    "2002/12/10",
    "2002/12/11",
    "2002/12/12",
    "2002/12/13",
    "2002/12/14",
    "2002/12/15",
    "2002/12/16",
    "2002/12/17",
    "2002/12/18",
    "2002/12/19",
    "2002/12/20",
    "2002/12/21",
    "2002/12/22",
    "2002/12/23",
    "2002/12/24",
    "2002/12/25",
    "2002/12/26",
    "2002/12/27",
    "2002/12/28",
    "2002/12/29",
    "2002/12/30",
    "2002/12/31",
    "2003/1/1",
    "2003/1/2",
    "2003/1/3",
    "2003/1/4",
    "2003/1/5",
    "2003/1/6",
    "2003/1/7",
    "2003/1/8",
    "2003/1/9",
    "2003/1/10",
    "2003/1/11",
    "2003/1/12",
    "2003/1/13",
    "2003/1/14",
    "2003/1/15",
    "2003/1/16",
    "2003/1/17",
    "2003/1/18",
    "2003/1/19",
    "2003/1/20",
    "2003/1/21",
    "2003/1/22",
    "2003/1/23",
    "2003/1/24",
    "2003/1/25",
    "2003/1/26",
    "2003/1/27",
    "2003/1/28",
    "2003/1/29",
    "2003/1/30",
    "2003/1/31",
    "2003/2/1",
    "2003/2/2",
    "2003/2/3",
    "2003/2/4",
    "2003/2/5",
    "2003/2/6",
    "2003/2/7",
    "2003/2/8",
    "2003/2/9",
    "2003/2/10",
    "2003/2/11",
    "2003/2/12",
    "2003/2/13",
    "2003/2/14",
    "2003/2/15",
    "2003/2/16",
    "2003/2/17",
    "2003/2/18",
    "2003/2/19",
    "2003/2/20",
    "2003/2/21",
    "2003/2/22",
    "2003/2/23",
    "2003/2/24",
    "2003/2/25",
    "2003/2/26",
    "2003/2/27",
    "2003/2/28",
    "2003/3/1",
    "2003/3/2",
    "2003/3/3",
    "2003/3/4",
    "2003/3/5",
    "2003/3/6",
    "2003/3/7",
    "2003/3/8",
    "2003/3/9",
    "2003/3/10",
    "2003/3/11",
    "2003/3/12",
    "2003/3/13",
    "2003/3/14",
    "2003/3/15",
    "2003/3/16",
    "2003/3/17",
    "2003/3/18",
    "2003/3/19",
    "2003/3/20",
    "2003/3/21",
    "2003/3/22",
    "2003/3/23",
    "2003/3/24",
    "2003/3/25",
    "2003/3/26",
    "2003/3/27",
    "2003/3/28",
    "2003/3/29",
    "2003/3/30",
    "2003/3/31",
    "2003/4/1",
    "2003/4/2",
    "2003/4/3",
    "2003/4/4",
    "2003/4/5",
    "2003/4/6",
    "2003/4/7",
    "2003/4/8",
    "2003/4/9",
    "2003/4/10",
    "2003/4/11",
    "2003/4/12",
    "2003/4/13",
    "2003/4/14",
    "2003/4/15",
    "2003/4/16",
    "2003/4/17",
    "2003/4/18",
    "2003/4/19",
    "2003/4/20",
    "2003/4/21",
    "2003/4/22",
    "2003/4/23",
    "2003/4/24",
    "2003/4/25",
    "2003/4/26",
    "2003/4/27",
    "2003/4/28",
    "2003/4/29",
    "2003/4/30",
    "2003/5/1",
    "2003/5/2",
    "2003/5/3",
    "2003/5/4",
    "2003/5/5",
    "2003/5/6",
    "2003/5/7",
    "2003/5/8",
    "2003/5/9",
    "2003/5/10",
    "2003/5/11",
    "2003/5/12",
    "2003/5/13",
    "2003/5/14",
    "2003/5/15",
    "2003/5/16",
    "2003/5/17",
    "2003/5/18",
    "2003/5/19",
    "2003/5/20",
    "2003/5/21",
    "2003/5/22",
    "2003/5/23",
    "2003/5/24",
    "2003/5/25",
    "2003/5/26",
    "2003/5/27",
    "2003/5/28",
    "2003/5/29",
    "2003/5/30",
    "2003/5/31",
    "2003/6/1",
    "2003/6/2",
    "2003/6/3",
    "2003/6/4",
    "2003/6/5",
    "2003/6/6",
    "2003/6/7",
    "2003/6/8",
    "2003/6/9",
    "2003/6/10",
    "2003/6/11",
    "2003/6/12",
    "2003/6/13",
    "2003/6/14",
    "2003/6/15",
    "2003/6/16",
    "2003/6/17",
    "2003/6/18",
    "2003/6/19",
    "2003/6/20",
    "2003/6/21",
    "2003/6/22",
    "2003/6/23",
    "2003/6/24",
    "2003/6/25",
    "2003/6/26",
    "2003/6/27",
    "2003/6/28",
    "2003/6/29",
    "2003/6/30",
    "2003/7/1",
    "2003/7/2",
    "2003/7/3",
    "2003/7/4",
    "2003/7/5",
    "2003/7/6",
    "2003/7/7",
    "2003/7/8",
    "2003/7/9",
    "2003/7/10",
    "2003/7/11",
    "2003/7/12",
    "2003/7/13",
    "2003/7/14",
    "2003/7/15",
    "2003/7/16",
    "2003/7/17",
    "2003/7/18",
    "2003/7/19",
    "2003/7/20",
    "2003/7/21",
    "2003/7/22",
    "2003/7/23",
    "2003/7/24",
    "2003/7/25",
    "2003/7/26",
    "2003/7/27",
    "2003/7/28",
    "2003/7/29",
    "2003/7/30",
    "2003/7/31",
    "2003/8/1",
    "2003/8/2",
    "2003/8/3",
    "2003/8/4",
    "2003/8/5",
    "2003/8/6",
    "2003/8/7",
    "2003/8/8",
    "2003/8/9",
    "2003/8/10",
    "2003/8/11",
    "2003/8/12",
    "2003/8/13",
    "2003/8/14",
    "2003/8/15",
    "2003/8/16",
    "2003/8/17",
    "2003/8/18",
    "2003/8/19",
    "2003/8/20",
    "2003/8/21",
    "2003/8/22",
    "2003/8/23",
    "2003/8/24",
    "2003/8/25",
    "2003/8/26",
    "2003/8/27",
    "2003/8/28",
    "2003/8/29",
    "2003/8/30",
    "2003/8/31",
    "2003/9/1",
    "2003/9/2",
    "2003/9/3",
    "2003/9/4",
    "2003/9/5",
    "2003/9/6",
    "2003/9/7",
    "2003/9/8",
    "2003/9/9",
    "2003/9/10",
    "2003/9/11",
    "2003/9/12",
    "2003/9/13",
    "2003/9/14",
    "2003/9/15",
    "2003/9/16",
    "2003/9/17",
    "2003/9/18",
    "2003/9/19",
    "2003/9/20",
    "2003/9/21",
    "2003/9/22",
    "2003/9/23",
    "2003/9/24",
    "2003/9/25",
    "2003/9/26",
    "2003/9/27",
    "2003/9/28",
    "2003/9/29",
    "2003/9/30",
    "2003/10/1",
    "2003/10/2",
    "2003/10/3",
    "2003/10/4",
    "2003/10/5",
    "2003/10/6",
    "2003/10/7",
    "2003/10/8",
    "2003/10/9",
    "2003/10/10",
    "2003/10/11",
    "2003/10/12",
    "2003/10/13",
    "2003/10/14",
    "2003/10/15",
    "2003/10/16",
    "2003/10/17",
    "2003/10/18",
    "2003/10/19",
    "2003/10/20",
    "2003/10/21",
    "2003/10/22",
    "2003/10/23",
    "2003/10/24",
    "2003/10/25",
    "2003/10/26",
    "2003/10/27",
    "2003/10/28",
    "2003/10/29",
    "2003/10/30",
    "2003/10/31",
    "2003/11/1",
    "2003/11/2",
    "2003/11/3",
    "2003/11/4",
    "2003/11/5",
    "2003/11/6",
    "2003/11/7",
    "2003/11/8",
    "2003/11/9",
    "2003/11/10",
    "2003/11/11",
    "2003/11/12",
    "2003/11/13",
    "2003/11/14",
    "2003/11/15",
    "2003/11/16",
    "2003/11/17",
    "2003/11/18",
    "2003/11/19",
    "2003/11/20",
    "2003/11/21",
    "2003/11/22",
    "2003/11/23",
    "2003/11/24",
    "2003/11/25",
    "2003/11/26",
    "2003/11/27",
    "2003/11/28",
    "2003/11/29",
    "2003/11/30",
    "2003/12/1",
    "2003/12/2",
    "2003/12/3",
    "2003/12/4",
    "2003/12/5",
    "2003/12/6",
    "2003/12/7",
    "2003/12/8",
    "2003/12/9",
    "2003/12/10",
    "2003/12/11",
    "2003/12/12",
    "2003/12/13",
    "2003/12/14",
    "2003/12/15",
    "2003/12/16",
    "2003/12/17",
    "2003/12/18",
    "2003/12/19",
    "2003/12/20",
    "2003/12/21",
    "2003/12/22",
    "2003/12/23",
    "2003/12/24",
    "2003/12/25",
    "2003/12/26",
    "2003/12/27",
    "2003/12/28",
    "2003/12/29",
    "2003/12/30",
    "2003/12/31",
    "2004/1/1",
    "2004/1/2",
    "2004/1/3",
    "2004/1/4",
    "2004/1/5",
    "2004/1/6",
    "2004/1/7",
    "2004/1/8",
    "2004/1/9",
    "2004/1/10",
    "2004/1/11",
    "2004/1/12",
    "2004/1/13",
    "2004/1/14",
    "2004/1/15",
    "2004/1/16",
    "2004/1/17",
    "2004/1/18",
    "2004/1/19",
    "2004/1/20",
    "2004/1/21",
    "2004/1/22",
    "2004/1/23",
    "2004/1/24",
    "2004/1/25",
    "2004/1/26",
    "2004/1/27",
    "2004/1/28",
    "2004/1/29",
    "2004/1/30",
    "2004/1/31",
    "2004/2/1",
    "2004/2/2",
    "2004/2/3",
    "2004/2/4",
    "2004/2/5",
    "2004/2/6",
    "2004/2/7",
    "2004/2/8",
    "2004/2/9",
    "2004/2/10",
    "2004/2/11",
    "2004/2/12",
    "2004/2/13",
    "2004/2/14",
    "2004/2/15",
    "2004/2/16",
    "2004/2/17",
    "2004/2/18",
    "2004/2/19",
    "2004/2/20",
    "2004/2/21",
    "2004/2/22",
    "2004/2/23",
    "2004/2/24",
    "2004/2/25",
    "2004/2/26",
    "2004/2/27",
    "2004/2/28",
    "2004/2/29",
    "2004/3/1",
    "2004/3/2",
    "2004/3/3",
    "2004/3/4",
    "2004/3/5",
    "2004/3/6",
    "2004/3/7",
    "2004/3/8",
    "2004/3/9",
    "2004/3/10",
    "2004/3/11",
    "2004/3/12",
    "2004/3/13",
    "2004/3/14",
    "2004/3/15",
    "2004/3/16",
    "2004/3/17",
    "2004/3/18",
    "2004/3/19",
    "2004/3/20",
    "2004/3/21",
    "2004/3/22",
    "2004/3/23",
    "2004/3/24",
    "2004/3/25",
    "2004/3/26",
    "2004/3/27",
    "2004/3/28",
    "2004/3/29",
    "2004/3/30",
    "2004/3/31",
    "2004/4/1",
    "2004/4/2",
    "2004/4/3",
    "2004/4/4",
    "2004/4/5",
    "2004/4/6",
    "2004/4/7",
    "2004/4/8",
    "2004/4/9",
    "2004/4/10",
    "2004/4/11",
    "2004/4/12",
    "2004/4/13",
    "2004/4/14",
    "2004/4/15",
    "2004/4/16",
    "2004/4/17",
    "2004/4/18",
    "2004/4/19",
    "2004/4/20",
    "2004/4/21",
    "2004/4/22",
    "2004/4/23",
    "2004/4/24",
    "2004/4/25",
    "2004/4/26",
    "2004/4/27",
    "2004/4/28",
    "2004/4/29",
    "2004/4/30",
    "2004/5/1",
    "2004/5/2",
    "2004/5/3",
    "2004/5/4",
    "2004/5/5",
    "2004/5/6",
    "2004/5/7",
    "2004/5/8",
    "2004/5/9",
    "2004/5/10",
    "2004/5/11",
    "2004/5/12",
    "2004/5/13",
    "2004/5/14",
    "2004/5/15",
    "2004/5/16",
    "2004/5/17",
    "2004/5/18",
    "2004/5/19",
    "2004/5/20",
    "2004/5/21",
    "2004/5/22",
    "2004/5/23",
    "2004/5/24",
    "2004/5/25",
    "2004/5/26",
    "2004/5/27",
    "2004/5/28",
    "2004/5/29",
    "2004/5/30",
    "2004/5/31",
    "2004/6/1",
    "2004/6/2",
    "2004/6/3",
    "2004/6/4",
    "2004/6/5",
    "2004/6/6",
    "2004/6/7",
    "2004/6/8",
    "2004/6/9",
    "2004/6/10",
    "2004/6/11",
    "2004/6/12",
    "2004/6/13",
    "2004/6/14",
    "2004/6/15",
    "2004/6/16",
    "2004/6/17",
    "2004/6/18",
    "2004/6/19",
    "2004/6/20",
    "2004/6/21",
    "2004/6/22",
    "2004/6/23",
    "2004/6/24",
    "2004/6/25",
    "2004/6/26",
    "2004/6/27",
    "2004/6/28",
    "2004/6/29",
    "2004/6/30",
    "2004/7/1",
    "2004/7/2",
    "2004/7/3",
    "2004/7/4",
    "2004/7/5",
    "2004/7/6",
    "2004/7/7",
    "2004/7/8",
    "2004/7/9",
    "2004/7/10",
    "2004/7/11",
    "2004/7/12",
    "2004/7/13",
    "2004/7/14",
    "2004/7/15",
    "2004/7/16",
    "2004/7/17",
    "2004/7/18",
    "2004/7/19",
    "2004/7/20",
    "2004/7/21",
    "2004/7/22",
    "2004/7/23",
    "2004/7/24",
    "2004/7/25",
    "2004/7/26",
    "2004/7/27",
    "2004/7/28",
    "2004/7/29",
    "2004/7/30",
    "2004/7/31",
    "2004/8/1",
    "2004/8/2",
    "2004/8/3",
    "2004/8/4",
    "2004/8/5",
    "2004/8/6",
    "2004/8/7",
    "2004/8/8",
    "2004/8/9",
    "2004/8/10",
    "2004/8/11",
    "2004/8/12",
    "2004/8/13",
    "2004/8/14",
    "2004/8/15",
    "2004/8/16",
    "2004/8/17",
    "2004/8/18",
    "2004/8/19",
    "2004/8/20",
    "2004/8/21",
    "2004/8/22",
    "2004/8/23",
    "2004/8/24",
    "2004/8/25",
    "2004/8/26",
    "2004/8/27",
    "2004/8/28",
    "2004/8/29",
    "2004/8/30",
    "2004/8/31",
    "2004/9/1",
    "2004/9/2",
    "2004/9/3",
    "2004/9/4",
    "2004/9/5",
    "2004/9/6",
    "2004/9/7",
    "2004/9/8",
    "2004/9/9",
    "2004/9/10",
    "2004/9/11",
    "2004/9/12",
    "2004/9/13",
    "2004/9/14",
    "2004/9/15",
    "2004/9/16",
    "2004/9/17",
    "2004/9/18",
    "2004/9/19",
    "2004/9/20",
    "2004/9/21",
    "2004/9/22",
    "2004/9/23",
    "2004/9/24",
    "2004/9/25",
    "2004/9/26",
    "2004/9/27",
    "2004/9/28",
    "2004/9/29",
    "2004/9/30",
    "2004/10/1",
    "2004/10/2",
    "2004/10/3",
    "2004/10/4",
    "2004/10/5",
    "2004/10/6",
    "2004/10/7",
    "2004/10/8",
    "2004/10/9",
    "2004/10/10",
    "2004/10/11",
    "2004/10/12",
    "2004/10/13",
    "2004/10/14",
    "2004/10/15",
    "2004/10/16",
    "2004/10/17",
    "2004/10/18",
    "2004/10/19",
    "2004/10/20",
    "2004/10/21",
    "2004/10/22",
    "2004/10/23",
    "2004/10/24",
    "2004/10/25",
    "2004/10/26",
    "2004/10/27",
    "2004/10/28",
    "2004/10/29",
    "2004/10/30",
    "2004/10/31",
    "2004/11/1",
    "2004/11/2",
    "2004/11/3",
    "2004/11/4",
    "2004/11/5",
    "2004/11/6",
    "2004/11/7",
    "2004/11/8",
    "2004/11/9",
    "2004/11/10",
    "2004/11/11",
    "2004/11/12",
    "2004/11/13",
    "2004/11/14",
    "2004/11/15",
    "2004/11/16",
    "2004/11/17",
    "2004/11/18",
    "2004/11/19",
    "2004/11/20",
    "2004/11/21",
    "2004/11/22",
    "2004/11/23",
    "2004/11/24",
    "2004/11/25",
    "2004/11/26",
    "2004/11/27",
    "2004/11/28",
    "2004/11/29",
    "2004/11/30",
    "2004/12/1",
    "2004/12/2",
    "2004/12/3",
    "2004/12/4",
    "2004/12/5",
    "2004/12/6",
    "2004/12/7",
    "2004/12/8",
    "2004/12/9",
    "2004/12/10",
    "2004/12/11",
    "2004/12/12",
    "2004/12/13",
    "2004/12/14",
    "2004/12/15",
    "2004/12/16",
    "2004/12/17",
    "2004/12/18",
    "2004/12/19",
    "2004/12/20",
    "2004/12/21",
    "2004/12/22",
    "2004/12/23",
    "2004/12/24",
    "2004/12/25",
    "2004/12/26",
    "2004/12/27",
    "2004/12/28",
    "2004/12/29",
    "2004/12/30",
    "2004/12/31",
    "2005/1/1",
    "2005/1/2",
    "2005/1/3",
    "2005/1/4",
    "2005/1/5",
    "2005/1/6",
    "2005/1/7",
    "2005/1/8",
    "2005/1/9",
    "2005/1/10",
    "2005/1/11",
    "2005/1/12",
    "2005/1/13",
    "2005/1/14",
    "2005/1/15",
    "2005/1/16",
    "2005/1/17",
    "2005/1/18",
    "2005/1/19",
    "2005/1/20",
    "2005/1/21",
    "2005/1/22",
    "2005/1/23",
    "2005/1/24",
    "2005/1/25",
    "2005/1/26",
    "2005/1/27",
    "2005/1/28",
    "2005/1/29",
    "2005/1/30",
    "2005/1/31",
    "2005/2/1",
    "2005/2/2",
    "2005/2/3",
    "2005/2/4",
    "2005/2/5",
    "2005/2/6",
    "2005/2/7",
    "2005/2/8",
    "2005/2/9",
    "2005/2/10",
    "2005/2/11",
    "2005/2/12",
    "2005/2/13",
    "2005/2/14",
    "2005/2/15",
    "2005/2/16",
    "2005/2/17",
    "2005/2/18",
    "2005/2/19",
    "2005/2/20",
    "2005/2/21",
    "2005/2/22",
    "2005/2/23",
    "2005/2/24",
    "2005/2/25",
    "2005/2/26",
    "2005/2/27",
    "2005/2/28",
    "2005/3/1",
    "2005/3/2",
    "2005/3/3",
    "2005/3/4",
    "2005/3/5",
    "2005/3/6",
    "2005/3/7",
    "2005/3/8",
    "2005/3/9",
    "2005/3/10",
    "2005/3/11",
    "2005/3/12",
    "2005/3/13",
    "2005/3/14",
    "2005/3/15",
    "2005/3/16",
    "2005/3/17",
    "2005/3/18",
    "2005/3/19",
    "2005/3/20",
    "2005/3/21",
    "2005/3/22",
    "2005/3/23",
    "2005/3/24",
    "2005/3/25",
    "2005/3/26",
    "2005/3/27",
    "2005/3/28",
    "2005/3/29",
    "2005/3/30",
    "2005/3/31",
    "2005/4/1",
    "2005/4/2",
    "2005/4/3",
    "2005/4/4",
    "2005/4/5",
    "2005/4/6",
    "2005/4/7",
    "2005/4/8",
    "2005/4/9",
    "2005/4/10",
    "2005/4/11",
    "2005/4/12",
    "2005/4/13",
    "2005/4/14",
    "2005/4/15",
    "2005/4/16",
    "2005/4/17",
    "2005/4/18",
    "2005/4/19",
    "2005/4/20",
    "2005/4/21",
    "2005/4/22",
    "2005/4/23",
    "2005/4/24",
    "2005/4/25",
    "2005/4/26",
    "2005/4/27",
    "2005/4/28",
    "2005/4/29",
    "2005/4/30",
    "2005/5/1",
    "2005/5/2",
    "2005/5/3",
    "2005/5/4",
    "2005/5/5",
    "2005/5/6",
    "2005/5/7",
    "2005/5/8",
    "2005/5/9",
    "2005/5/10",
    "2005/5/11",
    "2005/5/12",
    "2005/5/13",
    "2005/5/14",
    "2005/5/15",
    "2005/5/16",
    "2005/5/17",
    "2005/5/18",
    "2005/5/19",
    "2005/5/20",
    "2005/5/21",
    "2005/5/22",
    "2005/5/23",
    "2005/5/24",
    "2005/5/25",
    "2005/5/26",
    "2005/5/27",
    "2005/5/28",
    "2005/5/29",
    "2005/5/30",
    "2005/5/31",
    "2005/6/1",
    "2005/6/2",
    "2005/6/3",
    "2005/6/4",
    "2005/6/5",
    "2005/6/6",
    "2005/6/7",
    "2005/6/8",
    "2005/6/9",
    "2005/6/10",
    "2005/6/11",
    "2005/6/12",
    "2005/6/13",
    "2005/6/14",
    "2005/6/15",
    "2005/6/16",
    "2005/6/17",
    "2005/6/18",
    "2005/6/19",
    "2005/6/20",
    "2005/6/21",
    "2005/6/22",
    "2005/6/23",
    "2005/6/24",
    "2005/6/25",
    "2005/6/26",
    "2005/6/27",
    "2005/6/28",
    "2005/6/29",
    "2005/6/30",
    "2005/7/1",
    "2005/7/2",
    "2005/7/3",
    "2005/7/4",
    "2005/7/5",
    "2005/7/6",
    "2005/7/7",
    "2005/7/8",
    "2005/7/9",
    "2005/7/10",
    "2005/7/11",
    "2005/7/12",
    "2005/7/13",
    "2005/7/14",
    "2005/7/15",
    "2005/7/16",
    "2005/7/17",
    "2005/7/18",
    "2005/7/19",
    "2005/7/20",
    "2005/7/21",
    "2005/7/22",
    "2005/7/23",
    "2005/7/24",
    "2005/7/25",
    "2005/7/26",
    "2005/7/27",
    "2005/7/28",
    "2005/7/29",
    "2005/7/30",
    "2005/7/31",
    "2005/8/1",
    "2005/8/2",
    "2005/8/3",
    "2005/8/4",
    "2005/8/5",
    "2005/8/6",
    "2005/8/7",
    "2005/8/8",
    "2005/8/9",
    "2005/8/10",
    "2005/8/11",
    "2005/8/12",
    "2005/8/13",
    "2005/8/14",
    "2005/8/15",
    "2005/8/16",
    "2005/8/17",
    "2005/8/18",
    "2005/8/19",
    "2005/8/20",
    "2005/8/21",
    "2005/8/22",
    "2005/8/23",
    "2005/8/24",
    "2005/8/25",
    "2005/8/26",
    "2005/8/27",
    "2005/8/28",
    "2005/8/29",
    "2005/8/30",
    "2005/8/31",
    "2005/9/1",
    "2005/9/2",
    "2005/9/3",
    "2005/9/4",
    "2005/9/5",
    "2005/9/6",
    "2005/9/7",
    "2005/9/8",
    "2005/9/9",
    "2005/9/10",
    "2005/9/11",
    "2005/9/12",
    "2005/9/13",
    "2005/9/14",
    "2005/9/15",
    "2005/9/16",
    "2005/9/17",
    "2005/9/18",
    "2005/9/19",
    "2005/9/20",
    "2005/9/21",
    "2005/9/22",
    "2005/9/23",
    "2005/9/24",
    "2005/9/25",
    "2005/9/26",
    "2005/9/27",
    "2005/9/28",
    "2005/9/29",
    "2005/9/30",
    "2005/10/1",
    "2005/10/2",
    "2005/10/3",
    "2005/10/4",
    "2005/10/5",
    "2005/10/6",
    "2005/10/7",
    "2005/10/8",
    "2005/10/9",
    "2005/10/10",
    "2005/10/11",
    "2005/10/12",
    "2005/10/13",
    "2005/10/14",
    "2005/10/15",
    "2005/10/16",
    "2005/10/17",
    "2005/10/18",
    "2005/10/19",
    "2005/10/20",
    "2005/10/21",
    "2005/10/22",
    "2005/10/23",
    "2005/10/24",
    "2005/10/25",
    "2005/10/26",
    "2005/10/27",
    "2005/10/28",
    "2005/10/29",
    "2005/10/30",
    "2005/10/31",
    "2005/11/1",
    "2005/11/2",
    "2005/11/3",
    "2005/11/4",
    "2005/11/5",
    "2005/11/6",
    "2005/11/7",
    "2005/11/8",
    "2005/11/9",
    "2005/11/10",
    "2005/11/11",
    "2005/11/12",
    "2005/11/13",
    "2005/11/14",
    "2005/11/15",
    "2005/11/16",
    "2005/11/17",
    "2005/11/18",
    "2005/11/19",
    "2005/11/20",
    "2005/11/21",
    "2005/11/22",
    "2005/11/23",
    "2005/11/24",
    "2005/11/25",
    "2005/11/26",
    "2005/11/27",
    "2005/11/28",
    "2005/11/29",
    "2005/11/30",
    "2005/12/1",
    "2005/12/2",
    "2005/12/3",
    "2005/12/4",
    "2005/12/5",
    "2005/12/6",
    "2005/12/7",
    "2005/12/8",
    "2005/12/9",
    "2005/12/10",
    "2005/12/11",
    "2005/12/12",
    "2005/12/13",
    "2005/12/14",
    "2005/12/15",
    "2005/12/16",
    "2005/12/17",
    "2005/12/18",
    "2005/12/19",
    "2005/12/20",
    "2005/12/21",
    "2005/12/22",
    "2005/12/23",
    "2005/12/24",
    "2005/12/25",
    "2005/12/26",
    "2005/12/27",
    "2005/12/28",
    "2005/12/29",
    "2005/12/30",
    "2005/12/31",
    "2006/1/1",
    "2006/1/2",
    "2006/1/3",
    "2006/1/4",
    "2006/1/5",
    "2006/1/6",
    "2006/1/7",
    "2006/1/8",
    "2006/1/9",
    "2006/1/10",
    "2006/1/11",
    "2006/1/12",
    "2006/1/13",
    "2006/1/14",
    "2006/1/15",
    "2006/1/16",
    "2006/1/17",
    "2006/1/18",
    "2006/1/19",
    "2006/1/20",
    "2006/1/21",
    "2006/1/22",
    "2006/1/23",
    "2006/1/24",
    "2006/1/25",
    "2006/1/26",
    "2006/1/27",
    "2006/1/28",
    "2006/1/29",
    "2006/1/30",
    "2006/1/31",
    "2006/2/1",
    "2006/2/2",
    "2006/2/3",
    "2006/2/4",
    "2006/2/5",
    "2006/2/6",
    "2006/2/7",
    "2006/2/8",
    "2006/2/9",
    "2006/2/10",
    "2006/2/11",
    "2006/2/12",
    "2006/2/13",
    "2006/2/14",
    "2006/2/15",
    "2006/2/16",
    "2006/2/17",
    "2006/2/18",
    "2006/2/19",
    "2006/2/20",
    "2006/2/21",
    "2006/2/22",
    "2006/2/23",
    "2006/2/24",
    "2006/2/25",
    "2006/2/26",
    "2006/2/27",
    "2006/2/28",
    "2006/3/1",
    "2006/3/2",
    "2006/3/3",
    "2006/3/4",
    "2006/3/5",
    "2006/3/6",
    "2006/3/7",
    "2006/3/8",
    "2006/3/9",
    "2006/3/10",
    "2006/3/11",
    "2006/3/12",
    "2006/3/13",
    "2006/3/14",
    "2006/3/15",
    "2006/3/16",
    "2006/3/17",
    "2006/3/18",
    "2006/3/19",
    "2006/3/20",
    "2006/3/21",
    "2006/3/22",
    "2006/3/23",
    "2006/3/24",
    "2006/3/25",
    "2006/3/26",
    "2006/3/27",
    "2006/3/28",
    "2006/3/29",
    "2006/3/30",
    "2006/3/31",
    "2006/4/1",
    "2006/4/2",
    "2006/4/3",
    "2006/4/4",
    "2006/4/5",
    "2006/4/6",
    "2006/4/7",
    "2006/4/8",
    "2006/4/9",
    "2006/4/10",
    "2006/4/11",
    "2006/4/12",
    "2006/4/13",
    "2006/4/14",
    "2006/4/15",
    "2006/4/16",
    "2006/4/17",
    "2006/4/18",
    "2006/4/19",
    "2006/4/20",
    "2006/4/21",
    "2006/4/22",
    "2006/4/23",
    "2006/4/24",
    "2006/4/25",
    "2006/4/26",
    "2006/4/27",
    "2006/4/28",
    "2006/4/29",
    "2006/4/30",
    "2006/5/1",
    "2006/5/2",
    "2006/5/3",
    "2006/5/4",
    "2006/5/5",
    "2006/5/6",
    "2006/5/7",
    "2006/5/8",
    "2006/5/9",
    "2006/5/10",
    "2006/5/11",
    "2006/5/12",
    "2006/5/13",
    "2006/5/14",
    "2006/5/15",
    "2006/5/16",
    "2006/5/17",
    "2006/5/18",
    "2006/5/19",
    "2006/5/20",
    "2006/5/21",
    "2006/5/22",
    "2006/5/23",
    "2006/5/24",
    "2006/5/25",
    "2006/5/26",
    "2006/5/27",
    "2006/5/28",
    "2006/5/29",
    "2006/5/30",
    "2006/5/31",
    "2006/6/1",
    "2006/6/2",
    "2006/6/3",
    "2006/6/4",
    "2006/6/5",
    "2006/6/6",
    "2006/6/7",
    "2006/6/8",
    "2006/6/9",
    "2006/6/10",
    "2006/6/11",
    "2006/6/12",
    "2006/6/13",
    "2006/6/14",
    "2006/6/15",
    "2006/6/16",
    "2006/6/17",
    "2006/6/18",
    "2006/6/19",
    "2006/6/20",
    "2006/6/21",
    "2006/6/22",
    "2006/6/23",
    "2006/6/24",
    "2006/6/25",
    "2006/6/26",
    "2006/6/27",
    "2006/6/28",
    "2006/6/29",
    "2006/6/30",
    "2006/7/1",
    "2006/7/2",
    "2006/7/3",
    "2006/7/4",
    "2006/7/5",
    "2006/7/6",
    "2006/7/7",
    "2006/7/8",
    "2006/7/9",
    "2006/7/10",
    "2006/7/11",
    "2006/7/12",
    "2006/7/13",
    "2006/7/14",
    "2006/7/15",
    "2006/7/16",
    "2006/7/17",
    "2006/7/18",
    "2006/7/19",
    "2006/7/20",
    "2006/7/21",
    "2006/7/22",
    "2006/7/23",
    "2006/7/24",
    "2006/7/25",
    "2006/7/26",
    "2006/7/27",
    "2006/7/28",
    "2006/7/29",
    "2006/7/30",
    "2006/7/31",
    "2006/8/1",
    "2006/8/2",
    "2006/8/3",
    "2006/8/4",
    "2006/8/5",
    "2006/8/6",
    "2006/8/7",
    "2006/8/8",
    "2006/8/9",
    "2006/8/10",
    "2006/8/11",
    "2006/8/12",
    "2006/8/13",
    "2006/8/14",
    "2006/8/15",
    "2006/8/16",
    "2006/8/17",
    "2006/8/18",
    "2006/8/19",
    "2006/8/20",
    "2006/8/21",
    "2006/8/22",
    "2006/8/23",
    "2006/8/24",
    "2006/8/25",
    "2006/8/26",
    "2006/8/27",
    "2006/8/28",
    "2006/8/29",
    "2006/8/30",
    "2006/8/31",
    "2006/9/1",
    "2006/9/2",
    "2006/9/3",
    "2006/9/4",
    "2006/9/5",
    "2006/9/6",
    "2006/9/7",
    "2006/9/8",
    "2006/9/9",
    "2006/9/10",
    "2006/9/11",
    "2006/9/12",
    "2006/9/13",
    "2006/9/14",
    "2006/9/15",
    "2006/9/16",
    "2006/9/17",
    "2006/9/18",
    "2006/9/19",
    "2006/9/20",
    "2006/9/21",
    "2006/9/22",
    "2006/9/23",
    "2006/9/24",
    "2006/9/25",
    "2006/9/26",
    "2006/9/27",
    "2006/9/28",
    "2006/9/29",
    "2006/9/30",
    "2006/10/1",
    "2006/10/2",
    "2006/10/3",
    "2006/10/4",
    "2006/10/5",
    "2006/10/6",
    "2006/10/7",
    "2006/10/8",
    "2006/10/9",
    "2006/10/10",
    "2006/10/11",
    "2006/10/12",
    "2006/10/13",
    "2006/10/14",
    "2006/10/15",
    "2006/10/16",
    "2006/10/17",
    "2006/10/18",
    "2006/10/19",
    "2006/10/20",
    "2006/10/21",
    "2006/10/22",
    "2006/10/23",
    "2006/10/24",
    "2006/10/25",
    "2006/10/26",
    "2006/10/27",
    "2006/10/28",
    "2006/10/29",
    "2006/10/30",
    "2006/10/31",
    "2006/11/1",
    "2006/11/2",
    "2006/11/3",
    "2006/11/4",
    "2006/11/5",
    "2006/11/6",
    "2006/11/7",
    "2006/11/8",
    "2006/11/9",
    "2006/11/10",
    "2006/11/11",
    "2006/11/12",
    "2006/11/13",
    "2006/11/14",
    "2006/11/15",
    "2006/11/16",
    "2006/11/17",
    "2006/11/18",
    "2006/11/19",
    "2006/11/20",
    "2006/11/21",
    "2006/11/22",
    "2006/11/23",
    "2006/11/24",
    "2006/11/25",
    "2006/11/26",
    "2006/11/27",
    "2006/11/28",
    "2006/11/29",
    "2006/11/30",
    "2006/12/1",
    "2006/12/2",
    "2006/12/3",
    "2006/12/4",
    "2006/12/5",
    "2006/12/6",
    "2006/12/7",
    "2006/12/8",
    "2006/12/9",
    "2006/12/10",
    "2006/12/11",
    "2006/12/12",
    "2006/12/13",
    "2006/12/14",
    "2006/12/15",
    "2006/12/16",
    "2006/12/17",
    "2006/12/18",
    "2006/12/19",
    "2006/12/20",
    "2006/12/21",
    "2006/12/22",
    "2006/12/23",
    "2006/12/24",
    "2006/12/25",
    "2006/12/26",
    "2006/12/27",
    "2006/12/28",
    "2006/12/29",
    "2006/12/30",
    "2006/12/31",
    "2007/1/1",
    "2007/1/2",
    "2007/1/3",
    "2007/1/4",
    "2007/1/5",
    "2007/1/6",
    "2007/1/7",
    "2007/1/8",
    "2007/1/9",
    "2007/1/10",
    "2007/1/11",
    "2007/1/12",
    "2007/1/13",
    "2007/1/14",
    "2007/1/15",
    "2007/1/16",
    "2007/1/17",
    "2007/1/18",
    "2007/1/19",
    "2007/1/20",
    "2007/1/21",
    "2007/1/22",
    "2007/1/23",
    "2007/1/24",
    "2007/1/25",
    "2007/1/26",
    "2007/1/27",
    "2007/1/28",
    "2007/1/29",
    "2007/1/30",
    "2007/1/31",
    "2007/2/1",
    "2007/2/2",
    "2007/2/3",
    "2007/2/4",
    "2007/2/5",
    "2007/2/6",
    "2007/2/7",
    "2007/2/8",
    "2007/2/9",
    "2007/2/10",
    "2007/2/11",
    "2007/2/12",
    "2007/2/13",
    "2007/2/14",
    "2007/2/15",
    "2007/2/16",
    "2007/2/17",
    "2007/2/18",
    "2007/2/19",
    "2007/2/20",
    "2007/2/21",
    "2007/2/22",
    "2007/2/23",
    "2007/2/24",
    "2007/2/25",
    "2007/2/26",
    "2007/2/27",
    "2007/2/28",
    "2007/3/1",
    "2007/3/2",
    "2007/3/3",
    "2007/3/4",
    "2007/3/5",
    "2007/3/6",
    "2007/3/7",
    "2007/3/8",
    "2007/3/9",
    "2007/3/10",
    "2007/3/11",
    "2007/3/12",
    "2007/3/13",
    "2007/3/14",
    "2007/3/15",
    "2007/3/16",
    "2007/3/17",
    "2007/3/18",
    "2007/3/19",
    "2007/3/20",
    "2007/3/21",
    "2007/3/22",
    "2007/3/23",
    "2007/3/24",
    "2007/3/25",
    "2007/3/26",
    "2007/3/27",
    "2007/3/28",
    "2007/3/29",
    "2007/3/30",
    "2007/3/31",
    "2007/4/1",
    "2007/4/2",
    "2007/4/3",
    "2007/4/4",
    "2007/4/5",
    "2007/4/6",
    "2007/4/7",
    "2007/4/8",
    "2007/4/9",
    "2007/4/10",
    "2007/4/11",
    "2007/4/12",
    "2007/4/13",
    "2007/4/14",
    "2007/4/15",
    "2007/4/16",
    "2007/4/17",
    "2007/4/18",
    "2007/4/19",
    "2007/4/20",
    "2007/4/21",
    "2007/4/22",
    "2007/4/23",
    "2007/4/24",
    "2007/4/25",
    "2007/4/26",
    "2007/4/27",
    "2007/4/28",
    "2007/4/29",
    "2007/4/30",
    "2007/5/1",
    "2007/5/2",
    "2007/5/3",
    "2007/5/4",
    "2007/5/5",
    "2007/5/6",
    "2007/5/7",
    "2007/5/8",
    "2007/5/9",
    "2007/5/10",
    "2007/5/11",
    "2007/5/12",
    "2007/5/13",
    "2007/5/14",
    "2007/5/15",
    "2007/5/16",
    "2007/5/17",
    "2007/5/18",
    "2007/5/19",
    "2007/5/20",
    "2007/5/21",
    "2007/5/22",
    "2007/5/23",
    "2007/5/24",
    "2007/5/25",
    "2007/5/26",
    "2007/5/27",
    "2007/5/28",
    "2007/5/29",
    "2007/5/30",
    "2007/5/31",
    "2007/6/1",
    "2007/6/2",
    "2007/6/3",
    "2007/6/4",
    "2007/6/5",
    "2007/6/6",
    "2007/6/7",
    "2007/6/8",
    "2007/6/9",
    "2007/6/10",
    "2007/6/11",
    "2007/6/12",
    "2007/6/13",
    "2007/6/14",
    "2007/6/15",
    "2007/6/16",
    "2007/6/17",
    "2007/6/18",
    "2007/6/19",
    "2007/6/20",
    "2007/6/21",
    "2007/6/22",
    "2007/6/23",
    "2007/6/24",
    "2007/6/25",
    "2007/6/26",
    "2007/6/27",
    "2007/6/28",
    "2007/6/29",
    "2007/6/30",
    "2007/7/1",
    "2007/7/2",
    "2007/7/3",
    "2007/7/4",
    "2007/7/5",
    "2007/7/6",
    "2007/7/7",
    "2007/7/8",
    "2007/7/9",
    "2007/7/10",
    "2007/7/11",
    "2007/7/12",
    "2007/7/13",
    "2007/7/14",
    "2007/7/15",
    "2007/7/16",
    "2007/7/17",
    "2007/7/18",
    "2007/7/19",
    "2007/7/20",
    "2007/7/21",
    "2007/7/22",
    "2007/7/23",
    "2007/7/24",
    "2007/7/25",
    "2007/7/26",
    "2007/7/27",
    "2007/7/28",
    "2007/7/29",
    "2007/7/30",
    "2007/7/31",
    "2007/8/1",
    "2007/8/2",
    "2007/8/3",
    "2007/8/4",
    "2007/8/5",
    "2007/8/6",
    "2007/8/7",
    "2007/8/8",
    "2007/8/9",
    "2007/8/10",
    "2007/8/11",
    "2007/8/12",
    "2007/8/13",
    "2007/8/14",
    "2007/8/15",
    "2007/8/16",
    "2007/8/17",
    "2007/8/18",
    "2007/8/19",
    "2007/8/20",
    "2007/8/21",
    "2007/8/22",
    "2007/8/23",
    "2007/8/24",
    "2007/8/25",
    "2007/8/26",
    "2007/8/27",
    "2007/8/28",
    "2007/8/29",
    "2007/8/30",
    "2007/8/31",
    "2007/9/1",
    "2007/9/2",
    "2007/9/3",
    "2007/9/4",
    "2007/9/5",
    "2007/9/6",
    "2007/9/7",
    "2007/9/8",
    "2007/9/9",
    "2007/9/10",
    "2007/9/11",
    "2007/9/12",
    "2007/9/13",
    "2007/9/14",
    "2007/9/15",
    "2007/9/16",
    "2007/9/17",
    "2007/9/18",
    "2007/9/19",
    "2007/9/20",
    "2007/9/21",
    "2007/9/22",
    "2007/9/23",
    "2007/9/24",
    "2007/9/25",
    "2007/9/26",
    "2007/9/27",
    "2007/9/28",
    "2007/9/29",
    "2007/9/30",
    "2007/10/1",
    "2007/10/2",
    "2007/10/3",
    "2007/10/4",
    "2007/10/5",
    "2007/10/6",
    "2007/10/7",
    "2007/10/8",
    "2007/10/9",
    "2007/10/10",
    "2007/10/11",
    "2007/10/12",
    "2007/10/13",
    "2007/10/14",
    "2007/10/15",
    "2007/10/16",
    "2007/10/17",
    "2007/10/18",
    "2007/10/19",
    "2007/10/20",
    "2007/10/21",
    "2007/10/22",
    "2007/10/23",
    "2007/10/24",
    "2007/10/25",
    "2007/10/26",
    "2007/10/27",
    "2007/10/28",
    "2007/10/29",
    "2007/10/30",
    "2007/10/31",
    "2007/11/1",
    "2007/11/2",
    "2007/11/3",
    "2007/11/4",
    "2007/11/5",
    "2007/11/6",
    "2007/11/7",
    "2007/11/8",
    "2007/11/9",
    "2007/11/10",
    "2007/11/11",
    "2007/11/12",
    "2007/11/13",
    "2007/11/14",
    "2007/11/15",
    "2007/11/16",
    "2007/11/17",
    "2007/11/18",
    "2007/11/19",
    "2007/11/20",
    "2007/11/21",
    "2007/11/22",
    "2007/11/23",
    "2007/11/24",
    "2007/11/25",
    "2007/11/26",
    "2007/11/27",
    "2007/11/28",
    "2007/11/29",
    "2007/11/30",
    "2007/12/1",
    "2007/12/2",
    "2007/12/3",
    "2007/12/4",
    "2007/12/5",
    "2007/12/6",
    "2007/12/7",
    "2007/12/8",
    "2007/12/9",
    "2007/12/10",
    "2007/12/11",
    "2007/12/12",
    "2007/12/13",
    "2007/12/14",
    "2007/12/15",
    "2007/12/16",
    "2007/12/17",
    "2007/12/18",
    "2007/12/19",
    "2007/12/20",
    "2007/12/21",
    "2007/12/22",
    "2007/12/23",
    "2007/12/24",
    "2007/12/25",
    "2007/12/26",
    "2007/12/27",
    "2007/12/28",
    "2007/12/29",
    "2007/12/30",
    "2007/12/31",
    "2008/1/1",
    "2008/1/2",
    "2008/1/3",
    "2008/1/4",
    "2008/1/5",
    "2008/1/6",
    "2008/1/7",
    "2008/1/8",
    "2008/1/9",
    "2008/1/10",
    "2008/1/11",
    "2008/1/12",
    "2008/1/13",
    "2008/1/14",
    "2008/1/15",
    "2008/1/16",
    "2008/1/17",
    "2008/1/18",
    "2008/1/19",
    "2008/1/20",
    "2008/1/21",
    "2008/1/22",
    "2008/1/23",
    "2008/1/24",
    "2008/1/25",
    "2008/1/26",
    "2008/1/27",
    "2008/1/28",
    "2008/1/29",
    "2008/1/30",
    "2008/1/31",
    "2008/2/1",
    "2008/2/2",
    "2008/2/3",
    "2008/2/4",
    "2008/2/5",
    "2008/2/6",
    "2008/2/7",
    "2008/2/8",
    "2008/2/9",
    "2008/2/10",
    "2008/2/11",
    "2008/2/12",
    "2008/2/13",
    "2008/2/14",
    "2008/2/15",
    "2008/2/16",
    "2008/2/17",
    "2008/2/18",
    "2008/2/19",
    "2008/2/20",
    "2008/2/21",
    "2008/2/22",
    "2008/2/23",
    "2008/2/24",
    "2008/2/25",
    "2008/2/26",
    "2008/2/27",
    "2008/2/28",
    "2008/2/29",
    "2008/3/1",
    "2008/3/2",
    "2008/3/3",
    "2008/3/4",
    "2008/3/5",
    "2008/3/6",
    "2008/3/7",
    "2008/3/8",
    "2008/3/9",
    "2008/3/10",
    "2008/3/11",
    "2008/3/12",
    "2008/3/13",
    "2008/3/14",
    "2008/3/15",
    "2008/3/16",
    "2008/3/17",
    "2008/3/18",
    "2008/3/19",
    "2008/3/20",
    "2008/3/21",
    "2008/3/22",
    "2008/3/23",
    "2008/3/24",
    "2008/3/25",
    "2008/3/26",
    "2008/3/27",
    "2008/3/28",
    "2008/3/29",
    "2008/3/30",
    "2008/3/31",
    "2008/4/1",
    "2008/4/2",
    "2008/4/3",
    "2008/4/4",
    "2008/4/5",
    "2008/4/6",
    "2008/4/7",
    "2008/4/8",
    "2008/4/9",
    "2008/4/10",
    "2008/4/11",
    "2008/4/12",
    "2008/4/13",
    "2008/4/14",
    "2008/4/15",
    "2008/4/16",
    "2008/4/17",
    "2008/4/18",
    "2008/4/19",
    "2008/4/20",
    "2008/4/21",
    "2008/4/22",
    "2008/4/23",
    "2008/4/24",
    "2008/4/25",
    "2008/4/26",
    "2008/4/27",
    "2008/4/28",
    "2008/4/29",
    "2008/4/30",
    "2008/5/1",
    "2008/5/2",
    "2008/5/3",
    "2008/5/4",
    "2008/5/5",
    "2008/5/6",
    "2008/5/7",
    "2008/5/8",
    "2008/5/9",
    "2008/5/10",
    "2008/5/11",
    "2008/5/12",
    "2008/5/13",
    "2008/5/14",
    "2008/5/15",
    "2008/5/16",
    "2008/5/17",
    "2008/5/18",
    "2008/5/19",
    "2008/5/20",
    "2008/5/21",
    "2008/5/22",
    "2008/5/23",
    "2008/5/24",
    "2008/5/25",
    "2008/5/26",
    "2008/5/27",
    "2008/5/28",
    "2008/5/29",
    "2008/5/30",
    "2008/5/31",
    "2008/6/1",
    "2008/6/2",
    "2008/6/3",
    "2008/6/4",
    "2008/6/5",
    "2008/6/6",
    "2008/6/7",
    "2008/6/8",
    "2008/6/9",
    "2008/6/10",
    "2008/6/11",
    "2008/6/12",
    "2008/6/13",
    "2008/6/14",
    "2008/6/15",
    "2008/6/16",
    "2008/6/17",
    "2008/6/18",
    "2008/6/19",
    "2008/6/20",
    "2008/6/21",
    "2008/6/22",
    "2008/6/23",
    "2008/6/24",
    "2008/6/25",
    "2008/6/26",
    "2008/6/27",
    "2008/6/28",
    "2008/6/29",
    "2008/6/30",
    "2008/7/1",
    "2008/7/2",
    "2008/7/3",
    "2008/7/4",
    "2008/7/5",
    "2008/7/6",
    "2008/7/7",
    "2008/7/8",
    "2008/7/9",
    "2008/7/10",
    "2008/7/11",
    "2008/7/12",
    "2008/7/13",
    "2008/7/14",
    "2008/7/15",
    "2008/7/16",
    "2008/7/17",
    "2008/7/18",
    "2008/7/19",
    "2008/7/20",
    "2008/7/21",
    "2008/7/22",
    "2008/7/23",
    "2008/7/24",
    "2008/7/25",
    "2008/7/26",
    "2008/7/27",
    "2008/7/28",
    "2008/7/29",
    "2008/7/30",
    "2008/7/31",
    "2008/8/1",
    "2008/8/2",
    "2008/8/3",
    "2008/8/4",
    "2008/8/5",
    "2008/8/6",
    "2008/8/7",
    "2008/8/8",
    "2008/8/9",
    "2008/8/10",
    "2008/8/11",
    "2008/8/12",
    "2008/8/13",
    "2008/8/14",
    "2008/8/15",
    "2008/8/16",
    "2008/8/17",
    "2008/8/18",
    "2008/8/19",
    "2008/8/20",
    "2008/8/21",
    "2008/8/22",
    "2008/8/23",
    "2008/8/24",
    "2008/8/25",
    "2008/8/26",
    "2008/8/27",
    "2008/8/28",
    "2008/8/29",
    "2008/8/30",
    "2008/8/31",
    "2008/9/1",
    "2008/9/2",
    "2008/9/3",
    "2008/9/4",
    "2008/9/5",
    "2008/9/6",
    "2008/9/7",
    "2008/9/8",
    "2008/9/9",
    "2008/9/10",
    "2008/9/11",
    "2008/9/12",
    "2008/9/13",
    "2008/9/14",
    "2008/9/15",
    "2008/9/16",
    "2008/9/17",
    "2008/9/18",
    "2008/9/19",
    "2008/9/20",
    "2008/9/21",
    "2008/9/22",
    "2008/9/23",
    "2008/9/24",
    "2008/9/25",
    "2008/9/26",
    "2008/9/27",
    "2008/9/28",
    "2008/9/29",
    "2008/9/30",
    "2008/10/1",
    "2008/10/2",
    "2008/10/3",
    "2008/10/4",
    "2008/10/5",
    "2008/10/6",
    "2008/10/7",
    "2008/10/8",
    "2008/10/9",
    "2008/10/10",
    "2008/10/11",
    "2008/10/12",
    "2008/10/13",
    "2008/10/14",
    "2008/10/15",
    "2008/10/16",
    "2008/10/17",
    "2008/10/18",
    "2008/10/19",
    "2008/10/20",
    "2008/10/21",
    "2008/10/22",
    "2008/10/23",
    "2008/10/24",
    "2008/10/25",
    "2008/10/26",
    "2008/10/27",
    "2008/10/28",
    "2008/10/29",
    "2008/10/30",
    "2008/10/31",
    "2008/11/1",
    "2008/11/2",
    "2008/11/3",
    "2008/11/4",
    "2008/11/5",
    "2008/11/6",
    "2008/11/7",
    "2008/11/8",
    "2008/11/9",
    "2008/11/10",
    "2008/11/11",
    "2008/11/12",
    "2008/11/13",
    "2008/11/14",
    "2008/11/15",
    "2008/11/16",
    "2008/11/17",
    "2008/11/18",
    "2008/11/19",
    "2008/11/20",
    "2008/11/21",
    "2008/11/22",
    "2008/11/23",
    "2008/11/24",
    "2008/11/25",
    "2008/11/26",
    "2008/11/27",
    "2008/11/28",
    "2008/11/29",
    "2008/11/30",
    "2008/12/1",
    "2008/12/2",
    "2008/12/3",
    "2008/12/4",
    "2008/12/5",
    "2008/12/6",
    "2008/12/7",
    "2008/12/8",
    "2008/12/9",
    "2008/12/10",
    "2008/12/11",
    "2008/12/12",
    "2008/12/13",
    "2008/12/14",
    "2008/12/15",
    "2008/12/16",
    "2008/12/17",
    "2008/12/18",
    "2008/12/19",
    "2008/12/20",
    "2008/12/21",
    "2008/12/22",
    "2008/12/23",
    "2008/12/24",
    "2008/12/25",
    "2008/12/26",
    "2008/12/27",
    "2008/12/28",
    "2008/12/29",
    "2008/12/30",
    "2008/12/31",
    "2009/1/1",
    "2009/1/2",
    "2009/1/3",
    "2009/1/4",
    "2009/1/5",
    "2009/1/6",
    "2009/1/7",
    "2009/1/8",
    "2009/1/9",
    "2009/1/10",
    "2009/1/11",
    "2009/1/12",
    "2009/1/13",
    "2009/1/14",
    "2009/1/15",
    "2009/1/16",
    "2009/1/17",
    "2009/1/18",
    "2009/1/19",
    "2009/1/20",
    "2009/1/21",
    "2009/1/22",
    "2009/1/23",
    "2009/1/24",
    "2009/1/25",
    "2009/1/26",
    "2009/1/27",
    "2009/1/28",
    "2009/1/29",
    "2009/1/30",
    "2009/1/31",
    "2009/2/1",
    "2009/2/2",
    "2009/2/3",
    "2009/2/4",
    "2009/2/5",
    "2009/2/6",
    "2009/2/7",
    "2009/2/8",
    "2009/2/9",
    "2009/2/10",
    "2009/2/11",
    "2009/2/12",
    "2009/2/13",
    "2009/2/14",
    "2009/2/15",
    "2009/2/16",
    "2009/2/17",
    "2009/2/18",
    "2009/2/19",
    "2009/2/20",
    "2009/2/21",
    "2009/2/22",
    "2009/2/23",
    "2009/2/24",
    "2009/2/25",
    "2009/2/26",
    "2009/2/27",
    "2009/2/28",
    "2009/3/1",
    "2009/3/2",
    "2009/3/3",
    "2009/3/4",
    "2009/3/5",
    "2009/3/6",
    "2009/3/7",
    "2009/3/8",
    "2009/3/9",
    "2009/3/10",
    "2009/3/11",
    "2009/3/12",
    "2009/3/13",
    "2009/3/14",
    "2009/3/15",
    "2009/3/16",
    "2009/3/17",
    "2009/3/18",
    "2009/3/19",
    "2009/3/20",
    "2009/3/21",
    "2009/3/22",
    "2009/3/23",
    "2009/3/24",
    "2009/3/25",
    "2009/3/26",
    "2009/3/27",
    "2009/3/28",
    "2009/3/29",
    "2009/3/30",
    "2009/3/31",
    "2009/4/1",
    "2009/4/2",
    "2009/4/3",
    "2009/4/4",
    "2009/4/5",
    "2009/4/6",
    "2009/4/7",
    "2009/4/8",
    "2009/4/9",
    "2009/4/10",
    "2009/4/11",
    "2009/4/12",
    "2009/4/13",
    "2009/4/14",
    "2009/4/15",
    "2009/4/16",
    "2009/4/17",
    "2009/4/18",
    "2009/4/19",
    "2009/4/20",
    "2009/4/21",
    "2009/4/22",
    "2009/4/23",
    "2009/4/24",
    "2009/4/25",
    "2009/4/26",
    "2009/4/27",
    "2009/4/28",
    "2009/4/29",
    "2009/4/30",
    "2009/5/1",
    "2009/5/2",
    "2009/5/3",
    "2009/5/4",
    "2009/5/5",
    "2009/5/6",
    "2009/5/7",
    "2009/5/8",
    "2009/5/9",
    "2009/5/10",
    "2009/5/11",
    "2009/5/12",
    "2009/5/13",
    "2009/5/14",
    "2009/5/15",
    "2009/5/16",
    "2009/5/17",
    "2009/5/18",
    "2009/5/19",
    "2009/5/20",
    "2009/5/21",
    "2009/5/22",
    "2009/5/23",
    "2009/5/24",
    "2009/5/25",
    "2009/5/26",
    "2009/5/27",
    "2009/5/28",
    "2009/5/29",
    "2009/5/30",
    "2009/5/31",
    "2009/6/1",
    "2009/6/2",
    "2009/6/3",
    "2009/6/4",
    "2009/6/5",
    "2009/6/6",
    "2009/6/7",
    "2009/6/8",
    "2009/6/9",
    "2009/6/10",
    "2009/6/11",
    "2009/6/12",
    "2009/6/13",
    "2009/6/14",
    "2009/6/15",
    "2009/6/16",
    "2009/6/17",
    "2009/6/18",
    "2009/6/19",
    "2009/6/20",
    "2009/6/21",
    "2009/6/22",
    "2009/6/23",
    "2009/6/24",
    "2009/6/25",
    "2009/6/26",
    "2009/6/27",
    "2009/6/28",
    "2009/6/29",
    "2009/6/30",
    "2009/7/1",
    "2009/7/2",
    "2009/7/3",
    "2009/7/4",
    "2009/7/5",
    "2009/7/6",
    "2009/7/7",
    "2009/7/8",
    "2009/7/9",
    "2009/7/10",
    "2009/7/11",
    "2009/7/12",
    "2009/7/13",
    "2009/7/14",
    "2009/7/15",
    "2009/7/16",
    "2009/7/17",
    "2009/7/18",
    "2009/7/19",
    "2009/7/20",
    "2009/7/21",
    "2009/7/22",
    "2009/7/23",
    "2009/7/24",
    "2009/7/25",
    "2009/7/26",
    "2009/7/27",
    "2009/7/28",
    "2009/7/29",
    "2009/7/30",
    "2009/7/31",
    "2009/8/1",
    "2009/8/2",
    "2009/8/3",
    "2009/8/4",
    "2009/8/5",
    "2009/8/6",
    "2009/8/7",
    "2009/8/8",
    "2009/8/9",
    "2009/8/10",
    "2009/8/11",
    "2009/8/12",
    "2009/8/13",
    "2009/8/14",
    "2009/8/15",
    "2009/8/16",
    "2009/8/17",
    "2009/8/18",
    "2009/8/19",
    "2009/8/20",
    "2009/8/21",
    "2009/8/22",
    "2009/8/23",
    "2009/8/24",
    "2009/8/25",
    "2009/8/26",
    "2009/8/27",
    "2009/8/28",
    "2009/8/29",
    "2009/8/30",
    "2009/8/31",
    "2009/9/1",
    "2009/9/2",
    "2009/9/3",
    "2009/9/4",
    "2009/9/5",
    "2009/9/6",
    "2009/9/7",
    "2009/9/8",
    "2009/9/9",
    "2009/9/10",
    "2009/9/11",
    "2009/9/12",
    "2009/9/13",
    "2009/9/14",
    "2009/9/15",
    "2009/9/16",
    "2009/9/17",
    "2009/9/18",
    "2009/9/19",
    "2009/9/20",
    "2009/9/21",
    "2009/9/22",
    "2009/9/23",
    "2009/9/24",
    "2009/9/25",
    "2009/9/26",
    "2009/9/27",
    "2009/9/28",
    "2009/9/29",
    "2009/9/30",
    "2009/10/1",
    "2009/10/2",
    "2009/10/3",
    "2009/10/4",
    "2009/10/5",
    "2009/10/6",
    "2009/10/7",
    "2009/10/8",
    "2009/10/9",
    "2009/10/10",
    "2009/10/11",
    "2009/10/12",
    "2009/10/13",
    "2009/10/14",
    "2009/10/15",
    "2009/10/16",
    "2009/10/17",
    "2009/10/18",
    "2009/10/19",
    "2009/10/20",
    "2009/10/21",
    "2009/10/22",
    "2009/10/23",
    "2009/10/24",
    "2009/10/25",
    "2009/10/26",
    "2009/10/27",
    "2009/10/28",
    "2009/10/29",
    "2009/10/30",
    "2009/10/31",
    "2009/11/1",
    "2009/11/2",
    "2009/11/3",
    "2009/11/4",
    "2009/11/5",
    "2009/11/6",
    "2009/11/7",
    "2009/11/8",
    "2009/11/9",
    "2009/11/10",
    "2009/11/11",
    "2009/11/12",
    "2009/11/13",
    "2009/11/14",
    "2009/11/15",
    "2009/11/16",
    "2009/11/17",
    "2009/11/18",
    "2009/11/19",
    "2009/11/20",
    "2009/11/21",
    "2009/11/22",
    "2009/11/23",
    "2009/11/24",
    "2009/11/25",
    "2009/11/26",
    "2009/11/27",
    "2009/11/28",
    "2009/11/29",
    "2009/11/30",
    "2009/12/1",
    "2009/12/2",
    "2009/12/3",
    "2009/12/4",
    "2009/12/5",
    "2009/12/6",
    "2009/12/7",
    "2009/12/8",
    "2009/12/9",
    "2009/12/10",
    "2009/12/11",
    "2009/12/12",
    "2009/12/13",
    "2009/12/14",
    "2009/12/15",
    "2009/12/16",
    "2009/12/17",
    "2009/12/18",
    "2009/12/19",
    "2009/12/20",
    "2009/12/21",
    "2009/12/22",
    "2009/12/23",
    "2009/12/24",
    "2009/12/25",
    "2009/12/26",
    "2009/12/27",
    "2009/12/28",
    "2009/12/29",
    "2009/12/30",
    "2009/12/31",
    "2010/1/1",
    "2010/1/2",
    "2010/1/3",
    "2010/1/4",
    "2010/1/5",
    "2010/1/6",
    "2010/1/7",
    "2010/1/8",
    "2010/1/9",
    "2010/1/10",
    "2010/1/11",
    "2010/1/12",
    "2010/1/13",
    "2010/1/14",
    "2010/1/15",
    "2010/1/16",
    "2010/1/17",
    "2010/1/18",
    "2010/1/19",
    "2010/1/20",
    "2010/1/21",
    "2010/1/22",
    "2010/1/23",
    "2010/1/24",
    "2010/1/25",
    "2010/1/26",
    "2010/1/27",
    "2010/1/28",
    "2010/1/29",
    "2010/1/30",
    "2010/1/31",
    "2010/2/1",
    "2010/2/2",
    "2010/2/3",
    "2010/2/4",
    "2010/2/5",
    "2010/2/6",
    "2010/2/7",
    "2010/2/8",
    "2010/2/9",
    "2010/2/10",
    "2010/2/11",
    "2010/2/12",
    "2010/2/13",
    "2010/2/14",
    "2010/2/15",
    "2010/2/16",
    "2010/2/17",
    "2010/2/18",
    "2010/2/19",
    "2010/2/20",
    "2010/2/21",
    "2010/2/22",
    "2010/2/23",
    "2010/2/24",
    "2010/2/25",
    "2010/2/26",
    "2010/2/27",
    "2010/2/28",
    "2010/3/1",
    "2010/3/2",
    "2010/3/3",
    "2010/3/4",
    "2010/3/5",
    "2010/3/6",
    "2010/3/7",
    "2010/3/8",
    "2010/3/9",
    "2010/3/10",
    "2010/3/11",
    "2010/3/12",
    "2010/3/13",
    "2010/3/14",
    "2010/3/15",
    "2010/3/16",
    "2010/3/17",
    "2010/3/18",
    "2010/3/19",
    "2010/3/20",
    "2010/3/21",
    "2010/3/22",
    "2010/3/23",
    "2010/3/24",
    "2010/3/25",
    "2010/3/26",
    "2010/3/27",
    "2010/3/28",
    "2010/3/29",
    "2010/3/30",
    "2010/3/31",
    "2010/4/1",
    "2010/4/2",
    "2010/4/3",
    "2010/4/4",
    "2010/4/5",
    "2010/4/6",
    "2010/4/7",
    "2010/4/8",
    "2010/4/9",
    "2010/4/10",
    "2010/4/11",
    "2010/4/12",
    "2010/4/13",
    "2010/4/14",
    "2010/4/15",
    "2010/4/16",
    "2010/4/17",
    "2010/4/18",
    "2010/4/19",
    "2010/4/20",
    "2010/4/21",
    "2010/4/22",
    "2010/4/23",
    "2010/4/24",
    "2010/4/25",
    "2010/4/26",
    "2010/4/27",
    "2010/4/28",
    "2010/4/29",
    "2010/4/30",
    "2010/5/1",
    "2010/5/2",
    "2010/5/3",
    "2010/5/4",
    "2010/5/5",
    "2010/5/6",
    "2010/5/7",
    "2010/5/8",
    "2010/5/9",
    "2010/5/10",
    "2010/5/11",
    "2010/5/12",
    "2010/5/13",
    "2010/5/14",
    "2010/5/15",
    "2010/5/16",
    "2010/5/17",
    "2010/5/18",
    "2010/5/19",
    "2010/5/20",
    "2010/5/21",
    "2010/5/22",
    "2010/5/23",
    "2010/5/24",
    "2010/5/25",
    "2010/5/26",
    "2010/5/27",
    "2010/5/28",
    "2010/5/29",
    "2010/5/30",
    "2010/5/31",
    "2010/6/1",
    "2010/6/2",
    "2010/6/3",
    "2010/6/4",
    "2010/6/5",
    "2010/6/6",
    "2010/6/7",
    "2010/6/8",
    "2010/6/9",
    "2010/6/10",
    "2010/6/11",
    "2010/6/12",
    "2010/6/13",
    "2010/6/14",
    "2010/6/15",
    "2010/6/16",
    "2010/6/17",
    "2010/6/18",
    "2010/6/19",
    "2010/6/20",
    "2010/6/21",
    "2010/6/22",
    "2010/6/23",
    "2010/6/24",
    "2010/6/25",
    "2010/6/26",
    "2010/6/27",
    "2010/6/28",
    "2010/6/29",
    "2010/6/30",
    "2010/7/1",
    "2010/7/2",
    "2010/7/3",
    "2010/7/4",
    "2010/7/5",
    "2010/7/6",
    "2010/7/7",
    "2010/7/8",
    "2010/7/9",
    "2010/7/10",
    "2010/7/11",
    "2010/7/12",
    "2010/7/13",
    "2010/7/14",
    "2010/7/15",
    "2010/7/16",
    "2010/7/17",
    "2010/7/18",
    "2010/7/19",
    "2010/7/20",
    "2010/7/21",
    "2010/7/22",
    "2010/7/23",
    "2010/7/24",
    "2010/7/25",
    "2010/7/26",
    "2010/7/27",
    "2010/7/28",
    "2010/7/29",
    "2010/7/30",
    "2010/7/31",
    "2010/8/1",
    "2010/8/2",
    "2010/8/3",
    "2010/8/4",
    "2010/8/5",
    "2010/8/6",
    "2010/8/7",
    "2010/8/8",
    "2010/8/9",
    "2010/8/10",
    "2010/8/11",
    "2010/8/12",
    "2010/8/13",
    "2010/8/14",
    "2010/8/15",
    "2010/8/16",
    "2010/8/17",
    "2010/8/18",
    "2010/8/19",
    "2010/8/20",
    "2010/8/21",
    "2010/8/22",
    "2010/8/23",
    "2010/8/24",
    "2010/8/25",
    "2010/8/26",
    "2010/8/27",
    "2010/8/28",
    "2010/8/29",
    "2010/8/30",
    "2010/8/31",
    "2010/9/1",
    "2010/9/2",
    "2010/9/3",
    "2010/9/4",
    "2010/9/5",
    "2010/9/6",
    "2010/9/7",
    "2010/9/8",
    "2010/9/9",
    "2010/9/10",
    "2010/9/11",
    "2010/9/12",
    "2010/9/13",
    "2010/9/14",
    "2010/9/15",
    "2010/9/16",
    "2010/9/17",
    "2010/9/18",
    "2010/9/19",
    "2010/9/20",
    "2010/9/21",
    "2010/9/22",
    "2010/9/23",
    "2010/9/24",
    "2010/9/25",
    "2010/9/26",
    "2010/9/27",
    "2010/9/28",
    "2010/9/29",
    "2010/9/30",
    "2010/10/1",
    "2010/10/2",
    "2010/10/3",
    "2010/10/4",
    "2010/10/5",
    "2010/10/6",
    "2010/10/7",
    "2010/10/8",
    "2010/10/9",
    "2010/10/10",
    "2010/10/11",
    "2010/10/12",
    "2010/10/13",
    "2010/10/14",
    "2010/10/15",
    "2010/10/16",
    "2010/10/17",
    "2010/10/18",
    "2010/10/19",
    "2010/10/20",
    "2010/10/21",
    "2010/10/22",
    "2010/10/23",
    "2010/10/24",
    "2010/10/25",
    "2010/10/26",
    "2010/10/27",
    "2010/10/28",
    "2010/10/29",
    "2010/10/30",
    "2010/10/31",
    "2010/11/1",
    "2010/11/2",
    "2010/11/3",
    "2010/11/4",
    "2010/11/5",
    "2010/11/6",
    "2010/11/7",
    "2010/11/8",
    "2010/11/9",
    "2010/11/10",
    "2010/11/11",
    "2010/11/12",
    "2010/11/13",
    "2010/11/14",
    "2010/11/15",
    "2010/11/16",
    "2010/11/17",
    "2010/11/18",
    "2010/11/19",
    "2010/11/20",
    "2010/11/21",
    "2010/11/22",
    "2010/11/23",
    "2010/11/24",
    "2010/11/25",
    "2010/11/26",
    "2010/11/27",
    "2010/11/28",
    "2010/11/29",
    "2010/11/30",
    "2010/12/1",
    "2010/12/2",
    "2010/12/3",
    "2010/12/4",
    "2010/12/5",
    "2010/12/6",
    "2010/12/7",
    "2010/12/8",
    "2010/12/9",
    "2010/12/10",
    "2010/12/11",
    "2010/12/12",
    "2010/12/13",
    "2010/12/14",
    "2010/12/15",
    "2010/12/16",
    "2010/12/17",
    "2010/12/18",
    "2010/12/19",
    "2010/12/20",
    "2010/12/21",
    "2010/12/22",
    "2010/12/23",
    "2010/12/24",
    "2010/12/25",
    "2010/12/26",
    "2010/12/27",
    "2010/12/28",
    "2010/12/29",
    "2010/12/30",
    "2010/12/31",
    "2011/1/1",
    "2011/1/2",
    "2011/1/3",
    "2011/1/4",
    "2011/1/5",
    "2011/1/6",
    "2011/1/7",
    "2011/1/8",
    "2011/1/9",
    "2011/1/10",
    "2011/1/11",
    "2011/1/12",
    "2011/1/13",
    "2011/1/14",
    "2011/1/15",
    "2011/1/16",
    "2011/1/17",
    "2011/1/18",
    "2011/1/19",
    "2011/1/20",
    "2011/1/21",
    "2011/1/22",
    "2011/1/23",
    "2011/1/24",
    "2011/1/25",
    "2011/1/26",
    "2011/1/27",
    "2011/1/28",
    "2011/1/29",
    "2011/1/30",
    "2011/1/31",
    "2011/2/1",
    "2011/2/2",
    "2011/2/3",
    "2011/2/4",
    "2011/2/5",
    "2011/2/6",
    "2011/2/7",
    "2011/2/8",
    "2011/2/9",
    "2011/2/10",
    "2011/2/11",
    "2011/2/12",
    "2011/2/13",
    "2011/2/14",
    "2011/2/15",
    "2011/2/16",
    "2011/2/17",
    "2011/2/18",
    "2011/2/19",
    "2011/2/20",
    "2011/2/21",
    "2011/2/22",
    "2011/2/23",
    "2011/2/24",
    "2011/2/25",
    "2011/2/26",
    "2011/2/27",
    "2011/2/28",
    "2011/3/1",
    "2011/3/2",
    "2011/3/3",
    "2011/3/4",
    "2011/3/5",
    "2011/3/6",
    "2011/3/7",
    "2011/3/8",
    "2011/3/9",
    "2011/3/10",
    "2011/3/11",
    "2011/3/12",
    "2011/3/13",
    "2011/3/14",
    "2011/3/15",
    "2011/3/16",
    "2011/3/17",
    "2011/3/18",
    "2011/3/19",
    "2011/3/20",
    "2011/3/21",
    "2011/3/22",
    "2011/3/23",
    "2011/3/24",
    "2011/3/25",
    "2011/3/26",
    "2011/3/27",
    "2011/3/28",
    "2011/3/29",
    "2011/3/30",
    "2011/3/31",
    "2011/4/1",
    "2011/4/2",
    "2011/4/3",
    "2011/4/4",
    "2011/4/5",
    "2011/4/6",
    "2011/4/7",
    "2011/4/8",
    "2011/4/9",
    "2011/4/10",
    "2011/4/11",
    "2011/4/12",
    "2011/4/13",
    "2011/4/14",
    "2011/4/15",
    "2011/4/16",
    "2011/4/17",
    "2011/4/18",
    "2011/4/19",
    "2011/4/20",
    "2011/4/21",
    "2011/4/22",
    "2011/4/23",
    "2011/4/24",
    "2011/4/25",
    "2011/4/26",
    "2011/4/27",
    "2011/4/28",
    "2011/4/29",
    "2011/4/30",
    "2011/5/1",
    "2011/5/2",
    "2011/5/3",
    "2011/5/4",
    "2011/5/5",
    "2011/5/6",
    "2011/5/7",
    "2011/5/8",
    "2011/5/9",
    "2011/5/10",
    "2011/5/11",
    "2011/5/12",
    "2011/5/13",
    "2011/5/14",
    "2011/5/15",
    "2011/5/16",
    "2011/5/17",
    "2011/5/18",
    "2011/5/19",
    "2011/5/20",
    "2011/5/21",
    "2011/5/22",
    "2011/5/23",
    "2011/5/24",
    "2011/5/25",
    "2011/5/26",
    "2011/5/27",
    "2011/5/28",
    "2011/5/29",
    "2011/5/30",
    "2011/5/31",
    "2011/6/1",
    "2011/6/2",
    "2011/6/3",
    "2011/6/4",
    "2011/6/5",
    "2011/6/6",
    "2011/6/7",
    "2011/6/8",
    "2011/6/9",
    "2011/6/10",
    "2011/6/11",
    "2011/6/12",
    "2011/6/13",
    "2011/6/14",
    "2011/6/15",
    "2011/6/16",
    "2011/6/17",
    "2011/6/18",
    "2011/6/19",
    "2011/6/20",
    "2011/6/21",
    "2011/6/22",
    "2011/6/23",
    "2011/6/24",
    "2011/6/25",
    "2011/6/26",
    "2011/6/27",
    "2011/6/28",
    "2011/6/29",
    "2011/6/30",
    "2011/7/1",
    "2011/7/2",
    "2011/7/3",
    "2011/7/4",
    "2011/7/5",
    "2011/7/6",
    "2011/7/7",
    "2011/7/8",
    "2011/7/9",
    "2011/7/10",
    "2011/7/11",
    "2011/7/12",
    "2011/7/13",
    "2011/7/14",
    "2011/7/15",
    "2011/7/16",
    "2011/7/17",
    "2011/7/18",
    "2011/7/19",
    "2011/7/20",
    "2011/7/21",
    "2011/7/22",
    "2011/7/23",
    "2011/7/24",
    "2011/7/25",
    "2011/7/26",
    "2011/7/27",
    "2011/7/28",
    "2011/7/29",
    "2011/7/30",
    "2011/7/31",
    "2011/8/1",
    "2011/8/2",
    "2011/8/3",
    "2011/8/4",
    "2011/8/5",
    "2011/8/6",
    "2011/8/7",
    "2011/8/8",
    "2011/8/9",
    "2011/8/10",
    "2011/8/11",
    "2011/8/12",
    "2011/8/13",
    "2011/8/14",
    "2011/8/15",
    "2011/8/16",
    "2011/8/17",
    "2011/8/18",
    "2011/8/19",
    "2011/8/20",
    "2011/8/21",
    "2011/8/22",
    "2011/8/23",
    "2011/8/24",
    "2011/8/25",
    "2011/8/26",
    "2011/8/27",
    "2011/8/28",
    "2011/8/29",
    "2011/8/30",
    "2011/8/31",
    "2011/9/1",
    "2011/9/2",
    "2011/9/3",
    "2011/9/4",
    "2011/9/5",
    "2011/9/6",
    "2011/9/7",
    "2011/9/8",
    "2011/9/9",
    "2011/9/10",
    "2011/9/11",
    "2011/9/12",
    "2011/9/13",
    "2011/9/14",
    "2011/9/15",
    "2011/9/16",
    "2011/9/17",
    "2011/9/18",
    "2011/9/19",
    "2011/9/20",
    "2011/9/21",
    "2011/9/22",
    "2011/9/23",
    "2011/9/24",
    "2011/9/25",
    "2011/9/26",
    "2011/9/27",
    "2011/9/28",
    "2011/9/29",
    "2011/9/30",
    "2011/10/1",
    "2011/10/2",
    "2011/10/3",
    "2011/10/4",
    "2011/10/5",
    "2011/10/6",
    "2011/10/7",
    "2011/10/8",
    "2011/10/9",
    "2011/10/10",
    "2011/10/11",
    "2011/10/12",
    "2011/10/13",
    "2011/10/14",
    "2011/10/15",
    "2011/10/16",
    "2011/10/17",
    "2011/10/18",
    "2011/10/19",
    "2011/10/20",
    "2011/10/21",
    "2011/10/22",
    "2011/10/23",
    "2011/10/24",
    "2011/10/25",
    "2011/10/26",
    "2011/10/27",
    "2011/10/28",
    "2011/10/29",
    "2011/10/30",
    "2011/10/31",
    "2011/11/1",
    "2011/11/2",
    "2011/11/3",
    "2011/11/4",
    "2011/11/5",
    "2011/11/6",
    "2011/11/7",
    "2011/11/8",
    "2011/11/9",
    "2011/11/10",
    "2011/11/11",
    "2011/11/12",
    "2011/11/13",
    "2011/11/14",
    "2011/11/15",
    "2011/11/16",
    "2011/11/17",
    "2011/11/18",
    "2011/11/19",
    "2011/11/20",
    "2011/11/21",
    "2011/11/22",
    "2011/11/23",
    "2011/11/24",
    "2011/11/25",
    "2011/11/26",
    "2011/11/27",
    "2011/11/28",
    "2011/11/29",
    "2011/11/30",
    "2011/12/1",
    "2011/12/2",
    "2011/12/3",
    "2011/12/4",
    "2011/12/5",
    "2011/12/6",
    "2011/12/7",
    "2011/12/8",
    "2011/12/9",
    "2011/12/10",
    "2011/12/11",
    "2011/12/12",
    "2011/12/13",
    "2011/12/14",
    "2011/12/15",
    "2011/12/16",
    "2011/12/17",
    "2011/12/18",
    "2011/12/19",
    "2011/12/20",
    "2011/12/21",
    "2011/12/22",
    "2011/12/23",
    "2011/12/24",
    "2011/12/25",
    "2011/12/26",
    "2011/12/27",
    "2011/12/28",
    "2011/12/29",
    "2011/12/30",
    "2011/12/31",
    "2012/1/1",
    "2012/1/2",
    "2012/1/3",
    "2012/1/4",
    "2012/1/5",
    "2012/1/6",
    "2012/1/7",
    "2012/1/8",
    "2012/1/9",
    "2012/1/10",
    "2012/1/11",
    "2012/1/12",
    "2012/1/13",
    "2012/1/14",
    "2012/1/15",
    "2012/1/16",
    "2012/1/17",
    "2012/1/18",
    "2012/1/19",
    "2012/1/20",
    "2012/1/21",
    "2012/1/22",
    "2012/1/23",
    "2012/1/24",
    "2012/1/25",
    "2012/1/26",
    "2012/1/27",
    "2012/1/28",
    "2012/1/29",
    "2012/1/30",
    "2012/1/31",
    "2012/2/1",
    "2012/2/2",
    "2012/2/3",
    "2012/2/4",
    "2012/2/5",
    "2012/2/6",
    "2012/2/7",
    "2012/2/8",
    "2012/2/9",
    "2012/2/10",
    "2012/2/11",
    "2012/2/12",
    "2012/2/13",
    "2012/2/14",
    "2012/2/15",
    "2012/2/16",
    "2012/2/17",
    "2012/2/18",
    "2012/2/19",
    "2012/2/20",
    "2012/2/21",
    "2012/2/22",
    "2012/2/23",
    "2012/2/24",
    "2012/2/25",
    "2012/2/26",
    "2012/2/27",
    "2012/2/28",
    "2012/2/29",
    "2012/3/1",
    "2012/3/2",
    "2012/3/3",
    "2012/3/4",
    "2012/3/5",
    "2012/3/6",
    "2012/3/7",
    "2012/3/8",
    "2012/3/9",
    "2012/3/10",
    "2012/3/11",
    "2012/3/12",
    "2012/3/13",
    "2012/3/14",
    "2012/3/15",
    "2012/3/16",
    "2012/3/17",
    "2012/3/18",
    "2012/3/19",
    "2012/3/20",
    "2012/3/21",
    "2012/3/22",
    "2012/3/23",
    "2012/3/24",
    "2012/3/25",
    "2012/3/26",
    "2012/3/27",
    "2012/3/28",
    "2012/3/29",
    "2012/3/30",
    "2012/3/31",
    "2012/4/1",
    "2012/4/2",
    "2012/4/3",
    "2012/4/4",
    "2012/4/5",
    "2012/4/6",
    "2012/4/7",
    "2012/4/8",
    "2012/4/9",
    "2012/4/10",
    "2012/4/11",
    "2012/4/12",
    "2012/4/13",
    "2012/4/14",
    "2012/4/15",
    "2012/4/16",
    "2012/4/17",
    "2012/4/18",
    "2012/4/19",
    "2012/4/20",
    "2012/4/21",
    "2012/4/22",
    "2012/4/23",
    "2012/4/24",
    "2012/4/25",
    "2012/4/26",
    "2012/4/27",
    "2012/4/28",
    "2012/4/29",
    "2012/4/30",
    "2012/5/1",
    "2012/5/2",
    "2012/5/3",
    "2012/5/4",
    "2012/5/5",
    "2012/5/6",
    "2012/5/7",
    "2012/5/8",
    "2012/5/9",
    "2012/5/10",
    "2012/5/11",
    "2012/5/12",
    "2012/5/13",
    "2012/5/14",
    "2012/5/15",
    "2012/5/16",
    "2012/5/17",
    "2012/5/18",
    "2012/5/19",
    "2012/5/20",
    "2012/5/21",
    "2012/5/22",
    "2012/5/23",
    "2012/5/24",
    "2012/5/25",
    "2012/5/26",
    "2012/5/27",
    "2012/5/28",
    "2012/5/29",
    "2012/5/30",
    "2012/5/31",
    "2012/6/1",
    "2012/6/2",
    "2012/6/3",
    "2012/6/4",
    "2012/6/5",
    "2012/6/6",
    "2012/6/7",
    "2012/6/8",
    "2012/6/9",
    "2012/6/10",
    "2012/6/11",
    "2012/6/12",
    "2012/6/13",
    "2012/6/14",
    "2012/6/15",
    "2012/6/16",
    "2012/6/17",
    "2012/6/18",
    "2012/6/19",
    "2012/6/20",
    "2012/6/21",
    "2012/6/22",
    "2012/6/23",
    "2012/6/24",
    "2012/6/25",
    "2012/6/26",
    "2012/6/27",
    "2012/6/28",
    "2012/6/29",
    "2012/6/30",
    "2012/7/1",
    "2012/7/2",
    "2012/7/3",
    "2012/7/4",
    "2012/7/5",
    "2012/7/6",
    "2012/7/7",
    "2012/7/8",
    "2012/7/9",
    "2012/7/10",
    "2012/7/11",
    "2012/7/12",
    "2012/7/13",
    "2012/7/14",
    "2012/7/15",
    "2012/7/16",
    "2012/7/17",
    "2012/7/18",
    "2012/7/19",
    "2012/7/20",
    "2012/7/21",
    "2012/7/22",
    "2012/7/23",
    "2012/7/24",
    "2012/7/25",
    "2012/7/26",
    "2012/7/27",
    "2012/7/28",
    "2012/7/29",
    "2012/7/30",
    "2012/7/31",
    "2012/8/1",
    "2012/8/2",
    "2012/8/3",
    "2012/8/4",
    "2012/8/5",
    "2012/8/6",
    "2012/8/7",
    "2012/8/8",
    "2012/8/9",
    "2012/8/10",
    "2012/8/11",
    "2012/8/12",
    "2012/8/13",
    "2012/8/14",
    "2012/8/15",
    "2012/8/16",
    "2012/8/17",
    "2012/8/18",
    "2012/8/19",
    "2012/8/20",
    "2012/8/21",
    "2012/8/22",
    "2012/8/23",
    "2012/8/24",
    "2012/8/25",
    "2012/8/26",
    "2012/8/27",
    "2012/8/28",
    "2012/8/29",
    "2012/8/30",
    "2012/8/31",
    "2012/9/1",
    "2012/9/2",
    "2012/9/3",
    "2012/9/4",
    "2012/9/5",
    "2012/9/6",
    "2012/9/7",
    "2012/9/8",
    "2012/9/9",
    "2012/9/10",
    "2012/9/11",
    "2012/9/12",
    "2012/9/13",
    "2012/9/14",
    "2012/9/15",
    "2012/9/16",
    "2012/9/17",
    "2012/9/18",
    "2012/9/19",
    "2012/9/20",
    "2012/9/21",
    "2012/9/22",
    "2012/9/23",
    "2012/9/24",
    "2012/9/25",
    "2012/9/26",
    "2012/9/27",
    "2012/9/28",
    "2012/9/29",
    "2012/9/30",
    "2012/10/1",
    "2012/10/2",
    "2012/10/3",
    "2012/10/4",
    "2012/10/5",
    "2012/10/6",
    "2012/10/7",
    "2012/10/8",
    "2012/10/9",
    "2012/10/10",
    "2012/10/11",
    "2012/10/12",
    "2012/10/13",
    "2012/10/14",
    "2012/10/15",
    "2012/10/16",
    "2012/10/17",
    "2012/10/18",
    "2012/10/19",
    "2012/10/20",
    "2012/10/21",
    "2012/10/22",
    "2012/10/23",
    "2012/10/24",
    "2012/10/25",
    "2012/10/26",
    "2012/10/27",
    "2012/10/28",
    "2012/10/29",
    "2012/10/30",
    "2012/10/31",
    "2012/11/1",
    "2012/11/2",
    "2012/11/3",
    "2012/11/4",
    "2012/11/5",
    "2012/11/6",
    "2012/11/7",
    "2012/11/8",
    "2012/11/9",
    "2012/11/10",
    "2012/11/11",
    "2012/11/12",
    "2012/11/13",
    "2012/11/14",
    "2012/11/15",
    "2012/11/16",
    "2012/11/17",
    "2012/11/18",
    "2012/11/19",
    "2012/11/20",
    "2012/11/21",
    "2012/11/22",
    "2012/11/23",
    "2012/11/24",
    "2012/11/25",
    "2012/11/26",
    "2012/11/27",
    "2012/11/28",
    "2012/11/29",
    "2012/11/30",
    "2012/12/1",
    "2012/12/2",
    "2012/12/3",
    "2012/12/4",
    "2012/12/5",
    "2012/12/6",
    "2012/12/7",
    "2012/12/8",
    "2012/12/9",
    "2012/12/10",
    "2012/12/11",
    "2012/12/12",
    "2012/12/13",
    "2012/12/14",
    "2012/12/15",
    "2012/12/16",
    "2012/12/17",
    "2012/12/18",
    "2012/12/19",
    "2012/12/20",
    "2012/12/21",
    "2012/12/22",
    "2012/12/23",
    "2012/12/24",
    "2012/12/25",
    "2012/12/26",
    "2012/12/27",
    "2012/12/28",
    "2012/12/29",
    "2012/12/30",
    "2012/12/31",
    "2013/1/1",
    "2013/1/2",
    "2013/1/3",
    "2013/1/4",
    "2013/1/5",
    "2013/1/6",
    "2013/1/7",
    "2013/1/8",
    "2013/1/9",
    "2013/1/10",
    "2013/1/11",
    "2013/1/12",
    "2013/1/13",
    "2013/1/14",
    "2013/1/15",
    "2013/1/16",
    "2013/1/17",
    "2013/1/18",
    "2013/1/19",
    "2013/1/20",
    "2013/1/21",
    "2013/1/22",
    "2013/1/23",
    "2013/1/24",
    "2013/1/25",
    "2013/1/26",
    "2013/1/27",
    "2013/1/28",
    "2013/1/29",
    "2013/1/30",
    "2013/1/31",
    "2013/2/1",
    "2013/2/2",
    "2013/2/3",
    "2013/2/4",
    "2013/2/5",
    "2013/2/6",
    "2013/2/7",
    "2013/2/8",
    "2013/2/9",
    "2013/2/10",
    "2013/2/11",
    "2013/2/12",
    "2013/2/13",
    "2013/2/14",
    "2013/2/15",
    "2013/2/16",
    "2013/2/17",
    "2013/2/18",
    "2013/2/19",
    "2013/2/20",
    "2013/2/21",
    "2013/2/22",
    "2013/2/23",
    "2013/2/24",
    "2013/2/25",
    "2013/2/26",
    "2013/2/27",
    "2013/2/28",
    "2013/3/1",
    "2013/3/2",
    "2013/3/3",
    "2013/3/4",
    "2013/3/5",
    "2013/3/6",
    "2013/3/7",
    "2013/3/8",
    "2013/3/9",
    "2013/3/10",
    "2013/3/11",
    "2013/3/12",
    "2013/3/13",
    "2013/3/14",
    "2013/3/15",
    "2013/3/16",
    "2013/3/17",
    "2013/3/18",
    "2013/3/19",
    "2013/3/20",
    "2013/3/21",
    "2013/3/22",
    "2013/3/23",
    "2013/3/24",
    "2013/3/25",
    "2013/3/26",
    "2013/3/27",
    "2013/3/28",
    "2013/3/29",
    "2013/3/30",
    "2013/3/31",
    "2013/4/1",
    "2013/4/2",
    "2013/4/3",
    "2013/4/4",
    "2013/4/5",
    "2013/4/6",
    "2013/4/7",
    "2013/4/8",
    "2013/4/9",
    "2013/4/10",
    "2013/4/11",
    "2013/4/12",
    "2013/4/13",
    "2013/4/14",
    "2013/4/15",
    "2013/4/16",
    "2013/4/17",
    "2013/4/18",
    "2013/4/19",
    "2013/4/20",
    "2013/4/21",
    "2013/4/22",
    "2013/4/23",
    "2013/4/24",
    "2013/4/25",
    "2013/4/26",
    "2013/4/27",
    "2013/4/28",
    "2013/4/29",
    "2013/4/30",
    "2013/5/1",
    "2013/5/2",
    "2013/5/3",
    "2013/5/4",
    "2013/5/5",
    "2013/5/6",
    "2013/5/7",
    "2013/5/8",
    "2013/5/9",
    "2013/5/10",
    "2013/5/11",
    "2013/5/12",
    "2013/5/13",
    "2013/5/14",
    "2013/5/15",
    "2013/5/16",
    "2013/5/17",
    "2013/5/18",
    "2013/5/19",
    "2013/5/20",
    "2013/5/21",
    "2013/5/22",
    "2013/5/23",
    "2013/5/24",
    "2013/5/25",
    "2013/5/26",
    "2013/5/27",
    "2013/5/28",
    "2013/5/29",
    "2013/5/30",
    "2013/5/31",
    "2013/6/1",
    "2013/6/2",
    "2013/6/3",
    "2013/6/4",
    "2013/6/5",
    "2013/6/6",
    "2013/6/7",
    "2013/6/8",
    "2013/6/9",
    "2013/6/10",
    "2013/6/11",
    "2013/6/12",
    "2013/6/13",
    "2013/6/14",
    "2013/6/15",
    "2013/6/16",
    "2013/6/17",
    "2013/6/18",
    "2013/6/19",
    "2013/6/20",
    "2013/6/21",
    "2013/6/22",
    "2013/6/23",
    "2013/6/24",
    "2013/6/25",
    "2013/6/26",
    "2013/6/27",
    "2013/6/28",
    "2013/6/29",
    "2013/6/30",
    "2013/7/1",
    "2013/7/2",
    "2013/7/3",
    "2013/7/4",
    "2013/7/5",
    "2013/7/6",
    "2013/7/7",
    "2013/7/8",
    "2013/7/9",
    "2013/7/10",
    "2013/7/11",
    "2013/7/12",
    "2013/7/13",
    "2013/7/14",
    "2013/7/15",
    "2013/7/16",
    "2013/7/17",
    "2013/7/18",
    "2013/7/19",
    "2013/7/20",
    "2013/7/21",
    "2013/7/22",
    "2013/7/23",
    "2013/7/24",
    "2013/7/25",
    "2013/7/26",
    "2013/7/27",
    "2013/7/28",
    "2013/7/29",
    "2013/7/30",
    "2013/7/31",
    "2013/8/1",
    "2013/8/2",
    "2013/8/3",
    "2013/8/4",
    "2013/8/5",
    "2013/8/6",
    "2013/8/7",
    "2013/8/8",
    "2013/8/9",
    "2013/8/10",
    "2013/8/11",
    "2013/8/12",
    "2013/8/13",
    "2013/8/14",
    "2013/8/15",
    "2013/8/16",
    "2013/8/17",
    "2013/8/18",
    "2013/8/19",
    "2013/8/20",
    "2013/8/21",
    "2013/8/22",
    "2013/8/23",
    "2013/8/24",
    "2013/8/25",
    "2013/8/26",
    "2013/8/27",
    "2013/8/28",
    "2013/8/29",
    "2013/8/30",
    "2013/8/31",
    "2013/9/1",
    "2013/9/2",
    "2013/9/3",
    "2013/9/4",
    "2013/9/5",
    "2013/9/6",
    "2013/9/7",
    "2013/9/8",
    "2013/9/9",
    "2013/9/10",
    "2013/9/11",
    "2013/9/12",
    "2013/9/13",
    "2013/9/14",
    "2013/9/15",
    "2013/9/16",
    "2013/9/17",
    "2013/9/18",
    "2013/9/19",
    "2013/9/20",
    "2013/9/21",
    "2013/9/22",
    "2013/9/23",
    "2013/9/24",
    "2013/9/25",
    "2013/9/26",
    "2013/9/27",
    "2013/9/28",
    "2013/9/29",
    "2013/9/30",
    "2013/10/1",
    "2013/10/2",
    "2013/10/3",
    "2013/10/4",
    "2013/10/5",
    "2013/10/6",
    "2013/10/7",
    "2013/10/8",
    "2013/10/9",
    "2013/10/10",
    "2013/10/11",
    "2013/10/12",
    "2013/10/13",
    "2013/10/14",
    "2013/10/15",
    "2013/10/16",
    "2013/10/17",
    "2013/10/18",
    "2013/10/19",
    "2013/10/20",
    "2013/10/21",
    "2013/10/22",
    "2013/10/23",
    "2013/10/24",
    "2013/10/25",
    "2013/10/26",
    "2013/10/27",
    "2013/10/28",
    "2013/10/29",
    "2013/10/30",
    "2013/10/31",
    "2013/11/1",
    "2013/11/2",
    "2013/11/3",
    "2013/11/4",
    "2013/11/5",
    "2013/11/6",
    "2013/11/7",
    "2013/11/8",
    "2013/11/9",
    "2013/11/10",
    "2013/11/11",
    "2013/11/12",
    "2013/11/13",
    "2013/11/14",
    "2013/11/15",
    "2013/11/16",
    "2013/11/17",
    "2013/11/18",
    "2013/11/19",
    "2013/11/20",
    "2013/11/21",
    "2013/11/22",
    "2013/11/23",
    "2013/11/24",
    "2013/11/25",
    "2013/11/26",
    "2013/11/27",
    "2013/11/28",
    "2013/11/29",
    "2013/11/30",
    "2013/12/1",
    "2013/12/2",
    "2013/12/3",
    "2013/12/4",
    "2013/12/5",
    "2013/12/6",
    "2013/12/7",
    "2013/12/8",
    "2013/12/9",
    "2013/12/10",
    "2013/12/11",
    "2013/12/12",
    "2013/12/13",
    "2013/12/14",
    "2013/12/15",
    "2013/12/16",
    "2013/12/17",
    "2013/12/18",
    "2013/12/19",
    "2013/12/20",
    "2013/12/21",
    "2013/12/22",
    "2013/12/23",
    "2013/12/24",
    "2013/12/25",
    "2013/12/26",
    "2013/12/27",
    "2013/12/28",
    "2013/12/29",
    "2013/12/30",
    "2013/12/31",
    "2014/1/1",
    "2014/1/2",
    "2014/1/3",
    "2014/1/4",
    "2014/1/5",
    "2014/1/6",
    "2014/1/7",
    "2014/1/8",
    "2014/1/9",
    "2014/1/10",
    "2014/1/11",
    "2014/1/12",
    "2014/1/13",
    "2014/1/14",
    "2014/1/15",
    "2014/1/16",
    "2014/1/17",
    "2014/1/18",
    "2014/1/19",
    "2014/1/20",
    "2014/1/21",
    "2014/1/22",
    "2014/1/23",
    "2014/1/24",
    "2014/1/25",
    "2014/1/26",
    "2014/1/27",
    "2014/1/28",
    "2014/1/29",
    "2014/1/30",
    "2014/1/31",
    "2014/2/1",
    "2014/2/2",
    "2014/2/3",
    "2014/2/4",
    "2014/2/5",
    "2014/2/6",
    "2014/2/7",
    "2014/2/8",
    "2014/2/9",
    "2014/2/10",
    "2014/2/11",
    "2014/2/12",
    "2014/2/13",
    "2014/2/14",
    "2014/2/15",
    "2014/2/16",
    "2014/2/17",
    "2014/2/18",
    "2014/2/19",
    "2014/2/20",
    "2014/2/21",
    "2014/2/22",
    "2014/2/23",
    "2014/2/24",
    "2014/2/25",
    "2014/2/26",
    "2014/2/27",
    "2014/2/28",
    "2014/3/1",
    "2014/3/2",
    "2014/3/3",
    "2014/3/4",
    "2014/3/5",
    "2014/3/6",
    "2014/3/7",
    "2014/3/8",
    "2014/3/9",
    "2014/3/10",
    "2014/3/11",
    "2014/3/12",
    "2014/3/13",
    "2014/3/14",
    "2014/3/15",
    "2014/3/16",
    "2014/3/17",
    "2014/3/18",
    "2014/3/19",
    "2014/3/20",
    "2014/3/21",
    "2014/3/22",
    "2014/3/23",
    "2014/3/24",
    "2014/3/25",
    "2014/3/26",
    "2014/3/27",
    "2014/3/28",
    "2014/3/29",
    "2014/3/30",
    "2014/3/31",
    "2014/4/1",
    "2014/4/2",
    "2014/4/3",
    "2014/4/4",
    "2014/4/5",
    "2014/4/6",
    "2014/4/7",
    "2014/4/8",
    "2014/4/9",
    "2014/4/10",
    "2014/4/11",
    "2014/4/12",
    "2014/4/13",
    "2014/4/14",
    "2014/4/15",
    "2014/4/16",
    "2014/4/17",
    "2014/4/18",
    "2014/4/19",
    "2014/4/20",
    "2014/4/21",
    "2014/4/22",
    "2014/4/23",
    "2014/4/24",
    "2014/4/25",
    "2014/4/26",
    "2014/4/27",
    "2014/4/28",
    "2014/4/29",
    "2014/4/30",
    "2014/5/1",
    "2014/5/2",
    "2014/5/3",
    "2014/5/4",
    "2014/5/5",
    "2014/5/6",
    "2014/5/7",
    "2014/5/8",
    "2014/5/9",
    "2014/5/10",
    "2014/5/11",
    "2014/5/12",
    "2014/5/13",
    "2014/5/14",
    "2014/5/15",
    "2014/5/16",
    "2014/5/17",
    "2014/5/18",
    "2014/5/19",
    "2014/5/20",
    "2014/5/21",
    "2014/5/22",
    "2014/5/23",
    "2014/5/24",
    "2014/5/25",
    "2014/5/26",
    "2014/5/27",
    "2014/5/28",
    "2014/5/29",
    "2014/5/30",
    "2014/5/31",
    "2014/6/1",
    "2014/6/2",
    "2014/6/3",
    "2014/6/4",
    "2014/6/5",
    "2014/6/6",
    "2014/6/7",
    "2014/6/8",
    "2014/6/9",
    "2014/6/10",
    "2014/6/11",
    "2014/6/12",
    "2014/6/13",
    "2014/6/14",
    "2014/6/15",
    "2014/6/16",
    "2014/6/17",
    "2014/6/18",
    "2014/6/19",
    "2014/6/20",
    "2014/6/21",
    "2014/6/22",
    "2014/6/23",
    "2014/6/24",
    "2014/6/25",
    "2014/6/26",
    "2014/6/27",
    "2014/6/28",
    "2014/6/29",
    "2014/6/30",
    "2014/7/1",
    "2014/7/2",
    "2014/7/3",
    "2014/7/4",
    "2014/7/5",
    "2014/7/6",
    "2014/7/7",
    "2014/7/8",
    "2014/7/9",
    "2014/7/10",
    "2014/7/11",
    "2014/7/12",
    "2014/7/13",
    "2014/7/14",
    "2014/7/15",
    "2014/7/16",
    "2014/7/17",
    "2014/7/18",
    "2014/7/19",
    "2014/7/20",
    "2014/7/21",
    "2014/7/22",
    "2014/7/23",
    "2014/7/24",
    "2014/7/25",
    "2014/7/26",
    "2014/7/27",
    "2014/7/28",
    "2014/7/29",
    "2014/7/30",
    "2014/7/31",
    "2014/8/1",
    "2014/8/2",
    "2014/8/3",
    "2014/8/4",
    "2014/8/5",
    "2014/8/6",
    "2014/8/7",
    "2014/8/8",
    "2014/8/9",
    "2014/8/10",
    "2014/8/11",
    "2014/8/12",
    "2014/8/13",
    "2014/8/14",
    "2014/8/15",
    "2014/8/16",
    "2014/8/17",
    "2014/8/18",
    "2014/8/19",
    "2014/8/20",
    "2014/8/21",
    "2014/8/22",
    "2014/8/23",
    "2014/8/24",
    "2014/8/25",
    "2014/8/26",
    "2014/8/27",
    "2014/8/28",
    "2014/8/29",
    "2014/8/30",
    "2014/8/31",
    "2014/9/1",
    "2014/9/2",
    "2014/9/3",
    "2014/9/4",
    "2014/9/5",
    "2014/9/6",
    "2014/9/7",
    "2014/9/8",
    "2014/9/9",
    "2014/9/10",
    "2014/9/11",
    "2014/9/12",
    "2014/9/13",
    "2014/9/14",
    "2014/9/15",
    "2014/9/16",
    "2014/9/17",
    "2014/9/18",
    "2014/9/19",
    "2014/9/20",
    "2014/9/21",
    "2014/9/22",
    "2014/9/23",
    "2014/9/24",
    "2014/9/25",
    "2014/9/26",
    "2014/9/27",
    "2014/9/28",
    "2014/9/29",
    "2014/9/30",
    "2014/10/1",
    "2014/10/2",
    "2014/10/3",
    "2014/10/4",
    "2014/10/5",
    "2014/10/6",
    "2014/10/7",
    "2014/10/8",
    "2014/10/9",
    "2014/10/10",
    "2014/10/11",
    "2014/10/12",
    "2014/10/13",
    "2014/10/14",
    "2014/10/15",
    "2014/10/16",
    "2014/10/17",
    "2014/10/18",
    "2014/10/19",
    "2014/10/20",
    "2014/10/21",
    "2014/10/22",
    "2014/10/23",
    "2014/10/24",
    "2014/10/25",
    "2014/10/26",
    "2014/10/27",
    "2014/10/28",
    "2014/10/29",
    "2014/10/30",
    "2014/10/31",
    "2014/11/1",
    "2014/11/2",
    "2014/11/3",
    "2014/11/4",
    "2014/11/5",
    "2014/11/6",
    "2014/11/7",
    "2014/11/8",
    "2014/11/9",
    "2014/11/10",
    "2014/11/11",
    "2014/11/12",
    "2014/11/13",
    "2014/11/14",
    "2014/11/15",
    "2014/11/16",
    "2014/11/17",
    "2014/11/18",
    "2014/11/19",
    "2014/11/20",
    "2014/11/21",
    "2014/11/22",
    "2014/11/23",
    "2014/11/24",
    "2014/11/25",
    "2014/11/26",
    "2014/11/27",
    "2014/11/28",
    "2014/11/29",
    "2014/11/30",
    "2014/12/1",
    "2014/12/2",
    "2014/12/3",
    "2014/12/4",
    "2014/12/5",
    "2014/12/6",
    "2014/12/7",
    "2014/12/8",
    "2014/12/9",
    "2014/12/10",
    "2014/12/11",
    "2014/12/12",
    "2014/12/13",
    "2014/12/14",
    "2014/12/15",
    "2014/12/16",
    "2014/12/17",
    "2014/12/18",
    "2014/12/19",
    "2014/12/20",
    "2014/12/21",
    "2014/12/22",
    "2014/12/23",
    "2014/12/24",
    "2014/12/25",
    "2014/12/26",
    "2014/12/27",
    "2014/12/28",
    "2014/12/29",
    "2014/12/30",
    "2014/12/31",
    "2015/1/1",
    "2015/1/2",
    "2015/1/3",
    "2015/1/4",
    "2015/1/5",
    "2015/1/6",
    "2015/1/7",
    "2015/1/8",
    "2015/1/9",
    "2015/1/10",
    "2015/1/11",
    "2015/1/12",
    "2015/1/13",
    "2015/1/14",
    "2015/1/15",
    "2015/1/16",
    "2015/1/17",
    "2015/1/18",
    "2015/1/19",
    "2015/1/20",
    "2015/1/21",
    "2015/1/22",
    "2015/1/23",
    "2015/1/24",
    "2015/1/25",
    "2015/1/26",
    "2015/1/27",
    "2015/1/28",
    "2015/1/29",
    "2015/1/30",
    "2015/1/31",
    "2015/2/1",
    "2015/2/2",
    "2015/2/3",
    "2015/2/4",
    "2015/2/5",
    "2015/2/6",
    "2015/2/7",
    "2015/2/8",
    "2015/2/9",
    "2015/2/10",
    "2015/2/11",
    "2015/2/12",
    "2015/2/13",
    "2015/2/14",
    "2015/2/15",
    "2015/2/16",
    "2015/2/17",
    "2015/2/18",
    "2015/2/19",
    "2015/2/20",
    "2015/2/21",
    "2015/2/22",
    "2015/2/23",
    "2015/2/24",
    "2015/2/25",
    "2015/2/26",
    "2015/2/27",
    "2015/2/28",
    "2015/3/1",
    "2015/3/2",
    "2015/3/3",
    "2015/3/4",
    "2015/3/5",
    "2015/3/6",
    "2015/3/7",
    "2015/3/8",
    "2015/3/9",
    "2015/3/10",
    "2015/3/11",
    "2015/3/12",
    "2015/3/13",
    "2015/3/14",
    "2015/3/15",
    "2015/3/16",
    "2015/3/17",
    "2015/3/18",
    "2015/3/19",
    "2015/3/20",
    "2015/3/21",
    "2015/3/22",
    "2015/3/23",
    "2015/3/24",
    "2015/3/25",
    "2015/3/26",
    "2015/3/27",
    "2015/3/28",
    "2015/3/29",
    "2015/3/30",
    "2015/3/31",
    "2015/4/1",
    "2015/4/2",
    "2015/4/3",
    "2015/4/4",
    "2015/4/5",
    "2015/4/6",
    "2015/4/7",
    "2015/4/8",
    "2015/4/9",
    "2015/4/10",
    "2015/4/11",
    "2015/4/12",
    "2015/4/13",
    "2015/4/14",
    "2015/4/15",
    "2015/4/16",
    "2015/4/17",
    "2015/4/18",
    "2015/4/19",
    "2015/4/20",
    "2015/4/21",
    "2015/4/22",
    "2015/4/23",
    "2015/4/24",
    "2015/4/25",
    "2015/4/26",
    "2015/4/27",
    "2015/4/28",
    "2015/4/29",
    "2015/4/30",
    "2015/5/1",
    "2015/5/2",
    "2015/5/3",
    "2015/5/4",
    "2015/5/5",
    "2015/5/6",
    "2015/5/7",
    "2015/5/8",
    "2015/5/9",
    "2015/5/10",
    "2015/5/11",
    "2015/5/12",
    "2015/5/13",
    "2015/5/14",
    "2015/5/15",
    "2015/5/16",
    "2015/5/17",
    "2015/5/18",
    "2015/5/19",
    "2015/5/20",
    "2015/5/21",
    "2015/5/22",
    "2015/5/23",
    "2015/5/24",
    "2015/5/25",
    "2015/5/26",
    "2015/5/27",
    "2015/5/28",
    "2015/5/29",
    "2015/5/30",
    "2015/5/31",
    "2015/6/1",
    "2015/6/2",
    "2015/6/3",
    "2015/6/4",
    "2015/6/5",
    "2015/6/6",
    "2015/6/7",
    "2015/6/8",
    "2015/6/9",
    "2015/6/10",
    "2015/6/11",
    "2015/6/12",
    "2015/6/13",
    "2015/6/14",
    "2015/6/15",
    "2015/6/16",
    "2015/6/17",
    "2015/6/18",
    "2015/6/19",
    "2015/6/20",
    "2015/6/21",
    "2015/6/22",
    "2015/6/23",
    "2015/6/24",
    "2015/6/25",
    "2015/6/26",
    "2015/6/27",
    "2015/6/28",
    "2015/6/29",
    "2015/6/30",
    "2015/7/1",
    "2015/7/2",
    "2015/7/3",
    "2015/7/4",
    "2015/7/5",
    "2015/7/6",
    "2015/7/7",
    "2015/7/8",
    "2015/7/9",
    "2015/7/10",
    "2015/7/11",
    "2015/7/12",
    "2015/7/13",
    "2015/7/14",
    "2015/7/15",
    "2015/7/16",
    "2015/7/17",
    "2015/7/18",
    "2015/7/19",
    "2015/7/20",
    "2015/7/21",
    "2015/7/22",
    "2015/7/23",
    "2015/7/24",
    "2015/7/25",
    "2015/7/26",
    "2015/7/27",
    "2015/7/28",
    "2015/7/29",
    "2015/7/30",
    "2015/7/31",
    "2015/8/1",
    "2015/8/2",
    "2015/8/3",
    "2015/8/4",
    "2015/8/5",
    "2015/8/6",
    "2015/8/7",
    "2015/8/8",
    "2015/8/9",
    "2015/8/10",
    "2015/8/11",
    "2015/8/12",
    "2015/8/13",
    "2015/8/14",
    "2015/8/15",
    "2015/8/16",
    "2015/8/17",
    "2015/8/18",
    "2015/8/19",
    "2015/8/20",
    "2015/8/21",
    "2015/8/22",
    "2015/8/23",
    "2015/8/24",
    "2015/8/25",
    "2015/8/26",
    "2015/8/27",
    "2015/8/28",
    "2015/8/29",
    "2015/8/30",
    "2015/8/31",
    "2015/9/1",
    "2015/9/2",
    "2015/9/3",
    "2015/9/4",
    "2015/9/5",
    "2015/9/6",
    "2015/9/7",
    "2015/9/8",
    "2015/9/9",
    "2015/9/10",
    "2015/9/11",
    "2015/9/12",
    "2015/9/13",
    "2015/9/14",
    "2015/9/15",
    "2015/9/16",
    "2015/9/17",
    "2015/9/18",
    "2015/9/19",
    "2015/9/20",
    "2015/9/21",
    "2015/9/22",
    "2015/9/23",
    "2015/9/24",
    "2015/9/25",
    "2015/9/26",
    "2015/9/27",
    "2015/9/28",
    "2015/9/29",
    "2015/9/30",
    "2015/10/1",
    "2015/10/2",
    "2015/10/3",
    "2015/10/4",
    "2015/10/5",
    "2015/10/6",
    "2015/10/7",
    "2015/10/8",
    "2015/10/9",
    "2015/10/10",
    "2015/10/11",
    "2015/10/12",
    "2015/10/13",
    "2015/10/14",
    "2015/10/15",
    "2015/10/16",
    "2015/10/17",
    "2015/10/18",
    "2015/10/19",
    "2015/10/20",
    "2015/10/21",
    "2015/10/22",
    "2015/10/23",
    "2015/10/24",
    "2015/10/25",
    "2015/10/26",
    "2015/10/27",
    "2015/10/28",
    "2015/10/29",
    "2015/10/30",
    "2015/10/31",
    "2015/11/1",
    "2015/11/2",
    "2015/11/3",
    "2015/11/4",
    "2015/11/5",
    "2015/11/6",
    "2015/11/7",
    "2015/11/8",
    "2015/11/9",
    "2015/11/10",
    "2015/11/11",
    "2015/11/12",
    "2015/11/13",
    "2015/11/14",
    "2015/11/15",
    "2015/11/16",
    "2015/11/17",
    "2015/11/18",
    "2015/11/19",
    "2015/11/20",
    "2015/11/21",
    "2015/11/22",
    "2015/11/23",
    "2015/11/24",
    "2015/11/25",
    "2015/11/26",
    "2015/11/27",
    "2015/11/28",
    "2015/11/29",
    "2015/11/30",
    "2015/12/1",
    "2015/12/2",
    "2015/12/3",
    "2015/12/4",
    "2015/12/5",
    "2015/12/6",
    "2015/12/7",
    "2015/12/8",
    "2015/12/9",
    "2015/12/10",
    "2015/12/11",
    "2015/12/12",
    "2015/12/13",
    "2015/12/14",
    "2015/12/15",
    "2015/12/16",
    "2015/12/17",
    "2015/12/18",
    "2015/12/19",
    "2015/12/20",
    "2015/12/21",
    "2015/12/22",
    "2015/12/23",
    "2015/12/24",
    "2015/12/25",
    "2015/12/26",
    "2015/12/27",
    "2015/12/28",
    "2015/12/29",
    "2015/12/30",
    "2015/12/31",
    "2016/1/1",
    "2016/1/2",
    "2016/1/3",
    "2016/1/4",
    "2016/1/5",
    "2016/1/6",
    "2016/1/7",
    "2016/1/8",
    "2016/1/9",
    "2016/1/10",
    "2016/1/11",
    "2016/1/12",
    "2016/1/13",
    "2016/1/14",
    "2016/1/15",
    "2016/1/16",
    "2016/1/17",
    "2016/1/18",
    "2016/1/19",
    "2016/1/20",
    "2016/1/21",
    "2016/1/22",
    "2016/1/23",
    "2016/1/24",
    "2016/1/25",
    "2016/1/26",
    "2016/1/27",
    "2016/1/28",
    "2016/1/29",
    "2016/1/30",
    "2016/1/31",
    "2016/2/1",
    "2016/2/2",
    "2016/2/3",
    "2016/2/4",
    "2016/2/5",
    "2016/2/6",
    "2016/2/7",
    "2016/2/8",
    "2016/2/9",
    "2016/2/10",
    "2016/2/11",
    "2016/2/12",
    "2016/2/13",
    "2016/2/14",
    "2016/2/15",
    "2016/2/16",
    "2016/2/17",
    "2016/2/18",
    "2016/2/19",
    "2016/2/20",
    "2016/2/21",
    "2016/2/22",
    "2016/2/23",
    "2016/2/24",
    "2016/2/25",
    "2016/2/26",
    "2016/2/27",
    "2016/2/28",
    "2016/2/29",
    "2016/3/1",
    "2016/3/2",
    "2016/3/3",
    "2016/3/4",
    "2016/3/5",
    "2016/3/6",
    "2016/3/7",
    "2016/3/8",
    "2016/3/9",
    "2016/3/10",
    "2016/3/11",
    "2016/3/12",
    "2016/3/13",
    "2016/3/14",
    "2016/3/15",
    "2016/3/16",
    "2016/3/17",
    "2016/3/18",
    "2016/3/19",
    "2016/3/20",
    "2016/3/21",
    "2016/3/22",
    "2016/3/23",
    "2016/3/24",
    "2016/3/25",
    "2016/3/26",
    "2016/3/27",
    "2016/3/28",
    "2016/3/29",
    "2016/3/30",
    "2016/3/31",
    "2016/4/1",
    "2016/4/2",
    "2016/4/3",
    "2016/4/4",
    "2016/4/5",
    "2016/4/6",
    "2016/4/7",
    "2016/4/8",
    "2016/4/9",
    "2016/4/10",
    "2016/4/11",
    "2016/4/12",
    "2016/4/13",
    "2016/4/14",
    "2016/4/15",
    "2016/4/16",
    "2016/4/17",
    "2016/4/18",
    "2016/4/19",
    "2016/4/20",
    "2016/4/21",
    "2016/4/22",
    "2016/4/23",
    "2016/4/24",
    "2016/4/25",
    "2016/4/26",
    "2016/4/27",
    "2016/4/28",
    "2016/4/29",
    "2016/4/30",
    "2016/5/1",
    "2016/5/2",
    "2016/5/3",
    "2016/5/4",
    "2016/5/5",
    "2016/5/6",
    "2016/5/7",
    "2016/5/8",
    "2016/5/9",
    "2016/5/10",
    "2016/5/11",
    "2016/5/12",
    "2016/5/13",
    "2016/5/14",
    "2016/5/15",
    "2016/5/16",
    "2016/5/17",
    "2016/5/18",
    "2016/5/19",
    "2016/5/20",
    "2016/5/21",
    "2016/5/22",
    "2016/5/23",
    "2016/5/24",
    "2016/5/25",
    "2016/5/26",
    "2016/5/27",
    "2016/5/28",
    "2016/5/29",
    "2016/5/30",
    "2016/5/31",
    "2016/6/1",
    "2016/6/2",
    "2016/6/3",
    "2016/6/4",
    "2016/6/5",
    "2016/6/6",
    "2016/6/7",
    "2016/6/8",
    "2016/6/9",
    "2016/6/10",
    "2016/6/11",
    "2016/6/12",
    "2016/6/13",
    "2016/6/14",
    "2016/6/15",
    "2016/6/16",
    "2016/6/17",
    "2016/6/18",
    "2016/6/19",
    "2016/6/20",
    "2016/6/21",
    "2016/6/22",
    "2016/6/23",
    "2016/6/24",
    "2016/6/25",
    "2016/6/26",
    "2016/6/27",
    "2016/6/28",
    "2016/6/29",
    "2016/6/30",
    "2016/7/1",
    "2016/7/2",
    "2016/7/3",
    "2016/7/4",
    "2016/7/5",
    "2016/7/6",
    "2016/7/7",
    "2016/7/8",
    "2016/7/9",
    "2016/7/10",
    "2016/7/11",
    "2016/7/12",
    "2016/7/13",
    "2016/7/14",
    "2016/7/15",
    "2016/7/16",
    "2016/7/17",
    "2016/7/18",
    "2016/7/19",
    "2016/7/20",
    "2016/7/21",
    "2016/7/22",
    "2016/7/23",
    "2016/7/24",
    "2016/7/25",
    "2016/7/26",
    "2016/7/27",
    "2016/7/28",
    "2016/7/29",
    "2016/7/30",
    "2016/7/31",
    "2016/8/1",
    "2016/8/2",
    "2016/8/3",
    "2016/8/4",
    "2016/8/5",
    "2016/8/6",
    "2016/8/7",
    "2016/8/8",
    "2016/8/9",
    "2016/8/10",
    "2016/8/11",
    "2016/8/12",
    "2016/8/13",
    "2016/8/14",
    "2016/8/15",
    "2016/8/16",
    "2016/8/17",
    "2016/8/18",
    "2016/8/19",
    "2016/8/20",
    "2016/8/21",
    "2016/8/22",
    "2016/8/23",
    "2016/8/24",
    "2016/8/25",
    "2016/8/26",
    "2016/8/27",
    "2016/8/28",
    "2016/8/29",
    "2016/8/30",
    "2016/8/31",
    "2016/9/1",
    "2016/9/2",
    "2016/9/3",
    "2016/9/4",
    "2016/9/5",
    "2016/9/6",
    "2016/9/7",
    "2016/9/8",
    "2016/9/9",
    "2016/9/10",
    "2016/9/11",
    "2016/9/12",
    "2016/9/13",
    "2016/9/14",
    "2016/9/15",
    "2016/9/16",
    "2016/9/17",
    "2016/9/18",
    "2016/9/19",
    "2016/9/20",
    "2016/9/21",
    "2016/9/22",
    "2016/9/23",
    "2016/9/24",
    "2016/9/25",
    "2016/9/26",
    "2016/9/27",
    "2016/9/28",
    "2016/9/29",
    "2016/9/30",
    "2016/10/1",
    "2016/10/2",
    "2016/10/3",
    "2016/10/4",
    "2016/10/5",
    "2016/10/6",
    "2016/10/7",
    "2016/10/8",
    "2016/10/9",
    "2016/10/10",
    "2016/10/11",
    "2016/10/12",
    "2016/10/13",
    "2016/10/14",
    "2016/10/15",
    "2016/10/16",
    "2016/10/17",
    "2016/10/18",
    "2016/10/19",
    "2016/10/20",
    "2016/10/21",
    "2016/10/22",
    "2016/10/23",
    "2016/10/24",
    "2016/10/25",
    "2016/10/26",
    "2016/10/27",
    "2016/10/28",
    "2016/10/29",
    "2016/10/30",
    "2016/10/31",
    "2016/11/1",
    "2016/11/2",
    "2016/11/3",
    "2016/11/4",
    "2016/11/5",
    "2016/11/6",
    "2016/11/7",
    "2016/11/8",
    "2016/11/9",
    "2016/11/10",
    "2016/11/11",
    "2016/11/12",
    "2016/11/13",
    "2016/11/14",
    "2016/11/15",
    "2016/11/16",
    "2016/11/17",
    "2016/11/18",
    "2016/11/19",
    "2016/11/20",
    "2016/11/21",
    "2016/11/22",
    "2016/11/23",
    "2016/11/24",
    "2016/11/25",
    "2016/11/26",
    "2016/11/27",
    "2016/11/28",
    "2016/11/29",
    "2016/11/30",
    "2016/12/1",
    "2016/12/2",
    "2016/12/3",
    "2016/12/4",
    "2016/12/5",
    "2016/12/6",
    "2016/12/7",
    "2016/12/8",
    "2016/12/9",
    "2016/12/10",
    "2016/12/11",
    "2016/12/12",
    "2016/12/13",
    "2016/12/14",
    "2016/12/15",
    "2016/12/16",
    "2016/12/17",
    "2016/12/18",
    "2016/12/19",
    "2016/12/20",
    "2016/12/21",
    "2016/12/22",
    "2016/12/23",
    "2016/12/24",
    "2016/12/25",
    "2016/12/26",
    "2016/12/27",
    "2016/12/28",
    "2016/12/29",
    "2016/12/30",
    "2016/12/31",
    "2017/1/1",
    "2017/1/2",
    "2017/1/3",
    "2017/1/4",
    "2017/1/5",
    "2017/1/6",
    "2017/1/7",
    "2017/1/8",
    "2017/1/9",
    "2017/1/10",
    "2017/1/11",
    "2017/1/12",
    "2017/1/13",
    "2017/1/14",
    "2017/1/15",
    "2017/1/16",
    "2017/1/17",
    "2017/1/18",
    "2017/1/19",
    "2017/1/20",
    "2017/1/21",
    "2017/1/22",
    "2017/1/23",
    "2017/1/24",
    "2017/1/25",
    "2017/1/26",
    "2017/1/27",
    "2017/1/28",
    "2017/1/29",
    "2017/1/30",
    "2017/1/31",
    "2017/2/1",
    "2017/2/2",
    "2017/2/3",
    "2017/2/4",
    "2017/2/5",
    "2017/2/6",
    "2017/2/7",
    "2017/2/8",
    "2017/2/9",
    "2017/2/10",
    "2017/2/11",
    "2017/2/12",
    "2017/2/13",
    "2017/2/14",
    "2017/2/15",
    "2017/2/16",
    "2017/2/17",
    "2017/2/18",
    "2017/2/19",
    "2017/2/20",
    "2017/2/21",
    "2017/2/22",
    "2017/2/23",
    "2017/2/24",
    "2017/2/25",
    "2017/2/26",
    "2017/2/27",
    "2017/2/28",
    "2017/3/1",
    "2017/3/2",
    "2017/3/3",
    "2017/3/4",
    "2017/3/5",
    "2017/3/6",
    "2017/3/7",
    "2017/3/8",
    "2017/3/9",
    "2017/3/10",
    "2017/3/11",
    "2017/3/12",
    "2017/3/13",
    "2017/3/14",
    "2017/3/15",
    "2017/3/16",
    "2017/3/17",
    "2017/3/18",
    "2017/3/19",
    "2017/3/20",
    "2017/3/21",
    "2017/3/22",
    "2017/3/23",
    "2017/3/24",
    "2017/3/25",
    "2017/3/26",
    "2017/3/27",
    "2017/3/28",
    "2017/3/29",
    "2017/3/30",
    "2017/3/31",
    "2017/4/1",
    "2017/4/2",
    "2017/4/3",
    "2017/4/4",
    "2017/4/5",
    "2017/4/6",
    "2017/4/7",
    "2017/4/8",
    "2017/4/9",
    "2017/4/10",
    "2017/4/11",
    "2017/4/12",
    "2017/4/13",
    "2017/4/14",
    "2017/4/15",
    "2017/4/16",
    "2017/4/17",
    "2017/4/18",
    "2017/4/19",
    "2017/4/20",
    "2017/4/21",
    "2017/4/22",
    "2017/4/23",
    "2017/4/24",
    "2017/4/25",
    "2017/4/26",
    "2017/4/27",
    "2017/4/28",
    "2017/4/29",
    "2017/4/30",
    "2017/5/1",
    "2017/5/2",
    "2017/5/3",
    "2017/5/4",
    "2017/5/5",
    "2017/5/6",
    "2017/5/7",
    "2017/5/8",
    "2017/5/9",
    "2017/5/10",
    "2017/5/11",
    "2017/5/12",
    "2017/5/13",
    "2017/5/14",
    "2017/5/15",
    "2017/5/16",
    "2017/5/17",
    "2017/5/18",
    "2017/5/19",
    "2017/5/20",
    "2017/5/21",
    "2017/5/22",
    "2017/5/23",
    "2017/5/24",
    "2017/5/25",
    "2017/5/26",
    "2017/5/27",
    "2017/5/28",
    "2017/5/29",
    "2017/5/30",
    "2017/5/31",
    "2017/6/1",
    "2017/6/2",
    "2017/6/3",
    "2017/6/4",
    "2017/6/5",
    "2017/6/6",
    "2017/6/7",
    "2017/6/8",
    "2017/6/9",
    "2017/6/10",
    "2017/6/11",
    "2017/6/12",
    "2017/6/13",
    "2017/6/14",
    "2017/6/15",
    "2017/6/16",
    "2017/6/17",
    "2017/6/18",
    "2017/6/19",
    "2017/6/20",
    "2017/6/21",
    "2017/6/22",
    "2017/6/23",
    "2017/6/24",
    "2017/6/25",
    "2017/6/26",
    "2017/6/27",
    "2017/6/28",
    "2017/6/29",
    "2017/6/30",
    "2017/7/1",
    "2017/7/2",
    "2017/7/3",
    "2017/7/4",
    "2017/7/5",
    "2017/7/6",
    "2017/7/7",
    "2017/7/8",
    "2017/7/9",
    "2017/7/10",
    "2017/7/11",
    "2017/7/12",
    "2017/7/13",
    "2017/7/14",
    "2017/7/15",
    "2017/7/16",
    "2017/7/17",
    "2017/7/18",
    "2017/7/19",
    "2017/7/20",
    "2017/7/21",
    "2017/7/22",
    "2017/7/23",
    "2017/7/24",
    "2017/7/25",
    "2017/7/26",
    "2017/7/27",
    "2017/7/28",
    "2017/7/29",
    "2017/7/30",
    "2017/7/31",
    "2017/8/1",
    "2017/8/2",
    "2017/8/3",
    "2017/8/4",
    "2017/8/5",
    "2017/8/6",
    "2017/8/7",
    "2017/8/8",
    "2017/8/9",
    "2017/8/10",
    "2017/8/11",
    "2017/8/12",
    "2017/8/13",
    "2017/8/14",
    "2017/8/15",
    "2017/8/16",
    "2017/8/17",
    "2017/8/18",
    "2017/8/19",
    "2017/8/20",
    "2017/8/21",
    "2017/8/22",
    "2017/8/23",
    "2017/8/24",
    "2017/8/25",
    "2017/8/26",
    "2017/8/27",
    "2017/8/28",
    "2017/8/29",
    "2017/8/30",
    "2017/8/31",
    "2017/9/1",
    "2017/9/2",
    "2017/9/3",
    "2017/9/4",
    "2017/9/5",
    "2017/9/6",
    "2017/9/7",
    "2017/9/8",
    "2017/9/9",
    "2017/9/10",
    "2017/9/11",
    "2017/9/12",
    "2017/9/13",
    "2017/9/14",
    "2017/9/15",
    "2017/9/16",
    "2017/9/17",
    "2017/9/18",
    "2017/9/19",
    "2017/9/20",
    "2017/9/21",
    "2017/9/22",
    "2017/9/23",
    "2017/9/24",
    "2017/9/25",
    "2017/9/26",
    "2017/9/27",
    "2017/9/28",
    "2017/9/29",
    "2017/9/30",
    "2017/10/1",
    "2017/10/2",
    "2017/10/3",
    "2017/10/4",
    "2017/10/5",
    "2017/10/6",
    "2017/10/7",
    "2017/10/8",
    "2017/10/9",
    "2017/10/10",
    "2017/10/11",
    "2017/10/12",
    "2017/10/13",
    "2017/10/14",
    "2017/10/15",
    "2017/10/16",
    "2017/10/17",
    "2017/10/18",
    "2017/10/19",
    "2017/10/20",
    "2017/10/21",
    "2017/10/22",
    "2017/10/23",
    "2017/10/24",
    "2017/10/25",
    "2017/10/26",
    "2017/10/27",
    "2017/10/28",
    "2017/10/29",
    "2017/10/30",
    "2017/10/31",
    "2017/11/1",
    "2017/11/2",
    "2017/11/3",
    "2017/11/4",
    "2017/11/5",
    "2017/11/6",
    "2017/11/7",
    "2017/11/8",
    "2017/11/9",
    "2017/11/10",
    "2017/11/11",
    "2017/11/12",
    "2017/11/13",
    "2017/11/14",
    "2017/11/15",
    "2017/11/16",
    "2017/11/17",
    "2017/11/18",
    "2017/11/19",
    "2017/11/20",
    "2017/11/21",
    "2017/11/22",
    "2017/11/23",
    "2017/11/24",
    "2017/11/25",
    "2017/11/26",
    "2017/11/27",
    "2017/11/28",
    "2017/11/29",
    "2017/11/30",
    "2017/12/1",
    "2017/12/2",
    "2017/12/3",
    "2017/12/4",
    "2017/12/5",
    "2017/12/6",
    "2017/12/7",
    "2017/12/8",
    "2017/12/9",
    "2017/12/10",
    "2017/12/11",
    "2017/12/12",
    "2017/12/13",
    "2017/12/14",
    "2017/12/15",
    "2017/12/16",
    "2017/12/17",
    "2017/12/18",
    "2017/12/19",
    "2017/12/20",
    "2017/12/21",
    "2017/12/22",
    "2017/12/23",
    "2017/12/24",
    "2017/12/25",
    "2017/12/26",
    "2017/12/27",
    "2017/12/28",
    "2017/12/29",
    "2017/12/30",
    "2017/12/31",
    "2018/1/1",
    "2018/1/2",
    "2018/1/3",
    "2018/1/4",
    "2018/1/5",
    "2018/1/6",
    "2018/1/7",
    "2018/1/8",
    "2018/1/9",
    "2018/1/10",
    "2018/1/11",
    "2018/1/12",
    "2018/1/13",
    "2018/1/14",
    "2018/1/15",
    "2018/1/16",
    "2018/1/17",
    "2018/1/18",
    "2018/1/19",
    "2018/1/20",
    "2018/1/21",
    "2018/1/22",
    "2018/1/23",
    "2018/1/24",
    "2018/1/25",
    "2018/1/26",
    "2018/1/27",
    "2018/1/28",
    "2018/1/29",
    "2018/1/30",
    "2018/1/31",
    "2018/2/1",
    "2018/2/2",
    "2018/2/3",
    "2018/2/4",
    "2018/2/5",
    "2018/2/6",
    "2018/2/7",
    "2018/2/8",
    "2018/2/9",
    "2018/2/10",
    "2018/2/11",
    "2018/2/12",
    "2018/2/13",
    "2018/2/14",
    "2018/2/15",
    "2018/2/16",
    "2018/2/17",
    "2018/2/18",
    "2018/2/19",
    "2018/2/20",
    "2018/2/21",
    "2018/2/22",
    "2018/2/23",
    "2018/2/24",
    "2018/2/25",
    "2018/2/26",
    "2018/2/27",
    "2018/2/28",
    "2018/3/1",
    "2018/3/2",
    "2018/3/3",
    "2018/3/4",
    "2018/3/5",
    "2018/3/6",
    "2018/3/7",
    "2018/3/8",
    "2018/3/9",
    "2018/3/10",
    "2018/3/11",
    "2018/3/12",
    "2018/3/13",
    "2018/3/14",
    "2018/3/15",
    "2018/3/16",
    "2018/3/17",
    "2018/3/18",
    "2018/3/19",
    "2018/3/20",
    "2018/3/21",
    "2018/3/22",
    "2018/3/23",
    "2018/3/24",
    "2018/3/25",
    "2018/3/26",
    "2018/3/27",
    "2018/3/28",
    "2018/3/29",
    "2018/3/30",
    "2018/3/31",
    "2018/4/1",
    "2018/4/2",
    "2018/4/3",
    "2018/4/4",
    "2018/4/5",
    "2018/4/6",
    "2018/4/7",
    "2018/4/8",
    "2018/4/9",
    "2018/4/10",
    "2018/4/11",
    "2018/4/12",
    "2018/4/13",
    "2018/4/14",
    "2018/4/15",
    "2018/4/16",
    "2018/4/17",
    "2018/4/18",
    "2018/4/19",
    "2018/4/20",
    "2018/4/21",
    "2018/4/22",
    "2018/4/23",
    "2018/4/24",
    "2018/4/25",
    "2018/4/26",
    "2018/4/27",
    "2018/4/28",
    "2018/4/29",
    "2018/4/30",
    "2018/5/1",
    "2018/5/2",
    "2018/5/3",
    "2018/5/4",
    "2018/5/5",
    "2018/5/6",
    "2018/5/7",
    "2018/5/8",
    "2018/5/9",
    "2018/5/10",
    "2018/5/11",
    "2018/5/12",
    "2018/5/13",
    "2018/5/14",
    "2018/5/15",
    "2018/5/16",
    "2018/5/17",
    "2018/5/18",
    "2018/5/19",
    "2018/5/20",
    "2018/5/21",
    "2018/5/22",
    "2018/5/23",
    "2018/5/24",
    "2018/5/25",
    "2018/5/26",
    "2018/5/27",
    "2018/5/28",
    "2018/5/29",
    "2018/5/30",
    "2018/5/31",
    "2018/6/1",
    "2018/6/2",
    "2018/6/3",
    "2018/6/4",
    "2018/6/5",
    "2018/6/6",
    "2018/6/7",
    "2018/6/8",
    "2018/6/9",
    "2018/6/10",
    "2018/6/11",
    "2018/6/12",
    "2018/6/13",
    "2018/6/14",
    "2018/6/15",
    "2018/6/16",
    "2018/6/17",
    "2018/6/18",
    "2018/6/19",
    "2018/6/20",
    "2018/6/21",
    "2018/6/22",
    "2018/6/23",
    "2018/6/24",
    "2018/6/25",
    "2018/6/26",
    "2018/6/27",
    "2018/6/28",
    "2018/6/29",
    "2018/6/30",
    "2018/7/1",
    "2018/7/2",
    "2018/7/3",
    "2018/7/4",
    "2018/7/5",
    "2018/7/6",
    "2018/7/7",
    "2018/7/8",
    "2018/7/9",
    "2018/7/10",
    "2018/7/11",
    "2018/7/12",
    "2018/7/13",
    "2018/7/14",
    "2018/7/15",
    "2018/7/16",
    "2018/7/17",
    "2018/7/18",
    "2018/7/19",
    "2018/7/20",
    "2018/7/21",
    "2018/7/22",
    "2018/7/23",
    "2018/7/24",
    "2018/7/25",
    "2018/7/26",
    "2018/7/27",
    "2018/7/28",
    "2018/7/29",
    "2018/7/30",
    "2018/7/31",
    "2018/8/1",
    "2018/8/2",
    "2018/8/3",
    "2018/8/4",
    "2018/8/5",
    "2018/8/6",
    "2018/8/7",
    "2018/8/8",
    "2018/8/9",
    "2018/8/10",
    "2018/8/11",
    "2018/8/12",
    "2018/8/13",
    "2018/8/14",
    "2018/8/15",
    "2018/8/16",
    "2018/8/17",
    "2018/8/18",
    "2018/8/19",
    "2018/8/20",
    "2018/8/21",
    "2018/8/22",
    "2018/8/23",
    "2018/8/24",
    "2018/8/25",
    "2018/8/26",
    "2018/8/27",
    "2018/8/28",
    "2018/8/29",
    "2018/8/30",
    "2018/8/31",
    "2018/9/1",
    "2018/9/2",
    "2018/9/3",
    "2018/9/4",
    "2018/9/5",
    "2018/9/6",
    "2018/9/7",
    "2018/9/8",
    "2018/9/9",
    "2018/9/10",
    "2018/9/11",
    "2018/9/12",
    "2018/9/13",
    "2018/9/14",
    "2018/9/15",
    "2018/9/16",
    "2018/9/17",
    "2018/9/18",
    "2018/9/19",
    "2018/9/20",
    "2018/9/21",
    "2018/9/22",
    "2018/9/23",
    "2018/9/24",
    "2018/9/25",
    "2018/9/26",
    "2018/9/27",
    "2018/9/28",
    "2018/9/29",
    "2018/9/30",
    "2018/10/1",
    "2018/10/2",
    "2018/10/3",
    "2018/10/4",
    "2018/10/5",
    "2018/10/6",
    "2018/10/7",
    "2018/10/8",
    "2018/10/9",
    "2018/10/10",
    "2018/10/11",
    "2018/10/12",
    "2018/10/13",
    "2018/10/14",
    "2018/10/15",
    "2018/10/16",
    "2018/10/17",
    "2018/10/18",
    "2018/10/19",
    "2018/10/20",
    "2018/10/21",
    "2018/10/22",
    "2018/10/23",
    "2018/10/24",
    "2018/10/25",
    "2018/10/26",
    "2018/10/27",
    "2018/10/28",
    "2018/10/29",
    "2018/10/30",
    "2018/10/31",
    "2018/11/1",
    "2018/11/2",
    "2018/11/3",
    "2018/11/4",
    "2018/11/5",
    "2018/11/6",
    "2018/11/7",
    "2018/11/8",
    "2018/11/9",
    "2018/11/10",
    "2018/11/11",
    "2018/11/12",
    "2018/11/13",
    "2018/11/14",
    "2018/11/15",
    "2018/11/16",
    "2018/11/17",
    "2018/11/18",
    "2018/11/19",
    "2018/11/20",
    "2018/11/21",
    "2018/11/22",
    "2018/11/23",
    "2018/11/24",
    "2018/11/25",
    "2018/11/26",
    "2018/11/27",
    "2018/11/28",
    "2018/11/29",
    "2018/11/30",
    "2018/12/1",
    "2018/12/2",
    "2018/12/3",
    "2018/12/4",
    "2018/12/5",
    "2018/12/6",
    "2018/12/7",
    "2018/12/8",
    "2018/12/9",
    "2018/12/10",
    "2018/12/11",
    "2018/12/12",
    "2018/12/13",
    "2018/12/14",
    "2018/12/15",
    "2018/12/16",
    "2018/12/17",
    "2018/12/18",
    "2018/12/19",
    "2018/12/20",
    "2018/12/21",
    "2018/12/22",
    "2018/12/23",
    "2018/12/24",
    "2018/12/25",
    "2018/12/26",
    "2018/12/27",
    "2018/12/28",
    "2018/12/29",
    "2018/12/30",
    "2018/12/31",
    "2019/1/1",
    "2019/1/2",
    "2019/1/3",
    "2019/1/4",
    "2019/1/5",
    "2019/1/6",
    "2019/1/7",
    "2019/1/8",
    "2019/1/9",
    "2019/1/10",
    "2019/1/11",
    "2019/1/12",
    "2019/1/13",
    "2019/1/14",
    "2019/1/15",
    "2019/1/16",
    "2019/1/17",
    "2019/1/18",
    "2019/1/19",
    "2019/1/20",
    "2019/1/21",
    "2019/1/22",
    "2019/1/23",
    "2019/1/24",
    "2019/1/25",
    "2019/1/26",
    "2019/1/27",
    "2019/1/28",
    "2019/1/29",
    "2019/1/30",
    "2019/1/31",
    "2019/2/1",
    "2019/2/2",
    "2019/2/3",
    "2019/2/4",
    "2019/2/5",
    "2019/2/6",
    "2019/2/7",
    "2019/2/8",
    "2019/2/9",
    "2019/2/10",
    "2019/2/11",
    "2019/2/12",
    "2019/2/13",
    "2019/2/14",
    "2019/2/15",
    "2019/2/16",
    "2019/2/17",
    "2019/2/18",
    "2019/2/19",
    "2019/2/20",
    "2019/2/21",
    "2019/2/22",
    "2019/2/23",
    "2019/2/24",
    "2019/2/25",
    "2019/2/26",
    "2019/2/27",
    "2019/2/28",
    "2019/3/1",
    "2019/3/2",
    "2019/3/3",
    "2019/3/4",
    "2019/3/5",
    "2019/3/6",
    "2019/3/7",
    "2019/3/8",
    "2019/3/9",
    "2019/3/10",
    "2019/3/11",
    "2019/3/12",
    "2019/3/13",
    "2019/3/14",
    "2019/3/15",
    "2019/3/16",
    "2019/3/17",
    "2019/3/18",
    "2019/3/19",
    "2019/3/20",
    "2019/3/21",
    "2019/3/22",
    "2019/3/23",
    "2019/3/24",
    "2019/3/25",
    "2019/3/26",
    "2019/3/27",
    "2019/3/28",
    "2019/3/29",
    "2019/3/30",
    "2019/3/31",
    "2019/4/1",
    "2019/4/2",
    "2019/4/3",
    "2019/4/4",
    "2019/4/5",
    "2019/4/6",
    "2019/4/7",
    "2019/4/8",
    "2019/4/9",
    "2019/4/10",
    "2019/4/11",
    "2019/4/12",
    "2019/4/13",
    "2019/4/14",
    "2019/4/15",
    "2019/4/16",
    "2019/4/17",
    "2019/4/18",
    "2019/4/19",
    "2019/4/20",
    "2019/4/21",
    "2019/4/22",
    "2019/4/23",
    "2019/4/24",
    "2019/4/25",
    "2019/4/26",
    "2019/4/27",
    "2019/4/28",
    "2019/4/29",
    "2019/4/30",
    "2019/5/1",
    "2019/5/2",
    "2019/5/3",
    "2019/5/4",
    "2019/5/5",
    "2019/5/6",
    "2019/5/7",
    "2019/5/8",
    "2019/5/9",
    "2019/5/10",
    "2019/5/11",
    "2019/5/12",
    "2019/5/13",
    "2019/5/14",
    "2019/5/15",
    "2019/5/16",
    "2019/5/17",
    "2019/5/18",
    "2019/5/19",
    "2019/5/20",
    "2019/5/21",
    "2019/5/22",
    "2019/5/23",
    "2019/5/24",
    "2019/5/25",
    "2019/5/26",
    "2019/5/27",
    "2019/5/28",
    "2019/5/29",
    "2019/5/30",
    "2019/5/31",
    "2019/6/1",
    "2019/6/2",
    "2019/6/3",
    "2019/6/4",
    "2019/6/5",
    "2019/6/6",
    "2019/6/7",
    "2019/6/8",
    "2019/6/9",
    "2019/6/10",
    "2019/6/11",
    "2019/6/12",
    "2019/6/13",
    "2019/6/14",
    "2019/6/15",
    "2019/6/16",
    "2019/6/17",
    "2019/6/18",
    "2019/6/19",
    "2019/6/20",
    "2019/6/21",
    "2019/6/22",
    "2019/6/23",
    "2019/6/24",
    "2019/6/25",
    "2019/6/26",
    "2019/6/27",
    "2019/6/28",
    "2019/6/29",
    "2019/6/30",
    "2019/7/1",
    "2019/7/2",
    "2019/7/3",
    "2019/7/4",
    "2019/7/5",
    "2019/7/6",
    "2019/7/7",
    "2019/7/8",
    "2019/7/9",
    "2019/7/10",
    "2019/7/11",
    "2019/7/12",
    "2019/7/13",
    "2019/7/14",
    "2019/7/15",
    "2019/7/16",
    "2019/7/17",
    "2019/7/18",
    "2019/7/19",
    "2019/7/20",
    "2019/7/21",
    "2019/7/22",
    "2019/7/23",
    "2019/7/24",
    "2019/7/25",
    "2019/7/26",
    "2019/7/27",
    "2019/7/28",
    "2019/7/29",
    "2019/7/30",
    "2019/7/31",
    "2019/8/1",
    "2019/8/2",
    "2019/8/3",
    "2019/8/4",
    "2019/8/5",
    "2019/8/6",
    "2019/8/7",
    "2019/8/8",
    "2019/8/9",
    "2019/8/10",
    "2019/8/11",
    "2019/8/12",
    "2019/8/13",
    "2019/8/14",
    "2019/8/15",
    "2019/8/16",
    "2019/8/17",
    "2019/8/18",
    "2019/8/19",
    "2019/8/20",
    "2019/8/21",
    "2019/8/22",
    "2019/8/23",
    "2019/8/24",
    "2019/8/25",
    "2019/8/26",
    "2019/8/27",
    "2019/8/28",
    "2019/8/29",
    "2019/8/30",
    "2019/8/31",
    "2019/9/1",
    "2019/9/2",
    "2019/9/3",
    "2019/9/4",
    "2019/9/5",
    "2019/9/6",
    "2019/9/7",
    "2019/9/8",
    "2019/9/9",
    "2019/9/10",
    "2019/9/11",
    "2019/9/12",
    "2019/9/13",
    "2019/9/14",
    "2019/9/15",
    "2019/9/16",
    "2019/9/17",
    "2019/9/18",
    "2019/9/19",
    "2019/9/20",
    "2019/9/21",
    "2019/9/22",
    "2019/9/23",
    "2019/9/24",
    "2019/9/25",
    "2019/9/26",
    "2019/9/27",
    "2019/9/28",
    "2019/9/29",
    "2019/9/30",
    "2019/10/1",
    "2019/10/2",
    "2019/10/3",
    "2019/10/4",
    "2019/10/5",
    "2019/10/6",
    "2019/10/7",
    "2019/10/8",
    "2019/10/9",
    "2019/10/10",
    "2019/10/11",
    "2019/10/12",
    "2019/10/13",
    "2019/10/14",
    "2019/10/15",
    "2019/10/16",
    "2019/10/17",
    "2019/10/18",
    "2019/10/19",
    "2019/10/20",
    "2019/10/21",
    "2019/10/22",
    "2019/10/23",
    "2019/10/24",
    "2019/10/25",
    "2019/10/26",
    "2019/10/27",
    "2019/10/28",
    "2019/10/29",
    "2019/10/30",
    "2019/10/31",
    "2019/11/1",
    "2019/11/2",
    "2019/11/3",
    "2019/11/4",
    "2019/11/5",
    "2019/11/6",
    "2019/11/7",
    "2019/11/8",
    "2019/11/9",
    "2019/11/10",
    "2019/11/11",
    "2019/11/12",
    "2019/11/13",
    "2019/11/14",
    "2019/11/15",
    "2019/11/16",
    "2019/11/17",
    "2019/11/18",
    "2019/11/19",
    "2019/11/20",
    "2019/11/21",
    "2019/11/22",
    "2019/11/23",
    "2019/11/24",
    "2019/11/25",
    "2019/11/26",
    "2019/11/27",
    "2019/11/28",
    "2019/11/29",
    "2019/11/30",
    "2019/12/1",
    "2019/12/2",
    "2019/12/3",
    "2019/12/4",
    "2019/12/5",
    "2019/12/6",
    "2019/12/7",
    "2019/12/8",
    "2019/12/9",
    "2019/12/10",
    "2019/12/11",
    "2019/12/12",
    "2019/12/13",
    "2019/12/14",
    "2019/12/15",
    "2019/12/16",
    "2019/12/17",
    "2019/12/18",
    "2019/12/19",
    "2019/12/20",
    "2019/12/21",
    "2019/12/22",
    "2019/12/23",
    "2019/12/24",
    "2019/12/25",
    "2019/12/26",
    "2019/12/27",
    "2019/12/28",
    "2019/12/29",
    "2019/12/30",
    "2019/12/31",
    "2020/1/1",
    "2020/1/2",
    "2020/1/3",
    "2020/1/4",
    "2020/1/5",
    "2020/1/6",
    "2020/1/7",
    "2020/1/8",
    "2020/1/9",
    "2020/1/10",
    "2020/1/11",
    "2020/1/12",
    "2020/1/13",
    "2020/1/14",
    "2020/1/15",
    "2020/1/16",
    "2020/1/17",
    "2020/1/18",
    "2020/1/19",
    "2020/1/20",
    "2020/1/21",
    "2020/1/22",
    "2020/1/23",
    "2020/1/24",
    "2020/1/25",
    "2020/1/26",
    "2020/1/27",
    "2020/1/28",
    "2020/1/29",
    "2020/1/30",
    "2020/1/31",
    "2020/2/1",
    "2020/2/2",
    "2020/2/3",
    "2020/2/4",
    "2020/2/5",
    "2020/2/6",
    "2020/2/7",
    "2020/2/8",
    "2020/2/9",
    "2020/2/10",
    "2020/2/11",
    "2020/2/12",
    "2020/2/13",
    "2020/2/14",
    "2020/2/15",
    "2020/2/16",
    "2020/2/17",
    "2020/2/18",
    "2020/2/19",
    "2020/2/20",
    "2020/2/21",
    "2020/2/22",
    "2020/2/23",
    "2020/2/24",
    "2020/2/25",
    "2020/2/26",
    "2020/2/27",
    "2020/2/28",
    "2020/2/29",
    "2020/3/1",
    "2020/3/2",
    "2020/3/3",
    "2020/3/4",
    "2020/3/5",
    "2020/3/6",
    "2020/3/7",
    "2020/3/8",
    "2020/3/9",
    "2020/3/10",
    "2020/3/11",
    "2020/3/12",
    "2020/3/13",
    "2020/3/14",
    "2020/3/15",
    "2020/3/16",
    "2020/3/17",
    "2020/3/18",
    "2020/3/19",
    "2020/3/20",
    "2020/3/21",
    "2020/3/22",
    "2020/3/23",
    "2020/3/24",
    "2020/3/25",
    "2020/3/26",
    "2020/3/27",
    "2020/3/28",
    "2020/3/29",
    "2020/3/30",
    "2020/3/31",
    "2020/4/1",
    "2020/4/2",
    "2020/4/3",
    "2020/4/4",
    "2020/4/5",
    "2020/4/6",
    "2020/4/7",
    "2020/4/8",
    "2020/4/9",
    "2020/4/10",
    "2020/4/11",
    "2020/4/12",
    "2020/4/13",
    "2020/4/14",
    "2020/4/15",
    "2020/4/16",
    "2020/4/17",
    "2020/4/18",
    "2020/4/19",
    "2020/4/20",
    "2020/4/21",
    "2020/4/22",
    "2020/4/23",
    "2020/4/24",
    "2020/4/25",
    "2020/4/26",
    "2020/4/27",
    "2020/4/28",
    "2020/4/29",
    "2020/4/30",
    "2020/5/1",
    "2020/5/2",
    "2020/5/3",
    "2020/5/4",
    "2020/5/5",
    "2020/5/6",
    "2020/5/7",
    "2020/5/8",
    "2020/5/9",
    "2020/5/10",
    "2020/5/11",
    "2020/5/12",
    "2020/5/13",
    "2020/5/14",
    "2020/5/15",
    "2020/5/16",
    "2020/5/17",
    "2020/5/18",
    "2020/5/19",
    "2020/5/20",
    "2020/5/21",
    "2020/5/22",
    "2020/5/23",
    "2020/5/24",
    "2020/5/25",
    "2020/5/26",
    "2020/5/27",
    "2020/5/28",
    "2020/5/29",
    "2020/5/30",
    "2020/5/31",
    "2020/6/1",
    "2020/6/2",
    "2020/6/3",
    "2020/6/4",
    "2020/6/5",
    "2020/6/6",
    "2020/6/7",
    "2020/6/8",
    "2020/6/9",
    "2020/6/10",
    "2020/6/11",
    "2020/6/12",
    "2020/6/13",
    "2020/6/14",
    "2020/6/15",
    "2020/6/16",
    "2020/6/17",
    "2020/6/18",
    "2020/6/19",
    "2020/6/20",
    "2020/6/21",
    "2020/6/22",
    "2020/6/23",
    "2020/6/24",
    "2020/6/25",
    "2020/6/26",
    "2020/6/27",
    "2020/6/28",
    "2020/6/29",
    "2020/6/30",
    "2020/7/1",
    "2020/7/2",
    "2020/7/3",
    "2020/7/4",
    "2020/7/5",
    "2020/7/6",
    "2020/7/7",
    "2020/7/8",
    "2020/7/9",
    "2020/7/10",
    "2020/7/11",
    "2020/7/12",
    "2020/7/13",
    "2020/7/14",
    "2020/7/15",
    "2020/7/16",
    "2020/7/17",
    "2020/7/18",
    "2020/7/19",
    "2020/7/20",
    "2020/7/21",
    "2020/7/22",
    "2020/7/23",
    "2020/7/24",
    "2020/7/25",
    "2020/7/26",
    "2020/7/27",
    "2020/7/28",
    "2020/7/29",
    "2020/7/30",
    "2020/7/31",
    "2020/8/1",
    "2020/8/2",
    "2020/8/3",
    "2020/8/4",
    "2020/8/5",
    "2020/8/6",
    "2020/8/7",
    "2020/8/8",
    "2020/8/9",
    "2020/8/10",
    "2020/8/11",
    "2020/8/12",
    "2020/8/13",
    "2020/8/14",
    "2020/8/15",
    "2020/8/16",
    "2020/8/17",
    "2020/8/18",
    "2020/8/19",
    "2020/8/20",
    "2020/8/21",
    "2020/8/22",
    "2020/8/23",
    "2020/8/24",
    "2020/8/25",
    "2020/8/26",
    "2020/8/27",
    "2020/8/28",
    "2020/8/29",
    "2020/8/30",
    "2020/8/31",
    "2020/9/1",
    "2020/9/2",
    "2020/9/3",
    "2020/9/4",
    "2020/9/5",
    "2020/9/6",
    "2020/9/7",
    "2020/9/8",
    "2020/9/9",
    "2020/9/10",
    "2020/9/11",
    "2020/9/12",
    "2020/9/13",
    "2020/9/14",
    "2020/9/15",
    "2020/9/16",
    "2020/9/17",
    "2020/9/18",
    "2020/9/19",
    "2020/9/20",
    "2020/9/21",
    "2020/9/22",
    "2020/9/23",
    "2020/9/24",
    "2020/9/25",
    "2020/9/26",
    "2020/9/27",
    "2020/9/28",
    "2020/9/29",
    "2020/9/30",
    "2020/10/1",
    "2020/10/2",
    "2020/10/3",
    "2020/10/4",
    "2020/10/5",
    "2020/10/6",
    "2020/10/7",
    "2020/10/8",
    "2020/10/9",
    "2020/10/10",
    "2020/10/11",
    "2020/10/12",
    "2020/10/13",
    "2020/10/14",
    "2020/10/15",
    "2020/10/16",
    "2020/10/17",
    "2020/10/18",
    "2020/10/19",
    "2020/10/20",
    "2020/10/21",
    "2020/10/22",
    "2020/10/23",
    "2020/10/24",
    "2020/10/25",
    "2020/10/26",
    "2020/10/27",
    "2020/10/28",
    "2020/10/29",
    "2020/10/30",
    "2020/10/31",
    "2020/11/1",
    "2020/11/2",
    "2020/11/3",
    "2020/11/4",
    "2020/11/5",
    "2020/11/6",
    "2020/11/7",
    "2020/11/8",
    "2020/11/9",
    "2020/11/10",
    "2020/11/11",
    "2020/11/12",
    "2020/11/13",
    "2020/11/14",
    "2020/11/15",
    "2020/11/16",
    "2020/11/17",
    "2020/11/18",
    "2020/11/19",
    "2020/11/20",
    "2020/11/21",
    "2020/11/22",
    "2020/11/23",
    "2020/11/24",
    "2020/11/25",
    "2020/11/26",
    "2020/11/27",
    "2020/11/28",
    "2020/11/29",
    "2020/11/30",
    "2020/12/1",
    "2020/12/2",
    "2020/12/3",
    "2020/12/4",
    "2020/12/5",
    "2020/12/6",
    "2020/12/7",
    "2020/12/8",
    "2020/12/9",
    "2020/12/10",
    "2020/12/11",
    "2020/12/12",
    "2020/12/13",
    "2020/12/14",
    "2020/12/15",
    "2020/12/16",
    "2020/12/17",
    "2020/12/18",
    "2020/12/19",
    "2020/12/20",
    "2020/12/21",
    "2020/12/22",
    "2020/12/23",
    "2020/12/24",
    "2020/12/25",
    "2020/12/26",
    "2020/12/27",
    "2020/12/28",
    "2020/12/29",
    "2020/12/30",
    "2020/12/31",
    "2021/1/1",
    "2021/1/2",
    "2021/1/3",
    "2021/1/4",
    "2021/1/5",
    "2021/1/6",
    "2021/1/7",
    "2021/1/8",
    "2021/1/9",
    "2021/1/10",
    "2021/1/11",
    "2021/1/12",
    "2021/1/13",
    "2021/1/14",
    "2021/1/15",
    "2021/1/16",
    "2021/1/17",
    "2021/1/18",
    "2021/1/19",
    "2021/1/20",
    "2021/1/21",
    "2021/1/22",
    "2021/1/23",
    "2021/1/24",
    "2021/1/25",
    "2021/1/26",
    "2021/1/27",
    "2021/1/28",
    "2021/1/29",
    "2021/1/30",
    "2021/1/31",
    "2021/2/1",
    "2021/2/2",
    "2021/2/3",
    "2021/2/4",
    "2021/2/5",
    "2021/2/6",
    "2021/2/7",
    "2021/2/8",
    "2021/2/9",
    "2021/2/10",
    "2021/2/11",
    "2021/2/12",
    "2021/2/13",
    "2021/2/14",
    "2021/2/15",
    "2021/2/16",
    "2021/2/17",
    "2021/2/18",
    "2021/2/19",
    "2021/2/20",
    "2021/2/21",
    "2021/2/22",
    "2021/2/23",
    "2021/2/24",
    "2021/2/25",
    "2021/2/26",
    "2021/2/27",
    "2021/2/28",
    "2021/3/1",
    "2021/3/2",
    "2021/3/3",
    "2021/3/4",
    "2021/3/5",
    "2021/3/6",
    "2021/3/7",
    "2021/3/8",
    "2021/3/9",
    "2021/3/10",
    "2021/3/11",
    "2021/3/12",
    "2021/3/13",
    "2021/3/14",
    "2021/3/15",
    "2021/3/16",
    "2021/3/17",
    "2021/3/18",
    "2021/3/19",
    "2021/3/20",
    "2021/3/21",
    "2021/3/22",
    "2021/3/23",
    "2021/3/24",
    "2021/3/25",
    "2021/3/26",
    "2021/3/27",
    "2021/3/28",
    "2021/3/29",
    "2021/3/30",
    "2021/3/31",
    "2021/4/1",
    "2021/4/2",
    "2021/4/3",
    "2021/4/4",
    "2021/4/5",
    "2021/4/6",
    "2021/4/7",
    "2021/4/8",
    "2021/4/9",
    "2021/4/10",
    "2021/4/11",
    "2021/4/12",
    "2021/4/13",
    "2021/4/14",
    "2021/4/15",
    "2021/4/16",
    "2021/4/17",
    "2021/4/18",
    "2021/4/19",
    "2021/4/20",
    "2021/4/21",
    "2021/4/22",
    "2021/4/23",
    "2021/4/24",
    "2021/4/25",
    "2021/4/26",
    "2021/4/27",
    "2021/4/28",
    "2021/4/29",
    "2021/4/30",
    "2021/5/1",
    "2021/5/2",
    "2021/5/3",
    "2021/5/4",
    "2021/5/5",
    "2021/5/6",
    "2021/5/7",
    "2021/5/8",
    "2021/5/9",
    "2021/5/10",
    "2021/5/11",
    "2021/5/12",
    "2021/5/13",
    "2021/5/14",
    "2021/5/15",
    "2021/5/16",
    "2021/5/17",
    "2021/5/18",
    "2021/5/19",
    "2021/5/20",
    "2021/5/21",
    "2021/5/22",
    "2021/5/23",
    "2021/5/24",
    "2021/5/25",
    "2021/5/26",
    "2021/5/27",
    "2021/5/28",
    "2021/5/29",
    "2021/5/30",
    "2021/5/31",
    "2021/6/1",
    "2021/6/2",
    "2021/6/3",
    "2021/6/4",
    "2021/6/5",
    "2021/6/6",
    "2021/6/7",
    "2021/6/8",
    "2021/6/9",
    "2021/6/10",
    "2021/6/11",
    "2021/6/12",
    "2021/6/13",
    "2021/6/14",
    "2021/6/15",
    "2021/6/16",
    "2021/6/17",
    "2021/6/18",
    "2021/6/19",
    "2021/6/20",
    "2021/6/21",
    "2021/6/22",
    "2021/6/23",
    "2021/6/24",
    "2021/6/25",
    "2021/6/26",
    "2021/6/27",
    "2021/6/28",
    "2021/6/29",
    "2021/6/30",
    "2021/7/1",
    "2021/7/2",
    "2021/7/3",
    "2021/7/4",
    "2021/7/5",
    "2021/7/6",
    "2021/7/7",
    "2021/7/8",
    "2021/7/9",
    "2021/7/10",
    "2021/7/11",
    "2021/7/12",
    "2021/7/13",
    "2021/7/14",
    "2021/7/15",
    "2021/7/16",
    "2021/7/17",
    "2021/7/18",
    "2021/7/19",
    "2021/7/20",
    "2021/7/21",
    "2021/7/22",
    "2021/7/23",
    "2021/7/24",
    "2021/7/25",
    "2021/7/26",
    "2021/7/27",
    "2021/7/28",
    "2021/7/29",
    "2021/7/30",
    "2021/7/31",
    "2021/8/1",
    "2021/8/2",
    "2021/8/3",
    "2021/8/4",
    "2021/8/5",
    "2021/8/6",
    "2021/8/7",
    "2021/8/8",
    "2021/8/9",
    "2021/8/10",
    "2021/8/11",
    "2021/8/12",
    "2021/8/13",
    "2021/8/14",
    "2021/8/15",
    "2021/8/16",
    "2021/8/17",
    "2021/8/18",
    "2021/8/19",
    "2021/8/20",
    "2021/8/21",
    "2021/8/22",
    "2021/8/23",
    "2021/8/24",
    "2021/8/25",
    "2021/8/26",
    "2021/8/27",
    "2021/8/28",
    "2021/8/29",
    "2021/8/30",
    "2021/8/31",
    "2021/9/1",
    "2021/9/2",
    "2021/9/3",
    "2021/9/4",
    "2021/9/5",
    "2021/9/6",
    "2021/9/7",
    "2021/9/8",
    "2021/9/9",
    "2021/9/10",
    "2021/9/11",
    "2021/9/12",
    "2021/9/13",
    "2021/9/14",
    "2021/9/15",
    "2021/9/16",
    "2021/9/17",
    "2021/9/18",
    "2021/9/19",
    "2021/9/20",
    "2021/9/21",
    "2021/9/22",
    "2021/9/23",
    "2021/9/24",
    "2021/9/25",
    "2021/9/26",
    "2021/9/27",
    "2021/9/28",
    "2021/9/29",
    "2021/9/30",
    "2021/10/1",
    "2021/10/2",
    "2021/10/3",
    "2021/10/4",
    "2021/10/5",
    "2021/10/6",
    "2021/10/7",
    "2021/10/8",
    "2021/10/9",
    "2021/10/10",
    "2021/10/11",
    "2021/10/12",
    "2021/10/13",
    "2021/10/14",
    "2021/10/15",
    "2021/10/16",
    "2021/10/17",
    "2021/10/18",
    "2021/10/19",
    "2021/10/20",
    "2021/10/21",
    "2021/10/22",
    "2021/10/23",
    "2021/10/24",
    "2021/10/25",
    "2021/10/26",
    "2021/10/27",
    "2021/10/28",
    "2021/10/29",
    "2021/10/30",
    "2021/10/31",
    "2021/11/1",
    "2021/11/2",
    "2021/11/3",
    "2021/11/4",
    "2021/11/5",
    "2021/11/6",
    "2021/11/7",
    "2021/11/8",
    "2021/11/9",
    "2021/11/10",
    "2021/11/11",
    "2021/11/12",
    "2021/11/13",
    "2021/11/14",
    "2021/11/15",
    "2021/11/16",
    "2021/11/17",
    "2021/11/18",
    "2021/11/19",
    "2021/11/20",
    "2021/11/21",
    "2021/11/22",
    "2021/11/23",
    "2021/11/24",
    "2021/11/25",
    "2021/11/26",
    "2021/11/27",
    "2021/11/28",
    "2021/11/29",
    "2021/11/30",
    "2021/12/1",
    "2021/12/2",
    "2021/12/3",
    "2021/12/4",
    "2021/12/5",
    "2021/12/6",
    "2021/12/7",
    "2021/12/8",
    "2021/12/9",
    "2021/12/10",
    "2021/12/11",
    "2021/12/12",
    "2021/12/13",
    "2021/12/14",
    "2021/12/15",
    "2021/12/16",
    "2021/12/17",
    "2021/12/18",
    "2021/12/19",
    "2021/12/20",
    "2021/12/21",
    "2021/12/22",
    "2021/12/23",
    "2021/12/24",
    "2021/12/25",
    "2021/12/26",
    "2021/12/27",
    "2021/12/28",
    "2021/12/29",
    "2021/12/30",
    "2021/12/31",
    "2022/1/1",
    "2022/1/2",
    "2022/1/3",
    "2022/1/4",
    "2022/1/5",
    "2022/1/6",
    "2022/1/7",
    "2022/1/8",
    "2022/1/9",
    "2022/1/10",
    "2022/1/11",
    "2022/1/12",
    "2022/1/13",
    "2022/1/14",
    "2022/1/15",
    "2022/1/16",
    "2022/1/17",
    "2022/1/18",
    "2022/1/19",
    "2022/1/20",
    "2022/1/21",
    "2022/1/22",
    "2022/1/23",
    "2022/1/24",
    "2022/1/25",
    "2022/1/26",
    "2022/1/27",
    "2022/1/28",
    "2022/1/29",
    "2022/1/30",
    "2022/1/31",
    "2022/2/1",
    "2022/2/2",
    "2022/2/3",
    "2022/2/4",
    "2022/2/5",
    "2022/2/6",
    "2022/2/7",
    "2022/2/8",
    "2022/2/9",
    "2022/2/10",
    "2022/2/11",
    "2022/2/12",
    "2022/2/13",
    "2022/2/14",
    "2022/2/15",
    "2022/2/16",
    "2022/2/17",
    "2022/2/18",
    "2022/2/19",
    "2022/2/20",
    "2022/2/21",
    "2022/2/22",
    "2022/2/23",
    "2022/2/24",
    "2022/2/25",
    "2022/2/26",
    "2022/2/27",
    "2022/2/28",
    "2022/3/1",
    "2022/3/2",
    "2022/3/3",
    "2022/3/4",
    "2022/3/5",
    "2022/3/6",
    "2022/3/7",
    "2022/3/8",
    "2022/3/9",
    "2022/3/10",
    "2022/3/11",
    "2022/3/12",
    "2022/3/13",
    "2022/3/14",
    "2022/3/15",
    "2022/3/16",
    "2022/3/17",
    "2022/3/18",
    "2022/3/19",
    "2022/3/20",
    "2022/3/21",
    "2022/3/22",
    "2022/3/23",
    "2022/3/24",
    "2022/3/25",
    "2022/3/26",
    "2022/3/27",
    "2022/3/28",
    "2022/3/29",
    "2022/3/30",
    "2022/3/31",
    "2022/4/1",
    "2022/4/2",
    "2022/4/3",
    "2022/4/4",
    "2022/4/5",
    "2022/4/6",
    "2022/4/7",
    "2022/4/8",
    "2022/4/9",
    "2022/4/10",
    "2022/4/11",
    "2022/4/12",
    "2022/4/13",
    "2022/4/14",
    "2022/4/15",
    "2022/4/16",
    "2022/4/17",
    "2022/4/18",
    "2022/4/19",
    "2022/4/20",
    "2022/4/21",
    "2022/4/22",
    "2022/4/23",
    "2022/4/24",
    "2022/4/25",
    "2022/4/26",
    "2022/4/27",
    "2022/4/28",
    "2022/4/29",
    "2022/4/30",
    "2022/5/1",
    "2022/5/2",
    "2022/5/3",
    "2022/5/4",
    "2022/5/5",
    "2022/5/6",
    "2022/5/7",
    "2022/5/8",
    "2022/5/9",
    "2022/5/10",
    "2022/5/11",
    "2022/5/12",
    "2022/5/13",
    "2022/5/14",
    "2022/5/15",
    "2022/5/16",
    "2022/5/17",
    "2022/5/18",
    "2022/5/19",
    "2022/5/20",
    "2022/5/21",
    "2022/5/22",
    "2022/5/23",
    "2022/5/24",
    "2022/5/25",
    "2022/5/26",
    "2022/5/27",
    "2022/5/28",
    "2022/5/29",
    "2022/5/30",
    "2022/5/31",
    "2022/6/1",
    "2022/6/2",
    "2022/6/3",
    "2022/6/4",
    "2022/6/5",
    "2022/6/6",
    "2022/6/7",
    "2022/6/8",
    "2022/6/9",
    "2022/6/10",
    "2022/6/11",
    "2022/6/12",
    "2022/6/13",
    "2022/6/14",
    "2022/6/15",
    "2022/6/16",
    "2022/6/17",
    "2022/6/18",
    "2022/6/19",
    "2022/6/20",
    "2022/6/21",
    "2022/6/22",
    "2022/6/23",
    "2022/6/24",
    "2022/6/25",
    "2022/6/26",
    "2022/6/27",
    "2022/6/28",
    "2022/6/29",
    "2022/6/30",
    "2022/7/1",
    "2022/7/2",
    "2022/7/3",
    "2022/7/4",
    "2022/7/5",
    "2022/7/6",
    "2022/7/7",
    "2022/7/8",
    "2022/7/9",
    "2022/7/10",
    "2022/7/11",
    "2022/7/12",
    "2022/7/13",
    "2022/7/14",
    "2022/7/15",
    "2022/7/16",
    "2022/7/17",
    "2022/7/18",
    "2022/7/19",
    "2022/7/20",
    "2022/7/21",
    "2022/7/22",
    "2022/7/23",
    "2022/7/24",
    "2022/7/25",
    "2022/7/26",
    "2022/7/27",
    "2022/7/28",
    "2022/7/29",
    "2022/7/30",
    "2022/7/31",
    "2022/8/1",
    "2022/8/2",
    "2022/8/3",
    "2022/8/4",
    "2022/8/5",
    "2022/8/6",
    "2022/8/7",
    "2022/8/8",
    "2022/8/9",
    "2022/8/10",
    "2022/8/11",
    "2022/8/12",
    "2022/8/13",
    "2022/8/14",
    "2022/8/15",
    "2022/8/16",
    "2022/8/17",
    "2022/8/18",
    "2022/8/19",
    "2022/8/20",
    "2022/8/21",
    "2022/8/22",
    "2022/8/23",
    "2022/8/24",
    "2022/8/25",
    "2022/8/26",
    "2022/8/27",
    "2022/8/28",
    "2022/8/29",
    "2022/8/30",
    "2022/8/31",
    "2022/9/1",
    "2022/9/2",
    "2022/9/3",
    "2022/9/4",
    "2022/9/5",
    "2022/9/6",
    "2022/9/7",
    "2022/9/8",
    "2022/9/9",
    "2022/9/10",
    "2022/9/11",
    "2022/9/12",
    "2022/9/13",
    "2022/9/14",
    "2022/9/15",
    "2022/9/16",
    "2022/9/17",
    "2022/9/18",
    "2022/9/19",
    "2022/9/20",
    "2022/9/21",
    "2022/9/22",
    "2022/9/23",
    "2022/9/24",
    "2022/9/25",
    "2022/9/26",
    "2022/9/27",
    "2022/9/28",
    "2022/9/29",
    "2022/9/30",
    "2022/10/1",
    "2022/10/2",
    "2022/10/3",
    "2022/10/4",
    "2022/10/5",
    "2022/10/6",
    "2022/10/7",
    "2022/10/8",
    "2022/10/9",
    "2022/10/10",
    "2022/10/11",
    "2022/10/12",
    "2022/10/13",
    "2022/10/14",
    "2022/10/15",
    "2022/10/16",
    "2022/10/17",
    "2022/10/18",
    "2022/10/19",
    "2022/10/20",
    "2022/10/21",
    "2022/10/22",
    "2022/10/23",
    "2022/10/24",
    "2022/10/25",
    "2022/10/26",
    "2022/10/27",
    "2022/10/28",
    "2022/10/29",
    "2022/10/30",
    "2022/10/31",
    "2022/11/1",
    "2022/11/2",
    "2022/11/3",
    "2022/11/4",
    "2022/11/5",
    "2022/11/6",
    "2022/11/7",
    "2022/11/8",
    "2022/11/9",
    "2022/11/10",
    "2022/11/11",
    "2022/11/12",
    "2022/11/13",
    "2022/11/14",
    "2022/11/15",
    "2022/11/16",
    "2022/11/17",
    "2022/11/18",
    "2022/11/19",
    "2022/11/20",
    "2022/11/21",
    "2022/11/22",
    "2022/11/23",
    "2022/11/24",
    "2022/11/25",
    "2022/11/26",
    "2022/11/27",
    "2022/11/28",
    "2022/11/29",
    "2022/11/30",
    "2022/12/1",
    "2022/12/2",
    "2022/12/3",
    "2022/12/4",
    "2022/12/5",
    "2022/12/6",
    "2022/12/7",
    "2022/12/8",
    "2022/12/9",
    "2022/12/10",
    "2022/12/11",
    "2022/12/12",
    "2022/12/13",
    "2022/12/14",
    "2022/12/15",
    "2022/12/16",
    "2022/12/17",
    "2022/12/18",
    "2022/12/19",
    "2022/12/20",
    "2022/12/21",
    "2022/12/22",
    "2022/12/23",
    "2022/12/24",
    "2022/12/25",
    "2022/12/26",
    "2022/12/27",
    "2022/12/28",
    "2022/12/29",
    "2022/12/30",
    "2022/12/31",
    "2023/1/1",
    "2023/1/2",
    "2023/1/3",
    "2023/1/4",
    "2023/1/5",
    "2023/1/6",
    "2023/1/7",
    "2023/1/8",
    "2023/1/9",
    "2023/1/10",
    "2023/1/11",
    "2023/1/12",
    "2023/1/13",
    "2023/1/14",
    "2023/1/15",
    "2023/1/16",
    "2023/1/17",
    "2023/1/18",
    "2023/1/19",
    "2023/1/20",
    "2023/1/21",
    "2023/1/22",
    "2023/1/23",
    "2023/1/24",
    "2023/1/25",
    "2023/1/26",
    "2023/1/27",
    "2023/1/28",
    "2023/1/29",
    "2023/1/30",
    "2023/1/31",
    "2023/2/1",
    "2023/2/2",
    "2023/2/3",
    "2023/2/4",
    "2023/2/5",
    "2023/2/6",
    "2023/2/7",
    "2023/2/8",
    "2023/2/9",
    "2023/2/10",
    "2023/2/11",
    "2023/2/12",
    "2023/2/13",
    "2023/2/14",
    "2023/2/15",
    "2023/2/16",
    "2023/2/17",
    "2023/2/18",
    "2023/2/19",
    "2023/2/20",
    "2023/2/21",
    "2023/2/22",
    "2023/2/23",
    "2023/2/24",
    "2023/2/25",
    "2023/2/26",
    "2023/2/27",
    "2023/2/28",
    "2023/3/1",
    "2023/3/2",
    "2023/3/3",
    "2023/3/4",
    "2023/3/5",
    "2023/3/6",
    "2023/3/7",
    "2023/3/8",
    "2023/3/9",
    "2023/3/10",
    "2023/3/11",
    "2023/3/12",
    "2023/3/13",
    "2023/3/14",
    "2023/3/15",
    "2023/3/16",
    "2023/3/17",
    "2023/3/18",
    "2023/3/19",
    "2023/3/20",
    "2023/3/21",
    "2023/3/22",
    "2023/3/23",
    "2023/3/24",
    "2023/3/25",
    "2023/3/26",
    "2023/3/27",
    "2023/3/28",
    "2023/3/29",
    "2023/3/30",
    "2023/3/31",
    "2023/4/1",
    "2023/4/2",
    "2023/4/3",
    "2023/4/4",
    "2023/4/5",
    "2023/4/6",
    "2023/4/7",
    "2023/4/8",
    "2023/4/9",
    "2023/4/10",
    "2023/4/11",
    "2023/4/12",
    "2023/4/13",
    "2023/4/14",
    "2023/4/15",
    "2023/4/16",
    "2023/4/17",
    "2023/4/18",
    "2023/4/19",
    "2023/4/20",
    "2023/4/21",
    "2023/4/22",
    "2023/4/23",
    "2023/4/24",
    "2023/4/25",
    "2023/4/26",
    "2023/4/27",
    "2023/4/28",
    "2023/4/29",
    "2023/4/30",
    "2023/5/1",
    "2023/5/2",
    "2023/5/3",
    "2023/5/4",
    "2023/5/5",
    "2023/5/6",
    "2023/5/7",
    "2023/5/8",
    "2023/5/9",
    "2023/5/10",
    "2023/5/11",
    "2023/5/12",
    "2023/5/13",
    "2023/5/14",
    "2023/5/15",
    "2023/5/16",
    "2023/5/17",
    "2023/5/18",
    "2023/5/19",
    "2023/5/20",
    "2023/5/21",
    "2023/5/22",
    "2023/5/23",
    "2023/5/24",
    "2023/5/25",
    "2023/5/26",
    "2023/5/27",
    "2023/5/28",
    "2023/5/29",
    "2023/5/30",
    "2023/5/31",
    "2023/6/1",
    "2023/6/2",
    "2023/6/3",
    "2023/6/4",
    "2023/6/5",
    "2023/6/6",
    "2023/6/7",
    "2023/6/8",
    "2023/6/9",
    "2023/6/10",
    "2023/6/11",
    "2023/6/12",
    "2023/6/13",
    "2023/6/14",
    "2023/6/15",
    "2023/6/16",
    "2023/6/17",
    "2023/6/18",
    "2023/6/19",
    "2023/6/20",
    "2023/6/21",
    "2023/6/22",
    "2023/6/23",
    "2023/6/24",
    "2023/6/25",
    "2023/6/26",
    "2023/6/27",
    "2023/6/28",
    "2023/6/29",
    "2023/6/30",
    "2023/7/1",
    "2023/7/2",
    "2023/7/3",
    "2023/7/4",
    "2023/7/5",
    "2023/7/6",
  ];
  let data1 = [
    60.92607532652574, 59, 59, 69, 63, 72, 69, 68, 77, 72, 64, 74, 82, 73, 66,
    73, 77, 78, 74, 74, 80, 89, 94, 96, 90, 89, 84, 76, 71, 78, 84, 79, 87, 82,
    80, 71, 78, 84, 77, 68, 61, 56, 58, 52, 58, 56, 62, 63, 60, 54, 55, 64, 64,
    58, 67, 58, 59, 64, 70, 70, 77, 72, 82, 88, 97, 107, 103, 110, 103, 101, 98,
    100, 97, 106, 99, 109, 107, 112, 105, 105, 98, 107, 100, 106, 112, 108, 116,
    125, 132, 128, 123, 117, 125, 118, 118, 128, 129, 139, 133, 141, 140, 138,
    138, 136, 135, 136, 140, 135, 138, 137, 133, 133, 139, 134, 124, 127, 125,
    123, 123, 115, 108, 110, 115, 110, 120, 122, 117, 117, 119, 112, 104, 113,
    116, 116, 115, 109, 108, 106, 112, 112, 114, 121, 127, 132, 133, 136, 145,
    154, 153, 150, 140, 131, 124, 126, 125, 124, 127, 122, 112, 117, 126, 134,
    133, 133, 129, 122, 114, 117, 124, 124, 134, 130, 140, 149, 152, 146, 140,
    131, 130, 135, 134, 128, 138, 137, 141, 142, 145, 146, 149, 147, 150, 143,
    138, 140, 138, 148, 146, 137, 140, 141, 150, 149, 151, 141, 149, 141, 149,
    154, 145, 153, 145, 153, 158, 164, 171, 175, 175, 169, 169, 161, 155, 162,
    155, 155, 165, 165, 161, 162, 171, 169, 171, 181, 181, 185, 176, 173, 169,
    177, 167, 169, 163, 171, 173, 169, 168, 173, 170, 162, 157, 153, 153, 152,
    154, 154, 152, 161, 153, 159, 165, 170, 165, 158, 164, 165, 165, 173, 172,
    180, 170, 178, 183, 182, 178, 175, 184, 184, 190, 184, 180, 174, 177, 171,
    163, 169, 164, 168, 169, 162, 158, 161, 170, 167, 174, 171, 166, 167, 166,
    167, 157, 165, 167, 163, 156, 152, 146, 153, 155, 164, 161, 151, 147, 148,
    157, 157, 158, 164, 160, 162, 167, 159, 154, 147, 147, 155, 159, 154, 150,
    146, 151, 157, 167, 168, 160, 164, 157, 163, 171, 171, 163, 172, 170, 163,
    167, 158, 162, 153, 161, 166, 164, 156, 150, 148, 150, 157, 152, 146, 154,
    155, 160, 162, 162, 168, 159, 156, 154, 163, 172, 182, 187, 181, 172, 168,
    163, 164, 166, 163, 161, 165, 163, 166, 175, 168, 167, 173, 182, 183, 181,
    182, 182, 183, 173, 170, 176, 173, 171, 173, 179, 169, 161, 169, 176, 168,
    160, 163, 169, 162, 153, 144, 153, 148, 147, 141, 141, 148, 143, 141, 134,
    130, 121, 129, 136, 137, 144, 139, 129, 131, 129, 119, 126, 125, 120, 124,
    131, 134, 143, 136, 139, 145, 142, 136, 143, 150, 151, 154, 145, 135, 131,
    137, 129, 133, 136, 132, 129, 122, 122, 128, 120, 110, 111, 113, 113, 108,
    109, 117, 116, 110, 102, 92, 94, 101, 109, 112, 119, 116, 113, 115, 110,
    113, 118, 120, 118, 121, 123, 123, 128, 124, 122, 125, 117, 126, 119, 120,
    116, 112, 120, 126, 120, 121, 123, 123, 117, 109, 110, 116, 110, 118, 126,
    131, 129, 138, 142, 143, 134, 140, 145, 149, 155, 163, 155, 146, 141, 144,
    142, 150, 151, 154, 149, 143, 135, 131, 138, 139, 134, 144, 147, 141, 151,
    141, 138, 145, 150, 154, 145, 149, 151, 146, 147, 145, 152, 143, 151, 153,
    150, 141, 148, 144, 141, 151, 154, 157, 161, 160, 168, 162, 160, 159, 164,
    155, 159, 152, 154, 149, 140, 145, 143, 150, 140, 140, 135, 135, 129, 126,
    121, 125, 120, 116, 125, 129, 137, 136, 136, 143, 145, 153, 148, 142, 146,
    141, 134, 135, 135, 140, 143, 146, 156, 164, 169, 173, 180, 175, 179, 172,
    162, 167, 160, 154, 162, 166, 157, 148, 146, 147, 140, 148, 155, 153, 159,
    156, 165, 164, 170, 169, 175, 177, 172, 181, 173, 181, 189, 182, 184, 179,
    177, 184, 177, 174, 182, 185, 181, 187, 196, 189, 197, 191, 195, 202, 210,
    210, 211, 208, 200, 191, 190, 198, 201, 199, 206, 197, 206, 202, 211, 213,
    221, 224, 221, 213, 212, 221, 211, 217, 210, 208, 203, 200, 193, 187, 183,
    190, 190, 187, 196, 201, 208, 218, 227, 232, 239, 244, 243, 237, 229, 231,
    226, 234, 241, 243, 246, 255, 252, 262, 253, 245, 244, 248, 249, 248, 247,
    238, 245, 250, 251, 260, 267, 273, 281, 277, 267, 257, 263, 258, 268, 271,
    266, 260, 252, 247, 238, 245, 255, 264, 255, 245, 252, 246, 239, 234, 228,
    222, 215, 221, 221, 221, 226, 222, 228, 222, 222, 222, 221, 214, 210, 203,
    207, 203, 213, 216, 218, 210, 206, 214, 219, 222, 227, 237, 243, 237, 245,
    245, 242, 236, 228, 219, 220, 212, 221, 219, 221, 223, 221, 216, 208, 204,
    209, 216, 213, 217, 210, 214, 219, 211, 207, 202, 198, 192, 185, 182, 185,
    192, 190, 191, 186, 185, 176, 182, 180, 183, 180, 181, 178, 171, 180, 173,
    175, 174, 173, 165, 159, 162, 152, 149, 143, 140, 133, 124, 124, 126, 121,
    120, 127, 122, 129, 119, 120, 110, 111, 109, 110, 105, 95, 101, 108, 110,
    101, 101, 92, 92, 87, 86, 88, 86, 92, 86, 79, 82, 79, 70, 61, 70, 70, 61,
    57, 62, 63, 67, 67, 59, 68, 74, 69, 65, 73, 69, 63, 70, 67, 60, 65, 61, 61,
    61, 56, 60, 59, 68, 75, 66, 62, 54, 58, 63, 55, 53, 54, 50, 48, 41, 37, 37,
    32, 41, 38, 29, 33, 32, 29, 22, 27, 23, 24, 16, 23, 30, 29, 22, 27, 33, 25,
    17, 23, 21, 14, 19, 10, 6, 11, 4, -2, -10, -5, 0, 0, 5, -3, 5, 12, 17, 11,
    12, 21, 27, 17, 9, 17, 7, 6, 9, 17, 27, 20, 20, 13, 21, 16, 19, 27, 30, 32,
    27, 25, 22, 16, 12, 20, 26, 28, 26, 20, 14, 10, 4, -1, 4, -3, -9, -4, 4, 8,
    8, 14, 6, 6, 15, 12, 2, 0, 5, 3, -2, 8, 16, 13, 18, 11, 11, 14, 8, 14, 23,
    13, 19, 26, 29, 30, 31, 22, 30, 40, 45, 43, 40, 47, 53, 56, 46, 45, 53, 52,
    45, 46, 56, 47, 55, 65, 74, 71, 78, 76, 73, 79, 79, 73, 82, 80, 79, 82, 75,
    72, 74, 80, 71, 64, 62, 56, 53, 63, 56, 51, 56, 56, 52, 45, 50, 55, 60, 53,
    57, 54, 47, 51, 59, 62, 65, 70, 79, 87, 91, 91, 98, 98, 94, 89, 88, 95, 101,
    101, 111, 101, 108, 110, 111, 107, 109, 103, 103, 97, 103, 98, 103, 111,
    107, 104, 109, 114, 116, 115, 110, 116, 107, 115, 116, 111, 111, 117, 126,
    129, 134, 139, 134, 134, 130, 128, 134, 137, 132, 128, 127, 135, 142, 143,
    142, 138, 130, 122, 112, 105, 98, 92, 86, 81, 86, 83, 92, 86, 78, 86, 88,
    80, 89, 88, 83, 88, 89, 97, 89, 93, 90, 84, 88, 92, 97, 93, 101, 110, 118,
    112, 104, 113, 115, 118, 114, 115, 113, 104, 111, 104, 111, 117, 112, 105,
    95, 92, 97, 95, 98, 105, 109, 118, 126, 135, 130, 139, 146, 150, 144, 152,
    153, 151, 144, 150, 144, 136, 130, 134, 132, 128, 130, 130, 125, 117, 112,
    115, 107, 97, 106, 98, 98, 101, 97, 89, 94, 85, 76, 79, 80, 82, 74, 68, 73,
    82, 76, 81, 89, 82, 87, 88, 83, 76, 76, 67, 63, 58, 61, 63, 53, 59, 57, 52,
    57, 63, 62, 67, 77, 70, 70, 64, 71, 69, 75, 69, 77, 71, 68, 60, 70, 64, 59,
    55, 54, 57, 48, 42, 40, 43, 51, 47, 43, 43, 47, 54, 46, 43, 43, 50, 40, 33,
    31, 25, 22, 19, 22, 23, 20, 26, 18, 22, 28, 33, 33, 27, 26, 33, 35, 36, 30,
    39, 48, 57, 60, 55, 48, 39, 31, 30, 37, 44, 45, 44, 52, 46, 52, 44, 47, 42,
    48, 57, 64, 58, 54, 60, 51, 53, 45, 40, 38, 42, 45, 54, 57, 48, 50, 48, 39,
    44, 49, 58, 66, 76, 80, 71, 78, 82, 86, 80, 83, 80, 78, 87, 79, 87, 79, 87,
    89, 82, 79, 73, 64, 57, 57, 47, 44, 52, 57, 60, 51, 51, 44, 51, 49, 46, 56,
    57, 59, 58, 67, 67, 61, 68, 64, 63, 58, 53, 49, 40, 39, 38, 28, 19, 17, 8,
    17, 13, 23, 31, 40, 48, 50, 52, 62, 68, 66, 71, 67, 63, 56, 47, 42, 47, 46,
    41, 51, 61, 56, 47, 50, 43, 43, 46, 38, 42, 42, 51, 43, 35, 32, 36, 28, 38,
    48, 39, 39, 47, 43, 34, 39, 32, 37, 41, 45, 54, 62, 62, 66, 63, 57, 56, 65,
    57, 66, 62, 70, 68, 60, 52, 60, 64, 68, 67, 66, 68, 67, 61, 55, 58, 68, 77,
    82, 83, 76, 68, 67, 59, 68, 59, 61, 60, 61, 57, 61, 65, 68, 66, 75, 77, 86,
    82, 90, 96, 98, 108, 108, 118, 121, 114, 117, 111, 115, 116, 119, 109, 111,
    111, 115, 108, 117, 117, 111, 115, 114, 115, 109, 112, 104, 98, 91, 98, 90,
    86, 91, 93, 90, 93, 84, 94, 100, 102, 104, 110, 102, 95, 95, 101, 92, 82,
    74, 80, 77, 76, 77, 85, 85, 87, 85, 82, 76, 81, 75, 75, 76, 71, 64, 73, 77,
    71, 66, 57, 67, 68, 59, 50, 56, 50, 44, 49, 45, 36, 37, 33, 35, 38, 38, 29,
    21, 29, 34, 41, 39, 37, 37, 36, 44, 43, 38, 29, 27, 24, 21, 20, 16, 12, 8,
    17, 20, 15, 16, 25, 31, 35, 36, 41, 47, 56, 52, 45, 37, 45, 42, 47, 39, 47,
    38, 34, 38, 33, 35, 27, 28, 28, 37, 39, 35, 40, 44, 40, 34, 29, 39, 39, 43,
    35, 39, 40, 35, 28, 32, 36, 28, 25, 27, 18, 13, 18, 23, 15, 14, 15, 10, 15,
    13, 21, 14, 21, 25, 33, 33, 27, 29, 31, 30, 28, 35, 37, 43, 39, 40, 45, 53,
    49, 47, 52, 48, 41, 50, 56, 53, 43, 47, 47, 48, 47, 49, 42, 43, 47, 51, 43,
    40, 48, 50, 58, 67, 70, 73, 74, 65, 72, 78, 83, 82, 82, 85, 80, 82, 74, 66,
    57, 60, 60, 58, 66, 65, 62, 64, 55, 52, 55, 57, 63, 67, 67, 71, 69, 65, 65,
    75, 65, 60, 61, 62, 71, 79, 85, 77, 68, 67, 60, 56, 51, 55, 65, 57, 62, 63,
    67, 67, 67, 60, 65, 60, 60, 64, 55, 49, 42, 33, 38, 39, 45, 44, 35, 41, 43,
    39, 48, 41, 35, 35, 30, 33, 34, 31, 30, 23, 16, 18, 15, 21, 28, 31, 37, 35,
    27, 20, 13, 15, 17, 27, 29, 24, 23, 28, 30, 39, 46, 37, 32, 28, 27, 19, 24,
    30, 31, 33, 42, 45, 40, 37, 43, 47, 57, 49, 59, 53, 46, 54, 47, 57, 57, 60,
    57, 51, 55, 54, 57, 52, 58, 54, 50, 52, 48, 44, 51, 51, 54, 58, 57, 50, 56,
    65, 72, 74, 75, 83, 80, 87, 85, 90, 80, 75, 73, 76, 75, 76, 75, 82, 84, 83,
    91, 100, 104, 97, 94, 93, 95, 97, 93, 93, 103, 103, 107, 104, 101, 101, 95,
    90, 97, 102, 96, 100, 100, 109, 116, 123, 116, 112, 109, 105, 98, 107, 112,
    112, 121, 121, 117, 113, 111, 120, 115, 114, 114, 108, 105, 104, 95, 94, 90,
    90, 89, 98, 96, 91, 82, 81, 85, 77, 86, 88, 85, 94, 89, 94, 97, 104, 112,
    118, 118, 109, 118, 120, 116, 124, 115, 121, 114, 122, 112, 108, 115, 108,
    112, 119, 111, 112, 105, 100, 105, 99, 107, 108, 117, 126, 134, 126, 123,
    116, 106, 111, 106, 109, 100, 108, 99, 108, 108, 101, 105, 107, 105, 111,
    121, 122, 117, 113, 113, 120, 111, 103, 101, 93, 91, 90, 87, 91, 87, 91, 98,
    97, 98, 106, 110, 117, 113, 117, 113, 114, 109, 111, 116, 107, 105, 101, 98,
    95, 105, 99, 100, 105, 110, 108, 99, 91, 93, 96, 93, 100, 97, 96, 90, 82,
    87, 90, 96, 96, 89, 98, 103, 95, 87, 91, 88, 85, 94, 88, 84, 88, 90, 93,
    103, 102, 97, 91, 97, 92, 85, 88, 83, 86, 82, 76, 71, 66, 74, 79, 71, 63,
    64, 65, 59, 58, 52, 51, 50, 47, 48, 48, 49, 45, 45, 46, 38, 36, 28, 19, 13,
    20, 15, 7, -1, -3, -4, -1, 0, 4, 9, 3, 6, -1, -4, 3, 12, 15, 23, 28, 26, 34,
    33, 28, 23, 16, 7, 11, 12, 4, -4, -13, -14, -22, -22, -21, -24, -33, -33,
    -41, -38, -40, -35, -30, -31, -29, -25, -24, -27, -27, -26, -24, -23, -17,
    -23, -16, -16, -22, -20, -21, -24, -22, -14, -11, -21, -18, -13, -20, -15,
    -23, -29, -33, -39, -37, -45, -44, -41, -49, -56, -53, -62, -57, -58, -61,
    -55, -49, -45, -40, -36, -27, -28, -36, -31, -24, -20, -23, -25, -29, -36,
    -42, -43, -46, -45, -35, -30, -35, -37, -45, -44, -47, -54, -58, -52, -42,
    -40, -32, -30, -39, -30, -22, -17, -25, -30, -23, -31, -30, -34, -44, -50,
    -49, -42, -48, -40, -36, -35, -39, -49, -50, -56, -53, -62, -54, -46, -45,
    -47, -48, -51, -47, -38, -34, -33, -38, -35, -43, -37, -35, -41, -40, -49,
    -52, -58, -67, -76, -84, -85, -95, -94, -98, -105, -95, -98, -100, -101,
    -92, -90, -82, -87, -79, -77, -70, -63, -56, -63, -66, -68, -67, -69, -71,
    -77, -74, -81, -73, -63, -73, -67, -73, -77, -70, -78, -82, -91, -98, -92,
    -90, -87, -87, -87, -81, -76, -67, -60, -50, -53, -55, -57, -62, -66, -60,
    -63, -71, -79, -86, -89, -83, -77, -71, -64, -60, -65, -56, -53, -57, -57,
    -49, -41, -35, -35, -32, -32, -28, -27, -34, -42, -44, -42, -43, -43, -41,
    -32, -39, -37, -44, -49, -43, -46, -55, -63, -59, -59, -60, -59, -64, -61,
    -65, -59, -57, -57, -49, -51, -45, -43, -34, -38, -40, -46, -52, -57, -61,
    -64, -59, -67, -66, -70, -63, -55, -51, -58, -50, -52, -45, -41, -46, -51,
    -50, -60, -63, -70, -80, -84, -92, -89, -93, -90, -87, -78, -70, -75, -68,
    -73, -66, -70, -71, -67, -77, -81, -89, -87, -83, -73, -73, -77, -73, -73,
    -65, -63, -55, -46, -49, -46, -49, -54, -49, -50, -55, -49, -47, -56, -53,
    -62, -66, -70, -64, -63, -53, -55, -49, -51, -46, -40, -33, -41, -32, -26,
    -21, -13, -9, -1, -8, -14, -12, -18, -26, -29, -30, -27, -29, -21, -14, -13,
    -10, -9, -16, -21, -26, -28, -21, -25, -25, -21, -14, -14, -21, -11, -4,
    -13, -23, -16, -16, -21, -11, -13, -16, -7, -15, -14, -10, -7, -1, 9, 3, 9,
    16, 20, 26, 31, 21, 12, 7, 1, 7, 2, 6, 0, -8, 1, -3, 1, 5, 6, 7, 11, 2, 2,
    11, 4, 5, 3, 10, 18, 17, 18, 14, 14, 9, 17, 12, 3, 4, 12, 3, 8, 6, 13, 22,
    27, 29, 29, 37, 36, 31, 34, 31, 28, 20, 21, 31, 39, 33, 33, 29, 30, 25, 23,
    19, 28, 30, 25, 27, 27, 36, 36, 43, 39, 38, 43, 43, 38, 43, 34, 37, 29, 28,
    19, 28, 23, 20, 23, 15, 24, 30, 24, 23, 22, 23, 33, 34, 39, 34, 43, 37, 40,
    41, 32, 40, 43, 47, 50, 53, 58, 49, 45, 41, 44, 53, 60, 54, 48, 43, 45, 42,
    49, 40, 34, 30, 31, 28, 24, 26, 16, 25, 33, 32, 25, 21, 27, 26, 28, 27, 26,
    30, 33, 43, 48, 45, 44, 46, 46, 50, 45, 36, 33, 23, 25, 33, 36, 35, 37, 30,
    26, 29, 25, 34, 43, 40, 34, 43, 49, 41, 38, 38, 34, 28, 31, 37, 29, 30, 29,
    26, 18, 17, 26, 32, 37, 42, 51, 42, 44, 35, 42, 47, 49, 47, 56, 60, 57, 53,
    56, 65, 64, 58, 50, 52, 59, 62, 69, 78, 87, 83, 77, 77, 74, 66, 69, 63, 54,
    60, 69, 79, 85, 85, 82, 73, 72, 75, 82, 86, 76, 83, 80, 85, 92, 88, 95, 99,
    91, 90, 88, 89, 89, 80, 87, 97, 90, 90, 87, 96, 104, 109, 110, 100, 100,
    101, 99, 96, 93, 94, 100, 104, 99, 94, 95, 86, 85, 86, 87, 85, 92, 82, 86,
    83, 79, 76, 69, 78, 82, 81, 87, 97, 104, 109, 106, 103, 108, 112, 105, 111,
    113, 120, 123, 120, 121, 127, 127, 118, 120, 114, 109, 114, 106, 98, 89, 80,
    88, 96, 98, 97, 107, 101, 97, 95, 91, 89, 89, 96, 104, 111, 114, 113, 105,
    112, 111, 117, 125, 131, 137, 127, 128, 133, 127, 137, 134, 144, 149, 142,
    135, 131, 136, 127, 127, 117, 111, 116, 125, 134, 144, 154, 151, 142, 135,
    136, 131, 122, 128, 137, 134, 135, 134, 128, 125, 127, 122, 130, 123, 114,
    105, 104, 104, 94, 91, 101, 102, 102, 108, 108, 103, 104, 97, 107, 105, 101,
    95, 94, 96, 106, 96, 97, 103, 93, 84, 77, 80, 88, 85, 79, 82, 74, 70, 65,
    61, 69, 70, 79, 79, 88, 91, 94, 93, 87, 87, 89, 80, 81, 78, 78, 70, 78, 87,
    79, 88, 84, 79, 87, 86, 90, 89, 93, 84, 79, 84, 88, 78, 80, 84, 78, 85, 89,
    97, 107, 115, 118, 126, 125, 123, 119, 117, 114, 107, 108, 116, 112, 114,
    117, 117, 121, 121, 118, 120, 125, 119, 115, 119, 119, 117, 115, 118, 122,
    129, 130, 124, 131, 136, 132, 137, 140, 143, 134, 143, 146, 141, 138, 138,
    145, 153, 159, 155, 146, 151, 155, 151, 155, 151, 144, 146, 136, 131, 126,
    117, 125, 119, 121, 117, 116, 115, 115, 114, 105, 106, 97, 93, 98, 96, 98,
    97, 90, 97, 97, 87, 78, 85, 83, 75, 78, 80, 70, 64, 56, 49, 51, 50, 43, 44,
    38, 37, 42, 51, 43, 42, 33, 37, 29, 28, 32, 25, 24, 30, 22, 24, 34, 27, 18,
    25, 30, 21, 13, 7, 1, -4, -8, -5, -4, -10, -16, -8, 2, -2, -3, -12, -9, -13,
    -17, -10, -15, -13, -9, -9, -18, -9, -6, -12, -17, -16, -23, -28, -26, -19,
    -28, -20, -11, -5, -5, -9, -18, -22, -30, -30, -26, -22, -21, -24, -24, -20,
    -15, -10, 0, -2, 7, 0, -8, -10, -17, -17, -7, 0, -4, -9, -9, -8, -14, -17,
    -18, -13, -21, -27, -31, -27, -22, -25, -20, -24, -19, -20, -27, -32, -32,
    -31, -27, -22, -24, -21, -30, -25, -21, -23, -26, -27, -30, -30, -34, -42,
    -35, -44, -51, -48, -53, -48, -38, -43, -43, -47, -52, -42, -40, -45, -37,
    -41, -46, -39, -30, -21, -16, -7, -8, -3, 2, 5, 14, 11, 6, 5, 11, 19, 22,
    30, 35, 32, 36, 30, 39, 30, 32, 34, 39, 39, 30, 28, 20, 25, 21, 27, 34, 25,
    34, 31, 26, 21, 28, 34, 25, 21, 25, 26, 35, 44, 45, 49, 52, 56, 53, 44, 42,
    34, 29, 33, 36, 45, 45, 41, 47, 55, 58, 62, 63, 54, 58, 67, 62, 60, 61, 54,
    45, 50, 49, 56, 55, 52, 54, 55, 46, 39, 47, 46, 49, 49, 46, 50, 56, 50, 57,
    51, 57, 61, 66, 59, 56, 53, 60, 57, 48, 54, 53, 47, 39, 36, 46, 48, 43, 47,
    54, 49, 50, 45, 41, 39, 40, 43, 49, 41, 45, 43, 35, 37, 42, 46, 45, 39, 49,
    45, 45, 44, 52, 49, 44, 39, 39, 45, 53, 60, 68, 61, 67, 60, 51, 43, 48, 43,
    34, 39, 41, 37, 42, 37, 32, 35, 26, 22, 15, 6, 9, 9, 2, -5, 4, 2, 11, 2, 9,
    7, 1, 6, 11, 19, 23, 18, 25, 29, 37, 29, 26, 19, 25, 24, 23, 23, 16, 11, 14,
    24, 26, 27, 27, 26, 27, 36, 35, 27, 29, 31, 31, 39, 39, 39, 46, 45, 55, 59,
    58, 58, 53, 47, 43, 37, 32, 32, 38, 38, 33, 32, 25, 23, 19, 18, 14, 15, 14,
    22, 15, 22, 15, 17, 20, 19, 26, 34, 41, 45, 41, 45, 36, 44, 46, 49, 47, 48,
    41, 39, 41, 44, 52, 62, 63, 67, 72, 77, 72, 70, 75, 66, 64, 56, 48, 51, 59,
    61, 56, 48, 47, 51, 52, 45, 38, 32, 23, 15, 14, 12, 9, 2, 7, -3, -2, 6, 12,
    21, 17, 16, 16, 7, 1, -3, -13, -11, -12, -9, -15, -18, -20, -23, -20, -12,
    -20, -10, -10, -7, -9, -6, -2, -2, 5, -3, -7, -14, -11, -11, -9, -17, -18,
    -10, -18, -18, -13, -19, -16, -15, -16, -13, -7, 3, 0, 9, 1, -9, -7, -2, -3,
    5, 14, 5, -4, 0, 9, 4, 9, 14, 11, 14, 22, 23, 18, 14, 11, 12, 22, 20, 20,
    11, 14, 6, 5, 4, 6, 12, 6, 2, -7, -3, -9, -14, -19, -11, -2, 6, 3, 1, -6,
    -4, -3, 2, -5, -1, 3, -2, 7, 4, 10, 0, -4, 1, -5, -4, -6, 1, -1, 7, 2, 3,
    -5, -9, -8, 1, 7, 10, 12, 6, 1, 8, 8, 1, -4, 3, -1, 0, 7, -1, 0, -2, 1, -5,
    -3, -1, 4, 12, 8, 13, 4, 2, -3, 4, 13, 23, 24, 32, 37, 42, 41, 39, 35, 39,
    44, 52, 56, 56, 63, 62, 71, 63, 61, 70, 67, 68, 78, 80, 85, 77, 82, 91, 97,
    95, 87, 80, 71, 70, 63, 61, 55, 46, 55, 48, 45, 54, 56, 51, 46, 41, 36, 37,
    29, 36, 44, 50, 48, 53, 48, 41, 32, 34, 43, 37, 33, 24, 21, 20, 25, 26, 23,
    24, 19, 26, 32, 41, 32, 42, 35, 35, 36, 38, 34, 36, 40, 34, 25, 20, 17, 14,
    4, 6, 1, 8, 14, 17, 24, 34, 25, 16, 10, 8, 5, 1, 0, 9, 17, 18, 12, 19, 20,
    21, 27, 19, 16, 10, 16, 16, 22, 30, 32, 27, 27, 31, 21, 22, 18, 20, 19, 20,
    29, 32, 24, 34, 44, 53, 51, 56, 51, 46, 44, 49, 57, 56, 64, 74, 72, 75, 81,
    85, 76, 71, 68, 73, 74, 69, 73, 70, 69, 77, 78, 76, 69, 62, 54, 51, 55, 48,
    44, 40, 50, 59, 53, 45, 41, 38, 34, 35, 39, 35, 40, 43, 39, 32, 39, 39, 44,
    48, 44, 46, 45, 36, 27, 32, 32, 29, 29, 22, 31, 25, 27, 27, 36, 30, 24, 22,
    13, 21, 18, 9, 15, 11, 7, -3, -2, 4, -5, -6, -14, -21, -16, -12, -11, -5,
    -8, -10, -19, -21, -15, -18, -18, -26, -17, -19, -22, -16, -15, -12, -3, 1,
    3, 6, 8, 3, -3, 6, 15, 24, 28, 37, 35, 27, 22, 19, 11, 2, -1, -4, -8, -17,
    -19, -12, -7, -2, -6, 0, -6, -4, -7, -4, -4, 6, 13, 19, 19, 12, 10, 14, 22,
    20, 13, 6, 16, 22, 13, 15, 14, 16, 19, 29, 38, 30, 33, 34, 25, 17, 8, 15,
    18, 16, 17, 20, 20, 28, 22, 28, 29, 28, 22, 24, 23, 20, 22, 22, 14, 24, 26,
    18, 15, 23, 18, 11, 21, 12, 3, -1, -5, 4, -1, 0, -7, -4, 2, -4, -12, -4, -1,
    4, -6, -10, 0, -5, -2, -1, -5, 4, -6, -13, -3, -8, -17, -12, -4, 0, -8, -6,
    -14, -12, -3, 4, 3, -2, -4, -8, -1, -8, -7, -14, -12, -21, -19, -22, -14,
    -14, -15, -8, 1, 9, 5, -3, -13, -10, -16, -16, -10, -20, -28, -20, -21, -23,
    -25, -34, -40, -31, -31, -41, -46, -44, -48, -49, -43, -40, -44, -40, -38,
    -46, -47, -47, -37, -28, -29, -27, -33, -34, -26, -34, -33, -41, -34, -44,
    -41, -44, -47, -43, -45, -50, -49, -41, -48, -49, -46, -53, -55, -46, -47,
    -38, -36, -38, -36, -27, -19, -29, -36, -39, -34, -33, -24, -21, -20, -27,
    -26, -33, -27, -30, -22, -30, -20, -30, -38, -36, -43, -41, -40, -30, -31,
    -37, -30, -25, -22, -17, -20, -12, -4, -7, -1, -8, -5, -2, -9, -11, -19,
    -24, -28, -37, -32, -32, -39, -45, -40, -46, -53, -45, -41, -39, -30, -27,
    -17, -14, -12, -20, -11, -4, 2, -6, -4, -13, -16, -9, -12, -18, -24, -28,
    -29, -29, -28, -37, -29, -21, -16, -19, -10, -9, -6, -5, 2, 10, 6, 2, 2, 1,
    2, 8, 6, 11, 8, 9, 17, 9, 12, 22, 19, 25, 18, 19, 16, 9, 7, 10, 18, 21, 29,
    39, 46, 46, 44, 46, 49, 45, 40, 38, 31, 34, 41, 41, 37, 36, 42, 36, 35, 27,
    17, 14, 9, 19, 25, 24, 33, 38, 47, 41, 50, 43, 36, 31, 37, 41, 33, 25, 33,
    41, 34, 30, 23, 24, 25, 27, 25, 20, 23, 21, 19, 19, 15, 22, 19, 21, 28, 32,
    28, 27, 34, 33, 26, 17, 20, 22, 32, 29, 38, 31, 21, 16, 7, 0, -10, -19, -10,
    -7, -17, -20, -16, -10, -2, -2, -7, -17, -19, -15, -23, -17, -20, -10, -10,
    -5, -12, -3, 0, 0, 3, -3, -6, -5, -5, -6, -13, -17, -7, -4, -1, -6, -4, -10,
    -13, -23, -18, -10, -18, -24, -31, -39, -38, -32, -38, -31, -36, -37, -36,
    -31, -32, -24, -33, -31, -33, -42, -36, -43, -40, -31, -40, -35, -38, -37,
    -45, -41, -39, -42, -43, -38, -43, -35, -44, -42, -45, -52, -56, -51, -60,
    -54, -62, -64, -70, -75, -75, -79, -70, -66, -64, -59, -60, -60, -54, -49,
    -53, -57, -50, -53, -53, -44, -42, -50, -58, -63, -72, -64, -59, -67, -62,
    -53, -51, -51, -53, -55, -45, -44, -35, -41, -40, -31, -37, -36, -41, -36,
    -32, -39, -40, -45, -45, -44, -52, -50, -40, -43, -35, -45, -48, -44, -34,
    -35, -37, -46, -47, -51, -44, -42, -38, -36, -29, -23, -19, -23, -25, -16,
    -19, -11, -19, -11, -19, -15, -10, -15, -24, -33, -33, -25, -19, -16, -9,
    -17, -21, -22, -28, -31, -35, -35, -33, -35, -41, -43, -36, -36, -32, -25,
    -35, -35, -27, -28, -22, -20, -18, -27, -22, -16, -13, -9, -5, -4, 4, 5, 0,
    -3, 3, -1, -7, -13, -7, -1, -11, -6, -7, -14, -5, -13, -12, -9, -14, -20,
    -20, -24, -32, -41, -40, -30, -22, -26, -21, -13, -7, -8, -9, -1, 0, -6,
    -15, -15, -24, -27, -18, -8, 2, -6, -3, 5, 11, 12, 13, 21, 19, 23, 17, 26,
    18, 10, 9, 14, 9, 11, 3, 7, 5, -4, -4, -2, -6, -3, 2, 11, 16, 7, 4, -5, -6,
    3, 12, 11, 15, 5, 13, 11, 16, 13, 12, 18, 23, 30, 38, 37, 33, 41, 38, 40,
    31, 27, 33, 27, 19, 11, 5, -4, -10, -6, -14, -7, -1, 0, 8, 5, -2, -2, 7, 14,
    20, 19, 28, 36, 45, 40, 43, 49, 59, 56, 50, 44, 52, 56, 58, 54, 63, 56, 50,
    55, 60, 62, 61, 62, 67, 57, 53, 58, 53, 44, 54, 53, 48, 40, 39, 47, 40, 36,
    33, 40, 43, 35, 38, 47, 38, 38, 46, 37, 36, 29, 31, 39, 38, 34, 35, 37, 46,
    55, 45, 45, 38, 30, 31, 29, 28, 29, 26, 32, 28, 26, 24, 32, 25, 21, 29, 27,
    34, 30, 25, 15, 8, 8, 16, 18, 13, 19, 27, 17, 23, 29, 32, 27, 37, 41, 33,
    24, 34, 28, 21, 22, 21, 18, 22, 18, 11, 11, 8, 10, 17, 16, 18, 14, 11, 16,
    16, 17, 19, 10, 19, 18, 24, 19, 17, 14, 23, 33, 38, 45, 51, 58, 57, 53, 47,
    46, 38, 40, 43, 47, 42, 35, 41, 40, 39, 42, 51, 55, 53, 44, 45, 51, 49, 53,
    53, 61, 65, 69, 76, 82, 86, 85, 94, 84, 76, 74, 77, 81, 88, 97, 88, 82, 77,
    83, 77, 68, 61, 56, 46, 54, 48, 40, 49, 47, 45, 35, 26, 36, 41, 32, 24, 22,
    29, 37, 47, 37, 38, 40, 40, 32, 24, 20, 20, 24, 23, 27, 35, 35, 33, 43, 49,
    52, 56, 62, 63, 64, 61, 64, 65, 66, 73, 81, 74, 76, 83, 74, 83, 89, 82, 81,
    89, 93, 89, 90, 93, 90, 82, 90, 99, 91, 94, 100, 93, 100, 109, 119, 114,
    113, 109, 119, 113, 104, 98, 104, 102, 109, 113, 104, 106, 108, 118, 109,
    106, 97, 99, 100, 94, 84, 84, 92, 98, 104, 105, 102, 94, 100, 100, 105, 105,
    99, 94, 87, 94, 94, 92, 88, 86, 78, 76, 74, 83, 91, 89, 93, 100, 92, 96, 94,
    84, 94, 94, 103, 111, 117, 108, 99, 97, 91, 97, 98, 104, 104, 107, 109, 114,
    117, 120, 130, 134, 129, 137, 141, 149, 145, 145, 136, 135, 136, 138, 134,
    125, 125, 121, 112, 105, 98, 89, 94, 91, 85, 75, 81, 80, 73, 74, 82, 79, 86,
    82, 88, 87, 95, 102, 100, 108, 100, 102, 110, 109, 110, 105, 114, 108, 101,
    104, 96, 99, 105, 110, 106, 98, 104, 114, 105, 99, 93, 97, 104, 95, 87, 80,
    87, 79, 82, 83, 86, 88, 87, 81, 90, 90, 97, 93, 95, 91, 99, 98, 107, 102,
    93, 85, 86, 91, 90, 84, 87, 94, 87, 86, 90, 82, 82, 77, 85, 92, 88, 95, 91,
    86, 85, 83, 77, 86, 76, 81, 80, 74, 75, 82, 73, 63, 62, 63, 57, 63, 73, 64,
    72, 66, 65, 74, 70, 63, 57, 49, 42, 34, 25, 21, 24, 23, 21, 22, 21, 17, 11,
    18, 19, 25, 23, 27, 29, 22, 30, 25, 34, 27, 21, 29, 33, 28, 25, 31, 31, 33,
    25, 21, 16, 23, 23, 33, 33, 26, 32, 24, 31, 31, 38, 32, 25, 22, 16, 13, 7,
    0, -6, -2, 5, 0, -3, 5, -3, 1, 4, 9, 17, 10, 1, -2, 7, 5, 4, -5, -10, -12,
    -11, -7, -12, -7, -5, 3, 12, 4, 1, -7, -6, -16, -23, -13, -14, -14, -23,
    -31, -26, -32, -27, -37, -35, -29, -29, -35, -43, -36, -38, -43, -38, -39,
    -39, -44, -42, -44, -43, -42, -48, -51, -51, -61, -64, -57, -59, -55, -62,
    -72, -76, -78, -72, -81, -76, -83, -86, -89, -92, -90, -83, -83, -84, -94,
    -100, -97, -107, -103, -99, -104, -101, -96, -93, -83, -92, -96, -86, -77,
    -77, -79, -75, -82, -77, -86, -86, -95, -98, -93, -89, -95, -102, -105, -97,
    -96, -99, -93, -92, -92, -95, -96, -94, -96, -101, -97, -96, -86, -93, -97,
    -89, -88, -95, -88, -87, -82, -87, -82, -91, -86, -93, -94, -92, -95, -99,
    -108, -99, -104, -107, -114, -108, -103, -113, -115, -107, -98, -108, -98,
    -103, -99, -95, -91, -101, -99, -107, -103, -100, -106, -116, -114, -109,
    -115, -125, -133, -141, -144, -143, -133, -138, -148, -143, -143, -153,
    -159, -161, -161, -161, -169, -162, -157, -165, -156, -147, -155, -163,
    -170, -173, -169, -166, -157, -155, -163, -157, -162, -169, -179, -175,
    -179, -180, -180, -182, -175, -175, -175, -173, -176, -169, -175, -168,
    -164, -158, -162, -158, -150, -144, -145, -143, -140, -135, -128, -123,
    -126, -129, -130, -134, -133, -130, -137, -132, -122, -125, -131, -140,
    -144, -145, -153, -144, -140, -138, -131, -141, -146, -139, -148, -141,
    -144, -140, -134, -142, -140, -133, -130, -139, -132, -132, -131, -135,
    -138, -139, -145, -151, -151, -157, -164, -173, -183, -189, -194, -187,
    -190, -194, -203, -206, -201, -203, -195, -197, -205, -195, -199, -196,
    -196, -187, -195, -196, -196, -192, -198, -197, -188, -183, -188, -193,
    -186, -185, -194, -203, -206, -216, -214, -222, -222, -217, -219, -214,
    -205, -212, -205, -213, -215, -209, -200, -202, -207, -208, -198, -191,
    -193, -194, -194, -203, -193, -195, -190, -190, -187, -186, -194, -187,
    -188, -197, -200, -200, -201, -191, -193, -187, -187, -190, -200, -193,
    -199, -196, -186, -191, -192, -187, -189, -193, -186, -183, -181, -175,
    -184, -187, -190, -185, -180, -185, -189, -191, -185, -184, -187, -183,
    -176, -186, -187, -190, -185, -176, -182, -182, -192, -193, -203, -200,
    -201, -198, -207, -210, -205, -212, -221, -221, -227, -225, -232, -240,
    -247, -257, -258, -266, -274, -266, -269, -279, -272, -282, -279, -277,
    -282, -291, -291, -290, -281, -275, -267, -260, -253, -262, -264, -272,
    -264, -273, -280, -286, -290, -285, -284, -285, -294, -296, -293, -285,
    -287, -289, -288, -286, -295, -287, -286, -295, -298, -301, -310, -314,
    -316, -321, -316, -316, -319, -318, -323, -332, -342, -350, -346, -352,
    -343, -342, -347, -343, -342, -349, -342, -338, -333, -324, -331, -323,
    -317, -312, -321, -317, -325, -323, -324, -315, -311, -302, -307, -313,
    -309, -301, -304, -298, -298, -301, -299, -304, -302, -296, -297, -304,
    -304, -313, -303, -302, -310, -317, -310, -304, -311, -319, -310, -317,
    -326, -329, -320, -323, -321, -314, -315, -313, -303, -311, -304, -304,
    -300, -295, -298, -303, -304, -302, -294, -292, -293, -287, -292, -282,
    -286, -277, -285, -280, -289, -282, -288, -291, -300, -293, -291, -284,
    -289, -280, -288, -292, -298, -307, -315, -324, -317, -323, -317, -323,
    -318, -321, -326, -319, -321, -322, -313, -309, -319, -326, -328, -323,
    -323, -313, -308, -307, -306, -315, -320, -314, -322, -325, -331, -336,
    -336, -336, -331, -330, -326, -319, -309, -316, -317, -308, -299, -298,
    -306, -297, -301, -299, -304, -301, -300, -296, -304, -313, -317, -318,
    -320, -314, -314, -306, -312, -306, -306, -301, -307, -306, -308, -306,
    -302, -298, -295, -295, -296, -298, -301, -295, -289, -296, -295, -304,
    -295, -296, -300, -294, -303, -311, -304, -314, -311, -320, -310, -302,
    -300, -292, -294, -290, -295, -288, -280, -280, -288, -290, -289, -283,
    -284, -294, -286, -283, -285, -282, -291, -287, -280, -280, -275, -266,
    -266, -260, -260, -262, -271, -270, -269, -271, -267, -265, -261, -268,
    -261, -255, -254, -256, -256, -247, -241, -241, -249, -239, -249, -256,
    -264, -257, -253, -258, -252, -261, -256, -257, -259, -259, -252, -252,
    -257, -265, -273, -279, -278, -286, -285, -279, -270, -260, -264, -261,
    -268, -266, -271, -280, -277, -283, -288, -287, -290, -295, -301, -303,
    -307, -301, -302, -302, -304, -299, -300, -297, -307, -308, -305, -305,
    -304, -298, -289, -282, -281, -289, -288, -298, -291, -296, -293, -297,
    -290, -297, -293, -299, -298, -299, -303, -307, -303, -297, -304, -307,
    -301, -301, -308, -310, -308, -309, -300, -298, -301, -304, -308, -314,
    -315, -319, -326, -329, -338, -333, -331, -337, -333, -334, -327, -333,
    -339, -330, -329, -324, -316, -317, -315, -314, -320, -313, -305, -300,
    -296, -289, -284, -278, -285, -293, -302, -301, -291, -288, -279, -281,
    -272, -277, -274, -279, -284, -277, -283, -282, -286, -286, -289, -285,
    -293, -286, -280, -278, -287, -286, -286, -288, -296, -287, -296, -302,
    -297, -298, -308, -309, -305, -295, -294, -302, -301, -308, -314, -308,
    -301, -309, -317, -311, -321, -329, -330, -329, -328, -319, -313, -323,
    -323, -331, -329, -322, -316, -307, -298, -290, -300, -300, -305, -301,
    -301, -297, -301, -302, -303, -300, -294, -299, -299, -294, -284, -284,
    -277, -281, -286, -286, -295, -291, -296, -303, -306, -302, -299, -293,
    -298, -306, -301, -309, -311, -312, -309, -305, -299, -304, -303, -307,
    -299, -302, -296, -286, -293, -284, -293, -297, -294, -296, -290, -287,
    -279, -271, -263, -257, -248, -255, -251, -245, -254, -250, -249, -253,
    -262, -272, -275, -270, -273, -278, -275, -278, -272, -281, -276, -281,
    -288, -295, -299, -302, -300, -303, -301, -298, -288, -289, -291, -287,
    -291, -301, -292, -298, -288, -291, -300, -299, -303, -304, -309, -313,
    -310, -313, -319, -322, -330, -321, -320, -310, -314, -315, -311, -307,
    -307, -307, -301, -300, -310, -317, -316, -309, -317, -325, -315, -316,
    -311, -317, -312, -305, -299, -296, -288, -283, -275, -266, -261, -254,
    -257, -255, -247, -242, -234, -234, -231, -221, -213, -204, -196, -187,
    -188, -191, -187, -189, -184, -190, -186, -190, -181, -188, -179, -180,
    -172, -179, -171, -180, -178, -187, -191, -181, -172, -179, -189, -187,
    -180, -187, -179, -185, -184, -193, -187, -192, -194, -193, -187, -177,
    -167, -176, -178, -173, -166, -158, -157, -151, -161, -162, -163, -171,
    -177, -179, -172, -178, -186, -178, -186, -185, -187, -178, -169, -179,
    -180, -183, -181, -189, -191, -190, -183, -192, -196, -194, -193, -198,
    -207, -211, -220, -211, -218, -221, -216, -223, -228, -238, -234, -227,
    -235, -244, -248, -244, -254, -248, -242, -234, -241, -243, -239, -235,
    -240, -233, -238, -232, -224, -234, -229, -236, -234, -242, -251, -259,
    -262, -269, -271, -264, -272, -274, -277, -269, -262, -260, -255, -259,
    -265, -258, -253, -260, -256, -249, -259, -254, -258, -262, -263, -258,
    -260, -255, -255, -247, -249, -247, -255, -259, -254, -257, -267, -275,
    -283, -290, -289, -297, -295, -293, -293, -303, -294, -290, -291, -295,
    -289, -298, -307, -301, -292, -284, -285, -292, -300, -300, -297, -289,
    -289, -296, -300, -290, -285, -294, -298, -291, -284, -288, -284, -293,
    -302, -308, -311, -311, -312, -309, -300, -293, -289, -283, -286, -277,
    -274, -281, -287, -285, -292, -295, -295, -289, -284, -294, -299, -290,
    -289, -284, -289, -293, -291, -282, -284, -281, -285, -286, -278, -282,
    -288, -281, -275, -280, -283, -280, -277, -285, -287, -287, -295, -298,
    -293, -296, -302, -303, -295, -297, -297, -292, -288, -286, -282, -285,
    -285, -290, -285, -291, -300, -292, -301, -308, -318, -324, -331, -324,
    -314, -319, -312, -303, -298, -290, -299, -305, -305, -314, -308, -317,
    -325, -332, -330, -322, -322, -316, -315, -315, -316, -318, -319, -314,
    -323, -333, -337, -332, -324, -329, -330, -335, -336, -334, -333, -328,
    -323, -320, -320, -325, -322, -326, -329, -324, -320, -317, -326, -317,
    -313, -318, -323, -319, -319, -326, -323, -313, -304, -295, -299, -304,
    -299, -303, -299, -289, -286, -289, -299, -302, -302, -297, -297, -289,
    -286, -278, -283, -278, -286, -290, -298, -299, -298, -305, -305, -309,
    -308, -307, -310, -312, -319, -326, -333, -336, -334, -329, -325, -326,
    -323, -330, -324, -320, -324, -334, -325, -333, -328, -338, -338, -344,
    -348, -343, -348, -356, -348, -348, -340, -332, -336, -335, -332, -340,
    -339, -340, -337, -335, -340, -341, -336, -336, -346, -348, -341, -337,
    -329, -337, -336, -337, -328, -321, -325, -326, -318, -317, -311, -319,
    -317, -321, -322, -328, -323, -318, -309, -311, -308, -311, -318, -323,
    -319, -309, -310, -317, -317, -314, -321, -329, -337, -337, -343, -343,
    -342, -336, -327, -336, -338, -343, -347, -338, -340, -334, -338, -346,
    -353, -353, -345, -335, -333, -341, -336, -328, -323, -314, -316, -324,
    -324, -326, -322, -328, -333, -329, -336, -336, -339, -342, -341, -338,
    -342, -336, -334, -343, -352, -358, -357, -361, -352, -358, -355, -359,
    -351, -342, -347, -338, -346, -352, -357, -347, -338, -347, -346, -346,
    -345, -338, -333, -326, -324, -325, -317, -319, -320, -312, -318, -323,
    -333, -341, -347, -341, -333, -325, -331, -326, -319, -317, -320, -312,
    -322, -325, -332, -329, -332, -322, -316, -315, -321, -323, -316, -319,
    -323, -315, -318, -322, -329, -331, -333, -342, -344, -339, -333, -331,
    -338, -338, -347, -343, -342, -333, -330, -324, -325, -331, -327, -337,
    -346, -355, -348, -354, -363, -366, -361, -355, -364, -365, -366, -369,
    -365, -355, -360, -357, -361, -356, -348, -344, -350, -359, -364, -356,
    -348, -345, -343, -349, -341, -350, -359, -366, -372, -366, -362, -360,
    -352, -345, -339, -347, -339, -340, -339, -343, -343, -346, -337, -338,
    -333, -342, -346, -346, -342, -333, -325, -321, -326, -329, -329, -327,
    -320, -328, -318, -317, -321, -317, -316, -309, -302, -302, -298, -299,
    -297, -297, -294, -285, -293, -290, -290, -298, -294, -303, -298, -299,
    -295, -295, -297, -302, -301, -292, -292, -286, -293, -294, -292, -282,
    -280, -275, -274, -265, -265, -257, -247, -254, -259, -265, -270, -279,
    -274, -271, -271, -278, -276, -281, -273, -272, -267, -258, -265, -265,
    -259, -249, -245, -251, -260, -266, -260, -265, -272, -266, -260, -252,
    -245, -248, -245, -249, -250, -255, -255, -254, -260, -252, -249, -258,
    -265, -274, -264, -254, -256, -252, -245, -252, -258, -251, -255, -256,
    -246, -241, -233, -233, -236, -236, -226, -225, -232, -227, -225, -215,
    -222, -219, -223, -220, -215, -215, -210, -208, -200, -197, -192, -196,
    -195, -191, -183, -176, -177, -177, -171, -167, -163, -171, -168, -177,
    -168, -163, -168, -158, -158, -153, -157, -166, -160, -166, -164, -166,
    -163, -158, -167, -163, -156, -147, -149, -143, -135, -125, -122, -114,
    -112, -110, -110, -103, -95, -95, -87, -79, -72, -63, -57, -54, -59, -57,
    -60, -52, -51, -44, -50, -41, -48, -44, -41, -48, -41, -42, -32, -40, -30,
    -28, -20, -14, -10, -14, -11, -8, -8, -4, -10, -17, -22, -23, -24, -16, -11,
    -3, -8, -15, -6, -14, -24, -31, -29, -35, -38, -39, -42, -37, -40, -35, -37,
    -40, -43, -38, -40, -47, -42, -40, -34, -41, -49, -50, -48, -44, -47, -57,
    -67, -61, -58, -49, -45, -42, -44, -34, -44, -51, -55, -47, -38, -43, -44,
    -35, -39, -36, -33, -29, -23, -22, -16, -12, -6, -8, 1, -4, 2, -4, -5, -7,
    -6, -15, -18, -19, -17, -10, -20, -24, -25, -20, -17, -10, -17, -22, -27,
    -24, -19, -23, -24, -33, -27, -27, -19, -12, -21, -26, -22, -19, -17, -24,
    -26, -21, -13, -10, -16, -10, -12, -20, -19, -13, -21, -14, -19, -10, -18,
    -25, -22, -21, -20, -25, -26, -34, -40, -35, -25, -33, -24, -24, -15, -22,
    -17, -15, -18, -15, -11, -8, -11, -21, -17, -19, -12, -20, -11, -12, -10,
    -3, -11, -19, -11, -10, -9, -9, -3, 3, 2, -4, -8, -11, -3, -10, -1, -7, -8,
    -18, -16, -8, -3, 1, 8, 17, 19, 20, 23, 24, 24, 23, 16, 10, 14, 8, 14, 22,
    14, 14, 7, 1, 9, 2, 9, 10, 19, 27, 36, 34, 28, 37, 36, 33, 36, 41, 31, 29,
    27, 27, 33, 41, 47, 52, 43, 44, 42, 43, 41, 45, 44, 52, 55, 58, 55, 45, 47,
    48, 39, 41, 48, 52, 44, 47, 51, 60, 51, 46, 53, 53, 52, 61, 61, 60, 62, 53,
    62, 58, 64, 71, 62, 53, 45, 48, 52, 53, 50, 56, 63, 66, 62, 57, 59, 69, 77,
    78, 84, 85, 93, 84, 83, 93, 100, 104, 105, 110, 105, 111, 102, 106, 114,
    122, 123, 129, 134, 142, 139, 132, 138, 131, 139, 132, 131, 137, 139, 143,
    151, 147, 148, 150, 144, 146, 147, 143, 146, 136, 139, 136, 138, 132, 134,
    132, 139, 146, 148, 146, 137, 132, 126, 133, 139, 145, 151, 145, 151, 151,
    149, 158, 151, 151, 158, 162, 167, 170, 172, 180, 177, 169, 170, 171, 174,
    183, 183, 178, 183, 179, 169, 160, 169, 168, 170, 160, 166, 174, 172, 177,
    183, 179, 188, 189, 181, 190, 192, 192, 191, 184, 181, 174, 174, 181, 184,
    175, 174, 169, 178, 172, 178, 188, 185, 175, 176, 181, 186, 192, 183, 193,
    197, 200, 206, 216, 214, 210, 206, 212, 203, 210, 211, 207, 207, 198, 192,
    192, 197, 203, 210, 206, 204, 201, 210, 203, 194, 203, 203, 208, 212, 213,
    217, 213, 203, 196, 192, 190, 197, 192, 194, 202, 210, 213, 205, 203, 210,
    203, 199, 199, 202, 209, 208, 209, 218, 211, 217, 213, 220, 227, 233, 236,
    246, 251, 256, 248, 243, 236, 229, 235, 229, 221, 228, 221, 216, 215, 220,
    218, 225, 227, 219, 217, 214, 215, 205, 201, 192, 186, 185, 190, 181, 186,
    189, 190, 180, 185, 183, 191, 196, 201, 203, 193, 184, 184, 184, 194, 184,
    183, 173, 181, 182, 190, 192, 184, 192, 194, 194, 201, 203, 197, 202, 211,
    217, 209, 215, 220, 220, 218, 211, 219, 222, 225, 232, 230, 226, 224, 228,
    229, 226, 229, 227, 230, 228, 225, 228, 235, 243, 244, 244, 234, 238, 229,
    224, 227, 218, 213, 208, 212, 205, 200, 195, 195, 203, 201, 191, 194, 196,
    190, 183, 185, 176, 173, 177, 169, 169, 165, 159, 150, 141, 134, 127, 122,
    123, 125, 129, 132, 124, 129, 133, 134, 134, 131, 140, 134, 133, 139, 141,
    132, 132, 133, 136, 141, 137, 140, 132, 139, 134, 131, 131, 134, 126, 127,
    133, 128, 131, 123, 120, 118, 110, 120, 110, 117, 112, 117, 123, 123, 127,
    119, 117, 115, 118, 127, 128, 121, 128, 137, 139, 146, 153, 159, 163, 165,
    157, 150, 146, 136, 137, 143, 144, 143, 143, 150, 151, 147, 152, 160, 159,
    162, 160, 168, 161, 158, 155, 165, 161, 160, 157, 157, 165, 171, 178, 182,
    181, 186, 177, 182, 187, 192, 189, 194, 185, 177, 187, 186, 186, 189, 189,
    197, 191, 199, 204, 198, 201, 209, 209, 213, 207, 203, 200, 210, 206, 211,
    215, 207, 210, 217, 212, 216, 210, 205, 206, 215, 224, 226, 232, 237, 235,
    227, 227, 229, 234, 229, 221, 226, 227, 227, 229, 239, 241, 249, 248, 240,
    237, 236, 239, 235, 238, 248, 245, 235, 230, 233, 240, 243, 237, 239, 244,
    236, 229, 228, 223, 218, 225, 228, 219, 220, 216, 220, 228, 231, 236, 227,
    230, 228, 226, 223, 223, 215, 210, 215, 208, 199, 206, 211, 211, 219, 225,
    232, 241, 236, 227, 220, 217, 224, 223, 215, 209, 201, 208, 210, 212, 217,
    223, 228, 224, 231, 241, 249, 243, 250, 249, 253, 254, 245, 244, 239, 240,
    236, 232, 235, 235, 242, 238, 240, 234, 233, 236, 232, 230, 221, 222, 216,
    216, 217, 220, 210, 220, 229, 228, 224, 215, 208, 211, 209, 216, 223, 221,
    218, 215, 225, 226, 228, 225, 216, 208, 214, 215, 221, 215, 216, 224, 216,
    217, 223, 214, 215, 224, 230, 228, 237, 246, 249, 254, 261, 259, 259, 257,
    251, 251, 253, 251, 248, 257, 256, 264, 273, 264, 268, 270, 267, 273, 270,
    278, 280, 275, 271, 265, 267, 258, 256, 252, 261, 271, 264, 270, 268, 270,
    269, 266, 272, 279, 278, 280, 280, 274, 283, 292, 301, 298, 298, 298, 290,
    290, 289, 289, 288, 285, 285, 292, 292, 301, 297, 288, 285, 279, 270, 274,
    281, 283, 283, 274, 273, 268, 275, 276, 274, 276, 279, 270, 263, 256, 249,
    253, 260, 269, 264, 264, 255, 260, 251, 245, 254, 257, 259, 266, 261, 259,
    256, 246, 238, 240, 245, 247, 239, 243, 239, 229, 234, 235, 231, 221, 220,
    215, 214, 209, 210, 201, 211, 207, 217, 218, 211, 220, 215, 219, 216, 226,
    231, 226, 222, 228, 219, 222, 219, 210, 220, 213, 216, 213, 214, 219, 221,
    229, 232, 225, 233, 234, 229, 228, 232, 236, 245, 245, 245, 254, 249, 257,
    262, 264, 270, 269, 274, 284, 292, 299, 291, 289, 296, 301, 308, 317, 307,
    299, 292, 295, 304, 296, 303, 302, 299, 293, 286, 281, 289, 286, 290, 287,
    284, 283, 275, 283, 278, 278, 275, 285, 285, 282, 286, 288, 293, 289, 287,
    282, 272, 276, 271, 275, 271, 275, 270, 267, 276, 285, 285, 284, 277, 283,
    290, 293, 285, 290, 293, 289, 285, 290, 283, 275, 276, 282, 280, 289, 298,
    300, 306, 314, 310, 307, 297, 306, 303, 299, 307, 304, 305, 299, 299, 295,
    291, 286, 286, 283, 281, 280, 285, 284, 277, 280, 278, 271, 265, 259, 249,
    248, 243, 249, 258, 265, 270, 277, 274, 280, 276, 286, 290, 287, 296, 297,
    299, 306, 314, 313, 310, 312, 304, 313, 310, 318, 327, 330, 339, 348, 353,
    362, 355, 355, 361, 359, 359, 352, 348, 348, 349, 357, 347, 354, 347, 340,
    350, 345, 340, 339, 330, 323, 314, 313, 322, 314, 308, 306, 306, 298, 304,
    301, 302, 294, 288, 279, 283, 287, 281, 272, 272, 265, 265, 262, 254, 246,
    237, 246, 244, 244, 240, 232, 240, 239, 234, 230, 231, 236, 226, 225, 235,
    245, 238, 230, 221, 217, 218, 228, 221, 222, 224, 221, 217, 223, 215, 206,
    202, 208, 217, 226, 230, 227, 234, 228, 238, 230, 227, 227, 220, 223, 223,
    214, 222, 223, 230, 239, 240, 238, 230, 224, 229, 222, 214, 209, 209, 219,
    215, 209, 206, 207, 208, 198, 206, 212, 217, 209, 204, 207, 217, 209, 204,
    198, 204, 210, 215, 216, 213, 217, 220, 224, 225, 234, 239, 243, 242, 245,
    238, 245, 241, 233, 225, 216, 221, 231, 232, 238, 238, 243, 234, 236, 228,
    221, 216, 216, 209, 218, 223, 231, 226, 233, 237, 233, 227, 222, 219, 212,
    206, 213, 222, 226, 222, 222, 219, 217, 212, 204, 211, 213, 213, 204, 206,
    212, 219, 219, 214, 211, 207, 198, 191, 200, 191, 199, 209, 207, 202, 209,
    201, 211, 217, 225, 223, 216, 218, 212, 210, 203, 203, 204, 208, 216, 215,
    216, 224, 225, 224, 225, 227, 218, 227, 217, 211, 215, 220, 216, 208, 212,
    218, 211, 220, 229, 225, 219, 228, 233, 239, 234, 226, 230, 226, 230, 234,
    225, 224, 222, 213, 205, 211, 210, 212, 213, 222, 217, 226, 232, 227, 227,
    233, 236, 240, 246, 249, 254, 248, 246, 252, 262, 255, 261, 254, 260, 258,
    248, 254, 247, 255, 259, 260, 265, 266, 262, 254, 252, 258, 251, 255, 261,
    254, 247, 244, 241, 231, 234, 237, 242, 236, 229, 220, 227, 237, 241, 232,
    240, 247, 238, 243, 240, 239, 235, 242, 244, 246, 244, 250, 248, 251, 256,
    265, 264, 265, 272, 267, 276, 272, 270, 272, 270, 262, 262, 259, 251, 245,
    236, 238, 244, 234, 226, 220, 218, 211, 212, 207, 207, 202, 210, 202, 197,
    196, 203, 209, 200, 194, 195, 195, 199, 205, 201, 204, 197, 206, 213, 210,
    212, 208, 211, 207, 201, 195, 195, 198, 208, 205, 198, 198, 198, 203, 208,
    200, 209, 211, 203, 201, 197, 192, 195, 201, 202, 197, 193, 198, 200, 195,
    189, 181, 184, 177, 185, 184, 177, 170, 172, 166, 162, 158, 165, 163, 157,
    154, 153, 158, 165, 155, 146, 138, 141, 136, 135, 131, 131, 132, 138, 140,
    137, 132, 124, 125, 116, 114, 118, 114, 116, 110, 101, 94, 84, 87, 81, 72,
    66, 65, 65, 56, 56, 50, 46, 55, 51, 49, 46, 50, 56, 48, 56, 58, 57, 61, 53,
    44, 44, 48, 52, 58, 67, 77, 79, 88, 85, 76, 83, 88, 94, 99, 104, 105, 95,
    89, 92, 96, 91, 82, 86, 79, 83, 83, 83, 86, 82, 80, 82, 90, 99, 104, 98, 96,
    97, 101, 94, 93, 83, 91, 85, 84, 92, 94, 85, 95, 90, 92, 102, 108, 107, 102,
    102, 106, 110, 107, 111, 111, 120, 117, 109, 113, 120, 112, 120, 122, 128,
    129, 133, 135, 127, 130, 130, 130, 124, 117, 123, 124, 123, 123, 132, 141,
    151, 143, 145, 136, 141, 134, 126, 120, 128, 135, 136, 143, 147, 148, 158,
    160, 152, 145, 135, 138, 131, 125, 132, 125, 124, 128, 122, 129, 120, 128,
    130, 130, 122, 125, 119, 126, 125, 127, 118, 125, 128, 127, 118, 109, 110,
    107, 109, 99, 104, 106, 98, 101, 104, 108, 104, 104, 105, 106, 106, 96, 87,
    77, 82, 89, 89, 90, 82, 87, 85, 90, 84, 78, 78, 72, 70, 78, 71, 63, 55, 64,
    73, 65, 57, 65, 66, 75, 70, 61, 65, 72, 77, 80, 89, 91, 94, 95, 101, 103,
    100, 103, 111, 116, 113, 108, 109, 111, 121, 127, 133, 134, 127, 128, 127,
    124, 123, 116, 113, 104, 101, 108, 105, 113, 114, 114, 119, 118, 128, 122,
    112, 120, 117, 124, 124, 118, 115, 121, 113, 109, 118, 112, 117, 125, 126,
    124, 120, 117, 123, 114, 106, 113, 105, 108, 107, 100, 98, 99, 92, 101, 110,
    119, 114, 114, 111, 115, 114, 123, 131, 124, 131, 139, 130, 128, 128, 122,
    124, 126, 125, 130, 121, 126, 126, 118, 124, 124, 125, 121, 115, 108, 101,
    96, 92, 84, 86, 80, 72, 77, 67, 63, 60, 50, 42, 48, 56, 60, 66, 74, 76, 72,
    78, 74, 77, 76, 80, 71, 70, 61, 58, 64, 72, 71, 79, 86, 81, 81, 82, 88, 83,
    87, 90, 90, 97, 99, 99, 95, 97, 102, 110, 111, 110, 108, 114, 116, 120, 119,
    127, 129, 125, 133, 131, 138, 138, 146, 148, 156, 157, 163, 166, 168, 172,
    179, 171, 172, 167, 176, 186, 177, 176, 168, 160, 167, 167, 169, 175, 168,
    175, 176, 175, 170, 166, 174, 164, 174, 171, 180, 188, 179, 186, 182, 176,
    180, 172, 172, 179, 186, 193, 199, 197, 194, 202, 205, 204, 194, 193, 202,
    199, 195, 197, 200, 192, 189, 183, 186, 187, 194, 194, 201, 192, 189, 180,
    171, 179, 180, 174, 165, 165, 170, 170, 166, 162, 171, 168, 171, 178, 178,
    178, 170, 174, 171, 162, 169, 171, 171, 169, 167, 169, 165, 171, 175, 168,
    169, 179, 182, 188, 194, 190, 196, 205, 206, 204, 204, 197, 194, 192, 201,
    205, 212, 211, 207, 201, 209, 215, 212, 220, 229, 235, 243, 244, 251, 246,
    253, 256, 251, 245, 236, 238, 229, 226, 218, 214, 217, 219, 226, 233, 224,
    228, 235, 240, 249, 249, 244, 245, 235, 234, 225, 233, 229, 232, 236, 242,
    244, 251, 255, 248, 239, 246, 243, 234, 233, 229, 233, 228, 230, 238, 241,
    241, 234, 240, 234, 237, 227, 220, 222, 219, 221, 212, 218, 215, 216, 213,
    221, 228, 224, 225, 224, 228, 231, 223, 233, 238, 230, 225, 224, 220, 220,
    219, 212, 218, 215, 219, 221, 225, 223, 225, 231, 239, 248, 250, 245, 251,
    253, 247, 245, 243, 239, 241, 240, 241, 247, 239, 233, 229, 228, 222, 212,
    214, 216, 214, 221, 215, 220, 220, 218, 216, 216, 208, 211, 213, 213, 207,
    214, 204, 195, 188, 184, 180, 171, 168, 159, 167, 165, 158, 150, 154, 164,
    173, 173, 170, 175, 183, 177, 181, 185, 187, 189, 191, 192, 188, 180, 190,
    189, 184, 190, 194, 186, 187, 193, 199, 197, 190, 196, 198, 192, 201, 193,
    199, 203, 202, 193, 201, 205, 195, 194, 190, 186, 183, 178, 186, 188, 181,
    174, 181, 187, 192, 198, 194, 198, 202, 198, 189, 197, 200, 209, 203, 203,
    211, 213, 216, 209, 204, 211, 204, 200, 194, 195, 201, 201, 208, 200, 198,
    189, 187, 178, 169, 168, 178, 186, 194, 192, 196, 193, 188, 188, 181, 186,
    190, 196, 190, 184, 179, 189, 197, 195, 198, 199, 202, 207, 203, 200, 208,
    200, 205, 211, 216, 218, 220, 216, 218, 220, 224, 224, 215, 209, 214, 219,
    223, 219, 217, 211, 219, 217, 209, 206, 203, 209, 218, 209, 201, 207, 202,
    204, 209, 209, 215, 207, 217, 225, 225, 235, 235, 243, 250, 247, 247, 238,
    240, 246, 254, 253, 262, 259, 251, 255, 252, 259, 258, 255, 249, 244, 243,
    247, 255, 258, 261, 267, 271, 281, 277, 269, 271, 281, 276, 273, 265, 265,
    269, 276, 279, 286, 287, 294, 297, 295, 300, 298, 295, 292, 301, 298, 307,
    308, 317, 313, 311, 308, 305, 300, 308, 304, 298, 289, 284, 284, 286, 279,
    281, 288, 281, 275, 272, 280, 285, 289, 293, 290, 284, 278, 278, 279, 279,
    272, 268, 276, 284, 283, 284, 285, 280, 271, 273, 269, 268, 263, 270, 276,
    271, 263, 272, 276, 281, 275, 274, 278, 275, 270, 275, 281, 289, 292, 300,
    304, 300, 299, 303, 313, 320, 318, 327, 329, 325, 321, 328, 320, 320, 325,
    329, 320, 322, 318, 314, 318, 320, 327, 329, 327, 333, 333, 333, 341, 333,
    343, 352, 352, 351, 356, 364, 374, 381, 385, 383, 382, 380, 376, 384, 378,
    375, 378, 371, 374, 379, 386, 395, 387, 382, 372, 381, 373, 371, 367, 368,
    366, 365, 366, 375, 370, 380, 371, 367, 368, 363, 357, 354, 351, 348, 344,
    338, 344, 336, 334, 343, 337, 333, 324, 322, 314, 317, 317, 323, 316, 318,
    317, 323, 314, 322, 328, 324, 315, 312, 304, 303, 308, 307, 314, 311, 317,
    317, 314, 309, 299, 297, 300, 298, 300, 307, 306, 314, 313, 314, 324, 331,
    329, 329, 326, 333, 338, 330, 329, 331, 340, 342, 335, 329, 322, 316, 316,
    312, 322, 320, 324, 324, 328, 323, 332, 330, 331, 326, 324, 322, 315, 309,
    317, 319, 325, 329, 321, 321, 318, 327, 323, 318, 319, 310, 301, 293, 285,
    287, 293, 294, 290, 280, 286, 278, 288, 295, 292, 302, 310, 302, 308, 308,
    304, 303, 309, 303, 312, 302, 308, 302, 299, 292, 296, 305, 299, 292, 286,
    294, 296, 295, 288, 289, 291, 297, 302, 298, 290, 290, 291, 294, 302, 305,
    305, 314, 305, 308, 309, 306, 303, 298, 291, 299, 300, 296, 305, 312, 320,
    314, 317, 326, 318, 314, 322, 314, 316, 308, 307, 315, 311, 315, 307, 316,
    315, 324, 322, 329, 335, 325, 333, 327, 326, 329, 325, 327, 320, 314, 310,
    301, 296, 304, 306, 298, 298, 304, 310, 315, 321, 318, 317, 322, 316, 312,
    315, 314, 309, 318, 321, 314, 324, 324, 327, 336, 327, 336, 342, 338, 329,
    324, 317, 318, 319, 315, 307, 309, 316, 315, 307, 313, 312, 321, 322, 317,
    313, 312, 309, 316, 318, 315, 325, 325, 335, 342, 338, 348, 346, 338, 332,
    327, 329, 336, 345, 339, 341, 334, 324, 318, 316, 306, 308, 316, 313, 322,
    318, 314, 316, 311, 307, 307, 305, 314, 317, 308, 314, 305, 305, 312, 315,
    313, 322, 329, 334, 331, 321, 314, 305, 309, 305, 305, 307, 303, 309, 302,
    308, 310, 302, 307, 301, 298, 296, 306, 298, 296, 303, 306, 309, 311, 321,
    319, 314, 320, 316, 315, 319, 319, 320, 316, 310, 318, 313, 312, 304, 300,
    302, 305, 314, 312, 321, 327, 331, 328, 334, 327, 336, 339, 340, 344, 345,
    347, 356, 358, 364, 359, 358, 349, 355, 354, 358, 365, 361, 356, 360, 369,
    373, 374, 369, 365, 370, 376, 373, 377, 378, 371, 379, 380, 373, 364, 369,
    359, 362, 362, 358, 351, 348, 338, 341, 336, 338, 342, 340, 349, 351, 352,
    346, 343, 349, 355, 361, 358, 353, 351, 352, 360, 358, 366, 372, 363, 365,
    370, 376, 385, 386, 380, 386, 390, 400, 400, 393, 385, 388, 384, 389, 383,
    374, 372, 366, 370, 365, 372, 372, 380, 384, 392, 392, 397, 404, 410, 409,
    412, 409, 406, 407, 406, 412, 420, 426, 431, 429, 430, 438, 429, 420, 417,
    408, 409, 407, 405, 396, 405, 401, 401, 402, 396, 392, 392, 398, 398, 403,
    412, 412, 407, 417, 423, 421, 423, 421, 424, 417, 426, 417, 416, 426, 426,
    422, 412, 417, 424, 414, 405, 413, 418, 413, 416, 422, 422, 426, 435, 443,
    441, 442, 438, 429, 438, 445, 451, 461, 453, 447, 452, 448, 451, 454, 447,
    451, 459, 459, 463, 468, 461, 453, 459, 461, 452, 454, 452, 455, 458, 449,
    445, 435, 444, 440, 442, 435, 437, 431, 426, 419, 424, 415, 406, 400, 397,
    391, 399, 396, 392, 394, 401, 395, 393, 387, 396, 389, 379, 372, 369, 373,
    368, 361, 358, 359, 350, 358, 364, 354, 347, 349, 343, 348, 348, 345, 355,
    359, 369, 363, 356, 363, 366, 358, 362, 357, 350, 342, 345, 345, 353, 361,
    356, 348, 351, 353, 352, 353, 361, 357, 354, 349, 358, 355, 351, 356, 365,
    364, 355, 354, 351, 358, 365, 368, 377, 384, 380, 377, 381, 389, 386, 387,
    385, 385, 377, 368, 370, 373, 377, 382, 391, 387, 397, 388, 392, 384, 391,
    394, 399, 395, 393, 392, 396, 389, 392, 382, 380, 372, 372, 381, 378, 380,
    374, 370, 365, 364, 355, 352, 353, 347, 345, 349, 344, 334, 326, 329, 325,
    329, 335, 343, 347, 355, 356, 363, 360, 353, 347, 348, 344, 350, 347, 339,
    346, 336, 338, 339, 332, 326, 326, 327, 327, 317, 316, 322, 324, 321, 311,
    319, 329, 329, 339, 342, 336, 327, 327, 328, 324, 323, 314, 313, 307, 309,
    305, 303, 294, 288, 289, 294, 303, 308, 300, 296, 292, 289, 295, 293, 301,
    298, 305, 310, 310, 319, 326, 333, 338, 338, 336, 327, 320, 319, 311, 310,
    316, 309, 319, 319, 323, 315, 308, 302, 310, 313, 313, 319, 312, 303, 296,
    302, 298, 308, 300, 297, 295, 300, 305, 309, 306, 305, 302, 298, 304, 308,
    302, 298, 291, 285, 277, 267, 272, 271, 266, 262, 271, 279, 284, 276, 286,
    287, 279, 281, 282, 291, 300, 310, 303, 295, 296, 296, 287, 292, 288, 288,
    285, 289, 297, 301, 291, 294, 298, 289, 280, 286, 287, 280, 272, 268, 266,
    269, 259, 266, 264, 259, 249, 248, 244, 251, 259, 264, 274, 283, 274, 276,
    269, 264, 258, 268, 273, 277, 277, 286, 292, 293, 298, 302, 310, 313, 316,
    311, 317, 310, 301, 292, 287, 283, 285, 293, 298, 294, 294, 298, 300, 295,
    291, 295, 286, 284, 277, 282, 292, 298, 304, 294, 298, 307, 316, 313, 303,
    297, 300, 306, 310, 319, 323, 321, 322, 313, 316, 319, 329, 331, 324, 319,
    313, 322, 322, 319, 320, 327, 318, 325, 330, 326, 331, 335, 341, 348, 340,
    331, 338, 336, 326, 330, 328, 327, 319, 320, 323, 316, 319, 324, 330, 334,
    333, 328, 335, 335, 333, 331, 322, 331, 332, 333, 340, 344, 341, 334, 336,
    335, 333, 339, 346, 339, 335, 334, 328, 319, 323, 314, 323, 333, 338, 345,
    336, 333, 329, 336, 328, 337, 335, 331, 338, 336, 330, 325, 329, 338, 329,
    326, 327, 319, 310, 307, 302, 302, 306, 304, 300, 297, 290, 297, 291, 291,
    299, 305, 307, 314, 313, 310, 309, 315, 309, 318, 313, 308, 314, 313, 314,
    311, 316, 319, 326, 317, 314, 315, 317, 312, 311, 320, 313, 317, 307, 313,
    315, 307, 304, 301, 301, 303, 309, 300, 309, 312, 309, 309, 305, 297, 298,
    295, 293, 295, 300, 298, 304, 309, 310, 317, 319, 328, 338, 338, 333, 342,
    335, 328, 331, 324, 319, 325, 329, 330, 321, 327, 321, 324, 334, 339, 336,
    331, 327, 326, 328, 319, 310, 311, 308, 316, 311, 312, 303, 297, 288, 290,
    290, 293, 292, 288, 279, 276, 266, 262, 263, 259, 258, 249, 245, 245, 254,
    250, 241, 246, 237, 230, 227, 234, 240, 233, 232, 238, 234, 228, 223, 232,
    226, 219, 219, 224, 216, 207, 213, 221, 230, 237, 237, 235, 237, 238, 232,
    241, 242, 239, 242, 233, 231, 231, 241, 243, 249, 258, 261, 261, 270, 276,
    281, 285, 282, 281, 284, 283, 284, 286, 289, 287, 293, 300, 299, 295, 297,
    293, 293, 284, 286, 277, 272, 282, 288, 295, 285, 283, 288, 283, 287, 293,
    298, 293, 289, 286, 277, 270, 266, 262, 265, 264, 264, 269, 279, 282, 281,
    290, 290, 289, 295, 303, 298, 296, 297, 294, 288, 296, 290, 283, 284, 279,
    275, 281, 284, 282, 284, 278, 271, 276, 286, 276, 284, 287, 290, 293, 301,
    299, 304, 305, 309, 318, 323, 326, 335, 338, 338, 345, 341, 342, 344, 344,
    346, 343, 336, 337, 336, 328, 328, 327, 331, 339, 347, 343, 344, 338, 331,
    338, 335, 343, 345, 342, 333, 324, 333, 331, 332, 334, 332, 324, 332, 336,
    326, 329, 338, 330, 327, 319, 320, 327, 319, 310, 305, 306, 310, 320, 321,
    314, 307, 302, 296, 296, 299, 296, 302, 312, 317, 314, 306, 309, 309, 299,
    307, 310, 319, 323, 323, 314, 310, 302, 308, 300, 296, 302, 305, 299, 291,
    296, 306, 305, 302, 304, 298, 301, 301, 304, 310, 303, 297, 294, 286, 286,
    291, 287, 283, 276, 274, 278, 276, 270, 277, 286, 296, 299, 308, 301, 291,
    284, 293, 291, 296, 298, 302, 301, 304, 294, 286, 283, 292, 299, 309, 308,
    307, 303, 303, 298, 294, 301, 309, 307, 303, 297, 305, 296, 293, 295, 299,
    308, 317, 310, 312, 304, 311, 317, 321, 322, 317, 315, 324, 331, 336, 339,
    341, 336, 341, 348, 343, 335, 340, 344, 338, 348, 356, 351, 351, 350, 358,
    364, 363, 361, 369, 368, 367, 373, 375, 383, 373, 368, 368, 369, 364, 373,
    368, 363, 360, 358, 352, 354, 353, 350, 345, 342, 348, 355, 350, 356, 363,
    357, 359, 359, 361, 354, 348, 344, 340, 334, 340, 347, 353, 350, 349, 340,
    339, 348, 353, 360, 369, 375, 381, 377, 386, 381, 377, 370, 374, 376, 372,
    377, 376, 377, 381, 374, 379, 371, 362, 366, 372, 363, 365, 362, 372, 376,
    372, 362, 353, 362, 357, 350, 342, 335, 327, 331, 335, 331, 328, 334, 343,
    341, 340, 333, 327, 319, 324, 327, 318, 321, 317, 308, 306, 298, 302, 300,
    294, 301, 305, 311, 307, 312, 310, 308, 312, 307, 306, 311, 320, 323, 332,
    332, 327, 336, 342, 338, 337, 344, 347, 349, 349, 342, 348, 353, 347, 350,
    344, 336, 336, 333, 342, 333, 339, 338, 346, 350, 340, 334, 334, 334, 331,
    340, 334, 343, 334, 337, 337, 339, 332, 332, 325, 327, 329, 334, 338, 336,
    336, 327, 320, 320, 327, 322, 326, 332, 341, 343, 335, 345, 342, 344, 351,
    356, 352, 360, 355, 365, 372, 375, 369, 376, 375, 366, 366, 365, 375, 369,
    359, 360, 350, 354, 348, 338, 338, 346, 347, 341, 341, 351, 358, 356, 359,
    363, 355, 362, 370, 371, 374, 381, 380, 386, 386, 385, 381, 373, 366, 367,
    376, 374, 373, 364, 372, 369, 376, 383, 384, 379, 376, 367, 357, 357, 360,
    353, 351, 356, 364, 369, 378, 379, 376, 367, 358, 365, 371, 364, 371, 373,
    375, 370, 362, 365, 361, 352, 356, 347, 343, 352, 355, 362, 366, 363, 369,
    363, 361, 371, 369, 374, 373, 370, 361, 362, 361, 359, 354, 351, 342, 338,
    343, 334, 326, 318, 310, 312, 318, 323, 325, 325, 327, 320, 327, 325, 325,
    328, 334, 343, 344, 336, 336, 329, 332, 324, 318, 314, 315, 323, 317, 313,
    316, 317, 315, 323, 327, 324, 328, 318, 313, 319, 314, 311, 308, 304, 295,
    298, 293, 300, 295, 299, 300, 304, 302, 306, 306, 301, 298, 299, 301, 297,
    293, 289, 279, 285, 281, 273, 272, 263, 269, 274, 283, 288, 290, 282, 273,
    276, 269, 277, 284, 285, 276, 274, 267, 258, 263, 267, 276, 279, 283, 283,
    281, 284, 288, 292, 296, 287, 283, 289, 280, 272, 279, 269, 262, 270, 277,
    279, 274, 280, 285, 276, 281, 285, 285, 292, 292, 283, 275, 280, 271, 263,
    262, 268, 263, 269, 263, 258, 261, 260, 263, 272, 282, 283, 280, 285, 284,
    279, 278, 278, 283, 279, 276, 284, 279, 285, 292, 296, 297, 288, 295, 305,
    303, 305, 310, 319, 316, 320, 320, 318, 327, 329, 322, 324, 314, 311, 310,
    306, 303, 309, 303, 303, 305, 303, 312, 313, 312, 311, 310, 306, 300, 306,
    314, 320, 314, 305, 306, 309, 304, 313, 312, 317, 309, 310, 307, 306, 306,
    308, 310, 307, 304, 311, 301, 303, 299, 304, 298, 304, 308, 304, 311, 304,
    307, 301, 295, 298, 296, 299, 307, 310, 312, 309, 317, 316, 325, 324, 330,
    337, 328, 324, 316, 326, 333, 341, 339, 342, 347, 351, 352, 342, 341, 334,
    339, 341, 344, 340, 346, 353, 345, 353, 349, 349, 340, 336, 337, 346, 346,
    348, 350, 352, 348, 356, 359, 353, 353, 344, 354, 351, 350, 354, 361, 358,
    350, 343, 344, 347, 343, 345, 343, 353, 355, 358, 366, 376, 385, 384, 392,
    396, 391, 385, 378, 387, 377, 379, 374, 380, 372, 376, 381, 391, 395, 394,
    390, 383, 386, 382, 382, 391, 383, 382, 389, 380, 374, 383, 382, 378, 386,
    389, 393, 384, 381, 388, 389, 396, 399, 399, 401, 410, 410, 402, 410, 420,
    421, 421, 427, 429, 437, 435, 441, 441, 434, 428, 430, 426, 425, 418, 414,
    418, 425, 418, 414, 405, 396, 396, 403, 393, 395, 388, 388, 393, 396, 394,
    385, 379, 379, 385, 395, 397, 404, 400, 392, 388, 384, 374, 368, 363, 357,
    349, 345, 353, 356, 354, 360, 364, 356, 361, 356, 349, 357, 350, 356, 364,
    359, 350, 348, 343, 338, 328, 327, 329, 338, 345, 337, 340, 348, 357, 365,
    365, 364, 363, 355, 357, 367, 363, 361, 352, 346, 356, 356, 366, 357, 364,
    371, 379, 377, 375, 367, 360, 360, 365, 372, 378, 371, 380, 379, 371, 363,
    354, 355, 347, 339, 346, 347, 348, 340, 342, 336, 333, 328, 322, 322, 315,
    307, 312, 313, 307, 301, 291, 288, 283, 290, 283, 291, 292, 289, 290, 299,
    291, 281, 283, 283, 290, 290, 294, 286, 290, 293, 301, 293, 292, 287, 289,
    283, 287, 289, 283, 288, 297, 300, 293, 298, 304, 296, 296, 290, 283, 291,
    289, 297, 297, 307, 297, 299, 294, 302, 296, 287, 285, 285, 276, 274, 278,
    280, 277, 270, 269, 275, 282, 278, 268, 263, 253, 249, 258, 250, 252, 246,
    243, 249, 243, 233, 241, 240, 249, 250, 244, 253, 253, 251, 248, 254, 256,
    249, 257, 253, 246, 245, 237, 242, 239, 230, 237, 242, 239, 233, 240, 240,
    245, 242, 240, 234, 241, 242, 250, 249, 251, 244, 243, 251, 241, 239, 233,
    225, 226, 226, 222, 221, 229, 226, 235, 232, 237, 245, 251, 255, 262, 257,
    252, 256, 262, 263, 263, 270, 279, 281, 281, 285, 281, 279, 285, 289, 279,
    273, 282, 278, 272, 282, 283, 280, 274, 269, 271, 267, 262, 261, 251, 243,
    252, 251, 248, 253, 262, 271, 262, 265, 261, 263, 260, 259, 266, 258, 266,
    271, 268, 275, 276, 272, 265, 260, 264, 265, 256, 260, 270, 273, 266, 260,
    257, 261, 255, 251, 248, 249, 255, 246, 238, 240, 233, 223, 228, 224, 222,
    220, 218, 209, 210, 216, 219, 219, 216, 220, 219, 220, 216, 216, 225, 229,
    227, 236, 235, 236, 246, 250, 243, 251, 257, 257, 262, 267, 275, 278, 268,
    262, 271, 264, 256, 255, 258, 266, 264, 267, 266, 267, 258, 256, 265, 261,
    259, 261, 265, 256, 265, 261, 254, 251, 252, 255, 257, 257, 264, 260, 251,
    251, 251, 245, 247, 250, 254, 256, 256, 254, 258, 261, 258, 258, 260, 260,
    252, 244, 237, 238, 238, 246, 242, 245, 236, 242, 239, 243, 236, 237, 231,
    234, 240, 248, 254, 249, 249, 242, 239, 230, 233, 228, 237, 228, 225, 222,
    227, 231, 226, 221, 223, 217, 224, 219, 212, 216, 208, 200, 196, 206, 211,
    208, 210, 202, 192, 184, 183, 192, 201, 194, 202, 205, 196, 201, 207, 201,
    199, 203, 196, 198, 203, 211, 211, 219, 229, 237, 245, 255, 248, 241, 238,
    233, 228, 228, 226, 219, 228, 233, 224, 231, 234, 232, 228, 218, 215, 223,
    223, 227, 232, 234, 227, 235, 236, 235, 233, 229, 234, 239, 236, 242, 247,
    245, 254, 255, 259, 263, 258, 256, 256, 246, 246, 237, 231, 227, 227, 229,
    221, 231, 222, 212, 216, 211, 203, 205, 201, 204, 196, 199, 193, 185, 187,
    190, 199, 205, 210, 213, 214, 207, 200, 207, 212, 207, 216, 217, 212, 217,
    217, 210, 214, 205, 205, 195, 200, 191, 192, 194, 195, 196, 191, 190, 183,
    179, 185, 192, 198, 195, 187, 181, 188, 190, 198, 193, 186, 180, 179, 172,
    180, 183, 182, 180, 181, 182, 185, 179, 180, 181, 177, 178, 183, 186, 192,
    189, 181, 174, 173, 173, 181, 188, 197, 199, 198, 190, 196, 189, 180, 185,
    194, 186, 191, 183, 190, 193, 195, 194, 194, 194, 189, 195, 191, 184, 186,
    195, 203, 194, 197, 203, 212, 211, 208, 218, 211, 208, 207, 209, 203, 193,
    202, 205, 201, 199, 196, 188, 180, 183, 193, 190, 181, 180, 183, 187, 196,
    203, 207, 215, 208, 204, 199, 190, 195, 200, 201, 209, 208, 203, 207, 217,
    210, 201, 200, 195, 188, 182, 176, 175, 179, 178, 171, 178, 184, 188, 187,
    178, 178, 187, 182, 181, 185, 181, 191, 184, 186, 184, 183, 186, 185, 177,
    185, 190, 185, 189, 180, 173, 163, 162, 162, 167, 160, 164, 163, 157, 155,
    163, 169, 176, 186, 184, 185, 193, 198, 192, 191, 199, 204, 194, 191, 196,
    200, 206, 213, 205, 206, 202, 212, 212, 208, 202, 204, 212, 204, 208, 215,
    207, 203, 203, 194, 200, 207, 206, 196, 205, 211, 218, 226, 233, 234, 226,
    222, 218, 214, 216, 217, 225, 234, 232, 223, 225, 215, 209, 214, 220, 230,
    225, 221, 218, 227, 226, 217, 220, 212, 207, 200, 193, 203, 204, 198, 193,
    190, 184, 183, 185, 179, 169, 176, 170, 176, 172, 178, 188, 188, 193, 187,
    190, 188, 188, 182, 181, 174, 168, 168, 176, 168, 161, 166, 172, 170, 170,
    167, 162, 155, 151, 150, 147, 150, 141, 132, 131, 122, 114, 104, 114, 111,
    115, 116, 108, 112, 120, 118, 118, 124, 119, 123, 132, 135, 140, 146, 155,
    148, 139, 134, 130, 137, 127, 120, 122, 123, 125, 118, 118, 121, 131, 121,
    113, 116, 114, 119, 122, 113, 114, 107, 101, 96, 94, 94, 94, 99, 96, 99,
    108, 112, 122, 132, 136, 131, 131, 134, 140, 133, 136, 133, 130, 130, 127,
    118, 109, 106, 106, 113, 106, 101, 95, 101, 105, 109, 107, 104, 109, 115,
    107, 102, 101, 109, 117, 113, 108, 114, 111, 103, 109, 105, 100, 105, 105,
    106, 116, 112, 108, 118, 127, 121, 130, 131, 123, 128, 130, 128, 137, 136,
    134, 129, 129, 127, 128, 132, 132, 124, 119, 116, 112, 104, 98, 107, 106,
    114, 112, 106, 114, 107, 112, 116, 117, 119, 125, 116, 125, 122, 114, 121,
    112, 115, 110, 109, 109, 117, 120, 116, 106, 115, 118, 109, 105, 115, 117,
    108, 103, 103, 100, 102, 105, 100, 92, 102, 107, 107, 99, 100, 101, 100, 96,
    102, 101, 91, 85, 84, 88, 97, 102, 107, 111, 109, 119, 113, 120, 127, 124,
    123, 118, 124, 118, 127, 119, 114, 123, 114, 105, 109, 103, 112, 114, 112,
    105, 104, 100, 100, 105, 111, 118, 128, 132, 128, 130, 124, 134, 128, 133,
    133, 135, 130, 122, 118, 111, 120, 121, 121, 112, 121, 114, 119, 122, 119,
    111, 115, 116, 118, 108, 109, 101, 98, 106, 104, 113, 120, 112, 120, 122,
    118, 108, 115, 114, 115, 123, 119, 112, 111, 105, 107, 110, 111, 109, 112,
    119, 110, 117, 109, 108, 111, 106, 96, 102, 105, 101, 97, 97, 103, 102, 101,
    100, 95, 97, 90, 88, 95, 105, 105, 96, 92, 98, 101, 102, 97, 89, 96, 101,
    94, 85, 95, 91, 89, 80, 82, 74, 77, 71, 81, 71, 67, 69, 67, 63, 69, 62, 61,
    67, 64, 57, 56, 56, 49, 54, 56, 52, 56, 64, 73, 80, 74, 68, 76, 67, 73, 66,
    61, 67, 66, 58, 61, 60, 67, 69, 59, 54, 48, 53, 48, 39, 42, 48, 53, 44, 40,
    37, 35, 42, 51, 54, 46, 46, 42, 38, 36, 39, 41, 40, 47, 47, 39, 46, 39, 43,
    43, 35, 42, 42, 41, 39, 47, 56, 65, 69, 71, 64, 60, 65, 64, 54, 50, 42, 45,
    42, 37, 40, 32, 39, 30, 39, 32, 29, 36, 41, 33, 31, 39, 35, 35, 42, 33, 25,
    31, 40, 38, 31, 36, 42, 40, 44, 37, 39, 37, 32, 41, 38, 30, 25, 30, 26, 28,
    26, 32, 24, 23, 14, 9, 3, 2, -3, 5, -1, 5, -3, -4, -7, -3, 7, 0, 5, 4, 6, 4,
    11, 19, 10, 6, 9, 12, 2, 0, 6, -3, -1, -7, -8, 1, -2, 5, 9, 2, -3, -8, -12,
    -20, -14, -6, -2, 4, 13, 20, 16, 16, 26, 34, 32, 36, 29, 35, 33, 36, 39, 47,
    42, 35, 33, 41, 35, 34, 36, 44, 43, 45, 35, 42, 35, 36, 43, 46, 37, 36, 46,
    37, 28, 30, 27, 26, 27, 31, 24, 16, 22, 24, 28, 28, 22, 26, 23, 25, 32, 23,
    30, 37, 40, 42, 38, 38, 39, 29, 22, 12, 4, 0, 10, 2, 1, 1, 3, 8, 17, 20, 21,
    23, 24, 31, 36, 31, 39, 38, 37, 47, 52, 59, 54, 46, 36, 45, 39, 48, 42, 48,
    49, 57, 50, 45, 53, 44, 40, 38, 43, 52, 60, 63, 70, 71, 77, 78, 83, 76, 74,
    80, 71, 81, 90, 86, 94, 88, 98, 106, 102, 96, 88, 94, 90, 99, 99, 91, 88,
    87, 95, 96, 88, 94, 103, 100, 103, 95, 96, 98, 93, 98, 101, 96, 88, 84, 82,
    80, 90, 99, 102, 97, 105, 113, 110, 114, 105, 98, 94, 101, 102, 108, 112,
    112, 106, 100, 100, 103, 102, 97, 97, 90, 89, 87, 85, 93, 98, 94, 99, 104,
    113, 115, 106, 106, 110, 110, 106, 109, 104, 100, 102, 93, 84, 85, 81, 85,
    76, 70, 70, 75, 85, 91, 95, 95, 105, 102, 110, 105, 100, 103, 102, 110, 110,
    116, 120, 124, 116, 116, 126, 124, 124, 121, 112, 112, 106, 103, 113, 114,
    118, 122, 123, 124, 118, 123, 132, 133, 133, 130, 130, 125, 124, 124, 115,
    123, 133, 125, 128, 123, 128, 124, 133, 127, 127, 119, 122, 121, 126, 132,
    122, 117, 123, 121, 117, 124, 121, 125, 118, 112, 102, 103, 96, 97, 103,
    107, 104, 109, 99, 106, 105, 97, 104, 105, 113, 103, 98, 89, 88, 92, 84, 91,
    83, 90, 93, 99, 106, 115, 115, 111, 102, 93, 99, 92, 97, 96, 100, 104, 111,
    103, 105, 103, 107, 104, 102, 103, 94, 102, 96, 91, 99, 90, 97, 88, 87, 82,
    90, 88, 88, 95, 94, 92, 98, 101, 103, 110, 101, 109, 111, 112, 110, 106,
    115, 118, 118, 121, 120, 115, 113, 111, 117, 124, 119, 117, 126, 130, 139,
    130, 135, 132, 128, 133, 138, 141, 137, 145, 149, 159, 152, 154, 158, 164,
    163, 172, 164, 165, 169, 178, 185, 179, 182, 188, 184, 184, 175, 178, 173,
    174, 165, 164, 173, 175, 168, 163, 163, 156, 156, 152, 146, 143, 141, 144,
    152, 158, 164, 166, 166, 170, 167, 165, 165, 173, 179, 178, 180, 189, 180,
    173, 175, 183, 184, 185, 187, 178, 180, 184, 183, 180, 183, 188, 180, 187,
    181, 189, 185, 185, 180, 174, 179, 184, 186, 195, 189, 197, 191, 200, 197,
    199, 208, 207, 207, 214, 207, 213, 216, 210, 216, 209, 217, 216, 226, 231,
    222, 224, 219, 224, 222, 218, 224, 229, 236, 228, 225, 228, 219, 227, 223,
    226, 234, 228, 231, 234, 236, 228, 237, 240, 231, 225, 235, 229, 225, 231,
    223, 217, 226, 219, 224, 230, 235, 225, 234, 232, 240, 249, 241, 234, 237,
    229, 234, 243, 240, 242, 241, 248, 241, 232, 225, 227, 225, 219, 226, 232,
    224, 233, 227, 231, 233, 232, 226, 221, 212, 212, 217, 214, 222, 231, 236,
    242, 239, 231, 236, 230, 236, 227, 221, 222, 228, 231, 236, 236, 244, 242,
    251, 249, 248, 241, 235, 240, 237, 245, 248, 249, 241, 249, 241, 235, 237,
    232, 229, 232, 225, 231, 231, 238, 235, 236, 233, 239, 233, 229, 225, 218,
    210, 220, 215, 205, 203, 196, 200, 191, 195, 193, 201, 208, 216, 217, 226,
    228, 222, 226, 225, 234, 243, 245, 239, 246, 256, 259, 263, 258, 261, 268,
    264, 258, 267, 267, 260, 265, 267, 259, 260, 260, 265, 258, 257, 259, 262,
    257, 259, 259, 255, 257, 264, 263, 267, 276, 278, 276, 268, 271, 280, 289,
    285, 280, 280, 272, 276, 270, 271, 264, 262, 256, 260, 263, 263, 271, 277,
    270, 263, 254, 245, 248, 248, 241, 238, 229, 231, 235, 242, 245, 240, 238,
    240, 235, 239, 239, 247, 251, 260, 250, 256, 247, 257, 258, 257, 266, 258,
    259, 256, 247, 245, 246, 245, 246, 239, 241, 237, 228, 228, 225, 233, 237,
    245, 236, 237, 246, 238, 241, 251, 244, 251, 252, 244, 246, 246, 253, 248,
    251, 245, 251, 255, 257, 260, 266, 267, 264, 268, 264, 270, 261, 259, 256,
    261, 264, 255, 245, 241, 236, 239, 242, 242, 235, 235, 239, 238, 246, 245,
    235, 239, 244, 253, 245, 242, 249, 244, 237, 235, 245, 255, 255, 261, 257,
    264, 271, 269, 274, 272, 270, 261, 255, 264, 262, 269, 274, 271, 270, 270,
    261, 256, 246, 250, 256, 247, 237, 238, 231, 234, 233, 232, 242, 246, 248,
    247, 251, 252, 242, 238, 234, 238, 232, 225, 232, 226, 229, 226, 222, 226,
    217, 218, 224, 221, 216, 222, 224, 232, 235, 239, 231, 234, 244, 234, 241,
    242, 237, 241, 248, 239, 232, 224, 231, 229, 234, 232, 236, 238, 231, 223,
    231, 233, 238, 237, 246, 246, 253, 253, 254, 264, 262, 268, 265, 256, 257,
    262, 261, 270, 276, 273, 274, 265, 259, 253, 254, 246, 254, 245, 242, 242,
    236, 228, 223, 231, 223, 228, 238, 230, 225, 221, 211, 217, 226, 219, 228,
    233, 240, 244, 234, 239, 249, 246, 256, 262, 253, 255, 264, 255, 250, 244,
    246, 250, 257, 256, 263, 266, 268, 274, 275, 280, 272, 277, 278, 286, 278,
    270, 261, 269, 270, 278, 282, 275, 279, 273, 265, 267, 271, 279, 288, 293,
    296, 294, 301, 300, 295, 297, 305, 306, 310, 317, 319, 313, 313, 317, 324,
    332, 340, 344, 338, 344, 346, 349, 344, 341, 342, 332, 335, 329, 325, 326,
    331, 331, 330, 339, 336, 343, 350, 346, 353, 353, 358, 367, 377, 384, 385,
    395, 400, 408, 416, 411, 416, 424, 415, 412, 413, 407, 399, 398, 402, 397,
    395, 399, 400, 407, 402, 408, 404, 401, 404, 401, 392, 393, 398, 406, 414,
    420, 424, 418, 419, 411, 401, 410, 416, 419, 415, 413, 412, 409, 414, 421,
    427, 431, 434, 441, 443, 442, 449, 448, 444, 451, 457, 458, 459, 468, 467,
    461, 451, 441, 434, 426, 436, 439, 447, 454, 446, 441, 436, 446, 449, 444,
    446, 453, 455, 463, 455, 451, 445, 445, 452, 450, 441, 432, 422, 419, 415,
    414, 414, 404, 399, 397, 400, 400, 391, 384, 377, 374, 381, 375, 382, 392,
    383, 391, 393, 394, 384, 374, 370, 367, 369, 376, 368, 359, 362, 364, 360,
    358, 358, 350, 353, 346, 338, 344, 353, 358, 364, 372, 372, 365, 357, 353,
    363, 360, 363, 364, 370, 366, 361, 366, 369, 367, 358, 354, 356, 362, 358,
    360, 366, 376, 367, 357, 358, 366, 369, 364, 354, 348, 346, 353, 363, 371,
    374, 372, 370, 377, 373, 380, 374, 382, 374, 370, 380, 371, 363, 368, 377,
    387, 384, 390, 396, 406, 408, 409, 419, 418, 417, 423, 418, 425, 421, 422,
    420, 411, 418, 421, 424, 431, 433, 440, 440, 448, 445, 443, 442, 441, 448,
    452, 450, 458, 460, 460, 465, 464, 461, 465, 456, 455, 453, 446, 448, 455,
    455, 449, 440, 442, 432, 433, 426, 419, 422, 414, 424, 414, 410, 412, 402,
    409, 413, 422, 426, 418, 410, 406, 410, 402, 397, 395, 393, 391, 393, 392,
    385, 382, 378, 379, 388, 389, 384, 384, 390, 393, 391, 397, 392, 394, 401,
    403, 401, 402, 402, 408, 404, 401, 409, 413, 407, 401, 406, 414, 408, 401,
    402, 403, 402, 399, 394, 400, 395, 396, 395, 393, 387, 379, 389, 384, 381,
    386, 384, 388, 393, 385, 388, 395, 389, 392, 388, 395, 395, 401, 398, 398,
    393, 392, 395, 398, 390, 397, 398, 395, 400, 400, 409, 410, 412, 417, 408,
    408, 415, 415, 419, 411, 402, 404, 405, 401, 399, 403, 411, 407, 415, 413,
    409, 408, 402, 397, 395, 389, 388, 389, 389, 398, 392, 391, 392, 391, 400,
    398, 405, 398, 403, 395, 388, 392, 397, 391, 392, 384, 391, 384, 394, 397,
    393, 397, 399, 398, 408, 407, 404, 404, 398, 399, 392, 382, 381, 375, 375,
    377, 382, 381, 377, 378, 386, 379, 380, 375, 369, 367, 376, 383, 388, 390,
    394, 384, 379, 371, 367, 372, 370, 376, 367, 374, 384, 381, 387, 391, 383,
    389, 398, 390, 394, 401, 403, 407, 403, 411, 410, 413, 418, 423, 413, 408,
    411, 406, 400, 396, 392, 387, 395, 399, 401, 409, 417, 415, 414, 413, 418,
    416, 425, 421, 430, 436, 444, 446, 445, 452, 452, 458, 461, 471, 468, 458,
    451, 448, 452, 453, 453, 457, 464, 466, 459, 452, 461, 468, 471, 470, 475,
    479, 475, 471, 463, 460, 467, 459, 457, 449, 446, 437, 427, 428, 433, 437,
    428, 421, 426, 430, 425, 435, 433, 425, 432, 438, 430, 435, 435, 433, 432,
    424, 416, 417, 410, 409, 402, 408, 411, 420, 418, 415, 415, 406, 410, 405,
    414, 407, 406, 416, 423, 426, 420, 413, 412, 418, 414, 420, 420, 410, 405,
    396, 397, 395, 391, 386, 390, 385, 385, 385, 377, 372, 373, 368, 364, 368,
    375, 367, 372, 366, 374, 367, 360, 368, 371, 380, 380, 387, 383, 374, 366,
    365, 371, 369, 362, 368, 375, 367, 373, 377, 374, 375, 368, 365, 367, 376,
    371, 366, 358, 351, 360, 352, 343, 343, 340, 340, 331, 328, 319, 328, 319,
    313, 319, 314, 319, 311, 313, 316, 323, 330, 334, 332, 335, 332, 335, 327,
    329, 333, 325, 320, 322, 317, 308, 307, 316, 309, 303, 307, 304, 304, 307,
    299, 292, 285, 280, 290, 293, 288, 284, 294, 304, 304, 295, 291, 298, 306,
    307, 313, 317, 315, 324, 333, 343, 353, 360, 351, 346, 346, 341, 344, 346,
    348, 351, 345, 338, 332, 341, 348, 343, 335, 335, 328, 330, 337, 345, 346,
    352, 362, 355, 347, 350, 344, 351, 346, 339, 342, 334, 327, 324, 320, 330,
    328, 324, 323, 316, 316, 317, 324, 321, 311, 304, 313, 318, 324, 320, 324,
    318, 315, 320, 313, 312, 314, 311, 318, 326, 319, 314, 305, 296, 303, 303,
    297, 294, 296, 302, 296, 304, 313, 311, 303, 311, 307, 308, 305, 303, 303,
    307, 304, 313, 313, 315, 317, 316, 321, 320, 327, 331, 326, 334, 326, 323,
    327, 331, 329, 339, 335, 345, 335, 326, 318, 311, 316, 320, 330, 320, 322,
    324, 318, 319, 314, 317, 323, 314, 320, 312, 319, 321, 321, 318, 315, 308,
    311, 315, 321, 324, 331, 336, 334, 337, 336, 333, 334, 333, 341, 345, 350,
    342, 347, 347, 340, 349, 354, 362, 364, 363, 368, 372, 378, 374, 371, 363,
    355, 349, 351, 353, 359, 366, 367, 362, 368, 377, 367, 363, 359, 364, 370,
    373, 365, 372, 372, 374, 374, 368, 364, 359, 352, 344, 349, 340, 337, 334,
    344, 353, 351, 359, 365, 374, 380, 382, 385, 392, 400, 404, 410, 419, 413,
    414, 422, 426, 431, 429, 422, 414, 414, 409, 399, 400, 399, 395, 389, 394,
    396, 389, 379, 372, 374, 380, 388, 388, 390, 389, 382, 373, 372, 381, 388,
    389, 393, 400, 398, 397, 396, 386, 382, 384, 383, 388, 379, 380, 378, 374,
    378, 382, 392, 382, 392, 394, 393, 392, 389, 389, 383, 378, 387, 384, 375,
    374, 370, 362, 358, 362, 366, 357, 365, 356, 348, 355, 363, 366, 375, 379,
    370, 372, 363, 373, 365, 361, 356, 348, 343, 338, 336, 328, 320, 329, 321,
    313, 311, 307, 303, 303, 306, 300, 299, 305, 296, 291, 297, 306, 301, 300,
    308, 305, 314, 314, 321, 324, 320, 329, 322, 314, 314, 316, 316, 307, 299,
    307, 300, 301, 310, 307, 313, 304, 307, 304, 305, 299, 304, 295, 290, 291,
    282, 287, 291, 290, 289, 280, 288, 283, 280, 274, 277, 283, 292, 299, 295,
    304, 302, 295, 288, 279, 279, 274, 266, 262, 262, 259, 259, 254, 257, 259,
    259, 254, 253, 260, 267, 264, 272, 273, 266, 276, 275, 276, 286, 277, 270,
    279, 283, 285, 284, 287, 292, 291, 296, 289, 292, 283, 274, 273, 279, 272,
    265, 268, 263, 264, 261, 255, 261, 264, 256, 247, 252, 261, 257, 255, 255,
    253, 245, 252, 245, 255, 263, 260, 250, 248, 252, 249, 242, 240, 239, 247,
    237, 246, 245, 245, 241, 247, 243, 235, 226, 220, 222, 231, 237, 230, 236,
    232, 235, 242, 249, 248, 255, 260, 267, 277, 278, 286, 294, 290, 292, 297,
    302, 302, 304, 306, 298, 307, 303, 301, 310, 306, 308, 300, 300, 299, 307,
    308, 301, 298, 295, 302, 303, 312, 302, 303, 308, 308, 315, 305, 309, 313,
    303, 305, 301, 306, 304, 297, 302, 300, 303, 303, 298, 308, 310, 319, 312,
    312, 319, 324, 327, 323, 316, 311, 304, 308, 302, 294, 304, 301, 298, 291,
    282, 273, 270, 273, 278, 271, 262, 258, 265, 258, 256, 247, 240, 237, 239,
    243, 241, 236, 242, 243, 236, 234, 235, 235, 230, 226, 217, 212, 210, 212,
    204, 199, 208, 210, 213, 209, 205, 208, 204, 205, 196, 199, 192, 187, 190,
    183, 179, 186, 187, 187, 181, 185, 188, 194, 204, 203, 207, 207, 206, 200,
    194, 184, 187, 188, 195, 192, 185, 187, 181, 178, 171, 181, 182, 182, 173,
    179, 173, 165, 156, 147, 141, 136, 132, 135, 138, 132, 135, 136, 135, 127,
    119, 126, 121, 123, 118, 109, 105, 110, 106, 112, 115, 106, 110, 108, 114,
    122, 116, 122, 129, 127, 118, 123, 119, 123, 133, 124, 121, 125, 133, 131,
    132, 136, 126, 120, 119, 111, 114, 112, 118, 124, 129, 131, 132, 130, 137,
    136, 128, 120, 113, 119, 112, 117, 109, 117, 110, 114, 112, 114, 115, 107,
    100, 99, 105, 114, 121, 130, 128, 137, 127, 127, 122, 121, 124, 124, 132,
    128, 132, 122, 121, 118, 110, 114, 113, 111, 115, 117, 121, 127, 127, 130,
    121, 129, 126, 134, 142, 144, 147, 138, 133, 139, 130, 135, 125, 117, 125,
    135, 133, 137, 135, 140, 135, 129, 132, 130, 126, 132, 138, 136, 128, 118,
    122, 129, 122, 115, 107, 100, 100, 97, 107, 110, 103, 98, 102, 93, 98, 95,
    92, 93, 95, 90, 84, 78, 79, 75, 74, 82, 89, 87, 93, 84, 79, 83, 92, 92, 85,
    93, 97, 105, 107, 106, 110, 117, 114, 120, 119, 109, 113, 115, 119, 118,
    120, 120, 116, 111, 111, 108, 114, 118, 113, 110, 110, 102, 102, 93, 92, 87,
    88, 87, 94, 92, 94, 102, 99, 104, 108, 99, 90, 96, 95, 103, 98, 103, 98,
    104, 101, 101, 108, 112, 119, 124, 130, 127, 120, 112, 111, 118, 123, 126,
    131, 131, 125, 128, 120, 118, 110, 119, 125, 134, 133, 139, 147, 138, 136,
    138, 146, 137, 134, 128, 125, 118, 127, 127, 124, 121, 124, 115, 109, 118,
    113, 106, 109, 100, 105, 110, 113, 106, 106, 110, 118, 113, 114, 118, 119,
    115, 114, 107, 103, 111, 107, 111, 108, 106, 105, 113, 108, 105, 96, 103,
    104, 104, 98, 108, 100, 102, 101, 107, 107, 117, 121, 117, 123, 116, 116,
    123, 127, 133, 138, 141, 139, 142, 147, 139, 144, 139, 130, 124, 123, 127,
    121, 124, 132, 141, 148, 139, 139, 129, 134, 132, 141, 133, 127, 129, 136,
    144, 145, 141, 146, 154, 149, 152, 159, 152, 146, 148, 141, 132, 139, 134,
    141, 143, 145, 146, 146, 150, 145, 143, 142, 141, 139, 136, 136, 135, 132,
    141, 149, 152, 149, 146, 148, 157, 158, 159, 164, 171, 170, 166, 167, 173,
    174, 175, 184, 188, 197, 188, 198, 206, 203, 205, 208, 207, 209, 203, 201,
    196, 197, 188, 196, 205, 215, 218, 210, 212, 210, 213, 215, 223, 225, 224,
    223, 231, 223, 223, 221, 220, 226, 231, 227, 234, 241, 235, 229, 222, 219,
    216, 223, 218, 209, 217, 217, 210, 205, 202, 192, 185, 194, 203, 196, 193,
    191, 198, 189, 183, 188, 190, 199, 195, 194, 195, 200, 203, 198, 201, 201,
    196, 196, 202, 199, 207, 197, 201, 202, 193, 201, 207, 209, 217, 214, 215,
    222, 225, 222, 230, 230, 237, 234, 231, 227, 225, 223, 224, 215, 215, 211,
    206, 203, 201, 196, 188, 185, 189, 191, 186, 176, 179, 176, 167, 167, 163,
    169, 163, 163, 163, 167, 175, 169, 159, 154, 149, 144, 154, 145, 146, 137,
    128, 136, 132, 127, 118, 111, 105, 103, 100, 109, 118, 123, 129, 138, 135,
    133, 138, 130, 140, 149, 159, 161, 170, 178, 177, 175, 168, 176, 166, 171,
    162, 168, 168, 166, 172, 172, 177, 174, 179, 177, 178, 188, 182, 182, 183,
    187, 181, 186, 192, 188, 195, 202, 204, 195, 202, 210, 216, 219, 221, 229,
    222, 221, 216, 226, 217, 226, 227, 221, 227, 224, 232, 225, 231, 235, 236,
    240, 240, 240, 231, 224, 219, 212, 219, 222, 216, 224, 231, 225, 215, 214,
    205, 195, 203, 212, 221, 224, 215, 215, 207, 202, 201, 209, 204, 200, 194,
    185, 177, 180, 183, 186, 193, 200, 202, 193, 185, 190, 200, 208, 206, 206,
    212, 202, 199, 192, 192, 197, 197, 204, 200, 191, 185, 183, 173, 174, 166,
    173, 177, 185, 187, 179, 186, 191, 193, 199, 195, 193, 195, 193, 195, 196,
    193, 193, 188, 188, 198, 194, 186, 193, 201, 199, 207, 217, 225, 232, 227,
    218, 217, 219, 216, 210, 201, 199, 199, 195, 186, 187, 189, 180, 175, 167,
    164, 169, 167, 161, 170, 180, 185, 179, 170, 167, 170, 164, 158, 154, 148,
    154, 152, 162, 152, 161, 162, 159, 169, 177, 183, 187, 185, 187, 191, 199,
    195, 196, 192, 197, 202, 207, 216, 220, 228, 231, 226, 234, 242, 241, 232,
    225, 215, 219, 217, 226, 218, 223, 226, 236, 232, 237, 229, 225, 234, 233,
    232, 233, 242, 235, 236, 231, 228, 227, 225, 229, 236, 229, 228, 235, 225,
    226, 217, 216, 215, 224, 216, 212, 220, 226, 217, 223, 222, 219, 222, 216,
    222, 214, 214, 216, 218, 223, 223, 214, 223, 216, 218, 228, 228, 238, 235,
    244, 249, 254, 256, 250, 242, 234, 238, 231, 233, 235, 227, 233, 236, 236,
    228, 232, 238, 243, 248, 240, 245, 245, 243, 251, 251, 261, 263, 263, 267,
    262, 264, 271, 265, 273, 264, 254, 256, 256, 249, 246, 240, 233, 226, 233,
    231, 227, 220, 228, 224, 223, 227, 230, 235, 243, 242, 242, 242, 250, 253,
    253, 246, 241, 249, 243, 244, 249, 249, 247, 255, 247, 243, 249, 256, 249,
    248, 247, 240, 237, 240, 232, 231, 239, 248, 241, 251, 241, 234, 233, 234,
    234, 236, 232, 230, 224, 219, 215, 219, 212, 203, 195, 185, 190, 181, 173,
    183, 188, 185, 194, 198, 192, 202, 211, 214, 206, 208, 200, 191, 181, 183,
    185, 193, 196, 203, 193, 188, 184, 181, 186, 192, 194, 192, 188, 194, 187,
    182, 176, 172, 177, 168, 171, 170, 164, 158, 160, 166, 163, 162, 160, 167,
    169, 171, 169, 170, 161, 161, 171, 181, 190, 184, 193, 184, 191, 187, 180,
    185, 187, 180, 178, 180, 183, 191, 187, 194, 187, 179, 180, 174, 183, 183,
    180, 188, 186, 180, 179, 177, 170, 179, 177, 174, 165, 169, 176, 168, 172,
    178, 182, 191, 199, 205, 204, 209, 206, 208, 210, 213, 223, 221, 225, 216,
    223, 227, 227, 224, 217, 212, 212, 208, 201, 199, 200, 209, 206, 202, 207,
    206, 213, 213, 205, 211, 211, 216, 221, 215, 205, 198, 188, 193, 199, 201,
    198, 195, 191, 186, 176, 182, 188, 180, 171, 163, 168, 163, 158, 155, 145,
    154, 150, 158, 162, 170, 172, 169, 172, 168, 161, 162, 154, 146, 144, 138,
    136, 138, 135, 132, 134, 131, 123, 122, 128, 130, 133, 128, 129, 120, 127,
    121, 113, 104, 96, 98, 100, 91, 93, 98, 92, 86, 84, 88, 91, 99, 89, 98, 101,
    109, 108, 100, 90, 86, 88, 97, 93, 94, 90, 87, 82, 83, 90, 88, 96, 92, 90,
    90, 98, 92, 83, 84, 85, 90, 84, 85, 79, 72, 79, 80, 82, 85, 82, 90, 88, 92,
    86, 78, 71, 62, 56, 58, 67, 68, 71, 65, 67, 62, 63, 72, 65, 55, 64, 61, 69,
    64, 71, 67, 67, 57, 66, 74, 68, 71, 80, 81, 80, 81, 84, 89, 94, 89, 88, 94,
    97, 105, 100, 99, 96, 102, 106, 107, 112, 117, 108, 98, 94, 92, 91, 90, 91,
    83, 89, 96, 87, 95, 93, 89, 86, 82, 76, 67, 76, 66, 57, 61, 53, 61, 53, 57,
    51, 53, 61, 69, 79, 76, 75, 76, 70, 65, 70, 60, 62, 62, 65, 55, 48, 53, 51,
    43, 48, 53, 62, 70, 74, 73, 79, 72, 69, 66, 67, 61, 52, 54, 54, 56, 56, 63,
    65, 66, 71, 71, 71, 71, 62, 70, 78, 78, 87, 90, 83, 76, 68, 64, 64, 74, 83,
    75, 71, 66, 69, 78, 71, 69, 68, 77, 83, 87, 82, 72, 69, 75, 76, 79, 73, 74,
    71, 71, 70, 66, 65, 75, 77, 77, 75, 71, 64, 61, 52, 50, 45, 48, 55, 62, 65,
    60, 55, 52, 50, 47, 56, 59, 68, 69, 61, 70, 74, 72, 73, 72, 71, 71, 67, 70,
    62, 59, 67, 63, 65, 68, 59, 52, 48, 42, 47, 54, 62, 68, 70, 73, 76, 67, 64,
    70, 71, 77, 83, 91, 85, 94, 88, 92, 94, 93, 83, 90, 91, 86, 81, 74, 78, 77,
    72, 78, 69, 76, 73, 70, 69, 65, 70, 62, 69, 62, 59, 64, 61, 62, 65, 58, 64,
    69, 64, 60, 64, 63, 69, 77, 73, 64, 60, 50, 41, 41, 40, 50, 45, 37, 33, 38,
    43, 33, 24, 28, 18, 15, 10, 18, 27, 19, 13, 15, 20, 12, 7, -2, -8, -11, -9,
    -6, 0, -8, -15, -22, -28, -31, -38, -34, -42, -32, -36, -30, -23, -27, -33,
    -35, -34, -25, -16, -16, -13, -6, 0, -2, 0, 2, 9, 2, -1, 0, 5, 9, 8, -1, -9,
    -8, -13, -13, -11, -5, -13, -7, -12, -17, -20, -21, -24, -16, -20, -18, -19,
    -14, -16, -15, -10, -5, -7, -9, -17, -25, -27, -21, -26, -33, -40, -46, -51,
    -60, -51, -50, -43, -40, -47, -55, -50, -44, -34, -37, -29, -25, -29, -34,
    -30, -30, -34, -36, -28, -24, -29, -34, -41, -46, -45, -51, -59, -60, -54,
    -64, -66, -59, -60, -67, -71, -79, -78, -69, -66, -73, -79, -74, -78, -77,
    -71, -73, -77, -71, -72, -74, -70, -69, -78, -72, -67, -75, -83, -92, -91,
    -85, -75, -73, -79, -81, -73, -71, -65, -62, -54, -51, -56, -63, -62, -61,
    -54, -47, -49, -41, -34, -39, -38, -43, -49, -45, -42, -42, -39, -43, -46,
    -37, -46, -42, -38, -46, -54, -62, -60, -61, -63, -59, -59, -67, -76, -83,
    -90, -95, -103, -103, -104, -105, -111, -120, -119, -124, -128, -131, -122,
    -129, -137, -143, -135, -141, -142, -147, -145, -151, -143, -137, -132,
    -136, -135, -143, -138, -133, -126, -128, -137, -140, -148, -149, -153,
    -158, -157, -164, -154, -160, -167, -161, -155, -147, -155, -149, -148,
    -146, -137, -140, -132, -126, -132, -129, -133, -130, -122, -121, -124,
    -124, -129, -120, -123, -115, -108, -102, -104, -106, -105, -110, -101, -93,
    -95, -103, -109, -101, -94, -84, -86, -85, -78, -71, -67, -69, -61, -68,
    -64, -67, -66, -66, -57, -58, -66, -72, -69, -61, -55, -56, -65, -68, -76,
    -70, -73, -70, -64, -70, -71, -77, -85, -79, -70, -70, -60, -65, -60, -63,
    -61, -70, -62, -55, -50, -53, -52, -42, -45, -37, -44, -39, -36, -27, -31,
    -34, -35, -33, -39, -45, -38, -48, -39, -43, -43, -38, -29, -28, -22, -17,
    -9, -13, -12, -12, -18, -16, -9, -16, -15, -25, -32, -32, -24, -29, -23,
    -13, -18, -14, -23, -23, -16, -20, -25, -18, -19, -18, -15, -23, -27, -22,
    -14, -19, -28, -34, -44, -35, -45, -51, -51, -46, -42, -43, -50, -53, -46,
    -39, -44, -43, -36, -32, -33, -31, -35, -40, -47, -45, -52, -54, -55, -46,
    -54, -53, -50, -42, -44, -50, -46, -44, -49, -58, -55, -60, -64, -61, -67,
    -62, -65, -58, -50, -55, -59, -67, -61, -68, -76, -81, -82, -85, -81, -74,
    -77, -78, -68, -60, -56, -53, -44, -43, -52, -47, -57, -59, -66, -71, -72,
    -72, -73, -72, -78, -70, -69, -74, -64, -63, -66, -59, -62, -52, -52, -47,
    -50, -40, -42, -46, -43, -47, -55, -55, -58, -63, -54, -64, -66, -63, -73,
    -78, -81, -75, -69, -62, -60, -57, -49, -42, -33, -27, -23, -16, -25, -31,
    -27, -31, -33, -23, -25, -16, -24, -17, -24, -33, -41, -40, -43, -42, -51,
    -59, -50, -50, -48, -45, -39, -40, -39, -42, -50, -56, -62, -53, -63, -54,
    -52, -48, -43, -44, -38, -35, -42, -48, -40, -34, -43, -49, -51, -52, -45,
    -40, -35, -38, -35, -44, -45, -42, -48, -48, -49, -50, -45, -39, -40, -46,
    -45, -36, -26, -24, -29, -35, -30, -31, -29, -21, -18, -23, -27, -28, -35,
    -29, -39, -34, -30, -31, -28, -28, -30, -33, -40, -46, -49, -40, -30, -21,
    -28, -28, -25, -21, -18, -10, -6, -3, 0, -3, -6, -4, -4, -8, -12, -20, -14,
    -20, -11, -7, -16, -25, -17, -11, -10, -2, -7, -14, -22, -26, -35, -41, -36,
    -30, -32, -38, -47, -53, -60, -58, -56, -53, -49, -41, -49, -44, -34, -38,
    -43, -49, -55, -45, -38, -35, -42, -48, -46, -48, -50, -55, -51, -50, -57,
    -51, -41, -35, -29, -31, -25, -27, -27, -19, -22, -20, -17, -19, -26, -16,
    -21, -26, -33, -28, -22, -31, -37, -32, -37, -33, -40, -34, -32, -38, -46,
    -42, -45, -50, -54, -53, -62, -52, -52, -50, -53, -50, -40, -46, -53, -49,
    -54, -50, -58, -64, -68, -65, -60, -60, -64, -73, -68, -63, -70, -67, -67,
    -74, -67, -73, -78, -74, -72, -69, -64, -70, -74, -67, -67, -76, -72, -64,
    -72, -64, -59, -68, -71, -75, -69, -77, -79, -80, -74, -79, -86, -81, -85,
    -77, -77, -83, -87, -90, -87, -79, -81, -84, -79, -86, -79, -80, -72, -73,
    -82, -85, -94, -90, -89, -91, -98, -108, -100, -90, -92, -93, -99, -92, -83,
    -82, -72, -70, -68, -68, -74, -66, -58, -65, -70, -79, -88, -81, -82, -89,
    -91, -87, -78, -79, -79, -82, -86, -86, -78, -81, -87, -90, -91, -93, -92,
    -85, -83, -76, -80, -86, -77, -82, -85, -85, -79, -87, -80, -71, -80, -82,
    -73, -70, -61, -58, -54, -51, -58, -54, -60, -52, -54, -50, -49, -50, -59,
    -54, -53, -46, -54, -47, -43, -47, -49, -40, -41, -38, -29, -21, -12, -5, 1,
    6, 7, 9, 10, 18, 22, 24, 20, 21, 24, 27, 37, 34, 40, 48, 50, 52, 57, 56, 57,
    63, 58, 51, 47, 52, 50, 41, 39, 40, 45, 41, 43, 49, 56, 64, 69, 75, 74, 81,
    89, 96, 91, 88, 79, 81, 73, 73, 68, 63, 56, 57, 55, 62, 59, 59, 55, 51, 61,
    55, 63, 60, 61, 56, 55, 53, 56, 47, 38, 36, 38, 30, 20, 15, 22, 14, 8, 8, 5,
    8, 7, -1, 1, 6, 2, 3, 12, 21, 29, 31, 23, 18, 23, 31, 24, 32, 33, 26, 29,
    38, 39, 40, 49, 49, 50, 50, 53, 46, 47, 38, 36, 29, 38, 41, 33, 39, 32, 23,
    23, 26, 29, 37, 46, 42, 43, 36, 32, 38, 43, 38, 39, 35, 32, 26, 19, 27, 35,
    36, 30, 28, 19, 20, 20, 28, 34, 40, 31, 29, 38, 44, 54, 46, 49, 59, 54, 48,
    41, 33, 33, 28, 24, 23, 18, 11, 14, 15, 11, 15, 16, 22, 17, 21, 22, 22, 32,
    38, 46, 43, 39, 33, 38, 41, 39, 43, 50, 58, 62, 54, 45, 37, 44, 39, 32, 37,
    41, 42, 36, 42, 47, 42, 40, 38, 43, 52, 45, 49, 44, 42, 45, 43, 49, 50, 45,
    45, 47, 42, 39, 40, 32, 30, 38, 28, 29, 34, 35, 33, 25, 35, 42, 37, 46, 44,
    35, 33, 38, 36, 26, 35, 32, 36, 41, 41, 40, 45, 47, 40, 43, 43, 37, 39, 48,
    39, 43, 35, 40, 49, 46, 53, 60, 59, 68, 75, 83, 90, 98, 98, 95, 97, 87, 89,
    80, 71, 81, 89, 88, 88, 96, 95, 93, 95, 92, 93, 90, 89, 85, 76, 77, 69, 74,
    70, 66, 66, 58, 67, 60, 68, 77, 79, 79, 87, 79, 87, 90, 82, 89, 95, 95, 92,
    84, 78, 83, 80, 74, 79, 71, 61, 66, 64, 70, 70, 73, 79, 70, 74, 81, 88, 80,
    78, 70, 61, 53, 63, 60, 60, 59, 65, 56, 65, 62, 57, 53, 62, 72, 64, 65, 61,
    66, 59, 65, 70, 74, 67, 60, 68, 78, 87, 91, 97, 96, 100, 104, 107, 112, 113,
    120, 123, 128, 130, 130, 133, 139, 130, 132, 127, 121, 130, 139, 144, 149,
    140, 136, 143, 139, 143, 134, 132, 139, 147, 154, 159, 160, 167, 162, 157,
    151, 149, 156, 157, 154, 154, 155, 162, 154, 162, 161, 152, 158, 157, 148,
    140, 134, 135, 126, 119, 120, 113, 119, 115, 125, 127, 118, 112, 104, 108,
    106, 98, 91, 89, 82, 86, 94, 94, 92, 95, 92, 93, 103, 105, 107, 111, 119,
    115, 109, 108, 118, 118, 118, 122, 115, 124, 125, 134, 131, 140, 139, 132,
    131, 130, 136, 134, 130, 128, 125, 126, 134, 126, 126, 130, 124, 132, 139,
    140, 148, 146, 148, 151, 143, 138, 140, 149, 147, 156, 149, 145, 145, 150,
    141, 131, 126, 123, 114, 113, 106, 102, 111, 117, 123, 119, 122, 124, 127,
    122, 118, 109, 105, 103, 106, 104, 100, 90, 95, 96, 102, 106, 104, 105, 96,
    102, 96, 96, 91, 97, 102, 95, 92, 92, 94, 89, 82, 85, 85, 76, 79, 83, 77,
    68, 61, 52, 53, 47, 37, 31, 39, 36, 37, 31, 35, 38, 39, 34, 41, 36, 34, 42,
    47, 45, 53, 46, 51, 59, 51, 55, 61, 53, 49, 48, 41, 43, 50, 43, 47, 46, 45,
    41, 37, 42, 50, 53, 59, 64, 58, 67, 60, 63, 65, 56, 57, 49, 46, 41, 42, 51,
    43, 37, 45, 35, 39, 44, 39, 43, 36, 29, 31, 31, 40, 40, 32, 30, 24, 31, 31,
    35, 42, 32, 36, 37, 44, 52, 60, 63, 60, 55, 46, 40, 31, 33, 23, 32, 35, 32,
    32, 31, 23, 19, 26, 22, 27, 17, 23, 13, 10, 14, 7, 3, 10, 17, 22, 30, 36,
    30, 37, 43, 44, 51, 45, 36, 38, 40, 38, 32, 27, 25, 16, 24, 15, 23, 25, 24,
    25, 18, 11, 17, 10, 17, 18, 14, 13, 19, 9, 14, 16, 23, 22, 30, 36, 40, 49,
    39, 40, 45, 55, 63, 58, 55, 61, 52, 57, 54, 57, 54, 49, 56, 56, 66, 60, 54,
    50, 46, 37, 37, 42, 36, 33, 34, 25, 16, 18, 20, 21, 29, 34, 37, 38, 46, 52,
    44, 53, 58, 55, 49, 59, 66, 65, 58, 65, 64, 60, 70, 77, 80, 71, 63, 55, 62,
    59, 56, 65, 57, 53, 60, 60, 60, 63, 54, 46, 50, 52, 54, 52, 44, 48, 55, 54,
    47, 42, 49, 48, 45, 37, 41, 35, 33, 35, 42, 38, 47, 54, 46, 53, 62, 62, 64,
    58, 59, 65, 60, 56, 66, 66, 64, 74, 75, 79, 86, 81, 90, 92, 86, 87, 81, 84,
    92, 85, 79, 87, 95, 99, 101, 99, 98, 89, 85, 94, 103, 110, 108, 100, 97,
    104, 99, 103, 112, 119, 115, 120, 124, 119, 119, 128, 137, 144, 151, 157,
    166, 174, 170, 174, 182, 182, 191, 188, 187, 192, 195, 203, 193, 193, 194,
    197, 197, 201, 208, 215, 206, 199, 206, 213, 203, 203, 203, 196, 201, 198,
    194, 201, 202, 203, 198, 201, 206, 215, 208, 204, 200, 209, 217, 207, 216,
    224, 224, 216, 216, 213, 219, 219, 214, 207, 199, 200, 197, 204, 207, 207,
    213, 222, 225, 225, 233, 241, 242, 243, 250, 248, 253, 245, 249, 246, 254,
    260, 266, 261, 260, 267, 267, 260, 260, 257, 253, 258, 260, 253, 261, 262,
    269, 276, 279, 272, 276, 267, 272, 264, 270, 271, 280, 274, 276, 281, 284,
    287, 284, 289, 287, 288, 282, 284, 290, 281, 290, 280, 272, 262, 255, 260,
    269, 278, 269, 274, 273, 263, 266, 262, 256, 264, 254, 254, 250, 248, 257,
    262, 256, 258, 260, 257, 267, 275, 279, 269, 272, 264, 269, 259, 251, 253,
    245, 250, 247, 257, 250, 248, 238, 242, 251, 257, 260, 257, 254, 245, 237,
    231, 238, 234, 234, 233, 228, 235, 237, 237, 236, 238, 245, 243, 253, 260,
    270, 261, 255, 262, 269, 279, 277, 277, 272, 263, 264, 266, 264, 269, 272,
    275, 280, 277, 271, 280, 286, 295, 288, 285, 294, 285, 294, 288, 296, 287,
    294, 295, 291, 287, 285, 288, 286, 290, 299, 306, 299, 300, 297, 296, 288,
    288, 292, 286, 293, 299, 293, 296, 293, 286, 281, 281, 287, 292, 301, 296,
    298, 301, 303, 301, 291, 290, 300, 300, 308, 304, 297, 290, 280, 279, 277,
    277, 268, 277, 281, 291, 293, 290, 284, 288, 293, 301, 305, 313, 316, 307,
    299, 294, 289, 290, 284, 277, 282, 284, 293, 286, 281, 283, 280, 273, 276,
    277, 274, 272, 272, 276, 281, 286, 279, 270, 264, 254, 261, 263, 266, 269,
    271, 264, 259, 252, 251, 245, 253, 254, 251, 245, 243, 252, 262, 255, 257,
    264, 259, 266, 273, 277, 274, 276, 270, 273, 279, 284, 276, 277, 270, 275,
    278, 287, 284, 276, 282, 284, 283, 291, 290, 293, 302, 310, 301, 299, 298,
    296, 295, 291, 290, 291, 293, 299, 301, 295, 294, 301, 295, 302, 311, 318,
    311, 310, 301, 307, 311, 303, 309, 310, 315, 325, 326, 326, 331, 334, 329,
    331, 324, 331, 325, 318, 316, 311, 309, 318, 314, 317, 310, 319, 328, 318,
    326, 326, 335, 326, 325, 324, 328, 325, 321, 326, 322, 327, 333, 338, 339,
    333, 324, 321, 313, 317, 317, 318, 319, 327, 323, 324, 323, 332, 333, 339,
    339, 330, 335, 345, 335, 332, 337, 327, 334, 340, 340, 335, 333, 325, 333,
    333, 335, 329, 325, 321, 323, 317, 321, 321, 313, 316, 325, 316, 321, 316,
    326, 330, 333, 333, 330, 339, 348, 357, 352, 354, 346, 347, 342, 333, 338,
    342, 343, 335, 343, 343, 352, 357, 364, 361, 368, 374, 366, 364, 367, 373,
    368, 361, 357, 367, 373, 368, 358, 358, 361, 362, 356, 349, 359, 363, 353,
    348, 346, 344, 342, 333, 337, 332, 327, 319, 323, 328, 337, 338, 333, 332,
    330, 336, 336, 326, 324, 330, 324, 320, 322, 319, 328, 327, 320, 319, 323,
    323, 323, 319, 319, 328, 328, 322, 329, 334, 341, 348, 341, 339, 335, 337,
    345, 352, 357, 357, 347, 351, 353, 359, 369, 379, 382, 382, 374, 377, 367,
    360, 370, 374, 373, 381, 381, 371, 368, 376, 373, 380, 387, 385, 387, 391,
    384, 388, 384, 383, 375, 366, 357, 361, 369, 369, 372, 366, 369, 378, 372,
    362, 370, 369, 367, 367, 367, 360, 359, 366, 360, 356, 349, 359, 354, 362,
    362, 369, 374, 376, 377, 375, 372, 373, 380, 381, 384, 392, 392, 392, 382,
    381, 380, 371, 365, 369, 374, 374, 371, 367, 361, 361, 353, 351, 358, 363,
    370, 367, 360, 358, 360, 370, 365, 365, 362, 352, 348, 356, 361, 364, 356,
    356, 359, 359, 357, 363, 368, 376, 380, 378, 384, 379, 369, 373, 382, 378,
    383, 385, 380, 376, 382, 376, 382, 378, 384, 393, 397, 395, 394, 386, 387,
    393, 392, 394, 392, 385, 376, 369, 362, 356, 364, 363, 367, 363, 358, 355,
    357, 351, 342, 342, 340, 337, 346, 344, 338, 341, 342, 336, 332, 338, 333,
    335, 333, 338, 329, 321, 314, 316, 312, 310, 306, 303, 304, 304, 302, 299,
    292, 299, 300, 301, 291, 288, 295, 294, 303, 295, 300, 292, 295, 296, 294,
    292, 295, 286, 281, 275, 279, 271, 271, 265, 260, 262, 258, 266, 265, 270,
    263, 270, 270, 266, 266, 256, 250, 247, 255, 263, 256, 257, 266, 270, 266,
    258, 262, 269, 277, 279, 288, 288, 280, 270, 269, 259, 254, 253, 252, 248,
    249, 259, 259, 251, 241, 243, 238, 243, 250, 240, 247, 254, 260, 265, 260,
    251, 260, 269, 266, 262, 264, 272, 269, 278, 273, 264, 255, 258, 261, 261,
    251, 259, 260, 257, 254, 264, 270, 269, 272, 268, 273, 273, 266, 261, 252,
    249, 249, 244, 244, 236, 238, 248, 248, 257, 249, 255, 254, 248, 251, 261,
    258, 268, 258, 261, 261, 254, 255, 253, 261, 269, 276, 278, 276, 272, 269,
    260, 265, 269, 279, 273, 274, 264, 268, 275, 276, 284, 290, 291, 294, 291,
    297, 302, 297, 296, 299, 295, 291, 299, 298, 300, 293, 296, 299, 308, 305,
    304, 294, 293, 288, 279, 275, 280, 277, 269, 273, 277, 284, 290, 292, 292,
    285, 290, 298, 294, 294, 296, 295, 293, 295, 301, 310, 305, 298, 304, 301,
    306, 315, 323, 329, 336, 331, 336, 340, 345, 340, 340, 349, 349, 358, 359,
    349, 340, 338, 338, 332, 335, 338, 341, 338, 333, 335, 334, 329, 335, 332,
    340, 334, 329, 323, 321, 321, 324, 332, 333, 335, 342, 344, 350, 346, 345,
    349, 358, 349, 351, 361, 366, 366, 360, 365, 362, 353, 343, 336, 327, 325,
    320, 327, 321, 311, 307, 305, 296, 295, 291, 299, 307, 298, 289, 298, 300,
    292, 301, 303, 306, 315, 315, 318, 319, 311, 304, 299, 292, 286, 288, 285,
    281, 289, 293, 298, 296, 292, 302, 310, 315, 317, 315, 308, 316, 319, 317,
    307, 316, 321, 328, 329, 324, 320, 316, 324, 334, 335, 337, 347, 338, 329,
    337, 330, 329, 339, 331, 338, 343, 339, 343, 334, 327, 328, 323, 317, 325,
    323, 323, 327, 319, 328, 323, 327, 329, 324, 318, 325, 319, 318, 316, 310,
    313, 309, 314, 318, 322, 332, 338, 330, 334, 335, 330, 332, 326, 334, 331,
    329, 330, 325, 332, 322, 323, 318, 320, 323, 324, 323, 322, 332, 332, 324,
    322, 320, 328, 335, 339, 338, 330, 337, 329, 334, 343, 342, 340, 349, 342,
    350, 359, 361, 371, 378, 376, 386, 396, 394, 387, 390, 383, 386, 380, 380,
    387, 387, 385, 392, 388, 379, 370, 374, 365, 356, 349, 356, 359, 353, 355,
    363, 366, 364, 362, 369, 377, 385, 381, 388, 381, 379, 383, 388, 397, 398,
    402, 404, 413, 423, 416, 423, 431, 426, 432, 433, 441, 434, 439, 446, 443,
    435, 443, 444, 449, 447, 447, 444, 439, 444, 447, 439, 440, 436, 443, 450,
    442, 445, 451, 444, 454, 452, 443, 450, 451, 451, 453, 458, 460, 461, 459,
    466, 462, 471, 480, 472, 464, 472, 481, 479, 475, 480, 487, 494, 487, 478,
    483, 479, 479, 470, 466, 460, 464, 474, 483, 481, 489, 493, 498, 499, 506,
    498, 507, 515, 506, 498, 501, 507, 516, 515, 517, 518, 525, 522, 521, 516,
    513, 518, 514, 510, 506, 507, 505, 505, 500, 510, 515, 517, 513, 512, 502,
    496, 488, 493, 494, 498, 502, 499, 499, 495, 497, 489, 481, 478, 477, 467,
    473, 475, 468, 461, 458, 451, 446, 440, 431, 432, 439, 432, 432, 440, 444,
    435, 426, 434, 435, 426, 426, 429, 439, 445, 455, 449, 445, 443, 439, 440,
    445, 449, 455, 446, 447, 446, 448, 449, 447, 454, 455, 457, 463, 472, 473,
    472, 471, 474, 473, 480, 470, 461, 462, 464, 456, 450, 455, 449, 453, 445,
    451, 442, 443, 445, 446, 449, 445, 441, 440, 445, 445, 444, 451, 461, 464,
    467, 463, 466, 467, 459, 468, 462, 468, 474, 478, 472, 472, 464, 465, 462,
    466, 472, 477, 469, 465, 464, 461, 470, 478, 477, 482, 473, 464, 468, 476,
    479, 484, 488, 488, 492, 492, 500, 495, 503, 503, 496, 502, 510, 509, 507,
    498, 502, 498, 508, 515, 519, 525, 523, 524, 523, 519, 529, 527, 534, 528,
    524, 525, 521, 511, 510, 515, 523, 515, 508, 506, 507, 503, 504, 494, 490,
    491, 493, 492, 493, 501, 496, 499, 493, 484, 493, 484, 475, 474, 480, 472,
    463, 470, 470, 474, 466, 466, 465, 470, 476, 468, 461, 463, 468, 460, 453,
    456, 459, 456, 446, 437, 445, 449, 442, 448, 452, 456, 458, 463, 467, 461,
    457, 450, 445, 451, 451, 459, 454, 460, 460, 470, 465, 458, 450, 453, 444,
    454, 456, 458, 453, 445, 455, 457, 461, 464, 471, 471, 463, 464, 469, 461,
    465, 467, 469, 467, 472, 464, 468, 477, 472, 470, 465, 463, 455, 446, 443,
    436, 438, 442, 447, 445, 448, 440, 445, 446, 455, 454, 454, 445, 446, 440,
    432, 433, 442, 435, 428, 431, 424, 416, 410, 415, 417, 425, 415, 421, 418,
    427, 425, 424, 416, 415, 414, 414, 420, 417, 425, 428, 420, 413, 408, 410,
    408, 400, 400, 392, 393, 393, 402, 408, 400, 400, 404, 410, 415, 422, 416,
    423, 415, 424, 417, 420, 421, 427, 430, 439, 442, 440, 439, 442, 434, 432,
    442, 435, 433, 426, 426, 420, 414, 411, 419, 419, 426, 424, 427, 429, 426,
    425, 418, 411, 417, 426, 436, 437, 435, 431, 431, 436, 432, 431, 425, 423,
    426, 427, 418, 413, 410, 409, 402, 411, 406, 415, 417, 407, 399, 393, 386,
    386, 384, 376, 380, 384, 387, 390, 390, 396, 393, 394, 391, 399, 398, 402,
    403, 412, 421, 417, 421, 424, 427, 432, 428, 427, 431, 427, 420, 415, 409,
    410, 415, 406, 413, 419, 426, 422, 428, 429, 421, 413, 415, 412, 413, 412,
    415, 422, 424, 415, 417, 414, 414, 412, 419, 412, 421, 424, 418, 415, 411,
    412, 412, 404, 402, 407, 416, 423, 417, 426, 431, 440, 444, 441, 446, 453,
    452, 457, 461, 457, 462, 461, 469, 465, 470, 473, 480, 484, 481, 486, 484,
    490, 488, 479, 472, 464, 467, 470, 471, 469, 461, 466, 468, 471, 479, 485,
    477, 469, 463, 472, 476, 472, 480, 475, 475, 469, 478, 475, 469, 476, 480,
    473, 466, 470, 470, 477, 475, 485, 490, 489, 489, 487, 494, 503, 507, 511,
    506, 516, 511, 511, 510, 520, 530, 528, 536, 535, 543, 537, 539, 534, 529,
    524, 531, 527, 536, 542, 540, 545, 541, 541, 536, 539, 535, 536, 542, 537,
    529, 526, 529, 521, 527, 532, 527, 521, 526, 522, 521, 527, 518, 509, 507,
    505, 513, 512, 517, 511, 512, 514, 508, 509, 499, 498, 491, 483, 480, 482,
    475, 483, 478, 474, 472, 464, 457, 455, 457, 451, 461, 456, 451, 450, 450,
    458, 461, 453, 447, 448, 442, 436, 427, 420, 416, 424, 415, 417, 414, 415,
    411, 418, 410, 403, 394, 395, 387, 386, 390, 386, 388, 383, 373, 369, 374,
    373, 368, 376, 369, 363, 363, 362, 363, 366, 356, 365, 374, 368, 366, 373,
    366, 365, 371, 366, 369, 369, 371, 379, 379, 371, 376, 383, 378, 381, 380,
    384, 374, 378, 368, 369, 367, 367, 359, 359, 369, 374, 375, 373, 368, 370,
    378, 376, 374, 365, 361, 353, 350, 341, 337, 339, 332, 327, 320, 325, 335,
    343, 350, 350, 340, 335, 326, 333, 325, 322, 327, 319, 328, 329, 334, 330,
    329, 332, 325, 331, 331, 330, 323, 319, 325, 331, 326, 324, 329, 327, 332,
    330, 320, 315, 325, 330, 336, 346, 344, 350, 342, 350, 349, 351, 347, 349,
    345, 336, 344, 353, 359, 365, 365, 356, 347, 346, 356, 363, 355, 361, 354,
    356, 355, 359, 362, 367, 364, 365, 367, 374, 377, 375, 384, 375, 385, 393,
    402, 398, 394, 384, 374, 380, 382, 386, 377, 380, 382, 383, 387, 393, 392,
    390, 386, 377, 369, 363, 373, 379, 371, 375, 366, 357, 354, 356, 348, 356,
    360, 357, 347, 357, 352, 359, 365, 356, 355, 364, 364, 371, 366, 375, 375,
    381, 389, 383, 386, 378, 383, 388, 395, 400, 391, 389, 392, 398, 392, 391,
    384, 380, 383, 387, 393, 399, 398, 391, 384, 391, 386, 388, 397, 406, 410,
    415, 422, 426, 416, 412, 417, 423,
  ];
  const getOption = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: date,
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "100%"],
      max: "300",
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 10,
      },
    ],
    series: [
      {
        name: "Mentions",
        type: "line",
        symbol: "none",
        sampling: "lttb",
        itemStyle: {
          color: "#468C9D",
        },
        areaStyle: {
          color: "#9DBFC4",
        },
        data: data1,
      },
    ],
  };

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default StaticDialyMentionsChart;
