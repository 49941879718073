import { BaseModel } from "sjs-base-model";

// Delete model
export default class CampaignMappingDeleteModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point
  // message = "";
  result = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
