import BaseReducer from "../../../utilities/BaseReducer";
import PinterestFeedsAction from "./PinterestFeedsAction";

export default class PinterestFeedsFilterReduser extends BaseReducer {
  initialState = {
    pinterestFilter: [],
  };

  [PinterestFeedsAction.REQUEST_PINTEREST_FILTER_FINISHED](state, action) {
    return {
      ...state,
      pinterestFilter: action.payload,
    };
  }
}
