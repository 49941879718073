import environment from "environment";
import YoutubeVideosWebhookPostModel from "./models/YoutubeVideosWebhookPostModel";
import YoutubeVideosWebhookModel from "./models/YoutubeVideosWebhookModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import HttpErrorResponseModel from "../../../../models/HttpErrorResponseModel";
import YoutubeChannelMonitorPostModel from "./models/YoutubeChannelMonitorPostModel";
import YoutubeChannelSubscribeModel from "./models/YoutubeChannelSubscribeModel";
import YoutubeChannelMonitorDeleteModel from "./models/YoutubeChannelMonitorDeleteModel";
import YoutubeDataPostModel from "./models/YoutubePostdataModel";

export default class YoutubeVideosWebhookEffect {
  static async requestYoutubeVideosWebhook(params, callBackFunction) {
    const endpoint = environment.api.youtubeVideosWebhook;

    let response = EffectUtility.getToModel(
      YoutubeVideosWebhookModel,
      endpoint,
      params
    );
    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestYoutubeVideosWebhookFilter(params, callBackFunction) {
    const endpoint = environment.api.youtubeVideosWebhook;

    let response = EffectUtility.getToModel(
      YoutubeVideosWebhookModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestPostYoutubeVideosWebhook(data) {
    const endpoint = environment.api.youtubeVideosWebhook + "/add";

    return EffectUtility.postToModel(
      YoutubeVideosWebhookPostModel,
      endpoint,
      data
    );
  }

  static async requestPostYoutubeChannelsWebhook(data) {
    const endpoint = environment.api.channelMonitoringVideoListWebhook + "/add";

    return EffectUtility.postToModel(
      YoutubeChannelMonitorPostModel,
      endpoint,
      data
    );
  }

  static async requestPostSubscribeChannels(data) {
    const endpoint = environment.api.subscribeChannel;

    return EffectUtility.postToModel(
      YoutubeChannelSubscribeModel,
      endpoint,
      data
    );
  }

  static async requestChannelMonitoringYoutubeVideosWebhook(
    params,
    callBackFunction
  ) {
    const endpoint = environment.api.channelMonitoringVideoListWebhook;

    let response = EffectUtility.getToModel(
      YoutubeVideosWebhookModel,
      endpoint,
      params
    );
    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestDeleteYoutubeChannelWebhook(id) {
    const endpoint =
      environment.api.channelMonitoringVideoListWebhook + "/" + id;

    return EffectUtility.deleteToModel(
      YoutubeChannelMonitorDeleteModel,
      endpoint
    );
  }

  static async requestPostYoutubeData(data, callback) {
    const endpoint = environment.api.youtubePostData;

    let response = EffectUtility.postToModel(
      YoutubeDataPostModel,
      endpoint,
      data
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
