import environment from "environment";
import LinkCheckingModel from "./models/LinkCheckingGetModel";
import LinkCheckingPutModel from "./models/LinkCheckingPutModel";
import LinkCheckingPostModel from "./models/LinkCheckingPostModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import LinkValidatorGetModel from "./models/LinkValidatorGetModel";
import LinkCheckPlatformsGetModel from "./models/LinkCheckPlatformsGEtModel";

//LinkCheckingEffect
export default class LinkCheckingEffect {
  // calling an API according to theLinkChecking model
  static async requestLinkChecking(params) {
    const endpoint = environment.api.deListedLink;
    return EffectUtility.getToModel(LinkCheckingModel, endpoint, params);
  }

  static async requestLinkCheckingClear() {
    return {};
  }

  static async requestLinkCheckingFilterClear() {
    return {};
  }

  // Get method for theLinkChecking filter
  static async requestLinkCheckingFilter(params, callBack) {
    const endpoint = environment.api.export_links;
    let response = EffectUtility.getToModel(
      LinkCheckingModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // put method for theLinkChecking
  static async requestPutLinkChecking(data, id) {
    const endpoint = environment.api.deListedLink + "/" + id;
    return EffectUtility.putToModel(LinkCheckingPutModel, endpoint, data);
  }

  static async requestPutLinkCheckingPlatforms(data, id) {
    const endpoint = environment.api.linkCheckPlatforms + "/" + id;
    return EffectUtility.putToModel(LinkCheckingPutModel, endpoint, data);
  }

  // post method for theLinkChecking
  static async requestPostLinkChecking(data, params, callBack) {
    const endpoint = environment.api.deListedLink + "/add";
    let response = EffectUtility.postToModel(
      LinkCheckingPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestPostLinkCheckingPlatfrom(data, params, callBack) {
    const endpoint = environment.api.linkCheckPlatforms;
    let response = EffectUtility.postToModel(
      LinkCheckingPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestDeleteLinkChecking(id) {
    const endpoint = environment.api.linkCheking + "/" + id;
    return EffectUtility.deleteToModel(LinkCheckingPostModel, endpoint);
  }

  static async requestPostUploadLinks(data, params, callBack) {
    const endpoint = environment.api.uploadLinks;
    let response = EffectUtility.postToModel(
      LinkCheckingPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestValidateLinks(params) {
    const endpoint = environment.api.linkValidatorAll;
    return EffectUtility.getToModel(LinkValidatorGetModel, endpoint, params);
  }

  static async requestValidateLinksWithFilters(params, callBack) {
    const endpoint = environment.api.linkValidatorFilter;
    let response = EffectUtility.getToModel(
      LinkValidatorGetModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestArchivedLinks(params) {
    const endpoint = environment.api.deListedLink;
    return EffectUtility.getToModel(LinkCheckingModel, endpoint, params);
  }

  static async requestArchivedLinksFilter(params, callback) {
    const endpoint = environment.api.deListedLink;
    let response = EffectUtility.getToModel(
      LinkCheckingModel,
      endpoint,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestLinkCheckingPlatforms(params) {
    const endpoint = environment.api.linkCheckPlatforms;
    return EffectUtility.getToModel(
      LinkCheckPlatformsGetModel,
      endpoint,
      params
    );
  }
  static async requestLinkCheckingPlatformsFilter(params) {
    const endpoint = environment.api.linkCheckPlatforms;
    return EffectUtility.getToModel(
      LinkCheckPlatformsGetModel,
      endpoint,
      params
    );
  }
}
