import ACWhitelistReducer from "./assetCollection/ACWhitelistReducer";
import AssetCollectionReducer from "./assetCollection/AssetCollectionReducer";
import FingerPrintAssetReducer from "./assets/FinegrPrintAssetReducer";
import CopiesReducer from "./assetCollection/CopiesReducer";
import FingerPrintAssetByIdReducer from "./assets/fingerPrintAssetByIdReducer";
import FinegrPrintAssetFilterReducer from "./assets/FinegrPrintAssetFilterReducer";
import AssetCollectionFilterReducer from "./assetCollection/AssetCollectionFilterReducer";
import {
  FingerPrintActiveAssetReducer,
  FingerPrintAssetwiseCopiesReducer,
  FingerPrintTotalAssetReducer,
  FingerPrintTotalCollectionReducer,
  FingerPrintTotalCopiesReducer,
  FingerPrintViewsPerAssetReducer,
} from "./reports/FingerPrintReportsReducer";
import WhiteListReducer from "./whiteLists/WhiteListReducer";
import FingerPrintAcrSearchReducer from "./search/FingerPrintAcrSearchReducer";
import FingerPrintSearchReducer from "./search/fingerPrintSearchReducer";
import FPplatformReducer from "./fingerPrintPlatforms/FPplatformReducer";

export const fingerPrintingRootReducer = {
  collection: new AssetCollectionReducer().reducer,
  fingerPrintAsset: new FingerPrintAssetReducer().reducer,
  fingerPrintAssetFilter: new FinegrPrintAssetFilterReducer().reducer,
  whitelist: new ACWhitelistReducer().reducer,
  copies: new CopiesReducer().reducer,
  fingerPrintAssetById: new FingerPrintAssetByIdReducer().reducer,
  collectionFilter: new AssetCollectionFilterReducer().reducer,
  whitelist: new WhiteListReducer().reducer,
  FPplatforms: new FPplatformReducer().reducer,

  // reports reducers
  FPtotalAssetCount: new FingerPrintTotalAssetReducer().reducer,
  FPtotalCollectionsCount: new FingerPrintTotalCollectionReducer().reducer,
  FPtotalCopiesCount: new FingerPrintTotalCopiesReducer().reducer,
  FPassetWiseCopies: new FingerPrintAssetwiseCopiesReducer().reducer,
  FPviewsPerAsset: new FingerPrintViewsPerAssetReducer().reducer,
  FPactiveAssets: new FingerPrintActiveAssetReducer().reducer,
  FPsearch: new FingerPrintSearchReducer().reducer,
  FPacrSearch: new FingerPrintAcrSearchReducer().reducer,
};
