import ActionUtility from "../../../../utilities/ActionUtility";
import BlogsEffect from "./BlogsEffect";

export default class BlogsAction {
  // BlogsAction action with an function declaration
  static REQUEST_BLOGS = "BlogsAction.REQUEST_BLOGS";
  static REQUEST_BLOGS_FINISHED = "BlogsAction.REQUEST_BLOGS_FINISHED";

  static REQUEST_BLOGS_FILTER = "BlogsAction.REQUEST_BLOGS_FILTER";
  static REQUEST_BLOGS_FILTER_FINISHED =
    "BlogsAction.REQUEST_BLOGS_FILTER_FINISHED";

  static REQUEST_PUT_BLOGS = "BlogsAction.REQUEST_PUT_CLIENT";
  static REQUEST_PUT_BLOGS_FINSI = "BlogsAction.REQUEST_PUT_CLIENT_FINISHED";

  static REQUEST_POST_BLOGS = "BlogsAction.REQUEST_POST_BLOGS";
  static REQUEST_POST_BLOGS_FINISHED =
    "BlogsAction.REQUEST_POST_BLOGS_FINISHED";

  static REQUEST_BLOGS_CLEAR = "BlogsAction.REQUEST_BLOGS_CLEAR";
  static REQUEST_BLOGS_CLEAR_FINISHED =
    "BlogsAction.REQUEST_BLOGS_CLEAR_FINISHED";

  static REQUEST_CLIENT_EMAIL_ADDRESS =
    "BlogsAction.REQUEST_CLIENT_EMAIL_ADDRESS";
  static REQUEST_CLIENT_EMAIL_ADDRESS_FINISHED =
    "BlogsAction.REQUEST_CLIENT_EMAIL_ADDRESS_FINISHED";

  static REQUEST_CONFIGURED_EMAIL_ADDRESS =
    "BlogsAction.REQUEST_CONFIGURED_EMAIL_ADDRESS";
  static REQUEST_CONFIGURED_EMAIL_ADDRESS_FINISHED =
    "BlogsAction.REQUEST_CONFIGURED_EMAIL_ADDRESS_FINISHED";

  static REQUEST_POST_MUSICLABELS_BLOGS_MAIL =
    "BlogsAction.REQUEST_POST_MUSICLABELS_BLOGS_MAIL";
  static REQUEST_POST_MUSICLABELS_BLOGS_MAIL_FINISHED =
    "BlogsAction.REQUEST_POST_MUSICLABELS_BLOGS_MAIL_FINISHED";

  // METHODS
  // blog GET function
  static requestBlogs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_BLOGS,
        BlogsEffect.requestBlogs,
        params
      );
    };
  }

  static requestClientEmailAddress(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_CLIENT_EMAIL_ADDRESS,
        BlogsEffect.requestClientEmailAddress,
        params
      );
    };
  }

  static requestConfiguredEmailAddress(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_CONFIGURED_EMAIL_ADDRESS,
        BlogsEffect.requestConfiguredEmailAddress,
        params
      );
    };
  }

  static requestBlogsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_BLOGS_CLEAR,
        BlogsEffect.requestBlogsClear
      );
    };
  }

  static requestBlogsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_BLOGS_FILTER,
        BlogsEffect.requestBlogsFilter,
        params
      );
    };
  }

  static requestPostBlogs(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_POST_BLOGS,
        BlogsEffect.requestPostBlogs,
        data
      );
    };
  }

  static requestPostMusicLabelsBlogsMail(data, params, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_POST_MUSICLABELS_BLOGS_MAIL,
        BlogsEffect.requestPostMusicLabelsBlogsMail,
        data,
        params,
        id
      );
    };
  }

  static requestPutBlogs(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_PUT_BLOGS,
        BlogsEffect.requestPutBlogs,
        data,
        id
      );
    };
  }
}
