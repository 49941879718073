import BaseReducer from "../../../utilities/BaseReducer";
import IndeedAccountAction from "./IndeedAccountAction";

export default class IndeedAccountReducer extends BaseReducer {
  initialState = {
    indeedAccount: [],
  };
  [IndeedAccountAction.REQUEST_INDEED_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      indeedAccount: action.payload,
    };
  }
}
