import BaseReducer from "../../../../utilities/BaseReducer";
import TopLanguagesAction from "./TopLanguagesAction";

export default class TopLanguagesFilterReduser extends BaseReducer {
  //initial state of TopLanguagesFilterReduser
  initialState = {
    top_territories_report_filter: [],
  };
  //TopLanguagesFilterReduser request action finish
  [TopLanguagesAction.REQUEST_TOP_LANGUAGES_FILTER_FINISHED](state, action) {
    return {
      ...state,
      top_territories_report_filter: action.payload,
    };
  }
}
