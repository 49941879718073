import ActionUtility from "../../utilities/ActionUtility";
import ReportTemplateEffect from "./ReportTemplateEffect";

export default class ReportTemplateAction {
  //REPORT_TEMPLATEs requests
  static REQUEST_REPORT_TEMPLATE =
    "ReportTemplateAction.REQUEST_REPORT_TEMPLATE";
  static REQUEST_REPORT_TEMPLATE_FINISHED =
    "ReportTemplateAction.REQUEST_REPORT_TEMPLATE_FINISHED";

  //REPORT_TEMPLATEs post
  static REQUEST_POST_REPORT_TEMPLATE =
    "ReportTemplateAction.REQUEST_POST_REPORT_TEMPLATE";
  static REQUEST_POST_REPORT_TEMPLATE_FINISHED =
    "ReportTemplateAction.REQUEST_POST_REPORT_TEMPLATE_FINISHED";

  //REPORT_TEMPLATEs put
  static REQUEST_PUT_REPORT_TEMPLATE =
    "ReportTemplateAction.REQUEST_PUT_REPORT_TEMPLATE";
  static REQUEST_PUT_REPORT_TEMPLATE_FINISHED =
    "ReportTemplateAction.REQUEST_PUT_REPORT_TEMPLATE_FINISHED";

  static REQUEST_UPDATE_ORG_REPORT_TEMPLATE =
    "ReportTemplateAction.REQUEST_UPDATE_ORG_REPORT_TEMPLATE";
  static REQUEST_UPDATE_ORG_REPORT_TEMPLATE_FINISHED =
    "ReportTemplateAction.REQUEST_UPDATE_ORG_REPORT_TEMPLATE_FINISHED";

  //REPORT_TEMPLATEs filter
  static REQUEST_REPORT_TEMPLATE_FILTER =
    "ReportTemplateAction.REQUEST_REPORT_TEMPLATE_FILTER";
  static REQUEST_REPORT_TEMPLATE_FILTER_FINSIHED =
    "ReportTemplateAction.REQUEST_REPORT_TEMPLATE_FILTER_FINSIHED";

  //Reports requests
  static REQUEST_REPORTS_BY_ID = "ReportTemplateAction.REQUEST_REPORTS_BY_ID";
  static REQUEST_REPORTS_BY_ID_FINISHED =
    "ReportTemplateAction.REQUEST_REPORTS_BY_ID_FINISHED";

  //METHODS

  //REPORT_TEMPLATE list get method
  static requestReportTemplate(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportTemplateAction.REQUEST_REPORT_TEMPLATE,
        ReportTemplateEffect.requestReportTemplate,
        params,
        callBack
      );
    };
  }

  //REPORT_TEMPLATE list get method
  static requestReportTemplateFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportTemplateAction.REQUEST_REPORT_TEMPLATE_FILTER,
        ReportTemplateEffect.requestReportTemplateFilter,
        params
      );
    };
  }

  //REPORT_TEMPLATE list post method
  static requestPostReportTemplate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportTemplateAction.REQUEST_POST_REPORT_TEMPLATE,
        ReportTemplateEffect.requestPostReportTemplate,
        data
      );
    };
  }

  //REPORT_TEMPLATE list put method
  static requestPutReportTemplate(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportTemplateAction.REQUEST_PUT_REPORT_TEMPLATE,
        ReportTemplateEffect.requestPutReportTemplate,
        data,
        id
      );
    };
  }

  static requestUpdateOrgReportTemplate(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportTemplateAction.REQUEST_UPDATE_ORG_REPORT_TEMPLATE,
        ReportTemplateEffect.requestUpdateOrgReportTemplate,
        data,
        id,
        params
      );
    };
  }

  //Report list get method by id
  static requestReportsById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportTemplateAction.REQUEST_REPORTS_BY_ID,
        ReportTemplateEffect.requestReportsById,
        id
      );
    };
  }
}
