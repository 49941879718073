import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import UserPermissionDeleteModel from "./model/UserPermissionDeleteModel";
import UserPermissionModel from "./model/UserPermissionModel";
import UserPermissionPostModel from "./model/UserPermissionPostModel";

//Category effect
export default class UserPermissionEffect {
  // calling an API according to the ProjectModel
  static async requestUserPermission(params) {
    const endpoint = environment.api.userPermission;
    return EffectUtility.getToModel(UserPermissionModel, endpoint, params);
  }

  // Post API
  static async requestPostUserPermission(data) {
    const endpoint = environment.api.userPermission;

    return EffectUtility.postToModel(UserPermissionPostModel, endpoint, data);
  }

  static async requestDeleteUserPermission(id) {
    const endpoint = environment.api.userPermission + "/" + id;

    return EffectUtility.deleteToModel(UserPermissionDeleteModel, endpoint);
  }

  // Put API
  //   static async requestPutCategory(data, id) {
  //     const endpoint = environment.api.category + "/" + id;
  //     return EffectUtility.putToModel(CategoryPutModel, endpoint, data);
  //   }
}
