import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cronstrue from "cronstrue";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ProjectAction from "../../stores/project/projectAction";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import PlatformAction from "../../stores/platform/PlatformAction";
import PlatformSchedulerAction from "../../stores/platformScheduler/PlatformSchedulerAction";
import SchedulerPostForm from "../../components/schedulerPostForm/SchedulerPostForm";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../views/App";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import PaginationUI from "../../../components/pagination/Pagination";

let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ProjectAction.REQUEST_PROJECT_BY_ID,
    // SubSourceAction.REQUEST_SUBSOURCE,
    PlatformAccountsAction.REQUEST_PLATFORM_ACCOUNT_FILTER,
    PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULING,
    PlatformAction.REQUEST_PLATFORM,
  ]),
  // subSource: state.subSource.subSource || [],
  platformAccountFilter:
    state.platformAccountFilter.platformAccountFilter || [],
  projectById: state.projectById.projectById || [],
  pagination: state.platform_scheduling.platform_scheduling || [],
  platform_scheduling: state.platform_scheduling.platform_scheduling || [],
  platform: state.platform.platform.hits || {},
});

function TaskScheduler(props) {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const { isRequesting } = props;
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchProject(id) {
    props.dispatch(ProjectAction.requestProjectById(id));
  }

  function fetchPlatformAccounts(params) {
    props.dispatch(PlatformAccountsAction.requestPlatformAccountFilter(params));
  }

  function fetchPlatformScheduler() {
    let params = {
      page_limit: `${page_limit}`,
      order_by: '[["created_on", "desc"]]',
    };
    props.dispatch(PlatformSchedulerAction.requestPlatformScheduling(params));
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const all_accounts = [];

  if (props.platformAccountFilter.hits) {
    props.platformAccountFilter.hits.hits &&
      props.platformAccountFilter.hits.hits.map((item) => {
        all_accounts.push({
          account_id: item._id,
          account_name: item._source.account_name,
          platform: item._source.platform,
        });
      });
  }

  let platforms = [];

  function fetchPlatform() {
    let param = {
      //   level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };

    props.dispatch(PlatformAction.requestPlatform(param));
  }

  if (props.platform.hits) {
    props.platform.hits.map((item) => {
      platforms.push({
        platform_logo: item._source.platform_name,
        platform_name: item._source.platform_name,
        platform_id: item._id,
        platform_alias: item._source.alias,
      });
    });
  }

  platforms.sort((a, b) => a?.platform_alias.localeCompare(b.platform_alias));
  platforms = [...new Set(platforms)];

  let filteredPlatformList = [];

  let job_type_list = [];

  if (localStorage.getItem("activities") != null) {
    let activities = JSON.parse(localStorage.getItem("activities")).hits.hits;
    activities.map((item) => {
      job_type_list.push({
        job_type_id: item._id,
        job_type: item._source.activities,
        platform: item._source.platform.platform_name,
      });
    });
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    let workingProject = JSON.parse(
      localStorage.getItem("user_last_working_project")
    );

    fetchProject(workingProject.project_id);
    fetchPlatformAccounts();
    fetchPlatformScheduler();
    fetchPlatform();
  }, []);

  // pagination
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (platform !== null) {
      filter.push(
        `["job_type.keyword","must","","match","${platform.platform_name}"]`
      );
    }

    if (activities !== null) {
      filter.push(
        `["job_type.keyword","must","","match","${activities.activity_name}"]`
      );
    }

    if (FilterClicked) {
      if (platform !== null || activities !== null) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: '[["created_on", "desc"]]',
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(PlatformSchedulerAction.requestPlatformScheduling(params));
    }
  };

  //39 10 6-7 1 *
  const applyFilter = () => {
    const filter = [];
    if (platform !== null) {
      filter.push(
        `["task","must","nested","",["task_id.keyword","must","","term","${platform.platform_name}"]]`
      );
    }

    if (activities !== null) {
      filter.push(
        `["task","must","nested","",["job_type.keyword","must","","term","${activities.activity_name}"]]`
      );
    }

    if (platform !== null || activities !== null) {
      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      props.dispatch(PlatformSchedulerAction.requestPlatformScheduling(params));
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };
  const ClearState = () => {
    if (platform !== null || activities !== null) {
      setPlatform(null);
      setActivities(null);
      setClearLoader(true);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
    };
    FilterClicked &&
      props.dispatch(PlatformSchedulerAction.requestPlatformScheduling(params));
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded_2, setExpanded_2] = React.useState(false);

  const handleChangeExpand_2 = (panel) => (event, isExpanded) => {
    setExpanded_2(isExpanded ? panel : false);
  };

  const checkboxHandleChange = (event, item, task) => {
    const filter = [];
    if (platform !== null) {
      filter.push(
        `["task","must","nested","",["task_id.keyword","must","","term","${platform.platform_name}"]]`
      );
    }

    if (activities !== null) {
      filter.push(
        `["task","must","nested","",["job_type.keyword","must","","term","${activities.activity_name}"]]`
      );
    }

    let filter_params = {};

    if (platform !== null || activities !== null) {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_limit: `${page_limit}`,
        page_number: `${props.pagination.current_page_no}`,
      };
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_limit: `${page_limit}`,
        page_number: `${props.pagination.current_page_no}`,
      };
    }
    if (event.target.checked === true) {
      let params = {
        project_id: localStorage.getItem("user_last_working_project")
          ? JSON.parse(localStorage.getItem("user_last_working_project"))
              .project_id
          : "",
        task_id: task.task_id,
        orm_platform_scheduling_id: item._id,
        enable: true,
        job_id: task.job_id,
      };
      props
        .dispatch(PlatformSchedulerAction.requestPlatformTaskScheduling(params))
        .then(() => {
          props.dispatch(
            PlatformSchedulerAction.requestPlatformScheduling(filter_params)
          );
        });
    }
    if (event.target.checked === false) {
      let params = {
        project_id: localStorage.getItem("user_last_working_project")
          ? JSON.parse(localStorage.getItem("user_last_working_project"))
              .project_id
          : "",
        task_id: task.task_id,
        orm_platform_scheduling_id: item._id,
        enable: false,
        job_id: task.job_id,
      };
      props
        .dispatch(PlatformSchedulerAction.requestPlatformTaskScheduling(params))
        .then(() => {
          props.dispatch(
            PlatformSchedulerAction.requestPlatformScheduling(filter_params)
          );
        });
    }
  };

  let [item_data, set_item_data] = useState(null);

  const openButtonFunction = () => {
    setOpen(true);
    setEdit(false);
    setEditRowsModel(null);
  };
  const buttonFunction = (item) => {
    setOpen(true);
    setEdit(true);
    setEditRowsModel(item);
  };

  const [platform, setPlatform] = useState(null);
  const [activities, setActivities] = useState(null);

  //platform callback function
  const PlatformHandleChange = (event, platform) => {
    setPlatform(platform);
    setActivities(null);
  };

  //Activities callback function
  const ActivitiesHandleChange = (event, activity) => {
    setActivities(activity);
  };

  //activities list Assigning
  let activitiesList = [];
  const activitiesData = JSON.parse(localStorage.getItem("activities"));
  if (activitiesData !== null) {
    if (activitiesData.hits.hits) {
      activitiesData.hits.hits.map((item) => {
        if (platform !== null) {
          if (item._source.platform.platform_name === platform.platform_name) {
            activitiesList.push({
              activity_id: item._id,
              activity_name: item._source.activities,
            });
          }
        }
      });
    }
  }

  activitiesList.sort((a, b) =>
    a?.activity_name.localeCompare(b.activity_name)
  );
  activitiesList = [...new Set(activitiesList)];

  function formatName(name) {
    const words = name
      ?.split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words?.join(" ") ?? "";
  }

  return (
    <React.Fragment
      position={"relative"}
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   position: "relative",
      // }}
    >
      <Grid
        xs={12}
        // sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {open && (
          <SchedulerPostForm
            open={open}
            onSubmit={() => {}}
            onClose={() => {
              setOpen(false);
            }}
            editData={editRowsModel}
            edit={edit}
            all_accounts={all_accounts}
            platforms={platforms}
            jobTypeList={job_type_list}
            page_limit={page_limit}
          />
        )}
      </Grid>

      {!open && (
        <Box
          height={"100%"}
          // className="bg-warning"
          // ref={SchedulerBoxRef}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            style={{
              height: "10%",
              position: "relative",
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="space-between"
              pr={2}
              my={2}
              // className="bg-warning"
              alignItems={"center"}
            >
              <Grid
                item
                lg={4.5}
                md={7}
                sm={7}
                container
                justifyContent="space-between"
                // className="bg-success"
              >
                <Grid
                  item
                  xl={5.5}
                  lg={5}
                  md={5}
                  sm={5}
                  mt={1}
                  alignItems="center"
                  justifyContent="space-between"
                  // className="bg-info"
                >
                  <AutoCompleteComponent
                    textFieldProps={{
                      placeholder: "Platform",
                    }}
                    getOptionLabel={(option) =>
                      option.platform_alias ? option.platform_alias : ""
                    }
                    list={platforms}
                    value={platform}
                    size="small"
                    width="100%"
                    handleChange={PlatformHandleChange}
                  />
                </Grid>

                <Grid
                  item
                  xl={5.5}
                  lg={5}
                  md={5}
                  sm={5}
                  mt={1}
                  // className="bg-warning"
                >
                  <AutoCompleteComponent
                    textFieldProps={{
                      placeholder: "Job Type",
                    }}
                    getOptionLabel={(option) =>
                      option.activity_name ? option.activity_name : ""
                    }
                    value={activities}
                    disabled={!platform}
                    list={activitiesList}
                    size="small"
                    width="100%"
                    handleChange={ActivitiesHandleChange}
                  />
                </Grid>
              </Grid>

              <Grid item lg={7.5} md={7} sm={7} container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={1}
                  justifyContent="flex-end"
                  mt={0}
                >
                  <Grid
                    lg={8}
                    sm={8}
                    md={8}
                    display={"flex"}
                    justifyContent={"end"}
                    mt={1}
                    mr={2}
                    sx={{ gap: 2 }}
                  >
                    <Grid lg={3} md={3} sm={3}>
                      <MuiButton
                        name={"Filter"}
                        width="100%"
                        onClick={applyFilter}
                        loading={filterLoader && isRequesting}
                      />
                    </Grid>
                    <Grid lg={3} md={3} sm={3}>
                      <MuiButton
                        name={"Clear"}
                        width="100%"
                        onClick={ClearState}
                        loading={clearLoader && isRequesting}
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                    {/* post button component*/}
                    <PostPlusButton
                      open={open}
                      openCloseModal={openCloseModal}
                      editMode={editMode}
                      onClick={() => {
                        openButtonFunction();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box
            className="scrollable"
            style={{
              height: "80%",
              overflow: "auto",
              position: "relative",
            }}
          >
            {props.platform_scheduling.hits ? (
              <LoadingIndicator isActive={isRequesting}>
                {!isRequesting &&
                props.platform_scheduling.hits.hits.length !== 0 ? (
                  props.platform_scheduling.hits.hits.map(
                    (item, index) => (
                      // !isRequesting && (
                      <Box
                        sx={{
                          padding: 0,
                          margin: "1.2%",
                          marginTop: 0,
                          marginBottom: "0.5%",
                          borderRadius: theme.borderRadius,
                        }}
                        // className="bg-warning"
                      >
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChangeExpand(`panel${index}`)}
                          className="p-0 m-0"
                          style={{
                            backgroundColor: "#fff",
                            height: "auto",
                            padding: 2,
                            borderRadius: theme.borderRadius,
                            border: "solid 0.5px #d3e2e6",
                            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                          }}
                        >
                          <AccordionSummary
                            className="p-0 m-0"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                          >
                            <List
                              sx={{
                                width: "100%",
                              }}
                              className="p-0 m-0"
                            >
                              <Grid
                                container
                                className="p-0 m-0"
                                // className="bg-warning"
                              >
                                <Grid
                                  container
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  // className="bg-warning"
                                >
                                  <Grid item lg={12} md={12} sm={12} container>
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      container
                                      justifyContent={"space-between"}
                                      alignItems="center"
                                    >
                                      <Grid
                                        item
                                        xl={8}
                                        lg={6}
                                        md={12}
                                        sm={12}
                                        // className="bg-warning"
                                      >
                                        <ListItem>
                                          <h6 style={{ marginRight: "20px" }}>
                                            {item._source.group}
                                          </h6>
                                          <p>
                                            {" "}
                                            {(function createdDate() {
                                              let created =
                                                item._source.created_on;
                                              let dateConversion = new Date(
                                                created
                                              );
                                              dateConversion =
                                                dateConversion.toDateString() +
                                                ", " +
                                                dateConversion.toLocaleTimeString(
                                                  "en-US",
                                                  {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                  }
                                                );

                                              return dateConversion;
                                            })()}
                                          </p>
                                        </ListItem>
                                      </Grid>

                                      <Grid
                                        container
                                        item
                                        xl={1.5}
                                        lg={4}
                                        md={4}
                                        sm={4}
                                        // className="bg-info"
                                        justifyContent={"flex-end"}
                                        alignItems="center"
                                        textAlign={"right"}
                                      >
                                        <ListItem className="p-0 m-0">
                                          <h6 style={{ marginRight: "20px" }}>
                                            Add Task
                                          </h6>
                                          <p>
                                            <PostPlusButton
                                              open={open}
                                              openCloseModal={openCloseModal}
                                              editMode={editMode}
                                              onClick={() => {
                                                buttonFunction(item);
                                              }}
                                            />
                                          </p>
                                        </ListItem>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </List>
                          </AccordionSummary>
                          <AccordionDetails className="p-0 m-0">
                            <Typography>
                              <span
                                className="font-weight-bold pl-4"
                                style={{
                                  fontSize: 22,
                                }}
                              >
                                Tasks
                              </span>
                            </Typography>

                            {item._source.task.length >= 0
                              ? item._source.task.map((task, index_2) => (
                                  <Box
                                    sx={{
                                      padding: 1,
                                      margin: "1.2%",
                                      marginTop: 0,
                                      marginBottom: "0.5%",
                                      border: "solid 0.5px #d3e2e6",
                                      borderRadius: theme.borderRadius,
                                      boxShadow:
                                        "0px 1px 1px 0px rgba(0,0,0,0.16)",
                                    }}
                                  >
                                    <Typography>
                                      {/* {(function returnSlicedAnswer() {
                                              return `${
                                                index_2 + 1
                                              }. ${ans.answer.slice(
                                                0,
                                                80
                                              )} ...`;
                                            })()} */}

                                      <Grid
                                        item
                                        container
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        justifyContent="space-between"
                                        alignItems={"center"}
                                      >
                                        <Grid item>
                                          <Checkbox
                                            defaultChecked={
                                              task.enabled === true
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              checkboxHandleChange(
                                                e,
                                                item,
                                                task
                                              );
                                            }}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <ListItem>
                                            <span>Task :</span>
                                            <span>&nbsp; {task.task_id}</span>
                                          </ListItem>
                                        </Grid>

                                        <Grid item>
                                          <ListItem>
                                            <span>Start Time :</span>
                                            <span>
                                              &nbsp;{" "}
                                              {(function createdDate() {
                                                let created = task.start_time;
                                                let dateConversion = new Date(
                                                  created
                                                );
                                                dateConversion =
                                                  dateConversion.toDateString() +
                                                  ", " +
                                                  dateConversion.toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                      hour12: true,
                                                    }
                                                  );

                                                return dateConversion;
                                              })()}
                                            </span>
                                          </ListItem>
                                        </Grid>

                                        <Grid item>
                                          <ListItem>
                                            <span>End Time :</span>
                                            <span>
                                              &nbsp;{" "}
                                              {(function createdDate() {
                                                let created = task.end_time;
                                                let dateConversion = new Date(
                                                  created
                                                );
                                                dateConversion =
                                                  dateConversion.toDateString() +
                                                  ", " +
                                                  dateConversion.toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                      hour12: true,
                                                    }
                                                  );

                                                return dateConversion;
                                              })()}
                                            </span>
                                          </ListItem>
                                        </Grid>

                                        <Grid item>
                                          <ListItem>
                                            <span>Job Type :</span>
                                            <span>
                                              &nbsp; {formatName(task.job_type)}
                                            </span>
                                          </ListItem>
                                        </Grid>

                                        <Grid item>
                                          <ListItem>
                                            <span>Account :</span>
                                            <span>
                                              &nbsp; {task.account_id}
                                            </span>
                                          </ListItem>
                                        </Grid>

                                        <Grid item>
                                          <ListItem>
                                            <span>Status :</span>
                                            <span>&nbsp; {task.status}</span>
                                          </ListItem>
                                        </Grid>

                                        <Grid item>
                                          <ListItem>
                                            <span>Cron :</span>
                                            <span>
                                              &nbsp;{" "}
                                              {task.cron &&
                                              task.cron.split(" ").length ===
                                                5 &&
                                              task.cron
                                                .split(" ")
                                                .includes("") === false
                                                ? cronstrue.toString(
                                                    task.cron,
                                                    {
                                                      verbose: true,
                                                      throwExceptionOnParseError: true,
                                                    }
                                                  )
                                                : "Cron value not proper"}
                                            </span>
                                          </ListItem>
                                        </Grid>
                                      </Grid>
                                    </Typography>
                                  </Box>
                                ))
                              : null}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                    // )
                  )
                ) : (
                  <> {!isRequesting && <NoDataFound />}</>
                )}
              </LoadingIndicator>
            ) : null}
          </Box>

          <Box
            style={{
              height: "10%",
              position: "relative",
            }}
          >
            {props.platform_scheduling.hits
              ? props.platform_scheduling.hits.hits.length !== 0 && (
                  <PaginationUI
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChangePagination}
                  />
                )
              : null}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(TaskScheduler);
