import ActionUtility from "../../../../utilities/ActionUtility";
import YoutubeOriginalEffect from "./YoutubeOriginalEffect";

export default class YoutubeOriginalAction {
  // YoutubeOriginalAction action with an function declaration
  static REQUEST_YOUTUBETRACKCLAIMS =
    "YoutubeOriginalAction.REQUEST_YOUTUBETRACKCLAIMS";
  static REQUEST_YOUTUBETRACKCLAIMS_FINISHED =
    "YoutubeOriginalAction.REQUEST_YOUTUBETRACKCLAIMS_FINISHED";
  static REQUEST_YOUTUBEAFPREPORTS =
    "YoutubeOriginalAction.REQUEST_YOUTUBEAFPREPORTS";
  static REQUEST_YOUTUBEAFPREPORTS_FINISHED =
    "YoutubeOriginalAction.REQUEST_YOUTUBEAFPREPORTS_FINISHED";

  // METHODS
  // Organization GET function
  static requestYoutubeTrackClaims(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOriginalAction.REQUEST_YOUTUBETRACKCLAIMS,
        YoutubeOriginalEffect.requestYoutubeTrackClaims,
        params
      );
    };
  }

  static requestYoutubeAFPReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOriginalAction.REQUEST_YOUTUBEAFPREPORTS,
        YoutubeOriginalEffect.requestYoutubeAFPReports,
        params
      );
    };
  }

  // static requestYoutubeChannelRevenue(params) {
  //   return async (dispatch, getState) => {
  //     await ActionUtility.createThunkEffect(
  //       dispatch,
  //       YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE,
  //       YoutubeOfficialEffect.requestYoutubeChannelRevenue,
  //       params
  //     );
  //   };
  // }
}
