import { BaseModel } from "sjs-base-model";
import AddAccountShardsModel from "./AddAccountShardsModel";
import AddAccountHitsModel from "./AddAccountHitsModel";

// category Model
export default class AddAccountModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = { _shards: AddAccountShardsModel };
  current_page_no = 0;
  hits = { hits: AddAccountHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
