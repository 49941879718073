import { BaseModel } from "sjs-base-model";
import DiscordFeedsdataHitsModel from "./DiscordFeedsdataHitsModel";

export default class DiscordFeedsHitsModel extends BaseModel {
  hits = [DiscordFeedsdataHitsModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
