import { BaseModel } from "sjs-base-model";
import GmbQuestionsDataHitsModel from "./GmbQuestionsDataHitsModel";

//GmbQuestionsHitsModel
export default class GmbQuestionsHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ GmbQuestionsDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
