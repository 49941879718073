import ActionUtility from "../../../../utilities/ActionUtility";
import InfluencerLanguageEffect from "./InfluencerLanguageEffect";

export default class InfluencerLanguageAction {
  // InfluencerLanguageAction action with an function declaration
  static REQUEST_INFLUENCERS_LANGUAGE =
    "InfluencerLanguageAction.REQUEST_INFLUENCERS_LANGUAGE";
  static REQUEST_INFLUENCERS_LANGUAGE_FINISHED =
    "InfluencerLanguageAction.REQUEST_INFLUENCERS_LANGUAGE_FINISHED";

  // METHODS
  // InfluencersActivity GET function
  static requestInfluencersLanguage(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerLanguageAction.REQUEST_INFLUENCERS_LANGUAGE,
        InfluencerLanguageEffect.requestInfluencersLanguage,
        params
      );
    };
  }
}
