import ActionUtility from "../../../../utilities/ActionUtility";
import LinkCheckingEffect from "./LinkCheckingEffect";

export default class LinkCheckingAction {
  // LinkCheckingAction action with an function declaration
  static REQUEST_LINK_CHECKING = "LinkCheckingAction.REQUEST_LINK_CHECKING";
  static REQUEST_LINK_CHECKING_FINISHED =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_FINISHED";

  static REQUEST_LINK_CHECKING_FILTER =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER";
  static REQUEST_LINK_CHECKING_FILTER_FINISHED =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER_FINISHED";

  static REQUEST_PUT_LINK_CHECKING =
    "LinkCheckingAction.REQUEST_PUT_LINK_CHECKING";
  static REQUEST_PUT_LINK_CHECKING_FINISHED =
    "LinkCheckingAction.REQUEST_PUT_LINK_CHECKING_FINISHED";

  static REQUEST_POST_LINK_CHECKING =
    "LinkCheckingAction.REQUEST_POST_LINK_CHECKING";
  static REQUEST_POST_LINK_CHECKING_FINISHED =
    "LinkCheckingAction.REQUEST_POST_LINK_CHECKING_FINISHED";

  static REQUEST_LINK_CHECKING_CLEAR =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_CLEAR";
  static REQUEST_LINK_CHECKING_CLEAR_FINISHED =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_CLEAR_FINISHED";

  static REQUEST_LINK_CHECKING_FILTER_CLEAR =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER_CLEAR";
  static REQUEST_LINK_CHECKING_FILTER_CLEAR_FINISHED =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_LINK_CHECKING =
    "LinkCheckingAction.REQUEST_DELETE_LINK_CHECKING";
  static REQUEST_DELETE_LINK_CHECKING_FINISHED =
    "LinkCheckingAction.REQUEST_DELETE_LINK_CHECKING_FINISHED";

  static REQUEST_POST_UPLOAD_LINKS =
    "LinkCheckingAction.REQUEST_POST_UPLOAD_LINKS";
  static REQUEST_POST_UPLOAD_LINKS_FINISHED =
    "LinkCheckingAction.REQUEST_POST_UPLOAD_LINKS_FINISHED";

  static REQUEST_VALIDATE_LINK_CHECKING =
    "LinkCheckingAction.REQUEST_VALIDATE_LINK_CHECKING";
  static REQUEST_VALIDATE_LINK_CHECKING_FINISHED =
    "LinkCheckingAction.REQUEST_VALIDATE_LINK_CHECKING_FINISHED";

  static REQUEST_VALIDATE_LINK_CHECKING_WITH_FILTERS =
    "LinkCheckingAction.REQUEST_VALIDATE_LINK_CHECKING_WITH_FILTERS";
  static REQUEST_VALIDATE_LINK_CHECKING_WITH_FILTERS_FINISHED =
    "LinkCheckingAction.REQUEST_VALIDATE_LINK_CHECKING_WITH_FILTERS_FINISHED";

  static REQUEST_ARCHIVED_LINKS = "LinkCheckingAction.REQUEST_ARCHIVED_LINKS";
  static REQUEST_ARCHIVED_LINKS_FINISHED =
    "LinkCheckingAction.REQUEST_ARCHIVED_LINKS_FINISHED";

  static REQUEST_ARCHIVED_LINKS_FILTER =
    "LinkCheckingAction.REQUEST_ARCHIVED_LINKS_FILTER";
  static REQUEST_ARCHIVED_LINKS_FILTER_FINISHED =
    "LinkCheckingAction.REQUEST_ARCHIVED_LINKS_FILTER_FINISHED";

  static REQUEST_LINK_CHECKING_PLATFORMS =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORMS";
  static REQUEST_LINK_CHECKING_PLATFORMS_FINISHED =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORMS_FINISHED";

  static REQUEST_LINK_CHECKING_PLATFORM_FILTER =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORM_FILTER";
  static REQUEST_LINK_CHECKING_PLATFORM_FILTER_FINISHED =
    "LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORM_FILTER_FINISHED";

  static REQUEST_POST_LINK_CHECKING_PLATFORMS =
    "LinkCheckingAction.REQUEST_POST_LINK_CHECKING_PLATFORMS";
  static REQUEST_POST_LINK_CHECKING_PLATFORMS_FINISHED =
    "LinkCheckingAction.REQUEST_POST_LINK_CHECKING_PLATFORMS_FINISHED";

  static REQUEST_PUT_LINK_CHECKING_PLATFORMS =
    "LinkCheckingAction.REQUEST_PUT_LINK_CHECKING_PLATFORMS";
  static REQUEST_PUT_LINK_CHECKING_PLATFORMS_FINISHED =
    "LinkCheckingAction.REQUEST_PUT_LINK_CHECKING_PLATFORMS_FINISHED";

  // METHODS
  // LINK_CHECKING GET function
  static requestLinkChecking(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_LINK_CHECKING,
        LinkCheckingEffect.requestLinkChecking,
        params
      );
    };
  }

  static requestLinkCheckingClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_LINK_CHECKING_CLEAR,
        LinkCheckingEffect.requestLinkCheckingClear
      );
    };
  }

  static requestLinkCheckingFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER_CLEAR,
        LinkCheckingEffect.requestLinkCheckingFilterClear
      );
    };
  }

  static requestLinkCheckingFilter(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_LINK_CHECKING_FILTER,
        LinkCheckingEffect.requestLinkCheckingFilter,
        params,
        callBack
      );
    };
  }
  static requestPutLinkChecking(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_PUT_LINK_CHECKING,
        LinkCheckingEffect.requestPutLinkChecking,
        data,
        id
      );
    };
  }

  static requestPutLinkCheckingPlatforms(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_PUT_LINK_CHECKING_PLATFORMS,
        LinkCheckingEffect.requestPutLinkCheckingPlatforms,
        data,
        id
      );
    };
  }

  static requestPostLinkChecking(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_POST_LINK_CHECKING,
        LinkCheckingEffect.requestPostLinkChecking,
        data,
        params,
        callBack
      );
    };
  }

  static requestPostLinkCheckingPlatfrom(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_POST_LINK_CHECKING_PLATFORMS,
        LinkCheckingEffect.requestPostLinkCheckingPlatfrom,
        data,
        params,
        callBack
      );
    };
  }

  static requestDeleteLinkChecking(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_DELETE_LINK_CHECKING,
        LinkCheckingEffect.requestDeleteLinkChecking,
        id
      );
    };
  }

  static requestPostUploadLinks(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_POST_UPLOAD_LINKS,
        LinkCheckingEffect.requestPostUploadLinks,
        data,
        params,
        callBack
      );
    };
  }

  static requestValidateLinks(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_VALIDATE_LINK_CHECKING,
        LinkCheckingEffect.requestValidateLinks,
        params
      );
    };
  }

  static requestValidateLinksWithFilters(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_VALIDATE_LINK_CHECKING_WITH_FILTERS,
        LinkCheckingEffect.requestValidateLinksWithFilters,
        params,
        callback
      );
    };
  }

  static requestArchivedLinks(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_ARCHIVED_LINKS,
        LinkCheckingEffect.requestArchivedLinks,
        params
      );
    };
  }

  static requestArchivedLinksFilter(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_ARCHIVED_LINKS_FILTER,
        LinkCheckingEffect.requestArchivedLinksFilter,
        params,
        callback
      );
    };
  }

  static requestLinkCheckingPlatforms(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORMS,
        LinkCheckingEffect.requestLinkCheckingPlatforms,
        params
      );
    };
  }

  static requestLinkCheckingPlatformsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LinkCheckingAction.REQUEST_LINK_CHECKING_PLATFORM_FILTER,
        LinkCheckingEffect.requestLinkCheckingPlatformsFilter,
        params
      );
    };
  }
}
