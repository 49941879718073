import { BaseModel } from "sjs-base-model";

export default class TicketFirstResponseTimeModel extends BaseModel {
  //Ticket FRT model

  ticket_first_response_time = {
    value: 0,
    value_as_string: "",
  };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
