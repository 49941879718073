import { BaseModel } from "sjs-base-model";
import WordCloudDataHitsModel from "./WordCloudDataHitsModel";

// get model for word cloud
export default class WordCloudHitsModel extends BaseModel {
  hits = [{ WordCloudDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
