import BaseReducer from "../../../utilities/BaseReducer";
import ManageRoleAction from "./ManageRoleAction";

export default class ManageRoleTemplateReducer extends BaseReducer {
  initialState = {
    roleTemplate: [],
  };

  [ManageRoleAction.REQUEST_ROLE_TEMPLATE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      roleTemplate: action.payload,
    };
  }
}
