import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  List,
  ListItem,
  OutlinedInput,
  Select,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 100,
    textAlign: "center", // Center the selected text
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 1px 5px 0px gray`,
    },
  },
  border: {
    border: `1px solid #707070`,
    borderRadius: 2,
    boxShadow: "0px 1px 5px 0px #707070",
  },
}));

// Selection function
export default function MuiSelectField({
  options,
  label,
  handleSelectChange,
  value,
}) {
  //   const [data, setData] = useState("");

  const classes = useStyles();
  //   theme.components = {
  //     MuiOutlinedInput: {
  //       styleOverrides: {
  //         root: {
  //           outline: `solid 0.5px ${theme.palette.borderColor.textField}`,
  //           backgroundColor: "green",
  //           borderRadius: theme.palette.buttonComponent.borderRadius.small,
  //         },
  //       },
  //     },
  //   };

  const menuProps = {
    PaperProps: {
      className: classes.border,
      style: {
        maxHeight: 300,
        width: 200,
        marginTop: 32,
        marginLeft: 40,
      },
    },
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        displayEmpty
        className={classes.select}
        MenuProps={menuProps}
        // label={label}
        onChange={(e) => {
          handleSelectChange(e.target.value);
        }}
        renderValue={(selected) => {
          if (!selected) {
            return <em sx={{ padding: "10px" }}>Select Status</em>;
          }
          return selected;
        }}
        placeholder="Select Status"
        input={
          <OutlinedInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              "& svg": {
                margin: "40px",
                // color: "red",
              },
              outline: "none",
              backgroundColor: "#f3f9fb",
              border: "0px solid gray",
              //   boxShadow: "0px 1px 5px 1px gray",
              height: "100px",
              width: "120px",
            }}
          />
        }
      >
        <MenuItem disabled value="">
          <em>Select Status</em>
        </MenuItem>

        {options.map((item, ind) => (
          <MenuItem value={item.user_name} key={ind}>
            <Checkbox
              size="small"
              checked={value && value.indexOf(item.user_name) > -1}
            />
            {item.full_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
