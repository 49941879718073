import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import SessionLogGetModel from "./models/SessionLogGetModel";
import SessionLogReportGetModel from "./models/SessionLogReportGetModel";

// SessionLogEffect
export default class SessionLogEffect {
  // calling an API according to the SessionLog model
  static async requestSessionLog(params) {
    const endpoint = environment.api.sessionLog;
    return EffectUtility.getToModel(SessionLogGetModel, endpoint, params);
  }

  // Get method for the SessionLog filter
  static async requestSessionLogFilter(params) {
    const endpoint = environment.api.sessionLog;
    return EffectUtility.getToModel(SessionLogGetModel, endpoint, params);
  }

  static async requestSessionLogReport(params) {
    const endpoint = environment.api.sessionLogReport;
    return EffectUtility.getToModel(SessionLogReportGetModel, endpoint, params);
  }
}
