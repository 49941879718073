import BaseReducer from "../../../utilities/BaseReducer";
import AllMentionsAction from "./AllMentionsAction";

// AllMentionsReduser
export default class AllMentionsReduser extends BaseReducer {
  initialState = {
    allmentions: [],
  };
  [AllMentionsAction.REQUEST_ALLMENTIONS_FINISHED](state, action) {
    return {
      ...state,
      allmentions: action.payload,
    };
  }
}
