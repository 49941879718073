import ActionUtility from "../../../../utilities/ActionUtility";
import StatusEffect from "./StatusEffect";

export default class StatusAction {
  // StatusAction action with an function declaration
  static REQUEST_STATUS = "StatusAction.REQUEST_STATUS";
  static REQUEST_STATUS_FINISHED = "StatusAction.REQUEST_STATUS_FINISHED";

  static REQUEST_STATUS_FILTER = "StatusAction.REQUEST_STATUS_FILTER";
  static REQUEST_STATUS_FILTER_FINISHED =
    "StatusAction.REQUEST_STATUS_FILTER_FINISHED";

  static REQUEST_PUT_STATUS = "StatusAction.REQUEST_PUT_STATUS";
  static REQUEST_PUT_STATUS_FINISHED =
    "StatusAction.REQUEST_PUT_STATUS_FINISHED";

  static REQUEST_POST_STATUS = "StatusAction.REQUEST_POST_STATUS";
  static REQUEST_POST_STATUS_FINISHED =
    "StatusAction.REQUEST_POST_STATUS_FINISHED";

  static REQUEST_PUT_STATUS_BULKUPDATE =
    "StatusAction.REQUEST_PUT_STATUS_BULKUPDATE";
  static REQUEST_PUT_STATUS_BULKUPDATE_FINISHED =
    "StatusAction.REQUEST_PUT_STATUS_BULKUPDATE_FINISHED";

  static REQUEST_STATUS_CLEAR = "StatusAction.REQUEST_STATUS_CLEAR";
  static REQUEST_STATUS_CLEAR_FINISHED =
    "StatusAction.REQUEST_STATUS_CLEAR_FINISHED";

  static REQUEST_STATUS_FILTER_CLEAR =
    "StatusAction.REQUEST_STATUS_FILTER_CLEAR";
  static REQUEST_STATUS_FILTER_CLEAR_FINISHED =
    "StatusAction.REQUEST_STATUS_FILTER_CLEAR_FINISHED";

  // METHODS
  // Organization GET function
  static requestStatus(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StatusAction.REQUEST_STATUS,
        StatusEffect.requestStatus,
        params
      );
    };
  }

  static requestStatusClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StatusAction.REQUEST_STATUS_CLEAR,
        StatusEffect.requestStatusClear
      );
    };
  }

  static requestStatusFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StatusAction.REQUEST_STATUS_FILTER_CLEAR,
        StatusEffect.requestStatusFilterClear
      );
    };
  }

  static requestStatusFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StatusAction.REQUEST_STATUS_FILTER,
        StatusEffect.requestStatusFilter,
        params
      );
    };
  }
  static requestPutStatus(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StatusAction.REQUEST_PUT_STATUS,
        StatusEffect.requestPutStatus,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostStatus(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StatusAction.REQUEST_POST_STATUS,
        StatusEffect.requestPostStatus,
        data
      );
    };
  }

  static requestPutStatusBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        StatusAction.REQUEST_PUT_STATUS_BULKUPDATE,
        StatusEffect.requestPutStatusBulkUpdate,
        data
      );
    };
  }
}
