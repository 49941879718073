import BaseReducer from "../../../utilities/BaseReducer";
import YoutubeAccountAction from "./YoutubeAccountAction";

// YoutubeAccountReduser reducer function
export default class YoutubeAccountReduser extends BaseReducer {
  initialState = {
    youtubeAccount: [],
  };
  [YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      youtubeAccount: action.payload,
    };
  }
}
