import BaseReducer from "../../../../utilities/BaseReducer";
import TimeZoneAction from "./TimeZoneAction";

// TimeZoneReduser
export default class TimeZoneReduser extends BaseReducer {
  initialState = {
    timezone: [],
  };
  [TimeZoneAction.REQUEST_TIMEZONE_FINISHED](state, action) {
    return {
      ...state,
      timezone: action.payload,
    };
  }
}
