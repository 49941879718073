import BaseReducer from "../../../utilities/BaseReducer";
import WhatsappAccountAction from "./WhatsappAccountAction";

// WhatsappAccountReduser
export default class WhatsappAccountReduser extends BaseReducer {
  initialState = {
    whatsappAccount: [],
  };
  [WhatsappAccountAction.REQUEST_WHATSAPP_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      whatsappAccount: action.payload,
    };
  }
}
