import environment from "environment";
import ConsumerAccountModel from "./models/ConsumerAccountGetModel";
import ConsumerAccountPutModel from "./models/ConsumerAccountPutModel";
import ConsumerAccountPostModel from "./models/ConsumerAccountPostModel";
import ConsumerAccountDeleteModel from "./models/ConsumerAccountDeleteModel";
import EffectUtility from "../../../utilities/EffectUtility";

export default class ConsumerAccountEffect {
  // calling an API according to the consumer account Account model

  // Get method for the consumer account account
  static async requestConsumerAccount(params) {
    const endpoint = environment.api.consumer_account;
    return EffectUtility.getToModel(ConsumerAccountModel, endpoint, params);
  }

  // Get method for the consumer account account filter
  static async requestConsumerAccountFilter(params) {
    const endpoint = environment.api.consumer_account;
    return EffectUtility.getToModel(ConsumerAccountModel, endpoint, params);
  }

  // put method for the consumer account account filter
  static async requestPutConsumerAccount(data, id) {
    const endpoint = environment.api.consumer_account + "/" + id;
    return EffectUtility.putToModel(ConsumerAccountPutModel, endpoint, data);
  }

  // post method for the consumer account account filter
  static async requestPostConsumerAccount(data) {
    const endpoint = environment.api.consumer_account + "/add";

    return EffectUtility.postToModel(ConsumerAccountPostModel, endpoint, data);
  }

  static async requestDeleteConsumerAccount(id) {
    const endpoint = environment.api.consumer_account + "/" + id;

    return EffectUtility.deleteToModel(ConsumerAccountDeleteModel, endpoint);
  }
}
