import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeVideosWebhookAction from "./YoutubeVideosWebhookAction";

export default class YoutubeVideosWebhookFilterReducer extends BaseReducer {
  initialState = {
    youtubeVideosWebhookFilter: [],
  };

  [YoutubeVideosWebhookAction.REQUEST_YOUTUBE_VIDEOS_WEBHOOK_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeVideosWebhookFilter: action.payload,
    };
  }
}
