import BaseReducer from "../../../utilities/BaseReducer";
import DiscordAccountAction from "./DiscordAccountAction";

export default class DiscordAccountReducer extends BaseReducer {
  initialState = {
    discordAccount: [],
  };
  [DiscordAccountAction.REQUEST_DISCORD_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      discordAccount: action.payload,
    };
  }
}
