import BaseReducer from "../../../../utilities/BaseReducer";
import TopStoreAction from "./TopStoreAction";

// TopRetailerReduser
export default class TopRetailerReduser extends BaseReducer {
  initialState = {
    topRetailer: [],
  };
  [TopStoreAction.REQUEST_TOPRETAILER_FINISHED](state, action) {
    return {
      ...state,
      topRetailer: action.payload,
    };
  }
}
