import { BaseModel } from "sjs-base-model";

// YoutubeContentDownloadModel
export default class YoutubeContentDownloadModel extends BaseModel {
  // the reponse is JSON only when error else it is a blob()

  message = "";
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
