import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import TicketSettingsModel from "./models/TicketSettingsModel";
import TicketSettingsPostModel from "./models/TicketSettingsPostModel";
import TicketSettingsPutModel from "./models/TicketSettingsPutModel";

export default class TicketSettingsEffect {
  //get  request end point
  static async requestTicketSettings(params) {
    const endpoint = environment.api.ticketSettings;

    return EffectUtility.getToModel(TicketSettingsModel, endpoint, params);
  }

  // Post api
  static async requestPostTicketSettings(data) {
    const endpoint = environment.api.ticketSettings + "/add";

    return EffectUtility.postToModel(TicketSettingsPostModel, endpoint, data);
  }

  //Put api
  static async requestPutTicketSettings(data, id) {
    const endpoint = environment.api.ticketSettings + "/" + id;

    return EffectUtility.putToModel(TicketSettingsPutModel, endpoint, data);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
