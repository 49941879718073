export function startDate(date) {
  return new Date(date && date.getFullYear(), date && date.getMonth(), 1);
}

export function endDate(date) {
  return new Date(date && date.getFullYear(), date && date.getMonth() + 1, 0);
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
