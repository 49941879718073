import React, { useState } from "react";
import ReactWordcloud from "react-wordcloud";
import { Box, Grid } from "@mui/material";
import "tippy.js/dist/tippy.css";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import PaginationUI from "../../../components/pagination/Pagination";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";

const WordCloudStatic = (props) => {

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [loading, setLoading] = useState(false);
  const data = {
    status: "success",
    ticket_report: {
      reports_on_word_cloud: [
        {
          count: 316,
          key: "suraj",
          percentage: 5.47,
        },
        {
          count: 129,
          key: "sesquipedalian",
          percentage: 2.23,
        },
        {
          count: 953,
          key: "ratings",
          percentage: 16.51,
        },
        {
          count: 141,
          key: "snapchat",
          percentage: 2.44,
        },
        {
          count: 52,
          key: "snap",
          percentage: 0.9,
        },
        {
          count: 70,
          key: "reels",
          percentage: 1.21,
        },
        {
          count: 15,
          key: "sandhya",
          percentage: 0.26,
        },
        {
          count: 13,
          key: "bhabho",
          percentage: 0.23,
        },
        {
          count: 13,
          key: "neeluvaghela",
          percentage: 0.23,
        },
        {
          count: 13,
          key: "garmi",
          percentage: 0.23,
        },
        {
          count: 31,
          key: "foryou",
          percentage: 0.54,
        },
        {
          count: 11,
          key: "televison",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "anasrashidactor",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "mina",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "deepikasingh",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "tvseries",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "\u0938\u0942\u0930\u091c",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "diyaourbati",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "diyaorbatihum",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "sandhyarathi",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "kanikamaheshwari",
          percentage: 0.19,
        },
        {
          count: 11,
          key: "anasrashid",
          percentage: 0.19,
        },
        {
          count: 35,
          key: "viralreels",
          percentage: 0.61,
        },
        {
          count: 35,
          key: "instagood",
          percentage: 0.61,
        },
        {
          count: 10,
          key: "\ud83c\udf1e",
          percentage: 0.17,
        },
        {
          count: 39,
          key: "trendingreels",
          percentage: 0.68,
        },
        {
          count: 9,
          key: "neelu_vaghela",
          percentage: 0.16,
        },
        {
          count: 9,
          key: "surajrox",
          percentage: 0.16,
        },
        {
          count: 9,
          key: "diya_tum_bati_hum",
          percentage: 0.16,
        },
        {
          count: 9,
          key: "deepikasingh150",
          percentage: 0.16,
        },
        {
          count: 9,
          key: "kanicamaheshwari",
          percentage: 0.16,
        },
        {
          count: 85,
          key: "viral",
          percentage: 1.47,
        },
        {
          count: 9,
          key: "\ud83d\udc49\ud83c\udffb",
          percentage: 0.16,
        },
        {
          count: 8,
          key: "hanumanji",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "reallife",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "\ud83d\udcff",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "suraj.rox.official_",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "insta_girl_annu_8090",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "hanumanchalisa",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "pawanputrahanuman",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "1win_india",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "1win",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "tagyourfriends",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "insra_boy_suraj_official",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "dwarkadhish",
          percentage: 0.14,
        },
        {
          count: 42,
          key: "device",
          percentage: 0.73,
        },
        {
          count: 15,
          key: "\ud83e\udd40",
          percentage: 0.26,
        },
        {
          count: 10,
          key: "ayodhya",
          percentage: 0.17,
        },
        {
          count: 11,
          key: "\ud83d\ude48",
          percentage: 0.19,
        },
        {
          count: 23,
          key: "\u2665\ufe0f",
          percentage: 0.4,
        },
        {
          count: 9,
          key: "917019899879",
          percentage: 0.16,
        },
        {
          count: 13,
          key: "anupama",
          percentage: 0.23,
        },
        {
          count: 7,
          key: "jaishreekrishna",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\u0915\u093e\u0939\u0942",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\ud83d\udecd",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\ud83e\ude90",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\u0938\u0930\u0928\u093e",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\u0930\u0915\u094d\u0937\u0938\u0915",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "hanumanbhakt",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\u0932\u0939\u0948",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "hanumanstatus",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\u0924\u0941\u092e\u093e\u0930\u0940",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\ud83d\udc84",
          percentage: 0.12,
        },
        {
          count: 12,
          key: "comedyreels",
          percentage: 0.21,
        },
        {
          count: 8,
          key: "justforfun",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "ss06",
          percentage: 0.14,
        },
        {
          count: 7,
          key: "\u0938\u0941\u0916",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\u0921\u0930\u0928\u093e",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "\ud83d\ude1a",
          percentage: 0.12,
        },
        {
          count: 14,
          key: "ham",
          percentage: 0.24,
        },
        {
          count: 14,
          key: "instareels",
          percentage: 0.24,
        },
        {
          count: 6,
          key: "surajroxfunnyvibeo_",
          percentage: 0.1,
        },
        {
          count: 6,
          key: "surajroxfunnyvibeo",
          percentage: 0.1,
        },
        {
          count: 40,
          key: "banned",
          percentage: 0.69,
        },
        {
          count: 13,
          key: "\ud83d\udcab",
          percentage: 0.23,
        },
        {
          count: 13,
          key: "\ud83d\ude18",
          percentage: 0.23,
        },
        {
          count: 21,
          key: "viralvideos",
          percentage: 0.36,
        },
        {
          count: 11,
          key: "tvshows",
          percentage: 0.19,
        },
        {
          count: 30,
          key: "reelsinstagram",
          percentage: 0.52,
        },
        {
          count: 63,
          key: "instagram",
          percentage: 1.09,
        },
        {
          count: 5,
          key: "fbreels",
          percentage: 0.09,
        },
        {
          count: 5,
          key: "steph",
          percentage: 0.09,
        },
        {
          count: 5,
          key: "viralreelsfb",
          percentage: 0.09,
        },
        {
          count: 5,
          key: "rox",
          percentage: 0.09,
        },
        {
          count: 10,
          key: "\ud83d\udc96",
          percentage: 0.17,
        },
        {
          count: 8,
          key: "nadi",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "\u0924\u0941\u092e",
          percentage: 0.14,
        },
        {
          count: 13,
          key: "\ud83d\udc51",
          percentage: 0.23,
        },
        {
          count: 13,
          key: "\ud83d\ude14",
          percentage: 0.23,
        },
        {
          count: 4,
          key: "suraj.rox",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "instaviral",
          percentage: 0.07,
        },
        {
          count: 63,
          key: "trending",
          percentage: 1.09,
        },
        {
          count: 308,
          key: "5",
          percentage: 5.34,
        },
        {
          count: 23,
          key: "bug",
          percentage: 0.4,
        },
        {
          count: 9,
          key: "\ud83d\udc93",
          percentage: 0.16,
        },
        {
          count: 20,
          key: "\ud83d\udcaf",
          percentage: 0.35,
        },
        {
          count: 12,
          key: "\ud83d\udea9",
          percentage: 0.21,
        },
        {
          count: 13,
          key: "temporarily",
          percentage: 0.23,
        },
        {
          count: 4,
          key: "\ud83c\udf04",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "brightness",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "xxxtentacion",
          percentage: 0.07,
        },
        {
          count: 8,
          key: "\ud83d\udc9e",
          percentage: 0.14,
        },
        {
          count: 12,
          key: "\ud83d\udc94",
          percentage: 0.21,
        },
        {
          count: 3,
          key: "toptags",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "trendingcomedy",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "nirhuaa_amrpali_dubey",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "avdheshanandg_quotes",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "telephoto",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "viralcomedyreels",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "shilpi",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "1654",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "wordoftheday",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "tenge",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\u0917\u0930\u094d\u092e\u0940",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "suraj.new",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "angraecum",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "neelkamal",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "khesharilalyadav",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83e\udd85",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "singhania",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "amrindergill",
          percentage: 0.05,
        },
        {
          count: 8,
          key: "snaps",
          percentage: 0.14,
        },
        {
          count: 10,
          key: "\ud83d\udc95",
          percentage: 0.17,
        },
        {
          count: 5,
          key: "couplegoals",
          percentage: 0.09,
        },
        {
          count: 40,
          key: "\ud83d\ude4f",
          percentage: 0.69,
        },
        {
          count: 7,
          key: "\ud83d\ude1e",
          percentage: 0.12,
        },
        {
          count: 60,
          key: "\u2764\ufe0f",
          percentage: 1.04,
        },
        {
          count: 8,
          key: "\ud83d\ude80",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "\ud83d\ude08",
          percentage: 0.14,
        },
        {
          count: 19,
          key: "iphone",
          percentage: 0.33,
        },
        {
          count: 9,
          key: "comedyvideos",
          percentage: 0.16,
        },
        {
          count: 9,
          key: "funnyreels",
          percentage: 0.16,
        },
        {
          count: 18,
          key: "explorepage",
          percentage: 0.31,
        },
        {
          count: 4,
          key: "likeforlikes",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "viralreelsvideo",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "pawansingh",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83e\uddff",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "vairalvideo",
          percentage: 0.05,
        },
        {
          count: 7,
          key: "\u0915\u094c\u0928",
          percentage: 0.12,
        },
        {
          count: 7,
          key: "fb",
          percentage: 0.12,
        },
        {
          count: 11,
          key: "instadaily",
          percentage: 0.19,
        },
        {
          count: 21,
          key: "pronounce",
          percentage: 0.36,
        },
        {
          count: 7,
          key: "viralpost",
          percentage: 0.12,
        },
        {
          count: 4,
          key: "vivek_020293",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "lovequotes",
          percentage: 0.07,
        },
        {
          count: 8,
          key: "\u0938\u092c",
          percentage: 0.14,
        },
        {
          count: 3,
          key: "explorar",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "aashish",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "romans",
          percentage: 0.05,
        },
        {
          count: 8,
          key: "\ud83d\ude09",
          percentage: 0.14,
        },
        {
          count: 76,
          key: "app",
          percentage: 1.32,
        },
        {
          count: 5,
          key: "\ud83e\udde1",
          percentage: 0.09,
        },
        {
          count: 16,
          key: "\u2764",
          percentage: 0.28,
        },
        {
          count: 12,
          key: "bhojpuri",
          percentage: 0.21,
        },
        {
          count: 3,
          key: "shortsvideo",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "photooftheday",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "loveyourself",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "comedyclub",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83d\udcaa\ud83c\udffb",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "masti",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "bitmojis",
          percentage: 0.05,
        },
        {
          count: 11,
          key: "shree",
          percentage: 0.19,
        },
        {
          count: 5,
          key: "\ud83c\udf39",
          percentage: 0.09,
        },
        {
          count: 6,
          key: "\ud83e\udd2a",
          percentage: 0.1,
        },
        {
          count: 5,
          key: "exploremore",
          percentage: 0.09,
        },
        {
          count: 5,
          key: "instamood",
          percentage: 0.09,
        },
        {
          count: 7,
          key: "bitmoji",
          percentage: 0.12,
        },
        {
          count: 35,
          key: "\ud83d\ude02",
          percentage: 0.61,
        },
        {
          count: 3,
          key: "\ud83d\udc8b",
          percentage: 0.05,
        },
        {
          count: 13,
          key: "\ud83d\ude0e",
          percentage: 0.23,
        },
        {
          count: 6,
          key: "\u2763\ufe0f",
          percentage: 0.1,
        },
        {
          count: 6,
          key: "reelsvideo",
          percentage: 0.1,
        },
        {
          count: 4,
          key: "likesforlike",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "reelsinsta",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83e\udd1e",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "streaks",
          percentage: 0.09,
        },
        {
          count: 7,
          key: "fyp",
          percentage: 0.12,
        },
        {
          count: 21,
          key: "\ud83e\udd23",
          percentage: 0.36,
        },
        {
          count: 51,
          key: "account",
          percentage: 0.88,
        },
        {
          count: 19,
          key: "upload",
          percentage: 0.33,
        },
        {
          count: 4,
          key: "\ud83d\udc97",
          percentage: 0.07,
        },
        {
          count: 5,
          key: "instagramreels",
          percentage: 0.09,
        },
        {
          count: 7,
          key: "\ud83d\udc40",
          percentage: 0.12,
        },
        {
          count: 6,
          key: "curry",
          percentage: 0.1,
        },
        {
          count: 6,
          key: "instalike",
          percentage: 0.1,
        },
        {
          count: 17,
          key: "disabled",
          percentage: 0.29,
        },
        {
          count: 8,
          key: "shayari",
          percentage: 0.14,
        },
        {
          count: 12,
          key: "bugs",
          percentage: 0.21,
        },
        {
          count: 6,
          key: "insta",
          percentage: 0.1,
        },
        {
          count: 4,
          key: "funnyvideo",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "emoji",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83e\udd1f",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83e\udd0d",
          percentage: 0.05,
        },
        {
          count: 27,
          key: "shorts",
          percentage: 0.47,
        },
        {
          count: 4,
          key: "tranding",
          percentage: 0.07,
        },
        {
          count: 6,
          key: "ui",
          percentage: 0.1,
        },
        {
          count: 10,
          key: "dev",
          percentage: 0.17,
        },
        {
          count: 5,
          key: "filters",
          percentage: 0.09,
        },
        {
          count: 6,
          key: "crashing",
          percentage: 0.1,
        },
        {
          count: 42,
          key: "funny",
          percentage: 0.73,
        },
        {
          count: 9,
          key: "notification",
          percentage: 0.16,
        },
        {
          count: 22,
          key: "chat",
          percentage: 0.38,
        },
        {
          count: 3,
          key: "hun",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83d\udcac",
          percentage: 0.05,
        },
        {
          count: 33,
          key: "comment",
          percentage: 0.57,
        },
        {
          count: 12,
          key: "dost",
          percentage: 0.21,
        },
        {
          count: 8,
          key: "typing",
          percentage: 0.14,
        },
        {
          count: 3,
          key: "doremon",
          percentage: 0.05,
        },
        {
          count: 7,
          key: "funnyvideos",
          percentage: 0.12,
        },
        {
          count: 23,
          key: "\ud83d\ude0d",
          percentage: 0.4,
        },
        {
          count: 6,
          key: "reelitfeelit",
          percentage: 0.1,
        },
        {
          count: 37,
          key: "nice",
          percentage: 0.64,
        },
        {
          count: 4,
          key: "followforfollowback",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "\ud83d\ude4f\ud83c\udffb",
          percentage: 0.07,
        },
        {
          count: 395,
          key: "1",
          percentage: 6.84,
        },
        {
          count: 20,
          key: "\u2728",
          percentage: 0.35,
        },
        {
          count: 14,
          key: "login",
          percentage: 0.24,
        },
        {
          count: 12,
          key: "ban",
          percentage: 0.21,
        },
        {
          count: 8,
          key: "chats",
          percentage: 0.14,
        },
        {
          count: 3,
          key: "funnymeme",
          percentage: 0.05,
        },
        {
          count: 8,
          key: "ganga",
          percentage: 0.14,
        },
        {
          count: 8,
          key: "\ud83e\udd17",
          percentage: 0.14,
        },
        {
          count: 3,
          key: "infringing",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "yt",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "permanently",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "\u092c\u0939\u0941\u0924",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "yaad",
          percentage: 0.05,
        },
        {
          count: 4,
          key: "reelkarofeelkaro",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "shortvideo",
          percentage: 0.05,
        },
        {
          count: 13,
          key: "fix",
          percentage: 0.23,
        },
        {
          count: 3,
          key: "anup",
          percentage: 0.05,
        },
        {
          count: 6,
          key: "quot",
          percentage: 0.1,
        },
        {
          count: 9,
          key: "\ud83d\udc47",
          percentage: 0.16,
        },
        {
          count: 23,
          key: "\ud83d\ude0a",
          percentage: 0.4,
        },
        {
          count: 3,
          key: "\ud83d\ude1d",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "107",
          percentage: 0.05,
        },
        {
          count: 6,
          key: "dm",
          percentage: 0.1,
        },
        {
          count: 16,
          key: "locked",
          percentage: 0.28,
        },
        {
          count: 25,
          key: "explore",
          percentage: 0.43,
        },
        {
          count: 3,
          key: "glitch",
          percentage: 0.05,
        },
        {
          count: 6,
          key: "mani",
          percentage: 0.1,
        },
        {
          count: 7,
          key: "selfie",
          percentage: 0.12,
        },
        {
          count: 4,
          key: "subscribe",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "allowance",
          percentage: 0.05,
        },
        {
          count: 8,
          key: "agni",
          percentage: 0.14,
        },
        {
          count: 89,
          key: "4",
          percentage: 1.54,
        },
        {
          count: 5,
          key: "\ud83d\udcaa",
          percentage: 0.09,
        },
        {
          count: 12,
          key: "\ud83d\udca5",
          percentage: 0.21,
        },
        {
          count: 6,
          key: "tu",
          percentage: 0.1,
        },
        {
          count: 3,
          key: "viralvideo",
          percentage: 0.05,
        },
        {
          count: 13,
          key: "tips",
          percentage: 0.23,
        },
        {
          count: 4,
          key: "\ud83d\ude00",
          percentage: 0.07,
        },
        {
          count: 9,
          key: "notifications",
          percentage: 0.16,
        },
        {
          count: 9,
          key: "jay",
          percentage: 0.16,
        },
        {
          count: 8,
          key: "bhagwan",
          percentage: 0.14,
        },
        {
          count: 18,
          key: "\ud83e\udd70",
          percentage: 0.31,
        },
        {
          count: 11,
          key: "memories",
          percentage: 0.19,
        },
        {
          count: 5,
          key: "password",
          percentage: 0.09,
        },
        {
          count: 5,
          key: "spotlight",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "\u091c\u0940",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83d\udda4",
          percentage: 0.05,
        },
        {
          count: 8,
          key: "\ud83d\udc4d",
          percentage: 0.14,
        },
        {
          count: 5,
          key: "map",
          percentage: 0.09,
        },
        {
          count: 8,
          key: "jai",
          percentage: 0.14,
        },
        {
          count: 10,
          key: "button",
          percentage: 0.17,
        },
        {
          count: 3,
          key: "www.instagram.com",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "viratkohli",
          percentage: 0.09,
        },
        {
          count: 9,
          key: "pronunciation",
          percentage: 0.16,
        },
        {
          count: 3,
          key: "\ud83d\ude06",
          percentage: 0.05,
        },
        {
          count: 24,
          key: "issue",
          percentage: 0.42,
        },
        {
          count: 9,
          key: "hanuman",
          percentage: 0.16,
        },
        {
          count: 27,
          key: "hi",
          percentage: 0.47,
        },
        {
          count: 10,
          key: "39",
          percentage: 0.17,
        },
        {
          count: 5,
          key: "chand",
          percentage: 0.09,
        },
        {
          count: 12,
          key: "\u0939\u0948",
          percentage: 0.21,
        },
        {
          count: 3,
          key: "filter",
          percentage: 0.05,
        },
        {
          count: 4,
          key: "tiktok",
          percentage: 0.07,
        },
        {
          count: 7,
          key: "cant",
          percentage: 0.12,
        },
        {
          count: 11,
          key: "\ud83d\udd25",
          percentage: 0.19,
        },
        {
          count: 3,
          key: "\u270c\ufe0f",
          percentage: 0.05,
        },
        {
          count: 7,
          key: "ji",
          percentage: 0.12,
        },
        {
          count: 12,
          key: "unable",
          percentage: 0.21,
        },
        {
          count: 7,
          key: "ramayan",
          percentage: 0.12,
        },
        {
          count: 8,
          key: "pawan",
          percentage: 0.14,
        },
        {
          count: 10,
          key: "remove",
          percentage: 0.17,
        },
        {
          count: 4,
          key: "sunset",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "lock",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "permitted",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "youtube.com",
          percentage: 0.05,
        },
        {
          count: 7,
          key: "sun",
          percentage: 0.12,
        },
        {
          count: 3,
          key: "fitness",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "yadav",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "hua",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83d\ude2d",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "rocks",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "plant",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\u0915\u094d\u092f\u093e",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "suggestion",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "disclaimer",
          percentage: 0.05,
        },
        {
          count: 9,
          key: "\u0915\u0947",
          percentage: 0.16,
        },
        {
          count: 8,
          key: "photos",
          percentage: 0.14,
        },
        {
          count: 4,
          key: "delete",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "reporting",
          percentage: 0.05,
        },
        {
          count: 51,
          key: "video",
          percentage: 0.88,
        },
        {
          count: 3,
          key: "lo",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "loading",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "bihar",
          percentage: 0.05,
        },
        {
          count: 4,
          key: "mera",
          percentage: 0.07,
        },
        {
          count: 5,
          key: "mode",
          percentage: 0.09,
        },
        {
          count: 4,
          key: "\u090f\u0915",
          percentage: 0.07,
        },
        {
          count: 17,
          key: "application",
          percentage: 0.29,
        },
        {
          count: 5,
          key: "fashion",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "bro",
          percentage: 0.05,
        },
        {
          count: 12,
          key: "ke",
          percentage: 0.21,
        },
        {
          count: 3,
          key: "scholarship",
          percentage: 0.05,
        },
        {
          count: 17,
          key: "worst",
          percentage: 0.29,
        },
        {
          count: 3,
          key: "\u0928\u0939\u0940\u0902",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "suggestions",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "privacy",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83d\ude05",
          percentage: 0.05,
        },
        {
          count: 12,
          key: "option",
          percentage: 0.21,
        },
        {
          count: 3,
          key: "ultra",
          percentage: 0.05,
        },
        {
          count: 7,
          key: "veer",
          percentage: 0.12,
        },
        {
          count: 3,
          key: "angle",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "\ud83c\uddee\ud83c\uddf3",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "\u092e\u0947\u0902",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "\ud83d\ude1c",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "hide",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "log",
          percentage: 0.09,
        },
        {
          count: 5,
          key: "jaan",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "joshi",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "testing",
          percentage: 0.09,
        },
        {
          count: 3,
          key: "\u0932\u093f\u090f",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "\u0915\u0940",
          percentage: 0.09,
        },
        {
          count: 12,
          key: "test",
          percentage: 0.21,
        },
        {
          count: 4,
          key: "memes",
          percentage: 0.07,
        },
        {
          count: 4,
          key: "complain",
          percentage: 0.07,
        },
        {
          count: 3,
          key: "ra",
          percentage: 0.05,
        },
        {
          count: 6,
          key: "lyrics",
          percentage: 0.1,
        },
        {
          count: 3,
          key: "hoti",
          percentage: 0.05,
        },
        {
          count: 45,
          key: "comedy",
          percentage: 0.78,
        },
        {
          count: 3,
          key: "photography",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "sending",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "streak",
          percentage: 0.05,
        },
        {
          count: 3,
          key: "motivation",
          percentage: 0.05,
        },
        {
          count: 7,
          key: "games",
          percentage: 0.12,
        },
        {
          count: 3,
          key: "\u0939\u094b",
          percentage: 0.05,
        },
        {
          count: 10,
          key: "ka",
          percentage: 0.17,
        },
        {
          count: 3,
          key: "auto",
          percentage: 0.05,
        },
        {
          count: 5,
          key: "ab",
          percentage: 0.09,
        },
        {
          count: 5,
          key: "followers",
          percentage: 0.09,
        },
      ],
    },
  };

  let words = [];
  data &&
    data.ticket_report &&
    data.ticket_report.reports_on_word_cloud &&
    data.ticket_report.reports_on_word_cloud.length > 0 &&
    data.ticket_report.reports_on_word_cloud.map((item) => {
      words.push({
        text: item.key,
        value: item.count,
        percentage: item.percentage,
      });
    });
  const positive_callbacks = {
    getWordColor: (word) => {
      if (word.value > 0 && word.value < 20) {
        return "#48b91b";
      } else if (word.value > 20 && word.value < 60) {
        return "#53a545";
      } else {
        return "#2e6a0f";
      }
    },
  };

  const negative_callbacks = {
    getWordColor: (word) => {
      if (word.value > 0 && word.value < 20) {
        return "#f50707";
      } else if (word.value > 20 && word.value < 60) {
        return "#e05b4c";
      } else {
        return "#f08069";
      }
    },
  };

  const options = {
    colors: [
      `${theme.platform.color1}`,
      `${theme.platform.color2}`,
      `${theme.platform.color3}`,
      `${theme.platform.color4}`,
      `${theme.platform.color5}`,
      `${theme.platform.color6}`,
      `${theme.platform.color7}`,
      `${theme.platform.color8}`,
      `${theme.platform.color9}`,
      `${theme.platform.color10}`,
      `${theme.platform.color11}`,
      `${theme.platform.color12}`,
      `${theme.platform.color13}`,

      `${theme.platform.color17}`,
      `${theme.platform.color18}`,
      `${theme.platform.color19}`,
      `${theme.platform.color20}`,
    ],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "book Antiqua",
    fontSizes: [12, 70],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 2,
    rotations: 3,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };
  let rows = [];
  words &&
    words.map((item, index) => {
      rows.push({
        id: index + 1,
        keyword: item.text,
        count: item.value,
        percentage: prefixZero_for_singleDigit(item.percentage),
      });
    });
  let columns = [
    {
      field: "id",
      headerName: "Sl No",
      resizable: true,
      align: "left",
      headerAlign: "left",
      hide: true,
    },
    {
      field: "keyword",
      headerName: "Keywords",
      flex: 1,
      resizable: true,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "count",
      headerName: "Mention",
      flex: 1,
      resizable: true,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "percentage",
      headerName: "Percentage",
      flex: 1,
      resizable: true,
      align: "left",
      headerAlign: "left",
    },
  ];

  const totalRows = rows && rows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const updatedRows = rows.slice(startIndex, endIndex);

  const callbacks = {
    getWordTooltip: (word) =>
      `${word.text}  ${word.value}(${word.percentage}%)`,
  };

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {words.length > 0 ? (
          <Box
            className="scrollable"
            sx={{
              padding: "12px",
              overflowY: "auto",
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  width: "3px",
                  height: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#b0d2da",
                  borderRadius: "3px",
                  "&:hover": {
                    backgroundColor: "#85bac6",
                  },
                },
              },
            }}
          >
            <Grid container lg={12} md={12} sm={12} gap={1}>
              <Grid
                item
                lg={7.9}
                md={12}
                sm={12}
                sx={{
                  p: 1,
                  height: 400,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "solid 1px #ddd",
                  background: "#fff",
                }}
              >
                <ReactWordcloud
                  callbacks={callbacks}
                  words={words}
                  options={options}
                />
              </Grid>
              <Grid item lg={3.9} md={12} sm={12} sx={{ height: 400 }}>
                <DataGridFunction
                  rows={updatedRows}
                  columns={columns}
                  height={"100%"}
                  rowHeight={50}
                  rowBorder={false}
                  rowColor={true}
                  headerColor={true}
                  headerHeight={50}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                paddingTop: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <PaginationUI
                pagelength={totalPages}
                page={currentPage}
                handleChange={handleChange}
                variant={"outlined"}
              />
            </Box>
          </Box>
        ) : loading ? (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        ) : (
          <NoDataFound />
        )}
      </Box>
    </LoadingIndicator>
  );
};

export default WordCloudStatic;
