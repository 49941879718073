import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import PlatformModel from "./models/PlatformModel";

export default class PlatformEffect {
  //get platform request end point
  static async requestPlatform(params) {
    const endpoint = environment.api.platform;

    return EffectUtility.getToModel(PlatformModel, endpoint, params);
  }

  //get platform request end point
  static async filterRequestPlatform(params) {
    const endpoint = environment.api.platform;

    const response = await EffectUtility.getToModel(
      PlatformModel,
      endpoint,
      params
    );
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    localStorage.setItem("platform", JSON.stringify(response));

    return response;
  }

  // Post api
  static async requestPostPlatform(data) {
    const endpoint = environment.api.platform + "/add";
    return EffectUtility.postToModel(PlatformModel, endpoint, data);
  }

  //Put api
  static async requestPutPlatform(data, id) {
    const endpoint = environment.api.platform + "/" + id;

    return EffectUtility.putToModel(PlatformModel, endpoint, data);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
