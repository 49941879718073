import BaseReducer from "../../../../utilities/BaseReducer";
import TrackRevenueAction from "./TrackRevenueAction";

export default class TrackwiseRevenueSummaryReducer extends BaseReducer {
  initialState = {
    trackwiseRevenueSummary: [],
  };
  [TrackRevenueAction.REQUEST_TRACKWISE_REVENUE_SUMMARY_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      trackwiseRevenueSummary: action.payload,
    };
  }
}
