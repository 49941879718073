import BaseReducer from "../../../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Releases Reducer
export default class ChangeRequestReleasesReducer extends BaseReducer {
  initialState = {
    changeRequestRelease: [],
  };
  [ReleasesAction.REQUEST_CHANGE_REQUEST_RELEASES_FINISHED](state, action) {
    return {
      ...state,
      changeRequestRelease: action.payload,
    };
  }
}
