import ActionUtility from "../../../../utilities/ActionUtility";
import InfluencersCampaignsEffect from "./InfluencersCampaignsEffect";

export default class InfluencersCampaignsAction {
  // InfluencersCampaignsAction action with an function declaration
  static REQUEST_INFLUENCERS_CAMPAIGNS =
    "InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS";
  static REQUEST_INFLUENCERS_CAMPAIGNS_FINISHED =
    "InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_FINISHED";

  static REQUEST_INFLUENCERS_CAMPAIGNS_BY_ID =
    "InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_BY_ID";
  static REQUEST_INFLUENCERS_CAMPAIGNS_BY_ID_FINISHED =
    "InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_BY_ID_FINISHED";

  static REQUEST_INFLUENCERS_CAMPAIGNS_FILTER =
    "InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_FILTER";
  static REQUEST_INFLUENCERS_CAMPAIGNS_FILTER_FINISHED =
    "InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_FILTER_FINISHED";

  static REQUEST_PUT_INFLUENCERS_CAMPAIGNS =
    "InfluencersCampaignsAction.REQUEST_PUT_INFLUENCERS_CAMPAIGNS";
  static REQUEST_PUT_INFLUENCERS_CAMPAIGNS_FINISHED =
    "InfluencersCampaignsAction.REQUEST_PUT_INFLUENCERS_CAMPAIGNS_FINISHED";

  static REQUEST_POST_INFLUENCERS_CAMPAIGNS =
    "InfluencersCampaignsAction.REQUEST_POST_INFLUENCERS_CAMPAIGNS";
  static REQUEST_POST_INFLUENCERS_CAMPAIGNS_FINISHED =
    "InfluencersCampaignsAction.REQUEST_POST_INFLUENCERS_CAMPAIGNS_FINISHED";

  // METHODS
  // Organization GET function
  static requestInfluencerCampaigns(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS,
        InfluencersCampaignsEffect.requestInfluencerCampaigns,
        params
      );
    };
  }

  static requestInfluencerCampaignsById(id, campaigncallback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_BY_ID,
        InfluencersCampaignsEffect.requestInfluencerCampaignsById,
        id,
        campaigncallback
      );
    };
  }

  static requestInfluencersFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_FILTER,
        InfluencersCampaignsEffect.requestInfluencersFilter,
        params
      );
    };
  }
  static requestPutInfluencersCampaigns(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersCampaignsAction.REQUEST_PUT_INFLUENCERS_CAMPAIGNS,
        InfluencersCampaignsEffect.requestPutInfluencersCampaigns,
        data,
        id
      );
    };
  }

  // requestPostInfluencersCampaigns
  static requestPostInfluencersCampaigns(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencersCampaignsAction.REQUEST_POST_INFLUENCERS_CAMPAIGNS,
        InfluencersCampaignsEffect.requestPostInfluencersCampaigns,
        data
      );
    };
  }
}
