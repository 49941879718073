import ActionUtility from "../../utilities/ActionUtility";
import WordCloudEffect from "./WordCloudEffect";

export default class WordCloudAction {
  // WORDCLOUD action with an function declaration
  static REQUEST_WORDCLOUD = "WordCloudAction.REQUEST_WORDCLOUD";
  static REQUEST_WORDCLOUD_FINISHED =
    "WordCloudAction.REQUEST_WORDCLOUD_FINISHED";

  // WORDCLOUD action with an function declaration
  static REQUEST_WORDCLOUD_FILTER = "WordCloudAction.REQUEST_WORDCLOUD_FILTER";
  static REQUEST_WORDCLOUD_FILTER_FINISHED =
    "WordCloudAction.REQUEST_WORDCLOUD_FILTER_FINISHED";

  //   WORDCLOUD post
  static REQUEST_POST_WORDCLOUD = "WordCloudAction.REQUEST_POST_WORDCLOUD";
  static REQUEST_POST_WORDCLOUD_FINISHED =
    "WordCloudAction.REQUEST_POST_WORDCLOUD_FINISHED";

  // WORDCLOUD put
  static REQUEST_PUT_WORDCLOUD = "WordCloudAction.REQUEST_PUT_WORDCLOUD";
  static REQUEST_PUT_WORDCLOUD_FINISHED =
    "WordCloudAction.REQUEST_PUT_WORDCLOUD_FINISHED";

  // METHODS
  // WORDCLOUD GET function
  static requestWordCloud(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WordCloudAction.REQUEST_WORDCLOUD,
        WordCloudEffect.requestWordCloud,
        params
      );
    };
  }

  // WORDCLOUD filter
  // static requestWordCloudFilter(params) {
  //   return async (dispatch, getState) => {
  //     await ActionUtility.createThunkEffect(
  //       dispatch,
  //       WordCloudAction.REQUEST_WORDCLOUD_FILTER,
  //       WordCloudEffect.requestWordCloudFilter,
  //       params
  //     );
  //   };
  // }

  //   static requestProjectFinished() {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         ProjectAction.REQUEST_WORDCLOUD_FINISHED,
  //         ProjectEffect.requestProjectFinished
  //       );
  //     };
  //   }

  // WORDCLOUD post function
  static requestPostWordCloud(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WordCloudAction.REQUEST_POST_WORDCLOUD,
        WordCloudEffect.requestPostWordCloud,
        data
      );
    };
  }

  // WORDCLOUD put function
  static requestPutWordCloud({ positive_words, negative_words }, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WordCloudAction.REQUEST_PUT_WORDCLOUD,
        WordCloudEffect.requestPutWordCloud,
        { positive_words, negative_words },
        id
      );
    };
  }
}
