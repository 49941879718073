import ActionUtility from "../../../../utilities/ActionUtility";
import DsrActivityGroupEffect from "./DsrActivityGroupEffect";

export default class DsrActivityGroupAction {
  // DsrActivityGroupAction action with an function declaration
  static REQUEST_POST_DSR_ACTIVITY_GROUP =
    "DsrActivityGroupAction.REQUEST_POST_DSR_ACTIVITY_GROUP";
  static REQUEST_POST_DSR_ACTIVITY_GROUP_FINISHED =
    "DsrActivityGroupAction.REQUEST_POST_DSR_ACTIVITY_GROUP_FINISHED";

  static REQUEST_GET_DSR_ACTIVITY_GROUP =
    "DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP";
  static REQUEST_GET_DSR_ACTIVITY_GROUP_FINISHED =
    "DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP_FINISHED";

  static REQUEST_GET_DSR_ACTIVITY_GROUP_FILTER =
    "DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP_FILTER";
  static REQUEST_GET_DSR_ACTIVITY_GROUP_FILTER_FINISHED =
    "DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP_FILTER_FINISHED";

  static REQUEST_PUT_DSR_ACTIVITY_GROUP =
    "DsrActivityGroupAction.REQUEST_PUT_DSR_ACTIVITY_GROUP";
  static REQUEST_PUT_DSR_ACTIVITY_GROUP_FINISHED =
    "DsrActivityGroupAction.REQUEST_PUT_DSR_ACTIVITY_GROUP_FINISHED";

  // METHODS
  static requestGetDsrActivityGroup(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP,
        DsrActivityGroupEffect.requestGetDsrActivityGroup,
        params,
        callback
      );
    };
  }

  static requestGetDsrActivityGroupFilter(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityGroupAction.REQUEST_GET_DSR_ACTIVITY_GROUP_FILTER,
        DsrActivityGroupEffect.requestGetDsrActivityGroupFilter,
        params,
        callback
      );
    };
  }

  static requestPostDsrActivityGroup(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityGroupAction.REQUEST_POST_DSR_ACTIVITY_GROUP,
        DsrActivityGroupEffect.requestPostDsrActivityGroup,
        data,
        params,
        callback
      );
    };
  }

  static requestPutDsrActivityGroup(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DsrActivityGroupAction.REQUEST_PUT_DSR_ACTIVITY_GROUP,
        DsrActivityGroupEffect.requestPutDsrActivityGroup,
        data,
        id
      );
    };
  }
}
