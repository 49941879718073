import { BaseModel } from "sjs-base-model";

// TwitterDirect message data Model
export default class RetweetDataMode extends BaseModel {
  // response getting from the api

 

  constructor(data1) {
    super();

    this.update(data1);
  }
}
