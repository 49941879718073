import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import DiscoveredDataGetModel from "./models/DiscoveredDataGetModel";
import HssCategoryPostModal from "../hssCategory/models/HssCategoryPostModel";

export default class DiscoveredDataEffect {
  static async requestGetDiscoveredData(params, callback) {
    const endpoint = environment.api.websweepXDiscoveredData;
    let response = EffectUtility.getToModel(
      DiscoveredDataGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestPutDiscoveredData(data, id, callBackFunction) {
    const endpoint = environment.api.websweepXDiscoveredData + `/${id}`;
    let response = EffectUtility.putToModel(
      HssCategoryPostModal,
      endpoint,
      data
    );
    return response;
  }

  // post method for the Actress
  static async requestPostExportDiscoveredData(params, callBack) {
    const endpoint = environment.api.export_discovered_data;
    let response = EffectUtility.postToModel(
      HssCategoryPostModal,
      endpoint,
      {},
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestGetDiscoveredDataFilter(params, callback) {
    const endpoint = environment.api.websweepXDiscoveredData;
    let response = EffectUtility.getToModel(
      DiscoveredDataGetModel,
      endpoint,
      params
    );
    if (callback && response) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  static async requestClearDiscoveredData() {
    return {};
  }
}
