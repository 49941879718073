import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TaskModel from "./model/TaskModel";
import TaskPostModel from "./model/TaskPostModel";
import TaskPutModel from "./model/TaskPutModel";

// TaskEffect
export default class TaskEffect {
  // calling an API according to the Task model
  static async requesTask(params) {
    const endpoint = environment.api.task;
    return EffectUtility.getToModel(TaskModel, endpoint, params);
  }

  static async requesTaskClear() {
    return {};
  }

  static async requesTaskFilterClear() {
    return {};
  }

  // Get method for the Task filter
  static async requestTaskFilter(params) {
    const endpoint = environment.api.task;
    return EffectUtility.getToModel(TaskModel, endpoint, params);
  }

  // put method for the Task
  static async requestPutTask(data, id) {
    const endpoint = environment.api.task + "/" + id;
    return EffectUtility.putToModel(TaskPutModel, endpoint, data);
  }

  // post method for the Task
  static async requestPostTask(data) {
    const endpoint = environment.api.task + "/add";
    return EffectUtility.postToModel(TaskPostModel, endpoint, data);
  }

  static async requestDeleteTask(id) {
    const endpoint = environment.api.task + "/" + id;
    return EffectUtility.deleteToModel(TaskPostModel, endpoint);
  }
}
