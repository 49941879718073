import BaseReducer from "../../../utilities/BaseReducer";
import PlatformSchedulerAction from "./PlatformSchedulerAction";

// PlatformTaskSchedulingReduser
export default class PlatformTaskSchedulingReduser extends BaseReducer {
  initialState = {
    platform_task_scheduling: [],
  };
  [PlatformSchedulerAction.REQUEST_PLATFORM_TASKS_SCHEDULING_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      platform_task_scheduling: action.payload,
    };
  }
}
