import { BaseModel } from "sjs-base-model";
import PriorityHitsDataModel from "./PriorityHitsDataModel";

export default class PriorityHitsModel extends BaseModel {
  //priority list hits model
  hits = [PriorityHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
