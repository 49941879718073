import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import McnMonitoringModel from "./model/McnMonitoringModel";
import McnMonitoringPostModel from "./model/McnMonitoringPostModel";
import McnMonitoringPutModel from "./model/McnMonitoringPutModel";

// McnMonitoringEffect
export default class McnMonitoringEffect {
  // calling an API according to the Email Account model
  // Get method for the Mcn
  static async requestMcnMonitoring(params) {
    const endpoint = environment.api.mcnMonitoring;
    return EffectUtility.getToModel(McnMonitoringModel, endpoint, params);
  }

  // Get method for the Mcn filter
  static async requestMcnMonitoringFilter(params) {
    const endpoint = environment.api.mcnMonitoringFilter;
    return EffectUtility.getToModel(McnMonitoringModel, endpoint, params);
  }

  // put method for the Mcn
  static async requestPutMcnMonitoring(data, id) {
    const endpoint = environment.api.mcnMonitoring + "/" + id;
    return EffectUtility.putToModel(McnMonitoringPutModel, endpoint, data);
  }

  // post method for the Mcn
  static async requestPostMcnMonitoring(data) {
    const endpoint = environment.api.mcnMonitoring + "/add";

    return EffectUtility.postToModel(McnMonitoringPostModel, endpoint, data);
  }
}
