import BaseReducer from "../../../utilities/BaseReducer";
import InstagramAccountAction from "./InstagramAccountAction";

// InstagramAccountReduser  function
export default class InstagramAccountReduser extends BaseReducer {
  initialState = {
    instagramAccount: [],
  };
  [InstagramAccountAction.REQUEST_INSTAGRAM_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      instagramAccount: action.payload,
    };
  }
}
