import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import userImage from "../../Images/user.png";
import UserAction from "../../ormTicketing/stores/user/UserAction";
import jwtDecode from "jwt-decode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ResetProfilePassword from "../../components/resetProfilePassword/ResetProfilePassword";
import MuiButton from "../../components/muiButton/MuiButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import UpdateMyProfileImg from "../../components/updateMyProfileImg/UpdateMyProfileImg";

//map state to props
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [UserAction.REQUEST_USER_BY_ID]),
  userById: state.userById.userById || [],
});

function MyProfile(props) {
  // fetch function
  function fetch(id) {
    props.dispatch(UserAction.requestUserById(id));
  }

  const { isRequesting } = props;

  useEffect(() => {
    const auth = jwtDecode(localStorage.getItem("Auth"));

    fetch(auth.sub);
  }, []);

  const [openPassword, setOpenPassword] = useState(false);
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);

  const theme = useTheme();
  return (
    <Box>
      <Typography
        variant="h2"
        className="px-2 py-1"
        fontWeight={theme.typography.fontWeightBold}
      >
        My Profile
      </Typography>

      {props.userById.data ? (
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting && (
            <>
              <Divider variant="fullWidth" className="divider" />
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                // className="bg-warning"
              >
                <Card
                  sx={{
                    width: "auto",
                    marginTop: "2%",
                    border: "none",
                    borderRadius:
                      theme.palette.buttonComponent.borderRadius.medium,
                    padding: theme.palette.buttonComponent.borderRadius.small,
                    height: "auto",
                  }}
                  // className="bg-warning"
                >
                  <CardContent>
                    {/* <Grid
                      item
                      container
                      alignItems={"center"}
                      justifyContent="center"
                      className="bg-success"
                    ></Grid> */}
                    <Box p={2}>
                      <Grid
                        item
                        container
                        alignItems={"center"}
                        justifyContent="center"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          sx={{
                            height: 100,
                            width: 100,
                            objectFit: "cover",
                            backgroundColor: "white",
                            objectFit: "cover",
                            border: `0.1px solid ${theme.palette.primary.main}`,
                            color: theme.palette.primary.main,
                          }}
                          src={`${
                            props.userById.data &&
                            props.userById.data.attributes &&
                            props.userById.data.attributes.user_profile_url &&
                            props.userById.data.attributes.user_profile_url[0]
                          }`}
                        >
                          {props.userById.data ? (
                            <h1>
                              {props?.userById?.data?.firstName?.slice(0, 1)}
                            </h1>
                          ) : (
                            ""
                          )}
                        </Avatar>

                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          justifyContent="center"
                          alignItems={"center"}
                          mb={1}
                          mt={2}
                        >
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"left"}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                First Name &nbsp;:
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"right"}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                {props.userById.data &&
                                  props.userById.data.firstName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          justifyContent="center"
                          alignItems={"center"}
                          my={1}
                        >
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"left"}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                Last Name &nbsp;:
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"right"}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                {props.userById.data &&
                                  props.userById.data.lastName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          my={1}
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"left"}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                User Name &nbsp;:
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"right"}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                {props.userById.data &&
                                  props.userById.data.username}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          justifyContent="center"
                          alignItems={"center"}
                          my={1}
                        >
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"left"}
                              sx={{}}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                Email ID &nbsp;:
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"right"}
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme.typography.fontWeightBold,
                                  fontSize: "18px",
                                }}
                              >
                                {props.userById.data &&
                                  props.userById.data.email}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          justifyContent="center"
                          alignItems={"center"}
                          my={1}
                        >
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            justifyContent="space-between"
                            alignItems={"center"}
                          >
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              textAlign={"left"}
                              // className="bg-info"
                              sx={{}}
                            >
                              <MuiButton
                                // title="Reset Password"
                                onClick={(event) => {
                                  setOpenPassword(true);
                                }}
                                name="Reset Password"
                                // sx={{}}
                              ></MuiButton>
                            </Grid>

                            <Grid
                              item
                              xl={5.9}
                              lg={5.9}
                              md={5.9}
                              sm={5.9}
                              xs={5.9}
                              textAlign={"right"}

                              // className="bg-success"
                            >
                              <MuiButton
                                // title="Change Profile picture"
                                onClick={(event) => {
                                  setOpenUpdateProfile(true);
                                }}
                                name="Change Profile Picture"
                                // sx={{}}
                              ></MuiButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>

                <ResetProfilePassword
                  open={openPassword}
                  onClose={() => {
                    setOpenPassword(false);
                  }}
                />

                <UpdateMyProfileImg
                  open={openUpdateProfile}
                  onClose={() => {
                    setOpenUpdateProfile(false);
                  }}
                />
              </Grid>
            </>
          )}
        </LoadingIndicator>
      ) : (
        <NoDataFound />
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(MyProfile);
