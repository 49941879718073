import BaseReducer from "../../utilities/BaseReducer";
import OrmNotificationAction from "./ORMNotificationAction";

export default class OrmNotificationFilterReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    ormNotificationsFilter: [],
  };

  //operation Filter
  [OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ormNotificationsFilter: action.payload,
    };
  }
}
