import { BaseModel } from "sjs-base-model";
import UserRoleMappingHitsModel from "./UserRoleMappingHitsModel";
import UserRoleMappingShardsModel from "./UserRoleMappingShardsModel";

// UserRoleMappingModel
export default class UserRoleMappingModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: UserRoleMappingShardsModel };
  current_page_no = 0;
  hits = { hits: UserRoleMappingHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
