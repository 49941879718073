import BaseReducer from "../../../utilities/BaseReducer";
import PinterestFeedsAction from "./PinterestFeedsAction";

export default class PinterestFeedsReduser extends BaseReducer {
  initialState = {
    pinterestFeeds: [],
  };

  [PinterestFeedsAction.REQUEST_PINTEREST_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      pinterestFeeds: action.payload,
    };
  }

  [PinterestFeedsAction.REQUEST_PINTEREST_FEEDS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      pinterestFeeds: action.payload,
    };
  }
}
