import BaseReducer from "../../../../utilities/BaseReducer";
import McnFeedsAction from "./McnFeedsAction";

// EmailAccountReduser  function
export default class McnFeedsReduser extends BaseReducer {
  initialState = {
    mcnFeeds: [],
  };
  [McnFeedsAction.REQUEST_MCN_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      mcnFeeds: action.payload,
    };
  }
}
