import BaseReducer from "../../../../utilities/BaseReducer";
import WebsweepxReportsAction from "./WebsweepxReportsAction";

export default class WebsweepxCMSWSXTelegramReduser extends BaseReducer {
  initialState = {
    websweepxCMSvsWSXTelegramReport: [],
  };
  [WebsweepxReportsAction.REQUEST_GET_CMSvsWSXTELEGRAMREPORT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepxCMSvsWSXTelegramReport: action.payload,
    };
  }
}
