import BaseReducer from "../../../../utilities/BaseReducer";
import WebformsAction from "./WebformsAction";

export default class WebformsCsvDataReduser extends BaseReducer {
  initialState = {
    webforms_csv_data: [],
  };
  [WebformsAction.REQUEST_GET_WEBFORMS_CSV_DATA_FINISHED](state, action) {
    return {
      ...state,
      webforms_csv_data: action.payload,
    };
  }
}
