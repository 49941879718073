import ActionUtility from "../../utilities/ActionUtility";
import ReportsLogoEffect from "./ReportsLogoEffect";

export default class ReportsLogoAction {
  static REQUEST_REPORTS_LOGO = "ReportsLogoAction.REQUEST_REPORTS_LOGO";
  static REQUEST_REPORTS_LOGO_FINISHED =
    "ReportsLogoAction.REQUEST_REPORTS_LOGO_FINISHED";

  static REQUEST_REPORTS_LOGO_FILTER =
    "ReportsLogoAction.REQUEST_REPORTS_LOGO_FILTER";
  static REQUEST_REPORTS_LOGO_FILTER_FINISHED =
    "ReportsLogoAction.REQUEST_REPORTS_LOGO_FILTER_FINISHED";

  static REQUEST_REPORTS_LOGO_POST =
    "ReportsLogoAction.REQUEST_REPORTS_LOGO_POST";
  static REQUEST_REPORTS_LOGO_POST_FINISHED =
    "ReportsLogoAction.REQUEST_REPORTS_LOGO_POST_FINISHED";

  static REQUEST_REPORTS_LOGO_PUT =
    "ReportsLogoAction.REQUEST_REPORTS_LOGO_PUT";
  static REQUEST_REPORTS_LOGO_PUT_FINISHED =
    "ReportsLogoAction.REQUEST_REPORTS_LOGO_PUT_FINISHED";

  // METHODS

  static requestReportsLogo(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportsLogoAction.REQUEST_REPORTS_LOGO,
        ReportsLogoEffect.requestReportsLogo,
        params
      );
    };
  }

  static requestReportsLogoFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportsLogoAction.REQUEST_REPORTS_LOGO_FILTER,
        ReportsLogoEffect.requestReportsLogoFilter,
        params
      );
    };
  }

  static requestReportsLogoPost(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportsLogoAction.REQUEST_REPORTS_LOGO_POST,
        ReportsLogoEffect.requestReportsLogoPost,
        params
      );
    };
  }

  static requestReportsLogoPut(id, data) {
    return async (dispatch, getstate) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportsLogoAction.REQUEST_REPORTS_LOGO_PUT,
        ReportsLogoEffect.requestReportsLogoPut,
        id,
        data
      );
    };
  }
}
