import { BaseModel } from "sjs-base-model";
import AmbitionBoxAccountDataModel from "./AmbitionBoxAccountDataModel";

// AmbitionBoxAccountDataHitsModel
export default class AmbitionBoxAccountDataHitsModel extends BaseModel {
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [AmbitionBoxAccountDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
