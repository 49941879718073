import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import DirectorModel from "./model/DirectorModel";
import DirectorPostModel from "./model/DirectorPostModel";
import DirectorPutModel from "./model/DirectorPutModel";

// DirectorEffect
export default class DirectorEffect {
  // calling an API according to the Director model
  static async requestDirector(params) {
    const endpoint = environment.api.director;
    return EffectUtility.getToModel(DirectorModel, endpoint, params);
  }

  static async requestDirectorClear() {
    return {};
  }

  static async requestDirectorFilterClear() {
    return {};
  }

  // Get method for the Director filter
  static async requestDirectorFilter(params) {
    const endpoint = environment.api.director;
    return EffectUtility.getToModel(DirectorModel, endpoint, params);
  }

  // put method for the Director
  static async requestPutDirector(data, id) {
    const endpoint = environment.api.director + "/" + id;
    return EffectUtility.putToModel(DirectorPutModel, endpoint, data);
  }

  // post method for the Director
  static async requestPostDirector(data, params, callBack) {
    const endpoint = environment.api.director + "/add";
    let response = EffectUtility.postToModel(
      DirectorPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestDeleteDirector(id) {
    const endpoint = environment.api.director + "/" + id;
    return EffectUtility.deleteToModel(DirectorPostModel, endpoint);
  }
}
