import ActionUtility from "../../../../utilities/ActionUtility";
import PropertywiseUrlsEffect from "./PropertywiseUrlsEffect";

export default class PropertywiseUrlsAction {
  static REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_HISTORY =
    "PropertywiseUrlsAction.REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_HISTORY";
  static REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_HISTORY_FINISHED =
    "PropertywiseUrlsAction.REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_HISTORY_FINISHED";

  // METHODS

  static requestPropertyWiseContentUrlsHistory(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PropertywiseUrlsAction.REQUEST_GET_PROPERTY_WISE_CONTENT_URLS_HISTORY,
        PropertywiseUrlsEffect.requestPropertyWiseContentUrlsHistory,
        params,
        callback
      );
    };
  }
}
