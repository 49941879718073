import BaseReducer from "../../../utilities/BaseReducer";
import TicketHistoryAction from "./TicketHistoryAction";

export default class TicketHistoryMassReducer extends BaseReducer {
  //initial state of ticketType
  initialState = {
    ticketMassHistory: [],
  };

  //tickettype request action finish
  [TicketHistoryAction.REQUEST_MASS_TICKET_HISTORY_FINISHED](state, action) {
    return {
      ...state,
      ticketMassHistory: action.payload,
    };
  }

  //   [TicketHistoryAction.REQUEST_TICKET_HISTORY_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       ticketHistory: action.payload,
  //     };
  //   }
}
