import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ScrapeLinksModel from "./model/ScrapeLinksModel";

// ScrapeLinksEffect
export default class ScrapeLinksEffect {
  // calling an API according to the ScrapeLinksEffect model
  // Get method for the
  static async requestScrapeLinks(params) {
    const endpoint = environment.api.scrapeLinks;
    return EffectUtility.getToModel(ScrapeLinksModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestScrapeLinksFilter(params) {
    const endpoint = environment.api.scrapeLinksFilter;
    return EffectUtility.getToModel(ScrapeLinksModel, endpoint, params);
  }
}
