import BaseReducer from "../../../utilities/BaseReducer";
import ManageRoleAction from "./ManageRoleAction";

export default class ManageRoleReducer extends BaseReducer {
  //initialState od role filter
  initialState = {
    roleFilter: [],
  };

  //roleFilter
  [ManageRoleAction.REQUEST_ROLE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      roleFilter: action.payload,
    };
  }
}
