import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";

function BasicSunburst(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  var data1 = [
    {
      name: "Total Resolved",
      itemStyle: {
        color: "#42808E", // Color for Category 1
      },
      children: [
        {
          name: "Resolved After 24hrs",
          value: 19,
          itemStyle: {
            color: "#CCCCCC", // Color for Subcategory 1
          },
          children: [
            {
              name: "Instagram",
              value: 2,
              itemStyle: {
                color: "#E2E2E2", // Color for Subcategory 1
              },
            },
            {
              name: "YouTube",
              value: 5,
              itemStyle: {
                color: "#E2E2E2", // Color for Subcategory 1
              },
            },
            {
              name: "LinkedIn",
              value: 1,
              itemStyle: {
                color: "#E2E2E2", // Color for Subcategory 1
              },
            },
            {
              name: "Facebook",
              value: 7,
              itemStyle: {
                color: "#E2E2E2", // Color for Subcategory 1
              },
            },
            {
              name: "Twitter",
              itemStyle: {
                color: "#E2E2E2", // Color for Subcategory 1
              },
              value: 4,
            },
          ],
        },
        {
          name: "Resolved in 12-24hrs",
          value: 15,
          itemStyle: {
            color: "#92C4D0", // Color for Subcategory 1
          },
          children: [
            {
              name: "Instagram",
              value: 2,
              itemStyle: {
                color: "#B4D5DD", // Color for Subcategory 1
              },
            },
            {
              name: "YouTube",
              value: 5,
              itemStyle: {
                color: "#B4D5DD", // Color for Subcategory 1
              },
            },
            {
              name: "LinkedIn",
              value: 1,
              itemStyle: {
                color: "#B4D5DD", // Color for Subcategory 1
              },
            },
          ],
        },
        {
          name: "Resolved in 8-12hrs",
          value: 10,
          itemStyle: {
            color: "#9DBFC4", // Color for Subcategory 1
          },
          children: [
            {
              name: "Youtube",
              value: 5,
              itemStyle: {
                color: "#9DBFC4", // Color for Subcategory 1
              },
            },
            {
              name: "LinkedIn",
              value: 1,
              itemStyle: {
                color: "#9DBFC4", // Color for Subcategory 1
              },
            },
          ],
        },
        {
          name: "Resolved in 8 hrs",
          value: 10,
          itemStyle: {
            color: "#A4D9D1", // Color for Subcategory 1
          },
          children: [
            {
              name: "Twitter",
              value: 5,
              itemStyle: {
                color: "#A4D9D1", // Color for Subcategory 1
              },
            },
            {
              name: "Instagram",
              value: 1,
              itemStyle: {
                color: "#A4D9D1", // Color for Subcategory 1
              },
            },
          ],
        },
      ],
    },
  ];
  const getOption = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${params.name}: ${params.value}`;
      },
    },
    series: {
      type: "sunburst",
      data: data1,
      radius: [0, "90%"],
      label: {
        rotate: "radial",
        overflow: "truncate",
      },
      levels: [
        {},
        {},
        {
          label: {
            show: false,
            hideOverlap: true,
          },
          emphasis: {
            label: {
              show: false, // Ensure label is not shown on hover
            },
          },
        },
      ],

      minAngle: 10, // Minimum angle for displaying labels
    },
  };
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default BasicSunburst;
