import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { theme } from "../../../../../views/App";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function SortByPopper(props) {
  const [open, setOpen] = React.useState(false);
  const sortByPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      sortByPopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      sortByPopperRef.current &&
      sortByPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={sortByPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={sortByPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 200,
                height: "auto",
                boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  style={{
                    height: "max-content",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                  }}
                  className="scrollable"
                >
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box
                      width="100%"
                      sx={{ textAlign: "left", padding: "10px" }}
                    >
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        sx={{
                          width: "100%",
                          fontSize: 18,
                          fontWeight: 500,
                          borderBottom: `1px solid ${theme.palette.ticketListView.sortPopperBorder}`,
                          color: theme.palette.primary.main,
                          padding: 1,
                        }}
                      >
                        Sort By
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={props.sort_by}
                        name="radio-buttons-group"
                        onChange={(e, value) => {
                          props.setSort_By(value);
                        }}
                        sx={{
                          "& .Mui-checked": {
                            color: theme.palette.primary.light,
                          },
                        }}
                      >
                        <FormControlLabel
                          value="created_on"
                          control={
                            <Radio
                              sx={{
                                color: theme.palette.primary.light,
                              }}
                            />
                          }
                          label="Created Date"
                          sx={{
                            cursor: "pointer",
                            borderBottom: `1px solid ${theme.palette.ticketListView.sortPopperBorder}`,
                            padding: 0,
                            margin: 0,
                            "&:hover": {
                              backgroundColor: theme.palette.background.default,
                            },
                          }}
                        />
                        <FormControlLabel
                          value="modified_on"
                          control={
                            <Radio
                              sx={{
                                color: theme.palette.primary.light,
                              }}
                            />
                          }
                          label="Last Updated"
                          sx={{
                            cursor: "pointer",
                            borderBottom: `1px solid ${theme.palette.ticketListView.sortPopperBorder}`,
                            "&:hover": {
                              backgroundColor: theme.palette.background.default,
                            },
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      </RadioGroup>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        sx={{
                          fontSize: 18,
                          width: "100%",
                          fontWeight: 500,
                          borderBottom: `1px solid ${theme.palette.ticketListView.sortPopperBorder}`,
                          color: theme.palette.primary.main,
                          padding: 1,
                        }}
                      >
                        {" "}
                        Sort Order
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={props.sort_order}
                        onChange={(e, value) => {
                          props.set_sort_order(value);
                        }}
                        sx={{
                          "& .Mui-checked": {
                            color: theme.palette.primary.light,
                          },
                        }}
                      >
                        <FormControlLabel
                          value="asc"
                          control={
                            <Radio
                              sx={{
                                color: theme.palette.primary.light,
                              }}
                            />
                          }
                          label="Ascending"
                          sx={{
                            cursor: "pointer",
                            borderBottom: `1px solid ${theme.palette.ticketListView.sortPopperBorder}`,
                            "&:hover": {
                              backgroundColor: theme.palette.background.default,
                            },
                            padding: 0,
                            margin: 0,
                          }}
                        />
                        <FormControlLabel
                          value="desc"
                          control={
                            <Radio
                              sx={{
                                color: theme.palette.primary.light,
                              }}
                            />
                          }
                          label="Descending"
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: theme.palette.background.default,
                            },
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(SortByPopper);
