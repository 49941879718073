import ActionUtility from "../../../../utilities/ActionUtility";
import WhiteListEffect from "./WhiteListEffect";

export default class WhiteListAction {
  static REQUEST_POST_WHITELIST = "WhiteListAction.REQUEST_POST_WHITELIST";
  static REQUEST_POST_WHITELIST_FINISHED =
    "WhiteListAction.REQUEST_POST_WHITELIST_FINISHED";

  static REQUEST_GET_WHITELIST = "WhiteListAction.REQUEST_GET_WHITELIST";
  static REQUEST_GET_WHITELIST_FINISHED =
    "WhiteListAction.REQUEST_GET_WHITELIST_FINISHED";

  // METHODS
  static requestGetWhitelist(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhiteListAction.REQUEST_GET_WHITELIST,
        WhiteListEffect.requestGetWhitelist,
        params,
        callback
      );
    };
  }

  static requestPostWhitelist(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WhiteListAction.REQUEST_POST_COLLECTION,
        WhiteListEffect.requestPostWhitelist,
        params,
        callback
      );
    };
  }
}
