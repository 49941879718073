import ActionUtility from "../../../../utilities/ActionUtility";
import InrRatesEffect from "./InrRatesEffect";

export default class InrRatesAction {
  // InrRatesAction action with an function declaration
  static REQUEST_INR_RATES = "InrRatesAction.REQUEST_INR_RATES";
  static REQUEST_INR_RATES_FINISHED =
    "InrRatesAction.REQUEST_INR_RATES_FINISHED";

  static REQUEST_INR_RATES_FILTER = "InrRatesAction.REQUEST_INR_RATES_FILTER";
  static REQUEST_INR_RATES_FILTER_FINISHED =
    "InrRatesAction.REQUEST_INR_RATES_FILTER_FINISHED";

  static REQUEST_POST_INR_RATES = "InrRatesAction.REQUEST_POST_INR_RATES";
  static REQUEST_POST_INR_RATES_FINISHED =
    "InrRatesAction.REQUEST_POST_INR_RATES_FINISHED";

  // METHODS
  // inrrates GET function
  static requestInrRates(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InrRatesAction.REQUEST_INR_RATES,
        InrRatesEffect.requestInrRates,
        params
      );
    };
  }

  static requestInrRatesFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InrRatesAction.REQUEST_INR_RATES_FILTER,
        InrRatesEffect.requestInrRatesFilter,
        params
      );
    };
  }

  // Organizationfunction
  static requestPostInrRates(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InrRatesAction.REQUEST_POST_INR_RATES,
        InrRatesEffect.requestPostInrRates,
        data
      );
    };
  }
}
