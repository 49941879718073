import environment from "environment";
import ReleaseStatusCountModel from "./model/ReleaseStatusCountModel";
import ReleaseStatusCountPutModel from "./model/ReleaseStatusCountPutModel";
import ReleaseStatusCountPostModel from "./model/ReleaseStatusCountPostModel";
import EffectUtility from "../../../../utilities/EffectUtility";

// ReleaseStatusCountEffect
export default class ReleaseStatusCountEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestReleaseStatusCount(params, callBack) {
    const endpoint = environment.api.releaseStatusCount;
    let response = EffectUtility.getToModel(
      ReleaseStatusCountModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // // Get method for the track filter
  // static async requestReleaseStatusCountFilter(params) {
  //   const endpoint = environment.api.track;
  //   return EffectUtility.getToModel(ReleaseStatusCountModel, endpoint, params);
  // }

  // // put method for the track
  // static async requestPutReleaseStatusCount(data, id) {
  //   const endpoint = environment.api.track + "/" + id;
  //   return EffectUtility.putToModel(ReleaseStatusCountPutModel, endpoint, data);
  // }

  // // post method for the track
  // static async requestPostReleaseStatusCount(data) {
  //   const endpoint = environment.api.track + "/add";
  //   return EffectUtility.postToModel(
  //     ReleaseStatusCountPostModel,
  //     endpoint,
  //     data
  //   );
  // }

  // static async requestDeleteReleaseStatusCount(id) {
  //   const endpoint = environment.api.track + "/" + id;
  //   return EffectUtility.deleteToModel(ReleaseStatusCountPostModel, endpoint);
  // }
}
