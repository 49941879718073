import BaseReducer from "../../../../utilities/BaseReducer";
import SubGenreAction from "./SubGenreAction";

// StatusReduser
export default class SubGenreReducer extends BaseReducer {
  initialState = {
    subgenre: [],
  };
  [SubGenreAction.REQUEST_SUBGENRE_FINISHED](state, action) {
    return {
      ...state,
      subgenre: action.payload,
    };
  }

  [SubGenreAction.REQUEST_SUBGENRE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      subgenre: action.payload,
    };
  }
}
