import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import DiscordFeedsModel from "./model/DiscordFeedsModel";
import DiscordPostModel from "./model/DiscordPostModel";
import DiscordPutModel from "./model/DiscordPutModel";

export default class DiscordFeedsEffect {
  //discord feeds request
  static async requestDiscordFeeds(params) {
    const endpoint = environment.api.discord;
    return EffectUtility.getToModel(DiscordFeedsModel, endpoint, params);
  }

  static async requestDiscordFeedsClear() {
    return {};
  }

  //discord feeds Filter
  static async requestDiscordFilter(params) {
    const endpoint = environment.api.discord;
    return EffectUtility.getToModel(DiscordFeedsModel, endpoint, params);
  }

  // put method for the discord Feeds
  static async requestPutDiscordFeeds(data, id) {
    const endpoint = environment.api.discord + "/" + id;
    return EffectUtility.putToModel(DiscordPutModel, endpoint, data);
  }

  // Post API
  static async requestDiscordPost(queryParams, data) {
    const endpoint = environment.api.discord + "?" + queryParams;

    return EffectUtility.postToModel(DiscordPostModel, endpoint, data);
  }
}
