import ActionUtility from "../../../../utilities/ActionUtility";
import SitesExecutionLogsEffect from "./SitesExecutionLogsEffect";

export default class SitesExecutionLogsAction {
  static REQUEST_GET_SITES_EXECUTION_LOGS =
    "SitesExecutionLogsAction.REQUEST_GET_SITES_EXECUTION_LOGS";
  static REQUEST_GET_SITES_EXECUTION_LOGS_FINISHED =
    "SitesExecutionLogsAction.REQUEST_GET_SITES_EXECUTION_LOGS_FINISHED";

  // METHODS
  static requestSitesExecutionLogs(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SitesExecutionLogsAction.REQUEST_GET_SITES_EXECUTION_LOGS,
        SitesExecutionLogsEffect.requestSitesExecutionLogs,
        params,
        callback
      );
    };
  }
}
