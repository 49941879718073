import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import EffectUtility from "../../../utilities/EffectUtility";
import HttpUtility from "../../../utilities/HttpUtility";
import IndeedPutModel from "../indeed/models/IndeedPutModel";
import TelegramDataModel from "./models/TelegramDataModel";
import TelegramPostModel from "./models/TelegramPostModel";

export default class TelegramEffect {
  //telegram feeds get requests
  static async requestTelegramFeeds(params) {
    const endpoint = environment.api.telegramFeed;

    return EffectUtility.getToModel(TelegramDataModel, endpoint, params);
  }

  static async requestTelegramFeedsClear() {
    return {};
  }

  //telegram feeds get request for filter dropdown
  static async requestTelegramFeedsFilter(params) {
    const endpoint = environment.api.telegramFeed;

    return EffectUtility.getToModel(TelegramDataModel, endpoint, params);
  }

  static async requestTelegramPost(data, id, callback) {
    const endpoint = environment.api.telegramSendMessage + "/" + id;

    let response = EffectUtility.postToModel(TelegramPostModel, endpoint, data);

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback();
        resp.status === "fail" && alert(resp.message);
      }
    });

    return response;
  }

  // put method for the WhatsApp feed  filter

  static async requestTelegramPut(data, id) {
    const endpoint = environment.api.telegramFeed + "/" + id;
    return EffectUtility.putToModel(IndeedPutModel, endpoint, data);
  }

  //error
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
