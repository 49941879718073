import ActionUtility from "../../../../utilities/ActionUtility";
import BatchEffect from "./BatchEffect";

export default class BatchAction {
  static REQUEST_BATCH = "BatchAction.REQUEST_BATCH";
  static REQUEST_BATCH_FINISHED = "BatchAction.REQUEST_BATCH_FINISHED";

  static REQUEST_BATCH_FILTER = "BatchAction.REQUEST_BATCH_FILTER";
  static REQUEST_BATCH_FILTER_FINISHED =
    "BatchAction.REQUEST_BATCH_FILTER_FINISHED";

  // METHODS
  static requestBatch(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchAction.REQUEST_BATCH,
        BatchEffect.requestBatch,
        params
      );
    };
  }

  static requestBatchFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BatchAction.REQUEST_BATCH_FILTER,
        BatchEffect.requestBatchFilter,
        params
      );
    };
  }
}
