import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

export default class RoleSModel extends BaseModel {
  _shards = {};
  current_page_no = 0;
  hits = {};
  timed_out = boolean;
  took = 0;
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
