import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import GMBQuestions from "./GMBQuestions";
import GMBReviews from "./GMBReviews";

function GMBFeedTabs(props) {
  const [tabTwoValue, setTabTwoValue] = React.useState("1");

  const tabTwoHandleChange = (event, newValue) => {
    setTabTwoValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabTwoValue}>
        <Box sx={{ borderBottom: 1, borderColor: "#d4edf5" }}>
          <TabList
            onChange={tabTwoHandleChange}
            aria-label="lab API tabs example"
          >
            <Tab sx={{ border: "none" }} label="Reviews" value="1" />
            <Tab sx={{ border: "none" }} label="Question & Answers" value="2" />
          </TabList>
        </Box>

        <TabPanel
          value="1"
          sx={{
            backgroundColor: "white",
            padding: "5px",
            height: "auto",
            width: "100%",
          }}
        >
          <Box>
            <GMBReviews setValue={props.setValue} />
          </Box>
        </TabPanel>
        <TabPanel
          value="2"
          sx={{
            padding: "5px",
            backgroundColor: "white",
            height: "auto",
            width: "100%",
          }}
        >
          <Box>
            <GMBQuestions setValue={props.setValue} />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default GMBFeedTabs;
