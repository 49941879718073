import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../views/App";
import { useEffect, useRef, useState, useCallback } from "react";
import { connect } from "react-redux";
import ReportContainer from "./ReportsContainer";
import AnimationDelayChart from "../ormTicketing/views/Demo_static/AnimationDelayChart";
import BasicAreaChart from "../ormTicketing/views/Demo_static/BasicAreaChart";
import BasicSunburst from "../ormTicketing/views/Demo_static/BasicSunburst";
import BasicTreemap from "../ormTicketing/views/Demo_static/BasicTreemap";
import BridgeSummary from "../ormTicketing/views/Demo_static/BridgeSummary";
import BubbleChart from "../ormTicketing/views/Demo_static/BubbleChart";
import ClickableColumnChart from "../ormTicketing/views/Demo_static/ClickableColumnChart";
import DynamicData from "../ormTicketing/views/Demo_static/DynamicData";
import LargescaleBarchart from "../ormTicketing/views/Demo_static/LargescaleBarchart";
import NegativeMentions from "../ormTicketing/views/Demo_static/NegativeMentions";
import Nightingale from "../ormTicketing/views/Demo_static/Nightingale";
import PlatformWiseTicketTrend from "../ormTicketing/views/Demo_static/PlatformWiseTicketTrend";
import StackedAreaChart from "../ormTicketing/views/Demo_static/StackedAreaChart";
import StackedHorizontalBar from "../ormTicketing/views/Demo_static/StackedHorizontalBar";
import StackedLineChart from "../ormTicketing/views/Demo_static/StackedLineChart";
import StaticDialyMentionsChart from "../ormTicketing/views/Demo_static/StaticDialyMentionsChart";
import StaticDialySentimentTrend from "../ormTicketing/views/Demo_static/StaticDialySentimentTrend";
import StaticPlatformSentimentChart from "../ormTicketing/views/Demo_static/StaticPlatformSentimentChart";
import StaticSubcategoryTrend from "../ormTicketing/views/Demo_static/StaticSubcategoryTrend";
import StaticTicketCategoryChart from "../ormTicketing/views/Demo_static/StaticTicketCategoryChart";
import StaticTicketSummaryChart from "../ormTicketing/views/Demo_static/StaticTicketSummaryChart";
import WordCloudStatic from "../ormTicketing/views/Demo_static/WordCloudStatic";
import StaticReportContainer from "./StaticReportContainer";
import StaticPlatformWiseSentiment from "../ormTicketing/views/Demo_static/PlatformWiseTicketTrend";

let mapStateToProps = (state) => ({});

const ReturnDemoStaticReports = ({
  report,
  platform_list,
  descriptionCallback,
  activity_list,
  statusList,
  userList,
  setDetailReportId,
  setDetailReportOpen,
  myTicketsTime,
  DateFilter,
}) => {
  let report_id = report
    ? report
    : report.report_name
    ? JSON.parse(report.report_name).report_id
    : null;

  const summaryBoxRef = useRef(null);
  let dataGridHeight = useRef(null);
  const ChartContainerHeight = useRef(null);
  const ChartContainerWidth = useRef(null);

  useEffect(() => {
    if (summaryBoxRef?.current) {
      const calculateHeights = () => {
        const outerBoxHeight = summaryBoxRef.current.clientHeight;
        const outerBoxWidth = summaryBoxRef.current.clientWidth;
        if (
          summaryBoxRef.current &&
          summaryBoxRef.current.children[1] &&
          summaryBoxRef.current.children[0]
        ) {
          summaryBoxRef.current.children[0].style.height = 50;
          dataGridHeight.current = outerBoxHeight - 75;
          ChartContainerHeight.current = summaryBoxRef.current.clientHeight;
          ChartContainerWidth.current = summaryBoxRef.current.clientWidth;
        }
      };

      calculateHeights();

      // Use ResizeObserver to listen for changes in the size of summaryBoxRef
      const resizeObserver = new ResizeObserver(calculateHeights);
      resizeObserver.observe(summaryBoxRef.current);

      // Cleanup ResizeObserver on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <StaticReportContainer
        summaryBoxRef={summaryBoxRef}
        theme={theme}
        report={report}
        setDetailReportId={setDetailReportId}
        setDetailReportOpen={setDetailReportOpen}
      >
        {report_id === "rBPdGowBpodn5_f5jeD00" && <AnimationDelayChart />}
        {report_id === "rBPdGowBpodn5_f5jeD01" && <BasicAreaChart />}
        {report_id === "rBPdGowBpodn5_f5jeD02" && <BasicSunburst />}
        {report_id === "rBPdGowBpodn5_f5jeD03" && (
          <BridgeSummary dataGridHeight={dataGridHeight} />
        )}
        {/* {report_id === "rBPdGowBpodn5_f5jeD04" && <BridgeSummary />} */}
        {report_id === "rBPdGowBpodn5_f5jeD05" && <BubbleChart />}
        {report_id === "rBPdGowBpodn5_f5jeD06" && <ClickableColumnChart />}
        {report_id === "rBPdGowBpodn5_f5jeD07" && <DynamicData />}
        {report_id === "rBPdGowBpodn5_f5jeD08" && <LargescaleBarchart />}
        {report_id === "rBPdGowBpodn5_f5jeD09" && <NegativeMentions />}
        {report_id === "rBPdGowBpodn5_f5jeD10" && <Nightingale />}
        {/* {report_id === "rBPdGowBpodn5_f5jeD11" && <PlatformWiseTicketTrend />} */}
        {report_id === "rBPdGowBpodn5_f5jeD12" && (
          <StaticPlatformWiseSentiment
            ChartContainerHeight={ChartContainerHeight}
            ChartContainerWidth={ChartContainerWidth}
          />
        )}
        {report_id === "rBPdGowBpodn5_f5jeD13" && <StackedHorizontalBar />}
        {report_id === "rBPdGowBpodn5_f5jeD14" && <StaticTicketCategoryChart />}
        {report_id === "rBPdGowBpodn5_f5jeD15" && <StaticDialyMentionsChart />}
        {report_id === "rBPdGowBpodn5_f5jeD16" && <StaticDialySentimentTrend />}
        {report_id === "rBPdGowBpodn5_f5jeD17" && (
          <StaticPlatformSentimentChart />
        )}
        {report_id === "rBPdGowBpodn5_f5jeD18" && <StaticSubcategoryTrend />}
        {report_id === "rBPdGowBpodn5_f5jeD19" && <StaticTicketCategoryChart />}
        {report_id === "rBPdGowBpodn5_f5jeD20" && <StaticTicketSummaryChart />}
        {report_id === "rBPdGowBpodn5_f5jeD21" && <WordCloudStatic />}
      </StaticReportContainer>
    </div>
  );
};

export default connect(mapStateToProps)(ReturnDemoStaticReports);
