import BaseReducer from "../../../../utilities/BaseReducer";
import FingerPrintSearchAction from "./FingerPrintSearchAction";

export default class FingerPrintAcrSearchReducer extends BaseReducer {
  initialState = {
    FPacrSearch: [],
  };
  [FingerPrintSearchAction.REQUEST_POST_FP_ACR_SEARCH_FINISHED](state, action) {
    return {
      ...state,
      FPacrSearch: action.payload,
    };
  }
}
