import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import MuiButton from "../../../components/muiButton/MuiButton";
import { theme } from "../../../views/App";
import "./Reports.css";
import { getItemsById, ReturnReport } from "../../../components/ticketPartials";

function GenerateReports(props) {
  // REPORT GENERATION variables and functions
  const [selectedReports, setSelectedReports] = useState([]);
  const [reportChoosen, setReportChoosen] = useState([]);

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let reports = [];

    props.pages.length > 0 &&
      props.pages.map((page) => {
        page.reports.length > 0 &&
          page.reports.map((report) =>
            reports.push({
              report: report,
              left_top_logo: page.left_top_logo,
              right_top_logo: page.right_top_logo,
            })
          );

        return setSelectedReports([...selectedReports, ...reports]);
      });
  }, []);

  const isAllSelected =
    selectedReports &&
    selectedReports.length > 0 &&
    selectedReports.length === reportChoosen.length;

  // Tickets fields checkboxes rendering
  const reportsCheckbox = selectedReports
    ? selectedReports.map((option) => {
        return (
          <Grid item xl={12} container lg={12} md={12} sm={6} xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  value={
                    option.report.report_name
                      ? JSON.parse(option.report.report_name).report_id
                      : getItemsById(option.report.report_id)[0]
                  }
                  // label={option.report.report_name}
                  onChange={(e) => {
                    ReportTemplatehandleChange(e, option);
                  }}
                  checked={reportChoosen
                    .map((item) => {
                      return item.report.report_name
                        ? JSON.parse(item.report.report_name).report_id
                        : item.report.report_id;
                    })
                    .includes(option.report.report_id)}
                />
              }
              label={
                option.report.report_id
                  ? getItemsById(option.report.report_id)[0]?.report_name
                  : option.report.report_name
                  ? JSON.parse(option.report.report_name).report_name
                  : ""
              }
            />{" "}
          </Grid>
        );
      })
    : null;

  function ReportTemplatehandleChange(event, option) {
    const value = option;

    if (event.target.value === "all") {
      // setSelectedReports(
      //   selectedReports.length === props.selected.length ? [] : props.selected
      // );
      setReportChoosen(
        reportChoosen.length === selectedReports.length
          ? []
          : selectedReports.map((report) => report)
      );
      return;
    }

    if (reportChoosen.indexOf(value) !== -1) {
      // if value already present
      const newSelected = reportChoosen.filter((s) => s !== value);
      setReportChoosen(newSelected);
    } else {
      // if value not present
      setReportChoosen([...reportChoosen, value]);
    }
  }

  const returnLogoUrl = (id) => {
    let filtered_logo_array = props.logoList.filter((val) => {
      return val.logo_id === id;
    });

    return filtered_logo_array && filtered_logo_array.length !== 0
      ? filtered_logo_array[0].logo_url
      : "";
  };

  return (
    <Grid
      container
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{ display: "flex", justifyContent: "space-evenly" }}
    >
      <Grid container item lg={4} md={4} sm={12} xs={12}>
        <Accordion
          expanded={expanded}
          onChange={handleChange}
          style={{
            backgroundColor: expanded
              ? "white"
              : theme.palette.background.default,
            height: "auto",
            width: "100%",
            padding: 2,
            borderRadius: theme.borderRadius,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            marginTop: 3,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: 50 }}>Select Reports to Generate</span>
            <Grid sx={{ justifySelf: "flex-end" }}>
              <MuiButton
                name={"Save as PDF"}
                onClick={handlePrint}
                disabled={selectedReports.length > 0 ? false : true}
              />
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            className="scrollable"
            sx={{
              width: "100%",
              height: 550,
              overflow: "auto",
              overscrollBehaviorY: "scroll",
              backgroundColor: "transparent",
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {selectedReports.length > 0 && (
                <Typography>
                  <Box width="100%" mt={3}>
                    <Grid
                      container
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xl={12}
                        container
                        lg={12}
                        md={12}
                        sm={6}
                        xs={6}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={ReportTemplatehandleChange}
                              checked={isAllSelected}
                              enableReinitialize={true}
                              // defaultChecked
                            />
                          }
                          label={
                            <p className="font-weight-bold">{"Select All"}</p>
                          }
                        />{" "}
                      </Grid>

                      {reportsCheckbox}
                    </Grid>
                  </Box>
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid
        lg={7.5}
        md={7.5}
        sm={11.5}
        ml={{ lg: 0.5, md: 0.5, sm: 0.5, xs: 0 }}
        mt={{ xs: 1 }}
        sx={{
          width: "100%",
          height: 600,
          overflow: "auto",
          overscrollBehaviorY: "scroll",
          backgroundColor: "white",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
          border: "solid 0.5px #d3e2e6",
        }}
        // className="bg-warning"
      >
        {reportChoosen.length > 0 && (
          <Box
            ref={componentRef}
            // className="bg-warning"
          >
            {reportChoosen.map((report) => {
              return ReturnReport(
                report.report,
                props.platform_list,
                props.descriptionCallback,
                props.activity_list,
                props.statusList
              );
            })}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default GenerateReports;
