import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TelegramIntermediateGetModal from "./modals/TelegramIntermediateGetModal";
import TelegramIntermediatePostModal from "./modals/TelegramIntermediatePostModal";
import TelegramSearchModel from "./modals/TelegramSearchModel";
import TelegramSubscrbeMoodel from "./modals/TelegramSubscrbeMoodel";

export default class TelegramIntermediateEffect {
  static async requestGetTelegramIntermediate(params, callback) {
    const endpoint = environment.api.websweepxTelegramIntermediate;
    let response = EffectUtility.getToModel(
      TelegramIntermediateGetModal,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp && callback) {
        callback(resp);
      }
    });

    return response;
  }

  static async requestGetTelegramIntermediateExport(params, callback) {
    const endpoint = environment.api.websweepxTelegramIntermediate;
    let response = EffectUtility.getToModel(
      TelegramIntermediateGetModal,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp && callback) {
        callback(resp);
      }
    });

    return response;
  }

  static async requestTelegramSearch(params, callBackFunction) {
    const endpoint = environment.api.telegramSeach;
    let response = EffectUtility.getToModel(
      TelegramSearchModel,
      endpoint,
      params,
      callBackFunction
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestTelegramSubscribe(params, callBackFunction) {
    const endpoint = environment.api.telegramSubscribe;
    let response = EffectUtility.getToModel(
      TelegramSubscrbeMoodel,
      endpoint,
      params,
      callBackFunction
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestPostTelegramIntermediate(data, callBackFunction) {
    const endpoint = environment.api.websweepxTelegramIntermediate;
    let response = EffectUtility.postToModel(
      TelegramIntermediatePostModal,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPostExtractMessages(data, callBackFunction) {
    const endpoint = environment.api.websweepxExtractMessages;
    let response = EffectUtility.postToModel(
      TelegramIntermediatePostModal,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPostToDiscoveredData(data, callBackFunction) {
    const endpoint = environment.api.websweepxTelegramPostToDiscoveredData;
    let response = EffectUtility.postToModel(
      TelegramIntermediatePostModal,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPutTelegramIntermediate(data, id, callBackFunction) {
    const endpoint = environment.api.websweepxTelegramIntermediate + `/${id}`;
    let response = EffectUtility.putToModel(
      TelegramIntermediatePostModal,
      endpoint,
      data
    );
    return response;
  }

  static async requestPutTelegramIntermediateBulkUpdate(
    data,
    params,
    callBackFunction
  ) {
    const endpoint = environment.api.websweepxTelegramIntermediateBulkUpdate;
    let response = EffectUtility.putToModel(
      TelegramIntermediatePostModal,
      endpoint,
      data,
      params
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }
}
