import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import PlayStoreModel from "./models/PlayStoreModel";
import PlaystorePutModel from "./models/PlaystorePutModel";

export default class PlayStoreEffect {
  // calling an API according to the ProjectModel
  static async requestPlayStore(params, callBackFunction) {
    const endpoint = environment.api.playstore;

    let response = EffectUtility.getToModel(PlayStoreModel, endpoint, params);
    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestPlayStoreClear() {
    return {};
  }

  static async requestPlayStoreFilter(params) {
    const endpoint = environment.api.playstorefilter;

    return EffectUtility.getToModel(PlayStoreModel, endpoint, params);
  }

  static async requestPlayStorePut(data, id) {
    const endpoint = environment.api.playstore + "/" + id;
    return EffectUtility.putToModel(PlaystorePutModel, endpoint, data);
  }
}
