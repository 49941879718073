import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import FaqAction from "../../stores/faq/FaqAction";
import { ShadowContainer } from "../../../components/componentLayout/ComponentLayout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import { theme } from "../../../views/App";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import SearchIcon from "@mui/icons-material/Search";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import FaqPost from "./FaqPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    FaqAction.REQUEST_GET_FAQ,
    FaqAction.REQUEST_GET_FAQ_MODULES,
    FaqAction.REQUEST_GET_FAQ_TOPICS,
  ]),
  faq: state.faq.faq || [],
  faqModules: state.faqModules.faqModules || [],
  faqTopics: state.faqTopics.faqTopics || [],
});

function Faq(props) {
  const { module, topic, query, ...params } = useParams();

  const { isRequesting } = props;

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState("panel0");
  const [openPostDialog, setOpenPostDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState(false);

  const [breadcrumbsData, setBreadCrumbsData] = useState([
    {
      label: "FAQ",
      path: "/faq",
    },
  ]);
  const [questionSearch, setQuestionSearch] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function fetchFaqModules(params) {
    props.dispatch(FaqAction.requestGetFaqModules(params));
  }
  function fetchFaq(params) {
    props.dispatch(FaqAction.requestGetFaq(params));
  }
  function fetchFaqTopics(params, module) {
    props.dispatch(FaqAction.requestGetFaqTopics(params, module));
  }

  useEffect(() => {
    const params = {
      page_limit: "none",
    };
    fetchFaqModules(params);
  }, []);

  useEffect(() => {
    if (module) {
      let params = { page_limit: "none" };
      fetchFaqTopics(params, module);
    }
  }, [module]);

  useEffect(() => {
    let filters = [];

    if (module) {
      filters.push(`["module.keyword","must","","match","${module}"]`);

      let data = [...breadcrumbsData];

      data[1] = {
        label: module,
        path: `/faq/${module}`,
      };
      data = data.slice(0, 2);
      setBreadCrumbsData(data);
    } else {
      setBreadCrumbsData([
        {
          label: "FAQ",
          path: "/faq",
        },
      ]);
    }

    if (topic) {
      filters.push(`["topic.keyword","must","","match","${topic}"]`);

      if (topic) {
        let data = [...breadcrumbsData];

        data[1] = {
          label: module,
          path: `/faq/${module}`,
        };

        data[2] = {
          label: topic,
          path: `/faq/${module}/${topic}`,
        };
        data = data.slice(0, 3);
        setBreadCrumbsData(data);
      }
    }

    const params = {
      filters: `[${filters}]`,
      page_limit: "none",
    };

    fetchFaq(params);

    setExpanded("panel0");
  }, [module, topic]);

  const handleSearchChange = (event) => {
    setQuestionSearch(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      applyFilter();
    }
  };

  function applyFilter() {
    let filters = [];

    if (questionSearch) {
      filters.push(
        `["faqs","must","nested","",["question.keyword","must","","wildcard","*${questionSearch}*"]]`
      );
    }

    const params = {
      filters: `[${filters}]`,
      page_limit: "none",
    };

    fetchFaq(params);
  }

  function BreadCrumbs(props) {
    let navigate = useNavigate();
    function handleClick(event, value) {
      event.preventDefault();

      let index = breadcrumbsData.findIndex(
        (item) => item.label === event.target.innerText
      );

      navigate(breadcrumbsData[index].path);
    }

    return (
      <div onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          {breadcrumbsData.map((breadcrumb, index) => (
            <Link
              key={index}
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color:
                  props.pathname === breadcrumb.path
                    ? theme.palette.text.primary
                    : theme.palette.text.gray,
              }}
            >
              {breadcrumb.label}
            </Link>
          ))}
        </Breadcrumbs>
      </div>
    );
  }

  const openCloseModal = useCallback((open) => {
    setOpenPostDialog(open);
    setEdit(false);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Grid
        container
        sx={{
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          paddingBottom: 1,
          marginBottom: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <BreadCrumbs pathname={decodeURIComponent(window.location.pathname)} />

        <Grid>
          {/* <MuiTextField
            variant="outlined"
            placeholder={"Search by Question"}
            fieldheight="33px"
            width={"250px"}
            value={questionSearch}
            // autoComplete="off"
            size="small"
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: <SearchIcon onClick={applyFilter} />,
            }}
          /> */}

          <Grid>
            <PostPlusButton
              open={openPostDialog}
              openCloseModal={openCloseModal}
              editMode={false}
            />
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
          sm={12}
          md={12}
          lg={10}
          xl={8}
          columnSpacing={1}
          rowGap={2}
        >
          <>
            {!isRequesting ? (
              <>
                {!topic && !module
                  ? props.faqModules.length
                    ? props.faqModules.map((item) => (
                        <Grid item pr={1}>
                          <ShadowContainer>
                            <Typography
                              component={"div"}
                              sx={{
                                cursor: "pointer",
                                fontWeight:
                                  item.key === module ? "bold" : "normal",
                                padding: { lg: 5, md: 4, sm: 3 },
                                fontSize: { lg: 18, md: 18, sm: 16 },
                              }}
                              onClick={() => {
                                navigate(`/faq/${item.key}`);
                              }}
                            >
                              {item.key}
                            </Typography>
                          </ShadowContainer>
                        </Grid>
                      ))
                    : null
                  : null}

                {!topic && module
                  ? props.faqTopics.length
                    ? props.faqTopics.map((item) => (
                        <Grid item>
                          <ShadowContainer>
                            <Typography
                              component={"div"}
                              sx={{
                                cursor: "pointer",
                                fontWeight:
                                  item.key === topic ? "bold" : "normal",
                                padding: { lg: 5, md: 4, sm: 3 },
                                fontSize: { lg: 18, md: 18, sm: 16 },
                              }}
                              onClick={() => {
                                navigate(`/faq/${module}/${item.key}`);
                              }}
                            >
                              {item.key}
                            </Typography>
                          </ShadowContainer>
                        </Grid>
                      ))
                    : null
                  : null}

                {topic ? (
                  <Grid container item mt={2} rowGap={2} sm={12}>
                    {props.faq?.hits?.hits?.length
                      ? props.faq?.hits?.hits.map((item, index) => (
                          <Grid item sm={12}>
                            <Accordion
                              expanded={expanded === `panel${index}`}
                              onChange={handleChange(`panel${index}`)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Typography
                                  sx={{ fontSize: 20, fontWeight: "bold" }}
                                >
                                  {item._source.topic}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {item._source.faqs?.length
                                  ? item._source.faqs
                                      .filter((item) =>
                                        item.question.includes(
                                          query ? query : ""
                                        )
                                      )
                                      .map((qNa, index, array) => (
                                        <Grid sx={{ padding: 1 }}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Box>
                                              <Typography
                                                component={"p"}
                                                fontWeight={"bold"}
                                              >
                                                {qNa.question}
                                              </Typography>
                                              <Typography component={"p"}>
                                                {qNa.answer}
                                              </Typography>
                                            </Box>
                                            <Box>
                                              <IconButton
                                                onClick={() => {
                                                  setOpenPostDialog(true);
                                                  setEdit(true);

                                                  setEditData({
                                                    module: item._source.module,
                                                    topic: item._source.topic,
                                                    faqs: item._source.faqs,
                                                    id: item._id,
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faPenToSquare}
                                                  style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    color: "#2a5e6c",
                                                  }}
                                                ></FontAwesomeIcon>
                                              </IconButton>
                                            </Box>
                                          </Box>

                                          {index !== array.length - 1 ? (
                                            <Typography
                                              component={"div"}
                                              sx={{
                                                marginY: 1,
                                                borderBottom: `1px dashed ${theme.palette.primary.dark}`,
                                              }}
                                            />
                                          ) : null}
                                        </Grid>
                                      ))
                                  : null}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                ) : null}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </>

          <FaqPost
            open={openPostDialog}
            onSubmit={() => {}}
            onClose={() => {
              setOpenPostDialog(false);
            }}
            editData={editData}
            edit={edit}
          />
        </Grid>
      </Box>
    </Box>
  );
}

export default connect(mapStateToProps)(Faq);
