import BaseReducer from "../../../utilities/BaseReducer";
import CloudMailAction from "./CloudMailAction";

export default class CloudMailFilterReduser extends BaseReducer {
  //initial state of CloudMailFilterReduser
  initialState = {
    cloudMailFilter: [],
  };
  //CloudMailFilterReduser request action finish
  [CloudMailAction.REQUEST_CLOUDMAIL_FILTER_FINISHED](state, action) {
    return {
      ...state,
      cloudMailFilter: action.payload,
    };
  }
}
