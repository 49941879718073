import ActionUtility from "../../../../utilities/ActionUtility";
import PlanEffect from "./PlanEffect";

export default class PlanAction {
  // PlanAction action with an function declaration
  static REQUEST_PLAN = "PlanAction.REQUEST_PLAN";
  static REQUEST_PLAN_FINISHED = "PlanAction.REQUEST_PLAN_FINISHED";

  static REQUEST_PLAN_FILTER = "PlanAction.REQUEST_PLAN_FILTER";
  static REQUEST_PLAN_FILTER_FINISHED =
    "PlanAction.REQUEST_PLAN_FILTER_FINISHED";

  // METHODS
  // plan GET function
  static requestPlan(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlanAction.REQUEST_PLAN,
        PlanEffect.requestPlan,
        params
      );
    };
  }

  static requestPlanFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlanAction.REQUEST_PLAN_FILTER,
        PlanEffect.requestPlanFilter,
        params
      );
    };
  }
}
