import React, { useEffect, useState } from "react";
import environment from "environment";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Description from "./descriptionContainer/Description";
import Logo from "./logoContainer/Logo";
import { Box } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";

const InstagramMessageSummary = (props) => {
  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData().then((e) => {
        setData(e);
      });
      setLoading(false);
    })();
  }, []);

  async function fetchData() {
    let API = `${environment.api.reportsOnInstagram}?report_type=["count"]&reports=["instagram_message_summary"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${environment.api.reportsOnInstagram}?report_type=["count"]&reports=["instagram_message_summary"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     return response;
    //   });
  }

  let LabelList = [];
  let Received = [];
  let Sent = [];

  function date_format(val) {
    return val;
  }

  data &&
    data.ticket_report &&
    data.ticket_report.instagram_message_summary &&
    Object.entries(data.ticket_report.instagram_message_summary).map(
      ([key, value]) => {
        LabelList.push(date_format(key));
        value.map((e) => {
          Received.push(e.recieved);
          Sent.push(e.sent);
        });
      }
    );

  const state = {
    labels: LabelList,
    datasets: [
      {
        label: "All messages received from user",
        backgroundColor: `${theme.reports.neutral}`,
        borderWidth: 1.5,
        borderColor: `${theme.reports.neutral}`,
        data: Received,
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
      {
        label: "Messages sent by brand",
        backgroundColor: `${theme.reports.positive}`,
        data: Sent,
        borderWidth: 1.5,
        backgroundColor: `${theme.reports.positive}`,
        lineTension: 0.3,
        pointStyle: "circle",
        pointRadius: 6,
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        // padding: "15px",
        // border: "1px solid grey",
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} />
        <p
          style={{ fontSize: theme.reports.headerSize }}
          className="reportHeader"
        >
          {firstLetterOrange(props.report && props.report)}
        </p>

        <hr className="p-0 m-0 " />

        <br />

        <Box
          sx={{
            width: "100%",
            paddingX: 5,
          }}
        >
          <Line
            datasetIdKey="id"
            data={state}
            options={{
              responsive: true,
              animation: false,

              plugins: {
                tooltip: {
                  usePointStyle: true,
                },
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    usePointStyle: true,
                  },
                },
              },

              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: "Date",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "All messages received from user, Messages sent by brand",
                  },
                  // labelString: "Mention Count",
                  grid: {
                    display: true,
                  },
                },
              },
            }}
          />
        </Box>
        {window.location.pathname === "/ticketStatsReport" && (
          <Description
            description={props.description}
            callBack={props.callBack}
            report={props.report}
            index={0}
          />
        )}
      </LoadingIndicator>
    </div>
  );
};

export default InstagramMessageSummary;
