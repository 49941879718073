import ActionUtility from "../../../../utilities/ActionUtility";
import RedditAccountEffect from "./RedditAccountEffect";

export default class RedditAccountAction {
  // RedditAccountAction with an function declaration
  static REQUEST_REDDIT_ACCOUNT = "RedditAccountAction.REQUEST_REDDIT_ACCOUNT";
  static REQUEST_REDDIT_ACCOUNT_FINISHED =
    "RedditAccountAction.REQUEST_REDDIT_ACCOUNT_FINISHED";

  static REQUEST_REDDIT_ACCOUNT_FILTER =
    "RedditAccountAction.REQUEST_REDDIT_ACCOUNT_FILTER";
  static REQUEST_REDDIT_ACCOUNT_FILTER_FINISHED =
    "RedditAccountAction.REQUEST_REDDIT_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_REDDIT_ACCOUNT =
    "RedditAccountAction.REQUEST_PUT_REDDIT_ACCOUNT";
  static REQUEST_PUT_REDDIT_ACCOUNT_FINISHED =
    "RedditAccountAction.REQUEST_PUT_REDDIT_ACCOUNT_FINISHED";

  static REQUEST_POST_REDDIT_ACCOUNT =
    "RedditAccountAction.REQUEST_POST_REDDIT_ACCOUNT";
  static REQUEST_POST_REDDIT_ACCOUNT_FINISHED =
    "RedditAccountAction.REQUEST_POST_REDDIT_ACCOUNT_FINISHED";

  static REQUEST_DELETE_REDDIT_ACCOUNT =
    "RedditAccountAction.REQUEST_DELETE_REDDIT_ACCOUNT";
  static REQUEST_DELETE_REDDIT_ACCOUNT_FINISHED =
    "RedditAccountAction.REQUEST_DELETE_REDDIT_ACCOUNT_FINISHED";

  // METHODS
  // Reddit Get function
  static requestRedditAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditAccountAction.REQUEST_REDDIT_ACCOUNT,
        RedditAccountEffect.requestRedditAccount,
        params
      );
    };
  }

  static requestRedditAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditAccountAction.REQUEST_REDDIT_ACCOUNT_FILTER,
        RedditAccountEffect.requestRedditAccountFilter,
        params
      );
    };
  }
  static requestPutRedditAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditAccountAction.REQUEST_PUT_REDDIT_ACCOUNT,
        RedditAccountEffect.requestPutRedditAccount,
        data,
        id
      );
    };
  }

  // Reddit post function
  static requestPostRedditAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditAccountAction.REQUEST_POST_REDDIT_ACCOUNT,
        RedditAccountEffect.requestPostRedditAccount,
        data
      );
    };
  }

  static requestDeleteRedditAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RedditAccountAction.REQUEST_DELETE_REDDIT_ACCOUNT,
        RedditAccountEffect.requestDeleteRedditAccount,
        data
      );
    };
  }
}
