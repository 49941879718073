import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeOfficialAction from "./YoutubeOfficialAction";

export default class YoutubeChannelRevenueReduser extends BaseReducer {
  //initial state of youtubeChannelRevenueReduser
  initialState = {
    youtubeChannelRevenue: [],
  };
  //youtubeChannelRevenueReduser request action finish
  [YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeChannelRevenue: action.payload,
    };
  }

  [YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeChannelRevenue: action.payload,
    };
  }
}
