import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import ClientModel from "./models/ClientModel";
import ClientPostModel from "./models/ClientPostModel";
import ClientPutModel from "./models/ClientPutModel";

export default class ClientEffect {
  // calling an API according to the ClientModel
  static async requestClient(params) {
    const endpoint = environment.api.client;

    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  // client filter with page_limit none
  static async requestClientFilter(params) {
    const endpoint = environment.api.clientFilter;

    return EffectUtility.getToModel(ClientModel, endpoint, params);
  }

  // Post API
  static async requestPostClient(data, callBackFunction) {
    const endpoint = environment.api.client + "/add";
    let response = EffectUtility.postToModel(ClientPostModel, endpoint, data);
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  // Put API
  static async requestPutClient(data, id) {
    const endpoint = environment.api.client + "/" + id;

    return EffectUtility.putToModel(ClientPutModel, endpoint, data);
  }
}
