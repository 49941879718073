import BaseReducer from "../../../../utilities/BaseReducer";
import LabelAction from "./LabelAction";

// IngBannerListReduser
export default class IngBannerListReduser extends BaseReducer {
  initialState = {
    ing_banner_list: [],
  };
  [LabelAction.REQUEST_ING_BANNER_LIST_FINISHED](state, action) {
    return {
      ...state,
      ing_banner_list: action.payload,
    };
  }
}
