import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import GlassdoorAccountDeleteModel from "../glassdoorAccount/model/GlassdoorAccountDeleteModel";
import PlatformScheduleModel from "./model/PlatformScheduleModel";
import PlatformSchedulePostModel from "./model/PlatformPostScheduleModel";

// PlatformSchedulerEffect
export default class PlatformSchedulerEffect {
  // calling an API according to the platform model
  // Get method for the
  static async requestPlatformScheduling(params) {
    const endpoint = environment.api.platform_scheduling;
    return EffectUtility.getToModel(PlatformScheduleModel, endpoint, params);
  }

  // Get method for the platform filter
  static async requestPlatformTaskScheduling(params) {
    const endpoint = environment.api.platform_task_scheduling;
    return EffectUtility.getToModel(PlatformScheduleModel, endpoint, params);
  }

  // delete method for the platform
  static async requestDeletePlatformScheduling(id) {
    const endpoint = environment.api.platform_scheduling + "/" + id;

    return EffectUtility.deleteToModel(GlassdoorAccountDeleteModel, endpoint);
  }

  static async requestPlatformPostSchedule(data) {
    const endpoint = environment.api.platformPost;
    return EffectUtility.postToModel(PlatformSchedulePostModel, endpoint, data);
  }
}
