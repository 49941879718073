import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import ThreadsAccountAction from "../../stores/threadsAccount/ThreadsAccountAction";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  account_name: yup
    .string("Enter Account Name")
    .trim()
    .min(1, "Account Name should be minimum 1 character in length")
    .required("Account Name is required"),

  account_id: yup
    .string("Enter Account Id")
    .trim()
    .min(1, "Account Id should be minimum 1 character in length")
    .required("Account Id is required"),
});

function AddThreadsAccount(props) {
  let initial = {};
  initial = {
    account_name: "",
    account_id: "",
  };

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);

          let post_obj = {};

          Object.assign(
            post_obj,
            {
              account_name: values.account_name,
            },
            {
              account_id: values.account_id,
            },

            {
              platform: "Threads",
            }
          );

          props
            .dispatch(
              ThreadsAccountAction.requestPostThreadsAccount(
                JSON.stringify(post_obj)
              )
            )
            .then(() => {
              setLoading(false);

              let params = {
                page_limit: "none",
              };
              let account_params = {
                page_limit: "none",
                order_by: '[["created_on", "desc"]]',
              };
              props.onClose();
              props.setAdd_account(null);
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccount(account_params)
              );
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccountFilter(params)
              );
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">A</span>
                            dd &nbsp;
                            <span className="span_first_letter">T</span>hreads
                            &nbsp;
                            <span className="span_first_letter">A</span>ccount
                            &nbsp;
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">E</span>
                            dit &nbsp;
                            <span className="span_first_letter">P</span>roject
                            &nbsp;
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                        props.setAdd_account("");
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      id="account_name"
                      size="small"
                      name="account_name"
                      placeholder="Account Name"
                      error={
                        Boolean(errors.account_name) &&
                        Boolean(touched.account_name)
                      }
                      helperText={
                        Boolean(touched.account_name) && errors.account_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account ID :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="account_id"
                      size="small"
                      name="account_id"
                      placeholder="Account ID"
                      error={
                        Boolean(errors.account_id) &&
                        Boolean(touched.account_id)
                      }
                      helperText={
                        Boolean(touched.account_id) && errors.account_id
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Account "}
                      type="submit"
                      loading={loading}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Edit Project "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(mapDispatchToProps)(AddThreadsAccount);
