import ActionUtility from "../../../../utilities/ActionUtility";
import InfluencerDashboardEffect from "./InfluencerDashboardEffect";

export default class InfluencerDashboardAction {
  // InfluencerDashboardAction action with an function declaration
  static REQUEST_INFLUENCER_DASHBOARD =
    "InfluencerDashboardAction.REQUEST_INFLUENCER_DASHBOARD";
  static REQUEST_INFLUENCER_DASHBOARD_FINISHED =
    "InfluencerDashboardAction.REQUEST_INFLUENCER_DASHBOARD_FINISHED";

  static REQUEST_INFLUENCER_DASHBOARD_FILTER =
    "InfluencerDashboardAction.REQUEST_INFLUENCER_DASHBOARD_FILTER";
  static REQUEST_INFLUENCER_DASHBOARD_FILTER_FINISHED =
    "InfluencerDashboardAction.REQUEST_INFLUENCER_DASHBOARD_FILTER_FINISHED";

  static REQUEST_PUT_INFLUENCER_DASHBOARD =
    "InfluencerDashboardAction.REQUEST_PUT_INFLUENCER_DASHBOARD";
  static REQUEST_PUT_INFLUENCER_DASHBOARD_FINISHED =
    "InfluencerDashboardAction.REQUEST_PUT_INFLUENCER_DASHBOARD_FINISHED";

  static REQUEST_POST_INFLUENCER_DASHBOARD =
    "InfluencerDashboardAction.REQUEST_POST_INFLUENCER_DASHBOARD";
  static REQUEST_POST_INFLUENCER_DASHBOARD_FINISHED =
    "InfluencerDashboardAction.REQUEST_POST_INFLUENCER_DASHBOARD_FINISHED";

  // METHODS
  // InfluencersDashboard GET function
  static requestInfluencerDashboard(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerDashboardAction.REQUEST_INFLUENCER_DASHBOARD,
        InfluencerDashboardEffect.requestInfluencerDashboard,
        params
      );
    };
  }

  static requestInfluencerDashboardFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerDashboardAction.REQUEST_INFLUENCER_DASHBOARD_FILTER,
        InfluencerDashboardEffect.requestInfluencerDashboardFilter,
        params
      );
    };
  }
  static requestPutInfluencerDashboard(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerDashboardAction.REQUEST_PUT_INFLUENCER_DASHBOARD,
        InfluencerDashboardEffect.requestPutInfluencerDashboard,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostInfluencerDashboard(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerDashboardAction.REQUEST_POST_INFLUENCER_DASHBOARD,
        InfluencerDashboardEffect.requestPostInfluencerDashboard,
        data
      );
    };
  }
}
