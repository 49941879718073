import ActionUtility from "../../../../utilities/ActionUtility";
import TwitterDashboardEffect from "./TwitterDashboardEffect";
export default class TwitterDashboardAction {
  // Category action with an function declaration
  static REQUEST_TWITTER_DASHBOARD =
    "TwitterDashboardAction.REQUEST_TWITTER_DASHBOARD";
  static REQUEST_TWITTER_DASHBOARD_FINISHED =
    "TwitterDashboardAction.REQUEST_TWITTER_DASHBOARD_FINISHED";

  // METHODS
  // Category GET function
  static requestTwitterDashboard(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterDashboardAction.REQUEST_TWITTER_DASHBOARD,
        TwitterDashboardEffect.requestTwitterDashboard,
        params
      );
    };
  }
}
