import HttpErrorResponseModel from "../models/HttpErrorResponseModel";
import HttpUtility from "./HttpUtility";
import environment from "environment";
import { datadogLogs } from "@datadog/browser-logs";

export default class EffectUtility {
  // Project Get method Model
  static async getToModel(Model, endpoint, params) {
    const response = await HttpUtility.get(endpoint, params);

    if (response.status === 200) {
      response.request &&
        datadogLogs.logger.log(
          `${response.request.__apm_symbol__xhrMethod}-${response.request.__apm_symbol__xhrURL}`,
          response
        );
    } else {
      response.raw.request &&
        datadogLogs.logger.error(
          `${response.raw.request.__apm_symbol__xhrMethod}-${response.raw.request.__apm_symbol__xhrURL}`,
          response
        );
    }

    // if (response.status === 417) {
    //   let errorMessage = "";
    //   errorMessage = response.raw.data.message;
    //   errorMessage = errorMessage.split("-");
    //   errorMessage = errorMessage[1].split(".");
    //   errorMessage = errorMessage[0];
    //   // alert(errorMessage);
    // }

    // if (response.status === 404) {
    let errorMessage = "";
    // errorMessage = response.raw.data.message;
    // errorMessage = errorMessage.split("-");
    // errorMessage = errorMessage[1].split(".");
    // errorMessage = errorMessage[0];
    // alert(errorMessage);
    // }

    // if (
    //   response.status === 404 &&
    //   endpoint === environment.api.userLastWorkingProject + "/" + params
    // ) {
    //
    //   if (window.location.pathname === "/projectSelect") {
    //     if (response.data.message !== undefined) alert(response.data.message);
    //   }
    // }
    return EffectUtility._restModelCreator(Model, response);
  }

  // Project Post method Model
  static async postToModel(Model, endpoint, data, params) {
    const response = await HttpUtility.post(endpoint, data, params);

    if (response.status === 200) {
      response.request &&
        datadogLogs.logger.log(
          `${response.request.__apm_symbol__xhrMethod}-${response.request.__apm_symbol__xhrURL}`,
          response
        );
    } else {
      response &&
        response.raw &&
        response.raw.request &&
        datadogLogs.logger.error(
          `${response.raw.request.__apm_symbol__xhrMethod}-${response.raw.request.__apm_symbol__xhrURL}`,
          response
        );
    }

    // if (response.status === 500) {
    //   let errorMessage = "";
    //   errorMessage = response.raw.data.error;
    //   errorMessage = errorMessage && errorMessage.split(":");
    //   errorMessage = errorMessage[1].split(`"`);
    //   errorMessage = errorMessage[1];
    //   // alert(errorMessage);
    // } else if (response.status === 200) {
    //   // alert(response.data.status);
    // } else {W
    //   // alert(response.raw.data.message);
    // }

    return EffectUtility._restModelCreator(Model, response);
  }

  // Project Put method Model
  static async putToModel(Model, endpoint, data, params) {
    const response = await HttpUtility.put(endpoint, data, params);

    if (response.status === 200) {
      response.request &&
        datadogLogs.logger.log(
          `${response.request.__apm_symbol__xhrMethod}-${response.request.__apm_symbol__xhrURL}`,
          response
        );
    } else {
      response.raw.request &&
        datadogLogs.logger.error(
          `${response.raw.request.__apm_symbol__xhrMethod}-${response.raw.request.__apm_symbol__xhrURL}`,
          response
        );
    }

    if (response.status === 200) {
      // alert(response.data.status);
    } else {
      // alert(response.raw.data.message);
    }
    return EffectUtility._restModelCreator(Model, response);
  }

  static async postWithIdModel(Model, endpoint, data) {
    const response = await HttpUtility.postWithId(endpoint, data);

    return EffectUtility._restModelCreator(Model, response);
  }

  //DELETE to model
  static async deleteToModel(Model, endpoint, params) {
    const response = await HttpUtility.delete(endpoint, params);

    if (response.status === 200) {
      response.request &&
        datadogLogs.logger.log(
          `${response.request.__apm_symbol__xhrMethod}-${response.request.__apm_symbol__xhrURL}`,
          response
        );
    } else {
      response.raw.request &&
        datadogLogs.logger.error(
          `${response.raw.request.__apm_symbol__xhrMethod}-${response.raw.request.__apm_symbol__xhrURL}`,
          response
        );
    }

    if (response.status === 200) {
      // alert(response.data.status ? response.data.status : response.data.result);
    } else {
      // alert(response.raw.data.message);
    }
    return EffectUtility._restModelCreator(Model, response);
  }

  static _restModelCreator(Model, response) {
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return !Array.isArray(response.data)
      ? new Model(response.data)
      : response.data.map((json) => new Model(json));
  }
}
