import BaseReducer from "../../../../utilities/BaseReducer";
import NetRevenueByMonthAction from "./NetRevenueByMonthAction";

export default class NetRevenueByMonthReducer extends BaseReducer {
  initialState = {
    netRevenueByMonth: [],
  };
  [NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      netRevenueByMonth: action.payload,
    };
  }
}
