import ActionUtility from "../../../utilities/ActionUtility";

import CannedMessageEffect from "./CannedMessageEffect";

export default class CannedMessageAction {
  // CannedMessageAction action with an function declaration
  static REQUEST_CANNED_MESSAGE = "CannedMessageAction.REQUEST_CANNED_MESSAGE";
  static REQUEST_CANNED_MESSAGE_FINISHED =
    "CannedMessageAction.REQUEST_CANNED_MESSAGE_FINISHED";

  static REQUEST_CANNED_MESSAGE_FILTER =
    "CannedMessageAction.REQUEST_CANNED_MESSAGE_FILTER";
  static REQUEST_CANNED_MESSAGE_FILTER_FINISHED =
    "CannedMessageAction.REQUEST_CANNED_MESSAGE_FILTER_FINISHED";

  static REQUEST_PUT_CANNED_MESSAGE =
    "CannedMessageAction.REQUEST_PUT_CANNED_MESSAGE";
  static REQUEST_PUT_CANNED_MESSAGE_FINISHED =
    "CannedMessageAction.REQUEST_PUT_CANNED_MESSAGE_FINISHED";

  static REQUEST_POST_CANNED_MESSAGE =
    "CannedMessageAction.REQUEST_POST_CANNED_MESSAGE";
  static REQUEST_POST_CANNED_MESSAGE_FINISHED =
    "CannedMessageAction.REQUEST_POST_CANNED_MESSAGE_FINISHED";

  // METHODS
  // Canned Message GET function
  static requestCannedMessage(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CannedMessageAction.REQUEST_CANNED_MESSAGE,
        CannedMessageEffect.requestCannedMessage,
        params
      );
    };
  }

  static requestCannedMessageFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CannedMessageAction.REQUEST_CANNED_MESSAGE_FILTER,
        CannedMessageEffect.requestCannedMessageFilter,
        params
      );
    };
  }
  static requestPutCannedMessage(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CannedMessageAction.REQUEST_PUT_CANNED_MESSAGE,
        CannedMessageEffect.requestPutCannedMessage,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostCannedMessage(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CannedMessageAction.REQUEST_POST_CANNED_MESSAGE,
        CannedMessageEffect.requestPostOrganization,
        data
      );
    };
  }
}
