import BaseReducer from "../../../../utilities/BaseReducer";
import TopLanguagesAction from "./TopLanguagesAction";

// TopLanguagesReduser
export default class TopLanguagesReduser extends BaseReducer {
  initialState = {
    top_languages_report: [],
  };
  [TopLanguagesAction.REQUEST_TOP_LANGUAGES_FINISHED](state, action) {
    return {
      ...state,
      top_languages_report: action.payload,
    };
  }
}
