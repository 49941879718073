import ActionUtility from "../../../../utilities/ActionUtility";
import YoutubeDataExtractionEffect from "./YoutubeDataExtractionEffect";

export default class YoutubeDataExtractionAction {
  //Youtube DataExtraction get requests
  static REQUEST_YOUTUBE_DATAEXTRACTION =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION";
  static REQUEST_YOUTUBE_DATAEXTRACTION_FINISHED =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FINISHED";

  static REQUEST_YOUTUBE_DATAEXTRACTION_CLEAR =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_CLEAR";
  static REQUEST_YOUTUBE_DATAEXTRACTION_CLEAR_FINISHED =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_CLEAR_FINISHED";

  static REQUEST_YOUTUBE_DATAEXTRACTION_FILTER =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER";
  static REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_FINISHED =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_FINISHED";

  static REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_CLEAR =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_CLEAR";
  static REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_CLEAR_FINISHED =
    "YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_CLEAR_FINISHED";

  //youtube DataExtraction Put requests
  static REQUEST_PUT_YOUTUBE_DATAEXTRACTION =
    "YoutubeDataExtractionAction.REQUEST_PUT_YOUTUBE_DATAEXTRACTION";
  static REQUEST_PUT_YOUTUBE_DATAEXTRACTION_FINISHED =
    "YoutubeDataExtractionAction.REQUEST_PUT_YOUTUBE_DATAEXTRACTION_FINISHED";

  // dataextraction Projects Action
  static REQUEST_DATAEXTRACTION_PROJECTS =
    "YoutubeDataExtractionAction.REQUEST_DATAEXTRACTION_PROJECTS";
  static REQUEST_DATAEXTRACTION_PROJECTS_FINISHED =
    "YoutubeDataExtractionAction.REQUEST_DATAEXTRACTION_PROJECTS_FINISHED";

  //Methods

  //get method
  static requestYoutubeDataExtraction(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION,
        YoutubeDataExtractionEffect.requestYoutubeDataExtraction,
        params,
        callBackFunction
      );
    };
  }

  static requestYoutubeDataExtractionClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_CLEAR,
        YoutubeDataExtractionEffect.requestYoutubeDataExtractionClear
      );
    };
  }

  static requestYoutubeDataExtractionFilter(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER,
        YoutubeDataExtractionEffect.requestYoutubeDataExtractionFilter,
        params,
        callBackFunction
      );
    };
  }

  static requestYoutubeDataExtractionFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeDataExtractionAction.REQUEST_YOUTUBE_DATAEXTRACTION_FILTER_CLEAR,
        YoutubeDataExtractionEffect.requestYoutubeDataExtractionFilterClear
      );
    };
  }

  //put method
  static requestPutYoutubeDataExtraction(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeDataExtractionAction.REQUEST_PUT_YOUTUBE_DATAEXTRACTION,
        YoutubeDataExtractionEffect.requestPutYoutubeDataExtraction,
        data
      );
    };
  }

  static requestDataExtractionProjects(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeDataExtractionAction.REQUEST_DATAEXTRACTION_PROJECTS,
        YoutubeDataExtractionEffect.requestDataExtractionProjects,
        params
      );
    };
  }
}
