import BaseReducer from "../../../utilities/BaseReducer";
import AllMentionsAction from "./AllMentionsAction";

export default class AllMentionsFilterReduser extends BaseReducer {
  //initial state of AllMentionsFilterReduser
  initialState = {
    allmentionsFilter: [],
  };
  //AllMentionsFilterReduser request action finish
  [AllMentionsAction.REQUEST_ALLMENTIONS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      allmentionsFilter: action.payload,
    };
  }
}
