import { BaseModel } from "sjs-base-model";
import StatusDataHitsModel from "./StatusDataHitsModel";
import StatusShardsModel from "./StatusShardsModel";

// StatusModel
export default class StatusModel extends BaseModel {
    // response getting from the api mentioned end point
    _shards = { _shards: StatusShardsModel };
    current_page_no = 0;
    hits = { hits: StatusDataHitsModel };
    total_page_no = 0;
    timed_out = false;
    took = 0;
    constructor(data1) {
        super();

        this.update(data1);
    }
}
