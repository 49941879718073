import BaseReducer from "../../../../utilities/BaseReducer";
import DsrActivityAction from "./DsrActivityAction";

export default class DsrKanbanActivityReducer extends BaseReducer {
  initialState = {
    dsrKanbanActivity: [],
  };
  [DsrActivityAction.REQUEST_GET_DSR_KANBAN_ACTIVITY_FINISHED](state, action) {
    return {
      ...state,
      dsrKanbanActivity: action.payload,
    };
  }
}
