import BaseReducer from "../../../../utilities/BaseReducer";
import YoutubeOfficialAction from "./YoutubeOfficialAction";

// StatusReduser
export default class YoutubeOfficialReduser extends BaseReducer {
  initialState = {
    status: [],
  };
  [YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL_FINISHED](state, action) {
    return {
      ...state,
      youtubeOfficial: action.payload,
    };
  }

  [YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      youtubeOfficial: action.payload,
    };
  }
}
