import BaseReducer from "../../../utilities/BaseReducer";
import LinkedInFeedAction from "./LinkedInFeedAction";

// GmbReviewFeedsReduser  function
export default class LinkedInFeedsReduser extends BaseReducer {
  initialState = {
    linkedInFeeds: [],
  };
  [LinkedInFeedAction.REQUEST_LINKEDIN_FEED_FINISHED](state, action) {
    return {
      ...state,
      linkedInFeeds: action.payload,
    };
  }

  [LinkedInFeedAction.REQUEST_LINKEDIN_FEED_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      linkedInFeeds: action.payload,
    };
  }
}
