import BaseReducer from "../../../../utilities/BaseReducer";
import InrRatesAction from "./InrRatesAction";

export default class InrRatesFilterReduser extends BaseReducer {
  //initial state of StatusFilterReduser
  initialState = {
    inrRatesFilter: [],
  };
  //InrRatesFilterReduser request action finish
  [InrRatesAction.REQUEST_INR_RATES_FILTER_FINISHED](state, action) {
    return {
      ...state,
      inrRatesFilter: action.payload,
    };
  }
}
