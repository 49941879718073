import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
// postPlus button component
// Pagination component

import { Box, Grid, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TicketTypeAction from "../../../stores/ticketType/TicketTypeAction";
import TicketTypePost from "../../components/tickets/TicketTypePost";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TicketTypeAction.REQUEST_TICKETTYPE,
    TicketTypeAction.REQUEST_TICKETTYPE_FILTER,
  ]),
  ticketType: state.ticketType.ticketType || {},
  pagination: state.ticketType.ticketType || [],
  filterTicketType: state.filterTicketType.filterTicketType || [],
});

// TICKET TYPE FUNCTION
function TicketType(props) {
  const { isRequesting } = props;

  // State declarations
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [searchTicketType, setSearchTicketType] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //fetch function for ticket type lists
  function fetch(params) {
    props.dispatch(TicketTypeAction.requestTicketType(params));
  }

  //fetch function for ticketType Filter
  function fetchFilterTicketType(params) {
    props.dispatch(TicketTypeAction.requestFilterTicketType(params));
  }

  //useeffect function
  useEffect(() => {
    //--------------------------------------------------------------
    // ticketType fetching with pageLimit none for Ticket Type drop down

    const param = {
      page_limit: "none",
      order_by: `[["ticket_type_name.keyword", "asc"]]`,
    };

    // if (
    //   props?.filterTicketType === undefined ||
    //   props?.filterTicketType.length === 0
    // ) {
    fetchFilterTicketType(param);
    // }

    //--------------------------------------------------------------

    const filter = [];
    let params = {};

    if (searchTicketType !== null) {
      filter.push(
        `[["ticket_type_name.keyword","must","","term","${searchTicketType}"]]`
      );
    }

    if (searchTicketType !== null) {
      params = {
        filters: `${filter}`,
        order_by: `[["order_number","asc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: 0,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    }

    fetch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ticket Type callback function
  const TicketTypehandleChange = (event, searchTicketType) => {
    if (searchTicketType != null) {
      setSearchTicketType(searchTicketType);
    } else {
      setSearchTicketType(null);
    }
  };

  //CLEAR Function
  const handleClear = () => {
    // Clear the state
    if (searchTicketType !== null) {
      setSearchTicketType(null);
      setClearLoader(true);
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);

    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: 15,
    };

    FilterClicked && fetch(params);
  };

  // Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      feild: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "ticket_type_name",
      headerName: "Ticket Type Name",
      width: 250,
      flex: 4,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 4,
      resizable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      flex: 2,
      width: 160,
      cellClassName: "actions",
      align: "center",

      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  // pagination
  const handleChange = (event, value) => {
    // page_number=1&page_limit=20
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // PAGINATION PARAMS ASSIGNING
    const filter = [];
    let params = {};

    if (searchTicketType !== null) {
      filter.push(
        `[["ticket_type_name.keyword","must","","term","${searchTicketType}"]]`
      );
    }

    if (searchTicketType !== null && FilterClicked) {
      params = {
        filters: `${filter}`,
        page_number: `${value}` - 1,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetch(params);
    }
  };

  //TICKET TYPE DROPDOWN LIST ASSIGNING
  let ticketTypeDropdownList = [];
  let filteredTicketTypeList = [];
  if (props.filterTicketType.hits) {
    props.filterTicketType.hits.hits.forEach((value) => {
      ticketTypeDropdownList.push(value._source.ticket_type_name);
    });
    filteredTicketTypeList = [...new Set(ticketTypeDropdownList)];
  }

  filteredTicketTypeList.sort((a, b) => a.localeCompare(b));
  filteredTicketTypeList = [...new Set(filteredTicketTypeList)];

  //FILTER FUNCTION
  const handleFilter = () => {
    let params = {};
    const filter = [];

    if (searchTicketType !== null) {
      filter.push(
        `[["ticket_type_name.keyword","must","","term","${searchTicketType}"]]`
      );
    }

    if (searchTicketType !== null) {
      params = {
        filters: `${filter}`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      fetch(params);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
    // fetching ticket type list with filter params
  };

  //  datagrid rows
  const rows = [];
  //assigning data to rows
  if (props.ticketType.hits) {
    props.ticketType.hits.hits.map((item, index) => {
      return rows.push({
        // sl_no: item._id
        //   ? props.pagination.current_page_no * page_limit + ++index
        //   : "",
        sl_no: item._source.order_number,
        id: item._id,
        ticket_type_name: item._source.ticket_type_name,
        created_by: item._source.created_by.user_name,
      });
    });
  }

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["ticket_type_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    data &&
      props
        .dispatch(TicketTypeAction.requestPutTicketTypeBulkUpdate(bulkData))
        .then(() => {
          props.dispatch(TicketTypeAction.requestTicketType(paramss));
          props.dispatch(TicketTypeAction.requestFilterTicketType(param));
        });
  };

  return (
    <React.Fragment>
      <>
        {/* modal screen */}
        <TicketTypePost
          open={open}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          ticketTypeEditData={editRowsModel}
          edit={edit}
        />

        {/* FILTER CONTAINER */}
        <Grid container>
          <Grid container lg={12} md={12} sm={12} justifyContent="center">
            <Grid item lg={2} md={5} sm={5} mt={1}>
              <AutoCompleteComponent
                list={filteredTicketTypeList}
                label="Ticket Type Name"
                size="small"
                handleChange={TicketTypehandleChange}
                textFieldProps={{
                  placeholder: "Ticket Type Name",
                }}
                value={searchTicketType}
                width="100%"
              />
            </Grid>
            <Grid item lg={6} md={7} sm={7} container>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Filter"}
                      width="100%"
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Clear"}
                      width="100%"
                      onClick={handleClear}
                      loading={clearLoader && isRequesting}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  {/* post button component*/}

                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid item lg={8} md={12} sm={12} xs={12}>
                {/* data grid */}
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid component */}
                  {props.ticketType.hits && !isRequesting ? (
                    <DataGridFunction
                      rows={rows}
                      columns={columns}
                      rowHeight={60}
                      rowReordering
                      onRowOrderChange={rowOrderHandleChange}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingBgImage />
                    </Box>
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
            {/* Pagination */}
            {/* <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={10}
              md={12}
              sm={12}
              className="pagination_section"
            >
              <Grid item lg={8} md={12} sm={12} xs={12}>
                {props.ticketType.hits
                  ? props.ticketType.hits.hits.length !== 0 && (
                      <PaginationUI
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  : null}
              </Grid>
            </Grid> */}
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </>
    </React.Fragment>
  );
}

// connect the datagrid function to mapstate function
export default connect(mapStateToProps)(TicketType);
