import { BaseModel } from "sjs-base-model";
import LinksDataModel from "./LinksDataModel";

// LinksDataHitsModel
export default class LinksDataHitsModel extends BaseModel {
    // response getting from the api

    _id = "";
    _index = "";
    _score = 0.0;
    _source = [LinksDataModel];
    constructor(data) {
        super();
        this.update(data);
    }
}
