import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";
export default class CampaignMappingSourceModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  campaign_id = "";
  created_by = {};
  created_on = "";
  influencer_id = "";
  modified_by = {};
  modified_on = "";
  resource = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
