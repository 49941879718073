import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import "./LinkedIn.css";
import { format } from "date-fns";
import { connect } from "react-redux";
// Pagination component
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import environment from "environment";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import PaginationUI from "../../../../components/pagination/Pagination";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LinkedInFeedAction from "../../../stores/linkedInFeed/LinkedInFeedAction";
import LinkedInAccountAction from "../../../stores/linkedIn_account/LinkedInAccountAction";
import CustomerDataAction from "../../../../stores/ticketCustomerData/CustomerDataAction";
import { axiosRequest } from "../../../../utilities/FetchRequest";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    LinkedInFeedAction.REQUEST_LINKEDIN_FEED,
    LinkedInFeedAction.REQUEST_LINKEDIN_FEED_FILTER,
    CustomerDataAction.REQUEST_CUSTOMER_DATA,
    LinkedInAccountAction.REQUEST_LINKED_IN_ACCOUNT,
  ]),
  linkedInFeeds: state.linkedInFeeds.linkedInFeeds.hits || [],
  pagination: state.linkedInFeeds.linkedInFeeds || {},
  linkedInFeedFilter: state.linkedInFeedFilter.linkedInFeedFilter.hits || [],
  customerData: state.customerData.customerData || {},
  linkedInAccount: state.linkedInAccount.linkedInAccount || [],
});

function LinkedInListView(props) {
  // useState Functions
  const [date, setDate] = useState([null, null]);
  const [value, setValue] = React.useState("1");
  const [account_name_value, set_account_name_value] = useState(null);
  const [page_limit] = useState(10);
  const [customerName, setCustomerName] = useState(null);
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  //tab handle change
  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ************************************************* Dispatch functions
  function fetchLinkedIn(params) {
    props.dispatch(LinkedInFeedAction.requestLinkedInFeed(params));
  }

  function fetchLinkedInfilter(param) {
    props.dispatch(LinkedInFeedAction.requestLinkedInFeedFilter(param));
  }

  function fetchLinkedInAccountfilter() {
    let param = {
      page_limit: "none",
    };
    props.dispatch(LinkedInAccountAction.requestLinkedInAccount(param));
  }

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //   UseEffect function
  useEffect(() => {
    const param = { page_limit: "none" };

    fetchLinkedInfilter(param);
    fetchLinkedInAccountfilter();

    const filter = [];
    let params = {};

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }
    if (account_name_value !== null) {
      filter.push(
        `["account_name.keyword","must","","match","${account_name_value}"]`
      );
    }

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_at","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_at","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      customerName !== null ||
      account_name_value !== null ||
      sentimental_val !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    } else {
      params = {
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }

    fetchLinkedIn(params);

    fetchCustomerDataFunction("OJn77YUBlrS994sVgvgB");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // applyFilter Function

  const applyFilter = () => {
    let params = {};
    const filter = [];

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }
    if (account_name_value !== null) {
      filter.push(
        `["account_name.keyword","must","","match","${account_name_value}"]`
      );
    }

    // search
    if (searchQuery !== "") {
      filter.push(
        `["comment_content.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_at","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_at","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    if (
      customerName !== null ||
      account_name_value !== null ||
      sentimental_val !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
      fetchLinkedIn(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please add filters");
      // params = {
      //   order_by: '[["created", "desc"]]',
      //   page_limit: `${page_limit}`,
      // };
      // fetch(params);
    }
  };

  const AccountNameHandleChange = (event, value) => {
    if (value != null) {
      set_account_name_value(value);
    } else {
      set_account_name_value(null);
    }
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val(null);
    }
  };

  // ************************************* Filter List
  // Integrating Costomer name to list

  // Clear method
  const ClearState = () => {
    setFilterClicked(false);
    if (
      account_name_value !== null ||
      customerName !== null ||
      sentimental_val !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      set_account_name_value(null);
      setCustomerName(null);
      setSentimental_val(null);
      setDate([null, null]);
      setClearLoader(true);
      setSearchQuery("");

      if (navigator.onLine === true) {
        const params = {
          page_limit: `${page_limit}`,
          order_by: '[["created_on", "desc"]]',
        };
        FilterClicked && fetchLinkedIn(params);
      } else {
        alert("No internet connection");
      }
    }
  };

  // pagination
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }
    if (account_name_value !== null) {
      filter.push(
        `["account_name.keyword","must","","match","${account_name_value}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value","must","lte","range","-0.01"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(`["sentiment_value","must","gte","range","0.01"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value","must","","match","0"]`);
      }
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created_at","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created_at","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (FilterClicked) {
      if (
        customerName !== null ||
        account_name_value !== null ||
        sentimental_val !== null ||
        (date[0] !== null && date[1] !== null)
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: '[["created_on", "desc"]]',
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["created_on", "desc"]]',
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchLinkedIn(params);
    }
  };

  function fetchCustomerDataFunction(customerId) {
    let params = {
      filters: `[["_id","must","","match","${customerId}"]]`,
    };
    let result;
    props
      .dispatch(CustomerDataAction.requestCustomerData(params))
      .then((response) => {
        result =
          props.customerData.hits &&
          props.customerData.hits.hits &&
          props.customerData.hits.hits.length > 0 &&
          props.customerData.hits.hits[0]._source.customer_name;
      });
  }

  let account_names = [];

  if (
    props.linkedInAccount &&
    props.linkedInAccount.hits &&
    props.linkedInAccount.hits.hits.length > 0
  ) {
    props.linkedInAccount.hits.hits.map((item) => {
      account_names.push(
        item._source.account_name && item._source.account_name !== null
          ? item._source.account_name
          : ""
      );
    });
  }
  account_names = [...new Set(account_names)];

  let customer_name_list = [];
  if (
    props.linkedInFeedFilter &&
    props.linkedInFeedFilter.hits &&
    props.linkedInFeedFilter.hits.length > 0
  ) {
    props.linkedInFeedFilter.hits.map((item) => {
      customer_name_list.push(
        item._source.customer_name ? item._source.customer_name : ""
      );
    });
  }

  const uniqueCustomerList = [...new Set(customer_name_list)];

  let maxDate = new Date();

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);

    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number"
    ) {
      if (item._source.sentiment_value > 0) {
        sentiment = "Positive";
      } else if (item._source.sentiment_value < 0) {
        sentiment = "Negative";
      } else if (item._source.sentiment_value !== null) {
        sentiment = "Neutral";
      }
    }

    return sentiment;
  };

  const CustomerHandleChange = (e, val) => {
    setCustomerName(val);
  };

  return (
    <Box mt={1}>
      {/* *******************************************************Filters */}
      <Grid container>
        {/*LinkedIn filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            // borderRadius: theme.palette.buttonComponent.borderRadius.large,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            mt={2}
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Account Name</p>
                </Grid>
                <AutoCompleteComponent
                  // ***********************************************************Rating Filter
                  value={account_name_value}
                  list={account_names}
                  textFieldProps={{
                    placeholder: "Account Name",
                  }}
                  handleChange={AccountNameHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Customer Name</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={customerName}
                  list={uniqueCustomerList}
                  textFieldProps={{
                    placeholder: "Customer Name",
                  }}
                  handleChange={CustomerHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={2}>
                  <span>Search Content</span>
                </Grid>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      borderRadius:
                        theme.palette.buttonComponent.borderRadius.medium,
                      outline: `1px ${theme.palette.borderColor.textField}`,
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.typography.padding,
                      height: "35px",
                      width: "100%",
                    }}
                    id="search_bar"
                    autoComplete={"off"}
                    value={searchQuery}
                    placeholder={"Search by Content"}
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment>
                        <IconButton onClick={applyFilter}>
                          <SearchIcon
                            sx={{
                              "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>
              <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentiment value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid item xl={2} lg={3} md={12} sm={12} mt={1}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={2.5}
                >
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      width="100%"
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid item xl={3.5} lg={6} md={3} sm={5}>
                    <MuiButton
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      width="100%"
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <h5 className="pl-3 pr-3 pt-2"></h5> */}

        {/* <Divider sx={{ borderColor: theme.palette.background.button }} /> */}
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
      </Grid>
      {/* ****************************************************** Divider */}

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
          // className="bg-primary"
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Feeds</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid>

          {props.linkedInFeeds.hits ? (
            <LoadingIndicator isActive={isRequesting}>
              {props.linkedInFeeds.hits.length !== 0 ? (
                props.linkedInFeeds.hits.map((item, key) => (
                  //   !isRequesting &&
                  <Box
                    sx={{
                      padding: 0,
                      margin: "1.2%",
                      marginTop: 0,
                      marginBottom: "0.5%",
                    }}
                    // className="bg-warning"
                  >
                    <List
                      sx={{
                        width: "100%",
                        cursor:
                          item && item._source.ticket_id ? "pointer" : null,
                      }}
                      onClick={() => {
                        localStorage.setItem("activeTab", 0);

                        item._source &&
                          item._source.ticket_id &&
                          openTicketFunction(item._source.ticket_id);
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          backgroundColor: "#fff",
                          height: "auto",
                          padding: 2,
                          borderRadius: theme.borderRadius,
                          border: "solid 0.5px #d3e2e6",
                          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                        }}
                      >
                        <Grid container lg={12} md={12} sm={12}>
                          <Grid
                            item
                            lg={1}
                            md={2}
                            sm={2}
                            maxWidth={"80px !important"}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    backgroundColor: "skyblue",
                                  }}
                                  src={
                                    item._source.comment_image_url &&
                                    item._source.comment_image_url
                                  }
                                >
                                  {item._source.comment_image_url &&
                                    item._source.comment_image_url.charAt(0)}
                                </Avatar>
                              </ListItemAvatar>
                            </ListItem>
                          </Grid>
                          <Grid item lg={10} md={9} sm={9} container>
                            <Grid item lg={12} md={12} sm={12} container>
                              <Grid item lg={6} md={12} sm={12}>
                                <ListItem>
                                  <h6 className="p-0 m-0">
                                    {/* {item._source.customer_id &&
                                        fetchCustomerDataFunction(
                                          item._source.customer_id
                                        )} */}
                                    {item._source.customer_name}
                                  </h6>
                                  <p className="p-0 m-0 ml-2">
                                    {" "}
                                    {(function createdDate() {
                                      let created = item._source.created_at;
                                      let dateConversion = new Date(created);
                                      dateConversion =
                                        dateConversion.toDateString() +
                                        ", " +
                                        dateConversion.toLocaleTimeString(
                                          "en-US",
                                          {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          }
                                        );

                                      return dateConversion;
                                    })()}
                                  </p>
                                </ListItem>
                              </Grid>
                              {/* <Grid item lg={3} md={12} sm={12}>
                                      <ListItem>
                                        <h6>Tweet Type :</h6>&nbsp;
                                        {item._source.tweet_type}
                                      </ListItem>
                                    </Grid>
                                    <Grid item lg={3} md={12} sm={12}>
                                      <ListItem>
                                        <h6>Parent Post :</h6>&nbsp;
                                        {item._source.parent_post}
                                      </ListItem>
                                    </Grid> */}
                            </Grid>

                            <Grid container item lg={12} md={12} sm={12}>
                              <Grid item lg={4} md={4} sm={5}>
                                <ListItem>
                                  <Typography>
                                    <b
                                      style={{
                                        fontSize:
                                          theme.palette.buttonComponent.fontSize
                                            .extraLarge,
                                        lineHeight: "22px",
                                      }}
                                    >
                                      Account name :
                                    </b>
                                    &nbsp;
                                    {item._source.account_name}
                                  </Typography>
                                </ListItem>
                              </Grid>
                              <Grid item lg={6} md={6} sm={6}>
                                <Typography>
                                  <b
                                    style={{
                                      fontSize:
                                        theme.palette.buttonComponent.fontSize
                                          .extraLarge,
                                      lineHeight: "22px",
                                    }}
                                  >
                                    Activities :
                                  </b>
                                  &nbsp;
                                  {item._source.activities}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12}>
                              <ListItem>
                                <Typography>
                                  <b
                                    style={{
                                      fontSize:
                                        theme.palette.buttonComponent.fontSize
                                          .extraLarge,
                                      lineHeight: "22px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {item._source.activities === "Comment"
                                      ? "Comment Content :"
                                      : "Post Content :"}
                                  </b>
                                  &nbsp;
                                  {item._source.activities === "Comment"
                                    ? item._source.comment_content &&
                                      item._source.comment_content
                                        .replace(/&lt;/g, "<")
                                        .replace(/&gt;/g, ">")
                                        .replace(/&amp;/g, "&")
                                    : item._source.post_content &&
                                      item._source.post_content
                                        .replace(/&lt;/g, "<")
                                        .replace(/&gt;/g, ">")
                                        .replace(/&amp;/g, "&")}
                                </Typography>
                              </ListItem>
                            </Grid>
                            {item._source.sentiment_value !== null ? (
                              <Grid item lg={12} md={12} sm={12}>
                                <ListItem>
                                  <Typography>
                                    <b
                                      style={{
                                        fontSize:
                                          theme.palette.buttonComponent.fontSize
                                            .extraLarge,
                                        lineHeight: "22px",
                                      }}
                                    >
                                      Sentiment value :
                                    </b>
                                    &nbsp;
                                    {item._source.sentiment_value &&
                                    typeof item._source.sentiment_value ===
                                      "number" &&
                                    item._source.sentiment_value > 0 ? (
                                      <IconButton
                                        className="p-0 m-0"
                                        title={returnSentimentname(item)}
                                      >
                                        <SentimentSatisfiedAltRoundedIcon
                                          titleAccess="Positive"
                                          style={{
                                            width: "16px",
                                            color: `green`,
                                          }}
                                        />
                                      </IconButton>
                                    ) : item._source.sentiment_value < 0 ? (
                                      <IconButton
                                        className="p-0 m-0"
                                        title={returnSentimentname(item)}
                                      >
                                        <SentimentDissatisfiedRoundedIcon
                                          titleAccess="Negative"
                                          style={{
                                            width: "16px",
                                            color: `${theme.palette.sentimentIconColor.negative}`,
                                          }}
                                        />
                                      </IconButton>
                                    ) : item._source.sentiment_value !==
                                      null ? (
                                      <IconButton
                                        className="p-0 m-0"
                                        title={returnSentimentname(item)}
                                      >
                                        <SentimentNeutralRoundedIcon
                                          titleAccess="Neutral"
                                          style={{
                                            width: "16px",
                                            color: `${theme.palette.sentimentIconColor.neutral}`,
                                          }}
                                        />
                                      </IconButton>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </ListItem>
                              </Grid>
                            ) : null}
                          </Grid>

                          <Grid
                            item
                            container
                            lg={1}
                            md={2}
                            sm={2}
                            alignItems={"flex-start"}
                            justifyContent="space-between"
                          >
                            {item._source.feed_link && (
                              <Grid item lg={1} xl={1} md={1} sm={1}>
                                <Typography
                                  component={"a"}
                                  href={
                                    item._source.feed_link
                                      ? item._source.feed_link
                                      : ""
                                  }
                                  target="_blank"
                                  title="Feed Link"
                                >
                                  <OpenInNewIcon
                                    fontSize="25px"
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Typography>
                              </Grid>
                            )}

                            {circuler_progress_id === item._source.ticket_id &&
                              open_ticket && (
                                <CircularProgress
                                  color="inherit"
                                  size={15}
                                  // className="bg-light"
                                />
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </List>
                  </Box>
                ))
              ) : (
                <NoDataFound />
              )}
            </LoadingIndicator>
          ) : null}
        </Box>
      </Grid>
      {props.linkedInFeeds.hits
        ? props.linkedInFeeds.hits.length !== 0 &&
          !isRequesting && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChangePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(LinkedInListView);
