import { BaseModel } from "sjs-base-model";
import LinksDataHitsModel from "./LinksDataHitsModel";
import LinksShardsModel from "./LinksShardsModel";

// WebsiteLinksModel
export default class WebsiteLinksModel extends BaseModel {
    // response getting from the api mentioned end point
    _shards = { _shards: LinksShardsModel };
    current_page_no = 0;
    hits = { hits: LinksDataHitsModel };
    total_page_no = 0;
    timed_out = false;
    took = 0;
    constructor(data1) {
        super();

        this.update(data1);
    }
}
