import environment from "environment";
import RedditAccountGetModel from "./models/RedditAccountGetModel";
import RedditAccountPutModel from "./models/RedditAccountPutModel";
import RedditAccountPostModel from "./models/RedditAccountPostModel";
import RedditAccountDeleteModel from "./models/RedditAccountDeleteModel";
import EffectUtility from "../../../../utilities/EffectUtility";

export default class RedditAccountEffect {
  // Get method for the reddit account account
  static async requestRedditAccount(params) {
    const endpoint = environment.api.redditAccount;
    return EffectUtility.getToModel(RedditAccountGetModel, endpoint, params);
  }

  // Get method for the reddit account account filter
  static async requestRedditAccountFilter(params) {
    const endpoint = environment.api.redditAccount;
    return EffectUtility.getToModel(RedditAccountGetModel, endpoint, params);
  }

  // put method for the reddit account account filter
  static async requestPutRedditAccount(data, id) {
    const endpoint = environment.api.redditAccount + "/" + id;
    return EffectUtility.putToModel(RedditAccountPutModel, endpoint, data);
  }

  // post method for the reddit account account filter
  static async requestPostRedditAccount(data) {
    const endpoint = environment.api.redditAccount + "/add";

    return EffectUtility.postToModel(RedditAccountPostModel, endpoint, data);
  }

  static async requestDeleteRedditAccount(id) {
    const endpoint = environment.api.redditAccount + "/" + id;

    return EffectUtility.deleteToModel(RedditAccountDeleteModel, endpoint);
  }
}
