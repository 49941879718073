import BaseReducer from "../../../../utilities/BaseReducer";
import HssDetailsAction from "./HssDetailsAction";

export default class HssDetailsShadowReduser extends BaseReducer {
  initialState = {
    websweepXHssDetailsShadow: [],
  };
  [HssDetailsAction.REQUEST_GET_HSS_DETAILS_SHADOW_FINISHED](state, action) {
    return {
      ...state,
      websweepXHssDetailsShadow: action.payload,
    };
  }
}
