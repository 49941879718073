import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

import { Form, Formik, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { format } from "date-fns";
import TicketsAction from "../../ormTicketing/stores/tickets/TicketsAction";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../components/muiButton/MuiButton";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const BulkAssign = (props) => {
  let initial = {};

  initial = {
    user: "",
    status: "",
    message: "",
  };

  const validationSchema = yup.object({
    user: yup.object().shape({}).required("User is Required").nullable(),
    status: yup.object().shape({}).required("Status is Required").nullable(),
  });

  let [filtered_final_data, setFiltered_final_data] = useState([]);

  useEffect(() => {
    setFiltered_final_data(props.selectedRows);
  }, [props.selectedRows]);

  if (props.open) {
    filtered_final_data =
      filtered_final_data.length > 0 &&
      filtered_final_data.map((item) => {
        return {
          _id: item._id ? item._id : "",
          _index: item._index,
          _score: item._score,
          _source: {
            assigned_to: item._source.assigned_to,
            status: item._source.status,
          },
        };
      });
  }

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        // validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};

          if (!(values.status || values.user)) {
            errors.message = "Either Status or User is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          let object = {
            data: filtered_final_data.map((item) => {
              return {
                _id: item._id,
                _index: item._index,
                _score: item._score,
                _source: {
                  assigned_to: {
                    user_id: values.user.user_id,
                    user_name: values.user.user_name,
                  },
                  status: values.status.status_id,
                },
              };
            }),
          };
          props
            .dispatch(
              TicketsAction.requestBulkPutTicket(JSON.stringify(object))
            )
            .then(() => {
              props.fetchFunction(values.status);
              props.onClose();
              setFiltered_final_data([]);
              props.setSelectedRows([]);
              setLoading(false);
              localStorage.getItem("selected_tickets_for_bulk_update") &&
                localStorage.removeItem("selected_tickets_for_bulk_update");
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <Box width="100%" mb={3}>
                  <Grid
                    container
                    xl={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={11} textAlign="center">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;B
                          </span>
                          ulk &nbsp;
                          <span className="span_first_letter">U</span>
                          pdate &nbsp;
                        </b>
                      </h3>
                    </Grid>
                    <Grid item xl={1} textAlign="right">
                      <div className="col-2 closebtn">
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                          onClick={() => {
                            props.onClose();
                          }}
                        ></i>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContentText>

              {/* <Box sx={{ width: "300px", margin: "auto", marginLeft: "140px" }}> */}
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xl={7} lg={7}>
                    <Field
                      name="user"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      disabled={props.edit ? true : false}
                      value={values.user ? values.user : null}
                      component={AutoCompleteMultiSelect}
                      options={props.userlist}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.user_name === value.user_name
                      }
                      getOptionLabel={(option) =>
                        option ? option.fullname : ""
                      }
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Users",
                      }}
                    />
                  </Grid>

                  <Grid item xl={7} lg={7}>
                    <Field
                      name="status"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      disabled={props.edit ? true : false}
                      value={values.status ? values.status : null}
                      component={AutoCompleteMultiSelect}
                      options={props.statusList}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.status_name === value.status_name
                      }
                      getOptionLabel={(option) => option.status_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Status",
                      }}
                    />
                  </Grid>
                </Grid>
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error"
                />
              </Grid>

              {/* </Box> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      type="submit"
                      name="Submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(BulkAssign);
