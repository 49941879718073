import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import UserAction from "../../ormTicketing/stores/user/UserAction";
import MuiButton from "../muiButton/MuiButton";
import MuiTextField from "../muiTextField/MuiTextField";
import environment from "environment";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SurveyUserAction from "../../aiplex_tools_frontend_modular/survey/stores/surveyUser/SurveyUserAction";
import ClientAction from "../../a3label_org_intranet/a3labels/stores/client/ClientAction";
import InfluencerUserAction from "../../aiplex_tools_frontend_modular/influencer/stores/influencerUser/InfluencerUserAction";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const ResetPassword = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const passwordButtonRef = React.useRef(null);
  const confirmPasswordButtonRef = React.useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  let initial = {};

  initial = {
    password: "",
    confirmPassword: "",
  };
  const [loading, setLoading] = useState(false);

  const onCloseFunction = () => {
    setLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        onCloseFunction();
      }}
      fullWidth
      maxWidth="sm"
    >
      {/* Scope of Formik starts here */}
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.password) {
            errors.password = "Password is required";
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = "Password is Required";
          }
          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let refresh_token = localStorage.getItem("Auth")
            ? JSON.parse(localStorage.getItem("Auth")).refresh_token
            : "";

          if (values.password === values.confirmPassword) {
            let Id = props.projectEditData ? props.projectEditData.id : "";
            let object = {
              user_id: Id,
              password: values.confirmPassword,
              temporary: false,
            };

            let ormObject = {
              user_id: Id,
              password: values.confirmPassword,
              temporary: false,
              refresh_token: refresh_token,
            };

            let a3Object = {
              user_id: Id,
              password: values.confirmPassword,
            };

            if (props.influencerUser) {
              props
                .dispatch(
                  InfluencerUserAction.requestAdminResetPasswordPut(
                    JSON.stringify(object)
                  )
                )
                .then(() => {
                  props.onClose();
                  setLoading(false);
                });
            } else if (props.surveyUser) {
              props
                .dispatch(
                  SurveyUserAction.requestAdminResetPasswordPut(
                    JSON.stringify(object)
                  )
                )
                .then(() => {
                  props.onClose();
                  setLoading(false);
                });
            } else if (props.A3User) {
              props
                .dispatch(
                  ClientAction.requestAdminResetPassword(
                    JSON.stringify(a3Object)
                  )
                )
                .then(() => {
                  props.onClose();
                  setLoading(false);
                });
            } else {
              props
                .dispatch(
                  UserAction.requestAdminResetPasswordPut(
                    JSON.stringify(ormObject)
                  )
                )
                .then(() => {
                  props.onClose();
                  setLoading(false);
                });
            }
          } else {
            alert("Password should be same");
            setLoading(false);
          }

          // formikHelpers.resetForm();
        }}
        validateOnBlur={true}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          // Form Tag from Formik
          <Form>
            {/* Content of the dialog Box  */}
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* text Content inside DialogBox  */}
              <DialogContentText sx={{ width: "100%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10 ">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R
                          </span>
                          eset &nbsp;
                          <span className="span_first_letter">P</span>
                          assword &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>
              {/* Form Filed inside Dialog Box  */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Password :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      as={MuiTextField}
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="password"
                      type={showPassword ? "password" : "text"}
                      InputProps={{
                        onKeyPress: (event) => {
                          const { key } = event;
                          if (key === "Enter") {
                            passwordButtonRef.current.click();
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                              sx={{ mr: 1 }}
                            >
                              {showPassword ? (
                                <VisibilityOff transform="scale(0.8)" />
                              ) : (
                                <Visibility transform="scale(0.8)" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Enter Password"
                      error={
                        Boolean(errors.password) && Boolean(touched.password)
                      }
                      helperText={Boolean(touched.password) && errors.password}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Confirm Password :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5} mt={2}>
                    <Field
                      as={MuiTextField}
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="confirmPassword"
                      type={showConfirmPassword ? "password" : "text"}
                      InputProps={{
                        onKeyPress: (event) => {
                          const { key } = event;
                          if (key === "Enter") {
                            confirmPasswordButtonRef.current.click();
                          }
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="small"
                              sx={{ mr: 1 }}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff transform="scale(0.8)" />
                              ) : (
                                <Visibility transform="scale(0.8)" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Confirm Password"
                      error={
                        Boolean(errors.confirmPassword) &&
                        Boolean(touched.confirmPassword)
                      }
                      helperText={
                        Boolean(touched.confirmPassword) &&
                        errors.confirmPassword
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            {/* Actions performed from form action buttons  */}
            <DialogActions className="m-1 my-4">
              {/* Calling ButtonComponent to perform form actions  */}
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    name={"Submit Password"}
                    type="submit"
                    loading={loading}
                    size="large"
                    width="100%"
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Reset"}
                    type="reset"
                    size="large"
                    width="100%"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(ResetPassword);
