import BaseReducer from "../../../../utilities/BaseReducer";
import ClaimRequestAction from "./ClaimRequestAction";

export default class ClaimRequestByIdReducer extends BaseReducer {
  initialState = {
    claimRequestById: [],
  };
  [ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      claimRequestById: action.payload,
    };
  }

  [ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      claimRequestById: action.payload,
    };
  }
}
