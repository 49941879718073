import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { theme } from "../../views/App";
import { prefixZero_for_singleDigit } from "../ticketPartials";

export const NivoRadialBarChart = ({ ...props }) => {
  // Data Format for reference :
  // [
  //   {
  //     "id": "Supermarket",
  //     "data": [
  //       {
  //         "x": "Vegetables",
  //         "y": 222
  //       },
  //       {
  //         "x": "Fruits",
  //         "y": 194
  //       },
  //       {
  //         "x": "Meat",
  //         "y": 260
  //       },
  //       {
  //         "x": "Fish",
  //         "y": 105
  //       }
  //     ]
  //   },]

  const layout = props.layout ? props.layout : "vertical"; // horizontal | vertical

  const customColors = props.colors
    ? props.colors
    : [
        `${theme.pieChart.color1}`,
        `${theme.pieChart.color2}`,
        `${theme.pieChart.color3}`,
        `${theme.pieChart.color4}`,
        `${theme.pieChart.color5}`,
        `${theme.pieChart.color6}`,
      ];

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";

    // Function to calculate the brightness of a color
    function calculateLuminance(hex) {
      const rgb = parseInt(hex.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return 0.299 * r + 0.587 * g + 0.114 * b;
    }

    do {
      color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    } while (calculateLuminance(color) < 50); // Adjust the brightness threshold

    return color;
  }

  const CustomTick = ({ x, y, tick }) => (
    <g transform={`translate(${x},${y})`}>
      <line stroke="blue" y2={5} />
      <text
        x={0}
        y={12}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "blue", fontSize: 12 }}
      >
        {tick}
      </text>
    </g>
  );

  return (
    <ResponsiveRadialBar
      data={props.chartData}
      colors={customColors}
      padding={0.4}
      cornerRadius={2}
      enableTracks={false}
      enableRadialGrid={false}
      enableCircularGrid={true}
      margin={
        props.margin
          ? props.margin
          : { top: 40, right: 120, bottom: 40, left: 40 }
      }
      radialAxisStart={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      endAngle={270}
      tooltip={
        props.tooltip
          ? props.tooltip
          : (point) => (
              <div
                style={{
                  padding: "4px 10px 4px 10px",
                  color: theme.palette.primary.main,
                  background: "white",
                  display: "flex",
                  alignItems: "center",
                  border: `1px solid ${theme.palette.common.grey}`,
                  borderRadius: "4px",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                }}
              >
                <div
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 8,
                    backgroundColor: point.bar.color,
                  }}
                ></div>
                {point.bar.data.x} - {point.bar.groupId}&nbsp;:
                <strong>{prefixZero_for_singleDigit(point.bar.data.y)}</strong>
              </div>
            )
      }
      circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
      // legends={[
      //   {
      //     anchor: "right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 80,
      //     translateY: 0,
      //     itemsSpacing: 6,
      //     itemDirection: "left-to-right",
      //     itemWidth: 100,
      //     itemHeight: 18,
      //     itemTextColor: "#999",
      //     symbolSize: 18,
      //     symbolShape: "square",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000",
      //         },
      //       },
      //     ],
      //   },
      // ]}
      legends={[]}
    />
  );
};
