import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import PlatformScheduleModel from "./model/PlatformScheduleModel";
import PlatformScheduleDeleteModel from "./model/PlatformScheduleDeleteModel";
import PlatformSchedulePostModel from "./model/PlatformSchedulePostModel";

// PlatformSchedulerEffect
export default class PlatformSchedulerEffect {
  // calling an API according to the platform model
  // Get method for the
  static async requestPlatformScheduling(params) {
    const endpoint = environment.api.scheduling;
    return EffectUtility.getToModel(PlatformScheduleModel, endpoint, params);
  }

  // Get method for the platform filter
  static async requestPlatformTaskScheduling(params) {
    const endpoint = environment.api.influencerPlatformScheduling;
    return EffectUtility.getToModel(
      PlatformSchedulePostModel,
      endpoint,
      params
    );
  }

  // delete method for the platform
  static async requestDeletePlatformScheduling(id) {
    const endpoint = environment.api.influencerPlatformScheduling + "/" + id;
    return EffectUtility.deleteToModel(PlatformScheduleDeleteModel, endpoint);
  }

  // update method for the platform
  static async requestUpdatePlatformScheduling(id) {
    const endpoint = environment.api.influencerPlatformScheduling + "/" + id;
    return EffectUtility.deleteToModel(PlatformScheduleDeleteModel, endpoint);
  }
}
