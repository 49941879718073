import environment from "environment";
import AiChatFeedGetModel from "./models/AiChatFeedGetModel";
import AiChatFeedPostModel from "./models/AiChatFeedPostModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";

export default class AiChatEffect {
  static async requestAIchatFeed(params) {
    const endpoint = environment.api.getOpenAiChat;
    return EffectUtility.getToModel(AiChatFeedGetModel, endpoint, params);
  }

  static async requestAIchatFeedFilter(params) {
    const endpoint = environment.api.getOpenAiChat;
    return EffectUtility.getToModel(AiChatFeedGetModel, endpoint, params);
  }

  static async requestPostAIchat(data) {
    const endpoint = environment.api.postOpenAiChat;
    return EffectUtility.postToModel(AiChatFeedPostModel, endpoint, data);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
