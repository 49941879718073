import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";
import * as echarts from "echarts";
function Nightingale(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getOption = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      left: "center",
      top: "bottom",
      data: ["FRT Positive", "FRT Negative", "FRT Neutral"],
    },

    series: [
      {
        name: "FRT Breach Status",
        type: "pie",
        radius: [20, 100],
        center: ["25%", "50%"],
        roseType: "radius",
        itemStyle: {
          borderRadius: 5,
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        data: [
          { value: 28, name: "Positive", itemStyle: { color: "#468C9D" } },
          { value: 40, name: "Negative", itemStyle: { color: "#F26D4E" } },
          { value: 33, name: "Neutral", itemStyle: { color: "#92C4D0" } },
        ],
      },
      {
        name: "TAT Breach Status",
        type: "pie",
        radius: [20, 100],
        center: ["75%", "50%"],
        roseType: "area",
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        itemStyle: {
          borderRadius: 5,
        },
        data: [
          { value: 40, name: "Positive", itemStyle: { color: "#468C9D" } },
          { value: 33, name: "Negative", itemStyle: { color: "#F26D4E" } },
          { value: 28, name: "Neutral", itemStyle: { color: "#92C4D0" } },
        ],
      },
    ],
  };
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default Nightingale;
