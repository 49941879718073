import { BaseModel } from "sjs-base-model";
import TagSourceModel from "./TagSourceModel";

export default class TagHitsDataModel extends BaseModel {
  //priority list hits data model
  _id = "";
  _index = "";
  _score = 0.0;
  _source = [TagSourceModel];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
