import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
// DataGridFunction Component
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import { createFilterOptions, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import SwitchComponent from "../../../components/switchComponent/SwitchComponent";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import UserMailMappingAction from "../../stores/userMailMapping/UserMailMappingAction";
import UserMailMappingPost from "./UserMailMappingPost";
import MailTiggerListAction from "../../stores/triggerLIst/MailTiggerListAction";
import UserAction from "../../../ormTicketing/stores/user/UserAction";

// mapstatetoprops function

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserMailMappingAction.REQUEST_USERMAIL_MAPPING,
    UserMailMappingAction.REQUEST_USERMAIL_MAPPING_FILTER,
    MailTiggerListAction.REQUEST_TRIGGERLIST_FILTER,
    UserAction.REQUEST_USER_FILTER,
  ]),
  userMailMapping: state.userMailMapping.userMailMapping.hits || [],
  pagination: state.userMailMapping.userMailMapping || [],
  userMailMappingFilter:
    state.userMailMappingFilter.userMailMappingFilter || [],
  trigger_list_filter: state.trigger_list_filter.trigger_list_filter || {},
  userfilter: state.userfilter.userfilter || [],
});

function UserMailMapping(props) {
  //******************************************************** SetState
  const [searchUser, setSearchUser] = useState("");
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const switchComponentHandleChange = (id, enabled_value) => {
    let obj = {
      subscribed: enabled_value === true ? false : true,
    };
    props
      .dispatch(
        UserMailMappingAction.requestPutUserMailMapping(JSON.stringify(obj), id)
      )
      .then(() => {
        const params = {
          page_limit: `${page_limit}`,
        };
        const param = {
          page_limit: "none",
        };
        props.dispatch(UserMailMappingAction.requestUserMailMapping(params));
        props.dispatch(
          UserMailMappingAction.requestUserMailMappingFilter(param)
        );
      });
  };

  // ***************************************************** Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "email_id",
      headerName: "User Mail ID",
      width: 250,
      flex: 3,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
    },
    {
      field: "trigger_id",
      headerName: "Trigger Name",
      width: 100,
      flex: 2,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "subscribed",
      headerName: "subscribed",
      width: 100,
      flex: 2,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => {
        return (
          <SwitchComponent
            id={params.row.id ? params.row.id : ""}
            checked={params.row.subscribed}
            onClick={() => {
              switchComponentHandleChange(params.row.id, params.row.subscribed);
            }}
          />
        );
      },
    },

    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Edit",
    //   width: 160,
    //   flex: 2,
    //   hife: true,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <IconButton
    //       title="Edit"
    //       onClick={(event) => {
    //         event.ignore = true;
    //         setEdit(true);
    //         setOpen(true);
    //         setEditRowsModel(params.row);
    //       }}
    //       sx={{ border: "0" }}
    //     >
    //       <FontAwesomeIcon
    //         icon={faPenToSquare}
    //         style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
    //         title="Edit"
    //       ></FontAwesomeIcon>
    //     </IconButton>
    //   ),
    // },
  ];

  const { isRequesting } = props;

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);
  // *********************************************  fetch methods

  function fetchUserMailMapping(params) {
    props.dispatch(UserMailMappingAction.requestUserMailMapping(params));
  }

  function fetchUserMailMappingFilter(param) {
    props.dispatch(UserMailMappingAction.requestUserMailMappingFilter(param));
  }

  function fetchTriggerListFilter() {
    const param = {
      order_by: `[["trigger_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(MailTiggerListAction.requestTriggerListFilter(param));
  }

  function fetchUserFilter(param) {
    props.dispatch(UserAction.requestUserFilter(param));
  }

  // **************************************************  UseEffect
  useEffect(() => {
    const param = {
      page_limit: "none",
    };

    // if (
    //   props?.userMailMappingFilter === undefined ||
    //   props?.userMailMappingFilter.length === 0
    // ) {
    fetchUserMailMappingFilter(param);
    // }

    // if (
    //   props?.trigger_list_filter === undefined ||
    //   props?.trigger_list_filter.length === 0
    // ) {
    fetchTriggerListFilter();
    // }

    fetchUserFilter();

    const filter = [];
    let params = {};

    if (searchUser !== "") {
      filter.push(
        `["email_id.keyword","must","","wildcard","*${searchUser.user_email}*"]`
      );
    }

    if (searchUser !== "") {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    }
    fetchUserMailMapping(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //********************************************************* pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    if (searchUser !== "") {
      filter.push(
        `["email_id.keyword","must","","wildcard","*${searchUser.user_email}*"]`
      );
    }
    if (searchUser !== "" && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchUserMailMapping(params);
    }
  };

  let triggerNameList = [];
  if (props.trigger_list_filter.hits) {
    props.trigger_list_filter.hits.hits.map((item) => {
      return triggerNameList.push({
        trigger_id: item._id,
        trigger_name: item._source.trigger_name,
      });
    });
  }

  // *******************************************************  handle Filter
  const handleFilter = () => {
    const filter = [];

    if (searchUser !== "") {
      filter.push(
        `["email_id.keyword","must","","wildcard","*${searchUser.user_email}*"]`
      );
    }

    if (searchUser !== "") {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchUserMailMapping(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];

  if (props.userMailMapping.hits) {
    props.userMailMapping.hits.map((item, index) => {
      return rows.push({
        sl_no: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        id: item._id,
        _index: item._index,
        _score: item._score,
        email_id: item._source.email_id,
        trigger_id: item._source.trigger?.trigger_name,
        trigger_id_obj: item._source.trigger,
        created_by: item._source.created_by.user_name,
        subscribed: item._source.subscribed,
      });
    });
  }

  // ****************************************** tag name handle change

  const onFilterUser = (event, value) => {
    setSearchUser(value);
  };

  const ClearState = () => {
    // Clear the state

    if (searchUser !== "") {
      setClearLoader(true);
      setSearchUser("");
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);
    const params = {
      page_limit: 15,
    };
    FilterClicked && fetchUserMailMapping(params);
  };

  let userList = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((item) => {
      return userList.push({
        user_id: item.id,
        user_email: item.email,
      });
    });
  }

  userList.sort((a, b) => {
    return a?.user_email?.localeCompare(b);
  });
  userList = [...new Set(userList)];

  return (
    <React.Fragment>
      {/* Tag post modal screen */}
      <Box>
        <UserMailMappingPost
          open={open}
          onSubmit={() => {}}
          onClose={() => setOpen(false)}
          userList={userList}
          triggerNameList={triggerNameList}
          projectEditData={editRowsModel}
          edit={edit}
        />

        {/*************************************************** Tag Filter */}

        <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.5} mt={1}>
              <AutoCompleteComponent
                value={searchUser}
                list={userList}
                textFieldProps={{
                  placeholder: "Select Mail ID",
                }}
                filterOptions={createFilterOptions({
                  matchFrom: "start",
                  stringify: (option) => option.user_email,
                })}
                isOptionEqualToValue={(option, value) =>
                  option.user_email === value.user_email
                }
                getOptionLabel={(option) =>
                  option.user_email ? option.user_email : ""
                }
                handleChange={onFilterUser}
                size="small"
                width="100%"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={5}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  sm={8}
                  md={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Filter"}
                      width="100%"
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  {/* post button component*/}
                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid item lg={8} md={12} sm={12} xs={12}>
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {props.userMailMapping.hits && !isRequesting ? (
                    <DataGridFunction
                      columns={columns}
                      rows={rows}
                      rowHeight={60}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingBgImage />
                    </Box>
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(UserMailMapping);
