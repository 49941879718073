import ActionUtility from "../../../../utilities/ActionUtility";
import HssCategoryEffect from "./HssCategoryEffect";

export default class HssCategoryAction {
  static REQUEST_GET_HSS_CATEGORY =
    "HssCategoryAction.REQUEST_GET_HSS_CATEGORY";
  static REQUEST_GET_HSS_CATEGORY_FINISHED =
    "HssCategoryAction.REQUEST_GET_HSS_CATEGORY_FINISHED";

  static REQUEST_HSS_CATEGORY_FILTER =
    "HssCategoryAction.REQUEST_HSS_CATEGORY_FILTER";
  static REQUEST_HSS_CATEGORY_FILTER_FINISHED =
    "HssCategoryAction.REQUEST_HSS_CATEGORY_FILTER_FINISHED";

  static REQUEST_POST_HSS_CATEGORY =
    "HssCategoryAction.REQUEST_POST_HSS_CATEGORY";
  static REQUEST_POST_HSS_CATEGORY_FINISHED =
    "HssCategoryAction.REQUEST_POST_HSS_CATEGORY_FINISHED";

  static REQUEST_PUT_HSS_CATEGORY =
    "HssCategoryAction.REQUEST_PUT_HSS_CATEGORY";
  static REQUEST_PUT_HSS_CATEGORY_FINISHED =
    "HssCategoryAction.REQUEST_PUT_HSS_CATEGORY_FINISHED";

  // METHODS
  static requestHssCategory(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssCategoryAction.REQUEST_GET_HSS_CATEGORY,
        HssCategoryEffect.requestHssCategory,
        params,
        callback
      );
    };
  }

  static requestHssCategoryFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssCategoryAction.REQUEST_HSS_CATEGORY_FILTER,
        HssCategoryEffect.requestHssCategoryFilter,
        params
      );
    };
  }

  static requestPostHssCategory(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssCategoryAction.REQUEST_POST_HSS_CATEGORY,
        HssCategoryEffect.requestPostHssCategory,
        data,
        callBackFunction
      );
    };
  }

  static requestPutHSSCategory(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssCategoryAction.REQUEST_HSS_CATEGORY_FILTER,
        HssCategoryEffect.requestPutHSSCategory,
        data,
        id
      );
    };
  }
}
