import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencerLanguageAction from "./InfluencerLanguageAction";

// InfluencersLanguageReduser
export default class InfluencersLanguageReduser extends BaseReducer {
  initialState = {
    influencersLanguage: [],
  };
  [InfluencerLanguageAction.REQUEST_INFLUENCERS_LANGUAGE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencersLanguage: action.payload,
    };
  }
}
