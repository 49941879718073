import ActionUtility from "../../../utilities/ActionUtility";
import IndeedFeedEffect from "./IndeedFeedEffect";

export default class IndeedFeedAction {
  static REQUEST_INDEED_FEEDS = "IndeedFeedAction.REQUEST_INDEED_FEEDS";
  static REQUEST_INDEED_FEEDS_FINISHED =
    "IndeedFeedAction.REQUEST_INDEED_FEEDS_FINISHED";

  static REQUEST_INDEED_FEEDS_CLEAR =
    "IndeedFeedAction.REQUEST_INDEED_FEEDS_CLEAR";
  static REQUEST_INDEED_FEEDS_CLEAR_FINISHED =
    "IndeedFeedAction.REQUEST_INDEED_FEEDS_CLEAR_FINISHED";

  static REQUEST_PUT_INDEED_FEEDS = "IndeedFeedAction.REQUEST_PUT_INDEED_FEEDS";
  static REQUEST_PUT_INDEED_FEEDS_FINISHED =
    "IndeedFeedAction.REQUEST_PUT_INDEED_FEEDS_FINISHED";

  // METHODS
  static requestIndeedFeed(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedFeedAction.REQUEST_INDEED_FEEDS,
        IndeedFeedEffect.requestIndeedFeed,
        params,
        callBackFunction
      );
    };
  }

  static requestIndeedFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedFeedAction.REQUEST_INDEED_FEEDS_CLEAR,
        IndeedFeedEffect.requestIndeedFeedClear
      );
    };
  }

  static requestPutIndeedFeed(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        IndeedFeedAction.REQUEST_PUT_INDEED_FEEDS,
        IndeedFeedEffect.requestPutIndeedFeed,
        data,
        id
      );
    };
  }
}
