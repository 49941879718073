import BaseReducer from "../../../utilities/BaseReducer";
import AppStatusAction from "./AppStatusAction";

// reducer function
export default class AppStatusReducer extends BaseReducer {
  initialState = {
    appStatus: [],
  };

  [AppStatusAction.REQUEST_APP_STATUS_FINISHED](state, action) {
    return {
      ...state,
      appStatus: action.payload,
    };
  }
}
