import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import WhitelistedSitesGetModal from "./modals/WhitelistedSitesGetModal";
import WhitelistedSitesPutModal from "./modals/WhitelistedSitesPutModal";

export default class WhitelistedSitesEffect {
  static async requestWhitelistedSites(params, callback) {
    const endpoint = environment.api.websweepXWhitelistedSites;
    let response = EffectUtility.getToModel(
      WhitelistedSitesGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestWhitelistedSitesFilter(params) {
    const endpoint = environment.api.websweepXWhitelistedSites;
    return EffectUtility.getToModel(WhitelistedSitesGetModal, endpoint, params);
  }

  static async requestPostWhitelistedSites(data, callBackFunction) {
    const endpoint = environment.api.websweepXWhitelistedSites + "/add";

    let response = EffectUtility.postToModel(
      WhitelistedSitesPutModal,
      endpoint,
      data
    );

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestPutWhitelistedSites(data, id) {
    const endpoint = environment.api.websweepXWhitelistedSites + "/" + id;
    return EffectUtility.putToModel(WhitelistedSitesPutModal, endpoint, data);
  }
}
