import { BaseModel } from "sjs-base-model";
import AmbitionBoxFeedsDataModel from "./AmbitionBoxFeedsDataModel";

// AmbitionBoxFeedsDataHitsmodel function
export default class AmbitionBoxFeedsDataHitsmodel extends BaseModel {
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [AmbitionBoxFeedsDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
