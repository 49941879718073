import BaseReducer from "../../../utilities/BaseReducer";
import UnRetweetAction from "./UnRetweetAction";

// UnRetweetReduser function
export default class UnRetweetReduser extends BaseReducer {
  initialState = {
    unretweet: [],
  };

  [UnRetweetAction.REQUEST_UNRETWEET_FINISHED](state, action) {
    return {
      ...state,
      unretweet: action.payload,
    };
  }
}
