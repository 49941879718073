import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import TicketFirstResponseTimeModel from "./models/TicketFirstResponseTimeModel";

export default class TicketFirstResponseTimeEffect {
  //get ticket first response time request end point
  static async requestTicketFirstResponseTime(params) {
    const endpoint = environment.api.ticketFirstResponseTime;

    return EffectUtility.getToModel(
      TicketFirstResponseTimeModel,
      endpoint,
      params
    );
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
