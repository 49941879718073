import BaseReducer from "../../../../utilities/BaseReducer";
import FingerPrintAssetAction from "./FingerPrintAssetAction";

export default class FinegrPrintAssetFilterReducer extends BaseReducer {
  initialState = {
    fingerPrintAssetFilter: [],
  };
  [FingerPrintAssetAction.REQUEST_GET_ASSET_FILTER_FINISHED](state, action) {
    return {
      ...state,
      fingerPrintAssetFilter: action.payload,
    };
  }
}
