import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// InfluencersDataModel
export default class InfluencersDataModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;
    // response getting from the api
    // id = v4();
    // created_by = {
    //     user_id: "",
    //     user_name: "",
    // };
    // created_on = "";
    // modified_by = {
    //     user_id: "",
    //     user_name: "",
    // };
    // modified_on = "";
    // organization_name = "";

    constructor(data) {
        super();

        this.update(data);
    }
}
