import BaseReducer from "../../../utilities/BaseReducer";
import CloudSegmentCriteriaAction from "./CloudSegmentCriteriaAction";

// segment criteria reducer
export default class CloudSegmentCriteriaReducer extends BaseReducer {
  initialState = {
    segmentCriteria: [],
  };
  [CloudSegmentCriteriaAction.REQUEST_CLOUD_SEGMENT_CRITERIA_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      segmentCriteria: action.payload,
    };
  }
}
