import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import OrmPagesModel from "./models/OrmPagesModel";

export default class OrmPagesEffect {
  static async requestOrmPages(params) {
    const endpoint = environment.api.ormPages;

    return EffectUtility.getToModel(OrmPagesModel, endpoint, params);
  }
}
