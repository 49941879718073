import BaseReducer from "../../../utilities/BaseReducer";
import GmbReviewAction from "./GmbReviewAction";

// GmbReviewFeedsReduser  function
export default class GmbReviewFeedsReduser extends BaseReducer {
  initialState = {
    GMBReviewFeeds: [],
  };
  [GmbReviewAction.REQUEST_GMB_REVIEW_FEED_FINISHED](state, action) {
    return {
      ...state,
      GMBReviewFeeds: action.payload,
    };
  }

  [GmbReviewAction.REQUEST_GMB_REVIEW_FEED_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      GMBReviewFeeds: action.payload,
    };
  }
}
