import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import DeviceResetDeleteModel from "./models/DeviceResetModel";
import DeviceMapGetModel from "./models/DeviceMapGetModel";

export default class DeviceResetEffect {
  //get request endpoin for mobile device
  static async requestDeviceMapping(params) {
    const endpoint = environment.api.influencerDeviceMapping;
    return EffectUtility.getToModel(DeviceMapGetModel, endpoint, params);
  }

  //delete request endpoint for mobile device
  static async requestDeviceMapDelete(id) {
    const endpoint = environment.api.influencerDeviceMapping + "/" + id;
    return EffectUtility.deleteToModel(DeviceResetDeleteModel, endpoint);
  }
}
