import ActionUtility from "../../../../utilities/ActionUtility";
import InfluencerPlatformEffect from "./InfluencerPlatformEffect";

export default class InfluencerPlatformAction {
  static REQUEST_INFLUENCER_PLATFORM =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM";
  static REQUEST_INFLUENCER_PLATFORM_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM_FINISHED";

  // platform filter
  static REQUEST_INFLUENCER_PLATFORM_FILTER =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM_FILTER";
  static REQUEST_INFLUENCER_PLATFORM_FILTER_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM_FILTER_FINISHED";

  // facebook platform
  static REQUEST_INFLUENCER_FACEBOOKPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_FACEBOOKPLATFORM_POST";
  static REQUEST_INFLUENCER_FACEBOOKPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_FACEBOOKPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_FACEBOOKPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_FACEBOOKPLATFORM_PUT";
  static REQUEST_INFLUENCER_FACEBOOKPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_FACEBOOKPLATFORM_PUT_FINISHED";

  //instagram platform
  static REQUEST_INFLUENCER_INSTAGRAMPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_INSTAGRAMPLATFORM_POST";
  static REQUEST_INFLUENCER_INSTAGRAMPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_INSTAGRAMPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_INSTAGRAMPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_INSTAGRAMPLATFORM_PUT";
  static REQUEST_INFLUENCER_INSTAGRAMPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_INSTAGRAMPLATFORM_PUT_FINISHED";

  // youtube platform
  static REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST";
  static REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_YOUTUBEPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_PUT";
  static REQUEST_INFLUENCER_YOUTUBEPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_PUT_FINISHED";

  //quora platform

  static REQUEST_INFLUENCER_QUORAPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_QUORAPLATFORM_POST";
  static REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_QUORAPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_QUORAPLATFORM_PUT";
  static REQUEST_INFLUENCER_QUORAPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_QUORAPLATFORM_PUT_FINISHED";

  // playstore platform

  static REQUEST_INFLUENCER_PLAYSTOREPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLAYSTOREPLATFORM_POST";
  static REQUEST_INFLUENCER_PLAYSTOREPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLAYSTOREPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_PLAYSTOREPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLAYSTOREPLATFORM_PUT";
  static REQUEST_INFLUENCER_PLAYSTOREPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_PLAYSTOREPLATFORM_PUT_FINISHED";

  // twitter platform

  static REQUEST_INFLUENCER_TWITTERPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TWITTERPLATFORM_POST";
  static REQUEST_INFLUENCER_TWITTERPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TWITTERPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_TWITTERLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TWITTERLATFORM_PUT";
  static REQUEST_INFLUENCER_TWITTERLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TWITTERLATFORM_PUT_FINISHED";

  // whatspaa platform

  static REQUEST_INFLUENCER_WHATSAPPPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_WHATSAPPPLATFORM_POST";
  static REQUEST_INFLUENCER_WHATSAPPPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TWITTERPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_WHATSAPPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_WHATSAPPLATFORM_PUT";
  static REQUEST_INFLUENCER_WHATSAPPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_WHATSAPPLATFORM_PUT_FINISHED";

  // Ambition Box platform

  static REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_POST";
  static REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT";
  static REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT_FINISHED";

  // Appstore platform

  static REQUEST_INFLUENCER_APPSTOREPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_APPSTOREPLATFORM_POST";
  static REQUEST_INFLUENCER_APPSTOREPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_APPSTOREPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_APPSTOREPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT";
  static REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT_FINISHED";

  // Glassdoor platform

  static REQUEST_INFLUENCER_GLASSDOORPLATFORM_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_GLASSDOORPLATFORM_POST";
  static REQUEST_INFLUENCER_GLASSDOORPLATFORM_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_GLASSDOORPLATFORM_POST_FINISHED";

  static REQUEST_INFLUENCER_GLASSDOORPLATFORM_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_GLASSDOORPLATFORM_PUT";
  static REQUEST_INFLUENCER_GLASSDOORPLATFORM_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_GLASSDOORPLATFORM_PUT_FINISHED";

  // TELEGRAM ACCOUNT
  static REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_POST";
  static REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_POST_FINISHED";

  static REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_PUT";
  static REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_PUT_FINISHED";

  // EMAIL Account

  static REQUEST_INFLUENCER_EMAIL_ACCOUNT_POST =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_EMAIL_ACCOUNT_POST";
  static REQUEST_INFLUENCER_EMAIL_ACCOUNT_POST_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_EMAIL_ACCOUNT_POST_FINISHED";

  static REQUEST_INFLUENCER_EMAIL_ACCOUNT_PUT =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_EMAIL_ACCOUNT_PUT";
  static REQUEST_INFLUENCER_EMAIL_ACCOUNT_PUT_FINISHED =
    "InfluencerPlatformAction.REQUEST_INFLUENCER_EMAIL_ACCOUNT_PUT_FINISHED";

  static requestInfluencerPlatforms(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM,
        InfluencerPlatformEffect.requestInfluencerPlatforms,
        params
      );
    };
  }

  static requestInfluencerPlatformsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM_FILTER,
        InfluencerPlatformEffect.requestInfluencerPlatformsFilter,
        params
      );
    };
  }

  static requestPostInfluencerFacebookAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_FACEBOOKPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerFacebookAccount,
        data
      );
    };
  }

  static requestPostInfluencerInstagramAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_INSTAGRAMPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerInstagramAccount,
        data
      );
    };
  }

  static requestPostInfluencerYoutubeAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerYoutubeAccount,
        data
      );
    };
  }

  static requestPutInfluencerFacebookAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_FACEBOOKPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerFacebookAccount,
        data,
        id
      );
    };
  }

  static requestPutInfluencerInstagramAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_INSTAGRAMPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerInstagramAccount,
        data,
        id
      );
    };
  }

  static requestPutInfluencerYoutubeAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerYoutubeAccount,
        data,
        id
      );
    };
  }

  static requestPostInfluencerQuoraAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_YOUTUBEPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerQuoraAccount,
        data
      );
    };
  }

  static requestPutInfluencerQuoraAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_QUORAPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerQuoraAccount,
        data,
        id
      );
    };
  }

  //playstore platform
  static requestPostInfluencerPlaystoreAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_PLAYSTOREPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerPlaystoreAccount,
        data
      );
    };
  }

  static requestPutInfluencerPlaystoreAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_PLAYSTOREPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerPlaystoreAccount,
        data,
        id
      );
    };
  }

  //twitter platform
  static requestPostInfluencerTwitterAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_TWITTERPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerTwitterAccount,
        data
      );
    };
  }

  static requestPutInfluencerTwitterAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_TWITTERLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerTwitterAccount,
        data,
        id
      );
    };
  }

  //whatsapp platform
  static requestPostInfluencerWhatsappAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_WHATSAPPPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerWhatsappAccount,
        data
      );
    };
  }

  static requestPutInfluencerWhatsappAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_WHATSAPPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerWhatsappAccount,
        data,
        id
      );
    };
  }

  //AmbitionBox platform
  static requestPostInfluencerAmbitionBoxAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerAmbitionBoxAccount,
        data
      );
    };
  }

  static requestPutInfluencerAmbitionBoxAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_AMBITIONBOXPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerAmbitionBoxAccount,
        data,
        id
      );
    };
  }

  //Appstore platform
  static requestPostInfluencerAppstoreAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_APPSTOREPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerAppstoreAccount,
        data
      );
    };
  }

  static requestPutInfluencerAppstoreAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_APPSTOREPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerAppstoreAccount,
        data,
        id
      );
    };
  }

  //glassdoor platform
  static requestPostInfluencerGlassdoorAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_GLASSDOORPLATFORM_POST,
        InfluencerPlatformEffect.requestPostInfluencerGlassdoorAccount,
        data
      );
    };
  }

  static requestPutInfluencerGlassdoorAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_GLASSDOORPLATFORM_PUT,
        InfluencerPlatformEffect.requestPutInfluencerGlassdoorAccount,
        data,
        id
      );
    };
  }

  // telegram platform

  static requestPostInfluencerTelegramAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_POST,
        InfluencerPlatformEffect.requestPostInfluencerTelegramAccount,
        data
      );
    };
  }

  static requestPutInfluencerTelegramAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_TELEGRAM_ACCOUNT_PUT,
        InfluencerPlatformEffect.requestPutInfluencerTelegramAccount,
        data,
        id
      );
    };
  }

  // email  platform

  static requestPostInfluencerEmailAaccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_EMAIL_ACCOUNT_POST,
        InfluencerPlatformEffect.requestPostInfluencerEmailAaccount,
        data
      );
    };
  }

  static requestPutInfluencerEmailAaccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerPlatformAction.REQUEST_INFLUENCER_EMAIL_ACCOUNT_PUT,
        InfluencerPlatformEffect.requestPutInfluencerEmailAaccount,
        data,
        id
      );
    };
  }
}
