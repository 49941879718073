import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencersActivityAction from "./InfluencersActivityAction";

// InfluencersActivityReduser
export default class InfluencersActivityReduser extends BaseReducer {
  initialState = {
    influencersActivity: [],
  };
  [InfluencersActivityAction.REQUEST_INFLUENCERS_ACTIVITY_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencersActivity: action.payload,
    };
  }
}
