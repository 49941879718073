import React, { useState, useEffect } from "react";
import { ShadowContainer } from "../../../components/componentLayout/ComponentLayout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearIcon from "@mui/icons-material/Clear";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import UserAction from "../../../ormTicketing/stores/user/UserAction";
import UserPermissionAction from "../../stores/userPermission/UserPermissionAction";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { FullName } from "../../../components/ReusedFunctions";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../components/muiButton/MuiButton";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserAction.REQUEST_USER_FILTER,
    UserPermissionAction.REQUEST_USER_PERSMISSION,
  ]),
  userfilter: state.userfilter.userfilter || [],
  userPermission: state.userPermission.userPermission || [],
  pagination_user: state.userPermission.userPermission || {},
});

const AddUserPermission = (props) => {
  let orgId = props.projectProps
    ? props.projectProps.organization_id &&
      props.projectProps.organization_id.organization_id
    : "";

  function fetchUserFilter(param) {
    props.dispatch(UserAction.requestUserFilter(param));
  }

  const [page_limit] = useState(10);

  useEffect(() => {
    const param = { page_limit: "none" };
    fetchUserFilter(param);
  }, []);

  if (props.render) {
    let filter = [];

    filter.push(
      `["resource_id.keyword","must","","term","${props.projectProps.project_unique_id}"]`
    );
    let params_user = {
      order_by: '[["created_on", "desc"]]',
      filters: `[${filter}]`,
      page_number: 0,
      page_limit: 10,
    };
    props.dispatch(UserPermissionAction.requestUserPermission(params_user));
    props.onCloseRender();
  }

  let userlist = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((item) => {
      return userlist.push({
        user_id: item.id,
        user_name: item.username,
        org_id: item.attributes.organization_id
          ? item.attributes.organization_id[0]
          : "",
        fullName: FullName(item),
      });
    });
  }

  const handleInfluencerRemove = (row) => {
    props
      .dispatch(
        UserPermissionAction.requestDeleteUserPermission(row.user_unique_id)
      )
      .then(() => {
        let filter = [];

        filter.push(
          `["resource_id.keyword","must","","term","${props.projectProps.project_unique_id}"]`
        );
        let params_user = {
          order_by: '[["created_on", "desc"]]',
          filters: `[${filter}]`,

          page_limit: 10,
        };
        props.dispatch(UserPermissionAction.requestUserPermission(params_user));
      });
  };

  // columns for datagrid
  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 120,
      resizable: false,
      flex: 2,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "user_unique_id",
      headerName: "User ID",
      // width: 200,
      hide: true,
    },

    {
      field: "user_name",
      headerName: "User Name",
      width: 320,
      flex: 4,
    },

    {
      field: "fullName",
      headerName: "Full Name",
      width: 200,
      flex: 4,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Remove",
      resizable: false,
      width: 160,
      flex: 2,
      cellClassName: "actions",
      align: "center",
      renderCell: (params) => (
        <IconButton
          title="Remove"
          onClick={(event) => {
            event.ignore = true;
            handleInfluencerRemove(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  function username_function(id) {
    let user_name = userlist.filter((val) => {
      return val.user_id === id ? id : "";
    });
    return user_name[0] ? user_name[0].user_name : "";
  }

  function fullName_function(id) {
    let user_name = userlist.filter((val) => {
      return val.user_id === id ? id : "";
    });
    return user_name[0] ? user_name[0].fullName : "";
  }

  const rows = [];

  function FullNameFunction(value) {
    return value._source?.user?.firstname + " " + value._source?.user?.lastname;
  }

  if (props.userPermission.hits) {
    props.userPermission.hits.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination_user.current_page_no * page_limit + ++index
          : "",
        user_unique_id: item._id,
        user_name: item?._source?.user?.username,
        fullName: FullNameFunction(item),
      });
    });
  }

  const user_initial = {
    user: "",
    resource: "project",
    resource_id: props.projectProps.project_unique_id,
  };

  // pagination
  const handleChange = (event, value) => {
    // page_number=1&page_limit=10
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];

    filter.push(
      `["resource_id.keyword","must","","term","${props.projectProps.project_unique_id}"]`
    );

    let params_user = {
      order_by: '[["created_on", "desc"]]',
      filters: `[${filter}]`,
      page_number: `${value}` - 1,
      page_limit: `${page_limit}`,
    };

    props.dispatch(UserPermissionAction.requestUserPermission(params_user));
  };

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <ShadowContainer>
        <DialogContent
        // className="bg-warning"
        >
          <DialogContentText
            sx={{ width: "100%" }}
            // className="bg-success"
          >
            <div className="text-center pb-2 ">
              <div className="row p-3">
                <div className="col-10 ">
                  <h3 className="dailogtext">
                    <b>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="span_first_letter">U</span>ser &nbsp;
                      <span className="span_first_letter">M</span>apping&nbsp;
                      <span className="span_first_letter">T</span>o&nbsp;
                      <span className="span_first_letter">P</span>roject -
                      &nbsp;
                      {props.projectProps.project_name}
                    </b>
                  </h3>
                </div>
                <div className="col-2 closebtn">
                  <i
                    title="Close"
                    className="fa fa-times-circle-o fa-2x"
                    style={{ size: "15px" }}
                    aria-hidden="true"
                    onClick={() => {
                      props.onClose();
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </DialogContentText>

          <Formik
            initialValues={user_initial}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange
            onSubmit={(values, formikHelpers) => {
              setLoading(true);
              if (values.user.user_id != null) {
                let obj = {
                  user_id: values.user.user_id,
                  resource: values.resource,
                  resource_id: values.resource_id,
                };
                let filter = [];
                filter.push(
                  `["resource_id.keyword","must","","term","${values.resource_id}"]`
                );
                let params_user = {
                  order_by: '[["created_on", "desc"]]',
                  filters: `[${filter}]`,
                  page_number: 0,
                  page_limit: 10,
                };
                props
                  .dispatch(UserPermissionAction.requestPostUserPermission(obj))
                  .then(() => {
                    formikHelpers.resetForm();
                    props.dispatch(
                      UserPermissionAction.requestUserPermission(params_user)
                    );
                    setLoading(false);
                  });
              } else {
                alert("Please select a User");
                setLoading(false);
              }
              values.user.user_name = "";
              values.user.user_id = null;
            }}
          >
            {({
              errors,
              isValid,
              helperText,
              touched,
              dirty,
              handleChange,
              values,
            }) => (
              <Form>
                <Box
                  width="100%"
                  height="60px"
                  // className="bg-info"
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Field
                        name="user"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component
                        value={values.user ? values.user : null}
                        component={AutoCompleteMultiSelect}
                        options={userlist}
                        isOptionEqualToValue={(option, value) =>
                          //setting value property to custom value
                          option.fullName === value.fullName
                        }
                        getOptionLabel={(option) => {
                          return option.user_name !== ""
                            ? `${option.fullName} (${option.user_name})`
                            : "";
                        }}
                        // getOptionLabel={(option) => option.fullName}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Users",
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      textAlign="right"
                    >
                      <MuiButton
                        width="100%"
                        type="submit"
                        name="Add"
                        loading={loading}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
          {navigator.onLine ? (
            <Box>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                  <LoadingIndicator
                    isActive={navigator.onLine ? props.isRequesting : false}
                  >
                    {/* datagrid Component */}
                    {props.userPermission.hits && !props.isRequesting && (
                      <DataGridFunction
                        columns={columns}
                        rows={rows}
                        rowHeight={60}
                        pagelength={props.pagination_user.total_page_no}
                        page={props.pagination_user.current_page_no}
                        handleChange={handleChange}
                        height="40vh"
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              user_unique_id: false,
                            },
                          },
                        }}
                      />
                    )}
                  </LoadingIndicator>
                </Grid>
              </Grid>
              {/* Pagination */}
              {/* <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                container
                item
                lg={12}
                md={12}
                sm={12}
              >
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                  <PaginationUI
                    pagelength={props.pagination_user.total_page_no}
                    page={props.pagination_user.current_page_no}
                    handleChange={handleChange}
                  />
                </Grid>
              </Grid> */}
            </Box>
          ) : (
            alert("No Internet Connection") || (
              <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
            )
          )}
          <Box></Box>
        </DialogContent>
      </ShadowContainer>
    </Dialog>
  );
};
export default connect(mapStateToProps)(AddUserPermission);
