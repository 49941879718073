import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function ReportDetailedScreen() {
  return (
    <Box sx={{ height: "100%" }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          sx={{ height: "100%", backgroundColor: "yellow" }}
        >
          <Typography component={"p"}>Report view</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          sx={{ height: "100%", backgroundColor: "aqua" }}
        >
          <Typography component={"p"}>Grid view</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReportDetailedScreen;
