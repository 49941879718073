import BaseReducer from "../../../../utilities/BaseReducer";
import RevenueReportAction from "./RevenueReportAction";

export default class RevenueReportReducer extends BaseReducer {
  initialState = {
    revenueReport: [],
  };
  [RevenueReportAction.REQUEST_REVENUE_REPORT_FINISHED](state, action) {
    return {
      ...state,
      revenueReport: action.payload,
    };
  }

  [RevenueReportAction.REQUEST_REVENUE_REPORT_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      revenueReport: action.payload,
    };
  }
}
