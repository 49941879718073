import BaseReducer from "../../../utilities/BaseReducer";
import IndeedFeedAction from "./IndeedFeedAction";

export default class IndeedFeedReducer extends BaseReducer {
  initialState = {
    indeedFeeds: [],
  };

  [IndeedFeedAction.REQUEST_INDEED_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      indeedFeeds: action.payload,
    };
  }

  [IndeedFeedAction.REQUEST_INDEED_FEEDS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      indeedFeeds: action.payload,
    };
  }
}
