import BaseReducer from "../../../utilities/BaseReducer";
import BrandCompitatorAction from "./BrandCompitatorAction";

// reducer function
export default class BrandCompitatorFilterReducer extends BaseReducer {
  initialState = {
    brandCompitatorFilter: [],
  };

  [BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      brandCompitatorFilter: action.payload,
    };
  }
}
