import ActionUtility from "../../../../utilities/ActionUtility";
import SubGenreEffect from "./SubGenreEffect";

export default class SubGenreAction {
  // SubGenreAction action with an function declaration
  static REQUEST_SUBGENRE = "SubGenreAction.REQUEST_SUBGENRE";
  static REQUEST_SUBGENRE_FINISHED = "SubGenreAction.REQUEST_SUBGENRE_FINISHED";

  static REQUEST_SUBGENRE_FILTER = "SubGenreAction.REQUEST_SUBGENRE_FILTER";
  static REQUEST_SUBGENRE_FILTER_FINISHED =
    "SubGenreAction.REQUEST_SUBGENRE_FILTER_FINISHED";

  static REQUEST_PUT_SUBGENRE = "SubGenreAction.REQUEST_PUT_SUBGENRE";
  static REQUEST_PUT_SUBGENRE_FINISHED =
    "SubGenreAction.REQUEST_PUT_SUBGENRE_FINISHED";

  static REQUEST_POST_SUBGENRE = "SubGenreAction.REQUEST_POST_SUBGENRE";
  static REQUEST_POST_SUBGENRE_FINISHED =
    "SubGenreAction.REQUEST_POST_SUBGENRE_FINISHED";

  static REQUEST_SUBGENRE_CLEAR = "SubGenreAction.REQUEST_SUBGENRE_CLEAR";
  static REQUEST_SUBGENRE_CLEAR_FINISHED =
    "SubGenreAction.REQUEST_SUBGENRE_CLEAR_FINISHED";

  static REQUEST_SUBGENRE_FILTER_CLEAR =
    "SubGenreAction.REQUEST_SUBGENRE_FILTER_CLEAR";
  static REQUEST_SUBGENRE_FILTER_CLEAR_FINISHED =
    "SubGenreAction.REQUEST_SUBGENRE_FILTER_CLEAR_FINISHED";

  // METHODS
  // Organization GET function
  static requestSubgenre(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubGenreAction.REQUEST_SUBGENRE,
        SubGenreEffect.requestSubgenre,
        params
      );
    };
  }

  static requestSubgenreClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubGenreAction.REQUEST_SUBGENRE_CLEAR,
        SubGenreEffect.requestSubgenreClear
      );
    };
  }

  static requestSubgenreFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubGenreAction.REQUEST_SUBGENRE_FILTER_CLEAR,
        SubGenreEffect.requestSubgenreFilterClear
      );
    };
  }

  static requestSubgenreFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubGenreAction.REQUEST_SUBGENRE_FILTER,
        SubGenreEffect.requestSubgenreFilter,
        params
      );
    };
  }
  static requestPutSubgenre(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubGenreAction.REQUEST_PUT_SUBGENRE,
        SubGenreEffect.requestPutSubgenre,
        data,
        id
      );
    };
  }

  static requestPostSubgenre(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SubGenreAction.REQUEST_POST_SUBGENRE,
        SubGenreEffect.requestPostSubgenre,
        data
      );
    };
  }
}
