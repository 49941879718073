import BaseReducer from "../../../../utilities/BaseReducer";
import PlatformSchedulerAction from "./PlatformSchedulerAction";

// PlatformSchedulerReduser
export default class PlatformSchedulerReduser extends BaseReducer {
  initialState = {
    scheduling: [],
  };
  [PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULING_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      scheduling: action.payload,
    };
  }
}
