import BaseReducer from "../../../utilities/BaseReducer";
import AddAccountAction from "./AddAccountAction";

export default class AddAccountFilterReducer extends BaseReducer {
  //initial state of priority list
  initialState = {
    addAccountFilter: [],
  };

  //priority request action finish
  // [PriorityAction.REQUEST_PRIORITY_FINISHED](state, action) {
  //   return {
  //     ...state,
  //     filterpriority: action.payload,
  //   };
  // }

  //priority request action finish
  [AddAccountAction.REQUEST_ADD_ACCOUNT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      addAccountFilter: action.payload,
    };
  }
}
