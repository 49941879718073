import BaseReducer from "../../../utilities/BaseReducer";
import UserMailMappingAction from "./UserMailMappingAction";

export default class UserMailMappingFIlterReduser extends BaseReducer {
  //initial state of UserMailMappingFIlterReduser
  initialState = {
    userMailMappingFilter: [],
  };
  //UserMailMappingFIlterReduser request action finish
  [UserMailMappingAction.REQUEST_USERMAIL_MAPPING_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      userMailMappingFilter: action.payload,
    };
  }
}
