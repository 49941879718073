import BaseReducer from "../../../../utilities/BaseReducer";
import TopComposerAction from "./TopComposerAction";

export default class TopComposerFilterReducer extends BaseReducer {
  //initial state of TopComposerFilterReduser
  initialState = {
    top_composer_report_filter: [],
  };
  //TopComposerFilterReduser request action finish
  [TopComposerAction.REQUEST_TOP_COMPOSER_FILTER_FINISHED](state, action) {
    return {
      ...state,
      top_composer_report_filter: action.payload,
    };
  }
}
