import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import FrontEndResourceModel from "./models/FrontEndResourceModel";
import FrontEndResourcePostModel from "./models/FrontendResourcePostModel";
import FrontEndResourcePutModel from "./models/FrontEndResourcePutModel";

export default class FrontEndResourceEffect {
  // GET
  static async requestFrontEndResource(params) {
    const endpoint = environment.api.frontEndResource;
    return EffectUtility.getToModel(FrontEndResourceModel, endpoint, params);
  }

  // GET filter
  static async requestFrontEndResourceFilter(params) {
    const endpoint = environment.api.frontEndResource;
    return EffectUtility.getToModel(FrontEndResourceModel, endpoint, params);
  }

  // POST
  static async requestFrontEndResourcePost(data) {
    const endpoint = environment.api.frontEndResource + "/add";
    return EffectUtility.postToModel(FrontEndResourcePostModel, endpoint, data);
  }

  // PUT
  static async requestFrontEndResourcePut(data, id) {
    const endpoint = environment.api.frontEndResource + "/" + id;
    return EffectUtility.putToModel(FrontEndResourcePutModel, endpoint, data);
  }
}
