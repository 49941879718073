import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import TelegramChannelGetModal from "./modals/TelegramChannelGetModal";
import TelegramChannelPostModal from "./modals/TelegramChannelPostModal";

export default class TelegramChannelEffect {
  static async requestGetTelegramChannel(params, callback) {
    const endpoint = environment.api.websweepxTelegramChannelMonitoring;
    let response = EffectUtility.getToModel(
      TelegramChannelGetModal,
      endpoint,
      params
    );
    return response;
  }

  static async requestPostTelegramChannel(data, callBackFunction) {
    const endpoint = environment.api.websweepxTelegramChannelMonitoring;
    let response = EffectUtility.postToModel(
      TelegramChannelPostModal,
      endpoint,
      data
    );
    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestPutTelegramChannel(data, id, callBackFunction) {
    const endpoint =
      environment.api.websweepxTelegramChannelMonitoring + `/${id}`;
    let response = EffectUtility.putToModel(
      TelegramChannelPostModal,
      endpoint,
      data
    );
    return response;
  }

  static async requestSyncTelegramChannel(params) {
    const endpoint = environment.api.websweepxSyncTelegramChannels;
    let response = EffectUtility.getToModel(
      TelegramChannelPostModal,
      endpoint,
      params
    );
    return response;
  }
  static async requestFetchAllTelegramChannelMsgs(params) {
    const endpoint = environment.api.websweepxFetchAllChannelMsgs;
    let response = EffectUtility.getToModel(
      TelegramChannelPostModal,
      endpoint,
      params
    );
    return response;
  }
  static async requestFetchSelectedTelegramChannelMsgs(params) {
    const endpoint =
      environment.api.websweepxFetchSelectedChannelMsgs +
      "?ids=" +
      `[${params.ids}]`;
    let response = EffectUtility.getToModel(TelegramChannelPostModal, endpoint);
    return response;
  }
}
