import environment from "environment";
import ActivityTypeModel from "./models/ActivityTypeModel";
import ActivityTypePostModel from "./models/ActivityTypePostModel";
import ActivityTypePutModel from "./models/ActivityTypePutModel";
import EffectUtility from "../../../../utilities/EffectUtility";

export default class ActivityTypeEffect {
  static async requestActivityType(params) {
    const endpoint = environment.api.activityType;
    return EffectUtility.getToModel(ActivityTypeModel, endpoint, params);
  }

  static async requestActivityTypeFilter(params) {
    const endpoint = environment.api.activityType;
    return EffectUtility.getToModel(ActivityTypeModel, endpoint, params);
  }

  static async requestPostActivityType(data) {
    const endpoint = environment.api.activityType + "/add";
    return EffectUtility.postToModel(ActivityTypePostModel, endpoint, data);
  }

  static async requestPutActivityType(data, id) {
    const endpoint = environment.api.activityType + "/" + id;
    return EffectUtility.putToModel(ActivityTypePutModel, endpoint, data);
  }
}
