import ActionUtility from "../../../utilities/ActionUtility";
import UserEffect from "./UserEffect";

export default class UserAction {
  //USER get request
  static REQUEST_USER = "UserAction.REQUEST_USER";
  static REQUEST_USER_FINISHED = "UserAction.REQUEST_USER_FINISHED";

  //USER post
  static REQUEST_POST_USER = "UserAction.REQUEST_POST_USER";
  static REQUEST_POST_USER_FINISHED = "UserAction.REQUEST_POST_USER_FINISHED";

  //USER put
  static REQUEST_PUT_USER = "UserAction.REQUEST_PUT_USER";
  static REQUEST_PUT_USER_FINISHED = "UserAction.REQUEST_PUT_USER_FINISHED";

  //USER filterUserName request
  static REQUEST_USER_FILTERUSERNAME = "UserAction.REQUEST_USER_FILTERUSERNAME";
  static REQUEST_USER_FILTERUSERNAME_FINISHED =
    "UserAction.REQUEST_USER_FILTERUSERNAME_FINISHED";

  //User GetById
  static REQUEST_USER_BY_ID = "UserAction.REQUEST_USER_BY_ID";
  static REQUEST_USER_BY_ID_FINISHED = "UserAction.REQUEST_USER_BY_ID_FINISHED";

  // Admin Reset password
  static REQUEST_PUT_ADMIN_RESET_PASSWORD =
    "UserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD";
  static REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED =
    "UserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED";

  // USER Reset password
  static REQUEST_PUT_USER_RESET_PASSWORD =
    "UserAction.REQUEST_PUT_USER_RESET_PASSWORD";
  static REQUEST_PUT_USER_RESET_PASSWORD_FINISHED =
    "UserAction.REQUEST_PUT_USER_RESET_PASSWORD_FINISHED";

  // Device Reset
  static REQUEST_MOBILE_DEVICE_RESET = "UserAction.REQUEST_MOBILE_DEVICE_RESET";
  static REQUEST_MOBILE_DEVICE_RESET_FINISHED =
    "UserAction.REQUEST_MOBILE_DEVICE_RESET_FINISHED";

  // USER Profile img upload
  static REQUEST_PUT_USER_PROFILE_IMG_UPLOAD =
    "UserAction.REQUEST_PUT_USER_PROFILE_IMG_UPLOAD";
  static REQUEST_PUT_USER_PROFILE_IMG_UPLOAD_FINISHED =
    "UserAction.REQUEST_PUT_USER_PROFILE_IMG_UPLOAD_FINISHED";

  static REQUEST_PUT_WORKING_STATUS = "UserAction.REQUEST_PUT_WORKING_STATUS";
  static REQUEST_PUT_WORKING_STATUS_FINISHED =
    "UserAction.REQUEST_PUT_WORKING_STATUS_FINISHED";

  //METHODS

  //USER get method
  static requestUser(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_USER,
        UserEffect.requestUser,
        params
      );
    };
  }

  //USER POST method
  static requestPostUser(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_POST_USER,
        UserEffect.requestPostUser,
        data,
        callBack
      );
    };
  }

  // USER put method
  static requestPutUser(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_PUT_USER,
        UserEffect.requestPutUser,
        data,
        id
      );
    };
  }

  //USER filter username
  static requestFilterUserName() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_USER_FILTERUSERNAME,
        UserEffect.requestFilterUserName
      );
    };
  }

  //
  //USER get request for page_limit none
  static REQUEST_USER_FILTER = "UserAction.REQUEST_USER_FILTER";
  static REQUEST_USER_FILTER_FINISHED =
    "UserAction.REQUEST_USER_FILTER_FINISHED";

  //USER get method page_limit none
  static requestUserFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_USER_FILTER,
        UserEffect.requestUserFilter,
        params
      );
    };
  }

  //USER getByID method
  static requestUserById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_USER_BY_ID,
        UserEffect.requestUserById,
        id
      );
    };
  }

  //USER working status update method
  static requestPutUserWorkingStatus(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_PUT_WORKING_STATUS,
        UserEffect.requestPutUserWorkingStatus,
        data
      );
    };
  }

  //adminRest Password put method
  static requestAdminResetPasswordPut(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD,
        UserEffect.requestAdminResetPasswordPut,
        data
      );
    };
  }

  static requestUserResetPassword(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_PUT_USER_RESET_PASSWORD,
        UserEffect.requestUserResetPassword,
        data
      );
    };
  }

  static requestPutUserProfileImg_upload(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_PUT_USER_PROFILE_IMG_UPLOAD,
        UserEffect.requestPutUserProfileImg_upload,
        data
      );
    };
  }

  static requestDeviceReset(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserAction.REQUEST_MOBILE_DEVICE_RESET,
        UserEffect.requestDeviceReset,
        id
      );
    };
  }
}
