import ActionUtility from "../../../utilities/ActionUtility";
import PlatformEffect from "./PlatformEffect";
import PlatformModel from "./models/PlatformModel";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";

export default class PlatformAction {
  //PLATFORM requests
  static REQUEST_PLATFORM = "PlatformAction.REQUEST_PLATFORM";
  static REQUEST_PLATFORM_FINISHED = "PlatformAction.REQUEST_PLATFORM_FINISHED";

  //PLATFORM post
  static REQUEST_POST_PLATFORM = "PlatformAction.REQUEST_POST_PLATFORM";
  static REQUEST_POST_PLATFORM_FINISHED =
    "PlatformAction.REQUEST_POST_PLATFORM";

  //PLATFORM put
  static REQUEST_PUT_PLATFORM = "PlatformAction.REQUEST_PUT_PLATFORM";
  static REQUEST_PUT_PLATFORM_FINISHED =
    "PlatformAction.REQUEST_PUT_PLATFORM_FINISHED";

  //PLATFORM list (for Filter drop down)
  static REQUEST_PLATFORM_FILTER = "PlatformAction.REQUEST_PLATFORM_FILTER";
  static REQUEST_PLATFORM_FILTER_FINISHED =
    "PlatformAction.REQUEST_PLATFORM_FILTER_FINISHED";

  //METHODS

  //PLATFORM get method
  static requestPlatform(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformAction.REQUEST_PLATFORM,
        PlatformEffect.requestPlatform,
        params
      );
    };
  }

  //PLATFORM POST method
  static requestPostPlatform(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformAction.REQUEST_POST_PLATFORM,
        PlatformEffect.requestPostPlatform,
        data
      );
    };
  }

  // PLATFORM put function
  static requestPutPlatform(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformAction.REQUEST_PUT_PLATFORM,
        PlatformEffect.requestPutPlatform,
        data,
        id
      );
    };
  }

  // PLATFORM list get  method for filter dropdown
  static requestFilterPlatform(params) {
    return async (dispatch, getState) => {
      if (localStorage.getItem("platform") !== null) {
        let data = localStorage.getItem("platform");
        const model = new PlatformModel(JSON.parse(data));
        const isError = model instanceof HttpErrorResponseModel;
        dispatch(
          ActionUtility.createAction(
            `${PlatformAction.REQUEST_PLATFORM_FILTER}_FINISHED`,
            model,
            isError
          )
        );

        return model;
      } else {
        await ActionUtility.createThunkEffect(
          dispatch,
          PlatformAction.REQUEST_PLATFORM_FILTER,
          PlatformEffect.filterRequestPlatform,
          params
        );
      }
    };
  }
}
