import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
function SentimentByCustomFields(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [sentimentByCustomField, setSentimentByCustomField] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchSentimentByCustomFields(props.DateFilter).then((res) => {
        setSentimentByCustomField(res);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);
  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchSentimentByCustomFields(props.DateFilter).then((res) => {
          setSentimentByCustomField(res);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);
  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchSentimentByCustomFields(props.openDateFilter).then((res) => {
          setSentimentByCustomField(res);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchSentimentByCustomFields(dateRange) {
    const type_value_filters = props.type_value?.length
      ? props.type_value.map((item) => `${item.type_id}`)
      : [];

    let API = `${
      environment.api.sentimentalAnalysisReports
    }?reports=["sentiment_analysis_report_all_custom_value"]&report_type=["count"]&advanced_filters={"custom_value_type":${
      props.type_value?.length
        ? JSON.stringify(type_value_filters)
        : `["text","select","radio","toggle","date"]`
    }}${
      props.DateFilter || (props.filterClicked && props.openDateFilter)
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;
    try {
      const res = axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }
  let columns = [];
  columns.push(
    {
      field: "field_name",
      headerName: "Custom Field Name",
      resizable: true,
      hide: false,
      flex: 0.5,
      minWidth: 350,
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name?.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.field_name}
          </Typography>
        );
      },
    },
    {
      field: "positive",
      headerName: "Positive",
      resizable: true,
      hide: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name?.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.positive}
          </Typography>
        );
      },
    },
    {
      field: "neutral",
      headerName: "Neutral",
      resizable: true,
      hide: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name?.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.neutral}
          </Typography>
        );
      },
    },

    {
      field: "negative",
      headerName: "Negative",
      resizable: true,
      hide: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Typography
            className={`${
              params?.row?.field_name?.startsWith("Total")
                ? "font-weight-bold p-0 m-0"
                : "font-weight-normal p-0 m-0"
            }`}
          >
            {params?.row?.negative}
          </Typography>
        );
      },
    }
  );

  let updatedRows = [];
  if (sentimentByCustomField) {
    sentimentByCustomField?.sentiment_analysis_report?.sentiment_analysis_report_all_custom_value?.forEach(
      (entyItem, index) => {
        // Loop through each value in the `values` array
        entyItem?.values?.forEach((valueItem) => {
          updatedRows?.push({
            id: updatedRows.length + 1, // Ensure unique IDs
            field_name: `${entyItem.key} - ${valueItem.key} (${valueItem.count})`,
            negative:
              prefixZero_for_singleDigit(valueItem?.sentiment?.[0].count) ??
              "00",
            neutral:
              prefixZero_for_singleDigit(valueItem?.sentiment?.[1].count) ??
              "00",
            positive:
              prefixZero_for_singleDigit(valueItem?.sentiment?.[2].count) ??
              "00",
          });
        });
      }
    );
  }
  const totalRow = {
    id: updatedRows.length + 1,
    field_name: "Total",
    negative:
      prefixZero_for_singleDigit(
        sentimentByCustomField?.sentiment_analysis_report
          ?.sentiment_analysis_report_all_custom_value[
          sentimentByCustomField?.sentiment_analysis_report
            ?.sentiment_analysis_report_all_custom_value?.length - 1
        ]?.negative_sentiment
      ) ?? "00",
    neutral:
      prefixZero_for_singleDigit(
        sentimentByCustomField?.sentiment_analysis_report
          ?.sentiment_analysis_report_all_custom_value[
          sentimentByCustomField?.sentiment_analysis_report
            ?.sentiment_analysis_report_all_custom_value?.length - 1
        ]?.neutral_sentiment
      ) ?? "00",

    positive:
      prefixZero_for_singleDigit(
        sentimentByCustomField?.sentiment_analysis_report
          ?.sentiment_analysis_report_all_custom_value[
          sentimentByCustomField?.sentiment_analysis_report
            ?.sentiment_analysis_report_all_custom_value?.length - 1
        ]?.positive_sentiment
      ) ?? "00",
  };
  sentimentByCustomField?.sentiment_analysis_report
    ?.sentiment_analysis_report_all_custom_value?.length > 1 &&
    updatedRows.push(totalRow);
  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];
      updatedRows?.forEach((item) => {
        const exportEntry = {
          Custom_Field_Name: item.field_name,
          Positive: prefixZero_for_singleDigit(item?.positive),
          Neutral: prefixZero_for_singleDigit(item?.neutral),
          Negative: prefixZero_for_singleDigit(item?.negative),
        };
        exportData.push(exportEntry);
      });
      props.setexcelData(exportData);
      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, updatedRows]);
  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {!loading &&
            updatedRows.length > 0 &&
            props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={50}
                      onCellClick={onCellClick}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}
export default SentimentByCustomFields;
