import ActionUtility from "../../../utilities/ActionUtility";
import DiscordAccountEffect from "./DiscordAccountEffect";

export default class DiscordAccountAction {
  // DiscordAccountAction with an function declaration
  static REQUEST_DISCORD_ACCOUNT =
    "DiscordAccountAction.REQUEST_DISCORD_ACCOUNT";
  static REQUEST_DISCORD_ACCOUNT_FINISHED =
    "DiscordAccountAction.REQUEST_DISCORD_ACCOUNT_FINISHED";

  static REQUEST_PUT_DISCORD_ACCOUNT =
    "DiscordAccountAction.REQUEST_PUT_DISCORD_ACCOUNT";
  static REQUEST_PUT_DISCORD_ACCOUNT_FINISHED =
    "DiscordAccountAction.REQUEST_PUT_DISCORD_ACCOUNT_FINISHED";

  static REQUEST_DELETE_DISCORD_ACCOUNT =
    "DiscordAccountAction.REQUEST_DELETE_DISCORD_ACCOUNT";
  static REQUEST_DELETE_DISCORD_ACCOUNT_FINISHED =
    "DiscordAccountAction.REQUEST_DELETE_DISCORD_ACCOUNT_FINISHED";

  // METHODS
  // Discord Get function
  static requestDiscordAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordAccountAction.REQUEST_DISCORD_ACCOUNT,
        DiscordAccountEffect.requestDiscordAccount,
        params
      );
    };
  }

  static requestPutDiscordAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordAccountAction.REQUEST_PUT_DISCORD_ACCOUNT,
        DiscordAccountEffect.requestPutDiscordAccount,
        data,
        id
      );
    };
  }

  static requestDeleteDiscordAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordAccountAction.REQUEST_DELETE_DISCORD_ACCOUNT,
        DiscordAccountEffect.requestDeleteDiscordAccount,
        data
      );
    };
  }
}
