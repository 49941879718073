import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import FingerPrintSearchPostModel from "./models/FingerPrintSearchPostModel";
import FingerPrintAcrSearchPostModel from "./models/FingerPrintAcrSearchPostModel";

export default class FingerPrintSearchEffect {
  static async requestPostFPSearch(data, params, callback) {
    const endpoint = environment.api.FPSearch;

    let response = EffectUtility.postToModel(
      FingerPrintSearchPostModel,
      endpoint,
      data,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  static async requestPostFPacrSearch(data, params, callback) {
    const endpoint = environment.api.FPacrSearch + "/add";

    let response = EffectUtility.postToModel(
      FingerPrintAcrSearchPostModel,
      endpoint,
      data,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }
}
