import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// Category data model
export default class CategoryDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  category_name = "";
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = "";
  modified_on = 0;
  project = {
    project_id: "",
    project_name: "",
  };

  constructor(data) {
    super();

    this.update(data);
  }
}
