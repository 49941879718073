import React, { useEffect, useRef, useState } from "react";
import "../cssFiles/NegativeMentionsDispositionWise.css";
import environment from "environment";
import { format } from "date-fns";
import { endDate, monthNames, startDate } from "../dateFunctions/DateFunctions";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Logo from "../logoContainer/Logo";
import Description from "../descriptionContainer/Description";
import { Box } from "@mui/material";
import { theme } from "../../../../views/App";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoBarChart } from "../../../../components/nivoCharts/NivoBarChart";
import { firstLetterOrange } from "../../../../components/ticketPartials";

function NegativeMentionsDispositionWiseChart(props) {
  Chart.register(CategoryScale);

  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);

  const [current_month_data, set_current_month_data] = useState(null);
  const [previous_month_data, set_previous_month_data] = useState(null);
  const [before_previous_month_data, set_before_previous_month_data] =
    useState(null);
  const [last_3_months_data, set_last_3_months_data] = useState(null);

  // this month
  let current_month = new Date();
  let current_month_start_date = startDate(current_month);
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  let previous_month_start_date = startDate(previous_month);
  let previous_month_end_date = endDate(previous_month);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);

  let before_previous_month_start_date = startDate(before_previous_month);
  let before_previous_month_end_date = endDate(before_previous_month);

  // last three month names
  let current_month_name = monthNames[current_month.getMonth()];
  let previous_month_name = monthNames[previous_month.getMonth()];
  let before_previous_month_name = monthNames[before_previous_month.getMonth()];

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      // fetching platform data
      await fetchData(current_month_start_date, current_month_end_date).then(
        (e) => {
          set_current_month_data(e);
        }
      );
      await fetchData(previous_month_start_date, previous_month_end_date).then(
        (e) => {
          set_previous_month_data(e);
        }
      );
      await fetchData(
        before_previous_month_start_date,
        before_previous_month_end_date
      ).then((e) => {
        set_before_previous_month_data(e);
      });

      await fetchData(
        before_previous_month_start_date,
        current_month_end_date
      ).then((e) => {
        set_last_3_months_data(e);
      });
      setLoading(false);
    })();
  }, []);

  async function fetchData(from, to) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["sentiment_analysis_report_custom_value"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${
    //     environment.api.sentimentalAnalysisReports
    //   }?report_type=["count"]&reports=["sentiment_analysis_report_custom_value"]&from=${format(
    //     from,
    //     "yyyy-MM-dd"
    //   )}&to=${format(to, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     return response;
    //   });
  }

  let selected_report_data_current_month =
    current_month_data &&
    current_month_data.sentiment_analysis_report &&
    current_month_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let selected_report_data_previous_month =
    previous_month_data &&
    previous_month_data.sentiment_analysis_report &&
    previous_month_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let selected_report_data_before_previous_month =
    before_previous_month_data &&
    before_previous_month_data.sentiment_analysis_report &&
    before_previous_month_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let selected_report_data_last3_month =
    last_3_months_data &&
    last_3_months_data.sentiment_analysis_report &&
    last_3_months_data.sentiment_analysis_report.sentiment_analysis_report_custom_value.filter(
      (item) => {
        return Object.keys(item)[0] === props.filterValue;
      }
    )[0];

  let chartList = [];

  selected_report_data_current_month &&
    Object.entries(selected_report_data_current_month).map(([key, value]) => {
      value.map((item) => {
        chartList.push({
          [Object.keys(item)[0]]: [
            {
              current_month_count: item[Object.keys(item)]
                .filter((value) => value.key === "negative_sentiment")
                .map((e) => {
                  return e.count;
                }),
            },
          ],
        });
      });
    });

  selected_report_data_previous_month &&
    Object.entries(selected_report_data_previous_month).map(([key, value]) => {
      value.map((item) => {
        chartList.push({
          [Object.keys(item)[0]]: [
            {
              previous_month_count: item[Object.keys(item)]
                .filter((value) => value.key === "negative_sentiment")
                .map((e) => {
                  return e.count;
                }),
            },
          ],
        });
      });
    });

  selected_report_data_before_previous_month &&
    Object.entries(selected_report_data_before_previous_month).map(
      ([key, value]) => {
        value.map((item) => {
          chartList.push({
            [Object.keys(item)[0]]: [
              {
                before_previous_month_count: item[Object.keys(item)]
                  .filter((value) => value.key === "negative_sentiment")
                  .map((e) => {
                    return e.count;
                  }),
              },
            ],
          });
        });
      }
    );

  selected_report_data_last3_month &&
    Object.entries(selected_report_data_last3_month).map(([key, value]) => {
      value.map((item) => {
        chartList.push({
          [Object.keys(item)[0]]: [
            {
              last3months_count: item[Object.keys(item)]
                .filter((value) => value.key === "negative_sentiment")
                .map((e) => {
                  return e.count;
                }),
            },
          ],
        });
      });
    });

  const result = [];

  chartList &&
    chartList.forEach((el) => {
      let [key, value] = Object.entries(el)[0];
      for (let el of result)
        if (key in el) {
          el[key].push(value);
          return;
        }

      result.push({ [key]: [value] });
    });

  let Total_of_before_previous_month = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.before_previous_month_count) {
                Total_of_before_previous_month.push(
                  value.before_previous_month_count
                );
              }
            });
        });
    });

  let Total_of_previous_month = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.previous_month_count) {
                Total_of_previous_month.push(value.previous_month_count[0]);
              }
            });
        });
    });
  let Total_of_current_month = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.current_month_count) {
                Total_of_current_month.push(value.current_month_count[0]);
              }
            });
        });
    });

  let Total_of_3_months = [];

  result &&
    result.map((value) => {
      value &&
        value[Object.keys(value)].map((item) => {
          item &&
            item.map((value) => {
              if (value.last3months_count) {
                Total_of_3_months.push(value.last3months_count[0]);
              }
            });
        });
    });

  function TotalCount(item) {
    return item.length > 0 && item.reduce((prev, next) => prev + next);
  }

  Total_of_before_previous_month = TotalCount(Total_of_before_previous_month);
  Total_of_previous_month = TotalCount(Total_of_previous_month);
  Total_of_current_month = TotalCount(Total_of_current_month);
  Total_of_3_months = TotalCount(Total_of_3_months);

  let countData = [];
  let nameLabels = [];

  result &&
    result.map((item) => {
      return item[Object.keys(item)].map((value) => {
        return (
          value &&
          value.map((event) => {
            if (event.last3months_count) {
              return countData.push(event.last3months_count[0]);
            }
          })
        );
      });
    });

  let MaxValue = Math.max(...countData);

  let AvgOfMax = MaxValue / 10;
  MaxValue = MaxValue + AvgOfMax;

  AvgOfMax = Math.round(AvgOfMax);

  nameLabels =
    result &&
    result.map((item) => {
      return Object.keys(item)[0];
    });

  const state = {
    labels: nameLabels,
    datasets: [
      {
        type: "bar",
        labels: "Bar Chart",
        borderColor: `${theme.reports.negative}`,
        backgroundColor: `${theme.reports.negative}`,
        data: countData,
        borderWidth: 2,
        borderRadius: 5,
      },
    ],
  };

  const chartData = nameLabels.map((nameLabel, index) => ({
    nameLabel: nameLabel,
    Categories: countData[index],
  }));

  return (
    <LoadingIndicator isActive={loading}>
      <Box height="100%">
        <NivoBarChart
          chartData={chartData}
          keys={["Categories"]}
          indexedByAxis="nameLabel"
          otherAxis={"Count"}
          layout="horizontal"
          barPadding={0.1}
          legendPosition={"bottom"}
          legendsDirection={"row"}
          translateX={50}
          translateY={50}
          enableGridY={false}
          enableGridX={false}
          colors={[`${theme.reports.negative}`]}
          margin={{ top: 10, right: 10, bottom: 50, left: 100 }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Categories",
            legendPosition: "middle",
            legendOffset: -80,
            truncateTickAt: 0,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: "Count",
            legendPosition: "middle",
            legendOffset: 30,
            truncateTickAt: 0,
          }}
        />
      </Box>
    </LoadingIndicator>
  );
}

export default NegativeMentionsDispositionWiseChart;
