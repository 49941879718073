import BaseReducer from "../../utilities/BaseReducer";
import TicketTypeAction from "./TicketTypeAction";

export default class TicketTypeReducer extends BaseReducer {
  //initial state of ticketType
  initialState = {
    ticketType: [],
  };

  //tickettype request action finish
  [TicketTypeAction.REQUEST_TICKETTYPE_FINISHED](state, action) {
    return {
      ...state,
      ticketType: action.payload,
    };
  }
}
