import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MuiButton from "../../../components/muiButton/MuiButton";
import { theme } from "../../../views/App";
import FaqAction from "../../stores/faq/FaqAction";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const FaqPost = (props) => {
  const [loading, setLoading] = useState(false);

  //Initial values
  let initial = {};
  if (props.edit) {
    initial = {
      module: props.editData?.module || "",
      topic: props.editData?.topic || "",
      faqs: props.editData?.faqs || [
        {
          question: "",
          answer: "",
          order_no: 1,
          img_ref_urls: [""],
        },
      ],
    };
  } else {
    initial = {
      module: "",
      topic: "",
      faqs: [
        {
          question: "",
          answer: "",
          img_ref_urls: [""],
        },
      ],
    };
  }

  const validationSchema = yup.object({
    module: yup.string().required("Module is Required").nullable(),
    topic: yup.string().required("Topic is Required").nullable(),

    faqs: yup.array().of(
      yup.object().shape({
        question: yup.string().required("Question is Required").nullable(),
        answer: yup.string().required("Answer is Required").nullable(),
      })
    ),
  });

  const AddIconFunction = (values, push) => {
    return (
      values.faqs &&
      values.faqs[values.faqs.length - 1] &&
      values.faqs[values.faqs.length - 1].question &&
      values.faqs[values.faqs.length - 1].answer && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="flex-end"
          mt={1}
        >
          {" "}
          <MuiButton
            onClick={() =>
              push({
                question: "",
                answer: "",
                img_ref_urls: [""],
              })
            }
            name={<AddIcon />}
          />
        </Grid>
      )
    );
  };

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let obj = {
            module: values.module,
            topic: values.topic,
            faqs: [],
          };

          values.faqs.map((item, index) => {
            let faqsObject = { ...item, order_no: index + 1 };
            obj.faqs.push(faqsObject);
          });

          // parameter for showing list based on the modified_by
          if (props.edit) {
            props
              .dispatch(FaqAction.requestPutFaq(obj, props.editData.id))
              .then(() => {
                const params = {
                  page_limit: "none",
                };
                props.dispatch(FaqAction.requestGetFaq(params));
                setLoading(false);
              });
          }
          ///       ****************************************   Post method
          else {
            props.dispatch(FaqAction.requestPostFaq(obj)).then(() => {
              const params = {
                page_limit: "none",
              };
              props.dispatch(FaqAction.requestGetFaq(params));
              setLoading(false);
            });
          }

          // formikHelpers.resetForm();
          props.onClose();
        }}
      >
        {({
          values,
          errors,
          isValid,
          touched,
          dirty,
          handleChange,
          submitForm,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        <b>
                          {!props.edit ? (
                            <>
                              <span className="span_first_letter">A</span>dd{" "}
                            </>
                          ) : (
                            <>
                              <span className="span_first_letter">E</span>dit{" "}
                            </>
                          )}
                          <span className="span_first_letter">F</span>AQ's{" "}
                        </b>
                      </h3>
                    </div>
                    <div className="col-2 closebtn">
                      <i
                        className="fa fa-times-circle-o fa-2x"
                        title="Close"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid
                container
                m={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={3} md={3} sm={3} textAlign="center">
                  Module * :
                </Grid>
                <Grid item lg={8} md={6} sm={6}>
                  <Field
                    as={MuiTextField}
                    autoFocus
                    variant="outlined"
                    placeholder="Module Name"
                    id="module"
                    size="small"
                    name="module"
                    error={Boolean(errors.module) && Boolean(touched.module)}
                    helperText={Boolean(touched.module) && errors.module}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                m={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={3} md={3} sm={3} textAlign="center">
                  Topic * :
                </Grid>
                <Grid item lg={8} md={6} sm={6}>
                  <Field
                    as={MuiTextField}
                    variant="outlined"
                    placeholder="Topic Name"
                    id="topic"
                    size="small"
                    name="topic"
                    error={Boolean(errors.topic) && Boolean(touched.topic)}
                    helperText={Boolean(touched.topic) && errors.topic}
                  />
                </Grid>
              </Grid>

              <Grid container m={1}>
                <FieldArray name={`faqs`}>
                  {({ insert, remove, push }) => (
                    <Grid
                      container
                      lg={12}
                      md={12}
                      sm={12}
                      mt={1}
                      className="d-flex justify-content-center"
                    >
                      {values.faqs &&
                        values.faqs.length > 0 &&
                        values.faqs.map((faq, index) => (
                          <Grid
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1}
                            key={index}
                            sx={{
                              backgroundColor: theme.palette.background.default,
                              borderRadius: 3,
                              padding: 3,
                            }}
                          >
                            <Grid
                              item
                              xl={10}
                              lg={10}
                              md={12}
                              sm={12}
                              container
                              className="d-flex align-items-center"
                            >
                              <Grid item lg={3.5} md={3.5} sm={3.5}>
                                Question * :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                {console.log(
                                  "errors.faqs",
                                  errors.faqs,
                                  index,
                                  "ASGASG",
                                  errors.faqs?.[index]?.question
                                )}
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder="Question"
                                  id="topic"
                                  size="small"
                                  name={`faqs.${index}.question`}
                                  error={Boolean(
                                    errors.faqs && errors.faqs[index]?.question
                                  )}
                                  helperText={
                                    errors.faqs && errors.faqs[index]?.question
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xl={10}
                              lg={10}
                              md={12}
                              sm={12}
                              mt={1}
                              container
                              className="d-flex align-items-center"
                            >
                              <Grid item lg={3.5} md={3.5} sm={3.5}>
                                Answer * :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder="Answer"
                                  id="topic"
                                  size="small"
                                  name={`faqs.${index}.answer`}
                                  error={Boolean(
                                    errors.faqs && errors.faqs[index]?.answer
                                  )}
                                  helperText={
                                    errors.faqs && errors.faqs[index]?.answer
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xl={10}
                              lg={10}
                              md={12}
                              sm={12}
                              mt={1}
                              container
                              className="d-flex align-items-center"
                            >
                              <FieldArray name={`faqs.${index}.img_ref_urls`}>
                                {({ insert, remove, push }) => (
                                  <Grid
                                    container
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    mt={1}
                                    className="d-flex justify-content-center"
                                  >
                                    {values.faqs?.[index].img_ref_urls &&
                                      values.faqs?.[index].img_ref_urls.length >
                                        0 &&
                                      values.faqs?.[index].img_ref_urls.map(
                                        (faq, i) => (
                                          <Grid
                                            container
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mt={1}
                                            key={index}
                                            sx={{
                                              backgroundColor:
                                                theme.palette.background
                                                  .default,
                                              borderRadius: 3,
                                            }}
                                          >
                                            <Grid
                                              item
                                              xl={10}
                                              lg={10}
                                              md={10}
                                              sm={10}
                                              container
                                              className="d-flex align-items-center"
                                            >
                                              <Grid
                                                item
                                                lg={3.5}
                                                md={3.5}
                                                sm={3.5}
                                              >
                                                Image URLs :
                                              </Grid>
                                              <Grid item lg={8} md={8} sm={8}>
                                                <Field
                                                  as={MuiTextField}
                                                  variant="outlined"
                                                  placeholder="Enter Image Url"
                                                  id="topic"
                                                  size="small"
                                                  name={`faqs.${index}.img_ref_urls.${i}`}
                                                />
                                              </Grid>
                                            </Grid>

                                            {values.faqs?.[index]?.img_ref_urls
                                              ?.length !== 1 && (
                                              <Grid
                                                item
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                container
                                                mt={1}
                                                className="d-flex justify-content-end"
                                              >
                                                <MuiButton
                                                  name={<DeleteIcon />}
                                                  onClick={() => remove(i)}
                                                />
                                              </Grid>
                                            )}
                                          </Grid>
                                        )
                                      )}

                                    {values.faqs?.[index]?.img_ref_urls &&
                                      values.faqs?.[index]?.img_ref_urls[
                                        values.faqs?.[index]?.img_ref_urls
                                          .length - 1
                                      ] && (
                                        <Grid
                                          container
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          justifyContent="flex-end"
                                          mt={1}
                                        >
                                          <MuiButton
                                            onClick={() => push("")}
                                            name={<AddIcon />}
                                          />
                                        </Grid>
                                      )}
                                  </Grid>
                                )}
                              </FieldArray>
                            </Grid>

                            {values?.faqs?.length !== 1 && (
                              <Grid
                                item
                                xl={1}
                                lg={1}
                                md={1}
                                sm={1}
                                container
                                mt={1}
                                className="d-flex justify-content-end"
                              >
                                <MuiButton
                                  name={<DeleteIcon />}
                                  onClick={() => remove(index)}
                                />
                              </Grid>
                            )}
                          </Grid>
                        ))}

                      {AddIconFunction(values, push)}
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={4.8} md={5} sm={5} mb={3}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add FAQ"}
                      onClick={submitForm}
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      name={"Submit"}
                      onClick={submitForm}
                      loading={loading}
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={4.8} md={5} sm={5} mb={3}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                      width="100%"
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(FaqPost);
