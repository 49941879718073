import { BaseModel } from "sjs-base-model";

// faceBook Account shards model
export default class FaceBookAccountShardsModel extends BaseModel {

  // response getting from the api mentioned end point
  failed = 0;
  skipped = 0;
  successful = 1;
  total = 1;
  constructor(data1) {
    super();
    this.update(data1);
  }
}
