import { BaseModel } from "sjs-base-model";

export default class TicketReportsModel extends BaseModel {
  ticket_report = {};

  constructor(data) {
    super();

    this.update(data);
  }
}
