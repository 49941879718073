import BaseReducer from "../../../../utilities/BaseReducer";
import BatchAction from "./BatchAction";

// BatchReduser
export default class BatchReduser extends BaseReducer {
  initialState = {
    batchA3: [],
  };
  [BatchAction.REQUEST_BATCH_FINISHED](state, action) {
    return {
      ...state,
      batchA3: action.payload,
    };
  }
}
