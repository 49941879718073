import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";

function OverallSentimentMentions3months(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);
  const [sentimentData, setSentimentData] = useState(null);

  const [today, setToday] = useState(new Date());

  function getLast3monthStartAndThismonthEndDate() {
    let date = new Date();
    const last3monthStartDate = new Date(
      date.getFullYear(),
      date.getMonth() - 2,
      2
    );
    const last3monthstartDatestring = last3monthStartDate
      .toISOString()
      .slice(0, 10);

    const currentMonthEnddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    );
    const currentMonthendDatestring = currentMonthEnddate
      .toISOString()
      .slice(0, 10);

    return {
      last3monthStartDate: last3monthstartDatestring,
      currentMonthEnddate: currentMonthendDatestring,
    };
  }

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);
        await fetchSentimentData(firstDateString, endDateString).then((res) => {
          setSentimentData(res);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  let { last3monthStartDate, currentMonthEnddate } =
    getLast3monthStartAndThismonthEndDate();

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchSentimentData().then((res) => {
        setSentimentData(res);
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setToday(new Date());
        setLoading(true);
        await fetchSentimentData().then((res) => {
          setSentimentData(res);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  async function fetchSentimentData(from, to) {
    let API = `${
      environment.api.sentimentalAnalysisReports
    }?report_type=["count"]&reports=["overall_sentiment_mentions"]
    ${
      props.filterClicked
        ? `&from=${from}&to=${to}`
        : `&from=${last3monthStartDate}&to=${currentMonthEnddate}`
    }`;

    try {
      const res = await axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  const formatDate = (dateValue) => {
    const dateParts = dateValue.split("-");
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    // Create a Date object using the extracted parts
    const dateObj = new Date(year, month - 1, day);

    // Get the month and year from the Date object
    const monthName = dateObj.toLocaleString("default", { month: "short" });
    const formattedDate = `${monthName} ${year}`;

    return formattedDate;
  };

  let NoDataFoundBool =
    !loading &&
    sentimentData &&
    sentimentData.sentiment_analysis_report &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions
      .overall_sentiment_mentions &&
    sentimentData.sentiment_analysis_report.overall_sentiment_mentions
      .overall_sentiment_mentions.last_three_months_negative_percentage +
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.last_three_months_neutral_percentage +
      sentimentData.sentiment_analysis_report.overall_sentiment_mentions
        .overall_sentiment_mentions.last_three_months_positive_percentage ===
      0;

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      let currentMonthName;

      sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
        ?.overall_sentiment_mentions?.month_wise &&
        Object.entries(
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.month_wise
        )?.forEach(([month, monthData], index, array) => {
          let monthName = formatDate(month);
          currentMonthName = formatDate(array[array.length - 1][0]);
          let PositiveSentiment =
            monthData?.filter(
              (item) => item.key === "positive_sentiment"
            )?.[0] || {};
          let NegativeSentiment =
            monthData?.filter(
              (item) => item.key === "negative_sentiment"
            )?.[0] || {};
          let NeutralSentiment =
            monthData?.filter((item) => item.key === "neutral")?.[0] || {};
          let Total =
            monthData?.filter((item) => item.key === "total")?.[0] || {};

          exportData.push({
            Month: monthName,
            Positive: prefixZero_for_singleDigit(PositiveSentiment?.count),
            Negative: prefixZero_for_singleDigit(NegativeSentiment?.count),
            Neutral: prefixZero_for_singleDigit(NeutralSentiment?.count),
            Total: prefixZero_for_singleDigit(Total?.count),
          });
        });

      exportData.push({
        Month: "Total",

        Positive: prefixZero_for_singleDigit(
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.total_positive
        ),
        Negative: prefixZero_for_singleDigit(
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.total_negative
        ),
        Neutral: prefixZero_for_singleDigit(
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.total_neutral
        ),
        Total: prefixZero_for_singleDigit(
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.total_mentions
        ),
      });

      let mentionsData =
        sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
          ?.overall_sentiment_mentions;

      // Assuming the last three months percentages are available as follows
      const lastThreeMonths = {
        Positive:
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.last_three_months_positive_percentage,
        Negative:
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.last_three_months_negative_percentage,
        Neutral:
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions
            ?.overall_sentiment_mentions?.last_three_months_neutral_percentage,
        Total:
          sentimentData?.sentiment_analysis_report?.overall_sentiment_mentions?.overall_sentiment_mentions?.total_last_month_percentage?.toFixed(
            2
          ),
      };

      // Calculate total for average percentages

      exportData.push({
        Month: `${currentMonthName} Avg %`,
        Positive: `${lastThreeMonths.Positive}%`,
        Negative: `${lastThreeMonths.Negative}%`,
        Neutral: `${lastThreeMonths.Neutral}%`,
        Total: `${lastThreeMonths.Total}%`, // Or adjust as necessary
      });
      // Transpose the data
      const months = exportData.map((row) => row.Month);
      const sentimentTypes = ["Positive", "Negative", "Neutral", "Total"];

      const transposedData = [];

      // Create rows for each sentiment type
      sentimentTypes.forEach((type) => {
        const newRow = { Sentiment: type };
        months.forEach((month) => {
          const monthData = exportData.find((row) => row.Month === month);
          newRow[month] = monthData ? monthData[type] : "0";
        });
        transposedData.push(newRow);
      });

      props.setexcelData(transposedData);
    }
  }, [props.DownloadClicked]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box
          sx={{
            width: "100%",
            padding: "12px",
          }}
        >
          {!loading && !NoDataFoundBool ? (
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                borderRadius: theme.table.borderRadius,
              }}
            >
              {sentimentData &&
                sentimentData.sentiment_analysis_report &&
                sentimentData.sentiment_analysis_report
                  .overall_sentiment_mentions &&
                sentimentData.sentiment_analysis_report
                  .overall_sentiment_mentions.overall_sentiment_mentions && (
                  <table
                    style={{
                      width: "100%",
                      border: `1px solid ${theme.table.border}`,
                      // textAlign: "center",
                    }}
                    border="2"
                  >
                    <tbody style={{ width: "100%" }}>
                      <tr
                        style={{
                          border: `1px solid ${theme.table.border}`,
                          color: `${theme.palette.text.primary}`,
                          backgroundColor: `${theme.palette.background.primary}`,
                        }}
                      >
                        <th className="pl-3">Sentiment</th>
                        {Object.entries(
                          sentimentData.sentiment_analysis_report
                            .overall_sentiment_mentions
                            .overall_sentiment_mentions.month_wise
                        ).map(([date, values]) => (
                          <th className="pl-3" key={date}>
                            {formatDate(date)}
                          </th>
                        ))}
                        <th className="pl-3">Total</th>
                        <th className="pl-3">
                          {Object.entries(
                            sentimentData.sentiment_analysis_report
                              .overall_sentiment_mentions
                              .overall_sentiment_mentions.month_wise
                          )[
                            Object.entries(
                              sentimentData.sentiment_analysis_report
                                .overall_sentiment_mentions
                                .overall_sentiment_mentions.month_wise
                            ).length - 1
                          ][0] &&
                            formatDate(
                              Object.entries(
                                sentimentData.sentiment_analysis_report
                                  .overall_sentiment_mentions
                                  .overall_sentiment_mentions.month_wise
                              )[
                                Object.entries(
                                  sentimentData.sentiment_analysis_report
                                    .overall_sentiment_mentions
                                    .overall_sentiment_mentions.month_wise
                                ).length - 1
                              ][0]
                            )}{" "}
                          Avg %
                        </th>
                      </tr>

                      <tr
                        style={{
                          border: `1px solid ${theme.table.border}`,
                          color: `${theme.palette.text.primary}`,
                          backgroundColor: `${theme.table.rowColor}`,
                        }}
                      >
                        <td className="pl-3">Positive</td>
                        {Object.entries(
                          sentimentData.sentiment_analysis_report
                            .overall_sentiment_mentions
                            .overall_sentiment_mentions.month_wise
                        ).map(([date, values]) => {
                          const totalPositive =
                            values.find(
                              (item) => item.key === "positive_sentiment"
                            )?.count || 0;
                          return (
                            <td className="pl-3" key={date}>
                              {prefixZero_for_singleDigit(totalPositive)}
                            </td>
                          );
                        })}
                        <td className="pl-3 font-weight-bold">
                          {prefixZero_for_singleDigit(
                            sentimentData.sentiment_analysis_report
                              .overall_sentiment_mentions
                              .overall_sentiment_mentions.total_positive
                          )}
                        </td>

                        <td className="pl-3">
                          {sentimentData.sentiment_analysis_report.overall_sentiment_mentions.overall_sentiment_mentions.last_three_months_positive_percentage?.toFixed(
                            2
                          )}{" "}
                          %
                        </td>
                      </tr>

                      <tr
                        style={{
                          border: `1px solid ${theme.table.border}`,
                          color: `${theme.palette.text.primary}`,
                          backgroundColor: `white`,
                        }}
                      >
                        <td className="pl-3">Negative</td>
                        {Object.entries(
                          sentimentData.sentiment_analysis_report
                            .overall_sentiment_mentions
                            .overall_sentiment_mentions.month_wise
                        ).map(([date, values]) => {
                          const totalNegative =
                            values.find(
                              (item) => item.key === "negative_sentiment"
                            )?.count || 0;
                          return (
                            <td className="pl-3" key={date}>
                              {prefixZero_for_singleDigit(totalNegative)}
                            </td>
                          );
                        })}
                        <td className="pl-3 font-weight-bold">
                          {
                            sentimentData.sentiment_analysis_report
                              .overall_sentiment_mentions
                              .overall_sentiment_mentions.total_negative
                          }
                        </td>
                        <td className="pl-3">
                          {sentimentData.sentiment_analysis_report.overall_sentiment_mentions.overall_sentiment_mentions.last_three_months_negative_percentage?.toFixed(
                            2
                          )}{" "}
                          %
                        </td>
                      </tr>

                      <tr
                        style={{
                          border: `1px solid ${theme.table.border}`,
                          color: `${theme.palette.text.primary}`,
                          backgroundColor: `${theme.table.rowColor}`,
                        }}
                      >
                        <td className="pl-3">Neutral</td>
                        {Object.entries(
                          sentimentData.sentiment_analysis_report
                            .overall_sentiment_mentions
                            .overall_sentiment_mentions.month_wise
                        ).map(([date, values]) => {
                          const totalNeutral =
                            values.find((item) => item.key === "neutral")
                              ?.count || 0;
                          return (
                            <td className="pl-3" key={date}>
                              {prefixZero_for_singleDigit(totalNeutral)}
                            </td>
                          );
                        })}
                        <td className="pl-3 font-weight-bold">
                          {
                            sentimentData.sentiment_analysis_report
                              .overall_sentiment_mentions
                              .overall_sentiment_mentions.total_neutral
                          }
                        </td>
                        <td className="pl-3">
                          {sentimentData.sentiment_analysis_report.overall_sentiment_mentions.overall_sentiment_mentions.last_three_months_neutral_percentage?.toFixed(
                            2
                          )}{" "}
                          %
                        </td>
                      </tr>

                      <tr
                        style={{
                          border: `1px solid ${theme.table.border}`,
                          color: `${theme.palette.text.primary}`,
                          backgroundColor: `${theme.palette.background.primary}`,
                        }}
                      >
                        <td className="pl-3 font-weight-bold">Total</td>
                        {Object.entries(
                          sentimentData.sentiment_analysis_report
                            .overall_sentiment_mentions
                            .overall_sentiment_mentions.month_wise
                        ).map(([date, values]) => {
                          const total =
                            values.find((item) => item.key === "total")
                              ?.count || 0;
                          return (
                            <td className="pl-3 font-weight-bold" key={date}>
                              {prefixZero_for_singleDigit(total)}
                            </td>
                          );
                        })}
                        <td className="pl-3 font-weight-bold">
                          {
                            sentimentData.sentiment_analysis_report
                              .overall_sentiment_mentions
                              .overall_sentiment_mentions.total_mentions
                          }
                        </td>
                        <td className="pl-3 font-weight-bold">
                          {sentimentData.sentiment_analysis_report.overall_sentiment_mentions.overall_sentiment_mentions.total_last_month_percentage?.toFixed(
                            2
                          )}{" "}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
            </div>
          ) : (
            !loading && <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default OverallSentimentMentions3months;
