import BaseReducer from "../../utilities/BaseReducer";
import CustomerDataAction from "./CustomerDataAction";

export default class CustomerDataByIdReduser extends BaseReducer {
  //initial state of ticket
  initialState = {
    customerById: [],
  };

  // ticket filter
  [CustomerDataAction.REQUEST_CUSTOMER_DATA_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      customerById: action.payload,
    };
  }
}
