import BaseReducer from "../../../../utilities/BaseReducer";
import CmsUploadLinksAction from "./CmsUploadLinksAction";

export default class CmsUploadLinksFilterReducer extends BaseReducer {
  initialState = {
    CmsUploadLinksFilter: [],
  };
  [CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      CmsUploadLinksFilter: action.payload,
    };
  }

  [CmsUploadLinksAction.REQUEST_CMS_UPLOAD_LINKS_FILTER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      CmsUploadLinksFilter: action.payload,
    };
  }
}
