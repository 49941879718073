import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik, Field, getIn } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import DIYReportTemplateAction from "../../stores/newReportTemplate/DIYReportTemplateAction";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  template_name: string()
    .required("Template Name is Required")
    .trim()
    .min(1, "Template name should be of minimum 1 characters length")
    .required("Template name is required"),
});

const DIYReportTemplatePost = (props) => {
  let initial = {};

  initial = {
    template_name: "",
  };

  function fetchDIYreportTemplates() {
    const params = { page_limit: "none", order_by: `[["created_on","asc"]]` };
    props.dispatch(
      DIYReportTemplateAction.requestDIYReportTemplateFilter(params)
    );
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          let object = {};

          object = {
            report_template_name: values.template_name,
            template: props.template,
          };

          props
            .dispatch(
              DIYReportTemplateAction.requestDIYPostReportTemplate(object)
            )
            .then(() => {
              fetchDIYreportTemplates();
              props.onClose();
            });
          formikHelpers.resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "90%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                          </span>
                          dd &nbsp;
                          <span className="span_first_letter">T</span>emplate
                          &nbsp;
                          <span className="span_first_letter">N</span>ame
                        </b>
                      </h3>
                    </div>
                    <div className="col-2 closebtn">
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Template Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      autoFocus
                      variant="outlined"
                      placeholder="Template Name"
                      id="template_name"
                      size="small"
                      name="template_name"
                      error={
                        Boolean(errors.template_name) &&
                        Boolean(touched.template_name)
                      }
                      helperText={
                        Boolean(touched.template_name) && errors.template_name
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    type="submit"
                    name="Add Template Name"
                    width="100%"
                    // loading={loading}
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Cancel"}
                    width="100%"
                    onClick={() => {
                      props.onClose();
                      //   Formik.resetForm();
                    }}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(DIYReportTemplatePost);
