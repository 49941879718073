import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import SubSourceModel from "./models/SubSourceModel";

export default class SubSourceEffect {
  //get subsource request end point
  static async requestSubSource(params) {
    const endpoint = environment.api.activities;

    const response = await EffectUtility.getToModel(
      SubSourceModel,
      endpoint,
      params
    );
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    localStorage.setItem("activities", JSON.stringify(response));

    return response;
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
