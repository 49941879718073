import BaseReducer from "../../../utilities/BaseReducer";
import RolePermissionAction from "./RolePermissionsAction";

export default class RolePermissionsReducer extends BaseReducer {
  initialState = {
    rolePermission: [],
  };

  [RolePermissionAction.REQUEST_ROLEPERMISSIONS_FINISHED](state, action) {
    return {
      ...state,
      rolePermission: action.payload,
    };
  }
}
