import BaseReducer from "../../utilities/BaseReducer";
import ResourceAction from "./ResourceAction";

export default class ResourceFilterReducer extends BaseReducer {
  //initial state of resource filter
  initialState = {
    resourceFilter: [],
  };

  // resource filter
  [ResourceAction.REQUEST_RESOURCE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      resourceFilter: action.payload,
    };
  }
}
