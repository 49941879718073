import BaseReducer from "../../../utilities/BaseReducer";
import InstagramFeedsAction from "./InstagramFeedsAction";

export default class InstagramFeedsByIdReducer extends BaseReducer {
  initialState = {
    instagramFeedById: {},
  };

  [InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_BYID_FINISHED](state, action) {
    return {
      ...state,
      instagramFeedById: action.payload,
    };
  }
}
