import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencersCampaignsAction from "./InfluencersCampaignsAction";

//InfluencersCampaignByIdReduser
export default class InfluencersCampaignByIdReduser extends BaseReducer {
  initialState = {
    InfluencersCampaignById: [],
  };
  [InfluencersCampaignsAction.REQUEST_INFLUENCERS_CAMPAIGNS_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      InfluencersCampaignById: action.payload,
    };
  }
}
