import {
  Box,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import ProjectAction from "../../ormTicketing/stores/project/projectAction";
import MuiButton from "../muiButton/MuiButton";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { useLocation, useNavigate } from "react-router";
// eslint-disable-next-line no-unused-vars
import { replace } from "lodash";

import AutoCompleteMultiSelect from "../autoCompleteComponent/AutoCompleteMultiSelect";
import UserLastWorkingProjectAction from "../../ormTicketing/stores/userLastWorkingProject/UserLastWorkingProjectAction";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import PagePermissionAction from "../../stores/pagePermission/PagePermissionAction";
import StatusAction from "../../ormTicketing/stores/status/StatusAction";
import OrganizationAction from "../../common/stores/organization/OrganizationAction";
import ClientPost from "../../common/components/clientPost/ClientPost";
import ProjectPost from "../../common/components/projectPostForm/ProjectPost";
import DIYReportTemplateAction from "../../ormTicketing/stores/newReportTemplate/DIYReportTemplateAction";
// mapState to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    ProjectAction.REQUEST_PROJECT,
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
    OrganizationAction.REQUEST_ORGANIZATION,
    // UserLastWorkingProjectAction.REQUEST_POST_LAST_WORKING_PROJECT,
  ]),
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
  projectfilter: state.projectfilter.projectfilter || [],
  organization: state.organization.organization.hits || [],
});

function SelectProject(props) {
  const navigate = useNavigate();
  const location = useLocation();

  function fetchProjectFilter(filter_params) {
    props.dispatch(ProjectAction.requestProjectFilter(filter_params));
  }

  function fetchLastWorkingProject(id) {
    props.dispatch(
      UserLastWorkingProjectAction.requestLastWorkingProjectById(id)
    );
  }

  function fetchOrganization(params) {
    props.dispatch(OrganizationAction.requestOrganization(params));
  }

  const { isRequesting } = props;
  //   UseEffect function
  useEffect(() => {
    const params = {
      page_limit: "none",
      order_by: `[["project_name.keyword", "asc"]]`,
    };
    const org_params = {
      page_limit: "none",
    };
    fetchProjectFilter(params);
    let ID = localStorage.getItem("userId");
    // fetchLastWorkingProject(ID);
    fetchOrganization(org_params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function organizationName(item) {
  //   return props.organization.hits
  //     ? props.organization.hits.map((val) =>
  //         item === val._id ? val._source.organization_name : null
  //       )
  //     : null;
  // }

  let project_nameList = [];
  if (props.projectfilter.hits) {
    props.projectfilter.hits.hits &&
      props.projectfilter.hits.hits.length &&
      props.projectfilter.hits.hits.map((item) => {
        return project_nameList.push({
          project_id: item._id,
          project_name: item._source.project_name,
          organization_name: item._source.organization_id.organization_name,
          organization_id: item._source.organization_id.organization_id,
        });
      });
  }

  let organization_array = [];

  if (props.organization.hits) {
    props.organization.hits.map((item) => {
      return organization_array.push({
        organization_id: item._id,
        organization_name: item._source.organization_name,
        mapped_platforms: item._source.platform,
      });
    });
  }

  const project_initial = {
    project:
      props.lastWorkingProject.found === true
        ? props.lastWorkingProject._source.project
        : null,

    user_id: localStorage.getItem("userId"),
  };

  const project_validation = yup.object({
    project: yup
      .object()
      .shape({})
      .required("Project Name is Required")
      .nullable("Project Name is Required"),
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectOpen, setProjectOpen] = useState(false);
  const [clientArray, setClientArray] = useState([]);

  const callBackFunction = (obj) => {
    localStorage.setItem(
      "user_last_working_project",
      JSON.stringify(obj.project)
    );

    let param = {
      page_limit: "none",
    };

    props
      .dispatch(PagePermissionAction.requestPagePermission(param))
      .then(() => {
        navigate("/dashboard", {
          state: { from: location.pathname },
        });
        replace = true;
        props.onClose();
      });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xs">
      <ProjectPost
        open={projectOpen}
        onSubmit={() => {}}
        onClose={(resp) => {
          setProjectOpen(false);
          let obj = {
            project: {
              project_id: resp.id,
              project_name: resp.data.project_name,
            },
            user_id:
              localStorage.getItem("userId") && localStorage.getItem("userId"),
          };

          props
            .dispatch(
              UserLastWorkingProjectAction.requestPostLastWorkingProject(
                JSON.stringify(obj),
                callBackFunction(obj)
              )
            )
            .then(() => {
              props.dispatch(
                UserLastWorkingProjectAction.requestLastWorkingProjectById(
                  localStorage.getItem("userId")
                )
              );
            });
        }}
        client_array={clientArray ? clientArray : []}
        organizationArray={
          organization_array.length > 0 ? organization_array : []
        }
      />
      <ClientPost
        open={open}
        onSubmit={() => {}}
        onClose={(resp) => {
          setOpen(false);
          if (resp.status === "success") {
            setProjectOpen(true);

            setClientArray([
              {
                client_id: resp.id,
                client_name: resp.data.client_name,
                organization_id: resp.data.organization.organization_name,
              },
            ]);
          }
        }}
        // projectEditData={editRowsModel}
        // edit={edit}
        organization_array={organization_array}
      />

      <LoadingIndicator isActive={isRequesting}>
        {!isRequesting && (
          <Formik
            initialValues={project_initial}
            validationSchema={project_validation}
            onSubmit={(values, formikHelpers) => {
              setLoading(true);
              if (values.project.project_name !== "") {
                let obj = {
                  project: {
                    project_id: values.project.project_id,
                    project_name: values.project.project_name,
                    organization_id: values.project.organization_id,
                  },
                  user_id: values.user_id,
                };

                props
                  .dispatch(
                    UserLastWorkingProjectAction.requestPostLastWorkingProject(
                      JSON.stringify(obj)
                    )
                  )
                  .then(() => {
                    setLoading(false);
                    props.dispatch(
                      UserLastWorkingProjectAction.requestLastWorkingProjectById(
                        localStorage.getItem("userId")
                      )
                    );

                    let param = {
                      page_limit: "none",
                    };

                    props
                      .dispatch(
                        PagePermissionAction.requestPagePermission(param)
                      )
                      .then(async () => {
                        await props.dispatch(StatusAction.requestStatusClear());
                        await props.dispatch(
                          StatusAction.requestStatusFilterClear()
                        );

                        if (location.state && location.state.from) {
                          if (location.state.from === "/") {
                            navigate(`/dashboard`, {
                              state: { action: "initial", data: obj },
                            });
                            replace = true;

                            let report_id =
                              localStorage.getItem("default_dashboard") &&
                              JSON.parse(
                                localStorage.getItem("default_dashboard")
                              );
                            JSON.parse(
                              localStorage.getItem("default_dashboard")
                            ) &&
                              props.dispatch(
                                DIYReportTemplateAction.requestDIYReportsById(
                                  report_id
                                )
                              );
                          } else {
                            navigate(`${location.state.from}`);
                          }
                        } else {
                          navigate("/");
                        }
                      });

                    localStorage.setItem(
                      "user_last_working_project",
                      JSON.stringify(obj.project)
                    );

                    localStorage.getItem("dashboard_filter_priority") &&
                      localStorage.removeItem("dashboard_filter_priority");

                    localStorage.getItem("dashboard_platform_filter") &&
                      localStorage.removeItem("dashboard_platform_filter");

                    localStorage.getItem("dashboard_sources_filter") &&
                      localStorage.removeItem("dashboard_sources_filter");

                    localStorage.getItem("dashboard_time_filter") &&
                      localStorage.removeItem("dashboard_time_filter");

                    localStorage.getItem("dashboard_activities_filter") &&
                      localStorage.removeItem("dashboard_activities_filter");

                    localStorage.getItem("dashboard_TATactivities_filter") &&
                      localStorage.removeItem("dashboard_TATactivities_filter");

                    localStorage.getItem("mentionsFilters") &&
                      localStorage.removeItem("mentionsFilters");

                    localStorage.getItem("selected_platform_for_mentions") &&
                      localStorage.removeItem("selected_platform_for_mentions");

                    localStorage.getItem("filtersHistory") &&
                      localStorage.removeItem("filtersHistory");

                    localStorage.getItem("selected_tickets_for_bulk_update") &&
                      localStorage.removeItem(
                        "selected_tickets_for_bulk_update"
                      );
                  });
              } else {
                alert("Please Select a valid Project");
              }
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form>
                <DialogContent>
                  <DialogContentText>
                    <div className="text-left">
                      <div className="row px-3">
                        <div className="col-12">
                          <h4 align="left" className="pl-3">
                            <b>
                              <span className="span_first_letter">S</span>
                              elect &nbsp;
                              <span className="span_first_letter">P</span>roject
                              &nbsp;
                            </b>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </DialogContentText>

                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* <Grid item lg={2} md={2} sm={2} mt={1}>
                      Project Name :
                    </Grid> */}
                      <Grid item lg={10} md={8} sm={8}>
                        <Field
                          name="project"
                          size="small"
                          // Calling AutoCompleteMultiSelect Component
                          value={values.project ? values.project : null}
                          component={AutoCompleteMultiSelect}
                          options={project_nameList}
                          isOptionEqualToValue={(option, value) =>
                            //setting value property to custom value
                            option.project_name === value.project_name
                          }
                          getOptionLabel={(option) =>
                            option.project_name ? option.project_name : ""
                          }
                          filterOptions={createFilterOptions({
                            matchFrom: "start",
                            ignoreCase: true,
                            stringify: (option) => option.project_name,
                          })}
                          handleHomeEndKeys
                          renderOption={(props, option) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                              }}
                              {...props}
                            >
                              <span
                                style={{
                                  flex: 1,
                                  textAlign: "left",
                                  wordBreak: "break-all",
                                }}
                              >
                                {option.project_name}
                              </span>
                              <span style={{ flex: 0.2, textAlign: "center" }}>
                                {" - "}
                              </span>
                              <span
                                style={{
                                  flex: 1,
                                  textAlign: "left",
                                  wordBreak: "break-all",
                                }}
                              >
                                {option.organization_name}
                              </span>
                            </div>
                          )}
                          textFieldProps={{
                            margin: "normal",
                            variant: "outlined",
                            placeholder: "Project",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>

                <Box width="73%" margin="auto" mb={2}>
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="flex-end"
                    alignItems="center"
                    // className="bg-warning"
                  >
                    {props.projectfilter &&
                      props.projectfilter.status === "skip" && (
                        <Grid item xl={3} lg={2.5} md={2.5} sm={2.5} mx={2.5}>
                          <MuiButton
                            name={"Skip "}
                            // loading={loading}
                            // noTextOnLoading={true}
                            width="100%"
                            onClick={() => {
                              setOpen(true);
                            }}
                          />
                        </Grid>
                      )}

                    <Grid item xl={3} lg={2.5} md={2.5} sm={2.5} mx={2.5}>
                      <MuiButton
                        name={"Submit "}
                        loading={loading}
                        noTextOnLoading={true}
                        type="submit"
                        width="100%"
                      />
                    </Grid>

                    <Grid item xl={3} lg={2.5} md={2.5} sm={2.5}>
                      <MuiButton
                        name={"Cancel"}
                        width="100%"
                        onClick={() => {
                          // props.setOpen(false)
                          if (location) {
                            if (location.state.from === "/") {
                              navigate("/");
                              localStorage.setItem("open", true);
                              // window.location.reload();
                            } else {
                              navigate(location.state.from);
                              // localStorage.setItem("open", true);
                            }
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </LoadingIndicator>
    </Dialog>
  );
}

export default connect(mapStateToProps)(SelectProject);
