import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import QuoraFeedsModel from "./model/QuoraFeedsModel";
import QuoraFeedsPostModel from "./model/QuoraFeedsPostModel";
import QuoraFeedsPutModel from "./model/QuoraFeedsPutModel";

// QuoraFeedEffect
export default class QuoraFeedEffect {
  // calling an API according to the Quora Feeds model
  // Get method for the Quora Feeds
  static async requestQuoraFeed(params, callBackFunction) {
    const endpoint = environment.api.quoraFeed;
    let response = EffectUtility.getToModel(QuoraFeedsModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestQuoraFeedClear() {
    return {};
  }

  // Get method for the Quora Feeds filter
  static async requestQuoraFeedFilter(params) {
    const endpoint = environment.api.quoraFeedFilter;
    return EffectUtility.getToModel(QuoraFeedsModel, endpoint, params);
  }

  // put method for the Quora Feeds filter
  static async requestPutQuoraFeed(data, id) {
    const endpoint = environment.api.quoraFeed + "/" + id;
    return EffectUtility.putToModel(QuoraFeedsPutModel, endpoint, data);
  }

  // post method for the Quora Feeds filter
  static async requestPostQuoraFeed(data) {
    const endpoint = environment.api.quoraFeed;

    return EffectUtility.postToModel(QuoraFeedsPostModel, endpoint, data);
  }
}
