import BaseReducer from "../../../utilities/BaseReducer";
import DiscordFeedsAction from "./DiscordFeedsAction";

export default class DiscordFeedsFilterReduser extends BaseReducer {
  initialState = {
    discordFilter: [],
  };

  [DiscordFeedsAction.REQUEST_DISCORD_FILTER_FINISHED](state, action) {
    return {
      ...state,
      discordFilter: action.payload,
    };
  }
}
