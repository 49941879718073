import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";
import * as echarts from "echarts";
function BubbleChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data1, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const data = [
    [
      [28604, 77, 17096869, "Jan", 2022 - 2023],
      [31163, 77.4, 27662440, "Feb", 2022 - 2023],
      [1516, 68, 1154605773, "Mar", 2022 - 2023],
      [13670, 74.7, 10582082, "Apr", 2022 - 2023],
      [28599, 75, 4986705, "May", 2022 - 2023],
      [29476, 77.1, 56943299, "Jun", 2022 - 2023],
      [31476, 75.4, 78958237, "Jul", 2022 - 2023],
      [28666, 78.1, 254830, "Aug", 2022 - 2023],
      [1777, 57.7, 870601776, "Sept", 2022 - 2023],
      [29550, 79.1, 122249285, "Oct", 2022 - 2023],
      [2076, 67.9, 20194354, "Nov", 2022 - 2023],
      [12087, 72, 42972254, "Dec", 2022 - 2023],
    ],
    [
      [44056, 81.8, 23968973, "Jan", 2023 - 2024],
      [43294, 81.7, 35939927, "Feb", 2023 - 2024],
      [13334, 76.9, 1376048943, "Mar", 2023 - 2024],
      [21291, 78.5, 11389562, "Apr", 2023 - 2024],
      [38923, 80.8, 5503457, "May", 2023 - 2024],
      [37599, 81.9, 64395345, "Jun", 2023 - 2024],
      [44053, 81.1, 80688545, "Jul", 2023 - 2024],
      [42182, 82.8, 329425, "Aug", 2023 - 2024],
      [5903, 66.8, 1311050527, "Sept", 2023 - 2024],
      [36162, 83.5, 126573481, "Oct", 2023 - 2024],
      [1390, 71.4, 25155317, "Nov", 2023 - 2024],
      [34644, 80.7, 50293439, "Dec", 2023 - 2024],
    ],
  ];
  const getOption = {
    // tooltip: {
    //   formatter: function (params) {
    //     return `${params.seriesName}</br>${params.color} : ${params.data[3]}`;
    //   },
    //   trigger: "item",
    // },
    legend: {
      right: "10%",
      top: "3%",
      data: ["2022-2023", "2023-2024"],
    },
    grid: {
      left: "8%",
      top: "10%",
    },
    xAxis: {
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      scale: true,
    },
    series: [
      {
        name: "2022-2023",
        data: data[0],
        type: "scatter",
        symbolSize: function (data) {
          return Math.sqrt(data[2]) / 5e2;
        },
        emphasis: {
          focus: "series",
          label: {
            show: true,
            formatter: function (param) {
              return param.data[3];
            },
            position: "top",
          },
        },
        itemStyle: {
          color: "#9BB2AE",
        },
      },
      {
        name: "2023-2024",
        data: data[1],
        type: "scatter",
        symbolSize: function (data) {
          return Math.sqrt(data[2]) / 5e2;
        },
        emphasis: {
          focus: "series",
          label: {
            show: true,
            formatter: function (param) {
              return param.data[3];
            },
            position: "top",
          },
        },
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(25, 100, 150, 0.5)",
          shadowOffsetY: 5,
          color: "#468C9D",
        },
      },
    ],
  };
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default BubbleChart;
