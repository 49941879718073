import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import StatusAction from "../../../../stores/status/StatusAction";
import TicketHistoryAction from "../../../../stores/ticketHistory/TicketHistoryAction";
// import AdjustIcon from "@mui/icons-material/Adjust";
import { theme } from "../../../../../views/App";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    // TicketHistoryAction.REQUEST_TICKET_HISTORY,
    StatusAction.REQUEST_STATUS_FILTER,
  ]),
  ticketHistory: state.ticketHistory.ticketHistory || [],
  statusFilter: state.statusFilter.statusFilter || [],
});

const TicketTimeLineTab = (props) => {
  function fetchTicketHistory() {
    const filter = [];

    let params = {
      filters: `[["ticket_id.keyword", "must", "", "match", ${JSON.stringify(
        props.ticketData._id
      )}]]`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(TicketHistoryAction.requestTicketHistory(params));
  }

  function fetchStatusFilter() {
    let param = {
      page_limit: "none",
      order_by: `[["status_name.keyword","asc"]]`,
    };
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  useEffect(() => {
    if (Object.keys(props.ticketData).length) {
      // fetchTicketHistory();
    } else {
      props.dispatch(TicketHistoryAction.requestTicketHistoryClear());
    }
  }, [props.ticketData]);

  let statusList = [];
  // if (props.statusFilter.hits) {
  //   props.statusFilter.hits.hits.forEach((item) => {
  //     statusList.push({
  //       status_id: item._id,
  //       status_name: item._source.status_name,
  //     });
  //   });
  // }

  const returnStatusName = (statusId) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusId;
      });

    return filtered_status.length > 0 ? filtered_status[0].status_name : "";
  };

  function CreatedOnDate(item) {
    let UdateConversion = new Date(item);
    UdateConversion =
      UdateConversion.toDateString() +
      ", " +
      UdateConversion.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

    return UdateConversion;
  }

  return (
    <Box
      width={"100%"}
      sx={{
        paddingY: 1,
        paddingLeft: 1,
        height: "100%",
      }}
      className="scrollable"
    >
      <Timeline
        align="left"
        className="p-0 m-0"
        sx={
          {
            // overflow: "hidden",
            // overflowX: "hidden",
            // "&:hover": {
            //   overflow: "auto",
            //   overscrollBehaviorY: "scroll",
            // },
          }
        }
      >
        {props.ticketHistory.hits &&
          props.ticketHistory.hits.hits.map((item, index) => (
            <Box className="p-0 m-0">
              <TimelineItem className="p-0 m-0">
                <TimelineSeparator sx={{ marginX: "7px" }}>
                  {/* <AdjustIcon
                    sx={{
                      border: "#d3e2e6",
                      color: "red",
                    }}
                  /> */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "6px",
                        height: "6px",
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.main,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        border: `1px solid ${theme.palette.appbar.icons}`,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    ></div>
                  </div>
                  {index < props.ticketHistory.hits.hits.length - 1 && (
                    <TimelineConnector
                      sx={{
                        marginTop: "7px",
                        background: "#d3e2e6",
                      }}
                    />
                  )}
                </TimelineSeparator>
                <TimelineOppositeContent sx={{ flex: 1 }}>
                  <Box sx={{ textAlign: "left" }}>
                    <div
                      className="p-0 m-0 font-weight-bold"
                      style={{
                        color: "#86aeb6",
                      }}
                    >
                      {item._source.description}
                    </div>
                    <div
                      className="p-0 m-0"
                      style={{
                        color: "#86aeb6",
                      }}
                    >
                      {CreatedOnDate(item._source.created_on)}
                    </div>
                  </Box>
                </TimelineOppositeContent>
              </TimelineItem>
            </Box>
          ))}
      </Timeline>
    </Box>
  );
};

export default connect(mapStateToProps)(TicketTimeLineTab);
