import { BaseModel } from "sjs-base-model";

export default class RolePermissionModel extends BaseModel {
  _shards = {};
  current_page_no = "";
  hits = {};
  total_page_no = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
