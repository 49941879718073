import React, { useState } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import ToastsAction from "../../stores/toasts/ToastsAction";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Button, IconButton } from "@mui/material";
import MuiSnackbarAlert from "../muiSnackbarAlert/MuiSnackbarAlert";
const mapStateToProps = (state) => ({
  toasts: state.toasts,
});

function Toasts(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  if (props.toasts.items.length === 0) {
    return null;
  }

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const action = (key, model) => {
    if (model.type === "success") {
      setTimeout(() => {
        props.dispatch(ToastsAction.removeById(key));
        closeSnackbar(key);
      }, 2000);
    }

    return (
      <>
        {model.type !== "success" ? (
          <>
            {model?.errorData?.error?.error_id && (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setOpenAlert(true);
                  model?.errorData?.error?.exception_type
                    ? setAlertTitle(model?.errorData?.error?.exception_type)
                    : setAlertTitle(model?.errorData?.error?.message);

                  let { error_id, time, endpoint } = model?.errorData?.error;
                  const message = (
                    <React.Fragment key={"Error-alert"}>
                      <ul>
                        <li>
                          <div key={"error_id"}>Error ID : {error_id}</div>
                          <div key={"Endpoint"}>Endpoint : {endpoint}</div>
                          <div key={"time"}>Time : {time}</div>
                        </li>
                      </ul>
                    </React.Fragment>
                  );

                  model?.errorData?.error?.error_id
                    ? setAlertMessage(message)
                    : setAlertMessage(model?.errorData?.error?.message);
                }}
              >
                <RemoveRedEyeIcon fontSize="small" />
              </IconButton>
            )}
            <Button
              onClick={() => {
                props.dispatch(ToastsAction.removeById(key));
                closeSnackbar(key);
              }}
            >
              Dismiss
            </Button>
          </>
        ) : null}
      </>
    );
  };

  // let filtered_array = [];

  // if (props.toasts.items) {
  //   let array = props.toasts.items;
  //   let type = "error";

  //   for (let index = 0; index < array.length; index++) {
  //     if (array[index].type === type) {
  //       filtered_array.push(array[index]);
  //       break;
  //     } else if (array[index].type !== type) {
  //       filtered_array.push(array[index]);
  //     }
  //   }
  // }

  // Enqueue Snackbar outside of the map to control the flow properly
  props.toasts.items.forEach((model) => {
    enqueueSnackbar(
      model.type === "success"
        ? model.message && typeof model.message === "string"
          ? model.message
          : "Success"
        : Array.isArray(model.messagesString)
        ? model.message
        : model.messagesString,
      {
        preventDuplicate: true,
        autoHideDuration: model.type === "success" ? 2000 : null,
        action: action(model.id, model),
        key: model.id,
        variant: model.type,
      }
    );
  });

  return (
    <>
      <MuiSnackbarAlert
        open={openAlert}
        onClose={handleCloseAlert}
        message={alertMessage}
        title={alertTitle}
        severity="error"
      />
    </>
  );
}

export { Toasts as Unconnected };

export default connect(mapStateToProps)(Toasts);
