import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import GMBAccountPostModel from "./models/GMBAccountPostModel";
import GMBAccountPutModel from "./models/GMBAccountPutModel";
import GMBAccountDeleteModel from "./models/GMBAccountDeleteModel";
import GMBAccountModel from "./models/GMBAccountGetModel";

export default class GMBAccountEffect {
  // calling an API according to the GMB Account model

  // Get method for the GMB account
  static async requestGMBAccount(params) {
    const endpoint = environment.api.gmbAccount;
    return EffectUtility.getToModel(GMBAccountModel, endpoint, params);
  }

  // Get method for the GMB account filter
  static async requestGMBAccountFilter(params) {
    const endpoint = environment.api.gmbAccount;
    return EffectUtility.getToModel(GMBAccountModel, endpoint, params);
  }

  // put method for the GMB account filter
  static async requestPutGMBAccount(data, id) {
    const endpoint = environment.api.gmbAccount + "/" + id;
    return EffectUtility.putToModel(GMBAccountPutModel, endpoint, data);
  }

  // post method for the GMB account filter
  static async requestPostGMBAccount(data) {
    const endpoint = environment.api.gmbAccount + "/add";

    return EffectUtility.postToModel(GMBAccountPostModel, endpoint, data);
  }

  static async requestDeleteGMBAccount(id) {
    const endpoint = environment.api.gmbAccount + "/" + id;

    return EffectUtility.deleteToModel(GMBAccountDeleteModel, endpoint);
  }
}
