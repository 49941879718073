import environment from "environment";
import InfluencersCampaignModel from "./model/InfluencersCampaignModel";
import InfluencersCampaignPostModel from "./model/InfluencersCampaignPostModel";
import InfluencersCampaignPutModel from "./model/InfluencersCampaignPutModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import InfluencersCampaignByIdModel from "./model/InfluencersCampaignByIdModel";

// InfluencersCampaignsEffect
export default class InfluencersCampaignsEffect {
  // calling an API according to the Influencers model
  // Get method for the
  static async requestInfluencerCampaigns(params) {
    const endpoint = environment.api.influencersCampaign;
    return EffectUtility.getToModel(InfluencersCampaignModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestInfluencersFilter(params) {
    const endpoint = environment.api.influencersCampaignFilter;
    return EffectUtility.getToModel(InfluencersCampaignModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutInfluencersCampaigns(data, id) {
    const endpoint = environment.api.influencersCampaign + "/" + id;
    return EffectUtility.putToModel(
      InfluencersCampaignPutModel,
      endpoint,
      data
    );
  }

  // Get method for the client filter
  static async requestInfluencerCampaignsById(id, campaigncallback) {
    const endpoint = environment.api.influencersCampaign + "/" + id;
    let Response = EffectUtility.getToModel(
      InfluencersCampaignByIdModel,
      endpoint
    );

    Response.then((resp) => {
      resp.found && campaigncallback && campaigncallback(resp);
    });

    return Response;
  }

  // post method for the Organization
  static async requestPostInfluencersCampaigns(data) {
    const endpoint = environment.api.influencersCampaign + "/add";

    return EffectUtility.postToModel(
      InfluencersCampaignPostModel,
      endpoint,
      data
    );
  }
}
