import ActionUtility from "../../../utilities/ActionUtility";
import InstagramFeedsEffect from "./InstagramFeedsEffect";

export default class InstagramFeedsAction {
  //Instagram feeds requests
  static REQUEST_INSTAGRAM_FEEDS =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS";
  static REQUEST_INSTAGRAM_FEEDS_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_FINISHED";

  //Instagram feeds requests by ID
  static REQUEST_INSTAGRAM_FEEDS_BYID =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_BYID";
  static REQUEST_INSTAGRAM_FEEDS_BYID_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_BYID_FINISHED";

  // instagram Filter
  static REQUEST_INSTAGRAM_FILTER =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FILTER";
  static REQUEST_INSTAGRAM_FILTER_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FILTER_FINISHED";

  static REQUEST_INSTAGRAM_REPLY_POST =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_REPLY_POST";
  static REQUEST_INSTAGRAM_REPLY_POST_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_REPLY_POST_FINISHED";

  static REQUEST_THREADS_REPLY_POST =
    "InstagramFeedsAction.REQUEST_THREADS_REPLY_POST";
  static REQUEST_THREADS_REPLY_POST_FINISHED =
    "InstagramFeedsAction.REQUEST_THREADS_REPLY_POST_FINISHED";

  static REQUEST_INSTAGRAM_COMMENT_POST =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_POST";
  static REQUEST_INSTAGRAM_COMMENT_POST_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_POST_FINISHED";

  static REQUEST_PUT_INSTAGRAM_FEEDS =
    "InstagramFeedsAction.REQUEST_PUT_INSTAGRAM_FEEDS";
  static REQUEST_PUT_INSTAGRAM_FEEDS_FINISHED =
    "InstagramFeedsAction.REQUEST_PUT_INSTAGRAM_FEEDS_FINISHED";

  static REQUEST_INSTAGRAM_COMMENT_HIDE =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_HIDE";
  static REQUEST_INSTAGRAM_COMMENT_HIDE_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_HIDE_FINISHED";

  static REQUEST_INSTAGRAM_COMMENT_DELETE =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_DELETE";
  static REQUEST_INSTAGRAM_COMMENT_DELETE_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_DELETE_FINISHED";

  static REQUEST_INSTAGRAM_FEEDS_CLEAR =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_CLEAR";
  static REQUEST_INSTAGRAM_FEEDS_CLEAR_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_CLEAR_FINISHED";

  static REQUEST_INSTAGRAM_POST = "InstagramFeedsAction.REQUEST_INSTAGRAM_POST";
  static REQUEST_INSTAGRAM_POST_FINISHED =
    "InstagramFeedsAction.REQUEST_INSTAGRAM_POST_FINISHED";

  //tHREADS requests
  static REQUEST_THREADS = "InstagramFeedsAction.REQUEST_THREADS";
  static REQUEST_THREADS_FINISHED =
    "InstagramFeedsAction.REQUEST_THREADS_FINISHED";

  static REQUEST_PUT_THREADS_FEEDS =
    "InstagramFeedsAction.REQUEST_PUT_THREADS_FEEDS";
  static REQUEST_PUT_THREADS_FEEDS_FINISHED =
    "InstagramFeedsAction.REQUEST_PUT_THREADS_FEEDS_FINISHED";

  //METHODS

  //instagramfeeds get method
  static requestInstagramFeeds(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS,
        InstagramFeedsEffect.requestInstagramFeeds,
        params,
        callBackFunction
      );
    };
  }

  static requestThreads(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_THREADS,
        InstagramFeedsEffect.requestThreads,
        params,
        callBackFunction
      );
    };
  }

  static requestPutThreadsFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_PUT_THREADS_FEEDS,
        InstagramFeedsEffect.requestPutThreadsFeeds,
        data,
        id
      );
    };
  }

  static requestInstagramFeeds(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS,
        InstagramFeedsEffect.requestInstagramFeeds,
        params,
        callBackFunction
      );
    };
  }

  static requestInstagramFeedsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_CLEAR,
        InstagramFeedsEffect.requestInstagramFeedsClear
      );
    };
  }

  //instagramfeeds get method by id
  static requestInstagramFeedsById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_FEEDS_BYID,
        InstagramFeedsEffect.requestInstagramFeedsById,
        id
      );
    };
  }

  static requestPutInstaFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_PUT_INSTAGRAM_FEEDS,
        InstagramFeedsEffect.requestPutInstaFeeds,
        data,
        id
      );
    };
  }

  static requestInstagramFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_FILTER,
        InstagramFeedsEffect.requestInstagramFilter,
        params
      );
    };
  }

  static requestThreadsReplyPost(id, data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_THREADS_REPLY_POST,
        InstagramFeedsEffect.requestThreadsReplyPost,
        id,
        data,
        callback
      );
    };
  }

  static requestInstagramReplyPost(id, data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_REPLY_POST,
        InstagramFeedsEffect.requestInstagramReplyPost,
        id,
        data,
        callback
      );
    };
  }

  static requestInstagramCommentPost(id, data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_POST,
        InstagramFeedsEffect.requestInstagramCommentPost,
        id,
        data,
        callback
      );
    };
  }

  static requestInstagramCommentHide(id, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_HIDE,
        InstagramFeedsEffect.requestInstagramCommentHide,
        id,
        data
      );
    };
  }

  static requestInstagramCommentDelete(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_COMMENT_DELETE,
        InstagramFeedsEffect.requestInstagramCommentDelete,
        id
      );
    };
  }

  static requestInstagramPost(queryParams, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InstagramFeedsAction.REQUEST_INSTAGRAM_POST,
        InstagramFeedsEffect.requestInstagramPost,
        queryParams,
        data
      );
    };
  }
}
