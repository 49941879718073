import BaseReducer from "../../../../utilities/BaseReducer";
import CannedMessageAction from "../CannedMessageAction";

// CannedMessageReduser
export default class CannedMessageReduser extends BaseReducer {
  initialState = {
    cannedMessage: [],
  };
  [CannedMessageAction.REQUEST_CANNED_MESSAGE_FINISHED](state, action) {
    return {
      ...state,
      cannedMessage: action.payload,
    };
  }
}
