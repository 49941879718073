import { BaseModel } from "sjs-base-model";

//Google delete Model
export default class GoogleAccountDeleteModel extends BaseModel {
  result = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
