import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// Category data model
export default class UserPermissionDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  created_by = {
    user_id:"",
    user_name:"",
  }
  created_on ="";
  modified_by = {};
  modified_on = "";
  resource = "";
  resource_id = "";
  user_id = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
