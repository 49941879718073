import BaseReducer from "../../../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

export default class TrackwiseRevenueReduser extends BaseReducer {
  initialState = {
    trackwiseRevenue: [],
  };
  [A3ReportsAction.REQUEST_TRACKWISE_REVENUE_FINISHED](state, action) {
    return {
      ...state,
      trackwiseRevenue: action.payload,
    };
  }
}
