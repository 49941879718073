import ActionUtility from "../../../../utilities/ActionUtility";
import ActivityTypeEffect from "./ActivityTypeEffect";

export default class ActivityTypeAction {
  static REQUEST_ACTIVITY_TYPE = "ActivityTypeAction.REQUEST_ACTIVITY_TYPE";
  static REQUEST_ACTIVITY_TYPE_FINISHED =
    "ActivityTypeAction.REQUEST_ACTIVITY_TYPE_FINISHED";

  static REQUEST_ACTIVITY_TYPE_FILTER =
    "ActivityTypeAction.REQUEST_ACTIVITY_TYPE_FILTER";
  static REQUEST_ACTIVITY_TYPE_FILTER_FINISHED =
    "ActivityTypeAction.REQUEST_ACTIVITY_TYPE_FILTER_FINISHED";

  static REQUEST_ACTIVITY_TYPE_POST =
    "ActivityTypeAction.REQUEST_ACTIVITY_TYPE_POST";
  static REQUEST_ACTIVITY_TYPE_POST_FINISHED =
    "ActivityTypeAction.REQUEST_ACTIVITY_TYPE_POST_FINISHED";

  static REQUEST_ACTIVITY_TYPE_PUT =
    "ActivityTypeAction.REQUEST_ACTIVITY_TYPE_PUT";
  static REQUEST_ACTIVITY_TYPE_PUT_FINISHED =
    "ActivityTypeAction.REQUEST_ACTIVITY_TYPE_PUT_FINISHED";

  // METHODS

  //GET
  static requestActivityType(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActivityTypeAction.REQUEST_ACTIVITY_TYPE,
        ActivityTypeEffect.requestActivityType,
        params
      );
    };
  }

  // GET for dropdown
  static requestActivityTypeFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActivityTypeAction.REQUEST_ACTIVITY_TYPE_FILTER,
        ActivityTypeEffect.requestActivityTypeFilter,
        params
      );
    };
  }

  // POST
  static requestPostActivityType(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActivityTypeAction.REQUEST_ACTIVITY_TYPE_POST,
        ActivityTypeEffect.requestPostActivityType,
        data
      );
    };
  }

  // PUT
  static requestPutActivityType(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActivityTypeAction.REQUEST_ACTIVITY_TYPE_PUT,
        ActivityTypeEffect.requestPutActivityType,
        data,
        id
      );
    };
  }
}
