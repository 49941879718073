import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import WordCloudModel from "./models/WordCloudModel";
import WordCloudPostModel from "./models/WordCloudPostModel";
import WordCloudPutModel from "./models/WordCloudPutModel";

export default class WordCloudEffect {
  static async requestWordCloud(params) {
    const endpoint = environment.api.wordCloudSettings;

    return EffectUtility.getToModel(WordCloudModel, endpoint, params);
  }

  // Post API
  static async requestPostWordCloud(data) {
    const endpoint = environment.api.wordCloudSettings + "/add";

    return EffectUtility.postToModel(WordCloudPostModel, endpoint, data);
  }

  // Put API
  static async requestPutWordCloud({ positive_words, negative_words }, id) {
    const endpoint = environment.api.wordCloudSettings + "/" + id;
    return EffectUtility.putToModel(WordCloudPutModel, endpoint, {
      positive_words,
      negative_words,
    });
  }
}
