import BaseReducer from "../../../../utilities/BaseReducer";
import SessionLogAction from "./SessionLogAction";

// SessionLogReuser
export default class SessionLogReducer extends BaseReducer {
  initialState = {
    sessionLog: [],
  };
  [SessionLogAction.REQUEST_SESSION_LOG_FINISHED](state, action) {
    return {
      ...state,
      sessionLog: action.payload,
    };
  }
}
