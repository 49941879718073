// SpeedTestComponent.js
import React, { useState } from "react";
import axios from "axios";
import { internetCheckDomain } from "../InternetSpeedTest";
import { Box, Divider, Typography } from "@mui/material";
import MuiButton from "../../../components/muiButton/MuiButton";
import { ShadowContainer } from "../../../components/componentLayout/ComponentLayout";

const SpeedTestComponent = () => {
  const [speedTestResult, setSpeedTestResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fastSpeedTestResult, setFastSpeedTestResult] = useState(null);
  const [fastSpeedTestLoading, setFastSpeedTestLoading] = useState(false);

  const handleSpeedTest = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${internetCheckDomain}/api/speed-test`);
      setSpeedTestResult(response.data);
    } catch (error) {
      console.error("Error running speed test", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFastSpeedTest = async () => {
    setFastSpeedTestLoading(true);
    try {
      const response = await axios.get(
        `${internetCheckDomain}/api/fast-speed-test`
      );
      setFastSpeedTestResult(response.data);
    } catch (error) {
      console.error("Error running speed test", error);
    } finally {
      setFastSpeedTestLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2, border: `1px solid #2a5e6c`, borderRadius: 3 }}>
      <MuiButton
        onClick={handleSpeedTest}
        disabled={loading}
        loading={loading}
        noTextOnLoading={true}
        name={"Speed Test-Net"}
        size={"small"}
      />

      <Box sx={{ padding: 1 }}>
        {speedTestResult && (
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <ShadowContainer>
              <div>Download Speed</div>
              <Typography>
                {speedTestResult.download.toFixed(2)} Mbps
              </Typography>
            </ShadowContainer>
            <ShadowContainer>
              <div>Upload Speed</div>
              <Typography>{speedTestResult.upload.toFixed(2)} Mbps</Typography>
            </ShadowContainer>
            <ShadowContainer>
              <div>Ping</div>
              <Typography>{speedTestResult.ping}</Typography>
            </ShadowContainer>
          </Box>
        )}
      </Box>
      <Divider sx={{ marginBottom: 1 }} />

      <MuiButton
        onClick={handleFastSpeedTest}
        disabled={fastSpeedTestLoading}
        loading={fastSpeedTestLoading}
        noTextOnLoading={true}
        name={"Fast Speed Test"}
      />

      <Box sx={{ padding: 1 }}>
        {fastSpeedTestResult && (
          <ShadowContainer>
            <div>Download Speed</div>
            <Typography>{fastSpeedTestResult?.speed} Mbps</Typography>
          </ShadowContainer>
        )}
      </Box>
    </Box>
  );
};

export default SpeedTestComponent;
