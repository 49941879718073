import ActionUtility from "../../../utilities/ActionUtility";
import AddAccountEffect from "./AddAccountEffect";
export default class AddAccountAction {
  // AddAccount action with an function declaration
  static REQUEST_ADD_ACCOUNT = "AddAccountAction.REQUEST_ADD_ACCOUNT";
  static REQUEST_ADD_ACCOUNT_FINISHED =
    "AddAccountAction.REQUEST_ADD_ACCOUNT_FINISHED";

  static REQUEST_ADD_ACCOUNT_FILTER =
    "AddAccountAction.REQUEST_ADD_ACCOUNT_FILTER";
  static REQUEST_ADD_ACCOUNT_FILTER_FINISHED =
    "AddAccountAction.REQUEST_ADD_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_ADD_ACCOUNT = "AddAccountAction.REQUEST_PUT_ADD_ACCOUNT";
  static REQUEST_PUT_ADD_ACCOUNT_FINISHED =
    "AddAccountAction.REQUEST_PUT_ADD_ACCOUNT_FINISHED";

  static REQUEST_DELETE_ADD_ACCOUNT =
    "AddAccountAction.REQUEST_DELETE_ADD_ACCOUNT";
  static REQUEST_DELETE_ADD_ACCOUNT_FINISHED =
    "AddAccountAction.REQUEST_DELETE_ADD_ACCOUNT_FINISHED";

  // METHODS
  // aDDaCCOUNT GET function
  static requestAddAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AddAccountAction.REQUEST_ADD_ACCOUNT,
        AddAccountEffect.requestAddAccount,
        params
      );
    };
  }

  static requestAddAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AddAccountAction.REQUEST_ADD_ACCOUNT_FILTER,
        AddAccountEffect.requestAddAccountFilter,
        params
      );
    };
  }

  static requestPutAddAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AddAccountAction.REQUEST_PUT_ADD_ACCOUNT,
        AddAccountEffect.requestPutAddAccount,
        data,
        id
      );
    };
  }

  static requestDeleteAddAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AddAccountAction.REQUEST_DELETE_ADD_ACCOUNT,
        AddAccountEffect.requestDeleteAddAccount,
        id
      );
    };
  }
}
