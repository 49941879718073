import ActionUtility from "../../../../utilities/ActionUtility";
import AutomationFlowEffect from "./AutomationFlowEffect";

export default class AutomationFlowAction {
  static REQUEST_GET_AUTOMATION_FLOW =
    "AutomationFlowAction.REQUEST_GET_AUTOMATION_FLOW";
  static REQUEST_GET_AUTOMATION_FLOW_FINISHED =
    "AutomationFlowAction.REQUEST_GET_AUTOMATION_FLOW_FINISHED";

  // METHODS
  static requestGetAutomationFlow(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AutomationFlowAction.REQUEST_GET_AUTOMATION_FLOW,
        AutomationFlowEffect.requestGetAutomationFlow,
        params,
        callback
      );
    };
  }
}
