import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { Box, Typography } from "@mui/material";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";

function PlatformWiseReport(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);

  const [platformData, setPlatformData] = useState(null);

  function getLast3monthStartAndThismonthEndDate() {
    let date = new Date();
    const last3monthStartDate = new Date(
      date.getFullYear(),
      date.getMonth() - 2,
      2
    );
    const last3monthstartDatestring = last3monthStartDate
      .toISOString()
      .slice(0, 10);

    const currentMonthEnddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    );
    const currentMonthendDatestring = currentMonthEnddate
      .toISOString()
      .slice(0, 10);

    return {
      last3monthStartDate: last3monthstartDatestring,
      currentMonthEnddate: currentMonthendDatestring,
    };
  }

  const [today, setToday] = useState(new Date());

  let { last3monthStartDate, currentMonthEnddate } =
    getLast3monthStartAndThismonthEndDate();

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchPlatformReportMonthwise(
        last3monthStartDate,
        currentMonthEnddate
      ).then((res) => {
        setPlatformData(res);
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (props.clearClicked) {
      setToday(new Date());

      (async function CallApi() {
        setLoading(true);
        await fetchPlatformReportMonthwise(
          last3monthStartDate,
          currentMonthEnddate
        ).then((res) => {
          setPlatformData(res);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setLoading(true);
        await fetchPlatformReportMonthwise(firstDateString, endDateString).then(
          (res) => {
            setPlatformData(res);
          }
        );
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_name;
        })
    );
  }

  async function fetchPlatformReportMonthwise(from, to) {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["reports_on_platform_monthwise"]&from=${from}&to=${to}`;

    try {
      const res = await axiosRequest(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });
      const response = await res;
      return await response;
    } catch (err) {}
  }

  function convertDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month since it is zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastSecondFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // Set the date to the first day of the previous month

  const lastThirdFirstDay = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    1
  ); // Set the date to the first day of the previous month

  let currentMonth = convertDate(thisMonth);
  let lastSecond = convertDate(lastSecondFirstDay);
  let lastThird = convertDate(lastThirdFirstDay);

  let currentMonthTotal = currentMonth + "_count";
  let lastSecondTotal = lastSecond + "_count";
  let lastThirdTotal = lastThird + "_count";

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      mnWidth: 150,
    },
    {
      field: new Date(lastThird).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      headerName: new Date(lastThird).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      resizable: true,
      flex: 2,
      mnWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let monthKey = new Date(lastThird).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        });

        return (
          <span>{`${params.row[monthKey]} ${
            params.row.platforms !== "Total"
              ? `( ${params.row[`${monthKey}-Perc`]} % )`
              : ""
          }`}</span>
        );
      },
    },
    {
      field: new Date(lastSecond).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      headerName: new Date(lastSecond).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      resizable: true,
      flex: 2,
      mnWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let monthKey = new Date(lastSecond).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        });

        return (
          <span>{`${params.row[monthKey]} ${
            params.row.platforms !== "Total"
              ? `( ${params.row[`${monthKey}-Perc`]} % )`
              : ""
          }`}</span>
        );
      },
    },
    {
      field: new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      headerName: new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      }),
      resizable: true,
      flex: 2,
      mnWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let monthKey = new Date(currentMonth).toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        });

        return (
          <span>{`${params.row[monthKey]} ${
            params.row.platforms !== "Total"
              ? `( ${params.row[`${monthKey}-Perc`]} % )`
              : ""
          }`}</span>
        );
      },
    },
    {
      field: "total",
      headerName: `Total`,
      resizable: true,
      flex: 2,
      mnWidth: 150,
      align: "center",
      headerAlign: "center",
      cellClassName: "boldRow",
    },
    {
      field: "avg",
      headerName: `${new Date(currentMonth).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })} Avg%`,
      resizable: true,
      flex: 2,
      mnWidth: 150,
      align: "center",
      headerAlign: "center",
    },
  ];
  const rows = [];

  if (
    props.platformList &&
    props.platformList.length > 0 &&
    platformData?.ticket_report?.reports_on_platform_monthwise
  ) {
    props.platformList.map((platform, index) => {
      const lastThirdKey = `${new Date(lastThird).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })}`; // Construct the dynamic key

      const lastsecondKey = `${new Date(lastSecond).toLocaleDateString(
        "en-US",
        {
          month: "short",
          year: "numeric",
        }
      )}`; // Construct the dynamic key

      const currentMonthKey = `${new Date(currentMonth).toLocaleDateString(
        "en-US",
        {
          month: "short",
          year: "numeric",
        }
      )}`; // Construct the dynamic key

      let lastThirdCount =
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .length > 0 &&
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .slice(0, 5)
          .filter((item) => {
            return Array.isArray(item);
          })[0]
          .filter((item) => item.key === platform.platform_id).length > 0
          ? Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).length > 0 &&
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            )
              .slice(0, 5)
              .filter((item) => {
                return Array.isArray(item);
              })[0]
              .filter((item) => item.key === platform.platform_id)[0].count
          : 0;

      let lastThirdPercentage =
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .length > 0 &&
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .slice(0, 5)
          .filter((item) => {
            return Array.isArray(item);
          })[0]
          .filter((item) => item.key === platform.platform_id).length > 0
          ? Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).length > 0 &&
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            )
              .slice(0, 5)
              .filter((item) => {
                return Array.isArray(item);
              })[0]
              .filter((item) => item.key === platform.platform_id)[0]
              .monthly_percentage
          : 0;

      let lastSecondCount =
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .length > 0 &&
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .slice(0, 5)
          .filter((item) => {
            return Array.isArray(item);
          })[1]
          ?.filter((item) => item.key === platform.platform_id).length > 0
          ? Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).length > 0 &&
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            )
              .slice(0, 5)
              .filter((item) => {
                return Array.isArray(item);
              })[1]
              .filter((item) => item.key === platform.platform_id)[0].count
          : 0;

      let lastSecondPercentage =
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .length > 0 &&
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .slice(0, 5)
          .filter((item) => {
            return Array.isArray(item);
          })[1]
          ?.filter((item) => item.key === platform.platform_id).length > 0
          ? Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).length > 0 &&
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            )
              .slice(0, 5)
              .filter((item) => {
                return Array.isArray(item);
              })[1]
              .filter((item) => item.key === platform.platform_id)[0]
              .monthly_percentage
          : 0;

      let currentMonthKeyCount =
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .length > 0 &&
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .slice(0, 5)
          .filter((item) => {
            return Array.isArray(item);
          })[2]
          ?.filter((item) => item.key === platform.platform_id).length > 0
          ? Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).length > 0 &&
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            )
              .slice(0, 5)
              .filter((item) => {
                return Array.isArray(item);
              })[2]
              ?.filter((item) => item.key === platform.platform_id)[0].count
          : 0;

      let currentMonthKeyPercentage =
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .length > 0 &&
        Object.values(platformData.ticket_report.reports_on_platform_monthwise)
          .slice(0, 5)
          .filter((item) => {
            return Array.isArray(item);
          })[2]
          ?.filter((item) => item.key === platform.platform_id).length > 0
          ? Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).length > 0 &&
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            )
              .slice(0, 5)
              .filter((item) => {
                return Array.isArray(item);
              })[2]
              ?.filter((item) => item.key === platform.platform_id)[0]
              .monthly_percentage
          : 0;

      const row = {
        id: index + 1,
        platforms: platform.platform_alias,
        total:
          Object.values(
            platformData.ticket_report.reports_on_platform_monthwise
          ).length > 0 &&
          Object.values(
            platformData.ticket_report.reports_on_platform_monthwise
          )
            .slice(9, 10)
            .map((monthData, index) => {
              return monthData.length > 0 &&
                monthData.filter((item) => item.key === platform.platform_id)
                  .length > 0
                ? prefixZero_for_singleDigit(
                    monthData.filter(
                      (item) => item.key === platform.platform_id
                    )[0].count
                  )
                : "00";
            }),
        avg: `${
          platformData?.ticket_report?.reports_on_platform_monthwise &&
          Object.values(
            platformData.ticket_report.reports_on_platform_monthwise
          ).length > 0 &&
          Object.entries(
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).slice(6, 7)[0] || {} // Handle case when slice doesn't return an element
          ).length > 0 &&
          Object.entries(
            Object.values(
              platformData.ticket_report.reports_on_platform_monthwise
            ).slice(6, 7)[0] || {} // Handle case when slice doesn't return an element
          )?.filter(
            ([platformId, percentage]) => platformId === platform.platform_id
          )[0]
            ? Object.entries(
                Object.values(
                  platformData.ticket_report.reports_on_platform_monthwise
                ).slice(6, 7)[0] || {} // Handle case when slice doesn't return an element
              ).filter(
                ([platformId, percentage]) =>
                  platformId === platform.platform_id
              )[0][1]
            : "0"
        }%`,
      };

      row[lastThirdKey] = prefixZero_for_singleDigit(lastThirdCount);
      row[lastsecondKey] = prefixZero_for_singleDigit(lastSecondCount);
      row[currentMonthKey] = prefixZero_for_singleDigit(currentMonthKeyCount);

      row[`${lastThirdKey}-Perc`] = lastThirdPercentage;
      row[`${lastsecondKey}-Perc`] = lastSecondPercentage;
      row[`${currentMonthKey}-Perc`] = currentMonthKeyPercentage;

      rows.push(row);
    });

    if (
      Object.keys(
        platformData.ticket_report.reports_on_platform_monthwise
      ).includes(currentMonth)
    ) {
      const lastThirdKey = `${new Date(lastThird).toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      })}`; // Construct the dynamic key

      const lastsecondKey = `${new Date(lastSecond).toLocaleDateString(
        "en-US",
        {
          month: "short",
          year: "numeric",
        }
      )}`; // Construct the dynamic key

      const currentMonthKey = `${new Date(currentMonth).toLocaleDateString(
        "en-US",
        {
          month: "short",
          year: "numeric",
        }
      )}`; // Construct the dynamic key

      const row = {
        id: props.platformList.length + 1,
        platforms: "Total",
        total: platformData.ticket_report.reports_on_platform_monthwise[
          "per_month_total"
        ]
          ? platformData.ticket_report.reports_on_platform_monthwise[
              "per_month_total"
            ]
          : 0,
        avg: `${
          platformData.ticket_report.reports_on_platform_monthwise[
            "tot_percentage"
          ]
            ? platformData.ticket_report.reports_on_platform_monthwise[
                "tot_percentage"
              ]
            : 0
        }%`,
      };

      row[lastThirdKey] = platformData.ticket_report
        .reports_on_platform_monthwise[lastThirdTotal]
        ? platformData.ticket_report.reports_on_platform_monthwise[
            lastThirdTotal
          ]
        : 0;

      row[lastsecondKey] = platformData.ticket_report
        .reports_on_platform_monthwise[lastSecondTotal]
        ? platformData.ticket_report.reports_on_platform_monthwise[
            lastSecondTotal
          ]
        : 0;

      row[currentMonthKey] = platformData.ticket_report
        .reports_on_platform_monthwise[currentMonthTotal]
        ? platformData.ticket_report.reports_on_platform_monthwise[
            currentMonthTotal
          ]
        : 0;

      rows.push(row);
    }
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData?.push({
          Platforms: item?.platforms,
          [`${new Date(lastThird).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}`]: `${
            item[
              `${new Date(lastThird).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
              })}`
            ]
          }${
            item?.platforms !== "Total"
              ? `(${
                  item[
                    `${new Date(lastThird).toLocaleDateString("en-US", {
                      month: "short",
                      year: "numeric",
                    })}-Perc`
                  ]
                } %)`
              : ""
          }`,

          [`${new Date(lastSecond).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}`]: `${Number(
            item[
              `${new Date(lastSecond).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
              })}`
            ]
          )}${
            item?.platforms !== "Total"
              ? `(${
                  item[
                    `${new Date(lastSecond).toLocaleDateString("en-US", {
                      month: "short",
                      year: "numeric",
                    })}-Perc`
                  ]
                } %)`
              : ""
          }`,

          [`${new Date(currentMonth).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}`]: `${
            item[
              `${new Date(currentMonth).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
              })}`
            ]
          }${
            item?.platforms !== "Total"
              ? `(${
                  item[
                    `${new Date(currentMonth).toLocaleDateString("en-US", {
                      month: "short",
                      year: "numeric",
                    })}-Perc`
                  ]
                } %)`
              : ""
          }`,

          Total: Array.isArray(item.total)
            ? String(item?.total[0])
            : String(item.total),

          [`${new Date(currentMonth).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric",
          })}Avg%`]: item.avg,
        });
      });
      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, platformData]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <Box height={"100%"}>
          {!loading && rows.length > 0 && props.dataGridHeight.current ? (
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                borderRadius: theme.table.borderRadius,
              }}
            >
              {platformData &&
                platformData.ticket_report &&
                platformData.ticket_report.reports_on_platform_monthwise && (
                  <>
                    {props.dataGridHeight.current && rows && (
                      <DataGridFunction
                        rows={rows}
                        columns={columns}
                        rowBorder={false}
                        height={props.dataGridHeight.current}
                        rowHeight={50}
                        headerHeight={80}
                        onCellClick={onCellClick}
                        rowColor={true}
                        headerColor={true}
                        getRowClassName={(params) =>
                          (params.row.platforms == "Total" ? "boldRow" : "") +
                          (params.id % 2 === 0 ? " oddRow" : "")
                        }
                      />
                    )}
                  </>
                )}
            </div>
          ) : (
            !loading && <NoDataFound />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
}

export default PlatformWiseReport;
