import BaseReducer from "../../../../utilities/BaseReducer";
import TrustpilotAccountAction from "./TrustpilotAccountAction";

export default class TrustpilotAccountReduser extends BaseReducer {
  initialState = {
    trustpilotaccount: [],
  };
  [TrustpilotAccountAction.REQUEST_TRUSTPILOT_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      trustpilotaccount: action.payload,
    };
  }
}
