import { BaseModel } from "sjs-base-model";

// post model
export default class BrandPostModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point
  data = {};
  id = "";
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
