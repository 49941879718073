import ActionUtility from "../../../utilities/ActionUtility";
import PlatformSchedulerEffect from "./PlatformSchedulerEffect";

export default class PlatformSchedulerAction {
  // PlatformSchedulerAction action with an function declaration
  static REQUEST_PLATFORM_SCHEDULING =
    "PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULING";
  static REQUEST_PLATFORM_SCHEDULING_FINISHED =
    "PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULING_FINISHED";

  static REQUEST_PLATFORM_TASKS_SCHEDULING =
    "PlatformSchedulerAction.REQUEST_PLATFORM_TASKS_SCHEDULING";
  static REQUEST_PLATFORM_TASKS_SCHEDULING_FINISHED =
    "PlatformSchedulerAction.REQUEST_PLATFORM_TASKS_SCHEDULING_FINISHED";

  static REQUEST_DELETE_PLATFORM_SCHEDULING =
    "PlatformSchedulerAction.REQUEST_DELETE_PLATFORM_SCHEDULING";
  static REQUEST_DELETE_PLATFORM_SCHEDULING_FINISHED =
    "PlatformSchedulerAction.REQUEST_DELETE_PLATFORM_SCHEDULING_FINISHED";

  //  Platform Post Scheduling
  static REQUEST_PLATFORM_SCHEDULE_POST =
    "PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULE_POST";
  static REQUEST_PLATFORM_SCHEDULE_POST_FINISHED =
    "PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULE_POST_FINISHED";

  // METHODS
  // PLatform Action GET function
  static requestPlatformScheduling(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULING,
        PlatformSchedulerEffect.requestPlatformScheduling,
        params
      );
    };
  }

  static requestPlatformTaskScheduling(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformSchedulerAction.REQUEST_PLATFORM_TASKS_SCHEDULING,
        PlatformSchedulerEffect.requestPlatformTaskScheduling,
        params
      );
    };
  }

  static requestDeletePlatformScheduling(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformSchedulerAction.REQUEST_DELETE_PLATFORM_SCHEDULING,
        PlatformSchedulerEffect.requestDeletePlatformScheduling,
        id
      );
    };
  }

  static requestPlatformPostSchedule(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlatformSchedulerAction.REQUEST_PLATFORM_SCHEDULE_POST,
        PlatformSchedulerEffect.requestPlatformPostSchedule,
        data
      );
    };
  }
}
