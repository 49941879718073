import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AddIcon from "@mui/icons-material/Add";
import TicketDetailsTabs from "./tabs/TicketDetailsTabs";
import { connect } from "react-redux";
import TicketFeedComponent from "./TicketFeedComponent/TicketFeedComponent";
import TextArea from "antd/lib/input/TextArea";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import CategoryUpdateDialog from "./components/CategoryUpdateDialog";
import jwtDecode from "jwt-decode";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import TicketsAction from "../../../stores/tickets/TicketsAction";
import { theme } from "../../../../views/App";
import TicketCountAction from "../../../stores/ticketCount/TicketCountAction";
import MuiButton from "../../../../components/muiButton/MuiButton";
import TicketHistoryAction from "../../../stores/ticketHistory/TicketHistoryAction";
import TagUpdateDailog from "./components/TagUpdateDailog";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TicketsAction.REQUEST_TICKETS_BY_ID,
    TicketHistoryAction.REQUEST_TICKET_HISTORY,
    TicketHistoryAction.REQUEST_MASS_TICKET_HISTORY,
  ]),
  ticket: state.ticket.ticket || {},
  // customticketfields: state.customticketfields.customticketfields || {},
  // tickets: state.tickets.tickets || {},
  related_tickets: state.related_tickets.related_tickets || [],
  ticketHistory: state.ticketHistory.ticketHistory || [],
  ticketMassHistory: state.ticketMassHistory.ticketMassHistory || [],
});

const TicketDetails = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      height: "100%",
      borderTop: `0.5px solid ${theme.palette.background.border}`,
      // marginTop: 2,
    },
    paper: {
      //   padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    transitionContainer: {
      position: "relative",
    },
    transitionBox: {
      // position: "absolute",
      // top: 0,
      // left: 0,
      width: "100%",
      height: "100%",
    },
    button: {
      margin: theme.spacing(2),
    },
  }));

  let ticket_closed = [];

  if (props.ticketHistory && props.ticketHistory.hits) {
    ticket_closed = props.ticketHistory.hits.hits.filter((item) => {
      return item._source.final_status === true;
    });
  }

  let comment_deleted = [];

  if (props.ticketHistory && props.ticketHistory.hits) {
    comment_deleted = props.ticketHistory.hits.hits.filter((item) => {
      return item._source.deleted === true;
    });
  }

  let mass_ticket_history = [];

  if (props.ticketMassHistory && props.ticketMassHistory.hits) {
    mass_ticket_history = props.ticketMassHistory.hits.hits;
  }

  const classes = useStyles();
  const [showFeedDetails, setShowFeedDetails] = useState(true);

  // AI Suggestion Code
  const [openAIsuggestionTab, setOpenAIsuggestionTab] = useState(null);
  const [aiSuggestionTab, setAiSuggestionTab] = useState(null);

  const handleButtonClick = () => {
    setShowFeedDetails((prev) => !prev);
  };

  let { isRequesting } = props;

  function fetchAfterDirectClose() {
    // props.dispatch(TicketsAction.requestTicketsById(id));

    let params = {
      page_number: props.pageNumber,
      filters: `[${props.filters}]`,
      order_by: '[["modified_on", "desc"]]',
      page_limit: `15`,
    };
    props.dispatch(TicketsAction.requestTickets(params));

    let ticket_count_params = {
      filters: `[${props.ticket_count_filters}]`,
    };
    props.dispatch(TicketCountAction.requestTicketCount(ticket_count_params));

    props.onClose();
  }

  function fetchTicketByID(id) {
    let filter = [];
    if (
      props.ticketData._source &&
      props.ticketData._source.customer_id &&
      props.ticketData._source.customer_id.customer_id
    ) {
      filter.push(
        `["customer_id.keyword","must", "", "match", "${
          props.ticketData._source
            ? props.ticketData._source.customer_id.customer_id
            : null
        }"]`
      );
    }

    let params = {
      // order_by: '[["created_on", "desc"]]',
      filters: `[${filter}]`,
    };

    props.dispatch(TicketsAction.requestTicketsById(id)).then(() => {
      props.dispatch(
        TicketsAction.requestRelatedTickets(params, fetchMassTicketHistory)
      );
    });
  }

  function fetchTicketHistory() {
    const filter = [];

    let params = {
      filters: `[["ticket_id.keyword", "must", "", "match", ${JSON.stringify(
        props.ticketData._id
      )}]]`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(
      TicketHistoryAction.requestTicketHistory(params, fetchMassTicketHistory)
    );
  }

  function fetchMassTicketHistory(tickets_array) {
    const filter = [];

    let params = {
      filters: `[["ticket_id.keyword", "must", "", "terms", ${JSON.stringify(
        tickets_array
      )}]]`,
      order_by: '[["created_on", "desc"]]',
      page_limit: "none",
    };
    props.dispatch(TicketHistoryAction.requestMassTicketHistory(params));
  }

  //useeffect function
  useEffect(() => {
    setOpenAIsuggestionTab(null);

    if (props.ticketData._id) {
      let id = props.ticketData._id;
      fetchTicketByID(id);
      fetchTicketHistory();
    } else {
      props.onClose();
    }
  }, [props.ticketData]);

  const [loading, setLoading] = useState(false);

  let initialNotes = {
    notes: "",
  };

  function Required() {
    return (
      <>
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  const [open, setOpen] = React.useState(false);
  const notesPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const handleClose = (event) => {
    if (
      notesPopperRef.current &&
      notesPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleNotesPopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      notesPopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const DetailsBoxRef = useRef(null);
  const [DetailBoxHeight, setDetailBoxHeight] = useState(0);

  useEffect(() => {
    const detailBoxHeight =
      DetailsBoxRef.current && DetailsBoxRef.current.offsetHeight;
    setDetailBoxHeight(detailBoxHeight);
  });

  function createDuplicateTicket() {
    let objValues = {};
    Object.assign(
      objValues,
      props.ticketData._source.Attachment_url && {
        Attachment_url: props.ticketData._source.Attachment_url,
      },
      props.ticketData._source.Notes && {
        Notes: props.ticketData._source.Notes,
      },
      props.ticketData._source.activities && {
        activities: props.ticketData._source.activities.activities_id,
      },
      // props.ticketData._source.account_name && {
      //   account_name: props.ticketData._source.account_name,
      // },
      props.ticketData._source.assigned_to && {
        assigned_to: {
          user_id:
            props.ticketData._source.assigned_to &&
            props.ticketData._source.assigned_to.user_id,
          user_name:
            props.ticketData._source.assigned_to &&
            props.ticketData._source.assigned_to.user_name,
        },
      },

      props.ticketData._source.customer_id && {
        customer_id:
          props.ticketData._source.customer_id &&
          props.ticketData._source.customer_id.customer_id,
      },
      // props.ticketData._source.category && {
      //   category: props.ticketData._source.category,
      // },
      // props.ticketData._source.comment && {
      //   comment: props.ticketData._source.comment,
      // },
      // props.ticketData._source.content_type && {
      //   content_type: props.ticketData._source.content_type,
      // },
      props.ticketData._source.created_by && {
        created_by: props.ticketData._source.created_by,
      },
      // props.ticketData._source.created_on && {
      //   created_on: props.ticketData._source.created_on,
      // },
      props.ticketData._source.custom_value && {
        custom_value: props.ticketData._source.custom_value,
      },
      // props.ticketData._source.customer && {
      //   customer: props.ticketData._source.customer,
      // },
      props.ticketData._source.feed_id && {
        feed_id: props.ticketData._source.feed_id,
      },
      // props.ticketData._source.first_response_time && {
      //   first_response_time: props.ticketData._source.first_response_time,
      // },
      // props.ticketData._source.is_child_ticket && {
      //   is_child_ticket: props.ticketData._source.is_child_ticket,
      // },
      // props.ticketData._source.modified_by && {
      //   modified_by: props.ticketData._source.modified_by,
      // },
      // props.ticketData._source.modified_on && {
      //   modified_on: props.ticketData._source.modified_on,
      // },
      // props.ticketData._source.parent_ticket_id && {
      //   parent_ticket_id: props.ticketData._source.parent_ticket_id.ticket_id,
      // },
      props.ticketData._source.platform && {
        platform: props.ticketData._source.platform.platform_id,
      },
      props.ticketData._source.priority && {
        priority: props.ticketData._source.priority,
      },
      props.ticketData._source.project && {
        project: props.ticketData._source.project,
      },
      props.ticketData._source.related_tickets && {
        related_tickets: props.ticketData._source.related_tickets,
      },
      // props.ticketData._source.resolved_time && {
      //   resolved_time: props.ticketData._source.resolved_time,
      // },
      // props.ticketData._source.souce_link && {
      //   souce_link: props.ticketData._source.souce_link,
      // },
      // props.ticketData._source.status && {
      //   status: props.ticketData._source.status.status_id,
      // },
      // props.ticketData._source.tags && { tags: props.ticketData._source.tags },
      props.ticketData._source.ticket_desc && {
        ticket_desc: props.ticketData._source.ticket_desc,
      },
      props.ticketData._source.ticket_name && {
        ticket_name: props.ticketData._source.ticket_name,
      }
      // props.ticketData._source.ticket_type && {
      //   ticket_type: props.ticketData._source.ticket_type,
      // }
    );

    if (props.ticketData._source) {
      props.dispatch(TicketsAction.requestPostTicket(objValues)).then(() => {
        props.fetchFunction();
      });
    }
  }

  const [categoryUpdateOpen, setCategoryUpdateOpen] = useState(false);
  const [tagUpdateOpen, setTagUpdateOpen] = useState(false);

  // // category edit button open close model
  const openCloseModal = useCallback((open) => {
    setCategoryUpdateOpen(open);
  }, []);

  const tagopenCloseModal = useCallback((open) => {
    setTagUpdateOpen(open);
  }, []);

  let related_tickets_ids = [];

  if (props.related_tickets.hits) {
    props.related_tickets.hits &&
      props.related_tickets.hits.hits.lenth !== 0 &&
      props.related_tickets.hits.hits.map((item) => {
        related_tickets_ids.push(item._id);
      });
  }

  return (
    <Box className={classes.root} ref={DetailsBoxRef}>
      <Grid
        container
        height="100%"
        sx={
          {
            // borderTop: `0.5px solid ${theme.palette.background.border}`,
          }
        }
      >
        <Grid
          item
          xl={showFeedDetails ? 8 : 12}
          lg={showFeedDetails ? 8 : 12}
          md={showFeedDetails ? 8 : 12}
          sm={showFeedDetails ? 8 : 12}
          xs={showFeedDetails ? 8 : 12}
          sx={{
            backgroundColor: `${theme.palette.background.primary}`,
            height: "100%",
          }}
          // className="bg-success"
        >
          <Paper className={classes.paper} sx={{ height: "100%" }}>
            <Box
              width={"100%"}
              height="100%"
              sx={{
                margin: 0,
                backgroundColor: `${theme.palette.background.primary}`,
                // paddingX: 1,
                // paddingY: 1,
                // paddingLeft: 1,
              }}
              // className="bg-warning"
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                justifyContent={"space-between"}
                className="m-0 p-0 "
                height={"40px"}
                sx={{
                  backgroundColor: "white",
                  borderRight: `0.5px solid ${theme.palette.background.border}`,
                  borderLeft: `0.5px solid ${theme.palette.background.border}`,
                }}
              >
                <Grid
                  item
                  container
                  xl={6}
                  lg={8}
                  md={10}
                  sm={10}
                  xs={7}
                  gap={1}
                  alignItems="center"
                  sx={{ display: "flex" }}
                  justifyContent={"flext-start"}
                  // className="bg-warning"

                  // height={"inherit"}
                >
                  <Grid item lg={3} sm={4} md={4} ml={2}>
                    {/* <MuiButton name={"Add Note"} /> */}

                    <Typography
                      component={"div"}
                      ref={notesPopperRef}
                      id="composition-button"
                      aria-controls={open ? "composition-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleNotesPopper}
                      style={{
                        backgroundColor: "none",
                        // border: "1px solid #e5e2e2",
                        borderRadius: 4,
                      }}
                      className="m-0 px-2 py-0"
                    >
                      <IconButton
                        className="p-0 m-0"
                        style={{
                          minHeight: 0,
                          padding: 0,
                          color: `${theme.palette.primary.main}`,
                          backgroundColor: "transparent",
                        }}
                        title={open ? "Close Notes" : "Add Notes"}
                      >
                        {open ? (
                          <>
                            <CloseIcon style={{ width: "16px" }} />
                            <span
                              className="m-0 p-0"
                              style={{ cursor: "pointer", fontSize: "12px" }}
                            >
                              Close Notes
                            </span>
                          </>
                        ) : (
                          <>
                            <AddIcon style={{ width: "16px" }} />
                            <span
                              className="m-0 p-0"
                              style={{ cursor: "pointer", fontSize: "13px" }}
                            >
                              Add Note
                            </span>
                          </>
                        )}
                      </IconButton>
                    </Typography>

                    <Popper
                      open={open}
                      anchorEl={notesPopperRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                      sx={{ zIndex: 100 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper
                            style={{
                              boxShadow: theme.shadows,
                              width: 500,
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <div
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                              >
                                <Grid lg={12} md={12} sm={12} sx={{ p: 3 }}>
                                  <Formik
                                    initialValues={initialNotes}
                                    enableReinitialize
                                    validate={(values) => {
                                      var errors = {};

                                      if (!values.notes) {
                                        errors.notes = <Required />;
                                      }

                                      return errors;
                                    }}
                                    onSubmit={(values, formikHelpers) => {
                                      setLoading(true);

                                      let object = {
                                        Notes: [
                                          ...props.ticket._source.Notes,
                                          { notes_content: values.notes },
                                        ],
                                      };

                                      const data = object;

                                      const auth = localStorage.getItem("Auth");

                                      let authData = jwtDecode(auth);

                                      let post_obj_for_logs = {
                                        ticket_id: props.ticketData._id,
                                        description: `New Note has been added by ${authData.preferred_username}`,
                                      };

                                      props
                                        .dispatch(
                                          TicketsAction.requestPutTicket(
                                            data,
                                            props.ticketData._id
                                          )
                                        )
                                        .then(() => {
                                          props.dispatch(
                                            TicketsAction.requestTicketsById(
                                              props.ticketData._id
                                            )
                                          );

                                          setLoading(false);
                                          setOpen(false);
                                        });
                                    }}
                                  >
                                    {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleSubmit,
                                      setFieldValue,
                                      submitForm,
                                      resetForm,
                                    }) => (
                                      <Form onSubmit={handleSubmit}>
                                        <Grid lg={12} md={12} sm={12}>
                                          <Field
                                            name="notes"
                                            as={TextArea}
                                            // defaultValue={demoText.textarea}
                                            placeholder="Enter Notes...."
                                            className="textAreaFiled"
                                            style={{
                                              width: "100%",
                                              height: 100,
                                            }}
                                          />
                                          <ErrorMessage name="notes" />
                                        </Grid>
                                        <Grid
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <Grid
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            sx={{
                                              display: "flex",
                                              gap: 2,
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <MuiButton
                                              name={"Cancel"}
                                              type="reset"
                                              onClick={() => {
                                                // setPopperAnchor(null);
                                              }}
                                            ></MuiButton>
                                            <MuiButton
                                              name={"Submit"}
                                              type="submit"
                                              loading={loading}
                                              noTextOnLoading={true}
                                            ></MuiButton>
                                          </Grid>
                                        </Grid>
                                      </Form>
                                    )}
                                  </Formik>
                                </Grid>
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Grid>
                  {/* <Grid item lg={5} sm={5} md={5}>
                    <Typography
                      component={"div"}
                      style={{
                        backgroundColor: "none",
                        border: "1px solid #e5e2e2",
                        borderRadius: 4,
                      }}
                      className="m-0 px-2 py-0"
                    >
                      <IconButton
                        className="p-0 m-0"
                        onClick={() => {
                          createDuplicateTicket();
                        }}
                        style={{
                          minHeight: 0,
                          padding: 0,
                          color: `${theme.palette.primary.main}`,
                          backgroundColor: "transparent",
                        }}
                        title={"Create Duplicate Ticket"}
                      >
                        <ControlPointDuplicateIcon />
                        &nbsp;
                        <span
                          className="m-0 p-0"
                          style={{ cursor: "pointer", fontSize: "12px" }}
                        >
                          Duplicate Ticket
                        </span>
                      </IconButton>
                    </Typography>
                  </Grid> */}
                </Grid>
                <Grid
                  item
                  xl={1}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  textAlign="right"
                  alignItems={"center"}
                  justifyContent="flex-end"
                  px={2}
                  // className="bg-info"
                >
                  {!showFeedDetails ? (
                    <IconButton
                      onClick={handleButtonClick}
                      title="Open Profile"
                      style={{
                        minHeight: 0,
                        padding: 0,
                        color: `${theme.palette.ticketListView.headerIcon}`,
                      }}
                    >
                      <KeyboardDoubleArrowLeftIcon
                        style={{
                          width: "16px",
                          color: `${theme.palette.ticketListView.headerIcon}`,
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={handleButtonClick}
                      title="Close Profile"
                      style={{
                        minHeight: 0,
                        padding: 0,
                        color: `${theme.palette.ticketListView.headerIcon}`,
                      }}
                    >
                      <KeyboardDoubleArrowRightIcon style={{ width: "16px" }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Box width={"100%"} height={`${DetailBoxHeight - 40}px`}>
                {
                  //                   <ErrorBoundary
                  //                     message="Oops! Something went wrong. Our team has been notified and we are working to fix the problem. Please try again later.
                  // "
                  //                   >
                  <TicketFeedComponent
                    openCloseModal={openCloseModal}
                    tagopenCloseModal={tagopenCloseModal}
                    categoryUpdateOpen={categoryUpdateOpen}
                    tagUpdateOpen={tagUpdateOpen}
                    pageNumber={props.pageNumber}
                    ticketData={props.ticket}
                    filters={props.filters}
                    // customerData={props.customerData}
                    showFeedDetails={showFeedDetails}
                    statusList={props.statusList}
                    finalStatuses={props.finalStatuses}
                    replyClicked={props.replyClicked}
                    setReplyClicked={props.setReplyClicked}
                    emailClicked={props.emailClicked}
                    setEmailClicked={props.setEmailClicked}
                    fetchFunction={props.fetchFunction}
                    userlist={props.userlist}
                    onClose={props.onClose}
                    ticket_count_filters={props.ticket_count_filters}
                    page_limit={props.page_limit}
                    page_length={props.page_length}
                    category_custom_value={
                      props.customticketfields &&
                      props.customticketfields.hits &&
                      props.customticketfields.hits.hits[0] &&
                      props.customticketfields.hits.hits[0]._source.field_values
                    }
                    ticket_closed={ticket_closed}
                    comment_deleted={comment_deleted}
                    mass_ticket_history={mass_ticket_history}
                    openAIsuggestionTab={openAIsuggestionTab}
                    setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                    aiSuggestionTab={aiSuggestionTab}
                    setAiSuggestionTab={setAiSuggestionTab}
                  />
                  // </ErrorBoundary>
                }
              </Box>

              <CategoryUpdateDialog
                open={categoryUpdateOpen}
                onClose={() => {
                  setCategoryUpdateOpen(false);
                }}
                ticketData={props.ticket}
                custom_value={
                  props.ticket &&
                  props.ticket._source &&
                  props.ticket._source.custom_value &&
                  props.ticket._source.custom_value.length !== 0
                    ? props.ticket._source.custom_value
                    : []
                }
                customticketfields={props.customticketfields}
                customticketfields_filter={props.customticketfields_filter}
                from="ticketdetails"
                fetchFunction={props.fetchFunction}
              />

              <TagUpdateDailog
                filterTagList={props.filterTagList}
                open={tagUpdateOpen}
                onClose={() => {
                  setTagUpdateOpen(false);
                }}
                ticketData={props.ticket}
                fetchFunction={props.fetchFunction}
                // from="ticketlist"
              />
            </Box>
          </Paper>
        </Grid>
        {showFeedDetails && (
          <Grid
            item
            xl={showFeedDetails ? 4 : null}
            lg={showFeedDetails ? 4 : null}
            md={showFeedDetails ? 4 : null}
            sm={showFeedDetails ? 4 : null}
            className={`${classes.transitionContainer}`}
            height="100%"
          >
            <Slide
              direction="left"
              in={showFeedDetails}
              mountOnEnter
              unmountOnExit
            >
              <Paper className={classes.transitionBox}>
                <Box
                  width={"100%"}
                  height={"100%"}
                  sx={{
                    // border: "solid 0.5px #86aeb6",
                    margin: 0,
                  }}
                >
                  <TicketDetailsTabs
                    pageNumber={props.pageNumber}
                    setSelectedRows={props.setSelectedRows}
                    ticketData={props.ticket}
                    handleButtonClick={handleButtonClick}
                    showFeedDetails={showFeedDetails}
                    filters={props.filters}
                    // customerData={props.customerData}
                    statusList={props.statusList}
                    filterprioritylist={props.filterprioritylist}
                    userlist={props.userlist}
                    setShowDetailsBox={props.setShowDetailsBox}
                    onClose={props.onClose}
                    ticket_count_filters={props.ticket_count_filters}
                    page_limit={props.page_limit}
                    page_length={props.page_length}
                    fetchFunction={props.fetchFunction}
                    related_tickets_ids={related_tickets_ids}
                    filterTagList={props.filterTagList}
                    openAIsuggestionTab={openAIsuggestionTab}
                    setOpenAIsuggestionTab={setOpenAIsuggestionTab}
                    aiSuggestionTab={aiSuggestionTab}
                    setAiSuggestionTab={setAiSuggestionTab}
                  />
                </Box>
              </Paper>
            </Slide>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(TicketDetails);
