import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../components/muiButton/MuiButton";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import "./GMB.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { theme } from "../App";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import PaginationUI from "../../components/pagination/Pagination";
import StarIcon from "@mui/icons-material/Star";
import { format } from "date-fns";
import DatePicker from "../../components/dateRangePickerComponent/DateRangePicker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SearchIcon from "@mui/icons-material/Search";
import environment from "environment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import GMBAccountAction from "../../ormTicketing/stores/gmb_Account/GMBAccountAction";
import GmbReviewAction from "../../ormTicketing/stores/gmbReviews/GmbReviewAction";
import { axiosRequest } from "../../utilities/FetchRequest";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    GmbReviewAction.REQUEST_GMB_REVIEW_FEED,
    GmbReviewAction.REQUEST_GMB_REVIEW_FEED_FILTER,
    GMBAccountAction.REQUEST_GMB_ACCOUNT,
  ]),
  GMBReviewFeeds: state.GMBReviewFeeds.GMBReviewFeeds || [],
  pagination: state.GMBReviewFeeds.GMBReviewFeeds || {},
  GMBReviewFeedsFilter: state.GMBReviewFeedsFilter.GMBReviewFeedsFilter || [],
  gmbAccount: state.gmbAccount.gmbAccount || [],
});

function GMBReviews(props) {
  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  const [page_limit, set_page_limit] = useState(15);
  const [value, setValue] = React.useState("1");
  const [reviewer_name, set_reviewer_name] = useState(null);
  const [rating, setRating] = useState([]);
  const [date, setDate] = useState([null, null]);

  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [accountName, setAccountName] = useState(null);

  const [star] = useState(["ONE", "TWO", "THREE", "FOUR", "FIVE"]);

  function fetchGmbReviewFeeds() {
    let params = {
      page_limit: `${page_limit}`,
      order_by: '[["review_created_on", "desc"]]',
    };
    props.dispatch(GmbReviewAction.requestGmbReviewFeed(params));
  }

  function fetchGmbReviewFeedsFilter() {
    let params = {
      page_limit: "none",
      order_by: '[["review_created_on", "desc"]]',
    };
    props.dispatch(GmbReviewAction.requestGmbReviewFeedFilter(params));
  }

  function fetchGmbReviewAccountFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(GMBAccountAction.requestGMBAccount(params));
  }

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //   UseEffect function
  useEffect(() => {
    fetchGmbReviewFeeds();
    fetchGmbReviewFeedsFilter();
    fetchGmbReviewAccountFilter();
  }, []);

  const handleFilter = () => {
    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["review_comment.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (rating.length !== 0) {
      filter.push(
        `["review_rating.keyword","must","","terms",${JSON.stringify(rating)}]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    if (reviewer_name !== null) {
      filter.push(
        `["reviewer_name.keyword","must","","match","${reviewer_name}"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["review_created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["review_created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      (date[0] !== null && date[1] !== null) ||
      reviewer_name !== null ||
      accountName !== null ||
      searchQuery !== "" ||
      rating.length !== 0
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["review_created_on", "desc"]]',
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      props.dispatch(GmbReviewAction.requestGmbReviewFeed(params));
    } else {
      alert("Please add filters");
    }
  };

  let maxDate = new Date();

  // pagination
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["review_comment.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (rating.length !== 0) {
      filter.push(`["review_rating.keyword","must","","terms",[${rating}]]`);
    }

    if (reviewer_name !== null) {
      filter.push(
        `["reviewer_name.keyword","must","","match","${reviewer_name}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["review_created_on","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["review_created_on","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (FilterClicked) {
      if (
        (date[0] !== null && date[1] !== null) ||
        rating.length !== 0 ||
        accountName !== null ||
        searchQuery !== "" ||
        reviewer_name !== null
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: '[["review_created_on", "desc"]]',
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["review_created_on", "desc"]]',
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(GmbReviewAction.requestGmbReviewFeed(params));
    }
  };

  const reviewer_name_HandleChange = (event, value) => {
    if (value != null) {
      set_reviewer_name(value);
    } else {
      set_reviewer_name(null);
    }
  };

  // ***************************************  Rating HandleChange
  const ratingHandleChange = (event, value) => {
    if (value != null) {
      setRating(value);
    } else {
      setRating("");
    }
  };

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName(null);
    }
  };

  let Reviewer_list = [];

  if (props.GMBReviewFeedsFilter.hits) {
    props.GMBReviewFeedsFilter.hits.hits.map((item) => {
      return Reviewer_list.push(item._source.reviewer_name);
    });
  }

  function returnStars(stars) {
    let converssion = stars;
    let converted;

    if (converssion === "ONE") {
      converted = "1";
    } else if (converssion === "TWO") {
      converted = "2";
    } else if (converssion === "THREE") {
      converted = "3";
    } else if (converssion === "FOUR") {
      converted = "4";
    } else if (converssion === "FIVE") {
      converted = "5";
    }

    let i = converted;
    let displayStars = [];
    if (converted === i) {
      for (let k = 1; k <= i; k++) {
        displayStars.push(<StarIcon />);
      }
    }
    return displayStars;
  }

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  // Clear method
  const ClearState = () => {
    setFilterClicked(false);

    if (
      (date[0] !== null && date[1] !== null) ||
      reviewer_name !== null ||
      accountName !== null ||
      setSearchQuery !== "" ||
      rating.length !== 0
    ) {
      setDate([null, null]);
      set_reviewer_name(null);
      setRating([]);
      setSearchQuery("");
      setClearLoader(true);
      setAccountName(null);

      if (navigator.onLine === true) {
        const params = {
          page_limit: `${page_limit}`,
          order_by: '[["review_created_on", "desc"]]',
        };
        FilterClicked &&
          props.dispatch(GmbReviewAction.requestGmbReviewFeed(params));
      } else {
        alert("No internet connection");
      }
    }
  };

  const gmb_list = [];

  if (props.gmbAccount.hits) {
    props.gmbAccount.hits.hits.map((value) => {
      gmb_list.push(value._source.account_name);
    });
  }

  const GMB_uniqueList = [...new Set(gmb_list)];

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);

    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  return (
    <Box>
      <Grid
        mt={2}
        container
        sx={{
          backgroundColor: "#f3f9fb",
          height: "auto",
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Account Name</span>
            </Grid>
            <AutoCompleteComponent
              value={accountName}
              list={GMB_uniqueList}
              handleChange={accountNameHandleChange}
              textFieldProps={{
                placeholder: "Select Account",
              }}
              size="small"
              width="100%"
            />
          </Grid>

          <Grid item xl={2} lg={3} md={6} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Search by Review Content</span>
            </Grid>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  borderRadius:
                    theme.palette.buttonComponent.borderRadius.medium,
                  outline: `1px ${theme.palette.borderColor.textField}`,
                  backgroundColor: theme.palette.background.paper,
                  padding: theme.typography.padding,
                  height: "35px",
                  width: "100%",
                }}
                id="search_bar"
                autoComplete={"off"}
                value={searchQuery}
                placeholder={"Search by Review Content"}
                onInput={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
                startAdornment={
                  <InputAdornment>
                    <IconButton onClick={handleFilter}>
                      <SearchIcon
                        sx={{
                          "&.MuiSvgIcon-root": {
                            fontSize: "1.2rem",
                          },
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid item lg={2} md={4} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Customer Name</span>
            </Grid>
            <AutoCompleteComponent
              list={Reviewer_list}
              value={reviewer_name}
              textFieldProps={{
                placeholder: "Customer Name",
              }}
              handleChange={reviewer_name_HandleChange}
              size="small"
              width="100%"
            />
          </Grid>
          <Grid item lg={2} md={4} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Ratings</span>
            </Grid>
            <Grid style={{ position: "relative" }}>
              <Grid
                item
                xl={12}
                style={{ position: "absolute", width: "95%", zIndex: 999 }}
              >
                <AutoCompleteComponent
                  value={rating}
                  handleChange={ratingHandleChange}
                  list={star}
                  textFieldProps={{
                    placeholder: rating.length > 0 ? null : "Ratings",
                  }}
                  size="small"
                  width="100%"
                  multiple={true}
                  limitTags={1}
                  disableCloseOnSelect={true}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={12} mt={1}>
            <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
              <span>Date Filter</span>
            </Grid>
            <DatePicker
              value={date}
              onChange={dateValueHandleChange}
              maxDate={maxDate}
            />
          </Grid>
          <Grid item lg={2} md={3} sm={12} mt={0}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={2}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid display={"flex"} justifyContent={"center"} mt={4.8}>
                <Grid>
                  <MuiButton
                    loading={filterLoader && isRequesting}
                    name={"Apply"}
                    onClick={handleFilter}
                  />
                </Grid>
                <Grid>
                  <MuiButton
                    name={"Clear"}
                    loading={clearLoader && isRequesting}
                    onClick={ClearState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <hr />
      <Box
        sx={{
          backgroundColor: "#f3f9fb",
          height: "auto",
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
        }}
      >
        <Grid
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          mt={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xl={3.5} lg={4.5} md={12} sm={12} xs={12} textAlign="left">
            <h5>Reviews</h5>
          </Grid>
        </Grid>
        <hr className="dotted" />

        {/* ********************************************************************************** */}
        {props.GMBReviewFeeds.hits ? (
          <LoadingIndicator isActive={isRequesting}>
            {props.GMBReviewFeeds.hits.hits.length !== 0 ? (
              props.GMBReviewFeeds.hits.hits.map((item, index) => (
                <List
                  sx={{
                    width: "100%",
                    cursor: item && item._source.ticket_id ? "pointer" : null,
                  }}
                  component="nav"
                  onClick={() => {
                    localStorage.setItem("activeTab", 0);

                    item._source &&
                      item._source.ticket_id &&
                      openTicketFunction(item._source.ticket_id);
                  }}
                >
                  <Grid
                    container
                    sx={{
                      backgroundColor: "#fff",
                      height: "auto",
                      padding: 2,
                      borderRadius: theme.borderRadius,
                      border: "solid 0.5px #d3e2e6",
                      boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                    }}
                  >
                    <Grid
                      container
                      lg={12}
                      md={12}
                      sm={12}
                      alignItems="flex-start"
                      justifyContent={"center"}
                    >
                      <Grid
                        item
                        lg={1}
                        md={2}
                        sm={2}
                        maxWidth={"80px !important"}
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                backgroundColor: "skyblue",
                              }}
                              src={item._source.reviewer_photo}
                            >
                              {item._source.account_name.charAt(0)}
                            </Avatar>
                          </ListItemAvatar>
                        </ListItem>
                      </Grid>
                      <Grid
                        item
                        lg={10}
                        md={9}
                        sm={9}
                        container
                        alignItems={"center"}
                        // className="bg-warning"
                      >
                        <Grid item lg={12} md={12} sm={12} container>
                          <Grid item lg={4} md={12} sm={12} mt={1}>
                            <ListItem>
                              <h6>Name :&nbsp;</h6>
                              {item._source.reviewer_name}
                            </ListItem>
                          </Grid>
                          <Grid item lg={4} md={12} sm={12} mt={1}>
                            <ListItem>
                              <h6>Reviewed on :</h6>
                              {(function createdDate() {
                                let created = item._source.review_created_on;
                                let dateConversion = new Date(created);
                                dateConversion =
                                  dateConversion.toDateString() +
                                  ", " +
                                  dateConversion.toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  });

                                return dateConversion;
                              })()}{" "}
                            </ListItem>
                          </Grid>
                          <Grid item lg={4} md={12} sm={12} display={"flex"}>
                            <ListItem>
                              <h6>Rating :</h6>
                              {returnStars(item._source.review_rating)}
                            </ListItem>
                            {/* <ListItem>
                              <h6>Project :</h6>
                              {item._source.project[0].project_name}
                            </ListItem> */}
                          </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} mt={1}>
                          <ListItem>
                            <Grid container xl={12}>
                              <Grid item xl={0.8}>
                                <h6>Review : </h6>
                              </Grid>{" "}
                              <Grid xl={11.2}>
                                {item._source.review_comment}
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} mt={1}>
                          <ListItem>
                            <Grid container xl={12}>
                              <Grid>
                                <h6>Account Name : </h6>
                              </Grid>{" "}
                              <Grid>{item._source.account_name}</Grid>
                            </Grid>
                          </ListItem>
                        </Grid>

                        {item._source.review_replay !== null && (
                          <Grid item lg={12} md={12} sm={12} mt={1}>
                            <ListItem>
                              <Grid container xl={12}>
                                <Grid item xl={2}>
                                  <h6>Response form the owner : </h6>
                                </Grid>{" "}
                                <Grid xl={10}>
                                  {item._source.review_replay}
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        )}

                        {item._source.review_replay_on !== null && (
                          <Grid item lg={12} md={12} sm={12} mt={1}>
                            <ListItem>
                              <Grid container xl={12}>
                                <Grid item xl={1}>
                                  <h6> Responsed on : </h6>
                                </Grid>{" "}
                                <Grid xl={11}>
                                  {(function createdDate() {
                                    let created = item._source.review_replay_on;
                                    let dateConversion = new Date(created);
                                    dateConversion =
                                      dateConversion.toDateString() +
                                      ", " +
                                      dateConversion.toLocaleTimeString(
                                        "en-US",
                                        {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      );

                                    return dateConversion;
                                  })()}{" "}
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        )}
                      </Grid>

                      <Grid
                        item
                        container
                        lg={1}
                        md={2}
                        sm={2}
                        alignItems={"flex-start"}
                        justifyContent="space-between"
                      >
                        {item._source.feed_link && (
                          <Grid item lg={1} xl={1} md={1} sm={1}>
                            <Typography
                              component={"a"}
                              href={
                                item._source.feed_link
                                  ? item._source.feed_link
                                  : ""
                              }
                              target="_blank"
                              title="Feed Link"
                            >
                              <OpenInNewIcon
                                fontSize="25px"
                                sx={{ cursor: "pointer" }}
                              />
                            </Typography>
                          </Grid>
                        )}
                        {circuler_progress_id === item._source.ticket_id &&
                          open_ticket && (
                            <CircularProgress
                              color="inherit"
                              size={15}
                              // className="bg-light"
                            />
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </List>
              ))
            ) : (
              <NoDataFound />
            )}
          </LoadingIndicator>
        ) : null}
      </Box>

      {props.GMBReviewFeeds.hits
        ? props.GMBReviewFeeds.hits.hits.length !== 0 &&
          !isRequesting && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChangePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(GMBReviews);
