import { BaseModel } from "sjs-base-model";
import GlassDoorFeedsDataHitsModel from "./GlassDoorFeedsDataHitsModel";

//GlassdoorFeedHitsModel
export default class GlassdoorFeedHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ GlassDoorFeedsDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
