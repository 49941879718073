import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import surveyReportModel from "./models/SurveyReportModel";

export default class SurveyReportEffect {
  // calling an API according to the SurveyModel
  static async requestSurveyReportById(id, params) {
    const endpoint = environment.api.surveyReport + "/" + id;
    return EffectUtility.getToModel(surveyReportModel, endpoint, params);
  }
}
