import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import IngBannerListModel from "./model/IngBannerListModel";
import LabelModel from "./model/LabelModel";
import LabelPostModel from "./model/LabelPostModel";
import LabelPutModel from "./model/LabelPutModel";

// LabelEffect
export default class LabelEffect {
  // calling an API according to the label model
  static async requestLabel(params) {
    const endpoint = environment.api.label;
    return EffectUtility.getToModel(LabelModel, endpoint, params);
  }

  static async requestIngbannerList(params, callBack) {
    const endpoint = environment.api.ing_banner_list;
    let response = EffectUtility.getToModel(
      IngBannerListModel,
      endpoint,
      params
    );
    if (callBack && response) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestLabelClear() {
    return {};
  }

  static async requestLabelFilterClear() {
    return {};
  }

  // Get method for the label filter
  static async requestLabelFilter(params) {
    const endpoint = environment.api.label;
    return EffectUtility.getToModel(LabelModel, endpoint, params);
  }

  // put method for the label
  static async requestPutLabel(data, id) {
    const endpoint = environment.api.label + "/" + id;
    return EffectUtility.putToModel(LabelPutModel, endpoint, data);
  }

  // post method for the label
  static async requestPostLabel(data) {
    const endpoint = environment.api.label + "/add";
    return EffectUtility.postToModel(LabelPostModel, endpoint, data);
  }
}
