import BaseReducer from "../../../../utilities/BaseReducer";
import WebsiteLinksAction from "./WebsiteLinksAction";

export default class WebsiteLinksFilterReduser extends BaseReducer {
  //initial state of WebsiteLinksFilterReduser
  initialState = {
    websiteLinksFilter: [],
  };
  //WebsiteLinksFilterReduser request action finish
  [WebsiteLinksAction.REQUEST_WEBSITE_LINKS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      websiteLinksFilter: action.payload,
    };
  }
}
