import ActionUtility from "../../../utilities/ActionUtility";
import RolesEffect from "./RolesEffect";

export default class RolesAction {
  //roles requests
  static REQUEST_ROLES = "RolesAction.REQUEST_ROLES";
  static REQUEST_ROLES_FINISHED = "RolesAction.REQUEST_ROLES_FINISHED";

  //roles post
  static REQUEST_POST_ROLE = "RolesAction.REQUEST_POST_ROLE";
  static REQUEST_POST_ROLE_FINISHED = "RolesAction.REQUEST_POST_ROLE_FINISHED";

  //roles put
  static REQUEST_PUT_ROLE = "RolesAction.REQUEST_PUT_ROLE";
  static REQUEST_PUT_ROLE_FINISHED = "RolesAction.REQUEST_PUT_ROLE_FINISHED";

  //roles filter
  static REQUEST_ROLE_FILTER = "RolesAction.REQUEST_ROLE_FILTER";
  static REQUEST_ROLE_FILTER_FINSIHED =
    "RolesAction.REQUEST_ROLE_FILTER_FINSIHED";

  //METHODS

  //role list get method
  static requestRole(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolesAction.REQUEST_ROLES,
        RolesEffect.requestRole,
        params
      );
    };
  }

  //role list post method
  static requestPostRole(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolesAction.REQUEST_POST_ROLE,
        RolesEffect.requestPostRole,
        data
      );
    };
  }

  //role list put method
  static requestPutRole(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolesAction.REQUEST_PUT_ROLE,
        RolesEffect.requestPutRole,
        data,
        id
      );
    };
  }

  //role filter method
  static requestRoleFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolesAction.REQUEST_ROLE_FILTER,
        RolesEffect.requestRoleFilter,
        params
      );
    };
  }
}
