import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import IndeedAccountGetModel from "./models/IndeedAccountGetModel";
import IndeedAccountPutModel from "./models/IndeedAccountPutModel";
import IndeedAccountDeleteModel from "./models/IndeedAccountDeleteModel";
import IndeedAccountPostModel from "./models/IndeedAccountPostModel";

// IndeedAccountEffct
export default class IndeedAccountEffect {
  // calling an API according to the GoogleAccount model
  // Get method for Indeed account
  static async requestIndeedAccount(params) {
    const endpoint = environment.api.indeedAccount;
    return EffectUtility.getToModel(IndeedAccountGetModel, endpoint, params);
  }

  static async requestPostIndeedAccount(data) {
    const endpoint = environment.api.indeedAccount + "/add";

    return EffectUtility.postToModel(IndeedAccountPostModel, endpoint, data);
  }

  // Get method for Indeed account filter
  static async requestIndeedAccountFilter(params) {
    const endpoint = environment.api.indeedAccount;
    return EffectUtility.getToModel(IndeedAccountGetModel, endpoint, params);
  }

  // put method for Indeed account filter
  static async requestPutIndeedAccount(data, id) {
    const endpoint = environment.api.indeedAccount + "/" + id;
    return EffectUtility.putToModel(IndeedAccountPutModel, endpoint, data);
  }

  // delete method for Indeed account filter
  static async requestDeleteIndeedAccount(id) {
    const endpoint = environment.api.indeedAccount + "/" + id;
    return EffectUtility.deleteToModel(IndeedAccountDeleteModel, endpoint);
  }
}
