import BaseReducer from "../../../../utilities/BaseReducer";
import SurveyAction from "./SurveyAction";

// reducer function
export default class SurveyReducer extends BaseReducer {
  initialState = {
    survey: [],
  };

  [SurveyAction.REQUEST_SURVEY_FINISHED](state, action) {
    return {
      ...state,
      survey: action.payload,
    };
  }
}
