import { BaseModel } from "sjs-base-model";
import YoutubeAccountDataHitsModel from "./YoutubeAccountDataHitsModel";

//YouTube account HitsModel
export default class YoutubeAccountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ YoutubeAccountDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
