import { BaseModel } from "sjs-base-model";

export default class DashboardSummaryGetModel extends BaseModel {
  "dashboard data" = [];

  constructor(data) {
    super();
    this.update(data);
  }
}
