import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import SubGenreModel from "./models/SubGenreModel";
import SubGenrePutModel from "./models/SunGenrePutModel";
import SubGenrePostModel from "./models/SubGenrePostModel";

// SubGenreEffect
export default class SubGenreEffect {
  // calling an API according to the subgenre model
  // Get method for the
  static async requestSubgenre(params) {
    const endpoint = environment.api.subgenre;
    return EffectUtility.getToModel(SubGenreModel, endpoint, params);
  }

  static async requestSubgenreClear() {
    return {};
  }

  static async requestSubgenreFilterClear() {
    return {};
  }

  // Get method for the subgenre filter
  static async requestSubgenreFilter(params) {
    const endpoint = environment.api.subgenre;
    return EffectUtility.getToModel(SubGenreModel, endpoint, params);
  }

  // put method for the subgenre
  static async requestPutSubgenre(data, id) {
    const endpoint = environment.api.subgenre + "/" + id;
    return EffectUtility.putToModel(SubGenrePutModel, endpoint, data);
  }

  // post method for the subgenre
  static async requestPostSubgenre(data) {
    const endpoint = environment.api.subgenre + "/add";
    return EffectUtility.postToModel(SubGenrePostModel, endpoint, data);
  }
}
