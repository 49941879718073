import { BaseModel } from "sjs-base-model";

export default class FPViewsPerAssetGetModel extends BaseModel {
  "views count asset wise" = [];

  constructor(data) {
    super();
    this.update(data);
  }
}
