import BaseReducer from "../../../../utilities/BaseReducer";
import DsrActivityAction from "./DsrActivityAction";

export default class DsrActivityReducer extends BaseReducer {
  initialState = {
    dsrActivity: [],
  };
  [DsrActivityAction.REQUEST_GET_DSR_ACTIVITY_FINISHED](state, action) {
    return {
      ...state,
      dsrActivity: action.payload,
    };
  }
}
