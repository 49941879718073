import { BaseModel } from "sjs-base-model";
import TicketHistoryHitsDataModel from "./TicketHistoryHitsDataModel";

export default class TicketHistoryHitsModel extends BaseModel {
  //ticketType hits model
  hits = [TicketHistoryHitsDataModel];
  max_score = 0;
  total = {};

  constructor(data1) {
    super();
    this.update(data1);
  }
}
