import BaseReducer from "../../utilities/BaseReducer";
import SubSourceAction from "./SubSourceAction";

export default class SubSourceReducer extends BaseReducer {
  //initial state of SubSource.
  initialState = {
    subSource: [],
  };

  //Ticket resolved request action finish
  [SubSourceAction.REQUEST_SUBSOURCE_FINISHED](state, action) {
    return {
      ...state,
      subSource: action.payload,
    };
  }
}
