import { BaseModel } from "sjs-base-model";

export default class DashboardModel extends BaseModel {
  //Dashboard data model

  data = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
