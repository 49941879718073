import ActionUtility from "../../../../utilities/ActionUtility";
import FingerPrintSearchEffect from "./FingerPrintSearchEffect";

export default class FingerPrintSearchAction {
  static REQUEST_POST_FP_SEARCH =
    "FingerPrintSearchAction.REQUEST_POST_FP_SEARCH";
  static REQUEST_POST_FP_SEARCH_FINISHED =
    "FingerPrintSearchAction.REQUEST_POST_FP_SEARCH_FINISHED";

  static REQUEST_POST_FP_ACR_SEARCH =
    "FingerPrintSearchAction.REQUEST_POST_FP_ACR_SEARCH";
  static REQUEST_POST_FP_ACR_SEARCH_FINISHED =
    "FingerPrintSearchAction.REQUEST_POST_FP_ACR_SEARCH_FINISHED";

  // METHODS

  static requestPostFPSearch(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintSearchAction.REQUEST_POST_FP_SEARCH,
        FingerPrintSearchEffect.requestPostFPSearch,
        data,
        params,
        callback
      );
    };
  }

  static requestPostFPacrSearch(data, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FingerPrintSearchAction.REQUEST_POST_FP_ACR_SEARCH,
        FingerPrintSearchEffect.requestPostFPacrSearch,
        data,
        params,
        callback
      );
    };
  }
}
