import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  createFilterOptions,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ShadowContainer } from "../../../components/componentLayout/ComponentLayout";
import "./createTicket.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { string } from "yup";
import {
  Form,
  Formik,
  Field,
  ErrorMessage,
  FastField,
  useFormikContext,
} from "formik";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../components/muiButton/MuiButton";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ProjectAction from "../../stores/project/projectAction";
import PriorityAction from "../../stores/priority/PriorityAction";
import TicketTypeAction from "../../../stores/ticketType/TicketTypeAction";
import UserAction from "../../stores/user/UserAction";
// its to use the props sending from tickets list
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import CustomTicketFieldsAction from "../../../stores/customticketfields/CustomTicketFieldsAction";
import PlatformAction from "../../stores/platform/PlatformAction";
import * as yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import UserLastWorkingProjectAction from "../../stores/userLastWorkingProject/UserLastWorkingProjectAction";
import SwitchButton from "../../../components/switchComponent/SwitchButton";
import DatePickerComponent from "../../../components/datePickerComponent/DatePickerComponent";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StatusAction from "../../stores/status/StatusAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import environment from "environment";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { theme } from "../../../views/App";
import TicketsAction from "../../stores/tickets/TicketsAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import UserPermissionAction from "../../../common/stores/userPermission/UserPermissionAction";
import { formatName } from "../../../components/ticketPartials";

//map state to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    ProjectAction.REQUEST_PROJECT,
    // UserAction.REQUEST_USER,
    UserPermissionAction.REQUEST_USER_PERSMISSION,
    TicketsAction.REQUEST_TICKETS_FILTER,
    UserAction.REQUEST_USER_BY_ID,
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
    StatusAction.REQUEST_STATUS,
    PlatformAction.REQUEST_PLATFORM,
  ]),
  // requestingAddtionals: selectRequesting(state, [
  //   CategoryAction.REQUEST_CATEGORY,
  //   PriorityAction.REQUEST_PRIORITY,
  //   TicketTypeAction.REQUEST_TICKETTYPE,
  //   TagAction.REQUEST_TAG,
  // ]),
  // requestingCustomFields: selectRequesting(state, [
  //   CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS,
  // ]),

  // customticketfields: state.customticketfields.customticketfields || {},

  // category: state.category.category || [],
  // priority: state.priority.priority || [],
  ticketType: state.ticketType.ticketType || [],
  user: state.user.user || [],
  platform: state.platform.platform.hits || [],
  ticketfilter: state.ticketfilter.ticketfilter || [],
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
  userPermission: state.userPermission.userPermission || {},
  status: state.status.status || [],
  // tag: state.tag.tag.hits || [],
});

// TICKET CREATE FUNCTION
function CreateTicket(props) {
  const navigate = useNavigate();

  let categoryList = [];
  let priorityList = [];
  let tagList = [];
  let ticketTypeList = [];
  let custom_value = [];

  const [tickets_array, setTicketsArray] = useState([]);

  // let custom_individual_value = [];

  let custom_value_array = [];

  function FullName(value) {
    return value._source.user?.firstname + " " + value._source.user?.lastname;
  }

  //Request Category function to get category list
  function fetchCategory(values, setFieldValue) {
    // props.dispatch(CategoryAction.requestCategory());
    setFieldValue("ticketAddiionalLoader", true);

    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let API = `${environment.api.baseApi}/category?page_limit=none`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      // //category list assigning from api
      if (response.hits && response.hits.hits) {
        setFieldValue("ticketAddiionalLoader", false);

        response.hits.hits.forEach((item) => {
          categoryList.push({
            category_id: item._id,
            category_name: item._source.category_name,
          });
        });

        categoryList =
          categoryList &&
          categoryList.length !== 0 &&
          categoryList.filter((obj, index, self) => {
            return (
              index ===
              self.findIndex(
                (t) =>
                  t.category_id === obj.category_id &&
                  t.category_name === obj.category_name
              )
            );
          });
      }
    });
  }

  //Request Priority function to get priority list
  function fetchPriority(values, setFieldValue) {
    // props.dispatch(PriorityAction.requestPriority());
    setFieldValue("ticketAddiionalLoader", true);

    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let API = `${environment.api.baseApi}/ticket_priority?page_limit=none`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      // //priority list assigning from api
      if (response.hits && response.hits.hits) {
        setFieldValue("ticketAddiionalLoader", false);

        response.hits.hits.forEach((item) => {
          priorityList.push({
            priority_id: item._id,
            priority_name: item._source.ticket_priority_name,
          });
        });

        priorityList =
          priorityList &&
          priorityList.length !== 0 &&
          priorityList.filter((obj, index, self) => {
            return (
              index ===
              self.findIndex(
                (t) =>
                  t.priority_id === obj.priority_id &&
                  t.priority_name === obj.priority_name
              )
            );
          });
      }
    });
  }

  //Request TicketType function to get ticketType list
  function fetchTicketType(values, setFieldValue) {
    // props.dispatch(TicketTypeAction.requestTicketType());
    setFieldValue("ticketAddiionalLoader", true);

    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let API = `${environment.api.baseApi}/ticket_type?page_limit=none`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // //TicketType list assigning from api
        if (response.hits && response.hits.hits) {
          setFieldValue("ticketAddiionalLoader", false);

          response.hits.hits.forEach((item) => {
            ticketTypeList.push({
              ticket_type_id: item._id,
              ticket_type_name: item._source.ticket_type_name,
            });
          });

          ticketTypeList =
            ticketTypeList &&
            ticketTypeList.length !== 0 &&
            ticketTypeList.filter((obj, index, self) => {
              return (
                index ===
                self.findIndex(
                  (t) =>
                    t.ticket_type_id === obj.ticket_type_id &&
                    t.ticket_type_name === obj.ticket_type_name
                )
              );
            });
        }
      });
  }

  // Fetch Custom Fields
  function fetchCustomTicketFields(values, setFieldValue) {
    setFieldValue("customFieldLoader", true);

    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let API = `${environment.api.baseApi}/custom_ticket_fields?page_limit=none`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      // //TicketType list assigning from api
      if (response.hits && response.hits.hits) {
        setFieldValue("customFieldLoader", false);
        custom_value_array = [...response.hits.hits];

        response.hits.hits.forEach((item) => {
          custom_value.push({
            project_name: item._source.project.project_name,
            field_id: item._id,
            field_name: item._source.field_name,
            field_type: item._source.field_type,
            field_values: setCustomFieldValueType(item._source.field_type),
          });
        });

        custom_value =
          custom_value &&
          custom_value.length !== 0 &&
          custom_value.filter((obj, index, self) => {
            return (
              index ===
              self.findIndex(
                (t) =>
                  t.project_name === obj.project_name &&
                  t.field_id === obj.field_id &&
                  t.field_name === obj.field_name &&
                  t.field_type === obj.field_type &&
                  t.field_values === obj.field_values
              )
            );
          });
      }
    });

    // fetch(
    //   `${environment.api.baseApi}/custom_ticket_fields?filters=[["field_type.keyword","must","","match","category"]]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     // //TicketType list assigning from api
    //     if (response.hits && response.hits.hits) {
    //       setFieldValue("customFieldLoader", false);
    //       console.log("respone=====>", response.hits.hits);
    //       response.hits.hits.forEach((item) => {
    //         // custom_value.push({
    //         //   project_name: item._source.project.project_name,
    //         //   field_id: item._id,
    //         //   field_name: item._source.field_name,
    //         //   field_type: item._source.field_type,
    //         //   field_values: setCustomFieldValueType(item._source.field_type),
    //         // });
    //       });

    //       // custom_value =
    //       //   custom_value &&
    //       //   custom_value.length !== 0 &&
    //       //   custom_value.filter((obj, index, self) => {
    //       //     return (
    //       //       index ===
    //       //       self.findIndex(
    //       //         (t) =>
    //       //           t.project_name === obj.project_name &&
    //       //           t.field_id === obj.field_id &&
    //       //           t.field_name === obj.field_name &&
    //       //           t.field_type === obj.field_type &&
    //       //           t.field_values === obj.field_values
    //       //       )
    //       //     );
    //       //   });
    //     }
    //   });
  }
  const formikRef = useRef(null);

  function fetchTickets() {
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
    let API = `${environment.api.baseApi}/ticket?page_limit=none`;
    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        // console.log({ response });
        if (response.hits && response.hits.hits) {
          response.hits.hits.forEach((item) => {
            tickets_array.push({
              ticket_id: item._id,
              ticket_name: item._source.ticket_name,
            });
          });
        }
      })
      .finally(() => {
        // formikRef.current.setFieldValue("circularLoad", false);
      });
  }

  // if (props.customticketfields.hits) {
  //   props.customticketfields.hits.hits.map((item) => {
  //     return custom_value.push({
  //       project_name: item._source.project.project_name,
  //       field_id: item._id,
  //       field_name: item._source.field_name,
  //       field_type: item._source.field_type,
  //       field_values: setCustomFieldValueType(item._source.field_type),
  //     });
  //   });
  // }

  // const [attachements, setAttachements] = useState([]);

  let attachements = [];

  //Request Priority function to get Status list
  function fetchStatus() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(StatusAction.requestStatus(params));
  }

  //Request User function to get User List
  function fetchUser() {
    // props.dispatch(UserAction.requestUser());
    let filter = [];

    let project = localStorage.getItem("user_last_working_project")
      ? JSON.parse(localStorage.getItem("user_last_working_project")).project_id
      : "";

    let org_id = localStorage.getItem("user_last_working_project")
      ? JSON.parse(localStorage.getItem("user_last_working_project"))
          .organization_id &&
        JSON.parse(localStorage.getItem("user_last_working_project"))
          .organization_id
      : "";

    filter.push(
      `["resource_id.keyword","must","","terms",["${project}" , "*"]],["organization_id.keyword","must","","terms",["${org_id}" , "*"]]`
    );

    const param = {
      page_limit: "none",
      filters: `[${filter}]`,
    };
    props.dispatch(UserPermissionAction.requestUserPermission(param));
  }
  // Request platform function to get platform list
  function fetchPlatform() {
    let param = {
      // level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  // Request fetchLastWorkingProject function to get project
  function fetchLastWorkingProject() {
    let ID = localStorage.getItem("userId");
    props.dispatch(
      UserLastWorkingProjectAction.requestLastWorkingProjectById(ID)
    );
  }

  // handleClear function
  const handleClear = () => {
    filteredMentionsActivitiesList = [];
    MentionsActivitiesList = [];
    document.querySelector("input[type='file']").value = "";
    setLoading(false);
    attachements = [];
  };

  function setCustomFieldValueType(data) {
    if (data === "text") {
      return [{ value: "", id: "" }];
    } else if (data === "select") {
      return [];
    } else if (data === "date") {
      return [{ value: "", id: "" }];
    } else if (data === "multiselect") {
      return [];
    } else if (data === "radio") {
      return [{ value: "", id: "" }];
    } else if (data === "toggle") {
      return [{ value: "", id: "" }];
    } else if (data === "category") {
      return [{ value: "", id: "" }];
    } else if (data === "tree") {
      return [{ value: "", id: "" }];
    } else {
      return [];
    }
  }

  let customFieldInitialValues = [];
  if (custom_value.length != 0) {
    customFieldInitialValues = custom_value;
  }

  //USE EFFECT FUNCTION

  useEffect(() => {
    fetchUser();
    fetchLastWorkingProject();

    // if (props?.status === undefined || Object.keys(props.status).length === 0) {
    fetchStatus();
    // }

    // if (props?.ticketfilter === undefined || props?.ticketfilter.length === 0) {
    fetchTickets();
    // }

    // if (
    //   props?.platform === undefined ||
    //   Object.keys(props.platform).length === 0
    // ) {
    fetchPlatform();
    // }

    localStorage.removeItem("panel1");
    localStorage.removeItem("panel2");
  }, []);

  let platform_array = [];

  //PLATFORM list assigning
  let platformList = [];

  props.platform.hits &&
    props.platform.hits.length > 0 &&
    props.platform.hits.map((item) => {
      platformList.push({
        platform_name: item._source.platform_name,
        platform_id: item._id,
      });
    });

  platform_array =
    platformList.length !== 0
      ? platformList.filter((val) => {
          return val.platform_id === "14";
        })
      : [];

  // platform_array = [...new Set(platformList)];

  // if (props.ticketfilter.hits) {
  //   props.ticketfilter.hits.hits.map((item) => {
  //     return tickets_array.push({
  //       ticket_id: item._id,
  //       ticket_name: item._source.ticket_name,
  //     });
  //   });
  // }

  let MentionsActivitiesList = [];
  let filteredMentionsActivitiesList = [];

  const platFormvalHandleChange = (platform_val, setFieldValue) => {
    filteredMentionsActivitiesList = [];
    MentionsActivitiesList = [];
    setFieldValue(`platform`, platform_val);
    setFieldValue(`activities`, "");

    const activitiesData = JSON.parse(localStorage.getItem("activities"));

    if (activitiesData) {
      activitiesData.hits.hits.map((item) => {
        if (item._source.platform.platform_id === platform_val.platform_id) {
          MentionsActivitiesList.push({
            activity_id: item._id,
            activity_name: item._source.activities,
          });
        }
      });
    }
    filteredMentionsActivitiesList = [...new Set(MentionsActivitiesList)];
  };

  //user list Assigning
  const userList = [];
  if (props.userPermission.hits) {
    props.userPermission.hits.hits.forEach((val) => {
      userList.push({
        user_id: val._source.user_id,
        user_name: val._source.user?.username,
        fullName: FullName(val),
      });
    });
  }

  const returnUsernameFunction = (id) => {
    let user_name =
      userList.length > 0 &&
      userList.filter((val) => {
        return val.user_id === id;
      });

    return user_name.length > 0 ? user_name[0].user_name : "";
  };

  const handleDelete = (chipToDelete, setFieldValue, currentAttachments) => {
    document.querySelector("input[type='file']").value = "";

    const newAttachments = currentAttachments.filter(
      (attachment) => attachment.http !== chipToDelete.http
    );

    setFieldValue("Attachment_url", newAttachments);
    attachements = [];
  };

  // VALIDATION SCHEMA
  const validationSchema = yup.object({
    ticket_name: string("Enter your Ticket Name")
      .min(1, "Ticket name should be of minimum 1 character length")
      .required("Ticket name is required"),

    ticket_desc: string("Enter Ticket Description")
      .min(1, "Ticket Description should be of minimum 1 character length")
      .required("Ticket Description is required"),

    // ticket_type : yup
    // .object()
    // .shape({})
    // .required("Ticket type is Required")
    // .nullable(),

    project: yup
      .object()
      .shape({})
      .required("Project name is Required")
      .nullable(),

    // assigned_to : yup
    // .object()
    // .shape({})
    // .required("Assigned User name is Required")
    // .nullable(),

    // category: yup
    // .object()
    // .shape({})
    // .required("Category is Required")
    // .nullable(),

    // priority : yup
    // .object()
    // .shape({})
    // .required("Priority is Required")
    // .nullable(),

    // platform: yup.string().required("Platform is Required").nullable(),
  });

  //status list assigning
  let statusList = [];
  if (props.status.hits) {
    props.status.hits.hits.forEach((item) => {
      statusList.push({
        status_id: item._id,
        status_name: item._source.status_name,
        initial_status: item._source.initial_status,
      });
    });
  }

  statusList =
    statusList.length > 0 &&
    statusList.filter((val) => {
      return val.initial_status === true;
    });

  //SETTING INITIAL VALUES FOR CREATE TICKET
  let initial = {};

  //assigning initial values of create ticket fields for POST mode
  initial = {
    Attachment_url: [],
    Notes: [],
    account_name: "",
    activities: "",
    assigned_to: userList && userList.length === 1 ? userList[0] : "",
    category: "",
    comment: [],
    content_type: "",
    created_by: {
      user_id: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : "",
      user_name: localStorage.getItem("userId")
        ? returnUsernameFunction(localStorage.getItem("userId"))
        : "",
    },
    created_on: new Date(),
    custom_value: custom_value,
    customer: {
      customer_email: "",
      customer_id: "",
      customer_name: "",
    },
    feed_id: "",
    first_response_time: 0,
    is_child_ticket: false,
    modified_by: {
      user_id: "",
      user_name: "",
    },
    modified_on: 0,
    parent_ticket_id: "",
    platform: platform_array.length !== 0 ? platform_array[0] : "",
    priority: priorityList && priorityList.length === 1 ? priorityList[0] : "",
    project: {
      project_id:
        props.lastWorkingProject.found === true
          ? props.lastWorkingProject._source.project.project_id
          : "",
      project_name:
        props.lastWorkingProject.found === true
          ? props.lastWorkingProject._source.project.project_name
          : "",
    },
    related_tickets: [],
    resolved_time: 0,
    souce_link: "",
    status: statusList[0] || "",
    tags: [],
    ticket_desc: "",
    ticket_name: "",
    ticket_type: "",
    boolean: false,
    navigate: false,
    expanded: false,
    customFieldLoader: false,
    ticketAddiionalLoader: false,
    // circularLoad: false,
  };

  function fetchTag(values, setFieldValue) {
    // props.dispatch(TagAction.requestTag());
    setFieldValue("ticketAddiionalLoader", true);

    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let API = `${environment.api.baseApi}/tag?page_limit=none`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      // //taglist list assigning from api
      if (response.hits && response.hits.hits) {
        setFieldValue("ticketAddiionalLoader", false);

        response.hits.hits.forEach((item) => {
          tagList.push({
            tag_id: item._id,
            tag_name: item._source.tag_name,
          });
        });

        tagList =
          tagList &&
          tagList.length !== 0 &&
          tagList.filter((obj, index, self) => {
            return (
              index ===
              self.findIndex(
                (t) => t.tag_id === obj.tag_id && t.tag_name === obj.tag_name
              )
            );
          });
      }
    });
  }

  const handleExpand =
    (panel, setFieldValue, values) => (event, isExpanded) => {
      setFieldValue("expanded", isExpanded ? panel : false);

      if (!localStorage.getItem("panel1")) {
        if (isExpanded && panel === "panel1") {
          fetchCategory(values, setFieldValue);
          fetchPriority(values, setFieldValue);
          fetchTag(values, setFieldValue);
          fetchTicketType(values, setFieldValue);
          localStorage.setItem("panel1", true);
        }
      }

      if (!localStorage.getItem("panel2")) {
        if (isExpanded && panel === "panel2") {
          fetchCustomTicketFields(values, setFieldValue);
          localStorage.setItem("panel2", true);
        }
      }
    };

  //project list assigning from api

  const [loading, setLoading] = useState(false);
  const [stayLoading, setStayLoading] = useState(false);

  // status callback function
  // const statushandleChange = useCallback((status) => {
  // setSelectStatus(status);
  // }, []);

  //using custom theme

  const handlFileUpload = (value, setFieldValue, event) => {
    setFieldValue("boolean", true);

    if (value) {
      // event.preventDefault();
      let formData = new FormData();
      formData.append("file", value);

      let access_tocken = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";

      fetch(`${environment.api.baseApi}/file_upload/add`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + access_tocken,
          // Accept: "application/json",
          // "Content-Type": "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((response) => {
          attachements.push({ file_name: value.name, http: response.url });
          setFieldValue("Attachment_url", attachements);
          setFieldValue("boolean", false);
        });
    } else {
      setFieldValue("boolean", false);
    }
  };

  const clearFile = (setFieldValue) => {
    document.querySelector("input[type='file']").value = "";
    setFieldValue("Attachment_url", "");
  };

  const isChildHandleChange = (val, setFieldValue) => {
    setFieldValue("is_child_ticket", val);
  };

  // let [categoryUpdateOpen, setCategoryUpdateOpen] = useState(false);

  return (
    <Box
      style={{
        height: "100%",
        maxHeight: `${window.innerHeight}px`,
        overflow: "auto",
        position: "relative",
      }}
    >
      <Box
        className="scrollable"
        style={{
          height: "auto",
          overflow: "auto",
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "var(--white)",
          margin: "20px 20px 0px 20px",
        }}
      >
        <Formik
          initialValues={initial}
          innerRef={formikRef}
          enableReinitialize
          onSubmit={(values, formikHelpers) => {
            values.navigate ? setLoading(true) : setStayLoading(true);
            //ticket POST dispatch action
            let urls = [];
            values.Attachment_url &&
              values.Attachment_url.map((item) => {
                urls.push(item.http);
              });

            let objValues = {};
            Object.assign(
              objValues,
              urls && { Attachment_url: urls },
              values.Notes && { Notes: values.Notes },
              values.account_name && { account_name: values.account_name },
              values.activities && {
                activities: values.activities.activity_id,
              },
              values.assigned_to && {
                assigned_to: {
                  user_id: values.assigned_to && values.assigned_to.user_id,
                  user_name: values.assigned_to && values.assigned_to.user_name,
                },
              },
              values.category && { category: values.category },
              values.comment && { comment: values.comment },
              values.content_type && { content_type: values.content_type },
              values.created_by && { created_by: values.created_by },
              values.created_on && { created_on: values.created_on },
              values.custom_value && { custom_value: values.custom_value },
              values.customer && { customer: values.customer },
              values.feed_id && { feed_id: values.feed_id },
              values.first_response_time && {
                first_response_time: values.first_response_time,
              },
              values.is_child_ticket && {
                is_child_ticket: values.is_child_ticket,
              },
              values.modified_by && { modified_by: values.modified_by },
              values.modified_on && { modified_on: values.modified_on },
              values.parent_ticket_id && {
                parent_ticket_id: values.parent_ticket_id.ticket_id,
              },
              values.platform && {
                platform: values.platform.platform_id,
              },
              values.priority && { priority: values.priority },
              values.project && { project: values.project },
              values.related_tickets && {
                related_tickets: values.related_tickets,
              },
              values.resolved_time && { resolved_time: values.resolved_time },
              values.souce_link && { souce_link: values.souce_link },
              values.status && { status: values.status.status_id },
              values.tags && { tags: values.tags },
              values.ticket_desc && { ticket_desc: values.ticket_desc },
              values.ticket_name && { ticket_name: values.ticket_name },
              values.ticket_type && { ticket_type: values.ticket_type }
            );

            props
              .dispatch(TicketsAction.requestPostTicket(objValues))
              .then(() => {
                formikHelpers.resetForm();
                filteredMentionsActivitiesList = [];
                MentionsActivitiesList = [];
                document.querySelector("input[type='file']").value = "";
                setLoading(false);
                setStayLoading(false);
                values.navigate === true && navigate("/Tickets");
                localStorage.removeItem("panel1");
                localStorage.removeItem("panel2");
                attachements = [];
              });
          }}
          // validationSchema={validationSchema}
          validate={(values) => {
            var errors = {};
            if (!values.ticket_name) {
              errors.ticket_name = "Ticket Name is Required";
            }
            // if (!values.assigned_to) {
            //   errors.assigned_to = "Assigned to is Required";
            // }
            if (!values.ticket_desc) {
              errors.ticket_desc = "Ticket Description is Required";
            }
            if (!values.status) {
              errors.status = "Status is Required";
            }
            if (!values.platform) {
              errors.platform = "Platform is Required";
            }

            if (!values.platform) {
              errors.platform = "Platform is Required";
            }

            if (values.platform && MentionsActivitiesList.length !== 0) {
              if (!values.activities)
                errors.activities = "Activities is Required";
            }

            return errors;
          }}
          validateOnBlur={true}
          validateOnChange
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            enableReinitialize,
          }) => (
            <Form>
              <LoadingIndicator isActive={props.isRequesting}>
                {!props.isRequesting ? (
                  <Box
                    sx={{ flexGrow: 1, padding: "30px" }}
                    // className="bg-warning"
                  >
                    <Grid
                      container
                      spacing={2}
                      // className="bg-success"
                    >
                      <Grid item lg={4} md={6} sm={6} mb={3}>
                        {
                          <h3 className="p-0 m-0">
                            {formatName("Create Ticket")}
                          </h3>
                        }
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        container
                        justifyContent={"space-between"}
                        alignItems="flex--start"
                        // className="bg-info"
                      >
                        <Grid
                          item
                          lg={8}
                          md={12}
                          container
                          spacing={2}
                          // className="bg-dark"
                        >
                          <Grid
                            item
                            lg={12}
                            md={12}
                            // container
                            spacing={2}
                            flex
                            // className="bg-light"
                          >
                            <Grid item lg={12} md={12} sm={12}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                className="labelFieldBox"
                                container
                              >
                                <Grid item lg={1.6} md={2} sm={2}>
                                  <label className="p-0 m-0">
                                    Ticket Name *{" "}
                                  </label>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <FastField
                                    id="description"
                                    name="ticket_name"
                                    type="text"
                                    varient="outlined"
                                  >
                                    {({ field, form, meta }) => (
                                      <MuiTextField
                                        name="ticket_name"
                                        size="small"
                                        width="100%"
                                        value={values.ticket_name}
                                        onChange={handleChange}
                                        autoFocus
                                        // placeholder="Ticket name"
                                        error={
                                          Boolean(errors.ticket_name) &&
                                          Boolean(touched.ticket_name)
                                        }
                                        helperText={
                                          Boolean(touched.ticket_name) &&
                                          errors.ticket_name
                                        }
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} mt={2}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                className="labelFieldBox"
                                container
                                // spacing={2}
                              >
                                <Grid item lg={1.6} md={2} sm={2}>
                                  <label className="p-0 m-0">
                                    Ticket Description *{" "}
                                  </label>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <FastField
                                    className={
                                      touched.ticket_desc && errors.ticket_desc
                                        ? "ticket_desc_test_area_error scrollable"
                                        : "ticket_desc_test_area scrollable"
                                    }
                                    name="ticket_desc"
                                    component="textarea"
                                    value={values.ticket_desc}
                                    onChange={handleChange}
                                    type="text"
                                    maxRows={5}
                                    minRows={5}
                                    style={{
                                      height: 150,
                                      padding: "10px",
                                      margin: 0,
                                      boxSizing: "border-box",
                                      color: `${theme.palette.text.primary}`,
                                      boxShadow:
                                        "inset 0 3px 6px 0 rgba(0, 0, 0, 0.06)",
                                    }}
                                    aria-label="maximum height"
                                    // placeholder="Enter Ticket Description"
                                    label="ticket_desc"
                                  />
                                  <Box px={1}>
                                    <ErrorMessage name="ticket_desc" />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              className="labelFieldBox"
                              container
                              spacing={2}
                              // mt={2}
                            >
                              <Grid item lg={6} md={6} sm={6}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  flexDirection={"column"}
                                  justifyContent="center"
                                >
                                  <Grid
                                    item
                                    lg={3.2}
                                    md={4}
                                    sm={4}
                                    // className="bg-success"
                                  >
                                    <label className="p-0 m-0">
                                      Assigned to{" "}
                                    </label>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    // className="bg-info"
                                    style={{
                                      marginTop: -5,
                                    }}
                                  >
                                    <FastField
                                      name="assigned_to"
                                      size="small"
                                      // Calling AutoCompleteMultiSelect Component
                                      value={
                                        values.assigned_to
                                          ? values.assigned_to
                                          : null
                                      }
                                      disabled={
                                        userList && userList.length === 1
                                          ? true
                                          : false
                                      }
                                      component={AutoCompleteMultiSelect}
                                      options={userList ? userList : []}
                                      // disabled={true}
                                      // onChange={(_, value) => {
                                      //   setFieldValue(`client`, value);
                                      //   setFieldValue(`platform`, []);
                                      //   setOrg_id(value.organization_id);
                                      // }}
                                      filterOptions={createFilterOptions({
                                        matchFrom: "start",
                                        stringify: (option) => option.fullName,
                                      })}
                                      isOptionEqualToValue={(option, value) =>
                                        //setting value property to custom value
                                        option.fullName === value.fullName
                                      }
                                      getOptionLabel={(option) => {
                                        return option.user_name !== ""
                                          ? `${option.fullName} (${option.user_name})`
                                          : "";
                                      }}
                                      textFieldProps={{
                                        margin: "normal",
                                        variant: "outlined",
                                        // placeholder: "Assigned to",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item lg={6} md={6} sm={6}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  flexDirection={"column"}
                                  justifyContent="center"
                                >
                                  <Grid item lg={3.2} md={4} sm={4}>
                                    <label className="p-0 m-0">Status</label>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    style={{
                                      marginTop: -5,
                                    }}
                                  >
                                    <FastField
                                      name="status"
                                      size="small"
                                      // Calling AutoCompleteMultiSelect Component
                                      value={
                                        values.status ? values.status : null
                                      }
                                      component={AutoCompleteMultiSelect}
                                      options={statusList ? statusList : []}
                                      disabled={true}
                                      // onChange={(_, value) => {
                                      //   setFieldValue(`client`, value);
                                      //   setFieldValue(`platform`, []);
                                      //   setOrg_id(value.organization_id);
                                      // }}
                                      isOptionEqualToValue={(option, value) =>
                                        //setting value property to custom value
                                        option.status_name === value.status_name
                                      }
                                      getOptionLabel={(option) =>
                                        option.status_name
                                      }
                                      textFieldProps={{
                                        margin: "normal",
                                        variant: "outlined",
                                        // placeholder: "Status",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              className="labelFieldBox"
                              container
                              spacing={2}
                            >
                              <Grid item lg={6} md={6} sm={6}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  flexDirection={"column"}
                                  justifyContent="center"
                                >
                                  <Grid
                                    item
                                    lg={3.2}
                                    md={4}
                                    sm={4}
                                    // mt={1}
                                  >
                                    <label className="p-0 m-0">
                                      Platform *{" "}
                                    </label>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    // className="bg-info"
                                    style={{
                                      marginTop: -5,
                                    }}
                                  >
                                    <FastField
                                      name="platform"
                                      size="small"
                                      // Calling AutoCompleteMultiSelect Component

                                      value={
                                        values.platform ? values.platform : null
                                      }
                                      component={AutoCompleteMultiSelect}
                                      options={
                                        platform_array ? platform_array : []
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.platform_name ===
                                        value.platform_name
                                      }
                                      getOptionLabel={(option) =>
                                        option.platform_name
                                      }
                                      onChange={(_, value) => {
                                        platFormvalHandleChange(
                                          value,
                                          setFieldValue
                                        );
                                      }}
                                      textFieldProps={{
                                        margin: "normal",
                                        variant: "outlined",
                                        // placeholder: "Platform",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item lg={6} md={6} sm={6}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  container
                                  flexDirection={"column"}
                                  justifyContent="center"
                                >
                                  <Grid item lg={3.2} md={4} sm={4}>
                                    <label className="p-0 m-0">
                                      Activities
                                    </label>
                                  </Grid>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    // className="bg-info"
                                    style={{
                                      marginTop: -5,
                                    }}
                                  >
                                    <FastField
                                      name="activities"
                                      size="small"
                                      // Calling AutoCompleteMultiSelect Component
                                      value={
                                        values.activities
                                          ? values.activities
                                          : null
                                      }
                                      disabled={
                                        values.platform === "" ? true : false
                                      }
                                      component={AutoCompleteMultiSelect}
                                      options={
                                        filteredMentionsActivitiesList.length >
                                        0
                                          ? filteredMentionsActivitiesList
                                          : []
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.activity_name ===
                                        value.activity_name
                                      }
                                      getOptionLabel={(option) =>
                                        option.activity_name
                                      }
                                      textFieldProps={{
                                        margin: "normal",
                                        variant: "outlined",
                                        // placeholder: "Activities",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Box
                              sx={{
                                width: "100%",
                                mt: 3,
                              }}
                            >
                              <Grid
                                item
                                container
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                // gap={1}
                                justifyContent="space-between"
                                alignItems={"flex-start"}
                              >
                                <Grid
                                  item
                                  xl={5.8}
                                  lg={5.8}
                                  md={5.8}
                                  sm={5.8}
                                  xs={5.8}
                                >
                                  <Accordion
                                    // defaultExpanded
                                    expanded={values.expanded === "panel1"}
                                    onChange={handleExpand(
                                      "panel1",
                                      setFieldValue,
                                      values
                                    )}
                                    sx={{
                                      width: "100%",
                                      boxShadow:
                                        "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <h4>{formatName("Additionals")}</h4>
                                    </AccordionSummary>

                                    <AccordionDetails
                                      sx={{
                                        width: "100%",
                                        // height: "auto",
                                        overflow: "auto",
                                        // overscrollBehaviorY: "contain",
                                      }}
                                    >
                                      <LoadingIndicator
                                        isActive={values.ticketAddiionalLoader}
                                      />

                                      <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        container
                                      >
                                        <Grid item lg={12} md={12} sm={12}>
                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            container
                                          >
                                            <Grid item lg={3} md={4} sm={4}>
                                              <label className="p-0 m-0">
                                                Ticket Type
                                              </label>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                              <Field
                                                name="ticket_type"
                                                size="small"
                                                value={
                                                  values.ticket_type
                                                    ? values.ticket_type
                                                    : null
                                                }
                                                component={
                                                  AutoCompleteMultiSelect
                                                }
                                                options={
                                                  ticketTypeList
                                                    ? ticketTypeList
                                                    : []
                                                }
                                                isOptionEqualToValue={(
                                                  option,
                                                  value
                                                ) =>
                                                  option.ticket_type_name ===
                                                  value.ticket_type_name
                                                }
                                                getOptionLabel={(option) =>
                                                  option.ticket_type_name
                                                }
                                                style={{
                                                  marginTop: -5,
                                                }}
                                                textFieldProps={{
                                                  margin: "normal",
                                                  variant: "outlined",
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12}>
                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            container
                                          >
                                            <Grid item lg={3} md={4} sm={4}>
                                              <label className="p-0 m-0">
                                                Category
                                              </label>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                              <Field
                                                name="category"
                                                size="small"
                                                value={
                                                  values.category
                                                    ? values.category
                                                    : null
                                                }
                                                component={
                                                  AutoCompleteMultiSelect
                                                }
                                                options={
                                                  categoryList
                                                    ? categoryList
                                                    : []
                                                }
                                                filterOptions={createFilterOptions(
                                                  {
                                                    matchFrom: "start",
                                                    stringify: (option) =>
                                                      option.category_name,
                                                  }
                                                )}
                                                isOptionEqualToValue={(
                                                  option,
                                                  value
                                                ) =>
                                                  //setting value property to custom value
                                                  option.category_name ===
                                                  value.category_name
                                                }
                                                getOptionLabel={(option) =>
                                                  option.category_name
                                                }
                                                textFieldProps={{
                                                  margin: "normal",
                                                  variant: "outlined",
                                                  // placeholder: "Category",
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        container
                                      >
                                        <Grid item lg={12} md={12} sm={12}>
                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            container
                                          >
                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              mt={1}
                                            >
                                              <label className="p-0 m-0">
                                                Priority
                                              </label>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12}>
                                              <Field
                                                name="priority"
                                                size="small"
                                                // Calling AutoCompleteMultiSelect Component
                                                value={
                                                  values.priority
                                                    ? values.priority
                                                    : null
                                                }
                                                component={
                                                  AutoCompleteMultiSelect
                                                }
                                                options={
                                                  priorityList
                                                    ? priorityList
                                                    : []
                                                }
                                                // disabled={
                                                //   priorityList &&
                                                //   priorityList.length === 1
                                                //     ? true
                                                //     : false
                                                // }
                                                // disabled={true}
                                                // onChange={(_, value) => {
                                                //   setFieldValue(`client`, value);
                                                //   setFieldValue(`platform`, []);
                                                //   setOrg_id(value.organization_id);
                                                // }}
                                                isOptionEqualToValue={(
                                                  option,
                                                  value
                                                ) =>
                                                  //setting value property to custom value
                                                  option.priority_name ===
                                                  value.priority_name
                                                }
                                                e={{
                                                  marginTop: -5,
                                                }}
                                                getOptionLabel={(option) =>
                                                  option.priority_name
                                                }
                                                textFieldProps={{
                                                  margin: "normal",
                                                  variant: "outlined",
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12}>
                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            container
                                          >
                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              mt={1}
                                            >
                                              <label className="p-0 m-0">
                                                Tag
                                              </label>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                              <Field
                                                name="tags"
                                                size="small"
                                                // Calling AutoCompleteMultiSelect Component
                                                value={
                                                  values.tags
                                                    ? values.tags
                                                    : null
                                                }
                                                component={
                                                  AutoCompleteMultiSelect
                                                }
                                                options={tagList ? tagList : []}
                                                // disabled={
                                                //   tagList && tagList.length === 1
                                                //     ? true
                                                //     : false
                                                // }
                                                // onChange={(_, value) => {
                                                //   setFieldValue(`client`, value);
                                                //   setFieldValue(`platform`, []);
                                                //   setOrg_id(value.organization_id);
                                                // }}
                                                multiple
                                                isOptionEqualToValue={(
                                                  option,
                                                  value
                                                ) =>
                                                  //setting value property to custom value
                                                  option.tag_name ===
                                                  value.tag_name
                                                }
                                                getOptionLabel={(option) =>
                                                  option.tag_name
                                                }
                                                style={{
                                                  marginTop: -5,
                                                }}
                                                textFieldProps={{
                                                  margin: "normal",
                                                  variant: "outlined",
                                                  // placeholder: "Tags",
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        container
                                      >
                                        <Grid item lg={12} md={12} sm={12}>
                                          <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            container
                                          >
                                            <Grid
                                              item
                                              lg={3}
                                              md={4}
                                              sm={4}
                                              mt={1}
                                            >
                                              <label className="p-0 m-0">
                                                Source Link
                                              </label>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                              <FastField
                                                id="description"
                                                name="souce_link"
                                                type="text"
                                                varient="outlined"
                                              >
                                                {({ field, form, meta }) => (
                                                  <MuiTextField
                                                    name="souce_link"
                                                    // placeholder="Source Link"
                                                    size="small"
                                                    value={values.souce_link}
                                                    onChange={handleChange}
                                                  />
                                                )}
                                              </FastField>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                                <Grid
                                  item
                                  xl={5.8}
                                  lg={5.8}
                                  md={5.8}
                                  sm={5.8}
                                  xs={5.8}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      boxShadow:
                                        "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                                    }}
                                  >
                                    <Accordion
                                      expanded={values.expanded === "panel2"}
                                      onChange={handleExpand(
                                        "panel2",
                                        setFieldValue,
                                        values
                                      )}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                      >
                                        <h4>
                                          {formatName("Custom Ticket Fields")}
                                        </h4>
                                      </AccordionSummary>
                                      <LoadingIndicator
                                        isActive={values.customFieldLoader}
                                      />
                                      <AccordionDetails
                                        className="scrollable"
                                        sx={{
                                          width: "100%",
                                          height: 220,
                                          overflow: "auto",
                                          overscrollBehaviorY: "contain",
                                        }}
                                      >
                                        {values.custom_value.length > 0 ? (
                                          <Box>
                                            {values.custom_value &&
                                              values.custom_value.map(
                                                (item, index) => {
                                                  if (
                                                    item.field_type === "text"
                                                  ) {
                                                    return (
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        lg={12}
                                                        laptop={12}
                                                        md={12}
                                                        sm={12}
                                                        mt={1}
                                                        key={index}
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Grid
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          mt={1}
                                                        >
                                                          <label
                                                            htmlFor={
                                                              item.field_name
                                                            }
                                                          >
                                                            {item.field_name}{" "}
                                                            {" :"}
                                                          </label>
                                                        </Grid>
                                                        <Grid
                                                          lg={10}
                                                          md={10}
                                                          sm={10}
                                                        >
                                                          <FastField
                                                            id={item.field_id}
                                                            name={`custom_value.${index}.field_values[0].value`}
                                                            // render={({ field }) => (
                                                            //   <MuiTextField
                                                            //     name={`custom_value.${index}.field_values[0].value`}
                                                            //     size="small"
                                                            //     value={
                                                            //       item.field_values[0].value
                                                            //     }
                                                            //     onChange={handleChange}
                                                            //   />
                                                            // )}
                                                            type="text"
                                                            varient="outlined"
                                                          >
                                                            {({
                                                              field,
                                                              form,
                                                              meta,
                                                            }) => (
                                                              <MuiTextField
                                                                name={`custom_value.${index}.field_values[0].value`}
                                                                size="small"
                                                                value={
                                                                  item
                                                                    .field_values[0]
                                                                    .value
                                                                }
                                                                onChange={
                                                                  handleChange
                                                                }
                                                              />
                                                            )}
                                                          </FastField>
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  } else if (
                                                    item.field_type === "select"
                                                  ) {
                                                    return (
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        lg={12}
                                                        laptop={12}
                                                        md={12}
                                                        sm={12}
                                                        mt={1}
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Grid
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          mt={3}
                                                        >
                                                          <label
                                                            htmlFor={
                                                              item.field_name
                                                            }
                                                          >
                                                            {item.field_name}
                                                            {" :"}
                                                          </label>
                                                        </Grid>
                                                        <Grid
                                                          lg={10}
                                                          md={10}
                                                          sm={10}
                                                        >
                                                          <FastField
                                                            name={`custom_value.${index}.field_values[0]`}
                                                            component={
                                                              AutoCompleteMultiSelect
                                                            }
                                                            options={
                                                              custom_value_array &&
                                                              custom_value_array.filter(
                                                                (e) => {
                                                                  return (
                                                                    e._id ===
                                                                    item.field_id
                                                                  );
                                                                }
                                                              ).length != 0
                                                                ? custom_value_array.filter(
                                                                    (e) => {
                                                                      return (
                                                                        e._id ===
                                                                        item.field_id
                                                                      );
                                                                    }
                                                                  )[0]._source
                                                                    .field_values
                                                                : []
                                                            }
                                                            size="small"
                                                            isOptionEqualToValue={(
                                                              option,
                                                              value
                                                            ) =>
                                                              //setting value property to custom value
                                                              option.value ===
                                                              value.value
                                                            }
                                                            //setting custom label to custom value
                                                            getOptionLabel={(
                                                              option
                                                            ) =>
                                                              option.value
                                                                ? option.value
                                                                : ""
                                                            }
                                                            textFieldProps={{
                                                              fullWidth: true,
                                                              margin: "normal",
                                                              variant:
                                                                "outlined",
                                                            }}
                                                            // multiple
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  } else if (
                                                    item.field_type === "toggle"
                                                  ) {
                                                    return (
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        lg={12}
                                                        laptop={12}
                                                        md={12}
                                                        sm={12}
                                                        mt={1}
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Grid
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          mt={1}
                                                        >
                                                          <label
                                                            htmlFor={
                                                              item.field_name
                                                            }
                                                          >
                                                            {item.field_name}
                                                          </label>
                                                          {" :"}
                                                        </Grid>
                                                        <Grid
                                                          lg={10}
                                                          md={10}
                                                          sm={10}
                                                        >
                                                          <FormControlLabel
                                                            control={
                                                              <FastField
                                                                component={
                                                                  SwitchButton
                                                                }
                                                                type="checkbox"
                                                                name={`custom_value.${index}.field_values[0].value`}
                                                                checked={
                                                                  item
                                                                    .field_values[0]
                                                                    ? item
                                                                        .field_values[0]
                                                                        .value
                                                                    : null
                                                                }
                                                              />
                                                            }
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  }
                                                  // else if (item.field_type === "radio") {
                                                  //   return (
                                                  //     <Grid
                                                  //       item
                                                  //       xl={12}
                                                  //       lg={12}
                                                  //       laptop={12}
                                                  //       md={12}
                                                  //       sm={12}
                                                  //       mt={1}
                                                  //       sx={{
                                                  //         display: "flex",
                                                  //         justifyContent: "space-between",
                                                  //       }}
                                                  //     >
                                                  //       <Grid lg={2} md={2} sm={2} mt={1}>
                                                  //         <label htmlFor={item.field_name}>
                                                  //           {item.field_name}
                                                  //         </label>
                                                  //         {" :"}
                                                  //       </Grid>
                                                  //       <Grid lg={10} md={10} sm={10}></Grid>
                                                  //     </Grid>
                                                  //   );
                                                  // }
                                                  else if (
                                                    item.field_type ===
                                                    "multiselect"
                                                  ) {
                                                    return (
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        mt={2}
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Grid
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          mt={1}
                                                        >
                                                          <label
                                                            htmlFor={
                                                              item.field_name
                                                            }
                                                          >
                                                            {item.field_name}
                                                          </label>
                                                          {" :"}
                                                        </Grid>
                                                        <Grid
                                                          lg={10}
                                                          md={10}
                                                          sm={10}
                                                        >
                                                          <FastField
                                                            name={
                                                              item.field_name
                                                            }
                                                            component={
                                                              AutoCompleteMultiSelect
                                                            }
                                                            multiple
                                                            label={
                                                              item.field_name
                                                            }
                                                            size="small"
                                                            options={
                                                              item.field_values
                                                            }
                                                            isOptionEqualToValue={(
                                                              option,
                                                              value
                                                            ) =>
                                                              //setting value property to custom value
                                                              option.value ===
                                                              value.value
                                                            }
                                                            //setting custom label to custom value
                                                            getOptionLabel={(
                                                              option
                                                            ) => option.value}
                                                            textFieldProps={{
                                                              fullWidth: true,
                                                              margin: "normal",
                                                              variant:
                                                                "outlined",
                                                            }}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  } else if (
                                                    item.field_type === "date"
                                                  ) {
                                                    return (
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        mt={2}
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Grid
                                                          lg={2}
                                                          md={2}
                                                          sm={2}
                                                          mt={3}
                                                        >
                                                          <label
                                                            htmlFor={
                                                              item.field_name
                                                            }
                                                          >
                                                            {item.field_name}
                                                          </label>
                                                          {" :"}
                                                        </Grid>
                                                        <Grid
                                                          lg={10}
                                                          md={10}
                                                          sm={10}
                                                        >
                                                          <FastField
                                                            component={
                                                              DatePickerComponent
                                                            }
                                                            size="small"
                                                            name={`custom_value.${index}.field_values[0].value`}
                                                            label={false}
                                                            textFieldProps={{
                                                              fullWidth: true,
                                                              margin: "normal",
                                                              variant:
                                                                "outlined",
                                                            }}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  }
                                                  // else if (item.field_type === "category") {
                                                  //   return (
                                                  //     <Grid
                                                  //       item
                                                  //       xl={12}
                                                  //       lg={12}
                                                  //       md={12}
                                                  //       sm={12}
                                                  //       container
                                                  //       justifyContent={"center"}
                                                  //       alignItems="center"
                                                  //       // className="bg-warning"
                                                  //     >
                                                  //       <Grid
                                                  //         item
                                                  //         lg={2}
                                                  //         md={2}
                                                  //         sm={2}
                                                  //         // className="bg-success"
                                                  //       >
                                                  //         <label htmlFor={item.field_name}>
                                                  //           {/* {item.field_name} */}
                                                  //           {/* {returnFieldName(item.field_id)} */}
                                                  //           {item.field_name}
                                                  //         </label>{" "}
                                                  //         {" :"}
                                                  //       </Grid>

                                                  //       <Grid
                                                  //         item
                                                  //         lg={10}
                                                  //         md={10}
                                                  //         sm={10}
                                                  //         // className="bg-info"
                                                  //       >
                                                  //         {item.field_values[0].value !== "" ? (
                                                  //           <Chip
                                                  //             label={item.field_values[0].value}
                                                  //             deleteIcon={<EditIcon />}
                                                  //             variant="contained"
                                                  //             onClick={() => {}}
                                                  //             // onDelete={() => {
                                                  //             //   setOpenCategoryDialog(true);
                                                  //             // }}
                                                  //           />
                                                  //         ) : (
                                                  //           <Chip
                                                  //             label={"Select Category"}
                                                  //             variant="contained"
                                                  //             onClick={() => {
                                                  //               setCategoryUpdateOpen(true);
                                                  //             }}
                                                  //           />
                                                  //         )}
                                                  //       </Grid>

                                                  //       <CategoryFilterDialog
                                                  //         open={categoryUpdateOpen}
                                                  //         // setFieldValue={(value) => {
                                                  //         //   setFieldValue(`sub_value`, value);
                                                  //         // }}
                                                  //         // sub_value={values.sub_value}
                                                  //         // onClose={() => {
                                                  //         //   setCategoryUpdateOpen(false);
                                                  //         // }}
                                                  //         // customticketfields={
                                                  //         //   props.customticketfields
                                                  //         // }
                                                  //         // customticketfields_filter={
                                                  //         //   props.customticketfields_filter
                                                  //         // }
                                                  //       />

                                                  //       {/* {openCategoryDialog && (
                                                  //         <CategoryUpdateDialog
                                                  //           open={openCategoryDialog}
                                                  //           onClose={() => {
                                                  //             setOpenCategoryDialog(false);
                                                  //           }}
                                                  //           ticketData={props.ticket}
                                                  //           custom_value={
                                                  //             props.ticket._source &&
                                                  //             props.ticket._source
                                                  //               .custom_value &&
                                                  //             props.ticket._source.custom_value
                                                  //               .length !== 0
                                                  //               ? props.ticket._source
                                                  //                   .custom_value
                                                  //               : []
                                                  //           }
                                                  //         />
                                                  //       )} */}
                                                  //     </Grid>
                                                  //   );
                                                  // }
                                                }
                                              )}
                                          </Box>
                                        ) : (
                                          <Box>
                                            <NoDataFound />
                                          </Box>
                                        )}
                                      </AccordionDetails>
                                    </Accordion>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item lg={3.5} md={12} sm={12} container>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            spacing={2}
                          >
                            <Grid item lg={12} md={12} sm={12}>
                              <h4>{formatName("Customer Details")}</h4>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                flexDirection={"column"}
                                // spacing={2}
                              >
                                <Grid item lg={3} md={3} sm={3}>
                                  <label className="p-0 m-0">
                                    Customer Name
                                  </label>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <FastField
                                    id="description"
                                    name="customer.customer_name"
                                    type="text"
                                    varient="outlined"
                                  >
                                    {({ field, form, meta }) => (
                                      <MuiTextField
                                        name="customer.customer_name"
                                        size="small"
                                        value={values.customer.customer_name}
                                        onChange={handleChange}
                                        // placeholder="Customer name"
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                flexDirection={"column"}
                              >
                                <Grid item lg={3} md={3} sm={3}>
                                  <label className="p-0 m-0">
                                    Customer Email
                                  </label>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <FastField
                                    id="description"
                                    name="customer.customer_email"
                                    // render={({ field }) => (
                                    //   <MuiTextField
                                    //     name="customer.customer_email"
                                    //     size="small"
                                    //     value={values.customer.customer_email}
                                    //     onChange={handleChange}
                                    //     placeholder="Customer email"
                                    //   />
                                    // )}
                                    type="text"
                                    varient="outlined"
                                  >
                                    {({ field, form, meta }) => (
                                      <MuiTextField
                                        name="customer.customer_email"
                                        size="small"
                                        value={values.customer.customer_email}
                                        onChange={handleChange}
                                        // placeholder="Customer email"
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                flexDirection={"column"}
                              >
                                <Grid item lg={3} md={3} sm={3}>
                                  <label className="p-0 m-0">
                                    Customer Phone
                                  </label>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <FastField
                                    id="description"
                                    name="customer.customer_phone"
                                    type="text"
                                    varient="outlined"
                                  >
                                    {({ field, form, meta }) => (
                                      <MuiTextField
                                        name="customer.customer_phone"
                                        size="small"
                                        value={values.customer.customer_phone}
                                        onChange={handleChange}
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                flexDirection={"column"}
                              >
                                <Grid item lg={3} md={3} sm={3}>
                                  <label className="p-0 m-0">
                                    Customer Location
                                  </label>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <FastField
                                    id="description"
                                    name="customer.customer_location"
                                    type="text"
                                    varient="outlined"
                                  >
                                    {({ field, form, meta }) => (
                                      <MuiTextField
                                        name="customer.customer_location"
                                        size="small"
                                        value={
                                          values.customer.customer_location
                                        }
                                        onChange={handleChange}
                                      />
                                    )}
                                  </FastField>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            lg={12}
                            container
                            alignItems={"center"}
                            justifyContent="center"
                            gap={2}
                          >
                            <MuiButton
                              type={"submit"}
                              width="fit-content"
                              disabled={values.boolean}
                              loading={loading}
                              title={
                                "Create Ticket & Navigate to Ticket List Page"
                              }
                              name={"Navigate"}
                              onClick={() => {
                                setFieldValue("navigate", true);
                              }}
                            />

                            <MuiButton
                              type={"submit"}
                              width="fit-content"
                              disabled={values.boolean}
                              title={"Create Ticket & Stay on the Same Screen"}
                              loading={stayLoading}
                              name={"Stay"}
                            />

                            <MuiButton
                              type={"reset"}
                              name={"Reset"}
                              onClick={handleClear}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Box
                        mt={1}
                        sx={{
                          width: "100%",
                          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                        }}
                      >
                        <Accordion
                          expanded={values.expanded === "panel3"}
                          onChange={handleExpand(
                            "panel3",
                            setFieldValue,
                            values
                          )}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h4>{formatName("Advanced")}</h4>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              width: "100%",
                              // height: "auto",
                              overflow: "auto",
                              // overscrollBehaviorY: "contain",
                            }}
                          >
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              spacing={2}
                            >
                              <Grid item lg={12} md={12} sm={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.is_child_ticket}
                                      onChange={(e) =>
                                        isChildHandleChange(
                                          e.target.checked,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  }
                                  label="is child ticket"
                                  name="is_child_ticket"
                                />
                              </Grid>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                spacing={2}
                              >
                                <Grid item lg={6} md={12} sm={12}>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="labelFieldBox"
                                    container
                                    spacing={2}
                                  >
                                    <Grid
                                      item
                                      lg={2}
                                      md={3}
                                      sm={3}
                                      sx={{ display: "inline-flex" }}
                                    >
                                      <label>Parent Ticket Name {"   "}</label>
                                    </Grid>

                                    <Grid item lg={5} md={5} sm={5} mt={-1}>
                                      <Field
                                        name="parent_ticket_id"
                                        size="small"
                                        // Calling AutoCompleteMultiSelect Component
                                        value={
                                          values.parent_ticket_id
                                            ? values.parent_ticket_id
                                            : null
                                        }
                                        component={AutoCompleteMultiSelect}
                                        disabled={!values.is_child_ticket}
                                        filterOptions={createFilterOptions({
                                          matchFrom: "start",
                                          stringify: (option) =>
                                            option.ticket_name,
                                        })}
                                        svgColor="#bab8b8"
                                        options={
                                          tickets_array ? tickets_array : []
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          //setting value property to custom value
                                          option.ticket_id === value.ticket_id
                                        }
                                        getOptionLabel={(option) =>
                                          option.ticket_name
                                        }
                                        textFieldProps={{
                                          margin: "normal",
                                          variant: "outlined",
                                          placeholder: "Parent ticket id",
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item lg={6} md={12} sm={12}>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    className="labelFieldBox"
                                    container
                                    spacing={2}
                                  >
                                    <Grid
                                      item
                                      lg={2}
                                      md={3}
                                      sm={3}
                                      sx={{ display: "inline-flex" }}
                                    >
                                      <label>Related Tickets</label>
                                    </Grid>

                                    <Grid item lg={5} md={5} sm={5} mt={-1}>
                                      <Field
                                        name="related_tickets"
                                        size="small"
                                        multiple
                                        // Calling AutoCompleteMultiSelect Component
                                        value={
                                          values.related_tickets
                                            ? values.related_tickets
                                            : []
                                        }
                                        component={AutoCompleteMultiSelect}
                                        disabled={!values.is_child_ticket}
                                        options={
                                          tickets_array ? tickets_array : []
                                        }
                                        filterOptions={createFilterOptions({
                                          matchFrom: "start",
                                          stringify: (option) =>
                                            option.ticket_name,
                                        })}
                                        isOptionEqualToValue={(option, value) =>
                                          //setting value property to custom value
                                          option.ticket_name ===
                                          value.ticket_name
                                        }
                                        getOptionLabel={(option) =>
                                          option.ticket_name
                                        }
                                        textFieldProps={{
                                          margin: "normal",
                                          variant: "outlined",
                                          placeholder: "Related tickets",
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item lg={12} md={12} sm={12} container>
                                <Grid
                                  item
                                  lg={1.5}
                                  md={3}
                                  sm={3}
                                  textAlign="left"
                                >
                                  <label>Attachment</label>
                                </Grid>
                                <Grid item container lg={5} md={5} sm={5}>
                                  <Grid
                                    item
                                    container
                                    xl={12}
                                    // className="bg-warning"
                                  >
                                    <Grid
                                      item
                                      // className="bg-success"
                                      xl={10}
                                    >
                                      <input
                                        disabled={values.boolean}
                                        type="file"
                                        // name="attachments"
                                        // onChange={(e) => {
                                        //   setFieldValue(
                                        //     "attachments",
                                        //     e.target.files[0]
                                        //   );
                                        // }}
                                        style={{ cursor: "pointer" }}
                                        onChange={(e) => {
                                          handlFileUpload(
                                            e.target.files[0],
                                            setFieldValue,
                                            e
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item lg={12} md={12} sm={12} container>
                                {values.Attachment_url &&
                                  values.Attachment_url.map((chip) => (
                                    <Grid
                                      item
                                      // className="bg-success"
                                    >
                                      <Chip
                                        key={chip.file_name}
                                        label={chip.file_name}
                                        onDelete={() =>
                                          handleDelete(
                                            chip,
                                            setFieldValue,
                                            values.Attachment_url
                                          )
                                        }
                                        // color="primary"
                                      />
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </Grid>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "80vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingBgImage />
                  </Box>
                )}
              </LoadingIndicator>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
export default connect(mapStateToProps)(CreateTicket);
