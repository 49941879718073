import BaseReducer from "../../../utilities/BaseReducer";
import UserMailMappingAction from "./UserMailMappingAction";

// UserMailMappingReduser
export default class UserMailMappingReduser extends BaseReducer {
  initialState = {
    userMailMapping: [],
  };
  [UserMailMappingAction.REQUEST_USERMAIL_MAPPING_FINISHED](state, action) {
    return {
      ...state,
      userMailMapping: action.payload,
    };
  }
}
