import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// Category data model
export default class TwitterDashboardDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  followers_count="";
  following_count="";
  tweet_count="";
  user_name="";


  constructor(data) {
    super();

    this.update(data);
  }
}
