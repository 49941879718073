import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import QuestionsPutModel from "./models/QuestionsPutModel";
import QuestionsPostModel from "./models/QuestionsPostModel";
import QuestionsModel from "./models/QuestionModel";
import QuestionsBulkUpdateModel from "./models/QuestionsBulkUpdateModel";

export default class QuestionCreateEffect {
  static async requestQuestion(params) {
    const endpoint = environment.api.questions;
    return EffectUtility.getToModel(QuestionsModel, endpoint, params);
  }

  static async requestQuestionFilter(params) {
    const endpoint = environment.api.questionsFilter;
    return EffectUtility.getToModel(QuestionsModel, endpoint, params);
  }

  static async requestQuestionsPost(data) {
    const endpoint = environment.api.questions;
    return EffectUtility.postToModel(QuestionsPostModel, endpoint, data);
  }

  static async requestQuestionsPut(data, id) {
    const endpoint = environment.api.questions + "/" + id;
    return EffectUtility.putToModel(QuestionsPutModel, endpoint, data);
  }

  // Put method for the Questions bulk update
  static async requestPutQuestionsBulkUpdate(data) {
    const endpoint = environment.api.questionsBulkUpdate;
    return EffectUtility.putToModel(QuestionsBulkUpdateModel, endpoint, data);
  }
}
