import ActionUtility from "../../utilities/ActionUtility";
import ORMnotificationEffect from "./ORMNotoficationEffect";

export default class OrmNotificationAction {
  static REQUEST_ORM_NOTIFICATIONS =
    "OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS";
  static REQUEST_ORM_NOTIFICATIONS_FINISHED =
    "OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_FINISHED";

  static REQUEST_ORM_NOTIFICATIONS_FILTER =
    "OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_FILTER";
  static REQUEST_ORM_NOTIFICATIONS_FILTER_FINISHED =
    "OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_FILTER_FINISHED";

  static REQUEST_ORM_NOTIFICATIONS_BY_ID =
    "OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_BY_ID";
  static REQUEST_ORM_NOTIFICATIONS_BY_ID_FINISHED =
    "OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_BY_ID_FINISHED";

  static REQUEST_POST_ORM_NOTIFICATIONS =
    "OrmNotificationAction.REQUEST_POST_ORM_NOTIFICATIONS";
  static REQUEST_POST_ORM_NOTIFICATIONS_FINISHED =
    "OrmNotificationAction.REQUEST_POST_ORM_NOTIFICATIONS_FINISHED";

  static REQUEST_PUT_ORM_NOTIFICATIONS =
    "OrmNotificationAction.REQUEST_PUT_ORM_NOTIFICATIONS";
  static REQUEST_PUT_ORM_NOTIFICATIONS_FINISHED =
    "OrmNotificationAction.REQUEST_PUT_ORM_NOTIFICATIONS_FINISHED";

  static REQUEST_PUT_ORM_NOTIFICATIONS_BULK_UPDATE =
    "OrmNotificationAction.REQUEST_PUT_ORM_NOTIFICATIONS_BULK_UPDATE";
  static REQUEST_PUT_ORM_NOTIFICATIONS_BULK_UPDATE_FINISHED =
    "OrmNotificationAction.REQUEST_PUT_ORM_NOTIFICATIONS_BULK_UPDATE_FINISHED";

  static REQUEST_DELETE_ORM_NOTIFICATIONS =
    "OrmNotificationAction.REQUEST_DELETE_ORM_NOTIFICATIONS";
  static REQUEST_DELETE_ORM_NOTIFICATIONS_FINISHED =
    "OrmNotificationAction.REQUEST_DELETE_ORM_NOTIFICATIONS_FINISHED";

  //METHODS

  static requestOrmNotifications(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS,
        ORMnotificationEffect.requestOrmNotifications,
        params
      );
    };
  }

  static requestOrmNotificationsFilter(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_FILTER,
        ORMnotificationEffect.requestOrmNotificationsFilter,
        params,
        callback
      );
    };
  }

  static requestOrmNotificationsById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmNotificationAction.REQUEST_ORM_NOTIFICATIONS_BY_ID,
        ORMnotificationEffect.requestOrmNotificationsById,
        id,
        params
      );
    };
  }

  static requestPutOrmNotificationsBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmNotificationAction.REQUEST_PUT_ORM_NOTIFICATIONS_BULK_UPDATE,
        ORMnotificationEffect.requestPutOrmNotificationsBulkUpdate,
        data
      );
    };
  }

  //   static requestPostOrmNotifications(data) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         OrmNotificationAction.REQUEST_POST_ORM_NOTIFICATIONS,
  //         ORMnotificationEffect.requestPostOrmNotifications,
  //         data
  //       );
  //     };
  //   }

  static requestPutOrmNotifications(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OrmNotificationAction.REQUEST_PUT_ORM_NOTIFICATIONS,
        ORMnotificationEffect.requestPutOrmNotifications,
        data,
        id
      );
    };
  }

  //   static requestDeleteOrmNotifications(id) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         OrmNotificationAction.REQUEST_DELETE_ORM_NOTIFICATIONS,
  //         ORMnotificationEffect.requestDeleteOrmNotifications,
  //         id
  //       );
  //     };
  //   }
}
