import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import ProjectModel from "./models/ProjectModel";
import ProjectPostModel from "./models/ProjectPostDataModel";
import ProjectPutModel from "./models/ProjectPutModel";
import ProjectDeleteModel from "./models/ProjectDeleteModel";
import ProjectByIdModel from "./models/ProjectByIdModel";

export default class ProjectEffect {
  // calling an API according to the ProjectModel
  static async requestProject(params) {
    const endpoint = environment.api.project;
    return EffectUtility.getToModel(ProjectModel, endpoint, params);
  }

  static async requestProjectById(id) {
    const endpoint = environment.api.project + "/" + id;
    return EffectUtility.getToModel(ProjectByIdModel, endpoint);
  }

  // Project filter with page_limit none
  static async requestProjectFilter(params, callBackFunction) {
    const endpoint = environment.api.projectfilter;
    let response = EffectUtility.getToModel(ProjectModel, endpoint, params);

    response.then((resp) => {
      if (resp.status === "skip" && callBackFunction) {
        callBackFunction();
      }

      if (resp && resp.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  // Post API
  static async requestPostProject(data, callBackFunction) {
    const endpoint = environment.api.project + "/add";

    let response = EffectUtility.postToModel(ProjectPostModel, endpoint, data);

    response.then((resp) => {
      if (resp.status === "success" && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  // Put API
  static async requestPutProject(data, id) {
    const endpoint = environment.api.project + "/" + id;

    return EffectUtility.putToModel(ProjectPutModel, endpoint, data);
  }

  // Delete API
  static async requestDeleteProject(id) {
    const endpoint = environment.api.project + "/" + id;

    return EffectUtility.deleteToModel(ProjectDeleteModel, endpoint);
  }
}
