import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import PriorityModel from "./models/PriorityModel";
import PriorityBulkUpdateModel from "./models/PriorityBulkUpdateModel";

export default class PriorityEffect {
  //get priority request end point
  static async requestPriority(params) {
    const endpoint = environment.api.priority;

    return EffectUtility.getToModel(PriorityModel, endpoint, params);
  }

  //get priority request end point
  static async filterRequestPriority(params) {
    const endpoint = environment.api.filterpriority;

    return EffectUtility.getToModel(PriorityModel, endpoint, params);
  }

  // Post api
  static async requestPostPriority(data) {
    const endpoint = environment.api.priority + "/add";
    return EffectUtility.postToModel(PriorityModel, endpoint, data);
  }

  //Put api
  static async requestPutPriority(data, id) {
    const endpoint = environment.api.priority + "/" + id;

    return EffectUtility.putToModel(PriorityModel, endpoint, data);
  }

  // Put method for the Priority bulk update
  static async requestPutPriorityBulkUpdate(data) {
    const endpoint = environment.api.priorityBulkUpdate;
    return EffectUtility.putToModel(PriorityBulkUpdateModel, endpoint, data);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
