import ActionUtility from "../../../../utilities/ActionUtility";
import ClaimRequestEffect from "./ClaimRequestEffect";

export default class ClaimRequestAction {
  static REQUEST_REVENUE_CLAIM_REQUEST =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST";
  static REQUEST_REVENUE_CLAIM_REQUEST_FINISHED =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_FINISHED";

  static REQUEST_REVENUE_CLAIM_REQUEST_BY_ID =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID";
  static REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_FINISHED =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_FINISHED";

  static REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_CLEAR =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_CLEAR";
  static REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_CLEAR_FINISHED =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_CLEAR_FINISHED";

  static REQUEST_REVENUE_CLAIM_REQUEST_PUT =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_PUT";
  static REQUEST_REVENUE_CLAIM_REQUEST_PUT_FINISHED =
    "ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_PUT_FINISHED";

  static REQUEST_REVENUE_INVOICE_CLAIM_DETAILS =
    "ClaimRequestAction.REQUEST_REVENUE_INVOICE_CLAIM_DETAILS";
  static REQUEST_REVENUE_INVOICE_CLAIM_DETAILS_FINISHED =
    "ClaimRequestAction.REQUEST_REVENUE_INVOICE_CLAIM_DETAILS_FINISHED";

  // METHODS
  static requestRevenueClaimRequest(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST,
        ClaimRequestEffect.requestRevenueClaimRequest,
        params
      );
    };
  }

  static requestRevenueClaimRequestById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID,
        ClaimRequestEffect.requestRevenueClaimRequestById,
        id,
        params,
        callBack
      );
    };
  }

  static requestRevenueClaimRequestByIdClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_BY_ID_CLEAR,
        ClaimRequestEffect.requestRevenueClaimRequestByIdClear
      );
    };
  }

  static requestRevenueClaimRequestPut(data, id, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_PUT,
        ClaimRequestEffect.requestRevenueClaimRequestPut,
        data,
        id,
        params,
        callback
      );
    };
  }

  static requestInvoiceClaimDetails(clientId) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClaimRequestAction.REQUEST_REVENUE_INVOICE_CLAIM_DETAILS,
        ClaimRequestEffect.requestInvoiceClaimDetails,
        clientId
      );
    };
  }
}
