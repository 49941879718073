import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import BrandCompetitorPost from "../../components/brandCompetitor/BrandCompetitorPost";
import BrandCompitatorAction from "../../stores/brandCompitator/BrandCompitatorAction";
import PlatformAction from "../../stores/platform/PlatformAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    BrandCompitatorAction.REQUEST_BRAND_COMPITATOR,
    BrandCompitatorAction.REQUEST_BRAND_COMPITATOR_FILTER,
    PlatformAction.REQUEST_PLATFORM,
  ]),
  brandCompitator: state.brandCompitator.brandCompitator.hits || [],
  brandCompitatorFilter:
    state.brandCompitatorFilter.brandCompitatorFilter.hits || [],
  pagination: state.brandCompitator.brandCompitator || [],
  platform: state.platform.platform || [],
});

const BrandCompitator = (props) => {
  const { isRequesting } = props;

  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [page_limit] = useState(15);
  const [brandName, setBrandName] = useState("");
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchBrandCompitator(params) {
    props.dispatch(BrandCompitatorAction.requestBrandCompitator(params));
  }

  function fetchBrandCompitatorFilter(param) {
    props.dispatch(BrandCompitatorAction.requestBrandCompitatorFilter(param));
  }

  function fetchOrgPlatforms() {
    let param = {
      level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }
  useEffect(() => {
    const params = {
      page_number: 0,
      page_limit: `${page_limit}`,
    };

    fetchBrandCompitator(params);

    const param = {
      page_limit: "none",
    };
    fetchBrandCompitatorFilter(param);
    fetchOrgPlatforms();
  }, []);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "brand_competitor_account_name",
      headerName: "Brand Competitor Name",
      width: 250,
      flex: 4,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 4,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
            title="Edit"
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const rows = [];

  if (props.brandCompitator.hits) {
    props.brandCompitator.hits.map((item, index) => {
      return rows.push({
        sl_no: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        id: item._id,
        brand_competitor_account_name:
          item._source.brand_competitor_account_name,
        created_by: item._source.created_by.user_name,
        account: item._source.account,
        platform: item._source.platform,
        competitor_channel_id: item._source.competitor_channel_id,
      });
    });
  }

  const brandCompitatorList = [];

  if (props.brandCompitatorFilter.hits) {
    props.brandCompitatorFilter.hits.map((value) => {
      return brandCompitatorList.push(
        value._source.brand_competitor_account_name
      );
    });
  }

  brandCompitatorList.sort((a, b) => a.localeCompare(b));
  brandCompitatorList = [...new Set(brandCompitatorList)];

  // pagination
  const handleChange = (event, value) => {
    // page_number=1&page_limit=10
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (brandName !== null) {
      filter.push(
        `["brand_competitor_account_name.keyword","must","","match","${brandName}"]`
      );
    }

    if (brandName !== null && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetchBrandCompitator(params);
    }
  };

  const brandHandleChange = (event, value) => {
    if (value != null) {
      setBrandName(value);
    } else {
      setBrandName(null);
    }
  };

  const handleFilter = () => {
    const filter = [];
    if (brandName !== null) {
      filter.push(
        `["brand_competitor_account_name.keyword","must","","match","${brandName}"]`
      );
    }

    if (brandName !== null) {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchBrandCompitator(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    // Clear the state

    if (brandName !== null) {
      setClearLoader(true);
      setBrandName(null);
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);

    const params = {
      page_limit: 15,
    };
    FilterClicked && fetchBrandCompitator(params);
  };

  let platform_array = [];

  if (props.platform.hits) {
    props.platform.hits.hits.map((item) => {
      return platform_array.push({
        platform_id: item._id,
        platform_name: item._source.platform_name,
      });
    });
  }

  return (
    <React.Fragment>
      {/* Tag post modal screen */}
      <Box>
        <BrandCompetitorPost
          open={open}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          platform_array={platform_array}
          brandEditData={editRowsModel}
          edit={edit}
        />

        {/*************************************************** Tag Filter */}

        <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.5} mt={1}>
              <AutoCompleteComponent
                value={brandName}
                list={brandCompitatorList}
                textFieldProps={{
                  placeholder: "Brand Competitor Name",
                }}
                handleChange={brandHandleChange}
                size="small"
                width="100%"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={5}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  sm={8}
                  md={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      name={"Filter"}
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Clear"}
                      width="100%"
                      onClick={ClearState}
                      loading={clearLoader && isRequesting}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  {/* post button component*/}
                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid item lg={8} md={12} sm={12} xs={12}>
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {props.brandCompitator.hits ? (
                    <DataGridFunction
                      columns={columns}
                      rows={rows}
                      rowHeight={60}
                      // rowReordering
                      // onRowOrderChange={rowOrderHandleChange}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                    />
                  ) : null}
                </LoadingIndicator>
              </Grid>
            </Grid>

            {/* Pagination */}
            {/* <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={10}
              md={12}
              sm={12}
              className="pagination_section"
            >
              <Grid item lg={8} md={12} sm={12}>
                {props.tag.hits
                  ? props.tag.hits.length !== 0 && (
                      <PaginationUI
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  : null}
              </Grid>
            </Grid> */}
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(BrandCompitator);
