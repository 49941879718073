import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
// postPlus button component
// DataGridFunction Component
import { Button, Grid, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import CategoryAction from "../../stores/category/CategoryAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import CategoryPost from "../../components/categoryPostForm/CategoryPost";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

// mapstateto props function fetching both project api and category api
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    CategoryAction.REQUEST_CATEGORY,
    CategoryAction.REQUEST_CATEGORY_FILTER,
  ]),
  category: state.category.category.hits || [],
  pagination: state.category.category || [],
  categoryfilter: state.categoryfilter.categoryfilter || {},
});
// categoryList Component
const CategoryList = (props) => {
  const { isRequesting } = props;

  // Defining UseState Hook
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [category_name, setCategory_name] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // Array Creations
  // categoory Name List
  const categoryNameList = [];

  if (props.categoryfilter.hits) {
    props.categoryfilter.hits.hits.map((value) => {
      return categoryNameList.push(value._source.category_name);
    });
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  let uniqueList = [...new Set(categoryNameList)];

  uniqueList.sort((a, b) => a.localeCompare(b));
  uniqueList = [...new Set(uniqueList)];

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "Project_id",
      width: 200,
      hide: true,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      width: 250,
      flex: 4,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 4,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      align: "center",
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        // Edit icon from Material UI
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  // fetch function

  function fetch(params) {
    props.dispatch(CategoryAction.requestCategory(params));
  }

  function fetchCategoryNameFilter(param) {
    props.dispatch(CategoryAction.requestCategoryFilter(param));
  }

  //   UseEffect function
  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["category_name.keyword", "asc"]]`,
    };

    // if (
    //   props?.categoryfilter === undefined ||
    //   props?.categoryfilter.length === 0
    // ) {
    fetchCategoryNameFilter(param);
    // }

    const params = {
      order_by: `[["order_number","asc"]]`,
      page_number: 0,
      page_limit: `${page_limit}`,
    };

    fetch(params);
  }, []);

  // pagination
  const handleChange = (event, value) => {
    // page_number=1&page_limit=10
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (category_name !== null) {
      filter.push(
        `["category_name.keyword","must","","match","${category_name}"]`
      );
    }

    if (category_name !== null && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        order_by: `[["order_number","asc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        order_by: `[["order_number","asc"]]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetch(params);
    }
  };

  //  datagrid rows
  const rows = [];

  if (props.category.hits) {
    props.category.hits.map((item, index) => {
      return rows.push({
        // sl_no: item._id
        //   ? props.pagination.current_page_no * page_limit + ++index
        //   : "",
        sl_no: item._source.order_number,
        id: item._id,
        category_name: item._source.category_name,
        project_id: item._source.project.project_id,
        project_name: item._source.project.project_name,
        created_by: item._source.created_by.user_name,
      });
    });
  }

  // handle Change function

  const Category_HandleChange = (event, value) => {
    if (value != null) {
      setCategory_name(value);
    } else {
      setCategory_name(null);
    }
  };

  const handleFilter = () => {
    const filter = [];
    if (category_name !== null) {
      filter.push(
        `["category_name.keyword","must","","match","${category_name}"]`
      );
    }

    if (category_name !== null) {
      const params = {
        filters: `[${filter}]`,
        order_by: `[["order_number","asc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetch(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    // Clear the state

    if (category_name !== null) {
      setClearLoader(true);
      setCategory_name(null);
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);

    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetch(params);
  };

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["category_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    data &&
      props
        .dispatch(CategoryAction.requestPutCategoryBulkUpdate(bulkData))
        .then(() => {
          props.dispatch(CategoryAction.requestCategory(paramss));
          props.dispatch(CategoryAction.requestCategoryFilter(param));
        });
  };

  return (
    <React.Fragment>
      {/* Filter */}
      <Box>
        <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.7} mt={1}>
              <AutoCompleteComponent
                value={category_name}
                size="small"
                list={uniqueList}
                width="100%"
                handleChange={Category_HandleChange}
                textFieldProps={{
                  placeholder: "Category Name",
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={4.8} container>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name="Filter"
                      onClick={handleFilter}
                      width="100%"
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      name="Clear"
                      onClick={ClearState}
                      loading={clearLoader && isRequesting}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* category post using Formic validation */}
        <CategoryPost
          // sending project api data as props to Category post Component
          open={open}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          categoryEditData={editRowsModel}
          edit={edit}
        />
        {/* post button component*/}
        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              <Grid item lg={8} md={12} sm={12}>
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {props?.category?.hits && !isRequesting ? (
                    <DataGridFunction
                      columns={columns}
                      rows={rows}
                      rowHeight={60}
                      rowReordering
                      // onRowOrderChange={rowOrderHandleChange}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingBgImage />
                    </Box>
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
            {/* Pagination */}
            {/* {props.category.hits ? (
              props.category.hits.length !== 0 && (
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  container
                  item
                  lg={10}
                  md={12}
                  sm={12}
                  className="pagination_section"
                >
                  <Grid item lg={8} md={12} sm={12}>
                    <PaginationUI
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              )
            ) : (
              <NoDataFound />
            )} */}
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
};

// connect the datagrid function to mapstate function
export default connect(mapStateToProps)(CategoryList);
