import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import rootStore from "./stores/rootStore";
import App from "./views/App";
import environment from "environment";
import { SnackbarProvider } from "notistack";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

// datadogRum.init({
//   applicationId: "54eb02ba-eabe-4c11-b4c3-c163e344e47c",
//   clientToken: "pubfb5d2ef86e04a7544b9d6909a98b75f6",
//   site: "us5.datadoghq.com",
//   service: "rocky-frontend",
//   env: "rocky-frontend-staging",
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 20,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: "mask-user-input",
// });

// datadogRum.startSessionReplayRecording();

// datadogLogs.init({
//   clientToken: "pubfb5d2ef86e04a7544b9d6909a98b75f6",
//   site: "us5.datadoghq.com",
//   forwardErrorsToLogs: true,
//   env: "rocky-frontend-staging",
//   sessionSampleRate: 100,
//   forwardConsoleLogs: "all",
//   forwardReports: "all",
//   service: "frontend",
// });

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    const post_log_data = {
      type: "Crash",
      status: "crash-error",
      url: "",
      error: {
        message: error.message,
        stack: error.stack,
      },
      payload_data: errorInfo.componentStack,
      method: "",
    };

    fetch(`${environment.api.baseApi}/api/logs/frontendLogs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(post_log_data),
    });

    // Log the error and error info to the console
    const componentStack = errorInfo.componentStack;
    // Send error to Datadog
    // datadogRum.addError(error, {
    //   component_stack: errorInfo.componentStack,
    //   error: error,
    // });
  }

  render() {
    return this.props.children;
  }
}

window.REACT_APP_TITLE = process.env.REACT_APP_TITLE;

const initialState = {};
const store = rootStore(initialState);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider maxSnack={30}>
    <Provider store={store}>
        <App />
    </Provider>
  </SnackbarProvider>
);
