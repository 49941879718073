import ActionUtility from "../../../utilities/ActionUtility";
import OperationEffect from "./OperationEffect";

export default class OperationAction {
  //operation filter
  static REQUEST_OPERATIONS_FILTER =
    "OperationAction.REQUEST_OPERATIONS_FILTER";
  static REQUEST_OPERATIONS_FILTER_FINISHED =
    "OperationAction.REQUEST_OPERATIONS_FILTER_FINISHED";

  //METHODS

  //Operations get request for filter
  static requestOperationFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        OperationAction.REQUEST_OPERATIONS_FILTER,
        OperationEffect.requestOperationFilter,
        params
      );
    };
  }
}
