import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import InfluencersLangugeGetModel from "./models/InfluencersLangugeGetModel";

// InfluencerLanguageEffect
export default class InfluencerLanguageEffect {
  // calling an API according to the Influencers model
  // Get method for the
  static async requestInfluencersLanguage(params) {
    const endpoint = environment.api.influencersLanguage;
    return EffectUtility.getToModel(
      InfluencersLangugeGetModel,
      endpoint,
      params
    );
  }
}
