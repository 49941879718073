import ActionUtility from "../../../utilities/ActionUtility";
import FrontEndResourceEffect from "./FrontEndResourceEffect";

export default class FrontEndResourceAction {
  // GET action Types
  static REQUEST_FRONTEND_RESOURCE =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE";
  static REQUEST_FRONTEND_RESOURCE_FINISHED =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_FINISHED";

  //GET filter action types
  static REQUEST_FRONTEND_RESOURCE_FILTER =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_FILTER";
  static REQUEST_FRONTEND_RESOURCE_FILTER_FINISHED =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_FILTER_FINISHED";

  // POST action types
  static REQUEST_FRONTEND_RESOURCE_POST =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_POST";
  static REQUEST_FRONTEND_RESOURCE_POST_FINISHED =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_POST_FINISHED";

  // PUT action types
  static REQUEST_FRONTEND_RESOURCE_PUT =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_PUT";
  static REQUEST_FRONTEND_RESOURCE_PUT_FINISHED =
    "FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_PUT_FINISHED";

  // METHODS

  //GET method
  static requestFrontEndResource(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE,
        FrontEndResourceEffect.requestFrontEndResource,
        params
      );
    };
  }

  //GET method for Filter
  static requestFrontEndResourceFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_FILTER,
        FrontEndResourceEffect.requestFrontEndResourceFilter,
        params
      );
    };
  }

  //POST method
  static requestFrontEndResourcePost(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_POST,
        FrontEndResourceEffect.requestFrontEndResourcePost,
        data
      );
    };
  }

  //PUT method
  static requestFrontEndResourcePut(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_PUT,
        FrontEndResourceEffect.requestFrontEndResourcePut,
        data,
        id
      );
    };
  }
}
