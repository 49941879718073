import ActionUtility from "../../../utilities/ActionUtility";
import DiscordFeedsEffect from "./DiscordFeedsEffect";
export default class DiscordFeedsAction {
  //Instagram feeds requests
  static REQUEST_DISCORD_FEEDS = "DiscordFeedsAction.REQUEST_DISCORD_FEEDS";
  static REQUEST_DISCORD_FEEDS_FINISHED =
    "DiscordFeedsAction.REQUEST_DISCORD_FEEDS_FINISHED";

  // instagram Filter
  static REQUEST_DISCORD_FILTER = "DiscordFeedsAction.REQUEST_DISCORD_FILTER";
  static REQUEST_DISCORD_FILTER_FINISHED =
    "DiscordFeedsAction.REQUEST_DISCORD_FILTER_FINISHED";

  static REQUEST_PUT_DISCORD_FEEDS =
    "DiscordFeedsAction.REQUEST_PUT_DISCORD_FEEDS";
  static REQUEST_PUT_DISCORD_FEEDS_FINISHED =
    "DiscordFeedsAction.REQUEST_PUT_DISCORD_FEEDS_FINISHED";

  static REQUEST_DISCORD_FEEDS_CLEAR =
    "DiscordFeedsAction.REQUEST_DISCORD_FEEDS_CLEAR";
  static REQUEST_DISCORD_FEEDS_CLEAR_FINISHED =
    "DiscordFeedsAction.REQUEST_DISCORD_FEEDS_CLEAR_FINISHED";

  static REQUEST_DISCORD_POST = "DiscordFeedsAction.REQUEST_DISCORD_POST";
  static REQUEST_DISCORD_POST_FINISHED =
    "DiscordFeedsAction.REQUEST_DISCORD_POST_FINISHED";

  //METHODS

  //requestDiscordFeeds get method
  static requestDiscordFeeds(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordFeedsAction.REQUEST_DISCORD_FEEDS,
        DiscordFeedsEffect.requestDiscordFeeds,
        params
      );
    };
  }

  static requestDiscordFeedsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordFeedsAction.REQUEST_DISCORD_FEEDS_CLEAR,
        DiscordFeedsEffect.requestDiscordFeedsClear
      );
    };
  }

  static requestPutDiscordFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordFeedsAction.REQUEST_PUT_DISCORD_FEEDS,
        DiscordFeedsEffect.requestPutDiscordFeeds,
        data,
        id
      );
    };
  }

  static requestDiscordFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordFeedsAction.REQUEST_DISCORD_FILTER,
        DiscordFeedsEffect.requestDiscordFilter,
        params
      );
    };
  }

  static requestDiscordPost(queryParams, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DiscordFeedsAction.REQUEST_DISCORD_POST,
        DiscordFeedsEffect.requestDiscordPost,
        queryParams,
        data
      );
    };
  }
}
