import BaseReducer from "../../../../utilities/BaseReducer";
import FingerPrintReportsAction from "./FingerPrintReportsAction";

export class FingerPrintTotalAssetReducer extends BaseReducer {
  initialState = {
    FPtotalAssetCount: [],
  };
  [FingerPrintReportsAction.REQUEST_TOTAL_ASSET_COUNT_FINISHED](state, action) {
    return {
      ...state,
      FPtotalAssetCount: action.payload,
    };
  }
}

export class FingerPrintTotalCollectionReducer extends BaseReducer {
  initialState = {
    FPtotalCollectionsCount: [],
  };
  [FingerPrintReportsAction.REQUEST_TOTAL_COLLECTION_COUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      FPtotalCollectionsCount: action.payload,
    };
  }
}

export class FingerPrintTotalCopiesReducer extends BaseReducer {
  initialState = {
    FPtotalCopiesCount: [],
  };
  [FingerPrintReportsAction.REQUEST_TOTAL_COPIES_COUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      FPtotalCopiesCount: action.payload,
    };
  }
}

export class FingerPrintAssetwiseCopiesReducer extends BaseReducer {
  initialState = {
    FPassetWiseCopies: [],
  };
  [FingerPrintReportsAction.REQUEST_COPIES_ASSETWISE_COUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      FPassetWiseCopies: action.payload,
    };
  }
}

export class FingerPrintViewsPerAssetReducer extends BaseReducer {
  initialState = {
    FPviewsPerAsset: [],
  };
  [FingerPrintReportsAction.REQUEST_VIEWS_PER_ASSET_COUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      FPviewsPerAsset: action.payload,
    };
  }
}

export class FingerPrintActiveAssetReducer extends BaseReducer {
  initialState = {
    FPactiveAssets: [],
  };
  [FingerPrintReportsAction.REQUEST_ACTIVE_ASSET_COUNT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      FPactiveAssets: action.payload,
    };
  }
}
