import ActionUtility from "../../utilities/ActionUtility";
import AmbitionBoxFeedsEffect from "./AmbitionBoxFeedsEffect";

export default class AmbitionBoxFeedsAction {
  // AmbitionBoxFeedsAction  with an function declaration
  static REQUEST_AMBITIONBOX_FEED =
    "AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED";
  static REQUEST_AMBITIONBOX_FEED_FINISHED =
    "AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED_FINISHED";

  static REQUEST_PUT_AMBITIONBOX_FEED =
    "AmbitionBoxFeedsAction.REQUEST_PUT_AMBITIONBOX_FEED";
  static REQUEST_PUT_AMBITIONBOX_FEED_FINISHED =
    "AmbitionBoxFeedsAction.REQUEST_PUT_AMBITIONBOX_FEED_FINISHED";

  static REQUEST_AMBITION_FEED_FILTER =
    "AmbitionBoxFeedsAction.REQUEST_AMBITION_FEED_FILTER";
  static REQUEST_AMBITION_FEED_FILTER_FINISHED =
    "AmbitionBoxFeedsAction.REQUEST_AMBITION_FEED_FILTER_FINISHED";

  static REQUEST_AMBITIONBOX_FEED_CLEAR =
    "AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED_CLEAR";
  static REQUEST_AMBITIONBOX_FEED_CLEAR_FINISHED =
    "AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED_CLEAR_FINISHED";

  // METHODS
  // AmbitionBox Feeds GET function
  static requestAmbitionBoxFeed(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED,
        AmbitionBoxFeedsEffect.requestAmbitionBoxFeed,
        params
      );
    };
  }

  static requestPutAmbitionBoxFeed(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxFeedsAction.REQUEST_PUT_AMBITIONBOX_FEED,
        AmbitionBoxFeedsEffect.requestPutAmbitionBoxFeed,
        data,
        id
      );
    };
  }

  static requestAmbitionBoxFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED_CLEAR,
        AmbitionBoxFeedsEffect.requestAmbitionBoxFeedClear
      );
    };
  }

  static requestAmbitionBoxFeedFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AmbitionBoxFeedsAction.REQUEST_AMBITION_FEED_FILTER,
        AmbitionBoxFeedsEffect.requestAmbitionBoxFeedFilter,
        params
      );
    };
  }
}
