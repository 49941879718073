import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Description from "./descriptionContainer/Description";
import { format } from "date-fns";
import Logo from "./logoContainer/Logo";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const HourlyTicketsResolved = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  let firstDay = new Date(
    props.filterValue && new Date(props.filterValue).getFullYear(),
    props.filterValue && new Date(props.filterValue).getMonth(),
    1
  );

  let lastDay = new Date(
    props.filterValue && new Date(props.filterValue).getFullYear(),
    props.filterValue && new Date(props.filterValue).getMonth() + 1,
    0
  );

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter).then((e) => {
        setData(e);
      });
      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter).then((e) => {
          setData(e);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter).then((e) => {
          setData(e);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["resolution_time_split_wise"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;
    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });
  }

  function TotalCalculator(val) {
    return val.map((item) => item.count).reduce((prev, next) => prev + next);
  }

  function returnPlatformName(platformID) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === platformID)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  let rows = [];

  if (
    data &&
    data.ticket_report &&
    data.ticket_report.resolution_time_split_wise
  ) {
    data.ticket_report.resolution_time_split_wise.forEach((item, index) => {
      if (
        item &&
        Object.keys(item)[0] &&
        returnPlatformName(Object.keys(item)[0]) &&
        returnPlatformName(Object.keys(item)[0])[0]
      ) {
        const row = {
          id: index + 1,
          platform:
            returnPlatformName(Object.keys(item)[0]) &&
            returnPlatformName(Object.keys(item)[0])[0],
          resolvedIn8Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "Resolved_in_8_Hours")
            .map((item) => item.count)[0],
          resolvedIn8to12Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "Resolved_in_8-12_Hours")
            .map((item) => item.count)[0],
          resolvedIn12to24Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "Resolved_in_12-24_Hours")
            .map((item) => item.count)[0],
          resolvedMoreThan24Hours: item[Object.keys(item)[0]]
            .filter((value) => value.key === "More_Than_24_Hours")
            .map((item) => item.count)[0],
          totalCount: item[Object.keys(item)[0]]
            .filter((value) => value.key === "total_count")
            .map((item) => item.count)[0],
        };

        rows.push(row);
      }
      if (Object.keys(item)[0] === "total") {
        const row = {
          id: index + 1,
          platform: "Total",
          resolvedIn8Hours: Object.values(item)[0][0].Resolved_in_8_Hours,
          resolvedIn8to12Hours:
            Object.values(item)[0][0]["Resolved_in_8-12_Hours"],
          resolvedIn12to24Hours:
            Object.values(item)[0][0]["Resolved_in_12-24_Hours"],
          resolvedMoreThan24Hours: Object.values(item)[0][0].More_Than_24_Hours,
          totalCount: Object.values(item)[0][0].total_count,
        };
        rows.push(row);
      }
    });
  }

  const [renderKey, setRenderKey] = useState(0);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {params.row.platforms}
        </p>
      ),
    },
    {
      field: "Resolved_in_8_Hours",
      headerName: "In 8 Hrs",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row.Resolved_in_8_Hours)}
        </p>
      ),
    },
    {
      field: "Resolved_in_8-12_Hours",
      headerName: "8-12 Hrs",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row["Resolved_in_8-12_Hours"])}
        </p>
      ),
    },
    {
      field: "Resolved_in_12-24_Hours",
      headerName: "12-24 Hrs",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row["Resolved_in_12-24_Hours"])}
        </p>
      ),
    },
    {
      field: "More_Than_24_Hours",
      headerName: "After 24 Hrs",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <p
          className={`${
            params.row.platforms == "Total"
              ? "font-weight-bold p-0 m-0"
              : "font-weight-normal p-0 m-0"
          }`}
        >
          {prefixZero_for_singleDigit(params.row["More_Than_24_Hours"])}
        </p>
      ),
    },

    {
      field: "Total",
      headerName: "Total",
      resizable: true,
      flex: 2,
      // minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <p className={"font-weight-bold p-0 m-0"}>
          {prefixZero_for_singleDigit(params.row["Total"])}
        </p>
      ),
    },
  ];

  let Drows = [];

  if (rows && rows.length !== 0) {
    rows.map((row, i) => {
      Drows.push({
        id: i + 1,
        platforms: row.platform,
        Resolved_in_8_Hours: row.resolvedIn8Hours,
        "Resolved_in_8-12_Hours": row.resolvedIn8to12Hours,
        "Resolved_in_12-24_Hours": row.resolvedIn12to24Hours,
        More_Than_24_Hours: row.resolvedMoreThan24Hours,
        Total: row.totalCount,
      });
    });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData?.push({
          Platforms: item?.platform,
          ["In 8 Hrs"]: prefixZero_for_singleDigit(item?.resolvedIn8Hours),
          ["8-12 Hrs"]: prefixZero_for_singleDigit(item.resolvedIn8to12Hours),
          ["12-24 Hrs"]: prefixZero_for_singleDigit(item.resolvedIn12to24Hours),
          ["After 24 Hrs"]: prefixZero_for_singleDigit(
            item.resolvedMoreThan24Hours
          ),
          ["Total"]: prefixZero_for_singleDigit(item.totalCount),
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, rows]);

  return (
    <LoadingIndicator isActive={loading}>
      {!loading && props.dataGridHeight ? (
        <div
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: theme.table.borderRadius,
          }}
        >
          {props.dataGridHeight.current && rows && (
            <DataGridFunction
              rows={Drows}
              columns={columns}
              rowBorder={false}
              isRequesting={loading}
              height={props.dataGridHeight.current}
              rowHeight={50}
              headerHeight={80}
              onCellClick={onCellClick}
              rowColor={true}
              headerColor={true}
            />
          )}
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage />
        </Box>
      )}
    </LoadingIndicator>
  );
};

export default HourlyTicketsResolved;
