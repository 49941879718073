import {
  Box,
  Checkbox,
  createFilterOptions,
  Grid,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import SwitchComponent from "../../../components/switchComponent/SwitchComponent";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ManageRoleAction from "../../components/manageRole/ManageRoleAction";
import ManageUserPermissionPost from "../../components/manageUserPermissionPost/ManageUserPermissionPost";
import OperationAction from "../../stores/operations/OperationAction";
import OrmResourceAction from "../../stores/ormResources/OrmResourceAction";
import RolePermissionAction from "../../stores/rolePermissions/RolePermissionsAction";
import BuildIcon from "@mui/icons-material/Build";
import PersonIcon from "@mui/icons-material/Person";

// mapState to props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    RolePermissionAction.REQUEST_ROLEPERMISSIONS,
    ManageRoleAction.REQUEST_ROLE_FILTER,
    OperationAction.REQUEST_OPERATIONS_FILTER,
    ManageRoleAction.REQUEST_ROLE_TEMPLATE_FILTER,
    OrmResourceAction.REQUEST_ORM_RESOURCE,
  ]),
  rolePermission: state.rolePermission.rolePermission || [],
  roleFilter: state.roleFilter.roleFilter,
  operations: state.operations.operations || {},
  pagination: state.rolePermission.rolePermission || [],
  roleTemplate: state.roleTemplate.roleTemplate || {},
  OrmResource: state.OrmResource.OrmResource || [],
});

function Permissions(props) {
  const { isRequesting } = props;

  const [open, setOpen] = useState(false);
  const [filterRole, setFilterRole] = useState(null);
  const [filterResource, setFilterResource] = useState(null);
  let [filterResourceDomain, setfilterResourceDomain] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchRolePermission(params) {
    props.dispatch(RolePermissionAction.requestRolePermission(params));
  }

  const data = [
    {
      _id: "ZlBLm4kBXj2mrJkl4ZGd",
      _index: "role_permission",
      _score: null,
      _source: {
        created_by: {
          user_id: "admin",
          user_name: "admin",
        },
        created_on: "2023-07-28T12:29:47.782727",
        modified_on: "2023-07-28T12:29:47.782734",
        resource_name: "/UserPermissions",
        resource_desc:
          "User Settings - Project - Permission - User  Mapping To Project",
        operation: [
          {
            super_admin: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: true,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            org_admin: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: true,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            admin: [
              {
                enabled: true,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: true,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: true,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: true,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            manager: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: false,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            team_leader: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: false,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            agent: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: false,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
        ],
      },
    },
    {
      _id: "ZlBLm4kBXj2mrJkl4ZGd",
      _index: "role_permission",
      _score: null,
      _source: {
        created_by: {
          user_id: "admin",
          user_name: "admin",
        },
        created_on: "2023-07-28T12:29:47.782727",
        modified_on: "2023-07-28T12:29:47.782734",
        resource_name: "/UserPermissions_2nd",
        resource_desc: "2nd",
        operation: [
          {
            super_admin: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: true,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            org_admin: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: true,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            admin: [
              {
                enabled: true,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: true,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: true,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: true,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            manager: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: false,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            team_leader: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: false,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
          {
            agent: [
              {
                enabled: false,
                operation_id: 1,
                operation_name: "write",
              },
              {
                enabled: false,
                operation_id: 2,
                operation_name: "read",
              },
              {
                enabled: false,
                operation_id: 3,
                operation_name: "update",
              },
              {
                enabled: false,
                operation_id: 4,
                operation_name: "delete",
              },
            ],
          },
        ],
      },
    },
  ];

  function fetchRole() {
    const filter = [];

    filter.push(`[["is_template","must","","term","false"]]`);

    let param = {
      page_limit: "none",
      filters: `${filter}`,
    };

    props.dispatch(ManageRoleAction.requestRoleFilter(param));
  }

  function fetchOperations(params) {
    props.dispatch(OperationAction.requestOperationFilter(params));
  }

  function fetchRoleTemplateTrue() {
    const filter = [];
    let params = {};

    filter.push(`[["is_template","must","","term","true"]]`);

    params = {
      filters: `${filter}`,
      order_by: '[["modified_on", "desc"]]',
      page_limit: `none`,
    };
    props.dispatch(ManageRoleAction.requestRoleTemplateTrueFilter(params));
  }

  function fetchOrmResource() {
    props.dispatch(OrmResourceAction.requestOrmResource());
  }

  const [page_limit] = useState(15);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    let param = { page_limit: "none" };

    // if (props?.operations === undefined || props?.operations.length === 0) {
    fetchOperations();
    // }

    // if (props?.roleFilter === undefined || props?.roleFilter.length === 0) {
    // fetchRole();
    // }

    // if (props?.roleTemplate === undefined || props?.roleTemplate.length === 0) {
    fetchRoleTemplateTrue();
    // }

    // if (props?.OrmResource === undefined || props?.OrmResource.length === 0) {
    fetchOrmResource();
    // }

    const filter = [];
    let params;
    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }
    if (filterRole !== null) {
      filter.push(`["role_id.keyword","must","","terms",["${filterRole.id}"]]`);
    }
    if (filterResource !== null || filterRole !== null) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    } else {
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    }
    fetchRolePermission(params);
  }, []);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(true);
  }, []);

  // Resources drop down list initialize

  const [resourceKey, setResourceKey] = useState(null);

  let resourceKeys = [];

  let resourceList = [];

  if (props.OrmResource.resources) {
    props.OrmResource.resources
      .filter((item) => Object.keys(item)[0] === resourceKey)
      .map((item) => {
        Object.values(item)[0].map((val) => {
          resourceList.push({ name: val.resource_name, id: val.resource_id });
        });
      });
  }

  // Role drop down list initialize
  let roleList = [];

  if (props.roleFilter.hits) {
    props.roleFilter.hits.hits.forEach((val) => {
      roleList.push({ id: val._id, name: val._source.role_name });
    });
  }

  if (props.roleTemplate.hits) {
    props.roleTemplate.hits.hits
      .filter((val) => val._source.role_name === "super_admin")
      .map((item) => {
        return roleList.push({ id: item._id, name: item._source.role_name });
      });
  }

  // Permission drop down list initialize
  let operationsList = [];
  if (props.operations.hits) {
    props.operations.hits.hits.forEach((val) => {
      operationsList.push({
        operation_id: val._id,
        operation_name: val._source.operation_name,
        enabled: false,
      });
    });
  }

  // Datagrid rows list Assigning

  // if (props.rolePermission.hits && props.rolePermission.hits.length !== 0) {
  //   props.rolePermission.hits.hits.map((item, index) => {
  //     return rows.push({
  //       id: item._id
  //         ? props.pagination.current_page_no * page_limit + ++index
  //         : "",
  //       role_id: item._source.role_id,
  //       role_name: item._source.role_id.role_name,
  //       resource_id: item._source.resource_id,
  //       resource_name: item._source.resource_id.resource_name,
  //       operation: item,
  //     });
  //   });
  // }

  const generateRows = () => {
    const rows = data.map((item, index) => {
      const row = {
        id: index + 1,
        Pid: item._id,
        resources: item._source.resource_name,
        resources_desc: item._source.resource_desc,
      };

      item._source.operation.forEach((role) => {
        const roleName = Object.keys(role)[0];
        role[roleName].forEach((op) => {
          row[`${roleName}_${op.operation_name}`] = op.enabled;
        });
      });

      return row;
    });

    return rows;
  };

  const generateColumnGroupingModel = (data) => {
    const columnGroupingModel = [
      {
        groupId: "internal_data",
        headerName: "Default permissions",
        description: "",
        children: [
          { field: "id" },
          { field: "resources" },
          { field: "resources_desc" },
        ],
      },
    ];

    // Assuming operations are consistent across all items
    const operations = data[0]?._source.operation;

    if (operations) {
      operations.forEach((role, index) => {
        const roleName = Object.keys(role)[0];
        const children = role[roleName].map((op) => ({
          field: `${roleName}_${op.operation_name}`,
        }));

        columnGroupingModel.push({
          groupId: roleName,
          headerName: roleName
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          headerAlign: "center",
          freeReordering: true,
          children: children,
        });
      });
    }

    return columnGroupingModel;
  };
  const checkHandleChange = (
    rowId,
    operationId,
    operationName,
    newState,
    roleName,
    params
  ) => {
    console.log(
      "loggitt",
      rowId,
      operationId,
      operationName,
      newState,
      roleName,
      params
    );

    // Find the item with _id equal to rowId
    const item = data.find((item) => item._id === rowId);

    if (item) {
      // Update the specific operation state in params.row
      params.row[`${roleName}_${operationName}`] = newState;

      // Construct the operation array from params.row
      const roles = [
        "super_admin",
        "org_admin",
        "admin",
        "manager",
        "team_leader",
        "agent",
      ];
      const operationsList = ["write", "read", "update", "delete"];

      const operationArray = roles.map((role) => {
        const roleOperations = operationsList.map((operation, index) => ({
          enabled: params.row[`${role}_${operation}`],
          operation_id: index + 1,
          operation_name: operation,
        }));
        return { [role]: roleOperations };
      });

      // Log the constructed operation array
      console.log("Constructed operation array:", operationArray);

      // Update the item's operation array
      item._source.operation = operationArray;

      // Log the updated item
      console.log("Updated item:", item);
    }
  };

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(generateRows());
  }, []);
  const columnGroupingModel = generateColumnGroupingModel(data);

  props.OrmResource &&
    props?.OrmResource?.resources?.map((item) => {
      resourceKeys.push(Object.keys(item)[0]);
    });

  resourceKeys.sort((a, b) => a.localeCompare(b));
  resourceKeys = [...new Set(resourceKeys)];

  // resource value handling function
  const resourceHandleChange = (e, resource) => {
    setFilterResource(resource);
  };

  // resource value handling function
  const resourceDomainHandleChange = (e, resource) => {
    setfilterResourceDomain(resource);
    setResourceKey(resource);
  };

  // role value handling function
  const roleHandleChange = (e, role) => {
    setFilterRole(role);
  };

  // FILTER FUNCTION
  const ApplyFilter = () => {
    const filter = [];

    let params;

    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }
    if (filterRole !== null) {
      filter.push(
        `["role_id.keyword","must","","terms",${JSON.stringify(
          filterRole &&
            filterRole.options.length !== 0 &&
            filterRole.options.map((item) => {
              return item.id;
            })
        )}]`
      );
    }

    if (
      (filterResource !== null && filterResourceDomain !== null) ||
      (filterRole !== null && filterResourceDomain === null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
      fetchRolePermission(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);

      // params = {
      //   page_number: 0,
      //   page_limit: `${page_limit}`,
      //   order_by: `[["created_on","asc"]]`,
      // };
      // fetchRolePermission(params);
    }
  };

  const handleChange = (event, value) => {
    const filter = [];

    let params;

    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }

    if (filterRole !== null) {
      filter.push(
        `["role_id.keyword","must","","terms",["${
          filterRole &&
          filterRole.options.length !== 0 &&
          filterRole.options.map((item) => {
            return item.id;
          })
        }"]]`
      );
    }

    if (FilterClicked) {
      if (filterResource !== null || filterRole !== null) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: `[["created_on","asc"]]`,
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchRolePermission(params);
    }
  };

  //CLEAR FUNCTION
  const ClearFunction = () => {
    if (
      filterResource !== null ||
      filterRole !== null ||
      filterResourceDomain !== null
    ) {
      setFilterResource(null);
      setfilterResourceDomain(null);
      setResourceKey(null);
      setFilterRole(null);
      setClearLoader(true);
      setFilterClicked(false);
      !FilterClicked && setClearLoader(false);
    }

    let params = {
      page_limit: `${page_limit}`,
      order_by: `[["created_on","asc"]]`,
    };
    FilterClicked && fetchRolePermission(params);
  };

  //Handle Callback function for refreshing data
  const handleData = useCallback(async () => {
    const filter = [];

    let params;

    if (filterResource !== null) {
      filter.push(
        `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
      );
    }

    if (filterRole !== null) {
      filter.push(`["role_id.keyword","must","","terms",["${filterRole.id}"]]`);
    }

    if (filterResource !== null || filterRole !== null) {
      params = {
        filters: `[${filter}]`,
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    } else {
      params = {
        page_limit: `${page_limit}`,
        order_by: `[["created_on","asc"]]`,
      };
    }

    fetchRolePermission(params);
  }, [filterResource, filterRole]);

  const HeaderWithIconRoot = styled("div")(({ theme }) => ({
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginRight: theme.spacing(0.5),
    },
  }));

  function HeaderWithIcon(props) {
    const { icon, ...params } = props;

    return (
      <HeaderWithIconRoot>
        <span>{params.headerName ?? params.groupId}</span> {icon}
      </HeaderWithIconRoot>
    );
  }

  const [checkedLists, setCheckedLists] = React.useState([]);

  const columns = [
    // {
    //   field: "checker",
    //   renderHeader: () => {
    //     return (
    //       <Checkbox
    //         checked={rows
    //           .map((item) => item.id)
    //           .every((id) => checkedLists.some((check) => check.id === id))}
    //         onChange={(event) => {
    //           const { checked } = event.target;
    //           const allIds = rows.map((item) => item.id);

    //           if (checked)
    //             setCheckedLists((prevCheckedLists) => {
    //               const newCheckedLists = new Set(prevCheckedLists);
    //               rows.forEach((row) => {
    //                 // if (!newCheckedLists.has(row.id))
    //                 if (
    //                   ![...newCheckedLists].some((item) => item.id === row.id)
    //                 )
    //                   newCheckedLists.add({ id: row.id, status: row.status });
    //               });
    //               return Array.from(newCheckedLists);
    //             });
    //           else
    //             setCheckedLists((prev) =>
    //               prev.filter((id) => !allIds.includes(id.id))
    //             );
    //         }}
    //       ></Checkbox>
    //     );
    //   },
    //   width: 100,
    //   resizable: false,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex: 0.5,
    //   renderCell: (params) => {
    //     return (
    //       <Checkbox
    //         checked={checkedLists.some((item) => item.id === params.row.id)}
    //         // onChange={(event) => {
    //         //   const { checked } = event.target;
    //         //   if (checked)
    //         //     setCheckedLists((prev) => [
    //         //       ...prev,
    //         //       statuses.find((item) => item.id === params.row.id),
    //         //     ]);
    //         //   else
    //         //     setCheckedLists((prev) =>
    //         //       prev.filter((id) => id.id !== params.row.id)
    //         //     );
    //         // }}
    //       ></Checkbox>
    //     );
    //   },
    // },

    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      // flex: 2,
      // headerAlign: "center",
      align: "left",
    },

    {
      field: "Pid",
      headerName: "SL NO",
      width: 150,
      align: "left",
    },

    {
      field: "resources",
      headerName: "Resources",
      width: 150,
      // flex: 2,
    },
    {
      field: "resources_desc",
      headerName: "Resources_desc",
      width: 300,
      // flex: 2,
    },
  ];

  const operations = data[0]?._source.operation;

  if (operations) {
    operations.forEach((role) => {
      const roleName = Object.keys(role)[0];
      role[roleName].forEach((op) => {
        columns.push({
          field: `${roleName}_${op.operation_name}`,
          headerName: `${op.operation_name}`,
          width: 100,
          align: "left",
          renderCell: (params) => {
            const isChecked = params.value;

            return (
              <Box>
                <SwitchComponent
                  id={`${params.row.id}_${roleName}_${op.operation_name}`}
                  labelPlacement="left"
                  checked={isChecked}
                  onClick={() => {
                    checkHandleChange(
                      params.row.Pid,
                      op.operation_id,
                      op.operation_name,
                      !isChecked,
                      roleName,
                      params
                    );
                  }}
                />
              </Box>
            );
          },
        });
      });
    });
  }

  const handleCHange = (id, op_id, op_name, enabledValue, op_list) => {
    let an_object = op_list.filter((val) => {
      return val.operation_id === op_id;
    });

    let new_obj = {
      operation_id: op_id,
      operation_name: op_name,
      enabled: enabledValue === true ? false : true,
    };
    let index_of_clicked_switch = op_list.indexOf(
      an_object[0] ? an_object[0] : {}
    );

    op_list = [...op_list];
    op_list.splice(index_of_clicked_switch, 1, new_obj);

    let values = {
      operation: [...op_list],
    };

    props
      .dispatch(
        RolePermissionAction.requestRolePermissionPut(
          JSON.stringify(values),
          id
        )
      )
      .then(() => {
        const filter = [];
        let params;
        if (filterResource !== null) {
          filter.push(
            `["resource_id.keyword","must","","terms",["${filterResource.id}"]]`
          );
        }
        if (filterRole !== null) {
          filter.push(
            `["role_id.keyword","must","","terms",${JSON.stringify(
              filterRole &&
                filterRole.options.length !== 0 &&
                filterRole.options.map((item) => {
                  return item.id;
                })
            )}]`
          );
        }
        if (filterResource !== null || filterRole !== null) {
          params = {
            filters: `[${filter}]`,
            page_number: props.pagination.current_page_no,
            page_limit: `${page_limit}`,
            order_by: `[["created_on","asc"]]`,
          };
        } else {
          params = {
            page_number: props.pagination.current_page_no,
            page_limit: `${page_limit}`,
            order_by: `[["created_on","asc"]]`,
          };
        }
        fetchRolePermission(params);
      });
  };

  const groupedRoles = roleList.reduce((groups, role) => {
    const name = role.name;
    const existingGroup = groups.find((group) => group.name === name);
    if (existingGroup) {
      existingGroup.options.push(role);
    } else {
      groups.push({ name, options: [role] });
    }
    return groups;
  }, []);

  return (
    <React.Fragment>
      <Box>
        {/* <h5>Manage User-Role Permissions</h5> */}
        {/* <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            display="flex"
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={8} md={8} sm={8} sx={{ gap: 2, display: "flex" }}>
              <Grid item lg={6} md={6} sm={6} mt={1}>
                <AutoCompleteComponent
                  value={filterRole}
                  getOptionLabel={(group) => group.name}
                  groupBy={(group) => group.name}
                  size="small"
                  list={groupedRoles}
                  width="100%"
                  handleChange={roleHandleChange}
                  textFieldProps={{
                    placeholder: "Select Role",
                  }}
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option.name,
                  })}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} mt={1}>
                <AutoCompleteComponent
                  value={filterResourceDomain}
                  getOptionLabel={(option) => option}
                  size="small"
                  width="100%"
                  list={resourceKeys}
                  handleChange={resourceDomainHandleChange}
                  textFieldProps={{
                    placeholder: "Select Resource",
                  }}
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option,
                  })}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} mt={1}>
                <AutoCompleteComponent
                  value={filterResource}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  width="100%"
                  list={resourceList}
                  handleChange={resourceHandleChange}
                  textFieldProps={{
                    placeholder: "Select Resource Type",
                  }}
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option.name,
                  })}
                />
              </Grid>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              container
              display="flex"
              justifyContent="end"
            >
              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                container
                mt={1}
                sx={{ gap: "10px" }}
              >
                <Grid lg={5} md={5} sm={5}>
                  <MuiButton
                    loading={filterLoader && isRequesting}
                    name="Filter"
                    width="100%"
                    onClick={ApplyFilter}
                  />
                </Grid>
                <Grid lg={5} md={5} sm={5}>
                  <MuiButton
                    loading={clearLoader && isRequesting}
                    name="Clear"
                    width="100%"
                    onClick={ClearFunction}
                  />
                </Grid>
              </Grid>
              <Grid>
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  // editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        {/* PERMISSION ASSIGNING FORM - POST MODEL SCREEN */}
        <ManageUserPermissionPost
          open={open}
          roleList={roleList}
          resourceList={resourceList}
          OrmResource={props.OrmResource && props.OrmResource.resources}
          operationsList={operationsList}
          onClose={() => {
            setOpen(false);
          }}
          callback={() => handleData()}
        />
        <Grid>
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
              // className="bg-warning"
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // className="bg-success"
                width={"100vh"}
                height={"80vh"}
              >
                <LoadingIndicator isActive={isRequesting}>
                  {!isRequesting && props.rolePermission.hits ? (
                    <DataGridFunction
                      rows={rows}
                      columns={columns}
                      rowHeight={50}
                      // getRowHeight={() => "auto"}
                      // pagelength={props.pagination.total_page_no}
                      // page={props.pagination.current_page_no}
                      // handleChange={handleChange}
                      columnGroupingModel={columnGroupingModel}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            role_id: false,
                            Pid: false,
                            resource_id: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingBgImage />
                    </Box>
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(Permissions);
