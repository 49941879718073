import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Font from "@ckeditor/ckeditor5-font/src/font";

const CKeditor = (props) => {
  const callBack = props.callBack;

  const [description] = useState(props.description);

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={`${description[props.index]}`}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          let array = [...description];

          array[props.index] = data;

          callBack(props.report, array);
        }}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
      />
    </div>
  );
};

export default CKeditor;
