import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, createFilterOptions, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import ClientAction from "../../stores/client/ClientAction";
import OrganizationAction from "../../stores/organization/OrganizationAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import ClientPost from "../../components/clientPost/ClientPost";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ClientAction.REQUEST_CLIENT,
    // OrganizationAction.REQUEST_ORGANIZATION,
    OrganizationAction.REQUEST_ORGANIZATION_FILTER,
    ClientAction.REQUEST_CLIENT_FILTER,
  ]),
  client: state.client.client.hits || [],
  clientFilter: state.clientFilter.clientFilter || {},
  pagination: state.client.client || {},
  organizationFilter: state.organizationFilter.organizationFilter || {},
  // organization: state.organization.organization.hits || [],
});

export function ClientName(value) {
  return `["client_name.keyword", "must", "", "match", "${value}"]`;
}

export const Created_on = '[["created_on", "desc"]]';

function Client(props) {
  const { isRequesting } = props;
  const [client, setClient] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [organization, setOrganization] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  function fetch(param) {
    props.dispatch(ClientAction.requestClient(param));
  }

  function fetchClientFilter() {
    const param = {
      order_by: `[["client_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(ClientAction.requestClientFilter(param));
  }

  function fetchOrganizationFilter() {
    const param = {
      order_by: `[["organization_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(OrganizationAction.requestOrganizationFilter(param));
  }

  // useeffect get method
  useEffect(() => {
    const param = {
      order_by: Created_on,
      page_limit: `${page_limit}`,
    };
    fetch(param);

    // if (props?.clientFilter === undefined || props?.clientFilter.length === 0) {
    fetchClientFilter();
    // }

    // if (
    //   props?.organizationFilter === undefined ||
    //   props?.organizationFilter.length === 0
    // ) {
    fetchOrganizationFilter();
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterArray = [];

  // pushing client name into filter array
  if (client !== null) {
    filterArray.push(ClientName(client));
  }

  // pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};

    if (client !== null && FilterClicked) {
      filter_params = {
        order_by: Created_on,
        filters: `[${filterArray}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        order_by: Created_on,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetch(filter_params);
    }
  };

  let organization_array = [];
  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.map((item) => {
      return organization_array.push({
        organization_id: item._id,
        organization_name: item._source.organization_name,
      });
    });
  }

  // let OrganizationNameList = [];
  // if (props.organization.hits) {
  //   props.organization.hits.map((item) => {
  //     return OrganizationNameList.push(item._source.organization_name);
  //   });
  // }

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "project_unique_id",
      headerName: "Project ID",
      width: 200,
      hide: true,
    },

    {
      field: "client_name",
      headerName: "Client Name",
      width: 250,
      flex: 4,
    },

    {
      field: "mapped_organization_name",
      headerName: "Organization",
      width: 250,
      flex: 4,
      resizable: false,
    },
    {
      field: "mapped_organization",
      headerName: "organization_array",
      width: 250,
      flex: 4,
      resizable: false,
      hide: true,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 4,
      resizable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      align: "center",

      resizable: false,
      width: 160,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEditRowsModel(params.row);
            setEdit(true);
            setOpen(true);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  // datagrid rows
  const rows = [];
  if (props.client.hits) {
    props.client.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        project_unique_id: item._id,
        mapped_organization_name: item._source.organization
          ? item._source.organization.organization_name
          : "",
        mapped_organization: item._source.organization,
        client_name: item._source.client_name,
        created_by: item._source.created_by.user_name,
      });
    });
  }

  const onClintHandleChange = (event, Client) => {
    if (Client != null) {
      setClient(Client);
    } else {
      setClient(null);
    }
  };

  // post model dialog
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  // edit model dialog
  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // Filter function for Client
  const applyFilter = () => {
    if (client !== null) {
      const params = {
        filters: `[${filterArray}]`,
      };
      fetch(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  // clear
  const ClearState = () => {
    if (client !== null) {
      setClearLoader(true);
      setClient(null);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    const params = {
      order_by: Created_on,
      page_limit: 15,
    };
    FilterClicked && fetch(params);
  };

  let ClientList = [];
  if (props.clientFilter.hits) {
    props.clientFilter.hits.hits.map((item) => {
      return ClientList.push(item._source.client_name);
    });
  }

  ClientList = [...new Set(ClientList)];

  ClientList.sort((a, b) => a.localeCompare(b));
  ClientList = [...new Set(ClientList)];

  return (
    <React.Fragment>
      {/* Filter */}

      <ClientPost
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        projectEditData={editRowsModel}
        edit={edit}
        organization_array={organization_array}
      />

      <Grid container>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          spacing={1}
          justifyContent="center"
        >
          <Grid item lg={2} md={5} sm={5} mt={1}>
            <AutoCompleteComponent
              value={client}
              textFieldProps={{
                placeholder: "Client",
              }}
              filterOptions={createFilterOptions({
                matchFrom: "start",
                stringify: (option) => option,
              })}
              list={ClientList}
              handleChange={onClintHandleChange}
              size="small"
              width="100%"
            />
          </Grid>

          {/* <Grid item lg={2} md={5} sm={5} mt={1}>
            <AutoCompleteComponent
              value={organization}
              textFieldProps={{
                placeholder: "Organization",
              }}
              list={OrganizationNameList}
              handleChange={organizationName_handlechange}
              size="small"
              width="100%"
            />
          </Grid> */}
          <Grid item lg={8} md={7} sm={7} container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={1}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                lg={8}
                sm={8}
                md={8}
                display={"flex"}
                justifyContent={"end"}
                mt={1}
                mr={2}
                sx={{ gap: 2 }}
              >
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    width="100%"
                    name={"Filter"}
                    onClick={applyFilter}
                    loading={filterLoader && isRequesting}
                  />
                </Grid>
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    width="100%"
                    name={"Clear"}
                    onClick={ClearState}
                    loading={clearLoader && isRequesting}
                  />
                </Grid>
              </Grid>
              <Grid>
                {/* post button component*/}
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* project post modal screen */}

      {/* ***************************************************************  user filter */}

      {/* data grid */}
      <Grid
        display="flex"
        justifyContent="center"
        container
        item
        lg={12}
        md={12}
        sm={12}
        mt={2}
      >
        {" "}
        <Grid item lg={10} md={12} sm={12} xs={12}>
          {/* loading icon */}
          <LoadingIndicator isActive={isRequesting}>
            {!isRequesting ? (
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={60}
                pagelength={props.pagination.total_page_no}
                page={props.pagination.current_page_no}
                handleChange={handleChange}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      project_unique_id: false,
                      mapped_organization: false,
                    },
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "80vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Grid>
      </Grid>

      {/* Pagination */}
      {/* <Grid
        display="flex"
        justifyContent="center"
        container
        item
        lg={10}
        md={12}
        sm={12}
        className="pagination_section"
      >
        {" "}
        <Grid item lg={8} md={12} sm={12} xs={12}>
          {props.client.hits
            ? props.client.hits.length !== 0 && (
                <PaginationUI
                  pagelength={props.pagination.total_page_no}
                  page={props.pagination.current_page_no}
                  handleChange={handleChange}
                />
              )
            : null}
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(Client);
