import { Box, Grid, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import ProjectAction from "../../stores/project/projectAction";
import ReportTemplateAction from "../../../stores/reportTemplate/ReportTemplateAction";
import StatusAction from "../../stores/status/StatusAction";
import { format, isValid } from "date-fns";
import { theme } from "../../../views/App";
import PlatformAction from "../../stores/platform/PlatformAction";
import UserAction from "../../stores/user/UserAction";
import ErrorBoundary from "../../../components/ErrorBoundary";
import TicketSummary from "../../components/reportPdfTemplates/TicketSummary";
import TotalMentions from "../../components/reportPdfTemplates/TotalMentions";
import TotalMentionsChart from "../../components/reportPdfTemplates/chartReports/TotalMentionsChart";
import OverallSentimentMentions3months from "../../components/reportPdfTemplates/OverallSentimentMentions3months";
import OverallSentimentMentionChart3months from "../../components/reportPdfTemplates/chartReports/OverallSentimentMentionChart3months";
import NegativeMentionsDispositionWise from "../../components/reportPdfTemplates/NegativeMentionsDispositionWise";
import NegativeMentionsDispositionWiseChart from "../../components/reportPdfTemplates/chartReports/NegativeMentionDispositionwiseChart";
import NegativeMentions from "../../components/reportPdfTemplates/NegativeMentions";
import NegativeMentionsChart from "../../components/reportPdfTemplates/chartReports/NegativeMentionsChart";
import TotalNegativeMentions from "../../components/reportPdfTemplates/TotalNegativeMentions";
import TotalNegativeMentionsChart from "../../components/reportPdfTemplates/chartReports/TotalNeagativeMentionsChart";
import EscalationsReports from "../../components/reportPdfTemplates/EscalationsReports";
import MonthlyWisePlatformReviewCounts from "../../components/reportPdfTemplates/MonthlyWisePlatformReviewCounts";
import ResponseOverview from "../../components/reportPdfTemplates/ResponseOverview";
import FRTEfficiencyAgentWise from "../../components/reportPdfTemplates/FRTEfficiencyAgentWise";
import PlatformWiseReport from "../../components/reportPdfTemplates/PlatformWiseReport";
import AvgFRTPlatformWise from "../../components/reportPdfTemplates/AvgFRTPlatformWise";
import PlatformWiseSentimentOverview from "../../components/reportPdfTemplates/PlatformWiseSentimentOverview";
import CategoryWiseAvgFlr from "../../components/reportPdfTemplates/CategoryWiseAvgFlr";
import CategoryWiseTatDistribution from "../../components/reportPdfTemplates/CategoryWiseTatDistribution";
import CategoryWiseDistribution from "../../components/reportPdfTemplates/CategoryWiseDistribution";
import CategoryWiseDistributionChart from "../../components/reportPdfTemplates/chartReports/CategoryWiseDistributionChart";
import CategoryWiseSentiments from "../../components/reportPdfTemplates/CategoryWiseSentiments";
import OverallShareOfVoice from "../../components/reportPdfTemplates/OverallShareOfVoice";
import ShareOfVoiceChart from "../../components/reportPdfTemplates/chartReports/ShareOfVoiceChart";
import PlatformWiseOverallSentiment from "../../components/reportPdfTemplates/PlatformWiseOverallSentiment";
import PlatformWiseOverallSentimentChart from "../../components/reportPdfTemplates/chartReports/PlatformWiseOverallSentimentChart";
import ChannelWiseSentimentDisrtibution from "../../components/reportPdfTemplates/ChannelWiseSentimentDisrtibution";
import TicketClouserTatBreach from "../../components/reportPdfTemplates/TicketClouserTatBreach";
import TicketClouserTatBreachChart from "../../components/reportPdfTemplates/chartReports/TicketClosureTatBreachChart";
import SentimentOvertime from "../../components/reportPdfTemplates/SentimentOvertime";
import OverallAverageFRT from "../../components/reportPdfTemplates/OverallAverageFRT";
import OverallAverageTAT from "../../components/reportPdfTemplates/OverallAverageTAT";
import BridgeUsersAvgFRT from "../../components/reportPdfTemplates/BridgeUsersAvgFRT";
import BridgeUsersAvgTAT from "../../components/reportPdfTemplates/BridgeUsersAvgTAT";
import HourlyTicketsResolved from "../../components/reportPdfTemplates/HourlyTicketsResolved";
import MentionsTrendWithEngagement from "../../components/reportPdfTemplates/MentionsTrendWithEngagement";
import InstagramMessageSummary from "../../components/reportPdfTemplates/InstagramMessageSummary";
import InstagramUserActivity from "../../components/reportPdfTemplates/InstagramUserActivity";
import WordCloudReport from "../../components/reportPdfTemplates/WordCloudReport";
import OverallTicketStatusUserwise from "../../components/reportPdfTemplates/OverallTicketStatusUserwise";
import TatOverview from "../../components/reportPdfTemplates/TatOverview";
import OverallSentimentMentions from "../../components/reportPdfTemplates/OverallSentimentMentions";
import SentimentAnalysis from "../../components/reportPdfTemplates/SentimentAnalysis";
import RoleSummary from "../../components/reportPdfTemplates/RoleSummary";
import TicketCategoryReport from "../../components/reportPdfTemplates/TicketCategoryReport";
import TicketCategoryDetailedReport from "../../components/reportPdfTemplates/TicketCategoryDetailedReport";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DatePicker from "../../../components/dateRangePickerComponent/DateRangePicker";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import ChannelWiseSentimentDistribution from "../../components/reportPdfTemplates/ChannelWiseSentimentDisrtibution";
import SentimentAnalysisLast12monthsChart from "../../components/reportPdfTemplates/chartReports/SentimentAnalysisLast12monthsChart";
import { getItemsById } from "../../../components/ticketPartials";
import TicketClosureFrtBreachChart from "../../components/reportPdfTemplates/chartReports/TicketClosureFrtBreachChart";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TicketSummaryNew from "../../components/reportPdfTemplates/TicketSummaryNew";
import PlatformwiseTicketsByCategory from "../../components/reportPdfTemplates/PlatformwiseTicketsByCategory";
import PlatformwiseNegativeTicketsByCategory from "../../components/reportPdfTemplates/PlatformwiseNegativeTicketsByCategory";
import FrtTatAveragesByCategory from "../../components/reportPdfTemplates/FrtTatAveragesByCategory";
import SenrimentsByTicketsCategory from "../../components/reportPdfTemplates/SenrimentsByTicketsCategory";
import TicketsByCategory from "../../components/reportPdfTemplates/TicketsByCategory";
import NegativeTicketsByCategory from "../../components/reportPdfTemplates/NegativeTicketsByCategory";
import SentimentByCustomFields from "../../components/reportPdfTemplates/SentimentByCustomFields";
import SentimentOnCustomValue from "../../components/reportPdfTemplates/SentimentOnCustomValue";
import NegativeCustomValueReport from "../../components/reportPdfTemplates/NegativeCustomValueReport";
import TicketsByCustomFields from "../../components/reportPdfTemplates/TicketsByCustomFields";
import FrtTatAveargesByCustomFields from "../../components/reportPdfTemplates/FrtTatAveargesByCustomFields";
import PlatformwiseTicketsByCategoryChart from "../../components/reportPdfTemplates/PlatformwiseTicketsByCategoryChart";
import PlatformwiseNegativeTicketsByCategoryChart from "../../components/reportPdfTemplates/PlatformwiseNegativeTicketsByCategoryChart";
import FrtTatAveragesByCategoryChart from "../../components/reportPdfTemplates/FrtTatAveragesByCategoryChart";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReportTemplateAction.REQUEST_REPORTS_BY_ID,
    ProjectAction.REQUEST_PROJECT_BY_ID,
    PlatformAction.REQUEST_PLATFORM,
    UserAction.REQUEST_USER_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
  ]),

  reportById: state.reportById.reportById || [],
  projectById: state.projectById.projectById || [],
  platform: state.platform.platform || [],
  userfilter: state.userfilter.userfilter || [],
  statusFilter: state.statusFilter.statusFilter || [],
});

function Dashboard(props) {
  //useEffect function
  useEffect(() => {
    fetchReportTemplate();
    fetchOrgPlatforms();
    fetchUsers();
    fetchStatusFilter();
  }, []);

  const [myTicketsTime, setMyTicketTime] = useState(
    localStorage.getItem("my_tickets_date_filter")
      ? JSON.parse(localStorage.getItem("my_tickets_date_filter"))
      : "Last 30 Days"
  );
  let maxDate = new Date();

  const [createdDate, setCreatedDate] = useState(
    localStorage.getItem("my_tickets_custom_date_filter")
      ? [
          new Date(
            JSON.parse(localStorage.getItem("my_tickets_custom_date_filter"))[0]
          ),
          new Date(
            JSON.parse(localStorage.getItem("my_tickets_custom_date_filter"))[1]
          ),
        ]
      : [new Date(), new Date()]
  );

  let onKeyDown = (event) => {
    if (event.key === "Backspace") {
      event.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const createdDateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d ? e[0].$d : null;
    let date2 = e[1] && e[1].$d ? e[1].$d : null;

    const isPastDate = (date) => {
      const now = new Date();
      return date <= now;
    };

    if (
      isValid(date1) &&
      isValid(date2) &&
      isPastDate(date1) &&
      isPastDate(date2)
    ) {
      setCreatedDate([isValid(date1) && date1, isValid(date2) && date2]);

      if (isValid(date1) && isValid(date2)) {
        localStorage.setItem(
          "my_tickets_custom_date_filter",
          JSON.stringify([isValid(date1) && date1, isValid(date2) && date2])
        );
      } else {
        localStorage.removeItem("my_tickets_custom_date_filter");
      }
    }
  };

  const timeFilterHandleChange = async (event, time) => {
    setMyTicketTime(time);

    if (time !== "Custom") {
      setCreatedDate([new Date(), new Date()]);
    }

    if (time != null) {
      localStorage.setItem("my_tickets_date_filter", JSON.stringify(time));
      localStorage.setItem(
        "my_tickets_custom_date_filter",
        JSON.stringify([new Date(), new Date()])
      );
    } else {
      localStorage.removeItem("my_tickets_date_filter");
    }
  };

  function fetchOrgPlatforms() {
    let param = {
      // level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function fetchUsers() {
    let param = { page_limit: "none" };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  function fetchReportTemplate() {
    let report_id =
      localStorage.getItem("default_dashboard") &&
      JSON.parse(localStorage.getItem("default_dashboard"));
    JSON.parse(localStorage.getItem("default_dashboard")) &&
      props.dispatch(ReportTemplateAction.requestReportsById(report_id));
  }

  function fetchStatusFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(StatusAction.requestStatusFilter(params));
  }

  let platform_list = [];
  if (props.platform.hits) {
    props.platform.hits.hits &&
      props.platform.hits.hits.length > 0 &&
      props.platform.hits.hits.map((item) => {
        platform_list.push({
          platform_id: item._id,
          platform_name: item._source.platform_name,
          platform_alias: item._source.alias,
        });
      });
  }

  let userList = [];
  if (props.userfilter) {
    props.userfilter.data &&
      props.userfilter.data.length > 0 &&
      props.userfilter.data.map((user) => {
        userList.push({
          user_id: user.id,
          user_name: user.username,
        });
      });
  }

  const activitiesData = JSON.parse(localStorage.getItem("activities"));
  let activity_list = [];
  activitiesData &&
    activitiesData.hits &&
    activitiesData.hits.hits &&
    activitiesData.hits.hits.length > 0 &&
    activitiesData.hits.hits.map((item) => {
      activity_list.push({
        activity_id: item._id,
        activity_name: item._source.activities,
        platform: item._source.platform,
      });
    });

  let statusList = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits &&
      props.statusFilter.hits.hits.length > 0 &&
      props.statusFilter.hits.hits.map((item) => {
        statusList.push({
          status_id: item._id,
          status_name: item._source.status_name,
        });
      });
  }

  const [dateRange, setDateRange] = useState(UpdateDateRange());

  function UpdateDateRange() {
    let date = myTicketsTime !== "Custom" ? myTicketsTime : createdDate;

    let date0 = new Date();
    let nextday = new Date(date0);
    nextday.setDate(nextday.getDate() + 1);

    let date1 = new Date();
    let previousday = new Date(date1);
    previousday.setDate(previousday.getDate() - 1);

    // start from today's date last 7 days
    const last7Days = [...Array(8).keys()].map((index) => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      tomorrow.setDate(tomorrow.getDate() - index);

      return tomorrow;
    });

    // last 30 days
    let date2 = new Date();
    let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

    // ***************************************
    // last 3 months
    let date3 = new Date();
    let last3Month = date3.setMonth(date3.getMonth() - 3);

    let last2days = [...Array(3).keys()].map((index) => {
      const date = new Date();
      const tomorrow = new Date(date);
      tomorrow.setDate(date.getDate() + 1);

      tomorrow.setDate(tomorrow.getDate() - index);
      return tomorrow;
    });

    switch (date) {
      case "Today":
        return [
          `${format(new Date(), "yyyy-MM-dd")}`,
          `${format(nextday, "yyyy-MM-dd")}`,
        ];
      case "Previous Day":
        return [
          `${format(previousday, "yyyy-MM-dd")}`,
          `${format(new Date(), "yyyy-MM-dd")}`,
        ];
      case "Last 2 Days":
        return [
          `${format(last2days[2], "yyyy-MM-dd")}`,
          `${format(last2days[0], "yyyy-MM-dd")}`,
        ];
      case "Last 7 Days":
        return [
          `${format(last7Days[7], "yyyy-MM-dd")}`,
          `${format(last7Days[0], "yyyy-MM-dd")}`,
        ];
      case "Last 30 Days":
        return [
          `${format(last30Days, "yyyy-MM-dd")}`,
          `${format(nextday, "yyyy-MM-dd")}`,
        ];
      case "Last 3 Months":
        return [
          `${format(last3Month, "yyyy-MM-dd")}`,
          `${format(nextday, "yyyy-MM-dd")}`,
        ];
      default:
        if (Array.isArray(date)) {
          return [
            `${format(date[0], "yyyy-MM-dd")}`,
            `${format(date[1], "yyyy-MM-dd")}`,
          ];
        } else {
          return null;
        }
    }
  }
  useEffect(() => {
    setDateRange(UpdateDateRange());
  }, [myTicketsTime, createdDate]);

  const SingleBoxLayout = (page) => {
    return (
      <>
        <Grid
          // container
          // spacing={1}
          p={
            page.reports && page.reports[0].report_id === "rBPdGowBpodn5_f5jeOH"
              ? //   ||
                // (page.reports && page.reports[0].report_name === "Turn Around Time")
                "none"
              : 3
          }
          className="grid-container"
          sx={{
            // width: `100%`,
            // height: `auto`,
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            backgroundColor: "white",
            marginBottom: 2,
            // margin: "auto",
            // border:
            //   page.reports && page.reports[0].report_name
            //     ? "none"
            //     : `1px dotted gray`,
            // padding: page.reports && page.reports[0].report_name ? 0 : 5,
            // boxSizing: "border-box",
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            xs={12}
            // className="grid-item"
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border:
                page.reports &&
                page.reports[0].report_id === "rBPdGowBpodn5_f5jeOH"
                  ? //   ||
                    // (page.reports &&
                    //   page.reports[0].report_name === "Turn Around Time")
                    "none"
                  : `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowOneColumnLayout = (page) => {
    return (
      <>
        <Grid
          container
          // spacing={1}
          sx={{
            width: `100%`,
            height: `auto`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            margin: "auto",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              width: "inherit",
              // height: `${pageHeight / 2}mm`,
              // maxWidth: `${pageWidth}mm`,
              // maxHeight: `${pageHeight / 2}mm`,
              overflow: "hidden",
              border:
                page.reports && page.reports[0].report_id
                  ? "none"
                  : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              width: "inherit",
              // height: `${pageHeight / 2}mm`,
              // maxWidth: `${pageWidth}mm`,
              // maxHeight: `${pageHeight / 2}mm`,
              overflow: "hidden",
              border:
                page.reports && page.reports[0].report_id
                  ? "none"
                  : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[1].report_id &&
              ReturnReport(page.reports[1])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const OneRowTwoColumnLayout = (page) => {
    return (
      <>
        <Grid
          // container
          p={3}
          // item
          // lg={12}
          // xs={12}
          // sm={12}
          // md={12}
          className="grid-container-one "
          sx={{
            // width: `${pageWidth}mm`,
            // height: `${pageHeight}mm`,
            // display: "flex",
            // alignItems: "flex-start",
            // justifyContent: "center",
            backgroundColor: "white",
            // margin: "auto",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>

          <Grid
            item
            xs={6}
            className="grid-item"
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              overflow: "hidden",
              border: `1px solid ${theme.reports.gridBorder}`,
              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
              borderRadius: theme.table.layoutRadius,

              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // minHeight: 500,
            }}
          >
            {page.reports &&
              page.reports[1]?.report_id &&
              ReturnReport(page.reports[1])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowTowColumnLayout = (page) => {
    return (
      <>
        <Grid
          // container
          p={3}
          // item
          // lg={12}
          // xs={12}
          // sm={12}
          // md={12}
          className="grid-container-two"
          sx={{
            // width: `${pageWidth}mm`,
            // height: `${pageHeight}mm`,
            // display: "flex",
            // alignItems: "flex-start",
            // justifyContent: "center",
            backgroundColor: "white",
            // margin: "auto",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[1].report_id &&
              ReturnReport(page.reports[1])}
          </Grid>

          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[2].report_id &&
              ReturnReport(page.reports[2])}
          </Grid>
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[3].report_id &&
              ReturnReport(page.reports[3])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const ThreeRowTowColumnLayout = (page) => {
    return (
      <>
        <Grid
          p={3}
          className="grid-container-two"
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[1].report_id &&
              ReturnReport(page.reports[1])}
          </Grid>

          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[2].report_id &&
              ReturnReport(page.reports[2])}
          </Grid>
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[3].report_id &&
              ReturnReport(page.reports[3])}
          </Grid>

          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[4].report_id &&
              ReturnReport(page.reports[4])}
          </Grid>
          <Grid
            item
            className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[5].report_id &&
              ReturnReport(page.reports[5])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const SingleLeftDividedRight_2 = (page) => {
    return (
      <>
        <Grid
          p={3}
          className="grid-container-one "
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            // className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const DividedLeft_2_SingleRight = (page) => {
    return (
      <>
        <Grid
          p={3}
          className="grid-container-one "
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[0].report_id &&
                  ReturnReport(page.reports[0])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,

                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            // className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[2].report_id &&
              ReturnReport(page.reports[2])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const TwoColOn1stRowOneColOn2ndRow = (page) => {
    return (
      <>
        <Grid
          item
          container
          p={3}
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // height: "auto",
            }}
          >
            <Grid
              // item
              // container
              marginBottom={3}
              xs={12}
              // flexDirection={"column"}
              gap={"10px"}
              sx={{
                display: "flex",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  // boxSizing: "border-box",
                  // height: "100%",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[0].report_id &&
                  ReturnReport(page.reports[0])}
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  // boxSizing: "border-box",
                  // height: "100%",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                overflow: "hidden",
                border: `1px solid ${theme.reports.gridBorder}`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: theme.table.layoutRadius,
              }}
              // className="bg-dark"
            >
              {page.reports &&
                page.reports[2].report_id &&
                ReturnReport(page.reports[2])}
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const OneColOn1stRowTwoColOn2ndRow = (page) => {
    return (
      <>
        <Grid
          item
          container
          p={3}
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // height: "auto",
            }}
          >
            <Grid
              marginBottom={3}
              item
              xs={12}
              sx={{
                width: "inherit",
                overflow: "hidden",
                border: `1px solid ${theme.reports.gridBorder}`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: theme.table.layoutRadius,
              }}
              // className="bg-dark"
            >
              {page.reports &&
                page.reports[0].report_id &&
                ReturnReport(page.reports[0])}
            </Grid>

            <Grid
              // item
              // container
              xs={12}
              // flexDirection={"column"}
              gap={"10px"}
              sx={{
                display: "flex",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  borderRadius: theme.table.layoutRadius,

                  // boxSizing: "border-box",
                  // height: "100%",
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  borderRadius: theme.table.layoutRadius,

                  // boxSizing: "border-box",
                  // height: "100%",
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const BIREPORT = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"column"}
            justifyContent={"flex-start"}
            sx={{
              backgroundColor: "white",
              boxShadow: theme.shadows,
              width: "100%",
            }}
          >
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems="flex-start"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                overflow: "hidden",
                boxSizing: "border-box",
              }}
            >
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                alignItems="flex-start"
                justifyContent={"center"}
                textAlign="center"
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[0] &&
                  page.reports[0].report_id &&
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems="flex-start"
                xl={4.5}
                lg={4.5}
                md={4.5}
                sm={4.5}
                xs={4.5}
                sx={{
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  container
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      width: "inherit",
                      overflow: "hidden",
                      border: `1px solid ${theme.reports.gridBorder}`,
                      padding: page.reports && page.reports.length ? 0 : 5,
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      borderRadius: theme.table.layoutRadius,
                    }}
                  >
                    {page.reports &&
                      page.reports[1] &&
                      page.reports[1].report_id &&
                      ReturnReport(
                        page.reports[1],
                        props.platform_list,
                        props.descriptionCallback,
                        props.activity_list,
                        props.statusList,
                        props.userList
                      )}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{
                      width: "inherit",
                      overflow: "hidden",
                      border: `1px solid ${theme.reports.gridBorder}`,
                      padding: page.reports && page.reports.length ? 0 : 5,
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",

                      borderRadius: theme.table.layoutRadius,
                    }}
                  >
                    {page.reports &&
                      page.reports[2] &&
                      page.reports[2].report_id &&
                      ReturnReport(
                        page.reports[2],
                        props.platform_list,
                        props.descriptionCallback,
                        props.activity_list,
                        props.statusList,
                        props.userList
                      )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "inherit",
                    overflow: "hidden",
                    border: `1px solid ${theme.reports.gridBorder}`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: theme.table.layoutRadius,
                  }}
                >
                  {page.reports &&
                    page.reports[3] &&
                    (page.reports[3].report_name ||
                      page.reports[3].report_id) &&
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xl={4.5}
                lg={4.5}
                md={4.5}
                sm={4.5}
                xs={4.5}
                textAlign="center"
                sx={{
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "inherit",
                    overflow: "hidden",
                    border: `1px solid ${theme.reports.gridBorder}`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: theme.table.layoutRadius,
                  }}
                  // className="bg-dark"
                >
                  {page.reports &&
                    page.reports[4] &&
                    (page.reports[4].report_name ||
                      page.reports[4].report_id) &&
                    ReturnReport(
                      page.reports[4],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "inherit",
                    overflow: "hidden",
                    border: `1px solid ${theme.reports.gridBorder}`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    borderRadius: theme.table.layoutRadius,
                  }}
                  // className="bg-info"
                >
                  {page.reports &&
                    page.reports[5] &&
                    page.reports[5].report_id &&
                    ReturnReport(
                      page.reports[5],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="flex-start"
              justifyContent={"center"}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                overflow: "hidden",
                boxSizing: "border-box",
              }}
            >
              <Grid
                item
                container
                flexDirection={"column"}
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                textAlign="center"
                sx={{
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "inherit",
                    overflow: "hidden",
                    border: `1px solid ${theme.reports.gridBorder}`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: theme.table.layoutRadius,
                  }}
                  // className="bg-info"
                >
                  {page.reports &&
                    page.reports[6] &&
                    (page.reports[6].report_name ||
                      page.reports[6].report_id) &&
                    ReturnReport(
                      page.reports[6],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "inherit",
                    overflow: "hidden",
                    border: `1px solid ${theme.reports.gridBorder}`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: theme.table.layoutRadius,
                  }}
                  // className="bg-dark"
                >
                  {page.reports &&
                    page.reports[7] &&
                    page.reports[7].report_id &&
                    ReturnReport(
                      page.reports[7],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
              </Grid>

              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                alignItems="center"
                justifyContent={"center"}
                textAlign="center"
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[8] &&
                  page.reports[8].report_id &&
                  ReturnReport(
                    page.reports[8],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>

              <Grid
                item
                container
                // flexDirection={}
                xl={5}
                lg={5}
                md={5}
                sm={5}
                xs={5}
                textAlign="center"
                sx={{
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "inherit",
                  }}
                  spacing={1}
                >
                  <Grid container sx={{ height: "inherit" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        width: "inherit",
                        overflow: "hidden",
                        border: `1px solid ${theme.reports.gridBorder}`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: theme.table.layoutRadius,
                      }}
                    >
                      {page.reports &&
                        page.reports[9] &&
                        (page.reports[9].report_name ||
                          page.reports[9].report_id) &&
                        ReturnReport(
                          page.reports[9],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        width: "inherit",
                        overflow: "hidden",
                        border: `1px solid ${theme.reports.gridBorder}`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: theme.table.layoutRadius,
                      }}
                    >
                      {page.reports &&
                        page.reports[10] &&
                        page.reports[10].report_id &&
                        ReturnReport(
                          page.reports[10],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList,
                          props.userList
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  spacing={1}
                  sx={{
                    width: "inherit",
                  }}
                >
                  <Grid container sx={{ height: "inherit" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        width: "inherit",
                        overflow: "hidden",
                        border: `1px solid ${theme.reports.gridBorder}`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: theme.table.layoutRadius,
                      }}
                    >
                      {page.reports &&
                        page.reports[11] &&
                        page.reports[11].report_id &&
                        ReturnReport(
                          page.reports[11],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        width: "inherit",
                        overflow: "hidden",
                        border: `1px solid ${theme.reports.gridBorder}`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: theme.table.layoutRadius,
                      }}
                    >
                      {page.reports &&
                        page.reports[12] &&
                        page.reports[12].report_id &&
                        ReturnReport(
                          page.reports[12],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowsThreeColumnLayout = (page) => {
    return (
      <>
        <Grid
          // container
          p={3}
          // item
          // lg={12}
          // xs={12}
          // sm={12}
          // md={12}
          className="grid-container-three"
          sx={{
            // width: `${pageWidth}mm`,
            // height: `${pageHeight}mm`,
            // display: "flex",
            // alignItems: "flex-start",
            // justifyContent: "center",
            backgroundColor: "white",
            // margin: "auto",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            className="grid-item"
            xs={4}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>
          <Grid
            item
            className="grid-item"
            xs={4}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[1].report_id &&
              ReturnReport(page.reports[1])}
          </Grid>

          <Grid
            item
            className="grid-item"
            xs={4}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[2].report_id &&
              ReturnReport(page.reports[2])}
          </Grid>
          <Grid
            item
            className="grid-item"
            xs={4}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[3].report_id &&
              ReturnReport(page.reports[3])}
          </Grid>

          <Grid
            item
            className="grid-item"
            xs={4}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[4].report_id &&
              ReturnReport(page.reports[4])}
          </Grid>

          <Grid
            item
            className="grid-item"
            xs={4}
            sx={{
              // width: `${pageWidth / 2}mm`,
              // maxWidth: `${pageWidth / 2}mm`,
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",

              // border:
              //   page.reports && page.reports[0].report_name
              //     ? "none"
              //     : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[5].report_id &&
              ReturnReport(page.reports[5])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const SingleLeftDividedRight_3 = (page) => {
    return (
      <>
        <Grid
          p={3}
          className="grid-container-one "
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            // className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(page.reports[0])}
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                  marginBottom: 3,
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[3].report_id &&
                  ReturnReport(page.reports[3])}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const DividedLeft_3_SingleRight = (page) => {
    return (
      <>
        <Grid
          p={3}
          className="grid-container-one "
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[0].report_id &&
                  ReturnReport(page.reports[0])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  marginBottom: 3,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,

                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            // className="grid-item"
            xs={6}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              height: "auto",
              borderRadius: theme.table.layoutRadius,
            }}
          >
            {page.reports &&
              page.reports[3].report_id &&
              ReturnReport(page.reports[3])}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const ThreeColOn1stRowOneColOn2ndRow = (page) => {
    return (
      <>
        <Grid
          item
          container
          p={3}
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // height: "auto",
            }}
          >
            <Grid
              // item
              // container
              marginBottom={3}
              xs={12}
              // flexDirection={"column"}
              gap={"10px"}
              sx={{
                display: "flex",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  // boxSizing: "border-box",
                  // height: "100%",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[0].report_id &&
                  ReturnReport(page.reports[0])}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  // boxSizing: "border-box",
                  // height: "100%",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  // boxSizing: "border-box",
                  // height: "100%",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                overflow: "hidden",
                border: `1px solid ${theme.reports.gridBorder}`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: theme.table.layoutRadius,
              }}
              // className="bg-dark"
            >
              {page.reports &&
                page.reports[3].report_id &&
                ReturnReport(page.reports[3])}
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const OneColOn1stRowThreeColOn2ndRow = (page) => {
    return (
      <>
        <Grid
          item
          container
          p={3}
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              // height: "auto",
            }}
          >
            <Grid
              marginBottom={3}
              item
              xs={12}
              sx={{
                width: "inherit",
                overflow: "hidden",
                border: `1px solid ${theme.reports.gridBorder}`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: theme.table.layoutRadius,
              }}
              // className="bg-dark"
            >
              {page.reports &&
                page.reports[0].report_id &&
                ReturnReport(page.reports[0])}
            </Grid>

            <Grid
              // item
              // container
              xs={12}
              // flexDirection={"column"}
              gap={"10px"}
              sx={{
                display: "flex",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  borderRadius: theme.table.layoutRadius,

                  // boxSizing: "border-box",
                  // height: "100%",
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  borderRadius: theme.table.layoutRadius,

                  // boxSizing: "border-box",
                  // height: "100%",
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  border: `1px solid ${theme.reports.gridBorder}`,
                  overflow: "hidden",
                  padding: page.reports && page.reports.length ? 0 : 5,
                  borderRadius: theme.table.layoutRadius,

                  // boxSizing: "border-box",
                  // height: "100%",
                }}
              >
                {page.reports &&
                  page.reports[3].report_id &&
                  ReturnReport(page.reports[3])}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const DubleLeftDividedRight_3 = (page) => {
    return (
      <>
        <Grid
          p={3}
          className="grid-container-one "
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[0].report_id &&
                  ReturnReport(page.reports[0])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                  marginBottom: 3,
                }}
              >
                {page.reports &&
                  page.reports[3].report_id &&
                  ReturnReport(page.reports[3])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[4].report_id &&
                  ReturnReport(page.reports[4])}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const tripleLeftDoubleRight = (page) => {
    return (
      <>
        <Grid
          p={3}
          className="grid-container-one "
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            boxShadow: theme.shadows,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[0].report_id &&
                  ReturnReport(page.reports[0])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 3,
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[1].report_id &&
                  ReturnReport(page.reports[1])}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 3,
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[2].report_id &&
                  ReturnReport(page.reports[2])}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              overflow: "hidden",
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item container>
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginBottom: 3,
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[3].report_id &&
                  ReturnReport(page.reports[3])}
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  overflow: "hidden",
                  border: `1px solid ${theme.reports.gridBorder}`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderRadius: theme.table.layoutRadius,
                }}
              >
                {page.reports &&
                  page.reports[4].report_id &&
                  ReturnReport(page.reports[4])}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const ReturnReport = (report) => {
    return (
      <>
        {report.report_id === "rBPdGowBpodn5_f5jeOH" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketSummary
                description={report.description}
                platformList={platform_list}
                myTicketsTime={
                  myTicketsTime !== "Custom" ? myTicketsTime : createdDate
                }
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {/* {report.report_name === "Turn Around Time" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TurnAroundTime
                description={report.description}
                platformList={platform_list}
                myTicketsTime={
                  myTicketsTime !== "Custom" ? myTicketsTime : createdDate
                }
              />
            </Grid>
          </ErrorBoundary>
        )} */}
        {report.report_id === "uxPdGowBpodn5_f5kuPX" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TotalMentions
                report={getItemsById(report.report_id)[0]?.report_name}
                description={report.description}
                platformList={platform_list}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "vBPdGowBpodn5_f5k-Mo" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TotalMentionsChart
                report={getItemsById(report.report_id)[0]?.report_name}
                description={report.description}
                platformList={platform_list}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "wxPdGowBpodn5_f5leP9" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <OverallSentimentMentions3months
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "xBPdGowBpodn5_f5luNU" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <OverallSentimentMentionChart3months
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "0hPgGowBpodn5_f5vuOU" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <NegativeMentionsDispositionWise
                report={getItemsById(report.report_id)[0]?.report_name}
                filterValue={report.filter}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "0xPgGowBpodn5_f5v-Of" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <NegativeMentionsDispositionWiseChart
                report={getItemsById(report.report_id)[0]?.report_name}
                filterValue={report.filter}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "wRPdGowBpodn5_f5leNX" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <NegativeMentions
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                platformList={platform_list}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "whPdGowBpodn5_f5leOg" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <NegativeMentionsChart
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                platformList={platform_list}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "1BPgGowBpodn5_f5weMf" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TotalNegativeMentions
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "1BPgGowBpodn5_f5weGH" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <SentimentByCustomFields
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "1RPgGowBpodn5_f5wuMn" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TotalNegativeMentionsChart
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "zBPgGowBpodn5_f5uONx" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <EscalationsReports
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "Monthly Wise Platform Review Counts" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <MonthlyWisePlatformReviewCounts
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                platformList={platform_list}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sBPdGowBpodn5_f5juPF" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <ResponseOverview
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                platformList={platform_list}
                activity_list={activity_list}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "wBPdGowBpodn5_f5lONv" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <FRTEfficiencyAgentWise
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "xxPdGowBpodn5_f5l-NJ" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformWiseReport
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                platformList={platform_list}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "tRPdGowBpodn5_f5kOPx" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <AvgFRTPlatformWise
                platformList={platform_list}
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "uRPdGowBpodn5_f5kuMT" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformWiseSentimentOverview
                platformList={platform_list}
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "1xPgGowBpodn5_f5xONj" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <CategoryWiseAvgFlr
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "zxPgGowBpodn5_f5u-OE" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <CategoryWiseTatDistribution
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "zRPgGowBpodn5_f5ueN5" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <CategoryWiseDistribution
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "zhPgGowBpodn5_f5uuNw" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <CategoryWiseDistributionChart
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "1hPgGowBpodn5_f5w-Me" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <CategoryWiseSentiments
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "0BPgGowBpodn5_f5vON5" && (
          <ErrorBoundary message="Error in loading report">
            <Grid
              lg={12}
              sx={{
                height: "100%",
              }}
            >
              <OverallShareOfVoice
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "0RPgGowBpodn5_f5veOg" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <ShareOfVoiceChart
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "xRPdGowBpodn5_f5luOh" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformWiseOverallSentiment
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                platformList={platform_list}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "xhPdGowBpodn5_f5l-ME" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformWiseOverallSentimentChart
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                platformList={platform_list}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "vxPdGowBpodn5_f5lOMO" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <ChannelWiseSentimentDistribution
                filterValue={report.filter}
                platformList={platform_list}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {/* 
        {report.report_name ===
          "Channel Wise Sentiment Distribution (Chart view)" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <ChannelWiseSentimentDisrtibutionChart
                filterValue={report.filter}
                platformList={platform_list}
                report={"Channel Wise Sentiment Distribution (Chart view)"}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )} */}
        {/* {report.report_name === "Ticket Closure TAT Breach" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketClouserTatBreach
                filterValue={report.filter}
                report={"Ticket Closure TAT Breach"}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )} */}
        {report.report_id === "shPdGowBpodn5_f5j-Os" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketClouserTatBreachChart
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "qhPdGowBpodn5_f5jOPq" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketClosureFrtBreachChart
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "uhPdGowBpodn5_f5kuNa" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <SentimentOvertime
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                platformList={platform_list}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "txPdGowBpodn5_f5keN0" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <OverallAverageFRT
                platformList={platform_list}
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "uBPdGowBpodn5_f5keOw" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <OverallAverageTAT
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                platformList={platform_list}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "vRPdGowBpodn5_f5k-Ns" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <BridgeUsersAvgFRT
                platformList={platform_list}
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "vhPdGowBpodn5_f5k-Oq" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <BridgeUsersAvgTAT
                platformList={platform_list}
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "rRPdGowBpodn5_f5jePU" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <HourlyTicketsResolved
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
                platformList={platform_list}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "thPdGowBpodn5_f5keM7" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <MentionsTrendWithEngagement
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "Instagram Message Summary" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <InstagramMessageSummary
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "Instagram User Activity" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <InstagramUserActivity
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "tBPdGowBpodn5_f5kOOZ" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <WordCloudReport
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                callBack={props.descriptionCallback}
                description={report.description}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "rxPdGowBpodn5_f5juNd" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <OverallTicketStatusUserwise
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
                DateFilter={dateRange}
                statusList={statusList}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "yxPdGowBpodn5_f5mOPQ" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TatOverview
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "yRPdGowBpodn5_f5mOMQ" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <OverallSentimentMentions
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "yhPdGowBpodn5_f5mON9" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <SentimentAnalysisLast12monthsChart
                filterValue={report.filter}
                report={getItemsById(report.report_id)[0]?.report_name}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {/* {report.report_name === "Sentiment Analysis" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <SentimentAnalysis
                filterValue={report.filter}
                report={"Sentiment Analysis"}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )} */}
        {report.report_id === "sRPdGowBpodn5_f5j-Ma" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <RoleSummary
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-MB" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformwiseTicketsByCategory
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}{" "}
        {report.report_id === "sRPdGowBpodn5_f5j-BM" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformwiseTicketsByCategoryChart
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}{" "}
        {report.report_id === "sRPdGowBpodn5_f5j-CM" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformwiseNegativeTicketsByCategoryChart
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-MC" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <PlatformwiseNegativeTicketsByCategory
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}{" "}
        {report.report_id === "sRPdGowBpodn5_f5j-MF" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketsByCategory
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-MG" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <NegativeTicketsByCategory
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-MD" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <FrtTatAveragesByCategory
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}{" "}
        {report.report_id === "sRPdGowBpodn5_f5j-DM" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <FrtTatAveragesByCategoryChart
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}{" "}
        {report.report_id === "sRPdGowBpodn5_f5j-ME" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <SenrimentsByTicketsCategory
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-MH" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <SentimentOnCustomValue
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-My" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <FrtTatAveargesByCustomFields
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-Mz" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketsByCustomFields
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "sRPdGowBpodn5_f5j-MI" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <NegativeCustomValueReport
                report={getItemsById(report.report_id)[0]?.report_name}
                statusList={statusList}
                userList={userList}
                DateFilter={dateRange}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id === "yBPdGowBpodn5_f5l-Or" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketCategoryReport
                report={getItemsById(report.report_id)[0]?.report_name}
              />
            </Grid>
          </ErrorBoundary>
        )}
        {report.report_id ===
          "Ticket Category - Last 3 Months Detailed Overview" && (
          <ErrorBoundary message="Error in loading report">
            <Grid lg={12}>
              <TicketCategoryDetailedReport
                report={getItemsById(report.report_id)[0]?.report_name}
              />
            </Grid>
          </ErrorBoundary>
        )}
      </>
    );
  };

  const { isRequesting } = props;

  return (
    <Box
      className="scrollable"
      style={{
        height: "100%",
        maxHeight: `${window.innerHeight}px`,
        overflow: "auto",
        position: "relative",
      }}
    >
      <Stack direction={"column"} spacing={1}>
        <LoadingIndicator isActive={isRequesting}>
          {localStorage.getItem("default_dashboard") &&
          JSON.parse(localStorage.getItem("default_dashboard")) !== "" ? (
            <>
              <Box width="100%" pt={1}>
                {props.reportById._source &&
                  props.reportById._source.template.length !== 0 && (
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignItems="center"
                      justifyContent={"flex-end"}
                    >
                      {myTicketsTime === "Custom" && (
                        <Grid item lg={2} md={2} laptop={2} mr={1}>
                          {/* <DatePicker
                    value={createdDate}
                    onChange={createdDateValueHandleChange}
                  /> */}

                          <DatePicker
                            value={createdDate ? createdDate : [null, null]}
                            onChange={createdDateValueHandleChange}
                            maxDate={maxDate}
                            onKeyDown={onKeyDown}
                          />
                        </Grid>
                      )}

                      <Grid item xl={2}>
                        <AutoCompleteComponent
                          disablePortal
                          list={[
                            "Today",
                            "Previous Day",
                            "Last 2 Days",
                            "Last 7 Days",
                            "Last 30 Days",
                            "Last 3 Months",
                            "Custom",
                            // "Custom",
                          ]}
                          handleChange={timeFilterHandleChange}
                          textFieldProps={{
                            placeholder: "Date",
                          }}
                          value={myTicketsTime}
                          size="small"
                          width="100%"
                        />
                      </Grid>
                    </Grid>
                  )}
              </Box>
              <Box>
                <Grid item xl={12} xs={12} mt={1}>
                  {props.reportById._source &&
                    props.reportById._source.template.length !== 0 &&
                    props.reportById._source.template.map((page) =>
                      page.layout === "SingleBoxLayout"
                        ? SingleBoxLayout(page)
                        : page.layout === "TwoRowOneColumnLayout"
                        ? TwoRowOneColumnLayout(page)
                        : page.layout === "OneRowTwoColumnLayout"
                        ? OneRowTwoColumnLayout(page)
                        : page.layout === "TwoRowTowColumnLayout"
                        ? TwoRowTowColumnLayout(page)
                        : page.layout === "Single Left, Divided Right(2)"
                        ? SingleLeftDividedRight_2(page)
                        : page.layout === "Divided Left(2), Single right"
                        ? DividedLeft_2_SingleRight(page)
                        : page.layout ===
                          "Two Col on first Row, One col on second Row"
                        ? TwoColOn1stRowOneColOn2ndRow(page)
                        : page.layout ===
                          "One Col on first Row, Two col on second Row"
                        ? OneColOn1stRowTwoColOn2ndRow(page)
                        : // : page.layout === "BI"
                        // ? BIREPORT(page)
                        page.layout === "three rows two columns"
                        ? ThreeRowTowColumnLayout(page)
                        : page.layout === "two rows three columns"
                        ? TwoRowsThreeColumnLayout(page)
                        : page.layout === "Single Left, Divided Right(3)"
                        ? SingleLeftDividedRight_3(page)
                        : page.layout === "Divided Left(3), Single right"
                        ? DividedLeft_3_SingleRight(page)
                        : page.layout ===
                          "three Col on first Row, One col on second Row"
                        ? ThreeColOn1stRowOneColOn2ndRow(page)
                        : page.layout ===
                          "One Column on first Row, Two Column on second Row"
                        ? OneColOn1stRowThreeColOn2ndRow(page)
                        : page.layout === "Double Left, Divided Right(3)"
                        ? DubleLeftDividedRight_3(page)
                        : page.layout === "Triple Left, double right"
                        ? tripleLeftDoubleRight(page)
                        : null
                    )}
                </Grid>
              </Box>{" "}
            </>
          ) : (
            !isRequesting && (
              <div className="nodataFoundContainer">
                <p className="nodataFoundText">
                  Add default dashboard to the user in Add Role
                </p>
              </div>
            )
          )}
        </LoadingIndicator>
      </Stack>
    </Box>
  );
}

export default connect(mapStateToProps)(Dashboard);
