import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import McnFeedsModel from "./models/McnFeedsModel";

// McnFeedsEffect
export default class McnFeedsEffect {
  // calling an API according to the Email Account model
  // Get method for the Email account
  static async requestMcnFeeds(params) {
    const endpoint = environment.api.mcnFeeds;
    return EffectUtility.getToModel(McnFeedsModel, endpoint, params);
  }

  //   Get method for the Email account filter
  static async requestMcnFeedsFilter(params) {
    const endpoint = environment.api.mcnFeedsFilter;
    return EffectUtility.getToModel(McnFeedsModel, endpoint, params);
  }
}
