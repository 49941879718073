import BaseReducer from "../../../utilities/BaseReducer";
import FaceBookAccountAction from "./FaceBookAccountAction";

// FaceBookAccountReduser function
export default class FaceBookAccountReduser extends BaseReducer {
  initialState = {
    facebookAccount: [],
  };
  [FaceBookAccountAction.REQUEST_FACEBOOK_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      facebookAccount: action.payload,
    };
  }
}
