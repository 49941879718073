import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ReplayPostModel from "./models/ReplayPostModel";

//Category effect
export default class ReplyEffect {
  // calling an API according to the TwitterDirectmessage
  static async requestReply(id, params) {
    const endpoint = environment.api.reply + "/" + id;
    return EffectUtility.getToModel(ReplayPostModel, endpoint, params);
  }

  // TwitterDirectmessage Post API
  static async requestPostReply(
    id,
    params,
    data,
    callback,
    resolve,
    setLoading
  ) {
    const endpoint =
      environment.api.reply + "/" + id + "?account_name=" + params.account_name;

    let response = EffectUtility.postToModel(ReplayPostModel, endpoint, data);

    response.then((resp) => {
      if (resp) {
        resp.status === "success" && callback && callback(resp);
        resp.status === "success" && resolve && resolve();
        resolve.status !== "success" && setLoading(false);
      }
    });

    return response;
  }
}
