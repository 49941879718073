import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import PropertywiseUrlsModel from "./models/PropertywiseUrlsModel";

export default class PropertywiseUrlsEffect {
  static async requestPropertyWiseContentUrlsHistory(params, callback) {
    const endpoint = environment.api.websweepxPropertyWiseContentUrlsHistory;
    let response = EffectUtility.getToModel(
      PropertywiseUrlsModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.status === "success" && callback) {
        callback(resp);
      }
    });
    return response;
  }
}
