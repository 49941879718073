import CustomerDataEffect from "./CustomerDataEffect";
import ActionUtility from "../../utilities/ActionUtility";

export default class CustomerDataAction {
  static REQUEST_CUSTOMER_DATA = "CustomerDataAction.REQUEST_CUSTOMER_DATA";
  static REQUEST_CUSTOMER_DATA_FINISHED =
    "CustomerDataAction.REQUEST_CUSTOMER_DATA_FINISHED";

  static REQUEST_CUSTOMER_DATA_BY_ID =
    "CustomerDataAction.REQUEST_CUSTOMER_DATA_BY_ID";
  static REQUEST_CUSTOMER_DATA_BY_ID_FINISHED =
    "CustomerDataAction.REQUEST_CUSTOMER_DATA_BY_ID_FINISHED";

  static REQUEST_POST_CUSTOMER_DATA =
    "CustomerDataAction.REQUEST_POST_CUSTOMER_DATA";
  static REQUEST_POST_CUSTOMER_DATA_FINISHED =
    "CustomerDataAction.REQUEST_POST_CUSTOMER_DATA_FINISHED";

  static REQUEST_PUT_CUSTOMER_DATA =
    "CustomerDataAction.REQUEST_PUT_CUSTOMER_DATA";
  static REQUEST_PUT_CUSTOMER_DATA_FINISHED =
    "CustomerDataAction.REQUEST_PUT_CUSTOMER_DATA_FINISHED";

  static REQUEST_DELETE_CUSTOMER_DATA =
    "CustomerDataAction.REQUEST_DELETE_CUSTOMER_DATA";
  static REQUEST_DELETE_CUSTOMER_DATA_FINISHED =
    "CustomerDataAction.REQUEST_DELETE_CUSTOMER_DATA_FINISHED";

  //METHODS

  static requestCustomerData(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerDataAction.REQUEST_CUSTOMER_DATA,
        CustomerDataEffect.requestCustomerData,
        params
      );
    };
  }

  static requestCustomerDataById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerDataAction.REQUEST_CUSTOMER_DATA_BY_ID,
        CustomerDataEffect.requestCustomerDataById,
        id
      );
    };
  }

  static requestPostCustomerData(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerDataAction.REQUEST_POST_CUSTOMER_DATA,
        CustomerDataEffect.requestPostCustomerData,
        data,
        callBackFunction
      );
    };
  }

  static requestPutCustomerData(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerDataAction.REQUEST_PUT_CUSTOMER_DATA,
        CustomerDataEffect.requestPutCustomerData,
        data,
        id
      );
    };
  }

  static requestDeleteCustomerData(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CustomerDataAction.REQUEST_DELETE_CUSTOMER_DATA,
        CustomerDataEffect.requestDeleteCustomerData,
        id
      );
    };
  }
}
