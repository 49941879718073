import { BaseModel } from "sjs-base-model";
import McnFeedsDataHitsModel from "./McnFeedsDataHitsModel";

//McnFeedsHitsModel
export default class McnFeedsHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ McnFeedsDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
