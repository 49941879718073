import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import CloudMailOrgDataModel from "./models/CloudMailOrgDataModel";

// Cloud Mail org Effect
export default class CloudMailOrgEffect {
  // Get method for the
  static async requestCloudMailOrg(params) {
    const endpoint = environment.api.cloudMailOrg;
    return EffectUtility.getToModel(CloudMailOrgDataModel, endpoint, params);
  }
}
