import BaseReducer from "../../utilities/BaseReducer";
import AmbitionBoxFeedsAction from "./AmbitionBoxFeedsAction";

// AmbitionBoxFeedsReduser  function
export default class AmbitionBoxFeedsReduser extends BaseReducer {
  initialState = {
    ambitionBoxFeeds: [],
  };
  [AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED_FINISHED](state, action) {
    return {
      ...state,
      ambitionBoxFeeds: action.payload,
    };
  }

  [AmbitionBoxFeedsAction.REQUEST_AMBITIONBOX_FEED_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ambitionBoxFeeds: action.payload,
    };
  }
}
