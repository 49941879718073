import { BaseModel } from "sjs-base-model";
import QuoraFeedsHitsModel from "./QuoraFeedsHitsModel";
import QuoraFeedsShardsModel from "./QuoraFeedsShardsModel";

// QuoraFeedsModel
export default class QuoraFeedsModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: QuoraFeedsShardsModel };
  current_page_no = 0;
  hits = { hits: QuoraFeedsHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
