import BaseReducer from "../../../utilities/BaseReducer";
import OrmPagesAction from "./OrmPagesAction";

// reducer function
export default class OrmPagesReducer extends BaseReducer {
  initialState = {
    ormPages: [],
  };

  [OrmPagesAction.REQUEST_ORM_PAGES_FINISHED](state, action) {
    return {
      ...state,
      ormPages: action.payload,
    };
  }
}
