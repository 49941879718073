import BaseReducer from "../../../utilities/BaseReducer";
import ConsumerAction from "./ConsumerAction";

// consumer reducer function
export default class ConsumerReducer extends BaseReducer {
  initialState = {
    consumer_feed: [],
  };

  [ConsumerAction.REQUEST_CONSUMER_FINISHED](state, action) {
    return {
      ...state,
      consumer_feed: action.payload,
    };
  }
}
