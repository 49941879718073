import ActionUtility from "../../../../utilities/ActionUtility";
import TelegramChannelEffect from "./TelegramChannelEffect";

export default class TelegramChannelAction {
  static REQUEST_GET_TELEGRAM_CHANNEL =
    "TelegramChannelAction.REQUEST_GET_TELEGRAM_CHANNEL";
  static REQUEST_GET_TELEGRAM_CHANNEL_FINISHED =
    "TelegramChannelAction.REQUEST_GET_TELEGRAM_CHANNEL_FINISHED";

  static REQUEST_POST_TELEGRAM_CHANNEL =
    "TelegramChannelAction.REQUEST_POST_TELEGRAM_CHANNEL";
  static REQUEST_POST_TELEGRAM_CHANNEL_FINISHED =
    "TelegramChannelAction.REQUEST_POST_TELEGRAM_CHANNEL_FINISHED";

  static REQUEST_PUT_TELEGRAM_CHANNEL =
    "TelegramChannelAction.REQUEST_PUT_TELEGRAM_CHANNEL";
  static REQUEST_PUT_TELEGRAM_CHANNEL_FINISHED =
    "TelegramChannelAction.REQUEST_PUT_TELEGRAM_CHANNEL_FINISHED";

  static REQUEST_SYNC_TELEGRAM_CHANNEL =
    "TelegramChannelAction.REQUEST_SYNC_TELEGRAM_CHANNEL";
  static REQUEST_SYNC_TELEGRAM_CHANNEL_FINISHED =
    "TelegramChannelAction.REQUEST_SYNC_TELEGRAM_CHANNEL_FINISHED";

  static REQUEST_FETCH_ALL_TELEGRAM_CHANNEL_MSGS =
    "TelegramChannelAction.REQUEST_FETCH_ALL_TELEGRAM_CHANNEL_MSGS";
  static REQUEST_FETCH_ALL_TELEGRAM_CHANNEL_MSGS_FINISHED =
    "TelegramChannelAction.REQUEST_FETCH_ALL_TELEGRAM_CHANNEL_MSGS_FINISHED";

  static REQUEST_FETCH_SELECTED_TELEGRAM_CHANNEL_MSGS =
    "TelegramChannelAction.REQUEST_FETCH_SELECTED_TELEGRAM_CHANNEL_MSGS";
  static REQUEST_FETCH_SELECTED_TELEGRAM_CHANNEL_MSGS_FINISHED =
    "TelegramChannelAction.REQUEST_FETCH_SELECTED_TELEGRAM_CHANNEL_MSGS_FINISHED";

  static requestGetTelegramChannel(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramChannelAction.REQUEST_GET_TELEGRAM_CHANNEL,
        TelegramChannelEffect.requestGetTelegramChannel,
        params,
        callback
      );
    };
  }

  static requestPostTelegramChannel(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramChannelAction.REQUEST_POST_TELEGRAM_CHANNEL,
        TelegramChannelEffect.requestPostTelegramChannel,
        data,
        callBackFunction
      );
    };
  }

  static requestPutTelegramChannel(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramChannelAction.REQUEST_PUT_TELEGRAM_CHANNEL,
        TelegramChannelEffect.requestPutTelegramChannel,
        data,
        id
      );
    };
  }

  static requestSyncTelegramChannel(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramChannelAction.REQUEST_SYNC_TELEGRAM_CHANNEL,
        TelegramChannelEffect.requestSyncTelegramChannel,
        params
      );
    };
  }

  static requestFetchAllTelegramChannelMsgs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramChannelAction.REQUEST_FETCH_ALL_TELEGRAM_CHANNEL_MSGS,
        TelegramChannelEffect.requestFetchAllTelegramChannelMsgs,
        params
      );
    };
  }
  static requestFetchSelectedTelegramChannelMsgs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TelegramChannelAction.REQUEST_FETCH_SELECTED_TELEGRAM_CHANNEL_MSGS,
        TelegramChannelEffect.requestFetchSelectedTelegramChannelMsgs,
        params
      );
    };
  }
}
