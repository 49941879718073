import ActionUtility from "../../../../utilities/ActionUtility";
import ResourceEffect from "./ResourceEffect";

export default class ResourceAction {
  // ResourceAction action with an function declaration
  static REQUEST_RESOURCE = "ResourceAction.REQUEST_RESOURCE";
  static REQUEST_RESOURCE_FINISHED = "ResourceAction.REQUEST_RESOURCE_FINISHED";

  static REQUEST_RESOURCE_FILTER = "ResourceAction.REQUEST_RESOURCE_FILTER";
  static REQUEST_RESOURCE_FILTER_FINISHED =
    "ResourceAction.REQUEST_RESOURCE_FILTER_FINISHED";

  static REQUEST_PUT_RESOURCE = "ResourceAction.REQUEST_PUT_RESOURCE";
  static REQUEST_PUT_RESOURCE_FINISHED =
    "ResourceAction.REQUEST_PUT_RESOURCE_FINISHED";

  static REQUEST_POST_RESOURCE = "ResourceAction.REQUEST_POST_RESOURCE";
  static REQUEST_POST_RESOURCE_FINISHED =
    "ResourceAction.REQUEST_POST_RESOURCE_FINISHED";

  // METHODS
  // Organization GET function
  static requestResource(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResourceAction.REQUEST_RESOURCE,
        ResourceEffect.requestResource,
        params
      );
    };
  }

  static requestResourceFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResourceAction.REQUEST_RESOURCE_FILTER,
        ResourceEffect.requestResourceFilter,
        params
      );
    };
  }
  static requestPutResource(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResourceAction.REQUEST_PUT_RESOURCE,
        ResourceEffect.requestPutResource,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostResource(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResourceAction.REQUEST_POST_RESOURCE,
        ResourceEffect.requestPostResource,
        data
      );
    };
  }
}
