import ActionUtility from "../../../utilities/ActionUtility";
import ManageRoleEffect from "./ManageRoleEffect";

export default class ManageRoleAction {
  //role filter
  static REQUEST_ROLE_FILTER = "ManageRoleAction.REQUEST_ROLE_FILTER";
  static REQUEST_ROLE_FILTER_FINISHED =
    "ManageRoleAction.REQUEST_ROLE_FILTER_FINISHED";

  // role filter for is template == true condition
  static REQUEST_ROLE_TEMPLATE_FILTER =
    "ManageRoleAction.REQUEST_ROLE_TEMPLATE_FILTER";
  static REQUEST_ROLE_TEMPLATE_FILTER_FINISHED =
    "ManageRoleAction.REQUEST_ROLE_TEMPLATE_FILTER_FINISHED";

  //METHODS

  //role filter get method
  static requestRoleFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ManageRoleAction.REQUEST_ROLE_FILTER,
        ManageRoleEffect.requestRoleFilter,
        params
      );
    };
  }

  static requestRoleTemplateTrueFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ManageRoleAction.REQUEST_ROLE_TEMPLATE_FILTER,
        ManageRoleEffect.requestRoleTemplateTrueFilter,
        params
      );
    };
  }
}
