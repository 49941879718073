import { Box, Grid } from "@mui/material";
import { Field, Form, Formik, getIn } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import MuiTextField from "../../../../../components/muiTextField/MuiTextField";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";
import CustomerDataAction from "../../../../../stores/ticketCustomerData/CustomerDataAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";

// mapstateto props function fetching both project api and category api
let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    // TicketsAction.REQUEST_PUT_TICKETS,
    // CustomerDataAction.REQUEST_CUSTOMER_DATA_BY_ID,
  ]),
  twitter: state.twitter.twitter.hits || {},
  // customerById: state.customerById.customerById || {},
});

const CustomerPersonalDetailsTab = (props) => {
  const [loading, setLoading] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const aadharRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const customerValidationSchema = yup.object({
    customer_phone: yup
      .string("Invalid")
      .matches(phoneRegExp, "Invalid")
      .length(10, "Invalid")
      .nullable(),

    adhar_number: yup
      .string("Invalid")
      // .matches(aadharRegExp, "Invalid")
      .length(12, "Invalid")
      .nullable(),

    zipcode: yup
      .string("Invalid")
      // .matches(aadharRegExp, "Invalid")
      .length(6, "Invalid")
      .nullable(),

    customer_email: yup
      .string("Invalid")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
    // .email("Invalid Email address"),
    // .matches(aadharRegExp, "Invalid")
  });

  let customer_initial_values = {};

  if (
    props.ticketData &&
    props.ticketData._source &&
    props.ticketData._source.customer_id
  ) {
    customer_initial_values = {
      customer_name:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.customer_name
          ? props.ticketData._source.customer_id.customer_name !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.customer_name !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.customer_name
            : ""
          : "",
      customer_location:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.customer_location
          ? props.ticketData._source.customer_id.customer_location !== "" &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.customer_location !== null
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.customer_location
            : ""
          : "",

      customer_email:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.customer_email
          ? props.ticketData._source.customer_id.customer_email !== "" &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.customer_email !== null
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.customer_email
            : ""
          : "",

      customer_phone:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.customer_phone
          ? props.ticketData._source.customer_id.customer_phone !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.customer_phone !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.customer_phone
            : ""
          : "",
      gender:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.gender
          ? props.ticketData._source.customer_id.gender !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.gender !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.gender
            : ""
          : "",

      current_address:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.current_address
          ? props.ticketData._source.customer_id.current_address !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.current_address !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.current_address
            : ""
          : "",
      permanent_address:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.permanent_address
          ? props.ticketData._source.customer_id.permanent_address !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.permanent_address !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.permanent_address
            : ""
          : "",
      zipcode:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.zipcode
          ? props.ticketData._source.customer_id.zipcode !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.zipcode !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.zipcode
            : ""
          : "",
      city:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.city
          ? props.ticketData._source.customer_id.city !== "" &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.city !== null
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.city
            : ""
          : "",
      state:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.state
          ? props.ticketData._source.customer_id.state !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.state !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.state
            : ""
          : "",
      country:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.country
          ? props.ticketData._source.customer_id.country !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.country !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.country
            : ""
          : "",
      adhar_number:
        props.ticketData._source.customer_id &&
        props.ticketData._source.customer_id.adhar_number
          ? props.ticketData._source.customer_id.adhar_number !== null &&
            props.ticketData._source.customer_id &&
            props.ticketData._source.customer_id.adhar_number !== ""
            ? props.ticketData._source.customer_id &&
              props.ticketData._source.customer_id.adhar_number
            : ""
          : "",
    };
  } else {
    customer_initial_values = {
      customer_name: "",
      customer_location: "",
      customer_email: "",
      customer_phone: "",
      gender: "",
      current_address: "",
      permanent_address: "",
      zipcode: "",
      city: "",
      state: "",
      country: "",
      adhar_number: "",
    };
  }

  const handleGenderKeyPress = (event) => {
    const keyCode = event.which;
    // Regular expression pattern to exclude numbers and special characters
    const pattern = /^[^0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

    // Validate input against the pattern
    if ((keyCode >= 48 && keyCode <= 57) || !pattern.test(event.key)) {
      // Prevent input of numbers (0-9) and special characters
      event.preventDefault();
    }
  };

  const handlePhoneKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        padding: 3,
        height: "100%",
      }}
      className="scrollable"
    >
      <Box width={"100%"}>
        <Formik
          initialValues={customer_initial_values}
          enableReinitialize={true}
          validationSchema={customerValidationSchema}
          onSubmit={(values, formikHelpers) => {
            // setLoading(true);

            let Obj = {};

            Object.assign(
              Obj,
              values.city && { city: values.city },
              values.country && { country: values.country },
              values.current_address && {
                current_address: values.current_address,
              },
              values.customer_email && {
                customer_email: values.customer_email,
              },
              values.gender && {
                gender: values.gender,
              },
              values.customer_location && {
                customer_location: values.customer_location,
              },
              values.customer_name && { customer_name: values.customer_name },
              values.customer_phone && {
                customer_phone: values.customer_phone,
              },
              values.permanent_address && {
                permanent_address: values.permanent_address,
              },
              values.state && { state: values.state },
              values.zipcode && { zipcode: values.zipcode.toString() },
              values.adhar_number && {
                adhar_number: values.adhar_number.toString(),
              }
            );

            if (props.ticketData._source.customer_id) {
              props
                .dispatch(
                  CustomerDataAction.requestPutCustomerData(
                    JSON.stringify(Obj),
                    props.ticketData._source &&
                      props.ticketData._source.customer_id &&
                      props.ticketData._source.customer_id.customer_id
                  )
                )
                .then(() => {
                  setLoading(false);
                  props.dispatch(
                    TicketsAction.requestTicketsById(props.ticketData._id)
                  );
                });
            } else {
              const callBackFunction = (id) => {
                let data = { customer_id: id };
                props
                  .dispatch(
                    TicketsAction.requestPutTicket(
                      JSON.stringify(data),
                      props.ticketData._id
                    )
                  )
                  .then(() => {
                    props.dispatch(
                      TicketsAction.requestTicketsById(props.ticketData._id)
                    );

                    // let params = {
                    //   filters: `[${props.filters}]`,
                    //   page_limit: `15`,
                    //   order_by: '[["modified_on", "desc"]]',
                    // };
                    // props.dispatch(TicketsAction.requestTickets(params));
                  });
              };

              props
                .dispatch(
                  CustomerDataAction.requestPostCustomerData(
                    JSON.stringify(Obj),
                    callBackFunction
                  )
                )
                .then(() => {
                  setLoading(false);
                });
            }
          }}
          validateOnBlur={true}
          validateOnChange
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                // className="bg-warning"
              >
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="customer_name"
                    as={MuiTextField}
                    placeholder="Name"
                    type="text"
                    // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="customer_phone"
                    as={MuiTextField}
                    maxLength={10}
                    placeholder="Phone Number"
                    type="text"
                    inputMode="numeric"
                    handleKeyPress={handlePhoneKeyPress}
                    // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    size="small"
                    error={Boolean(
                      getIn(touched, "customer_phone") &&
                        getIn(errors, "customer_phone")
                    )}
                    helperText={
                      getIn(touched, "customer_phone") &&
                      getIn(errors, "customer_phone")
                    }
                  />
                </Grid>
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  my={0.5}

                  // className="bg-warning"
                >
                  <Field
                    name="customer_email"
                    as={MuiTextField}
                    placeholder="Email"
                    // value={values.customer.customer_email}
                    size="small"
                    type="text"
                    error={Boolean(
                      getIn(touched, "customer_email") &&
                        getIn(errors, "customer_email")
                    )}
                    helperText={
                      getIn(touched, "customer_email") &&
                      getIn(errors, "customer_email")
                    }
                  />
                </Grid>
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  my={0.5}

                  // className="bg-warning"
                >
                  <Field
                    name="gender"
                    as={MuiTextField}
                    placeholder="Gender"
                    size="small"
                    type="text"
                    inputMode="text"
                    handleKeyPress={handleGenderKeyPress}
                  />
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  my={0.5}

                  // className="bg-warning"
                >
                  <Field
                    name="current_address"
                    as={MuiTextField}
                    placeholder="Address 1"
                    size="small"
                    type="text"
                  />
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  my={0.5}

                  // className="bg-warning"
                >
                  <Field
                    name="permanent_address"
                    as={MuiTextField}
                    placeholder="Address 2"
                    size="small"
                    type="text"
                  />
                </Grid>
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="customer_location"
                    as={MuiTextField}
                    placeholder="Location"
                    size="small"
                    type="text"
                  />
                </Grid>
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="zipcode"
                    as={MuiTextField}
                    placeholder="Zipcode"
                    size="small"
                    type="text"
                    inputMode="numeric"
                    maxLength={6}
                    handleKeyPress={handlePhoneKeyPress}
                    error={Boolean(
                      getIn(touched, "zipcode") && getIn(errors, "zipcode")
                    )}
                    helperText={
                      getIn(touched, "zipcode") && getIn(errors, "zipcode")
                    }

                    // inputProps={{
                    //   inputMode: "numeric",
                    //   pattern: "[0-9]*",
                    // }}
                  />
                </Grid>
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="city"
                    as={MuiTextField}
                    placeholder="City"
                    size="small"
                    type="text"
                    inputMode="text"
                    handleKeyPress={handleGenderKeyPress}
                  />
                </Grid>{" "}
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="state"
                    as={MuiTextField}
                    placeholder="State"
                    size="small"
                    type="text"
                    inputMode="text"
                    handleKeyPress={handleGenderKeyPress}
                  />
                </Grid>{" "}
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="country"
                    as={MuiTextField}
                    placeholder="Country"
                    size="small"
                    type="text"
                    inputMode="text"
                    handleKeyPress={handleGenderKeyPress}
                  />
                </Grid>{" "}
                <Grid
                  item
                  xl={5.8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  // className="bg-warning"
                  my={0.5}
                >
                  <Field
                    name="adhar_number"
                    as={MuiTextField}
                    placeholder="AWB/ Aadhaar Number"
                    size="small"
                    type="text"
                    inputMode="numeric"
                    maxLength={12}
                    handleKeyPress={handlePhoneKeyPress}
                    error={Boolean(
                      getIn(touched, "adhar_number") &&
                        getIn(errors, "adhar_number")
                    )}
                    helperText={
                      getIn(touched, "adhar_number") &&
                      getIn(errors, "adhar_number")
                    }
                  />
                </Grid>
              </Grid>

              <Box my={2} textAlign="left">
                <MuiButton
                  variant="contained"
                  width={"20%"}
                  loading={loading}
                  type="submit"
                  name={"Update"}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(CustomerPersonalDetailsTab);
