import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import {
  BorderContainer,
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../../components/componentLayout/ComponentLayout";
import CircularLoadingIndicator from "../../../../../components/loading-indicator/CircularLoadingIndicator";
import AutoCompleteComponent from "../../../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../../../components/muiButton/MuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiSelectField from "../../../../../components/muiSelectField/MuiSelectField";
import { returnLogo } from "../../../../../components/ticketPartials";
import MuiAutocomplete from "../../../../../components/muiSelectField/MuiAutocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TagFilterPopper(props) {
  const [tags, setTags] = useState(() => {
    if (localStorage.getItem("mentionsFilters")) {
      return JSON.parse(localStorage.getItem("mentionsFilters")).tags;
    } else {
      return [];
    }
  });

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
    if (filters) {
      setTags(filters.tags && filters.tags);
    }
  }, [props.open]);

  const tagsHandleChange = (event, newValue) => {
    if (newValue.length === 0) {
      setTags([]);
      return;
    }

    const selectedOption = newValue[newValue.length - 1];
    const isPresent = tags.some((obj) => obj === selectedOption);
    let updatedValue = [];
    if (isPresent) {
      updatedValue = tags.filter((obj) => obj !== selectedOption);
    } else {
      updatedValue = [...tags, selectedOption];
    }
    setTags(updatedValue);
  };

  const handleDeleteTags = (tag) => {
    const updatedTags = tags.filter((obj) => obj !== tag);
    setTags(updatedTags);
  };

  let [tagsError, settagsError] = useState(false);

  async function handleSaveFilters() {
    if (tags && tags.length === 0) {
      settagsError(true);

      setTimeout(() => {
        settagsError(false);
      }, 1000);
    } else {
      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));

      if (filters) {
        filters.tags = tags;
        filters.pageNumber = `0`;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            tags,
          })
        );
      }

      props.saveFilter(filters);
      props.fetchTicketCount();
    }
  }

  function handleClearFilters() {
    if (tags && tags.length !== 0) {
      setTags([]);
      props.clearFilter("tags");
      localStorage.removeItem("mentionsFilters");
    }
  }

  return (
    <BorderContainer>
      <ClickAwayListener onClickAway={props.handleClose}>
        <div
          autoFocusItem={props.popopen}
          id="composition-menu"
          aria-labelledby="composition-button"
        >
          <Grid
            container
            sx={{
              height: 250,
            }}
          >
            <Typography
              component={"p"}
              className="dashboard-title"
              sx={{ fontSize: 18 }}
            >
              Tags
            </Typography>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              sx={{
                //marginTop: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                sx={{
                  animation: tagsError && "bounce 0.5s linear",
                  "@keyframes bounce": {
                    "0%, 20%, 50%, 80%, 100%": {
                      transform: "translateY(0)",
                    },
                    "40%": {
                      transform: "translateY(-30px)",
                    },
                    "60%": {
                      transform: "translateY(-15px)",
                    },
                  },
                }}
              >
                <AutoCompleteComponent
                  width="100%"
                  value={tags}
                  name="tags"
                  textFieldProps={{
                    placeholder: tags && tags.length !== 0 ? null : "Tag Name",
                  }}
                  //disabled={!platform}
                  //limitTags={1}
                  list={props.filterTagList ? props.filterTagList : []}
                  handleChange={tagsHandleChange}
                  multiple
                  disableCloseOnSelect={true}
                  getOptionLabel={(option) =>
                    //option.tag_name ? option.tag_name : ""
                    option ? option : ""
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        variant="outlined"
                        size="small"
                        className="mx-1 "
                        onDelete={() => handleDeleteTags(option)}
                      />
                    ))
                  }
                  renderOption={(props, option) => {
                    const isPresent = tags.some((obj) => obj === option);

                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={isPresent}
                        />
                        {option}
                      </li>
                    );
                  }}
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              laptop={12}
              columnGap={2}
              rowGap={1}
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item>
                <MuiButton
                  variant="outlined"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Reset"}
                  loading={false}
                  backgroundColor={"white"}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleClearFilters();
                  }}
                />
              </Grid>
              <Grid item>
                <MuiButton
                  variant="contained"
                  width={"103px"}
                  height={"35px"}
                  type="submit"
                  name={"Apply"}
                  loading={false}
                  noTextOnLoading={true}
                  onClick={() => {
                    handleSaveFilters();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ClickAwayListener>
    </BorderContainer>
  );
}

export default TagFilterPopper;
