import ActionUtility from "../../../utilities/ActionUtility";
import TwitterEffect from "./TwitterEffect";

// twitter api fetch model
export default class TwitterAction {
  // project action with an function declaration
  static REQUEST_TWITTER = "TwitterAction.REQUEST_TWITTER";
  static REQUEST_TWITTER_FINISHED = "TwitterAction.REQUEST_TWITTER_FINISHED";

  // Twitter filter
  static REQUEST_TWITTER_FILTER = "TwitterAction.REQUEST_TWITTER_FILTER";
  static REQUEST_TWITTER_FILTER_FINISHED =
    "TwitterAction.REQUEST_TWITTER_FILTER_FINISHED";

  static REQUEST_PUT_TWITTER_FEEDS = "TwitterAction.REQUEST_PUT_TWITTER_FEEDS";
  static REQUEST_PUT_TWITTER_FEEDS_FINISHED =
    "TwitterAction.REQUEST_PUT_TWITTER_FEEDS_FINISHED";

  static REQUEST_TWITTER_FEEDS_CLEAR =
    "TwitterAction.REQUEST_TWITTER_FEEDS_CLEAR";
  static REQUEST_TWITTER_FEEDS_CLEAR_FINISHED =
    "TwitterAction.REQUEST_TWITTER_FEEDS_CLEAR_FINISHED";

  static REQUEST_TWITTER_POST = "TwitterAction.REQUEST_TWITTER_POST";
  static REQUEST_TWITTER_POST_FINISHSED =
    "TwitterAction.REQUEST_TWITTER_POST_FINISHSED";

  static REQUEST_TWEET_DELETE = "TwitterAction.REQUEST_TWEET_DELETE";
  static REQUEST_TWEET_DELETE_FINISHED =
    "TwitterAction.REQUEST_TWEET_DELETE_FINISHED";

  // METHODS
  // Project GET function
  static requestTwitter(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterAction.REQUEST_TWITTER,
        TwitterEffect.requestTwitter,
        params,
        callBackFunction
      );
    };
  }

  static requestTwitterFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterAction.REQUEST_TWITTER_FILTER,
        TwitterEffect.requestTwitterFilter,
        params
      );
    };
  }

  // TAG post function
  static requstPostTwitter(params, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterAction.REQUEST_TWITTER_POST,
        TwitterEffect.requstPostTwitter,
        params,
        data
      );
    };
  }

  static requestPutTwitterFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterAction.REQUEST_PUT_TWITTER_FEEDS,
        TwitterEffect.requestPutTwitterFeeds,
        data,
        id
      );
    };
  }

  static requestTwitterFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterAction.REQUEST_TWITTER_FEEDS_CLEAR,
        TwitterEffect.requestTwitterFeedClear
      );
    };
  }

  static requestTwitterFeedClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterAction.REQUEST_TWITTER_FEEDS_CLEAR,
        TwitterEffect.requestTwitterFeedClear
      );
    };
  }

  static requestTweetDelete(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TwitterAction.REQUEST_TWEET_DELETE,
        TwitterEffect.requestTweetDelete,
        id
      );
    };
  }
}
