import {
  ClickAwayListener,
  Grid,
  Grow,
  ListItem,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import FacebookAction from "../../../../stores/facebook/FacebookAction";
import TicketsAction from "../../../../stores/tickets/TicketsAction";
import RetweetAction from "../../../../stores/retweet/RetweetAction";
import { theme } from "../../../../../views/App";
import InstagramFeedsAction from "../../../../stores/instagramFeeds/InstagramFeedsAction";
import TwitterAction from "../../../../stores/twitterFeeds/TwitterAction";
import UnRetweetAction from "../../../../stores/unRetweet/UnRetweetAction";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

const useStyles = makeStyles({
  gridContainer: {
    height: "auto", // set the height of the grid container
    overflow: "auto", // add scrollbars when necessary
    "&::-webkit-scrollbar": {
      display: "none", // hide the scrollbar for webkit-based browsers (e.g. Chrome, Safari)
    },
    "-ms-overflow-style": "none", // hide the scrollbar for Internet Explorer and Edge
    "scrollbar-width": "none", // hide the scrollbar for Firefox and other browsers that support the new scrollbar style
  },
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

const ThreeDotsPopper = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [feedComponentPoperOpen, setFeedComponentPoperOpen] =
    React.useState(false);
  const feedComponentPoperRef = React.useRef(null);
  const scrollRef = useRef(null);
  const prevOpen = React.useRef(feedComponentPoperOpen);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && feedComponentPoperOpen === false) {
      feedComponentPoperRef.current.focus();
    }
    prevOpen.current = feedComponentPoperOpen;
  }, [feedComponentPoperOpen]);

  const handleNotificationPopper = () => {
    setFeedComponentPoperOpen((prevOpen) => !prevOpen);
  };

  const handleCloseFeedComponentPoper = (event) => {
    if (
      feedComponentPoperRef.current &&
      feedComponentPoperRef.current.contains(event.target)
    ) {
      return;
    }
    setFeedComponentPoperOpen(false);
  };

  const handleFacebookCommentHide = (props) => {
    let commentId = props.comment_id && props.comment_id;

    let post_obj = {};
    if (props.is_hidden) {
      post_obj = { is_hidden: false };
    } else {
      post_obj = { is_hidden: true };
    }

    props
      .dispatch(
        FacebookAction.requestFacebookCommentHidePost(
          commentId,
          JSON.stringify(post_obj)
        )
      )
      .then(() => {
        let filter = [];
        filter.push(
          `[["comment_id.keyword","must","","terms",${JSON.stringify(
            props.ticketData._source.feed_id
              ? props.ticketData._source.feed_id
              : []
          )}]]`
        );

        let param = {
          // comments: props.ticketData._source && props.ticketData._source.feed_id,
          filters: `${filter}`,
          page_limit: "none",
        };
        props.dispatch(FacebookAction.requestFacebook(param));
        props.dispatch(TicketsAction.requestTicketsById(props.ticketData._id));
      });
  };

  function handleInstagramCommentHide(feed) {
    let Id = feed._id && feed._id;

    let post_obj = {};
    if (feed._source.comment_is_hidden) {
      post_obj = { hide: false };
    } else {
      post_obj = { hide: true };
    }

    props
      .dispatch(
        InstagramFeedsAction.requestInstagramCommentHide(
          Id,
          JSON.stringify(post_obj)
        )
      )
      .then(() => {
        let filter = [];

        filter.push(
          `[["feed_id.keyword","must","","terms",${JSON.stringify(
            props.ticketData._source.feed_id
              ? props.ticketData._source.feed_id
              : []
          )}]]`
        );
        let params = {
          filters: `${filter}`,
          order_by: '[["created_on", "desc"]]',
          page_limit: "none",
        };
        props.dispatch(InstagramFeedsAction.requestInstagramFeeds(params));
        props.dispatch(TicketsAction.requestTicketsById(props.ticketData._id));
      });
  }

  function DeleteCommentInFacebook(commentId) {
    props
      .dispatch(FacebookAction.requestFacebookCommentDelete(commentId))
      .then(() => {
        let filter = [];
        filter.push(
          `[["comment_id.keyword","must","","terms",${JSON.stringify(
            props.ticketData._source.feed_id
              ? props.ticketData._source.feed_id
              : []
          )}]]`
        );

        let param = {
          // comments: props.ticketData._source && props.ticketData._source.feed_id,
          filters: `${filter}`,
          page_limit: "none",
        };
        props.dispatch(FacebookAction.requestFacebook(param));

        props.dispatch(TicketsAction.requestTicketsById(props.ticketData._id));
      });
  }

  function DeleteCommentInInstagram(commentId) {
    props
      .dispatch(InstagramFeedsAction.requestInstagramCommentDelete(commentId))
      .then(() => {
        props.dispatch(TicketsAction.requestTicketsById(props.ticketData._id));
      });
  }

  function fetchTwitter() {
    let filter = [];

    filter.push(
      `[["tweet_id.keyword","must","","match","${
        props.ticketData._source.feed_id
          ? props.ticketData._source.feed_id
          : null
      }"]]`
    );
    let param = {
      filters: `${filter}`,
    };
    props.dispatch(TwitterAction.requestTwitter(param));
  }

  function fetchRetweet(id, params) {
    props.dispatch(RetweetAction.requestRetweet(id, params)).then(() => {
      fetchTwitter();
    });
  }

  function fetchUnRetweet(id, params) {
    props.dispatch(UnRetweetAction.requestUnRetweet(id, params)).then(() => {
      fetchTwitter();
    });
  }

  const handleTweet = () => {
    let params = {
      account_name:
        props.ticketData._source.account_name &&
        props.ticketData._source.account_name.account_name,
    };
    if (props.retweeted) {
      fetchUnRetweet(props.tweet_id, params);
    } else {
      fetchRetweet(props.tweet_id, params);
    }
  };

  // AI Suggestion codes
  const {
    openAIsuggestionTab,
    setOpenAIsuggestionTab,
    aiSuggestionTab,
    setAiSuggestionTab,
  } = props;

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={feedComponentPoperRef}
        id="composition-button"
        aria-controls={feedComponentPoperOpen ? "composition-menu" : undefined}
        aria-expanded={feedComponentPoperOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleNotificationPopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={feedComponentPoperOpen}
        anchorEl={feedComponentPoperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{
          zIndex: 200,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 150,
                height: "auto",
                boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleCloseFeedComponentPoper}>
                <div
                  autoFocusItem={feedComponentPoperOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  style={{ display: "flex", backgroundColor: "white" }}
                >
                  <Grid
                    item
                    sx={{
                      padding: 1,
                    }}
                    gap={1}
                    className={classes.gridContainer}
                    ref={scrollRef}
                  >
                    {/* <Typography
                      component={"div"}
                      sx={{
                        // marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "left",
                        // fontWeight:
                        //   props.createdDatelistSelected ===
                        //   item
                        //     ? "bold"
                        //     : "none",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={() => {}}
                    >
                      <ListItem className="p-1 m-0 ">
                        <DeleteForeverIcon
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <p
                          className="m-0 p-1"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          Delete From Aiplex Bridge
                        </p>
                      </ListItem>
                    </Typography> */}

                    <Typography
                      component={"div"}
                      sx={{
                        // marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "left",
                        // fontWeight:
                        //   props.createdDatelistSelected ===
                        //   item
                        //     ? "bold"
                        //     : "none",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={(e) => {
                        props.setTranslate();
                      }}
                    >
                      <ListItem className="p-1 m-0">
                        <GTranslateIcon
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color: props.translated
                              ? theme.palette.primary.main
                              : `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <span
                          className="m-0 p-0"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          See Translation
                        </span>
                      </ListItem>
                    </Typography>

                    {/* AI SUGGESTIONS */}
                    <Typography
                      component={"div"}
                      sx={{
                        cursor: "pointer",
                        textAlign: "left",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={(e) => {
                        sessionStorage.setItem(
                          "ai_suggestion_feed_id",
                          JSON.stringify({
                            feed_id: props.feed?._id,
                            tab: "open_ai",
                          })
                        );
                        setOpenAIsuggestionTab(props.feed?._id);
                        setAiSuggestionTab("open_ai");
                      }}
                    >
                      <ListItem className="p-1 m-0">
                        <LiveHelpIcon
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color:
                              openAIsuggestionTab &&
                              JSON.parse(
                                sessionStorage.getItem("ai_suggestion_feed_id")
                              )?.feed_id === props.feed?._id &&
                              JSON.parse(
                                sessionStorage.getItem("ai_suggestion_feed_id")
                              )?.tab === "open_ai"
                                ? theme.palette.primary.main
                                : `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <span
                          className="m-0 p-0"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          Open AI Suggestion
                        </span>
                      </ListItem>
                    </Typography>

                    {/* BARD AI Suggestions */}
                    <Typography
                      component={"div"}
                      sx={{
                        cursor: "pointer",
                        textAlign: "left",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={(e) => {
                        sessionStorage.setItem(
                          "ai_suggestion_feed_id",
                          JSON.stringify({
                            feed_id: props.feed?._id,
                            tab: "bard",
                          })
                        );
                        setOpenAIsuggestionTab(props.feed?._id);
                        setAiSuggestionTab("bard");
                      }}
                    >
                      <ListItem className="p-1 m-0">
                        <LiveHelpIcon
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color:
                              openAIsuggestionTab &&
                              JSON.parse(
                                sessionStorage.getItem("ai_suggestion_feed_id")
                              )?.feed_id === props.feed?._id &&
                              JSON.parse(
                                sessionStorage.getItem("ai_suggestion_feed_id")
                              )?.tab === "bard"
                                ? theme.palette.primary.main
                                : `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <span
                          className="m-0 p-0"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          Google AI Suggestion
                        </span>
                      </ListItem>
                    </Typography>

                    {/* <Typography
                      component={"div"}
                      sx={{
                        // marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "left",
                        // fontWeight:
                        //   props.createdDatelistSelected ===
                        //   item
                        //     ? "bold"
                        //     : "none",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={() => {}}
                    >
                      <ListItem className="p-1 m-0">
                        <SubscriptionsIcon
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <p
                          className="m-0 p-0"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          Subscribe
                        </p>
                      </ListItem>
                    </Typography> */}

                    <Typography
                      component={"div"}
                      sx={{
                        // marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "left",
                        // fontWeight:
                        //   props.createdDatelistSelected ===
                        //   item
                        //     ? "bold"
                        //     : "none",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={() => {
                        navigate("/createTicket");
                      }}
                    >
                      {/* <ListItem className="p-1 m-0">
                        <FontAwesomeIcon
                          //   className="bg-warning"
                          icon={faTicket}
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        ></FontAwesomeIcon>
                        <p
                          className="m-0 p-0"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          Create Ticket
                        </p>
                      </ListItem> */}
                    </Typography>

                    {props.ticketData &&
                      props.ticketData._source.platform.platform_name ===
                        "Facebook" &&
                      props.comment_id && (
                        <>
                          <Typography
                            component={"div"}
                            sx={{
                              // marginBottom: 2,
                              cursor: "pointer",
                              textAlign: "left",
                              // fontWeight:
                              //   props.createdDatelistSelected ===
                              //   item
                              //     ? "bold"
                              //     : "none",
                              "&:hover": {
                                backgroundColor: `${theme.palette.background.primary}`,
                              },
                            }}
                            onClick={() => {
                              handleFacebookCommentHide(props);
                            }}
                          >
                            <ListItem className="p-1 m-0">
                              {/* <OpenInNewIcon
                                              style={{
                                                width: "16px",
                                                marginRight: 4,
                                                color: `${theme.palette.ticketListView.iconColor}`,
                                              }}
                                            /> */}
                              {props.is_hidden ? (
                                <FontAwesomeIcon
                                  //   className="bg-warning"
                                  icon={faEyeSlash}
                                  style={{
                                    width: "16px",
                                    marginRight: 4,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                ></FontAwesomeIcon>
                              ) : (
                                <FontAwesomeIcon
                                  //   className="bg-warning"
                                  icon={faEye}
                                  style={{
                                    width: "16px",
                                    marginRight: 4,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                ></FontAwesomeIcon>
                              )}
                              {props.is_hidden ? (
                                <span
                                  className="m-0 p-0"
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                >
                                  UnHide From Facebook
                                </span>
                              ) : (
                                <span
                                  className="m-0 p-0"
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                >
                                  Hide From Facebook
                                </span>
                              )}
                            </ListItem>
                          </Typography>

                          <Typography
                            component={"div"}
                            sx={{
                              opacity: props.is_deleted ? 0.5 : 1,
                              pointerEvents: props.is_deleted && "none",
                              // marginBottom: 2,
                              cursor: "pointer",
                              textAlign: "left",
                              // fontWeight:
                              //   props.createdDatelistSelected ===
                              //   item
                              //     ? "bold"
                              //     : "none",
                              "&:hover": {
                                backgroundColor: `${theme.palette.background.primary}`,
                              },
                            }}
                            onClick={() => {
                              DeleteCommentInFacebook(props.comment_id);
                            }}
                          >
                            <ListItem className="p-1 m-0">
                              <DeleteIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                style={{
                                  width: "16px",
                                  marginRight: 4,
                                  color: `${theme.palette.ticketListView.iconColor}`,
                                }}
                              />

                              {props.is_deleted ? (
                                <Typography
                                  component={"span"}
                                  className="m-0 p-0"
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                >
                                  Deleted From Facebook
                                </Typography>
                              ) : (
                                <Typography
                                  component={"span"}
                                  className="m-0 p-0"
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                  sx={{
                                    border: "none",
                                  }}
                                >
                                  Delete From Facebook
                                </Typography>
                              )}
                            </ListItem>
                          </Typography>
                        </>
                      )}

                    {props.ticketData &&
                      props.ticketData._source.platform.platform_name ===
                        "Instagram" &&
                      props.feedData &&
                      props.feedData._source &&
                      props.feedData._source.activities &&
                      props.feedData._source.activities === "Comments" &&
                      props.feedData._id && (
                        <>
                          <Typography
                            component={"div"}
                            sx={{
                              cursor: "pointer",
                              textAlign: "left",
                              "&:hover": {
                                backgroundColor: `${theme.palette.background.primary}`,
                              },
                            }}
                            onClick={() => {
                              handleInstagramCommentHide(props.feedData);
                            }}
                          >
                            <ListItem className="p-1 m-0">
                              {props.is_hidden ? (
                                <FontAwesomeIcon
                                  //   className="bg-warning"
                                  icon={faEyeSlash}
                                  style={{
                                    width: "16px",
                                    marginRight: 4,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                ></FontAwesomeIcon>
                              ) : (
                                <FontAwesomeIcon
                                  //   className="bg-warning"
                                  icon={faEye}
                                  style={{
                                    width: "16px",
                                    marginRight: 4,
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                ></FontAwesomeIcon>
                              )}
                              {props.is_hidden ? (
                                <span
                                  className="m-0 p-0"
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                >
                                  UnHide From Instagram
                                </span>
                              ) : (
                                <span
                                  className="m-0 p-0"
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                >
                                  Hide From Instagram
                                </span>
                              )}
                            </ListItem>
                          </Typography>

                          {props.feedData._source.feed_id && (
                            <Typography
                              component={"div"}
                              sx={{
                                opacity: props.is_deleted ? 0.5 : 1,
                                pointerEvents: props.is_deleted && "none",
                                // marginBottom: 2,
                                cursor: "pointer",
                                textAlign: "left",
                                // fontWeight:
                                //   props.createdDatelistSelected ===
                                //   item
                                //     ? "bold"
                                //     : "none",
                                "&:hover": {
                                  backgroundColor: `${theme.palette.background.primary}`,
                                },
                              }}
                              onClick={() => {
                                if (props.is_deleted === false) {
                                  DeleteCommentInInstagram(
                                    props.feedData._source.feed_id
                                  );
                                }
                              }}
                            >
                              <ListItem className="p-1 m-0">
                                {props && props.is_deleted === false ? (
                                  <DeleteIcon
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    style={{
                                      width: "16px",
                                      marginRight: 4,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                ) : (
                                  <DeleteForeverSharpIcon
                                    style={{
                                      width: "13px",
                                      // marginRight: 2,
                                      color: `${theme.palette.ticketListView.iconColor}`,
                                    }}
                                  />
                                )}

                                <span
                                  className="m-0 p-0"
                                  style={{
                                    color: `${theme.palette.ticketListView.iconColor}`,
                                  }}
                                >
                                  {props.is_deleted === false
                                    ? "Delete From Instagram"
                                    : "Deleted From Instagram"}
                                </span>
                              </ListItem>
                            </Typography>
                          )}
                        </>
                      )}

                    {/* {props.tweet_id && (
                      <Typography
                        component={"div"}
                        sx={{
                          // marginBottom: 2,
                          cursor: "pointer",
                          textAlign: "left",
                          // fontWeight:
                          //   props.createdDatelistSelected ===
                          //   item
                          //     ? "bold"
                          //     : "none",
                          "&:hover": {
                            backgroundColor: `${theme.palette.background.primary}`,
                          },
                        }}
                        onClick={() => {
                          handleTweet();
                        }}
                      >
                        <ListItem className="p-1 m-0">
                          <FontAwesomeIcon
                            icon={faRetweet}
                            style={{
                              width: "16px",
                              marginRight: 4,
                              color: `${theme.palette.ticketListView.iconColor}`,
                            }}
                          ></FontAwesomeIcon>
                          {props.retweeted ? (
                            <p
                              className="m-0 p-0"
                              style={{
                                color: `${theme.palette.ticketListView.iconColor}`,
                              }}
                            >
                              UnRetweet
                            </p>
                          ) : (
                            <p
                              className="m-0 p-0"
                              style={{
                                color: `${theme.palette.ticketListView.iconColor}`,
                              }}
                            >
                              ReTweet
                            </p>
                          )}
                        </ListItem>
                      </Typography>
                    )} */}

                    {/* <Typography
                      component={"div"}
                      sx={{
                        // marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "left",
                        // fontWeight:
                        //   props.createdDatelistSelected ===
                        //   item
                        //     ? "bold"
                        //     : "none",
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={() => {}}
                    >
                      <ListItem className="p-1 m-0">
                        <AttachFileIcon
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <p
                          className="m-0 p-0"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          Attach Ticket
                        </p>
                      </ListItem>
                    </Typography>

                    <Typography
                      component={"div"}
                      sx={{
                        // marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "left",
                        // fontWeight:
                        //   props.createdDatelistSelected ===
                        //   item
                        //     ? "bold"
                        //     : "none",
                        color: `${theme.palette.ticketListView.iconColor}`,
                        "&:hover": {
                          backgroundColor: `${theme.palette.background.primary}`,
                        },
                      }}
                      onClick={() => {}}
                    >
                      <ListItem className="p-1 m-0">
                        <DeviceHubIcon
                          style={{
                            width: "16px",
                            marginRight: 4,
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        />
                        <p
                          className="m-0 p-0"
                          style={{
                            color: `${theme.palette.ticketListView.iconColor}`,
                          }}
                        >
                          Escalate
                        </p>
                      </ListItem>
                    </Typography> */}
                  </Grid>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default connect(mapDispatchToProps)(ThreeDotsPopper);
