import ActionUtility from "../../../../utilities/ActionUtility";
import SurveyUserEffect from "./SurveyUserEffect";

export default class SurveyUserAction {
  //USER get request
  static REQUEST_SURVEY_USER = "SurveyUserAction.REQUEST_SURVEY_USER";
  static REQUEST_SURVEY_USER_FINISHED =
    "SurveyUserAction.REQUEST_SURVEY_USER_FINISHED";

  //USER post
  static REQUEST_POST_SURVEY_USER = "SurveyUserAction.REQUEST_POST_SURVEY_USER";
  static REQUEST_POST_SURVEY_USER_FINISHED =
    "SurveyUserAction.REQUEST_POST_SURVEY_USER_FINISHED";

  //USER put
  static REQUEST_PUT_SURVEY_USER = "SurveyUserAction.REQUEST_PUT_SURVEY_USER";
  static REQUEST_PUT_SURVEY_USER_FINISHED =
    "SurveyUserAction.REQUEST_PUT_SURVEY_USER_FINISHED";

  //USER filterUserName request
  static REQUEST_SURVEY_USER_FILTERUSERNAME =
    "SurveyUserAction.REQUEST_SURVEY_USER_FILTERUSERNAME";
  static REQUEST_SURVEY_USER_FILTERUSERNAME_FINISHED =
    "SurveyUserAction.REQUEST_SURVEY_USER_FILTERUSERNAME_FINISHED";

  //User GetById
  static REQUEST_SURVEY_USER_BY_ID =
    "SurveyUserAction.REQUEST_SURVEY_USER_BY_ID";
  static REQUEST_SURVEY_USER_BY_ID_FINISHED =
    "SurveyUserAction.REQUEST_SURVEY_USER_BY_ID_FINISHED";

  //admin reset password
  static REQUEST_PUT_ADMIN_RESET_PASSWORD =
    "SurveyUserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD";
  static REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED =
    "SurveyUserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED";

  // USER Reset password
  static REQUEST_PUT_USER_RESET_PASSWORD =
    "SurveyUserAction.REQUEST_PUT_USER_RESET_PASSWORD";
  static REQUEST_PUT_USER_RESET_PASSWORD_FINISHED =
    "SurveyUserAction.REQUEST_PUT_USER_RESET_PASSWORD_FINISHED";

  static REQUEST_SURVEY_USER_DEVICE_MAPPING =
    "DeviceResetAction.REQUEST_SURVEY_USER_DEVICE_MAPPING";
  static REQUEST_SURVEY_USER_DEVICE_MAPPING_FINISHED =
    "DeviceResetAction.REQUEST_SURVEY_USER_DEVICE_MAPPING_FINISHED";

  static REQUEST_DELETE_SURVEY_USER_DEVICE_MAPPING =
    "DeviceResetAction.REQUEST_DELETE_SURVEY_USER_DEVICE_MAPPING";
  static REQUEST_DELETE_SURVEY_USER_DEVICE_MAPPING_FINISHED =
    "DeviceResetAction.REQUEST_DELETE_SURVEY_USER_DEVICE_MAPPING_FINISHED";

  //METHODS

  //USER get method
  static requestSurveyUser(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_SURVEY_USER,
        SurveyUserEffect.requestSurveyUser,
        params
      );
    };
  }

  //USER POST method
  static requestPostSurveyUser(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_POST_SURVEY_USER,
        SurveyUserEffect.requestPostSurveyUser,
        data,
        callBack
      );
    };
  }

  // USER put method
  static requestPutSurveyUser(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_PUT_SURVEY_USER,
        SurveyUserEffect.requestPutSurveyUser,
        data,
        id
      );
    };
  }

  //USER filter username
  static requestFilterSurveyUserName() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_SURVEY_USER_FILTERUSERNAME,
        SurveyUserEffect.requestFilterSurveyUserName
      );
    };
  }

  //
  //USER get request for page_limit none
  static REQUEST_SURVEY_USER_FILTER =
    "SurveyUserAction.REQUEST_SURVEY_USER_FILTER";
  static REQUEST_SURVEY_USER_FILTER_FINISHED =
    "SurveyUserAction.REQUEST_SURVEY_USER_FILTER_FINISHED";

  //USER get method page_limit none
  static requestSurveyUserFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_SURVEY_USER_FILTER,
        SurveyUserEffect.requestSurveyUserFilter,
        params
      );
    };
  }

  //USER getByID method
  static requestSurveyUserById(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_SURVEY_USER_BY_ID,
        SurveyUserEffect.requestSurveyUserById,
        id
      );
    };
  }

  static requestAdminResetPasswordPut(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD,
        SurveyUserEffect.requestAdminResetPasswordPut,
        data
      );
    };
  }

  static requestUserResetPassword(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_PUT_USER_RESET_PASSWORD,
        SurveyUserEffect.requestUserResetPassword,
        data
      );
    };
  }

  static requestSurveyUserDeviceMapping(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_SURVEY_USER_DEVICE_MAPPING,
        SurveyUserEffect.requestSurveyUserDeviceMapping,
        params
      );
    };
  }

  //delete method for device reset
  static requestSurveyUserDeviceMapDelete(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        SurveyUserAction.REQUEST_DELETE_SURVEY_USER_DEVICE_MAPPING,
        SurveyUserEffect.requestSurveyUserDeviceMapDelete,
        id
      );
    };
  }
}
