import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";
export default class ResponseCategorySourceModel extends BaseModel {
  /*
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_by = {};
  modified_on = "";
  project = {
    project_id: "",
    project_name: "",
  };
  response_category_name = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
