import BaseReducer from "../../../../utilities/BaseReducer";
import TelegramIntermediateAction from "./TelegramIntermediateAction";

export default class TelegramIntermediateExportGetReduser extends BaseReducer {
  initialState = {
    websweepxTelegramIntermediateExport: [],
  };
  [TelegramIntermediateAction.REQUEST_GET_TELEGRAM_INTERMEDIATE_EXPORT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      websweepxTelegramIntermediateExport: action.payload,
    };
  }
}
