import BaseReducer from "../../../utilities/BaseReducer";
import GMBAccountAction from "./GMBAccountAction";

// GMB AccountReduser  function
export default class GMBAccountReducer extends BaseReducer {
  initialState = {
    gmbAccount: [],
  };
  [GMBAccountAction.REQUEST_GMB_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      gmbAccount: action.payload,
    };
  }
}
