import BaseReducer from "../../../../utilities/BaseReducer";
import HssDetailsAction from "./HssDetailsAction";

export default class HssDetailsReducer extends BaseReducer {
  initialState = {
    websweepxHssDetails: [],
  };
  [HssDetailsAction.REQUEST_GET_HSS_DETAILS_FINISHED](state, action) {
    return {
      ...state,
      websweepxHssDetails: action.payload,
    };
  }
}
