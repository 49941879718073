import ActionUtility from "../../../../utilities/ActionUtility";
import HssDetailsEffect from "./HssDetailsEffect";

export default class HssDetailsAction {
  static REQUEST_GET_HSS_DETAILS = "HssDetailsAction.REQUEST_GET_HSS_DETAILS";
  static REQUEST_GET_HSS_DETAILS_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_FINISHED";

  static REQUEST_GET_HSS_DETAILS_BY_ID =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_BY_ID";
  static REQUEST_GET_HSS_DETAILS_BY_ID_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_BY_ID_FINISHED";

  static REQUEST_GET_HSS_DETAILS_SHADOW =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_SHADOW";
  static REQUEST_GET_HSS_DETAILS_SHADOW_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_SHADOW_FINISHED";

  static REQUEST_POST_HSS_DETAILS = "HssDetailsAction.REQUEST_POST_HSS_DETAILS";
  static REQUEST_POST_HSS_DETAILS_FINISHED =
    "HssDetailsAction.REQUEST_POST_HSS_DETAILS_FINISHED";

  static REQUEST_PUT_HSS_DETAILS = "HssDetailsAction.REQUEST_PUT_HSS_DETAILS";
  static REQUEST_PUT_HSS_DETAILS_FINISHED =
    "HssDetailsAction.REQUEST_PUT_HSS_DETAILS_FINISHED";

  static REQUEST_GET_HSS_DETAILS_EXECUTE_SCRIPTS =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_EXECUTE_SCRIPTS";
  static REQUEST_GET_HSS_DETAILS_EXECUTE_SCRIPTS_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_EXECUTE_SCRIPTS_FINISHED";

  static REQUEST_GET_HSS_DETAILS_EXECUTE_ALL_SCRIPTS =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_EXECUTE_ALL_SCRIPTS";
  static REQUEST_GET_HSS_DETAILS_EXECUTE_ALL_SCRIPTS_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_EXECUTE_ALL_SCRIPTS_FINISHED";

  static REQUEST_GET_HSS_DETAILS_PAUSE_SCRIPTS =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_PAUSE_SCRIPTS";
  static REQUEST_GET_HSS_DETAILS_PAUSE_SCRIPTS_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_PAUSE_SCRIPTS_FINISHED";

  static REQUEST_GET_HSS_DETAILS_RESUME_SCRIPTS =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_RESUME_SCRIPTS";
  static REQUEST_GET_HSS_DETAILS_RESUME_SCRIPTS_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_RESUME_SCRIPTS_FINISHED";

  static REQUEST_GET_HSS_DETAILS_STOP_SCRIPTS =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_STOP_SCRIPTS";
  static REQUEST_GET_HSS_DETAILS_STOP_SCRIPTS_FINISHED =
    "HssDetailsAction.REQUEST_GET_HSS_DETAILS_STOP_SCRIPTS_FINISHED";

  // METHODS
  static requestGetHssDetails(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS,
        HssDetailsEffect.requestGetHssDetails,
        params,
        callback
      );
    };
  }

  static requestGetHssDetailsByID(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS_BY_ID,
        HssDetailsEffect.requestGetHssDetailsByID,
        params,
        callBack
      );
    };
  }

  static requestGetHssDetailsShadow(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS_SHADOW,
        HssDetailsEffect.requestGetHssDetailsShadow,
        params,
        callback
      );
    };
  }

  static requestPostHss(data, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_POST_HSS_DETAILS,
        HssDetailsEffect.requestPostHss,
        data,
        callBackFunction
      );
    };
  }

  static requestPutHss(data, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_PUT_HSS_DETAILS,
        HssDetailsEffect.requestPutHss,
        data,
        id,
        callBackFunction
      );
    };
  }

  static requestGetHssDetailsExecuteScripts(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS_EXECUTE_SCRIPTS,
        HssDetailsEffect.requestGetHssDetailsExecuteScripts,
        params,
        callback
      );
    };
  }

  static requestGetHssDetailsExecuteAllScripts(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS_EXECUTE_ALL_SCRIPTS,
        HssDetailsEffect.requestGetHssDetailsExecuteAllScripts,
        params,
        callback
      );
    };
  }

  static requestGetHssDetailsPauseScripts(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS_PAUSE_SCRIPTS,
        HssDetailsEffect.requestGetHssDetailsPauseScripts,
        params,
        callback
      );
    };
  }

  static requestGetHssDetailsResumneScripts(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS_RESUME_SCRIPTS,
        HssDetailsEffect.requestGetHssDetailsResumneScripts,
        params,
        callback
      );
    };
  }

  static requestGetHssDetailsStopScripts(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        HssDetailsAction.REQUEST_GET_HSS_DETAILS_STOP_SCRIPTS,
        HssDetailsEffect.requestGetHssDetailsStopScripts,
        params,
        callback
      );
    };
  }
}
