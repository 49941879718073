import { BaseModel } from "sjs-base-model";

// ContactPutModel
export default class ContactPutModel extends BaseModel {
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
