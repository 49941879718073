import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Box, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import PriorityAction from "../../stores/priority/PriorityAction";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import TicketPriorityPost from "../../components/tickets/TicketPriorityPost";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

// mapState to props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    PriorityAction.REQUEST_PRIORITY,
    PriorityAction.REQUEST_PRIORITY_FILTER,
  ]),
  priority: state.priority.priority.hits || [],
  filterpriority: state.filterpriority.filterpriority.hits || {},
  pagination: state.priority.priority || [],
});

function TicketPriority(props) {
  const { isRequesting } = props;

  // SetState
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [priority, setPriority] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // put method edit
  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(true);
  }, []);

  // filter priority dispatch function
  function fetchFilterPriority(params) {
    props.dispatch(PriorityAction.filterRequestPriority(params));
  }

  // priority dispatch function
  function fetch(params) {
    props.dispatch(PriorityAction.requestPriority(params));
  }

  // priority callback function
  const priorityHandleChange = (event, priority) => {
    if (priority != null) {
      setPriority(priority);
    } else {
      setPriority(null);
    }
  };

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //************************************************* UseEffect
  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["ticket_priority_name.keyword", "asc"]]`,
    };

    if (
      props?.filterpriority === undefined ||
      Object.keys(props.filterpriority).length === 0
    ) {
      fetchFilterPriority(param);
    }

    let params = {};
    const filter = [];

    // priority
    if (priority !== null) {
      filter.push(
        `[["ticket_priority_name.keyword","must","","match","${priority.ticket_priority_name}"]]`
      );
    }

    if (priority !== null) {
      params = {
        filters: `${filter}`,
        page_number: 0,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["order_number","asc"]]`,
      };
    }

    fetch(params);
  }, []);

  // *********************************************** Priority List
  let filterPriorityList = [];

  if (props.filterpriority.hits) {
    props.filterpriority.hits.forEach((value) => {
      filterPriorityList.push({
        ticket_priority_name: value._source.ticket_priority_name,
        color: value._source.color,
      });
    });
  }
  let uniqueList = [...new Set(filterPriorityList)];

  uniqueList.sort((a, b) =>
    a?.ticket_priority_name.localeCompare(b.ticket_priority_name)
  );
  uniqueList = [...new Set(uniqueList)];

  //*************************************************applyFilter
  const applyFilter = () => {
    const filter = [];

    if (priority !== null) {
      filter.push(
        `[["ticket_priority_name.keyword","must","","match","${priority.ticket_priority_name}"]]`
      );
    }

    if (priority !== null) {
      // filter parameter
      const params = {
        filters: `${filter}`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: `[["order_number","asc"]]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      fetch(params);
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  // pagination
  //************************************************* handleChange
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    if (priority !== null) {
      filter.push(
        `[["ticket_priority_name.keyword","must","","match","${priority.ticket_priority_name}"]]`
      );
    }

    if (priority !== null && FilterClicked) {
      params = {
        filters: `${filter}`,
        page_number: `${value}` - 1,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: `[["order_number","asc"]]`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetch(params);
    }
  };

  // Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      width: 120,
      hide: true,
    },
    {
      field: "ticket_priority_name",
      headerName: "Ticket Priority Name",
      width: 250,
      flex: 4,
      renderCell: (params) => <RowPriority params={params.row} />,
    },

    {
      field: "color",
      headerName: "color",
      width: 250,
      flex: 4,
      hide: true,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 4,
      resizable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      align: "center",

      flex: 2,
      cellClassName: "actions",

      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  // ******************************************** clear function

  const ClearState = () => {
    // Clear the state

    if (priority !== null) {
      setPriority(null);
      setClearLoader(true);
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);

    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetch(params);
  };

  const rows = [];

  // placing counter in place of id
  let i = 1;

  //assigning data to rows of datagrid component
  if (props.priority.hits) {
    props.priority.hits.map((item, index) => {
      return rows.push({
        // sl_no: item._id
        //   ? props.pagination.current_page_no * page_limit + i++
        //   : "",
        sl_no: item._source.order_number,
        id: item._id,
        ticket_priority_name: item._source.ticket_priority_name,
        created_by: item._source.created_by.user_name,
        color: item._source.color,
      });
    });
  }

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: `${page_limit}`,
    };
    const param = {
      order_by: `[["ticket_priority_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    data &&
      props
        .dispatch(PriorityAction.requestPutPriorityBulkUpdate(bulkData))
        .then(() => {
          props.dispatch(PriorityAction.requestPriority(paramss));
          props.dispatch(PriorityAction.filterRequestPriority(param));
        });
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container lg={12} md={12} sm={12} justifyContent="center">
          <Grid item lg={2} md={5} sm={5} mt={1}>
            <AutoCompleteComponent
              value={priority}
              list={uniqueList ? uniqueList : []}
              getOptionLabel={(option) =>
                option.ticket_priority_name ? option.ticket_priority_name : ""
              }
              isOptionEqualToValue={(option, value) =>
                option.ticket_priority_name === value.ticket_priority_name
              }
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Box mr={1} width={10} height={10} borderRadius="50%">
                      {returnColor(option.color)}
                    </Box>
                    {option.ticket_priority_name}
                  </Box>
                );
              }}
              textFieldProps={{
                placeholder: "Priority Name",
              }}
              handleChange={priorityHandleChange}
              size="small"
              width="100%"
            />
          </Grid>
          <Grid item lg={6} md={7} sm={7}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={2}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                lg={8}
                display={"flex"}
                justifyContent={"end"}
                mt={1}
                mr={2}
                sx={{ gap: 2 }}
              >
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    name={"Filter"}
                    width="100%"
                    onClick={applyFilter}
                    loading={filterLoader && isRequesting}
                  />
                </Grid>
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    name={"Clear"}
                    width="100%"
                    onClick={ClearState}
                    loading={clearLoader && isRequesting}
                  />
                </Grid>
              </Grid>

              <Grid>
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* modal screen */}
      <TicketPriorityPost
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        priorityEditData={editRowsModel}
        edit={edit}
      />

      {/* data grid */}
      {navigator.onLine ? (
        <Box>
          <Grid
            display="flex"
            justifyContent="center"
            container
            item
            lg={12}
            md={12}
            sm={12}
          >
            {" "}
            <Grid item lg={8} md={12} sm={12} xs={12}>
              {/* loading icon */}
              <LoadingIndicator isActive={isRequesting}>
                {props.priority.hits && !isRequesting ? (
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={60}
                    rowReordering
                    onRowOrderChange={rowOrderHandleChange}
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                          platform: false,
                        },
                      },
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "80vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingBgImage />
                  </Box>
                )}
              </LoadingIndicator>
            </Grid>
          </Grid>

          {/* Pagination */}
          {/* <Grid
            display="flex"
            justifyContent="center"
            container
            item
            lg={12}
            md={12}
            sm={12}
            // className="pagination_section"
          >
            {" "}
            <Grid item lg={8} md={12} sm={12} xs={12}>
              {props.priority.hits
                ? props.priority.hits.length !== 0 && (
                    <PaginationUI
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                    />
                  )
                : null}
            </Grid>
          </Grid> */}
        </Box>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(TicketPriority);

const RowPriority = (item) => {
  return (
    <>
      <Box
        mr={1}
        width={10}
        height={10}
        borderRadius="50%"
        sx={{
          backgroundColor: item.params.color,
        }}
      ></Box>
      <span>{item.params.ticket_priority_name}</span>
    </>
  );
};

const returnColor = (color) => {
  return (
    <Box
      width={10}
      height={10}
      borderRadius="50%"
      sx={{
        backgroundColor: `${color}`,
      }}
    ></Box>
  );
};
