import React, { useCallback, useEffect, useState } from "react";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import {
  Button,
  Divider,
  Grid,
  useTheme,
  CardActionArea,
  CardMedia,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Card,
} from "@mui/material";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import TicketDescEditModel from "./TicketDescEditModel";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";

// mapstateto props function fetching

let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const Details = (props) => {
  const theme = useTheme();

  const { isRequesting } = props;

  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          backgroundColor: theme.palette.background.paper,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
          borderRadius: theme.palette.buttonComponent.borderRadius.large,
        }}
        p={2}
      >
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          // justifyContent="space-between"
          // alignItems="flex-start"
        >
          <Grid item xl={10} md={10} sm={10} mt={1}>
            <Typography style={{ textAlign: "left" }}>
              <span className="font-weight-bold">Ticket Name: </span>
              <span>
                {props.ticketData._source &&
                  props.ticketData._source.ticket_name}
              </span>
            </Typography>

            <Typography style={{ textAlign: "justify" }}>
              <span className="font-weight-bold">Ticket Description: </span>
              <span style={{ overflowWrap: "anywhere" }}>
                {props.ticketData._source && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.ticketData._source.ticket_desc.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}
              </span>
            </Typography>

            {props.ticketData._source &&
              props.ticketData._source.Attachment_url &&
              props.ticketData._source.Attachment_url.length !== 0 && (
                <Grid
                  item
                  xl={12}
                  md={12}
                  sm={12}
                  mt={1}
                  display="flex"
                  gap={1}
                >
                  {props.ticketData._source.Attachment_url.map((item) => (
                    <Grid item lg={3} sm={3} md={3} xl={3}>
                      <Card>
                        <CardMedia
                          component="img"
                          image={item}
                          sx={{ height: 100 }}
                          onClick={() => handleOpen(item)}
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}

            <Dialog
              open={openDialog}
              onClose={handleClose}
              fullWidth
              maxWidth="lg"
              height="100%"
            >
              <DialogContent>
                {/* <img
                  src={selectedImage}
                  alt="full screen"
                  style={{
                    // height: "max-content",
                    width: "100%",
                    objectFit: "cover",
                  }}
                /> */}

                <Box width={"100%"}>
                  <Grid
                    item
                    container
                    justifyContent={"flex-end"}
                    alignItems="center"
                  >
                    <Grid item>
                      <IconButton
                        title="Close"
                        onClick={() => {
                          setOpenDialog(false);
                          setSelectedImage(null);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          style={{
                            // color: `${theme.palette.ticketListView.iconColor}`,
                            fontSize: "20px",
                          }}
                        ></FontAwesomeIcon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>

                <Card>
                  <CardMedia
                    component="img"
                    image={selectedImage}
                    sx={{
                      height: 500,
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </DialogContent>
            </Dialog>

            <TicketDescEditModel
              open={open}
              onSubmit={() => {}}
              onClose={() => setOpen(false)}
              EditData={props.ticketData ? props.ticketData : ""}
              filters={props.filters}
              pageNumber={props.pageNumber}
              fetchFunction={props.fetchFunction}
            />
          </Grid>
          {props.ticketData._source.release_id ? (
            ""
          ) : (
            <Grid item xl={2} md={2} sm={2}>
              <IconButton
                title="Edit"
                onClick={(event) => {
                  event.ignore = true;
                  setOpen(true);
                }}
                sx={{ border: "0" }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{
                    height: "20px",
                    width: "20px",
                    color: theme.palette.ticketListView.iconColor,
                  }}
                ></FontAwesomeIcon>
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(Details);
