import BaseReducer from "../../../utilities/BaseReducer";
import OrganizationAction from "./OrganizationAction";

export default class OrganizationFilterReduser extends BaseReducer {
  //initial state of OrganizationFilterReduser
  initialState = {
    organizationFilter: [],
  };
  //OrganizationFilterReduser request action finish
  [OrganizationAction.REQUEST_ORGANIZATION_FILTER_FINISHED](state, action) {
    return {
      ...state,
      organizationFilter: action.payload,
    };
  }
}
