import { BaseModel } from "sjs-base-model";
import AmbitionBoxAccountHitsModel from "./AmbitionBoxAccountHitsModel";
import AmbitionBoxAccountShardsModel from "./AmbitionBoxAccountShardsModel";

// AmbitionBoxAccountModel
export default class AmbitionBoxAccountModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: AmbitionBoxAccountShardsModel };
  current_page_no = 0;
  hits = { hits: AmbitionBoxAccountHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
