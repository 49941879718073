import ActionUtility from "../../../../utilities/ActionUtility";
import TopLabelEffect from "./TopLabelEffect";

export default class TopLabelAction {
  // TopLabelAction action with an function declaration
  static REQUEST_TOPLABEL = "TopLabelAction.REQUEST_TOPLABEL";
  static REQUEST_TOPLABEL_FINISHED = "TopLabelAction.REQUEST_TOPLABEL_FINISHED";

  static REQUEST_TOPLABEL_FILTER = "TopLabelAction.REQUEST_TOPLABEL_FILTER";
  static REQUEST_TOPLABEL_FILTER_FINISHED =
    "TopLabelAction.REQUEST_TOPLABEL_FILTER_FINISHED";

  static REQUEST_PUT_TOPLABEL = "TopLabelAction.REQUEST_PUT_TOPLABEL";
  static REQUEST_PUT_TOPLABEL_FINISHED =
    "TopLabelAction.REQUEST_PUT_TOPLABEL_FINISHED";

  static REQUEST_POST_TOPLABEL = "TopLabelAction.REQUEST_POST_TOPLABEL";
  static REQUEST_POST_TOPLABEL_FINISHED =
    "TopLabelAction.REQUEST_POST_TOPLABEL_FINISHED";

  static REQUEST_DELETE_TOPLABEL = "TopLabelAction.REQUEST_DELETE_TOPLABEL";
  static REQUEST_DELETE_TOPLABEL_FINISHED =
    "TopLabelAction.REQUEST_DELETE_TOPLABEL_FINISHED";

  // METHODS
  // Organization GET function
  static requestTopLabel(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLabelAction.REQUEST_TOPLABEL,
        TopLabelEffect.requestTopLabel,
        params,
        callBack
      );
    };
  }

  static requestTopLabelFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLabelAction.REQUEST_TOPLABEL_FILTER,
        TopLabelEffect.requestTopLabelFilter,
        params
      );
    };
  }
  static requestPutTopLabel(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLabelAction.REQUEST_PUT_TOPLABEL,
        TopLabelEffect.requestPutTopLabel,
        data,
        id
      );
    };
  }

  static requestPostTopLabel(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLabelAction.REQUEST_POST_TOPLABEL,
        TopLabelEffect.requestPostTopLabel,
        data
      );
    };
  }

  static requestDeleteTopLabel(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLabelAction.REQUEST_DELETE_TOPLABEL,
        TopLabelEffect.requestDeleteTopLabel,
        id
      );
    };
  }
}
