import BaseReducer from "../../../utilities/BaseReducer";
import PriorityAction from "./PriorityAction";

export default class FilterPriorityReducer extends BaseReducer {
  //initial state of priority list
  initialState = {
    filterpriority: [],
  };

  //priority request action finish
  // [PriorityAction.REQUEST_PRIORITY_FINISHED](state, action) {
  //   return {
  //     ...state,
  //     filterpriority: action.payload,
  //   };
  // }

  //priority request action finish
  [PriorityAction.REQUEST_PRIORITY_FILTER_FINISHED](state, action) {
    return {
      ...state,
      filterpriority: action.payload,
    };
  }
}
