import ActionUtility from "../../../utilities/ActionUtility";
import TagEffect from "./TagEffect";

export default class TagAction {
  // TAG action with an function declaration
  static REQUEST_TAG = "TAGAction.REQUEST_TAG";
  static REQUEST_TAG_FINISHED = "TAGAction.REQUEST_TAG_FINISHED";

  // TAG Filter
  static REQUEST_TAG_FILTER = "TAGAction.REQUEST_TAG_FILTER";
  static REQUEST_TAG_FILTER_FINISHED = "TAGAction.REQUEST_TAG_FILTER_FINISHED";

  //   TAG post
  static REQUEST_POST_TAG = "TAGAction.REQUEST_POST_TAG";
  static REQUEST_POST_TAG_FINISHED = "TAGAction.REQUEST_POST_TAG_FINISHED";

  // TAG put
  static REQUEST_PUT_TAG = "TAGAction.REQUEST_PUT_TAG";
  static REQUEST_PUT_TAG_FINISHED = "TAGAction.REQUEST_PUT_TAG_FINISHED";

  // TAG delete
  static REQUEST_DELETE_TAG = "TAGAction.REQUEST_DELETE_TAG";
  static REQUEST_DELETE_TAG_FINISHED = "TAGAction.REQUEST_DELETE_TAG_FINISHED";

  //Tag put Bulk
  static REQUEST_PUT_TAG_BULKUPDATE = "TAGAction.REQUEST_PUT_TAG_BULKUPDATE";
  static REQUEST_PUT_TAG_BULKUPDATE_FINISHED =
    "TAGAction.REQUEST_PUT_TAG_BULKUPDATE_FINISHED";

  // METHODS
  // TAG GET function
  static requestTag(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TagAction.REQUEST_TAG,
        TagEffect.requestTag,
        params
      );
    };
  }

  // TAG Filter Method
  static requestTagFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TagAction.REQUEST_TAG_FILTER,
        TagEffect.requestTagFilter,
        params
      );
    };
  }

  //   static requestTAGFinished() {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         TAGAction.REQUEST_TAG_FINISHED,
  //         TAGEffect.requestTAGFinished
  //       );
  //     };
  //   }

  // TAG post function
  static requestPostTag(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TagAction.REQUEST_POST_TAG,
        TagEffect.requestPostTag,
        data
      );
    };
  }

  // TAG put function
  static requestPutTag(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TagAction.REQUEST_PUT_TAG,
        TagEffect.requestPutTag,
        data,
        id
      );
    };
  }

  // TAG delete function
  static requestDeleteTag(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TagAction.REQUEST_DELETE_TAG,
        TagEffect.requestDeleteTag,
        id
      );
    };
  }

  // Tag Bulk put function
  static requestPutTagBulkUpdate(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TagAction.REQUEST_PUT_TAG_BULKUPDATE,
        TagEffect.requestPutTagBulkUpdate,
        data,
        callBack
      );
    };
  }
}
