import { BaseModel } from "sjs-base-model";

export default class TurnAroundTimeModel extends BaseModel {
  //Ticket TAT model

  ticket_tat = {
    value: 0,
  };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
