import { BaseModel } from "sjs-base-model";

export default class InfluencerPostModel extends BaseModel {
  //USER POST model
  data = "";
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}

//  email: props.userEditData.email,
//     username: props.userEditData.username,
//     firstName: props.userEditData.firstName,
//     lastName: props.userEditData.lastName,
