import BaseReducer from "../../../../utilities/BaseReducer";
import GoogleSearchAction from "./GoogleSearchAction";

export default class GoogleSearchGetReduser extends BaseReducer {
  initialState = {
    websweepxGoogleSearchMain: [],
  };
  [GoogleSearchAction.REQUEST_GET_GOOGLE_SEARCH_FINISHED](state, action) {
    return {
      ...state,
      websweepxGoogleSearchMain: action.payload,
    };
  }
}
