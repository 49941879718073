import environment from "environment";
import KeywordConfigGetModel from "../../../../ormTicketing/stores/keywordConfiguration/models/KeywordConfigGetModel";
import KeywordConfigPostModel from "../../../../ormTicketing/stores/keywordConfiguration/models/KeywordConfigPostModel";
import KeywordConfigDeleteModel from "../../../../ormTicketing/stores/keywordConfiguration/models/KeywordConfigDeleteModel";
import HttpErrorResponseModel from "../../../../models/HttpErrorResponseModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";

export default class KeywordDiscoverPatternEffect {
  static async requestKeywordConfiguration(params) {
    const endpoint = environment.api.keyword_discover_pattern;
    return EffectUtility.getToModel(KeywordConfigGetModel, endpoint, params);
  }

  static async requestKeywordConfigurationFilter(params, callback) {
    const endpoint = environment.api.keyword_discover_pattern_filter;
    let response = EffectUtility.getToModel(
      KeywordConfigGetModel,
      endpoint,
      params,
      callback
    );

    callback &&
      response?.then((res) => {
        callback(res);
      });

    return response;
  }

  static async requestPostKeywordConfig(data) {
    const endpoint = environment.api.keyword_discover_pattern;
    return EffectUtility.postToModel(KeywordConfigPostModel, endpoint, data);
  }

  static async requestPutKeywordConfig(data, id) {
    const endpoint = environment.api.keyword_discover_pattern + "/" + id;
    return EffectUtility.putToModel(
      KeywordConfigPostModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  static async requestDeleteKeywordConfig(id) {
    const endpoint = environment.api.keyword_discover_pattern + "/" + id;

    return EffectUtility.deleteToModel(KeywordConfigDeleteModel, endpoint);
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
