import { BaseModel } from "sjs-base-model";
import TwitterDashboardDataModel from "./TwitterDashboardDataModel";

// twitter Model
export default class TwitterDashboardModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  data = [TwitterDashboardDataModel];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
