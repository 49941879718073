import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import TelegramAccountDeleteModel from "./model/TelegramAccountDeleteModel";
import TelegramAccountModel from "./model/TelegramAccountModel";
import TelegramAccountPostModel from "./model/TelegramAccountPostModel";
import TelegramAccountPutModel from "./model/TelegramAccountPutModel";

// TelegramAccountEffect
export default class TelegramAccountEffect {
  // calling an API according to the Whatsapp account model
  // Get method for the Telegram account
  static async requestTelegramAccount(params) {
    const endpoint = environment.api.telegramAccount;
    return EffectUtility.getToModel(TelegramAccountModel, endpoint, params);
  }

  // put method for the Whatsapp account
  static async requestPutTelegramAccount(data, id) {
    const endpoint = environment.api.telegramAccount + "/" + id;
    return EffectUtility.putToModel(TelegramAccountPutModel, endpoint, data);
  }

  // post method for the Whatsapp account
  static async requestPostTelegramAccount(data) {
    const endpoint = environment.api.telegramAccount + "/add";

    return EffectUtility.postToModel(TelegramAccountPostModel, endpoint, data);
  }

  // post method for the Telegram Auth
  static async requestPostTelegramAuth(data) {
    const endpoint = environment.api.telegramAuth;

    return EffectUtility.postToModel(TelegramAccountPostModel, endpoint, data);
  }

  // Delete API
  static async requestDeleteTelegramAccount(id) {
    const endpoint = environment.api.telegramAccount + "/" + id;
    return EffectUtility.deleteToModel(TelegramAccountDeleteModel, endpoint);
  }
}
