import BaseReducer from "../../../utilities/BaseReducer";
import UserAction from "./UserAction";

export default class UserByIdReducer extends BaseReducer {
  //initial state of user
  initialState = {
    userById: [],
  };

  //user request action finish
  [UserAction.REQUEST_USER_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      userById: action.payload,
    };
  }
}
