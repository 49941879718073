import ActionUtility from "../../../utilities/ActionUtility";
import RolePermissionsEffect from "./RolePermissionsEffect";

export default class RolePermissionAction {
  //roles Permission requests
  static REQUEST_ROLEPERMISSIONS =
    "RolePermissionAction.REQUEST_ROLEPERMISSIONS";
  static REQUEST_ROLEPERMISSIONS_FINISHED =
    "RolePermissionAction.REQUEST_ROLEPERMISSIONS_FINISHED";

  //roles Permission POST
  static REQUEST_ROLEPERMISSION_POST =
    "RolePermissionAction.REQUEST_ROLEPERMISSION_POST";
  static REQUEST_ROLEPERMISSION_POST_FINISHED =
    "RolePermissionAction.REQUEST_ROLEPERMISSION_POST_FINISHED";

  //roles Permission put
  static REQUEST_ROLEPERMISSION_PUT =
    "RolePermissionAction.REQUEST_ROLEPERMISSION_PUT";
  static REQUEST_ROLEPERMISSION_PUT_FINISHED =
    "RolePermissionAction.REQUEST_ROLEPERMISSION_PUT_FINISHED";

  //role Permission delete
  static REQUEST_ROLEPERMISSIONS_DELETE =
    "RolePermissionAction.REQUEST_ROLEPERMISSIONS_DELETE";
  static REQUEST_ROLEPERMISSIONS_DELETE_FINISHED =
    "RolePermissionAction.REQUEST_ROLEPERMISSIONS_DELETE_FINISHED";

  //METHODS

  //role permission list get method
  static requestRolePermission(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolePermissionAction.REQUEST_ROLEPERMISSIONS,
        RolePermissionsEffect.requestRolePermission,
        params
      );
    };
  }

  //rolePERMISSION post method
  static requestRolePermissionPost(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolePermissionAction.REQUEST_ROLEPERMISSION_POST,
        RolePermissionsEffect.requestRolePermissionPost,
        data
      );
    };
  }

  //rolePERMISSION puy method
  static requestRolePermissionPut(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolePermissionAction.REQUEST_ROLEPERMISSION_PUT,
        RolePermissionsEffect.requestRolePermissionPut,
        data,
        id
      );
    };
  }

  //role Permission delete
  static requestRolePermissionDelete(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RolePermissionAction.REQUEST_ROLEPERMISSIONS_DELETE,
        RolePermissionsEffect.requestRolePermissionDelete,
        id
      );
    };
  }
}
