import ActionUtility from "../../../../utilities/ActionUtility";
import FeedbackEffect from "./FeedBackEffect";

export default class FeedBackAction {
  // FeedBackAction action with an function declaration
  static REQUEST_FEED_BACK = "FeedBackAction.REQUEST_FEED_BACK";
  static REQUEST_FEED_BACK_FINISHED =
    "FeedBackAction.REQUEST_FEED_BACK_FINISHED";

  static REQUEST_FEED_BACK_FILTER = "FeedBackAction.REQUEST_FEED_BACK_FILTER";
  static REQUEST_FEED_BACK_FILTER_FINISHED =
    "FeedBackAction.REQUEST_FEED_BACK_FILTER_FINISHED";

  // METHODS
  static requestFeedback(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FeedBackAction.REQUEST_FEED_BACK,
        FeedbackEffect.requestFeedback,
        params
      );
    };
  }

  static requestFeedbackFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FeedBackAction.REQUEST_FEED_BACK_FILTER,
        FeedbackEffect.requestFeedbackFilter,
        params
      );
    };
  }
}
