import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
// import CategoryAction from "../../stores/category/CategoryAction";
import { connect } from "react-redux";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../../components/muiButton/MuiButton";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import AddAccountAction from "../../stores/addAccount/AddAccountAction";
import BrandCompitatorAction from "../../stores/brandCompitator/BrandCompitatorAction";
import YoutubeAccountAction from "../../stores/youtubeAccount/YoutubeAccountAction";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AddAccountAction.REQUEST_ADD_ACCOUNT_FILTER,
    YoutubeAccountAction.REQUEST_YOUTUBE_ACCOUNT_FILTER,
  ]),
  addAccountFilter: state.addAccountFilter.addAccountFilter.hits || [],
  youtubeAccountFilter:
    state.youtubeAccountFilter.youtubeAccountFilter.hits || [],
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Vlaidation Schema
const validationSchema = yup.object({
  // category_name: yup
  //   .string("Enter category name")
  //   .trim()
  //   .min(1, "Category name should be of minimum 1 characters length")
  //   .required("Category name is required"),
});

const BrandCompetitorPost = (props) => {
  const { brandEditData } = props;
  // project pros from project api fectched by category component
  // Setting Initial Values
  let initial = {};
  const [platform, setPlatform] = useState(null);

  let accountList = [];

  let twitterList = [];
  if (props.addAccountFilter.hits) {
    props.addAccountFilter.hits.map((value) => {
      twitterList.push({
        account_id: value._id,
        account_name: value._source.account_name,
      });
    });
  }
  let youtubelist = [];
  if (props.youtubeAccountFilter.hits) {
    props.youtubeAccountFilter.hits.map((value) => {
      youtubelist.push({
        account_id: value._id,
        account_name: value._source.account_name,
      });
    });
  }
  if (
    brandEditData.platform === "1" ||
    (platform && platform.platform_name === "Twitter")
  ) {
    accountList = twitterList;
  } else if (
    brandEditData.platform === "2" ||
    (platform && platform.platform_name === "Youtube")
  ) {
    accountList = youtubelist;
  }

  function platformObj(item) {
    let platform = props.platform_array
      .filter((val) => val.platform_id === item)
      .map((val) => {
        return {
          platform_id: val.platform_id,
          platform_name: val.platform_name,
        };
      });

    return platform;
  }

  const getOptionDisabled = (option) => {
    return (
      option.platform_name === "Ambition Box" ||
      option.platform_name === "Email" ||
      option.platform_name === "Facebook" ||
      option.platform_name === "GMB" ||
      option.platform_name === "Glassdoor" ||
      option.platform_name === "Instagram" ||
      option.platform_name === "LinkedIn" ||
      option.platform_name === "None" ||
      option.platform_name === "Playstore" ||
      option.platform_name === "Quora" ||
      option.platform_name === "Telegram" ||
      option.platform_name === "Trust Pilot" ||
      option.platform_name === "Whatsapp"
    );
  };

  if (props.edit) {
    initial = {
      brand_competitor_account_name:
        brandEditData.brand_competitor_account_name,
      competitor_channel_id: brandEditData.competitor_channel_id,
      platform:
        platformObj(brandEditData.platform) &&
        platformObj(brandEditData.platform)[0],
      account: brandEditData.account ? brandEditData.account : null,
    };
  } else {
    initial = {
      brand_competitor_account_name: "",
      competitor_channel_id: "",
      platform:
        props.platform_array && props.platform_array.length === 1
          ? props.platform_array[0]
          : null,
      account: null,
    };
  }

  function fetchtwitterAccountfilter() {
    let param = {
      page_limit: "none",
    };
    props.dispatch(AddAccountAction.requestAddAccountFilter(param));
  }

  function fetchYoutubeAccountFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(YoutubeAccountAction.requestYoutubeAccountFilter(params));
  }

  useEffect(() => {
    if (props.platform_array && props.platform_array.length === 1) {
      setPlatform(props.platform_array[0]);
    } else {
      setPlatform(null);
    }
  }, [props.open]);

  useEffect(() => {
    fetchtwitterAccountfilter();
    fetchYoutubeAccountFilter();
  }, []);

  const [loading, setLoading] = useState(false);

  // Returning A DialogBox
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      {/* Scope of Formik starts here */}
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        validate={(values) => {
          var errors = {};
          if (!values.brand_competitor_account_name) {
            errors.brand_competitor_account_name = "Required";
          }

          if (platform && platform.platform_id === "2") {
            if (!values.competitor_channel_id) {
              errors.competitor_channel_id = "Required";
            }
          }

          if (!values.account) {
            errors.account = "Required";
          }
          if (!values.platform) {
            errors.platform = "Required";
          }

          return errors;
        }}
        // validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          // setLoading(true);
          const params = {
            order_by: `[["order_number","asc"]]`,
            page_limit: 15,
          };
          const param = {
            order_by: `[["category_name.keyword", "asc"]]`,
            page_limit: "none",
          };

          let obj = {};

          Object.assign(
            obj,
            values.account && { account: values.account },
            values.brand_competitor_account_name && {
              brand_competitor_account_name:
                values.brand_competitor_account_name,
            },
            values.competitor_channel_id && {
              competitor_channel_id: values.competitor_channel_id,
            },
            values.platform && {
              platform: values.platform.platform_id,
            }
          );

          // Object.assign;
          if (props.edit) {
            props
              .dispatch(BrandCompitatorAction.requestPutBrandCompitator(obj))
              .then(() => {
                const params = {
                  page_limit: 15,
                };
                const param = {
                  page_limit: "none",
                };
                props.dispatch(
                  BrandCompitatorAction.requestBrandCompitator(params)
                );
                props.dispatch(
                  BrandCompitatorAction.requestBrandCompitatorFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(BrandCompitatorAction.requestPostBrandCompitator(obj))
              .then(() => {
                const params = {
                  page_limit: 15,
                };
                const param = {
                  page_limit: "none",
                };
                props.dispatch(
                  BrandCompitatorAction.requestBrandCompitator(params)
                );
                props.dispatch(
                  BrandCompitatorAction.requestBrandCompitatorFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
        validateOnBlur={true}
        validateOnChange
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          // Form Tag from Formik
          <Form>
            {/* Content of the dialog Box  */}
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* text Content inside DialogBox  */}
              <DialogContentText sx={{ width: "100%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10 ">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
                            </span>
                            dd &nbsp;
                            <span className="span_first_letter">C</span>
                            ompetitor &nbsp;
                            <span className="span_first_letter">N</span>ame
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                            </span>
                            dit &nbsp;
                            <span className="span_first_letter">C</span>
                            ompetitor &nbsp;
                            <span className="span_first_letter">N</span>ame
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>
              {/* Form Filed inside Dialog Box  */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Brand Competitor :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      // text feild from material Ui
                      as={MuiTextField}
                      autoFocus
                      fullWidth
                      variant="outlined"
                      id="brand_competitor_account_name"
                      size="small"
                      name="brand_competitor_account_name"
                      placeholder="Competitor Name"
                      // value={formik.values.tag_name}
                      error={
                        Boolean(errors.brand_competitor_account_name) &&
                        Boolean(touched.brand_competitor_account_name)
                      }
                      helperText={
                        Boolean(touched.brand_competitor_account_name) &&
                        errors.brand_competitor_account_name
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Platform * :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="platform"
                      size="small"
                      component={AutoCompleteMultiSelect}
                      options={props.platform_array ? props.platform_array : []}
                      disabled={
                        props.platform_array &&
                        props.platform_array.length === 1
                          ? true
                          : false
                      }
                      getOptionDisabled={getOptionDisabled}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.platform_name === value.platform_name
                      }
                      onChange={(_, value) => {
                        setFieldValue(`platform`, value);
                        setFieldValue(`account`, null);
                        setPlatform(value);
                      }}
                      getOptionLabel={(option) => option.platform_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Platform",
                      }}
                    />
                  </Grid>
                </Grid>

                {(brandEditData.platform === "2" ||
                  (platform && platform.platform_id === "2")) && (
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      Channel Id :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5}>
                      <Field
                        // text feild from material Ui
                        as={MuiTextField}
                        fullWidth
                        variant="outlined"
                        id="competitor_channel_id"
                        size="small"
                        name="competitor_channel_id"
                        placeholder="Channel Id"
                        // value={formik.values.tag_name}
                        error={
                          Boolean(errors.competitor_channel_id) &&
                          Boolean(touched.competitor_channel_id)
                        }
                        helperText={
                          Boolean(touched.competitor_channel_id) &&
                          errors.competitor_channel_id
                        }
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Account * :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="account"
                      size="small"
                      component={AutoCompleteMultiSelect}
                      options={accountList ? accountList : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.account_name === value.account_name
                      }
                      getOptionLabel={(option) => option.account_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Account",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            {/* Actions performed from form action buttons  */}
            <DialogActions className="m-1 my-4">
              {/* Calling ButtonComponent to perform form actions  */}
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add"}
                      type="submit"
                      size="large"
                      loading={loading}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      size="large"
                      name={"Submit"}
                      type="submit"
                      width="100%"
                      loading={loading}
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      size="large"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      size="large"
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapStateToProps)(BrandCompetitorPost);
