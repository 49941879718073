import { BaseModel } from "sjs-base-model";
import GoogleAccountDataHitsModel from "./GoogleAccountDataHitsModel";

//GoogleAccountHitsModel
export default class GoogleAccountHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ GoogleAccountDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
