import React, { useEffect, useRef, useState } from "react";
import "../cssFiles/NegativeMentionsDispositionWise.css";
import environment from "environment";
import { endDate, monthNames, startDate } from "../dateFunctions/DateFunctions";
import { format } from "date-fns";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import Logo from "../logoContainer/Logo";
import { Box, Typography } from "@mui/material";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../../../views/App";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../../utilities/FetchRequest";
import { NivoBarChart } from "../../../../components/nivoCharts/NivoBarChart";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../../components/ticketPartials";
import NivoWaffleChart from "../../../../components/nivoCharts/NivoWaffleChart";
import ReactEcharts from "echarts-for-react";

function NegativeMentionsChart(props) {
  Chart.register(CategoryScale);
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);

  // this month
  let current_month = new Date();
  let current_month_end_date = endDate(current_month);

  // last month
  let previous_month = new Date();
  previous_month.setMonth(previous_month.getMonth() - 1);

  //  before_previous_month
  let before_previous_month = new Date();
  before_previous_month.setMonth(before_previous_month.getMonth() - 2);

  let before_previous_month_start_date = startDate(before_previous_month);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      // fetching platform data
      await fetchData(
        before_previous_month_start_date,
        current_month_end_date
      ).then((e) => {
        setReportData(e);
      });
      setLoading(false);
    })();
  }, []);

  const [today, setToday] = useState(new Date());

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setToday(new Date());

        setLoading(true);
        // fetching platform data
        await fetchData(
          before_previous_month_start_date,
          current_month_end_date
        ).then((e) => {
          setReportData(e);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      // Assuming props.openDateFilter is provided and valid
      const date = new Date(props.openDateFilter);
      setToday(new Date(props.openDateFilter));

      const firstDateOfLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 2,
        1
      );
      const firstDateString = format(firstDateOfLastMonth, "yyyy-MM-dd");

      const endOfCurrentMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      const endDateString = format(endOfCurrentMonth, "yyyy-MM-dd");

      (async function CallApi() {
        setToday(new Date());

        setLoading(true);
        // fetching platform data
        await fetchData(
          new Date(firstDateString),
          new Date(endDateString)
        ).then((e) => {
          setReportData(e);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(from, to) {
    let API = `${
      environment.api.ticketReports
    }?report_type=["count"]&reports=["platform"]&from=${format(
      from,
      "yyyy-MM-dd"
    )}&to=${format(to, "yyyy-MM-dd")}`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${
    //     environment.api.ticketReports
    //   }?report_type=["count"]&reports=["platform"]&from=${format(
    //     from,
    //     "yyyy-MM-dd"
    //   )}&to=${format(to, "yyyy-MM-dd")}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(async (response) => {
    //     return response;
    //   })
    //   .catch((err) => {});
  }

  let platforms = [];
  let negative_count = [];

  reportData &&
    reportData.ticket_report &&
    reportData.ticket_report.platform &&
    reportData.ticket_report.platform.length > 0 &&
    reportData.ticket_report.platform
      .slice(
        0,
        reportData &&
          reportData.ticket_report &&
          reportData.ticket_report.platform &&
          reportData.ticket_report.platform.length - 6
      )
      .map((platformData) => {
        platforms.push(platformName(platformData.platform)[0]);
        negative_count.push(platformData.negative_count);
      });

  let chartData = platforms.map((platform, index) => ({
    Platforms: platform,
    "Negative Count": negative_count[index],
  }));

  let MaxValue = Math.max(...negative_count);

  function getDigit(value) {
    let digit = 1;

    for (let i = 0; value >= 10; i++) {
      value /= 10;
      digit *= 10;
    }

    return digit;
  }

  const digit = getDigit(MaxValue);
  let num = parseInt(MaxValue / digit);

  let updatedDigit = num * digit;

  let stepSize = updatedDigit / 10;

  const remainder = MaxValue % stepSize;

  const difference = stepSize - remainder;

  MaxValue += difference;

  let colors = [`${theme.reports.negative}`];

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }

  let legendOffset,
    maxYAxisLength = 0;
  if (chartData?.length > 0) {
    maxYAxisLength =
      chartData &&
      Math.max(
        ...chartData?.map((item) => {
          return String(item.Platforms?.length);
        })
      );
    if (maxYAxisLength >= 10)
      legendOffset = -100 - maxYAxisLength * (maxYAxisLength / 10);
    else if (maxYAxisLength > 0) legendOffset = -85;
  }

  let waffleChartData = [];

  let totalCount = 0;

  chartData.forEach((item) => {
    // Add the value of "Negative Count" to the total count
    totalCount += item["Negative Count"];
  });

  chartData?.map((item) => {
    waffleChartData.push({
      id: item.Platforms,
      label: item.Platforms,
      value: (item["Negative Count"] / totalCount) * 100,
      // viewvalue: prefixZero_for_singleDigit(item["Negative Count"]),
    });
  });

  let colorsArray = [
    `${theme.platform.color1}`,
    `${theme.platform.color2}`,
    `${theme.platform.color3}`,
    `${theme.platform.color4}`,
    `${theme.platform.color5}`,
    `${theme.platform.color6}`,
    `${theme.platform.color7}`,
    `${theme.platform.color8}`,
    `${theme.platform.color9}`,
    `${theme.platform.color10}`,
    `${theme.platform.color11}`,
    `${theme.platform.color2}`,
    `${theme.platform.color13}`,
    `${theme.platform.color14}`,
    `${theme.platform.color5}`,
    `${theme.platform.color16}`,
    `${theme.platform.color17}`,
    `${theme.platform.color18}`,
    `${theme.platform.color19}`,
    `${theme.platform.color20}`,
  ];

  chartData = chartData?.filter((chart) => chart?.["Negative Count"] !== 0);

  let shouldShowLegend = false;
  if (
    (props.ChartContainerHeight?.current &&
      props.ChartContainerWidth?.current &&
      props.ChartContainerHeight.current >= 350 &&
      props.ChartContainerWidth.current > 450) ||
    (props.ChartContainerHeight?.current === undefined &&
      props.ChartContainerWidth?.current === undefined)
  ) {
    shouldShowLegend = true;
  }

  const getOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      formatter: function (params) {
        return `<span style="color: #468C9D;">${params[0].name}</span>: <span style="color: ${theme.reports.negative};">${params[0].value}</span>`;
      },
    },
    xAxis: {
      data: chartData.map((item) => item.Platforms),
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: {
        show: shouldShowLegend ? true : false,
        color: "#468C9D",
        // interval: 0, // Ensures all labels are shown
        rotate: 0, // Rotate the labels if they are long to avoid overlap
        // formatter: function (value) {
        //   return !props?.detailedReport
        //     ? value.length > 15
        //       ? value.slice(0, 15) + "..."
        //       : value
        //     : value.length > 20
        //     ? value.slice(0, 20) + "..."
        //     : value; // Optional: truncate long labels
        // },
      },
    },
    yAxis: {
      splitLine: { show: true },
      axisTick: { show: true },
      axisLine: { show: true },
      axisLabel: { show: true },
    },
    color: [`${theme.reports.negative}`],
    series: [
      {
        name: "hill",
        type: "pictorialBar",
        barCategoryGap: "0%",
        symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
        itemStyle: {
          opacity: 0.5,
        },
        emphasis: {
          itemStyle: {
            opacity: 1,
          },
        },
        data: chartData.map((item) => item["Negative Count"]),
        z: 10,
      },
    ],
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      chartData?.forEach((item) => {
        exportData?.push({
          Platforms: item.Platforms,
          ["Negative Count"]: prefixZero_for_singleDigit(
            item["Negative Count"]
          ),
        });
      });

      props.setexcelData(exportData);

      // props.setDownloadClicked(false);
    }
  }, [props.DownloadClicked, chartData]);

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        className="scrollable"
        sx={{
          width: "100%",
          height: "100%",
          padding: "12px",
          overflowY: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "3px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
      >
        {!loading ? (
          <Box
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {!loading && negative_count.length > 0 ? (
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <ReactEcharts
                  option={getOption}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                  onEvents={{
                    click: (params) => {
                      // Handle click events
                    },
                  }}
                />
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}

export default NegativeMentionsChart;
