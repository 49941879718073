import ActionUtility from "../../../utilities/ActionUtility";
import TicketWorkedOnEffect from "./TicketWorkedOnEffect";

export default class TicketWorkedOnAction {
  //TicketWorkedOn requests
  static REQUEST_TICKETWORKEDON = "TicketWorkedOnAction.REQUEST_TICKETWORKEDON";
  static REQUEST_TICKETWORKEDON_FINISHED =
    "TicketWorkedOnAction.REQUEST_TICKETWORKEDON_FINISHED";

  //METHODS

  //TicketWorked On get method
  static requestTicketWorkedOn(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketWorkedOnAction.REQUEST_TICKETWORKEDON,
        TicketWorkedOnEffect.requestTicketWorkedOn,
        params
      );
    };
  }
}
