import { BaseModel } from "sjs-base-model";
import PlayStoreDataModel from "./PlayStoreDataModel";

export default class PlayStoreHitsDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = { PlayStoreDataModel };
  created_on = "";
  created_time = "";
  customer_image = "";
  customer_name = "";
  modified_on = "";
  platform = "";
  ratings = "";
  reply_content = "";
  reply_created_at = "";
  reviewId = "";
  review_content = "";
  review_created_at = "";
  sentimental_value = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
