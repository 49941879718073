import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import LeadModel from "./model/LeadModel";
import LeadPostModel from "./model/LeadPostModel";
import LeadPutModel from "./model/LeadPutModel";

// LeadEffect
export default class LeadEffect {
  // calling an API according to the LEAD model
  static async requesLead(params) {
    const endpoint = environment.api.lead;
    return EffectUtility.getToModel(LeadModel, endpoint, params);
  }

  static async requesLeadClear() {
    return {};
  }

  static async requesLeadFilterClear() {
    return {};
  }

  // Get method for the LEAD filter
  static async requestLeadFilter(params) {
    const endpoint = environment.api.lead;
    return EffectUtility.getToModel(LeadModel, endpoint, params);
  }

  // put method for the LEAD
  static async requestPutLead(data, id) {
    const endpoint = environment.api.lead + "/" + id;
    return EffectUtility.putToModel(LeadPutModel, endpoint, data);
  }

  // post method for the LEAD
  static async requestPostLead(data) {
    const endpoint = environment.api.lead + "/add";
    return EffectUtility.postToModel(LeadPostModel, endpoint, data);
  }
  static async requestDeleteLead(id) {
    const endpoint = environment.api.lead + "/" + id;
    return EffectUtility.deleteToModel(LeadPostModel, endpoint);
  }
}
