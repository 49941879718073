import BaseReducer from "../../../utilities/BaseReducer";
import ResponseCategoryAction from "./ResponseCategoryAction";

// reducer function
export default class ResponseCategoryFilterReducer extends BaseReducer {
  initialState = {
    responseCategoryFilter: [],
  };

  [ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      responseCategoryFilter: action.payload,
    };
  }
}
