import ActionUtility from "../../../utilities/ActionUtility";
import PlayStoreEffect from "./PlayStoreEffect";

export default class PlayStoreAction {
  // playstore action with an function declaration
  static REQUEST_PLAYSTORE = "PlayStoreAction.REQUEST_PLAYSTORE";
  static REQUEST_PLAYSTORE_FINISHED =
    "PlayStoreAction.REQUEST_PLAYSTORE_FINISHED";

  static REQUEST_PLAYSTORE_FILTER = "PlayStoreAction.REQUEST_PLAYSTORE_FILTER";
  static REQUEST_PLAYSTORE_FILTER_FINISHED =
    "PlayStoreAction.REQUEST_PLAYSTORE_FILTER_FINISHED";

  static REQUEST_PLAYSTORE_CLEAR = "PlayStoreAction.REQUEST_PLAYSTORE_CLEAR";
  static REQUEST_PLAYSTORE_CLEAR_FINISHED =
    "PlayStoreAction.REQUEST_PLAYSTORE_CLEAR_FINISHED";

  static REQUEST_PLAYSTORE_PUT = "PlayStoreAction.REQUEST_PLAYSTORE_PUT";
  static REQUEST_PLAYSTORE_PUT_FINISHED =
    "PlayStoreAction.REQUEST_PLAYSTORE_PUT_FINISHED";

  // METHODS
  // playstore GET function
  static requestPlayStore(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlayStoreAction.REQUEST_PLAYSTORE,
        PlayStoreEffect.requestPlayStore,
        params,
        callBackFunction
      );
    };
  }

  static requestPlayStoreClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlayStoreAction.REQUEST_PLAYSTORE_CLEAR,
        PlayStoreEffect.requestPlayStoreClear
      );
    };
  }

  static requestPlayStoreFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlayStoreAction.REQUEST_PLAYSTORE_FILTER,
        PlayStoreEffect.requestPlayStoreFilter,
        params
      );
    };
  }

  static requestPlayStorePut(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PlayStoreAction.REQUEST_PLAYSTORE_PUT,
        PlayStoreEffect.requestPlayStorePut,
        data,
        id
      );
    };
  }
}
