import BaseReducer from "../../../utilities/BaseReducer";
import CategoryAction from "./CategoryAction";

// category reducer function
export default class CategoryReducer extends BaseReducer {
  initialState = {
    category: [],
  };

  [CategoryAction.REQUEST_CATEGORY_FINISHED](state, action) {
    return {
      ...state,
      category: action.payload,
    };
  }
}
