import BaseReducer from "../../../utilities/BaseReducer";
import DIYReportTemplateAction from "./DIYReportTemplateAction";

export default class DIYReportTemplateReducerFilter extends BaseReducer {
  initialState = {
    diyReportTemplateFilter: [],
  };

  [DIYReportTemplateAction.REQUEST_DIY_REPORT_TEMPLATE_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      diyReportTemplateFilter: action.payload,
    };
  }
}
