import environment from "environment";
import DiscordAccountModel from "./models/DiscordAccountGetModel";
import DiscordAccountPutModel from "./models/DiscordAccountPutModel";
import DiscordAccountDeleteModel from "./models/DiscordAccountDeleteModel";
import EffectUtility from "../../../utilities/EffectUtility";

export default class DiscordAccountEffect {
  // Get method for the Discord account
  static async requestDiscordAccount(params) {
    const endpoint = environment.api.discordAccount;
    return EffectUtility.getToModel(DiscordAccountModel, endpoint, params);
  }

  // put method for the Discord account filter
  static async requestPutDiscordAccount(data, id) {
    const endpoint = environment.api.discordAccount + "/" + id;
    return EffectUtility.putToModel(DiscordAccountPutModel, endpoint, data);
  }

  static async requestDeleteDiscordAccount(id) {
    const endpoint = environment.api.discordAccount + "/" + id;

    return EffectUtility.deleteToModel(DiscordAccountDeleteModel, endpoint);
  }
}
