import ActionUtility from "../../../utilities/ActionUtility";
import ResponseCategoryEffect from "./ResponseCategoryEffect";

export default class ResponseCategoryAction {
  // CATEGORY action with an function declaration
  static REQUEST_RESPONSE_CATEGORY =
    "ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY";
  static REQUEST_RESPONSE_CATEGORY_FINISHED =
    "ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY_FINISHED";

  // CATEGORY action with an function declaration
  static REQUEST_RESPONSE_CATEGORY_FILTER =
    "ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY_FILTER";
  static REQUEST_RESPONSE_CATEGORY_FILTER_FINISHED =
    "ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY_FILTER_FINISHED";

  //   CATEGORY post
  static REQUEST_POST_RESPONSE_CATEGORY =
    "ResponseCategoryAction.REQUEST_POST_RESPONSE_CATEGORY";
  static REQUEST_POST_RESPONSE_CATEGORY_FINISHED =
    "ResponseCategoryAction.REQUEST_POST_RESPONSE_CATEGORY_FINISHED";

  // CATEGORY put
  static REQUEST_PUT_RESPONSE_CATEGORY =
    "ProjectAction.REQUEST_PUT_RESPONSE_CATEGORY";
  static REQUEST_PUT_RESPONSE_CATEGORY_FINISHED =
    "ResponseCategoryAction.REQUEST_PUT_RESPONSE_CATEGORY_FINISHED";

  // METHODS
  // CATEGORY GET function
  static requestResponseCategory(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY,
        ResponseCategoryEffect.requestResponseCategory,
        params
      );
    };
  }

  // CATEGORY filter
  static requestResponseCategoryFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseCategoryAction.REQUEST_RESPONSE_CATEGORY_FILTER,
        ResponseCategoryEffect.requestResponseCategoryFilter,
        params
      );
    };
  }

  //   static requestProjectFinished() {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         ProjectAction.REQUEST_CATEGORY_FINISHED,
  //         ProjectEffect.requestProjectFinished
  //       );
  //     };
  //   }

  // CATEGORY post function
  static requestPostResponseCategory(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseCategoryAction.REQUEST_POST_RESPONSE_CATEGORY,
        ResponseCategoryEffect.requestPostResponseCategory,
        data
      );
    };
  }

  // CATEGORY put function
  static requestPutResponseCategory(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ResponseCategoryAction.REQUEST_PUT_RESPONSE_CATEGORY,
        ResponseCategoryEffect.requestPutResponseCategory,
        data,
        id
      );
    };
  }
}
