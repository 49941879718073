import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import TicketTypeModel from "./models/TicketTypeModel";
import TicketTypePostDataModel from "./models/TicketTypePostDataModel";
import TicketTypePutModel from "./models/TicketTypePutModel";
import TicketTypeBulkUpdatePutModel from "./models/TicketTypeBulkUpdatePutModel";

export default class TicketTypeEffect {
  //get tickettype request end point
  static async requestTicketType(params) {
    const endpoint = environment.api.ticketType;

    return EffectUtility.getToModel(TicketTypeModel, endpoint, params);
  }

  // Post api
  static async requestPostTicketType(data) {
    const endpoint = environment.api.ticketType + "/add";

    return EffectUtility.postToModel(TicketTypePostDataModel, endpoint, data);
  }

  //Put api
  static async requestPutTicketType(data, id) {
    const endpoint = environment.api.ticketType + "/" + id;

    return EffectUtility.putToModel(TicketTypePutModel, endpoint, data);
  }

  //GET filter TicketType dropdown end point
  static async requestFilterTicketType(params) {
    const endpoint = environment.api.filterTicketType;

    return EffectUtility.getToModel(TicketTypeModel, endpoint, params);
  }

  // Put method for the Ticket type bulk update
  static async requestPutTicketTypeBulkUpdate(data) {
    const endpoint = environment.api.ticketTypeUpdate;
    return EffectUtility.putToModel(TicketTypeBulkUpdatePutModel, endpoint, data);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
