import React, { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";

import { connect } from "react-redux";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import EmailAccountAction from "../../stores/emailAccount/EmailAccountAction";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};
const validationSchema = yup.object({
  account_name: yup
    .string("Enter your Account name")
    .trim()
    .min(1, "Account name should be of minimum 1 characters length")
    .required("Account name is required"),

  host: yup
    .string("Enter your Host id")
    .trim()
    .min(1, "Host id should be of minimum 1 characters length")
    .required("Incoming host id is required"),

  port: yup
    .string("Enter your port number")
    .trim()
    .min(1, "port number should be of minimum 1 characters length")
    .required("Incoming port number is required"),

  outgoing_host: yup
    .string("Enter your Host id")
    .trim()
    .min(1, "Outgoing Host id should be of minimum 1 characters length")
    .required("Outgoing host id is required"),

  outgoing_port: yup
    .string("Enter your port number")
    .trim()
    .min(1, "Outgoing port number should be of minimum 1 characters length")
    .required("Outgoing port number is required"),

  username: yup
    .string("Enter your username")
    .trim()
    .min(1, "username should be of minimum 1 characters length")
    .required("Mail Id required"),

  password: yup
    .string("Enter your password")
    .trim()
    .min(1, "password should be of minimum 1 characters length")
    .required("password is required"),

  // project: yup
  //   .object()
  //   .shape({})
  //   .required("Project Name is Required")
  //   .nullable(),
});

const AddEmailAccount = (props) => {
  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method
  let initial = {};
  initial = {
    account_name: "",
    host: "",
    port: "",
    outgoing_host: "",
    outgoing_port: "",
    username: "",
    password: "",
    is_ssl: false,
    // project: "",
  };

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          setLoading(true);
          props
            .dispatch(
              EmailAccountAction.requestPostEmailAccount(JSON.stringify(values))
            )
            .then(() => {
              let params = {
                page_limit: "none",
              };
              let account_params = {
                page_limit: "none",
                order_by: `[["account_name.keyword", "asc"]]`,
              };
              setLoading(false);

              props.onClose();
              props.setAdd_account("");
              props.dispatch(EmailAccountAction.requestEmailAccount());
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccount(account_params)
              );
              props.dispatch(
                PlatformAccountsAction.requestPlatformAccountFilter(params)
              );
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        {!props.edit ? (
                          <b>
                            <span className="span_first_letter">A</span>
                            dd &nbsp;
                            <span className="span_first_letter">E</span>mail
                            &nbsp;
                            <span className="span_first_letter">A</span>ccount
                            &nbsp;
                          </b>
                        ) : (
                          <b>
                            <span className="span_first_letter">E</span>
                            dit &nbsp;
                            <span className="span_first_letter">P</span>roject
                            &nbsp;
                          </b>
                        )}
                      </h3>
                    </div>
                    <div
                      className="col-2 closebtn"
                      onClick={() => {
                        props.onClose();
                        props.setAdd_account("");
                      }}
                    >
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account Name* :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="account_name"
                      size="small"
                      name="account_name"
                      placeholder="Account Name"
                      error={
                        Boolean(errors.account_name) &&
                        Boolean(touched.account_name)
                      }
                      helperText={
                        Boolean(touched.account_name) && errors.account_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Incoming Host* :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="host"
                      size="small"
                      name="host"
                      placeholder="Host"
                      error={Boolean(errors.host) && Boolean(touched.host)}
                      helperText={Boolean(touched.host) && errors.host}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Incoming Port* :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="port"
                      size="small"
                      name="port"
                      placeholder="Port"
                      error={Boolean(errors.port) && Boolean(touched.port)}
                      helperText={Boolean(touched.port) && errors.port}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Outgoing Host* :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="outgoing_host"
                      size="small"
                      name="outgoing_host"
                      placeholder="Outgoing Host"
                      error={
                        Boolean(errors.outgoing_host) &&
                        Boolean(touched.outgoing_host)
                      }
                      helperText={
                        Boolean(touched.outgoing_host) && errors.outgoing_host
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Outgoing Port* :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="outgoing_port"
                      size="small"
                      name="outgoing_port"
                      placeholder="Outgoing Port"
                      error={
                        Boolean(errors.outgoing_port) &&
                        Boolean(touched.outgoing_port)
                      }
                      helperText={
                        Boolean(touched.outgoing_port) && errors.outgoing_port
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Mail Id* :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="username"
                      size="small"
                      name="username"
                      placeholder="Mail id"
                      error={
                        Boolean(errors.username) && Boolean(touched.username)
                      }
                      helperText={Boolean(touched.username) && errors.username}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Password* :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      id="password"
                      size="small"
                      name="password"
                      placeholder="Password"
                      error={
                        Boolean(errors.password) && Boolean(touched.password)
                      }
                      helperText={Boolean(touched.password) && errors.password}
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}></Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <FormControlLabel
                      control={<Checkbox checked={values.is_ssl} />}
                      label="is ssl"
                      name="is_ssl"
                      value={values.is_ssl}
                      onChange={handleChange}
                    />{" "}
                  </Grid>
                </Grid>
                {/* 
                <Grid item lg={12} md={12} sm={12} container mt={1}>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    Project :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      name="project"
                      size="small"
                      // Calling AutoCompleteMultiSelect Component
                      value={values.project ? values.project : null}
                      component={AutoCompleteMultiSelect}
                      options={props.project_array ? props.project_array : []}
                      isOptionEqualToValue={(option, value) =>
                        //setting value property to custom value
                        option.project_name === value.project_name
                      }
                      getOptionLabel={(option) => option.project_name}
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Project",
                      }}
                    />
                  </Grid>
                </Grid> */}
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Add Account "}
                      type="submit"
                      width="100%"
                      loading={loading}
                    />
                  ) : (
                    <MuiButton
                      name={"Edit Project "}
                      type="submit"
                      width="100%"
                    />
                  )}
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                        Formik.resetForm();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AddEmailAccount);
