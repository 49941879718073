import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../../../views/App";
// import "./dashboard.css";
import jwtDecode from "jwt-decode";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
function StaticTicketSummaryChart(props) {
  const auth = localStorage.getItem("Auth");

  const authData = jwtDecode(auth);

  localStorage.setItem("userId", authData.sub);
  localStorage.setItem("userName", authData.preferred_username);

  let AssiggnedToObject = [];
  AssiggnedToObject.push({
    user_name: authData.preferred_username,
    full_name: authData.given_name + " " + authData.family_name,
  });
  let prioritylist = [];
  let filteredPriorityList = [
    {
      ticket_priority_name: "high",
      color: "#f10909",
      priority_id: "N8oPlpABqjMFJi8vKhC0",
    },
    {
      ticket_priority_name: "medium",
      color: "#eef207",
      priority_id: "udJLm5ABFjnxxkKIaj64",
    },
    {
      ticket_priority_name: "low",
      color: "#2ff109",
      priority_id: "vdJLm5ABFjnxxkKIkD6Q",
    },
  ];

  let statusList = [
    {
      status_id: "s9TrfJABX0C8S9THT0YF",
      status_name: "Open",
    },
    {
      status_id: "3bndkJABqjMFJi8vT7zJ",
      status_name: "Hold",
    },
    {
      status_id: "tNTrfJABX0C8S9THT0Y5",
      status_name: "Close",
    },
  ];

  let userlist = [
    {
      user_id: "96152a20-5978-424d-87e3-0144814cf25c",
      user_name: "123",
      full_name: "123 123",
      statusValue: "Offline",
    },
    {
      user_id: "25a7a0d2-4d85-4c9d-bfff-41fbcad8831f",
      user_name: "abhiram",
      full_name: "abhiram abhiram",
      statusValue: "Offline",
    },
    {
      user_id: "75a71360-ca19-4004-a079-0157ac60efed",
      user_name: "admin",
      full_name: "Admin admin",
      statusValue: "Offline",
    },
    {
      user_id: "95835080-bd02-42d4-a5ef-4c5dd1aee71e",
      user_name: "agent1",
      full_name: "agent1 agent1",
      statusValue: "Offline",
    },
    {
      user_id: "12d8a59e-d367-421e-9a55-7fe1f71458a5",
      user_name: "agent2",
      full_name: "agent2 agent1",
      statusValue: "Offline",
    },
    {
      user_id: "0615f9aa-7b44-4c50-8928-48ae6c21805f",
      user_name: "agent3",
      full_name: "agent3 agent3",
      statusValue: "Offline",
    },
    {
      user_id: "e79bee4e-3816-48ec-bb9a-0d88f4b2dfba",
      user_name: "agent4",
      full_name: "agent4 agent4",
      statusValue: "Offline",
    },
    {
      user_id: "3dd25978-2d2a-45fc-9b94-568200fa2c8f",
      user_name: "aiplexagent",
      full_name: "aiplex agent",
      statusValue: "Offline",
    },
    {
      user_id: "dc3577cd-9b22-4159-8406-5914f35247bf",
      user_name: "aiplexagent1",
      full_name: "aiplex agent",
      statusValue: "Offline",
    },
    {
      user_id: "4fe1e81d-5c7c-4c88-aa77-d9f99f17787b",
      user_name: "aiplexagent2",
      full_name: "aiplex agent",
      statusValue: "Offline",
    },
    {
      user_id: "4f39cc6f-592b-4dce-8156-1863e5413bb9",
      user_name: "aiplexagent5",
      full_name: "aiplex agent5",
      statusValue: "Offline",
    },
    {
      user_id: "7c877971-074d-4993-82c9-73b8408ac4e3",
      user_name: "aiplexorgadmin",
      full_name: "aiplex orgadmin",
      statusValue: "Offline",
    },
    {
      user_id: "f03d5449-a068-400b-92bb-18263cd385b9",
      user_name: "friend",
      full_name: "friend s",
      statusValue: "Offline",
    },
    {
      user_id: "cb51ff35-8043-445f-8093-d647438b7a02",
      user_name: "sai",
      full_name: "gg sai",
      statusValue: "Offline",
    },
    {
      user_id: "cb7bee74-e0dd-4454-8d23-1a8cc63ff6af",
      user_name: "anupjoshi",
      full_name: "Joshi joshi",
      statusValue: "Offline",
    },
    {
      user_id: "c95420af-df8d-4b75-b2e9-61e067632af6",
      user_name: "k_user2",
      full_name: "k_user 2",
      statusValue: "Offline",
    },
    {
      user_id: "f43b0583-e921-4246-b87b-7175de4bb855",
      user_name: "k_user3",
      full_name: "k_user 3",
      statusValue: "Offline",
    },
    {
      user_id: "db119a31-f3b5-4dc8-94b9-72026e961146",
      user_name: "user1",
      full_name: "k-user 1",
      statusValue: "Offline",
    },
    {
      user_id: "68d1431c-9a13-4946-b003-6dfe012c7b70",
      user_name: "karthika",
      full_name: "karthika karthika",
      statusValue: "Offline",
    },
    {
      user_id: "3b71dd12-0821-4eee-ad2b-55412a02eb9b",
      user_name: "kuser4",
      full_name: "kuser 4",
      statusValue: "Offline",
    },
    {
      user_id: "ae95dc4c-31ca-465c-9b94-447dd4d2fa09",
      user_name: "kuser5",
      full_name: "kuser 5",
      statusValue: "Offline",
    },
    {
      user_id: "0798940b-8a1f-4dd5-ab70-e43082eb601b",
      user_name: "panduranga",
      full_name: "panduranga panduranga",
      statusValue: "Offline",
    },
    {
      user_id: "3f04059b-9cbc-4a7e-8192-7fc690bcb8f3",
      user_name: "rakesh",
      full_name: "rakesh g",
      statusValue: "Offline",
    },
    {
      user_id: "a308084a-c05e-4ad1-8211-e3da421fdaee",
      user_name: "ajit",
      full_name: "Ram Ram",
      statusValue: "Offline",
    },
    {
      user_id: "33915f69-cfe0-473e-9081-6683bfdcbb00",
      user_name: "ram",
      full_name: "Ram Ram",
      statusValue: "Offline",
    },
    {
      user_id: "5c7f4a18-e4ef-418d-a42e-96d33dfe4643",
      user_name: "sachin",
      full_name: "sachin sachin",
      statusValue: "Offline",
    },
    {
      user_id: "279a6f4b-ac36-46bd-be5f-69f4930681da",
      user_name: "shivasharanya",
      full_name: "shivasharanya shivasharanya",
      statusValue: "Offline",
    },
    {
      user_id: "22cbe1f3-f31f-4c6f-8449-90893afe3f17",
      user_name: "smruthiorg",
      full_name: "smruthiorg smruthiorg",
      statusValue: "Offline",
    },
    {
      user_id: "1b3d39ee-9501-4f04-8cc3-37d1cfe9a31b",
      user_name: "sneha_cs",
      full_name: "sneha cs",
      statusValue: "Offline",
    },
    {
      user_id: "88a76e71-927a-4e2a-86e7-7726741f2271",
      user_name: "sneha",
      full_name: "sneha g",
      statusValue: "Offline",
    },
    {
      user_id: "6c5a98cf-f404-4f0e-a17a-0b108a6f7eda",
      user_name: "user2",
      full_name: "user 2",
      statusValue: "Offline",
    },
    {
      user_id: "0ec13503-77a3-4d53-a085-cd7ea1dbdd86",
      user_name: "useragent1",
      full_name: "user agent",
      statusValue: "Offline",
    },
    {
      user_id: "2bf3ae5a-b746-4a60-b347-bda12108aa50",
      user_name: "useragent2",
      full_name: "user agent",
      statusValue: "Offline",
    },
    {
      user_id: "e15a0caf-87b0-4dac-9b66-826c3be849b0",
      user_name: "useragent3",
      full_name: "user agent3",
      statusValue: "Offline",
    },
    {
      user_id: "2c6c3a86-7ac5-4a07-9cc6-3a627475141a",
      user_name: "useorgadmin",
      full_name: "User orgadmin",
      statusValue: "Offline",
    },
    {
      user_id: "f8306055-ef5b-4773-b6d3-d9af824ec9ca",
      user_name: "vaishnavi",
      full_name: "vaishnavi vaishnavi",
      statusValue: "Offline",
    },
    {
      user_id: "641dac1e-20ea-40e2-84b0-94539dd540c2",
      user_name: "vaniel",
      full_name: "vaniel vaniel",
      statusValue: "Offline",
    },
    {
      user_id: "42f9fa0d-0ae9-49a6-b4b8-1065a5824956",
      user_name: "vivek",
      full_name: "vivek  vivek",
      statusValue: "Offline",
    },
    {
      user_id: "5bd58664-921b-4536-8469-611f6f04f096",
      user_name: "yash",
      full_name: "yash yash",
      statusValue: "Offline",
    },
  ];
  const returnStatusName = (statusId) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusId;
      });

    return filtered_status.length > 0 ? filtered_status[0].status_name : null;
  };

  const returnStatusObject = (statusId) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusId;
      });

    return filtered_status.length > 0 && filtered_status[0];
  };

  const today = new Date();

  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);
  // start from today's date last 7 days
  const last7Days = [...Array(7).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date;
  });

  // last 30 days
  let date2 = new Date();
  let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  // ***************************************
  // last 3 months
  let date3 = new Date();
  let last3Month = date3.setMonth(date3.getMonth() - 3);

  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  const activitiesData = JSON.parse(localStorage.getItem("activities"));

  let uniqueList = [
    {
      platform_id: "1",
      platform_name: "Twitter",
      platform_alias: " X (Twitter)",
    },
    {
      platform_id: "7",
      platform_name: "Ambition Box",
      platform_alias: "AmbitionBox",
    },
    {
      platform_id: "4",
      platform_name: "Facebook",
      platform_alias: "Facebook",
    },
    {
      platform_id: "12",
      platform_name: "Glassdoor",
      platform_alias: "Glassdoor",
    },
    {
      platform_id: "6",
      platform_name: "GMB",
      platform_alias: "Google Business Profile (GMB)",
    },
    {
      platform_id: "11",
      platform_name: "Playstore",
      platform_alias: "Google Play Store",
    },
    {
      platform_id: "9",
      platform_name: "Instagram",
      platform_alias: "Instagram",
    },
    {
      platform_id: "15",
      platform_name: "LinkedIn",
      platform_alias: "LinkedIn",
    },
    {
      platform_id: "5",
      platform_name: "Email",
      platform_alias: "Mail",
    },
    {
      platform_id: "14",
      platform_name: "None",
      platform_alias: "Manual Ticket",
    },
    {
      platform_id: "13",
      platform_name: "Quora",
      platform_alias: "Quora",
    },
    {
      platform_id: "10",
      platform_name: "Telegram",
      platform_alias: "Telegram",
    },
    {
      platform_id: "8",
      platform_name: "Trust Pilot",
      platform_alias: "Trustpilot",
    },
    {
      platform_id: "3",
      platform_name: "Whatsapp",
      platform_alias: "WhatsApp",
    },
    {
      platform_id: "2",
      platform_name: "Youtube",
      platform_alias: "YouTube",
    },
  ];

  const dashboardDataPerRow = 5;

  const [dashboardData, setdashboardDataPerRow] = useState(dashboardDataPerRow);

  let dasboard_modified_data = [
    {
      count: 30,
      key: "s9TrfJABX0C8S9THT0YF",
      priority: [
        {
          count: 11,
          key: "low",
        },
        {
          count: 6,
          key: "high",
        },
        {
          count: 5,
          key: "medium",
        },
      ],
      status_name: "Open",
    },
    {
      count: 14,
      key: "3bndkJABqjMFJi8vT7zJ",
      priority: [
        {
          count: 1,
          key: "c2",
        },
      ],
      status_name: "Hold",
    },
    {
      count: 42,
      key: "tNTrfJABX0C8S9THT0Y5",
      priority: [
        {
          count: 29,
          key: "low",
        },
        {
          count: 12,
          key: "medium",
        },
        {
          count: 7,
          key: "high",
        },
      ],
      status_name: "Close",
    },

    {
      count: 22,
      status_name: "Ignored",
      priority: [],
    },
    {
      count: 18,
      status_name: "Unassigned",
      priority: [],
    },
  ];

  const returnTime = (time) => {
    function getFullTimeStringFromHours(hours) {
      const totalHours = Math.floor(hours);
      const minutes = Math.floor((hours - totalHours) * 60);
      const seconds = Math.floor(((hours - totalHours) * 60 - minutes) * 60);
      const formattedTime = `${totalHours}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return formattedTime;
    }

    return (
      <p style={{ fontSize: "16px", margin: 0, padding: 0 }}>
        {getFullTimeStringFromHours(time)}
      </p>
    );
  };

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      setOpen(false);
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const summaryBoxRef = useRef(null);

  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;
      const firstBoxHeight = 0.1 * outerBoxHeight;
      const secondBoxHeight = 0.9 * outerBoxHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `50px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 50
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount
  const [renderKey, setRenderKey] = useState(0);

  const onHoldCount = 14;

  const resolvedCount = 42;

  const getOption2 = () => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: ["On Hold", "Resolved"],
      },
      yAxis: {
        type: "value",
      },
      confine: true,
      grid: {
        top: "15%",
        left: "5%",
        right: "5%",
        bottom: "10%",
        containLabel: true,
      },
      series: [
        {
          barWidth: "30%",
          data: [
            {
              value: onHoldCount,
              itemStyle: { color: "#6BA5B2" },
            },
            {
              value: resolvedCount,
              itemStyle: { color: theme.platform[`color7`] },
            },
          ],
          type: "bar",
        },
      ],
    };
  };

  const getOption1 = () => {
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 530 &&
        props.ChartContainerWidth.current > 530) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }

    const keys = [];
    dasboard_modified_data.forEach((item) => {
      keys.push(item.status_name);
    });
    const colors1 = ["#42808E", "#6BA5B2", "#B4D5DD", "#92C4D0", "#E2E2E2"];
    const seriesData = dasboard_modified_data.map((item, index) => ({
      name: item.status_name,
      value: item.count,
      itemStyle: {
        color: colors1[index],
      },
    }));

    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: shouldShowLegend,
        type: "scroll",
        orient: "horizontal",
        icon: "circle",
        left: "center",
        top: 0,
      },

      grid: {
        top: "15%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },

      dataZoom: [
        {
          type: "inside",
        },
      ],
      confine: true,
      series: [
        {
          type: "pie",
          radius: "70%",
          data: seriesData,
          label: {
            show: true,
            position: "outside",
            formatter: "{c}", // Show name and value
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          labelLine: {
            show: true,
            smooth: true,
            lineStyle: {
              type: "solid",
            },
          },
          emphasis: {
            focus: "series",
          },
          selectedMode: "series",
          padAngle: 0.7,
        },
      ],
    };
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // position: "relative",
      }}
      key={renderKey}
    >
      <LoadingIndicator
        isActive={props.isRequesting}
        style={{
          height: "100%",
        }}
      >
        <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box
            style={{
              display: "none",
              flexDirection: "column",
            }}
          ></Box>

          {!props.isRequesting ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 1,
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  boxSizing: "border-box",
                }}
              >
                {!props.isRequesting ? (
                  <ReactEcharts
                    option={getOption1()}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                ) : (
                  <LoadingBgImage />
                )}
              </Box>
              <hr
                style={{
                  height: "100%",
                  margin: 0,
                  border: "0",
                  borderLeft: "1px solid rgba(0,0,0,.1)",
                }}
              />
              <Box
                sx={{
                  width: "50%",
                  height: "100%",
                  //   display: "flex",
                  //   flexDirection: "column",
                  boxSizing: "border-box",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "60%",
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <ReactEcharts
                    option={getOption2()}
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                </Box>
                <hr
                  style={{
                    width: "100%",
                    border: "none",
                    borderTop: "1px solid rgba(0,0,0,.1)",
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    height: "40%",
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    mt: 2,
                    paddingX: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Card
                        sx={{
                          width: "100%",
                          height: "100%",
                          bgcolor: "#42808E",
                          border: "1px solid #42808E", // Border color
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
                          borderRadius: "8px", // Rounded corners
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden", // Hide overflowing content
                          textOverflow: "ellipsis", // Text ellipsis
                        }}
                      >
                        <Box
                          sx={{
                            color: "#ffff",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            textOverflow: "hidden",
                            textOverflow: "ellipsis", // Text ellipsis
                            overflow: "hidden", // Hide overflowing text
                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                        >
                          <Typography
                            component={"span"}
                            sx={{
                              color: "#ffff",
                              fontSize: 14,
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis", // Add ellipsis for overflowing text
                              whiteSpace: "nowrap", // Prevent text wrapping
                              width: "100%", // Ensure it takes full width
                            }}
                          >
                            First Response Time
                          </Typography>
                          <Typography
                            component={"span"}
                            sx={{
                              color: "#ffff",
                              fontSize: 16,
                            }}
                          >
                            {"6:55:42"}
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>

                    <Grid item xs={6}>
                      <Card
                        sx={{
                          width: "100%",
                          height: "100%",
                          bgcolor: "#42808E", // Light blue color
                          border: "1px solid #42808E", // Border color
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow effect
                          borderRadius: "8px", // Rounded corners
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden", // Hide overflowing content
                          textOverflow: "ellipsis", // Text ellipsis
                        }}
                      >
                        <CardContent
                          sx={{
                            color: "#ffff",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            textOverflow: "ellipsis", // Text ellipsis
                            overflow: "hidden", // Hide overflowing text
                            whiteSpace: "nowrap", // Prevent text wrapping
                          }}
                        >
                          <Typography
                            component={"span"}
                            sx={{
                              color: "#ffff",
                              fontSize: 14,
                              mt: 0.5,
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis", // Add ellipsis for overflowing text
                              whiteSpace: "nowrap", // Prevent text wrapping
                              width: "100%", // Ensure it takes full width
                            }}
                          >
                            Turn Around Time
                          </Typography>
                          <Typography
                            component={"span"}
                            sx={{ fontSize: 16, color: "#ffff" }}
                          >
                            {"82:42:11"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "30vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingBgImage />
            </Box>
          )}
        </Box>
      </LoadingIndicator>
    </Box>
  );
}

export default StaticTicketSummaryChart;
