import BaseReducer from "../../../../utilities/BaseReducer";
import ClaimRequestAction from "./ClaimRequestAction";

export default class ClaimRequestReducer extends BaseReducer {
  initialState = {
    revenueClaimRequest: [],
  };
  [ClaimRequestAction.REQUEST_REVENUE_CLAIM_REQUEST_FINISHED](state, action) {
    return {
      ...state,
      revenueClaimRequest: action.payload,
    };
  }
}
