import { BaseModel } from "sjs-base-model";
import TagHitsDataModel from "./TagHitsDataModel";

export default class TagHitsModel extends BaseModel {
  //Tag list hits model
  hits = [{ TagHitsDataModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
