import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import StarIcon from "@mui/icons-material/Star";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Fade,
  FormControl,
  FormControlLabel,
  ListItem,
  OutlinedInput,
  Paper,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import environment from "environment";

import InputAdornment from "@mui/material/InputAdornment";
import PriorityAction from "../../ormTicketing/stores/priority/PriorityAction";
import { useTheme } from "@mui/material/styles";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import PaginationUI from "../../components/pagination/Pagination";
import UserAction from "../../ormTicketing/stores/user/UserAction";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import MuiButton from "../../components/muiButton/MuiButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StyleIcon from "@mui/icons-material/Style";
import "./Reports.css";

// Autocomplete
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import { useLocation } from "react-router";
import FacebookIcon from "@mui/icons-material/Facebook";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DatePicker from "../../components/dateRangePickerComponent/DateRangePicker";
import AppleIcon from "@mui/icons-material/Apple";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAmilia,
  faAppStore,
  faGoogle,
  faGooglePlay,
  faXTwitter,
  faQuora,
} from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { CSVLink } from "react-csv";
import { theme } from "../App";
import StatusAction from "../../ormTicketing/stores/status/StatusAction";
import CustomTicketFieldsAction from "../../stores/customticketfields/CustomTicketFieldsAction";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { set } from "lodash";
import PlatformAction from "../../ormTicketing/stores/platform/PlatformAction";
import { FullName } from "../../components/ReusedFunctions";
import { Form, Formik } from "formik";
import CustomPopper from "./CustomPopper";
import CategoryFilterDialog from "../../ormTicketing/views/ticketsListView/allTickets/filterPoppers/CategoryFilterDialog";
import TicketsAction from "../../ormTicketing/stores/tickets/TicketsAction";
import {
  faChartBar,
  faClock,
  faComments,
  faTicket,
  faTicketSimple,
} from "@fortawesome/free-solid-svg-icons";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import twitterLogoWebp from "../../Images/ticket_image.webp";
import tat_image from "../../Images/TAT_image.webp";
import mentions_image from "../../Images/mentions_image.webp";
import custom_report_image from "../../Images/custom_report_image.webp";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import TagAction from "../../ormTicketing/stores/tag/TagAction";
const XLSX = require("xlsx");

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TicketsAction.REQUEST_TICKETS_FOR_REPORTS,
    TicketsAction.REQUEST_TICKETS_BY_FIELDS,
    PriorityAction.REQUEST_PRIORITY_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    TicketsAction.REQUEST_TICKETS_FOR_REPORTS_WITH_PAGINATION,
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS,
    StatusAction.REQUEST_STATUS,
    UserAction.REQUEST_USER_FILTER,
    PlatformAction.REQUEST_PLATFORM,
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER,
    TagAction.REQUEST_TAG_FILTER,
  ]),
  customticketfields: state.customticketfields.customticketfields || {},
  ticketsReport: state.ticketsReport.ticketsReport || {},
  ticketFields: state.ticketFields.ticketFields || [],
  filterpriority: state.filterpriority.filterpriority.hits || [],
  statusFilter: state.statusFilter.statusFilter || [],
  ticketsReportsWithPagination:
    state.ticketsReportsWithPagination.ticketsReportsWithPagination || [],
  pagination:
    state.ticketsReportsWithPagination.ticketsReportsWithPagination || [],
  status: state.status.status.hits || [],
  userfilter: state.userfilter.userfilter || {},
  tagfilter: state.tagfilter.tagfilter || {},
  platform: state.platform.platform.hits || {},
  customticketfields_filter:
    state.customticketfields_filter.customticketfields_filter.hits || {},
});

// Reports
function Reports(props) {
  // theme
  const [selected, setSelected] = useState([]);
  const [page_limit, setPage_limit] = useState(15);

  //using useLocation for props from  dashboard page

  //initial props state assigning with useeffect
  const [exportData, setExportData] = useState([]);
  const [exportEnable, setExportEnable] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = React.useState([]);

  const [columnsSelected, setColumnsSelected] = useState([]);
  const [statusSelected, setStatusSelected] = useState([]);
  const [platformSelected, setPlatformSelected] = useState([]);
  const [assigned_to_selected, setAssigned_To_Selected] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [activitySelected, setActivitySelected] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [category, setCategory] = useState([]);

  const [prioritySelected, setPrioritySelected] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const textField = props.customticketfields_filter?.hits?.find(
    (item) => item?._source?.field_type === "text"
  )?._source?.field_name;

  function fetchTicketByFields(params) {
    props.dispatch(TicketsAction.requestTicketsByFields(params));
  }

  function fetchStatusFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(StatusAction.requestStatusFilter(params));
  }

  function fetchtagsFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(TagAction.requestTagFilter(params));
  }

  function fetchCustomticketfields(paramater) {
    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFields(paramater)
    );
  }
  function fetchPlatform() {
    let param = {
      //   level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };

    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function fetchUserFilter() {
    const param = {
      page_limit: "none",
    };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  function fetchStatus(params) {
    props.dispatch(StatusAction.requestStatus(params));
  }

  // customticketfield_api
  function fetchCustomticketfieldsFilter() {
    const paramater = {
      page_limit: "none",
    };

    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFieldsFilter(paramater)
    );
  }

  // filter priority dispatch function
  function fetchFilterPriority(params) {
    props.dispatch(PriorityAction.filterRequestPriority(params));
  }

  const activitiesData = JSON.parse(localStorage.getItem("activities"));

  useEffect(() => {
    // ************************************************************
    // Priority Api with an page_limit none
    const param = { page_limit: "none" };

    // if (props?.ticketFields === undefined || props?.ticketFields.length === 0) {
    fetchTicketByFields(param);
    // }

    fetchStatus();

    const filter = [];

    filter.push(`["field_type.keyword","must","","match","category"]`);

    let paramater = {
      filters: `[${filter}]`,
    };
    fetchCustomticketfields(paramater);

    // if (
    //   props?.customticketfields_filter === undefined ||
    //   Object.keys(props.customticketfields_filter).length === 0
    // ) {
    fetchCustomticketfieldsFilter();
    // }

    // if (
    //   props?.platform === undefined ||
    //   Object.keys(props.platform).length === 0
    // ) {
    fetchPlatform();
    // }

    // if (
    //   props?.statusFilter === undefined ||
    //   Object.keys(props.statusFilter).length === 0
    // ) {
    fetchStatusFilter();
    fetchtagsFilter();

    // }

    if (props?.userfilter === undefined || props?.userfilter.length === 0) {
      fetchUserFilter();
    }

    const priority_param = {
      page_limit: "none",
      order_by: `[["ticket_priority_name.keyword", "asc"]]`,
    };

    // if (
    //   props?.filterpriority === undefined ||
    //   props?.filterpriority.length === 0
    // ) {
    fetchFilterPriority(priority_param);
    // }
  }, []);

  let treeArray = [];

  treeArray = [
    // { "custom Values": [] },
    { ticket: [] },
    { mention: [] },
    // { "Advanced Ticket Details": [] },
    // { "Ticket Additionals": [] },
    // { customer: [] },
    { "Turn Around Time": [] },
  ];

  let fieldName = null;

  // props.customticketfields.hits &&
  //   props.customticketfields.hits.hits.map((item) => {
  //     fieldName = item._source.field_name;
  //     if (
  //       props.ticketFields.data &&
  //       props.ticketFields.data.includes(item._source.field_name)
  //     ) {
  //       treeArray[0]["custom Values"].push(item._source.field_name);
  //     }
  //   });

  let ticket_additionals = [
    "category",
    "ticket_type",
    "priority",
    "tags",
    "souce_link",
  ];

  let advanced = [
    "ticket_additionals",
    "related_tickets",
    "Attachment_url",
    "is_child_ticket",
  ];

  function getSentimentCategory(sentimentValue) {
    if (sentimentValue === 0) {
      return "Neutral";
    } else if (sentimentValue < 0) {
      return "Negative";
    } else {
      return "Positive";
    }
  }

  const dateLists = [
    "Last 2 Days",
    "Today",
    "Previous Day",
    "Last 7 Days",
    "Last 30 Days",
    "Last 3 Months",
    "Custom",
  ];

  // props.ticketFields.data &&
  //   props.ticketFields.data.map((item) => {
  //     if (ticket.includes(item)) {
  //       treeArray[0].ticket.push(item);
  //     }
  //   });

  // props.ticketFields.data &&
  //   props.ticketFields.data.map((item) => {
  //     if (ticket_additionals.includes(item)) {
  //       treeArray[4]["Ticket Additionals"].push(item);
  //     }
  //   });

  // props.ticketFields.data &&
  //   props.ticketFields.data.map((item) => {
  //     if (advanced.includes(item)) {
  //       treeArray[3]["Advanced Ticket Details"].push(item);
  //     }
  //   });

  // props.ticketFields.data &&
  //   props.ticketFields.data.map((item) => {
  //     if (customer.includes(item)) {
  //       treeArray[5].customer.push(item);
  //     }
  //   });

  // props.ticketFields.data &&
  //   props.ticketFields.data.map((item) => {
  //     if (turn_around_time.includes(item)) {
  //       treeArray[2]["Turn Around Time"].push(item);
  //     }
  //   });

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  let statuslist = [];
  if (props.status.hits) {
    props.status.hits.forEach((value) => {
      statuslist.push({
        status_id: value._id,
        status_name: value._source.status_name,
      });
    });
  }

  let userlist = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((val) => {
      return userlist.push({
        user_id: val.id,
        user_name: val.username,
        fullname: FullName(val),
      });
    });
  }

  let platformList = [];

  if (props.platform.hits) {
    props.platform.hits.map((item) => {
      platformList.push({
        platform_name: item._source.platform_name,
        platform_id: item._id,
      });
    });
  }

  const [platformCheck, setPlatformCheck] = useState([]);

  const handlePlatformCheckBox = (value) => {
    if (platformCheck.includes(value)) {
      setPlatformCheck(platformCheck.filter((item) => item !== value));
    } else {
      setPlatformCheck([...platformCheck, value]);
    }
  };

  let activityList = [];
  if (activitiesData) {
    activitiesData.hits.hits.map((item) => {
      activityList.push({
        activity_id: item._id,
        activity_name: item._source.activities,
        platform_id: item._source.platform.platform_id,
        platform_name: item._source.platform.platform_name,
      });
    });
  }

  const filteredActivities = activityList.filter((obj1) => {
    return platformCheck.some((obj2) => {
      return obj1.platform_id === obj2;
    });
  });

  const [statusCheck, setStatusCheck] = useState([]);
  const [tagsCheck, setTagsCheck] = useState([]);

  const handleStatusCheckBox = (value) => {
    if (statusCheck.includes(value)) {
      setStatusCheck(statusCheck.filter((item) => item !== value));
    } else {
      setStatusCheck([...statusCheck, value]);
    }
  };

  const handleTagsCheckBox = (value) => {
    if (tagsCheck.includes(value)) {
      setTagsCheck(tagsCheck.filter((item) => item !== value));
    } else {
      setTagsCheck([...tagsCheck, value]);
    }
  };

  const [userCheck, setUserCheck] = useState([]);

  const handleUserCheckBox = (value) => {
    if (userCheck.includes(value)) {
      setUserCheck(userCheck.filter((item) => item !== value));
    } else {
      setUserCheck([...userCheck, value]);
    }
  };

  const [unassignedChecked, setUnassignedChecked] = useState(false);

  const [priorityCheck, setPriorityCheck] = useState([]);

  const handlePriorityCheckBox = (value) => {
    if (priorityCheck.includes(value)) {
      setPriorityCheck(priorityCheck.filter((item) => item !== value));
    } else {
      setPriorityCheck([...priorityCheck, value]);
    }
  };

  const [activityCheck, setActivityCheck] = useState([]);

  const handleActivityCheckBox = (value) => {
    if (activityCheck.includes(value)) {
      setActivityCheck(activityCheck.filter((item) => item !== value));
    } else {
      setActivityCheck([...activityCheck, value]);
    }
  };

  let priorityList = [];

  if (props.filterpriority.hits) {
    props.filterpriority.hits.map((item) => {
      priorityList.push({
        priority_id: item._id,
        priority_name: item._source.ticket_priority_name,
      });
    });
  }

  function grandChildren(item) {
    if (item === "status") {
      let status_children = [];

      statuslist.map((item) => {
        status_children.push({
          value: item.status_id,
          label: capitalize(item.status_name),
        });
      });

      return status_children && status_children;
    } else if (item === "platform") {
      let platform_children = [];

      platformList.map((item) => {
        platform_children.push({
          value: item.platform_id,
          label: capitalize(item.platform_name),
        });
      });

      return platform_children && platform_children;
    } else if (item === "assigned_to") {
      let user_children = [];

      userlist.map((item) => {
        user_children.push({
          value: item.user_name,
          label: capitalize(item.fullname),
        });
      });

      return user_children && user_children;
    } else if (item === "activities") {
      let activity_children = [];

      filteredActivities &&
        filteredActivities.map((item) => {
          activity_children.push({
            value: item.activity_id,
            label: item.activity_name,
          });
        });

      return activity_children && activity_children;
    } else if (item === "priority") {
      let priority_children = [];
      priorityList &&
        priorityList.map((item) => {
          priority_children.push({
            value: item.priority_id,
            label: item.priority_name,
          });
        });

      return priority_children && priority_children;
    }
    return [];
  }

  function childrenPages(item) {
    let List = [];
    item.map((event) => {
      List.push({
        value: event,
        label: event,
        children: grandChildren(event),
      });
    });

    return List && List;
  }
  let nodesList = [];
  treeArray &&
    treeArray.map((item) => {
      nodesList.push({
        value: Object.keys(item)[0],
        label: capitalize(Object.keys(item)[0]),
        children: childrenPages(Object.values(item)[0]),
      });
    });
  const columns = [
    {
      field: "id",
      headerName: "SL No.",
      width: 200,
    },
    {
      field: "ticket_id",
      headerName: "Ticket Id",
      width: 200,
      hide: selected.includes("_id") ? false : true,
    },

    {
      field: "rating",
      headerName: "Rating",
      width: 300,
      // renderCell: (params) => <RowIcons params={params.row} />,
      hide: selected.includes("ticket_desc") ? false : true,
    },

    // {
    //   field: "description",
    //   headerName: "First User Mention",
    //   width: 300,
    //   renderCell: (params) => <RowIcons params={params.row} />,
    //   hide: selected.includes("ticket_desc") ? false : true,
    // },
    {
      field: "created_on",
      headerName: "Ticket Created On",
      width: 200,
      hide: selected.includes("created_on") ? false : true,
    },
    {
      field: "priority",
      headerName: "Ticket Priority",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: (params) => <RowPriority params={params.row} />,
      hide: selected.includes("priority") ? false : true,
    },
    {
      field: "customer_name",
      headerName: "Platform User",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("customer") ? false : true,
    },
    {
      field: "status",
      headerName: "Ticket Status",
      headerAlign: "center",
      align: "center",
      width: 200,
      hide: selected.includes("status") ? false : true,
    },
    {
      field: "platform",
      headerName: "Platform",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("platform") ? false : true,
    },
    {
      field: "Attachment_url",
      headerName: "Attachment Url",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("Attachment_url") ? false : true,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("account_name") ? false : true,
    },
    {
      field: "activities",
      headerName: "Platform Activity",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("activities") ? false : true,
    },
    {
      field: "assigned_to",
      headerName: "Currently Assigned To",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("assigned_to") ? false : true,
    },
    // {
    //   field: "category",
    //   headerName: "Category",
    //   headerAlign: "left",
    //   align: "left",
    //   width: 200,
    //   hide: selected.includes("category") ? false : true,
    // },

    {
      field: "first_ticket_assigned_to",
      headerName: "First Ticket Assigned To",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("first_ticket_assigned_to") ? false : true,
    },

    {
      field: "first_user_mention",
      headerName: "First User Mention",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("first_user_mention") ? false : true,
    },

    {
      field: "latest_user_mention",
      headerName: "Latest User Mention",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("latest_user_mention") ? false : true,
    },

    {
      field: "first_user_mention_on",
      headerName: "First User Mentioned On",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("first_user_mention_on") ? false : true,
    },

    {
      field: "frt_breach",
      headerName: "FRT Breach",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("frt_breach") ? false : true,
    },

    {
      field: "first_ticket_assigned_date_time",
      headerName: "First Ticket Assigned Date and Time",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("first_ticket_assigned_date_time") ? false : true,
    },

    {
      field: "first_response_message_date_time",
      headerName: "First Response Message Date and Time",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("first_response_message_date_time")
        ? false
        : true,
    },

    {
      field: "feed_id",
      headerName: "Feed ID",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("feed_id") ? false : true,
    },
    {
      field: "is_child_ticket",
      headerName: "Is Child Ticket",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("is_child_ticket") ? false : true,
    },
    {
      field: "modified_by",
      headerName: "Modified By",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("modified_by") ? false : true,
    },
    {
      field: "created_by",
      headerName: "Created By",
      headerAlign: "left",
      align: "left",
      width: 200,
      hide: selected.includes("created_by") ? false : true,
    },
    {
      field: "modified_on",
      headerName: "Ticket Updated On",
      width: 200,
      hide: selected.includes("modified_on") ? false : true,
    },
    {
      field: "content_type",
      headerName: "Content Type",
      width: 200,
      hide: selected.includes("content_type") ? false : true,
    },
    {
      field: "sentiment_value",
      headerName: "Sentiment",
      width: 200,
      hide: selected.includes("sentiment_value") ? false : true,
    },
    {
      field: "customer",
      headerName: "User Name",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "user_total_mentions",
      headerName: "User Total Mention",
      width: 200,
      hide: selected.includes("user_total_mentions") ? false : true,
    },

    {
      field: "customer_phone",
      headerName: "Phone Number",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "store_code",
      headerName: "Store Code",
      width: 200,
      hide: selected.includes("store_code") ? false : true,
    },

    {
      field: "customer_email",
      headerName: "Email",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "current_address",
      headerName: "Address1",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "role_name",
      headerName: "Role Name",
      width: 200,
      hide: selected.includes("role_name") ? false : true,
    },

    {
      field: "tat_breach",
      headerName: "TAT Breach",
      width: 200,
      hide: selected.includes("tat_breach") ? false : true,
    },

    {
      field: "ticket_non_working_hours",
      headerName: "Ticket Non Working Hours",
      width: 200,
      hide: selected.includes("ticket_non_working_hours") ? false : true,
    },

    {
      field: "permanent_address",
      headerName: "Address2",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "customer_location",
      headerName: "Location",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "pre_response_time",
      headerName: "Pre Response Time",
      width: 200,
      hide: selected.includes("pre_response_time") ? false : true,
    },

    {
      field: "first_response_in",
      headerName: "First Response Time",
      width: 200,
      hide: selected.includes("first_response_in") ? false : true,
    },

    {
      field: "first_response_message",
      headerName: "FRT Response Message",
      width: 200,
      hide: selected.includes("first_response_message") ? false : true,
    },

    {
      field: "first_response_message_by",
      headerName: "FRT Response By",
      width: 200,
      hide: selected.includes("first_response_message_by") ? false : true,
    },

    {
      field: "latest_response_to_user",
      headerName: "Latest Response To User",
      width: 200,
      hide: selected.includes("latest_response_to_user") ? false : true,
    },

    {
      field: "zipcode",
      headerName: "Zipcode",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "city",
      headerName: "City",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "state",
      headerName: "State",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "country",
      headerName: "Country",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "aadhaar_number",
      headerName: "Aadhaar Number",
      width: 200,
      hide: selected.includes("customer_id") ? false : true,
    },

    {
      field: "is_influencer",
      headerName: "Marked Influencer",
      width: 200,
      hide: selected.includes("is_influencer") ? false : true,
    },
    {
      field: "parent_ticket_id",
      headerName: "Parent Ticket ID",
      width: 200,
      hide: selected.includes("parent_ticket_id") ? false : true,
    },
    {
      field: "project",
      headerName: "Project",
      width: 200,
      hide: selected.includes("project") ? false : true,
    },
    {
      field: "related_tickets",
      headerName: "Related Tickets",
      width: 200,
      hide: selected.includes("related_tickets") ? false : true,
    },
    {
      field: "resolved_time",
      headerName: "Resolved Time",
      width: 200,
      hide: selected.includes("resolved_time") ? false : true,
    },
    {
      field: "souce_link",
      headerName: "Platform URL",
      width: 200,
      hide: selected.includes("souce_link") ? false : true,
    },
    // {
    //   field: "tags",
    //   headerName: "Tags",
    //   width: 200,
    //   hide: selected.includes("tags") ? false : true,
    // },
    // {
    //   field: "ticket_type",
    //   headerName: "Ticket Type",
    //   width: 200,
    //   hide: selected.includes("ticket_type") ? false : true,
    // },
    {
      field: "Notes",
      headerName: "Latest Note By User",
      width: 200,
      hide: selected.includes("Notes") ? false : true,
    },
    {
      field: "comment",
      headerName: "comment",
      width: 200,
      hide: selected.includes("comment") ? false : true,
    },
    {
      field: "category",
      headerName: "Ticket Category",
      width: 200,
      hide: selected.includes("category") ? false : true,
    },

    // {
    //   field: "custom_value",
    //   headerName: textField ? textField : "Ticket Custom Value",
    //   width: 200,
    //   hide: selected.includes("custom_value") ? false : true,
    // },

    {
      field: "tat_response_in",
      headerName: "Turn Around Time",
      width: 200,
      hide: selected.includes("tat_response_in") ? false : true,
    },

    {
      field: "first_response_time",
      headerName: "First Response Time",
      width: 200,
      hide: selected.includes("first_response_time") ? false : true,
    },
  ];
  if (selected.includes("custom_value")) {
    if (props?.customticketfields_filter?.hits?.length > 0) {
      props.customticketfields_filter?.hits?.forEach((item) => {
        if (item?._source?.field_type !== "category") {
          columns.push({
            field: item?._source?.field_name,
            headerName: item?._source?.field_name,
            width: 200,
            hide: false,

            renderCell: (params) => {
              if (params.row?.custom_value.length > 0) {
                return params.row?.custom_value
                  .filter((meti) => meti.field_id === item._id)
                  .map((meti) => (
                    <p>
                      {meti.field_values
                        .map((fieldItem) => fieldItem.value)
                        .join(",")}
                    </p>
                  ));
              }
            },
          });
        }
      });
    }
  }
  let maxTagsRow = null;
  let maxTagsLength = 0;

  // Iterate through the hits
  props?.ticketsReport?.hits?.hits?.forEach((item) => {
    if (item?._source?.tags?.length > maxTagsLength) {
      maxTagsLength = item._source.tags.length;
      maxTagsRow = item._source.tags;
    }
  });

  // Now map the tags from the row with the maximum tags length
  if (maxTagsRow) {
    maxTagsRow.map((item1, index) => {
      columns.push({
        field: `Tag${index + 1}`,
        headerName: `Tag${index + 1}`,
        minWidth: 100,
        resizable: true,
        align: "center",
        headerAlign: "center",
      });
    });
  }

  let statusList = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits.forEach((item) => {
      statusList.push({
        status_id: item._id,
        status_name: item._source.status_name,
      });
    });
  }

  let tagsList = [];
  if (props.tagfilter.hits) {
    props.tagfilter.hits.hits.forEach((item) => {
      tagsList.push({
        tag_id: item._id,
        tag_name: item._source.tag_name,
      });
    });
  }

  const returnStatusName = (statusID) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusID;
      });

    return filtered_status.length > 0 ? filtered_status[0].status_name : ``;
  };

  function createdDate(item) {
    const dateObj = new Date(item);

    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear());
    const formattedDate = `${day}/${month}/${year}`;

    // Format: hh:mm:ss
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return dateObj ? `${formattedDate}  ${formattedTime}` : 0;
  }

  function DateOnly(item) {
    const dateObj = new Date(item);

    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear());
    const formattedDate = `${day}/${month}/${year}`;

    return dateObj ? `${formattedDate}` : 0;
  }

  function TimeOnly(item) {
    const dateObj = new Date(item);

    // Format: hh:mm:ss
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return dateObj ? `${formattedTime}` : 0;
  }

  const convertTimeFormat = (timeString) => {
    const timeParts = timeString.split(":");

    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = Math.floor(parseFloat(timeParts[2]));

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  const rows = [];

  if (props.ticketsReportsWithPagination.hits) {
    props.ticketsReportsWithPagination.hits.hits.map((list, index) => {
      const row = {
        id: list._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",

        ticket_id: list._id ? list._id : "",

        parent_ticket_id: list._source.parent_ticket_id
          ? list._source.parent_ticket_id
          : "",
        rating: list._source.ticket_desc
          ? list._source.ticket_desc.split("/ratings/")[1]
          : "",

        customer:
          list._source.customer_id && list._source.customer_id.customer_name
            ? list._source.customer_id.customer_name
            : "",
        customer_phone:
          list._source.customer_id && list._source.customer_id.customer_phone
            ? list._source.customer_id.customer_phone
            : "",

        customer_email:
          list._source.customer_id && list._source.customer_id.customer_email
            ? list._source.customer_id.customer_email
            : "",

        gender:
          list._source.customer_id && list._source.customer_id.gender
            ? list._source.customer_id.gender
            : "",

        first_response_message_by:
          list._source.first_response_message_by &&
          list._source.first_response_message_by.user_name
            ? list._source.first_response_message_by.user_name
            : "",
        first_response_message: list._source.first_response_message
          ? list._source.first_response_message
          : "",

        latest_response_to_user: list._source.latest_response_to_user
          ? list._source.latest_response_to_user
          : "",

        user_total_mentions: list._source.user_total_mentions
          ? list._source.user_total_mentions
          : "",
        first_ticket_assigned_date_time: list._source
          .first_ticket_assigned_date_time
          ? createdDate(list._source.first_ticket_assigned_date_time)
          : "",
        first_response_message_date_time: list._source
          .first_response_message_date_time
          ? createdDate(list._source.first_response_message_date_time)
          : "00/00/0000 00:00:00",

        current_address:
          list._source.customer_id && list._source.customer_id.current_address
            ? list._source.customer_id.current_address
            : "",
        permanent_address:
          list._source.customer_id && list._source.customer_id.permanent_address
            ? list._source.customer_id.permanent_address
            : "",

        customer_location:
          list._source.customer_id && list._source.customer_id.customer_location
            ? list._source.customer_id.customer_location
            : "",

        zipcode:
          list._source.customer_id && list._source.customer_id.zipcode
            ? list._source.customer_id.zipcode
            : "",

        city:
          list._source.customer_id && list._source.customer_id.city
            ? list._source.customer_id.city
            : "",

        state:
          list._source.customer_id && list._source.customer_id.state
            ? list._source.customer_id.state
            : "",

        country:
          list._source.customer_id && list._source.customer_id.country
            ? list._source.customer_id.country
            : "",

        aadhaar_number:
          list._source.customer_id && list._source.customer_id.adhar_number
            ? list._source.customer_id.adhar_number
            : "",

        is_influencer:
          list._source.customer_id && list._source.customer_id.is_influencer
            ? "Yes"
            : "No",

        first_user_mention: list._source.first_user_mention
          ? list._source.first_user_mention
          : "",

        latest_user_mention: list._source.latest_user_mention
          ? list._source.latest_user_mention
          : "",

        first_user_mention_on: list._source.first_user_mention_on
          ? createdDate(list._source.first_user_mention_on)
          : "",

        ticket_non_working_hours: list._source.ticket_non_working_hours
          ? convertTimeFormat(list._source.ticket_non_working_hours)
          : "",

        first_response_time: list._source.first_response_time
          ? list._source.first_response_time
          : "",
        pre_response_time: list._source.pre_response_time
          ? HoursAndMinutes(list._source.pre_response_time)
          : "00:00:00",
        first_response_in: list._source.first_response_in
          ? HoursAndMinutes(list._source.first_response_in)
          : "00:00:00",
        frt_breach: list._source.frt_breach ? "YES" : "NO",
        tat_breach: list._source.tat_breach ? "YES" : "NO",

        role_name: list._source.role_name ? list._source.role_name : "",
        first_ticket_assigned_to:
          list._source.first_ticket_assigned_to &&
          list._source.first_ticket_assigned_to.user_name
            ? list._source.first_ticket_assigned_to.user_name
            : "",
        souce_link: list._source.souce_link ? list._source.souce_link : "",
        sentiment_value:
          list._source.sentiment_value !== null
            ? getSentimentCategory(list._source.sentiment_value)
            : "",

        is_child_ticket: list._source.is_child_ticket
          ? list._source.is_child_ticket
          : "",

        store_code: list._source.store_code ? list._source.store_code : "",
        content_type: list._source.content_type
          ? list._source.content_type
          : "",
        // category: list._source.category
        //   ? list._source.category.category_name
        //   : "",
        project: list._source.project ? list._source.project.project_name : "",
        // ticket_type: list._source.ticket_type
        //   ? list._source.ticket_type.ticket_type_name
        //   : "",
        ticket_name: list._source.ticket_name ? list._source.ticket_name : "",
        feed_id: list._source.feed_id ? list._source.feed_id : "",
        assigned_to: list._source.assigned_to
          ? list._source.assigned_to.user_name
          : "",
        modified_by: list._source.modified_by
          ? list._source.modified_by.user_name
          : "",
        created_by: list._source.created_by
          ? list._source.created_by.user_name
          : "",
        Attachment_url: list._source.Attachment_url
          ? list._source.Attachment_url
          : "",
        account_name: list._source.account_name
          ? list._source.account_name
          : "",

        tat_response_in: list._source.tat_response_in
          ? HoursAndMinutes(list._source.tat_response_in)
          : "00:00:00",
        activities: list._source.activities
          ? list._source.activities.activities_name
            ? list._source.activities.activities_name
            : ""
          : "",
        description: [list._source.platform, list._source.ticket_desc],

        created_on: list._source.created_on
          ? createdDate(list._source.created_on)
          : "",
        modified_on: list._source.modified_on
          ? createdDate(list._source.modified_on)
          : "",
        resolved_time: list._source.resolved_time
          ? createdDate(list._source.resolved_time)
          : "",
        priority: list._source.priority
          ? list._source.priority.priority_name
          : null,

        customer_name: list._source.customer
          ? list._source.customer.customer_name
          : null,
        status: list._source.status
          ? returnStatusName(
              list._source.status && list._source.status.status_id
            )
          : "" || null,
        platform: list._source.platform
          ? list._source.platform.platform_name
            ? list._source.platform.platform_name
            : ""
          : "",
        Notes:
          list._source.Notes && list._source.Notes.length > 0
            ? list._source.Notes[list._source.Notes.length - 1].notes_content
            : "",
        comment:
          list._source.comment && list._source.comment.length > 0
            ? list._source.comment.map((val) => {
                return `${val} ,`;
              })
            : "",
        related_tickets:
          list._source.related_tickets &&
          list._source.related_tickets.length > 0
            ? list._source.related_tickets.map((val) => {
                return `${val.ticket_name}`;
              })
            : "",
        // tags:
        //   list._source.tags && list._source.tags.length > 0
        //     ? list._source.tags.map((val) => {
        //         return `${val.tag_name}`;
        //       })
        //     : "",
        category:
          list._source.custom_value && list._source.custom_value.length > 0
            ? list._source.custom_value
                .filter((val) => val.field_type === "category")
                .map((item) => {
                  return item.field_values && item.field_values?.[0]?.value;
                })
            : "",

        custom_value: list._source.custom_value,
      };

      // Adding tags to each row
      for (let i = 0; i < maxTagsLength; i++) {
        row[`Tag${i + 1}`] =
          list._source.tags && list._source.tags[i]
            ? list._source.tags[i].tag_name
            : "";
      }

      rows.push(row);
    });
  }

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [filterLoading, setFetchLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [clearLoading, setClearLoading] = useState(false);

  const [createdDatelistSelected, setCreatedDatelistSelected] =
    useState("Since Inception");

  const today = new Date();

  // ***************************************
  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);

  //Last 2 days filter
  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  // start from today's date last 7 days
  const last7Days = [...Array(7).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date;
  });

  // last 30 days
  let date2 = new Date();
  let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  // ***************************************
  // last 3 months
  let date3 = new Date();
  let last3Month = date3.setMonth(date3.getMonth() - 3);

  const createdDateHandle = (event, createdDate) => {
    if (createdDate !== null) {
      setCreatedDatelistSelected(createdDate);

      if (createdDate === "Today") {
        setDateRange([today, today]);
      }
      if (createdDate === "Last 2 Days") {
        setDateRange([last2days[1], last2days[0]]);
      }
      if (createdDate === "Previous Day") {
        setDateRange([previousday, previousday]);
      }
      if (createdDate === "Last 7 Days") {
        setDateRange([last7Days[6], last7Days[0]]);
      }
      if (createdDate === "Last 30 Days") {
        setDateRange([last30Days, today]);
      }
      if (createdDate === "Last 3 Months") {
        setDateRange([last3Month, today]);
      }
      if (createdDate === "Custom") {
        setDateRange([null, null]);
      }
    } else {
      setCreatedDatelistSelected("Since Inception");
      setDateRange([null, null]);
    }
  };

  // fetch function
  const { isRequesting } = props;

  useEffect(() => {
    if (!isRequesting) {
      setFetchLoading(false);
      setClearLoading(false);
      setExportLoading(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    // const containsAll =
    //   columnsSelected.length !== 0 &&
    //   treeArray[0]["custom Values"] &&
    //   treeArray[0]["custom Values"].every((elem) =>
    //     columnsSelected.includes(elem)
    //   );
    // if (!containsAll) {
    //   setSelected(columnsSelected);
    // } else {
    //   let filteredColumnArray = [];
    //   filteredColumnArray = columnsSelected.filter(
    //     (elem) => !treeArray[0]["custom Values"].includes(elem)
    //   );
    //   if (containsAll) {
    //     filteredColumnArray.push("custom_value");
    //   }
    //   setSelected(filteredColumnArray);
    // }
  }, [columnsSelected]);

  let maxDate = new Date();

  const dateValueHandleChange = (e, v) => {
    setDateRange(e);
  };

  const csvLink = useRef();

  let filterArray = [];

  if (!unassignedChecked) {
    filterArray.push(
      `["assigned_to.user_name.keyword","must_not","","term","Unassigned"]`
    );
  }

  if (statusCheck.length !== 0) {
    filterArray.push(
      `["status.keyword","must","","terms",${JSON.stringify(statusCheck)}]`
    );
  }

  if (tagsCheck.length !== 0) {
    filterArray.push(
      `["tags","must","nested","",["tag_id.keyword","must","","terms",${JSON.stringify(
        tagsCheck
      )}]]`
    );
  }

  if (platformCheck.length !== 0) {
    filterArray.push(
      `["platform.keyword","must","","terms",${JSON.stringify(platformCheck)}]`
    );
  }

  if (userCheck.length !== 0) {
    filterArray.push(
      `["assigned_to.user_name.keyword","must","","terms",${JSON.stringify(
        userCheck
      )}]`
    );
  }

  if (activityCheck.length !== 0) {
    filterArray.push(
      `["activities.keyword","must","","terms",${JSON.stringify(
        activityCheck
      )}]`
    );
  }

  if (priorityCheck.length !== 0) {
    filterArray.push(
      `["priority.priority_id.keyword","must","","terms",${JSON.stringify(
        priorityCheck
      )}]`
    );
  }

  if (category && category.length !== 0) {
    let category_array = [];
    Array.isArray(category) &&
      category.map((item) => {
        category_array.push(item.id);
      });
    filterArray.push(
      `[
        "custom_value",
        "must",
        "nested",
        "",
        ["field_values.id.keyword", "must", "", "terms", ${JSON.stringify(
          category_array
        )}],
      ],[
        "custom_value",
        "must",
        "nested",
        "",
        ["field_type.keyword", "must", "", "match", "category"],
      ]`
    );
  }

  if (dateRange) {
    let date1 =
      dateRange[0] && dateRange[0].$d
        ? dateRange[0].$d
        : dateRange[0] && dateRange[0];
    let date2 =
      dateRange[1] && dateRange[1].$d
        ? dateRange[1].$d
        : dateRange[1] && dateRange[1];

    if (isValid(date1) && isValid(date2)) {
      filterArray.push(
        `["created_on","must","lte","range","${format(
          date2,
          "yyyy-MM-dd"
        )}"],["created_on","must","gte","range","${format(
          date1,
          "yyyy-MM-dd"
        )}"]`
      );
    }
  }

  const FetchTicket = () => {
    if (selected.length === 0) {
      alert("Please select field name");
    } else {
      setFetchLoading(true);

      const params = {
        fields: `${JSON.stringify(selected)}`,
        page_limit: page_limit,
        order_by: `[["modified_on", "desc"]]`,
        filters: `[${filterArray}]`,
      };

      const param = {
        fields: `${JSON.stringify(selected)}`,
        order_by: `[["modified_on", "desc"]]`,
        page_limit: "none",
        filters: `[${filterArray}]`,
      };

      props.dispatch(
        TicketsAction.requestTicketsForReportsWithPagination(params)
      );
    }
    selected.length > 0 && setOpenSelectFeild(false);
  };

  //Export button function
  const handleExport = async () => {
    if (selected.length === 0) {
      alert("Please select Fields and export");
    } else {
      if (!selected.includes("_id")) {
        selected.push("_id");
      }

      setExportLoading(true);
      const body = {
        fields: selected?.includes("ticket_desc")
          ? selected.map((item) => (item === "ticket_desc" ? "rating" : item))
          : selected,
      };

      const callBack = (response) => {
        let Export = [];

        let index = 0;

        if (response.hits) {
          response.hits.hits.forEach((list) => {
            let objValues = {};

            function purifyAndFormatText(dirtyText) {
              // Create a temporary DOM element to parse the HTML
              const tempDiv = document.createElement("div");
              tempDiv.innerHTML = dirtyText;

              // Extract the text content
              let cleanText = tempDiv.textContent || tempDiv.innerText || "";

              // Remove newline characters
              cleanText = cleanText.replace(/[\n\t\\"]/g, " ");

              // Trim extra spaces
              cleanText = cleanText.trim();

              return cleanText;
            }

            // const zeroWidthSpace = "\u200B";

            Object.assign(
              objValues,

              list._id && {
                ["SL No."]: ++index,
              },

              selected.includes("_id") &&
                list._id && {
                  ["Ticket ID"]: `${list._id}`,
                },

              list._source.ticket_desc && {
                ["Rating"]: list._source.ticket_desc.split("/ratings/")[1],
              },

              list._source.created_on && {
                ["Ticket Created Date"]: DateOnly(list._source.created_on),
              },
              list._source.created_on && {
                ["Ticket Created Time"]: TimeOnly(list._source.created_on),
              },

              list._source.priority && {
                ["Ticket Priority"]: list._source.priority.priority_name,
              },

              list._source.status && {
                ["Ticket Status"]: list._source.status.status_name,
              },

              list._source.platform && {
                ["Platform"]: list._source.platform.platform_name,
              },

              list._source.activities && {
                ["Platform Activity"]: list._source.activities.activities_name,
              },

              list._source.assigned_to && {
                ["Currently Assigned To"]:
                  list._source.assigned_to &&
                  list._source.assigned_to.user_name,
              },

              list._source &&
                list._source.first_user_mention && {
                  ["First User Mention"]: purifyAndFormatText(
                    list._source.first_user_mention
                  ),
                },

              list._source &&
                list._source.latest_user_mention && {
                  ["Latest User Mention"]: purifyAndFormatText(
                    list._source.latest_user_mention
                  ),
                },
              list._source &&
                list._source.first_user_mention_on && {
                  ["First User Mentioned On"]: createdDate(
                    list._source.first_user_mention_on
                  ),
                },

              list._source &&
                list._source.hasOwnProperty("frt_breach") && {
                  ["FRT Breach"]: list._source.frt_breach ? `YES` : "NO",
                },

              list._source &&
                list._source.first_ticket_assigned_date_time && {
                  ["First Ticket Assigned Date and Time"]: createdDate(
                    list._source.first_ticket_assigned_date_time
                  ),
                },

              list._source &&
                checkedItems?.includes("first_response_message_date_time")
                ? list._source?.first_response_message_date_time !== null
                  ? {
                      ["First Response Message Date and Time"]: createdDate(
                        list._source.first_response_message_date_time
                      ),
                    }
                  : {
                      ["First Response Message Date and Time"]:
                        "00/00/0000 00:00:00",
                    }
                : null,

              list._source.created_by &&
                list._source.created_by.user_name && {
                  ["Ticket Created By"]: list._source.created_by.user_name,
                },

              // list._source.modified_on && {
              //   ["Ticket Updated Date"]: DateOnly(list._source.modified_on),
              // },
              // list._source.modified_on && {
              //   ["Ticket Updated Time"]: TimeOnly(list._source.modified_on),
              // },
              list._source.modified_on && {
                ["Ticket Updated Date Time"]: createdDate(
                  list._source.modified_on
                ),
              },

              typeof list._source.sentiment_value === "number" && {
                ["Sentiment"]: `${getSentimentCategory(
                  list._source.sentiment_value
                )}`,
              },

              list._source.customer_id &&
                list._source.customer_id.customer_name && {
                  ["User Name"]: list._source.customer_id.customer_name,
                },

              list._source &&
                list._source.user_total_mentions && {
                  ["User Total Mention"]: list._source.user_total_mentions,
                },

              list._source.customer_id &&
                list._source.customer_id.customer_phone && {
                  ["Phone"]: list._source.customer_id.customer_phone,
                },

              list._source &&
                list._source.store_code && {
                  ["Store Code"]: list._source.store_code,
                },

              list._source.customer_id &&
                list._source.customer_id.customer_email && {
                  ["Email"]: list._source.customer_id.customer_email,
                },

              list._source.customer_id &&
                list._source.customer_id.current_address && {
                  ["Address1"]: purifyAndFormatText(
                    list._source.customer_id.current_address
                  ),
                },

              list._source.customer_id &&
                list._source.customer_id.permanent_address && {
                  ["Address2"]: purifyAndFormatText(
                    list._source.customer_id.permanent_address
                  ),
                },

              list._source &&
                list._source.role_name && {
                  ["Role Name"]: list._source.role_name,
                },

              list._source &&
                list._source.hasOwnProperty("tat_breach") && {
                  ["TAT Breach"]: list._source.tat_breach ? "YES" : "NO",
                },

              list._source &&
                list._source.ticket_non_working_hours && {
                  ["Ticket Non Working Hours"]: convertTimeFormat(
                    list._source.ticket_non_working_hours
                  ),
                },

              list._source.customer_id &&
                list._source.customer_id.customer_location && {
                  ["Location"]: list._source.customer_id.customer_location,
                },

              // list._source &&
              //   list._source.first_response_in && {
              //     ["First Response Time"]: HoursAndMinutes(
              //       list._source.first_response_in
              //     ),
              //   },

              list._source &&
                list._source.first_response_message && {
                  ["FRT Response Message"]: purifyAndFormatText(
                    list._source.first_response_message
                  ),
                },

              list._source.first_response_message_by &&
                list._source.first_response_message_by.user_name && {
                  ["FRT Response By"]:
                    list._source.first_response_message_by.user_name,
                },

              list._source &&
                list._source.latest_response_to_user && {
                  ["Latest Response To User"]: purifyAndFormatText(
                    list._source.latest_response_to_user
                  ),
                },

              list._source.customer_id &&
                list._source.customer_id.zipcode && {
                  ["Zipcode"]: list._source.customer_id.zipcode,
                },

              list._source.customer_id &&
                list._source.customer_id.gender && {
                  ["Gender"]: list._source.customer_id.gender,
                },

              list._source.customer_id &&
                list._source.customer_id.city && {
                  ["City"]: list._source.customer_id.city,
                },

              list._source.customer_id &&
                list._source.customer_id.state && {
                  ["State"]: list._source.customer_id.state,
                },
              list._source.customer_id &&
                list._source.customer_id.country && {
                  ["Country"]: list._source.customer_id.country,
                },

              list._source.customer_id &&
                list._source.customer_id.adhar_number && {
                  ["Aadhar Number"]: list._source.customer_id.adhar_number,
                },

              list._source.customer_id && {
                ["Marked Influencer"]: list._source.customer_id.is_influencer
                  ? "Yes"
                  : "No",
              },

              list._source.souce_link && {
                ["Platform URL"]: list._source.souce_link,
              },

              list._source.Notes && {
                ["Latest Note By User"]:
                  list._source.Notes.length > 0
                    ? list._source.Notes[list._source.Notes.length - 1]
                        .notes_content
                    : "",
              },

              list._source.custom_value && {
                ["Ticket Category"]:
                  list._source.custom_value &&
                  list._source.custom_value.length > 0
                    ? list._source.custom_value
                        .filter((val) => val.field_type === "category")
                        .map(
                          (item) =>
                            item.field_values && item.field_values[0].value
                        )
                        .join(", ")
                    : "",
              },

              list._source.custom_value && {
                [textField ? textField : "TIcket Custom Value"]:
                  list._source.custom_value &&
                  list._source.custom_value.length > 0
                    ? list._source.custom_value.find(
                        (val) => val.field_type === "text"
                      )?.field_values?.[0]?.value || ""
                    : "",
              },

              list._source && checkedItems?.includes("tat_response_in")
                ? list._source.tat_response_in !== null
                  ? {
                      ["Turn Around Time"]: HoursAndMinutes(
                        list._source.tat_response_in
                      ),
                    }
                  : {
                      ["Turn Around Time"]: "00:00:00",
                    }
                : null,

              list._source && checkedItems?.includes("pre_response_time")
                ? list._source.pre_response_time !== null
                  ? {
                      ["Pre Response Time"]: HoursAndMinutes(
                        list._source.pre_response_time
                      ),
                    }
                  : {
                      ["Pre Response Time"]: "00:00:00",
                    }
                : null,

              list._source.parent_ticket_id && {
                parent_ticket_id: list._source.parent_ticket_id,
              },
              list._source && checkedItems?.includes("first_response_in")
                ? list._source.first_response_in !== null
                  ? {
                      first_response_time: HoursAndMinutes(
                        list._source.first_response_in
                      ),
                    }
                  : { first_response_time: "00:00:00" }
                : null,

              list._source.is_child_ticket && {
                is_child_ticket: list._source.is_child_ticket,
              },
              list._source.content_type && {
                content_type: list._source.content_type,
              },

              list._source.project && {
                ["Project"]: list._source.project.project_name,
              },
              list._source.ticket_type && {},

              list._source.resolved_time && {
                resolved_time: list._source.resolved_time,
              },

              list._source.customer && {
                ["Customer"]: list._source.customer.customer_name,
              },

              list._source.comment && {
                ["Comment"]:
                  list._source.comment.length > 0
                    ? list._source.comment.map((val) => {
                        return `${purifyAndFormatText(val)} ,`;
                      })
                    : "",
              },
              list._source.related_tickets && {
                related_tickets:
                  list._source.related_tickets.length > 0
                    ? list._source.related_tickets.map((val) => {
                        return `${val.ticket_name} ,`;
                      })
                    : "",
              },

              // list._source.customer_id &&
              //   list._source.customer_id.customer_location && {
              //     ["Location"]: list._source.customer_id.customer_location,
              //   },

              list._source &&
                list._source.first_ticket_assigned_to &&
                list._source.first_ticket_assigned_to.user_name && {
                  ["First Ticket Assigned To"]:
                    list._source.first_ticket_assigned_to.user_name,
                }
            );

            // Tags
            if (
              Array.isArray(list._source.tags) &&
              list._source.tags.length > 0
            ) {
              list._source.tags.forEach((tag, index) => {
                objValues[`Tag${index + 1}`] = tag.tag_name;
              });
            }

            Export.push(objValues);
          });

          setExportData(Export);
        }
      };

      let access_token = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";

      // &page_limit=none&filters=[${filterArray}]

      const url = `${environment.api.export_ticket_report}?filters=[${filterArray}]`;

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        // Retrieve the blob from the response
        const blob = await response.blob();

        // Create a download link and click it programmatically to download the file
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "Tickets.xlsx"; // You can set the desired file name
        document.body.appendChild(a);
        a.click();

        // Clean up the URL object and remove the link element
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
        setExportLoading(false);
      } catch (error) {
        console.error("An error occurred while downloading the file:", error);
        setExportLoading(false);
      }
    }
  };

  // Trigger the export once the state is updated

  useEffect(() => {
    if (exportData.length > 0) {
      // Create a new workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Define cell styles and formats

      const formatCells = (worksheet) => {
        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!worksheet[cellRef]) continue;

            // Define the format based on cell value or header
            const cell = worksheet[cellRef];
            if (R === 0) {
              // Assuming first row is header
              cell.t = "s"; // Text type
            } else {
              const header =
                worksheet[XLSX.utils.encode_cell({ c: C, r: 0 })].v;
              switch (header) {
                case "SL No.":
                case "Aadhar Number":
                case "Phone":
                case "User Total Mention":
                case "Store Code":
                  cell.t = "n"; // Number type
                  cell.z = "0";
                  break;
                case "Ticket ID":
                case "Currently Assigned To":
                case "Platform Activity":
                case "Rating":
                case "Platform URL":
                case "Sentiment":
                case "Category":
                case "Project":
                case "Ticket Priority":
                case "Customer":
                case "Ticket Status":
                case "Platform":
                case "Latest Note By User":
                case "Comment":
                case "Ticket Category":
                case "TIcket Custom Value":
                case "Ticket Created By":
                case "User Name":
                case "Location":
                case "Email":
                case "City":
                case "Country":
                case "Address1":
                case "Address2":
                case "Gender":
                case "Marked Influencer":
                case "State":
                case "FRT Response Message":
                case "Latest Response To User":
                case "First User Mention":
                case "FRT Response B":
                case "Role Name":
                case "First Ticket Assigned To":
                case "FRT Response By":
                  cell.t = "s"; // Text type
                  cell.z = "@"; // Apply text format
                  break;
                case "TAT Breach":
                case "FRT Breach":
                  cell.t = "s"; // Text type
                  // cell.z = "@"; // Apply text format
                  break;
                // case "Turn Around Time":
                // case "Ticket Created Time":
                // case "Ticket Updated Time":
                //   // case "First Response Time":
                //   // Convert string to time object if necessary
                //   const timeParts = cell.v.split(":");
                //   const timeObject = new Date(
                //     0,
                //     0,
                //     0,
                //     timeParts[0],
                //     timeParts[1],
                //     timeParts[2]
                //   );
                //   cell.v = timeObject;
                //   cell.t = "d"; // Date type for time
                //   cell.z = "hh:mm:ss"; // Apply custom time format
                //   break;
                // case "Ticket Created Date":
                // case "Ticket Updated Date":
                //   // Convert string to date object
                //   const dateParts = cell.v.split("/");
                //   const dateObject = new Date(
                //     `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                //   );
                //   cell.v = dateObject;
                //   cell.t = "d"; // Date type
                //   cell.z = "dd/mm/yyyy"; // Apply custom date format
                //   break;
                case "First Ticket Assigned Date and Time":
                  const [datePart, timePart] = cell.v.split("  ");
                  const [day, month, year] = datePart.split("/");
                  const [hour, minute, second] = timePart.split(":");

                  // Construct the date-time string in ISO 8601 format (yyyy-mm-ddTHH:MM:SS)
                  const dateTimeString = `${year}-${month}-${day}T${hour}:${minute}:${second}`;

                  // Parse the date-time string using new Date()
                  const dateTimeObject = new Date(dateTimeString);

                  cell.v = dateTimeObject;
                  cell.t = "d"; // Date type for date-time
                  cell.z = "dd/mm/yyyy hh:mm:ss"; // Custom date-time format

                  break;

                case "First Response In":
                  // Assuming cell.v is a number like 0.31
                  cell.t = "n"; // Number type
                  cell.z = "0.00"; // Custom percentage format, change to "0.00" for two decimal places
                  break;

                // case "Ticket Non Working Hours":
                //   // Assuming format "00:05:09"
                //   const [hours, minutes, seconds] = cell.v.split(":");

                //   // Log the parsed values for debugging

                //   // Construct a duration object
                //   const durationObject = new Date(
                //     0,
                //     0,
                //     0,
                //     hours,
                //     minutes,
                //     seconds
                //   );

                //   // Assign the duration object to the cell value
                //   cell.v = durationObject;
                //   cell.t = "d"; // Date type for duration
                //   cell.z = "hh:mm:ss"; // Custom duration format without milliseconds
                //   break;

                default:
                  if (header.startsWith("Tag")) {
                    cell.t = "s"; // Text type for tags
                    cell.z = "@"; // Apply text format
                  }
                  break;
              }
            }
          }
        }
      };
      //  ."Ticket Non Working Hours",

      // Apply cell formatting
      formatCells(worksheet);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");

      // Export the workbook to a file
      XLSX.writeFile(workbook, "Tickets.xlsx");
    }
  }, [exportData]);

  let initial = {
    sub_value: [],
  };

  const formikRef = useRef(null);

  const FieldsClearState = () => {
    setPlatformAnchor(null);
    setPlatformCheck([]);
    setStatusAnchor(null);
    setStatusCheck([]);
    setTagsCheck([]);
    setUserAnchor(null);
    setUserCheck([]);
    setPriorityAnchor(null);
    setPriorityCheck([]);
    setActivityAnchor(null);
    setActivityCheck([]);
    setSelected([]);
    setChecked([]);
    setExpanded([]);
    setColumnsSelected([]);
    setPlatformSelected([]);
    setStatusSelected([]);
    setAssigned_To_Selected([]);
    setActivitySelected([]);
    setDateRange([null, null]);
    setPrioritySelected([]);
    setCategory([]);
    setCheckedItems([]);
    setFilterdItem("");
    setUnassignedChecked(false);
    setCreatedDatelistSelected("Since Inception");
    formikRef &&
      formikRef.current &&
      formikRef.current.setFieldValue("sub_value", []);

    const filter = [];

    filter.push(`["platform.keyword","must","","match","!"]`);

    let param = {
      page_limit: page_limit,
    };

    const params = {
      filters: `[${filter}]`,
    };

    if (selected.length > 0) {
      setClearLoading(true);
      fetchTicketByFields(param);
    } else {
    }

    // setOpen(false);
    // setOpenSelectFeild(false);
  };

  // priority list
  let filterprioritylist = [];

  if (props.filterpriority.hits) {
    props.filterpriority.hits.forEach((value) => {
      filterprioritylist.push({
        ticket_priority_name: value._source.ticket_priority_name,
        color: value._source.color,
      });
    });
  }

  const renderPriorityColor = (p_name) => {
    let p_list = filterprioritylist.filter((val) => {
      return val.ticket_priority_name === p_name;
    });

    return `${p_list.length > 0 ? p_list[0].color : null}`;
  };

  const RowPriority = (item) => {
    if (item.params.priority) {
      return (
        <>
          <Box
            mr={1}
            width={10}
            height={10}
            borderRadius="50%"
            sx={{
              backgroundColor: renderPriorityColor(item.params.priority),
            }}
          ></Box>
          <span>{item.params.priority}</span>
        </>
      );
    }
  };

  const [openSelectFeild, setOpenSelectFeild] = useState(true);

  const handleChange_pagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const params = {
      fields: `${JSON.stringify(selected)}`,
      filters: `[${filterArray}]`,
      page_limit: page_limit,
      page_number: `${value}` - 1,
    };

    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(
        TicketsAction.requestTicketsForReportsWithPagination(params)
      );
    }
  };

  const [categoryUpdateOpen, setCategoryUpdateOpen] = useState(false);

  // category edit button open close model
  const openCloseModal = useCallback((open) => {
    setCategoryUpdateOpen(open);
  }, []);

  const returnCustomValues = (sub_value_id) => {
    let category_values =
      props.customticketfields &&
      props.customticketfields.hits &&
      props.customticketfields.hits.hits[0]._source.field_values;

    function ReturnCategoryName(nodes, id, names = []) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        names.push(nodes[i].value);

        if (node.id === id) {
          return names;
        } else if (node.sub_value && node.sub_value.length > 0) {
          const foundIndexes = ReturnCategoryName(node.sub_value, id, [
            ...names,
          ]);
          if (foundIndexes) {
            return foundIndexes;
          }
        }
        names.pop();
      }
      return null;
    }
    let custom_field_vals = ReturnCategoryName(category_values, sub_value_id);

    return custom_field_vals;
  };

  let returnCustomValuesAsString = (data) => {
    let values = [];

    values = returnCustomValues(data);

    // Initialize an empty string to store the result
    let result = "";

    // Loop through the values and append them to the result string with a slash
    for (var i = 0; i < values.length; i++) {
      result += values[i];
      // Add a slash after every value, except for the last one
      if (i !== values.length - 1) {
        result += "/";
      }
    }
    return result;
  };

  let turn_around_time = [
    "assigned_to",
    "created_on",
    "first_response_in",
    "first_response_message_by",
    "first_response_message",
    "first_ticket_assigned_to",
    "first_ticket_assigned_date_time",
    "first_response_message_date_time",
    "ticket_non_working_hours",
    "role_name",
    "tat_response_in",
    "frt_breach",
    "tat_breach",
  ];

  let ticket = [
    "_id",
    "assigned_to",
    "status",
    "tags",
    "priority",
    "store_code",
    "ticket_non_working_hours",
    "first_ticket_assigned_date_time",
    "first_ticket_assigned_to",
    "role_name",
    "pre_response_time",
    "first_response_in",
    "modified_on",
    "created_on",
    "Notes",
    "latest_response_to_user",
    "ticket_desc",
    // "platform",
    "category",
    "custom_value",
  ];

  let mention = [
    "assigned_to",
    "customer_id",
    "platform",
    "activities",
    "first_user_mention",
    "latest_user_mention",
    "first_user_mention_on",
    "is_influencer",
    // "category",
    "created_on",
    // "latest_response_to_user",
    // "first_ticket_assigned_to",
    // "first_ticket_assigned_date_time",
    // "Notes",
    // "first_response_in",
    // "ticket_desc",
    "sentiment_value",
    "souce_link",
    "user_total_mentions",
  ];

  let custom_report = [];

  let fields = [
    {
      value: "assigned_to",
      desc: "To whom the ticket is currently assigned to",
      label: "Currently Assigned To",
      sub_values: userlist,
    },
    {
      value: "tags",
      desc: "Ticket Tags",
      label: "Ticket Tags",
      sub_values: tagsList,
    },

    {
      value: "ticket_desc",
      desc: "The mention's rating is shown here",
      label: "Rating",
    },

    {
      value: "first_user_mention",
      desc: "The first user's mention that was captured",
      label: "First User Mention",
    },
    {
      value: "latest_user_mention",
      desc: "The latest user's mention that was captured",
      label: "Latest User Mention",
    },
    {
      value: "first_user_mention_on",
      desc: "The first user's mentioned date and time that was captured",
      label: "First User Mentioned On",
    },
    {
      value: "platform",
      desc: "The social media channel where the mention was made",
      label: "Platform",
      sub_values: platformList,
    },

    {
      value: "activities",
      desc: "The social media activity of the platform where the mention was made",
      label: "Platform Activity",
      sub_values: filteredActivities,
    },
    {
      value: "sentiment_value",
      desc: "The post's sentiment in the ticket, whether positive, negative, or neutral",
      label: "Sentiment",
    },
    { value: "created_by", desc: "Ticket Created by", label: "Created by" },
    {
      value: "pre_response_time",
      desc: "The delay between a user's ticket submission and the initial response from a Bridge user support",
      label: "Pre Response Time",
    },
    {
      value: "first_response_in",
      desc: "The initial time of the comment made to the bridge user in the ticket",
      label: "First Response Time",
    },
    {
      value: "first_ticket_assigned_to",
      desc: "The bridge user or role name to whom the ticket is assigned first",
      label: "First Ticket Assigned To",
    },
    {
      value: "first_ticket_assigned_date_time",
      desc: "The date and time of the ticket is assigned first",
      label: "First Ticket Assigned Date and Time",
    },

    {
      value: "first_response_message_date_time",
      desc: "The date and time of the ticket responded first",
      label: "First Response Message Date and Time",
    },

    {
      value: "first_response_message_by",
      desc: "The bridge user or role name who responds for the first time",
      label: "FRT Response By",
    },
    {
      value: "first_response_message",
      desc: "The first response message by the bridge user",
      label: "FRT Response Message",
    },
    {
      value: "role_name",
      desc: "The role name that receives the ticket",
      label: "Role Name",
    },
    {
      value: "created_on",
      desc: "The date when the ticket was made",
      label: "Ticket Created On",
    },
    {
      value: "_id",
      desc: "The ticket ID, a set of numbers used to identify each ticket produced, is unique",
      label: "Ticket ID",
    },
    {
      value: "ticket_non_working_hours",
      desc: "It displays the tickets for non-working hours",
      label: "Ticket Non Working Hours",
    },
    {
      value: "modified_on",
      desc: "The date and time of the most recent update to the ticket",
      label: "Ticket Updated On",
    },
    {
      value: "tat_response_in",
      desc: "Total turn around time on the tickets",
      label: "Turn Around Time",
    },
    { value: "store_code", desc: "GMB store code", label: "Store Code" },
    {
      value: "Notes",
      desc: "The most recent note added to the ticket by the bridge user",
      label: "Latest Note By User",
    },
    {
      value: "customer_id",
      desc: "User personal information",
      label: "User Details",
    },
    {
      value: "latest_response_to_user",
      desc: "The most recent response sent to the user",
      label: "Latest Response To User",
    },
    {
      value: "is_influencer",
      desc: "Whether the ticket is marked influencer",
      label: "Marked Influencer",
    },
    {
      value: "status",
      desc: "The status of the ticket, whether it is open, closed, or held by the bridge user",
      label: "Ticket Status",
      sub_values: statusList,
    },
    {
      value: "priority",
      desc: "The priority of the ticket: high, medium, low, etc.",
      label: "Ticket Priority",
      sub_values: priorityList,
    },

    {
      value: "souce_link",
      desc: "The post's URL (unique resource locator) address from which the ticket was created",
      label: "Platform URL",
    },

    {
      value: "category",
      desc: "The ticket category is based on the entire conversation",
      label: "Ticket Category",
    },

    {
      value: "custom_value",
      desc: "The ticket category is based on the entire conversation",
      label: "Ticket Custom Value",
    },

    {
      value: "frt_breach",
      desc: "The delay time taken by the user to provide FRT based on the benchmark set",
      label: "FRT Breach",
    },
    {
      value: "tat_breach",
      desc: "The delay time taken for the tickets to be resolved based on the benchmark set",
      label: "TAT Breach",
    },
    {
      value: "user_total_mentions",
      desc: "The overall number of user mentions",
      label: "User Total Mentions",
    },
  ];

  fields &&
    fields.map((item) => {
      custom_report.push(item.value);
    });

  let convertedfields = fields.map((key) => ({
    value: key.value,
    label: key.label,
    desc: key.desc,
    subvalues: key.sub_values,
  }));

  const [checkedItems, setCheckedItems] = useState([]);
  const [platformAnchor, setPlatformAnchor] = useState(null);
  const [statusAnchor, setStatusAnchor] = useState(null);
  const [tagsAnchor, setTagsAnchor] = useState(null);
  const [userAnchor, setUserAnchor] = useState(null);
  const [priorityAnchor, setPriorityAnchor] = useState(null);
  const [activityAnchor, setActivityAnchor] = useState(null);

  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  useEffect(() => {
    setSelected(checkedItems);
  }, [checkedItems]);

  let filterdArray = ["ticket", "turn_around_time", "mention", "custom_report"];

  const [filterdItem, setFilterdItem] = useState();

  if (filterdItem === "ticket") {
    convertedfields = convertedfields.filter((item) =>
      ticket.includes(item.value)
    );
  } else if (filterdItem === "turn_around_time") {
    convertedfields = convertedfields.filter((item) =>
      turn_around_time.includes(item.value)
    );
  } else if (filterdItem === "mention") {
    convertedfields = convertedfields.filter((item) =>
      mention.includes(item.value)
    );
  } else if (filterdItem === "custom_report") {
    convertedfields = convertedfields.filter((item) =>
      custom_report.includes(item.value)
    );
  }

  const handleFieldChange = (value) => {
    if (filterdItem === value) {
      setFilterdItem("");
      setCheckedItems([]);
    } else {
      setFilterdItem(value);

      if (value === "turn_around_time") {
        setCheckedItems(turn_around_time);
      } else if (value === "mention") {
        setCheckedItems(mention);
      } else if (value === "ticket") {
        setCheckedItems(ticket);
      } else if (value === "custom_report") {
        setCheckedItems(custom_report);
      }
    }

    setPlatformAnchor(null);
    setStatusAnchor(null);
    setPriorityAnchor(null);
    setStatusAnchor(null);
    setTagsCheck([]);
    setActivityAnchor(null);
    setPlatformCheck([]);
    setStatusCheck([]);
    setUserCheck([]);
    setPriorityCheck([]);
    setActivityCheck([]);
  };

  const popperRef = useRef(null);
  const containerRef = useRef(null);

  const handleClosePopper = () => {
    setStatusAnchor(null);
    setPlatformAnchor(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        handleClosePopper();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const summaryBoxRef = useRef(null);
  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `100px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 100
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const [renderKey, setRenderKey] = useState(0);

  // const accordianBoxRef = useRef(null);
  // useEffect(() => {
  //   const calculateHeights = () => {
  //     const outerBoxHeight = accordianBoxRef.current.clientHeight;

  //     if (
  //       accordianBoxRef.current &&
  //       accordianBoxRef.current.children[1] &&
  //       accordianBoxRef.current.children[0]
  //     ) {
  //       accordianBoxRef.current.children[0].style.height = `50px`;
  //       accordianBoxRef.current.children[1].style.height = `${
  //         outerBoxHeight - 50
  //       }px`;
  //     }
  //   };

  //   calculateHeights();

  //   // Use ResizeObserver to listen for changes in the size of accordianBoxRef
  //   const resizeObserver = new ResizeObserver(calculateHeights);
  //   resizeObserver.observe(accordianBoxRef.current);

  //   // Cleanup ResizeObserver on component unmount
  //   return () => {
  //     resizeObserver.disconnect();
  //   };
  // }, []);

  const [imgHeight, setImgHeight] = useState(40); // Default height is 40px

  const handleResize = () => {
    if (window.innerWidth <= 1024) {
      setImgHeight(20); // Change height to 20px if window width is 1024px or below
    } else {
      setImgHeight(40); // Reset height to 40px for larger window widths
    }
  };

  useEffect(() => {
    // Set initial height based on current window width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      style={{
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
      className="scrollable"
      ref={containerRef}
    >
      <Box
        ref={summaryBoxRef}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Accordion
            expanded={openSelectFeild}
            style={{
              backgroundColor: theme.palette.background.default,
              height: "auto",
              width: "100%",
              borderRadius: theme.borderRadius,
              border: "solid 0.5px #d3e2e6",
              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            }}
          >
            <Typography
              width={"100%"}
              sx={{
                zIndex: 999,
              }}
            >
              <Box width="100%">
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    p={1}
                    // gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      alignItems={"center"}
                      gap={2}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                    >
                      <Grid item>
                        <MuiButton
                          name={"Fetch"}
                          width="100px"
                          loading={filterLoading}
                          onClick={FetchTicket}
                        />
                      </Grid>
                      <Grid item>
                        <MuiButton
                          disabled={
                            props.ticketsReportsWithPagination.hits &&
                            props.ticketsReportsWithPagination.hits.hits
                              .length > 0 &&
                            selected.length > 0
                              ? false
                              : true
                          }
                          loading={exportLoading}
                          name={"Export"}
                          width="100px"
                          onClick={handleExport}
                          //   disabled={!exportEnable}
                        />
                        {/* <CSVLink
                          data={exportData}
                          filename="Tickets.xls"
                          className="hidden"
                          ref={csvLink}
                          target="_blank"
                        /> */}
                      </Grid>
                      <Grid item>
                        <MuiButton
                          name={"Clear"}
                          width="100px"
                          onClick={FieldsClearState}
                          disabled={
                            (checkedItems && checkedItems.length !== 0) ||
                            (dateRange[0] !== null && dateRange[1] !== null)
                              ? false
                              : true
                          }
                          loading={clearLoading}
                          id="checkbox-clear-btn"
                        />
                      </Grid>
                      {!openSelectFeild && (
                        <Grid item>
                          <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                            {filterdItem
                              ?.replace(/_/g, " ")
                              ?.replace(/\b\w/g, (c) => c.toUpperCase())}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      sx={{
                        alignSelf: "flex-end",
                        order: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        columnGap: 1,
                      }}
                    >
                      <AutoCompleteComponent
                        list={dateLists}
                        textFieldProps={{
                          placeholder: "Created Date",
                        }}
                        value={createdDatelistSelected}
                        handleChange={createdDateHandle}
                        size="small"
                      />

                      <DatePicker
                        value={dateRange ? dateRange : [null, null]}
                        disabled={createdDatelistSelected !== "Custom"}
                        onChange={dateValueHandleChange}
                        maxDate={maxDate}
                      />
                      <IconButton
                        onClick={() =>
                          setOpenSelectFeild((prevexapand) => !prevexapand)
                        }
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Typography
                height={"10px"}
                sx={{
                  position: "relative",
                  background: openSelectFeild
                    ? "linear-gradient(to top, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%)"
                    : "linear-gradient(to top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)",
                }}
              >
                <LoadingIndicator
                  isActive={props.isRequesting || clearLoading}
                ></LoadingIndicator>
              </Typography>
            </Typography>

            <Box width="100%" height={"84vh"}>
              <Grid
                container
                item
                xl={12}
                justifyContent="flex-start"
                alignItems="center"
                height="70vh"
              >
                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{
                    borderRadius: 3,
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.05)",
                    backgroundColor: "var(--white)",
                    marginBottom: 2.5,
                  }}
                  // height="10vh"
                  // className="bg-info"
                >
                  {filterdArray.map((item, i) => (
                    <Grid
                      item
                      xs={3}
                      sx={{
                        marginY: 1,
                        borderRight: i === 3 ? null : `1px dotted #a0bfc9`,
                        padding: 1,
                        textAlign: "center",
                      }}
                    >
                      <Box>
                        <FormControlLabel
                          key={item}
                          control={
                            <Checkbox
                              sx={{
                                borderColor: "#bab8b8",
                                color: "#bab8b8",
                              }}
                              checkedIcon={
                                <CheckBoxIcon
                                  sx={{
                                    color: theme.palette.primary.main,
                                  }}
                                />
                              }
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  sx={{
                                    color: theme.palette.text.gray,
                                  }}
                                />
                              }
                              checked={filterdItem === item}
                              onChange={() => handleFieldChange(item)}
                            />
                          }
                          label={
                            <ListItem className="p-0 m-0">
                              {item === "ticket" && (
                                // <FontAwesomeIcon
                                //   icon={faTicket}
                                //   style={{
                                //     width: 60,
                                //     height: 40,
                                //     opacity: 0.5,
                                //     marginRight: 10,
                                //   }}
                                // ></FontAwesomeIcon>
                                <img
                                  src={twitterLogoWebp}
                                  style={{
                                    height: `${imgHeight}px`,
                                    marginRight: 10,
                                  }}
                                />
                              )}

                              {item === "mention" && (
                                // <FontAwesomeIcon
                                //   icon={faComments}
                                //   style={{
                                //     width: 60,
                                //     height: 40,
                                //     opacity: 0.5,
                                //     marginRight: 10,
                                //   }}
                                // ></FontAwesomeIcon>

                                <img
                                  src={mentions_image}
                                  style={{
                                    height: `${imgHeight}px`,
                                    marginRight: 10,
                                  }}
                                />
                              )}

                              {item === "custom_report" && (
                                <img
                                  src={custom_report_image}
                                  style={{
                                    height: `${imgHeight}px`,
                                    marginRight: 10,
                                  }}
                                />
                              )}

                              {item === "turn_around_time" && (
                                // <FontAwesomeIcon
                                //   icon={faClock}
                                //   style={{
                                //     width: 60,
                                //     height: 40,
                                //     opacity: 0.5,
                                //     marginRight: 10,
                                //   }}
                                // ></FontAwesomeIcon>

                                <img
                                  src={tat_image}
                                  style={{
                                    height: `${imgHeight}px`,
                                    marginRight: 10,
                                  }}
                                />
                              )}

                              <Typography
                                fontSize={{
                                  lg: 18,
                                  xs: 14,
                                }}
                              >
                                {item
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (c) => c.toUpperCase())}
                              </Typography>
                            </ListItem>
                          }
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  className="grid-container-reports scrollable "
                  sx={{
                    marginX: 1,
                    justifyContent: "center",
                  }}
                  height="70vh"
                  container
                >
                  {convertedfields &&
                    convertedfields.map((item) => (
                      <Box
                        key={item.value}
                        sx={{
                          width: 289,
                          height: [
                            "custom_value",
                            "category",
                            "platform",
                            "status",
                            "assigned_to",
                            "priority",
                            "activities",
                            "tags",
                          ].some((str) => checkedItems.includes(str))
                            ? 160
                            : 114,
                          // margin: "0 17px 16px 0",
                          margin: 1,
                          padding: "8.1px 9px 32px 7.8px",
                          borderRadius: 1,
                          border: "solid 1px #d3e2e6",
                          backgroundColor: "white",
                        }}
                      >
                        <Box
                          style={{
                            width: "100%",
                            // display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <FormControlLabel
                            className="p-0 m-0"
                            sx={{
                              zIndex: 1,
                              // display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              gap: 1,
                            }}
                            control={
                              <Checkbox
                                sx={{
                                  zIndex: 1,
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  padding: 0,
                                  margin: 0,
                                  borderColor: theme.palette.text.gray,
                                  color: theme.palette.text.gray,
                                }}
                                checkedIcon={
                                  <CheckBoxIcon
                                    sx={{
                                      color: theme.palette.primary.main,
                                    }}
                                  />
                                }
                                icon={
                                  <CheckBoxOutlineBlankIcon
                                    sx={{
                                      color: theme.palette.text.gray,
                                    }}
                                  />
                                }
                                checked={checkedItems.includes(item.value)}
                                onChange={() =>
                                  handleCheckboxChange(item.value)
                                }
                              />
                            }
                          />
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                            }}
                          >
                            <span>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                }}
                              >
                                {item.label}
                              </span>
                              <br />
                              <div
                                style={{
                                  fontSize: "13px",
                                  color: theme.palette.ticketListView.iconColor,
                                }}
                              >
                                {item.desc}
                              </div>
                            </span>
                          </span>

                          {checkedItems.includes("category") &&
                            item.value === "category" && (
                              <Formik initialValues={initial}>
                                {({
                                  values,
                                  errors,
                                  touched,
                                  isSubmitting,
                                  setFieldValue,
                                  submitForm,
                                  handleSubmit,
                                  handleChange,
                                }) => (
                                  <Form onSubmit={handleSubmit}>
                                    <Box>
                                      <Grid
                                        item
                                        p={1}
                                        mb={-2}
                                        mt={2}
                                        alignItems="center"
                                        justifyContent={"center"}
                                        textAlign="center"
                                        sx={{
                                          backgroundColor:
                                            theme.palette.primary.main,
                                          borderRadius: "5px",
                                          height: "38px",
                                          width: "150px",
                                          border: `1px solid ${theme.palette.primary.main}`,
                                        }}
                                      >
                                        <ListItem className="py-0 m-0 px-1">
                                          <StyleIcon
                                            style={{
                                              marginTop: "-2px",
                                              width: "16px",
                                              marginRight: 2,
                                              color: `${theme.palette.ticketListView.iconColor}`,
                                            }}
                                          />
                                          <span
                                            className="m-0 p-0"
                                            style={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              width: "100px",
                                              cursor: "pointer",
                                              color: "white",
                                            }}
                                            onClick={() => {
                                              openCloseModal(
                                                !categoryUpdateOpen
                                              );
                                            }}
                                          >
                                            Select Category
                                          </span>
                                        </ListItem>
                                      </Grid>
                                      <CategoryFilterDialog
                                        open={categoryUpdateOpen}
                                        setFieldValue={(value) => {
                                          setFieldValue(`sub_value`, value);
                                        }}
                                        sub_value={values.sub_value}
                                        onClose={() => {
                                          setCategoryUpdateOpen(false);
                                        }}
                                        customticketfields={
                                          props.customticketfields
                                        }
                                        customticketfields_filter={
                                          props.customticketfields_filter
                                        }
                                      />
                                      {setCategory(values.sub_value)}
                                    </Box>
                                  </Form>
                                )}
                              </Formik>
                            )}

                          {checkedItems.includes("platform") &&
                            item.value === "platform" &&
                            item.subvalues && (
                              <Box pt={2}>
                                <MuiButton
                                  onClick={(event) => {
                                    if (
                                      platformAnchor === event.currentTarget
                                    ) {
                                      setPlatformAnchor(null);
                                    } else {
                                      setPlatformAnchor(event.currentTarget);
                                    }
                                  }}
                                  name={
                                    <Grid
                                      item
                                      container
                                      alignItems={"center"}
                                      justifyContent="space-between"
                                      gap={1}
                                    >
                                      <Grid item>
                                        <span>Select</span>
                                      </Grid>

                                      <Grid item>
                                        <IconButton className="p-0 m-0">
                                          {!platformAnchor ? (
                                            <ExpandMoreIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          ) : (
                                            <ExpandLessIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </Box>
                            )}

                          {checkedItems.includes("platform") &&
                            item.value === "platform" &&
                            item.subvalues && (
                              <CustomPopper
                                anchorEl={platformAnchor}
                                open={Boolean(platformAnchor)}
                                onClose={() => {
                                  setPlatformAnchor(null);
                                  setSearchTerm("");
                                }}
                                renderContent={
                                  <>
                                    <MuiTextField
                                      variant="outlined"
                                      placeholder="Search Platform"
                                      fullWidth
                                      size="small"
                                      value={searchTerm}
                                      onChange={handleSearchChange}
                                      sx={{ mb: 2 }}
                                    />
                                    {item.subvalues
                                      .filter((val) =>
                                        val.platform_name
                                          ?.toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                      )
                                      .map((val) => (
                                        <Box
                                          key={val.platform_id}
                                          sx={{ zIndex: 999 }}
                                        >
                                          <FormControlLabel
                                            className="p-0 m-0"
                                            sx={{ zIndex: 999, flex: 1 }}
                                            control={
                                              <Checkbox
                                                checked={platformCheck.includes(
                                                  val.platform_id
                                                )}
                                                sx={{ zIndex: 999 }}
                                                onChange={() =>
                                                  handlePlatformCheckBox(
                                                    val.platform_id
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <span>
                                                <span
                                                  style={{ fontSize: "16px" }}
                                                >
                                                  {val.platform_name}
                                                </span>
                                              </span>
                                            }
                                          />
                                        </Box>
                                      ))}
                                  </>
                                }
                              />
                            )}

                          {checkedItems.includes("status") &&
                            item.value === "status" &&
                            item.subvalues && (
                              <Box pt={2}>
                                <MuiButton
                                  onClick={(event) => {
                                    if (statusAnchor === event.currentTarget) {
                                      setStatusAnchor(null);
                                    } else {
                                      setStatusAnchor(event.currentTarget);
                                    }
                                  }}
                                  name={
                                    <Grid
                                      item
                                      container
                                      alignItems={"center"}
                                      justifyContent="space-between"
                                      gap={1}
                                    >
                                      <Grid item>
                                        <span>Select</span>
                                      </Grid>

                                      <Grid item>
                                        <IconButton className="p-0 m-0">
                                          {!statusAnchor ? (
                                            <ExpandMoreIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          ) : (
                                            <ExpandLessIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </Box>
                            )}

                          {checkedItems.includes("status") &&
                            item.value === "status" &&
                            item.subvalues && (
                              <CustomPopper
                                anchorEl={statusAnchor}
                                open={Boolean(statusAnchor)}
                                onClose={() => {
                                  setStatusAnchor(null);
                                  setSearchTerm("");
                                }}
                                renderContent={
                                  <>
                                    <MuiTextField
                                      variant="outlined"
                                      placeholder="Search Status"
                                      fullWidth
                                      size="small"
                                      value={searchTerm}
                                      onChange={handleSearchChange}
                                      sx={{ mb: 2 }}
                                    />
                                    {item.subvalues
                                      .filter((val) =>
                                        val.status_name
                                          ?.toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                      )
                                      .map((val) => (
                                        <Box
                                          key={val.status_id}
                                          sx={{ zIndex: 999 }}
                                        >
                                          <FormControlLabel
                                            className="p-0 m-0"
                                            sx={{ zIndex: 999, flex: 1 }}
                                            control={
                                              <Checkbox
                                                checked={statusCheck.includes(
                                                  val.status_id
                                                )}
                                                sx={{ zIndex: 999 }}
                                                onChange={() =>
                                                  handleStatusCheckBox(
                                                    val.status_id
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <span>
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {val.status_name}
                                                </span>
                                              </span>
                                            }
                                          />
                                        </Box>
                                      ))}
                                  </>
                                }
                              />
                            )}

                          {checkedItems.includes("tags") &&
                            item.value === "tags" &&
                            item.subvalues && (
                              <Box pt={2}>
                                <MuiButton
                                  onClick={(event) => {
                                    if (tagsAnchor === event.currentTarget) {
                                      setTagsAnchor(null);
                                    } else {
                                      setTagsAnchor(event.currentTarget);
                                    }
                                  }}
                                  name={
                                    <Grid
                                      item
                                      container
                                      alignItems={"center"}
                                      justifyContent="space-between"
                                      gap={1}
                                    >
                                      <Grid item>
                                        <span>Select</span>
                                      </Grid>

                                      <Grid item>
                                        <IconButton className="p-0 m-0">
                                          {!tagsAnchor ? (
                                            <ExpandMoreIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          ) : (
                                            <ExpandLessIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </Box>
                            )}

                          {checkedItems.includes("tags") &&
                            item.value === "tags" &&
                            item.subvalues && (
                              <CustomPopper
                                anchorEl={tagsAnchor}
                                open={Boolean(tagsAnchor)}
                                onClose={() => {
                                  setTagsAnchor(null);
                                  setSearchTerm("");
                                }}
                                renderContent={
                                  <>
                                    <MuiTextField
                                      variant="outlined"
                                      placeholder="Search Tags"
                                      fullWidth
                                      size="small"
                                      value={searchTerm}
                                      onChange={handleSearchChange}
                                      sx={{ mb: 2 }}
                                    />
                                    {item.subvalues
                                      .filter((val) =>
                                        val.tag_name
                                          ?.toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                      )
                                      .map((val) => (
                                        <Box
                                          key={val.tag_id}
                                          sx={{ zIndex: 999 }}
                                        >
                                          <FormControlLabel
                                            className="p-0 m-0"
                                            sx={{ zIndex: 999, flex: 1 }}
                                            control={
                                              <Checkbox
                                                checked={tagsCheck.includes(
                                                  val.tag_id
                                                )}
                                                sx={{ zIndex: 999 }}
                                                onChange={() =>
                                                  handleTagsCheckBox(val.tag_id)
                                                }
                                              />
                                            }
                                            label={
                                              <span>
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {val.tag_name}
                                                </span>
                                              </span>
                                            }
                                          />
                                        </Box>
                                      ))}
                                  </>
                                }
                              />
                            )}

                          {checkedItems.includes("assigned_to") &&
                            item.value === "assigned_to" &&
                            item.subvalues && (
                              <Box pt={4}>
                                <MuiButton
                                  onClick={(event) => {
                                    if (userAnchor === event.currentTarget) {
                                      setUserAnchor(null);
                                    } else {
                                      setUserAnchor(event.currentTarget);
                                    }
                                  }}
                                  name={
                                    <Grid
                                      item
                                      container
                                      alignItems={"center"}
                                      justifyContent="space-between"
                                      gap={1}
                                    >
                                      <Grid item>
                                        <span>Select</span>
                                      </Grid>

                                      <Grid item>
                                        <IconButton className="p-0 m-0">
                                          {!userAnchor ? (
                                            <ExpandMoreIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          ) : (
                                            <ExpandLessIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  }
                                />

                                <FormControlLabel
                                  className="p-0 m-0"
                                  sx={{ zIndex: 999, flex: 1 }}
                                  control={
                                    <Checkbox
                                      checked={unassignedChecked}
                                      sx={{ zIndex: 999 }}
                                      onChange={() =>
                                        setUnassignedChecked(!unassignedChecked)
                                      }
                                    />
                                  }
                                  label={
                                    <span>
                                      <span
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        Include Unassigned
                                      </span>
                                    </span>
                                  }
                                />
                              </Box>
                            )}

                          {checkedItems.includes("assigned_to") &&
                            item.value === "assigned_to" &&
                            item.subvalues && (
                              <CustomPopper
                                anchorEl={userAnchor}
                                open={Boolean(userAnchor)}
                                onClose={() => {
                                  setUserAnchor(null);
                                  setSearchTerm("");
                                }}
                                renderContent={
                                  <>
                                    <MuiTextField
                                      variant="outlined"
                                      placeholder="Search Assigned to"
                                      fullWidth
                                      size="small"
                                      value={searchTerm}
                                      onChange={handleSearchChange}
                                      sx={{ mb: 2 }}
                                    />

                                    {item.subvalues
                                      .filter((val) =>
                                        val.user_name
                                          .toLowerCase()
                                          ?.includes(searchTerm.toLowerCase())
                                      )
                                      .map((val) => (
                                        <Box
                                          key={val.user_name}
                                          sx={{ zIndex: 999 }}
                                        >
                                          <FormControlLabel
                                            className="p-0 m-0"
                                            sx={{ zIndex: 999, flex: 1 }}
                                            control={
                                              <Checkbox
                                                checked={userCheck.includes(
                                                  val.user_name
                                                )}
                                                sx={{ zIndex: 999 }}
                                                onChange={() =>
                                                  handleUserCheckBox(
                                                    val.user_name
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <span>
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {val.user_name}
                                                </span>
                                              </span>
                                            }
                                          />
                                        </Box>
                                      ))}
                                  </>
                                }
                              />
                            )}

                          {checkedItems.includes("priority") &&
                            item.value === "priority" &&
                            item.subvalues && (
                              <Box pt={4}>
                                <MuiButton
                                  onClick={(event) => {
                                    if (
                                      priorityAnchor === event.currentTarget
                                    ) {
                                      setPriorityAnchor(null);
                                    } else {
                                      setPriorityAnchor(event.currentTarget);
                                    }
                                  }}
                                  name={
                                    <Grid
                                      item
                                      container
                                      alignItems={"center"}
                                      justifyContent="space-between"
                                      gap={1}
                                    >
                                      <Grid item>
                                        <span>Select</span>
                                      </Grid>

                                      <Grid item>
                                        <IconButton className="p-0 m-0">
                                          {!priorityAnchor ? (
                                            <ExpandMoreIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          ) : (
                                            <ExpandLessIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </Box>
                            )}

                          {checkedItems.includes("priority") &&
                            item.value === "priority" &&
                            item.subvalues && (
                              <CustomPopper
                                anchorEl={priorityAnchor}
                                open={Boolean(priorityAnchor)}
                                onClose={() => {
                                  setPriorityAnchor(null);
                                  setSearchTerm("");
                                }}
                                renderContent={
                                  <>
                                    <MuiTextField
                                      variant="outlined"
                                      placeholder="Search Priority"
                                      fullWidth
                                      size="small"
                                      value={searchTerm}
                                      onChange={handleSearchChange}
                                      sx={{ mb: 2 }}
                                    />

                                    {item.subvalues
                                      .filter((val) =>
                                        val.priority_name
                                          ?.toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                      )
                                      .map((val) => (
                                        <Box
                                          key={val.priority_id}
                                          sx={{ zIndex: 999 }}
                                        >
                                          <FormControlLabel
                                            className="p-0 m-0"
                                            sx={{ zIndex: 999, flex: 1 }}
                                            control={
                                              <Checkbox
                                                checked={priorityCheck.includes(
                                                  val.priority_id
                                                )}
                                                sx={{ zIndex: 999 }}
                                                onChange={() =>
                                                  handlePriorityCheckBox(
                                                    val.priority_id
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <span>
                                                <span
                                                  style={{
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {val.priority_name}
                                                </span>
                                              </span>
                                            }
                                          />
                                        </Box>
                                      ))}
                                  </>
                                }
                              />
                            )}

                          {checkedItems.includes("activities") &&
                            item.value === "activities" &&
                            item.subvalues && (
                              <Box pt={2}>
                                <MuiButton
                                  onClick={(event) => {
                                    if (
                                      activityAnchor === event.currentTarget
                                    ) {
                                      setActivityAnchor(null);
                                    } else {
                                      setActivityAnchor(event.currentTarget);
                                    }
                                  }}
                                  name={
                                    <Grid
                                      item
                                      container
                                      alignItems={"center"}
                                      justifyContent="space-between"
                                      gap={1}
                                    >
                                      <Grid item>
                                        <span>Select</span>
                                      </Grid>

                                      <Grid item>
                                        <IconButton className="p-0 m-0">
                                          {!activityAnchor ? (
                                            <ExpandMoreIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          ) : (
                                            <ExpandLessIcon
                                              className="p-0 m-0"
                                              sx={{ color: "white" }}
                                            />
                                          )}
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </Box>
                            )}

                          {checkedItems.includes("activities") &&
                            item.value === "activities" &&
                            item.subvalues && (
                              <CustomPopper
                                anchorEl={activityAnchor}
                                open={Boolean(activityAnchor)}
                                onClose={() => {
                                  setActivityAnchor(null);
                                }}
                                renderContent={
                                  item.subvalues.length ? (
                                    item.subvalues.map((val) => (
                                      <Box
                                        key={val.activity_id}
                                        sx={{ zIndex: 999 }}
                                      >
                                        <FormControlLabel
                                          className="p-0 m-0"
                                          sx={{
                                            zIndex: 999,
                                            flex: 1,
                                          }}
                                          control={
                                            <Checkbox
                                              checked={activityCheck.includes(
                                                val.activity_id
                                              )}
                                              sx={{ zIndex: 999 }}
                                              onChange={() =>
                                                handleActivityCheckBox(
                                                  val.activity_id
                                                )
                                              }
                                            />
                                          }
                                          label={
                                            <span className="d-flex">
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {val.activity_name}
                                              </span>{" "}
                                              &nbsp;{" - "} &nbsp;
                                              <span
                                                style={{
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {val.platform_name}
                                              </span>
                                            </span>
                                          }
                                        />
                                      </Box>
                                    ))
                                  ) : (
                                    <Box>Select Atleast One Platform</Box>
                                  )
                                }
                              />
                            )}
                        </Box>
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </Box>
            {/* </AccordionDetails> */}
          </Accordion>
        </Box>

        <Box
          className="scrollable"
          sx={{
            padding: "12px",
            overflowY: "auto",
            "& .MuiDataGrid-virtualScroller": {
              "&::-webkit-scrollbar": {
                width: "3px",
                height: "3px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#b0d2da",
                borderRadius: "3px",
                "&:hover": {
                  backgroundColor: "#85bac6",
                },
              },
            },
          }}
        >
          {selected.length > 0 && !openSelectFeild && (
            <>
              {!props.isRequesting && (
                <Box>
                  {/* data grid */}
                  <Grid
                    display="flex"
                    justifyContent="center"
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      pr={1}
                      width={"100vh"}
                      height={"80vh"}
                    >
                      {
                        <DataGridFunction
                          curser="pointer"
                          columns={columns}
                          rows={rows}
                          rowHeight={80}
                          checkboxSelection={true}
                          // getRowHeight={() => "auto"}
                          height="75vh"
                          pagelength={props.pagination.total_page_no}
                          page={props.pagination.current_page_no}
                          handleChange={handleChange_pagination}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                ticket_id: selected.includes("_id")
                                  ? true
                                  : false,
                                rating: selected.includes("ticket_desc")
                                  ? true
                                  : false,
                                created_on: selected.includes("created_on")
                                  ? true
                                  : false,
                                priority: selected.includes("priority")
                                  ? true
                                  : false,
                                customer_name: selected.includes("customer")
                                  ? true
                                  : false,
                                status: selected.includes("status")
                                  ? true
                                  : false,

                                platform: selected.includes("platform")
                                  ? true
                                  : false,
                                Attachment_url: selected.includes(
                                  "Attachment_url"
                                )
                                  ? true
                                  : false,
                                account_name: selected.includes("account_name")
                                  ? true
                                  : false,
                                activities: selected.includes("activities")
                                  ? true
                                  : false,
                                assigned_to: selected.includes("assigned_to")
                                  ? true
                                  : false,
                                first_ticket_assigned_to: selected.includes(
                                  "first_ticket_assigned_to"
                                )
                                  ? true
                                  : false,
                                first_user_mention: selected.includes(
                                  "first_user_mention"
                                )
                                  ? true
                                  : false,
                                latest_user_mention: selected.includes(
                                  "latest_user_mention"
                                )
                                  ? true
                                  : false,
                                first_user_mention_on: selected.includes(
                                  "first_user_mention_on"
                                )
                                  ? true
                                  : false,
                                frt_breach: selected.includes("frt_breach")
                                  ? true
                                  : false,
                                first_ticket_assigned_date_time:
                                  selected.includes(
                                    "first_ticket_assigned_date_time"
                                  )
                                    ? true
                                    : false,
                                first_response_message_date_time:
                                  selected.includes(
                                    "first_response_message_date_time"
                                  )
                                    ? true
                                    : false,
                                feed_id: selected.includes("feed_id")
                                  ? true
                                  : false,
                                is_child_ticket: selected.includes(
                                  "is_child_ticket"
                                )
                                  ? true
                                  : false,
                                modified_by: selected.includes("modified_by")
                                  ? true
                                  : false,
                                created_by: selected.includes("created_by")
                                  ? true
                                  : false,
                                modified_on: selected.includes("modified_on")
                                  ? true
                                  : false,
                                content_type: selected.includes("content_type")
                                  ? true
                                  : false,
                                sentiment_value: selected.includes(
                                  "sentiment_value"
                                )
                                  ? true
                                  : false,
                                customer: selected.includes("customer_id")
                                  ? true
                                  : false,
                                user_total_mentions: selected.includes(
                                  "user_total_mentions"
                                )
                                  ? true
                                  : false,
                                customer_phone: selected.includes("customer_id")
                                  ? true
                                  : false,
                                store_code: selected.includes("store_code")
                                  ? true
                                  : false,
                                customer_email: selected.includes("customer_id")
                                  ? true
                                  : false,
                                current_address: selected.includes(
                                  "customer_id"
                                )
                                  ? true
                                  : false,

                                role_name: selected.includes("role_name")
                                  ? true
                                  : false,
                                tat_breach: selected.includes("tat_breach")
                                  ? true
                                  : false,
                                ticket_non_working_hours: selected.includes(
                                  "ticket_non_working_hours"
                                )
                                  ? true
                                  : false,
                                permanent_address: selected.includes(
                                  "customer_id"
                                )
                                  ? true
                                  : false,
                                customer_location: selected.includes(
                                  "customer_id"
                                )
                                  ? true
                                  : false,
                                pre_response_time: selected.includes(
                                  "pre_response_time"
                                )
                                  ? true
                                  : false,
                                first_response_message: selected.includes(
                                  "first_response_message"
                                )
                                  ? true
                                  : false,
                                first_response_message_by: selected.includes(
                                  "first_response_message_by"
                                )
                                  ? true
                                  : false,
                                latest_response_to_user: selected.includes(
                                  "latest_response_to_user"
                                )
                                  ? true
                                  : false,
                                zipcode: selected.includes("customer_id")
                                  ? true
                                  : false,
                                gender: selected.includes("customer_id")
                                  ? true
                                  : false,
                                city: selected.includes("customer_id")
                                  ? true
                                  : false,
                                city: selected.includes("customer_id")
                                  ? true
                                  : false,
                                country: selected.includes("customer_id")
                                  ? true
                                  : false,
                                aadhaar_number: selected.includes("customer_id")
                                  ? true
                                  : false,
                                is_influencer: selected.includes(
                                  "is_influencer"
                                )
                                  ? true
                                  : false,
                                parent_ticket_id: selected.includes(
                                  "parent_ticket_id"
                                )
                                  ? true
                                  : false,
                                project: selected.includes("project")
                                  ? true
                                  : false,
                                related_tickets: selected.includes(
                                  "related_tickets"
                                )
                                  ? true
                                  : false,

                                resolved_time: selected.includes(
                                  "resolved_time"
                                )
                                  ? true
                                  : false,

                                souce_link: selected.includes("souce_link")
                                  ? true
                                  : false,
                                Notes: selected.includes("Notes")
                                  ? true
                                  : false,
                                comment: selected.includes("comment")
                                  ? true
                                  : false,
                                category: selected.includes("category")
                                  ? true
                                  : false,
                                tat_response_in: selected.includes(
                                  "tat_response_in"
                                )
                                  ? true
                                  : false,
                                first_response_time: selected.includes(
                                  "first_response_time"
                                )
                                  ? true
                                  : false,
                              },
                            },
                          }}
                        />
                      }
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export default connect(mapStateToProps)(Reports);
const RowIcons = (item) => {
  if (item.params.platform === "") {
    return (
      <>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Twitter") {
    return (
      <>
        <FontAwesomeIcon
          icon={faXTwitter}
          style={{ width: "12px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Facebook") {
    return (
      <>
        <FacebookIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Playstore") {
    return (
      <>
        <FontAwesomeIcon
          icon={faGooglePlay}
          style={{ width: "12px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Instagram") {
    return (
      <>
        <InstagramIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Youtube") {
    return (
      <>
        <YouTubeIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Whatsapp") {
    return (
      <>
        <WhatsAppIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Email") {
    return (
      <>
        <EmailIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Telegram") {
    return (
      <>
        <TelegramIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Linked In") {
    return (
      <>
        <LinkedInIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Appstore") {
    return (
      <>
        <AppleIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Quora") {
    return (
      <>
        <FontAwesomeIcon
          icon={faQuora}
          style={{ width: "12x", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Glassdoor") {
    return (
      <>
        <CropPortraitIcon style={{ width: "12px", marginRight: 5 }} />
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "Ambition Box") {
    return (
      <>
        <FontAwesomeIcon
          icon={faAmilia}
          style={{ width: "10px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  } else if (item.params.platform === "None") {
    return (
      <>
        <FontAwesomeIcon
          icon={faIdCard}
          style={{ width: "10px", marginRight: 5 }}
        ></FontAwesomeIcon>
        <span className="text-wrap">{item.params.description[1]}</span>
      </>
    );
  }
};
