import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import EffectUtility from "../../../utilities/EffectUtility";
import HttpUtility from "../../../utilities/HttpUtility";
import IndeedFeedModel from "./models/IndeedFeedModel";
import IndeedPutModel from "./models/IndeedPutModel";

export default class IndeedFeedEffect {
  static async requestIndeedFeed(params, callBackFunction) {
    const endpoint = environment.api.indeed;

    let response = EffectUtility.getToModel(IndeedFeedModel, endpoint, params);

    response.then((resp) => {
      if (resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestIndeedFeedClear() {
    return {};
  }

  static async requestPutIndeedFeed(data, id) {
    const endpoint = environment.api.indeed + "/" + id;
    return EffectUtility.putToModel(IndeedPutModel, endpoint, data);
  }
}
