import ActionUtility from "../../../../utilities/ActionUtility";
import YoutubeOfficialEffect from "./YoutubeOfficialEffect";

export default class YoutubeOfficialAction {
  // YoutubeOfficialAction action with an function declaration
  static REQUEST_YOUTUBEOFFICIAL =
    "YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL";
  static REQUEST_YOUTUBEOFFICIAL_FINISHED =
    "YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL_FINISHED";

  static REQUEST_YOUTUBECHANNELREVENUE =
    "YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE";
  static REQUEST_YOUTUBECHANNELREVENUE_FINISHED =
    "YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE_FINISHED";

  static REQUEST_PUT_YOUTUBEOFFICIAL =
    "YoutubeOfficialAction.REQUEST_PUT_YOUTUBEOFFICIAL";
  static REQUEST_PUT_YOUTUBEOFFICIAL_FINISHED =
    "YoutubeOfficialAction.REQUEST_PUT_YOUTUBEOFFICIAL_FINISHED";

  static REQUEST_YOUTUBEOFFICIAL_CLEAR =
    "YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL_CLEAR";
  static REQUEST_YOUTUBEOFFICIAL_CLEAR_FINISHED =
    "YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL_CLEAR_FINISHED";

  static REQUEST_YOUTUBECHANNELREVENUE_CLEAR =
    "YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE_CLEAR";
  static REQUEST_YOUTUBECHANNELREVENUE_CLEAR_FINISHED =
    "YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE_CLEAR_FINISHED";

  static REQUEST_YOUTUBECONTENTDOWNLOAD =
    "YoutubeOfficialAction.REQUEST_YOUTUBECONTENTDOWNLOAD";
  static REQUEST_YOUTUBECONTENTDOWNLOAD_FINISHED =
    "YoutubeOfficialAction.REQUEST_YOUTUBECONTENTDOWNLOAD_FINISHED";

  // METHODS
  // Organization GET function
  static requestYoutubeOfficial(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL,
        YoutubeOfficialEffect.requestYoutubeOfficial,
        params
      );
    };
  }

  static requestYoutubeChannelRevenue(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE,
        YoutubeOfficialEffect.requestYoutubeChannelRevenue,
        params
      );
    };
  }

  static requestPutYoutubeOfficial(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOfficialAction.REQUEST_PUT_YOUTUBEOFFICIAL,
        YoutubeOfficialEffect.requestPutYoutubeOfficial,
        data,
        id
      );
    };
  }

  static requestYoutubeOfficialClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOfficialAction.REQUEST_YOUTUBEOFFICIAL_CLEAR,
        YoutubeOfficialEffect.requestYoutubeOfficialClear
      );
    };
  }

  static requestYoutubeChannelRevenueClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOfficialAction.REQUEST_YOUTUBECHANNELREVENUE_CLEAR,
        YoutubeOfficialEffect.requestYoutubeChannelRevenueClear
      );
    };
  }

  static requestYoutubeContentDownload(id, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YoutubeOfficialAction.REQUEST_YOUTUBECONTENTDOWNLOAD,
        YoutubeOfficialEffect.requestYoutubeContentDownload,
        id,
        callBack
      );
    };
  }
}
