import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import TicketWorkedOnModel from "./models/TicketWorkedOnModel";

export default class TicketWorkedOnEffect {
  //get ticket worked on request end point
  static async requestTicketWorkedOn(params) {
    const endpoint = environment.api.ticketWorkedOn;

    return EffectUtility.getToModel(TicketWorkedOnModel, endpoint, params);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
