import React, { useState } from "react";
import axios from "axios";
import { internetCheckDomain } from "../InternetSpeedTest";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import MuiButton from "../../../components/muiButton/MuiButton";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ShadowContainer } from "../../../components/componentLayout/ComponentLayout";

function Ping() {
  const [pingData, setPingData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pingUrls, setPingUrls] = useState(null);

  const pingApi = async (pingUrls) => {
    let urls = pingUrls.split(",");
    setLoading(true);
    try {
      const response = await axios.post(
        `${internetCheckDomain}/api/ping`,
        urls
      );
      setPingData(response.data?.results);
    } catch (error) {
      console.error("Error running speed test", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("pingData", pingData);
  return (
    <Box sx={{ padding: 2, border: `1px solid #2a5e6c`, borderRadius: 3 }}>
      <Grid container item sm={12}>
        <Grid item sm={8}>
          <MuiTextField
            onChange={(e) => setPingUrls(e.target.value)}
            placeholder="Enter Hostname"
            value={pingUrls}
            size="small"
            height="35px"
          />
        </Grid>
        <Grid item sm={3} ml={1}>
          <MuiButton
            onClick={() => {
              pingApi(pingUrls);
            }}
            name={"Ping"}
            size={"small"}
          />
        </Grid>
      </Grid>
      <Divider />

      <Grid container>
        {pingData?.length
          ? pingData.map((item) => (
              <ShadowContainer>
                <Grid item sx={{ padding: 2, textAlign: "left" }}>
                  <Typography>Host : {item.host}</Typography>
                  <Typography>Alive : {item.alive ? "Yes" : "No"}</Typography>
                  <Typography>Numeric : {item.numeric_host}</Typography>
                  <Typography>Packet Loss : {item.packetLoss}</Typography>
                  <Typography>Time : {item.time}</Typography>
                  <Typography>Alive : {item.alive}</Typography>
                  <Typography>Output : {item.output}</Typography>
                </Grid>
              </ShadowContainer>
            ))
          : null}
      </Grid>
    </Box>
  );
}

export default Ping;
