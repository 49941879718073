import { BaseModel } from "sjs-base-model";
import CustomTicketFieldsDataModel from "./CustomTicketFieldsDataModel";

// category data hits Model
export default class CustomTicketFieldsModel extends BaseModel {
  // response getting from the api
  _shards = {};
  current_page_no = 0;
  hits = { CustomTicketFieldsDataModel };
  total_page_no = 0;

  constructor(data) {
    super();

    this.update(data);
  }
}
