import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { connect } from "react-redux";
import { ShadowContainer } from "../../../../../components/componentLayout/ComponentLayout";
import { theme } from "../../../../../views/App";
import TicketsAction from "../../../../stores/tickets/TicketsAction";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function TagsUpdatePopper(props) {
  const [open, setOpen] = React.useState(false);
  const priorityPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      priorityPopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      priorityPopperRef.current &&
      priorityPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={priorityPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={priorityPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <ShadowContainer borderRadius={5}>
              <Paper
                style={{
                  width: "auto",
                  minWidth: 200,
                  height: "auto",
                  padding: 2,
                  //   boxShadow: theme.shadows,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    style={{
                      maxHeight: 250,
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                    className="scrollable"
                  >
                    {props.tagList.length > 0 &&
                      props.tagList.map((tag) => (
                        <ListItem
                          sx={{
                            cursor: "pointer",
                            fontWeight:
                              props.selectedtag === tag.tag_name
                                ? "bold"
                                : "none",

                            "&:hover": {
                              backgroundColor: theme.palette.background.default,
                            },
                          }}
                          onClick={() => {
                            props
                              .dispatch(
                                TicketsAction.requestPutTicket(
                                  JSON.stringify({
                                    tags: [
                                      {
                                        tag_id: tag.tag_id,
                                        tag_name: tag.tag_name,
                                      },
                                    ],
                                  }),
                                  props.ticketId
                                )
                              )
                              .then(() => {
                                props.fetchFunction();
                              });
                          }}
                        >
                          {tag.tag_name}
                        </ListItem>
                      ))}
                  </div>
                </ClickAwayListener>
              </Paper>
            </ShadowContainer>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(TagsUpdatePopper);
