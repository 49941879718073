import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import RetweetGetModel from "../retweet/models/RetweetGetModel";

//UnRetweetEffect
export default class UnRetweetEffect {
  // Retweet Post API
  static async requestUnRetweet(id, params) {
    const endpoint = environment.api.unretweet + "/" + id;

    return EffectUtility.getToModel(RetweetGetModel, endpoint, params);
  }

  //get error end point
  // static async requestError() {
  //   const endpoint = environment.api.errorExample;
  //   const response = await HttpUtility.get(endpoint);
}
