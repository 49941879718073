import { BaseModel } from "sjs-base-model";

export default class ChatBotPostModel extends BaseModel {
  response = {};
  query = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
