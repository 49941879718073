import { BaseModel } from "sjs-base-model";

import BrandHitsModel from "./BrandHitsModel";
import BrandShardsModel from "./BrandShardsModel";

// get model for project
export default class BrandModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = { _shards: BrandShardsModel };
  current_page_no = 0;
  hits = { hits: BrandHitsModel };
  total_page_no = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
