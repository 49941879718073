import BaseReducer from "../../../../utilities/BaseReducer";
import TrackAction from "./TrackAction";

// ChangeRequestTrackReduser
export default class ChangeRequestTrackReduser extends BaseReducer {
  initialState = {
    changeRequestTrack: [],
  };
  [TrackAction.REQUEST_CHANGE_REQUEHST_TRACK_FINISHED](state, action) {
    return {
      ...state,
      changeRequestTrack: action.payload,
    };
  }
}
