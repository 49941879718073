import ActionUtility from "../../../../utilities/ActionUtility";
import ContactEffect from "./ContactEffect";

export default class ContactAction {
  // ContactAction action with an function declaration
  static REQUEST_CONTACT = "ContactAction.REQUEST_CONTACT";
  static REQUEST_CONTACT_FINISHED = "ContactAction.REQUEST_CONTACT_FINISHED";

  static REQUEST_CONTACT_FILTER = "ContactAction.REQUEST_CONTACT_FILTER";
  static REQUEST_CONTACT_FILTER_FINISHED =
    "ContactAction.REQUEST_CONTACT_FILTER_FINISHED";

  static REQUEST_PUT_CONTACT = "ContactAction.REQUEST_PUT_CONTACT";
  static REQUEST_PUT_CONTACT_FINISHED =
    "ContactAction.REQUEST_PUT_CONTACT_FINISHED";

  static REQUEST_POST_CONTACT = "ContactAction.REQUEST_POST_CONTACT";
  static REQUEST_POST_CONTACT_FINISHED =
    "ContactAction.REQUEST_POST_CONTACT_FINISHED";

  static REQUEST_CONTACT_CLEAR = "ContactAction.REQUEST_CONTACT_CLEAR";
  static REQUEST_CONTACT_CLEAR_FINISHED =
    "ContactAction.REQUEST_CONTACT_CLEAR_FINISHED";

  static REQUEST_CONTACT_FILTER_CLEAR =
    "ContactAction.REQUEST_CONTACT_FILTER_CLEAR";
  static REQUEST_CONTACT_FILTER_CLEAR_FINISHED =
    "ContactAction.REQUEST_CONTACT_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_CONTACT = "ContactAction.REQUEST_DELETE_CONTACT";
  static REQUEST_DELETE_CONTACT_FINISHED =
    "ContactAction.REQUEST_DELETE_CONTACT_FINISHED";

  // METHODS
  // Contact GET function
  static requesContact(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ContactAction.REQUEST_CONTACT,
        ContactEffect.requesContact,
        params
      );
    };
  }

  static requesContactClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ContactAction.REQUEST_CONTACT_CLEAR,
        ContactEffect.requesContactClear
      );
    };
  }

  static requesContactFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ContactAction.REQUEST_CONTACT_FILTER_CLEAR,
        ContactEffect.requesContactFilterClear
      );
    };
  }

  static requestContactFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ContactAction.REQUEST_CONTACT_FILTER,
        ContactEffect.requestContactFilter,
        params
      );
    };
  }
  static requestPutContact(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ContactAction.REQUEST_PUT_CONTACT,
        ContactEffect.requestPutContact,
        data,
        id
      );
    };
  }

  static requestPostContact(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ContactAction.REQUEST_POST_CONTACT,
        ContactEffect.requestPostContact,
        data
      );
    };
  }

  static requestDeleteContact(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ContactAction.REQUEST_DELETE_CONTACT,
        ContactEffect.requestDeleteContact,
        id
      );
    };
  }
}
