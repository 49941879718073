import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import GmbQuestionsModel from "./models/GmbQuestionsModel";
import GMBReviewPutModel from "../gmbReviews/models/GMBReviewPutModel";

// GmbQuestionsFeedEffect
export default class GmbQuestionsFeedEffect {
  // calling an API according to the Gmb Feeds model
  // Get method for the GMB Feeds
  static async requestGmbQuestionFeed(params, callBackFunction) {
    const endpoint = environment.api.GMBQuestionFeeds;
    let response = EffectUtility.getToModel(
      GmbQuestionsModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits && resp.hits.hits && callBackFunction) {
        callBackFunction(resp);
      }
    });
    return response;
  }

  static async requestGmbQuestionFeedClear() {
    return {};
  }

  // Get method for the GMB Feeds filter
  static async requestGmbQuestionFeedFilter(params) {
    const endpoint = environment.api.GMBQuestionFeedsFilter;
    return EffectUtility.getToModel(GmbQuestionsModel, endpoint, params);
  }

  // put method for the Gmb Question Feeds
  static async requestPutGMBQuestions(data, id) {
    const endpoint = environment.api.GMBQuestionFeeds + "/" + id;
    return EffectUtility.putToModel(GMBReviewPutModel, endpoint, data);
  }
}
