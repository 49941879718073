import BaseReducer from "../../../utilities/BaseReducer";
import FacebookAction from "./FacebookAction";

// twitter reducer function
export default class FacebookFilterReducer extends BaseReducer {
  initialState = {
    facebookFilter: [],
  };

  [FacebookAction.REQUEST_FACEBOOK_FILTER_FINISHED](state, action) {
    return {
      ...state,
      facebookFilter: action.payload,
    };
  }
}
