import { BaseModel } from "sjs-base-model";

export default class TicketFieldsModel extends BaseModel {
  //ticket list hits data model
  data = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
