import BaseReducer from "../../../utilities/BaseReducer";
import TicketWorkedOnAction from "./TicketWorkedOnAction";

export default class TicketWorkedOnReducer extends BaseReducer {
  //initial state of Ticket Worked on
  initialState = {
    ticketWorkedOn: [],
  };

  //Ticket Worked on request action finish
  [TicketWorkedOnAction.REQUEST_TICKETWORKEDON_FINISHED](state, action) {
    return {
      ...state,
      ticketWorkedOn: action.payload,
    };
  }
}
