import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import FacebookAccountDeleteModel from "./model/FacebookAccountDeleteModel";
import FaceBookAccountModel from "./model/FaceBookAccountModel";
import FaceBookAccountPutModel from "./model/FaceBookAccountPutModel";

// FaceBookAccountEffct
export default class FaceBookAccountEffect {
  // calling an API according to the GoogleAccount model
  // Get method for FaceBook account
  static async requestFaceBookAccount(params) {
    const endpoint = environment.api.facebookAccount;
    return EffectUtility.getToModel(FaceBookAccountModel, endpoint, params);
  }

  // Get method for FaceBook account filter
  static async requestFaceBookAccountFilter(params) {
    const endpoint = environment.api.facebookAccountFilter;
    return EffectUtility.getToModel(FaceBookAccountModel, endpoint, params);
  }

  // put method for FaceBook account filter
  static async requestPutFaceBookAccount(data, id) {
    const endpoint = environment.api.facebookAccount + "/" + id;
    return EffectUtility.putToModel(FaceBookAccountPutModel, endpoint, data);
  }

  // delete method for FaceBook account filter
  static async requestDeleteFaceBookAccount(id) {
    const endpoint = environment.api.facebookAccount + "/" + id;
    return EffectUtility.deleteToModel(FacebookAccountDeleteModel, endpoint);
  }
}
