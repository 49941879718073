import ActionUtility from "../../../../utilities/ActionUtility";
import RevenueReportErrorsEffect from "./RevenueReportErrorsEffect";

export default class RevenueReportErrorsAction {
  // RevenueReportErrorsAction action with an function declaration
  static REQUEST_REVENUE_REPORT_ERRORS =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS";
  static REQUEST_REVENUE_REPORT_ERRORS_FINISHED =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FINISHED";

  static REQUEST_REVENUE_REPORT_ERRORS_FILTER =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FILTER";
  static REQUEST_REVENUE_REPORT_ERRORS_FILTER_FINISHED =
    "RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FILTER_FINISHED";

  // METHODS
  static requestRevenueReportErrors(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS,
        RevenueReportErrorsEffect.requestRevenueReportErrors,
        params
      );
    };
  }

  static requestRevenueReportErrorsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportErrorsAction.REQUEST_REVENUE_REPORT_ERRORS_FILTER,
        RevenueReportErrorsEffect.requestRevenueReportErrorsFilter,
        params
      );
    };
  }
}
