import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencerUserAction from "./InfluencerUserAction";

// InfluencerUserReducer
export default class InfluencerUserReducer extends BaseReducer {
  initialState = {
    influencerUser: [],
  };
  [InfluencerUserAction.REQUEST_INFLUENCER_USER_FINISHED](state, action) {
    return {
      ...state,
      influencerUser: action.payload,
    };
  }
}
