import React, { useCallback, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import "./TwitterStyles.css";
import { format } from "date-fns";
import { connect } from "react-redux";
// Pagination component
import Grid from "@mui/material/Grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tab,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SearchIcon from "@mui/icons-material/Search";
import environment from "environment";
import SentimentSatisfiedAltRoundedIcon from "@mui/icons-material/SentimentSatisfiedAltRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import SentimentNeutralRoundedIcon from "@mui/icons-material/SentimentNeutralRounded";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import TwitterAction from "../../../stores/twitterFeeds/TwitterAction";
import { theme } from "../../../../views/App";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import DatePicker from "../../../../components/dateRangePickerComponent/DateRangePicker";
import MuiButton from "../../../../components/muiButton/MuiButton";
import LoadingIndicator from "../../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import PaginationUI from "../../../../components/pagination/Pagination";
import AddAccountAction from "../../../stores/addAccount/AddAccountAction";
import { axiosRequest } from "../../../../utilities/FetchRequest";

// mapstateto props function
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    TwitterAction.REQUEST_TWITTER,
    TwitterAction.REQUEST_TWITTER_FILTER,
    AddAccountAction.REQUEST_ADD_ACCOUNT_FILTER,
    // LastSyncDateAction.LAST_SYNC_DATE_REQUEST,
  ]),
  twitter: state.twitter.twitter.hits || [],
  pagination: state.twitter.twitter || {},
  twitterfilter: state.twitterfilter.twitterfilter.hits || [],
  addAccountFilter: state.addAccountFilter.addAccountFilter || [],
  // lastSyncDate: state.lastSyncDate.lastSyncDate || {},
});

function TwitterListView(props) {
  // useState Functions
  const [date, setDate] = useState([null, null]);
  const [value, setValue] = React.useState("1");
  const [tweetTypevalue, setTweetTypevalue] = useState(null);
  const [page_limit] = useState(10);
  const [customerName, setCustomerName] = useState(null);
  const tweetTypes = ["Tweet", "ReTweet", "Reply", "Direct Message"];
  const sentimental_val_list = ["Positive", "Negative", "Neutral"];
  const [sentimental_val, setSentimental_val] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [accountName, setAccountName] = useState(null);

  // importing useStyes hook

  let { isRequesting } = props;
  let [open_ticket, set_open_ticket] = useState(null);
  let [circuler_progress_id, set_circuler_pogress_id] = useState(null);

  //tab handle change
  const TabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ************************************************* Dispatch functions
  function fetchTwitter(params) {
    props.dispatch(TwitterAction.requestTwitter(params));
  }

  function fetchtwitterfilter(param) {
    props.dispatch(TwitterAction.requestTwitterFilter(param));
  }

  function fetchtwitterAccountfilter() {
    let param = {
      page_limit: "none",
    };
    props.dispatch(AddAccountAction.requestAddAccountFilter(param));
  }

  const dateValueHandleChange = (e, v) => {
    let date1 = e[0] && e[0].$d;
    let date2 = e[1] && e[1].$d;
    setDate([date1, date2]);
  };

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //   UseEffect function
  useEffect(() => {
    const param = { page_limit: "none" };

    // fetchLastSyncDate(param);

    fetchtwitterfilter(param);
    fetchtwitterAccountfilter();

    const filter = [];
    let params = {};

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }
    if (tweetTypevalue !== null) {
      filter.push(
        `["tweet_type.keyword","must","","match","${tweetTypevalue}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value.keyword","must","","wildcard","*-*"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(
          `["sentiment_value.keyword","must_not","","wildcard","*-*"]`
        );
        filter.push(`["sentiment_value.keyword","must_not","","term","0.0"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value.keyword","must","","term","0.0"]`);
      }
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (
      customerName !== null ||
      tweetTypevalue !== null ||
      sentimental_val !== null ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,

        order_by: '[["created", "desc"]]',
      };
    } else {
      params = {
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["created", "desc"]]',
      };
    }

    fetchTwitter(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let twitterActivities = [];

  if (JSON.parse(localStorage.getItem("activities"))) {
    let activities = JSON.parse(localStorage.getItem("activities"));

    activities.hits &&
      activities.hits.hits &&
      activities.hits.hits.length > 0 &&
      activities.hits.hits
        .filter(
          (activity) => activity._source.platform.platform_name === "Twitter"
        )
        .map((activity) => {
          if (activity._source.activities !== "Direct Message") {
            twitterActivities.push(activity._source.activities);
          }
        });
  }

  // applyFilter Function

  const [searchQuery, setSearchQuery] = useState("");

  const applyFilter = () => {
    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["content_of_the_tweet.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }
    if (tweetTypevalue !== null) {
      filter.push(
        `["tweet_type.keyword","must","","match","${tweetTypevalue}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value.keyword","must","","wildcard","*-*"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(
          `["sentiment_value.keyword","must_not","","wildcard","*-*"]`
        );
        filter.push(`["sentiment_value.keyword","must_not","","term","0.0"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value.keyword","must","","term","0.0"]`);
      }
    }

    if (
      accountName !== null ||
      customerName !== null ||
      tweetTypevalue !== null ||
      sentimental_val !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        order_by: '[["created", "desc"]]',
      };
      fetchTwitter(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please add filters");
      // params = {
      //   order_by: '[["created", "desc"]]',
      //   page_limit: `${page_limit}`,
      // };
      // fetch(params);
    }
  };

  //  autocompleteHandleChange
  const autocompleteHandleChange = (event, value) => {
    if (value != null) {
      setCustomerName(value);
    } else {
      setCustomerName(null);
    }
  };

  const tweetTypeHandleChange = (event, value) => {
    if (value != null) {
      setTweetTypevalue(value);
    } else {
      setTweetTypevalue(null);
    }
  };

  const sentimentalvalHandleChange = (event, value) => {
    if (value != null) {
      setSentimental_val(value);
    } else {
      setSentimental_val(null);
    }
  };

  const accountNameHandleChange = (event, value) => {
    if (value != null) {
      setAccountName(value);
    } else {
      setAccountName(null);
    }
  };

  // ************************************* Filter List
  // Integrating Costomer name to list
  const list = [];

  if (props.twitterfilter.hits) {
    props.twitterfilter.hits.map((value) => {
      list.push(value._source.customer_name);
    });
  }

  const uniqueList = [...new Set(list)];

  // Clear method
  const ClearState = () => {
    setFilterClicked(false);
    if (
      accountName !== null ||
      tweetTypevalue !== null ||
      customerName !== null ||
      sentimental_val !== null ||
      searchQuery !== "" ||
      (date[0] !== null && date[1] !== null)
    ) {
      setTweetTypevalue(null);
      setCustomerName(null);
      setAccountName(null);
      setSearchQuery("");
      setSentimental_val(null);
      setDate([null, null]);
      setClearLoader(true);

      if (navigator.onLine === true) {
        const params = {
          order_by: '[["created", "desc"]]',
          page_limit: `${page_limit}`,
        };
        FilterClicked && fetchTwitter(params);
      } else {
        alert("No internet connection");
      }
    }
  };

  // pagination
  const handleChangePagination = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let params = {};
    const filter = [];

    // search
    if (searchQuery !== "") {
      filter.push(
        `["content_of_the_tweet.keyword","must","","wildcard","*${searchQuery}*"]`
      );
    }

    if (customerName !== null) {
      filter.push(
        `["customer_name.keyword","must","","match","${customerName}"]`
      );
    }
    if (tweetTypevalue !== null) {
      filter.push(
        `["tweet_type.keyword","must","","match","${tweetTypevalue}"]`
      );
    }

    if (accountName !== null) {
      filter.push(
        `["account_name.keyword", "must", "", "match", "${accountName}"]`
      );
    }

    if (sentimental_val !== null) {
      if (sentimental_val === "Negative") {
        filter.push(`["sentiment_value.keyword","must","","wildcard","*-*"]`);
      } else if (sentimental_val === "Positive") {
        filter.push(
          `["sentiment_value.keyword","must_not","","wildcard","*-*"]`
        );
        filter.push(`["sentiment_value.keyword","must_not","","term","0.0"]`);
      } else if (sentimental_val === "Neutral") {
        filter.push(`["sentiment_value.keyword","must","","term","0.0"]`);
      }
    }

    // created_time filter
    if (date[0] !== null && date[1] !== null) {
      filter.push(
        `["created","must","lte","range","${format(
          date[1],
          "yyyy-MM-dd"
        )}"],["created","must","gte","range","${format(
          date[0],
          "yyyy-MM-dd"
        )}"]`
      );
    }

    if (FilterClicked) {
      if (
        accountName !== null ||
        customerName !== null ||
        tweetTypevalue !== null ||
        sentimental_val !== null ||
        searchQuery !== "" ||
        (date[0] !== null && date[1] !== null)
      ) {
        params = {
          filters: `[${filter}]`,
          page_number: `${value}` - 1,
          page_limit: `${page_limit}`,
          order_by: '[["created", "desc"]]',
        };
      }
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["created", "desc"]]',
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchTwitter(params);
    }
  };

  const twitter_list = [];

  if (props.addAccountFilter.hits) {
    props.addAccountFilter.hits.hits.map((value) => {
      twitter_list.push(value._source.account_name);
    });
  }

  const twitter_uniqueList = [...new Set(twitter_list)];

  let maxDate = new Date();

  const openTicketFunction = (ticket_id) => {
    set_circuler_pogress_id(ticket_id);
    let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    let searchQuery = ticket_id;
    let searchTypeSelected = "Ticket-ID";
    let selected_status;
    let activities = [];
    let assigned_To = null;
    let createdDate = [null, null];
    let modifiedDate = [null, null];
    let modifiedDatelistSelected = null;
    let createdDatelistSelected = null;
    let platform = null;
    let priority = null;
    let selected = [];
    let sentiment_value = null;
    let tags = [];
    let category = null;

    set_open_ticket(true);

    let API = `${environment.api.baseApi}/ticket/${ticket_id}`;

    axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      set_open_ticket(false);
      selected_status =
        response &&
        response._source &&
        response._source.status &&
        response._source.status.status_id;

      props.setValue(0);

      let filters = JSON.parse(localStorage.getItem("mentionsFilters"));
      if (filters) {
        filters.searchQuery = searchQuery;
        filters.searchTypeSelected = searchTypeSelected;
        filters.selected_status = selected_status;
        filters.pageNumber = `0`;
        filters.activities = activities;
        filters.assigned_To = assigned_To;
        filters.createdDate = createdDate;
        filters.createdDatelistSelected = createdDatelistSelected;
        filters.modifiedDate = modifiedDate;
        filters.modifiedDatelistSelected = modifiedDatelistSelected;
        filters.platform = platform;
        filters.priority = priority;
        filters.selected = selected;
        filters.sentiment_value = sentiment_value;
        filters.tags = tags;
        filters.category = category;

        localStorage.setItem("mentionsFilters", JSON.stringify(filters));
      } else {
        localStorage.setItem(
          "mentionsFilters",
          JSON.stringify({
            searchQuery,
            searchTypeSelected,
            selected_status,
            activities,
            assigned_To,
            createdDate,
            createdDatelistSelected,
            modifiedDate,
            modifiedDatelistSelected,
            platform,
            priority,
            selected,
            sentiment_value,
            tags,
            category,
          })
        );
      }
    });
  };

  const returnSentimentname = (item) => {
    let sentiment;
    if (
      item._source.sentiment_value &&
      typeof item._source.sentiment_value === "number" &&
      item._source.sentiment_value > 0
    ) {
      sentiment = "Positive";
    } else if (item._source.sentiment_value < 0) {
      sentiment = "Negative";
    } else if (item._source.sentiment_value !== null) {
      sentiment = "Neutral";
    }

    return sentiment;
  };

  return (
    <Box>
      {/* *******************************************************Filters */}

      <Grid container>
        {/*Twitter filter */}
        <Box
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: theme.palette.background.default,
            // borderRadius: theme.palette.buttonComponent.borderRadius.large,
            border: "solid 0.5px #d3e2e6",
            borderRadius: theme.borderRadius,
          }}
        >
          <Grid
            container
            mt={2}
            sx={{
              margin: 0,
              height: "auto",
              padding: 2,
              borderRadius: theme.borderRadius,
            }}
          >
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              justifyContent="space-between"
              alignItems={"center"}
              // spacing={1}
            >
              <Grid
                item
                xl={1.7}
                lg={1.9}
                md={3.9}
                sm={12}
                mt={1}
                // className="bg-warning"
              >
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <span>Account Name</span>
                </Grid>
                <AutoCompleteComponent
                  value={accountName}
                  list={twitter_uniqueList}
                  handleChange={accountNameHandleChange}
                  textFieldProps={{
                    placeholder: "Select Account",
                  }}
                  size="small"
                  width="100%"
                />
              </Grid>

              <Grid item xl={1.7} lg={1.9} md={3.9} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <span>Search Content</span>
                </Grid>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      borderRadius:
                        theme.palette.buttonComponent.borderRadius.medium,
                      outline: `1px ${theme.palette.borderColor.textField}`,
                      backgroundColor: theme.palette.background.paper,
                      padding: theme.typography.padding,
                      height: "35px",
                      width: "100%",
                    }}
                    id="search_bar"
                    autoComplete={"off"}
                    value={searchQuery}
                    placeholder={"Search by Content"}
                    onInput={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    size="small"
                    startAdornment={
                      <InputAdornment>
                        <IconButton onClick={applyFilter}>
                          <SearchIcon
                            sx={{
                              "&.MuiSvgIcon-root": { fontSize: "1.2rem" },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xl={1.7} lg={1.9} md={3.9} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Tweet Type</p>
                </Grid>
                <AutoCompleteComponent
                  // ***********************************************************Rating Filter
                  value={tweetTypevalue}
                  list={twitterActivities}
                  textFieldProps={{
                    placeholder: "Tweet Type",
                  }}
                  handleChange={tweetTypeHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid xl={1.7} lg={1.9} md={3.9} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Customer Name</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={customerName}
                  list={uniqueList}
                  textFieldProps={{
                    placeholder: "Customer Name",
                  }}
                  handleChange={autocompleteHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid ixl={1.9} lg={1.9} md={3.9} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Date Filter</p>
                </Grid>
                <DatePicker
                  value={date}
                  onChange={dateValueHandleChange}
                  maxDate={maxDate}
                />
              </Grid>
              <Grid xl={1.7} lg={1.9} md={3.9} sm={12} mt={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} mb={1}>
                  <p>Sentiment value</p>
                </Grid>
                <AutoCompleteComponent
                  // ***************************************************Customer name filter
                  value={sentimental_val}
                  list={sentimental_val_list}
                  textFieldProps={{
                    placeholder: "Sentimental Value",
                  }}
                  handleChange={sentimentalvalHandleChange}
                  size="small"
                  width="100%"
                />
              </Grid>
              <Grid xl={1.5} lg={12} md={12} sm={12} mt={0}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={2.5}
                  // className="bg-success"
                >
                  <Grid item xl={4} lg={2} md={3} sm={5}>
                    <MuiButton
                      loading={filterLoader && isRequesting}
                      name={"Apply"}
                      width="100%"
                      onClick={applyFilter}
                    />
                  </Grid>
                  <Grid item xl={4} lg={2} md={3} sm={5}>
                    <MuiButton
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      width="100%"
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <h5 className="pl-3 pr-3 pt-2"></h5> */}

        {/* <Divider sx={{ borderColor: theme.palette.background.button }} /> */}
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        style={{ marginTop: "1%", marginBottom: "1%" }}
      >
        <hr style={{ marginTop: "4px", marginBottom: "4px" }} />
      </Grid>
      {/* ****************************************************** Divider */}

      <Grid
        container
        sx={{
          backgroundColor: theme.palette.background.paper,

          borderRadius: theme.borderRadius,
        }}
      >
        {/* ******************************************************PLay Store List */}

        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.default,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            borderRadius: theme.borderRadius,
          }}
          // className="bg-primary"
        >
          <Grid
            container
            sx={{
              height: "auto",
              padding: 2.5,
              paddingBottom: 0,
            }}
          >
            <Grid container lg={12} md={12} sm={12} spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Grid container lg={12} display="flex-start">
                  <Grid item>
                    <h5>Tweets</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="dotted" />
          </Grid>

          {props.twitter.hits ? (
            <LoadingIndicator isActive={isRequesting}>
              {props.twitter.hits.length !== 0 ? (
                props.twitter.hits.map(
                  (item, key) =>
                    !isRequesting && (
                      <Box
                        sx={{
                          padding: 0,
                          margin: "1.2%",
                          marginTop: 0,
                          marginBottom: "0.5%",
                        }}
                        // className="bg-warning"
                      >
                        <List
                          sx={{
                            width: "100%",
                            cursor:
                              item && item._source.ticket_id ? "pointer" : null,
                          }}
                          // className="bg-success"
                          onClick={() => {
                            localStorage.setItem("activeTab", 0);

                            item._source &&
                              item._source.ticket_id &&
                              openTicketFunction(item._source.ticket_id);
                          }}

                          // component="nav"
                        >
                          <Grid
                            container
                            sx={{
                              backgroundColor: "#fff",
                              height: "auto",
                              padding: 2,
                              borderRadius: theme.borderRadius,
                              border: "solid 0.5px #d3e2e6",
                              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                            }}
                          >
                            <Grid
                              container
                              lg={12}
                              md={12}
                              sm={12}
                              // className="bg-success"
                            >
                              <Grid
                                item
                                lg={1}
                                md={2}
                                sm={2}
                                maxWidth={"80px !important"}
                              >
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar></Avatar>
                                  </ListItemAvatar>
                                </ListItem>
                              </Grid>
                              <Grid
                                item
                                lg={10}
                                md={9}
                                sm={9}
                                container
                                // className="bg-warning"
                              >
                                <Grid item lg={12} md={12} sm={12} container>
                                  <Grid item lg={6} md={12} sm={12}>
                                    <ListItem>
                                      <h6 style={{ marginRight: "20px" }}>
                                        {item._source.customer_name}{" "}
                                      </h6>
                                      <p>
                                        {" "}
                                        {(function createdDate() {
                                          let created = item._source.created;
                                          let dateConversion = new Date(
                                            created
                                          );
                                          dateConversion =
                                            dateConversion.toDateString() +
                                            ", " +
                                            dateConversion.toLocaleTimeString(
                                              "en-US",
                                              {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              }
                                            );

                                          return dateConversion;
                                        })()}
                                      </p>
                                    </ListItem>
                                  </Grid>
                                  <Grid item lg={3} md={12} sm={12}>
                                    <ListItem>
                                      <h6>Tweet Type :</h6>&nbsp;
                                      {item._source.tweet_type}
                                    </ListItem>
                                  </Grid>
                                  <Grid item lg={3} md={12} sm={12}>
                                    <ListItem>
                                      <h6>Parent Post :</h6>&nbsp;
                                      {item._source.parent_post}
                                    </ListItem>
                                  </Grid>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12}>
                                  <ListItem>
                                    <Typography>
                                      <b
                                        style={{
                                          fontSize:
                                            theme.palette.buttonComponent
                                              .fontSize.extraLarge,
                                          lineHeight: "22px",
                                        }}
                                      >
                                        Tweet Content :
                                      </b>
                                      &nbsp;
                                      {item._source.content_of_the_tweet
                                        .replace(/&lt;/g, "<")
                                        .replace(/&gt;/g, ">")
                                        .replace(/&amp;/g, "&")}
                                    </Typography>
                                  </ListItem>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                  <ListItem>
                                    <Typography>
                                      <b
                                        style={{
                                          fontSize:
                                            theme.palette.buttonComponent
                                              .fontSize.extraLarge,
                                          lineHeight: "22px",
                                        }}
                                      >
                                        Sentiment value :
                                      </b>
                                      &nbsp;
                                      {item._source.sentiment_value &&
                                      typeof item._source.sentiment_value ===
                                        "number" &&
                                      item._source.sentiment_value > 0 ? (
                                        <IconButton
                                          className="p-0 m-0"
                                          title={returnSentimentname(item)}
                                        >
                                          <SentimentSatisfiedAltRoundedIcon
                                            titleAccess="Positive"
                                            style={{
                                              width: "16px",
                                              color: `green`,
                                            }}
                                          />
                                        </IconButton>
                                      ) : item._source.sentiment_value < 0 ? (
                                        <IconButton
                                          className="p-0 m-0"
                                          title={returnSentimentname(item)}
                                        >
                                          <SentimentDissatisfiedRoundedIcon
                                            titleAccess="Negative"
                                            style={{
                                              width: "16px",
                                              color: `${theme.palette.sentimentIconColor.negative}`,
                                            }}
                                          />
                                        </IconButton>
                                      ) : item._source.sentiment_value !==
                                        null ? (
                                        <IconButton
                                          className="p-0 m-0"
                                          title={returnSentimentname(item)}
                                        >
                                          <SentimentNeutralRoundedIcon
                                            titleAccess="Neutral"
                                            style={{
                                              width: "16px",
                                              color: `${theme.palette.sentimentIconColor.neutral}`,
                                            }}
                                          />
                                        </IconButton>
                                      ) : (
                                        ""
                                      )}
                                    </Typography>
                                  </ListItem>
                                </Grid>

                                <Grid item lg={3} md={3} sm={3}>
                                  <ListItem>
                                    <Typography>
                                      <b
                                        style={{
                                          fontSize:
                                            theme.palette.buttonComponent
                                              .fontSize.extraLarge,
                                          lineHeight: "22px",
                                        }}
                                      >
                                        Account Name :
                                      </b>
                                      &nbsp;
                                      {item._source.account_name}
                                    </Typography>
                                  </ListItem>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                container
                                lg={1}
                                md={2}
                                sm={2}
                                alignItems={"flex-start"}
                                justifyContent="space-between"
                              >
                                {item._source.feed_link && (
                                  <Grid
                                    item
                                    // className="bg-info"
                                    lg={1}
                                    xl={1}
                                    md={1}
                                    sm={1}
                                  >
                                    <Typography
                                      component={"a"}
                                      href={
                                        item._source.feed_link
                                          ? item._source.feed_link
                                          : ""
                                      }
                                      target="_blank"
                                      title="Feed Link"
                                    >
                                      <OpenInNewIcon
                                        fontSize="25px"
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Typography>
                                  </Grid>
                                )}

                                {circuler_progress_id ===
                                  item._source.ticket_id &&
                                  open_ticket && (
                                    <CircularProgress
                                      color="inherit"
                                      size={15}
                                      // className="bg-light"
                                    />
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </List>
                      </Box>
                    )
                )
              ) : (
                <NoDataFound />
              )}
            </LoadingIndicator>
          ) : null}
        </Box>
      </Grid>
      {props.twitter.hits
        ? props.twitter.hits.length !== 0 &&
          !isRequesting && (
            <PaginationUI
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChangePagination}
            />
          )
        : null}
    </Box>
  );
}

export default connect(mapStateToProps)(TwitterListView);
