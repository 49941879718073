import BaseReducer from "../../../../utilities/BaseReducer";
import SurveyUserAction from "./SurveyUserAction";

export default class SurveyUserByIdReducer extends BaseReducer {
  //initial state of user
  initialState = {
    surveyUserById: [],
  };

  //user request action finish
  [SurveyUserAction.REQUEST_SURVEY_USER_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      surveyUserById: action.payload,
    };
  }
}
