import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import TicketHistoryModel from "./models/TicketHistoryModel";
import OrganizationPostModel from "../../../common/stores/organization/OrganizationPostModel";

export default class TicketHistoryEffect {
  //get tickettype request end point
  static async requestTicketHistory(params) {
    const endpoint = environment.api.ticketHistory;
    return EffectUtility.getToModel(TicketHistoryModel, endpoint, params);
  }

  static async requestMassTicketHistory(params) {
    const endpoint = environment.api.ticketHistory;
    return EffectUtility.getToModel(TicketHistoryModel, endpoint, params);
  }

  static async requestTicketHistoryClear() {
    return {};
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }

  // post method for the Organization
  static async requestPostTicketHistory(data) {
    const endpoint = environment.api.ticketHistory + "/add";

    return EffectUtility.postToModel(OrganizationPostModel, endpoint, data);
  }
}
