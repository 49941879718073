import BaseReducer from "../../../../utilities/BaseReducer";
import KeywordDiscoverPatternAction from "./KeywordDiscoverPatternAction";

export default class KeywordDiscoverPatterReduser extends BaseReducer {
  //initialState of operation filter
  initialState = {
    keyword_discover_pattern: [],
  };

  //operation Filter
  [KeywordDiscoverPatternAction.REQUEST_KEYWORD_CONFIG_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      keyword_discover_pattern: action.payload,
    };
  }
}
