import { BaseModel } from "sjs-base-model";

export default class HssDetailsExecuteScriptModel extends BaseModel {
  status = "";
  message = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
