import { BaseModel } from "sjs-base-model";

export default class ActivityDataGetModel extends BaseModel {
  "activity data" = [];

  constructor(data) {
    super();
    this.update(data);
  }
}
