import { BaseModel } from "sjs-base-model";
import WhatsappAccountHitsModel from "./WhatsappAccountHitsModel";
import WhatsappAccountShardsModel from "./WhatsappAccountShardsModel";

// WhatsappAccountModel
export default class WhatsappAccountModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: WhatsappAccountShardsModel };
  current_page_no = 0;
  hits = { hits: WhatsappAccountHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
