import BaseReducer from "../../../utilities/BaseReducer";
import KeywordConfigAction from "./KeywordConfigAction";

export default class KeywordConfigFilterReduser extends BaseReducer {
  //initial state of KeywordConfigFilterReduser
  initialState = {
    keywordConfigurationFilter: [],
  };
  //KeywordConfigFilterReduser request action finish
  [KeywordConfigAction.REQUEST_KEYWORD_CONFIG_FILTER_FINISHED](state, action) {
    return {
      ...state,
      keywordConfigurationFilter: action.payload,
    };
  }
}
