import React, { useEffect, useState } from "react";
import {
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";

import { Form, Formik, Field, FieldArray, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiButton from "../../../components/muiButton/MuiButton";
import UserRoleMappingAction from "../../stores/userRoleMapping/UserRoleMappingAction";
import AutoCompleteMultiSelect from "../../../components/autoCompleteComponent/AutoCompleteMultiSelect";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  user: yup.object().shape({}).required("User is Required").nullable(),
  roles: yup.array().of(
    yup.object().shape({
      role: yup.object().shape({}).required("Role is Required").nullable(),
      project: yup
        .object()
        .shape({})
        .required("Project is Required")
        .nullable(),
    })
  ),
});

const PostUserRoleMapping = (props) => {
  const [orgId, setOrgId] = useState(null);

  let projectList = [];

  if (props.project_array && orgId) {
    props.project_array
      .filter((item) => {
        return item.org_id.organization_id === orgId?.org_id;
      })
      .map((val) => {
        projectList.push({
          project_id: val.project_id,
          project_name: val.project_name,
          organization_id: val.org_id?.organization_id,
        });
      });
  }

  projectList.push({ project_id: "*", project_name: "All" });

  function RoleList(projectId, values, ind) {
    let filtered_list = props.roleList.filter((val) => {
      return (
        val.project_id === projectId && val.organization_id === orgId?.org_id
      );
    });

    // initial.roles[ind].role = "";
    // values.roles[ind].role = "";

    return filtered_list;
  }

  let initial = {};

  if (!props.edit) {
    initial = {
      user: "",
      roles: [
        {
          role: "",
          project: "",
        },
      ],
    };
  } else {
    initial = {
      user: props.projectEditData.user_object,
      roles: props.projectEditData.roles
        ? SetEditInitialRoles(props.projectEditData.roles)
        : [],
    };
  }

  function SetEditInitialRoles(rolesArray) {
    let roles = [];

    rolesArray.map((item) => {
      return roles.push({
        role: SetRole(item.role_id),
        project: SetProject(item.project_id),
      });
    });

    return roles;
  }

  function SetRole(roleId) {
    let role = props.roleList.filter((item) => item.role_id === roleId);
    return role[0];
  }

  function SetProject(projectId) {
    let project = props.project_array.filter((item) => {
      return item.project_id === projectId.project_id;
    });
    return project[0] ? project[0] : { project_id: "*", project_name: "All" };
  }

  // const setProject_roles_value = (setFieldValue , values)=>
  // {

  //   values.roles.map((val , index)=>
  //   {
  //     setFieldValue(`roles.${index}.project` , "")
  //     setFieldValue(`roles.${index}.role` , "")
  //   })
  // }

  const [loading, setLoading] = useState(false);

  const AddIconFunction = (values, push) => {
    return (
      values.roles &&
      values.roles[values.roles.length - 1] &&
      values.roles[values.roles.length - 1].role &&
      values.roles[values.roles.length - 1].project && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="flex-end"
          mt={1}
        >
          {" "}
          <MuiButton
            onClick={() => push({ role: "", project: "" })}
            name={<AddIcon />}
          />
        </Grid>
      )
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          const params = {
            order_by: '[["modified_on", "desc"]]',
            page_limit: 15,
          };
          const param = {
            order_by: '[["modified_on", "desc"]]',
            page_limit: "none",
          };

          let obj = {
            user_id: values.user.user_id,
            roles: [],
          };

          values.roles.map((item) => {
            obj.roles.push(item.role);
          });

          if (!props.edit) {
            props
              .dispatch(
                UserRoleMappingAction.requestPostUserRoleMapping(
                  JSON.stringify(obj)
                )
              )
              .then(() => {
                props.dispatch(
                  UserRoleMappingAction.requestUserRoleMapping(params)
                );
                props.dispatch(
                  UserRoleMappingAction.requestUserRoleMappingFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          } else {
            props
              .dispatch(
                UserRoleMappingAction.requestPutUserRoleMapping(
                  JSON.stringify(obj),
                  props.projectEditData.userRole_unique_id
                )
              )
              .then(() => {
                props.dispatch(
                  UserRoleMappingAction.requestUserRoleMapping(params)
                );
                props.dispatch(
                  UserRoleMappingAction.requestUserRoleMappingFilter(param)
                );
                props.onClose();
                setLoading(false);
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
          resetForm,
        }) => {
          return (
            <Form>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DialogContentText sx={{ width: "80%" }}>
                  <div className="text-center pb-2">
                    <div className="row p-3">
                      <div className="col-10">
                        <h3 className="dailogtext">
                          {!props.edit ? (
                            <b>
                              <span className="span_first_letter">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;M
                              </span>
                              ap &nbsp;
                              <span className="span_first_letter">U</span>ser
                              &nbsp;
                              <span className="span_first_letter">R</span>ole
                            </b>
                          ) : (
                            <b>
                              <span className="span_first_letter">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                              </span>
                              dit &nbsp;
                              <span className="span_first_letter">U</span>ser
                              &nbsp;
                              <span className="span_first_letter">R</span>ole
                            </b>
                          )}
                        </h3>
                      </div>
                      <div
                        className="col-2 closebtn"
                        onClick={() => {
                          props.onClose();
                          props.setEdit(false);
                        }}
                      >
                        <i
                          title="Close"
                          className="fa fa-times-circle-o fa-2x"
                          style={{ size: "15px" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    // p={1}
                    className="d-flex"
                  >
                    <Grid item lg={2} md={2} sm={2} mt={3}>
                      User Name * :
                    </Grid>
                    <Grid item lg={4.8} md={4.8} sm={4.8}>
                      <Field
                        name="user"
                        size="small"
                        // Calling AutoCompleteMultiSelect Component
                        disabled={props.edit ? true : false}
                        value={values.user ? values.user : null}
                        component={AutoCompleteMultiSelect}
                        options={props.postUserList}
                        isOptionEqualToValue={(option, value) =>
                          //setting value property to custom value
                          option.fullName === value.fullName
                        }
                        // onChange={(_, value) => {
                        //   // setFieldValue(`user`, value);
                        //   // setFieldValue(`roles.project`, "");
                        // }}
                        filterOptions={createFilterOptions({
                          matchFrom: "start",
                          stringify: (option) => option.fullName,
                        })}
                        getOptionLabel={(option) => {
                          return option.user_name !== ""
                            ? `${option.fullName} (${option.user_name})`
                            : "";
                        }}
                        onChange={(_, value) => {
                          setFieldValue(`user`, value);
                          setFieldValue(`roles`, [
                            {
                              role: "",
                              project: "",
                            },
                          ]);
                        }}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Users",
                          autoFocus: true,
                        }}
                      />
                      {setOrgId(values.user ? values.user : "")}
                    </Grid>
                  </Grid>
                </Grid>
                <FieldArray name="roles">
                  {({ insert, remove, push }) => (
                    <Grid
                      container
                      lg={12}
                      md={12}
                      sm={12}
                      mt={1}
                      // px={1}
                      className="d-flex justify-content-center"
                    >
                      {values.roles &&
                        values.roles.length > 0 &&
                        values.roles.map((role, index) => (
                          <Grid
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1}
                            // px={1}
                            key={index}
                            // className="bg-success"
                          >
                            <Grid item xl={5} lg={5} md={5} sm={5} container>
                              <Grid item lg={3.5} md={3.5} sm={3.5} mt={3}>
                                Project * :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`roles.${index}.project`}
                                  size="small"
                                  disableClearable={true}
                                  // Calling AutoCompleteMultiSelect Component
                                  value={
                                    values.roles[index].project
                                      ? values.roles[index].project
                                      : null
                                  }
                                  component={AutoCompleteMultiSelect}
                                  options={projectList ? projectList : []}
                                  onChange={(_, value) => {
                                    setFieldValue(
                                      `roles.${index}.project`,
                                      value
                                    );
                                    setFieldValue(`roles.${index}.role`, "");
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.project_name === value.project_name
                                  }
                                  getOptionLabel={(option) =>
                                    option.project_name
                                  }
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Project",
                                  }}
                                />
                              </Grid>
                            </Grid>

                            <Grid item xl={5} lg={5} md={5} sm={5} container>
                              <Grid item lg={3} md={3} sm={3} mt={3}>
                                Roles * :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  name={`roles.${index}.role`}
                                  size="small"
                                  disableClearable={true}
                                  // Calling AutoCompleteMultiSelect Component
                                  value={
                                    values.roles[index].role
                                      ? values.roles[index].role
                                      : null
                                  }
                                  component={AutoCompleteMultiSelect}
                                  options={RoleList(
                                    values.roles[index].project
                                      ? values.roles[index].project.project_id
                                      : "",
                                    values,
                                    index
                                  )}
                                  // options={props.roleList}
                                  isOptionEqualToValue={(option, value) =>
                                    //setting value property to custom value
                                    option.role_name === value.role_name
                                  }
                                  getOptionLabel={(option) => option.role_name}
                                  textFieldProps={{
                                    margin: "normal",
                                    variant: "outlined",
                                    placeholder: "Role",
                                  }}
                                />
                              </Grid>
                            </Grid>
                            {values?.roles?.length !== 1 && (
                              <Grid
                                item
                                xl={1}
                                lg={1}
                                md={1}
                                sm={1}
                                container
                                mt={1}
                                className="d-flex justify-content-end"
                              >
                                <MuiButton
                                  name={<DeleteIcon />}
                                  onClick={() => remove(index)}
                                />
                              </Grid>
                            )}
                          </Grid>
                        ))}

                      {AddIconFunction(values, push)}
                    </Grid>
                  )}
                </FieldArray>
              </DialogContent>

              <DialogActions className="m-1 my-4">
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  display="flex"
                  justifyContent="space-evenly"
                >
                  <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                    {!props.edit ? (
                      <MuiButton
                        type="submit"
                        name="Map User Role"
                        width="100%"
                        loading={loading}
                      />
                    ) : (
                      <MuiButton
                        type="submit"
                        loading={loading}
                        name="Submit"
                        width="100%"
                      />
                    )}
                  </Grid>
                  <Grid item lg={5} md={5} sm={5} minWidth={200}>
                    {!props.edit ? (
                      <MuiButton
                        type="reset"
                        name="Reset"
                        onClick={() => {}}
                        width="100%"
                      />
                    ) : (
                      <MuiButton
                        name={"Cancel"}
                        width="100%"
                        onClick={() => {
                          props.onClose();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(PostUserRoleMapping);
