import { BaseModel } from "sjs-base-model";

export default class FaqModulesGetModel extends BaseModel {
  ["doc_count"] = 0;
  ["key"] = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
