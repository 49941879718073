import ActionUtility from "../../../utilities/ActionUtility";
import AppStoreAddAccountEffect from "./AppStoreAddAccountEffect";

export default class AppStoreAddAccountAction {
  //AppStore Account get request
  static REQUEST_APPSTORE_ACCOUNT =
    "AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT";
  static REQUEST_APPSTORE_ACCOUNT_FINISHED =
    "AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT_FINISHED";

  //Add Appstore account post request
  static REQUEST_POST_APPSTORE_ACCOUNT =
    "AppStoreAddAccountAction.REQUEST_POST_APPSTORE_ACCOUNT";
  static REQUEST_POST_APPSTORE_ACCOUNT_FINISHED =
    "AppStoreAddAccountAction.REQUEST_POST_APPSTORE_ACCOUNT_FINISHED";

  //Appstore account put requests
  static REQUEST_PUT_APPSTORE_ACCOUNT =
    "AppStoreAddAccountAction.REQUEST_PUT_APPSTORE_ACCOUNT";
  static REQUEST_PUT_APPSTORE_ACCOUNT_FINISHED =
    "AppStoreAddAccountAction.REQUEST_PUT_APPSTORE_ACCOUNT_FINISHED";

  //Appstore account filters
  static REQUEST_APPSTORE_ACCOUNT_FILTER =
    "AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT_FILTER";
  static REQUEST_APPSTORE_ACCOUNT_FILTER_FINISHED =
    "AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT_FILTER_FINISHED";

  static REQUEST_DELETE_APPSTORE_ACCOUNT =
    "AppStoreAddAccountAction.REQUEST_DELETE_APPSTORE_ACCOUNT";
  static REQUEST_DELETE_APPSTORE_ACCOUNT_FINISHED =
    "AppStoreAddAccountAction.REQUEST_DELETE_APPSTORE_ACCOUNT_FINISHED";

  //METHODS

  //AppStore Account get method
  static requestAppStoreAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT,
        AppStoreAddAccountEffect.requestAppStoreAccount,
        params
      );
    };
  }

  //Appstore Account get for filter dropdown
  static requestAppStoreAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAddAccountAction.REQUEST_APPSTORE_ACCOUNT_FILTER,
        AppStoreAddAccountEffect.requestAppStoreAccountFilter,
        params
      );
    };
  }

  //Appstore Account Post method
  static requestPostAppstoreAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAddAccountAction.REQUEST_POST_APPSTORE_ACCOUNT,
        AppStoreAddAccountEffect.requestPostAppstoreAccount,
        data
      );
    };
  }

  //Appstore Account Put method
  static requestPutAppstoreAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAddAccountAction.REQUEST_PUT_APPSTORE_ACCOUNT,
        AppStoreAddAccountEffect.requestPutAppSstoreAccount,
        data,
        id
      );
    };
  }

  //Appstore Account delete method
  static requestDeleteAppstoreAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AppStoreAddAccountAction.REQUEST_DELETE_APPSTORE_ACCOUNT,
        AppStoreAddAccountEffect.requestDeleteAppstoreAccount,
        id
      );
    };
  }
}
