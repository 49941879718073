import { BaseModel } from "sjs-base-model";

export default class AppStatusModel extends BaseModel {
  data = [];
  pagination = [];
  // status = "";
  total_counts = 0;

  constructor(data) {
    super();

    this.update(data);
  }
}
