import BaseReducer from "../../../../utilities/BaseReducer";
import CustomerAction from "./CustomerAction";

export default class CustomerFilterReducer extends BaseReducer {
  //initial state of CustomerFilterReduser
  initialState = {
    Customer_filter: [],
  };
  //CustomerFilterReduser request action finish
  [CustomerAction.REQUEST_CUSTOMER_FILTER_FINISHED](state, action) {
    return {
      ...state,
      Customer_filter: action.payload,
    };
  }

  [CustomerAction.REQUEST_CUSTOMER_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Customer_filter: action.payload,
    };
  }
}
