import BaseReducer from "../../../../utilities/BaseReducer";
import SessionLogAction from "./SessionLogAction";

// SessionLogReuser
export default class SessionLogReportReducer extends BaseReducer {
  initialState = {
    sessionLogReport: [],
  };
  [SessionLogAction.REQUEST_SESSION_LOG_REPORT_FINISHED](state, action) {
    return {
      ...state,
      sessionLogReport: action.payload,
    };
  }
}
