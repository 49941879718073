import ActionUtility from "../../../../utilities/ActionUtility";
import QuestionCreateEffect from "./QuestionCreateEffect";

export default class QuestionsAction {
  // Question action with an function declaration
  static REQUEST_QUESTIONS = "QuestionsAction.REQUEST_QUESTIONS";
  static REQUEST_QUESTIONS_FINISHED =
    "QuestionsAction.REQUEST_QUESTIONS_FINISHED";

  static REQUEST_QUESTIONS_POST = "QuestionsAction.REQUEST_QUESTIONS_POST";
  static REQUEST_QUESTIONS_POST_FINISHED =
    "QuestionsAction.REQUEST_QUESTIONS_POST_FINISHED";

  // Question action with an function declaration
  static REQUEST_QUESTIONS_FILTER = "QuestionsAction.REQUEST_QUESTIONS_FILTER";
  static REQUEST_QUESTIONS_FILTER_FINISHED =
    "QuestionsAction.REQUEST_QUESTIONS_FILTER_FINISHED";

  static REQUEST_QUESTIONS_PUT = "QuestionsAction.REQUEST_QUESTIONS_PUT";
  static REQUEST_QUESTIONS_PUT_FINISHED =
    "QuestionsAction.REQUEST_QUESTIONS_PUT_FINISHED";

  //Question put Bulk
  static REQUEST_PUT_QUESTIONS_BULKUPDATE =
    "QuestionsAction.REQUEST_PUT_QUESTIONS_BULKUPDATE";
  static REQUEST_PUT_QUESTIONS_BULKUPDATE_FINISHED =
    "QuestionsAction.REQUEST_PUT_QUESTIONS_BULKUPDATE_FINISHED";

  // METHODS
  // Question GET function
  static requestQuestion(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuestionsAction.REQUEST_QUESTIONS,
        QuestionCreateEffect.requestQuestion,
        params
      );
    };
  }

  // Question filter
  static requestQuestionFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuestionsAction.REQUEST_QUESTIONS_FILTER,
        QuestionCreateEffect.requestQuestionFilter,
        params
      );
    };
  }

  // Question filter
  static requestQuestionsPost(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuestionsAction.REQUEST_QUESTIONS_POST,
        QuestionCreateEffect.requestQuestionsPost,
        params
      );
    };
  }

  // put method
  static requestQuestionsPut(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuestionsAction.REQUEST_QUESTIONS_PUT,
        QuestionCreateEffect.requestQuestionsPut,
        data,
        id
      );
    };
  }

  // questions Bulk put function
  static requestPutQuestionsBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuestionsAction.REQUEST_PUT_QUESTIONS_BULKUPDATE,
        QuestionCreateEffect.requestPutQuestionsBulkUpdate,
        data
      );
    };
  }
}
