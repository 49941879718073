import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import AgentReportsModel from "./models/AgentReportsModel";
import SentimentalAnalysisReportsModel from "./models/SentimentalAnalysisReportsModel";
import TicketReportsModel from "./models/TicketReportsModel";

export default class TicketReportSEffect {
  static async requestTicketREports(params) {
    const endpoint = environment.api.ticketReports;

    return EffectUtility.getToModel(TicketReportsModel, endpoint, params);
  }

  static async requestAgentReport(params) {
    const endpoint = environment.api.agentReports;

    return EffectUtility.getToModel(AgentReportsModel, endpoint, params);
  }

  static async requestSentimentalAnalysisReports(params) {
    const endpoint = environment.api.sentimentalAnalysisReports;

    return EffectUtility.getToModel(
      SentimentalAnalysisReportsModel,
      endpoint,
      params
    );
  }
}
