import BaseReducer from "../../../utilities/BaseReducer";
import MailTiggerListAction from "./MailTiggerListAction";

export default class MailTriggerLIstFilterReduser extends BaseReducer {
  //initial state of MailTriggerLIstFilterReduser
  initialState = {
    trigger_list_filter: [],
  };
  //MailTriggerLIstFilterReduser request action finish
  [MailTiggerListAction.REQUEST_TRIGGERLIST_FILTER_FINISHED](state, action) {
    return {
      ...state,
      trigger_list_filter: action.payload,
    };
  }
}
