import BaseReducer from "../../../../utilities/BaseReducer";
import BatchLinksAction from "./BatchLinksAction";

export default class BatchLinksFilterReducer extends BaseReducer {
  initialState = {
    batchLinksFilter: [],
  };
  [BatchLinksAction.REQUEST_BATCH_LINKS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      batchLinksFilter: action.payload,
    };
  }

  [BatchLinksAction.REQUEST_BATCH_LINKS_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      batchLinksFilter: action.payload,
    };
  }
}
