import ActionUtility from "../../../utilities/ActionUtility";
import CloudMailEffect from "./CloudMailEffect";

export default class CloudMailAction {
  // CloudMailAction action with an function declaration
  static REQUEST_CLOUDMAIL = "CloudMailAction.REQUEST_CLOUDMAIL";
  static REQUEST_CLOUDMAIL_FINISHED =
    "CloudMailAction.REQUEST_CLOUDMAIL_FINISHED";

  static REQUEST_CLOUDMAIL_FILTER = "CloudMailAction.REQUEST_CLOUDMAIL_FILTER";
  static REQUEST_CLOUDMAIL_FILTER_FINISHED =
    "CloudMailAction.REQUEST_CLOUDMAIL_FILTER_FINISHED";

  static REQUEST_PUT_CLOUDMAIL = "CloudMailAction.REQUEST_PUT_CLOUDMAIL";
  static REQUEST_PUT_CLOUDMAIL_FINISHED =
    "CloudMailAction.REQUEST_PUT_CLOUDMAIL_FINISHED";

  static REQUEST_POST_CLOUDMAIL = "CloudMailAction.REQUEST_POST_CLOUDMAIL";
  static REQUEST_POST_CLOUDMAIL_FINISHED =
    "CloudMailAction.REQUEST_POST_CLOUDMAIL_FINISHED";

  // METHODS
  // Cloudmail GET function
  static requestCloudMail(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CloudMailAction.REQUEST_CLOUDMAIL,
        CloudMailEffect.requestCloudMail,
        params
      );
    };
  }

  static requestCloudMailFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CloudMailAction.REQUEST_CLOUDMAIL_FILTER,
        CloudMailEffect.requestCloudMailFilter,
        params
      );
    };
  }
  static requestPutCloudMail(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CloudMailAction.REQUEST_PUT_CLOUDMAIL,
        CloudMailEffect.requestPutCloudMail,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostCloudMail(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CloudMailAction.REQUEST_POST_CLOUDMAIL,
        CloudMailEffect.requestPostCloudMail,
        data
      );
    };
  }
}
