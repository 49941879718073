import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { Box, Typography, Grid } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import {
  firstLetterOrange,
  prefixZero_for_singleDigit,
} from "../../../components/ticketPartials";
import DataGridFunction from "../../../components/datagridComponent/datagrid";

const OverallAverageFRT = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter).then((e) => {
        setData(e);
      });
      setLoading(false);
      props.setclearClicked && props.setclearClicked(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter).then((e) => {
          setData(e);
        });
        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter).then((e) => {
          setData(e);
        });
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.agentReports
    }?report_type=["count"]&reports=["platform_wise_flr"]${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${
    //     environment.api.agentReports
    //   }?report_type=["count"]&reports=["platform_wise_flr"]${
    //     props.DateFilter ? `&from=${dateRange[0]}&to=${dateRange[1]}` : ""
    //   }`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     return response;
    //   });
  }

  let ChartList = [];

  data &&
    data.agent_report &&
    data.agent_report.platform_wise_flr &&
    data.agent_report.platform_wise_flr.length !== 0 &&
    data.agent_report.platform_wise_flr.map((item) => {
      ChartList.push({
        name: Object.keys(item)[0],
        count: item && item[Object.keys(item)].count,
        value: item && item[Object.keys(item)].value,
      });
    });

  let platformList = [];
  if (props.projectById && props.projectById._source) {
    props.projectById._source.platform.map((item) => {
      platformList.push({
        platform_id: item.platform_id,
        platform_name: item.platform_name,
      });
    });
  }

  function platformName(val) {
    return (
      props.platformList &&
      props.platformList
        .filter((item) => item.platform_id === val)
        .map((item) => {
          return item.platform_alias;
        })
    );
  }
  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };

  // const summaryBoxRef = useRef(null);

  // let dataGridHeight = useRef(null);

  // useEffect(() => {
  //   const calculateHeights = () => {
  //     const outerBoxHeight = summaryBoxRef.current.clientHeight;

  //     if (
  //       summaryBoxRef.current &&
  //       summaryBoxRef.current.children[1] &&
  //       summaryBoxRef.current.children[0]
  //     ) {
  //       summaryBoxRef.current.children[0].style.height = 50;

  //       dataGridHeight.current = outerBoxHeight - 75;
  //     }
  //   };

  //   calculateHeights();

  //   // Use ResizeObserver to listen for changes in the size of summaryBoxRef
  //   const resizeObserver = new ResizeObserver(calculateHeights);
  //   resizeObserver.observe(summaryBoxRef.current);

  //   // Cleanup ResizeObserver on component unmount
  //   return () => {
  //     resizeObserver.disconnect();
  //   };
  // }, []); // Empty dependency array to ensure the effect runs only once on mount

  // const [renderKey, setRenderKey] = useState(0);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  let columns = [
    {
      field: "platforms",
      headerName: "Platforms",
      resizable: true,
      flex: 2,
      minWidth: 150,
      // renderCell: (params) => (
      //   <p
      //     className={`${
      //       params.row.platforms == "Total"
      //         ? "font-weight-bold p-0 m-0"
      //         : "font-weight-normal p-0 m-0"
      //     }`}
      //   >
      //     {params.row.platforms}
      //   </p>
      // ),
    },
    {
      field: "count",
      headerName: "Count",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 2,
      // renderCell: (params) => (
      //   <p
      //     className={`${
      //       params.row.platforms == "Total"
      //         ? "font-weight-bold p-0 m-0"
      //         : "font-weight-normal p-0 m-0"
      //     }`}
      //   >
      //     {params.row.platforms}
      //   </p>
      // ),
    },
    {
      field: "avg",
      headerName: "Avg FRT",
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 2,
      // renderCell: (params) => (
      //   <p
      //     className={`${
      //       params.row.platforms == "Total"
      //         ? "font-weight-bold p-0 m-0"
      //         : "font-weight-normal p-0 m-0"
      //     }`}
      //   >
      //     {params.row.platforms}
      //   </p>
      // ),
    },
  ];

  let rows = [];

  if (ChartList?.length) {
    ChartList.map((platform, index) => {
      rows.push({
        id: index + 1,
        platforms: platform.name ? platformName(platform.name) : "",
        count: prefixZero_for_singleDigit(platform.count),
        avg: platform.value ? HoursAndMinutes(platform.value) : "00:00:00",
      });
    });
  }

  useEffect(() => {
    if (props.DownloadClicked) {
      const exportData = [];

      rows?.map((item) => {
        exportData.push({
          Platforms: item?.platforms[0],
          Count: item.count,
          ["Avg FRT"]: item.avg,
        });
      });

      props.setexcelData(exportData);
    }
  }, [props.DownloadClicked, rows]);
  return props.dataGridHeight ? (
    // <Box
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //   }}

    // >
    <LoadingIndicator isActive={loading}>
      {/* <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} /> */}
      {/* <Box
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        > */}
      {/* <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                fontSize: theme.reports.headerSize,
                padding: theme.reports.headerPadding,
              }}
            >
              {firstLetterOrange(props.report && props.report)}
            </Typography>
            <hr className="p-0 m-0 " />
          </Box> */}

      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {!loading &&
            ChartList.length > 0 &&
            props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid container lg={12} md={12} sm={12}>
                  <DataGridFunction
                    rows={rows}
                    columns={columns}
                    rowBorder={false}
                    height={props.dataGridHeight.current}
                    rowHeight={50}
                    headerHeight={80}
                    onCellClick={onCellClick}
                    rowColor={true}
                    headerColor={true}
                  />
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}

        {window.location.pathname === "/ticketStatsReport" && (
          <Description
            description={props.description}
            callBack={props.callBack}
            report={props.report}
            index={0}
          />
        )}
      </Box>
      {/* </Box> */}
    </LoadingIndicator>
  ) : // </Box>
  null;
};

export default OverallAverageFRT;
