import { BaseModel } from "sjs-base-model";

export default class YoutubeChannelRevenueModel extends BaseModel {
  /*
   * YoutubeOfficial-Side properties (Not from API)
   */
  // response getting from the api mentioned end point

  data = [];

  constructor(data) {
    super();
    this.update(data);
  }
}
