import { BaseModel } from "sjs-base-model";
import RetweetDataMode from "./RetweetDataMode.";

export default class RetweetGetModel extends BaseModel {
  data = {RetweetDataMode}
  status = "";

  constructor(data1) {
    super();
    this.update(data1);
  }
}
