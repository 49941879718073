import { BaseModel } from "sjs-base-model";
import McnMonitoringDataHitsModel from "./McnMonitoringDataHitsModel";

//McnMonitoringHitsModel
export default class McnMonitoringHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ McnMonitoringDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
