import { BaseModel } from "sjs-base-model";
import InfluencerDashboardHitsModel from "./InfluencerDashboardHitsModel";
import InfluencerDashboardShardsModel from "./InfluencerDashboardShardsModel";

// InfluencerDashboardModel
export default class InfluencerDashboardModel extends BaseModel {
  // response getting from the api mentioned end point

  data = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
