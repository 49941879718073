import environment from "environment";
import HttpErrorResponseModel from "../../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../../utilities/HttpUtility";
import EffectUtility from "../../../../utilities/EffectUtility";
import A3NotificationGetModel from "./models/A3NotificationsGetModel";
// import A3NotificationGetByIdModel from "./models/A3NotificationsByIdModel";
import A3NotificationsBulkUpdateModel from "./models/A3notificationBulkUpdateModel";
import A3NotificationsPutModel from "./models/A3NotificationsPutModel";

export default class A3notificationEffect {
  static async requestA3Notifications(params) {
    const endpoint = environment.api.a3Notifications;
    return EffectUtility.getToModel(A3NotificationGetModel, endpoint, params);
  }

  static async requestA3FilterNotifications(params, callback) {
    const endpoint = environment.api.a3Notifications;
    let response = EffectUtility.getToModel(
      A3NotificationGetModel,
      endpoint,
      params
    );
    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  static async requestPutA3NotificationsBulkUpdate(data) {
    const endpoint = environment.api.a3NotificationsBulkUpdate;
    return EffectUtility.putToModel(
      A3NotificationsBulkUpdateModel,
      endpoint,
      data
    );
  }

  // static async requestPostA3Notifications(data) {
  //   const endpoint = environment.api.ormNotificationsData;
  //   return EffectUtility.postToModel(, endpoint, data);
  // }

  static async requestPutA3Notifications(data, id) {
    const endpoint = environment.api.a3Notifications + "/" + id;
    return EffectUtility.putToModel(A3NotificationsPutModel, endpoint, data);
  }

  // static async requestDeleteA3Notifications(id) {
  //   const endpoint = environment.api.ormNotificationsData + "/" + id;

  //   return EffectUtility.deleteToModel(, endpoint);
  // }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
