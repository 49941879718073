import { BaseModel } from "sjs-base-model";

// CloudMailOrg Data Model
export default class CloudMailOrgDataModel extends BaseModel {
  // response getting from the api mentioned end point

  id = "";
  name = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
