import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
// DataGridFunction Component
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import StatusPost from "./StatusPost";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import StatusAction from "../../stores/status/StatusAction";
import SwitchComponent from "../../../components/switchComponent/SwitchComponent";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

// mapstatetoprops function

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    StatusAction.REQUEST_STATUS,
    StatusAction.REQUEST_STATUS_FILTER,
  ]),
  status: state.status.status.hits || [],
  pagination: state.status.status || [],
  statusFilter: state.statusFilter.statusFilter || [],
});

function StatusGridView(props) {
  //******************************************************** SetState
  const [searchStatus, setSearchStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const switchComponentHandleChange = (status_id, enabled_value) => {
    let obj = {
      initial_status: enabled_value === true ? false : true,
    };
    props
      .dispatch(StatusAction.requestPutStatus(JSON.stringify(obj), status_id))
      .then(() => {
        const params = {
          order_by: `[["order_number","asc"]]`,
          page_limit: `${page_limit}`,
        };
        const param = {
          order_by: `[["status_name.keyword", "asc"]]`,
          page_limit: "none",
        };
        props.dispatch(StatusAction.requestStatus(params));
        props.dispatch(StatusAction.requestStatusFilter(param));
      });
  };

  const switchComponentHandleChange_final_status = (
    status_id,
    enabled_value
  ) => {
    let obj = {
      final_status: enabled_value === true ? false : true,
    };
    props
      .dispatch(StatusAction.requestPutStatus(JSON.stringify(obj), status_id))
      .then(() => {
        const params = {
          order_by: `[["order_number","asc"]]`,
          page_limit: `${page_limit}`,
        };
        const param = {
          order_by: `[["status_name.keyword", "asc"]]`,
          page_limit: "none",
        };
        props.dispatch(StatusAction.requestStatus(params));
        props.dispatch(StatusAction.requestStatusFilter(param));
      });
  };

  // ***************************************************** Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "status_name",
      headerName: "Status Name",
      width: 250,
      flex: 3,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
    },
    {
      field: "initial_status",
      headerName: "Initial Status",
      width: 100,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <SwitchComponent
              id={params.row.id ? params.row.id : ""}
              checked={params.row.initial_status}
              onClick={() => {
                switchComponentHandleChange(
                  params.row.id,
                  params.row.initial_status
                );
              }}
            />
          </Box>
        );
      },
    },

    {
      field: "final_status",
      headerName: "Final Status",
      width: 100,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <SwitchComponent
              id={params.row.id ? params.row.id : ""}
              checked={params.row.final_status}
              onClick={() => {
                switchComponentHandleChange_final_status(
                  params.row.id,
                  params.row.final_status
                );
              }}
            />
          </Box>
        );
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      hide: false,
      align: "center",
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
            title="Edit"
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const { isRequesting } = props;

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);
  // *********************************************  fetch methods

  function fetchStatus(params) {
    props.dispatch(StatusAction.requestStatus(params));
  }

  function fetchStatusFilter(param) {
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  // **************************************************  UseEffect
  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["status_name.keyword", "asc"]]`,
    };

    // if (
    //   props?.statusFilter === undefined ||
    //   Object.keys(props.statusFilter).length === 0
    // ) {
    fetchStatusFilter(param);
    // }

    // projectFetch(param);

    const filter = [];
    let params = {};

    if (searchStatus !== "") {
      filter.push(`["status_name.keyword","must","","term","${searchStatus}"]`);
    }

    if (searchStatus !== "") {
      params = {
        filters: `[${filter}]`,
        order_by: `[["order_number","asc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: 0,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    fetchStatus(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //********************************************************* pagination
  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    if (searchStatus !== "") {
      filter.push(`["status_name.keyword","must","","term","${searchStatus}"]`);
    }
    if (searchStatus !== "" && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["order_number","asc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchStatus(params);
    }
  };

  // *******************************************************  handle Filter
  const handleFilter = () => {
    const filter = [];

    if (searchStatus !== "") {
      filter.push(`["status_name.keyword","must","","term","${searchStatus}"]`);
    }

    if (searchStatus !== "") {
      const params = {
        filters: `[${filter}]`,
        order_by: `[["order_number","asc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchStatus(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];

  if (props.status.hits) {
    props.status.hits.map((item, index) => {
      return rows.push({
        // sl_no: item._id
        //   ? props.pagination.current_page_no * page_limit + ++index
        //   : "",
        sl_no: item._source.order_number,
        id: item._id,
        _index: item._index,
        _score: item._score,
        status_name: item._source.status_name,
        created_by: item._source.created_by.user_name,
        initial_status: item._source.initial_status,
        final_status: item._source.final_status,
        order_number: item._source.order_number,
      });
    });
  }

  // ****************************************** tag name handle change

  const statusListHandleChange = (event, searchStatus) => {
    if (searchStatus != null) {
      setSearchStatus(searchStatus);
    } else {
      setSearchStatus("");
    }
  };

  // ********************************************* Tag Filter List
  let statuslist = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits.forEach((value) => {
      statuslist.push(value._source.status_name);
    });
  }

  function updateRowPosition(oldIndex, targetIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];

        const row = rowsClone.splice(oldIndex, 1)[0];
        rowsClone.splice(targetIndex, 0, row);
        resolve(rowsClone);
      }, 100);
    });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    const newArr = newRows.map((obj, index) => {
      return {
        ...obj,
        order_number:
          props.pagination &&
          props.pagination.current_page_no * page_limit + ++index,
      };
    });

    let data = [];

    const bulkData = { data };

    newArr.map((item) => {
      data.push({
        _index: item._index,
        _id: item.id,
        _source: {
          order_number: item.order_number,
        },
      });
    });

    const paramss = {
      order_by: `[["order_number","asc"]]`,
      page_limit: 15,
    };
    const param = {
      order_by: `[["status_name.keyword", "asc"]]`,
      page_limit: "none",
    };

    data &&
      props
        .dispatch(StatusAction.requestPutStatusBulkUpdate(bulkData))
        .then(() => {
          props.dispatch(StatusAction.requestStatus(paramss));
          props.dispatch(StatusAction.requestStatusFilter(param));
        });
  };

  let uniqueList = [...new Set(statuslist)];

  uniqueList.sort((a, b) => a.localeCompare(b));
  uniqueList = [...new Set(uniqueList)];

  //***************************************** */ project nama list

  // ************************************************* clear function

  const ClearState = () => {
    // Clear the state

    if (searchStatus !== "") {
      setClearLoader(true);
      setSearchStatus("");
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);
    const params = {
      order_by: `[["order_number","asc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetchStatus(params);
  };

  return (
    <React.Fragment>
      {/* Tag post modal screen */}
      <Box>
        <StatusPost
          open={open}
          onSubmit={() => {}}
          onClose={() => setOpen(false)}
          projectEditData={editRowsModel}
          edit={edit}
        />

        {/*************************************************** Tag Filter */}

        <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.5} mt={1}>
              <AutoCompleteComponent
                value={searchStatus}
                list={uniqueList}
                textFieldProps={{
                  placeholder: "Status Name",
                }}
                handleChange={statusListHandleChange}
                size="small"
                width="100%"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={5}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  sm={8}
                  md={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Filter"}
                      width="100%"
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  {/* post button component*/}
                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid item lg={8} md={12} sm={12} xs={12}>
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {props.status.hits && !isRequesting ? (
                    <DataGridFunction
                      columns={columns}
                      rows={rows}
                      rowHeight={60}
                      rowReordering
                      onRowOrderChange={rowOrderHandleChange}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            id: false,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingBgImage />
                    </Box>
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>

            {/* Pagination */}
            {/* <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={10}
              md={12}
              sm={12}
              className="pagination_section"
            >
              <Grid item lg={8} md={12} sm={12}>
                {props.tag.hits
                  ? props.tag.hits.length !== 0 && (
                      <PaginationUI
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  : null}
              </Grid>
            </Grid> */}
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(StatusGridView);
