import { BaseModel } from "sjs-base-model";

export default class BatchLinkStatusGetModel extends BaseModel {
  batch_status = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
