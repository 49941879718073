import BaseReducer from "../../../../utilities/BaseReducer";
import McnMonitoringAction from "./McnMonitoringAction";

export default class McnMonitoringFilterReduser extends BaseReducer {
  //initial state of mcnMonitoringFilter
  initialState = {
    mcnMonitoringFilter: [],
  };
  //mcnMonitoring request action finish
  [McnMonitoringAction.REQUEST_MCN_MONITORING_FILTER_FINISHED](state, action) {
    return {
      ...state,
      mcnMonitoringFilter: action.payload,
    };
  }
}
