import ActionUtility from "../../../../utilities/ActionUtility";
import GenreEffect from "./GenreEffect";

export default class GenreAction {
  // GenreAction action with an function declaration
  static REQUEST_GENRE = "GenreAction.REQUEST_GENRE";
  static REQUEST_GENRE_FINISHED = "GenreAction.REQUEST_GENRE_FINISHED";

  static REQUEST_GENRE_FILTER = "GenreAction.REQUEST_GENRE_FILTER";
  static REQUEST_GENRE_FILTER_FINISHED =
    "GenreAction.REQUEST_GENRE_FILTER_FINISHED";

  static REQUEST_PUT_GENRE = "GenreAction.REQUEST_PUT_GENRE";
  static REQUEST_PUT_GENRE_FINISHED = "GenreAction.REQUEST_PUT_GENRE_FINISHED";

  static REQUEST_POST_GENRE = "GenreAction.REQUEST_POST_GENRE";
  static REQUEST_POST_GENRE_FINISHED =
    "GenreAction.REQUEST_POST_GENRE_FINISHED";

  static REQUEST_PUT_GENRE_BULKUPDATE =
    "GenreAction.REQUEST_PUT_GENRE_BULKUPDATE";
  static REQUEST_PUT_GENRE_BULKUPDATE_FINISHED =
    "GenreAction.REQUEST_PUT_GENRE_BULKUPDATE_FINISHED";

  static REQUEST_GENRE_CLEAR = "GenreAction.REQUEST_GENRE_CLEAR";
  static REQUEST_GENRE_CLEAR_FINISHED =
    "GenreAction.REQUEST_GENRE_CLEAR_FINISHED";

  static REQUEST_GENRE_FILTER_CLEAR = "GenreAction.REQUEST_GENRE_FILTER_CLEAR";
  static REQUEST_GENRE_FILTER_CLEAR_FINISHED =
    "GenreAction.REQUEST_GENRE_FILTER_CLEAR_FINISHED";

  // METHODS
  // Organization GET function
  static requestGenre(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GenreAction.REQUEST_GENRE,
        GenreEffect.requestGenre,
        params
      );
    };
  }

  static requestGenreClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GenreAction.REQUEST_GENRE_CLEAR,
        GenreEffect.requestGenreClear
      );
    };
  }

  static requestGenreFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GenreAction.REQUEST_GENRE_FILTER_CLEAR,
        GenreEffect.requestGenreFilterClear
      );
    };
  }

  static requestGenreFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GenreAction.REQUEST_GENRE_FILTER,
        GenreEffect.requestGenreFilter,
        params
      );
    };
  }
  static requestPutGenre(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GenreAction.REQUEST_PUT_GENRE,
        GenreEffect.requestPutGenre,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostGenre(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GenreAction.REQUEST_POST_GENRE,
        GenreEffect.requestPostGenre,
        data
      );
    };
  }

  static requestPutGenreBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GenreAction.REQUEST_PUT_GENRE_BULKUPDATE,
        GenreEffect.requestPutGenreBulkUpdate,
        data
      );
    };
  }
}
