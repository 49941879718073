import {
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
  Paper,
  rgbToHex,
  Popover,
  Slide,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import "./dashboard.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { format } from "date-fns";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import DashboardAction from "../../stores/dashboard/DashboardAction";
import PriorityAction from "../../stores/priority/PriorityAction";
import StatusAction from "../../stores/status/StatusAction";
import UserAction from "../../stores/user/UserAction";
import TicketCountAction from "../../stores/ticketCount/TicketCountAction";
import TicketWorkedOnAction from "../../stores/ticketWorkedOn/TicketWorkedOnAction";
import ResolvedTicketAction from "../../stores/resolvedTicket/ResolvedTicketAction";
import TicketFirstResponseTimeAction from "../../stores/firstResponseTime/TicketFirstResponseTimeAction";
import TurnAroundTimeAction from "../../stores/turnAroundTime/TurnAroundTimeAction";
import { FullName } from "../../../components/ReusedFunctions";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import {
  prefixZero_for_singleDigit,
  returnAvailability,
  returnColor,
  returnLogo,
  returnPlatformDropdownLogos,
} from "../../../components/ticketPartials";
import MuiButton from "../../../components/muiButton/MuiButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    DashboardAction.REQUEST_DASHBOARD,
    PriorityAction.REQUEST_PRIORITY_FILTER,
    StatusAction.REQUEST_DASHBOARD_STATUS_FILTER,
    UserAction.REQUEST_USER_FILTER,
    TicketCountAction.REQUEST_DASHBOARD_TICKET_COUNT,
    TicketWorkedOnAction.REQUEST_TICKETWORKEDON,
    ResolvedTicketAction.REQUEST_RESOLVEDTICKET,
    TicketFirstResponseTimeAction.REQUEST_TICKETFIRSTRESPONSETIME,
  ]),
  dashboard: state.dashboard.dashboard || [],
  filterPriority: state.filterpriority.filterpriority || [],
  dashboardStatusFilter:
    state.dashboardStatusFilter.dashboardStatusFilter || [],
  userfilter: state.userfilter.userfilter || {},
  dashboardTicketCount: state.dashboardTicketCount.dashboardTicketCount || [],
  ticketWorkedOn: state.ticketWorkedOn.ticketWorkedOn || [],
  ticketResolved: state.ticketResolved.ticketResolved || [],
  ticketFirstResponseTime:
    state.ticketFirstResponseTime.ticketFirstResponseTime || [],
  ticketAvgTat: state.ticketAvgTat.ticketAvgTat || [],
});

function TicketSummaryNew(props) {
  const navigate = useNavigate();
  const [priority, setPriority] = useState(
    localStorage.getItem("dashboard_filter_priority")
      ? JSON.parse(localStorage.getItem("dashboard_filter_priority"))
      : null
  );

  const [assigned_to, setAssigned_to] = useState(
    localStorage.getItem("dashboard_filter_assigned_to")
      ? JSON.parse(localStorage.getItem("dashboard_filter_assigned_to"))
      : null
  );

  const [ticketsAllSources, setTicketAllSources] = useState(
    localStorage.getItem("dashboard_platform_filter")
      ? JSON.parse(localStorage.getItem("dashboard_platform_filter"))
      : null
  );

  const [mentionsActivity, setMentionsActivity] = useState(
    localStorage.getItem("dashboard_activities_filter")
      ? JSON.parse(localStorage.getItem("dashboard_activities_filter"))
      : null
  );

  const [timeFilter, setTimeFilter] = useState(
    localStorage.getItem("dashboard_time_filter")
      ? localStorage.getItem("dashboard_time_filter")
      : null
  );

  // const [myTicketsTime, setMyTicketTime] = useState(
  //   localStorage.getItem("my_tickets_date_filter")
  //     ? JSON.parse(localStorage.getItem("my_tickets_date_filter"))
  //     : null
  // );

  let filters = useRef();

  //fetching priority list for priority dropdown
  function fetchFilterPriority() {
    const params = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    props.dispatch(PriorityAction.filterRequestPriority(params));
  }

  // ticket dispatch function for filter
  function fetchUserFilter() {
    const param = {
      page_limit: "none",
    };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  //fetch filterTicketsCount
  function fetchFilterTicketCount(params) {
    props.dispatch(DashboardAction.requestDashboard(params));
  }

  function fetchTicketCountData(params) {
    props.dispatch(TicketCountAction.requestDashboardTicketCount(params));
  }

  // ticket dispatch function for filter
  function fetchStatusFilter() {
    let param = {
      page_limit: "none",
      order_by: `[["order_number","asc"]]`,
    };
    props.dispatch(StatusAction.requestdashboardStatusFilter(param));
  }

  //fetching ticket WorkedOn values
  function fetchTicketworkedOn(params) {
    props.dispatch(TicketWorkedOnAction.requestTicketWorkedOn(params));
  }

  //fetching ticket resolved values
  function fetchResolvedTicket(params) {
    props.dispatch(ResolvedTicketAction.requestResolvedTicket(params));
  }

  //fetching ticket first response time
  function fetchTicketFirstResponseTime(params) {
    props.dispatch(
      TicketFirstResponseTimeAction.requestTicketFirstResponseTime(params)
    );
  }

  function fetchTicketTAT(params) {
    props.dispatch(TurnAroundTimeAction.requestTurnAroundTime(params));
  }

  const auth = localStorage.getItem("Auth");

  const authData = jwtDecode(auth);

  localStorage.setItem("userId", authData.sub);
  localStorage.setItem("userName", authData.preferred_username);

  let AssiggnedToObject = [];
  AssiggnedToObject.push({
    user_name: authData.preferred_username,
    full_name: authData.given_name + " " + authData.family_name,
  });

  //useEffect function
  useEffect(() => {
    filters.current = [];

    fetchFilterPriority();
    fetchStatusFilter();
    fetchUserFilter();
    fetchDashboardData();
    // fetchTicketCountData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.DateFilter]);

  //PRIORITY list assigning
  let prioritylist = [];
  let filteredPriorityList = [];

  if (props.filterPriority.hits) {
    props.filterPriority.hits.hits.forEach((value) => {
      prioritylist.push({
        ticket_priority_name: value._source.ticket_priority_name,
        color: value._source.color,
        priority_id: value._id,
      });
    });
    filteredPriorityList = [...new Set(prioritylist)];
  }

  let statusList = [];
  if (props.dashboardStatusFilter.hits) {
    props.dashboardStatusFilter.hits.hits.forEach((item) => {
      statusList.push({
        status_id: item._id,
        status_name: item._source.status_name,
      });
    });
  }

  const returnStatusName = (statusId) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusId;
      });

    return filtered_status.length > 0 ? filtered_status[0].status_name : null;
  };

  const returnStatusObject = (statusId) => {
    let filtered_status =
      statusList.length > 0 &&
      statusList.filter((val) => {
        return val.status_id === statusId;
      });

    return filtered_status.length > 0 && filtered_status[0];
  };

  let userlist = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((val) => {
      return userlist.push({
        user_id: val.id,
        user_name: val.username,
        // org_id: val.attributes.organization_id
        //   ? val.attributes.organization_id[0]
        //   : "",
        full_name: FullName(val),
        statusValue:
          val.attributes.user_working_status &&
          val.attributes.user_working_status[0],
      });
    });
  }

  userlist.sort((a, b) => a?.full_name.localeCompare(b.full_name));
  userlist = [...new Set(userlist)];

  // DATE value handle change
  const today = new Date();

  // ***************************************
  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);

  // start from today's date last 7 days
  const last7Days = [...Array(7).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date;
  });

  // last 30 days
  let date2 = new Date();
  let last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  // ***************************************
  // last 3 months
  let date3 = new Date();
  let last3Month = date3.setMonth(date3.getMonth() - 3);

  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  //ACTIVITIES list assigning

  let MentionsActivitiesList = [];
  let filteredMentionsActivitiesList = [];

  const activitiesData = JSON.parse(localStorage.getItem("activities"));

  if (activitiesData && ticketsAllSources) {
    activitiesData.hits.hits.map((item) => {
      if (
        item._source.platform.platform_name === ticketsAllSources.platform_name
      ) {
        MentionsActivitiesList.push({
          activity_id: item._id,
          activity_name: item._source.activities,
        });
      }
    });
  }

  filteredMentionsActivitiesList = [...new Set(MentionsActivitiesList)];

  filteredMentionsActivitiesList.sort((a, b) =>
    a?.activity_name.localeCompare(b.activity_name)
  );
  filteredMentionsActivitiesList = [...new Set(filteredMentionsActivitiesList)];

  let uniqueList = [];

  props?.platformList?.sort((a, b) =>
    a?.platform_alias.localeCompare(b.platform_alias)
  );
  uniqueList = [...new Set(props.platformList)];

  //Dashboard data fetch
  function fetchDashboardData() {
    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }
    if (mentionsActivity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${mentionsActivity.activity_id}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    // if (props.DateFilter) {
    //   let dateRange = props.DateFilter;

    //   const dateString = dateRange[1];
    //   const date = new Date(dateString);
    //   date.setDate(date.getDate() - 1);

    //   filters.current.push(
    //     `["created_on","must","lte","range","${
    //       localStorage.getItem("my_tickets_date_filter") &&
    //       JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
    //         "Custom"
    //         ? dateRange[1]
    //         : format(date, "yyyy-MM-dd")
    //     }"],["created_on","must","gte","range","${dateRange[0]}"]`
    //   );
    // }

    if (props.DateFilter) {
      let dateRange = props.DateFilter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    if (
      priority !== null ||
      ticketsAllSources !== null ||
      mentionsActivity !== null ||
      assigned_to !== null ||
      props.DateFilter
    ) {
      let dashboard_main_filter = [];
      let workedupon_main_filter = [];
      let ticket_Resolved_main_filters = [];
      let ticket_first_response_time_main_filters = [];
      let ticket_avg_tat_main_filters = [];
      let ticket_count_main_filters = [];
      let agent_ticket_status_main_filters = [];

      let main_filter = [];

      if (filters.current.length !== 0) {
        dashboard_main_filter.push(filters.current);
        workedupon_main_filter.push(filters.current);
        ticket_Resolved_main_filters.push(filters.current);
        ticket_first_response_time_main_filters.push(filters.current);
        ticket_avg_tat_main_filters.push(filters.current);
        ticket_count_main_filters.push(filters.current);
        agent_ticket_status_main_filters.push(filters.current);
      }

      if (props.DateFilter !== null) {
        dashboard_main_filter.push(dashboard_filters);
        workedupon_main_filter.push(workedupon_filters);
        ticket_Resolved_main_filters.push(ticket_Resolved_filters);
        ticket_first_response_time_main_filters.push(
          ticket_first_response_time_filters
        );
        ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
        ticket_count_main_filters.push(ticket_count_filters);
        agent_ticket_status_main_filters.push(agent_ticket_status);
      }

      params = { filters: `[${filters.current}]` };
      param = {
        filters: `[${main_filter}]`,
      };

      dashboard_param = {
        filters: `[${dashboard_main_filter}]`,
      };

      worked_upon_param = {
        filters: `[${workedupon_main_filter}]`,
      };

      ticket_Resolved_param = {
        filters: `[${ticket_Resolved_main_filters}]`,
      };

      ticket_first_response_time_param = {
        filters: `[${ticket_first_response_time_main_filters}]`,
      };

      ticket_avg_tat_param = {
        filters: `[${ticket_avg_tat_main_filters}]`,
      };

      ticket_count_param = {
        filters: `[${ticket_count_main_filters}]`,
      };

      Object.assign(
        worked_upon_param,
        props.DateFilter && {
          from: props.DateFilter[0],
          to: props.DateFilter[1],
        }
      );

      Object.assign(
        dashboard_param,
        props.DateFilter && {
          from: props.DateFilter[0],
          to: props.DateFilter[1],
        }
      );

      Object.assign(
        ticket_first_response_time_param,
        props.DateFilter && {
          from: props.DateFilter[0],
          to: props.DateFilter[1],
        }
      );

      Object.assign(
        ticket_avg_tat_param,
        props.DateFilter && {
          from: props.DateFilter[0],
          to: props.DateFilter[1],
        }
      );

      Object.assign(
        ticket_Resolved_param,
        props.DateFilter && {
          from: props.DateFilter[0],
          to: props.DateFilter[1],
        }
      );
    }

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  }

  // priority callback function
  const priorityhandleChange = (event, priority) => {
    setPriority(priority);
    if (priority != null) {
      localStorage.setItem(
        "dashboard_filter_priority",
        JSON.stringify(priority)
      );
    } else {
      localStorage.removeItem("dashboard_filter_priority");
    }
    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }
    if (mentionsActivity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${mentionsActivity.activity_id}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (props.DateFilter) {
      let dateRange = props.DateFilter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${filters.current}]`,
    };

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (props.DateFilter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      dashboard_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  // assigned to  function
  const assignedTohandleChange = (event, assigned_to) => {
    setAssigned_to(assigned_to);
    if (assigned_to != null) {
      localStorage.setItem(
        "dashboard_filter_assigned_to",
        JSON.stringify(assigned_to)
      );
    } else {
      localStorage.removeItem("dashboard_filter_assigned_to");
    }
    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (mentionsActivity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${mentionsActivity.activity_id}"]]`
      );
    }
    if (props.DateFilter) {
      let dateRange = props.DateFilter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (props.DateFilter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${filters.current}]`,
    };

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      dashboard_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  //tickets allSources callbackFunction
  const platformHandleChange = (event, platform) => {
    setTicketAllSources(platform);
    setMentionsActivity(null);

    if (platform != null) {
      localStorage.setItem(
        "dashboard_platform_filter",
        JSON.stringify(platform)
      );
    } else {
      localStorage.removeItem("dashboard_platform_filter");
      localStorage.removeItem("dashboard_activities_filter");
    }

    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (platform !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${platform.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }

    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (props.DateFilter) {
      let dateRange = props.DateFilter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (props.DateFilter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${main_filter}]`,
    };

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      dashboard_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  // Mentions activity callBack function
  const activityHandleChange = (event, activity) => {
    setMentionsActivity(activity);
    if (activity != null) {
      localStorage.setItem(
        "dashboard_activities_filter",
        JSON.stringify(activity)
      );
    } else {
      localStorage.removeItem("dashboard_activities_filter");
    }

    filters.current = [];
    let dashboard_filters = [];
    let workedupon_filters = [];
    let ticket_Resolved_filters = [];
    let ticket_first_response_time_filters = [];
    let ticket_avg_tat_filters = [];
    let ticket_count_filters = [];
    let agent_ticket_status = [];

    let params = {};
    let param = {};
    let dashboard_param = {};
    let worked_upon_param = {};
    let ticket_Resolved_param = {};
    let ticket_first_response_time_param = {};
    let ticket_avg_tat_param = {};
    let ticket_count_param = {};
    let agent_ticket_param = {};

    if (ticketsAllSources !== null) {
      filters.current.push(
        `["platform.keyword","must","","terms",["${ticketsAllSources.platform_id}"]]`
      );
    }
    if (priority !== null) {
      filters.current.push(
        `["priority.priority_name.keyword","must","","terms",["${priority.ticket_priority_name}"]]`
      );
    }
    if (activity !== null) {
      filters.current.push(
        `["activities.keyword","must","","terms",["${activity.activity_id}"]]`
      );
    }
    if (assigned_to !== null) {
      filters.current.push(
        `["assigned_to.user_name.keyword","must","","terms",["${assigned_to.user_name}"]]`
      );
    }

    if (props.DateFilter) {
      let dateRange = props.DateFilter;

      const dateString = dateRange[1];
      const date = new Date(dateString);
      date.setDate(date.getDate() - 1);

      dashboard_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_count_filters.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      agent_ticket_status.push(
        `["created_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["created_on","must","gte","range","${dateRange[0]}"]`
      );

      workedupon_filters.push(
        `["modified_on","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["modified_on","must","gte","range","${dateRange[0]}"]`
      );

      ticket_Resolved_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_avg_tat_filters.push(
        `["resolved_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["resolved_time","must","gte","range","${dateRange[0]}"]`
      );

      ticket_first_response_time_filters.push(
        `["first_response_message_date_time","must","lte","range","${
          localStorage.getItem("my_tickets_date_filter") &&
          JSON.parse(localStorage.getItem("my_tickets_date_filter")) ===
            "Custom"
            ? dateRange[1]
            : format(date, "yyyy-MM-dd")
        }"],["first_response_message_date_time","must","gte","range","${
          dateRange[0]
        }"]`
      );
    }

    let dashboard_main_filter = [];
    let workedupon_main_filter = [];
    let ticket_Resolved_main_filters = [];
    let ticket_first_response_time_main_filters = [];
    let ticket_avg_tat_main_filters = [];
    let ticket_count_main_filters = [];
    let agent_ticket_status_main_filters = [];

    let main_filter = [];

    if (filters.current.length !== 0) {
      dashboard_main_filter.push(filters.current);
      workedupon_main_filter.push(filters.current);
      ticket_Resolved_main_filters.push(filters.current);
      ticket_first_response_time_main_filters.push(filters.current);
      ticket_avg_tat_main_filters.push(filters.current);
      ticket_count_main_filters.push(filters.current);
      agent_ticket_status_main_filters.push(filters.current);
    }

    if (props.DateFilter !== null) {
      dashboard_main_filter.push(dashboard_filters);
      workedupon_main_filter.push(workedupon_filters);
      ticket_Resolved_main_filters.push(ticket_Resolved_filters);
      ticket_first_response_time_main_filters.push(
        ticket_first_response_time_filters
      );
      ticket_avg_tat_main_filters.push(ticket_avg_tat_filters);
      ticket_count_main_filters.push(ticket_count_filters);
      agent_ticket_status_main_filters.push(agent_ticket_status);
    }

    params = { filters: `[${filters.current}]` };
    param = {
      filters: `[${main_filter}]`,
    };

    dashboard_param = {
      filters: `[${dashboard_main_filter}]`,
    };

    worked_upon_param = {
      filters: `[${workedupon_main_filter}]`,
    };

    ticket_Resolved_param = {
      filters: `[${ticket_Resolved_main_filters}]`,
    };

    ticket_first_response_time_param = {
      filters: `[${ticket_first_response_time_main_filters}]`,
    };

    ticket_avg_tat_param = {
      filters: `[${ticket_avg_tat_main_filters}]`,
    };

    ticket_count_param = {
      filters: `[${ticket_count_main_filters}]`,
    };

    Object.assign(
      worked_upon_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      dashboard_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_first_response_time_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_avg_tat_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    Object.assign(
      ticket_Resolved_param,
      props.DateFilter && {
        from: props.DateFilter[0],
        to: props.DateFilter[1],
      }
    );

    // fetchFilterTicketCount(dashboard_param);
    fetchTicketCountData(ticket_count_param);

    fetchTicketworkedOn(worked_upon_param);
    fetchTicketFirstResponseTime(ticket_first_response_time_param);
    fetchTicketTAT(ticket_avg_tat_param);
    fetchResolvedTicket(ticket_Resolved_param);
  };

  const theme = useTheme();

  const dashboardDataPerRow = 5;

  const [dashboardData, setdashboardDataPerRow] = useState(dashboardDataPerRow);

  const handleDashboardDataPerRow = () => {
    setdashboardDataPerRow(dashboardData + dashboardDataPerRow);
  };

  const handleDashboardDataShowLess = () => {
    setdashboardDataPerRow(dashboardData - dashboardDataPerRow);
  };

  let dasboard_modified_data = [];

  if (props.dashboardStatusFilter.hits && props.dashboardTicketCount.data) {
    let filtered_data = props.dashboardStatusFilter.hits.hits.forEach(
      (item) => {
        props.dashboardTicketCount.data.filter((val) => {
          if (val.key === item._id) {
            dasboard_modified_data.push(val);
          }
        });
      }
    );
  }

  // Unassigned Object appent to Ticket Status list
  props.dashboardTicketCount &&
    props.dashboardTicketCount.unassigned &&
    dasboard_modified_data.push({
      count: props.dashboardTicketCount.unassigned,
      key: "Unassigned",
      priority: [],
    });

  props.dashboardTicketCount &&
    props.dashboardTicketCount.data &&
    props.dashboardTicketCount.data
      .filter((val) => {
        return val.key === "direct_close";
      })
      .map((item) => {
        dasboard_modified_data.push({
          count: item?.count,
          key: "Ignored",
          priority: [],
        });
      });

  const returnTime = (time) => {
    // function getFullTimeStringFromSeconds(input_seconds) {
    //   const date = new Date(input_seconds * 1000); // Convert seconds to milliseconds
    //   const hours = date.getUTCHours().toString().padStart(2, "0");
    //   const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    //   const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    //   return `${hours}:${minutes}:${seconds}`;
    // }

    function getFullTimeStringFromHours(hours) {
      const totalHours = Math.floor(hours);
      const minutes = Math.floor((hours - totalHours) * 60);
      const seconds = Math.floor(((hours - totalHours) * 60 - minutes) * 60);
      const formattedTime = `${totalHours}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return formattedTime;
    }

    return (
      <p style={{ fontSize: "42px" }}>{getFullTimeStringFromHours(time)}</p>
    );
  };

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      setOpen(false);
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const returnOnholdTicketCount = (response) => {
    let over_all_count = 0;

    if (
      response &&
      response.data &&
      response.data.ticket_onhold_count &&
      response.data.ticket_onhold_count.buckets.length !== 0
    ) {
      response.data.ticket_onhold_count.buckets.map((val) => {
        over_all_count = over_all_count + val.doc_count;
      });
    }

    return prefixZero_for_singleDigit(over_all_count);
  };

  const returnstatuswithticketcounts = (response) => {
    return (
      <Grid>
        {response &&
          response.data &&
          response.data.ticket_onhold_count &&
          response.data.ticket_onhold_count.buckets.map((val) => {
            return (
              <p className="p-0 m-0">
                {returnStatusName(val.key)} :{" "}
                {prefixZero_for_singleDigit(val.doc_count)}
              </p>
            );
          })}
      </Grid>
    );
  };

  const [showFilters, setShowFilters] = useState(
    priority || assigned_to || ticketsAllSources || mentionsActivity
      ? true
      : false
  );

  const summaryBoxRef = useRef(null);

  useEffect(() => {
    const calculateHeights = () => {
      const outerBoxHeight = summaryBoxRef.current.clientHeight;
      const firstBoxHeight = 0.1 * outerBoxHeight;
      const secondBoxHeight = 0.9 * outerBoxHeight;

      if (
        summaryBoxRef.current &&
        summaryBoxRef.current.children[1] &&
        summaryBoxRef.current.children[0]
      ) {
        summaryBoxRef.current.children[0].style.height = `50px`;
        summaryBoxRef.current.children[1].style.height = `${
          outerBoxHeight - 50
        }px`;
      }
    };

    calculateHeights();

    // Use ResizeObserver to listen for changes in the size of summaryBoxRef
    const resizeObserver = new ResizeObserver(calculateHeights);
    resizeObserver.observe(summaryBoxRef.current);

    // Cleanup ResizeObserver on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array to ensure the effect runs only once on mount
  const [renderKey, setRenderKey] = useState(0);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <LoadingIndicator isActive={props.isRequesting}>
        <Box
          textAlign={"center"}
          ref={summaryBoxRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "12",
          }}
        >
          <Box
            style={{
              // flex: "1",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              item
              xl={12}
              lg={12}
              laptop={12}
              md={12}
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Grid
                item
                lg={2}
                laptop={2}
                md={12}
                sm={12}
                textAlign="left"
                alignSelf="center"
                pl={2}
              >
                <p style={{ fontSize: "28px", margin: 0, padding: 0 }}>
                  <span className="firstLetter">T</span>icket&nbsp;
                  <span className="firstLetter">S</span>ummary
                </p>
              </Grid>

              {!showFilters && (
                <Grid
                  container
                  item
                  lg={!showFilters ? 10 : null}
                  sm={!showFilters ? 12 : null}
                  laptop={!showFilters ? 8 : null}
                  md={!showFilters ? 12 : null}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Grid item xl={0.5} lg={1} md={2} sm={3} p={1}>
                    <Box
                      sx={{
                        //display: "inline-block",
                        marginTop: "2px",
                        padding: "4px",
                        // border: `1px solid ${theme.palette.common.grey}`,
                        borderRadius: "4px",
                      }}
                    >
                      <Tooltip title="Apply Filters" arrow placement="left">
                        <FilterAltIcon
                          onClick={() => {
                            setShowFilters(true);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              )}

              {showFilters && (
                <Grid
                  container
                  item
                  lg={showFilters ? 10 : null}
                  sm={showFilters ? 12 : null}
                  laptop={showFilters ? 8 : null}
                  md={showFilters ? 12 : null}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Slide
                    direction="left"
                    in={showFilters}
                    mountOnEnter
                    unmountOnExit
                    style={{
                      width: "100%",
                    }}
                  >
                    <Paper>
                      <Grid
                        container
                        item
                        lg={12}
                        sm={12}
                        laptop={8}
                        md={12}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems={"center"}
                      >
                        <Grid
                          item
                          xl={0.5}
                          lg={1}
                          md={1}
                          sm={2}
                          p={1}
                          // className="bg-success"
                        >
                          <IconButton
                            onClick={() => {
                              setShowFilters(false);
                            }}
                            title="Close Filters"
                            style={{
                              minHeight: 0,
                              padding: 0,
                              color: `${theme.palette.ticketListView.headerIcon}`,
                            }}
                          >
                            <KeyboardDoubleArrowRight
                              style={{
                                width: "16px",
                                color: `${theme.palette.ticketListView.headerIcon}`,
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                          <AutoCompleteComponent
                            list={uniqueList}
                            getOptionLabel={(option) =>
                              option.platform_alias ? option.platform_alias : ""
                            }
                            disablePortal
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <Box width={20} ml={"-10px"} mr={"25px"}>
                                  {returnPlatformDropdownLogos(
                                    option.platform_name
                                  )}
                                </Box>
                                {option.platform_name === "Youtube" ? (
                                  <span className="mx-4">
                                    {option.platform_alias}
                                  </span>
                                ) : (
                                  <span>{option.platform_alias}</span>
                                )}
                              </Box>
                            )}
                            value={ticketsAllSources}
                            handleChange={platformHandleChange}
                            textFieldProps={{
                              placeholder: "Platforms",
                            }}
                            size="small"
                            width="100%"
                          />
                        </Grid>
                        <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                          <AutoCompleteComponent
                            disablePortal
                            disabled={!ticketsAllSources}
                            value={mentionsActivity}
                            getOptionLabel={(option) =>
                              option.activity_name ? option.activity_name : ""
                            }
                            list={filteredMentionsActivitiesList}
                            handleChange={activityHandleChange}
                            textFieldProps={{
                              placeholder: "Activities",
                            }}
                            size="small"
                            width="100%"
                          />
                        </Grid>
                        <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                          <AutoCompleteComponent
                            disablePortal
                            list={
                              filteredPriorityList ? filteredPriorityList : []
                            }
                            getOptionLabel={(option) =>
                              option.ticket_priority_name
                                ? option.ticket_priority_name
                                : ""
                            }
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <Box
                                  mr={1}
                                  width={10}
                                  height={10}
                                  borderRadius="50%"
                                >
                                  {returnColor(option.color)}
                                </Box>
                                {option.ticket_priority_name}
                              </Box>
                            )}
                            value={priority}
                            handleChange={priorityhandleChange}
                            textFieldProps={{
                              placeholder: "Priority",
                            }}
                            size="small"
                            width="100%"
                          />
                        </Grid>

                        {props.ticketResolved &&
                          props.ticketResolved.agent === false && (
                            <Grid item xl={1.6} lg={2} md={3} sm={4} p={1}>
                              <AutoCompleteComponent
                                disablePortal
                                list={userlist ? userlist : []}
                                value={assigned_to}
                                textFieldProps={{
                                  placeholder: "Assigned To",
                                }}
                                lastComponent
                                isOptionEqualToValue={(option, value) =>
                                  //setting value property to custom value
                                  option.full_name === value.full_name
                                }
                                getOptionLabel={(option) =>
                                  option.full_name ? option.full_name : ""
                                }
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    <Box
                                      mr={1}
                                      width={10}
                                      height={10}
                                      borderRadius="50%"
                                    >
                                      {returnAvailability(option.statusValue)}
                                    </Box>
                                    {option.full_name}
                                  </Box>
                                )}
                                handleChange={assignedTohandleChange}
                                size="small"
                                width="100%"
                                height="50%"
                              />
                            </Grid>
                          )}
                      </Grid>
                    </Paper>
                  </Slide>
                </Grid>
              )}
            </Grid>
            <hr style={{ width: "100%", margin: 0 }} />
          </Box>

          <Box
            className="scrollable "
            sx={{
              // flex: "11",
              // display: "flex",
              // flexDirection: "column",
              height: "100%",
              textAlign: "center",
              overflowY: "scroll",
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  width: "3px",
                  height: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#b0d2da",
                  borderRadius: "3px",
                  "&:hover": {
                    backgroundColor: "#85bac6",
                  },
                },
              },
            }}
          >
            <Grid
              container
              gap={1}
              sx={{ padding: 2 }}
              // className="bg-warning"
              justifyContent={"space-between"}
              alignItems="center"
            >
              {props.dashboardStatusFilter?.hits &&
              dasboard_modified_data?.length > 0 ? (
                dasboard_modified_data.slice(0, dashboardData).map((item) => (
                  <Grid
                    container
                    item
                    sx={{
                      width: 150,
                      height: 100,
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "9px",
                      border: `solid 2px #e0f7fa`,
                      boxShadow: `inset 0 -17px 66px 0 #f3f9fb`,
                    }}
                    // onClick={() => {
                    //   navigate("/detailed_report");
                    // }}
                  >
                    <Grid>
                      <Typography fontSize={20}>
                        {returnStatusName(item.key)
                          ? returnStatusName(item.key)
                          : item.key}
                      </Typography>
                      <Typography fontSize={35}>{item.count}</Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid xs={12}>
                  <Typography
                    component={"h6"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: 24,
                    }}
                  >
                    No Tickets Assigned
                  </Typography>
                </Grid>
              )}
            </Grid>

            <hr className="p-0 m-0" />

            <Grid
              container
              gap={1}
              sx={{ padding: 2 }}
              justifyContent={"space-between"}
              alignItems="center"
              // className="bg-warning"
              // height="100%"
            >
              <Grid
                container
                item
                sx={{
                  width: 150,
                  height: 100,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "9px",
                  border: `solid 2px #e0f7fa`,
                  boxShadow: `inset 0 -17px 66px 0 #f3f9fb`,
                }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <Grid>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography
                      sx={{ p: 1 }}
                      // className="bg-dark"
                    >
                      <Box>
                        <Grid
                          item
                          container
                          // className="bg-dark"
                          sx={{
                            color: "white",
                          }}
                          maxWidth={200}
                          justifyContent="flex-start"
                          alignItems={"center"}
                        >
                          {props.ticketWorkedOn &&
                          props.ticketWorkedOn.data &&
                          props.ticketWorkedOn.data.ticket_onhold_count &&
                          props.ticketWorkedOn.data.ticket_onhold_count
                            .buckets &&
                          props.ticketWorkedOn.data.ticket_onhold_count.buckets
                            .length
                            ? returnstatuswithticketcounts(props.ticketWorkedOn)
                            : null}
                        </Grid>
                      </Box>
                    </Typography>
                  </Popover>

                  <Typography fontSize={20}>On Hold</Typography>

                  <Typography fontSize={35}>
                    {returnOnholdTicketCount(props.ticketWorkedOn)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                sx={{
                  width: 150,
                  height: 100,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "9px",
                  border: `solid 2px #e0f7fa`,
                  boxShadow: `inset 0 -17px 66px 0 #f3f9fb`,
                }}
              >
                <Grid>
                  <Typography fontSize={20}>Resolved</Typography>

                  <Typography fontSize={35}>
                    {props.ticketResolved &&
                    props.ticketResolved.data &&
                    props.ticketResolved.data.ticket_resolved_count &&
                    props.ticketResolved.data.ticket_resolved_count.buckets &&
                    props.ticketResolved.data.ticket_resolved_count.buckets
                      .length !== 0
                      ? props.ticketResolved.data.ticket_resolved_count
                          .buckets[0].doc_count
                      : 0}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                sx={{
                  width: "auto",
                  height: 100,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "9px",
                  border: `solid 2px #e0f7fa`,
                  boxShadow: `inset 0 -17px 66px 0 #f3f9fb`,
                  paddingX: 2,
                }}
              >
                <Grid>
                  <Typography fontSize={20}>First Response Time</Typography>

                  <Typography fontSize={35} fontWeight="bold">
                    {props.ticketFirstResponseTime.ticket_first_response_time
                      ? props.ticketFirstResponseTime.ticket_first_response_time
                          .value !== null
                        ? returnTime(
                            props.ticketFirstResponseTime
                              .ticket_first_response_time.value
                          )
                        : "00:00"
                      : "00:00"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                sx={{
                  width: "auto",
                  height: 100,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "9px",
                  border: `solid 2px #e0f7fa`,
                  boxShadow: `inset 0 -17px 66px 0 #f3f9fb`,
                  paddingX: 2,
                }}
              >
                <Grid>
                  <Typography fontSize={20}>Turn Around Time</Typography>

                  <Typography fontSize={35} fontWeight="bold">
                    {props.ticketAvgTat.ticket_tat
                      ? props.ticketAvgTat.ticket_tat.value !== null
                        ? returnTime(props.ticketAvgTat.ticket_tat.value)
                        : "00:00"
                      : "00:00"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </LoadingIndicator>
    </Box>
  );
}

export default connect(mapStateToProps)(TicketSummaryNew);
