import { BaseModel } from "sjs-base-model";
import EmailFeedsDataModel from "./EmailFeedsDataModel";

// twitter api fetch model
export default class EmailFeedsDataHitsModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [EmailFeedsDataModel];

  constructor(data) {
    super();

    this.update(data);
  }
}
