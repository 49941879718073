import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../components/reportPdfTemplates/logoContainer/Logo";
import MuiButton from "../../../components/muiButton/MuiButton";
import { theme } from "../../../views/App";
import "./Reports.css";
import { ReturnReport } from "../../../components/ticketPartials";

function DownloadReport(props) {
  let ReportTemplates =
    props.reportTemplates &&
    props.reportTemplates.hits &&
    props.reportTemplates.hits.hits.length > 0
      ? props.reportTemplates.hits.hits
      : [];

  const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let [pageWidth, setPageWidth] = useState(210);
  let [pageHeight, setPageHeight] = useState(297);
  let [logoHeight, setLogoHeight] = useState(40);
  let [reportHeight, setReportHeight] = useState(257);

  const returnLogoUrl = (id) => {
    let filtered_logo_array = props.logoList.filter((val) => {
      return val.logo_id === id;
    });

    return filtered_logo_array && filtered_logo_array.length !== 0
      ? filtered_logo_array[0].logo_url
      : "";
  };

  const SingleBoxLayout = (page) => {
    return (
      <>
        <Grid
          item
          container
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems="center"
          // className="bg-warning"
          sx={{
            width: `${pageWidth}mm`,
            height: `${pageHeight}mm`,

            backgroundColor: "white",
            margin: "auto",
            border:
              page.reports && page.reports[0].report_name
                ? "1px solid gray"
                : `1px dotted gray`,
            padding: page.reports && page.reports[0].report_name ? 0 : 5,
            boxSizing: "border-box",
            marginBottom: 3,
          }}
        >
          <Grid
            item
            sx={{
              width: "inherit",
              height: `${logoHeight}mm`,
              maxWidth: `${pageWidth}mm`,
              maxHeight: `${logoHeight}mm`,
            }}
            // className="bg-success"
          >
            <Logo
              leftLogo={returnLogoUrl(page.left_top_logo)}
              rightLogo={returnLogoUrl(page.right_top_logo)}
            />
          </Grid>

          <Grid
            item
            // xs={12}
            sx={{
              width: "inherit",
              height: `${reportHeight}mm`,
            }}
          >
            {page.reports &&
              page.reports[0].report_id &&
              ReturnReport(
                page.reports[0],
                props.platform_list,
                props.descriptionCallback,
                props.activity_list,
                props.statusList,
                props.userList
              )}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowOneColumnLayout = (page) => {
    return (
      <>
        <Grid
          item
          container
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems="center"
          // className="bg-warning"
          sx={{
            width: `${pageWidth}mm`,
            height: `${pageHeight}mm`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            border:
              page.reports && page.reports[0].report_name
                ? "1px solid gray"
                : `1px dotted gray`,
            margin: "auto",
            marginBottom: 3,
          }}
        >
          <Grid
            item
            // className="bg-success"
            sx={{
              width: "inherit",
              height: `${logoHeight}mm`,
              maxWidth: `${pageWidth}mm`,
              maxHeight: `${logoHeight}mm`,
            }}
          >
            <Logo
              leftLogo={returnLogoUrl(page.left_top_logo)}
              rightLogo={returnLogoUrl(page.right_top_logo)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              width: "inherit",
              height: `${reportHeight / 2}mm`,
              maxWidth: `${pageWidth}mm`,
              maxHeight: `${reportHeight / 2}mm`,
              overflow: "hidden",
              border:
                page.reports && page.reports[0].report_name
                  ? "none"
                  : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {page.reports &&
              page.reports[0] &&
              ReturnReport(
                page.reports[0],
                props.platform_list,
                props.descriptionCallback,
                props.activity_list,
                props.statusList,
                props.userList
              )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              width: "inherit",
              height: `${reportHeight / 2}mm`,
              maxWidth: `${pageWidth}mm`,
              maxHeight: `${reportHeight / 2}mm`,
              overflow: "hidden",
              border:
                page.reports && page.reports[0].report_name
                  ? "none"
                  : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {page.reports &&
              page.reports[1] &&
              ReturnReport(
                page.reports[1],
                props.platform_list,
                props.descriptionCallback,
                props.activity_list,
                props.statusList,

                props.userList
              )}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const OneRowTwoColumnLayout = (page) => {
    return (
      <>
        <Grid
          item
          container
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems="center"
          // className="bg-warning"
          sx={{
            width: `${pageWidth}mm`,
            height: `${pageHeight}mm`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            border:
              page.reports && page.reports[0].report_name
                ? "1px solid gray"
                : `1px dotted gray`,
            margin: "auto",
            marginBottom: 3,
          }}
        >
          <Grid
            item
            sx={{
              width: "inherit",
              height: `${logoHeight}mm`,
              maxWidth: `${pageWidth}mm`,
              maxHeight: `${logoHeight}mm`,
            }}
            // className="bg-success"
          >
            <Logo
              leftLogo={returnLogoUrl(page.left_top_logo)}
              rightLogo={returnLogoUrl(page.right_top_logo)}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              width: `${pageWidth / 2}mm`,
              height: "inherit",
              maxWidth: `${pageWidth / 2}mm`,
              overflow: "hidden",
              border:
                page.reports && page.reports[0].report_name
                  ? "none"
                  : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {page.reports &&
              page.reports[0] &&
              ReturnReport(
                page.reports[0],
                props.platform_list,
                props.descriptionCallback,
                props.activity_list,
                props.statusList,
                props.userList
              )}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              width: `${pageWidth / 2}mm`,
              maxWidth: `${pageWidth / 2}mm`,
              overflow: "hidden",
              height: "inherit",
              border:
                page.reports && page.reports[0].report_name
                  ? "none"
                  : `1px dotted gray`,
              padding: page.reports && page.reports.length ? 0 : 5,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {page.reports &&
              page.reports[1] &&
              ReturnReport(
                page.reports[1],
                props.platform_list,
                props.descriptionCallback,
                props.activity_list,
                props.statusList,
                props.userList
              )}
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowTowColumnLayout = (page) => {
    return (
      <>
        <Grid
          item
          container
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems="center"
          sx={{
            width: `${pageWidth}mm`,
            height: `${pageHeight}mm`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            margin: "auto",
            marginBottom: 3,
            border:
              page.reports && page.reports[0].report_name
                ? "1px solid gray"
                : `1px dotted gray`,
          }}
        >
          <Grid
            item
            sx={{
              width: "inherit",
              height: `${logoHeight}mm`,
              maxWidth: `${pageWidth}mm`,
              maxHeight: `${logoHeight}mm`,
            }}
          >
            <Logo
              leftLogo={returnLogoUrl(page.left_top_logo)}
              rightLogo={returnLogoUrl(page.right_top_logo)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              width: "inherit",
              height: `${reportHeight / 2}mm`,
            }}
            spacing={1}
          >
            <Grid container sx={{ height: "inherit" }}>
              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                  page.reports[0] &&
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                  page.reports[1] &&
                  ReturnReport(
                    page.reports[1],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            spacing={1}
            sx={{
              width: "inherit",
              height: `${reportHeight / 2}mm`,
            }}
          >
            <Grid container sx={{ height: "inherit" }}>
              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                  page.reports[2] &&
                  ReturnReport(
                    page.reports[2],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                  page.reports[3] &&
                  ReturnReport(
                    page.reports[3],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="pagebreak" />
      </>
    );
  };

  const SingleLeftDividedRight_2 = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
            // className="bg-warning"
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
              // className="bg-warning"
            >
              <Grid
                item
                xl={6}
                // className="bg-success"
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight}mm`,
                  height: `${reportHeight}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                  page.reports[0] &&
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>
              <Grid
                item
                container
                xl={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                    page.reports[1] &&
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[2].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                    page.reports[2] &&
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const DividedLeft_2_SingleRight = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
            // className="bg-warning"
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
              // className="bg-warning"
            >
              <Grid
                item
                container
                xl={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                    page.reports[1] &&
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                    page.reports[1] &&
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )}
                </Grid>
              </Grid>

              <Grid
                item
                xl={6}
                // className="bg-success"
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight}mm`,
                  height: `${reportHeight}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[2].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                  page.reports[2] &&
                  ReturnReport(
                    page.reports[2],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )}
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  return (
    <Box>
      <Grid
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "space-evenly" }}
      >
        {" "}
        <Grid
          container
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "white",
            padding: 2,
          }}
        >
          <Box width={"100%"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                component={"p"}
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                Report Templates
              </Typography>

              <MuiButton
                name={"Save as PDF"}
                onClick={() => {
                  handlePrint();
                }}
                disabled={selectedReportTemplate !== null ? false : true}
              />
            </Box>

            <Box
              sx={{
                height: 700,
                overflowY: "auto",
                marginTop: 2,
              }}
              className="scrollable"
            >
              {ReportTemplates.length > 0 &&
                ReportTemplates.map((template, index) => {
                  return (
                    <Grid item lg={12} md={12} sx={{ paddingY: "3px" }}>
                      <Accordion
                        expanded={
                          selectedReportTemplate &&
                          selectedReportTemplate.template_id === template._id
                        }
                        style={{
                          backgroundColor: theme.palette.background.default,
                          borderRadius: theme.borderRadius,
                          border: "solid 0.5px #d3e2e6",
                          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                          width: "100%",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor: "transparent",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={template}
                                label={template}
                                onChange={(e, val) => {
                                  if (val) {
                                    setSelectedReportTemplate({
                                      template_id: template._id,
                                      template_name:
                                        template._source.report_template_name,
                                    });
                                  } else {
                                    setSelectedReportTemplate(null);
                                  }
                                }}
                                checked={
                                  selectedReportTemplate &&
                                  selectedReportTemplate.template_id ===
                                    template._id
                                }
                              />
                            }
                            label={template._source.report_template_name}
                          />
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            width: "100%",
                            overflow: "auto",
                            overscrollBehaviorY: "scroll",
                            backgroundColor: "transparent",
                          }}
                        >
                          {template._source.template.length > 0 &&
                            template._source.template.map((report, index) => (
                              <Grid
                                item
                                lg={12}
                                sm={12}
                                md={12}
                                // className="bg-warining"
                              >
                                <Grid item ml={5}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={report}
                                        label={report}
                                        onChange={(e, val) => {}}
                                        checked={true}
                                        hidden
                                      />
                                    }
                                    label={
                                      <p className="font-weight-bold p-0 m-0">{`Page ${
                                        index + 1
                                      }`}</p>
                                    }
                                  />
                                </Grid>
                              </Grid>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  );
                })}
            </Box>
          </Box>
        </Grid>
        <Grid
          lg={7.5}
          md={7.5}
          sm={11.5}
          ml={{ lg: 0.5, md: 0.5, sm: 0.5, xs: 0 }}
          mt={{ xs: 1 }}
          className="scrollable"
          sx={{
            width: "100%",
            height: 800,
            overflow: "auto",
            overscrollBehaviorY: "scroll",
            backgroundColor: "white",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            border: "solid 0.5px #d3e2e6",
          }}
        >
          <Box ref={componentRef}>
            {ReportTemplates &&
              ReportTemplates.length > 0 &&
              selectedReportTemplate &&
              ReportTemplates.filter(
                (template) =>
                  template._id === selectedReportTemplate.template_id
              ).length > 0 &&
              ReportTemplates.filter(
                (template) =>
                  template._id === selectedReportTemplate.template_id
              )[0]._source.template.map((report, index) => {
                return (
                  <Grid item>
                    {report.layout === "SingleBoxLayout"
                      ? SingleBoxLayout(report)
                      : report.layout === "TwoRowOneColumnLayout"
                      ? TwoRowOneColumnLayout(report)
                      : report.layout === "OneRowTwoColumnLayout"
                      ? OneRowTwoColumnLayout(report)
                      : report.layout === "TwoRowTowColumnLayout"
                      ? TwoRowTowColumnLayout(report)
                      : report.layout === "Single Left, Divided Right(2)"
                      ? SingleLeftDividedRight_2(report)
                      : report.layout === "Divided Left(2), Single right"
                      ? DividedLeft_2_SingleRight(report)
                      : null}
                  </Grid>
                );
              })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DownloadReport;
