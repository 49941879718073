import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
// DataGridFunction Component
import { connect } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import MobileOffIcon from "@material-ui/icons/MobileOff";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
  createFilterOptions,
  ListItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import LockResetIcon from "@mui/icons-material/LockReset";
import jwtDecode from "jwt-decode";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import UserAction from "../../stores/user/UserAction";
import { FullName } from "../../../components/ReusedFunctions";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import UserPost from "../../components/user/UserPost";
import ResetPassword from "../../../components/resetPassword/ResetPassword";
import OrganizationAction from "../../../common/stores/organization/OrganizationAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserAction.REQUEST_USER,
    UserAction.REQUEST_USER_FILTER,
    OrganizationAction.REQUEST_ORGANIZATION_FILTER,
  ]),
  user: state.user.user || [],
  userfilter: state.userfilter.userfilter || [],
  organizationFilter: state.organizationFilter.organizationFilter || [],
});

//MANAGE USER
function ManageUser(props) {
  const { isRequesting } = props;

  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState("none");
  const [searchUser, setSearchUser] = useState(null);
  const [enable] = useState();
  const [openProject, setOpenProject] = useState(false);
  const [userName, setUserName] = useState("");
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  const onFilterUser = (event, value) => {
    setSearchUser(value);
  };

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const [openPassword, setOpenPassword] = useState(false);

  // Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 150,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      hide: true,
      width: 150,
    },
    {
      field: "firstName",
      headerName: "First Name",
      // flex: 3.5,
      width: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      // flex: 3.5,
      width: 150,
    },
    {
      field: "username",
      headerName: "User Name",
      // flex: 3.5,
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      // flex: 4,
      // flex: 1,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      // flex: 2,
      resizable: false,
      width: 120,
    },

    {
      field: "user_login_status",
      headerName: "User Login Status",
      // flex: 2,
      resizable: false,
      width: 180,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="flex-start"
            gap={2}
          >
            <Grid>
              <Box>
                <Box
                  sx={{
                    backgroundColor:
                      params.row.user_login_status === "Logged In"
                        ? "green"
                        : "red",
                    height: 10,
                    width: 10,
                  }}
                ></Box>
              </Box>
            </Grid>

            <Grid>{params.row.user_login_status}</Grid>
          </Grid>
        );
      },
    },
    {
      field: "user_working_status",
      headerName: "User Working Status",
      // flex: 2,
      resizable: false,
      width: 180,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="flex-start"
            gap={2}
          >
            <Grid>
              <Box>
                <Box
                  sx={{
                    backgroundColor:
                      params.row.user_working_status === "Offline"
                        ? "red"
                        : "green",
                    height: 10,
                    width: 10,
                  }}
                ></Box>
              </Box>
            </Grid>

            <Grid>{params.row.user_working_status}</Grid>
          </Grid>
        );
      },
    },
    {
      field: "dsr_enabled",
      headerName: "DSR Enabled",
      // flex: 2,
      resizable: false,
      width: 150,
    },
    {
      field: "mobile_login",
      headerName: "Mobile Login",
      // flex: 2,
      resizable: false,
      width: 150,
    },
    {
      field: "roles",
      headerName: "Roles",
      // flex: 4,
      resizable: false,
      hide: true,
    },
    {
      field: "organization",
      headerName: "Organization",
      // flex: 4,
      resizable: false,
      width: 200,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      // flex: 2,
      width: 50,

      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <IconButton title="Edit" sx={{ border: "0" }}>
            <FontAwesomeIcon
              onClick={(event) => {
                event.ignore = true;
                setEdit(true);
                setOpen(true);
                setEditRowsModel(params.row);
              }}
              icon={faPenToSquare}
              style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
            ></FontAwesomeIcon>
          </IconButton>
        );
      },
    },
    {
      field: "reset",
      type: "actions",
      headerName: "Reset",
      width: 100,
      // flex: 2,

      renderCell: (params) => (
        <IconButton
          title="Reset Password"
          sx={{ border: "0" }}
          onClick={(event) => {
            setOpenPassword(true);
            setEditRowsModel(params.row);
          }}
        >
          <LockResetIcon sx={{ color: "#2a5e6c" }}></LockResetIcon>
        </IconButton>
      ),
    },
    {
      field: "resetDevice",
      type: "actions",
      headerName: "Reset Device",
      width: 120,
      renderCell: (params) => {
        return params.row.mobile_login === "Enabled" ? (
          <IconButton
            title="Reset Device"
            sx={{ border: "0" }}
            onClick={(event) => {
              const auth = jwtDecode(localStorage.getItem("Auth"));
              props.dispatch(UserAction.requestDeviceReset(params.row.id));
            }}
          >
            <MobileOffIcon htmlColor="#2a5e6c" />
          </IconButton>
        ) : null;
      },
    },
  ];

  function fetch(params) {
    props.dispatch(UserAction.requestUser(params));
  }

  function fetchUserFilter(param) {
    props.dispatch(UserAction.requestUserFilter(param));
  }

  function fetchOrganization(params) {
    props.dispatch(OrganizationAction.requestOrganizationFilter(params));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  //   UseEffect function
  useEffect(() => {
    const param = { page_limit: "none" };
    fetchUserFilter(param);
    const params = {
      order_by: '[["created_on", "asc"]]',
      page_number: 0,
      page_limit: `${page_limit}`,
    };

    // if (
    //   props?.organizationFilter === undefined ||
    //   props?.organizationFilter.length === 0
    // ) {
    fetchOrganization(param);
    // }

    fetch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let roleList = [];

  // if (props.roleFilter.hits) {
  //   props.roleFilter.hits.hits.forEach((val) => {
  //     roleList.push({ role_id: val._id, role_name: val._source.role_name });
  //   });••••••••••••
  // }

  let organizationList = [];
  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.forEach((val) => {
      organizationList.push({
        organization_id: val._id,
        organization_name: val._source.organization_name,
      });
    });
  }

  function orgname_function(id) {
    let o_name = organizationList.filter((val) => {
      return val.organization_id === id ? id : "";
    });
    return o_name[0] ? o_name[0].organization_name : "";
  }

  //  datagrid rows
  const rows = [];
  let i = 1;
  if (props.user.data) {
    props.user.data.map((item, index) => {
      rows.push({
        sl_no: item.id ? i++ : "",
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        username: item.username,
        email: item.email,
        enabled: item.enabled === true ? "Enabled" : "Disabled",
        organization: orgname_function(
          item.attributes.organization_id
            ? item.attributes.organization_id[0]
            : ""
        ),
        user_working_status:
          item.attributes &&
          item.attributes.user_working_status &&
          item.attributes.user_working_status[0]
            ? item.attributes.user_working_status[0]
            : "",
        user_login_status:
          item.attributes &&
          item.attributes.user_login_status &&
          item.attributes.user_login_status[0]
            ? item.attributes.user_login_status[0]
            : "",
        mobile_login:
          item.attributes.mobile_login &&
          Boolean(
            item.attributes.mobile_login[0] === "true"
              ? item.attributes.mobile_login[0]
              : null
          )
            ? "Enabled"
            : "Disabled",
        dsr_enabled:
          item.attributes.dsr_enabled &&
          Boolean(
            item.attributes.dsr_enabled[0] === "true"
              ? item.attributes.dsr_enabled[0]
              : null
          )
            ? "Enabled"
            : "Disabled",
      });
    });
  }

  //username dropdown list assign
  let userList = [];

  if (props.userfilter.data) {
    props.userfilter.data.map((item) => {
      return userList.push({
        user_name: item.username,
        fullName: FullName(item),
      });
    });
  }

  userList.sort((a, b) => a.fullName?.localeCompare(b));
  userList = [...new Set(userList)];

  //********************************************** FILTER Function
  const handleFilter = () => {
    let params = {};

    if (searchUser && searchUser.user_name !== null) {
      params = {
        search: `${searchUser.user_name}`,
        order_by: '[["created_on", "desc"]]',
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetch(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  //*************************************** CLEAR Function
  const ClearState = () => {
    // clear state
    if (searchUser && searchUser.user_name !== null) {
      setClearLoader(true);
      setSearchUser(null);
      !FilterClicked && setClearLoader(false);
    }
    setFilterClicked(false);
    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: 15,
    };
    FilterClicked && fetch(params);
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box width={"100%"}>
        {/* Filter Container*/}
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          justifyContent="space-between"
          pr={6}
        >
          <Grid item lg={2} md={4} sm={4} mt={1}>
            <AutoCompleteComponent
              value={searchUser}
              list={userList}
              textFieldProps={{
                placeholder: "Select User",
              }}
              filterOptions={createFilterOptions({
                matchFrom: "start",
                stringify: (option) => option.fullName,
              })}
              isOptionEqualToValue={(option, value) =>
                option.fullName === value.fullName
              }
              getOptionLabel={(option) =>
                option.fullName ? option.fullName : ""
              }
              handleChange={onFilterUser}
              size="small"
              width="100%"
            />
          </Grid>

          {/* <AutoCompleteComponent
                list={enabled}
                textFieldProps={{
                  placeholder: "Enabled/Disabled",
                }}
                handleChange={onFilterEnable}
                size="small"
              /> */}

          <Grid item lg={6} md={7} sm={7} container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={1}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                lg={8}
                sm={8}
                md={8}
                display={"flex"}
                justifyContent={"end"}
                mt={1}
                mr={2}
                sx={{ gap: 2 }}
              >
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    name="Filter"
                    width="100%"
                    onClick={handleFilter}
                    loading={filterLoader}
                  />
                </Grid>
                <Grid lg={3} md={3} sm={3}>
                  <MuiButton
                    name="Clear"
                    width="100%"
                    loading={clearLoader}
                    onClick={ClearState}
                  />
                </Grid>
              </Grid>

              {/* post button component*/}
              <Grid>
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* user post modal screen */}

        <Dialog open={openProject} fullWidth maxWidth="sm">
          <DialogContent>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Would you like to assign project to {userName} ?</h5>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              size="small"
              onClick={() => {
                setOpenProject(false);
              }}
            >
              <ClearIcon />
            </Button>
            <Button
              size="small"
              onClick={() => {
                navigate("/project");
              }}
            >
              <CheckIcon />
            </Button>
          </DialogActions>
        </Dialog>

        <UserPost
          open={open}
          onSubmit={() => {}}
          onClose={() => {
            setOpen(false);
          }}
          user_name={searchUser && searchUser.user_name}
          onNavigateProject={(value) => {
            setOpenProject(true);
            setUserName(value);
          }}
          userList={userList}
          userEditData={editRowsModel}
          edit={edit}
          // roleList={roleList ? roleList : []}
          organizationList={organizationList ? organizationList : []}
        />

        <ResetPassword
          open={openPassword}
          onClose={() => {
            setOpenPassword(false);
          }}
          projectEditData={editRowsModel}
        />

        {/* data grid */}

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          // pr={1}
          container
          alignItems={"center"}
          justifyContent={"center"}
          // className="bg-success"
        >
          {/* loading icon */}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            // className="bg-info"
            height={"80vh"}
            width={"100vh"}
          >
            <LoadingIndicator isActive={isRequesting}>
              {/* datagrid Component */}
              {!isRequesting && props.user.data ? (
                <DataGridFunction
                  columns={columns}
                  rows={rows}
                  rowHeight={60}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                        roles: false,
                        resource_id: false,
                      },
                    },
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage />
                </Box>
              )}
            </LoadingIndicator>
          </Grid>
        </Grid>

        {/* Pagination */}
        {/* <Grid item lg={9} md={12} sm={12} xs={12}>
          <Box sx={{ position: "relative" }}>
            {props.user
              ? props.user.data !== 0 && (
                  <PaginationUI
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                  />
                )
              : null}
          </Box>
        </Grid> */}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(ManageUser);
