import environment from "environment";
import InfluencerPostModel from "./models/InfluencerPostModel";
import InfluencerPutModel from "./models/InfluencerPutModel";
import InfluencerUserModel from "./models/InfluencerUserModel";
import AdminResetPasswordPutModel from "../../../../ormTicketing/stores/user/models/AdminResetPasswordPutModel";
import UserResetPasswordPutModel from "../../../../ormTicketing/stores/user/models/UserResetPasswordPutModel";
import EffectUtility from "../../../../utilities/EffectUtility";

// InfluencerUserEffect
export default class InfluencerUserEffect {
  // calling an API according to the Influencers model
  // Get method for the
  static async requestInfluencerUser(params) {
    const endpoint = environment.api.influencerUser;
    return EffectUtility.getToModel(InfluencerUserModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestInfluencerUserFilter(params) {
    const endpoint = environment.api.influencerUserFilter;
    return EffectUtility.getToModel(InfluencerUserModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutInfluencerUser(data, id) {
    const endpoint = environment.api.influencerUser + "/" + id;
    return EffectUtility.putToModel(InfluencerPutModel, endpoint, data);
  }

  // post method for the Organization
  static async requestPostInfluencerUser(data, callBack) {
    const endpoint = environment.api.influencerUser;

    let response = EffectUtility.postToModel(
      InfluencerPostModel,
      endpoint,
      data
    );

    response.then((res) => {
      if (res.status === "success" && callBack) {
        callBack();
      }
    });
    return response;
  }

  static async requestAdminResetPasswordPut(data) {
    const endpoint = environment.api.influencerAdminResetPassword;
    return EffectUtility.putToModel(AdminResetPasswordPutModel, endpoint, data);
  }

  static async requestUserResetPassword(data) {
    const endpoint = environment.api.influencerUserResetPassword;
    return EffectUtility.putToModel(UserResetPasswordPutModel, endpoint, data);
  }
}
