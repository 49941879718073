import ActionUtility from "../../../../utilities/ActionUtility";
import UserResourceEffect from "./UserResourceEffect";

export default class UserResourceAction {
  // UserResourceAction action with an function declaration
  static REQUEST_USER_RESOURCE = "UserResourceAction.REQUEST_USER_RESOURCE";
  static REQUEST_USER_RESOURCE_FINISHED =
    "UserResourceAction.REQUEST_USER_RESOURCE_FINISHED";

  static REQUEST_USER_RESOURCE_FILTER =
    "UserResourceAction.REQUEST_USER_RESOURCE_FILTER";
  static REQUEST_USER_RESOURCE_FILTER_FINISHED =
    "UserResourceAction.REQUEST_USER_RESOURCE_FILTER_FINISHED";

  static REQUEST_PUT_USER_RESOURCE =
    "UserResourceAction.REQUEST_PUT_USER_RESOURCE";
  static REQUEST_PUT_USER_RESOURCE_FINISHED =
    "UserResourceAction.REQUEST_PUT_USER_RESOURCE_FINISHED";

  static REQUEST_POST_USER_RESOURCE =
    "UserResourceAction.REQUEST_POST_USER_RESOURCE";
  static REQUEST_POST_USER_RESOURCE_FINISHED =
    "UserResourceAction.REQUEST_POST_USER_RESOURCE_FINISHED";

  // METHODS
  // userResource GET function
  static requestUserResource(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserResourceAction.REQUEST_USER_RESOURCE,
        UserResourceEffect.requestUserResource,
        params
      );
    };
  }

  static requestUserResourceFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserResourceAction.REQUEST_USER_RESOURCE_FILTER,
        UserResourceEffect.requestUserResourceFilter,
        params
      );
    };
  }
  static requestPutUserResource(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserResourceAction.REQUEST_PUT_USER_RESOURCE,
        UserResourceEffect.requestPutUserResource,
        data,
        id
      );
    };
  }

  // userResourcefunction
  static requestPostUserResource(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserResourceAction.REQUEST_POST_USER_RESOURCE,
        UserResourceEffect.requestPostUserResource,
        data
      );
    };
  }
}
