import React, { useEffect, useRef, useState } from "react";
import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Zoom from "@mui/material/Zoom";
import Popper from "@mui/material/Popper";
import {
  ModelScreenContainer,
  ShadowContainer,
} from "../../../../components/componentLayout/ComponentLayout";
import { makeStyles } from "@material-ui/core";
import Badge from "@mui/material/Badge";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import CircularLoadingIndicator from "../../../../components/loading-indicator/CircularLoadingIndicator";
import { theme } from "../../../../views/App";
import MusicVideoSharpIcon from "@mui/icons-material/MusicVideoSharp";
import A3NotificationAction from "../../stores/notificationsA3/A3NotificationAction";
import A3NotificationDialog from "../../views/notifications/A3NotificationDialog";

const useStyles = makeStyles({
  gridContainer: {
    height: 250,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
});

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    A3NotificationAction.REQUEST_A3_NOTIFICATIONS,
    A3NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
  ]),
  A3NotificationsFilter:
    state.A3NotificationsFilter.A3NotificationsFilter || [],
});

function A3NotificationBox(props) {
  const [open, setOpen] = React.useState(false);
  const notificationPopperRef = React.useRef(null);
  const scrollRef = useRef(null);
  const prevOpen = React.useRef(open);
  const [page_limit] = useState(15);

  const [notificationsData, setNotificationData] = useState({});
  const [openNotificationDialog, setOpenNotificationsDialog] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  let { isRequesting } = props;

  useEffect(() => {
    if (open) fetchA3Notifications();
  }, [open]);

  function fetchA3Notifications() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(A3NotificationAction.requestA3FilterNotifications(params));
  }

  let unreadNotificationList = [];
  props.A3NotificationsFilter &&
    props.A3NotificationsFilter.hits?.hits &&
    props.A3NotificationsFilter.hits.hits.filter(
      (item) => item._source.notification_msg_read_status === false
    ).length &&
    (unreadNotificationList = props.A3NotificationsFilter.hits.hits.filter(
      (item) => item._source.notification_msg_read_status === false
    ));

  const handleNotificationPopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const [notificationsUnread, setNotifictionsUnread] = useState(0);

  useEffect(() => {
    props.A3NotificationsFilter &&
      props.A3NotificationsFilter.hits?.hits &&
      props.A3NotificationsFilter.hits.hits.filter(
        (item) => item._source.notification_msg_read_status === false
      ).length &&
      setNotifictionsUnread(
        props.A3NotificationsFilter.hits?.hits.filter(
          (item) => item._source.notification_msg_read_status === false
        ).length
      );
    setNotifictionsUnread(props.count);
  }, [props.count]);

  const handleClose = (event) => {
    if (
      notificationPopperRef.current &&
      notificationPopperRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      notificationPopperRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const container = scrollRef.current;
    container?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function returnLocalTime(time) {
    let timeConversion = new Date(time);
    timeConversion =
      timeConversion.toDateString() +
      ", " +
      timeConversion.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

    return timeConversion;
  }

  function UpdateNotificationCount() {
    props.setNewA3NotificationsCount(props.count - 1);
  }

  return (
    <Grid container xs={6}>
      <Typography
        component={"div"}
        ref={notificationPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleNotificationPopper}
        style={{ backgroundColor: "none" }}
      >
        {" "}
        <Tooltip title="A3 Notifications" arrow placement="bottom">
          <Badge badgeContent={notificationsUnread} color="secondary">
            <MusicVideoSharpIcon style={{ fontSize: "28px" }} />
          </Badge>
        </Tooltip>
      </Typography>

      <Popper
        open={open}
        anchorEl={notificationPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Zoom
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
            timeout={300}
          >
            <Paper style={{ width: 350 }}>
              {props.A3NotificationsFilter &&
              props.A3NotificationsFilter.hits?.hits &&
              props.A3NotificationsFilter.hits?.hits.length ? (
                <ShadowContainer>
                  {!isRequesting ? (
                    <ClickAwayListener onClickAway={handleClose}>
                      <div
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          justifyContent="flex-end"
                          sx={{
                            position: "sticky",
                            top: 0,
                            mb: 2,
                          }}
                        >
                          <Typography
                            component={"span"}
                            onClick={() => {
                              navigate("/A3notifications");
                              setOpen(false);
                            }}
                            sx={{
                              boxSizing: "border-box",
                              borderColor: theme.palette.primary.main,
                              border: "1px solid",
                              paddingX: "15px",
                              paddingY: "4px",
                              borderRadius: "4px",
                              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                              "&:hover": {
                                fontWeight: "bold",
                                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.6)",
                              },
                            }}
                          >
                            View All
                          </Typography>
                        </Grid>
                        {props.A3NotificationsFilter.hits?.hits?.filter(
                          (item) =>
                            item._source.notification_msg_read_status === false
                        ).length != 0 && (
                          <Grid
                            container
                            xs={12}
                            gap={1}
                            className={classes.gridContainer}
                            ref={scrollRef}
                          >
                            {props?.A3NotificationsFilter?.hits?.hits
                              ?.filter((item) => {
                                return (
                                  item?._source
                                    ?.notification_msg_read_status === false
                                );
                              })
                              .slice(0, 15)
                              .map((notification, ind) => (
                                <Grid item key={ind} xs={12}>
                                  <div
                                    onClick={() => {
                                      setNotificationData({
                                        id: notification._id,
                                        created_by:
                                          notification?._source.created_by,
                                        created_on:
                                          notification?._source.created_on,
                                        modified_by:
                                          notification?._source.modified_by,
                                        modified_on:
                                          notification?._source.modified_on,
                                        notification_image_url:
                                          notification?._source
                                            .notification_image_url,
                                        notification_msg:
                                          notification?._source
                                            .notification_msg,
                                        notification_msg_read_status:
                                          notification?._source
                                            .notification_msg_read_status,
                                        notification_sent:
                                          notification?._source
                                            .notification_sent,
                                        notification_title:
                                          notification?._source
                                            .notification_title,
                                        notification_type:
                                          notification?._source
                                            .notification_type,
                                      });
                                      setOpenNotificationsDialog(true);
                                      let post_data = {
                                        notification_msg_read_status: true,
                                      };
                                      notification?._source
                                        .notification_msg_read_status ===
                                        false &&
                                        props
                                          .dispatch(
                                            A3NotificationAction.requestPutA3Notifications(
                                              JSON.stringify(post_data),
                                              notification._id
                                            )
                                          )
                                          .then(() => {
                                            let params = {
                                              page_limit: page_limit,
                                            };
                                            UpdateNotificationCount();
                                            props.dispatch(
                                              A3NotificationAction.requestA3Notifications(
                                                params
                                              )
                                            );
                                          });
                                    }}
                                  >
                                    <ModelScreenContainer
                                      sx={{
                                        backgroundColor:
                                          theme.palette.background.primary,
                                        borderColor: theme.palette.primary.main,
                                      }}
                                    >
                                      <p>
                                        <span style={{ fontWeight: "bold" }}>
                                          Title:{" "}
                                        </span>
                                        {
                                          notification._source
                                            .notification_title
                                        }
                                      </p>
                                      <p>
                                        <span style={{ fontWeight: "bold" }}>
                                          Message:{" "}
                                        </span>
                                        {notification._source.notification_msg}{" "}
                                      </p>
                                      <span style={{ fontWeight: "bold" }}>
                                        {returnLocalTime(
                                          notification._source.created_on
                                        )}
                                      </span>
                                    </ModelScreenContainer>
                                  </div>
                                </Grid>
                              ))}
                          </Grid>
                        )}
                      </div>
                    </ClickAwayListener>
                  ) : (
                    <div style={{ marginTop: "50px" }}>
                      <CircularLoadingIndicator isActive={isRequesting} />
                    </div>
                  )}
                </ShadowContainer>
              ) : (
                <CircularLoadingIndicator isActive={isRequesting} />
              )}
              {!isRequesting &&
                props.A3NotificationsFilter.hits?.hits?.length &&
                props.A3NotificationsFilter.hits?.hits?.filter(
                  (item) => item._source.notification_msg_read_status === false
                ).length == 0 && (
                  <ShadowContainer sx={{ width: "100%", padding: "10px" }}>
                    <Typography component={"span"} sx={{ fontSize: "19px" }}>
                      No Notifications
                    </Typography>
                  </ShadowContainer>
                )}
            </Paper>
          </Zoom>
        )}
      </Popper>
      <A3NotificationDialog
        open={openNotificationDialog}
        onClose={() => {
          setOpenNotificationsDialog(false);
        }}
        notificationsData={notificationsData}
      />
    </Grid>
  );
}

export default connect(mapStateToProps)(A3NotificationBox);
