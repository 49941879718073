import environment from "environment";
import BatchLinksGetModel from "./models/BatchLinksGetModel";
import BatchLinksPostModel from "./models/BatchLinksPostModel";
import BatchLinkStatusGetModel from "./models/BatchLinkStatusGetModel";
import EffectUtility from "../../../../utilities/EffectUtility";

export default class BatchLinksEffect {
  // calling an API according to theBatchLinks model
  static async requestBatchLinks(params) {
    const endpoint = environment.api.batchLink;
    return EffectUtility.getToModel(BatchLinksGetModel, endpoint, params);
  }

  static async requestBatchLinksClear() {
    return {};
  }

  static async requestBatchLinksFilterClear() {
    return {};
  }

  // Get method for theBatchLinks filter
  static async requestBatchLinksFilter(params) {
    const endpoint = environment.api.batchLink;
    return EffectUtility.getToModel(BatchLinksGetModel, endpoint, params);
  }

  // put method for theBatchLinks
  static async requestPutBatchLinks(data, id) {
    const endpoint = environment.api.batchLink + "/" + id;
    return EffectUtility.putToModel(BatchLinksPostModel, endpoint, data);
  }

  // post method for theBatchLinks
  static async requestPostBatchLinks(data, params, callBack) {
    const endpoint = environment.api.batchLink + "/add";
    let response = EffectUtility.postToModel(
      BatchLinksPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestPostUploadLinks(data, params, callBack) {
    const endpoint = environment.api.uploadLinks;
    let response = EffectUtility.postToModel(
      BatchLinksPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestBatchLinksStatusReportById(id) {
    const endpoint = environment.api.batchLinkStatusReport + "/" + id;
    return EffectUtility.getToModel(BatchLinkStatusGetModel, endpoint);
  }
}
