import { Box, Container, Typography } from "@mui/material";
import React from "react";

export default function UnderDevelopment() {
  return (
    <Container>
      <Box>
        <Typography>This Page is Not Available...!</Typography>
        <Typography>Please contact Administrator.</Typography>
      </Box>
    </Container>
  );
}
