import BaseReducer from "../../../utilities/BaseReducer";
import FrontEndResourceAction from "./FrontEndResourceAction";

export default class FrontEndResourceFilterReducer extends BaseReducer {
  initialState = {
    frontEndResourceFilter: {},
  };

  [FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      frontEndResourceFilter: action.payload,
    };
  }
}
