import BaseReducer from "../../../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Releases Reducer
export default class DraftReleaseReduser extends BaseReducer {
  initialState = {
    draftrelease: [],
  };
  [ReleasesAction.REQUEST_DRAFT_RELEASES_FINISHED](state, action) {
    return {
      ...state,
      draftrelease: action.payload,
    };
  }

  //   [ReleasesAction.REQUEST_RELEASE_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       release: action.payload,
  //     };
  //   }
}
