import BaseReducer from "../../../utilities/BaseReducer";
import CloudMailAction from "./CloudMailAction";

// CloudMailReduser
export default class CloudMailReduser extends BaseReducer {
  initialState = {
    cloudMail: [],
  };
  [CloudMailAction.REQUEST_CLOUDMAIL_FINISHED](state, action) {
    return {
      ...state,
      cloudMail: action.payload,
    };
  }
}
