import BaseReducer from "../../../../utilities/BaseReducer";
import ActivityInsightAction from "./ActivityInsightAction";

export default class ActvityInsightReportReducer extends BaseReducer {
  initialState = {
    activityInsightReport: [],
  };
  [ActivityInsightAction.REQUEST_ACTIVITY_INSIGHT_REPORT_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      activityInsightReport: action.payload,
    };
  }
}
