import BaseReducer from "../../../utilities/BaseReducer";
import FacebookDmAction from "./FacebookDmAction";

// TwitterDirectMessageReducer function
export default class FacebookDmReducer extends BaseReducer {
  initialState = {
    facebook_dm: [],
  };

  [FacebookDmAction.REQUEST_FACEBOOK_DM_FINISHED](state, action) {
    return {
      ...state,
      facebook_dm: action.payload,
    };
  }

  [FacebookDmAction.REQUEST_FACEBOOK_DM_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      facebook_dm: action.payload,
    };
  }
}
