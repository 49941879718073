import React, { useEffect, useState } from "react";
import { Grid, Paper, Button, Slide, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../../../components/autoCompleteComponent/Autocomplete";
import { selectRequesting } from "../../../../selectors/requesting/RequestingSelector";
import ProjectAction from "../../../stores/project/projectAction";
import PlatformAction from "../../../stores/platform/PlatformAction";
import UserLastWorkingProjectAction from "../../../stores/userLastWorkingProject/UserLastWorkingProjectAction";
import {
  returnLogo,
  returnUsestateFunctionForAllMentions,
} from "../../../../components/ticketPartials";
import MuiButton from "../../../../components/muiButton/MuiButton";
import FacebookFeedMain from "../../feeds/facebookPlatform/FacebookFeedMain";
import AppStoreListView from "../../feeds/appStorePlatform/AppStoreListView";
import PlatformPostDialog from "../postDataDialogs/PlatformPostDialog";
import PlayStoreListView from "../../feeds/playtorePlatform/PlayStoreListView";
import EmailListView from "../../feeds/emailPlatform/EmailListView";
import GlassdoorListView from "../../feeds/glassdoorFeeds/GlassdoorListView";
import YoutubeFeedsListView from "../../feeds/youtubePlatform/YoutubeFeedsListView";
import InstagramFeedsMain from "../../feeds/instagramPlatform/InstagramFeedsMain";
import ConsumerComplaintFeeds from "../../feeds/consumerFeeds/ConsumerComplaintFeeds";
import TwitterListView from "../../feeds/twitterPlatform/TwitterListView";
import WhatsappFeeds from "../../feeds/WhatsAppPlatform/WhatsappFeeds";
import TelegramFeeds from "../../feeds/telegramPlatform/TelegramFeeds";
import GMBFeedTabs from "../../../../views/gmbFeeds/GMBFeedTabs";
import LinkedInListView from "../../feeds/linkedInFeeds/LinkedInListView";
import QuoraListView from "../../feeds/quoraFeeds/QuoraListView";
import NoDataFound from "../../../../components/nodatafound/NoDataFound";
import IndeedFeeds from "../../feeds/indeedFeeds/IndeedFeeds";
import RedditFeeds from "../../feeds/redditFeeds/RedditFeeds";
import PinterestFeedsListView from "../../feeds/pinterestPlatform/PinterestFeedsListView";
import AmbitionBoxListView from "../../feeds/ambitionBoxFeeds/AmbitionBoxListView";
import DiscordFeeds from "../../feeds/discordPlatform/DiscordFeeds";
import TrustpilotListview from "../../feeds/trustPilotPlatform/TrustpilotListview";

let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ProjectAction.REQUEST_PROJECT,
    PlatformAction.REQUEST_PLATFORM,
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
  ]),
  projectfilter: state.projectfilter.projectfilter || [],
  platform: state.platform.platform.hits || {},
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
});

function AllMentions(props) {
  const platform_list = [];

  const [twitter_feed_open, set_twitter_feed_open] = useState(false);
  const [facebook_feed_open, set_facebook_feed_open] = useState(false);
  const [instagram_feed_open, set_instagram_feed_open] = useState(false);
  const [playstore_feed_open, set_playstore_feed_open] = useState(false);
  const [youtube_feed_open, set_youtube_feed_open] = useState(false);
  const [email_feed_open, set_email_feed_open] = useState(false);
  const [appstore_feed_open, set_appstore_feed_open] = useState(false);
  const [glassdoor_feed_open, set_glassdoor_feed_open] = useState(false);
  const [ambition_box_feed_open, set_ambition_box_feed_open] = useState(false);
  const [whatsapp_feed_open, set_whatsapp_feed_open] = useState(false);
  const [telegram_feed_open, set_telegram_feed_open] = useState(false);
  const [GMB_feed_open, set_GMB_feed_open] = useState(false);
  const [linkedIn_feed_open, set_linkedIn_feed_open] = useState(false);
  const [quora_feed_open, set_quora_feed_open] = useState(false);
  const [indeed_feed_open, set_indeed_feed_open] = useState(false);
  const [reddit_feed_open, set_reddit_feed_open] = useState(false);
  const [discord_feed_open, set_discord_feed_open] = useState(false);
  const [trustpilot_feed_open, set_trustpilot_feed_open] = useState(false);
  const [pinterest_feed_open, set_pinterest_feed_open] = useState(false);

  const [consumer_feed_open, set_consumer_feed_open] = useState(false);
  const [none_feed_open, set_none_feed_open] = useState(false);

  if (props.platform.hits) {
    props.platform.hits.map((item) => {
      platform_list.push(item._source.platform_name);
    });
  }

  const [selected_platform, set_selected_platform] = useState(() => {
    if (localStorage.getItem("selected_platform_for_mentions")) {
      return localStorage.getItem("selected_platform_for_mentions");
    } else {
      localStorage.setItem(
        "selected_platform_for_mentions",
        platform_list ? platform_list[0] : ""
      );
      return platform_list ? platform_list[0] : "";
    }
  });

  // fetch project filter
  function fetchProjectFilter(filter_params) {
    props.dispatch(ProjectAction.requestProjectFilter(filter_params));
  }

  // fetchLastWorkingProject
  function fetchLastWorkingProject(id) {
    props.dispatch(
      UserLastWorkingProjectAction.requestLastWorkingProjectById(id)
    );
  }

  function fetchPlatform() {
    let param = {
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };

    props.dispatch(PlatformAction.requestPlatform(param));
  }

  useEffect(() => {
    let params = {
      page_limit: "none",
    };

    let ID = localStorage.getItem("userId");
    fetchLastWorkingProject(ID);

    fetchProjectFilter(params);
    fetchPlatform();
  }, []);

  useEffect(() => {
    returnUsestateFunctionForAllMentions(
      selected_platform,
      set_twitter_feed_open,
      set_facebook_feed_open,
      set_instagram_feed_open,
      set_playstore_feed_open,
      set_youtube_feed_open,
      set_email_feed_open,
      set_appstore_feed_open,
      set_glassdoor_feed_open,
      set_ambition_box_feed_open,
      set_whatsapp_feed_open,
      set_telegram_feed_open,
      set_GMB_feed_open,
      set_linkedIn_feed_open,
      set_quora_feed_open,
      set_indeed_feed_open,
      set_reddit_feed_open,
      set_discord_feed_open,
      set_trustpilot_feed_open,
      set_pinterest_feed_open,
      set_none_feed_open,
      set_consumer_feed_open
    );
  }, [selected_platform]);

  // platform Handle Change function
  const platformHandleChange = (event, value) => {
    value && localStorage.setItem("selected_platform_for_mentions", value);

    // autoCompleteRef.current.scrollTo(0, 0);
    if (value === "Twitter") {
      set_twitter_feed_open(true);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);
      set_selected_platform(value);
      set_none_feed_open(false);
      set_consumer_feed_open(false);
    } else if (value === "Reddit") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(true);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_selected_platform(value);
      set_consumer_feed_open(false);
      set_none_feed_open(false);
    } else if (value === "Discord") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(true);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);
      set_selected_platform(value);
      set_consumer_feed_open(false);
      set_none_feed_open(false);
    } else if (value === "Indeed") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_indeed_feed_open(true);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);
      set_consumer_feed_open(false);
      set_selected_platform(value);
      set_none_feed_open(false);
    } else if (value === "Facebook") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(true);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);
      set_selected_platform(value);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);
    } else if (value === "Instagram") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(true);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_indeed_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_none_feed_open(false);
      set_consumer_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);
      set_selected_platform(value);
    } else if (value === "Playstore") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(true);
      set_youtube_feed_open(false);
      set_indeed_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_selected_platform(value);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);
    } else if (value === "Youtube") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(true);
      set_indeed_feed_open(false);
      set_email_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_selected_platform(value);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);
    } else if (value === "Email") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_indeed_feed_open(false);
      set_email_feed_open(true);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_selected_platform(value);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);
    } else if (value === "Appstore") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_indeed_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(true);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Glassdoor") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_appstore_feed_open(false);
      set_indeed_feed_open(false);
      set_glassdoor_feed_open(true);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Ambition Box") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_indeed_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(true);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Whatsapp") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(true);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Telegram") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_indeed_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(true);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_none_feed_open(false);
      set_consumer_feed_open(false);
      set_selected_platform(value);
    } else if (value === "GMB") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_email_feed_open(false);
      set_indeed_feed_open(false);
      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(true);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "LinkedIn") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_indeed_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(true);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Consumer Complaints") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_indeed_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(true);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Quora") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(true);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Trust Pilot") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(true);
      set_pinterest_feed_open(false);

      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "Pinterest") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(true);

      set_appstore_feed_open(false);
      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(false);

      set_selected_platform(value);
    } else if (value === "None" || selected_platform === "Telegram") {
      set_twitter_feed_open(false);
      set_facebook_feed_open(false);
      set_instagram_feed_open(false);
      set_playstore_feed_open(false);
      set_youtube_feed_open(false);
      set_email_feed_open(false);
      set_appstore_feed_open(false);
      set_indeed_feed_open(false);
      set_reddit_feed_open(false);
      set_discord_feed_open(false);
      set_trustpilot_feed_open(false);
      set_pinterest_feed_open(false);

      set_glassdoor_feed_open(false);
      set_ambition_box_feed_open(false);
      set_whatsapp_feed_open(false);
      set_telegram_feed_open(false);
      set_GMB_feed_open(false);
      set_linkedIn_feed_open(false);
      set_quora_feed_open(false);
      set_consumer_feed_open(false);
      set_none_feed_open(true);
      set_selected_platform(value);
    } else {
      set_selected_platform(null);
    }
  };

  const [postDialogOpen, setPostDialogOpen] = useState(false);

  return (
    <Box width="100%">
      <Box width={"100%"} my={0.5}>
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          // className="bg-warning"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid
            item
            container
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            // className="bg-success"
          >
            <AutoCompleteComponent
              width="100%"
              value={selected_platform}
              // defaultValue={platform_list[0]}
              handleChange={platformHandleChange}
              list={platform_list}
              disableClearable={true}
              textFieldProps={{
                placeholder: "Platforms",
              }}
              size="small"
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Box width={"auto"} sx={{ mr: "5px" }}>
                      {returnLogo(option)}
                    </Box>
                    {option}
                  </Box>
                );
              }}
            />
          </Grid>
          <Grid>
            <MuiButton
              onClick={() => {
                setPostDialogOpen(true);
              }}
              disabled={
                selected_platform === "Twitter" ||
                selected_platform === "Facebook" ||
                selected_platform === "Instagram" ||
                selected_platform === "Youtube"
                  ? false
                  : true
              }
              name="Post"
            ></MuiButton>
          </Grid>
        </Grid>
      </Box>
      <Box width={"100%"}>
        {appstore_feed_open && <AppStoreListView setValue={props.setValue} />}
        {facebook_feed_open && <FacebookFeedMain setValue={props.setValue} />}
        {playstore_feed_open && <PlayStoreListView setValue={props.setValue} />}
        {email_feed_open && <EmailListView setValue={props.setValue} />}
        {glassdoor_feed_open && <GlassdoorListView setValue={props.setValue} />}
        {youtube_feed_open && (
          <YoutubeFeedsListView setValue={props.setValue} />
        )}
        {instagram_feed_open && (
          <InstagramFeedsMain setValue={props.setValue} />
        )}

        {consumer_feed_open && (
          <ConsumerComplaintFeeds setValue={props.setValue} />
        )}

        {twitter_feed_open && <TwitterListView setValue={props.setValue} />}

        {whatsapp_feed_open && <WhatsappFeeds setValue={props.setValue} />}

        {telegram_feed_open && <TelegramFeeds setValue={props.setValue} />}

        {GMB_feed_open && <GMBFeedTabs setValue={props.setValue} />}

        {linkedIn_feed_open && <LinkedInListView setValue={props.setValue} />}

        {quora_feed_open && <QuoraListView setValue={props.setValue} />}

        {none_feed_open && <NoDataFound setValue={props.setValue} />}

        {indeed_feed_open && <IndeedFeeds setValue={props.setValue} />}

        {reddit_feed_open && <RedditFeeds setValue={props.setValue} />}

        {pinterest_feed_open && (
          <PinterestFeedsListView setValue={props.setValue} />
        )}

        {ambition_box_feed_open && (
          <AmbitionBoxListView setValue={props.setValue} />
        )}

        {discord_feed_open && <DiscordFeeds setValue={props.setValue} />}

        {trustpilot_feed_open && (
          <TrustpilotListview setValue={props.setValue} />
        )}
      </Box>

      {postDialogOpen && selected_platform && (
        <PlatformPostDialog
          platform={selected_platform}
          open={postDialogOpen}
          onClose={() => {
            setPostDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(AllMentions);
