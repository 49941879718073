import BaseReducer from "../../../utilities/BaseReducer";
import AutoresponseAction from "./AutoresponseAction";

export default class AutomationOperationReducer extends BaseReducer {
  initialState = {
    automationOperations: [],
  };

  [AutoresponseAction.REQUEST_AUTOMATION_OPERATIONS_FINISHED](state, action) {
    return {
      ...state,
      automationOperations: action.payload,
    };
  }
}
