import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { selectRequesting } from "../../../../../selectors/requesting/RequestingSelector";

// mapstate to props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const CannedMessageDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
      sx={{
        backgroundColor: "transparent",
      }}
      //   className="bg-warning"
    >
      <DialogContent
        className=" p-0 m-0"
        sx={{
          border: "2px solid #c0bfbf",
        }}
      >
        <DialogContentText>
          <Box
            width="100%"
            // className="bg-success"
            sx={{
              height: "fit-content",
            }}
          >
            <Box
              // className="bg-warning"
              sx={{
                borderBottom: "1px solid #c0bfbf",
              }}
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                // className="bg-warning"
              >
                <Grid item px={1} sx={{ display: "flex" }}>
                  <Typography>
                    {/* <FontAwesomeIcon
                      icon={faFilter}
                      style={{
                        color: `${theme.palette.ticketListView.iconColor}`,
                      }}
                    ></FontAwesomeIcon> */}
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>C</span>
                    <span>anned</span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>M</span>
                    <span></span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#f79f8a" }}>V</span>
                    <span>iew</span>
                  </Typography>
                </Grid>
                <Grid item px={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{
                        // color: `${theme.palette.ticketListView.iconColor}`,
                        fontSize: "20px",
                      }}
                    ></FontAwesomeIcon>
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps)(CannedMessageDialog);
