import BaseReducer from "../../../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Release by id Reducer
export default class ReleaseByIdReducer extends BaseReducer {
  initialState = {
    releaseById: [],
  };
  [ReleasesAction.REQUEST_RELEASE_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      releaseById: action.payload,
    };
  }

  [ReleasesAction.REQUEST_RELEASE_BY_ID_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      releaseById: action.payload,
    };
  }
}
