import { BaseModel } from "sjs-base-model";
import UserPermissionHitsModel from "./UserPermissionHitsModel";
import UserPermissionShardsModel from "./UserPermissionShardsModel";

// category Model
export default class UserPermissionModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  _shards = { _shards: UserPermissionShardsModel };
  current_page_no = 0;
  hits = { hits: UserPermissionHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
