import BaseReducer from "../../../../utilities/BaseReducer";
import ReleasesShadowTableAction from "./ReleasesShadowTableAction";

// Release by id Reducer
export default class ReleasesShadowTableReduser extends BaseReducer {
  initialState = {
    releasesShadowTableById: [],
  };
  [ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_BY_ID_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      releasesShadowTableById: action.payload,
    };
  }

  [ReleasesShadowTableAction.REQUEST_RELEASE_BY_ID_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      releasesShadowTableById: action.payload,
    };
  }
}
