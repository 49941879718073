import environment from "environment";
import HttpErrorResponseModel from "../../../../models/HttpErrorResponseModel";
import EffectUtility from "../../../../utilities/EffectUtility";
import HttpUtility from "../../../../utilities/HttpUtility";
import YoutubeDataExtractionModel from "./models/YoutubeDataExtractionModel";
import YoutubeDataExtractionPutModel from "./models/YoutubeDataExtractionPutmodel";
import DataExtractionProjectsModel from "./models/DataExtractionProjectsModel";

export default class YoutubeDataExtractionEffect {
  //youtube data extraction get method
  static async requestYoutubeDataExtraction(params, callBackFunction) {
    const endpoint = environment.api.youTubeDataExtraction;

    let response = EffectUtility.getToModel(
      YoutubeDataExtractionModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestYoutubeDataExtractionClear() {
    return {};
  }

  static async requestYoutubeDataExtractionFilter(params, callBackFunction) {
    const endpoint = environment.api.youTubeDataExtraction;

    let response = EffectUtility.getToModel(
      YoutubeDataExtractionModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp && callBackFunction) {
        callBackFunction(resp);
      }
    });

    return response;
  }

  static async requestYoutubeDataExtractionFilterClear() {
    return {};
  }

  //youtube dataExtraction put method
  static async requestPutYoutubeDataExtraction(data) {
    const endpoint = environment.api.youTubeDataExtraction + "UpdateAll";

    return EffectUtility.putToModel(
      YoutubeDataExtractionPutModel,
      endpoint,
      data
    );
  }

  // Dataextraction Projects
  static async requestDataExtractionProjects() {
    const endpoint = environment.api.dataExtractionApi + "/projects";

    return EffectUtility.getToModel(DataExtractionProjectsModel, endpoint);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
