import BaseReducer from "../../../../utilities/BaseReducer";
import InfluencerPlatformAction from "./InfluencerPlatformAction";

export default class InfluencerPlatformReducer extends BaseReducer {
  initialState = {
    influencerPlatformAccounts: [],
  };

  [InfluencerPlatformAction.REQUEST_INFLUENCER_PLATFORM_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      influencerPlatformAccounts: action.payload,
    };
  }
}
