import ActionUtility from "../../../utilities/ActionUtility";
import FacebookEffect from "./FacebookEffect";

// twitter api fetch model
export default class FacebookAction {
  // project action with an function declaration
  static REQUEST_FACEBOOK = "FacebookAction.REQUEST_FACEBOOK";
  static REQUEST_FACEBOOK_FINISHED = "FacebookAction.REQUEST_FACEBOOK_FINISHED";

  static REQUEST_FACEBOOK_CLEAR = "FacebookAction.REQUEST_FACEBOOK_CLEAR";
  static REQUEST_FACEBOOK_CLEAR_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_CLEAR_FINISHED";

  static REQUEST_FACEBOOK_WEBHOOK = "FacebookAction.REQUEST_FACEBOOK_WEBHOOK";
  static REQUEST_FACEBOOK_WEBHOOK_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_WEBHOOK_FINISHED";

  static REQUEST_FACEBOOK_FILTER = "FacebookAction.REQUEST_FACEBOOK_FILTER";
  static REQUEST_FACEBOOK_FILTER_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_FILTER_FINISHED";

  // POST Requests for reply
  static REQUEST_FACEBOOK_REPLY_POST =
    "FacebookAction.REQUEST_FACEBOOK_REPLY_POST";
  static REQUEST_FACEBOOK_REPLY_POST_FINISHED =
    "FacebookAction.REQUEST_INSTAGRAM_REPLY_POST_FINISHED";

  // POST Requests for comments
  static REQUEST_FACEBOOK_COMMENT_POST =
    "FacebookAction.REQUEST_FACEBOOK_COMMENT_POST";
  static REQUEST_FACEBOOK_COMMENT_POST_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_COMMENT_POST_FINISHED";

  //DELETE Requests for comments
  static REQUEST_FACEBOOK_COMMENT_DELETE =
    "FacebookAction.REQUEST_FACEBOOK_COMMENT_DELETE";
  static REQUEST_FACEBOOK_COMMENT_DELETE_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_COMMENT_DELETE_FINISHED";

  //Post Requests for comment hide
  static REQUEST_FACEBOOK_COMMENT_HIDE_POST =
    "FacebookAction.REQUEST_FACEBOOK_COMMENT_HIDE_POST";
  static REQUEST_FACEBOOK_COMMENT_HIDE_POST_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_COMMENT_HIDE_POST_FINISHED";

  // Facebook comment filter requests for comments raised by single user
  static REQUEST_FACEBOOK_INNER_FILTER =
    "FacebookAction.REQUEST_FACEBOOK_INNER_FILTER";
  static REQUEST_FACEBOOK_INNER_FILTER_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_INNER_FILTER_FINISHED";

  static REQUEST_PUT_FACEBOOK_FEEDS =
    "FacebookAction.REQUEST_PUT_FACEBOOK_FEEDS";
  static REQUEST_PUT_FACEBOOK_FEEDS_FINISHED =
    "FacebookAction.REQUEST_PUT_FACEBOOK_FEEDS_FINISHED";

  static REQUEST_FACEBOOK_INNER_FILTER_CLEAR =
    "FacebookAction.REQUEST_FACEBOOK_INNER_FILTER_CLEAR";
  static REQUEST_FACEBOOK_INNER_FILTER_CLEAR_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_INNER_FILTER_CLEAR_FINISHED";

  //Action type for Data post in facebook platform
  static REQUEST_FACEBOOK_DATA_POST =
    "FacebookAction.REQUEST_FACEBOOK_DATA_POST";
  static REQUEST_FACEBOOK_DATA_POST_FINISHED =
    "FacebookAction.REQUEST_FACEBOOK_DATA_POST_FINISHED";

  // METHODS
  // Facebook GET function
  static requestFacebook(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK,
        FacebookEffect.requestFacebook,
        params,
        callBackFunction
      );
    };
  }

  static requestFacebookClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_CLEAR,
        FacebookEffect.requestFacebookClear
      );
    };
  }

  // Facebook Webhook
  static requestFacebookWebhook(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_WEBHOOK,
        FacebookEffect.requestFacebookWebhook,
        params
      );
    };
  }

  // Facebook comment filter for comment raised by single user
  static requestFacebookInnerFilter(params, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_INNER_FILTER,
        FacebookEffect.requestFacebookInnerFilter,
        params,
        callBackFunction
      );
    };
  }

  static requestFacebookInnerFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_INNER_FILTER_CLEAR,
        FacebookEffect.requestFacebookInnerFilterClear
      );
    };
  }

  static requestFacebookFilter(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_FILTER,
        FacebookEffect.requestFacebookFilter,
        data
      );
    };
  }

  static requestFacebookReplyPost(id, data, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_REPLY_POST,
        FacebookEffect.requestFacebookReplyPost,
        id,
        data,
        callback
      );
    };
  }

  static requestFacebookCommentPost(id, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_COMMENT_POST,
        FacebookEffect.requestFacebookCommentPost,
        id,
        data
      );
    };
  }

  static requestFacebookCommentDelete(id, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_COMMENT_DELETE,
        FacebookEffect.requestFacebookCommentDelete,
        id,
        data
      );
    };
  }

  static requestFacebookCommentHidePost(id, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_COMMENT_HIDE_POST,
        FacebookEffect.requestFacebookCommentHidePost,
        id,
        data
      );
    };
  }

  static requestPutFbFeeds(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_PUT_FACEBOOK_FEEDS,
        FacebookEffect.requestPutFbFeeds,
        data,
        id
      );
    };
  }

  static requetFacebookDataPost(params, data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FacebookAction.REQUEST_FACEBOOK_DATA_POST,
        FacebookEffect.requetFacebookDataPost,
        params,
        data
      );
    };
  }
}
