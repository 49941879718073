import ActionUtility from "../../../utilities/ActionUtility";
import KeywordConfigEffect from "./KeywordConfigEffect";

export default class KeywordConfigAction {
  static REQUEST_KEYWORD_CONFIG = "KeywordConfigAction.REQUEST_KEYWORD_CONFIG";
  static REQUEST_KEYWORD_CONFIG_FINISHED =
    "KeywordConfigAction.REQUEST_KEYWORD_CONFIG_FINISHED";

  static REQUEST_KEYWORD_CONFIG_FILTER =
    "KeywordConfigAction.REQUEST_KEYWORD_CONFIG_FILTER";
  static REQUEST_KEYWORD_CONFIG_FILTER_FINISHED =
    "KeywordConfigAction.REQUEST_KEYWORD_CONFIG_FILTER_FINISHED";

  static REQUEST_POST_KEYWORD_CONFIG =
    "KeywordConfigAction.REQUEST_POST_KEYWORD_CONFIG";
  static REQUEST_POST_KEYWORD_CONFIG_FINISHED =
    "KeywordConfigAction.REQUEST_POST_KEYWORD_CONFIG_FINISHED";

  static REQUEST_PUT_KEYWORD_CONFIG =
    "KeywordConfigAction.REQUEST_PUT_KEYWORD_CONFIG";
  static REQUEST_PUT_KEYWORD_CONFIG_FINISHED =
    "KeywordConfigAction.REQUEST_PUT_KEYWORD_CONFIG_FINISHED";

  static REQUEST_DELETE_KEYWORD_CONFIG =
    "KeywordConfigAction.REQUEST_DELETE_KEYWORD_CONFIG";
  static REQUEST_DELETE_KEYWORD_CONFIG_FINISHED =
    "KeywordConfigAction.REQUEST_DELETE_KEYWORD_CONFIG_FINISHED";

  //METHODS

  static requestKeywordConfiguration(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordConfigAction.REQUEST_KEYWORD_CONFIG,
        KeywordConfigEffect.requestKeywordConfiguration,
        params
      );
    };
  }

  static requestKeywordConfigurationFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordConfigAction.REQUEST_KEYWORD_CONFIG_FILTER,
        KeywordConfigEffect.requestKeywordConfigurationFilter,
        params
      );
    };
  }

  static requestPostKeywordConfig(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordConfigAction.REQUEST_POST_KEYWORD_CONFIG,
        KeywordConfigEffect.requestPostKeywordConfig,
        data
      );
    };
  }

  static requestPutKeywordConfig(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordConfigAction.REQUEST_PUT_KEYWORD_CONFIG,
        KeywordConfigEffect.requestPutKeywordConfig,
        data,
        id
      );
    };
  }

  static requestDeleteKeywordConfig(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        KeywordConfigAction.REQUEST_DELETE_KEYWORD_CONFIG,
        KeywordConfigEffect.requestDeleteKeywordConfig,
        id
      );
    };
  }
}
