import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import { Button, createFilterOptions, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import OrganizationAction from "../../stores/organization/OrganizationAction";
import PlatformAction from "../../../ormTicketing/stores/platform/PlatformAction";
import OrganizationPost from "./OrganizationPost";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    OrganizationAction.REQUEST_ORGANIZATION,
    OrganizationAction.REQUEST_ORGANIZATION_FILTER,
    PlatformAction.REQUEST_PLATFORM,
  ]),
  organization: state.organization.organization.hits || [],
  organizationFilter: state.organizationFilter.organizationFilter || {},
  pagination: state.organization.organization || {},
  platform: state.platform.platform || [],
});

function Organization(props) {
  const { isRequesting } = props;
  const [organization, setOrganization] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  function fetchOrganization() {
    const param = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
    };
    props.dispatch(OrganizationAction.requestOrganization(param));
  }

  function fetchOrganizationFilter() {
    const param = {
      order_by: `[["organization_name.keyword", "asc"]]`,
      page_limit: "none",
    };
    props.dispatch(OrganizationAction.requestOrganizationFilter(param));
  }

  function fetchOrgPlatforms() {
    let param = {
      level: "organization",
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  useEffect(() => {
    if (!isRequesting) {
      setFilterLoader(false);
      setClearLoader(false);
    }
  }, [isRequesting]);

  useEffect(() => {
    fetchOrganization();

    // if (
    //   props?.organizationFilter === undefined ||
    //   props?.organizationFilter.length === 0
    // ) {
    fetchOrganizationFilter();
    // }

    fetchOrgPlatforms();
  }, []);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let filter_params = {};
    const filter = [];

    if (organization !== null) {
      filter.push(
        `["organization_name.keyword","must","","match","${organization}"]`
      );
    }

    if (organization !== null && FilterClicked) {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    } else {
      filter_params = {
        order_by: '[["created_on", "desc"]]',
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      props.dispatch(OrganizationAction.requestOrganization(filter_params));
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "SL NO",
      width: 150,
      flex: 2,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "organization_unique_id",
      headerName: "Project ID",
      width: 200,
      hide: true,
    },

    {
      field: "organisation_name",
      headerName: "Organization Name",
      width: 250,
      flex: 4,
    },

    {
      field: "platform",
      headerName: "Platform",
      width: 250,
      flex: 5,
      resizable: false,
      hide: true,
    },
    {
      field: "Platform",
      headerName: "Platforms",
      width: 250,
      flex: 5,
      resizable: false,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
      flex: 3,
      resizable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      resizable: false,
      align: "center",

      width: 160,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEditRowsModel(params.row);
            setEdit(true);
            setOpen(true);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const rows = [];
  if (props.organization.hits) {
    props.organization.hits.map((item, index) => {
      return rows.push({
        id: item._id
          ? props.pagination.current_page_no * page_limit + ++index
          : "",
        organization_unique_id: item._id,
        organisation_name: item._source.organization_name,
        created_by: item._source.created_by.user_name,
        platform: item._source.platform ? item._source.platform : [],
        Platform:
          item._source.platform && item._source.platform.length > 0
            ? item._source.platform.map((val) => {
                return val.platform_name;
              })
            : [],
      });
    });
  }

  const organizationName_handlechange = (event, value) => {
    if (value != null) {
      setOrganization(value);
    } else {
      setOrganization(null);
    }
  };

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // Filter function for Client
  const applyFilter = () => {
    const filter = [];
    if (organization !== null) {
      filter.push(
        `["organization_name.keyword","must","","match","${organization}"]`
      );
    }

    if (organization !== null) {
      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
      };
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);

      props.dispatch(OrganizationAction.requestOrganization(params));
    } else {
      alert("Please add filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    if (organization !== null) {
      setClearLoader(true);
      setOrganization(null);
      !FilterClicked && setClearLoader(false);
    }

    setFilterClicked(false);

    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: 15,
    };
    FilterClicked &&
      props.dispatch(OrganizationAction.requestOrganization(params));
  };

  let OrganizationNameList = [];
  if (props.organizationFilter.hits) {
    props.organizationFilter.hits.hits.map((item) => {
      return OrganizationNameList.push(item._source.organization_name);
    });
  }

  OrganizationNameList.sort((a, b) => a.localeCompare(b));
  OrganizationNameList = [...new Set(OrganizationNameList)];

  let platform_array = [];

  if (props.platform.hits) {
    props.platform.hits.hits.map((item) => {
      return platform_array.push({
        platform_id: item._id,
        platform_name: item._source.platform_name,
        platform_alias: item._source.alias,
      });
    });
  }

  //  datagrid rows

  return (
    <React.Fragment>
      {/* Filter */}

      <OrganizationPost
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        projectEditData={editRowsModel}
        platform_array={platform_array.length > 0 ? platform_array : null}
        edit={edit}
      />

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={2}
      >
        <Grid item lg={2} md={5} sm={5} mt={1}>
          <AutoCompleteComponent
            value={organization}
            textFieldProps={{
              placeholder: "Organization",
            }}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: (option) => option,
            })}
            list={OrganizationNameList}
            handleChange={organizationName_handlechange}
            size="small"
            width="100%"
          />
        </Grid>
        <Grid item lg={6} md={7} sm={7} container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            container
            spacing={1}
            justifyContent="flex-end"
            mt={0}
          >
            <Grid
              lg={8}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"end"}
              mt={1}
              mr={2}
              sx={{ gap: 2 }}
            >
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Filter"}
                  width="100%"
                  onClick={applyFilter}
                  loading={filterLoader && isRequesting}
                />
              </Grid>
              <Grid lg={3} md={3} sm={3}>
                <MuiButton
                  name={"Clear"}
                  width="100%"
                  onClick={ClearState}
                  loading={clearLoader && isRequesting}
                />
              </Grid>
            </Grid>
            <Grid>
              {/* post button component*/}
              <PostPlusButton
                open={open}
                openCloseModal={openCloseModal}
                editMode={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* ***************************************************************  user filter */}

      {/* data grid */}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* loading icon */}
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting && (
            <DataGridFunction
              columns={columns}
              rows={rows}
              rowHeight={60}
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    organization_unique_id: false,
                    platform: false,
                  },
                },
              }}
            />
          )}
        </LoadingIndicator>
      </Grid>

      {/* Pagination */}
      {/* <Grid
        display="flex"
        justifyContent="center"
        container
        item
        lg={10}
        md={12}
        sm={12}
        className="pagination_section"
      >
        {" "}
        <Grid item lg={8} md={12} sm={12} xs={12}>
          {props.organization.hits
            ? props.organization.hits.length !== 0 && (
                <PaginationUI
                  pagelength={props.pagination.total_page_no}
                  page={props.pagination.current_page_no}
                  handleChange={handleChange}
                />
              )
            : null}
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(Organization);
