import QuestionReducer from "./questions/QuestionReducer";
import QuestionFilterReducer from "./questions/QuestionsFilterReducer";
import SurveyReducer from "./survey/SurveyReducer";
import surveyFilterReducer from "./survey/surveyFilterReducer";
import SurveyDataFilterReduser from "./surveyData/SurveyDataFilterReduser";
import SurveyDataRduser from "./surveyData/SurveyDataRduser";
import SurveyReportReducer from "./surveyManagementReport/SurveyReportReducer";
import surveyDeviceResetReducer from "./surveyMobileDeviceReset/surveyDeviceResetReducer";
import SurveyUserByIdReducer from "./surveyUser/SurveyUserByIdReducer";
import SurveyUserPageLimitNoneReducer from "./surveyUser/SurveyUserPageLimitNoneReducer";
import SurveyUserReducer from "./surveyUser/SurveyUserReducer";
import SurveyUserMapReducer from "./surveyUserMapping/SurveyUserMapReducer";

export const surveyRootReducer = {
  //survey
  survey: new SurveyReducer().reducer,
  surveyFilter: new surveyFilterReducer().reducer,

  //SurveY user
  surveyUser: new SurveyUserReducer().reducer,
  surveyUserFilter: new SurveyUserPageLimitNoneReducer().reducer,
  surveyUserById: new SurveyUserByIdReducer().reducer,

  //Survey Data
  surveyData: new SurveyDataRduser().reducer,
  surveyDataFilter: new SurveyDataFilterReduser().reducer,

  surveyReport: new SurveyReportReducer().reducer,

  //Device reset reducer for survey mobile app
  surveyMappedDevice: new surveyDeviceResetReducer().reducer,

  //survey User mapp
  surveyUserMap: new SurveyUserMapReducer().reducer,

  //questions
  questions: new QuestionReducer().reducer,
  questionsFilter: new QuestionFilterReducer().reducer,
};
