import React, { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import environment from "environment";
import MuiTextField from "../../../components/muiTextField/MuiTextField";
import PlatformAccountsAction from "../../stores/platformAccounts/PlatformAccountsAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import TelegramAccountAction from "../../stores/telegramAccount/TelegramAccountAction";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const AddTelegramAccount = (props) => {
  // Math.floor(Math.random() * (999 - 100 + 1) + 100)
  // Project post and Put method

  const [loading, setLoading] = useState(false);

  let initial = {};
  initial = {
    account_name: "",
    telegram_number: "",
    isAuthSubmitted: false,
    phone_code_hash: "",
    login_code: "",
    // project: "",
    // token: "",
    // waba_id: "",
    // phone_number_id: "",
  };

  const triggerTelegramOTP = (phone_number, setFieldValue) => {
    setFieldValue("isAuthSubmitted", false);
    phone_number && setLoading(true);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let body_obj = { phone_number: Number(phone_number) };

    {
      phone_number &&
        fetch(`${environment.api.baseApi}/telegram_get_otp`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + access_tocken,
            // Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body_obj),
        })
          .then((res) => res.json())
          .then((response) => {
            setFieldValue("phone_code_hash", response.hash_key);
            setFieldValue("isAuthSubmitted", true);
            setLoading(false);
          })
          .catch((error) => {
            if (error) {
              setLoading(false);
            }

            // setFieldValue("pincode_error", error);
          });
    }
  };

  const handlePhoneKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        // validationSchema={validationSchema}
        validate={(values) => {
          var errors = {};
          if (!values.isAuthSubmitted && !values.telegram_number) {
            errors.telegram_number = "Number is Required";
          }

          if (values.isAuthSubmitted && !values.phone_code_hash) {
            errors.phone_code_hash = "phone code hash is Required";
          }

          if (values.isAuthSubmitted && !values.login_code) {
            errors.login_code = "login code is Required";
          }

          if (values.isAuthSubmitted && !values.account_name) {
            errors.account_name = "login code is Required";
          }

          return errors;
        }}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        onSubmit={(values) => {
          if (values.isAuthSubmitted) {
            let post_obj = {
              phone_number: Number(values.telegram_number),
              login_code: Number(values.login_code),
              phone_code_hash: values.phone_code_hash,
              account_name: values.account_name,
            };
            props
              .dispatch(
                TelegramAccountAction.requestPostTelegramAuth(
                  JSON.stringify(post_obj)
                )
              )
              .then(() => {
                let params = {
                  page_limit: "none",
                };
                let account_params = {
                  page_limit: "none",
                  order_by: `[["account_name.keyword", "asc"]]`,
                };
                props.onClose();
                props.setAdd_account("");
                props.dispatch(
                  PlatformAccountsAction.requestPlatformAccount(account_params)
                );
                props.dispatch(
                  PlatformAccountsAction.requestPlatformAccountFilter(params)
                );
                // setFieldValue("isAuthSubmitted", false);
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          submitForm,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ marginLeft: "15%", width: "75%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-11">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">A</span>
                          dd &nbsp;
                          <span className="span_first_letter">T</span>elegram
                          &nbsp;
                          <span className="span_first_letter">A</span>ccount
                          &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div className="col-1 closebtn">
                      <i
                        title="Close"
                        className="fa fa-times-circle-o fa-2x"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                          props.setAdd_account("");
                          setLoading(false);
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>
              {!values.isAuthSubmitted && (
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Telegram Number :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      autoFocus
                      type="text"
                      id="telegram_number"
                      size="small"
                      name="telegram_number"
                      placeholder="Telegram Number"
                      error={
                        Boolean(errors.telegram_number) &&
                        Boolean(touched.telegram_number)
                      }
                      helperText={
                        Boolean(touched.telegram_number) &&
                        errors.telegram_number
                      }
                      handleKeyPress={handlePhoneKeyPress}
                    />
                  </Grid>
                </Grid>
              )}

              {!values.isAuthSubmitted && (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  // className="bg-warning"
                  justifyContent={"center"}
                  alignItems="center"
                  my={1}
                >
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={3}
                    // className="bg-success"
                    textAlign={"center"}
                  >
                    <MuiButton
                      // disabled={values.boolean_pincode_fetch}
                      width={"100%"}
                      name={"Submit"}
                      type="submit"
                      onClick={() => {
                        triggerTelegramOTP(
                          values.telegram_number,
                          setFieldValue
                        );
                      }}
                      loading={loading}
                      noTextOnLoading={true}
                    />
                  </Grid>
                </Grid>
              )}

              {values.isAuthSubmitted && (
                <Grid item lg={12} md={12} sm={12} my={1} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Login Code :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      variant="outlined"
                      as={MuiTextField}
                      type="text"
                      id="login_code"
                      size="small"
                      name="login_code"
                      placeholder="Login code"
                      error={
                        Boolean(errors.login_code) &&
                        Boolean(touched.login_code)
                      }
                      helperText={
                        Boolean(touched.login_code) && errors.login_code
                      }
                    />
                  </Grid>
                </Grid>
              )}

              {values.isAuthSubmitted && (
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} my={1} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      Account Name :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5}>
                      <Field
                        variant="outlined"
                        as={MuiTextField}
                        id="account_name"
                        // autoFocus
                        size="small"
                        name="account_name"
                        placeholder="Account Name"
                        error={
                          Boolean(errors.account_name) &&
                          Boolean(touched.account_name)
                        }
                        helperText={
                          Boolean(touched.account_name) && errors.account_name
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </DialogContent>

            {values.isAuthSubmitted && (
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
                mb={2}
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    name={"Add Account "}
                    type="submit"
                    width="100%"
                    // onClick={() => {
                    //   submitForm();
                    // }}
                  />
                </Grid>

                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Cancel"}
                    width="100%"
                    onClick={() => {
                      props.onClose();
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AddTelegramAccount);
