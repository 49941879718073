import { BaseModel } from "sjs-base-model";
import TicketsSourceModel from "./TicketsSourceModel";

export default class TicketsHitsDataModel extends BaseModel {
  //ticket list hits data model
  _id = "";
  _index = "";
  _score = "";
  _source = { TicketsSourceModel };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
