import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import ReportTemplateModel from "./models/ReportTemplateModel";
import ReportTemplatePostDataModel from "./models/ReportTemplatePostDataModel";
import ReportTemplatePutDataModel from "./models/ReportTemplatePutDataModel";
import ReportsByIdModel from "./models/ReportsByIdModel";

export default class ReportTemplateEffect {
  // get reportTemplate request endpoint
  static async requestReportTemplate(params, callBack) {
    const endpoint = environment.api.reportTemplate;

    let response = EffectUtility.getToModel(
      ReportTemplateModel,
      endpoint,
      params
    );

    response.then((resp) => {
      if (resp.hits.hits && callBack) {
        callBack(resp);
      }
    });

    return response;
  }

  // get reportTemplate request endpoint
  static async requestReportTemplateFilter(params) {
    const endpoint = environment.api.reportTemplateFilter;
    return EffectUtility.getToModel(ReportTemplateModel, endpoint, params);
  }

  //get reportTemplate post api
  static async requestPostReportTemplate(data) {
    const endpoint = environment.api.reportTemplate + "/add";
    return EffectUtility.postToModel(
      ReportTemplatePostDataModel,
      endpoint,
      data
    );
  }

  //get reportTemplate put api
  static async requestPutReportTemplate(data, id) {
    const endpoint = environment.api.reportTemplate + "/" + id;

    return EffectUtility.putToModel(ReportTemplatePutDataModel, endpoint, data);
  }

  static async requestUpdateOrgReportTemplate(data, id, params) {
    const endpoint = environment.api.UpdateOrgReportTemplate;

    return EffectUtility.putToModel(
      ReportTemplatePutDataModel,
      endpoint,
      data,
      params
    );
  }

  //get reports request by id end point
  static async requestReportsById(id) {
    const endpoint = environment.api.reportTemplate + "/" + id;

    return EffectUtility.getToModel(ReportsByIdModel, endpoint);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }
}
