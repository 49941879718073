import BaseReducer from "../../../utilities/BaseReducer";
import YoutubeAction from "./YoutubeAction";

export default class YoutubeFeedByIdReducer extends BaseReducer {
  initialState = {
    youtubeFeedById: [],
  };

  [YoutubeAction.REQUEST_YOUTUBE_FEEDS_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      youtubeFeedById: action.payload,
    };
  }
}
