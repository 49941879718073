import ActionUtility from "../../../utilities/ActionUtility";
import QuoraAccountEffect from "./QuoraAccountEffect";

export default class QuoraAccountAction {
  // QuoraAccountAction with an function declaration
  static REQUEST_QUORA_ACCOUNT = "QuoraAccountAction.REQUEST_QUORA_ACCOUNT";
  static REQUEST_QUORA_ACCOUNT_FINISHED =
    "QuoraAccountAction.REQUEST_QUORA_ACCOUNT_FINISHED";

  static REQUEST_QUORA_ACCOUNT_FILTER =
    "QuoraAccountAction.REQUEST_QUORA_ACCOUNT_FILTER";
  static REQUEST_QUORA_ACCOUNT_FILTER_FINISHED =
    "QuoraAccountAction.REQUEST_QUORA_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_QUORA_ACCOUNT =
    "QuoraAccountAction.REQUEST_PUT_QUORA_ACCOUNT";
  static REQUEST_PUT_QUORA_ACCOUNT_FINISHED =
    "QuoraAccountAction.REQUEST_PUT_QUORA_ACCOUNT_FINISHED";

  static REQUEST_POST_QUORA_ACCOUNT =
    "QuoraAccountAction.REQUEST_POST_QUORA_ACCOUNT";
  static REQUEST_POST_QUORA_ACCOUNT_FINISHED =
    "QuoraAccountAction.REQUEST_POST_QUORA_ACCOUNT_FINISHED";

  static REQUEST_DELETE_QUORA_ACCOUNT =
    "QuoraAccountAction.REQUEST_DELETE_QUORA_ACCOUNT";
  static REQUEST_DELETE_QUORA_ACCOUNT_FINISHED =
    "QuoraAccountAction.REQUEST_DELETE_QUORA_ACCOUNT_FINISHED";

  // METHODS
  // Quora Get function
  static requestQuoraAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraAccountAction.REQUEST_QUORA_ACCOUNT,
        QuoraAccountEffect.requestQuoraAccount,
        params
      );
    };
  }

  static requestQuoraAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraAccountAction.REQUEST_QUORA_ACCOUNT_FILTER,
        QuoraAccountEffect.requestQuoraAccountFilter,
        params
      );
    };
  }
  static requestPutQuoraAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraAccountAction.REQUEST_PUT_QUORA_ACCOUNT,
        QuoraAccountEffect.requestPutQuoraAccount,
        data,
        id
      );
    };
  }

  // Quora post function
  static requestPostQuoraAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraAccountAction.REQUEST_POST_QUORA_ACCOUNT,
        QuoraAccountEffect.requestPostQuoraAccount,
        data
      );
    };
  }

  static requestDeleteQuoraAccount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        QuoraAccountAction.REQUEST_DELETE_QUORA_ACCOUNT,
        QuoraAccountEffect.requestDeleteQuoraAccount,
        data
      );
    };
  }
}
