import React, { forwardRef } from "react";
import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { theme } from "../../views/App";

// Custom component with scrollbar
const CustomListbox = forwardRef((props, ref) => (
  <Paper
    {...props}
    ref={ref}
    sx={{
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#b0d2da",
        borderRadius: "3px",
        "&:hover": {
          backgroundColor: "#85bac6",
        },
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
    }}
  />
));

// Autocomplete component function
const AutoCompleteComponent = forwardRef(
  (
    {
      renderOption,
      disableCloseOnSelect,
      getOptionLabel,
      list,
      getOptionDisabled,
      name,
      textFieldProps,
      handleChange,
      error,
      clear,
      size,
      width,
      disabled,
      helperText,
      value,
      disableClearable,
      multiple,
      filterOptions,
      defaultValue,
      isOptionEqualToValue,
      limitTags,
      renderTags,
      fieldheight,
      disablePortal,
      firstComponent,
      lastComponent,
      diasbleSvg,
      svgColor,
    },
    ref
  ) => {
    const useStyles = makeStyles({
      root: {
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: 2,
        marginBottom: 2,
        zIndex: 1,
        overflow: "hidden",
        width: "auto",
        maxWidth: "fit-content",
        minWidth: "max-content",
        display: "flex",
        position: "absolute",
        right: lastComponent && 0,
        left: firstComponent && 0,
      },
    });

    const classes = useStyles();

    return (
      //Added the box width
      <Box sx={{ width: !width ? 200 : width }}>
        <Autocomplete
          classes={{
            paper: classes.root,
          }}
          MenuProps={{
            style: {
              // Adjust the width based on your requirements
              backgroundColor: "gray",
            },
          }}
          style={{ zIndex: 1 }}
          // multiple={true}
          disablePortal={disablePortal ? disablePortal : false}
          disableCloseOnSelect={disableCloseOnSelect}
          defaultValue={defaultValue}
          getOptionLabel={getOptionLabel}
          value={value}
          tag
          ListboxComponent={CustomListbox}
          limitTags={limitTags ? limitTags : 10}
          disableClearable={disableClearable}
          isOptionEqualToValue={isOptionEqualToValue}
          multiple={multiple}
          disabled={disabled}
          clearOnBlur={true}
          ref={ref}
          // id="combo-box-demo" // otherwise drop down will open inside dailog box
          options={list}
          getOptionSelected={(item, current) => item.value === current.value}
          getOptionDisabled={getOptionDisabled}
          renderOption={renderOption}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              helperText={helperText}
              size={size}
              error={error}
              name={name}
              sx={{
                // '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                //   border : `1.5px solid ${theme.palette.primary.main}`,
                // },
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                svg: {
                  display: diasbleSvg ? diasbleSvg : "block",
                  color: svgColor ? svgColor : "#bab8b8",
                },
                height: fieldheight ? fieldheight : "100%",
              }}
            />
          )}
          onChange={handleChange}
          renderTags={renderTags}
        />
      </Box>
    );
  }
);

export default AutoCompleteComponent;
