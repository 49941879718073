import React, { useEffect, useRef, useState } from "react";
import environment from "environment";
import { Box, Grid, ListItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import PaginationUI from "../../../components/pagination/Pagination";

import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { CoPresentOutlined } from "@mui/icons-material";

function BridgeSummary(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [roleSummaryData, setRoleSummaryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const roundToThreeDec = (decimal) => {
    const number = decimal;
    const roundedNumber = number.toFixed(3);
    return roundedNumber;
  };

  const returnWorkingHours = (hours) => {
    return hours !== 0 ? roundToThreeDec(hours) : 0;
  };

  let columns = [
    { field: "id", headerName: "Id", resizable: false, width: 50, hide: true },
    {
      field: "user_name",
      headerName: "Assigned To",
      width: 150,
      resizable: true,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 100,
      resizable: true,
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "assigned_tickets",
      headerName: "Assigned Tickets",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },

    {
      field: "cummulative",
      headerName: "Tickets",
      resizable: true,
      align: "left",
      headerAlign: "center",
      flex: 1.5,
      renderCell: (params) => (
        <Box
          sx={{
            height: "100%",
            width: "auto",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
          }}
          style={{
            marginHorizontal: "5%",
          }}
        >
          <ListItem
            // title={`Assigned tickets : ${params.row.assigned_tickets}`}
            sx={{
              width: "auto",
              cursor: "pointer",
              textAlign: "left",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            // className="bg-success"
          >
            <Tooltip title={`Assigned :${params.row.assigned_tickets}`}>
              <Box
                sx={{
                  // width: `${
                  //   (params.row.assigned_tickets /
                  //     (params.row.assigned_tickets +
                  //       params.row.assigned_tickets)) *
                  //   100
                  // }%`,
                  width: "auto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  backgroundColor: "#6BA5B2",
                  color: "black",
                  textAlign: "center",
                  paddingX: 1,
                  borderBottomLeftRadius: "4px",
                  borderTopLeftRadius: "4px",
                }}
              >
                {params.row.assigned_tickets}
              </Box>
            </Tooltip>
            <Tooltip title={`Resolved :${params.row.resolved_tickets}`}>
              <Box
                sx={{
                  // width: `${
                  //   (params.row.resolved_tickets /
                  //     (params.row.assigned_tickets * 2)) *
                  //   100
                  // }%`,
                  width: "auto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  backgroundColor: "#bab8b8",
                  color: "black",
                  textAlign: "center",
                  paddingX: 1,
                }}
              >
                {params.row.resolved_tickets}
              </Box>
            </Tooltip>
            <Tooltip title={`UnResolved :${params.row.unresolved_tickets}`}>
              <Box
                sx={{
                  // width: `${
                  //   (params.row.unresolved_tickets /
                  //     (params.row.assigned_tickets * 2)) *
                  //   100
                  // }%`,
                  width: "auto",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  backgroundColor: "#e5e2e2",
                  color: "black",
                  textAlign: "center",
                  paddingX: 1,
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                {params.row.unresolved_tickets}
              </Box>
            </Tooltip>
          </ListItem>
        </Box>
      ),
    },
    //
    {
      field: "resolved_tickets",
      headerName: "Resolved Tickets",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },

    {
      field: "unresolved_tickets",
      headerName: "UnResolved Tickets",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
      hide: true,
    },
  ];

  columns.push(
    {
      field: "frt",
      headerName: "Avg FRT",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "beyond_frt",
      headerName: "FRT Breach",
      width: 100,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "working_hours",
      headerName: "Active Hrs",
      width: 150,
      resizable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    }
  );
  const HoursAndMinutes = (decimal) => {
    let number = decimal ? decimal : 0;
    const hours = Math.floor(number); // Extract the whole number part (hours)
    const minutes = Math.floor((number - hours) * 60); // Convert the decimal part to minutes
    const seconds = Math.floor(((number - hours) * 60 - minutes) * 60); // Convert the decimal part to seconds

    return decimal
      ? `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      : "00:00:00";
  };
  console.log("hi");
  let updatedRows = [
    {
      id: 1,
      user_name: "agent1",
      role_name: "agent",
      assigned_tickets: "18",
      resolved_tickets: "15",
      unresolved_tickets: "03",
      frt: "00:16:53",
      tat: "163:10:20",
      within_frt: 7,
      within_tat: 5,
      beyond_frt: "06",
      beyond_tat: 12,
      working_hours: "09:30:35",
      Open: 2,
      Close: 15,
      test: 1,
      pending: 0,
    },
    {
      id: 2,
      user_name: "rakesh",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "17",
      resolved_tickets: "16",
      unresolved_tickets: "01",
      frt: "36:00:40",
      tat: "71:56:39",
      within_frt: 5,
      within_tat: 10,
      beyond_frt: "03",
      beyond_tat: 6,
      working_hours: "126:22:04",
      Open: 1,
      Close: 16,
      test: 0,
      pending: 0,
    },
    {
      id: 3,
      user_name: "yash",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "16",
      resolved_tickets: "12",
      unresolved_tickets: "04",
      frt: "00:00:17",
      tat: "47:24:36",
      within_frt: 2,
      within_tat: 7,
      beyond_frt: "00",
      beyond_tat: 3,
      working_hours: "135:40:58",
      Open: 4,
      Close: 12,
      test: 0,
      pending: 0,
    },
    {
      id: 4,
      user_name: "panduranga",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "13",
      resolved_tickets: "12",
      unresolved_tickets: "01",
      frt: "00:00:00",
      tat: "254:55:12",
      within_frt: 0,
      within_tat: 0,
      beyond_frt: "00",
      beyond_tat: 2,
      working_hours: "05:16:54",
      Open: 1,
      Close: 12,
      test: 0,
      pending: 0,
    },
    {
      id: 5,
      user_name: 0,
      role_name: "vaniel_orgadmin",
      assigned_tickets: "11",
      resolved_tickets: "10",
      unresolved_tickets: "01",
      frt: "00:00:11",
      tat: "00:00:46",
      within_frt: 3,
      within_tat: 7,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "00:00:02",
      Open: 0,
      Close: 10,
      test: 1,
      pending: 0,
    },
    {
      id: 6,
      user_name: "vivek",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "09",
      resolved_tickets: "00",
      unresolved_tickets: "09",
      frt: "00:00:00",
      tat: "00:00:35",
      within_frt: 0,
      within_tat: 2,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "48:34:38",
      Open: 9,
      Close: 0,
      test: 0,
      pending: 0,
    },
    {
      id: 8,
      user_name: 0,
      role_name: "vaniel_orgadmin",
      assigned_tickets: "08",
      resolved_tickets: "08",
      unresolved_tickets: "00",
      frt: "00:00:00",
      tat: "00:08:59",
      within_frt: 1,
      within_tat: 3,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "00:00:00",
      Open: 0,
      Close: 8,
      test: 0,
      pending: 0,
    },
    {
      id: 9,
      user_name: "sachin",
      role_name: "Testing Agent Role",
      assigned_tickets: "07",
      resolved_tickets: "05",
      unresolved_tickets: "02",
      frt: "00:04:56",
      tat: "154:24:59",
      within_frt: 4,
      within_tat: 0,
      beyond_frt: "00",
      beyond_tat: 6,
      working_hours: "10:33:21",
      Open: 2,
      Close: 5,
      test: 0,
      pending: 0,
    },
    {
      id: 10,
      user_name: "karthika",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "07",
      resolved_tickets: "07",
      unresolved_tickets: "00",
      frt: "00:03:00",
      tat: "23:49:48",
      within_frt: 4,
      within_tat: 6,
      beyond_frt: "00",
      beyond_tat: 1,
      working_hours: "115:25:18",
      Open: 0,
      Close: 7,
      test: 0,
      pending: 0,
    },
    {
      id: 11,
      user_name: "admin",
      role_name: "Main_Admin",
      assigned_tickets: "07",
      resolved_tickets: "07",
      unresolved_tickets: "00",
      frt: "00:00:53",
      tat: "72:30:30",
      within_frt: 4,
      within_tat: 4,
      beyond_frt: "00",
      beyond_tat: 3,
      working_hours: "42:41:10",
      Open: 0,
      Close: 7,
      test: 0,
      pending: 0,
    },
    {
      id: 12,
      user_name: "user2",
      role_name: null,
      assigned_tickets: "04",
      resolved_tickets: "04",
      unresolved_tickets: "00",
      frt: "00:00:00",
      tat: "00:21:54",
      within_frt: 0,
      within_tat: 4,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "01:27:11",
      Open: 0,
      Close: 4,
      test: 0,
      pending: 0,
    },
    {
      id: 13,
      user_name: "ajit",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "04",
      resolved_tickets: "03",
      unresolved_tickets: "01",
      frt: "00:01:02",
      tat: "51:26:23",
      within_frt: 4,
      within_tat: 2,
      beyond_frt: "00",
      beyond_tat: 2,
      working_hours: "00:00:00",
      Open: 0,
      Close: 3,
      test: 0,
      pending: 1,
    },
    {
      id: 14,
      user_name: "agent2",
      role_name: "agent",
      assigned_tickets: "03",
      resolved_tickets: "02",
      unresolved_tickets: "01",
      frt: "00:45:47",
      tat: "139:33:23",
      within_frt: 0,
      within_tat: 1,
      beyond_frt: "03",
      beyond_tat: 2,
      working_hours: "00:00:00",
      Open: 0,
      Close: 2,
      test: 0,
      pending: 1,
    },
    {
      id: 15,
      user_name: "smruthiorg",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "02",
      resolved_tickets: "01",
      unresolved_tickets: "01",
      frt: "00:00:35",
      tat: "170:39:53",
      within_frt: 1,
      within_tat: 1,
      beyond_frt: "00",
      beyond_tat: 1,
      working_hours: "21:08:51",
      Open: 0,
      Close: 1,
      test: 1,
      pending: 0,
    },
    {
      id: 16,
      user_name: "abhiram",
      role_name: "vaniel_orgadmin",
      assigned_tickets: "02",
      resolved_tickets: "00",
      unresolved_tickets: "02",
      frt: "00:00:00",
      tat: "00:00:00",
      within_frt: 0,
      within_tat: 0,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "82:06:18",
      Open: 2,
      Close: 0,
      test: 0,
      pending: 0,
    },
    {
      id: 17,
      user_name: "agent3",
      role_name: "agent",
      assigned_tickets: "01",
      resolved_tickets: "01",
      unresolved_tickets: "00",
      frt: "00:50:23",
      tat: "317:07:11",
      within_frt: 0,
      within_tat: 0,
      beyond_frt: "01",
      beyond_tat: 1,
      working_hours: "00:00:00",
      Open: 0,
      Close: 1,
      test: 0,
      pending: 0,
    },
    {
      id: 18,
      user_name: 0,
      role_name: "",
      assigned_tickets: "01",
      resolved_tickets: "00",
      unresolved_tickets: "01",
      frt: "00:00:00",
      tat: "00:00:00",
      within_frt: 0,
      within_tat: 1,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "00:00:00",
      Open: 0,
      Close: 0,
      test: 0,
      pending: 0,
    },
    {
      id: 19,
      user_name: 0,
      role_name: "",
      assigned_tickets: "01",
      resolved_tickets: "01",
      unresolved_tickets: "00",
      frt: "00:00:00",
      tat: "00:00:00",
      within_frt: 0,
      within_tat: 0,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "00:00:00",
      Open: 0,
      Close: 1,
      test: 0,
      pending: 0,
    },
    {
      id: 20,
      user_name: "kuser5",
      role_name: "agent",
      assigned_tickets: "01",
      resolved_tickets: "01",
      unresolved_tickets: "00",
      frt: "00:00:00",
      tat: "01:02:59",
      within_frt: 1,
      within_tat: 1,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "00:00:00",
      Open: 0,
      Close: 1,
      test: 0,
      pending: 0,
    },
    {
      id: 21,
      user_name: "user1",
      role_name: null,
      assigned_tickets: "01",
      resolved_tickets: "01",
      unresolved_tickets: "00",
      frt: "00:00:00",
      tat: "00:00:00",
      within_frt: 0,
      within_tat: 1,
      beyond_frt: "00",
      beyond_tat: 0,
      working_hours: "02:30:02",
      Open: 0,
      Close: 1,
      test: 0,
      pending: 0,
    },
    {
      id: 22,
      user_name: "agent4",
      role_name: "agent",
      assigned_tickets: "01",
      resolved_tickets: "01",
      unresolved_tickets: "00",
      frt: "00:51:00",
      tat: "00:51:00",
      within_frt: 0,
      within_tat: 1,
      beyond_frt: "01",
      beyond_tat: 0,
      working_hours: "00:00:26",
      Open: 0,
      Close: 1,
      test: 0,
      pending: 0,
    },
  ];
  const totalRows = updatedRows && updatedRows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event, value) => {
    setCurrentPage(value - 1);
  };
  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const finalRows = updatedRows && updatedRows.slice(startIndex, endIndex);

  const onCellClick = (params, event) => {
    event.stopPropagation();
  };

  return props.dataGridHeight ? (
    <LoadingIndicator isActive={loading}>
      <Box height={"100%"}>
        {!loading ? (
          <Box height={"100%"}>
            {!loading &&
            updatedRows.length > 0 &&
            props.dataGridHeight.current ? (
              <Box
                sx={{
                  padding: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{ height: 400 }}
                >
                  {console.log("rows", props.dataGridHeight.current)}
                  {props.dataGridHeight.current && (
                    <DataGridFunction
                      rows={updatedRows}
                      columns={columns}
                      height={props.dataGridHeight.current}
                      rowHeight={50}
                      headerHeight={50}
                      onCellClick={onCellClick}
                      rowBorder={false}
                      rowColor={true}
                      headerColor={true}
                    />
                  )}
                </Grid>
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  ) : null;
}

export default BridgeSummary;
