import { BaseModel } from "sjs-base-model";
import AmbitionBoxFeedsDataHitsmodel from "./AmbitionBoxFeedsDataHitsmodel";

//AmbitionBoxFeedsHitsModel
export default class AmbitionBoxFeedsHitsModel extends BaseModel {
  // response getting from the api mentioned end point
  hits = [{ AmbitionBoxFeedsDataHitsmodel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
