import ActionUtility from "../../utilities/ActionUtility";
import PageTaggedPostsEffect from "./PageTaggedPostsEffect";

export default class PageTaggedPostsAction {
  static REQUEST_PAGE_TAGGED_POST =
    "PageTaggedPostsAction.REQUEST_PAGE_TAGGED_POST";
  static REQUEST_PAGE_TAGGED_POST_FINISHED =
    "PageTaggedPostsAction.REQUEST_PAGE_TAGGED_POST_FINISHED";

  //METHODS

  static requestPageTaggedPosts(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        PageTaggedPostsAction.REQUEST_PAGE_TAGGED_POST,
        PageTaggedPostsEffect.requestPageTaggedPosts,
        params
      );
    };
  }
}
