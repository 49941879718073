import BaseReducer from "../../../utilities/BaseReducer";
import CloudMailListAction from "./CloudMailListAction";

// OrganizationReduser
export default class CloudMailListReducer extends BaseReducer {
  initialState = {
    cloudMailList: [],
  };
  [CloudMailListAction.REQUEST_CLOUD_MAIL_LIST_FINISHED](state, action) {
    return {
      ...state,
      cloudMailList: action.payload,
    };
  }
}
