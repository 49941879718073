import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import HttpUtility from "../../../utilities/HttpUtility";
import EffectUtility from "../../../utilities/EffectUtility";
import AssignmentRuleGetModel from "./models/AssignementRuleGetModel";
import AssignmentRulePostModel from "./models/AssignmentRulePostModel";
import AssignmentRulePutModel from "./models/AssignmentRulePutModel";
import AssignmentRulePutBulkUpdate from "./models/AssignmentRulePutBulkUpdate";
import AutomationOperationsModel from "./models/AutomationOperationsModel";
import AutomationFieldsModel from "./models/AutomationFieldsModel";

export default class AutoresponseEffect {
  static async requestAutomationOperations(params) {
    const endpoint = environment.api.automationOperations;
    return EffectUtility.getToModel(
      AutomationOperationsModel,
      endpoint,
      params
    );
  }

  static async requestAutomationFields(params) {
    const endpoint = environment.api.automationFields;
    return EffectUtility.getToModel(AutomationFieldsModel, endpoint, params);
  }
  static async requestAutorespons(params) {
    const endpoint = environment.api.autoresponse;
    return EffectUtility.getToModel(AssignmentRuleGetModel, endpoint, params);
  }

  static async requestAutoresponsFilter(params) {
    const endpoint = environment.api.autoresponse;
    return EffectUtility.getToModel(AssignmentRuleGetModel, endpoint, params);
  }

  static async requestPostAutoresponse(data) {
    const endpoint = environment.api.autoresponse;
    return EffectUtility.postToModel(AssignmentRulePostModel, endpoint, data);
  }

  static async requestPutAutorespons(data, id) {
    const endpoint = environment.api.autoresponse + "/" + id;
    return EffectUtility.putToModel(AssignmentRulePutModel, endpoint, data);
  }

  // Put method for the Tag bulk update
  static async requestPutAutoresponseBulkUpdate(data) {
    const endpoint = environment.api.autoresponseBulkUpdate;
    return EffectUtility.putToModel(
      AssignmentRulePutBulkUpdate,
      endpoint,
      data
    );
  }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
