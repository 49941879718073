import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";
import LastProjectSourceModel from "./LastProjectSourceModel";

export default class TicketByUserLastWorkingProjectByIdModeldModel extends BaseModel {
  //ticket list hits model
  _id = "";
  _index = "";
  _primary_term = "";
  _seq_no = 0;
  _source = { LastProjectSourceModel };
  _version = 0;
  found = boolean;
  message = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
