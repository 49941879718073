import BaseReducer from "../../../utilities/BaseReducer";
import GlassdoorAccountAction from "./GlassdoorAccountAction";

// GlassdoorAccountReduser  function
export default class GlassdoorAccountReduser extends BaseReducer {
  initialState = {
    glassdoorAccount: [],
  };
  [GlassdoorAccountAction.REQUEST_GLASSDOOR_ACCOUNT_FINISHED](state, action) {
    return {
      ...state,
      glassdoorAccount: action.payload,
    };
  }
}
