import BaseReducer from "../../../../utilities/BaseReducer";
import CampaignMappingAction from "./CampaignMappingAction";

// reducer function
export default class CampaignMappingReducer extends BaseReducer {
  initialState = {
    campaignMapping: [],
  };

  [CampaignMappingAction.REQUEST_CAMPAIGN_MAPPING_FINISHED](state, action) {
    return {
      ...state,
      campaignMapping: action.payload,
    };
  }
}
