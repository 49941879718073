import BaseReducer from "../../../utilities/BaseReducer";
import FaqAction from "./FaqAction";

export default class FaqTopicsReducer extends BaseReducer {
  initialState = {
    faqTopics: [],
  };
  [FaqAction.REQUEST_GET_FAQ_TOPICS_FINISHED](state, action) {
    return {
      ...state,
      faqTopics: action.payload,
    };
  }
}
