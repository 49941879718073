import { BaseModel } from "sjs-base-model";

export default class LastSyncDateModal extends BaseModel {
  _shards = {
    failed: 0,
    skipped: 0,
    successful: 1,
    total: 1,
  };
  current_page_no = 0;
  hits = {
    hits: [
      {
        _id: "",
        _index: "",
        _source: {
          last_sync_date: Date,
          platform_name: "",
        },
      },
    ],
  };

  constructor(data1) {
    super();

    this.update(data1);
  }
}
