import environment from "environment";
import InfluencersActivityModel from "./model/InfluencersActivityModel";
import InfluencersActivityPostModel from "./model/InfluencersActivityPostModel";
import InfluencersActivityPutModel from "./model/InfluencersActivityPutModel";
import EffectUtility from "../../../../utilities/EffectUtility";

// InfluencersActivityEffect
export default class InfluencersActivityEffect {
  // calling an API according to the Influencers model
  // Get method for the
  static async requestInfluencersActivity(params) {
    const endpoint = environment.api.influencersActivity;
    return EffectUtility.getToModel(InfluencersActivityModel, endpoint, params);
  }

  // Get method for the Organization filter
  static async requestInfluencersActivityFilter(params) {
    const endpoint = environment.api.influencersActivityFilter;
    return EffectUtility.getToModel(InfluencersActivityModel, endpoint, params);
  }

  static async requestInfluencersActivityReports(params) {
    const endpoint = environment.api.influencersActivityFilter;
    return EffectUtility.getToModel(InfluencersActivityModel, endpoint, params);
  }

  // put method for the Organization
  static async requestPutInfluencersActivity(data, id) {
    const endpoint = environment.api.influencersActivity + "/" + id;
    return EffectUtility.putToModel(
      InfluencersActivityPutModel,
      endpoint,
      data
    );
  }

  // post method for the Organization
  static async requestPostInfluencersActivity(data) {
    const endpoint = environment.api.influencersActivity + "/add";

    return EffectUtility.postToModel(
      InfluencersActivityPostModel,
      endpoint,
      data
    );
  }
}
