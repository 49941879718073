import BaseReducer from "../../../utilities/BaseReducer";
import TrustpilotFeedsAction from "./TrustpilotFeedsAction";

export default class TrustpilotFeedsReduser extends BaseReducer {
  initialState = {
    trustPilotFeeds: [],
  };

  [TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      trustPilotFeeds: action.payload,
    };
  }

  [TrustpilotFeedsAction.REQUEST_TRUSTPILOT_FEEDS_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      trustPilotFeeds: action.payload,
    };
  }
}
