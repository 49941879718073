import { BaseModel } from "sjs-base-model";
import TicketWorkedOnCountBucketsModel from "./TicketWorkedOnCountBucketsModel";

export default class TicketWorkedOnCountModel extends BaseModel {
  //Ticket Worked on count data model

  buckets = [TicketWorkedOnCountBucketsModel];
  doc_count_error_upper_bound = 0;
  sum_other_doc_count = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
