import BaseReducer from "../../../utilities/BaseReducer";
import TicketReportsAction from "./TicketReportsAction";

export default class TicketReportsReducer extends BaseReducer {
  initialState = {
    ticketReports: [],
  };

  [TicketReportsAction.REQUEST_TICKET_REPORTS_FINISHED](state, action) {
    return {
      ...state,
      ticketReports: action.payload,
    };
  }
}
