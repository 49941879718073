import BaseReducer from "../../../../utilities/BaseReducer";
import ScrapeLinksAction from "./ScrapeLinksAction";

// ScrapeLinksReduser
export default class ScrapeLinksReduser extends BaseReducer {
  initialState = {
    scrapeLinks: [],
  };
  [ScrapeLinksAction.REQUEST_SCRAPELINKS_FINISHED](state, action) {
    return {
      ...state,
      scrapeLinks: action.payload,
    };
  }
}
