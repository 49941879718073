import BaseReducer from "../../../../utilities/BaseReducer";
import AssetsAction from "./AssetsAction";

export default class AssetsFilterReduser extends BaseReducer {
  //initial state of AssetsFilterReduser
  initialState = {
    assetsFilter: [],
  };
  //AssetsFilterReduser request action finish
  [AssetsAction.REQUEST_ASSETS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      assetsFilter: action.payload,
    };
  }
}
