import React, { useEffect, useState } from "react";
import environment from "environment";

import Logo from "./logoContainer/Logo";
import Description from "./descriptionContainer/Description";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { firstLetterOrange } from "../../../components/ticketPartials";

const CategoryWiseAvgFlr = (props) => {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData();
      setLoading(false);
    })();
  }, []);

  async function fetchData(month) {
    let API = `${environment.api.ticketReports}?report_type=["count"]&reports=["frt_custom_value"]`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });

    // return fetch(
    //   `${environment.api.ticketReports}?report_type=["count"]&reports=["frt_custom_value"]`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + accessToken,
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((response) => {
    //     setData(response);
    //   });
  }

  let categoryList = [];

  data &&
    data.ticket_report.frt_custom_value
      .filter((item) => Object.keys(item)[0] === props.filterValue)
      .map((value) => {
        Object.entries(value)[0][1].map((item) => {
          categoryList.push({ name: item.key, count: item.count });
        });
      });

  return (
    <div
      style={{
        width: "100%",
        padding: "10px",
        //  border: "1px solid grey"
      }}
    >
      <LoadingIndicator isActive={loading}>
        <Logo leftLogo={props.leftLogo} rightLogo={props.rightLogo} />
        <p
          style={{ fontSize: theme.reports.headerSize }}
          className="reportHeader"
        >
          {firstLetterOrange(props.report && props.report)}
        </p>
        <br />
        <div style={{ width: "50%", overflowX: "auto" }}>
          {categoryList.length > 0 && (
            <table
              style={{
                width: "100%",
                border: `1px solid ${theme.table.border}`,
                textAlign: "left",
              }}
              border="2"
            >
              <tbody style={{ width: "100%" }}>
                <tr
                  style={{
                    border: `1px solid ${theme.table.border}`,
                    color: `${theme.palette.text.primary}`,
                    backgroundColor: `${theme.palette.background.primary}`,
                  }}
                >
                  <th className="pl-5 pr-5">Category Name</th>
                  <th className="pl-5 pr-5">Count</th>
                </tr>

                {categoryList &&
                  categoryList.map((value, index) => (
                    <tr
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? `${theme.table.rowColor}` : "white",
                        color: `${theme.table.rowText}`,
                        border: `1px solid ${theme.table.border}`,
                      }}
                    >
                      <td className="pl-5">{value.name ? value.name : "-"}</td>
                      <td className="pl-5">{value.count}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        {window.location.pathname === "/ticketStatsReport" && (
          <Description
            description={props.description}
            callBack={props.callBack}
            report={props.report}
            index={0}
          />
        )}
      </LoadingIndicator>
    </div>
  );
};

export default CategoryWiseAvgFlr;
