import BaseReducer from "../../../utilities/BaseReducer";
import FrontEndResourceAction from "./FrontEndResourceAction";

export default class FrontEndResourceReducer extends BaseReducer {
  initialState = {
    frontEndResource: {},
  };

  [FrontEndResourceAction.REQUEST_FRONTEND_RESOURCE_FINISHED](state, action) {
    return {
      ...state,
      frontEndResource: action.payload,
    };
  }
}
