import BaseReducer from "../../../../utilities/BaseReducer";
import BatchLinksAction from "./BatchLinksAction";

export default class BatchLinksReducer extends BaseReducer {
  initialState = {
    batchLinks: [],
  };
  [BatchLinksAction.REQUEST_BATCH_LINKS_FINISHED](state, action) {
    return {
      ...state,
      batchLinks: action.payload,
    };
  }

  [BatchLinksAction.REQUEST_BATCH_LINKS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      batchLinks: action.payload,
    };
  }
}
