import ActionUtility from "../../../../utilities/ActionUtility";
import InfluencerUserEffect from "./InfluencerUserEffect";

export default class InfluencerUserAction {
  // InfluencerUserAction action with an function declaration
  static REQUEST_INFLUENCER_USER =
    "InfluencerUserAction.REQUEST_INFLUENCER_USER";
  static REQUEST_INFLUENCER_USER_FINISHED =
    "InfluencerUserAction.REQUEST_INFLUENCER_USER_FINISHED";

  static REQUEST_INFLUENCER_USER_FILTER =
    "InfluencerUserAction.REQUEST_INFLUENCER_USER_FILTER";
  static REQUEST_INFLUENCER_USER_FILTER_FINISHED =
    "InfluencerUserAction.REQUEST_INFLUENCER_USER_FILTER_FINISHED";

  static REQUEST_PUT_INFLUENCER_USER =
    "InfluencerUserAction.REQUEST_PUT_INFLUENCER_USER";
  static REQUEST_PUT_INFLUENCER_USER_FINISHED =
    "InfluencerUserAction.REQUEST_PUT_INFLUENCER_USER_FINISHED";

  static REQUEST_POST_INFLUENCER_USER =
    "InfluencerUserAction.REQUEST_POST_INFLUENCER_USER";
  static REQUEST_POST_INFLUENCER_USER_FINISHED =
    "InfluencerUserAction.REQUEST_POST_INFLUENCER_USER_FINISHED";

  //admin reset password
  static REQUEST_PUT_ADMIN_RESET_PASSWORD =
    "InfluencerUserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD";
  static REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED =
    "InfluencerUserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED";

  // USER Reset password
  static REQUEST_PUT_USER_RESET_PASSWORD =
    "InfluencerUserAction.REQUEST_PUT_USER_RESET_PASSWORD";
  static REQUEST_PUT_USER_RESET_PASSWORD_FINISHED =
    "InfluencerUserAction.REQUEST_PUT_USER_RESET_PASSWORD_FINISHED";

  // METHODS
  // InfluencersDashboard GET function
  static requestInfluencerUser(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerUserAction.REQUEST_INFLUENCER_USER,
        InfluencerUserEffect.requestInfluencerUser,
        params
      );
    };
  }

  static requestInfluencerUserFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerUserAction.REQUEST_INFLUENCER_USER_FILTER,
        InfluencerUserEffect.requestInfluencerUserFilter,
        params
      );
    };
  }

  static requestPutInfluencerUser(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerUserAction.REQUEST_PUT_INFLUENCER_USER,
        InfluencerUserEffect.requestPutInfluencerUser,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostInfluencerUser(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerUserAction.REQUEST_POST_INFLUENCER_USER,
        InfluencerUserEffect.requestPostInfluencerUser,
        data,
        callBack
      );
    };
  }

  static requestAdminResetPasswordPut(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerUserAction.REQUEST_PUT_ADMIN_RESET_PASSWORD,
        InfluencerUserEffect.requestAdminResetPasswordPut,
        data
      );
    };
  }

  static requestUserResetPassword(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        InfluencerUserAction.REQUEST_PUT_USER_RESET_PASSWORD,
        InfluencerUserEffect.requestUserResetPassword,
        data
      );
    };
  }
}
