import { BaseModel } from "sjs-base-model";
import InfluencersActivityShardsModel from "./InfluencersActivityShardsModel";
import InfluencersDataHitsModel from "./InfluencersDataHitsModel";

// InfluencersActivityModel
export default class InfluencersActivityModel extends BaseModel {
    // response getting from the api mentioned end point
    _shards = { _shards: InfluencersActivityShardsModel };
    current_page_no = 0;
    hits = { hits: InfluencersDataHitsModel };
    total_page_no = 0;
    timed_out = false;
    took = 0;
    constructor(data1) {
        super();

        this.update(data1);
    }
}
