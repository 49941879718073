import environment from "environment";
import EffectUtility from "../../../utilities/EffectUtility";
import ConsumerModel from "./models/ConsumerModel";
import ConsumerPutModel from "./models/ConsumerPutModel";

// consumer api fetch model
export default class ConsumerEffect {
  // calling an API according to the consumerModel
  static async requestConsumer(params, callBackFunction) {
    const endpoint = environment.api.consumer_feed;

    let response = EffectUtility.getToModel(ConsumerModel, endpoint, params);

    return response;
  }

  static async requestConsumerFilter(params) {
    const endpoint = environment.api.consumer_feed;

    return EffectUtility.getToModel(ConsumerModel, endpoint, params);
  }

  static async requestPutConsumer(data, id) {
    const endpoint = environment.api.consumer_feed + "/" + id;
    return EffectUtility.putToModel(ConsumerPutModel, endpoint, data);
  }

  static async requestConsumerFeedClear() {
    return {};
  }
}
