import { BaseModel } from "sjs-base-model";
import OrganizationDataModel from "./OrganizationDataModel";

// OragnizationDataHitsModel
export default class OragnizationDataHitsModel extends BaseModel {
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [OrganizationDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
