import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import NoDataFound from "../nodatafound/NoDataFound";
import { makeStyles } from "@material-ui/core/styles/";
import { theme } from "../../views/App";
import { Box, Stack } from "@mui/material";
import PaginationUI from "../pagination/Pagination";
import "./datagrid.css";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

function DataGridFunction({
  columns,
  rows,
  rowReordering,
  pagination,
  rowHeight,
  getRowHeight,
  onCellClick,
  checkboxSelection,
  curser,
  getRowId,
  rowBorder,
  rowBorderCustom,
  rowColor,
  headerColor,
  onSelectionModelChange,
  apiRef,
  onRowClick,
  pagelength,
  onRowOrderChange,
  page,
  handleChange,
  headerHeight,
  height,
  selectionModel,
  getRowClassName,
  isRequesting,
  initialState,
  getDetailPanelHeight,
  getDetailPanelContent,
  columnGroupingModel,
  disableRowSelectionOnClick,
  unstable_rowSpanning,
}) {
  const useStyles = makeStyles({
    root: {
      "& .MuiDataGrid-cellCenter": {
        justifyContent: "center",
        display: "none",
      },
      "& .MuiDataGrid-cell": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        position: "relative",
        pointerEvents: "none",
      },

      "& .MuiDataGrid-cell": {
        border: rowBorder ? "1px solid #f7f7f6" : "",

        borderRight: rowBorderCustom ? "1px solid rgba(229, 235, 237, 1)" : "",

        padding: rowBorder ? "8px" : "",
      },

      "& .MuiDataGrid-columnSeparator": {
        visibility: "visible !important",
        opacity: "1 !important",
      },

      // header background color
      "& .css-1oi61yq-MuiDataGrid-topContainer": {
        backgroundColor: theme.palette.datagrid.background.datagridHeader,
        color: theme.palette.primary.main,
      },
      "& .MuiDataGrid-topContainer": {
        fontSize: theme.palette.datagrid.Columns.fontSize,
        backgroundColor: headerColor
          ? theme.palette.datagrid.background.datagridHeader
          : "",
      },

      "& .MuiDataGrid-row": {
        backgroundColor:
          !getRowClassName && theme.palette.datagrid.background.datagridCell,
        justifyContent: "center",

        "&:hover": {
          backgroundColor:
            !getRowClassName && theme.palette.datagrid.background.hover,
          cursor: curser === "pointer" ? "pointer" : "default",
        },
      },
    },
  });
  const classes = useStyles();

  const paginTionUI = () => {
    return (
      <PaginationUI
        pagelength={pagelength}
        page={page}
        handleChange={handleChange}
      />
    );
  };

  const noDataUI = () => {
    return <NoDataFound />;
  };

  const EmptyFooter = () => {
    return null;
  };

  return (
    //*******************************************  Datagrid components
    <Box
      sx={{
        // paddingTop: 1,
        height: rows.length === 0 ? "15vh" : height ? height : "80vh",
        width: "100%",
      }}
    >
      <DataGridPro
        slots={{
          noRowsOverlay:
            !isRequesting && rows.length === 0 ? noDataUI : EmptyFooter,
          footer: pagelength ? paginTionUI : EmptyFooter,
        }}
        slotProps={{
          pagination: pagination,
        }}
        columnGroupingModel={columnGroupingModel}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        unstable_rowSpanning={unstable_rowSpanning}
        onRowOrderChange={onRowOrderChange}
        rowReordering={rowReordering}
        className={classes.root}
        density="compact"
        rowHeight={rowHeight}
        rows={rows}
        getRowHeight={getRowHeight}
        onCellClick={onCellClick}
        getRowId={getRowId ? getRowId : (rows) => rows.id}
        columns={columns}
        disableVirtualization
        checkboxSelection={checkboxSelection}
        selectionModel={selectionModel}
        disableSelectionOnClick
        onRowClick={onRowClick}
        getRowClassName={
          getRowClassName
            ? getRowClassName
            : (params) => {
                return rowColor ? (params.id % 2 === 0 ? "oddRow" : "") : "";
              }
        }
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: theme.palette.buttonComponent.fontSize.extraLarge,
            fontWeight: theme.typography.fontWeightBold,
          },
          fontSize: theme.palette.buttonComponent.fontSize.large,

          "& .MuiDataGrid-scrollbar": {
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
        columnHeaderHeight={headerHeight ? headerHeight : 55}
        hideFooterPagination={true}
        onSelectionModelChange={onSelectionModelChange}
        apiRef={apiRef}
        initialState={initialState}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Box>
  );
}

export default DataGridFunction;
