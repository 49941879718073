import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tab,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faTrash,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { Field, Form, Formik } from "formik";
import ReportTemplates from "./ReportTemplates";
import ReportsLogo from "./ReportsLogo";
import { useParams } from "react-router";
import SelectReportDialog from "./SelectReportDialog";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TicketReportsAction from "../../stores/reportsOnTickets/TicketReportsAction";
import TicketsAction from "../../stores/tickets/TicketsAction";
import PriorityAction from "../../stores/priority/PriorityAction";
import StatusAction from "../../stores/status/StatusAction";
import ReportTemplateAction from "../../../stores/reportTemplate/ReportTemplateAction";
import CustomTicketFieldsAction from "../../../stores/customticketfields/CustomTicketFieldsAction";
import OrmReportLogoAction from "../../stores/ormReportsLogo/OrmReportLogoAction";
import WordCloudAction from "../../../stores/wordCloud/WordCloudAction";
import UserAction from "../../stores/user/UserAction";
import PlatformAction from "../../stores/platform/PlatformAction";
import { theme } from "../../../views/App";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import ReportTemplatePost from "./reportTemplatePost";
import MuiButton from "../../../components/muiButton/MuiButton";
import DownloadReport from "./DownloadReport";
import GenerateReports from "./GenerateReports";
import {
  getItemsById,
  templateReports,
} from "../../../components/ticketPartials";
import TicketSettingsAction from "../../stores/ticketSettings/TicketSettingsAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";

let mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TicketReportsAction.REQUEST_TICKET_REPORTS,
    TicketsAction.REQUEST_TICKETS_BY_FIELDS,
    PriorityAction.REQUEST_PRIORITY_FILTER,
    TicketReportsAction.REQUEST_AGENT_REPORTS,
    TicketReportsAction.REQUEST_SENTIMENTAL_ANALYSIS_REPORTS,
    StatusAction.REQUEST_STATUS_FILTER,
    ReportTemplateAction.REQUEST_REPORT_TEMPLATE,
    ReportTemplateAction.REQUEST_REPORT_TEMPLATE_FILTER,
    CustomTicketFieldsAction.REQUEST_CUSTOMTICKETFIELDS_FILTER,
    OrmReportLogoAction.REQUEST_ORM_REPORT_LOGO,
    WordCloudAction.REQUEST_WORDCLOUD,
    UserAction.REQUEST_USER_FILTER,
    TicketSettingsAction.REQUEST_TICKET_SETTINGS,
  ]),
  ticketFields: state.ticketFields.ticketFields || [],
  ticketReports: state.ticketReports.ticketReports || [],
  filterpriority: state.filterpriority.filterpriority.hits || [],
  agentReports: state.agentReports.agentReports || [],
  sentimentalAnalysisReport:
    state.sentimentalAnalysisReport.sentimentalAnalysisReport || [],
  statusFilter: state.statusFilter.statusFilter || [],
  reportTemplate: state.reportTemplate.reportTemplate || [],
  reportTemplateFilter: state.reportTemplateFilter.reportTemplateFilter || [],
  customticketfields_filter:
    state.customticketfields_filter.customticketfields_filter || [],
  ormReportLogo: state.ormReportLogo.ormReportLogo || [],
  wordCloudSettings: state.wordCloudSettings.wordCloudSettings || [],
  platform: state.platform.platform || [],
  userfilter: state.userfilter.userfilter || [],
  userById: state.userById.userById || [],
  ticketSettings: state.ticketSettings.ticketSettings.hits || {},
});

function TicketStatsReport(props) {
  const { reportName } = useParams();

  // Tab view state and handle change function
  const [tabValue, setTabValue] = React.useState("3");
  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [openTemplate, setOpenTemplate] = useState(false);

  const [date, setDate] = useState([null, null]);
  const [openSelectFeild, setOpenSelectFeild] = useState(false);
  const [reportTemplate, setReportTemplate] = useState(null);
  const [selectedReports, setSelectedReports] = useState([]);
  const [reportPages, setReportPages] = useState([]);
  const [openLayoutSelection, setOpenLayoutSelection] = useState(false);
  const [selectedPageNumber, setSelectedPageNumber] = useState([]);

  let date_format = [];

  if (date[0]) {
    date_format.push(date[0].$d);
  }

  if (date[1]) {
    date_format.push(date[1].$d);
  }

  function fetchTicketSettings() {
    props.dispatch(TicketSettingsAction.requestTicketSettings());
  }

  useEffect(() => {
    const param = { page_limit: "none" };

    let emptyArray = [" "];
    let params = {
      report_type: `${JSON.stringify(emptyArray)}`,
      reports: `${JSON.stringify(emptyArray)}`,
    };

    fetchTicketReportsData(params);
    fetchAgentReports(params);
    fetchSentimentalanalysisReports(params);
    fetchWordCloud();
    fetchTicketSettings();
    // fetchTicketByFields(param);

    // if (
    //   props?.statusFilter === undefined ||
    //   Object.keys(props.statusFilter).length === 0
    // ) {
    fetchStatusFilter();
    // }

    // if (props?.userfilter === undefined || props?.userfilter.length === 0) {
    fetchUsers();
    // }

    // if (
    //   props?.reportTemplate === undefined ||
    //   props?.reportTemplate.length === 0
    // ) {
    fetchReportTemplate(param);
    // }

    if (
      props?.reportTemplateFilter === undefined ||
      props?.reportTemplateFilter.length === 0
    ) {
      // fetchReportTemplateFilter(param);
    }

    // if (
    //   props?.customticketfields_filter === undefined ||
    //   props?.customticketfields_filter.length === 0
    // ) {
    fetchCustomFields(param);
    // }

    // if (
    //   props?.ormReportLogo === undefined ||
    //   props?.ormReportLogo.length === 0
    // ) {
    fetchReportLogos(param);
    // }

    // if (
    //   props?.filterpriority === undefined ||
    //   props?.filterpriority.length === 0
    // ) {
    fetchPriority(param);
    // }

    // if (props?.platform === undefined || props?.platform.length === 0) {
    fetchOrgPlatforms();
    // }

    // settting countdata to empty after comming from next page
  }, []);

  function fetchOrgPlatforms() {
    let param = {
      page_limit: "none",
      order_by: `[["platform_name.keyword","asc"]]`,
      // level: "organization",
    };
    props.dispatch(PlatformAction.requestPlatform(param));
  }

  function fetchUsers() {
    let param = { page_limit: "none" };
    props.dispatch(UserAction.requestUserFilter(param));
  }

  function fetchPriority(params) {
    props.dispatch(PriorityAction.filterRequestPriority(params));
  }

  function fetchWordCloud(params) {
    props.dispatch(WordCloudAction.requestWordCloud(params));
  }

  function fetchReportTemplate(params) {
    props.dispatch(ReportTemplateAction.requestReportTemplate(params));
  }

  function fetchReportTemplateFilter(params) {
    props.dispatch(ReportTemplateAction.requestReportTemplateFilter(params));
  }

  function fetchTicketReportsData(params) {
    props.dispatch(TicketReportsAction.requestTicketReports(params));
  }

  function fetchTicketByFields(params) {
    props.dispatch(TicketsAction.requestTicketsByFields(params));
  }

  function fetchAgentReports(params) {
    props.dispatch(TicketReportsAction.requestAgentReport(params));
  }

  function fetchStatusFilter() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(StatusAction.requestStatusFilter(params));
  }

  function fetchSentimentalanalysisReports(params) {
    props.dispatch(
      TicketReportsAction.requestSentimentalAnalysisReports(params)
    );
  }

  function fetchCustomFields(params) {
    props.dispatch(
      CustomTicketFieldsAction.requestCustomTicketFieldsFilter(params)
    );
  }

  function fetchReportLogos(params) {
    props.dispatch(OrmReportLogoAction.requestOrmReportLogo(params));
  }

  let platform_list = [];
  if (props.platform.hits) {
    props.platform.hits.hits &&
      props.platform.hits.hits.length > 0 &&
      props.platform.hits.hits.map((item) => {
        platform_list.push({
          platform_id: item._id,
          platform_name: item._source.platform_name,
          platform_alias: item._source.alias,
        });
      });
  }

  const activitiesData = JSON.parse(localStorage.getItem("activities"));
  let activity_list = [];
  activitiesData &&
    activitiesData.hits &&
    activitiesData.hits.hits &&
    activitiesData.hits.hits.length > 0 &&
    activitiesData.hits.hits.map((item) => {
      activity_list.push({
        activity_id: item._id,
        activity_name: item._source.activities,
        platform: item._source.platform,
      });
    });

  // report Template List
  let reportTemplateList = [];

  if (props.reportTemplate.hits) {
    props.reportTemplate.hits.hits.forEach((value) => {
      reportTemplateList.push({
        report_template_id: value._id,
        report_template_name: value._source.report_template_name,
      });
    });
  }

  reportTemplateList.sort((a, b) =>
    a?.report_template_name.localeCompare(b.report_template_name)
  );
  reportTemplateList = [...new Set(reportTemplateList)];

  // NegativeWordCloudList

  const reportNameHandleChange = (event, value) => {
    if (value != null) {
      setReportTemplate(value);

      let filteredReport =
        props.reportTemplate.hits &&
        props.reportTemplate.hits.hits &&
        props.reportTemplate.hits.hits.length > 0 &&
        props.reportTemplate.hits.hits.filter(
          (report) => report._id === value.report_template_id
        );

      let reports = [];

      filteredReport &&
        filteredReport.length > 0 &&
        filteredReport[0]._source.template.map((item, index) => {
          reports.push({
            page_layout: item.layout,
            left_top_logo: item.left_top_logo,
            right_top_logo: item.right_top_logo,
            reports: item.reports,
            page_number: index + 1,
          });
        });

      setReportPages(reports);
    } else {
      setReportTemplate(null);
      setSelectedReports([]);
      setSelectedReportsFilter([]);
      setReportPages([]);
    }

    if (props.reportTemplate.hits && value) {
      let reports = [];
      let reportsFilter = [];

      // props.reportTemplate.hits.hits.length &&
      //   props.reportTemplate.hits.hits.filter(
      //     (e) => e._id === value.report_template_id
      //   ).length &&
      //   props.reportTemplate.hits.hits
      //     .filter((e) => e._id === value.report_template_id)
      //     .map((item) => {
      //       return item._source.template.map((e) => {
      //         reports.push(e.template_name);
      //         reportsFilter.push({
      //           report: e.template_name,
      //           filterValue: e.filter,
      //           description: e.description,
      //           left_top_logo: e.left_top_logo,
      //           right_top_logo: e.right_top_logo,
      //         });

      //         setSelectedReports(reports);
      //         setSelectedReportsFilter(reportsFilter);
      //       });
      //     });
    }
  };

  let reportTemplates = [
    "Loop Closure of Escalations",
    "Sample Screenshot of Loop Closure by ORM team on Social Media",
    "Snapshot of all activities and metrics on each item",
    "Summery Overview",
    "Engagement Trend",
    "Total Mentions",
    "Twitter Most :- Retweets",
    "Twitter Most :- Favorited",
  ];

  // customeFields list assigning
  let customFieldList = [];

  props.customticketfields_filter &&
    props.customticketfields_filter.hits &&
    props.customticketfields_filter.hits.hits.length > 0 &&
    props.customticketfields_filter.hits.hits.map((item) => {
      customFieldList.push(item._source.field_name);
    });

  customFieldList = [...new Set(customFieldList)];

  // custom field autocomplete in reports handle change
  const hanldeCustomFieldChange = (page_number, index, value) => {
    if (
      reportPages.length &&
      reportPages.filter((val) => val.page_number === page_number).length > 0
    ) {
      const newReportsPage = reportPages.map((val) => {
        let newReports = [...val.reports];
        newReports[index] = {
          ...newReports[index],
          filter: value,
        };
        return val.page_number === page_number
          ? {
              ...val,
              reports: newReports,
            }
          : val;
      });

      setReportPages(newReportsPage);
    } else {
      setReportPages([...reportPages]);
    }
  };

  // const [monthlywiseReportValue, setMonthlywiseReportValue] = React.useState(
  //   new Date()
  // );

  // LOGO list assigning
  let logoList = [];

  if (props.ormReportLogo.hits) {
    props.ormReportLogo.hits.hits &&
      props.ormReportLogo.hits.hits.length &&
      props.ormReportLogo.hits.hits.map((item) => {
        logoList.push({
          logo_id: item._id,
          logo_name: item._source.logo_name,
          logo_url: item._source.logo_image_url,
        });
      });
  }

  function returnLogoData(logoID) {
    return logoList.length &&
      logoList.filter((item) => item.logo_id === logoID).length
      ? logoList.filter((item) => item.logo_id === logoID)[0]
      : null;
  }

  const visibleFiter = (report) => {
    let boolean = false;

    if (report?.report_name) {
      if (JSON.parse(report.report_name)?.report_id) {
        let id = JSON.parse(report.report_name)?.report_id;
        if (
          id === "0hPgGowBpodn5_f5vuOU" ||
          id === "zhPgGowBpodn5_f5uuNw" ||
          id === "0xPgGowBpodn5_f5v-Of" ||
          id === "1BPgGowBpodn5_f5weMf" ||
          id === "1BPgGowBpodn5_f5weGH" ||
          id === "1RPgGowBpodn5_f5wuMn" ||
          id === "zBPgGowBpodn5_f5uONx" ||
          id === "1xPgGowBpodn5_f5xONj" ||
          id === "zxPgGowBpodn5_f5u-OE" ||
          id === "zRPgGowBpodn5_f5ueN5" ||
          id === "1hPgGowBpodn5_f5w-Me" ||
          id === "0BPgGowBpodn5_f5vON5" ||
          id === "0RPgGowBpodn5_f5veOg"
        ) {
          boolean = true;
        }
      }
    }

    if (report?.report_id) {
      let id = report?.report_id;

      if (
        id === "0hPgGowBpodn5_f5vuOU" ||
        id === "zhPgGowBpodn5_f5uuNw" ||
        id === "0xPgGowBpodn5_f5v-Of" ||
        id === "1BPgGowBpodn5_f5weMf" ||
        id === "1BPgGowBpodn5_f5weGH" ||
        id === "1RPgGowBpodn5_f5wuMn" ||
        id === "zBPgGowBpodn5_f5uONx" ||
        id === "1xPgGowBpodn5_f5xONj" ||
        id === "zxPgGowBpodn5_f5u-OE" ||
        id === "zRPgGowBpodn5_f5ueN5" ||
        id === "1hPgGowBpodn5_f5w-Me" ||
        id === "0BPgGowBpodn5_f5vON5" ||
        id === "0RPgGowBpodn5_f5veOg"
      ) {
        boolean = true;
      }
    }

    return boolean;
  };

  const returnLogoUrl = (logoID) => {
    return logoList.length &&
      logoList.filter((item) => item.logo_id === logoID).length
      ? logoList.filter((item) => item.logo_id === logoID)[0].logo_url
      : "";
  };

  // word cloud settings list
  let positive_words_list = [];
  let negative_words_list = [];
  let wordCloudID = null;

  if (props.wordCloudSettings.hits) {
    props.wordCloudSettings.hits.hits.map((item) => {
      wordCloudID = item._id;
      item._source.positive_words.map((item) => {
        positive_words_list.push(item);
      });
      item._source.negative_words.map((item) => {
        negative_words_list.push(item);
      });
    });
  }

  // reports filter pushing to array
  const [selectedReportsFilter, setSelectedReportsFilter] = useState([]);

  let word_cloud_initial = {
    positive_words: positive_words_list,
    negative_words: negative_words_list,
  };

  const [openReportDialog, setOpenReportDialog] = React.useState(false);
  const [index, setIndex] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);

  //Reports checkboxes rendering
  const reportsCheckboxRender = (page) => {
    return page.reports.map((report, index) => {
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} container>
          <Accordion
            expanded={report.report_name || report.report_id}
            style={{
              backgroundColor: "white",
              height: "auto",
              width: "100%",
              borderRadius: theme.borderRadius,
              border: "solid 0.5px #d3e2e6",
              boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            }}
            className="mx-1"
          >
            <AccordionSummary key={index} style={{ width: "100%" }}>
              {(report && report.report_name) || report.report_id ? (
                <Grid
                  xs={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {report && report.report_name
                      ? JSON.parse(report.report_name).report_name
                      : report.report_id
                      ? getItemsById(report.report_id)[0]?.report_name
                      : ""}
                  </span>
                  <Grid>
                    <Typography
                      title="Change Report"
                      id={index}
                      component={"span"}
                      onClick={() => {
                        setOpenReportDialog(true);
                        setSelectedPage(page);
                        setIndex(index);
                      }}
                      style={{ marginRight: 10 }}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "#2a5e6c",
                        }}
                      ></FontAwesomeIcon>
                    </Typography>
                    <Typography
                      title="Delete Report"
                      id={index}
                      component={"span"}
                      onClick={() => {
                        setIndex(index);

                        const newReportsPage = reportPages.map((val) => {
                          let newReports = [...val.reports];
                          newReports[index] = {
                            report_name: null,
                            filter: "",
                            description: [],
                          };
                          return val.page_number === page.page_number
                            ? {
                                ...val,
                                reports: newReports,
                              }
                            : val;
                        });

                        setReportPages(newReportsPage);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "#2a5e6c",
                        }}
                      ></FontAwesomeIcon>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  xs={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Select Report</span>

                  <>
                    <Typography
                      title="Choose Report"
                      id={index}
                      component={"span"}
                      onClick={() => {
                        setOpenReportDialog(true);
                        setSelectedPage(page);
                        setIndex(index);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlusSquare}
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "#2a5e6c",
                        }}
                      ></FontAwesomeIcon>
                    </Typography>
                  </>
                </Grid>
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Grid item xl={12} lg={12} md={12} sm={12} container>
                {visibleFiter(report) && (
                  //   "Share of Voice - Brand vs Compititors Brand" ||
                  // report.report_name ===
                  // "Share of Voice - Brand vs Compititors Brand (Chart view)"
                  <Grid item xl={5} lg={5} md={5} sm={5} margin={1}>
                    <AutoCompleteComponent
                      width="100%"
                      list={customFieldList}
                      handleChange={(e, val) => {
                        hanldeCustomFieldChange(page.page_number, index, val);
                      }}
                      size="small"
                      disableClearable={true}
                      textFieldProps={{
                        placeholder: "Custom Field",
                      }}
                      value={
                        reportPages.length
                          ? reportPages.filter(
                              (i) => i.page_number === page.page_number
                            ).length
                            ? reportPages.filter(
                                (i) => i.page_number === page.page_number
                              )[0].reports[index].filter
                            : ""
                          : null
                      }
                    />
                  </Grid>
                )}

                {/* {(report.report_name ===
                  "Hourly Platformwise Tickets Resolved" ||
                  report.report_name === "Sentiment Analysis") && (
                  <Grid item xl={5} lg={5} md={5} sm={5} margin={1}>
                    <div style={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MonthPicker
                          views={["year", "month"]}
                          label="Year and Month"
                          value={
                            reportPages.length
                              ? reportPages.filter(
                                  (i) => i.page_number === page.page_number
                                ).length
                                ? new Date(
                                    reportPages.filter(
                                      (i) => i.page_number === page.page_number
                                    )[0].reports[index].filter
                                  )
                                : new Date()
                              : new Date()
                          }
                          onChange={(newValue) => {
                            if (newValue !== null) {
                              // setMonthlywiseReportValue(newValue.$d);
                              hanldeCustomFieldChange(
                                page.page_number,
                                index,
                                newValue.$d
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label={false}
                              size="small"
                              helperText={null}
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                )} */}

                {/* {report.report_name === "Word Cloud" && (
                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={10}
                    margin={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Formik
                      initialValues={word_cloud_initial}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        let positive_words = Array.isArray(
                          values.positive_words
                        )
                          ? values.positive_words
                          : values.positive_words.split(",");

                        let negative_words = Array.isArray(
                          values.negative_words
                        )
                          ? values.negative_words
                          : values.negative_words.split(",");

                        let postObjValues = {};
                        if (!wordCloudID) {
                          postObjValues = {
                            positive_words: positive_words,
                            negative_words: negative_words,
                          };
                        }

                        if (wordCloudID) {
                          props
                            .dispatch(
                              WordCloudAction.requestPutWordCloud(
                                { positive_words, negative_words },
                                wordCloudID
                              )
                            )
                            .then(() => {
                              props.dispatch(
                                WordCloudAction.requestWordCloud()
                              );
                            });
                        } else {
                          props
                            .dispatch(
                              WordCloudAction.requestPostWordCloud(
                                postObjValues
                              )
                            )
                            .then(() => {
                              props.dispatch(
                                WordCloudAction.requestWordCloud()
                              );
                            });
                        }
                      }}
                    >
                      {({
                        errors,
                        isValid,
                        helperText,
                        touched,
                        dirty,
                        handleChange,
                        setFieldValue,
                        submitForm,
                        values,
                      }) => (
                        <Form>
                          <Field
                            name="positive_words"
                            as={TextareaAutosize}
                            minRows={3}
                            placeholder="Positive Words"
                            style={{ width: "100%" }}
                          />
                          <Field
                            name="negative_words"
                            as={TextareaAutosize}
                            minRows={3}
                            placeholder="Negative Words"
                            style={{ width: "100%" }}
                          />
                          <MuiButton
                            name={"Add Words"}
                            onClick={() => {
                              submitForm();
                            }}
                            width="100%"
                          />
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                )} */}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  };

  // checkbox handle change
  function ReportTemplatehandleChange(event) {
    const value = event.target.value;

    if (selectedReports.indexOf(value) !== -1) {
      // if value already present
      const newSelected = selectedReports.filter((s) => s !== value);
      setSelectedReports(newSelected);
    } else {
      // if value not present
      setSelectedReports([...selectedReports, value]);
    }

    if (
      selectedReportsFilter.length &&
      selectedReportsFilter.filter((val) => val.report === value).length > 0
    ) {
      // uncheck condition
      // removing report from selected reports filter
      const newReportFilter = selectedReportsFilter.filter(
        (v) => v.report !== value
      );

      setSelectedReportsFilter(newReportFilter);
    } else {
      if (
        value === "Monthly Wise Platform Review Counts" ||
        value === "Hourly Platformwise Tickets Resolved"
      ) {
        setSelectedReportsFilter([
          ...selectedReportsFilter,
          {
            report: value,
            // filterValue: monthlywiseReportValue,
            description: [],
            left_top_logo: null,
            right_top_logo: null,
          },
        ]);
      } else {
        setSelectedReportsFilter([
          ...selectedReportsFilter,
          {
            report: value,
            filterValue: "",
            description: [],
            left_top_logo: null,
            right_top_logo: null,
          },
        ]);
      }
    }
  }

  // DESCRIPTION EDIT
  const descriptionCallback = (page_number, index, descriptionData) => {
    // if (
    //   selectedReportsFilter.length &&
    //   selectedReportsFilter.filter((val) => val.report === report).length > 0
    // ) {
    //   const newReportFilter = selectedReportsFilter.map((val) => {
    //     return val.report === report
    //       ? {
    //           description: descriptionData,
    //           report: val.report,
    //           filterValue: val.filterValue,
    //           left_top_logo: val.left_top_logo,
    //           right_top_logo: val.right_top_logo,
    //         }
    //       : val;
    //   });

    //   setSelectedReportsFilter(newReportFilter);
    // }
    if (
      reportPages.length &&
      reportPages.filter((val) => val.page_number === page_number).length > 0
    ) {
      const newReportsPage = reportPages.map((val) => {
        let newReports = [...val.reports];
        newReports[index] = {
          ...newReports[index],
          description: descriptionData,
        };
        return val.page_number === page_number
          ? {
              ...val,
              reports: newReports,
            }
          : val;
      });

      setReportPages(newReportsPage);
    }
  };

  const layoutList = [
    "SingleBoxLayout",
    "TwoRowOneColumnLayout",
    "OneRowTwoColumnLayout",
    "TwoRowTowColumnLayout",
    "Single Left, Divided Right(2)",
    "Divided Left(2), Single right",
    "Two Col on first Row, One col on second Row",
    "One Col on first Row, Two col on second Row",
    "three rows two columns",
    "two rows three columns",
    "Single Left, Divided Right(3)",
    "Divided Left(3), Single right",
  ];

  const [view, setView] = React.useState("SingleBoxLayout");

  const boxhandleChange = (event, value) => {
    setView(value);

    if (value === "SingleBoxLayout") {
      let newReportsPages = reportPages.map((item, index) => ({
        ...item,
        page_number: index + 1,
      }));
      setReportPages([
        ...newReportsPages,
        {
          page_layout: value,
          reports: [{ report_name: null, filter: "", description: [] }],
          left_top_logo: "",
          right_top_logo: "",
          page_number: reportPages.length + 1,
        },
      ]);
    } else if (
      value === "TwoRowOneColumnLayout" ||
      value === "OneRowTwoColumnLayout"
    ) {
      let newReportsPages = reportPages.map((item, index) => ({
        ...item,
        page_number: index + 1,
      }));
      setReportPages([
        ...newReportsPages,
        {
          page_layout: value,
          reports: [
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
          ],
          left_top_logo: "",
          right_top_logo: "",
          page_number: reportPages.length + 1,
        },
      ]);
    } else if (
      value === "TwoRowTowColumnLayout" ||
      value === "Single Left, Divided Right(3)" ||
      value === "Divided Left(3), Single right" ||
      value === "three Col on first Row, One col on second Row" ||
      value === "One Column on first Row, Two Column on second Row"
    ) {
      let newReportsPages = reportPages.map((item, index) => ({
        ...item,
        page_number: index + 1,
      }));
      setReportPages([
        ...newReportsPages,
        {
          page_layout: value,
          reports: [
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
          ],
          left_top_logo: "",
          right_top_logo: "",
          page_number: reportPages.length + 1,
        },
      ]);
    } else if (
      value === "Single Left, Divided Right(2)" ||
      value === "Divided Left(2), Single right" ||
      value === "Two Col on first Row, One col on second Row" ||
      value === "One Col on first Row, Two col on second Row"
    ) {
      let newReportsPages = reportPages.map((item, index) => ({
        ...item,
        page_number: index + 1,
      }));
      setReportPages([
        ...newReportsPages,
        {
          page_layout: value,
          reports: [
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
          ],
          left_top_logo: "",
          right_top_logo: "",
          page_number: reportPages.length + 1,
        },
      ]);
    } else if (value === "BI") {
      let newReportsPages = reportPages.map((item, index) => ({
        ...item,
        page_number: index + 1,
      }));
      setReportPages([
        ...newReportsPages,
        {
          page_layout: value,
          reports: [
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
          ],
          left_top_logo: "",
          right_top_logo: "",
          page_number: reportPages.length + 1,
        },
      ]);
    } else if (
      value === "three rows two columns" ||
      value === "two rows three columns"
    ) {
      let newReportsPages = reportPages.map((item, index) => ({
        ...item,
        page_number: index + 1,
      }));
      setReportPages([
        ...newReportsPages,
        {
          page_layout: value,
          reports: [
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
          ],
          left_top_logo: "",
          right_top_logo: "",
          page_number: reportPages.length + 1,
        },
      ]);
    } else if (
      value === "Double Left, Divided Right(3)" ||
      value === "Triple Left, double right"
    ) {
      let newReportsPages = reportPages.map((item, index) => ({
        ...item,
        page_number: index + 1,
      }));
      setReportPages([
        ...newReportsPages,
        {
          page_layout: value,
          reports: [
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
            { report_name: null, filter: "", description: [] },
          ],
          left_top_logo: "",
          right_top_logo: "",
          page_number: reportPages.length + 1,
        },
      ]);
    }

    setOpenLayoutSelection(false);
  };

  // const PagesHandleChange = (e, value) => {
  //   if (value === "SingleBoxLayout") {
  //     let newReportsPages = reportPages.map((item, index) => ({
  //       ...item,
  //       page_number: index + 1,
  //     }));
  //     setReportPages([
  //       ...newReportsPages,
  //       {
  //         page_layout: value,
  //         reports: [{ report_name: null, filter: "", description: [] }],
  //         left_top_logo: "",
  //         right_top_logo: "",
  //         page_number: reportPages.length + 1,
  //       },
  //     ]);
  //   } else if (
  //     value === "TwoRowOneColumnLayout" ||
  //     value === "OneRowTwoColumnLayout"
  //   ) {
  //     let newReportsPages = reportPages.map((item, index) => ({
  //       ...item,
  //       page_number: index + 1,
  //     }));
  //     setReportPages([
  //       ...newReportsPages,
  //       {
  //         page_layout: value,
  //         reports: [
  //           { report_name: null, filter: "", description: [] },
  //           { report_name: null, filter: "", description: [] },
  //         ],
  //         left_top_logo: "",
  //         right_top_logo: "",
  //         page_number: reportPages.length + 1,
  //       },
  //     ]);
  //   } else if (value === "TwoRowTowColumnLayout") {
  //     let newReportsPages = reportPages.map((item, index) => ({
  //       ...item,
  //       page_number: index + 1,
  //     }));
  //     setReportPages([
  //       ...newReportsPages,
  //       {
  //         page_layout: value,
  //         reports: [
  //           { report_name: null, filter: "", description: [] },
  //           { report_name: null, filter: "", description: [] },
  //           { report_name: null, filter: "", description: [] },
  //           { report_name: null, filter: "", description: [] },
  //         ],
  //         left_top_logo: "",
  //         right_top_logo: "",
  //         page_number: reportPages.length + 1,
  //       },
  //     ]);
  //   } else if (
  //     value === "Single Left, Divided Right(2)" ||
  //     value === "Divided Left(2), Single right" ||
  //     value === "Two Col on first Row, One col on second Row" ||
  //     value === "One Col on first Row, Two col on second Row"
  //   ) {
  //     let newReportsPages = reportPages.map((item, index) => ({
  //       ...item,
  //       page_number: index + 1,
  //     }));
  //     setReportPages([
  //       ...newReportsPages,
  //       {
  //         page_layout: value,
  //         reports: [
  //           { report_name: null, filter: "", description: [] },
  //           { report_name: null, filter: "", description: [] },
  //           { report_name: null, filter: "", description: [] },
  //         ],
  //         left_top_logo: "",
  //         right_top_logo: "",
  //         page_number: reportPages.length + 1,
  //       },
  //     ]);
  //   }

  //   setOpenLayoutSelection(false);
  // };

  function PageLayoutHandleChange(event, value) {
    if (selectedPageNumber.includes(value)) {
      // if value already present
      const newSelected = selectedPageNumber.filter((s) => s !== value);
      setSelectedPageNumber(newSelected);
    } else {
      // if value not present
      setSelectedPageNumber([...selectedPageNumber, value]);
    }
  }

  // Left top logo handle handle change
  const hanldeLeftLogoChange = (page, value) => {
    if (
      reportPages.length &&
      reportPages.filter((val) => val.page_number === page.page_number).length >
        0
    ) {
      const newReportPages = reportPages.map((val) => {
        return val.page_number === page.page_number
          ? {
              page_layout: val.page_layout,
              reports: val.reports,
              left_top_logo: value ? value.logo_id : "",
              right_top_logo: val.right_top_logo,
              page_number: val.page_number,
            }
          : val;
      });

      setReportPages(newReportPages);
    } else {
      setReportPages([...reportPages]);
    }
  };

  const hanldeRightLogoChange = (page, value) => {
    if (
      reportPages.length &&
      reportPages.filter((val) => val.page_number === page.page_number).length >
        0
    ) {
      const newReportPages = reportPages.map((val) => {
        return val.page_number === page.page_number
          ? {
              page_layout: val.page_layout,
              reports: val.reports,
              left_top_logo: val.left_top_logo,
              right_top_logo: value ? value.logo_id : "",
              page_number: val.page_number,
            }
          : val;
      });

      setReportPages(newReportPages);
    } else {
      setReportPages([...reportPages]);
    }
  };

  // Pages list with checkbox
  const PagesCheckboxList = reportPages.length
    ? reportPages.map((option, index) => {
        return (
          <Grid item xl={12} lg={12} md={12} sm={12} container>
            <Accordion
              // expanded={
              //   selectedPageNumber &&
              //   selectedPageNumber.includes(option.page_number)
              // }
              expanded={true}
              style={{
                backgroundColor: theme.palette.background.default,
                height: "auto",
                width: "100%",
                borderRadius: theme.borderRadius,
                border: "solid 0.5px #d3e2e6",
                boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
              }}
              className="mx-1"
            >
              <AccordionSummary>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      value={option}
                      label={option}
                      onChange={(e, val) => {
                        PageLayoutHandleChange(e, option.page_number);
                      }}
                      checked={
                        selectedPageNumber &&
                        selectedPageNumber.includes(option.page_number)
                      }
                    />
                  }
                  label={`Page ${option.page_number}`}
                /> */}
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography component={"span"}>{`Layout ${
                    index + 1
                  }`}</Typography>
                  <Typography
                    title="Delete Layout"
                    id={index}
                    component={"span"}
                    onClick={async () => {
                      const newReportsPage = reportPages.filter(
                        (obj) => obj.page_number !== option.page_number
                      );

                      newReportsPage.map(
                        (item, index) => (item.page_number = index + 1)
                      );

                      return setReportPages(newReportsPage);
                    }}
                  >
                    <DeleteForeverIcon />
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xl={12} lg={12} md={12} sm={12} container>
                  <Grid item xl={5} lg={5} md={5} sm={5} margin={1}>
                    <AutoCompleteComponent
                      width="100%"
                      list={logoList}
                      handleChange={(e, val) => {
                        hanldeLeftLogoChange(option, val);
                      }}
                      size="small"
                      textFieldProps={{
                        placeholder: "Left Top Logo",
                      }}
                      getOptionLabel={(option) => option.logo_name}
                      value={
                        reportPages.length
                          ? reportPages.filter(
                              (page) => page.page_number === option.page_number
                            ).length
                            ? returnLogoData(
                                reportPages.filter(
                                  (page) =>
                                    page.page_number === option.page_number
                                )[0].left_top_logo
                              )
                            : null
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xl={5} lg={5} md={5} sm={5} margin={1}>
                    <AutoCompleteComponent
                      width="100%"
                      list={logoList}
                      handleChange={(e, val) => {
                        hanldeRightLogoChange(option, val);
                      }}
                      getOptionLabel={(option) => option.logo_name}
                      size="small"
                      // disableClearable={true}
                      textFieldProps={{
                        placeholder: "Right Top Logo",
                      }}
                      value={
                        reportPages.length
                          ? reportPages.filter(
                              (page) => page.page_number === option.page_number
                            ).length
                            ? returnLogoData(
                                reportPages.filter(
                                  (page) =>
                                    page.page_number === option.page_number
                                )[0].right_top_logo
                              )
                            : null
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} margin={1}>
                    {reportsCheckboxRender(option)}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
      })
    : null;

  let statusList = [];
  if (props.statusFilter.hits) {
    props.statusFilter.hits.hits &&
      props.statusFilter.hits.hits.length > 0 &&
      props.statusFilter.hits.hits.map((item) => {
        statusList.push({
          status_id: item._id,
          status_name: item._source.status_name,
        });
      });
  }

  let userList = [];
  if (props.userfilter) {
    props.userfilter.data &&
      props.userfilter.data.length > 0 &&
      props.userfilter.data.map((user) => {
        userList.push({
          user_id: user.id,
          user_name: user.username,
        });
      });
  }

  const windowHeight = window.innerHeight;

  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  const heightOfDiv = isXl
    ? windowHeight * 0.8 // Adjust as needed for xl
    : isLg
    ? windowHeight * 0.68 // Adjust as needed for lg
    : isLaptop
    ? windowHeight * 0.7 // Adjust as needed for md
    : isMd
    ? windowHeight * 0.7 // Adjust as needed for md
    : windowHeight * 0.7; // Default for smaller sizes

  return (
    <Box>
      <ReportTemplatePost
        open={openTemplate}
        onSubmit={() => {}}
        onClose={() => {
          setOpenTemplate(false);
        }}
        selectedReports={reportPages}
      />

      {openReportDialog && (
        <SelectReportDialog
          reportsList={templateReports}
          pages={reportPages}
          setReportPages={setReportPages}
          page={selectedPage}
          index={index}
          onClose={() => setOpenReportDialog(false)}
          open={openReportDialog}
        />
      )}

      <Grid container lg={12}>
        <Grid sx={{ width: "100%", marginBottom: 1.5 }}>
          <LoadingIndicator isActive={props.isRequesting}>
            <TabContext value={tabValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  position: "sticky",
                  top: 65,
                  zIndex: 999,
                  backgroundColor: theme.palette.background.default,
                }}
              >
                <TabList
                  onChange={tabHandleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    sx={{ border: "none" }}
                    label="Create Template"
                    value="3"
                    style={{
                      textTransform: "none",
                      minHeight: 0, // set the minimum height to 0
                      padding: "10.8px 20px", // adjust the padding to decrease the height
                    }}
                  />
                  <Tab
                    sx={{ border: "none" }}
                    label="Generate Report"
                    value="4"
                    style={{
                      textTransform: "none",
                      minHeight: 0, // set the minimum height to 0
                      padding: "10.8px 20px", // adjust the padding to decrease the height
                    }}
                  />
                  <Tab
                    sx={{ border: "none" }}
                    label="Download Report"
                    value="5"
                    style={{
                      textTransform: "none",
                      minHeight: 0, // set the minimum height to 0
                      padding: "10.8px 20px", // adjust the padding to decrease the height
                    }}
                  />
                  <Tab
                    sx={{ border: "none" }}
                    label="Reports Logo"
                    value="6"
                    style={{
                      textTransform: "none",
                      minHeight: 0, // set the minimum height to 0
                      padding: "10.8px 20px", // adjust the padding to decrease the height
                    }}
                  />
                </TabList>
              </Box>

              <Box>
                <TabPanel
                  value="3"
                  sx={{
                    padding: "5px",
                    height: "auto",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      position: "sticky",
                      top: 115,
                      backgroundColor: theme.palette.background.default,
                    }}
                    // className="bg-warning"
                  >
                    {props?.ticketSettings &&
                      Object.keys(props.ticketSettings).length > 0 &&
                      props?.ticketSettings?.hits[0]?._id && (
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          item
                          p={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Grid item xl={1.5} lg={1.5} md={2} sm={3} xs={4}>
                            <AutoCompleteComponent
                              value={reportTemplate}
                              lastComponent
                              textFieldProps={{
                                placeholder: "Report Name",
                              }}
                              disablePortal={true}
                              handleChange={reportNameHandleChange}
                              getOptionLabel={(option) =>
                                option.report_template_name
                              }
                              height="50%"
                              size="small"
                              width="100%"
                              list={reportTemplateList}
                            />
                          </Grid>

                          <Grid item ml={1} lg={1} md={2} sm={2} xs={3}>
                            <MuiButton
                              name={reportTemplate ? "Save" : "Add"}
                              onClick={() => {
                                if (!reportTemplate) {
                                  if (reportPages.length === 0) {
                                    alert("Please Create Report Layout");
                                  } else {
                                    setOpenTemplate(true);
                                  }
                                } else {
                                  let putObj = {};
                                  let template = [];

                                  reportPages.map((item) => {
                                    template.push({
                                      layout: item.page_layout,
                                      left_top_logo: item.left_top_logo
                                        ? item.left_top_logo
                                        : "",
                                      right_top_logo: item.right_top_logo
                                        ? item.right_top_logo
                                        : "",
                                      reports: item.reports?.map((sub_item) => {
                                        return {
                                          description: sub_item.description,
                                          filter: sub_item.filter,
                                          report_id: sub_item.report_id
                                            ? sub_item.report_id
                                            : sub_item.report_name
                                            ? JSON.parse(sub_item.report_name)
                                                .report_id
                                            : null,
                                        };
                                      }),
                                    });
                                  });

                                  putObj = {
                                    report_template_name:
                                      reportTemplate.report_template_name,
                                    template: template,
                                  };

                                  props
                                    .dispatch(
                                      ReportTemplateAction.requestPutReportTemplate(
                                        JSON.stringify(putObj),
                                        reportTemplate.report_template_id
                                      )
                                    )
                                    .then(() => {
                                      props.dispatch(
                                        ReportTemplateAction.requestReportTemplate()
                                      );
                                    });
                                }
                              }}
                              width="100%"
                            />
                          </Grid>
                        </Grid>
                      )}
                  </Box>
                  <ReportTemplates
                    // listItem={reportsCheckbox}
                    selected={selectedReports}
                    selectedFilter={selectedReportsFilter}
                    descriptionCallback={descriptionCallback}
                    renderLogoCallBack={returnLogoUrl}
                    //
                    pages={reportPages}
                    setReportPages={setReportPages}
                    boxhandleChange={boxhandleChange}
                    openLayoutSelection={openLayoutSelection}
                    setOpenLayoutSelection={setOpenLayoutSelection}
                    layoutList={layoutList}
                    PageLayoutHandleChange={PageLayoutHandleChange}
                    selectedPageNumber={selectedPageNumber}
                    PagesCheckboxList={PagesCheckboxList}
                    templateReports={templateReports}
                    platform_list={platform_list}
                    statusList={statusList}
                    userList={userList}
                    activity_list={activity_list}
                    logoList={logoList && logoList}
                  />
                </TabPanel>
                <TabPanel
                  value="4"
                  sx={{
                    padding: "5px",
                    height: "auto",
                    width: "100%",
                  }}
                >
                  <GenerateReports
                    selected={selectedReports}
                    selectedFilter={selectedReportsFilter}
                    renderLogoCallBack={returnLogoUrl}
                    pages={reportPages}
                    setReportPages={setReportPages}
                    boxhandleChange={boxhandleChange}
                    view={view}
                    openLayoutSelection={openLayoutSelection}
                    setOpenLayoutSelection={setOpenLayoutSelection}
                    layoutList={layoutList}
                    PageLayoutHandleChange={PageLayoutHandleChange}
                    selectedPageNumber={selectedPageNumber}
                    PagesCheckboxList={PagesCheckboxList}
                    templateReports={templateReports}
                    platform_list={platform_list}
                    activity_list={activity_list}
                    logoList={logoList && logoList}
                    statusList={statusList}
                    userList={userList}
                  />
                </TabPanel>
                <TabPanel
                  value="5"
                  sx={{
                    padding: "5px",
                    height: "auto",
                    width: "100%",
                  }}
                >
                  <DownloadReport
                    reportTemplates={props.reportTemplate}
                    platform_list={platform_list}
                    statusList={statusList}
                    userList={userList}
                    logoList={logoList && logoList}
                    activity_list={activity_list}
                  />
                </TabPanel>
                <TabPanel
                  value="6"
                  sx={{
                    padding: "5px",
                    height: "auto",
                    width: "100%",
                  }}
                >
                  <ReportsLogo />
                </TabPanel>
              </Box>
            </TabContext>
          </LoadingIndicator>
        </Grid>
      </Grid>
    </Box>
  );
}

export default connect(mapStateToProps)(TicketStatsReport);
