import BaseReducer from "../../utilities/BaseReducer";
import WordCloudAction from "./WordCloudAction";

// reducer function
export default class WordCloudReducer extends BaseReducer {
  initialState = {
    wordCloudSettings: [],
  };

  [WordCloudAction.REQUEST_WORDCLOUD_FINISHED](state, action) {
    return {
      ...state,
      wordCloudSettings: action.payload,
    };
  }
}
