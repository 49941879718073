import { BaseModel } from "sjs-base-model";
import ConsumerDataHitsModel from "./ConsumerDataHitsModel";

// get model for project
export default class ConsumerHitsModel extends BaseModel {
  hits = [{ ConsumerDataHitsModel }];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
