import BaseReducer from "../../../../utilities/BaseReducer";
import McnFeedsAction from "./McnFeedsAction";

export default class McnFeedsFilterReduser extends BaseReducer {
  //initial state of InstagramAccount
  initialState = {
    mcnFeedsFilter: [],
  };
  //InstagramAccount request action finish
  [McnFeedsAction.REQUEST_MCN_FEEDS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      mcnFeedsFilter: action.payload,
    };
  }
}
