import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ActivityDataGroupwiseModel from "./models/ActivityDataGroupwise";
import ActivityDataGetModel from "./models/ActivityDataGetModel";
import DashboardSummaryGetModel from "./models/DashboardSummaryGetModel";

export default class DsrActivityDashboardEffect {
  static async requestDsrActivityData(params) {
    const endpoint = environment.api.dsrActiviyData;
    let response = EffectUtility.getToModel(
      ActivityDataGetModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestDsrActivityGroupwise(params) {
    const endpoint = environment.api.dsrActiviyGroupwise;
    let response = EffectUtility.getToModel(
      ActivityDataGroupwiseModel,
      endpoint,
      params
    );
    return response;
  }

  static async requestDsrDashboardSummary(params) {
    const endpoint = environment.api.dsrDashboardSummary;
    let response = EffectUtility.getToModel(
      DashboardSummaryGetModel,
      endpoint,
      params
    );
    return response;
  }
}
