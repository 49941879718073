import BaseReducer from "../../../utilities/BaseReducer";
import AllMentionsAction from "./AllMentionsAction";

export default class MentionsCustomerAndAccountsReducer extends BaseReducer {
  initialState = {
    all_customer_and_account_names: [],
  };
  [AllMentionsAction.REQUEST_MENTIONS_CUSTOMERS_AND_ACCOUNTNAMES_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      all_customer_and_account_names: action.payload,
    };
  }
}
