import ActionUtility from "../../../utilities/ActionUtility";
import GoogleAccountEffect from "./GoogleAccountEffect";
export default class GoogleAccountAction {
  // GoogleAccountAction action with an function declaration
  static REQUEST_GOOGLE_ACCOUNT = "GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT";
  static REQUEST_GOOGLE_ACCOUNT_FINISHED =
    "GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT_FINISHED";

  static REQUEST_GOOGLE_ACCOUNT_FILTER =
    "GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT_FILTER";
  static REQUEST_GOOGLE_ACCOUNT_FILTER_FINISHED =
    "GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_GOOGLE_ACCOUNT =
    "GoogleAccountAction.REQUEST_PUT_GOOGLE_ACCOUNT";
  static REQUEST_PUT_GOOGLE_ACCOUNT_FINISHED =
    "GoogleAccountAction.REQUEST_PUT_GOOGLE_ACCOUNT_FINISHED";

  static REQUEST_DELETE_GOOGLE_ACCOUNT =
    "GoogleAccountAction.REQUEST_DELETE_GOOGLE_ACCOUNT";
  static REQUEST_DELETE_GOOGLE_ACCOUNT_FINISHED =
    "GoogleAccountAction.REQUEST_DELETE_GOOGLE_ACCOUNT_FINISHED";

  // METHODS
  // aDDaCCOUNT GET function
  static requestGoogleAccount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT,
        GoogleAccountEffect.requestGoogleAccount,
        params
      );
    };
  }

  static requestGoogleAccountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAccountAction.REQUEST_GOOGLE_ACCOUNT_FILTER,
        GoogleAccountEffect.requestGoogleAccountFilter,
        params
      );
    };
  }
  static requestPutGoogleAccount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAccountAction.REQUEST_PUT_GOOGLE_ACCOUNT,
        GoogleAccountEffect.requestPutGoogleAccount,
        data,
        id
      );
    };
  }

  static requestDeleteGoogleAccount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        GoogleAccountAction.REQUEST_DELETE_GOOGLE_ACCOUNT,
        GoogleAccountEffect.requestDeleteGoogleAccount,
        id
      );
    };
  }
}
