import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// QuoraFeedsDataModel
export default class QuoraFeedsDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api
  id = v4();
  activities = "";
  answers = [
    {
      answer: "",
      score: 0,
    },
  ];
  created_by = {
    user_id: "",
    user_name: "",
  };
  created_on = "";
  modified_on = "";
  platform = "";
  project = {
    project_id: "",
    project_name: "",
  };
  question = "";
  question_url = "";
  sentiment_value = "";

  constructor(data) {
    super();

    this.update(data);
  }
}
