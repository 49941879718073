import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// twitter api fetch model
export default class EmailFeedsDataModel extends BaseModel {
  /*
   * Client-Side properties (Not from API)
   */
  // noneApiProperties = null;
  // response getting from the api

  id = v4();
  account_name = "";
  activities = "";
  attachments = [];
  bcc= [];
  body_html= "";
  cc= [];
  created_by ={
    user_id :"",user_name:"",
  };
  created_on = "";
  customer_name= "";
  date= "";
  feed_id= "";
  from= "";
  modified_on = "";
  platform= "";
  project= {
    project_id :"",project_name:""
  }
  replay_to = [];
  subject= "";
  to = [];

  constructor(data) {
    super();

    this.update(data);
  }
}
