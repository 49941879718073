import BaseReducer from "../../../../utilities/BaseReducer";
import AssetCollectionAction from "./AssetCollectionAction";

// ACWhitelistReducer
export default class ACWhitelistReducer extends BaseReducer {
  initialState = {
    whitelist: [],
  };
  [AssetCollectionAction.REQUEST_GET_WHITELIST_FINISHED](state, action) {
    return {
      ...state,
      whitelist: action.payload,
    };
  }
}
