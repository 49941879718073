import ActionUtility from "../../../../utilities/ActionUtility";
import FPplatformEffect from "./FPplatformEffect";

export default class FPplatformAction {
  static REQUEST_GET_FP_PLATFORMS = "FPplatformAction.REQUEST_GET_FP_PLATFORMS";
  static REQUEST_GET_FP_PLATFORMS_FINISHED =
    "FPplatformAction.REQUEST_GET_FP_PLATFORMS_FINISHED";

  // METHODS
  static requestGetFPplatforms(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FPplatformAction.REQUEST_GET_FP_PLATFORMS,
        FPplatformEffect.requestGetFPplatforms,
        params,
        callback
      );
    };
  }
}
