import { BaseModel } from "sjs-base-model";

//TrustPilotAccountDeleteModel
export default class TrustPilotAccountDeleteModel extends BaseModel {
  _id = "";
  _index = "";
  _primary_term = 0;
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
