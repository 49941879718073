import BaseReducer from "../../../../utilities/BaseReducer";
import SurveyAction from "./SurveyAction";

// reducer function
export default class surveyFilterReducer extends BaseReducer {
  initialState = {
    surveyFilter: [],
  };

  [SurveyAction.REQUEST_SURVEY_FILTER_FINISHED](state, action) {
    return {
      ...state,
      surveyFilter: action.payload,
    };
  }
}
