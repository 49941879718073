import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";

import ArtistModel from "./model/ArtistModel";
import ArtistPutModel from "./model/ArtistPutModel";
import ArtistPostModel from "./model/ArtistPostModel";

// artistEffect
export default class artistEffect {
  // calling an API according to the artist model
  // Get method for the
  static async requestArtist(params) {
    const endpoint = environment.api.artist;
    return EffectUtility.getToModel(ArtistModel, endpoint, params);
  }

  // Get method for the artist filter
  static async requestArtistFilter(params) {
    const endpoint = environment.api.artist;
    return EffectUtility.getToModel(ArtistModel, endpoint, params);
  }

  // put method for the artist
  static async requestPutArtist(data, id) {
    const endpoint = environment.api.artist + "/" + id;
    return EffectUtility.putToModel(ArtistPutModel, endpoint, data);
  }

  // post method for the artist
  static async requestPostArtist(data) {
    const endpoint = environment.api.artist + "/add";
    return EffectUtility.postToModel(ArtistPostModel, endpoint, data);
  }
}
