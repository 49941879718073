import ActionUtility from "../../../../utilities/ActionUtility";
import CmsLinkStatusLogEffect from "./CmsLinkStatusLogEffect";

export default class CmsLinkStatusLogAction {
  static REQUEST_CMS_LINKS_STATUS_LOG =
    "CmsLinkStatusLogAction.REQUEST_CMS_LINKS_STATUS_LOG";
  static REQUEST_CMS_LINKS_STATUS_LOG_FINISHED =
    "CmsLinkStatusLogAction.REQUEST_CMS_LINKS_STATUS_LOG_FINISHED";

  // METHODS
  static requestCmsLinkStatusLog(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CmsLinkStatusLogAction.REQUEST_CMS_LINKS_STATUS_LOG,
        CmsLinkStatusLogEffect.requestCmsLinkStatusLog,
        params
      );
    };
  }
}
