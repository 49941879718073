import ActionUtility from "../../../../utilities/ActionUtility";
import ReportUploadEffect from "./ReportUploadEffect";

export default class ReportUploadAction {
  // ReportUploadAction action with an function declaration
  static REQUEST_FETCH_RELEASE_STATUS_REPORT_UPLOAD =
    "ReportUploadAction.REQUEST_FETCH_RELEASE_STATUS_REPORT_UPLOAD";
  static REQUEST_FETCH_RELEASE_STATUS_REPORT_UPLOAD_FINISHED =
    "ReportUploadAction.REQUEST_FETCH_RELEASE_STATUS_REPORT_UPLOAD_FINISHED";

  static REQUEST_FETCH_RELEASE_UPC_REPORT_UPLOAD =
    "ReportUploadAction.REQUEST_FETCH_RELEASE_UPC_REPORT_UPLOAD";
  static REQUEST_FETCH_RELEASE_UPC_REPORT_UPLOAD_FINISHED =
    "ReportUploadAction.REQUEST_FETCH_RELEASE_UPC_REPORT_UPLOAD_FINISHED";

  static REQUEST_CSV_STREAMING_REPORT_UPLOAD =
    "ReportUploadAction.REQUEST_CSV_STREAMING_REPORT_UPLOAD";
  static REQUEST_CSV_STREAMING_REPORT_UPLOAD_FINISHED =
    "ReportUploadAction.REQUEST_CSV_STREAMING_REPORT_UPLOAD_FINISHED";

  static REQUEST_YT_OFFICIAL_REVENUE_REPORT_UPLOAD =
    "ReportUploadAction.REQUEST_YT_OFFICIAL_REVENUE_REPORT_UPLOAD";
  static REQUEST_YT_OFFICIAL_REVENUE_REPORT_UPLOAD_FINISHED =
    "ReportUploadAction.REQUEST_YT_OFFICIAL_REVENUE_REPORT_UPLOAD_FINISHED";

  static REQUEST_CSV_UPLOAD_SUMMARY =
    "ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY";
  static REQUEST_CSV_UPLOAD_SUMMARY_FINISHED =
    "ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY_FINISHED";

  static REQUEST_CSV_UPLOAD_SUMMARY_BY_ID =
    "ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY_BY_ID";
  static REQUEST_CSV_UPLOAD_SUMMARY_BY_ID_FINISHED =
    "ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY_BY_ID_FINISHED";

  // METHODS
  static requestFetchReleaseStatus(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportUploadAction.REQUEST_FETCH_RELEASE_STATUS_REPORT_UPLOAD,
        ReportUploadEffect.requestFetchReleaseStatus,
        data,
        callBack
      );
    };
  }

  static requestFetchReleaseUpc(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportUploadAction.REQUEST_FETCH_RELEASE_UPC_REPORT_UPLOAD,
        ReportUploadEffect.requestFetchReleaseUpc,
        data
      );
    };
  }

  static requestCsvStreamingReport(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportUploadAction.REQUEST_FETCH_RELEASE_STATUS_REPORT_UPLOAD,
        ReportUploadEffect.requestCsvStreamingReport,
        data,
        callBack
      );
    };
  }

  static requestYtOfficialRevenueReport(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportUploadAction.REQUEST_YT_OFFICIAL_REVENUE_REPORT_UPLOAD,
        ReportUploadEffect.requestYtOfficialRevenueReport,
        data,
        callBack
      );
    };
  }

  static requestCsvUploadSummary(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY,
        ReportUploadEffect.requestCsvUploadSummary,
        params
      );
    };
  }

  static requestCsvUploadSummaryByID(params, id, callBackFunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReportUploadAction.REQUEST_CSV_UPLOAD_SUMMARY_BY_ID,
        ReportUploadEffect.requestCsvUploadSummaryByID,
        params,
        id,
        callBackFunction
      );
    };
  }
}
