import BaseReducer from "../../../utilities/BaseReducer";
import TelegramAction from "./TelegramAction";

export default class TelegramFeedsReducer extends BaseReducer {
  initialState = {
    telegramFeeds: [],
  };

  [TelegramAction.REQUEST_TELEGRAM_FEEDS_FINISHED](state, action) {
    return {
      ...state,
      telegramFeeds: action.payload,
    };
  }

  [TelegramAction.REQUEST_TELEGRAM_FEEDS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      telegramFeeds: action.payload,
    };
  }
}
