import BaseReducer from "../../../../utilities/BaseReducer";
import TwitterDashboardAction from "./TwitterDashboardAction";

// category reducer function
export default class TwitterDashboardReducer extends BaseReducer {
  initialState = {
    twitterDashboard: [],
  };

  [TwitterDashboardAction.REQUEST_TWITTER_DASHBOARD_FINISHED](state, action) {
    return {
      ...state,
      twitterDashboard: action.payload,
    };
  }
}
