import BaseReducer from "../../../utilities/BaseReducer";
import TicketsAction from "./TicketsAction";

export default class TicketsByFiledsReduser extends BaseReducer {
  //initial state of ticket list
  initialState = {
    ticketFields: [],
  };

  // ticket Fields
  [TicketsAction.REQUEST_TICKETS_BY_FIELDS_FINISHED](state, action) {
    return {
      ...state,
      ticketFields: action.payload,
    };
  }
}
