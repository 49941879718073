import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tab,
  Typography,
  Paper,
  ListItem,
} from "@mui/material";
import { connect } from "react-redux";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Logo from "../../components/reportPdfTemplates/logoContainer/Logo";
import MuiButton from "../../../components/muiButton/MuiButton";
import { theme } from "../../../views/App";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import SelectReportDialog from "./SelectReportDialog";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RectangleIcon from "@mui/icons-material/Rectangle";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import WindowIcon from "@mui/icons-material/Window";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { ReturnReport } from "../../../components/ticketPartials";
import GridOnIcon from "@mui/icons-material/GridOn";
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewDayIcon from "@mui/icons-material/ViewDay";
//Chart Reports

// mapstateto props function
const mapStateToProps = (state) => ({});

function ReportTemplates(props) {
  const [expanded, setExpanded] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(null);
  const [page, setPage] = React.useState(null);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const [tabValue, setTabValue] = React.useState("2");
  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  let [pageWidth, setPageWidth] = useState(210);
  let [pageHeight, setPageHeight] = useState(297);
  let [logoHeight, setLogoHeight] = useState(40);
  let [reportHeight, setReportHeight] = useState(257);

  const returnLogoUrl = (id) => {
    let filtered_logo_array = props.logoList.filter((val) => {
      return val.logo_id === id;
    });

    return filtered_logo_array && filtered_logo_array.length !== 0
      ? filtered_logo_array[0].logo_url
      : "";
  };

  const SingleBoxLayout = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,

              backgroundColor: "white",
              margin: "auto",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              padding: page.reports && page.reports[0].report_name ? 0 : 5,
              boxSizing: "border-box",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              // xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              // className="bg-info"
            >
              {page.reports &&
              page.reports[0] &&
              (page.reports[0].report_name || page.reports[0].report_id) ? (
                ReturnReport(
                  page.reports[0],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(0);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowOneColumnLayout = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              margin: "auto",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                border:
                  page.reports && page.reports[0].report_name
                    ? "none"
                    : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // className="bg-info"
            >
              {page.reports &&
              page.reports[0] &&
              (page.reports[0].report_name || page.reports[0].report_id) ? (
                ReturnReport(
                  page.reports[0],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(0);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                border:
                  page.reports && page.reports[0] ? "none" : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // className="bg-dark"
            >
              {page.reports &&
              page.reports[1] &&
              (page.reports[1].report_name || page.reports[1].report_id) ? (
                ReturnReport(
                  page.reports[1],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(1);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const OneRowTwoColumnLayout = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              margin: "auto",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                width: `${pageWidth / 2}mm`,
                height: "inherit",
                maxWidth: `${pageWidth / 2}mm`,
                overflow: "hidden",
                border:
                  page.reports && page.reports[0].report_name
                    ? "none"
                    : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
              // className="bg-dark"
            >
              {page.reports &&
              page.reports[0] &&
              (page.reports[0].report_name || page.reports[0].report_id) ? (
                ReturnReport(
                  page.reports[0],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(0);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                width: `${pageWidth / 2}mm`,
                maxWidth: `${pageWidth / 2}mm`,
                overflow: "hidden",
                height: "inherit",
                border:
                  page.reports && page.reports[0].report_name
                    ? "none"
                    : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
              // className="bg-info"
            >
              {page.reports &&
              page.reports[1] &&
              (page.reports[1].report_name || page.reports[1].report_id) ? (
                ReturnReport(
                  page.reports[1],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(1);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowTowColumnLayout = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
              }}
              spacing={1}
            >
              <Grid container sx={{ height: "inherit" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[0] &&
                  (page.reports[0].report_name || page.reports[0].report_id) ? (
                    ReturnReport(
                      page.reports[0],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(0);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              spacing={1}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
              }}
            >
              <Grid container sx={{ height: "inherit" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[3] &&
                  (page.reports[3].report_name || page.reports[3].report_id) ? (
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(3);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const SingleLeftDividedRight_2 = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
            >
              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight}mm`,
                  height: `${reportHeight}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[0] &&
                (page.reports[0].report_name || page.reports[0].report_id) ? (
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(0);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[2].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const DividedLeft_2_SingleRight = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
            >
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[0] &&
                  (page.reports[0].report_name || page.reports[0].report_id) ? (
                    ReturnReport(
                      page.reports[0],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(0);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight}mm`,
                  height: `${reportHeight}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[2].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[2] &&
                (page.reports[2].report_name || page.reports[2].report_id) ? (
                  ReturnReport(
                    page.reports[2],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(2);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const TwoColOn1stRowOneColOn2ndRow = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              margin: "auto",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems="center"
              sx={{
                width: `${pageWidth}mm`,
                height: `${reportHeight / 2}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                // border:
                //   page.reports && page.reports[0].report_name
                //     ? "1px solid gray"
                //     : `1px dotted gray`,
                margin: "auto",
                // marginBottom: 3,
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  height: `${reportHeight / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[0] &&
                (page.reports[0].report_name || page.reports[0].report_id) ? (
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(0);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  height: `${reportHeight / 2}mm`,
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                // className="bg-info"
              >
                {page.reports &&
                page.reports[1] &&
                (page.reports[1].report_name || page.reports[1].report_id) ? (
                  ReturnReport(
                    page.reports[1],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(1);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                border:
                  page.reports && page.reports[2].report_name
                    ? "none"
                    : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // className="bg-dark"
            >
              {page.reports &&
              page.reports[2] &&
              (page.reports[2].report_name || page.reports[2].report_id) ? (
                ReturnReport(
                  page.reports[2],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(2);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const OneColOn1stRowTwoColOn2ndRow = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              margin: "auto",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                border:
                  page.reports && page.reports[0].report_name
                    ? "none"
                    : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // marginBottom={3}
              // className="bg-dark"
            >
              {page.reports &&
              page.reports[0] &&
              (page.reports[0].report_name || page.reports[0].report_id) ? (
                ReturnReport(
                  page.reports[0],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(0);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>

            <Grid
              item
              container
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems="center"
              sx={{
                width: `${pageWidth}mm`,
                height: `${reportHeight / 2}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                // border:
                //   page.reports && page.reports[0].report_name
                //     ? "1px solid gray"
                //     : `1px dotted gray`,
                margin: "auto",
                // marginBottom: 3,
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  height: `${reportHeight / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[1].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[1] &&
                (page.reports[1].report_name || page.reports[1].report_id) ? (
                  ReturnReport(
                    page.reports[1],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(1);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  height: `${reportHeight / 2}mm`,
                  border:
                    page.reports && page.reports[2].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                // className="bg-info"
              >
                {page.reports &&
                page.reports[2] &&
                (page.reports[2].report_name || page.reports[2].report_id) ? (
                  ReturnReport(
                    page.reports[2],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(2);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const BIREPORT = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              margin: "auto",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              justifyContent={"center"}
              alignItems="center"
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                boxSizing: "border-box",
              }}
            >
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                alignItems="center"
                justifyContent={"center"}
                textAlign="center"
                sx={{
                  height: `${reportHeight / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                }}
              >
                {page.reports &&
                page.reports[0] &&
                (page.reports[0].report_name || page.reports[0].report_id) ? (
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(0);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid
                item
                container
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems="center"
                xl={4.5}
                lg={4.5}
                md={4.5}
                sm={4.5}
                xs={4.5}
                textAlign="center"
                sx={{
                  height: `${reportHeight / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  container
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems="center"
                  sx={{
                    // width: `${pageWidth}mm`,
                    height: `${reportHeight / 4}mm`,
                    maxHeight: `${reportHeight / 4}mm`,
                    margin: "auto",
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      // width: `${pageWidth / 2}mm`,
                      height: `${reportHeight / 4}mm`,
                      // maxWidth: `${pageWidth / 2}mm`,
                      overflow: "hidden",
                      border:
                        page.reports && page.reports[1].report_name
                          ? "none"
                          : `1px dotted gray`,
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    {page.reports &&
                    page.reports[1] &&
                    (page.reports[1].report_name ||
                      page.reports[1].report_id) ? (
                      ReturnReport(
                        page.reports[1],
                        props.platform_list,
                        props.descriptionCallback,
                        props.activity_list,
                        props.statusList,
                        props.userList
                      )
                    ) : (
                      <IconButton
                        onClick={() => {
                          setOpen(true);
                          setIndex(1);
                          setPage(page);
                        }}
                      >
                        <PostAddIcon
                          style={{
                            height: "45px",
                            width: "45px",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{
                      // width: `${pageWidth / 2}mm`,
                      // maxWidth: `${pageWidth / 2}mm`,
                      overflow: "hidden",
                      height: `${reportHeight / 4}mm`,
                      border:
                        page.reports && page.reports[2].report_name
                          ? "none"
                          : `1px dotted gray`,
                      boxSizing: "border-box",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                    // className="bg-info"
                  >
                    {page.reports &&
                    page.reports[2] &&
                    (page.reports[2].report_name ||
                      page.reports[2].report_id) ? (
                      ReturnReport(
                        page.reports[2],
                        props.platform_list,
                        props.descriptionCallback,
                        props.activity_list,
                        props.statusList,
                        props.userList
                      )
                    ) : (
                      <IconButton
                        onClick={() => {
                          setOpen(true);
                          setIndex(2);
                          setPage(page);
                        }}
                      >
                        <PostAddIcon
                          style={{
                            height: "45px",
                            width: "45px",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "inherit",
                    height: `${reportHeight / 4}mm`,
                    // maxWidth: `${pageWidth}mm`,
                    // maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[3].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {page.reports &&
                  page.reports[3] &&
                  (page.reports[3].report_name || page.reports[3].report_id) ? (
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(3);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xl={4.5}
                lg={4.5}
                md={4.5}
                sm={4.5}
                xs={4.5}
                textAlign="center"
                sx={{
                  height: `${reportHeight / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    // width: `${pageWidth / 2}mm`,
                    height: "inherit",
                    // maxWidth: `${pageWidth / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[4].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                  // className="bg-dark"
                >
                  {page.reports &&
                  page.reports[4] &&
                  (page.reports[4].report_name || page.reports[4].report_id) ? (
                    ReturnReport(
                      page.reports[4],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(4);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    // width: `${pageWidth / 2}mm`,
                    // maxWidth: `${pageWidth / 2}mm`,
                    overflow: "hidden",
                    height: "inherit",
                    border:
                      page.reports && page.reports[5].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                  // className="bg-info"
                >
                  {page.reports &&
                  page.reports[5] &&
                  (page.reports[5].report_name || page.reports[5].report_id) ? (
                    ReturnReport(
                      page.reports[5],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(5);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems="center"
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                boxSizing: "border-box",
              }}
            >
              <Grid
                item
                container
                flexDirection={"column"}
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                textAlign="center"
                sx={{
                  height: `${reportHeight / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "inherit",
                    height: `${reportHeight / 4}mm`,
                    // maxWidth: `${pageWidth}mm`,
                    maxHeight: `${reportHeight / 4}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[6].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  // className="bg-info"
                >
                  {page.reports &&
                  page.reports[6] &&
                  (page.reports[6].report_name || page.reports[6].report_id) ? (
                    ReturnReport(
                      page.reports[6],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(6);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "inherit",
                    height: `${reportHeight / 4}mm`,
                    maxWidth: `${pageWidth}mm`,
                    maxHeight: `${reportHeight / 4}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[7]
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  // className="bg-dark"
                >
                  {page.reports &&
                  page.reports[7] &&
                  (page.reports[7].report_name || page.reports[7].report_id) ? (
                    ReturnReport(
                      page.reports[7],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(7);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                alignItems="center"
                justifyContent={"center"}
                textAlign="center"
                sx={{
                  height: `${reportHeight / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[8].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                }}
              >
                {page.reports &&
                page.reports[8] &&
                (page.reports[8].report_name || page.reports[8].report_id) ? (
                  ReturnReport(
                    page.reports[8],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(8);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                container
                // flexDirection={}
                xl={5}
                lg={5}
                md={5}
                sm={5}
                xs={5}
                textAlign="center"
                sx={{
                  height: `${reportHeight / 2}mm`,
                  maxHeight: `${reportHeight / 2}mm`,
                  overflow: "hidden",

                  boxSizing: "border-box",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "inherit",
                    height: `${reportHeight / 4}mm`,
                  }}
                  spacing={1}
                >
                  <Grid container sx={{ height: "inherit" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        // maxWidth: `${pageWidth / 2}mm`,
                        maxHeight: `${reportHeight / 4}mm`,
                        overflow: "hidden",
                        border:
                          page.reports && page.reports[9].report_name
                            ? "none"
                            : `1px dotted gray`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      {page.reports &&
                      page.reports[9] &&
                      (page.reports[9].report_name ||
                        page.reports[9].report_id) ? (
                        ReturnReport(
                          page.reports[9],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList
                        )
                      ) : (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setIndex(9);
                            setPage(page);
                          }}
                        >
                          <PostAddIcon
                            style={{
                              height: "45px",
                              width: "45px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        // maxWidth: `${pageWidth / 2}mm`,
                        maxHeight: `${reportHeight / 4}mm`,
                        overflow: "hidden",
                        border:
                          page.reports && page.reports[10].report_name
                            ? "none"
                            : `1px dotted gray`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      {page.reports &&
                      page.reports[10] &&
                      (page.reports[10].report_name ||
                        page.reports[10].report_id) ? (
                        ReturnReport(
                          page.reports[10],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList,
                          props.userList
                        )
                      ) : (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setIndex(10);
                            setPage(page);
                          }}
                        >
                          <PostAddIcon
                            style={{
                              height: "45px",
                              width: "45px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  spacing={1}
                  sx={{
                    width: "inherit",
                    height: `${reportHeight / 4}mm`,
                  }}
                >
                  <Grid container sx={{ height: "inherit" }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        // maxWidth: `${pageWidth / 2}mm`,
                        maxHeight: `${reportHeight / 4}mm`,
                        overflow: "hidden",
                        border:
                          page.reports && page.reports[11].report_name
                            ? "none"
                            : `1px dotted gray`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      {page.reports &&
                      page.reports[11] &&
                      (page.reports[11].report_name ||
                        page.reports[11].report_id) ? (
                        ReturnReport(
                          page.reports[11],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList
                        )
                      ) : (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setIndex(11);
                            setPage(page);
                          }}
                        >
                          <PostAddIcon
                            style={{
                              height: "45px",
                              width: "45px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        // maxWidth: `${pageWidth / 2}mm`,
                        maxHeight: `${reportHeight / 4}mm`,
                        overflow: "hidden",
                        border:
                          page.reports && page.reports[12].report_name
                            ? "none"
                            : `1px dotted gray`,
                        padding: page.reports && page.reports.length ? 0 : 5,
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      {page.reports &&
                      page.reports[12] &&
                      (page.reports[12].report_name ||
                        page.reports[12].report_id) ? (
                        ReturnReport(
                          page.reports[12],
                          props.platform_list,
                          props.descriptionCallback,
                          props.activity_list,
                          props.statusList,
                          props.userList
                        )
                      ) : (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setIndex(12);
                            setPage(page);
                          }}
                        >
                          <PostAddIcon
                            style={{
                              height: "45px",
                              width: "45px",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const ThreeRowTowColumnLayout = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 3}mm`,
              }}
              spacing={1}
            >
              <Grid container sx={{ height: "inherit" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[0] &&
                  (page.reports[0].report_name || page.reports[0].report_id) ? (
                    ReturnReport(
                      page.reports[0],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(0);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              spacing={1}
              sx={{
                width: "inherit",
                height: `${reportHeight / 3}mm`,
              }}
            >
              <Grid container sx={{ height: "inherit" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[3] &&
                  (page.reports[3].report_name || page.reports[3].report_id) ? (
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(3);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              spacing={1}
              sx={{
                width: "inherit",
                height: `${reportHeight / 3}mm`,
              }}
            >
              <Grid container sx={{ height: "inherit" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[4].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[4] &&
                  (page.reports[4].report_name || page.reports[4].report_id) ? (
                    ReturnReport(
                      page.reports[4],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(4);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[5].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[5] &&
                  (page.reports[5].report_name || page.reports[5].report_id) ? (
                    ReturnReport(
                      page.reports[5],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(5);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const TwoRowsThreeColumnLayout = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
              }}
              spacing={1}
            >
              <Grid container sx={{ height: "inherit" }}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[0] &&
                  (page.reports[0].report_name || page.reports[0].report_id) ? (
                    ReturnReport(
                      page.reports[0],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(0);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              spacing={1}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
              }}
            >
              <Grid container sx={{ height: "inherit" }}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[3].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[3] &&
                  (page.reports[3].report_name || page.reports[3].report_id) ? (
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(3);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[4].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[4] &&
                  (page.reports[4].report_name || page.reports[4].report_id) ? (
                    ReturnReport(
                      page.reports[4],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(4);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[5].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  {page.reports &&
                  page.reports[5] &&
                  (page.reports[5].report_name || page.reports[5].report_id) ? (
                    ReturnReport(
                      page.reports[5],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(5);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const DividedLeft_3_SingleRight = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
            >
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[0] &&
                  (page.reports[0].report_name || page.reports[0].report_id) ? (
                    ReturnReport(
                      page.reports[0],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(0);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>

                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[2].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight}mm`,
                  height: `${reportHeight}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[3].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[3] &&
                (page.reports[3].report_name || page.reports[3].report_id) ? (
                  ReturnReport(
                    page.reports[3],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(3);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const SingleLeftDividedRight_3 = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
            >
              <Grid
                item
                xs={6}
                sx={{
                  maxWidth: `${pageWidth / 2}mm`,
                  maxHeight: `${reportHeight}mm`,
                  height: `${reportHeight}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  padding: page.reports && page.reports.length ? 0 : 5,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[0] &&
                (page.reports[0].report_name || page.reports[0].report_id) ? (
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(0);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[2].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[3].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[3] &&
                  (page.reports[3].report_name || page.reports[3].report_id) ? (
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(3);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const ThreeColOn1stRowOneColOn2ndRow = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              margin: "auto",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems="center"
              sx={{
                width: `${pageWidth}mm`,
                height: `${reportHeight / 2}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                // border:
                //   page.reports && page.reports[0].report_name
                //     ? "1px solid gray"
                //     : `1px dotted gray`,
                margin: "auto",
                // marginBottom: 3,
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  height: `${reportHeight / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[0] &&
                (page.reports[0].report_name || page.reports[0].report_id) ? (
                  ReturnReport(
                    page.reports[0],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(0);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  height: `${reportHeight / 2}mm`,
                  border:
                    page.reports && page.reports[0].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                // className="bg-info"
              >
                {page.reports &&
                page.reports[1] &&
                (page.reports[1].report_name || page.reports[1].report_id) ? (
                  ReturnReport(
                    page.reports[1],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(1);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  height: `${reportHeight / 2}mm`,
                  border:
                    page.reports && page.reports[2].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                // className="bg-info"
              >
                {page.reports &&
                page.reports[2] &&
                (page.reports[2].report_name || page.reports[2].report_id) ? (
                  ReturnReport(
                    page.reports[2],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(2);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                border:
                  page.reports && page.reports[3].report_name
                    ? "none"
                    : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // className="bg-dark"
            >
              {page.reports &&
              page.reports[3] &&
              (page.reports[3].report_name || page.reports[3].report_id) ? (
                ReturnReport(
                  page.reports[3],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(3);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const OneColOn1stRowThreeColOn2ndRow = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
              margin: "auto",
              marginBottom: 3,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight / 2}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                overflow: "hidden",
                border:
                  page.reports && page.reports[0].report_name
                    ? "none"
                    : `1px dotted gray`,
                padding: page.reports && page.reports.length ? 0 : 5,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // marginBottom={3}
              // className="bg-dark"
            >
              {page.reports &&
              page.reports[0] &&
              (page.reports[0].report_name || page.reports[0].report_id) ? (
                ReturnReport(
                  page.reports[0],
                  props.platform_list,
                  props.descriptionCallback,
                  props.activity_list,
                  props.statusList,
                  props.userList
                )
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setIndex(0);
                    setPage(page);
                  }}
                >
                  <PostAddIcon
                    style={{
                      height: "45px",
                      width: "45px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              )}
            </Grid>

            <Grid
              item
              container
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems="center"
              sx={{
                width: `${pageWidth}mm`,
                height: `${reportHeight / 2}mm`,
                maxHeight: `${reportHeight / 2}mm`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                // border:
                //   page.reports && page.reports[0].report_name
                //     ? "1px solid gray"
                //     : `1px dotted gray`,
                margin: "auto",
                // marginBottom: 3,
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  height: `${reportHeight / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  border:
                    page.reports && page.reports[1].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                {page.reports &&
                page.reports[1] &&
                (page.reports[1].report_name || page.reports[1].report_id) ? (
                  ReturnReport(
                    page.reports[1],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(1);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  height: `${reportHeight / 2}mm`,
                  border:
                    page.reports && page.reports[2].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                // className="bg-info"
              >
                {page.reports &&
                page.reports[2] &&
                (page.reports[2].report_name || page.reports[2].report_id) ? (
                  ReturnReport(
                    page.reports[2],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(2);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  width: `${pageWidth / 2}mm`,
                  maxWidth: `${pageWidth / 2}mm`,
                  overflow: "hidden",
                  height: `${reportHeight / 2}mm`,
                  border:
                    page.reports && page.reports[3].report_name
                      ? "none"
                      : `1px dotted gray`,
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                // className="bg-info"
              >
                {page.reports &&
                page.reports[3] &&
                (page.reports[3].report_name || page.reports[3].report_id) ? (
                  ReturnReport(
                    page.reports[3],
                    props.platform_list,
                    props.descriptionCallback,
                    props.activity_list,
                    props.statusList,
                    props.userList
                  )
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpen(true);
                      setIndex(3);
                      setPage(page);
                    }}
                  >
                    <PostAddIcon
                      style={{
                        height: "45px",
                        width: "45px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const doubleLeftDividedRight_3 = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
            >
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[0] &&
                  (page.reports[0].report_name || page.reports[0].report_id) ? (
                    ReturnReport(
                      page.reports[0],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(0);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[2].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[3].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[3] &&
                  (page.reports[3].report_name || page.reports[3].report_id) ? (
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(3);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[4].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[4] &&
                  (page.reports[4].report_name || page.reports[4].report_id) ? (
                    ReturnReport(
                      page.reports[4],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(4);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  const tripleLeftDoubleRight = (page) => {
    return (
      <>
        {
          <Grid
            item
            container
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            sx={{
              width: `${pageWidth}mm`,
              height: `${pageHeight}mm`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              margin: "auto",
              marginBottom: 3,
              border:
                page.reports && page.reports[0].report_name
                  ? "1px solid gray"
                  : `1px dotted gray`,
            }}
          >
            <Grid
              item
              sx={{
                width: "inherit",
                height: `${logoHeight}mm`,
                maxWidth: `${pageWidth}mm`,
                maxHeight: `${logoHeight}mm`,
              }}
              // className="bg-dark"
            >
              <Logo
                leftLogo={returnLogoUrl(page.left_top_logo)}
                rightLogo={returnLogoUrl(page.right_top_logo)}
              />
            </Grid>

            <Grid
              item
              container
              // xl={12}
              // lg={12}
              // md={12}
              // sm={12}
              xs={12}
              sx={{
                width: "inherit",
                height: `${reportHeight}mm`,
              }}
              alignItems="flex-start"
              textAlign={"center"}
              justifyContent={"center"}
              // spacing={1}
            >
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[0].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[0] &&
                  (page.reports[0].report_name || page.reports[0].report_id) ? (
                    ReturnReport(
                      page.reports[0],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(0);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[1].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[1] &&
                  (page.reports[1].report_name || page.reports[1].report_id) ? (
                    ReturnReport(
                      page.reports[1],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(1);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>

                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 3}mm`,
                    height: `${reportHeight / 3}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[2].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[2] &&
                  (page.reports[2].report_name || page.reports[2].report_id) ? (
                    ReturnReport(
                      page.reports[2],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(2);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={6}
                // className="bg-info"
                flexDirection="column"
              >
                <Grid
                  item
                  // className="bg-light"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[3].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[3] &&
                  (page.reports[3].report_name || page.reports[3].report_id) ? (
                    ReturnReport(
                      page.reports[3],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(3);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  // className="bg-dark"
                  sx={{
                    maxWidth: `${pageWidth / 2}mm`,
                    maxHeight: `${reportHeight / 2}mm`,
                    height: `${reportHeight / 2}mm`,
                    overflow: "hidden",
                    border:
                      page.reports && page.reports[4].report_name
                        ? "none"
                        : `1px dotted gray`,
                    padding: page.reports && page.reports.length ? 0 : 5,
                    boxSizing: "border-box",
                  }}
                >
                  {page.reports &&
                  page.reports[4] &&
                  (page.reports[4].report_name || page.reports[4].report_id) ? (
                    ReturnReport(
                      page.reports[4],
                      props.platform_list,
                      props.descriptionCallback,
                      props.activity_list,
                      props.statusList,
                      props.userList
                    )
                  ) : (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setIndex(4);
                        setPage(page);
                      }}
                    >
                      <PostAddIcon
                        style={{
                          height: "45px",
                          width: "45px",
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="pagebreak" />
      </>
    );
  };

  function ReturnDetailedViewReport(page) {
    return (
      page.reports.length > 0 &&
      page.reports.map((report) =>
        ReturnReport(
          report,
          props.platform_list,
          props.descriptionCallback,
          props.activity_list,
          props.statusList,
          props.userList
        )
      )
    );
  }

  return (
    <Grid container item lg={12}>
      <Grid container item lg={4}>
        <Accordion
          className="p-0 m-0"
          expanded={expanded}
          onChange={handleChange}
          style={{
            backgroundColor: theme.palette.background.default,
            height: "auto",
            borderRadius: theme.borderRadius,
            border: "solid 0.5px #d3e2e6",
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
            marginTop: 3,
            width: "100%",
            padding: 5,
          }}
        >
          <AccordionSummary
            expandIcon={
              <ListItem>
                <IconButton className="p-0 m-0" title="Select Layout">
                  <ExpandMoreIcon />
                </IconButton>
              </ListItem>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "transparent",
              padding: 1,
              boxShadow: theme.shadows,
              // border: `1px solid ${theme.palette.text.primary}`,
            }}
          >
            Report Types
          </AccordionSummary>
          <AccordionDetails
            className="scrollable"
            sx={{
              width: "100%",
              height: 550,
              overflow: "auto",
              backgroundColor: "transparent",
            }}
          >
            <Grid item container lg={12} alignItems="center" gap={1}>
              {!props.openLayoutSelection && (
                <Grid item>
                  <IconButton
                    title="Add Layout"
                    onClick={(event) => {
                      event.ignore = true;
                      props.setOpenLayoutSelection(true);
                    }}
                    sx={{ border: "0" }}
                  >
                    <FontAwesomeIcon
                      icon={faPlusSquare}
                      style={{
                        height: "20px",
                        width: "20px",
                        color: "#2a5e6c",
                      }}
                    ></FontAwesomeIcon>
                  </IconButton>
                </Grid>
              )}

              {!props.openLayoutSelection && (
                <Grid
                  item
                  alignItems="center"
                  onClick={(event) => {
                    event.ignore = true;
                    props.setOpenLayoutSelection(true);
                  }}
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                >
                  Select Layout
                </Grid>
              )}

              {props.openLayoutSelection && (
                <Grid item xl={12} lg={12} md={2} sm={3} xs={4}>
                  <Box margin={"auto"}>
                    <Box margin={"auto"} width="80%">
                      <ToggleButtonGroup
                        orientation="vertical"
                        value={props.view}
                        exclusive
                        onChange={props.boxhandleChange}
                        style={{}}
                        className="mx-2"
                      >
                        <ToggleButton
                          value="SingleBoxLayout"
                          aria-label="SingleBoxLayout"
                        >
                          <RectangleIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="TwoRowOneColumnLayout"
                          aria-label="TwoRowOneColumnLayout"
                        >
                          <ViewAgendaIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="OneRowTwoColumnLayout"
                          aria-label="OneRowTwoColumnLayout"
                        >
                          <ViewAgendaIcon
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        orientation="vertical"
                        value={props.view}
                        exclusive
                        onChange={props.boxhandleChange}
                        className="mx-2"
                      >
                        <ToggleButton
                          value="TwoRowTowColumnLayout"
                          aria-label="TwoRowTowColumnLayout"
                        >
                          <WindowIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="Single Left, Divided Right(2)"
                          aria-label="Single Left, Divided Right(2)"
                        >
                          <AutoAwesomeMosaicIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="Divided Left(2), Single right"
                          aria-label="Divided Left(2), Single right"
                        >
                          <AutoAwesomeMosaicIcon
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        orientation="vertical"
                        value={props.view}
                        exclusive
                        onChange={props.boxhandleChange}
                        className="mx-2"
                      >
                        <ToggleButton
                          value="Two Col on first Row, One col on second Row"
                          aria-label="Two Col on first Row, One col on second Row"
                        >
                          <AutoAwesomeMosaicIcon
                            style={{
                              transform: "rotate(270deg)",
                            }}
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="One Col on first Row, Two col on second Row"
                          aria-label="One Col on first Row, Two col on second Row"
                        >
                          <AutoAwesomeMosaicIcon
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="three rows two columns"
                          aria-label="three rows two columns"
                        >
                          <ViewModuleIcon
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>

                      <ToggleButtonGroup
                        orientation="vertical"
                        value={props.view}
                        exclusive
                        onChange={props.boxhandleChange}
                        className="mx-2"
                      >
                        <ToggleButton
                          value="two rows three columns"
                          aria-label="two rows three columns"
                        >
                          <ViewModuleIcon
                          // style={{
                          //   transform: "rotate(90deg)",
                          // }}
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="Single Left, Divided Right(3)"
                          aria-label="Single Left, Divided Right(3)"
                        >
                          <TableChartIcon
                            style={{
                              transform: "rotate(270deg)",
                            }}
                          />
                        </ToggleButton>

                        <ToggleButton
                          value="Divided Left(3), Single right"
                          aria-label="Divided Left(3), Single right"
                        >
                          <TableChartIcon
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          />
                        </ToggleButton>

                        {/* <ToggleButton value="BI" aria-label="BI">
                          <GridOnIcon
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          />
                        </ToggleButton> */}
                      </ToggleButtonGroup>

                      <ToggleButtonGroup
                        orientation="vertical"
                        value={props.view}
                        exclusive
                        onChange={props.boxhandleChange}
                        className="mx-2"
                      >
                        <ToggleButton
                          value="three Col on first Row, One col on second Row"
                          aria-label="three Col on first Row, One col on second Row"
                        >
                          <TableChartIcon
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        </ToggleButton>

                        <ToggleButton
                          value="One Column on first Row, Two Column on second Row"
                          aria-label="One Column on first Row, Two Column on second Row"
                        >
                          <TableChartIcon />
                        </ToggleButton>

                        <ToggleButton
                          value="Double Left, Divided Right(3)"
                          aria-label="Double Left, Divided Right(3)"
                        >
                          <ViewDayIcon
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>

                      <ToggleButtonGroup
                        orientation="vertical"
                        value={props.view}
                        exclusive
                        onChange={props.boxhandleChange}
                        className="mx-2"
                      >
                        <ToggleButton
                          value="Triple Left, double right"
                          aria-label="Triple Left, double right"
                        >
                          <ViewDayIcon
                            style={{
                              transform: "rotate(90deg)",
                            }}
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid item lg={12}>
              <Typography>
                <Box width="100%" mt={5}>
                  <Grid
                    container
                    item
                    xl={12}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {props.PagesCheckboxList}
                  </Grid>
                </Box>
              </Typography>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid
        lg={7.5}
        sx={{
          width: "100%",
          height: 650,
          backgroundColor: theme.palette.background.default,
          padding: 2,
          borderRadius: theme.borderRadius,
          border: "solid 0.5px #d3e2e6",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
        }}
        // className="bg-info"
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1 }}>
            <TabList
              onChange={tabHandleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{ border: "none" }}
                label="Detailed View"
                value="1"
                style={{
                  textTransform: "none",
                  minHeight: 0, // set the minimum height to 0
                  padding: "10.8px 20px", // adjust the padding to decrease the height
                }}
              />
              <Tab
                sx={{ border: "none" }}
                label="Pdf Preview"
                value="2"
                style={{
                  textTransform: "none",
                  minHeight: 0, // set the minimum height to 0
                  padding: "10.8px 20px", // adjust the padding to decrease the height
                }}
              />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              padding: "5px",
            }}
          >
            <Grid
              lg={12}
              className="scrollable"
              sx={{
                width: "100%",
                height: 550,
                overflow: "auto",
                overscrollBehaviorY: "scroll",
                backgroundColor: "white",
              }}
            >
              {props.pages.length > 0 ? (
                props.pages.map((page) => ReturnDetailedViewReport(page))
              ) : (
                <div className="nodataFoundContainer">
                  <p className="nodataFoundText">
                    For detailed view select the layout and report type
                  </p>
                </div>
              )}
            </Grid>
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              padding: "5px",
              height: "auto",
            }}
          >
            <Grid
              className="scrollable"
              lg={12}
              sx={{
                width: "100%",
                height: 550,
                overflow: "auto",
                overscrollBehaviorY: "scroll",
              }}
            >
              {props.pages.map((page) => {
                return page.page_layout === "SingleBoxLayout"
                  ? SingleBoxLayout(page)
                  : page.page_layout === "TwoRowOneColumnLayout"
                  ? TwoRowOneColumnLayout(page)
                  : page.page_layout === "OneRowTwoColumnLayout"
                  ? OneRowTwoColumnLayout(page)
                  : page.page_layout === "TwoRowTowColumnLayout"
                  ? TwoRowTowColumnLayout(page)
                  : page.page_layout === "Single Left, Divided Right(2)"
                  ? SingleLeftDividedRight_2(page)
                  : page.page_layout === "Divided Left(2), Single right"
                  ? DividedLeft_2_SingleRight(page)
                  : page.page_layout ===
                    "Two Col on first Row, One col on second Row"
                  ? TwoColOn1stRowOneColOn2ndRow(page)
                  : page.page_layout ===
                    "One Col on first Row, Two col on second Row"
                  ? OneColOn1stRowTwoColOn2ndRow(page)
                  : // : page.page_layout === "BI"
                  // ? BIREPORT(page)
                  page.page_layout === "three rows two columns"
                  ? ThreeRowTowColumnLayout(page)
                  : page.page_layout === "two rows three columns"
                  ? TwoRowsThreeColumnLayout(page)
                  : page.page_layout === "Single Left, Divided Right(3)"
                  ? SingleLeftDividedRight_3(page)
                  : page.page_layout === "Divided Left(3), Single right"
                  ? DividedLeft_3_SingleRight(page)
                  : page.page_layout ===
                    "three Col on first Row, One col on second Row"
                  ? ThreeColOn1stRowOneColOn2ndRow(page)
                  : page.page_layout ===
                    "One Column on first Row, Two Column on second Row"
                  ? OneColOn1stRowThreeColOn2ndRow(page)
                  : page.page_layout === "Double Left, Divided Right(3)"
                  ? doubleLeftDividedRight_3(page)
                  : page.page_layout === "Triple Left, double right"
                  ? tripleLeftDoubleRight(page)
                  : null;
              })}
            </Grid>
            {open && (
              <SelectReportDialog
                reportsList={props.templateReports}
                pages={props.pages}
                setReportPages={props.setReportPages}
                page={page}
                index={index}
                onClose={() => setOpen(false)}
                open={open}
              />
            )}
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
}

export default connect(mapStateToProps)(ReportTemplates);
