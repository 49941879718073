import React, { useState, useEffect, useRef } from "react";
import environment from "environment";
import Logo from "./logoContainer/Logo";
import { connect } from "react-redux";
import Description from "./descriptionContainer/Description";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import PaginationUI from "../../../components/pagination/Pagination";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { axiosRequest } from "../../../utilities/FetchRequest";
import { NivoBulletChart } from "../../../components/nivoCharts/NivoBulletChart";
import StatusAction from "../../stores/status/StatusAction";
import { NivoBarChart } from "../../../components/nivoCharts/NivoBarChart";
import { firstLetterOrange } from "../../../components/ticketPartials";
import { prefixZero_for_singleDigit } from "../../../components/ticketPartials";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [StatusAction.REQUEST_STATUS_FILTER]),
  statusFilter: state.statusFilter.statusFilter || [],
});

function OverallTicketStatusUserwiseChart(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function fetchData(dateRange) {
    let API = `${
      environment.api.baseApi
    }/agent_ticket_status?show_unassigned=false${
      props.DateFilter || props.filterClicked
        ? `&from=${dateRange[0]}&to=${dateRange[1]}`
        : ""
    }`;

    return axiosRequest(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      return response;
    });
  }

  function fetchStatusFilter(param) {
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["status_name.keyword", "asc"]]`,
    };
    fetchStatusFilter(param);
  }, []);

  let finalStatus = [];
  let notFinalStatus = [];
  if (props.statusFilter?.hits?.hits) {
    props.statusFilter.hits.hits?.map((item) => {
      if (item._source.final_status) {
        finalStatus.push(item._source.status_name);
      } else {
        notFinalStatus.push(item._source.status_name);
      }
    });
  }

  useEffect(() => {
    (async function CallApi() {
      setLoading(true);
      await fetchData(props.DateFilter).then((e) => setData(e));

      setLoading(false);
    })();
  }, [props.DateFilter]);

  useEffect(() => {
    if (props.clearClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.DateFilter).then((e) => setData(e));

        setLoading(false);
        props.setclearClicked && props.setclearClicked(false);
      })();
    }
  }, [props.clearClicked]);

  useEffect(() => {
    if (props.filterClicked) {
      (async function CallApi() {
        setLoading(true);
        await fetchData(props.openDateFilter).then((e) => setData(e));
        setLoading(false);
        props.setFilterClicked(false);
      })();
    }
  }, [props.openDateFilter, props.filterClicked]);

  let TicketsCountList = [];

  data &&
    data.data &&
    data.data.map((item) => {
      item.key !== "total" &&
        TicketsCountList.push({
          agent_name: item.key,
          total_tickets: item.count,
          status: item.status,
        });
    });

  let NoDataFoundBool = data?.data.length === 0;

  let status_id_list = [];
  TicketsCountList &&
    TicketsCountList.map((item) => {
      item.status &&
        item.status.map((e) => {
          status_id_list.push(e.key);
        });
    });

  status_id_list = [...new Set(status_id_list)];

  function StatusName(val) {
    return (
      props.statusList &&
      props.statusList
        .filter((item) => item.status_id === val)
        .map((item) => {
          return item.status_name;
        })
    );
  }

  let columns = [];

  columns = [
    // { field: "id", headerName: "Sl No", resizable: false, flex: 1, width: 50 },
    {
      field: "agent_name",
      headerName: "Bridge User",
      width: 150,
      flex: 2,
      resizable: true,
    },
    {
      field: "total_tickets",
      headerName: "Total",
      width: 150,
      flex: 2,
      resizable: true,
      align: "left",
    },
  ];

  status_id_list &&
    status_id_list.map((item) => {
      item &&
        columns.push({
          field: StatusName(item)[0],
          headerName: StatusName(item)[0],
          width: 100,
          flex: 1,
          resizable: true,
          align: "left",
          headerAlign: "left",
        });
    });

  let updatedRows = [];

  TicketsCountList &&
    TicketsCountList.sort((a, b) => {
      const agentNameA = a.agent_name.toLowerCase();
      const agentNameB = b.agent_name.toLowerCase();

      if (agentNameA < agentNameB) {
        return -1; // agentNameA comes before agentNameB
      }
      if (agentNameA > agentNameB) {
        return 1; // agentNameA comes after agentNameB
      }
      return 0; // agentNameA and agentNameB are equal
    });

  TicketsCountList &&
    TicketsCountList.map((item, index) => {
      if (item?.agent_name !== "Unassigned" && item?.agent_name !== "total") {
        const rows = {
          id: index + 1,
          agent_name: item.agent_name,
          total_tickets: prefixZero_for_singleDigit(item.total_tickets),
        };

        status_id_list.forEach((key) => {
          if (key) {
            const filteredStatus = item.status.find((val) => val.key === key);

            rows[StatusName(key)[0]] = prefixZero_for_singleDigit(
              filteredStatus?.count
            );
          }
        });

        updatedRows.push(rows);
      }
    });

  const totalRows = updatedRows && updatedRows.length;
  const paginationSize = 10;
  const totalPages = Math.ceil(totalRows && totalRows / paginationSize);

  const [currentPage, setCurrentPage] = useState(0);

  const startIndex = currentPage * paginationSize;
  const endIndex = startIndex + paginationSize;

  const finalRows = updatedRows && updatedRows.slice(startIndex, endIndex);

  let chartData = [];

  const excludedKeys = ["id", "agent_name", "total_tickets"];
  // const excludedKeys = ["id", "agent_name"];
  const filteredKeys =
    updatedRows[0] &&
    Object.keys(updatedRows[0]).filter((key) => !excludedKeys.includes(key));

  updatedRows.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (key !== "id") {
        // Add the value to the corresponding key in chartData
        chartData[key] = (chartData[key] || 0) + obj[key];
      }
    });
  });

  const customColors = [
    `${theme.platform.color1}`,
    `${theme.platform.color10}`,
    `${theme.platform.color2}`,
    `${theme.platform.color14}`,
    `${theme.platform.color3}`,
    `${theme.platform.color19}`,
    `${theme.platform.color4}`,
    `${theme.platform.color5}`,
    `${theme.platform.color6}`,
    `${theme.platform.color7}`,
    `${theme.platform.color8}`,
    `${theme.platform.color9}`,
    `${theme.platform.color11}`,
    `${theme.platform.color12}`,
    `${theme.platform.color13}`,
    `${theme.platform.color15}`,
    `${theme.platform.color16}`,
    `${theme.platform.color17}`,
    `${theme.platform.color18}`,
    `${theme.platform.color20}`,
  ];

  // Initialize a Set to store keys
  const keysSet = new Set();

  // Iterate over each object in the data array
  updatedRows.forEach((obj) => {
    // Get the keys of the current object, excluding 'id' and 'agent_name'
    Object.keys(obj).forEach((key) => {
      if (key !== "id" && key !== "agent_name") {
        // Add the key to the Set
        keysSet.add(key);
      }
    });
  });

  // Convert the Set to an array
  const keysArray = Array.from(keysSet).filter((key) => key !== "undefined");

  const getOption = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    let shouldShowLegend = false;
    if (
      (props.ChartContainerHeight?.current &&
        props.ChartContainerWidth?.current &&
        props.ChartContainerHeight.current >= 350 &&
        props.ChartContainerWidth.current > 350) ||
      (props.ChartContainerHeight?.current === undefined &&
        props.ChartContainerWidth?.current === undefined)
    ) {
      shouldShowLegend = true;
    }
    const yAxisData = updatedRows.reverse().map((item) => item.agent_name);
    const series = [];

    // Extract keys dynamically except for 'agent_name' and 'total_tickets'
    const keys = Array.from(
      new Set(
        updatedRows
          .flatMap((item) => Object.keys(item))
          .filter(
            (key) =>
              key !== "agent_name" &&
              key !== "total_tickets" &&
              key !== "id" &&
              key !== "undefined"
          )
      )
    );

    const colorValues = customColors;

    keys.forEach((key, index) => {
      const seriesData = updatedRows.map((item) => ({
        name: item.agent_name,
        value: item[key] ? parseInt(item[key]) : 0,
      }));

      series.push({
        name: key,
        type: "bar",
        stack: "total",
        showBackground: true,
        backgroundStyle: {
          color: "#f8f9f91A", // 1A represents the transparency (10% opacity)
        },
        label: {
          show: true,
          position: "insideRight", // Position label inside the bar
          formatter: (params) => {
            let val = params.value;
            let formattedvalue = formatValue(val);
            return val === 0 ? "" : formattedvalue;
          },
          color: theme.palette.primary.dark,
        },
        emphasis: {
          focus: "series",
        },
        data: seriesData,
        itemStyle: {
          color: colorValues[index % colorValues.length],
        },
      });
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        confine: true,
        formatter: (params) => {
          let tooltipContent = `${params[0].axisValue}<br/>`;
          params.forEach((item) => {
            if (item.value !== 0) {
              let val = item.value;
              let formattedvalue = formatValue(val);
              const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${item.color};border-radius:50%;"></span>`;
              tooltipContent += `${colorSpan}${item.seriesName} : ${formattedvalue}<br/>`;
            }
          });
          return tooltipContent;
        },
      },
      legend: {
        icon: "circle",
        data: shouldShowLegend ? keys : [],
      },
      grid: {
        top: props.ChartContainerHeight?.current >= 500 ? "5%" : "10%",
        left: "6%",
        right: "3%",
        bottom: "5%",
        containLabel: true,
      },
      yAxis: {
        type: "category",
        data: yAxisData,
        axisLabel: {
          show: props.detailedReport ? true : false,
          padding: [0, 30, 0, 0],
          hideOverlap: true,
        },

        axisTick: {
          show: true,
        },
        name:
          props.detailedReport || props.ChartContainerWidth?.current > 600
            ? "Bridge User"
            : "",
        // name: "Bridge User",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 0,
        },
      },

      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      labelLayout: {
        hideOverlap: true,
      },
      xAxis: {
        type: "value",
        name:
          props.detailedReport || props.ChartContainerHeight?.current > 300
            ? "Count"
            : "",
        nameLocation: "middle",
        nameTextStyle: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontFamily: "BookAntiqua",
          verticalAlign: "left",
          lineHeight: 25,
        },
        axisLabel: {
          hideOverlap: true,
          formatter: (value) => {
            let formattedValue = formatValue(value);
            return formattedValue;
          },
        },
      },
      series: series,
    };
  };

  useEffect(() => {
    if (props.DownloadClicked) {
      const rearrangedArray = updatedRows.map((item) => {
        // Create a new object with the desired order
        const rearrangedItem = {
          ["Bridge User"]: item.agent_name, // Add platform as the first key
          ...Object.keys(item)
            .filter((key) => key !== "agent_name" && key !== "total_tickets")
            .reduce((acc, key) => {
              acc[key] = prefixZero_for_singleDigit(item[key]);
              return acc;
            }, {}), // Add all other keys except platform and total
          Total: prefixZero_for_singleDigit(item.total_tickets), // Add total as the last key
        };

        delete rearrangedItem["id"];

        return rearrangedItem;
      });

      props.setexcelData(rearrangedArray);
    }
  }, [props.DownloadClicked, updatedRows]);

  return (
    <LoadingIndicator isActive={loading}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flex: "12",
        }}
      >
        {!loading ? (
          <>
            {!loading && !NoDataFoundBool && updatedRows?.length ? (
              <Box height="100%" width={"100%"}>
                <ReactEcharts
                  option={getOption()}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                />
              </Box>
            ) : (
              !loading && <NoDataFound />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingBgImage width={"50%"} />
          </Box>
        )}
      </Box>
    </LoadingIndicator>
  );
}
export default connect(mapStateToProps)(OverallTicketStatusUserwiseChart);
