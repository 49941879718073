import React, { useState, useEffect, useRef, useCallback } from "react";
import environment from "environment";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../../../views/App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import { axiosRequest } from "../../../utilities/FetchRequest";
import * as echarts from "echarts";
function StackedHorizontalBar(props) {
  let accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      icon: "circle",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      data: ["YouTube", "Facebook", "Instagram", "Twitter", "Linkedn"],
    },
    series: [
      {
        name: "Comment",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#468C9D",
        },
        data: [320, 302, 301, 334, 390],
      },
      {
        name: "Post/Caption Mention",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#6BA5B2",
        },
        data: [120, 132, 101, 134, 234],
      },
      {
        name: "Tagged Post",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        itemStyle: {
          color: "#92C4D0",
        },
        emphasis: {
          focus: "series",
        },
        data: [220, 182, 191, 234, 290],
      },

      {
        name: "Direct Message",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        itemStyle: {
          color: "#B4D5DD",
        },
        emphasis: {
          focus: "series",
        },
        data: ["", 832, 901, 934, 1290, 1330, 1320],
      },
    ],
  };
  return (
    <LoadingIndicator isActive={loading}>
      {!loading ? (
        <>
          {!loading ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <ReactEcharts
                option={getOption}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                notMerge={true}
                lazyUpdate={true}
                onEvents={{
                  click: (params) => {
                    // console.log("clicked:", params);
                  },
                }}
              />
            </Box>
          ) : (
            !loading && <NoDataFound />
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "30vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingBgImage width={"50%"} />
        </Box>
      )}
    </LoadingIndicator>
  );
}
export default StackedHorizontalBar;
