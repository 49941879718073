import BaseReducer from "../../../utilities/BaseReducer";
import GlassdoorFeedsAction from "./GlassdoorFeedsAction";

// GlassdoorFeedsReduser  function
export default class GlassdoorFeedsReduser extends BaseReducer {
  initialState = {
    glassdoorFeeds: [],
  };
  [GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_FINISHED](state, action) {
    return {
      ...state,
      glassdoorFeeds: action.payload,
    };
  }

  [GlassdoorFeedsAction.REQUEST_GLASSDOOR_FEED_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      glassdoorFeeds: action.payload,
    };
  }
}
