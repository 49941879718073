import environment from "environment";
import EffectUtility from "../../../../utilities/EffectUtility";
import ReportUploadPostModel from "./models/ReportUploadPostModel";
import CsvUploadSummaryGetModel from "./models/CsvUploadSummaryGetModel";
import CsvUploadSummaryGetbyIDModel from "./models/CsvUploadSummaryGetbyIDModel";

export default class ReportUploadEffect {
  // calling an API according to the resource model
  static async requestFetchReleaseStatus(data, callBack) {
    const endpoint = environment.api.fetchReleaseStatus;
    let response = EffectUtility.postToModel(
      ReportUploadPostModel,
      endpoint,
      data
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestFetchReleaseUpc(data) {
    const endpoint = environment.api.fetchReleaseUpc;
    return EffectUtility.postToModel(ReportUploadPostModel, endpoint, data);
  }

  // put method for the resource
  static async requestCsvStreamingReport(data, callBack) {
    const endpoint = environment.api.csvStreamingReport;
    let response = EffectUtility.postToModel(
      ReportUploadPostModel,
      endpoint,
      data
    );
    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  static async requestYtOfficialRevenueReport(data, callBack) {
    const endpoint = environment.api.ytOfficialRevenueReport;
    let response = EffectUtility.postToModel(
      ReportUploadPostModel,
      endpoint,
      data
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }

  // post method for the resource
  static async requestCsvUploadSummary(params) {
    const endpoint = environment.api.csvUploadSummary;
    return EffectUtility.getToModel(CsvUploadSummaryGetModel, endpoint, params);
  }

  static async requestCsvUploadSummaryByID(params, ID, callBackFunction) {
    const endpoint = environment.api.csvUploadSummary + "/" + ID;
    const response = EffectUtility.getToModel(
      CsvUploadSummaryGetbyIDModel,
      endpoint,
      params
    );

    if (callBackFunction) {
      response.then((res) => {
        callBackFunction(res);
      });
    }

    return response;
  }
}
