import BaseReducer from "../../../../utilities/BaseReducer";
import TerritoryAction from "./TerritoryAction";

// TerritoryReduser
export default class TerritoryReducer extends BaseReducer {
  initialState = {
    Territory: [],
  };
  [TerritoryAction.REQUEST_TERRITORY_FINISHED](state, action) {
    return {
      ...state,
      Territory: action.payload,
    };
  }

  [TerritoryAction.REQUEST_TERRITORY_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      Territory: action.payload,
    };
  }
}
