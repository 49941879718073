import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import UserLastWorkingProjectAction from "../../ormTicketing/stores/userLastWorkingProject/UserLastWorkingProjectAction";
import { Typography, useMediaQuery } from "@mui/material";
import { theme } from "../App";

// mapState to props
const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [
    UserLastWorkingProjectAction.REQUEST_LAST_WORKING_PROJECT_BY_ID,
  ]),
  lastWorkingProject:
    state.userLastWorkingProjectById.userLastWorkingProjectById || {},
});
const WorkingOnProjectNav = (props) => {
  const isXl = useMediaQuery(() => theme.breakpoints.up("xl"));
  const isLg = useMediaQuery(() => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(() => theme.breakpoints.up("laptop"));
  const isMd = useMediaQuery(() => theme.breakpoints.up("md"));

  const truncationLength = isXl
    ? 31 // Adjust as needed for xl
    : isLg
    ? 22 // Adjust as needed for lg
    : isLaptop
    ? 22 // Adjust as needed for md
    : isMd
    ? 15 // Adjust as needed for md
    : 15; // Default for smaller sizes

  return (
    <Typography
      textOverflow="ellipsis" // Truncate with three dots
      whiteSpace="nowrap" // No line break
      fontSize={{
        xl: "24px",
        lg: "20px",
        md: "18px",
        sm: "16px",
        xs: "10px",
      }}
      title={
        props?.lastWorkingProject?._source?.project?.project_name.length >
        truncationLength
          ? props?.lastWorkingProject?._source?.project?.project_name
          : null
      }
    >
      {props.lastWorkingProject.found === true
        ? props?.lastWorkingProject?._source?.project?.project_name.length >
          truncationLength
          ? props?.lastWorkingProject?._source?.project?.project_name.substring(
              0,
              truncationLength - 3
            ) + "..."
          : props?.lastWorkingProject?._source?.project?.project_name
        : "Select project here"}
    </Typography>
  );
};

export default connect(mapStateToProps)(WorkingOnProjectNav);
