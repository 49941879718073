import BaseReducer from "../../../../utilities/BaseReducer";
import TwitterDirectMessageAction from "./TwitterDirectMessageAction";

// TwitterDirectMessageReducer function
export default class TwitterDirectMessageReducer extends BaseReducer {
  initialState = {
    twitterDirectMeassage: [],
  };

  [TwitterDirectMessageAction.REQUEST_TWITTERDIRECTMESSAGE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      twitterDirectMeassage: action.payload,
    };
  }

  [TwitterDirectMessageAction.REQUEST_TWITTER_DM_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      twitterDirectMeassage: action.payload,
    };
  }
}
