import BaseReducer from "../../../utilities/BaseReducer";
import FacebookAction from "./FacebookAction";

// twitter reducer function
export default class FacebookWebhookReducer extends BaseReducer {
  initialState = {
    facebookWebhook: [],
  };

  [FacebookAction.REQUEST_FACEBOOK_WEBHOOK_FINISHED](state, action) {
    return {
      ...state,
      facebookWebhook: action.payload,
    };
  }
}
