import { BaseModel } from "sjs-base-model";
import { bool } from "yup";

export default class SurveyUserMapDeleteModel extends BaseModel {
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
