import { BaseModel } from "sjs-base-model";

export default class TelegramSubscrbeMoodel extends BaseModel {
  error_list = [];
  status = "";
  total_subscribed = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
