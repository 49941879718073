import BaseReducer from "../../../utilities/BaseReducer";
import UserRoleMappingAction from "./UserRoleMappingAction";

export default class UserRoleMappingFilterReduser extends BaseReducer {
  //initial state of UserRoleMappingFilterReduser
  initialState = {
    userRoleMappingFilter: [],
  };
  //UserRoleMappingFilterReduser request action finish
  [UserRoleMappingAction.REQUEST_USER_ROLE_MAPPING_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      userRoleMappingFilter: action.payload,
    };
  }
}
