import BaseReducer from "../../../../utilities/BaseReducer";
import ProxyAction from "./ProxyAction";

export default class ProxyAccountInfoReducer extends BaseReducer {
  initialState = {
    proxyAccountInfo: [],
  };
  [ProxyAction.REQUEST_PROXY_ACCOUNT_INFO_FINISHED](state, action) {
    return {
      ...state,
      proxyAccountInfo: action.payload,
    };
  }
}
